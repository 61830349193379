import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import BigButton from 'components/BigButton';
import CloseIcon from 'components/Icons/Close';

import styles from './DigitalTwinSidePanel.module.css';

const DigitalTwinSidePanel = ({
  opened, children, onClose, headerText, sidePanelRef,
}) => (
  <div
    ref={sidePanelRef}
    className={classnames(
      styles.sidePanel,
      {
        [styles.sidePanelOpened]: opened
      }
    )}
  >
    <div className={styles.header}>
      <div className={styles.headerRow}>
        <h3 className={styles.headerText}>
          {headerText}
        </h3>
        <BigButton
          className={styles.closeIcon}
          onClick={onClose}
          icon={<CloseIcon />}
          theme='transparent'
          bigIcon
        />
      </div>
    </div>
    {children}
  </div>
);

DigitalTwinSidePanel.propTypes = {
  opened: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.element
  ]),
  onClose: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  sidePanelRef: PropTypes.object,
};

DigitalTwinSidePanel.defaultProps = {
  children: null,
  headerText: null,
  sidePanelRef: null,
};

export default DigitalTwinSidePanel;
