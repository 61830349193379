import moment from 'moment-timezone';

export default function generateFakeWeeklyData() {
  const result = [];
  const now = moment().isoWeekday(1).subtract(28 * 7, 'days');

  for (let i = 0; i < 28; i++) {
    result.push({
      periodStart: now.format('YYYY-MM-DD'),
      periodLength: 'week',
      metricValues: {},
      isFake: true,
    });

    now.add(7, 'days');
  }

  return result;
}
