import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getYearFromRoute,
  getOrganizationSlug,

  getAllFruitClasses,
  getAllVarieties,
  getAllPlantingCycles,
  getDefaultOpenFromRoute,
} from 'store/company/selectors';
import { trackPageViewSettings } from 'store/company/actions';

import {
  getDataQualityReport,
  getIsDataQualityReportFetching,
  getGreenhousesOptions,
  getFilteredGreenhouses,
  getDataTypeOptions,
} from 'store/dataQuality/selectors';

import {
  requestDataQualityReport,
  updateGreenhousesOptions,
  updateDataTypeOptions,
} from 'store/dataQuality/actions';

import SettingsDataQuality from '../components/SettingsDataQuality';

const mapDispatchToProps = {
  requestDataQualityReport,
  updateGreenhousesOptions,
  updateDataTypeOptions,
  trackPageViewSettings,
};

const mapStateToProps = (state, props) => ({
  ...props,

  year: getYearFromRoute(state, props),

  organizationSlug: getOrganizationSlug(state, props),
  dataQualityReport: getDataQualityReport(state),
  isDataQualityReportFetching: getIsDataQualityReportFetching(state),
  greenhousesOptions: getGreenhousesOptions(state),
  filteredGreenhouses: getFilteredGreenhouses(state),
  dataTypeOptions: getDataTypeOptions(state),

  fruitClasses: getAllFruitClasses(state, props),
  varieties: getAllVarieties(state, props),
  allPlantingCycles: getAllPlantingCycles(state),
  defaultOpen: getDefaultOpenFromRoute(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsDataQuality));
