import { createAction } from 'redux-actions';

export const requestSimulationById = createAction('REQUEST_SIMULATION_BY_ID');
export const receiveSimulationById = createAction('RECEIVE_SIMULATION_BY_ID');

export const requestSaveSimulationById = createAction('REQUEST_SAVE_SIMULATION_BY_ID');
export const receiveSaveSimulationById = createAction('RECEIVE_SAVE_SIMULATION_BY_ID');

export const requestSaveSimulation = createAction('REQUEST_SAVE_SIMULATION');
export const receiveSaveSimulation = createAction('RECEIVE_SAVE_SIMULATION');

export const requestDeleteSimulationById = createAction('REQUEST_DELETE_SIMULATION_BY_ID');
export const receiveDeleteSimulationById = createAction('RECEIVE_DELETE_SIMULATION_BY_ID');

export const requestSimulationsList = createAction('REQUEST_SIMULATIONS_LIST');
export const receiveSimulationsList = createAction('RECEIVE_SIMULATIONS_LIST');

export const requestNewSimulationId = createAction('REQUEST_NEW_SIMULATION_ID');
export const receiveNewSimulationId = createAction('RECEIVE_NEW_SIMULATION_ID');

export const requestNewSimulationParams = createAction('REQUEST_NEW_SIMULATION_PARAMS');
export const receiveNewSimulationParams = createAction('RECEIVE_NEW_SIMULATION_PARAMS');

export const setCalculateInProgress = createAction('SET_CALCULATE_IN_PROGRESS');
export const requestSimulationCalculate = createAction('REQUEST_SIMULATION_CALCULATE');
export const receiveSimulationCalculate = createAction('RECEIVE_SIMULATION_CALCULATE');

export const requestSupportedVarieties = createAction('REQUEST_SUPPORTED_VARIETIES');
export const receiveSupportedVarieties = createAction('RECEIVE_SUPPORTED_VARIETIES');

export const setSimulationEditedData = createAction('SET_SIMULATION_EDITED_DATA');
export const resetSimulationEditedData = createAction('RESET_SIMULATION_EDITED_DATA');

export const requestSaveSimulationByIdWithoutParams = createAction('REQUEST_SAVE_SIMULATION_BY_ID_WITHOUT_PARAMS');
export const receiveSaveSimulationByIdWithoutParams = createAction('RECEIVE_SAVE_SIMULATION_BY_ID_WITHOUT_PARAMS');

export const requestSimulationDatesChange = createAction('REQUEST_SIMULATION_DATES_CHANGE');
export const receiveSimulationDatesChange = createAction('RECEIVE_SIMULATION_DATES_CHANGE');
