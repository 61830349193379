import React, {
  FocusEventHandler,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';

// @ts-ignore
import ReactTooltip from 'react-tooltip';

import classnames from 'classnames';
import { get, uniqueId } from 'lodash';

// @ts-ignore
import moment from 'moment-timezone';

// @ts-ignore
import { injectIntl, FormattedMessage, InjectedIntlProps } from 'react-intl';

import {
  Compartment,
  Location,
  Metric,
  SubNode,
  PlantingCycle,
  Species,
  Variety,
  FruitClass,
  AvailableIdTypes,
  TreeNodeIdTypes,
  Preset,
  MetricDef,
  NodeComponents,
  SpeciesApi,
  MetricGroupInput,
  GraphsPresetRange,
} from 'store/graphs/types';

import Select from 'components/Select';
import tooltipStyles from 'components/Tooltip/index.module.css';

import { getNameByLocal } from 'helpers/getNameByLocal';

import {
  ChangeGraphPeriodAndThreshold,
  GetGraphDataPointsRequest,
  ISetAvailableNodeIds,
  PushRangeHistory
} from 'store/graphs/actions';

import isFullGroup from '../../utils/isFullGroup';

import MetricItem from '../MetricItem';

import getSubNodeTitle from '../../utils/getSubNodeTitle';
import getSpeciesTitle from '../../utils/getSpeciesTitle';
import getPlantingCycleTitle from '../../utils/getPlantingCycleTitle';
import getGraphThresholdSelectors from '../../../../helpers/getGraphThresholdSelectors';
import getGraphPointsPayload from '../../utils/getGraphPointsPayload';

import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as ChevronIcon } from './assets/chevron.svg';

import { ReactComponent as CompartmentIcon } from './assets/compartment.svg';
import { ReactComponent as EquipIcon } from './assets/equip.svg';
import { ReactComponent as PlantingIcon } from './assets/planting.svg';
import { ReactComponent as DatesIcon } from './assets/dates.svg';
import { ReactComponent as PresentIcon } from './assets/present.svg';

import styles from './index.module.css';

type MetricGroupProp = {
  location: Location;
  isUnique: boolean;
  type: TreeNodeIdTypes;
  input: MetricGroupInput;
  metrics: Array<MetricDef>;
  groupKey: string;
  preset: Preset | null;
  allCompartments: Array<Compartment>;
  allSubNodes: Array<SubNode>;
  allMetrics: Array<Metric>;
  allPlantingCycles: Array<PlantingCycle>;
  allSpecies: Array<SpeciesApi>;
  allVarieties: Array<Variety>;
  allFruitClasses: Array<FruitClass>;
  selectFirstEntity: boolean;
  showIcon: boolean;
  threshold: number;
  availableNodeIds: ISetAvailableNodeIds;
};

export type OnChangeFunc = (key: string, values: MetricGroupInput) => void;

export type OnGroupCloseFunc = (treeType: string, key: string) => void;

export type OnMetricCloseFunc = (
  treeType: string,
  key: string,
  metricId: number,
  locationId: number
) => void;

type MetricGroupFunc = {
  onChange: OnChangeFunc;
  onGroupClose: OnGroupCloseFunc;
  onMetricClose: OnMetricCloseFunc;
  pushRangeHistory: PushRangeHistory;
  changeGraphPeriodAndThreshold: ChangeGraphPeriodAndThreshold;
  requestGetGraphDataPoints: GetGraphDataPointsRequest;
  showNotificationWithTimeout: Function;
};

function getCompartments(
  allCompartments: Array<Compartment>,
  location: Location,
  formatMessage: Function,
  availableNodeIds: ISetAvailableNodeIds,
): Array<Compartment> {
  const compartments = [...allCompartments]
    .filter(
      item =>
        location &&
        get(item, 'relationships.location.data[0].id') === location.id &&
          availableNodeIds.compartments.includes(+item.id)
    )
    .sort((a, b) =>
      a.attributes.name.localeCompare(b.attributes.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      }));

  compartments.unshift({
    id: 0,
    type: 'Compartment',
    attributes: {
      name: formatMessage({ id: 'graphs.sidePanel.commonLocationMetrics' }),
    },
  });

  return compartments;
}

function getSpecies(
  allSpecies: Array<SpeciesApi>,
  location: Location,
  formatMessage: Function,
  availableNodeIds: ISetAvailableNodeIds,
): Array<Species> {
  return [...allSpecies]
    .map(sp => ({
      id: sp.id,
      code: sp.code,
      attributes: {
        name: getSpeciesTitle(sp?.code, formatMessage),
      },
    }))
    .filter(sp => availableNodeIds.species.includes(sp.id))
    .sort((a, b) =>
      a.attributes.name.localeCompare(b.attributes.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      }));
/*
  species.unshift({
    id: 0,
    type: 'Species',
    attributes: {
      name: formatMessage({ id: 'dashboards.allSpecies' }),
    },
  });
  return species;
 */
}

function getSubNodes(
  allSubNodes: Array<SubNode>,
  selectedCompartment: Compartment | null,
  formatMessage: Function,
  availableNodeIds: ISetAvailableNodeIds
): Array<SubNode> {
  const subNodes = allSubNodes
    .filter(
      item =>
        selectedCompartment &&
        get(item, 'relationships.compartment.data[0].id') ===
          selectedCompartment.id && availableNodeIds.subNodes.includes(+item.id)
    )
    .map(sn => ({
      ...sn,
      attributes: {
        name: getSubNodeTitle({ formatMessage, subNode: sn }),
      },
    }))
    .sort((a, b) =>
      a.attributes.name.localeCompare(b.attributes.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      }));

  subNodes.unshift({
    id: 0,
    type: 'SubNode',
    attributes: {
      name: formatMessage({ id: 'graphs.sidePanel.commonCompartmentMetrics' }),
    },
  });

  return subNodes;
}

function getPlantingCycles(
  allCompartments: Array<Compartment>,
  allVarieties: Array<Variety>,
  allPlantingCycles: Array<PlantingCycle>,
  allFruitClasses: Array<FruitClass>,
  selectedSpecies: Species | null,
  formatMessage: Function,
  locale: string,
  location: Location,
  availableNodeIds: ISetAvailableNodeIds
) {
  const { attributes: { timezone } } = location;
  const xLocationTime = moment.tz(timezone).utc()
    .hours(23)
    .minutes(59)
    .seconds(0)
    .milliseconds(0);
  const cycles = allPlantingCycles
    .filter((item) => {
      if (!availableNodeIds.plantingCycles.includes(+item.id)) {
        return false;
      }
      if (!selectedSpecies) {
        return true;
      }
      const fruitClass = allFruitClasses.find(
        fc => fc.id === get(item, 'relationships.fruitClass.data[0].id')
      );
      return fruitClass?.attributes?.species === selectedSpecies.code;
    })
    .map((pc:PlantingCycle) => {
      const xEndDate = pc?.attributes?.endDate ? moment(pc?.attributes?.endDate, 'YYYY-MM-DD')
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 0)
        .set('millisecond', 0) : null;
      return {
        ...pc,
        attributes: {
          name: getPlantingCycleTitle(
            pc,
            allCompartments,
            allVarieties,
            allFruitClasses,
            formatMessage,
            locale
          ),
        },
        complete: xEndDate && xEndDate.isBefore(xLocationTime)
      };
    });
    return cycles.sort((lit:any) => (lit?.complete ? 0 : -1));
}

function findChainedSecondNode(
  type: TreeNodeIdTypes,
  selectedFirstNode: Compartment | Species,
  currentSelectedSecondNode: PlantingCycle | SubNode | null,
  allSecondNodes: Array<PlantingCycle | SubNode>,
  allFruitClasses: Array<FruitClass>
): PlantingCycle | SubNode | null {
  if (!selectedFirstNode?.id || !currentSelectedSecondNode) {
    return null;
  }
  if (selectedFirstNode?.id && currentSelectedSecondNode.id === 0) {
    return currentSelectedSecondNode;
  }
  const foundSubNode = allSecondNodes.find((item) => {
    if (type === 'g') {
      return (
        item.relationships?.compartment?.data?.[0]?.id === selectedFirstNode.id
      );
    }
    if (type === 'c') {
      const fruitClass = allFruitClasses.find(
        fc => fc.id === item.relationships?.fruitClass?.data?.[0]?.id
      );
      return fruitClass?.attributes?.species === selectedFirstNode.id;
    }
    return false;
  });
  return foundSubNode || null;
}

export type MetricGroupPassedProps = MetricGroupProp &
  MetricGroupFunc &
  InjectedIntlProps;

export function useHoverLogic(): {
  isGroupHover: boolean;
  handlerCloseGroupMouseOver: MouseEventHandler<HTMLButtonElement>;
  handlerCloseGroupMouseOut: MouseEventHandler<HTMLButtonElement>;
  handlerCloseGroupFocus: FocusEventHandler<HTMLButtonElement>;
  handlerCloseGroupBlur: FocusEventHandler<HTMLButtonElement>;
} {
  const [isGroupHover, setIsGroupHover] = useState(false);

  const handlerCloseGroupMouseOver = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(() => {
    setIsGroupHover(true);
  }, [setIsGroupHover]);
  const handlerCloseGroupMouseOut = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(() => {
    setIsGroupHover(false);
  }, [setIsGroupHover]);
  const handlerCloseGroupFocus = useCallback<
    FocusEventHandler<HTMLButtonElement>
  >(() => {
    setIsGroupHover(false);
  }, [setIsGroupHover]);
  const handlerCloseGroupBlur = useCallback<
    FocusEventHandler<HTMLButtonElement>
  >(() => {
    setIsGroupHover(true);
  }, [setIsGroupHover]);

  return {
    isGroupHover,
    handlerCloseGroupMouseOver,
    handlerCloseGroupMouseOut,
    handlerCloseGroupFocus,
    handlerCloseGroupBlur,
  };
}

export type FirstNodeEntities = Array<Compartment | Species>;

function useInitialization(
  selectFirstEntity: boolean,
  input: MetricGroupInput,
  allNodes: FirstNodeEntities
) {
  const { firstNode, secondNode } = input;

  // Select first Compartment by default if select one groups with compartmentId and subNodeId is null
  const selectedFirstNode =
    selectFirstEntity && allNodes.length > 1 ? allNodes[1] : null;

  const [firstNodeState, setFirstNodeState] = useState<
    Compartment | Species | null
  >(selectedFirstNode || firstNode);

  const [secondNodeState, setSecondNodeState] = useState<
    SubNode | PlantingCycle | null
  >(secondNode);

  return {
    firstNodeState,
    setFirstNodeState,
    secondNodeState,
    setSecondNodeState,
  };
}

function useFirstNodeEntities({
  type,
  allNodes,
  intl,
  location,
  availableNodeIds
}: {
  type: TreeNodeIdTypes;
  allNodes: FirstNodeEntities;
  location: string;
  availableNodeIds: ISetAvailableNodeIds;
} & InjectedIntlProps): Array<Compartment | Species> {
  const { formatMessage } = intl;
  return useMemo(() => {
    if (type === 'g') {
      return getCompartments(allNodes, location, formatMessage, availableNodeIds);
    }
    if (type === 'c') {
      return getSpecies(allNodes, location, formatMessage, availableNodeIds);
    }
    return [];
  }, [type, allNodes, location, formatMessage, availableNodeIds]);
}

function useAvailableSecondNodeEntities({
  type,
  selectedFirstNode,
  allSubNodes,
  allCompartments,
  allVarieties,
  allFruitClasses,
  allPlantingCycles,
  intl,
  location,
  availableNodeIds
}: {
  type: TreeNodeIdTypes;
  selectedFirstNode: Species | Compartment | null;
  allSubNodes: Array<SubNode>;
  allCompartments: Array<Compartment>;
  allVarieties: Array<Variety>;
  allFruitClasses: Array<FruitClass>;
  allPlantingCycles: Array<PlantingCycle>;
  location: Location;
  availableNodeIds: ISetAvailableNodeIds;
} & InjectedIntlProps): Array<SubNode | PlantingCycle> {
  const { formatMessage, locale } = intl;
  return useMemo(() => {
    if (type === 'g') {
      return getSubNodes(allSubNodes, selectedFirstNode, formatMessage, availableNodeIds);
    }
    if (type === 'c') {
      return getPlantingCycles(
        allCompartments,
        allVarieties,
        allPlantingCycles,
        allFruitClasses,
        selectedFirstNode,
        formatMessage,
        locale,
        location,
        availableNodeIds
      );
    }
    return [];
    //
  }, [
    type,
    selectedFirstNode,
    formatMessage,
    locale,
    allSubNodes,
    allCompartments,
    allVarieties,
    allPlantingCycles,
    allFruitClasses,
    availableNodeIds,
    location
  ]);
}

function selectFirstNodeEntities(
  type: TreeNodeIdTypes,
  allCompartments: Array<Compartment>,
  allSpecies: Array<Species>
) {
  if (type === 'g') {
    return allCompartments;
  }
  if (type === 'c') {
    return allSpecies;
  }
  return [];
}

const MetricGroup = ({
  intl,
  groupKey,
  location,
  isUnique,
  metrics,
  type,
  input,
  onChange,
  onGroupClose,
  onMetricClose,
  preset,
  allCompartments,
  allSubNodes,
  allMetrics,
  allSpecies,
  allVarieties,
  allFruitClasses,
  allPlantingCycles,
  selectFirstEntity,
  showIcon,
  pushRangeHistory,
  changeGraphPeriodAndThreshold,
  requestGetGraphDataPoints,
  threshold,
  availableNodeIds,
  showNotificationWithTimeout
}: MetricGroupPassedProps) => {
  const { formatMessage, locale } = intl;

  const tooltipIconId = `metric-group-tooltip-icon-${uniqueId()}`;

  const {
    isGroupHover,
    handlerCloseGroupMouseOver,
    handlerCloseGroupMouseOut,
    handlerCloseGroupFocus,
    handlerCloseGroupBlur,
  } = useHoverLogic();

  const [isCompartmentSelectOpen, setIsCompartmentSelectOpen] = useState(false);
  const [isSubNodeSelectOpen, setIsSubNodeSelectOpen] = useState(false);

  // replace to useFirstNodeEntities
  const firstNodeEntities = useFirstNodeEntities({
    type,
    allNodes: selectFirstNodeEntities(type, allCompartments, allSpecies),
    intl,
    location,
    availableNodeIds
  });

  const {
    firstNodeState,
    setFirstNodeState,
    secondNodeState,
    setSecondNodeState,
  } = useInitialization(selectFirstEntity, input, firstNodeEntities);

  const isFull = useMemo(
    // @ts-ignore
    () =>
      isFullGroup({
        firstNode: firstNodeState,
        secondNode: secondNodeState,
      }),
    [firstNodeState, secondNodeState]
  );

  const firstNodeOptions = useMemo(
    () => [
      ...firstNodeEntities.map(item => ({
        id: item?.id,
        name: item?.attributes?.name,
        removeSelected: false,
      })),
    ],
    [firstNodeEntities]
  );

  const availableSecondNodeEntities = useAvailableSecondNodeEntities({
    type,
    selectedFirstNode: firstNodeState,
    allSubNodes,
    allCompartments,
    allVarieties,
    allFruitClasses,
    allPlantingCycles,
    intl,
    location,
    availableNodeIds
  });

  const secondNodeOptions = useMemo(
    () => [
      ...availableSecondNodeEntities.map(item => ({
        id: item?.id,
        name: item?.attributes?.name,
        removeSelected: false,
        // @ts-ignore
        icon: item?.complete ? (
          <div
            data-tip={formatMessage({ id: 'graphs.completedCycleTitle' })}
            data-for={`tooltip-node-options-complete-${item?.id}`}
          >
            <PresentIcon />
            <ReactTooltip
              className={classnames(tooltipStyles.smallTooltip)}
              id={`tooltip-node-options-complete-${item?.id}`}
              effect='solid'
              html
            />
          </div>) : null
      })),
    ],
    [availableSecondNodeEntities, formatMessage]
  );

  const handlerGroupClose = useCallback(() => {
    onGroupClose(type, groupKey);
  }, [onGroupClose, type, groupKey]);

  const handlerGraphMetricClose = useCallback(
    (metricId: number, locationId: number) => {
      onMetricClose(type, groupKey, metricId, locationId);
    },
    [onMetricClose, type, groupKey]
  );

  const handlerOpenChangeCompartmentSelect = useCallback(
    (isOpen: boolean) => {
      setIsCompartmentSelectOpen(isOpen);
      setIsSubNodeSelectOpen(false);
    },
    [setIsSubNodeSelectOpen, setIsCompartmentSelectOpen]
  );

  const handlerOnChangeCompartmentSelect = useCallback(
    ({ value }: { value: AvailableIdTypes }) => {
      const selectedCompartment = firstNodeEntities.find(
        item => item.id === value
      );
      let chainedSubNode = null;
      if (selectedCompartment && selectedCompartment.id !== 0) {
        chainedSubNode = findChainedSecondNode(
          type,
          selectedCompartment,
          secondNodeState,
          preset.subNodes,
          allFruitClasses
        );
      }
      setFirstNodeState(selectedCompartment || null);
      setSecondNodeState(chainedSubNode);
      setIsCompartmentSelectOpen(false);
      setIsSubNodeSelectOpen(selectedCompartment?.id !== 0);
      onChange(groupKey, {
        firstNode: selectedCompartment,
        secondNode: chainedSubNode,
      });
    },
    [
      type,
      firstNodeEntities,
      setFirstNodeState,
      secondNodeState,
      setSecondNodeState,
      setIsCompartmentSelectOpen,
      setIsSubNodeSelectOpen,
      onChange,
      groupKey,
      preset,
      allFruitClasses,
    ]
  );

  // TODO: Localize average value for all types
  const firstNodeSelectValueRenderer = useCallback(
    (option: { label: string }) => {
      const { label } = option;
      return label === '#'
        ? formatMessage({ id: 'graphs.sidePanel.commonLocationMetrics' })
        : label;
    },
    [formatMessage]
  );

  const handlerOpenChangeSubNodeSelect = useCallback(
    (isOpen: boolean) => {
      setIsCompartmentSelectOpen(false);
      setIsSubNodeSelectOpen(isOpen);
    },
    [setIsSubNodeSelectOpen, setIsCompartmentSelectOpen]
  );

  const handlerOnChangeSubNodeSelect = useCallback(
    ({ value }: { value: AvailableIdTypes }) => {
      const selectedSubNode = availableSecondNodeEntities.find(
        item => item.id === value
      );
      setSecondNodeState(selectedSubNode || null);
      onChange(groupKey, {
        firstNode: firstNodeState,
        secondNode: selectedSubNode,
      });
      setIsCompartmentSelectOpen(false);
      setIsSubNodeSelectOpen(false);
    },
    [
      availableSecondNodeEntities,
      setSecondNodeState,
      setIsCompartmentSelectOpen,
      setIsSubNodeSelectOpen,
      onChange,
      groupKey,
      firstNodeState,
    ]
  );

  const subNodeSelectValueRenderer = useCallback(
    (option: { label: string }) => {
      const { label } = option;
      return label;
    },
    []
  );

  const metricsToDisplay = useMemo(
    () =>
      metrics
        .map((item: MetricDef) => {
          const foundMetric = allMetrics.find(
            (metric: Metric) => metric.id === item.node[NodeComponents.MetricId]
          );
          return foundMetric
            ? {
                metricData: {
                  ...foundMetric,
                  ...foundMetric.attributes,
                },
                presetMetric: item,
              }
            : null;
          // @ts-ignore
        })
        .filter((item: any) => item !== null),
    [metrics, allMetrics]
  );

  const handlerOpenCycle = useCallback(() => {
    if (secondNodeState?.type === 'PlantingCycle') {
      const {
        attributes: {
          startDate,
          endDate
        }
      } = secondNodeState;

      const xToDate = endDate ? moment(endDate, 'YYYY-MM-DD') : moment.tz('UTC');
      const xFromDate = moment(startDate, 'YYYY-MM-DD');

      const xRangeEnd = xToDate
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 0)
        .set('millisecond', 0)
        .format('YYYY-MM-DDTHH:mm:ss');
      const xRangeLengthInMins = xToDate.diff(xFromDate, 'minutes') + 1;

      const range: GraphsPresetRange = {
        xRange: preset?.preset?.xRange || 'last2Days',
        xRangeEnd: preset?.preset?.xRangeEnd || null,
        xRangeLengthInMins: preset?.preset?.xRangeLengthInMins || null,
      };

      pushRangeHistory({
        range,
        threshold,
      });

      const thresholdSelectors = getGraphThresholdSelectors({
        xRange: 'custom',
        xRangeEnd: xRangeEnd || undefined,
        xRangeLengthInMins: xRangeLengthInMins || undefined,
        location,
      });
      const minThreshold = Math.min(
        ...thresholdSelectors.map((item: any) => item.length)
      );

      changeGraphPeriodAndThreshold({
        range: {
          xRange: 'custom',
          xRangeEnd: xRangeEnd || undefined,
          xRangeLengthInMins: xRangeLengthInMins || undefined,
        },
        threshold: minThreshold,
      });

      requestGetGraphDataPoints(
        getGraphPointsPayload(preset, {
          xRange: 'custom',
          xRangeEnd: xRangeEnd || null,
          xRangeLengthInMins: xRangeLengthInMins || null,
        }, location, minThreshold)
      );

      showNotificationWithTimeout({
        id: 'graphs.openCycleDatesOpened',
        messageId: 'graphs.openCycleDatesOpened',
        position: 'leftDown',
        iconType: 'success',
        notificationType: 'withAction'
      });
    }
  }, [
    secondNodeState,
    pushRangeHistory,
    changeGraphPeriodAndThreshold,
    requestGetGraphDataPoints,
    threshold,
    preset,
    location,
    showNotificationWithTimeout
  ]);

  return (
    <div
      className={classnames(styles.group, {
        [styles.groupHoverable]: isGroupHover,
        [styles.error]: !isFull || !isUnique,
      })}
    >
      <div
        className={classnames(styles.groupControls, {
          [styles.groupLeftMargin]: !showIcon,
        })}
      >
        {showIcon && (
          <div
            className={styles.groupIconContainer}
            data-tip=''
            data-for={tooltipIconId}
          >
            {type === 'g' && <CompartmentIcon />}
            {type === 'e' && <EquipIcon />}
            {type === 'c' && <PlantingIcon />}
            <ReactTooltip
              className={classnames(tooltipStyles.smallTooltip)}
              id={tooltipIconId}
              effect='solid'
              html
              getContent={() => formatMessage({ id: `graphs.groupMetricTypes.${type}` })}
            />
          </div>
        )}
        <Select
          className={styles.groupSelect}
          classNameButton={classnames(
            styles.groupSelectButton,
            styles.firstGroupSelectButton
          )}
          options={firstNodeOptions}
          value={firstNodeState?.id}
          open={isCompartmentSelectOpen}
          labelPath='name'
          placeholder={formatMessage({ id: 'graphs.group' })}
          onOpenChange={handlerOpenChangeCompartmentSelect}
          onChange={handlerOnChangeCompartmentSelect}
          valueRenderer={firstNodeSelectValueRenderer}
          classNamePopup={styles.graphSelectPopup}
        />
        {firstNodeState && firstNodeState.id !== 0 && (
          <div className={styles.groupIcon}>
            <ChevronIcon />
          </div>
        )}
        {firstNodeState && firstNodeState.id !== 0 && (
          <Select
            className={styles.groupSelect}
            classNameButton={classnames(
              styles.groupSelectButton,
              styles.secondSelect,
              styles.secondGroupSelectButton
            )}
            classNamePopup={styles.secondPopup}
            classNameOption={styles.graphSelectOptions}
            options={secondNodeOptions}
            value={secondNodeState?.id}
            open={isSubNodeSelectOpen}
            labelPath='name'
            placeholder={formatMessage({ id: 'graphs.group' })}
            onOpenChange={handlerOpenChangeSubNodeSelect}
            onChange={handlerOnChangeSubNodeSelect}
            valueRenderer={subNodeSelectValueRenderer}
            isSearchable
            whiteSpaceOptions='normal'
            textEllipsis
            searchPlaceholder={formatMessage({ id: 'graphs.searchPlaceholder' })}
            empty={formatMessage({ id: 'graphs.sidePanel.noFilterResult' })}
          />
        )}
        {!isFull ? (
          <div className={styles.groupError}>
            <FormattedMessage id='graphs.isFullError' />
          </div>
        ) : null}
        {isFull && !isUnique ? (
          <div className={styles.groupError}>
            <FormattedMessage id='graphs.isUniqueError' />
          </div>
        ) : null}
        {type === 'c' && (
          <button
            type='button'
            className={styles.openCycle}
            onClick={handlerOpenCycle}
          >
            <DatesIcon />
            <FormattedMessage id='graphs.openCycle' />
          </button>
        )}
        <button
          type='button'
          className={styles.groupClose}
          onClick={handlerGroupClose}
          onMouseOver={handlerCloseGroupMouseOver}
          onMouseOut={handlerCloseGroupMouseOut}
          onFocus={handlerCloseGroupFocus}
          onBlur={handlerCloseGroupBlur}
        >
          <CloseIcon className={styles.groupCloseIcon} />
        </button>
      </div>
      <div className={styles.groupDataSeries}>
        {metricsToDisplay.map((metric: any) => (
          <MetricItem
            key={`data-series-${metric.presetMetric.node.join('_')}`}
            title={getNameByLocal(metric.metricData, locale)}
            metricId={metric.metricData?.id}
            locationId={metric.presetMetric.node[NodeComponents.LocationId]}
            onClose={handlerGraphMetricClose}
            blocked={!metric.presetMetric.available}
            units={metric.metricData?.cunit}
          />
        ))}
      </div>
    </div>
  );
};

export default injectIntl(MetricGroup);
