import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import { get, isObject, find } from 'lodash';


import numbersRounding from '../../../../helpers/numbersRounding';
import numbersFormatting from '../../../../helpers/numbersFormatting';

// import VarietySelect from '../../../VarietySelect';

import VarietyDropdown from '../VarietyDropdown';
import ProductMultilevelMenu from '../ProductMultilevelMenu';

import styles from './SheetRenderer.module.css';

export default class SheetRenderer extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    plantingCycles: PropTypes.array.isRequired,
    handlerAddVariety: PropTypes.func.isRequired,
    handlerRemoveVariety: PropTypes.func.isRequired,
    handlerChangeVariety: PropTypes.func.isRequired,
    handlerAddCompartment: PropTypes.func.isRequired,
    isVarietyRemoving: PropTypes.bool.isRequired,
    year: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    tableScroll: PropTypes.object,
    connectScrollTarget: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.array,
    headerRow: PropTypes.array,
    showFakeRow: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
    ]),
    notPublished: PropTypes.bool,
    isTableDisabled: PropTypes.bool,
    isOperationalPlan: PropTypes.bool.isRequired,
    organizationSlug: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: undefined,
    children: null,
    headerRow: [],
    tableScroll: null,
    connectScrollTarget: null,
    showFakeRow: false,
    notPublished: false,
    isTableDisabled: false,
  };

  state = {
    tableScrollPosition: 'top',
  };

  componentDidMount() {
    const { tableScroll, connectScrollTarget } = this.props;
    const table = connectScrollTarget ? connectScrollTarget.current : null;

    if (table && tableScroll) {
      table.scrollLeft = tableScroll.x;
      table.scrollTop = tableScroll.y;
    }
  }

  setTableSize = (newTableScrollPosition) => {
    const { tableScrollPosition } = this.state;

    if (tableScrollPosition !== newTableScrollPosition) {
      this.setState({
        tableScrollPosition: newTableScrollPosition,
      });

      if (newTableScrollPosition === 'bottom') {
        setTimeout(() => this.scrollToBottom(), 100);
      }
    }
  };

  scrollToBottom = () => this.pageEnd.scrollIntoView({ behavior: 'smooth' });

  handlerScroll = (event) => {
    const { target } = event;

    const scrollHeight = target?.scrollHeight;
    const scrollTop = target?.scrollTop;
    const clientHeight = target?.clientHeight;

    const isTop = scrollTop === 0;
    const isBottom = scrollHeight - scrollTop === clientHeight;

    if (isTop) {
      this.setTableSize('top');
    }

    if (isBottom) {
      this.setTableSize('bottom');
    }
  }

  renderFakeRow = (isOperationalPlan, headerRow, isTableDisabled, firstRow) => (
    <tr>
      <td
        colSpan='2'
        className={classnames(
          'cell',
          'read-only',
          styles.fakeCell,
          styles.fakeCellFirstColumn,
        )}
      />
      {headerRow.reduce((acc, compartment) => {
        if (get(compartment, 'varieties') && compartment.varieties.length > 0) {
          const varietyColumns = compartment.varieties.map((variety) => {
            const cellData = firstRow ? find(firstRow, { idx: variety.idx }) : null;

            return (
              <td
                className={classnames(
                  'cell',
                  'read-only',
                  styles.fakeCell,
                  {
                    [styles.disabledCell]: isTableDisabled || (isOperationalPlan && !cellData?.isInSection),
                    [styles[cellData?.species]]: cellData?.isInSection,
                  }
                )}
                key={`fakeCell-${variety.idx}`}
              >
                <div className={styles.tableCellInner} />
              </td>
            );
          });

          return [...acc, ...varietyColumns];
        }

        return [...acc, (
          <td
            className={classnames('cell', 'read-only', styles.fakeCell, styles.fakeCellWithoutVariety)}
            key={`fakeCellCompartment-${compartment.id}`}
          />
        )];
      }, [])}
      <td className={classnames('cell', 'read-only', styles.fakeCell, styles.fakeCellWithoutVariety)} />
    </tr>
  );

  renderTotalBar = (isOperationalPlan, headerRow, totalText, year) => (
    <tr className={styles.totalBar}>
      <td colSpan='2' className={classnames('cell', 'read-only', styles.totalCell, styles.totalCellFirstColumn)}>
        <span className={styles.totalBarText}>
          {`${totalText} ${year}`}
        </span>
      </td>
      {headerRow.reduce((acc, compartment) => {
        if (get(compartment, 'varieties') && compartment.varieties.length > 0) {
          const varietyColumns = compartment.varieties.map(variety => (
            <td
              className={classnames('cell', 'read-only', styles.totalCell)}
              key={`totalCell-${variety.idx}`}
            >
              {numbersFormatting(numbersRounding(get(variety, 'harvestSum'), 'fixed', 1))}
            </td>
          ));

          return [...acc, ...varietyColumns];
        }

        return [...acc, (
          <td
            className={classnames('cell', 'read-only', styles.totalCell, styles.totalCellWithoutValue)}
            key={`totalCellCompartment-${compartment.id}`}
          />
        )];
      }, [])}
      <td className={classnames('cell', 'read-only', styles.totalCell, styles.totalCellLastElement)} />
    </tr>
  );

  render() {
    const {
      intl: { formatMessage },
      className, children, headerRow, fruitClasses, varieties, isVarietyRemoving, year,
      handlerAddVariety, handlerRemoveVariety, handlerChangeVariety, handlerAddCompartment,
      connectScrollTarget,
      showFakeRow, notPublished, isTableDisabled, isOperationalPlan,
      organizationSlug, plantingCycles,
    } = this.props;

    const { tableScrollPosition } = this.state;

    const totalText = formatMessage({ id: 'harvest.total' });

    const isShowFakeRow = isObject(showFakeRow) ? showFakeRow?.hasValue : showFakeRow;

    return (
      <div>
        <div
          className={classnames(
            styles.dataSheetContainer,
            {
              [styles.notPublished]: notPublished,
              [styles.bottomBosition]: tableScrollPosition === 'bottom',
            }
          )}
          ref={connectScrollTarget}
          // onScroll={throttle(this.handlerScroll, 100)}
          // onScroll={this.handlerScroll}
        >
          <table className={classnames(className, styles.dataSheet)}>
            <thead>
              <tr>
                <th
                  className={classnames('cell', 'read-only', styles.cellHeader, styles.mainCellHeader)}
                  colSpan='2'
                  rowSpan='2'
                >
                  {formatMessage({ id: 'harvest.date' })}
                </th>
                {headerRow.map(compartment => (
                  <th
                    className={classnames('cell', 'read-only', styles.cellHeader)}
                    key={`compartment-${compartment.id}`}
                    colSpan={get(compartment, 'varieties') ? compartment.varieties.length : 0}
                  >
                    {get(compartment, 'attributes.name')}
                  </th>
                ))}
                <th
                  className={classnames('cell', 'read-only', styles.cellHeader, styles.addComartmentButton)}
                  rowSpan='2'
                >
                  <div
                    className={styles.addCompartmentButtonContent}
                    onClick={handlerAddCompartment}
                    role='button'
                    tabIndex={0}
                  >
                    {`+ ${formatMessage({ id: 'plans.addCompartment' })}`}
                  </div>
                </th>
              </tr>
              <tr>
                {headerRow.reduce((acc, compartment) => {
                  // тут на самом деле не varieties, а скорее productGroups
                  if (get(compartment, 'varieties') && compartment.varieties.length > 0) {
                    const varietyColumns = compartment.varieties.map(variety => (
                      <th
                        className={classnames('cell', 'read-only', styles.cellHeader, styles.cellHeaderSecondLevel, styles.cellHeaderWithDropdown)}
                        key={`varietyAtCompartment-${variety.idx}`}
                      >
                        <VarietyDropdown
                          varieties={varieties}
                          fruitClasses={fruitClasses}
                          plantingCycles={plantingCycles}
                          varietyId={variety.varietyId}
                          species={variety.species}
                          currentVariety={variety}
                          compartmentId={compartment.id}
                          idx={variety.idx}
                          handlerAddVariety={handlerAddVariety}
                          handlerRemoveVariety={handlerRemoveVariety}
                          handlerChangeVariety={handlerChangeVariety}
                          isVarietyRemoving={isVarietyRemoving}
                          disabled={isTableDisabled}
                          isExistingCycle={isOperationalPlan}
                          organizationSlug={organizationSlug}
                        />
                      </th>
                    ));

                    return [...acc, ...varietyColumns];
                  }

                  return [...acc, (
                    <th
                      className={classnames('cell', 'read-only', styles.cellHeader, styles.cellHeaderSecondLevel)}
                      key={`variety-${compartment.id}`}
                    >
                      {!isOperationalPlan && (
                        <ProductMultilevelMenu
                          disabled={isTableDisabled}
                          onSelectOption={(fruitClassCode, varietyId, targetWeight) => handlerAddVariety(compartment.id, fruitClassCode, varietyId, targetWeight)}
                        />
                      )}
                    </th>
                  )];
                }, [])}
              </tr>
            </thead>
            <tbody>
              {
              /*
                * Весь седующий элемент tr это хак, чтобы не было перекрытия плашкой урожая
                * селекта variety в перовой строке
              */
              }
              {isShowFakeRow && this.renderFakeRow(isOperationalPlan, headerRow, isTableDisabled, showFakeRow?.firstRow)}

              {children}

              {this.renderTotalBar(isOperationalPlan, headerRow, totalText, year)}
            </tbody>
          </table>
        </div>
        <div
          className={styles.emptyBlockPageEnd}
          ref={(el) => { this.pageEnd = el; }}
        />
      </div>
    );
  }
}
