import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './Plate.module.css';

const Plate = ({
  icon,
  header,
  subHeader,
}) => (
  <div className={styles.plateWrapper}>
    {icon && (
      <div className={styles.icon}>
        {icon}
      </div>
    )}
    {header && (
      <div className={styles.header}>
        {header}
      </div>
    )}
    {subHeader && (
      <div className={styles.subHeader}>
        {subHeader}
      </div>
    )}
  </div>
);

Plate.propTypes = {
  icon: PropTypes.oneOfType([
    // PropTypes.object,
    PropTypes.node,
    PropTypes.element,
  ]),
  header: PropTypes.string,
  subHeader: PropTypes.string,
};

Plate.defaultProps = {
  icon: null,
  header: null,
  subHeader: null,
};

export default memo(Plate);
