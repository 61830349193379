import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { contextMenu } from 'react-contexify';

import classnames from 'classnames';
import {
  get, isEmpty, head, uniqueId
} from 'lodash';

import ReactTooltip from 'react-tooltip';

import PlantingAreaPopover from '../PlantingAreaPopover';
import ErrorHint from '../../../Icons/ErrorHint';

import styles from './CellRenderer.module.css';
import tooltipStyles from '../../../Tooltip/index.module.css';

export default class CellRenderer extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    changeCellGroupParam: PropTypes.func.isRequired,
    contextMenuId: PropTypes.string.isRequired,
    className: PropTypes.string,
    plantingAreaUnits: PropTypes.string,
    children: PropTypes.object,
    cell: PropTypes.object,
    isTableDisabled: PropTypes.bool,
    isOperationalPlan: PropTypes.bool,
  };

  static defaultProps = {
    className: undefined,
    plantingAreaUnits: undefined,
    children: null,
    cell: null,
    isTableDisabled: false,
    isOperationalPlan: false,
  };

  handlerContextMenu = (e) => {
    e.preventDefault();

    /* eslint-disable react/prop-types */
    const {
      row, col, cell, contextMenuId
    } = this.props;
    /* eslint-enable react/prop-types */
    const {
      value, startDate, varietyId, compartmentId
    } = cell;

    contextMenu.show({
      id: contextMenuId,
      event: e,
      props: {
        startDate,
        varietyId,
        compartmentId,
        row,
        col,
        value,
      }
    });
  };

  render() {
    // Выключаем валидацию пропсов, чтобы в rest не уходили лишние свойства
    /* eslint-disable react/prop-types */
    const {
      intl, className, children, cell, plantingAreaUnits, fruitClasses, varieties,
      row, col, columns, attributesRenderer, selected, editing, updated, style,
      changeCellGroupParam, isTableDisabled, contextMenuId, isOperationalPlan,
      ...rest
    } = this.props;
    /* eslint-enable react/prop-types */
    const { formatMessage } = intl;

    const attributes = cell.attributes || {};
    const isError = !isEmpty(cell.validationErrors);
    const cellDisabled = isTableDisabled || (isOperationalPlan && !cell.isInCycle);
    const noEditable = (get(cell, 'noSelectable') || cellDisabled);

    // В rest хвосте содержатся обработчики, не вешаем их если у ячейки свойсво, запрещающее выделение
    const handlers = noEditable ? {} : rest;

    const firstErrorCode = get(head(cell.validationErrors), 'errorCode');
    const errorText = firstErrorCode ? formatMessage({ id: `formErrors.${firstErrorCode}` }) : null;
    const errorTooltipId = uniqueId('data-sheet-error-tooltip-');

    const cellWithValue = cell.value !== '' || cell.isInCycle;
    const cellColored = cell.value !== '' || cell.isInSection;

    return (
      <td
        className={classnames(
          className,
          styles.tableCell,
          {
            [styles.withValue]: cellWithValue,
            [styles[cell.species]]: cellColored && cell.species,
            [styles.isFirstForTableColumn]: cell.isFirstForTableColumn,
            [styles.isFirst]: cell.isFirst,
            [styles.isLast]: cell.isLast,
            [styles.isError]: isError,
            [styles.disabledCell]: cellDisabled,
            [styles.smallWidth]: !isOperationalPlan,
          },
        )}
        {...handlers}
        {...attributes}
        onContextMenu={(noEditable || isOperationalPlan) ? null : this.handlerContextMenu}
      >
        <div className={styles.tableCellInner}>
          {cell.isFirst && (
            <PlantingAreaPopover
              intl={intl}
              changeCellGroupParam={changeCellGroupParam}
              fruitClasses={fruitClasses}
              plantingAreaUnits={plantingAreaUnits}
              varieties={varieties}
              varietyId={cell.varietyId}
              cell={cell}
              isTableDisabled={cellDisabled}
            />
          )}
          {(isError && !editing) && (
            <>
              <div
                className={styles.errorHint}
                data-tip={errorText}
                data-for={errorTooltipId}
              >
                <button type='button' className={styles.hintButton}><ErrorHint /></button>
              </div>
              <ReactTooltip
                className={classnames(tooltipStyles.errorHintTooltip, tooltipStyles.clickable)}
                id={errorTooltipId}
                effect='solid'
                event={null}
                place='left'
                offset={{ left: 12 }}
                scrollHide
                html
              />
            </>
          )}
          {children}
        </div>
      </td>
    );
  }
}
