import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import classnames from 'classnames';

import Modal from 'components/Modal';
import BigButton from 'components/BigButton';

import { ReactComponent as CrossIcon } from './assets/cross.svg';

import styles from './HarvestForecastModalDelete.module.css';

const HarvestForecastModalDelete = ({
  intl,
  isOpen,
  onDelete,
  onClose
}) => {
  const { formatMessage } = intl;

  const handlerNoAction = () => {
    onClose();
  };
  const handlerClickAction = () => {
    onDelete();
  };

  return (
    <Modal isOpen={isOpen}>
      <div className={classnames(styles.body)}>
        <div className={styles.window}>
          <div
            className={styles.closeCross}
            role='button'
            tabIndex={0}
            onClick={handlerNoAction}
          >
            <CrossIcon />
          </div>
          <div className={styles.title}>{formatMessage({ id: 'forecast.dashboard.deletePopup.title' })}</div>
          <div className={styles.description}>{formatMessage({ id: 'forecast.dashboard.deletePopup.description' })}</div>
          <div className={styles.actions}>
            <BigButton
              className={styles.noButton}
              title={formatMessage({ id: 'forecast.dashboard.deletePopup.cancelButton' })}
              onClick={handlerNoAction}
              theme='light'
            />
            <BigButton
              className={styles.yesButton}
              title={formatMessage({ id: 'forecast.dashboard.deletePopup.yesButton' })}
              onClick={handlerClickAction}
              theme='dark'
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

HarvestForecastModalDelete.propTypes = {
  intl: intlShape.isRequired,
  isOpen: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

HarvestForecastModalDelete.defaultProps = {
  isOpen: false,
};

export default injectIntl(HarvestForecastModalDelete);
