/**
 * Check point intersect width bounding box
 * @param point
 * @param rect
 * @returns {boolean}
 */
export const pointInRect = (point, rect) =>
  point.x >= rect.x && point.x <= rect.x + rect.width && point.y >= rect.y && point.y <= rect.y + rect.height;


export const addPoints = (point1, point2) => ({
  x: point1.x + point2.x,
  y: point1.y + point2.y
});

export const intersectsWith = (r1, r2) => (r1.x <= r2.x + r2.width &&
    r2.x <= r1.x + r1.width &&
    r1.y <= r2.y + r2.height &&
    r2.y <= r1.y + r1.height);

export const adjustRectHorizontal = (rect, bounds, margin = 0) => {
  const fitRectPos = { x: rect.x, y: rect.y };
  const left = fitRectPos.x;
  if (left < bounds.x) {
    fitRectPos.x = bounds.x + margin;
  }
  const right = fitRectPos.x + rect.width;
  if (right > bounds.x + bounds.width) {
    fitRectPos.x -= (right - (bounds.x + bounds.width) + margin);
  }
  return {
    ...rect,
    ...fitRectPos,
  };
};
