import React, { Fragment, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import classnames from 'classnames';
import { isNumber, uniqueId } from 'lodash';

import isTruncated from 'helpers/isTruncated';

import ArrowRightIcon from 'components/Icons/ArrowRightIcon';
import Checkbox from 'components/Checkbox';

import magicRound from 'helpers/magicRound';

import tooltipStyles from 'components/Tooltip/index.module.css';
import styles from './HarvestForecastOverallTableRow.module.css';

function formatValue(intl, value, units) {
  const { formatMessage } = intl;
  const unitsText = units === 'percent' ? formatMessage({ id: `cunits.mini.${units}` }) : '';
  if (isNumber(value)) {
    return `${magicRound(value)}${unitsText}`;
  }
  if (Array.isArray(value)) {
    const sign = isNumber(value[0]) && value[0] > 0 ? '+' : '';
    return (
      <span className={styles.valueWrapper}>{isNumber(value[0]) ? `${sign}${magicRound(value[0]) }` : '–'} {isNumber(value[1]) && (<span className={styles.percentValue}>({value[1]}%)</span>)}</span>
    );
  }
  return '–';
}

const TableRowItem = ({
  intl,
  row,
  secondLevel,
  currentGraphKey,
  onGraphChange,
  onRowClick,
  isNoChildren
}) => {
  const plantingCycleLinkRef = useRef(null);
  const rowTextRef = useRef(null);

  const tooltipId = `planting-cycle-link-tooltip-${uniqueId()}`;

  const handlerGraphKeyChange = () => {
    onGraphChange(row);
  };

  const handlerRowClick = () => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <tr
      key={`row-${row.graphKey}`}
      className={classnames(styles.tr, {
        [styles.trSecondLevel]: secondLevel,
        [styles.totalRow]: row.isTotal,
      })}
      onClick={secondLevel ? () => {} : handlerRowClick}
    >
      <td
        role='gridcell'
        className={styles.chartIconWrapper}
      >
        <div className={styles.chartIconContainer}>
          <Checkbox
            onChange={handlerGraphKeyChange}
            checked={currentGraphKey && currentGraphKey.includes(row.graphKey)}
            value={`overall-${row.graphKey}`}
            alignCenter
            noWrap
            pointClass={styles.point}
          />
        </div>
      </td>
      <td>
        <div className={styles.nameWrapper}>
          {!secondLevel && !row.isTotal && !isNoChildren && (
            <div
              className={classnames(
                styles.arrowRightIcon,
                {
                  [styles.arrowIconRotate]: row.expand
                }
              )}
            >
              <ArrowRightIcon />
            </div>
          )}
          {row.href ? (
            <>
              <NavLink
                to={row.href}
                className={styles.plantingCycleLink}
                data-tip=''
                data-for={tooltipId}
                ref={plantingCycleLinkRef}
              >
                {row.label}
              </NavLink>

              <ReactTooltip
                className={classnames(tooltipStyles.smallTooltip, styles.tooltip)}
                id={tooltipId}
                effect='solid'
                html
                getContent={() => (isTruncated(plantingCycleLinkRef?.current) ? row.label : undefined)}
              />
            </>
            )
            :
            (
              <>
                <span
                  className={styles.rowText}
                  data-tip=''
                  data-for={tooltipId}
                  ref={rowTextRef}
                >
                  {row.label}
                </span>

                <ReactTooltip
                  className={classnames(tooltipStyles.smallTooltip, styles.tooltip)}
                  id={tooltipId}
                  effect='solid'
                  html
                  getContent={() => (isTruncated(rowTextRef?.current) ? row.label : undefined)}
                />
              </>
            )}
        </div>
      </td>
      {/* eslint-disable-next-line react/no-array-index-key */}
      {row.columns.map((column, index) => <td key={`column-${index}`} className={styles.tdValue}>{formatValue(intl, column, row?.units?.[index])}</td>)}
    </tr>
  );
};

TableRowItem.propTypes = {
  intl: intlShape.isRequired,
  row: PropTypes.object.isRequired,
  secondLevel: PropTypes.bool.isRequired,
  currentGraphKey: PropTypes.string.isRequired,
  onGraphChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  isNoChildren: PropTypes.bool
};

TableRowItem.defaultProps = {
  isNoChildren: false
};

const HarvestForecastOverallTableRow = ({
  intl,
  onGraphChange,
  currentGraphKey,
  onRowClick,
  rows
}) => {
  const { firstRow, childRows, totalRow } = useMemo(() => ({
    firstRow: rows[0],
    childRows: rows.slice(1).filter(row => !row.isTotal),
    totalRow: rows.find(row => row.isTotal)
  }), [rows]);
  if (rows.length === 0) {
    return null;
  }
  return (
    <Fragment key={`rowFragment-${firstRow.graphKey}`}>
      {!firstRow.isTotal && (
        <TableRowItem
          intl={intl}
          row={firstRow}
          secondLevel={false}
          currentGraphKey={currentGraphKey}
          onGraphChange={onGraphChange}
          onRowClick={onRowClick}
          isNoChildren={childRows.length === 0}
        />
      )}
      {firstRow.expand && childRows.length > 0 && childRows.map(row => (
        <TableRowItem
          intl={intl}
          row={row}
          secondLevel
          currentGraphKey={currentGraphKey}
          onGraphChange={onGraphChange}
        />
      ))}
      {totalRow && (
        <TableRowItem
          intl={intl}
          row={totalRow}
          secondLevel={false}
          currentGraphKey={currentGraphKey}
          onGraphChange={onGraphChange}
        />
      )}
    </Fragment>
  );
};

HarvestForecastOverallTableRow.propTypes = {
  intl: intlShape.isRequired,
  rows: PropTypes.array.isRequired,
  currentGraphKey: PropTypes.string.isRequired,
  onGraphChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default injectIntl(HarvestForecastOverallTableRow);
