import moment from 'moment-timezone';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { get, isNil } from 'lodash';

import DashboardSectionHeader from 'components/DashboardSectionHeader';
import numbersRounding from '../../../../helpers/numbersRounding';
import getDatePeriodRange from '../../../../helpers/getDatePeriodRange';
import { API_DATE_FORMAT, getDefaultDateRange } from '../../../../helpers/defaultDates';

import DefaultBreakdown from '../../../DefaultBreakdown';

import styles from './DashboardReportBreakdown.module.css';


const DEFAULT_DATE_RANGE = getDefaultDateRange();

class DashboardReportBreakdown extends Component {
  static propTypes = {
    intl: intlShape.isRequired,

    breakdownItems: PropTypes.array.isRequired,
    selectedRow: PropTypes.object,
    isDataFetching: PropTypes.bool,
    currentUnitKind: PropTypes.string,
    periodType: PropTypes.string,
    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
  };

  static defaultProps = {
    currentUnitKind: 'absolute',
    isDataFetching: false,
    selectedRow: null,
    periodType: 'month',
    anyDateOfPeriodStart: DEFAULT_DATE_RANGE.startDate,
    anyDateOfPeriodEnd: DEFAULT_DATE_RANGE.endDate,
  };

  render() {
    const {
      intl, periodType, breakdownItems,
      anyDateOfPeriodStart, anyDateOfPeriodEnd,
      isDataFetching, currentUnitKind, selectedRow,
    } = this.props;

    const { formatMessage } = intl;

    const startOfPeriod = moment(anyDateOfPeriodStart, API_DATE_FORMAT);
    const endOfPeriod = moment(anyDateOfPeriodEnd, API_DATE_FORMAT).clone().endOf(periodType);

    const roundTo = currentUnitKind === 'absolute' ? 1 : 2;

    const { start, end } = getDatePeriodRange({
      date: null,
      periodType: 'whole',
      minDate: startOfPeriod,
      maxDate: endOfPeriod,
      formatMessage,
    });

    const rangeText = `${start} – ${end}`;

    const valuedItems = breakdownItems.filter(item => !isNil(get(item, 'value')));
    const rawAvg = valuedItems && valuedItems.length > 1 ? valuedItems.map(item => get(item, 'value')).reduce((reducer, item) => reducer + item, 0) / valuedItems.length : 0;
    const averageValue = rawAvg && get(selectedRow, 'type') === 'total' ? numbersRounding(rawAvg, 'fixed', roundTo) : null;

    return (
      <>
        <DashboardSectionHeader className={styles.secondGraphHeader} headerText={formatMessage({ id: 'dashboards.breakdown' })} />

        <DefaultBreakdown
          className={styles.defaultBreakdown}
          emptyTitle={formatMessage({ id: 'harvestDashboard.emptyText' })}
          items={breakdownItems}
          labelPath='label'
          valuePath='value'
          averageValue={averageValue}
          height={405}
          roundTo={roundTo}
          tooltipTitle={rangeText}
          isDataFetching={isDataFetching}
          isEmpty={!isDataFetching && (!breakdownItems || !breakdownItems.length)}
        />
      </>
    );
  }
}

export default injectIntl(DashboardReportBreakdown);
