import React, { useCallback, useMemo } from 'react';
// @ts-ignore
import ReactTooltip from 'react-tooltip';

import classnames from 'classnames';

// @ts-ignore
import { injectIntl, InjectedIntlProps } from 'react-intl';

import {
  Metric, MetricDef, TreeNodePathComponents
} from 'store/graphs/types';

import ColorPicker from 'components/Graphs/components/ColorPicker';

import LINE_COLORS from 'helpers/graphColors';

import getMetricName from '../../../../../../utils/getMetricName';

import getMetricDescription from '../../../../../../utils/getMetricDescription';

import { MetricGroupPath } from '../../../../../../utils/metricsGroupUtils';

import CellDefault from '../CellDefault';

// eslint-disable-next-line import/no-unresolved
import styles from '../../index.module.css';
import tooltipStyles from "../../../../../../../Tooltip/index.module.css";

export type OnClickColorSelectOpenFunc = (
  presetMetric: Array<TreeNodePathComponents>
) => void;

export type OnChangeColorFunc = (
  presetMetric: Array<TreeNodePathComponents>,
  color: string
) => void;

export type OnClickOutsideColorPickerFunc = (
  presetMetric: Array<TreeNodePathComponents>
) => void;

type CellNameWithColorProp = {
  metric: Metric;
  presetMetric: MetricDef;
  headerKey: string;
  groupId: number;
  path: MetricGroupPath | null;
  isShowColorPicker: boolean;
  isSingleGroupCheck: boolean;
}

type CellNameWithColorFunc = {
  onClickColorSelectOpen: OnClickColorSelectOpenFunc;
  onChangeColor: OnChangeColorFunc;
  onClickOutsideColorPicker: OnClickOutsideColorPickerFunc;
}

const CellNameWithColor = ({
  intl,
  metric,
  presetMetric,
  headerKey,
  groupId,
  path,
  isShowColorPicker,
  isSingleGroupCheck,
  onClickColorSelectOpen,
  onChangeColor,
  onClickOutsideColorPicker,
}: CellNameWithColorProp & CellNameWithColorFunc & InjectedIntlProps) => {
  const { formatMessage, locale } = intl;

  const tooltipId = `Tooltip-CellNameWithColor-${headerKey}-${groupId}`;

  const { color } = presetMetric;

  const metricDescription = useMemo(() => getMetricDescription(path),
    [path]);

  const metricName = useMemo(() => {
    const name = getMetricName(metric, locale);
    return `${name}, ${formatMessage({ id: `cunits.mini.${metric.attributes.cunit}` })}`;
}, [metric, locale, formatMessage]);

  const handleClickColorSelect = () => {
    onClickColorSelectOpen(presetMetric);
  };

  const handleChangeColor = useCallback((value: string) => {
    onChangeColor(value);
  }, [onChangeColor]);

  const handleClickOutsideColorPicker = useCallback(() => {
    onClickOutsideColorPicker(presetMetric);
  }, [onClickOutsideColorPicker, presetMetric]);

  return (
    <CellDefault
      presetMetric={presetMetric}
      headerKey={headerKey}
      groupId={groupId}
      isMetricDatasFetching={false}
      className={classnames(styles.flex, styles.main, styles.name)}
    >
      <div
        tabIndex={0}
        role='button'
        className={styles.colorWrapper}
        onClick={handleClickColorSelect}
      >
        <div
          className={styles.color}
          style={{ backgroundColor: color || LINE_COLORS[groupId % LINE_COLORS.length] }}
        />
      </div>
      <div className={classnames(styles.flex, styles.flexColumn, styles.flexAlignLeft)}>
        <div className={styles.title}>
          {metricName}
        </div>
        <div
          className={classnames(styles.description)}
          data-for={tooltipId}
          data-tip={!isSingleGroupCheck && metricDescription}
        >
          {!isSingleGroupCheck && metricDescription}
        </div>
      </div>
      {isShowColorPicker && (
        <ColorPicker
          wrapperClassName={styles.colorPicker}
          onChange={handleChangeColor}
          onClickOutside={handleClickOutsideColorPicker}
          initValue={color}
        />
      )}
      <ReactTooltip
        className={classnames(tooltipStyles.smallTooltip)}
        effect='solid'
        html
        id={tooltipId}
      />
    </CellDefault>
  );
};

export default injectIntl(CellNameWithColor);
