import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import HarvestForm from 'components/HarvestForm';
import CreatePlantingCycle from 'components/CreatePlantingCycle';
import UpdatePlantingCycle from 'components/UpdatePlantingCycle';
import Crops from 'components/NewCrops';
import Cycle from 'components/Cycle';
import DigitalTwin from 'components/DigitalTwin';
import MeasurementsEnter from 'components/MeasurementsEnter';
import HarvestForecastsList from 'components/HarvestForecastsList';
import HarvestForecastCreate from 'components/HarvestForecastCreate';
import HarvestForecastDashboard from 'components/HarvestForecastDashboard';
import BenchmarkCompare from 'components/BenchmarkCompare';

export default class CropsRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route
          key='compare'
          path='/:organizationSlug/crops/compare'
          exact
          component={BenchmarkCompare}
        />
        <Route key='create-crops' path='/:organizationSlug/crops/create' exact component={CreatePlantingCycle} />
        <Route key='digital-twin' path='/:organizationSlug/crops/model' exact component={DigitalTwin} />
        <Route key='forecasts' path='/:organizationSlug/crops/forecasts' exact component={HarvestForecastsList} />
        <Route key='forecasts-create' path='/:organizationSlug/crops/forecasts/create' exact component={HarvestForecastCreate} />
        <Route key='forecasts-create' path='/:organizationSlug/crops/forecasts/:forecastId/edit' exact render={props => <HarvestForecastCreate editMode {...props} />} />
        <Route key='forecasts-dashboard' path='/:organizationSlug/crops/forecasts/:forecastId/report' exact render={props => <HarvestForecastDashboard {...props} />} />
        <Route key='forecasts-dashboard' path='/:organizationSlug/crops/forecasts/:forecastId/data' exact render={props => <HarvestForecastDashboard {...props} />} />
        <Route key='edit-crops' path='/:organizationSlug/crops/:cycleId/edit' exact component={UpdatePlantingCycle} />
        <Route key='edit-harvest' path='/:organizationSlug/crops/:cycleId/edit-harvest' exact component={HarvestForm} />
        <Route key='add-harvest' path='/:organizationSlug/crops/:cycleId/add-harvest' exact component={HarvestForm} />
        <Route key='add-harvest' path='/:organizationSlug/crops/:cycleId/measurements/edit' exact component={MeasurementsEnter} />
        <Route key='view-crops' path='/:organizationSlug/crops/:cycleId/:tabName/:reportId' exact component={Cycle} />
        <Route key='view-crops' path='/:organizationSlug/crops/:cycleId/:tabName?' component={Cycle} />
        <Route key='main-crops' path='/:organizationSlug/*' component={Crops} />
      </Switch>
    );
  }
}
