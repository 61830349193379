import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  getOrganizationSlug,
} from 'store/company/selectors';

import AnalysisRoutes from './AnalysisRoutes';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,

  organizationSlug: getOrganizationSlug(state, props),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnalysisRoutes));
