import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { get, find, isNil } from 'lodash';
import moment from 'moment-timezone';

import LinesGraph from 'components/LinesGraph';
import LinesGraphTooltip from 'components/LinesGraphTooltip';

import numbersFormatting from 'helpers/numbersFormatting';
import { getPercentDiff } from 'helpers/getPercent';
import { findGetAndRoundingValue } from 'helpers/getValueDetails';
import { getRowName } from 'helpers/getRowName';
import LINE_COLORS from 'helpers/graphColors';
import numbersRounding from 'helpers/numbersRounding';
import { emptyStateIntervals } from 'helpers/emptyStateInterval';
import getDateByPeriod from 'helpers/getDateByPeriod';

import styles from './MeterTypeGraph.module.css';

class MeterTypeGraph extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    isDataFetching: PropTypes.bool,
    points: PropTypes.object,
    maxValue: PropTypes.number.isRequired,
    periodType: PropTypes.string,
    roundTo: PropTypes.number,
    allPlantingCycles: PropTypes.array.isRequired,
    productGroups: PropTypes.array.isRequired,
    workTypes: PropTypes.array,
    varieties: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    currentBreakdown: PropTypes.string,
    currentSpecies: PropTypes.string,
    currentVarietyId: PropTypes.number,
    currentFruitClassCode: PropTypes.string,
    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
  };

  static defaultProps = {
    workTypes: null,
    currentBreakdown: 'compartment',
    currentSpecies: null,
    currentVarietyId: null,
    currentFruitClassCode: null,
    isDataFetching: false,
    points: [],
    periodType: null,
    roundTo: 1,
    anyDateOfPeriodStart: null,
    anyDateOfPeriodEnd: null,
  };

  getName = (row) => {
    const {
      intl,
      workTypes,
      compartments,
      fruitClasses,
      varieties,
      productGroups,
      allPlantingCycles,
      currentBreakdown,
      currentSpecies,
      currentFruitClassCode,
      currentVarietyId,
    } = this.props;

    return getRowName({
      type: get(row, 'type'),
      ref: get(row, 'ref'),
      intl,
      workTypes,
      compartments,
      fruitClasses,
      varieties,
      productGroups,
      allPlantingCycles,
      currentBreakdown,
      currentSpecies,
      currentFruitClassCode,
      currentVarietyId,
    });
  };

  generateLineByPoints = (dataPoints) => {
    if (dataPoints) {
      return Object.keys(dataPoints).reduce((acc, key) => {
        const date = moment.utc(new Date((+key) * 60000));

        return [...acc, { x: date.format('MM-DD-YYYY'), y: dataPoints[key] }];
      }, []);
    }

    return [];
  };

  generateLines = (points) => {
    const {
      intl: { formatMessage },
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,
    } = this.props;

    const totalData = points?.data ? points : emptyStateIntervals({
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,
      dataUnits: get(points, 'dataUnits')
    });

    const actualPoints = get(totalData, 'data');
    const units = get(totalData, 'dataUnits');
    const name = this.getName(totalData);

    return [
      {
        key: 'line',
        points: this.generateLineByPoints(actualPoints),
        name,
        color: LINE_COLORS[0],
        units: formatMessage({ id: `cunits.mini.${units}` }),
      },
    ];
  };

  renderValueWithPercent = (actual, previous, units) => {
    const { intl: { formatMessage } } = this.props;

    if (isNil(previous)) {
      return formatMessage({ id: 'crops.noData' });
    }

    return (
      <span>
        <span>{`${numbersFormatting(previous)} ${units}`}</span>
        <span className={styles.tooltipPercent}>{` (${getPercentDiff(previous, actual)})`}</span>
      </span>
    );
  };

  renderTooltipContent = (tooltipDate) => {
    const {
      intl: { formatMessage }, points, periodType, roundTo
    } = this.props;

    const lines = this.generateLines(points);

    const getPoints = (linesArray, lineKey) => get(find(linesArray, { key: lineKey }), 'points');
    const getName = (linesArray, lineKey) => get(find(linesArray, { key: lineKey }), 'name');
    const getColor = (linesArray, lineKey) => get(find(linesArray, { key: lineKey }), 'color');
    const getLineUnits = (linesArray, lineKey) => get(find(linesArray, { key: lineKey }), 'units');

    const actualLine = getPoints(lines, 'line');

    const currentDate = tooltipDate ? tooltipDate.format('MM-DD-YYYY') : undefined;

    if (!currentDate) {
      return null;
    }

    const actualLineValue = findGetAndRoundingValue(actualLine, currentDate, roundTo);

    const actualLineWithPercent = isNil(actualLineValue) ? formatMessage({ id: 'crops.noData' }) : `${numbersFormatting(numbersRounding(actualLineValue, 'fixed', 0))} ${getLineUnits(lines, 'line')}`;

    const generatedLines = [
      {
        id: 'line',
        value: actualLineWithPercent,
        header: getName(lines, 'line'),
        color: getColor(lines, 'line'),
      },
    ];

    return (
      <LinesGraphTooltip
        lines={generatedLines}
        tooltipDate={tooltipDate}
        periodType={periodType}
        customRenderDate={date => getDateByPeriod({
          date,
          periodType,
          formatMessage,
          mode: 'tooltip',
        })}
      />
    );
  };

  render() {
    const {
      isDataFetching, points, maxValue,
    } = this.props;

    const isEmpty = !points?.data || Object.keys(points?.data).length === 0;
    const lines = this.generateLines(points);

    return (
      <LinesGraph
        isOverlapedEmptyState={false}
        isDataFetching={isDataFetching}
        lines={lines}
        maxValue={maxValue}
        renderTooltipContent={this.renderTooltipContent}
        defaultSize={{ height: 324 }}
        xAxisPadding={21}
        margin={{
          top: 20,
          right: 20,
          bottom: 38,
          left: 50,
        }}
        isFlexModeEnabled
        isHoverLineSizeNeedCalc
        isLegendDisabled
        isEmpty={isEmpty}
      />
    );
  }
}

export default injectIntl(MeterTypeGraph);
