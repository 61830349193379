import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { includes } from 'lodash';

import { BLOCKED_DASHBOARDS_FOR_ESSENTIAL } from 'helpers/constants';

import DashboardsMenu from 'components/DashboardsMenu';
import EnergyDashboard from 'components/EnergyDashboard';
import LaborDashboard from 'components/LaborDashboard';
import HarvestKpiDashboard from 'components/HarvestKpiDashboard';
import HarvestDashboard from 'components/HarvestDashboard';
import CropsPerformanceDashboard from 'components/CropsPerformanceDashboard';
import PlanFactReportDashboard from 'components/PlanFactReportDashboard';
import BenchmarkDashboard from 'components/BenchmarkDashboard';
import EssentialPricePlanDashboard from 'components/EssentialPricePlanDashboard';

import PageViewTracker from 'components/PageViewTracker';

class Dashboard extends Component {
  static propTypes = {
    trackPageViewDashboards: PropTypes.func.isRequired,
    tabName: PropTypes.string,
    defaultOpen: PropTypes.bool,
    isEssentialPricePlan: PropTypes.bool,
  };

  static defaultProps = {
    tabName: null,
    defaultOpen: false,
    isEssentialPricePlan: false,
  };

  handlerPageView = () => {
    const {
      trackPageViewDashboards,
      defaultOpen,
      tabName,
    } = this.props;

    let dashboardName = 'Harvest Targets';

    if (tabName === 'energy') {
      dashboardName = 'Energy';
    }

    if (tabName === 'labor-resources') {
      dashboardName = 'Labor works';
    }

    if (tabName === 'targets') {
      dashboardName = 'Harvest Targets';
    }

    if (tabName === 'harvest') {
      dashboardName = 'Crop Harvest';
    }

    if (tabName === 'crop-performance') {
      dashboardName = 'Crop Performance';
    }

    if (tabName === 'benchmarking') {
      dashboardName = 'Benchmarking';
    }

    if (tabName === 'climate-insights') {
      dashboardName = 'Climate Insights';
    }

    if (tabName === 'plan-fact') {
      dashboardName = 'Plan fact analysis';
    }

    trackPageViewDashboards({ dashboardName, defaultOpen });
  };

  renderTab() {
    const { tabName, isEssentialPricePlan } = this.props;

    const isTabInBlockedList = includes(BLOCKED_DASHBOARDS_FOR_ESSENTIAL, tabName);

    if (isEssentialPricePlan && isTabInBlockedList) {
      return (
        <EssentialPricePlanDashboard tabName={tabName} />
      );
    }

    switch (tabName) {
      case 'labor-resources': {
        return (
          <LaborDashboard />
        );
      }
      case 'energy': {
        return (
          <EnergyDashboard />
        );
      }
      case 'targets': {
        return (
          <HarvestKpiDashboard />
        );
      }
      case 'harvest': {
        return (
          <HarvestDashboard />
        );
      }
      case 'crop-performance': {
        return (
          <CropsPerformanceDashboard />
        );
      }
      case 'benchmarking': {
        return (
          <BenchmarkDashboard />
        );
      }
      case 'plan-fact': {
        return (
          <PlanFactReportDashboard />
        );
      }
      default: {
        return (
          <HarvestKpiDashboard />
        );
      }
    }
  }

  render() {
    const { tabName } = this.props;

    const renderedTab = this.renderTab();

    return (
      <>
        <PageViewTracker key={`tabName-${tabName}`} onMount={this.handlerPageView} />
        <DashboardsMenu>
          {renderedTab}
        </DashboardsMenu>
      </>
    );
  }
}

export default injectIntl(Dashboard);
