import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ProgressBar from '../ProgressBar';

import styles from './ProgressBarWithValue.module.css';

const ProgressBarWithValue = ({
  percent,
  ...props
}) => (
  <div className={styles.progressBarWithValue}>
    <ProgressBar percent={percent} {...props} />
    <div className={styles.valueText}>
      {`${percent}%`}
    </div>
  </div>
);

ProgressBarWithValue.propTypes = {
  percent: PropTypes.number,
};

ProgressBarWithValue.defaultProps = {
  percent: 0,
};

export default memo(ProgressBarWithValue);
