import { get } from 'lodash';
import moment from 'moment-timezone';

const getIncidentCoord = (incident, getXScaleValue, timezone) => {
  const incidentStartDate = get(incident, 'timeRange.start');
  const incidentEndDate = get(incident, 'timeRange.end');

  const momentIncidentStartDate = moment.utc(incidentStartDate, 'YYYY-MM-DDTHH:mm:ss', timezone);
  const momentIncidentEndDate = moment.utc(incidentEndDate, 'YYYY-MM-DDTHH:mm:ss', timezone);

  const incidentStartCoordinate = getXScaleValue(momentIncidentStartDate.toDate());
  const incidentEndCoordinate = getXScaleValue(momentIncidentEndDate.toDate());

  return {
    startCoord: incidentStartCoordinate,
    endCoord: incidentEndCoordinate,
  };
};

export default getIncidentCoord;
