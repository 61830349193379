import { Observable } from 'rxjs';
import { filter, get, uniqBy } from 'lodash';
import queryString from 'query-string';
import initAxiosInstanse from '../../api/axios';
import { receiveCropsData } from '../newCrops/actions';


import {
  getCropsPerformanceStarted,
  getCropsPerformanceFailed,
  getCropsPerformanceSucceeded,
  getCropsPerformanceEnded,
} from './actions';


export const startedGetCropsPerformanceEpic = (action$, store) =>
  action$.ofType(getCropsPerformanceStarted)
    .switchMap((actionData) => {
      const { company } = store.getState();
      const { payload: { year, locationPlan } } = actionData;

      const {
        location,
        allCompartments,
        allPlantingCycles,
        varieties,
      } = company;

      const { id: locationId } = location;

      const queryParams = queryString.stringify({
        year,
        locationPlan,
      });

      const query = queryParams ? `&${queryParams}` : '';

      return Observable.from(initAxiosInstanse().get(`/locations/${locationId}/kpi/completed-crop-perfomance?${query}`))
        .map(({ data }) => {
          const locationCompartments = allCompartments.filter(item => get(item, 'relationships.location.data[0].id') === get(location, 'id'));
          const locationPlantingCycles = filter(allPlantingCycles, plantingCycle => locationCompartments.find(compartment => compartment.id === get(plantingCycle, 'relationships.compartment.data[0].id')));
          const varietiesIds = locationPlantingCycles.map(plantingCycle => get(plantingCycle, 'relationships.variety.data[0].id'));
          const locationVarieties = filter(varieties, variety => varietiesIds.find(id => id === variety.id));
          const uniqLocationVarieties = uniqBy(locationVarieties, 'attributes.species');

          const locationSpecies = uniqLocationVarieties.map(variety => get(variety, 'attributes.species')).sort((a, b) => a.localeCompare(b));

          const sorting = locationSpecies.reduce((reducer, item) => {
            const temp = reducer;

            temp[item] = {
              type: 'avgHarvestPerWeek',
              category: item,
              direction: 'down',
            };

            return temp;
          }, {});

          return getCropsPerformanceSucceeded({ data, sorting });
        })
        .catch(error => getCropsPerformanceFailed({ error }));
    });

export const failedGetCropsPerformanceEpic = action$ =>
  action$
    .ofType(getCropsPerformanceFailed)
    .mapTo(getCropsPerformanceEnded());

export const succeededGetCropsPerformanceEpic = action$ =>
  action$
    .ofType(getCropsPerformanceSucceeded)
    .mapTo(getCropsPerformanceEnded());

export const receiveCropsDataEpic = action$ =>
  action$
    .ofType(receiveCropsData)
    .switchMap((actionData) => {
      const { payload: { isCropsPerformanceCallback, year, locationPlan } } = actionData;

      if (isCropsPerformanceCallback) {
        return Observable.of(getCropsPerformanceStarted({ year, locationPlan }));
      }
      return Observable.empty();
    });
