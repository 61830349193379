export default function getGraphMetricGroups(metrics) {
  return metrics && metrics.length ? metrics
    .sort((a, b) => a.attributes.position - b.attributes.position)
    .map(({ attributes: { location: locationItem, compartment, subNode } }) => ({
      type: 'GraphPresetMetricGroup',
      location: locationItem,
      compartment: compartment || {
        id: '#',
        type: 'Compartment',
        attributes: { name: '#' },
        relationships: { location: { data: [] } }
      },
      subNode: subNode || (compartment ? {
        id: '#',
        type: 'SubNode',
        attributes: { name: '#', type: '' },
        relationships: { location: { data: [] }, compartment: { data: [] } }
      } : null),
    }))
    .reduce((reducer, checkedGroup) => {
      const find = reducer.find((item) => {
        const isLocationEqual = item.location.id === checkedGroup.location.id;
        const isCompartmentEqual = item.compartment && checkedGroup.compartment ? item.compartment.id === checkedGroup.compartment.id : !item.compartment && !checkedGroup.compartment;
        const isSubNodeEqual = item.subNode && checkedGroup.subNode ? (item.subNode.id === checkedGroup.subNode.id) : !item.subNode && !checkedGroup.subNode;

        return isLocationEqual && isCompartmentEqual && isSubNodeEqual;
      });

      if (!find) {
        reducer.push(checkedGroup);
      }

      return reducer;
    }, [])
    .map((item, i) => ({
      ...item,
      id: i,
    })) : [];
  // .sort(sortGraphMetricsGroups);
}
