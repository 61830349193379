import React from 'react';

import Handsontable from 'handsontable';
import ReactDOMServer from 'react-dom/server';

import numbersRounding from 'helpers/numbersRounding';

import DefaultPlate from 'components/DefaultPlate';

import { valueValidator } from './validator';

import styles from './BulkEditTable.module.css';

const INPUT_FILTERED_EVENTS = ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'];

const renderErrorPlate = text => (
  <DefaultPlate className={styles.errorPlate} type='error'>{text}</DefaultPlate>
);

function setInputFilter(input, inputFilter) {
  INPUT_FILTERED_EVENTS.forEach((event) => {
    input.addEventListener(event, function handleInput() {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
        // eslint-disable-next-line no-prototype-builtins
      } else if (this.hasOwnProperty('oldValue')) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = '';
      }
    });
  });
}

function removeInputFilter(input, inputFilter) {
  INPUT_FILTERED_EVENTS.forEach((event) => {
    input.removeEventListener(event, inputFilter);
  });
}

class SeriesValueEditor extends Handsontable.editors.TextEditor {
  prepare(row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);
    this.valueMetrics = cellProperties?.valueMetrics;
    this.formatMessage = cellProperties?.formatMessage;
  }

  setValue(value) {
    const stringValue = (value || '0').toString();
    const normalizedDecimal = parseFloat(stringValue.replace(',', '.'));
    this.TEXTAREA.value = numbersRounding(normalizedDecimal, 'fixed', this?.valueMetrics?.round || 0);
  }

  handleValidate(value) {
    const {
      isCellValid,
      error
    } = valueValidator({
      formatMessage: this?.formatMessage,
      value,
      callback: (result, message) => ({
        isCellValid: result, error: message
      }),
      maxValue: this?.valueMetrics?.max,
      minValue: this?.valueMetrics?.min,
    });

    if (this?.ERROR_LABEL) {
      this.ERROR_LABEL.className = isCellValid ? '' : 'valueError';
    }

    if (this.PLATE_CONTAINER) {
      this.PLATE_CONTAINER.innerHTML = isCellValid ? '' :
        ReactDOMServer.renderToStaticMarkup(renderErrorPlate(error));
    }

    if (value.startsWith(',') || value.startsWith('.')) {
      return false;
    }

    return /^\d*[.,]?\d*$/.test(value);
  }

  // eslint-disable-next-line no-unused-vars
  close(tdOutside) {
    removeInputFilter(this.TEXTAREA, this.handleValidate);
  }

  createElements() {
    super.createElements();
    this.TEXTAREA = document.createElement('input');
    this.TEXTAREA.className = 'handsontableInput';
    this.textareaStyle = this.TEXTAREA.style;
    Handsontable.dom.empty(this.TEXTAREA_PARENT);
    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
    this.PLATE_CONTAINER = document.createElement('div');
    this.ERROR_LABEL = document.createElement('label');
    this.ERROR_LABEL.className = '';
    this.TEXTAREA_PARENT.appendChild(this.ERROR_LABEL);
    this.TEXTAREA_PARENT.appendChild(this.PLATE_CONTAINER);
    if (this.TEXTAREA) {
      setInputFilter(this.TEXTAREA, value => this.handleValidate(value));
    }
    this.PLATE_CONTAINER.style.position = 'absolute';
  }
}


export default SeriesValueEditor;
