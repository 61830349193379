import React from 'react';
import classnames from 'classnames';
// @ts-ignore
import moment from 'moment-timezone';

// @ts-ignore
import { injectIntl, InjectedIntlProps } from 'react-intl';

import getDateFormat from 'helpers/getDateFormat';

import WarningIcon from 'components/Icons/WarningIcon';


import { Incident } from 'store/graphs/types';


import { AxisScaleType } from '../../ChartLines/components/ChartLine';

import styles from './index.module.css';


export type AbnormalityIncidentsButtonProps = {
  incident: Incident;
  xScale: AxisScaleType;
  graphWidth: number;
  isActive: boolean;
  tooltipId: string;
};

export type OnOpenIncidentFunc = (incident: Incident) => void;

export type AbnormalityIncidentsButtonFunc = {
  onOpenIncident: OnOpenIncidentFunc
};

export const BUTTON_WIDTH = 20;
export const BUTTON_HEIGHT = 20;

const AbnormalityIncidentButton = ({
  intl,
  incident,
  xScale,
  graphWidth,
  isActive,
  tooltipId,
  onOpenIncident,
}: AbnormalityIncidentsButtonProps & AbnormalityIncidentsButtonFunc & InjectedIntlProps) => {
  const { formatMessage } = intl;

  const {
    id,
    severity,
    timeRange: {
      start: startString,
      end: endString
    }
  } = incident;

  const start = moment.tz(startString, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
  const end = moment.tz(endString, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
  const startCoord = xScale(start);
  const endCoord = xScale(end);

  if (startCoord > graphWidth) {
    return null;
  }

  // Вычисляем координату, в которой мы должны показать значок инцидента
  const halfOfIncident = (endCoord - startCoord) / 2;
  const incidentCenterCoordinate = (halfOfIncident) + startCoord;

  // Если координата значка инцидента выходит за пределы графика, то отображаем его в самом конце
  const buttonXCoordinate = incidentCenterCoordinate > graphWidth ? graphWidth : incidentCenterCoordinate;

  const isSameDay = start.format('YYYY-MM-DD') === end.format('YYYY-MM-DD');

  const tip = `${formatMessage({ id: `graphIncidents.${severity}Incident` })}<br />${isSameDay ? `${start.format(getDateFormat('lll hh'))} - ${end.format('HH:mm')}` : `${start.format(getDateFormat('lll hh'))} — ${end.format(getDateFormat('lll hh'))}`}`;

  return (
    <button
      type='button'
      key={`abnormalityIncident-${id}`}
      data-tip={tip}
      data-for={tooltipId}
      className={classnames(
        styles.abnormalityIncidentButton,
        {
          [styles.abnormalityIncidentButtonActive]: isActive,
          [styles.high]: severity === 'high',
          [styles.middle]: severity === 'middle',
          [styles.low]: severity === 'low',
          [styles.potential]: severity === 'potential',
        },
      )}
      style={{
        height: BUTTON_HEIGHT,
        width: BUTTON_WIDTH,
        left: Math.round(buttonXCoordinate - (BUTTON_WIDTH / 2)),
        bottom: 0,
      }}
      onClick={() => onOpenIncident(incident)}
    >
      <WarningIcon />
    </button>
  );
};


export default injectIntl(AbnormalityIncidentButton);
