import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Checkbox.module.css';
import { ReactComponent as Arrow } from './assets/arrow.svg';
import { ReactComponent as Square } from './assets/square.svg';

export default class Checkbox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    value: PropTypes.any.isRequired,
    name: PropTypes.string,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    alignCenter: PropTypes.bool,
    noWrap: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    markerIcon: PropTypes.elementType,
    noBackgroundColor: PropTypes.bool,
    disabled: PropTypes.bool,
    pointClass: PropTypes.string
  };

  static defaultProps = {
    onChange: null,
    onClick: null,
    className: null,
    title: null,
    name: null,
    checked: false,
    alignCenter: false,
    noWrap: false,
    markerIcon: Arrow,
    noBackgroundColor: false,
    disabled: false,
    pointClass: null
  };

  static MARKERS = {
    Square,
    Arrow
  };

  render() {
    const {
      value,
      className,
      title,
      name,
      checked,
      alignCenter,
      noWrap,
      markerIcon,
      noBackgroundColor,
      disabled,
      pointClass
    } = this.props;

    const propsInput = {
      onChange: this.props.onChange,
    };

    const propsLabel = {
      onClick: this.props.onClick,
    };

    const Marker = markerIcon;

    return (
      <label
        htmlFor={`radio-button-${name}-${value}`}
        className={classnames(
          'checkbox',
          styles.checkbox,
          className,
          { [styles.alignCenter]: alignCenter }
        )}
        {...propsLabel}
      >
        <input disabled={disabled} checked={checked} id={`radio-button-${name}-${value}`} name={name} value={value} type='checkbox' {...propsInput} />
        <div
          className={classnames(
            styles.point,
            { [styles.noBackgroundColor]: noBackgroundColor },
            { [styles.disabled]: disabled },
            pointClass
          )}
        >
          <Marker className={styles.arrow} />
        </div>
        {title && (
          <span
            className={classnames(
              styles.arrow,
              { [styles.noWrap]: noWrap },
            )}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
      </label>
    );
  }
}
