import browser from 'browser-detect';
import { get } from 'lodash';

const DEFAULT_MAX = 268435456;
const MAX_BROWSERS_VALUES = {
  ios: 16777216,
};

const getCurrentBrowserMaxValue = (browserData) => {
  if (browserData.name === 'safari' && browserData.mobile === true) {
    return MAX_BROWSERS_VALUES.ios;
  }

  return get(MAX_BROWSERS_VALUES, browserData?.name, DEFAULT_MAX);
};

/**
 * Check is canvas area exceeds the maximum limit for current browser
 * correct table of max canvas sizes https://www.npmjs.com/package/canvas-size#desktop
 *
 * @param {Object} { width, height } - width and height of canvas
 * @return {boolean} is canvas size valid for this browser
 */
const getIsCanvasValid = ({ width, height }) => {
  if (!width || !height) {
    return true;
  }

  /**
   * Possible values: firefox opera edge ie safari chrome android ios yandexbrowser crios bb10
   * now we handle only iOS because now it is enouught
   */
  const currentBrowser = browser();

  const maxValue = getCurrentBrowserMaxValue(currentBrowser);
  const canvasSize = width * height;

  if (canvasSize > maxValue) {
    return false;
  }

  return true;
};

export default getIsCanvasValid;
