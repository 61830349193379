import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import styles from './General.module.css';


class General extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  render() {
    return (
      <article className={styles.main}>
        <h1>Общие условия и положения</h1>

        <p>До начала использования Приложения компании Pylot вам необходимо ознакомиться с настоящей <RouterLink to={`/${this.props.match.params.organizationSlug}/terms/privacy-and-cookie-policy`}>политикой конфиденциальности</RouterLink>, а также изучить и принять наши общие условия и положения.</p>

        <br />

        <p>Нижеприведенные условия и положения распространяются на SaaS-сервисы (облачные приложения), предлагаемые компанией Pylot клиенту, с которым компания Pylot заключает Договор <strong>(Клиент)</strong>.</p>

        <br />

        <p>Услуги предоставляются компанией Pylot B.V., юридический адрес: Vlotlaan 710, 2681 TX Monster, зарегистрированной в Торговой палате за номером 68947232 <strong>(Pylot)</strong>.</p>

        <h2><strong>Пункт 1.</strong>&nbsp;Определения</h2>
        <p>Термины, употребленные в настоящих Общих условиях и положениях с заглавной буквы, имеют (как в форме единственного, так и в форме множественного числа) нижеприведенные значения.</p>
        <br />
        <p><strong>1.1</strong>&nbsp;<strong>Учетная запись</strong> - учетная запись, предоставляемая Клиенту компанией Pylot в целях обеспечения использования Программного обеспечения (ПО).</p>
        <p><strong>1.2</strong>&nbsp;<strong>Договор</strong> - любой Договор между компанией Pylot и Клиентом, согласно которому компания Pylot оказывает Услуги Клиенту и неотъемлемой частью которого являются настоящие Общие условия и положения.</p>
        <p><strong>1.3</strong>&nbsp;<strong>Приложение</strong> - приложение компании Pylot с веб-сайтом&nbsp;<a href='https://pylot.app'>https://pylot.app</a>.</p>
        <p><strong>1.4</strong>&nbsp;<strong>Клиент</strong> - физическое или юридическое лицо, выступающее в своем профессиональном качестве, с которым компания Pylot заключила Договор.</p>
        <p><strong>1.5</strong>&nbsp;<strong>Конечные пользователи</strong> - сотрудники Клиента, которым через Клиента предоставляется доступ к ПО, предоставляемому Клиенту компанией Pylot через учетные записи пользователей.</p>
        <p><strong>1.6</strong>&nbsp;<strong>Общие условия и положения</strong> - настоящие условия и положения.</p>
        <p><strong>1.7</strong>&nbsp;<strong>Письменный</strong> - датированный и подписанный документ в бумажном формате. Помимо вышеуказанного к письменным документам также относятся электронные и факсимильные сообщения в тех случаях, в которых источник и достоверность таких сообщений могут быть установлены к разумному удовлетворению стороны.</p>
        <p><strong>1.8</strong>&nbsp;<strong>Права на объекты интеллектуальной собственности</strong> - все права на объекты интеллектуальной собственности и смежные права, включая, помимо прочего, авторские права, права на базы данных, имена доменов, права на фирменные наименования, товарные знаки, промышленные образцы, сопутствующие права, патентные права, а также права на ноу-хау.</p>
        <p><strong>1.9</strong>&nbsp;<strong>Материалы</strong> - все результаты работ, например, веб-сайты и приложения/веб-приложения, фирменный стиль, логотипы, рекламные проспекты, брошюры, буклеты, надписи, рекламные объявления, маркетинговые планы и/или планы коммуникации, чертежи, изображения, тексты, эскизы, документы, материалы консультационного характера, отчеты и прочие результаты умственной деятельности, все материалы, связанные с подготовкой вышеперечисленного, а также файлы или носители информации (будь то дешифрованные или недешифрованные), содержащие материалы.</p>
        <p><strong>1.10</strong>&nbsp;<strong>Часы работы</strong> - с 9:00 до 17:00 (по нидерландскому времени) с понедельника по пятницу кроме официальных государственных праздников в Нидерландах.</p>
        <p><strong>1.11</strong>&nbsp;<strong>Сторона/Стороны</strong> - стороны Договора, компания Pylot и Клиент, по отдельности или совместно.</p>
        <p><strong>1.12</strong>&nbsp;<strong>Услуги</strong> - все услуги, оказываемые компанией Pylot Клиенту по Договору, включая помимо прочего, (i) предоставление и установку ПО, (ii) предоставление облачных услуг в форме «ПО как услуга» через Приложение, (iii) настройка учетных записей пользователей и (iv) обслуживание ПО (как услуги).</p>
        <p><strong>1.13</strong>&nbsp;<strong>ПО</strong> - все ПО (как услуга), предоставляемое на платформе (Приложение компании Pylot, домен и приложение компании Pylot https, агенты, установленные в системе greenhouse для интеграции с локальными системами, API) компанией Pylot в соответствии с Договором.</p>
        <p><strong>1.14</strong>&nbsp;<strong>Период тестирования</strong> - установленный исходя из требований заказчика период времени, в рамках которого Приложение компании Pylot тестируется Клиентом.</p>

        <h2><strong>Пункт 2.</strong>&nbsp;Заключение и исполнение Договора</h2>
        <p><strong>2.1</strong>&nbsp;Никакие оферты компании Pylot никоим образом не связывают компанию Pylot какими-либо обязательствами и действуют только в течение тридцати дней с даты ценового предложения или оферты. Компания Pylot не обязана принимать согласие с условиями ценового предложения или оферты по истечении указанного выше срока, но в случае принятия компанией такого согласия оферта или ценовое предложение признаются принятыми.</p>
        <p><strong>2.2</strong>&nbsp;Если согласие Клиента предоставляется в отношении условий, отличных (даже в незначительной степени) от условий оферты, компания Pylot никоим образом не обязана соблюдать такие условия. В таком случае Договор не будет признан заключенным в соответствии с измененными условиями за исключением случаев, когда компания Pylot прямо свидетельствует об обратном в Письменном виде.</p>
        <p><strong>2.3</strong>&nbsp;Договор заключается при согласии с офертой или подтверждении таковой в вышеописанном порядке.</p>
        <p><strong>2.4</strong>&nbsp;Компания Pylot обязуется предоставлять ПО в полную меру своих возможностей, добросовестно и на должном профессиональном уровне.</p>

        <h2><strong>Пункт 3.</strong>&nbsp;Сфера применения, срок действия и расторжение</h2>
        <p><strong>3.1</strong>&nbsp;Общие условия и положения распространяются на все оферты, предоставляемые компанией Pylot, на порядок подтверждения таковых Клиентом и на Договор, заключаемый в этой связи между Сторонами, а также на оказание Услуг компанией Pylot.</p>
        <p><strong>3.2</strong>&nbsp;Применимость любых положений или иных условий и положений Клиента настоящим явным образом исключается.</p>
        <p><strong>3.3</strong>&nbsp;Договор вступает в силу с даты, согласованной Сторонами, и действует в течение Периода тестирования.</p>
        <p><strong>3.4</strong>&nbsp;По истечении Периода тестирования Договор продлевается на один год в тех случаях и в той степени, как это согласовано Сторонами. По истечении этого года Договор продлевается еще на один год в тех случаях и в той степени, как это согласовано Сторонами. Кроме случаев, когда Договор заключается на неопределенный срок, он не может быть расторгнут досрочно, за исключением обстоятельств, упомянутых в настоящих Общих условиях и положениях.</p>
        <p><strong>3.5</strong>&nbsp;Компания Pylot вправе приостановить или направить уведомление о расторжении Договора в Письменном виде с незамедлительным вступлением решения в силу в отсутствие обязательств по направлению уведомления о неисполнении обязательств в случае нарушения Клиентом существенного обязательства по Договору.</p>
        <p><strong>3.6</strong>&nbsp;Компания Pylot вправе приостановить или направить уведомление о расторжении Договора в Письменном виде с незамедлительным вступлением решения в силу в отсутствие обязательств по направлению уведомления о неисполнении обязательств в случае объявления Клиента банкротом, направления заявления Клиентом о приостановлении платежей или предоставления Клиенту такого права, прекращения деятельности Клиента или ликвидации его предприятия.</p>
        <p><strong>3.7</strong>&nbsp;В случае приостановления компанией Pylot действия Договора компания Pylot сохраняет за собой все свои права, в том числе права требования, по Договору и применимым законам и нормам.</p>
        <p><strong>3.8</strong>&nbsp;В случае расторжения Договора суммы, причитающиеся компании Pylot от Клиента, признаются подлежащими незамедлительной выплате (независимо от оснований расторжения).</p>
        <p><strong>3.9</strong>&nbsp;В случае расторжения Договора суммы, уже выставленные Клиенту в счетах, будут подлежать уплате Клиентом, и у компании никоим образом не возникнет обязательств по их аннуляции.</p>
        <p><strong>3.10</strong>&nbsp;Клиент вправе расторгнуть Договор только в части обязательств, еще не исполненных компанией Pylot.</p>

        <h2><strong>Пункт 4.</strong>&nbsp;Оказание Услуг</h2>
        <p><strong>4.1</strong>&nbsp;После заключения Договора компания Pylot обязуется прилагать все усилия для оказания Услуг в разумные сроки в соответствии с Договором.</p>
        <p><strong>4.2</strong>&nbsp;Клиент обязуется оказывать компании Pylot любое содействие, которое необходимо и желательно в целях надлежащего и своевременного оказания Услуг. Клиент обязуется при любых обстоятельствах:</p>

        <ol type='a'>
          <li>предоставлять все данные и прочую информацию, которые являются необходимыми, по мнению компании Pylot, или которые Клиент по разумным соображениям должен счесть обязательными в целях оказания Услуг;</li>
          <li>предоставлять временный доступ сотрудникам службы поддержки компании Pylot в целях настройки взаимодействия (Team Viewer);</li>
          <li>способствовать установке агентов взаимодействия на локальных компьютерах.</li>
        </ol>

        <p><strong>4.3</strong>&nbsp;Клиент несет ответственность за предоставление достоверных данных и информации (при использовании ПО). Результаты и решения, отражаемые в ПО, основываются на данных, предоставляемых Клиентом. Компания Pylot не принимает на себя никакой ответственности в этой связи.</p>
        <p><strong>4.4</strong>&nbsp;Компания Pylot вправе привлекать третьих лиц для целей исполнения Договора. Любые связанные с этим расходы возлагаются на Клиента только в случаях получения его предварительного согласия.</p>
        <p><strong>4.5</strong>&nbsp;В случае выполнения сотрудниками компании Pylot работ по месту нахождения офиса Клиента или по иному адресу, указанному Клиентом, при исполнении Договора, Клиент обязан оказывать любое содействие и предоставлять любые средства, необходимые для выполнения работ, на безвозмездной основе.</p>

        <h2><strong>Пункт 5.</strong>&nbsp;Использование ПО</h2>
        <p><strong>5.1</strong>&nbsp;Для использования ПО Клиенту необходимо иметь Учетную запись. Компания Pylot обязуется приложить все усилия для настройки исходной конфигурации Учетной записи Клиента в течение четырех недель с момента заключения Договора. Клиент вправе потребовать создания дополнительных учетных записей пользователей для Конечных пользователей в рамках Учетной записи организации.</p>
        <p><strong>5.2</strong>&nbsp;Клиент обязуется защитить Учетную запись (Учетные записи) путем использования логина и пароля. Пароль (пароли) должен (должны) храниться в строжайшем секрете. Компания Pylot вправе предполагать, что все действия, осуществляемые под Учетной записью (Учетными записями) Клиента после ввода логина и пароля для авторизации, совершаются исключительно под надзором и контролем Клиента.</p>
        <p><strong>5.3</strong>&nbsp;Компания Pylot обязуется приложить все усилия для подключения Учетной записи Клиента в отсутствие дополнительной платы. Компания Pylot сохраняет за собой право взимать плату за подключение нового устройства или соединение с другой системой, если настройка соединения не может быть выполнена без приобретения продуктов или услуг третьих лиц. В таких случаях компания Pylot обязана заблаговременно уведомить об этом Клиента.</p>
        <p><strong>5.4</strong>&nbsp;В тех случаях и в той степени, в которых Клиент предоставляет компании Pylot доступ к системе Клиента, например через Team Viewer, обязанность по контролю за подключениями и отключению доступа компании Pylot возлагается на Клиента. Более того, Клиент несет ответственность за проверку надлежащего использования оборудования Клиента. Компания Pylot не принимает на себя никакой ответственности в этой связи.</p>

        <h2><strong>Пункт 6.</strong>&nbsp;Правила использования</h2>
        <p><strong>6.1</strong>&nbsp;Запрещается использовать ПО в порядке, нарушающем настоящие условия и положения или применимые законы и нормы. Кроме того, строго запрещается использовать ПО в порядке, который может причинить неудобства, убытки и/или ущерб компании Pylot или любому третьему лицу.</p>
        <p><strong>6.2</strong>&nbsp;Если компания Pylot приходит к заключению, что Клиент или Конечный пользователь нарушили настоящие условия и положения или закон, или получает претензию в этой связи, она вправе принять меры для устранения нарушения. В этом случае компания Pylot заблокирует доступ к соответствующей информации.</p>
        <p><strong>6.3</strong>&nbsp;Если, по мнению компании Pylot, использование компьютерных систем или сети компании Pylot или третьих лиц и/или оказание услуг с использованием сети Интернет затруднены, сопряжены с проблемами или так или иначе поставлены под угрозу, в частности, в результате передачи слишком больших объемов электронных сообщений или прочих данных, утечки персональных данных или вирусной активности, троянских программ и аналогичного ПО, компания Pylot вправе принять любые меры, которые она сочтет разумно необходимыми для предупреждения или предотвращения такой угрозы. Такие меры включают, помимо прочего, приостановление работы ПО и расторжение Договора.</p>
        <p><strong>6.4</strong>&nbsp;Компания Pylot вправе в любое время сообщать о любых выявленных уголовно наказуемых деяниях и оказывать содействие при исполнении надлежащим образом вынесенных приказов и распоряжений. Кроме того, компания Pylot вправе предоставлять наименование/имя, адрес, IP-адрес и прочие данные, идентифицирующие Клиента / Конечного пользователя ПО, третьему лицу, направившему претензию о нарушении Клиентом / Конечным пользователем ПО его прав или настоящих условий и положений при условии, что:</p>
        <ol type='a'>
          <li>существуют достаточно веские основания полагать, что информация сама по себе является незаконной и вредоносной в отношении третьего лица;</li>
          <li>у третьего лица имеется подлинная заинтересованность в получении данных;</li>
          <li>очевидно, что в рассматриваемом конкретном случае возможность применения менее радикальных мер для получения данных отсутствует; и</li>
          <li>при оценке интересов сторон заинтересованность третьего лица определяется как главенствующая.</li>
        </ol>
        <p><strong>6.5</strong>&nbsp;Клиент обязуется следовать всем разумным указаниям компании Pylot в отношении использования ПО.</p>
        <p><strong>6.6</strong>&nbsp;Компания Pylot вправе взыскать с Клиента любые убытки и/или ущерб, понесенные в результате нарушения правил, приведенных в настоящем пункте. Клиент освобождает компанию Pylot от всех претензий третьих лиц в связи с причинением убытков и/или ущерба в результате нарушения правил, приведенных в настоящего пункте.</p>

        <h2><strong>Пункт 7.</strong>&nbsp;Модификации и улучшения</h2>
        <p><strong>7.1</strong>&nbsp;Компания Pylot вправе модифицировать ПО для исправления ошибок, добавления новых функций или повышения эффективности. Несмотря на то, что Стороны могут обсуждать такие изменения заблаговременно и Клиент вправе вносить свои предложения, окончательное решение о внедрении модификации принимает компания Pylot.</p>
        <p><strong>7.2</strong>&nbsp;Компания Pylot обязуется приложить все усилия для устранения любых недостатков ПО, при этом она также зависит от своих поставщиков в этой связи. Компания Pylot вправе не устанавливать отдельные обновления или усовершенствованные версии поставщиков, если она полагает, что установка таких обновлений или усовершенствованных версий не создаст преимуществ при эксплуатации ПО.</p>
        <p><strong>7.3</strong>&nbsp;Если внедрение модификаций и улучшений может привести к ограничению доступа к ПО, такие действия по мере возможности должны выполняться вне Часов работы. Аварийные технические работы могут выполняться в любой момент, и об их выполнении не будет сообщаться заблаговременно.</p>

        <h2><strong>Пункт 8.</strong>&nbsp;Поддержка</h2>
        <p><strong>8.1</strong>&nbsp;Компания Pylot обязуется обеспечивать Конечным пользователям и Клиенту разумный уровень поддержки по вопросам использования и управления ПО, а также в отношении технических вопросов, касающихся ПО.</p>
        <p><strong>8.2</strong>&nbsp;Поддержка, упомянутая в пункте 8.1, предоставляется через службу поддержки, которая доступна в Часы работы.</p>
        <p><strong>8.3</strong>&nbsp;Компания Pylot обязуется прилагать усилия для обработки запросов, поступающих в службу поддержки, в разумные сроки. Для решения проблем, о которых сообщается в службу поддержки, может потребоваться разное время.</p>

        <h2><strong>Пункт 9.</strong>&nbsp;Доступность</h2>
        <p><strong>9.1</strong>&nbsp;Компания Pylot обязуется прилагать усилия для обеспечения доступности ПО онлайн по мере возможности, но не может гарантировать его непрерывную доступность.</p>
        <p><strong>9.2</strong>&nbsp;Если, по мнению компании Pylot, использование компьютерных систем или сети компании Pylot или третьих лиц сопряжено с риском, например в случае D(DOS)-атаки или действия вредоносного программного обеспечения, компания Pylot вправе принять любые меры, которые она сочтет разумно необходимыми, для предотвращения риска и в целях ограничения или предотвращения любых убытков и/или ущерба. Это может повлечь за собой ограничение доступа к ПО.</p>
        <p><strong>9.3</strong>&nbsp;Компания Pylot не несет ответственности за создание резервных копий за исключением случаев, когда это предусмотрено в Соглашении об уровне услуг.</p>

        <h2><strong>Пункт 10.</strong>&nbsp;Безопасность и конфиденциальность</h2>
        <p><strong>10.1</strong>&nbsp;Компания Pylot обязуется прикладывать все усилия для защиты ПО в целях предотвращения ненадлежащего и противоправного использования данных Клиента, а также получения незаконного доступа к таковым.</p>
        <p><strong>10.2</strong>&nbsp;Исполнение Договора может предусматривать обработку персональных данных компанией Pylot от лица Клиента, при этом Клиент признается контроллером обработки данных, а компания Pylot - обработчиком данных. В случае обработки персональных данных Стороны обязуются заключить договор с обработчиком данных, в котором будут определены договоренности относительно обработки и обеспечения безопасности таких персональных данных. Вышеприведенные термины имеют значения, приведенные в применимом законодательстве о защите конфиденциальности.</p>
        <p><strong>10.3</strong>&nbsp;Клиент гарантирует, что введение или загрузка (персональных) данных для компании Pylot законны и что обработка таких данных в соответствии с Договором не противоречит никаким применимым законам и нормам о защите конфиденциальности.</p>

        <h2><strong>Пункт 11.</strong>&nbsp;Дополнительные работы</h2>
        <p><strong>11.1</strong>&nbsp;Если Клиент запрашивает дополнительные работы, ПО или Услуги, не включенные в сферу действия Договора, Стороны обязуются согласовать друг с другом условия выполнения такого запроса, а компания Pylot обязуется подготовить дополнительное ценовое предложение. Компания Pylot не приступит к выполнению дополнительных работ до получения от Клиента согласия с условиями ценового предложения.</p>

        <h2><strong>Пункт 12.</strong>&nbsp;Оплата</h2>
        <p><strong>12.1</strong>&nbsp;В течение Периода тестирования плата с Клиента не взимается. По истечении Периода тестирования компания Pylot обязуется выставить счет на все причитающиеся ей суммы и вправе выставлять счета авансом и в электронной форме. Любые возражения Клиента по (суммам по) счету не являются основанием для предоставления ему отсрочки исполнения соответствующего платежного обязательства.</p>
        <p><strong>12.2</strong>&nbsp;Все счета подлежат оплате в течение 14 дней за исключением случаев, когда в счете указан иной срок оплаты либо иной срок оплаты согласован Сторонами в Письменном виде.</p>
        <p><strong>12.3</strong>&nbsp;В случае неоплаты счета в указанный срок Клиент признается нарушившим условия Договора, при этом компания Pylot не обязана направлять Клиенту уведомление о неисполнении. В таком случае компания Pylot вправе приостановить предоставление ПО в части или в целом. Компания Pylot не несет ответственности за любые убытки и/или ущерб, понесенные Клиентом в этой связи.</p>
        <p><strong>12.4</strong>&nbsp;Все суммы, причитающиеся компании Pylot, подлежат незамедлительной уплате в случае объявления Клиента банкротом, направления заявления Клиентом о приостановлении платежей или предоставления Клиенту такого права, прекращения деятельности Клиента или ликвидации его предприятия.</p>
        <p><strong>12.5</strong>&nbsp;Все цены, указываемые компанией Pylot, приводятся в евро и не включают НДС и прочие государственные сборы.</p>
        <p><strong>12.6</strong>&nbsp;Если размер платы основан на информации, предоставленной Клиентом, и такая информация оказывается неверной, компания Pylot вправе соответствующим образом скорректировать размер платы, даже если Договор уже был заключен.</p>
        <p><strong>12.7</strong>&nbsp;Размер платы, перечисляемой на регулярной основе, может корректироваться ежегодно с учетом Индекса потребительских цен или иного коэффициента или нормы, согласованных Сторонами. Клиенту не предоставляется прав на расторжение в связи с таким изменением размера платы.</p>
        <p><strong>12.8</strong>&nbsp;Если Клиент не уплачивает причитающиеся суммы в установленные сроки, он несет ответственность за выплату предусмотренных законом коммерческих неустоек, упомянутых в разделе 6:119a Гражданского кодекса Нидерландов, начисляемых на сумму задолженности, в отсутствие обязательства о направлении уведомления о неисполнении.</p>
        <p><strong>12.9</strong>&nbsp;Если после получения требования об уплате или уведомления о неисполнении Клиент не перечисляет причитающиеся с него суммы, компания Pylot вправе подать претензию о взыскании, и в таком случае Клиент также несет ответственность за оплату в полном объеме всех судебных и внесудебных расходов, включая все расходы и вознаграждения юристов и сторонних экспертов, в дополнение к общей сумме задолженности, имеющейся в соответствующий момент времени.</p>

        <h2><strong>Пункт 13.</strong>&nbsp;Ответственность и обстоятельства непреодолимой силы</h2>
        <p><strong>13.1</strong>&nbsp;В силу того, что с Клиента не взимается плата за предоставление ПО в Период тестирования, компания Pylot не несет ответственности за убытки и/или ущерб, возникшие в результате неисполнения обязательств по Договору, незаконных действий или иных обстоятельств в Период тестирования, за исключением случаев, когда убытки и/или ущерб (1) возникли в результате умышленных виновных действий или намеренно допущенной небрежности руководства компании Pylot или (2) связаны со смертью или причинением вреда здоровью.</p>
        <p><strong>13.2</strong>&nbsp;По истечении Периода тестирования ответственность компании Pylot за убытки и/или ущерб, возникшие в результате неисполнения обязательств по Договору, незаконных действий или иных обстоятельств, ограничивается суммой, уплаченной компании Pylot страховщиком. Компания Pylot обязуется приложить все усилия для обеспечения выплаты страховщиком соответствующих сумм. В отсутствие выплаты страховщика ответственность за страховой случай (при этом несколько последовательных событий объединяются в один страховой случай) ограничивается суммой (не включая НДС), уплаченной Клиентом по Договору в течение шести месяцев до возникновения убытков и/или ущерба.</p>
        <p><strong>13.3</strong>&nbsp;Компания Pylot несет ответственность только за прямые убытки и/или ущерб, возникшие в результате соответствующей несостоятельности компании при исполнении Договора.</p>
        <p><strong>13.4</strong>&nbsp;Любое ограничение или исключение ответственности, предусмотренное Договором, не распространяется на случаи, когда убытки и/или ущерб (1) возникли в результате умышленных виновных действий или намеренно допущенной небрежности руководства (компании) Pylot или (2) связаны со смертью или причинением вреда здоровью.</p>
        <p><strong>13.5</strong>&nbsp;Любое право требования компенсации в любое время предоставляется при условии уведомления Клиентом компании Pylot об убытках и/или ущербе в Письменном виде в течение 30 дней с даты их обнаружения.</p>
        <p><strong>13.6</strong>&nbsp;Компания Pylot не обязана исполнять какое-либо обязательство по Договору, если такое исполнение невозможно в силу действия обстоятельств непреодолимой силы. Компания Pylot не несет ответственности за любые убытки и/или ущерб, вызванные обстоятельствами непреодолимой силы.</p>
        <p><strong>13.7</strong>&nbsp;Обстоятельствами непреодолимой силы признаются случаи прекращения электроснабжения, сбои в работе сети Интернет и телекоммуникационной инфраструктуры, сетевые атаки (включая D(DOS)-атаки), атаки вредоносного или иного причиняющего вред ПО, гражданские волнения, природные катастрофы, террористические акты, мобилизация, войны, введение запретов на импорт и экспорт, забастовки, прекращение поставок, пожары, наводнения и любые обстоятельства, в которых компания Pylot не может исполнять свои обязательства или сталкивается с трудностями при их исполнении из-за своих поставщиков независимо от причин.</p>
        <p><strong>13.8</strong>&nbsp;Если обстоятельство непреодолимой силы длится в течение более 90 дней, Стороны вправе направить уведомление о расторжении Договора в Письменном виде с незамедлительным вступлением такого решения в силу. Услуги, которые в таком случае были оказаны компанией Pylot до наступления обстоятельств непреодолимой силы и в период их действия, оплачиваются на пропорциональной основе.</p>
        <p><strong>13.9</strong>&nbsp;Стороны подтверждают, что Раздел 6:271 Гражданского кодекса Нидерландов и распространение действия его положений на нижеприведенные пункты исключаются.</p>
        <p><strong>13.10</strong>&nbsp;Любое право требования компенсации в любой период предоставляется при условии уведомления Клиентом компании Pylot об убытках и/или ущербе в Письменном виде в течение 30 дней с даты их возникновения.</p>

        <h2><strong>Пункт 14.</strong>&nbsp;Объекты интеллектуальной собственности</h2>
        <p><strong>14.1</strong>&nbsp;Все Права на объекты интеллектуальной собственности в отношении ПО и любых Материалов, предоставляемых компанией Pylot по Договору, сохраняются за компанией Pylot или ее лицензиарами.</p>
        <p><strong>14.2</strong>&nbsp;Клиент получает от компании Pylot неисключительные и не подлежащие переуступке и сублицензированию права пользования в отношении ПО и любых Материалов, если таковые проистекают из Договора или в ином порядке предоставляются в Письменном виде. Права пользования имеют силу в течение срока действия Договора.</p>
        <p><strong>14.3</strong>&nbsp;Клиент не вправе изменять ПО за исключением случаев, когда это требуется согласно имеющим обязательную силу законам.</p>
        <p><strong>14.4</strong>&nbsp;Компания Pylot вправе применять технические или иные меры для защиты ПО. При использовании компанией Pylot указанных средств защиты Клиент не вправе отключать или обходить такие средства защиты.</p>
        <p><strong>14.5</strong>&nbsp;Клиент не вправе удалять из ПО или изменять любые уведомления или сообщения о Правах на объекты интеллектуальной собственности. Аналогичным образом, запрещается удалять из ПО любые уведомления или сообщения, касающиеся их конфиденциального характера.</p>

        <h2><strong>Пункт 15.</strong>&nbsp;Поправки в условия и положения</h2>
        <p><strong>15.1</strong>&nbsp;Компания Pylot вправе в любое время вносить поправки в настоящие Общие условия и положения.</p>
        <p><strong>15.2</strong>&nbsp;Компания Pylot обязуется приложить усилия для доведения до сведения Клиента информации об изменениях или дополнениях по электронной почте или путем уведомления при установлении Клиентом связи с компанией Pylot как минимум за тридцать дней до их вступления в силу с тем, чтобы Клиент смог принять их во внимание.</p>
        <p><strong>15.3</strong>&nbsp;Если Клиент не желает принимать вносимые поправки или дополнения, Клиент вправе направить уведомление о расторжении Договора до даты их вступления в силу. Использование ПО после даты их вступления в силу будет расценено как согласие Клиента с измененными или дополненными условиями и положениями.</p>
        <p><strong>15.4</strong>&nbsp;Обе Стороны обязуются сотрудничать друг с другом в полном объеме в отношении внесения поправок или дополнений в Общие условия и положения, необходимых в свете введения новых или изменения действующих законов. Такие поправки могут вноситься без направления уведомления компанией Pylot, при этом компания Pylot обязуется приложить усилия для сообщения о таких поправках, а Клиент не вправе направить уведомление о расторжении Договора.</p>
        <p><strong>15.5</strong>&nbsp;Процедура, описанная в настоящем пункте, не распространяется на несущественные поправки. Такие поправки могут вноситься без направления уведомления компанией Pylot, а Клиент не вправе направить уведомление о расторжении Договора.</p>

        <h2><strong>Пункт 16.</strong>&nbsp;Конфиденциальность</h2>
        <p><strong>16.1</strong>&nbsp;Вся информация, которую стороны предоставляют друг другу до заключения, в течение срока действия или по истечении срока действия настоящего Договора или включают в Услуги, квалифицируется сторонами как конфиденциальная (далее - «Конфиденциальная информация»). Стороны также возложат соответствующие обязательства на своих сотрудников, а также на любых третьих лиц, привлекаемых ими в целях исполнения Договора.</p>
        <p><strong>16.2</strong>&nbsp;В отношении Конфиденциальной информации Стороны обязуются:</p>
        <ol type='a'>
          <li>принимать во внимание все разумные меры для обеспечения безопасности или хранения Конфиденциальной информации, гарантируя как минимум такой же уровень безопасности, который Сторона использует в отношении ее собственной Конфиденциальной информации;</li>
          <li>не использовать Конфиденциальную информацию в целях, отличных от согласованной сторонами цели (исполнение Договора);</li>
          <li>не хранить Конфиденциальную информацию дольше, чем это разумно необходимо для исполнения согласованных обязательств, и предоставить такие данные, включая любые изготовленные копии, другой Стороне сразу после полного исполнения связанных с ними обязательств или после передачи копии Конфиденциальной информации другой Стороне и предоставления такой Стороной подтверждения надлежащего получения таковой в Письменном виде, уничтожать такие данные.</li>
        </ol>
        <p><strong>16.3 </strong>&nbsp;В тех случаях и в той степени, которые явно согласованы Сторонами, компания Pylot вправе разгласить ту или иную Конфиденциальную информацию другим клиентам компании Pylot. Клиент по своему собственному усмотрению вправе определить, какую Конфиденциальную информацию компания Pylot может разглашать и кому.</p>

        <h2><strong>Пункт 17.</strong>&nbsp;Прекращение действия Договора</h2>
        <p><strong>17.1</strong>&nbsp;Компания Pylot хранит все данные и прочую информацию Клиента в течение тридцати дней после окончания действия Договора. При условии, что Клиент исполнил все свои платежные и иные обязательства по Договору, компания Pylot обязуется приложить усилия для предоставления копии таких данных и прочей информации по требованию Клиента. Компания Pylot взимает за это плату.</p>
        <p><strong>17.2</strong>&nbsp;Копия будет предоставлена в файле стандартного формата по усмотрению компании Pylot. Разумные расходы, связанные с копированием файлов и данных в соответствии с настоящим пунктом, возлагаются на Клиента.</p>

        <h2><strong>Пункт 18.</strong>&nbsp;Заключительные положения</h2>
        <p><strong>18.1</strong>&nbsp;Договор регулируется исключительно законодательством Нидерландов.</p>
        <p><strong>18.2</strong>&nbsp;В той степени, в которой положения имеющего обязательную силу закона не требуют обратного, любой спор между Сторонами в связи с Договором представляется на рассмотрение компетентного суда Нидерландов в районе, в котором расположен зарегистрированный офис компании Pylot.</p>
        <p><strong>18.3</strong>&nbsp;Компания Pylot может переуступать свои права и обязательства, возникающие в результате заключения Договора, третьему лицу, которое принимает на себя руководство компанией Pylot или ее хозяйственной деятельностью.</p>
        <p><strong>18.4</strong>&nbsp;Клиент обязан незамедлительно сообщать компании Pylot о любой смене наименования, (почтового) адреса, электронного адреса, номера телефона и прочей информации и реквизитов, связанных с исполнением Договора.</p>
        <p><strong>18.5</strong>&nbsp;Журналы регистрации и переписка, которые хранятся компанией Pylot, считаются верными, если Клиент не предоставит доказательств обратного.</p>
        <p><strong>18.6</strong>&nbsp;Если любое положение Договора признается ничтожным или недействительным, это никоим образом не влияет на действительность Договора в целом. В таком случае стороны обязуются заменить такое положение одним или несколькими новыми положениями, отражающими цели исходного положения, насколько это возможно согласно закону.</p>
      </article>
    );
  }
}

export default withRouter(General);
