import React, { memo } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import DefaultBackButton from 'components/DefaultBackButton';
import BigButton from 'components/BigButton';

import styles from './LoginExpiredLink.module.css';

const LoginExpiredLink = ({
  intl: { formatMessage },
}) => (
  <div className={styles.resetPageWrapper}>
    <DefaultBackButton
      className={styles.backButton}
      text={formatMessage({ id: 'button.back' })}
      link='/login'
    />
    <h2 className={styles.resetPageHeader}>
      {formatMessage({ id: 'password.linkExpired' })}
    </h2>
    <div className={styles.resetPageSubHeader}>
      {formatMessage({ id: 'password.linkExpiredSubHeader' })}
    </div>

    <BigButton
      title={formatMessage({ id: 'password.requestAnotherLink' })}
      type='button'
      href='/login/reset-password'
      theme='dark'
      className={styles.sendButton}
      titleClassName={styles.sendButtonTitle}
    />
  </div>
);

LoginExpiredLink.propTypes = {
  intl: intlShape.isRequired,
};

export default memo(injectIntl(LoginExpiredLink));
