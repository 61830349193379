import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import {
  get, groupBy, map, find, isEmpty
} from 'lodash';
import classnames from 'classnames';

import { getVarietyName } from '../../helpers/getVarietyName';
import Select from '../Select';

import selectStyles from '../Select/Select.module.css';
import styles from './VarietySelect.module.css';

class VarietySelect extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    onSelectOption: PropTypes.func.isRequired,
    varieties: PropTypes.array.isRequired,
    selectedOption: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    withoutAllOption: PropTypes.bool,
    customPlaceholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    classNameButton: PropTypes.string,
    title: PropTypes.string,
    withoutArrow: PropTypes.bool,
    onlyContent: PropTypes.bool,
    opened: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    selectedOption: null,
    withoutAllOption: false,
    customPlaceholder: null,
    classNameButton: null,
    title: undefined,
    withoutArrow: false,
    onlyContent: false,
    opened: false,
    disabled: false,
  };

  state = {
    varietySelectOpened: this.props.opened,
  };

  getPlaceholderName = (currentVariety, selectedOption, locale, formatMessage) => (currentVariety ? getVarietyName(currentVariety, locale) : formatMessage({ id: `plantingCycles.allSpecies.${selectedOption}` }));

  handlerCloseSelect = () => {
    const { varietySelectOpened } = this.state;

    if (varietySelectOpened) {
      this.setState({ varietySelectOpened: false });
    }
  };

  handlerSelectOption = (species, varietyId) => {
    const { onSelectOption } = this.props;

    onSelectOption(species, varietyId);
    this.handlerCloseSelect();
  }

  handlerSelectState = newState => this.setState({ varietySelectOpened: newState });

  renderAllSpeciesOption = (species) => {
    const { intl: { formatMessage }, selectedOption } = this.props;

    return (
      <div
        key={`speciesOption-${species}`}
        className={classnames(
          styles.option,
          selectStyles.option,
          { [selectStyles.selected]: selectedOption === species }
        )}
        onClick={() => this.handlerSelectOption(species)}
        role='button'
        tabIndex={0}
      >
        {formatMessage({ id: `plantingCycles.allSpecies.${species}` })}
      </div>
    );
  };

  renderVarieties = varieties => varieties.map((variety) => {
    const { intl: { locale }, selectedOption } = this.props;

    const { id } = variety;
    const species = get(variety, 'attributes.species');

    return (
      <div
        key={`varietyOption-${id}`}
        className={classnames(
          styles.option,
          selectStyles.option,
          { [selectStyles.selected]: Number(selectedOption) === Number(id) }
        )}
        onClick={() => this.handlerSelectOption(species, id)}
        role='button'
        tabIndex={0}
      >
        {getVarietyName(variety, locale)}
      </div>
    );
  });

  renderSpecies = (groupedVarieties, withoutAllOption, formatMessage) => map(groupedVarieties, (varieties, species) => (
    <div key={`speciesOption-${species}`}>
      <div className={styles.speciesName}>{formatMessage({ id: `plantingCycles.speciesPlural.${species}` })}</div>
      <div>
        {!withoutAllOption && this.renderAllSpeciesOption(species)}
        {this.renderVarieties(varieties)}
      </div>
    </div>
  ));

  renderSpeciesHeader = (species, formatMessage) => (
    <div key={`speciesOption-${species}`}>
      <div className={styles.speciesName}>{formatMessage({ id: `plantingCycles.speciesPlural.${species}` })}</div>
      <div>
        {this.renderAllSpeciesOption(species)}
      </div>
    </div>
  );

  renderOptions = () => {
    const {
      intl, varieties, selectedOption, withoutAllOption,
    } = this.props;
    const { formatMessage } = intl;

    const groupedVarieties = groupBy(varieties, 'attributes.species');

    return (
      <div className={selectStyles.options}>
        {isEmpty(groupedVarieties) ?
          this.renderSpeciesHeader(selectedOption, formatMessage)
          :
          this.renderSpecies(groupedVarieties, withoutAllOption, formatMessage)}
      </div>
    );
  };

  renderSelect = () => {
    const {
      intl, varieties, selectedOption, customPlaceholder, classNameButton, withoutArrow,
      disabled,
    } = this.props;
    const { formatMessage, locale } = intl;
    const { varietySelectOpened } = this.state;

    const currentVariety = find(varieties, { id: selectedOption });

    return (
      <Select
        classNameButton={classnames(styles.longSelectButton, classNameButton)}
        classNamePopup={styles.longSelectPopup}
        placeholder={customPlaceholder || this.getPlaceholderName(currentVariety, selectedOption, locale, formatMessage)}
        theme='white'
        size='medium'
        open={varietySelectOpened}
        onOpenChange={this.handlerSelectState}
        textEllipsis
        withoutArrow={withoutArrow}
        disabled={disabled}
      >
        {this.renderOptions()}
      </Select>
    );
  };

  render() {
    const {
      title, onlyContent,
    } = this.props;

    if (onlyContent) {
      return (
        <div className={styles.popup}>
          {this.renderOptions()}
        </div>
      );
    }

    if (title) {
      return (
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{title}</div>
          {this.renderSelect()}
        </div>
      );
    }

    // TODO: тут вместо varieties должны использоваться ProductGroups
    return this.renderSelect();
  }
}
export default injectIntl(VarietySelect);
