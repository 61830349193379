import { handleActions } from 'redux-actions';

import {
  requestLaborReport,
  receiveLaborReport,
  updateLaborReportSorting,
  requestLaborResources,
  receiveLaborResources,
  requestLaborWorkTypes,
  receiveLaborWorkTypes,
  clearLaborReport,
  requestLaborRegistrations,
  receiveLaborRegistrations,
  requestSaveLaborRegistrations,
  receiveSaveLaborRegistrations,
} from './actions';

const initialState = {
  isLaborReportFetching: false,
  laborReport: null,

  laborReportSorting: {
    type: 'meter',
    direction: 'up'
  },

  isLaborResourcesFetching: false,
  laborResources: [],

  isWorkTypesFetching: false,
  workTypes: null,

  isLaborRegistrationsFetching: false,
  laborRegistrations: null,

  isSaveLaborRegistrationsFetching: false,
};

export default handleActions({
  [clearLaborReport](state) {
    return {
      ...state,

      isLaborReportFetching: false,
      laborReport: null,
      laborReportSorting: {
        type: 'meter',
        direction: 'up'
      },
    };
  },
  [requestLaborReport](state) {
    return {
      ...state,

      isLaborReportFetching: true,
    };
  },
  [receiveLaborReport](state, action) {
    return {
      ...state,

      isLaborReportFetching: false,
      laborReport: action.payload.laborReport,
    };
  },
  [updateLaborReportSorting](state, action) {
    return {
      ...state,

      laborReportSorting: action.payload,
    };
  },
  [requestLaborResources](state) {
    return {
      ...state,

      isLaborResourcesFetching: true,
    };
  },
  [receiveLaborResources](state, action) {
    return {
      ...state,

      isLaborResourcesFetching: false,
      laborResources: action.payload.laborResources,
    };
  },
  [requestLaborWorkTypes](state) {
    return {
      ...state,

      isWorkTypesFetching: true,
    };
  },
  [receiveLaborWorkTypes](state, action) {
    return {
      ...state,

      isWorkTypesFetching: false,
      workTypes: action.payload.workTypes,
    };
  },
  [requestLaborRegistrations](state) {
    return {
      ...state,

      isLaborRegistrationsFetching: true,
    };
  },
  [receiveLaborRegistrations](state, action) {
    return {
      ...state,

      isLaborRegistrationsFetching: false,
      laborRegistrations: action.payload.laborRegistrations,
    };
  },
  [requestSaveLaborRegistrations](state) {
    return {
      ...state,
      isSaveLaborRegistrationsFetching: true,
    };
  },
  [receiveSaveLaborRegistrations](state) {
    return {
      ...state,
      isSaveLaborRegistrationsFetching: false,
    };
  },
}, initialState);
