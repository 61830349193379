import { connect } from 'react-redux';

import { getCurrentSpecies } from 'store/company/selectors';

import VarietySearchSelect from '../components/VarietySearchSelect';

const mapDispatchToProps = { };

const mapStateToProps = (state, props) => ({
  ...props,
  speciesList: getCurrentSpecies(state, props),
});

export default connect(mapStateToProps, mapDispatchToProps)(VarietySearchSelect);
