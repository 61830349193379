
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import moment from 'moment-timezone';
import classnames from 'classnames';
import { get } from 'lodash';

import getDateFormat from 'helpers/getDateFormat';

import CropsLink from 'components/CropsLink';

import styles from './HorizontalBarsTooltipContent.module.css';

const getFormattedDate = date => moment(date).format(getDateFormat('lll'));

const HorizontalBarsTooltipContent = ({
  intl, tooltipData, isShowLocationName, className
}) => {
  const { formatMessage } = intl;
  const { product } = tooltipData;

  const cycleWeeks = get(product, 'cycleWeeks');
  const harvestedWeeks = get(product, 'harvest.harvestedWeeks');

  const locationName = get(product, 'location.name');

  const startDate = get(product, 'startDate');
  const endDate = get(product, 'endDate');
  const dates = `${getFormattedDate(startDate)} – ${endDate ? getFormattedDate(endDate) : formatMessage({ id: 'cycle.present' })}`;

  return (
    <div className={classnames(styles.tooltipContent, className)}>
      {isShowLocationName && (
        <div className={styles.locationName}>
          {locationName}
        </div>
      )}
      <div className={styles.productName}>
        <CropsLink product={product} />
      </div>
      <div className={styles.dates}>
        {dates}
      </div>
      <div className={styles.compartment}>
        {product?.compartment?.name}
      </div>
      <div className={styles.harvest}>
        <div className={styles.harvestRow}>
          <span className={styles.harvestTitle}>
            {formatMessage({ id: 'benchmarking.cycleWeeks' })}
          </span>
          <span className={styles.harvestRowAmount}>{cycleWeeks}</span>
        </div>

        <div className={styles.harvestRow}>
          <span className={styles.harvestTitle}>
            {formatMessage({ id: 'benchmarking.harvestedWeeks' })}
          </span>
          <span className={styles.harvestRowAmount}>{harvestedWeeks}</span>
        </div>
      </div>
    </div>
  );
};

HorizontalBarsTooltipContent.propTypes = {
  intl: intlShape.isRequired,
  tooltipData: PropTypes.object.isRequired,
  isShowLocationName: PropTypes.bool,
  className: PropTypes.string,
};

HorizontalBarsTooltipContent.defaultProps = {
  isShowLocationName: true,
  className: null,
};

export default memo(injectIntl(HorizontalBarsTooltipContent));
