import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  requestSendResetEmail,
  cleanResetEmail,
} from '../../../store/login/actions';

import {
  getEmailToReset,
  getIsResetEmailFetching,
} from '../../../store/login/selectors';

import LoginReset from '../components/LoginReset';

const mapDispatchToProps = {
  requestSendResetEmail,
  cleanResetEmail,
};

const mapStateToProps = (state, props) => ({
  ...props,

  emailToReset: getEmailToReset(state, props),
  isResetEmailFetching: getIsResetEmailFetching(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LoginReset));
