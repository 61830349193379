import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import { uniq } from 'lodash';

import classnames from 'classnames';

// @ts-ignore
import { injectIntl, InjectedIntlProps } from 'react-intl';

import {
  Metric, Variety,
} from 'store/graphs/types';

import {
  ITreeSearchData,
  RequestMetricsTree, ResetTreeExpandedKeys, SetGraphMetricSearch, SetLoadingNodeKey,
  SetMetricsPanelFilters, SetTreeSearchData
} from 'store/graphs/actions';

import { ReactComponent as SearchIcon } from 'components/SidePanel/SidePanelGraphParameters/components/assets/search.svg';
import { ReactComponent as CloseIcon } from 'components/SidePanel/SidePanelGraphParameters/components/assets/close.svg';
import FilterIcon from 'components/Icons/FilterIcon';

import DashboardComplexFilters from 'components/DashboardComplexFilters';

// TODO: Move
import { ReactComponent as LoaderIcon } from '../../../../../SidePanel/SidePanelGraphParameters/components/assets/loader.svg';

import styles from './PanelMetricSearch.module.css';

export type PanelMetricSearchProps = {
  allMetrics: Array<Metric>;
  allVarieties: Array<Variety>;
  setMetricsPanelFilters: SetMetricsPanelFilters;
  requestMetricsTree: RequestMetricsTree;
  metricsSearchResult: Array<number> | null;
  metricsSearchData: ITreeSearchData | null;
  resetTreeExpandedKeys: ResetTreeExpandedKeys;
  setTreeSearchData: SetTreeSearchData;
  setLoadingNodeKey: SetLoadingNodeKey;
  graphMetricSearch: string;
  setGraphMetricSearch: SetGraphMetricSearch;
  metricsTreeFilter: {
    metricId: Array<number> | null;
    compartmentId: Array<number> | null;
    categoryId: Array<number> | null;
    categoryCode: Array<string> | null;
    species: Array<string> | null;
    varietyId: Array<number> | null;
  },
  isMetricsPanelFilterFetching: boolean;
};


type FilterPopupMetricOption = {
  id: number,
  processedTitle: string;
  title: string;
}

export type OnGraphMetricFilterChange = (option? : FilterPopupMetricOption) => void;

export type FilterOptionsPopupProp = {
  isFetching: boolean;
  allMetrics: Array<Metric>;
  metricsSearchResult: Array<number> | null;
  graphMetricSearch: string;
  handlerGraphMetricFilterChange: OnGraphMetricFilterChange;
}

const SearchPopup = ({
  intl,
  isFetching,
  metricsSearchResult,
  allMetrics,
  graphMetricSearch,
  handlerGraphMetricFilterChange,
} : FilterOptionsPopupProp & InjectedIntlProps) => {
  const { formatMessage, locale } = intl;

  const metricsList = useMemo<Array<FilterPopupMetricOption>>(() => (metricsSearchResult?.length > 0 ? metricsSearchResult.map((metricId: number) => {
      const metric = allMetrics.find((mIt: Metric) => mIt.id === metricId);
      if (metric) {
        const { id, attributes: { name } } = metric;
        const title = name[locale] || name.en;
        const processedFilterWords = graphMetricSearch.split(' ');
        const processedTitle = title
          .split(' ')
          .map((titleChunk: string) => {
            let processedChunk = titleChunk;
            processedFilterWords.forEach((processedFilterWord: string) => {
              const boldPosition = titleChunk.toLowerCase().indexOf(processedFilterWord.toLowerCase());
              if (boldPosition >= 0 && processedChunk.toLowerCase().localeCompare(titleChunk.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }) === 0) {
                processedChunk = [
                  titleChunk.slice(0, boldPosition),
                  '<strong>',
                  titleChunk.slice(boldPosition, boldPosition + processedFilterWord.length),
                  '</strong>',
                  titleChunk.slice(boldPosition + processedFilterWord.length),
                ].join('');
              }
            });
            return processedChunk;
          }).join(' ');
        return {
          id,
          processedTitle,
          title
        };
      }
      return null;
    }).filter((mId:Metric | null) => mId !== null) : []),
    [allMetrics, metricsSearchResult, graphMetricSearch, locale]);

  return (
    <div className={classnames(styles.filterOptionsPopup, { [styles.loading]: isFetching })}>
      {isFetching && (
        <div className={classnames(styles.filterLoader)}>
          <div className={classnames(styles.icon, styles.loadable)}>
            <LoaderIcon />
          </div>
        </div>
      )}
      {!isFetching && (!metricsList.length || metricsList.length === 0) && (
        <div className={styles.filterEmpty}>
          {formatMessage({ id: 'graphs.sidePanel.noSearchResult' })}
        </div>
      )}
      {/* eslint-disable react/no-danger */}
      {!isFetching && metricsList.length > 0 && (
        <div className={styles.filterOptions}>
          {metricsList.map((metric: FilterPopupMetricOption) => (
            <div
              role='button'
              tabIndex={0}
              key={`filter-metric-${metric.id}`}
              className={classnames(`filter-metric-option-${metric.id}`, styles.filterOption)}
              dangerouslySetInnerHTML={{ __html: metric.processedTitle }}
              onClick={() => {
                  handlerGraphMetricFilterChange(metric);
              }}
              onKeyDown={() => {
                  handlerGraphMetricFilterChange(metric);
              }}
            />
            ))}
        </div>
      )}
      {/* eslint-enable react/no-danger */}
    </div>
  );
};

const PanelMetricSearch = ({
  intl,
  setMetricsPanelFilters,
  metricsSearchResult,
  allMetrics,
  requestMetricsTree,
  metricsSearchData,
  resetTreeExpandedKeys,
  setTreeSearchData,
  setLoadingNodeKey,
  graphMetricSearch,
  setGraphMetricSearch,
  metricsTreeFilter,
  isMetricsPanelFilterFetching,
  allVarieties
} : PanelMetricSearchProps & InjectedIntlProps) => {
  const { formatMessage } = intl;

  const [useSearchFilter, setUseSearchFilter] = useState(true);
  const [selectedSearchMetric, setSelectedSearchMetric] = useState<number | null>(null);

  const handlerGraphMetricSearchInputChange = useCallback((e) => {
    setGraphMetricSearch(e.target.value);
    setMetricsPanelFilters({
      term: e.target.value
    });
    setUseSearchFilter(false);
  }, [setGraphMetricSearch, setMetricsPanelFilters, setUseSearchFilter]);

  const handlerEmptyMetricFilter = useCallback(() => {
    requestMetricsTree({
      ids: null,
    });
    setUseSearchFilter(false);
    setSelectedSearchMetric(null)
  }, [requestMetricsTree, setUseSearchFilter, setSelectedSearchMetric]);

  const handlerClearSearchField = useCallback(() => {
    setGraphMetricSearch('');
    setLoadingNodeKey(undefined);
    setUseSearchFilter(false);
    handlerEmptyMetricFilter();
    setTreeSearchData(null);
    resetTreeExpandedKeys();
  }, [
    setGraphMetricSearch,
    setUseSearchFilter,
    handlerEmptyMetricFilter,
    resetTreeExpandedKeys,
    setTreeSearchData,
    setLoadingNodeKey
  ]);

  const handlerFilterChange = useCallback((options) => {
    const payload = {
      ids: null,
      ...metricsTreeFilter,
      ...options,
    };

    if (selectedSearchMetric) {
      payload.metricId = uniq([selectedSearchMetric, ...(payload.metricId || [])]);
    }

    if (options?.fruitClassCode?.length > 0) {
      const allFruitClassCode = uniq([...options.fruitClassCode, ...(metricsTreeFilter.fruitClassCode || [])]);
      const fcVarieties = allVarieties
        .filter((item:Variety) => allFruitClassCode.includes(item.attributes.fruitClass))
        .map((item:Variety) => +item.id);
      payload.varietyId = uniq([...fcVarieties, ...payload.varietyId]);
    }

    resetTreeExpandedKeys();
    setLoadingNodeKey(undefined);
    requestMetricsTree(payload);
  }, [
    requestMetricsTree,
    // metricsSearchData,
    metricsTreeFilter,
    resetTreeExpandedKeys,
    setLoadingNodeKey,
    selectedSearchMetric,
    allVarieties
  ]);

  const filters = useMemo(() => [{
      type: 'group',
      options: {
        withoutArrow: true,
        className: styles.groupFilter,
        renderPlaceholder: () => 'Filters',
        renderIcon: (applied:Array<string>) => (applied?.length > 0 ?
          <span className={styles.filterAppliedCount}>{applied?.length}</span> :
          <FilterIcon />)
      },
      filters: [{
        type: 'compartment',
        options: {
          classNameButton: styles.selectButtonText,
          defaultNoSelectedValue: []
        }
      }, {
        type: 'variety',
        options: {
          classNameButton: styles.selectButtonText,
          withCheckBox: true,
          showActiveState: false
        }
      }]
    }], []);

  const handlerGraphMetricFilterChange = useCallback((metric: FilterPopupMetricOption) => {
    const payload = {
      ids: null,
      ...metricsTreeFilter,
      metricId: [metric.id]
    };

    const allFruitClassCode = uniq([...(metricsTreeFilter.fruitClassCode || [])]);
    const fcVarieties = allVarieties
      .filter((item:Variety) => allFruitClassCode.includes(item.attributes.fruitClass))
      .map((item:Variety) => +item.id);
    payload.varietyId = uniq([...fcVarieties, ...(payload.varietyId || [])]);

    requestMetricsTree(payload);
    setSelectedSearchMetric(metric.id);
    setUseSearchFilter(true);
    setGraphMetricSearch(metric.title);
  }, [
    requestMetricsTree,
    setUseSearchFilter,
    setGraphMetricSearch,
    setSelectedSearchMetric,
    metricsTreeFilter,
    allVarieties
  ]);

  return (
    <div className={classnames(styles.filterInputWrapper)}>
      <div className={classnames(styles.filterControl)}>
        <div className={classnames(styles.icon, styles.filterInputButton, styles.filterInputButtonLeft)}>
          <SearchIcon />
        </div>
        <input
          type='text'
          className={styles.filterInput}
          onChange={handlerGraphMetricSearchInputChange}
          value={graphMetricSearch}
          placeholder={formatMessage({ id: 'graphs.sidePanel.filterSeries' })}
        />
        {graphMetricSearch && (
          <div
            role='menu'
            tabIndex={0}
            className={classnames(styles.icon, styles.filterInputButton, styles.filterInputButtonRight)}
            onClick={handlerClearSearchField}
          >
            <CloseIcon />
          </div>
        )}
      </div>
      {graphMetricSearch && !useSearchFilter && (
        <SearchPopup
          intl={intl}
          isFetching={isMetricsPanelFilterFetching}
          allMetrics={allMetrics}
          metricsSearchResult={metricsSearchResult}
          graphMetricSearch={graphMetricSearch}
          requestMetricsTree={requestMetricsTree}
          handlerGraphMetricFilterChange={handlerGraphMetricFilterChange}
        />
      )}
      <DashboardComplexFilters
        filters={filters}
        onFiltersChange={handlerFilterChange}
        showResetAll
      />
    </div>
  );
};

export default injectIntl(PanelMetricSearch);

