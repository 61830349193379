import { connect } from 'react-redux';

import { getCurrentYearOverallQuality } from 'store/dataQuality/selectors';
import { getIsCropCompareAvailable } from 'store/company/selectors';
import { getCyclesToCompare } from 'store/benchmark/selectors';

import NavigationMenu from '../components/NavigationMenu';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,

  isCropCompareAvailable: getIsCropCompareAvailable(state, props),
  overallQuality: getCurrentYearOverallQuality(state),
  cyclesToCompare: getCyclesToCompare(state, props),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);

