import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  getOrganizationSlug,
  getIsBenchmarkAvailable,
  getIsEssentialPricePlan,
  getHarvestForecastAccess,
  getCropReportsAccess,
  getClimateInsightsAccess,
} from 'store/company/selectors';

import SettingsMenu from '../components/SettingsMenu';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,

  organizationSlug: getOrganizationSlug(state, props),
  isBenchmarkAvailable: getIsBenchmarkAvailable(state, props),
  isEssentialPricePlan: getIsEssentialPricePlan(state, props),
  harvestForecastAccess: getHarvestForecastAccess(state),
  cropReportsAccess: getCropReportsAccess(state, props),
  incidentsAccess: getClimateInsightsAccess(state, props),
  alertingAccess: getClimateInsightsAccess(state, props),
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsMenu)));
