import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { isNull } from 'lodash';

import storageWrapper from '../../../helpers/storageWrapper';
import { getDefaultDateRange } from '../../../helpers/defaultDates';

import {
  getOrganizationSlug,
  getResourceType,
  getCurrentTimezone,
  getIsAddEnergyAvailable,
  getAnyDateOfPeriodStartFromRoute,
  getAnyDateOfPeriodEndFromRoute,
  getPeriodTypeFromRoute,
} from '../../../store/company/selectors';

import {
  getEnergyRegistrations,
  getIsEnergyRegistrationsFetching,
  getIsSaveRegistrationsFetching,
} from '../../../store/energy/selectors';

import { showNotificationWithTimeout } from '../../../store/notificationCenter/actions';

import {
  requestEnergyRegistrations,
  requestSaveRegistrations
} from '../../../store/energy/actions';

import EnergyAdd from '../components/EnergyAdd';

const DEFAULT_PERIOD = 'month';
const safeLocalStorage = storageWrapper.get('localStorage');

const mapDispatchToProps = {
  requestEnergyRegistrations,
  requestSaveRegistrations,
  showNotificationWithTimeout,
};

const mapStateToProps = (state, props) => {
  const periodTypeFromStorage = safeLocalStorage.getItem('energyAddPeriodType');
  const defaultPeriod = isNull(periodTypeFromStorage) ? DEFAULT_PERIOD : periodTypeFromStorage;

  const periodType = getPeriodTypeFromRoute(state, props) || defaultPeriod;
  const defaultDateRange = getDefaultDateRange(periodType, true);

  const startDate = getAnyDateOfPeriodStartFromRoute(state, props) || defaultDateRange.startDate;
  const endDate = getAnyDateOfPeriodEndFromRoute(state, props) || defaultDateRange.endDate;

  return {
    ...props,

    organizationSlug: getOrganizationSlug(state, props),
    resourceType: getResourceType(state, props),
    timezone: getCurrentTimezone(state, props),
    energyRegistrations: getEnergyRegistrations(state, props),
    isEnergyReportFetching: getIsEnergyRegistrationsFetching(state, props),
    isSaveRegistrationsFetching: getIsSaveRegistrationsFetching(state, props),
    isAddEnergyAvailable: getIsAddEnergyAvailable(state),
    periodType,
    startDate,
    endDate,
  };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(EnergyAdd)));
