import { isNumber, round } from 'lodash';


/**
 * Дефолтное округление чисел
 * @param {number} number - Округляемое число.
 * @return {number} Округленное число.
 */
function defaultRounding(number) {
  const absNumber = Math.abs(number);

  if (absNumber < 100) {
    return Number((number).toFixed(1));
  }

  return Number((number).toFixed());
}

/**
 * Округляет числа для ручных значений (harvest, on crops screen)
 * @param {number} number - Округляемое число.
 * @return {number} Округленное число.
 */
function roundManualValue(number) {
  return Number((number).toFixed(2));
}

/**
 * Округляет значения процентов
 * @param {number} number - Округляемое число.
 * @return {number} Округленное число.
 */
function roundPercentageValue(number) {
  if (Math.abs(number) < 10) {
    return Number((number).toFixed(1));
  }

  return Number((number).toFixed());
}

/**
 * Округляет числа для dashboard и tables (все кроме процентов)
 * @param {number} number - Округляемое число.
 * @return {number} Округленное число.
 */
function roundKPIValue(number) {
  const absNumber = Math.abs(number);

  if (absNumber < 10) {
    return Number((number).toFixed(2));
  } if (absNumber < 100) {
    return Number((number).toFixed(1));
  }

  return Number((number).toFixed());
}

/**
 * TODO: Узнать у Руслана логику применения этой функции, кажется её можно заменить на roundKPIValue
 * Cейчас отличия только в значении по модулю и прочерку если не number
 *
 * Округляет числа для графиков
 * @param {number} number - Округляемое число.
 * @return {number} Округленное число.
 */
function roundGraphsValue(number) {
  let roundedNubmer;

  if (!isNumber(number)) {
    return number || '-';
  }

  if (number > 100) {
    roundedNubmer = Number((number).toFixed());
  } else if (number > 10) {
    roundedNubmer = Number((number).toFixed(1));
  } else {
    roundedNubmer = Number((number).toFixed(2));
  }

  return roundedNubmer;
}

/**
 * Округляет числа c строго фиксированным количеством знаков после запятой
 * https://stackoverflow.com/questions/2283566/how-can-i-round-a-number-in-javascript-tofixed-returns-a-string
 * возвращает string, т.к. в js по-другому нельзя
 * @param {number} number - Округляемое число.
 * @param {number} digits - Количество знаков после запятой для fixed типа округления.
 * @return {string} Округленное число.
 */
function roundFixedValue(number, digits) {
  return parseFloat(number).toFixed(digits);
}

/**
 * Добавляет 0 до необходимого разряда
 * @param {(number|string)} number - Округляемое число.
 * @param {number} zeroesCount - Минимальное количество разрядов.
 * @return {number} Округленное число.
 */
function addZeroes(number, zeroesCount) {
  let value = Number(number);

  const strinfifiedNum = number.toString();
  const res = strinfifiedNum.split('.');

  if (res.length === 1 || res[1].length <= zeroesCount) {
    value = value.toFixed(zeroesCount);
  }

  return value;
}

/**
 * Округляет большие числа (тысячи, десятки)
 *
 * @param {(number|string)} number - Округляемое число.
 * @return {number} Округленное число.
 */
function bigNumbers(number) {
  const absNumber = Math.abs(number).toFixed();
  const numberLength = absNumber.toString().length;

  return round(number, -(numberLength - 2));
}

/**
 * Округляет числа до нужного знака после запятой в зависимости от значения и типа округления
 * @param {number} number - Округляемое число.
 * @param {string} type - Тип округления.
 * @param {number} digits - Количество знаков после запятой для fixed типа округления.
 * @return {(number|string)} Округленное число.
 */
export default function numbersRounding(number, type, digits) {
  if (Number.isNaN(parseFloat(number))) {
    return number;
  }

  switch (type) {
    case 'manual':
      return roundManualValue(number);
    case 'percentage':
      return roundPercentageValue(number);
    case 'kpi':
      return roundKPIValue(number);
    case 'graphs':
      return roundGraphsValue(number);
    case 'fixed':
      return roundFixedValue(number, digits);
    case 'zeroes':
      return addZeroes(number, digits);
    case 'bigNumbers':
      return bigNumbers(number);
    default:
      return defaultRounding(number);
  }
}
