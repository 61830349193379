import React from 'react';
import { intlShape, injectIntl } from 'react-intl';

import { showIntercom } from 'helpers/intercomHelpers';

import Typography from 'components/Typography';
import BigButton from 'components/BigButton';

import Plus from 'components/Icons/Plus';
import UnionIcon from 'components/Icons/UnionIcon';

import styles from './CropReportsEmpty.module.css';

const CropReportsEmpty = ({
  intl,
}) => {
  const { formatMessage } = intl;

  return (
    <div className={styles.emptyWrapper}>
      <UnionIcon className={styles.unionIcon} />
      <Typography variant='h3'>
        {formatMessage({ id: 'reports.emptyTitle' })}
      </Typography>
      <Typography variant='subtitle3' className={styles.subtitle}>
        {formatMessage({ id: 'reports.emptyDesc' })}
      </Typography>
      <BigButton
        className={styles.openButton}
        title={formatMessage({ id: 'reports.addGraphs' })}
        onClick={showIntercom}
        theme='dark'
        icon={<Plus className={styles.icon} />}
        bigIcon
        size='small'
      />
    </div>
  );
};

CropReportsEmpty.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CropReportsEmpty);
