import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, FormattedPlural } from 'react-intl';

import { get } from 'lodash';
import classnames from 'classnames';
import moment from 'moment-timezone';

import getWeeks from '../../helpers/getWeeks';
import storageWrapper from '../../helpers/storageWrapper';

import BigButton from '../BigButton';

import { ReactComponent as OpenLargeIcon } from './assets/open_large.svg';
import { ReactComponent as CloseLargeIcon } from './assets/close_large.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as DeleteIcon } from './assets/delete.svg';
import { ReactComponent as ShareIcon } from './assets/share.svg';
import { ReactComponent as ArrowButton } from './assets/arrow_button.svg';
import { ReactComponent as Replace } from './assets/replace.svg';
import { ReactComponent as PhotoGray } from '../CellPhoto/assets/photo_gray.svg';

import styles from './PhotoDetailDialog.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');

function buildPhotosSequence({
  photos,
  photoCategories,
  selectedPlantingCycle,
  timezone
}) {
  const { attributes: plantingCycleAttributes } = selectedPlantingCycle;
  const { startDate, endDate } = plantingCycleAttributes;
  const weeks = getWeeks({ startDate, endDate, timezone });
  return photoCategories.map(photosType => ({
    category: photosType,
    photos: weeks.map(weekIt => photos?.find((item) => {
      const { date } = item.attributes;
      return item.attributes.category === photosType.code && date === weekIt.fullDate;
    }) || {
      type: 'Photo',
      attributes: {
        category: photosType.code,
        date: weekIt.fullDate
      },
    })
  }));
}

export default class PhotoDetailDialog extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    leftPlantingCycle: PropTypes.object,
    rightPlantingCycle: PropTypes.object,
    photoCategories: PropTypes.array.isRequired,
    leftPhotos: PropTypes.array,
    rightPhotos: PropTypes.array,
    varieties: PropTypes.array,
    userProfile: PropTypes.object,
    createPhotoComment: PropTypes.func.isRequired,
    setSelectedPhoto: PropTypes.func.isRequired,
    onPhotoDialogClose: PropTypes.func.isRequired,
    onPhotoDialogOpen: PropTypes.func.isRequired,
    onPhotoDeleteDialogOpen: PropTypes.func.isRequired,
    intl: intlShape.isRequired,

    selectedPhoto: PropTypes.object.isRequired,
    selectedCompartment: PropTypes.object.isRequired,
    selectedPlantingCycle: PropTypes.object.isRequired,
    selectedWeek: PropTypes.number.isRequired,
    selectedYear: PropTypes.number.isRequired,
    selectedPhotos: PropTypes.array.isRequired,
    isAddPhotoAvailable: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    userProfile: null,
    leftPlantingCycle: null,
    rightPlantingCycle: null,
    varieties: [],
    leftPhotos: [],
    rightPhotos: [],
  };

  state = {
    largeMode: false,
    photos: []
  };

  componentDidMount() {
    const {
      leftPhotos,
      photoCategories,
      selectedPlantingCycle,
      location
    } = this.props;

    const { attributes: { timezone } } = location;

    this.setState(prevState => ({
      ...prevState,
      photos: buildPhotosSequence({
        photos: leftPhotos,
        photoCategories,
        selectedPlantingCycle,
        timezone
      })
    }));
  }

  handlerPhotoDialogOpen = (options) => {
    const { onPhotoDialogOpen } = this.props;

    if (onPhotoDialogOpen) {
      onPhotoDialogOpen(options);
    }
  };

  handlerPhotoDeleteDialogOpen = (options) => {
    const { onPhotoDeleteDialogOpen } = this.props;

    if (onPhotoDeleteDialogOpen) {
      onPhotoDeleteDialogOpen(options);
    }
  };

  handlerPhotoDialogClose = () => {
    const { onPhotoDialogClose } = this.props;

    if (onPhotoDialogClose) {
      onPhotoDialogClose();
    }
  };

  handlerCommentSubmit = async () => {
    const { selectedPhoto, createPhotoComment } = this.props;
    const photoComment = this.photoComment.value;
    const { id: photoId } = selectedPhoto;

    await createPhotoComment({ photoId, photoComment });

    this.photoComment.value = '';
  };

  handlerShadowClick = (e) => {
    if (e.target.classList && e.target.classList.contains(styles.shadow)) {
      this.handlerPhotoDialogClose(true);
    }
  };

  isPhotoUppable = () => {
    const {
      selectedPhoto,
    } = this.props;
    const {
      photos
    } = this.state;
    if (!photos.length) {
      return false;
    }
    const selectedCategory = photos.findIndex(pI => pI.category.code === selectedPhoto.attributes.category);
    return selectedCategory > 0;
  };

  handlerPhotoUp = () => {
    const {
      selectedPhoto,
      selectedPhotos,
      setSelectedPhoto,
      selectedYear,
      selectedWeek,
      selectedCompartment,
      selectedPlantingCycle,
    } = this.props;

    const {
      photos
    } = this.state;

    const selectedCategory = photos.findIndex(pI => pI.category.code === selectedPhoto.attributes.category);

    if (selectedCategory > 0) {
      const currentPhotosList = photos[selectedCategory].photos;
      const currentIndex = currentPhotosList.findIndex((photo) => {
        const { attributes: { date: photoDate } } = photo;
        const { attributes: { date: selectedPhotoDate } } = selectedPhoto;
        return photoDate === selectedPhotoDate;
      });
      const nextSelectedPhoto = photos[selectedCategory - 1].photos[currentIndex];

      setSelectedPhoto({
        selectedPhoto: nextSelectedPhoto,
        selectedPhotos,
        selectedYear,
        selectedWeek,
        selectedCompartment,
        selectedPlantingCycle
      });
    }
  };

  isPhotoDownable = () => {
    const {
      selectedPhoto,
    } = this.props;
    const {
      photos
    } = this.state;
    if (!photos.length) {
      return false;
    }
    const selectedCategory = photos.findIndex(pI => pI.category.code === selectedPhoto.attributes.category);
    return selectedCategory < photos.length - 1;
  };

  handlerPhotoDown = () => {
    const {
      selectedPhoto,
      selectedPhotos,
      setSelectedPhoto,
      selectedYear,
      selectedWeek,
      selectedCompartment,
      selectedPlantingCycle,
    } = this.props;

    const {
      photos
    } = this.state;

    const selectedCategory = photos.findIndex(pI => pI.category.code === selectedPhoto.attributes.category);

    if (selectedCategory < photos.length - 1) {
      const currentPhotosList = photos[selectedCategory].photos;

      const currentIndex = currentPhotosList.findIndex((photo) => {
        const { attributes: { date: photoDate } } = photo;
        const { attributes: { date: selectedPhotoDate } } = selectedPhoto;
        return photoDate === selectedPhotoDate;
      });

      const nextSelectedPhoto = photos[selectedCategory + 1].photos[currentIndex];

      setSelectedPhoto({
        selectedPhoto: nextSelectedPhoto,
        selectedPhotos,
        selectedYear,
        selectedWeek,
        selectedCompartment,
        selectedPlantingCycle
      });
    }
  };

  isWeekRightable = () => {
    const {
      selectedPhoto,
    } = this.props;
    const {
      photos
    } = this.state;
    if (!photos.length) {
      return false;
    }
    const selectedCategory = photos.findIndex(pI => pI.category.code === selectedPhoto.attributes.category);
    const currentPhotosList = photos[selectedCategory].photos;
    const { attributes: { date: selectedPhotoDate } } = selectedPhoto;
    const currentIndex = currentPhotosList.findIndex((photo) => {
      const { attributes: { date: photoDate } } = photo;
      return photoDate === selectedPhotoDate;
    });
    return currentIndex < currentPhotosList.length - 1;
  };

  handlerWeekRight = () => {
    const {
      selectedPhoto,
      selectedPhotos,
      setSelectedPhoto,
      selectedCompartment,
      selectedPlantingCycle,
      location
    } = this.props;

    const {
      photos
    } = this.state;

    const selectedCategory = photos.findIndex(pI => pI.category.code === selectedPhoto.attributes.category);
    const currentPhotosList = photos[selectedCategory].photos;
    const { attributes: { timezone } } = location;
    const { attributes: { date: selectedPhotoDate } } = selectedPhoto;
    const currentIndex = currentPhotosList.findIndex((photo) => {
      const { attributes: { date: photoDate } } = photo;
      return photoDate === selectedPhotoDate;
    });

    if (currentIndex < currentPhotosList.length - 1) {
      const nextSelectedPhoto = photos[selectedCategory].photos[currentIndex + 1];
      const week = moment.tz(nextSelectedPhoto.attributes.date, timezone).isoWeek();
      const year = moment.tz(nextSelectedPhoto.attributes.date, timezone).year();
      setSelectedPhoto({
        selectedPhoto: nextSelectedPhoto,
        selectedPhotos,
        selectedYear: year,
        selectedWeek: week,
        selectedCompartment,
        selectedPlantingCycle
      });
    }
  };

  isWeekLeftable = () => {
    const {
      selectedPhoto,
    } = this.props;
    const {
      photos
    } = this.state;
    if (!photos.length) {
      return false;
    }
    const selectedCategory = photos.findIndex(pI => pI.category.code === selectedPhoto.attributes.category);
    const currentPhotosList = photos[selectedCategory].photos;
    const { attributes: { date: selectedPhotoDate } } = selectedPhoto;
    const currentIndex = currentPhotosList.findIndex((photo) => {
      const { attributes: { date: photoDate } } = photo;
      return photoDate === selectedPhotoDate;
    });
    return currentIndex > 0;
  };

  handlerWeekLeft = () => {
    const {
      selectedPhoto,
      selectedPhotos,
      setSelectedPhoto,
      selectedCompartment,
      selectedPlantingCycle,
      location
    } = this.props;

    const {
      photos
    } = this.state;

    const selectedCategory = photos.findIndex(pI => pI.category.code === selectedPhoto.attributes.category);
    const currentPhotosList = photos[selectedCategory].photos;
    const { attributes: { timezone } } = location;
    const { attributes: { date: selectedPhotoDate } } = selectedPhoto;
    const currentIndex = currentPhotosList.findIndex((photo) => {
      const { attributes: { date: photoDate } } = photo;
      return photoDate === selectedPhotoDate;
    });

    if (currentIndex > 0) {
      const nextSelectedPhoto = photos[selectedCategory].photos[currentIndex - 1];
      const week = moment.tz(nextSelectedPhoto.attributes.date, timezone).isoWeek();
      const year = moment.tz(nextSelectedPhoto.attributes.date, timezone).year();
      setSelectedPhoto({
        selectedPhoto: nextSelectedPhoto,
        selectedPhotos,
        selectedYear: year,
        selectedWeek: week,
        selectedCompartment,
        selectedPlantingCycle
      });
    }
  };

  render() {
    const {
      location,
      selectedPhoto,
      varieties,
      userProfile,
      selectedPhotos,
      selectedWeek,
      selectedPlantingCycle,
      selectedCompartment,
      selectedYear,
      intl,
      photoCategories,
      isAddPhotoAvailable,
    } = this.props;

    const { category: selectedPlace } = selectedPhoto.attributes;
    const { formatMessage } = intl;
    const { attributes: { timezone } } = location;
    const { largeMode } = this.state;

    const findedVariety = varieties.find((item) => {
      if (selectedPlantingCycle) {
        const { id: itemId } = item;
        const { id: varietyId } = selectedPlantingCycle.relationships.variety.data[0];
        return selectedPlantingCycle && itemId === varietyId;
      }
      return false;
    });

    const locale = safeLocalStorage.getItem('locale') || 'en';

    const varietyName = get(findedVariety, `attributes.mlName[${locale}]`) || get(findedVariety, 'attributes.mlName.en');

    const selectedPlantingCycleName = findedVariety ? `${formatMessage({ id: `plantingCycles.species.${findedVariety.attributes.species}` })} ${varietyName}` : '';

    const weekNumber = getWeeks({
      startDate: selectedPlantingCycle.attributes.startDate,
      endDate: selectedPlantingCycle.attributes.endDate,
      timezone
    }).find(item => item.week === selectedWeek && item.year === selectedYear).id + 1;

    const largeImgProps = {
      onClick: () => this.setState({ largeMode: false }),
    };

    const photoProps = {
      onClick: () => {
        if (selectedPhoto.attributes.mainUrl) {
          this.setState({ largeMode: true });
        }
      },
    };

    const shadowProps = {
      onClick: this.handlerShadowClick,
    };

    return (
      <div className={styles.shadow} {...shadowProps}>
        <div className={classnames(styles.photoDialog, { [styles.largeMode]: largeMode })}>
          <CloseIcon className={styles.photoDialogClose} onClick={this.handlerPhotoDialogClose} />
          {!largeMode && <OpenLargeIcon className={styles.photoDialogOpenLarge} onClick={() => { this.setState({ largeMode: true }); return false; }} />}
          {largeMode && <CloseLargeIcon className={styles.photoDialogCloseLarge} onClick={() => { this.setState({ largeMode: false }); return false; }} />}
          {!largeMode && (
            <div className={styles.photoDialogHeader}>
              <div className={styles.photoDialogPhotoWrapper}>
                {selectedPhoto?.attributes?.mainUrl ? (
                  <img alt='' className={styles.photoDialogPhoto} {...photoProps} src={selectedPhoto.attributes.mainUrl} />
                ) : (
                  <a
                    className={styles.photoReplaceWrapper}
                    href='#link'
                    onClick={() => {
                      this.handlerPhotoDialogOpen({
                        photo: selectedPhoto,
                        year: selectedYear,
                        week: selectedWeek,
                        compartment: selectedCompartment,
                        plantingCycle: selectedPlantingCycle,
                        category: selectedPlace,
                        photos: selectedPhotos,
                        replace: false
                      });
                    }}
                  >
                    <PhotoGray />
                    <div><FormattedMessage id='photos.takePhoto' /></div>
                  </a>
                )}
              </div>
              <div className={styles.photoDialogTitle}>{selectedPlantingCycleName.replace(/(\(.*\))/, '')}</div>
              <div className={styles.photoDialogCommandsWrapper}>
                <div className={classnames(styles.photoDialogCommands, styles.commandButtons)}>
                  <a className={styles.linkedButton} href={`${selectedPhoto.attributes.originUrl || ''}`} target='_blank' rel='noreferrer noopener'>
                    <ShareIcon />
                    <div><FormattedMessage id='photos.share' /></div>
                  </a>
                  {isAddPhotoAvailable && (
                    <a
                      className={styles.linkedButton}
                      href='#link'
                      onClick={() => {
                        if (selectedPhoto?.attributes?.mainUrl) {
                          this.handlerPhotoDialogOpen({
                            photo: selectedPhoto,
                            year: selectedYear,
                            week: selectedWeek,
                            compartment: selectedCompartment,
                            plantingCycle: selectedPlantingCycle,
                            category: selectedPlace,
                            photos: selectedPhotos,
                            replace: true
                          });
                        }
                      }}
                    >
                      <Replace />
                      <div><FormattedMessage id='photos.replace' /></div>
                    </a>
                  )}
                  {isAddPhotoAvailable && (
                    <a
                      className={styles.linkedButton}
                      href='#link'
                      onClick={() => {
                        if (selectedPhoto?.attributes?.mainUrl) {
                          this.handlerPhotoDeleteDialogOpen({
                            photo: selectedPhoto,
                            plantingCycle: selectedPlantingCycle,
                          });
                        }
                      }}
                    >
                      <DeleteIcon />
                      <div><FormattedMessage id='photos.delete' /></div>
                    </a>
                  )}
                </div>

                <div className={classnames(styles.photoDialogCommands, styles.walkButtons)}>
                  <div className={classnames(styles.walkButton, styles.walkButtonFirst)}>
                    <div className={classnames(styles.walkButtonIcon, { [styles.disabled]: !this.isPhotoUppable() })}>
                      <ArrowButton style={{ transform: 'rotate(180deg)' }} onClick={this.handlerPhotoUp} />
                    </div>
                    <div className={styles.walkButtonTitle}>
                      {photoCategories.find(item => item.code === selectedPhoto.attributes.category)[locale]}
                    </div>
                    <div className={classnames(styles.walkButtonIcon, { [styles.disabled]: !this.isPhotoDownable() })}>
                      <ArrowButton onClick={this.handlerPhotoDown} />
                    </div>
                  </div>
                  <div className={classnames(styles.walkButton, styles.walkButtonSecond)}>
                    <div className={classnames(styles.walkButtonIcon, { [styles.disabled]: !this.isWeekLeftable() })}>
                      <ArrowButton style={{ transform: 'rotate(90deg)' }} onClick={this.handlerWeekLeft} />
                    </div>
                    <div className={styles.walkButtonTitle}>
                      <div>
                        <FormattedPlural value={1} one={<FormattedMessage id='photos.week.one' />} other={<FormattedMessage id='photos.week.other' />} />&nbsp;{selectedWeek}&nbsp;/&nbsp;{weekNumber}&nbsp;
                        <FormattedPlural
                          value={weekNumber}
                          one={<FormattedMessage id='photos.week.one' />}
                          few={<FormattedMessage id='photos.week.few' />}
                          many={<FormattedMessage id='photos.week.many' />}
                          other={<FormattedMessage id='photos.week.other' />}
                        />
                      </div>
                      <div>{moment.tz(selectedPhoto.attributes.date, timezone).format('DD.MM.YYYY')}</div>
                    </div>
                    <div className={classnames(styles.walkButtonIcon, { [styles.disabled]: !this.isWeekRightable() })}>
                      <ArrowButton style={{ transform: 'rotate(-90deg)' }} onClick={this.handlerWeekRight} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!largeMode && (
            <div className={styles.photoDialogBody}>
              <div className={styles.comments}>
                {
                  selectedPhoto && selectedPhoto.attributes.comments && selectedPhoto.attributes.comments
                    .sort((a, b) => moment(new Date(a.attributes.whenCreated)) - moment(new Date(b.attributes.whenCreated)))
                    .map((comment) => {
                      const { attributes, id } = comment;
                      const { authorName, whenCreated, text } = attributes;
                      const created = new Date(whenCreated);

                      return (
                        <div className={styles.comment} key={`dialog-comment-${id}`}>
                          <div className={styles.commentHeader}>
                            <div className={styles.commentAuthor}>{authorName}</div>
                            <div className={styles.commentDate}>{moment(created).format('HH:mm / DD.MM.YYYY')}</div>
                          </div>
                          <div className={styles.commentBody}>
                            {text}
                          </div>
                        </div>
                      );
                    })
                }
              </div>
              <div className={styles.createComment}>
                <div className={styles.editComment}>
                  <div className={styles.createCommentUser}>{userProfile.attributes.name}</div>
                  <textarea
                    className={styles.createCommentText}
                    rows='5'
                    maxLength={1000}
                    placeholder={formatMessage({ id: 'photos.enterYourComment' })}
                    ref={(element) => {
                      this.photoComment = element;
                    }}
                  />
                </div>
                <BigButton
                  className={styles.createCommentButton}
                  onClick={this.handlerCommentSubmit}
                  title={<FormattedMessage id='photos.submit' />}
                  theme='dark'
                />
              </div>
            </div>
          )}

          {largeMode && (
            <div className={styles.photoDialogLarge}>
              <img alt='' src={selectedPhoto.attributes.originUrl} {...largeImgProps} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
