import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { reduxForm, Field } from 'redux-form';

import { isEmpty, trim } from 'lodash';

import BigButton from 'components/BigButton';
import ClickOutside from 'components/ClickOutside';
import DefaultTextInput from 'components/DefaultTextInput';
import DeleteIcon from 'components/Icons/Delete';
import CloseIcon from 'components/Icons/Close';
import CheckIcon from 'components/Icons/CheckIcon';

import styles from './NameEditorComponent.module.css';

const MAX_PLANT_NAME_LENGTH = 48;

const validate = (values, props) => {
  const { intl: { formatMessage }, nameEditorData, validatePlantName } = props;
  const {
    data: { rowIndex, columnIndex }
  } = nameEditorData;

  const errors = {};

  const isInvalidPlantName = validatePlantName(rowIndex, columnIndex, values.plantName);


  if (!values.plantName) {
    errors.plantName = formatMessage({ id: 'formErrors.requiredField' });
  }

  if (values.plantName?.length > MAX_PLANT_NAME_LENGTH) {
    errors.plantName = formatMessage({ id: 'formErrors.cannotBeExceedCharacters' }, { number: MAX_PLANT_NAME_LENGTH });
  }

  if (isInvalidPlantName) {
    errors.plantName = formatMessage({ id: 'measurements.plantNameError' });
  }

  return errors;
};

const renderDeletePopup = (formatMessage, deleteConfirmMessage, closePopup, onDeleteClick, isVarietyRemoving = false) => (
  <div className={styles.deletePopup}>
    <div>
      {deleteConfirmMessage}
    </div>
    <div className={styles.deleteButtons}>
      <BigButton
        className={styles.deleteCancel}
        onClick={closePopup}
        title={formatMessage({ id: 'harvest.cancelButton' })}
        theme='transparent'
      />
      <BigButton
        className={styles.deleteConfitm}
        onClick={onDeleteClick}
        title={formatMessage({ id: 'harvest.confirmDelete' })}
        theme='transparentRed'
        isLoading={isVarietyRemoving}
      />
    </div>
  </div>
);

const renderNameInput = (props) => {
  const {
    input,
    meta: { error },
  } = props;

  return (
    <DefaultTextInput
      {...input}
      theme='mini'
      className={styles.field}
      error={error}
    />
  );
};

const NameEditorComponent = ({
  intl,
  nameEditorData,
  setNameEditorData,
  requestUpdate,
  requestDelete,
  getCellInfo,
  formValues,
  formSyncErrors,
  deleteConfirmMessage,
}) => {
  const { formatMessage } = intl;

  const {
    isVisible, top, left, data
  } = nameEditorData;

  const { cellProps } = getCellInfo(data?.rowIndex, data?.columnIndex);

  const isDeleteAvailable = data?.isLastCell;

  const [dropdownStep, setDropdownStep] = useState('');

  if (!isVisible) {
    return null;
  }

  const handleClickOutside = () => {
    setDropdownStep('');
    setNameEditorData({
      isVisible: false,
      top: 0,
      left: 0,
      data: null,
    });
  };


  const handlerSubmitForm = (e) => {
    e.preventDefault();

    if (!formValues || !isEmpty(formSyncErrors)) {
      return null;
    }

    const { plantName } = formValues;

    return requestUpdate(cellProps, trim(plantName));
  };


  const handlerDeletePlant = () => requestDelete(cellProps);

  return (
    <ClickOutside
      handleClickOutside={handleClickOutside}
      // className={wrapperClassName}
    >
      <div
        className={styles.nameEditorComponent}
        style={{
          top,
          left,
        }}
      >
        {dropdownStep === 'delete' ?
          renderDeletePopup(formatMessage, deleteConfirmMessage, handleClickOutside, handlerDeletePlant)
          : (
            <>
              <form
                className={styles.form}
                onSubmit={handlerSubmitForm}
              >
                <Field
                  name='plantName'
                  component={renderNameInput}
                  className={styles.textInput}
                />
                <div className={styles.formButtons}>
                  <BigButton
                    className={styles.checkIcon}
                    // onClick={() => {}}
                    icon={<CheckIcon />}
                    theme='transparent'
                    type='submit'
                    tooltip={formatMessage({ id: 'button.save' })}
                    bigIcon
                  />
                  <BigButton
                    className={styles.closeIcon}
                    onClick={handleClickOutside}
                    icon={<CloseIcon />}
                    theme='transparent'
                    tooltip={formatMessage({ id: 'button.cancel' })}
                    bigIcon
                  />
                </div>
              </form>
              {!isDeleteAvailable && (
                <div className={styles.deleteButtonWrapper}>
                  <BigButton
                    className={styles.deleteButton}
                    onClick={() => setDropdownStep('delete')}
                    icon={<DeleteIcon />}
                    theme='menuItem'
                    title={formatMessage({ id: 'dialog.delete' })}
                  />
                </div>
              )}
            </>
          )}
      </div>
    </ClickOutside>
  );
};

NameEditorComponent.propTypes = {
  intl: intlShape.isRequired,
  nameEditorData: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  formSyncErrors: PropTypes.object,

  setNameEditorData: PropTypes.func.isRequired,
  requestUpdate: PropTypes.func.isRequired,
  requestDelete: PropTypes.func.isRequired,
  getCellInfo: PropTypes.func.isRequired,
  deleteConfirmMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
};

NameEditorComponent.defaultProps = {
  formValues: null,
  formSyncErrors: null,
};

export default memo(reduxForm({
  form: 'plantNameEditorForm',
  validate,
})(NameEditorComponent));
