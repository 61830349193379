import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  get, find, uniqueId, flatMapDeep
} from 'lodash';
import classnames from 'classnames';

import ChartNotSelectedIcon from '../../../Icons/ChartNotSelectedIcon';
import ChartSelectedIcon from '../../../Icons/ChartSelectedIcon';
import ArrowRightIcon from '../../../Icons/ArrowRightIcon';

import styles from './NestedTableCollapseRow.module.css';

const DEFAULT_PADDING = 12;
const ARROW_WIDTH_WITH_MARGIN = 24;

const getIsActiveRowGraphKey = (row, selectedRow) => {
  const toFlat = rows => flatMapDeep(rows, (item) => {
    if (Array.isArray(item.subRows)) {
      return [item, ...toFlat(item.subRows)];
    }
    return [item];
  });

  const flattenNodes = toFlat(get(row, 'subRows'));
  const activeRow = find(flattenNodes, { key: selectedRow });
  return get(activeRow, 'key');
};

export default class NestedTableCollapseRow extends PureComponent {
  static propTypes = {
    row: PropTypes.object.isRequired,
    selectedRow: PropTypes.string,
    onRowClick: PropTypes.func,
    nestingLevel: PropTypes.number,
  };

  static defaultProps = {
    selectedRow: null,
    onRowClick: null,
    nestingLevel: 0,
  };

  state = {
    opened: getIsActiveRowGraphKey(this.props.row, this.props.selectedRow) === this.props.selectedRow,
  };

  handlerRowClick = () => this.setState(prevState => ({ opened: !prevState.opened }));

  renderTableRow = (nestingLevel, row, hideArrow = false) => {
    if (!row) {
      return null;
    }

    const { selectedRow, onRowClick } = this.props;
    const { opened } = this.state;

    const nestingPadding = hideArrow ? 8 : 0; // Для последнего уровня вложенности Добавляем ещё отступ
    const paddingLeft = DEFAULT_PADDING + (nestingLevel * ARROW_WIDTH_WITH_MARGIN) + nestingPadding;

    return (
      <tr
        key={uniqueId('table-row-')}
        className={classnames(styles.row, row.className, {
          [styles.boldRow]: row.rowStyle === 'bold',
        })}
        onClick={hideArrow ? () => {} : this.handlerRowClick}
      >
        <td
          onClick={(e) => {
            if (onRowClick) {
              onRowClick({ row, e });
            }
            if (row.handlerRowClick) {
              row.handlerRowClick(e, row.key);
            }
          }}
          role='gridcell'
          tabIndex={0}
          className={styles.chartIconWrapper}
        >
          <div className={styles.chartIconContainer}>
            {selectedRow === row.key ? <ChartSelectedIcon /> : <ChartNotSelectedIcon />}
          </div>
        </td>
        <td
          className={classnames(styles.totalCell, row.labelClassName, {
            [styles.left]: row.labelAlign === 'left',
          })}
          style={{ paddingLeft }}
        >
          <div className={styles.nameWrapper}>
            {!hideArrow && (
              <div
                className={classnames(
                  styles.arrowRightIcon,
                  {
                    [styles.arrowIconRotate]: opened
                  }
                )}
              >
                <ArrowRightIcon />
              </div>
            )}
            {row.label}
          </div>
        </td>

        {row.columns && row.columns.map(column => (
          <td
            key={uniqueId('table-cell-')}
            className={classnames(styles.tdValue, column.className)}
          >
            {column.label}
          </td>
        ))}
      </tr>
    );
  };

  render() {
    const {
      row,
      onRowClick,
      selectedRow,
      nestingLevel,
    } = this.props;
    const { opened } = this.state;

    const subRows = get(row, 'subRows', []);

    return (
      <Fragment key={uniqueId('rowCollapseFragment-')}>
        {this.renderTableRow(nestingLevel, row, subRows.length === 0)}
        {opened && subRows.length > 0 ?
          row.subRows.map(subRow => (
            <NestedTableCollapseRow
              key={uniqueId('rowNestedCollapseFragment-')}
              row={subRow}
              selectedRow={selectedRow}
              onRowClick={onRowClick}
              nestingLevel={nestingLevel + 1}
            />
          ))
          :
          null}
      </Fragment>
    );
  }
}
