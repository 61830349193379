import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import {
  get, find, head, drop, isEmpty, dropRight, isNil, isString, isNaN
} from 'lodash';

import { getDatesArray } from '../../../../helpers/defaultDates';
import updateLocationSearch from '../../../../helpers/updateLocationSearch';
import storageWrapper from '../../../../helpers/storageWrapper';

import Typography from '../../../Typography';
import BigButton from '../../../BigButton';
import BackButton from '../../../BackButton';
import DefaultRangePicker from '../../../DefaultRangePicker';
import SaveChangesDialog from '../../../SaveChangesDialog';
import EnergyAddTable from '../EnergyAddTable';

import styles from './EnergyAdd.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');

const COL_WIDTHS = 168;
const SIDE_PADDING = 24;
const DEFAULT_MIN_WIDTH = 768;

export default class EnergyAdd extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    history: PropTypes.object.isRequired,

    organizationSlug: PropTypes.string.isRequired,
    resourceType: PropTypes.string.isRequired,
    timezone: PropTypes.string,
    isEnergyReportFetching: PropTypes.bool,
    isSaveRegistrationsFetching: PropTypes.bool,
    energyRegistrations: PropTypes.object,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
    isAddEnergyAvailable: PropTypes.bool.isRequired,

    requestEnergyRegistrations: PropTypes.func.isRequired,
    requestSaveRegistrations: PropTypes.func.isRequired,
    showNotificationWithTimeout: PropTypes.func.isRequired,
  };

  static defaultProps = {
    timezone: 'Europe/London',
    isEnergyReportFetching: false,
    isSaveRegistrationsFetching: true,
    energyRegistrations: null,
  };

  constructor(props) {
    super(props);

    this.tableDataEnter = React.createRef();
  }

  state = {
    // formErrors: [],
    stateTableData: null,
    isTableValid: true,
    isSaveChangesDialogVisible: false,
    actionAfter: undefined,
  };

  componentDidMount() {
    const {
      history,
      organizationSlug,
      isAddEnergyAvailable,
      resourceType,
      startDate,
      endDate,
      requestEnergyRegistrations,
    } = this.props;


    // Проверка прав пользователя
    if (!isAddEnergyAvailable) {
      history.push(`/${organizationSlug}/resources/energy`);
    }

    requestEnergyRegistrations({
      meterType: this.getMeterType(resourceType),
      startDate,
      endDate,
    });
  }

  getMeterType = (resourceType) => {
    switch (resourceType) {
      case 'gas':
        return 'gasMeter';
      case 'electricity':
        return 'electricityMeter';
      case 'water':
        return 'waterMeter';
      default:
        return 'unknown';
    }
  };

  getTableData = (registrationsMeters, registrationsRows) => {
    const {
      startDate,
      endDate,
    } = this.props;

    const { stateTableData } = this.state;

    if (stateTableData) {
      return stateTableData;
    }

    // Добавляем строку с Total
    const datesArray = [...getDatesArray(startDate, endDate), 'total'];

    const emptyValuesCols = registrationsMeters.reduce((acc, meter) => ({
      ...acc,

      [meter.id]: '',
    }), {});

    const prefilledWithDates = datesArray.map((currentDate) => {
      const currentRow = find(registrationsRows, { date: currentDate });

      if (currentRow) {
        return { date: currentDate, ...currentRow.values };
      }

      return { date: currentDate, ...emptyValuesCols };
    });

    return prefilledWithDates;
  };

  getTableContainerMaxWidth = (meters = []) => {
    if (meters.length > 3) {
      return (meters.length * COL_WIDTHS) + COL_WIDTHS + (SIDE_PADDING * 2);
    }

    return DEFAULT_MIN_WIDTH + (SIDE_PADDING * 2);
  };

  convertTableArray = (meters, tableData, isBackendFormat = false) => tableData.map((row) => {
    const date = head(row);
    const valuesList = drop(row);

    const values = valuesList.reduce((acc, listItem, index) => {
      const stringValue = !isNil(listItem) ? listItem.toString() : null;

      if (isEmpty(stringValue)) {
        return acc;
      }

      // TODO: Возможно, стоит убрать, т.к. дублирует логику трима в handleEnergyTableChange
      const trimmedValue = isString(listItem) ? listItem.replace(/\s+/g, '') : listItem;
      const numberValue = Number(trimmedValue);
      // Чтобы если человек ввёл текст, он отобразился с ошибкой
      const realValue = isNaN(numberValue) ? trimmedValue : numberValue;

      return {
        ...acc,
        [meters[index].id]: isBackendFormat ? numberValue : realValue,
      };
    }, {});

    if (isBackendFormat) {
      return {
        date,
        values,
      };
    }

    return {
      date,
      ...values,
    };
  });

  convertRegistrations = (startDate, endDate, meters, tableResults) => {
    const rows = this.convertTableArray(meters, tableResults, true);

    const filteredRows = rows.filter(filteredRow => !isEmpty(filteredRow.values));

    return {
      meterIds: meters.map(meter => meter.id),
      startDate,
      endDate,
      rows: filteredRows,
    };
  };

  handleAfterSuccess = () => {
    const {
      history,
      organizationSlug,
      resourceType,
      showNotificationWithTimeout
    } = this.props;

    showNotificationWithTimeout({
      id: 'energy.saveRegistrationsSuccess',
      messageId: 'energy.saveRegistrationsSuccess',
      position: 'leftDown',
      iconType: 'error',
      notificationType: 'withActionWide',
    });

    history.push(`/${organizationSlug}/resources/energy/${resourceType}`);
  }

  handleSave = (meters) => {
    const {
      startDate,
      endDate,
      requestSaveRegistrations,
    } = this.props;

    const { isTableValid } = this.state;

    if (!isTableValid) {
      return null;
    }

    const tableInstance = this.tableDataEnter.current.hotInstance;
    const tableResults = tableInstance.getData();
    // Удаляем строку с значениями Total
    const tableResultsWithoutTotal = dropRight(tableResults);
    const converdedResults = this.convertRegistrations(startDate, endDate, meters, tableResultsWithoutTotal);

    return requestSaveRegistrations({
      registrations: converdedResults,
      actionAfterSuccess: this.handleAfterSuccess,
    });


    // Валидация данных
    // const rowsArray = tableResultsWithoutTotal.reduce((acc, item, index) => ([...acc, index]), []);
    // tableInstance.validateRows(rowsArray, (valid) => {
    //   if (!valid) {
    //     return null;
    //   }

    //   return requestSaveRegistrations({
    //     registrations: converdedResults,
    //     actionAfterSuccess: this.handleAfterSuccess,
    //   });
    // });
  };

  // handleCancel = () => {};

  showDialog = ({ actionAfter }) => this.setState({ isSaveChangesDialogVisible: true, actionAfter });

  handleOnRangeSelect = (newParams) => {
    const { stateTableData } = this.state;

    // Добавить проверку isEqual
    if (stateTableData) {
      return this.showDialog({ actionAfter: () => this.handleAfterRangeSelect(newParams) });
    }

    return this.handleAfterRangeSelect(newParams);
  };

  handleAfterRangeSelect = (newParams) => {
    const {
      resourceType,
      startDate,
      endDate,
      requestEnergyRegistrations,
    } = this.props;

    const newPeriodType = get(newParams, 'periodType');

    if (newPeriodType) {
      safeLocalStorage.setItem('energyAddPeriodType', newPeriodType);
    }

    updateLocationSearch(newParams);

    const dateParams = {
      startDate: get(newParams, 'anyDateOfPeriodStart', startDate),
      endDate: get(newParams, 'anyDateOfPeriodEnd', endDate)
    };

    return requestEnergyRegistrations({
      meterType: this.getMeterType(resourceType),
      ...dateParams,
    });
  };

  handleChangeTableValues = async (changesArray) => {
    const tableInstance = this.tableDataEnter.current.hotInstance;
    const oldTableData = tableInstance.getData();

    const newTableData = [...oldTableData];

    if (tableInstance) {
      /* eslint-disable no-restricted-syntax, no-unused-vars */
      for (const [row, column, oldValue, newValue] of changesArray) {
        // Т.к. у нас prop отличается от номера col (берётся по data атрибуту колонки)
        const colNum = tableInstance.propToCol(column);

        if (newTableData[row]) {
          newTableData[row][colNum] = newValue;
        }
      }
      /* eslint-enable no-restricted-syntax, no-unused-vars */

      return newTableData;
    }

    return null;
  };

  handleEnergyTableChange = async (meters, changesArray) => {
    // changesArray передаётся как [row, prop, oldVal, newVal]
    changesArray.forEach((change) => {
      /**
       * Вырезаем спецсимволы перед изменением ячейки (чтобы не вставлялись переносы строк и прочее подобное)
       * Затем вырезаем пробелы
       */
      const pastedValue = change[3];
      const trimmedValue = isString(pastedValue) ? pastedValue.replace(/^\s+|\s+$/g, '').replace(/\s/g, '') : pastedValue;

      /**
       * Меняем сам передаваемый массив changesArray (по ссылке), а не возвращаем новый, т.к.
       * handsontable по-другому не умеет, нужно именно менять передаваемый массив изменений
       */
      // eslint-disable-next-line no-param-reassign
      change[3] = trimmedValue;
    });

    const tableData = await this.handleChangeTableValues(changesArray);

    const formattedTableData = this.convertTableArray(meters, tableData);

    const tableInstance = this.tableDataEnter.current.hotInstance;

    // Оборачиваем в setTimeout, т.к. иначе hotInstance будет валидироваться до конвертации значений string в number
    setTimeout(() => {
      tableInstance.validateCells(isValid => this.setState({
        isTableValid: isValid
      }));
    }, 0);


    this.setState({
      stateTableData: formattedTableData,
    });
  };

  render() {
    const {
      intl,
      intl: { formatMessage },

      organizationSlug,
      resourceType,
      timezone,
      isEnergyReportFetching,
      isSaveRegistrationsFetching,
      energyRegistrations,

      startDate,
      endDate,
      periodType,
    } = this.props;

    const { isSaveChangesDialogVisible, actionAfter } = this.state;

    // Строим заголовки таблицы
    const registrationsMeters = get(energyRegistrations, 'meters', []);

    // Строим данные таблицы
    const registrationsRows = get(energyRegistrations, 'rows', []);
    const tableData = this.getTableData(registrationsMeters, registrationsRows);

    /**
     * Задаём сами, т.к. иначе при маленьком количестве столбцов не будет центровки таблицы
     */
    const tableContainerMaxWidth = this.getTableContainerMaxWidth(registrationsMeters);

    return (
      <div
        className={styles.energyAddContainer}
        style={{
          maxWidth: `${tableContainerMaxWidth}px`,
          paddingLeft: SIDE_PADDING,
          paddingRight: SIDE_PADDING,
        }}
      >
        <BackButton
          link={`/${organizationSlug}/resources/energy/${resourceType}`}
          text={formatMessage({ id: 'energy.energyResources' })}
        />

        <div className={styles.controls}>
          <Typography variant='h2' className={styles.header}>
            {formatMessage({ id: `energy.addHeader.${resourceType}` })}
          </Typography>

          <DefaultRangePicker
            intl={intl}
            className={styles.rangePicker}
            timezone={timezone}
            handlerAfterRangeSelect={this.handleOnRangeSelect}
            periodType={periodType}
            anyDateOfPeriodStart={startDate}
            anyDateOfPeriodEnd={endDate}
            byOneDate
          />
        </div>

        <EnergyAddTable
          tableRef={this.tableDataEnter}
          isFetching={isEnergyReportFetching}
          energyRegistrations={energyRegistrations}
          tableData={tableData}
          colWidths={COL_WIDTHS}
          handleTableChange={changes => this.handleEnergyTableChange(registrationsMeters, changes)}
        />

        <div className={styles.buttons}>
          <BigButton
            className={styles.cancelBtn}
            href={`/${organizationSlug}/resources/energy/${resourceType}`}
            // onClick={this.handleCancel}
            title={formatMessage({ id: 'button.cancel' })}
            theme='light'
            disabled={isSaveRegistrationsFetching}
          />
          <BigButton
            className={styles.saveBtn}
            onClick={() => this.handleSave(registrationsMeters)}
            title={formatMessage({ id: `energy.saveEnergy.${resourceType}` })}
            theme='dark'
            isLoading={isSaveRegistrationsFetching}
          />
        </div>

        {isSaveChangesDialogVisible && (
          <SaveChangesDialog
            handlerCloseDialog={() => this.setState({ isSaveChangesDialogVisible: false, actionAfter: undefined })}
            handlerDontSave={() => {
              actionAfter();

              this.setState({
                isSaveChangesDialogVisible: false, stateTableData: null, isTableValid: true, actionAfter: undefined
              });
            }}
            handlerSave={() => {
              this.handleSave(registrationsMeters);

              this.setState({ isSaveChangesDialogVisible: false, actionAfter: undefined });
            }}
          />
        )}
      </div>
    );
  }
}
