import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage, intlShape } from 'react-intl';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import moment from 'moment-timezone';
import uuid from 'uuid/v4';
import ReactTooltip from 'react-tooltip';

import DefaultCircleLoader from 'components/DefaultCircleLoader';
import SelectBoxFilter from 'components/SelectBoxFilter';
import PageViewTracker from 'components/PageViewTracker';

import { getProductTypeName } from 'helpers/getVarietyName';
import LINE_COLORS from 'helpers/harvestColors';
import addGetParameters from 'helpers/addGetParameters';
import getDateFormat from 'helpers/getDateFormat';

import PlusIcon from 'components/Icons/Plus';
import CloseIcon from 'components/Icons/Close';
import CropCompareIcon from 'components/Icons/CropCompareIcon';

import BenchmarkTimeline from '../../../BenchmarkDashboard/components/BenchmarkTimeline';
import BenchmarkCompareTable from '../BenchmarkCompareTable/BenchmarkCompareTable';
import BenchmarkMetricsChangeDialog from '../BenchmarkMetricsChangeDialog';
import BackButton from '../../../BackButton';

import BenchmarkAddCycleDialog from '../BenchmarkAddCycleDialog';
import BenchmarkCompareSection from '../BenchmarkCompareSection';
import BenchmarkHarvestChart from '../BenchmarkHarvestChart';
import Typography from '../../../Typography';
import BenchmarkMetrics from '../BenchmarkMetrics/BenchmarkMetrics';
import DropdownMenu from '../../../DropdownMenu';
import BigButton from '../../../BigButton';
import EditIcon from '../../../Icons/Edit';

import styles from './BenchmarkCompare.module.css';
import tooltipStyles from '../../../Tooltip/index.module.css';
import isTouchDevice from '../../../../helpers/isTouchDevice';
import Legend from '../../../Legend';


class BenchmarkCompare extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    comparison: PropTypes.object,
    metrics: PropTypes.object,
    fromPage: PropTypes.string,
    isCropCompareAvailable: PropTypes.bool,
    isBenchmarkComparisonFetching: PropTypes.bool,
    requestBenchmarkComparison: PropTypes.func.isRequired,
    requestBenchmarkComparisonMetrics: PropTypes.func.isRequired,
    removeCycleFromComparison: PropTypes.func.isRequired,
    removeAllCyclesFromComparison: PropTypes.func.isRequired,
    requestDeleteBenchmarkComparisonMetrics: PropTypes.func.isRequired,
    requestUpdateBenchmarkComparisonMetrics: PropTypes.func.isRequired,
    trackPageViewCropCyclesCompare: PropTypes.func.isRequired,

    cyclesToCompare: PropTypes.array,
  };

  static defaultProps = {
    comparison: null,
    metrics: null,
    fromPage: undefined,
    cyclesToCompare: [],
    isBenchmarkComparisonFetching: false,
    isCropCompareAvailable: false,
  };

  state = {
    isOpenChangeMetricsDialog: false,
    alignedBy: 'calendar',
    screenX: 0,
    screenY: 0,
    isShow: false,
    xCoord: 0,
    uid: uuid(),
    isOpenAddCycleDialog: false,
  };

  componentDidMount() {
    const {
      match, history, requestBenchmarkComparison, requestBenchmarkComparisonMetrics, cyclesToCompare, isCropCompareAvailable
    } = this.props;

    const {
      alignedBy
    } = this.state;

    if (!isCropCompareAvailable) {
      history.push(`/${match.params.organizationSlug}/crops`);
    } else if (cyclesToCompare.length > 0) {
      requestBenchmarkComparison({ cyclesToCompare, compareType: alignedBy });
      requestBenchmarkComparisonMetrics({ cyclesToCompare });
    }
  }

  changeTimelinePeriod = (alignedBy) => {
    const {
      requestBenchmarkComparison, requestBenchmarkComparisonMetrics, cyclesToCompare, history
    } = this.props;
    this.setState({ alignedBy });

    const newParameters = {
      compareType: alignedBy,
    };

    const oldQuery = get(history, 'location.search', '');
    const searchString = addGetParameters(oldQuery, newParameters);

    history.push({
      search: searchString,
    });

    requestBenchmarkComparison({ cyclesToCompare, compareType: alignedBy });
    requestBenchmarkComparisonMetrics({ cyclesToCompare });
  }

  updatePageData = (cyclesToCompare) => {
    const {
      requestBenchmarkComparison, requestBenchmarkComparisonMetrics, history
    } = this.props;

    if (cyclesToCompare.length > 0) {
      const newParameters = {
        productId: cyclesToCompare.join('_'),
      };

      const oldQuery = get(history, 'location.search', '');
      const searchString = addGetParameters(oldQuery, newParameters);

      history.push({
        search: searchString,
      });

      requestBenchmarkComparison({ cyclesToCompare });
      requestBenchmarkComparisonMetrics({ cyclesToCompare });
    }
  };

  getBackLink = () => {
    const { match } = this.props;

    return `/${match.params.organizationSlug}/dashboards/benchmarking`;
  };

  handlerUpdateMetrics = () => {
    const { requestUpdateBenchmarkComparisonMetrics } = this.props;

    requestUpdateBenchmarkComparisonMetrics();

    this.setState({ uid: uuid() });
  };

  handlerDeleteMetrics = () => {
    const { cyclesToCompare, requestDeleteBenchmarkComparisonMetrics } = this.props;

    requestDeleteBenchmarkComparisonMetrics({ cyclesToCompare });

    this.setState({ uid: uuid() });
  };

  handlerChangeMetricsDialogOpen = () => {
    this.setState({ isOpenChangeMetricsDialog: true });
  };

  handlerChangeDialogClose = () => {
    this.setState({ isOpenChangeMetricsDialog: false });
  };

  handlerChangeDialogSave = ({ graphMetrics }) => {
    const { cyclesToCompare, history, requestBenchmarkComparison } = this.props;

    const newParameters = {
      graphMetricId: graphMetrics.join('_'),
    };

    const oldQuery = get(history, 'location.search', '');
    const searchString = addGetParameters(oldQuery, newParameters);

    history.push({
      search: searchString,
    });

    this.handlerChangeDialogClose();

    requestBenchmarkComparison({ cyclesToCompare });
  };

  handlerTooltipChanged = (options) => {
    this.setState(options);
  };

  handlerClearAll = () => {
    const { removeAllCyclesFromComparison, history } = this.props;

    history.push({
      search: null,
    });

    removeAllCyclesFromComparison();
  };

  handlerAddCropCycle = () => this.setState({ isOpenAddCycleDialog: true });

  generateLines = () => {
    const { intl, comparison } = this.props;

    const products = get(comparison, 'products');

    return products.map((product, i) => {
      const organizationName = get(product, 'location.name', '');

      const productTypeName = getProductTypeName({
        intl,
        variety: get(product, 'variety'),
        fruitClass: get(product, 'fruitClass'),
        directPath: true,
      });

      const startDate = get(product, 'startDate');
      const endDate = get(product, 'endDate');

      const startDateMoment = moment(startDate).format(getDateFormat('lll'));
      const endDateMoment = endDate ? moment(endDate).format(getDateFormat('lll')) : intl.formatMessage({ id: 'cycle.present' });

      return ({
        key: get(product, 'id'),
        name: `${organizationName}, ${productTypeName}, ${startDateMoment}—${endDateMoment}, ${product?.compartment?.name}`,
        color: LINE_COLORS[i],
      });
    });
  };

  renderContent = () => {
    const {
      intl,
      comparison,
      removeCycleFromComparison,
      requestBenchmarkComparison,
      isBenchmarkComparisonFetching,
    } = this.props;

    if (isBenchmarkComparisonFetching) {
      return <DefaultCircleLoader />;
    }

    if (!comparison || !comparison.products) {
      return null;
    }

    const {
      alignedBy,
      isShow,
      screenY,
      screenX,
      // lines: tooltipLines,
      xCoord,
      uid,
    } = this.state;

    const { formatMessage } = intl;

    const products = get(comparison, 'products');

    if (!comparison || !comparison.products) {
      return null;
    }

    const alignedByOptions = [
      { label: formatMessage({ id: 'benchmarking.cycleWeeks' }), value: 'cycleWeeks' },
      { label: formatMessage({ id: 'benchmarking.alignedYears' }), value: 'years' },
      { label: formatMessage({ id: 'benchmarking.calendar' }), value: 'calendar' },
    ];

    const lines = this.generateLines();

    const {
      descriptor: {
        periods,
        // compareType
      }
    } = comparison;

    const serialNumber = +xCoord.toFixed(0);
    const period = periods.find(item => item.serialNumber === +serialNumber);

    const tooltipState = {
      isShow,
      screenY,
      screenX,
      xCoord,
      period,
      serialNumber
    };

    return (
      <>
        <BenchmarkCompareTable
          comparison={comparison}
          removeCycleFromComparison={removeCycleFromComparison}
          requestBenchmarkComparison={requestBenchmarkComparison}
        />
        <BenchmarkCompareSection
          headerVariant='h2'
          header={<FormattedMessage id='benchmarking.analysis' />}
          wrapperClassName={styles.analysisSection}
          className={styles.analysisSection}
          controls={(
            <SelectBoxFilter
              value={alignedBy}
              options={alignedByOptions}
              onChange={({ value }) => this.changeTimelinePeriod(value)}
              title={formatMessage({ id: 'benchmarking.compareBy' })}
            />
          )}
        />
        <BenchmarkCompareSection
          className={styles.growingDetailsSection}
          wrapperClassName={styles.growingDetailsSectionWrapper}
          header={<FormattedMessage id='benchmarking.alignmentInTime' />}
        >
          <div>
            <BenchmarkTimeline
              data={products}
              comparison={comparison}
              alignedBy={alignedBy}
              colors={LINE_COLORS}
              forceColor
            />
            <Legend lines={lines} />
          </div>
        </BenchmarkCompareSection>

        <BenchmarkCompareSection
          className={styles.harvestSection}
          wrapperClassName={styles.harvestSectionWrapper}
          header={<FormattedMessage id='benchmarking.harvest' />}
          controls={(
            <div>
              {isShow && period ? (
                <Typography variant='body2'>
                  <b>
                    <FormattedMessage id='tables.week.one' />&nbsp;{period.weekNumber}:&nbsp;
                  </b>
                  {products
                    .map((product) => {
                      const productPeriods = product?.descriptor?.periods || [];
                      const productPeriod = productPeriods.find(item => item.serialNumber === +serialNumber);
                      let hintDate = formatMessage({ id: 'benchmarking.noDates' });
                      if (productPeriod && product.harvest) {
                        const startDateMoment = moment(productPeriod.startDate).clone().startOf('week').format(getDateFormat('lll'));
                        const endDateMoment = moment(productPeriod.endDate).clone().endOf('week').format(getDateFormat('lll'));
                        hintDate = `${startDateMoment}—${endDateMoment}`;
                      }
                      return {
                        key: get(product, 'id'),
                        hintDate,
                      };
                    })
                    .map((line, i) => <span key={`timestamp-${get(line, 'key', '')}`}>{i > 0 ? ' | ' : ''}{get(line, 'hintDate', '')}</span>)}
                </Typography>
              ) : null}
            </div>
          )}
        >
          <BenchmarkHarvestChart
            isDataFetching={false}
            comparison={comparison}
            periodType='weekLight'
            onTooltipChanged={this.handlerTooltipChanged}
            tooltipState={tooltipState}
          />
        </BenchmarkCompareSection>

        <BenchmarkCompareSection
          header={<FormattedMessage id='benchmarking.metrics' />}
          wrapperClassName={styles.metricsSectionWrapper}
          controls={(
            <div className={styles.row}>
              <BigButton
                className={styles.marginRight}
                onClick={this.handlerChangeMetricsDialogOpen}
                icon={<EditIcon />}
                title={formatMessage({ id: 'benchmarking.changeMetrics' })}
                theme='transparent'
              />
              <DropdownMenu className={classnames(styles.iconButton)} buttonSize='small' isMini key={`dropdown-metric-${uid}`}>
                <div>
                  <BigButton
                    className={styles.dropdownItem}
                    onClick={this.handlerUpdateMetrics}
                    theme='menuItem'
                    title={<FormattedMessage id='benchmarking.saveMetrics' />}
                  />
                  <BigButton
                    className={styles.dropdownItem}
                    onClick={this.handlerDeleteMetrics}
                    theme='menuItem'
                    title={<FormattedMessage id='benchmarking.resetMetrics' />}
                  />
                </div>
              </DropdownMenu>
            </div>
          )}
        >
          <BenchmarkMetrics
            isDataFetching={false}
            comparison={comparison}
            periodType='weekLight'
            onTooltipChanged={this.handlerTooltipChanged}
            products={products}
            tooltipState={tooltipState}
          />
        </BenchmarkCompareSection>
      </>
    );
  };

  renderEmptyState = () => (
    <div className={styles.emptyState}>
      <div className={styles.emptyStateInfo}>
        <CropCompareIcon className={styles.compareIcon} />
        <div className={styles.emptyStateTitle}>
          <FormattedMessage id='benchmarking.noCropsToCompare' />
        </div>
        <div className={styles.emptyStateDescription}>
          <FormattedMessage id='benchmarking.emptyStateDescription' />
          &nbsp;
          <button
            type='button'
            className={styles.emptyStateButton}
            onClick={this.handlerAddCropCycle}
          >
            <FormattedMessage id='benchmarking.addCropCycles' />
          </button>
          .
        </div>
      </div>
    </div>
  );

  render() {
    const {
      intl,
      metrics,
      comparison,
      fromPage,
      cyclesToCompare,
      trackPageViewCropCyclesCompare,
    } = this.props;

    const {
      isOpenChangeMetricsDialog,
      isOpenAddCycleDialog,
    } = this.state;

    const { formatMessage } = intl;

    const fromBenchmarkPage = fromPage === 'benchmarking';

    return (
      <>
        <PageViewTracker onMount={trackPageViewCropCyclesCompare} />

        <div
          className={styles.layout}
        >
          <div className={styles.contentWrapper}>
            <div className={styles.header}>
              <div className={styles.controls}>
                {fromBenchmarkPage && (
                  <BackButton
                    className={styles.backButton}
                    link={this.getBackLink()}
                    text={formatMessage({ id: 'benchmarking.compareBack' })}
                  />
                )}
              </div>

              <div className={styles.titleGroup}>
                <Typography variant='h1' className={styles.title}>
                  {formatMessage({ id: 'benchmarking.compareTitle' })}
                </Typography>
                <div className={styles.titleButtons}>
                  <BigButton
                    className={styles.titleButton}
                    icon={<PlusIcon />}
                    title={formatMessage({ id: 'benchmarking.addCropCycles' })}
                    theme='transparent'
                    onClick={this.handlerAddCropCycle}
                  />
                  <BigButton
                    className={styles.titleButton}
                    icon={<CloseIcon />}
                    title={formatMessage({ id: 'benchmarking.clearAll' })}
                    theme='transparent'
                    onClick={this.handlerClearAll}
                  />
                </div>
              </div>
            </div>
            <div className={styles.content}>
              {cyclesToCompare.length > 0 ?
                this.renderContent()
                :
                this.renderEmptyState()}
            </div>
          </div>

          {isOpenChangeMetricsDialog && (
            <BenchmarkMetricsChangeDialog
              comparison={comparison}
              metrics={metrics}
              handlerCancel={this.handlerChangeDialogClose}
              handlerOk={this.handlerChangeDialogSave}
            />
          )}

          {isOpenAddCycleDialog && (
            <BenchmarkAddCycleDialog
              onClose={() => this.setState({ isOpenAddCycleDialog: false })}
              getNewData={this.updatePageData}
            />
          )}

          <ReactTooltip
            className={classnames(tooltipStyles.smallTooltip, styles.tooltip)}
            id='compare-tooltip'
            effect='solid'
            place='right'
            event={isTouchDevice() ? 'click' : null}
            html
          />
        </div>
      </>
    );
  }
}

export default withRouter(BenchmarkCompare);
