import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getFormValues, getFormSyncErrors } from 'redux-form';
import { withRouter } from 'react-router';

import {
  getOrganizationSlug,
  getAllVarieties,
  getDefaultOpenFromRoute
} from 'store/company/selectors';
import { trackPageViewSettings } from 'store/company/actions';

import { getDefaultNumberOfPlants, getIsDefaultNumberOfPlantsFetching, getManualFormsMetrics } from 'store/measurements/selectors';
import { requestDefaultNumberOfPlantsUpdate, requestDefaultNumberOfPlants } from 'store/measurements/actions';

import SettingsMeasurements from '../components/SettingsMeasurements';

const mapDispatchToProps = {
  requestDefaultNumberOfPlantsUpdate,
  requestDefaultNumberOfPlants,
  trackPageViewSettings,
};

const mapStateToProps = (state, props) => ({
  ...props,

  organizationSlug: getOrganizationSlug(state, props),
  formValues: getFormValues('plantsToEnterForm')(state),
  formSyncErrors: getFormSyncErrors('plantsToEnterForm')(state),
  isSettingsFetching: getIsDefaultNumberOfPlantsFetching(state, props),
  initialValues: {
    plantsToEnter: getDefaultNumberOfPlants(state, props),
  },
  manualFormsMetrics: getManualFormsMetrics(state, props),
  varieties: getAllVarieties(state, props),
  defaultOpen: getDefaultOpenFromRoute(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsMeasurements)));
