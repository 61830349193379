import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import { get, isEmpty } from 'lodash';

import { ANIMATION_DURATION } from '../../../../helpers/constants';

import BigButton from '../../../BigButton';
import DropdownMenu from '../../../DropdownMenu';
import Plate from '../Plate';
import RateIncidentButtonForm from '../RateIncidentButtonForm';

import StarIcon from '../../../Icons/StarIcon';
import StarCheckedIcon from '../../../Icons/StarCheckedIcon';
import RateIcon from '../../../Icons/RateIcon';
import ErrorIcon from '../../../Icons/ErrorIcon';
import SuccessIcon from '../../../Icons/SuccessIcon';

import styles from './RateIncidentButton.module.css';

const RATES = [1, 2, 3, 4, 5];

class RateIncidentButton extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    activeIncidentId: PropTypes.number.isRequired,

    formValues: PropTypes.object,
    feedback: PropTypes.object,
    sendingError: PropTypes.object,
    successfulSending: PropTypes.bool,
    isFeedbackSendingFetching: PropTypes.bool,
    withoutText: PropTypes.bool,

    requestFeedbackSending: PropTypes.func.isRequired,
    clearFeedbackSending: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formValues: null,
    feedback: null,
    sendingError: null,
    successfulSending: false,
    isFeedbackSendingFetching: false,
    withoutText: false,
  };

  state = {
    newFeedbackRate: undefined,
    closeDropdown: false,
  };

  getIsFormValuesNotEmpty = () => {
    const { formValues } = this.props;
    const { newFeedbackRate } = this.state;

    return formValues && newFeedbackRate && formValues.rateIncidentReason;
  };

  clearFeedbackSendingWithDelay = () => {
    const { clearFeedbackSending } = this.props;

    setTimeout(clearFeedbackSending, ANIMATION_DURATION);
  }

  handlerRateIncidentClick = () => this.setState({ closeDropdown: false });

  handlerDontSave = (e) => {
    e.preventDefault();

    this.setState({ closeDropdown: true });

    this.clearFeedbackSendingWithDelay();
  };

  handlerOnClickOutside = () => this.clearFeedbackSendingWithDelay();

  handlerRateClick = rate => this.setState({ newFeedbackRate: rate });

  handlerActionAfterSuccess = () => setTimeout(() => {
    this.setState({ closeDropdown: true, newFeedbackRate: undefined });

    this.clearFeedbackSendingWithDelay();
  }, 5000);

  handlerSubmitForm = () => {
    const {
      formValues,
      requestFeedbackSending,
      activeIncidentId,
    } = this.props;

    const isFormEmpty = !this.getIsFormValuesNotEmpty();

    if (isFormEmpty) {
      return null;
    }

    const { newFeedbackRate } = this.state;
    const { rateIncidentReason } = formValues;

    return requestFeedbackSending({
      incidentId: activeIncidentId,
      score: newFeedbackRate,
      comment: rateIncidentReason,
      actionAfterSuccess: this.handlerActionAfterSuccess,
    });
  };


  renderButton = () => {
    const { intl: { formatMessage }, feedback, withoutText } = this.props;

    const buttonText = feedback ? formatMessage({ id: 'feedback.viewScore' }) : formatMessage({ id: 'feedback.rateButton' });
    const buttonIcon = feedback ? <StarCheckedIcon className={styles.starIcon} /> : <StarIcon className={styles.starIcon} />;

    return (
      <BigButton
        className={styles.rateIncidentButton}
        titleClassName={styles.rateIncidentButtonTitle}
        icon={buttonIcon}
        title={!withoutText ? buttonText : ''}
        onClick={this.handlerRateIncidentClick}
        theme='transparent'
      />
    );
  };

  renderContent = () => {
    const {
      intl: { formatMessage }, feedback, successfulSending,
      intl, isFeedbackSendingFetching,
    } = this.props;
    const { newFeedbackRate } = this.state;

    const feedbackScore = get(feedback, 'score', newFeedbackRate);

    if (successfulSending) {
      return (
        <div className={styles.successfulSending}>
          <Plate
            icon={<SuccessIcon />}
            header={formatMessage({ id: 'feedback.successHeader' })}
            subHeader={formatMessage({ id: 'feedback.successSubHeader' })}
          />
        </div>
      );
    }

    return (
      <div className={styles.rateIncidentPopup}>
        <div className={styles.rateIncidentPopupHeader}>
          <RateIcon className={styles.rateIcon} />
          <h3 className={styles.rateIncidentPopupHeaderText}>
            {formatMessage({ id: 'feedback.popupHeader' })}
          </h3>
        </div>
        <div className={styles.rateIncidentPopupScale}>
          <div className={styles.rateScale}>
            {RATES.map(rate => (
              <BigButton
                key={`rateButton-${rate}`}
                className={classnames(
                  styles.rateNumButton,
                  {
                    [styles.active]: feedbackScore === rate
                  },
                )}
                onClick={() => this.handlerRateClick(rate)}
                title={rate}
                theme='light'
                disabled={!isEmpty(feedback)}
              />
            ))}
          </div>
          <div className={styles.rateDescription}>
            <div className={styles.rateDescriptionText}>{formatMessage({ id: 'feedback.bad' })}</div>
            <div className={styles.rateDescriptionText}>{formatMessage({ id: 'feedback.good' })}</div>
          </div>
        </div>
        {(feedback || newFeedbackRate) && (
          <RateIncidentButtonForm
            intl={intl}
            feedback={feedback}
            isFeedbackSendingFetching={isFeedbackSendingFetching}
            handlerDontSave={this.handlerDontSave}
            handlerSubmitForm={this.handlerSubmitForm}
          />
        )}
      </div>
    );
  };

  renderSendingError = () => {
    const { intl: { formatMessage }, isFeedbackSendingFetching } = this.props;

    return (
      <div className={styles.sendingError}>
        <Plate
          icon={<ErrorIcon />}
          header={formatMessage({ id: 'feedback.errorHeader' })}
          subHeader={formatMessage({ id: 'feedback.errorSubHeader' })}
        />
        <div>
          <div className={styles.sendingErrorButtons}>
            <BigButton
              className={styles.cancel}
              onClick={this.handlerDontSave}
              title={formatMessage({ id: 'dialog.close' })}
              theme='light'
            />
            <BigButton
              className={styles.confitm}
              title={formatMessage({ id: 'button.tryAgain' })}
              theme='dark'
              type='submit'
              onClick={this.handlerSubmitForm}
              isLoading={isFeedbackSendingFetching}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { sendingError } = this.props;
    const { closeDropdown } = this.state;

    return (
      <DropdownMenu
        closeDropdown={closeDropdown}
        className={styles.dropdownMenu}
        buttonClassName={styles.dropdownMenuButton}
        wrapperClassName={styles.dropdownMenuWrapper}
        contentClassName={styles.dropdownWrapper}
        buttonElement={this.renderButton()}
        afterClickOutside={this.handlerOnClickOutside}
      >
        {sendingError ?
          this.renderSendingError()
          :
          this.renderContent()}
      </DropdownMenu>
    );
  }
}

export default RateIncidentButton;
