import {
  find, first, get, head, indexOf, isFinite
} from 'lodash';

import { createSelector } from 'reselect';
import queryString from 'query-string';

import storageWrapper from 'helpers/storageWrapper';

import {
  checkAddEnergyPermission,
  checkAddHarvestPermission,
  checkAddLaborPermission,
  checkAddManualObservationPermission,
  checkAddPhotoPermission,
  checkAddPlantingCyclePermission,
  checkBenchmarkPermission,
  checkAddOperationalPlansPermission,
  checkAddBudgetPlansPermission,
  checkAddHarvestForecastPermission,
  checkAbnormalityIncidentRegisterPermission,
} from 'helpers/checkPermissions';

import getWeeks from 'helpers/getWeeks';

import { getPhotoCategories } from 'store/photos/selectors';

const safeLocalStorage = storageWrapper.get('localStorage');


export const getRouteLocation = (state, props) => props.location;
export const getRouteQueryParams = createSelector(
  [getRouteLocation],
  location => queryString.parse(get(location, 'search')),
);

export const getIntl = (state, props) => props.intl;
export const getMatch = (state, props) => props.match;

export const getLocation = state => state.company.location || {};
export const getAllVarieties = state => state.company.varieties || [];
export const getAllFruitClasses = state => state.company.fruitClasses || [];
export const getAllCompartments = state => state.company.allCompartments || [];
export const getAllProductGroups = state => state.company.productGroups || [];
export const getAllSubNodes = state => state.company.allSubNodes || [];
export const allGraphMetricsSelector = state => state.company.allGraphMetrics;
export const getAllPlantingCycles = state => state.company.allPlantingCycles || [];
export const getAllGreenhouseTypes = state => state.company.allGreenhouseTypes || [];
export const getCurrentOrganization = state => state.company.location;
export const getPermissions = state => state.company.permissions;

export const getAllCompartmentsFetching = state => state.company.isAllCompartmentsFetching;
export const getAllPlantingCyclesFetching = state => state.company.isAllPlantingCyclesFetching;
export const getVarietiesFetching = state => state.company.isVarietiesFetching;
export const getIsProductGroupsFetching = state => state.company.isProductGroupsFetching;
export const getIsOrganizationFetching = state => state.company.isLocationFetching;
export const getIsPermissionsFetching = state => state.company.isPermissionsFetching;

export const getLeftPlantingCycle = state => state.company.leftPlantingCycle;
export const getLeftCompartment = state => state.company.leftCompartment;

export const getUserProfile = state => get(state, 'company.user');

export const getAllLocationPlans = state => state.company.allLocationPlans || [];
export const getIsAllLocationPlansFetching = state => state.company.isAllLocationPlansFetching;
export const getLocationPlans = state => state.company.locationPlans || [];
export const getIsLocationPlansFetching = state => state.company.isVarietiesFetching;
export const getCurrentLocationPlan = state => state.company.currentLocationPlan;

export const getLocationMetrics = state => state.company.metrics || [];
export const getIsLocationMetricsFetching = state => state.company.isMetricsFetching;

export const getBenchmarkAllProducts = state => state.company.benchmarkAllProducts;
export const getBenchmarkAllLocations = state => state.company.benchmarkAllLocations;
export const getBenchmarkAllCompartments = state => state.company.benchmarkAllCompartments;
export const getBenchmarkAllFruitClasses = state => state.company.benchmarkAllFruitClasses;
export const getBenchmarkAllVarieties = state => state.company.benchmarkAllVarieties;

export const getUserName = createSelector(
  [getUserProfile],
  userProfile => get(userProfile, 'attributes.name'),
);

export const getUserEmail = createSelector(
  [getUserProfile],
  userProfile => get(userProfile, 'attributes.email'),
);

export const getUserFactor2 = createSelector(
  [getUserProfile],
  userProfile => userProfile?.attributes?.factor2,
);

export const getUserPhone = createSelector(
  [getUserProfile],
  userProfile => userProfile?.attributes?.phone,
);

export const getFetching = createSelector(
  [getIsOrganizationFetching, getAllCompartmentsFetching, getAllPlantingCyclesFetching, getVarietiesFetching, getAllCompartments, getAllPlantingCycles, getAllVarieties, getIsPermissionsFetching, getIsProductGroupsFetching],
  // TODO: убрать compartments.length, такая ситуация гипотетически возможна, просто заменить в редьюсере [] на null и провреять на null
  (isOrganizationFetching, isAllCompartmentsFetching, isAllPlantingCyclesFetching, isVarietiesFetching, compartments, plantingCycles, varieties, isPermissionsFetching, isProductGroupsFetching) => isOrganizationFetching || isAllCompartmentsFetching || isAllPlantingCyclesFetching || isVarietiesFetching || !compartments || compartments.length === 0 || !plantingCycles || !varieties || isPermissionsFetching || isProductGroupsFetching,
);

export const getCurrentLocation = createSelector(
  [getCurrentOrganization],
  location => location,
);

export const getOrganizations = state => state.company.locations || [];
export const getOrganizationSlug = (state, props) => get(props, 'match.params.organizationSlug'); // Внимание! Селектор требует использование HOC withRouter в компоненте
export const getResourceType = (state, props) => get(props, 'match.params.resourceType'); // Внимание! Селектор требует использование HOC withRouter в компоненте
export const getCycleId = (state, props) => get(props, 'match.params.cycleId'); // Внимание! Селектор требует использование HOC withRouter в компоненте

export const getOrganization = createSelector(
  [getOrganizationSlug, getOrganizations],
  (slug, organizations) => {
    const urlOrganization = organizations.find(item => get(item, 'attributes.slug') === slug);
    const firstOrganization = first(organizations);
    const savedOrganizationId = safeLocalStorage.getItem('location');
    const savedOrganization = organizations.find(item => savedOrganizationId && item && item.id.toString() === savedOrganizationId.toString());

    return urlOrganization || savedOrganization || firstOrganization;
  },
);


export const getDefaultOpenFromRoute = createSelector(
  [getRouteQueryParams],
  query => (get(query, 'defaultOpen') ? JSON.parse(get(query, 'defaultOpen')) : false),
);

export const getSpeciesFromRoute = createSelector(
  [getRouteQueryParams],
  (query) => {
    const species = get(query, 'species');
    if (species && species.indexOf(',') !== -1) {
      return species.split(',').map(item => item);
    }
    return species || undefined;
  },
);

export const getProductGroupFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'productGroup'),
);

export const getBreakdownTypeFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'breakdown'),
);

export const getCompartmentIdFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'compartmentId'),
);

export const getWorkClassIdFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'workClassId'),
);

export const getCompartmentIdsFromRoute = createSelector(
  [getRouteQueryParams],
  (query) => {
    const value = get(query, 'compartmentId');

    if (value) {
      return decodeURIComponent(value).split(',');
    }

    return value;
  }
);

export const getWorkTypeIdsFromRoute = createSelector(
  [getRouteQueryParams],
  (query) => {
    const value = get(query, 'workTypeId');

    if (value) {
      return decodeURIComponent(value).split(',');
    }

    return value;
  }
);

export const getBenchmarkGroupIdFromRoute = createSelector(
  [getRouteQueryParams],
  (query) => {
    const benchmarkGroupId = get(query, 'benchmarkGroupId');

    return benchmarkGroupId ? Number(benchmarkGroupId) : undefined;
  },
);

export const getAdditionalMetricIdFromRoute = createSelector(
  [getRouteQueryParams],
  (query) => {
    const additionalMetricId = get(query, 'additionalMetricId');

    return additionalMetricId ? Number(additionalMetricId) : undefined;
  },
);

export const getViewByFromRoute = createSelector(
  [getRouteQueryParams],
  query => query?.viewBy,
);

export const getCompartmentFromRoute = createSelector(
  getAllCompartments,
  getCompartmentIdFromRoute,
  (allCompartments, compartmentId) =>
    allCompartments.find(compartment => get(compartment, 'id') === Number(compartmentId)),
);

export const getVarietyIdFromRoute = createSelector(
  [getRouteQueryParams],
  (query) => {
    const varietyId = get(query, 'varietyId');
    if (isFinite(varietyId)) {
      return +varietyId;
    }
    if (varietyId && varietyId.indexOf(',')) {
      return varietyId.split(',').map(item => +item);
    }
    return undefined;
  },
);

export const getFruitClassFromRoute = createSelector(
  [getRouteQueryParams],
  (query) => {
    const fruitClass = get(query, 'fruitClassCode');
    if (fruitClass && fruitClass.indexOf(',')) {
      return fruitClass.split(',').map(item => item);
    }
    return fruitClass || undefined;
  },
);

export const getPlantingCycleIdFromRoute = createSelector(
  [getRouteQueryParams],
  (query) => {
    const plantingCycleId = get(query, 'plantingCycleId');
    return plantingCycleId ? Number(plantingCycleId) : undefined;
  },
);

export const getProductIdFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'productId'),
);


export const getUnitsFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'units'),
);

export const getUnitKindFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'unitKind', 'absolute'),
);

export const getBreakdownFromRoute = createSelector(
  [getRouteQueryParams],
  query => query?.breakdown,
);

export const getGraphKeyFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'graphKey'),
);

export const getGraphKeySecondFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'graphKeySecond'),
);

export const getPercentageForQualityFromRoute = createSelector(
  [getRouteQueryParams],
  query => JSON.parse((get(query, 'percentageForQuality') || false)),
);

export const getCumulativeForQualityFromRoute = createSelector(
  [getRouteQueryParams],
  query => JSON.parse((get(query, 'cumulativeForQuality') || false)),
);

export const getAnyDateOfPeriodStartFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'anyDateOfPeriodStart'),
);

export const getAnyDateOfPeriodEndFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'anyDateOfPeriodEnd'),
);

export const getDateFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'date'),
);

export const getStartDateFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'startDate'),
);

export const getEndDateFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'endDate'),
);

export const getPeriodTypeFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'periodType'),
);

export const getYearFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'year'),
);

export const getPeriodLengthFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'periodLength'),
);

export const getLocationPlanFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'locationPlan'),
);


export const getOrganizationId = createSelector(
  [getCurrentOrganization],
  location => get(location, 'id'),
);

export const getOrganizationSlugFromRoute = createSelector(
  [getMatch],
  match => get(match, 'params.organizationSlug'),
);

export const getPlanIdFromRoute = createSelector(
  [getMatch],
  match => get(match, 'params.planId'),
);

export const getCurrentTimezone = createSelector(
  [getCurrentOrganization],
  location => get(location, 'attributes.timezone'),
);

export const getCurrentSpecies = createSelector(
  [getCurrentOrganization],
  (location) => {
    const species = get(location, 'attributes.species');

    return species;
  },
);

export const getCurrentCompartments = createSelector(
  [getOrganizationId, getAllCompartments],
  (organizationId, allCompartments) => allCompartments.filter(item => get(item, 'relationships.location.data[0].id') === organizationId),
);

export const getCurrentPlantingCycles = createSelector(
  [getAllPlantingCycles, getCurrentCompartments],
  (allPlantingCycles, compartments) => allPlantingCycles.filter(plantingCycle => compartments.find(compartment => compartment.id === get(plantingCycle, 'relationships.compartment.data[0].id'))),
);

export const getFirstSpecies = createSelector(
  [getCurrentSpecies],
  species => head(species),
);

// Filtered values
export const getFilteredSpecies = createSelector(
  [getCurrentOrganization],
  location => get(location, 'attributes.species'),
);

// PlantingCycles, фильтрованные по компартменту (пока что не нужно)
// export const getFilteredPlantingCycles = createSelector(
//   getCurrentPlantingCycles,
//   getCompartmentIdFromRoute,
//   (plantingCycles, compartmentId) => {
//     if (compartmentId) {
//       return plantingCycles.filter(cycle => get(cycle, 'relationships.compartment.data[0].id') === Number(compartmentId));
//     }

//     return plantingCycles;
//   },
// );

export const getVarietiesInLocation = createSelector(
  getAllVarieties,
  getCurrentPlantingCycles,
  (varieties, plantingCycles) => {
    // Фильтруем по plantingCycles, чтобы вывести только те varieties, которые есть в compartments этой локации
    const filteredByCompartment = varieties.filter(variety => plantingCycles.find(cycle => get(cycle, 'relationships.variety.data[0].id') === variety.id));

    return filteredByCompartment;
  },
);

export const getVarietiesInLocationWithTargetWeight = createSelector(
  getVarietiesInLocation,
  getAllProductGroups,
  (varieties, products) => varieties.map(((variety) => {
    const currentProductGroup = find(products, { 'attributes.varietyId': variety.id });
    const targetWeight = get(currentProductGroup, 'attributes.targetWeight', null);
    return { ...variety, targetWeight };
  })),
);

export const getFilteredVarieties = createSelector(
  getAllVarieties,
  getSpeciesFromRoute,
  getCurrentPlantingCycles,
  (varieties, species, plantingCycles) => {
    // Фильтруем по plantingCycles, чтобы вывести только те varieties, которые есть в compartments этой локации
    const filteredByCompartment = varieties.filter(variety => plantingCycles.find(cycle => get(cycle, 'relationships.variety.data[0].id') === variety.id));

    if (species) {
      return filteredByCompartment
        .filter(variety => get(variety, 'attributes.species') === species);
    }

    return filteredByCompartment;
  },
);

export const getFilteredPlantingCyclesByVarieties = createSelector(
  getCurrentPlantingCycles,
  getVarietyIdFromRoute,
  getFilteredVarieties,
  (plantingCycles, varietyId, varieties) => {
    // Сначала фильтруем по отфильтрованнным varieties
    const filteredByVarieties = plantingCycles.filter(cycle => varieties.find(variety => get(cycle, 'relationships.variety.data[0].id') === variety.id));

    // Если пришёл конкретный variety, то фильтруем по нему
    if (varietyId) {
      return filteredByVarieties
        .filter(cycle => get(cycle, 'relationships.variety.data[0].id') === Number(varietyId));
    }

    return filteredByVarieties;
  },
);

export const getClimateInsightsAccess = createSelector(
  [getCurrentOrganization],
  location => get(location, 'attributes.features.climateInsights') || false,
);

export const getDigitalTwinAccess = createSelector(
  [getCurrentOrganization],
  location => get(location, 'attributes.features.digitalTwin', false),
);

export const getUnrealizedPotentialAccess = createSelector(
  [getCurrentOrganization],
  location => get(location, 'attributes.features.unrealizedPotential', false),
);

export const getCropReportsAccess = createSelector(
  [getCurrentOrganization],
  location => get(location, 'attributes.features.customReports', false),
);

export const getIsAddOperationalPlansAvailable = createSelector(
  [getPermissions, getOrganizationId],
  (permissions, locationId) => checkAddOperationalPlansPermission(permissions, locationId)
);

export const getIsAddBudgetPlansAvailable = createSelector(
  [getPermissions, getOrganizationId],
  (permissions, locationId) => checkAddBudgetPlansPermission(permissions, locationId)
);

export const getIsAddPlantingCycleAvailable = createSelector(
  [getPermissions, getOrganizationId],
  (permissions, locationId) => checkAddPlantingCyclePermission(permissions, locationId)
);

export const getIsAddHarvestAvailable = createSelector(
  [getPermissions, getOrganizationId],
  (permissions, locationId) => checkAddHarvestPermission(permissions, locationId)
);

export const getIsAddPhotoAvailable = createSelector(
  [getPermissions, getOrganizationId],
  (permissions, locationId) => checkAddPhotoPermission(permissions, locationId)
);

export const getIsAddManualObservationAvailable = createSelector(
  [getPermissions, getOrganizationId],
  (permissions, locationId) => checkAddManualObservationPermission(permissions, locationId)
);

export const getIsBenchmarkAvailable = createSelector(
  [getPermissions, getOrganizationId],
  (permissions, locationId) => checkBenchmarkPermission(permissions, locationId)
);

export const getIsAddEnergyAvailable = createSelector(
  [getPermissions, getOrganizationId],
  (permissions, locationId) => checkAddEnergyPermission(permissions, locationId)
);

export const getIsAddLaborAvailable = createSelector(
  [getPermissions, getOrganizationId],
  (permissions, locationId) => checkAddLaborPermission(permissions, locationId)
);

export const getIsAddHarvestForecastAvailable = createSelector(
  [getPermissions, getOrganizationId],
  (permissions, locationId) => checkAddHarvestForecastPermission(permissions, locationId)
);

export const getIsIncidentsExpert = createSelector(
  [getPermissions, getOrganizationId],
  (permissions, locationId) => checkAbnormalityIncidentRegisterPermission(permissions, locationId)
);

export const getIsOnlyLettuce = createSelector(
  getCurrentSpecies,
  species => (species && species.length === 1 && get(species, '[0]') === 'lettuce')
);

export const getIsOnlyVegetables = createSelector(
  getCurrentSpecies,
  species => (indexOf(species, 'lettuce') === -1)
);

export const getPlantingCyclePhotoCategories = createSelector(
  [getLeftPlantingCycle, getAllVarieties, getPhotoCategories],
  (plantingCycle, varieties, photoCategories) => {
    if (plantingCycle) {
      const findedVariety = varieties.find(item => item.id === plantingCycle.relationships.variety.data[0].id);

      if (findedVariety) {
        const photoCategory = photoCategories.find(item => item.species === findedVariety.attributes.species);

        return photoCategory?.categories || [];
      }
      return [];
    }
    return [];
  }
);

export const getLeftPhotoWeeks = createSelector(
  [getLeftPlantingCycle, getOrganization],
  (plantingCycle, organization) => {
    const { attributes: { timezone } } = organization;
    const startDate = plantingCycle?.attributes?.startDate;
    const endDate = plantingCycle?.attributes?.endDate;

    return getWeeks({
      startDate,
      endDate,
      timezone,
    });
  },
);

export const getPricePlan = createSelector(
  [getLocation],
  location => location?.attributes?.pricePlan,
);

export const getIsEssentialPricePlan = createSelector(
  [getPricePlan],
  pricePlan => pricePlan === 'ESSENTIAL',
);

export const getCurrentGrownFilterFromRoute = createSelector(
  [getVarietyIdFromRoute, getFruitClassFromRoute, getSpeciesFromRoute, getFirstSpecies],
  (varietyId, fruitClass, secies, firstSpecies) => (varietyId || fruitClass || secies || firstSpecies),
);

export const getHarvestForecastAccess = createSelector(
  [getCurrentOrganization],
  location => get(location, 'attributes.features.harvestForecast', false),
);

export const getDictionaries = state => state.company.dictionaries || {};

export const getCurrentSupportedSpecies = createSelector([getDictionaries], (dictionaries) => {
  const species = dictionaries?.species || [];
  return species.filter(sp => sp.supported).map(sp => ({
    id: sp.id,
    code: sp.code,
  }));
});

export const getIsCropCompareAvailable = createSelector(
  [getIsEssentialPricePlan],
  isEssentialPricePlan => !isEssentialPricePlan,
);
