import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { getFormValues, getFormSyncErrors } from 'redux-form';

import {
  getCurrentLocation,
} from 'store/company/selectors';

import { requestUpdateForecast, requestCreateForecast } from 'store/harvestForecast/actions';
import { getIsForecastFetching } from 'store/harvestForecast/selectors';

import HarvestForecastCreateForm from './HarvestForecastCreateForm';

const mapDispatchToProps = {
  requestUpdateForecast,
  requestCreateForecast,
};

const mapStateToProps = (state, props) => ({
  ...props,

  location: getCurrentLocation(state),
  formValues: getFormValues('forecastCreateOrEditForm')(state),
  formSyncErrors: getFormSyncErrors('forecastCreateOrEditForm')(state),
  isForecastFetching: getIsForecastFetching(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(HarvestForecastCreateForm)));
