import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import { get, isNil } from 'lodash';
import moment from 'moment-timezone';
import { utcMonday } from 'd3';

import numbersFormatting from 'helpers/numbersFormatting';
import numbersFormatAndRounding from 'helpers/numbersFormatAndRounding';
import { findGetAndRoundingValue } from 'helpers/getValueDetails';


import LinesGraph from 'components/LinesGraph/components/LinesGraph/LinesGraph';
import LinesGraphTooltip from 'components/LinesGraphTooltip/LinesGraphTooltip';

import styles from './ResultLineChart.module.css';

const getMaxValue = (points = []) => {
  if (points.length === 0) {
    return 100;
  }

  const items = points.map(point => point.y);

  return Math.max(...items);
};

const getMinValue = (points = []) => {
  if (points.length === 0) {
    return 0;
  }

  const items = points.map(point => point.y);

  const minimum = Math.min(...items);

  if (minimum < 0) {
    return minimum;
  }

  return 0;
};

const ResultLineChart = ({
  intl,
  lines,
  maxValue,
  title,
  totalValue,
  avgValue,
  isDataFetching,
  units,
  roundTo,
  onTooltipChanged,
  screenX,
  screenY,
  closestPoint,
  closestPointCoordX,
  tooltipDate,
  showTooltip,
}) => {
  const { formatMessage } = intl;

  const isEmpty = get(lines, '[0].points').length === 0;
  const maxPointsValue = useMemo(() => getMaxValue(get(lines, '[0].points')), [lines]);
  const minPointsValue = useMemo(() => getMinValue(get(lines, '[0].points')), [lines]);
  const unitsText = formatMessage({ id: `cunits.mini.${units}` });

  /**
   * Нужна кастомная функция расчёта тиков, т.к. дефолтная utcWeek
   * выдаёт тик недели не на понедельник, а у точек в качестве даты идут понедельники,
   * в результате тики не совпадают с точками, т.к. точки и тики фактически выставляются
   * по дням
   */
  const customTicksFunction = (xFromValue, xToValue, ticksCount, preparedData) => {
    const ticksByData = preparedData ? (preparedData.length / 10) : 1;
    const everyTick = ticksByData > 1 ? ticksByData : 1;

    return utcMonday.every(everyTick);
  };

  const customRenderDate = (tooltipDateCustomRender) => {
    // Берём последний день недели. Проверяем попадает ли в текущий год. Если нет, то добавлеяем в тултип.
    const dateYear = tooltipDateCustomRender.clone().isoWeekday(7).format('YYYY');
    const currentYear = moment(new Date()).format('YYYY');
    const isAddPrevYear = dateYear !== currentYear;
    const prevYearText = isAddPrevYear ? `, ${dateYear}` : '';

    const week = tooltipDateCustomRender.isoWeek();

    // TODO: скопипастил, вынести в хелпер работы с датами
    const weekDates = `${tooltipDateCustomRender.clone().isoWeekday(1).format(`${formatMessage({ id: 'date.dayMonth' })}`)} – ${tooltipDateCustomRender.clone().isoWeekday(7).format(`${formatMessage({ id: 'date.dayMonth' })}`)}`;

    return `${weekDates}${prevYearText}, ${formatMessage({ id: 'timeSelector.week.one' })} ${week}`;
  };

  const renderTooltipContent = (tooltipDateInRender) => {
    const currentLine = get(lines, '[0]');

    const currentLinePoints = get(currentLine, 'points');

    const currentDate = tooltipDateInRender ? tooltipDateInRender.format('MM-DD-YYYY') : undefined;

    if (!currentDate) {
      return null;
    }

    const actualLineValue = findGetAndRoundingValue(currentLinePoints, currentDate, 1);
    // const unitsText = formatMessage({ id: `cunits.mini.${currentLine.units}` });

    const actualLineWithUnits = isNil(actualLineValue) ? formatMessage({ id: 'crops.noData' }) : `${numbersFormatting(actualLineValue)} ${unitsText}`;

    const generatedLines = [
      {
        id: currentLine.key,
        value: actualLineWithUnits,
        // header: currentLine.name,
        header: formatMessage({ id: 'dashboards.totalTooltip' }),
        color: currentLine.color,
      },
    ];

    return (
      <LinesGraphTooltip
        lines={generatedLines}
        tooltipDate={tooltipDateInRender}
        customRenderDate={customRenderDate}
        periodType='week'
      />
    );
  };


  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div className={styles.totals}>
          <div className={styles.total}>
            <div className={styles.totalHeader}>{formatMessage({ id: 'benchmarking.total' })}</div>
            <div className={styles.totalValue}>{`${numbersFormatAndRounding(totalValue, roundTo)} ${unitsText}`}</div>
          </div>
          <div className={styles.total}>
            <div className={styles.totalHeader}>{formatMessage({ id: 'benchmarking.avgWeekly' })}</div>
            <div className={styles.totalValue}>{`${numbersFormatAndRounding(avgValue, roundTo)} ${unitsText}`}</div>
          </div>
          <div className={styles.total}>
            <div className={styles.totalHeader}>{formatMessage({ id: 'graphs.table.maximum' })}</div>
            <div className={styles.totalValue}>{`${numbersFormatAndRounding(maxValue, roundTo)} ${unitsText}`}</div>
          </div>
        </div>
      </div>
      <div className={styles.chartContainer}>
        <LinesGraph
          onTooltipChanged={onTooltipChanged}
          screenY={screenY}
          screenX={screenX}
          closestPoint={closestPoint}
          closestPointCoordX={closestPointCoordX}
          tooltipDate={tooltipDate}
          showTooltip={showTooltip}
          wrapperClassName={styles.lineChartWrapper}
          isOverlapedEmptyState={false}
          isDataFetching={isDataFetching}
          lines={lines}
          isEmpty={isEmpty}
          maxValue={maxPointsValue}
          minValue={minPointsValue}
          isLegendDisabled
          renderTooltipContent={tooltipDateInRender => renderTooltipContent(tooltipDateInRender, lines)}
          defaultSize={{ height: 215 }}
          yTicks={4}
          emptyStateText={formatMessage({ id: 'cropModel.emptyStateText' })}
          tickTimeDisplayFormat={date => moment(date).isoWeek()}
          customTicksFunction={customTicksFunction}
          withoutPoints
        />
      </div>
    </div>
  );
};

ResultLineChart.propTypes = {
  intl: intlShape.isRequired,
  lines: PropTypes.array,
  title: PropTypes.string.isRequired,
  maxValue: PropTypes.number,
  totalValue: PropTypes.number,
  avgValue: PropTypes.number,
  isDataFetching: PropTypes.bool,
  units: PropTypes.string.isRequired,
  roundTo: PropTypes.number,
  onTooltipChanged: PropTypes.func.isRequired,
  screenX: PropTypes.number,
  screenY: PropTypes.number,
  closestPointCoordX: PropTypes.number,
  closestPoint: PropTypes.object,
  tooltipDate: PropTypes.any,
  showTooltip: PropTypes.bool,
};

ResultLineChart.defaultProps = {
  lines: [],
  isDataFetching: false,
  maxValue: 0,
  totalValue: 0,
  avgValue: 0,
  roundTo: 1,
  screenX: null,
  screenY: null,
  closestPointCoordX: null,
  closestPoint: null,
  tooltipDate: null,
  showTooltip: false,
};

export default injectIntl(ResultLineChart);
