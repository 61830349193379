import classnames from 'classnames';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from './assets/close.svg';

import styles from './DefaultDialog.module.css';


export default class DefaultDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    dialogCloseName: PropTypes.string,
    hideCloseIcon: PropTypes.bool,
    children: PropTypes.object,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    subTitle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
  };

  static defaultProps = {
    hideCloseIcon: false,
    children: null,
    title: null,
    subTitle: null,
    className: null,
    wrapperClassName: null,
    headerClassName: null,
    bodyClassName: null,
    dialogCloseName: null,
  };

  handlerClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  handlerShadowClick = (e) => {
    if (e.target.classList && e.target.classList.contains(styles.dialog)) {
      this.handlerClose(true);
    }
  };

  render() {
    const {
      title, subTitle, children, className, wrapperClassName, hideCloseIcon, headerClassName, bodyClassName, dialogCloseName
    } = this.props;

    const handlers = {
      onClick: this.handlerShadowClick,
    };

    return (
      <div className={classnames(styles.dialog, className)} {...handlers}>
        <div className={classnames(styles.dialogWrapper, wrapperClassName)}>
          {!hideCloseIcon && <CloseIcon className={classnames(styles.dialogClose, dialogCloseName)} onClick={this.handlerClose} />}
          <div className={classnames(styles.dialogHeader, headerClassName)}>
            <div className={styles.dialogHeaderTitle}>{title}</div>
            <div className={styles.dialogHeaderSubtitle}>{subTitle}</div>
          </div>
          <div className={classnames(styles.dialogBody, bodyClassName)}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}
