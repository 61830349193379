import moment from 'moment-timezone';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent, } from 'react';
import { get, uniqueId } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import LINE_COLORS from 'helpers/harvestColors';

import { getMlNameDirect } from 'helpers/getVarietyName';
import getDateFormat from 'helpers/getDateFormat';
import numbersRounding from 'helpers/numbersRounding';
import storageWrapper from 'helpers/storageWrapper';
import { getUnits } from 'helpers/getValueDetails';
import isTouchDevice from 'helpers/isTouchDevice';

import Typography from 'components/Typography';
import CropsLink from 'components/CropsLink';
import BigButton from 'components/BigButton';
import CloseIcon from 'components/Icons/Close';

import tooltipStyles from 'components/Tooltip/index.module.css';

import isTruncated from 'helpers/isTruncated';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';

import styles from './BenchmarkCompareTable.module.css';


const safeLocalStorage = storageWrapper.get('localStorage');


class BenchmarkCompareTable extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    comparison: PropTypes.object,
    removeCycleFromComparison: PropTypes.func.isRequired,
    requestBenchmarkComparison: PropTypes.func.isRequired,
  };

  static defaultProps = {
    comparison: null,
  };

  state = {};

  tooltipRef = React.createRef();

  handlerDeleteProduct = ({ productId }) => {
    const { removeCycleFromComparison, requestBenchmarkComparison } = this.props;

    const actionAfterSuccess = (result) => {
      if (!result || result.length === 0) {
        return () => {};
      }

      return requestBenchmarkComparison({ cyclesToCompare: result });
    };

    removeCycleFromComparison({ cycleId: productId, actionAfterSuccess, withUpdateUrlParams: true });

    this.forceUpdate();
  }

  renderTableSectionRow = ({ title, getValue, id }) => {
    const {
      comparison,
    } = this.props;

    const products = get(comparison, 'products');

    return (
      <React.Fragment key={`table-section-${id}`}>
        <tr className={classnames(styles.bodyRow, styles.mobileRow)}>
          <th className={classnames(styles.bodyCell, styles.mainPadding, styles.mobileHeaderBodyCell)} colSpan={products.length}>
            <div>
              <Typography variant='subtitle2' color='secondary'>{title}</Typography>
            </div>
          </th>
        </tr>

        <tr className={classnames(styles.bodyRow, styles.mobileBorderTopNone)}>
          <th className={classnames(styles.bodyCell, styles.mainPadding, styles.desktopCell)}>
            <div>
              <Typography variant='subtitle2'>{title}</Typography>
            </div>
          </th>

          {products.map(product => (
            <td key={`product-column-${product.id}`} className={classnames(styles.bodyCell, styles.contentPadding)}>
              <div>
                <Typography variant='subtitle2'>{getValue(product)}</Typography>
              </div>
            </td>
          ))}
        </tr>
      </React.Fragment>
    );
  }

  renderTableSection = ({ title, items, id }) => (
    <>
      <tr>
        <th className={styles.mainPadding}>
          <div className={styles.expandCard}>
            <button
              type='button'
              className={classnames(styles.expandButton, { [styles.hidden]: get(this.state, id) })}
              onClick={() => this.setState(prevState => ({ [id]: !get(prevState, id) }))}
            >
              <Typography variant='h3'>{title}</Typography>
              <ArrowIcon />
            </button>
          </div>
        </th>
      </tr>
      {!get(this.state, id) && items.map(item => this.renderTableSectionRow(item))}
    </>
  )

  render() {
    const {
      intl,
      comparison,
    } = this.props;

    const { formatMessage } = intl;

    const products = get(comparison, 'products');
    const formMetrics = get(comparison, 'formMetrics');

    const locale = safeLocalStorage.getItem('locale') || 'en';

    return (
      <div className={classnames(styles.layout)}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={classnames(styles.headerCellMain, styles.mainPadding, styles.desktopCell)} />
              {products.map((product, i) => {
                const productId = get(product, 'id');
                const organizationName = get(product, 'location.name', '');

                const startDate = get(product, 'startDate');
                const endDate = get(product, 'endDate');

                const startDateMoment = moment(startDate).format(getDateFormat('lll'));
                const endDateMoment = endDate ? moment(endDate).format(getDateFormat('lll')) : formatMessage({ id: 'cycle.present' });

                const tooltipId = `product-item-tooltip-${uniqueId()}`;
                const datesAndCompartmentText = `${startDateMoment} - ${endDateMoment}, ${product?.compartment?.name}`;

                return (
                  <th key={`product-header-column-${product.id}`} className={classnames(styles.headerCell, styles.contentPadding)}>
                    <div className={styles.headerCard}>
                      <div className={classnames(styles.row, styles.spaceBetween)}>
                        <Typography variant='body2' className={classnames(styles.headerCardTitle)}>{organizationName}</Typography>

                        <BigButton
                          className={styles.iconButton}
                          onClick={() => this.handlerDeleteProduct({ productId })}
                          icon={<CloseIcon />}
                          category='icon'
                          tooltip={formatMessage({ id: 'benchmarking.removeFrom' })}
                        />
                      </div>
                      <div className={classnames(styles.row, styles.alignBaseline)}>
                        <div className={styles.square} style={{ backgroundColor: LINE_COLORS[i] }} />
                        <Typography variant='body1' bold className={classnames(styles.headerCardName)}><CropsLink product={product} /></Typography>
                      </div>
                      <p
                        variant='body2'
                        className={classnames(styles.headerCardDates)}
                        data-tip=''
                        data-for={tooltipId}
                        ref={this.tooltipRef}
                      >
                        {datesAndCompartmentText}
                        <ReactTooltip
                          className={classnames(tooltipStyles.smallTooltip)}
                          id={tooltipId}
                          effect='solid'
                          event={isTouchDevice() ? 'click' : null}
                          html
                          getContent={() => (isTruncated(this.tooltipRef?.current) ? datesAndCompartmentText : undefined)}
                        />
                      </p>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {this.renderTableSection({
              id: 'production',
              title: <FormattedMessage id='benchmarking.production' />,
              items: [
                {
                  id: 'avgWeeklyHarvest',
                  title: (
                    <>
                      <FormattedMessage id='benchmarking.avgWeeklyHarvest' />
                      ,
                      &nbsp;
                      <FormattedMessage id='cunits.mini.kilogramPerSquareMeter' />
                    </>
                  ),
                  getValue: product => numbersRounding(get(product, 'harvest.weekly.amount', '—') || '—', 'fixed', 1),
                },
                {
                  id: 'totalHarvest',
                  title: (
                    <>
                      <FormattedMessage id='benchmarking.totalHarvest' />
                      ,
                      &nbsp;
                      <FormattedMessage id='cunits.mini.kilogramPerSquareMeter' />
                    </>
                  ),
                  getValue: product => numbersRounding(get(product, 'harvest.total.amount', '—') || '—', 'fixed', 1),
                }
              ]
            })}

            {this.renderTableSection({
              id: 'plant',
              title: <FormattedMessage id='benchmarking.plant' />,
              items: [
                {
                  id: 'cycleWeeks',
                  title: <FormattedMessage id='benchmarking.cycleWeeks' />,
                  getValue: product => get(product, 'cycleWeeks') || '—',
                },
                {
                  id: 'harvestedWeeks',
                  title: <FormattedMessage id='benchmarking.harvestedWeeks' />,
                  getValue: product => get(product, 'harvest.harvestedWeeks') || '—',
                }
              ]
            })}

            {this.renderTableSection({
              id: 'climate',
              title: <FormattedMessage id='benchmarking.climate' />,
              items: formMetrics
                .filter(item => get(item, 'category') === 'climate')
                .map(item => ({
                  id: get(item, 'id', ''),
                  title: `${get(item, `name.${locale || 'en'}`)}, ${getUnits(item, formatMessage, 'cunit')}`,
                  getValue: product => numbersRounding(get(product, `measurements.${item.id}`, '—') || '—', 'fixed', 1),
                }))
            })}

            {this.renderTableSection({
              id: 'equipment',
              title: <FormattedMessage id='benchmarking.equipment' />,
              items: [
                {
                  id: 'greenhouse',
                  title: <FormattedMessage id='benchmarking.greenhouse' />,
                  getValue: product => getMlNameDirect(get(product, 'greenhouseType'), locale),
                },
                {
                  id: 'artificialLightPower',
                  title: (
                    <>
                      <FormattedMessage id='benchmarking.artificialLightPower' />
                      ,
                      &nbsp;
                      <FormattedMessage id='cunits.mini.wattPerSquareMeter' />
                    </>
                  ),
                  getValue: product => get(product, 'lightPower', '—') || '—',
                }
              ]
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default injectIntl(BenchmarkCompareTable);
