import {
 Compartment, PlantingCycle, Species, SubNode
} from 'store/graphs/types';

export default function isFullGroup(
  input: {
    firstNode: Compartment | Species | null;
    secondNode: SubNode | PlantingCycle| null;
  }
): boolean {
  if (input?.firstNode && input?.firstNode.id !== 0 && !input.secondNode) {
    return false;
  }
  return Boolean(input?.firstNode);
}
