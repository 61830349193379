import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from 'components/Typography';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { ReactComponent as SettingsIcon } from 'components/UnrealizedPotential/components/Harvest/assets/settings.svg';
import ModelSettings from 'components/UnrealizedPotential/components/ModelSettings';

import styles from './UnrealizedPotentialHeader.module.css';

const UnrealizedPotentialHeader = ({ modelSettings }) => {
  const [settingsOpen, setSettingsOpen] = useState(false);

  const toggleMenuState = useCallback(() => {
    setSettingsOpen(!settingsOpen);
  }, [settingsOpen, setSettingsOpen]);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.title}>
          <Typography variant='h3'>
            <FormattedMessage id='unrealizedPotential.calculatedPotentialHarvestTitle' />
          </Typography>
        </div>
        <div
          className={classnames(styles.menuButton, { [styles.active]: settingsOpen })}
          role='button'
          tabIndex={0}
          onClick={() => {
            toggleMenuState();
          }}
        >
          <div className={styles.settingsIcon}><SettingsIcon /></div>
          <div className={styles.settingsLabel}><FormattedMessage id='unrealizedPotential.modelSettings' /></div>
        </div>
      </div>
      {settingsOpen && <ModelSettings modelSettings={modelSettings} />}
    </div>
  );
};

UnrealizedPotentialHeader.propTypes = {
  modelSettings: PropTypes.array.isRequired
};

export default UnrealizedPotentialHeader;
