import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { reduxForm, Field } from 'redux-form';

import {
  isEmpty, trim
} from 'lodash';

import DefaultDialog from 'components/DefaultDialog';
import BigButton from 'components/BigButton';
import DefaultTextInput from 'components/DefaultTextInput';
import DefaultTextareaInput from 'components/DefaultTextareaInput';

import styles from './EditDialog.module.css';


const validate = (values, props) => {
  const { intl: { formatMessage } } = props;
  const errors = {};

  if (!values.simulationName) {
    errors.simulationName = formatMessage({ id: 'formErrors.requiredField' });
  }

  if (values.simulationName && values.simulationName.length > 140) {
    errors.simulationName = formatMessage({ id: 'formErrors.maximumNumberOfCharacters' }, { maxValue: 140 });
  }

  if (values.simulationDesc && values.simulationDesc.length > 280) {
    errors.simulationDesc = formatMessage({ id: 'formErrors.maximumNumberOfCharacters' }, { maxValue: 280 });
  }

  return errors;
};

class EditDialog extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    label: PropTypes.string.isRequired,
    formValues: PropTypes.object,
    isFetching: PropTypes.bool,
    formSyncErrors: PropTypes.object,

    handlerCloseDialog: PropTypes.func.isRequired,
    handlerDontSave: PropTypes.func.isRequired,
    handlerSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formValues: null,
    isFetching: false,
    formSyncErrors: null,
  };

  getIsFormValuesNotEmpty = () => {
    const { formValues } = this.props;

    return formValues && formValues.simulationName;
  };

  handlerSubmitForm = (e) => {
    e.preventDefault();

    const {
      formValues, handlerSave, handlerCloseDialog, formSyncErrors
    } = this.props;
    const { simulationName, simulationDesc } = formValues;
    const isFormEmpty = !this.getIsFormValuesNotEmpty();

    if (!formValues || isFormEmpty || !isEmpty(formSyncErrors)) {
      return null;
    }

    return handlerSave({
      name: trim(simulationName),
      description: simulationDesc,
      actionSuccess: handlerCloseDialog,
    });
  }

  renderNameInput = ({
    input,
    meta: { touched, error, active },
  }) => {
    const { intl: { formatMessage } } = this.props;

    return (
      <DefaultTextInput
        {...input}
        className={styles.nameField}
        placeholder={`${formatMessage({ id: 'cropModel.title' })}*`}
        error={touched && !active && error ? error : null}
      />
    );
  };

  renderDescriptionTextarea = ({
    input,
    meta: { touched, error, active },
  }) => {
    const { intl: { formatMessage } } = this.props;

    return (
      <DefaultTextareaInput
        {...input}
        placeholder={formatMessage({ id: 'cropModel.description' })}
        rows={4}
        error={touched && !active && error ? error : null}
      />
    );
  };

  render() {
    const {
      intl: { formatMessage }, isFetching,
      label, handlerCloseDialog, handlerDontSave,
    } = this.props;

    return (
      <DefaultDialog
        title={label}
        onClose={handlerCloseDialog}
        wrapperClassName={styles.dialogWrapper}
        className={styles.dialog}
      >
        <form
          className={styles.passwordForm}
          onSubmit={this.handlerSubmitForm}
        >
          <div className={styles.dialogText}>
            <Field
              name='simulationName'
              component={this.renderNameInput}
            />
            <Field
              name='simulationDesc'
              component={this.renderDescriptionTextarea}
            />
          </div>
          <div className={styles.buttons}>
            <BigButton
              className={styles.cancel}
              onClick={handlerDontSave}
              title={formatMessage({ id: 'button.cancel' })}
              theme='light'
              disabled={isFetching}
            />
            <BigButton
              className={styles.confitm}
              title={formatMessage({ id: 'button.saveChanges' })}
              theme='dark'
              isLoading={isFetching}
            />
          </div>
        </form>
      </DefaultDialog>
    );
  }
}

export default reduxForm({
  form: 'simulationNameForm',
  validate,
})(EditDialog);
