import {
  toNumber, isString
} from 'lodash';

/* eslint-disable import/prefer-default-export */
export const getTrimmedValue = (value, defaultValue = null) => {
  if (value === '') {
    return defaultValue;
  }

  const trimmedValue = isString(value) ?
    value
      .replace(/^\s+|\s+$/g, '')
      .replace(/\s/g, '')
      .replace(/,/g, '.')
    :
    value;

  return toNumber(trimmedValue);
};
/* eslint-enable import/prefer-default-export */

