import React, { useMemo } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import LinesGraph from 'components/LinesGraph/components/LinesGraph/LinesGraph';

import { getCategoryColor, getCategoryName } from 'helpers/harvestForecast';

import { API_DATE_FORMAT } from 'helpers/defaultDates';
import moment from 'moment';
import {
  customTicksFunction,
  getForecastLineChartItems,
  getLinesMaxValue,
  getLinesMinValue,
  getTicksRange,
  renderTooltipContent,
  tickTimeDisplayFormat,
  useOverlayData,
} from '../../helpers';

import styles from './HarvestForecastHarvestChart.module.css';

function getHarvestLines(intl, assistant, isRightAxis = false) {
  if (!assistant || !assistant?.data?.harvest) {
    return [];
  }

  const {
    descriptor: { periods },
    data: {
      harvest: { categories, data },
    },
    realDataTill,
  } = assistant;

  const till = realDataTill ? moment.utc(realDataTill, API_DATE_FORMAT) : null;
  const items = [];
  categories
    .filter(cat =>
      (isRightAxis
        ? cat.categoryType === 'ACCURACY'
        : cat.categoryType !== 'ACCURACY'))
    .forEach((catItem) => {
      const categoryName = getCategoryName(catItem, intl);
      const color = getCategoryColor(catItem);
      if (catItem.categoryType !== 'FORECAST') {
        items.push(
          ...getForecastLineChartItems(
            assistant,
            data[catItem.id] || {},
            catItem.units,
            periods,
            catItem.id,
            categoryName,
            color
          )
        );
      } else {
        items.push(
          ...getForecastLineChartItems(
            assistant,
            data[catItem.id] || {},
            catItem.units,
            periods,
            catItem.id,
            categoryName,
            color,
            till,
            true
          )
        );
      }
    });
  return items;
}

const HarvestForecastHarvestChart = ({
  intl,
  assistant,
  isAssistantFetching,
  showHistoricalPeriod,
  chartMargins,
}) => {
  const { formatMessage } = intl;

  const rightLines = useMemo(
    () => getHarvestLines(intl, assistant, true),
    [intl, assistant]
  );

  const leftLines = useMemo(
    () => getHarvestLines(intl, assistant, false),
    [intl, assistant]
  );

  const allLines = useMemo(
    () => [...rightLines, ...leftLines],
    [rightLines, leftLines]
  );

  const rightMaxValue = useMemo(
    () => getLinesMaxValue(rightLines),
    [rightLines]
  );
  const rightMinValue = useMemo(
    () => getLinesMinValue(rightLines),
    [rightLines]
  );

  const leftMaxValue = useMemo(() => getLinesMaxValue(leftLines), [leftLines]);
  const leftMinValue = useMemo(() => getLinesMinValue(leftLines), [leftLines]);

  const isEmpty = useMemo(
    () =>
      !isAssistantFetching &&
      rightLines &&
      rightLines.length === 0 &&
      leftLines &&
      leftLines.length === 0,
    [isAssistantFetching, rightLines, leftLines]
  );

  const overlayData = useOverlayData(showHistoricalPeriod, assistant);

  return (
    <div className={styles.chartBlock}>
      <div className={styles.chartBlockTitle}>
        {formatMessage({ id: 'forecast.assistant.harvest' })}
      </div>
      <LinesGraph
        wrapperClassName={styles.chartWrapper}
        withoutPoints
        isDataFetching={isAssistantFetching}
        lines={leftLines}
        maxValue={leftMaxValue}
        minValue={leftMinValue}
        rightLines={rightLines}
        rightMaxValue={rightMaxValue}
        rightMinValue={rightMinValue}
        renderTooltipContent={tooltipDate =>
          renderTooltipContent(tooltipDate, allLines, intl)}
        isEmbeddedEmptyState
        isEmpty={isEmpty}
        emptyTitle={formatMessage({ id: 'forecast.emptyNoData' })}
        isLegendDisabled={false}
        defaultSize={{
          height: 180,
        }}
        xTicks={5}
        customTicksFunction={customTicksFunction}
        tickTimeDisplayFormat={tickTimeDisplayFormat}
        cropEmptyValues
        customGetTicksRange={getTicksRange}
        overlay={overlayData}
        margin={chartMargins}
        isHoverLineSizeNeedCalc
      />
    </div>
  );
};

HarvestForecastHarvestChart.propTypes = {
  intl: intlShape.isRequired,
  assistant: PropTypes.object.isRequired,
  isAssistantFetching: PropTypes.bool.isRequired,
  showHistoricalPeriod: PropTypes.bool,
  chartMargins: PropTypes.object.isRequired,
};

HarvestForecastHarvestChart.defaultProps = {
  showHistoricalPeriod: true,
};

export default injectIntl(HarvestForecastHarvestChart);
