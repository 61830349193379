import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import classnames from 'classnames';

import styles from './CollapsibleCard.module.css';

const CollapsibleCardItem = ({
  id, title, subTitle, contentItem
}) => (
  <div className={styles.item}>
    <input id={id} type='checkbox' />
    <label htmlFor={id}>
      <div className={styles.lables}>
        <div className={styles.title}>{title}</div>
        {styles.subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      </div>
      <span className={styles.arrow} />
    </label>
    <div className={styles.content}>
      <div className={styles.contentWrap}>{contentItem}</div>
    </div>
  </div>
);

CollapsibleCardItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  contentItem: PropTypes.node.isRequired,
};

CollapsibleCardItem.defaultProps = {
  subTitle: null,
};

const CollapsibleCard = ({ items, className }) => (
  <div className={classnames(styles.card, className)}>
    {Array.isArray(items) ?
      items.map(item => ({ ...item, id: uniqueId() })).map(item => <CollapsibleCardItem {...item} key={item.id} />) :
      <CollapsibleCardItem {...items} key={items.id} />}
  </div>
);

// TODO: Check rule
// eslint-disable-next-line react/forbid-foreign-prop-types
const CollapsibleCardItemDataShape = PropTypes.shape(CollapsibleCardItem.propTypes);

CollapsibleCard.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(CollapsibleCardItemDataShape),
    CollapsibleCardItemDataShape,
  ]),
  className: PropTypes.string,
};

CollapsibleCard.defaultProps = {
  className: undefined,
  items: [],
};

export default CollapsibleCard;
