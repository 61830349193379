import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { get } from 'lodash';
import classnames from 'classnames';
import parseISO from 'date-fns/parseISO';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isThisYear from 'date-fns/isThisYear';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

import { formatDateLocalized } from 'helpers/datesHelper';
import { getNameByLocal } from 'helpers/getNameByLocal';
import { mappingGraphPresetMetricsToUrl } from 'helpers/graphMetricsMapping';
import addGetParameters from 'helpers/addGetParameters';
import getGraphMetricGroups from 'helpers/getGraphMetricsGroups';

import RateIncidentButton from 'components/RateIncidentButton';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import AbnormalityIncidentIssue from 'components/AbnormalityIncidentIssue';
import DefaultSimpleTooltip from 'components/DefaultSimpleTooltip';
import CustomChart from 'components/CustomChart';
import CloseIcon from 'components/Icons/Close';
import SettingsIcon from 'components/Icons/SettingsIcon';

import { ReactComponent as LinkIcon } from './assets/link.svg';

import styles from './IncidentInfoPanel.module.css';

const getSeverityColor = (severity) => {
  if (severity === 'high') {
    return '#990515';
  }

  if (severity === 'middle') {
    return '#D25871';
  }

  if (severity === 'low') {
    return '#F7E2E7';
  }

  if (severity === 'potential') {
    return '#F7E2E7';
  }

  return '#F7E2E7';
};

const getFormattedTimeDiff = (formatMessage, parsedStartDate, parsedEndDate) => {
  if (!parsedStartDate || !parsedEndDate) {
    return undefined;
  }

  const diffInMinutes = differenceInMinutes(parsedEndDate, parsedStartDate);

  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;

  const hoursText = `${hours} ${formatMessage({ id: 'cunits.mini.hour' })}`;
  const minutesText = `${minutes} ${formatMessage({ id: 'timeSelector.min.one' })}`;

  if (hours === 0) {
    return minutesText;
  }

  if (minutes === 0) {
    return hoursText;
  }

  return `${hoursText} ${minutesText}`;
};

const getFormattedDateRange = (formatMessage, parsedStartDate, parsedEndDate) => {
  if (!parsedStartDate || !parsedEndDate) {
    return undefined;
  }

  const isBothDatesInThisYear = isThisYear(parsedStartDate) && isThisYear(parsedStartDate);
  const datesFormat = isBothDatesInThisYear ? 'll hh' : 'lll hh';
  const startDate = formatDateLocalized(parsedStartDate, datesFormat);
  const endDate = formatDateLocalized(parsedEndDate, datesFormat);
  const formattedDiff = getFormattedTimeDiff(formatMessage, parsedStartDate, parsedEndDate);

  return `${startDate} — ${endDate} (${formattedDiff})`;
};

const getIncidentGraphUrl = (organizationSlug, activeIncidentId, metricsList, parsedStartDate, parsedEndDate) => {
  if (!metricsList || metricsList?.length === 0) {
    return `/${organizationSlug}/graphs`;
  }

  let nodeMetrics = [];

  nodeMetrics = metricsList.map((graphMetric) => {
    const {
      nodeMetric,
      position,
      rightAxis,
      selected,
    } = graphMetric;

    const {
      compartmentId,
      locationId,
      metricId,
      subNodeId,
    } = nodeMetric;

    return {
      ...graphMetric,
      attributes: {
        location: {
          id: locationId,
        },
        compartment: {
          id: compartmentId,
        },
        subNode: {
          id: subNodeId,
        },
        metric: {
          id: metricId,
        },
        rightAxis,
        selected,
        position,
      },
      // Из инцидентов эти данные не приходят, но они нужны в графиках
      relationships: {
        compartment: compartmentId ? { data: [{ id: +compartmentId, type: 'Compartment' }] } : null,
        location: locationId ? { data: [{ id: +locationId, type: 'Location' }] } : null,
        metric: { data: [{ id: +metricId, type: 'Metric' }] },
        subNode: subNodeId ? { data: [{ id: +subNodeId, type: 'SubNode' }] } : null
      }
    };
  });

  const endOfEndDay = endOfDay(parsedEndDate);
  const xRangeLengthInMins = differenceInMinutes(endOfEndDay, startOfDay(parsedStartDate));

  // Вычитаем tz offset, т.к. в графиках учитывается время по unix и таймзона добавляется вручную
  const millisecondsSinceUnixEpoch = endOfEndDay.getTime() - endOfEndDay.getTimezoneOffset() * 60000;

  const chunk = {
    returnAbnormalityIncidents: 1,
    xRange: 'custom',
    xRangeEnd: millisecondsSinceUnixEpoch,
    xRangeLengthInMins,
    activeIncidentId,
  };

  if (nodeMetrics.length) {
    chunk.nm = mappingGraphPresetMetricsToUrl({ graphPresetMetrics: nodeMetrics, graphPresetMetricGroups: getGraphMetricGroups(nodeMetrics) });
  }

  const searchString = addGetParameters('', chunk);

  return `/${organizationSlug}/graphs${searchString}`;
};

const IncidentInfoPanel = ({
  intl,
  organizationSlug,
  onCollapse,
  panelCollapsed,
  allCompartments,
  allGraphMetrics,
  activeIncident,
  isIncidentFetching,
  feedback,
  isExpert,
  activeIncidentPlot,
  isIncidentPlotFetching,
  allSubNodes,
}) => {
  const { locale, formatMessage } = intl;

  const handlerIncidentCollapse = useCallback(() => {
    onCollapse();
  }, [onCollapse]);

  const handlerLikeIncidentClick = useCallback(() => {}, []);

  const incidentStartDate = activeIncident?.timeRange?.start;
  const incidentEndDate = activeIncident?.timeRange?.end;

  const parsedStartDate = incidentStartDate ? parseISO(incidentStartDate) : undefined;
  const parsedEndDate = incidentEndDate ? parseISO(incidentEndDate) : undefined;
  const formattedDateRange = getFormattedDateRange(formatMessage, parsedStartDate, parsedEndDate);

  const metricsList = activeIncident?.diagramState;
  const graphsHref = getIncidentGraphUrl(organizationSlug, activeIncident?.id, metricsList, parsedStartDate, parsedEndDate);

  // eslint-disable-next-line no-underscore-dangle
  const href = `https://admin${window.__DEV__ ? '-dev' : ''}.pylot.app/incident/${activeIncident?.id}`;


  const compartmentId = activeIncident?.nodeId?.compartmentId;
  const otherCompartmentIds = activeIncident?.otherCompartments?.ids || [];

  const otherCompartments = otherCompartmentIds.map(id => allCompartments.find(item => item.id === id));
  const compartment = allCompartments.find(item => item.id === compartmentId);

  const subNodeId = activeIncident?.nodeId?.subNodeId;
  const subNode = allSubNodes.find(item => item.id === subNodeId);

  let likeLabel = null;
  const like = get(activeIncident, 'like', null);
  if (like) {
    const likeStart = get(like, 'timeRange.start');
    const likeCompartment = allCompartments.find(item => item.id === get(like, 'nodeId.compartmentId'));
    if (likeCompartment && likeStart) {
      const likeStartDate = parseISO(likeStart);
      const likeStartText = formatDateLocalized(likeStartDate, 'lll hh');
      likeLabel = `${likeStartText} (${get(compartment, 'attributes.name')})`;
    }
  }

  const problems = get(activeIncident, 'problems', []);

  const incidentTypeId = activeIncident?.incidentType?.id;
  const detailsVars = activeIncident?.vars;
  const title = getNameByLocal(activeIncident?.incidentType?.payload, locale);

  return (
    <div className={classnames(styles.panel, { [styles.collapsed]: panelCollapsed })}>
      {isIncidentFetching && <DefaultCircleLoader />}
      <div
        className={styles.header}
      >
        <DefaultSimpleTooltip text={title} position='bottom' noWrap ellipsis>
          <div
            className={styles.headerText}
          >
            {title}
          </div>
        </DefaultSimpleTooltip>
        <div className={styles.buttonsWrapper}>
          <DefaultSimpleTooltip text={formatMessage({ id: 'feedback.rateButton' })} position='bottom' noWrap>
            <RateIncidentButton
              activeIncidentId={activeIncident?.id}
              feedback={feedback}
              withoutText
            />
          </DefaultSimpleTooltip>
          <DefaultSimpleTooltip text={formatMessage({ id: 'forecast.dashboard.menu.openSettings' })} position='bottom' noWrap>
            <NavLink
              className={styles.settingsLink}
              to={`/${organizationSlug}/settings/incidents/${incidentTypeId}`}
              exact
            >
              <SettingsIcon className={styles.icon} />
            </NavLink>
          </DefaultSimpleTooltip>
          <DefaultSimpleTooltip text={formatMessage({ id: 'dialog.close' })} position='bottom' noWrap>
            <button type='button' className={styles.iconButton} onClick={handlerIncidentCollapse}>
              <CloseIcon className={styles.icon} />
            </button>
          </DefaultSimpleTooltip>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.section}>
          <div className={styles.header}>
            <FormattedMessage id='graphIncidents.parameters' />
          </div>
          <div className={styles.body}>
            <table className={styles.table}>
              <tbody>
                {parsedStartDate && parsedEndDate && (
                  <tr className={styles.row}>
                    <th className={styles.headerCell}>
                      <div className={styles.headerCellContent}>
                        <FormattedMessage id='graphIncidents.time' />
                      </div>
                    </th>
                    <td className={styles.cell}>
                      <div className={styles.cellContent}>
                        {formattedDateRange}
                      </div>
                    </td>
                  </tr>
                )}
                {compartment && (
                  <tr className={styles.row}>
                    <th className={styles.headerCell}>
                      <div className={styles.headerCellContent}>
                        <FormattedMessage id='incidentsTimeline.location' />
                      </div>
                    </th>
                    <td className={styles.cell}>
                      <div className={styles.cellContent}>
                        {get(compartment, 'attributes.name')}
                        {subNode && `, ${formatMessage({ id: 'incidentsTimeline.climateZone' })} ${get(subNode, 'attributes.name')}`}
                      </div>
                    </td>
                  </tr>
                )}
                {otherCompartments && otherCompartments.length ? (
                  <tr className={styles.row}>
                    <th className={styles.headerCell}>
                      <div className={styles.headerCellContent}>
                        <FormattedMessage id='graphIncidents.otherCompartments' />
                      </div>
                    </th>
                    <td className={styles.cell}>
                      <div className={styles.cellContent}>
                        {
                          otherCompartments.map(item => get(item, 'attributes.name')).join(', ')
                        }
                      </div>
                    </td>
                  </tr>
                ) : null}
                {get(activeIncident, 'severity') && (
                <tr className={styles.row}>
                  <th className={styles.headerCell}>
                    <div className={styles.headerCellContent}>
                      <FormattedMessage id='graphIncidents.severity' />
                    </div>
                  </th>
                  <td className={styles.cell}>
                    <div className={styles.cellContent}>
                      <div className={styles.label}>
                        <FormattedMessage id={`incidentsTimeline.${get(activeIncident, 'severity')}`} />
                        <div className={styles.severityColor} style={{ background: getSeverityColor(activeIncident?.severity) }} />
                      </div>
                    </div>
                  </td>
                </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {(detailsVars && detailsVars?.length > 0) && (
          <div className={styles.section}>
            <div className={styles.header}>
              <FormattedMessage id='incidentsTimeline.incidentDetails' />
              <NavLink
                className={styles.link}
                to={`/${organizationSlug}/settings/incidents/${incidentTypeId}`}
                exact
              >
                <FormattedMessage id='incidentsTimeline.viewDescription' />
              </NavLink>
            </div>
            <div className={styles.body}>
              {detailsVars.map((varItem, index) => {
                const varName = getNameByLocal(varItem, locale);
                const varValue = varItem?.value;
                const varUnits = varItem?.units ? formatMessage({ id: `cunits.mini.${varItem?.units}` }) : '';
                const comma = index === (detailsVars?.length - 1) ? '' : ',';

                return (
                  <span className={styles.incidentDetails}>{`${varName} = ${varValue}${varUnits}${comma}`}</span>
                );
              })}
            </div>
          </div>
        )}

        <div className={styles.section}>
          <div className={styles.header}>
            <FormattedMessage id='incidentsTimeline.metricsSnapshot' />
            <NavLink
              className={styles.link}
              to={graphsHref}
              exact
              target='_blank'
            >
              <FormattedMessage id='dashboards.viewInGraphs' />
            </NavLink>
          </div>
          <div className={classnames(styles.body, styles.chartWrapper)}>
            {activeIncidentPlot ? (
              <CustomChart
                plot={activeIncidentPlot}
                isDataFetching={isIncidentPlotFetching}
                chartSize={{ height: 146 }}
              />
              )
              :
              <DefaultCircleLoader />}
          </div>
        </div>

        {isExpert && (
          <div className={styles.section}>
            <div className={styles.header}>
              <FormattedMessage id='incidentsTimeline.adminDetails' />
              <a
                href={href}
                className={styles.link}
                // eslint-disable-next-line no-underscore-dangle
                target={`Pylot ${window.__DEV__ ? 'Dev' : ''} Admin`}
              >
                <FormattedMessage id='incidentsTimeline.viewInAdmin' />
                <LinkIcon />
              </a>
            </div>
            <div className={styles.body}>
              <table className={styles.table}>
                <tbody>
                  {get(activeIncident, 'status') && (
                  <tr className={styles.row}>
                    <th className={styles.headerCell}>
                      <div className={styles.headerCellContent}>
                        <FormattedMessage id='graphIncidents.status' />
                      </div>
                    </th>
                    <td className={styles.cell}>
                      <div className={styles.cellContent}>
                        <FormattedMessage id={`graphIncidents.${get(activeIncident, 'status')}`} />
                      </div>
                    </td>
                  </tr>
                  )}
                  {get(activeIncident, 'whoCreated') && (
                  <tr className={styles.row}>
                    <th className={styles.headerCell}>
                      <div className={styles.headerCellContent}>
                        <FormattedMessage id='graphIncidents.registeredBy' />
                      </div>
                    </th>
                    <td className={styles.cell}>
                      <div className={styles.cellContent}>
                        {get(activeIncident, 'whoCreated', null)}
                      </div>
                    </td>
                  </tr>
                  )}
                  {like && (
                  <tr className={styles.row}>
                    <th className={styles.headerCell}>
                      <div className={styles.headerCellContent}>
                        <FormattedMessage id='graphIncidents.like' />
                      </div>
                    </th>
                    <td className={styles.cell}>
                      <div className={styles.cellContent}>
                        <button type='button' className={styles.button} onClick={handlerLikeIncidentClick}>
                          {likeLabel}
                        </button>
                      </div>
                    </td>
                  </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {isExpert && get(activeIncident, 'internalComment') && (
          <div className={styles.section}>
            <div className={styles.header}>
              <FormattedMessage id='graphIncidents.internalComment' />
            </div>
            <div className={styles.body}>
              <div className={styles.simpleText}>
                {get(activeIncident, 'internalComment', null)}
              </div>
            </div>
          </div>
        )}

        {get(activeIncident, 'problems', []).length > 0 && (
          <div className={styles.section}>
            <div className={styles.header}>
              <FormattedMessage id='graphIncidents.causes' />
            </div>
            <div className={styles.body}>
              {problems.length > 0 && (
                problems.map(issue => (
                  <AbnormalityIncidentIssue
                    key={`issue-${get(issue, 'localId')}`}
                    issue={issue}
                    locale={locale}
                    allGraphMetrics={allGraphMetrics}
                  />
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

IncidentInfoPanel.propTypes = {
  intl: intlShape.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  activeIncident: PropTypes.object,
  isIncidentFetching: PropTypes.bool,
  panelCollapsed: PropTypes.bool,
  allCompartments: PropTypes.array,
  allGraphMetrics: PropTypes.array,
  allSubNodes: PropTypes.array,
  feedback: PropTypes.object,
  onCollapse: PropTypes.func,
  isExpert: PropTypes.bool,
  activeIncidentPlot: PropTypes.object,
  isIncidentPlotFetching: PropTypes.bool,
};

IncidentInfoPanel.defaultProps = {
  activeIncident: null,
  isIncidentFetching: false,
  allCompartments: [],
  allGraphMetrics: [],
  allSubNodes: [],
  onCollapse: () => {},
  panelCollapsed: false,
  feedback: null,
  isExpert: false,
  activeIncidentPlot: undefined,
  isIncidentPlotFetching: false,
};

export default IncidentInfoPanel;
