import { combineEpics } from 'redux-observable';

import rootHarvestKpiDashboardEpic from './harvestKpiDashboard/epics';
import rootEnergyEpic from './energy/epics';
import rootLaborEpic from './labor/epics';
import rootDigitalTwinEpic from './digitalTwin/epics';
import rootTablesEpic from './tables/epics';
import rootPlanFactReportEpic from './planFactReport/epics';

import {
  getHarvestPlansEpic,
  getHarvestPlansListEpic,
} from './plans/epics';

import {
  actionAfterSuccessEpic,
  getAllPlanVersionsEpic,
  getPlanVersionByIdEpic,
  publishPlanByIdEpic,
  getDefaultPlanVersionEpic,
  savePlanEpic,
  savePlanByIdEpic,
  restorePlanEpic,
  restorePlanByIdEpic,
  saveAndPublishPlanEpic,
  publishPlanEpic,
  saveVersionInfoEpic,
  deleteVersionInfoEpic,
  requestPlanVersionSummaryEpic,
  getPlanForecastEpic,
} from './plansEditor/epics';

import { getHarvestDataByCategoriesEpic } from './harvest/epics';

import { removeNotificationEpic } from './notificationCenter/epics';

import {
  getClimateIncidentsEpic,
  getClimateIncidentsForGraphsEpic,
  getClimateIncidentsDetailEpic,
  setClimateIncidentsDayEpic,
  createAbnormalityIncidentEpic,
  checkAbnormalityIncidentEpic,
  updateAbnormalityIncidentEpic,
  deleteAbnormalityIncidentEpic,
} from './climateInsights/epics';

import {
  startedGetCropsPerformanceEpic,
  succeededGetCropsPerformanceEpic,
  failedGetCropsPerformanceEpic,
  receiveCropsDataEpic,
} from './cropsPerformance/epics';

import {
  getUnrealizedPotentialArgumentsEpic,
  getUnrealizedPotentialEpic
} from './unrealizedPotential/epics';

import {
  startedGetCropsMetricsEpic,
  failedGetCropsMetricsEpic,
  succeededGetCropsMetricsEpic,
  startedGetCropsObservationsEpic,
  failedGetCropsObservationsEpic,
  succeededGetCropsObservationsEpic,
  startedCreateCropsObservationsEpic,
  failedCreateCropsObservationsEpic,
  succeededCreateCropsObservationsEpic,
  startedCreatePlantingCycleEpic,
  failedCreatePlantingCycleEpic,
  succeededCreatePlantingCycleEpic,
  endedCreatePlantingCycleEpic,
  startedGetPlantingCycleEpic,
  failedGetPlantingCycleEpic,
  succeededGetPlantingCycleEpic,
  startedUpdatePlantingCycleEpic,
  failedUpdatePlantingCycleEpic,
  succeededUpdatePlantingCycleEpic,
  endedUpdatePlantingCycleEpic,
  requestDeletePlantingCycleEpic,
  receiveDeletePlantingCycleEpic,
} from './crops/epics';

import {
  getUserPrivilegesEpic,
  updateUserInfoEpic,
  updateUserLocaleEpic
} from './user/epics';

import { getDataQualityReportEpic, getOverallQualityEpic } from './dataQuality/epics';

import rootIncidentsEpic from './incidents/epics';

import rootBenchmarkEpic from './benchmark/epics';

import rootCompanyEpic from './company/epics';

import rootGraphsMetrics from './graphs/epics';

import rootMeasurementsEpic from './measurements/epics';

import harvestForecastEpic from './harvestForecast/epics';

import rootLoginEpic from './login/epics';

import rootCropReportsEpic from './cropReports/epics';

import climateInsightsTimelineEpic from './climateInsightsTimeline/epic';

import newCropsEpic from './newCrops/epic';

const rootEpic = (...args) => combineEpics(
  removeNotificationEpic,

  startedGetCropsMetricsEpic,
  failedGetCropsMetricsEpic,
  succeededGetCropsMetricsEpic,
  startedGetCropsObservationsEpic,
  failedGetCropsObservationsEpic,
  succeededGetCropsObservationsEpic,
  startedCreateCropsObservationsEpic,
  failedCreateCropsObservationsEpic,
  succeededCreateCropsObservationsEpic,
  startedGetPlantingCycleEpic,
  failedGetPlantingCycleEpic,
  succeededGetPlantingCycleEpic,
  startedCreatePlantingCycleEpic,
  failedCreatePlantingCycleEpic,
  succeededCreatePlantingCycleEpic,
  endedCreatePlantingCycleEpic,
  startedUpdatePlantingCycleEpic,
  failedUpdatePlantingCycleEpic,
  succeededUpdatePlantingCycleEpic,
  endedUpdatePlantingCycleEpic,
  requestDeletePlantingCycleEpic,
  receiveDeletePlantingCycleEpic,

  startedGetCropsPerformanceEpic,
  succeededGetCropsPerformanceEpic,
  failedGetCropsPerformanceEpic,
  receiveCropsDataEpic,

  getHarvestDataByCategoriesEpic,

  getHarvestPlansEpic,
  getHarvestPlansListEpic,

  actionAfterSuccessEpic,

  // getNodeMetricsFromUrlEpic,

  getClimateIncidentsEpic,
  getClimateIncidentsForGraphsEpic,
  getClimateIncidentsDetailEpic,
  setClimateIncidentsDayEpic,
  createAbnormalityIncidentEpic,
  checkAbnormalityIncidentEpic,
  updateAbnormalityIncidentEpic,
  deleteAbnormalityIncidentEpic,

  rootHarvestKpiDashboardEpic,

  getUserPrivilegesEpic,
  updateUserInfoEpic,

  getAllPlanVersionsEpic,
  getPlanVersionByIdEpic,
  publishPlanByIdEpic,
  getDefaultPlanVersionEpic,
  savePlanEpic,
  savePlanByIdEpic,
  restorePlanEpic,
  restorePlanByIdEpic,
  saveAndPublishPlanEpic,
  publishPlanEpic,
  saveVersionInfoEpic,
  deleteVersionInfoEpic,

  requestPlanVersionSummaryEpic,

  getDataQualityReportEpic,
  getOverallQualityEpic,

  rootIncidentsEpic,

  getPlanForecastEpic,

  getUnrealizedPotentialEpic,
  getUnrealizedPotentialArgumentsEpic,

  rootGraphsMetrics,
  rootMeasurementsEpic,
  harvestForecastEpic,

  rootEnergyEpic,
  rootLaborEpic,

  rootDigitalTwinEpic,
  rootTablesEpic,

  rootPlanFactReportEpic,

  rootBenchmarkEpic,

  rootCompanyEpic,

  rootLoginEpic,

  rootCropReportsEpic,

  climateInsightsTimelineEpic,
  newCropsEpic,

  updateUserLocaleEpic
)(...args);

export default rootEpic;
