import { Observable } from 'rxjs';
import { get } from 'lodash';
import queryString from 'query-string';

import initAxiosInstanse from '../../api/axios';
import { cleanServiceInfoJson } from '../../helpers/generateGrid';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

import {
  actionAfterSuccess,

  requestAllPlanVersions,
  receiveAllPlanVersions,

  requestPlanVersionById,
  receivePlanVersionById,

  requestPublishPlanById,
  receivePublishPlanById,

  requestDefaultPlanVersion,
  receiveDefaultPlanVersion,

  requestSavePlan,
  requestSavePlanById,
  receiveSavePlanById,

  requestRestorePlan,
  requestRestorePlanById,
  receiveRestorePlanById,

  requestSaveAndPublishPlan,
  requestPublishPlan,
  receivePublishPlan,

  requestPlanVersionSummary,
  receivePlanVersionSummary,

  requestSaveVersionInfo,
  receiveSaveVersionInfo,

  requestDeleteVersionInfo,
  receiveDeleteVersionInfo,

  requestPlanForecast,
  receivePlanForecast,
} from './actions';

const getPostOrPatchByQuery = (requestPath, requestBody, queryParams) => {
  if (queryParams !== '') {
    return initAxiosInstanse().patch(`${requestPath}?${queryParams}`, requestBody);
  }

  return initAxiosInstanse().post(requestPath, requestBody);
};

const getPostOrPatch = (method, requestPath, requestBody, queryParams) => {
  const requestPathWithQuery = queryParams !== '' ? `${requestPath}?${queryParams}` : requestPath;

  if (method === 'patch') {
    return initAxiosInstanse().patch(requestPathWithQuery, requestBody);
  }

  return initAxiosInstanse().post(requestPathWithQuery, requestBody);
};

export const actionAfterSuccessEpic = action$ =>
  action$.ofType(actionAfterSuccess)
    .switchMap((actionData) => {
      const {
        payload: {
          actionAfterSave,
        }
      } = actionData;

      if (actionAfterSave) {
        actionAfterSave();
      }

      return Observable.of();
    });

export const getAllPlanVersionsEpic = (action$, store) =>
  action$.ofType(requestAllPlanVersions)
    .switchMap((actionData) => {
      const {
        company,
      } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          year,
          isOperationalPlan,
          planId,
        }
      } = actionData;

      const planHistoryRoute = isOperationalPlan ?
        `harvest-plan-history/location-plan/${planId}/${year}`
        :
        `harvest-plan-history/${organizationId}/${year}`;

      return Observable
        .from(initAxiosInstanse().get(planHistoryRoute))
        .map(({ data }) => receiveAllPlanVersions({ allPlanVersions: data }))
        .catch(() => Observable.of(
          receiveAllPlanVersions(),
          showNotificationWithTimeout({
            id: `notifications.getHarvestPlansEditorError.${Date.now()}`,
            messageId: 'notifications.getHarvestPlansEditorError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getPlanVersionByIdEpic = action$ =>
  action$.ofType(requestPlanVersionById)
    .switchMap((actionData) => {
      const {
        payload: {
          planId,

          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
          productGroup,
          compartmentId,
          species,
        }
      } = actionData;

      const queryParams = queryString.stringify({
        anyDateOfPeriodStart,
        anyDateOfPeriodEnd,
        productGroup,
        compartmentId,
        species,
      });

      return Observable
        .from(initAxiosInstanse().get(`harvest-plan-history/${planId}?${queryParams}`))
        .map(({ data }) => receivePlanVersionById({
          harvestPlan: data,
          harvestPlanEdited: data,
        }))
        .catch(() => Observable.of(
          receivePlanVersionById(),
          showNotificationWithTimeout({
            id: `notifications.getHarvestPlansEditorError.${Date.now()}`,
            messageId: 'notifications.getHarvestPlansEditorError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const saveAndPublishPlanEpic = action$ =>
  action$.ofType(requestSaveAndPublishPlan)
    .switchMap((actionData) => {
      const {
        payload: {
          modifiedHarvestPlan,
          actionAfterSave,

          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
          productGroup,
          compartmentId,
          species,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse().get('/harvest-plan-history/new-id'))
        .map(({ data }) => requestPublishPlan({
          newId: data.id,
          modifiedHarvestPlan,
          actionAfterSave,

          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
          productGroup,
          compartmentId,
          species,
        }))
        .catch(() => Observable.of(
          receivePublishPlan(),
          showNotificationWithTimeout({
            id: `notifications.getHarvestPlansEditorError.${Date.now()}`,
            messageId: 'notifications.getHarvestPlansEditorError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const publishPlanEpic = action$ =>
  action$.ofType(requestPublishPlan)
    .switchMap((actionData) => {
      const {
        payload: {
          newId,
          modifiedHarvestPlan,
          actionAfterSave,

          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
          productGroup,
          compartmentId,
          species,
        }
      } = actionData;

      const queryParams = queryString.stringify({
        anyDateOfPeriodStart,
        anyDateOfPeriodEnd,
        productGroup,
        compartmentId,
        species,
      });

      const cleanedModifiedPlan = cleanServiceInfoJson(modifiedHarvestPlan);
      const cleanedModifiedPlanWithNewId = { ...cleanedModifiedPlan, id: newId };

      return Observable
        .from(getPostOrPatchByQuery('harvest-plan-history/publish', cleanedModifiedPlanWithNewId, queryParams))
        .mergeMap(() => Observable.of(
          receivePublishPlan({ withoutSaveDate: Date.now() }),
          actionAfterSuccess({ actionAfterSave }),
        ))
        .catch(() => Observable.of(
          receivePublishPlan(),
          showNotificationWithTimeout({
            id: `notifications.saveHarvestPlansEditorError.${Date.now()}`,
            messageId: 'notifications.saveHarvestPlansEditorError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const publishPlanByIdEpic = action$ =>
  action$.ofType(requestPublishPlanById)
    .switchMap((actionData) => {
      const {
        payload: {
          planId,
          actionAfterSave,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse().post(`harvest-plan-history/${planId}/publish`))
        .mergeMap(({ data }) => Observable.of(
          receivePublishPlanById({
            harvestPlan: data,
            harvestPlanEdited: data,
          }),
          requestAllPlanVersions({ year: data.year }),
          actionAfterSuccess({ actionAfterSave }),
        ))
        .catch(() => Observable.of(
          receivePublishPlanById(),
          showNotificationWithTimeout({
            id: `notifications.saveHarvestPlansEditorError.${Date.now()}`,
            messageId: 'notifications.saveHarvestPlansEditorError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const restorePlanEpic = action$ =>
  action$.ofType(requestRestorePlan)
    .switchMap((actionData) => {
      const {
        payload: {
          planId,
          actionAfterSave,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse().get('/harvest-plan-history/new-id'))
        .map(({ data }) => requestRestorePlanById({
          planId,
          newId: data.id,
          actionAfterSave,
        }))
        .catch(() => Observable.of(
          receiveRestorePlanById(),
          showNotificationWithTimeout({
            id: `notifications.getHarvestPlansEditorError.${Date.now()}`,
            messageId: 'notifications.getHarvestPlansEditorError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const restorePlanByIdEpic = action$ =>
  action$.ofType(requestRestorePlanById)
    .switchMap((actionData) => {
      const {
        payload: {
          planId,
          newId,
          actionAfterSave,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse().post(`harvest-plan-history/${planId}/restore`, { newId }))
        .mergeMap(({ data }) => Observable.of(
          receiveRestorePlanById({
            harvestPlan: data,
            harvestPlanEdited: data,
          }),
          actionAfterSuccess({ actionAfterSave }),
          requestAllPlanVersions({ year: data.year }),
        ))
        .catch(() => Observable.of(
          receiveRestorePlanById(),
          showNotificationWithTimeout({
            id: `notifications.saveHarvestPlansEditorError.${Date.now()}`,
            messageId: 'notifications.saveHarvestPlansEditorError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const savePlanEpic = action$ =>
  action$.ofType(requestSavePlan)
    .switchMap((actionData) => {
      const {
        payload: {
          modifiedHarvestPlan,
          actionAfterSave,

          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
          productGroup,
          compartmentId,
          species,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse().get('/harvest-plan-history/new-id'))
        .map(({ data }) => requestSavePlanById({
          newId: data.id,
          modifiedHarvestPlan,
          actionAfterSave,

          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
          productGroup,
          compartmentId,
          species,
        }))
        .catch(() => Observable.of(
          receiveSavePlanById(),
          showNotificationWithTimeout({
            id: `notifications.getHarvestPlansEditorError.${Date.now()}`,
            messageId: 'notifications.getHarvestPlansEditorError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const savePlanByIdEpic = action$ =>
  action$.ofType(requestSavePlanById)
    .switchMap((actionData) => {
      const {
        payload: {
          newId,
          modifiedHarvestPlan,
          actionAfterSave,

          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
          productGroup,
          compartmentId,
          species,
        }
      } = actionData;

      const queryParams = queryString.stringify({
        anyDateOfPeriodStart,
        anyDateOfPeriodEnd,
        productGroup,
        compartmentId,
        species,
      });

      const cleanedModifiedPlan = cleanServiceInfoJson(modifiedHarvestPlan);
      const cleanedModifiedPlanWithNewId = { ...cleanedModifiedPlan, id: newId };

      return Observable
        .from(getPostOrPatchByQuery('harvest-plan-history', cleanedModifiedPlanWithNewId, queryParams))
        .mergeMap(({ data }) => Observable.of(
          receiveSavePlanById({
            withoutSaveDate: Date.now(),
            harvestPlan: data,
            harvestPlanEdited: data,
          }),
          requestAllPlanVersions({ year: data.year }),
          actionAfterSuccess({ actionAfterSave }),
        ))
        .catch(() => Observable.of(
          receiveSavePlanById(),
          showNotificationWithTimeout({
            id: `notifications.saveHarvestPlansEditorError.${Date.now()}`,
            messageId: 'notifications.saveHarvestPlansEditorError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getDefaultPlanVersionEpic = (action$, store) =>
  action$.ofType(requestDefaultPlanVersion)
    .switchMap((actionData) => {
      const {
        company,
      } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          year,
          isOperationalPlan,
          planId,

          harvestPlanEdited,
          withoutSaveDate,

          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
          productGroup,
          compartmentId,
          species,
        }
      } = actionData;

      const queryParams = queryString.stringify({
        anyDateOfPeriodStart,
        anyDateOfPeriodEnd,
        productGroup,
        compartmentId,
        species,
      });

      const defaultPlanRoute = isOperationalPlan ?
        `harvest-plan-history/location-plan/${planId}/${year}/default?${queryParams}`
        :
        `harvest-plan-history/${organizationId}/${year}/default?${queryParams}`;

      return Observable
        .from(initAxiosInstanse().get(defaultPlanRoute))
        .map(({ data }) => receiveDefaultPlanVersion({
          harvestPlan: data,
          harvestPlanEdited: harvestPlanEdited || data,
          withoutSaveDate,
        }))
        .catch(() => Observable.of(
          receivePlanVersionById(),
          showNotificationWithTimeout({
            id: `notifications.getHarvestPlansEditorError.${Date.now()}`,
            messageId: 'notifications.getHarvestPlansEditorError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const requestPlanVersionSummaryEpic = (action$, store) =>
  action$.ofType(requestPlanVersionSummary)
    .switchMap((actionData) => {
      const {
        payload: {
          relative,
          groupedBy,
          year,
          isDetachedComponent,

          periodLength,
          locationPlan,
          isOperationalPlan,

          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
          productGroup,
          compartmentId,
          species,
          fruitClassCode,
          varietyId,
        }
      } = actionData;

      const {
        company: {
          location,
        }
      } = store.getState();

      const { id: locationId } = location;

      const planSummaryRoute = locationPlan ?
        `/harvest-plan-history/location-plan/${locationPlan}/summary/${year}`
        :
        `/harvest-plan-history/summary/${locationId}/${year}`;

      if (isDetachedComponent) {
        return Observable
          .from(initAxiosInstanse().get(`${planSummaryRoute}?relative=${relative}&groupBy=${groupedBy}&periodLength=${periodLength}`))
          .map(({ data }) => receivePlanVersionSummary({ harvestPlanSummary: data }))
          .catch(() => Observable.of(
            receivePlanVersionSummary({ harvestPlanSummary: {} }),
            showNotificationWithTimeout({
              id: `notifications.getHarvestPlansEditorError.${Date.now()}`,
              messageId: 'notifications.getHarvestPlansEditorError',
              position: 'leftDown',
              iconType: 'error',
              notificationType: 'withActionWide',
            }),
          ));
      }

      const {
        plansEditor: {
          harvestPlanEdited,
        }
      } = store.getState();

      if (!Array.isArray(get(harvestPlanEdited, 'data'))) {
        return Observable.of(receivePlanVersionSummary({ harvestPlanSummary: null }));
      }

      const cleanedModifiedPlan = cleanServiceInfoJson(harvestPlanEdited);

      const queryParams = queryString.stringify({
        relative,
        groupBy: groupedBy,
        periodLength,

        anyDateOfPeriodStart,
        anyDateOfPeriodEnd,
        productGroup,
        compartmentId,
        species,
        fruitClassCode,
        varietyId,
      });

      const method = isOperationalPlan ? 'patch' : 'post';

      return Observable
        .from(getPostOrPatch(method, 'harvest-plan-history/summary', cleanedModifiedPlan, queryParams))
        .map(({ data }) => receivePlanVersionSummary({ harvestPlanSummary: data }))
        .catch(() => Observable.of(
          receivePlanVersionSummary({ harvestPlanSummary: null }),
          showNotificationWithTimeout({
            id: `notifications.getHarvestPlansEditorError.${Date.now()}`,
            messageId: 'notifications.getHarvestPlansEditorError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });


export const saveVersionInfoEpic = action$ =>
  action$.ofType(requestSaveVersionInfo)
    .switchMap((actionData) => {
      const {
        payload: {
          planId,
          label,
          comment,
          actionSuccess,
        },
      } = actionData;

      return Observable
        .from(initAxiosInstanse().post(`harvest-plan-history/${planId}/label`, {
          label,
          comment,
        }))
        .mergeMap(({ data }) => Observable.of(
          actionSuccess,
          receiveSaveVersionInfo(),
          requestAllPlanVersions({ year: data.year }),
          // showNotificationWithTimeout({
          //   id: `notifications.saveVersionInfoSuccess.${Date.now()}`,
          //   messageId: 'notifications.saveVersionInfoSuccess',
          //   position: 'leftDown',
          //   iconType: 'success',
          //   notificationType: 'withActionWide',
          // })
        ))
        .catch(() => Observable.of(
          receiveSaveVersionInfo(),
          showNotificationWithTimeout({
            id: `notifications.getHarvestPlansEditorError.${Date.now()}`,
            messageId: 'notifications.getHarvestPlansEditorError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const deleteVersionInfoEpic = action$ =>
  action$.ofType(requestDeleteVersionInfo)
    .switchMap((actionData) => {
      const {
        payload: {
          planId,
          actionSuccess,
        },
      } = actionData;

      return Observable
        .from(initAxiosInstanse().delete(`harvest-plan-history/${planId}/label`))
        .mergeMap(({ data }) => Observable.of(
          actionSuccess,
          receiveDeleteVersionInfo(),
          requestAllPlanVersions({ year: data.year }),
        ))
        .catch(() => Observable.of(
          receiveDeleteVersionInfo(),
          showNotificationWithTimeout({
            id: `notifications.getHarvestPlansEditorError.${Date.now()}`,
            messageId: 'notifications.getHarvestPlansEditorError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getPlanForecastEpic = (action$, store) =>
  action$.ofType(requestPlanForecast)
    .switchMap((actionData) => {
      const {
        company,
      } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          compartmentId,
          date,
          fruitClass,
          varietyId,
          actionSuccess,
        },
      } = actionData;

      const queryParams = queryString.stringify({
        date,
        fruitClass,
        varietyId,
      });

      const query = queryParams ? `${queryParams}` : '';

      return Observable
        .from(initAxiosInstanse().get(`plan-forecast/${organizationId}/${compartmentId}?${query}`))
        .mergeMap(({ data }) => {
          const errorCode = get(data, 'errorCode');

          if (errorCode === 'UNSUPPORTED_FRUIT_CLASS' || errorCode === 'UNSUPPORTED_SPECIES') {
            return Observable.of(
              receivePlanForecast(),
              showNotificationWithTimeout({
                id: `plans.varietyNotSupported.${Date.now()}`,
                messageId: 'plans.varietyNotSupported',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withActionWide',
              }),
            );
          }

          return Observable.of(
            () => actionSuccess(data),
            receivePlanForecast(),
          );
        })
        .catch(() => Observable.of(
          receivePlanForecast(),
          showNotificationWithTimeout({
            id: `plans.cannotCalculate.${Date.now()}`,
            messageId: 'plans.cannotCalculate',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });
