import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import Page from '../../../components/Page';


class Layout extends Component {
  static propTypes = {
    getLocation: PropTypes.func.isRequired,
    setIntl: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    userProfile: PropTypes.object,
    locations: PropTypes.array,
    locale: PropTypes.string,

    errorDialogMessage: PropTypes.string,
    errorDialogOpen: PropTypes.bool.isRequired,
    photoDetailDialogOpen: PropTypes.bool.isRequired,
    photoUploadDialogOpen: PropTypes.bool.isRequired,
    photoDeleteDialogOpen: PropTypes.bool.isRequired,
    cropCreateDialogOpen: PropTypes.bool.isRequired,
    tableCellEditDialogOpen: PropTypes.bool.isRequired,
    graphPresetChangeWorkspaceDialogOpen: PropTypes.bool.isRequired,
    graphPresetCreateDialogOpen: PropTypes.bool.isRequired,
    graphPresetUpdateDialogOpen: PropTypes.bool.isRequired,
    graphPresetDeleteDialogOpen: PropTypes.bool.isRequired,
    graphPresetDatesDialogOpen: PropTypes.bool.isRequired,
    graphPresetParametersDialogOpen: PropTypes.bool.isRequired,
    sidePanelOpen: PropTypes.bool,

    isFetching: PropTypes.bool,
    isStatic: PropTypes.bool,
    invalidate: PropTypes.bool,

    setToggleTableCellEditDialog: PropTypes.func.isRequired,
    setToggleGraphPresetDatesDialog: PropTypes.func.isRequired,
    setToggleGraphPresetParametersDialog: PropTypes.func.isRequired,
    setToggleGraphPresetCreateDialog: PropTypes.func.isRequired,
    setToggleGraphPresetUpdateDialog: PropTypes.func.isRequired,
    setToggleGraphPresetDeleteDialog: PropTypes.func.isRequired,
    setToggleErrorDialog: PropTypes.func.isRequired,
    setTogglePhotoDetailDialog: PropTypes.func.isRequired,
    setTogglePhotoUploadDialog: PropTypes.func.isRequired,
    setTogglePhotoDeleteDialog: PropTypes.func.isRequired,
    setToggleCropCreateDialog: PropTypes.func.isRequired,

    clearCardsState: PropTypes.func.isRequired,

    setNavigationMenuState: PropTypes.func.isRequired,
    isNavigationMenuOpened: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    organization: PropTypes.object,
    isLocationSpecificUrl: PropTypes.bool,
    isNoScroll: PropTypes.bool,
    clearCompanyStore: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userProfile: null,
    organization: null,
    locations: null,
    locale: 'en',
    errorDialogMessage: '',
    isFetching: false,
    invalidate: false,
    sidePanelOpen: false,
    isLocationSpecificUrl: false,
    isStatic: false,
    isNoScroll: false,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handlerKeyDown);

    if (window.Intercom) {
      window.Intercom('update');
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handlerKeyDown);
  }

  handlerKeyDown = (event) => {
    const {
      setToggleErrorDialog,
      setTogglePhotoDetailDialog,
      setTogglePhotoUploadDialog,
      setTogglePhotoDeleteDialog,
      setToggleCropCreateDialog,
      setToggleGraphPresetDeleteDialog,
      setToggleGraphPresetUpdateDialog,
      setToggleGraphPresetCreateDialog,
      setToggleGraphPresetDatesDialog,
      setToggleGraphPresetParametersDialog,
      setToggleTableCellEditDialog,

      graphPresetDatesDialogOpen,
      graphPresetParametersDialogOpen,
      graphPresetCreateDialogOpen,
      graphPresetUpdateDialogOpen,
      graphPresetDeleteDialogOpen,
      photoDeleteDialogOpen,
      photoDetailDialogOpen,
      photoUploadDialogOpen,
      tableCellEditDialogOpen,
      cropCreateDialogOpen,
      errorDialogOpen,
    } = this.props;

    if (event.key === 'Escape' || event.keyCode === 27) {
      if (photoDeleteDialogOpen) {
        return setTogglePhotoDeleteDialog(false);
      }

      if (photoDetailDialogOpen) {
        return setTogglePhotoDetailDialog(false);
      }

      if (cropCreateDialogOpen) {
        return setToggleCropCreateDialog(false);
      }

      if (photoUploadDialogOpen) {
        return setTogglePhotoUploadDialog(false);
      }

      if (graphPresetDeleteDialogOpen) {
        return setToggleGraphPresetDeleteDialog(false);
      }

      if (graphPresetCreateDialogOpen) {
        return setToggleGraphPresetCreateDialog(false);
      }

      if (graphPresetUpdateDialogOpen) {
        return setToggleGraphPresetUpdateDialog(false);
      }

      if (graphPresetDatesDialogOpen) {
        return setToggleGraphPresetDatesDialog(false);
      }

      if (graphPresetParametersDialogOpen) {
        return setToggleGraphPresetParametersDialog(false);
      }

      if (tableCellEditDialogOpen) {
        return setToggleTableCellEditDialog(false);
      }

      if (errorDialogOpen) {
        return setToggleErrorDialog(false);
      }
    }
    return null;
  };

  render() {
    const {
      setToggleErrorDialog,
      locale,
      children,
      userProfile,
      organization,
      locations,
      location,
      getLocation,
      errorDialogMessage,
      setIntl,
      isFetching,
      invalidate,

      graphPresetDatesDialogOpen,
      graphPresetParametersDialogOpen,
      graphPresetCreateDialogOpen,
      graphPresetUpdateDialogOpen,
      graphPresetDeleteDialogOpen,
      graphPresetChangeWorkspaceDialogOpen,
      photoDeleteDialogOpen,
      photoDetailDialogOpen,
      photoUploadDialogOpen,
      cropCreateDialogOpen,
      tableCellEditDialogOpen,
      errorDialogOpen,
      sidePanelOpen,

      clearCardsState,

      setNavigationMenuState,
      isNavigationMenuOpened,
      isLocationSpecificUrl,
      isStatic,
      isNoScroll,
      clearCompanyStore,
    } = this.props;

    return (
      <Page
        // TODO: переделать, перенести в обёртку рута и тут мерджить пропсы
        isNoScroll={isNoScroll}
        isStatic={isStatic}
        isFetching={isFetching}
        invalidate={invalidate}
        setToggleErrorDialog={setToggleErrorDialog}
        locale={locale}
        setIntl={setIntl}
        userProfile={userProfile}
        locations={locations}
        organization={organization}
        location={location}
        getLocation={getLocation}
        errorDialogMessage={errorDialogMessage}
        graphPresetChangeWorkspaceDialogOpen={graphPresetChangeWorkspaceDialogOpen}
        graphPresetDatesDialogOpen={graphPresetDatesDialogOpen}
        graphPresetParametersDialogOpen={graphPresetParametersDialogOpen}
        graphPresetCreateDialogOpen={graphPresetCreateDialogOpen}
        graphPresetUpdateDialogOpen={graphPresetUpdateDialogOpen}
        graphPresetDeleteDialogOpen={graphPresetDeleteDialogOpen}
        photoDeleteDialogOpen={photoDeleteDialogOpen}
        photoDetailDialogOpen={photoDetailDialogOpen}
        photoUploadDialogOpen={photoUploadDialogOpen}
        cropCreateDialogOpen={cropCreateDialogOpen}
        tableCellEditDialogOpen={tableCellEditDialogOpen}
        errorDialogOpen={errorDialogOpen}
        sidePanelOpen={sidePanelOpen}
        clearCardsState={clearCardsState}
        setNavigationMenuState={setNavigationMenuState}
        isNavigationMenuOpened={isNavigationMenuOpened}
        isLocationSpecificUrl={isLocationSpecificUrl}
        clearCompanyStore={clearCompanyStore}
      >
        {children}
      </Page>
    );
  }
}

export default withRouter(Layout);
