import * as amplitude from '@amplitude/analytics-browser';

const API_KEY = window.AMPLITUDE_API_KEY;

export const initAmplitude = () => {
  const config = {
    minIdLength: 1,
    useDynamicConfig: true,
  };
  amplitude.init(API_KEY, null, config);
};

export function ampLogEvent({ event, data }) {
  amplitude.track(event, data || {});
}

export function ampSetUserProperties({ data }) {
  const identify = new amplitude.Identify();
  const keys = Object.keys(data);

  keys.forEach((key) => {
    if (data[key]) {
      identify.set(key, data[key]);
    }
  });

  amplitude.identify(identify);
}

export function ampSetUserId({ userId }) {
  amplitude.setUserId(userId);
}
