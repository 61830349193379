import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import DefaultSelectWithChecks from '../DefaultSelectWithChecks';

import styles from './SelectboxFilterWithChecks.module.css';

const SelectboxFilterWithChecks = React.memo(({
  onChange,
  options,
  title,
  className,
  classNameWrapper,
  classNameButton,
  classNameTitle,
  value,
  size,
  customTitle,
  closeOnChange,
  selectComponent,
  dropdownRightAlign,
  pluralPlaceholderId,
  capitalizeTitle,
  isTitleEnabled,
  customPlaceholder,
  possibleSelectAll,
  isMobile,
  popupClassName
}) => {
  if (isMobile) {
    return (
      <DefaultSelectWithChecks
        options={options}
        pluralPlaceholderId={pluralPlaceholderId}
        possibleSelectAll={possibleSelectAll}
        onChange={onChange}
        value={value}
        size={size}
        customTitle={customTitle}
        closeOnChange={closeOnChange}
        selectComponent={selectComponent}
        dropdownRightAlign={dropdownRightAlign}
        classNameButton={classNameButton}
        isTitleEnabled={isTitleEnabled}
        customPlaceholder={customPlaceholder}
        popupClassName={popupClassName}
      />
    );
  }
  return (
    <div className={classnames(styles.filterWrapper, classNameWrapper)}>
      {title && (
        <div className={classnames(styles.filterTitle, classNameTitle, {
          [styles.capitalizeTitle]: capitalizeTitle
        })}
        >
          {title}
        </div>
      )}
      <div className={classnames(styles.selectWrapper, className)}>
        <DefaultSelectWithChecks
          options={options}
          pluralPlaceholderId={pluralPlaceholderId}
          possibleSelectAll={possibleSelectAll}
          onChange={onChange}
          value={value}
          size={size}
          customTitle={customTitle}
          closeOnChange={closeOnChange}
          selectComponent={selectComponent}
          dropdownRightAlign={dropdownRightAlign}
          classNameButton={classNameButton}
          isTitleEnabled={isTitleEnabled}
          customPlaceholder={customPlaceholder}
          popupClassName={popupClassName}
        />
      </div>
    </div>
  );
});

SelectboxFilterWithChecks.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  title: PropTypes.string,
  pluralPlaceholderId: PropTypes.string,
  possibleSelectAll: PropTypes.bool,
  className: PropTypes.string,
  classNameWrapper: PropTypes.string,
  classNameTitle: PropTypes.string,
  classNameButton: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  size: PropTypes.string,
  customTitle: PropTypes.string,
  closeOnChange: PropTypes.bool,
  selectComponent: PropTypes.object,
  capitalizeTitle: PropTypes.bool,
  dropdownRightAlign: PropTypes.string,
  isTitleEnabled: PropTypes.bool,
  customPlaceholder: PropTypes.string,
  isMobile: PropTypes.bool,
  popupClassName: PropTypes.string
};

SelectboxFilterWithChecks.defaultProps = {
  title: null,
  className: null,
  classNameWrapper: null,
  classNameTitle: null,
  classNameButton: null,
  possibleSelectAll: true,
  value: undefined,
  customTitle: undefined,
  pluralPlaceholderId: undefined,
  size: 'medium',
  closeOnChange: undefined,
  selectComponent: undefined,
  dropdownRightAlign: false,
  capitalizeTitle: false,
  isTitleEnabled: false,
  customPlaceholder: undefined,
  isMobile: false,
  popupClassName: null
};

export default SelectboxFilterWithChecks;
