import { createAction } from 'redux-actions';

export const setHarvestPlanGrid = createAction('SET_HARVEST_PLAN_GRID');
export const setTableScrollPosition = createAction('SET_TABLE_SCROLL_POSITION');
export const resetPlansEditorData = createAction('RESET_PLANS_EDITOR_DATA');

export const actionAfterSuccess = createAction('ACTION_AFTER_SUCCESS');

export const requestAllPlanVersions = createAction('REQUEST_ALL_PLAN_VERSIONS');
export const receiveAllPlanVersions = createAction('RECEIVE_ALL_PLAN_VERSIONS');

export const requestPlanVersionById = createAction('REQUEST_PLAN_VERSION_BY_ID');
export const receivePlanVersionById = createAction('RECEIVE_PLAN_VERSION_BY_ID');

export const requestPublishPlanById = createAction('REQUEST_PUBLISH_PLAN_BY_ID');
export const receivePublishPlanById = createAction('RECEIVE_PUBLISH_PLAN_BY_ID');

export const requestDefaultPlanVersion = createAction('REQUEST_DEFAULT_PLAN_VERSION');
export const receiveDefaultPlanVersion = createAction('RECEIVE_DEFAULT_PLAN_VERSION');

export const requestSavePlan = createAction('REQUEST_SAVE_PLAN');
export const requestSavePlanById = createAction('REQUEST_SAVE_PLAN_BY_ID');
export const receiveSavePlanById = createAction('RECEIVE_SAVE_PLAN_BY_ID');


export const requestSaveAndPublishPlan = createAction('REQUEST_SAVE_AND_PUBLISH_PLAN');
export const requestPublishPlan = createAction('REQUEST_PUBLISH_PLAN');
export const receivePublishPlan = createAction('RECEIVE_PUBLISH_PLAN');

export const requestRestorePlan = createAction('REQUEST_RESTORE_PLAN');
export const requestRestorePlanById = createAction('REQUEST_RESTORE_PLAN_BY_ID');
export const receiveRestorePlanById = createAction('RECEIVE_RESTORE_PLAN_BY_ID');

export const setVersionsFilter = createAction('SET_VERSIONS_FILTER');

export const requestPlanVersionSummary = createAction('REQUEST_PLAN_VERSION_SUMMARY');
export const receivePlanVersionSummary = createAction('RECEIVE_PLAN_VERSION_SUMMARY');

export const requestSaveVersionInfo = createAction('REQUEST_SAVE_VERSION_INFO');
export const receiveSaveVersionInfo = createAction('RECEIVE_SAVE_VERSION_INFO');

export const requestDeleteVersionInfo = createAction('REQUEST_DELETE_VERSION_INFO');
export const receiveDeleteVersionInfo = createAction('RECEIVE_DELETE_VERSION_INFO');

export const requestPlanForecast = createAction('REQUEST_PLAN_FORECAST');
export const receivePlanForecast = createAction('RECEIVE_PLAN_FORECAST');
