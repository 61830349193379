import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { useParams } from 'react-router-dom';

import { find, uniqueId } from 'lodash';

import useMountEffect from 'hooks/useMountEffect';
import { getNameByLocal } from 'helpers/getNameByLocal';

// import PageViewTracker from 'components/PageViewTracker';
import Typography from 'components/Typography';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import BackButton from 'components/BackButton';
import BigButton from 'components/BigButton';
import BigSwitch from 'components/BigSwitch';
import AdditionalInfo from 'components/AdditionalInfo';
import BellIcon from 'components/Icons/BellIcon';

import IncidentConfiguration from '../IncidentConfiguration';
import AlertRuleHref from '../AlertRuleHref';

import styles from './SettingsIncidentPage.module.css';

const renderAdditionalInfo = (intl, alertItem) => {
  const { formatMessage, formatPlural } = intl;

  const incidentsText = formatMessage({
    id: `plural.incident.${formatPlural(alertItem.incidents.length)}`
  });

  const recipientsText = formatMessage({
    id: `alerting.recipientsPlural.${formatPlural(alertItem.recipients.length)}`
  });

  const additionalInfoItems = [`${alertItem.incidents.length} ${incidentsText}`, `${alertItem.recipients.length} ${recipientsText}`];

  return <AdditionalInfo items={additionalInfoItems} />;
};

const SettingsIncidentPage = ({
  intl,
  intl: { formatMessage, locale },
  organizationSlug,
  isSettingsFetching,
  incidentTypes,
  requestIncidentTypes,
  requestUpdateIncidentState,
  // defaultOpen,

  // trackPageViewSettings,
}) => {
  useMountEffect(() => {
    requestIncidentTypes();
  });

  // const handlerPageView = useCallback(() => {
  //   trackPageViewSettings({ settingName: 'Incident page', defaultOpen });
  // }, [trackPageViewSettings, defaultOpen]);
  const { incidentType } = useParams();
  const currentIncident = find(incidentTypes, { id: Number(incidentType) });
  const [switchEnabled, setSwitchEnabled] = useState(currentIncident?.enabled);

  useEffect(() => {
      setSwitchEnabled(currentIncident?.enabled);
  }, [currentIncident]);

  const handlerSwitchClick = useCallback(() => {
    const newSwitchState = !switchEnabled;
    setSwitchEnabled(newSwitchState);

    requestUpdateIncidentState({
      incidentId: currentIncident?.id,
      enabled: newSwitchState,
      actionAfterFail: () => {
        setSwitchEnabled(!newSwitchState);
      },
    });
  }, [setSwitchEnabled, switchEnabled, requestUpdateIncidentState, currentIncident]);

  const incidentName = getNameByLocal(currentIncident, locale);
  const negativeEffects = getNameByLocal(currentIncident, locale, 'negativeEffects');
  const configuration = getNameByLocal(currentIncident, locale, 'logic');
  const alertRules = currentIncident?.alertRules || [];

  const relatedAlerts = alertRules.map(alertItem => ({
    id: alertItem.id,
    name: alertItem.name,
    additionalInfo: renderAdditionalInfo(intl, alertItem),
  }));

  return (
    <div className={styles.settingsIncidents}>
      {/* <PageViewTracker onMount={handlerPageView} /> */}

      {isSettingsFetching && <DefaultCircleLoader />}

      <BackButton
        link={`/${organizationSlug}/settings/incidents`}
        text={formatMessage({ id: 'header.links.incidents' })}
      />

      <div className={styles.headerContainer}>
        <Typography variant='h2' className={styles.header}>
          {incidentName}
        </Typography>

        <BigSwitch
          className={styles.switch}
          onClick={handlerSwitchClick}
          value={switchEnabled}
          tooltip={switchEnabled ?
            formatMessage({ id: 'incidentsSettings.tapToDisable' })
            :
            formatMessage({ id: 'incidentsSettings.tapToEnable' })}
          titleLeft={switchEnabled ?
            formatMessage({ id: 'button.enabled' })
            :
            formatMessage({ id: 'button.disabled' })}
        />
      </div>

      <div className={styles.relatedRules}>
        <Typography variant='h3' className={styles.subHeader}>
          {formatMessage({ id: 'alerting.relatedRules' })}
        </Typography>

        {relatedAlerts?.length > 0 ? (
          <div className={styles.alertsHrefsWrapper}>
            {relatedAlerts.map(alert => (
              <AlertRuleHref
                key={uniqueId('alert-tule-href-')}
                href={`/${organizationSlug}/settings/alerting?activeAlertId=${alert.id}`}
                text={alert.name}
                additionalInfo={alert.additionalInfo}
                tooltipText={formatMessage({ id: 'alerting.viewRule' })}
              />
            ))}
          </div>
          )
          :
          (
            <div className={styles.relatedRulesEmptyPlate}>
              <BellIcon />
              <Typography variant='body1' className={styles.relatedRulesEmptyPlateText}>
                {formatMessage({ id: 'alerting.emptyRelated' })}
              </Typography>
              &nbsp;
              <BigButton
                className={styles.plateButton}
                href={`/${organizationSlug}/settings/alerting`}
                title={formatMessage({ id: 'alerting.alertingPage' })}
                theme='transparent'
              />
              .
            </div>
          )}
      </div>

      <IncidentConfiguration configuration={configuration} />

      <div className={styles.info}>
        <Typography variant='h3' className={styles.subHeader}>
          {formatMessage({ id: 'incidentsSettings.aboutIncident' })}
        </Typography>

        <div className={styles.infoText}>
          <p>
            {negativeEffects}
          </p>
        </div>
      </div>
    </div>
  );
};

SettingsIncidentPage.propTypes = {
  intl: intlShape.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  // defaultOpen: PropTypes.bool,
  isSettingsFetching: PropTypes.bool,
  incidentTypes: PropTypes.array,
  requestIncidentTypes: PropTypes.func.isRequired,
  requestUpdateIncidentState: PropTypes.func.isRequired,

  // trackPageViewSettings: PropTypes.func.isRequired,
};

SettingsIncidentPage.defaultProps = {
  // defaultOpen: false,
  isSettingsFetching: false,
  incidentTypes: [],
};

export default SettingsIncidentPage;
