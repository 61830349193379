import React, {
  useCallback, useState, forwardRef
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import cn from 'classnames';

import DropdownMenu from 'components/DropdownMenu';
import Plus from 'components/Icons/Plus';

import AddUsersSearchList from '../AddUsersSearchList';

import styles from './AddUsersDropdown.module.css';

const renderButton = (title, setIsCloseDropdown, isCloseDropdown) => (
  <div
    role='button'
    tabIndex={0}
    className={styles.buttonWrapper}
    onClick={() => setIsCloseDropdown(!isCloseDropdown)}
  >
    <div className={cn(styles.plusWrapper, { [styles.active]: !isCloseDropdown })}>
      <Plus />
    </div>
    {title && (
      <span className={styles.title}>
        {title}
      </span>
    )}
  </div>
);


const AddUsersDropdown = forwardRef(({
  intl,
  className,
  usersList,
  alignLeft,
  handlerClickOption,
  title,
  positionTop,
  selectedOption,
}, ref) => {
  const [isCloseDropdown, setIsCloseDropdown] = useState(true);

  const handlerClick = useCallback((option) => {
    // avoid dropdown blinking on userlist changes
    setTimeout(() => {
      handlerClickOption(option);
    }, 200);
  }, [handlerClickOption]);

  return (
    <div ref={ref}>
      <DropdownMenu
        wrapperClassName={cn(styles.addUserDropdown, className)}
        contentClassName={cn(styles.switchContent, {
          [styles.alignLeft]: alignLeft,
          [styles.positionTop]: positionTop,
        })}
        buttonElement={renderButton(title, setIsCloseDropdown, isCloseDropdown)}
        closeDropdown={isCloseDropdown}
        afterClickOutside={() => setIsCloseDropdown(true)}
      >
        <AddUsersSearchList
          intl={intl}
          users={usersList}
          handlerClickOption={handlerClick}
          selectedOption={selectedOption}
          // selectedId: PropTypes.number,
        />
      </DropdownMenu>
    </div>
  );
});

AddUsersDropdown.propTypes = {
  intl: intlShape.isRequired,
  className: PropTypes.string,
  usersList: PropTypes.array,
  handlerClickOption: PropTypes.func,
  alignLeft: PropTypes.bool,
  title: PropTypes.string,
  positionTop: PropTypes.bool,
  selectedOption: PropTypes.array,
};

AddUsersDropdown.defaultProps = {
  className: undefined,
  usersList: [],
  handlerClickOption: undefined,
  alignLeft: false,
  title: undefined,
  positionTop: false,
  selectedOption: [],
};

export default AddUsersDropdown;
