import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import withPropsOnChange from 'recompose/withPropsOnChange';
import withState from 'recompose/withState';
import pure from 'recompose/pure';
import { BasicTooltip } from '@nivo/core';

import { CSSTransition } from 'react-transition-group';

import styles from './PieSlice.module.css';

const PieSlice = ({
  data,

  path,
  hoverPath,
  color,
  fill,
  borderWidth,
  borderColor,

  showTooltip,
  hideTooltip,
  onClick,
  onMouseEnter,
  onMouseLeave,
  tooltipFormat,
  tooltip,

  theme,
  hoverVisible,
  setHoverVisible,
}) => {
  const handleTooltip = e =>
    showTooltip(
      <BasicTooltip
        id={data.label}
        value={data.value}
        enableChip
        color={color}
        theme={theme}
        format={tooltipFormat}
        // TODO: smells
        // eslint-disable-next-line react/jsx-no-bind
        renderContent={
          typeof tooltip === 'function' ? tooltip.bind(null, { color, ...data }) : null
        }
      />,
      e
    );

  const handleMouseEnter = (e) => {
    onMouseEnter(data, e);
    handleTooltip(e);
    setHoverVisible(true);
  };
  const handleMouseLeave = (e) => {
    onMouseLeave(data, e);
    hideTooltip(e);
    setHoverVisible(false);
  };

  return (
    <>
      <path
        key={data.id}
        d={path}
        fill={fill}
        opacity={hoverVisible ? 0.9 : 1}
        strokeWidth={borderWidth}
        stroke={borderColor}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleTooltip}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      />
      <CSSTransition
        component='path'
        in={hoverPath && hoverVisible}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: styles.sliceEnter,
          enterActive: styles.sliceEnterActive,
          exit: styles.sliceLeave,
          exitActive: styles.sliceLeaveActive,
        }}
      >
        <path
          key={`hoverPath-${data.id}`}
          id={`hoverPath-${data.id}`}
          d={hoverPath}
          fill={fill}
          strokeWidth={borderWidth}
          stroke={borderColor}
          className={styles.sliceHoverPath}
        />
      </CSSTransition>
    </>
  );
};

PieSlice.propTypes = {
  /* eslint-disable react/require-default-props */
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,

  path: PropTypes.string.isRequired,
  hoverPath: PropTypes.string,
  color: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  borderWidth: PropTypes.number.isRequired,
  borderColor: PropTypes.string.isRequired,

  tooltipFormat: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  tooltip: PropTypes.func,
  showTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,

  theme: PropTypes.shape({
    tooltip: PropTypes.shape({}).isRequired,
  }).isRequired,
  hoverVisible: PropTypes.bool.isRequired,
  setHoverVisible: PropTypes.func.isRequired,
  /* eslint-enable react/require-default-props */
};

const enhance = compose(
  withState('hoverVisible', 'setHoverVisible', false),
  withPropsOnChange(['data', 'onClick'], ({ data, onClick }) => ({
    onClick: event => onClick(data, event),
  })),
  pure
);

export default enhance(PieSlice);
