import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { get } from 'lodash';
import queryString from 'query-string';

import CumulativeToggle from '../components/CumulativeToggle';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => {
  const parsedQuery = queryString.parse(get(props, 'location.search'));

  const cumulative = get(parsedQuery, 'cumulative') || false;
  const cumulativeForQuality = get(parsedQuery, 'cumulativeForQuality') || false;
  const percentageForQuality = get(parsedQuery, 'percentageForQuality') || false;

  return {
    ...props,

    cumulative: JSON.parse(cumulative),
    cumulativeForQuality: JSON.parse(cumulativeForQuality),
    percentageForQuality: JSON.parse(percentageForQuality),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CumulativeToggle));
