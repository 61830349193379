import { head, last } from 'lodash';
import {
  range,
  tickStep,
  timeDay,
  utcYear,
  utcMonth,
  utcWeek,
  utcDay,
  utcHour,
  utcMinute,
  utcSecond,
} from 'd3';

const getRange = (start, stop, step) => range(
  Math.floor(start / step) * step,
  (Math.ceil(stop / step) * step) + (step / 2),
  step
);

export const TICKS_COUNT = 4;

export const TICKS_COUNT_ON_MOBILE = 5;

/**
 * Вычисляет тики для графика, округляя min/max значения.
 * Функция нужна, чтобы:
 * 1) верхний тик всегда был выше max значения графика;
 * 2) значения тиков были красивыми;
 * 3) количество тиков было максимально приближено к заданному числу;
 * Стандартная функция расчёта тиков d3 нам не подходит, т.к. не выполняется п.1
 *
 * @param {number} start - Минимальное значение графика.
 * @param {number} stop - Максимальное значение графика.
 * @param {number} count - Желаемое количество тиков.
 * @return {number[]} Массив тиков (их количество близко к count, но не обязательно что равно).
 */
export const getTicksRange = (start = 0, stop = 100, count = TICKS_COUNT) => {
  let step = tickStep(start, stop, count);

  // Исключаем значения рейнджа 0-100 (дефолтная шкала), чтобы отрисовка графика при этом была 0-100
  const isCustomScaleValue = (start !== 0 && stop !== 100);

  if (Math.abs(step - count) > 2 && isCustomScaleValue) {
    step = tickStep(start, stop, count - 1);
  }

  let newRange = getRange(start, stop, step);

  if (last(newRange) === stop && isCustomScaleValue) {
    newRange = getRange(start, stop + (stop / 100), step);
  }

  return newRange;
};

/**
 * Возвращает минимальное и максимальное значение оси
 * (фактически, значения первого и последнего тиков).
 *
 * @param {number[]} ticks - Массив тиков (т.е. фактически, значения оси).
 * @return {number[]} Массив, содержащий min и max значение оси.
 */
export const getTicksMinMax = ticks => ([head(ticks), last(ticks)]);

/**
 * Возвращает абсолютную разницу между количеством тиков в наборе тиков правой и левой оси.
 *
 * @param {Object} ranges - Объект, содержащий массивы тиков для левой и правой шкалы.
 * @return {number} Разница между количеством тиков в наборе тиков правой и левой оси.
 */
const getRangesDiff = ranges => (Math.abs(ranges.leftRange.length - ranges.rightRange.length));

/**
 * Рекурсивная функция для прохождения итерации увеличению количества тиков.
 *
 * @param {number} min - Минимальное значение графика.
 * @param {number} max - Максимальное значение графика.
 * @param {number} newCount - Новое количество тиков.
 * @param {number} wantedLength - Желаемое количество тиков.
 * @return {number[]} Массив тиков.
 */
const increaseTicks = (min, max, wantedLength, newCount) => {
  const newRange = getTicksRange(min, max, newCount);

  // Страховка от бесконечного цикла
  if (newCount > 10) {
    return newRange;
  }

  if (newRange.length < wantedLength) {
    return increaseTicks(min, max, wantedLength, newCount + 1);
  }

  return newRange;
};

/**
 * Рекурсивная функция для прохождения итерации по синхронизации количества тиков.
 *
 * @param {number} minLeft - Минимальное значение левой шкалы графика.
 * @param {number} maxLeft - Максимальное значение левой шкалы графика.
 * @param {number} minRight - Минимальное значение правой шкалы графика.
 * @param {number} maxRight - Максимальное значение правой шкалы графика.
 * @param {number[]} leftRange - Массив тиков левой шкалы на предыдущей итерации
 * @param {number[]} rightRange - Массив тиков правой шкалы на предыдущей итерации
 * @param {number} count - Желаемое количество тиков.
 * @return {Object} Объект, содержащий массивы тиков для левой и правой шкалы.
 */
const rangeIteration = ({
  minLeft, maxLeft, minRight, maxRight, leftRange, rightRange, count
}) => {
  const newRightRange = leftRange.length > rightRange.length ? increaseTicks(minRight, maxRight, leftRange.length, count + 1) : rightRange;
  const newlLeftRange = rightRange.length > leftRange.length ? increaseTicks(minLeft, maxLeft, rightRange.length, count + 1) : leftRange;

  if (newlLeftRange.length !== newRightRange.length && count < 11) {
    return rangeIteration({
      minLeft, maxLeft, minRight, maxRight, leftRange: newlLeftRange, rightRange: newRightRange, count: count + 1,
    });
  }

  return {
    leftRange: newlLeftRange,
    rightRange: newRightRange
  };
};

/**
 * Рекурсивная функция для прохождения итерации по синхронизации количества тиков
 * путём увеличения максимума правой оси.
 *
 * @param {number} minRight - Минимальное значение правой шкалы графика.
 * @param {number} maxRight - Максимальное значение правой шкалы графика.
 * @param {number[]} leftRange - Массив тиков левой шкалы на предыдущей итерации
 * @param {number} count - Желаемое количество тиков.
 * @param {number} initialMax - Начальное максимальное значение правой шкалы графика.
 * @return {Object} Объект, содержащий массивы тиков для левой и правой шкалы.
 */
const minMaxIteration = ({
  minRight, maxRight, leftRange, count, initialMax
}) => {
  const newMaxRight = maxRight + ((maxRight / 100) * 10);
  const newRightRange = getTicksRange(minRight, newMaxRight, count);
  const isNewMaxOversize = newMaxRight - initialMax > initialMax;

  if (leftRange.length !== newRightRange.length && !isNewMaxOversize) {
    return minMaxIteration({
      minRight, maxRight: newMaxRight, leftRange, count, initialMax
    });
  }

  return {
    leftRange,
    rightRange: newRightRange
  };
};

/**
 * Функция аналогична getTicksRange, но синхронизирует
 * вычисление тиков для двух шкал
 *
 * @param {number} minLeft - Минимальное значение левой шкалы графика.
 * @param {number} maxLeft - Максимальное значение левой шкалы графика.
 * @param {number} minRight - Минимальное значение правой шкалы графика.
 * @param {number} maxRight - Максимальное значение правой шкалы графика.
 * @param {number} count - Желаемое количество тиков.
 * @return {Object} Объект, содержащий массивы тиков для левой и правой шкалы.
 */
export const getSynhronizedTicksRange = ({
  minLeft, maxLeft, minRight, maxRight, count
}) => {
  const oldRanges = {
    leftRange: getTicksRange(minLeft, maxLeft, count),
    rightRange: getTicksRange(minRight, maxRight, count)
  };

  if (getRangesDiff(oldRanges) === 0) {
    return oldRanges;
  }

  let newRanges = rangeIteration({
    minLeft,
    maxLeft,
    minRight,
    maxRight,
    leftRange: oldRanges.leftRange,
    rightRange: oldRanges.rightRange,
    count: count + 1
  });

  if (getRangesDiff(newRanges) === 0) {
    return newRanges;
  }

  newRanges = minMaxIteration({
    minRight, maxRight, leftRange: oldRanges.leftRange, count, initialMax: maxRight
  });

  if (getRangesDiff(newRanges) < getRangesDiff(oldRanges)) {
    return newRanges;
  }

  return oldRanges;
};

/**
 * Функция для расчёта количества тиков оси времени. Скопипащено из графиков.
 * /src/components/Graphs/components/Chart/index.js
 *
 * @param {Object} xFrom - Начальная дата.
 * @param {Object} xTo - Конечная дата.
 * @param {number} ticksCount - Желаемое количество тиков.
 * @return {function} Функция расчёта тиков.
 */
// TODO: кажется, отсюда надо выпиливать UTC
export const calculateTicksForTimeAxis = (xFrom, xTo, ticksCount = 6) => {
  const delimeter = ticksCount - 1;

  const yearsDiff = xTo.diff(xFrom, 'years');
  const monthsDiff = xTo.diff(xFrom, 'months');
  const weeksDiff = xTo.diff(xFrom, 'weeks');
  const daysDiff = xTo.diff(xFrom, 'days');
  const hoursDiff = xTo.diff(xFrom, 'hours');
  const minutesDiff = xTo.diff(xFrom, 'minutes');
  const secondsDiff = xTo.diff(xFrom, 'seconds');

  let timeRange = timeDay.every(1);

  // По идее всегда надо проверять на > delimeter, иначе в Math.floor будет 0. Пока добавил только к месяцам.
  if (yearsDiff > delimeter) {
    timeRange = utcYear.every(Math.floor(yearsDiff / delimeter));
  } else if (monthsDiff > delimeter) {
    timeRange = utcMonth.every(Math.floor(monthsDiff / delimeter));
  } else if (weeksDiff > 1) {
    if (Math.floor(weeksDiff / delimeter)) {
      timeRange = utcWeek.every(Math.floor(weeksDiff / delimeter));
    } else {
      timeRange = utcDay.every(4);
    }
  } else if (daysDiff > 1) {
    timeRange = utcDay.every(Math.floor(daysDiff / delimeter));
  } else if (hoursDiff > 1) {
    timeRange = utcHour.every(Math.floor(hoursDiff / delimeter));
  } else if (minutesDiff > 1) {
    timeRange = utcMinute.every(Math.floor(minutesDiff / delimeter));
  } else if (secondsDiff > 1) {
    timeRange = utcSecond.every(Math.floor(secondsDiff / delimeter));
  }

  return timeRange;
};
