import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { getOrganizationSlug, getResourceType } from '../../../store/company/selectors';

import ResourcesMenu from '../components/ResourcesMenu';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,

  organizationSlug: getOrganizationSlug(state, props),
  resourceType: getResourceType(state, props),
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ResourcesMenu)));
