import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import withPropsOnChange from 'recompose/withPropsOnChange';
import pure from 'recompose/pure';
import setDisplayName from 'recompose/setDisplayName';
import { themePropType } from '@nivo/core';
import { LegendPropShape, BoxLegendSvg } from '@nivo/legends';
import { arcPropType } from './props';

class PieLegends extends Component {
    static propTypes = {
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      arcs: PropTypes.arrayOf(arcPropType).isRequired,
      data: PropTypes.arrayOf(PropTypes.object).isRequired,
      legends: PropTypes.arrayOf(PropTypes.shape(LegendPropShape)).isRequired,
      theme: themePropType.isRequired,
    }

    render() {
      const {
        width, height, legends, data, theme
      } = this.props;

      return legends.map((legend, i) => (
        <BoxLegendSvg
          /* eslint-disable react/no-array-index-key */
          key={i}
          /* eslint-enable react/no-array-index-key */
          {...legend}
          containerWidth={width}
          containerHeight={height}
          data={data}
          theme={theme}
        />
      ));
    }
}

export const enhance = EnhanceComponent =>
  compose(
    withPropsOnChange(['arcs'], ({ arcs }) => ({
      data: arcs.map(arc => ({
        id: arc.data.id,
        label: arc.data.id,
        color: arc.color,
        fill: arc.fill,
      })),
    })),
    pure
  )(EnhanceComponent);

export default setDisplayName('PieLegends')(enhance(PieLegends));
