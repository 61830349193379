import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router';


import { requestLaborReport, updateLaborReportSorting } from 'store/labor/actions';

import { getIsLaborReportFetching, getLaborWorkTypes } from 'store/labor/selectors';

import {
  getIntl,
  getAllFruitClasses,
  getAllPlantingCycles,
  getAllProductGroups,
  getCurrentCompartments,
  getSpeciesFromRoute,
  getVarietiesInLocationWithTargetWeight,
  getAnyDateOfPeriodStartFromRoute,
  getAnyDateOfPeriodEndFromRoute,
  getUnitKindFromRoute,
  getVarietyIdFromRoute,
  getFruitClassFromRoute,
  getBreakdownTypeFromRoute,
} from 'store/company/selectors';

import { getPeriodType } from 'store/plans/selectors';
import { getRowName } from 'helpers/getRowName';
import { getReport } from 'store/dashboards/selectors';
import DashboardReportBarChart from './DashboardReportBarChart';

const mapDispatchToProps = {
  requestLaborReport,
  updateLaborReportSorting,
};

const getBarChartItems = createSelector(
  [
    getReport,
    getIntl,
    getLaborWorkTypes,
    getCurrentCompartments,
    getAllFruitClasses,
    getVarietiesInLocationWithTargetWeight,
    getAllProductGroups,
    getAllPlantingCycles,
    getBreakdownTypeFromRoute,
    getSpeciesFromRoute,
    getFruitClassFromRoute,
    getVarietyIdFromRoute,
  ],
  (report, intl, workTypes, compartments, fruitClasses, varieties, productGroups, allPlantingCycles, currentBreakdown = 'compartment', currentSpecies, currentFruitClassCode, currentVarietyId) => {
    const breakdown = get(report, 'breakdown');
    const seeds = get(breakdown, 'seeds', []);

    return seeds.map(seed => ({
      id: get(seed, 'ref.id'),
      name: getRowName({
        ref: get(seed, 'ref'),
        type: get(seed, 'type'),
        intl,
        workTypes,
        compartments,
        fruitClasses,
        varieties,
        productGroups,
        allPlantingCycles,
        currentBreakdown,
        currentSpecies,
        currentFruitClassCode,
        currentVarietyId,
      }),
      color: '#1DBADF',
      value: get(seed, 'amount.amount'),
      units: get(seed, 'amount.units'),

      seed,
    }));
  },
);

const mapStateToProps = (state, props) => ({
  items: getBarChartItems(state, props),

  isDataFetching: getIsLaborReportFetching(state, props),

  anyDateOfPeriodStart: getAnyDateOfPeriodStartFromRoute(state, props),
  anyDateOfPeriodEnd: getAnyDateOfPeriodEndFromRoute(state, props),
  periodType: getPeriodType(state, props),
  currentUnitKind: getUnitKindFromRoute(state, props),
  currentVarietyId: getVarietyIdFromRoute(state, props),
  currentFruitClassCode: getFruitClassFromRoute(state, props),
  currentBreakdown: getBreakdownTypeFromRoute(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardReportBarChart)));
