import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classnames from 'classnames';

import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';

import { ReactComponent as ToIcon } from './assets/to.svg';
import { ReactComponent as DivIcon } from './assets/div.svg';

import TitleWithInfoIcon from './components/TitleWithInfoIcon';
import styles from './LightProductivity.module.css';

const LightProductivity = ({
  cycle, model, toModel, units, intl, wrappedClassName
}) => {
  const { formatMessage } = intl;
  const cycleValue = numbersFormatting(numbersRounding(cycle, 'fixed', 0));
  const modelValue = numbersFormatting(numbersRounding(model, 'fixed', 0));
  const toModelUnitsId = useMemo(() =>
    (toModel !== 0 ? `unrealizedPotential.${toModel > 0 ? 'better' : 'worse'}` : ''), [toModel]);

  return (
    <div className={classnames(styles.lightProductivity, wrappedClassName)}>
      <TitleWithInfoIcon
        tooltipText={formatMessage({ id: 'unrealizedPotential.lightTooltipText' })}
        titleText={formatMessage({ id: 'unrealizedPotential.lightProductivityTitle' })}
        className={styles.title}
      />
      <div className={styles.content}>
        <div className={styles.valueContainer}>
          <div className={styles.valueTitle}>
            <FormattedMessage id='unrealizedPotential.cycle' />
          </div>
          <div className={styles.valueLine}>
            <span className={styles.value}>{cycleValue || '—'}</span>
            <span className={styles.units}><FormattedMessage id={`cunits.mini.${units}`} /></span>
          </div>
        </div>
        <div className={styles.divIconWrapper}>
          <DivIcon />
        </div>
        <div className={styles.valueContainer}>
          <div className={styles.valueTitle}>
            <FormattedMessage id='unrealizedPotential.model' />
          </div>
          <div className={styles.valueLine}>
            <span className={styles.value}>{modelValue || '—'}</span>
            <span className={styles.units}><FormattedMessage id={`cunits.mini.${units}`} /></span>
          </div>
        </div>
        <div className={styles.divIconWrapper}>
          <ToIcon />
        </div>
        <div className={styles.valueContainer}>
          <div className={styles.valueTitle}>
            <FormattedMessage id='unrealizedPotential.cycleToModel' />
          </div>
          <div className={styles.valueLine}>
            <span className={styles.value}>{toModel ? `${Math.abs(toModel)}%` : '—'}</span>
            {toModel !== 0 && (
              <span className={styles.units}><FormattedMessage id={toModelUnitsId} /></span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

LightProductivity.propTypes = {
  cycle: PropTypes.number.isRequired,
  model: PropTypes.number.isRequired,
  toModel: PropTypes.number.isRequired,
  units: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  wrappedClassName: PropTypes.string
};

LightProductivity.defaultProps = {
  wrappedClassName: null
};

export default injectIntl(LightProductivity);

