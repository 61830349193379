import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  getYearFromRoute,
  getOrganization,
  getAllCompartments,
  getAllVarieties,
  getAllFruitClasses,
  getPeriodLengthFromRoute,
  getCurrentLocationPlan,
  getPlanIdFromRoute,
  getAnyDateOfPeriodStartFromRoute,
  getAnyDateOfPeriodEndFromRoute,
  getSpeciesFromRoute,
  getProductGroupFromRoute,
  getVarietyIdFromRoute,
  getFruitClassFromRoute,
  getCompartmentIdsFromRoute,
} from '../../../store/company/selectors';

import {
  getHarvestPlanEdited,
  getPlanVersionSummary,
  getIsPlanVersionSummaryFetching,
  getIsOperationalPlan,
} from '../../../store/plansEditor/selectors';

import { showNotificationWithTimeout } from '../../../store/notificationCenter/actions';

import {
  resetPlansEditorData,

  requestPlanVersionSummary,
  receivePlanVersionSummary,
} from '../../../store/plansEditor/actions';

import PlansSummary from '../components/PlansSummary';

import { getRelative, getGroupedBy } from '../../../store/plans/selectors';

const mapDispatchToProps = {
  resetPlansEditorData,
  showNotificationWithTimeout,

  requestPlanVersionSummary,
  receivePlanVersionSummary,
};

const mapStateToProps = (state, props) => ({
  year: props.year || getYearFromRoute(state, props),
  relative: props.relative || getRelative(state, props),
  groupedBy: props.groupedBy || getGroupedBy(state, props),

  allCompartments: getAllCompartments(state),
  allVarieties: getAllVarieties(state),
  fruitClasses: getAllFruitClasses(state),
  organization: getOrganization(state, props),
  harvestPlanSummary: getPlanVersionSummary(state, props),
  isPlanVersionSummaryFetching: getIsPlanVersionSummaryFetching(state, props),
  harvestPlanEdited: getHarvestPlanEdited(state, props),

  locationPlan: getCurrentLocationPlan(state, props) || getPlanIdFromRoute(state, props),
  periodLength: getPeriodLengthFromRoute(state, props),
  isOperationalPlan: getIsOperationalPlan(state, props),

  anyDateOfPeriodStart: getAnyDateOfPeriodStartFromRoute(state, props),
  anyDateOfPeriodEnd: getAnyDateOfPeriodEndFromRoute(state, props),
  species: getSpeciesFromRoute(state, props),
  productGroup: getProductGroupFromRoute(state, props),
  varietyId: getVarietyIdFromRoute(state, props),
  fruitClassCode: getFruitClassFromRoute(state, props),
  compartmentId: getCompartmentIdsFromRoute(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(PlansSummary)));
