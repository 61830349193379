import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getCurrentCompartments,

  getSpeciesFromRoute,
  getCompartmentIdsFromRoute,
  getVarietyIdFromRoute,
  getPlantingCycleIdFromRoute,
  getUnitKindFromRoute,

  getVarietiesInLocationWithTargetWeight,
  getFilteredPlantingCyclesByVarieties,

  getAllFruitClasses,

  getBreakdownTypeFromRoute,
  getFruitClassFromRoute,
  getCurrentSpecies,
  getWorkTypeIdsFromRoute,
  getIsOnlyVegetables,
  getIsOnlyLettuce,
} from 'store/company/selectors';

import {
  getRelative,
} from 'store/plans/selectors';

import { getLaborWorkTypes } from 'store/labor/selectors';

import { updateFiltersEnergyReport } from 'store/energy/actions';

import DashboardComplexFilters from '../components/DashboardComplexFilters';

const mapDispatchToProps = {
  updateFiltersEnergyReport,
};

const mapStateToProps = (state, props) => ({
  varieties: getVarietiesInLocationWithTargetWeight(state, props),
  plantingCycles: getFilteredPlantingCyclesByVarieties(state, props),
  compartments: getCurrentCompartments(state),
  fruitClasses: getAllFruitClasses(state, props),
  species: getCurrentSpecies(state, props),
  workTypes: getLaborWorkTypes(state, props),

  currentBreakdown: getBreakdownTypeFromRoute(state, props),
  currentGrownFilter: getVarietyIdFromRoute(state, props) || getFruitClassFromRoute(state, props) || getSpeciesFromRoute(state, props),
  currentCompartment: getCompartmentIdsFromRoute(state, props),
  currentPlantingCycle: getPlantingCycleIdFromRoute(state, props),
  currentUnitKind: getUnitKindFromRoute(state, props),
  currentWorkTypeId: getWorkTypeIdsFromRoute(state, props),

  currentSpecies: getSpeciesFromRoute(state, props),
  currentVarietyId: getVarietyIdFromRoute(state, props),
  currentFruitClassCode: getFruitClassFromRoute(state, props),

  isOnlyLettuce: getIsOnlyLettuce(state, props),
  isOnlyVegetables: getIsOnlyVegetables(state, props),

  relative: getRelative(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardComplexFilters)));
