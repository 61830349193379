import moment from 'moment-timezone';
import getDateFormat from '../../../helpers/getDateFormat';

export const getDate = ({ date, periodType, intl: { formatMessage } }) => {
  if (periodType === 'day') {
    return date.format(getDateFormat('LLLL'));
  }

  if (periodType === 'week') {
    const week = date.isoWeek();

    const weekDates = `${date.clone().isoWeekday(1).format('MMM D')} – ${date.clone().isoWeekday(7).format('MMM D')}`;

    return `${weekDates}, ${formatMessage({ id: 'cycle.wk' })} ${week}`;
  }

  const currentYeat = moment().utc().year();

  if (periodType === 'month') {
    return date.format(date.year() === currentYeat ? 'MMM' : 'MMM YYYY');
  }

  return date.format('MMM YYYY');
};

export const getDateFromKey = ({ dateKey, periodType, intl }) => {
  const date = moment.utc(new Date((+dateKey) * 60000));
  return getDate({
    date,
    periodType,
    intl
  });
};
