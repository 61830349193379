import { isObject } from 'lodash';

export const textEllipsis = (ctx, source, maxWidth) => {
  let str = source;
  const { width } = ctx.measureText(str);
  const ellipsis = '...';
  const ellipsisWidth = ctx.measureText(ellipsis).width;

  if (width <= maxWidth || width <= ellipsisWidth) {
    return str;
  }
  let len = str.length;
  let w = width;
  // eslint-disable-next-line no-plusplus
  while (w >= maxWidth - ellipsisWidth && len-- > 0) {
    str = str.substring(0, len);
    w = ctx.measureText(str).width;
  }
  return `${str}${ellipsis}`;
};

export const measureFormattedText = (ctx, formattedText) => {
  ctx.save();
  let measureX = 0;
  for (let i = 0; i < formattedText.length; i += 1) {
    const item = formattedText[i];
    if (isObject(item)) {
      if (item.fillStyle) {
        ctx.fillStyle = item.fillStyle;
      }
      if (item.textAlign) {
        ctx.textAlign = item.textAlign;
      }
      if (item.font) {
        ctx.font = item.font;
      }
    } else {
      measureX += ctx.measureText(item).width + 2;
    }
  }
  ctx.restore();
  return measureX;
};

export const getTextHeight = textMetrics => ({
  fontHeight: textMetrics.fontBoundingBoxAscent + textMetrics.fontBoundingBoxDescent,
  actualHeight: textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent,
});

export const wrapText = ({
  ctx, text, x, y, maxWidth, lineHeight, center, noDraw
}) => {
  const lines = text.split('\n');

  let ySize = 0;
  let lY = y;
  for (let ii = 0; ii < lines.length; ii++) {
    let line = '';
    const words = lines[ii].split(' ');

    for (let n = 0; n < words.length; n++) {
      const testLine = `${line + words[n]} `;
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;

      // Добавлено условие n !== 0, тк если первое слово будет шире maxWidth, будет лишняя пустая строка
      if (testWidth > maxWidth && n !== 0) {
        const lineX = center ? x + (maxWidth / 2) - (ctx.measureText(line).width / 2) : x;
        if (!noDraw) { ctx.fillText(line, lineX, lY); }
        line = `${words[n]} `;
        lY += lineHeight;
        ySize += lineHeight;
      } else {
        line = testLine;
      }
    }

    const lineX = center ? x + (maxWidth / 2) - (ctx.measureText(line).width / 2) : x;
    if (!noDraw) { ctx.fillText(line, lineX, lY); }
    lY += lineHeight;
    ySize += lineHeight;
  }
  return ySize;
};

