import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import storageWrapper from '../../../../../helpers/storageWrapper';

import DefaultDialog from '../../../../DefaultDialog';
import BigButton from '../../../../BigButton';
import Checkbox from '../../../../Checkbox';

import styles from './PublishButtonDialog.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');

class PublishButtonDialog extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    isFetching: PropTypes.bool,

    handlerCloseDialog: PropTypes.func.isRequired,
    handlerDontSave: PropTypes.func.isRequired,
    handlerSave: PropTypes.func.isRequired,
    handlerSaveWithoutPublish: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isFetching: false,
  };

  state = {
    isChecked: false,
  };

  handlerCheckbox = () => this.setState(prevState => ({ isChecked: !prevState.isChecked }));

  handlerPublishButtonClick = () => {
    const { handlerSave } = this.props;
    const { isChecked } = this.state;

    if (isChecked) {
      safeLocalStorage.setItem('isPublishDialogHidden', true);
    }

    handlerSave();
  };

  render() {
    const {
      intl: { formatMessage }, isFetching,
      handlerCloseDialog, handlerDontSave, handlerSaveWithoutPublish,
    } = this.props;
    const { isChecked } = this.state;

    return (
      <DefaultDialog
        title={formatMessage({ id: 'plansVersion.publishPlan' })}
        onClose={handlerCloseDialog}
        wrapperClassName={styles.dialogWrapper}
      >
        <div>
          <p className={styles.dialogText}>
            {formatMessage({ id: 'plansVersion.publishPlanText' })}
          </p>

          <Checkbox
            className={styles.checkbox}
            onChange={this.handlerCheckbox}
            checked={isChecked}
            value='publishDialogHidden'
            title={formatMessage({ id: 'plansVersion.dontShowMessage' })}
            alignCenter
            noWrap
          />

          <div className={styles.buttons}>
            <BigButton
              className={styles.cancel}
              onClick={handlerDontSave}
              title={formatMessage({ id: 'button.cancel' })}
              theme='light'
              disabled={isFetching}
            />
            <BigButton
              className={styles.cancel}
              onClick={handlerSaveWithoutPublish}
              title={formatMessage({ id: 'plansVersion.saveWithoutPublish' })}
              theme='light'
              disabled={isFetching}
            />
            <BigButton
              className={styles.confitm}
              onClick={this.handlerPublishButtonClick}
              title={formatMessage({ id: 'plansVersion.publish' })}
              theme='dark'
              isLoading={isFetching}
            />
          </div>
        </div>
      </DefaultDialog>
    );
  }
}

export default injectIntl(PublishButtonDialog);
