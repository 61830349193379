import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { get, find } from 'lodash';
import classnames from 'classnames';

import { getMlName } from 'helpers/getVarietyName';

import ChartNotSelectedIcon from 'components/Icons/ChartNotSelectedIcon';
import ChartSelectedIcon from 'components/Icons/ChartSelectedIcon';
import ArrowRightIcon from 'components/Icons/ArrowRightIcon';

import styles from './CollapsedRow.module.css';

const getIsActiveRowGraphKey = (tableRow, currentGraphKey) => {
  const activeRow = find(tableRow.seeds, { graphKey: currentGraphKey });

  return get(activeRow, 'graphKey');
};

class CollapsedRow extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    tableRow: PropTypes.object.isRequired,
    currentGraphKey: PropTypes.string.isRequired,
    varieties: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    getName: PropTypes.func.isRequired,
    onGraphKeyChange: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired,
    organizationSlug: PropTypes.string.isRequired
  };

  state = {
    opened: getIsActiveRowGraphKey(this.props.tableRow, this.props.currentGraphKey) === this.props.currentGraphKey,
  };

  handlerRowClick = () => this.setState(prevState => ({ opened: !prevState.opened }));

  envelopeLink = (text, plantingCycleId, organizationSlug) => {
    if (!plantingCycleId) {
      return text;
    }

    return (
      <NavLink
        to={`/${organizationSlug}/crops/${plantingCycleId}`}
        className={styles.plantingCycleLink}
      >
        {text}
      </NavLink>
      );
  };

  renderTableRow = (tableRow, secondLevel = false) => {
    if (!tableRow) {
      return null;
    }

    const {
      intl: { locale, formatMessage },
      currentGraphKey,
      onGraphKeyChange,
      getValue,
      columns,
      getName,
      varieties,
      organizationSlug,
    } = this.props;
    const { opened } = this.state;

    const {
      graphKey, combinedHarvest, variety, ref, plantingCycleId
    } = tableRow;

    const rowName = ref?.type === 'Plan' ?
      `${getMlName(find(varieties, { id: variety.id }), locale)} (${formatMessage({ id: 'planFact.planOnly' })})`
      : this.envelopeLink(getName(ref), plantingCycleId, organizationSlug);

    return (
      <tr
        key={`row-${graphKey}`}
        className={classnames({
          [styles.trSecondLevel]: secondLevel
        })}
        onClick={secondLevel ? () => {} : this.handlerRowClick}
      >
        <td
          onClick={e => onGraphKeyChange(e, graphKey)}
          role='gridcell'
          tabIndex={0}
          className={styles.chartIconWrapper}
        >
          <div className={styles.chartIconContainer}>
            {graphKey === currentGraphKey ? <ChartSelectedIcon /> : <ChartNotSelectedIcon />}
          </div>
        </td>
        <td>
          <div className={styles.nameWrapper}>
            {!secondLevel && (
              <div
                className={classnames(
                  styles.arrowRightIcon,
                  {
                    [styles.arrowIconRotate]: opened
                  }
                )}
              >
                <ArrowRightIcon />
              </div>
            )}
            {rowName}
          </div>
        </td>

        {columns.map(column => <td key={`column-${get(column, 'value')}`} className={styles.tdValue}>{getValue({ combinedHarvest, column })}</td>)}
      </tr>
    );
  };

  render() {
    const {
      // intl: { formatMessage, },
      tableRow,
    } = this.props;
    const { opened } = this.state;

    return (
      <Fragment key={`rowFragment-${tableRow.graphKey}`}>
        {this.renderTableRow(tableRow)}
        {opened && tableRow.seeds && tableRow.seeds.length > 0 ?
          tableRow.seeds.map(seedsRow => this.renderTableRow(seedsRow, true))
          :
          null}
      </Fragment>
    );
  }
}

export default injectIntl(CollapsedRow);
