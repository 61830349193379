import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

// @ts-ignore
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import BigButton from 'components/BigButton';

import {
  Compartment,
  FruitClass,
  Preset,
  GraphPresetListItem,
  Location,
  Metric,
  PlantingCycle,
  // MetricDef,
  // PresetMetricScale,
  Species,
  SubNode,
  Variety, MetricGroupInput, TreeNodeIdTypes
} from 'store/graphs/types';

import {
  ChangeGraphPeriodAndThreshold,
  ChangePresetDescription,
  ChangePresetMetricGroup,
  ChangePresetName,
  ClearWorkspace,
  DeletePresetMetric,
  DeletePresetMetricGroup,
  GetGraphDataPointsRequest,
  PushRangeHistory,
  RequestCreateGraphPreset,
  RequestGetGraphPresetFunc,
  RequestMetricsTree,
  RequestUpdateGraphPreset,
  requestMetricsTree
} from 'store/graphs/actions';

import { getAllCompartments, getAllSubNodes } from 'store/company/selectors';

import { setToggleGraphPresetDeleteDialog as setToggleGraphPresetDeleteDialogAction } from 'store/dialogs';

// import { useLocalStorage } from 'react-use';
import useLocalStorageWithTimestamp from 'hooks/useLocalStorageWithTimestamp';

import { getMetricsSelector } from 'store/graphs/selectors';

import MetricGroup from '../MetricGroup';
import GraphsPresetMeta from './components/GraphsPresetMeta';


import { ReactComponent as ExpandIcon } from './assets/expand.svg';
import { ReactComponent as CollapseIcon } from './assets/collapse.svg';

import styles from './index.module.css';
import {
  buildMetricsTreeGroups, MetricGroupsView,
  MetricGroupsViewEntity,
  MetricGroupView
} from '../../utils/metricsGroupUtils';

import isFullGroup from '../../utils/isFullGroup';


type GraphsPresetSettingsProps = {
  preset: Preset | null;
  location: Location;
  isEditedPreset: boolean;
  presets: Array<GraphPresetListItem>;
  allMetrics: Array<Metric>;
  allCompartments: Array<Compartment>;
  allSubNodes: Array<SubNode>;
  // allSpecies: Array<Species>;
  allVarieties: Array<Variety>;
  allFruitClasses: Array<FruitClass>;
  allPlantingCycles: Array<PlantingCycle>;
  // presetMetrics: Array<MetricDef>;
  // presetScales: Array<PresetMetricScale>;
  threshold: number;
}

type GraphsPresetSettingsFunc = {
  showSidePanelAction: Function; // TODO: Types for SidePanel
  hideSidePanelAction: Function; // TODO: Types for SidePanel
  requestGetGraphPreset: RequestGetGraphPresetFunc;
  requestCreateGraphPreset: RequestCreateGraphPreset;
  requestUpdateGraphPreset: RequestUpdateGraphPreset;
  changePresetName: ChangePresetName;
  changePresetDescription: ChangePresetDescription;
  deletePresetMetric: DeletePresetMetric;
  deletePresetMetricGroup: DeletePresetMetricGroup;
  changePresetMetricGroup: ChangePresetMetricGroup;
  setToggleGraphPresetDeleteDialog: Function;
  clearWorkspace: ClearWorkspace;
  pushRangeHistory: PushRangeHistory;
  changeGraphPeriodAndThreshold: ChangeGraphPeriodAndThreshold;
  requestGetGraphDataPoints: GetGraphDataPointsRequest;
  requestMetricsTree: RequestMetricsTree;
}

function combineGroup(groups: MetricGroupsView) {
  return groups.reduce<Array<MetricGroupView>>((acc:Array<MetricGroupView>, grp:MetricGroupsViewEntity) => {
    acc.push(...grp.metricGroups);
    return acc;
  }, []);
}

function isNotUniqueGroup(
  groups: MetricGroupsView,
  input: {
    firstNode: Compartment | Species | null;
    secondNode: SubNode | PlantingCycle| null;
  },
) {
  const allGroups = combineGroup(groups);

  const inputKey = [input?.firstNode?.id, input?.secondNode?.id].join(',');

  const filtered = allGroups
    .filter(item =>
      item.key === inputKey);

  return filtered.length > 0;
}

const GraphsPresetSettings = ({
  intl,
  preset,
  presets,
  location,
  isEditedPreset,
  showSidePanelAction,
  requestGetGraphPreset,
  requestCreateGraphPreset,
  requestUpdateGraphPreset,
  changePresetName,
  changePresetDescription,
  deletePresetMetric,
  deletePresetMetricGroup,
  changePresetMetricGroup,
  allCompartments,
  allSubNodes,
  allMetrics,
  // allSpecies,
  allVarieties,
  allFruitClasses,
  allPlantingCycles,
  setToggleGraphPresetDeleteDialog,
  clearWorkspace,
  // presetMetrics,
  // presetScales,
  pushRangeHistory,
  changeGraphPeriodAndThreshold,
  requestGetGraphDataPoints,
  threshold,
  requestMetricsTree
}: GraphsPresetSettingsProps & InjectedIntlProps & GraphsPresetSettingsFunc) => {
  const { formatMessage, formatPlural, locale } = intl;

  // const [collapsedGroups, setCollapsedGroups] = useLocalStorage<boolean>('graphs-metrics-groups-collapsed', false);
  const [collapsedGroups, setCollapsedGroups] = useLocalStorageWithTimestamp('graphs-metrics-groups-collapsed', false);

  const [metricGroups, setMetricGroups] = useState<MetricGroupsView>(() => []);

  const [notUniqueGroupMetrics, setNotUniqueGroupMetrics] = useState<Array<string>>([]);
  const [notFullGroupMetrics, setNotFullGroupMetrics] = useState<Array<string>>([]);

  useEffect(() => {
    if (preset) {
      setMetricGroups(
        buildMetricsTreeGroups(preset, {
            formatMessage,
            locale,
            allCompartments,
            allSubNodes,
            allVarieties,
            allFruitClasses,
            allPlantingCycles
        })
      );
    } else {
      setMetricGroups([]);
    }
  }, [
    preset,
    formatMessage,
    locale,
    allCompartments,
    allSubNodes,
    allVarieties,
    allFruitClasses,
    allPlantingCycles,
    setMetricGroups
  ]);

  const handlerGroupToggle = () => {
    setCollapsedGroups(!collapsedGroups);
  };

  const handlerGraphPresetParametersDialogOpen = useCallback(() => {
    showSidePanelAction({
      sidePanelType: 'graphParameters',
      sidePanelProps: {
        headerText: <FormattedMessage id='graphs.dataSeries' />
      },
    });
  }, [showSidePanelAction]);

  const handleChangeMetricGroup = useCallback((
    treeType: TreeNodeIdTypes,
    locationId: number,
    key: string,
    values: MetricGroupInput,
  ) => {
    const notFullIds = [];
    const notUniqueIds = [];

    if (!isFullGroup(values)) {
      notFullIds.push(key);
    }
    if (isNotUniqueGroup(metricGroups, values)) {
      notUniqueIds.push(key);
    }

    setNotFullGroupMetrics(notFullIds);
    setNotUniqueGroupMetrics(notUniqueIds);

    if (!notFullIds.length && !notUniqueIds.length) {
      changePresetMetricGroup({
        target: [treeType, locationId, ...key.split(',').map(v => +v)],
        values: [treeType, locationId, values?.firstNode?.id || 0, values?.secondNode?.id || 0]
      });
    }
  }, [metricGroups, changePresetMetricGroup, setNotUniqueGroupMetrics]);

  const handleGroupClose = useCallback((type:string, key: string) => {
    setMetricGroups((prevState:MetricGroupsView) => {
      const treeGroupIndex = prevState.findIndex(item => item.key === type);
      if (treeGroupIndex !== -1) {
        const treeGroup = prevState[treeGroupIndex];
        const metricGroupsIndex = treeGroup.metricGroups.findIndex(item => item.key === key);
        if (metricGroupsIndex !== -1) {
          const deletedNode = treeGroup.metricGroups.splice(metricGroupsIndex, 1);
          const locationId = deletedNode[0].path?.locationId;
          if (treeGroup.metricGroups.length <= 0) {
            prevState.splice(treeGroupIndex, 1);
          }
          deletePresetMetricGroup({
            group: [type, locationId, ...key.split(',').map(val => +val)]
          });
        }
      }
      return [...prevState];
    });
  }, [setMetricGroups, deletePresetMetricGroup]);

  const handleMetricClose = useCallback((type:string, key: string, metricId: number, locationId: number) => {
    const treeGroupIndex = metricGroups.findIndex(item => item.key === type);
    if (treeGroupIndex !== -1) {
      const treeGroup = metricGroups[treeGroupIndex];
      const metricGroupsIndex = treeGroup.metricGroups.findIndex(item => item.key === key);
      if (metricGroupsIndex !== -1) {
        const metricGroup = treeGroup.metricGroups[metricGroupsIndex];
        const deletedMetricNode = [
          type,
          +locationId,
          ...key.split(',').map(val => +val),
          +metricId
        ];
        const metricIndex = metricGroup.metrics.findIndex(item => item.node.join('_') === deletedMetricNode.join('_'));
        if (metricIndex !== -1) {
          metricGroup.metrics.splice(metricIndex, 1);
          deletePresetMetric({
            metric: [...deletedMetricNode]
          });
          setMetricGroups([...metricGroups]);
        }
      }
    }
  }, [setMetricGroups, deletePresetMetric, metricGroups]);

  const selectedMetricsCount = useMemo(() =>
    metricGroups.reduce((acu: number, item: MetricGroupsViewEntity) => {
      let metricGroupCount = 0;
      for (let i = 0; i < item.metricGroups.length; i += 1) {
        metricGroupCount += item.metricGroups[i].metrics.length;
      }
      return acu + metricGroupCount;
    }, 0),
  [metricGroups]);

  const isNoGroupsErrors = useMemo(() => {
    for (let i = 0; i < metricGroups.length; i += 1) {
      for (let j = 0; j < metricGroups[i].metricGroups.length; j += 1) {
        const groupKey = metricGroups[i].metricGroups[j].key;
        if (notUniqueGroupMetrics.includes(groupKey) || notFullGroupMetrics.includes(groupKey)) {
          return false;
        }
      }
    }
    return true;
  }, [metricGroups, notUniqueGroupMetrics, notFullGroupMetrics]);

  return (
    <div className={styles.graphsSettings}>
      <GraphsPresetMeta
        preset={preset}
        presets={presets}
        isEditedPreset={isEditedPreset}
        showSidePanelAction={showSidePanelAction}
        requestGetGraphPreset={requestGetGraphPreset}
        requestCreateGraphPreset={requestCreateGraphPreset}
        requestUpdateGraphPreset={requestUpdateGraphPreset}
        changePresetName={changePresetName}
        changePresetDescription={changePresetDescription}
        isNoGroupsErrors={isNoGroupsErrors}
        setToggleGraphPresetDeleteDialog={setToggleGraphPresetDeleteDialog}
        clearWorkspace={clearWorkspace}
        requestMetricsTree={requestMetricsTree}
      />
      <div className={styles.graphPresetSettings}>
        <div className={styles.graphPresetSettingsControls}>
          <div className={styles.graphsSettingsDataSeriesItems}>
            <div className={styles.groups}>
              {preset && metricGroups?.length > 0 && (
                <div className={styles.groupsToggleWrapper}>
                  <button type='button' className={styles.groupsToggle} onClick={handlerGroupToggle}>
                    {formatMessage({ id: `plural.selected.${formatPlural(selectedMetricsCount)}` })}
                    &nbsp;{selectedMetricsCount}&nbsp;
                    {formatMessage({ id: `plural.metric.${formatPlural(selectedMetricsCount)}` })}
                    &nbsp;{formatMessage({ id: 'in' })}
                    &nbsp;{metricGroups.length}&nbsp;
                    {formatMessage({ id: `plural.inGroup.${formatPlural(metricGroups.length)}` })}
                    {collapsedGroups ?
                      <ExpandIcon className={styles.groupsToggleIcon} /> :
                      <CollapseIcon className={styles.groupsToggleIcon} />}
                  </button>
                </div>
              )}
              {!collapsedGroups && metricGroups.map((treeGroup: MetricGroupsViewEntity) => (
                <div className={styles.treeGroup} key={treeGroup.key}>
                  {treeGroup.metricGroups.map((group:MetricGroupView, index: number) => (
                    <MetricGroup
                      type={treeGroup.key}
                      showIcon={index === 0}
                      key={`metric-group-${group.key}`}
                      groupKey={group.key}
                      location={location}
                      isUnique={!notUniqueGroupMetrics.includes(group.key)}
                      metrics={group.metrics}
                      input={group.path.input}
                      onChange={(
                        key: string,
                        values: MetricGroupInput,
                      ) => {
                        handleChangeMetricGroup(treeGroup.key, location.id, key, values);
                      }}
                      onGroupClose={handleGroupClose}
                      onMetricClose={handleMetricClose}
                      preset={preset}
                      selectFirstCompartment={false}
                      allMetrics={allMetrics}
                      pushRangeHistory={pushRangeHistory}
                      changeGraphPeriodAndThreshold={changeGraphPeriodAndThreshold}
                      requestGetGraphDataPoints={requestGetGraphDataPoints}
                      threshold={threshold}
                    />
                  ))}
                </div>
              ))}
            </div>
            <div className={styles.addDataSeriesButtonWrapper}>
              <BigButton
                className={classnames(styles.addDataSeriesButton, styles.miniButton)}
                onClick={handlerGraphPresetParametersDialogOpen}
                title={<FormattedMessage id='graphs.addDataSeries' />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  ...state.intl,
  allCompartments: getAllCompartments(state),
  allSubNodes: getAllSubNodes(state),
  allMetrics: getMetricsSelector(state),
});

const mapDispatchToProps = {
  setToggleGraphPresetDeleteDialog: setToggleGraphPresetDeleteDialogAction,
  requestMetricsTree
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(GraphsPresetSettings));
