import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getFormValues } from 'redux-form';
import { withRouter } from 'react-router';

import { requestFeedbackSending, clearFeedbackSending } from 'store/incidents/actions';

import {
  getFeedbackSendingError,
  getFeedbackSuccessfulSending,
  getIsFeedbackSendingFetching,
} from 'store/incidents/selectors';

import RateIncidentButton from '../components/RateIncidentButton';


const mapDispatchToProps = {
  requestFeedbackSending,
  clearFeedbackSending,
};

const mapStateToProps = (state, props) => ({
  ...props,

  formValues: getFormValues('rateIncidentForm')(state),
  sendingError: getFeedbackSendingError(state),
  successfulSending: getFeedbackSuccessfulSending(state),
  isFeedbackSendingFetching: getIsFeedbackSendingFetching(state),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(RateIncidentButton)));
