import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import {
  requestMeasurementsByDate,
  requestCreateNextPlant,
  requestUpdatePlant,
  requestDeletePlant,
} from 'store/measurements/actions';
import { showNotificationWithTimeout, removeNotification } from 'store/notificationCenter/actions';

import MeasurementsForm from './MeasurementsForm';

const mapDispatchToProps = {
  requestMeasurementsByDate,
  showNotificationWithTimeout,
  removeNotification,
  requestCreateNextPlant,
  requestUpdatePlant,
  requestDeletePlant,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(MeasurementsForm)));
