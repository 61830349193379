import { createSelector } from 'reselect';
import { find, omit } from 'lodash';

import {
  getCompartmentIdsFromRoute,
  getWorkTypeIdsFromRoute,
  getWorkClassIdFromRoute,
  getCurrentCompartments,
} from 'store/company/selectors';

import sortByLocal from 'helpers/sortByLocal';

export const getLaborReport = state => state?.labor?.laborReport;
export const getIsLaborReportFetching = state => state?.labor?.isLaborReportFetching;

export const getLaborReportSorting = state => state?.labor?.laborReportSorting;

export const getLaborResources = state => state?.labor?.laborResources || [];
export const getIsLaborResourcesFetching = state => state?.labor?.isLaborResourcesFetching;

export const getLaborWorkTypes = state => state?.labor?.workTypes || [];
export const getIsLaborWorkTypesFetching = state => state?.labor?.isWorkTypesFetching;

export const getLaborRegistrations = state => state?.labor?.laborRegistrations;
export const getAvaliableWorkClasses = state => state?.labor?.laborRegistrations?.descriptor?.workClasses;
export const getIsLaborRegistrationsFetching = state => state?.labor?.isLaborRegistrationsFetching;
export const getIsSaveLaborRegistrationsFetching = state => state?.labor?.isSaveLaborRegistrationsFetching;

export const workTypesSelector = createSelector(
  [getLaborWorkTypes],
  workTypes => Object.keys(workTypes?.usage || {})
    .map(type => ({
      type,
      works: workTypes.data.filter(work => workTypes.usage[type].includes(work.id))
    })),
);

export const getFilteredLaborRegistrations = createSelector(
  getLaborRegistrations,
  getCompartmentIdsFromRoute,
  getWorkTypeIdsFromRoute,
  getWorkClassIdFromRoute,
  getCurrentCompartments,
  (laborRegistrations, compartmentIds, workTypeIds, workClassId, currentCompartments) => {
    const works = laborRegistrations?.works;
    const descriptor = laborRegistrations?.descriptor;
    const compartments = descriptor?.compartments ?? [];
    const workClasses = descriptor?.workClasses;
    const workTypes = descriptor?.workTypes;

    const compartmentsWithNames = compartments.map((compartment) => {
      const fullCompartmentInfo = find(currentCompartments, { id: compartment.id });

      return {
        ...compartment,

        name: fullCompartmentInfo?.attributes?.name,
      };
    });

    const sortedCompartments = sortByLocal(compartmentsWithNames, 'name');

    // Удаляем добавленную ранее информацию, которая была нужна только для сортировки
    const sortedPureCompartments = sortedCompartments.map(compartment => omit(compartment, 'name'));

    const filteredCompartments = compartmentIds ?
      sortedPureCompartments?.filter(compartment => compartmentIds.includes(compartment.id.toString())) : sortedPureCompartments;

    const filteredCompartmentsWithWorkTypes = workTypeIds ? filteredCompartments?.map(filteredCompartment => ({
      ...filteredCompartment,
      plantingCycles: filteredCompartment?.plantingCycles.map(plantingCycle => ({
        ...plantingCycle,
        workTypeIds: plantingCycle.workTypeIds?.filter(workTypeId => workTypeIds.includes(workTypeId.toString())),
      })),
    })) : filteredCompartments;

    const filteredWorkTypes = workTypeIds ?
      workTypes?.filter(workType => workTypeIds.includes(workType.id.toString())) : workTypes;

    const filteredWorkClasses = workClassId ?
      workClasses?.filter(workClass => workClass === workClassId) : workClasses;


    const avaliablePlantingCycles = filteredCompartmentsWithWorkTypes?.reduce((acc, compartment) => ([...acc, ...compartment.plantingCycles.map(plantingCycle => plantingCycle.id)]), []) ?? [];

    const getFilteredWorks = () => {
      const firstFilterWorks = compartmentIds ?
        works?.filter(work => avaliablePlantingCycles.includes(work.plantingCycleId)) : works;

      const secondFilterWorks = workTypeIds ?
        firstFilterWorks?.filter(work => workTypeIds.includes(work.workTypeId.toString())) : firstFilterWorks;

      const thirdFilterWorks = workClassId ?
        secondFilterWorks?.filter(work => work.workClass === workClassId) : secondFilterWorks;

      return thirdFilterWorks;
    };

    const filteredWorks = getFilteredWorks();

    return {
      descriptor: {
        ...descriptor,

        compartments: filteredCompartmentsWithWorkTypes,
        workClasses: filteredWorkClasses,
        workTypes: filteredWorkTypes,
      },
      works: filteredWorks,
    };
  },
);
