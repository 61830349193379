import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Typography.module.css';


const defaultVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  subtitle1: 'h6',
  subtitle2: 'h6',
  subtitle3: 'span',
  body1: 'p',
  body2: 'p',
  caption1: 'span',
  caption2: 'span',
  span: 'span',
  div: 'div',
};

export default class Typography extends React.Component {
  static propTypes = {
    variant: PropTypes.string,
    bold: PropTypes.bool,
    color: PropTypes.string,
    component: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    variant: null,
    bold: false,
    color: null,
    component: null,
    className: null,
  };

  render() {
    const {
      className,
      color = 'initial',
      component,
      variant = 'body1',
      bold,
      ...other
    } = this.props;

    const Component = component || defaultVariantMapping[variant] || 'span';

    return (
      <Component
        className={classnames(
          styles.typography,
          {
            [styles[variant]]: styles[variant] && variant !== 'inherit',
            [styles[color]]: styles[color] && color !== 'initial',
            [styles.bold]: bold,
          },
          className,
        )}
        {...other}
      />
    );
  }
}
