import PropTypes from 'prop-types';
import React, { Component, } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { get } from 'lodash';

import classnames from 'classnames';
import Split from '../Split';
import TableCellEditDialog from '../TableCellEditDialog';
import CropsChart from '../CropsChart';
import BigButton from '../BigButton';
import DownloadIcon from '../Icons/DownloadIcon';

import styles from './Tables.module.css';

class Tables extends Component {
  static propTypes = {
    intl: intlShape.isRequired,

    location: PropTypes.object,
    leftCompartmentTables: PropTypes.object,
    rightCompartmentTables: PropTypes.object,
    leftPlantingCycleTables: PropTypes.object,
    rightPlantingCycleTables: PropTypes.object,
    leftPlantingCyclesTables: PropTypes.array,
    rightPlantingCyclesTables: PropTypes.array,
    leftWeeklyData: PropTypes.array,
    rightWeeklyData: PropTypes.array,
    leftMetrics: PropTypes.array,
    rightMetrics: PropTypes.array,
    varieties: PropTypes.array,
    selectedMetricId: PropTypes.number,
    isFetching: PropTypes.bool.isRequired,
    isLeftWeeklyDataFetching: PropTypes.bool.isRequired,
    isRightWeeklyDataFetching: PropTypes.bool.isRequired,

    tableCellEditDialogOpen: PropTypes.bool,
    tableCellEditDialogDateEditable: PropTypes.bool,
    tableCellEditDialogDate: PropTypes.object,
    tableCellEditDialogMetric: PropTypes.object,
    tableCellEditDialogDailyValues: PropTypes.array,
    tableCellEditDialogPlantingCycle: PropTypes.object,
    tableCellEditDialogMode: PropTypes.string,
    cycleId: PropTypes.string,
    allPlantingCycles: PropTypes.array,

    getLeftPlantingCycleTables: PropTypes.func.isRequired,
    getRightPlantingCycleTables: PropTypes.func.isRequired,
    getLeftDailyData: PropTypes.func.isRequired,
    getRightDailyData: PropTypes.func.isRequired,
    getSplitCompartmentsTables: PropTypes.func.isRequired,
    updateTableDailyData: PropTypes.func.isRequired,
    setToggleTableCellEditDialog: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    hideNotification: PropTypes.func.isRequired,
    showNotificationWithTimeout: PropTypes.func.isRequired,
    updateSelectedMetricId: PropTypes.func.isRequired,
    trackClickClimateTableWeek: PropTypes.func.isRequired,
    requestDrillDown: PropTypes.func.isRequired,

    currentCompartment: PropTypes.object,
    isDrillDownFetching: PropTypes.bool,
    isEnableXSLSExportButton: PropTypes.bool,
    onClickExportButton: PropTypes.func,
  };

  static defaultProps = {
    selectedMetricId: null,
    leftCompartmentTables: null,
    rightCompartmentTables: null,
    leftPlantingCycleTables: null,
    rightPlantingCycleTables: null,
    leftPlantingCyclesTables: null,
    rightPlantingCyclesTables: null,
    leftWeeklyData: null,
    rightWeeklyData: null,
    leftMetrics: null,
    rightMetrics: null,
    varieties: null,
    location: null,

    tableCellEditDialogMode: null,
    tableCellEditDialogOpen: false,
    tableCellEditDialogDateEditable: null,
    tableCellEditDialogDate: null,
    tableCellEditDialogMetric: null,
    tableCellEditDialogDailyValues: null,
    tableCellEditDialogPlantingCycle: null,
    cycleId: null,
    allPlantingCycles: null,
    currentCompartment: null,
    isDrillDownFetching: false,
    isEnableXSLSExportButton: false,
    onClickExportButton: null,
  };

  componentDidMount() {
    const {
      getSplitCompartmentsTables, cycleId, getLeftPlantingCycleTables, leftPlantingCycleTables, allPlantingCycles,
    } = this.props;
    const currentTablesCycleId = get(leftPlantingCycleTables, 'id');

    if (currentTablesCycleId !== Number(cycleId)) {
      getSplitCompartmentsTables().then(() => {
        // TODO: ВНИМАНИЕ!!! Это люто-бешенный костыль, нужен на время пока нельзя выпиливать старые таблицы
        if (cycleId && allPlantingCycles) {
          const newPlantingCycle = allPlantingCycles.find((item) => {
            const { id: itemId } = item;

            return itemId === Number(cycleId);
          });

          getLeftPlantingCycleTables(newPlantingCycle);
        }
      });
    }
  }

  handlerChangeLeftPlantingCycle = (id) => {
    const { getLeftPlantingCycleTables, leftPlantingCyclesTables } = this.props;

    const plantingCycle = leftPlantingCyclesTables.find((item) => {
      const { id: itemId } = item;

      return itemId === id;
    });

    setTimeout(() => {
      getLeftPlantingCycleTables(plantingCycle);
    }, 0);
  };

  handlerChangeRightPlantingCycle = (id) => {
    const { getRightPlantingCycleTables, rightPlantingCyclesTables } = this.props;
    const plantingCycle = rightPlantingCyclesTables.find((item) => {
      const { id: itemId } = item;

      return itemId === id;
    });

    setTimeout(() => {
      getRightPlantingCycleTables(plantingCycle);
    }, 0);
  };

  handlerTableCellEditDialogOpen = (options) => {
    const { setToggleTableCellEditDialog } = this.props;
    if (setToggleTableCellEditDialog) {
      setToggleTableCellEditDialog(options);
    }
  };

  handlerTableCellEditDialogClose = ({
    plantingCycle,
    compartment,
    date,
    metric,
    dailyValues = {},
    isClose,
  }) => {
    const { updateTableDailyData, setToggleTableCellEditDialog, } = this.props;

    if (isClose) {
      setToggleTableCellEditDialog({ open: false });
    }

    if (!isClose && date && Object.keys(dailyValues).length) {
      updateTableDailyData({
        plantingCycle,
        compartment,
        date,
        metric,
        dailyValues,
      });
    }
  };

  handleClickExportButton = () => {
    const {
      onClickExportButton
    } = this.props;
    if (onClickExportButton) {
      onClickExportButton();
    }
  }

  getInnerComponent = () => {
    const {
      isEnableXSLSExportButton,
      intl
    } = this.props;
    const { formatMessage } = intl;
    if (!isEnableXSLSExportButton) {
      return null;
    }
    return (
      <div className={styles.tableActionButtons}>
        <BigButton
          className={classnames(styles.button, styles.exelButton)}
          title={formatMessage({ id: 'planFact.exportButton' })}
          icon={<DownloadIcon />}
          onClick={() => {
            this.handleClickExportButton();
          }}
        />
      </div>
    );
  }

  render() {
    const {
      leftCompartmentTables,
      rightCompartmentTables,
      rightPlantingCycleTables,
      leftPlantingCycleTables,
      rightWeeklyData,
      leftWeeklyData,
      leftMetrics,
      rightMetrics,
      intl,
      getLeftDailyData,
      getRightDailyData,
      isFetching,
      isLeftWeeklyDataFetching,
      isRightWeeklyDataFetching,

      tableCellEditDialogOpen,
      tableCellEditDialogDateEditable,
      tableCellEditDialogMode,
      tableCellEditDialogDate,
      tableCellEditDialogMetric,
      tableCellEditDialogDailyValues,
      tableCellEditDialogPlantingCycle,
      location,
      varieties,
      showNotification,
      hideNotification,
      showNotificationWithTimeout,
      currentCompartment,
      updateSelectedMetricId,
      selectedMetricId,
      cycleId,

      trackClickClimateTableWeek,
      requestDrillDown,
      isDrillDownFetching,
    } = this.props;

    if (location && currentCompartment) {
      return (
        <div style={{ margin: '0 auto', overflowY: 'hidden' }}>
          {leftMetrics && leftMetrics.length && leftWeeklyData && leftWeeklyData.length ? <CropsChart cycleId={cycleId} /> : null}
          <Split
            innerComponent={this.getInnerComponent()}
            isFetching={isFetching}
            isLeftWeeklyDataFetching={isLeftWeeklyDataFetching}
            isRightWeeklyDataFetching={isRightWeeklyDataFetching}
            onTableCellEdit={this.handlerTableCellEditDialogOpen}
            location={location}
            leftMetrics={leftMetrics}
            rightMetrics={rightMetrics}
            leftWeeklyData={leftWeeklyData}
            rightWeeklyData={rightWeeklyData}
            rightCompartment={rightCompartmentTables}
            leftCompartment={leftCompartmentTables}
            rightPlantingCycle={rightPlantingCycleTables}
            leftPlantingCycle={leftPlantingCycleTables}
            getLeftDailyData={getLeftDailyData}
            getRightDailyData={getRightDailyData}
            showNotification={showNotification}
            hideNotification={hideNotification}
            showNotificationWithTimeout={showNotificationWithTimeout}
            updateSelectedMetricId={updateSelectedMetricId}
            selectedMetricId={selectedMetricId}
            trackClickClimateTableWeek={trackClickClimateTableWeek}
            requestDrillDown={requestDrillDown}
            isDrillDownFetching={isDrillDownFetching}
            tableWrapperClassName={styles.tableWrapper}
          />
          {
            tableCellEditDialogOpen && (
              <TableCellEditDialog
                weeklyData={tableCellEditDialogMode === 'left' ? leftWeeklyData : rightWeeklyData}
                onDialogClose={this.handlerTableCellEditDialogClose}
                compartment={currentCompartment}
                plantingCycle={tableCellEditDialogPlantingCycle}
                location={location}
                varieties={varieties}
                metric={tableCellEditDialogMetric}
                dailyValues={tableCellEditDialogDailyValues}
                dateEditable={tableCellEditDialogDateEditable}
                date={tableCellEditDialogDate}
                intl={intl}
              />
            )
          }
        </div>
      );
    }
    return null;
  }
}

export default injectIntl(Tables);

