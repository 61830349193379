import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { createPlantingCycleStart } from '../../store/crops/actions';
import { getCurrentSpecies, getIsAddPlantingCycleAvailable, getAllFruitClasses } from '../../store/company/selectors';

import CreatePlantingCycle from './CreatePlantingCycle';


const isCreatePlantingCycleStarted = state => state.crops.createPlantingCycleStarted;
const isCreatePlantingCycleEnded = state => state.crops.createPlantingCycleEnded;

const isCreatePlantingCycleFetching = createSelector(
  [isCreatePlantingCycleStarted, isCreatePlantingCycleEnded],
  (isCreatePlantingCycleStartedFlag, isCreatePlantingCycleEndedFlag) => isCreatePlantingCycleStartedFlag && !isCreatePlantingCycleEndedFlag,
);

const mapDispatchToProps = {
  createPlantingCycleStart,
};

const mapStateToProps = (state) => {
  const {
    intl,
    form,
    company,
    crops,
  } = state;

  const {
    location,
    ...otherCompany
  } = company;

  return {
    // TODO: убрать спреды, выбирать параметры явно
    organization: location,
    ...intl,
    ...otherCompany,
    ...form,
    ...crops,

    species: getCurrentSpecies(state),
    isCreatePlantingCycleFetching: isCreatePlantingCycleFetching(state),
    isAddPlantingCycleAvailable: getIsAddPlantingCycleAvailable(state),
    fruitClasses: getAllFruitClasses(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePlantingCycle);
