import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { getProductTypeName } from 'helpers/getVarietyName';

import styles from './CropsLink.module.css';

const CropsLink = ({
  intl,
  product,
  organizations,
  directPath,
}) => {
  const productName = getProductTypeName({
    intl,
    variety: product?.variety,
    fruitClass: product?.fruitClass,
    targetWeight: product?.targetWeight,
    directPath,
  });

  const slug = organizations.find(org => org.id === product?.location?.id)?.attributes?.slug;

  const linkUrl = `/${slug}/crops/${product.plantingCycleId}`;

  return <a className={styles.link} href={linkUrl}>{productName}</a>;
};

CropsLink.propTypes = {
  intl: intlShape.isRequired,
  product: PropTypes.object,
  organizations: PropTypes.array,
  directPath: PropTypes.bool,
};

CropsLink.defaultProps = {
  organizations: [],
  product: {},
  directPath: true,
};

export default CropsLink;
