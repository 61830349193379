import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Menu, Item } from 'react-contexify';

import 'react-contexify/dist/ReactContexify.min.css';
import styles from './CellContextMenu.module.css';

// const isCutCopyDisabled = ({ props }) => (props.value === '');
// const isCutCopyDisabled = () => true;

// const isPasteDisabled = () => true;

// const handlerCopy = () => document.execCommand('copy');

// const handlerCut = () => document.execCommand('cut');

const CellContextMenu = ({ intl: { formatMessage }, contextMenuId, onModelHarvest }) => (
  <Menu id={contextMenuId} className={styles.contextMenu}>
    {/* <Item onClick={handlerCut} className={styles.menuItem} disabled={isCutCopyDisabled}>Cut</Item>
    <Item onClick={handlerCopy} className={styles.menuItem} disabled={isCutCopyDisabled}>Copy</Item>
    <Item onClick={() => {}} className={styles.menuItem} disabled={isPasteDisabled}>Paste</Item> */}
    <Item onClick={onModelHarvest} className={styles.menuItem}>{formatMessage({ id: 'plans.calculateHarvest' })}</Item>
  </Menu>
);

CellContextMenu.propTypes = {
  intl: intlShape.isRequired,
  contextMenuId: PropTypes.string.isRequired,
  onModelHarvest: PropTypes.func.isRequired,
};

export default memo(CellContextMenu);
