import { useLocation } from 'react-router-dom';

import { isNull } from 'lodash';

const useSearchParams = (key, defaultValue) => {
  const locationFromQuery = useLocation();
  const searchParamsValues = new URLSearchParams(locationFromQuery.search);
  const value = searchParamsValues.get(key);

  if (isNull(value) && defaultValue) {
    return defaultValue;
  }

  return value;
};

export default useSearchParams;
