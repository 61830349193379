import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import DefaultDialog from '../../../../DefaultDialog';
import BigButton from '../../../../BigButton';

import styles from './RestoreDialog.module.css';

class RestoreDialog extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    isFetching: PropTypes.bool,
    versionTime: PropTypes.string,

    handlerCloseDialog: PropTypes.func.isRequired,
    handlerDontSave: PropTypes.func.isRequired,
    handlerSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isFetching: false,
    versionTime: '',
  };

  render() {
    const {
      intl: { formatMessage }, isFetching, versionTime,
      handlerCloseDialog, handlerDontSave, handlerSave
    } = this.props;

    return (
      <DefaultDialog
        title={formatMessage({ id: 'plansVersion.restoreTitle' })}
        onClose={handlerCloseDialog}
        wrapperClassName={styles.dialogWrapper}
      >
        <div>
          <p className={styles.dialogText}>
            {formatMessage({ id: 'plansVersion.restoreText' })}
            {` ${versionTime}.`}
          </p>
          <div className={styles.buttons}>
            <BigButton
              className={styles.cancel}
              onClick={handlerDontSave}
              title={formatMessage({ id: 'button.cancel' })}
              theme='light'
              disabled={isFetching}
            />
            <BigButton
              className={styles.confitm}
              onClick={handlerSave}
              title={formatMessage({ id: 'plansVersion.restore' })}
              theme='dark'
              isLoading={isFetching}
            />
          </div>
        </div>
      </DefaultDialog>
    );
  }
}

export default injectIntl(RestoreDialog);
