import CanvasComponent from './CanvasComponent';
import { ReactComponent as OpenIcon } from '../assets/open.svg';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { addPoints, pointInRect } from '../helpers/GeometryUtils';
import { wrapText } from '../helpers/TextUtils';
import { roundRect } from '../helpers/DrawUtils';
import { INCIDENT_TIMELINE_CONFIG } from '../helpers/incidentScale';
import SvgIcon from '../helpers/SvgIcon';

class IncidentTreePanel extends CanvasComponent {
  constructor({
    position = { x: 0, y: 0 },
    viewport,
    data = [],
    onClickNode,
    intl,
    isScrolledTreePanelX,
  }) {
    super({
      position,
      cursorType: 'default'
    });
    this.scroll = {
      x: 0,
      y: 0,
    };
    this.data = data;
    this.onClickNode = onClickNode;
    this.expanded = [];
    this.intl = intl;
    this.updateView({
      viewport
    });
    this.openIcon = new SvgIcon(OpenIcon);
    this.closeIcon = new SvgIcon(CloseIcon);
    this.isScrolledTreePanelX = isScrolledTreePanelX;
    this.nodesGeometryCacheDirty = true;
  }

  setTimelineComponent(timeline) {
    this.timelineComponent = timeline;
  }

  setExpandedKeys(expanded) {
    this.expanded = expanded;
    this.setDirty();
    if (this.timelineComponent) {
      this.timelineComponent.setDirty();
    }
  }

  updateView({
    viewport
  }) {
    this.viewport = viewport;
    this.setDirty();
    this.setZIndex(0);
  }

  setTreeScroll({ x, y }) {
    this.scroll = { x, y };
  }

  getContentHeight() {
    if (this.nodesGeometryCache?.length > 0) {
      // Считаем по каждой ноде, т.к. их высота может менятся при переносе на несколько строк
      const allNodesHeight = this.nodesGeometryCache.reduce((acc, nodeItem) => {
        if (nodeItem?.nodeBounds?.height) {
          return acc + nodeItem.nodeBounds.height + INCIDENT_TIMELINE_CONFIG.TREE_NODE_Y_PADDING;
        }
        return acc;
      }, 0);
      return allNodesHeight + INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT +
        INCIDENT_TIMELINE_CONFIG.TREE_FIRST_NODE_PADDING +
        INCIDENT_TIMELINE_CONFIG.TREE_BUTTON_SCROLL_RESERVE;
    }
    return 0;
  }

  getSize() {
    return this.viewport ? {
      width: INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH,
      height: this.viewport.height // - this.position.y,
    } : {
      width: 0,
      height: 0
    };
  }

  updateData({
    treeData
  }) {
    this.data = treeData;
    this.nodesGeometryCacheDirty = true;
  }

  calcNodeGeometry({
     ctx,
     x,
     y,
     level,
     node,
  }) {
    const baseX = (INCIDENT_TIMELINE_CONFIG.TREE_EXPAND_X_SHIFT * level) + x;
    const isNodeHasChildren = Object.prototype.hasOwnProperty.call(node, 'children');
    const paddingSize = isNodeHasChildren ? 10 : -10;
    let baseY = wrapText({
      ctx,
      text: node.text,
      x: baseX + paddingSize,
      y,
      maxWidth: 180 - baseX,
      lineHeight: 18,
      center: false,
      noDraw: true
    });
    const nodeBounds = {
      x: baseX - 8,
      width: 190 - baseX,
      y,
      height: baseY,
    };
    if (node?.children?.length > 0 && this.expanded.includes(node.key)) {
      for (let i = 0; i < node.children.length; i += 1) {
        const childNode = node.children[i];
        const nodeGeometry = {
          x: baseX,
          y: baseY + y + INCIDENT_TIMELINE_CONFIG.TREE_NODE_Y_PADDING,
          node: childNode,
          level: level + 1
        };
        const geometry = this.calcNodeGeometry({
          ctx,
          ...nodeGeometry
        });
        baseY += (geometry.baseY + INCIDENT_TIMELINE_CONFIG.TREE_NODE_Y_PADDING);
      }
    }
    this.nodesGeometryCache.push({
      baseY,
      baseX,
      node,
      nodeBounds,
      isNodeHasChildren,
      level
    });
    return {
      baseY,
      baseX,
      node,
      nodeBounds,
      isNodeHasChildren,
      level
    };
  }

  calcNodesGeometryCache({
    ctx
  }) {
    if (this.nodesGeometryCacheDirty) {
      this.nodesGeometryCache = [];
      const baseX = 0;
      let baseY = 0;
      ctx.save();
      ctx.textAlign = 'left';
      ctx.textBaseline = 'top';
      ctx.font = 'normal normal 400 13px Roboto';
      for (let i = 0; i < this.data.length; i += 1) {
        const node = this.data[i];
        const nodeGeometry = this.calcNodeGeometry({
          ctx,
          x: baseX,
          y: baseY,
          node,
          level: 0
        });
        baseY += (nodeGeometry.baseY + INCIDENT_TIMELINE_CONFIG.TREE_NODE_Y_PADDING);
      }
      ctx.restore();
      this.timelineComponent.setIncidentCacheDirty();
      this.nodesGeometryCacheDirty = false;
    }
  }

  drawNodeArrow({
    ctx,
    x,
    y,
    // level,
    // node,
    expanded
  }) {
    if (expanded) {
      this.openIcon.draw(ctx, {
        position: {
          x, y: y - 4
        },
      });
    } else {
      this.closeIcon.draw(ctx, {
        position: {
          x, y: y - 4
        },
      });
    }
  }

  drawBorder({
      ctx, screenPosition
  }) {
    ctx.save();
    ctx.strokeStyle = '#e7e9ee';
    ctx.beginPath();
    ctx.moveTo(INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH + screenPosition.x, 0);
    ctx.lineTo(INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH + screenPosition.x, this.viewport.height - 1);
    ctx.stroke();
    ctx.restore();
  }

  drawNodeFromCache(ctx, {
    baseX,
    level,
    node,
    nodeBounds,
    isNodeHasChildren
  }, {
    x, y
  }) {
    ctx.save();
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';
    ctx.font = 'normal normal 400 13px Roboto';
    if (isNodeHasChildren) {
      this.drawNodeArrow({
        ctx,
        x: x + baseX,
        y: y + nodeBounds.y + 10,
        level,
        node,
        expanded: this.expanded.includes(node.key)
      });
    }
    // Определем после вызова this.drawNodeArrow, чтобы не перебивался цвет
    ctx.fillStyle = '#4A4A49';
    const paddingSize = isNodeHasChildren ? 10 : -10;
    wrapText({
      ctx,
      text: node.text,
      x: x + baseX + paddingSize,
      y: y + nodeBounds.y,
      maxWidth: 180 - baseX,
      lineHeight: 18,
      center: false,
      noDraw: false
    });
    ctx.restore();
  }

  getScreenPos() {
    const position = this.getPositionAbs();
    return addPoints(position, {
      x: this.isScrolledTreePanelX ? this.scroll.x : 0,
      y: this.scroll.y,
    });
  }

  draw(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    // calc nodes position to cache
    this.calcNodesGeometryCache({ ctx });

    const screenPosition = this.getScreenPos();

    // Бордер (чтобы был при скролле)
    this.drawBorder({ ctx, screenPosition });

    ctx.save();
    super.draw(ctx, {
      translateAdd
    });

    ctx.fillStyle = '#FFF';
    ctx.fillRect(0, 0, INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH + screenPosition.x, ctx.canvas.height);

    for (let i = 0; i < this.nodesGeometryCache.length; i += 1) {
      this.drawNodeFromCache(ctx, this.nodesGeometryCache[i], {
        x: screenPosition.x + 24,
        y: screenPosition.y + INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT + 20,
      });
    }

    ctx.restore();

    ctx.save();

    roundRect(ctx, {
      x: 0,
      width: INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH,
      y: this.getRealHeight(),
      height: this.viewport.height,
      fill: true,
      radius: {
        tl: 0,
        bl: 0,
        tr: 0,
        br: 0,
      },
      color: '#FFFFFF'
    });

    ctx.restore();

    // empty state
    if (this.data?.length === 0) {
      const { formatMessage } = this.intl;

      ctx.save();
      ctx.fillStyle = '#777776';
      ctx.textAlign = 'left';
      ctx.font = 'normal normal 400 13px Roboto';
      wrapText({
        ctx,
        text: formatMessage({ id: 'dashboards.noData' }),
        x: screenPosition.x,
        y: screenPosition.y + 90,
        maxWidth: INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH,
        lineHeight: 18,
        center: true,
        noDraw: false
      });
      ctx.restore();
    }

    this.updateScrollOnExpand();
  }

  updateScrollOnExpand() {
    if (this.scrollExpandedTarget) {
      const screenPosition = this.getScreenPos();
      const childNodes =
        this.nodesGeometryCache.filter(node =>
          node.node.key.startsWith(this.scrollExpandedTarget.nodeKey) &&
          node.node.key !== this.scrollExpandedTarget.nodeKey);
      if (childNodes.length) {
        const lastNode = childNodes[childNodes.length - 1];
        const lowerBound = lastNode.nodeBounds.y + lastNode.nodeBounds.height + screenPosition.y + INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT + 20;
        const realHeight = this.getRealHeight();
        if (lowerBound >= realHeight) {
          const scrollSize = lowerBound - realHeight;
          this.setTreeScroll({
            ...this.scroll,
            y: this.scroll.y - scrollSize
          });
        }
      }
      this.scrollExpandedTarget = null;
    }
  }

  findIntersectWithNodeBounds({
    x, y
  }) {
    const screenPosition = this.getScreenPos();
    return this.nodesGeometryCache.find(node =>
      pointInRect({ x, y }, {
        x: node.nodeBounds.x + screenPosition.x + 24,
        y: node.nodeBounds.y + screenPosition.y + INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT + 20,
        width: node.nodeBounds.width,
        height: node.nodeBounds.height
      }));
  }

  getNodesBounds() {
    return this.nodesGeometryCache || [];
  }

  onClick({
    x, y, shiftKey, ctrlKey
  }) {
    super.onClick({
      x, y, shiftKey, ctrlKey
    });

    const clickNode = this.findIntersectWithNodeBounds({ x, y });

    if (clickNode && this.onClickNode) {
      if (this.timelineComponent) {
        this.timelineComponent.setDirty();
      }
      this.onClickNode({
        ...clickNode,
        nodeId: clickNode?.node?.sourceNode?.entity?.id,
        expandCallback: this.handleLoadExpandedNodeData
      });
    }

    return false;
  }

  onPointerMove({
    x, y, dx, dy
  }) {
    super.onPointerMove({
      x, y, dx, dy
    });
    const clickNode = this.findIntersectWithNodeBounds({ x, y });

    if (clickNode) {
      this.setCursorType('pointer');
    } else {
      this.setCursorType('default');
    }
  }

  handleLoadExpandedNodeData = (nodeKey, sourceIds, data) => {
    const screenPosition = this.getScreenPos();
    const expandedNode = this.nodesGeometryCache.find(node => node.node.key === nodeKey);
    const nodeY = screenPosition.y + expandedNode.nodeBounds.y + expandedNode.nodeBounds.height + INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT + 20;
    this.scrollExpandedTarget = {
      nodeKey,
      data,
      nodeY
    };
  }

  getRealHeight() {
    const {
      height
    } = this.getSize();
    return height -
      INCIDENT_TIMELINE_CONFIG.TREE_FIRST_NODE_PADDING -
      INCIDENT_TIMELINE_CONFIG.TREE_BUTTON_SCROLL_RESERVE;
  }
}

export default IncidentTreePanel;
