import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import moment from 'moment';
import { indexOf, head } from 'lodash';

import { DEFAULT_SPECIES } from 'helpers/constants';
import { API_DATE_FORMAT } from 'helpers/defaultDates';
import useMountEffect from 'hooks/useMountEffect';

import Typography from 'components/Typography';
import DefaultCircleLoader from 'components/DefaultCircleLoader';

import HarvestForecastCreateForm from '../HarvestForecastCreateForm';

import styles from './HarvestForecastCreate.module.css';

const HarvestForecastCreate = ({
  intl,
  match,
  history,
  editMode,
  requestGetForecast,
  forecast,
  isForecastFetching,
  harvestForecastAccess,
  supportedSpecies,
}) => {
  const { formatMessage } = intl;
  const { params: { organizationSlug, forecastId } } = match;

  useMountEffect(() => {
    if (!harvestForecastAccess) {
      history.push(`/${organizationSlug}/crops`);
    }

    if (editMode) {
      requestGetForecast({ id: forecastId });
    }
  });

  let initialValues = {};

  if (editMode && forecast) {
    // TODO: используется moment js, т.к. datepicker работает только с ним. Нужно в идеале выпилить
    initialValues = {
      forecastName: forecast.name,
      startDate: moment(forecast.start, API_DATE_FORMAT),
      endDate: moment(forecast.endInclusive, API_DATE_FORMAT),
    };
  }

  const speciesList = ['cucumber', 'tomato'];
  const filteredSpeciesList = speciesList.filter(speciesItem => indexOf(supportedSpecies, speciesItem) !== -1);

  const getSelectedSpecies = useCallback(() => {
      if (forecast?.species) {
        return forecast?.species;
      }

      if (indexOf(filteredSpeciesList, DEFAULT_SPECIES) !== -1) {
        return DEFAULT_SPECIES;
      }

      return head(filteredSpeciesList);
  }, [forecast, filteredSpeciesList]);

  const species = getSelectedSpecies();

  if (isForecastFetching) {
    return <DefaultCircleLoader />;
  }

  return (
    <div className={styles.body}>
      <Typography variant='h1' className={styles.header}>
        {editMode ?
          formatMessage({ id: 'forecast.editReportDetails' })
          :
          formatMessage({ id: 'forecast.createHarvestForecast' })}
      </Typography>

      <HarvestForecastCreateForm
        initialValues={initialValues}
        editMode={editMode}
        species={species}
        forecastId={forecastId}
        speciesList={filteredSpeciesList}
      />
    </div>
  );
};

HarvestForecastCreate.propTypes = {
  intl: intlShape.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  requestGetForecast: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  forecast: PropTypes.object,
  isForecastFetching: PropTypes.bool,
  harvestForecastAccess: PropTypes.bool,
  supportedSpecies: PropTypes.array,
};

HarvestForecastCreate.defaultProps = {
  editMode: false,
  isForecastFetching: false,
  forecast: null,
  harvestForecastAccess: false,
  supportedSpecies: [],
};

export default HarvestForecastCreate;
