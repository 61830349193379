import { useEffect } from 'react';

import { isFinite } from 'lodash';

import { select } from 'd3';

import styles from '../LineChart.module.css';

function useHoverLineH(groupRef, options) {
  const {
    chartParams: {
      y, h
    },
    width,
    position,
    hidden
  } = options;
  useEffect(() => {
    const group = groupRef.current
      ? select(groupRef.current)
      : null;

    if (group) {
      group
        .selectAll('*')
        .remove();

      if (isFinite(position) && !hidden) {
        group
          .append('line')
          .attr('width', width)
          .attr('height', 1)
          .attr('class', styles.lineX)
          .attr('y1', position)
          .attr('y2', position)
          .attr('x1', '0')
          .attr('x2', width);
      }
    }
  }, [
    groupRef,
    y,
    h,
    width,
    position,
    hidden
  ]);
}

export default useHoverLineH;
