import { SubNode } from 'store/graphs/types';

function getSubNodeTitle({
  subNode,
  formatMessage,
}: {
  subNode: SubNode;
  formatMessage: Function;
}) {
  const {
    id,
    attributes: { name, type },
  } = subNode;

  if (id === '#') {
    return formatMessage({ id: 'graphs.sidePanel.commonCompartmentMetrics' });
  }

  let title = name;

  if (type) {
    title = `${formatMessage({ id: `subNodes.${type}` })}${
      name === 'wireless' ? '' : ` ${name}`
    }`;
  }

  return title;
}

export default getSubNodeTitle;
