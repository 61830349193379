import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styles from './Policies.module.css';


export default class Policies extends Component {
  render() {
    return (
      <article className={styles.main}>
        <h1>Политика конфиденциальности и использования файлов cookie</h1>

        <p>
          На вебсайте <RouterLink to='https://pylot.app'>https://pylot.app</RouterLink> (далее - «веб-сайт»)
          осуществляется сбор персональных данных, при этом используются файлы cookie. Компания Pylot B.V. (далее -
          Pylot) уважает права на неприкосновенность частной жизни посетителей веб-сайта. В этой связи собираемые нами
          персональные данные обрабатываются и защищаются с большой осторожностью в соответствии с требованиями к
          обработке персональных данных, установленными европейскими законами о конфиденциальности. В настоящей Политике
          конфиденциальности и использования файлов cookie мы приводим порядок обработки персональных данных и защиты
          вашей конфиденциальности.
        </p>

        <br />

        <p>
          При обработке данных мы соблюдаем требования законодательства о конфиденциальности. Это означает, помимо
          прочего, что мы:
        </p>

        <ul type='circle'>
          <li><strong>четко обозначаем наши цели</strong> до обработки персональных данных с помощью настоящей Политики
            конфиденциальности и использования файлов Cookie;
          </li>
          <li><strong>стремимся ограничивать объем собираемых персональных данных</strong> до пределов, обязательных
            только в связи с реализацией законных целей;
          </li>
          <li>всегда сначала <strong>запрашиваем ваше явное согласие</strong> на обработку ваших персональных данных в
            случаях, когда такое согласие необходимо;
          </li>
          <li>принимаем <strong>надлежащие меры безопасности</strong> для защиты ваших персональных данных и требуем
            того же от лиц, обрабатывающих персональные данные от нашего лица;
          </li>
          <li><strong>уважаем</strong> ваши права в отношении ваших персональных данных, находящихся в нашем
            распоряжении.
          </li>
        </ul>

        <br />

        <p>
          Мы выступаем в роли контролера обработки данных. В настоящей Политике конфиденциальности и использования
          файлов Cookie мы поясняем, какие персональные данные мы собираем и используем и с какими целями. Рекомендуем
          вам внимательно ознакомиться с настоящим документом.
        </p>

        <br />

        <p>
          Последняя редакция настоящей Политики конфиденциальности и использования файлов Cookie была
          создана 11/12/2018.
        </p>

        <h2>Обработка персональных данных</h2>
        <p>
          Используя наш веб-сайт, вы делитесь с нами определенной информацией, которая может включать персональные
          данные. Мы храним и используем только те персональные данные, которые были получены нами напрямую от вас или
          которые вне всяких сомнений были предоставлены нам для целей их обработки. Мы не будем использовать
          персональные данные в любых иных целях в отсутствие вашего предварительного согласия или соответствующих
          законодательных норм, разрешающих такое использование.
        </p>

        <br />

        <p>
          При использовании вами веб-сайта мы обрабатываем следующие персональные данные:
        </p>

        <ul type='circle'>
          <li>электронный адрес;</li>
          <li>логин;</li>
          <li>IP-адрес;</li>
          <li>операционная система;</li>
          <li>тип устройства;</li>
          <li>ваш регион;</li>
          <li>используемый язык;</li>
          <li>посещаемые страницы.</li>
        </ul>

        <br />

        <p>
          Мы обрабатываем такие персональные данные в целях исполнения договора, в частности для настройки параметров и
          поддержки вашей учетной записи на веб-сайте, предоставления доступа к веб-сайту и вашей учетной записи,
          установления с вами связи относительно запрашиваемых вами услуг, предоставления запрашиваемых вами услуг и
          уведомления вас об услугах.
        </p>

        <br />

        <p>
          Кроме того, мы используем ваш электронный адрес для направления электронных информационных рассылок с тем,
          чтобы сообщать вам об обновлениях на веб-сайте, в случае предоставления вами согласия на получение таких
          информационных рассылок. В каждом информационном письме есть кнопка «Отписаться». Вы также можете отказаться
          от получения информационных рассылок, обратившись в нашу службу поддержки и сообщив специалисту о своем
          желании отписаться от информационных рассылок. В случае отказа от получения информационных рассылок мы удалим
          ваш электронный адрес из списка подписчиков.
        </p>

        <br />

        <p>
          Кроме того, мы отслеживаем и анализируем определенную информацию на основании Вашей активности на нашем
          веб-сайте. Мы используем эти данные только для целей внутреннего анализа, чтобы получить более подробную
          информацию о вашем местоположении, используемых языках, частоте использования сайта, посещаемых вами страницах
          и вашем IP-адресе. Такая информация позволит нам усовершенствовать наш продукт, спрогнозировать и разрешить
          потенциальные проблемы и проанализировать ваше поведение как пользователя сети.
        </p>

        <br />

        <p>
          В указанных целях мы вправе воспользоваться данными о вашем местоположении. Мы отдельно запрашиваем ваше
          согласие на использование нами данных о вашем местоположении и будем осуществлять обработку таких данных
          только при фактическом предоставлении вами согласия.
        </p>

        <h2>Файлы cookie</h2>
        <p>
          Файлы cookie представляют собой небольшие информационные файлы, в которых мы можем хранить информацию, чтобы
          вам не приходилось указывать такую информацию повторно. Мы также можем использовать их, чтобы зафиксировать
          ваши повторные посещения веб-сайта. Это осуществляется через веб-браузер вашего устройства (включая ПК,
          планшет или смартфон). Информация, которая может передаваться с файлом cookie, помимо использования на нашем
          веб-сайте, может передаваться на собственные защищенные серверы компании Pylot или серверы третьего лица.
        </p>

        <br />

        <p>
          Наш веб-сайт использует файлы cookie. Файлы cookie размещаются нами, а также через третьих лиц, которых мы
          привлекаем для обработки данных. Мы используем файлы сookie на нашем веб-сайте в следующих целях:
        </p>

        <ul type='circle'>
          <li>
            для создания общей статистики и получения подробной информации об использовании нашего веб-сайта широкой
            публикой в целях оптимизации нашего веб-сайта и услуг (аналитические файлы сookie);
          </li>
          <li>
            для повышения вашей заинтересованности путем размещения рекламных объявлений, отвечающих вашим интересам,
            и персонализации рекламы и контента на нашем веб-сайте с учетом ваших интересов и статистики переходов и
            посещений (трекинговые/целевые/маркетинговые файлы cookie).
          </li>
        </ul>

        <br />

        <p>
          В вышеназванных целях мы используем следующие файлы cookie:
        </p>

        <br />

        <div className={styles.scrollable}>
          <table>
            <thead>
              <tr>
                <th>Сторона, предоставляющая файлы сookie</th>
                <th>Категория</th>
                <th>Юридический адрес</th>
                <th>Цель</th>
                <th>Тип</th>
                <th>Окончание срока действия</th>
                <th>Положение о конфиденциальности</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Google Analytics _ga_cid</td>
                <td>Аналитические</td>
                <td>США</td>
                <td>Google Analytics: регистрирует уникальный идентификатор с тем, чтобы сгенерировать статистику посещений веб-сайта.</td>
                <td>Https-cookie</td>
                <td>По истечении сеанса</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Положение о конфиденциальности</a></td>
              </tr>
              <tr>
                <td>Google Analytics _ga</td>
                <td>Аналитические</td>
                <td>США</td>
                <td>Google Analytics: отслеживает (повторные) посещения.</td>
                <td>Https-cookie</td>
                <td>2 года</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Положение о конфиденциальности</a></td>
              </tr>
              <tr>
                <td>Google Analytics _gid</td>
                <td>Аналитические</td>
                <td>США</td>
                <td>Google Analytic: делит пользователей на различные типы.</td>
                <td>Https-cookie</td>
                <td>24 часа</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Положение о конфиденциальности</a></td>
              </tr>
              <tr>
                <td>Google Analytics _gat</td>
                <td>Аналитические</td>
                <td>США</td>
                <td>Google Analytics: используется для управления долей запросов.</td>
                <td>Https-cookie</td>
                <td>По истечении сеанса</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Положение о конфиденциальности</a></td>
              </tr>
              <tr>
                <td>Google Doubleclick IDE</td>
                <td>Маркетинговые</td>
                <td>США</td>
                <td>Google Doubleclick: используется для оптимизации рекламных объявлений.</td>
                <td>Https-cookie</td>
                <td>1 год</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Положение о конфиденциальности</a></td>
              </tr>
              <tr>
                <td>Google Doubleclick DSID</td>
                <td>Маркетинговые</td>
                <td>США</td>
                <td>Google Doubleclick: используется для анализа ваших посещений в целях отображения соответствующих рекламных объявлений.</td>
                <td>Https-cookie</td>
                <td>1 неделя</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Положение о конфиденциальности</a></td>
              </tr>
              <tr>
                <td>Amplitude amplitude_id*</td>
                <td>Трекинговые</td>
                <td>США</td>
                <td>Amplitude: отслеживает уникальных посетителей веб-сайта в целях оптимизации.</td>
                <td>Https-cookie</td>
                <td>10 лет</td>
                <td><a href='https://amplitude.com/privacy'>Положение о конфиденциальности</a></td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />

        <p>
          При первом посещении вами нашего сервиса мы показываем вам уведомление с описанием наших файлов cookie и
          запрашиваем ваше согласие на использование таких файлов. Вы можете отключить использование файлов cookie в
          настройках в своем браузере, но в этом случае отдельные разделы нашего веб-сайта могут работать
          некорректно.
        </p>

        <h2>Intercom</h2>
        <p>
          На нашем веб-сайте используются аналитические, маркетинговые и трекинговые/оптимизационные файлы cookie, как
          указано выше. Мы также используем файлы cookie компании Intercom, обеспечивающие возможность работы
          онлайн-чата. Информация о посещениях вашего браузера и данные из чата собираются и хранятся на серверах в
          Соединенных Штатах. Мы подписали соглашение об обработке данных с компанией Intercom. В результате мы не
          предоставили компании Intercom согласие на использование информации, полученной через эти файлы cookie. Мы
          кодируем максимально возможный объем информации. Для получения более подробной информации рекомендуем вам
          ознакомиться с <a href='https://www.intercom.com/terms-and-policies#terms'>Положением о конфиденциальности</a> компании Intercom.
        </p>

        <h2>Google Analytics и Amplitude</h2>
        <p>
          Мы используем системы Google Analytics и Amplitude для отслеживания посетителей нашего веб-сайта и подготовки
          отчетов об использовании посетителями нашего веб-сайта. Мы согласились с положениями соглашения об обработке
          данных компании Google. В результате мы не предоставили компании Google согласие на использование информации,
          полученной системой Analytics, для других сервисов Google. Мы также подписали соглашение об обработке данных с
          системой Amplitude. Мы кодируем максимально возможный объем информации. Для получения более подробной
          информации рекомендуем Вам ознакомиться с <a href='https://amplitude.com/privacy'>Положением о конфиденциальности</a> компании Google и/или Amplitude.
        </p>

        <br />

        <p>
          Мы заключили договоренности с другими лицами, размещающими файлы cookie через наш веб-сайт. Тем не менее, мы
          не можем обеспечить полный контроль за их действиями в отношении размещаемых ими файлов cookie, поэтому просим
          вас также ознакомиться с их положениями о конфиденциальности. Более подробная информация содержится в сводной
          таблице по файлам cookie в настоящем пункте.
        </p>

        <h2>Предоставление персональных данных третьим лицам</h2>
        <p>
          Ваши персональные данные могут передаваться сторонним специалистам, нанимаемым компанией Pylot для обработки
          данных для компании Pylot и оказания содействия в предоставлении запрашиваемых вами контента и услуг, например
          нашим ИТ-поставщикам, или храниться таковыми. Компания Pylot обязуется принять надлежащие меры для защиты
          ваших персональных данных. Компания Pylot должна обязать таких сторонних специалистов обрабатывать ваши
          персональные данные с сохранением их конфиденциальности и принимать надлежащие технические и организационные
          меры для защиты ваших данных от любых форм незаконной обработки.
        </p>

        <br />

        <p>
          Кроме того, ваши персональные данные могут быть разглашены третьему лицу, если компания Pylot обязана
          раскрыть их по решению суда или в силу любого иного законного или юридического обязательства или в случаях,
          когда она считает это необходимым для предотвращения или прекращения незаконных действий или нарушений нашей
          политики.
        </p>

        <h2>Безопасность</h2>
        <p>
          Мы обязуемся приложить все усилия для защиты наших услуг в целях предотвращения противоправного и
          ненадлежащего использования ваших персональных данных и получения незаконного доступа к таковым. Мы
          гарантируем предоставление доступа к вашим персональным данным только уполномоченным лицам и контроль за
          получением доступа к таким данным.
        </p>

        <h2>Сроки хранения данных</h2>
        <p>
          Все персональные данные, предоставляемые или вводимые вами, хранятся в защищенной базе данных Amazon Web
          Service Europe (Ирландия). Amazon Web Services является одной из самых безопасных ныне действующих платформ
          облачных услуг.
        </p>

        <h2>Поправки в настоящую политику конфиденциальности и использования файлов cookie</h2>

        <p>
          В случае внесения изменений на веб-сайте мы также должны вносить поправки в нашу Политику конфиденциальности
          и использования файлов cookie. Поэтому просим вас обращать внимание на дату последней редакции, указанную в
          начале настоящей страницы, и удостоверяться в отсутствии новых версий. Мы обязуемся всегда прилагать все
          усилия для информирования о таких поправках помимо размещения уведомлений на вебсайте.
        </p>

        <h2>Ваши права</h2>

        <p>
          Вы можете связаться с нами в случае возникновения у вас любых вопросов по обработке нами ваших персональных
          данных или если вы захотите получить сведения о типах ваших персональных данных, находящихся в нашем
          распоряжении. Наша контактная информация приведена ниже.
        </p>

        <br />

        <p>В соответствии с законодательством о защите конфиденциальности вы обладаете следующими правами:</p>

        <ul type='circle'>
          <li>на получение <strong>информации</strong> об имеющихся у нас персональных данных и целях их использования;</li>
          <li>на <strong>проверку</strong> хранящихся у нас ваших персональных данных;</li>
          <li>на <strong>исправление ошибок</strong> в недостоверных данных;</li>
          <li>на <strong>передачу</strong> ваших персональных данных;</li>
          <li>на направление требования об <strong>удалении</strong> персональных данных;</li>
          <li>на направление требования об <strong>ограничении</strong> передачи данных;</li>
          <li>на <strong>отзыв</strong> вашего согласия;</li>
          <li>на выражение <strong>несогласия</strong> с определенными формами использования.</li>
        </ul>

        <br />

        <p>
          Просим гарантировать предоставление нам информации, идентифицирующей вашу личность, с тем чтобы мы могли быть
          уверены, что мы передаем информацию надлежащему лицу и что мы не изменяем и не удаляем информацию по заявлению
          ненадлежащего лица.
        </p>

        <h2>Подача претензий</h2>

        <p>
          В соответствии с законодательством о защите конфиденциальности вы вправе подать претензию касательно
          обработки нами ваших персональных данных в надзорный орган в вашей стране. В Нидерландах такой орган именуется
          Autoriteit Persoonsgegevens. В целях подачи претензии в связи с обработкой нами данных просим вас связаться с
          надзорным органом в вашей стране или обратиться в <a href='https://autoriteitpersoonsgegevens.nl'>Autoriteit Persoonsgegevens</a>.
        </p>

        <h2>Контактная информация</h2>

        <p>
          Pylot B.V.<br />
          Адрес: Vlotlaan 710, Monster, 2681 TX, The Netherlands (Нидерланды)<br />
          Номер в Торговой палате: 69516103<br />
          Электронный адрес: info@pylot.nl<br />
          Номер телефона: +31 174 286 161<br />
        </p>

      </article>
    );
  }
}
