import numbersRounding from '../numbersRounding';

/**
 * Вычисляет на сколько процентов текущее значение больше/меньше предыдущего
 * @param {(number|string)} previous - Предыдущее значение.
 * @param {(number|string)} actual - Текущее значение.
 * @param {boolean} withoutPrefix - Вернуть без знака.
 * @param {boolean} invertPercent - Инверсия значения.
 * @return {string} Округлённое количество процентов со знаком и единицей измерения или '–'
 */
export const getPercentDiff = (previous, actual, withoutPrefix = false, invertPercent = false) => {
  // Приводим к числам (если переданы строки)
  const actualNumber = Number(actual);
  const previousNumber = Number(previous);

  // Проверяем являются ли числом и не 0
  if (actualNumber === 0 || previousNumber === 0 || Number.isNaN(actualNumber) || Number.isNaN(previousNumber)) {
    return '–';
  }

  const onePercent = previousNumber / 100;
  const diff = actualNumber - previousNumber;
  const prefix = diff < 0 || withoutPrefix ? '' : '+';
  const value = withoutPrefix ? Math.abs(diff / onePercent) : diff / onePercent;
  const valueWithInverse = invertPercent ? 100 - value : value;

  return `${prefix}${numbersRounding(valueWithInverse, 'fixed', 1)}%`;
};


/**
 * Вычисляет сколько процентов текущее значение составляет от предыдущего предыдущего
 *
 * @param {(number|string)} previous - Предыдущее значение.
 * @param {(number|string)} actual - Текущее значение.
 * @return {string} Округлённое количество процентов.
 */
export const getPercent = (previous, actual) => {
  // Приводим к числам (если переданы строки)
  const actualNumber = Number(actual);
  const previousNumber = Number(previous);

  // Проверяем являются ли числом и не 0
  if (actualNumber === 0 || previousNumber === 0 || Number.isNaN(actualNumber) || Number.isNaN(previousNumber)) {
    return '–';
  }

  const onePercent = previousNumber / 100;
  const percentValue = actualNumber / onePercent;

  return `${numbersRounding(percentValue, 'fixed', 1)}%`;
};
