import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  requestPlanFactReport,
  requestPlanFactGraph,
  updatePlanFactSorting,
  requestPlanFactV2Report,
  requestPlanFactExport,
} from 'store/planFactReport/actions';

import {
  getPlanFactPoints,
  getPlanFactReport,
  getIsPlanFactV2Fetching,
  getPlanFactMaxValue,
  getPeriodFromRoute,
  getPlanFactReportSorting,
  getPlanFactCategories,
  getPlanFactUnits,
  getPlanFactV2Report,
} from 'store/planFactReport/selectors';

import {
  getFirstSpecies,
  getSpeciesFromRoute,
  getBreakdownTypeFromRoute,
  getGraphKeyFromRoute,
  getGraphKeySecondFromRoute,
  getCurrentSpecies,
  getAllVarieties,
  getAllFruitClasses,
  getCurrentCompartments,
  getAllPlantingCycles,
  getAllProductGroups,
  getOrganizationSlug,
  getUnitsFromRoute,
  getLocationPlanFromRoute,
  getAnyDateOfPeriodStartFromRoute,
  getAnyDateOfPeriodEndFromRoute,
  getCompartmentIdsFromRoute,
  getCurrentGrownFilterFromRoute,
  getVarietyIdFromRoute,
  getFruitClassFromRoute,
} from 'store/company/selectors';

import PlanFactReportDashboard from '../components/PlanFactReportDashboard';

const mapDispatchToProps = {
  requestPlanFactReport,
  requestPlanFactGraph,
  updatePlanFactSorting,
  requestPlanFactV2Report,
  requestPlanFactExport,
};

const mapStateToProps = (state, props) => ({
  ...props,

  locationPlan: getLocationPlanFromRoute(state, props),
  isPlanFactFetching: getIsPlanFactV2Fetching(state, props),
  points: getPlanFactPoints(state, props),
  maxValue: getPlanFactMaxValue(state, props),
  report: getPlanFactReport(state, props),
  period: getPeriodFromRoute(state, props),
  breakdown: getBreakdownTypeFromRoute(state, props),
  currentGraphKey: getGraphKeyFromRoute(state, props),
  currentGraphKeySecond: getGraphKeySecondFromRoute(state, props),
  currentSpecies: getSpeciesFromRoute(state, props) || getFirstSpecies(state, props),
  currentVariety: getVarietyIdFromRoute(state, props),
  currentFruitClass: getFruitClassFromRoute(state, props),
  species: getCurrentSpecies(state, props),
  varieties: getAllVarieties(state, props),
  fruitClasses: getAllFruitClasses(state, props),
  compartments: getCurrentCompartments(state, props),
  allPlantingCycles: getAllPlantingCycles(state, props),
  productGroups: getAllProductGroups(state, props),
  organizationSlug: getOrganizationSlug(state, props),
  planFactReportSorting: getPlanFactReportSorting(state, props),
  planFactCategories: getPlanFactCategories(state, props),
  currentUnits: getUnitsFromRoute(state, props),
  planFactUnits: getPlanFactUnits(state, props),
  v2Report: getPlanFactV2Report(state, props),

  anyDateOfPeriodStart: getAnyDateOfPeriodStartFromRoute(state, props),
  anyDateOfPeriodEnd: getAnyDateOfPeriodEndFromRoute(state, props),
  compartmentId: getCompartmentIdsFromRoute(state, props),
  currentGrownFilter: getCurrentGrownFilterFromRoute(state, props),
  // allPlantingCycles: getAllPlantingCycles(state),
  // harvestFirstCategoryName: getHarvestFirstCategoryName(state),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(PlanFactReportDashboard)));
