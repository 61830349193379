import { createSelector } from 'reselect';

import { get, find, flow } from 'lodash';

export const getDataQualityReport = state => get(state, 'dataQuality.dataQualityReport');
export const getDataQualityReportCompartments = state => get(state, 'dataQuality.dataQualityReport.compartments') || [];
export const getCurrentYearOverallQuality = state => get(state, 'dataQuality.currentYearOverallQuality');
export const getOverallQualityReceivedAt = state => get(state, 'dataQuality.overallQualityReceivedAt');
export const getIsDataQualityReportFetching = state => get(state, 'dataQuality.isDataQualityReportFetching');
export const getGreenhousesOptions = state => get(state, 'dataQuality.greenhousesOptions');
export const getDataTypeOptions = state => get(state, 'dataQuality.dataTypeOptions');

export const getFilteredGreenhouses = createSelector(
  getDataQualityReportCompartments,
  getGreenhousesOptions,
  (compartments, greenhousesOptions) => compartments.filter(compartment => flow([
    options => find(options, { value: compartment.id }),
    currentCompartmentOption => currentCompartmentOption.checked,
  ])(greenhousesOptions)),
);
