import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';


import {
  clearEnergyReport, requestEnergyReport, updateFiltersEnergyReport, updateEnergyReportSorting
} from 'store/energy/actions';

import { getEnergyReport, getEnergyReportSorting, getIsEnergyReportFetching } from 'store/energy/selectors';

import {
  getAllProductGroups,
  getAnyDateOfPeriodEndFromRoute,
  getAnyDateOfPeriodStartFromRoute,
  getBreakdownTypeFromRoute,
  getCompartmentIdsFromRoute,
  getFruitClassFromRoute,
  getSpeciesFromRoute,
  getVarietyIdFromRoute,
  getUnitKindFromRoute,
  getOrganizationSlugFromRoute,
  getCurrentSpecies,
  getAllFruitClasses,
  getVarietiesInLocationWithTargetWeight,
} from 'store/company/selectors';

import { getPeriodType } from 'store/plans/selectors';
import EnergyDashboard from '../components/EnergyDashboard';

const mapDispatchToProps = {
  requestEnergyReport,
  updateFiltersEnergyReport,
  updateEnergyReportSorting,
  clearEnergyReport,
};

const mapStateToProps = (state, props) => ({
  energyReport: getEnergyReport(state, props),
  energyReportSorting: getEnergyReportSorting(state, props),
  isEnergyReportFetching: getIsEnergyReportFetching(state, props),

  varieties: getVarietiesInLocationWithTargetWeight(state, props),
  allSpecies: getCurrentSpecies(state, props),
  fruitClasses: getAllFruitClasses(state, props),

  productGroups: getAllProductGroups(state, props),
  anyDateOfPeriodStart: getAnyDateOfPeriodStartFromRoute(state, props),
  anyDateOfPeriodEnd: getAnyDateOfPeriodEndFromRoute(state, props),
  periodType: getPeriodType(state, props),
  currentSpecies: getSpeciesFromRoute(state, props),
  currentVarietyId: getVarietyIdFromRoute(state, props),
  currentFruitClassCode: getFruitClassFromRoute(state, props),
  currentBreakdown: getBreakdownTypeFromRoute(state, props),
  currentUnitKind: getUnitKindFromRoute(state, props),
  currentCompartment: getCompartmentIdsFromRoute(state, props),
  organizationSlug: getOrganizationSlugFromRoute(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(EnergyDashboard)));
