export default function toNumber(str) {
  let isNegative = false;
  let unsigned = str.replace(/[-+]/g, (s) => {
    if (s === '-') {
      isNegative = true;
    }
    return '';
  });

  unsigned = unsigned.trim();
  const allSeparators = unsigned.match(/[^\d]/g);
  const distinctSeparators = allSeparators ? allSeparators.filter((v, i, a) => a.indexOf(v) === i) : [];

  if (distinctSeparators.length === 0) {
    return parseFloat((isNegative ? '-' : '') + unsigned);
  }

  let lastIdx = -1;

  distinctSeparators.forEach((s) => {
    const idx = unsigned.lastIndexOf(s);

    lastIdx = idx > lastIdx ? idx : lastIdx;
  });

  let whole = unsigned.substring(0, lastIdx);
  const fraction = unsigned.substring(lastIdx + 1);

  whole = whole.replace(/[^\d]/g, '');

  if (distinctSeparators.length === 1 && (allSeparators.length > 1 || fraction.length === 3)) { // this is group separator
    return parseFloat((isNegative ? '-' : '') + whole + fraction);
  }

  return parseFloat(`${isNegative ? '-' : ''}${whole}.${fraction}`);
}
