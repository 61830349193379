import queryString from 'query-string';

import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getCropsData,
  updateCropsVisibility,
  updateCropsSorting,
  trackPageViewPlantingCycles,
} from 'store/newCrops/actions';

import {
  getOrganization,
  getIsAddPlantingCycleAvailable,
  getIsAddHarvestAvailable,
  getIsCropCompareAvailable,
} from 'store/company/selectors';

import { trackAddHarvestClick, clearHarvestData } from 'store/harvest/actions';
import {
  requestDeletePlantingCycle,
  trackCreatePlantingCycleClick,
  trackEditPlantingCycleClick
} from 'store/crops/actions';
import {
  getVisibilityFilter, getVisibleCycles, getCropsFetching, getCropsSorting, getCyclesCounter
} from 'store/newCrops/selectors';

import {
  addCycleToComparison,
  removeCycleFromComparison,
} from 'store/benchmark/actions';

import {
  getCyclesToCompare,
} from 'store/benchmark/selectors';

import NewCrops from './NewCrops';

const mapDispatchToProps = {
  getCropsData,
  updateCropsVisibility,
  updateCropsSorting,
  trackPageViewPlantingCycles,
  trackCreatePlantingCycleClick,
  trackEditPlantingCycleClick,
  trackAddHarvestClick,
  clearHarvestData,
  requestDeletePlantingCycle,
  addCycleToComparison,
  removeCycleFromComparison,
};

const mapStateToProps = (state, props) => {
  const { intl } = state;

  const parsedQuery = queryString.parse(get(props, 'location.search'));
  const fromPage = get(parsedQuery, 'fromPage');

  return {
    ...intl,
    visibilityFilter: getVisibilityFilter(state),
    visibleCycles: getVisibleCycles(state, props),
    cropsSorting: getCropsSorting(state),
    isCropsFetching: getCropsFetching(state),
    cyclesCounter: getCyclesCounter(state),
    organization: getOrganization(state, props),
    isAddPlantingCycleAvailable: getIsAddPlantingCycleAvailable(state, props),
    isAddHarvestAvailable: getIsAddHarvestAvailable(state, props),
    cyclesToCompare: getCyclesToCompare(state, props),
    isCropCompareAvailable: getIsCropCompareAvailable(state, props),
    fromPage,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewCrops)));
