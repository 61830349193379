import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';
import classnames from 'classnames';
import {
  chunk, uniqueId, head, last
} from 'lodash';

import { getYearsBefore } from '../../../../helpers/getYears';

import styles from './YearRangePickerDropdown.module.css';

export default class YearRangePickerDropdown extends PureComponent {
  static propTypes = {
    years: PropTypes.array.isRequired,
    selectedYears: PropTypes.array.isRequired,
    disabledYears: PropTypes.array.isRequired,
    yearsInRow: PropTypes.number,
    onPeriodSelect: PropTypes.func.isRequired,
    onPeriodHover: PropTypes.func,

    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    yearsInRow: 4, // Для кастомизации этого надо ещэ добавить изменение стилей

    onPeriodHover: () => {},
  };

  state ={
    inProgress: undefined,
    hoveredYears: [],
  };

  handlerPeriodChange = (years) => {
    const { onPeriodSelect } = this.props;

    const startOfPeriod = head(years);
    const endOfPeriod = last(years);

    onPeriodSelect({
      startDate: moment(startOfPeriod, 'YYYY').startOf('year').format('YYYY-MM-DD'),
      endDate: moment(endOfPeriod, 'YYYY').endOf('year').format('YYYY-MM-DD'),
    });
  };

  handlerYearClick = (year) => {
    const { onPeriodHover } = this.props;
    const { inProgress, hoveredYears } = this.state;


    if (inProgress && inProgress === year) {
      this.handlerPeriodChange([year]);

      return this.setState({ inProgress: undefined, hoveredYears: [] });
    }

    if (inProgress) {
      this.handlerPeriodChange(hoveredYears);

      return this.setState({ inProgress: undefined, hoveredYears: [] });
    }

    const newHoveredYears = [year];

    onPeriodHover({ hoveredYears: newHoveredYears });

    return this.setState({ inProgress: year, hoveredYears: newHoveredYears });
  };

  handlerYearMouseEnter = (year) => {
    const { onPeriodHover } = this.props;
    const { inProgress } = this.state;

    if (inProgress) {
      const numInProgressYear = Number(inProgress);
      const numYear = Number(year);
      const minYear = Math.min(numInProgressYear, numYear);
      const maxYear = Math.max(numInProgressYear, numYear);

      const yearsCount = maxYear - minYear;
      const hoveredYears = getYearsBefore(maxYear, yearsCount);

      onPeriodHover({ hoveredYears });

      this.setState({ hoveredYears });
    }
  };
  // handlerYearMouseOut = year => this.setState({ inProgress: year });

  renderYear = (year) => {
    const { selectedYears, disabledYears } = this.props;
    const { hoveredYears, inProgress } = this.state;


    const isDisabled = disabledYears.includes(year);

    const selectAvailable = !inProgress && !isDisabled;
    const isSelected = selectedYears.includes(year) && selectAvailable;
    const isSelectedFirst = year === head(selectedYears) && selectAvailable;
    const isSelectedLast = year === last(selectedYears) && selectAvailable;

    const hoverAvailable = inProgress && !isDisabled;
    const inProgressSelected = inProgress === year && hoverAvailable;
    const inProgressHovered = hoveredYears.includes(year) && hoverAvailable;
    const isHoveredFirst = year === head(hoveredYears) && hoverAvailable;
    const isHoveredLast = year === last(hoveredYears) && hoverAvailable;

    /* eslint-disable jsx-a11y/mouse-events-have-key-events */
    return (
      <div
        onMouseEnter={isDisabled ? () => {} : () => this.handlerYearMouseEnter(year)}
        onClick={isDisabled ? () => {} : () => this.handlerYearClick(year)}
        role='button'
        tabIndex={0}
        key={uniqueId('year-')}
        className={classnames(
          styles.year,
          {
            [styles.disabled]: isDisabled,
            [styles.selected]: isSelected,
            [styles.selectedFirst]: isSelectedFirst,
            [styles.selectedLast]: isSelectedLast,

            [styles.hovered]: inProgressHovered,
            [styles.inProgressSelected]: inProgressSelected,
            [styles.hoveredFirst]: isHoveredFirst,
            [styles.hoveredLast]: isHoveredLast,
          }
        )}
      >
        {year}
      </div>
    );
    /* eslint-enable jsx-a11y/mouse-events-have-key-events */
  }

  renderRow = rowYears => (
    <div
      key={uniqueId('row-year-')}
      className={styles.yearsRow}
    >
      {rowYears.map(this.renderYear)}
    </div>
  );

  render() {
    const { years, yearsInRow, className } = this.props;

    const splittedByRows = chunk(years, yearsInRow);

    return (
      <div className={classnames(styles.dropdownContent, className)}>
        <div className={styles.years}>
          {splittedByRows.map(this.renderRow)}
        </div>
      </div>
    );
  }
}
