import { useEffect } from 'react';

import { event as lastEvent, select } from 'd3';

function usePoints(groupRef, groupRightRef, options) {
  const {
    chartParams: {
      x, y, colors, rY, rX
    },
    rightData,
    data,
    xType,
    yType,
    mouseOverHandler,
    mouseOutHandler,
    mouseMoveHandler,
    clickHandler,
    withoutPoints,
    parseDate
  } = options;
  useEffect(() => {
    const group = groupRef.current ? select(groupRef.current) : null;
    if (group && !withoutPoints) {
      const pointsRadius = withoutPoints ? '0' : '4';

      /*
       * We don't really need to do this, but it
       * avoids obscure "this" below
       */
      const calculateDate = v => parseDate(v);

      const getStroke = colorIndex => colors[colorIndex];


      const mouseover = d => mouseOverHandler(d, lastEvent);
      const mouseout = d => mouseOutHandler(d, lastEvent);
      const mousemove = d => mouseMoveHandler(d, lastEvent);
      const click = d => clickHandler(d, lastEvent);

      /*
       * Creating the calculation functions
       */
      const calculateCX = d => (
        (xType === 'time')
          ? x(calculateDate(d.x))
          : x(d.x));
      const calculateCY = d => (
        (yType === 'time')
          ? y(calculateDate(d.y))
          : y(d.y));

      group.selectAll('circle').remove();

      data.forEach((item, i) => {
        item.forEach((d) => {
          group
            .datum(d)
            .append('circle')
            .attr('class', 'data-point')
            .attr('r', pointsRadius)
            // .style('strokeWidth', '2px')
            // .style('stroke', getStroke)
            .style('fill', getStroke(i))
            .attr('cx', calculateCX)
            .attr('cy', calculateCY)
            .on('mouseover', mouseover)
            .on('mouseout', mouseout)
            .on('mousemove', mousemove)
            .on('click', click);
        });
      });
      const groupRight = groupRightRef.current ? select(groupRightRef.current) : null;

      groupRight.selectAll('circle').remove();

      if (rightData && rightData.length > 0) {
        const calculateCXRight = d => (
          (xType === 'time')
            ? rX(calculateDate(d.x))
            : rX(d.x));
        const calculateCYRight = d => (
          (yType === 'time')
            ? rY(calculateDate(d.y))
            : rY(d.y));

        rightData.forEach((item, i) => {
          item.forEach((d) => {
            const fill = getStroke((data?.length || 0) + i);
            /*
             * Applying the calculation functions
             */
            groupRight
              .datum(d)
              .append('circle')
              .attr('class', 'data-point')
              .attr('r', pointsRadius)
              // .style('strokeWidth', '2px')
              // .style('stroke', getStroke)
              .style('fill', fill)
              .attr('cx', calculateCXRight)
              .attr('cy', calculateCYRight)
              .on('mouseover', mouseover)
              .on('mouseout', mouseout)
              .on('mousemove', mousemove)
              .on('click', click);
          });
        });
      }
    }
  }, [
    groupRef,
    groupRightRef,
    x, y, colors, rY, rX,
    rightData,
    data,
    xType,
    yType,
    mouseOverHandler,
    mouseOutHandler,
    mouseMoveHandler,
    clickHandler,
    withoutPoints,
    parseDate
  ]);
}

export default usePoints;
