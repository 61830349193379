import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import classnames from 'classnames';

import Typography from 'components/Typography';
import { showIntercom } from 'helpers/intercomHelpers';
import BigButton from 'components/BigButton';

import { ReactComponent as ArrowLeft } from './assets/arrow-left.svg';
import { ReactComponent as ArrowRight } from './assets/arrow-right.svg';

import styles from './EssentialPricePlan.module.css';

const EssentialPricePlan = ({
  intl, className
}) => {
  const { formatMessage } = intl;

  const SLIDER_SENSITIVITY = 150;
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = ['cropModel', 'harvestBenchmarking', 'climateInsights'];
  const imageSizes = [528, 1440, 2880];
  const widthList = [320, 960, 1440];
  const slideName = formatMessage({ id: `essential.page.slide.${images[currentImageIndex]}` });

  const toNextImage = () => {
    setCurrentImageIndex(prevIndex => (prevIndex >= images.length - 1 ? 0 : prevIndex + 1));
  };

  const toPrevImage = () => {
    setCurrentImageIndex(prevIndex => (prevIndex <= 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleTouchStart = ({ targetTouches }) => {
    setTouchStart(targetTouches[0].clientX);
  };

  const handleTouchMove = ({ targetTouches }) => {
    setTouchEnd(targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > SLIDER_SENSITIVITY) {
      toNextImage();
    }

    if (touchStart - touchEnd < -SLIDER_SENSITIVITY) {
      toPrevImage();
    }
  };

  const drawSliderImage = ({
    showIndex,
    altText
  }) => (
    <img
      src={`/${images[showIndex]}-${imageSizes[imageSizes.length - 1]}w.png`}
      srcSet={imageSizes.map((size, index) => `/${images[showIndex]}-${imageSizes[index]}w.png ${widthList[index]}w`).join(',')}
      sizes='50vw'
      className={styles.image}
      alt={`${altText}`}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    />
  );

  return (
    <div className={classnames(styles.layout, className)}>
      <div className={styles.benefits}>
        <span className={styles.label}>{formatMessage({ id: 'essential.page.label' })}</span>
        <Typography className={styles.header} variant='h1'>{formatMessage({ id: 'essential.page.title' })}</Typography>
        <Typography className={styles.caption} variant='caption1'>{formatMessage({ id: 'essential.page.caption' })}</Typography>
        <ul className={styles.benefitsList}>
          <li>{formatMessage({ id: 'essential.page.benefits.compare' })}</li>
          <li>{formatMessage({ id: 'essential.page.benefits.create' })}</li>
          <li>{formatMessage({ id: 'essential.page.benefits.incidents' })}</li>
          <li>{formatMessage({ id: 'essential.page.benefits.resources' })}</li>
          <li>{formatMessage({ id: 'essential.page.benefits.more' })}</li>
        </ul>
        <div className={styles.buttons}>
          <BigButton
            className={classnames(styles.leanMore, styles.button)}
            title={formatMessage({ id: 'essential.page.leanMore' })}
            theme='light'
            href='https://pylot.nl/'
            target='_blank'
            externalLink
          />
          <BigButton
            className={classnames(styles.contact, styles.button)}
            title={formatMessage({ id: 'essential.page.contactSupport' })}
            onClick={showIntercom}
            theme='dark'
          />
        </div>
      </div>
      <div className={styles.slider}>
        {drawSliderImage({
          showIndex: currentImageIndex,
          altText: slideName
        })}
        <div className={styles.sliderButtons}>
          <span className={styles.sliderLabel}>{`${slideName}, ${currentImageIndex + 1} ${formatMessage({ id: 'of' })} ${images.length}`}</span>
          <div
            onClick={toPrevImage}
            className={styles.sliderButton}
            tabIndex={0}
            role='button'
          >
            <ArrowLeft />
          </div>
          <span className={classnames(styles.sliderLabel, styles.sliderLabelMobile)}>{`${slideName}, ${currentImageIndex + 1} of ${images.length}`}</span>
          <div
            onClick={toNextImage}
            className={styles.sliderButton}
            tabIndex={0}
            role='button'
          >
            <ArrowRight />
          </div>
        </div>
      </div>
      <div className={styles.bigButtons}>
        <BigButton
          type='button'
          theme='dark'
          onClick={showIntercom}
          className={classnames(styles.contact, styles.bigButton)}
          title={formatMessage({ id: 'essential.page.contactSupport' })}
        />
        <BigButton
          type='button'
          theme='light'
          className={classnames(styles.leanMore, styles.bigButton)}
          href='https://pylot.nl/'
          target='_blank'
          title={formatMessage({ id: 'essential.page.leanMore' })}
          externalLink
        />
      </div>
    </div>
  );
};

EssentialPricePlan.propTypes = {
  intl: intlShape.isRequired,
  className: PropTypes.string,
};

EssentialPricePlan.defaultProps = {
  className: null,
};

export default injectIntl(EssentialPricePlan);
