import queryString from 'query-string';
import { isString } from 'lodash';

/**
 * Добавляет/изменяет/удаляет GET параметры запроса
 *
 * @param {string || object} oldQuery - Текущая строка GET параметров (или объект).
 * @param {object || string} newParameters - Новые параметры запроса.
 * @param {object} options - Параметры для query-string.
 * @return {string} Новая строка с GET параметрами.
 */
const addGetParameters = (oldQuery, newParameters, options) => {
  const oldParsedQuery = isString(oldQuery) ? queryString.parse(oldQuery, options) : oldQuery;
  const newParsedQuery = isString(newParameters) ? queryString.parse(newParameters, options) : newParameters;

  const newQuery = { ...oldParsedQuery, ...newParsedQuery };
  const newSearch = queryString.stringify(newQuery, options);

  return newSearch ? `?${newSearch}` : '';
};

export default addGetParameters;
