import React, {
 useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import { uniqueId } from 'lodash';

import DefaultTable from 'components/DefaultTable';
import Checkbox from 'components/Checkbox';

import styles from './IncidentsTable.module.css';

const CRITICAL_SEVERITY = 3;
const WARNING_SEVERITY = 2;
const POTENTIAL_SEVERITY = 1;

const renderHeaderRows = formatMessage => (
  <tr>
    <th className={styles.th}>{formatMessage({ id: 'graphIncidents.incidentHeader' })}</th>
    <th className={styles.th}>{formatMessage({ id: 'incidentsTimeline.severity.critical' })}</th>
    <th className={styles.th}>{formatMessage({ id: 'incidentsTimeline.severity.warning' })}</th>
    <th className={styles.th}>{formatMessage({ id: 'incidentsTimeline.severity.potential' })}</th>
  </tr>
);

const renderRows = (handlerUpdateCheckbox, incidentsList) => incidentsList.map(incident => (
  <tr key={uniqueId('incident-table-row-')}>
    <td className={styles.td}>{incident.text}</td>
    <td className={styles.td}>
      <div className={styles.checkboxTd}>
        <Checkbox
          className={styles.checkbox}
          onChange={() => handlerUpdateCheckbox({ id: incident.id, level: CRITICAL_SEVERITY })}
          onClick={(e) => {
            e.stopPropagation();
          }}
          checked={incident.incidentSeverities.includes(CRITICAL_SEVERITY)}
          value={`${incident.id}-${CRITICAL_SEVERITY}`}
        />
      </div>
    </td>
    <td className={styles.td}>
      <div className={styles.checkboxTd}>
        <Checkbox
          key={incident.id}
          className={styles.checkbox}
          onChange={() => handlerUpdateCheckbox({ id: incident.id, level: WARNING_SEVERITY })}
          onClick={(e) => {
            e.stopPropagation();
          }}
          checked={incident.incidentSeverities.includes(WARNING_SEVERITY)}
          value={`${incident.id}-${WARNING_SEVERITY}`}
        />
      </div>
    </td>
    <td className={styles.td}>
      <div className={styles.checkboxTd}>
        <Checkbox
          className={styles.checkbox}
          onChange={() => handlerUpdateCheckbox({ id: incident.id, level: POTENTIAL_SEVERITY })}
          onClick={(e) => {
            e.stopPropagation();
          }}
          checked={incident.incidentSeverities.includes(POTENTIAL_SEVERITY)}
          value={`${incident.id}-${POTENTIAL_SEVERITY}`}
        />
      </div>
    </td>
  </tr>
));

const updateLevels = (incidentSeverities, currentSeverity) => {
  const exists = incidentSeverities.includes(currentSeverity);
  if (exists && incidentSeverities.length === 1) {
    return incidentSeverities;
  }

  if (exists) {
    return incidentSeverities.filter(item => item !== currentSeverity);
  }

  return [...incidentSeverities, currentSeverity];
};

const IncidentsTable = ({
  intl: { formatMessage },
  incidentsList,
  setIncidentsList,
}) => {
  const handlerUpdateCheckbox = useCallback(({ id, level }) => {
    const newIncidentsListState = incidentsList.map((incidentItem) => {
      if (incidentItem.id === id) {
        return {
          ...incidentItem,
          incidentSeverities: updateLevels(incidentItem.incidentSeverities, level),
        };
      }

      return incidentItem;
    });

    setIncidentsList(newIncidentsListState);
  }, [setIncidentsList, incidentsList]);

  return (
    <div className={styles.tableWrapper}>
      <DefaultTable
        headerRows={renderHeaderRows(formatMessage)}
        bodyRows={renderRows(handlerUpdateCheckbox, incidentsList)}
        small
      />
    </div>
  );
};

IncidentsTable.propTypes = {
  intl: intlShape.isRequired,
  incidentsList: PropTypes.array,
  setIncidentsList: PropTypes.func.isRequired,
};

IncidentsTable.defaultProps = {
  incidentsList: [],
};

export default injectIntl(IncidentsTable);
