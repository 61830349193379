export function googleAnalyticsTrackPageView({ path }) {
  if (window && window.ga) {
    window.ga('send', 'pageview', path);
  }
}

export function googleAnalyticsLogEvent({ payload }) {
  if (window && window.ga) {
    window.ga('send', payload);
  }
}

export function googleAnalyticsSetCustomDimention({ dimention, value }) {
  if (window && window.ga && dimention && value) {
    window.ga('set', dimention, value);
  }
}

export function googleAnalyticsSetUserId({ userId }) {
  if (window && window.ga) {
    window.ga('set', 'userId', userId);
  }
}
