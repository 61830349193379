import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import { find, uniqueId } from 'lodash';
import classnames from 'classnames';

import useMountEffect from 'hooks/useMountEffect';
import { getNameByLocal } from 'helpers/getNameByLocal';

import Typography from 'components/Typography';
import BackButton from 'components/BackButton';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import DefaultTextInput from 'components/DefaultTextInput';
import ArrowDown from 'components/Icons/ArrowDown';

import styles from './SettingsHarvestForecastSpecies.module.css';

const renderControlWrapper = ({
  className, disabled, placeholder, content
}) => (
  <div className={classnames(styles.inputWrapper, className)}>
    <div
      className={classnames(
        styles.inputContainer,
        {
          [styles.inputContainerDisabled]: disabled,
        },
      )}
    >
      <label
        htmlFor={`default-text-input-${uniqueId()}`}
        className={styles.inputWithLabel}
      >
        {placeholder && (
          <span className={classnames(styles.label, { [styles.labelActive]: true })}>
            {placeholder}
          </span>
        )}
        <div>
          {content}
        </div>
      </label>
    </div>
  </div>
);

const renderCategoryName = (locale, category) => (
  <span key={uniqueId('categoryName-')} className={styles.categoryName}>
    {getNameByLocal(category, locale)}
  </span>
);

const renderCategory = (intl, allCategories, category) => {
  const { formatMessage, locale } = intl;
  const name = getNameByLocal(category, locale);
  const units = formatMessage({ id: `cunits.mini.${category?.units}` });
  const relatedTo = category?.relatedTo;
  const currentRelatedToCategory = find(allCategories, { id: relatedTo });

  return (
    <div key={uniqueId('categoryGroup-')} className={styles.categoryGroup}>
      <div className={classnames(styles.column, styles.firstColumn)}>
        <Typography variant='body1' color='secondary' className={styles.columnHeader}>
          {formatMessage({ id: 'forecast.settings.enterCategory' })}
        </Typography>
        <DefaultTextInput
          className={styles.columnContent}
          placeholder={formatMessage({ id: 'plansList.name' })}
          value={name}
          disabled
        />
      </div>
      <div className={classnames(styles.column, styles.secondColumn)}>
        <Typography variant='body1' color='secondary' className={styles.columnHeader}>
          {formatMessage({ id: 'forecast.settings.enterUnit' })}
        </Typography>
        <div className={styles.categoryWrapper}>
          <div className={styles.arrowWrapper}>
            {renderControlWrapper({
              className: styles.columnContent,
              disabled: true,
              placeholder: formatMessage({ id: 'plansList.unit' }),
              content: units,
            })}
            <ArrowDown className={styles.arrowDown} />
          </div>
          {relatedTo && (
            <>
              <div className={styles.controlPrefix}>
                {formatMessage({ id: 'forecast.settings.from' })}
              </div>
              <div className={classnames(styles.arrowWrapper, styles.arrowWrapperWide)}>
                {renderControlWrapper({
                  className: styles.columnContent,
                  disabled: true,
                  placeholder: formatMessage({ id: 'forecast.settings.enterCategory' }),
                  content: getNameByLocal(currentRelatedToCategory, locale),
                })}
                <ArrowDown className={styles.arrowDown} />
              </div>
            </>
          )}
        </div>
      </div>
      <div className={classnames(styles.column, styles.thirdColumn)}>
        <Typography variant='body1' color='secondary' className={styles.columnHeader}>
          {formatMessage({ id: 'forecast.settings.systemMatching' })}
        </Typography>
        {renderControlWrapper({
          className: styles.columnContent,
          disabled: true,
          placeholder: formatMessage({ id: 'forecast.settings.harvestInSystem' }),
          content: category?.harvestCategories?.map(item => renderCategoryName(locale, item)),
        })}
      </div>
    </div>
  );
};

const renderCategoriesList = (intl, categoriesList) => (
  <div className={styles.categoriesList}>
    {categoriesList?.forecastCategories?.map(category => renderCategory(intl, categoriesList?.forecastCategories, category))}
  </div>
);

const SettingsHarvestForecastSpecies = ({
  intl,
  intl: { formatMessage },
  history,
  organizationSlug,
  isSettingsFetching,
  speciesType,
  harvestForecastSettings,
  harvestForecastAccess,

  requestHarvestForecastSettings,
}) => {
  useMountEffect(() => {
    if (!harvestForecastAccess) {
      history.push(`/${organizationSlug}/settings/profile`);
    }

    requestHarvestForecastSettings();
  });

  const currentSettings = find(harvestForecastSettings, { species: speciesType });

  return (
    <div className={styles.settingsHarvestForecastSpecies}>
      {isSettingsFetching && <DefaultCircleLoader />}

      <BackButton link={`/${organizationSlug}/settings/harvest-forecast`} text={formatMessage({ id: 'forecast.harvestForecasts' })} />
      <Typography variant='h2' className={styles.header}>
        {formatMessage({ id: `plantingCycles.allSpecies.${speciesType}` })}
      </Typography>
      <Typography variant='subtitle3' className={styles.subtitle}>
        {formatMessage({ id: 'forecast.settings.enterHarvestCategories' })}
      </Typography>

      <div className={styles.content}>
        {renderCategoriesList(intl, currentSettings)}
      </div>
    </div>
  );
};

SettingsHarvestForecastSpecies.propTypes = {
  intl: intlShape.isRequired,
  history: PropTypes.object.isRequired,
  isSettingsFetching: PropTypes.bool,
  speciesType: PropTypes.string.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  harvestForecastSettings: PropTypes.array,
  harvestForecastAccess: PropTypes.bool,

  requestHarvestForecastSettings: PropTypes.func.isRequired,
};

SettingsHarvestForecastSpecies.defaultProps = {
  isSettingsFetching: false,
  harvestForecastSettings: [],
  harvestForecastAccess: false,
};

export default memo((SettingsHarvestForecastSpecies));
