import numbersRounding from 'helpers/numbersRounding';
import numbersFormatting from 'helpers/numbersFormatting';

/**
 * Форматирует с отбивкой нулей и огругляет число до нужного количество знаков после запятой
 *
 * @param {(string || number)} value - Число, которое нужно отформатировать.
 * @param {number} roundTo - Количество знаков после запятой для fixed типа округления.
 * @return {string} Отформатированное число.
 */
const numbersFormatAndRounding = (value, roundTo = 0) => (roundTo < 0 ?
  numbersFormatting(value) : numbersFormatting(numbersRounding(value, 'fixed', roundTo)));

export default numbersFormatAndRounding;
