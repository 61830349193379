import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import moment from 'moment-timezone';
import { get, sortBy } from 'lodash';

import VarietySearchSelect from 'components/VarietySearchSelect';

import updateLocationSearch from '../../../../helpers/updateLocationSearch';
import { getYearsByDates } from '../../../../helpers/getYears';
import { getProductTypeName } from '../../../../helpers/getVarietyName';

import BigButton from '../../../BigButton';
import SelectBoxFilter from '../../../SelectBoxFilter';
import DashboardHeader from '../../../DashboardHeader';
import DefaultCircleLoader from '../../../DefaultCircleLoader';
import SimpleBarChart from '../../../SimpleBarChart';
import YearRangePicker from '../../../YearRangePicker';
import TextInfoTooltip from '../../../TextInfoTooltip';

import Plus from '../../../Icons/Plus';

import AvgHarvest from '../AvgHarvest';
import BenchmarkTimeline from '../BenchmarkTimeline';
import Tooltip from '../Tooltip';

import styles from './BenchmarkDashboard.module.css';

const NOT_SELECTED_BENCHMARK_GROUP = -9999;
const NOT_SELECTED_ADDITIONAL_METRIC = -9999;
const NOT_SELECTED_VIEW_BY = 'weekly';

export default class BenchmarkDashboard extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    allPlantingCycles: PropTypes.array,

    benchmarkGroups: PropTypes.array,
    currentBenchmarkGroup: PropTypes.number,
    currentAdditionalMetricId: PropTypes.number,
    currentViewBy: PropTypes.string,
    isBenchmarkAvailable: PropTypes.bool.isRequired,
    isBenchmarkOverviewFetching: PropTypes.bool.isRequired,
    benchmarkOverview: PropTypes.object,
    currentSpecies: PropTypes.string.isRequired,
    currentFruitClass: PropTypes.string,
    currentCompartment: PropTypes.string,
    currentVariety: PropTypes.string,
    availableAdditionalMetrics: PropTypes.array,

    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    currentGrownFilter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    cyclesToCompare: PropTypes.array,

    requestBenchmarkGroups: PropTypes.func.isRequired,
    requestBenchmarkOverview: PropTypes.func.isRequired,
    requestBenchmarkMetrics: PropTypes.func.isRequired,
  };

  static defaultProps = {
    benchmarkOverview: null,
    allPlantingCycles: null,
    currentCompartment: 'all',
    startDate: moment().startOf('year').format('YYYY-MM-DD'),
    endDate: moment().endOf('year').format('YYYY-MM-DD'),
    currentVariety: undefined,
    currentFruitClass: undefined,
    benchmarkGroups: [],
    currentBenchmarkGroup: NOT_SELECTED_BENCHMARK_GROUP,
    currentAdditionalMetricId: NOT_SELECTED_ADDITIONAL_METRIC,
    availableAdditionalMetrics: [],
    currentViewBy: NOT_SELECTED_VIEW_BY,
    cyclesToCompare: [],
  };

  state = {
    sorting: 'harvest',
    alignedBy: 'calendar',
    hoveredTimelineItem: null,
    clickedItem: null,
    hoveredBarItem: null,
  };

  componentDidMount() {
    const {
      match,
      history,
      requestBenchmarkGroups,
      requestBenchmarkMetrics,
      isBenchmarkAvailable,
    } = this.props;

    if (!isBenchmarkAvailable) {
      history.push(`/${match.params.organizationSlug}/dashboards/targets`);
    } else {
      requestBenchmarkGroups();

      requestBenchmarkMetrics();

      this.getBenchmarkOverviewData();
      /**
       * Устанавливаем listener на переход по кнопкам браузера назад/вперёд,
       * чтобы обновлять данные в соответствии с изменившимся search запросом в урле
       *
       * TODO: Вынести в HOC
      */
      this.backListener = history.listen((location, action) => {
        if (action === 'POP') {
          // TODO: заполнять данными, проверить можно ли пропсами или надо через location.search
          this.getBenchmarkOverviewData();
        }
      });
    }
  }

  getParametersFromRoute = () => {
    const {
      currentSpecies,
      currentCompartment,
      currentVariety,
      currentFruitClass,
      currentBenchmarkGroup,
      currentAdditionalMetricId,
      currentViewBy,
      startDate,
      endDate,
    } = this.props;


    return {
      startDate,
      endDate,
      species: currentSpecies,
      compartmentId: currentCompartment === 'all' ? undefined : currentCompartment,
      varietyId: currentVariety,
      fruitClassCode: currentFruitClass,
      benchmarkGroupId: currentBenchmarkGroup === NOT_SELECTED_BENCHMARK_GROUP ? undefined : currentBenchmarkGroup,
      additionalMetricId: currentAdditionalMetricId === NOT_SELECTED_ADDITIONAL_METRIC ? undefined : currentAdditionalMetricId,
      viewBy: currentViewBy === NOT_SELECTED_VIEW_BY ? undefined : currentViewBy,
    };
  };

  getProducts = () => {
    const {
      intl,
      benchmarkOverview,
      currentBenchmarkGroup,
      currentViewBy
    } = this.props;
    const { sorting } = this.state;

    const products = get(benchmarkOverview, 'products', []);
    const productsWithLabels = products.map((item) => {
      const productName = getProductTypeName({
        intl,
        variety: get(item, 'variety'),
        fruitClass: get(item, 'fruitClass'),
        targetWeight: get(item, 'targetWeight'),
        directPath: true,
      });

      return {
        ...item,

        graphLabel: currentBenchmarkGroup === NOT_SELECTED_BENCHMARK_GROUP ?
          productName
          :
          `${get(item, 'location.name')}, ${productName}`,
      };
    });

    if (sorting === 'harvest') {
      return sortBy(productsWithLabels, `harvest.${currentViewBy}.amount`);
    }

    if (sorting === 'seeding') {
      return sortBy(productsWithLabels, product => moment(product.startDate, 'YYYY-MM-DD').valueOf());
    }

    return productsWithLabels;
  }

  getBenchmarkOverviewData = (newParams = {}) => {
    const {
      requestBenchmarkOverview,
    } = this.props;


    const oldParams = this.getParametersFromRoute();

    requestBenchmarkOverview({
      ...oldParams,

      ...newParams,
    });
  };

  getValidSelectValue = (value, valuesList) => (get(valuesList.find(variety => variety.value === Number(value)), 'value') || 'all');

  getCompartmentsList = (compartments, formatMessage) => compartments.reduce((acc, compartment) => (
    [...acc, { label: get(compartment, 'attributes.name'), value: compartment.id }]
  ), [{ label: formatMessage({ id: 'dashboards.allCompartments' }), value: 'all' }]);

  setNewSearchPath = (newParams) => {
    updateLocationSearch({
      ...newParams,
    });

    this.setState({
      hoveredTimelineItem: null,
      clickedItem: null,
      hoveredBarItem: null,
    });

    this.getBenchmarkOverviewData(newParams);
  };

  getAllPlantingYears = () => {
    const { allPlantingCycles } = this.props;
    const minDate = moment.utc('0001-01-01');
    const maxDate = moment.utc('3000-01-01');
    const allYears = allPlantingCycles
      .filter((cycle) => {
        const date = moment.utc(cycle?.attributes?.plantingDate);
        return date.isAfter(minDate) && date.isBefore(maxDate);
      })
      .map(cycle => parseInt(moment(cycle?.attributes?.plantingDate).format('YYYY'), 10));
    const uniqueYears = new Set(allYears);
    return Array.from(uniqueYears).sort();
  }

  handlerCompartmentsSelect = options => this.setNewSearchPath({ compartmentId: options.value === 'all' ? undefined : options.value });

  handlerVarietyOptionSelect = ({ species, varietyId, fruitClassCode }) => this.setNewSearchPath({ species, varietyId, fruitClassCode });

  handlerYearSelect = ({ startDate, endDate }) => this.setNewSearchPath({ startDate, endDate });

  handlerBenchmarkGroupOptionSelect = options => this.setNewSearchPath({ benchmarkGroupId: options.value === NOT_SELECTED_BENCHMARK_GROUP ? undefined : options.value });

  handlerAdditionalMetricsOptionSelect = options => this.setNewSearchPath({ additionalMetricId: options.value === NOT_SELECTED_ADDITIONAL_METRIC ? undefined : options.value });

  handlerViewByOptionSelect = options => this.setNewSearchPath({ viewBy: options.value === NOT_SELECTED_VIEW_BY ? undefined : options.value });

  handleMouseOverTimeline = id => this.setState({ hoveredBarItem: id });

  handleMouseOutTimeline = () => this.setState({ hoveredBarItem: null });

  handleMouseOverBar = id => this.setState({ hoveredTimelineItem: id });

  handleMouseOutBar = () => this.setState({ hoveredTimelineItem: null });

  handleMouseClickBar = (id) => {
    const { clickedItem } = this.state;

    if (id === clickedItem) {
      this.setState({ clickedItem: null });
    } else {
      this.setState({ clickedItem: id });
    }
  };

  renderOptionsFooter = (formatMessage, organizationSlug) => (
    <div className={styles.optionsFooterWrapper}>
      <BigButton
        className={styles.joinBtn}
        href={`/${organizationSlug}/settings/benchmarking`}
        icon={<Plus />}
        title={formatMessage({ id: 'benchmarking.joinGroup' })}
        theme='transparent'
      />
    </div>
  );


  renderDatePicker = () => {
    const { startDate, endDate } = this.props;
    const selectedYears = getYearsByDates(startDate, endDate);
    const currentYear = parseInt(moment.utc().format('YYYY'), 10);
    const avaliableYears = this.getAllPlantingYears();
    const MIN_YEARS_COUNT = 8;
    const years = [];
    const disabledYears = [];

    for (let year = currentYear; year > currentYear - MIN_YEARS_COUNT; year--) {
      if (!avaliableYears.includes(year)) {
        disabledYears.push(year);
      }
      years.push(year);
    }
    const pickedYears = Array.from(new Set(years.concat(avaliableYears))).sort().map(year => year.toString());
    return (
      <YearRangePicker
        years={pickedYears}
        selectedYears={selectedYears}
        disabledYears={disabledYears.map(year => year.toString())}
        onRangeSelect={this.handlerYearSelect}
      />
    );
  };

  renderCustomTooltip = ({
    className, x, y, item
  }) => {
    const { currentBenchmarkGroup } = this.props;

    const isShowLocationName = currentBenchmarkGroup !== NOT_SELECTED_BENCHMARK_GROUP;

    return (
      <Tooltip
        x={x}
        y={y}
        tooltipData={item}
        className={className}
        isShowLocationName={isShowLocationName}
      />
    );
  };

  render() {
    const {
      intl,
      isBenchmarkOverviewFetching,
      benchmarkOverview,
      varieties,
      fruitClasses,
      currentGrownFilter,
      compartments,
      currentCompartment,
      // isBenchmarkGroupsFetching,
      currentBenchmarkGroup,
      currentAdditionalMetricId,
      currentViewBy,
      benchmarkGroups,
      availableAdditionalMetrics,
      match,
      cyclesToCompare,
    } = this.props;
    const {
      sorting, alignedBy, hoveredTimelineItem, clickedItem, hoveredBarItem
    } = this.state;
    const { formatMessage, locale } = intl;
    const { params: { organizationSlug } } = match;

    const locationHarvest = get(benchmarkOverview, 'locationHarvest');
    const groupHarvest = get(benchmarkOverview, 'groupHarvest');
    const products = this.getProducts();

    const totalHarvest = get(locationHarvest, 'total');
    const weeklyHarvest = get(locationHarvest, 'weekly');

    const totalGroupHarvest = get(groupHarvest, 'total');
    const weeklyGroupHarvest = get(groupHarvest, 'weekly');
    const totalChangeGroupHarvest = get(groupHarvest, 'change.total');
    const weeklyChangeGroupHarvest = get(groupHarvest, 'change.weekly');

    const sortingOptions = [
      { label: formatMessage({ id: 'benchmarking.harvest' }), value: 'harvest' },
      { label: formatMessage({ id: 'benchmarking.seedingDate' }), value: 'seeding' },
    ];

    const viewByOptions = [
      { label: formatMessage({ id: 'benchmarking.total' }), value: 'total' },
      { label: formatMessage({ id: 'benchmarking.avgWeekly' }), value: 'weekly' },
    ];

    const alignedByOptions = [
      { label: formatMessage({ id: 'benchmarking.calendar' }), value: 'calendar' },
      { label: formatMessage({ id: 'benchmarking.alignedYears' }), value: 'years' },
    ];

    const compartmentsList = this.getCompartmentsList(compartments, formatMessage);

    const benchmarkAdditionalMetric = get(benchmarkOverview, 'additionalMetrics[0]'); // у нас всегда одна метрика

    const preparedPoints = products.map(product => ({
      id: get(product, 'id'),
      name: get(product, 'graphLabel'),
      color: '#8C46C3',
      value: get(product, `harvest.${currentViewBy}.amount`, null),

      // для дополнительного столбца метрики
      additionalMetrics: benchmarkAdditionalMetric ?
        {
          ...benchmarkAdditionalMetric,

          metricValue: product.measurements[benchmarkAdditionalMetric.id],
          metricColor: '#C8427A',
        }
        :
        null,

      // Служебная информация для тултипа
      viewBy: currentViewBy,
      product,
    }));

    const benchmarkGroupsOptions = [
      { label: formatMessage({ id: 'benchmarking.notSelected' }), value: NOT_SELECTED_BENCHMARK_GROUP },

      ...benchmarkGroups.map(group => ({
        label: group.name,
        value: group.id,
      })),
    ];

    const additionalMetricsOptions = [
      { label: formatMessage({ id: 'benchmarking.notSelected' }), value: NOT_SELECTED_ADDITIONAL_METRIC },

      ...availableAdditionalMetrics.map(metric => ({
        label: get(metric, `name[${locale}]`) || get(metric, 'name.en'),
        value: metric.id,
      })),
    ];

    return (
      <div className={styles.layout}>
        <div className={styles.contentWrapper}>
          <DashboardHeader
            containerClassName={styles.benchmarkingDashboardHeader}
            dashboardName={formatMessage({ id: 'benchmarking.header' })}
            customDatePicker={this.renderDatePicker()}
          />

          <div className={styles.filtersWrapper}>
            <div className={styles.selectboxFilterWrapper}>
              <VarietySearchSelect
                onSelectOption={this.handlerVarietyOptionSelect}
                varieties={varieties}
                fruitClasses={fruitClasses}
                selectedOption={currentGrownFilter}
                classNameButton={styles.varietySelect}
              />
            </div>
            <SelectBoxFilter
              value={this.getValidSelectValue(currentCompartment, compartmentsList)}
              options={compartmentsList}
              onChange={this.handlerCompartmentsSelect}
              title={formatMessage({ id: 'benchmarking.growingIn' })}
              classNameWrapper={styles.selectboxFilterWrapper}
            />
            <SelectBoxFilter
              value={sorting}
              options={sortingOptions}
              onChange={({ value }) => this.setState({ sorting: value })}
              title={formatMessage({ id: 'benchmarking.sortBy' })}
              classNameWrapper={classnames(styles.selectboxFilterWrapper, styles.sortingOptionsFilterWrapper)}
            />
            <div className={styles.benchmarkGroupFilterWrapper}>
              <SelectBoxFilter
                value={currentBenchmarkGroup}
                options={benchmarkGroupsOptions}
                onChange={this.handlerBenchmarkGroupOptionSelect}
                title={formatMessage({ id: 'benchmarking.benchmarkingGroup' })}
                optionsFooter={this.renderOptionsFooter(formatMessage, organizationSlug)}
              />
            </div>
          </div>

          <div className={classnames(styles.dashboardGroup, styles.dashboardGroupCyclesHarvest)}>
            <div className={styles.dashboardGroupHeaderWrapper}>
              <TextInfoTooltip
                text={formatMessage({ id: 'benchmarking.cropCyclesHarvest' })}
                tooltipText={formatMessage({ id: 'benchmarking.benchmarkTooltip' })}
                className={styles.dashboardGroupHeader}
              />
              <div className={styles.dashboardGroupFilters}>
                <SelectBoxFilter
                  value={currentViewBy}
                  options={viewByOptions}
                  onChange={this.handlerViewByOptionSelect}
                  title={formatMessage({ id: 'benchmarking.viewBy' })}
                />
                <SelectBoxFilter
                  value={currentAdditionalMetricId}
                  options={additionalMetricsOptions}
                  onChange={this.handlerAdditionalMetricsOptionSelect}
                  title={formatMessage({ id: 'benchmarking.additionalMetrics' })}
                  classNameWrapper={styles.additionalMetricsFilterWrapper}
                  rightPopupAlign
                />
              </div>
            </div>
            <div className={styles.avgHarvestGroup}>
              <div className={styles.avgHarvest}>
                {weeklyHarvest && (
                  <AvgHarvest
                    headerText={formatMessage({ id: 'benchmarking.avgWeeklyHarvest' })}
                    groupText={formatMessage({ id: 'benchmarking.groupAvg' })}
                    value={get(weeklyHarvest, 'amount')}
                    units={formatMessage({ id: `cunits.mini.${get(weeklyHarvest, 'units')}` })}
                    groupValue={get(weeklyGroupHarvest, 'amount')}
                    changeValue={get(weeklyChangeGroupHarvest, 'amount')}
                    changeUnits='%'
                  />
                )}
              </div>
              <div className={styles.avgHarvest}>
                {totalHarvest && (
                  <AvgHarvest
                    headerText={formatMessage({ id: 'benchmarking.avgTotal' })}
                    groupText={formatMessage({ id: 'benchmarking.groupAvg' })}
                    value={get(totalHarvest, 'amount')}
                    units={formatMessage({ id: `cunits.mini.${get(totalHarvest, 'units')}` })}
                    groupValue={get(totalGroupHarvest, 'amount')}
                    changeValue={get(totalChangeGroupHarvest, 'amount')}
                    changeUnits='%'
                  />
                )}
              </div>
            </div>

            <div className={styles.barChartContainer}>
              <SimpleBarChart
                items={preparedPoints}
                fixedHeight={300}
                toCompareItems={cyclesToCompare}
                onMouseOverCallback={this.handleMouseOverBar}
                onMouseOutCallback={this.handleMouseOutBar}
                onClickCallback={this.handleMouseClickBar}
                hoveredItem={clickedItem || hoveredBarItem}
                withCompareTips
                renderCustomTooltip={this.renderCustomTooltip}
                // isFetching={false}
              />
            </div>
            {isBenchmarkOverviewFetching && <DefaultCircleLoader />}
          </div>

          <div className={styles.dashboardGroup}>
            <div className={styles.dashboardGroupHeaderWrapper}>
              <div className={styles.dashboardGroupHeader}>
                {formatMessage({ id: 'benchmarking.growingDetails' })}
              </div>
              <div className={styles.dashboardGroupFilters}>
                <SelectBoxFilter
                  value={alignedBy}
                  options={alignedByOptions}
                  onChange={({ value }) => this.setState({ alignedBy: value })}
                  title={formatMessage({ id: 'benchmarking.timeline' })}
                />
              </div>
            </div>
            <BenchmarkTimeline
              data={products}
              alignedBy={alignedBy}
              toCompareItems={cyclesToCompare}
              hoveredItem={clickedItem || hoveredTimelineItem}
              withCompareButton
              isShowLocationName={currentBenchmarkGroup !== NOT_SELECTED_BENCHMARK_GROUP}
              onMouseOverCallback={this.handleMouseOverTimeline}
              onMouseOutCallback={this.handleMouseOutTimeline}
              onClickCallback={this.handleMouseClickBar}
            />
          </div>
        </div>
      </div>
    );
  }
}
