/* eslint-disable react/jsx-curly-brace-presence */

import React, { Component } from 'react';
import { withRouter } from 'react-router';

import styles from './General.module.css';


class General extends Component {
  render() {
    return (
      <article className={styles.main}>
        <h1>Algemene Voorwaarden</h1>

        <p>
          Voordat u de Pylot-applicatie gaat gebruiken, dient u kennis te nemen van het onderhavige privacybeleid en onze algemene voorwaarden te lezen en te aanvaarden.
        </p>

        <br />

        <p>
          De volgende voorwaarden zijn van toepassing op de SaaS-diensten die Pylot aanbiedt aan de klant met wie Pylot een Overeenkomst sluit (hierna: <strong>{"'de Klant'"}</strong>).
        </p>

        <br />

        <p>
          De Diensten worden geleverd door Pylot B.V., met haar statutaire zetel te Vlotlaan 710, 2681 TX Monster en ingeschreven bij de Kamer van Koophandel onder nummer 68947232 (hierna: <strong>{"'Pylot'"}</strong>).
        </p>

        <h2><strong>Artikel 1.</strong> Definities</h2>
        <p>De begrippen die in deze Algemene Voorwaarden met een hoofdletter zijn geschreven, hebben (in zowel het enkelvoud als in het meervoud) de hieronder vastgestelde betekenis:</p>
        <br />
        <p><strong>1.1 Account:</strong>&nbsp;de account die door Pylot voor het gebruik van de Software aan de Klant is verstrekt.</p>
        <p><strong>1.2 Overeenkomst:</strong>&nbsp;een Overeenkomst tussen Pylot en de Klant op grond waarvan Pylot de Diensten aan de Klant verleent en waarvan deze Algemene Voorwaarden integraal deel uitmaken.</p>
        <p><strong>1.3 Applicatie:</strong>&nbsp;de applicatie van Pylot op een website&nbsp;<a href='https://pylot.app'>https://pylot.app</a>.</p>
        <p><strong>1.4 Klant:</strong>&nbsp;de natuurlijke persoon of rechtspersoon, die handelt in de uitoefening van een beroep of bedrijf, met wie of waarmee Pylot een Overeenkomst heeft gesloten.</p>
        <p><strong>1.5 Eindgebruikers:</strong>&nbsp;de werknemers van de Klant die via de Klant toegang hebben gekregen tot de Software die Pylot aan de Klant via gebruikersaccounts heeft geleverd.</p>
        <p><strong>1.6 Algemene Voorwaarden:</strong>&nbsp;deze voorwaarden.</p>
        <p><strong>1.7 Schriftelijk:</strong>&nbsp;correspondentie op schrift die van een datum is voorzien en is ondertekend. Schriftelijk omvat, behalve berichten op papier als beschreven, ook e-mail- en faxberichten, voor zover de herkomst en de integriteit van bedoelde berichten voldoende is vastgesteld.</p>
        <p><strong>1.8 Intellectuele Eigendomsrechten:</strong>&nbsp;alle intellectuele eigendomsrechten en bijbehorende rechten, met inbegrip van, maar niet beperkt tot, auteursrechten, databaserechten, domeinnamen, handelsnaamrechten, merkrechten, ontwerprechten, naburige rechten, octrooirechten, alsmede rechten op knowhow.</p>
        <p><strong>1.9 Materialen:</strong>&nbsp;alle werken, zoals websites en applicaties/webapplicaties, bedrijfsidentiteiten, {"logo's"}, flyers, brochures, folders, belettering, reclame, marketing- en/of communicatieplannen, concepten, afbeeldingen, teksten, schetsen, documentatie, adviezen, verslagen en andere scheppingen van de geest, alsmede het voorbereidend materiaal daarvan en de (al dan niet gedecodeerde) bestanden of gegevensdragers waarop de materialen zijn opgeslagen.</p>
        <p><strong>1.10 Kantooruren:</strong>&nbsp;de uren tussen 9:00 en 17:00 Nederlandse tijd, van maandag tot en met vrijdag, uitgezonderd Nederlandse officiële feestdagen.</p>
        <p><strong>1.11 Partij/Partijen:</strong>&nbsp;de partijen bij de Overeenkomst, Pylot en de Klant, individueel of gezamenlijk.</p>
        <p><strong>1.12 Diensten:</strong>&nbsp;alle diensten die Pylot uit hoofde van de Overeenkomst aan de Klant levert, met inbegrip van, maar niet beperkt tot: (i) de levering en installatie van Software, (ii) de levering van op cloud gebaseerde Software-as-a-Service via de Applicatie, (iii) de configuratie van gebruikersaccounts, en (iv) het onderhoud van de Software (as-a-Service).</p>
        <p><strong>1.13 Software:</strong>&nbsp;alle software (as-a-Service) die door Pylot uit hoofde van de Overeenkomst op het platform wordt aangeboden (Applicatie van Pylot, https-domein en app van Pylot, installaties die in de kas worden geïnstalleerd met het oog op lokale systeemintegratie, API).</p>
        <p><strong>1.14 Testperiode:</strong>&nbsp;een specifieke periode waarin de Applicatie van Pylot door de Klant wordt getest.</p>

        <h2><strong>Artikel 2.</strong> Totstandkoming en nakoming van de Overeenkomst</h2>
        <p><strong>2.1</strong>&nbsp;Alle aanbiedingen van Pylot houden geen verplichting voor Pylot in en gelden voor een periode van dertig dagen na de datum van de offerte of aanbieding. Pylot is niet verplicht om na afloop van deze periode een aanvaarding te accepteren, maar indien Pylot dat toch doet, wordt de offerte of aanbieding geacht te zijn aanvaard.</p>
        <p><strong>2.2</strong>&nbsp;Indien de aanvaarding door de Klant (zelfs op een klein punt) afwijkt van de aanbieding, is Pylot er niet aan gebonden. Er komt in dat geval geen Overeenkomst volgens deze afwijkende aanvaarding tot stand, tenzij Pylot uitdrukkelijk Schriftelijk anders aangeeft.</p>
        <p><strong>2.3</strong>&nbsp;De Overeenkomst komt tot stand bij de aanvaarding of bevestiging van de aanbieding, zoals hierboven is weergegeven.</p>
        <p><strong>2.4</strong>&nbsp;Pylot levert de Software naar haar beste vermogen en zij betracht hierbij de nodige zorgvuldigheid en vakbekwaamheid.</p>

        <h2><strong>Artikel 3.</strong> Toepasselijkheid, looptijd en beëindiging</h2>
        <p><strong>3.1</strong>&nbsp;De Algemene Voorwaarden zijn van toepassing op alle aanbiedingen van Pylot, de aanvaarding daarvan door de Klant en de aldus tussen de Partijen gesloten Overeenkomst en de uitvoering van de Diensten door Pylot.</p>
        <p><strong>3.2</strong>&nbsp;De toepasselijkheid van voorwaarden of andere bepalingen van de Klant worden uitdrukkelijk uitgesloten.</p>
        <p><strong>3.3</strong>&nbsp;De Overeenkomst vangt aan op de door de Partijen overeengekomen datum en blijft vervolgens gedurende de Testperiode van kracht.</p>
        <p><strong>3.4</strong>&nbsp;Na afloop van de Testperiode wordt de Overeenkomst met een jaar verlengd, indien en voor zover de Partijen dat overeenkomen. Na afloop van dit jaar wordt de Overeenkomst met nog een jaar verlengd, indien en voor zover de Partijen dat overeenkomen. Tenzij de Overeenkomst voor onbepaalde tijd wordt gesloten, kan deze niet voortijdig worden beëindigd, behalve in geval van de in deze Algemene Voorwaarden genoemde gebeurtenissen.</p>
        <p><strong>3.5</strong>&nbsp;Pylot kan de Overeenkomst zonder ingebrekestelling met onmiddellijke ingang Schriftelijk opschorten of opzeggen, indien de Klant met betrekking tot een materiële verplichting krachtens de Overeenkomst in gebreke blijft.</p>
        <p><strong>3.6</strong>&nbsp;Pylot kan de Overeenkomst zonder ingebrekestelling met onmiddellijke ingang Schriftelijk opschorten of opzeggen, indien de Klant failliet is verklaard, de Klant surséance van betaling aanvraagt of wordt verleend, de activiteiten van de Klant zijn stopgezet of de onderneming van de Klant is opgeheven.</p>
        <p><strong>3.7</strong>&nbsp;Indien Pylot de uitvoering van de Overeenkomst opschort, behoudt Pylot al haar rechten en aanspraken uit hoofde van de Overeenkomst en de toepasselijke wet- en regelgeving.</p>
        <p><strong>3.8</strong>&nbsp;Ingeval de Overeenkomst is beëindigd, worden de aan Pylot door de Klant verschuldigde bedragen onmiddellijk opeisbaar (ongeacht de reden van de beëindiging).</p>
        <p><strong>3.9</strong>&nbsp;Ingeval de Overeenkomst is ontbonden, blijft de Klant de reeds in rekening gebrachte facturen verschuldigd en er ontstaat geen verplichting om deze terug te draaien.</p>
        <p><strong>3.10</strong>&nbsp;De Klant kan alleen het deel van de Overeenkomst annuleren dat nog niet door Pylot is nagekomen.</p>

        <h2><strong>Artikel 4.</strong> Levering van Diensten</h2>
        <p><strong>4.1</strong>&nbsp;Bij de totstandkoming van de Overeenkomst tracht Pylot ervoor te zorgen dat de Diensten binnen een redelijke termijn conform de Overeenkomst worden geleverd.</p>
        <p><strong>4.2</strong>&nbsp;De Klant biedt Pylot alle benodigde en wenselijke ondersteuning voor de juiste en tijdige levering van de Diensten. De Klant dient in elk geval:</p>
        <ol type='a'>
          <li>alle gegevens en overige informatie te verstrekken die volgens Pylot nodig zijn of waarvan van de Klant redelijkerwijs moet begrijpen dat die voor de levering van de Diensten van essentieel belang zijn;</li>
          <li>tijdelijk toegang te verlenen aan de afdeling support van Pylot om de integratie tot stand te brengen (Team Viewer);</li>
          <li>ondersteuning te bieden bij de installatie van integratiemiddelen op lokale machines.</li>
        </ol>
        <p><strong>4.3</strong>&nbsp;De Klant dient te zorgen voor de juiste gegevens en informatie (via de Software). De resultaten en besluiten die in de Software worden getoond, zijn gebaseerd op de door de Klant verstrekte gegevens. Pylot aanvaardt in dit verband geen enkele aansprakelijkheid.</p>
        <p><strong>4.4</strong>&nbsp;Pylot kan voor de nakoming van de Overeenkomst derden inschakelen. Eventuele bijkomende kosten komen uitsluitend voor rekening van de Klant indien zulks vooraf is overeengekomen.</p>
        <p><strong>4.5</strong>&nbsp;Ingeval voor de nakoming van de Overeenkomst werknemers van Pylot werkzaamheden op het kantoor van de Klant of op een andere door de Klant aangewezen locatie uitvoeren, verleent de Klant kosteloos alle ondersteuning en faciliteiten die nodig zijn voor de uitvoering van de werkzaamheden.</p>

        <h2><strong>Artikel 5.</strong> Gebruik van de Software</h2>
        <p><strong>5.1</strong>&nbsp;De Klant heeft een Account nodig om de Software te gebruiken. Pylot streeft ernaar binnen vier weken na de totstandkoming van de Overeenkomst te zorgen voor de initiële configuratie van de Account voor de Klant. De Klant kan met de organisatie-Account extra gebruikersaccounts voor de Eindgebruikers aanvragen.</p>
        <p><strong>5.2</strong>&nbsp;De Klant dient de Account(s) te beveiligen met een gebruikersnaam en wachtwoord. Met name de wachtwoorden moeten strikt geheim worden gehouden. Pylot mag ervan uitgaan dat alle werkzaamheden die via de Account(s) van de Klant worden uitgevoerd, nadat met de gebruikersnaam en het wachtwoord is ingelogd, onder toezicht en controle van de Klant plaatsvinden.</p>
        <p><strong>5.3</strong>&nbsp;Pylot streeft ernaar de Account van de Klant kosteloos aan te sluiten.  Pylot behoudt zich het recht voor om een vergoeding in rekening te brengen voor de aansluiting van een nieuw apparaat of een aansluiting op een ander systeem, indien de aansluiting niet tot stand kan worden gebracht zonder producten of diensten van derden af te nemen. In dat geval stelt Pylot de Klant daar vooraf van op de hoogte.</p>
        <p><strong>5.4</strong>&nbsp;Indien en voor zover de Klant Pylot toegang tot het systeem van de Klant verleent, bijvoorbeeld via Team Viewer, is de Klant verantwoordelijk voor het toezicht op de verbindingen en het opheffen van de toegang voor Pylot. De Klant is tevens verantwoordelijk voor (de controle op) de juiste werking van de machines van de Klant. Pylot aanvaardt in dit verband geen enkele aansprakelijkheid.</p>

        <h2><strong>Artikel 6.</strong> Gebruiksregels</h2>
        <p><strong>6.1</strong>&nbsp;Het is verboden de Software te gebruiken op een wijze die in strijd is met deze voorwaarden of toepasselijke wet- en regelgeving. Daarnaast is het gebruik van de Software op een manier die hinder, verlies en/of schade voor Pylot of derden kan meebrengen, uitdrukkelijk verboden.</p>
        <p><strong>6.2</strong>&nbsp;Indien Pylot vaststelt dat de Klant of een Eindgebruiker deze voorwaarden of de wet heeft geschonden, of in dit verband een klacht ontvangt, kan Pylot maatregelen nemen om de schending te beëindigen. Pylot blokkeert dan de toegang tot de desbetreffende informatie.</p>
        <p><strong>6.3</strong>&nbsp;Indien volgens Pylot de werking van de computersystemen of het computernetwerk van Pylot of derden en/of de dienstverlening via het internet wordt belemmerd, verstoord of anderszins risico loopt, met name als gevolg van het verzenden van grote hoeveelheden e-mailberichten of overige gegevens, gelekte persoonsgegevens of virusactiviteiten, Trojaanse paarden en soortgelijke software, is Pylot gerechtigd om de maatregelen te nemen die zij redelijkerwijs noodzakelijk acht om een dergelijk risico af te wenden of te voorkomen. Deze maatregelen omvatten, onder meer, opschorting van de Software en beëindiging van de Overeenkomst.</p>
        <p><strong>6.4</strong>&nbsp;Pylot is te allen tijde gerechtigd om strafbare feiten die worden ontdekt, te melden en zij zal naar behoren afgegeven besluiten of bevelen opvolgen. Daarnaast is Pylot gerechtigd om de naam, het adres, het IP-adres en overige gegevens waarmee de Klant / een Eindgebruiker van de Software wordt geïdentificeerd, te verstrekken aan een derde die heeft geklaagd dat de Klant / een Eindgebruikers van de Software zijn rechten of deze voorwaarden heeft geschonden, op voorwaarde dat;</p>
        <ol type='a'>
          <li>het voldoende aannemelijk is dat de informatie op zich onrechtmatig en schadelijk is voor de derde;</li>
          <li>de derde een reëel belang heeft bij het verkrijgen van de gegevens;</li>
          <li>het aannemelijk is dat er in het specifieke geval geen ander middel is om de gegevens te verkrijgen dat minder ingrijpend is; en</li>
          <li>bij het wegen van de betrokken belangen blijkt dat het belang van de derde voorrang moet krijgen.</li>
        </ol>
        <p><strong>6.5</strong>&nbsp;De Klant dient alle redelijke voorschriften van Pylot met betrekking tot het gebruik van de Software op te volgen.</p>
        <p><strong>6.6</strong>&nbsp;Pylot kan verliezen en/of schade die zij heeft opgelopen als gevolg van schendingen van de regels van dit artikel, van de Klant terugvorderen. De Klant vrijwaart Pylot tegen alle vorderingen van derden met betrekking tot verlies en/of schade dat/die voortvloeit uit een schending van de regels van dit artikel.</p>

        <h2><strong>Artikel 7.</strong> Wijzigingen en verbeteringen</h2>
        <p><strong>7.1</strong>&nbsp;Pylot kan de Software wijzigen om fouten te herstellen, nieuwe functionaliteiten toe te voegen of de prestaties te verbeteren. Hoewel de Partijen vooraf in overleg kunnen treden over dergelijke wijzigingen en de Klant suggesties mag doen, neem Pylot de uiteindelijke beslissing of de wijziging al dan niet wordt doorgevoerd.</p>
        <p><strong>7.2</strong>&nbsp;Pylot streeft ernaar fouten in de Software te herstellen, maar zij is in dit verband ook afhankelijk van haar leveranciers. Pylot is gerechtigd om bepaalde updates of upgrades van leveranciers niet te installeren, indien de installatie van die updates of upgrades volgens haar de werking van de Software niet ten goede komt.</p>
        <p><strong>7.3</strong>&nbsp;Indien het invoeren van wijzigingen of verbeteringen kan leiden tot beperkte beschikbaarheid van de Software, wordt zulks zoveel mogelijk buiten Kantooruren uitgevoerd. Spoedonderhoud kan op elk moment worden uitgevoerd en wordt niet vooraf gemeld.</p>

        <h2><strong>Artikel 8.</strong> Ondersteuning</h2>
        <p><strong>8.1</strong>&nbsp;Pylot biedt de Eindgebruikers en de Klant redelijke ondersteuning bij vragen over het gebruik en beheer van de Software, alsmede bij technische vragen over de Software.</p>
        <p><strong>8.2</strong>&nbsp;De in artikel 8.1 bedoelde ondersteuning loopt via een helpdesk die tijdens Kantooruren bereikbaar is.</p>
        <p><strong>8.3</strong>&nbsp;Pylot streeft ernaar bij de helpdesk ingediende verzoeken binnen een redelijke termijn in behandeling te nemen. De tijd die nodig is om een probleem op te lossen, wisselt per geval.</p>

        <h2><strong>Artikel 9.</strong> Beschikbaarheid</h2>
        <p><strong>9.1</strong>&nbsp;Pylot streeft ernaar ervoor te zorgen dat de Software voor zover mogelijk online blijft, maar zij kan niet garanderen dat deze ononderbroken beschikbaar is.</p>
        <p><strong>9.2</strong>&nbsp;Indien er volgens Pylot een risico bestaat voor de werking van de computersystemen of het computernetwerk van Pylot of derden, bijvoorbeeld bij een DDoS-aanval of malware, is Pylot gerechtigd om de maatregelen te nemen die zij redelijkerwijs noodzakelijk acht om dat risico af te wenden en verlies en/of schade te voorkomen. Dit kan verminderde beschikbaarheid tot gevolg hebben.</p>
        <p><strong>9.3</strong>&nbsp;Pylot is niet verplicht om back-ups te maken, tenzij dit in een dienstenniveau-overeenkomst is overeengekomen.</p>

        <h2><strong>Artikel 10.</strong> Beveiliging en privacy</h2>
        <p><strong>10.1</strong>&nbsp;Pylot zal naar haar beste vermogen de Software beschermen tegen verkeerd gebruik, misbruik en onbevoegde toegang tot de gegevens van de Klant.</p>
        <p><strong>10.2</strong>&nbsp;Bij de nakoming van de Overeenkomst is mogelijk sprake van verwerking van persoonsgegevens door Pylot namens de Klant, waarbij de Klant als de verwerkingsverantwoordelijke wordt beschouwd en Pylot als de gegevensverwerker. Indien er persoonsgegevens worden verwerkt, sluiten de Partijen een gegevensverwerkersovereenkomst waarin afspraken worden opgenomen over de verwerking en beveiliging van bedoelde persoonsgegevens. De hierboven genoemde begrippen hebben de betekenis die daaraan in de toepasselijke privacywetgeving is gegeven.</p>
        <p><strong>10.3</strong>&nbsp;De Klant garandeert dat het invoeren of uploaden van gegevens of persoonsgegevens naar Pylot rechtmatig is en dat de verwerking van bedoelde gegevens in overeenstemming met de Overeenkomst niet in strijd is met toepasselijke privacywet- en regelgeving.</p>

        <h2><strong>Artikel 11.</strong> Meerwerk</h2>
        <p><strong>11.1 </strong>&nbsp;Indien de Klant aanvullend(e) werk, Software of Diensten aanvraagt die buiten de Overeenkomst vallen, treden de Partijen over dit verzoek met elkaar in overleg en zal Pylot een aanvullende offerte opstellen. Pylot voert geen meerwerk uit totdat de Klant de offerte heeft aanvaard.</p>

        <h2><strong>Artikel 12.</strong> Betaling</h2>
        <p><strong>12.1</strong>&nbsp;Tijdens de Testperiode wordt de Klant niets in rekening gebracht. Na de Testperiode zendt Pylot een factuur voor alle verschuldigde bedragen en zij kan vooraf elektronisch een factuur sturen. Bezwaren van de Klant tegen (het bedrag op) een factuur schorten de betalingsverplichting niet op.</p>
        <p><strong>12.2</strong>&nbsp;Alle facturen dienen binnen 14 dagen te worden betaald, tenzij op de factuur een andere betaaltermijn is vermeld of er Schriftelijk een andere termijn is afgesproken.</p>
        <p><strong>12.3</strong>&nbsp;Indien een factuur niet binnen de betaaltermijn is voldaan, is de Klant in verzuim, zonder dat een ingebrekestelling is vereist. In dat geval kan Pylot de levering van de Software, of een deel daarvan, opschorten. Pylot is niet aansprakelijk voor daaruit voor de Klant voortvloeiend(e) verlies en/of schade.</p>
        <p><strong>12.4</strong>&nbsp;Alle aan Pylot verschuldigde bedragen zijn onmiddellijk opeisbaar ingeval de Klant failliet is verklaard, de Klant surséance van betaling aanvraagt of is verleend, de activiteiten van de Klant zijn stopgezet of de onderneming van de Klant is opgeheven.</p>
        <p><strong>12.5</strong>&nbsp;Alle door Pylot vermelde prijzen zijn in euro en exclusief btw en overige overheidsheffingen.</p>
        <p><strong>12.6</strong>&nbsp;Indien een vergoeding is gebaseerd op door de Klant verstrekte informatie en bedoelde informatie onjuist blijkt te zijn, is Pylot gerechtigd om de vergoeding overeenkomstig aan te passen, zelfs nadat de Overeenkomst reeds is gesloten.</p>
        <p><strong>12.7</strong>&nbsp;De vergoedingen die geregeld dienen te worden betaald, kunnen jaarlijks worden aangepast aan de hand van de consumentenprijsindex of een andere index of een door de Partijen overeengekomen norm. De Klant is niet gerechtigd om de Overeenkomst te beëindiging vanwege bedoelde aanpassing.</p>
        <p><strong>12.8</strong>&nbsp;Indien de Klant de verschuldigde bedragen niet binnen de overeengekomen termijn betaalt, is de Klant de wettelijke handelsrente verschuldigd, als bedoeld in artikel 6:119a van het Burgerlijk Wetboek, over het verschuldigde bedrag, zonder dat daartoe een ingebrekestelling is vereist.</p>
        <p><strong>12.9</strong>&nbsp;Indien na ontvangst van een aanmaning of ingebrekestelling de Klant het verschuldigde bedrag niet betaalt, kan Pylot de vordering daarvan uit handen geven. In dat geval is de Klant naast het op dat moment totale verschuldigde bedrag ook alle gerechtelijke en buitengerechtelijke kosten verschuldigd, waaronder alle kosten en vergoedingen voor advocaten en externe deskundigen.</p>

        <h2><strong>Artikel 13.</strong> Aansprakelijkheid en overmacht</h2>
        <p><strong>13.1</strong>&nbsp;Omdat de Klant voor de levering van de Software tijdens de Testperiode geen vergoeding in rekening wordt gebracht, is Pylot tijdens de Testperiode niet aansprakelijk voor verlies en/of schade voortvloeiende uit een tekortkoming in de nakoming van de Overeenkomst, een onrechtmatige daad of anderszins, behalve ingeval het verlies en/of de schade te wijten is aan (1) opzettelijk wangedrag of bewuste roekeloosheid door het management van Pylot, of (2) overlijden of lichamelijk letsel.</p>
        <p><strong>13.2</strong>&nbsp;Na afloop van de Testperiode is de aansprakelijkheid van Pylot voor verlies en/of schade voortvloeiende uit een tekortkoming in de nakoming van de Overeenkomst, een onrechtmatige daad of anderszins, beperkt tot het door de verzekeraar aan Pylot uitbetaalde bedrag. Pylot spant zich tot het uiterste in om ervoor te zorgen dat de verzekeraar tot uitbetaling overgaat. Indien er geen uitbetaling is, is de aansprakelijkheid voor iedere gebeurtenis (waarbij een reeks opeenvolgende gebeurtenissen als één gebeurtenis wordt beschouwd) beperkt tot het bedrag (exclusief btw) dat de Klant uit hoofde van de Overeenkomst gedurende de periode van zes maanden voorafgaand aan het ontstaan van het verlies en/of de schade heeft betaald.</p>
        <p><strong>13.3</strong>&nbsp;Pylot is uitsluitend aansprakelijk voor direct(e) verlies en/of schade voortvloeiende uit een verwijtbare tekortkoming in de nakoming van de Overeenkomst.</p>
        <p><strong>13.4</strong>&nbsp;De in de Overeenkomst vastgestelde beperking of uitsluiting van aansprakelijkheid is niet van toepassing ingeval het verlies en/of de schade te wijten is aan (1) opzettelijk wangedrag of bewuste roekeloosheid door het (bedrijfs-)management van Pylot of (2) overlijden of lichamelijk letsel</p>
        <p><strong>13.5</strong>&nbsp;Een recht op schadevergoeding bestaat te allen tijde alleen indien de Klant Pylot Schriftelijk binnen 30 dagen na ontdekking daarvan in kennis stelt van het verlies en/of de schade.</p>
        <p><strong>13.6</strong>&nbsp;Pylot kan niet worden verplicht om een verplichting uit hoofde van de Overeenkomst uit te voeren indien de uitvoering wegens overmacht wordt verhinderd. Pylot is niet aansprakelijk voor verlies en/of schade wegens overmacht.</p>
        <p><strong>13.7</strong>&nbsp;Er is in ieder geval sprake van overmacht bij stroomuitval, internetstoringen, defecten aan de telecommunicatie-infrastructuur, netwerkaanvallen (waaronder (D)DoS-aanvallen), malware-aanvallen of andere schadelijke software, burgerlijk oproer, natuurrampen, terreur, mobilisering, oorlog, invoer- en uitvoerbelemmeringen, stakingen, bevoorradingsstremmingen, brand, overstromingen en omstandigheden waardoor Pylot door haar leveranciers niet in staat wordt gesteld of wordt gehinderd om haar verplichtingen na te komen ongeacht de reden.</p>
        <p><strong>13.8</strong>&nbsp;Indien een overmachtssituatie langer duurt dan 90 dagen, kunnen beide Partijen de Overeenkomst met onmiddellijke ingang beëindigen door middel van een Schriftelijke opzegging. De Diensten die in een dergelijk geval vóór en tijdens de overmachtssituatie door Pylot zijn geleverd, worden naar evenredigheid betaald.</p>
        <p><strong>13.9</strong>&nbsp;De Partijen stemmen ermee in dat artikel 6:271 van het Burgerlijk Wetboek en het gevolg van het bepaalde daarin in de volgende artikelen worden uitgesloten.</p>
        <p><strong>13.10</strong>&nbsp;Een recht op schadevergoeding bestaat te allen tijde alleen indien de Klant Pylot Schriftelijk binnen 30 dagen na het ontstaan van het verlies en/of de schade daarvan in kennis stelt.</p>

        <h2><strong>Artikel 14.</strong> Intellectueel eigendom</h2>
        <p><strong>14.1</strong>&nbsp;Alle Intellectuele Eigendomsrechten op de Software en Materialen die door Pylot uit hoofde van de Overeenkomst zijn geleverd, berusten bij Pylot of haar licentiegevers.</p>
        <p><strong>14.2</strong>&nbsp;De Klant krijgt van Pylot de niet-exclusieve, niet-overdraagbare en niet in sublicentie te geven rechten op het gebruik van de Software en Materialen voor zover die voortvloeien uit de Overeenkomst of die anderszins Schriftelijk zijn verleend. Het recht op gebruik geldt gedurende de looptijd van de Overeenkomst.</p>
        <p><strong>14.3</strong>&nbsp;De Klant is niet gerechtigd om wijzigingen aan de Software aan te brengen, behalve ingeval zulks wettelijk verplicht is.</p>
        <p><strong>14.4</strong>&nbsp;Pylot kan ter bescherming van de Software technische of andere maatregelen treffen. Indien Pylot dergelijke beveiligingsmaatregelen heeft getroffen, mag de Klant die beveiliging niet verwijderen of omzeilen.</p>
        <p><strong>14.5</strong>&nbsp;De Klant mag een melding of aanduiding van Intellectuele Eigendomsrechten niet van de Software verwijderen of wijzigen. De Klant mag ook niet meldingen of aanduidingen over de vertrouwelijke aard daarvan verwijderen van de Software.</p>

        <h2><strong>Artikel 15.</strong> Wijziging van de voorwaarden</h2>
        <p><strong>15.1</strong>&nbsp;Pylot kan deze Algemene Voorwaarden op elk moment wijzigen.</p>
        <p><strong>15.2 </strong>&nbsp;Pylot streeft ernaar de wijzigingen of toevoegingen, ten minste dertig dagen voordat ze van kracht worden, per e-mail of per kennisgeving aan de Klant bekend te maken zodra er een verbinding met Pylot tot stand komt, zodat de Klant daar kennis van kan nemen.</p>
        <p><strong>15.3</strong>&nbsp;Indien de Klant een wijziging of toevoeging niet wilt aanvaarden, kan de Klant de Overeenkomst door opzegging beëindigen tegen de datum waarop die van kracht wordt. Het gebruik van de Software na de ingangsdatum wordt beschouwd als aanvaarding van de gewijzigde of aangevulde voorwaarden.</p>
        <p><strong>15.4</strong>&nbsp;Beide Partijen verlenen alle medewerking met betrekking tot wijzigingen of aanvullingen van de Algemene Voorwaarden die vereist zijn met het oog op nieuwe of gewijzigde wetgeving. Dergelijke wijzigingen kunnen zonder kennisgeving van Pylot worden ingevoerd, maar Pylot streeft ernaar deze wijzigingen ook aan te kondigen, zonder dat de Klant de Overeenkomst door opzegging kan beëindigen.</p>
        <p><strong>15.5</strong>&nbsp;De procedure in dit artikel is niet van toepassing op kleine wijzigingen. Dergelijke wijzigingen kunnen zonder kennisgeving van Pylot worden ingevoerd, zonder dat de Klant de Overeenkomst door opzegging kan beëindigen.</p>

        <h2><strong>Artikel 16.</strong> Geheimhouding</h2>
        <p><strong>16.1</strong>&nbsp;Alle informatie die de Partijen voor, tijdens of na de nakoming van deze Overeenkomst aan elkaar of bij de Diensten verstrekken, wordt door de Partijen vertrouwelijk behandeld (hierna: {"'Vertrouwelijke Informatie'"}). De Partijen leggen deze verplichting ook op aan hun werknemers alsmede aan derden die door hen zijn ingeschakeld voor de nakoming van de Overeenkomst.</p>
        <p><strong>16.2</strong>&nbsp;Met betrekking tot de Vertrouwelijke Informatie, zullen de Partijen:</p>
        <ol type='a'>
          <li>naar behoren rekening houden met alle redelijke maatregelen voor het bewaren of opslaan van de Vertrouwelijke Informatie, waarbij zij ten minste hetzelfde beveiligingsniveau bieden als voor hun eigen Vertrouwelijke Informatie;</li>
          <li>de Vertrouwelijke Informatie niet gebruiken voor een ander doeleinde dan het overeengekomen doeleinde (de nakoming van de Overeenkomst);</li>
          <li>de Vertrouwelijke Informatie niet langer bewaren dan redelijkerwijs nodig is voor de nakoming van de overeengekomen verplichtingen en zij zullen deze gegevens, waaronder eventuele gemaakte kopieën, weer aan de andere Partij geven zodra bedoelde verplichtingen volledig zijn nagekomen, of zij zullen, nadat een kopie van de Vertrouwelijke Informatie aan de andere Partij is overhandigd en die Partij de juiste ontvangst daarvan Schriftelijk heeft bevestigd, bedoelde gegevens vernietigen.</li>
        </ol>
        <p><strong>16.3</strong>&nbsp;Indien en voor zover de Partijen zulks uitdrukkelijk zijn overeengekomen, kan Pylot sommige Vertrouwelijke Informatie met andere klanten van Pylot delen. De Klant kan naar zijn eigen oordeel besluiten welke Vertrouwelijke Informatie Pylot mag delen en met wie.</p>

        <h2><strong>Artikel 17.</strong> Verwijdering</h2>
        <p><strong>17.1</strong>&nbsp;Pylot bewaart alle data en overige gegevens van de Klant gedurende een periode van dertig dagen nadat de Overeenkomst is beëindigd. Op voorwaarde dat de Klant al zijn betalingsverplichtingen en overige verplichtingen van de Overeenkomst is nagekomen, streeft Pylot ernaar een kopie van deze data en overige gegevens te verstrekken indien de Klant daar om vraagt. Hiervoor brengt Pylot kosten in rekening.</p>
        <p><strong>17.2</strong>&nbsp;De kopie wordt in een door Pylot gekozen standaard bestandsformaat verstrekt. De redelijke kosten voor het kopiëren van de bestanden en gegevens overeenkomstig dit artikel komen voor rekening van de Klant.</p>

        <h2><strong>Artikel 18.</strong> Slotbepalingen</h2>
        <p><strong>18.1</strong>&nbsp;Op de Overeenkomst is uitsluitend het Nederlandse recht van toepassing.</p>
        <p><strong>18.2</strong>&nbsp;Voor zover de regels van dwingend recht niet anders voorschrijven, worden eventuele geschillen tussen de Partijen in verband met de Overeenkomst voorgelegd aan de bevoegde rechter in Nederland van het arrondissement waar de statutaire zetel van Pylot is gevestigd.</p>
        <p><strong>18.3</strong>&nbsp;Pylot kan haar rechten en plichten die voortvloeien uit de Overeenkomst, overdragen aan een derde die Pylot of haar bedrijfsactiviteiten overneemt.</p>
        <p><strong>18.4</strong>&nbsp;De Klant dient Pylot onmiddellijk in kennis te stellen van eventuele wijzigingen van de naam, het (post-)adres, e-mailadres, telefoonnummer en andere informatie en gegevens die van belang zijn voor de nakoming van de Overeenkomst.</p>
        <p><strong>18.5</strong>&nbsp;De logbestanden en mededelingen die Pylot heeft bewaard, worden geacht juist te zijn, behoudens tegenbewijs door de Klant.</p>
        <p><strong>18.6</strong>&nbsp;Indien een bepaling van de Overeenkomst nietig of ongeldig wordt verklaard, laat zulks de geldigheid van de Overeenkomst in haar geheel onverlet. In dat geval vervangen de Partijen die bepaling door een of meer nieuwe bepalingen waarin de strekking van de oorspronkelijke bepaling zoveel mogelijk wordt weergegeven voor zover dat binnen de wet mogelijk is.</p>
      </article>
    );
  }
}

export default withRouter(General);
