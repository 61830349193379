import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { API_DATE_FORMAT } from 'helpers/defaultDates';
import moment from 'moment';

import {
  getLogin,
  getLocation,
  getCompartment,
  getPlantingCycle,
  getCompartments,
} from 'store/company/actions';

import { getOrganizationSlug } from 'store/company/selectors';
import { trackPageViewPlantingCycle } from 'store/newCrops/actions';

import {
  getCropsMetricsStart,
  getCropsObservationsStart,
  createCropsObservationsStart,
  trackClickMeasurementsTableDay,
} from 'store/crops/actions';

import { trackPageView } from 'store/analytics/actions';
import { setToggleCropCreateDialog } from 'store/dialogs';
import setIntl from 'store/localization';

import { showNotificationWithTimeout } from 'store/notificationCenter/actions';

import {
  requestMeasurementsByPeriod,
  requestMeasurementsAdditionalMetrics,
  saveMeasurementsAdditionalMetrics,
  saveMeasurementsGraphMetrics,
  requestMeasurementsExport
} from 'store/measurements/actions';

import {
  getPeriodFromRoute,
  getIsMeasurementsFetching,
  getIsMeasurementsSuccess,
  getMeasurements,
  getPlantingCycleIdFromUrl,
  getMeasurementsAdditionalMetrics
} from 'store/measurements/selectors';

import { getNameByLocal } from 'helpers/getNameByLocal';

import Crops from '../components/Crops';

const getDescriptorSelector = createSelector(
  [getMeasurements],
  measurements => measurements?.descriptor
);

const getGraphMetricsAndPlants = createSelector(
  [getMeasurements],
  measurements => measurements?.graphMetricsAndPlants
);

const getMetricCategories = createSelector(
  [getMeasurements],
  measurements => measurements?.metricCategories
);

const getDefaultMeasurementsData = createSelector(
  [getDescriptorSelector],
  descriptor => descriptor?.periods.map(period => [
    moment.utc(period?.startDate, API_DATE_FORMAT).valueOf() / 60000,
    null
  ])
);

const getIntl = (state, props) => {
  const { intl } = props;
  return intl;
};

const getTableRowsSelector = createSelector(
  [getMeasurements, getDefaultMeasurementsData, getIntl],
  (measurements, defaultData, intl) => {
    const { locale } = intl;
    if (!measurements) {
      return [];
    }
    const {
      metrics,
      descriptor,
      data,
      plants,
      additionalMetrics
    } = measurements;
    const allMetrics = [...metrics, ...additionalMetrics.map(item => ({ ...item, isAdditionalMetric: true }))];
    return allMetrics.map((metric) => {
      const samplesData = data.map(item => ({
        date: item.date,
        samples: item?.samples?.filter(sample => Object.prototype.hasOwnProperty.call(sample?.observations, metric.id))
      })).filter(item => item?.samples?.length > 0);
      const defaultPlants = plants.map(plant => ({
        name: plant?.name,
        graphKey: `row_${metric?.id}_${plant.discriminator}_key`,
        parentName: getNameByLocal(metric, locale),
        groupKey: metric?.category,
        displayScale: metric?.displayScale,
        extra: plant.extra,
        cunit: metric?.cunit,
        children: [],
        data: descriptor?.periods.map((period) => {
          const periodData = samplesData.find(item => item.date === period?.startDate);
          const periodSample = periodData?.samples?.find(sample => sample.discriminator === plant.discriminator);
          const value = periodSample && Object.prototype.hasOwnProperty.call(periodSample?.observations, metric.id) ?
              periodSample?.observations[metric.id] : null;
          return [
            moment.utc(period?.startDate, API_DATE_FORMAT).valueOf() / 60000,
            value
          ];
        }),
      }));
      const isAdditionalMetric = Boolean(metric.isAdditionalMetric);
      return {
        name: getNameByLocal(metric, locale),
        graphKey: `row_${metric.id}_key`,
        groupKey: metric?.category,
        calculated: metric.calculated,
        isAdditionalMetric,
        displayScale: metric?.displayScale,
        cunit: metric?.cunit,
        description: metric?.description,
        data: descriptor?.periods.map((period) => {
          const periodData = data.find(item => item.date === period?.startDate);
          let value = periodData && Object.prototype.hasOwnProperty.call(periodData?.observations, metric.id) ?
            periodData?.observations[metric.id] : null;
          if (!value) {
            value = periodData && Object.prototype.hasOwnProperty.call(periodData?.additionalMeasurements, metric.id) ?
              periodData?.additionalMeasurements[metric.id] : null;
          }
          return [
            moment.utc(period?.startDate, API_DATE_FORMAT).valueOf() / 60000,
            value
          ];
        }),
        children: isAdditionalMetric ? [] : defaultPlants,
      };
    });
  }
);

const mapDispatchToProps = {
  getCropsMetricsStart,
  getCropsObservationsStart,
  createCropsObservationsStart,
  trackPageView,
  getLogin,
  getLocation,
  getCompartment,
  getPlantingCycle,
  setToggleCropCreateDialog,
  getCompartments,
  trackPageViewPlantingCycle,
  setIntl,
  trackClickMeasurementsTableDay,
  requestMeasurementsByPeriod,
  requestMeasurementsAdditionalMetrics,
  showNotificationWithTimeout,
  saveMeasurementsAdditionalMetrics,
  saveMeasurementsGraphMetrics,
  requestMeasurementsExport
};

const mapStateToProps = (state, props) => {
  const {
    company,
    dialogs,
    crops,
    intl
  } = state;
  return {
    ...props,
    ...crops,
    ...company,
    ...intl,
    ...dialogs,
    isMeasurementsByPeriodFetching: getIsMeasurementsFetching(state),
    isMeasurementsByPeriodSuccess: getIsMeasurementsSuccess(state),
    tableRows: getTableRowsSelector(state, props),
    descriptor: getDescriptorSelector(state),
    period: getPeriodFromRoute(state),
    plantingCycleId: getPlantingCycleIdFromUrl(state),
    additionalMetrics: getMeasurementsAdditionalMetrics(state),
    organizationSlug: getOrganizationSlug(state, props),
    metricCategories: getMetricCategories(state),
    metricsAndPlants: getGraphMetricsAndPlants(state)
  };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Crops)));
