import React, {
  useCallback
} from 'react';

import classnames from 'classnames';

// @ts-ignore
import { injectIntl, InjectedIntlProps } from 'react-intl';

// @ts-ignore
import ReactTooltip from 'react-tooltip';

import {
  Metric, MetricDef, MetricGroupInput, NodeComponents, TreeNodeIdTypes, TreeNodePathComponents
} from 'store/graphs/types';

import { DeletePresetMetric, DeletePresetMetricGroup } from 'store/graphs/actions';

import { getNameByLocal } from 'helpers/getNameByLocal';

import { useHoverLogic } from '../../../MetricGroup/MetricGroup';

import MetricItem from '../../../MetricItem';

import { ReactComponent as CloseIcon } from '../../../MetricGroup/assets/close.svg';
import { ReactComponent as CompartmentIcon } from '../../../MetricGroup/assets/compartment.svg';
import { ReactComponent as EquipIcon } from '../../../MetricGroup/assets/equip.svg';
import { ReactComponent as PlantingIcon } from '../../../MetricGroup/assets/planting.svg';

import { ReactComponent as ChevronIcon } from '../../../../../SidePanel/SidePanelGraphPresetMetricGroups/components/assets/chevron.svg';
import tooltipStyles from '../../../../../Tooltip/index.module.css';

import getSubNodeTitle from '../../../../../../helpers/getSubNodeTitle';

import styles from './PanelMetricGroup.module.css';
import {uniqueId} from "lodash";


export type OnClickTitleFunc = (key: Array<TreeNodePathComponents>) => void;

export type PanelMetricGroupProps = {
  showIcon: boolean;
  type: TreeNodeIdTypes;
  input: MetricGroupInput;
  metrics: Array<MetricDef>;
  groupKey: string;
  allMetrics: Array<Metric>;
  deletePresetMetric: DeletePresetMetric;
  deletePresetMetricGroup: DeletePresetMetricGroup;
  locationId: number;
  onClickTitle: OnClickTitleFunc;
}

const PanelMetricGroup = ({
  intl,
  showIcon,
  type,
  input,
  metrics,
  groupKey,
  allMetrics,
  locationId,
  deletePresetMetric,
  deletePresetMetricGroup,
  onClickTitle,
}: PanelMetricGroupProps & InjectedIntlProps) => {
  const { formatMessage, locale } = intl;
  const {
    isGroupHover,
    handlerCloseGroupMouseOver,
    handlerCloseGroupMouseOut,
    handlerCloseGroupFocus,
    handlerCloseGroupBlur,
  } = useHoverLogic();

  const tooltipId = `Tooltip-PanelMetricGroup-${type}-${groupKey}-${locationId}-${input.firstNode.id || 0}-${input.secondNode.id || 0}`;
  const tooltipIconId = `Tooltip-PanelMetricGroup-tooltip-icon-${uniqueId()}`;

  const handlerGroupClose = useCallback(() => {
    deletePresetMetricGroup({
      group: [type, locationId, input.firstNode.id || 0, input.secondNode.id || 0]
    });
  }, [deletePresetMetricGroup, type, locationId, input]);

  const handlerGraphMetricClose = useCallback((metricId: number) => {
    deletePresetMetric({
      metric: [type, locationId, input.firstNode.id || 0, input.secondNode.id || 0, metricId]
    });
  }, [type, locationId, input, deletePresetMetric]);

  const handleTitleClick = useCallback(() => {
    if (onClickTitle) {
      const firstMetric = metrics[0];
      onClickTitle([...firstMetric.node]);
    }
  }, [onClickTitle, metrics]);

  return (
    <div
      className={classnames(styles.group, {
        [styles.groupHoverable]: isGroupHover,
      })}
    >
      <div className={classnames(styles.groupControls, {
        [styles.groupLeftMargin]: !showIcon
      })}
      >
        {showIcon && (
          <div
            className={styles.groupIconContainer}
            data-tip=''
            data-for={tooltipIconId}
          >
            {type === 'g' && (<CompartmentIcon />)}
            {type === 'e' && (<EquipIcon />)}
            {type === 'c' && (<PlantingIcon />)}
            <ReactTooltip
              className={classnames(tooltipStyles.smallTooltip)}
              id={tooltipIconId}
              effect='solid'
              html
              getContent={() => formatMessage({ id: `graphs.groupMetricTypes.${type}` })}
            />
          </div>
        )}
        <div className={styles.groupNameWrapper}>
          <span
            role='menu'
            tabIndex={0}
            className={styles.groupName}
            onClick={handleTitleClick}
          >
            {!input.firstNode && formatMessage({ id: 'graphs.sidePanel.commonLocationMetrics' })}
            {input.firstNode && input.firstNode.attributes.name}
          </span>
          {input.firstNode && input.firstNode.id !== 0 && <div className={styles.groupIcon}><ChevronIcon /></div>}
          <span
            role='menu'
            tabIndex={0}
            className={styles.groupName}
            onClick={handleTitleClick}
            data-for={tooltipId}
            data-tip={input.firstNode && input.secondNode && getSubNodeTitle({
              subNode: input.secondNode, formatMessage
            })}
          >
            {input.firstNode && input.firstNode.id !== 0 && !input.secondNode && formatMessage({ id: 'graphs.sidePanel.commonCompartmentMetrics' })}
            {input.firstNode && input.firstNode.id !== 0 && input.secondNode && getSubNodeTitle({
              subNode: input.secondNode, formatMessage
            })}
          </span>
        </div>
        <button
          type='button'
          className={styles.groupClose}
          onClick={handlerGroupClose}
          onMouseOver={handlerCloseGroupMouseOver}
          onMouseOut={handlerCloseGroupMouseOut}
          onFocus={handlerCloseGroupFocus}
          onBlur={handlerCloseGroupBlur}
        >
          <CloseIcon className={styles.groupCloseIcon} />
        </button>
      </div>
      <div className={styles.groupDataSeries}>
        {metrics.map((metric: MetricDef) => {
          const foundMetric = allMetrics.find((m: Metric) => m.id === metric.node[NodeComponents.MetricId]);
          return (
            <MetricItem
              key={`data-series-${metric.node.join('_')}`}
              title={getNameByLocal(foundMetric, locale, 'attributes.name')}
              metricId={foundMetric?.id}
              locationId={+metric.node[NodeComponents.LocationId]}
              onClose={handlerGraphMetricClose}
              blocked={!metric.available}
              units={foundMetric.attributes.cunit}
            />
          );
        })}
      </div>
      <ReactTooltip
        className={classnames(tooltipStyles.smallTooltip)}
        effect='solid'
        html
        id={tooltipId}
      />
    </div>
  );
};

export default injectIntl(PanelMetricGroup);
