export default function getSubNodeTitle({ subNode, formatMessage }) {
  const { id, attributes: { type, name } } = subNode;

  if (id === '#') {
    return formatMessage({ id: 'graphs.sidePanel.commonCompartmentMetrics' });
  }

  let title = name;

  if (type) {
    title = `${formatMessage({ id: `subNodes.${type}` })}${name === 'wireless' ? '' : ` ${name}`}`;
  }

  return title;
}
