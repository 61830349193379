export const truncateSeries = (series, type, alignedForecastDate) => ({
  ...series,
  points: series?.points ? Object.keys(series?.points)
    .filter(dt => (type === 'future' ? dt <= alignedForecastDate : dt >= alignedForecastDate))
    .reduce((memo, val) => ({
      ...memo,
      [val]: series?.points[val]
    }), {}) : null,
});

export const splitPastFuture = (series, alignedForecastDate) => {
  const pastModel = truncateSeries(series, 'future', alignedForecastDate);
  const futureModel = truncateSeries(series, 'past', alignedForecastDate);
  return [pastModel, futureModel];
};
