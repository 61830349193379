import moment from 'moment-timezone';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import { getUnits, getValue } from 'helpers/getValueDetails';
import { API_DATE_FORMAT, getDefaultDateRange } from 'helpers/defaultDates';

import TextInfoTooltip from 'components/TextInfoTooltip/TextInfoTooltip';
import DashboardSectionHeader from 'components/DashboardSectionHeader';
import DashboardParameter from 'components/DashboardParameter';
import SimpleBarChart from 'components/SimpleBarChart';

import styles from './DashboardReportBarChart.module.css';

const DEFAULT_DATE_RANGE = getDefaultDateRange();

class DashboardReportBarChart extends Component {
  static propTypes = {
    intl: intlShape.isRequired,

    items: PropTypes.array.isRequired,
    report: PropTypes.object,
    isDataFetching: PropTypes.bool,
    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
  };

  static defaultProps = {
    isDataFetching: false,
    report: null,
    anyDateOfPeriodStart: DEFAULT_DATE_RANGE.startDate,
    anyDateOfPeriodEnd: DEFAULT_DATE_RANGE.endDate,
  };

  render() {
    const {
      intl,
      isDataFetching,
      items,
      report,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
    } = this.props;

    const { formatMessage } = intl;

    const total = get(report, 'breakdown.total');

    const totalAmount = getValue(total, 0);
    const totalUnits = getUnits(total, formatMessage);

    const isEmpty = Boolean(!report?.rows || report?.rows.length === 0);

    const startDate = moment(anyDateOfPeriodStart, API_DATE_FORMAT);
    const endDate = moment(anyDateOfPeriodEnd, API_DATE_FORMAT);
    const isCurrentYear = moment().year() === endDate.year();

    const start = startDate.format(`${formatMessage({ id: 'date.dayMonth' })}${isCurrentYear ? '' : ', YYYY'}`);
    const end = endDate.format(`${formatMessage({ id: 'date.dayMonth' })}${isCurrentYear ? '' : ', YYYY'}`);

    const customRenderTooltipDate = () => `${start} — ${end}`;

    return (
      <>
        <DashboardSectionHeader
          className={styles.header}
          headerText={(
            <TextInfoTooltip
              text={formatMessage({ id: 'dashboards.plannedWorks' })}
              tooltipText={formatMessage({ id: 'dashboards.plannedWorksTooltip' })}
              tooltipClassName={styles.tooltip}
            />
          )}
        >
          <div className={styles.params}>
            <DashboardParameter
              containerClassName={styles.param}
              headerText={formatMessage({ id: 'dashboards.totalAll' })}
              values={[{
                value: totalAmount,
                units: totalUnits,
              }]}
            />
          </div>
        </DashboardSectionHeader>

        <SimpleBarChart
          items={items}
          intl={intl}
          fixedHeight={216}
          isFetching={isDataFetching}
          isFlexModeEnabled
          chartWrapperClassName={styles.chartWrapper}
          isEmpty={isEmpty}
          showEmptyBars={false}
          isForceRenderDate
          customRenderTooltipDate={customRenderTooltipDate}
        />
      </>
    );
  }
}

export default injectIntl(DashboardReportBarChart);
