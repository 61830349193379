import { useEffect } from 'react';
import { event as lastEvent, select } from 'd3';

function useMinMax(groupRef, groupRightRef, options) {
  const {
    chartParams: {
      x, y, colors, rY, rX
    },
    rightData,
    data,
    xType,
    yType,
    mouseOverHandler,
    mouseOutHandler,
    mouseMoveHandler,
    clickHandler,
    withoutMinMax,
    parseDate
  } = options;

  useEffect(() => {
    const group = groupRef.current ? select(groupRef.current) : null;
    if (group && !withoutMinMax) {
      const getFillColor = colorIndex => colors[colorIndex];
      const mouseover = d => mouseOverHandler(d, lastEvent);
      const mouseout = d => mouseOutHandler(d, lastEvent);
      const mousemove = d => mouseMoveHandler(d, lastEvent);
      const click = d => clickHandler(d, lastEvent);

      const calculateX = d => (
        (xType === 'time')
          ? x(parseDate(d.x))
          : x(d.x)) - 2;

      const calculateY = d => (
        (yType === 'time')
          ? y(parseDate(d.max))
          : y(d.max));

      const calculateHeight = d => y(d.min) - y(d.max);

      group.selectAll('rect').remove();

      data.forEach((item, i) => {
        item.filter(point => point.min && point.max).forEach((d) => {
          group
            .datum(d)
            .append('rect')
            .attr('class', 'min-max-rect')
            .attr('width', '4px')
            .attr('height', calculateHeight(d))
            .style('fill', getFillColor(i))
            .attr('x', calculateX)
            .attr('y', calculateY)
            .style('fill-opacity', 0.6)
            .on('mouseover', mouseover)
            .on('mouseout', mouseout)
            .on('mousemove', mousemove)
            .on('click', click);
        });
      });
    }
  }, [
    groupRef,
    groupRightRef,
    x, y, colors, rY, rX,
    rightData,
    data,
    xType,
    yType,
    mouseOverHandler,
    mouseOutHandler,
    mouseMoveHandler,
    clickHandler,
    withoutMinMax,
    parseDate
  ]);
}

export default useMinMax;
