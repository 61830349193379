import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import styles from './SidePanelHeader.module.css';

import { ReactComponent as CloseIcon } from './assets/close.svg';


export default class SidePanelHeader extends Component {
  static propTypes = {
    headerText: PropTypes.object.isRequired,
    handlerClose: PropTypes.func,
  };

  static defaultProps = {
    handlerClose: null,
  };

  render() {
    const { headerText, handlerClose } = this.props;

    return (
      <div className={styles.header}>
        <span className={styles.text}>
          {headerText}
        </span>
        <span
          role='button'
          tabIndex={0}
          className={styles.close}
          onClick={handlerClose}
          onKeyPress={handlerClose}
        >
          <CloseIcon className={styles.closeIcon} />
        </span>
      </div>
    );
  }
}
