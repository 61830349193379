import moment from 'moment';
import getDateFormat from 'helpers/getDateFormat';

const getFormattedDate = (formatMessage, date, period = 'month') => {
  const momentDate = moment.utc(new Date((+date) * 60000));

  switch (period) {
    case 'month':
      return momentDate.format('MMM');
    case 'week': {
      const week = momentDate.isoWeek();
      const weekDates = `${momentDate.clone().isoWeekday(1).format('MMM D')} – ${momentDate.clone().isoWeekday(7).format('MMM D')}`;
      return `${weekDates}, ${formatMessage({ id: 'cycle.wk' })} ${week}`;
    }
    case 'day':
      return momentDate.format(getDateFormat('llll'));
    default:
      return momentDate.format(getDateFormat('MMM'));
  }
};

export default getFormattedDate;
