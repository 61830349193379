import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { uniqueId } from 'lodash';
import classnames from 'classnames';

import styles from './DefaultTextInput.module.css';

class DefaultTextInput extends PureComponent {
  static propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    error: PropTypes.string,
    theme: PropTypes.string,

    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    value: '',
    placeholder: null,
    className: null,
    disabled: false,
    type: null,
    error: null,
    theme: null,

    onChange: () => {},
    onFocus: null,
    onBlur: null,
  };

  state = {
    active: this.props.value.length !== 0,
    focused: false,
  };

  onFocus = (event) => {
    const { onFocus } = this.props;

    this.setState({
      active: true,
      focused: true,
    });
    if (onFocus) {
      onFocus(event);
    }
  }

  onBlur = (event) => {
    const { onBlur } = this.props;

    this.setState({
      active: event.target.value.length !== 0,
      focused: false,
    });

    if (onBlur) {
      onBlur(event);
    }
  }

  render() {
    const {
      value, placeholder, error, className, disabled, type, onChange, theme
    } = this.props;
    const { active, focused } = this.state;
    const inputId = uniqueId();

    return (
      <div className={classnames(styles.inputWrapper, className)}>
        <div
          className={classnames(
            styles.inputContainer,
            {
              [styles.inputContainerDisabled]: disabled,
              [styles.widthoutPlaceholder]: !placeholder,
              [styles.inputContainerFocused]: focused,
              [styles.inputWithError]: error,
              [styles.mini]: theme === 'mini',
            },
          )}
        >
          <label
            htmlFor={`default-text-input-${inputId}`}
            className={styles.inputWithLabel}
          >
            {placeholder && (
              <span className={classnames(styles.label, { [styles.labelActive]: active })}>
                {placeholder}
              </span>
            )}
            <input
              id={`default-text-input-${inputId}`}
              className={styles.input}
              type={type || 'text'}
              value={value}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={onChange}
              disabled={disabled}
            />
          </label>
        </div>
        {error && (
          <span className={styles.error}>
            {error}
          </span>
        )}
      </div>
    );
  }
}

export default DefaultTextInput;
