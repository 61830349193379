import classnames from 'classnames';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import DefaultCircleLoader from '../../../DefaultCircleLoader';

import styles from './BenchmarkCompareSection.module.css';
import Typography from '../../../Typography';


class BenchmarkCompareSection extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    controls: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    className: PropTypes.string,
    headerIcon: PropTypes.element,
    isFetching: PropTypes.bool,
    headerVariant: PropTypes.string,
    wrapperClassName: PropTypes.string
  };

  static defaultProps = {
    className: null,
    children: null,
    header: null,
    headerIcon: null,
    controls: null,
    isFetching: null,
    headerVariant: 'h3',
    wrapperClassName: null
  };

  render() {
    const {
      className,
      children,
      header,
      headerIcon,
      controls,
      isFetching,
      headerVariant,
      wrapperClassName
    } = this.props;

    return (
      <div className={classnames(styles.layout, className)}>
        <div className={classnames(styles.wrapper, wrapperClassName)}>
          <div className={styles.header}>
            <Typography variant={headerVariant}>
              {header}
            </Typography>
            {headerIcon}
          </div>
          <div className={styles.controls}>
            {controls}
          </div>
        </div>
        <div className={styles.content}>
          {isFetching ? <DefaultCircleLoader /> : children}
        </div>
      </div>
    );
  }
}

export default injectIntl(BenchmarkCompareSection);
