
// TODO: Объединить с компонентом из бенча
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import TooltipContent from '../TooltipContent';

import styles from './Tooltip.module.css';

// Высота и отступ треугольника тултипа
const TAIL_HEIGHT = 9;
const TAIL_PADDING = 7;

const Tooltip = ({
  x, y, tooltipData, width, className,
  onMouseOverCallback, onMouseOutCallback, roundTo,
}) => {
  const tooltipStyles = {
    // height: `${height}px`,
    width: `${width}px`,
    left: `${x - (width / 2)}px`,
    bottom: `${y + TAIL_HEIGHT + TAIL_PADDING}px`,
  };

  /* eslint-disable jsx-a11y/mouse-events-have-key-events */
  return (
    <div
      onMouseEnter={() => onMouseOverCallback({ tooltipData, x, y })}
      onMouseLeave={() => onMouseOutCallback(null)}
      className={classnames(styles.tooltip, className)}
      style={tooltipStyles}
    >
      <div className={styles.tooltipContent}>
        {/* TODO: вынести контент тултипа в функцию-рендер и передавать её пропсом */}
        <TooltipContent tooltipData={tooltipData} roundTo={roundTo} />
      </div>
      <div className={styles.triangle} />
    </div>
  );
  /* eslint-enable jsx-a11y/mouse-events-have-key-events */
};

Tooltip.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  tooltipData: PropTypes.object.isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
  onMouseOverCallback: PropTypes.func,
  onMouseOutCallback: PropTypes.func,
  roundTo: PropTypes.number,
};

Tooltip.defaultProps = {
  className: null,
  width: 288,
  onMouseOverCallback: () => {},
  onMouseOutCallback: () => {},
  roundTo: 0,
};

export default memo(Tooltip);

