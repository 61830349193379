import { createAction } from 'redux-actions';

export const clearClimateIncidents = createAction('CLEAR_CLIMATE_INCIDENTS');

export const requestGetClimateIncidents = createAction('REQUEST_GET_CLIMATE_INCIDENTS');
export const receiveGetClimateIncidents = createAction('RECEIVE_GET_CLIMATE_INCIDENTS');

export const requestGetClimateIncidentsForGraphs = createAction('REQUEST_GET_CLIMATE_INCIDENTS_FOR_GRAPHS');
export const receiveGetClimateIncidentsForGraphs = createAction('RECEIVE_GET_CLIMATE_INCIDENTS_FOR_GRAPHS');

export const requestGetClimateIncidentsDetail = createAction('REQUEST_GET_CLIMATE_INCIDENTS_DETAIL');
export const receiveGetClimateIncidentsDetail = createAction('RECEIVE_GET_CLIMATE_INCIDENTS_DETAIL');

export const requestSetClimateIncidentsDay = createAction('REQUEST_SET_CLIMATE_INCIDENTS_DAY');
export const receiveSetClimateIncidentsDay = createAction('RECEIVE_SET_CLIMATE_INCIDENTS_DAY');

export const requestCreateAbnormalityIncident = createAction('REQUEST_CREATE_ABNORMALITY_INCIDENT');
export const receiveCreateAbnormalityIncident = createAction('RECEIVE_CREATE_ABNORMALITY_INCIDENT');

export const requestCheckAbnormalityIncident = createAction('REQUEST_CHECK_ABNORMALITY_INCIDENT');
export const receiveCheckAbnormalityIncident = createAction('RECEIVE_CHECK_ABNORMALITY_INCIDENT');

export const requestUpdateAbnormalityIncident = createAction('REQUEST_UPDATE_ABNORMALITY_INCIDENT');
export const receiveUpdateAbnormalityIncident = createAction('RECEIVE_UPDATE_ABNORMALITY_INCIDENT');

export const requestDeleteAbnormalityIncident = createAction('REQUEST_DELETE_ABNORMALITY_INCIDENT');
export const receiveDeleteAbnormalityIncident = createAction('RECEIVE_DELETE_ABNORMALITY_INCIDENT');

export const setEditedAbnormalityIncident = createAction('SET_EDITED_ABNORMALITY_INCIDENT');
export const setIsAddAbnormalityIncidentsMode = createAction('SET_IS_ADD_ABNORMALITY_INCIDENT_MODE');
export const setIsUpdateAbnormalityIncidentsMode = createAction('SET_IS_UPDATE_ABNORMALITY_INCIDENT_MODE');
export const setIsDeleteAbnormalityIncidentsMode = createAction('SET_IS_DELETE_ABNORMALITY_INCIDENT_MODE');
