import React, {
  useState, useEffect, useMemo, useRef
} from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import classnames from 'classnames';
import animateScrollTo from 'animated-scroll-to';
import moment from 'moment';

import { API_DATE_FORMAT } from 'helpers/defaultDates';
import useMountEffect from 'hooks/useMountEffect';

import BigButton from 'components/BigButton';
import DefaultDeleteDialog from 'components/DefaultDeleteDialog';
import TextInfoTooltip from 'components/TextInfoTooltip';
import EssentialPricePlanDigitalTwin from 'components/EssentialPricePlanDigitalTwin';
import SettingsIcon from 'components/Icons/SettingsIcon';

import SeriesEditorBlock from '../SeriesEditorBlock';
import SunLightEditorBlock from '../SunLightEditorBlock';
import CropsSelectBlock from '../CropsSelectBlock';
import DigitalTwinHeader from '../DigitalTwinHeader';
import DigitalTwinSidePanel from '../DigitalTwinSidePanel';
import DigitalTwinSimulationsList from '../DigitalTwinSimulationsList';
import EditDialog from '../EditDialog';
import DigitalTwinGraphs from '../DigitalTwinGraphs';

import styles from './DigitalTwin.module.css';


const DigitalTwin = ({
  intl,
  location,
  match,
  history,
  simulationsList,
  requestSimulationsList,
  requestNewSimulationParams,
  requestSimulationById,
  requestDeleteSimulationById,
  requestSupportedVarieties,
  supportedVarieties,
  requestSimulationCalculate,
  currentSimulation,
  currentSimulationEdited,
  requestSaveSimulation,
  requestSaveSimulationByIdWithoutParams,
  setSimulationEditedData,
  resetSimulationEditedData,
  isDataFetching,
  digitalTwinAccess,
  isEssentialPricePlan,
  requestSimulationDatesChange,
}) => {
  const { formatMessage } = intl;

  const varietySelectRef = useRef(null);
  const sidePanelRef = useRef(null);

  const [sidePanelOpened, setToggleSidePanel] = useState(true);
  const [simulationsListOpened, setToggleSimulationsList] = useState(false);
  const [deleteDialogOpened, setToggleDeleteDialog] = useState(false);
  const [selectedSimulationId, setSelectedSimulationId] = useState(null);
  const [simulationNameEdited, setSimulationNameEdited] = useState(null);
  const [simulationInDialogState, setSimulationInDialogState] = useState(null);
  const [varietyIdError, setVarietyIdError] = useState(null);
  const [originExternalPoints, setOriginExternalPoints] = useState(null);

  const [totalLightPoints, setTotalLightPoints] = useState(null);
  // Если есть редактированная версия, то приоритет для неё
  const simulation = currentSimulationEdited || currentSimulation;
  const simulationName =
    simulation?.name || formatMessage({ id: 'cropModel.untitledModel' });
  const simulationCopyName = `${simulationName} (${formatMessage({ id: 'cropModel.copy' })})`;
  const simulationDesc = simulation?.description;
  const isSimulationEdited = !!currentSimulationEdited;

  const {
    artificialLight,
    temperature,
    co2,
    plantDensity,
    sunLight,
    seedingDate,
    endDate,
  } = simulation?.params || {};

  const defaultSeedingDate = moment().startOf('year').format(API_DATE_FORMAT);
  const defaultEndDate = moment().endOf('year').format(API_DATE_FORMAT);

  useMountEffect(() => {
    if (!digitalTwinAccess) {
      history.push(`/${match.params.organizationSlug}/crops`);
    }
    requestSimulationsList();
    requestSupportedVarieties();
    requestNewSimulationParams({
      seedingDate: seedingDate || defaultSeedingDate,
      endDate: endDate || defaultEndDate,
      withReset: true,
    });
  });

  if (originExternalPoints === null && sunLight?.pointsByEntryType?.fromExternalProvider) {
    setOriginExternalPoints(sunLight?.pointsByEntryType?.fromExternalProvider);
  }

  useEffect(() => {
    if (artificialLight && sunLight) {
      const {
        entryType,
        pointsByEntryType: {
          custom,
          fromExternalProvider
        }
      } = sunLight;

      const {
        points
      } = artificialLight;

      const sunLightPoints = (entryType === 'custom' ? custom : fromExternalProvider) || {};

      const sumLight = Object.keys(sunLightPoints).reduce((memo, key) => {
        const sunValue = sunLightPoints[key];
        const artificialValue = points[key];
        return {
          ...memo,
          [key]: sunValue + artificialValue
        };
      }, {});

      setTotalLightPoints(sumLight);
    }
    // eslint-disable-next-line
  }, [artificialLight, sunLight, originExternalPoints]);

  const maxTotalValue = useMemo(() => {
    if (artificialLight && sunLight) {
      const {
        entryType,
        pointsByEntryType: {
          custom,
          fromExternalProvider
        }
      } = sunLight;

      const {
        points
      } = artificialLight;
      const sunLightPoints = (entryType === 'custom' ? custom : fromExternalProvider) || {};

      const maxArtificialLight = Math.max(...Object.values(points));
      const maxSunLights = Math.max(...Object.values(sunLightPoints));

      const value = maxArtificialLight + maxSunLights;
      // TODO: Не надо ли сюда в гэп добавить услловие чтобы он ставился в зависимости от юнита?
      const ceilGape = 20000;
      return Math.ceil(value / ceilGape) * ceilGape;
    }
    return 0;
    // eslint-disable-next-line
  }, [artificialLight, sunLight, originExternalPoints]);


  const scrollToElementByRef = (refToElem, parentElem) => {
    if (refToElem) {
      const scrollHeightWithPadding = refToElem?.current?.offsetTop - 16;

      // Общее окно скроллим тоже, т.к. и сама страница может быть проскроллена вниз
      window.scrollTo(0, 0);

      animateScrollTo(scrollHeightWithPadding, {
        element: parentElem?.current,
      });
    }
  };

  const onSetVarietyIdErrorTrue = () => {
    scrollToElementByRef(varietySelectRef, sidePanelRef);
    setVarietyIdError(true);
  };

  const onSimulationDeleteClick = ({ simulationId }) => {
    setSelectedSimulationId(simulationId);
    setToggleDeleteDialog(true);
  };

  const onSimulationSelectClick = (simulationId) => {
    setVarietyIdError(false);
    requestSimulationById(simulationId);
    setToggleSimulationsList(false);
  };

  const onRemoveSimulation = () => {
    requestDeleteSimulationById({ simulationId: selectedSimulationId });
    setToggleDeleteDialog(false);
  };

  const onCancelRemoveSimulation = () => {
    setSelectedSimulationId(null);
    setToggleDeleteDialog(false);
  };

  const onEditSimulationName = () => {
    if (!simulation?.varietyId) {
      onSetVarietyIdErrorTrue();

      return null;
    }

    return setSimulationNameEdited('edit');
  };

  const onEditSimulationAtList = (editedSimulationAtList) => {
    setSimulationNameEdited('editFromList');
    setSimulationInDialogState(editedSimulationAtList);
  };

  const onSaveSimulationClick = (saveMode) => {
    if (!simulation?.varietyId) {
      onSetVarietyIdErrorTrue();

      return null;
    }

    setVarietyIdError(false);

    return setSimulationNameEdited(saveMode);
  };

  const onSaveSimulationChanges = ({
    changedSimulation, name, description, actionSuccess = () => {}
  }) => {
    if (!changedSimulation?.varietyId) {
      onSetVarietyIdErrorTrue();

      return null;
    }

    setVarietyIdError(false);

    // Если выбрали save as, то обнуляем id, чтобы модель охранилась как новая
    const simulationId =
      simulationNameEdited === 'saveAs' ? null : changedSimulation.id;

    requestSaveSimulation({
      newSimulation: {
        ...changedSimulation,
        name,
        description,
        id: simulationId,
        varietyId: changedSimulation?.varietyId,
      },
    });

    return actionSuccess();
  };

  const onSaveSimulationFromListChanges = ({
    changedSimulation, name, description, actionSuccess = () => {}
  }) => {
    requestSaveSimulationByIdWithoutParams({
      newSimulation: {
        ...changedSimulation,
        name,
        description,
        id: changedSimulation.id,
        varietyId: changedSimulation?.varietyId,
      },
    });

    return actionSuccess();
  };

  const handleFormChange = (newSimulationData) => {
    setSimulationEditedData(newSimulationData);

    if (!newSimulationData?.varietyId) {
      onSetVarietyIdErrorTrue();

      return null;
    }

    setVarietyIdError(false);

    return requestSimulationCalculate({ calculatedSimulation: newSimulationData });
  };

  const handleChangeDateRange = ({
    anyDateOfPeriodStart,
    anyDateOfPeriodEnd,
  }) => {
    if (!simulation?.varietyId) {
      return requestNewSimulationParams({
        seedingDate: anyDateOfPeriodStart,
        endDate: anyDateOfPeriodEnd,
      });
    }

    setOriginExternalPoints(null);

    return requestSimulationDatesChange({
      seedingDate: anyDateOfPeriodStart,
      endDate: anyDateOfPeriodEnd,
    });
  };

  const handleChangeVariety = ({ value: varietyId }) => {
    handleFormChange({
      ...simulation,
      varietyId,
    });
  };

  const handleChangeLightType = ({ entryType }) => {
    handleFormChange({
      ...simulation,
      params: {
        ...simulation?.params,
        sunLight: {
          ...simulation?.params?.sunLight,
          entryType,
        },
      },
    });
  };

  const envelopePoints = (source, points) => {
    const result = {};
    Object.keys(source).forEach((key, index) => {
      // eslint-disable-next-line no-param-reassign
      result[key] = points[index].value;
    });
    return result;
  };

  const handleUpdateCustomLightPoints = (points) => {
    handleFormChange({
      ...simulation,
      params: {
        ...simulation?.params,
        sunLight: {
          ...simulation?.params?.sunLight,
          pointsByEntryType: {
            ...simulation?.params?.sunLight?.pointsByEntryType,
            custom: envelopePoints(
              simulation?.params?.sunLight?.pointsByEntryType?.custom,
              points
            ),
          },
        },
      },
    });
  };

  const handleUpdateExternalLightPoints = (points, correctionPercentage) => {
    handleFormChange({
      ...simulation,
      params: {
        ...simulation?.params,
        sunLight: {
          ...simulation?.params?.sunLight,
          correctionPercentage,
          pointsByEntryType: {
            ...simulation?.params?.sunLight?.pointsByEntryType,
            fromExternalProvider: envelopePoints(
              simulation?.params?.sunLight?.pointsByEntryType?.fromExternalProvider,
              points
            ),
          },
        },
      },
    });
  };


  const updatePointSeries = (type, points) => {
    handleFormChange({
      ...simulation,
      params: {
        ...simulation?.params,
        [type]: {
          ...simulation?.params?.[type],
          points: envelopePoints(simulation?.params?.[type]?.points, points),
        },
      },
    });
  };

  const getValuesMetrics = (type, units) => {
    const metrics = {
      sunLight: {
        min: 0,
        max: units === 'joulePerSquareCentimeter' ? 30000 : 500,
        default: units === 'joulePerSquareCentimeter' ? 10000 : 200,
        round: 0,
        units
      },

      artificialLight: {
        min: 0,
        max: units === 'joulePerSquareCentimeter' ? 20000 : 500,
        default: units === 'joulePerSquareCentimeter' ? 10000 : 200,
        round: 0,
        units
      },

      totalLight: {
        min: 0,
        max: maxTotalValue,
        default: Math.floor(maxTotalValue / 2.0),
        round: 0,
        units
      },

      temperature: {
        min: 0,
        max: 40.0,
        default: 20.0,
        round: 1,
        units
      },

      co2: {
        min: 0,
        max: 2000,
        default: 1000,
        round: 0,
        units
      },

      plantDensity: {
        min: 2.0,
        max: 6.0,
        default: 2.0,
        round: 1,
        units
      },
    };
    return metrics[type];
  };

  const seriesRange = (series) => {
    const keys = Object.keys(series);
    return {
      startWeek: Number(keys[0]) || 0,
      endWeek: Number(keys[keys.length - 1]) || 0,
    };
  };

  const simulationInDialog = simulationInDialogState || simulation;

  if (isEssentialPricePlan) {
    return (
      <EssentialPricePlanDigitalTwin />
    );
  }

  return (
    <div>
      <div className={styles.layout}>
        <DigitalTwinHeader
          isDataFetching={isDataFetching}
          isEdited={isSimulationEdited}
          isNewSimulation={Boolean(!simulation?.id)}
          simulationName={simulationName}
          simulationDesc={simulationDesc}
          onSaveSimulationClick={() => {
            if (!simulation?.id) {
              return onSaveSimulationClick('save');
            }

            return onSaveSimulationChanges({
              name: simulation?.name,
              description: simulation?.description,
              changedSimulation: simulation,
            });
          }}
          onNewSimulationClick={() =>
            requestNewSimulationParams({
              seedingDate,
              endDate,
              withReset: true,
            })}
          onOpenSimulationsListClick={() => setToggleSimulationsList(true)}
          onSimulationNameClick={onEditSimulationName}
          onSaveAsSimulationClick={() => onSaveSimulationClick('saveAs')}
          onRevertClick={resetSimulationEditedData}
        />
        <div className={styles.content}>
          <div
            className={classnames(styles.basePanel, {
              [styles.sidePanelOpened]: sidePanelOpened,
            })}
          >
            <div className={styles.panelHeader}>
              <div className={styles.panelHeaderText}>
                <TextInfoTooltip
                  text={formatMessage({ id: 'cropModel.calculatedModel' })}
                  tooltipText={formatMessage({
                    id: 'cropModel.calculatedModelTooltip',
                  })}
                  className={styles.lineChartsGroupHeader}
                />
              </div>
              <BigButton
                className={classnames(styles.settingsIcon, {
                  [styles.settingsIconOn]: sidePanelOpened,
                })}
                onClick={() => setToggleSidePanel(!sidePanelOpened)}
                tooltip={formatMessage({ id: 'cropModel.showInputs' })}
                icon={<SettingsIcon />}
                theme='transparent'
                highIcon
                tooltipClassName={styles.buttonTooltip}
              />
            </div>
            <div className={styles.panelContent}>
              <DigitalTwinGraphs calculatedResult={simulation?.result} isDataFetching={isDataFetching} />
            </div>
          </div>
          <DigitalTwinSidePanel
            sidePanelRef={sidePanelRef}
            opened={sidePanelOpened}
            onClose={() => setToggleSidePanel(false)}
            headerText={formatMessage({ id: 'cropModel.modelInputs' })}
          >
            <div className={styles.sidePanelContent}>
              <CropsSelectBlock
                varietySelectRef={varietySelectRef}
                onChangeDateRange={handleChangeDateRange}
                onChangeVariety={handleChangeVariety}
                seedingDate={seedingDate}
                endDate={endDate}
                varieties={supportedVarieties}
                currentVariety={simulation?.varietyId}
                error={varietyIdError ? formatMessage({ id: 'cropModel.varietyIsRequired' }) : null}
                isDataFetching={isDataFetching}
              />
              <SunLightEditorBlock
                {...seriesRange(sunLight?.pointsByEntryType?.fromExternalProvider || {})}
                units={sunLight?.units}
                fromExternalProvider={originExternalPoints || {}}
                custom={sunLight?.pointsByEntryType?.custom || {}}
                correctionPercentage={sunLight?.correctionPercentage}
                entryType={sunLight?.entryType}
                onChangeLightType={handleChangeLightType}
                onUpdateCustomLightPoints={handleUpdateCustomLightPoints}
                onUpdateExternalLightPoints={handleUpdateExternalLightPoints}
                title={formatMessage({ id: 'cropModel.sunLight' })}
                location={location}
                getValuesMetrics={getValuesMetrics}
              />
              <SeriesEditorBlock
                {...seriesRange(artificialLight?.points || {})}
                points={artificialLight?.points || {}}
                units={artificialLight?.units}
                valueMetrics={getValuesMetrics('artificialLight', artificialLight?.units)}
                title={formatMessage({ id: 'cropModel.artificialLight' })}
                onValuesChanged={(points) => {
                  updatePointSeries('artificialLight', points);
                }}
              />
              <SeriesEditorBlock
                {...seriesRange(artificialLight?.points || {})}
                points={totalLightPoints}
                units={artificialLight?.units}
                valueMetrics={getValuesMetrics('totalLight', artificialLight?.units)}
                title={formatMessage({ id: 'cropModel.totalLight' })}
                inputTitle={formatMessage({ id: 'cropModel.tolalLightLabel' })}
                editable={false}
              />
              <SeriesEditorBlock
                {...seriesRange(temperature?.points || {})}
                valueMetrics={getValuesMetrics('temperature', temperature?.units)}
                points={temperature?.points || {}}
                units={temperature?.units}
                title={formatMessage({ id: 'cropModel.temperature' })}
                inputTitle={formatMessage({ id: 'cropModel.temperature24' })}
                onValuesChanged={(points) => {
                  updatePointSeries('temperature', points);
                }}
              />
              <SeriesEditorBlock
                {...seriesRange(co2?.points || {})}
                valueMetrics={getValuesMetrics('co2', co2?.units)}
                points={co2?.points || {}}
                units={co2?.units}
                title={formatMessage({ id: 'cropModel.co2' })}
                onValuesChanged={(points) => {
                  updatePointSeries('co2', points);
                }}
              />
              <SeriesEditorBlock
                {...seriesRange(plantDensity?.points || {})}
                valueMetrics={getValuesMetrics('plantDensity', plantDensity?.units)}
                points={plantDensity?.points || {}}
                units={plantDensity?.units}
                title={formatMessage({ id: 'plans.plantingDensity' })}
                inputTitle={formatMessage({ id: 'cropModel.density' })}
                onValuesChanged={(points) => {
                  updatePointSeries('plantDensity', points);
                }}
              />
            </div>
          </DigitalTwinSidePanel>
        </div>
      </div>

      <DigitalTwinSimulationsList
        varieties={supportedVarieties}
        simulationsList={simulationsList}
        isOpened={simulationsListOpened}
        onClose={() => setToggleSimulationsList(false)}
        onSimulationSelect={onSimulationSelectClick}
        onSimulationEdit={onEditSimulationAtList}
        onSimulationDelete={onSimulationDeleteClick}
      />

      {deleteDialogOpened && (
        <DefaultDeleteDialog
          isFetching={false}
          title={formatMessage({ id: 'cropModel.deleteDialogTitle' })}
          text={formatMessage({ id: 'cropModel.deleteDialogText' })}
          handlerCloseDialog={onCancelRemoveSimulation}
          handlerDontDelete={onCancelRemoveSimulation}
          handlerDelete={onRemoveSimulation}
        />
      )}

      {simulationNameEdited && (
        <EditDialog
          label={
            (simulationNameEdited === 'edit' || simulationNameEdited === 'editFromList')
              ? formatMessage({ id: 'cropModel.editCropModel' })
              : formatMessage({ id: 'cropModel.saveCropModel' })
          }
          handlerCloseDialog={() => {
            setSimulationNameEdited(null);
            setSimulationInDialogState(null);
          }}
          handlerDontSave={() => {
            setSimulationNameEdited(null);
            setSimulationInDialogState(null);
          }}
          handlerSave={(changedParams) => {
            if (simulationNameEdited === 'editFromList') {
              return onSaveSimulationFromListChanges({
                changedSimulation: simulationInDialog,
                ...changedParams,
              });
            }

            return onSaveSimulationChanges({
              changedSimulation: simulationInDialog,
              ...changedParams,
            });
          }}
          initialValues={{
            simulationName:
              simulationNameEdited !== 'saveAs'
                ? simulationInDialog?.name
                : simulationCopyName,
            simulationDesc: simulationInDialog?.description,
          }}
        />
      )}
    </div>
  );
};

DigitalTwin.propTypes = {
  intl: intlShape.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  simulationsList: PropTypes.array,
  requestSimulationsList: PropTypes.func.isRequired,
  requestNewSimulationParams: PropTypes.func.isRequired,
  requestSimulationById: PropTypes.func.isRequired,
  requestDeleteSimulationById: PropTypes.func.isRequired,
  requestSimulationCalculate: PropTypes.func.isRequired,
  requestSupportedVarieties: PropTypes.func.isRequired,
  supportedVarieties: PropTypes.array,
  currentSimulation: PropTypes.object,
  currentSimulationEdited: PropTypes.object,
  requestSaveSimulation: PropTypes.func.isRequired,
  requestSaveSimulationByIdWithoutParams: PropTypes.func.isRequired,
  setSimulationEditedData: PropTypes.func.isRequired,
  resetSimulationEditedData: PropTypes.func.isRequired,
  isDataFetching: PropTypes.bool,
  digitalTwinAccess: PropTypes.bool.isRequired,
  isEssentialPricePlan: PropTypes.bool.isRequired,
  requestSimulationDatesChange: PropTypes.func.isRequired,
};

DigitalTwin.defaultProps = {
  simulationsList: [],
  supportedVarieties: [],
  currentSimulation: null,
  currentSimulationEdited: null,
  isDataFetching: false,
};

export default injectIntl(DigitalTwin);
