import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { isNil } from 'lodash';

import ProgressBarCircle from '../ProgressBarCircle';
import styles from './ProgressBarCircleWithValue.module.css';

export default class ProgressBarCircleWithValue extends PureComponent {
  static propTypes = {
    percent: PropTypes.number,
    size: PropTypes.oneOf(['M']), // При необходимости добавить новый размер
  };

  static defaultProps = {
    percent: 0,
    size: 'M',
  };

  render() {
    const { size } = this.props;
    let { percent } = this.props;

    if (percent > 100) {
      percent = 100;
    } else if (percent < 0 || isNil(percent)) {
      percent = 0;
    }

    return (
      <div
        className={classnames(
          styles.progressBarCircleWithValueWrapper,
          {
            [styles.medium]: size === 'M'
          }
        )}
      >
        <ProgressBarCircle
          percent={percent}
          strokeWidth={10}
          colorized
        />
        <div className={styles.percentValue}>
          {`${percent}%`}
        </div>
      </div>
    );
  }
}
