import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from 'react-select';
import { get } from 'lodash';
import { ReactComponent as ArrowDown } from './assets/arrow_down.svg';
import { ReactComponent as ArrowDownMini } from './assets/arrow_down_mini.svg';

import styles from './BigSelect.module.css';


export default class BigSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
    labelPath: PropTypes.string,
    valuePath: PropTypes.string,
    mini: PropTypes.bool,
    micro: PropTypes.bool,
    clearable: PropTypes.bool,
    value: PropTypes.any,
    placeholder: PropTypes.any,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    children: PropTypes.object,
  };

  static defaultProps = {
    mini: false,
    micro: false,
    className: '',
    clearable: false,
    onChange: null,
    value: null,
    children: null,
    labelPath: 'attributes.name',
    valuePath: 'id',
    placeholder: '',
  };

  handlerChange = (options) => {
    if (options) {
      const { value } = options;
      const { onChange } = this.props;

      onChange(value);
    } else {
      const { onChange } = this.props;

      onChange(null);
    }
  };

  arrowRender = () => {
    const { mini, micro } = this.props;

    if (mini || micro) {
      return <ArrowDownMini />;
    }
    return <ArrowDown />;
  };

  render() {
    const {
      className,
      clearable,
      options,
      value,
      children,
      labelPath,
      valuePath,
      placeholder,
      mini,
      micro,
      ...props
    } = this.props;

    if (options) {
      const data = options.map(item => ({ label: get(item, labelPath), value: get(item, valuePath) }));

      return (
        <div className={classnames(styles.select, className, { [styles.full]: !!children, [styles.mini]: mini, [styles.micro]: micro })}>
          <Select
            disabled={data.length === 0}
            className={styles.control}
            value={value}
            options={data}
            onChange={this.handlerChange}
            arrowRenderer={this.arrowRender}
            clearable={clearable}
            placeholder={placeholder}
            searchable={false}
            {...props}
          />
          {children}
        </div>
      );
    }
    return null;
  }
}
