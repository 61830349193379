import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker/dist/react-datepicker.min';
import { injectIntl, intlShape } from 'react-intl';

import CustomDatePickerInput from './components/CustomDatePickerInput';

import { ReactComponent as DateIcon } from './assets/date.svg';
import { ReactComponent as NewDateIcon } from './assets/icon.svg';
import styles from './BigDateInput.module.css';

class BigDateInput extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    periodType: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.object,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    theme: PropTypes.string,
    clearable: PropTypes.bool,
    isErrorTextDisable: PropTypes.bool,
    popperPlacement: PropTypes.string,
  };

  static defaultProps = {
    isErrorTextDisable: false,
    onChange: null,
    onClear: null,
    onBlur: null,
    onFocus: null,
    periodType: null,
    value: null,
    placeholder: '',
    wrapperClassName: null,
    className: null,
    disabled: false,
    error: '',
    theme: null,
    clearable: true,
    minDate: moment.tz('UTC')
      .utc()
      .year(2015)
      .month(0)
      .date(1)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0),
    maxDate: moment.tz('UTC')
      .utc()
      .add(5, 'years')
      .hours(23)
      .minutes(59)
      .seconds(0)
      .milliseconds(0),
    popperPlacement: ''
  };

  render() {
    const {
      intl,
      value,
      error,
      placeholder,
      className,
      wrapperClassName,
      onChange,
      onClear,
      onFocus,
      onBlur,
      disabled,
      minDate,
      maxDate,
      periodType,
      theme,
      clearable,
      isErrorTextDisable,
      popperPlacement
    } = this.props;

    const { formatMessage } = intl;

    return (
      <div className={classnames(styles.wrapper, wrapperClassName, { [styles.errored]: !!error, [styles.errorTextDisabled]: isErrorTextDisable })}>
        <DatePicker
          className={classnames(styles.button, className)}
          wrapperClassName={styles.datePickerWrapper}
          dropdownMode='select'
          placeholderText={placeholder}
          utcOffset={0}
          dateFormat={formatMessage ? `${formatMessage({ id: 'date.dayMonth' })}, YYYY` : 'D MMM, YYYY'}
          disabled={disabled}
          ref={(datepicker) => {
            this.datepicker = datepicker;
          }}
          popperPlacement={popperPlacement}
          preventOpenOnFocus
          customInput={(
            <CustomDatePickerInput
              className={classnames(styles.customDatePickerInput)}
              wrapperClassName={styles.customDatePickerInputWrapper}
              icon={theme === 'mini' ? <NewDateIcon className={styles.icon} /> : <DateIcon className={styles.icon} />}
              error={error}
              disabled={disabled}
              dateFormat={formatMessage ? `${formatMessage({ id: 'date.dayMonth' })}, YYYY` : 'D MMM, YYYY'}
              periodType={periodType}
              minDate={minDate}
              maxDate={maxDate}
              container={this}
              theme={theme}
              clearable={clearable}
              onClear={() => { onChange(null); onClear(null); }}
              isErrorTextDisable={isErrorTextDisable}
              onInnerClick={(e) => {
                if (onFocus) {
                  onFocus(e);
                }
              }}
            />
          )}
          selected={!value ? null : value}
          minDate={minDate}
          maxDate={maxDate}
          onClickOutside={() => {
            if (onBlur) {
              onBlur(value);
            }
          }}
          onChange={(innerValue) => {
            if (onChange) {
              onChange(innerValue);
            }

            if (onBlur) {
              onBlur(innerValue);
            }
          }}
          onBlur={(data) => {
            if (onBlur) {
              onBlur(data);
            }
          }}
          onFocus={(data) => {
            if (onFocus) {
              onFocus(data);
            }
          }}
        />
      </div>
    );
  }
}

export default injectIntl(BigDateInput);
