import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';

import { showIntercom } from 'helpers/intercomHelpers';

import PageViewTracker from 'components/PageViewTracker';
import UserInformationForm from 'components/UserInformationForm';
import UserPasswordForm from 'components/UserPasswordForm';
import DefaultPlate from 'components/DefaultPlate';
import BigButton from 'components/BigButton';

import styles from './SettingsProfile.module.css';

export default class SettingsProfile extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    trackPageViewSettings: PropTypes.func.isRequired,
    defaultOpen: PropTypes.bool,
  };

  static defaultProps = {
    defaultOpen: false,
  };

  handlerPageView = () => {
    const { trackPageViewSettings, defaultOpen } = this.props;

    trackPageViewSettings({ settingName: 'Profile', defaultOpen });
  };

  render() {
    const { intl: { formatMessage }, } = this.props;

    return (
      <div className={styles.settingsProfile}>
        <PageViewTracker onMount={this.handlerPageView} />

        <h2 className={styles.header2}>
          {formatMessage({ id: 'settings.profile' })}
        </h2>
        <DefaultPlate className={styles.infoPlate}>
          <div>
            {formatMessage({ id: 'settings.toChangeInformation' })}
            <BigButton
              className={styles.supportButton}
              onClick={showIntercom}
              title={formatMessage({ id: 'harvestDashboard.contactSupport' })}
              theme='transparent'
            />
            .
          </div>
        </DefaultPlate>
        <div className={styles.section}>
          <h3 className={classnames(styles.header3, styles.userInfoHeader)}>
            {formatMessage({ id: 'settings.basicInformation' })}
          </h3>
          <UserInformationForm />
        </div>
        <div className={styles.section}>
          <h3 className={classnames(styles.header3, styles.userPasswordHeader)}>
            {formatMessage({ id: 'login.password' })}
          </h3>
          <UserPasswordForm />
        </div>
      </div>
    );
  }
}
