import PropTypes from 'prop-types';
import React, {
  useCallback, useMemo, useState
} from 'react';
import classnames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';

import { ReactComponent as LessIcon } from './assets/less.svg';
import { ReactComponent as MoreIcon } from './assets/more.svg';

import styles from './ShowMoreButton.module.css';

const ShowMoreButton = ({
  className,
  isExpanded,
  intl,
  onClick,
  style,
  containerRef
}) => {
  const { formatMessage } = intl;
  const [expand, setExpand] = useState(isExpanded);

  const title = useMemo(() =>
    formatMessage({ id: `button.${expand ? 'showLess' : 'showMore'}` }), [expand, formatMessage]);
  const icon = expand ? <LessIcon /> : <MoreIcon />;

  const handleClick = useCallback(() => {
    setExpand(!expand);
    if (onClick) {
      onClick(!expand);
    }
  }, [expand, setExpand, onClick]);

  return (
    <button
      type='button'
      className={classnames(styles.button, className)}
      onClick={handleClick}
      style={style}
      ref={containerRef}
    >
      <span className={styles.title}>{title}</span>{icon}
    </button>
  );
};


ShowMoreButton.propTypes = {
  intl: intlShape.isRequired,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  containerRef: PropTypes.object
};

ShowMoreButton.defaultProps = {
  isExpanded: false,
  onClick: null,
  className: null,
  style: {},
  containerRef: null
};


export default injectIntl(ShowMoreButton);
