import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';

import ArrowDown from 'components/Icons/ArrowDown';
import Checkbox from 'components/Checkbox';
import getFormattedDate from 'components/Measurements/components/Crops/helpers/getFormattedDate';

import styles from './HeaderRow.module.css';

const HeaderRow = ({
  intl,
  descriptor,
  period,
  handleSorting,
  title,
  currentGraphKey,
  onCheckAll,
  enableSorting,
  isSelectAll
}) => {
  const { formatMessage } = intl;
  const { periods } = descriptor;

  const [sorting, setSorting] = useState({ value: null, direction: null });

  const headers = [
    ...periods.map((pValue, index) => ({
      key: index,
      label: getFormattedDate(formatMessage, pValue, period),
      value: pValue.serialNumber,
      className: 'thRight'
    }))
  ];

  const handleClickSorting = useCallback((value) => {
    if (enableSorting) {
      let newSorting = {};
      if (!sorting.direction) {
        newSorting = { value, direction: 'up' };
      } else if (sorting.direction === 'up') {
        newSorting = { value, direction: 'down' };
      } else if (sorting.direction === 'down') {
        newSorting = { value: null, direction: null };
      }
      setSorting(prevState => ({
        ...prevState,
        ...newSorting
      }));
      if (handleSorting) {
        handleSorting(newSorting);
      }
    }
  }, [sorting, setSorting, handleSorting, enableSorting]);

  const CheckBoxMarkerIcon = useMemo(() => {
    if (isSelectAll) {
      return Checkbox.MARKERS.Arrow;
    }
    return currentGraphKey.length > 0 ? Checkbox.MARKERS.Square : Checkbox.MARKERS.Arrow;
  }, [currentGraphKey, isSelectAll]);

  const handleCheckedAll = useCallback(() => {
    onCheckAll();
  }, [onCheckAll]);

  return (
    <tr className={styles.trHead}>
      <th className={classnames(styles.activeTh, styles.sticky)}>
        <Checkbox
          value='all'
          checked={isSelectAll || currentGraphKey.length > 0}
          onChange={handleCheckedAll}
          markerIcon={CheckBoxMarkerIcon}
          noBackgroundColor={!isSelectAll}
          className={styles.checkBox}
        />&nbsp;{title}
      </th>
      {headers.map(item => (
        <th
          key={`tableHeader-${item.value}`}
          onClick={() => handleClickSorting(item.value)}
          className={classnames(styles.activeTh, {
            [styles.activeSort]: sorting.value === item.value,
            [styles[item.className]]: item.className,
          })}
        >
          <div className={styles.thContent}>
            {item.label}
            <div
              className={classnames(styles.arrow, {
                [styles.sortDirectionDown]: sorting.direction === 'down' && sorting.value === item.value
              })}
            >
              <ArrowDown />
            </div>
          </div>
        </th>
      ))}
      <th className={styles.tdPad}>&nbsp;</th>
    </tr>
  );
};

HeaderRow.propTypes = {
  intl: intlShape.isRequired,
  descriptor: PropTypes.object.isRequired,
  period: PropTypes.string.isRequired,
  handleSorting: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  currentGraphKey: PropTypes.array,
  onCheckAll: PropTypes.func,
  enableSorting: PropTypes.bool,
  isSelectAll: PropTypes.bool,
};

HeaderRow.defaultProps = {
  currentGraphKey: [],
  onCheckAll: null,
  enableSorting: false,
  isSelectAll: false
};

export default injectIntl(HeaderRow);
