import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import {
  get, find, reverse, sortBy, filter, capitalize
} from 'lodash';

import NestedTable from 'components/NestedTable';

import updateLocationSearch from 'helpers/updateLocationSearch';
import numbersRounding from 'helpers/numbersRounding';
import numbersFormatting from 'helpers/numbersFormatting';
import sortByLocal from 'helpers/sortByLocal';
import { tableEmptyStateIntervals } from 'helpers/emptyStateInterval';

import { getDate, getDateFromKey } from '../../../LaborWorks/helpers/getDate';

class EnergyTable extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    energyReportSorting: PropTypes.object,
    periodType: PropTypes.string.isRequired,
    graphKey: PropTypes.string.isRequired,
    reportRows: PropTypes.array.isRequired,
    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,

    getRowName: PropTypes.func.isRequired,
    updateEnergyReportSorting: PropTypes.func.isRequired,
  };

  static defaultProps = {
    energyReportSorting: null,
    anyDateOfPeriodStart: null,
    anyDateOfPeriodEnd: null,
  };

  getHeaderItems = (reportRows = []) => {
    const { intl, periodType } = this.props;
    const { formatMessage } = intl;
    const columns = get(reportRows, '[0].data', {});
    const meterLabel = capitalize(formatMessage({ id: 'energy.meter' }));

    return [
      { label: meterLabel, value: 'meter', textAlign: 'left' },

      ...Object.keys(columns).map(key => ({
        label: getDateFromKey({
          dateKey: key,
          intl,
          periodType
        }),
        value: key,
      }))
    ];
  };

  getBodyItems = (reportRows = []) => reportRows.map(tableRow => ({
    ...this.getRowItem(tableRow),
  }));

  getRowItem = (tableRow) => {
    const { getRowName } = this.props;

    return {
      key: tableRow.key,
      label: getRowName(tableRow),
      columns: Object.keys(tableRow.data).map(columnKey => ({
        label: tableRow.data[columnKey] ? numbersFormatting(numbersRounding(tableRow.data[columnKey], 'fixed', 1)) : '–',
      })),

      labelAlign: 'left',
      handlerRowClick: this.handlerGraphKeyChange,
      rowStyle: tableRow.key === 'total' ? 'bold' : 'normal',
      subRows: tableRow.children ? tableRow.children.map(subRow => this.getRowItem(subRow)) : undefined,
    };
  };

  getSorted = (sortingType, rows = []) => {
    const { getRowName } = this.props;

    if (rows.length === 0) {
      return rows;
    }

    if (sortingType === 'meter') {
      const rowsWithNames = rows.map(row => ({ ...row, nameText: getRowName(row, true) }));

      return sortByLocal(rowsWithNames, 'nameText');
    }

    return sortBy(rows, item => get(item, `data.${sortingType}`));
  };

  getSortedReportRows = (sorting, rows = []) => {
    if (rows.length === 0) {
      return rows;
    }

    const sortingType = get(sorting, 'type');
    const sortingDirection = get(sorting, 'direction');

    // Чтобы Total вседа был в конце
    const totalRow = find(rows, { key: 'total' });
    const filteredRows = filter(rows, row => (row.key !== 'total'));

    const sortWithDirection = array => (sortingDirection === 'down' ? reverse(array) : array);

    const sortedFirstLevel = this.getSorted(sortingType, filteredRows);
    const sortedWithDirectionFirstLevel = sortWithDirection(sortedFirstLevel);

    const sortedRows = sortedWithDirectionFirstLevel.map(row => ({
      ...row,

      children: sortWithDirection(this.getSorted(sortingType, row.children)),
    }));

    return [...sortedRows, totalRow];
  };

  handlerGraphKeyChange = (e, graphKey) => {
    e.stopPropagation();

    updateLocationSearch({ graphKey });
  };

  render() {
    const {
      reportRows,
      graphKey,
      intl,
      energyReportSorting,
      updateEnergyReportSorting,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,
    } = this.props;

    const sortedReportRows = this.getSortedReportRows(energyReportSorting, reportRows);

    const {
      headerItems,
      bodyItems
    } = sortedReportRows && sortedReportRows.length > 0 ? {
      headerItems: this.getHeaderItems(sortedReportRows),
      bodyItems: this.getBodyItems(sortedReportRows),
    } : tableEmptyStateIntervals({
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,
      getDate,
      value: 'meter',
      intl,
      labelLocId: 'energy.meter'
    });

    return (
      <NestedTable
        headerItems={headerItems}
        bodyItems={bodyItems}
        selectedRow={graphKey}
        updateSorting={updateEnergyReportSorting}
        tableSorting={energyReportSorting}
        showEmptyState
      />
    );
  }
}

export default injectIntl(EnergyTable);
