import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getAllFruitClasses,
  getAllVarieties,
  getCurrentCompartments,
} from 'store/company/selectors';

import {
  getReportFilters,
} from 'store/harvestForecast/selectors';

import {
  changeReportFilters,
  clearGraphKeys
} from 'store/harvestForecast/actions';

import HarvestForecastModalExport from './HarvestForecastModalExport';

const mapDispatchToProps = {
  changeReportFilters,
  clearGraphKeys,
};

const mapStateToProps = (state, props) => ({
  ...props,

  varieties: getAllVarieties(state, props),
  fruitClasses: getAllFruitClasses(state, props),
  compartments: getCurrentCompartments(state, props),
  filters: getReportFilters(state),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HarvestForecastModalExport));
