
import { MetricDef, NodeComponents } from 'store/graphs/types';

function getPresetMetricKey(
  metric: MetricDef,
  groupId: string
) {
  return `PresetMetricKey-${metric.node[NodeComponents.LocationId]}-${metric.node[NodeComponents.Node] || ''}-${metric.node[NodeComponents.SubNode] || ''}-${groupId}-${metric.node[NodeComponents.MetricId]}-${metric.node[NodeComponents.TreeType]}`;
}

export default getPresetMetricKey;
