import { get } from 'lodash';

import addGetParameters from 'helpers/addGetParameters';

const updateUrl = (history: any, params: any, replace = false) => {
  if (params && Object.keys(params).length > 0) {
    const searchString = addGetParameters(get(history, 'location.search'), params, {});
    const payload = {
      search: searchString// remove first '?' symbol
    };
    if (replace) {
      history.replace(payload);
    } else {
      history.push(payload);
    }
  }
};

export default updateUrl;
