import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './DefaultTable.module.css';

const DefaultTable = React.memo(({
  headerRows, bodyRows, fixedColumn, fixedColumnOnMobile, scrollerClassName, className, small
}) => (
  <div
    className={classnames(
      styles.tableWrapper,
      className,
      {
        [styles.fixedColumn]: fixedColumn,
        [styles.fixedColumnOnMobile]: fixedColumnOnMobile,
        [styles.small]: small,
      }
    )}
  >
    <div className={classnames(styles.tableScroller, scrollerClassName)}>
      <table className={styles.table}>
        {headerRows && (
          <thead>
            {headerRows}
          </thead>
        )}
        {bodyRows && (
          <tbody>
            {bodyRows}
          </tbody>
        )}
      </table>
    </div>
  </div>
));

DefaultTable.propTypes = {
  headerRows: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]),
  bodyRows: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]),
  fixedColumn: PropTypes.bool,
  fixedColumnOnMobile: PropTypes.bool,
  small: PropTypes.bool,
  scrollerClassName: PropTypes.string,
  className: PropTypes.string,
};

DefaultTable.defaultProps = {
  headerRows: null,
  bodyRows: null,
  fixedColumn: false,
  fixedColumnOnMobile: false,
  small: false,
  scrollerClassName: null,
  className: null,
};

export default DefaultTable;
