import { useEffect } from 'react';

import moment from 'moment';

import { axisBottom, select } from 'd3';

import { get, head, last } from 'lodash';
import { calculateTicksForTimeAxis, TICKS_COUNT_ON_MOBILE } from 'helpers/getTicks';

function useXAxis(groupRef, options) {
  const {
    chartParams: {
      m, w, h, x, rX
    },
    xType,
    axisLabels: { x: label },
    xTicks,
    grid,
    verticalGrid,
    tickTimeDisplayFormat,
    yAxisOrientRight,
    xAxisPadding,
    xDomainRange,
    customTicksFunction,
    isPhone,
    sortedData,
    preparedData,
    isOnlyRightAxis
  } = options;
  useEffect(() => {
    const group = groupRef.current ? select(groupRef.current) : null;
    if (group) {
      const axis = axisBottom(isOnlyRightAxis ? rX : x);

      const tickPadding = xAxisPadding || 15;

      if (grid && verticalGrid) {
        axis
          .tickSize(-h, 6)
          .tickPadding(tickPadding);
      } else {
        axis
          .tickSize(-5)
          .tickPadding(tickPadding);
      }
      // Берём начальную и конечную точки дат
      const xFrom = get(head(sortedData), 'x');
      const xTo = get(last(sortedData), 'x');

      if (xType === 'time' && tickTimeDisplayFormat && xFrom && xTo) {
        const ticksCount = isPhone ? TICKS_COUNT_ON_MOBILE : xTicks;

        const xFromValue = xDomainRange ? moment.utc(xDomainRange[0], 'MM-DD-YYYY') : xFrom;
        const xToValue = xDomainRange ? moment.utc(xDomainRange[1], 'MM-DD-YYYY') : xTo;

        const ticksFunction = customTicksFunction ?
          customTicksFunction(xFromValue, xToValue, ticksCount, preparedData)
          :
          calculateTicksForTimeAxis(xFromValue, xToValue, ticksCount);

        axis
          .tickFormat(tickTimeDisplayFormat)
          .ticks(ticksFunction);
      } else if (xTicks) {
        axis.ticks(xTicks);
      }

      group
        .call(axis);

      if (label) {
        group
          .append('text')
          .attr('class', 'label')
          .attr('y', m.bottom - 10)
          .attr('x', yAxisOrientRight ? 0 : w)
          .style(
            'text-anchor',
            (yAxisOrientRight)
              ? 'start'
              : 'end'
          )
          .text(label);
      }
    }
  }, [
    groupRef,
    m, w, h, x, rX,
    xType,
    label,
    xTicks,
    grid,
    verticalGrid,
    tickTimeDisplayFormat,
    yAxisOrientRight,
    xAxisPadding,
    xDomainRange,
    customTicksFunction,
    isPhone,
    sortedData,
    preparedData,
    isOnlyRightAxis
  ]);
}

export default useXAxis;
