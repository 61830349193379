import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

// @ts-ignore
import moment from 'moment-timezone';

import { Action } from 'redux-actions';

// @ts-ignore
import queryString from 'query-string';

import { cloneDeep } from 'lodash';

import nodeMetricsToUrlParams from 'components/Graphs/utils/nodeMetricsToUrlParams';
import addMetricsToPreset from 'components/Graphs/utils/addMetricsToPreset';
import updateUrl from 'components/Graphs/utils/updateUrl';

import getDatesRange from 'helpers/getDatesRange';
import getGraphThreshold from 'helpers/getGraphThreshold';


import {
  ICreatePresetRequest,
  IDeletePresetRequest,
  IGetGraphDataPointsRequest,
  IGetGraphPresetRequest,
  IUpdatePresetRequest,
  IGetGraphPresetResponse,
  IChangeGraphPeriodRange,
  IChangeGraphPeriodThreshold,
  changeGraphPeriodRange,
  changeGraphPeriodThreshold,
  clearRangeHistory,
  clearRangeHistorySuccess,
  popRangeHistory,
  popRangeHistorySuccess,
  requestCreateGraphPreset,
  requestDeleteGraphPreset,
  requestGetGraphDataPoints,
  requestGetGraphPreset,
  requestGetGraphPresets,
  requestGetMetrics,
  requestUpdateGraphPreset,
  responseCreateGraphPreset,
  responseDeleteGraphPreset,
  responseGetGraphDataPoints,
  responseGetGraphPreset,
  responseGetGraphPresets,
  responseGetMetrics,
  responseUpdateGraphPreset,
  changePresetMetricGroup,
  updateNodeMetricUrl,
  deletePresetMetric,
  togglePresetMetric,
  deletePresetMetricGroup,
  updatePresetDataFromUrl,
  IUpdatePresetDataFromUrl,
  setPresetMetricSelected,
  setAllPresetMetricSelected,
  setMetricColor,
  setMetricToScale,
  changeScale,
  deleteScale,
  clearWorkspace,
  setShowIncidents,
  ISetShowIncidents,
  setActiveIncidentId,
  ISetActiveIncidentId,
  changeGraphPeriodAndThreshold,
  IChangeGraphPeriodAndThreshold,
  requestNewIncidentId,
  setCreateIncidentId,
  checkNewIncidentStatus,
  requestMetricsTree,
  IRequestMetricsTree,
  responseMetricsTree,
  setMetricsPanelFilters,
  ISetMetricsPanelFilters,
  receiveMetricsPanelSearchResult,
  setTreeSearchData, setAvailableNodeIds, requestFullTreeData, patchMetricsTree,
} from './actions';

import initAxiosInstanse from '../../api/axios';

import {
  Incident, IncidentDiagramStateItem, MetricDef
} from './types';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

import history from '../../history/history';

import getGraphPointsPayload from '../../components/Graphs/utils/getGraphPointsPayload';


// eslint-disable-next-line import/prefer-default-export
const getMetricsEpic = (action$: any, store: any) =>
  action$.ofType(requestGetMetrics)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const searchParams = router?.location?.search;
      const { id: organizationId } = location;

      const queryParams = queryString.stringify({
        includeUnused: true
      });

      const query = queryParams || '';

      return Observable
        // @ts-ignore
        .from(initAxiosInstanse(searchParams).get(`locations/${organizationId}/metrics?${query}`))
        .map((response: any) => responseGetMetrics({
          metrics: response?.data?.data || []
        }))
        .catch(() => Observable.of(
          responseGetMetrics({
            metrics: []
          }),
          // @ts-ignore
          showNotificationWithTimeout({
            id: `notifications.getMetrics.${Date.now()}`,
            messageId: 'notifications.getMetricsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

const getGraphPresetsEpic = (action$: any, store: any) =>
  action$.ofType(requestGetGraphPresets)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const searchParams = router?.location?.search;

      return Observable
        // @ts-ignore
        .from(initAxiosInstanse(searchParams).get(`graph/${location.id}/presets`))
        .map((response: any) => responseGetGraphPresets({
          presets: response.data,
        }))
        .catch(() => Observable.of(
          responseGetGraphPresets({
            presets: []
          }),
          // @ts-ignore
          showNotificationWithTimeout({
            id: `notifications.getGraphPresets.${Date.now()}`,
            messageId: 'notifications.getGraphPresetsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });


const getGraphPresetEpic = (action$: any, store: any) =>
  action$.ofType(requestGetGraphPreset)
    .switchMap((actionData: Action<IGetGraphPresetRequest>) => {
      const {
        router,
        company,
        graphs
      } = store.getState();

      const {
        location,
      } = company;

      const searchParams = router?.location?.search;

      const {
        payload
      } = actionData;

      const metrics = graphs?.metrics;

      const requests = [
        // @ts-ignore
        initAxiosInstanse(searchParams).get(`graph/${location.id}/presets/${payload.id}`)
      ];

      return Observable
        .forkJoin(requests)
        .mergeMap((response: any) => {
          const preset = response[0].data;

          preset.preset.presetMetrics = preset.preset.presetMetrics.map((metric:any) => ({
            ...metric,
            available: true
          }));

          const originalPreset = cloneDeep(preset);

          const scales = payload?.scales || [];
          if (payload?.presetMetrics?.length) {
            preset.preset.presetMetrics = [];
            for (let metricIndex = 0; metricIndex < payload?.presetMetrics?.length; metricIndex += 1) {
              const presetMetric = payload?.presetMetrics[metricIndex];
              const scale = presetMetric?.scale === null ?
                null : scales.find(item => item.name === presetMetric?.scale) || null;
              presetMetric.available = true;
              addMetricsToPreset(preset, {
                presetMetric,
                scale
              }, metrics);
            }
          }

          const nodes = preset?.preset?.presetMetrics?.map((metric:MetricDef) => [...metric.node]) || [];

          const rangePayload = {
            xRange: payload?.range?.xRange || preset?.preset?.xRange || null,
            xRangeEnd: payload?.range?.xRangeEnd || preset?.preset?.xRangeEnd || null,
            xRangeLengthInMins: payload?.range?.xRangeLengthInMins || preset?.preset?.xRangeLengthInMins || null,
          };

          const { xTo, xFrom } = getDatesRange({
            ...rangePayload,
            location,
          });

          const scaleAsRoundToNMinutes = payload?.threshold ? payload?.threshold :
            getGraphThreshold({
              xRange: preset?.preset?.xRange,
              xRangeEnd: preset?.preset?.xRangeEnd,
              xRangeLengthInMins: preset?.preset?.xRangeLengthInMins,
              location,
            });

          const observables = [];

          originalPreset.preset.xRange = rangePayload.xRange;
          originalPreset.preset.xRangeEnd = rangePayload.xRangeEnd;
          originalPreset.preset.xRangeLengthInMins = rangePayload.xRangeLengthInMins;

          preset.preset.xRange = rangePayload.xRange;
          preset.preset.xRangeEnd = rangePayload.xRangeEnd;
          preset.preset.xRangeLengthInMins = rangePayload.xRangeLengthInMins;

          observables.push(
            responseGetGraphPreset({
              preset,
              originalPreset,
              preventUpdatePresetIdInUrl: payload?.preventUpdatePresetIdInUrl
            })
          );

          if (payload?.threshold) {
            observables.push(
              changeGraphPeriodThreshold({
                threshold: payload?.threshold,
                preventHistory: true
              })
            );
          }

          if (payload?.activeIncidentId) {
            observables.push(
              setShowIncidents({
                returnAbnormalityIncidents: true,
                preventHistory: true
              })
            );
            observables.push(
              setActiveIncidentId({
                activeIncidentId: payload?.activeIncidentId,
                preventHistory: true
              })
            );
          } else {
            observables.push(
              setShowIncidents({
                returnAbnormalityIncidents: !!payload?.returnAbnormalityIncidents,
                preventHistory: true
              })
            );
          }
          observables.push(
            requestGetGraphDataPoints({
              includeMetrics: true,
              nodes,
              returnIncidents: 'all',
              scaleAsRoundToNMinutes,
              xFrom: Math.round(+xFrom / 60 / 1000),
              xTo: Math.round(+xTo / 60 / 1000),
              showFirstIncident: payload?.showFirstIncident
            }),
          );
          return observables.length > 0 ? Observable.of(...observables) : Observable.empty();
        })
        .catch(() => Observable.of(
          responseGetGraphPreset({
            preset: null
          }),
          // @ts-ignore
          showNotificationWithTimeout({
            id: `notifications.getGraphPreset.${Date.now()}`,
            messageId: 'notifications.getGraphPresetError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });


const createGraphPresetEpic = (action$: any, store: any) =>
  action$.ofType(requestCreateGraphPreset)
    .switchMap((actionData: Action<ICreatePresetRequest>) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const searchParams = router?.location?.search;
      return Observable
        // @ts-ignore
        .from(initAxiosInstanse(searchParams).post(
          `graph/${location.id}/presets`,
          actionData.payload.preset,
        ))
        .mergeMap((response: any) => Observable.of(
          responseCreateGraphPreset({
            preset: response.data,
          }),
          // @ts-ignore
          requestGetGraphPresets(),
          requestGetGraphPreset({ id: response.data.preset.id })
        ))
        .catch(() => Observable.of(
          responseCreateGraphPreset({
            preset: null
          }),
          // @ts-ignore
          showNotificationWithTimeout({
            id: `notifications.createGraphPreset.${Date.now()}`,
            messageId: 'notifications.createGraphPresetError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

const updateGraphPresetEpic = (action$: any, store: any) =>
  action$.ofType(requestUpdateGraphPreset)
    .switchMap((actionData: Action<IUpdatePresetRequest>) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const searchParams = router?.location?.search;

      return Observable
        // @ts-ignore
        .from(initAxiosInstanse(searchParams).put(
          `graph/${location.id}/presets/${actionData.payload.preset.id}`,
          actionData.payload.preset,
        ))
        .mergeMap((response: any) => Observable.of(
          responseUpdateGraphPreset({
            preset: response.data,
          }),
          // @ts-ignore
          requestGetGraphPresets(),
          requestGetGraphPreset({ id: response.data.preset.id })
        ))
        .catch(() => Observable.of(
          responseUpdateGraphPreset({
            preset: null
          }),
          // @ts-ignore
          showNotificationWithTimeout({
            id: `notifications.updateGraphPreset.${Date.now()}`,
            messageId: 'notifications.updateGraphPresetError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

const deleteGraphPresetEpic = (action$: any, store: any) =>
  action$.ofType(requestDeleteGraphPreset)
    .switchMap((actionData: Action<IDeletePresetRequest>) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const searchParams = router?.location?.search;
      return Observable
        // @ts-ignore
        .from(initAxiosInstanse(searchParams).delete(
          `graph/${location.id}/presets/${actionData.payload.presetId}`,
        ))
        .mergeMap(() => Observable.of(
          responseDeleteGraphPreset(null),
          // @ts-ignore
          requestGetGraphPresets(),
          // @ts-ignore
          clearWorkspace(),
        ))
        .catch(() => Observable.of(
          responseDeleteGraphPreset(null),
          // @ts-ignore
          showNotificationWithTimeout({
            id: `notifications.deleteGraphPreset.${Date.now()}`,
            messageId: 'notifications.deleteGraphPresetError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

const getGraphDataPointsEpic = (action$: any, store: any) =>
  action$.ofType(requestGetGraphDataPoints)
    .switchMap((actionData: Action<IGetGraphDataPointsRequest>) => {
      const {
        router,
        company
      } = store.getState();
      const {
        location,
      } = company;
      const searchParams = router?.location?.search;
      return Observable
        // TODO: Та же ли это ручка, что и graph-data-points? Нужен ли тут таймаут 30000 или оставить дефолтный?
        // @ts-ignore
        .from(initAxiosInstanse(searchParams, 30000).post(`graph/${location.id}/data`, actionData.payload))
        .mergeMap((response: any) => {
          const observables = [
            responseGetGraphDataPoints(response.data)
          ];
          if (actionData.payload.showFirstIncident) {
            const { incidents } = response.data;
            const activeIncident = incidents.length > 0 ? incidents[0] : null;
            if (activeIncident) {
              observables.push(
                // @ts-ignore
                setActiveIncidentId({
                  activeIncidentId: activeIncident.id,
                  preventHistory: true
                })
              );
            }
          }
          return Observable.of(...observables);
        })
        .catch(() => Observable.of(
          responseGetGraphDataPoints({
            data: [],
            incidents: [],
            metrics: []
          }),
          // @ts-ignore
          showNotificationWithTimeout({
            id: `notifications.getGraphDataPoints.${Date.now()}`,
            messageId: 'notifications.getGraphDataPointsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

const popRangeHistoryEpic = (action$: any, store: any) =>
  action$.ofType(popRangeHistory)
    .switchMap(() => {
      const {
        graphs,
        company
      } = store.getState();
      const {
        location,
      } = company;
      const rangeHistory = [...graphs.rangeHistory];
      const historyEntity = rangeHistory[rangeHistory.length - 1];
      return Observable.of(
        changeGraphPeriodRange({
          range: historyEntity.range,
          preventHistory: false
        }),
        // @ts-ignore
        changeGraphPeriodThreshold({
          threshold: historyEntity.threshold,
          preventHistory: false
        }),
        // @ts-ignore
        requestGetGraphDataPoints(
          getGraphPointsPayload(graphs.editedPreset, historyEntity.range, location, historyEntity.threshold)
        ),
        // @ts-ignore
        popRangeHistorySuccess(),
      );
    });

const clearRangeHistoryEpic = (action$: any, store: any) =>
  action$.ofType(clearRangeHistory)
    .switchMap(() => {
      const {
        graphs,
        company
      } = store.getState();
      const {
        location,
      } = company;
      const rangeHistory = [...graphs.rangeHistory];
      if (rangeHistory.length === 0) {
        return Observable.empty();
      }
      const historyEntity = rangeHistory[0];
      return Observable.of(
        changeGraphPeriodRange({
          range: historyEntity.range,
          preventHistory: false
        }),
        // @ts-ignore
        changeGraphPeriodThreshold({
          threshold: historyEntity.threshold,
          preventHistory: false
        }),
        // @ts-ignore
        requestGetGraphDataPoints(
          getGraphPointsPayload(graphs.editedPreset, historyEntity.range, location, historyEntity.threshold)
        ),
        // @ts-ignore
        clearRangeHistorySuccess(),
      );
    });

const changePresetMetricGroupEpic = (action$: any, store: any) =>
  action$.ofType(changePresetMetricGroup)
    .switchMap((/* actionData: Action<IChangePresetMetricGroup> */) => {
      const {
        graphs,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const scaleAsRoundToNMinutes = graphs?.threshold ? graphs?.threshold :
        getGraphThreshold({
          xRange: graphs.editedPreset?.preset?.xRange,
          xRangeEnd: graphs.editedPreset?.preset?.xRangeEnd,
          xRangeLengthInMins: graphs.editedPreset?.preset?.xRangeLengthInMins,
          location,
        });

      return Observable.of(
        updateNodeMetricUrl(null),
        // @ts-ignore
        requestGetGraphDataPoints(
          getGraphPointsPayload(graphs.editedPreset, graphs.editedPreset.preset, location, scaleAsRoundToNMinutes)
        ),
      );
    });

const deletePresetMetricEpic = (action$: any) =>
  action$.ofType(deletePresetMetric)
    .switchMap(() => Observable.of(updateNodeMetricUrl(null)));

const togglePresetMetricEpic = (action$: any, store: any) =>
  action$.ofType(togglePresetMetric)
    .switchMap(() => {
      const {
        graphs,
        company
      } = store.getState();
      const {
        location,
      } = company;

      const scaleAsRoundToNMinutes = graphs?.threshold ? graphs?.threshold :
        getGraphThreshold({
          xRange: graphs.editedPreset?.preset?.xRange,
          xRangeEnd: graphs.editedPreset?.preset?.xRangeEnd,
          xRangeLengthInMins: graphs.editedPreset?.preset?.xRangeLengthInMins,
          location,
        });
      return Observable.of(
        updateNodeMetricUrl(null),
        // @ts-ignore
        requestGetGraphDataPoints(
          getGraphPointsPayload(graphs.editedPreset, graphs.editedPreset.preset, location, scaleAsRoundToNMinutes)
        ),
      );
    });


const deletePresetMetricGroupEpic = (action$: any) =>
  action$.ofType(deletePresetMetricGroup)
    .switchMap(() => Observable.of(updateNodeMetricUrl(null)));

const updateNodeMetricUrlEpic = (action$: any, store: any) =>
  action$.ofType(updateNodeMetricUrl)
    .switchMap(() => {
      const {
        graphs,
      } = store.getState();
      const nmParams = nodeMetricsToUrlParams(graphs.editedPreset);
      updateUrl(history, { nm: nmParams || undefined });
      return Observable.empty();
    });

const responseGetGraphPresetEpic = (action$: any) =>
  action$.ofType(responseGetGraphPreset)
    .switchMap((actionData: Action<IGetGraphPresetResponse>) => {
      const {
        payload
      } = actionData;
      if (!payload?.preventUpdatePresetIdInUrl && payload?.preset?.preset?.id) {
        updateUrl(history, {
          graphPreset: payload?.preset?.preset?.id,
          xRange: undefined,
          xRangeEnd: undefined,
          xRangeLengthInMins: undefined,
          ticks: undefined,
          nm: undefined,
          returnAbnormalityIncidents: undefined,
          activeIncidentId: undefined,
          showFirstIncident: undefined,
          metricId: undefined,
          compartmentId: undefined,
          categoryId: undefined,
          categoryCode: undefined,
          species: undefined,
          varietyId: undefined,
          fruitClassCode: undefined
        });
      }
      return Observable.empty();
    });

const changeGraphPeriodRangeEpic = (action$: any) =>
  action$.ofType(changeGraphPeriodRange)
    .switchMap((actionData: Action<IChangeGraphPeriodRange>) => {
      const {
        payload
      } = actionData;
      if (payload?.range && !payload.preventHistory) {
        updateUrl(history, {
          xRange: payload?.range?.xRange || undefined,
          xRangeEnd: payload?.range?.xRangeEnd ?
            moment.tz(payload?.range?.xRangeEnd, 'YYYY-MM-DDTHH:mm:ss', 'UTC').format('x') : undefined,
          xRangeLengthInMins: payload?.range?.xRangeLengthInMins || undefined,
        });
      }
      return Observable.empty();
    });

const changeGraphPeriodThresholdEpic = (action$: any) =>
  action$.ofType(changeGraphPeriodThreshold)
    .switchMap((actionData: Action<IChangeGraphPeriodThreshold>) => {
      const {
        payload
      } = actionData;
      if (!payload.preventHistory) {
        updateUrl(history, { ticks: payload.threshold || undefined });
      }
      return Observable.empty();
    });

const changeGraphPeriodAndThresholdEpic = (action$: any) =>
  action$.ofType(changeGraphPeriodAndThreshold)
    .switchMap((actionData: Action<IChangeGraphPeriodAndThreshold>) => {
      const {
        payload
      } = actionData;
      if (payload?.range && !payload.preventHistory) {
        const historyPayload = {
          ticks: payload.threshold || undefined
        };
        if (payload?.range) {
          Object.assign(historyPayload, {
            xRange: payload?.range?.xRange || undefined,
            xRangeEnd: payload?.range?.xRangeEnd ?
              moment.tz(payload?.range?.xRangeEnd, 'YYYY-MM-DDTHH:mm:ss', 'UTC').format('x') : undefined,
            xRangeLengthInMins: payload?.range?.xRangeLengthInMins || undefined,
          });
        }
        updateUrl(history, historyPayload);
      }
      return Observable.empty();
    });

const updatePresetDataFromUrlEpic = (action$: any, store:any) =>
  action$.ofType(updatePresetDataFromUrl)
    .switchMap((actionData: Action<IUpdatePresetDataFromUrl>) => {
      const {
        company,
        graphs
      } = store.getState();

      const {
        location,
      } = company;

      const {
        payload
      } = actionData;

      const {
        editedPreset
      } = graphs;

     // const searchParams = router?.location?.search;

      const rangePayload = {
        xRange: payload?.range?.xRange || editedPreset?.preset?.xRange || null,
        xRangeEnd: payload?.range?.xRangeEnd || editedPreset?.preset?.xRangeEnd || null,
        xRangeLengthInMins: payload?.range?.xRangeLengthInMins || editedPreset?.preset?.xRangeLengthInMins || null,
      };

      // @ts-ignore
      const { xTo, xFrom } = getDatesRange({
        ...rangePayload,
        location,
      });

      const nodes = payload?.presetMetrics?.map((metric:MetricDef) => [...metric.node]) || [];

      const scaleAsRoundToNMinutes = payload?.threshold ? +payload?.threshold : getGraphThreshold({
        ...rangePayload,
        location,
      });

      const observables = [];

      observables.push(
        requestGetGraphDataPoints({
          includeMetrics: true,
          nodes,
          returnIncidents: 'all',
          // @ts-ignore
          scaleAsRoundToNMinutes,
          xFrom: Math.round(+xFrom / 60 / 1000),
          xTo: Math.round(+xTo / 60 / 1000),
          showFirstIncident: payload?.showFirstIncident
        })
      );

      if (payload?.range) {
        observables.push(
          changeGraphPeriodRange({
            range: payload?.range,
            preventHistory: true
          })
        );
      }

      if (scaleAsRoundToNMinutes) {
        observables.push(
          changeGraphPeriodThreshold({
            threshold: scaleAsRoundToNMinutes,
            preventHistory: true
          })
        );
      }

      if (payload?.activeIncidentId) {
        observables.push(
          setShowIncidents({
            returnAbnormalityIncidents: true,
            preventHistory: true
          })
        );

        observables.push(
          setActiveIncidentId({
            activeIncidentId: payload?.activeIncidentId,
            preventHistory: true
          })
        );
      } else {
        observables.push(
          setShowIncidents({
            returnAbnormalityIncidents: !!payload?.returnAbnormalityIncidents,
            preventHistory: true
          })
        );
      }

      return observables.length > 0 ? Observable.of(...observables) : Observable.empty();
    });

const setPresetMetricSelectedEpic = (action$: any) =>
  action$.ofType(setPresetMetricSelected)
    .switchMap(() => Observable.of(updateNodeMetricUrl(null)));

const setAllPresetMetricSelectedEpic = (action$: any) =>
  action$.ofType(setAllPresetMetricSelected)
    .switchMap(() => Observable.of(updateNodeMetricUrl(null)));

const setMetricColorEpic = (action$: any) =>
  action$.ofType(setMetricColor)
    .switchMap(() => Observable.of(updateNodeMetricUrl(null)));

const setMetricToScaleEpic = (action$: any) =>
  action$.ofType(setMetricToScale)
    .switchMap(() => Observable.of(updateNodeMetricUrl(null)));

const changeScaleEpic = (action$: any) =>
  action$.ofType(changeScale)
    .switchMap(() => Observable.of(updateNodeMetricUrl(null)));

const deleteScaleEpic = (action$: any) =>
  action$.ofType(deleteScale)
    .switchMap(() => Observable.of(updateNodeMetricUrl(null)));

const clearWorkspaceEpic = (action$: any) =>
  action$.ofType(clearWorkspace)
    .switchMap(() => {
      updateUrl(history, {
        graphPreset: undefined,
        xRange: undefined,
        xRangeEnd: undefined,
        xRangeLengthInMins: undefined,
        ticks: undefined,
        nm: undefined,
        returnAbnormalityIncidents: undefined,
        activeIncidentId: undefined,
        showFirstIncident: undefined,
        metricId: undefined,
        compartmentId: undefined,
        categoryId: undefined,
        categoryCode: undefined,
        species: undefined,
        varietyId: undefined,
        fruitClassCode: undefined
      });
      return Observable.empty();
    });

const setShowIncidentsEpic = (action$: any) =>
  action$.ofType(setShowIncidents)
    .switchMap((actionData: Action<ISetShowIncidents>) => {
      const {
        payload
      } = actionData;
      if (!payload.preventHistory) {
        updateUrl(history, {
          returnAbnormalityIncidents: payload.returnAbnormalityIncidents ? '1' : undefined
        });
      }
      return Observable.empty();
    });

const setActiveIncidentIdEpic = (action$: any, store:any) =>
  action$.ofType(setActiveIncidentId)
    .switchMap((actionData: Action<ISetActiveIncidentId>) => {
      const {
        graphs,
        company
      } = store.getState();
      const {
        location,
      } = company;
      const {
        payload
      } = actionData;
      const {
        dataPoints,
        editedPreset,
        threshold,
        metrics
      } = graphs;
      const observables: any[] = [];
      const urlPayload = {};
      const incidents = dataPoints?.incidents || [];
      const selectedIncident = incidents.find((incident:Incident) =>
        incident.id === payload.activeIncidentId);
      if (selectedIncident && editedPreset) {
        const presetCopy = cloneDeep(editedPreset);
        presetCopy.preset.presetMetrics = [];
        for (let i = 0; i < selectedIncident?.diagramState?.length; i += 1) {
          const dsItem: IncidentDiagramStateItem = selectedIncident?.diagramState[i];
          addMetricsToPreset(presetCopy, {
            presetMetric: {
              selected: dsItem.selected,
              available: true,
              scale: null,
              color: null,
              node: [...dsItem.node]
            },
            scale: null,
          }, metrics);
        }
        const {
          preset: {
            presetMetrics,
          }
        } = presetCopy;

        const { xTo, xFrom } = getDatesRange({
          xRange: presetCopy?.preset?.xRange || undefined,
          xRangeEnd: presetCopy?.preset?.xRangeEnd || undefined,
          xRangeLengthInMins: presetCopy?.preset?.xRangeLengthInMins || undefined,
          location,
        });

        const scaleAsRoundToNMinutes = threshold ||
          getGraphThreshold({
            xRange: editedPreset?.preset?.xRange || undefined,
            xRangeEnd: editedPreset?.preset?.xRangeEnd || undefined,
            xRangeLengthInMins: editedPreset?.preset?.xRangeLengthInMins || undefined,
            location,
          });

        observables.push(
          requestGetGraphDataPoints({
            includeMetrics: true,
            nodes: presetMetrics.map((metric:MetricDef) => [...metric.node]),
            returnIncidents: 'all',
            scaleAsRoundToNMinutes,
            xFrom: Math.round(+xFrom / 60 / 1000),
            xTo: Math.round(+xTo / 60 / 1000),
            showFirstIncident: false
          }),
        );

        const nms = nodeMetricsToUrlParams(presetCopy);
        Object.assign(urlPayload, {
          nm: nms || undefined
        });

        observables.push(
          responseUpdateGraphPreset({
            preset: presetCopy,
          })
        );
      }

      if (!payload.preventHistory) {
        Object.assign(urlPayload, {
          activeIncidentId: payload.activeIncidentId || undefined
        });
        updateUrl(history, urlPayload);
      }

      return observables.length > 0 ? Observable.of(...observables) : Observable.empty();
    });


const requestNewIncidentIdEpic = (action$: any, store:any) =>
  action$.ofType(requestNewIncidentId)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const { id: organizationId } = location;
      const searchParams = router?.location?.search;

      return Observable
        // @ts-ignore
        .from(initAxiosInstanse(searchParams).get(`locations/${organizationId}/incident/new-id`))
        .map((response: any) => setCreateIncidentId({
          id: response.data.id
        }))
        .catch(() => Observable.of(
          setCreateIncidentId({
            id: null
          }),
          // @ts-ignore
          showNotificationWithTimeout({
            id: `notifications.requestNewIncidentId.${Date.now()}`,
            messageId: 'notifications.requestNewIncidentIdError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });


const setCreateIncidentIdEpic = (action$: any, store:any) =>
  action$.ofType(setCreateIncidentId)
    .switchMap(() => {
      const {
        graphs,
      } = store.getState();

      const {
        createIncident: {
          id,
          timeRange
        },
        editedPreset
      } = graphs;

      const nms = nodeMetricsToUrlParams(editedPreset);

      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      window.open(`https://admin${window.__DEV__ ? '-dev' : ''}.pylot.app/incident/${id}?start=${+moment.tz(timeRange.start, 'YYYY-MM-DDTHH:mm:ss', 'UTC')}&end=${+moment.tz(timeRange.end, 'YYYY-MM-DDTHH:mm:ss', 'UTC')}${nms.map(nm => `&nm=${nm}`)}`, `Pylot ${window.__DEV__ ? 'Dev' : ''} Admin`);

      return Observable.empty();
    });

const checkNewIncidentStatusEpic = (action$: any, store:any) =>
  action$.ofType(checkNewIncidentStatus)
    .switchMap(() => {
      const {
        company,
        graphs,
      } = store.getState();

      const {
        editedPreset,
        threshold
      } = graphs;

      const {
        location,
      } = company;

      if (!editedPreset) {
        return Observable.empty();
      }

      // @ts-ignore
      const { xTo, xFrom } = getDatesRange({
        xRange: editedPreset?.preset?.xRange || undefined,
        xRangeEnd: editedPreset?.preset?.xRangeEnd || undefined,
        xRangeLengthInMins: editedPreset?.preset?.xRangeLengthInMins || undefined,
        location,
      });

      const nodes = editedPreset?.preset?.presetMetrics?.map((metric:MetricDef) => [...metric.node]) || [];

      const scaleAsRoundToNMinutes = threshold ||
        getGraphThreshold({
          xRange: editedPreset?.preset?.xRange || undefined,
          xRangeEnd: editedPreset?.preset?.xRangeEnd || undefined,
          xRangeLengthInMins: editedPreset?.preset?.xRangeLengthInMins || undefined,
          location,
        });

      return Observable.of(
        requestGetGraphDataPoints({
          includeMetrics: true,
          nodes,
          returnIncidents: 'all',
          // @ts-ignore
          scaleAsRoundToNMinutes,
          xFrom: Math.round(+xFrom / 60 / 1000),
          xTo: Math.round(+xTo / 60 / 1000)
        })
      );
    });

const requestFullTreeDataEpic = (action$: any, store:any) =>
  action$.ofType(requestFullTreeData)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const { id: organizationId } = location;

      const searchParams = router?.location?.search;

      const path = `graph/${organizationId}/tree`;

      return Observable
        // @ts-ignore
        .from(initAxiosInstanse(searchParams).get(path))
        .mergeMap((response: any) => {
          const rootNode = response.data.node;
          const observables = [];

          const availableIds = {
            plantingCycles: [],
            species: [],
            compartments: [],
            subNodes: [],
          };

          const gTree = rootNode.children.find((n:any) => n.entity.id === 'g');
          availableIds.compartments = gTree.children.map((c:any) => c.entity.id).filter((id:number) => id !== 0);

          for (let i = 0; i < gTree.children.length; i += 1) {
            const children = gTree.children[i].children || [];
            // @ts-ignore
            availableIds.subNodes.push(...children.map((c:any) => c.entity.id).filter((id:number) => id !== 0));
          }

          const cTree = rootNode.children.find((n:any) => n.entity.id === 'c');
          availableIds.species = cTree.children.map((s:any) => s.entity.id);

          for (let i = 0; i < cTree.children.length; i += 1) {
            const children = cTree.children[i].children || [];
            // @ts-ignore
            availableIds.plantingCycles.push(...children.map((c:any) => c.entity.id).filter((id:number) => id !== 0));
          }
          observables.push(
            setAvailableNodeIds({
              ...availableIds
            })
          );

          return observables.length > 0 ? Observable.of(...observables) : Observable.empty();
        })
        .catch(() => Observable.of(
          // responseMetricsTree(null),
          // @ts-ignore
          showNotificationWithTimeout({
            id: `notifications.requestFullTreeData.${Date.now()}`,
            messageId: 'notifications.requestFullTreeDataError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

const requestMetricsTreeEpic = (action$: any, store:any) =>
  action$.ofType(requestMetricsTree)
    .switchMap((actionData: Action<IRequestMetricsTree>) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const { id: organizationId } = location;

      const searchParams = router?.location?.search;

      const {
        ids,
        actionSuccess,
      } = actionData.payload;

      const path = `graph/${organizationId}/tree`;

      const queryParams = queryString.stringify({
        metricId: actionData.payload?.metricId,
        compartmentId: actionData.payload?.compartmentId,
        categoryId: actionData.payload?.categoryId,
        categoryCode: actionData.payload?.categoryCode,
        species: actionData.payload?.species,
        varietyId: actionData.payload?.varietyId,
        fruitClassCode: actionData.payload?.fruitClassCode,
      });

      const query = queryParams ? `?${queryParams}` : '';

      return Observable
        // @ts-ignore
        .from(initAxiosInstanse(searchParams).get(ids ? `${path}/${ids}${query}` : `${path}${query}`))
        .mergeMap((response: any) => {
          const rootNode = response.data.node;
          const observables = [];
          // TODO: Path tree
          /*
            Call path tree action
          * */
          if (actionData.payload?.patchTree) {
            observables.push(patchMetricsTree(rootNode));
          } else if (!actionData.payload?.preventStateUpdate) {
            observables.push(responseMetricsTree(rootNode));
          }
          if (response.data.search) {
            observables.push(setTreeSearchData(response.data.search));
          }
          if (actionSuccess) {
            observables.push(() => actionSuccess(ids, rootNode),);
          }
          return observables.length > 0 ? Observable.of(...observables) : Observable.empty();
        })
        .catch(() => Observable.of(
          responseMetricsTree(null),
          // @ts-ignore
          () => actionSuccess(ids, null),
          // @ts-ignore
          showNotificationWithTimeout({
            id: `notifications.requestMetricsTree.${Date.now()}`,
            messageId: 'notifications.requestMetricsTreeError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });


const setMetricsPanelFiltersEpic = (action$: any, store:any) =>
  action$.ofType(setMetricsPanelFilters)
    .switchMap((actionData: Action<ISetMetricsPanelFilters>) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const { id: organizationId } = location;
      const searchParams = router?.location?.search;

      const limit = actionData.payload.limit || 10;
      const term = actionData.payload.term || '';

      if ((Number.isInteger(Number.parseInt(term, 10)) ||
        term.trim().length > 2)) {
        return Observable
          // @ts-ignore
          .from(initAxiosInstanse(searchParams).post(`graph/${organizationId}/search?limit=${limit}`, { term }))
          .map((response: any) => receiveMetricsPanelSearchResult(response.data))
          .catch(() => Observable.of(
            receiveMetricsPanelSearchResult(null),
            // @ts-ignore
            showNotificationWithTimeout({
              id: `notifications.setMetricsPanelFilters.${Date.now()}`,
              messageId: 'notifications.setMetricsPanelFiltersError',
              position: 'leftDown',
              iconType: 'error',
              notificationType: 'withActionWide',
            }),
          ));
      }

      return Observable.empty();
    });

export default combineEpics(
  getMetricsEpic,
  getGraphPresetsEpic,
  getGraphPresetEpic,
  createGraphPresetEpic,
  updateGraphPresetEpic,
  deleteGraphPresetEpic,
  getGraphDataPointsEpic,
  popRangeHistoryEpic,
  clearRangeHistoryEpic,
  changePresetMetricGroupEpic,
  deletePresetMetricEpic,
  togglePresetMetricEpic,
  deletePresetMetricGroupEpic,
  updateNodeMetricUrlEpic,
  responseGetGraphPresetEpic,
  changeGraphPeriodRangeEpic,
  changeGraphPeriodThresholdEpic,
  changeGraphPeriodAndThresholdEpic,
  updatePresetDataFromUrlEpic,
  setPresetMetricSelectedEpic,
  setAllPresetMetricSelectedEpic,
  setMetricColorEpic,
  setMetricToScaleEpic,
  changeScaleEpic,
  deleteScaleEpic,
  clearWorkspaceEpic,
  setShowIncidentsEpic,
  setActiveIncidentIdEpic,
  requestNewIncidentIdEpic,
  setCreateIncidentIdEpic,
  checkNewIncidentStatusEpic,
  requestMetricsTreeEpic,
  setMetricsPanelFiltersEpic,
  requestFullTreeDataEpic
);
