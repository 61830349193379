import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';
import classnames from 'classnames';
import { head, last } from 'lodash';

import DropdownMenu from '../DropdownMenu';

import YearRangePickerButton from './components/YearRangePickerButton';
import YearRangePickerDropdown from './components/YearRangePickerDropdown';

import ArrowRightIcon from '../Icons/ArrowRightIcon';
import ArrowLeftIcon from '../Icons/ArrowLeftIcon';

import styles from './YearRangePicker.module.css';

export default class YearRangePicker extends PureComponent {
  static propTypes = {
    years: PropTypes.array.isRequired,
    disabledYears: PropTypes.array,
    selectedYears: PropTypes.array,

    onRangeSelect: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabledYears: [],
    selectedYears: [moment().format('YYYY')],
  };

  state = {
    closeDropdown: false,
    hoveredYears: [],
  };

  getSelectedRangeText = (selectedYears) => {
    if (selectedYears.length === 1) {
      return head(selectedYears);
    }

    const firstYear = head(selectedYears);
    const lastYear = last(selectedYears);

    return `${firstYear} – ${lastYear}`;
  };

  getIsArrowDisabled = (operation) => {
    const { years, disabledYears, selectedYears } = this.props;

    if (selectedYears.length > 1) {
      return true;
    }

    const currentYear = head(selectedYears);
    const availableYears = years.filter(year => !disabledYears.includes(year));
    const minYear = Math.min(...availableYears);
    const maxYear = Math.max(...availableYears);

    if (operation === 'add' && maxYear > currentYear) {
      return false;
    }

    if (operation === 'substract' && minYear < currentYear) {
      return false;
    }


    return true;
  };

  handlerOnClickOutside = () => this.setState({ hoveredYears: [] });

  handlerPeriodSelect = (newPeriod) => {
    const { onRangeSelect } = this.props;

    onRangeSelect(newPeriod);

    // Если будет моргать - убрать обнуление hoveredYears
    this.setState({ closeDropdown: true, hoveredYears: [] });
  };

  handlerPeriodHover = ({ hoveredYears }) => this.setState({ hoveredYears });

  handlerArrowClick = (subtract = false) => {
    const { selectedYears, onRangeSelect } = this.props;

    const selectedYear = head(selectedYears);

    const newYear = subtract ? Number(selectedYear) - 1 : Number(selectedYear) + 1;

    const newPeriod = {
      startDate: moment(newYear, 'YYYY').startOf('year').format('YYYY-MM-DD'),
      endDate: moment(newYear, 'YYYY').endOf('year').format('YYYY-MM-DD'),
    };

    onRangeSelect(newPeriod);
  };

  render() {
    const { years, disabledYears, selectedYears } = this.props;

    const { closeDropdown, hoveredYears } = this.state;

    const yearsForButton = hoveredYears.length === 0 ? selectedYears : hoveredYears;
    const buttonDateText = this.getSelectedRangeText(yearsForButton);

    const arrowsDisabled = selectedYears.length > 1 || hoveredYears.length > 1;
    const isSubstrArrowsDisabled = this.getIsArrowDisabled('substract');
    const isAddArrowsDisabled = this.getIsArrowDisabled('add');

    return (
      <div
        className={styles.yearRangePicker}
      >
        <button
          type='button'
          className={classnames(styles.leftButton)}
          onClick={() => this.handlerArrowClick(true)}
          disabled={arrowsDisabled || isSubstrArrowsDisabled}
        >
          <ArrowLeftIcon />
        </button>

        <DropdownMenu
          closeDropdown={closeDropdown}
          contentClassName={styles.dropdownContent}
          buttonElement={(
            <YearRangePickerButton
              date={buttonDateText}
              onClick={() => this.setState({ closeDropdown: false })}
            />
          )}
          afterClickOutside={this.handlerOnClickOutside}
        >
          <YearRangePickerDropdown
            years={years}
            disabledYears={disabledYears}
            selectedYears={selectedYears}
            onPeriodSelect={this.handlerPeriodSelect}
            onPeriodHover={this.handlerPeriodHover}
          />
        </DropdownMenu>

        <button
          type='button'
          className={classnames(styles.rightButton)}
          onClick={() => this.handlerArrowClick(false)}
          disabled={arrowsDisabled || isAddArrowsDisabled}
        >
          <ArrowRightIcon />
        </button>
      </div>
    );
  }
}
