import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import SidePageMenu from 'components/SidePageMenu';

import getLayoutMinWidth from 'helpers/getLayoutMinWidth';

export default class SettingsMenu extends Component {
  static propTypes = {
    intl: intlShape.isRequired,

    location: PropTypes.object.isRequired,
    organizationSlug: PropTypes.string.isRequired,
    isEssentialPricePlan: PropTypes.bool.isRequired,
    children: PropTypes.object.isRequired,
    harvestForecastAccess: PropTypes.bool,
    cropReportsAccess: PropTypes.bool,
    incidentsAccess: PropTypes.bool,
    alertingAccess: PropTypes.bool,
  };

  static defaultProps = {
    harvestForecastAccess: false,
    cropReportsAccess: false,
    incidentsAccess: false,
    alertingAccess: false,
  };

  render() {
    const {
      intl: { formatMessage },
      location: { pathname },
      organizationSlug,
      isEssentialPricePlan,
      harvestForecastAccess,
      cropReportsAccess,
      incidentsAccess,
      alertingAccess,
      children,
    } = this.props;

    const pagesWithMinWidth = [
      { name: 'data-quality', minWidth: 720 },
      { name: 'permissions', minWidth: 720 }
    ];

    const settingsEquipmentName = formatMessage({ id: 'settings.equipment' });
    const equipmentGreenhouses = {
      href: `/${organizationSlug}/settings/equipment/greenhouses`,
      name: formatMessage({ id: 'settings.greenhouses' })
    };
    const equipmentMeters = {
      href: `/${organizationSlug}/settings/equipment/meters`,
      name: formatMessage({ id: 'settings.meters' })
    };

    const settingsCropCyclesName = formatMessage({ id: 'plantingCycles.plantingCycles' });
    const settingsMeasurements = {
      href: `/${organizationSlug}/settings/crop-cycles/measurements`,
      name: formatMessage({ id: 'cycle.measurements' })
    };

    const settingsReports = cropReportsAccess ? [{
      href: `/${organizationSlug}/settings/crop-cycles/reports`,
      name: formatMessage({ id: 'reports.reports' })
    }] : [];

    // Для Essential пользователей скрываем часть пунктов меню
    const menuItemsByPricePlan = isEssentialPricePlan ?
      [
        {
          name: settingsEquipmentName,
          links: [
            equipmentGreenhouses,
          ]
        },
        {
          name: settingsCropCyclesName,
          links: [
            settingsMeasurements,
            ...settingsReports,
          ]
        }
      ]
      :
      [
        {
          name: settingsEquipmentName,
          links: [
            equipmentGreenhouses,
            equipmentMeters,
          ]
        },
        {
          name: settingsCropCyclesName,
          links: [
            settingsMeasurements,
            ...settingsReports,
          ]
        },
        ...(incidentsAccess ? [{
          href: `/${organizationSlug}/settings/incidents`,
          name: formatMessage({ id: 'header.links.incidents' })
        }] : []),
        {
          href: `/${organizationSlug}/settings/benchmarking`,
          name: formatMessage({ id: 'settings.benchmarking' })
        },
        ...(harvestForecastAccess ? [{
          href: `/${organizationSlug}/settings/harvest-forecast`,
          name: formatMessage({ id: 'forecast.harvestForecasts' })
        }] : []),
        {
          href: `/${organizationSlug}/settings/labor/works`,
          name: formatMessage({ id: 'settings.laborWorks' })
        },
      ];

    const menuItems = [
      {
        header: formatMessage({ id: 'settings.yourAccount' }),
        links: [
          {
            href: `/${organizationSlug}/settings/profile`,
            name: formatMessage({ id: 'settings.profile' })
          }
        ]
      },
      {
        header: formatMessage({ id: 'settings.organization' }),
        links: [
          {
            href: `/${organizationSlug}/settings/permissions`,
            name: formatMessage({ id: 'settings.permissions' })
          },
          ...(alertingAccess ? [{
            href: `/${organizationSlug}/settings/alerting`,
            name: formatMessage({ id: 'settings.alerting' })
          }] : []),
          {
            href: `/${organizationSlug}/settings/data-quality`,
            name: formatMessage({ id: 'settings.dataQuality' })
          },

          ...menuItemsByPricePlan,
        ]
      }
    ];

    return (
      <SidePageMenu
        header={formatMessage({ id: 'settings.header' })}
        menuItems={menuItems}
        minWidth={getLayoutMinWidth(pathname, pagesWithMinWidth)}
      >
        {children}
      </SidePageMenu>
    );
  }
}
