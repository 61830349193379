import moment from 'moment-timezone';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import { get } from 'lodash';
import loadImage from 'blueimp-load-image';
import classnames from 'classnames';

import getIsCanvasValid from 'helpers/getIsCanvasValid';

import SplitPhotos from '../../../SplitPhotos';
import PhotoDetailDialog from '../../../PhotoDetailDialog';
import PhotoUploadDialog from '../../../PhotoUploadDialog';
import Dialog from '../../../Dialog';
import styles from './Photos.module.css';
import storageWrapper from '../../../../helpers/storageWrapper';

const safeLocalStorage = storageWrapper.get('localStorage');


class Photos extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    location: PropTypes.object,
    leftPlantingCycle: PropTypes.object,
    compartments: PropTypes.array,
    deletedPhoto: PropTypes.object,
    photoCategories: PropTypes.array,
    photoDetailDialogOpen: PropTypes.bool.isRequired,
    photoUploadDialogOpen: PropTypes.bool.isRequired,
    photoDeleteDialogOpen: PropTypes.bool.isRequired,

    cycleId: PropTypes.string,
    allPlantingCycles: PropTypes.array,
    isAddPhotoAvailable: PropTypes.bool.isRequired,

    getSplitCompartmentsPhotos: PropTypes.func.isRequired,
    getLeftPlantingCycle: PropTypes.func.isRequired,
    deletePhoto: PropTypes.func.isRequired,
    setSelectedPhoto: PropTypes.func.isRequired,
    setDeletedPhoto: PropTypes.func.isRequired,
    setTogglePhotoDetailDialog: PropTypes.func.isRequired,
    setTogglePhotoUploadDialog: PropTypes.func.isRequired,
    setTogglePhotoDeleteDialog: PropTypes.func.isRequired,
    setToggleErrorDialog: PropTypes.func.isRequired,
  };

  static defaultProps = {
    photoCategories: [],
    leftPlantingCycle: null,
    compartments: null,
    deletedPhoto: null,
    location: null,
    cycleId: null,
    allPlantingCycles: null,
  };

  state = {
    uploadedPhoto: null,
    file: null,
  };

  componentDidMount() {
    const {
      getSplitCompartmentsPhotos, cycleId, getLeftPlantingCycle, leftPlantingCycle, allPlantingCycles
    } = this.props;
    const currentPhotosCycleId = get(leftPlantingCycle, 'id');

    if (currentPhotosCycleId !== Number(cycleId)) {
      getSplitCompartmentsPhotos().then(() => {
        // TODO: ВНИМАНИЕ!!! Это люто-бешенный костыль, нужен на время пока нельзя выпиливать старые фото
        if (cycleId && allPlantingCycles) {
          const plantingCycle = allPlantingCycles.find((item) => {
            const { id: itemId } = item;

            return itemId === Number(cycleId);
          });

          getLeftPlantingCycle(plantingCycle);
        }
      });
    }
  }

  handlerPhotoSelect = (e) => {
    const { intl: { formatMessage }, setTogglePhotoUploadDialog, setToggleErrorDialog } = this.props;

    if (e.target.files.length > 0) {
      loadImage(
        e.target.files[0],
        (img) => {
          const isCanvasValid = getIsCanvasValid({
            width: img?.width,
            height: img?.height,
          });

          // Check if browser support current img/canvas resolution (sometimes it is too big on iphones)
          if (isCanvasValid) {
            this.setState({
              uploadedPhoto: img,
            });
            setTogglePhotoUploadDialog(true);
          } else {
            setToggleErrorDialog(true, formatMessage({ id: 'photos.photoSizeError' }));
            this.handlerPhotoSelectDialogClose();
          }
        },
        {
          canvas: true,
          orientation: true,
        }
      );
    } else {
      this.handlerPhotoSelectDialogClose();
    }
  };

  handlerPhotoDeleteDialogOpen = ({ photo, plantingCycle }) => {
    const { setDeletedPhoto, setTogglePhotoDeleteDialog } = this.props;

    setDeletedPhoto({
      deletedPlantingCycle: plantingCycle,
      deletedPhoto: photo,
    });

    setTogglePhotoDeleteDialog(true);
  };

  handlerPhotoDialogOpen = ({
    photo,
    year,
    week,
    compartment,
    plantingCycle,
    replace,
    photos,
  }) => {
    const { setSelectedPhoto, setTogglePhotoDetailDialog, isAddPhotoAvailable } = this.props;

    setSelectedPhoto({
      selectedCompartment: compartment,
      selectedPlantingCycle: plantingCycle,
      selectedWeek: week,
      selectedYear: year,
      selectedPhoto: photo,
      selectedPhotos: photos,
    });

    if (photo.attributes && photo.attributes.thumbnailUrl && !replace) {
      setTogglePhotoDetailDialog(true);
    } else if (isAddPhotoAvailable) {
      this.fileButton.click();
    }
  };

  handlerPhotoDialogClose = () => {
    const { setSelectedPhoto, setTogglePhotoDetailDialog } = this.props;

    setSelectedPhoto({}, true);

    setTogglePhotoDetailDialog(false);
  };

  handlerPhotoSelectDialogClose = () => {
    const { setTogglePhotoUploadDialog } = this.props;
    setTogglePhotoUploadDialog(false);
  };

  handlerPhotoDeleteDialogCancel = () => {
    const { setTogglePhotoDeleteDialog, setDeletedPhoto } = this.props;
    setTogglePhotoDeleteDialog(false);

    setDeletedPhoto({
      deletedPlantingCycle: null,
      deletedPhoto: null,
    });
  };

  handlerPhotoDeleteDialogOk = () => {
    const { setTogglePhotoDeleteDialog, deletePhoto } = this.props;
    setTogglePhotoDeleteDialog(false);

    deletePhoto();
  };

  render() {
    const {
      compartments,
      location,
      deletedPhoto,
      photoCategories,
      photoDetailDialogOpen,
      photoUploadDialogOpen,
      photoDeleteDialogOpen,
      isAddPhotoAvailable,
    } = this.props;


    const { file, uploadedPhoto } = this.state;

    const locale = safeLocalStorage.getItem('locale') || 'en';

    if (location && compartments && compartments.length) {
      const { attributes: { timezone } } = location;

      return (
        <div style={{ margin: '0 auto' }}>
          <SplitPhotos
            onPhotoDialogOpen={this.handlerPhotoDialogOpen}
            onPhotoDeleteDialogOpen={this.handlerPhotoDeleteDialogOpen}
            isAddPhotoAvailable={isAddPhotoAvailable}
          />
          {photoDetailDialogOpen && (
            <PhotoDetailDialog
              onPhotoDialogClose={this.handlerPhotoDialogClose}
              onPhotoDialogOpen={this.handlerPhotoDialogOpen}
              onPhotoDeleteDialogOpen={this.handlerPhotoDeleteDialogOpen}
            />
          )}
          {photoUploadDialogOpen && (
            <PhotoUploadDialog
              file={file}
              uploadedPhoto={uploadedPhoto}
              onPhotoSelectDialogClose={this.handlerPhotoSelectDialogClose}
            />
          )}
          {photoDeleteDialogOpen && (
            <Dialog
              title='Delete photo'
              onClose={this.handlerPhotoDeleteDialogCancel}
              wrapperClassName={styles.wrapperDeleteDialog}
            >
              <div className={classnames(styles.photoDeleteDialogContent)}>
                <div className={styles.photoDeleteDialogText}>
                  <FormattedMessage id='photos.deleteQuestion' /> <span className={styles.photoDeleteDialogTextMedium}>{deletedPhoto ? photoCategories.find(item => item.code === deletedPhoto.attributes.category)[locale] : ''}{deletedPhoto ? `, ${moment.tz(deletedPhoto.attributes.date, timezone).format('MMMM YYYY')}` : ''}</span> ?
                </div>
                <div className={styles.photoDeleteDialogButtons}>
                  <button type='button' className={styles.photoDeleteDialogCancel} onClick={this.handlerPhotoDeleteDialogCancel}><FormattedMessage id='dialog.cancel' /></button>
                  <button type='button' className={styles.photoDeleteDialogOk} onClick={this.handlerPhotoDeleteDialogOk}><FormattedMessage id='dialog.ok' /></button>
                </div>
              </div>
            </Dialog>
          )}
          <input
            id='file-selector'
            type='file'
            name='photos'
            ref={(element) => {
              this.fileButton = element;
            }}
            style={{ display: 'none' }}
            onChange={this.handlerPhotoSelect}
            onClick={(event) => {
              // Fix same photo add bug
              // https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component/40429197
              event.target.value = null; // eslint-disable-line no-param-reassign
            }}
            accept='image/jpeg,image/jpg,image/png'
          />
        </div>
      );
    }
    return null;
  }
}

export default injectIntl(Photos);
