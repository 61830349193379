import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import cn from 'classnames';
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import endOfDay from 'date-fns/endOfDay';
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';

import { formatDateLocalized, formatDate, parseDate } from 'helpers/datesHelper';

import DropdownMenu from 'components/DropdownMenu';
import MobileMenu from 'components/MobileMenu';
import CalendarIcon from 'components/Icons/CalendarIcon';

import DateButton from '../DateButton';
import MobileMenuContent from '../MobileMenuContent';
import DateRangePickerContent from '../DateRangePickerContent';

import styles from './UiDateRangePicker.module.css';


const getDatesDesc = (start, end) => {
  const yearDifference = differenceInCalendarYears(
    end,
    start,
  );

  const startDate = formatDateLocalized(start, yearDifference !== 0 ? 'lll' : 'll');
  const endDate = formatDateLocalized(end, 'lll');

  return `${startDate} – ${endDate}`;
};

const renderButton = (dateButtonClassName, setIsCloseDropdown, isCloseDropdown, fotmattedRange, updateDates, startDate, endDate) => (
  <div
    role='button'
    tabIndex={0}
    onClick={() => setIsCloseDropdown(!isCloseDropdown)}
  >
    <DateButton
      date={fotmattedRange}
      updateDates={updateDates}
      startDate={startDate}
      endDate={endDate}
      className={dateButtonClassName}
    />
  </div>
);

const UiDateRangePicker = ({
  intl,
  months,
  startDate,
  endDate,
  minDate,
  maxDate,
  className,
  onApply,
  staticRanges,
  alignRight,
  datesRenderer,
  withMobileVersion,
  isMobileMenuOpened,
  setIsMobileMenuOpened,
  dateButtonClassName,
  dropdownContentClassName,
  applyFormat
}) => {
  const { formatMessage } = intl;

  const [isCloseDropdown, setIsCloseDropdown] = useState(true);
  const [isMobileMenuOpenedInner, setIsMobileMenuOpenedInner] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);

  const handlerSetIsMobileMenuOpened = setIsMobileMenuOpened || setIsMobileMenuOpenedInner;
  const handledIsMobileMenuOpened = setIsMobileMenuOpened ? isMobileMenuOpened : isMobileMenuOpenedInner;

  const [pickerState, setPickerState] = useState([
    {
      startDate,
      endDate,
      key: 'selection'
    }
  ]);

  const fotmattedRange = datesRenderer ? datesRenderer(startDate, endDate) : getDatesDesc(startDate, endDate);

  const handlerClickApply = useCallback(() => {
    onApply({
      startDate: formatDate(pickerState[0]?.startDate, applyFormat),
      endDate: formatDate(endOfDay(pickerState[0]?.endDate), applyFormat),
    });
    setIsCloseDropdown(true);
  }, [onApply, pickerState, applyFormat]);

  const handlerClickArrays = useCallback((newDates) => {
    const endDateWithTime = endOfDay(parseDate(newDates?.endDate));
    onApply({
      startDate: formatDate(parseDate(newDates?.startDate), applyFormat),
      endDate: formatDate(endDateWithTime, applyFormat),
    });
    setPickerState([
      {
        startDate: parseDate(newDates?.startDate),
        endDate: parseDate(newDates?.endDate),
        key: 'selection'
      }
    ]);
  }, [setPickerState, onApply, applyFormat]);

  const handlerClickCancel = useCallback(() => {
    setPickerState([
      {
        startDate,
        endDate,
        key: 'selection'
      }
    ]);

    setIsCloseDropdown(true);
  }, [setPickerState, setIsCloseDropdown, startDate, endDate]);

  return (
    <div className={cn(styles.dateRangePicker, { [styles.withMobileVersion]: withMobileVersion })}>
      <div className={styles.mobileRangePickerContainer}>
        <MobileMenu
          className={styles.mobileMenu}
          icon={<CalendarIcon />}
          headerText={isFirstStep ? formatMessage({ id: 'harvest.period' }) : formatMessage({ id: 'button.selectDate' })}
          onClose={() => handlerSetIsMobileMenuOpened(false)}
          onOpen={() => handlerSetIsMobileMenuOpened(true)}
          menuOpened={handledIsMobileMenuOpened}
          menuClassName={styles.mobileMenuInner}
        >
          <MobileMenuContent
            isFirstStep={isFirstStep}
            setIsFirstStep={setIsFirstStep}
            fotmattedRange={fotmattedRange}
            setIsMobileMenuOpened={handlerSetIsMobileMenuOpened}
            minDate={minDate}
            maxDate={maxDate}
            months={months}
            pickerState={pickerState}
            setPickerState={setPickerState}
            staticRanges={staticRanges}
            handlerClickCancel={handlerClickCancel}
            handlerClickApply={handlerClickApply}
            withMobileVersion={withMobileVersion}
          />
        </MobileMenu>
      </div>

      <DropdownMenu
        wrapperClassName={cn(styles.rangePickerDropdown, className)}
        contentClassName={cn(styles.dropdownContent, dropdownContentClassName, {
          [styles.alignRight]: alignRight,
        })}
        // contentClassName={cn(styles.switchContent, { [styles.alignLeft]: alignLeft })}
        buttonElement={renderButton(dateButtonClassName, setIsCloseDropdown, isCloseDropdown, fotmattedRange, handlerClickArrays, startDate, endDate)}
        closeDropdown={isCloseDropdown}
        afterClickOutside={() => setIsCloseDropdown(true)}
      >
        <DateRangePickerContent
          minDate={minDate}
          maxDate={maxDate}
          months={months}
          pickerState={pickerState}
          setPickerState={setPickerState}
          staticRanges={staticRanges}
          handlerClickCancel={handlerClickCancel}
          handlerClickApply={handlerClickApply}
          withMobileVersion={withMobileVersion}
        />
      </DropdownMenu>
    </div>
  );
};

UiDateRangePicker.propTypes = {
  onApply: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  className: PropTypes.string,
  months: PropTypes.number,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  staticRanges: PropTypes.array,
  alignRight: PropTypes.bool,
  datesRenderer: PropTypes.func,
  withMobileVersion: PropTypes.bool,
  isMobileMenuOpened: PropTypes.bool,
  setIsMobileMenuOpened: PropTypes.func,
  dateButtonClassName: PropTypes.string,
  dropdownContentClassName: PropTypes.string,
  applyFormat: PropTypes.string
};

UiDateRangePicker.defaultProps = {
  className: undefined,
  months: 1,
  minDate: addYears(new Date(), -100),
  maxDate: addYears(new Date(), 20),
  startDate: new Date(),
  endDate: addDays(new Date(), 7),
  staticRanges: undefined,
  alignRight: false,
  datesRenderer: undefined,
  withMobileVersion: true,
  isMobileMenuOpened: false,
  setIsMobileMenuOpened: undefined,
  dateButtonClassName: undefined,
  dropdownContentClassName: undefined,
  applyFormat: 'yyyy-MM-dd'
};

export default injectIntl(UiDateRangePicker);
