import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import {
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';

import cn from 'classnames';

import DropdownMenu from 'components/DropdownMenu';
import DefaultSimpleTooltip from 'components/DefaultSimpleTooltip';
import BigButton from 'components/BigButton';
import Typography from 'components/Typography';

import ArrowDown from 'components/Icons/ArrowDown';
import CloseIcon from 'components/Icons/Close';

import CropSwitchSearchList from '../CropSwitchSearchList';

import styles from './CropSwitch.module.css';

const renderButton = (formatMessage, setIsCloseDropdown, isCloseDropdown) => (
  <DefaultSimpleTooltip
    text={formatMessage({ id: 'cycle.switchCropCycle' })}
    noWrap
  >
    <div
      role='button'
      tabIndex={0}
      className={cn(styles.arrowWrapper, { [styles.active]: !isCloseDropdown })}
      onClick={() => setIsCloseDropdown(!isCloseDropdown)}
    >
      <ArrowDown />
    </div>
  </DefaultSimpleTooltip>
);

const CropSwitch = ({
  intl,
  cycleId,
  compartments,
  fruitClasses,
  varieties,
  plantingCycles,
  sortedPlantingCycles,
  isMobileVersion,
  onCloseMobileMenu,
  className,
  customRedirect,
}) => {
  const { formatMessage } = intl;

  const [isCloseDropdown, setIsCloseDropdown] = useState(true);
  const [selectedCycleOnMobile, setSelectedCycleOnMobile] = useState(cycleId);

  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const redirectHandler = useCallback((newCycleId) => {
    const newPathname = location.pathname.replace(params.cycleId, newCycleId);

    history.push({
      pathname: newPathname,
      search: location.search
    });
  }, [params, history, location]);

  const redirectToOtherCycle = customRedirect || redirectHandler;

  /**
   * Используем разные обработчики событий, т.к. на мобилке нужно переходить к циклу только после
   * нажатия на кнопку, а на десктопе сразу при клике на цикл
   */
  const handlerClickCycleLink = useCallback(({ option }) => {
    redirectToOtherCycle(option.id);
  }, [redirectToOtherCycle]);

  const handlerClickCycleSelect = useCallback(({ option }) => {
    setSelectedCycleOnMobile(option.id);
  }, [setSelectedCycleOnMobile]);

  if (isMobileVersion) {
    return (
      <div className={cn(styles.mobileSwitchWrapper, className)}>
        <div className={styles.headersGroup}>
          <Typography variant='h2' className={styles.header}>
            {formatMessage({ id: 'cycle.switchCropCycle' })}
          </Typography>
          <CloseIcon className={styles.dialogClose} onClick={onCloseMobileMenu} />
        </div>

        <div className={styles.searchListMobile}>
          <CropSwitchSearchList
            intl={intl}
            cycleId={selectedCycleOnMobile || cycleId}
            compartments={compartments}
            fruitClasses={fruitClasses}
            varieties={varieties}
            plantingCycles={plantingCycles}
            sortedPlantingCycles={sortedPlantingCycles}
            handlerClickCycle={handlerClickCycleSelect}
            isMobileVersion
          />
        </div>

        <div className={styles.buttons}>
          <BigButton
            className={styles.cancel}
            onClick={onCloseMobileMenu}
            title={formatMessage({ id: 'button.cancel' })}
            theme='light'
          />
          <BigButton
            className={styles.confitm}
            onClick={() => redirectToOtherCycle(selectedCycleOnMobile)}
            title={formatMessage({ id: 'cycle.switchCropCycle' })}
            theme='dark'
          />
        </div>
      </div>
    );
  }

  return (
    <DropdownMenu
      wrapperClassName={cn(styles.switchWrapper, className)}
      contentClassName={styles.switchContent}
      buttonElement={renderButton(formatMessage, setIsCloseDropdown, isCloseDropdown)}
      closeDropdown={isCloseDropdown}
      afterClickOutside={() => setIsCloseDropdown(true)}
    >
      <CropSwitchSearchList
        intl={intl}
        cycleId={cycleId}
        compartments={compartments}
        fruitClasses={fruitClasses}
        varieties={varieties}
        plantingCycles={plantingCycles}
        sortedPlantingCycles={sortedPlantingCycles}
        handlerClickCycle={handlerClickCycleLink}
      />
    </DropdownMenu>
  );
};

CropSwitch.propTypes = {
  intl: intlShape.isRequired,
  cycleId: PropTypes.number.isRequired,
  compartments: PropTypes.array.isRequired,
  fruitClasses: PropTypes.array.isRequired,
  varieties: PropTypes.array.isRequired,
  plantingCycles: PropTypes.array.isRequired,
  sortedPlantingCycles: PropTypes.array,
  className: PropTypes.string,
  isMobileVersion: PropTypes.bool,
  onCloseMobileMenu: PropTypes.func,
  customRedirect: PropTypes.func,
};

CropSwitch.defaultProps = {
  sortedPlantingCycles: [],
  className: undefined,
  isMobileVersion: false,
  onCloseMobileMenu: () => {},
  customRedirect: undefined,
};

export default CropSwitch;
