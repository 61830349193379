// TODO: переиспользовать тут map по константе LANGUAGES
export default function getLocaleName(locale) {
  switch (locale) {
    case 'ru':
      return 'Russian';
    case 'nl':
      return 'Dutch';
    case 'fr':
      return 'French';
    case 'zh-cn':
      return 'Chinese';
    default:
      return 'English';
  }
}
