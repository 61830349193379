import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { trackPageViewResources } from 'store/company/actions';

import {
  getCurrentCompartments,
  getCurrentTimezone,
  getAllPlantingCycles,
  getAllVarieties,
  getAllFruitClasses,
  getIsAddLaborAvailable,
  getDefaultOpenFromRoute,
} from 'store/company/selectors';

import {
  getLaborResources,
  getIsLaborResourcesFetching,
  getLaborWorkTypes
} from 'store/labor/selectors';

import {
  requestLaborResources,
  requestLaborWorkTypes
} from 'store/labor/actions';

import LaborWorks from '../components/LaborWorks';

const mapDispatchToProps = {
  requestLaborResources,
  requestLaborWorkTypes,
  trackPageViewResources,
};

const mapStateToProps = (state, props) => ({
  ...props,

  allPlantingCycles: getAllPlantingCycles(state),
  compartments: getCurrentCompartments(state, props),
  varieties: getAllVarieties(state, props),
  fruitClasses: getAllFruitClasses(state, props),
  timezone: getCurrentTimezone(state, props),
  resources: getLaborResources(state),
  isFetching: getIsLaborResourcesFetching(state),
  workTypes: getLaborWorkTypes(state),
  isAddLaborAvailable: getIsAddLaborAvailable(state),
  defaultOpen: getDefaultOpenFromRoute(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(LaborWorks)));
