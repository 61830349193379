import React from 'react';

const renderHeaderRows = (styles, formatMessage) => (
  <tr>
    <th className={styles.alignLeft}>{formatMessage({ id: 'settings.greenhouseWork' })}</th>
    <th className={styles.alignLeft}>{formatMessage({ id: 'settings.measureUnit' })}</th>
  </tr>
);

export default renderHeaderRows;
