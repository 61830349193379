import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Plans from 'components/Plans';
import PlansList from 'components/PlansList';
import PlansEditor from 'components/PlansEditor';

export default class PlansRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route key='plans-page' path='/:organizationSlug/plans' component={Plans} exact />
        <Route key='plans-list-page' path='/:organizationSlug/plans/list' component={PlansList} exact />
        <Route key='plans-editor-page' path='/:organizationSlug/plans/:planType/:planId/edit' component={PlansEditor} />
      </Switch>
    );
  }
}
