
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';

import classnames from 'classnames';

import numbersFormatAndRounding from 'helpers/numbersFormatAndRounding';

import styles from './TooltipMetric.module.css';


const TooltipMetric = ({
  value, percentValue, units, text, color, isActive, className, isSecond, roundTo
}) => (
  <div
    className={classnames(
      className,
      styles.harvestRow,
      {
        [styles.harvestRowActive]: isActive,
        [styles.secondRow]: isSecond
      }
    )}
  >
    <span className={styles.harvestTitle}>
      <span className={styles.harvestColor} style={{ backgroundColor: color }} />
      <span>
        {text}
      </span>
    </span>
    <span className={styles.harvestRowAmount}>{numbersFormatAndRounding(value, roundTo) || '—'}{units === '%' ? '' : ' '}{units} {isNumber(percentValue) && (<span className={styles.percentValue}>({percentValue}%)</span>)}</span>
  </div>
);

TooltipMetric.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  units: PropTypes.string,
  value: PropTypes.string,
  percentValue: PropTypes.string,
  isActive: PropTypes.bool,
  isSecond: PropTypes.bool,
  className: PropTypes.string,
  roundTo: PropTypes.number,
};

TooltipMetric.defaultProps = {
  className: null,
  isActive: false,
  isSecond: false,
  value: null,
  units: '',
  roundTo: 0,
  percentValue: null
};

export default memo(TooltipMetric);
