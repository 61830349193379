import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import queryString from 'query-string';
import { get } from 'lodash';
import { push } from 'connected-react-router';

import addGetParameters from 'helpers/addGetParameters';

import { getSelectedRow } from 'store/dashboards/selectors';

import initAxiosInstanse from '../../api/axios';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

import {
  requestLaborReport,
  receiveLaborReport,
  requestLaborResources,
  receiveLaborResources,
  requestLaborWorkTypes,
  receiveLaborWorkTypes,
  requestLaborRegistrations,
  receiveLaborRegistrations,
  requestSaveLaborRegistrations,
  receiveSaveLaborRegistrations,
} from './actions';

export const getLaborReportEpic = (action$, store) =>
  action$.ofType(requestLaborReport)
    .switchMap((actionData) => {
      const state = store.getState();

      const {
        router,
        company,
      } = state;

      const {
        location,
      } = company;

      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          workTypeId,
          periodType,
          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
          breakdown,
          plantingCycleId,
          compartmentId,
          unitKind,
          species,
          varietyId,
          fruitClassCode,
        }
      } = actionData;

      const filteredProductGroups = [[species, fruitClassCode, varietyId].filter(x => x)].filter(item => item && item.length > 1).map(item => item.join('_'));

      const queryParams = queryString.stringify({
        workTypeId,
        periodType,
        anyDateOfPeriodStart,
        anyDateOfPeriodEnd,
        breakdown,
        productGroup: filteredProductGroups && filteredProductGroups.length ? filteredProductGroups : undefined,
        plantingCycleId,
        compartmentId: compartmentId ? compartmentId.filter(x => +x) : undefined,
        species,
        unitKind,
      });

      const query = queryParams || '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`labor/${organizationId}/dashboard?${query}`))
        .mergeMap(({ data }) => {
          const actions = [];

          const graphKeyId = 'labor';

          const selectedRow = getSelectedRow(state, {
            graphKeyId,
            report: data,
            location: router.location,
          });

          if (!selectedRow) {
            const searchString = addGetParameters(searchParams, { [graphKeyId]: undefined });

            actions.push(push({
              search: searchString
            }));
          }

          return Observable.of(
            receiveLaborReport({ laborReport: data }),
            ...actions,
          );
        })
        .catch(() => Observable.of(
          receiveLaborReport(),
          showNotificationWithTimeout({
            id: `notifications.getLaborReportError.${Date.now()}`,
            messageId: 'notifications.getLaborReportError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });


export const getLaborResourcesEpic = (action$, store) =>
  action$.ofType(requestLaborResources)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          periodType,
          anyDateOfPeriodStart,
          anyDateOfPeriodEnd
        }
      } = actionData;

      const queryParams = queryString.stringify({
        periodType,
        anyDateOfPeriodStart,
        anyDateOfPeriodEnd
      });

      const query = queryParams;
      return Observable
        .from(initAxiosInstanse(searchParams).get(`/labor/${organizationId}/resources?${query}`))
        .map(({ data }) => receiveLaborResources({ laborResources: data }))
        .catch(() => Observable.of(
          receiveLaborResources({ laborResources: [] }),
          showNotificationWithTimeout({
            id: `notifications.getLaborResourcesError.${Date.now()}`,
            messageId: 'notifications.getLaborResourcesError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getLaborWorkTypesEpic = (action$, store) =>
  action$.ofType(requestLaborWorkTypes)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/labor/${organizationId}/usedWorkTypes`))
        .map(({ data }) => receiveLaborWorkTypes({ workTypes: data }))
        .catch(() => Observable.of(
          receiveLaborWorkTypes(),
          showNotificationWithTimeout({
            id: `notifications.getLaborWorkTypesError.${Date.now()}`,
            messageId: 'notifications.getLaborWorkTypesError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getLaborRegistrationsEpic = (action$, store) =>
  action$.ofType(requestLaborRegistrations)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          startDate,
          endDate,
        }
      } = actionData;

      const queryParams = queryString.stringify({
        startDate,
        endDate,
      });

      const query = queryParams || '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/labor/${organizationId}/works/edit?${query}`))
        .map(({ data }) => receiveLaborRegistrations({ laborRegistrations: data }))
        .catch(() => Observable.of(
          receiveLaborRegistrations(),
          showNotificationWithTimeout({
            id: `notifications.getLaborRegistrationsError.${Date.now()}`,
            messageId: 'notifications.getLaborRegistrationsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const saveLaborRegistrationsEpic = (action$, store) =>
  action$.ofType(requestSaveLaborRegistrations)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          registrations,
          actionAfterSuccess,
        }
      } = actionData;

      const searchParams = get(router, 'location.search');


      return Observable
        .from(initAxiosInstanse(searchParams).post(`/labor/${organizationId}/works`, registrations))
        .mergeMap(() => Observable.of(
          actionAfterSuccess,
          receiveSaveLaborRegistrations(),
        ))
        .catch(() => Observable.of(
          receiveSaveLaborRegistrations(),
          showNotificationWithTimeout({
            id: `notifications.saveLaborRegistrationsError.${Date.now()}`,
            messageId: 'labor.saveLaborRegistrationsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export default combineEpics(
  getLaborReportEpic,
  getLaborResourcesEpic,
  getLaborWorkTypesEpic,
  getLaborRegistrationsEpic,
  saveLaborRegistrationsEpic,
);
