import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import classnames from 'classnames';
import { uniqueId } from 'lodash';

import { GREEN_ZONE, YELLOW_ZONE } from '../../helpers/constants';
import isTouchDevice from '../../helpers/isTouchDevice';

import ProgressBarCircleWithValue from '../ProgressBarCircleWithValue';
import InfoIcon from '../Icons/InfoIcon';

import tooltipStyles from '../Tooltip/index.module.css';
import styles from './OverallQuality.module.css';

const getDataText = (formatMessage, percent) => {
  if (percent >= GREEN_ZONE) {
    return formatMessage({ id: 'dataQuality.goodData' });
  } if (percent >= YELLOW_ZONE) {
    return formatMessage({ id: 'dataQuality.warningData' });
  }

  return formatMessage({ id: 'dataQuality.badData' });
};


// TODO: вынести в отдельный компонент. Похожее используется в HarvestKpiDashboard
const getHeaderTooltip = (formatMessage) => {
  const getColoredSpan = (colorName, value) => `<span class="${styles[colorName]}">${value}</span>`;

  const mainText = formatMessage({ id: 'dataQuality.mainTooltipText' });
  const subooltipText = formatMessage({ id: 'dataQuality.subooltipText' });

  const red = formatMessage({ id: 'kpiDashboard.tooltipTextRed' });
  const orange = formatMessage({ id: 'kpiDashboard.tooltipTextOrange' });
  const green = formatMessage({ id: 'kpiDashboard.tooltipTextGreen' });
  const andMore = formatMessage({ id: 'dataQuality.andMore' });

  return (
    `<span>${mainText} ${red} — ${getColoredSpan('tooltipRed', `0-${YELLOW_ZONE - 1}%`)}, ${orange} <br/> — ${getColoredSpan('tooltipOrange', `${YELLOW_ZONE}–${GREEN_ZONE - 1}%`)}, ${green} — ${getColoredSpan('tooltipGreen', `${GREEN_ZONE}-100% ${andMore}.`)} ${subooltipText}</span>`
  );
};

const OverallQuality = ({
  intl: { formatMessage },
  percent,
  withInfoIcon,
  className,
  textClassName,
}) => {
  const tooltipId = `kpi-header-tooltip-${uniqueId()}`;

  return (
    <div className={classnames(styles.overallQuality, className)}>
      <ProgressBarCircleWithValue percent={percent} size='M' />

      <div className={classnames(styles.overallQualityText, textClassName)}>
        {getDataText(formatMessage, percent)}
        {withInfoIcon && (
          <span
            className={styles.overallQualityInfoIcon}
            data-tip={getHeaderTooltip(formatMessage)}
            data-for={tooltipId}
          >
            <InfoIcon />
          </span>
        )}
      </div>

      {withInfoIcon && (
        <ReactTooltip
          className={classnames(tooltipStyles.smallTooltip, styles.tooltip)}
          id={tooltipId}
          effect='solid'
          event={isTouchDevice() ? 'click' : null}
          html
        />
      )}
    </div>
  );
};

OverallQuality.propTypes = {
  intl: intlShape.isRequired,
  percent: PropTypes.number,
  withInfoIcon: PropTypes.bool,
  className: PropTypes.string,
  textClassName: PropTypes.string,
};

OverallQuality.defaultProps = {
  percent: 0,
  withInfoIcon: false,
  className: null,
  textClassName: null,
};

export default memo(injectIntl(OverallQuality));
