import React from 'react';
import PropTypes from 'prop-types';


export default class PageViewTracker extends React.Component {
  static propTypes = {
    onMount: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { onMount } = this.props;

    setTimeout(() => onMount(), 0);
  }

  render() {
    return null;
  }
}
