import { injectIntl } from 'react-intl';
import React, { useRef } from 'react';
import numbersRounding from 'helpers/numbersRounding';
import { clamp } from 'lodash';
import { getDateFromKey } from 'components/LaborWorks/helpers/getDate';

const ValueTooltip = injectIntl(({
  intl, x, valueMetrics, dateKey, value, show
}) => {
  const textRef = useRef();
  const TEXT_PADDING = 16;
  const { formatMessage } = intl;

  const width = (textRef?.current?.getBBox()?.width || 0) + TEXT_PADDING;
  const roundValue = numbersRounding(value || 0, 'fixed', valueMetrics?.round || 0);
  const xPos = clamp(x - (width / 2), 0, width / 2);
  const unit = `cunits.mini.${valueMetrics?.units}`;

  return (
    <svg
      x={xPos}
      width={width}
      height='74'
      viewBox={`0 0 ${width} 74`}
      fill='none'
      opacity={show ? 1 : 0}
    >
      <g filter='url(#a)'>
        <rect width={width} height='26' rx='4' fill='#fff' />
        <rect width={width - 1} height='25' rx='3.5' stroke='#E1E8F0' />
        <text dx={TEXT_PADDING / 2} dy='17' ref={textRef}>
          <tspan
            x='0'
            textAnchor='left'
            fontStyle='normal'
            fontWeight='600'
            fontSize='13px'
            fill='#4a4a49'
          >
            {`${roundValue} ${formatMessage({ id: unit })}`}
          </tspan>
          <tspan
            textAnchor='left'
            fontStyle='normal'
            fontWeight='normal'
            fontSize='13px'
            fill='#777776'
            dx='8'
          >
            {getDateFromKey({
              dateKey,
              periodType: 'week',
              intl
            })}
          </tspan>
        </text>
      </g>
      <defs>
        <filter id='a' x='0' y='0' width='295' height='74' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dy='8' />
          <feGaussianBlur stdDeviation='12' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0' />
          <feBlend in2='effect1_dropShadow' result='effect2_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect2_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  );
});

export default ValueTooltip;
