import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { getHarvestCategoriesByCycle } from '../../../../store/harvest/selectors';
import { clearHarvestData, clearHarvestDataByCategories } from '../../../../store/harvest/actions';
import { showNotificationWithTimeout, removeNotification } from '../../../../store/notificationCenter/actions';

import HarvestForm from './HarvestForm';

const mapDispatchToProps = {
  clearHarvestDataByCategories,
  showNotificationWithTimeout,
  removeNotification,
  clearHarvestData,
};

const mapStateToProps = state => ({
  harvestCategories: getHarvestCategoriesByCycle(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(HarvestForm)));
