import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Transition } from 'react-transition-group';
import ReactTooltip from 'react-tooltip';

import { uniqueId } from 'lodash';
import classnames from 'classnames';

import { ANIMATION_DURATION } from 'helpers/constants';
import isTouchDevice from 'helpers/isTouchDevice';

import MenuArrowIcon from 'components/Icons/MenuArrowIcon';

import tooltipStyles from 'components/Tooltip/index.module.css';
import styles from './DashboardHeaderIcon.module.css';

const tooltipId = `dashboard-menu-tooltip-${uniqueId()}`;

const DashboardHeaderIcon = React.memo(({ intl: { formatMessage }, isClosed, onClick }) => (
  <span
    className={classnames(styles.menuIconWrapper, { [styles.isMenuClosed]: isClosed })}
    onClick={onClick}
    tabIndex={0}
    role='button'
    data-tip={isClosed ? formatMessage({ id: 'dashboardMenu.showMenu' }) : formatMessage({ id: 'dashboardMenu.hideMenu' })}
    data-for={isTouchDevice() ? null : tooltipId}
  >
    <Transition
      in={!isClosed}
      timeout={ANIMATION_DURATION}
      unmountOnExit
    >
      <MenuArrowIcon className={styles.menuIcon} />
    </Transition>

    <div className={styles.linesWrapper}>
      <span className={styles.line} />
      <span className={styles.line} />
      <span className={styles.line} />
    </div>

    <ReactTooltip
      className={classnames(tooltipStyles.smallTooltip, styles.tooltip)}
      id={tooltipId}
      effect='solid'
      event={null}
      place='right'
      html
    />
  </span>
));

DashboardHeaderIcon.propTypes = {
  intl: intlShape.isRequired,
  isClosed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default injectIntl(DashboardHeaderIcon);
