import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { trackPageViewDashboards } from '../../../store/company/actions';

import {
  getCropsPerformanceFetching,
  getDirectionSortedCropsPerformanceItems,
  getCropsPerformanceYear,
  getCropsPerformanceSorting,
  getPlantingCyclesYears,
  getIsEmptyCropsPerformance,
} from '../../../store/cropsPerformance/selectors';

import { getCropsPerformanceStarted, updateCropsPerformanceSorting } from '../../../store/cropsPerformance/actions';
import { getCropsData } from '../../../store/newCrops/actions';

import CropsPerformanceDashboard from '../components/CropsPerformanceDashboard';
import { getOrganization, getCurrentLocationPlan } from '../../../store/company/selectors';
import { getCyclesFullInfo } from '../../../store/newCrops/selectors';

const mapDispatchToProps = {
  getCropsPerformanceStarted,
  updateCropsPerformanceSorting,
  getCropsData,
  trackPageViewDashboards,
};

const mapStateToProps = (state, props) => {
  const {
    company,
    intl,
  } = state;

  return {
    ...props,
    ...company,
    ...intl,
    locationPlan: getCurrentLocationPlan(state),
    year: getCropsPerformanceYear(state, props),
    years: getPlantingCyclesYears(state, props),
    cropsPerformanceSorting: getCropsPerformanceSorting(state, props),
    cropsPerformanceItems: getDirectionSortedCropsPerformanceItems(state, props),
    isCropsPerformanceFetching: getCropsPerformanceFetching(state, props),
    isEmptyCropsPerformance: getIsEmptyCropsPerformance(state, props),
    organization: getOrganization(state, props),
    allFullPlantingCycles: getCyclesFullInfo(state, props),
  };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(CropsPerformanceDashboard)));
