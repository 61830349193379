import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { requestUserAuth } from 'store/company/actions';

import Login from '../components/Login';

const mapDispatchToProps = {
  requestUserAuth,
};

const mapStateToProps = (state) => {
  const { company, intl } = state;
  // нужно только error
  return {
    ...company,
    ...intl,
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login));
