import moment from 'moment-timezone';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import queryString from 'query-string';
import React, { Component } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import scrollbarSize from 'dom-helpers/util/scrollbarSize';

import getMinMaxDates from '../../../../helpers/getMinMaxDates';
import normalizeCycle from '../../../../helpers/normalizeCycle';
import storageWrapper from '../../../../helpers/storageWrapper';
import CircleLoader from '../../../CircleLoader';
import loaderStyles from '../../../CircleLoader/CircleLoader.module.css';
import HarvestForm from '../HarvestForm';
import HarvestFormHeader from '../HarvestFormHeader';
import { ReactComponent as ArrowBackIcon } from './assets/arrow_back.svg';

import styles from './UpdateHarvest.module.css';
import getDatePeriodRange from '../../../../helpers/getDatePeriodRange';

const safeLocalStorage = storageWrapper.get('localStorage');


const COL_WIDTHS = 168;
const SIDE_PADDING = 24;
const DEFAULT_MIN_WIDTH = 840;

class UpdateHarvest extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    organization: PropTypes.object,
    date: PropTypes.object,
    mode: PropTypes.string.isRequired,
    btnSource: PropTypes.string,
    periodType: PropTypes.string,
    fromPage: PropTypes.string,
    cycleId: PropTypes.string,
    cycleInfo: PropTypes.object,
    allCompartments: PropTypes.array,
    harvestCategories: PropTypes.object,
    harvestByCategories: PropTypes.object,
    clearHarvestData: PropTypes.func.isRequired,
    updateHarvestDataByCategories: PropTypes.func.isRequired,
    requestGetHarvestDataByCategories: PropTypes.func.isRequired,
    getHarvestCategories: PropTypes.func.isRequired,
    getCycleData: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    isGetHarvestDataByCategoriesFetching: PropTypes.bool.isRequired,
    isUpdateHarvestDataByCategoriesFetching: PropTypes.bool.isRequired,
    isAddHarvestAvailable: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  };

  static defaultProps = {
    btnSource: null,
    harvestCategories: null,
    cycleId: null,
    cycleInfo: null,
    allCompartments: [],
    organization: null,
    date: null,
    periodType: null,
    harvestByCategories: null,
    fromPage: null,
  };

  scrollbarSize = scrollbarSize();

  componentDidMount() {
    const {
      intl,
      date,
      history,
      periodType,
      cycleId,
      cycleInfo,
      getCycleData,
      harvestCategories,
      getHarvestCategories,
      requestGetHarvestDataByCategories,
      isAddHarvestAvailable,
      match,
    } = this.props;

    if (!isAddHarvestAvailable) {
      history.push(`/${match.params.organizationSlug}/crops`);
    }

    if (!cycleInfo || get(cycleInfo, 'cycle.id') !== cycleId) {
      getCycleData(cycleId);
    }


    if (!harvestCategories) {
      getHarvestCategories();
    }

    if (periodType && date) {
      if (get(cycleInfo, 'cycle.id') && harvestCategories) {
        const {
          locale,
          formatMessage,
        } = intl;

        const currentCycle = normalizeCycle(cycleInfo, locale);

        const {
          startDate,
          endDate,
        } = currentCycle;

        const { minDate, maxDate } = getMinMaxDates({ startDate, endDate });

        if (date.isSameOrAfter(minDate) && date.isSameOrBefore(maxDate)) {
          const { startDate: startOfPeriodDate, endDate: endOfPeriodDate } = getDatePeriodRange({
            date,
            periodType,
            minDate,
            maxDate,
            formatMessage,
          });

          requestGetHarvestDataByCategories({
            plantingCycleId: cycleId,
            startDate: startOfPeriodDate.format('YYYY-MM-DD'),
            endDate: endOfPeriodDate.format('YYYY-MM-DD'),
          });
        } else {
          const newParams = {
            date: maxDate.format('YYYY-MM-DD'),
            periodType: periodType || 'week',
          };

          const oldQuery = get(history, 'location.search');
          const parsedQuery = queryString.parse(oldQuery);
          const newQuery = { ...parsedQuery, ...newParams };
          const newSearch = queryString.stringify(newQuery);
          const searchString = newSearch ? `?${newSearch}` : '';

          history.push({
            search: searchString,
          });
        }
      }
    } else {
      const savedPeriodType = safeLocalStorage.getItem('periodType');

      const newParams = {
        date: date ? date.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        periodType: savedPeriodType || periodType || 'week',
      };

      const oldQuery = get(history, 'location.search');
      const parsedQuery = queryString.parse(oldQuery);
      const newQuery = { ...parsedQuery, ...newParams };
      const newSearch = queryString.stringify(newQuery);
      const searchString = newSearch ? `?${newSearch}` : '';

      history.push({
        search: searchString,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      intl,
      date: nextDate,
      periodType: nextPeriodType,
      requestGetHarvestDataByCategories,
      cycleInfo,
      cycleId,
      harvestCategories: nextHarvestCategories,
      history,
    } = this.props;

    const {
      date: oldDate,
      periodType: oldPeriodType,
      cycleInfo: oldCycleInfo,
      harvestCategories: oldHarvestCategories,
    } = prevProps;


    if (get(cycleInfo, 'cycle.id')) {
      if (((!oldDate && nextDate) || (oldDate && nextDate && (oldDate.format('YYYY-MM-DD') !== nextDate.format('YYYY-MM-DD')))) || oldPeriodType !== nextPeriodType || get(oldCycleInfo, 'cycle.id') !== get(cycleInfo, 'cycle.id') || (!oldHarvestCategories && nextHarvestCategories)) {
        const date = nextDate;
        const periodType = nextPeriodType;

        if (periodType && date) {
          const {
            locale,
            formatMessage,
          } = intl;

          const currentCycle = normalizeCycle(cycleInfo, locale);

          const {
            startDate,
            endDate,
          } = currentCycle;

          const { minDate, maxDate } = getMinMaxDates({ startDate, endDate });

          if (nextDate.isSameOrAfter(minDate) && nextDate.isSameOrBefore(maxDate)) {
            const { startDate: startOfPeriodDate, endDate: endOfPeriodDate } = getDatePeriodRange({
              date: nextDate,
              periodType: nextPeriodType,
              minDate,
              maxDate,
              formatMessage,
            });

            requestGetHarvestDataByCategories({
              plantingCycleId: cycleId,
              startDate: startOfPeriodDate.format('YYYY-MM-DD'),
              endDate: endOfPeriodDate.format('YYYY-MM-DD'),
            });
          } else {
            const newParams = {
              date: maxDate.format('YYYY-MM-DD'),
              periodType: nextPeriodType || 'week',
            };

            const oldQuery = get(history, 'location.search');
            const parsedQuery = queryString.parse(oldQuery);
            const newQuery = { ...parsedQuery, ...newParams };
            const newSearch = queryString.stringify(newQuery);
            const searchString = newSearch ? `?${newSearch}` : '';

            history.push({
              search: searchString,
            });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    const { clearHarvestData } = this.props;

    clearHarvestData();
  }

  getTableContainerMaxWidth = () => {
    const { harvestCategories, periodType } = this.props;

    if (harvestCategories && harvestCategories.length > 3) {
      return (harvestCategories.length * COL_WIDTHS) + COL_WIDTHS * 2 + (SIDE_PADDING * 2) + 2;
    }

    return (periodType === 'whole' ? this.scrollbarSize : 0) + DEFAULT_MIN_WIDTH + (SIDE_PADDING * 2) + 2;
  };

  handlerSubmit = (form) => {
    const {
      intl,
      btnSource,
      mode,
      cycleInfo,
      cycleId,
      fromPage,
      updateHarvestDataByCategories,
      isUpdateHarvestDataByCategoriesFetching,
    } = this.props;

    const {
      rows,
    } = form;

    const {
      locale,
    } = intl;


    const currentCycle = normalizeCycle(cycleInfo, locale);

    const {
      startDate,
      endDate,
    } = currentCycle;

    const { minDate, maxDate } = getMinMaxDates({ startDate, endDate });


    if (!isUpdateHarvestDataByCategoriesFetching) {
      updateHarvestDataByCategories({
        plantingCycleId: cycleId,
        data: rows,
        fromPage,
        minDate,
        maxDate,
        btnSource,
        mode,
        intl,
      });
    }
  };

  render() {
    const {
      harvestByCategories,
      date,
      periodType,
      intl,
      organization,
      allCompartments,
      harvestCategories,
      cycleInfo,
      fromPage,
      isFetching,
      isGetHarvestDataByCategoriesFetching,
      isUpdateHarvestDataByCategoriesFetching,
      clearHarvestData,
    } = this.props;

    if (!periodType || !date) {
      return (
        <div className={styles.layout}>
          <CircleLoader
            className={loaderStyles.circleLoader}
            iconClassName={loaderStyles.circleLoaderIcon}
          />
        </div>
      );
    }

    const compartments = allCompartments.filter(item => organization && item.relationships.organization && item.relationships.organization.data[0].id === organization.id);

    const { formatMessage } = intl;
    const { attributes: { slug } } = organization;

    const isLoading = isFetching || !organization || !cycleInfo || !harvestByCategories || isGetHarvestDataByCategoriesFetching || !harvestCategories;

    /**
     * Задаём сами, т.к. иначе при маленьком количестве столбцов не будет центровки таблицы
     */
    const tableContainerMaxWidth = this.getTableContainerMaxWidth();

    return (
      <div
        className={classnames(styles.body)}
        style={{
          maxWidth: `${tableContainerMaxWidth}px`,
          paddingLeft: SIDE_PADDING,
          paddingRight: SIDE_PADDING,
        }}
      >
        <div className={styles.controls}>
          <RouterLink
            to={fromPage}
            className={classnames(styles.routeControl)}
            activeClassName={styles.routeControlActive}
            tabIndex={0}
            role='button'
            onClick={() => {
              clearHarvestData();
            }}
          >
            <span className={styles.routeControlIcon}>
              <ArrowBackIcon />
            </span>
            <span className={styles.routeControlText}>
              {fromPage === `/${slug}/crops/${get(cycleInfo, 'cycle.id')}` ?
                formatMessage({ id: 'plantingCycles.plantingCycle' })
                :
                formatMessage({ id: 'plantingCycles.plantingCycles' })}
            </span>
          </RouterLink>
        </div>
        <HarvestFormHeader
          mode='update'
          organization={organization}
        />
        {!isLoading && (
          <HarvestForm
            date={date}
            periodType={periodType}
            loadedValues={harvestByCategories}
            compartments={compartments}
            handleSubmit={this.handlerSubmit}
            cycle={cycleInfo}
            mode='update'
            isLoading={isLoading}
            pageToGoBack={fromPage}
            isUpdateHarvestDataByCategoriesFetching={isUpdateHarvestDataByCategoriesFetching}
          />
        )}
        {isLoading && (
          <div className={styles.loaderWrapper}>
            <CircleLoader
              className={classnames(loaderStyles.circleLoader, styles.loader)}
              iconClassName={loaderStyles.circleLoaderIcon}
            />
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(UpdateHarvest);
