import React, {
  useCallback, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { uniq, isNull, isNil } from 'lodash';

import VarietySearchSelect from 'components/VarietySearchSelect/containers/VarietySearchSelectContainer';
import SelectBoxFilter from 'components/SelectBoxFilter';
import CompartmentFilter from 'components/DashboardComplexFilters/components/CompartmentFilter';
import DefaultTextareaInput from 'components/DefaultTextareaInput';

import prepareProductGroupParams from 'helpers/prepareProductGroupParams';
import getFormattedDate from 'helpers/getFormattedDate';
import filterForecastPlantingData from 'helpers/filterForecastPlantingData';

import {
  getCategoryName,
  getReportBarChartItems,
  getLineChartItems,
  getCategoriesLegend,
  getForecastTableData,
  getIndicatorCategories,
  getCategorySortingValue
} from 'helpers/harvestForecast';

import classnames from 'classnames';
import EditModeDisclaimer from '../EditModeDisclaimer';

import HarvestForecastBarChart
  from '../HarvestForecastBarChart/HarvestForecastBarChart';
import HarvestForecastActionsTable
  from '../HarvestForecastActionsTable/HarvestForecastActionsTable';
import HarvestForecastOverallTableRow
  from '../HarvestForecastOverallTableRow';
import HarvestForecastLinesChart
  from '../HarvestForecastLinesChart/HarvestForecastLinesChart';
import HarvestForecastByDaysTableRow
  from '../HarvestForecastByDaysTableRow/HarvestForecastByDaysTableRow';

import styles from './HarvestForecastTabPage.module.css';


function useMultiChartSelectAll(
  key,
  setGraphKey,
  graphKeysList,
  isAllSelected,
  onSelect,
  selectRowLimit
) {
  return [useCallback(() => {
    if (isAllSelected) {
      setGraphKey({
        [key]: null,
      });
    } else {
      setGraphKey({
        [key]: [...graphKeysList].slice(0, selectRowLimit),
      });
    }
    if (onSelect) {
      onSelect();
    }
  }, [setGraphKey, graphKeysList, isAllSelected, key, onSelect, selectRowLimit])];
}

function useMultiChartChangeGraphKey(
  key,
  graphKey,
  setGraphKey,
  byGroup = false,
  onSelect,
  selectRowLimit,
  showNotificationWithTimeout
) {
  return [useCallback((row) => {
    const targetKey = byGroup ? row.groupKey : row.graphKey;
    if (!graphKey?.[key]) {
      setGraphKey({
        [key]: [targetKey].slice(0, selectRowLimit),
      });
    } else if (graphKey?.[key]?.includes(targetKey)) {
      setGraphKey({
        [key]: graphKey[key].filter(item => item !== targetKey).slice(0, selectRowLimit),
      });
    } else {
      const newSelect = [...graphKey?.[key], targetKey];
      if (newSelect?.length >= selectRowLimit) {
        showNotificationWithTimeout({
          id: `notifications.maxChartMetricsSelected.${Date.now()}`,
          messageId: 'notifications.maxChartMetricsSelected',
          messageParams: { limit: selectRowLimit },
          position: 'leftDown',
          iconType: 'error',
          notificationType: 'withAction',
        });
      }
      setGraphKey({
        [key]: newSelect.slice(0, selectRowLimit),
      });
    }
    if (onSelect) {
      onSelect();
    }
  }, [graphKey, setGraphKey, key, byGroup, selectRowLimit, onSelect, showNotificationWithTimeout])];
}

const renderForecastComment = (formatMessage, comment) => (
  <span className={classnames({ [styles.defaultComment]: isNil(comment) })}>
    {comment || formatMessage({ id: 'forecast.defaultComment' })}
  </span>
);

const HarvestForecastTabPage = ({
  intl,
  organizationSlug,
  editMode,
  filters,
  changeReportFilters,
  varieties,
  fruitClasses,
  compartments,
  productGroups,
  forecastReportData,
  isForecastReportDataFetching,
  isForecastDataFetching,
  onFiltersChange,
  setGraphKey,
  graphKey,
  fields,
  setFields,
  forecastData,
  highlightsText,
  handlerUpdateHighlightsText,
  clearGraphKeys,
  showNotificationWithTimeout
}) => {
  const { formatMessage } = intl;

  /**
   * Чтобы отображать empty state явно (не прочерками данных в таблице, а текстом), проверяем
   * наличие дескриптора. Его не будет, если данных за текущий период нет (например, если выбрать период
   * tody/yesterday, а прогноз в будущее)
   */
  const isForecastNoData = forecastReportData?.descriptor?.periods?.length === 0;

  const breakdownOptions = useMemo(() => [
    { label: formatMessage({ id: 'dashboards.compartments' }), value: 'compartment' },
    { label: formatMessage({ id: 'dashboards.varieties' }), value: 'varietyWeight' },
    { label: formatMessage({ id: 'dashboards.types' }), value: 'fruitClass' },
    { label: formatMessage({ id: 'dashboards.plantingCycles' }), value: 'plantingCycle' },
  ], [formatMessage]);

  const unitsList = useMemo(() => [
    { label: formatMessage({ id: 'cunits.mini.kilogramPerSquareMeter' }), value: 'kilogramPerSquareMeter' },
    { label: formatMessage({ id: 'cunits.mini.kilogram' }), value: 'kilogram' },
  ], [formatMessage]);

  const periodOptions = useMemo(() => [
    { label: formatMessage({ id: 'dashboards.allTime' }), value: 'all' },
    { label: formatMessage({ id: 'dashboards.today' }), value: 'today' },
    { label: formatMessage({ id: 'dashboards.yesterday' }), value: 'yesterday' },
  ], [formatMessage]);

  const [overallExpanded, setOverallExpanded] = useState([]);

  const [isHighlightsError, setIsHighlightsError] = useState(false);

  const handlerVarietyChange = useCallback((options) => {
    const { species, varietyId, fruitClassCode } = options;
    const filterParams = prepareProductGroupParams(varieties, species, varietyId, fruitClassCode);
    changeReportFilters(filterParams);
    onFiltersChange(filterParams);
  }, [changeReportFilters, varieties, onFiltersChange]);

  const handlerBreakdownChange = useCallback((options) => {
    const payload = {
      breakdown: options.value
    };
    changeReportFilters(payload);
    onFiltersChange(payload);
    clearGraphKeys();
  }, [changeReportFilters, onFiltersChange, clearGraphKeys]);

  const handlerPeriodChange = useCallback((options) => {
    const payload = {
      period: options.value
    };
    changeReportFilters(payload);
    onFiltersChange(payload);
  }, [changeReportFilters, onFiltersChange]);

  const handlerCompartmentChange = useCallback((options) => {
    changeReportFilters(options);
    onFiltersChange(options);
  }, [changeReportFilters, onFiltersChange]);

  const handlerUnitsChange = useCallback((options) => {
    const payload = {
      relative: options.value === 'kilogramPerSquareMeter'
    };
    changeReportFilters(payload);
    onFiltersChange(payload);
  }, [changeReportFilters, onFiltersChange]);

  const overallTableData = useMemo(() => getForecastTableData(
    isForecastNoData,
    'overall',
    forecastReportData,
    fields,
    varieties,
    fruitClasses,
    compartments,
    productGroups,
    filters.breakdown,
    intl,
    organizationSlug,
    overallExpanded
  ), [
    isForecastNoData,
    forecastReportData,
    fields,
    varieties,
    fruitClasses,
    compartments,
    productGroups,
    filters,
    intl,
    organizationSlug,
    overallExpanded
  ]);

  const byDaysTableData = useMemo(() => getForecastTableData(
    isForecastNoData,
    'byDays',
    forecastReportData,
    fields,
    varieties,
    fruitClasses,
    compartments,
    productGroups,
    filters.breakdown,
    intl,
    organizationSlug,
    []
  ), [
    isForecastNoData,
    forecastReportData,
    fields,
    varieties,
    fruitClasses,
    compartments,
    productGroups,
    filters,
    organizationSlug,
    intl
  ]);

  const overallGraphKeysList = useMemo(() => {
    if (!overallTableData) {
      return [];
    }
    return overallTableData.reduce((memo, item) =>
      [...memo, item.graphKey, ...item.children.map(child => child.graphKey)], []);
  }, [overallTableData]);


  const isOverallAllSelected = useMemo(() =>
    overallGraphKeysList?.length === graphKey?.overall?.length, [overallGraphKeysList, graphKey]);

  const byDaysGraphKeysList = useMemo(() => {
    if (!byDaysTableData) {
      return [];
    }
    return uniq(byDaysTableData.map(item => item.groupKey));
  }, [byDaysTableData]);


  const isByDaysAllSelected = useMemo(() =>
     byDaysGraphKeysList?.length === graphKey?.byDates?.length, [byDaysGraphKeysList, graphKey]);

  const reportBarChartItems = useMemo(() => {
    if (isForecastNoData) {
      return [];
    }

    const items = getReportBarChartItems(
      forecastReportData,
      fields,
      varieties,
      fruitClasses,
      compartments,
      productGroups,
      filters.breakdown,
      graphKey,
      isOverallAllSelected,
      intl,
      false
    );

    if (fields?.overall?.length === 0) {
      return items.map(item => ({
        ...item,
        bars: item.bars.sort((b1, b2) =>
          getCategorySortingValue(b2.category) - getCategorySortingValue(b1.category))
      }));
    }
    const overallIds = fields?.overall.map(field => field.id);
    return items.map(item => ({
      ...item,
      bars: item.bars.sort((b1, b2) => {
        const b1Index = overallIds.findIndex(id => id === b1.category.id);
        const b2Index = overallIds.findIndex(id => id === b2.category.id);
        return b1Index - b2Index;
      })
    }));
  },
  [
    forecastReportData,
    fields,
    varieties,
    fruitClasses,
    compartments,
    productGroups,
    filters,
    graphKey,
    isOverallAllSelected,
    intl,
    isForecastNoData,
  ]);

  const tableLineChartItems = useMemo(() => {
    if (isForecastNoData) {
      return [];
    }

    const items = getLineChartItems(
      forecastReportData,
      fields,
      varieties,
      fruitClasses,
      compartments,
      productGroups,
      filters,
      graphKey,
      isByDaysAllSelected,
      intl,
      false
    );

    if (fields?.byDates?.length === 0) {
      return items.sort((c1, c2) =>
        getCategorySortingValue(c2.category) - getCategorySortingValue(c1.category));
    }
    const byDatesIds = fields?.byDates.map(field => field.id);
    return items.sort((c1, c2) => {
      const b1Index = byDatesIds.findIndex(id => id === c1.id);
      const b2Index = byDatesIds.findIndex(id => id === c2.id);
      return b1Index - b2Index;
    });
  }, [
    forecastReportData,
    fields,
    varieties,
    fruitClasses,
    compartments,
    productGroups,
    filters,
    graphKey,
    isByDaysAllSelected,
    intl,
    isForecastNoData,
  ]);

  const reportBarChartLegend = useMemo(() => {
    if (isForecastNoData) {
      return [];
    }

    return getCategoriesLegend(
      fields.overall,
      intl
    );
  },
  [
    fields,
    intl,
    isForecastNoData,
  ]);

  const overallTableHeader = useMemo(() =>
    reportBarChartLegend.map(item => ({ label: item.name, id: item.id })),
  [
    reportBarChartLegend
  ]);

  const [handlerOverallSelectAllChange] = useMultiChartSelectAll(
    'overall',
    setGraphKey,
    overallGraphKeysList,
    isOverallAllSelected,
    () => {},
    20
  );

  const [handlerByDatesSelectAllChange] = useMultiChartSelectAll(
    'byDates',
    setGraphKey,
    byDaysGraphKeysList,
    isByDaysAllSelected,
    () => {},
    3
  );

  const [handlerOverallGraphChange] = useMultiChartChangeGraphKey(
    'overall',
    graphKey,
    setGraphKey,
    false,
    () => {},
    20,
    showNotificationWithTimeout
  );

  const [handlerByDatesGraphChange] = useMultiChartChangeGraphKey(
    'byDates',
    graphKey,
    setGraphKey,
    true,
    () => {},
    3,
    showNotificationWithTimeout
  );

  const handlerOverallRowClick = useCallback((row) => {
    if (overallExpanded.includes(row.graphKey)) {
      setOverallExpanded([...overallExpanded.filter(item => item !== row.graphKey)]);
    } else {
      setOverallExpanded([...overallExpanded, row.graphKey]);
    }
  }, [overallExpanded, setOverallExpanded]);

  const byDaysTableHeader = useMemo(() => {
    const items = forecastReportData?.descriptor?.periods.map(item => item?.epochMinutes) || [];
    return items.map(dateItem => ({
      label: getFormattedDate(formatMessage, dateItem, 'day'), value: dateItem, id: dateItem
    }));
  }, [forecastReportData, formatMessage]);

  const handlerOverallFieldsChange = useCallback((options) => {
    if (forecastReportData) {
      const {
        categories
      } = forecastReportData;

      setFields({
        fields: {
          overall: options.map(item =>
            ({ ...(categories.find(catItem => catItem.id === item.value)), checked: item.checked }))
        },
        settingsId: forecastReportData?.settingsId
      });
    }
  }, [setFields, forecastReportData]);

  const handlerByDatesFieldsChange = useCallback((options) => {
    if (forecastReportData) {
      const {
        categories
      } = forecastReportData;
      setFields({
        fields: {
          byDates: options.map(item =>
            ({ ...(categories.find(catItem => catItem.id === item.value)), checked: item.checked }))
        },
        settingsId: forecastReportData?.settingsId
      });
    }
  }, [setFields, forecastReportData]);

  const overallTableFields = useMemo(() => (fields?.overall || []).map(catItem => ({
    label: getCategoryName(catItem, intl),
    value: catItem.id,
    checked: catItem.checked
  })), [fields, intl]);

  const byDaysTableFields = useMemo(() => (fields?.byDates || []).map(catItem => ({
    label: getCategoryName(catItem, intl),
    value: catItem.id,
    checked: catItem.checked
  })), [fields, intl]);

  const overallIndicators = useMemo(() => {
    if (!forecastReportData) {
      return [];
    }
    const {
      categories,
      report
    } = forecastReportData;
    const totalData = report.find(item => item.key === 'total')?.data || {};
    return getIndicatorCategories(categories, totalData).map(categoryIndicator => ({
      ...categoryIndicator,
      name: getCategoryName(categoryIndicator.category, intl)
    }));
  }, [forecastReportData, intl]);

  const byDatesIndicators = useMemo(() => {
    if (!forecastReportData) {
      return [];
    }
    const {
      categories,
      table
    } = forecastReportData;
    const totalData = table.find(item => item.key === 'total')?.average || {};
    return getIndicatorCategories(categories, totalData).map(categoryIndicator => ({
      ...categoryIndicator,
      name: `${formatMessage({ id: 'dashboards.avg' })} ${getCategoryName(categoryIndicator.category, intl)}`
    }));
  }, [formatMessage, forecastReportData, intl]);

  const searchVarieties = useMemo(() =>
    filterForecastPlantingData(forecastData, varieties, 'varietyRef'),
  [forecastData, varieties]);

  const searchFruitClasses = useMemo(() =>
    filterForecastPlantingData(forecastData, fruitClasses, 'fruitClassRef'),
  [forecastData, fruitClasses]);

  const searchCompartments = useMemo(() =>
    filterForecastPlantingData(forecastData, compartments, 'compartmentRef'),
  [forecastData, compartments]);

  const onHighlightsUpdate = useCallback((event) => {
    if (event?.target?.value?.length > 1000) {
      setIsHighlightsError(true);

      return null;
    }

    if (isHighlightsError) {
      setIsHighlightsError(false);
    }

    return handlerUpdateHighlightsText(event);
  }, [handlerUpdateHighlightsText, setIsHighlightsError, isHighlightsError]);

  return (
    <div className={styles.reportTabPageContainer}>
      <h3 className={classnames(styles.contentBlockHeader, styles.contentBlockHeaderHighlights)}>{formatMessage({ id: 'forecast.dashboard.highlights' })}</h3>
      <div className={styles.forecastNotes}>
        {/* {forecastData?.comment || formatMessage({ id: 'forecast.defaultComment' })} */}
        {editMode ? (
          <DefaultTextareaInput
            className={styles.userInfoInput}
            inputContainerClassName={styles.inputContainerClassName}
            placeholder={formatMessage({ id: 'forecast.defaultComment' })}
            activePlaceholder={formatMessage({ id: 'forecast.defaultCommentActive' })}
            value={isNull(highlightsText) ? undefined : highlightsText}
            onChange={event => onHighlightsUpdate(event)}
            rows={2}
            error={isHighlightsError ? formatMessage({ id: 'formErrors.maximumNumberOfCharacters' }, { maxValue: 1000 }) : null}
          />
        )
          :
          renderForecastComment(formatMessage, forecastData?.comment)}
      </div>
      <div className={styles.mainContentWrapper}>
        <h3 className={classnames(styles.contentBlockHeader, styles.contentBlockHeaderForecast)}>{formatMessage({ id: 'forecast.dashboard.forecast' })}</h3>
        <div className={styles.filtersWrapper}>
          <VarietySearchSelect
            isLoading={isForecastDataFetching}
            isShowAllSpecies={false}
            enabledSpecies={[forecastData?.species]}
            onSelectOption={handlerVarietyChange}
            varieties={searchVarieties}
            fruitClasses={searchFruitClasses}
            selectedOption={filters.varietyId || filters.fruitClassCode || filters.species || forecastData?.species}
            classNameButton={styles.varietySelect}
            title={formatMessage({ id: 'forecast.dashboard.filters.varietiesSearch' })}
          />
          <SelectBoxFilter
            value={filters.breakdown}
            options={breakdownOptions}
            onChange={handlerBreakdownChange}
            title={formatMessage({ id: 'forecast.dashboard.filters.groupBy' })}
          />
          <CompartmentFilter
            intl={intl}
            compartments={searchCompartments}
            compartmentId={filters.compartmentId}
            disableUseQuery
            onFiltersChange={handlerCompartmentChange}
            title={formatMessage({ id: 'forecast.dashboard.filters.greenhouses' })}
            classNameWrapper={styles.selectboxFilterWrapper}
          />
          <SelectBoxFilter
            value={filters.period}
            options={periodOptions}
            onChange={handlerPeriodChange}
            title={`${formatMessage({ id: 'forecast.dashboard.filters.period' })}:`}
          />
          <SelectBoxFilter
            value={filters.relative === true ? 'kilogramPerSquareMeter' : 'kilogram'}
            options={unitsList}
            onChange={handlerUnitsChange}
            title={`${formatMessage({ id: 'forecast.dashboard.filters.units' })}:`}
            classNameButton={styles.unitsFilter}
          />
        </div>
        <HarvestForecastBarChart
          items={reportBarChartItems}
          legend={reportBarChartLegend}
          isFetching={isForecastReportDataFetching}
          indicators={overallIndicators}
        />
        <HarvestForecastActionsTable
          tableFields={overallTableFields}
          tableHeaders={isForecastNoData ? [] : overallTableHeader}
          breakdown={filters.breakdown}
          isAllSelected={isOverallAllSelected}
          onFieldsChange={handlerOverallFieldsChange}
          onSelectAllChange={handlerOverallSelectAllChange}
          isExpandable
          tableKey='overall'
          graphKey={graphKey}
          isEmpty={overallTableData?.length === 0}
        >
          {overallTableData?.length > 0 ? overallTableData.map(row => (
            <HarvestForecastOverallTableRow
              rows={[row, ...row.children]}
              groupKey={row.groupKey}
              currentGraphKey={graphKey?.overall}
              onGraphChange={handlerOverallGraphChange}
              onRowClick={handlerOverallRowClick}
            />
          )) : (
            <tr>
              <td colSpan={(overallTableHeader?.length || 0) + 2}>
                <div className={styles.emptyState}>{formatMessage({ id: 'emptyStates.noData' })}</div>
              </td>
            </tr>
          )}
        </HarvestForecastActionsTable>
        <HarvestForecastLinesChart
          items={tableLineChartItems}
          isFetching={isForecastReportDataFetching}
          indicators={byDatesIndicators}
        />
        <HarvestForecastActionsTable
          tableFields={byDaysTableFields}
          tableHeaders={byDaysTableHeader}
          breakdown={filters.breakdown}
          isAllSelected={isByDaysAllSelected}
          onFieldsChange={handlerByDatesFieldsChange}
          onSelectAllChange={handlerByDatesSelectAllChange}
          isExpandable={false}
          tableKey='byDates'
          graphKey={graphKey}
          isThreeColumnsFixed
          isEmpty={byDaysTableData?.length === 0 || byDaysTableHeader?.length === 0}
        >
          {(byDaysTableData?.length > 0 && byDaysTableHeader?.length > 0) ? byDaysTableData.map(row => (
            <HarvestForecastByDaysTableRow
              row={row}
              currentGraphKey={graphKey?.byDates}
              onGraphChange={handlerByDatesGraphChange}
            />
          )) : (
            <tr>
              <td colSpan={(byDaysTableHeader?.length || 0) + 3}>
                <div className={styles.emptyState}>{formatMessage({ id: 'emptyStates.noData' })}</div>
              </td>
            </tr>
          )}
        </HarvestForecastActionsTable>
        {editMode && <EditModeDisclaimer />}
      </div>
    </div>
  );
};

HarvestForecastTabPage.propTypes = {
  intl: intlShape.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  varieties: PropTypes.array,
  fruitClasses: PropTypes.array,
  compartments: PropTypes.array,
  productGroups: PropTypes.array,
  filters: PropTypes.object,
  changeReportFilters: PropTypes.func,
  onFiltersChange: PropTypes.func.isRequired,
  clearGraphKeys: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  forecastReportData: PropTypes.object,
  isForecastReportDataFetching: PropTypes.bool,
  isForecastDataFetching: PropTypes.bool,
  setGraphKey: PropTypes.func.isRequired,
  graphKey: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
  forecastData: PropTypes.object,
  handlerUpdateHighlightsText: PropTypes.func.isRequired,
  highlightsText: PropTypes.string,
  showNotificationWithTimeout: PropTypes.func.isRequired,
};

HarvestForecastTabPage.defaultProps = {
  varieties: null,
  fruitClasses: null,
  compartments: null,
  productGroups: null,
  filters: null,
  changeReportFilters: null,
  editMode: false,
  forecastReportData: null,
  isForecastReportDataFetching: false,
  forecastData: null,
  highlightsText: undefined,
  isForecastDataFetching: false
};

export default HarvestForecastTabPage;
