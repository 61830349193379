import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import {
  getAllFruitClasses,
  getAllVarieties,
  getCurrentCompartments,
  getCurrentLocation
} from 'store/company/selectors';

import {
  getAssistantForecast,
  getIsAssistantForecastFetching,
} from 'store/harvestForecast/selectors';

import { requestAssistantForecast, setAssistantPanelSettings } from 'store/harvestForecast/actions';

import HarvestForecastAssistantPanel from '../components/HarvestForecastAssistantPanel';

const mapDispatchToProps = {
  requestAssistantForecast,
  setAssistantPanelSettings
};

const mapStateToProps = (state, props) => ({
  ...props,
  location: getCurrentLocation(state),
  isAssistantFetching: getIsAssistantForecastFetching(state),
  assistant: getAssistantForecast(state),
  varieties: getAllVarieties(state, props),
  fruitClasses: getAllFruitClasses(state, props),
  compartments: getCurrentCompartments(state, props),
  // options: getAssistantPanelSettings(state, props), // FEATURE: Show historic period feature, temporary hided
  options: {
    showHistoricalPeriod: true
  },
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(HarvestForecastAssistantPanel)));
