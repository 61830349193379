import PropTypes from 'prop-types';
import ReactFauxDOM from 'react-faux-dom';
import classnames from 'classnames';
import React, {
  useState, useEffect, useCallback, forwardRef, useMemo
} from 'react';
import * as d3 from 'd3';

import { isFinite } from 'lodash';

import ScaleSettings from 'components/Graphs/components/ScaleSettings';
import useOnClickOutside from 'hooks/useOnClickOutside';

import useHover from 'hooks/useHover';

import styles from './index.module.css';

const ScaleMarker = ({
  scale,
  active,
  onClick,
  hide
}) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(scale);
    }
  }, [scale, onClick]);
  return (
    <svg
      className={classnames(styles.scaleMarker, {
        [styles.hide]: hide
      })}
      width={22}
      height={22}
    >
      <g
        width={22}
        height={22}
        viewBox='0 0 22 22'
        fill='none'
        onClick={handleClick}
        pointerEvents='bounding-box'
        cursor='pointer'
      >
        <rect
          x='0.5'
          y='0.5'
          width='21'
          height='21'
          rx='3.5'
          stroke='#B4C5D6'
          pointerEvents='bounding-box'
          fill={active ? '#E7E9EE' : null}
        />
        {scale?.name ? (
          <text
            x={7}
            y={16}
            fill='#777776'
            fontSize={12}
            fontWeight={600}
          >
            {scale?.name}
          </text>
        ) : (
          <g
            width={12}
            height={12}
            viewBox='0 0 12 12'
            fill='none'
            transform='translate(5,5)'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M8.94628 6.58926C9.53554 6.00001 8.94628 5.41075 8.94628 5.41075L6.58926 3.05373C6.58926 3.05373 6.00001 2.46447 5.41075 3.05373L3.05373 5.41075C3.05373 5.41075 2.46447 6.00001 3.05373 6.58926L5.41075 8.94628C5.41075 8.94628 6.00001 9.53554 6.58926 8.94628L8.94628 6.58926ZM7.17852 1.28596C6.29463 0.402076 5.70538 0.402076 4.82149 1.28596L1.28596 4.82149C0.402076 5.70538 0.402076 6.29463 1.28596 7.17852L4.82149 10.7141C5.70538 11.5979 6.29463 11.5979 7.17852 10.7141L10.7141 7.17852C11.5979 6.29463 11.5979 5.70538 10.7141 4.82149L7.17852 1.28596Z'
              fill='#777776'
            />
          </g>
        )}
      </g>
    </svg>
  );
};

ScaleMarker.propTypes = {
  scale: PropTypes.object,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  hide: PropTypes.bool,

};

ScaleMarker.defaultProps = {
  scale: null,
  active: false,
  onClick: null,
  hide: false
};

const ScaleItem = ({
  nodeWidths,
  index,
  scale,
  handleClickScaleMarker,
  selectedScale,
  isFirstScale,
  onChange,
  onDelete,
  height,
  side,
  mouseCoords,
  showIndicator
}) => {
  const [hoverRef, isHovered] = useHover();
  const dtValue = useMemo(() => scale.d3Scale.invert(mouseCoords.y).toFixed(scale.fraction || 0), [scale, mouseCoords]);
  return (
    <div className={styles.axis} ref={hoverRef}>
      <svg
        width={nodeWidths[index]}
        height={height + 10}
      >
        <g>{scale.node.toReact()}</g>
        {showIndicator && (
          <g>
            <rect rx='5' ry='5' x={0} y={mouseCoords.y - 5} width={nodeWidths[index]} height='20' fill='#4A4A49' />
            <text fontSize='11px' x={nodeWidths[index] / 2} y={mouseCoords.y + 6} fill='white' dominantBaseline='middle' textAnchor='middle'>{dtValue}</text>
          </g>
        )}
      </svg>
      <ScaleMarker
        scale={scale.data}
        onClick={handleClickScaleMarker}
        active={selectedScale && selectedScale?.name === scale?.data?.name}
        hide={isFirstScale && !isHovered}
      />
      {selectedScale && selectedScale?.name === scale?.data?.name && (
        <ScaleSettings
          className={classnames(styles.dropSetupPosition, {
            [styles.rightDrop]: side !== 'left'
          })}
          onChange={onChange}
          onDelete={onDelete}
          {...selectedScale}
        />
      )}
    </div>
  );
};

ScaleItem.propTypes = {
  nodeWidths: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  scale: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  side: PropTypes.string.isRequired,
  selectedScale: PropTypes.object.isRequired,
  handleClickScaleMarker: PropTypes.func.isRequired,
  isFirstScale: PropTypes.bool.isRequired,
  mouseCoords: PropTypes.object,
  showIndicator: PropTypes.bool
};

ScaleItem.defaultProps = {
  mouseCoords: { x: 0, y: 0 },
  showIndicator: false
};

const StackedAxis = forwardRef(({
  scales,
  height,
  intervalCount,
  fractionDigits,
  side,
  onChange,
  onDelete,
  allScales,
  mouseCoords,
  showIndicator
}, ref) => {
  const [yScales, setYScales] = useState([]);

  const [selectedScale, setSelectedScale] = useState(null);

  const [nodeWidths, setNodeWidths] = useState(null);

  const handleClickScaleMarker = useCallback((scale) => {
    setSelectedScale(prevState => (prevState?.name === scale?.name ? null : scale));
  }, [setSelectedScale]);

  const isFirstScale = allScales.length === 1;

  useEffect(() => {
    const usedAxis = side === 'left' ? d3.axisLeft : d3.axisRight;
    const sortedScales = [...scales].sort((left, right) => {
      if (left.name === null) {
        return side === 'left' ? 0 : -1;
      }
      if (right.name === null) {
        return side === 'left' ? -1 : 0;
      }
      return side === 'left' ? right.name.localeCompare(left.name) : left.name.localeCompare(right.name);
    });
    setYScales(
      sortedScales.map(
        (scale) => {
          const node = new ReactFauxDOM.Element('g');
          const startRange = isFinite(scale?.range?.start) ? scale?.range?.start : (scale?.defaultStart || 0);
          let endRange = isFinite(scale?.range?.end) ? scale?.range?.end : (scale?.defaultEnd || 100);
          if (startRange === endRange) {
            endRange += startRange;
          }
          const rangeLen = endRange - startRange;
          const rangeDelta = rangeLen / intervalCount;
          const d3Scale = (scale?.d3ScaleLinear || d3.scaleLinear())
            .range([height || 100, 0])
            .domain([startRange, endRange]);
          d3.select(node).call(
            usedAxis().scale(d3Scale)
              .tickPadding(4)
              .tickSizeInner(0)
              .tickSizeOuter(0)
              .tickValues(
                Array(intervalCount + 1)
                  .fill(null)
                  .map((value, rangeIndex) => {
                    const tickValue = startRange + (rangeIndex * rangeDelta);
                    const fraction = rangeLen <= 10 ? 4 : fractionDigits;
                    return fraction ? tickValue.toFixed(fraction) : tickValue.toFixed(0);
                  })
              )
          ).selectAll('.domain').remove();
          return {
            node,
            data: scale,
            d3Scale,
            fraction: scale?.displayScale > 1 ? scale?.displayScale : 0
          };
        }
      )
    );
  }, [scales, setYScales, height, side, fractionDigits, intervalCount]);


  useEffect(() => {
    const visibleScales = yScales.filter(scale => !scale.data.invisible);
    const scalesBounds = visibleScales.map(scale => scale.node.component.getBBox());
    const widths = scalesBounds.map(rect => rect.width);
    for (let i = 0; i < widths.length; i += 1) {
      if (side === 'left') {
        visibleScales[i].node.component.setAttribute('transform', `translate(${widths[i]}, 5)`);
      } else {
        visibleScales[i].node.component.setAttribute('transform', 'translate(0, 5)');
      }
    }
    setNodeWidths(widths);
  }, [yScales, setNodeWidths, side]);

  useOnClickOutside(ref, () => {
    setSelectedScale(null);
  });

  if (!nodeWidths) {
    return null;
  }

  return (
    <div className={styles.axisStack} ref={ref}>
      {yScales.filter(scale => !scale.data.invisible).map((scale, index) => (
        <ScaleItem
          nodeWidths={nodeWidths}
          index={index}
          scale={scale}
          handleClickScaleMarker={handleClickScaleMarker}
          selectedScale={selectedScale}
          isFirstScale={isFirstScale}
          onChange={onChange}
          onDelete={onDelete}
          height={height}
          side={side}
          mouseCoords={mouseCoords}
          showIndicator={showIndicator}
        />
      ))}
    </div>
  );
});

StackedAxis.propTypes = {
  scales: PropTypes.array,
  height: PropTypes.number,
  intervalCount: PropTypes.number,
  fractionDigits: PropTypes.number,
  side: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  allScales: PropTypes.array,
  showIndicator: PropTypes.bool,
  mouseCoords: PropTypes.object
};

StackedAxis.defaultProps = {
  scales: [],
  height: 500,
  intervalCount: 5,
  fractionDigits: 1,
  side: 'left',
  allScales: [],
  showIndicator: false,
  mouseCoords: null
};

export default StackedAxis;
