import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';
import { find } from 'lodash';

import { LANGUAGES } from '../../helpers/constants';

import { ReactComponent as CloseIcon } from './assets/close.svg';

import styles from './LanguageDialog.module.css';


export default class LanguageDialog extends PureComponent {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    setIntl: PropTypes.func.isRequired,
    onLanguageDialogClose: PropTypes.func.isRequired,
  };

  handlerLanguageSelect = (id) => {
    const { setIntl, onLanguageDialogClose } = this.props;

    if (onLanguageDialogClose) {
      onLanguageDialogClose();
    }

    if (setIntl) {
      setIntl(id);
    }
  };

  handlerPhotoSelectDialogClose = () => {
    const { onLanguageDialogClose } = this.props;

    if (onLanguageDialogClose) {
      onLanguageDialogClose();
    }
  };

  handlerShadowClick = (e) => {
    if (e.target.classList && e.target.classList.contains(styles.dialog)) {
      this.handlerPhotoSelectDialogClose(true);
    }
  };

  renderLanguagesButtons = (locale, languages) => languages.map((language) => {
    const currentLocale = find(languages, { locale });

    return (
      <button
        type='button'
        key={`language-button-${language.localeName}`}
        className={classnames(
          styles.dialogItem,
          {
            [styles.dialogItemSelected]: language.localeName === currentLocale.localeName
          }
        )}
        onClick={() => this.handlerLanguageSelect(language.locale)}
      >
        {language.localeName}
      </button>
    );
  });

  render() {
    const { locale } = this.props;

    const props = {
      onClick: this.handlerShadowClick,
    };

    return (
      <div className={styles.dialog} {...props}>
        <div className={styles.dialogWrapper}>
          <CloseIcon className={styles.dialogClose} onClick={this.handlerPhotoSelectDialogClose} />
          <div className={styles.dialogHeader}>
            <FormattedMessage id='header.account.languageDialogHeader' />
          </div>
          <div className={styles.dialogBody}>
            {this.renderLanguagesButtons(locale, LANGUAGES)}
          </div>
        </div>
      </div>
    );
  }
}
