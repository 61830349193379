import { createSelector } from 'reselect';
import {
 find, flow, get, isEqual
} from 'lodash';

import getGraphThresholdSelectors from 'helpers/getGraphThresholdSelectors';

import {
  PresetMetricScale,
  // Incident,
  MetricDef,
  NodeComponents
} from './types';

import { getLocation } from '../company/selectors';

export const getMetricsSelector = (state:any) => state.graphs.metrics;

export const getPresetsSelector = (state:any) => state.graphs.presets;

export const isMetricsFetchingSelector = (state:any) => state.graphs.isMetricsFetching;

export const isDataPointsFetchingSelector = (state:any) => state.graphs.isDataPointsFetching;

export const getPreset = (state:any) => state.graphs.editedPreset;

export const getOriginalPreset = (state:any) => state.graphs.originalPreset;

export const getDataPoints = (state:any) => state.graphs?.dataPoints;

export const getStateThreshold = (state:any) => state.graphs?.threshold;

export const getRangeHistory = (state:any) => state.graphs?.rangeHistory;

export const defaultGraphPresetMetricsSelector = (state:any) => state.graphs?.defaultGraphPresetMetrics;

export const getReturnAbnormalityIncidents = (state:any) => state.graphs?.returnAbnormalityIncidents;

export const getActiveIncidentId = (state:any) => state.graphs?.activeIncidentId;

export const getCreateIncident = (state: any) => state.graphs?.createIncident;

export const getPresetThreshold = createSelector([
  getPreset,
  getLocation,
  getStateThreshold
], (
  editedPreset,
  location,
  threshold
) => {
  if (threshold !== 0) {
    return threshold;
  }
  const thresholdSelectors: Array<any> = getGraphThresholdSelectors({
    location,
    xRange: editedPreset?.preset?.xRange || 'last2Days',
    xRangeLengthInMins: editedPreset?.preset?.xRangeLengthInMins || null,
    xRangeEnd: editedPreset?.preset?.xRangeEnd || null
  });
  return thresholdSelectors[0].length;
});

export const getMetricDatas = createSelector([
  getDataPoints
], dataPoints => dataPoints?.data || []);

export const getIncidents = createSelector([
  getDataPoints
], dataPoints => dataPoints?.incidents || []);

export const getDataPointsMetrics = createSelector([
  getDataPoints
], dataPoints => dataPoints?.metrics || []);

export const checkIsEditedPreset = createSelector([
  getPreset,
  getOriginalPreset
], (
  editedPreset,
  originalPreset
) => {
  if (!editedPreset) {
    return false;
  }

  const isEditedName = editedPreset.preset.name !== originalPreset.preset.name;
  const isEditedDescription = editedPreset.preset.description !== originalPreset.preset.description;
  const isEditedRange = editedPreset.preset.xRange !== originalPreset.preset.xRange ||
    editedPreset.preset.xRangeEnd !== originalPreset.preset.xRangeEnd ||
    editedPreset.preset.xRangeLengthInMins !== originalPreset.preset.xRangeLengthInMins;

  const normalizeScale = (item:PresetMetricScale) => ({
    name: item.name,
    rightAxis: item.rightAxis,
    invisible: item.invisible,
    rangeStart: item?.range?.start,
    rangeEnd: item?.range?.end
  });

  const normalizeMetric = (item: MetricDef) => ({
    node: [...item.node],
    selected: item.selected,
    // available: item.available,
    scale: item.scale,
    color: item.color,
  });

  const isEditedScales = editedPreset.preset.scales.length !== originalPreset.preset.scales.length || !isEqual(
    editedPreset.preset.scales.map(normalizeScale)
      .sort((a:PresetMetricScale, b:PresetMetricScale) =>
        (a?.name || '').localeCompare(b?.name || '')),
    originalPreset.preset.scales.map(normalizeScale)
      .sort((a:PresetMetricScale, b:PresetMetricScale) =>
        (a?.name || '').localeCompare(b?.name || ''))
  );

  const isEditedMetrics = editedPreset.preset.presetMetrics.length !== originalPreset.preset.presetMetrics.length || !isEqual(
    editedPreset.preset.presetMetrics.map(normalizeMetric)
      .sort((a:MetricDef, b:MetricDef) =>
        (+a.node[NodeComponents.MetricId] || 0) - (+b.node[NodeComponents.MetricId] || 0)),
    originalPreset.preset.presetMetrics.map(normalizeMetric)
      .sort((a:MetricDef, b:MetricDef) =>
        (+a.node[NodeComponents.MetricId] || 0) - (+b.node[NodeComponents.MetricId] || 0))
  );

  return isEditedName || isEditedDescription || isEditedRange || isEditedScales || isEditedMetrics;
});


export const getUserFeedback = createSelector([
  getIncidents,
  getActiveIncidentId
], (
  incidents,
  activeIncidentId
) => flow([
  incidentId => find(incidents, { id: incidentId }),
  activeIncident => get(activeIncident, 'feedbacks[0]'),
])(activeIncidentId));


export const getPresetMetrics = createSelector([
  getIncidents,
  getActiveIncidentId,
  getPreset,
], (
  incidents,
  activeIncidentId,
  editedPreset,
) => {
  if (!editedPreset/* && !activeIncidentId */) {
    return [];
  }
  /*
  const activeIncident = (incidents || []).find((incident: Incident) => incident.id === activeIncidentId);
  if (activeIncident) {
    return activeIncident.diagramState.map((ds:any) => ({ ...ds, ...ds?.nodeMetric, available: true }));
  }
  */
  return editedPreset.preset.presetMetrics;
});

export const getPresetScales = createSelector([
  getIncidents,
  getActiveIncidentId,
  getPreset,
], (
  incidents,
  activeIncidentId,
  editedPreset,
) => {
  if (!editedPreset/* && !activeIncidentId */) {
    return [];
  }
  /*
  const activeIncident = (incidents || []).find((incident: Incident) => incident.id === activeIncidentId);
  return activeIncident ? [{
    name: null,
    rightAxis: false,
    invisible: false,
    range: null
  }] : editedPreset.preset.scales; */
  return editedPreset.preset.scales;
});

export const activeIncidentCollapsedSelector = () => null;

export const getMetricsTree = (state:any) => state.graphs?.metricsTree;

export const getMetricsTreeSearchFilterResult = (state: any) => state.graphs?.treeSearchFilterResult;

export const getMetricsTreeSearchData = (state: any) => state.graphs?.treeSearchData;

export const getAvailableNodeIds = (state: any) => state.graphs?.availableNodeIds;

export const getExpandedKeys = (state: any) => state.graphs?.expandedKeys;

export const getGraphMetricSearch = (state: any) => state.graphs?.graphMetricSearch;

export const getMetricsTreeFilter = (state: any) => state.graphs?.requestMetricsTreeFilter;

export const getIsMetricsPanelFilterFetching = (state: any) => state.graphs?.isMetricsPanelFilterFetching;

