import { isEqual, throttle } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { intlShape } from 'react-intl';
import React, { Component, } from 'react';

import EmptyState from '../EmptyState';
import CircleLoader from '../CircleLoader/CircleLoader';

import styles from './BigTable.module.css';
import circleLoaderStyles from '../CircleLoader/CircleLoader.module.css';


class BigTable extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    uuid: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    onlyFirstColumn: PropTypes.bool,
    showFirstColumn: PropTypes.bool,
    showLastColumn: PropTypes.bool,
    items: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
  };

  static defaultProps = {
    onlyFirstColumn: false,
    showFirstColumn: false,
    showLastColumn: false,
  };

  state = {
    tableScrolled: false,
  };

  componentDidMount() {
    // I picked this because it's approx 1 frame (ie: 16.7ms)
    document.addEventListener('scroll', throttle(this.handlerScroll, 16), true);

    this.scrollToEnd();
  }

  componentDidUpdate(oldProps) {
    const { items: oldItems } = oldProps;
    const { items: nextItems } = this.props;

    if (!isEqual([...oldItems].sort((a, b) => a.id - b.id), [...nextItems].sort((a, b) => a.id - b.id))) {
      this.scrollToEnd();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', throttle(this.handlerScroll, 16), true);
  }

  handlerScroll = (e) => {
    const { tableScrolled } = this.state;
    const { uuid } = this.props;

    if (e.target.className && e.target.className.indexOf(uuid) >= 0) {
      if (e.target.scrollLeft > 0 && !tableScrolled) {
        this.setState({ tableScrolled: true });
      } else if (e.target.scrollLeft === 0 && tableScrolled) {
        this.setState({ tableScrolled: false });
      }
    }
  };

  scrollToEnd = () => {
    if (!this.props.onlyFirstColumn) {
      setTimeout(() => {
        if (this.table && this.wrapper) {
          const componentStyle = window.getComputedStyle(this.table, null);
          const componentWidth = parseInt(componentStyle.getPropertyValue('width'), 10);

          this.wrapper.scrollLeft = componentWidth || 0;
        }
      }, 100);
    }
  };

  renderCell = ({ column, data }) => {
    const { isFetching } = this.props;

    const {
      isHeader,
      isFirstColumn,
      renderValueCell,
      id,
    } = column;


    const className = classnames(
      {
        [styles.indicatorCell]: isFirstColumn,
        [styles.cell]: !isFirstColumn,
        [styles.loading]: isFetching,
      },
      styles.hoverable
    );

    if (isFetching && !data) {
      return (
        <th className={className} key={id}>
          <div className={classnames(styles.loaderWrapper, { [styles.headerLoader]: isHeader })}>
            <div className={styles.loader} />
          </div>
        </th>
      );
    }

    if (isHeader) {
      return (
        <th className={className} key={id}>
          <div className={classnames({ [styles.loading]: isFetching })}>
            {renderValueCell({ column, data })}
          </div>

          {isFetching && (
            <div className={classnames(styles.loaderWrapper, styles.headerLoader)}>
              <div className={classnames(styles.loader)} />
            </div>
          )}
        </th>
      );
    }

    return (
      <td className={className} key={id}>
        <div className={classnames({ [styles.loading]: isFetching })}>
          {renderValueCell({ column, data })}
        </div>

        {isFetching && (
          <div className={styles.loaderWrapper}>
            <div className={styles.loader} />
          </div>
        )}
      </td>
    );
  };

  renderHeaderCell = ({ column }) => {
    const {
      isHeader,
      isFirstColumn,
      renderHeaderCell,
      id,
      onHeaderClick,
    } = column;

    const headerProps = {
      onClick: onHeaderClick,
    };

    let className = classnames(styles.headerCell, styles.headerCellMain);

    if (isFirstColumn) {
      className = classnames(styles.headerCell, styles.indicatorCellHeader, styles.indicatorCellContentHeader);
    }

    if (isHeader) {
      return (
        <th className={className} key={id} {...headerProps}>
          {renderHeaderCell({ column })}
        </th>
      );
    }

    return (
      <td className={className} key={id} {...headerProps}>
        {renderHeaderCell({ column })}
      </td>
    );
  };

  render() {
    const {
      onlyFirstColumn,
      showFirstColumn,
      showLastColumn,

      items,
      columns,
      isFetching,
      intl,
      uuid,
    } = this.props;

    const {
      tableScrolled,
    } = this.state;

    const { formatMessage } = intl;

    const firstColumns = columns.filter(item => item.isFirstColumn);
    const lastColumns = columns.filter(item => !item.isFirstColumn);

    return (
      <div className={classnames('table', styles.extraWrapper, { [styles.onlyFirstColumn]: onlyFirstColumn, [styles.showFirstColumn]: showFirstColumn })}>
        <div className={classnames(styles.wrapper, uuid)} ref={(node) => { this.wrapper = node; }}>
          <table className={classnames(styles.table, { [styles.tableScrolled]: tableScrolled, [styles.empty]: !items.length })} ref={(node) => { this.table = node; }}>
            <thead>
              <tr style={styles.headerRow}>
                {firstColumns.map(column => this.renderHeaderCell({ column }))}
                {!onlyFirstColumn && lastColumns.map(column => this.renderHeaderCell({ column }))}

                {showLastColumn && <th className={classnames(styles.headerCell, styles.lastHeaderCell)} ref={(node) => { this.lastHeaderCell = node; }} />}
              </tr>
            </thead>
            <tbody>
              {items.length ? items
                .map((item) => {
                  const { id, } = item;

                  return (
                    <tr className={styles.row} key={`table-row-${id}`}>
                      {firstColumns.map(column => this.renderCell({ column, data: item }))}
                      {!onlyFirstColumn && lastColumns.map(column => this.renderCell({ column, data: item }))}

                      {showLastColumn && <td className={classnames(styles.cell, styles.lastCell)} />}
                    </tr>
                  );
                }) : null}
              {!items.length && isFetching ? (
                <>
                  <tr className={styles.row} key='table-row-1'>
                    {firstColumns.map(column => this.renderCell({ column }))}
                    {!onlyFirstColumn && lastColumns.map(column => this.renderCell({ column }))}

                    {showLastColumn && <td className={classnames(styles.cell, styles.lastCell)} />}
                  </tr>
                  <tr className={styles.row} key='table-row-2'>
                    {firstColumns.map(column => this.renderCell({ column }))}
                    {!onlyFirstColumn && lastColumns.map(column => this.renderCell({ column }))}

                    {showLastColumn && <td className={classnames(styles.cell, styles.lastCell)} />}
                  </tr>
                  <tr className={styles.row} key='table-row-3'>
                    {firstColumns.map(column => this.renderCell({ column }))}
                    {!onlyFirstColumn && lastColumns.map(column => this.renderCell({ column }))}

                    {showLastColumn && <td className={classnames(styles.cell, styles.lastCell)} />}
                  </tr>
                </>
              ) : null}
            </tbody>
          </table>
          {!isFetching && !onlyFirstColumn && !items.length && (
            <div className={styles.empty}>
              <EmptyState isInline header='' text={formatMessage({ id: 'cropsPerformance.emptyStateSpecies' })} />
            </div>
          )}
          {isFetching && (
            <div className={styles.circleLoaderWrapper}>
              <CircleLoader
                showOnlyBackground={onlyFirstColumn}
                className={circleLoaderStyles.circleLoader}
                iconClassName={circleLoaderStyles.circleLoaderIcon}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default BigTable;
