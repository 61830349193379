import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { DndProvider } from 'react-dnd';
import update from 'immutability-helper';
import Backend from 'react-dnd-html5-backend';
import withScrolling from 'react-dnd-scrolling';

import classnames from 'classnames';

import Select from 'components/Select';

import styles from './DefaultConfigurableSelect.module.css';
import DraggableOption from '../DraggableOption';

const ScrollingComponent = withScrolling('div');

class DefaultConfigurableSelect extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    options: PropTypes.array.isRequired, // Обязательно должны содержать поля label, valuе и checked
    onChange: PropTypes.func.isRequired, // Желательно заворачивать в throttle

    placeholder: PropTypes.oneOfType([ // Передавать, если нет placeholderText
      PropTypes.string,
      PropTypes.node
    ]),
    className: PropTypes.string,
  };

  static defaultProps = {
    placeholder: null,
    className: null,
  };

  handlerMove = (dragIndex, hoverIndex) => {
    const {
      options,
      onChange,
    } = this.props;

    const draggedOption = options[dragIndex];

    onChange(update(options, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedOption],
      ],
    }));
  };

  renderOptions = options => options.map((option, i) => (
    <DraggableOption
      key={`option-${option.value}`}
      className={styles.checkboxItem}
      option={option}
      title={this.props.intl.formatMessage({ id: `${option.label}` })}
      options={options}
      index={i}
      onChange={this.props.onChange}
      onMove={this.handlerMove}
    />
  ));

  render() {
    const { options, className, placeholder } = this.props;

    return (
      <div className={classnames(styles.selectWrapper, className)}>
        <Select
          classNamePopup={styles.selectPopup}
          classNameButton={styles.selectButton}
          placeholder={placeholder}
          theme='transparent'
          size='medium'
          isPopupRight
          textEllipsis
          withoutArrow
        >
          <DndProvider backend={Backend}>
            <ScrollingComponent className={styles.checkboxesContainer}>
              {this.renderOptions(options)}
            </ScrollingComponent>
          </DndProvider>
        </Select>
      </div>
    );
  }
}

export default injectIntl(DefaultConfigurableSelect);
