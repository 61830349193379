import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import LinesGraph from 'components/LinesGraph';
import LinesGraphTooltip from 'components/LinesGraphTooltip/LinesGraphTooltip';
import HarvestForecastChartWrapper from 'components/HarvestForecastDashboard/components/HarvestForecastChartWrapper';

import {
  customTicksFunction,
  formatTooltipValue,
  getLinesMaxValue,
  getLinesMinValue,
  tickTimeDisplayFormat,
  getTicksRange
} from 'components/HarvestForecastAssistantPanel/helpers';


const renderTooltipContent = (tooltipDate, items, intl) => {
  if (!tooltipDate) {
    return null;
  }

  const { formatMessage } = intl;

  const dataKey = tooltipDate.format('MM-DD-YYYY');

  const lines = items.map((item) => {
    const point = item.points.find(p => p.x === dataKey);
    const unitsText = formatMessage({ id: `cunits.mini.${item.units}` });
    return point && point?.y !== null && point?.y !== undefined ? {
      value: formatTooltipValue(point?.y, unitsText),
      header: item.name,
      ...item,
      id: item.key
    } : null;
  }).filter(item => item !== null);

  if (lines.length === 0) {
    return null;
  }

  return (
    <LinesGraphTooltip
      lines={lines}
      tooltipDate={tooltipDate}
      isSpaced
      periodType='day'
    />
  );
};

const HarvestForecastLinesChart = ({
  intl,
  items,
  isFetching,
  indicators
}) => {
  const { formatMessage } = intl;

  const rightLines = items.filter(item => item?.category?.categoryType === 'ACCURACY');
  const leftLines = items.filter(item => item?.category?.categoryType !== 'ACCURACY');

  const rightMaxValue = useMemo(() => getLinesMaxValue(rightLines), [rightLines]);
  const rightMinValue = useMemo(() => getLinesMinValue(rightLines), [rightLines]);

  const leftMaxValue = useMemo(() => getLinesMaxValue(leftLines), [leftLines]);
  const leftMinValue = useMemo(() => getLinesMinValue(leftLines), [leftLines]);

  const isEmpty = useMemo(() =>
    !isFetching &&
      (!leftLines || !leftLines.length || leftLines.every(line => !line.points || !line.points.length)) &&
      (!rightLines || !rightLines.length || rightLines.every(line => !line.points || !line.points.length)),
  [isFetching, rightLines, leftLines]);

  const renderTooltipContentMemo = useCallback(tooltipDate =>
    renderTooltipContent(tooltipDate, items, intl),
  [items, intl]);

  return (
    <HarvestForecastChartWrapper
      legend={items}
      indicators={indicators}
      roundTo={-1}
      title={formatMessage({ id: 'forecast.dashboard.lineChartTitle' })}
      tooltipText={formatMessage({ id: 'forecast.dashboard.byDatesTooltip' })}
    >
      <LinesGraph
        isDataFetching={isFetching}
        lines={leftLines}
        maxValue={leftMaxValue}
        minValue={leftMinValue}
        rightLines={rightLines}
        rightMaxValue={rightMaxValue}
        rightMinValue={rightMinValue}
        renderTooltipContent={renderTooltipContentMemo}
        isEmpty={isEmpty}
        emptyTitle={formatMessage({ id: 'emptyStates.noData' })}
        isLegendDisabled
        isEmbeddedEmptyState
        withoutPoints
        defaultSize={{
          height: 292
        }}
        cropEmptyValues
        xTicks={5}
        yTicks={4}
        tickTimeDisplayFormat={tickTimeDisplayFormat}
        customTicksFunction={customTicksFunction}
        customGetTicksRange={getTicksRange}
      />
    </HarvestForecastChartWrapper>
  );
};

HarvestForecastLinesChart.propTypes = {
  intl: intlShape.isRequired,
  items: PropTypes.array,
  isFetching: PropTypes.bool,
  indicators: PropTypes.array
};

HarvestForecastLinesChart.defaultProps = {
  items: [],
  indicators: [],
  isFetching: false,
};

export default injectIntl(HarvestForecastLinesChart);
