/* eslint-disable */
// TODO: включить тут линтер и навести порядок
import moment from 'moment-timezone';
import * as d3 from 'd3';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import classnames from 'classnames';


import styles from './index.module.css';

import storageWrapper from '../../../../helpers/storageWrapper';
import GraphsTooltip from '../Tooltip';
import numbersRounding from '../../../../helpers/numbersRounding';
import colors from '../../../../helpers/colors';
import compareGraphPresetMetrics from "../../../../helpers/compareGraphPresetMetrics";
import compareGraphMetricToMetricData from "../../../../helpers/compareGraphMetricToMetricData";
import getGraphKey from "../../../../helpers/getGraphKey";
import { getHarvestMetric } from '../../../../helpers/checkHarvestMetrics';
import isEqual from "lodash/isEqual";

const safeLocalStorage = storageWrapper.get('localStorage');


class Chart extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    selectedMetric: PropTypes.object.isRequired,
    cycle: PropTypes.object,
    location: PropTypes.object,
    currentDate: PropTypes.object.isRequired,
    maxDate: PropTypes.object,
    minDate: PropTypes.object,
    wrapperOffsetX: PropTypes.number,
    selectedMetricId: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    leftMetrics: PropTypes.array,
    preparedWeeklyData: PropTypes.array,
    preparedHarvestWeeklyData: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    showHarvest: PropTypes.bool.isRequired,
    isLeftWeeklyDataFetching: PropTypes.bool.isRequired,
    isRightWeeklyDataFetching: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    selectedMetricId: null,
    leftMetrics: [],
    preparedWeeklyData: [],
    preparedHarvestWeeklyData: [],
    location: null,
    wrapperOffsetX: 0,
    cycle: null,
    width: 100,
    height: 100,
    minDate: null,
    maxDate: null,
    showHarvest: false,
  };

  constructor(...props) {
    super(...props);

    this.x = d3.scaleUtc();
    this.yLeft = d3.scaleLinear();
    this.yRight = d3.scaleLinear();
  }

  state = {
    hoverDate: null,
    showTooltip: false,
    margin: {
      top: 10,
      right: window.innerWidth < 720 ? 32 : 54, // 50
      bottom: window.innerWidth < 720 ? 28 : 44,
      left: 54,
    },
    ticksCount: {
      x: 12,
      y: 3,
    },
  };

  componentDidMount() {
    const props = this.props;
    const state = this.state;

    document.addEventListener('click', this.handlerDocumentClick);
    document.addEventListener('touchstart', this.handlerDocumentTouchStart);
    document.addEventListener('scroll', this.handlerScroll);

    setTimeout(() => {
      this.handlerResize(props, state);
    }, 10);
  }

  componentDidUpdate(prevProps) {
    const {
      preparedWeeklyData: oldPreparedWeeklyData,
      preparedHarvestWeeklyData: oldPreparedHarvestWeeklyData,
      width: prevWidth,
      showHarvest: prevShowHarvest,
      currentDate: prevCurrentDate,
    } = prevProps;

    const {
      preparedWeeklyData: nextPreparedWeeklyData,
      preparedHarvestWeeklyData: nextPreparedHarvestWeeklyData,
      width: nextWidth,
      showHarvest: nextShowHarvest,
      currentDate: nextCurrentDate,
    } = this.props;

    if (!isEqual(nextPreparedHarvestWeeklyData, oldPreparedHarvestWeeklyData) || !isEqual(nextPreparedWeeklyData, oldPreparedWeeklyData) || prevWidth !== nextWidth || prevShowHarvest !== nextShowHarvest || !prevCurrentDate.isSame(nextCurrentDate)) {
      this.handlerResize(this.props, this.state);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResize);

    document.removeEventListener('click', this.handlerDocumentClick);
    document.removeEventListener('touchstart', this.handlerDocumentTouchStart);
    document.removeEventListener('scroll', this.handlerScroll);
  }

  getBarsCount = () => {
    const width = window.innerWidth;

    if (width < 480) {
      return 14;
    }

    if (width < 720) {
      return 28;
    }

    if (width < 960) {
      return 42;
    }

    return 56;
  };

  getBarsWidth = () => {
    if (window.innerWidth < 1024) {
      return 8;
    }

    return 12;
  };

  getScaleDomain = ({ selectedMetricDatas, scale }) => {
    if (!selectedMetricDatas || !selectedMetricDatas.length) {
      scale.domain([0, 100]);
    } else {
      const min = Math.floor(d3.min(selectedMetricDatas));
      const max = Math.ceil(d3.max(selectedMetricDatas));

      if (min === 0 && max === 0 || Number.isNaN(min) && Number.isNaN(max)) {
        scale.domain(d3.extent([0, 100]));
      } else if (min === max) {
        scale.domain(d3.extent([min - min, max + max]));
      } else {
        const deltaData = max - min;
        const delta = deltaData || max;

        const maxDelta = Math.ceil(delta * 0.04);

        scale.domain(d3.extent(
          [{ value: 0 }, { value: max + maxDelta }],
          d => d.value
        ));
      }
    }
  };

  calculateTicksForVerticalAxis(yDomain) {
    const delimeter = this.state.ticksCount.y - 1;
    const min = yDomain[0];
    const max = yDomain[1];
    const delta = max - min;
    const step = delta / delimeter;
    return d3.range(min, max + step, step);
  }

  handlerDocumentClick = async (e) => {
    const { showTooltip, hoverDate } = this.state;

    if (showTooltip && hoverDate) {
      if (!e.target.closest(`.${styles.chart}`)) {
        this.setState({
          showTooltip: false,
          hoverDate: null,
        });
      }
    }
  };

  handlerDocumentTouchStart = (e) => {
    const { touches } = e;

    if (touches && touches.length) {
      const touch = touches[0];

      const { showTooltip, hoverDate } = this.state;

      if (showTooltip && hoverDate) {
        if (!touch.target.closest(`.${styles.chart}`)) {
          this.setState({
            showTooltip: false,
            hoverDate: null,
          });
        }
      }
    }
  };

  handlerScroll = () => {
    const { hoverDate, showTooltip } = this.state;

    if (showTooltip && hoverDate) {
      this.setState({
        showTooltip: false,
        hoverDate: null,
      });
    }
  };

  handlerTouchStart = (e) => {
    const { touches } = e;

    if (touches && touches.length) {
      const { clientX, clientY } = touches[0];

      this.handlerMouseMove({ clientX, clientY });
    }
  };

  handlerTouchEnd = () => {};

  handlerTouchMove = (e) => {
    const { touches } = e;

    if (touches && touches.length) {
      const { clientX, clientY } = touches[0];

      this.handlerMouseMove({ clientX, clientY });
    }
  };

  handlerMouseMove = (e) => {
    const { preparedWeeklyData, currentDate, width } = this.props;
    const threshold = this.getBarsCount({ width });
    const rect = this.chartOverlay.getBoundingClientRect();
    const data = preparedWeeklyData.filter(({ date }) => date.clone().isoWeekday(1).isSameOrAfter(currentDate) && date.clone().isoWeekday(7).isSameOrBefore(currentDate.clone().add(threshold, 'weeks')));
    const xValue = this.x.invert(e.clientX - rect.left);
    const bisectDateLeft = d3.bisector(d => d.date).left;
    const iLeft = bisectDateLeft(data, xValue);
    const d0 = data[iLeft - 1];
    const d1 = data[iLeft];

    let d = null;

    if (d0 && d1) {
      d = xValue - d0.date > d1.date - xValue ? d1 : d0;
    } else if (d0 || d1) {
      d = d1 || d0;
    }

    if (d) {
      this.setState({
        hoverDate: d.date.format('YYYY-MM-DD'),
        showTooltip: true,
        screenY: e.clientY,
        screenX: e.clientX,
      });
    } else {
      this.setState({
        screenY: e.clientY,
        screenX: e.clientX,
        hoverDate: null,
        showTooltip: false,
      });
    }
  };

  handlerMouseEnter = () => {};

  handlerMouseLeave = () => {
    this.setState({
      showTooltip: false,
      hoverDate: null,
    });
  };

  handlerResize = (props, state) => {
    const { currentDate, width, showHarvest } = props;
    const { margin } = state;
    const newMargin = {
      ...margin,
      right: showHarvest ? 54 : window.innerWidth < 720 ? 32 : 54,
      bottom: window.innerWidth < 720 ? 28 : 44,
    };

    this.calculateDomains(props);
    this.calculateHorizontalAxis({ threshold: this.getBarsCount({ width }), currentDate, width, margin: newMargin });
    this.renderAxises({ threshold: this.getBarsCount({ width }), margin: newMargin });

    this.setState({ margin: newMargin, });
  };

  calculateDomains(props) {
    const {
      preparedWeeklyData,
      preparedHarvestWeeklyData,
      location,
      height,
      currentDate,
      width,
    } = props;

    const threshold = this.getBarsCount({ width });

    this.yLeft.range([height, 0]);
    this.yRight.range([height, 0]);

    if (location && preparedWeeklyData && preparedWeeklyData.length) {
      const metricDatasY = preparedWeeklyData
        .filter(({ date }) => date.isSameOrAfter(currentDate) && date.isSameOrBefore(currentDate.clone().add(threshold, 'weeks')))
        .map(item => item.value);

      this.getScaleDomain({ selectedMetricDatas: metricDatasY, scale: this.yLeft });
    }

    if (location && preparedHarvestWeeklyData && preparedHarvestWeeklyData.length) {
      const harvestDatasY = preparedHarvestWeeklyData
        .filter(({ date }) => date.isSameOrAfter(currentDate) && date.isSameOrBefore(currentDate.clone().add(threshold, 'weeks')))
        .map(item => item.value);

      this.getScaleDomain({ selectedMetricDatas: harvestDatasY, scale: this.yRight });
    }
  }

  calculateHorizontalAxis = ({ threshold, currentDate, width: wrapperWidth, margin }) => {
    const width = wrapperWidth - margin.left - margin.right;

    const fromDate = currentDate.clone();

    const toDate = fromDate
      .clone()
      .add(threshold, 'weeks')
      .subtract(1, 'seconds');

    this.x.domain(d3.extent([+fromDate, +toDate])).range([0, width]);
  };

  renderAxises = ({ threshold, margin }) => {
    const { width: wrapperWidth } = this.props;
    const width = wrapperWidth - margin.left - margin.right;


    this.xAxisScale = d3.axisBottom().scale(this.x)
      .tickFormat(date => d3.utcFormat("%b '%y")(date))
      .ticks(Math.floor(threshold / 4))
      .tickPadding(12)
      .tickSizeInner(-4)
      .tickSizeOuter(0);

    this.yLeftAxisScale = d3.axisLeft().scale(this.yLeft)
      .tickValues(this.calculateTicksForVerticalAxis(this.yLeft.domain()))
      .tickPadding(12)
      .tickSizeInner(-width)
      .tickSizeOuter(0);

    this.yRightAxisScale = d3.axisRight().scale(this.yRight)
      .tickValues(this.calculateTicksForVerticalAxis(this.yRight.domain()))
      .tickPadding(12)
      .tickSizeInner(0)
      .tickSizeOuter(0);

    d3.select(this.axisX).call(this.xAxisScale);
    d3.select(this.axisYLeft).call(this.yLeftAxisScale);
    d3.select(this.axisYRight).call(this.yRightAxisScale);

    const ticks = d3.select(this.axisX).selectAll('.tick');

    ticks.attr('time', (d) => {
      if (moment(d).isAfter(moment().isoWeekday(7))) {
        return 'after';
      }

      return 'before';
    });
  };

  renderBars = () => {
    const {
      preparedWeeklyData,
      currentDate,
      height: wrapperHeight,
      width,
    } = this.props;

    const {
      hoverDate,
    } = this.state;

    const threshold = this.getBarsCount({ width });

    const height = wrapperHeight;

    const metricDatasFiltered = preparedWeeklyData.filter(({ date }) => date.clone().isoWeekday(1).isSameOrAfter(currentDate) && date.clone().isoWeekday(7).isSameOrBefore(currentDate.clone().add(threshold, 'weeks')));

    const d = metricDatasFiltered.find(({ date }) => {
      return date.format('YYYY-MM-DD') === hoverDate;
    });

    return preparedWeeklyData
      .filter(({ date }) => date.isSameOrAfter(currentDate) && date.isSameOrBefore(currentDate.clone().add(threshold, 'weeks')))
      .map(({ value, date }) => {
        return (
          <rect
            key={btoa(date.format())}
            className={classnames(styles.bar, { [styles.hover]: d && d.date.isSame(date), [styles.noData]: value === null })}
            x={this.x(date) - Math.round(this.getBarsWidth() / 2)}
            y={value === null || value === 0 ? height - 2 : (height - this.yLeft(value) < 2 ? height - 2 : this.yLeft(value))}
            width={this.getBarsWidth()}
            height={value === null || value === 0 ? 2 : height - this.yLeft(value) < 2 ? 2 : height - this.yLeft(value)}
            data-date={date.format('YYYY-MM-DD')}
          />
        );
      });
    };

  renderLine = () => {
    const { preparedHarvestWeeklyData, currentDate, width } = this.props;

    const threshold = this.getBarsCount({ width });

    const items = preparedHarvestWeeklyData.filter(({ date }) => date.isSameOrAfter(currentDate) && date.isSameOrBefore(currentDate.clone().add(threshold, 'weeks')));

    const line = d3.line()
      .x(d => this.x(d.date))
      .y(d => this.yRight(d.value) || 0);

    let path = line(items);

    return <path className={classnames(styles.path, styles.harvest)} d={path} />;
  };

  renderCircle = ({ y }) => {
    const { preparedHarvestWeeklyData } = this.props;
    const { hoverDate } = this.state;

    if (hoverDate) {
      const d = preparedHarvestWeeklyData.find(({ date }) => {
        return date.format('YYYY-MM-DD') === hoverDate;
      });

      const xLinePosition = d ? this.x(d.date) : -100;
      const yLinePosition = d ? this.yRight(d.value) : -100;

      return (
        <g>
          <circle
            className={classnames(styles.dataPointTransparent)}
            r='6'
            cx={xLinePosition}
            cy={yLinePosition}
          />
          <circle
            className={classnames(styles.dataPoint)}
            r='4'
            cx={xLinePosition}
            cy={yLinePosition}
          />
        </g>
      );
    }
    return null;
  };

  render() {
    const {
      preparedWeeklyData,
      preparedHarvestWeeklyData,
      width: wrapperWidth,
      height,
      wrapperOffsetX,
      showHarvest,
      selectedMetric,
      leftMetrics,
      intl,
    } = this.props;

    const {
      margin,
      showTooltip,
      screenY,
      hoverDate,
    } = this.state;

    const harvestMetric = getHarvestMetric(leftMetrics);

    const width = wrapperWidth - margin.left - margin.right;

    const { formatMessage } = intl;

    const func = this.x;

    const d = preparedWeeklyData.find(({ date }) => {
      return date.format('YYYY-MM-DD') === hoverDate;
    });

    const dHarvest = preparedHarvestWeeklyData.find(({ date }) => {
      return date.format('YYYY-MM-DD') === hoverDate;
    });

    const xLinePosition = d ? func(d.date) : -100;

    const defaultOffsetY = d ? d.value === null ? 17 : height - this.yLeft(d.value) + 15 : 0;
    const defaultHarvestOffsetY = dHarvest ? dHarvest.value === null ? 18 : height - this.yRight(dHarvest.value) + 16 : 0;

    let metricName = '';
    let harvestName = '';

    const locale = safeLocalStorage.getItem('locale');

    if (selectedMetric.attributes.name[locale]) {
      metricName = selectedMetric.attributes.name[locale];
    } else if (selectedMetric.attributes.name.en) {
      metricName = `[${selectedMetric.attributes.name.en}]`;
    }

    if (harvestMetric) {
      if (harvestMetric.attributes.name[locale]) {
        harvestName = harvestMetric.attributes.name[locale];
      } else if (harvestMetric.attributes.name.en) {
        harvestName = `[${harvestMetric.attributes.name.en}]`;
      }
    }

    const sortedItems = [
      {
        key: 'data-popup-point',
        value: d && d.value !== null ? numbersRounding(d ? d.value : 0) : formatMessage({ id: 'crops.noData' }),
        date: d ? d.date : null,
        name: metricName,
        description: null,
        isSingleGroupCheck: true,
        color: colors[1],
        cunit: d && d.value !== null ? selectedMetric.attributes.cunit : null,
        selected: true,
      },
    ];

    if (showHarvest) {
      sortedItems.push({
        key: 'harvest-popup-point',
        value: dHarvest && dHarvest.value !== null ? numbersRounding(dHarvest ? dHarvest.value : 0, 'fixed', 2) : formatMessage({ id: 'crops.noData' }),
        date: dHarvest ? dHarvest.date : null,
        name: harvestName,
        description: null,
        isSingleGroupCheck: true,
        color: '#873679',
        cunit: dHarvest && dHarvest.value !== null ? harvestMetric.attributes.cunit : null,
        selected: true,
      });
    }

    const tooltipDate = sortedItems[0] && sortedItems[0].date ? moment(sortedItems[0].date).tz('UTC') : moment.tz('UTC');

    const name = selectedMetric.attributes.name[locale || 'en'] || `[${selectedMetric.attributes.name.en}]`;

    const date = tooltipDate.clone().isoWeekday(1).year() !== moment().year() ?
      `${tooltipDate.clone().isoWeekday(1).format(`${formatMessage({ id: 'date.dayMonth' })}, YYYY`)} – ${tooltipDate.clone().isoWeekday(7).format(`${formatMessage({ id: 'date.dayMonth' })}, YYYY`)}` :
      `${tooltipDate.clone().isoWeekday(1).format(`${formatMessage({ id: 'date.dayMonth' })}`)} – ${tooltipDate.clone().isoWeekday(7).format(`${formatMessage({ id: 'date.dayMonth' })}`)}`;

    return (
      <div
        className={styles.chart}
        onTouchMove={this.handlerTouchMove}
        onTouchStart={this.handlerTouchStart}
        onTouchEnd={this.handlerTouchEnd}
        onMouseMove={this.handlerMouseMove}
        onMouseEnter={this.handlerMouseEnter}
        onMouseLeave={this.handlerMouseLeave}
      >
        {d && sortedItems && sortedItems.length > 0 && (
          <GraphsTooltip
            intl={intl}
            items={sortedItems}
            date={date}
            week={tooltipDate.isoWeek()}
            screenX={xLinePosition + wrapperOffsetX + 54}
            screenY={screenY}
            showTooltip={showTooltip}
            parent={styles.chartOverlay}
            defaultOffsetY={defaultHarvestOffsetY > defaultOffsetY ? defaultHarvestOffsetY : defaultOffsetY}
          />
        )}
        <svg
          ref={(element) => {
            this.svg = element;
          }}
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g transform={`translate(${margin.left},${margin.top})`}>
            <g
              className={styles.axisX}
              transform={`translate(0,${height})`}
              ref={(element) => {
                this.axisX = element;
              }}
            />
            <g
              className={classnames(styles.axisY)}
              ref={(element) => {
                this.axisYLeft = element;
              }}
            />
            {showHarvest && (
              <g
                className={classnames(styles.axisY)}
                transform={`translate(${width},0)`}
                ref={(element) => {
                  this.axisYRight = element;
                }}
              />
            )}
            <g width={width + margin.left + margin.right} height={height} clipPath='url(#clip)'>
              {this.renderBars()}
            </g>
            {showHarvest && (
              <g width={width + margin.left + margin.right} height={height} clipPath='url(#clip)'>
                {this.renderLine()}
              </g>
            )}
            {showHarvest && showTooltip && (
              <g width={width + margin.left + margin.right} height={height + margin.top + margin.bottom} clipPath='url(#clip)'>
                {this.renderCircle({ y: this.yRight })}
              </g>
            )}
            <g>
              <rect
                width={width}
                height={height}
                className={classnames(styles.chartOverlay)}
                ref={(element) => { this.chartOverlay = element; }}
              />
            </g>
          </g>
        </svg>
        {showHarvest && (
          <div className={styles.legend}>
            <div className={classnames(styles.legendItem)}>
              <div className={styles.legendItemColor}>
                <div className={classnames(styles.legendItemColorSquare, styles.metric)} />
              </div>
              <div className={styles.legendItemTitle}>{name}</div>
            </div>
            <div className={classnames(styles.legendItem)}>
              <div className={styles.legendItemColor}>
                <div className={classnames(styles.legendItemColorSquare, styles.harvest)} />
              </div>
              <div className={styles.legendItemTitle}>{formatMessage({ id: 'crops.harvest' })}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Chart;
