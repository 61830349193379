import { groupBy } from 'lodash';

import {
  LocalizationUtilOptions,
  MetricDef, MetricGroupInput,
  NodeComponents,
  Preset,
  TreeNodeIdTypes,
} from 'store/graphs/types';

import getSpeciesCode from './getSpeciesCode';

import getSpeciesTitle from './getSpeciesTitle';
import getPlantingCycleTitle from './getPlantingCycleTitle';

export type MetricGroupPath = {
  type: TreeNodeIdTypes;
  locationId: number;
  input: MetricGroupInput;
};

export type MetricGroupView = {
  key: string;
  path: MetricGroupPath;
  metrics: Array<MetricDef>;
};

export type MetricGroupsViewEntity = {
  key: TreeNodeIdTypes;
  metricGroups: Array<MetricGroupView>;
};

export type MetricGroupsView = Array<MetricGroupsViewEntity>;

export function buildMetricPath(refMetric: MetricDef, {
  formatMessage,
  locale,
  allCompartments,
  allSubNodes,
  allVarieties,
  allFruitClasses,
  allPlantingCycles
} : LocalizationUtilOptions): MetricGroupPath | null {
  const type = refMetric.node[NodeComponents.TreeType];
  if (type === 'g') {
    return {
      locationId: +refMetric.node[NodeComponents.LocationId],
      type,
      input: {
        firstNode: allCompartments.find(item => item.id === refMetric.node[NodeComponents.CompartmentId]) || {
          id: 0,
          type: 'Compartment',
          attributes: {
            name: formatMessage({ id: 'graphs.sidePanel.commonLocationMetrics' })
          }
        },
        secondNode: allSubNodes.find(item => item.id === refMetric.node[NodeComponents.SubNodeId]) || {
          id: 0,
          type: 'SubNode',
          attributes: {
            name: formatMessage({ id: 'graphs.sidePanel.commonCompartmentMetrics' })
          }
        }
      }
    };
  }
  if (type === 'c') {
    const speciesId = +refMetric.node[NodeComponents.SpeciesId];
    const speciesCode = getSpeciesCode(speciesId);
    const cycle = allPlantingCycles.find(item => item.id === refMetric.node[NodeComponents.PlantingCycleId]);
    return {
      locationId: +refMetric.node[NodeComponents.LocationId],
      type,
      input: {
        firstNode: speciesId !== 0 ? {
          id: speciesId,
          type: 'Species',
          code: speciesCode,
          attributes: {
            name: getSpeciesTitle(speciesCode, formatMessage)
          }
        } : {
          id: 0,
          type: 'Species',
          code: 'all',
          attributes: {
            name: formatMessage({ id: 'dashboards.allSpecies' })
          }
        },
        secondNode: cycle ? {
          id: +refMetric.node[NodeComponents.PlantingCycleId],
          type: 'PlantingCycle',
          attributes: {
            ...cycle.attributes,
            name: getPlantingCycleTitle(
              cycle,
              allCompartments,
              allVarieties,
              allFruitClasses,
              formatMessage,
              locale
            )
          }
        } : {
          id: 0,
          type: 'PlantingCycle',
          attributes: {
            name: formatMessage({ id: 'dashboards.allPlantingCycles' })
          }
        }
      }
    };
  }
  return null;
}

export function groupMetrics(metrics: Array<MetricDef>, options: LocalizationUtilOptions) {
  const grouped = groupBy(metrics, item => [item.node[NodeComponents.Node], item.node[NodeComponents.SubNode]]);
  const groupKeys = Object.keys(grouped);
  return groupKeys.map((key: string) => ({
    key,
    path: buildMetricPath(grouped[key][0], options), // TODO: it stinks
    metrics: grouped[key]
  }));
}

export function buildMetricsTreeGroups(
  preset: Preset, options: LocalizationUtilOptions
): MetricGroupsView {
  const {
    preset: {
      presetMetrics
    }
  } = preset;
  const groupedByTreeType = groupBy(presetMetrics, item => item.node[NodeComponents.TreeType]);
  const treeTypeKeys = Object.keys(groupedByTreeType);
  // @ts-ignore
  return treeTypeKeys.map(key => ({
    key,
    metricGroups: groupMetrics(groupedByTreeType[key], options)
  }));
}
