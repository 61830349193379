import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import classnames from 'classnames';

import { ReactComponent as ToggleIcon } from './assets/icon.svg';
import { ReactComponent as MobileDateIcon } from './assets/date_icon.svg';

import styles from './DateButton.module.css';

class DateButton extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    className: undefined,
    onClick: null,
    date: null,
    disabled: false,
  };

  render() {
    const {
      intl: { formatMessage }, className, date, disabled, onClick,
    } = this.props;

    return (
      <button
        type='button'
        className={classnames(styles.button, className, { [styles.disabled]: disabled })}
        onClick={onClick}
        disabled={disabled}
      >
        <span className={styles.placeholder}>
          {formatMessage({ id: 'harvest.date' })}
        </span>
        {date}
        <ToggleIcon className={styles.icon} />
        <MobileDateIcon className={styles.mobileIcon} />
      </button>
    );
  }
}

export default injectIntl(DateButton);
