import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import SelectboxFilterWithChecks from 'components/SelectboxFilterWithChecks';
import Select from 'components/Select';

import styles from './SeverityFilter.module.css';

const SeverityFilter = ({
  intl,
  onFiltersChange,
  selectedOption,
  isMobile,
  selectComponent,
                          onSetPlaceholder
}) => {
  const { formatMessage, formatPlural } = intl;

  const options = useMemo(() => ({
      options: [{
        value: 'critical',
        label: formatMessage({ id: 'incidentsTimeline.severity.critical' }),
        checked: selectedOption.includes('critical'),
      }, {
        value: 'warning',
        label: formatMessage({ id: 'incidentsTimeline.severity.warning' }),
        checked: selectedOption.includes('warning'),
      }, {
        value: 'potential',
        label: formatMessage({ id: 'incidentsTimeline.severity.potential' }),
        checked: selectedOption.includes('potential'),
      }],
      title: formatMessage({ id: 'incidentsTimeline.filters.severity' }),
      onChange: (changes) => {
        onFiltersChange({
          severity: changes.filter(item => item.checked).map(item => item.value)
        });
      }
    }), [selectedOption, onFiltersChange, formatMessage]);

  const placeholder = useMemo(() => {
    if (selectedOption.length === 0 || selectedOption.length === options.options.length) {
      return formatMessage({ id: 'incidentsTimeline.filters.allLevels' });
    }
    const count = selectedOption.length === 1 && selectedOption[0] === 'none' ? 0 : selectedOption.length;
    const pluralLevels = formatMessage({
      id: `incidentsTimeline.filters.selectedLevelsPlural.${formatPlural(count)}`
    });
    return `${count} ${pluralLevels}`;
  }, [formatMessage, selectedOption, formatPlural, options]);

  useEffect(() => {
    if (onSetPlaceholder) {
      onSetPlaceholder('severity', placeholder);
    }
  }, [onSetPlaceholder, placeholder]);

  return (
    <SelectboxFilterWithChecks
      {...options}
      classNameWrapper={styles.selectboxFilterWrapper}
      isMobile={isMobile}
      selectComponent={selectComponent}
      customPlaceholder={isMobile ? formatMessage({ id: 'incidentsTimeline.filters.mobile.severity' }) : placeholder}
      customTitle={isMobile ? placeholder : null}
      possibleSelectAll
    />
  );
};

SeverityFilter.propTypes = {
  intl: intlShape.isRequired,
  onFiltersChange: PropTypes.func,
  selectedOption: PropTypes.array,
  isMobile: PropTypes.bool,
  selectComponent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  onSetPlaceholder: PropTypes.func
};

SeverityFilter.defaultProps = {
  onFiltersChange: null,
  selectedOption: [],
  isMobile: false,
  selectComponent: Select,
  onSetPlaceholder: null
};

export default SeverityFilter;
