import { Observable, BehaviorSubject } from 'rxjs';

// exhaustMap, but plays the last event https://github.com/ReactiveX/rxjs/issues/1777
function auditMap(project, resultSelector) {
  return Observable.using(
    () => ({
      running$: new BehaviorSubject(false)
    }),
    ({ running$ }) =>
      this
        .audit(() => running$
          .first(running => !running)
          .do(() => running$.next(true)))
        .concatMap(
          (value, index) => Observable.from(project(value, index))
            .do(null, null, () => running$.next(false)),
          resultSelector
        )
  );
}

export default auditMap;
