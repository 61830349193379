import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { get } from 'lodash';

import {
  getLeftCompartment,
  getLeftPlantingCycle, getPlantingCyclePhotoCategories,
} from 'store/company/selectors';

import { getLeftPhotosMap } from 'store/photos/selectors';

import CellPhoto from './CellPhoto';


export const getPhotoCategory = (state, props) => props?.category;
export const getWeek = (state, props) => props?.week;
export const getYear = (state, props) => props?.year;

export const makeGetPhoto = () => createSelector(
  [getPhotoCategory, getWeek, getYear, getLeftPhotosMap],
  (photoCategory, week, year, photosMap) => {
    const findedPhoto = get(photosMap, `${photoCategory}/${year}/${week}`);

    return findedPhoto || {
      attributes: {
        category: photoCategory,
      },
    };
  }
);

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => {
  const getPhoto = makeGetPhoto();

  return ({
    photo: getPhoto(state, props),
    photosMap: getLeftPhotosMap(state, props),
    photoCategories: getPlantingCyclePhotoCategories(state, props),
    plantingCycle: getLeftPlantingCycle(state, props),
    compartment: getLeftCompartment(state, props),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(CellPhoto);
