import React, {
 useCallback, useMemo, useRef, useState
} from 'react';
import classnames from 'classnames';

import { isFinite } from 'lodash';

// @ts-ignore
import { injectIntl, InjectedIntlProps } from 'react-intl';

import * as d3 from 'd3';

// @ts-ignore
import moment from 'moment-timezone';

import CircleLoader from 'components/CircleLoader';

import useDimension from 'hooks/useDimension';

// @ts-ignore
import ReactTooltip from 'react-tooltip';
import tooltipStyles from 'components/Tooltip/index.module.css';

import getDatesRange from 'helpers/getDatesRange';
import getDatesRangeDiff from 'helpers/getDatesRangeDiff';

import ChartSelectionIcon from 'components/Icons/ChartSelectionIcon';
import WarningIcon from 'components/Icons/WarningIcon';

import {
  Preset,
  GraphsPresetRange,
  Incident,
  Line,
  Location,
  PresetMetricScale, TreeNodePathComponents, Margin,
} from 'store/graphs/types';

import {
  ChangeScale,
  CheckNewIncidentStatus,
  ClearRangeHistory,
  CloseIncidentEditor,
  CreateIncident,
  DeleteScale,
  PopRangeHistory,
  RequestNewIncidentId,
  SetActiveIncidentId,
  SetMetricColor,
  SetShowIncidents,
} from 'store/graphs/actions';

import { RangeHistory } from 'store/graphs/reducers';

import getIsExpert from 'helpers/getIsExpert';

import { ChartLegendItemProps } from './components/ChartLegend/components/ChartLegendItem';

import ChartLegend from './components/ChartLegend';
import ChartLines from './components/ChartLines';

import StackedAxis from '../StackedAxis';

import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { ReactComponent as ResetIcon } from './assets/reset.svg';
import { ReactComponent as ZoomOutIcon } from './assets/zoom_out.svg';

import styles from './index.module.css';

type LinesChartProps = {
  lines: Array<Line>;
  enableMove: "BOTH" | "BACK" | "FORWARD";
  isFetching: boolean;
  range: GraphsPresetRange;
  margin: Margin;
  location: Location;
  scales: Array<PresetMetricScale>;
  incidents: Array<Incident>;
  activeIncidentId: number | null;
  returnAbnormalityIncidents: boolean;
  activeIncidentCollapsed: boolean;
  rangeHistory: Array<RangeHistory>;
  preset: Preset;
  defaultMinY: number;
  defaultMaxY: number;
  threshold: number;
  createdIncident: Incident | null;
  requestNewIncidentId: RequestNewIncidentId;
  permissions: Array<any>;
};

export type OnWalkRangeFunc = (range: GraphsPresetRange) => void;

export type OnScaleRangeFunc = (range: GraphsPresetRange) => void;

export type OnOpenShowIncidentPopupFunc = () => void;

type LineChartFunc = {
  setMetricColor: SetMetricColor;
  changeScale: ChangeScale;
  deleteScale: DeleteScale;
  walkRange: OnWalkRangeFunc;
  scaleRange: OnScaleRangeFunc;
  popRangeHistory: PopRangeHistory;
  clearRangeHistory: ClearRangeHistory;
  setShowIncidents: SetShowIncidents;
  setActiveIncidentId: SetActiveIncidentId;
  openShowIncidentPopup: OnOpenShowIncidentPopupFunc;
  createIncident: CreateIncident;
  closeIncidentEditor: CloseIncidentEditor;
  checkNewIncidentStatus: CheckNewIncidentStatus;
};

const checkRange = (
  direction: "BACK" | "FORWARD",
  range: GraphsPresetRange,
  location: Location
) => {
  const { xTo, xFrom } = getDatesRange({
    ...range,
    location,
  });

  const { type, diff } = getDatesRangeDiff({
    ...range,
    location,
  });

  const {
    attributes: { timezone },
  } = location;
  const zoneOffset = -moment.tz.zone(timezone).offset(+moment.tz(timezone));
  const start = moment
    .tz('UTC')
    .utc()
    .year(2016)
    .month(0)
    .date(1)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);

  const end = moment
    .tz(timezone)
    .add(zoneOffset, 'minutes')
    .utc()
    .add(1, 'days')
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);

  return {
    xTo: xTo.clone(),
    xFrom: xFrom.clone(),
    type,
    diff,
    isDisabled:
      direction === 'BACK'
        ? xFrom.subtract(diff, type).isBefore(start)
        : xTo.add(diff, type).isAfter(end),
  };
};

const height = 420; // TODO: To props

const tooltipId = 'chart-lines-buttons-tooltipId';

const LinesChart = ({
  intl,
  location,
  scales,
  margin,
  range,
  lines,
  enableMove,
  isFetching,
  incidents,
  activeIncidentId,
  returnAbnormalityIncidents,
  activeIncidentCollapsed,
  setShowIncidents,
  setActiveIncidentId,
  openShowIncidentPopup,
  setMetricColor,
  changeScale,
  deleteScale,
  walkRange,
  scaleRange,
  rangeHistory,
  popRangeHistory,
  clearRangeHistory,
  preset,
  defaultMinY,
  defaultMaxY,
  threshold,
  createdIncident,
  createIncident,
  requestNewIncidentId,
  closeIncidentEditor,
  checkNewIncidentStatus,
  permissions,
}: LinesChartProps & LineChartFunc & InjectedIntlProps) => {
  const { formatMessage } = intl;

  const chartWrapperRef = useRef<HTMLDivElement>(null);

  const [hiddenLines, setHiddenLines] = useState<Array<Array<TreeNodePathComponents>>>([]);

  const handlerZoomOut = useCallback(() => {
    popRangeHistory();
  }, [popRangeHistory]);
  const handlerReset = useCallback(() => {
    clearRangeHistory();
  }, [clearRangeHistory]);

  const handlerMove = useCallback(
    (dir: "BACK" | "FORWARD") => {
      const result = checkRange(dir, range, location);
      if (!result.isDisabled) {
        const end =
          dir === 'BACK'
            ? result.xTo.clone().subtract(result.diff, result.type)
            : result.xTo.clone().add(result.diff, result.type);
        walkRange({
          xRange: 'custom',
          xRangeEnd: end.format('YYYY-MM-DDTHH:mm:ss'),
          xRangeLengthInMins: result.xTo
            .add(1, 'minutes')
            .diff(result.xFrom, 'minutes'),
        });
      }
    },
    [walkRange, range, location]
  );

  const handlerSelectedLegendItem = useCallback(
    (toggledMetric: Array<TreeNodePathComponents>) => {
      const allLineMetrics = lines.filter((line: Line) => line.selected);
      setHiddenLines((prevState: Array<Array<TreeNodePathComponents>>) => {
        const currentHiddenLineMetrics = prevState.filter(
          (metric: Array<TreeNodePathComponents>) =>
            allLineMetrics.findIndex(
              (line: Line) =>
                metric.join('_') !== line.node?.join('_')
            ) !== -1
        );

        if (currentHiddenLineMetrics.length === 0) {
          return allLineMetrics
            .map((line: Line) => line.node)
            .filter(
              (metric: Array<TreeNodePathComponents>) =>
                metric.join('_') !== toggledMetric.join('_')
            );
        }
        if (prevState.length === allLineMetrics.length - 1 &&
          !prevState.some((metric: Array<TreeNodePathComponents>) =>
            metric.join('_') === toggledMetric.join('_'))) {
          return [];
        }
        if (currentHiddenLineMetrics.findIndex((metric: Array<TreeNodePathComponents>) =>
          metric.join('_') === toggledMetric.join('_')) === -1) {
          currentHiddenLineMetrics.push([...toggledMetric]);
          return currentHiddenLineMetrics;
        }
        return currentHiddenLineMetrics.filter(
          (metric: Array<TreeNodePathComponents>) =>
            metric.join('_') !== toggledMetric.join('_')
        );
      });
    },
    [setHiddenLines, lines]
  );

  const handlerChangeColorLegendItem = useCallback(
    (metric: Array<TreeNodePathComponents>, color: string) => {
      setMetricColor({
        metric,
        color,
      });
    },
    [setMetricColor]
  );

  const handleChangeRange = useCallback(
    (newRange: GraphsPresetRange) => {
      scaleRange(newRange);
    },
    [scaleRange]
  );

  const handlerScaleChange = useCallback(
    (scale: PresetMetricScale) => {
      changeScale({
        scale,
      });
    },
    [changeScale]
  );

  const handlerScaleDelete = useCallback(
    (scale: PresetMetricScale) => {
      if (scale?.name) {
        deleteScale({
          scaleName: scale.name,
        });
      }
    },
    [deleteScale]
  );

  const { width } = useDimension(chartWrapperRef);

  const allScales = useMemo(
    () => {
      const defaultScalesMin = [];
      const defaultScalesMax = [];
      for (let iLine = 0; iLine < lines.length; iLine += 1) {
        const line = lines[iLine];
        if (line?.scale?.name === null && line?.selected) {
          let min = defaultMinY;
          let max = defaultMaxY;
          if (isFinite(line?.scale?.range?.start) && isFinite(line?.scale?.range?.end)) {
            min = line?.scale?.range?.start;
            max = line?.scale?.range?.end;
          } else if (isFinite(line?.defaultStart) && isFinite(line?.defaultEnd)) {
            min = line?.defaultStart;
            max = line?.defaultEnd;
          }
          defaultScalesMin.push(min);
          defaultScalesMax.push(max);
        }
      }
      const defaultMinValue = Math.min(...defaultScalesMin) || 0;
      const defaultMaxValue = Math.max(...defaultScalesMax) || 100;

      return lines.reduce((acc: Array<any>, line: any) => {
        const isExistDefaultScale =
          acc.findIndex(scale => scale?.name === null) !== -1;
        if (!isExistDefaultScale && line?.scale?.name === null) {
          acc.push({
            lineKey: null,
            ...line.scale,
            d3ScaleLinear: d3
              .scaleLinear()
              .range([height, 0])
              .nice()
              .domain([defaultMinValue, defaultMaxValue]),
            defaultStart: defaultMinY,
            defaultEnd: defaultMaxY,
            displayScale: line.displayScale,
          });
        } else if (line?.scale?.name !== null) {
          let min = 0;
          let max = 100;
          if (line.scale?.range) {
            min = line.scale.range.start;
            max = line.scale.range.end;
          } else if (isFinite(line?.defaultStart) && isFinite(line?.defaultEnd)) {
            min = line?.defaultStart;
            max = line?.defaultEnd;
          } else {
            min = defaultMinY;
            max = defaultMaxY;
          }
          const foundScale = acc.find(
            sItem => sItem.name === line?.scale?.name
          );
          if (foundScale) {
            foundScale.lineKey.push(line.key);
            foundScale.defaultStart = Math.min(foundScale.defaultStart, min);
            foundScale.defaultEnd = Math.max(foundScale.defaultEnd, max);
            foundScale.d3ScaleLinear = d3
              .scaleLinear()
              .range([height, 0])
              .nice()
              .domain([foundScale.defaultStart, foundScale.defaultEnd]);
          } else {
            acc.push({
              lineKey: [line.key],
              ...line.scale,
              d3ScaleLinear: d3
                .scaleLinear()
                .range([height, 0])
                .nice()
                .domain([min, max]),
              defaultStart: min,
              defaultEnd: max,
              displayScale: line.displayScale,
            });
          }
        }
        return acc;
      }, []);
    },
    // eslint-disable-next-line
    [scales, defaultMinY, defaultMaxY, lines, height]
  );


  const leftScales = useMemo(
    () =>
      allScales.filter((scale: any) => !scale.invisible && !scale.rightAxis),
    [allScales]
  );

  const rightScales = useMemo(
    () => allScales.filter((scale: any) => !scale.invisible && scale.rightAxis),
    [allScales]
  );

  const charLines = useMemo(
    () =>
      lines
        .filter((line: Line) => line?.data?.length > 0 && line.selected)
        .map((line: Line) => {
          const lineScale =
            line?.scale?.name !== null
              ? allScales.find((scale: any) =>
                  scale?.lineKey?.includes(line.key))
              : allScales.find((scale: any) => scale?.lineKey === null);
          const isHidden = hiddenLines.findIndex(
            (metric: Array<TreeNodePathComponents>) =>
              metric.join('_') === line?.node?.join('_')
          );
          return {
            ...line,
            yScale: lineScale?.d3ScaleLinear,
            selected: isHidden === -1,
          };
        }),
    [lines, allScales, hiddenLines]
  );

  const legendItems = useMemo<Array<ChartLegendItemProps>>(
    () =>
      lines
        .filter((line: Line) => line.selected)
        .map((line: Line) => ({
          itemKey: line.key,
          title: line.title,
          description: line.description,
          selected: line.selected,
          rightAxis: line.rightAxis,
          color: line.color,
          node: line.node,
          isSingleGroupCheck: line.isSingleGroupCheck,
        })),
    [lines]
  );

  const isExpert = getIsExpert({
    permissions,
    organization: location,
    activeIncidentId,
    abnormalityIncidents: incidents,
  });

  const [isAddAbnormalityIncidentsMode, setIsAddAbnormalityIncidentsMode] =
    useState(false);

  const handlerToggleShowIncidents = () => {
    setShowIncidents({
      returnAbnormalityIncidents: !returnAbnormalityIncidents,
    });
  };

  const handlerAddIncidentsModeToggle = () => {
    setIsAddAbnormalityIncidentsMode(prevState => !prevState);
  };

  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 });

  const handlerUpdateInnerMouseCoords = useCallback(
    (coords) => {
      setMouseCoords(coords);
    },
    [setMouseCoords]
  );

  const [mouseOver, setMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setMouseOver(true);
  };
  const handleMouseLeave = () => {
    setMouseOver(false);
  };

  // @ts-ignore
  return (
    <div className={styles.multichart}>
      <div className={styles.chartWrapper}>
        <button
          type='button'
          className={classnames(styles.walkButton, styles.leftWalkButton, {
            [styles.disabled]: enableMove !== 'BOTH' && enableMove !== 'BACK',
          })}
          onClick={() => handlerMove('BACK')}
        >
          <ArrowIcon className={styles.icon} />
        </button>
        <div className={styles.axisWrapper}>
          {leftScales.length > 0 && (
            <StackedAxis
              // @ts-ignore
              side='left'
              scales={leftScales}
              height={420}
              width={width}
              allScales={allScales}
              onChange={handlerScaleChange}
              onDelete={handlerScaleDelete}
              mouseCoords={mouseCoords}
              showIndicator={mouseOver}
            />
          )}
          {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          <div
            className={styles.resizeWrapper}
            ref={chartWrapperRef}
            onMouseOver={handleMouseEnter}
            onMouseOut={handleMouseLeave}
          >
            <ChartLines
              incidents={incidents}
              activeIncidentId={activeIncidentId}
              returnAbnormalityIncidents={returnAbnormalityIncidents}
              setActiveIncidentId={setActiveIncidentId}
              openShowIncidentPopup={openShowIncidentPopup}
              activeIncidentCollapsed={activeIncidentCollapsed}
              isAddAbnormalityIncidentsMode={isAddAbnormalityIncidentsMode}
              createdIncident={createdIncident}
              lines={charLines}
              width={width - margin.left - margin.right - 8}
              margin={margin}
              range={range}
              location={location}
              height={420}
              hoverMode='BOTH'
              onChangeRange={handleChangeRange}
              preset={preset}
              threshold={threshold}
              createIncident={createIncident}
              requestNewIncidentId={requestNewIncidentId}
              closeIncidentEditor={closeIncidentEditor}
              checkNewIncidentStatus={checkNewIncidentStatus}
              onUpdateInnerMouseCoords={handlerUpdateInnerMouseCoords}
            />
            <div className={styles.chartButtonsContainer}>
              {isExpert && (
                <button
                  type='button'
                  className={classnames(styles.graphSelectionButton, {
                    [styles.graphSelectionButtonActive]:
                      isAddAbnormalityIncidentsMode,
                  })}
                  onClick={handlerAddIncidentsModeToggle}
                  data-tip={formatMessage({
                    id: 'graphIncidents.addIncidentTooltip',
                  })}
                  data-for={tooltipId}
                >
                  <ChartSelectionIcon />
                </button>
              )}
              <button
                type='button'
                className={classnames(styles.graphWarningButton, {
                  [styles.graphWarningButtonActive]: returnAbnormalityIncidents,
                })}
                onClick={handlerToggleShowIncidents}
                data-tip={formatMessage({
                  id: 'graphIncidents.showAllIncidentsTooltip',
                })}
                data-for={tooltipId}
              >
                <WarningIcon />
              </button>
            </div>
            {rangeHistory?.length > 0 ? (
              <div className={styles.historyButtons}>
                <button
                  type='button'
                  className={styles.historyButton}
                  onClick={handlerZoomOut}
                >
                  <ZoomOutIcon />
                </button>
                <div className={styles.historyButtonsDelimeter} />
                <button
                  type='button'
                  className={styles.historyButton}
                  onClick={handlerReset}
                >
                  <ResetIcon />
                </button>
              </div>
            ) : null}
          </div>
          {rightScales.length > 0 && (
            <StackedAxis
              // @ts-ignore
              side='right'
              scales={rightScales}
              height={420}
              width={width}
              allScales={allScales}
              onChange={handlerScaleChange}
              onDelete={handlerScaleDelete}
              mouseCoords={mouseCoords}
              showIndicator={mouseOver}
            />
          )}
        </div>
        {isFetching && (
          <CircleLoader
            className={styles.circleLoader}
            iconClassName={styles.circleLoaderIcon}
          />
        )}
        <button
          type='button'
          className={classnames(styles.walkButton, styles.rightWalkButton, {
            [styles.disabled]:
              enableMove !== 'BOTH' && enableMove !== 'FORWARD',
          })}
          onClick={() => handlerMove('FORWARD')}
        >
          <ArrowIcon className={styles.icon} />
        </button>
      </div>
      <ChartLegend
        items={legendItems}
        onSelected={handlerSelectedLegendItem}
        onChangeColor={handlerChangeColorLegendItem}
        hiddenLines={hiddenLines}
      />
      <ReactTooltip
        className={tooltipStyles.smallTooltip}
        id={tooltipId}
        effect='solid'
        html
      />
    </div>
  );
};

export default injectIntl(LinesChart);
