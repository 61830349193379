import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';

import { uniqueId } from 'lodash';

import useMountEffect from 'hooks/useMountEffect';
import { showIntercom } from 'helpers/intercomHelpers';
import { getNameByLocal } from 'helpers/getNameByLocal';

import PageViewTracker from 'components/PageViewTracker';
import Typography from 'components/Typography';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import BigButton from 'components/BigButton';
import DefaultPlate from 'components/DefaultPlate';
import DefaultTable from 'components/DefaultTable';

import styles from './SettingsReports.module.css';

const renderHeaderRows = formatMessage => (
  <tr>
    <th className={styles.th}>{formatMessage({ id: 'reports.report' })}</th>
    <th className={styles.th}>{formatMessage({ id: 'plantingCycles.plantingCycles' })}</th>
    <th className={styles.th}>{formatMessage({ id: 'reports.charts' })}</th>
  </tr>
);

const renderRows = reportsSettings => reportsSettings.map((report) => {
  const graphsList = report?.graphs || '—';

  return (
    <tr key={uniqueId('report-settings-row-')}>
      <td className={styles.td}>{report.name || '—'}</td>
      <td className={styles.td}>{report.cycles || '—'}</td>
      <td className={styles.td}>{graphsList}</td>
    </tr>
  );
});

const SettingsReports = ({
  intl: { formatMessage, locale },
  cropReportsAccess,
  reportsSettings,
  isFetching,
  defaultOpen,

  trackPageViewSettings,
  requestCropReportsSettings,
}) => {
  const { organizationSlug } = useParams();
  const history = useHistory();

  useMountEffect(() => {
    if (!cropReportsAccess) {
      history.push(`/${organizationSlug}/settings/profile`);
    }

    requestCropReportsSettings();
  });

  const handlerPageView = useCallback(() => {
    trackPageViewSettings({ settingName: 'Reports', defaultOpen });
  }, [trackPageViewSettings, defaultOpen]);

  const reportsSettingsFormatted = reportsSettings.map((settingItem) => {
    const name = getNameByLocal(settingItem, locale);
    const cycles = settingItem.species ? formatMessage({ id: `plantingCycles.speciesPlural.${settingItem.species}` }) : undefined;
    const graphs = settingItem.plots?.reduce((acc, plot) => {
      const localizedGraphName = getNameByLocal(plot, locale);

      if (localizedGraphName) {
        return [...acc, localizedGraphName];
      }

      return acc;
    }, []);

    return {
      name,
      cycles,
      graphs: graphs?.length > 0 ? graphs.join(', ') : undefined,
    };
  });

  return (
    <div className={styles.settingsReports}>
      <PageViewTracker onMount={handlerPageView} />

      {isFetching && <DefaultCircleLoader />}

      <Typography variant='h2' className={styles.header}>
        {formatMessage({ id: 'reports.cropCyclesReports' })}
      </Typography>
      <Typography variant='subtitle3' className={styles.subtitle}>
        {formatMessage({ id: 'reports.manageReports' })}
      </Typography>
      <DefaultPlate className={styles.infoPlate}>
        <div>
          {formatMessage({ id: 'reports.changeConfiguration' })}
          &nbsp;
          <BigButton
            className={styles.supportButton}
            onClick={showIntercom}
            title={formatMessage({ id: 'harvestDashboard.contactSupport' })}
            theme='transparent'
          />
          .
        </div>
      </DefaultPlate>
      <div className={styles.tableWrapper}>
        <DefaultTable
          headerRows={renderHeaderRows(formatMessage)}
          bodyRows={renderRows(reportsSettingsFormatted)}
          fixedColumnOnMobile
          small
        />
      </div>
    </div>
  );
};

SettingsReports.propTypes = {
  intl: intlShape.isRequired,
  cropReportsAccess: PropTypes.bool,
  reportsSettings: PropTypes.object,
  isFetching: PropTypes.bool,
  defaultOpen: PropTypes.bool,

  requestCropReportsSettings: PropTypes.func.isRequired,
  trackPageViewSettings: PropTypes.func.isRequired,
};

SettingsReports.defaultProps = {
  cropReportsAccess: false,
  reportsSettings: [],
  isFetching: false,
  defaultOpen: false,
};

export default SettingsReports;
