import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getAllCompartments, getAllPlantingCycles,
  getAllVarieties,
  getIsAddPhotoAvailable,
  getOrganization, getPlantingCyclePhotoCategories,
  getUserProfile,
} from 'store/company/selectors';

import { trackPageViewPlantingCycle } from 'store/newCrops/actions';
import {
  setTogglePhotoDeleteDialog, setTogglePhotoDetailDialog, setTogglePhotoUploadDialog, setToggleErrorDialog
} from 'store/dialogs';
import { deletePhoto, setDeletedPhoto, setSelectedPhoto } from 'store/photos/actions';
import { getLeftPlantingCycle, getSplitCompartmentsPhotos } from 'store/company/actions';

import Photos from './Photos';


const mapDispatchToProps = {
  trackPageViewPlantingCycle,
  setTogglePhotoDeleteDialog,
  setTogglePhotoUploadDialog,
  setTogglePhotoDetailDialog,
  setSelectedPhoto,
  setDeletedPhoto,
  deletePhoto,
  getLeftPlantingCycle,
  getSplitCompartmentsPhotos,
  setToggleErrorDialog,
};

const mapStateToProps = state => ({
  userProfile: getUserProfile(state),
  location: getOrganization(state),
  allPlantingCycles: getAllPlantingCycles(state),
  varieties: getAllVarieties(state),
  compartments: getAllCompartments(state),
  photoCategories: getPlantingCyclePhotoCategories(state),
  leftPlantingCycle: getLeftPlantingCycle(state),
  isAddPhotoAvailable: getIsAddPhotoAvailable(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Photos));
