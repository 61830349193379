import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import HarvestTable from './HarvestTable';

const mapDispatchToProps = {
};

const mapStateToProps = () => ({});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HarvestTable));
