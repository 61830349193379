import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Typography from 'components/Typography';
import { FormattedMessage } from 'react-intl';

import Speedometer from 'components/HarvestKpiDashboard/components/Speedometer/Speedometer';

import styles from './RealizedIndicator.module.css';

const RealizedIndicator = ({ realized, wrappedClassName }) => {
  const {
    percentage: {
      units,
      amount
    },
    model: {
      amount: modelAmount
    },
    cycle: {
      amount: cycleAmount
    }
  } = realized;
  return (
    <div className={classnames(styles.container, wrappedClassName)}>
      <div className={styles.titleWrapper}>
        <Typography variant='h4'>
          <FormattedMessage id='unrealizedPotential.realizedPotentialTitle' />
        </Typography>
      </div>
      <div className={styles.speedometerWrapper}>
        <Speedometer
          percent={amount}
          color='blue'
          radius={75}
          strokeWidth={28}
          wrapperPadding={30}
          size='S'
          units={units}
          currentValueText={`${cycleAmount || '—'} of ${modelAmount || '—'}`}
          percentBoxClassName={styles.percentBoxClassName}
          currentValueBoxClassName={styles.currentValueBoxClassName}
          withoutTooltip
        />
      </div>
    </div>
  );
};

RealizedIndicator.propTypes = {
  realized: PropTypes.object.isRequired,
  wrappedClassName: PropTypes.string,
};

RealizedIndicator.defaultProps = {
  wrappedClassName: null
};

export default RealizedIndicator;
