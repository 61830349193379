import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';


import { requestForecastNotificationSubscribers, clearForecastNotificationSubscribers } from 'store/harvestForecast/actions';
import { getNotificationSubscribers, getIsSubscribersFetching } from 'store/harvestForecast/selectors';
import { requestUserPrivileges } from 'store/user/actions';
import {
  getSortedUserPrivileges,
  getIsUserPrivilegesFetching,
} from 'store/user/selectors';

import HarvestForecastModalPublish from '../components/HarvestForecastModalPublish';

const mapDispatchToProps = {
  requestForecastNotificationSubscribers,
  clearForecastNotificationSubscribers,
  requestUserPrivileges,
};

const mapStateToProps = (state, props) => ({
  ...props,

  notificationSubscribers: getNotificationSubscribers(state, props),
  isSubscribersFetching: getIsSubscribersFetching(state, props),

  userPrivileges: getSortedUserPrivileges(state),
  isUserPrivilegesFetching: getIsUserPrivilegesFetching(state),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HarvestForecastModalPublish));
