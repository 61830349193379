import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getLocation,
  getBenchmarkAllProducts,
  getBenchmarkAllLocations,
  getBenchmarkAllCompartments,
  getBenchmarkAllFruitClasses,
  getBenchmarkAllVarieties,
} from 'store/company/selectors';

import {
  getReportFilters,
} from 'store/harvestForecast/selectors';

import { getCyclesToCompare, getAllowedPlantingCycles } from 'store/benchmark/selectors';
import {
  addCycleToComparison,
  removeCycleFromComparison,
  replaceAllCyclesInComparison,
} from 'store/benchmark/actions';

import { showNotificationWithTimeout } from 'store/notificationCenter/actions';


import BenchmarkAddCycleDialog from './BenchmarkAddCycleDialog';

const mapDispatchToProps = {
  addCycleToComparison,
  removeCycleFromComparison,
  replaceAllCyclesInComparison,
  showNotificationWithTimeout,
};

const mapStateToProps = (state, props) => ({
  ...props,

  varieties: getBenchmarkAllVarieties(state, props),
  fruitClasses: getBenchmarkAllFruitClasses(state, props),
  compartments: getBenchmarkAllCompartments(state, props),
  filters: getReportFilters(state, props),
  plantingCycles: getAllowedPlantingCycles(state, props),
  cyclesToCompare: getCyclesToCompare(state, props),
  benchmarkAllLocations: getBenchmarkAllLocations(state),
  location: getLocation(state, props),
  sortedPlantingCycles: getBenchmarkAllProducts(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BenchmarkAddCycleDialog));
