/* eslint-disable */

import { find, get } from 'lodash';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import classnames from 'classnames';


import CircleLoader from '../CircleLoader/CircleLoader';
import ChartWrapper from './components/ChartWrapper';

import styles from './CropsChart.module.css';


class CropsChart extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    location: PropTypes.object,
    cycleId: PropTypes.string,
    selectedMetricId: PropTypes.number,
    harvestWeeklyData: PropTypes.array,
    metricsWeeklyData: PropTypes.array,
    leftMetrics: PropTypes.array,
    cycleInfo: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    showHarvest: PropTypes.bool.isRequired,
    isLeftWeeklyDataFetching: PropTypes.bool.isRequired,
    isRightWeeklyDataFetching: PropTypes.bool.isRequired,
    setShowHarvest: PropTypes.func.isRequired,
    trackClickShowHarvest: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedMetricId: null,
    cycleInfo: null,
    leftMetrics: [],
    harvestWeeklyData: [],
    metricsWeeklyData: [],
    location: null,
    cycleId: null,
  };

  render() {
    const {
      intl,
      location,
      leftMetrics,
      metricsWeeklyData,
      harvestWeeklyData,
      selectedMetricId,
      isFetching,
      isLeftWeeklyDataFetching,
      isRightWeeklyDataFetching,
      cycleInfo: currentCycle,
      cycleId,
      setShowHarvest,
      showHarvest,
      trackClickShowHarvest,
    } = this.props;

    const selectedMetric = leftMetrics.find(item => item.id === selectedMetricId);

    const showLoader = isFetching || isLeftWeeklyDataFetching || isRightWeeklyDataFetching;

    if (location && currentCycle && selectedMetric && selectedMetric.attributes && metricsWeeklyData) {
      return (
        <div className={styles.paper}>
          <ChartWrapper
            setShowHarvest={setShowHarvest}
            showHarvest={showHarvest}
            cycle={currentCycle}
            cycleId={cycleId}
            location={location}
            leftMetrics={leftMetrics}
            metricsWeeklyData={showLoader ? [] : metricsWeeklyData}
            harvestWeeklyData={showLoader ? [] : harvestWeeklyData}
            selectedMetric={selectedMetric}
            selectedMetricId={selectedMetricId}
            intl={intl}
            isFetching={isFetching}
            isLeftWeeklyDataFetching={isLeftWeeklyDataFetching}
            isRightWeeklyDataFetching={isRightWeeklyDataFetching}
            trackClickShowHarvest={trackClickShowHarvest}
          />
          {showLoader && (
            <div className={classnames(styles.loader)}>
              <CircleLoader className={styles.circleLoader} iconClassName={classnames(styles.circleLoaderIcon)} />
            </div>
          )}
        </div>
      );
    }

    return null;
  }
}

export default injectIntl(CropsChart);
