import numbersFormatAndRounding from 'helpers/numbersFormatAndRounding';

export default function magicRound(value) {
  const textValue = value.toString();
  const parsed = textValue.split('.');
  let roundTo = 0;
  if (parsed.length > 1) {
    roundTo = parsed[1].length;
  }
  return numbersFormatAndRounding(value, roundTo);
}
