import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { includes, uniqueId } from 'lodash';
import useMountEffect from 'hooks/useMountEffect';
import { showIntercom } from 'helpers/intercomHelpers';

import PageViewTracker from 'components/PageViewTracker';
import Typography from 'components/Typography';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import BigButton from 'components/BigButton';
import DefaultPlate from 'components/DefaultPlate';
import UserBage from 'components/UserBage';
import ArrowNextIcon from 'components/Icons/ArrowNextIcon';

import styles from './SettingsHarvestForecast.module.css';

const HARVEST_FORECAST_EDIT = 'HARVEST_FORECAST_EDIT';
const HARVEST_FORECAST_PUBLISH = 'HARVEST_FORECAST_PUBLISH';

const countUsersWithPrivilege = (privilege, userPrivileges) => userPrivileges.reduce((acc, user) => {
  if (includes(user?.privileges, privilege)) {
    return acc + 1;
  }

  return acc;
}, 0);
const countCanEditUsers = userPrivileges => countUsersWithPrivilege(HARVEST_FORECAST_EDIT, userPrivileges);
const countCanPublishUsers = userPrivileges => countUsersWithPrivilege(HARVEST_FORECAST_PUBLISH, userPrivileges);

const getMembersPermissionsText = (formatMessage, formatPlural, organizationSlug, userPrivileges) => {
  const canEditUsers = countCanEditUsers(userPrivileges);
  const canPublishUsers = countCanPublishUsers(userPrivileges);

  const pluralEditMembers = formatMessage({ id: `forecast.settings.members.${formatPlural(canEditUsers)}` });
  const pluralPublishMembers = formatMessage({ id: `forecast.settings.members.${formatPlural(canPublishUsers)}` });
  const pluralCanEditMembers = formatMessage({ id: `forecast.settings.canDo.${formatPlural(canEditUsers)}` });
  const pluralCanPublishMembers = formatMessage({ id: `forecast.settings.canDo.${formatPlural(canPublishUsers)}` });
  const firstPart = formatMessage({ id: 'forecast.settings.permissionsMsg.firstPart' });
  const secondPart = formatMessage({ id: 'forecast.settings.permissionsMsg.secondPart' });
  const thirdPart = formatMessage({ id: 'forecast.settings.permissionsMsg.thirdPart' });

  return (
    <span>
      {`${canEditUsers} ${pluralEditMembers} ${pluralCanEditMembers} ${firstPart} ${canPublishUsers} ${pluralPublishMembers} ${pluralCanPublishMembers} ${secondPart} `}
      <NavLink className={styles.href} to={`/${organizationSlug}/settings/permissions`}>
        {formatMessage({ id: 'forecast.settings.permissionsMsg.permissionsPage' })}
      </NavLink>
      {` ${thirdPart}`}
    </span>
  );
};

const getCategoriesList = (formatMessage, organizationSlug, harvestForecastSettings) => harvestForecastSettings.map(item => ({
  name: formatMessage({ id: `plantingCycles.allSpecies.${item.species}` }),
  href: `/${organizationSlug}/settings/harvest-forecast/${item.species}`,
  count: item?.forecastCategories?.length,
}));

const renderCategoriesList = (formatPlural, formatMessage, categoriesList) => categoriesList.map(group => (
  <div key={uniqueId('group-')} className={styles.group}>
    <NavLink className={styles.groupContainer} to={group?.href}>
      <div className={styles.firstColumn}>
        <div className={styles.groupName}>
          <Typography variant='body1' className={styles.name} bold>
            {group?.name}
          </Typography>
        </div>
      </div>
      <div className={styles.count}>
        <span className={styles.countLength}>
          {`${group?.count} ${formatMessage({ id: `forecast.settings.categories.${formatPlural(group?.count)}` })}`}
        </span>
        <div className={styles.arrowRightIconWrapper}>
          <ArrowNextIcon className={styles.arrowRightIcon} />
        </div>
      </div>
    </NavLink>
  </div>
));

const SettingsHarvestForecast = ({
  intl: { formatMessage, formatPlural },
  history,
  organizationSlug,
  harvestForecastSettings,
  forecastSubscribers,
  userPrivileges,
  isSettingsFetching,
  defaultOpen,

  requestHarvestForecastSettings,
  requestForecastSubscribersOfLocation,
  requestUserPrivileges,
  harvestForecastAccess,
  trackPageViewSettings,
}) => {
  useMountEffect(() => {
    if (!harvestForecastAccess) {
      history.push(`/${organizationSlug}/settings/profile`);
    }

    requestHarvestForecastSettings();
    requestForecastSubscribersOfLocation();
    requestUserPrivileges();
  });

  const handlerPageView = useCallback(() => {
    trackPageViewSettings({ settingName: 'Harvest Forecast', defaultOpen });
  }, [trackPageViewSettings, defaultOpen]);

  const membersPermissionsText = getMembersPermissionsText(formatMessage, formatPlural, organizationSlug, userPrivileges);
  const categoriesList = getCategoriesList(formatMessage, organizationSlug, harvestForecastSettings);

  return (
    <div className={styles.settingsHarvestForecast}>
      <PageViewTracker onMount={handlerPageView} />

      {isSettingsFetching && <DefaultCircleLoader />}

      <Typography variant='h2' className={styles.header}>
        {formatMessage({ id: 'forecast.harvestForecasts' })}
      </Typography>
      <Typography variant='subtitle3' className={styles.subtitle}>
        {formatMessage({ id: 'forecast.settings.subtitle' })}
      </Typography>
      <DefaultPlate className={styles.infoPlate}>
        <div>
          {formatMessage({ id: 'forecast.settings.toChangeSettings' })}
          &nbsp;
          <BigButton
            className={styles.supportButton}
            onClick={showIntercom}
            title={formatMessage({ id: 'settings.contactSupport' })}
            theme='transparent'
          />
        </div>
      </DefaultPlate>
      <div className={styles.contentGroup}>
        <Typography variant='h3' className={styles.permissionsHeader}>
          {formatMessage({ id: 'forecast.settings.membersPermissions' })}
        </Typography>
        <Typography variant='body1'>
          {membersPermissionsText}
        </Typography>
      </div>
      <div className={styles.contentGroup}>
        <Typography variant='h3' className={styles.publishHeader}>
          {formatMessage({ id: 'forecast.settings.publishNotification' })}
        </Typography>
        <Typography variant='subtitle3' className={styles.subtitle}>
          {formatMessage({ id: 'forecast.settings.listOfMembers' })}
        </Typography>
        <div className={styles.users}>
          {forecastSubscribers?.map(subscriber => <UserBage user={subscriber} className={styles.userBage} />)}
        </div>
      </div>
      <div className={styles.contentGroup}>
        <Typography variant='h3'>
          {formatMessage({ id: 'forecast.settings.enterHarvestCategories' })}
        </Typography>
        <div className={styles.categoriesList}>
          {renderCategoriesList(formatPlural, formatMessage, categoriesList)}
        </div>
      </div>
    </div>
  );
};

SettingsHarvestForecast.propTypes = {
  intl: intlShape.isRequired,
  history: PropTypes.object.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  harvestForecastSettings: PropTypes.array,
  forecastSubscribers: PropTypes.array,
  isSettingsFetching: PropTypes.bool,
  userPrivileges: PropTypes.array,
  harvestForecastAccess: PropTypes.bool,
  defaultOpen: PropTypes.bool,

  requestHarvestForecastSettings: PropTypes.func.isRequired,
  requestForecastSubscribersOfLocation: PropTypes.func.isRequired,
  requestUserPrivileges: PropTypes.func.isRequired,
  trackPageViewSettings: PropTypes.func.isRequired,
};

SettingsHarvestForecast.defaultProps = {
  isSettingsFetching: false,
  harvestForecastSettings: [],
  forecastSubscribers: [],
  userPrivileges: [],
  harvestForecastAccess: false,
  defaultOpen: false,
};

export default memo(SettingsHarvestForecast);
