import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getOrganizationSlug, getAllVarieties } from 'store/company/selectors';
import { getCropType, getManualFormsMetrics, getIsManualFormsMetricsFetching } from 'store/measurements/selectors';
import { requestManualFormsMetrics } from 'store/measurements/actions';

import SettingsMeasurementsCrop from '../components/SettingsMeasurementsCrop';

const mapDispatchToProps = {
  requestManualFormsMetrics,
};

const mapStateToProps = (state, props) => ({
  ...props,

  organizationSlug: getOrganizationSlug(state, props),
  cropType: getCropType(state, props),
  manualFormsMetrics: getManualFormsMetrics(state, props),
  varieties: getAllVarieties(state, props),
  isSettingsFetching: getIsManualFormsMetricsFetching(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsMeasurementsCrop));
