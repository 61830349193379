import { TreeNode, TreeNodePathComponents } from 'store/graphs/types';

function findMetricsTreeNode(currentTree: TreeNode, node: Array<TreeNodePathComponents>) : TreeNode | null {
  const {
    children
  } = currentTree;
  for (let i = 0; i < children.length; i += 1) {
    const child = children[i];
    if (child.node.join('_') === node.join('_')) {
      return child;
    }
    if (child.children) {
      const foundNode = findMetricsTreeNode(child, node);
      if (foundNode) {
        return foundNode;
      }
    }
  }
  return null;
}

export default findMetricsTreeNode;
