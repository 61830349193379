import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import getRandomId from '../../helpers/getRandomId';

import CircleLoader from '../CircleLoader/CircleLoader';

import styles from './BigButton.module.css';
import tooltipStyles from '../Tooltip/index.module.css';


export default class BigButton extends Component {
  static propTypes = {
    title: PropTypes.any,
    href: PropTypes.string,
    className: PropTypes.string,
    titleClassName: PropTypes.string,
    theme: PropTypes.string,
    icon: PropTypes.object,
    disabled: PropTypes.bool,
    bigIcon: PropTypes.bool,
    highIcon: PropTypes.bool,
    tooltip: PropTypes.string,
    category: PropTypes.string,
    isLoading: PropTypes.bool,
    type: PropTypes.string,
    size: PropTypes.string,
    externalLink: PropTypes.bool,
    tooltipClassName: PropTypes.string,
  };

  static defaultProps = {
    href: null,
    icon: null,
    className: null,
    titleClassName: null,
    disabled: false,
    bigIcon: false,
    highIcon: false,
    tooltip: null,
    title: null,
    theme: null,
    category: null,
    isLoading: false,
    type: null,
    size: 'medium',
    externalLink: false,
    tooltipClassName: undefined,
  };

  state = {
    id: getRandomId(),
  };

  renderButtonContent = (tooltipClassName) => {
    const {
      titleClassName,
      title,
      icon,
      tooltip,
      isLoading,
    } = this.props;

    const {
      id,
    } = this.state;

    return (
      <>
        {icon}
        {isLoading ? (
          <CircleLoader className={styles.loader} iconClassName={styles.loaderIcon} />
        ) : null}
        {title && <div className={classnames(styles.title, titleClassName)}>{title}</div>}
        {tooltip ? (
          <ReactTooltip
            className={classnames(tooltipStyles.smallTooltip, tooltipClassName)}
            effect='solid'
            html
            id={id}
          />
        ) : null}
      </>
    );
  };

  render() {
    const {
      className,
      href,
      title,
      titleClassName,
      icon,
      bigIcon,
      highIcon,
      disabled,
      theme,
      tooltip,
      category,
      isLoading,
      type,
      size,
      externalLink,
      tooltipClassName,
      ...props
    } = this.props;

    const {
      id,
    } = this.state;

    const classes = classnames(
      {
        [styles.button]: !href && category !== 'icon',
        [styles.link]: href && category !== 'icon',
        [styles.icon]: category === 'icon',
        [styles.themeLight]: theme === 'light',
        [styles.themeDark]: theme === 'dark',
        [styles.themeTransparent]: theme === 'transparent',
        [styles.themeTransparentRed]: theme === 'transparentRed',
        [styles.themeError]: theme === 'error',
        [styles.themeMenuItem]: theme === 'menuItem',
        [styles.hasIcon]: !!icon,
        [styles.bigIcon]: bigIcon,
        [styles.highIcon]: highIcon,
        [styles.loading]: isLoading,
        [styles[size]]: size,
      },
      className
    );

    if (externalLink && href) {
      return (
        <a
          to={href}
          href={href}
          data-for={id}
          data-tip={tooltip}
          disabled={disabled}
          activeClassName={styles.editActive}
          className={classes}
          {...props}
        >
          {this.renderButtonContent(tooltipClassName)}
        </a>
      );
    }

    if (href && !disabled) {
      return (
        <RouterLink
          to={href}
          href={href}
          data-for={id}
          data-tip={tooltip}
          disabled={disabled}
          activeClassName={styles.editActive}
          className={classes}
          {...props}
        >
          {this.renderButtonContent(tooltipClassName)}
        </RouterLink>
      );
    }

    return (
      <button
        disabled={disabled}
        data-tip={tooltip}
        data-for={id}
        className={classes}
        // eslint-disable-next-line react/button-has-type
        type={type}
        {...props}
      >
        {this.renderButtonContent(tooltipClassName)}
      </button>
    );
  }
}
