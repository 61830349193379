import { get, groupBy } from 'lodash';

import {
  MetricDef,
  NodeComponents,
  Preset
} from 'store/graphs/types';

import { rgbaToHex } from '../../../helpers/hex2rgba';

function parseId(value: string) {
  if (value === 'null') {
    return null;
  }
  return parseInt(value, 10);
}

// safe $-_.+!*'(),
// i can take $_+!*'
const GROUP_FIELDS_DELIMETER = '$';
const FIELDS_DELIMETER = '_';
const ARRAY_DELIMETER = '!';

function nodeMetricsToUrlParams(preset: Preset) {
  const {
    preset: {
      presetMetrics,
      scales
    }
  } = preset;
  const sourceGroup = groupBy(presetMetrics, item => `${[item.node[NodeComponents.TreeType], item.node[NodeComponents.Node], item.node[NodeComponents.SubNode], item.node[NodeComponents.LocationId]]}`);
  return Object.keys(sourceGroup).map((groupKey:string) => {
    const [
      treeId,
      nodeId,
      subNodeId,
      locationId
    ] = groupKey.split(',');
    const group = {
      treeId,
      nodeId: parseId(nodeId),
      subNodeId: parseId(subNodeId),
      locationId: parseId(locationId),
    };
    const metrics = sourceGroup[groupKey].map((item: MetricDef, position: number) => {
      const color = item?.color ? rgbaToHex(item?.color).slice(1) : '';

      const unselected = get(item, 'selected') ? '' : 1;
      const scale = scales.find(scaleItem => scaleItem.name === get(item, 'scale'));

      const scaleName = scale?.name;
      const invisible = scale?.invisible ? 1 : '';
      const rightAxis = scale?.rightAxis ? 1 : '';
      const start = scale?.range?.start;
      const end = scale?.range?.end;
      //  const { position } = item.attributes;
      const metricResult = [item.node[NodeComponents.MetricId], position, unselected, scaleName, invisible, rightAxis, start, end, color];

      let minifiedResult = metricResult.join(FIELDS_DELIMETER);

      while (minifiedResult.endsWith(FIELDS_DELIMETER)) {
        minifiedResult = minifiedResult.slice(0, minifiedResult.length - 1);
      }

      return minifiedResult;
    });
    const groupResult = [
      [
        group.treeId,
        group.locationId,
        group.nodeId || '',
        group.subNodeId || ''
      ].join(FIELDS_DELIMETER),
      metrics.join(ARRAY_DELIMETER)
    ];
    return groupResult.join(GROUP_FIELDS_DELIMETER);
  });
}

export default nodeMetricsToUrlParams;
