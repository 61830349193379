import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { isFunction } from 'lodash';
import classnames from 'classnames';

import CalendarIcon from '../../../Icons/CalendarIcon';
import { ReactComponent as ToggleIcon } from './assets/icon.svg';

import styles from './DateButton.module.css';

class DateButton extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    className: PropTypes.string, // Почему-то className не работает, по-ходу из-за react-datepicker
    dateButtonClassName: PropTypes.string,
    onClick: PropTypes.func,
    anyDateOfPeriod: PropTypes.object,
    periodType: PropTypes.string,
    disabled: PropTypes.bool,
    dateFormat: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    capitalized: PropTypes.bool,
    withoutPhoneVersion: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    dateButtonClassName: null,
    onClick: null,
    anyDateOfPeriod: null,
    periodType: null,
    disabled: false,
    dateFormat: 'MMMM, YYYY',
    capitalized: true,
    withoutPhoneVersion: true,
  };

  render() {
    const {
      intl: { formatMessage }, periodType, disabled, onClick, anyDateOfPeriod, className, dateButtonClassName, dateFormat, capitalized,
      withoutPhoneVersion,
    } = this.props;

    const formattedDate = isFunction(dateFormat) ? dateFormat(anyDateOfPeriod) : anyDateOfPeriod.clone().format(dateFormat);

    return (
      <button
        type='button'
        className={classnames(styles.button, className, dateButtonClassName, {
          [styles.disabled]: disabled,
          [styles.capitalized]: capitalized,
          [styles.withPhoneVersion]: !withoutPhoneVersion,
        })}
        onClick={onClick}
        disabled={disabled}
      >
        <span className={styles.placeholder}>
          {formatMessage({ id: 'harvest.date' })}
        </span>
        {anyDateOfPeriod && periodType !== 'year' ?
          formattedDate :
          anyDateOfPeriod.clone().format('YYYY')}
        <ToggleIcon className={styles.icon} />
        <CalendarIcon className={styles.mobileIcon} />
      </button>
    );
  }
}

export default injectIntl(DateButton);
