import {
  get, reverse, find, sortBy
} from 'lodash';
import { createSelector } from 'reselect';

import { getProductTypeName } from 'helpers/getVarietyName';

import {
  getAllVarieties, getAllCompartments, getAllFruitClasses, getIntl
} from '../company/selectors';


export const getVisibilityFilter = state => state.newCrops.visibilityFilter;
export const getCropsSorting = state => state.newCrops.cropsSorting;
export const getLocationCycles = state => state.newCrops.locationCycles || [];
export const getCycleInfo = state => state.newCrops.cycleInfo;
export const getCropsFetching = state => state.newCrops.isCropsFetching;

export const getCycleVarietyRefId = cycle => get(cycle, 'cycle.varietyRef.id');
export const getCycleFruitClassRefRefId = cycle => get(cycle, 'cycle.fruitClassRef.id');
export const getCycleCompartmentRefId = cycle => get(cycle, 'cycle.compartmentRef.id');
export const getCycleFetching = state => state.newCrops.isCycleFetching;

export const getCyclesFullInfo = createSelector(
  getLocationCycles,
  getAllVarieties,
  getAllCompartments,
  getAllFruitClasses,
  getIntl,
  (locationCycles, varieties, compartments, fruitClasses, intl) => locationCycles.map((locationCycle) => {
    const cycleVariety = find(varieties, variety => getCycleVarietyRefId(locationCycle) === variety.id);
    const cycleFruitClass = find(fruitClasses, fruitClass => getCycleFruitClassRefRefId(locationCycle) === fruitClass.id);
    const cycleCompartment = find(compartments, compartment => getCycleCompartmentRefId(locationCycle) === compartment.id);

    const fullName = getProductTypeName({
      intl,
      variety: cycleVariety,
      fruitClass: cycleFruitClass,
      targetWeight: get(locationCycle, 'cycle.targetWeight'),
    });


    return {
      ...locationCycle,
      id: get(locationCycle, 'cycle.id'),
      variety: cycleVariety,
      fruitClass: cycleFruitClass,
      compartment: cycleCompartment,
      fullName,
    };
  }),
);

export const getCurrentCycleFullInfo = createSelector(
  getCycleInfo,
  getAllVarieties,
  getAllCompartments,
  getAllFruitClasses,
  (cycle, varieties, compartments, fruitClasses) => {
    if (!cycle) {
      return null;
    }

    return {
      ...cycle,
      id: get(cycle, 'cycle.id'),
      variety: find(varieties, variety => getCycleVarietyRefId(cycle) === variety.id),
      fruitClass: find(fruitClasses, fruitClass => getCycleFruitClassRefRefId(cycle) === fruitClass.id),
      compartment: find(compartments, compartment => getCycleCompartmentRefId(cycle) === compartment.id),
    };
  },
);

export const getSortedCycles = createSelector(
  [getCropsSorting, getCyclesFullInfo],
  (cropsSorting, cycles) => {
    const sortingType = get(cropsSorting, 'type');

    switch (sortingType) {
      case 'cycle':
        return sortBy(cycles, 'fullName');
      case 'compartment':
        return sortBy(cycles, 'compartment.attributes.name');
      case 'species':
        return sortBy(cycles, 'variety.attributes.species');
      case 'week':
        return sortBy(cycles, 'weekNo');
      case 'harvested':
        return sortBy(cycles, (cycle) => {
          const currentAmount = get(cycle, 'totalHarvest.value.amount');

          if (currentAmount) {
            return currentAmount;
          }

          return 0;
        });
      case 'plan':
        return sortBy(cycles, (cycle) => {
          const currentAmount = get(cycle, 'totalHarvest.value.amount');
          const goalAmount = get(cycle, 'totalHarvest.totalGoal.amount');

          if (currentAmount && goalAmount) {
            return currentAmount / (goalAmount / 100);
          }

          return 0;
        });
      default:
        return cycles;
    }
  }
);

export const getDirectionSortedCycles = createSelector(
  [getCropsSorting, getSortedCycles],
  (cropsSorting, cycles) => {
    const sortingDirection = get(cropsSorting, 'direction');
    const sortedCycles = sortingDirection === 'down' ? reverse(cycles) : cycles;

    return sortedCycles;
  }
);

export const getVisibleCycles = createSelector(
  [getVisibilityFilter, getDirectionSortedCycles],
  (visibilityFilter, cycles) => {
    switch (visibilityFilter) {
      case 'SHOW_ALL':
        return cycles;
      case 'SHOW_COMPLETED':
        return cycles.filter(item => item.activeStatus === 'complete');
      case 'SHOW_ACTIVE':
        return cycles.filter(item => item.activeStatus === 'active');
      default:
        return cycles;
    }
  }
);

export const getCompletedCycles = createSelector(
  [getCyclesFullInfo],
  cycles => cycles.filter(item => item.activeStatus === 'complete')
);

export const getCyclesCounter = createSelector(
  [getLocationCycles],
  locationCycles => ({
    SHOW_ALL: locationCycles.length,
    SHOW_ACTIVE: locationCycles.filter(item => item.activeStatus === 'active').length,
    SHOW_COMPLETED: locationCycles.filter(item => item.activeStatus === 'complete').length,
  }),
);
