import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { GREEN_ZONE, YELLOW_ZONE } from '../../helpers/constants';

import styles from './ProgressBarCircle.module.css';

const RADIUS = 52;

// Based on https://codepen.io/xgad/post/svg-radial-progress-meters
export default class ProgressBarCircle extends PureComponent {
  static propTypes = {
    percent: PropTypes.number,
    strokeLinecap: PropTypes.bool,
    strokeWidth: PropTypes.number,
    colorized: PropTypes.bool,
    greenZone: PropTypes.number,
    yellowZone: PropTypes.number,
  };

  static defaultProps = {
    percent: 0,
    strokeLinecap: false,
    strokeWidth: 16,
    colorized: false,
    greenZone: GREEN_ZONE,
    yellowZone: YELLOW_ZONE,
  };

  getBarColor = (percent) => {
    const { colorized, greenZone, yellowZone } = this.props;

    if (!colorized) {
      return null;
    }

    if (percent >= greenZone) {
      return 'green';
    } if (percent >= yellowZone) {
      return 'yellow';
    }

    return 'red';
  };

  render() {
    const { strokeWidth, strokeLinecap } = this.props;
    let { percent } = this.props;

    if (percent > 100) {
      percent = 100;
    } else if (percent < 0) {
      percent = 0;
    }

    const circumference = 2 * Math.PI * RADIUS;

    const progress = percent / 100;
    const dashoffset = circumference * (1 - progress);

    const color = this.getBarColor(percent);

    return (
      <div className={styles.progressBarWrapper}>
        <svg className={styles.progressBar} width='120' height='120' viewBox='0 0 120 120'>
          <circle className={styles.progressBarMeter} cx='60' cy='60' r={RADIUS} strokeWidth={strokeWidth} />
          <circle
            className={classnames(
              styles.progressBarValue,
              {
                [styles.strokeLinecap]: strokeLinecap,
                [styles.red]: color === 'red',
                [styles.yellow]: color === 'yellow',
                [styles.green]: color === 'green',
              }
            )}
            cx='60'
            cy='60'
            r={RADIUS}
            strokeWidth={strokeWidth}
            strokeDashoffset={dashoffset}
            strokeDasharray={circumference}
          />
        </svg>
      </div>
    );
  }
}
