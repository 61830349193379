import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { getUnrealizedPotentialAccess, getOrganizationSlug } from 'store/company/selectors';

import {
  requestGetUnrealizedPotential,
  requestGetUnrealizedPotentialArguments
} from 'store/unrealizedPotential/actions';

import {
  getUnrealizedPotential,
  getUnrealizedPotentialFetching,
  getUnrealizedPotentialSuccess,
  getUnrealizedPotentialSupported,
  getUnrealizedPotentialDescriptor,
  getUnrealizedPotentialModelSettings,
  getUnrealizedPotentialWarnings
} from 'store/unrealizedPotential/selectors';


import UnrealizedPotential from '../UnrealizedPotential';

const mapDispatchToProps = {
  requestGetUnrealizedPotential,
  requestGetUnrealizedPotentialArguments
};

const mapStateToProps = (state, props) => ({
  ...props,

  isFetching: getUnrealizedPotentialFetching(state),
  isSuccess: getUnrealizedPotentialSuccess(state),
  isSupported: getUnrealizedPotentialSupported(state),
  potential: getUnrealizedPotential(state),
  descriptor: getUnrealizedPotentialDescriptor(state),
  unrealizedPotentialAccess: getUnrealizedPotentialAccess(state, props),
  organizationSlug: getOrganizationSlug(state, props),
  modelSettings: getUnrealizedPotentialModelSettings(state),
  warnings: getUnrealizedPotentialWarnings(state)
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(UnrealizedPotential)));
