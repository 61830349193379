import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import ProgressBar from '../../../ProgressBar';

import styles from './CycleProgress.module.css';


export default class CycleProgress extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    percent: PropTypes.number,
    harvestValue: PropTypes.string,
    totalGoalValue: PropTypes.string,
    tooltipId: PropTypes.string,
  };

  static defaultProps = {
    percent: 0,
    harvestValue: '0',
    totalGoalValue: '0',
    tooltipId: undefined,
  };

  getTooltipText = (harvestValue, totalGoalValue) => {
    const { intl } = this.props;
    const { formatMessage } = intl;

    return formatMessage(
      { id: 'cycle.tooltip' },
      { harvestValue, totalGoalValue }
    );
  };

  render() {
    const {
      percent, harvestValue, totalGoalValue, tooltipId
    } = this.props;

    return (
      <div
        className={styles.progress}
        data-tip={this.getTooltipText(harvestValue, totalGoalValue)}
        data-for={tooltipId}
      >
        <div className={styles.progressBarContainer}>
          <ProgressBar percent={percent} />
        </div>
        <div className={styles.progressText}>
          {`${percent}%`}
        </div>
      </div>
    );
  }
}
