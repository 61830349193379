import moment from 'moment-timezone';
import { get, find, flow } from 'lodash';

import getDateFormat from '../getDateFormat';
import { getVarietyName, getProductTypeName } from '../getVarietyName';
import numbersFormatting from '../numbersFormatting';
import numbersRounding from '../numbersRounding';

export const getPlantingCycleDates = (cycle, formatMessage, isAsTimestamp) => {
  if (!cycle) {
    return undefined;
  }

  const startDate = get(cycle, 'attributes.startDate') ? moment(get(cycle, 'attributes.startDate')).format(getDateFormat('lll')) : undefined;
  const endDate = get(cycle, 'attributes.endDate') ? moment(get(cycle, 'attributes.endDate')).format(getDateFormat('lll')) : undefined;

  const startTimestamp = get(cycle, 'attributes.startDate') ? +moment(get(cycle, 'attributes.startDate')) : undefined;
  const endTimestamp = get(cycle, 'attributes.endDate') ? +moment(get(cycle, 'attributes.endDate')) : undefined;

  const now = moment();
  const startDateMoment = moment(get(cycle, 'attributes.startDate'), 'YYYY-MM-DD');
  const isNotSpecified = !get(cycle, 'attributes.endDate') && startDateMoment.isAfter(now);
  let textEndDate;
  let textEndTimestamp;

  if (isNotSpecified) {
    textEndDate = formatMessage({ id: 'cycle.notSpecified' });
    textEndTimestamp = '..';
  } else {
    textEndDate = endDate || formatMessage({ id: 'cycle.present' });
    textEndTimestamp = endTimestamp || '.';
  }

  if (isAsTimestamp) {
    return `${startTimestamp} ${textEndTimestamp}`;
  }

  return `${startDate} – ${textEndDate}`;
};

export const getPlantingCycleLabel = (cycle, varieties, fruitClasses, intl) => {
  if (!cycle) {
    return undefined;
  }

  const { formatMessage } = intl;

  const varietyId = get(cycle, 'relationships.variety.data[0].id');
  const fruitClassId = get(cycle, 'relationships.fruitClass.data[0].id');
  const currentVariety = find(varieties, { id: varietyId });
  const currentFruitClass = find(fruitClasses, { id: fruitClassId });

  const fullName = getProductTypeName({
    intl,
    variety: currentVariety,
    fruitClass: currentFruitClass,
    targetWeight: get(cycle, 'attributes.targetWeight'),
  });

  const plantingCycleDates = getPlantingCycleDates(cycle, formatMessage);

  return `${fullName}, ${plantingCycleDates}`;
};

export const getPlantingCycleDatesById = ({
  plantingCycleId, allPlantingCycles, formatMessage, isAsTimestamp,
}) => flow([
  plantingCycles => find(plantingCycles, { id: plantingCycleId }),
  currentPlantingCycle => getPlantingCycleDates(currentPlantingCycle, formatMessage, isAsTimestamp),
])(allPlantingCycles);

export const getVarietyNameByPlantingCycle = ({
  plantingCycleId, allPlantingCycles, varieties, locale
}) => flow([
  plantingCycles => find(plantingCycles, { id: plantingCycleId }),
  currentPlantingCycle => get(currentPlantingCycle, 'relationships.variety.data[0].id'),
  varietyId => find(varieties, { id: varietyId }),
  variety => getVarietyName(variety, locale),
])(allPlantingCycles);

export const getCompartmentNameByPlantingCycle = ({
  plantingCycleId, allPlantingCycles, compartments,
}) => flow([
  plantingCycles => find(plantingCycles, { id: plantingCycleId }),
  currentPlantingCycle => get(currentPlantingCycle, 'relationships.compartment.data[0].id'),
  compartmentId => find(compartments, { id: compartmentId }),
  compartment => get(compartment, 'attributes.name'),
])(allPlantingCycles);

export const getCompartmentNameById = ({
  compartmentId, compartments,
}) => flow([
  allCompartments => find(allCompartments, { id: compartmentId }),
  compartment => get(compartment, 'attributes.name'),
])(compartments);

export const getPlantingCycleExtendedLabel = ({
  plantingCycle,
  varieties,
  fruitClasses,
  intl,
  allPlantingCycles,
  allCompartments,
  currentBreakdown,
  currentSpecies,
  currentVarietyId,
  currentFruitClassCode,
  isPlantingCycleDatesAsTimestamp,
  withFruitClass = false,
  withLocation = false,
}) => {
  if (!plantingCycle) {
    return undefined;
  }

  const plantingCycleId = get(plantingCycle, 'id');
  const { formatMessage } = intl;


  const plantingCycleDates = getPlantingCycleDatesById({
    plantingCycleId,
    allPlantingCycles,
    formatMessage,
    isAsTimestamp: isPlantingCycleDatesAsTimestamp,
  });

  const compartmentName = getCompartmentNameByPlantingCycle({
    plantingCycleId,
    allPlantingCycles,
    compartments: allCompartments,
  });

  let names = [];

  if (plantingCycle) {
    const currentVariety = find(varieties, { id: get(plantingCycle, 'relationships.variety.data.0.id') });
    const currentFruitClass = find(fruitClasses, { id: get(plantingCycle, 'relationships.fruitClass.data.0.id') });
    const targetWeight = get(plantingCycle, 'attributes.targetWeight');

    const currentProductGroupName = getProductTypeName({
      intl,
      targetWeight: targetWeight ? numbersFormatting(numbersRounding(targetWeight, 'fixed', 0)) : null,
      variety: currentVariety,
      fruitClass: currentFruitClass,
      withSpecies: !currentSpecies && !currentVarietyId && !currentFruitClassCode,
      withFruitClass: withFruitClass || currentBreakdown !== 'fruitClass',
    });

    if (currentBreakdown === 'compartment') {
      names = [currentProductGroupName, plantingCycleDates];
    } else if (currentBreakdown === 'workType') {
      names = [currentProductGroupName, plantingCycleDates];
    } else if (currentBreakdown === 'varietyWeight') {
      names = [plantingCycleDates, compartmentName];
    } else if (currentBreakdown === 'fruitClass') {
      names = [currentProductGroupName, plantingCycleDates, compartmentName];
    }
  } else {
    names = [compartmentName, plantingCycleDates];
  }

  if (withLocation && plantingCycle?.location?.name) {
    names = [...names, plantingCycle?.location?.name];
  }

  return names.join(', ');
};

export const getPlantingCycleLabelByRefs = ({
  startDate,
  endDate,
  targetWeight = null,
  varietyId,
  fruitClassId,
  varieties,
  fruitClasses,
  intl
}) => {
  const { formatMessage } = intl;

  const currentVariety = find(varieties, { id: varietyId });
  const currentFruitClass = find(fruitClasses, { id: fruitClassId });

  const fullName = getProductTypeName({
    intl,
    variety: currentVariety,
    fruitClass: currentFruitClass,
    targetWeight,
  });

  const plantingCycleDates = getPlantingCycleDates({
    attributes: {
      startDate,
      endDate,
    }
  }, formatMessage);

  return `${fullName}, ${plantingCycleDates}`;
};
