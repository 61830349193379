import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from './assets/close.svg';

import styles from './ErrorDialog.module.css';


export default class ErrorDialog extends Component {
  static propTypes = {
    content: PropTypes.string.isRequired,
    setIntl: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
  };

  handlerDialogClose = () => {
    const { onDialogClose } = this.props;

    if (onDialogClose) {
      onDialogClose();
    }
  };

  handlerShadowClick = (e) => {
    if (e.target.classList && e.target.classList.contains(styles.dialog)) {
      this.handlerDialogClose(true);
    }
  };

  render() {
    const { content } = this.props;
    const props = {
      onClick: this.handlerShadowClick
    };

    return (
      <div className={classnames(styles.dialog)} {...props}>
        <div className={styles.dialogWrapper}>
          <CloseIcon className={styles.dialogClose} onClick={this.handlerDialogClose} />
          <div className={styles.dialogHeader}>
            <FormattedMessage id='error.error' />
          </div>
          <div className={styles.dialogBody}>
            {content}
          </div>
        </div>
      </div>
    );
  }
}
