import React from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import styles from './DigitalTwinNavigation.module.css';

const DigitalTwinNavigation = ({
  match, digitalTwinAccess, harvestForecastAccess
}) => {
  const { params: { organizationSlug } } = match;

  return (
    <nav className={styles.nav}>
      <NavLink
        className={styles.link}
        activeClassName={styles.linkActive}
        to={`/${organizationSlug}/crops`}
        exact
      >
        <FormattedMessage id='cycle.plantingCycles' />
      </NavLink>
      {harvestForecastAccess && (
        <NavLink
          className={styles.link}
          activeClassName={styles.linkActive}
          to={`/${organizationSlug}/crops/forecasts`}
        >
          <FormattedMessage id='forecast.harvestForecasts' />
        </NavLink>
      )}
      {digitalTwinAccess && (
        <NavLink
          className={styles.link}
          activeClassName={styles.linkActive}
          to={`/${organizationSlug}/crops/model`}
        >
          <FormattedMessage id='cycle.cropModel' />
        </NavLink>
      )}
    </nav>
  );
};

DigitalTwinNavigation.propTypes = {
  match: PropTypes.object.isRequired,
  digitalTwinAccess: PropTypes.bool,
  harvestForecastAccess: PropTypes.bool,
};

DigitalTwinNavigation.defaultProps = {
  digitalTwinAccess: false,
  harvestForecastAccess: false,
};

export default withRouter(DigitalTwinNavigation);
