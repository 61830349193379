import moment from 'moment';
import React, { useMemo } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import LinesGraph from 'components/LinesGraph/components/LinesGraph/LinesGraph';
import {
  getLinesMaxValue,
  getLinesMinValue,
  preparePointValue,
  renderTooltipContent,
  customTicksFunction,
  tickTimeDisplayFormat, useOverlayData
} from 'components/HarvestForecastAssistantPanel/helpers';

import { API_DATE_FORMAT } from 'helpers/defaultDates';
import styles from './HarvestForecastWeatherChart.module.css';

function getWeatherItems(
  intl,
  assistant
) {
  if (!assistant || !assistant?.data?.harvest) {
    return [];
  }

  const { formatMessage } = intl;

  const {
    descriptor: {
      periods
    },
    data: {
      outsideAverageTemperature,
      outsideMinTemperature,
      outsideMaxTemperature
    },
    realDataTill
  } = assistant;

  const till = realDataTill ? moment.utc(realDataTill, API_DATE_FORMAT) : null;

  if (!till) {
    return [{
      points: periods?.map((period) => {
        const date = moment.utc(new Date((+period.epochMinutes) * 60000));
        return {
          y: preparePointValue(outsideAverageTemperature?.points?.[period.epochMinutes]),
          min: preparePointValue(outsideMinTemperature?.points?.[period.epochMinutes]),
          max: preparePointValue(outsideMaxTemperature?.points?.[period.epochMinutes]),
          x: date.format('MM-DD-YYYY'),
        };
      }),
      color: '#1DBADF',
      name: formatMessage({ id: 'forecast.assistant.weatherForecast' }),
      units: outsideAverageTemperature?.units,
      key: 'data-weather-temp-till',
      lineStyle: 'dashed',
    }];
  }

  const allPoints = periods?.map((period) => {
    const date = moment.utc(new Date((+period.epochMinutes) * 60000));
    const isBefore = date.isBefore(till, 'date');
    const isSame = date.isSame(till, 'date');
    return {
      y: preparePointValue(outsideAverageTemperature?.points?.[period.epochMinutes]),
      min: preparePointValue(outsideMinTemperature?.points?.[period.epochMinutes]),
      max: preparePointValue(outsideMaxTemperature?.points?.[period.epochMinutes]),
      x: date.format('MM-DD-YYYY'),
      isBefore,
      isSame
    };
  });

  const items = [];

  const pastPoints = allPoints.filter(p => p.isBefore || p.isSame);

  items.push({
    points: pastPoints,
    color: '#1DBADF',
    name: formatMessage({ id: 'forecast.assistant.weatherFact' }),
    units: outsideAverageTemperature?.units,
    key: 'data-weather-temp',
    hideNullableInTooltip: true,

    /**
     * customName это дикий костыль, т.к. не получилось скрыть нормально тултип today точки из графика fact
     * и отображать тултип today графика future
     */
    customNameIndexes: pastPoints ? [pastPoints.length - 1] : [],
    customName: formatMessage({ id: 'forecast.assistant.weatherForecast' }),
  });

  const lastPoint = items?.[0]?.points?.length > 0 ? items[0].points[items[0].points.length - 1] : null;
  const futurePoints = allPoints.filter(p => !p.isBefore);

  items.push({
    points: lastPoint ? [lastPoint, ...futurePoints] : futurePoints,
    color: '#1DBADF',
    name: formatMessage({ id: 'forecast.assistant.weatherForecast' }),
    units: outsideAverageTemperature?.units,
    key: 'data-weather-temp-till',
    hideNullableInTooltip: true,
    lineStyle: 'dashed',
    showInLegend: false,
    tooltipHidePointIndexes: lastPoint ? [0] : [],
  });

  return items;
}

const HarvestForecastWeatherChart = ({
  intl,
  assistant,
  isAssistantFetching,
  showHistoricalPeriod,
  chartMargins,
}) => {
  const { formatMessage } = intl;

  const lines = useMemo(() =>
    getWeatherItems(intl, assistant),
  [intl, assistant]);

  const maxValue = useMemo(() => getLinesMaxValue(lines), [lines]);
  const minValue = useMemo(() => getLinesMinValue(lines), [lines]);

  const isEmpty = useMemo(() =>
    !isAssistantFetching && (lines && lines.length === 0),
  [isAssistantFetching, lines]);

  const overlayData = useOverlayData(showHistoricalPeriod, assistant);

  return (
    <div className={styles.chartBlock}>
      <div className={styles.chartBlockTitle}>{formatMessage({ id: 'forecast.assistant.weatherForecast' })}</div>
      <LinesGraph
        wrapperClassName={styles.chartWrapper}
        withoutPoints
        isDataFetching={isAssistantFetching}
        lines={lines}
        maxValue={maxValue}
        minValue={minValue}
        renderTooltipContent={tooltipDate => renderTooltipContent(tooltipDate, lines, intl)}
        isEmbeddedEmptyState
        isEmpty={isEmpty}
        emptyTitle={formatMessage({ id: 'forecast.emptyNoData' })}
        isLegendDisabled
        defaultSize={{
          height: 180
        }}
        xTicks={5}
        withoutMinMax={false}
        customTicksFunction={customTicksFunction}
        tickTimeDisplayFormat={tickTimeDisplayFormat}
        cropEmptyValues
        overlay={overlayData}
        margin={chartMargins}
        isHoverLineSizeNeedCalc
      />
    </div>
  );
};

HarvestForecastWeatherChart.propTypes = {
  intl: intlShape.isRequired,
  assistant: PropTypes.object.isRequired,
  isAssistantFetching: PropTypes.bool.isRequired,
  showHistoricalPeriod: PropTypes.bool,
  chartMargins: PropTypes.object.isRequired,
};

HarvestForecastWeatherChart.defaultProps = {
  showHistoricalPeriod: true,
};

export default injectIntl(HarvestForecastWeatherChart);
