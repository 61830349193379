import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import {
  get, isNil, isFinite
} from 'lodash';

import moment from 'moment-timezone';

import LinesGraph from 'components/CustomChart/components/LinesGraph';

import LINE_COLORS from '../../../../helpers/harvestColors';
import numbersFormatting from '../../../../helpers/numbersFormatting';
import { getUnits } from '../../../../helpers/getValueDetails';

import LinesGraphTooltip from '../../../LinesGraphTooltip';

import styles from './BenchmarkMetricChart.module.css';
import { getProductTypeName } from '../../../../helpers/getVarietyName';
import getDateFormat from '../../../../helpers/getDateFormat';
import BenchmarkMetricChartInfo from '../BenchmarkMetricChartInfo';
import numbersRounding from '../../../../helpers/numbersRounding';

function generateLineByPoints(dataPoints) {
  if (dataPoints) {
    return Object.keys(dataPoints).reduce((acc, key, i) => {
      const date = moment.utc(new Date((+key) * 60000));
      const y = dataPoints[key];
      return [...acc, { x: i + 1, y: y || 0, date }];
    }, []);
  }
  return [];
}

const renderTooltip = ({
  tooltipState, graphMetric, comparison, periodType, intl
}) => {
  const { formatMessage } = intl;

  if (!tooltipState || !tooltipState.isShow || !tooltipState?.lines?.length) {
    return null;
  }

  const products = get(comparison, 'products');

  const {
    descriptor: {
      periods,
    }
  } = comparison;

  const serialNumber = +tooltipState.xCoord.toFixed(0);
  const period = periods.find(item => item.serialNumber === +serialNumber);

  const generatedLines = tooltipState?.lines.map((line, i) => {
    const productId = line.trace.id;
    const product = products.find(item => item.id === productId);
    if (!isFinite(line.value)) {
      return null;
    }
    const actualLineValue = numbersRounding(line.value, 'fixed', 1);
    const actualLineWithUnit = isNil(actualLineValue) ? formatMessage({ id: 'crops.noData' }) : `${numbersFormatting(actualLineValue)} ${getUnits(graphMetric, formatMessage, 'cunit')}`;
    const dataPoints = get(product, `graphs.${graphMetric.id}`);
    const organizationName = get(product, 'location.name', '');
    const productTypeName = getProductTypeName({
      intl,
      variety: get(product, 'variety'),
      fruitClass: get(product, 'fruitClass'),
      directPath: true,
    });
    const startDate = get(product, 'startDate');
    const endDate = get(product, 'endDate');
    const startDateMoment = moment(startDate).format(getDateFormat('lll'));
    const endDateMoment = endDate ? moment(endDate).format(getDateFormat('lll')) : formatMessage({ id: 'cycle.present' });
    return {
      id: productId,
      value: actualLineWithUnit,
      header: (
        <div className={styles.tooltipHeaderContent}>
          <div className={styles.locationName}>
            {`${organizationName}, ${productTypeName},`}
          </div>
          <div className={styles.dates}>
            {`${startDateMoment}—${endDateMoment}, ${product?.compartment?.name}`}
          </div>
        </div>
      ),
      color: LINE_COLORS[i],
      points: generateLineByPoints(dataPoints),
      units: getUnits(graphMetric, formatMessage),
    };
  }).filter(item => item !== null);

  return generatedLines?.length && (
    <LinesGraphTooltip
      isSpaced
      lines={generatedLines}
      tooltipDate={period.weekNumber}
      periodType={periodType}
    />
  );
};

const BenchmarkMetricChart = ({
  intl,
  isDataFetching,
  products,
  comparison,
  graphMetric,
  periodType,
  onTooltipChanged,
  tooltipState
}) => {
  const plot = useMemo(() => {
    const { locale, formatMessage } = intl;
    const comparisonProducts = get(comparison, 'products');
    return comparisonProducts.length > 0 ? {
      traces: comparisonProducts.map((product, i) => {
        const dataPoints = get(product, `graphs.${graphMetric.id}`);
        const organizationName = get(product, 'location.name', '');
        const productTypeName = getProductTypeName({
          intl,
          variety: get(product, 'variety'),
          fruitClass: get(product, 'fruitClass'),
          directPath: true,
        });
        const startDate = get(product, 'startDate');
        const endDate = get(product, 'endDate');
        const startDateMoment = moment(startDate).format(getDateFormat('lll'));
        const endDateMoment = endDate ? moment(endDate).format(getDateFormat('lll')) : formatMessage({ id: 'cycle.present' });
        const { x, y } = Object.keys(dataPoints).reduce((acc, key) =>
            ({
              x: [...acc.x, +key],
              y: [...acc.y, dataPoints[key]]
            }),
          { x: [], y: [] });
        return {
          id: product.id,
          type: 'scatter',
          name: {
            [locale]: `${organizationName}, ${productTypeName}, ${startDateMoment}—${endDateMoment}, ${product?.compartment?.name}`
          },
          line: {
            color: LINE_COLORS[i]
          },
          x,
          y
        };
      }),
      xAxis: {
        type: 'xRanged'
      },
      yAxis: {
        type: 'yRanged'
      },
      hover: {
        type: 'nearPointer'
      }
    } : null;
  }, [comparison, intl, graphMetric]);

  const tickDisplayFormat = useCallback((value) => {
    if (!comparison) {
      return null;
    }
    const {
      descriptor: {
        periods,
        compareType
      }
    } = comparison;

    const serialNumber = +value.toFixed(0);
    const period = periods.find(item => item.serialNumber === +serialNumber);

    if (!period) {
      return null;
    }

    const date = moment.utc(new Date((+period.epochMinutes) * 60000));

    if (compareType === 'CALENDAR') {
      return date.format('MMM YYYY');
    }

    if (compareType === 'YEARS') {
      return date.format('MMM');
    }

    if (compareType === 'CYCLE_WEEKS') {
      return period.weekNumber;
    }

    return serialNumber;
  }, [comparison]);

  const handlerTooltipChanged = useCallback((options) => {
    if (onTooltipChanged) {
      onTooltipChanged(options);
    }
  }, [onTooltipChanged]);

  // eslint-disable-next-line no-shadow
  const renderTooltipContent = useCallback(tooltipStateInner => renderTooltip({
    tooltipState: tooltipStateInner, graphMetric, comparison, periodType, intl
  }), [comparison, graphMetric, periodType, intl]);

  return (
    <div>
      <BenchmarkMetricChartInfo
        intl={intl}
        graphMetric={graphMetric}
        tooltipState={tooltipState}
        products={products}
      />
      <LinesGraph
        plot={plot}
        isDataFetching={isDataFetching}
        defaultSize={{ height: 112 }}
        isEmpty={!plot?.traces?.length}
        tickDisplayFormat={tickDisplayFormat}
        ticksFunction={13}
        onTooltipChanged={handlerTooltipChanged}
        renderTooltipContent={renderTooltipContent}
        cropNullValues
        legendClassName={styles.legend}
        tooltipState={tooltipState}
      />
    </div>
  );
};

BenchmarkMetricChart.propTypes = {
  intl: intlShape.isRequired,
  isDataFetching: PropTypes.bool,
  products: PropTypes.array,
  comparison: PropTypes.object,
  graphMetric: PropTypes.object,
  periodType: PropTypes.string,
  onTooltipChanged: PropTypes.func,
  tooltipState: PropTypes.any
};

BenchmarkMetricChart.defaultProps = {
  isDataFetching: false,
  products: null,
  comparison: null,
  graphMetric: null,
  periodType: null,
  onTooltipChanged: null,
  tooltipState: null
};


export default injectIntl(BenchmarkMetricChart);
