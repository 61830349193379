import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getAllFruitClasses,
  getAllProductGroups,
  getAllVarieties,
  getCurrentCompartments,
  getCurrentLocation,
  getOrganizationSlug,
} from 'store/company/selectors';

import {
  getFields,
  getGraphKey,
  getReportFilters
} from 'store/harvestForecast/selectors';

import {
  changeReportFilters,
  setGraphKey,
  setFields,
  requestGetForecastVersion,
  clearGraphKeys
} from 'store/harvestForecast/actions';

import { showNotificationWithTimeout } from 'store/notificationCenter/actions';

import HarvestForecastTabPage from './HarvestForecastTabPage';

const mapDispatchToProps = {
  changeReportFilters,
  setGraphKey,
  setFields,
  requestGetForecastVersion,
  clearGraphKeys,
  showNotificationWithTimeout
};

const mapStateToProps = (state, props) => ({
  ...props,
  varieties: getAllVarieties(state, props),
  fruitClasses: getAllFruitClasses(state, props),
  compartments: getCurrentCompartments(state, props),
  productGroups: getAllProductGroups(state, props),
  location: getCurrentLocation(state),
  filters: getReportFilters(state),
  graphKey: getGraphKey(state),
  fields: getFields(state),
  organizationSlug: getOrganizationSlug(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(HarvestForecastTabPage)));

