import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getIsEssentialPricePlan } from 'store/company/selectors';

import ResourcesRoutes from './ResourcesRoutes';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,

  isEssentialPricePlan: getIsEssentialPricePlan(state, props),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResourcesRoutes));
