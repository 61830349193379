import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect, Route } from 'react-router-dom';

import storageWrapper from 'helpers/storageWrapper';
import MainRoutes from '../MainRoutes';

const safeLocalStorage = storageWrapper.get('localStorage');

export default class OrganizationRoutes extends PureComponent {
  static propTypes = {
    getLogin: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    locations: PropTypes.array,
  };

  static defaultProps = {
    locations: null,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { getLogin, history } = this.props;

    getLogin(history);
  }

  render() {
    const { locations } = this.props;

    const dashboardsCurrentTab = safeLocalStorage.getItem('dashboardsCurrentTab');
    const dashboardsLink = dashboardsCurrentTab ? `/${dashboardsCurrentTab}` : '';

    if (locations && locations.length) {
      return (
        <Route
          key='organization-routes'
          path='/:organizationSlug?'
          render={({ match }) => {
            const { params: { organizationSlug } } = match;
            const savedLocationId = safeLocalStorage.getItem('location');
            const savedLocation = locations.find(item => savedLocationId && item && item.id.toString() === savedLocationId.toString());

            if (organizationSlug) {
              return <MainRoutes key='main-routes' />;
            }

            if (savedLocation) {
              return <Redirect to={`/${savedLocation.attributes.slug}/dashboards${dashboardsLink}`} />;
            }

            return <Redirect to={`/${locations[0].attributes.slug}/dashboards${dashboardsLink}`} />;
          }}
        />
      );
    }

    return null;
  }
}
