import 'whatwg-fetch';
import { get } from 'lodash';
import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { trackPageView } from '../analytics/actions';

import storageWrapper from '../../helpers/storageWrapper';

import initAxiosInstanse from '../../api/axios';

// import getPlantingCycleHumanAttributes from '../../helpers/getPlantingCycleHumanAttributes';

const safeLocalStorage = storageWrapper.get('localStorage');


export const requestCropsData = createAction('REQUEST_CROPS_DATA');
export const receiveCropsData = createAction('RECEIVE_CROPS_DATA');
export const setCropsVisibility = createAction('SET_CROPS_VISIBILITY');
export const setCropsSorting = createAction('SET_CROPS_SORTING');

export const requestCycleData = createAction('REQUEST_CYCLE_DATA');
export const receiveCycleData = createAction('RECEIVE_CYCLE_DATA');

export function getCropsData(payload = {}) {
  return async (dispatch, getState) => {
    const { isCropsPerformanceCallback, year, locationPlan } = payload;
    const state = getState();

    const { location } = state.company;
    const jwt = safeLocalStorage.getItem('jwt');
    const locale = safeLocalStorage.getItem('locale');
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `JWT ${jwt}`);
    headers.append('X-Lang', locale);

    if (location) {
      dispatch(requestCropsData());

      const queryParams = queryString.stringify({
        locationPlan,
      });

      const query = queryParams ? `&${queryParams}` : '';

      const response = await fetch(`${window.API}/locations/${location.id}/kpi/planting-cycle-overviews?${query}`, {
        method: 'GET',
        headers,
        credentials: 'include',
      });

      const { status } = response;

      if (Math.round(status / 100) === 2) {
        const data = await response.json() || [];

        dispatch(receiveCropsData({
          data,
          isCropsPerformanceCallback,
          year,
          locationPlan
        }));
      } else {
        dispatch(receiveCropsData({
          data: null,
          isCropsPerformanceCallback,
          year,
          locationPlan
        }));
      }
    }
  };
}

export function getCycleData(plantingCycleId) {
  return async (dispatch, getState) => {
    const state = getState();

    const { location } = state.company;

    if (location) {
      dispatch(requestCycleData());

      try {
        const response = await initAxiosInstanse()
          .get(`/locations/${location.id}/kpi/planting-cycle-overview/${plantingCycleId}`);

        dispatch(receiveCycleData(response.data));
      } catch (error) {
        const status = get(error, 'response.status');

        if (status === 400) {
          dispatch(push(`/${location.attributes.slug}/crops`));
        }

        throw new Error(error);
      }
    }
  };
}

export function updateCropsVisibility(data) {
  return async dispatch => dispatch(setCropsVisibility(data));
}

export function updateCropsSorting(data) {
  return async dispatch => dispatch(setCropsSorting(data));
}

/*
 * Analytics
 */
export function trackPageViewPlantingCycle(sectionName, defaultOpen = false) {
  return async (dispatch) => {
    const pageName = 'Planting Cycle';
    const path = window.location.href;

    await dispatch(trackPageView({
      path,
      data: {
        pageName, sectionName, defaultOpen
      }
    }));
  };
}

export function trackPageViewPlantingCycles(sectionName, defaultOpen = false) {
  return async (dispatch) => {
    const pageName = 'Crops';
    const path = window.location.href;

    await dispatch(trackPageView({
      path,
      data: {
        pageName, sectionName, defaultOpen
      }
    }));
  };
}

export const requestCropsExport = createAction('REQUEST_CROPS_EXPORT');
export const receiveCropsExport = createAction('RECEIVE_CROPS_EXPORT');
