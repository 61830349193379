import { createAction } from 'redux-actions';

export const requestFeedbackSending = createAction('REQUEST_FEEDBACK_SENDING');
export const receiveFeedbackSending = createAction('RECEIVE_FEEDBACK_SENDING');

export const clearFeedbackSending = createAction('CLEAR_FEEDBACK_SENDING');

export const requestIncidentById = createAction('REQUEST_INCIDENT_BY_ID');
export const receiveIncidentById = createAction('RECEIVE_INCIDENT_BY_ID');

export const requestIncidentTypes = createAction('REQUEST_INCIDENT_TYPES');
export const receiveIncidentTypes = createAction('RECEIVE_INCIDENT_TYPES');

export const requestIncidentTypeCategories = createAction('REQUEST_INCIDENT_TYPE_CATEGORIES');
export const receiveIncidentTypeCategories = createAction('RECEIVE_INCIDENT_TYPE_CATEGORIES');

export const requestUpdateIncidentState = createAction('REQUEST_UPDATE_INCIDENT_STATE');
export const receiveUpdateIncidentState = createAction('RECEIVE_UPDATE_INCIDENT_STATE');

export const requestIncidentPlot = createAction('REQUEST_INCIDENT_PLOT');
export const receiveIncidentPlot = createAction('RECEIVE_INCIDENT_PLOT');

export const requestAlertRules = createAction('REQUEST_ALERT_RULES');
export const receiveAlertRules = createAction('RECEIVE_ALERT_RULES');

export const requestCreateAlertRule = createAction('REQUEST_CREATE_ALERT_RULE');
export const receiveCreateAlertRule = createAction('RECEIVE_CREATE_ALERT_RULE');

export const requestUpdateAlertRule = createAction('REQUEST_UPDATE_ALERT_RULE');
export const receiveUpdateAlertRule = createAction('RECEIVE_UPDATE_ALERT_RULE');

export const requestDeleteAlertRule = createAction('REQUEST_DELETE_ALERT_RULE');
export const receiveDeleteAlertRule = createAction('RECEIVE_DELETE_ALERT_RULE');

export const requestUpdateAlertState = createAction('REQUEST_UPDATE_ALERT_STATE');
export const receiveUpdateAlertState = createAction('RECEIVE_DELETE_ALERT_STATE');

