import { get } from 'lodash';
import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import FileSaver from 'file-saver';

import { receiveCropsExport, requestCropsExport } from './actions';
import { hideNotification, showNotification, showNotificationWithTimeout } from '../notificationCenter/actions';

import initAxiosInstanse from '../../api/axios';

const showCropsExportNotificationEpic = action$ =>
  action$
    .ofType(requestCropsExport)
    .mergeMap(() => Observable.of(
      showNotification({
        id: 'notifications.downloadCropsXSLX',
        messageId: 'notifications.downloadCropsXSLX',
        iconType: 'loading',
        notificationType: 'withAction',
      })
    ));

const getCropsExportEpic = (action$, store) =>
  action$.ofType(requestCropsExport)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          plantingCycleId,
          actionAfterSuccess = () => {},
        }
      } = actionData;

      return Observable
        .from(
          initAxiosInstanse(
            searchParams,
            120000,
            {
              credentials: 'include',
              responseType: 'blob',
            }
          )
            .get(`planting-cycles/${plantingCycleId}/table-data/export`)
        )
        .mergeMap((response) => {
          const fileName = get(response, 'headers[x-filename]');

          FileSaver.saveAs(response.data, fileName);

          return Observable.of(
            actionAfterSuccess,
            hideNotification('notifications.downloadCropsXSLX'),
            receiveCropsExport(),
            showNotificationWithTimeout({
              id: 'notifications.cropsXSLXDownloaded',
              messageId: 'notifications.cropsXSLXDownloaded',
              iconType: 'success',
              notificationType: 'withAction',
            })
          );
        })
        // .map(({ data }) => receivePlanFactExport({ planFact: data }))
        .catch(() => Observable.of(
          hideNotification('notifications.downloadCropsXSLX'),
          receiveCropsExport(),
          showNotificationWithTimeout({
            id: `notifications.cropsXSLXDownloadError.${Date.now()}`,
            messageId: 'notifications.cropsXSLXDownloadError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export default combineEpics(
  showCropsExportNotificationEpic,
  getCropsExportEpic
);
