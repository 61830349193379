import { useEffect } from 'react';

function useWindowResize(callback, args = []) {
  useEffect(() => {
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, [callback, ...args]); // eslint-disable-line react-hooks/exhaustive-deps
}

export default useWindowResize;
