import { createAction } from 'redux-actions';

export const requestDataQualityReport = createAction('REQUEST_DATA_QUALITY_REPORT');
export const receiveDataQualityReport = createAction('RECEIVE_DATA_QUALITY_REPORT');

export const requestOverallQuality = createAction('REQUEST_OVERALL_QUALITY');
export const receiveOverallQuality = createAction('RECEIVE_OVERALL_QUALITY');

export const updateGreenhousesOptions = createAction('UPDATE_GREENHOUSES_OPTIONS');
export const updateDataTypeOptions = createAction('UPDATE_DATA_QUALITY_OPTIONS');
