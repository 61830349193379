import { useEffect } from 'react';
import { select } from 'd3';

// TODO: сделать более универсальным (без бордеров, например, чтобы можно было)
function useOverlay(overlayRef, options) {
  const {
    chartParams: {
      x, h
    },
    overlayFigure,
  } = options;

  const start = overlayFigure?.xrange?.start;
  const end = overlayFigure?.xrange?.end;

  useEffect(() => {
    const group = overlayRef.current
      ? select(overlayRef.current)
      : null;

    if (group && overlayFigure) {
      group
        .selectAll('rect').remove();
      group
        .selectAll('line').remove();

      if (end) {
        const startX = start ? x(start) : 0;
        const endX = x(end);

        const dasharrayLeft = overlayFigure?.border?.left?.dash === 'dot' ? '3, 2' : '0';
        const dasharrayRight = overlayFigure?.border?.right?.dash === 'dot' ? '3, 2' : '0';

        group.append('rect')
          .attr('x', startX)
          .attr('y', 0)
          .attr('width', endX - startX)
          .attr('height', h || 0)
          .attr('fill', overlayFigure?.background?.color);

        group
          .append('line')
          .attr('width', '1')
          .attr('height', h)
          .attr('x1', startX)
          .attr('x2', startX)
          .attr('y1', '0')
          .attr('y2', h)
          .attr('stroke', overlayFigure?.border?.left?.color)
          .attr('stroke-width', '1')
          .attr('stroke-dasharray', dasharrayLeft);

        group
          .append('line')
          .attr('width', '1')
          .attr('height', h)
          .attr('x1', endX)
          .attr('x2', endX)
          .attr('y1', '0')
          .attr('y2', h)
          .attr('stroke', overlayFigure?.border?.right?.color)
          .attr('stroke-width', '1')
          .attr('stroke-dasharray', dasharrayRight);
      }
    }
  }, [overlayRef, overlayFigure, h, start, end, x]);
}

export default useOverlay;
