import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getSortedUserPrivileges,
  getIsUserPrivilegesFetching,
} from 'store/user/selectors';

import {
  getCurrentCompartments,
  getIsEssentialPricePlan,
  getDefaultOpenFromRoute
} from 'store/company/selectors';

import { trackPageViewSettings } from 'store/company/actions';

import { requestUserPrivileges } from 'store/user/actions';

import SettingsPermissions from '../components/SettingsPermissions';

const mapDispatchToProps = {
  requestUserPrivileges,
  trackPageViewSettings,
};

const mapStateToProps = (state, props) => ({
  userPrivileges: getSortedUserPrivileges(state),
  isUserPrivilegesFetching: getIsUserPrivilegesFetching(state),
  allCompartments: getCurrentCompartments(state),
  isEssentialPricePlan: getIsEssentialPricePlan(state, props),
  defaultOpen: getDefaultOpenFromRoute(state, props),

  ...props,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsPermissions));
