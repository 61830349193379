import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as BackIcon } from './assets/back.svg';
import styles from './NotFound.module.css';


export default class NotFound extends Component {
  render() {
    return (
      <main className={styles.main}>
        <div className={styles.content}>
          <h1 className={styles.title}><FormattedMessage id='notFound.title' /></h1>
          <p className={styles.description}>
            <FormattedMessage id='notFound.info' />
            <br />
            <FormattedMessage id='notFound.description' />
          </p>
          <RouterLink className={styles.link} to='/'>
            <BackIcon className={styles.icon} />
            <FormattedMessage id='notFound.link' />
          </RouterLink>
        </div>
      </main>
    );
  }
}
