import React from 'react';

import { ReactComponent as DeleteIcon } from './delete.svg';

import styles from './Delete.module.css';

const Delete = () => (
  <DeleteIcon className={styles.icon} />
);

export default Delete;
