import { createAction } from 'redux-actions';

export const requestLaborReport = createAction('REQUEST_LABOR_REPORT');
export const receiveLaborReport = createAction('RECEIVE_LABOR_REPORT');

export const updateLaborReportSorting = createAction('UPDATE_LABOR_REPORT_SORTING');
export const clearLaborReport = createAction('CLEAR_LABOR_REPORT');

export const requestLaborResources = createAction('REQUEST_LABOR_RESOURCES');
export const receiveLaborResources = createAction('RECEIVE_LABOR_RESOURCES');

export const requestLaborWorkTypes = createAction('REQUEST_LABOR_WORK_TYPES');
export const receiveLaborWorkTypes = createAction('RECEIVE_LABOR_WORK_TYPES');

export const requestLaborRegistrations = createAction('REQUEST_LABOR_REGISTRATIONS');
export const receiveLaborRegistrations = createAction('RECEIVE_LABOR_REGISTRATIONS');

export const requestSaveLaborRegistrations = createAction('REQUEST_SAVE_LABOR_REGISTRATIONS');
export const receiveSaveLaborRegistrations = createAction('RECEIVE_SAVE_LABOR_REGISTRATIONS');
