import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import numbersRounding from 'helpers/numbersRounding';
import DefaultHotTable from 'components/DefaultHotTable';
import { getDateFromKey } from 'components/LaborWorks/helpers/getDate';
import {
  renderCellWithError
} from 'components/LaborWorksAdd/components/LaborWorksAddTable/LaborWorksAddTable';

import numbersFormatting from 'helpers/numbersFormatting';
import { valueValidator } from './validator';
import SeriesValueEditor from './SeriesValueEditor';

import styles from './BulkEditTable.module.css';


class BulkEditTable extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    valueMetrics: PropTypes.object,
    points: PropTypes.array,
    editable: PropTypes.bool,
    onPointsChanged: PropTypes.func
  };

  static defaultProps = {
    intl: null,
    valueMetrics: null,
    points: [],
    onPointsChanged: null,
    editable: true
  };

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  rerenderColHeaders = (colIndex) => {
    const {
      intl: { formatMessage }
    } = this.props;

    const colNames = [formatMessage({ id: 'cropModel.calendarWeek' }), formatMessage({ id: 'cropModel.value' })];

    return colNames[colIndex];
  }

  getColWidths = (colIndex) => {
    const colWidths = [200, 127];
    return colWidths[colIndex];
  }

  renderCells = (row, column, prop) => {
    const {
      valueMetrics,
      intl,
      editable
    } = this.props;
    const { formatMessage } = intl;
    const readOnly = prop === 'key' || !editable;
    const isValueCell = prop === 'value';
    const maxValue = valueMetrics?.max || Number.MAX_VALUE;
    const minValue = valueMetrics?.min || 0;
    return isValueCell ? {
      ...prop,
      readOnly,
      validator: function cellValidator(value, callback) {
        valueValidator({
          formatMessage,
          value,
          callback,
          maxValue,
          minValue
        });
      },
      renderer(instance, td, cRow, cCol, cProp, value) {
        let error;
        let isCellValid = true;

        const validationCallback = (isValid, errorMessage) => {
          error = errorMessage;

          return isValid;
        };

        isCellValid = valueValidator({
          formatMessage,
          value,
          callback: validationCallback,
          maxValue,
          minValue
        });

        if (!isCellValid) {
          const currentColArray = instance.getDataAtCol(cCol);
          const rowCount = currentColArray.length;
          // выравниваем по низу для последних 3 строк, чтобы не ехала таблица
          const alignBottom = ((rowCount - 1) - row) < 3;

          // eslint-disable-next-line no-param-reassign
          td.className = classnames('htInvalid', styles.invalidCell);
          // eslint-disable-next-line no-param-reassign
          td.innerHTML = renderCellWithError(true, alignBottom, error, value);
        } else {
          const normalizedDecimal = parseFloat((value.toString() || '0').replace(',', '.'));
          // eslint-disable-next-line no-param-reassign
          td.innerHTML = numbersFormatting(numbersRounding(normalizedDecimal, 'fixed', valueMetrics?.round || 0));
        }
      },
      type: 'numeric',
      editor: SeriesValueEditor,
      allowInvalid: false,
      valueMetrics,
      formatMessage
    } : {
      ...prop,
      readOnly,
      renderer(instance, td, cRow, cCol, cProp, value) {
        // eslint-disable-next-line no-param-reassign
        td.innerHTML = getDateFromKey({
          dateKey: value,
          periodType: 'week',
          intl
        });
      }
    };
  }

  handleChangeValue = (value, type) => {
    const {
      onPointsChanged
    } = this.props;
    if (!onPointsChanged) {
      return;
    }
    if (type === 'edit' && value) {
      const tableData = this.tableRef.current.hotInstance.getData();
      const rowIndex = value[0][0];
      onPointsChanged(tableData.map(p => ({
        key: p[0],
        value: parseFloat(p[1])
      })), rowIndex);
    }

    if (type === 'CopyPaste.paste') {
      const tableData = this.tableRef.current.hotInstance.getData();
      onPointsChanged(tableData.map(p => ({
        key: p[0],
        value: parseFloat(p[1])
      })), 0);
    }
  }


  render() {
    const {
      points,
    } = this.props;
    return (
      <div className={styles.tableWrapper}>
        <DefaultHotTable
          ref={this.tableRef}
          data={points}
          classNameWrapper={styles.hotTableWrapper}
          colHeaders={this.rerenderColHeaders}
          colWidths={this.getColWidths}
          rowHeights={28}
          columnHeaderHeight={36}
          height={296}
          width={346}
          cells={this.renderCells}
          afterChange={this.handleChangeValue}
        />
      </div>
    );
  }
}

export default injectIntl(BulkEditTable);
