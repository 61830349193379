const colors = {
  1: '#1DBADF', // brand color
  2: '#00B197', // additional color
  3: '#873679', // additional color
  4: '#BE1034', // additional color
  5: '#DB973A', // additional color
  6: '#8C46C3', // extended color
  7: '#C740B1', // extended color
  8: '#C8427A', // extended color
  9: '#2576C1', // extended color
  10: '#1185AD', // extended color
  11: '#34BA6A', // extended color
  12: '#E17227', // extended color
  13: '#61CFE9', // 300
  14: '#4D7795',
  15: '#4DC8B6',
  16: '#AB72A1',
  17: '#AF7ED5',
  18: '#D879C8',
  19: '#D97BA2',
  20: '#D25871', // 700
  21: '#15ABD7',
  22: '#002E55',
  23: '#00A184',
  24: '#742966',
};

export default colors;


// https://stackoverflow.com/questions/2353211/hsl-to-rgb-color-conversion
const hue2rgb = (p, q, t) => {
  let nT = t;
  if (nT < 0) nT += 1;
  if (nT > 1) nT -= 1;
  if (nT < 1 / 6) return p + (q - p) * 6 * nT;
  if (nT < 1 / 2) return q;
  if (nT < 2 / 3) return p + (q - p) * (2 / 3 - nT) * 6;
  return p;
};

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
export function hslToRgb(h, s, l) {
  let r = 0; let g = 0; let b = 0;
  if (s === 0) {
    r = l; // achromatic
    g = l;
    b = l;
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   {number}  r       The red color value
 * @param   {number}  g       The green color value
 * @param   {number}  b       The blue color value
 * @return  {Array}           The HSL representation
 */
export function rgbToHsl(r, g, b) {
  const rN = r / 255;
  const gN = g / 255;
  const bN = b / 255;

  const max = Math.max(rN, gN, bN);
  const min = Math.min(rN, gN, bN);
  let h;
  let s;
  const l = (max + min) / 2;

  if (max === min) {
    // achromatic
    h = 0;
    s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case rN: h = (gN - bN) / d + (gN < bN ? 6 : 0); break;
      case gN: h = (bN - rN) / d + 2; break;
      case bN: h = (rN - gN) / d + 4; break;
      default: {
        // achromatic
        h = 0;
        s = 0;
      }
    }
    h /= 6;
  }

  return [h, s, l];
}


/**
 * Converts an RGB color value to HSV. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and v in the set [0, 1].
 *
 * @param   Number  r       The red color value
 * @param   Number  g       The green color value
 * @param   Number  b       The blue color value
 * @return  Array           The HSV representation
 */
export function rgbToHsv(r, g, b) {
  const rN = r / 255;
  const gN = g / 255;
  const bN = b / 255;

  const max = Math.max(rN, gN, bN);
  const min = Math.min(rN, gN, bN);
  let h;
  const v = max;

  const d = max - min;
  const s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case rN: h = (gN - bN) / d + (gN < bN ? 6 : 0); break;
      case gN: h = (bN - rN) / d + 2; break;
      case bN: h = (rN - gN) / d + 4; break;
      default: {
        h = 0;
      }
    }

    h /= 6;
  }

  return [h, s, v];
}


/**
 * Converts an HSV color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes h, s, and v are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  v       The value
 * @return  Array           The RGB representation
 */
export function hsvToRgb(h, s, v) {
  let r; let g; let
    b;

  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);

  // eslint-disable-next-line default-case
  switch (i % 6) {
    case 0: {
      r = v; g = t; b = p; break;
    }
    case 1: {
      r = q; g = v; b = p; break;
    }
    case 2: {
      r = p; g = v; b = t; break;
    }
    case 3: {
      r = p; g = q; b = v; break;
    }
    case 4: {
      r = t; g = p; b = v; break;
    }
    case 5: {
      r = v; g = p; b = q; break;
    }
  }

  return [r * 255, g * 255, b * 255];
}

/**
 * TODO: Bug!
 * Convert rgb components to hex string (without #)
 * @param r
 * @param g
 * @param b
 * @returns {string}
 */
// eslint-disable-next-line no-bitwise,no-mixed-operators
export const rgb2hex = (r, g, b) => `${((b | g << 8 | r << 16) | 1 << 24).toString(16).slice(1)}`;
