import React from 'react';

import EssentialPricePlan from 'components/EssentialPricePlan';

import styles from './EssentialPricePlanResources.module.css';

const EssentialPricePlanResources = () => (
  <div className={styles.layout}>
    <EssentialPricePlan className={styles.pricePlan} />
  </div>
);

EssentialPricePlanResources.propTypes = {};

export default EssentialPricePlanResources;
