import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getAllSubNodes,
  getOrganizationSlug,
  getPermissions,
  getAllCompartments,
  getIsIncidentsExpert,
} from 'store/company/selectors';
import {
  getMetricsSelector,
} from 'store/graphs/selectors';
import {
  getIncidentData,
  getIsIncidentFetching,
  getUserFeedbackFromIncident,
  getIncidentPlot,
  getIsIncidentPlotFetching,
} from 'store/incidents/selectors';

import IncidentInfoPanel from './components/IncidentInfoPanel';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,

  allCompartments: getAllCompartments(state),
  allSubNodes: getAllSubNodes(state),
  allGraphMetrics: getMetricsSelector(state),
  permissions: getPermissions(state),
  organizationSlug: getOrganizationSlug(state, props),
  isIncidentFetching: getIsIncidentFetching(state, props),
  activeIncident: getIncidentData(state, props),
  feedback: getUserFeedbackFromIncident(state, props),
  isExpert: getIsIncidentsExpert(state, props),
  activeIncidentPlot: getIncidentPlot(state, props),
  isIncidentPlotFetching: getIsIncidentPlotFetching(state, props),
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(IncidentInfoPanel)));
