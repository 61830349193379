import { connect } from 'react-redux';

import { getAllCompartments, getFetching, getOrganization } from 'store/company/selectors';

import {
  getPhotoCategories as getAllPhotoCategories,
} from 'store/photos/selectors';

import { getPhotoCategories } from 'store/photos/actions';

import PhotosWrapper from '../components/PhotosWrapper';

const mapDispatchToProps = {
  getPhotoCategories,
};

const mapStateToProps = (state) => {
  const {
    dialogs,
  } = state;

  return {
    ...dialogs,
    location: getOrganization(state),
    photoCategories: getAllPhotoCategories(state),
    allCompartments: getAllCompartments(state),
    isFetching: getFetching(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotosWrapper);
