import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { showNotificationWithTimeout } from 'store/notificationCenter/actions';
import {
  getOrganization,
  getCurrentCompartments,
  getAllVarieties,
  getAllFruitClasses,
  getCurrentPlantingCycles,
  getYearFromRoute,
} from 'store/company/selectors';
import {
  getHarvestPlanEdited,
  getPlansEditorFetching,
  getVarietyRemoving,
  getMaxIdx,
  getTableScroll,
  getIsNotPublishedVersionPlate,
  getPlanDescriptor,
  getIsLastVersion,
  getIsOperationalPlan,
  getPlanType,
  getYearType,
} from 'store/plansEditor/selectors';

import {
  setHarvestPlanGrid,
  setTableScrollPosition,
  requestPlanForecast,
} from 'store/plansEditor/actions';

import DataSheetWrapper from '../components/DataSheetWrapper';

const mapDispatchToProps = {
  setHarvestPlanGrid,
  setTableScrollPosition,
  requestPlanForecast,
  showNotificationWithTimeout,
};

const mapStateToProps = (state, props) => ({
  ...props,

  year: getYearFromRoute(state, props),
  compartments: getCurrentCompartments(state),
  varieties: getAllVarieties(state, props),
  fruitClasses: getAllFruitClasses(state, props),
  plantingCycles: getCurrentPlantingCycles(state, props),
  organization: getOrganization(state, props),

  harvestPlanEdited: getHarvestPlanEdited(state, props),
  isPlansEditorFetching: getPlansEditorFetching(state, props),
  isVarietyRemoving: getVarietyRemoving(state, props),
  maxIdx: getMaxIdx(state, props),
  tableScroll: getTableScroll(state, props),
  isNotPublishedVersionPlate: getIsNotPublishedVersionPlate(state),
  planDescriptor: getPlanDescriptor(state, props),
  isLastVersion: getIsLastVersion(state),
  isOperationalPlan: getIsOperationalPlan(state, props),
  planType: getPlanType(state, props),
  yearType: getYearType(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DataSheetWrapper));
