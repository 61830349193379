import React, { MouseEvent } from 'react';

import classnames from 'classnames';

import styles from './index.module.css';

export type OnClickFunc = (
  event: MouseEvent<HTMLButtonElement>
) => void;

type CustomDatePickerInputProps = {
  icon: any; // TODO: Types
  // eslint-disable-next-line react/require-default-props
  value?: any; // TODO: Types
  // eslint-disable-next-line react/require-default-props
  classNames?: string;
}

type CustomDatePickerInputFunc = {
  // eslint-disable-next-line react/require-default-props
  onClick?: OnClickFunc
}

const CustomDatePickerInput = ({
  onClick,
  value,
  classNames,
  icon,
}: CustomDatePickerInputProps & CustomDatePickerInputFunc) => (
  <button
    type='button'
    className={classnames(styles.button, classNames)}
    onClick={onClick}
  >
    {value}
    {icon}
  </button>
);

export default CustomDatePickerInput;
