import { createSelector } from 'reselect';
import { get, sortBy } from 'lodash';

export const getUserPrivileges = state => get(state, 'user.userPrivileges');
export const getIsUserPrivilegesFetching = state => get(state, 'user.isUserPrivilegesFetching');
export const getIsUserInfoUpdated = state => get(state, 'user.isUserInfoUpdated');

export const getSortedUserPrivileges = createSelector(
  [getUserPrivileges],
  users => sortBy(users, 'user.name'),
);
