import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getDefaultOpenFromRoute } from 'store/company/selectors';
import { trackPageViewSettings } from 'store/company/actions';

import { getIsEnergyMetersFetching, getMetersCombined } from 'store/energy/selectors';
import { requestEnergyMeters } from 'store/energy/actions';

import SettingsEquipmentMeters from '../components/SettingsEquipmentMeters';

const mapDispatchToProps = {
  requestEnergyMeters,
  trackPageViewSettings,
};

const mapStateToProps = (state, props) => ({
  isMetersFetching: getIsEnergyMetersFetching(state),
  meters: getMetersCombined(state),
  defaultOpen: getDefaultOpenFromRoute(state, props),

  ...props,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsEquipmentMeters));
