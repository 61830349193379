function findPatchedTreeEntity(rootNode, path) {
  const joinedPath = path.join('_');
  const joinedNodePath = rootNode.path.join('_');

  if (joinedPath === joinedNodePath) {
    return rootNode;
  }

  if (rootNode?.children?.length) {
    for (let i = 0; i < rootNode.children.length; i += 1) {
      const child = rootNode.children[i];
      if (child.path.join('_') === joinedPath) {
        return child;
      }

      if (child.children) {
        const foundNode = findPatchedTreeEntity(child, path);
        if (foundNode) {
          return foundNode;
        }
      }
    }
  }

  return null;
}

export default findPatchedTreeEntity;
