import React, {
 useCallback, useEffect, useMemo, useState
} from 'react';

import moment from 'moment';

// @ts-ignore
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';

import classnames from 'classnames';
import { groupBy, uniq, uniqBy } from 'lodash';

import {
  Compartment,
  FruitClass,
  GraphPresetListItem,
  GraphsPresetRange,
  Incident,
  Location,
  Metric,
  MetricData, MetricDef, NodeComponents,
  PlantingCycle,
  Preset,
  PresetMetricScale,
  Species,
  SubNode, Variety,
} from 'store/graphs/types';

import {
  RequestGetMetricsFunc,
  RequestGetGraphPresetsFunc,
  RequestGetGraphPresetFunc,
  RequestCreateGraphPreset,
  RequestUpdateGraphPreset,
  ChangePresetName,
  ChangePresetDescription,
  DeletePresetMetric,
  DeletePresetMetricGroup,
  ChangePresetMetricGroup,
  SetPresetMetricSelected,
  SetMetricColor,
  SetMetricToScale,
  ChangeScale,
  DeleteScale,
  GetGraphDataPointsRequest,
  PushRangeHistory,
  PopRangeHistory,
  ClearRangeHistory,
  SetAllPresetMetricSelected,
  RequestDeleteGraphPreset,
  UpdatePresetDataFromUrl,
  ClearWorkspace,
  SetShowIncidents,
  SetActiveIncidentId,
  ChangeGraphPeriodAndThreshold,
  CreateIncident,
  RequestNewIncidentId,
  CloseIncidentEditor,
  CheckNewIncidentStatus,
  ChangeGraphPeriodThreshold,
  RequestMetricsTree,
  TrackPageViewGraphs, RequestFullTreeData,
  RequestIncidentById,
  RequestIncidentPlot,
} from 'store/graphs/actions';

import getGraphThresholdSelectors from 'helpers/getGraphThresholdSelectors';
import LINE_COLORS from 'helpers/graphColors';
import { RangeHistory } from 'store/graphs/reducers';
import /* useQuery, */ { getQueryValue } from 'hooks/useQuery/useQuery';

import PageViewTracker from 'components/PageViewTracker';
import Dialog from 'components/Dialog';
import BigButton from 'components/BigButton';
import SidePanel from 'components/SidePanel';
import IncidentInfoPanel from 'components/IncidentInfoPanel';

import ChartTable from '../ChartTable';

import GraphsPresetSettings from '../GraphsPresetSettings';

import GraphsPresetFilters from '../GraphsPresetFilters';

import getGraphPointsPayload from '../../utils/getGraphPointsPayload';
import nmUrlParamsToNodeMetrics from '../../utils/nmUrlParamsToNodeMetrics';
import useUrlNavHandler from '../../utils/useUrlNavHandler';
import ChartContainer from '../ChartContainer';

import styles from './index.module.css';

import getMetricName from '../../utils/getMetricName';
import getMetricDescription from '../../utils/getMetricDescription';
import { buildMetricPath } from '../../utils/metricsGroupUtils';


type GraphsProps = {
  sidePanel: any;
  preset: Preset;
  location: Location;
  allMetrics: Array<Metric>;
  allCompartments: Array<Compartment>;
  allSubNodes: Array<SubNode>;
  allSpecies: Array<Species>;
  allVarieties: Array<Variety>;
  allFruitClasses: Array<FruitClass>;
  allPlantingCycles: Array<PlantingCycle>;
  isMetricsFetching: boolean;
  metricDatas: Array<MetricData>;
  isEditedPreset: boolean;
  presets: Array<GraphPresetListItem>;
  incidents: Array<Incident>;
  // dataPointsMetrics: Array<MetricAttr & IdMixin>;
  threshold: number;
  rangeHistory: Array<RangeHistory>;
  graphPresetDeleteDialogOpen: boolean;
  graphPresetDeleteDialogId: number;
  returnAbnormalityIncidents: boolean;
  activeIncidentId: number | null;
  createdIncident: Incident | null;
  permissions: Array<any>;
  presetMetrics: Array<MetricDef>;
  presetScales: Array<PresetMetricScale>;
};

type GraphsPropsFunc = {
  requestGetMetrics: RequestGetMetricsFunc;
  requestGetGraphPresets: RequestGetGraphPresetsFunc;
  requestGetGraphPreset: RequestGetGraphPresetFunc;
  requestCreateGraphPreset: RequestCreateGraphPreset;
  requestUpdateGraphPreset: RequestUpdateGraphPreset;
  requestDeleteGraphPreset: RequestDeleteGraphPreset;
  changePresetName: ChangePresetName;
  changePresetDescription: ChangePresetDescription;
  showSidePanelAction: Function; // TODO: Types for SidePanel
  hideSidePanelAction: Function; // TODO: Types for SidePanel
  getAllGraphMetrics: Function; // TODO: Types for SidePanel
  deletePresetMetric: DeletePresetMetric;
  deletePresetMetricGroup: DeletePresetMetricGroup;
  changePresetMetricGroup: ChangePresetMetricGroup;
  setPresetMetricSelected: SetPresetMetricSelected;
  setMetricColor: SetMetricColor;
  setMetricToScale: SetMetricToScale;
  changeScale: ChangeScale;
  deleteScale: DeleteScale;
  requestGetGraphDataPoints: GetGraphDataPointsRequest;
  pushRangeHistory: PushRangeHistory;
  popRangeHistory: PopRangeHistory;
  clearRangeHistory: ClearRangeHistory;
  setAllPresetMetricSelected: SetAllPresetMetricSelected;
  setToggleGraphPresetDeleteDialog: Function;
  updatePresetDataFromUrl: UpdatePresetDataFromUrl;
  clearWorkspace: ClearWorkspace;
  setShowIncidents: SetShowIncidents;
  setActiveIncidentId: SetActiveIncidentId;
  changeGraphPeriodAndThreshold: ChangeGraphPeriodAndThreshold;
  createIncident: CreateIncident;
  requestNewIncidentId: RequestNewIncidentId;
  closeIncidentEditor: CloseIncidentEditor;
  checkNewIncidentStatus: CheckNewIncidentStatus;
  changeGraphPeriodThreshold: ChangeGraphPeriodThreshold;
  requestMetricsTree: RequestMetricsTree;
  trackPageViewGraphs: TrackPageViewGraphs;
  requestFullTreeData: RequestFullTreeData;
  requestIncidentById: RequestIncidentById;
  requestIncidentPlot: RequestIncidentPlot;
};

function buildPresetUpdatePayload(params: any) {
  const payload = {};

  const xRangeEnd = params?.xRangeEnd
    ? moment.utc(+params?.xRangeEnd).format('YYYY-MM-DDTHH:mm:ss')
    : null;

  if (params?.nm) {
    Object.assign(payload, {
      // @ts-ignore
      ...nmUrlParamsToNodeMetrics(
        Array.isArray(params?.nm) ? params?.nm : [params?.nm]
      ),
    });
  }
  if (params?.ticks) {
    Object.assign(payload, {
      threshold: +params?.ticks,
    });
  }

  let range = null;

  if (xRangeEnd || params?.xRange || params?.xRangeLengthInMins) {
    range = {};
  }

  if (range && xRangeEnd) {
    Object.assign(range, { xRangeEnd });
  }
  if (range && params?.xRange) {
    Object.assign(range, { xRange: params?.xRange });
  }
  if (range && params?.xRangeLengthInMins) {
    Object.assign(range, { xRangeLengthInMins: +params?.xRangeLengthInMins });
  }

  if (range) {
    Object.assign(payload, { range });
  }

  if (params?.showFirstIncident) {
    Object.assign(payload, { showFirstIncident: params?.showFirstIncident });
  }

  if (params?.returnAbnormalityIncidents) {
    Object.assign(payload, {
      returnAbnormalityIncidents: !!params?.returnAbnormalityIncidents,
    });
  }
  if (params?.activeIncidentId) {
    Object.assign(payload, {
      activeIncidentId: +params?.activeIncidentId,
    });
  }

  return payload;
}

function fetchData(
  params: any,
  requestGetGraphPreset: RequestUpdateGraphPreset,
  updatePresetDataFromUrl: UpdatePresetDataFromUrl
) {
  if (params?.graphPreset) {
    const payload = buildPresetUpdatePayload(params);
    Object.assign(payload, {
      id: +params?.graphPreset,
      preventUpdatePresetIdInUrl: true,
    });
    // @ts-ignore
    requestGetGraphPreset(payload);
  } else if (params?.nm) {
    const payload = buildPresetUpdatePayload(params);
    // @ts-ignore
    updatePresetDataFromUrl(payload);
  }
}

const Graphs = ({
  intl,
  sidePanel,
  location,
  preset,
  allMetrics,
  allCompartments,
  allSubNodes,
  allSpecies,
  allVarieties,
  allFruitClasses,
  allPlantingCycles,
  metricDatas,
  incidents,
  // dataPointsMetrics,
  permissions,
  isEditedPreset,
  isMetricsFetching,
  requestGetMetrics,
  requestGetGraphPresets,
  requestGetGraphPreset,
  showSidePanelAction,
  hideSidePanelAction,
  getAllGraphMetrics,
  presets,
  requestCreateGraphPreset,
  requestUpdateGraphPreset,
  requestDeleteGraphPreset,
  changePresetName,
  changePresetDescription,
  deletePresetMetric,
  deletePresetMetricGroup,
  changePresetMetricGroup,
  changeGraphPeriodAndThreshold,
  threshold,
  setPresetMetricSelected,
  setMetricColor,
  setMetricToScale,
  changeScale,
  deleteScale,
  requestGetGraphDataPoints,
  rangeHistory,
  pushRangeHistory,
  popRangeHistory,
  clearRangeHistory,
  setAllPresetMetricSelected,
  graphPresetDeleteDialogOpen,
  graphPresetDeleteDialogId,
  setToggleGraphPresetDeleteDialog,
  updatePresetDataFromUrl,
  clearWorkspace,
  returnAbnormalityIncidents,
  activeIncidentId,
  setShowIncidents,
  setActiveIncidentId,
  createIncident,
  createdIncident,
  requestNewIncidentId,
  closeIncidentEditor,
  checkNewIncidentStatus,
  presetMetrics,
  presetScales,
  changeGraphPeriodThreshold,
  requestMetricsTree,
  trackPageViewGraphs,
  requestFullTreeData,
  requestIncidentById,
  requestIncidentPlot,
}: GraphsProps & GraphsPropsFunc & InjectedIntlProps) => {
  const { locale, formatMessage } = intl;

  const currentVarietyId = getQueryValue('varietyId') || undefined;
  const currentSpecies = getQueryValue('species');
  const currentFruitClassCode = getQueryValue('fruitClassCode');
  const currentCompartmentId = getQueryValue('compartmentId') || undefined;

  const compartmentIds = (currentCompartmentId ? currentCompartmentId.split(',') : []).map(item => +item);
  const varietyIds = (currentVarietyId ? currentVarietyId.split(',') : []).map(item => +item);
  const fruitClassCodeIds = (currentFruitClassCode ? currentFruitClassCode.split(',') : []);
  const speciesIds = (currentSpecies ? currentSpecies.split(',') : []);

  const [activeIncidentCollapsed, setActiveIncidentCollapsed] = useState(true);

  const handlerPageView = useCallback(() => {
    trackPageViewGraphs();
  }, [trackPageViewGraphs]);

  const handleIncidentCollapsed = useCallback(() => {
    setActiveIncidentCollapsed(true);
  }, [setActiveIncidentCollapsed]);

  const handleShowIncidentPopup = useCallback(() => {
    setActiveIncidentCollapsed(false);
  }, [setActiveIncidentCollapsed]);

  useEffect(() => {
    if (activeIncidentId && !activeIncidentCollapsed) {
      requestIncidentById({ incidentId: +activeIncidentId });
      requestIncidentPlot({ incidentId: +activeIncidentId });
    }
  }, [activeIncidentCollapsed, activeIncidentId, requestIncidentById, requestIncidentPlot]);


  useEffect(() => {
    requestGetMetrics();
    requestGetGraphPresets();
    getAllGraphMetrics({ location }); // TODO: Refactoring SidePanel and remove it
    const fcVarieties = allVarieties
      .filter((item:Variety) => fruitClassCodeIds.includes(item.attributes.fruitClass))
      .map((item:Variety) => +item.id);
    requestMetricsTree({
      ids: null,
      species: speciesIds,
      varietyId: uniq([...varietyIds, ...fcVarieties]),
      fruitClassCode: fruitClassCodeIds,
      compartmentId: compartmentIds
    });
    requestFullTreeData();
    // eslint-disable-next-line
  }, [
    getAllGraphMetrics,
    requestGetGraphPresets,
    requestGetMetrics,
    requestMetricsTree,
    allVarieties,
    requestFullTreeData
    // compartmentIds
  ]);

  // const [graphPreset, nm, ticks, xRange, xRangeEnd, xRangeLengthInMins] = useGraphUrlParams();

  const [queryState, setQueryState] = useState(null);

  useUrlNavHandler(
    [
      'graphPreset',
      'xRange',
      'xRangeEnd',
      'xRangeLengthInMins',
      'ticks',
      'nm',
      'returnAbnormalityIncidents',
      'activeIncidentId',
      'showFirstIncident',
    ],
    (oldQueryState: any, newQueryState: any) => {
      if (!newQueryState?.ticks) {
        const thresholdSelectors: Array<any> = getGraphThresholdSelectors({
          location,
          xRange: preset?.preset?.xRange || 'last2Days',
          xRangeLengthInMins: preset?.preset?.xRangeLengthInMins || null,
          xRangeEnd: preset?.preset?.xRangeEnd || null,
        });
        changeGraphPeriodThreshold({
          threshold: thresholdSelectors[0].length,
          preventHistory: true,
        });
      }
      setQueryState(newQueryState);
    }
  );

  useEffect(() => {
    if (queryState && allMetrics.length > 0) {
      fetchData(queryState, requestGetGraphPreset, updatePresetDataFromUrl);
    }
  }, [allMetrics, queryState, requestGetGraphPreset, updatePresetDataFromUrl]);

  const handlerDeletePresetDialogCancel = useCallback(() => {
    setToggleGraphPresetDeleteDialog(false);
  }, [setToggleGraphPresetDeleteDialog]);

  const handlerDeletePresetDialogOk = useCallback(() => {
    requestDeleteGraphPreset({
      presetId: graphPresetDeleteDialogId,
    });
    setToggleGraphPresetDeleteDialog(false);
  }, [
    setToggleGraphPresetDeleteDialog,
    graphPresetDeleteDialogId,
    requestDeleteGraphPreset,
  ]);

  const handlerSidePanelClose = useCallback(() => {
    hideSidePanelAction();
    /*
    removeNotification('notifications.selectedDataSeriesLimit');
    setSelectedGraphMetricFilterOption({ graphMetricFilterOption: null });
    setGraphMetricFilter({ graphMetricFilter: '' });
    resetShowAllMetricCategories();
    resetLocationShowAll();
    */
  }, [hideSidePanelAction]);

  const range: GraphsPresetRange = {
    xRange: preset?.preset?.xRange || 'last2Days',
    xRangeEnd: preset?.preset?.xRangeEnd || null,
    xRangeLengthInMins: preset?.preset?.xRangeLengthInMins || null,
  };

  const graphMetrics = useMemo(
    () =>
      // const presetMetrics = preset?.metrics || [];
      uniqBy(
        [
          ...allMetrics,
          ...presetMetrics.map((presetMetric: MetricDef) => ({
            id: presetMetric.node[NodeComponents.MetricId],
            attributes: {
              ...presetMetric,
            },
            type: 'Metric',
          })),
        ],
        'id'
      ),
    [allMetrics, presetMetrics]
  );

  const linesState = useMemo(() => {
    if (graphMetrics.length === 0) {
      return {
        lines: [],
        minValue: 0,
        maxValue: 0,
      };
    }
    const allValues: Array<number> = [];
    const groups = groupBy(presetMetrics, item => [
      item.node[NodeComponents.TreeType],
      item.node[NodeComponents.Node],
      item.node[NodeComponents.SubNode],
    ]);
    const linesResult = presetMetrics.map(
      (presetMetric: MetricDef, index: number) => {
        const metricData = metricDatas.find(
          (dataItem: MetricData) =>
            dataItem.node.join('_') === presetMetric.node.join('_')
        );
        const scale =
          presetScales.find(
            (scaleItem: PresetMetricScale) =>
              scaleItem.name === presetMetric.scale
          ) || null;
        const values: Array<number> =
          metricData && presetMetric.selected && metricData.dataPoints
            ? Object.values(metricData.dataPoints).map(v => Number(v || 0))
            : [];
        const defaultStart = values?.length > 0 ? Math.min(...values) : 0;
        const defaultEnd = values?.length > 0 ? Math.max(...values) : 100;
        if (values?.length > 0 && !scale?.name) {
          allValues.push(defaultStart, defaultEnd);
        }
        const metric = graphMetrics.find(
          (metricItem: Metric) => metricItem.id === presetMetric.node[NodeComponents.MetricId]
        );
        const key = `metric_${presetMetric.node.join('_')}`;
        return {
          key,
          data: metricData?.dataPoints
            ? Object.keys(metricData.dataPoints).map((dtKey) => {
                const dt: number = +dtKey;
                const date = new Date(dt * 60000);
                return {
                  date: moment(date).utc(),
                  // @ts-ignore
                  value: parseFloat(metricData.dataPoints[dtKey]),
                };
              })
            : [],
          color: presetMetric.color || LINE_COLORS[index % LINE_COLORS.length],
          scale,
          node: [...presetMetric.node],
          title: getMetricName(metric, locale),
          description: getMetricDescription(
            buildMetricPath(presetMetric, {
            formatMessage,
            locale,
            allCompartments,
            allSubNodes,
            allVarieties,
            allFruitClasses,
            allPlantingCycles
          })
          ),
          selected: presetMetric.selected,
          displayScale: metric?.attributes?.displayScale || 0,
          cunit: metric?.attributes?.cunit,
          defaultStart,
          defaultEnd,
          isSingleGroupCheck: Object.keys(groups).length === 1,
        };
      }
    );
    const min = allValues.length > 0 ? Math.min(...allValues) : 0;
    const max = allValues.length > 0 ? Math.max(...allValues) : 100;
    let defaultStart = 0;
    let defaultEnd = 100;
    if (min === max) {
      defaultStart = max - min;
      defaultEnd = max;
      // binary metrics (all values is 0)
      if (min === 0) {
        defaultStart = 0;
        defaultEnd = 1;
      }
    } else {
      defaultStart = min;
      defaultEnd = max;
    }

    return {
      lines: linesResult,
      minValue: defaultStart,
      maxValue: defaultEnd,
    };
  }, [
    metricDatas,
    locale,
    formatMessage,
    allCompartments,
    allSubNodes,
    allVarieties,
    allFruitClasses,
    allPlantingCycles,
    graphMetrics,
    presetMetrics,
    presetScales,
  ]);

  const handlerChangePresetFilter = useCallback(
    (newRange: GraphsPresetRange, newThreshold: number) => {
      changeGraphPeriodAndThreshold({
        range: newRange,
        threshold: newThreshold,
      });
      requestGetGraphDataPoints(
        getGraphPointsPayload(preset, newRange, location, newThreshold)
      );
    },
    [changeGraphPeriodAndThreshold, preset, location, requestGetGraphDataPoints]
  );

  const handlerWalkRange = useCallback(
    (newRange: GraphsPresetRange) => {
      changeGraphPeriodAndThreshold({
        range: newRange,
        threshold,
      });
      requestGetGraphDataPoints(
        getGraphPointsPayload(preset, newRange, location, threshold)
      );
    },
    [
      changeGraphPeriodAndThreshold,
      threshold,
      requestGetGraphDataPoints,
      preset,
      location,
    ]
  );

  const handleScaleRange = useCallback(
    (newRange: GraphsPresetRange) => {
      pushRangeHistory({
        range,
        threshold,
      });
      const thresholdSelectors = getGraphThresholdSelectors({
        xRange: newRange?.xRange || undefined,
        xRangeEnd: newRange?.xRangeEnd || undefined,
        xRangeLengthInMins: newRange?.xRangeLengthInMins || undefined,
        location,
      });
      const minThreshold = Math.min(
        ...thresholdSelectors.map((item: any) => item.length)
      );
      changeGraphPeriodAndThreshold({
        range: newRange,
        threshold: minThreshold,
      });
      requestGetGraphDataPoints(
        getGraphPointsPayload(preset, newRange, location, minThreshold)
      );
    },
    [
      changeGraphPeriodAndThreshold,
      threshold,
      pushRangeHistory,
      range,
      location,
      requestGetGraphDataPoints,
      preset,
    ]
  );

  const hasNonBlockedMetrics = useMemo(
    () => presetMetrics?.some((metric: MetricDef) => metric.available),
    [presetMetrics]
  );

  const hasMetrics = useMemo(() => presetMetrics?.length > 0, [presetMetrics]);

  const hasSelectedAndNonBlockedMetrics = useMemo(
    () =>
      presetMetrics?.some(
        (metric: MetricDef) => metric.available && metric.selected
      ),
    [presetMetrics]
  );

  // @ts-ignore
  return (
    <div key='graphs' className={classnames(styles.graphs)}>
      <PageViewTracker onMount={handlerPageView} />

      <div
        className={classnames(styles.graphsContainer, {
          [styles.marginRight]: !activeIncidentCollapsed,
        })}
      >
        <div className={classnames(styles.layout)}>
          <div className={styles.paper}>
            <GraphsPresetSettings
              preset={preset}
              presets={presets}
              location={location}
              allCompartments={allCompartments}
              allSubNodes={allSubNodes}
              allMetrics={allMetrics}
              allSpecies={allSpecies}
              allVarieties={allVarieties}
              allFruitClasses={allFruitClasses}
              allPlantingCycles={allPlantingCycles}
              isEditedPreset={isEditedPreset}
              showSidePanelAction={showSidePanelAction}
              hideSidePanelAction={hideSidePanelAction}
              requestGetGraphPreset={requestGetGraphPreset}
              requestCreateGraphPreset={requestCreateGraphPreset}
              requestUpdateGraphPreset={requestUpdateGraphPreset}
              changePresetName={changePresetName}
              changePresetDescription={changePresetDescription}
              deletePresetMetric={deletePresetMetric}
              deletePresetMetricGroup={deletePresetMetricGroup}
              changePresetMetricGroup={changePresetMetricGroup}
              clearWorkspace={clearWorkspace}
              presetMetrics={presetMetrics}
              presetScales={presetScales}
              pushRangeHistory={pushRangeHistory}
              changeGraphPeriodAndThreshold={changeGraphPeriodAndThreshold}
              requestGetGraphDataPoints={requestGetGraphDataPoints}
              threshold={threshold}
            />
          </div>
          <div className={styles.paper}>
            {presetMetrics?.length ? (
              <GraphsPresetFilters
                location={location}
                range={range}
                threshold={threshold}
                onChange={handlerChangePresetFilter}
                hasPresetMetrics={presetMetrics?.length > 0}
              />
            ) : null}
            <ChartContainer
              location={location}
              lines={linesState.lines}
              defaultMinY={linesState.minValue}
              defaultMaxY={linesState.maxValue}
              enableMove='BOTH'
              scales={presetScales}
              incidents={incidents}
              returnAbnormalityIncidents={returnAbnormalityIncidents}
              activeIncidentId={activeIncidentId}
              setShowIncidents={setShowIncidents}
              openShowIncidentPopup={handleShowIncidentPopup}
              setActiveIncidentId={setActiveIncidentId}
              activeIncidentCollapsed={activeIncidentCollapsed}
              range={range}
              isFetching={isMetricsFetching}
              changeScale={changeScale}
              deleteScale={deleteScale}
              setMetricColor={setMetricColor}
              hasNonBlockedMetrics={hasNonBlockedMetrics}
              hasMetrics={hasMetrics}
              hasSelectedAndNonBlockedMetrics={hasSelectedAndNonBlockedMetrics}
              walkRange={handlerWalkRange}
              scaleRange={handleScaleRange}
              rangeHistory={rangeHistory}
              popRangeHistory={popRangeHistory}
              clearRangeHistory={clearRangeHistory}
              preset={preset}
              threshold={threshold}
              showSidePanelAction={showSidePanelAction}
              createIncident={createIncident}
              createdIncident={createdIncident}
              requestNewIncidentId={requestNewIncidentId}
              closeIncidentEditor={closeIncidentEditor}
              checkNewIncidentStatus={checkNewIncidentStatus}
              permissions={permissions}
            />
          </div>
          {presetMetrics?.length ? (
            <div className={styles.paper}>
              <ChartTable
                allCompartments={allCompartments}
                allSubNodes={allSubNodes}
                allMetrics={allMetrics}
                presetMetrics={presetMetrics}
                scales={presetScales}
                metricDatas={metricDatas}
                setPresetMetricSelected={setPresetMetricSelected}
                setAllPresetMetricSelected={setAllPresetMetricSelected}
                setMetricColor={setMetricColor}
                setMetricToScale={setMetricToScale}
                changeScale={changeScale}
                deleteScale={deleteScale}
                allSpecies={allSpecies}
                allVarieties={allVarieties}
                allFruitClasses={allFruitClasses}
                allPlantingCycles={allPlantingCycles}
              />
            </div>
          ) : null}
          {graphPresetDeleteDialogOpen && (
            <Dialog
              title={<FormattedMessage id='graphs.deleteDialog.title' />}
              onClose={handlerDeletePresetDialogCancel}
              wrapperClassName={styles.wrapperGraphPresetDialog}
            >
              <FormattedMessage id='graphs.deleteDialog.question' />
              <div className={styles.graphPresetDialogButtons}>
                <BigButton
                  className={styles.noButton}
                  title={formatMessage({ id: 'dialog.cancel' })}
                  onClick={handlerDeletePresetDialogCancel}
                  theme='light'
                />
                <BigButton
                  className={styles.yesButton}
                  title={formatMessage({ id: 'dialog.delete' })}
                  onClick={handlerDeletePresetDialogOk}
                  theme='dark'
                />
              </div>
            </Dialog>
          )}
          <SidePanel
            sidePanel={sidePanel}
            handlerClose={handlerSidePanelClose}
          />
        </div>
      </div>
      {/* <AbnormalityIncidentPanel
        incidents={incidents}
        feedback={feedback}
        activeIncidentId={activeIncidentId}
        activeIncidentCollapsed={activeIncidentCollapsed}
        onCollapse={handleIncidentCollapsed}
      /> */}
      <IncidentInfoPanel
        // @ts-ignore
        panelCollapsed={activeIncidentCollapsed}
        onCollapse={handleIncidentCollapsed}
      />
    </div>
  );
};

export default injectIntl(Graphs);
