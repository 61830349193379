import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getOrganizations } from 'store/company/selectors';

import CropsLink from '../components/CropsLink';

const mapDispatchToProps = { };
const mapStateToProps = (state, props) => {
  const {
    intl,
  } = state;

  return {
    ...props,
    ...intl,
    organizations: getOrganizations(state, props),
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CropsLink));
