import React, {
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  flatten, merge, sortBy, uniqueId
} from 'lodash';
import { utcParse as parse } from 'd3-time-format/src/defaultLocale';
import {
  calculateMargin,
  createDomainRangeGenerator,
  createValueGenerator,
  defaultColors,
  defaultStyles,
  getAxisStyles,
  reduce,
} from 'components/LinesGraph/components/LineChart/shared';
import {
  getTicksMinMax,
  getTicksRange,
} from 'helpers/getTicks';
import isTouchDevice from 'helpers/isTouchDevice';
import isPhoneView from 'helpers/isPhoneView';
import CircleLoader from 'components/CircleLoader';
import loaderStyles from 'components/CircleLoader/CircleLoader.module.css';
import { Style } from 'radium';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  bisector,
} from 'd3';

import browser from 'browser-detect';

import useXAxis from 'components/LinesGraph/components/LineChart/hoohs/useXAxis';
import useYAxis from 'components/LinesGraph/components/LineChart/hoohs/useYAxis';
import useLinePath from 'components/LinesGraph/components/LineChart/hoohs/useLinePath';
import usePoints from 'components/LinesGraph/components/LineChart/hoohs/usePoints';
import useHoverLineY from 'components/LinesGraph/components/LineChart/hoohs/useHoverLineY';
import useArea from 'components/LinesGraph/components/LineChart/hoohs/useArea';
import useMinMax from 'components/LinesGraph/components/LineChart/hoohs/useMinMax';
import useOverlay from 'components/LinesGraph/components/LineChart/hoohs/useOverlay';

import styles from './LineChart.module.css';

const CURRENT_BROWSER = browser();

const LineChart = ({
  data,
  axes,
  dataPreparer,
  datePattern,
  interpolate,
  xType,
  yType,
  xDomainRange,
  yDomainRange,
  margin,
  width,
  height,
  lineColors,
  yAxisOrientRight,
  yTicks,
  isDataFetching,
  grid,
  verticalGrid,
  style,
  axisLabels,
  xTicks,
  tickTimeDisplayFormat,
  xAxisPadding,
  customTicksFunction,
  mouseOverHandler,
  mouseOutHandler,
  mouseMoveHandler,
  clickHandler,
  withoutPoints,
  positionHoverLineY,
  isHoverLineSizeNeedCalc,
  mouseMoveCallback,
  closestPointCoordX,
  rightYDomainRange,
  rightData,
  customGetTicksRange,
  areaData,
  lineStyles,
  allLines,
  withoutMinMax,
  cropEmptyValues,
  overlay,
  emptyTitle,
  isEmpty,
}) => {
  const uid = useMemo(() => uniqueId('line-chart-id-'), []);
  const className = useMemo(() => `line-chart-${uid}`, [uid]);
  const lineChartClassName = useMemo(() => `lineChart-${uniqueId()}`, []);

  const lineChartEl = useRef(null);
  const chartRootEl = useRef(null);
  const svgRef = useRef(null);
  const xAxisRef = useRef(null);
  const yAxisLeftRef = useRef(null);
  const yAxisRightRef = useRef(null);
  const lineChartRightRef = useRef(null);
  const lineChartLeftRef = useRef(null);
  const dataPointsLeftRef = useRef(null);
  const dataPointsRightRef = useRef(null);
  const minMaxLeftRef = useRef(null);
  const minMaxRightRef = useRef(null);
  const areaChartRef = useRef(null);
  const overlayRef = useRef(null);

  const tickTimeDisplayFormatMemo = useCallback(
    (xFromValue, xToValue, ticksCount) =>
      tickTimeDisplayFormat(xFromValue, xToValue, ticksCount),
    [tickTimeDisplayFormat]
  );

  const isTouch = useMemo(() => isTouchDevice(), []);
  const isPhone = useMemo(() => isPhoneView(), []);

  const preparedData = useMemo(() => dataPreparer([...data, ...rightData]), [
    data,
    rightData,
    dataPreparer,
  ]);

  const sortedData = useMemo(() => sortBy(preparedData, point => point.x), [
    preparedData,
  ]);

  const preparedAllLinesData = useMemo(() =>
    allLines.map(line => ({ ...line, points: dataPreparer(line.points) })),
  [
    allLines,
    dataPreparer
  ]);

  const parseDate = useCallback(v => parse(datePattern)(v), [datePattern]);

  const chartParameters = useMemo(() => {
    const isRightAxis = rightData && rightData.length > 0 && rightYDomainRange;
    const m = calculateMargin(axes, margin, yAxisOrientRight, isRightAxis);
    const w = reduce(width, m.left, m.right);
    const h = reduce(height, m.top, m.bottom);

    let yDomainRangeCorrected = yDomainRange;
    if (yTicks) {
      const oldStart = yDomainRange[0];
      const oldStop = yDomainRange[1];
      const ticksRange = customGetTicksRange
        ? customGetTicksRange(oldStart, oldStop, yTicks)
        : getTicksRange(oldStart, oldStop, yTicks);
      yDomainRangeCorrected = getTicksMinMax(ticksRange);
    }

    const x = createDomainRangeGenerator(
      'x',
      xDomainRange,
      data,
      xType,
      w,
      parseDate
    );
    const y = createDomainRangeGenerator(
      'y',
      yDomainRangeCorrected,
      data,
      yType,
      h,
      parseDate
    );

    const xValue = createValueGenerator('x', xType, parseDate);
    const yValue = createValueGenerator('y', yType, parseDate);

    const colors = lineColors.concat(defaultColors);

    // for right axis
    let rightAxis = {};
    if (isRightAxis) {
      let rightYDomainRangeCorrected = rightYDomainRange;
      if (yTicks) {
        const oldStart = rightYDomainRange[0];
        const oldStop = rightYDomainRange[1];
        const ticksRange = customGetTicksRange
          ? customGetTicksRange(oldStart, oldStop, yTicks)
          : getTicksRange(oldStart, oldStop, yTicks);
        rightYDomainRangeCorrected = getTicksMinMax(ticksRange);
      }
      const rX = createDomainRangeGenerator(
        'x',
        xDomainRange,
        rightData,
        xType,
        w,
        parseDate
      );
      const rY = createDomainRangeGenerator(
        'y',
        rightYDomainRangeCorrected,
        rightData,
        yType,
        h,
        parseDate
      );
      const rYValue = createValueGenerator('y', yType, parseDate);
      rightAxis = {
        rX,
        rY,
        rYValue,
      };
    }

    return {
      m,
      w,
      h,
      x,
      y,
      xValue,
      yValue,
      colors,
      svgWidth: w + m.left + m.right,
      svgHeight: h + m.top + m.bottom,
      ...rightAxis,
    };
  }, [
    parseDate,
    axes,
    margin,
    yAxisOrientRight,
    yDomainRange,
    data,
    xType,
    yType,
    rightData,
    rightYDomainRange,
    width,
    height,
    customGetTicksRange,
    lineColors,
    xDomainRange,
    yTicks,
  ]);

  const isOnlyRightAxis = useMemo(() => !data?.length && rightData?.length, [
    data,
    rightData,
  ]);

  /*
  const offsetX = useMemo(() => {
    let offset = 0;
    // Определяем положение полотна, чтобы получить отступ от окна
    const lineChartElement = document.querySelector(`.${lineChartClassName}`);
    const lineChartClientRect = lineChartElement
      ? lineChartElement.getBoundingClientRect()
      : {};
    const currentBrowser = browser();

    offset = lineChartClientRect.x;

    // Здесь приходится завязываться на имя браузера, т.к. в Firefox getBoundingClientRect
    // не учитывает transform (ни css, ни атрибуты), а у нас transform используется для позиционирования
    // полотна. Из-за этого получаем неправильное значение для отсупов. Альтернативных методов
    // для получения отступа слева у svg нет. В итоге не можем завязаться на feature detection или использовать
    // другой способ. Добавляем вручную отступ, который делали в transform для полотна.
    // Подробнее: https://github.com/servo/servo/issues/6799,
    // а так же гуглить по ключевым "getBoundingClientRect does not account for transform"
    if (currentBrowser.name === 'firefox') {
      const margins = calculateMargin(axes, margin, yAxisOrientRight);

      offset += margins.left;
    }

    return offset;
    // eslint-disable-next-line
  }, [axes, margin, yAxisOrientRight, lineChartClassName, chartParameters]);
  */


  useXAxis(xAxisRef, {
    chartParams: chartParameters,
    xType,
    axisLabels,
    xTicks,
    grid,
    verticalGrid,
    tickTimeDisplayFormat: tickTimeDisplayFormatMemo,
    yAxisOrientRight,
    xAxisPadding,
    xDomainRange,
    customTicksFunction,
    isPhone,
    sortedData,
    preparedData,
    isOnlyRightAxis,
  });

  useYAxis(yAxisLeftRef, {
    chartParams: chartParameters,
    yType,
    axisLabels,
    yTicks,
    grid,
    tickTimeDisplayFormat: tickTimeDisplayFormatMemo,
    yAxisOrientRight,
    yDomainRange,
    customGetTicksRange,
  });

  useYAxis(
    yAxisRightRef,
    {
      chartParams: chartParameters,
      yType,
      axisLabels,
      yTicks,
      grid,
      tickTimeDisplayFormat: tickTimeDisplayFormatMemo,
      yAxisOrientRight: true,
      yDomainRange: rightYDomainRange,
      isRightAxis: true,
      customGetTicksRange,
      isDisabled: rightData?.length <= 0
    }
  );

  useLinePath(
    lineChartLeftRef,
    lineChartRightRef,
    {
      chartParams: chartParameters,
      data,
      rightData,
      interpolate,
      lineChartClassName,
      lineStyles,
      width,
      allLines,
      cropEmptyValues
    }
  );

  usePoints(
    dataPointsLeftRef,
    dataPointsRightRef,
    {
      chartParams: chartParameters,
      data,
      rightData,
      xType,
      yType,
      mouseOverHandler,
      mouseOutHandler,
      mouseMoveHandler,
      clickHandler,
      withoutPoints,
      parseDate,
    }
  );

  useMinMax(
    minMaxLeftRef,
    minMaxRightRef,
    {
      chartParams: chartParameters,
      data,
      rightData,
      xType,
      yType,
      mouseOverHandler,
      mouseOutHandler,
      mouseMoveHandler,
      clickHandler,
      withoutMinMax,
      parseDate,
    }
  );

  useHoverLineY(lineChartClassName, {
    chartParams: chartParameters,
    height,
    positionHoverLineY,
    closestPointCoordX,
    isHoverLineSizeNeedCalc,
    margin,
    withoutPoints,
    sortedData,
    allLines: preparedAllLinesData
  });

  useArea(
    areaChartRef,
    {
      chartParams: chartParameters,
      areaData,
      interpolate,
      height,
      margin,
      isHoverLineSizeNeedCalc,
    }
  );

  const allLinesPrepared = useMemo(() =>
    allLines.map(line => ({ ...line, points: dataPreparer(line.points) })),
  [allLines, dataPreparer]);

  const overlayOptions = {
    chartParams: chartParameters,
  };
  if (overlay) {
    Object.assign(overlayOptions, { ...overlay });
  }

  useOverlay(
    overlayRef, overlayOptions
  );

  const handlerMouseMove = useCallback(
    (e) => {
      let offsetX = 0;
      const lineChartElement = chartRootEl?.current;
      const lineChartClientRect = lineChartElement
        ? lineChartElement.getBoundingClientRect()
        : { x: 0 };
      offsetX = lineChartClientRect.x;
      if (CURRENT_BROWSER.name === 'firefox') {
        const margins = calculateMargin(axes, margin, yAxisOrientRight);
        offsetX += margins.left;
      }
      if (offsetX) {
        const { x, rX } = chartParameters;
        const containerScroll = document.querySelector('.layout__viewport') || {
          scrollLeft: 0,
        };
        // TODO getBoundingClientRect from target line
        const targetRect = xAxisRef?.current?.querySelector('.domain')?.getBoundingClientRect() || { left: 0 };
        let rightPoint = false;
        const cursorX = e.clientX - targetRect.left + containerScroll.scrollLeft;
        let xValue = x.invert(cursorX);
        if ((Number.isNaN(xValue) || xValue.toString() === 'Invalid Date') && rX) {
          xValue = rX.invert(cursorX);
          rightPoint = true;
        }
        const bisectDate = bisector(d => d.x).left;
        const intersectPoints = allLinesPrepared.map((line) => {
          const {
            points,
            tooltipHidePointIndexes
          } = line;
          const iLeft = bisectDate(points, xValue);
          if (iLeft === -1) {
            return null;
          }
          if (Array.isArray(tooltipHidePointIndexes) && tooltipHidePointIndexes.includes(iLeft)) {
            return null;
          }
          // Забираем нужную точку из массива
          const d0 = points[iLeft - 1];
          const d1 = points[iLeft];
          let closestPoint = null;
          if (d0 && d1) {
            closestPoint = points.length === iLeft ? d1 : d0;
          } else if (d0 || d1) {
            // debugger;
            if (points.length === iLeft || iLeft === 0) {
              closestPoint = d1 || d0;
            }
          }
          return closestPoint;
        })
          .filter(line => line !== null);
        const closestPoint = intersectPoints.reduce(
          (max, obj) => {
            if (moment.isMoment(max?.x)) {
              return ((max?.x?.isAfter(obj?.x)) ? max : obj);
            }
            return max?.x > obj?.x ? max : obj;
          },
          null
        );
        if (intersectPoints.length > 0) {
          if (mouseMoveCallback) {
            mouseMoveCallback({
              closestPoint,
              closestPointCoordX: rightPoint
                ? rX(closestPoint.x)
                : x(closestPoint.x),
              closestPointCoordXWithOffset: closestPointCoordX + offsetX + 30,
            });
          }
        }
      }
    },
    [
      chartParameters,
      closestPointCoordX,
      mouseMoveCallback,
      margin,
      yAxisOrientRight,
      axes,
      allLinesPrepared,
      xAxisRef,
      chartRootEl
    ]
  );

  const StyleDef = useMemo(() => {
    const scope = `.line-chart-${uid}`;
    const axisStyles = getAxisStyles(grid, verticalGrid, yAxisOrientRight);
    const rules = merge({}, defaultStyles, style, axisStyles);

    return <Style scopeSelector={scope} rules={rules} />;
  }, [style, grid, verticalGrid, yAxisOrientRight, uid]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={lineChartEl}
      className={className}
      onMouseMove={e => handlerMouseMove(e)}
      onClick={isTouch ? e => handlerMouseMove(e) : null}
    >
      {StyleDef}
      <svg
        width={chartParameters.svgWidth}
        height={chartParameters.svgHeight}
        ref={svgRef}
      >
        <g
          transform={`translate(${chartParameters.m.left}, ${chartParameters.m.top})`}
          ref={chartRootEl}
        >
          <g ref={overlayRef} className='linesOverlay' />
          <g
            ref={xAxisRef}
            transform={`translate(0, ${chartParameters.h})`}
            className='x axis'
          />
          <g ref={yAxisLeftRef} className='y axis left' />
          <g ref={yAxisRightRef} className='y axis right' />
          <g ref={lineChartLeftRef} className='lineChart left' />
          <g ref={lineChartRightRef} className='lineChart right' />
          <g ref={dataPointsLeftRef} className='dataPoints left' />
          <g ref={dataPointsRightRef} className='dataPoints right' />
          <g ref={minMaxLeftRef} className='minMax left' />
          <g ref={minMaxRightRef} className='minMax right' />
          <g ref={areaChartRef} className='areaChart' />
        </g>
      </svg>
      {isDataFetching && (
        <CircleLoader
          className={loaderStyles.circleLoader}
          iconClassName={loaderStyles.circleLoaderIcon}
        />
      )}

      {isEmpty && emptyTitle ? (
        <div className={styles.emptyState}>
          {emptyTitle}
        </div>
      ) : null}
    </div>
  );
};

LineChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  xType: PropTypes.string,
  yType: PropTypes.string,
  datePattern: PropTypes.string,
  interpolate: PropTypes.string,
  style: PropTypes.object,
  margin: PropTypes.object,
  axes: PropTypes.bool,
  grid: PropTypes.bool,
  verticalGrid: PropTypes.bool,
  isHoverLineSizeNeedCalc: PropTypes.bool,
  xAxisPadding: PropTypes.number,
  xDomainRange: PropTypes.array,
  yDomainRange: PropTypes.array,
  tickTimeDisplayFormat: PropTypes.func,
  yTicks: PropTypes.number,
  xTicks: PropTypes.number,
  lineColors: PropTypes.array,
  axisLabels: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
  }),
  yAxisOrientRight: PropTypes.bool,
  mouseOverHandler: PropTypes.func,
  mouseOutHandler: PropTypes.func,
  mouseMoveHandler: PropTypes.func,
  clickHandler: PropTypes.func,
  positionHoverLineY: PropTypes.number,
  mouseMoveCallback: PropTypes.func,
  dataPreparer: PropTypes.func,
  isDataFetching: PropTypes.bool,
  closestPointCoordX: PropTypes.number,
  withoutPoints: PropTypes.bool,
  customTicksFunction: PropTypes.func,
  rightYDomainRange: PropTypes.array,
  rightData: PropTypes.array,
  customGetTicksRange: PropTypes.func,
  areaData: PropTypes.array,
  lineStyles: PropTypes.array,
  allLines: PropTypes.array,
  withoutMinMax: PropTypes.bool,
  cropEmptyValues: PropTypes.bool,
  overlay: PropTypes.object,
  emptyTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  isEmpty: PropTypes.object,
};

LineChart.defaultProps = {
  style: null,
  margin: null,
  grid: null,
  verticalGrid: null,
  xDomainRange: null,
  yDomainRange: null,
  rightYDomainRange: null,
  rightData: null,
  tickTimeDisplayFormat: null,
  yTicks: null,
  xTicks: null,
  yAxisOrientRight: null,
  positionHoverLineY: null,
  isHoverLineSizeNeedCalc: false,
  xAxisPadding: 0,
  width: 200,
  height: 150,
  datePattern: '%d-%b-%y',
  interpolate: 'linear',
  axes: false,
  xType: 'linear',
  yType: 'linear',
  lineColors: [],
  axisLabels: {
    x: '',
    y: '',
  },
  isDataFetching: false,
  closestPointCoordX: null,
  mouseOverHandler: () => {},
  mouseOutHandler: () => {},
  mouseMoveHandler: () => {},
  clickHandler: () => {},
  mouseMoveCallback: () => {},
  dataPreparer: data =>
    flatten(data).map(point => ({
      ...point,
      x: moment.utc(point.x, 'MM-DD-YYYY'),
    })),
  withoutPoints: false,
  customTicksFunction: null,
  customGetTicksRange: null,
  areaData: [],
  lineStyles: [],
  allLines: [],
  withoutMinMax: true,
  cropEmptyValues: false,
  overlay: null,
  emptyTitle: undefined,
  isEmpty: false,
};

export default LineChart;
