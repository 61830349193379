import { createAction } from 'redux-actions';

export const requestMeasurementsByPeriod = createAction('REQUEST_MEASUREMENTS_BY_PERIOD');
export const receiveMeasurementsByPeriod = createAction('RECEIVE_MEASUREMENTS_BY_PERIOD');

export const requestMeasurementsPlantsList = createAction('REQUEST_MEASUREMENTS_PLANTS_LIST');
export const receiveMeasurementsPlantsList = createAction('RECEIVE_MEASUREMENTS_PLANTS_LIST');

export const requestMeasurementsAdditionalMetrics = createAction('REQUEST_MEASUREMENTS_ADDITIONAL_METRICS');
export const receiveMeasurementsAdditionalMetrics = createAction('RECEIVE_MEASUREMENTS_ADDITIONAL_METRICS');

export const requestMeasurementsByDate = createAction('REQUEST_MEASUREMENTS_BY_DATE');
export const receiveMeasurementsByDate = createAction('RECEIVE_MEASUREMENTS_BY_DATE');
export const clearMeasurementsByDate = createAction('CLEAR_MEASUREMENTS_BY_DATE');

export const requestSaveMeasurementsByDate = createAction('REQUEST_SAVE_MEASUREMENTS_BY_DATE');
export const receiveSaveMeasurementsByDate = createAction('RECEIVE_SAVE_MEASUREMENTS_BY_DATE');

export const requestCreateNextPlant = createAction('REQUEST_CREATE_NEXT_PLANT');
export const receiveCreateNextPlant = createAction('RECEIVE_CREATE_NEXT_PLANT');

export const requestUpdatePlant = createAction('REQUEST_UPDATE_PLANT');
export const receiveUpdatePlant = createAction('RECEIVE_UPDATE_PLANT');

export const requestDeletePlant = createAction('REQUEST_DELETE_PLANT');
export const receiveDeletePlant = createAction('RECEIVE_DELETE_PLANT');

export const saveMeasurementsAdditionalMetrics = createAction('SAVE_MEASUREMENTS_ADDITIONAL_METRICS');
export const receiveSaveMeasurementsAdditionalMetrics = createAction('RECEIVE_SAVE_MEASUREMENTS_ADDITIONAL_METRICS');

export const saveMeasurementsGraphMetrics = createAction('SAVE_MEASUREMENTS_GRAPH_METRICS');
export const receiveSaveMeasurementsGraphMetrics = createAction('RECEIVE_SAVE_MEASUREMENTS_GRAPH_METRICS');

export const requestDefaultNumberOfPlants = createAction('REQUEST_DEFAULT_NUMBER_OF_PLANTS');
export const receiveDefaultNumberOfPlants = createAction('RECEIVE_DEFAULT_NUMBER_OF_PLANTS');

export const requestDefaultNumberOfPlantsUpdate = createAction('REQUEST_DEFAULT_NUMBER_OF_PLANTS_UPDATE');
export const receiveDefaultNumberOfPlantsUpdate = createAction('RECEIVE_DEFAULT_NUMBER_OF_PLANTS_UPDATE');

export const requestManualFormsMetrics = createAction('REQUEST_MANUAL_FORMS_METRICS');
export const receiveManualFormsMetrics = createAction('RECEIVE_MANUAL_FORMS_METRICS');

export const requestMeasurementsExport = createAction('REQUEST_MEASUREMENTS_EXPORT');
export const receiveMeasurementsExport = createAction('RECEIVE_MEASUREMENTS_EXPORT');
