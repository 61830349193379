import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { split } from 'moment-range-split';

extendMoment(moment);

export const emptyStateIntervals = ({
  anyDateOfPeriodStart,
  anyDateOfPeriodEnd,
  periodType,
  dataUnits
}) => {
  const intervals = split(moment.range(anyDateOfPeriodStart, anyDateOfPeriodEnd), `${periodType}s`);
  return {
    type: 'total',
    data: intervals.reduce((acc, value) => {
      const startKey = (value.start.utc().add(1, 'days').valueOf() / 60000).toString();
      const endKey = (value.end.utc().valueOf() / 60000).toString();
      return { ...acc, [startKey]: null, [endKey]: null };
    }, {}),
    dataUnits
  };
};


export const tableEmptyStateIntervals = ({
  anyDateOfPeriodStart,
  anyDateOfPeriodEnd,
  periodType,
  getDate,
  intl,
  labelLocId,
  value,
}) => {
  const { formatMessage } = intl;
  const intervals = split(moment.range(anyDateOfPeriodStart, anyDateOfPeriodEnd), `${periodType}s`);
  return {
    headerItems: [
      {
        label: formatMessage({ id: labelLocId }),
        value,
        textAlign: 'left'
      },
    ].concat(intervals.map(interval => ({
      label: getDate({
        date: interval.start,
        intl,
        periodType
      }),
      key: interval.start
    }))),
    bodyItems: []
  };
};
