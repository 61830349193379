import 'whatwg-fetch';

import { trackAddMeasurementClick } from './crops/actions';

// ------------------------------------
// Constants
// ------------------------------------

export const TOGGLE_GRAPH_PRESET_DATES_DIALOG = 'TOGGLE_GRAPH_PRESET_DATES_DIALOG';
export const TOGGLE_GRAPH_PRESET_PARAMETERS_DIALOG = 'TOGGLE_GRAPH_PRESET_PARAMETERS_DIALOG';
export const TOGGLE_GRAPH_PRESET_CREATE_DIALOG = 'TOGGLE_GRAPH_PRESET_CREATE_DIALOG';
export const TOGGLE_GRAPH_PRESET_UPDATE_DIALOG = 'TOGGLE_GRAPH_PRESET_UPDATE_DIALOG';
export const TOGGLE_GRAPH_PRESET_DELETE_DIALOG = 'TOGGLE_GRAPH_PRESET_DELETE_DIALOG';
export const TOGGLE_TABLE_CELL_EDIT_DIALOG = 'TOGGLE_TABLE_CELL_EDIT_DIALOG';
export const TOGGLE_CROP_CREATE_DIALOG = 'TOGGLE_CROP_CREATE_DIALOG';
export const TOGGLE_PHOTO_DETAIL_DIALOG = 'TOGGLE_PHOTO_DETAIL_DIALOG';
export const TOGGLE_PHOTO_UPLOAD_DIALOG = 'TOGGLE_PHOTO_UPLOAD_DIALOG';
export const TOGGLE_PHOTO_DELETE_DIALOG = 'TOGGLE_PHOTO_DELETE_DIALOG';
export const TOGGLE_ERROR_DIALOG = 'TOGGLE_ERROR_DIALOG';


// ------------------------------------
// Actions
// ------------------------------------

/*  This is a thunk, meaning it is a function that immediately
 returns a function for lazy evaluation. It is incredibly useful for
 creating async actions, especially when combined with redux-thunk! */

function toggleTableCellEditDialog({
  open,
  date,
  metric,
  dailyValues,
  mode,
  plantingCycle,
  compartment,
}) {
  return {
    type: TOGGLE_TABLE_CELL_EDIT_DIALOG,
    tableCellEditDialogMode: mode,
    tableCellEditDialogOpen: open,
    tableCellEditDialogDateEditable: !date,
    tableCellEditDialogDate: date,
    tableCellEditDialogMetric: metric,
    tableCellEditDialogDailyValues: dailyValues,
    tableCellEditDialogPlantingCycle: plantingCycle,
    tableCellEditDialogCompartment: compartment,
    receivedAt: Date.now()
  };
}

export function toggleCropCreateDialog({
  open, date, observations, sampleNumber, isLastSample
}) {
  return {
    type: TOGGLE_CROP_CREATE_DIALOG,
    cropCreateDialogOpen: open,
    cropCreateDialogDateEditable: !date,
    cropCreateDialogDate: date,
    cropCreateDialogSampleNumber: sampleNumber,
    cropCreateDialogObservations: observations,
    cropCreateDialogIsLastSample: isLastSample,
    receivedAt: Date.now()
  };
}

function toggleGraphPresetCreateDialog(open) {
  return {
    type: TOGGLE_GRAPH_PRESET_CREATE_DIALOG,
    graphPresetCreateDialogOpen: open,
    receivedAt: Date.now()
  };
}

function toggleGraphPresetUpdateDialog(open) {
  return {
    type: TOGGLE_GRAPH_PRESET_UPDATE_DIALOG,
    graphPresetUpdateDialogOpen: open,
    receivedAt: Date.now()
  };
}

function toggleGraphPresetDeleteDialog(open, id) {
  return {
    type: TOGGLE_GRAPH_PRESET_DELETE_DIALOG,
    graphPresetDeleteDialogOpen: open,
    graphPresetDeleteDialogId: id,
    receivedAt: Date.now()
  };
}

function toggleGraphPresetDatesDialog(open) {
  return {
    type: TOGGLE_GRAPH_PRESET_DATES_DIALOG,
    graphPresetDatesDialogOpen: open,
    receivedAt: Date.now()
  };
}

function toggleGraphPresetParametersDialog(open) {
  return {
    type: TOGGLE_GRAPH_PRESET_PARAMETERS_DIALOG,
    graphPresetParametersDialogOpen: open,
    receivedAt: Date.now()
  };
}

function togglePhotoDetailDialog(open) {
  return {
    type: TOGGLE_PHOTO_DETAIL_DIALOG,
    photoDetailDialogOpen: open,
    receivedAt: Date.now()
  };
}

function togglePhotoUploadDialog(open) {
  return {
    type: TOGGLE_PHOTO_UPLOAD_DIALOG,
    photoUploadDialogOpen: open,
    receivedAt: Date.now()
  };
}

function togglePhotoDeleteDialog(open) {
  return {
    type: TOGGLE_PHOTO_DELETE_DIALOG,
    photoDeleteDialogOpen: open,
    receivedAt: Date.now()
  };
}

export function toggleErrorDialog(open, message) {
  return {
    type: TOGGLE_ERROR_DIALOG,
    errorDialogOpen: open,
    errorDialogMessage: message,
    receivedAt: Date.now()
  };
}

export function setToggleCropCreateDialog({
  open, date, observations, sampleNumber, isLastSample, withTrackEvent = false
}) {
  return async (dispatch) => {
    if (open && withTrackEvent) {
      dispatch(trackAddMeasurementClick());
    }

    await dispatch(toggleCropCreateDialog({
      open: !!open, date, observations, sampleNumber, isLastSample
    }));
  };
}

export function setToggleTableCellEditDialog({
  open,
  date,
  dailyValues,
  metric,
  mode,
  plantingCycle,
  compartment,
}) {
  return async (dispatch) => {
    await dispatch(toggleTableCellEditDialog({
      open,
      date,
      dailyValues,
      metric,
      mode,
      plantingCycle,
      compartment,
    }));
  };
}

export function setToggleGraphPresetCreateDialog(open) {
  return async (dispatch) => {
    await dispatch(toggleGraphPresetCreateDialog(!!open));
  };
}

export function setToggleGraphPresetUpdateDialog(open) {
  return async (dispatch) => {
    await dispatch(toggleGraphPresetUpdateDialog(!!open));
  };
}

export function setToggleGraphPresetDeleteDialog(open, id) {
  return async (dispatch) => {
    await dispatch(toggleGraphPresetDeleteDialog(!!open, id));
  };
}

export function setToggleGraphPresetDatesDialog(open) {
  return async (dispatch) => {
    await dispatch(toggleGraphPresetDatesDialog(!!open));
  };
}

export function setToggleGraphPresetParametersDialog(open) {
  return async (dispatch) => {
    await dispatch(toggleGraphPresetParametersDialog(!!open));
  };
}

export function setTogglePhotoDetailDialog(open) {
  return async (dispatch) => {
    await dispatch(togglePhotoDetailDialog(!!open));
  };
}

export function setTogglePhotoUploadDialog(open) {
  return async (dispatch) => {
    await dispatch(togglePhotoUploadDialog(!!open));
  };
}

export function setTogglePhotoDeleteDialog(open) {
  return async (dispatch) => {
    await dispatch(togglePhotoDeleteDialog(!!open));
  };
}

export function setToggleErrorDialog(open, message) {
  return async (dispatch) => {
    await dispatch(toggleErrorDialog(!!open, message));
  };
}


// ------------------------------------
// Reducer
// ------------------------------------

export default function (state = {
  error: false,
  photoDetailDialogOpen: false,
  photoUploadDialogOpen: false,
  photoDeleteDialogOpen: false,
  cropCreateDialogOpen: false,
  graphPresetChangeWorkspaceDialogOpen: false,
  graphPresetCreateDialogOpen: false,
  graphPresetUpdateDialogOpen: false,
  graphPresetDeleteDialogOpen: false,
  graphPresetDeleteDialogId: null,
  graphPresetDatesDialogOpen: false,
  graphPresetParametersDialogOpen: false,
  cropCreateDialogDateEditable: true,
  cropCreateDialogDate: null,
  cropCreateDialogSampleNumber: null,
  cropCreateDialogObservations: null,
  cropCreateDialogIsLastSample: false,

  tableCellEditDialogMode: null,
  tableCellEditDialogOpen: false,
  tableCellEditDialogDateEditable: null,
  tableCellEditDialogDate: null,
  tableCellEditDialogMetric: null,
  tableCellEditDialogDailyValues: null,
  tableCellEditDialogPlantingCycle: null,
  tableCellEditDialogCompartment: null,

  errorDialogOpen: false,
  errorDialogMessage: null,
}, action) {
  switch (action.type) {
    case TOGGLE_ERROR_DIALOG:
      return {
        ...state,
        errorDialogOpen: action.errorDialogOpen,
        errorDialogMessage: action.errorDialogMessage,
        isFetching: false,
        isTablesFetching: false,
        isGraphsFetching: false,
        isCropsFetching: false,
        isPhotosFetching: false,
      };

    case TOGGLE_CROP_CREATE_DIALOG:
      return {
        ...state,
        cropCreateDialogOpen: action.cropCreateDialogOpen,
        cropCreateDialogDateEditable: action.cropCreateDialogDateEditable,
        cropCreateDialogDate: action.cropCreateDialogDate,
        cropCreateDialogSampleNumber: action.cropCreateDialogSampleNumber,
        cropCreateDialogObservations: action.cropCreateDialogObservations,
        cropCreateDialogIsLastSample: action.cropCreateDialogIsLastSample
      };

    case TOGGLE_TABLE_CELL_EDIT_DIALOG:
      return {
        ...state,
        tableCellEditDialogOpen: action.tableCellEditDialogOpen,
        tableCellEditDialogDateEditable: action.tableCellEditDialogDateEditable,
        tableCellEditDialogMode: action.tableCellEditDialogMode,
        tableCellEditDialogDate: action.tableCellEditDialogDate,
        tableCellEditDialogMetric: action.tableCellEditDialogMetric,
        tableCellEditDialogDailyValues: action.tableCellEditDialogDailyValues,
        tableCellEditDialogPlantingCycle: action.tableCellEditDialogPlantingCycle,
        tableCellEditDialogCompartment: action.tableCellEditDialogCompartment,
      };

    case TOGGLE_GRAPH_PRESET_CREATE_DIALOG:
      return { ...state, graphPresetCreateDialogOpen: action.graphPresetCreateDialogOpen, };

    case TOGGLE_GRAPH_PRESET_UPDATE_DIALOG:
      return { ...state, graphPresetUpdateDialogOpen: action.graphPresetUpdateDialogOpen, };

    case TOGGLE_GRAPH_PRESET_DELETE_DIALOG:
      return {
        ...state,
        graphPresetDeleteDialogOpen: action.graphPresetDeleteDialogOpen,
        graphPresetDeleteDialogId: action.graphPresetDeleteDialogId,
      };

    case TOGGLE_GRAPH_PRESET_DATES_DIALOG:
      return { ...state, graphPresetDatesDialogOpen: action.graphPresetDatesDialogOpen, };

    case TOGGLE_GRAPH_PRESET_PARAMETERS_DIALOG:
      return { ...state, graphPresetParametersDialogOpen: action.graphPresetParametersDialogOpen, };

    case TOGGLE_PHOTO_DETAIL_DIALOG:
      return { ...state, photoDetailDialogOpen: action.photoDetailDialogOpen, };

    case TOGGLE_PHOTO_UPLOAD_DIALOG:
      return { ...state, photoUploadDialogOpen: action.photoUploadDialogOpen, };

    case TOGGLE_PHOTO_DELETE_DIALOG:
      return { ...state, photoDeleteDialogOpen: action.photoDeleteDialogOpen, };

    default:
      return state;
  }
}
