import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import {
  requestClimateIncidentsTree,
  receiveClimateIncidentsTree,
  requestClimateIncidentsTypes,
  receiveClimateIncidentsTypes,
  requestClimateIncident,
  receiveClimateIncident
} from 'store/climateInsightsTimeline/actions';

import queryString from 'query-string';
import initAxiosInstanse from '../../api/axios';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

export const getClimateIncidentsTreeEpic = (action$, store) =>
  action$.ofType(requestClimateIncidentsTree)
    .mergeMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: locationId } = location;

      const {
        payload
      } = actionData;

      const ids = payload?.ids;
      const start = payload?.start;
      const endInclusive = payload?.endInclusive;
      const compartmentId = payload?.compartmentId;
      const subNodeId = payload?.subNodeId;
      const incidentTypeId = payload?.incidentTypeId;
      const incidentSeverity = payload?.incidentSeverity;
      const keepNodes = payload?.keepNodes;
      const expand = payload?.expandNodeId;
      const actionSuccess = payload?.actionSuccess;
      const resetExpand = payload?.resetExpand;

      const searchParams = router?.location?.search;

      const queryParams = queryString.stringify({
        start,
        endInclusive,
        compartmentId,
        subNodeId,
        incidentTypeId,
        incidentSeverity,
        expand
      });

      const bodyQuery = {
        ...(keepNodes && { keepNodes })
      };

      if (incidentSeverity?.length === 1 && incidentSeverity[0] === 0) {
        return Observable.of(
          receiveClimateIncidentsTree({
            complete: true,
            node: {
              path: [],
              entity: {},
              groups: [],
              children: []
            },
            range: []
          })
        );
      }

      if (compartmentId?.length === 1 && compartmentId[0] === 0) {
        return Observable.of(
          receiveClimateIncidentsTree({
            complete: true,
            node: {
              path: [],
              entity: {},
              groups: [],
              children: []
            },
            range: []
          })
        );
      }

      const path = ids ? `/incidents/${locationId}/tree/${ids}` : `/incidents/${locationId}/tree`;
      const query = queryParams ? `&${queryParams}` : '';
      return Observable
        .from(initAxiosInstanse(searchParams).post(`${path}?${query}`, bodyQuery))
        .mergeMap(({ data }) => {
          const observables = [
            receiveClimateIncidentsTree({ ...data, patchTree: payload?.patchTree, resetExpand })
          ];
          if (actionSuccess) {
            observables.push(() => actionSuccess(ids, data));
          }
          return observables.length > 0 ? Observable.of(...observables) : Observable.empty();
        })
        // .map(({ data }) => receiveClimateIncidentsTree({ ...data, patchTree: payload?.patchTree }))
        .catch(() => Observable.of(
          receiveClimateIncidentsTree(),
          showNotificationWithTimeout({
            id: `notifications.getClimateIncidentsTreeError.${Date.now()}`,
            messageId: 'notifications.getClimateIncidentsTreeError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

// requestClimateIncidentsTreeSubNode

export const getClimateIncidentsTypesEpic = (action$, store) =>
  action$.ofType(requestClimateIncidentsTypes)
    .mergeMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: locationId } = location;

     const searchParams = router?.location?.search;

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/locations/${locationId}/incident/types`))
        .map(({ data }) => receiveClimateIncidentsTypes(data))
        .catch(() => Observable.of(
          receiveClimateIncidentsTypes(),
          showNotificationWithTimeout({
            id: `notifications.getClimateIncidentsTypesError.${Date.now()}`,
            messageId: 'notifications.getClimateIncidentsTypesError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getClimateIncidentEpic = (action$, store) =>
  action$.ofType(requestClimateIncident)
    .mergeMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: locationId } = location;

      const searchParams = router?.location?.search;

      const {
        payload
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/locations/${locationId}/incident/${payload.id}`))
        .map(({ data }) => receiveClimateIncident(data))
        .catch(() => Observable.of(
          receiveClimateIncident(),
          showNotificationWithTimeout({
            id: `notifications.getClimateIncidentError.${Date.now()}`,
            messageId: 'notifications.getClimateIncidentError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });


export default combineEpics(
  getClimateIncidentsTreeEpic,
  getClimateIncidentsTypesEpic,
  getClimateIncidentEpic
);
