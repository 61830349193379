import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { find, get } from 'lodash';

import /* useQuery, */ { getQueryValue } from 'hooks/useQuery/useQuery';

import SelectboxFilterWithChecks from 'components/SelectboxFilterWithChecks';
import Select from 'components/Select';

import styles from './CompartmentFilter.module.css';
import updateLocationSearch from '../../../../helpers/updateLocationSearch';

const CompartmentFilter = ({
  intl,
  compartments,
  compartmentId: externCompartmentsIds,
  disableUseQuery,
  onFiltersChange,
  className,
  classNameButton,
  classNameWrapper,
  noTitle,
  defaultNoSelectedValue,
  capitalizeTitle,
  title,
  selectComponent,
  customTitle,
  customPlaceholder,
  isTitleEnabled,
  isMobile,
  onSetPlaceholder,
  popupClassName
}) => {
  const { formatMessage, formatPlural } = intl;

  // const [compartmentId, setCompartmentId] = useQuery('compartmentId', '');
  const compartmentId = getQueryValue('compartmentId') || '';

  const selectedCompartments = disableUseQuery ? externCompartmentsIds?.join(',') : compartmentId;

  const compartmentsList = useMemo(() => compartments.reduce((acc, compartment) => (
    [
      ...acc,
      {
        label: get(compartment, 'attributes.name'),
        value: compartment.id,
        checked: selectedCompartments ? !!selectedCompartments.split(',').find(id => +compartment.id === +id) : true,
      },
    ]
  ), []), [selectedCompartments, compartments]);

  const handlerCompartmentsChange = useCallback((options) => {
    const isAll = options.every(item => get(item, 'checked'));
    const isAllReset = options.every(item => !get(item, 'checked'));

    let foundIds;

    if (!isAll) {
      foundIds = options.filter(item => get(item, 'checked')).map(item => get(item, 'value'));
    }

    if (isAllReset) {
      foundIds = defaultNoSelectedValue;
    }

    if (!disableUseQuery) {
      // setCompartmentId(foundIds ? foundIds.join(',') : undefined);
      updateLocationSearch({
        compartmentId: foundIds ? foundIds.join(',') : undefined
      });
    }

    if (onFiltersChange) {
      onFiltersChange({ compartmentId: foundIds });
    }
  }, [onFiltersChange, /* setCompartmentId, */ defaultNoSelectedValue, disableUseQuery]);

  const isAllChecked = useMemo(() => compartmentsList.every(option => option.checked), [compartmentsList]);

  const placeholderText = useMemo(() => {
    if (customPlaceholder) {
      return customPlaceholder;
    }
    if (isAllChecked) {
      const pluralPlaceholderTextAll = formatMessage({ id: 'dataQuality.greenhouses.other' });

      return `${formatMessage({ id: 'button.all' })} ${pluralPlaceholderTextAll.toLowerCase()}`;
    }
    const countCheckedOptions = compartmentsList.reduce((acc, option) => (option.checked ? acc + 1 : acc), 0);

    if (countCheckedOptions > 1 || countCheckedOptions === 0) {
      const pluralPlaceholderText = formatMessage({ id: `dataQuality.greenhouses.${formatPlural(countCheckedOptions)}` });

      return `${countCheckedOptions} ${pluralPlaceholderText.toLowerCase()}`;
    }

    if (countCheckedOptions === 1) {
      const checkedOption = find(compartmentsList, { checked: true });

      return get(checkedOption, 'label');
    }

    // return 'Nothing selected';
    return null;
  }, [isAllChecked, compartmentsList, customPlaceholder, formatMessage, formatPlural]);

  const placeholder = useMemo(() =>
    customPlaceholder || (isMobile ? formatMessage({ id: 'dashboards.growingInTitle' }) : placeholderText),
    [customPlaceholder, isMobile, formatMessage, placeholderText]);

  const options = {
    options: compartmentsList
      .sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' })),
    onChange: handlerCompartmentsChange,
    className,
    classNameWrapper: classNameWrapper || styles.selectboxFilterWrapper,
    classNameButton,
    capitalizeTitle,
    selectComponent,
    customTitle,
    isTitleEnabled,
    customPlaceholder: placeholder,
    isMobile,
    pluralPlaceholderId: 'dataQuality.greenhouses',
    popupClassName,
  };

  if (!noTitle) {
    options.title = title || formatMessage({ id: 'dashboards.growingIn' });
  }

  useEffect(() => {
    if (onSetPlaceholder) {
      onSetPlaceholder('compartment', placeholder);
    }
  }, [onSetPlaceholder, placeholder]);

  return (
    <SelectboxFilterWithChecks {...options} />
  );
};

CompartmentFilter.propTypes = {
  intl: intlShape.isRequired,
  compartments: PropTypes.array,
  currentCompartment: PropTypes.string,
  onFiltersChange: PropTypes.func,
  noTitle: PropTypes.bool,
  className: PropTypes.string,
  classNameButton: PropTypes.string,
  classNameWrapper: PropTypes.string,
  defaultNoSelectedValue: PropTypes.array,
  capitalizeTitle: PropTypes.bool,
  compartmentId: PropTypes.array,
  disableUseQuery: PropTypes.bool,
  title: PropTypes.string,
  selectComponent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  customTitle: PropTypes.string,
  isTitleEnabled: PropTypes.bool,
  customPlaceholder: PropTypes.string,
  isMobile: PropTypes.bool,
  onSetPlaceholder: PropTypes.func,
  popupClassName: PropTypes.string
};

CompartmentFilter.defaultProps = {
  currentCompartment: null,
  onFiltersChange: null,
  noTitle: false,
  className: null,
  classNameButton: null,
  classNameWrapper: null,
  defaultNoSelectedValue: [0],
  compartments: [],
  capitalizeTitle: false,
  compartmentId: null,
  disableUseQuery: false,
  title: undefined,
  selectComponent: Select,
  customTitle: undefined,
  isTitleEnabled: false,
  customPlaceholder: undefined,
  isMobile: false,
  onSetPlaceholder: null,
  popupClassName: null
};

export default CompartmentFilter;

