import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { get } from 'lodash';
import queryString from 'query-string';

import {
  getHarvestDashboardData,
  getHarvestGraphData,
  getQualityGraphData,
  getBreakdownGraphData,
} from 'store/dashboards/actions';

import { getHarvestCategoriesDefault, clearHarvestDataByCategories } from 'store/harvest/actions';

import {
  getFirstSpecies,
  getUnitsFromRoute,
  getBreakdownFromRoute,
  getVarietiesInLocation,
  getCurrentPlantingCycles,
  getCurrentCompartments,
  getSpeciesFromRoute,
  getPercentageForQualityFromRoute,
  getCumulativeForQualityFromRoute,
  getAnyDateOfPeriodStartFromRoute,
  getAnyDateOfPeriodEndFromRoute,
  getIsOnlyVegetables,
  getIsOnlyLettuce,
  getAllFruitClasses,
  getCurrentLocationPlan, getCurrentOrganization,
} from 'store/company/selectors';
import {
  getHarvestDashboardFetching,
  getHarvestGraphFetching,
  getQualityGraphFetching,
  getBreakdownGraphFetching,
  getHarvestDashboardPoints,
  getHarvestDashboardActual,
  getHarvestDashboardGoal,
  getHarvestDashboardPrevYear,
  getHarvestDashboardMaxValue,

  getHarvestDashboardPointsByCategories,
  getHarvestByCategoriesEnabled,
  getHarvestDashboardTotalsByCategories,
  getHarvestCategoriesMaxValue,
  getHarvestCategoriesPercentMaxValue,

  getHarvestDashboardBreakdown,
  getDefaultBreakdownType,
} from 'store/dashboards/selectors';
import { getHarvestCategories } from 'store/harvest/selectors';

import HarvestDashboard from '../components/HarvestDashboard';

const mapDispatchToProps = {
  getHarvestDashboardData,
  getHarvestGraphData,
  getQualityGraphData,
  getBreakdownGraphData,

  getHarvestCategoriesDefault,
  clearHarvestDataByCategories,
};

const mapStateToProps = (state, props) => {
  const parsedQuery = queryString.parse(get(props, 'location.search'));
  const periodType = get(parsedQuery, 'periodType') || 'week';

  return {
    ...props,

    periodType,
    organization: getCurrentOrganization(state),
    locationPlan: getCurrentLocationPlan(state),
    currentUnits: getUnitsFromRoute(state, props),
    defaultSpecies: getFirstSpecies(state),
    isDataFetching: getHarvestDashboardFetching(state),
    isHarvestGraphFetching: getHarvestGraphFetching(state),
    isQualityGraphFetching: getQualityGraphFetching(state),
    isBreakdownGraphFetching: getBreakdownGraphFetching(state),
    points: getHarvestDashboardPoints(state),
    totalActual: getHarvestDashboardActual(state),
    totalGoal: getHarvestDashboardGoal(state),
    totalPrevYear: getHarvestDashboardPrevYear(state),
    maxValue: getHarvestDashboardMaxValue(state),
    varieties: getVarietiesInLocation(state, props),
    fruitClasses: getAllFruitClasses(state, props),
    plantingCycles: getCurrentPlantingCycles(state, props),
    compartments: getCurrentCompartments(state),

    pointsByCategories: getHarvestDashboardPointsByCategories(state),
    totalsByCategories: getHarvestDashboardTotalsByCategories(state),
    harvestByCategoriesEnabled: getHarvestByCategoriesEnabled(state),
    maxCategoriesValue: getHarvestCategoriesMaxValue(state),
    maxCategoriesPercentValue: getHarvestCategoriesPercentMaxValue(state),

    currentBreakdown: getBreakdownFromRoute(state, props) || getDefaultBreakdownType(state),
    breakdown: getHarvestDashboardBreakdown(state),
    harvestCategories: getHarvestCategories(state),
    currentSpecies: getSpeciesFromRoute(state, props) || getFirstSpecies(state),
    percentageForQuality: getPercentageForQualityFromRoute(state, props),
    cumulativeForQuality: getCumulativeForQualityFromRoute(state, props),
    anyDateOfPeriodStart: getAnyDateOfPeriodStartFromRoute(state, props),
    anyDateOfPeriodEnd: getAnyDateOfPeriodEndFromRoute(state, props),

    isOnlyLettuce: getIsOnlyLettuce(state, props),
    isOnlyVegetables: getIsOnlyVegetables(state, props),
  };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(HarvestDashboard)));
