import { createAction } from 'redux-actions';


export const requestBenchmarkGroups = createAction('REQUEST_BENCHMARK_GROUPS');
export const receiveBenchmarkGroups = createAction('RECEIVE_BENCHMARK_GROUPS');

export const requestBenchmarkOverview = createAction('REQUEST_BENCHMARK_OVERVIEW');
export const receiveBenchmarkOverview = createAction('RECEIVE_BENCHMARK_OVERVIEW');

export const requestBenchmarkComparison = createAction('REQUEST_BENCHMARK_COMPARISON');
export const receiveBenchmarkComparison = createAction('RECEIVE_BENCHMARK_COMPARISON');

export const requestBenchmarkComparisonMetrics = createAction('REQUEST_BENCHMARK_COMPARISON_METRICS');
export const receiveBenchmarkComparisonMetrics = createAction('RECEIVE_BENCHMARK_COMPARISON_METRICS');

export const requestUpdateBenchmarkComparisonMetrics = createAction('REQUEST_UPDATE_BENCHMARK_COMPARISON_METRICS');
export const receiveUpdateBenchmarkComparisonMetrics = createAction('RECEIVE_UPDATE_BENCHMARK_COMPARISON_METRICS');

export const requestDeleteBenchmarkComparisonMetrics = createAction('REQUEST_DELETE_BENCHMARK_COMPARISON_METRICS');
export const receiveDeleteBenchmarkComparisonMetrics = createAction('RECEIVE_DELETE_BENCHMARK_COMPARISON_METRICS');

export const requestBenchmarkMetrics = createAction('REQUEST_BENCHMARK_METRICS');
export const receiveBenchmarkMetrics = createAction('RECEIVE_BENCHMARK_METRICS');

export const addCycleToComparison = createAction('ADD_CYCLE_TO_COMPARISON');
export const replaceAllCyclesInComparison = createAction('REPLACE_ALL_CYCLES_IN_COMPARISON');
export const removeCycleFromComparison = createAction('REMOVE_CYCLE_FROM_COMPARISON');
export const removeAllCyclesFromComparison = createAction('REMOVE_ALL_CYCLES_FROM_COMPARISON');
export const updateCyclesComparison = createAction('UPDATE_CYCLES_COMPARISON');
