import React, { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import moment from 'moment-timezone';
import classnames from 'classnames';
import {
  get, isNumber, isNaN, isNil, find, isInteger
} from 'lodash';

import { API_DATE_FORMAT } from 'helpers/defaultDates';
import { getMlNameDirect } from 'helpers/getVarietyName';
import { getCompartmentNameById, getPlantingCycleLabel } from 'helpers/getPlantingCycleLabel';

import DefaultCircleLoader from 'components/DefaultCircleLoader';
import DefaultHotTable from 'components/DefaultHotTable';
import numbersFormatting from 'helpers/numbersFormatting';
import CellWithError from '../CellWithError';

import styles from './LaborWorksAddTable.module.css';

const ROW_HEADER_HEIGHT = 144;
const ROW_HEIGHT = 39;
const MAX_VISIBLE_COL = 3;

const getCurrentCellData = (workRow, laborRegistrations, rowDate) => {
  if (!workRow) {
    return undefined;
  }

  const laborWorks = laborRegistrations?.works ?? [];

  const currentCell = find(laborWorks, {
    plantingCycleId: workRow.plantingCycleId,
    workTypeId: workRow.workTypeId,
    workClass: workRow.workClass,
    startDate: rowDate,
  });

  return currentCell;
};

const numericValidator = (prevValue, formatMessage, query, callback) => {
  if (query === null || query === '') {
    return callback(true);
  }

  if (!isNumber(query) || isNaN(query)) {
    return callback(false, formatMessage({ id: 'formErrors.shouldBeANumber' }));
  }

  if (Number(query) > 1000000) {
    return callback(false, formatMessage({ id: 'harvest.errors.cannotBeExceedOneMillion' }));
  }

  if (Number(query) < 0) {
    return callback(false, formatMessage({ id: 'formErrors.cannotBeZero' }));
  }

  if (!isInteger(Number(query))) {
    return callback(false, formatMessage({ id: 'plantingCycles.errors.shouldBeWholeNumber' }));
  }

  /**
   * Если текущее значение счетчика “много меньше” предыдущего, то мы считаем, что это не ошибка, а счетчик просто обнулили
   * https://pylotapp.slack.com/archives/C80K84L8J/p1618228088003700?thread_ts=1617960877.001400&cid=C80K84L8J
   */
  const isMeterReset = Number(query) < 0.01 * Number(prevValue);

  // При типе meterReading в каждую ячейку должно вводиться значение не меньше предыдущего
  if ((prevValue !== null || prevValue !== '') && Number(query) < Number(prevValue) && !isMeterReset) {
    return callback(false, formatMessage({ id: 'energy.formErrors.meterReading' }));
  }

  return callback(true);
};

// renderToStaticMarkup т.к. handsontable работает только с html
export const renderCellWithError = (alignLeft, alignBottom, error, value) => ReactDOMServer
  .renderToStaticMarkup(<CellWithError
    value={value}
    error={error}
    errorClassName={styles.errorContent}
    alignLeft={alignLeft}
    alignBottom={alignBottom}
  />);

class LaborWorksAddTable extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    isFetching: PropTypes.bool,
    laborRegistrations: PropTypes.object,
    tableData: PropTypes.array,
    rowsGrid: PropTypes.array,
    firstColWidths: PropTypes.number.isRequired,
    colWidths: PropTypes.number.isRequired,

    tableRef: PropTypes.object.isRequired,
    handleTableChange: PropTypes.func.isRequired,
    getValueId: PropTypes.func.isRequired,

    allPlantingCycles: PropTypes.array.isRequired,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    organizationSlug: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isFetching: false,
    laborRegistrations: null,
    tableData: [],
    rowsGrid: [],
  };

  constructor(props) {
    super(props);

    this.laborTable = React.createRef();
  }

  calculateTableHeight = (data) => {
    const borders = 2;
    const scrollHeight = 15; // Иначе при появлении горизонтального скролла появится и вертикальный

    // TODO: Посмотреть на методы получения высоты хедеров и строк в handsontable
    const height = (data.length * ROW_HEIGHT) + ROW_HEADER_HEIGHT + borders + scrollHeight;

    return height;
  };

  getColWidths = (firstColWidths, colWidths, index) => {
    if (index === 0) {
      return firstColWidths;
    }

    return colWidths;
  };

  getWorkClassName = (formatMessage, index, workClassesLength, workClass) => {
    const isLast = (index + 1) % workClassesLength === 0;
    const classes = classnames(styles.workClassName, {
      [styles.lastWorkClassName]: isLast,
    });
    return this.renderDefaultHeaderCell(formatMessage({ id: `labor.stats.${workClass}` }), classes);
  };

  getWorkTypesName = (workTypes, workTypeId) => {
    const { intl: { formatMessage, locale } } = this.props;

    const currentWorkType = find(workTypes, { id: workTypeId });

    const name = getMlNameDirect(currentWorkType, locale);
    const workTypeUnits = formatMessage({ id: `cunits.mini.${currentWorkType?.unit}` });

    return this.renderDefaultHeaderCell(`${name}, ${workTypeUnits}`, styles.workTypeNameText);
  };

  getPlantingCycleName = (colWidths, workTypesLength, workClassesLength, plantingCycleId) => {
    const {
      intl,
      allPlantingCycles,
      varieties,
      fruitClasses,
      organizationSlug,
    } = this.props;

    const currentPlantingCycle = find(allPlantingCycles, { id: plantingCycleId });

    const text = getPlantingCycleLabel(currentPlantingCycle, varieties, fruitClasses, intl);

    // Чтобы ячейка не растягивалась слишком сильно
    // const linkStyles = workTypesLength < 4 ? { maxWidth: colWidths * workTypesLength * workClassesLength } : {};

    /**
     * renderToStaticMarkup т.к. handsontable работает только с html.
     * Не используем NavLink, т.к. он не умеет с renderToStaticMarkup.
     * Не обрабатывает клики почему-то внутри хедера handosntable, поэтому, чтобы сохранить данные,
     * открываем в новом табе
     */
    return ReactDOMServer
      .renderToStaticMarkup(
        <a
          href={plantingCycleId ? `/${organizationSlug}/crops/${plantingCycleId}` : `/${organizationSlug}/crops`}
          className={styles.plantingCycleLink}
          // style={linkStyles}
          target='_blank'
          rel='noopener noreferrer'
        >
          {text}
        </a>
      );
  };

  getCompartmentName = (id) => {
    const { compartments } = this.props;

    return getCompartmentNameById({ compartmentId: id, compartments });
  };

  getHeaderCells = (formatMessage, colWidths, laborRegistrations, rowsGrid) => {
    const compartments = get(laborRegistrations, 'descriptor.compartments', []);
    const workTypes = get(laborRegistrations, 'descriptor.workTypes', []);
    const workClasses = get(laborRegistrations, 'descriptor.workClasses', []);

    const workClassesLength = workClasses.length;

    const plantingCycles = compartments.reduce((acc, compartment) => {
      const plantingCyclesArr = compartment.plantingCycles.map(plantingCycle => plantingCycle);

      return [...acc, ...plantingCyclesArr];
    }, []);
    const workTypeIds = plantingCycles.reduce((acc, plantingCycle) => {
      const workTypesArr = plantingCycle ? plantingCycle.workTypeIds.map(workType => workType) : [];

      return [...acc, ...workTypesArr];
    }, []);

    const compartmentsCells = compartments.map((compartment) => {
      const allCompaetmentWorkTypeIdsLength = compartment.plantingCycles.reduce((acc, plantingCycle) => (acc + plantingCycle.workTypeIds.length), 0);

      return {
        label: this.getCompartmentName(compartment?.id), colspan: allCompaetmentWorkTypeIdsLength * workClassesLength,
      };
    });

    const plantingCyclesCells = plantingCycles.map(plantingCycle => ({
      label: this.getPlantingCycleName(colWidths, workTypes.length, workClasses.length, plantingCycle?.id), colspan: plantingCycle?.workTypeIds.length * workClassesLength,
    }));
    const workTypesCells = workTypeIds.map(workTypeId => ({
      label: this.getWorkTypesName(workTypes, workTypeId), colspan: workClassesLength,
    }));
    const workClassesCells = rowsGrid.map((work, index) => this.getWorkClassName(formatMessage, index, workClassesLength, work.workClass));

    return {
      compartmentsCells,
      plantingCyclesCells,
      workTypesCells,
      workClassesCells,
    };
  };

  getHeader = (formatMessage, colWidths, laborRegistrations, rowsGrid) => {
    const weeksHeaderCell = this.renderDefaultHeaderCell(formatMessage({ id: 'dashboards.weeks' }), styles.weeksHeader);
    const emptyHeaderCell = this.renderDefaultHeaderCell('', styles.weeksEmptyHeader);

    const {
      compartmentsCells,
      plantingCyclesCells,
      workTypesCells,
      workClassesCells,
    } = this.getHeaderCells(formatMessage, colWidths, laborRegistrations, rowsGrid);

    return [
      [weeksHeaderCell, ...compartmentsCells],
      [emptyHeaderCell, ...plantingCyclesCells],
      [emptyHeaderCell, ...workTypesCells],
      ['', ...workClassesCells],
    ];
  };

  rerenderColHeaders = (rowsGrid, col, formatMessage) => {
    if (col === 0) {
      return formatMessage({ id: 'dashboards.weeks' });
    }

    return this.renderMeterHeaderCell(rowsGrid[col - 1]);
  };

  rerenderColumns = (formatMessage, getValueId, rowsGrid, laborRegistrations) => ([
    {
      data: 'date',
      editor: false,
      readOnly: true,
      renderer(instance, td, row, col, prop, value) {
        /* eslint-disable no-param-reassign */
        if (value === 'total') {
          td.className = styles.totalFirstCell;
          td.innerHTML = formatMessage({ id: 'energy.total' });
        } else {
          const isDateDisabled = moment(value, API_DATE_FORMAT) > moment();
          td.className = isDateDisabled ? classnames(styles.dateCell, styles.dateCellDisabled) : styles.dateCell;
          const startOfWeek = moment(value, API_DATE_FORMAT).startOf('week').format('MMM D');
          const endOfWeek = moment(value, API_DATE_FORMAT).endOf('week').format('MMM D');
          const weekNumber = moment(value, API_DATE_FORMAT).isoWeek();
          const dateFormatted = `${formatMessage({ id: 'dashboards.week' })} ${weekNumber}, ${startOfWeek} – ${endOfWeek}`;
          td.innerHTML = dateFormatted;
        }
        /* eslint-enable no-param-reassign */

        return td;
      },
    },

    ...rowsGrid.reduce((acc, workRow) => ([
      ...acc,

      {
        data: [getValueId(workRow)],
        type: 'numeric',
        // renderer: 'html',
        renderer(instance, td, row, col, prop, value) {
          const currentRowArray = instance.getDataAtRow(row);

          const rowDate = currentRowArray[0];
          const currentCellData = getCurrentCellData(workRow, laborRegistrations, rowDate);
          const isDisabled = currentCellData?.disabled;

          let error;
          let isCellValid = true;

          const validationCallback = (isValid, errorMessage) => {
            error = errorMessage;

            return isValid;
          };

          if (!isNil(value) && value !== '') {
            // При типе meterReading в каждую ячейку должно вводиться значение не меньше предыдущего
            const prevValue = null; // getPrevMeterReadingValue(registrationsMeters, instance, row, col);

            isCellValid = numericValidator(prevValue, formatMessage, value, validationCallback);
          }

          /* eslint-disable no-param-reassign */
          if (isDisabled) {
            td.className = classnames(td.className, styles.disabledCell);
          }

          if (!isCellValid) {
            const colCount = currentRowArray.length;
            // выравниваем по левому крю для последних столбцов (если их много и есть скролл), чтобы не ехала таблица
            const alignLeft = (colCount > MAX_VISIBLE_COL) && ((colCount - 1) - col <= 1);

            const currentColArray = instance.getDataAtCol(col);
            const rowCount = currentColArray.length;
            // выравниваем по низу для последних 3 строк, чтобы не ехала таблица
            const alignBottom = ((rowCount - 1) - row) < 3;

            td.className = classnames('htInvalid', styles.invalidCell);
            td.innerHTML = renderCellWithError(alignLeft, alignBottom, error, value);
          } else {
            td.innerHTML = numbersFormatting(value);
          }
          /* eslint-enable no-param-reassign */
        },
        validator: function cellValidator(value, callback) {
          // Не вешаем просто 'table.numericValidator', чтобы передавать prevValue
          // При типе meterReading в каждую ячейку должно вводиться значение не меньше предыдущего
          const prevValue = null; // getPrevMeterReadingValue(registrationsMeters, this.instance, this.row, this.col);

          numericValidator(prevValue, formatMessage, value, callback);
        }
      }
    ]), []),
  ]);

  rerenderCells = (tableRef, rowsGrid, laborRegistrations, rowsCount, row, col) => {
    const cellProperties = {};

    if (col === 0) {
      return cellProperties;
    }

    const totalCellsRenderer = (instance, td) => {
      const colArray = instance.getDataAtCol(col);

      const colSum = colArray.reduce((acc, item) => {
        const numberItem = Number(item);
        if (isNumber(numberItem) && !isNaN(numberItem)) {
          return acc + numberItem;
        }

        return acc;
      }, 0);
      /* eslint-disable no-param-reassign */
      td.innerHTML = colSum === 0 ? '' : colSum; // numbersRounding(colSum, 'fixed', 0);
      td.classList.add(styles.totalCell);
      /* eslint-enable no-param-reassign */
    };

    // В последней строке выводим сумму
    if (row === (rowsCount - 1)) {
      cellProperties.renderer = totalCellsRenderer;
      cellProperties.editor = false;
      cellProperties.readOnly = true;
    }

    const currentRowArray = tableRef?.current?.hotInstance?.getDataAtRow(row) ?? [];
    const rowDate = currentRowArray[0];
    const workRow = rowsGrid[col - 1]; // т.к. первый столбец это дата
    const currentCellData = getCurrentCellData(workRow, laborRegistrations, rowDate);
    const isDisabled = currentCellData?.disabled;

    if (isDisabled) {
      cellProperties.editor = false;
      cellProperties.readOnly = true;
    }

    return cellProperties;
  };

  renderMeterHeaderCell = (row) => {
    const { workClass, } = row;

    const elementRender = () => (
      <div className={styles.meterHeader}>
        {workClass}
      </div>
    );

    // Т.к. handsontable работает только с html
    return ReactDOMServer.renderToStaticMarkup(elementRender());
  };

  renderDefaultHeaderCell = (text, className) => {
    const elementRender = () => (
      <div className={classnames(styles.defaultHeaderCell, className)}>
        {text}
      </div>
    );

    // Т.к. handsontable работает только с html
    return ReactDOMServer.renderToStaticMarkup(elementRender());
  };

  render() {
    const {
      intl: { formatMessage },

      rowsGrid,
      colWidths,
      firstColWidths,
      isFetching,
      laborRegistrations,
      tableData,
      tableRef,
      handleTableChange,
      getValueId,
    } = this.props;

    return (
      <div className={styles.tableContainer} ref={this.laborTable}>
        {isFetching && <DefaultCircleLoader />}

        <DefaultHotTable
          ref={tableRef}
          classNameWrapper={styles.tableWrapper}
          data={tableData}
          height={this.calculateTableHeight(tableData)}
          // colWidths={colWidths}
          colWidths={index => this.getColWidths(firstColWidths, colWidths, index)}
          // colHeaders={col => this.rerenderColHeaders(rowsGrid, col, formatMessage)}
          colHeaders
          columns={this.rerenderColumns(formatMessage, getValueId, rowsGrid, laborRegistrations)}
          cells={(row, col, prop) => this.rerenderCells(tableRef, rowsGrid, laborRegistrations, tableData.length, row, col, prop)}
          beforeChange={handleTableChange}
          nestedHeaders={this.getHeader(formatMessage, colWidths, laborRegistrations, rowsGrid)}
          afterValidate={this.handleAfterValidate}
          // renderAllRows // Выключаем виртуальный рендеринг, т.к. он тормозит // не спасло, на большом количестве всё-равно лагает при скролле
        />
      </div>
    );
  }
}

export default injectIntl(LaborWorksAddTable);
