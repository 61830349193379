import { connect } from 'react-redux';

import NotificationCenter from '../components/NotificationCenter';

import { showNotificationWithTimeout, hideNotification } from '../../../store/notificationCenter/actions';

const mapDispatchToProps = {
  showNotificationWithTimeout,
  hideNotification,
};

const mapStateToProps = (state) => {
  const { notificationCenter } = state;

  return {
    ...notificationCenter,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter);
