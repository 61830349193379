import React, { useCallback, useState } from 'react';
import { intlShape } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';

import moment from 'moment';
import classnames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import isToday from 'date-fns/isToday';

import { getProductTypeName } from 'helpers/getVarietyName';
import { getCompartmentNameByPlantingCycle } from 'helpers/getPlantingCycleLabel';
import { showIntercomLauncher, hideIntercomLauncher } from 'helpers/intercomHelpers';
import addGetParameters from 'helpers/addGetParameters';
import { formatDate, parseDate } from 'helpers/datesHelper';

import PeriodPicker from 'components/PeriodPicker';
import CalendarTurnIcon from 'components/Icons/CalendarTurnIcon';
import BigButton from 'components/BigButton';
import SaveChangesDialog from 'components/SaveChangesDialog';
import CropSwitch from 'components/CropSwitch';
import DropdownMenu from 'components/DropdownMenu';
import ArrowsClockwiseIcon from 'components/Icons/ArrowsClockwiseIcon';


import styles from './MeasurementsFormHeader.module.css';

const MeasurementsFormHeader = ({
  date,
  intl,
  organization,
  nextDate,
  previousDate,
  minDate,
  maxDate,
  handlerSave,
  handlerDontSave,
  plantingCycleId,
  isPreventDateChange,
  allPlantingCycles,
  varieties,
  fruitClasses,
  allCompartments,
  clearData,
  showNotificationWithTimeout,
}) => {
  const { formatMessage } = intl;
  const location = useLocation();
  const history = useHistory();

  const [isDateSwitchOn, setIsDateSwitchOn] = useState(true);
  const [saveChangesDialogState, setSaveChangesDialogState] = useState(null);
  const [isMobileCropSwitchOpen, setIsMobileCropSwitchOpen] = useState(false);

  const getDateFormat = useCallback((formattedDate) => {
    const dateFormatted = formattedDate.clone().format('MMM D');

    return `${dateFormatted}, ${formatMessage({ id: 'cycle.wk' })} ${formattedDate.isoWeek()}`;
  }, [formatMessage]);

  const dateChangeAction = useCallback((newDate) => {
    const formattedDate = newDate.format('YYYY-MM-DD');

    const newParams = {
      date: formattedDate,
    };

    const oldQuery = get(history, 'location.search');
    const parsedQuery = queryString.parse(oldQuery);
    const newQuery = { ...parsedQuery, ...newParams };
    const newSearch = queryString.stringify(newQuery);
    const searchString = newSearch ? `?${newSearch}` : '';

    history.push({
      search: searchString
    });
  }, [history]);

  const handlerDateChange = useCallback((newDate) => {
    // setActionAfter(() => dateChangeAction(newDate));

    if (isPreventDateChange) {
      return setSaveChangesDialogState({ newDate });
    }

   return dateChangeAction(newDate);
  }, [dateChangeAction, isPreventDateChange]);

  const handlerArrowAddClick = useCallback(() => {
    if (!nextDate) {
      return null;
    }

    return handlerDateChange(moment(nextDate));
  }, [handlerDateChange, nextDate]);

  const handlerArrowSubtractClick = useCallback(() => {
    if (!previousDate) {
      return null;
    }

    return handlerDateChange(moment(previousDate));
  }, [handlerDateChange, previousDate]);

  const getNewSearchString = useCallback((newCycleId, cycleDateOutOfRangeType) => {
    const oldQuery = location.search || '';

    if (cycleDateOutOfRangeType === 'outOfEnd') {
      const newPlantingCycleSource = allPlantingCycles.find(item => item.id === Number(newCycleId));
      const endDateOfNewCycle = newPlantingCycleSource?.attributes?.endDate ? parseDate(newPlantingCycleSource?.attributes?.endDate) : null;

      const newParameters = {
        date: formatDate(endDateOfNewCycle, 'yyyy-MM-dd'),
      };

      return addGetParameters(oldQuery, newParameters);
    }

    if (cycleDateOutOfRangeType === 'outOfStart') {
      const newParameters = {
        date: formatDate(new Date(), 'yyyy-MM-dd'),
      };

      return addGetParameters(oldQuery, newParameters);
    }

    return location.search;
  }, [allPlantingCycles, location]);

  const getIsCycleDateOutOfRange = useCallback((newCycleId) => {
    const newPlantingCycleSource = allPlantingCycles.find(item => item.id === Number(newCycleId));
    const currentDateFromMoment = date.clone().format('YYYY-MM-DD');
    const currentDate = parseDate(currentDateFromMoment);
    const isCurrentDateToday = isToday(currentDate);

    if (isCurrentDateToday) {
      return 'inRange';
    }

    const endDateOfNewCycle = newPlantingCycleSource?.attributes?.endDate ? parseDate(newPlantingCycleSource?.attributes?.endDate) : null;
    const startDateOfNewCycle = newPlantingCycleSource?.attributes?.startDate ? parseDate(newPlantingCycleSource?.attributes?.startDate) : null;
    const isDateOutOfRangeEnd = endDateOfNewCycle ? endDateOfNewCycle < currentDate : false;
    const isDateOutOfRangeStart = startDateOfNewCycle ? startDateOfNewCycle > currentDate : false;

    if (isDateOutOfRangeEnd) {
      return 'outOfEnd';
    }

    if (isDateOutOfRangeStart) {
      return 'outOfStart';
    }

    return 'inRange';
  }, [allPlantingCycles, date]);

  const cycleChangeAction = useCallback((newCycleId) => {
    clearData();

    const cycleDateOutOfRangeType = getIsCycleDateOutOfRange(newCycleId);

    const newSearchString = getNewSearchString(newCycleId, cycleDateOutOfRangeType);

    const newPathname = location.pathname.replace(plantingCycleId, newCycleId);

    history.push({
      pathname: newPathname,
      search: newSearchString,
    });

    if (cycleDateOutOfRangeType === 'outOfStart') {
      showNotificationWithTimeout({
        id: `notifications.dateChangedTodayNotification.${Date.now()}`,
        messageId: 'measurements.dateChangedTodayNotification',
        position: 'leftDown',
        iconType: 'info',
      });
    }

    if (cycleDateOutOfRangeType === 'outOfEnd') {
      showNotificationWithTimeout({
        id: `notifications.benchmarkLimitError.${Date.now()}`,
        messageId: 'measurements.dateChangedEndDateNotification',
        position: 'leftDown',
        iconType: 'info',
      });
    }
  }, [getNewSearchString, getIsCycleDateOutOfRange, showNotificationWithTimeout, plantingCycleId, clearData, history, location]);

  const customRedirect = useCallback((newCycleId) => {
    if (isPreventDateChange) {
      return setSaveChangesDialogState({ newCycleId });
    }

    return cycleChangeAction(newCycleId);
  }, [cycleChangeAction, isPreventDateChange]);

  const handlerSwitchCropMobileClick = useCallback(() => {
    setIsMobileCropSwitchOpen(true);
    hideIntercomLauncher();
  }, []);
  const handlerSwitchCropMobileClose = useCallback(() => {
    setIsMobileCropSwitchOpen(false);
    showIntercomLauncher();
  }, []);

  const plantingCycleSource = allPlantingCycles.find(item => item.id === Number(plantingCycleId));
  const varietyId = get(plantingCycleSource, 'relationships.variety.data.0.id');
  const fruitClassId = get(plantingCycleSource, 'relationships.fruitClass.data.0.id');
  const variety = varieties.find(item => item.id === varietyId);
  const fruitClass = fruitClasses.find(item => item.id === fruitClassId);
  const compartmentName = getCompartmentNameByPlantingCycle({
    plantingCycleId: Number(plantingCycleId),
    allPlantingCycles,
    compartments: allCompartments,
  });

  const productTypeName = getProductTypeName({
    intl,
    variety,
    fruitClass,
    targetWeight: plantingCycleSource?.attributes?.targetWeight,
    withSpecies: true,
  });

  const fullName = `${productTypeName}, ${compartmentName}`;

  return (
    <div className={classnames(styles.header)}>
      <div className={styles.headerPartColumn}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.headerTitle}>{formatMessage({ id: 'crops.measurementsEnter' })}</h1>
          {/* shows only on desktop */}
          <CropSwitch
            cycleId={Number(plantingCycleId)}
            className={styles.cropSwitch}
            customRedirect={customRedirect}
          />

          {/* shows only on mobile */}
          <DropdownMenu
            buttonClassName={styles.dropdownMenuButton}
            buttonSize='big'
          >
            <BigButton
              className={classnames(styles.dropdownItem, styles.arrowsClockwiseIcon)}
              onClick={handlerSwitchCropMobileClick}
              icon={<ArrowsClockwiseIcon />}
              theme='menuItem'
              title={formatMessage({ id: 'cycle.switchCropCycle' })}
            />
          </DropdownMenu>
          {isMobileCropSwitchOpen && (
            <CropSwitch
              cycleId={Number(plantingCycleId)}
              className={styles.cropSwitchMobile}
              onCloseMobileMenu={handlerSwitchCropMobileClose}
              customRedirect={customRedirect}
              isMobileVersion
            />
          )}
        </div>
        <h2 className={styles.headerDescription}>{fullName}</h2>
      </div>
      <div className={styles.headerPartRow}>
        <BigButton
          className={classnames(styles.dateSwitchingMode, { [styles.active]: isDateSwitchOn })}
          onClick={() => setIsDateSwitchOn(!isDateSwitchOn)}
          // title={formatMessage({ id: 'button.cancel' })}
          tooltip={formatMessage({ id: 'measurements.byDatesTooltip' })}
          icon={<CalendarTurnIcon />}
          theme='light'
        />
        <PeriodPicker
          date={date}
          periodType='day'
          hidePeriodSelect
          // dateFormat='MMM D'
          minDate={minDate}
          maxDate={maxDate}
          dateFormat={getDateFormat}
          organization={organization}
          onDateChange={handlerDateChange}
          capitalized={false}
          onArrowAddClick={nextDate && isDateSwitchOn ? handlerArrowAddClick : null}
          onArrowSubtractClick={previousDate && isDateSwitchOn ? handlerArrowSubtractClick : null}
          withoutPhoneVersion
        />
      </div>

      {saveChangesDialogState && (
        <SaveChangesDialog
          handlerCloseDialog={() => setSaveChangesDialogState(null)}
          handlerDontSave={() => {
            handlerDontSave();

            if (saveChangesDialogState?.newDate) {
              dateChangeAction(saveChangesDialogState?.newDate);
            } else if (saveChangesDialogState?.newCycleId) {
              cycleChangeAction(saveChangesDialogState?.newCycleId);
            }
          }}
          handlerSave={() => {
            handlerSave();

            if (saveChangesDialogState?.newDate) {
              dateChangeAction(saveChangesDialogState?.newDate);
            } else if (saveChangesDialogState?.newCycleId) {
              cycleChangeAction(saveChangesDialogState?.newCycleId);
            }
          }}
        />
      )}
    </div>
  );
};

MeasurementsFormHeader.propTypes = {
  intl: intlShape.isRequired,
  plantingCycleId: PropTypes.string,
  organization: PropTypes.object,
  date: PropTypes.object.isRequired,
  previousDate: PropTypes.string,
  nextDate: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  isPreventDateChange: PropTypes.bool,
  allCompartments: PropTypes.array,
  allPlantingCycles: PropTypes.array,
  varieties: PropTypes.array,
  fruitClasses: PropTypes.array,

  handlerSave: PropTypes.func.isRequired,
  handlerDontSave: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  showNotificationWithTimeout: PropTypes.func.isRequired,
};

MeasurementsFormHeader.defaultProps = {
  plantingCycleId: null,
  organization: null,
  previousDate: null,
  nextDate: null,
  minDate: null,
  maxDate: null,
  isPreventDateChange: false,
  allCompartments: null,
  allPlantingCycles: null,
  varieties: null,
  fruitClasses: null,
};

export default MeasurementsFormHeader;
