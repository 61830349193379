import PropTypes from 'prop-types';
import React, {
  useCallback
} from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import styles from './index.module.css';

const ScaleSelect = ({
  scales,
  selectedScale,
  onSelect,
  onNewScale,
  className,
  intl
}) => {
  const { formatMessage } = intl;
  const handleSelectBaseScale = useCallback(() => {
    if (onSelect) {
      onSelect({
        name: null,
        rightAxis: false,
        invisible: false,
        range: {
          start: null,
          end: null
        }
      });
    }
  }, [onSelect]);

  const handleSelectScale = useCallback((scale) => {
    if (onSelect) {
      onSelect(scale);
    }
  }, [onSelect]);

  const handleAddToNewScale = useCallback(() => {
    const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
    const names = scales.filter(scale => scale.name !== null).map(scale => scale.name).sort();
    const differenceChars = ALPHABET.filter(x => !names.includes(x)).sort();
    const name = differenceChars.length > 0 ? differenceChars[0] : 'A';
    if (onNewScale) {
      onNewScale({
        name,
        rightAxis: false,
        invisible: false,
        range: {
          start: null,
          end: null
        }
      });
    }
  }, [onNewScale, scales]);

  return (
    <div className={classnames(styles.scaleSelectWrapper, className)}>
      <div
        className={styles.scaleLine}
        role='button'
        onClick={handleAddToNewScale}
        tabIndex={0}
      >
        {formatMessage({ id: 'graphs.addToNewScale' })}
      </div>
      {scales.filter(scale => scale.name !== null).map((scale, index) => (
        <div
          key={`scale-key-${scale.name}`}
          className={classnames(
            styles.scaleLine,
            { [styles.selected]: selectedScale !== null && selectedScale.name === scale.name }
          )}
          onClick={() => { handleSelectScale(scale); }}
          role='button'
          tabIndex={index + 1}
        >{formatMessage({ id: 'graphs.addToScale' })} {scale.name}
        </div>
      ))}
      <div
        className={classnames(
          styles.scaleLine,
          styles.baseScale,
          { [styles.selected]: selectedScale === null }
        )}
        onClick={handleSelectBaseScale}
        role='button'
        tabIndex={scales.length + 1}
      >
        {formatMessage({ id: 'graphs.useBaseScale' })}
      </div>
    </div>
  );
};

ScaleSelect.propTypes = {
  intl: PropTypes.object,
  className: PropTypes.string,
  scales: PropTypes.array,
  selectedScale: PropTypes.object,
  onSelect: PropTypes.func,
  onNewScale: PropTypes.func
};

ScaleSelect.defaultProps = {
  intl: null,
  className: null,
  scales: [],
  selectedScale: null,
  onSelect: null,
  onNewScale: null
};

export default injectIntl(ScaleSelect);
