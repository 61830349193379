import { useEffect } from 'react';
import { line, select } from 'd3';
import { lineChunked } from 'd3-line-chunked';
import interpolateLine from 'components/LinesGraph/components/LineChart/interpolate';

function buildLineChunked({
  x,
  y,
  xValue,
  yValue,
  colors,
  lineStyles,
  lineIndex,
  interpolate,
  group,
  data,
  attrFromIndex = 0
}) {
  const lineChunkedDef = lineChunked()
    .x(d => x(xValue(d)))
    .y(d => y(yValue(d)))
    .curve(interpolateLine(interpolate))
    .defined(d => d.y != null)
    .lineStyles({
      stroke: colors[lineIndex + attrFromIndex],
      'stroke-dasharray': lineStyles[lineIndex + attrFromIndex] === 'dashed' ? '6,3' : '0,0',
    })
    .gapAttrs({
      'stroke-dasharray': lineStyles[lineIndex + attrFromIndex] === 'dashed' ? '6,3' : '0,0',
    })
    .pointAttrs({
      r: 0,
    });
  group
    .append('g')
    .datum(data)
    .call(lineChunkedDef);
}

function buildLine({
  x,
  y,
  xValue,
  yValue,
  colors,
  lineStyles,
  lineIndex,
  interpolate,
  group,
  data,
  attrFromIndex = 0
}) {
  const lineDef = line()
    .curve(interpolateLine(interpolate))
    .x(d => x(xValue(d)))
    .y(d => y(yValue(d)));

  group
    .append('g')
    .selectAll('path')
    .data([data])
    .enter()
    .append('path')
    .attr('class', 'line')
    .style('stroke', colors[lineIndex + attrFromIndex])
    .style('stroke-dasharray', lineStyles[lineIndex + attrFromIndex] === 'dashed' ? '6,3' : '0,0')
    .attr('d', lineDef);
}

function buildLinesGroup(
  groupRef,
  lineChartClassName,
  cropEmptyValues,
  x,
  y,
  xValue,
  yValue,
  colors,
  lineStyles,
  interpolate,
  data,
  attrFromIndex = 0
) {
  const group = groupRef.current ? select(groupRef.current) : null;
  if (group) {
    group.selectAll('*').remove();
    group.attr('class', lineChartClassName);
    for (let lineIndex = 0; lineIndex < data.length; lineIndex += 1) {
      const lineOption = {
        x,
        y,
        xValue,
        yValue,
        colors,
        lineStyles,
        lineIndex,
        interpolate,
        group,
        data: data[lineIndex],
        attrFromIndex
      };
      if (cropEmptyValues) {
        buildLineChunked(lineOption);
      } else {
        buildLine(lineOption);
      }
    }
  }
}


function useLinePath(groupRef, groupRightRef, options) {
  const {
    chartParams: {
      x, y, xValue, yValue, colors, rYValue, rY, rX
    },
    data,
    rightData,
    interpolate,
    lineChartClassName,
    lineStyles,
    width,
    cropEmptyValues
  } = options;
  useEffect(() => {
    // left
    buildLinesGroup(
      groupRef,
      lineChartClassName,
      cropEmptyValues,
      x,
      y,
      xValue,
      yValue,
      colors,
      lineStyles,
      interpolate,
      data
    );
    // right
    buildLinesGroup(
      groupRightRef,
      lineChartClassName,
      cropEmptyValues,
      rX,
      rY,
      xValue,
      rYValue,
      colors,
      lineStyles,
      interpolate,
      rightData,
      data?.length || 0
    );
  }, [
    groupRef,
    groupRightRef,
    x, y, xValue, yValue, colors, rYValue, rY, rX,
    data,
    rightData,
    interpolate,
    lineChartClassName,
    lineStyles,
    width,
    cropEmptyValues
  ]);
}

export default useLinePath;
