import moment from 'moment-timezone';


export default function getDateByPeriod({
  date: rawDate,
  periodType,
  formatMessage,
  mode,
}) {
  const date = rawDate.clone().startOf(periodType);
  const isCurrentYear = moment().year() === date.year();

  const array = [];

  if (periodType === 'day') {
    array.push(date.format('ddd'));
    array.push(date.format(`${formatMessage({ id: 'date.dayMonth' })}${isCurrentYear ? '' : ', YYYY'}`));
  }

  if (periodType === 'week') {
    const startWeek = date.format(`${formatMessage({ id: 'date.dayMonth' })}${isCurrentYear ? '' : ', YYYY'}`);
    const endWeek = date.clone().endOf(periodType).format(`${formatMessage({ id: 'date.dayMonth' })}${isCurrentYear ? '' : ', YYYY'}`);

    array.push(`${startWeek} — ${endWeek}`);
    array.push(`${formatMessage({ id: 'cycle.wk' })} ${date.isoWeek()}`);
  }

  if (periodType === 'month') {
    array.push(date.format(`MMM ${isCurrentYear && mode !== 'tooltip' ? '' : ' YYYY'}`));
  }


  return array.join(', ');
}
