import { createSelector } from 'reselect';
import { connect } from 'react-redux';

import CropsChart from './CropsChart';
import { getCurrentCycleFullInfo } from '../../store/newCrops/selectors';
import { setShowHarvest, trackClickShowHarvest } from '../../store/tables/actions';
import { getHarvestMetric } from '../../helpers/checkHarvestMetrics';

const leftWeeklyData = state => state.tables.leftWeeklyData;
const leftMetrics = state => state.tables.leftMetrics;
const selectedLeftMetric = state => state.tables.selectedMetricId;

const harvestMetricsWeeklyData = createSelector(
  [leftWeeklyData, leftMetrics],
  (weeklyData, leftMetricsData) => {
    const harvestMetric = getHarvestMetric(leftMetricsData);

    if (harvestMetric) {
      return weeklyData
        .filter(({ isFake }) => !isFake)
        .map(item => ({
          periodStart: item.periodStart,
          periodLength: item.periodLength,
          value: item.metricValues[harvestMetric.id],
        }));
    }
    return [];
  }
);

const selectedMetricsWeeklyData = createSelector(
  [leftWeeklyData, selectedLeftMetric],
  (weeklyData, metricId) => {
    if (weeklyData) {
      return weeklyData
        .filter(({ isFake }) => !isFake)
        .map(item => ({
          periodStart: item.periodStart,
          periodLength: item.periodLength,
          value: item.metricValues[metricId],
        }));
    }
    return [];
  }
);

const mapDispatchToProps = {
  setShowHarvest,
  trackClickShowHarvest,
};

const mapStateToProps = (state, props) => {
  const { intl, tables, company } = state;

  return {
    ...props,
    ...intl,
    ...company,
    ...tables,
    harvestWeeklyData: harvestMetricsWeeklyData(state),
    metricsWeeklyData: selectedMetricsWeeklyData(state),
    cycleInfo: getCurrentCycleFullInfo(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CropsChart);
