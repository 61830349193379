import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { FormattedMessage, intlShape } from 'react-intl';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import CloseIcon from 'components/Icons/Close';

import BigButton from 'components/BigButton';
import CustomTextInput from 'components/CustomTextInput';
import CustomDatePickerInput from 'components/CustomDatePickerInput';

import styles from './AbnormalityIncidentForm.module.css';

function validate(values, props) {
  const { intl } = props;
  const { formatMessage } = intl;

  const {
    startTime,
    startDate,
    endTime,
    endDate,
  } = values;

  const errors = {};

  if (!values.category) {
    errors.category = formatMessage({ id: 'graphIncidents.chooseIncidentType' });
  }

  if (!startTime) {
    errors.startTime = formatMessage({ id: 'graphIncidents.allFieldsShouldBeFilled' });
  }

  if (!endTime) {
    errors.endTime = formatMessage({ id: 'graphIncidents.allFieldsShouldBeFilled' });
  }

  if (startTime && endTime && startDate && endDate) {
    const [startHour, startMinutes] = startTime.split(':');
    const [endHour, endMinutes] = endTime.split(':');

    const start = startDate.hour(startHour).minute(startMinutes);
    const end = endDate.hour(endHour).minute(endMinutes);

    if (end.isSameOrBefore(start)) {
      errors.startDate = formatMessage({ id: 'graphIncidents.startDateIsAfterEndDate' });
      errors.endDate = formatMessage({ id: 'graphIncidents.startDateIsAfterEndDate' });
    }
  }

  return errors;
}

function warn() {
  return {};
}


class AbnormalityIncidentForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    formSyncErrors: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    isCreateAbnormalityIncidentFetching: PropTypes.bool.isRequired,
    isUpdateAbnormalityIncidentFetching: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    periodType: PropTypes.string.isRequired,
    formValues: PropTypes.object,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formValues: null,
    formSyncErrors: null,
  };

  handlerClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  handlerSubmit = (options) => {
    const { handleSubmit } = this.props;

    if (handleSubmit) {
      handleSubmit(options);
    }
  };

  render() {
    const {
      intl,
      formSyncErrors,
      isCreateAbnormalityIncidentFetching,
      isUpdateAbnormalityIncidentFetching,
    } = this.props;

    const { formatMessage } = intl;

    const props = {
      onSubmit: this.handlerSubmit,
    };

    const startTimeError = get(formSyncErrors, 'startTime');
    const endTimeError = get(formSyncErrors, 'endTime');

    const startDateError = get(formSyncErrors, 'startDate');

    const isDateRangeError = startDateError || startTimeError || endTimeError;

    const dateRangeError = startDateError || startTimeError || endTimeError;

    return (
      <form className={classnames(styles.form)} {...props} noValidate>
        <div className={styles.incidentPopupHeaderContainer}>
          <span className={styles.incidentPopupHeader}>
            {formatMessage({ id: 'graphIncidents.addIncidentHeader' })}
          </span>
          <div className={styles.incidentPopupControls}>
            <button
              type='button'
              className={styles.incidentBtn}
              onClick={this.handlerClose}
            >
              <CloseIcon />
            </button>
          </div>
        </div>

        <div className={styles.incidentPopupGroup}>
          <div className={styles.incidentPopupGroupHeader}>
            {formatMessage({ id: 'graphIncidents.incidentTime' })}
          </div>
          <div className={styles.incidentPopupGroupText}>
            <div className={styles.row}>
              <Field name='startDate' component={CustomDatePickerInput} theme='mini' clearable={false} isErrorTextDisable />
              <Field name='startTime' component={CustomTextInput} className={styles.timeInput} type='time' theme='mini' />
              <span className={styles.delimeter}>
                <FormattedMessage id='to' />
              </span>
              <Field name='endDate' component={CustomDatePickerInput} theme='mini' clearable={false} isErrorTextDisable />
              <Field name='endTime' component={CustomTextInput} className={styles.timeInput} type='time' theme='mini' />
            </div>
          </div>
          {isDateRangeError && (
            <div className={styles.incidentPopupGroupError}>
              {dateRangeError}
            </div>
          )}
        </div>

        <div className={styles.controls}>
          <BigButton
            className={styles.button}
            title={formatMessage({ id: 'dialog.cancel' })}
            type='button'
            onClick={this.handlerClose}
            theme='light'
          />
          <BigButton
            isLoading={isCreateAbnormalityIncidentFetching || isUpdateAbnormalityIncidentFetching}
            className={styles.button}
            title={formatMessage({ id: 'dialog.addInAdmin' })}
            type='submit'
            theme='dark'
          />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'abnormalityIncidentForm',
  validate,
  warn,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnBlur: false,
})(AbnormalityIncidentForm);
