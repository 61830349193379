import classnames from 'classnames';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import BigNumberInput from '../BigNumberInput';

import styles from './CustomNumberInput.module.css';

export default class CustomNumberInput extends Component {
  static propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    mode: PropTypes.string,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    className: PropTypes.string,
    buttonWrapperClassName: PropTypes.string,
    disabled: PropTypes.bool,
    decimalScale: PropTypes.number,
    decimalZeroMask: PropTypes.number,
    fixedDecimalScale: PropTypes.bool,
    forceDecimalScale: PropTypes.bool,
    forcedDecimalScale: PropTypes.number,
    allowNegative: PropTypes.bool,
    onInnerPaste: PropTypes.func,
    onInnerBlur: PropTypes.func,
    onInnerFocus: PropTypes.func,
    onInnerChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    isDebounceChange: PropTypes.bool,
  };

  static defaultProps = {
    decimalScale: undefined,
    decimalZeroMask: null,
    fixedDecimalScale: false,
    forceDecimalScale: false,
    forcedDecimalScale: undefined,
    allowNegative: true,
    disabled: false,
    input: null,
    meta: null,
    step: 0.01,
    min: null,
    max: null,
    placeholder: '',
    mode: null,
    className: null,
    buttonWrapperClassName: null,
    onInnerPaste: null,
    onInnerBlur: null,
    onInnerFocus: null,
    onInnerChange: null,
    autoFocus: false,
    isDebounceChange: false,
  };

  render() {
    const {
      input,
      meta,
      step,
      min,
      max,
      mode,
      placeholder,
      className,
      disabled,
      onInnerPaste: handlerPaste,
      onInnerBlur: handlerBlur,
      onInnerFocus: handlerFocus,
      onInnerChange: handlerChange,
      fixedDecimalScale,
      decimalZeroMask,
      decimalScale,
      allowNegative,
      forceDecimalScale,
      forcedDecimalScale,
      autoFocus,
      isDebounceChange,
      buttonWrapperClassName
    } = this.props;

    const {
      onChange,
      onBlur,
      value,
    } = input;

    const {
      error,
      warning,
      touched,
    } = meta;

    return (
      <BigNumberInput
        isDebounceChange={isDebounceChange}
        autoFocus={autoFocus}
        className={classnames(styles.button, className)}
        wrapperClassName={classnames(styles.buttonWrapper, buttonWrapperClassName)}
        error={touched && error ? error : null}
        warning={touched && warning ? warning : null}
        value={value}
        step={step}
        mode={mode}
        allowNegative={allowNegative}
        decimalZeroMask={decimalZeroMask}
        forceDecimalScale={forceDecimalScale}
        forcedDecimalScale={forcedDecimalScale}
        fixedDecimalScale={fixedDecimalScale}
        decimalScale={decimalScale}
        onPaste={(options) => {
          if (handlerPaste) {
            handlerPaste(options);
          }
        }}
        onChange={(options) => {
          onChange(options);

          if (handlerChange) {
            handlerChange(options);
          }
        }}
        onBlur={(options) => {
          if (options.toString().length > 0) {
            onBlur(options.toString());
          }

          if (handlerBlur) {
            handlerBlur(options);
          }
        }}
        onFocus={(options) => {
          if (handlerFocus) {
            handlerFocus(options);
          }
        }}
        placeholder={placeholder}
        min={min}
        max={max}
        disabled={disabled}
      />
    );
  }
}
