import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { useCallback, useMemo, } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import useQuery from 'hooks/useQuery';

import styles from './RelativeFilter.module.css';
import SelectBoxFilter from '../../../SelectBoxFilter';

const RelativeFilter = ({
  intl,
  onFiltersChange,
  isOnlyVegetables,
  isOnlyLettuce,
  title,
  capitalizeTitle
}) => {
  const { formatMessage } = intl;

  const [relative, setRelative] = useQuery('relative', 'true');

  const handlerRelativeChange = useCallback((event) => {
    const value = (event?.value === 'kilogramPerSquareMeter').toString();
    setRelative(value);
    onFiltersChange({
      relative: value,
    });
  }, [onFiltersChange, setRelative]);

  const currentUnits = relative === 'true' ? 'kilogramPerSquareMeter' : 'kilogram';

  const unitsList = useMemo(() => ['kilogramPerSquareMeter', 'kilogram'].reduce((acc, unit) => {
    const label = isOnlyVegetables ? formatMessage({ id: `cunits.mini.${unit}` }) :
      `${formatMessage({ id: `cunits.mini.${unit}` })}, ${formatMessage({ id: 'cunits.mini.count' })}`;
    return [...acc, { label, value: unit }];
  }, []), [isOnlyVegetables, formatMessage]);

  const unitsFilter = {
    name: 'units',
    value: currentUnits,
    options: unitsList,
    handler: handlerRelativeChange,
    classNameButton: styles.unitsFilter,
    classNamePopup: styles.unitsFilterPopup,
  };

  // maybe it's not used
  if (isOnlyLettuce) {
    return (
      <div className={classnames(styles.container)}>
        <div className={classnames(styles.title, {
          [styles.capitalizeTitle]: capitalizeTitle
        })}
        >
          {title || <FormattedMessage id='plans.title' />}
        </div>
      </div>
    );
  }

  return (
    <SelectBoxFilter
      disabled={unitsFilter.disabled}
      options={unitsFilter.options}
      value={unitsFilter.value}
      onChange={unitsFilter.handler}
      title={formatMessage({ id: 'dashboards.viewBy' })}
      classNameButton={classnames(unitsFilter.classNameButton, styles.selectButton)}
      classNamePopup={unitsFilter.classNamePopup}
      capitalizeTitle={capitalizeTitle}
    />
  );
};

RelativeFilter.propTypes = {
  intl: intlShape.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  isOnlyVegetables: PropTypes.bool,
  isOnlyLettuce: PropTypes.bool,
  title: PropTypes.string,
  capitalizeTitle: PropTypes.bool
};

RelativeFilter.defaultProps = {
  isOnlyVegetables: false,
  isOnlyLettuce: false,
  title: null,
  capitalizeTitle: false
};

export default RelativeFilter;
