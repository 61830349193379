import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getUserName,
  getUserEmail,
  getUserPhone,
  getUserFactor2,
} from '../../../store/company/selectors';

import { getIsUserInfoUpdated } from '../../../store/user/selectors';

import { requestUpdateUserInfo } from '../../../store/user/actions';

import UserInformationForm from '../components/UserInformationForm';

const mapDispatchToProps = {
  requestUpdateUserInfo,
};

const mapStateToProps = (state, props) => ({
  userName: getUserName(state),
  userEmail: getUserEmail(state),
  isUserInfoUpdated: getIsUserInfoUpdated(state),
  userFactor2: getUserFactor2(state, props),
  userPhone: getUserPhone(state, props),

  ...props,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserInformationForm));
