
import { Action, handleActions } from 'redux-actions';


import { cloneDeep } from 'lodash';

import {
  GraphPresetListItem,
  GraphsPresetRange,
  IdMixin,
  Incident,
  Metric,
  MetricAttr,
  MetricData, MetricDef, NodeComponents,
  Preset,
  TreeNode,
} from './types';

import {
  ICreatePresetResponse,
  IDeletePresetMetric,
  IDeletePresetMetricGroup,
  IGetGraphDataPointsResponse,
  IGetGraphPresetResponse,
  IGraphPresetsResponse,
  IMetricsResponse,
  ISetPresetMetricSelected,
  IUpdatePresetResponse,
  IChangePresetMetricGroup,
  ISetMetricToScale,
  IChangeScale,
  ISetMetricColor,
  REQUEST_GET_GRAPH_DATA_POINTS,
  REQUEST_GET_GRAPH_PRESET,
  REQUEST_GET_GRAPH_PRESETS,
  REQUEST_GET_METRICS,
  RESPONSE_CREATE_GRAPH_PRESET,
  RESPONSE_GET_GRAPH_DATA_POINTS,
  RESPONSE_GET_GRAPH_PRESET,
  RESPONSE_GET_GRAPH_PRESETS,
  RESPONSE_GET_METRICS,
  RESPONSE_UPDATE_GRAPH_PRESET,
  SET_PRESET_METRIC_SELECTED,
  DELETE_PRESET_METRIC,
  DELETE_PRESET_METRIC_GROUP,
  CHANGE_PRESET_METRIC_GROUP,
  SET_METRIC_TO_SCALE,
  CHANGE_SCALE,
  SET_METRIC_COLOR,
  DELETE_SCALE,
  IDeleteScale,
  CHANGE_GRAPH_PERIOD_RANGE,
  IChangeGraphPeriodRange,
  CHANGE_GRAPH_THRESHOLD,
  IChangeGraphPeriodThreshold,
  CHANGE_PRESET_NAME,
  IChangePresetName,
  CHANGE_PRESET_DESCRIPTION,
  IChangePresetDescription,
  TOGGLE_PRESET_METRIC,
  ITogglePresetMetric,
  IPushRangeHistory,
  PUSH_RANGE_HISTORY,
  POP_RANGE_HISTORY_SUCCESS,
  CLEAR_RANGE_HISTORY_SUCCESS,
  SET_ALL_PRESET_METRIC_SELECTED,
  ISetAllPresetMetricSelected,
  UPDATE_PRESET_DATA_FROM_URL,
  IUpdatePresetDataFromUrl,
  CLEAR_WORKSPACE,
  SET_SHOW_INCIDENTS,
  ISetShowIncidents,
  SET_ACTIVE_INCIDENT_ID,
  ISetActiveIncidentId,
  CHANGE_GRAPH_PERIOD_AND_THRESHOLD,
  IChangeGraphPeriodAndThreshold,
  SET_CREATE_INCIDENT_ID,
  ISetCreateIncidentId,
  CREATE_INCIDENT,
  CLOSE_INCIDENT_EDITOR,
  RESPONSE_METRICS_TREE,
  SET_METRICS_PANEL_FILTERS,
  ISetMetricsPanelFilters,
  ITreeFilters,
  RECEIVE_METRICS_PANEL_SEARCH_RESULT,
  IMetricSearchResult,
  SET_TREE_SEARCH_DATA,
  ITreeSearchData,
  SET_AVAILABLE_NODE_IDS,
  ISetAvailableNodeIds,
  ISetTreeExpandedKeys,
  SET_TREE_EXPANDED_KEYS,
  RESET_TREE_EXPANDED_KEYS,
  SET_GRAPH_METRIC_SEARCH,
  IRequestMetricsTree,
  REQUEST_METRICS_TREE,
  PATCH_METRICS_TREE
} from './actions';

import addMetricsToPreset from '../../components/Graphs/utils/addMetricsToPreset';
import findMetricsTreeNode from '../../helpers/graphs/findMetricsTreeNode';


export type RangeHistory = {
  range: GraphsPresetRange;
  threshold: number;
}

export type MetricFilterNode = {
  compartmentId: number | null;
  subNodeId: number | null;
  locationId: number | null;
};

export type MetricFilterOption = {
  nodes: Array<MetricFilterNode>;
  metric: MetricAttr & IdMixin;
}

export type GraphsState = {
  metrics: Array<Metric>;
  isMetricsFetching: boolean;

  presets: Array<GraphPresetListItem>;
  isPresetsFetching: boolean;

  originalPreset: Preset | null;
  isPresetFetching: boolean;
  editedPreset: Preset | null;

  dataPoints: {
    data: Array<MetricData>;
    incidents: Array<Incident>;
    metrics: Array<MetricAttr & IdMixin>;
  } | null;

  isDataPointsFetching: boolean;

  threshold: number;

  rangeHistory: Array<RangeHistory>;

  defaultGraphPresetMetrics: Array<any>;

  graphMetricFilter: string;
  graphMetricFilterOptions: Array<MetricFilterOption>;
  selectedGraphMetricFilterOption: MetricFilterOption;
  isGraphMetricFilterOptionsFetching: boolean;
  didGraphMetricFilterOptionsInvalidate: boolean;

  activeIncidentId: number | null;
  returnAbnormalityIncidents: boolean;

  createIncident: Incident | null;

  metricsTree: TreeNode | null;

  treeSearchFilter: {
    term: string;
    limit: number;
  } & ITreeFilters;

  treeSearchFilterResult: Array<number> | null;
  treeSearchData: ITreeSearchData | null;

  availableNodeIds: {
    plantingCycles: Array<number>;
    species: Array<number>;
    compartments: Array<number>;
    subNodes: Array<number>;
  },

  availableNodeIdsLoaded: boolean;

  expandedKeys: {
    g: Array<string | number>;
    c: Array<string | number>;
    e: Array<string | number>;
  },

  graphMetricSearch: string;

  requestMetricsTreeFilter: {
    metricId: Array<number> | null;
    compartmentId: Array<number> | null;
    categoryId: Array<number> | null;
    categoryCode: Array<string> | null;
    species: Array<string> | null;
    varietyId: Array<number> | null;
  },

  isMetricsPanelFilterFetching: boolean
};

const initialState = {
  metrics: [],
  isMetricsFetching: false,

  presets: [],
  isPresetsFetching: false,

  originalPreset: null,
  isPresetFetching: false,
  editedPreset: null,

  dataPoints: null,
  isDataPointsFetching: false,

  threshold: 0,

  rangeHistory: [],

  defaultGraphPresetMetrics: [],

  graphMetricFilter: '',
  selectedGraphMetricFilterOption: null,
  graphMetricFilterOptions: [],
  isGraphMetricFilterOptionsFetching: false,
  didGraphMetricFilterOptionsInvalidate: false,

  activeIncidentId: null,
  returnAbnormalityIncidents: false,

  createIncident: null,

  treeSearchFilter: null,

  treeSearchFilterResult: null,

  treeSearchData: null,

  availableNodeIds: {
    plantingCycles: [],
    species: [],
    compartments: [],
    subNodes: [],
  },

  availableNodeIdsLoaded: false,

  expandedKeys: {
    g: [],
    c: [],
    e: [],
  },

  graphMetricSearch: '',

  requestMetricsTreeFilter: {
    metricId: null,
    compartmentId: null,
    categoryId: null,
    categoryCode: null,
    species: null,
    varietyId: null,
  },

  isMetricsPanelFilterFetching: false,
};

function buildEmptyPreset(): Preset {
  const range: GraphsPresetRange = {
    xRange: 'last2Days',
    xRangeEnd: null,
    xRangeLengthInMins: null,
  };
  return {
    preset: {
      id: null,
      type: 'custom',
      ...range,
      description: '',
      name: '',
      scales: [{
        name: null,
        rightAxis: false,
        invisible: false,
        range: null
      }],
      presetMetrics: [],
    },
    compartments: [],
    metrics: [],
    locations: [],
    subNodes: []
  };
}

function patchTree(currentTree: TreeNode, patch: TreeNode): TreeNode {
  const nodePtr = findMetricsTreeNode(currentTree, patch.node);
  if (nodePtr && patch.children) {
    nodePtr.children = [...patch.children];
  }
  return { ...currentTree };
}

// TODO: Action types (ReturnType is not defined error), remove `any` (after update)
// @ts-ignore
export default handleActions<GraphsState, any>({
  [REQUEST_GET_METRICS](state) {
    return {
      ...state,
      isMetricsFetching: true,
    };
  },
  [RESPONSE_GET_METRICS](state, action: Action<IMetricsResponse>) {
    return {
      ...state,
      metrics: action.payload.metrics,
      isMetricsFetching: false,
    };
  },
  [REQUEST_GET_GRAPH_PRESETS](state) {
    return {
      ...state,
      isPresetsFetching: true,
    };
  },
  [RESPONSE_GET_GRAPH_PRESETS](state, action: Action<IGraphPresetsResponse>) {
    return {
      ...state,
      presets: action.payload.presets,
      isPresetsFetching: false,
    };
  },
  [REQUEST_GET_GRAPH_PRESET](state) {
    return {
      ...state,
      isPresetFetching: true,
    };
  },
  [PATCH_METRICS_TREE](state, action: Action<TreeNode>) {
    // TODO: Implementation patch tree
    if (!state.metricsTree) {
      return state;
    }
    return {
      ...state,
      metricsTree: patchTree(state.metricsTree, action.payload)
    };
  },
  // @ts-ignore
  [RESPONSE_GET_GRAPH_PRESET](state, action: Action<IGetGraphPresetResponse>) {
    const originalPreset = action.payload.originalPreset ?
      action.payload.originalPreset : action.payload.preset;
    return {
      ...state,
      originalPreset: { ...originalPreset },
      editedPreset: cloneDeep(action.payload.preset),
      dataPoints: [],
      rangeHistory: [],
      // threshold: 0,
    };
  },
  [RESPONSE_CREATE_GRAPH_PRESET](state, action: Action<ICreatePresetResponse>) {
    return {
      ...state,
      originalPreset: action.payload.preset,
      editedPreset: cloneDeep(action.payload.preset),
    };
  },
  [RESPONSE_UPDATE_GRAPH_PRESET](state, action: Action<IUpdatePresetResponse>) {
    return {
      ...state,
      originalPreset: action.payload.preset,
      editedPreset: cloneDeep(action.payload.preset),
    };
  },
  [REQUEST_GET_GRAPH_DATA_POINTS](state) {
    return {
      ...state,
      isDataPointsFetching: true,
    };
  },
  [RESPONSE_GET_GRAPH_DATA_POINTS](state, action: Action<IGetGraphDataPointsResponse>) {
    const {
      payload
    } = action;
    // @ts-ignore
    const editedPreset: Preset | null = state?.editedPreset ? {
      ...state?.editedPreset,
      preset: {
        ...state.editedPreset.preset,
        presetMetrics: state.editedPreset.preset.presetMetrics.map((metric:MetricDef) => {
          const metricData = payload.data.find((m:MetricData) => m.node.join('_') === metric.node.join('_'));
          return {
            ...metric,
            available: metricData && metricData.available
          };
        })
      }
    } : null;
    return {
      ...state,
      dataPoints: { ...payload },
      isDataPointsFetching: false,
      editedPreset
    };
  },
  [CHANGE_PRESET_NAME](state, action: Action<IChangePresetName>) {
    const {
      payload
    } = action;
    return state?.editedPreset ? {
      ...state,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          name: payload.name
        }
      }
    } : state;
  },
  [CHANGE_PRESET_DESCRIPTION](state, action: Action<IChangePresetDescription>) {
    const {
      payload
    } = action;
    return state?.editedPreset ? {
      ...state,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          description: payload.description
        }
      }
    } : state;
  },
  [SET_PRESET_METRIC_SELECTED](state, action: Action<ISetPresetMetricSelected>) {
    const {
      payload
    } = action;
    return state?.editedPreset ? {
      ...state,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          presetMetrics: state.editedPreset.preset.presetMetrics.map(metric =>
            (metric.node.join('_') === payload.metric.join('_') ? {
              ...metric,
              selected: payload.selected
            } : metric)),
        }
      }
    } : state;
  },
  [SET_ALL_PRESET_METRIC_SELECTED](state, action: Action<ISetAllPresetMetricSelected>) {
    const {
      payload
    } = action;
    return state?.editedPreset ? {
      ...state,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          presetMetrics: state.editedPreset.preset.presetMetrics.map(metric => ({
            ...metric,
            selected: payload.selected
          })),
        }
      }
    } : state;
  },
  [SET_METRIC_COLOR](state, action: Action<ISetMetricColor>) {
    const {
      payload
    } = action;
    return state?.editedPreset ? {
      ...state,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          presetMetrics: state.editedPreset.preset.presetMetrics.map(metric =>
            (metric.node.join('_') === payload.metric.join('_') ? {
              ...metric,
              color: payload.color
            } : metric)),
        }
      }
    } : state;
  },
  [UPDATE_PRESET_DATA_FROM_URL](state, action: Action<IUpdatePresetDataFromUrl>) {
    const {
      payload
    } = action;
    const preset = state?.editedPreset || buildEmptyPreset();
    preset.preset.presetMetrics = [...payload.presetMetrics];
    preset.preset.scales = [...payload.scales];
    const metricsIds = preset.preset.presetMetrics.map(item => item.node[NodeComponents.MetricId]);
    const metrics = state?.metrics;
    preset.metrics = metrics
      // @ts-ignore
      .filter(item => metricsIds.includes(item?.id))
      .map(item => ({
        id: item.id,
        ...item.attributes,
      }));
    return {
      ...state,
      originalPreset: preset,
      editedPreset: cloneDeep(preset),
    };
  },
  [DELETE_PRESET_METRIC](state, action: Action<IDeletePresetMetric>) {
    const {
      payload
    } = action;
    return state?.editedPreset ? {
      ...state,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          presetMetrics: state.editedPreset.preset.presetMetrics.filter(metric =>
            metric.node.join('_') !== payload.metric.join('_')),
        }
      }
    } : state;
  },
  [TOGGLE_PRESET_METRIC](state, action: Action<ITogglePresetMetric>) {
    const {
      payload,
    } = action;
    const metrics = state?.metrics;
    const metricId = payload.metric[NodeComponents.MetricId];
    const presetMetric = metrics.find(item => item.id === metricId);
    if (!state?.editedPreset) {
      const emptyPreset = buildEmptyPreset();
      addMetricsToPreset(emptyPreset, {
        presetMetric: {
          available: true,
          color: null,
          scale: null,
          selected: true,
          node: [...payload.metric],
        },
        scale: null
      }, metrics);
      return {
        ...state,
        originalPreset: emptyPreset,
        editedPreset: cloneDeep(emptyPreset),
      };
    }
    const presetMetrics = state?.editedPreset.preset.presetMetrics;
    const metricIndex = presetMetrics.findIndex(metric => metric.node.join('_') === payload.metric.join('_'));
    if (metricIndex === -1) {
      const newPresetMetrics = [...state.editedPreset.metrics];
      // @ts-ignore
      newPresetMetrics.push({
        // @ts-ignore
        id: presetMetric.id,
        ...presetMetric?.attributes
      });
      return {
        ...state,
        editedPreset: {
          ...state.editedPreset,
          preset: {
            ...state.editedPreset.preset,
            presetMetrics: [...state.editedPreset.preset.presetMetrics, {
              available: true,
              node: [...payload.metric],
              color: null, // find color this ?
              scale: null,
              selected: state.editedPreset.preset.presetMetrics.length <= 24, // TODO: To constants
            }],
          },
          metrics: newPresetMetrics
        }
      };
    }
    return {
      ...state,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          presetMetrics: state.editedPreset.preset.presetMetrics.filter(metric =>
            metric.node.join('_') !== payload.metric.join('_')),
        }
      }
    };
  },
  [DELETE_PRESET_METRIC_GROUP](state, action: Action<IDeletePresetMetricGroup>) {
    const {
      payload
    } = action;
    return state?.editedPreset ? {
      ...state,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          presetMetrics: state.editedPreset.preset.presetMetrics.filter(metric =>
            [
              metric.node[NodeComponents.TreeType],
              metric.node[NodeComponents.LocationId],
              metric.node[NodeComponents.Node],
              metric.node[NodeComponents.SubNode],
            ].join('_') !== payload.group.join('_')),
        }
      }
    } : state;
  },
  [CHANGE_PRESET_METRIC_GROUP](state, action: Action<IChangePresetMetricGroup>) {
    const {
      payload
    } = action;
    const editedMetrics = state?.editedPreset ? state.editedPreset.preset.presetMetrics.map(metric =>
      ([
        metric.node[NodeComponents.TreeType],
        metric.node[NodeComponents.LocationId],
        metric.node[NodeComponents.Node],
        metric.node[NodeComponents.SubNode],
      ].join('_') === payload.target.join('_') ? {
        ...metric,
        node: [...payload.values, metric.node[NodeComponents.MetricId]]
      } : metric)) : [];
    return state?.editedPreset ? {
      ...state,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          presetMetrics: editedMetrics,
        }
      }
    } : state;
  },
  [SET_METRIC_TO_SCALE](state, action: Action<ISetMetricToScale>) {
    if (!state?.editedPreset) {
      return state;
    }
    const {
      payload
    } = action;

    const newScaleName = payload.scale.name;

    const isExistScale = state.editedPreset.preset.scales
      .find(scale => scale.name === newScaleName);

    const presetMetrics = state.editedPreset.preset.presetMetrics.map(metric =>
      (metric.node.join('_') === payload.metric.join('_') ? {
        ...metric,
        scale: newScaleName
      } : metric));

    const metricScales = presetMetrics.map(metric => metric.scale);

    const newScales = state.editedPreset.preset.scales
      .filter(scale => metricScales.includes(scale.name));

    if (!isExistScale) {
      newScales.push({
        name: newScaleName,
        rightAxis: false,
        invisible: false,
        range: null
      });
    }

    return {
      ...state,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          presetMetrics,
          scales: newScales
        }
      }
    };
  },
  [CHANGE_SCALE](state, action: Action<IChangeScale>) {
    const {
      payload
    } = action;
    return state?.editedPreset ? {
      ...state,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          scales: state.editedPreset.preset.scales.map(scale =>
            (scale.name === payload.scale.name ? {
              ...scale,
              ...payload.scale
            } : scale)),
        }
      }
    } : state;
  },
  [DELETE_SCALE](state, action: Action<IDeleteScale>) {
    if (!state?.editedPreset) {
      return state;
    }
    const {
      payload
    } = action;
    const isExistCurrentScale = state.editedPreset.preset.scales
      .find(scale => scale.name === payload.scaleName);
    if (!isExistCurrentScale) {
      return state;
    }
    return {
      ...state,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          scales: state.editedPreset.preset.scales.filter(scale => scale.name !== payload.scaleName),
          presetMetrics: state.editedPreset.preset.presetMetrics.map(metric =>
            (metric.scale === payload.scaleName ? {
              ...metric,
              scale: null
            } : metric)),
        },
      }
    };
  },
  [CHANGE_GRAPH_PERIOD_RANGE](state, action: Action<IChangeGraphPeriodRange>) {
    const {
      payload
    } = action;
    return state?.editedPreset ? {
      ...state,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          ...payload.range,
        }
      }
    } : state;
  },
  [CHANGE_GRAPH_THRESHOLD](state, action: Action<IChangeGraphPeriodThreshold>) {
    const {
      payload
    } = action;
    return state?.editedPreset ? {
      ...state,
      threshold: payload.threshold
    } : state;
  },
  [CHANGE_GRAPH_PERIOD_AND_THRESHOLD](state, action: Action<IChangeGraphPeriodAndThreshold>) {
    const {
      payload
    } = action;
    return state?.editedPreset ? {
      ...state,
      threshold: payload.threshold,
      editedPreset: {
        ...state.editedPreset,
        preset: {
          ...state.editedPreset.preset,
          ...payload.range,
        }
      }
    } : state;
  },
  [PUSH_RANGE_HISTORY](state, action: Action<IPushRangeHistory>) {
    const rangeHistory = [...state.rangeHistory];
    rangeHistory.push(action.payload);
    return {
      ...state,
      rangeHistory,
    };
  },
  [POP_RANGE_HISTORY_SUCCESS](state) {
    const rangeHistory = [...state.rangeHistory];
    rangeHistory.pop();
    return {
      ...state,
      rangeHistory: [...rangeHistory],
    };
  },
  [CLEAR_RANGE_HISTORY_SUCCESS](state) {
    return {
      ...state,
      rangeHistory: [],
    };
  },
  //  @ts-ignore
  [CLEAR_WORKSPACE](state) {
    return {
      ...state,
      originalPreset: null,
      isPresetFetching: false,
      editedPreset: null,
      dataPoints: null,
      isDataPointsFetching: false,
      threshold: 0,
      rangeHistory: [],
      defaultGraphPresetMetrics: [],
      graphMetricFilter: '',
      selectedGraphMetricFilterOption: null,
      graphMetricFilterOptions: [],
      isGraphMetricFilterOptionsFetching: false,
      didGraphMetricFilterOptionsInvalidate: false,
    };
  },
  [SET_SHOW_INCIDENTS](state, action: Action<ISetShowIncidents>) {
    return {
      ...state,
      returnAbnormalityIncidents: action.payload.returnAbnormalityIncidents
    };
  },
  // @ts-ignore
  [SET_ACTIVE_INCIDENT_ID](state, action: Action<ISetActiveIncidentId>) {
    return {
      ...state,
      returnAbnormalityIncidents: true,
      activeIncidentId: action.payload.activeIncidentId,
    };
  },
  [SET_CREATE_INCIDENT_ID](state, action: Action<ISetCreateIncidentId>) {
    if (!state.createIncident) {
      return state;
    }
    return {
      ...state,
      createIncident: {
        ...state.createIncident,
        id: action.payload.id,
        status: 'check'
      },
    };
  },
  [CLOSE_INCIDENT_EDITOR](state) {
    return {
      ...state,
      createIncident: null,
    };
  },
  [CREATE_INCIDENT](state, action: Action<Incident>) {
    return {
      ...state,
      createIncident: {
        ...action.payload,
        status: 'create'
      },
    };
  },
  [RESPONSE_METRICS_TREE](state, action: Action<TreeNode>) {
    return {
      ...state,
      metricsTree: {
        ...action.payload,
      },
    };
  },

  [SET_METRICS_PANEL_FILTERS](state, action: Action<ISetMetricsPanelFilters>) {
    const term = action.payload.term || '';
    const isMetricsPanelFilterFetching = (Number.isInteger(Number.parseInt(term, 10)) ||
      term.trim().length > 2);
    return {
      ...state,
      isMetricsPanelFilterFetching,
      treeSearchFilter: {
        ...state.treeSearchFilter,
        ...action.payload
      }
    };
  },

  [RECEIVE_METRICS_PANEL_SEARCH_RESULT](state, action: Action<IMetricSearchResult>) {
    return {
      ...state,
      treeSearchFilterResult: action.payload?.metrics,
      isMetricsPanelFilterFetching: false
    };
  },

  [SET_TREE_SEARCH_DATA](state, action: Action<ITreeSearchData>) {
    return {
      ...state,
      treeSearchData: action.payload
    };
  },

  [SET_AVAILABLE_NODE_IDS](state, action: Action<ISetAvailableNodeIds>) {
    return {
      ...state,
      availableNodeIds: {
        ...action.payload
      },
      availableNodeIdsLoaded: true
    };
  },

  [SET_TREE_EXPANDED_KEYS](state, action: Action<ISetTreeExpandedKeys>) {
    return {
      ...state,
      expandedKeys: {
        ...state.expandedKeys,
        [action.payload.treeId]: [...action.payload.keys]
      }
    };
  },

  [RESET_TREE_EXPANDED_KEYS](state) {
    return {
      ...state,
      expandedKeys: {
        g: [],
        c: [],
        e: []
      }
    };
  },

  [SET_GRAPH_METRIC_SEARCH](state, action: Action<string>) {
    return {
      ...state,
      graphMetricSearch: action.payload
    };
  },

  [REQUEST_METRICS_TREE](state, action: Action<IRequestMetricsTree>) {
    return {
      ...state,
      requestMetricsTreeFilter: {
        metricId: action.payload.metricId,
        compartmentId: action.payload.compartmentId,
        categoryId: action.payload.categoryId,
        categoryCode: action.payload.categoryCode,
        species: action.payload.species,
        varietyId: action.payload.varietyId,
      }
    };
  }
}, initialState);
