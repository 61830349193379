import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { get, find, isNil } from 'lodash';
import moment from 'moment-timezone';

import LinesGraph from 'components/LinesGraph';
import LinesGraphTooltip from 'components/LinesGraphTooltip';

import numbersFormatting from 'helpers/numbersFormatting';
import { getPercentDiff } from 'helpers/getPercent';
import { getUnits, findGetAndRoundingValue } from 'helpers/getValueDetails';
import LINE_COLORS from 'helpers/graphColors';

import styles from './HarvestGraph.module.css';

class HarvestGraph extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    isDataFetching: PropTypes.bool,
    points: PropTypes.array,
    maxValue: PropTypes.number.isRequired,
    periodType: PropTypes.string,
    roundTo: PropTypes.number
  };

  static defaultProps = {
    isDataFetching: false,
    points: [],
    periodType: null,
    roundTo: 1,
  };

  generateLineByPoints = (dataPoints) => {
    if (dataPoints) {
      return Object.keys(dataPoints).reduce((acc, key) => {
        const date = moment.utc(new Date((+key) * 60000));

        return [...acc, { x: date.format('MM-DD-YYYY'), y: dataPoints[key] }];
      }, []);
    }

    return [];
  };

  generateLines = (points) => {
    const { intl: { formatMessage } } = this.props;

    const goalPoints = find(points, { key: 'goal' });
    const actualPoints = find(points, { key: 'actual' });
    const prevYearPoints = find(points, { key: 'prevYear' });

    return [
      {
        key: 'actualLine',
        points: this.generateLineByPoints(get(actualPoints, 'dataPoints')),
        name: formatMessage({ id: 'dashboards.harvested' }),
        color: LINE_COLORS[0],
        units: getUnits(actualPoints, formatMessage, 'unit'),
      },
      {
        key: 'goalLine',
        points: this.generateLineByPoints(get(goalPoints, 'dataPoints')),
        name: formatMessage({ id: 'dashboards.plan' }),
        color: LINE_COLORS[1],
        units: getUnits(goalPoints, formatMessage, 'unit'),
      },
      {
        key: 'prevYearLine',
        points: this.generateLineByPoints(get(prevYearPoints, 'dataPoints')),
        name: formatMessage({ id: 'dashboards.previousYear' }),
        color: LINE_COLORS[2],
        units: getUnits(prevYearPoints, formatMessage, 'unit'),
      },
    ];
  };

  renderValueWithPercent = (actual, previous, units) => {
    const { intl: { formatMessage } } = this.props;

    if (isNil(previous)) {
      return formatMessage({ id: 'crops.noData' });
    }

    return (
      <span>
        <span>{`${numbersFormatting(previous)} ${units}`}</span>
        <span className={styles.tooltipPercent}>{` (${getPercentDiff(previous, actual)})`}</span>
      </span>
    );
  };

  renderTooltipContent = (tooltipDate) => {
    const {
      intl: { formatMessage }, points, periodType, roundTo
    } = this.props;

    const lines = this.generateLines(points);

    const getPoints = (linesArray, lineKey) => get(find(linesArray, { key: lineKey }), 'points');
    const getName = (linesArray, lineKey) => get(find(linesArray, { key: lineKey }), 'name');
    const getColor = (linesArray, lineKey) => get(find(linesArray, { key: lineKey }), 'color');
    const getLineUnits = (linesArray, lineKey) => get(find(linesArray, { key: lineKey }), 'units');

    const actualLine = getPoints(lines, 'actualLine');
    const goalLine = getPoints(lines, 'goalLine');
    const prevYearLine = getPoints(lines, 'prevYearLine');

    const currentDate = tooltipDate ? tooltipDate.format('MM-DD-YYYY') : undefined;

    if (!currentDate) {
      return null;
    }

    const actualLineValue = findGetAndRoundingValue(actualLine, currentDate, roundTo);
    const prevYearLineValue = findGetAndRoundingValue(prevYearLine, currentDate, roundTo);
    const goalLineValue = findGetAndRoundingValue(goalLine, currentDate, roundTo);

    const actualLineWithPercent = isNil(actualLineValue) ? formatMessage({ id: 'crops.noData' }) : `${numbersFormatting(actualLineValue)} ${getLineUnits(lines, 'actualLine')}`;
    const prevWithPercent = this.renderValueWithPercent(actualLineValue, prevYearLineValue, getLineUnits(lines, 'goalLine'));
    const goalWithPercent = this.renderValueWithPercent(actualLineValue, goalLineValue, getLineUnits(lines, 'prevYearLine'));

    const generatedLines = [
      {
        id: 'actualLine',
        value: actualLineWithPercent,
        header: getName(lines, 'actualLine'),
        color: getColor(lines, 'actualLine'),
      },
      {
        id: 'goalLine',
        value: goalWithPercent,
        header: getName(lines, 'goalLine'),
        color: getColor(lines, 'goalLine'),
      },
      {
        id: 'prevYearLine',
        value: prevWithPercent,
        header: getName(lines, 'prevYearLine'),
        color: getColor(lines, 'prevYearLine'),
      }
    ];

    return (
      <LinesGraphTooltip
        lines={generatedLines}
        tooltipDate={tooltipDate}
        periodType={periodType}
      />
    );
  };

  render() {
    const {
      isDataFetching, points, maxValue,
    } = this.props;

    const lines = this.generateLines(points);

    return (
      <LinesGraph
        isDataFetching={isDataFetching}
        lines={lines}
        maxValue={maxValue}
        renderTooltipContent={this.renderTooltipContent}
      />
    );
  }
}

export default injectIntl(HarvestGraph);
