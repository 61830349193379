import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';

import { isEmpty } from 'lodash';

import BigButton from '../../../BigButton';
import DefaultTextInput from '../../../DefaultTextInput';

import styles from './UserPasswordForm.module.css';

const validate = (values, props) => {
  const { intl: { formatMessage } } = props;
  const errors = {};

  if (values.newPassword && values.newPassword.length < 8) {
    errors.newPassword = formatMessage({ id: 'password.passwordError' });
  }

  if (values.confirmNewPassword && values.newPassword !== values.confirmNewPassword) {
    errors.confirmNewPassword = formatMessage({ id: 'settings.passwordNotMatchError' });
  }

  if (!values.currentPassword) {
    errors.currentPassword = formatMessage({ id: 'settings.currentPasswordError' });
  }

  if (!values.newPassword) {
    errors.newPassword = formatMessage({ id: 'settings.newPasswordError' });
  }

  if (!values.confirmNewPassword) {
    errors.confirmNewPassword = formatMessage({ id: 'settings.confirmNewPasswordError' });
  }

  return errors;
};

class UserPasswordForm extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    isPasswordUpdateFetching: PropTypes.bool,
    formValues: PropTypes.object,
    formSyncErrors: PropTypes.object,

    userFormOnSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isPasswordUpdateFetching: false,
    formValues: null,
    formSyncErrors: null,
  };

  state = {
    formActivated: false,
  };

  handlerActivateForm = () => this.setState({ formActivated: true });

  handlerCancel = () => {
    const { reset } = this.props;

    reset();

    this.setState({
      formActivated: false,
    });
  };

  handlerSubmitForm = () => {
    const {
      intl: { formatMessage }, userFormOnSubmit, formValues, formSyncErrors
    } = this.props;

    if (!formValues || !isEmpty(formSyncErrors)) {
      return null;
    }

    const { currentPassword, confirmNewPassword } = formValues;


    return userFormOnSubmit({
      oldPassword: currentPassword,
      newPassword: confirmNewPassword,
      currentPasswordError: formatMessage({ id: 'settings.incorrectPasswordError' }),
      actionAfterSuccess: this.handlerCancel,
    });
  };

  renderCurrentPassword = ({
    input,
    meta: { touched, error, active },
  }) => {
    const { intl: { formatMessage } } = this.props;

    return (
      <DefaultTextInput
        {...input}
        className={styles.passwordInput}
        placeholder={formatMessage({ id: 'settings.currentPassword' })}
        type='password'
        error={touched && !active && error ? error : null}
      />
    );
  };

  renderNewPassword = ({
    input,
    meta: { touched, error, active },
  }) => {
    const { intl: { formatMessage } } = this.props;

    return (
      <DefaultTextInput
        {...input}
        className={styles.passwordInput}
        placeholder={formatMessage({ id: 'settings.newPassword' })}
        type='password'
        error={touched && !active && error ? error : null}
      />
    );
  };

  renderConfirmNewPassword = ({
    input,
    meta: { touched, error, active },
  }) => {
    const { intl: { formatMessage } } = this.props;

    return (
      <DefaultTextInput
        {...input}
        className={styles.passwordInput}
        placeholder={formatMessage({ id: 'settings.confirmNewPassword' })}
        type='password'
        error={touched && !active && error ? error : null}
      />
    );
  };

  render() {
    const {
      intl: { formatMessage }, isPasswordUpdateFetching, formValues, handleSubmit
    } = this.props;
    const { formActivated } = this.state;

    if (!formActivated) {
      return (
        <BigButton
          title={formatMessage({ id: 'settings.changePassword' })}
          type='button'
          onClick={this.handlerActivateForm}
          theme='light'
        />
      );
    }

    return (
      <form
        className={styles.passwordForm}
        onSubmit={handleSubmit(this.handlerSubmitForm)}
      >
        <Field
          name='currentPassword'
          component={this.renderCurrentPassword}
        />
        <Field
          name='newPassword'
          component={this.renderNewPassword}
        />
        <Field
          name='confirmNewPassword'
          component={this.renderConfirmNewPassword}
        />
        <div className={styles.buttons}>
          <BigButton
            title={formatMessage({ id: 'button.saveChanges' })}
            type='submit'
            theme='dark'
            disabled={!formValues}
            isLoading={isPasswordUpdateFetching}
          />
          <BigButton
            className={styles.cancelButton}
            title={formatMessage({ id: 'button.cancel' })}
            type='button'
            onClick={this.handlerCancel}
            theme='light'
            disabled={isPasswordUpdateFetching}
          />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'userPasswordForm',
  validate,
})(UserPasswordForm);
