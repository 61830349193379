import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classnames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { DndProvider } from 'react-dnd';
import update from 'immutability-helper';
import Backend from 'react-dnd-html5-backend';


import DragablePanel from 'components/DragablePanel';

import storageWrapper from '../../../../helpers/storageWrapper';
import { getUnits } from '../../../../helpers/getValueDetails';
import { getNameByLocal } from '../../../../helpers/getNameByLocal';

import DefaultDialog from '../../../DefaultDialog';
import BigButton from '../../../BigButton';
import Checkbox from '../../../Checkbox';
import Typography from '../../../Typography';

import styles from './BenchmarkMetricsChangeDialog.module.css';

import { ReactComponent as SearchIcon } from '../../../DefaultSearchList/assets/search.svg';
import { ReactComponent as CloseIcon } from '../../../DefaultSearchList/assets/close.svg';

const safeLocalStorage = storageWrapper.get('localStorage');


class BenchmarkMetricsChangeDialog extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    isFetching: PropTypes.bool,
    metrics: PropTypes.object,
    comparison: PropTypes.object,

    handlerCancel: PropTypes.func.isRequired,
    handlerOk: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isFetching: false,
    metrics: null,
    comparison: null,
  };

  state = {
    search: '',
    selectedMetrics: get(this.props.comparison, 'graphMetrics', []).map(x => +get(x, 'id')),
  };

  getAllMetrics = () => {
    const {
      metrics,
    } = this.props;


    return metrics ? Object.keys(metrics)
      .map(key => metrics[key])
      .reduce((reducer, item) => {
        reducer.push(...item);

        return reducer;
      }, []) : [];
  };

  handlerChanged = (e) => {
    this.setState({ search: get(e, 'target.value', '') });
  };

  handlerCheckedChange = ({ graphMetric }) => {
    const {
      selectedMetrics,
    } = this.state;

    const id = get(graphMetric, 'id');
    const index = selectedMetrics.findIndex(x => +x === id);

    if (index > -1) {
      this.setState({ selectedMetrics: selectedMetrics.filter(x => x !== id) });
    } else {
      this.setState({ selectedMetrics: [...selectedMetrics, id] });
    }
  };

  handlerCancel = () => {
    const {
      handlerCancel,
    } = this.props;

    handlerCancel();
  };

  handlerOk = () => {
    const {
      handlerOk,
    } = this.props;

    const {
      selectedMetrics,
    } = this.state;

    handlerOk({ graphMetrics: selectedMetrics });
  };

  handlerCardMove = (dragIndex, hoverIndex) => {
    const {
      selectedMetrics,
    } = this.state;

    const draggedMetric = selectedMetrics[dragIndex];

    this.setState({
      selectedMetrics: update(selectedMetrics, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, draggedMetric],
        ],
      }),
    });
  };

  render() {
    const {
      intl: { formatMessage },
      isFetching,
      metrics,
    } = this.props;

    const {
      selectedMetrics,
      search,
    } = this.state;

    const locale = safeLocalStorage.getItem('locale');

    const allMetrics = this.getAllMetrics();

    return (
      <DefaultDialog
        title={formatMessage({ id: 'benchmarking.changeMetrics' })}
        onClose={this.handlerCancel}
        wrapperClassName={styles.layout}
        className={styles.dialog}
      >
        <div>
          <div className={classnames(styles.row, styles.content)}>
            <div className={styles.parameters}>
              <div>

                <div className={classnames(styles.control)}>
                  <button
                    tabIndex={0}
                    type='button'
                    className={classnames(styles.button, styles.left)}
                    onClick={() => { if (this.inputRef) { this.inputRef.current.focus(); } }}
                  >
                    <SearchIcon />
                  </button>
                  <input
                    type='text'
                    value={search}
                    ref={this.inputRef}
                    placeholder={formatMessage({ id: 'benchmarking.searchMetric' })}
                    className={styles.input}
                    onChange={this.handlerChanged}
                  />
                  {search && (
                    <button
                      tabIndex={0}
                      type='button'
                      className={classnames(styles.button, styles.right)}
                      onClick={() => {
                        setTimeout(() => {
                          this.handlerChanged(null);

                          if (this.inputRef) { this.inputRef.current.focus(); }
                        }, 0);
                      }}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </div>
              </div>
              <div className={styles.groups}>
                {metrics ? Object.keys(metrics)
                  .filter(key => key !== 'userMetrics')
                  .map((key) => {
                    const graphMetrics = metrics[key];

                    const filteredGraphMetrics = graphMetrics && graphMetrics.length ? graphMetrics
                      .filter((graphMetric) => {
                        const name = getNameByLocal(graphMetric, locale);

                        if (!search) {
                          return true;
                        }

                        const processedWords = search ? search.split(' ') : [];

                        return name
                          .split(' ')
                          .some((titleChunk) => {
                            const processedChunk = titleChunk;

                            return processedWords.some((processedWord) => {
                              const boldPosition = titleChunk.toLowerCase().indexOf(processedWord.toLowerCase());

                              return boldPosition >= 0 && processedChunk.toLowerCase().localeCompare(titleChunk.toLowerCase(), undefined, {
                                numeric: true,
                                sensitivity: 'base'
                              }) === 0;
                            });
                          });
                      }) : [];

                    if (!filteredGraphMetrics || !filteredGraphMetrics.length) {
                      return null;
                    }

                    return (
                      <div className={styles.group} key={`group-${key}`}>
                        <div className={styles.groupHeader}>
                          <Typography variant='subtitle2' bold color='secondary'>
                            <FormattedMessage id={`benchmarking.${key}`} />
                          </Typography>
                        </div>
                        <div>
                          {filteredGraphMetrics.map((graphMetric) => {
                            const processedWords = search ? search.split(' ') : [];
                            const name = getNameByLocal(graphMetric, locale);

                            const processedTitle = name
                              .split(' ')
                              .map((titleChunk) => {
                                let processedChunk = titleChunk;

                                processedWords.forEach((processedWord) => {
                                  const boldPosition = titleChunk.toLowerCase().indexOf(processedWord.toLowerCase());

                                  if (boldPosition >= 0 && processedChunk.toLowerCase().localeCompare(titleChunk.toLowerCase(), undefined, {
                                    numeric: true,
                                    sensitivity: 'base'
                                  }) === 0) {
                                    processedChunk = [
                                      titleChunk.slice(0, boldPosition),
                                      '<strong>',
                                      titleChunk.slice(boldPosition, boldPosition + processedWord.length),
                                      '</strong>',
                                      titleChunk.slice(boldPosition + processedWord.length),
                                    ].join('');
                                  }
                                });
                                return processedChunk;
                              }).join(' ');

                            return (
                              <div
                                key={`metric-${get(graphMetric, 'id')}`}
                                className={classnames(styles.row, styles.alignCenter, styles.spaceBetween, styles.graphMetric)}
                              >
                                <Checkbox
                                  checked={!!selectedMetrics.find(x => +x === get(graphMetric, 'id'))}
                                  onChange={() => this.handlerCheckedChange({ graphMetric })}
                                  value={get(graphMetric, 'id')}
                                  title={(processedTitle)}
                                  alignCenter
                                  noWrap
                                />
                                <div>
                                  <Typography variant='body1' color='secondary'>
                                    {getUnits(graphMetric, formatMessage, 'cunit')}
                                  </Typography>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }) : null}
              </div>
            </div>
            <div className={styles.selectedMetrics}>
              <div className={styles.selectedMetricsHeader}>
                <Typography className={styles.selectedMetricTitle} variant='subtitle2' color='secondary' bold>
                  <FormattedMessage id='benchmarking.metricsOrder' />
                </Typography>
              </div>
              <div className={styles.selectedMetricsList}>
                <DndProvider backend={Backend}>
                  {selectedMetrics.map((selectedMetric, i) => {
                    const graphMetric = allMetrics.find(x => get(x, 'id') === selectedMetric);

                    return (
                      <DragablePanel
                        key={`draggable-panel-${get(graphMetric, 'id')}`}
                        graphMetric={graphMetric}
                        index={i}
                        onMove={this.handlerCardMove}
                      />
                    );
                  })}
                </DndProvider>
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <BigButton
              className={styles.cancel}
              onClick={this.handlerCancel}
              title={formatMessage({ id: 'button.cancel' })}
              theme='light'
              disabled={isFetching}
            />
            <BigButton
              className={styles.marginLeft}
              onClick={this.handlerOk}
              title={formatMessage({ id: 'benchmarking.changeMetrics' })}
              theme='dark'
              isLoading={isFetching}
            />
          </div>
        </div>
      </DefaultDialog>
    );
  }
}

export default injectIntl(BenchmarkMetricsChangeDialog);
