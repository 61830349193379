import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { requestMeasurementsByDate, requestSaveMeasurementsByDate, clearMeasurementsByDate } from 'store/measurements/actions';
import { getMeasurementsByDate, getIsMeasurementsByDateFetching } from 'store/measurements/selectors';

import {
  getAllCompartments,
  getAllVarieties,
  getLocation,
  getDateFromRoute,
} from 'store/company/selectors';

import {
  setHasChangesTime
} from 'store/harvest/actions';

import { getCurrentCycleFullInfo } from 'store/newCrops/selectors';
import { getCycleData } from 'store/newCrops/actions';
import {
  getChangesTime,
} from 'store/harvest/selectors';

import { showNotificationWithTimeout } from 'store/notificationCenter/actions';

import MeasurementsEnterWrapper from './MeasurementsEnterWrapper';


const mapDispatchToProps = {
  getCycleData,
  requestMeasurementsByDate,
  requestSaveMeasurementsByDate,
  showNotificationWithTimeout,
  clearMeasurementsByDate,
  setHasChangesTime,
};

const mapStateToProps = (state, props) => {
  const {
    match,
  } = props;

  return {
    cycleId: match.params.cycleId,
    date: getDateFromRoute(state, props),

    cycleInfo: getCurrentCycleFullInfo(state),
    isFetching: getIsMeasurementsByDateFetching(state),
    allCompartments: getAllCompartments(state),
    varieties: getAllVarieties(state),
    organization: getLocation(state),

    measurementsByDate: getMeasurementsByDate(state, props),
    changesTime: getChangesTime(state, props),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeasurementsEnterWrapper));
