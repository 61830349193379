import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import PlusIcon from 'components/Icons/Plus';

import styles from './AddViaIntercomButton.module.css';

const AddViaIntercomButton = ({
  className,
  text,
  onClick,
}) => (
  <button
    type='button'
    className={classnames(styles.button, className)}
    onClick={onClick}
  >
    <PlusIcon className={styles.plusIcon} />
    {text}
  </button>
);

AddViaIntercomButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

AddViaIntercomButton.defaultProps = {
  className: undefined,
};

export default AddViaIntercomButton;
