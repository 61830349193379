import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';

import historyPush from 'helpers/historyPush';

import DefaultCircleLoader from 'components/DefaultCircleLoader';

import UnrealizedPotentialHeader from 'components/UnrealizedPotential/components/UnrealizedPotentialHeader';
import RealizedIndicator from 'components/UnrealizedPotential/components/RealizedIndicator';
import LightProductivity from 'components/UnrealizedPotential/components/LightProductivity';
import Typography from 'components/Typography';
import DefaultPlate from 'components/DefaultPlate';

import { isNumber } from 'lodash/lang';
import DiscrepancyOverview from './components/DiscrepancyOverview';
import Metrics from './components/Metrics';
import NotSupportedPlaceholder from './components/NotSupportedPlaceholder';
import Harvest from './components/Harvest';

import styles from './UnrealizedPotential.module.css';


const INCORRECT_SUN_RADIATION_KEY = 'INCORRECT_SUN_RADIATION';

const UnrealizedPotential = ({
  intl,
  isFetching,
  isSuccess,
  isSupported,
  potential,
  descriptor,
  cycleId,
  requestGetUnrealizedPotential,
  requestGetUnrealizedPotentialArguments,
  unrealizedPotentialAccess,
  organizationSlug,
  modelSettings,
  warnings
}) => {
  const { formatMessage } = intl;

  useEffect(() => {
    if (!unrealizedPotentialAccess) {
      historyPush(`/${organizationSlug}/crops`);
    } else {
      requestGetUnrealizedPotential({
        plantingCycleId: cycleId
      });
      requestGetUnrealizedPotentialArguments({
        plantingCycleId: cycleId
      });
    }
  }, [
    cycleId,
    requestGetUnrealizedPotential,
    organizationSlug,
    unrealizedPotentialAccess,
    requestGetUnrealizedPotentialArguments
  ]);

  const showNotSupported = !isSupported && isSuccess && !isFetching;
  const lightingProps = {
    cycle: potential?.lightProductivity?.cycle?.amount,
    model: potential?.lightProductivity?.model?.amount,
    toModel: potential?.lightProductivity?.percentage?.amount,
    units: potential?.lightProductivity?.model?.units,
  };

  const warningsKeys = Object.keys(warnings);

  const warning = warningsKeys.length > 0 && warningsKeys.includes(INCORRECT_SUN_RADIATION_KEY) ?
    Number(warnings[INCORRECT_SUN_RADIATION_KEY]?.difference || warnings[INCORRECT_SUN_RADIATION_KEY]?.discrepancy) : null;

  return (
    <div className={styles.page}>
      {isFetching && <DefaultCircleLoader />}
      {showNotSupported && <NotSupportedPlaceholder />}
      {isSupported && isSuccess && (
        <div className={styles.container}>
          {isNumber(warning) && (
            <DefaultPlate
              className={styles.warningPlate}
              type='info'
            >
              {formatMessage({
                id: 'unrealizedPotential.warnings.INCORRECT_SUN_RADIATION'
              }, {
                difference: warning.toFixed(0),
                side: formatMessage({ id: `unrealizedPotential.warnings.${warning > 0 ? 'greater' : 'lower'}` }),
              })} <br />
            </DefaultPlate>
          )}
          <UnrealizedPotentialHeader modelSettings={modelSettings} />
          <div className={styles.potentialHarvestRow}>
            <RealizedIndicator
              realized={potential?.realized}
              wrappedClassName={styles.indicator}
            />
            <Harvest
              harvest={potential?.harvest}
              realized={potential?.realized}
              plan={potential?.plan}
              descriptor={descriptor}
              wrappedClassName={styles.harvestChart}
              unrealized={potential?.unrealized}
            />
          </div>
          <div className={styles.calculationOverviewTitle}>
            <Typography variant='h3'>
              <FormattedMessage id='unrealizedPotential.calculationOverview' />
            </Typography>
          </div>
          <div className={styles.overviewRow}>
            <DiscrepancyOverview
              metricsDiscrepancy={potential?.metricsDiscrepancy}
              descriptor={descriptor}
              wrappedClassName={styles.overviewChart}
            />
            <LightProductivity
              wrappedClassName={styles.lightingProductivity}
              {...lightingProps}
            />
          </div>
          <Metrics
            metrics={potential?.metrics}
            descriptor={descriptor}
          />
        </div>
      )}
    </div>
  );
};

UnrealizedPotential.propTypes = {
  intl: intlShape.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isSupported: PropTypes.bool.isRequired,
  unrealizedPotentialAccess: PropTypes.bool.isRequired,
  potential: PropTypes.object.isRequired,
  cycleId: PropTypes.string.isRequired,
  descriptor: PropTypes.object.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  requestGetUnrealizedPotential: PropTypes.func.isRequired,
  requestGetUnrealizedPotentialArguments: PropTypes.func.isRequired,
  modelSettings: PropTypes.array.isRequired,
  warnings: PropTypes.object.isRequired
};

export default UnrealizedPotential;
