import React, {
  useEffect, useRef, useState, useCallback
} from 'react';
import PropTypes from 'prop-types';

import parse from 'color-parse';
import hex2rgba from 'helpers/hex2rgba';

import { rgb2hex } from 'helpers/colors';
import styles from './index.module.css';

const KEY_CODE_ARROW_UP = 38;
const KEY_CODE_ARROW_DOWN = 40;

const trimNumberSymbol = (hex) => {
  if (hex[0] === '#') {
    return hex.slice(1);
  }
  return hex;
};

const trimPercentSign = (val) => {
  if (val[val.length - 1] === '%') {
    return val.slice(0, val.length - 1);
  }
  return val;
};

const ColorInput = ({
  onChange,
  initValue
}) => {
  const colorInputRef = useRef(null);
  const opacityInputRef = useRef(null);

  const [color, setColor] = useState(null);
  const [opacity, setOpacity] = useState(100);

  const handleColorKeyPress = (e) => {
    const {
      key,
      target: {
        selectionStart,
        selectionEnd,
        value
      },
    } = e;
    if (selectionStart === selectionEnd && (
      !(/^[0-9A-F]+$/.test(key.toUpperCase())) || value.length >= 6
    )) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleColorChange = useCallback((e) => {
    const input = trimNumberSymbol(e.currentTarget.value.toUpperCase());
    const HEX_REGEXP = /^[0-9A-F]{6}$/i;
    if (HEX_REGEXP.test(input)) {
      setColor(input);
      onChange(hex2rgba(input, opacity));
    }
  }, [setColor, opacity, onChange]);

  const handleColorInputBlur = useCallback((e) => {
    const input = trimNumberSymbol(e.currentTarget.value.toUpperCase());
    const HEX_REGEXP = /^[0-9A-F]{6}$/i;
    if (HEX_REGEXP.test(input)) {
      setColor(input);
      colorInputRef.current.value = `#${input}`;
      onChange(hex2rgba(input, opacity / 100));
    } else {
      setColor('FFFFFF');
      colorInputRef.current.value = '#FFFFFF';
      onChange(hex2rgba('FFFFFF', opacity / 100));
    }
  }, [setColor, opacity, onChange]);

  const handleColorInputFocus = useCallback(() => {
    colorInputRef.current.value = trimNumberSymbol(colorInputRef.current.value.toUpperCase());
  }, []);

  const handleOpacityChange = useCallback((e) => {
    const opacityValue = e.currentTarget.value;
    const HEX_REGEXP = /^[0-9]{1,3}$/i;
    if (HEX_REGEXP.test(opacityValue) && Number(opacityValue) >= 0 && Number(opacityValue) <= 100) {
      onChange(hex2rgba(color, Number(opacityValue) / 100));
      setOpacity(Number(opacityValue));
    } else {
      setOpacity(Number(opacityValue));
      onChange(hex2rgba(color, 1));
    }
  }, [onChange, color]);

  const handleOpacityKeyPress = useCallback((e) => {
    const {
      key,
      target: {
        selectionStart,
        selectionEnd,
        value
      },
    } = e;
    if (selectionStart === selectionEnd && (
      !(/^[0-9]+$/.test(key.toUpperCase())) || value.length >= 3 || Number(value) >= 100
    )) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  const handleOpacityBlur = useCallback((e) => {
    const input = trimPercentSign(e.currentTarget.value);
    const HEX_REGEXP = /^[0-9]{1,3}$/i;
    const numberValue = Number(input);
    if (HEX_REGEXP.test(input) && numberValue <= 100 && numberValue >= 0) {
      setOpacity(numberValue);
      opacityInputRef.current.value = `${input}%`;
      onChange(hex2rgba(color, numberValue / 100));
    } else {
      setOpacity(100);
      opacityInputRef.current.value = '100%';
      onChange(hex2rgba(color, 1));
    }
  }, [setOpacity, color, onChange]);

  const handleOpacityFocus = useCallback(() => {
    opacityInputRef.current.value = trimPercentSign(opacityInputRef.current.value);
  }, []);

  const handleOpacityKeyDown = useCallback((e) => {
    const {
      key,
      target: {
        value
      },
    } = e;
    const numberValue = Number(trimPercentSign(value));
    if (key === KEY_CODE_ARROW_UP || key === 'ArrowUp') {
      if (numberValue < 100) {
        const newOpacity = numberValue + 1;
        setOpacity(newOpacity);
        onChange(hex2rgba(color, newOpacity / 100));
      }
    } else if (key === KEY_CODE_ARROW_DOWN || key === 'ArrowDown') {
      if (numberValue > 0) {
        const newOpacity = numberValue - 1;
        setOpacity(newOpacity);
        onChange(hex2rgba(color, newOpacity / 100));
      }
    }
  }, [setOpacity, color, onChange]);

  useEffect(() => {
    if (initValue) {
      const parsedColor = parse(initValue);
      // eslint-disable-next-line no-bitwise,no-mixed-operators
      const newColor = rgb2hex(parsedColor?.values?.[0], parsedColor?.values?.[1], parsedColor?.values?.[2]);
      const newOpacity = Math.round(parsedColor?.alpha * 100);
      setColor(newColor);
      setOpacity(newOpacity);
      opacityInputRef.current.value = `${newOpacity}%`;
      colorInputRef.current.value = `#${newColor.toUpperCase()}`;
    }
  }, [initValue, setColor, setOpacity]);

  return (
    <div className={styles.container}>
      <input
        ref={colorInputRef}
        className={styles.color}
        type='text'
        placeholder='#FFFFFF'
        onChange={handleColorChange}
        onBlur={handleColorInputBlur}
        onFocus={handleColorInputFocus}
        onKeyPress={handleColorKeyPress}
        defaultValue={color}
      />
      <span
        className={styles.indicator}
        style={{
          background: `#${color}`
        }}
      />
      <input
        ref={opacityInputRef}
        className={styles.opacity}
        type='text'
        placeholder='100%'
        onChange={handleOpacityChange}
        onKeyPress={handleOpacityKeyPress}
        onBlur={handleOpacityBlur}
        onFocus={handleOpacityFocus}
        defaultValue={opacity}
        onKeyDown={handleOpacityKeyDown}
      />
    </div>
  );
};

ColorInput.propTypes = {
  initValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ColorInput.defaultProps = {
  initValue: 'rgba(255,255,255,1)'
};

export default ColorInput;
