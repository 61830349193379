import moment from 'moment-timezone';
import { isFinite } from 'lodash';
import { differenceInCalendarYears } from 'date-fns';
import getDateFormat from 'helpers/getDateFormat';
import { getMlName, getProductTypeName } from 'helpers/getVarietyName';
import { getValue } from 'helpers/getValueDetails';
import { getCompartmentNameById } from 'helpers/getPlantingCycleLabel';
import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';

import {
  parseAndFormatDateLocalized,
  parseDate,
} from 'helpers/datesHelper';

import { getNameByLocal } from 'helpers/getNameByLocal';

const getPlantingCycleHref = (organizationSlug, item) => {
  if (item?.ref?.type === 'PlantingCycle') {
    return `/${organizationSlug}/crops/${item?.ref?.id}`;
  }

  return undefined;
};

export function getCategorySortingValue(cat) {
  if (cat.forecastType === 'MANUAL') {
    if (cat.categoryType === 'ACCURACY') {
      return 9;
    }
    return 10;
  }
  if (cat.forecastType === 'AUTO') {
    if (cat.categoryType === 'ACCURACY') {
      return 7;
    }
    return 8;
  }
  return 0;
}

export function getCategoryName(
  category,
  intl
) {
  const { locale, formatMessage } = intl;

  const names = [getNameByLocal(category, locale)];

  if (category.forecastType === 'MANUAL') {
    switch (category.categoryType) {
      case 'FORECAST': {
        names.push(formatMessage({ id: 'forecast.dashboard.forecastNotCapitalized' }));
        break;
      }
      case 'ACCURACY': {
        names.push(formatMessage({ id: 'forecast.dashboard.forecastNotCapitalized' }));
        names.push(formatMessage({ id: 'forecast.dashboard.accuracy' }));
        break;
      }
      case 'DIFFERENCE_FORECAST': {
        names.push(formatMessage({ id: 'forecast.dashboard.forecastNotCapitalized' }));
        names.push('/');
        names.push(formatMessage({ id: 'forecast.dashboard.actualNotCapitalized' }));
        break;
      }
      // case 'DIFFERENCE_ACCURACY': {
      //  break;
      // }
      default: {
        break;
      }
    }
  }

  if (category.forecastType === 'AUTO') {
    switch (category.categoryType) {
      case 'FORECAST': {
        names.push(formatMessage({ id: 'forecast.dashboard.autoNotCapitalized' }));
        names.push(formatMessage({ id: 'forecast.dashboard.forecastNotCapitalized' }));
        break;
      }
      case 'ACCURACY': {
        names.push(formatMessage({ id: 'forecast.dashboard.autoNotCapitalized' }));
        names.push(formatMessage({ id: 'forecast.dashboard.forecastNotCapitalized' }));
        names.push(formatMessage({ id: 'forecast.dashboard.accuracy' }));
        break;
      }
      case 'DIFFERENCE_FORECAST': {
        names.push(formatMessage({ id: 'forecast.dashboard.autoNotCapitalized' }));
        names.push(formatMessage({ id: 'forecast.dashboard.forecastNotCapitalized' }));
        names.push('/');
        names.push(formatMessage({ id: 'forecast.dashboard.actualNotCapitalized' }));
        break;
      }
      // case 'DIFFERENCE_ACCURACY': {
      //  break;
      // }
      default: {
        break;
      }
    }
  }

  if (!category.forecastType && category.categoryType === 'HARVEST') {
    names.push(formatMessage({ id: 'forecast.dashboard.actualNotCapitalized' }));
  }

  return names.join(' ');
}

export function getCategoryColor(category) {
  const colors = [];

  if (category.forecastType === 'MANUAL') {
    switch (category.categoryType) {
      case 'FORECAST': {
        colors.push('#873679', '#D97BA2');
        break;
      }
      case 'ACCURACY': {
        colors.push('#AB72A1', '#8C46C3');
        break;
      }
      case 'DIFFERENCE_FORECAST': {
        colors.push('#C8427A', '#AF7ED5');
        break;
      }
      default: {
        break;
      }
    }
  }
  if (category.forecastType === 'AUTO') {
    switch (category.categoryType) {
      case 'FORECAST': {
        colors.push('#DB973A', '#E6B675');
        break;
      }
      case 'ACCURACY': {
        colors.push('#AF7ED5', '#E17227');
        break;
      }
      case 'DIFFERENCE_FORECAST': {
        colors.push('#1DBADF', '#E69A81');
        break;
      }
      default: {
        break;
      }
    }
  }

  if (!category.forecastType && category.categoryType === 'HARVEST') {
    colors.push('#0A94CA', '#C740B1');
  }

  return colors.length - 1 < category.groupIndex ? '#BADDAD' : colors[category.groupIndex];
}

export function getPlantingCycleDates(
  cycle,
  intl
) {
  const now = moment();
  const { formatMessage } = intl;

  const startDate = cycle?.startDate ? moment(cycle?.startDate).format(getDateFormat('lll')) : undefined;
  const endDate = cycle?.endDate ? moment(cycle?.endDate).format(getDateFormat('lll')) : undefined;

  const startDateMoment = moment(cycle?.startDate, 'YYYY-MM-DD');
  const isNotSpecified = !cycle?.endDate && startDateMoment.isAfter(now);

  return `${startDate} – ${isNotSpecified ? formatMessage({ id: 'cycle.notSpecified' }) :
    (endDate || formatMessage({ id: 'cycle.present' }))}`;
}

export function getItemName(
  item,
  plantingCycles,
  varieties,
  fruitClasses,
  compartments,
  productGroups,
  currentBreakdown,
  intl
) {
  if (!plantingCycles) {
    return '–';
  }

  const { formatMessage, locale } = intl;

  const refId = item?.ref?.id;
  const refType = item?.ref?.type;

  switch (refType) {
    case 'ProductGroup': {
      const currentProductGroup = productGroups.find(pgItem => pgItem.id === refId);
      if (currentProductGroup) {
        const {
          attributes: {
            fruitClass,
            targetWeight,
            varietyId,
          },
        } = currentProductGroup;
        const currentVariety = varieties.find(vrItem => vrItem.id === varietyId);
        const currentFruitClass = fruitClasses.find(vrItem => vrItem.id === fruitClass);
        return getProductTypeName({
          intl,
          targetWeight: targetWeight ? getValue(targetWeight, 0) : null,
          variety: currentVariety,
          fruitClass: currentFruitClass,
          withSpecies: false,
        });
      }
      return '–';
    }
    case 'Compartment': {
      return getCompartmentNameById({ compartmentId: refId, compartments });
    }
    case 'FruitClass': {
      const currentFruitClass = fruitClasses.find(fcItem => fcItem.id === refId);
      return refId === null ? formatMessage({ id: 'fruitClass.other' }) : getMlName(currentFruitClass, locale) || '';
    }
    case 'PlantingCycle': {
      const currentPlantingCycle = plantingCycles.find(pcItem => pcItem.id === refId);
      const plantingCycleDates = getPlantingCycleDates(currentPlantingCycle, intl);
      const currentCompartment = compartments.find(coItem => coItem.id === currentPlantingCycle?.compartmentRef?.id);
      const compartmentName = currentCompartment?.attributes?.name || undefined;
      const targetWeight = currentPlantingCycle?.targetWeight;
      const currentVariety = varieties.find(vrItem => vrItem.id === currentPlantingCycle?.varietyRef?.id);
      const currentFruitClass = fruitClasses.find(fcItem => fcItem.id === currentPlantingCycle?.fruitClassRef?.id);
      const currentProductGroupName = getProductTypeName({
        intl,
        targetWeight: targetWeight ? numbersFormatting(numbersRounding(targetWeight, 'fixed', 0)) : null,
        variety: currentVariety,
        fruitClass: currentFruitClass,
        withSpecies: false,
        withFruitClass: currentBreakdown !== 'fruitClass',
      });

      let names = [];
      if (currentBreakdown === 'compartment') {
        names = [currentProductGroupName, plantingCycleDates];
      } else if (currentBreakdown === 'workType') {
        names = [currentProductGroupName, plantingCycleDates];
      } else if (currentBreakdown === 'varietyWeight') {
        names = [plantingCycleDates, compartmentName];
      } else if (currentBreakdown === 'fruitClass') {
        names = [currentProductGroupName, plantingCycleDates, compartmentName];
      } else if (currentBreakdown === 'plantingCycle') {
        names = [currentProductGroupName, plantingCycleDates, compartmentName];
      }
      return names.join(', ');
    }
    default:
      return '–';
  }
}

export function getReportItem(
  item,
  forecastReportData,
  fields,
  varieties,
  fruitClasses,
  compartments,
  productGroups,
  currentBreakdown,
  intl
) {
  if (!forecastReportData) {
    return [];
  }

  const {
    range: {
      endInclusive,
      start
    },
    categories
  } = forecastReportData;
  const yearDifference = differenceInCalendarYears(
    parseDate(endInclusive),
    parseDate(start),
  );
  const startDate = parseAndFormatDateLocalized(start, yearDifference !== 0 ? 'lll' : 'llll');
  const endDate = parseAndFormatDateLocalized(endInclusive, 'llll');
  const { formatMessage } = intl;
  const data = item?.data || {};
  const barKeys = Object.keys(data);
  return {
    periodLabel: `${startDate} – ${endDate}`,
    id: item.key,
    bars: barKeys.map((categoryKey) => {
      const currentCategory = fields.overall.find(caItem => caItem.id === categoryKey);
      if (!currentCategory || !currentCategory.checked) {
        return null;
      }
      let percentValue = null;
      if (currentCategory.categoryType === 'DIFFERENCE_FORECAST') {
        const differenceAccuracyCategory = categories.find(cat => cat.categoryType === 'DIFFERENCE_ACCURACY' &&
          currentCategory.forecastType === cat.forecastType &&
          currentCategory.forecastCategoryId === cat.forecastCategoryId);
        if (differenceAccuracyCategory) {
          percentValue = data[differenceAccuracyCategory.id];
        }
      }
      return {
        key: categoryKey,
        name: getCategoryName(currentCategory, intl),
        color: getCategoryColor(currentCategory),
        value: data[categoryKey],
        percentValue,
        units: currentCategory.units,
        category: currentCategory,
        isRight: currentCategory?.categoryType === 'ACCURACY'
      };
    }).filter(barItem => barItem !== null && barItem.category.categoryType !== 'DIFFERENCE_ACCURACY'),
    name: item.key === 'total' ? formatMessage({ id: 'planFact.total' }) : getItemName(
      item,
      forecastReportData?.plantingCycles,
      varieties,
      fruitClasses,
      compartments,
      productGroups,
      currentBreakdown,
      intl
    ),
  };
}

export function getReportBarChartItems(
  forecastReportData,
  fields,
  varieties,
  fruitClasses,
  compartments,
  productGroups,
  currentBreakdown,
  graphKey,
  isOverallAllSelected,
  intl,
  isDefaultState,
  selectRowLimit = 20
) {
  if (!forecastReportData) {
    return [];
  }

  const {
    report,
  } = forecastReportData;

  const topLevelItems = report.map(item => item.key).slice(0, selectRowLimit);

  const data = report
    // .filter(item => item.key !== 'total')
    .reduce((acc, item) => [...acc, getReportItem(
      item,
      forecastReportData,
      fields,
      varieties,
      fruitClasses,
      compartments,
      productGroups,
      currentBreakdown,
      intl
    ), ...(item.children || []).map(childItem => getReportItem(
      childItem,
      forecastReportData,
      fields,
      varieties,
      fruitClasses,
      compartments,
      productGroups,
      currentBreakdown,
      intl
    ))], []);

  if (isDefaultState && !graphKey?.overall) {
    return data.filter(item => topLevelItems.includes(item.id));
  }

  return data.filter(item => graphKey?.overall?.includes(item.id));
}

export function getLineChartItems(
  forecastReportData,
  fields,
  varieties,
  fruitClasses,
  compartments,
  productGroups,
  currentBreakdown,
  graphKey,
  isByDaysAllSelected,
  intl,
  isDefaultState,
  selectRowLimit = 3
) {
  if (!forecastReportData) {
    return [];
  }

  const { formatMessage } = intl;

  const {
    table,
    descriptor
  } = forecastReportData;

  const dataKeys = fields.byDates.filter(dataItem => dataItem.checked).map(dataItem => dataItem.id);

  const topLevelItems = table.map(item => item.key).slice(0, selectRowLimit);

  const filteredTable = isDefaultState && !graphKey?.byDates ?
    table.filter(item => topLevelItems.includes(item.key)) :
    table.filter(item => graphKey?.byDates?.includes(item.key));

  return filteredTable
    .reduce((acc, item) => {
      const data = item?.data || {};
      return [...acc, ...dataKeys.map((categoryKey) => {
        const currentCategory = fields.byDates.find(caItem => caItem.id === categoryKey);
        const points = descriptor?.periods?.map((period) => {
          const date = moment.utc(new Date((+period.epochMinutes) * 60000));
          const y = data[categoryKey] && isFinite(data[categoryKey][period.epochMinutes]) ?
            data[categoryKey][period.epochMinutes] : null;
          return {
            y,
            x: date.format('MM-DD-YYYY')
          };
        });
        return {
          points,
          color: getCategoryColor(currentCategory),
          name: getCategoryName(currentCategory, intl),
          units: currentCategory.units,
          id: currentCategory.id,
          category: currentCategory,
          description: item.key === 'total' ? formatMessage({ id: 'planFact.total' }) : getItemName(
            item,
            forecastReportData?.plantingCycles,
            varieties,
            fruitClasses,
            compartments,
            productGroups,
            currentBreakdown,
            intl
          )
        };
      }).filter(barItem => barItem.category.categoryType !== 'DIFFERENCE_ACCURACY')];
    }, []);
}

export function getCategoriesLegend(categories, intl) {
  return categories.filter(category => category.checked).map(category => ({
    color: getCategoryColor(category),
    name: getCategoryName(category, intl),
    id: category.id
  }));
}

export function getForecastTableData(
  isForecastNoData,
  sourceType,
  forecastReportData,
  fields,
  varieties,
  fruitClasses,
  compartments,
  productGroups,
  currentBreakdown,
  intl,
  organizationSlug,
  expanded,
) {
  if (!forecastReportData || isForecastNoData) {
    return [];
  }

  const {
    report,
    table,
    descriptor,
    categories
  } = forecastReportData;

  const { formatMessage } = intl;

  return sourceType === 'overall' ? report.map((item) => {
    const graphKey = item.key;
    const data = item?.data || {};
    const children = item?.children || [];
    const isTotal = item.key === 'total';

    return {
      graphKey,
      groupKey: graphKey,
      label: isTotal ? formatMessage({ id: 'benchmarking.total' }) : getItemName(
        item,
        forecastReportData?.plantingCycles,
        varieties,
        fruitClasses,
        compartments,
        productGroups,
        currentBreakdown,
        intl
      ),
      href: getPlantingCycleHref(organizationSlug, item),
      columns: fields.overall.filter(dataItem => dataItem.checked).map((dataItem) => {
        const currentCategory = dataItem;
        if (currentCategory.categoryType === 'DIFFERENCE_FORECAST') {
          const differenceAccuracyCategory = categories.find(cat => cat.categoryType === 'DIFFERENCE_ACCURACY' &&
            currentCategory.forecastType === cat.forecastType &&
            currentCategory.forecastCategoryId === cat.forecastCategoryId);
          if (differenceAccuracyCategory) {
            return [data[dataItem.id], data[differenceAccuracyCategory.id]];
          }
          return data[dataItem.id];
        }
        return data[dataItem.id];
      }),
      units: fields.overall.filter(dataItem => dataItem.checked).map(dataItem => dataItem.units),
      expand: expanded.includes(graphKey),
      isTotal,
      children: isTotal ? [] : children.map(child => ({
        graphKey: child.key,
        groupKey: graphKey,
        label: getItemName(
          child,
          forecastReportData?.plantingCycles,
          varieties,
          fruitClasses,
          compartments,
          productGroups,
          currentBreakdown,
          intl
        ),
        href: getPlantingCycleHref(organizationSlug, child),
        columns: fields.overall.filter(dataItem => dataItem.checked).map((dataItem) => {
          const currentCategory = dataItem;
          if (currentCategory.categoryType === 'DIFFERENCE_FORECAST') {
            const differenceAccuracyCategory = categories.find(cat => cat.categoryType === 'DIFFERENCE_ACCURACY' &&
              currentCategory.forecastType === cat.forecastType &&
              currentCategory.forecastCategoryId === cat.forecastCategoryId);
            if (differenceAccuracyCategory) {
              return [child.data[dataItem.id], child.data[differenceAccuracyCategory.id]];
            }
            return child.data[dataItem.id];
          }
          return child.data[dataItem.id];
        }),
        units: fields.overall.filter(dataItem => dataItem.checked).map(dataItem => dataItem.units),
        isTotal,
      }))
    };
  }) : table.reduce((acc, item) => {
    const graphKey = item.key;
    const data = item?.data || {};
    const isTotal = item.key === 'total';

    const itemName = isTotal ? formatMessage({ id: 'benchmarking.total' }) : getItemName(
      item,
      forecastReportData?.plantingCycles,
      varieties,
      fruitClasses,
      compartments,
      productGroups,
      currentBreakdown,
      intl
    );

    const dataKeys = fields.byDates.filter(dataItem => dataItem.checked).map(dataItem => dataItem.id);
    return [...acc, ...dataKeys.map((categoryKey, groupIndex) => {
      const currentCategory = fields.byDates.find(caItem => caItem.id === categoryKey);
      if (!currentCategory || !currentCategory.checked) {
        return null;
      }
      let percentColumns = null;
      if (currentCategory.categoryType === 'DIFFERENCE_FORECAST') {
        const differenceAccuracyCategory = categories.find(cat => cat.categoryType === 'DIFFERENCE_ACCURACY' &&
          currentCategory.forecastType === cat.forecastType &&
          currentCategory.forecastCategoryId === cat.forecastCategoryId);
        if (differenceAccuracyCategory) {
          percentColumns = descriptor?.periods?.map(period => data[differenceAccuracyCategory.id] && data[differenceAccuracyCategory.id][period.epochMinutes]);
        }
      }
      const columns = descriptor?.periods?.map(period => data[categoryKey] && data[categoryKey][period.epochMinutes]);
      return {
        groupLabel: itemName,
        href: getPlantingCycleHref(organizationSlug, item),
        groupIndex,
        graphKey: categoryKey,
        groupKey: graphKey,
        label: getCategoryName(currentCategory, intl),
        columns,
        percentColumns,
        groupSpan: dataKeys.length,
        isTotal,
        units: currentCategory.units,
      };
    }).filter(dataLine => dataLine !== null)];
  }, []);
}

export function getIndicatorCategories(categories, data) {
  return categories
    .filter(cat => cat.categoryType === 'FORECAST' && cat.forecastType === 'MANUAL')
    .map(forecast =>
      /* const accuracy = categories.find(cat =>
      cat.categoryType === 'ACCURACY' &&
      cat.forecastType === 'MANUAL' &&
      cat.forecastCategoryId === forecast.forecastCategoryId); */
       (data[forecast.id] ? {
        category: forecast,
        value: data[forecast.id],
        roundTO: 0,
        units: forecast.units,
        accuracy: null // accuracy ? data[accuracy.id] : null,
      } : null)).filter(item => item !== null);
}
