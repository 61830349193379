import moment from 'moment-timezone';

export default function getDatePeriodRange({
  date,
  periodType,
  minDate,
  maxDate,
  formatMessage,
}) {
  let startOfPeriodDateRaw = null;

  if (periodType === 'whole') {
    startOfPeriodDateRaw = minDate.clone();
  } else {
    startOfPeriodDateRaw = date.clone().startOf(periodType);
  }
  const startOfPeriodDate = startOfPeriodDateRaw.isBefore(minDate) ? minDate : startOfPeriodDateRaw;


  let endOfPeriodDateRaw = null;

  if (periodType === 'whole') {
    endOfPeriodDateRaw = maxDate.clone();
  } else {
    endOfPeriodDateRaw = date.clone().endOf(periodType);
  }
  const endOfPeriodDate = endOfPeriodDateRaw.isAfter(maxDate) ? maxDate : endOfPeriodDateRaw;


  const sameYear = startOfPeriodDate.year() === moment().year() && endOfPeriodDate.year() === moment().year();


  let startOfPeriod = null;

  if (periodType === 'whole') {
    startOfPeriod = startOfPeriodDate.clone().format(`${formatMessage({ id: 'date.dayMonth' })}${sameYear ? '' : ', YYYY'}`);
  } else {
    startOfPeriod = startOfPeriodDate.clone().startOf(periodType).format(`${formatMessage({ id: 'date.dayMonth' })}${sameYear ? '' : ', YYYY'}`);
  }


  let endOfPeriod = null;

  if (periodType === 'whole') {
    endOfPeriod = endOfPeriodDate.clone().format(`${formatMessage({ id: 'date.dayMonth' })}${sameYear ? '' : ', YYYY'}`);
  } else {
    endOfPeriod = endOfPeriodDate.clone().endOf(periodType).format(`${formatMessage({ id: 'date.dayMonth' })}${sameYear ? '' : ', YYYY'}`);
  }

  return {
    start: startOfPeriod,
    end: endOfPeriod,
    startDate: startOfPeriodDate,
    endDate: endOfPeriodDate,
    sameYear,
  };
}
