import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './ApiUseNotification.module.css';

const ApiUseNotification = ({
  prodApiList,
  excludeHosts,
}) => {
  const isProd = useMemo(() => {
    if (prodApiList.includes(window.API) && !excludeHosts.includes(window.location.host)) {
      return true;
    }
    return false;
  }, [
    prodApiList,
    excludeHosts
  ]);
  return isProd && <div className={styles.indicator}>prod</div>;
};

ApiUseNotification.propTypes = {
  prodApiList: PropTypes.array,
  excludeHosts: PropTypes.array
};

ApiUseNotification.defaultProps = {
  prodApiList: ['https://site-api-green.pylot.app/api', 'https://site-api-blue.pylot.app/api'],
  excludeHosts: ['pylot.app', 'demo.pylot.app', 'staging.pylot.app']
};

export default ApiUseNotification;
