import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component, } from 'react';
import { ReactComponent as LoaderIcon } from './assets/loader.svg';

import styles from './CircleLoader.module.css';


export default class CircleLoader extends Component {
  static propTypes = {
    iconClassName: PropTypes.string,
    className: PropTypes.string,
    showOnlyBackground: PropTypes.bool,
  };

  static defaultProps = {
    iconClassName: null,
    className: null,
    showOnlyBackground: false,
  };

  render() {
    const { iconClassName, className, showOnlyBackground } = this.props;
    return (
      <div className={classnames(styles.loader, className)}>
        {!showOnlyBackground && (
          <div className={classnames(styles.icon, iconClassName)}>
            <LoaderIcon />
          </div>
        )}
      </div>
    );
  }
}
