import PropTypes from 'prop-types';
import React, { Component, useCallback, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router';
import { injectIntl, intlShape } from 'react-intl';
import {
  reduxForm, Field, touch, untouch, FieldArray, change
} from 'redux-form';

import { get, find, uniqueId } from 'lodash';
import classnames from 'classnames';
import animateScrollTo from 'animated-scroll-to';

import { getProductTypeName } from 'helpers/getVarietyName';

import DeleteIcon from 'components/Icons/Delete';

import tooltipStyles from 'components/Tooltip/index.module.css';
import isTouchDevice from 'helpers/isTouchDevice';
import Typography from 'components/Typography';
import getDateFormat from 'helpers/getDateFormat';
import BigButton from '../BigButton';
import CustomDatePickerInput from '../CustomDatePickerInput';
import CustomNumberInput from '../CustomNumberInput';
import CustomSelectInput from '../CustomSelectInput';

import { ReactComponent as AddIcon } from './assets/add.svg';
import { ReactComponent as ArrowBackIcon } from '../CreateCropWizard/assets/arrow_back.svg';
import styles from './PlantingCycleForm.module.css';


export const preparePlantingCycleFormData = (form) => {
  const {
    variety,
    compartment,
    targetWeight,
    plantingArea,
    plantDensity: defaultPlantDensity,
    startDate,
    plantingDate,
    endDate,
    plantDensityChanges: allPlantDensityChanges
  } = form;

  const defaultPlantDensityNumber = defaultPlantDensity ?
    parseFloat(defaultPlantDensity) : null;

  const plantDensity = allPlantDensityChanges?.length > 0 ?
    parseFloat(allPlantDensityChanges[0].value) : defaultPlantDensityNumber;

  const filteredPlantDensityChanges = allPlantDensityChanges
    ?.filter(changes => changes.since && changes.value) || [];

  if (filteredPlantDensityChanges.length > 0) {
    filteredPlantDensityChanges.shift();
  }

  return {
    startDate: startDate.format('YYYY-MM-DD'),
    plantingDate: plantingDate ? plantingDate.format('YYYY-MM-DD') : null,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
    compartmentId: compartment,
    varietyId: variety,
    plantingArea: parseFloat(plantingArea),
    targetWeight: targetWeight ? +targetWeight : null,
    plantDensity,
    plantDensityChanges: filteredPlantDensityChanges
      .map(changes => ({
        since: changes.since.format('YYYY-MM-DD'),
        value: parseFloat(changes.value)
      }))
  };
};

const parseNumber = (value) => {
  const sample = 1.1;
  const delimiter = /^1(.+)1$/.exec(sample.toLocaleString())[1];
  return Number(
    value.toString()
      .replace('.', delimiter)
      .replace(',', delimiter)
      .replace(delimiter, '.')
  );
};

const warn = (values, props) => {
  const { intl, compartments, selectedCrop } = props;
  const { formatMessage } = intl;
  const isLettuce = selectedCrop === 'lettuce';

  const warnings = {};

  let compartmentArea = null;

  if (values) {
    const { compartment } = values;
    if (compartment) {
      const findedCompartment = compartments.find(item => item.id === compartment);

      const { attributes: { floorArea } } = findedCompartment;

      if (floorArea) {
        compartmentArea = floorArea;
      }
    }
  }

  if (!isLettuce && values.plantingArea && !Number.isNaN(parseNumber(values.plantingArea)) && !(parseNumber(values.plantingArea) < 1)) {
    if (compartmentArea && parseNumber(values.plantingArea) > compartmentArea) {
      warnings.plantingArea = formatMessage({ id: 'plantingCycles.errors.cannotBeExceedCompartmentArea' });
    }
  }

  return warnings;
};

const validate = (values, props) => {
  const { intl, selectedCrop } = props;
  const { formatMessage } = intl;

  const dateCompareGranularity = 'day';

  const isLettuce = selectedCrop === 'lettuce';

  const errors = {};
  if (!values.variety) {
    errors.variety = formatMessage({ id: 'plantingCycles.errors.chooseVariety' });
  }

  if (!values.compartment) {
    errors.compartment = formatMessage({ id: 'plantingCycles.errors.chooseCompartment' });
  }

  if (!values.targetWeight) {
    errors.targetWeight = formatMessage({ id: 'plantingCycles.errors.chooseTargetWeight' });
  }

  const validateNumberValue = (value) => {
    if (Number.isNaN(parseNumber(value))) {
      return formatMessage({ id: 'plantingCycles.errors.shouldBeANumber' });
    }
    if (parseNumber(value) < 1) {
      return formatMessage({ id: 'plantingCycles.errors.cannotBeLessThanOne' });
    }
    if (parseNumber(value) > 10000) {
      return formatMessage({ id: 'plantingCycles.errors.cannotBeExceedTenThousand' });
    }
    return undefined;
  };

  const validateNumberField = (fieldName, formValues) => {
    const value = formValues[fieldName];
    return value ? {
      [fieldName]: validateNumberValue(value)
    } : {};
  };

  if (!isLettuce) {
    if (!values.plantingArea) {
      errors.plantingArea = formatMessage({ id: 'plantingCycles.errors.enterPlantingArea' });
    } else if (Number.isNaN(parseNumber(values.plantingArea))) {
      errors.plantingArea = formatMessage({ id: 'plantingCycles.errors.shouldBeANumber' });
    } else if (parseNumber(values.plantingArea) < 1) {
      errors.plantingArea = formatMessage({ id: 'plantingCycles.errors.cannotBeLessThanOne' });
    }
  }

  // for all species
  Object.assign(errors, validateNumberField('targetWeight', values));

  if (!values.startDate) {
    errors.startDate = formatMessage({ id: 'plantingCycles.errors.chooseSeedingDate' });
  } else if (values.plantingDate && values.plantingDate.isSameOrBefore(values.startDate, dateCompareGranularity)) {
    errors.startDate = formatMessage({ id: 'plantingCycles.errors.seedingDateShouldBeEarlierThanPlantingDate' });
  } else if (values.endDate && values.endDate.isSameOrBefore(values.startDate, dateCompareGranularity)) {
    errors.startDate = formatMessage({ id: 'plantingCycles.errors.seedingDateShouldBeEarlierThanEndDate' });
  }

  if (!isLettuce) {
    if (!values.plantingDate) {
      errors.plantingDate = formatMessage({ id: 'plantingCycles.errors.choosePlantingDate' });
    } else if (!values.plantingDate) {
      if (values.startDate && values.startDate.isSameOrAfter(values.plantingDate, dateCompareGranularity)) {
        errors.plantingDate = formatMessage({ id: 'plantingCycles.errors.plantingDateShouldBeLaterThanSeedingDate' });
      } else if (values.endDate && values.endDate.isSameOrBefore(values.plantingDate, dateCompareGranularity)) {
        errors.plantingDate = formatMessage({ id: 'plantingCycles.errors.plantingDateShouldBeEarlierThanEndDate' });
      }
    }
  }

  if (values.endDate) {
    if (values.startDate && values.startDate.isSameOrAfter(values.endDate, dateCompareGranularity)) {
      errors.endDate = formatMessage({ id: 'plantingCycles.errors.endDateShouldBeLaterThanSeedingDate' });
    } else if (values.plantingDate && values.plantingDate.isSameOrAfter(values.endDate, dateCompareGranularity)) {
      errors.endDate = formatMessage({ id: 'plantingCycles.errors.endDateShouldBeLaterThanPlantingDate' });
    }
  }

  const validateChangeRow = (changes, plantingDate, endDate, rowIndex) => {
    const rowChange = changes[rowIndex];
    const isLastChange = rowIndex === changes.length - 1;
    const prevSince = rowIndex > 0 ? changes[rowIndex - 1].since : plantingDate;

    if (isLastChange && !rowChange.value && !rowChange.since) {
      return {};
    }

    let value;
    let since;

    if (!rowChange.value) {
      value = formatMessage({ id: 'plantingCycles.errors.chooseValue' });
    }

    if (rowChange.value) {
      value = validateNumberValue(rowChange.value);
    }

    if (rowChange.value && !rowChange.since && rowIndex > 0) {
      since = formatMessage({ id: 'plantingCycles.errors.chooseSince' });
    }

    if (rowChange.since && rowIndex > 0) {
      if (prevSince && !rowChange.since.isAfter(prevSince, dateCompareGranularity)) {
        since = formatMessage({
          id: 'plantingCycles.errors.sinceShouldBeLaterThatPreviousSince'
        });
      } else if (endDate && !rowChange.since.isSameOrBefore(endDate, dateCompareGranularity)) {
        since = formatMessage({
          id: 'plantingCycles.errors.sinceShouldBeEarlierThanEndDate'
        });
      }
    }

    return {
      value,
      since
    };
  };

  const filterPlantDensityChanges = (plantDensityChanges) => {
    const changes = [];
    let preventRemove = false;
    for (let i = plantDensityChanges.length - 1; i >= 0; i -= 1) {
      const rowChange = plantDensityChanges[i];
      if (rowChange.since !== undefined || rowChange.value !== undefined || preventRemove) {
        changes.unshift(rowChange);
        preventRemove = true;
      }
    }
    return changes;
  };

  if (!isLettuce) {
    const plantDensityChanges = values?.plantDensityChanges;
    if (plantDensityChanges?.length > 1) {
      const plantDensityChangesErrors = [];
      const changes = filterPlantDensityChanges(plantDensityChanges);
      for (let i = 0; i < changes.length; i += 1) {
        plantDensityChangesErrors.push(
          validateChangeRow(changes, values.plantingDate, values.endDate, i)
        );
      }
      errors.plantDensityChanges = plantDensityChangesErrors;
    } else {
      const { plantDensity } = validateNumberField('plantDensity', values);
      errors.plantDensityChanges = [{
        plantDensity
      }];
    }
  }

  return errors;
};

const FORM_NAME = 'createPlantingCycleForm';
const PLANTING_DENSITY_DECIMAL_SCALE = 2;

const PlantDensityChanges = injectIntl(({
  intl,
  fields,
  selectedPlantingDate,
  selectedEndDate,
  selectedPlantDensity,
  dispatch
}) => {
  const { formatMessage } = intl;

  const handleClickAdd = useCallback(() => {
    if (fields.length === 0) {
      fields.push({
        id: uniqueId('plantDensityChanges-'),
        since: selectedPlantingDate,
        value: selectedPlantDensity
      });
      fields.push({
        id: uniqueId('plantDensityChanges-')
      });
    } else {
      fields.push({
        id: uniqueId('plantDensityChanges-')
      });
    }
  }, [fields, selectedPlantingDate, selectedPlantDensity]);

  const handleClickRemove = useCallback((index) => {
    if (fields.length > 0) {
      if (fields.length === 2) {
        dispatch(change(FORM_NAME, 'plantDensity', fields.get(0).value));
        fields.removeAll();
      } else {
        fields.remove(index);
      }
    }
  }, [fields, dispatch]);

  const deleteTooltipId = `delete-tooltip-${uniqueId()}`;
  const firstSinceTooltipId = `first-since-tooltip-${uniqueId()}`;

  const rowLabels = useMemo(() => {
    const plantingDateLabelLoc = formatMessage({ id: 'plantingCycles.plantingDate' });
    const endDateLabelLoc = formatMessage({ id: 'plantingCycles.endDate' });
    const newPlantingDensityLabelLoc = formatMessage({ id: 'plantingCycles.newPlantingDensity' });
    const presentLabelLoc = formatMessage({ id: 'cycle.present' });
    const endDateLoc = selectedEndDate ? endDateLabelLoc : presentLabelLoc;
    const labels = [];

    const pickNextDate = (index) => {
      for (let i = index + 1; i < fields.length; i += 1) {
        const rowData = fields.get(i);
        if (rowData?.since) {
          return rowData?.since;
        }
      }
      return null;
    };

    for (let i = 0; i < fields.length; i += 1) {
      const rowData = fields.get(i);
      const dateLoc = rowData?.since ? rowData?.since.format(getDateFormat('lll')) : presentLabelLoc;
      const nextDate = pickNextDate(i);
      const nextDateLoc = nextDate ? nextDate.format(getDateFormat('lll')) : endDateLoc;
      if (i === 0) {
        labels.push(`${plantingDateLabelLoc} - ${nextDateLoc}`);
      } else if (i > 0 && rowData?.since) {
        labels.push(`${dateLoc} - ${nextDateLoc}`);
      } else if (i > 0 && !rowData?.since) {
        labels.push(newPlantingDensityLabelLoc);
      }
    }
    return labels;
  }, [fields, formatMessage, selectedEndDate]);

  return (
    <div className={styles.part}>
      <h2 className={styles.subtitle}>{formatMessage({ id: 'plantingCycles.plantingDensity' })}</h2>
      <p className={styles.description}>
        {formatMessage({ id: 'plantingCycles.plantingDensityDescription' })}
      </p>
      <div className={styles.fields}>
        {fields.length === 0 ? (
          <Field
            name='plantDensity'
            component={CustomNumberInput}
            allowNegative={false}
            forcedDecimalScale={PLANTING_DENSITY_DECIMAL_SCALE}
            forceDecimalScale
            placeholder={`${formatMessage({ id: 'plantingCycles.plantingDensityInputPlaceholder' })}`}
            onInnerBlur={(e) => {
              if (e.toString().length === 0) {
                dispatch(touch(FORM_NAME, 'plantDensity'));
              }
            }}
            onInnerChange={() => {
              dispatch(touch(FORM_NAME, 'plantDensity'));
            }}
          />
        ) : fields.map((line, index) => (
          <div className={styles.plantingDensityRow} key={line}>
            <div className={styles.rowTitle}>
              <Typography variant='subtitle3'>{rowLabels[index]}</Typography>
            </div>
            <div
              className={styles.plantingValue}
            >
              <Field
                name={`${line}.value`}
                component={CustomNumberInput}
                buttonWrapper={styles.buttonWrapperClassName}
                allowNegative={false}
                forcedDecimalScale={PLANTING_DENSITY_DECIMAL_SCALE}
                forceDecimalScale
                placeholder={`${formatMessage({ id: 'plantingCycles.plantingDensityInputPlaceholder' })}`}
                onInnerChange={() => {
                  dispatch(touch(FORM_NAME, `${line}.value`));
                }}
              />
            </div>
            <div
              data-tip={index === 0 ? formatMessage({ id: 'plantingCycles.firstRowSinceTooltip' }) : null}
              data-for={firstSinceTooltipId}
              className={styles.dateSelector}
            >
              <Field
                name={`${line}.since`}
                component={CustomDatePickerInput}
                popperPlacement='auto'
                formatMessage={formatMessage}
                placeholder={index === 0 ? formatMessage({ id: 'plantingCycles.plantingDate' }) :
                  formatMessage({ id: 'plantingCycles.effectiveFrom' })}
                onInnerChange={() => {
                  dispatch(touch(FORM_NAME, `${line}.since`));
                }}
                disabled={index === 0}
                wrapperClassName={styles.dateSelectorWrapper}
                clearable={index !== 0}
              />
            </div>
            <div
              className={classnames(styles.removeIconContainer, {
                [styles.noHover]: index === 0
              })}
              role='button'
              tabIndex={0}
              onClick={() => {
                if (index > 0) {
                  handleClickRemove(index);
                }
              }}
            >
              {index > 0 && (
                <div
                  data-tip={formatMessage({ id: 'plantingCycles.deleteTooltip' })}
                  data-for={deleteTooltipId}
                  className={styles.deleteIconWrapper}
                >
                  <DeleteIcon />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div
        role='button'
        tabIndex={0}
        className={styles.plantingDensityAdd}
        onClick={handleClickAdd}
      >
        <AddIcon className={styles.entity} />
        <span className={styles.entity}>
          {formatMessage({ id: 'plantingCycles.plantingDensityAddAnother' })}
        </span>
      </div>
      <ReactTooltip
        className={classnames(tooltipStyles.smallTooltip)}
        id={firstSinceTooltipId}
        effect='solid'
        event={isTouchDevice() ? 'click' : null}
        html
      />
      <ReactTooltip
        className={classnames(tooltipStyles.smallTooltip)}
        id={deleteTooltipId}
        effect='solid'
        event={isTouchDevice() ? 'click' : null}
        html
      />
    </div>
  );
});


PlantDensityChanges.propTypes = {
  intl: intlShape.isRequired,
  fields: PropTypes.object.isRequired,
  selectedPlantingDate: PropTypes.object,
  selectedEndDate: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  selectedPlantDensity: PropTypes.number,
};

PlantDensityChanges.defaultProps = {
  selectedPlantingDate: null,
  selectedEndDate: null,
  selectedPlantDensity: null
};

class PlantingCycleForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    selectedCrop: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    pageToGoBack: PropTypes.string,
    onBackStep: PropTypes.func,
  };

  static defaultProps = {
    isLoading: false,
    pageToGoBack: null,
    onBackStep: null,
  };

  constructor(...props) {
    super(...props);

    this.variety = React.createRef();
    this.compartment = React.createRef();
    this.plantingArea = React.createRef();
    this.plantDensity = React.createRef();
    this.startDate = React.createRef();
    this.plantingDate = React.createRef();
    this.endDate = React.createRef();
    this.targetWeight = React.createRef();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      dispatch
    } = this.props;
    const { values } = this.getForm();
    const { values: prevValues } = prevProps[FORM_NAME];
    if (prevValues?.plantingDate !== values?.plantingDate) {
      if (values?.plantDensityChanges?.length > 1) {
        dispatch(change(FORM_NAME, 'plantDensityChanges[0].since', values?.plantingDate));
      }
    }
  }

  handlerBackStep = () => {
    const { onBackStep } = this.props;

    if (onBackStep) {
      onBackStep();
    }
  };

  getFirstErrorKey = () => {
    const { syncErrors = {} } = this.getForm();

    const isPlantDensityChangesError = syncErrors?.plantDensityChanges.length > 0 &&
      syncErrors?.plantDensityChanges?.some(row => row?.since && row?.value);

    const syncErrorsKeys = Object.keys(syncErrors);

    const sortedKeys = [
      'variety',
      'targetWeight',
      'compartment',
      'plantingArea',
      'plantDensity',
      'startDate',
      'plantingDate',
      'endDate',
      'plantDensityChanges'
    ];

    const firstErrorKey = syncErrorsKeys
      .sort((a, b) => {
        const aIndex = sortedKeys.findIndex(item => a === item);
        const bIndex = sortedKeys.findIndex(item => b === item);

        return aIndex > bIndex;
      })
      .find(key => syncErrors[key]);

    if (firstErrorKey !== 'plantDensityChanges') {
      return firstErrorKey;
    }

    return isPlantDensityChangesError ? 'plantDensityChanges' : undefined;
  }

  handlerSubmit = (options) => {
    const {
      handleSubmit,
    } = this.props;

    if (handleSubmit) {
      handleSubmit(options);
    }

    const firstErrorRefKey = this.getFirstErrorKey();
    if (firstErrorRefKey) {
      if (firstErrorRefKey === 'plantDensityChanges') {
        this.scrollToElement(this?.plantDensity.current);
      } else {
        this.scrollToElement(this[firstErrorRefKey].current);
      }
    }
  };

  getForm = () => this.props[FORM_NAME];

  scrollToElement = (el) => {
    if (el) {
      const desiredOffset = el.offsetTop - 64 - 12 - 32;
      const offset = desiredOffset > 0 ? desiredOffset : 0;
      animateScrollTo(offset);
    }
  };

  render() {
    const {
      intl,
      fruitClasses,
      varieties,
      compartments,
      mode,
      selectedCrop,
      dispatch,
      isLoading,
      history,
      pageToGoBack,
    } = this.props;

    const { values: formValues } = this.getForm();

    let selectedStartDate = null;
    let selectedPlantingDate = null;
    let selectedEndDate = null;
    let selectedPlantDensity = null;

    if (formValues) {
      const {
        plantingDate,
        endDate,
        startDate,
        plantDensity,
      } = formValues;

      selectedPlantingDate = plantingDate;
      selectedStartDate = startDate;
      selectedEndDate = endDate;
      selectedPlantDensity = plantDensity;
    }

    const { formatMessage } = intl;

    const filteredVarieties = varieties
      .filter((variety) => {
        const { attributes: { species: speciesCheck } } = variety;

        return speciesCheck === selectedCrop;
      })
      .map((variety) => {
        const { id } = variety;

        const fruitClassId = get(variety, 'attributes.fruitClass');
        const currentFruitClass = find(fruitClasses, { id: fruitClassId });

        const productName = getProductTypeName({
          intl,
          variety,
          fruitClass: currentFruitClass,
          withCommaSeparated: true,
        });

        return {
          id,
          name: productName,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));

    const filteredCompartments = compartments
      .map(compartment => ({
        id: compartment.id,
        description: compartment.attributes.floorArea ? ` (${compartment.attributes.floorArea} m²)` : null,
        title: compartment.attributes.name,
      }))
      .sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true, sensitivity: 'base' }));


    const title = mode === 'edit' ? formatMessage({ id: 'plantingCycles.saveChanges' }) : formatMessage({ id: 'plantingCycles.addCycle' });

    const isLettuce = selectedCrop === 'lettuce';

    return (
      <div className={classnames(styles.layout)}>
        <form className={styles.form} onSubmit={this.handlerSubmit}>
          {mode === 'edit' && (
            <h1 className={styles.title}>
              {formatMessage({ id: 'plantingCycles.editPlantingCycle' })}
            </h1>
          )}
          <div className={styles.part}>
            <h2 className={styles.subtitle}>{formatMessage({ id: 'plantingCycles.cropTitle' }, { crop: formatMessage({ id: `plantingCycles.ofSpecies.${selectedCrop}` }).toLowerCase() })}</h2>
            <p className={styles.description}>
              {formatMessage({ id: 'plantingCycles.cropDescription' })}
            </p>
            <div className={styles.row} ref={this.variety}>
              <Field
                name='variety'
                labelPath='name'
                valuePath='id'
                titlePath='name'
                buttonLabelPath='name'
                isSearchable
                empty={formatMessage({ id: 'graphs.sidePanel.noSearchResult' })}
                placeholder={`${formatMessage({ id: 'plantingCycles.varietyAndTypeOption' })}*`}
                placeholderSearchList={formatMessage({ id: 'plantingCycles.varietyAndTypeSearch' })}
                options={filteredVarieties}
                component={CustomSelectInput}
                onInnerOpenChange={() => {
                  dispatch(untouch(FORM_NAME, 'variety'));
                }}
              />
            </div>
            <div className={styles.row} ref={this.targetWeight}>
              <Field
                name='targetWeight'
                component={CustomNumberInput}
                allowNegative={false}
                forcedDecimalScale={0}
                forceDecimalScale={false}
                placeholder={`${formatMessage({
                  id: `plantingCycles.${isLettuce ? 'targetWeight' : 'targetFruitWeight'}`
                })}`}
                onInnerBlur={(e) => {
                  if (e.toString().length === 0) {
                    dispatch(untouch(FORM_NAME, 'targetWeight'));
                  }
                }}
                onInnerChange={() => {
                  dispatch(untouch(FORM_NAME, 'targetWeight'));
                }}
              />
            </div>
          </div>

          <div className={styles.part}>
            <h2 className={styles.subtitle}>{formatMessage({ id: 'plantingCycles.growingAreaTitle' })}</h2>
            <p className={styles.description}>
              {formatMessage({ id: 'plantingCycles.growingAreaDescription' })}
            </p>

            <div className={styles.row} ref={this.compartment}>
              <Field
                name='compartment'
                placeholder={`${formatMessage({ id: 'plantingCycles.compartment' })}*`}
                labelPath='title'
                valuePath='id'
                options={filteredCompartments}
                component={CustomSelectInput}
                disabled={mode === 'edit'}
                onInnerOpenChange={() => {
                  dispatch(untouch(FORM_NAME, 'compartment'));
                }}
              />
            </div>
            {!isLettuce && (
              <div className={styles.row} ref={this.plantingArea}>
                <Field
                  name='plantingArea'
                  component={CustomNumberInput}
                  allowNegative={false}
                  forcedDecimalScale={1}
                  forceDecimalScale
                  placeholder={`${formatMessage({ id: 'plantingCycles.plantingArea' })}`}
                  onInnerBlur={(e) => {
                    if (e.toString().length === 0) {
                      dispatch(untouch(FORM_NAME, 'plantingArea'));
                    }
                  }}
                  onInnerChange={() => {
                    dispatch(untouch(FORM_NAME, 'plantingArea'));
                  }}
                />
              </div>
            )}
          </div>
          <div className={styles.part}>
            <h2 className={styles.subtitle}>{formatMessage({ id: 'plantingCycles.cycleDatesTitle' })}</h2>
            <p className={styles.description}>
              {formatMessage({ id: 'plantingCycles.cycleDatesDescription' })}
            </p>

            <div className={styles.row} ref={this.startDate}>
              <Field
                name='startDate'
                component={CustomDatePickerInput}
                formatMessage={formatMessage}
                placeholder={isLettuce ? `${formatMessage({ id: 'plantingCycles.lettuceSeedingDate' })}*` : `${formatMessage({ id: 'plantingCycles.seedingDate' })}*`}
                onInnerBlur={(e) => {
                  if (!e) {
                    dispatch(untouch(FORM_NAME, 'startDate'));
                  }
                  if (selectedPlantingDate) {
                    dispatch(touch(FORM_NAME, 'plantingDate'));
                  }
                  if (selectedEndDate) {
                    dispatch(touch(FORM_NAME, 'endDate'));
                  }
                }}
                onInnerFocus={() => {
                  dispatch(untouch(FORM_NAME, 'startDate'));
                  dispatch(untouch(FORM_NAME, 'plantingDate'));
                  dispatch(untouch(FORM_NAME, 'endDate'));
                }}
                onInnerClear={() => { dispatch(untouch(FORM_NAME, 'startDate')); }}
              />
            </div>


            {!isLettuce && (
              <div className={styles.row} ref={this.plantingDate}>
                <Field
                  name='plantingDate'
                  component={CustomDatePickerInput}
                  formatMessage={formatMessage}
                  placeholder={`${formatMessage({ id: 'plantingCycles.plantingDate' })}*`}
                  onInnerBlur={(e) => {
                    if (!e) {
                      dispatch(untouch(FORM_NAME, 'plantingDate'));
                    }
                    if (selectedStartDate) {
                      dispatch(touch(FORM_NAME, 'startDate'));
                    }
                    if (selectedEndDate) {
                      dispatch(touch(FORM_NAME, 'endDate'));
                    }
                  }}
                  onInnerFocus={() => {
                    dispatch(untouch(FORM_NAME, 'startDate'));
                    dispatch(untouch(FORM_NAME, 'plantingDate'));
                    dispatch(untouch(FORM_NAME, 'endDate'));
                  }}
                  onInnerClear={() => {
                    dispatch(untouch(FORM_NAME, 'plantingDate'));
                  }}
                />
              </div>
            )}

            <div className={styles.row} ref={this.endDate}>
              <Field
                name='endDate'
                component={CustomDatePickerInput}
                formatMessage={formatMessage}
                placeholder={isLettuce ? formatMessage({ id: 'plantingCycles.lettuceEndDate' }) : formatMessage({ id: 'plantingCycles.endDate' })}
                onInnerBlur={(e) => {
                  if (!e) {
                    dispatch(untouch(FORM_NAME, 'endDate'));
                  }
                  if (selectedPlantingDate) {
                    dispatch(touch(FORM_NAME, 'plantingDate'));
                  }
                  if (selectedStartDate) {
                    dispatch(touch(FORM_NAME, 'startDate'));
                  }
                }}
                onInnerFocus={() => {
                  dispatch(untouch(FORM_NAME, 'startDate'));
                  dispatch(untouch(FORM_NAME, 'plantingDate'));
                  dispatch(untouch(FORM_NAME, 'endDate'));
                }}
                onInnerClear={() => { dispatch(untouch(FORM_NAME, 'endDate')); }}
              />
            </div>
          </div>

          {!isLettuce && (
            <FieldArray
              ref={this.plantDensity}
              name='plantDensityChanges'
              component={PlantDensityChanges}
              dispatch={dispatch}
              selectedPlantingDate={selectedPlantingDate}
              selectedPlantDensity={selectedPlantDensity}
              selectedEndDate={selectedEndDate}
            />
          )}

          <div className={styles.controls}>
            <div>
              {mode !== 'edit' && (
                <button
                  className={classnames(styles.back)}
                  tabIndex={0}
                  type='button'
                  onClick={this.handlerBackStep}
                >
                  <span className={styles.backIcon}>
                    <ArrowBackIcon />
                  </span>
                  <span className={styles.backText}>
                    {formatMessage({ id: 'button.back' })}
                  </span>
                </button>
              )}
            </div>
            <div className={styles.flexRow}>
              <BigButton
                className={styles.button}
                title={formatMessage({ id: 'plantingCycles.cancel' })}
                type='button'
                onClick={() => {
                  history.push(pageToGoBack);
                }}
                theme='light'
              />
              <BigButton
                className={styles.button}
                title={title}
                type='submit'
                theme='dark'
                isLoading={isLoading}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  warn,
})(injectIntl(withRouter(PlantingCycleForm)));
