import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape } from 'react-intl';
import { reduxForm, change } from 'redux-form';

import {
  groupBy, find, uniqBy, get, sortBy,
} from 'lodash';

import { getMlName, getProductTypeName } from '../../../../../helpers/getVarietyName';

import CustomLightSelectInput from '../../../../CustomLightSelectInput';
import CustomNumberInput from '../../../../CustomNumberInput';
import DropdownMenu from '../../../../DropdownMenu';
import MultilevelMenu from '../../../../MultilevelMenu';

import styles from './ProductMultilevelMenu.module.css';

class ProductMultilevelMenu extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    dispatch: PropTypes.func.isRequired,
    fruitClasses: PropTypes.array,
    varieties: PropTypes.array,
    disabled: PropTypes.bool,
    onlyContent: PropTypes.bool,

    onSelectOption: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    fruitClasses: [],
    varieties: [],
    disabled: false,
    onlyContent: false,
    onCancel: () => {},
  };

  state = {
    closeDropdown: false,
  };

  getTargetWeightField = () => {
    const { intl: { formatMessage } } = this.props;

    const targetWeightText = `${formatMessage({ id: 'plantingCycles.targetWeight' })}`;

    return {
      id: 'targetWeight',
      name: targetWeightText,
      component: CustomNumberInput,
      props: {
        mode: 'mini',
        placeholder: targetWeightText,
        labelPath: 'name',
        valuePath: 'id',
        titlePath: 'name',
        buttonLabelPath: 'name',
        isSearchable: true,
        allowNegative: false,
        forcedDecimalScale: 0,
        forceDecimalScale: false,
      },
      isRequired: true,
    };
  };

  getFruitClassField = (formId, varietiesItems) => {
    const { intl: { formatMessage, locale }, fruitClasses, dispatch } = this.props;

    const fruitClassText = `${formatMessage({ id: 'planFact.type' })}*`;

    const options = uniqBy(varietiesItems.map((item) => {
      const fruitClassId = item.attributes.fruitClass;
      const currentFruitClass = find(fruitClasses, { id: fruitClassId });

      return {
        id: fruitClassId,
        name: getMlName(currentFruitClass, locale) || formatMessage({ id: 'fruitClass.other' }),
      };
    }), 'id');

    const sortedOptions = sortBy(options, 'name');

    return {
      id: 'type',
      name: fruitClassText,
      component: CustomLightSelectInput,
      props: {
        placeholder: fruitClassText,
        labelPath: 'name',
        valuePath: 'id',
        titlePath: 'name',
        buttonLabelPath: 'name',
        empty: formatMessage({ id: 'graphs.sidePanel.noSearchResult' }),
        isSearchable: true,
        onInnerChange: () => dispatch(change(formId, 'variety', null)),
      },
      isRequired: true,
      options: sortedOptions,
    };
  };

  getVarietyField = (formId, varietiesItems) => {
    const {
      intl, intl: { formatMessage, locale }, fruitClasses, dispatch
    } = this.props;

    const varietyText = `${formatMessage({ id: 'plans.variety' })}*`;

    const options = varietiesItems.map((item) => {
      const fruitClassId = item.attributes.fruitClass;
      const currentFruitClass = find(fruitClasses, { id: fruitClassId });

      return {
        id: item.id,
        name: getProductTypeName({ intl, variety: item, fruitClass: currentFruitClass }) || formatMessage({ id: 'fruitClass.other' }),
        buttonTitle: getMlName(item, locale) || formatMessage({ id: 'fruitClass.other' }),
      };
    });

    const sortedOptions = sortBy(options, 'name');

    return {
      id: 'variety',
      name: varietyText,
      component: CustomLightSelectInput,
      props: {
        placeholder: varietyText,
        labelPath: 'name',
        valuePath: 'id',
        titlePath: 'name',
        buttonLabelPath: 'buttonTitle',
        isSearchable: true,
        empty: formatMessage({ id: 'graphs.sidePanel.noSearchResult' }),
        onInnerChange: ({ value }) => {
          const currentVariety = find(varietiesItems, { id: value });
          const newFruitClass = get(currentVariety, 'attributes.fruitClass');

          dispatch(change(formId, 'type', newFruitClass));
        }
      },
      isRequired: true,
      options: sortedOptions,
    };
  };

  getFields = (formId, currentSpecies, varietiesItems) => {
    const fruitClassField = this.getFruitClassField(formId, varietiesItems);
    const varietyField = this.getVarietyField(formId, varietiesItems);

    if (currentSpecies === 'lettuce') {
      const targetWeightField = this.getTargetWeightField();

      return [varietyField, fruitClassField, targetWeightField];
    }

    return [varietyField, fruitClassField];
  };

  getForm = (currentSpecies, varietiesItems) => {
    const { intl: { formatMessage }, } = this.props;
    const formId = `micro-form-${currentSpecies}`;

    return {
      id: formId,
      // id: 'productMultilevelForm',
      cancelTitle: formatMessage({ id: 'button.cancel' }),
      submitTitle: formatMessage({ id: 'button.apply' }),
      onSubmit: this.handlerOnSubmit,
      onCancel: this.handlerOnCancel,
      validate: this.handlerOnValidate,
      fields: this.getFields(formId, currentSpecies, varietiesItems),
    };
  };

  getSpeciesItems = (groupedVarieties) => {
    const { intl: { formatMessage } } = this.props;

    return Object.keys(groupedVarieties).map(key => ({
      id: key,
      title: formatMessage({ id: `plantingCycles.speciesPlural.${key}` }),
      form: this.getForm(key, groupedVarieties[key]),
    }));
  };

  getSortedItems = (items) => {
    const sortedTopLevel = sortBy(items, 'title');

    return sortedTopLevel;
  };

  handlerOnSubmit = (values) => {
    const { onSelectOption, varieties } = this.props;

    this.handlerCloseMenu();

    const fruitClassCode = get(values, 'type');
    const varietyId = get(values, 'variety');
    const targetWeight = get(values, 'targetWeight');
    const currentVariety = find(varieties, { id: varietyId });
    const species = get(currentVariety, 'attributes.species');

    return onSelectOption(fruitClassCode, varietyId, targetWeight, species);
  };

  handlerOnCancel = () => {
    const { onCancel } = this.props;

    this.handlerCloseMenu();

    onCancel();

    return null;
  };

  handlerOnValidate = (values) => {
    const { intl: { formatMessage }, varieties } = this.props;

    const errors = {};

    if (!values.variety) {
      errors.variety = formatMessage({ id: 'plantingCycles.errors.chooseVariety' });
    }

    if (!values.type) {
      errors.type = formatMessage({ id: 'plantingCycles.errors.chooseType' });
    }

    if (values.variety) {
      const currentVariety = find(varieties, { id: values.variety });
      const currentSpecies = get(currentVariety, 'attributes.species');

      if (!values.targetWeight && currentSpecies === 'lettuce') {
        errors.targetWeight = formatMessage({ id: 'plantingCycles.errors.chooseTargetWeight' });
      }
    }

    return errors;
  };

  handlerCloseMenu = () => this.setState({ closeDropdown: true });

  renderButton = () => (
    <div
      className={styles.addButton}
      onClick={() => this.setState({ closeDropdown: false })}
      role='button'
      tabIndex={0}
    >
      +
    </div>
  );

  render() {
    const {
      onlyContent,
      disabled,
      varieties,
    } = this.props;
    const { closeDropdown } = this.state;


    const groupedVarieties = groupBy(varieties, 'attributes.species');
    const generatedItems = this.getSpeciesItems(groupedVarieties);

    const sortedItems = this.getSortedItems(generatedItems);

    if (onlyContent) {
      return (
        <MultilevelMenu
          className={styles.multilevelMenu}
          items={sortedItems}
        />
      );
    }

    return (
      <div className={styles.productMenu}>
        <DropdownMenu
          buttonElement={this.renderButton()}
          className={styles.dropdownMenu}
          contentClassName={styles.dropdownContent}
          wrapperClassName={styles.dropdownWrapper}
          buttonClassName={styles.dropdownButton}
          disabled={disabled}
          closeDropdown={closeDropdown}
        >
          <MultilevelMenu
            className={styles.multilevelMenu}
            items={sortedItems}
          />
        </DropdownMenu>
      </div>
    );
  }
}

export default reduxForm({
  form: 'formWrapper'
})(ProductMultilevelMenu);
