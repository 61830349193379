import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import { find, isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { getMlNameDirect } from 'helpers/getVarietyName';
import { API_DATE_FORMAT } from 'helpers/defaultDates';
import getDateFormat from 'helpers/getDateFormat';

import DefaultSidePanel from 'components/DefaultSidePanel';
import DropdownMenu from 'components/DropdownMenu';
import BigButton from 'components/BigButton';
import FolderIcon from 'components/Icons/FolderIcon';

import styles from './DigitalTwinSimulationsList.module.css';

const RenderEmtyState = ({ intl }) => {
  const { formatMessage } = intl;

  return (
    <div className={styles.emptyState}>
      <div>
        <FolderIcon className={styles.folderIcon} />
        <div className={styles.emptyStateText}>
          {formatMessage({ id: 'cropModel.savedModels' })}
        </div>
      </div>
    </div>
  );
};

RenderEmtyState.propTypes = {
  intl: intlShape.isRequired,
};

const RenderSimulationCard = ({
  intl,
  simulation,
  hideDeleteButton,
  varieties,
  onSimulationSelect,
  onSimulationEdit,
  onSimulationDelete,
}) => {
  const { formatMessage, locale } = intl;

  const {
    id, name, description, varietyId, seedingDate, endDate
  } = simulation;

  const getFormattedDates = (start, end) => {
    if (!start || !end) {
      return null;
    }
    const momentSeedingDate = moment(start, API_DATE_FORMAT);
    const momentEndDate = moment(end, API_DATE_FORMAT);
    const isSameYear = momentSeedingDate.year() === momentEndDate.year();
    const formattedSeedingDate = momentSeedingDate.format(getDateFormat(isSameYear ? 'll' : 'lll'));
    const formattedEndDate = momentEndDate.format(getDateFormat('lll'));

    return `${formattedSeedingDate} – ${formattedEndDate}`;
  };

  const formattedDate = getFormattedDates(seedingDate, endDate);

  const simulationVariety = find(varieties, { id: varietyId });
  const label = getMlNameDirect(simulationVariety, locale);

  const onEditClick = (e) => {
    e.stopPropagation();

    onSimulationEdit(simulation);
  };

  const onDeleteClick = (e) => {
    e.stopPropagation();

    onSimulationDelete({ simulationId: id });
  };

  return (
    <div
      className={styles.simulationCard}
      onClick={() => onSimulationSelect({ simulationId: id })}
      tabIndex={0}
      role='button'
    >
      <div className={styles.simulationCardInfo}>
        <div className={styles.simulationCardHeader}>{name}</div>
        <div className={styles.simulationCardLabelWrapper}>
          <span className={styles.simulationCardLabelVariety}>{label}</span>
          {formattedDate && (
            <>
              <span className={styles.dot} />
              <span className={styles.simulationCardLabelDate}>{formattedDate}</span>
            </>
          )}
        </div>
        {description && <div className={styles.simulationCardDesc}>{description}</div>}
      </div>
      <DropdownMenu className={styles.simulationMenu}>
        <div>
          <BigButton
            className={styles.dropdownItem}
            titleClassName={styles.dropdownItemTitle}
            onClick={onEditClick}
            theme='menuItem'
            title={formatMessage({ id: 'harvest.edit' })}
          />
          {!hideDeleteButton && (
            <BigButton
              className={styles.dropdownItem}
              titleClassName={styles.dropdownItemTitle}
              onClick={onDeleteClick}
              theme='menuItem'
              title={formatMessage({ id: 'harvest.delete' })}
            />
          )}
        </div>
      </DropdownMenu>
    </div>
  );
};

RenderSimulationCard.propTypes = {
  intl: intlShape.isRequired,
  simulation: PropTypes.object.isRequired,
  varieties: PropTypes.array.isRequired,
  hideDeleteButton: PropTypes.bool,
  onSimulationSelect: PropTypes.func.isRequired,
  onSimulationEdit: PropTypes.func.isRequired,
  onSimulationDelete: PropTypes.func.isRequired,
};

RenderSimulationCard.defaultProps = {
  hideDeleteButton: false,
};

const RenderSimulationsGroup = ({
  intl,
  simulations,
  header,
  varieties,
  hideDeleteButton,
  onSimulationSelect,
  onSimulationEdit,
  onSimulationDelete,
  withEmptyState,
}) => {
  const emptySimulationsList = isEmpty(simulations);

  if (!withEmptyState && emptySimulationsList) {
    return null;
  }

  return (
    <div>
      <div className={styles.simulationsGroupHeader}>{header}</div>
      <div>
        {!emptySimulationsList && simulations.map(simulation => (
          <RenderSimulationCard
            intl={intl}
            key={`simulation-${simulation.id}`}
            simulation={simulation}
            hideDeleteButton={hideDeleteButton}
            varieties={varieties}
            onSimulationSelect={onSimulationSelect}
            onSimulationEdit={onSimulationEdit}
            onSimulationDelete={onSimulationDelete}
          />
        ))}
        {(withEmptyState && emptySimulationsList) && (
          <RenderEmtyState intl={intl} />
        )}
      </div>
    </div>
  );
};

RenderSimulationsGroup.propTypes = {
  intl: intlShape.isRequired,
  header: PropTypes.string.isRequired,
  simulations: PropTypes.array,
  varieties: PropTypes.array.isRequired,
  hideDeleteButton: PropTypes.bool,
  withEmptyState: PropTypes.bool,
  onSimulationSelect: PropTypes.func.isRequired,
  onSimulationEdit: PropTypes.func.isRequired,
  onSimulationDelete: PropTypes.func.isRequired,
};

RenderSimulationsGroup.defaultProps = {
  simulations: [],
  hideDeleteButton: false,
  withEmptyState: false,
};

const DigitalTwinSimulationsList = ({
  intl,
  isOpened,
  onClose,
  simulationsList,
  varieties,
  onSimulationSelect,
  onSimulationEdit,
  onSimulationDelete,
}) => {
  const { formatMessage } = intl;

  const templates = simulationsList.filter(simulation => simulation.template);
  const customSimulations = simulationsList.filter(simulation => !simulation.template);

  const isAllSimulationsEmpty = isEmpty(templates) && isEmpty(customSimulations);

  return (
    <DefaultSidePanel
      isOpened={isOpened}
      onClose={onClose}
      headerText={formatMessage({ id: 'cropModel.cropModels' })}
      contentClassName={styles.simulationsListContainer}
    >
      {isAllSimulationsEmpty ?
        <RenderEmtyState intl={intl} />
        :
        (
          <>
            <RenderSimulationsGroup
              intl={intl}
              header={formatMessage({ id: 'cropModel.pylotModels' })}
              simulations={templates}
              varieties={varieties}
              onSimulationSelect={onSimulationSelect}
              onSimulationEdit={onSimulationEdit}
              onSimulationDelete={onSimulationDelete}
              hideDeleteButton
            />
            <RenderSimulationsGroup
              intl={intl}
              header={formatMessage({ id: 'cropModel.myModels' })}
              simulations={customSimulations}
              varieties={varieties}
              onSimulationSelect={onSimulationSelect}
              onSimulationEdit={onSimulationEdit}
              onSimulationDelete={onSimulationDelete}
              withEmptyState
            />
          </>
        )}
    </DefaultSidePanel>
  );
};

DigitalTwinSimulationsList.propTypes = {
  intl: intlShape.isRequired,
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  simulationsList: PropTypes.array.isRequired,
  varieties: PropTypes.array.isRequired,
  onSimulationSelect: PropTypes.func.isRequired,
  onSimulationEdit: PropTypes.func.isRequired,
  onSimulationDelete: PropTypes.func.isRequired,
};

export default injectIntl(DigitalTwinSimulationsList);
