import Typography from 'components/Typography';
import { FormattedMessage } from 'react-intl';
import React from 'react';

import styles from './NotSupportedPlaceholder.module.css';

const NotSupportedPlaceholder = () => (
  <div className={styles.notSupported}>
    <div>
      <Typography variant='h2'>
        <FormattedMessage id='unrealizedPotential.modelNotReadyTitle' />
      </Typography>
    </div>
    <div>
      <Typography variant='subtitle1'>
        <FormattedMessage id='unrealizedPotential.modelNotReadyDescription' />
      </Typography>
    </div>
  </div>
);

export default NotSupportedPlaceholder;
