import { handleActions } from 'redux-actions';

import {
  requestSendResetEmail,
  receiveSendResetEmail,
  cleanResetEmail,

  requestResetPassword,
  receiveResetPassword,

  requestValidateToken,
  receiveValidateToken,

  receiveUpdatePassword,
  requestUpdatePassword,

  requestSendingCode,
  receiveSendingCode,

  clearVerificationFormState,

  requestVerifyCode,
  receiveVerifyCode,
  receiveVerifyCodeError,

  clearVerificationErrors,
  setVerificationError,

  setLoginError,
  clearLoginError,
} from './actions';

const initialState = {
  isResetPasswordFetching: false,
  isResetEmailFetching: false,
  emailToReset: null,
  isTokenValidityFetching: false,
  tokenValidation: null,
  isPasswordUpdateFetching: false,
  isSendingCodeFetching: false,
  sendingCodeInfo: undefined,
  authTicketInfo: undefined,
  isVerifyCodeFetching: false,
  verifyCodeError: undefined,
  loginError: undefined,
};

export default handleActions({
  [setLoginError](state, action) {
    return {
      ...state,
      loginError: action.payload,
    };
  },
  [clearLoginError](state) {
    return {
      ...state,
      loginError: undefined,
    };
  },

  [clearVerificationErrors](state) {
    return {
      ...state,
      verifyCodeError: undefined,
    };
  },
  [setVerificationError](state, action) {
    return {
      ...state,
      verifyCodeError: action.payload,
    };
  },
  [requestVerifyCode](state) {
    return {
      ...state,
      isVerifyCodeFetching: true,
    };
  },
  [receiveVerifyCode](state) {
    return {
      ...state,
      isVerifyCodeFetching: false,

      authTicketInfo: undefined,
      sendingCodeInfo: undefined,
    };
  },
  [receiveVerifyCodeError](state, action) {
    return {
      ...state,
      isVerifyCodeFetching: false,

      verifyCodeError: action.payload,
    };
  },
  [clearVerificationFormState](state) {
    return {
      ...state,
      authTicketInfo: undefined,
      sendingCodeInfo: undefined,
    };
  },
  [requestSendingCode](state, action) {
    return {
      ...state,
      isSendingCodeInfoFetching: true,
      authTicketInfo: {
        receivedAt: new Date(),

        ...action.payload,
      }
    };
  },
  [receiveSendingCode](state, action) {
    return {
      ...state,
      isSendingCodeInfoFetching: false,
      sendingCodeInfo: {
        receivedAt: new Date(),

        ...action.payload,
      },
    };
  },

  [requestSendResetEmail](state) {
    return {
      ...state,
      isResetEmailFetching: true,
    };
  },
  [receiveSendResetEmail](state, action) {
    return {
      ...state,
      isResetEmailFetching: false,
      emailToReset: action.payload.emailToReset,
    };
  },
  [cleanResetEmail](state) {
    return {
      ...state,
      isResetEmailFetching: false,
      emailToReset: null,
      tokenValidation: null,
    };
  },
  [requestResetPassword](state) {
    return {
      ...state,
      isResetPasswordFetching: true,
    };
  },
  [receiveResetPassword](state, action) {
    return {
      ...state,
      isResetPasswordFetching: false,
      result: action.payload,
    };
  },
  [requestValidateToken](state) {
    return {
      ...state,
      isTokenValidityFetching: true,
    };
  },
  [receiveValidateToken](state, action) {
    return {
      ...state,
      isTokenValidityFetching: false,
      tokenValidation: action.payload.tokenValidation,
    };
  },
  [requestUpdatePassword](state) {
    return {
      ...state,
      isPasswordUpdateFetching: true,
    };
  },
  [receiveUpdatePassword](state, action) {
    return {
      ...state,
      isPasswordUpdateFetching: false,
      result: action.payload,
    };
  },
}, initialState);
