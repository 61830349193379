import 'whatwg-fetch';
import debug from 'debug';
import { get } from 'lodash';
import storageWrapper from '../helpers/storageWrapper';
import getDatesRange from '../helpers/getDatesRange';
import initAxiosInstanse from './axios';


const log = debug('api');
const safeLocalStorage = storageWrapper.get('localStorage');


export default class Api {
  initializeHeaders = () => {
    const jwt = safeLocalStorage.getItem('jwt');
    const locale = safeLocalStorage.getItem('locale');

    const headers = new Headers();

    headers.append('X-Lang', locale);
    headers.append('Authorization', `JWT ${jwt}`);
    headers.append('Content-Type', 'application/json');

    return headers;
  };

  getMetrics = async (
    {
      location,
      compartment,
      subNode
    }
  ) => {
    log('Start getMetrics');

    const start = performance.now();

    try {
      if (location) {
        let url = `${window.API}/locations/${location.id}/metrics`;

        if (compartment && compartment.id !== '#') {
          url += `?compartmentId=${compartment.id}`;
        }

        if (subNode && subNode.id !== '#') {
          url += `&subNodeId=${subNode.id}`;
        }

        const response = await initAxiosInstanse().get(url);

        log(`Complete getMetrics in ${performance.now() - start}ms`);

        const { status } = response;

        if (Math.round(status / 100) === 2) {
          return response.data;
        }

        return { error: new Error(status), data: null };
      }

      return { error: new Error('Location is undefined'), data: null };
    } catch (error) {
      return { error, data: [] };
    }
  };

  getCropsMetrics = async ({ id }) => {
    log('Start getCropsMetrics');

    const start = performance.now();

    try {
      const headers = this.initializeHeaders();
      const payload = { headers };
      const url = `${window.API}/planting-cycles/${id}/crops-metrics`;
      const response = await fetch(url, payload);

      log(`Complete getCropsMetrics in ${performance.now() - start}ms`);

      const { status } = response;

      if (Math.round(status / 100) === 2) {
        return response.json();
      }

      return { error: new Error(status), data: null };
    } catch (error) {
      return { error, data: [] };
    }
  };

  getCropsObservations = async ({ id, date }) => {
    log('Start getCropsObservations');

    const start = performance.now();

    try {
      const headers = this.initializeHeaders();
      const payload = { headers };
      const url = `${window.API}/planting-cycles/${id}/crops-observations${date ? `?date=${date.format('YYYY-MM-DD')}` : ''}`;
      const response = await fetch(url, payload);

      log(`Complete getCropsObservations in ${performance.now() - start}ms`);

      const { status } = response;

      if (Math.round(status / 100) === 2) {
        return response.json();
      }

      return { error: new Error(status), data: [] };
    } catch (error) {
      return { error, data: [] };
    }
  };

  createCropsObservations = async ({ plantingCycle, data }) => {
    log('Start createCropsObservations');

    const start = performance.now();

    try {
      const { id: plantingCycleId } = plantingCycle;
      const headers = this.initializeHeaders();
      const payload = { headers, method: 'POST', body: JSON.stringify(data) };
      const url = `${window.API}/planting-cycles/${plantingCycleId}/crops-observations`;
      const response = await fetch(url, payload);

      log(`Complete createCropsObservations in ${performance.now() - start}ms`);

      const { status } = response;

      if (Math.round(status / 100) === 2) {
        return response.json();
      }

      const { text } = await response.json();

      return { error: text || status, data: null };
    } catch (error) {
      return { error, data: [] };
    }
  };


  createPlantingCycle = async ({ data }) => {
    log('Start createPlantingCycle');

    const start = performance.now();
    const jwt = safeLocalStorage.getItem('jwt');
    const locale = safeLocalStorage.getItem('locale');

    try {
      const responseNewId = await fetch(`${window.API}/planting-cycles/new-id`, {
        headers: {
          Authorization: `JWT ${jwt}`,
          'X-Lang': locale,
        },
        method: 'GET',
        credentials: 'include',
      });

      if (Math.round(responseNewId.status / 100) === 2) {
        const { id: plantingCycleId, text: responseNewIdText } = await responseNewId.json();

        const headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', `JWT ${jwt}`);
        headers.append('X-Lang', locale);

        if (plantingCycleId) {
          const response = await fetch(`${window.API}/planting-cycles/${plantingCycleId}`, {
            headers,
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
          });

          log(`Complete createPlantingCycle in ${performance.now() - start}ms`);

          const { status } = response;
          if (Math.round(status / 100) === 2) {
            return response.json();
          }

          const { text } = await response.json();

          return { error: text || status, data: null };
        }

        return { error: responseNewIdText || responseNewId.status, data: null };
      }

      return { error: responseNewId.status, data: null };
    } catch (error) {
      return { error, data: [] };
    }
  };

  updatePlantingCycle = async ({ id: plantingCycleId, data }) => {
    log('Start updatePlantingCycle');

    const start = performance.now();
    const jwt = safeLocalStorage.getItem('jwt');
    const locale = safeLocalStorage.getItem('locale');

    try {
      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `JWT ${jwt}`);
      headers.append('X-Lang', locale);

      if (plantingCycleId) {
        const response = await fetch(`${window.API}/planting-cycles/${plantingCycleId}`, {
          headers,
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(data)
        });

        log(`Complete updatePlantingCycle in ${performance.now() - start}ms`);

        const { status } = response;
        if (Math.round(status / 100) === 2) {
          return response.json();
        }

        const { text, errorClass } = await response.json();

        return { error: text || status, errorClass, data: null };
      }

      return { error: 'plantingCycleId is null', data: null };
    } catch (error) {
      return { error, data: [] };
    }
  };

  getPlantingCycle = async ({ id: plantingCycleId }) => {
    log('Start getPlantingCycle');

    const start = performance.now();
    const jwt = safeLocalStorage.getItem('jwt');
    const locale = safeLocalStorage.getItem('locale');

    try {
      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `JWT ${jwt}`);
      headers.append('X-Lang', locale);

      if (plantingCycleId) {
        const response = await fetch(`${window.API}/planting-cycles/${plantingCycleId}`, {
          headers,
          method: 'GET',
          credentials: 'include',
        });

        log(`Complete getPlantingCycle in ${performance.now() - start}ms`);

        const { status } = response;
        if (Math.round(status / 100) === 2) {
          return response.json();
        }

        const { text } = await response.json();

        return { error: text || status, data: null };
      }

      return { error: 'plantingCycleId is null', data: null };
    } catch (error) {
      return { error, data: [] };
    }
  };

  getLocationCycle = async ({ cycleId, locationId }) => {
    log('Start getLocationCycle');

    const start = performance.now();
    const jwt = safeLocalStorage.getItem('jwt');
    const locale = safeLocalStorage.getItem('locale');

    try {
      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `JWT ${jwt}`);
      headers.append('X-Lang', locale);

      if (cycleId) {
        const response = await fetch(`${window.API}/locations/${locationId}/kpi/planting-cycle-overview/${cycleId}`, {
          headers,
          method: 'GET',
          credentials: 'include',
        });

        log(`Complete getLocationCycle in ${performance.now() - start}ms`);

        const { status } = response;
        if (Math.round(status / 100) === 2) {
          return response.json();
        }

        const { text } = await response.json();

        return { error: text || status, data: null };
      }

      return { error: 'cycleId is null', data: null };
    } catch (error) {
      return { error, data: [] };
    }
  };


  getGraphDataPoints = async (
    {
      location,
      xRange,
      xRangeEnd,
      xRangeLengthInMins,
      graphPresetThreshold,
      graphPresetMetrics,
      returnIncidents,
    }
  ) => {
    log('Start getGraphDataPoints');

    const start = performance.now();

    try {
      const { xTo, xFrom } = getDatesRange({
        location,
        xRange,
        xRangeEnd,
        xRangeLengthInMins,
        returnIncidents,
      });

      const nodeMetrics = graphPresetMetrics.map(item => ({
        locationId: item.attributes.location && item.attributes.location.id,
        compartmentId: item.attributes.compartment && item.attributes.compartment.id,
        subNodeId: item.attributes.subNode && item.attributes.subNode.id,
        metricId: get(item, 'relationships.metric.data.0.id'),
      }));

      const headers = this.initializeHeaders();

      if (location) {
        const url = `${window.API}/graph-data-points`;
        const method = 'POST';
        const body = JSON.stringify({
          xFrom: Math.round(+xFrom / 60 / 1000),
          xTo: Math.round(+xTo / 60 / 1000),
          nodeMetrics,
          scaleAsRoundToNMinutes: graphPresetThreshold,
          returnIncidents,
          includeMetrics: true,
        });

        const payload = { headers, method, body };
        const response = await fetch(url, payload);

        log(`Complete getGraphDataPoints in ${performance.now() - start}ms`);

        const { status } = response;

        if (Math.round(status / 100) === 2) {
          return response.json();
        }

        return { error: new Error(status), data: null };
      }

      return { error: new Error('Location is undefined'), data: null };
    } catch (error) {
      return { error, data: [] };
    }
  };

  getGraphMetrics = async (
    {
      id,
    }
  ) => {
    log('Start getGraphMetrics');

    const start = performance.now();

    try {
      if (id) {
        const url = `${window.API}/graph-presets/${id}/graph-metrics?include=metric,subNode,location,compartment`;
        const response = await initAxiosInstanse().get(url);

        log(`Complete getGraphMetrics in ${performance.now() - start}ms`);

        const { status } = response;

        if (Math.round(status / 100) === 2) {
          return response.data;
        }

        return { error: new Error(status), data: null };
      }

      return { error: new Error('Id is undefined'), data: null };
    } catch (error) {
      return { error, data: [] };
    }
  };
}
