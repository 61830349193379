import { connect } from 'react-redux';
import { trackPageViewDashboards } from 'store/company/actions';
import { getIsEssentialPricePlan } from 'store/company/selectors';

import Dashboard from '../components/Dashboards';

const mapDispatchToProps = {
  trackPageViewDashboards,
};

const mapStateToProps = (state, props) => {
  const { intl } = state;
  const { match } = props;

  return {
    ...props,
    ...intl,
    tabName: match.params.tabName,
    defaultOpen: !match.params.tabName,
    isEssentialPricePlan: getIsEssentialPricePlan(state, props),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
