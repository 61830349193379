import { createAction } from 'redux-actions';

export const requestCropReportsList = createAction('REQUEST_CROP_REPORTS_LIST');
export const receiveCropReportsList = createAction('RECEIVE_CROP_REPORTS_LIST');

export const requestCropReport = createAction('REQUEST_CROP_REPORT');
export const receiveCropReport = createAction('RECEIVE_CROP_REPORT');

export const requestCropReportsSettings = createAction('REQUEST_CROP_REPORTS_SETTINGS');
export const receiveCropReportsSettings = createAction('RECEIVE_CROP_REPORTS_SETTINGS');
