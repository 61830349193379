import { get, first } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Layout from '../../layouts/Layout/containers/LayoutContainer';

import DashboardsRoutes from '../DashboardsRoutes';
import CropsRoutes from '../CropsRoutes';
import PlansRoutes from '../PlansRoutes';
import NotFound from '../NotFound';
import Terms from '../Terms';
import SettingsRoutes from '../SettingsRoutes';
import ResourcesRoutes from '../ResourcesRoutes';
import AnalysisRoutes from '../AnalysisRoutes';

import storageWrapper from '../../helpers/storageWrapper';

const safeLocalStorage = storageWrapper.get('localStorage');

export default class MainRoutes extends PureComponent {
  static propTypes = {
    getLocation: PropTypes.func.isRequired,
    organization: PropTypes.object,
    locations: PropTypes.array,
    match: PropTypes.object.isRequired,
  };

  static defaultProps = {
    organization: null,
    locations: null,
  };

  componentDidMount() {
    const {
      getLocation,
      locations,
      organization,
      match,
    } = this.props;

    const { params: { organizationSlug } } = match;

    if (!organization || organizationSlug !== get(organization, 'attributes.slug')) {
      const location = locations.find(item => item?.attributes?.slug === organizationSlug);
      if (location) {
        getLocation(location);
      }
    }
  }

  render() {
    const { organization, locations, match } = this.props;
    const { params: { organizationSlug } } = match;

    const savedLocationId = safeLocalStorage.getItem('location');
    const savedLocation = locations.find(item =>
      savedLocationId && item && item.id.toString() === savedLocationId.toString());
    const firstLocation = first(locations);

    if (!organization) {
      const redirectLocation = savedLocation || firstLocation;
      const redirectSlug = get(redirectLocation, 'attributes.slug');

      return (
        <Layout isStatic>
          <Switch>
            <Route
              key='old-dashboards'
              path='/dashboards'
              render={() => <Redirect to={`/${redirectSlug}/dashboards`} />}
            />
            <Route
              key='old-crops'
              path='/crops'
              render={() => <Redirect to={`/${redirectSlug}/crops`} />}
            />
            <Route
              key='old-graphs'
              path='/graphs'
              render={() => <Redirect to={`/${redirectSlug}/graphs`} />}
            />
            <Route
              key='old-terms'
              path='/terms'
              render={() => <Redirect to={`/${redirectSlug}/terms/general-terms-and-conditions`} />}
            />
            <Route
              key='not-found'
              path='*'
              exact
              component={NotFound}
            />
          </Switch>
        </Layout>
      );
    }

    return (
      <Layout>
        <Switch>
          <Route
            key='old-dashboards-climate-insights'
            path='/:organizationSlug/dashboards/climate-insights'
            render={() => <Redirect to={`/${organizationSlug}/incidents`} />}
          />
          <Route
            key='dashboards'
            path='/:organizationSlug/dashboards'
            component={DashboardsRoutes}
          />
          <Route
            key='crops'
            path='/:organizationSlug/crops'
            component={CropsRoutes}
          />
          <Route
            key='plans'
            path='/:organizationSlug/plans'
            component={PlansRoutes}
          />
          {/* <Route
            key='graphs'
            path='/:organizationSlug/graphs'
            exact
            component={Graphs}
          /> */}
          <Route
            key='analysis'
            path='/:organizationSlug/graphs'
            component={AnalysisRoutes}
          />
          <Route
            key='analysis'
            path='/:organizationSlug/incidents'
            component={AnalysisRoutes}
          />
          <Route
            key='default'
            path='/:organizationSlug'
            exact
            render={() => <Redirect to={`/${organizationSlug}/dashboards`} />}
          />
          <Route
            key='terms'
            path='/:organizationSlug/terms'
            component={Terms}
          />
          <Route
            key='settings'
            path='/:organizationSlug/settings'
            component={SettingsRoutes}
          />
          <Route
            key='resources'
            path='/:organizationSlug/resources'
            component={ResourcesRoutes}
          />
          <Route
            key='not-found'
            path='/:organizationSlug/*'
            exact
            component={NotFound}
          />
        </Switch>
      </Layout>
    );
  }
}
