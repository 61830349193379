import { getTableBodyRow } from 'helpers/getTableBodyRow';

/* eslint-disable import/prefer-default-export */
export function getTableBodyItems({
  reportRows = [],
  intl,
  workTypes,
  compartments,
  fruitClasses,
  varieties,
  organizationSlug,
  productGroups,
  allPlantingCycles,
  currentBreakdown,
  currentUnitKind,
  currentSpecies,
  currentFruitClassCode,
  currentVarietyId,
  roundTo,
  formatCell,
}) {
  return reportRows.map(tableRow => ({
    ...getTableBodyRow({
      tableRow,
      intl,
      workTypes,
      compartments,
      fruitClasses,
      varieties,
      organizationSlug,
      productGroups,
      allPlantingCycles,
      currentUnitKind,
      currentBreakdown,
      currentSpecies,
      currentFruitClassCode,
      currentVarietyId,
      roundTo,
      formatCell,
    }),
    subRows: tableRow.children ? getTableBodyItems({
      reportRows: tableRow.children,
      intl,
      workTypes,
      compartments,
      fruitClasses,
      varieties,
      organizationSlug,
      productGroups,
      allPlantingCycles,
      currentBreakdown,
      currentUnitKind,
      currentSpecies,
      currentFruitClassCode,
      currentVarietyId,
      roundTo,
      formatCell,
    }) : undefined,
  }));
}

export default getTableBodyItems;
