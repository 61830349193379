import classnames from 'classnames';
import PropTypes from 'prop-types';
import { get, last } from 'lodash';
import React, { Component } from 'react';
import { intlShape } from 'react-intl';
import { NavLink as RouterLink } from 'react-router-dom';

import CropSelectPanel from '../CropSelectPanel';
import PlantingCycleForm from '../PlantingCycleForm';
import CircleLoader from '../CircleLoader';

import { ReactComponent as ArrowBackIcon } from './assets/arrow_back.svg';

import loaderStyles from '../CircleLoader/CircleLoader.module.css';
import styles from './CreateCropWizard.module.css';


export default class CreateCropWizard extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    className: PropTypes.string,
    varieties: PropTypes.array,
    fruitClasses: PropTypes.array,
    species: PropTypes.array,
    organization: PropTypes.object,
    allCompartments: PropTypes.array,
    createPlantingCycleForm: PropTypes.object,
    match: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isCreatePlantingCycleFetching: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    className: null,
    varieties: [],
    fruitClasses: [],
    allCompartments: [],
    species: [],
    organization: null,
    createPlantingCycleForm: {},
    onSubmit: null,
  };

  state = {
    step: 1,
    selectedCrop: null,
  };

  handlerCropSelected = ({ crop }) => {
    this.setState({ selectedCrop: crop });
  };

  handlerSubmit = (...options) => {
    const { onSubmit } = this.props;

    if (onSubmit) {
      onSubmit(...options);
    }
  };

  handlerNextStep = () => {
    const { step } = this.state;

    this.setState({ step: step + 1 });
  };

  renderStep1 = () => {
    const { intl, match, species } = this.props;
    const { selectedCrop } = this.state;

    return (
      <CropSelectPanel
        intl={intl}
        selectedCrop={selectedCrop}
        speciesList={species}
        onSelected={this.handlerCropSelected}
        onContinue={this.handlerNextStep}
        pageToGoBack={`/${match.params.organizationSlug}/crops?fromPage=cropEdit`}
      />
    );
  };

  renderStep2 = () => {
    const {
      intl,
      fruitClasses,
      varieties,
      organization,
      allCompartments,
      createPlantingCycleForm,
      isCreatePlantingCycleFetching,
      match,
    } = this.props;

    const {
      selectedCrop,
    } = this.state;

    const compartments = allCompartments.filter(item => organization && item.relationships.location && item.relationships.location.data[0].id === organization.id);

    const initialValues = {
      plantDensityChanges: []
    };

    return (
      <PlantingCycleForm
        selectedCrop={selectedCrop}
        organization={organization}
        varieties={varieties}
        fruitClasses={fruitClasses}
        compartments={compartments}
        createPlantingCycleForm={createPlantingCycleForm}
        onSubmit={this.handlerSubmit}
        initialValues={initialValues}
        mode='create'
        isLoading={isCreatePlantingCycleFetching}
        intl={intl}
        pageToGoBack={`/${match.params.organizationSlug}/crops?fromPage=cropEdit`}
        onBackStep={() => this.setState({ step: 1 })}
      />
    );
  };

  render() {
    const {
      intl,
      className,
      organization,
      isFetching,
      match,
    } = this.props;

    const {
      step,
    } = this.state;

    const formatMessage = get(intl, 'formatMessage', ({ id }) => last(id.split('.')));

    if (isFetching || !organization) {
      return (
        <div className={styles.layout}>
          <CircleLoader
            className={loaderStyles.circleLoader}
            iconClassName={loaderStyles.circleLoaderIcon}
          />
        </div>
      );
    }

    let content;

    if (step === 2) {
      content = this.renderStep2();
    }

    if (step === 1) {
      content = this.renderStep1();
    }

    return (
      <div className={classnames(styles.layout, className)}>
        <div className={styles.body}>
          <div className={styles.controls}>
            <RouterLink
              to={`/${match.params.organizationSlug}/crops`}
              className={classnames(styles.back)}
              activeClassName={styles.routeActive}
              tabIndex={0}
              role='button'
            >
              <span className={styles.backIcon}>
                <ArrowBackIcon />
              </span>
              <span className={styles.backText}>
                {formatMessage({ id: 'plantingCycles.plantingCycles' })}
              </span>
            </RouterLink>
          </div>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              {formatMessage({ id: 'plantingCycles.createPlantingCycle' })}
            </div>
            <div className={styles.headerStep}>
              {formatMessage({ id: 'step' })}
              &nbsp;
              {step}
              &nbsp;
              {formatMessage({ id: 'of' })}
              &nbsp;
              2
            </div>
          </div>
          <div className={styles.content}>
            {content}
          </div>
        </div>
      </div>
    );
  }
}
