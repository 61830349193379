import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import { DEFAULT_ENERGY_RESOURCE_TYPE } from 'helpers/constants';

import EssentialPricePlanResources from 'components/EssentialPricePlanResources';
import Energy from 'components/Energy';
import EnergyAdd from 'components/EnergyAdd';
import LaborWorks from 'components/LaborWorks';
import LaborWorksAdd from 'components/LaborWorksAdd';


export default class ResourcesRoutes extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    isEssentialPricePlan: PropTypes.bool.isRequired,
  };

  render() {
    const { match, isEssentialPricePlan } = this.props;
    const { params: { organizationSlug } } = match;

    if (isEssentialPricePlan) {
      return (
        <EssentialPricePlanResources />
      );
    }

    return (
      <Switch>
        <Route path='/:organizationSlug/resources/energy/:resourceType/add' exact component={EnergyAdd} />
        <Route path='/:organizationSlug/resources/energy/:resourceType' exact component={Energy} />
        <Route path='/:organizationSlug/resources/labor/works/add' exact component={LaborWorksAdd} />
        <Route path='/:organizationSlug/resources/labor/works' exact component={LaborWorks} />
        <Route path='/:organizationSlug/resources/labor' exact render={() => <Redirect to={`/${organizationSlug}/resources/labor/works`} />} />
        <Route path='/:organizationSlug/resources/energy' exact render={() => <Redirect to={`/${organizationSlug}/resources/energy/${DEFAULT_ENERGY_RESOURCE_TYPE}?defaultOpen=true`} />} />
        <Route path='/:organizationSlug/resources' exact render={() => <Redirect to={`/${organizationSlug}/resources/energy/${DEFAULT_ENERGY_RESOURCE_TYPE}?defaultOpen=true`} />} />
        <Redirect to='/:organizationSlug/404' />
      </Switch>
    );
  }
}
