import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { line } from 'd3-shape';
import { textPropsByEngine, axisThemePropType, labelsThemePropType } from '@nivo/core';
import { arcPropType } from './props';
import { computeRadialLabels } from './compute';

const lineGenerator = line()
  .x(d => d.x)
  .y(d => d.y);

export default class PieRadialLabels extends Component {
    static propTypes = {
      arcs: PropTypes.arrayOf(arcPropType).isRequired,
      label: PropTypes.func.isRequired,
      skipAngle: PropTypes.number,
      radius: PropTypes.number.isRequired,
      linkOffset: PropTypes.number,
      linkDiagonalLength: PropTypes.number,
      linkHorizontalLength: PropTypes.number,
      linkStrokeWidth: PropTypes.number,
      textXOffset: PropTypes.number,
      textColor: PropTypes.func.isRequired,
      linkColor: PropTypes.func.isRequired,
      theme: PropTypes.shape({
        axis: axisThemePropType.isRequired,
        labels: labelsThemePropType.isRequired,
      }).isRequired,
    }

    static defaultProps = {
      skipAngle: 0,
      linkOffset: 0,
      linkDiagonalLength: 16,
      linkHorizontalLength: 24,
      linkStrokeWidth: 1,
      textXOffset: 6,
    }

    render() {
      const {
        arcs,
        label,
        radius,
        skipAngle,
        linkOffset,
        linkDiagonalLength,
        linkHorizontalLength,
        linkStrokeWidth,
        textXOffset,
        textColor,
        linkColor,
        theme,
      } = this.props;

      const labels = computeRadialLabels(arcs, {
        getLabel: label,
        radius,
        skipAngle,
        linkOffset,
        linkDiagonalLength,
        linkHorizontalLength,
        textXOffset,
      });

      return labels.map(labelItem => (
        <Fragment key={labelItem.arc.data.id}>
          <path
            d={lineGenerator(labelItem.line)}
            fill='none'
            style={{ fill: 'none', stroke: linkColor(labelItem.arc, theme) }}
            strokeWidth={linkStrokeWidth}
          />
          <g transform={`translate(${labelItem.position.x}, ${labelItem.position.y})`}>
            <text
              textAnchor={textPropsByEngine.svg.align[labelItem.align]}
              dy='0.3em'
              style={{
                ...theme.labels.text,
                fill: textColor(labelItem.arc.data, theme),
              }}
            >
              {labelItem.text}
            </text>
          </g>
        </Fragment>
      ));
    }
}
