import classnames from 'classnames';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { last, get } from 'lodash';
import { withRouter } from 'react-router';
import React, { Component } from 'react';

import styles from './CropSelectPanel.module.css';

import CropToggleButton from '../CropToggleButton';
import BigButton from '../BigButton';
import PlusIcon from '../PlusIcon';

class CropSelectPanel extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    speciesList: PropTypes.array.isRequired,
    className: PropTypes.string,
    history: PropTypes.object.isRequired,
    pageToGoBack: PropTypes.string,
    selectedCrop: PropTypes.oneOf([null, 'cucumber', 'tomato', 'eggplant', 'lettuce', 'pepper']),
    onSelected: PropTypes.func,
    onContinue: PropTypes.func,
  };

  static defaultProps = {
    className: null,
    selectedCrop: null,
    onSelected: null,
    pageToGoBack: null,
    onContinue: null,
  };

  handlerSelected = ({ crop }) => {
    const { onSelected } = this.props;

    if (onSelected) {
      onSelected({ crop });
    }
  };

  handlerContinue = () => {
    const { onContinue } = this.props;

    if (onContinue) {
      onContinue();
    }
  };

  render() {
    const {
      intl,
      selectedCrop,
      className,
      history,
      pageToGoBack,
      speciesList,
    } = this.props;

    const formatMessage = get(intl, 'formatMessage', ({ id }) => last(id.split('.')));

    return (
      <div className={classnames(styles.layout, className)}>
        <h2 className={styles.title}>
          {formatMessage({ id: 'plantingCycles.cropSelectPanelTitle' })}
        </h2>
        <p className={styles.description}>
          {formatMessage({ id: 'plantingCycles.cropSelectPanelDescription' })}
        </p>
        <div className={classnames(styles.items)}>
          {speciesList.map(currentSpecies => (
            <CropToggleButton
              key={`crop-toggle-button${currentSpecies}`}
              className={styles.item}
              crop={currentSpecies}
              isSelected={selectedCrop === currentSpecies}
              title={formatMessage({ id: `plantingCycles.species.${currentSpecies}` })}
              onSelected={() => this.handlerSelected({ crop: currentSpecies })}
            />
          ))}
          <CropToggleButton
            className={styles.item}
            icon={<PlusIcon className={styles.icon} />}
            isSelected={false}
            isTitleComponent
            title={(
              <div>
                <div className={styles.itemTitle}>
                  {formatMessage({ id: 'plantingCycles.addNewSpecies' })}
                </div>
                <div className={styles.itemDescription}>
                  {formatMessage({ id: 'plantingCycles.viaSupport' })}
                </div>
              </div>
            )}
            onSelected={() => {
              if (window.Intercom) {
                window.Intercom('showNewMessage');
              }
            }}
          />
        </div>
        <div className={styles.actions}>
          <BigButton
            className={styles.action}
            theme='light'
            title={formatMessage({ id: 'button.cancel' })}
            onClick={() => {
              history.push(pageToGoBack);
            }}
          />
          <BigButton
            disabled={!selectedCrop}
            className={classnames(styles.continue, styles.action)}
            theme='dark'
            title={formatMessage({ id: 'button.continue' })}
            onClick={this.handlerContinue}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(CropSelectPanel);
