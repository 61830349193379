import { connect } from 'react-redux';
import {
  getHarvestForecastAccess,
  getDigitalTwinAccess,
  getClimateInsightsAccess
} from 'store/company/selectors';

import Header from './Header';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,

  digitalTwinAccess: getDigitalTwinAccess(state),
  harvestForecastAccess: getHarvestForecastAccess(state, props),
  incidentsAccess: getClimateInsightsAccess(state, props),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
