import moment from 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { ReactComponent as Comment } from './assets/comment.svg';
import { ReactComponent as Replace } from './assets/replace.svg';
import { ReactComponent as Delete } from './assets/delete.svg';
import { ReactComponent as PhotoGray } from './assets/photo_gray.svg';

import styles from './CellPhoto.module.css';


const CellPhoto = (props) => {
  const {
    onDelete, onClick, compartment, isAddPhotoAvailable, plantingCycle, week, column, photo, category, year, photosMap, photoCategories,
  } = props;

  const handlerClick = ({ e, replace }) => {
    const photos = photoCategories.map(item => get(photosMap, `${item.code}/${year}/${week}`, {
      attributes: {
        category: item.code,
      },
    }));

    if (onClick) {
      onClick({
        photo, compartment, plantingCycle, week, column, category, year, e, replace, photos,
      });
    }
  };

  const handlerDelete = () => {
    if (onDelete) {
      onDelete({ photo, plantingCycle });
    }
  };

  const { attributes } = photo;
  const { thumbnailUrl, comments: rawComments = [] } = attributes;
  const comments = rawComments.sort((a, b) => moment(new Date(a.attributes.whenCreated)) - moment(new Date(b.attributes.whenCreated)));
  const url = `${thumbnailUrl}`;

  const cellProps = {
    onClick: e => handlerClick({ e, replace: !thumbnailUrl }),
  };

  const style = {
    backgroundImage: thumbnailUrl ? `url(${url})` : null,
  };

  const replaceIconProps = {
    onClick: (e) => {
      handlerClick({ e, replace: true });
      e.stopPropagation();
      return false;
    },
  };

  const deleteIconProps = {
    onClick: (e) => {
      handlerDelete();
      e.stopPropagation();
      return false;
    },
  };

  return (
    <div
      {...cellProps}
      style={style}
      className={classnames(
        styles.cellPhoto,
        {
          [styles.loading]: !!thumbnailUrl,
          [styles.addPhotoDisabled]: !isAddPhotoAvailable,
        },
      )}
    >
      {!!thumbnailUrl && (
        <div className={styles.cellPhotoHeader}>
          <div className={styles.cellPhotoIcons}>
            <div className={classnames(styles.cellPhotoIconWrapper)}>
              <Comment className={classnames(styles.cellPhotoIcon, styles.commentIcon)} />
            </div>
            {isAddPhotoAvailable && (
              <div className={classnames(styles.cellPhotoIconWrapper)} {...replaceIconProps}>
                <Replace className={classnames(styles.cellPhotoIcon, styles.replaceIcon)} />
              </div>
            )}
            {isAddPhotoAvailable && (
              <div className={classnames(styles.cellPhotoIconWrapper)} {...deleteIconProps}>
                <Delete className={classnames(styles.cellPhotoIcon, styles.deleteIcon)} />
              </div>
            )}
          </div>
          {comments && !!comments.length && (
            <div className={styles.cellPhotoTime}>
              {moment(comments[comments.length - 1].attributes.whenCreated).format('DD MMM')}
            </div>
          )}
        </div>
      )}
      {!!thumbnailUrl && comments && !!comments.length && (
        <div className={styles.cellPhotoComments}>
          <div className={styles.cellPhotoCommentCount}>{comments.length}</div>
          <div className={styles.cellPhotoComment}>
            {comments[comments.length - 1].attributes.text}
          </div>
        </div>
      )}
      {!!thumbnailUrl && <div className={styles.cellPhotoHover} />}
      {!thumbnailUrl && (
        <div className={styles.cellPhotoReplaceWrapper}>
          <PhotoGray />
          <div><FormattedMessage id='photos.takePhoto' /></div>
        </div>
      )}
    </div>
  );
};

CellPhoto.propTypes = {
  photo: PropTypes.object.isRequired,
  compartment: PropTypes.object.isRequired,
  photosMap: PropTypes.object.isRequired,
  photoCategories: PropTypes.array.isRequired,
  plantingCycle: PropTypes.object.isRequired,
  photos: PropTypes.array.isRequired,
  year: PropTypes.number.isRequired,
  week: PropTypes.number.isRequired,
  isAddPhotoAvailable: PropTypes.bool.isRequired,
  column: PropTypes.string,
  category: PropTypes.number,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
};

CellPhoto.defaultProps = {
  onClick: null,
  onDelete: null,
  column: '',
  category: '',
};

export default CellPhoto;
