import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import { get } from 'lodash';

import storageWrapper from 'helpers/storageWrapper';
import { getNameByLocal } from 'helpers/getNameByLocal';
import Typography from 'components/Typography';

import styles from './DragablePanel.module.css';

import { ReactComponent as DashIcon } from './assets/dash.svg';

const safeLocalStorage = storageWrapper.get('localStorage');


const DragablePanel = ({ graphMetric, index, onMove }) => {
  const id = get(graphMetric, 'id');

  const locale = safeLocalStorage.getItem('locale');

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'DRAGGABLE_PANEL',
    hover(item, monitor) {
      const temp = item;

      if (!ref.current) {
        return;
      }
      const dragIndex = temp.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      onMove(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      temp.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'DRAGGABLE_PANEL', id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  const metricName = getNameByLocal(graphMetric, locale);

  return (
    <div
      ref={ref}
      className={classnames(styles.row, styles.alignCenter, styles.layout)}
      style={{ opacity }}
    >
      <DashIcon className={styles.dash} />
      <Typography className={styles.title} variant='body1' color='secondary'>
        {metricName}
      </Typography>
    </div>
  );
};

DragablePanel.propTypes = {
  graphMetric: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onMove: PropTypes.func.isRequired,
};

export default injectIntl(DragablePanel);
