import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import { getHarvestData, deleteHarvestData, trackAddHarvestClick } from '../../../store/harvest/actions';
import { getCycleData } from '../../../store/newCrops/actions';
import {
  getHarvestWeeks, getHarvestFetching, getHarvestAverage, getWeeksWithHarvest
} from '../../../store/harvest/selectors';
import { getCurrentCycleFullInfo } from '../../../store/newCrops/selectors';
import { getIsAddHarvestAvailable } from '../../../store/company/selectors';

import Harvest from '../components/Harvest';

const mapDispatchToProps = {
  getHarvestData,
  getCycleData,
  deleteHarvestData,
  trackAddHarvestClick,
};

const mapStateToProps = (state, props) => ({
  ...props,
  harvestWeeks: getHarvestWeeks(state),
  isHarvestFetching: getHarvestFetching(state),
  harvestAverage: getHarvestAverage(state),
  cycleInfo: getCurrentCycleFullInfo(state),
  weeksWithHarvest: getWeeksWithHarvest(state),
  isAddHarvestAvailable: getIsAddHarvestAvailable(state),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Harvest));
