import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import { reduxForm, Field } from 'redux-form';
import { DefinedRange, DateRange } from 'date-range-picker';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import parse from 'date-fns/parse';

import { getCurrentLocale } from 'helpers/datesHelper';

import BigButton from 'components/BigButton';
import DefaultTextInput from 'components/DefaultTextInput';

import styles from './MobileDateRangePickerContent.module.css';

const renderDateInput = (props) => {
  const {
    input,
    meta: { error },
  } = props;

  return (
    <DefaultTextInput
      {...input}
      theme='mini'
      className={styles.field}
      error={error}
    />
  );
};

const MobileDateRangePickerContent = ({
  intl: { formatMessage },
  minDate,
  maxDate,
  pickerState,
  setPickerState,
  staticRanges,
  handlerClickCancel,
  handlerClickApply,
  withMobileVersion,
  formValues,
  formSyncErrors,
  setIsMobileMenuOpened,
}) => {
  const tabContentRef = useRef(null);
  const tabContentWidth = tabContentRef?.current?.offsetWidth;
  const arrowsWidth = 31; // Ширина стрелок, лучше получать реальное значение, но т.к. оно не меняется хардкодим
  const scrollBarWidth = 5; // Резервируем под появление скролла
  const calendarWidth = tabContentWidth - arrowsWidth - scrollBarWidth;

  const [isFirstTabActive, setIsFirstTabActive] = useState(true);

  // Remove custom range for mobile view
  const filteredStaticRanges = staticRanges?.filter(currentRange => currentRange?.id !== 'custom');

  const handlerSubmitForm = useCallback((e) => {
    e.preventDefault();

    if (!formValues || !isEmpty(formSyncErrors)) {
      return null;
    }

    const { startDate, endDate } = formValues;

    const currentPickerData = pickerState[0];

    return setPickerState([
      {
        ...currentPickerData,

        startDate: parse(startDate, 'dd/MM/yyyy', new Date()),
        endDate: parse(endDate, 'dd/MM/yyyy', new Date()),
      }
    ]);
  }, [setPickerState, pickerState, formValues, formSyncErrors]);

  return (
    <div className={cn(styles.contentPickerWrapper, { [styles.withMobileVersion]: withMobileVersion })}>
      <form
        className={styles.dateInputs}
        onSubmit={handlerSubmitForm}
      >
        <Field
          name='startDate'
          component={renderDateInput}
          className={styles.textInput}
          onBlur={handlerSubmitForm}
        />
        <Field
          name='endDate'
          component={renderDateInput}
          className={styles.textInput}
          onBlur={handlerSubmitForm}
        />
      </form>
      <div className={styles.tabsContainer}>
        <button
          tabIndex={0}
          type='button'
          className={cn(styles.tab, { [styles.active]: isFirstTabActive })}
          onClick={() => setIsFirstTabActive(true)}
        >
          {formatMessage({ id: 'datepicker.custom' })}
        </button>
        <button
          tabIndex={0}
          type='button'
          className={cn(styles.tab, { [styles.active]: !isFirstTabActive })}
          onClick={() => setIsFirstTabActive(false)}
        >
          {formatMessage({ id: 'datepicker.periods' })}
        </button>
      </div>
      <div className={styles.tabContent} ref={tabContentRef}>
        {isFirstTabActive ? (
          <DateRange
            ranges={pickerState}
            minDate={minDate}
            maxDate={maxDate}
            months={2}
            direction='vertical'
            scroll={{
              enabled: true,
              calendarHeight: 255,
              // calendarWidth: 250,
            }}
            moveRangeOnFirstSelection={false}
            // editableDateInputs
            showDateDisplay={false}
            onChange={item => setPickerState([item.selection])}
            navigatorRenderer={(() => {})}
            locale={getCurrentLocale()}
            monthStyle={{
              width: `${calendarWidth}px`,
            }}
            // onChange={onChange}
          />
        )
        :
        (
          <DefinedRange
            ranges={pickerState}
            onChange={item => setPickerState([item.selection])}
            staticRanges={filteredStaticRanges}
            locale={getCurrentLocale()}
            inputRanges={[]}
            // onChange={onChange}
          />
        )}
      </div>
      <div className={styles.actions}>
        <BigButton
          className={styles.cancelButton}
          title={formatMessage({ id: 'button.cancel' })}
          onClick={handlerClickCancel}
          theme='light'
          size='small'
        />
        <BigButton
          className={styles.okButton}
          title={formatMessage({ id: 'button.apply' })}
          onClick={(applyData) => {
            handlerClickApply(applyData);
            setIsMobileMenuOpened(false);
          }}
          theme='dark'
          size='small'
          disabled={!pickerState[0]?.startDate && !pickerState[0]?.endDate}
        />
      </div>
    </div>
  );
};


MobileDateRangePickerContent.propTypes = {
  intl: intlShape.isRequired,
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  pickerState: PropTypes.array.isRequired,
  setPickerState: PropTypes.func.isRequired,
  staticRanges: PropTypes.array.isRequired,
  handlerClickCancel: PropTypes.func.isRequired,
  handlerClickApply: PropTypes.func.isRequired,
  withMobileVersion: PropTypes.bool.isRequired,
  setIsMobileMenuOpened: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  formSyncErrors: PropTypes.object,
};

MobileDateRangePickerContent.defaultProps = {
  formValues: null,
  formSyncErrors: null,
};

export default reduxForm({
  form: 'mobileDateRangePickerForm',
  enableReinitialize: true,
  // validate,
})(MobileDateRangePickerContent);
