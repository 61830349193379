import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import DefaultToggle from '../../DefaultToggle';
import { ReactComponent as AbsoluteIcon } from './assets/absolute.svg';
import { ReactComponent as PercentageIcon } from './assets/percentage.svg';

class PercentageToggle extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    className: PropTypes.string,
    percentageForQuality: PropTypes.bool,
    cumulativeForQuality: PropTypes.bool,
    onToggleChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: null,
    percentageForQuality: false,
    cumulativeForQuality: false,
  };

  handlerOnClick = () => {
    const { percentageForQuality, cumulativeForQuality, onToggleChange } = this.props;
    const newPercentageForQuality = !percentageForQuality;

    onToggleChange({
      percentageForQuality: newPercentageForQuality,
      cumulativeForQuality: newPercentageForQuality ? false : cumulativeForQuality,
    });
  }

  render() {
    const { intl: { formatMessage }, className, percentageForQuality } = this.props;

    return (
      <DefaultToggle
        className={className}
        toggleState={percentageForQuality}
        onToggleChange={this.handlerOnClick}
        leftIcon={<AbsoluteIcon />}
        rightIcon={<PercentageIcon />}
        leftTooltip={formatMessage({ id: 'harvestDashboard.showAbsoluteNumbers' })}
        rightTooltip={formatMessage({ id: 'harvestDashboard.showPercentages' })}
      />
    );
  }
}

export default injectIntl(PercentageToggle);
