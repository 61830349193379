import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import { get } from 'lodash';

import PageViewTracker from 'components/PageViewTracker';
import BigButton from 'components/BigButton';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import DefaultPlate from 'components/DefaultPlate';
import DefaultTable from 'components/DefaultTable';

import { showIntercom } from 'helpers/intercomHelpers';

import styles from './SettingsBenchmarking.module.css';

export default class SettingsBenchmarking extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    groups: PropTypes.array,
    isBenchmarkGroupsFetching: PropTypes.bool.isRequired,
    requestBenchmarkGroups: PropTypes.func.isRequired,
    trackPageViewSettings: PropTypes.func.isRequired,
    defaultOpen: PropTypes.bool,
  };

  static defaultProps = {
    groups: [],
    defaultOpen: false,
  };

  componentDidMount() {
    const { requestBenchmarkGroups } = this.props;

    requestBenchmarkGroups();
  }

  handlerPageView = () => {
    const { trackPageViewSettings, defaultOpen } = this.props;

    trackPageViewSettings({ settingName: 'Benchmarking', defaultOpen });
  };

  renderHeaderRows = () => {
    const { intl: { formatMessage } } = this.props;

    return (
      <tr>
        <th>{formatMessage({ id: 'settings.benchmarkingCompany' })}</th>
      </tr>
    );
  };

  renderRows = group => get(group, 'participants', []).map((participant, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <tr key={`participant-${i}`}>
      <td>{get(participant, 'location.name')}</td>
    </tr>
  ));

  render() {
    const { intl: { formatMessage }, isBenchmarkGroupsFetching, groups } = this.props;

    const isEmpty = !groups || !groups.length;

    return (
      <div className={classnames(styles.settingsPermission)}>
        <PageViewTracker onMount={this.handlerPageView} />

        {isBenchmarkGroupsFetching && <DefaultCircleLoader />}

        <h2 className={styles.header2}>
          {formatMessage({ id: 'settings.benchmarking' })}
        </h2>

        {isEmpty && (
          <DefaultPlate className={styles.infoPlate}>
            <div>
              {formatMessage({ id: 'settings.toJoinDisabled' })}
              &nbsp;
              <BigButton
                className={styles.supportButton}
                onClick={showIntercom}
                title={formatMessage({ id: 'harvestDashboard.contactSupport' })}
                theme='transparent'
              />.
            </div>
          </DefaultPlate>
        )}

        {!isEmpty && (
          <DefaultPlate className={styles.infoPlate}>
            <div>
              {formatMessage({ id: 'settings.toJoin' })}
              &nbsp;
              <BigButton
                className={styles.supportButton}
                onClick={showIntercom}
                title={formatMessage({ id: 'harvestDashboard.contactSupport' })}
                theme='transparent'
              />.
            </div>
          </DefaultPlate>
        )}

        {!isEmpty && groups.map((group, k) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`group-${k}`} className={styles.group}>
            <h3 className={styles.header3}>
              {formatMessage({ id: 'settings.benchmarkingGroup' }, { name: get(group, 'name') })}
            </h3>
            <div className={styles.tableWrapper}>
              <DefaultTable
                headerRows={this.renderHeaderRows()}
                bodyRows={this.renderRows(group)}
                fixedColumnOnMobile
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
}
