import { createAction } from 'redux-actions';

import storageWrapper from '../../helpers/storageWrapper';

const safeLocalStorage = storageWrapper.get('localStorage');

export const setDashboardsMenuState = createAction('SET_DASHBOARDS_MENU_STATE');
export const setDashboardsCurrentTabState = createAction('SET_DASHBOARDS_CURRENT_TAB');


export function setDashboardsMenu({ isDashboardsMenuOpened }) {
  return async (dispatch) => {
    safeLocalStorage.setItem('isDashboardsMenuOpened', isDashboardsMenuOpened);

    dispatch(setDashboardsMenuState({ isDashboardsMenuOpened }));
  };
}

export function setDashboardsCurrentTab({ dashboardsCurrentTab }) {
  return async (dispatch) => {
    safeLocalStorage.setItem('dashboardsCurrentTab', dashboardsCurrentTab);

    dispatch(setDashboardsCurrentTabState({ dashboardsCurrentTab }));
  };
}
