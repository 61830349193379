import React, {
  useEffect, useState, useCallback, useRef
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import { uniqueId, find, sortBy } from 'lodash';

import DefaultDialog from 'components/DefaultDialog';
import BigButton from 'components/BigButton';
import Typography from 'components/Typography';
import DefaultCircleLoader from 'components/DefaultCircleLoader';

import UserBage from 'components/UserBage';

import AddUsersDropdown from '../AddUsersDropdown';

import styles from './HarvestForecastModalPublish.module.css';

const getPreparedUsersList = (users) => {
  const preparedUsers = users.map(user => ({
    id: user?.user.id,
    name: user?.user.name,
    email: user?.user.email,
  }));

  return sortBy(preparedUsers, 'name');
};

const HarvestForecastModalPublish = ({
  intl,
  onClose,
  onSubmit,
  isSubscribersFetching,
  notificationSubscribers,
  requestForecastNotificationSubscribers,
  clearForecastNotificationSubscribers,
  versionId,
  forecastId,
  userPrivileges,
  isUserPrivilegesFetching,
  requestUserPrivileges,
  isRepublish,
}) => {
  const { formatMessage } = intl;

  const isLoading = isSubscribersFetching || isUserPrivilegesFetching;

  const [additionalNotificationSubscribers, setAdditionalNotificationSubscribers] = useState([]);

  const [isElementAtTheRight, setIsElementAtTheRight] = useState();

  const dropdownRef = useRef(null);

  const getIsElementAtRight = useCallback(() => {
    const parentWidth = dropdownRef?.current?.offsetParent?.offsetWidth;
    const elementWidth = dropdownRef?.current?.offsetWidth;
    const elementOffsetLeft = dropdownRef?.current?.offsetLeft;

    return (elementOffsetLeft + elementWidth) > parentWidth / 2;
  }, [dropdownRef]);

  useEffect(() => {
    if (forecastId) {
      requestUserPrivileges();

      requestForecastNotificationSubscribers({ forecastId, versionId });
    }

    return () => {
      clearForecastNotificationSubscribers({});
    };
  }, [
    forecastId,
    versionId,
    clearForecastNotificationSubscribers,
    requestForecastNotificationSubscribers,
    requestUserPrivileges,
    setIsElementAtTheRight,
    getIsElementAtRight,
  ]);

  // Chech dropdown position on add/remove users from list
  useEffect(() => {
    setIsElementAtTheRight(getIsElementAtRight());
  }, [
    setIsElementAtTheRight,
    getIsElementAtRight,
    notificationSubscribers,
    additionalNotificationSubscribers
  ]);

  const handlerClickDontSave = () => {
    onClose();
  };
  const handlerClickSave = useCallback(() => {
    onSubmit(additionalNotificationSubscribers);
    onClose();
  }, [onSubmit, onClose, additionalNotificationSubscribers]);

  const usersList = getPreparedUsersList(userPrivileges);

  const filteredUsersList = usersList
    .filter(user => !find(notificationSubscribers, { name: user.name }));

  const handlerClickOption = useCallback(({ option, onlyCheck }) => {
    let newAdditionalSubscribers = [];

    if (option?.id === 'all') {
      const isAllChecked = additionalNotificationSubscribers?.length === filteredUsersList?.length;

      if (isAllChecked) {
        return setAdditionalNotificationSubscribers([]);
      }

      return setAdditionalNotificationSubscribers(filteredUsersList);
    }

    const isAlreadyExist = find(additionalNotificationSubscribers, { id: option?.id });
    const selectedUser = find(filteredUsersList, { id: option?.id });

    if (onlyCheck) {
      return setAdditionalNotificationSubscribers([selectedUser]);
    }

    if (isAlreadyExist) {
      newAdditionalSubscribers = additionalNotificationSubscribers.filter(item => item.id !== option?.id);
    } else {
      newAdditionalSubscribers = [...additionalNotificationSubscribers, selectedUser];
    }

    return setAdditionalNotificationSubscribers(newAdditionalSubscribers);
  }, [setAdditionalNotificationSubscribers, additionalNotificationSubscribers, filteredUsersList]);


  const handlerOnCloseClick = useCallback((user) => {
    const newAdditionalSubscribers = additionalNotificationSubscribers.filter(subscriber => subscriber.id !== user.id);

    setAdditionalNotificationSubscribers(newAdditionalSubscribers);
  }, [setAdditionalNotificationSubscribers, additionalNotificationSubscribers]);

  const actionName = isRepublish ?
    formatMessage({ id: 'forecast.dashboard.republish' })
    :
    formatMessage({ id: 'forecast.dashboard.publish' });

  return (
    // <Modal isOpen={isOpen}>
    <DefaultDialog
      title={formatMessage({ id: 'forecast.dashboard.publishPopup.title' }, { actionName })}
      onClose={onClose}
      wrapperClassName={styles.layout}
      className={styles.dialog}
    >
      <div className={classnames(styles.body)}>
        <div className={styles.window}>
          {/* <Typography className={styles.title} variant='h2'>
            {formatMessage({ id: 'forecast.dashboard.publishPopup.title' })}
          </Typography> */}
          <div className={styles.contentWrapper}>
            {isLoading && <DefaultCircleLoader />}
            <Typography className={styles.description} variant='subtitle2'>
              {formatMessage({ id: 'forecast.dashboard.publishPopup.description' }, { actionName: actionName?.toLowerCase() })}
            </Typography>
            <Typography className={styles.notify} variant='subtitle3' bold>
              {formatMessage({ id: 'forecast.dashboard.publishPopup.notify' })}
            </Typography>
            <div className={styles.users}>
              {notificationSubscribers?.map(subscriber => (
                <UserBage
                  key={uniqueId('user-bage-')}
                  user={subscriber}
                  onCloseClick={() => {}}
                  closeDisabledTooltip={formatMessage({ id: 'forecast.mandatoryUser' })}
                  isCloseDisabled
                />
              ))}

              {additionalNotificationSubscribers?.map(subscriber => (
                <UserBage
                  key={uniqueId('user-additional-bage-')}
                  user={subscriber}
                  onCloseClick={() => handlerOnCloseClick(subscriber)}
                />
              ))}

              <AddUsersDropdown
                ref={dropdownRef}
                intl={intl}
                className={styles.usersDropdown}
                usersList={filteredUsersList}
                handlerClickOption={handlerClickOption}
                alignLeft={!isElementAtTheRight}
                selectedOption={additionalNotificationSubscribers.map(item => item.id)}
              />
            </div>
            <div className={styles.actions}>
              <BigButton
                className={classnames(styles.dontSaveButton)}
                title={formatMessage({ id: 'forecast.dashboard.publishPopup.cancelButton' })}
                onClick={handlerClickDontSave}
                theme='light'
              />
              <BigButton
                className={classnames(styles.saveButton)}
                title={actionName}
                onClick={handlerClickSave}
                theme='dark'
              />
            </div>
          </div>
        </div>
      </div>
    </DefaultDialog>
  );
};

HarvestForecastModalPublish.propTypes = {
  intl: intlShape.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  notificationSubscribers: PropTypes.array,
  forecastId: PropTypes.number,
  versionId: PropTypes.number,
  isSubscribersFetching: PropTypes.bool,
  userPrivileges: PropTypes.array,
  isUserPrivilegesFetching: PropTypes.bool,
  isRepublish: PropTypes.bool,
  requestForecastNotificationSubscribers: PropTypes.func.isRequired,
  clearForecastNotificationSubscribers: PropTypes.func.isRequired,
  requestUserPrivileges: PropTypes.func.isRequired,
};

HarvestForecastModalPublish.defaultProps = {
  notificationSubscribers: [],
  forecastId: null,
  versionId: null,
  isSubscribersFetching: false,
  userPrivileges: [],
  isUserPrivilegesFetching: false,
  isRepublish: false,
};

export default HarvestForecastModalPublish;
