import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { preparePlantingCycleFormData } from 'components/PlantingCycleForm/PlantingCycleForm';
import CreateCropWizard from '../CreateCropWizard';


class CreatePlantingCycle extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    species: PropTypes.array,
    varieties: PropTypes.array,
    fruitClasses: PropTypes.array,
    organization: PropTypes.object,
    allCompartments: PropTypes.array,
    createPlantingCycleForm: PropTypes.object,
    createPlantingCycleStart: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isCreatePlantingCycleFetching: PropTypes.bool.isRequired,
    isAddPlantingCycleAvailable: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    species: [],
    varieties: [],
    fruitClasses: [],
    allCompartments: [],
    organization: null,
    createPlantingCycleForm: {},
  };

  componentDidMount() {
    const { match, history, isAddPlantingCycleAvailable } = this.props;

    if (!isAddPlantingCycleAvailable) {
      history.push(`/${match.params.organizationSlug}/crops`);
    }
  }

  handlerSubmit = (form) => {
    const { createPlantingCycleStart, isCreatePlantingCycleFetching, match } = this.props;
    if (!isCreatePlantingCycleFetching) {
      createPlantingCycleStart(preparePlantingCycleFormData(form), match);
    }
  };

  render() {
    const {
      intl,
      species,
      varieties,
      fruitClasses,
      organization,
      allCompartments,
      createPlantingCycleForm,
      isFetching,
      isCreatePlantingCycleFetching,
      match,
    } = this.props;

    return (
      <CreateCropWizard
        intl={intl}
        varieties={varieties}
        species={species}
        fruitClasses={fruitClasses}
        organization={organization}
        allCompartments={allCompartments}
        createPlantingCycleForm={createPlantingCycleForm}
        match={match}
        isFetching={isFetching}
        isCreatePlantingCycleFetching={isCreatePlantingCycleFetching}
        onSubmit={this.handlerSubmit}
      />
    );
  }
}

export default withRouter(injectIntl(CreatePlantingCycle));
