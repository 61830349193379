import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import styles from './PlusIcon.module.css';

import { ReactComponent as Plus } from './assets/plus.svg';

export default class PlusIcon extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  render() {
    const {
      className,
    } = this.props;

    return (
      <div className={classnames(styles.layout, className)}>
        <Plus />
      </div>
    );
  }
}
