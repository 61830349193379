import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { createPhotoComment, setSelectedPhoto } from 'store/photos/actions';
import {
  getAllVarieties,
  getIsAddPhotoAvailable,
  getLeftPlantingCycle,
  getOrganization,
  getPlantingCyclePhotoCategories,
  getUserProfile,
} from 'store/company/selectors';

import {
  getLeftPhotos,
  getSelectedCompartment,
  getSelectedPhoto,
  getSelectedPhotos,
  getSelectedPlantingCycle,
  getSelectedWeek,
  getSelectedYear,
} from 'store/photos/selectors';

import PhotoDetailDialog from './PhotoDetailDialog';


const mapDispatchToProps = {
  setSelectedPhoto,
  createPhotoComment,
};

const mapStateToProps = state => ({
  location: getOrganization(state),
  photoCategories: getPlantingCyclePhotoCategories(state),
  selectedPhoto: getSelectedPhoto(state),
  selectedYear: getSelectedYear(state),
  selectedWeek: getSelectedWeek(state),
  selectedCompartment: getSelectedCompartment(state),
  selectedPlantingCycle: getSelectedPlantingCycle(state),
  selectedPhotos: getSelectedPhotos(state),
  isAddPhotoAvailable: getIsAddPhotoAvailable(state),
  leftPlantingCycle: getLeftPlantingCycle(state),
  leftPhotos: getLeftPhotos(state),
  varieties: getAllVarieties(state),
  userProfile: getUserProfile(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PhotoDetailDialog));
