import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import CircleLoader from '../CircleLoader';
import loaderStyles from '../CircleLoader/CircleLoader.module.css';

const DefaultCircleLoader = React.memo(({ className, iconClassName }) => (
  <CircleLoader
    className={classnames(loaderStyles.circleLoader, className)}
    iconClassName={classnames(loaderStyles.circleLoaderIcon, iconClassName)}
  />
));

DefaultCircleLoader.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

DefaultCircleLoader.defaultProps = {
  className: null,
  iconClassName: null,
};

export default DefaultCircleLoader;
