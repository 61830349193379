import {
  get, isNaN, isNil, isNumber,
} from 'lodash';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import scrollbarSize from 'dom-helpers/util/scrollbarSize';
import React, { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import { intlShape } from 'react-intl';
import moment from 'moment-timezone';

import DefaultHotTable from 'components/DefaultHotTable';

import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';
import getDatePeriodRange from 'helpers/getDatePeriodRange';
import { API_DATE_FORMAT } from 'helpers/defaultDates';
import getDateFormat from 'helpers/getDateFormat';

import CellWithError from '../CellWithError';
import CellWithWarning from '../CellWithWarning';

import styles from './HarvestTable.module.css';


const COL_WIDTHS = 168;
const SIDE_PADDING = 24;
const DEFAULT_MIN_WIDTH = 840;

const ROW_HEADER_HEIGHT = 70;
const ROW_HEIGHT = 35;
const ROW_TOTAL_HEIGHT = 39;
const MAX_VISIBLE_COL = 3;

function cleanNumber({ value, delimeter }) {
  const temp = (value !== null && value !== undefined) ? value : '';

  return temp.toString().replace('.', delimeter).replace(',', delimeter).replace(delimeter, '.');
}

const warningate = ({ value, formatMessage, callback }) => {
  const sample = 1.1;
  const delimeter = /^1(.+)1$/.exec(sample.toLocaleString())[1]; // FIXME

  const cleanValue = cleanNumber({ value, delimeter });
  const checkValue = Number(cleanValue);

  if (cleanValue.length > 0) {
    if (Number(checkValue) < 10 && Number(checkValue) > 0) {
      return callback(false, formatMessage({ id: 'harvest.errors.cannotBeLessThanTen' }));
    }
  }

  return callback(true);
};

const validate = ({
  value, formatMessage, isLettuce, values, callback, isSubmitted,
}) => {
  const sample = 1.1;
  const delimeter = /^1(.+)1$/.exec(sample.toLocaleString())[1]; // FIXME

  const cleanValue = cleanNumber({ value, delimeter });
  const checkValue = Number(cleanValue);

  const checkAllValues = values ? values.every(item => !Number.isNaN(Number(cleanNumber({
    value: item.value,
    delimeter,
  }))) && cleanNumber({ value: item.value }).length > 0) : false;

  const checkAllEmptyValues = values ? values.every(item => cleanNumber({ value: item.value }).length === 0) : false;

  if (!isNumber(checkValue) || isNaN(checkValue)) {
    return callback(false, formatMessage({ id: 'formErrors.shouldBeANumber' }));
  }


  if (cleanValue.length > 0) {
    if (Number(checkValue) > 1000000) {
      return callback(false, formatMessage({ id: 'harvest.errors.cannotBeExceedOneMillion' }));
    }
    if (isLettuce && !Number.isInteger(Number(checkValue))) {
      return callback(false, formatMessage({ id: 'harvest.errors.shouldBeAnInt' }));
    }
  } else if (!checkAllValues && !checkAllEmptyValues && isSubmitted) {
    return callback(false, formatMessage({ id: 'harvest.errors.shouldBeFullRow' }));
  }

  return callback(true);
};

// renderToStaticMarkup т.к. handsontable работает только с html
const renderCellWithError = (alignLeft, alignBottom, error, value) => ReactDOMServer
  .renderToStaticMarkup(<CellWithError
    value={value}
    error={error}
    errorClassName={styles.errorContent}
    alignLeft={alignLeft}
    alignBottom={alignBottom}
  />);

const renderCellWithWarning = (alignLeft, alignBottom, warning, value) => ReactDOMServer
  .renderToStaticMarkup(<CellWithWarning
    value={value}
    warning={warning}
    warningClassName={styles.warningContent}
    alignLeft={alignLeft}
    alignBottom={alignBottom}
  />);

export default class HarvestTable extends PureComponent {
  static propTypes = {
    tableRef: PropTypes.object.isRequired,
    harvestCategories: PropTypes.array.isRequired,
    periodType: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
    cycle: PropTypes.object.isRequired,
    tableData: PropTypes.array.isRequired,
    minDate: PropTypes.object.isRequired,
    maxDate: PropTypes.object.isRequired,
    isSubmitted: PropTypes.bool,
    isLettuce: PropTypes.bool,
    onTableChange: PropTypes.func.isRequired,
    onValidChange: PropTypes.func,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    isSubmitted: false,
    isLettuce: false,
    onValidChange: null,
  };

  scrollbarSize = scrollbarSize();

  getTableWidth = () => {
    const { harvestCategories, periodType } = this.props;

    if (harvestCategories.length > 3) {
      return (harvestCategories.length * COL_WIDTHS) + COL_WIDTHS + (SIDE_PADDING * 2);
    }

    return (periodType === 'whole' ? this.scrollbarSize : 0) + DEFAULT_MIN_WIDTH + (SIDE_PADDING * 2);
  };

  getTableHeight = () => {
    const {
      tableData,
    } = this.props;

    const borders = 0;
    // TODO: Посмотреть на методы получения высоты хедеров и строк в handsontable
    const selfHeight = ((tableData.length - 1) * ROW_HEIGHT) + ROW_TOTAL_HEIGHT + ROW_HEADER_HEIGHT + borders + this.scrollbarSize;

    const rawDesiredHeight = window.innerHeight - 60 - 340;
    const desiredHeight = rawDesiredHeight < 480 ? 480 : rawDesiredHeight;

    return desiredHeight > selfHeight ? selfHeight : desiredHeight;
  };

  rerenderColumnHeaders = ({ column }) => {
    const {
      intl,
      harvestCategories,
    } = this.props;

    const {
      formatMessage,
    } = intl;

    if (column === 0) {
      return formatMessage({ id: 'harvest.date' });
    }

    if (column === harvestCategories.length + 1) {
      return this.renderMeterHeaderCell({
        isTotal: true,
      });
    }

    return this.renderMeterHeaderCell({
      harvestCategory: harvestCategories[column - 1],
    });
  };

  rerenderColumns = () => {
    const {
      intl,
      cycle,
      date,
      periodType,
      isSubmitted,
      minDate,
      maxDate,
      tableData,
      harvestCategories,
    } = this.props;

    const {
      formatMessage,
    } = intl;

    const species = get(cycle, 'variety.attributes.species');
    const isLettuce = species === 'lettuce';


    const { sameYear } = getDatePeriodRange({
      date,
      periodType,
      minDate,
      maxDate,
      formatMessage,
    });

    return ([
      {
        data: 'date',
        editor: false,
        readOnly: true,
        renderer(instance, td, row, col, prop, value) {
          /* eslint-disable no-param-reassign */
          if (value === 'total') {
            td.className = styles.totalFirstCell;
            td.innerHTML = formatMessage({ id: 'energy.total' });
          } else {
            const cellDate = moment(tableData[row]?.date, 'YYYY-MM-DD');
            const isDateDisabled = cellDate.isBefore(minDate) || cellDate.isAfter(maxDate);
            td.className = isDateDisabled ? classnames(styles.dateCell, styles.dateCellDisabled) : styles.dateCell;
            td.innerHTML = moment(value, API_DATE_FORMAT).format(getDateFormat(sameYear ? 'llll' : 'LLLL'));
          }
          /* eslint-enable no-param-reassign */

          return td;
        },
      },

      ...harvestCategories.map(harvestCategory => ({
        data: `values.${harvestCategories.findIndex(category => category.id === harvestCategory.id)}.value`,
        type: 'numeric',
        // renderer: 'html',
        renderer(instance, td, row, col, prop, value) {
          let error;
          let warning;
          let isCellValid = true;
          let isCellSafe = true;

          const cellDate = moment(tableData[row]?.date, 'YYYY-MM-DD');
          const isDateDisabled = cellDate.isBefore(minDate) || cellDate.isAfter(maxDate);

          const validationCallback = (isValid, errorMessage) => {
            error = errorMessage;

            return isValid;
          };

          const warningCallback = (isSafe, warningMessage) => {
            warning = warningMessage;

            return isSafe;
          };

          isCellValid = validate({
            value, formatMessage, isLettuce, callback: validationCallback, values: get(tableData, `${row}.values`), isSubmitted,
          });

          isCellSafe = warningate({ value, formatMessage, callback: warningCallback });

          const currentRowArray = instance.getDataAtRow(row);
          const colCount = currentRowArray.length;
          // выравниваем по левому крю для последних столбцов (если их много и есть скролл), чтобы не ехала таблица
          const alignLeft = (colCount > MAX_VISIBLE_COL) && ((colCount - 1) - col <= 1);

          const currentColArray = instance.getDataAtCol(col);
          const rowCount = currentColArray.length;
          // выравниваем по низу для последних 3 строк, чтобы не ехала таблица
          const alignBottom = ((rowCount - 1) - row) < 3;

          /* eslint-disable no-param-reassign */
          if (!isCellValid) {
            td.className = classnames('htInvalid', styles.invalidCell);
            td.innerHTML = renderCellWithError(alignLeft, alignBottom, error, value);
          } else if (!isCellSafe) {
            td.className = classnames('htWarning', styles.warningCell);
            td.innerHTML = renderCellWithWarning(alignLeft, alignBottom, warning, value);
          } else {
            td.className = classnames(styles.cell, { [styles.cellDisabled]: isDateDisabled });
            td.innerHTML = isNil(value) || value === '' ? value : numbersFormatting(value);
          }
          /* eslint-enable no-param-reassign */

          td.setAttribute('data-row', row);

          return td;
        },
        validator: function cellValidator(value, callback) {
          return validate({
            value,
            formatMessage,
            isLettuce,
            isSubmitted,
            callback,
            values: get(tableData, `${this.row}.values`),
          });
        },
      })),

      {
        // data: 'total',
        editor: false,
        readOnly: true,
        renderer(instance, td) {
          return {
            ...td,
            className: classnames(styles.totalCell, styles.cell),
            innerHTML: formatMessage({ id: 'energy.total' }),
          };
        },
      },
    ]);
  }

  handlerAfterValidate = (isValid, value, row, prop) => {
    const { onValidChange } = this.props;

    if (onValidChange) {
      onValidChange({ isValid, row, prop });
    }
    return isValid;
  }

  handlerBeforeChange = async (changesArray) => {
    const { onTableChange } = this.props;

    if (onTableChange) {
      return onTableChange(changesArray);
    }
    return null;
  }

  getRenderTotalColumnCell = column => (instance, td) => {
    const colArray = instance.getDataAtCol(column);

    const colSum = colArray.reduce((reducer, item) => {
      const numberItem = Number(item);

      if (isNumber(numberItem) && !isNaN(numberItem)) {
        return reducer + numberItem;
      }

      return reducer;
    }, 0);

    /* eslint-disable no-param-reassign */
    const total = colSum === 0 ? '' : numbersRounding(colSum, 'fixed', 0);
    td.innerHTML = numbersFormatting(total);
    td.classList.add(styles.totalCell);
    td.classList.add(styles.cell);
    /* eslint-enable no-param-reassign */
  };

  getRenderTotalRowCell = row => (instance, td) => {
    const colArray = instance.getDataAtRow(row);

    const colSum = colArray.reduce((reducer, item) => {
      const numberItem = Number(item);

      if (isNumber(numberItem) && !isNaN(numberItem)) {
        return reducer + numberItem;
      }

      return reducer;
    }, 0);

    /* eslint-disable no-param-reassign */
    const total = colSum === 0 ? '' : numbersRounding(colSum, 'fixed', 0);
    td.innerHTML = numbersFormatting(total);
    td.classList.add(styles.totalCell);
    td.classList.add(styles.cell);
    /* eslint-enable no-param-reassign */
  };

  getRenderTotalCell = () => (instance, td) => {
    const {
      tableData,
    } = this.props;

    const sum = tableData.reduce((acc, data, row) => {
      const colArray = instance.getDataAtRow(row);

      const colSum = colArray.reduce((reducer, item) => {
        const numberItem = Number(item);

        if (isNumber(numberItem) && !isNaN(numberItem)) {
          return reducer + numberItem;
        }

        return reducer;
      }, 0);

      return acc + colSum;
    }, 0);

    /* eslint-disable no-param-reassign */
    const total = sum === 0 ? '' : numbersRounding(sum, 'fixed', 0);
    td.innerHTML = numbersFormatting(total);
    td.classList.add(styles.totalCell);
    td.classList.add(styles.cell);
    /* eslint-enable no-param-reassign */
  };

  rerenderCells = (harvestCategories, rowsCount, row, column) => {
    const { minDate, maxDate, tableData } = this.props;

    const cellProperties = {};

    // Проверка на возможность редактирования
    if (row > 0 && row < rowsCount && column < harvestCategories.length) {
      const date = moment(tableData[row].date, 'YYYY-MM-DD');
      const isDisabled = date.isBefore(minDate) || date.isAfter(maxDate);

      if (isDisabled) {
        cellProperties.editor = false;
        cellProperties.readOnly = true;

        return cellProperties;
      }
    }

    if (column === 0) {
      return cellProperties;
    }

    // В последней строке выводим сумму
    if (row === (rowsCount - 1) && column === harvestCategories.length + 1) {
      cellProperties.renderer = this.getRenderTotalCell();
      cellProperties.editor = false;
      cellProperties.readOnly = true;
    } else {
      // В последней строке выводим сумму
      if (row === (rowsCount - 1)) {
        cellProperties.renderer = this.getRenderTotalColumnCell(column);
        cellProperties.editor = false;
        cellProperties.readOnly = true;
      }

      // В последней колонке выводим сумму
      if (column === harvestCategories.length + 1) {
        cellProperties.renderer = this.getRenderTotalRowCell(row);
        cellProperties.editor = false;
        cellProperties.readOnly = true;
      }
    }
    return cellProperties;
  };

  renderMeterHeaderCell = ({ harvestCategory, isTotal }) => {
    const {
      intl,
      isLettuce,
      periodType,
    } = this.props;

    const {
      formatMessage,
      locale,
    } = intl;

    const units = isLettuce ? formatMessage({ id: 'cunits.mini.count' }) : formatMessage({ id: 'cunits.mini.kilogram' });

    let columnName;
    let columnValue;

    if (isTotal) {
      columnName = formatMessage({ id: 'harvest.total' });
      columnValue = (
        <div className={styles.headerCellDescription}>
          {units}
        </div>
      );
    } else {
      const {
        name,
      } = harvestCategory;

      const categoryName = name[locale] || name.en;
      columnName = categoryName;
      columnValue = periodType !== 'day' ? (
        <div className={styles.headerCellDescription}>
          {units}
        </div>
      ) : null;
    }

    const elementRender = () => (
      <div className={classnames(styles.headerCellContainer, { [styles.total]: isTotal })}>
        <div className={styles.headerCellTitle}>{columnName}</div>
        {columnValue}
      </div>
    );

    // Т.к. handsontable работает только с html
    return ReactDOMServer.renderToStaticMarkup(elementRender());
  };

  render() {
    const {
      date,
      periodType,
      harvestCategories,

      tableData,
      tableRef,
    } = this.props;

    if (!date || !periodType) {
      return null;
    }

    const tableHeight = this.getTableHeight();

    return (
      <div className={styles.tableContainer}>
        <DefaultHotTable
          ref={tableRef}
          classNameWrapper={styles.tableWrapper}
          data={tableData}
          height={tableHeight}
          columnHeaderHeight={ROW_HEADER_HEIGHT}
          rowHeights={(index) => {
            if (index === tableData.length) {
              return ROW_TOTAL_HEIGHT;
            }
            return ROW_HEIGHT;
          }}
          colWidths={COL_WIDTHS}
          colHeaders={column => this.rerenderColumnHeaders({ column })}
          columns={this.rerenderColumns()}
          cells={(row, column, prop) => this.rerenderCells(harvestCategories, tableData.length, row, column, prop)}
          beforeChange={this.handlerBeforeChange}
          afterValidate={this.handlerAfterValidate}
        />
      </div>
    );
  }
}
