import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import cn from 'classnames';
// import { sortBy, findIndex } from 'lodash';

import Avatar from 'components/Avatar';
import DefaultSearchList from 'components/DefaultSearchList';

import styles from './AddUsersSearchList.module.css';

const renderIcon = name => (
  <div className={styles.iconWrapper}>
    <Avatar
      className={styles.avatar}
      userName={name}
    />
  </div>
);

const getOptions = users => users.map(userItem => ({
  id: userItem?.id,
  name: userItem?.name,
  leadIcon: renderIcon(userItem?.name),
}));

const AddUsersSearchList = ({
  intl,
  selectedOption,
  users,
  isMobileVersion,
  handlerClickOption,
}) => {
  const { formatMessage } = intl;

  const [query, setQuery] = useState('');

  const allOption = {
    id: 'all',
    name: formatMessage({ id: 'button.all' }),
    leadIcon: renderIcon(formatMessage({ id: 'button.all' })),
  };

  const isAllChecked = users?.length === selectedOption?.length;
  const selectedOptionWithAll = isAllChecked ? ['all', ...selectedOption] : selectedOption;

  const listOfOptions = getOptions([allOption, ...users]);

  return (
    <DefaultSearchList
      contentClassName={cn(styles.searchListContent, { [styles.searchListContentMobile]: isMobileVersion })}
      classNameOption={cn(styles.option, { [styles.onMobile]: isMobileVersion })}
      controlClassName={cn(styles.control, { [styles.onMobile]: isMobileVersion })}
      groupNameClassName={cn(styles.groupName, { [styles.onMobile]: isMobileVersion })}
      placeholder={formatMessage({ id: 'benchmarking.search' })}
      options={listOfOptions}
      selectedOption={selectedOptionWithAll}
      value={query}
      empty={formatMessage({ id: 'graphs.sidePanel.noSearchResult' })}
      onChanged={({ value }) => setQuery(value)}
      onSelected={handlerClickOption}
      whiteSpaceOptions='normal'
      withCheckBox
    />
  );
};

AddUsersSearchList.propTypes = {
  intl: intlShape.isRequired,
  selectedOption: PropTypes.array,
  users: PropTypes.array,
  isMobileVersion: PropTypes.bool,
  handlerClickOption: PropTypes.func.isRequired,
};

AddUsersSearchList.defaultProps = {
  selectedOption: [],
  isMobileVersion: false,
  users: [],
};

export default AddUsersSearchList;
