import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';


import {
  getFormatCell, getGraphKeyId, getReport, getRoundTo, getSorting,
} from 'store/dashboards/selectors';

import { getPeriodType } from 'store/plans/selectors';
import { getLaborWorkTypes } from 'store/labor/selectors';

import {
  getAllFruitClasses,
  getAllPlantingCycles,
  getAllProductGroups,
  getBreakdownTypeFromRoute,
  getCurrentCompartments,
  getFruitClassFromRoute,
  getIntl,
  getOrganizationSlugFromRoute,
  getRouteQueryParams,
  getSpeciesFromRoute,
  getUnitKindFromRoute,
  getVarietiesInLocationWithTargetWeight,
  getVarietyIdFromRoute,
  getAnyDateOfPeriodStartFromRoute,
  getAnyDateOfPeriodEndFromRoute
} from 'store/company/selectors';

import { getDefaultDateRange } from 'helpers/defaultDates';

import { getSortedTree } from 'helpers/getSortedTree';
import { getTableHeaderItems, getBreakdownLabel } from 'helpers/getTableHeaderItems';
import { getTableBodyItems } from 'helpers/getTableBodyItems';

import { tableEmptyStateIntervals } from 'helpers/emptyStateInterval';
import { getDate } from 'components/LaborWorks/helpers/getDate';

import DashboardReportTable from './DashboardReportTable';

const DEFAULT_DATE_RANGE = getDefaultDateRange();

const makeGetGraphKey = () => createSelector(
  [getRouteQueryParams, getGraphKeyId],
  (query, key) => get(query, key, 'total'),
);

const makeGetTableHeaderItems = () => createSelector(
  [
    getReport,
    getSorting,
    getPeriodType,
    getIntl,
    getLaborWorkTypes,
    getCurrentCompartments,
    getAllFruitClasses,
    getVarietiesInLocationWithTargetWeight,
    getOrganizationSlugFromRoute,
    getAllProductGroups,
    getAllPlantingCycles,
    getBreakdownTypeFromRoute,
    getSpeciesFromRoute,
    getFruitClassFromRoute,
    getVarietyIdFromRoute,
    getAnyDateOfPeriodStartFromRoute,
    getAnyDateOfPeriodEndFromRoute
  ],
  (report,
    sorting,
    periodType = 'month',
    intl,
    workTypes,
    compartments,
    fruitClasses,
    varieties,
    organizationSlug,
    productGroups,
    allPlantingCycles,
    currentBreakdown = 'compartment',
    currentSpecies,
    currentFruitClassCode,
    currentVarietyId,
    anyDateOfPeriodStart,
    anyDateOfPeriodEnd) => {
    const rows = get(report, 'rows', []);

    const sortedReportRows = getSortedTree({
      sorting,
      rows,
      intl,
      workTypes,
      compartments,
      fruitClasses,
      varieties,
      organizationSlug,
      productGroups,
      allPlantingCycles,
      currentBreakdown,
      currentSpecies,
      currentFruitClassCode,
      currentVarietyId,
      isPlantingCycleDatesAsTimestamp: true,
    });

    return sortedReportRows && sortedReportRows.length > 0 ? getTableHeaderItems({
      reportRows: sortedReportRows,
      currentBreakdown,
      intl,
      periodType,
    }) : tableEmptyStateIntervals({
      anyDateOfPeriodStart: anyDateOfPeriodStart || DEFAULT_DATE_RANGE.startDate,
      anyDateOfPeriodEnd: anyDateOfPeriodEnd || DEFAULT_DATE_RANGE.endDate,
      periodType,
      getDate,
      value: 'meter',
      intl,
      labelLocId: `plans.${getBreakdownLabel(currentBreakdown)}`
    }).headerItems;
  },
);

const makeGetTableBodyItems = () => createSelector(
  [
    getReport,
    getSorting,
    getIntl,
    getLaborWorkTypes,
    getUnitKindFromRoute,
    getCurrentCompartments,
    getAllFruitClasses,
    getVarietiesInLocationWithTargetWeight,
    getOrganizationSlugFromRoute,
    getAllProductGroups,
    getAllPlantingCycles,
    getBreakdownTypeFromRoute,
    getSpeciesFromRoute,
    getFruitClassFromRoute,
    getVarietyIdFromRoute,
    getRoundTo,
    getFormatCell,
  ],
  (
    report,
    sorting,
    intl,
    workTypes,
    currentUnitKind = 'absolute',
    compartments,
    fruitClasses,
    varieties,
    organizationSlug,
    productGroups,
    allPlantingCycles,
    currentBreakdown = 'compartment',
    currentSpecies,
    currentFruitClassCode,
    currentVarietyId,
    roundTo,
    formatCell,
  ) => {
    const rows = get(report, 'rows', []);

    const sortedReportRows = getSortedTree({
      sorting,
      rows,
      intl,
      workTypes,
      compartments,
      fruitClasses,
      varieties,
      organizationSlug,
      productGroups,
      allPlantingCycles,
      currentBreakdown,
      currentSpecies,
      currentFruitClassCode,
      currentVarietyId,
      isPlantingCycleDatesAsTimestamp: true,
    });

    return sortedReportRows ? getTableBodyItems({
      reportRows: sortedReportRows,
      intl,
      workTypes,
      compartments,
      fruitClasses,
      varieties,
      organizationSlug,
      productGroups,
      allPlantingCycles,
      currentUnitKind,
      currentBreakdown,
      currentSpecies,
      currentFruitClassCode,
      currentVarietyId,
      roundTo,
      formatCell,
    }) : [];
  },
);

const makeMapStateToProps = () => {
  const getTableBodyItemsSelector = makeGetTableBodyItems();
  const getTableHeaderItemsSelector = makeGetTableHeaderItems();
  const getGraphKey = makeGetGraphKey();

  return (state, props) => ({
    graphKey: getGraphKey(state, props),

    tableHeaderItems: getTableHeaderItemsSelector(state, props),
    tableBodyItems: getTableBodyItemsSelector(state, props),
    sorting: getSorting(state, props),
  });
};

export default injectIntl(withRouter(connect(makeMapStateToProps)(DashboardReportTable)));
