import { handleActions } from 'redux-actions';
import { get } from 'lodash';

import storageWrapper from 'helpers/storageWrapper';
import { CYCLES_TO_COMPARE } from 'helpers/constants';

import {
  requestBenchmarkGroups,
  receiveBenchmarkGroups,

  requestBenchmarkOverview,
  receiveBenchmarkOverview,

  requestBenchmarkComparison,
  receiveBenchmarkComparison,

  requestBenchmarkComparisonMetrics,
  receiveBenchmarkComparisonMetrics,

  requestUpdateBenchmarkComparisonMetrics,
  receiveUpdateBenchmarkComparisonMetrics,

  requestBenchmarkMetrics,
  receiveBenchmarkMetrics,

  updateCyclesComparison,
} from './actions';

const safeLocalStorage = storageWrapper.get('localStorage');

const initialState = {
  isBenchmarkComparisonFetching: false,
  isBenchmarkComparisonMetricsFetching: false,
  isUpdateBenchmarkComparisonMetricsFetching: false,
  isDeleteBenchmarkComparisonMetricsFetching: false,
  isDeleteBenchmarkComparisonProductFetching: false,
  comparison: null,
  metrics: null,

  isBenchmarkGroupsFetching: false,
  isBenchmarkOverviewFetching: false,
  isBenchmarkMetricsFetching: false,

  groups: undefined,
  overview: undefined,
  availableAdditionalMetrics: undefined,

  cyclesToCompare: safeLocalStorage.getItem(CYCLES_TO_COMPARE),
};

export default handleActions({
  [requestBenchmarkMetrics](state) {
    return {
      ...state,

      isBenchmarkMetricsFetching: true,
    };
  },
  [receiveBenchmarkMetrics](state, action) {
    return {
      ...state,

      isBenchmarkMetricsFetching: false,
      availableAdditionalMetrics: get(action, 'payload.metrics.additionalMetrics'),
    };
  },
  [requestBenchmarkGroups](state) {
    return {
      ...state,

      isBenchmarkGroupsFetching: true,
    };
  },
  [receiveBenchmarkGroups](state, action) {
    return {
      ...state,

      isBenchmarkGroupsFetching: false,
      groups: get(action, 'payload.groups'),
    };
  },
  [requestBenchmarkOverview](state) {
    return {
      ...state,

      isBenchmarkOverviewFetching: true,
    };
  },
  [receiveBenchmarkOverview](state, action) {
    return {
      ...state,

      isBenchmarkOverviewFetching: false,
      overview: get(action, 'payload.overview'),
    };
  },

  [requestBenchmarkComparison](state) {
    return {
      ...state,

      isBenchmarkComparisonFetching: true,
    };
  },
  [receiveBenchmarkComparison](state, action) {
    return {
      ...state,

      isBenchmarkComparisonFetching: false,
      comparison: get(action, 'payload.comparison', null),
    };
  },

  [requestBenchmarkComparisonMetrics](state) {
    return {
      ...state,

      isBenchmarkComparisonMetricsFetching: true,
    };
  },
  [receiveBenchmarkComparisonMetrics](state, action) {
    return {
      ...state,

      isBenchmarkComparisonMetricsFetching: false,
      metrics: get(action, 'payload.metrics', null),
    };
  },

  [requestUpdateBenchmarkComparisonMetrics](state) {
    return {
      ...state,

      isUpdateBenchmarkComparisonMetricsFetching: true,
    };
  },
  [receiveUpdateBenchmarkComparisonMetrics](state) {
    return {
      ...state,

      isUpdateBenchmarkComparisonMetricsFetching: false,
    };
  },
  [updateCyclesComparison](state, action) {
    return {
      ...state,

      cyclesToCompare: action.payload,
    };
  },
}, initialState);
