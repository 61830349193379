import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sizeMe from 'react-sizeme';
import { FormattedMessage } from 'react-intl';

import * as d3 from 'd3';
import moment from 'moment-timezone';
import { get, find, flow } from 'lodash';

import AddToComparisonIcon from '../Icons/AddToComparisonIcon';

import XAxis from './components/XAxis';
import YAxis from './components/YAxis';
import HorizontalBars from './components/HorizontalBars';
// import HorizontalBarsTooltip from './components/HorizontalBarsTooltip';

import styles from './DefaultTimeline.module.css';

const ONE_ROW_HEIGHT = 24;

class DefaultTimeline extends Component {
  static propTypes = {
    size: PropTypes.object.isRequired,
    items: PropTypes.array,
    forceColor: PropTypes.bool,
    minDateLimit: PropTypes.number,
    maxDateLimit: PropTypes.number,
    tickDateFormat: PropTypes.string,
    toCompareItems: PropTypes.array,
    hoveredItem: PropTypes.number,
    isShowLocationName: PropTypes.bool,
    withCompareButton: PropTypes.bool, // TODO: вынести рендеринг тултипа во внешнюю функцию
    // fixedHeight: PropTypes.number.isRequired,
    onMouseOverCallback: PropTypes.func,
    onMouseOutCallback: PropTypes.func,
    onClickCallback: PropTypes.func,
  };

  static defaultProps = {
    items: [],
    forceColor: false,
    minDateLimit: undefined,
    maxDateLimit: undefined,
    tickDateFormat: null,
    withCompareButton: false,
    isShowLocationName: true,
    toCompareItems: null,
    hoveredItem: null,
    onMouseOverCallback: () => {},
    onMouseOutCallback: () => {},
    onClickCallback: () => {},
  };

  constructor(props) {
    super(props);

    this.xScale = d3.scaleUtc();
    this.yScale = d3.scaleBand();
  }

  getYTickFormat = itemsArray => item => flow([
    itemId => find(itemsArray, { id: itemId }),
    currentItem => get(currentItem, 'label'),
  ])(item);

  renderCompareTips = (margins, svgDimensions, xScale, yScale) => {
    const {
      items,
      toCompareItems,
    } = this.props;
    if (items.length === 0) {
      return null;
    }
    const filteredItems = items.filter(item => (toCompareItems.indexOf(item.id) !== -1));
    return filteredItems.map((item) => {
      const fixedBarHeight = 8;
      const barHeight = yScale.bandwidth();
      const barPaddingTop = (barHeight - fixedBarHeight) / 2;
      const rectX = xScale(item.startTime);
      const rectY = (yScale(item.id) - barHeight) + barPaddingTop + (fixedBarHeight / 2);
      const barWidth = xScale(item.endTime) - xScale(item.startTime);
      return (
        <div
          key={`compare-tip-timeline-${item.id}`}
          className={styles.compareTip}
          style={{
            height: 20,
            width: 28,
            left: rectX + barWidth + 8,
            top: (rectY - fixedBarHeight) + 2 // 2 это поправка на высоту строки
          }}
        >
          <AddToComparisonIcon className={styles.compareTipIcon} width={28} />
        </div>
      );
    });
  };

  render() {
    const {
      items,
      size,
      minDateLimit,
      maxDateLimit,
      withCompareButton,
      isShowLocationName,
      toCompareItems,
      hoveredItem,
      forceColor,
      onMouseOutCallback,
      onMouseOverCallback,
      onClickCallback,
      tickDateFormat
    } = this.props;

    // debugger;
    const timelineItems = [...items].reverse();

    const timesArray = timelineItems.reduce((acc, item) => ([...acc, item.startTime, item.endTime || moment().endOf('day').valueOf()]), []);

    const minDate = d3.min(timesArray);
    const maxDate = d3.max(timesArray);

    // Прогнать на реальных данных, проверить надо ли (гипотетически будет надо в циклах, не пересекающих год)
    // const paddingMinDate = moment(minDate).subtract(1, 'month').valueOf();
    // const paddingMaxDate = moment(maxDate).add(1, 'month').valueOf();

    const paddingMinDate = minDateLimit || moment(minDate).valueOf();
    const paddingMaxDate = maxDateLimit || moment(maxDate).valueOf();

    const xTicksCount = moment(paddingMaxDate).diff(moment(paddingMinDate), 'months') + 1;

    const margins = {
      top: 20, right: 8, bottom: 30, left: 84
    };

    const timelineHeight = timelineItems.length > 0 ? timelineItems.length * ONE_ROW_HEIGHT : 3 * ONE_ROW_HEIGHT;

    const svgDimensions = {
      width: size.width,
      height: timelineHeight + margins.top + margins.bottom,
    };

    const xScale = this.xScale
      .domain(d3.extent([paddingMinDate, paddingMaxDate]))
      .range([margins.left, svgDimensions.width - margins.right]);

    const yScale = this.yScale
      .domain(timelineItems.map(d => d.id))
      .range([svgDimensions.height - margins.bottom, margins.top]);

    const yTickFormat = this.getYTickFormat(timelineItems);
    const yTicksCount = timelineItems.length;

    return (
      <div className={styles.timeline}>
        <svg width={svgDimensions.width} height={svgDimensions.height}>
          <g>
            <YAxis
              scale={yScale}
              margins={margins}
              tickFormatting={yTickFormat}
              ticksCount={yTicksCount}
            />
            <XAxis
              scale={xScale}
              height={svgDimensions.height}
              margins={margins}
              ticksCount={xTicksCount}
              tickDateFormat={tickDateFormat}
              // isFirstTickHidden
            />
          </g>
        </svg>

        <HorizontalBars
          forceColor={forceColor}
          scales={{ xScale, yScale }}
          margins={margins}
          items={timelineItems}
          svgDimensions={svgDimensions}
          hoveredItem={hoveredItem}
          withCompareButton={withCompareButton}
          isShowLocationName={isShowLocationName}
          onMouseOutCallback={onMouseOutCallback}
          onMouseOverCallback={onMouseOverCallback}
          onClickCallback={onClickCallback}
        />

        {toCompareItems && (
          <div>
            {this.renderCompareTips(margins, svgDimensions, xScale, yScale)}
          </div>
        )}

        {timelineItems.length === 0 ? (
          <div className={styles.emptyState}>
            <FormattedMessage id='dashboards.noDataForSelectedPeriod' />
          </div>
        ) : null}
      </div>
    );
  }
}

export default sizeMe()(DefaultTimeline);
