// @ts-ignore
import moment from 'moment-timezone';

import {
 Compartment, FruitClass, PlantingCycle, Variety
} from 'store/graphs/types';

import { getProductTypeName } from 'helpers/getVarietyName';
import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';
import getDateFormat from 'helpers/getDateFormat';

export function getPlantingCycleDates(
  cycle: PlantingCycle,
  formatMessage: Function
) {
  const now = moment();

  const startDate = cycle?.attributes.startDate
    ? moment(cycle?.attributes.startDate).format(getDateFormat('lll'))
    : undefined;
  const endDate = cycle?.attributes.endDate
    ? moment(cycle?.attributes.endDate).format(getDateFormat('lll'))
    : undefined;

  const startDateMoment = moment(cycle?.attributes.startDate, 'YYYY-MM-DD');
  const isNotSpecified =
    !cycle?.attributes.endDate && startDateMoment.isAfter(now);

  return `${startDate} – ${
    isNotSpecified
      ? formatMessage({ id: 'cycle.notSpecified' })
      : endDate || formatMessage({ id: 'cycle.present' })
  }`;
}

function getPlantingCycleTitle(
  cycle: PlantingCycle,
  allCompartments: Array<Compartment>,
  allVarieties: Array<Variety>,
  allFruitClasses: Array<FruitClass>,
  formatMessage: Function,
  locale: string
) {
  const plantingCycleDates = getPlantingCycleDates(cycle, formatMessage);
  const currentCompartment = allCompartments.find(
    coItem => coItem.id === cycle?.relationships?.compartment?.data?.[0]?.id
  );
  const compartmentName = currentCompartment?.attributes?.name || undefined;
  const targetWeight = cycle?.attributes?.targetWeight;
  const currentVariety = allVarieties.find(
    vrItem => vrItem.id === cycle?.relationships?.variety?.data?.[0]?.id
  );
  const currentFruitClass = allFruitClasses.find(
    fcItem => fcItem.id === cycle?.relationships?.fruitClass?.data?.[0]?.id
  );
  const currentProductGroupName = getProductTypeName({
    intl: { formatMessage, locale },
    targetWeight: targetWeight
      ? numbersFormatting(numbersRounding(targetWeight, 'fixed', 0))
      : null,
    variety: currentVariety,
    fruitClass: currentFruitClass,
    withSpecies: false,
    withFruitClass: true,
  });
  return [currentProductGroupName, plantingCycleDates, compartmentName].join(
    ', '
  );
}

export default getPlantingCycleTitle;
