import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './UiDropdownByHover.module.css';

const UiDropdownByHover = ({
  children,
  buttonElement,
  className,
  buttonClassName,
  contentClassName,
  isOpenExternal,
  setIsOpenExternal,
}) => {
  const [isOpenInternal, setIsOpenInternal] = useState(false);
  const isOpen = setIsOpenExternal ? isOpenExternal : isOpenInternal;

  const handleHover = () => {
    if (setIsOpenExternal) {
      setIsOpenExternal(true);
    } else {
      setIsOpenInternal(true);
    }
  };

  const handleLeave = () => {
    if (setIsOpenExternal) {
      setIsOpenExternal(false);
    } else {
      setIsOpenInternal(false);
    }
  };

  const handleTouchStart = () => {
    if (setIsOpenExternal) {
      setIsOpenExternal(!isOpen);
    } else {
      setIsOpenInternal(!isOpen);
    }
  };

  // const handleTouchEnd = () => {
  //   setIsOpen(false);
  // };

  return (
    <div
      className={classnames(styles.dropdown, className)}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      // onTouchEnd={handleTouchEnd}
    >
      <div
        className={classnames(styles.dropdownButton, buttonClassName)}
        role='button'
        tabIndex={0}
        onTouchStart={handleTouchStart}
      >
        {buttonElement}
      </div>
      <div
        className={classnames(styles.dropdownContent, contentClassName)}
        style={{ visibility: isOpen ? 'visible' : 'hidden' }}
      >
        {children}
      </div>
    </div>
  );
};


UiDropdownByHover.propTypes = {
  children: PropTypes.element.isRequired,
  buttonElement: PropTypes.element.isRequired,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  isOpenExternal: PropTypes.bool,
  setIsOpenExternal: PropTypes.func,
};

UiDropdownByHover.defaultProps = {
  className: undefined,
  buttonClassName: undefined,
  contentClassName: undefined,
  isOpenExternal: undefined,
  setIsOpenExternal: undefined,
};

export default UiDropdownByHover;
