import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import classnames from 'classnames';
// @ts-ignore
import moment from 'moment-timezone';
import { get } from 'lodash';

// @ts-ignore
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';

// @ts-ignore
import DatePicker from 'react-datepicker/dist/react-datepicker.min';

import BigSelect from 'components/BigSelect';
import BigButton from 'components/BigButton/BigButton';

import getDatesRange from 'helpers/getDatesRange';
import getGraphThresholdSelectors from 'helpers/getGraphThresholdSelectors';
import getGraphThreshold from 'helpers/getGraphThreshold';

import {
  GraphsPresetRange, Location

} from 'store/graphs/types';


import CustomDatePickerInput from './components/CustomDatePickerInput';

import { ReactComponent as DateIcon } from './assets/date.svg';
import { ReactComponent as ArrowRightIcon } from './assets/arrow_right.svg';

import styles from './index.module.css';

const MIN_DATE = moment.tz('UTC')
  .utc()
  .year(2016)
  .month(0)
  .date(1)
  .hours(0)
  .minutes(0)
  .seconds(0)
  .milliseconds(0);

type GraphsPresetFiltersProp = {
  location: Location;
  range: GraphsPresetRange;
  threshold: number;
  scaledGraphPresetHistory: Array<any>; // TODO: type for scaledGraphPresetHistory
  hasPresetMetrics: boolean;
};

export type OnChangeFunc = (
  range: GraphsPresetRange,
  threshold: number
) => void;

type GraphsPresetFiltersFunc = {
  onChange: OnChangeFunc;
};

const GraphsPresetFilters = ({
  intl,
  location,
  range,
  threshold,
  scaledGraphPresetHistory,
  onChange,
  hasPresetMetrics
}: GraphsPresetFiltersProp & GraphsPresetFiltersFunc & InjectedIntlProps) => {
  const { formatMessage, formatPlural } = intl;
  const { attributes: { timezone } } = location;

  const MAX_DATE = moment.tz(timezone)
    .add(-moment.tz.zone(timezone).offset(+moment.tz(timezone)), 'minutes')
    .utc()
    .hours(23)
    .minutes(59)
    .seconds(0)
    .milliseconds(0);

  const datesRanges = useMemo(() => ['today', 'last2Days', 'last7Days', 'last30Days', 'last90Days', 'custom']
    .map(item => ({ id: item, name: formatMessage({ id: `dates.${item}` }), removeSelected: false, })),
  [formatMessage]);

  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [isIncorrectRangeError, setIsIncorrectRangeError] = useState(range.xRangeLengthInMins < 0);

  const [rangeState, setRangeState] = useState(range);

  useEffect(() => {
    setRangeState({
      ...range
    });
  }, [range, setRangeState]);

  const thresholdSelectors: Array<any> = getGraphThresholdSelectors({
    location,
    ...rangeState
  });

  const { xFrom: xFromDate, xTo: xToDate } = getDatesRange({
    location,
    ...rangeState
  });

  const isLargeRangeError = xToDate.diff(xFromDate, 'days') > 731;

  const renderedLocaleTimeOffset = useMemo(() => {
    const utcOffset = moment.tz(timezone).utcOffset() / 60;
    const currentUtcOffset = moment().utcOffset() / 60;

    if (utcOffset === currentUtcOffset) {
      return null;
    }

    if (hasPresetMetrics) {
      const diff = utcOffset - currentUtcOffset;
      // Location time -2 hours from yours
      return `${formatMessage({ id: 'graphs.locationTime' })} ${diff > 0 ? '+' : ''}${diff} ${formatMessage({ id: `timeSelector.hour.${formatPlural(Math.abs(diff))}` })} ${formatMessage({ id: 'graphs.fromYours' })}`;
    }
    return null;
  }, [
    hasPresetMetrics,
    formatMessage,
    formatPlural,
    timezone
  ]);

  const handlerChangeDate = useCallback((option) => {
    const { value } = option;
    if (value === 'custom') {
      /*
      const { xFrom: xFromDate, xTo: xToDate } = getDatesRange({
        location,
        ...range
      });
      */
      const nowFormattedDate = moment.tz('UTC')
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 0)
        .set('millisecond', 0);

      setRangeState({
        xRange: 'custom',
        xRangeEnd: (xToDate || nowFormattedDate).format('YYYY-MM-DDTHH:mm:ss'),
        xRangeLengthInMins: xFromDate && xToDate ? xToDate.diff(xFromDate, 'minutes') + 1 : 43200
      });

      setShowCustomDatePicker(true);
    } else {
      const newThreshold = getGraphThreshold({
        location,
        xRange: value,
        xRangeEnd: null,
        xRangeLengthInMins: null
      });
      onChange({
        xRange: value,
        xRangeEnd: null,
        xRangeLengthInMins: null
      }, newThreshold);
    }
  }, [
    location,
    setRangeState,
    setShowCustomDatePicker,
    xFromDate,
    xToDate,
    onChange
  ]);

  const handleChangeLeftDataPicker = useCallback((date) => {
    setRangeState((prevState:GraphsPresetRange) => {
      const xToDateLocal = prevState.xRangeEnd ?
        moment.tz(prevState.xRangeEnd, 'YYYY-MM-DDTHH:mm:ss', 'UTC') :
        moment.tz('UTC')
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 0)
          .set('millisecond', 0);
      const xFromDateLocal = date.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
      return {
        ...prevState,
        xRangeLengthInMins: xToDateLocal.diff(xFromDateLocal, 'minutes') + 1,
      };
    });
    setIsIncorrectRangeError(false);
  }, [setRangeState, setIsIncorrectRangeError]);

  const handleChangeRightDataPicker = useCallback((date) => {
    const normalizedDate = date.clone();
    normalizedDate
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 0)
      .set('millisecond', 0);
    setRangeState((prevState:GraphsPresetRange) => ({
      ...prevState,
      xRangeLengthInMins: normalizedDate.diff(xFromDate, 'minutes') + 1,
      xRangeEnd: normalizedDate.format('YYYY-MM-DDTHH:mm:ss'),
    }));
    setIsIncorrectRangeError(false);
  }, [setRangeState, xFromDate, setIsIncorrectRangeError]);

  const handlerChangeCustomDate = useCallback(() => {
    setIsIncorrectRangeError(rangeState.xRangeLengthInMins < 0);
    if (rangeState.xRangeLengthInMins > 0) {
      const { xFrom: xFromDateNew, xTo: xToDateNew } = getDatesRange({
        location,
        ...rangeState
      });
      const xFromPrepared = xFromDateNew.clone()
        .startOf('day');

      const xToPrepared = xToDateNew.clone()
        .hours(23)
        .minutes(59)
        .seconds(0)
        .milliseconds(0);

      const newGraphPresetXRangeEnd = xToPrepared.format('YYYY-MM-DDTHH:mm:ss');
      const newGraphPresetXRangeLengthInMins = xToPrepared.diff(xFromPrepared, 'minutes') + 1;

      const newThreshold = getGraphThreshold({
        location,
        xRange: rangeState.xRange,
        xRangeEnd: newGraphPresetXRangeEnd,
        xRangeLengthInMins: newGraphPresetXRangeLengthInMins,
      });

      onChange({
        xRange: rangeState.xRange,
        xRangeEnd: newGraphPresetXRangeEnd,
        xRangeLengthInMins: newGraphPresetXRangeLengthInMins,
      }, newThreshold);

      setShowCustomDatePicker(false);
    }
  }, [
    rangeState,
    onChange,
    setIsIncorrectRangeError,
    setShowCustomDatePicker,
    location
  ]);

  const handleClickThresholdSelectorButton = useCallback((newThreshold: number) => {
    onChange(rangeState, newThreshold);
  }, [onChange, rangeState]);

  const valueRenderer = useCallback((option) => {
    const { value, label } = option;
    const sameYear = xToDate.year() === moment().year() && xFromDate.year() === moment().year();
    const hasHistory = scaledGraphPresetHistory && scaledGraphPresetHistory.length;
    const isOverallRange = xFromDate.format('HH:mm') === '00:00' && xToDate.format('HH:mm') === '23:59';
    const isNotOverallRange = !isOverallRange;
    const specialRange = value === 'custom' &&
      !thresholdSelectors.find(item => get(item, 'length') === rangeState.xRangeLengthInMins);
    const needTime = (hasHistory || specialRange) && isNotOverallRange ? ', HH:mm' : '';
    if (value === 'custom') {
      if (xToDate.diff(xFromDate, 'days') === 0 && isOverallRange) {
        return `${xFromDate.format(`${formatMessage({ id: 'date.dayMonth' })}${sameYear ? '' : ', YYYY'}${needTime}`)}`;
      }
      return `${xFromDate.format(`${formatMessage({ id: 'date.dayMonth' })}${sameYear ? '' : ', YYYY'}${needTime}`)} - ${xToDate.format(`${formatMessage({ id: 'date.dayMonth' })}${sameYear ? '' : ', YYYY'}${needTime}`)}`;
    }
    return label;
  }, [
    xToDate,
    xFromDate,
    formatMessage,
    scaledGraphPresetHistory,
    rangeState,
    thresholdSelectors
  ]);

  return (
    <div className={classnames(styles.graphsFiltersWrapper)}>
      <div className={classnames(styles.graphsFiltersText)}>
        {renderedLocaleTimeOffset}
      </div>
      <div className={classnames(styles.graphsFilters)}>
        <div className={styles.stepSelect}>
          {!showCustomDatePicker &&
            // TODO: Type for getGraphThresholdSelectors
            // @ts-ignore
            thresholdSelectors.map(({ range: thresholdRange, type, length }, i) => (
              <BigButton
                key={`threshold-selector-${type}-${length}`}
                className={classnames(styles.buttonSticky, {
                  [styles.selected]: threshold === length,
                  [styles.first]: i === 0,
                })}
                title={`${thresholdRange} ${formatMessage({
                  id: `timeSelector.${type}.${formatPlural(thresholdRange)}`,
                })}`}
                onClick={() => {
                  handleClickThresholdSelectorButton(length);
                }}
              />
            ))}
        </div>
        {!showCustomDatePicker && (
          <BigSelect
            mini
            intl={intl}
            className={styles.dateSelect}
            options={datesRanges}
            value={rangeState.xRange}
            onChange={handlerChangeDate}
            labelPath='name'
            placeholder={formatMessage({ id: 'graphs.selectDatePlaceholder' })}
            valueRenderer={valueRenderer}
          />
        )}
        {showCustomDatePicker && (
          <div className={styles.customDatePicker}>
            <div className={styles.customDatePickerWrapper}>
              <div className={styles.leftDatePicker}>
                <DatePicker
                  utcOffset={0}
                  dateFormat={`${formatMessage({ id: 'date.dayMonth' })}, YYYY`}
                  customInput={(
                    <CustomDatePickerInput
                      classNames={classnames(styles.customDatePickerInput, {
                        [styles.error]:
                          isLargeRangeError || isIncorrectRangeError,
                      })}
                      icon={<DateIcon className={styles.icon} />}
                    />
                  )}
                  selected={xFromDate}
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  onChange={handleChangeLeftDataPicker}
                />
              </div>

              <div className={styles.customDatePickerMiddle}>
                <ArrowRightIcon />
              </div>

              <div className={styles.rightDatePicker}>
                <DatePicker
                  utcOffset={0}
                  dateFormat={`${formatMessage({ id: 'date.dayMonth' })}, YYYY`}
                  customInput={(
                    <CustomDatePickerInput
                      classNames={classnames(styles.customDatePickerInput, {
                        [styles.error]:
                          isLargeRangeError || isIncorrectRangeError,
                      })}
                      icon={<DateIcon className={styles.icon} />}
                    />
                  )}
                  selected={xToDate}
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  onChange={handleChangeRightDataPicker}
                />
              </div>
              <BigButton
                type='button'
                disabled={isLargeRangeError}
                className={styles.customDatePickerButton}
                onClick={handlerChangeCustomDate}
                size='small'
                theme='dark'
                title={formatMessage({ id: 'button.done' })}
              />
            </div>
            {!isIncorrectRangeError && isLargeRangeError && (
              <div className={styles.customDatePickerError}>
                <FormattedMessage id='graphs.largeRangeError' />
              </div>
            )}
            {isIncorrectRangeError && (
              <div className={styles.customDatePickerError}>
                <FormattedMessage id='graphs.incorrectRangeError' />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(GraphsPresetFilters);
