import { useState, useCallback } from 'react';

const useSorting = (initialValue) => {
  const [sorting, setSorting] = useState(initialValue);
  const updateSorting = useCallback(value => setSorting(value), []);
  return [
    sorting,
    updateSorting
  ];
};

export default useSorting;
