import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import LoginVerificationProblems from '../components/LoginVerificationProblems';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginVerificationProblems)));
