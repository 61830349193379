import React, { Component } from 'react';
import { withRouter } from 'react-router';

import styles from './General.module.css';


class General extends Component {
  render() {
    return (
      <article className={styles.main}>
        <h1>Conditions générales</h1>

        <p>Avant de commencer à utiliser l’Application Pylot, il est recommandé de faire connaissance de la présente Politique de confidentialité, ainsi que d’étudier et d’accepter nos Conditions générales.</p>

        <br />

        <p>Les conditions exposées ci-dessous s’appliquent aux services SaaS (Software as a Service - logiciel en tant que service) proposés par la société Pylot au Client, avec lequel la société Pylot signe le Contrat (<strong>Client</strong>).</p>

        <br />

        <p>Les services sont fournis par la société Pylot B. V., siège social : Vlotlaan 710, 2681 TX Monster, inscrite au Registre de Commerce et des Sociétés sous le numéro 68947232 (<strong>Pylot</strong>).</p>

        <h2><strong>Point 1.</strong>&nbsp;Définitions</h2>
        <p>Les termes utilisés dans les présentes Conditions générales commençant par une majuscule ont (en singulier et en pluriel) les significations expliquées ci-dessous.</p>

        <br />

        <p><strong>1.1 Compte d'utilisateur</strong>&nbsp;– un compte attribué au Client par la société Pylot dans le but d’assurer l’utilisation du Logiciel.</p>
        <p><strong>1.2 Contrat</strong>&nbsp;– tout Contrat entre la société Pylot et le Client, dans le cadre duquel la société Pylot offre des Services au Client, et dont les présentes Conditions générales font une partie intégrante.</p>
        <p><strong>1.3 Application</strong>&nbsp;– l’application de la société Pylot avec le site web&nbsp;<a href='https://pylot.app'>https://pylot.app</a></p>
        <p><strong>1.4 Client</strong>&nbsp;– toute personne physique ou morale agissant à titre professionnel, avec laquelle la société Pylot a signé un Contrat.</p>
        <p><strong>1.5 Utilisateurs finaux</strong>&nbsp;– les employés du Client ayant par l’intermédiaire du Client un accès au Logiciel, offert au Client par la société Pylot via les comptes des utilisateurs.</p>
        <p><strong>1.6 Condition générales</strong>&nbsp;– les présentes conditions.</p>
        <p><strong>1.7 Par écrit</strong>&nbsp;– tout document en papier daté et signé. En plus du document en papier tel que décrit, cela inclut également les emails et les fax, dans la mesure où l’origine et l’intégrité de ces messages sont suffisamment établies.</p>
        <p><strong>1.8 Droits de propriété intellectuelle</strong>&nbsp;– tous les droits de propriété intellectuelle, y compris, entre autres, des droits d’auteurs, des droits des bases de données, des noms de domaine, des droits des désignations sociales, des marques de commerce, des modèles industriels, des droits d’accompagnement, des droits de brevets, aussi bien que tous les droits de savoir-faire.</p>
        <p><strong>1.9 Produits</strong>&nbsp;– tout matériel, par exemple : sites web et applications/applications web, image de marque, logos, prospectus, brochures, dépliants, typographie, annonces publicitaires, plans marketing et/ou plans de communication, dessins, images, textes, esquisses, documents, documentation à caractère consultatif, rapports et d’autres résultats de l’activité intellectuelle, tout produit lié à la préparation de tout ce qui est cité ci-dessus, ainsi que des fichiers ou des supports d’information (codés ou non) contenant ces produits.</p>
        <p><strong>1.10 Horaires d’ouverture</strong>&nbsp;– de 9:00 à 17:00 (heure néerlandaise) du lundi au vendredi, sauf les jours fériés officiellement célébrés au Royaume des Pays-Bas.</p>
        <p><strong>1.11 Partie/Parties</strong>&nbsp;– Parties contractantes, la société Pylot et le Client, mentionnées ensemble ou séparément.</p>
        <p><strong>1.12 Services</strong>&nbsp;– toute prestation offerte par Pylot au Client conformément au Contrat, y compris, entre autre, (i) la mise à disposition et l’installation du Logiciel, (ii) l’offre des prestations dans le nuage sous la forme de « logiciel en tant que service» par l’intermédiaire de l’Application, (iii) la configuration des comptes des utilisateurs, (iv) et le maintien technique du Logiciel (offert comme une prestation).</p>
        <p><strong>1.13 Logiciel</strong>&nbsp;– tout Logiciel (en tant que Service) fourni sur la plate-forme (Application de la société Pylot, https domaine et application, agents installés pour l’intégration avec des systèmes locaux  dans la serre, API) par la société Pylot en conformité avec le Contrat.</p>
        <p><strong>1.14 Période d’essa</strong>&nbsp; – une période personnalisée pendant laquelle l’Application Pylot est testée par le Client.</p>

        <h2><strong>Point 2.</strong>&nbsp;Conclusion et exécution du Contrat</h2>
        <p><strong>2.1</strong>&nbsp;Aucune offre de la société Pylot n’engage pas la responsabilité de la société Pylot et n’est valable que pendant trente jours de la date de proposition d’un prix ou d’une offre. La société Pylot n'est pas tenue d'accepter l'offre d'achat de ces services après l’écoulement du délai indiqué ci-dessus, mais si Pylot accepte l’offre d’achat, ce prix ou l’offre sont considérés acceptés.</p>
        <p><strong>2.2</strong>&nbsp;Si le Client accepte des conditions qui diffèrent (même très peu) des conditions de l’offre, la société Pylot n’est pas obligée à observer ces conditions. Dans ce cas, le Contrat ne sera pas conclu conformément à cette acceptation variable, sauf si Pylot certifie le contraire sous forme écrite.</p>
        <p><strong>2.3</strong>&nbsp;Le Contrat est conclu si l’offre est acceptée ou confirmée, comme décrit ci-dessus.</p>
        <p><strong>2.4</strong>&nbsp;La société Pylot s’engage à faire tout le possible pour fournir le Logiciel en faisant preuve de diligence et de professionnalisme.</p>

        <h2><strong>Point 3.</strong>&nbsp;Domaines d’application, durée et résiliation du Contrat</h2>
        <p><strong>3.1</strong>&nbsp;Les Conditions générales s’appliquent à toutes les offres de la société Pylot, à leur acceptation par le Client et par conséquent au Contrat conclu entre les Parties, ainsi qu’à la prestation de Services de Pylot.</p>
        <p><strong>3.2</strong>&nbsp;L’applicabilité de toute autre clause et condition du Client est expressément exclue.</p>
        <p><strong>3.3</strong>&nbsp;Le Contrat entre en vigueur dès la date approuvée par les Parties et reste valable pendant toute la Période d’essai.</p>
        <p><strong>3.4</strong>&nbsp;Après l’écoulement de la Période d’essai, le Contrat est prolongé pour une période de 12 mois si et dans la mesure convenue par les Parties. Après l’écoulement de ces 12 mois, le Contrat est prolongé encore une fois pour la période de 12 mois si et dans la mesure convenue par les Parties. Sauf les cas où le Contrat est conclu pour un durée indéterminée, la résiliation anticipée n’est pas possible, excepté les cas mentionnés dans les présentes Conditions générales.</p>
        <p><strong>3.5</strong>&nbsp;Si le Client manque à une obligation matérielle conformément au Contrat, la société Pylot a le droit de suspendre ou résilier le Contrat Par écrit, avec effet immédiat, sans qu’un avis de défaut soit nécessaire.</p>
        <p><strong>3.6</strong>&nbsp;Pylot a le droit de suspendre ou résilier le Contrat Par écrit, avec effet immédiat, sans qu’un avis de défaut soit nécessaire, en cas de déclaration de faillite du Client, de demande ou de suspension des paiements, de cessation des activités du Client ou de liquidation de son activité.</p>
        <p><strong>3.7</strong>&nbsp;Si la société Pylot suspend l’exécution du Contrat, Pylot se réserve les droits et réclamations conformément au Contrat et des lois et normes applicable.</p>
        <p><strong>3.8</strong>&nbsp;En cas de résiliation du Contrat, les montants dus à la société Pylot par le Client deviennent immédiatement exigibles et payables (quel que soit le motif de la résiliation).</p>
        <p><strong>3.9</strong>&nbsp;En cas de résiliation du Contrat, les montants déjà facturés restent à payer par le Client et aucune obligation de révoquer cette dette ne sera créée.</p>
        <p><strong>3.10</strong>&nbsp;Le Client ne peut qu’annuler la partie de Contrat qui n'a pas encore été exécutée par Pylot.</p>

        <h2><strong>Point 4.</strong>&nbsp;Prestation de services</h2>
        <p><strong>4.1</strong>&nbsp;Après la conclusion du Contrat, la société Pylot s’engage à faire tout le nécessaire de fournir les Services dans un délais raisonnable en conformité avec les dispositions du Contrat.</p>
        <p><strong>4.2</strong>&nbsp;Le Client s’engage à donner toute assistance nécessaire et souhaitable à la société Pylot pour que celle-ci puisse fournir de façon exacte et rapide les Services. En tout état de cause, le Client s’engage à :</p>
        <ol type='a'>
          <li>fournir toutes les données et autres informations que Pylot déclare nécessaires, ou à fournir ces informations qui devraient être perçues par le Client comme essentielles pour la prestation de Services;</li>
          <li>assurer l’accès temporaire des employés d’assistance technique de Pylot dans le but d’établir une intégration (Team Viewer);</li>
          <li>assister l’installation d’agents d’intégration sur des ordinateurs locaux.</li>
        </ol>
        <p><strong>4.3</strong>&nbsp;Le Client est responsable de fournir des données et des informations exactes (via le Logiciel). Les résultats et les décisions affichés dans le Logiciel sont basés sur les données fournies par le Client. La société Pylot n’en assume aucune responsabilité.</p>
        <p><strong>4.4</strong>&nbsp;Pylot se réserve le droit d’embaucher des tiers pour exécuter le Contrat. Tous les coûts associés sont à payer par le Client si cela a été convenu entre les parties par avance.</p>
        <p><strong>4.5</strong>&nbsp;Si les employés de Pylot effectuent un travail dans les bureaux du Client ou à une autre adresse indiquée par le Client lors de l’exécution du Contrat, le Client s’engage à fournir gratuitement toute assistance et aménagements nécessaires pour permettre l'exécution de ce travail.</p>

        <h2><strong>Point 5.</strong>&nbsp;Utilisation du Logiciel</h2>
        <p><strong>5.1</strong>&nbsp;Le Client doit avoir un Compte d'utilisateur pour utiliser le Logiciel. La société Pylot s’engage à faire tout le nécessaire pour régler la configuration initiale du Compte d'utilisateur du Client dans un délai de quatre semaines dès la date de signature du Contrat. Le Client a le droit de demander la création des comptes d'utilisateurs supplémentaires pour les Utilisateurs finaux associés aux Compte de l’entreprise.</p>
        <p><strong>5.2</strong>&nbsp;Le Client s’engage à protéger le Compte (les Comptes) d'utilisateur par l’usage de noms d’utilisateur et de mots de passe. Les mots de passe doivent rester strictement confidentiels. Pylot a le droit de supposer que toutes les actions réalisées sous un Compte (des Comptes) d'utilisateur du Client après la saisie du nom d’utilisateur et du mot de passe, sont exécutées sous la surveillance et le contrôle du Client.</p>
        <p><strong>5.3</strong>&nbsp;La société Pylot s’engage à faire tout le nécessaire pour connecter le Compte d'utilisateur du Client gratuitement. Pylot se réserve le droit de facturer des frais de connexion d’un nouveau dispositif ou de connexion avec un autre système si le réglage de la connexion exige l’achat de produits et de services des tiers. Dans ce cas, Pylot s’engage à en informer préalablement le Client.</p>
        <p><strong>5.4</strong>&nbsp;Dans les cas et compte tenu des circonstances, dans lesquelles le Client assure l’accès de la société Pylot au système du Client, par exemple, par l’intermédiaire de l’outil Team Viewer, le Client assume la responsabilité de connexion et de déconnexion de la société Pylot au (du) système du Client. De plus, le Client assume la responsabilité de vérification de la bonne utilisation de l’équipement du Client. La société Pylot n’en assume aucune responsabilité.</p>

        <h2><strong>Point 6.</strong>&nbsp;Consignes d’utilisation</h2>
        <p><strong>6.1</strong>&nbsp;Il est interdit d’utiliser le Logiciel en violation des présentes Conditions générales ou les lois et normes applicables. De plus, il est strictement interdit d’utiliser le Logiciel d’une manière pouvant empêcher le fonctionnement normal, endommager ou causer un préjudice à la société Pylot ou à un tiers.</p>
        <p><strong>6.2</strong>&nbsp;Si Pylot constate que le Client ou l’Utilisateur final ont violé les présentes Conditions générales ou la loi, ou si la société Pylot reçoit une réclamation y liée, elle a le droit de prendre des mesures pour éliminer cette infraction. En ce cas, Pylot bloquera l’accès à l’information correspondante.</p>
        <p><strong>6.3</strong>&nbsp;Si, d’après l’opinion de la société Pylot, l’utilisation des systèmes informatiques ou du réseau de la société Pylot ou des tiers et/ou la prestation de services avec utilisation du réseau Internet sont entravés, abîmés ou sont autrement mis à risque,  en particulier en résultat de la transmission de grands volumes d’emails ou d’autres données, de perte de données personnelles ou d’une activité des virus, des programmes Trojan et du Logiciel analogique, Pylot a le droit de prendre toute mesure nécessaire qu’elle croit raisonnable pour éviter ou exclure ce risque. Ces mesures comprennent, entre autre, l’arrêt de fonctionnement du Logiciel et la résiliation du Contrat.</p>
        <p><strong>6.4</strong>&nbsp;La société Pylot a le droit de dénoncer à tout moment toute action criminelle révélée et apportera son aide concernant l’exécution des ordres et ordonnances dûment autorisés. De plus, la société Pylot a le droit de fournir le nom de la société, son adresse, l’adresse IP et autres données identifiant le Client / l’Utilisateur final du Logiciel au tiers qui a envoyé une réclamation concernant la violation par le Client / l’Utilisateur final du Logiciel de ses droits ou des présentes Clauses et Conditions générales, à la condition que :</p>
        <ol type='a'>
          <li>il existe des raisons sérieuses de supposer que l’information elle-même est nuisible ou illégitime par rapport au tiers;</li>
          <li>le tiers a un intérêt réel de recevoir ces données;</li>
          <li>il est probable que le cas étudié ne prévoit pas de possibilité de prendre des mesures moins radicales assurant la réception de données;</li>
          <li>et l’intérêt du tiers après l’évaluation des intérêts des parties est apprécié comme prioritaire.</li>
        </ol>
        <p><strong>6.5</strong>&nbsp;Le Client s’engage à suivre toutes les instructions raisonnables que Pylot lui communique concernant l’utilisation du Logiciel.</p>
        <p><strong>6.6</strong>&nbsp;Pylot peut récupérer auprès du Client toute perte et / ou dommage résultant de violations des règles énoncées dans le présent article. Le Client dédommage Pylot pour toute réclamation de tiers relative à une perte et / ou à un dommage résultant d'une violation des règles énoncées dans le présent article.</p>

        <h2><strong>Point 7.</strong>&nbsp;Modifications et améliorations</h2>
        <p><strong>7.1</strong>&nbsp;La société Pylot a le droit d’apporter des modifications dans le Logiciel pour corriger des erreurs, ajouter de nouvelles fonctionnalités et pour améliorer sa performance. Même si les Parties peuvent discuter ces modifications à l’avance et que le Client a le droit de faire des propositions, la décision définitive concernant l’apport des modifications est prise par la société Pylot.</p>
        <p><strong>7.2</strong>&nbsp;La société Pylot s’engage à faire tout le nécessaire pour éliminer tout défaut du Logiciel, mais Pylot est également dépendante de ses fournisseurs. La société Pylot a le droit de ne pas installer certaines mises à jour ou des versions perfectionnées de ses fournisseurs si elle croit que l’installation de ces mises à jour ou versions perfectionnées ne bénéficiera pas le fonctionnement du Logiciel.</p>
        <p><strong>7.3</strong>&nbsp;Si la mise en application des modifications et améliorations peut limiter la disponibilité du Logiciel, cette opération doit être effectuée, dans la mesure du possible, hors les Horaires d’ouverture. Un entretien d’urgence peut être exécuté à tout moment et il ne sera pas annoncé à l’avance.</p>

        <h2><strong>Point 8.</strong>&nbsp;Assistance technique</h2>
        <p><strong>8.1</strong>&nbsp;La société Pylot s’engage à assurer un degré d’assistance technique raisonnable pour les Utilisateurs finaux et le Client concernant des questions d’utilisation et de commande du Logiciel, ainsi que des questions techniques d’utilisation du Logiciel.</p>
        <p><strong>8.2</strong>&nbsp;L’assistance technique mentionnée dans le point 8.1 est fournie par l’intermédiaire du service d’assistance qui est disponible aux Horaires d’ouverture.</p>
        <p><strong>8.3</strong>&nbsp;La société Pylot s’engage à faire tout le nécessaire pour traiter les demandes arrivant au service d’assistance dans des délais raisonnables. Le temps nécessaire pour résoudre les problèmes signalés peut varier.</p>

        <h2><strong>Point 9.</strong>&nbsp;Disponibilité</h2>
        <p><strong>9.1</strong>&nbsp;La société Pylot s’engage à faire tout le nécessaire pour assurer la disponibilité du Logiciel en ligne dans la mesure du possible, mais elle ne peut pas garantir une disponibilité ininterrompue.</p>
        <p><strong>9.2</strong>&nbsp;Si d’après l’opinion de Pylot, il existe un risque pour le fonctionnement des systèmes informatiques ou du réseau de Pylot ou des tiers, par exemple, en cas d’une attaque D(DOS)-ou d’une action nuisible, la société Pylot a le droit de prendre toute mesure qu’elle croit nécessaire et raisonnable pour prévenir ce risque et pour limiter ou exclure tout dommage et/ou perte. Cela peut entraîner une disponibilité limitée au Logiciel.</p>
        <p><strong>9.3</strong>&nbsp;Pylot n'est pas obligé de faire des sauvegardes, sauf si cela a été convenu dans un Accord de niveau de service.</p>

        <h2><strong>Point 10.</strong>&nbsp;Sécurité et confidentialité</h2>
        <p><strong>10.1</strong>&nbsp;La société Pylot s’engage à faire tout le nécessaire pour protéger le Logiciel et empêcher des utilisations abusives, illégales et bloquer l’accès non autorisé aux données du Client.</p>
        <p><strong>10.2</strong>&nbsp;L’exécution du Contrat peut prévoir le traitement de données personnelles par la société Pylot par ordre du Client, en même temps le Client est reconnu le contrôleur de traitement de données et la société Pylot – traiteur de données. En cas de traitement de données personnelles, les Parties s’engagent à conclure un contrat avec le traiteur de données qui définit les conditions de traitement de données et de protection de ces données personnelles. Les termes utilisés ci-dessous ont les valeurs expliquées dans les lois appliquées relatives à la protection de la confidentialité.</p>
        <p><strong>10.3</strong>&nbsp;Le Client garantit que la saisie et le chargement des données (personnelles) pour Pylot sont conformes à la loi et que le traitement de ces données en conformité avec le Contrat ne contredit pas des lois et normes appliquées relatives à la protection de la confidentialité.</p>

        <h2><strong>Point 11.</strong>&nbsp;Travaux supplémentaires</h2>
        <p><strong>11.1</strong>&nbsp;Si le Client demande un travail supplémentaire, le Logiciel ou les Services n'entrant pas dans le champ d'application du Contrat, les Parties s’engagent à discuter les conditions d’exécution de cette demande, et la société Pylot s’engage à préparer un nouveau devis. Pylot n'effectuera pas du travail supplémentaire tant que le Client n'aura pas accepté ce devis.</p>

        <h2><strong>Point 12.</strong>&nbsp;Paiement</h2>
        <p><strong>12.1</strong>&nbsp;Pendant la Période d’essai le Client ne paie rien. Après la Période d’essai, la société Pylot s’engage à produire la facture pour toute somme due et a le droit de produire des factures à l’avance et sous forme électronique. Toute objection du Client concernant ces factures (sommes dues) ne suspend pas l'obligation de paiement.</p>
        <p><strong>12.2</strong>&nbsp;Toutes les factures sont à payer dans un délai de 14 jours, excepté les cas quand un autre délai de paiement est indiqué dans la facture ou si un autre délai de paiement est défini par les Parties dans un document Par écrit.</p>
        <p><strong>12.3</strong>&nbsp;En cas de non-paiement d’une facture dans le délai indiqué, il est reconnu que le Client est en défaillance et la société Pylot n’est pas obligée à envoyer au Client un avis de défaillance. En ce cas, Pylot a le droit de suspendre le fonctionnement du Logiciel entièrement ou partiellement. Pylot n’assume pas la responsabilité des dommages et pertes causés au Client suite à l’arrêt du fonctionnement du Logiciel.</p>
        <p><strong>12.4</strong>&nbsp;Toutes les sommes dues exigées par la société Pylot sont à payer immédiatement si le Client a déposé son bilan, a demandé ou a obtenu une suspension des paiements, ses activités sont arrêtées ou si son entreprise est en liquidation.</p>
        <p><strong>12.5</strong>&nbsp;Toutes les prix de la société Pylot sont indiquées en euros, hors TVA et excluent toute autre taxe gouvernementale.</p>
        <p><strong>12.6</strong>&nbsp;Si les sommes à payer sont définies à la base de l’information fournie par le Client et cette information est incorrecte, la société Pylot a le droit de corriger les sommes à payer de la manière correspondante, même si le Contrat est déjà signé.</p>
        <p><strong>12.7</strong>&nbsp;Les frais dus périodiquement peuvent être ajustés chaque année en tenant compte de l'Indice des prix à la consommation ou d'un autre indice ou standard convenu par les Parties. Le Client n’a pas le droit de résilier le Contrat à cause d’un tel ajustement.</p>
        <p><strong>12.8</strong>&nbsp;Si le Client ne paie pas des sommes dues dans les délais prévus, il assume la responsabilité de paiement des indemnités commerciales prévues par la loi mentionnées dans le Chapitre 6 : 119a du Code Civil du Royaume des Pays-Bas, calculées sur la somme non payée, sans qu’une notice de défaillance soit requise.</p>
        <p><strong>12.9</strong>&nbsp;Si, après la réception de la demande de remboursement ou de l’avis de non-exécution des obligations contractuelles, le Client ne règle pas ses comptes, la société Pylot peut initier une procédure de recouvrement de créances, et, en ce cas, le Client assume la responsabilité de payer entièrement non seulement la somme générale, mais toutes les frais judiciaires et autres, y compris les frais d’engagement des juristes et des experts.</p>

        <h2><strong>Point 13.</strong>&nbsp;Responsabilité et force majeure</h2>
        <p><strong>13.1</strong>&nbsp;Compte tenu du fait que le Client ne paie pas l’utilisation du Logiciel pendant la Période d’essai, la société Pylot n’assume pas la responsabilité de pertes et/ou dommages causés en résultat de la non-exécution des obligations, des actions illégales ou d’autres événements qui ont lieu pendant la Période d’essai, excepté les cas où ces pertes et/ou dommages sont causés par (1) une faute ou par une négligence intentionnelle de la direction de la société Pylot ou (2) sont liés à la mort ou à une blessures corporelle.</p>
        <p><strong>13.2</strong>&nbsp;Après l’écoulement de la Période d’essai, la responsabilité de la société Pylot des pertes et/ou dommages causés en résultat de non-exécution des obligations, des actions illégales ou d’autres événements, est limitée à la somme payée par l’assureur à Pylot. La société Pylot s’engage à faire tout le nécessaire pour faire l’assureur payer toutes les sommes correspondantes. Si aucun paiement n'est effectué, la responsabilité pour chaque événement (lorsqu'une série d'événements consécutifs est considérée comme constituant un seul événement) sera limitée au montant (TVA non comprise) que le Client a payé en vertu du Contrat au cours des six mois précédant la perte et / ou les dommages se produisant.</p>
        <p><strong>13.3</strong>&nbsp;Pylot n’assume la responsabilité que des pertes et / ou des dommages directs résultant d'un manquement  imputable à l'exécution du Contrat.</p>
        <p><strong>13.4</strong>&nbsp;Toute restriction ou exclusion de la responsabilité prévue par le Contrat ne s’applique pas aux cas où les pertes et/ou dommages (1) ont apparu suite aux actions intentionnelles nuisibles ou par une négligence intentionnelle des dirigeants de la société Pylot ou (2) sont liés à la mort ou blessure corporelle.</p>
        <p><strong>13.5</strong>&nbsp;Tout droit de réclamer une indemnité est à tout moment soumis à la condition que le Client informe Pylot de la perte et / ou du dommage Par écrit dans un délai de 30 jours maximum à compter de sa découverte.</p>
        <p><strong>13.6</strong>&nbsp;La société Pylot n’est pas tenue d’exécuter une obligation contractuelle si cette exécution est impossible à cause d’une force majeure. La société Pylot n’assume pas la responsabilité des dommages et/ou pertes causés par une force majeure.</p>
        <p><strong>13.7</strong>&nbsp;La force majeure est considérée comme existant dans tous les cas de rupture de l’alimentation électrique, des pannes de fonctionnement du réseau Internet et de l’infrastructure de télécommunication, des attaques réseau (y compris des attaques D(DOS), des attaques du logiciel nuisible ou empêchant un fonctionnement normal, des troubles civils, des catastrophes naturelles, des actes de terrorisme, la mobilisation, la guerre, l’interdiction de l’importation et de l’exportation, des grèves, l’arrêt des fournitures, des incendies, des inondations et tout événement empêchant la société Pylot d’exécuter ses obligations contractuelles ou la faisant se heurter contre des difficultés à cause des fournisseurs indépendamment des raisons.</p>
        <p><strong>13.8</strong>&nbsp;Si une force majeure dure plus de 90 jours, les Parties ont le droit d’envoyer un avis écrit de résiliation du Contrat qui entre immédiatement en vigueur. Les Services déjà fournis par la société Pylot avant l’arrivée d'une force majeure et pendant son action sont payés proportionnellement.</p>
        <p><strong>13.9</strong>&nbsp;Les Parties confirment que le Chapitre 6 : 271 du Code Civil du Royaume des Pays-Bas et son application aux dispositions exposées ci-dessous sont exclus.</p>
        <p><strong>13.10</strong>&nbsp;Tout droit d’exiger une compensation à tout temps est octroyé à la condition d’envoi par le Client d’un avis écrit informant des pertes et/ou dommages à la société Pylot pendant 30 jours à compter de la date de détection de ces pertes/dommages.</p>

        <h2><strong>Point 14.</strong>&nbsp;Droits de propriété intellectuelle</h2>
        <p><strong>14.1</strong>&nbsp;Tous les droits de propriété intellectuelle sur le Logiciel et tous les éléments fournis par Pylot en vertu du Contrat appartiennent à Pylot ou à ses concédants de licence.</p>
        <p><strong>14.2</strong>&nbsp;Le Client reçoit de la société Pylot les droits d’utiliser à titre non exclusif et non transférable, ne pouvant faire l’octroi d’une sous-licence du Logiciel et de tout Produit si ces droits sont prévus par le Contrat ou  qui ont par ailleurs été accordés Par écrit. Les droits d’utilisation s’appliquent pendant toute la durée du Contrat.</p>
        <p><strong>14.3</strong>&nbsp;Le Client n’est pas autorisé à modifier le Logiciel, excepté les cas où cela est exigé par les lois en vigueur.</p>
        <p><strong>14.4</strong>&nbsp;La société Pylot peut prendre des mesures techniques ou autres pour protéger le Logiciel. Lorsque Pylot a mis en place de telles mesures de sécurité, le Client ne peut ni supprimer ni contourner cette sécurité.</p>
        <p><strong>14.5</strong>&nbsp;Le Client n'est pas autorisé à supprimer ou à modifier tout message ou désignation de Droits de propriété intellectuelle du Logiciel. Il est également interdit de supprimer tout message ou désignation faisant partie du Logiciel concernant leur caractère confidentiel.</p>

        <h2><strong>Point 15.</strong>&nbsp;Corrections apportées aux Conditions générales</h2>
        <p><strong>15.1</strong>&nbsp;La société Pylot a le droit d’apporter à tout moment des corrections aux présentes Conditions générales.</p>
        <p><strong>15.2</strong>&nbsp;La société Pylot s’engage à faire tout le possible pour annoncer au Client, par courrier ou par notification lors de connexion à Pylot, les modifications ou les compléments apportés du moins trente jours avant leur prise d'effet, afin de permettre au Client de les prendre en compte.</p>
        <p><strong>15.3</strong>&nbsp;Si le Client ne veut pas accepter certaine correction ou mise à jour apportée, le Client peut envoyer un avis de résiliation du Contrat avant la date d’entrée en vigueur de ces corrections. Si le Client utilise le Logiciel après leur entrée en vigueur, cela signifie qu’il accepte toutes les modifications et corrections apportées aux Conditions générales.</p>
        <p><strong>15.4</strong>&nbsp;Les deux Parties s’engagent à collaborer en ce qui concerne l’apport de corrections ou de compléments aux Conditions générales, nécessaires à cause de l’introduction de nouvelles lois ou de leur modification. Ces modifications peuvent être appliquées sans un préavis de Pylot, mais Pylot s’engage à faire tout le possible pour informer son Client, sans que le Client ait le droit de résilier le Contrat.</p>
        <p><strong>15.5</strong>&nbsp;La procédure décrite dans cet article ne s’applique pas aux corrections insignifiantes. La société Pylot peut apporter ces corrections sans donner un préavis, et le Client n’a pas le droit de résilier le Contrat à cause de ces corrections.</p>

        <h2><strong>Point 16.</strong>&nbsp;Confidentialité</h2>
        <p><strong>16.1</strong>&nbsp;Toutes les informations que les Parties se communiquent avant la conclusion du Contrat, pendant et après l’écoulement de sa durée, ou comptent dans leur Services, sont considérées comme de l’information confidentielle (ci-après <strong>«Information confidentielle»</strong>). Les Parties imposeront également cette obligation à leurs employés, ainsi qu'à tous les tiers engagés pour l’exécution du Contrat.</p>
        <p><strong>16.2</strong>&nbsp;En ce qui concerne l’Information confidentielle, les Parties s’engagent à :</p>
        <ol type='a'>
          <li>prendre toutes les mesures raisonnables pour assurer la sécurité ou la sauvegarde de l’Information confidentielle en garantissant au minimum le même niveau de sécurité que l’autre Partie assure en ce qui concerne la sécurité de sa propre Information confidentielle ;</li>
          <li>ne pas utiliser l’Information confidentielle dans un autre but que celui approuvé (l’exécution du présent Contrat) ;</li>
          <li>ne pas conserver l’Information confidentielle plus longtemps que c’est nécessaire pour l’exécution des obligations approuvées, et rendre ces données, y compris toutes les copies fabriquées, à l’autre Partie tout de suite après l’exécution complète des obligations y liées, ou détruire ces données après la transmission de la copie de l’Information confidentielle à l’autre Partie et la réception Par écrit de sa confirmation.</li>
        </ol>
        <p><strong>16.3 </strong>&nbsp;Si et dans la mesure expressément convenue entre les Parties, la société Pylot a le droit de partager certaine Information confidentielle aux autres Clients de Pylot. Le Client décide, à son entière discrétion, quelle Information confidentielle peut être partagée par Pylot et avec qui.</p>

        <h2><strong>Point 17.</strong>&nbsp;Expiration du Contrat</h2>
        <p><strong>17.1</strong>&nbsp;La société Pylot sauvegarde toutes les données et informations du Client pendant trente jours après l’expiration du Contrat. À la condition que le Client a exécuté toutes ses obligations contractuelles, y compris celles concernant le paiement des factures, Pylot s’engage à faire tout le possible pour fournir au Client une copie des données et autres détails, à la demande du Client. C’est un service payant.</p>
        <p><strong>17.2</strong>&nbsp;La copie sera envoyée dans un format de fichier standard, à déterminer par Pylot. Les frais raisonnables de copie de fichiers et de données prévus par ce point sont à la charge du Client.</p>

        <h2><strong>Point 18.</strong>&nbsp;Clauses finales</h2>
        <p><strong>18.1</strong>&nbsp;Le présent Contrat est exclusivement régi par le droit néerlandais.</p>
        <p><strong>18.2</strong>&nbsp;Si les dispositions obligatoires de la loi en vigueur n’exigent pas le contraire, tout litige entre les Parties associées est soumis au tribunal compétent du Royaume des Pays-Bas situé dans la province où se trouve le siège social de la société Pylot.</p>
        <p><strong>18.3</strong>&nbsp;La société Pylot peut céder ses droits et obligations liés au présent Contrat à un tiers qui accepte la direction de Pylot ou de ses activités commerciales.</p>
        <p><strong>18.4</strong>&nbsp;Le Client s’engage à informer immédiatement la société Pylot de tout changement de nom, d’adresse (postale), d’adresse e-mail, de téléphone et d’autres informations et coordonnées liées avec l’exécution du Contrat.</p>
        <p><strong>18.5</strong>&nbsp;Les journaux d’enregistrement et la correspondance sauvegardés par Pylot sont considérés correctes sauf le cas où le Client fournit des preuves confirmant le contraire.</p>
        <p><strong>18.6</strong>&nbsp;Si une clause du Contrat est reconnue nulle ou invalide, ce fait n'affecte pas aucunement la validité des autres clauses. En ce cas, les Parties s’engagent à remplacer cette clause nulle par une ou plusieurs nouvelles clauses reflétant les objectifs de la clause initiale, autant que possible et conforme à la législation en vigueur.</p>
      </article>
    );
  }
}

export default withRouter(General);
