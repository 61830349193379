import { get } from 'lodash';

// import queryString from 'query-string';
import history from '../../history';

// import { useHistory } from 'react-router-dom';
import addGetParameters from '../addGetParameters';

/**
 * Check is required update url search
 *
 * @param params {object} - new params
 * @returns {boolean}
 */
/*
const isUpdateRequired = (params) => {
  const paramKeys = Object.keys(params);
  if (paramKeys.length > 0) {
    return true;
  }

  let required = false;
  const query = get(history, 'location.search');
  const parsedQuery = queryString.parse(query);
  for (let i = 0; i < paramKeys.length; i += 1) {
    const existParams = parsedQuery[paramKeys[i]];
    if (!existParams || existParams !== parsedQuery[paramKeys[i]]) {
      required = true;
    }
  }

  return required;
};
*/
/**
 * Обновляет search в урле. Функция с сайдэффектом, не возвращает ничего.
 *
 * @param {object} params - Новые параметры для роута.
 */
const updateLocationSearch = (params, replace = false) => {
  const searchString = addGetParameters(get(history, 'location.search'), params);
  const payload = {
    search: searchString// remove first '?' symbol
  };
  if (replace) {
    history.replace(payload);
  } else {
    history.push(payload);
  }
};

export default updateLocationSearch;
