import { connect } from 'react-redux';
import { getFormValues, getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

import NameEditorComponent from './NameEditorComponent';

const mapDispatchToProps = {
  // userFormOnSubmit,
};

const mapStateToProps = (state, props) => ({
  formValues: getFormValues('plantNameEditorForm')(state),
  formSyncErrors: getFormSyncErrors('plantNameEditorForm')(state),

  ...props,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NameEditorComponent));
