import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import Truncate from 'react-truncate';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import moment from 'moment-timezone';
import classnames from 'classnames';
import { get, uniqueId } from 'lodash';

import { getWeeksOfYear } from '../../../../helpers/getWeekNumbers';
import { getMlNameDirect, getProductTypeName } from '../../../../helpers/getVarietyName';
import getDateFormat from '../../../../helpers/getDateFormat';
import numbersFormatting from '../../../../helpers/numbersFormatting';
import isTouchDevice from '../../../../helpers/isTouchDevice';

import CompartmentIcon from '../../../Icons/CompartmentIcon';

import tooltipStyles from '../../../Tooltip/index.module.css';
import styles from './DataQualityTable.module.css';

const TH_OFFSET = 56; // Ширина ячейки, чтобы был отступ до текущей недели

class DataQualityTable extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    year: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    filteredGreenhouses: PropTypes.array.isRequired,
    dataTypeOptions: PropTypes.array.isRequired,
    organizationSlug: PropTypes.string.isRequired,

    fruitClasses: PropTypes.array.isRequired,
    varieties: PropTypes.array.isRequired,
    allPlantingCycles: PropTypes.array.isRequired,

    handlerResetFilters: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.currentWeek = React.createRef();
    this.tableScroller = React.createRef();
  }

  state = {
    currentWeekNo: moment().isoWeek()
  };

  componentDidMount() {
    this.scrollCurrentWeek();
  }

  getCellLink = (organizationSlug, year, dataOptionValue, id) => {
    if (dataOptionValue === 'plans') {
      return `/${organizationSlug}/plans/edit?year=${year}`;
    } if ((dataOptionValue === 'harvest' || dataOptionValue === 'measurements') && !id) {
      return `/${organizationSlug}/crops`;
    } if (dataOptionValue === 'harvest') {
      return `/${organizationSlug}/crops/${id}/harvest`;
    } if (dataOptionValue === 'measurements') {
      return `/${organizationSlug}/crops/${id}/measurements`;
    }

    return `/${organizationSlug}/settings/data-quality?year=${year}`;
  };

  scrollCurrentWeek = () => this.tableScroller.current.scrollTo(this.currentWeek.current.offsetLeft - TH_OFFSET, 0);

  renderHeaderRows = (tooltipId, formatMessage, weeks) => {
    const { currentWeekNo } = this.state;

    return (
      <tr className={styles.tableHeader}>
        <th colSpan={2} className={classnames(styles.cycleRow, styles.firsColumn)}>
          {formatMessage({ id: 'cycle.table.cycle' })}
        </th>
        {weeks.map(week => (
          <th
            key={`tableWeek-${week.weekNo}`}
            className={styles.weekNo}
            ref={currentWeekNo === week.weekNo ? this.currentWeek : null}
            data-for={tooltipId}
            data-tip={`${moment(week.startDate, 'YYYY-MM-DD').format(getDateFormat('ll'))} – ${moment(week.endDate, 'YYYY-MM-DD').format(getDateFormat('ll'))}`}
          >
            {`${formatMessage({ id: 'dashboards.week' })} ${week.weekNo}`}
          </th>
        ))}
      </tr>
    );
  };

  renderDataCell = (isCurrentWeek, dataOptionValue, { plantingCycleId }, day) => {
    const {
      intl: { formatMessage },
      organizationSlug,
      year,
    } = this.props;

    const isGoodDay = day === 'GOOD';
    const isBadDay = day === 'BAD';

    let dayText = '';

    if (isGoodDay) {
      dayText = formatMessage({ id: 'button.yes' });
    } else if (isBadDay) {
      dayText = formatMessage({ id: 'button.no' });
    }

    return (
      <td
        key={`dataCell-${uniqueId()}`}
        className={classnames(
          styles.dataCell,
          {
            [styles.green]: day === 'GOOD',
            [styles.red]: day === 'BAD',
            [styles.currentWeek]: isCurrentWeek,
          }
        )}
      >
        {isBadDay ? (
          <NavLink
            to={this.getCellLink(organizationSlug, year, dataOptionValue, plantingCycleId)}
            className={styles.tdLink}
          >
            {dayText}
          </NavLink>
        )
          :
          dayText}
      </td>
    );
  };

  renderTooltipText = (quality) => {
    const { intl: { formatMessage } } = this.props;

    if (quality === 'GOOD') {
      return formatMessage({ id: 'dataQuality.tooltipGood' });
    }

    if (quality === 'BAD') {
      return formatMessage({ id: 'dataQuality.tooltipBad' });
    }

    return formatMessage({ id: 'dataQuality.tooltipNoData' });
  };

  renderDataReportName = (tooltipId, name, quality) => (
    <td
      className={classnames(styles.dataReportName, styles.firsColumn)}
    >
      <div className={styles.dataReportNameContent}>
        <span
          className={classnames(
            styles.qualitySquare,
            {
              [styles.green]: quality === 'GOOD',
              [styles.red]: quality === 'BAD',
            }
          )}
          data-tip={this.renderTooltipText(quality)}
          data-for={tooltipId}
        />
        <span>{name}</span>
      </div>
    </td>
  );

  renderDataQualityRow = (tooltipId, dataOption, { quality, data }, plantingCycle) => {
    const { currentWeekNo } = this.state;

    return (
      <>
        {this.renderDataReportName(tooltipId, dataOption.label, quality)}
        {data.map((day, index) => this.renderDataCell(currentWeekNo === (index + 1), dataOption.value, plantingCycle, day))}
      </>
    );
  };

  renderPlantingCycleRow = (tooltipId, plantingCycles) => plantingCycles.map((plantingCycle) => {
    const {
      intl, intl: { formatMessage, locale }, dataTypeOptions, organizationSlug,
      fruitClasses, varieties, allPlantingCycles,
    } = this.props;

    const { startDate, endDate } = plantingCycle;

    const textEndDate = endDate ? moment(endDate).format(getDateFormat('lll')) : formatMessage({ id: 'cycle.present' });

    // Рендерим только неотфильтрованные строки типов данных
    const dataTypeRows = dataTypeOptions.reduce((acc, dataOption) => {
      if (dataOption.checked) {
        return [...acc, this.renderDataQualityRow(tooltipId, dataOption, plantingCycle[dataOption.value], plantingCycle)];
      }

      return acc;
    }, []);


    if (dataTypeRows.length === 0) {
      return null;
    }

    const { plantingCycleId } = plantingCycle;

    const isOneRow = dataTypeRows.length === 1;

    const species = get(plantingCycle, 'variety.species');
    const speciesName = formatMessage({ id: `plantingCycles.species.${species}` });

    let fullName = `${getMlNameDirect(plantingCycle.variety, locale)} ${speciesName}`;

    if (plantingCycleId) {
      const plantingCycleSource = allPlantingCycles.find(item => item.id === plantingCycleId);
      const varietyId = get(plantingCycleSource, 'relationships.variety.data.0.id');
      const fruitClassId = get(plantingCycleSource, 'relationships.fruitClass.data.0.id');
      const variety = varieties.find(item => item.id === varietyId);
      const fruitClass = fruitClasses.find(item => item.id === fruitClassId);

      fullName = getProductTypeName({
        intl,
        variety,
        fruitClass,
        targetWeight: get(plantingCycle, 'attributes.targetWeight'),
        withSpecies: true,
      });
    }

    const varietyTooltipId = `variery-title-tooltip-${plantingCycle.variety.id}`;
    const tooltipData = isOneRow ? {
      'data-for': varietyTooltipId,
      'data-tip': fullName
    } : {};

    return (
      <Fragment key={`tablePlantingCycle-${plantingCycleId || uniqueId()}`}>
        <tr
          className={classnames(
            styles.dataRow,
            { [styles.oneRow]: isOneRow }
          )}
        >
          <td
            rowSpan={dataTypeRows.length}
            className={classnames(styles.varietyNameCol, styles.firsColumn)}
            style={{ height: isOneRow ? 54 : dataTypeRows.length * 36 }}
          >
            <NavLink
              to={plantingCycleId ? `/${organizationSlug}/crops/${plantingCycleId}` : `/${organizationSlug}/crops`}
              className={styles.plantingCycleLink}
            >
              <div className={styles.speciesName} {...tooltipData}>
                {isOneRow ? (
                  <Truncate lines={1}>
                    {fullName}
                  </Truncate>
                ) : fullName}
                {isOneRow && (
                <ReactTooltip
                  className={classnames(tooltipStyles.smallTooltip, styles.tooltip)}
                  id={varietyTooltipId}
                  effect='solid'
                  event={isTouchDevice() ? 'click' : null}
                  html
                />
                )}
              </div>
              <div className={styles.period}>
                <span>
                  <span className={styles.startDate}>{ moment(startDate).format(getDateFormat('lll'))}</span>
                  {` – ${textEndDate}`}
                </span>
              </div>
            </NavLink>
          </td>
          {dataTypeRows[0]}
        </tr>
        {/* Не отрендерится, если только одна строка, поэтому тут не обязательно вешать isOneRow */}
        {dataTypeRows[1] && (
          <tr className={styles.dataRow}>
            {dataTypeRows[1]}
          </tr>
        )}
        {dataTypeRows[2] && (
          <tr className={styles.dataRow}>
            {dataTypeRows[2]}
          </tr>
        )}
      </Fragment>
    );
  });

  renderCompartmentRow = (tooltipId, weeks, compartments, formatMessage) => {
    const { currentWeekNo } = this.state;

    return compartments.map(compartment => (
      <Fragment key={`tableCompartment-${compartment.id}`}>
        <tr className={styles.compartmentRow}>
          <td colSpan={2} className={classnames(styles.compartmentNameCol, styles.firsColumn)}>
            <div className={styles.compartmentHeader}>
              <CompartmentIcon className={styles.compartmentIcon} />
              {get(compartment, 'name')}
              <span className={styles.compartmentFloor}>
                {` (${numbersFormatting(get(compartment, 'floorArea'))} ${formatMessage({ id: 'cunits.mini.squareMeter' })})`}
              </span>
            </div>
          </td>
          {weeks.map(week => (
            <td
              key={`compartmentWeek-${week.weekNo}`}
              className={classnames({
                [styles.currentWeek]: currentWeekNo === week.weekNo,
              })}
            />
          ))}
        </tr>
        {this.renderPlantingCycleRow(tooltipId, get(compartment, 'plantingCycles'))}
      </Fragment>
    ));
  };

  renderResetButton = () => {
    const {
      intl: { formatMessage }, handlerResetFilters
    } = this.props;

    return (
      <span
        className={styles.resetButton}
        onClick={handlerResetFilters}
        role='button'
        tabIndex={0}
      >
        {formatMessage({ id: 'dataQuality.emptyStateText2' })}
      </span>
    );
  };

  renderEmptyState = () => {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <div className={styles.emptyState}>
        <span>
          {`${formatMessage({ id: 'dataQuality.emptyStateText1' })} `}
          {this.renderResetButton()}
          {` ${formatMessage({ id: 'dataQuality.emptyStateText3' })}`}
        </span>
      </div>
    );
  };

  render() {
    const {
      intl: { formatMessage },
      year,
      filteredGreenhouses,
      dataTypeOptions,
    } = this.props;

    const weeks = getWeeksOfYear(year);

    const filteredDataTypeOptions = dataTypeOptions.filter(dataOption => (dataOption.checked));

    const isNotEmpty = filteredGreenhouses.length > 0 && filteredDataTypeOptions.length > 0;

    const tooltipId = `data-quality-tooltip-${uniqueId()}`;

    return (
      <div>
        <div className={styles.tableWrapper}>
          <div
            ref={this.tableScroller}
            className={classnames(
              styles.tableScroller,
              { [styles.withoutOverflow]: !isNotEmpty }
            )}
          >
            <table className={styles.table}>
              <thead>
                {this.renderHeaderRows(tooltipId, formatMessage, weeks)}
              </thead>
              <tbody>
                {isNotEmpty &&
                  this.renderCompartmentRow(tooltipId, weeks, filteredGreenhouses, formatMessage)}
              </tbody>
            </table>
          </div>
        </div>
        {!isNotEmpty &&
          this.renderEmptyState()}

        {/* Чтобы не делать rebuild в componentDidUpdate */}
        {isNotEmpty && (
          <ReactTooltip
            className={classnames(tooltipStyles.smallTooltip, styles.tooltip)}
            id={tooltipId}
            effect='solid'
            event={isTouchDevice() ? 'click' : null}
            html
          />
        )}
      </div>
    );
  }
}

export default injectIntl(DataQualityTable);
