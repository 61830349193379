import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import DashboardReportTable from '../../../DashboardReportTable';
import DashboardReportBreakdown from '../DashboardReportBreakdown';
import DashboardReportSelectedChart from '../DashboardReportSelectedChart';
import Typography from '../../../Typography';

import styles from './DashboardReport.module.css';


class DashboardReport extends PureComponent {
  static propTypes = {
    graphKeyId: PropTypes.string.isRequired,
    meter: PropTypes.string.isRequired,
    report: PropTypes.object,
    sorting: PropTypes.object,
    currentUnitKind: PropTypes.string,
    currentSpecies: PropTypes.string,
    currentVarietyId: PropTypes.string,
    currentFruitClassCode: PropTypes.string,
    onRowClick: PropTypes.func,
    onUpdateSorting: PropTypes.func,
  };

  static defaultProps = {
    currentUnitKind: 'absolute',
    currentSpecies: undefined,
    currentVarietyId: null,
    currentFruitClassCode: null,
    onRowClick: null,
    onUpdateSorting: null,
    report: null,
    sorting: null,
  };

  handlerRowClick = (options) => {
    const { onRowClick, graphKeyId } = this.props;

    if (onRowClick) {
      onRowClick({ ...options, key: graphKeyId });
    }
  }

  render() {
    const {
      meter,
      report,
      sorting,
      currentSpecies,
      currentFruitClassCode,
      currentVarietyId,
      currentUnitKind,
      onUpdateSorting,
    } = this.props;

    const clearKey = meter.replace('Meter', '');

    return (
      <div className={styles.report} key={`report-${clearKey}`}>
        <Typography variant='h2' className={styles.reportTitle}>
          <FormattedMessage id={`energy.${meter.replace('Meter', '')}`} />
        </Typography>

        <div className={styles.charts}>
          <div className={styles.firstGraph}>
            <DashboardReportSelectedChart meter={meter} graphKeyId={meter.replace('Meter', '')} report={report} />
          </div>

          <div className={styles.secondGraph}>
            <DashboardReportBreakdown
              meter={meter}
              report={report}
              currentUnitKind={currentUnitKind}
              currentSpecies={currentSpecies}
              currentFruitClassCode={currentFruitClassCode}
              currentVarietyId={currentVarietyId}
            />
          </div>
        </div>

        <DashboardReportTable
          meter={meter}
          graphKeyId={meter.replace('Meter', '')}
          report={report}
          sorting={sorting}
          onRowClick={this.handlerRowClick}
          onUpdateSorting={onUpdateSorting}
        />
      </div>
    );
  }
}

export default injectIntl(DashboardReport);
