import React, { useCallback, useMemo } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { uniqueId } from 'lodash';

import LinesGraph from 'components/LinesGraph/components/LinesGraph/LinesGraph';

import moment from 'moment';
import { API_DATE_FORMAT } from 'helpers/defaultDates';
import { getNameByLocal } from 'helpers/getNameByLocal';

import {
  customTicksFunction,
  tickTimeDisplayFormat,
  getForecastLineChartItems,
  getLinesMaxValue,
  getLinesMinValue,
  renderTooltipContent, useOverlayData,
} from '../../helpers';

import styles from './HarvestForecastAssistantPanel.module.css';


const HarvestForecastChartBlock = ({
  intl,
  assistant,
  isAssistantFetching,
  dataKey,
  chartData,
  cropTill,
  cropEmptyValues,
  showHistoricalPeriod,
  chartMargins,
}) => {
  const { formatMessage, locale } = intl;

  const getNameByKey = useCallback(() => formatMessage({ id: `forecast.assistant.${dataKey}` }), [formatMessage, dataKey]);

  const getLines = useCallback((name, units, points) => {
    const {
      descriptor: {
        periods
      },
      realDataTill
    } = assistant;
    const till = realDataTill ? moment.utc(realDataTill, API_DATE_FORMAT) : null;

    return getForecastLineChartItems(
      assistant,
        points || {},
        units || '',
        periods,
        uniqueId('chart-'),
        name,
        '#1DBADF',
        till,
        cropTill
    );
  }, [assistant, cropTill]);

  const getLinesByData = useCallback((name) => {
    if (!chartData || !chartData?.data) {
      return [];
    }

    const data = chartData?.data;

    return getLines(name, data?.units, data?.points);
  }, [getLines, chartData]);

  const getLinesByKey = useCallback((name) => {
    if (!assistant || !assistant?.data[dataKey]) {
      return [];
    }
    const {
      data,
    } = assistant;

    return getLines(name, data[dataKey]?.units, data[dataKey]?.points);
  }, [getLines, assistant, dataKey]);

  const name = useMemo(() => {
    if (dataKey) {
      return getNameByKey();
    }

    return getNameByLocal(chartData, locale, 'title');
  }, [getNameByKey, dataKey, chartData, locale]);

  const lines = useMemo(() => {
    if (dataKey) {
      return getLinesByKey(name);
    }

    return getLinesByData(name);
  },
  [getLinesByKey, getLinesByData, dataKey, name]);

  const maxValue = useMemo(() => getLinesMaxValue(lines), [lines]);
  const minValue = useMemo(() => getLinesMinValue(lines), [lines]);

  const isEmpty = useMemo(() =>
    !isAssistantFetching && (lines && lines.length === 0),
  [isAssistantFetching, lines]);

  const overlayData = useOverlayData(showHistoricalPeriod, assistant);

  return (
    <div className={styles.chartBlock}>
      <div className={styles.chartBlockTitle}>{name}</div>
      <LinesGraph
        wrapperClassName={styles.chartWrapper}
        withoutPoints
        isDataFetching={isAssistantFetching}
        lines={lines}
        maxValue={maxValue}
        minValue={minValue}
        renderTooltipContent={tooltipDate => renderTooltipContent(tooltipDate, lines, intl)}
        isEmbeddedEmptyState
        isEmpty={isEmpty}
        emptyTitle={formatMessage({ id: 'forecast.emptyNoData' })}
        isLegendDisabled
        defaultSize={{
          height: 180
        }}
        cropEmptyValues={cropEmptyValues}
        xTicks={5}
        customTicksFunction={customTicksFunction}
        tickTimeDisplayFormat={tickTimeDisplayFormat}
        overlay={overlayData}
        margin={chartMargins}
        isHoverLineSizeNeedCalc
      />
    </div>
  );
};

HarvestForecastChartBlock.propTypes = {
  intl: intlShape.isRequired,
  assistant: PropTypes.object.isRequired,
  isAssistantFetching: PropTypes.bool.isRequired,
  dataKey: PropTypes.string,
  chartData: PropTypes.object,
  cropTill: PropTypes.bool,
  cropEmptyValues: PropTypes.bool,
  showHistoricalPeriod: PropTypes.bool,
  chartMargins: PropTypes.object.isRequired,
};

HarvestForecastChartBlock.defaultProps = {
  cropTill: false,
  cropEmptyValues: false,
  showHistoricalPeriod: true,
  dataKey: undefined,
  chartData: undefined,
};

export default injectIntl(HarvestForecastChartBlock);
