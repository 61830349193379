import classnames from 'classnames';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import React, { Component } from 'react';

import MicroForm from '../MicroForm';
import ArrowRightIconFull from '../Icons/ArrowRightIconFull';

import styles from './MultilevelMenu.module.css';


export default class MultilevelMenu extends Component {
  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      form: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        cancelTitle: PropTypes.string,
        submitTitle: PropTypes.string,
        validate: PropTypes.func,
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
        fields: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          component: PropTypes.func.isRequired,
          props: PropTypes.object,
          isRequired: PropTypes.bool,
          options: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
          })),
        })).isRequired,
      }),
    })),
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    className: null,
    items: null,
    onSubmit: null,
  };

  state = {
    selectedOption: null,
    position: 'right',
  };

  handlerResize = throttle(() => {
    this.getPopupPosition();
  }, 100);

  wrapperRef = React.createRef();

  componentDidMount() {
    window.addEventListener('resize', this.handlerResize);

    setTimeout(() => {
      this.handlerResize();
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResize);
  }

  getPopupPosition() {
    if (this.wrapperRef && this.wrapperRef.current) {
      const rect = this.wrapperRef.current.getBoundingClientRect();
      const rightBorder = rect.right + window.scrollX + 280;
      const isRightOverflow = rightBorder > window.innerWidth - 8;

      this.setState({
        position: isRightOverflow ? 'left' : 'right',
      });
    }
  }

  handlerSelectOption = ({ id }) => {
    const { selectedOption } = this.state;

    if (!selectedOption || id !== selectedOption) {
      this.setState({ selectedOption: id });
    }
  };

  renderForm = ({ id, form }) => {
    const {
      selectedOption,
      position,
    } = this.state;

    if (selectedOption !== id) {
      return null;
    }

    const { onSubmit, onCancel } = form;

    return selectedOption === id && (
      <div className={classnames(styles.popup, { [styles.right]: position === 'right', [styles.left]: position === 'left' })}>
        <MicroForm
          {...form}
          form={form.id}
          onSubmit={(values) => {
            this.setState({ selectedOption: null });

            onSubmit(values);
          }}
          onCancel={() => {
            this.setState({ selectedOption: null });

            onCancel();
          }}
        />
      </div>
    );
  };

  render() {
    const {
      className,
      items,
    } = this.props;

    const {
      selectedOption,
    } = this.state;

    if (!items) {
      return null;
    }

    return (
      <div className={classnames(styles.wrapper, className)} ref={this.wrapperRef}>
        {items.map((item) => {
          const { id, title, form } = item;

          const renderedForm = this.renderForm({ id, form });

          return (
            <div
              key={`item-${id}`}
              className={classnames(
                styles.option,
                { [styles.selected]: selectedOption === id }
              )}
              onClick={() => this.handlerSelectOption({ id })}
              onMouseEnter={() => this.handlerSelectOption({ id })}
              role='button'
              tabIndex={0}
            >
              {title}
              <ArrowRightIconFull className={styles.arrowRightIcon} />
              {renderedForm}
            </div>
          );
        })}
      </div>
    );
  }
}
