import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component, } from 'react';
import { withRouter } from 'react-router';
import { injectIntl, intlShape } from 'react-intl';


import { uniqueId } from 'lodash';
import { preparePlantingCycleFormData } from 'components/PlantingCycleForm/PlantingCycleForm';
import { ReactComponent as ArrowBackIcon } from './assets/arrow_back.svg';
import PlantingCycleForm from '../../../PlantingCycleForm';
import CircleLoader from '../../../CircleLoader';


import loaderStyles from '../../../CircleLoader/CircleLoader.module.css';
import styles from './UpdatePlantingCycle.module.css';


class UpdatePlantingCycle extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    varieties: PropTypes.array,
    fruitClasses: PropTypes.array,
    cycleId: PropTypes.string.isRequired,
    organization: PropTypes.object,
    plantingCycle: PropTypes.object,
    allCompartments: PropTypes.array,
    createPlantingCycleForm: PropTypes.object,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    updatePlantingCycleStart: PropTypes.func.isRequired,
    getPlantingCycleStart: PropTypes.func.isRequired,
    isUpdatePlantingCycleFetching: PropTypes.bool.isRequired,
    fromPage: PropTypes.string,
    clearHarvestData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    varieties: [],
    fruitClasses: [],
    allCompartments: [],
    organization: null,
    plantingCycle: null,
    createPlantingCycleForm: {},
    fromPage: null
  };

  componentDidMount() {
    const { getPlantingCycleStart, cycleId, clearHarvestData } = this.props;

    clearHarvestData();

    getPlantingCycleStart({ id: cycleId });
  }

  handlerSubmit = (form) => {
    const {
      updatePlantingCycleStart,
      plantingCycle,
      isUpdatePlantingCycleFetching,
      fromPage,
      history,
      match,
    } = this.props;

    const { cycle: { id } } = plantingCycle;
    const btnSource = fromPage === 'crop' ? 'page' : 'list';

    if (!isUpdatePlantingCycleFetching) {
      updatePlantingCycleStart({
        id, data: preparePlantingCycleFormData(form), btnSource, history, match,
      });
    }
  };

  getFormInitialValuesFromProps = () => {
    const {
      plantingCycle,
      varieties,
    } = this.props;

    const selectedVariety = plantingCycle?.cycle?.varietyRef ?
      varieties.find(item => item.id === plantingCycle.cycle.varietyRef.id) : null;
    const selectedCrop = selectedVariety ? selectedVariety.attributes.species : null;
    const isLettuce = selectedCrop === 'lettuce';
    const variety = plantingCycle?.cycle?.varietyRef ? plantingCycle.cycle.varietyRef.id : null;
    const species = selectedVariety ? selectedVariety.attributes.species : null;
    const targetWeight = plantingCycle.cycle.targetWeight ? plantingCycle.cycle.targetWeight : null;
    const startDate = plantingCycle.cycle.startDate ? moment(plantingCycle.cycle.startDate, 'YYYY-MM-DD') : null;
    const endDate = plantingCycle.cycle.endDate ? moment(plantingCycle.cycle.endDate, 'YYYY-MM-DD') : null;
    const compartment = plantingCycle.cycle.compartmentRef ? plantingCycle.cycle.compartmentRef.id : null;
    const plantingDate = plantingCycle.cycle.plantingDate ?
      moment(plantingCycle.cycle.plantingDate, 'YYYY-MM-DD') : null;
    const plantDensity = plantingCycle.cycle.plantDensity ? plantingCycle.cycle.plantDensity : null;
    const plantDensityChanges = plantingCycle?.cycle?.plantDensityChanges?.length > 0 ?
      [{
        since: plantingDate,
        value: plantDensity
      }, ...plantingCycle.cycle.plantDensityChanges]
      : [];

    return isLettuce ? {
      variety,
      species,
      targetWeight,
      startDate,
      endDate,
      compartment
    } : {
      variety,
      species,
      startDate,
      targetWeight,
      plantingDate,
      endDate,
      compartment,
      plantingArea: plantingCycle.cycle.plantingArea,
      plantDensity,
      plantDensityChanges: plantDensityChanges.map(changes => ({
        id: uniqueId('plantDensityChanges-'),
        since: moment(changes.since, 'YYYY-MM-DD'),
        value: changes.value
      }))
    };
  }

  render() {
    const {
      plantingCycle,
      intl,
      varieties,
      fruitClasses,
      organization,
      allCompartments,
      createPlantingCycleForm,
      isUpdatePlantingCycleFetching,
      fromPage,
      history,
      match,
      cycleId,
    } = this.props;

    const { formatMessage } = intl;

    if (!plantingCycle) {
      return (
        <div className={styles.layout}>
          <CircleLoader
            className={loaderStyles.circleLoader}
            iconClassName={loaderStyles.circleLoaderIcon}
          />
        </div>
      );
    }

    const compartments = allCompartments.filter(item =>
      organization && item.relationships.location && item.relationships.location.data[0].id === organization.id);

    const initialValues = this.getFormInitialValuesFromProps();

    const pageToGoBack = fromPage === 'crop' ? `/${match.params.organizationSlug}/crops/${cycleId}` : `/${match.params.organizationSlug}/crops?fromPage=cropEdit`;

    return (
      <div className={styles.layout}>
        <div className={styles.body}>
          <div className={styles.controls}>
            <div
              className={classnames(styles.back)}
              tabIndex={0}
              role='button'
              onClick={() => {
                history.push(pageToGoBack);
              }}
            >
              <span className={styles.backIcon}>
                <ArrowBackIcon />
              </span>
              <span className={styles.backText}>
                {fromPage === 'crop' ?
                  formatMessage({ id: 'plantingCycles.plantingCycle' })
                  :
                  formatMessage({ id: 'plantingCycles.plantingCycles' })}
              </span>
            </div>
          </div>
          <div className={styles.content}>
            <PlantingCycleForm
              selectedCrop={initialValues.species}
              initialValues={initialValues}
              organization={organization}
              varieties={varieties}
              fruitClasses={fruitClasses}
              compartments={compartments}
              createPlantingCycleForm={createPlantingCycleForm}
              onSubmit={this.handlerSubmit}
              mode='edit'
              isLoading={isUpdatePlantingCycleFetching}
              intl={intl}
              pageToGoBack={pageToGoBack}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(UpdatePlantingCycle));
