import { useEffect } from 'react';

import { bisector, select } from 'd3';
import styles from 'components/LinesGraph/components/LineChart/LineChart.module.css';

function useHoverLineY(lineChartClassName, options) {
  const {
    chartParams: {
      x, y, colors
    },
    height,
    positionHoverLineY,
    closestPointCoordX,
    isHoverLineSizeNeedCalc,
    margin,
    withoutPoints,
    sortedData,
    allLines
  } = options;
  useEffect(() => {
    const group = select(`.${lineChartClassName}`);

    const xValue = x.invert(closestPointCoordX);
    const bisectDate = bisector(d => d.x).left;
    const dotsY = allLines.map((line) => {
      const {
        points,
        color,
        tooltipHidePointIndexes
      } = line;

      const iLeft = bisectDate(points, xValue);

      if (iLeft === -1) {
        return null;
      }

      const point = points[iLeft];

      if (!point || point?.y === null || point?.y === undefined) {
        return null;
      }

      if (Array.isArray(tooltipHidePointIndexes) && tooltipHidePointIndexes.includes(iLeft)) {
        return null;
      }

      return {
        y: point.y,
        color,
      };
    }).filter(line => line !== null);

    if (group) {
      group
        .selectAll(`.${styles.lineY}`)
        .remove();

      if (positionHoverLineY) {
        group
          .append('line')
          .attr('width', '1')
          .attr('height', height)
          .attr('class', styles.lineY)
          .attr('x1', closestPointCoordX)
          .attr('x2', closestPointCoordX)
          .attr('y1', '0')
          .attr('y2', height - (isHoverLineSizeNeedCalc ? margin.top + margin.bottom : 70));
      }
    }

    // Если график без точек, то отрисовываем точку при пересечении графика и hover line
    if (group && withoutPoints) {
      group
        .selectAll(`.${styles.customDataPoint}`)
        .remove();

      if (positionHoverLineY) {
        for (let i = 0; i < dotsY.length; i += 1) {
          const circle = dotsY[i];
          group
            .append('circle')
            .attr('class', styles.customDataPoint)
            .attr('r', '4')
            .style('fill', circle.color)
            .attr('cx', closestPointCoordX)
            .attr('cy', y(circle.y));
        }
      }
    }
  }, [
    lineChartClassName,
    x, y, colors,
    height,
    positionHoverLineY,
    closestPointCoordX,
    isHoverLineSizeNeedCalc,
    margin,
    withoutPoints,
    sortedData,
    allLines
  ]);
}

export default useHoverLineY;
