import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import Truncate from 'react-truncate';
import ReactTooltip from 'react-tooltip';

import { get, find } from 'lodash';
import classnames from 'classnames';

import { getProductTypeName } from 'helpers/getVarietyName';
import { getPlantingCycleDates } from 'helpers/getPlantingCycleLabel';

import BigButton from 'components/BigButton';
import DropdownMenu from 'components/DropdownMenu';

import ArrowDown from 'components/Icons/ArrowDown';
import DeleteIcon from 'components/Icons/Delete';
import ChangeIcon from 'components/Icons/Change';
import PlusIcon from 'components/Icons/Plus';

import ProductMultilevelMenu from '../ProductMultilevelMenu';

import styles from './VarietyDropdown.module.css';
import tooltipStyles from '../../../Tooltip/index.module.css';

class VarietyDropdown extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    plantingCycles: PropTypes.array.isRequired,
    species: PropTypes.string.isRequired,
    varietyId: PropTypes.number.isRequired,
    compartmentId: PropTypes.number.isRequired,
    idx: PropTypes.number.isRequired,
    handlerRemoveVariety: PropTypes.func.isRequired,
    handlerAddVariety: PropTypes.func.isRequired,
    handlerChangeVariety: PropTypes.func.isRequired,
    isVarietyRemoving: PropTypes.bool.isRequired,
    currentVariety: PropTypes.object.isRequired,
    organizationSlug: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isExistingCycle: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    isExistingCycle: false,
  };

  state = {
    dropdownStep: 'main',
    closeDropdown: false,
    truncated: false,
  };


  handlerTruncate = (truncated) => {
    if (this.state.truncated !== truncated) {
      this.setState({
        truncated
      });
    }
  };

  handlerDeleteButton = () => {
    const { idx, handlerRemoveVariety } = this.props;
    const afterRemove = () => this.setState({ closeDropdown: true });

    handlerRemoveVariety(idx, afterRemove);
  };

  handlerSelectVariety = (compartmentId, fruitClassCode, varietyId, targetWeight, stepAction, species) => {
    const {
      handlerAddVariety, idx, handlerChangeVariety
    } = this.props;


    if (stepAction === 'change') {
      handlerChangeVariety(idx, fruitClassCode, varietyId, targetWeight, species);
    } else {
      handlerAddVariety(compartmentId, fruitClassCode, varietyId, targetWeight, species);
    }

    this.setState({ closeDropdown: true });
  };

  renderButton = () => {
    const {
      intl,
      varieties,
      varietyId,
      currentVariety,
      fruitClasses,
      isExistingCycle,
    } = this.props;

    const {
      truncated
    } = this.state;

    const variety = find(varieties, { id: varietyId });
    const fruitClass = find(fruitClasses, { id: get(currentVariety, 'fruitClassCode') });

    const fullName = getProductTypeName({
      intl,
      variety,
      fruitClass,
      targetWeight: get(currentVariety, 'targetWeight'),
    });

    const tooltipId = `variery-select-tooltip-${varietyId}`;

    const tooltipData = truncated ? {
      'data-for': tooltipId,
      'data-tip': fullName
    } : {};

    return (
      <div
        className={styles.varietyDropdown}
        onClick={() => this.setState({ closeDropdown: false, dropdownStep: 'main' })}
        role='button'
        tabIndex={0}
      >
        <div className={styles.varietyText} {...tooltipData}>
          {isExistingCycle ?
            this.renderPlantingCycleName(fullName)
            :
            (
              <Truncate lines={2} onTruncate={this.handlerTruncate}>
                {fullName}
              </Truncate>
            )}
        </div>
        <ArrowDown className={styles.arrowDown} />
        {truncated && (
          <ReactTooltip
            className={classnames(tooltipStyles.smallTooltip, styles.headerTooltip)}
            id={tooltipId}
            effect='solid'
            place='top'
          >
            {fullName}
          </ReactTooltip>
        )}
      </div>
    );
  };

  renderPlantingCycleName = (fullName) => {
    const {
      intl: { formatMessage },
      currentVariety,
      plantingCycles,
    } = this.props;

    const plantingCycle = find(plantingCycles, { id: get(currentVariety, 'plantingCycleId') });
    const plantingCycleDates = getPlantingCycleDates(plantingCycle, formatMessage);

    return (
      <div>
        <div>
          <Truncate lines={1} onTruncate={this.handlerTruncate}>
            {fullName}
          </Truncate>
        </div>
        <div>
          {plantingCycleDates}
        </div>
      </div>
    );
  };

  renderDropdownContent = formatMessage => (
    <div className={styles.dropdownContent}>
      <BigButton
        className={styles.dropdownItem}
        onClick={() => this.setState({ dropdownStep: 'varietyChange' })}
        icon={<div className={styles.dropdownIcon}><ChangeIcon /></div>}
        category='icon'
        title={formatMessage({ id: 'plans.changeVariety' })}
      />
      <BigButton
        className={styles.dropdownItem}
        onClick={() => this.setState({ dropdownStep: 'delete' })}
        icon={<div className={styles.dropdownIcon}><DeleteIcon /></div>}
        category='icon'
        title={formatMessage({ id: 'dialog.delete' })}
      />
      <hr className={styles.line} />
      <BigButton
        className={styles.dropdownItem}
        onClick={() => this.setState({ dropdownStep: 'varietyAdd' })}
        icon={<div className={styles.dropdownIcon}><PlusIcon /></div>}
        category='icon'
        title={formatMessage({ id: 'plans.insertVariety' })}
      />
    </div>
  );

  renderDeletePopup = () => {
    const { intl: { formatMessage }, isVarietyRemoving } = this.props;

    return (
      <div className={styles.deletePopup}>
        <div>
          {formatMessage({ id: 'plans.deleteVarietyDialog' })}
        </div>
        <div className={styles.deleteButtons}>
          <BigButton
            className={styles.deleteCancel}
            onClick={() => this.setState({ closeDropdown: true })}
            title={formatMessage({ id: 'harvest.cancelButton' })}
            theme='transparent'
          />
          <BigButton
            className={styles.deleteConfitm}
            onClick={this.handlerDeleteButton}
            title={formatMessage({ id: 'harvest.confirmDelete' })}
            theme='transparentRed'
            isLoading={isVarietyRemoving}
          />
        </div>
      </div>
    );
  }

  renderAddCropCycleButton = () => {
    const { intl: { formatMessage }, organizationSlug } = this.props;

    return (
      <div className={styles.dropdownContent}>
        <BigButton
          className={styles.dropdownItem}
          // onClick={this.redirectToAddCropCycle}
          href={`/${organizationSlug}/crops/create`}
          icon={<div className={styles.dropdownIcon}><PlusIcon /></div>}
          category='icon'
          title={formatMessage({ id: 'plans.addCropCycle' })}
        />
      </div>
    );
  }

  redirectToAddCropCycle = () => {};

  renderVarietySelect = (stepAction) => {
    const { disabled, compartmentId } = this.props;

    return (
      <ProductMultilevelMenu
        onSelectOption={(fruitClassCode, varietyId, targetWeight, species) => this.handlerSelectVariety(compartmentId, fruitClassCode, varietyId, targetWeight, stepAction, species)}
        onlyContent
        disabled={disabled}
        onCancel={() => this.setState({ closeDropdown: true })}
      />
    );
  };

  renderContent = () => {
    const { intl: { formatMessage }, isExistingCycle } = this.props;
    const { dropdownStep } = this.state;

    if (isExistingCycle) {
      return this.renderAddCropCycleButton();
    }

    switch (dropdownStep) {
      case 'main':
        return this.renderDropdownContent(formatMessage);
      case 'delete':
        return this.renderDeletePopup();
      case 'varietyAdd':
        return this.renderVarietySelect('add');
      case 'varietyChange':
        return this.renderVarietySelect('change');
      default:
        return this.renderDropdownContent(formatMessage);
    }
  };

  render() {
    const { disabled } = this.props;
    const { closeDropdown, dropdownStep } = this.state;
    const isSmallPaddings = dropdownStep === 'varietyAdd' || dropdownStep === 'varietyChange';

    return (
      <DropdownMenu
        closeDropdown={closeDropdown}
        className={styles.dropdownMenu}
        buttonClassName={styles.dropdownMenuButton}
        wrapperClassName={styles.dropdownMenuWrapper}
        contentClassName={classnames(
          styles.dropdownWrapper,
          {
            [styles.dropdownWrapperSmallPaggings]: isSmallPaddings,
          }
        )}
        buttonElement={this.renderButton()}
        disabled={disabled}
      >
        {this.renderContent()}
      </DropdownMenu>
    );
  }
}
export default injectIntl(VarietyDropdown);
