import React, { memo, } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import { uniqueId } from 'lodash';

import ClickOutside from 'components/ClickOutside';

import styles from './ContextMenu.module.css';

const renderMenuItem = ({
  message, onClick, rowIndex, columnIndex
}) => (
  <div
    key={uniqueId('menuItem-')}
    tabIndex={0}
    role='button'
    onClick={() => onClick(rowIndex, columnIndex)}
    className={styles.menuItem}
  >
    {message}
  </div>
);

const ContextMenu = ({
  intl: { formatMessage }, left, top, copy, paste, handleClickOutside, additionalItems, rowIndex, columnIndex,
}) => (
  <ClickOutside
    handleClickOutside={handleClickOutside}
    // className={wrapperClassName}
  >
    <div
      className={styles.contextMenu}
      style={{
        left,
        top,
      }}
    >
      <div
        tabIndex={0}
        role='button'
        onClick={() => {
          copy();

          handleClickOutside();
        }}
        className={styles.menuItem}
      >
        {formatMessage({ id: 'button.copy' })}
      </div>
      <div
        tabIndex={0}
        role='button'
        onClick={() => {
          paste();

          handleClickOutside();
        }}
        className={styles.menuItem}
      >
        {formatMessage({ id: 'button.paste' })}
      </div>
      {additionalItems.map(item => renderMenuItem({ ...item, rowIndex, columnIndex }))}
    </div>
  </ClickOutside>
);

ContextMenu.propTypes = {
  intl: intlShape.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  copy: PropTypes.func.isRequired,
  paste: PropTypes.func.isRequired,
  handleClickOutside: PropTypes.func.isRequired,
  rowIndex: PropTypes.number,
  columnIndex: PropTypes.number,

  additionalItems: PropTypes.array,
};

ContextMenu.defaultProps = {
  additionalItems: [],
  rowIndex: null,
  columnIndex: null,
};
export default injectIntl(memo(ContextMenu));
