import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getCurrentTimezone } from 'store/company/selectors';

import { requestCropReport } from 'store/cropReports/actions';
import {
  getCropReport,
  getIsCropReportFetching,
 } from 'store/cropReports/selectors';

import CropReport from '../components/CropReport';

const mapDispatchToProps = {
  requestCropReport,
};

const mapStateToProps = (state, props) => ({
  ...props,

  currentReport: getCropReport(state, props),
  isFetching: getIsCropReportFetching(state, props),
  timezone: getCurrentTimezone(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CropReport));
