import { createSelector } from 'reselect';
import { get, find } from 'lodash';
import update from 'immutability-helper';

import {
  getOrganizationId,
  getBenchmarkAllProducts,
  getBenchmarkAllLocations,
} from 'store/company/selectors';
import { getRouteQueryParams } from '../router/selectors';

export const getBenchmarkGroups = state => get(state, 'benchmark.groups');
export const getAvailableAdditionalMetrics = state => get(state, 'benchmark.availableAdditionalMetrics');
export const getIsBenchmarkGroupsFetching = state => get(state, 'benchmark.isBenchmarkGroupsFetching');
export const getBenchmarkOverview = state => get(state, 'benchmark.overview');
export const getIsBenchmarkOverviewFetching = state => get(state, 'benchmark.isBenchmarkOverviewFetching');

export const getBenchmarkComparison = state => get(state, 'benchmark.comparison');
export const getIsBenchmarkComparisonFetching = state => get(state, 'benchmark.isBenchmarkComparisonFetching');

export const getBenchmarkComparisonMetrics = state => get(state, 'benchmark.metrics');
export const getIsBenchmarkComparisonMetricsFetching = state => get(state, 'benchmark.isBenchmarkComparisonMetricsFetching');
export const getIsUpdateBenchmarkComparisonMetricsFetching = state => get(state, 'benchmark.isUpdateBenchmarkComparisonMetricsFetching');
export const getIsDeleteBenchmarkComparisonMetricsFetching = state => get(state, 'benchmark.isDeleteBenchmarkComparisonMetricsFetching');

export const getAllCyclesToCompare = state => state?.benchmark?.cyclesToCompare;

export const getCyclesToCompare = createSelector(
  [getOrganizationId, getAllCyclesToCompare],
  (organizationId, cyclesToCompare) => {
    if (!cyclesToCompare || cyclesToCompare === '') {
      return [];
    }

    const currentCycles = JSON.parse(cyclesToCompare) || {};
    const cycleIdsArray = currentCycles[organizationId] || [];

    return cycleIdsArray.map(cycleItem => Number(cycleItem));
  },
);

export const getCyclesToCompareFromRoute = createSelector(
  [getRouteQueryParams],
  (query) => {
    if (query?.productId && query?.productId !== '') {
      const cycleIdsArray = query?.productId?.split('_');

      return cycleIdsArray.map(cycleItem => Number(cycleItem));
    }

    return [];
  },
);


export const getFilteredBenchmarkComparison = createSelector(
  [getBenchmarkComparison, getCyclesToCompareFromRoute],
  (comparison, cyclesToCompare) => {
    if (!comparison) {
      return null;
    }

    // фильтруем по циклам в сравнении, чтобы удаление циклов срабатывало и для comparison
    const filteredComparison = update(comparison, {
      products: {
        $set: comparison.products.filter(item => cyclesToCompare.indexOf(item.id) !== -1),
      }
    });

    return filteredComparison;
  },
);

export const getAllowedPlantingCycles = createSelector(
  [getBenchmarkAllProducts, getBenchmarkAllLocations],
  (benchmarkAllProducts, benchmarkAllLocations) => {
    const productsWithAdditionalInfo = benchmarkAllProducts.map((product) => {
      const currentLocation = find(benchmarkAllLocations, { id: product?.relationships?.location?.data[0]?.id });
      const { id, attributes: { name } } = currentLocation;

      return {
        ...product,

        location: {
          id,
          name,
        }
      };
    });

    return productsWithAdditionalInfo;
  },
);
