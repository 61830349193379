import { get } from 'lodash';
import queryString from 'query-string';
import { createSelector } from 'reselect';

import {
  DEFAULT_PLAN_RELATIVE,
  DEFAULT_PLAN_GROUPED_BY,
  DEFAULT_PLAN_PERIOD_TYPE,
} from './constants';

export const getHarvestPlansList = state => state?.plans?.harvestPlansList;
export const getIsHarvestPlansListFetching = state => state?.plans?.isHarvestPlansListFetching;

export const getHarvestPlansThickLines = state => state.plans.thickLines;
export const getHarvestPlansDescriptor = state => state.plans.descriptor;
export const getHarvestPlansSucceeded = state => state.plans.harvestPlansSucceeded;
export const getHarvestPlansFailed = state => state.plans.harvestPlansFailed;
export const getHarvestPlansFetching = state => state.plans.harvestPlansFetching;

export const getHarvestPlansPeriodEnd = state => state.plans.periodEnd;
export const getHarvestPlansPeriodStart = state => state.plans.periodStart;

export const getRouteLocation = (state, props) => props.location;

export const getRouteQueryParams =
  createSelector([getRouteLocation],
    location => queryString.parse(get(location, 'search')));

export const getRelative = createSelector(
  [getRouteQueryParams],
  query => get(query, 'relative', DEFAULT_PLAN_RELATIVE),
);

export const getGroupedBy = createSelector(
  [getRouteQueryParams],
  query => get(query, 'groupedBy', DEFAULT_PLAN_GROUPED_BY),
);

export const getPeriodType = createSelector(
  [getRouteQueryParams],
  query => get(query, 'periodType', DEFAULT_PLAN_PERIOD_TYPE),
);

export const getYear = createSelector(
  [getRouteQueryParams],
  query => get(query, 'year'),
);

export const getTimelineDate = createSelector(
  [getRouteQueryParams],
  query => get(query, 'timelineDate'),
);

export const getLocationPlanId = createSelector(
  [getRouteQueryParams],
  query => get(query, 'locationPlan'),
);
