import { get } from 'lodash';
import { createSelector } from 'reselect';
import addSeconds from 'date-fns/addSeconds';

import { getRouteMatchParams } from '../router/selectors';

export const getEmailToReset = state => get(state, 'login.emailToReset');
export const getIsResetEmailFetching = state => get(state, 'login.isResetEmailFetching');
export const getIsResetPasswordFetching = state => get(state, 'login.isResetPasswordFetching');
export const getTokenValidation = state => get(state, 'login.tokenValidation');
export const getIsTokenValidityFetching = state => get(state, 'login.isTokenValidityFetching');
export const getIsPasswordUpdateFetching = state => get(state, 'login.isPasswordUpdateFetching');

export const getLoginError = state => state?.login?.loginError;

export const getSendingCodeInfo = state => state?.login?.sendingCodeInfo;
export const getIsSendingCodeInfoFetching = state => state?.login?.isSendingCodeInfoFetching;
export const getIsVerifyCodeFetching = state => state?.login?.isVerifyCodeFetching;
export const getSendingCodeExpireInSecs = state => state?.login?.sendingCodeInfo?.expireInSecs;
export const getSendingCodeReceivedAt = state => state?.login?.sendingCodeInfo?.receivedAt;
export const getRetryInSecs = state => state?.login?.sendingCodeInfo?.retryInSecs;
export const getNumberOfDigits = state => state?.login?.sendingCodeInfo?.numberOfDigits;
export const getCodeTicket = state => state?.login?.sendingCodeInfo?.ticket;
export const getVerifyCodeError = state => state?.login?.verifyCodeError;

export const getAuthTicket = state => state?.login?.authTicketInfo?.ticket;
export const getAuthTicketInfoReceivedAt = state => state?.login?.authTicketInfo?.receivedAt;
export const getAuthTicketExpireInSecs = state => state?.login?.authTicketInfo?.expireInSecs;

export const getWhenTicketIsExpired = createSelector(
  [getAuthTicketExpireInSecs, getAuthTicketInfoReceivedAt],
  (expireInSecs, receivedAt) => {
    if (!expireInSecs || !receivedAt) {
      return undefined;
    }

    return addSeconds(receivedAt, expireInSecs);
  }
);

export const getWhenRetryIsAvailable = createSelector(
  [getRetryInSecs, getSendingCodeReceivedAt],
  (retryInSecs, receivedAt) => {
    if (!retryInSecs || !receivedAt) {
      return undefined;
    }

    return addSeconds(receivedAt, retryInSecs);
  }
);

export const getWhenCodeIsExpired = createSelector(
  [getSendingCodeExpireInSecs, getSendingCodeReceivedAt],
  (expireInSecs, receivedAt) => {
    if (!expireInSecs || !receivedAt) {
      return undefined;
    }

    return addSeconds(receivedAt, expireInSecs);
  }
);

export const getJwtFromRoute = createSelector(
  [getRouteMatchParams],
  params => get(params, 'jwt'),
);
