import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import QueryString from 'query-string';
import { filter } from 'lodash';

import toStringNormalize from 'helpers/toStringNormalize';
import initAxiosInstanse from 'api/axios';
import { showNotificationWithTimeout } from 'store/notificationCenter/actions';

import { trackUserProperties } from '../analytics/actions';


import {
  requestLocations,
  receiveLocations,
  requestAllCompartments,
  receiveAllCompartments,
  requestAllSubNodes,
  receiveAllSubNodes,
  requestAllPlantingCycles,
  receiveAllPlantingCycles,
  requestAllGreenhouseTypes,
  receiveAllGreenhouseTypes,
  requestAllLocationPlans,
  receiveAllLocationPlans,
  changeLocationPlan,
  changedLocationPlan,
  requestLocationPlans,
  receiveLocationPlans,
  receiveUserPermissions,
  requestUserPermissions,
  receiveProductGroups,
  requestProductGroups,
  requestVarietiesAndFruitClasses,
  receiveVarietiesAndFruitClasses,
  requestLocation,
  receiveLocation,
  checkCookiePolicy,
  requestLocationMetrics,
  receiveLocationMetrics,

  requestDictionaries, receiveDictionaries,

  requestAllAvailableToBenchmarkProducts,
  receiveAllAvailableToBenchmarkProducts,
} from './actions';

export const locationsEpic = (action$, store) =>
  action$.ofType(requestLocations)
    .switchMap(() => {
      const {
        router,
      } = store.getState();
      const searchParams = QueryString.parse(router?.location?.search || '');

      return Observable.from(initAxiosInstanse(
        searchParams
      ).get('/locations'))
        .mergeMap(({ data }) => {
          const receivedAt = Date.now();

          return Observable.of(
            receiveLocations({
              locations: data?.data || [],
              receivedAt
            }),
            requestDictionaries({
              include: ['species']
            }),
          );
        })
        .catch(() => receiveLocations());
    });

export const changeLocationPlanEpic = (action$, store) =>
  action$.ofType(changeLocationPlan)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();

      const searchParams = router?.location?.search;

      const {
        payload: {
          locationPlanId,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).post(`/location-plan/default/${locationPlanId}`))
        .mergeMap(() => Observable.of(
          changedLocationPlan({
            locationPlanId,
          }),
        ))
        .catch(() => Observable.of(
          changedLocationPlan(),
          showNotificationWithTimeout({
            id: `notifications.changeLocationPlanError.${Date.now()}`,
            messageId: 'plans.errors.changeLocationPlan',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const locationPlansEpic = (action$, store) =>
  action$.ofType(requestLocationPlans)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = QueryString.parse(router?.location?.search || '');
      return Observable.from(initAxiosInstanse(
        searchParams
      ).get(`/location-plan/${organizationId}`))
        .mergeMap(({ data }) => {
          const receivedAt = Date.now();
          return Observable.of(
            receiveLocationPlans({
              plans: data || [],
              receivedAt
            }),
          );
        })
        .catch(() => receiveLocationPlans());
    });

export const getUserPermissionsEpic = (action$, store) =>
  action$.ofType(requestUserPermissions)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();

      const {
        payload: {
          location: {
            id: locationId,
          },
        }
      } = actionData;

      const searchParams = QueryString.parse(router?.location?.search || '');
      return Observable.from(initAxiosInstanse(
        searchParams
      ).get(`/user-profile/acl?locationId=${locationId}`))
        .mergeMap(({ data }) => {
          const receivedAt = Date.now();
          return Observable.of(
            receiveUserPermissions({
              permissions: data?.permissions || [],
              receivedAt
            }),
          );
        })
        .catch(() => receiveUserPermissions());
    });

export const getProductGroupsEpic = (action$, store) =>
  action$.ofType(requestProductGroups)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = QueryString.parse(router?.location?.search || '');

      return Observable.from(initAxiosInstanse(
        searchParams
      ).get(`/locations/${organizationId}/product-groups/used?include=fruitClasses,varieties`))
        .mergeMap(({ data }) => {
          const receivedAt = Date.now();
          return Observable.of(
            receiveProductGroups({
              productGroups: data?.data || [],
              receivedAt
            }),
          );
        })
        .catch(() => receiveProductGroups());
    });

export const getVarietiesAndFruitClasses = (action$, store) =>
  action$.ofType(requestVarietiesAndFruitClasses)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      // compartments,compartments.subNodes,compartments.plantingCycles,locationPlans
      const searchParams = QueryString.parse(router?.location?.search || '');

      const includeList = [
        'fruitClasses',
        'varieties',
        'compartments',
        'compartments.subNodes',
        'compartments.plantingCycles',
        'locationPlans',
        'plantingCycles',
      ];

      return Observable.concat(
        Observable.of(
          requestAllCompartments(),
          requestAllSubNodes(),
          requestAllPlantingCycles(),
          requestAllGreenhouseTypes(),
          requestAllLocationPlans(),
        ),
        Observable.from(initAxiosInstanse(
          searchParams
        ).get(`/locations?locationId=${organizationId}&include=${includeList.join(',')}`))
          .mergeMap(({ data }) => {
            const receivedAt = Date.now();
            const { included } = data;
            const fruitClasses = filter(included, { type: 'FruitClass' });
            const varieties = filter(included, { type: 'Variety' });
            const compartments = filter(included, { type: 'Compartment' });
            const plantingCycles = filter(included, { type: 'PlantingCycle' });
            const greenhouseTypes = filter(included, { type: 'GreenhouseType' });
            const subNodes = filter(included, { type: 'SubNode' });
            const locationPlans = filter(included, { type: 'LocationPlan' });

            return Observable.of(
              receiveAllPlantingCycles({
                plantingCycles,
                receivedAt
              }),
              receiveAllGreenhouseTypes({
                greenhouseTypes,
                receivedAt
              }),
              receiveAllCompartments({
                compartments: compartments.map(c => ({ ...c, showAll: true })),
                receivedAt
              }),
              receiveAllSubNodes({
                subNodes,
                receivedAt
              }),
              receiveAllLocationPlans({
                locationPlans,
                receivedAt
              }),
              receiveVarietiesAndFruitClasses({
                fruitClasses,
                varieties,
                receivedAt
              }),
            );
          })
          .catch(() => receiveVarietiesAndFruitClasses())
      );
    });


// eslint-disable-next-line no-unused-vars
export const setLocationEpic = (action$, store) =>
  action$.ofType(requestLocation)
    .switchMap((actionData) => {
      const {
        payload: {
          editedLocation,
        }
      } = actionData;

      return Observable.of(
        receiveLocation(editedLocation)
      );
    });

export const getLocationEpic = (action$, store) =>
  action$.ofType(receiveLocation)
    .switchMap((actionData) => {
      const {
        payload: editedLocation
      } = actionData;

      const state = store.getState();

      const {
        company: {
          location: {
            id: locationId,
            attributes: {
              name: locationName
            }
          }
        }
      } = state;

      return Observable.of(
        requestUserPermissions({ location: editedLocation }),
        trackUserProperties({
          data: {
            'Company ID': locationId,
            Company: toStringNormalize(locationName),
          },
        }),
        requestLocationPlans(),
        checkCookiePolicy(),
        requestVarietiesAndFruitClasses(),
        requestProductGroups(),
        requestAllAvailableToBenchmarkProducts(),
      );
    });

export const getLocationMetricsEpic = (action$, store) =>
  action$.ofType(requestLocationMetrics)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = QueryString.parse(router?.location?.search || '');

      return Observable.from(initAxiosInstanse(
        searchParams
      ).get(`/locations/${organizationId}/metrics`))
        .mergeMap(({ data }) => Observable.of(
          receiveLocationMetrics({
            ...data
          }),
        ))
        .catch(() => receiveLocationMetrics());
    });

export const requestDictionariesEpic = (action$, store) =>
  action$.ofType(requestDictionaries)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();
      const {
        payload
      } = actionData;
      const searchParams = QueryString.parse(router?.location?.search || '');
      const path = payload?.include?.length > 0 ? `/dictionaries?include=${payload?.include?.join(',')}`
        : '/dictionaries';
      return Observable.from(initAxiosInstanse(
        searchParams
      ).get(path))
        .mergeMap(({ data }) => Observable.of(
          receiveDictionaries({
            ...data
          }),
        ))
        .catch(() => receiveDictionaries());
    });

export const getAllAvailableToBenchmarkProductsEpic = (action$, store) =>
  action$.ofType(requestAllAvailableToBenchmarkProducts)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = QueryString.parse(router?.location?.search || '');

      const includeList = [
        'fruitClasses',
        'varieties',
        'compartments',
        'locations',
      ];

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/benchmark/products/${organizationId}?include=${includeList.join(',')}`))
        .mergeMap(({ data }) => {
          const { included } = data;
          const locations = filter(included, { type: 'Location' });
          const compartments = filter(included, { type: 'Compartment' });
          const fruitClasses = filter(included, { type: 'FruitClass' });
          const varieties = filter(included, { type: 'Variety' });

          return Observable.of(
            receiveAllAvailableToBenchmarkProducts({
              locations,
              compartments,
              fruitClasses,
              varieties,
              plantingCycles: data?.data,
            }),
          );
        })
        .catch(() => Observable.of(
          receiveAllAvailableToBenchmarkProducts(),
          showNotificationWithTimeout({
            id: `notifications.deleteForecastVersionInfoError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export default combineEpics(
  locationsEpic,
  changeLocationPlanEpic,
  locationPlansEpic,
  getUserPermissionsEpic,
  getProductGroupsEpic,
  getVarietiesAndFruitClasses,
  setLocationEpic,
  getLocationEpic,
  getLocationMetricsEpic,
  getAllAvailableToBenchmarkProductsEpic,
  requestDictionariesEpic,
);
