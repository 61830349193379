import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { replace } from 'connected-react-router';
import {
  requestGetHarvestPlans,
  clearHarvestPlans,
} from 'store/plans/actions';

import {
  getHarvestPlansPeriodStart,
  getHarvestPlansPeriodEnd,
  getHarvestPlansSucceeded,
  getHarvestPlansFetching,
  // getIsHarvestPlansEmpty,
  getTimelineDate,
  getRelative,
  getHarvestPlansThickLines,
  getHarvestPlansDescriptor,
  getLocationPlanId
} from 'store/plans/selectors';

import {
  getOrganization,
  getAllVarieties,
  getIsAddOperationalPlansAvailable,
  getIsAddBudgetPlansAvailable,
  getIsOnlyVegetables,
  getIsOnlyLettuce,
  getCurrentLocationPlan,
  getIsLocationPlansFetching,
  getBreakdownFromRoute,
  getCompartmentIdsFromRoute,
  getVarietyIdFromRoute,
  getSpeciesFromRoute,
  getFruitClassFromRoute,
  getPeriodTypeFromRoute,
  getCurrentCompartments
} from 'store/company/selectors';

import { trackPageViewPlans } from 'store/company/actions';

import Plans from '../../components/Plans';

const mapDispatchToProps = {
  requestGetHarvestPlans,
  clearHarvestPlans,
  trackPageViewPlans,
  replace
};

const mapStateToProps = (state, props) => ({
  varieties: getAllVarieties(state, props),
  organization: getOrganization(state, props),
  compartments: getCurrentCompartments(state, props),
  harvestPlansSucceeded: getHarvestPlansSucceeded(state, props),
  harvestPlansFetching: getHarvestPlansFetching(state, props),
  thickLines: getHarvestPlansThickLines(state, props),
  descriptor: getHarvestPlansDescriptor(state, props),
  isAddPlansAvailable: getIsAddOperationalPlansAvailable(state, props) || getIsAddBudgetPlansAvailable(state, props),
  isOnlyLettuce: getIsOnlyLettuce(state, props),
  isOnlyVegetables: getIsOnlyVegetables(state, props),
  timelineDate: getTimelineDate(state, props),
  relative: getRelative(state, props),
  breakdown: getBreakdownFromRoute(state, props) || 'varietyWeight',
  compartmentId: getCompartmentIdsFromRoute(state, props),
  varietyId: getVarietyIdFromRoute(state, props),
  locationPlan: getCurrentLocationPlan(state, props),
  locationPlanIdFromUrl: getLocationPlanId(state, props),
  isLocationPlansFetching: getIsLocationPlansFetching(state, props),
  species: getSpeciesFromRoute(state, props),
  fruitClassCode: getFruitClassFromRoute(state, props),
  periodStart: getHarvestPlansPeriodStart(state, props),
  periodEnd: getHarvestPlansPeriodEnd(state, props),
  periodType: getPeriodTypeFromRoute(state, props),
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Plans)));
