import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';

import queryString from 'query-string';

import initAxiosInstanse from '../../api/axios';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

import { updateAbnormalityIncidentFeedback } from '../graphs/actions';

import {
  requestFeedbackSending,
  receiveFeedbackSending,
  receiveIncidentById,
  requestIncidentById,
  requestIncidentTypes,
  receiveIncidentTypes,
  requestIncidentTypeCategories,
  receiveIncidentTypeCategories,
  requestUpdateIncidentState,
  receiveUpdateIncidentState,
  requestIncidentPlot,
  receiveIncidentPlot,
  requestAlertRules,
  receiveAlertRules,
  receiveCreateAlertRule,
  requestCreateAlertRule,
  requestUpdateAlertRule,
  receiveUpdateAlertRule,
  requestDeleteAlertRule,
  receiveDeleteAlertRule,
  requestUpdateAlertState,
  receiveUpdateAlertState,
} from './actions';

export const feedbackSendingEpic = (action$, store) =>
  action$.ofType(requestFeedbackSending)
    .switchMap((actionData) => {
      const {
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          incidentId,
          score,
          comment,
          actionAfterSuccess,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse().post(`/locations/${organizationId}/incident/${incidentId}/feedback`, {
          score,
          comment,
        }))
        .mergeMap(({ status, data }) => {
          if (status !== 200 && status !== 204) {
            return Observable.of(
              receiveFeedbackSending({ sendingError: { status, data } }),
              showNotificationWithTimeout({
                id: `feedback.errorHeader.${Date.now()}`,
                messageId: 'feedback.errorHeader',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withActionWide',
              }),
            );
          }

          return Observable.of(
            actionAfterSuccess,
            receiveFeedbackSending({ successfulSending: true }),
            updateAbnormalityIncidentFeedback({
              incidentId,
              feedback: data
            }),
          );
        })
        .catch(error => Observable.of(
          receiveFeedbackSending({ sendingError: error }),
          showNotificationWithTimeout({
            id: `feedback.errorHeader.${Date.now()}`,
            messageId: 'feedback.errorHeader',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getIncidentByIdEpic = (action$, store) =>
  action$.ofType(requestIncidentById)
    .switchMap((actionData) => {
      const {
        company,
        router,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          incidentId,
        }
      } = actionData;
      const searchParams = router?.location?.search;

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/locations/${organizationId}/incident/${incidentId}`))
        .map(({ data }) => receiveIncidentById(data))
        .catch(() => Observable.of(
          receiveIncidentById(),
          showNotificationWithTimeout({
            id: `notifications.getIncidentByIdEpicError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getIncidentTypesEpic = (action$, store) =>
  action$.ofType(requestIncidentTypes)
    .switchMap(() => {
      const {
        company,
        router,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = router?.location?.search;

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/locations/${organizationId}/incident-types?include=alertRules`))
        .map(({ data }) => receiveIncidentTypes(data))
        .catch(() => Observable.of(
          receiveIncidentTypes(),
          showNotificationWithTimeout({
            id: `notifications.getIncidentTypesEpicError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getIncidentTypeCategoriesEpic = (action$, store) =>
  action$.ofType(requestIncidentTypeCategories)
    .switchMap(() => {
      const {
        company,
        router,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = router?.location?.search;

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/locations/${organizationId}/incident-type-categories`))
        .map(({ data }) => receiveIncidentTypeCategories(data))
        .catch(() => Observable.of(
          receiveIncidentTypeCategories(),
          showNotificationWithTimeout({
            id: `notifications.getIncidentTypeCategoriesEpicError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const updateIncidentStateEpic = (action$, store) =>
  action$.ofType(requestUpdateIncidentState)
    .switchMap((actionData) => {
      const {
        company,
        router,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = router?.location?.search;

      const {
        payload: {
          incidentId,
          enabled,
          actionAfterFail = () => {},
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).patch(`/locations/${organizationId}/incident-types/${incidentId}`, {
          enabled,
        }))
        .mergeMap(({ status, data }) => {
          if (status !== 200) {
            return Observable.of(
              actionAfterFail,
              receiveUpdateIncidentState(),
              showNotificationWithTimeout({
                id: `settings.notificationUpdateIncidentStateError.${Date.now()}`,
                messageId: enabled ? 'incidentsSettings.incidentNotEnabled' : 'incidentsSettings.incidentNotDisabled',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withActionWide',
              }),
            );
          }

          return Observable.of(
            receiveUpdateIncidentState(data),
            showNotificationWithTimeout({
              id: `notifications.notificationUpdateIncidentStateSuccess.${Date.now()}`,
              messageId: enabled ? 'incidentsSettings.incidentEnabled' : 'incidentsSettings.incidentDisabled',
              position: 'leftDown',
              iconType: 'success',
              notificationType: 'withAction',
            }),
          );
        })
        .catch(() => Observable.of(
          actionAfterFail,
          receiveUpdateIncidentState(),
          showNotificationWithTimeout({
            id: `settings.notificationUpdateIncidentStateError.${Date.now()}`,
            messageId: enabled ? 'incidentsSettings.incidentNotEnabled' : 'incidentsSettings.incidentNotDisabled',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getIncidentPlotEpic = (action$, store) =>
  action$.ofType(requestIncidentPlot)
    .switchMap((actionData) => {
      const {
        company,
        router,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          incidentId,
        }
      } = actionData;
      const searchParams = router?.location?.search;

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/locations/${organizationId}/incident/${incidentId}/plot`))
        .map(({ data }) => receiveIncidentPlot(data))
        .catch(() => Observable.of(
          receiveIncidentPlot(),
          showNotificationWithTimeout({
            id: `notifications.getIncidentPlotEpicError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getAlertRulesEpic = (action$, store) =>
  action$.ofType(requestAlertRules)
    .switchMap(() => {
      const {
        company,
        router,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = router?.location?.search;

      const queryParams = queryString.stringify({
        locationId: organizationId,
      });

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/alert-rules?${queryParams}`))
        .map(({ data }) => receiveAlertRules(data))
        .catch(() => Observable.of(
          receiveAlertRules(),
          showNotificationWithTimeout({
            id: `notifications.getAlertRulesEpicError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const createAlertRuleEpic = (action$, store) =>
  action$.ofType(requestCreateAlertRule)
    .switchMap((actionData) => {
      const {
        company,
        router,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = router?.location?.search;

      const queryParams = queryString.stringify({
        locationId: organizationId,
      });

      const {
        payload: {
          alertData,
          actionAfterSuccess = () => {},
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).post(`/alert-rules?${queryParams}`, alertData))
        .mergeMap(({ data }) => Observable.of(
          actionAfterSuccess,
          receiveCreateAlertRule(data),
          requestAlertRules(),
          showNotificationWithTimeout({
            id: 'alerting.alertAdded',
            messageId: 'alerting.alertAdded',
            iconType: 'success',
            notificationType: 'withAction',
          })
        ))
        .catch(() => Observable.of(
          receiveCreateAlertRule(),
          showNotificationWithTimeout({
            id: `alerting.cannotAddError.${Date.now()}`,
            messageId: 'alerting.cannotAdd',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const updateAlertRuleEpic = (action$, store) =>
  action$.ofType(requestUpdateAlertRule)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();
      const searchParams = router?.location?.search;

      const {
        payload: {
          alertId,
          alertData,
          actionAfterSuccess = () => {},
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).put(`/alert-rules/${alertId}`, alertData))
        .mergeMap(({ data }) => Observable.of(
          actionAfterSuccess,
          receiveUpdateAlertRule(data),
          requestAlertRules(),
          showNotificationWithTimeout({
            id: 'alerting.changesSaved',
            messageId: 'alerting.changesSaved',
            iconType: 'success',
            notificationType: 'withAction',
          })
        ))
        .catch(() => Observable.of(
          receiveUpdateAlertRule(),
          showNotificationWithTimeout({
            id: `alerting.cannotSaveError.${Date.now()}`,
            messageId: 'alerting.cannotSave',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const deleteAlertRuleEpic = (action$, store) =>
  action$.ofType(requestDeleteAlertRule)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();
      const searchParams = router?.location?.search;

      const {
        payload: {
          alertId,
          actionAfterSuccess = () => {},
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).delete(`/alert-rules/${alertId}`))
        .mergeMap(({ data }) => Observable.of(
          actionAfterSuccess,
          receiveDeleteAlertRule(data),
          requestAlertRules(),
          showNotificationWithTimeout({
            id: 'alerting.alertDeleted',
            messageId: 'alerting.alertDeleted',
            iconType: 'success',
            notificationType: 'withAction',
          })
        ))
        .catch(() => Observable.of(
          receiveDeleteAlertRule(),
          showNotificationWithTimeout({
            id: `alerting.cannotDeleteError.${Date.now()}`,
            messageId: 'alerting.cannotDelete',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const updateAlertStateEpic = (action$, store) =>
  action$.ofType(requestUpdateAlertState)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();

      const searchParams = router?.location?.search;

      const {
        payload: {
          alertId,
          enabled,
          actionAfterFail = () => {},
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).patch(`alert-rules/${alertId}`, {
          disabled: !enabled,
        }))
        .mergeMap(({ status, data }) => {
          if (status !== 200) {
            return Observable.of(
              actionAfterFail,
              receiveUpdateAlertState(),
              showNotificationWithTimeout({
                id: `settings.notificationUpdateAlertError.${Date.now()}`,
                messageId: enabled ? 'alerting.alertEnabledError' : 'alerting.alertDisabledError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withActionWide',
              }),
            );
          }

          return Observable.of(
            receiveUpdateAlertState(data),
            showNotificationWithTimeout({
              id: `notifications.notificationUpdateAlertSucces.${Date.now()}`,
              messageId: enabled ? 'alerting.alertEnabled' : 'alerting.alertDisabled',
              position: 'leftDown',
              iconType: 'success',
              notificationType: 'withAction',
            }),
          );
        })
        .catch(() => Observable.of(
          actionAfterFail,
          receiveUpdateAlertState(),
          showNotificationWithTimeout({
            id: `settings.notificationUpdateAlertError.${Date.now()}`,
            messageId: enabled ? 'alerting.alertEnabledError' : 'alerting.alertDisabledError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export default combineEpics(
  feedbackSendingEpic,
  getIncidentByIdEpic,
  getIncidentTypesEpic,
  getIncidentTypeCategoriesEpic,
  updateIncidentStateEpic,
  getIncidentPlotEpic,
  getAlertRulesEpic,
  createAlertRuleEpic,
  updateAlertRuleEpic,
  deleteAlertRuleEpic,
  updateAlertStateEpic,
);
