import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './PhotosTypes.module.css';
import storageWrapper from '../../helpers/storageWrapper';

const safeLocalStorage = storageWrapper.get('localStorage');


export default class PhotosTypes extends Component {
  static propTypes = {
    photoCategories: PropTypes.array.isRequired,
  };

  render() {
    const { photoCategories } = this.props;
    const locale = safeLocalStorage.getItem('locale');

    return (
      <div className={styles.photosTypes}>
        {photoCategories.map((item, i) => (
          <div key={`photo-type-${item.code}`} className={classnames(styles.photoType, { [styles.extra]: i > 2 })}>
            {item[locale]}
          </div>
        ))}
      </div>
    );
  }
}
