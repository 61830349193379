import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import ReactTooltip from 'react-tooltip';

import {
  maxBy, max, get, uniqueId
} from 'lodash';
import classnames from 'classnames';

import isTouchDevice from '../../helpers/isTouchDevice';
import numbersRounding from '../../helpers/numbersRounding';
import numbersFormatting from '../../helpers/numbersFormatting';

import tooltipStyles from '../Tooltip/index.module.css';
import styles from './BreakdownHorizontalBarChart.module.css';

const DEFAULT_MIDDLE_VALUE = '500 000';
const DEFAULT_MAX_VALUE = '1 000 000';

export default class BreakdownHorizontalBarChart extends PureComponent {
  static DISPLAY_TYPES = {
    STACKED: 'STACKED',
    TWO_BARS: 'TWO_BARS'
  };

  static propTypes = {
    dataList: PropTypes.array.isRequired,
    renderTooltipContent: PropTypes.func,
    displayType: PropTypes.oneOf([
      BreakdownHorizontalBarChart.DISPLAY_TYPES.STACKED, BreakdownHorizontalBarChart.DISPLAY_TYPES.TWO_BARS
    ]),
  };

  static defaultProps = {
    renderTooltipContent: null,
    displayType: BreakdownHorizontalBarChart.DISPLAY_TYPES.TWO_BARS,
  };

  getWidth = scaleValue => (value) => {
    const trueValue = (value / scaleValue) * 100;

    if (trueValue > 100) {
      return 100;
    } if (trueValue < 0) {
      return 0;
    }

    return trueValue;
  };

  renderEmptyBarRow = () => (
    <div className={styles.row}>
      <div className={styles.rowLabel} />
      <div className={styles.rowBar} />
      <div className={styles.bufferZone} />
    </div>
  );

  renderBarRows = ({
    dataList, roundedMaxValue, tooltipId, displayType
  }) => dataList.map((item) => {
    const getWidthByMax = this.getWidth(roundedMaxValue);

    const actualBarWidth = getWidthByMax(item.value);
    const expectedBarWidth = getWidthByMax(item.expectedValue);
    const targetBarWidth = getWidthByMax(item.targetValue);

    return (
      <div
        className={styles.row}
        key={uniqueId('barRow-')}
      >
        {item.labelHref ? (
          <NavLink className={classnames(styles.rowLabel, styles.rowLabelLink)} to={item.labelHref}>
            <span className={styles.rowLabelText}>{item.label}</span>
          </NavLink>
        )
          :
          <div className={styles.rowLabel}>{item.label}</div>}
        <div
          className={styles.rowBar}
          data-tip={JSON.stringify(item)}
          data-for={tooltipId}
        >
          {item.expectedValue > 0 && <div style={{ left: `${expectedBarWidth}%` }} className={styles.expectedBar} />}
          {displayType === BreakdownHorizontalBarChart.DISPLAY_TYPES.TWO_BARS &&
          item.targetValue > 0 && <div style={{ width: `${targetBarWidth}%` }} className={styles.targetBarTwoLine} />}
          {displayType === BreakdownHorizontalBarChart.DISPLAY_TYPES.TWO_BARS &&
          item.value > 0 && <div style={{ width: `${actualBarWidth}%` }} className={styles.actualBarTwoLine} />}
          {displayType === BreakdownHorizontalBarChart.DISPLAY_TYPES.STACKED &&
          item.targetValue > 0 && <div style={{ left: `${targetBarWidth}%` }} className={styles.targetBar} />}
          {displayType === BreakdownHorizontalBarChart.DISPLAY_TYPES.STACKED &&
          <div style={{ width: `${actualBarWidth}%` }} className={styles.actualBar} />}
        </div>
        <div className={styles.bufferZone} />
      </div>
    );
  });

  render() {
    const { dataList, renderTooltipContent, displayType } = this.props;

    const maxValue = get(maxBy(dataList, 'value'), 'value');
    const maxTargetValue = get(maxBy(dataList, 'targetValue'), 'targetValue');
    const finalMaxValue = max([maxValue, maxTargetValue]);

    const minValue = 0;
    const roundedMaxValue = numbersRounding(finalMaxValue, 'bigNumbers');
    const middleValue = roundedMaxValue / 2;

    const formattedMiddleValue = numbersFormatting(middleValue);
    const formattedMaxValue = numbersFormatting(roundedMaxValue);

    const tooltipId = uniqueId('horizontal-bar-chart-tooltip-');

    return (
      <div>
        <div className={styles.rowsWrapper}>
          {dataList.length > 0 ? this.renderBarRows({
            dataList, roundedMaxValue, tooltipId, displayType
          }) : this.renderEmptyBarRow()}
        </div>
        <div className={styles.scaleX}>
          <div className={styles.scaleXValues}>
            <div className={styles.scaleXMin}>{minValue}</div>
            <div className={styles.scaleXMiddle}>{middleValue ? formattedMiddleValue : DEFAULT_MIDDLE_VALUE}</div>
            <div className={styles.scaleXMax}>{roundedMaxValue ? formattedMaxValue : DEFAULT_MAX_VALUE}</div>
          </div>
        </div>

        {/* TODO: сделать у хвоста тултипа бордеры */}
        {renderTooltipContent && (
          <ReactTooltip
            className={classnames(tooltipStyles.lightTooltip, styles.horizontalBarTooltip)}
            id={tooltipId}
            effect='solid'
            type='light'
            place='top'
            getContent={dataTip => renderTooltipContent(JSON.parse(dataTip))}
            event={isTouchDevice() ? 'click' : null}
          />
        )}
      </div>
    );
  }
}
