const ABNORMALITY_INCIDENT_REGISTER = 'incident.write';
const OPERATIONAL_PLAN_WRITE = 'plan[operational].write';
const BUDGET_PLAN_WRITE = 'plan[budget].write';
const PLANTING_CYCLE_WRITE = 'plantingCycle.write';
const HARVEST_WRITE = 'harvest.write';
const PHOTO_WRITE = 'photo.write';
const MANUAL_OBSERVATION_WRITE = 'manualObservation.write';
const BENCHMARK = 'benchmark.access';
const ENERGY_REGISTRATION_WRITE = 'energyRegistration.write';
const LABOR_REGISTRATION_WRITE = 'work.write';
const HARVEST_FORECAST_WRITE = 'harvestForecast.write';

export const PERMISSIONS = {
  ABNORMALITY_INCIDENT_REGISTER,
  OPERATIONAL_PLAN_WRITE,
  BUDGET_PLAN_WRITE,
  PLANTING_CYCLE_WRITE,
  HARVEST_WRITE,
  PHOTO_WRITE,
  MANUAL_OBSERVATION_WRITE,
  BENCHMARK,
  ENERGY_REGISTRATION_WRITE,
  LABOR_REGISTRATION_WRITE,
  HARVEST_FORECAST_WRITE
};

function isNotEmpty(x) {
  if (x) {
    return 1;
  }

  return 0;
}

function zeroIfNull(x) {
  if (!x) {
    return 0;
  }

  return x;
}

function isMeOrMyNested(permission, { locationId, compartmentId, subNodeId }) {
  return zeroIfNull(permission.nodeId.locationId) === isNotEmpty(permission.nodeId.locationId) * zeroIfNull(locationId)
    && zeroIfNull(permission.nodeId.compartmentId) === isNotEmpty(permission.nodeId.compartmentId) * zeroIfNull(compartmentId)
    && zeroIfNull(permission.nodeId.subNodeId) === isNotEmpty(permission.nodeId.subNodeId) * zeroIfNull(subNodeId);
}

/**
 * Проверяет права пользователя
 * https://docs.google.com/document/d/1SoC6VDA3NhVf7vQnbg5in7zF5cleA9wi7DxWgTLXVLM
 *
 * @param permissions
 * @param permissionName
 * @param node
 * @returns {boolean} Есть ли требуемое разрешение в пользовательских правах
 */
export const checkPermissions = (permissions, permissionName, node) => permissions?.some(permission => permission.name === permissionName && isMeOrMyNested(permission, node));

/**
 * Проверяет право пользователя в текущей локации
 *
 * @param permissions
 * @param permissionName
 * @param locationId
 * @returns {boolean} Есть ли требуемое разрешение в пользовательских правах
 */
export const checkPermissionInLocation = (permissions, locationId, permissionName) => checkPermissions(
  permissions,
  permissionName,
  { locationId, compartmentId: 0, subNodeId: 0 }
);

/**
 * Проверяет право пользователя на изменение операционных планов в текущей локации
 *
 * @param permissions
 * @param locationId
 * @returns {boolean} Есть ли требуемое разрешение в пользовательских правах
 */
export const checkAddOperationalPlansPermission = (permissions, locationId) => checkPermissionInLocation(
  permissions,
  locationId,
  PERMISSIONS.OPERATIONAL_PLAN_WRITE,
);

/**
 * Проверяет право пользователя на изменение бюджетных планов в текущей локации
 *
 * @param permissions
 * @param locationId
 * @returns {boolean} Есть ли требуемое разрешение в пользовательских правах
 */
export const checkAddBudgetPlansPermission = (permissions, locationId) => checkPermissionInLocation(
  permissions,
  locationId,
  PERMISSIONS.BUDGET_PLAN_WRITE,
);

/**
 * Проверяет право пользователя на изменение циклов в текущей локации
 *
 * @param permissions
 * @param locationId
 * @returns {boolean} Есть ли требуемое разрешение в пользовательских правах
 */
export const checkAddPlantingCyclePermission = (permissions, locationId) => checkPermissionInLocation(
  permissions,
  locationId,
  PERMISSIONS.PLANTING_CYCLE_WRITE,
);

/**
 * Проверяет право пользователя на изменение урожая в текущей локации
 *
 * @param permissions
 * @param locationId
 * @returns {boolean} Есть ли требуемое разрешение в пользовательских правах
 */
export const checkAddHarvestPermission = (permissions, locationId) => checkPermissionInLocation(
  permissions,
  locationId,
  PERMISSIONS.HARVEST_WRITE,
);

/**
 * Проверяет право пользователя на добавление фотографий в текущей локации
 *
 * @param permissions
 * @param locationId
 * @returns {boolean} Есть ли требуемое разрешение в пользовательских правах
 */
export const checkAddPhotoPermission = (permissions, locationId) => checkPermissionInLocation(
  permissions,
  locationId,
  PERMISSIONS.PHOTO_WRITE,
);

/**
 * Проверяет право пользователя на добавление измерений в текущей локации
 *
 * @param permissions
 * @param locationId
 * @returns {boolean} Есть ли требуемое разрешение в пользовательских правах
 */
export const checkAddManualObservationPermission = (permissions, locationId) => checkPermissionInLocation(
  permissions,
  locationId,
  PERMISSIONS.MANUAL_OBSERVATION_WRITE,
);

/**
 * Проверяет право пользователя на добавление измерений в текущей локации
 *
 * @param permissions
 * @param locationId
 * @returns {boolean} Есть ли требуемое разрешение в пользовательских правах
 */
export const checkBenchmarkPermission = (permissions, locationId) => checkPermissionInLocation(
  permissions,
  locationId,
  PERMISSIONS.BENCHMARK,
);

/**
 * Проверяет право пользователя на изменение данных по энергии в текущей локации
 *
 * @param permissions
 * @param locationId
 * @returns {boolean} Есть ли требуемое разрешение в пользовательских правах
 */
export const checkAddEnergyPermission = (permissions, locationId) => checkPermissionInLocation(
  permissions,
  locationId,
  PERMISSIONS.ENERGY_REGISTRATION_WRITE,
);

/**
 * Проверяет право пользователя на изменение данных по работам в теплицах в текущей локации
 *
 * @param permissions
 * @param locationId
 * @returns {boolean} Есть ли требуемое разрешение в пользовательских правах
 */
export const checkAddLaborPermission = (permissions, locationId) => checkPermissionInLocation(
  permissions,
  locationId,
  PERMISSIONS.LABOR_REGISTRATION_WRITE,
);

/**
 * Проверяет право пользователя на изменение harvest forecast в текущей локации
 *
 * @param permissions
 * @param locationId
 * @returns {boolean} Есть ли требуемое разрешение в пользовательских правах
 */
export const checkAddHarvestForecastPermission = (permissions, locationId) => checkPermissionInLocation(
  permissions,
  locationId,
  PERMISSIONS.HARVEST_FORECAST_WRITE,
);

/**
 * Проверяет право пользователя на добавление/изменение инцидентов (проверяет isExpert)
 *
 * @param permissions
 * @param locationId
 * @returns {boolean} Есть ли требуемое разрешение в пользовательских правах
 */
export const checkAbnormalityIncidentRegisterPermission = (permissions, locationId) => checkPermissionInLocation(
  permissions,
  locationId,
  PERMISSIONS.ABNORMALITY_INCIDENT_REGISTER,
);

