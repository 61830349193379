import moment from 'moment-timezone';
import queryString from 'query-string';
import { createSelector } from 'reselect';
import {
  filter, find, get, reverse, sortBy, uniqBy
} from 'lodash';

import {
  getAllVarieties,
  getAllCompartments,
  getAllPlantingCycles,
  getCurrentLocation,
  getLocation
} from '../company/selectors';
import { getCompletedCycles } from '../newCrops/selectors';

export const getCropsPerformanceSortingParameter = state => state.cropsPerformance.cropsPerformanceSorting;
export const getCropsPerformanceData = state => state.cropsPerformance.cropsPerformanceData;
export const getCropsPerformanceStarted = state => state.cropsPerformance.getCropsPerformanceStarted;
export const getCropsPerformanceEnded = state => state.cropsPerformance.getCropsPerformanceEnded;
export const isCropsFetching = state => state.newCrops.isCropsFetching;

export const getRouteLocation = (state, props) => props.location;

export const getRouteQueryParams = createSelector([getRouteLocation], location => queryString.parse(get(location, 'search')));

export const getCropsPerformanceYear = createSelector(
  [getRouteQueryParams],
  (query) => {
    const year = get(query, 'year');

    return year ? +year : null;
  }
);

export const getCropsPerformanceSorting = createSelector(
  [getCropsPerformanceSortingParameter],
  sorting => sorting,
);

export const getCropsPerformanceFetching = createSelector(
  [getCropsPerformanceStarted, getCropsPerformanceEnded, isCropsFetching],
  (started, ended, fetching) => (!!started && !ended) || fetching,
);

export const getSortedCropsPerformanceItems = createSelector(
  [getCropsPerformanceSorting, getCropsPerformanceData, getAllVarieties, getAllCompartments, getAllPlantingCycles, getCurrentLocation],
  (sorting, cropsPerformanceData, allVarieties, allCompartments, allPlantingCycles, location) => {
    const rawItems = cropsPerformanceData.map((item, i) => ({
      id: get(item, 'cycle.id', i),
      ...item,
    }));

    const locationCompartments = allCompartments.filter(item => get(item, 'relationships.location.data[0].id') === get(location, 'id'));
    const locationPlantingCycles = filter(allPlantingCycles, plantingCycle => locationCompartments.find(compartment => compartment.id === get(plantingCycle, 'relationships.compartment.data[0].id')));
    const varietiesIds = locationPlantingCycles.map(plantingCycle => get(plantingCycle, 'relationships.variety.data[0].id'));
    const locationVarieties = filter(allVarieties, variety => varietiesIds.find(id => id === variety.id));
    const uniqLocationVarieties = uniqBy(locationVarieties, 'attributes.species');
    const locationSpecies = uniqLocationVarieties.map(variety => get(variety, 'attributes.species')).sort((a, b) => a.localeCompare(b));

    return locationSpecies.reduce((reducer, locationSpeciesItem) => {
      const temp = reducer;

      temp[locationSpeciesItem] = {};

      const filteredItems = rawItems.filter((rawItem) => {
        const variety = find(allVarieties, varietyItem => get(varietyItem, 'id') === get(rawItem, 'cycle.varietyRef.id'));

        return get(variety, 'attributes.species') === locationSpeciesItem;
      });

      const sortingType = get(sorting, `${locationSpeciesItem}.type`);

      switch (sortingType) {
        case 'cycle':
          temp[locationSpeciesItem].items = sortBy(filteredItems, (item) => {
            const variety = find(allVarieties, varietyItem => get(varietyItem, 'id') === get(item, 'cycle.varietyRef.id'));

            return get(variety, 'attributes.name');
          });

          break;
        case 'compartment':
          temp[locationSpeciesItem].items = sortBy(filteredItems, 'compartment.attributes.name');
          break;
        case 'totalWeeks':
          temp[locationSpeciesItem].items = sortBy(filteredItems, 'totalWeeks');
          break;
        case 'harvestedWeeks':
          temp[locationSpeciesItem].items = sortBy(filteredItems, 'harvestWeeks');
          break;
        case 'actualHarvest':
          if (filteredItems.map(item => get(item, 'actualHarvest.amount')).some(item => !!item)) {
            temp[locationSpeciesItem].items = sortBy(filteredItems, 'actualHarvest.amount');
          } else {
            temp[locationSpeciesItem].items = filteredItems;
          }

          break;
        case 'harvestGoal':
          if (filteredItems.map(item => get(item, 'harvestGoal.amount')).some(item => !!item)) {
            temp[locationSpeciesItem].items = sortBy(filteredItems, 'harvestGoal.amount');
          } else {
            temp[locationSpeciesItem].items = filteredItems;
          }

          break;
        case 'actualHarvestVsGoal':
          if (filteredItems.map(a => get(a, 'actualHarvestVsGoal.percentage.amount')).some(a => !!a)) {
            temp[locationSpeciesItem].items = sortBy(filteredItems, (item) => {
              const currentAmount = get(item, 'actualHarvestVsGoal.percentage.amount');
              const currentCategory = get(item, 'actualHarvestVsGoal.category') === 'red' ? -1 : 1;

              return currentCategory * currentAmount;
            });
          } else {
            temp[locationSpeciesItem].items = filteredItems;
          }

          break;
        case 'avgHarvestPerWeek':
          if (filteredItems.map(item => get(item, 'avgHarvestPerWeek.amount')).some(item => !!item)) {
            temp[locationSpeciesItem].items = sortBy(filteredItems, 'avgHarvestPerWeek.amount');
          } else {
            temp[locationSpeciesItem].items = filteredItems;
          }

          break;
        default:
          temp[locationSpeciesItem].items = filteredItems;
          break;
      }

      return temp;
    }, {});
  }
);

export const getDirectionSortedCropsPerformanceItems = createSelector(
  [getCropsPerformanceSorting, getSortedCropsPerformanceItems],
  (sorting, items) => {
    if (sorting) {
      Object.keys(sorting).forEach((key) => {
        const sortingDirection = get(sorting, `${key}.direction`);
        const sortingCategory = get(sorting, `${key}.category`);
        const sortingType = get(sorting, `${key}.type`);


        if (items[sortingCategory]) {
          const item = items[sortingCategory];
          const rows = items[sortingCategory].items;

          let isEmptyColumn = false;

          switch (sortingType) {
            case 'actualHarvest':
              isEmptyColumn = rows.map(row => get(row, 'actualHarvest.amount')).every(row => !row);

              break;
            case 'harvestGoal':
              isEmptyColumn = rows.map(row => get(row, 'harvestGoal.amount')).every(row => !row);

              break;
            case 'actualHarvestVsGoal':
              isEmptyColumn = rows.map(row => get(row, 'actualHarvestVsGoal.percentage.amount')).every(row => !row);

              break;
            case 'avgHarvestPerWeek':
              isEmptyColumn = rows.map(row => get(row, 'avgHarvestPerWeek.amount')).every(row => !row);

              break;
            default:
              isEmptyColumn = false;

              break;
          }

          if (!isEmptyColumn) {
            item.items = sortingDirection === 'down' ? reverse(items[sortingCategory].items) : items[sortingCategory].items;
          }
        }
      });
    }

    return items;
  }
);


export const getPlantingCyclesYears = createSelector(
  [getAllPlantingCycles, getAllCompartments, getLocation, getCompletedCycles],
  (allPlantingCycles, allCompartments, location, cycles) => {
    const endDates = cycles
      .map(cycle => get(cycle, 'cycle.endDate'))
      .filter(item => !!item && moment(item, 'YYYY-MM-DD').isSameOrBefore(moment().hour(0).minutes(0).seconds(0)
        .milliseconds(0)))
      .map(endDateText => moment(endDateText, 'YYYY-MM-DD').year())
      .filter(year => year <= moment().year());

    const years = [];

    if (endDates.length) {
      const minYear = Math.min(...endDates) || moment().year();
      // const maxYear = Math.max(...endDates) || moment().year();
      const maxYear = moment().year();


      for (let y = minYear; y <= maxYear; y++) {
        years.push(y);
      }

      if (!years.length) {
        years.push(moment().year());
      }

      years.reverse();
    }

    return years.length === 0 ? [moment().year()] : years;
  }
);

export const getIsEmptyCropsPerformance = createSelector(
  [getAllPlantingCycles, getAllCompartments, getLocation, getCompletedCycles],
  (allPlantingCycles, allCompartments, location, cycles) => {
    const endDates = cycles
      .map(cycle => get(cycle, 'cycle.endDate'))
      .filter(item => !!item && moment(item, 'YYYY-MM-DD').isSameOrBefore(moment().hour(0).minutes(0).seconds(0)
        .milliseconds(0)))
      .map(endDateText => moment(endDateText, 'YYYY-MM-DD').year())
      .filter(year => year <= moment().year());

    const years = [];

    if (endDates.length) {
      const minYear = Math.min(...endDates) || moment().year();
      const maxYear = Math.max(...endDates) || moment().year();


      for (let y = minYear; y <= maxYear; y++) {
        years.push(y);
      }

      if (!years.length) {
        years.push(moment().year());
      }

      years.reverse();
    }

    return years.length === 0;
  }
);
