import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import sizeMe from 'react-sizeme';
import { injectIntl, intlShape } from 'react-intl';

import classnames from 'classnames';
import { get, uniqueId } from 'lodash';

import useContainerDimensions from '../../hooks/useContainerDimensions';

import DefaultSimpleTable from '../DefaultSimpleTable';
import ArrowDown from '../Icons/ArrowDown';

import NestedTableCollapseRow from './components/NestedTableCollapseRow';

import styles from './NestedTable.module.css';

// TODO: Вынести рендер-функции в отдельные компоненты

const handlePlanFactSorting = (updateSorting, tableSorting, type) => {
  const isOldSort = type === get(tableSorting, 'type');
  const currentSortDirection = get(tableSorting, 'direction');

  let sorting = {};

  if (currentSortDirection === 'up' && isOldSort) {
    sorting = { type, direction: 'down' };
  } else if (!isOldSort) {
    sorting = { type, direction: 'up' };
  } else {
    sorting = null;
  }

  updateSorting(sorting);
};

const renderHeaderRows = (updateSorting, tableSorting, headers) => {
  const activeSort = get(tableSorting, 'type');
  const sortDirection = get(tableSorting, 'direction');

  if (headers.length === 0) {
    return null;
  }

  return (
    <tr>
      <th className={styles.chartIconWrapper} />
      {headers.map(item => (
        <th
          key={uniqueId('tableHeader-')}
          onClick={() => handlePlanFactSorting(updateSorting, tableSorting, item.value)}
          className={classnames(styles.activeTh, item.className, {
            [styles.activeSort]: activeSort === item.value,
            [styles.thLeft]: item.textAlign === 'left',
          })}
        >
          <div className={styles.thContent}>
            {item.label}
            <div
              className={classnames(styles.arrow, {
                [styles.sortDirectionDown]: sortDirection === 'down' && activeSort === item.value
              })}
            >
              <ArrowDown />
            </div>
          </div>
        </th>
      ))}
    </tr>
  );
};

const renderBodyRows = ({
  onRowClick,
  selectedRow,
  rows,
}) => {
  if (rows.length === 0) {
    return null;
  }

  return (
    <>
      {rows.map(row => (
        <NestedTableCollapseRow
          key={uniqueId('nestedTableRow-')}
          row={row}
          selectedRow={selectedRow}
          onRowClick={onRowClick}
        />
      ))}
    </>
  );
};

const NestedTable = injectIntl(({
  headerItems, bodyItems, tableSize, selectedRow,
  tableSorting, onRowClick, updateSorting,
  className, fixedColumn, size, showEmptyState,
  intl: { formatMessage }
}) => {
  const tableRef = useRef();

  // Передаём headerItems.length чтобы ширина пересчитывалась при изменении количества колонок
  const { width } = useContainerDimensions(tableRef, headerItems.length);

  const isTableOverflow = size.width < width;
  const isRenderEmpty = showEmptyState && bodyItems.length === 0;

  return (
    <>
      <div
        className={classnames(
          styles.tableWrapper,
          {
            [styles.fixedColumn]: fixedColumn && isTableOverflow,
            [styles.sizeSmall]: tableSize === 'small',
            [styles.tableWrapperEmpty]: isRenderEmpty,
            [styles.tableScrollerDisable]: isRenderEmpty
          }
        )}
      >
        <div className={classnames(
          styles.tableScroller,
          {
            [styles.tableScrollerDisable]: isRenderEmpty
          }
        )}
        >
          <DefaultSimpleTable
            ref={tableRef}
            className={classnames(styles.table, className)}
            headerRows={renderHeaderRows(updateSorting, tableSorting, headerItems)}
            bodyRows={renderBodyRows({
              onRowClick,
              selectedRow,
              rows: bodyItems,
            })}
            size={tableSize}
          />
        </div>
      </div>
      {isRenderEmpty && (
        <div className={styles.emptyState}>
          {formatMessage({ id: 'harvestDashboard.emptyText' })}
        </div>
      )}
    </>
  );
});

NestedTable.propTypes = {
  intl: intlShape.isRequired,
  headerItems: PropTypes.array,
  bodyItems: PropTypes.array,
  className: PropTypes.string,
  tableSorting: PropTypes.object,
  updateSorting: PropTypes.func,
  onRowClick: PropTypes.func,
  selectedRow: PropTypes.string,
  size: PropTypes.object.isRequired,
  tableSize: PropTypes.string,
  fixedColumn: PropTypes.bool,
  showEmptyState: PropTypes.bool,
};

NestedTable.defaultProps = {
  headerItems: [],
  bodyItems: [],
  className: null,
  tableSorting: null,
  selectedRow: null,
  onRowClick: null,
  tableSize: null,
  fixedColumn: true,
  updateSorting: () => {},
  showEmptyState: false,
};

export default sizeMe()(NestedTable);
