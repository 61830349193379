import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage, intlShape } from 'react-intl';
import { withRouter } from 'react-router';

import PasswordField from '../../../PasswordField';

import styles from './Login.module.css';

const getErrorMessage = (formatMessage, errorCode) => {
  switch (errorCode) {
    case 'INCORRECT_NAME_OR_PASSWORD':
      return formatMessage({ id: 'login.errors.incorrect' });
    default:
      return formatMessage({ id: 'notification.backendError' });
  }
};

class Login extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    history: PropTypes.object.isRequired,
    error: PropTypes.bool,

    requestUserAuth: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: false,
  };

  state = {
    email: '',
    password: '',
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handlerKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handlerKeyDown);
  }

  handlerKeyDown = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      return this.handlerLogin();
    }

    return null;
  };

  handlerLogin = async () => {
    const { email, password } = this.state;

    const { requestUserAuth, history } = this.props;

    requestUserAuth({ email, password, browserHistory: history });
  };

  render() {
    const {
      error,
      intl,
    } = this.props;
    const { formatMessage } = intl;

    const { email, password } = this.state;

    return (
      <div className={styles.loginWrapper}>
        <div className={styles.logo} />
        <input
          className={styles.username}
          name='email'
          type='text'
          placeholder={formatMessage({ id: 'login.email' })}
          value={email}
          onChange={e => this.setState({ email: e.target.value })}
        />
        <PasswordField
          className={styles.password}
          value={password}
          placeholder={formatMessage({ id: 'login.password' })}
          onChange={e => this.setState({ password: e.target.value })}
        />
        <button type='button' onClick={this.handlerLogin} className={styles.button}><FormattedMessage id='login.submit' /></button>
        {error && <div className={styles.error}>{getErrorMessage(formatMessage, 'INCORRECT_NAME_OR_PASSWORD')}</div>}

        <div className={styles.forgot}>
          <RouterLink className={styles.forgotLink} to='/login/reset-password'>
            {formatMessage({ id: 'password.forgotPassword' })}
          </RouterLink>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
