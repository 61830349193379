import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';

import CellPhoto from '../CellPhoto';

import styles from './WeekPhoto.module.css';


const WeekPhoto = (props) => {
  const {
    onClick,
    onDelete,
    week,
    photoCategories,
    isAddPhotoAvailable,
  } = props;

  const handlerClick = (options) => {
    if (onClick) {
      onClick(options);
    }
  };

  const handlerDelete = (options) => {
    if (onDelete) {
      onDelete(options);
    }
  };

  if (week) {
    const { week: weekNumber, year, date } = week;

    return (
      <div className={classnames(styles.weekPhoto)}>
        <div className={styles.tableHeader}>
          <div className={styles.tableHeaderLink}>
            {<FormattedMessage id='photos.week.one' />} {weekNumber}
          </div>
          <div className={styles.tableHeaderData}>
            {date}
          </div>
        </div>
        <div className={styles.weekPhotoMain}>
          {photoCategories.slice(0, 3).map((photoCategory) => {
            const { code } = photoCategory;
            return (
              <CellPhoto
                onDelete={handlerDelete}
                key={`cell-photo-${code}`}
                year={year}
                category={code}
                week={weekNumber}
                column={`Week ${weekNumber}`}
                onClick={handlerClick}
                isAddPhotoAvailable={isAddPhotoAvailable}
              />
            );
          })}
        </div>
        <div className={styles.weekPhotoExtra}>
          {photoCategories.slice(3).map((photoCategory) => {
            const { code } = photoCategory;
            return (
              <CellPhoto
                onDelete={handlerDelete}
                key={`cell-photo-${code}`}
                year={year}
                category={code}
                week={weekNumber}
                column={`Week ${weekNumber}`}
                onClick={handlerClick}
                isAddPhotoAvailable={isAddPhotoAvailable}
              />
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};

WeekPhoto.propTypes = {
  week: PropTypes.object.isRequired,
  photoCategories: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  isAddPhotoAvailable: PropTypes.bool.isRequired,
};

WeekPhoto.defaultProps = {
  onClick: null,
  onDelete: null,
};

export default WeekPhoto;
