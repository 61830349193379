import { useEffect } from 'react';
import { isFinite } from 'lodash';
import { select } from 'd3';

function useHoverPoints(groupRef, options) {
  const {
    chartParams: {
      x, y, xValue, yValue
    },
    traces,
    xCoord,
    isShowHoverPoints
  } = options;
  useEffect(() => {
    const group = groupRef.current ? select(groupRef.current) : null;
    if (group) {
      group.selectAll('circle').remove();
    }
    if (group && isShowHoverPoints) {
      for (let i = 0; i < traces.length; i += 1) {
        const {
          trace,
          value
        } = traces[i];

        if (isFinite(value) && !trace.marker) {
          const cy = y(value);
          const cx = x(xCoord);

          group
            .append('circle')
            .attr('class', 'data-point')
            .attr('r', 4)
            .style('fill', trace.line.color)
            .attr('cx', cx)
            .attr('cy', cy);
        }
      }
    }
  }, [
    groupRef,
    x, y, xValue, yValue,
    xCoord,
    traces,
    isShowHoverPoints
  ]);
}

export default useHoverPoints;
