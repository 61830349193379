import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import classnames from 'classnames';

import CalendarIcon from 'components/Icons/CalendarIcon';

import styles from './MobileDateButton.module.css';

const MobileDateButton = ({
  intl: { formatMessage }, className, date, disabled, onClick,
}) => (
  <button
    type='button'
    className={classnames(styles.button, className, { [styles.disabled]: disabled })}
    onClick={onClick}
    disabled={disabled}
  >
    <span className={styles.placeholder}>
      {formatMessage({ id: 'harvest.period' })}
    </span>
    {date}
    <CalendarIcon className={styles.mobileIcon} />
  </button>
);


MobileDateButton.propTypes = {
  intl: intlShape.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  disabled: PropTypes.bool,
};

MobileDateButton.defaultProps = {
  className: undefined,
  onClick: null,
  date: null,
  disabled: false,
};

export default injectIntl(MobileDateButton);
