import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import {
  groupBy,
  find,
  uniqueId,
  sortBy,
} from 'lodash';
import cn from 'classnames';

import { hideIntercomLauncher, showIntercomLauncher } from 'helpers/intercomHelpers';
import { getNameByLocal } from 'helpers/getNameByLocal';

import DefaultCircleLoader from 'components/DefaultCircleLoader';
import BigButton from 'components/BigButton';
import Typography from 'components/Typography';
import BigSwitch from 'components/BigSwitch';
import DefaultTextInput from 'components/DefaultTextInput';
import UserBage from 'components/UserBage';
import DefaultDeleteDialog from 'components/DefaultDeleteDialog';

import CloseIcon from 'components/Icons/Close';
import DeleteIcon from 'components/Icons/Delete';

// Вынести в отдельный компонент и отредактировать
import AddUsersDropdown from 'components/HarvestForecastDashboard/components/HarvestForecastModalPublish/components/AddUsersDropdown';

import SelectIncidentsDialog from '../../../SelectIncidentsDialog';
import IncidentsTable from '../../../IncidentsTable';

import styles from './AlertPanel.module.css';

const MAX_NAME_LENGTH = 200;

const getPreparedUsersList = (users) => {
  const preparedUsers = users.map(user => ({
    id: user?.user.id,
    name: user?.user.name,
    email: user?.user.email,
  }));

  return sortBy(preparedUsers, 'name');
};

const normalizeIncidentsList = (intl, selectedIncidents, incidentTypeCategories, incidentTypes) => {
  if (incidentTypes.length === 0 || !incidentTypeCategories.length === 0) {
    return [];
  }

  const { locale } = intl;

  const groupedIncidentTypes = groupBy(incidentTypes, 'category');

  const incidentsList = Object.keys(groupedIncidentTypes).map((key) => {
    const currentCategory = find(incidentTypeCategories, { id: Number(key) });
    const groupName = getNameByLocal(currentCategory, locale);
    const list = groupedIncidentTypes[key].map(listItem => ({
      id: listItem.id,
      name: getNameByLocal(listItem, locale),
      checked: selectedIncidents.includes(listItem.id),
    }));

    return {
      groupName,
      list,
    };
  });

  return incidentsList;
};

const getNotificationSubscribers = (usersList, recipients) => {
  if (!usersList || !recipients || usersList?.length === 0 || recipients?.length === 0) {
    return [];
  }

  return recipients?.reduce((acc, item) => {
    const currentUser = find(usersList, { id: Number(item.address) });

    if (currentUser) {
      return [...acc, currentUser];
    }

    return acc;
  }, []);
};

const AlertPanel = ({
  intl,
  intl: { locale },
  onCollapse,
  panelCollapsed,
  isFetching,
  incidentTypeCategories,
  incidentTypes,
  userPrivileges,
  alertRules,
  activeAlertId,
  requestCreateAlertRule,
  requestUpdateAlertRule,
  requestDeleteAlertRule,
}) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (panelCollapsed) {
      showIntercomLauncher();
    } else {
      hideIntercomLauncher();
    }
  }, [panelCollapsed]);

  const isNewRule = activeAlertId === 0;

  const emptyAlertRule = {
    disabled: false,
    id: 0,
    incidents: [],
    name: undefined,
    recipients: [],
  };

  const currentAlertRule = find(alertRules, { id: activeAlertId }) || emptyAlertRule;
  const usersList = getPreparedUsersList(userPrivileges);

  const initialSwitchEnabled = !currentAlertRule?.disabled;
  const initialAlertName = currentAlertRule?.name;
  const initialSubscribers = getNotificationSubscribers(usersList, currentAlertRule?.recipients);
  const activeIncidentsIds = currentAlertRule?.incidents?.map(item => item.incidentTypeId) || [];

  const [switchEnabled, setSwitchEnabled] = useState(initialSwitchEnabled);
  const [alertName, setAlertName] = useState(initialAlertName);
  const [selectedIncidents, setSelectedIncidents] = useState(activeIncidentsIds);
  const [notificationSubscribers, setNotificationSubscribers] = useState(initialSubscribers);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [isElementAtTheRight, setIsElementAtTheRight] = useState();

  const getIsElementAtRight = useCallback(() => {
    const parentWidth = dropdownRef?.current?.offsetParent?.offsetWidth;
    const elementWidth = dropdownRef?.current?.offsetWidth;
    const elementOffsetLeft = dropdownRef?.current?.offsetLeft;

    return (elementOffsetLeft + elementWidth) > parentWidth / 2;
  }, [dropdownRef]);

  // Изменяем состояние свитча, если поменяли его из списка всех правил
  useEffect(() => {
    setSwitchEnabled(!currentAlertRule?.disabled);
  }, [
    currentAlertRule,
  ]);


  useEffect(() => {
    setIsElementAtTheRight(getIsElementAtRight());
  }, [
    setIsElementAtTheRight,
    getIsElementAtRight,
    notificationSubscribers,
  ]);

  const incidentsList = normalizeIncidentsList(intl, selectedIncidents, incidentTypeCategories, incidentTypes);

  const getTableIncidentsList = useCallback(incidents => incidents.map((item) => {
    const currentType = find(incidentTypes, { id: Number(item) });
    const currentIncidentStatus = find(currentAlertRule?.incidents, { incidentTypeId: Number(item) });
    const text = getNameByLocal(currentType, locale);

    return {
      id: item,
      text,
      incidentSeverities: currentIncidentStatus?.incidentSeverities || [3],
    };
  }), [currentAlertRule, incidentTypes, locale]);
  const [tableIncidentsListState, setTableIncidentsListState] = useState(getTableIncidentsList(selectedIncidents));

  // useEffect(() => setTableIncidentsListState(getTableIncidentsList(selectedIncidents)), [setTableIncidentsListState, getTableIncidentsList, selectedIncidents]);

  const [isOpenSelectIncidentsDialog, setIsOpenSelectIncidentsDialog] = useState(false);

  const { formatMessage } = intl;

  const handlerIncidentCollapse = useCallback(() => {
    onCollapse();
  }, [onCollapse]);

  const handlerDeleteAlert = useCallback(() => setIsDeleteDialogOpened(true), [setIsDeleteDialogOpened]);

  const handlerOnSelectIncidents = useCallback((ids) => {
    if (ids?.length > 0 && formErrors.incidentsField) {
      const { incidentsField, ...newFormErrors } = formErrors;

      setFormErrors(newFormErrors);
    }

    setSelectedIncidents(ids);
    setTableIncidentsListState(getTableIncidentsList(ids));
  }, [formErrors, getTableIncidentsList, setTableIncidentsListState, setSelectedIncidents]);

  const handlerUpdateInputValue = useCallback((event) => {
    const isNameTooLong = event.target.value?.length > MAX_NAME_LENGTH;

    if (isNameTooLong) {
      return setFormErrors({
        ...formErrors,
        nameField: formatMessage({ id: 'formErrors.cannotBeExceedCharacters' }, { number: MAX_NAME_LENGTH }),
      });
    }

    if (formErrors?.nameField) {
      const { nameField, ...newFormErrors } = formErrors;

      setFormErrors(newFormErrors);
    }

    return setAlertName(event.target.value);
  }, [setFormErrors, formatMessage, formErrors, setAlertName]);

  const handlerClickSubscriberOption = useCallback(({ option, onlyCheck }) => {
    let newAdditionalSubscribers = [];

    if (option?.id === 'all') {
      const isAllChecked = notificationSubscribers?.length === usersList?.length;

      if (isAllChecked) {
        return setNotificationSubscribers([]);
      }

      return setNotificationSubscribers(usersList);
    }

    const isAlreadyExist = find(notificationSubscribers, { id: option?.id });
    const selectedUser = find(usersList, { id: option?.id });

    if (onlyCheck) {
      return setNotificationSubscribers([selectedUser]);
    }

    if (isAlreadyExist) {
      newAdditionalSubscribers = notificationSubscribers.filter(item => item.id !== option?.id);
    } else {
      newAdditionalSubscribers = [...notificationSubscribers, selectedUser];
    }

    if (newAdditionalSubscribers?.length > 0 && formErrors.recipientsField) {
      const { recipientsField, ...newFormErrors } = formErrors;

      setFormErrors(newFormErrors);
    }

    return setNotificationSubscribers(newAdditionalSubscribers);
  }, [setFormErrors, formErrors, setNotificationSubscribers, notificationSubscribers, usersList]);

  const handlerOnSubscriberDeleteClick = useCallback((user) => {
    const newAdditionalSubscribers = notificationSubscribers.filter(subscriber => subscriber.id !== user.id);

    setNotificationSubscribers(newAdditionalSubscribers);
  }, [setNotificationSubscribers, notificationSubscribers]);

  const handlerSave = useCallback(() => {
    const isNameEmpty = !alertName;
    const isNameTooLong = alertName?.length > MAX_NAME_LENGTH;
    const isIncidentsEmpty = !tableIncidentsListState || tableIncidentsListState?.length === 0;
    const isSubscribersEmpty = !notificationSubscribers || notificationSubscribers?.length === 0;

    let newFormErrors = formErrors;

    if (isNameTooLong) {
      newFormErrors = {
        ...newFormErrors,
        nameField: formatMessage({ id: 'formErrors.cannotBeExceedCharacters' }, { number: MAX_NAME_LENGTH }),
      };
    }

    if (isNameEmpty) {
      newFormErrors = {
        ...newFormErrors,
        nameField: formatMessage({ id: 'formErrors.requiredField' }),
      };
    }

    if (isIncidentsEmpty) {
      newFormErrors = {
        ...newFormErrors,
        incidentsField: formatMessage({ id: 'alerting.incidentsRequired' }),
      };
    }

    if (isSubscribersEmpty) {
      newFormErrors = {
        ...newFormErrors,
        recipientsField: formatMessage({ id: 'alerting.recipientsRequired' }),
      };
    }

    if (isNameTooLong || isNameEmpty || isIncidentsEmpty || isSubscribersEmpty) {
      return setFormErrors(newFormErrors);
    }

    const alertData = {
      name: alertName,
      incidents: tableIncidentsListState.map(item => ({
        incidentTypeId: item.id,
        incidentSeverities: item.incidentSeverities
      })),
      recipients: notificationSubscribers.map(item => ({ address: item.id })),
      disabled: !switchEnabled,
    };

    if (Number(activeAlertId) === 0) {
      return requestCreateAlertRule({ alertData, actionAfterSuccess: onCollapse });
    }

    return requestUpdateAlertRule({ alertId: Number(activeAlertId), alertData, actionAfterSuccess: onCollapse });
  }, [
    formatMessage,
    onCollapse,
    requestUpdateAlertRule,
    activeAlertId,
    alertName,
    notificationSubscribers,
    switchEnabled,
    tableIncidentsListState,
    requestCreateAlertRule,
    setFormErrors,
    formErrors,
  ]);

  return (
    <div className={cn(styles.panel, {
      [styles.collapsed]: panelCollapsed,
      [styles.dialogOpened]: isOpenSelectIncidentsDialog || isDeleteDialogOpened,
    })}
    >
      {isFetching && <DefaultCircleLoader />}
      <div className={styles.header}>
        <span className={styles.headerText}>
          {formatMessage({ id: 'alerting.addRule' })}
        </span>
        <div className={styles.buttonsWrapper}>
          {!isNewRule && (
            <button type='button' className={cn(styles.iconButton, styles.deleteIcon)} onClick={handlerDeleteAlert}>
              <DeleteIcon className={styles.icon} />
            </button>
          )}
          <button type='button' className={styles.iconButton} onClick={handlerIncidentCollapse}>
            <CloseIcon className={styles.icon} />
          </button>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.section}>
          <Typography variant='h3' className={styles.sectionHeader}>
            {formatMessage({ id: 'alerting.enableAlertRule' })}
          </Typography>
          <div className={styles.sectionContent}>
            <BigSwitch
              className={styles.switch}
              onClick={() => setSwitchEnabled(!switchEnabled)}
              value={switchEnabled}
              title={switchEnabled ?
                formatMessage({ id: 'button.enabledDeclension' })
                :
                formatMessage({ id: 'button.disabledDeclension' })}
            />
          </div>
        </div>
        <div className={styles.section}>
          <Typography variant='h3' className={styles.sectionHeader}>
            {formatMessage({ id: 'alerting.name' })}
          </Typography>
          <Typography variant='subtitle3' className={styles.sectionSubHeader}>
            {formatMessage({ id: 'alerting.addRuleName' })}
          </Typography>
          <div className={styles.sectionContent}>
            <DefaultTextInput
              className={styles.ruleNameInput}
              placeholder={formatMessage({ id: 'alerting.name' })}
              value={alertName}
              onChange={handlerUpdateInputValue}
              error={formErrors?.nameField}
            />
          </div>
        </div>
        <div className={styles.section}>
          <Typography variant='h3' className={styles.sectionHeader}>
            {formatMessage({ id: 'incidentsTimeline.incidentPanelLabel' })}
          </Typography>
          <Typography variant='subtitle3' className={styles.sectionSubHeader}>
            {formatMessage({ id: 'alerting.addIncidentsForAlerts' })}
          </Typography>
          {tableIncidentsListState?.length > 0 && (
            <IncidentsTable
              incidentsList={tableIncidentsListState}
              setIncidentsList={setTableIncidentsListState}
            />
          )}
          <div className={styles.sectionContent}>
            <BigButton
              className={styles.selectIncidentsButton}
              title={formatMessage({ id: 'alerting.selectIncidents' })}
              onClick={() => setIsOpenSelectIncidentsDialog(true)}
              theme='light'
            />
            {formErrors?.incidentsField && (
              <div className={styles.error}>
                {formErrors.incidentsField}
              </div>
            )}
          </div>
        </div>
        <div className={styles.section}>
          <Typography variant='h3' className={styles.sectionHeader}>
            {formatMessage({ id: 'alerting.recipientsEmail' })}
          </Typography>
          <Typography variant='subtitle3' className={styles.sectionSubHeader}>
            {formatMessage({ id: 'alerting.recipientsEmailSub' })}
          </Typography>
          <div className={styles.sectionContent}>
            <div className={styles.usersWrapper}>
              <div className={styles.users}>
                {notificationSubscribers?.map(subscriber => (
                  <UserBage
                    key={uniqueId('user-bage-')}
                    user={subscriber}
                    onCloseClick={() => handlerOnSubscriberDeleteClick(subscriber)}
                  />
                ))}

                <AddUsersDropdown
                  ref={dropdownRef}
                  intl={intl}
                  className={styles.usersDropdown}
                  usersList={usersList}
                  handlerClickOption={handlerClickSubscriberOption}
                  title={notificationSubscribers.length === 0 ? formatMessage({ id: 'alerting.addRecipients' }) : undefined}
                  alignLeft={!isElementAtTheRight}
                  selectedOption={notificationSubscribers.map(item => item.id)}
                  positionTop
                />
              </div>

              {formErrors?.recipientsField && (
                <div className={styles.error}>
                  {formErrors.recipientsField}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.actionButtons}>
        <BigButton
          className={cn(styles.cancel)}
          title={formatMessage({ id: 'dialog.cancel' })}
          onClick={handlerIncidentCollapse}
          theme='light'
        />
        <BigButton
          className={cn(styles.save)}
          title={formatMessage({ id: 'button.save' })}
          onClick={handlerSave}
          theme='dark'
        />
      </div>


      {isOpenSelectIncidentsDialog && (
        <SelectIncidentsDialog
          onClose={() => setIsOpenSelectIncidentsDialog(false)}
          getNewData={() => {}}
          incidentsList={incidentsList}
          onSelect={handlerOnSelectIncidents}
        />
      )}

      {isDeleteDialogOpened && (
        <DefaultDeleteDialog
          isFetching={false}
          title={formatMessage({ id: 'alerting.deleteHeader' })}
          text={formatMessage({ id: 'alerting.deleteText' })}
          handlerCloseDialog={() => setIsDeleteDialogOpened(false)}
          handlerDontDelete={() => setIsDeleteDialogOpened(false)}
          handlerDelete={() => requestDeleteAlertRule({ alertId: Number(activeAlertId), actionAfterSuccess: onCollapse })}
        />
      )}
    </div>
  );
};

AlertPanel.propTypes = {
  intl: intlShape.isRequired,
  isFetching: PropTypes.bool,
  panelCollapsed: PropTypes.bool,
  incidentTypeCategories: PropTypes.array,
  incidentTypes: PropTypes.array,
  onCollapse: PropTypes.func,
  userPrivileges: PropTypes.array,
  alertRules: PropTypes.array,
  activeAlertId: PropTypes.number,
  requestCreateAlertRule: PropTypes.func.isRequired,
  requestUpdateAlertRule: PropTypes.func.isRequired,
  requestDeleteAlertRule: PropTypes.func.isRequired,
};

AlertPanel.defaultProps = {
  isFetching: false,
  onCollapse: () => {},
  panelCollapsed: false,
  incidentTypeCategories: [],
  incidentTypes: [],
  userPrivileges: [],
  alertRules: [],
  activeAlertId: 0,
};

export default AlertPanel;
