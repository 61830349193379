import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getCurrentSpecies,
  getAllVarieties,
  getAllFruitClasses,
  getCurrentCompartments,
  getAllProductGroups,
  getIsOnlyLettuce,
  getIsOnlyVegetables,
} from '../../../../../store/company/selectors';

import ProductMultilevelMenu from '../components/ProductMultilevelMenu';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,

  productGroups: getAllProductGroups(state),
  species: getCurrentSpecies(state),

  varieties: getAllVarieties(state, props),
  fruitClasses: getAllFruitClasses(state, props),
  compartments: getCurrentCompartments(state),

  isOnlyLettuce: getIsOnlyLettuce(state, props),
  isOnlyVegetables: getIsOnlyVegetables(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductMultilevelMenu));
