import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import numbersFormatAndRounding from 'helpers/numbersFormatAndRounding';

import Typography from 'components/Typography';
import styles from './ChartHeaderWithTotal.module.css';


const ChartTotalLine = injectIntl(({
  title, value, round, intl
}) => {
  const { formatMessage } = intl;
  return (
    <div className={styles.total}>
      <div className={styles.totalHeader}>{title}</div>
      <div className={styles.totalValue}>
        <span className={styles.value}>{numbersFormatAndRounding(value.total, round)}</span>&nbsp;<span className={styles.unit}>{formatMessage({ id: `cunits.mini.${value.units}` })}</span>
      </div>
    </div>
  );
});

const ChartHeaderWithTotal = ({ plan, harvest, unrealized }) => (
  <div className={styles.header}>
    <Typography variant='h4'>
      <FormattedMessage id='unrealizedPotential.harvestTitle' />
    </Typography>
    <div className={styles.totals}>
      <ChartTotalLine
        title={<FormattedMessage id='unrealizedPotential.plan' />}
        round={1}
        value={plan}
      />
      <ChartTotalLine
        title={<FormattedMessage id='unrealizedPotential.harvested' />}
        round={1}
        value={harvest?.cycle}
      />
      <ChartTotalLine
        title={<FormattedMessage id='unrealizedPotential.potentialHarvest' />}
        round={1}
        value={harvest?.model}
      />
      <ChartTotalLine
        title={<FormattedMessage id='unrealizedPotential.unrealizedPotential' />}
        round={1}
        value={{
          total: unrealized?.amount,
          units: unrealized?.units,
        }}
      />
    </div>
  </div>
);

ChartHeaderWithTotal.propTypes = {
  harvest: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired,
  unrealized: PropTypes.object.isRequired,
};

export default ChartHeaderWithTotal;
