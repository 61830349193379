import { isNull, isNil } from 'lodash';

import { GREEN_ZONE, YELLOW_ZONE } from 'helpers/constants';

/**
 * Возвращает показывать ли бейдж с процентом Data Quality
 * (не показываем если всё ок)
 *
 * @param {number} percent - Значение Data Quality
 * @return {boolean} Показываем бейдж или нет
 */
export const getIsAlertVisible = percent => (!isNull(percent) && percent < GREEN_ZONE);

/**
 * Возвращает цвет бейджа Data Quality, в зависимости от процента
 *
 * @param {number} percent - Значение Data Quality
 * @return {string} Название цвета
 */
export const getDataQualityColor = (percent) => {
  if (isNil(percent)) {
    return null;
  }

  if (percent >= GREEN_ZONE) {
    return 'green';
  } if (percent >= YELLOW_ZONE) {
    return 'yellow';
  }

  return 'red';
};
