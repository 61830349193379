import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import classnames from 'classnames';
import { isNumber } from 'lodash';

import Checkbox from 'components/Checkbox';
import magicRound from 'helpers/magicRound';

import styles from './HarvestForecastByDaysTableRow.module.css';

const renderGroupName = (row) => {
  if (row.href) {
    return (
      <NavLink
        to={row.href}
        className={styles.plantingCycleLink}
      >
        {row.groupLabel}
      </NavLink>
    );
  }

  return (
    <div>
      {row.groupLabel}
    </div>
  );
};

const HarvestForecastByDaysTableRow = ({
  intl,
  currentGraphKey,
  row,
  onGraphChange
}) => {
  const { formatMessage } = intl;

  const handlerGraphKeyChange = () => {
    onGraphChange(row);
  };

  const unitsText = useMemo(() =>
    (row.units === 'percent' ? formatMessage({ id: `cunits.mini.${row.units}` }) : ''),
    [formatMessage, row]);

  return (
    <tr
      className={classnames(styles.tr, {
        [styles.totalRow]: row.isTotal,
        [styles.withCategoryName]: row.groupIndex === 0,
        [styles.rowTotal]: row.isTotal,
        [styles.lastRow]: row.groupIndex === row.groupSpan - 1,
      })}
    >
      {row.groupIndex === 0 && (
        <td
          role='gridcell'
          className={classnames(styles.chartIconWrapper, styles.categoryNameTd)}
          // rowSpan={row.groupSpan}
        >
          <div className={styles.chartIconContainer}>
            <Checkbox
              onChange={handlerGraphKeyChange}
              checked={currentGraphKey && currentGraphKey.includes(row.groupKey)}
              value={`by-days-${row.groupKey}`}
              alignCenter
              noWrap
              pointClass={styles.point}
            />
          </div>
        </td>
      )}
      {row.groupIndex === 0 && (
        <td
          className={classnames(styles.categoryName, styles.categoryNameTd)}
          // rowSpan={row.groupSpan}
        >
          {row.isTotal ? formatMessage({ id: 'planFact.total' }) : renderGroupName(row) }
        </td>
      )}
      {row.groupIndex !== 0 && (
        <>
          <td
            className={classnames(styles.emptyCell, { [styles.emptyCellTotal]: row.isTotal })}
          />
          <td
            className={classnames(styles.emptyCell, { [styles.emptyCellTotal]: row.isTotal })}
          />
        </>
      )}
      <td
        className={classnames(styles.fieldName)}
      >
        {row.label}
      </td>
      {row?.columns?.length > 0 && row?.columns.map((value, index) => {
        const percentValue = row?.percentColumns?.[index];
        const sign = isNumber(percentValue) && isNumber(value) && value > 0 ? '+' : '';
        const val = isNumber(value) ? `${sign}${magicRound(value)}${unitsText}` : '–';
        return (
          <td
            className={classnames(styles.dataCell)}
          >
            {val} {isNumber(percentValue) && (<span className={styles.percentValue}>({percentValue}%)</span>)}
          </td>
        );
      })}
    </tr>
  );
};

HarvestForecastByDaysTableRow.propTypes = {
  intl: intlShape.isRequired,
  currentGraphKey: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  onGraphChange: PropTypes.func.isRequired
};

HarvestForecastByDaysTableRow.defaultProps = {
};

export default injectIntl(HarvestForecastByDaysTableRow);
