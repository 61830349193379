import compose from 'recompose/compose';
import defaultProps from 'recompose/defaultProps';
import pure from 'recompose/pure';
import { withTheme, withDimensions } from '@nivo/core';
import { PieDefaultProps } from './props';

export default Component =>
  compose(
    defaultProps(PieDefaultProps),
    withTheme(),
    withDimensions(),
    pure
  )(Component);
