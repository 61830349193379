import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { reducer as formReducer } from 'redux-form';

import locationReducer from './location';
import companyReducer from './company/reducers';
import loginReducer from './login/reducers';
import dialogsReducer from './dialogs';
import analyticsReducer from './analytics/reducers';
import navigationMenuReducer from './navigationMenu/reducers';
import notificationCenterReducer from './notificationCenter/reducers';
import sidePanelReducer from './sidePanel/reducers';
import photosReducer from './photos/reducers';
import tablesReducer from './tables/reducers';
import cropsReducer from './crops/reducers';
import newCropsReducer from './newCrops/reducers';
import harvestReducer from './harvest/reducers';
import dashboardsReducer from './dashboards/reducers';
import cropsPerformanceReducer from './cropsPerformance/reducers';
import plansReducer from './plans/reducers';
import plansEditorReducer from './plansEditor/reducers';
import climateInsightsReducer from './climateInsights/reducers';
import harvestKpiDashboardReducer from './harvestKpiDashboard/reducers';
import userReducer from './user/reducers';
import dashboardsMenuReducer from './dashboardsMenu/reducers';
import dataQualityReducer from './dataQuality/reducers';
import incidentsReducer from './incidents/reducers';
import planFactReportReducer from './planFactReport/reducers';
import benchmarkReducer from './benchmark/reducers';
import energyReducer from './energy/reducers';
import laborReducer from './labor/reducers';
import digitalTwinReducer from './digitalTwin/reducers';
import measurementsReducer from './measurements/reducers';
import unrealizedPotentialReducer from './unrealizedPotential/reducers';
import harvestForecastReducer from './harvestForecast/reducers';
import graph2Reducer from './graphs/reducers';
import cropReportsReducer from './cropReports/reducers';
import climateInsightsTimelineReducer from './climateInsightsTimeline/reducers';

export const makeRootReducer = asyncReducers => combineReducers({
  location: locationReducer,
  intl: intlReducer,
  company: companyReducer,
  login: loginReducer,
  dialogs: dialogsReducer,
  analytics: analyticsReducer,
  navigationMenu: navigationMenuReducer,
  notificationCenter: notificationCenterReducer,
  sidePanel: sidePanelReducer,
  photos: photosReducer,
  tables: tablesReducer,
  crops: cropsReducer,
  plans: plansReducer,
  plansEditor: plansEditorReducer,
  climateInsights: climateInsightsReducer,
  newCrops: newCropsReducer,
  harvest: harvestReducer,
  dashboards: dashboardsReducer,
  cropsPerformance: cropsPerformanceReducer,
  form: formReducer,
  harvestKpiDashboard: harvestKpiDashboardReducer,
  user: userReducer,
  dashboardsMenu: dashboardsMenuReducer,
  dataQuality: dataQualityReducer,
  incidents: incidentsReducer,
  planFact: planFactReportReducer,
  benchmark: benchmarkReducer,
  energy: energyReducer,
  labor: laborReducer,
  digitalTwin: digitalTwinReducer,
  measurements: measurementsReducer,
  unrealizedPotential: unrealizedPotentialReducer,
  harvestForecast: harvestForecastReducer,
  graphs: graph2Reducer,
  cropReports: cropReportsReducer,
  climateInsightsTimeline: climateInsightsTimelineReducer,
  ...asyncReducers
});

export const injectReducer = (store, { key, reducer }) => {
  const innerStore = store;

  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  innerStore.asyncReducers[key] = reducer;
  innerStore.replaceReducer(makeRootReducer(innerStore.asyncReducers));
};
