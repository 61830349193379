import { find } from 'lodash';
import { checkPermissions, PERMISSIONS } from './checkPermissions';

const getIsExpert = ({
  permissions,
  organization,
  abnormalityIncidents,
  activeIncidentId,
}) => {
  const activeIncident = find(abnormalityIncidents, { id: activeIncidentId });

  if (activeIncident) {
    if (!permissions || !organization) {
      return false;
    }

    const { nodeId } = activeIncident;

    return checkPermissions(permissions, PERMISSIONS.ABNORMALITY_INCIDENT_REGISTER, nodeId);
  }

  return false;
};

export default getIsExpert;
