import PropTypes from 'prop-types';
import React, {
  useState, useCallback, useMemo
} from 'react';
import classnames from 'classnames';

import { isNumber } from 'lodash';

import { injectIntl } from 'react-intl';
import { ReactComponent as DeleteIcon } from './assets/delete.svg';
import { ReactComponent as InvisibleIcon } from './assets/invisible.svg';
import { ReactComponent as LeftIcon } from './assets/left.svg';
import { ReactComponent as RightIcon } from './assets/right.svg';

import MaskedInput, { DELIMITER } from './MaskedInput';

import styles from './index.module.css';

const MAX_VALUE = 99999;
const MIN_VALUE = -99999;

const ScaleSettings = ({
  name,
  rightAxis,
  invisible,
  range,
  onChange,
  onDelete,
  className,
  intl
}) => {
  const { formatMessage } = intl;
  const [isRightAxis, setIsRightAxis] = useState(rightAxis);
  const [isInvisible, setIsInvisible] = useState(invisible);
  const [rangeState, setRangeState] = useState(range);

  const validateErrorText = useMemo(() => {
    if (rangeState?.start > MAX_VALUE || rangeState?.end > MAX_VALUE) {
      return formatMessage({ id: 'formErrors.cannotBeExceed' }, { number: MAX_VALUE });
    }
    if (rangeState?.start < MIN_VALUE || rangeState?.end < MIN_VALUE) {
      return formatMessage({ id: 'formErrors.cannotBeLess' }, { number: MIN_VALUE });
    }
    return null;
  }, [rangeState, formatMessage]);

  const handleClickSelectLeft = useCallback(() => {
    setIsRightAxis((presState) => {
      if (onChange) {
        onChange({
          range: rangeState,
          name,
          rightAxis: !presState,
          invisible: isInvisible,
        });
      }
      return !presState;
    });
  }, [setIsRightAxis, rangeState, isInvisible, name, onChange]);

  const handleClickSelectRight = useCallback(() => {
    setIsRightAxis((presState) => {
      if (onChange) {
        onChange({
          range: rangeState,
          name,
          rightAxis: !presState,
          invisible: isInvisible,
        });
      }
      return !presState;
    });
  }, [setIsRightAxis, rangeState, isInvisible, name, onChange]);

  const handleClickSelectInvisible = useCallback(() => {
    setIsInvisible((presState) => {
      if (onChange) {
        onChange({
          range: rangeState,
          name,
          rightAxis: isRightAxis,
          invisible: !presState,
        });
      }
      return !presState;
    });
  }, [setIsInvisible, rangeState, isRightAxis, name, onChange]);

  // eslint-disable-next-line no-shadow
  const handleChangeRange = useCallback((rangeState) => {
    if (setRangeState) {
      setRangeState(() => {
        if (onChange) {
          onChange({
            range: rangeState?.range,
            name,
            rightAxis: isRightAxis,
            invisible: isInvisible,
          });
        }
        return rangeState?.range;
      });
    }
  }, [
    name,
    isRightAxis,
    isInvisible,
    setRangeState,
    onChange
  ]);

  const handleClickDelete = useCallback(() => {
    if (onDelete) {
      onDelete({
        name,
      });
    }
  }, [onDelete, name]);

  const localizedTitle = useMemo(() =>
    (name ? `${formatMessage({ id: 'graphs.scaleTitle' })} ${name}` : formatMessage({ id: 'graphs.baseScaleTitle' })),
  [name, formatMessage]);

  const localizedDeleteTitle = useMemo(() =>
    (name ? `${formatMessage({ id: 'graphs.deleteScale' })} ${name}` : formatMessage({ id: 'graphs.deleteBaseScale' })),
  [name, formatMessage]);

  const defaultValue = useMemo(() => {
    const start = isNumber(range?.start) ? range?.start : '';
    const end = isNumber(range?.end) ? range?.end : '';
    return `${start}${DELIMITER}${end}`;
  }, [range]);

  return (
    <div className={classnames(styles.scaleSettingsContainer, className)}>
      <div className={styles.scaleSettingsHeader}>{localizedTitle}</div>
      <div className={styles.scaleSettingsAxis}>
        <div className={styles.scaleSettingsLeftTitle}>{formatMessage({ id: 'graphs.axisTitle' })}</div>
        <div className={styles.scaleSettingsAxisButtons}>
          <button
            className={classnames(styles.buttonIcon, {
              [styles.buttonSelected]: !isRightAxis
            })}
            type='button'
            onClick={handleClickSelectLeft}
          ><LeftIcon />
          </button>
          <button
            className={classnames(styles.buttonIcon, {
              [styles.buttonSelected]: isRightAxis
            })}
            type='button'
            onClick={handleClickSelectRight}
          ><RightIcon />
          </button>
          <button
            className={classnames(styles.buttonIcon, styles.fixInvisibleIconSize, {
              [styles.buttonSelected]: isInvisible
            })}
            type='button'
            onClick={handleClickSelectInvisible}
          ><InvisibleIcon />
          </button>
        </div>
      </div>
      <div className={styles.scaleSettingsValuesContainer}>
        <div className={styles.scaleSettingsValues}>
          <div className={styles.scaleSettingsLeftTitle}>{formatMessage({ id: 'graphs.valuesTitle' })}</div>
          <MaskedInput onChange={handleChangeRange} defaultValue={defaultValue} />
        </div>
        {validateErrorText && (
          <div className={styles.error}>{validateErrorText}</div>
        )}
      </div>
      <div className={classnames(styles.scaleSettingsDelete, {
        [styles.deleteDisable]: !name
      })}
      >
        <button
          className={styles.scaleSettingsDeleteButton}
          type='button'
          onClick={handleClickDelete}
        ><DeleteIcon />&nbsp; {localizedDeleteTitle}
        </button>
      </div>
    </div>
  );
};

ScaleSettings.propTypes = {
  intl: PropTypes.object,
  className: PropTypes.string,
  name: PropTypes.string,
  rightAxis: PropTypes.bool,
  invisible: PropTypes.bool,
  range: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
  }),
  onChange: PropTypes.func,
  onDelete: PropTypes.func
};

ScaleSettings.defaultProps = {
  intl: null,
  name: null,
  rightAxis: false,
  invisible: false,
  range: {
    start: null,
    end: null
  },
  onChange: null,
  onDelete: null,
  className: null
};

export default injectIntl(ScaleSettings);
