import React from 'react';

import { ReactComponent as ArrowUp } from './assets/arrow_up.svg';
import { ReactComponent as ArrowDown } from './assets/arrow_down.svg';

const getArrow = (dynamic) => {
  let arrow = '';

  if (dynamic && dynamic > 0) {
    arrow = <ArrowUp />;
  } else if (dynamic && dynamic < 0) {
    arrow = <ArrowDown />;
  }

  return arrow;
};

export default getArrow;
