// https://github.com/remarkablemark/hex2rgba/blob/master/index.js
/**
 * Constants.
 */
const BASE = 16;
const HEX_REGEX = /^#?[a-fA-F0-9]+$/;
const HEX_SHORTHAND_LENGTH = 3;
const HEXA_LENGTH = 8;
const HEX_LENGTH = 6;


export function rgbToGrayscale(red, green, blue) {
  const r = red * 0.3;
  const g = green * 0.59;
  const b = blue * 0.11;
  const gray = r + g + b;

  // const gray = red / 3;

  return [gray, gray, gray];
}

/**
 * Converts hexadecimal to RGBA.
 *
 * @param  {String}        hexInput     - The hexadecimal.
 * @param  {Number|String} [alphaInput] - The alpha transparency.
 * @return {Array}                - The RGBA.
 */
export function hex2rgbComp(hexInput, alphaInput) {
  let alpha = alphaInput;
  let hex = hexInput;
  if (!HEX_REGEX.test(hex)) {
    return null;
    // throw Error('hex2rgba: first argument has invalid hexadecimal characters');
  }

  // trim unnecessary characters
  if (hex[0] === '#') {
    hex = hex.slice(1);
  }

  // expand shorthand
  if (hex.length === HEX_SHORTHAND_LENGTH) {
    hex = hex.split('');
    hex.splice(2, 0, hex[2]);
    hex.splice(1, 0, hex[1]);
    hex.splice(0, 0, hex[0]);
    hex = hex.join('');
  }

  if (hex.length !== HEXA_LENGTH && hex.length !== HEX_LENGTH) {
    return null;
    // throw Error(`hex2rgba: first argument has invalid hexadecimal length === ${hex.length}, ${hex}`);
  }

  // convert hex to rgb
  const values = [
    parseInt(hex.slice(0, 2), BASE),
    parseInt(hex.slice(2, 4), BASE),
    parseInt(hex.slice(4, 6), BASE),
  ];

  if (hex.length === HEXA_LENGTH) {
    values.push(parseInt(hex.slice(6, 8), BASE) / 255);
  } else {
    alpha = typeof alpha === 'number' ? alpha : parseFloat(alpha);
    if (alpha >= 0 && alpha <= 1) {
      values[3] = alpha;
    } else {
      values[3] = 1;
    }
  }

  return values;
}


/**
 * Converts hexadecimal to RGBA.
 *
 * @param  {String}        hexInput     - The hexadecimal.
 * @param  {Number|String} [alphaInput] - The alpha transparency.
 * @return {String}                - The RGBA.
 */
function hex2rgba(hexInput, alphaInput) {
  const values = hex2rgbComp(hexInput, alphaInput);
  return `rgba(${values.join(',')})`;
}

function trim(str) {
  return str.replace(/^\s+|\s+$/gm, '');
}

// https://gist.github.com/whitlockjc/9363016
export function rgbaToHex(rgba) {
  try {
    const inParts = rgba.substring(rgba.indexOf('(')).split(',');
    const r = parseInt(trim(inParts[0].substring(1)), 10);
    const g = parseInt(trim(inParts[1]), 10);
    const b = parseInt(trim(inParts[2]), 10);
    const a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
    const outParts = [
      r.toString(16),
      g.toString(16),
      b.toString(16),
      Math.round(a * 255).toString(16).substring(0, 2)
    ];

    // Pad single-digit output values
    outParts.forEach((part, i) => {
      if (part.length === 1) {
        outParts[i] = `0${part}`;
      }
    });

    return (`#${outParts.join('')}`);
  } catch (e) {
    return `${rgba}`;
  }
}

export default hex2rgba;
