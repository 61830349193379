import React from 'react';

// @ts-ignore
import { injectIntl, InjectedIntlProps } from 'react-intl';

import { get, isNumber } from 'lodash';


import { Metric, MetricDef } from 'store/graphs/types';


import CellDefault from '../CellDefault';

// eslint-disable-next-line import/no-unresolved
import styles from '../../index.module.css';

type CellNumericProp = {
  metric: Metric;
  presetMetric: MetricDef;
  headerKey: string;
  groupId: string;
  isMetricDatasFetching: boolean;
  value: number;
}

const numbersRounding = (value: number, didgits: number) => {
  if (!isNumber(value)) {
    return value || '-';
  }
  return value.toFixed(didgits);
};

const CellNumeric = ({
  intl,
  metric,
  presetMetric,
  groupId,
  headerKey,
  isMetricDatasFetching,
  value
}: CellNumericProp & InjectedIntlProps) => {
  const { formatMessage } = intl;
  const { displayScale } = metric.attributes;
  return (
    <CellDefault
      presetMetric={presetMetric}
      headerKey={headerKey}
      groupId={groupId}
      isMetricDatasFetching={isMetricDatasFetching}
      className={styles.number}
    >
      {`${numbersRounding(value, displayScale)}${numbersRounding(value, displayScale) !== '-' &&
      get(metric, 'cunit') === 'percent' ?
        formatMessage({ id: `cunits.mini.${get(metric, 'cunit')}` }) : ''}`}
    </CellDefault>
  );
};

export default injectIntl(CellNumeric);
