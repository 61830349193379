import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { trackPageViewResources } from 'store/company/actions';

import {
  getCurrentCompartments,
  getResourceType,
  getCurrentTimezone,
  getPeriodTypeFromRoute,
  getAnyDateOfPeriodStartFromRoute,
  getAnyDateOfPeriodEndFromRoute,
  getGraphKeyFromRoute,
  getOrganizationSlug,
  getAllPlantingCycles,
  getAllVarieties,
  getAllFruitClasses,
  getIsAddEnergyAvailable,
  getDefaultOpenFromRoute,
} from '../../../store/company/selectors';

import {
  getEnergyReport,
  getIsEnergyReportFetching,
  getEnergyReportSorting,
  getMetersCombined,
} from '../../../store/energy/selectors';

import {
  requestEnergyReport,
  requestEnergyMeters,
  updateEnergyReportSorting,
  clearEnergyReport,
} from '../../../store/energy/actions';

import Energy from '../components/Energy';

const mapDispatchToProps = {
  requestEnergyReport,
  requestEnergyMeters,
  updateEnergyReportSorting,
  clearEnergyReport,
  trackPageViewResources,
};

const mapStateToProps = (state, props) => ({
  ...props,

  organizationSlug: getOrganizationSlug(state, props),
  compartments: getCurrentCompartments(state, props),
  allPlantingCycles: getAllPlantingCycles(state, props),
  varieties: getAllVarieties(state, props),
  fruitClasses: getAllFruitClasses(state, props),
  resourceType: getResourceType(state, props),
  timezone: getCurrentTimezone(state, props),
  energyReport: getEnergyReport(state, props),
  isEnergyReportFetching: getIsEnergyReportFetching(state, props),

  periodType: getPeriodTypeFromRoute(state, props),
  anyDateOfPeriodStart: getAnyDateOfPeriodStartFromRoute(state, props),
  anyDateOfPeriodEnd: getAnyDateOfPeriodEndFromRoute(state, props),
  graphKey: getGraphKeyFromRoute(state, props),
  energyReportSorting: getEnergyReportSorting(state, props),
  meters: getMetersCombined(state),
  isAddEnergyAvailable: getIsAddEnergyAvailable(state),
  defaultOpen: getDefaultOpenFromRoute(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Energy)));
