/**
 * TODO: компонент был скопипащен из барчарта на странице климата, в нём очень
 * много неоптимальных вещей и костылей, перед переиспользованием в других местах требует рефакторинга
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as d3 from 'd3';
import classnames from 'classnames';
import moment from 'moment-timezone';
import {
  get, isEqual, find, flow, throttle, trim, isFunction
} from 'lodash';

import { getTicksRange, getTicksMinMax } from 'helpers/getTicks';
import { formatTicksByMask } from 'helpers/formatTicksByMask';

import DefaultCircleLoader from 'components/DefaultCircleLoader';
import AddToComparisonIcon from 'components/Icons/AddToComparisonIcon';

import Tooltip from '../Tooltip';

import styles from './SimpleBarChart.module.css';

const BAR_COUNT_SECOND_LIMIT_LABELS = 50;
const BAR_COUNT_FIRST_LIMIT = 50;
const BAR_COUNT_SECOND_LIMIT = 80;

class SimpleBarChart extends Component {
  static propTypes = {
    size: PropTypes.object.isRequired,

    items: PropTypes.array,
    toCompareItems: PropTypes.array,
    isFetching: PropTypes.bool,
    isForceRenderDate: PropTypes.bool,
    fixedHeight: PropTypes.number,
    hoveredItem: PropTypes.number,
    customRenderTooltipDate: PropTypes.func,
    margins: PropTypes.object,
    defaultBarWidth: PropTypes.number.isRequired,
    barWrapperPadding: PropTypes.number.isRequired,
    additionalMetricsPadding: PropTypes.number.isRequired,
    maxLeftValue: PropTypes.number,
    maxRightValue: PropTypes.number,
    rotateLabels: PropTypes.bool,
    xTypeTime: PropTypes.bool,
    withCompareTips: PropTypes.bool,
    timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    periodType: PropTypes.string,

    onMouseOverCallback: PropTypes.func,
    onMouseOutCallback: PropTypes.func,
    onClickCallback: PropTypes.func,
    renderCustomTooltip: PropTypes.func,
    showEmptyBars: PropTypes.bool,
    isEmpty: PropTypes.bool,
    emptyTitle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
  };

  static defaultProps = {
    items: null,
    hoveredItem: null,
    toCompareItems: [],
    isFetching: false,
    isForceRenderDate: false,
    maxLeftValue: null,
    maxRightValue: null,
    rotateLabels: false,
    xTypeTime: false,
    withCompareTips: false,
    margins: {
      top: 10,
      bottom: 38,
      left: 65,
      right: 65,
    },
    fixedHeight: 269,
    timeFormat: "MMM 'YY",
    periodType: null,

    onMouseOverCallback: () => {},
    onMouseOutCallback: () => {},
    onClickCallback: () => {},
    renderCustomTooltip: null,
    showEmptyBars: true,
    isEmpty: false,
    customRenderTooltipDate: null,
    emptyTitle: undefined,
  };

  chart = React.createRef();

  handlerResize = throttle((e, callback) => {
    if (this.chart && this.chart.current) {
      const chartStyle = window.getComputedStyle(this.chart.current, null);
      const chartWidth = parseInt(chartStyle.getPropertyValue('width'), 10);

      this.setState({
        width: chartWidth,
      }, () => {
        if (callback) {
          callback();
        } else {
          this.rebuild(this.props, this.state);
        }
      });
    }
  }, 100);

  constructor(...props) {
    super(...props);

    this.x = this.props.xTypeTime ? d3.scaleUtc() : d3.scaleBand();
    this.yLeft = d3.scaleLinear();
    this.yRight = d3.scaleLinear(); // нужна только для правильного масштабирования additional metrics
  }

  // TODO: перепроверить всё ли нужно в стейте
  state = {
    hoveredBar: null,
    hoveredTooltip: null,
    ticksCount: {
      x: 12,
      y: 4,
    },
    width: this.props.size.width || window.innerWidth - (24 * 2) - (24 * 2),
  };

  componentDidMount() {
    // TODO: выпилить handlerResize функцию, сейчас есть sizeMe
    window.addEventListener('resize', this.handlerResize);

    setTimeout(() => {
      this.handlerResize();
    }, 0);
  }

  componentDidUpdate(prevProps) {
    // TODO: выпилить componentDidUpdate
    const {
      items: oldItems,
      size: oldSize,
    } = prevProps;

    const {
      items: nextItems,
      size: nextSize,
    } = this.props;

    if (!isEqual(nextItems, oldItems) || oldSize.width !== nextSize.width) {
      this.handlerResize(null, () => {
        this.rebuild(this.props, this.state);
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResize);
  }

  getBarsCount = ({ items }) => {
    if (items) {
      return items.length;
    }

    return 0;
  };

  getBarsWidth = () => {
    const { items, defaultBarWidth } = this.props;

    const allBars = [...items].reduce((acc, item) => {
      if (item.bars) {
        return [...acc, ...item.bars];
      }

      return acc;
    }, []);

    const barsCount = allBars ? allBars.length : 0;

    const sumOfBars = barsCount;

    if (sumOfBars > BAR_COUNT_SECOND_LIMIT) {
      return 4;
    }

    if (sumOfBars > BAR_COUNT_FIRST_LIMIT) {
      return 8;
    }

    return defaultBarWidth;
  };

  getScaleDomain = ({
    items, scale, valuePath, maxValue, isEmpty
  }) => {
    if (!items || !items.length || isEmpty) {
      scale.domain([0, 100]);
    } else {
      const values = items.map(item => get(item, valuePath));
      const min = 0;
      const max = maxValue || Math.ceil(d3.max(values));

      if ((min === 0 && max === 0) || (Number.isNaN(min) && Number.isNaN(max))) {
        scale.domain(d3.extent([0, 100]));
      } else if (min === max) {
        scale.domain(d3.extent([min - min, max + max]));
      } else {
        const deltaData = max - min;
        const delta = deltaData || max;

        const maxDelta = Math.ceil(delta * 0.04);

        scale.domain(d3.extent(
          [{ value: 0 }, { value: max + maxDelta }],
          d => d.value,
        ));
      }
    }
  };

  getTicksCount = (xTypeTime, itemsLength) => {
    if (!xTypeTime) {
      return itemsLength;
    }

    if (window && window.innerWidth < 720) {
      return 6;
    }

    return 12;
  };

  truncateText = (texts) => {
    const { margins } = this.props;

    const padding = margins.bottom + 16;
    /* eslint-disable func-names */
    // не стрелочная для сохранения контекста this для d3.select
    const trimText = (currentNode) => {
      if (currentNode.node().getComputedTextLength() > padding) {
        const oldText = currentNode.text();
        const newText = oldText.substring(0, oldText.length - 3);

        currentNode.text(newText);

        if (currentNode.node().getComputedTextLength() > padding) {
          return trimText(currentNode);
        }

        return currentNode.text(`${newText}...`);
      }

      return currentNode;
    };

    texts.each(function () {
      const text = d3.select(this);

      return trimText(text);
    });
    /* eslint-enable func-names */
  }

  wrapText = (texts, width) => {
    /* eslint-disable func-names */
    // не стрелочная для сохранения контекста this для d3.select
    texts.each(function () {
      const text = d3.select(this);
      const words = trim(text.text()).split(' ').reverse();
      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.1; // ems
      const y = text.attr('y');
      const dy = parseFloat(text.attr('dy'));
      let tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', `${dy}em`);

      /* eslint-disable no-cond-assign,no-plusplus */
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(' '));

        if (tspan.node().getComputedTextLength() > width && lineNumber < 1 && line.length > 1) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];

          tspan = text
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', `${(++lineNumber * lineHeight) + dy}em`)
            .text(word);
        }

        if (tspan.node().getComputedTextLength() > width) {
          tspan
            .text(`${word}`);
        }
      }
      /* eslint-enable no-cond-assign,no-plusplus */
    });
    /* eslint-enable func-names */
  }

  rebuild = () => {
    // TODO: rebuild делать при рендеринге
    const {
      items, size: { width }, margins, fixedHeight, xTypeTime, timeFormat,
    } = this.props;
    const { ticksCount } = this.state;

    this.calculateDomains(this.props, this.state);

    this.calculateHorizontalAxis({
      width: width - margins.left - margins.right,
      height: fixedHeight - margins.top - margins.bottom,
    });

    this.renderAxises({
      timeFormat,
      xTypeTime,
      items,
      width: width - margins.left - margins.right,
    });

    this.setState({ ticksCount }); // Грязный хак, чтобы при rebuild всегда вызывался render (т.к. сетим объект), надо убрать
  };

  calculateHorizontalAxis = ({ width }) => {
    const { items, xTypeTime } = this.props;

    if (!items || !items.length) {
      this.x.domain([0, 100]).range([0, width]);
    } else if (xTypeTime) {
      const values = items.map(item => get(item, 'date'));
      const startDate = d3.min(values);
      const endDate = d3.max(values);

      const fromDate = startDate.clone();
      const toDate = endDate.clone();

      this.x.domain(d3.extent([+fromDate, +toDate]))
        .range([0, width]);
    } else {
      this.x.domain(items.map(d => d.id))
        .range([0, width]);
    }
  };

  calculateDomains(props) {
    const {
      maxLeftValue,
      maxRightValue,
      fixedHeight,
    } = this.props;

    const {
      items,
      margins,
      isEmpty,
    } = props;


    this.yRight.range([fixedHeight - margins.top - margins.bottom, 0]);
    this.yLeft.range([fixedHeight - margins.top - margins.bottom, 0]);

    if (items && items.length > 0) {
      const leftBars = items.reduce((acc, item) =>
        [...acc, ...item.bars.filter(bar => !bar.isRight)], []);

      if (leftBars?.length > 0) {
        this.getScaleDomain({
          items: leftBars,
          scale: this.yLeft,
          valuePath: 'value',
          maxValue: maxLeftValue,
          isEmpty,
        });
      }

      const rightBars = items.reduce((acc, item) =>
        [...acc, ...item.bars.filter(bar => bar.isRight)], []);

      if (rightBars?.length > 0) {
        this.getScaleDomain({
          items: leftBars,
          scale: this.yRight,
          valuePath: 'value',
          maxValue: maxRightValue,
          isEmpty,
        });
      }
    }
  }

  calculateTicksForLeftAxis(yDomain) {
    const delimeter = this.state.ticksCount.y - 1;
    const min = yDomain[0];
    const max = yDomain[1];

    const ticksRange = getTicksRange(min, max, delimeter);
    const yLeftDomainRange = getTicksMinMax(ticksRange);

    this.yLeft.domain(yLeftDomainRange);

    return ticksRange;
  }

  calculateTicksForRightAxis(yDomain) {
    const delimeter = this.state.ticksCount.y - 1;
    const min = yDomain[0];
    const max = yDomain[1];

    const ticksRange = getTicksRange(min, max, delimeter);
    const yRightDomainRange = getTicksMinMax(ticksRange);

    this.yRight.domain(yRightDomainRange);

    return ticksRange;
  }

  renderFittedTicks = () => {
    const {
      items, rotateLabels, xTypeTime,
    } = this.props;

    // Поворачиваем лейблы если слишком много барчартов
    let fontSize = '11px';

    // Если слишком много элементов, то переворачиваем текст тиков
    if (rotateLabels) {
      let ticksDy = items.length > BAR_COUNT_SECOND_LIMIT_LABELS ? '-0.9em' : '-0.15em';
      const rotateDeg = items.length > BAR_COUNT_SECOND_LIMIT_LABELS ? 90 : 65;

      if (items.length > BAR_COUNT_SECOND_LIMIT) {
        ticksDy = '-2.35em';
        fontSize = '5px';
      }

      d3.select(this.axisX)
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', ticksDy)
        .attr('transform', `rotate(-${rotateDeg})`)
        .style('font-size', fontSize)
        .call(this.truncateText);
    } else if (!xTypeTime) {
      // Если не переворачиваем текст, то переносим на несколько строк
      d3.select(this.axisX)
        .selectAll('text')
        .call(this.wrapText, this.x.bandwidth());
    }
  };

  renderAxises = ({
    timeFormat, xTypeTime, width, items
  }) => {
    d3.select(this.axisX).selectAll('.tick').remove(); // нужно для ресета тиков, т.к. если у тика такой же id, то не будет его перерисовки
    d3.select(this.axisYLeft).selectAll('.tick').remove(); // нужно для ресета тиков, т.к. если у тика такой же id, то не будет его перерисовки
    d3.select(this.axisYRight).selectAll('.tick').remove();

    const getTicksFormat = () => {
      if (xTypeTime) {
        return date => moment(date).format(isFunction(timeFormat) ? timeFormat(date) : timeFormat);
      }
      return item => flow([
        itemId => find(items, { id: itemId }),
        currentItem => get(currentItem, 'name'),
      ])(item);
    };

    const rightBars = items.reduce((acc, item) =>
      [...acc, ...item.bars.filter(bar => bar.isRight)], []);

    const xAxisScale = d3.axisBottom()
      .scale(this.x)
      .ticks(this.getTicksCount(xTypeTime, items.length))
      .tickFormat(getTicksFormat())
      .tickPadding(13)
      .tickSizeInner(-4)
      .tickSizeOuter(0);

    this.yLeftAxisScale = d3.axisLeft()
      .scale(this.yLeft)
      .tickValues(this.calculateTicksForLeftAxis(this.yLeft.domain()))
      .tickFormat(formatTicksByMask)
      .tickPadding(12)
      .tickSizeInner(-width)
      .tickSizeOuter(0);

    if (rightBars?.length > 0) {
      this.yRightAxisScale = d3.axisRight()
        .scale(this.yRight)
        .tickValues(this.calculateTicksForRightAxis(this.yRight.domain()))
        .tickFormat(formatTicksByMask)
        .tickPadding(12)
        .tickSizeInner(0)
        .tickSizeOuter(0);
    }

    d3.select(this.axisX)
      .call(xAxisScale);

    d3.select(this.axisYLeft)
      .call(this.yLeftAxisScale);

    if (rightBars?.length > 0) {
      d3.select(this.axisYRight)
        .call(this.yRightAxisScale);
    }

    // Вмещаем текст тиков в ширину баров
    this.renderFittedTicks();
  };

  renderSlicedBar = (slicedValues, barStyle) => {
    const {
      margins, fixedHeight,
    } = this.props;

    const height = fixedHeight - margins.top - margins.bottom;

    let accHeight = 0;

    return (
      <div>
        {slicedValues.map((slicedItem) => {
          const currentSliceValue = slicedItem?.value;

          let currentSliceHeight = 0;

          if (currentSliceValue === null || currentSliceValue === 0) {
            currentSliceHeight = 0; // height - this.yLeft(index * 10);
          } else {
            currentSliceHeight = height - this.yLeft(currentSliceValue);

            if (currentSliceHeight < 2) {
              currentSliceHeight = 2;
            }
          }

          const currentSliceStyle = {
            ...barStyle,

            height: `${currentSliceHeight}px`,
            // top: `${currentSliceTop}px`,
            bottom: `${accHeight}px`,
            background: slicedItem.color,
          };

          accHeight += currentSliceHeight;

          return (
            <div
              key={slicedItem?.key}
              className={classnames(styles.bar)}
              style={currentSliceStyle}
            />
          );
        })}
      </div>
    );
  };

  renderHtmlBars = () => {
    const {
      items, hoveredItem, margins,
      barWrapperPadding, additionalMetricsPadding, fixedHeight,
      onMouseOverCallback, onMouseOutCallback, onClickCallback,
      xTypeTime, renderCustomTooltip, periodType, showEmptyBars,
      customRenderTooltipDate, isForceRenderDate,
    } = this.props;


    if (items.length === 0) {
      return null;
    }

    const height = fixedHeight - margins.top - margins.bottom;
    const barsWidth = this.getBarsWidth();

    return items.map((item) => {
      const {
        id, date
      } = item;
      const key = date ? btoa(date) : btoa(id);

      if (!showEmptyBars && item?.bars?.length === 0) {
        return null;
      }

      const itemValues = item.bars.map(itemBar => itemBar.value);
      const maxValue = Math.max(...itemValues);

      // TODD: Очень сложная логика расчёта высот столбцов, подумать как упростить
      let rectHeight = 0;
      let rectY = 0;

      if (maxValue === null || maxValue === 0) {
        rectY = height - 2;
        rectHeight = 2;
      } else {
        rectY = height - this.yLeft(maxValue) < 2 ? height - 2 : this.yLeft(maxValue);
        rectHeight = height - this.yLeft(maxValue) < 2 ? 2 : height - this.yLeft(maxValue);
      }

      const xCoord = xTypeTime ? this.x(date.toDate()) : this.x(id);
      const rectX = xTypeTime ? xCoord : xCoord + (this.x.bandwidth() / 2);

      const barLeft = barsWidth / 2;
      let tooltipX = barsWidth;
      let barColumnWidth = barsWidth * 2;
      let barColumnLeft = rectX + margins.left;

      let wrapperHeight = rectHeight;
      let wrapperY = rectY;

      let totalHeight = 0;
      let totalRectY = 0;

      const renderedBars = item.bars?.map((barItem, barIndex) => {
        const isRightBar = barItem?.isRight;
        const yScale = isRightBar ? this.yRight : this.yLeft;

        if (barItem.value === null || barItem.value === 0) {
          totalRectY = height - 2;
          totalHeight = 2;
        } else {
          totalRectY = height - yScale(barItem.value) < 2 ? height - 2 : yScale(barItem.value);
          totalHeight = height - yScale(barItem.value) < 2 ? 2 : height - yScale(barItem.value);
        }

        if (totalHeight > rectHeight) {
          wrapperHeight = totalHeight;
          wrapperY = totalRectY;
        }

        if (barIndex !== 0) {
          barColumnWidth = barColumnWidth + additionalMetricsPadding + barsWidth;
          barColumnLeft = barColumnLeft - (additionalMetricsPadding / 2) - (barsWidth / 2);
          tooltipX = tooltipX + (additionalMetricsPadding / 2) + (barsWidth / 2);
        }

        const barValue = barItem?.value;
        let barHeight = 0;

        if (barValue === null || barValue === 0) {
          barHeight = 2;
        } else {
          barHeight = height - yScale(barValue);
        }

        if (barHeight < 1) {
          barHeight = 2;
        }

        const countedBarLeft = barIndex === 0 ?
          barLeft
          :
          barLeft + (barsWidth * barIndex) + (additionalMetricsPadding * barIndex);

        const barStyle = {
          width: `${barsWidth}px`,
          left: `${countedBarLeft}px`,
          height: `${barHeight}px`,
          bottom: 0,
          background: barItem.color,
        };

        if (barItem.slicedValues) {
          return this.renderSlicedBar(barItem.slicedValues, barStyle);
        }

        return (
          <div
            key={barItem?.key}
            className={classnames(styles.bar)}
            style={barStyle}
          />
        );
      });

      const barColumnStyles = {
        top: `${margins.top}px`,
        width: `${barColumnWidth}px`,
        left: `${barColumnLeft}px`,
        height: `${rectY + rectHeight}px`,
      };

      const barWrapperStyles = {
        height: `${wrapperHeight + barWrapperPadding}px`,
        left: 0,
        right: 0,
        top: `${wrapperY - barWrapperPadding}px`,
      };

      /* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/mouse-events-have-key-events */
      return (
        <div
          key={key}
          className={classnames(
            styles.barColumn,
            {
              [styles.isHovered]: hoveredItem === id,
            }
          )}
          style={barColumnStyles}
        >
          <div
            className={styles.barWrapper}
            style={barWrapperStyles}
            onMouseOver={() => onMouseOverCallback(id)}
            onMouseOut={() => onMouseOutCallback(id)}
            onClick={() => onClickCallback(id)}
            role='button'
            tabIndex={0}
          >
            {renderedBars}
            {renderCustomTooltip ?
              renderCustomTooltip({
                className: styles.barTooltip,
                x: tooltipX,
                y: wrapperHeight,
                item,
                periodType,
              })
              : (
                <Tooltip
                  x={tooltipX}
                  y={wrapperHeight}
                  tooltipData={item}
                  className={styles.barTooltip}
                  periodType={periodType}
                  isForceRenderDate={isForceRenderDate}
                  customRenderDate={customRenderTooltipDate}
                />
              )}
          </div>
        </div>
      );
      /* eslint-enable jsx-a11y/mouse-events-have-key-events, jsx-a11y/mouse-events-have-key-events */
    });
  };

  renderCompareTips = () => {
    const {
      items,
      margins,
      toCompareItems,
      fixedHeight,
    } = this.props;

    if (items.length === 0) {
      return null;
    }

    const height = fixedHeight - margins.top - margins.bottom;

    const filteredItems = items.filter(item => (toCompareItems.indexOf(item.id) !== -1));

    const barsWidth = this.getBarsWidth();

    return filteredItems.map((item) => {
      const { id, value } = item;
      const key = btoa(id);

      let rectY = 0;

      if (value === null || value === 0) {
        rectY = height - 2;
      } else {
        rectY = height - this.yLeft(value) < 2 ? height - 2 : this.yLeft(value);
      }

      const rectX = this.x(id) + (this.x.bandwidth() / 2) + margins.left;

      return (
        <div
          key={key}
          className={styles.compareTip}
          style={{
            height: 20,
            width: barsWidth * 2,
            left: Number.isNaN(rectX) ? null : rectX,
            top: (rectY + margins.top) - 28
          }}
        >
          <AddToComparisonIcon className={styles.compareTipIcon} width={barsWidth * 2} />
        </div>
      );
    });
  };

  render() {
    const {
      items,
      rotateLabels,
      isFetching,
      margins,
      fixedHeight,
      withCompareTips,
      isEmpty,
      emptyTitle,
    } = this.props;

    const {
      width,
      // hoveredBar,
      // hoveredTooltip,
    } = this.state;

    // Добавляем высоты для перевёрнуых лейблов если слишком много барчартов
    const bottomPaddingForLabels = rotateLabels ? 30 : 0;

    return (
      // Враппер нужен чтобы тултип было видно за пределами графика, т.к. у chart стоит overflow: hodden
      <div className={styles.tooltipWrapper}>
        <div
          ref={this.chart}
          className={styles.chart}
        >
          <svg
            ref={(element) => {
              this.svg = element;
            }}
            width={width}
            height={fixedHeight + bottomPaddingForLabels}
          >
            <g transform={`translate(${margins.left},${margins.top})`}>
              <g
                className={classnames(styles.axisX)}
                transform={`translate(12,${fixedHeight - margins.top - margins.bottom})`} // 12 магическое число выведенное эмпирическим путём, пофиксить
                ref={(element) => {
                  this.axisX = element;
                }}
              />
              <g
                className={classnames(styles.axisY)}
                ref={(element) => {
                  this.axisYLeft = element;
                }}
              />
              <g
                className={classnames(styles.axisY)}
                transform={`translate(${width - margins.right - margins.left},0)`}
                ref={(element) => {
                  this.axisYRight = element;
                }}
              />
            </g>
          </svg>

          {isFetching && <DefaultCircleLoader />}

          {(items.length === 0 || isEmpty) && !emptyTitle ? (
            <div className={styles.emptyState}>
              <FormattedMessage id='harvestDashboard.emptyText' />
            </div>
          ) : null}

          {(items.length === 0 || isEmpty) && emptyTitle ? (
            <div className={styles.emptyState}>
              {emptyTitle}
            </div>
          ) : null}
        </div>

        <div>
          {this.renderHtmlBars()}
        </div>

        {withCompareTips && (
          <div>
            {this.renderCompareTips()}
          </div>
        )}
      </div>
    );
  }
}

export default SimpleBarChart;
