import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './actions';

const initialState = {
  notifications: [],
};

export default function notificationCenterReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return { ...state, notifications: [...state.notifications, action.payload], };
    case REMOVE_NOTIFICATION:
      return { ...state, notifications: state.notifications.filter(item => item.id !== action.id), };
    default:
      return state;
  }
}
