import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { GREEN_ZONE, YELLOW_ZONE } from '../../helpers/constants';

import styles from './ProgressBar.module.css';

export default class ProgressBar extends PureComponent {
  static propTypes = {
    percent: PropTypes.number,
    width: PropTypes.number,
    colorized: PropTypes.bool,
    greenZone: PropTypes.number,
    yellowZone: PropTypes.number,
  };

  static defaultProps = {
    percent: 0,
    width: null,
    colorized: false,
    greenZone: GREEN_ZONE,
    yellowZone: YELLOW_ZONE,
  };

  getBarColor = (percent) => {
    const { colorized, greenZone, yellowZone } = this.props;

    if (!colorized) {
      return null;
    }

    if (percent >= greenZone) {
      return 'green';
    } if (percent >= yellowZone) {
      return 'yellow';
    }

    return 'red';
  };

  render() {
    const { percent, width } = this.props;
    const color = this.getBarColor(percent);

    return (
      <div
        className={styles.progressBar}
        style={width ? { width } : null}
      >
        <div
          className={classnames(
            styles.progressBarLine,
            {
              [styles.red]: color === 'red',
              [styles.yellow]: color === 'yellow',
              [styles.green]: color === 'green',
            }
          )}
          style={{ width: `${percent}%` }}
        />
      </div>
    );
  }
}
