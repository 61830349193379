import React, { useEffect, useState } from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import moment from 'moment';

import BigButton from 'components/BigButton';

import styles from './HasChanges.module.css';

const getTimeText = (changesTime) => {
  if (!changesTime) {
    return undefined;
  }

  const start = moment.utc(changesTime);
  const end = moment.utc(Date.now());

  const duration = moment.duration(end.diff(start));

  return duration.humanize();
};

const renderSaveText = (formatMessage, onSubmit, timeText) => (
  <span className={styles.timeText}>
    <span>{`${formatMessage({ id: 'plans.lastSave' })} ${timeText} ${formatMessage({ id: 'plans.ago' })}.`}</span>
    <BigButton
      className={styles.timeTextButton}
      title={`${formatMessage({ id: 'plans.saveChanges' })}.`}
      type='button'
      onClick={onSubmit}
      // disabled={isPlansEditorSaving}
      theme='transparent'
    />
  </span>
);

const HasChanges = ({
  intl,
  onSubmit,
  changesTime,
}) => {
  const { formatMessage } = intl;

  const [timeText, setTimeText] = useState(null);

  useEffect(() => {
    const newTimeText = getTimeText(changesTime);

    if (timeText !== newTimeText) {
      setTimeText(newTimeText);
    }
    const interval = setInterval(() => {
      setTimeText(getTimeText(changesTime));
    }, 60000);
    return () => clearInterval(interval);
  }, [timeText, setTimeText, changesTime]);

  // useInterval(() => {
  //   setCount(count + 1);
  // }, delay);

  return (
    <div className={styles.headerControlsSave}>
      {changesTime ?
        renderSaveText(formatMessage, onSubmit, timeText)
        :
        formatMessage({ id: 'plans.noChanges' })}
    </div>
  );
};

HasChanges.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  changesTime: PropTypes.number,
};

HasChanges.defaultProps = {
  changesTime: undefined,
};

export default HasChanges;
