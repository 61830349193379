import React, {
  memo, useState, useImperativeHandle, forwardRef, useCallback, useEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import {
  find, groupBy, isEqual, indexOf, sortBy, findIndex, head, maxBy, isNil,
} from 'lodash';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import add from 'date-fns/add';
import isToday from 'date-fns/isToday';
import isWeekend from 'date-fns/isWeekend';
import classnames from 'classnames';

import { getNameByLocal } from 'helpers/getNameByLocal';
import { parseDate, formatDate, formatDateLocalized } from 'helpers/datesHelper';
import { getPlantingCycleLabelByRefs, getCompartmentNameById } from 'helpers/getPlantingCycleLabel';
import prepareProductGroupParams from 'helpers/prepareProductGroupParams';
import storageWrapper from 'helpers/storageWrapper';
import filterForecastPlantingData from 'helpers/filterForecastPlantingData';

import VarietySearchSelect from 'components/VarietySearchSelect/containers/VarietySearchSelectContainer';
import CompartmentFilter from 'components/DashboardComplexFilters/components/CompartmentFilter';
import HarvestForecastEnterTable from 'components/HarvestForecastEnterTable';
import HarvestForecastAssistantPanel from 'components/HarvestForecastAssistantPanel';
import BigButton from 'components/BigButton';
import AddChartIcon from 'components/Icons/AddChartIcon';

import styles from './HarvestForecastEnterForm.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');

const getInitialDataArray = (intl, harvestHeaderRowIndex, editMode, varieties, fruitClasses, compartments, forecastData = {}) => {
  const { formatMessage, locale } = intl;
  const emptyResult = {
    initialDataArr: [],
    mergedCells: [],
  };

  if (!forecastData) {
    return emptyResult;
  }

  const {
    manualForecast, harvestCategories, start, endInclusive
  } = forecastData;

  if (!manualForecast) {
    return emptyResult;
  }

  const startDate = parseDate(start);
  const endInclusiveDate = parseDate(endInclusive);

  const dayDifference = differenceInCalendarDays(
    endInclusiveDate,
    startDate,
  );

  const datesLength = new Array(dayDifference + 1).fill(0); // + 1 потому что включительно
  const datesArray = datesLength.map((item, index) => {
    const currentDate = add(startDate, { days: index });
    const dateText = formatDateLocalized(currentDate, 'llll');
    const dateString = formatDate(currentDate, 'yyyy-MM-dd');

    return {
      rowIndex: 3 + index,
      columnIndex: 0,
      value: dateText,
      date: dateString,
      type: 'date',
      canEdit: false,
      isToday: isToday(currentDate),
      isWeekend: isWeekend(currentDate),
    };
  });

  const dateHeader = {
    columnIndex: 0,
    value: formatMessage({ id: 'harvest.date' }),
    type: 'dateHeader',
    canEdit: false,
  };

  const headers = [
    {
      ...dateHeader,
      rowIndex: 0,
    },
    {
      ...dateHeader,
      rowIndex: 1,
    },
    {
      ...dateHeader,
      rowIndex: 2,
    },
  ];

  /**
   * Группируем по compartment, чтобы проще было строить табличку
   * потом сортируем по изначальному порядку (как возвращается с бэка), т.к.
   * groupBy не гарантирует возвращаемый порядок сгрупиированных элементов
   */
  const groupByCompartment = sortBy(groupBy(manualForecast, 'product.payload.compartmentRef.id'), group => findIndex(manualForecast, { product: { id: group[0]?.product?.id } }));

  let startColumnIndex = 1;

  const mergedCells = [
    {
      top: 0, bottom: 2, left: 0, right: 0
    },
  ];

  const buildedHeaders = Object.keys(groupByCompartment).reduce((compartmentAcc, key) => {
    const productsList = groupByCompartment[key];

    mergedCells.push({
      top: 0, bottom: 0, left: startColumnIndex, right: startColumnIndex + productsList.length * harvestCategories.length - 1
    });

    const productsColumns = productsList.reduce((productAcc, productItem) => {
      const product = productItem?.product?.payload;
      const isExtraProduct = productItem?.product?.type === 'HarvestForecastExtraProduct';

      if (isExtraProduct) {
        mergedCells.push({
          top: 0, bottom: 1, left: startColumnIndex, right: startColumnIndex + harvestCategories.length - 1
        });
      } else {
        // Тут в startColumnIndex уже учитывается индекс productIndex
        mergedCells.push({
          top: 1, bottom: 1, left: startColumnIndex, right: startColumnIndex + harvestCategories.length - 1
        });
      }

      const headersByCategories = harvestCategories.reduce((accCategories, categoryItem, index) => {
        const units = formatMessage({ id: `cunits.mini.${categoryItem?.units}` });
        const name = getNameByLocal(categoryItem, locale);
        const columnIndex = startColumnIndex + index;
        const plantingCycleLabel = getPlantingCycleLabelByRefs({
          startDate: product?.startDate,
          endDate: product?.endDate,
          targetWeight: product?.targetWeight,
          varietyId: product?.varietyRef?.id,
          fruitClassId: product?.fruitClassRef?.id,
          varieties,
          fruitClasses,
          intl
        });
        const greenhouseName = getCompartmentNameById({ compartmentId: product?.compartmentRef?.id, compartments });

        // Для extra columns формируем другие имена
        const finalGreenhouseName = isExtraProduct ? product?.name : greenhouseName;
        const finalPlantingCycleLabel = isExtraProduct ? product?.name : plantingCycleLabel;


        const dataArray = datesArray.map((dateItem) => {
          const forecastItem = find(productItem.forecast, { date: dateItem.date });
          const isCanEdit = parseDate(productItem?.range?.start) <= parseDate(dateItem.date) && parseDate(productItem?.range?.endInclusive) >= parseDate(dateItem.date);

          return {
            rowIndex: dateItem?.rowIndex,
            columnIndex,
            value: forecastItem ? forecastItem?.harvest[categoryItem?.id] : '',
            type: 'data',
            canEdit: isCanEdit,
            productId: product?.id,
          };
        });

        const extraProductId = isExtraProduct ? { extraProductId: product?.id } : {};

        return [
          ...accCategories,
          {
            rowIndex: 0,
            columnIndex,
            value: finalGreenhouseName,
            type: isExtraProduct ? 'extraColumnHeader' : 'greenhouse',
            canEdit: false,
            withAction: isExtraProduct,
            ...extraProductId,
          },
          {
            rowIndex: 1,
            columnIndex,
            value: finalPlantingCycleLabel,
            type: isExtraProduct ? 'extraColumnHeader' : 'plantingCycle',
            productId: product?.id,
            canEdit: false,
            withAction: isExtraProduct,
            ...extraProductId,
          },
          {
            rowIndex: harvestHeaderRowIndex,
            columnIndex,
            value: `${name}, ${units}`,
            type: 'harvestHeader',
            category: categoryItem?.id?.toString(),
            relatedTo: categoryItem?.relatedTo?.toString(),
            units: categoryItem?.units,
            canEdit: false,
            ...extraProductId,
          },
          ...dataArray,
        ];
      }, []);

      const columnPerProduct = harvestCategories?.length || 1;
      startColumnIndex += columnPerProduct;

      return [...productAcc, ...headersByCategories];
    }, []);

    return [...compartmentAcc, ...productsColumns];
  }, []);

  const maxColumn = maxBy(buildedHeaders, 'columnIndex');
  const maxColumnIndex = maxColumn?.columnIndex || 0;
  const totalColumnIndex = maxColumnIndex + 1;

  const totalColumnHeader = {
    columnIndex: totalColumnIndex,
    value: formatMessage({ id: 'harvest.total' }),
    type: 'totalColumnHeader',
    canEdit: false,
  };

  const totalHeaders = harvestCategories.reduce((acc, categoryItem, categoryItemIndex) => {
    // Для тотал строк считаем в абсолютных единицах (кг), поэтому в тотале выводим единицы relatedTo столбца
    const categoryShownUnits = categoryItem?.units !== 'percent' ?
      categoryItem?.units
      :
      find(harvestCategories, { id: categoryItem?.relatedTo })?.units;

    const units = formatMessage({ id: `cunits.mini.${categoryShownUnits}` });
    const name = getNameByLocal(categoryItem, locale);
    const currentColumnIndex = totalColumnIndex + categoryItemIndex;

    /**
     * Заполняем пустыми данными, т.к. подсчёт тоталов идёт в render функции
     * для динамического обновления. Ниже код подсчёта, если динамическое обновление не нужно
     */

    //  const forecastItem = find(productItem.forecast, { date: dateItem.date });
    //  const sumOfValues = manualForecast.reduce((accSumOf, manualForecastItem) => {
    //    const currentDateData = find(manualForecastItem?.forecast, { date: dateItem.date });

    //    const currentHarvestValue = currentDateData?.harvest[Number(categoryItem?.id)];
    //    const currentHarvestValueNumber = currentHarvestValue ? Number(currentHarvestValue) : 0;

    //    return accSumOf + currentHarvestValueNumber;
    //  }, 0);

    const dataArray = datesArray.map(dateItem => ({
      rowIndex: dateItem?.rowIndex,
      columnIndex: currentColumnIndex,
      value: '',
      type: 'totalColumnData',
      canEdit: false,
    }));

    return [
      ...acc,
      {
        ...totalColumnHeader,
        rowIndex: 0,
        columnIndex: currentColumnIndex
      },
      {
        ...totalColumnHeader,
        rowIndex: 1,
        columnIndex: currentColumnIndex,
      },
      {
        rowIndex: 2,
        columnIndex: currentColumnIndex,
        value: `${name}, ${units}`,
        type: 'harvestHeader',
        category: categoryItem?.id?.toString(),
        relatedTo: categoryItem?.relatedTo?.toString(),
        units: categoryItem?.units,
        canEdit: false,
      },
      ...dataArray,
    ];
  }, []);

  mergedCells.push({
    top: 0, bottom: 1, left: totalColumnIndex, right: totalColumnIndex + harvestCategories.length - 1
  });

  /*
  // Пока убираем функциональность Extra Columns
  const maxColumn = maxBy(buildedHeaders, 'columnIndex');
  const extraColumnIndex = maxColumn?.columnIndex + 1;

  const createExtraColumnHeader = {
    columnIndex: extraColumnIndex,
    value: formatMessage({ id: 'forecast.addExtraCycle' }),
    type: 'createExtraColumnHeader',
    canEdit: false,
    withAction: true,
  };

  let extraHeaders = [];
  let extraColumnDataCells = [];

  if (editMode) {
    extraHeaders = [
      {
        ...createExtraColumnHeader,
        rowIndex: 0,
      },
      {
        ...createExtraColumnHeader,
        rowIndex: 1,
      },
      {
        ...createExtraColumnHeader,
        rowIndex: 2,
      },
    ];

    extraColumnDataCells = datesArray.map(item => ({
      rowIndex: item?.rowIndex,
      columnIndex: extraColumnIndex,
      value: '',
      type: 'data',
      canEdit: false,
      forcedWhiteColor: true,
    }));

    mergedCells.push({
      top: 0, bottom: 2, left: extraColumnIndex, right: extraColumnIndex
    });
  }
  */
  const initialDataArr = [
    ...headers,
    ...buildedHeaders,
    // ...extraHeaders,
    ...datesArray,
    ...totalHeaders,
    // ...extraColumnDataCells,
  ];

  return {
    initialDataArr,
    mergedCells,
  };
};

const getInitialDataObjects = (initialDataArr = []) => initialDataArr.reduce((acc, item) => ({
  ...acc,
  [[item.rowIndex, item.columnIndex]]: item.value,
}), {});


const formatDataToTable = (intl, harvestHeaderRowIndex, editMode, varieties, fruitClasses, compartments, forecastData) => {
  const { initialDataArr, mergedCells } = getInitialDataArray(intl, harvestHeaderRowIndex, editMode, varieties, fruitClasses, compartments, forecastData);

  const initialData = getInitialDataObjects(initialDataArr);

  return {
    initialData,
    initialDataArr,
    mergedCells,
  };
};

const getCellInfo = (initialDataArr, rowIndex, columnIndex) => {
  const currentCellProps = find(initialDataArr, { rowIndex: Number(rowIndex), columnIndex: Number(columnIndex) }) || null;

  return currentCellProps;
};

const getTypeName = (typeName) => {
  switch (typeName) {
    case 'plantingCycle':
      return 'PLANTING_CYCLE';
    case 'extraColumnHeader':
      return 'EXTRA';
    default:
      return 'PLANTING_CYCLE';
  }
};

const getConvertedData = (initialDataArr, data) => {
  const convertedArray = Object.keys(data).map((key) => {
    const [rowIndex, columnIndex] = key.split(',');

    return {
      rowIndex: Number(rowIndex),
      columnIndex: Number(columnIndex),
      value: data[key],
      type: find(initialDataArr, { columnIndex: Number(columnIndex), rowIndex: Number(rowIndex) })?.type,
    };
  });

  const filteredData = convertedArray
    .filter(item => item?.type !== 'harvestHeader')
    .filter(item => item?.type !== 'plantingCycle')
    .filter(item => item?.type !== 'greenhouse')
    .filter(item => item?.type !== 'date')
    .filter(item => item?.type !== 'dateHeader')
    .filter(item => item?.type !== 'createExtraColumnHeader')
    .filter(item => item?.type !== 'extraColumnHeader')
    .filter(item => item?.type !== 'totalColumnHeader')
    .filter(item => item?.type !== 'totalColumnData');

  const dataWithAdditionalInfo = filteredData.map((item) => {
    const date = find(initialDataArr, { columnIndex: 0, rowIndex: item.rowIndex });
    const category = find(initialDataArr, { columnIndex: item.columnIndex, rowIndex: 2 });
    const product = find(initialDataArr, { columnIndex: item.columnIndex, rowIndex: 1 });

    return {
      ...item,
      date: date?.date,
      category: category?.category,
      productId: product?.productId,
      productType: product?.type,
    };
  });

  const dataByProduct = groupBy(dataWithAdditionalInfo, 'productId');

  const getGroupedByDate = (productGroup) => {
    const groupedByDate = groupBy(productGroup, 'date');

    const preparedArray = Object.keys(groupedByDate).reduce((groupAcc, key) => {
      const harvestObj = groupedByDate[key]?.reduce((acc, item) => {
        // Реальная фильтрация ячеек по нулевому значению происходит здесь
        if (item.value === '' || isNil(item.value)) {
          return acc;
        }

        return {
          ...acc,
          [item.category]: item.value,
        };
      }, null);

      if (!harvestObj) {
        return groupAcc;
      }

      return [
        ...groupAcc,
        {
          date: key,
          harvest: harvestObj,
        },
      ];
    }, []);

    return preparedArray;
  };

  const productForecasts = Object.keys(dataByProduct).map(key => ({
    product: {
      id: Number(key),
      type: getTypeName(head(dataByProduct[key])?.productType)
    },
    forecast: getGroupedByDate(dataByProduct[key]),
  }));

  return { convertedData: productForecasts };
};

const getFilteredForecastData = (filters, forecastData) => {
  if (!forecastData) {
    return forecastData;
  }

  let filteredData = { ...forecastData };

  if (filters.compartmentId) {
    const compartmentsList = filters.compartmentId.map(item => Number(item));
    const filteredManualForecast = filteredData?.manualForecast?.filter((item) => {
      // Extra cycles удаляем из фильтрации
      if (item.product.type !== 'PlantingCycle') {
        return false;
      }

      const arrayIndex = indexOf(compartmentsList, item.product.payload.compartmentRef.id);

      return arrayIndex !== -1;
    });

    filteredData = {
      ...filteredData,
      manualForecast: filteredManualForecast.length > 0 ? filteredManualForecast : null,
    };
  }

  if (filters.varietyId) {
    const filteredManualForecast = filteredData?.manualForecast?.filter(item => (item.product.type === 'PlantingCycle' && filters.varietyId === item.product.payload.varietyRef.id));

    filteredData = {
      ...filteredData,
      manualForecast: filteredManualForecast.length > 0 ? filteredManualForecast : null,
    };
  } else if (filters.fruitClassCode) {
    const filteredManualForecast = filteredData?.manualForecast?.filter(item => (item.product.type === 'PlantingCycle' && filters.fruitClassCode === item.product.payload.fruitClassRef.id));

    filteredData = {
      ...filteredData,
      manualForecast: filteredManualForecast.length > 0 ? filteredManualForecast : null,
    };
  }

  return filteredData;
};

const handlerDataCellClick = (getCurrentData, setCurrentPlantingCycleId, setCurrentProductData, currentPlantingCycleId, productId) => {
  const currentData = getCurrentData();
  const currentProductData = find(currentData, { product: { id: productId } });

  // TODO: узнать должна ли работать assistant panel с extra циклами, если не должна, то тут нужно возвращать null

  const currentProductDataWithEmpty = !currentProductData ?
    {
      product: {
        id: productId || currentPlantingCycleId,
        type: 'PLANTING_CYCLE'
      },
      forecast: []
    }
    :
    currentProductData;

  setCurrentProductData(currentProductDataWithEmpty);

  if (currentPlantingCycleId !== productId) {
    setCurrentPlantingCycleId(productId);
  }
};

const afterOnSubmit = (forwardedRef, isShowAssistantPanel, setCurrentPlantingCycleId, setCurrentProductData) => {
  if (!isShowAssistantPanel) {
    return null;
  }

  // const productForecasts = forwardedRef?.current?.getCurrentData();
  const plantingCycleId = forwardedRef?.current?.getCurrentPlantingCycleId();

  return handlerDataCellClick(forwardedRef?.current?.getCurrentData, setCurrentPlantingCycleId, setCurrentProductData, plantingCycleId, plantingCycleId);
};

const createExtraProduct = (requestCreateExtraProduct, forecastData, defaultExtraProductName, actionAfterSuccess) => requestCreateExtraProduct({ forecastId: forecastData?.forecastId, name: defaultExtraProductName, actionAfterSuccess });

const HarvestForecastEnterForm = ({
  intl,
  forecastData,
  compartments,
  varieties,
  fruitClasses,
  editMode,
  setHasChangesTime,
  filters,
  changeReportFilters,
  forecastIdHash,
  requestCreateExtraProduct,
  requestUpdateExtraProduct,
  requestDeleteExtraProduct,
}, forwardedRef) => {
  const { formatMessage } = intl;
  const rowsHeadersCount = 3;
  const columnsHeadersCount = 1;
  const harvestHeaderRowIndex = 2;

  const forecastDataFromLocalstorage = forecastIdHash ? safeLocalStorage.getItem(forecastIdHash) : null;

  const filteredForecastData = getFilteredForecastData(filters, forecastData);

  // TODO: переделать инит данных, тут наспех сделал, очень неаккуратно и многословно
  const { initialData, initialDataArr, mergedCells } = formatDataToTable(intl, harvestHeaderRowIndex, editMode, varieties, fruitClasses, compartments, filteredForecastData);

  // const rowCount = sortedMetrics?.length + HEADER_ROWS_LENGTH;
  // const columnCount = plants?.length + HEADER_COLUMNS_LENGTH + ADD_COLUMNS_LENGTH;
  const rowCount = initialDataArr.filter(dataItem => dataItem.columnIndex === 0).length;
  const columnCount = initialDataArr.filter(dataItem => dataItem.rowIndex === 0).length;

  // Нужно чтобы убирать из результатов (и при копипасте) данные фейковых столбцов (с + на добавление колонки)
  const addPlantHeaderColumnIndex = find(initialDataArr, { type: 'addPlantHeader' })?.columnIndex;

  // Проверяем есть ли в localstorage сохранённые данные, если есть, то берём их
  const previousData = forecastDataFromLocalstorage ? JSON.parse(forecastDataFromLocalstorage) : initialData;
  const [data, setData] = useState(previousData);

  const assistantPanelState = safeLocalStorage.getItem('assistantPanelState') || 'opened';
  const defaultAssistantPanelState = assistantPanelState === 'opened';
  const [isShowAssistantPanel, setIsShowAssistantPanel] = useState(defaultAssistantPanelState);
  const [currentPlantingCycleId, setCurrentPlantingCycleId] = useState(null);
  const [currentProductData, setCurrentProductData] = useState(null);

  const toggleSidePanel = () => {
    safeLocalStorage.setItem('assistantPanelState', assistantPanelState === 'opened' ? 'closed' : 'opened');
    setIsShowAssistantPanel(!isShowAssistantPanel);
  };

  const getCurrentData = useCallback(() => {
    const { convertedData } = getConvertedData(
      initialDataArr,
      data,
    );

    return convertedData;
  }, [data, initialDataArr]);

  const getCurrentPlantingCycleId = useCallback(() => currentPlantingCycleId, [currentPlantingCycleId]);

  // Для возможности использовать из родительского компонента
  useImperativeHandle(forwardedRef, () => ({
    getCurrentData,
    getCurrentPlantingCycleId,
  }));

  /**
   * оптимизировать, сейчас немного из-за этого проседает скорость ввода
   * т.к. дёргается на кажлое изменение инпута
   */
  useEffect(() => {
    if (isEqual(initialData, data)) {
      setHasChangesTime(null);
    } else {
      safeLocalStorage.setItem(forecastIdHash, JSON.stringify(data));
      setHasChangesTime(Date.now());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handlerVarietyChange = useCallback((options) => {
    const { species, varietyId, fruitClassCode } = options;
    const filterParams = prepareProductGroupParams(varieties, species, varietyId, fruitClassCode);
    changeReportFilters(filterParams);
    // onFiltersChange(filterParams);
  }, [changeReportFilters, varieties]);


  const handlerCompartmentChange = useCallback((options) => {
    changeReportFilters(options);
    // onFiltersChange(options);
  }, [changeReportFilters]);

  const handlerProductIdChange = useCallback((productId) => {
    handlerDataCellClick(getCurrentData, setCurrentPlantingCycleId, setCurrentProductData, currentPlantingCycleId, productId);
  }, [getCurrentData, setCurrentPlantingCycleId, setCurrentProductData, currentPlantingCycleId]);

  const defaultExtraProductName = formatMessage({ id: 'forecast.extraCycle' });

  const searchVarieties = useMemo(() =>
    filterForecastPlantingData(forecastData, varieties, 'varietyRef'),
  [forecastData, varieties]);

  const searchFruitClasses = useMemo(() =>
    filterForecastPlantingData(forecastData, fruitClasses, 'fruitClassRef'),
  [forecastData, fruitClasses]);

  const searchCompartments = useMemo(() =>
    filterForecastPlantingData(forecastData, compartments, 'compartmentRef'),
  [forecastData, compartments]);

  return (
    <div className={styles.body}>
      <div className={styles.controlsContainer}>
        <div className={styles.filtersContainer}>
          <VarietySearchSelect
            isShowAllSpecies={false}
            enabledSpecies={[forecastData?.species]}
            onSelectOption={handlerVarietyChange}
            varieties={searchVarieties}
            fruitClasses={searchFruitClasses}
            selectedOption={filters.varietyId || filters.fruitClassCode || filters.species || forecastData?.species}
            classNameButton={styles.varietySelect}
            title={formatMessage({ id: 'forecast.dashboard.filters.varietiesSearch' })}
          />
          <CompartmentFilter
            intl={intl}
            compartments={searchCompartments}
            compartmentId={filters.compartmentId}
            disableUseQuery
            onFiltersChange={handlerCompartmentChange}
            title={formatMessage({ id: 'forecast.dashboard.filters.greenhouses' })}
          />
        </div>
        <BigButton
          className={classnames(
            styles.showDataAssistantButton,
            {
              [styles.sidePanelOpened]: isShowAssistantPanel
            }
          )}
          onClick={toggleSidePanel}
          icon={<AddChartIcon />}
          title={formatMessage({ id: 'forecast.assistant.showDataAssistant' })}
          theme='transparent'
          bigIcon
        />
      </div>
      <div className={styles.mainContent}>
        <div
          className={classnames(
            styles.container,
            {
              [styles.sidePanelOpened]: isShowAssistantPanel
            }
          )}
        >
          <HarvestForecastEnterTable
            rowCount={rowCount}
            columnCount={columnCount}
            harvestHeaderRowIndex={harvestHeaderRowIndex}
            data={data}
            setData={setData}
            getCellInfo={(rowIndex, colIndex) => getCellInfo(initialDataArr, rowIndex, colIndex)}
            mergedCells={mergedCells}
            rowsHeadersCount={rowsHeadersCount}
            columnsHeadersCount={columnsHeadersCount}
            addPlantHeaderColumnIndex={addPlantHeaderColumnIndex}
            validatePlantName={() => {}}
            editMode={editMode}
            // onDataCellClick={productId => handlerDataCellClick(getCurrentData(), setCurrentPlantingCycleId, setCurrentProductData, currentPlantingCycleId, productId)}
            onDataCellClick={handlerProductIdChange}
            afterOnSubmit={() => afterOnSubmit(forwardedRef, isShowAssistantPanel, setCurrentPlantingCycleId, setCurrentProductData)}
            createExtraProduct={actionAfterSuccess => createExtraProduct(requestCreateExtraProduct, forecastData, defaultExtraProductName, actionAfterSuccess)}
            requestDeleteExtraProduct={requestDeleteExtraProduct}
            requestUpdateExtraProduct={requestUpdateExtraProduct}
            clearForecastFromLocalstorage={() => safeLocalStorage.removeItem(forecastIdHash)}
          />
        </div>
        <div
          className={classnames(
            styles.sidePanel,
            {
              [styles.sidePanelOpened]: isShowAssistantPanel
            }
          )}
        >
          <HarvestForecastAssistantPanel
            versionId={forecastData?.id}
            plantingCycleId={currentPlantingCycleId}
            currentProductData={currentProductData}
            onClose={toggleSidePanel}
            opened={isShowAssistantPanel}
            forecastId={forecastData?.forecastId}
          />
        </div>
      </div>
    </div>
  );
};

HarvestForecastEnterForm.propTypes = {
  intl: intlShape.isRequired,
  forecastData: PropTypes.object,
  compartments: PropTypes.array.isRequired,
  varieties: PropTypes.array.isRequired,
  fruitClasses: PropTypes.array.isRequired,
  editMode: PropTypes.bool,
  setHasChangesTime: PropTypes.func.isRequired,
  changeReportFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
  forecastIdHash: PropTypes.string,
  requestCreateExtraProduct: PropTypes.func.isRequired,
  requestUpdateExtraProduct: PropTypes.func.isRequired,
  requestDeleteExtraProduct: PropTypes.func.isRequired,
};

HarvestForecastEnterForm.defaultProps = {
  forecastData: null,
  editMode: false,
  filters: null,
  forecastIdHash: undefined,
};

export default memo(forwardRef(HarvestForecastEnterForm));
