import React, { Component } from 'react';
import DatePicker from 'react-datepicker/dist/react-datepicker.min';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import moment from 'moment-timezone';
import classnames from 'classnames';

import DateButton from '../DateButton';
import Select from '../../../Select';
import MobileMenu from '../../../MobileMenu';
import BigSelectInput from '../../../BigSelectInput';
import BigButton from '../../../BigButton';

import getDateFormat from '../../../../helpers/getDateFormat';
import { API_DATE_FORMAT, getDefaultDateRange } from '../../../../helpers/defaultDates';
import isPhoneView from '../../../../helpers/isPhoneView';

import { ReactComponent as LeftIcon } from './assets/left.svg';
import { ReactComponent as RightIcon } from './assets/right.svg';
import CalendarIcon from '../../../Icons/CalendarIcon';

import styles from './RangePicker.module.css';

const DEFAULT_DATE_RANGE = getDefaultDateRange('year', true);

export default class RangePicker extends Component {
  static propTypes = {
    intl: intlShape.isRequired,

    timezone: PropTypes.string.isRequired,
    handlerAfterRangeSelect: PropTypes.func.isRequired,
    customRanges: PropTypes.array,
    periodType: PropTypes.string,
    withoutRangePicker: PropTypes.bool,
    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
    forcedMaxDate: PropTypes.object,
    plantingCycleId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    className: PropTypes.string,
    withoutAddDisable: PropTypes.bool,
    withoutArrow: PropTypes.bool,
    dateButtonClassName: PropTypes.string,
    firstDateFormat: PropTypes.string,
    secondDateFormat: PropTypes.string,
    enableFutureDates: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    withoutRangePicker: false,
    customRanges: null,
    periodType: 'week',
    anyDateOfPeriodStart: DEFAULT_DATE_RANGE.startDate,
    anyDateOfPeriodEnd: DEFAULT_DATE_RANGE.endDate,
    plantingCycleId: null,
    forcedMaxDate: null,
    className: null,
    withoutAddDisable: false,
    withoutArrow: false,
    dateButtonClassName: null,
    firstDateFormat: 'lll',
    secondDateFormat: 'lll',
    enableFutureDates: false,
    disabled: false,
  };

  // Дублируем в стейте компонента, чтобы не было проблем с перерендером после смены GET параметров
  state = {
    periodType: this.props.periodType,
    anyDateOfPeriodStart: null,
    anyDateOfPeriodEnd: null,
    highlightedDate: undefined,
    mobileMenuOpened: false,
    isPhone: isPhoneView(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.handlerResizeWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResizeWindow);
  }

  setNewSearchPath = (newParams) => {
    const { handlerAfterRangeSelect } = this.props;

    this.setState({
      ...newParams,
      anyDateOfPeriodStart: null,
      anyDateOfPeriodEnd: null,
    });

    handlerAfterRangeSelect(newParams);
  };

  handlerResizeWindow = () => {
    this.setState({ isPhone: isPhoneView() });
  };

  handlerRangeSelect = options => this.setNewSearchPath({ periodType: options.value });

  handlerRangeSelectMobile = options => this.setState({ periodType: options.value });

  handlerChangeStart = (date) => {
    const { plantingCycleId } = this.props;
    const { periodType } = this.state;

    //  Если установлен planting cycle, то дейтпикер задисейблен
    if (plantingCycleId) return;

    this.endDatepicker.setOpen(true);
    // Устанавливаем дату начала, в зависимости от начала периода (неделя/месяц) выбранной даты
    this.setState({
      anyDateOfPeriodStart: date.clone().startOf(periodType).format(API_DATE_FORMAT),
      highlightedDate: 'end',
    });
  };

  handlerChangeEnd = (date) => {
    const { anyDateOfPeriodStart: anyDateOfPeriodStartFromProps } = this.props;
    const { periodType, anyDateOfPeriodStart: anyDateOfPeriodStartFromState } = this.state;

    const anyDateOfPeriodStart = anyDateOfPeriodStartFromState || anyDateOfPeriodStartFromProps;
    // Устанавливаем дату конца, в зависимости от конца периода (неделя/месяц) выбранной даты
    this.setNewSearchPath({
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd: date.clone().endOf(periodType).format(API_DATE_FORMAT),
      highlightedDate: undefined,
    });
  };

  handlerChangeEndMobile = (date) => {
    const { anyDateOfPeriodStart: anyDateOfPeriodStartFromProps } = this.props;
    const { periodType, anyDateOfPeriodStart: anyDateOfPeriodStartFromState } = this.state;

    const anyDateOfPeriodStart = anyDateOfPeriodStartFromState || anyDateOfPeriodStartFromProps;

    // Устанавливаем дату конца, в зависимости от конца периода (неделя/месяц) выбранной даты
    this.setState({
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd: date.clone().endOf(periodType).format(API_DATE_FORMAT),
      highlightedDate: undefined,
    });
  };

  handlerShowResults = () => {
    const {
      anyDateOfPeriodStart: anyDateOfPeriodStartFromProps,
      anyDateOfPeriodEnd: anyDateOfPeriodEndFromProps,
    } = this.props;

    const {
      periodType,
      anyDateOfPeriodStart: anyDateOfPeriodStartFromState,
      anyDateOfPeriodEnd: anyDateOfPeriodEndFromState,
    } = this.state;

    const anyDateOfPeriodStart = anyDateOfPeriodStartFromState || anyDateOfPeriodStartFromProps;
    const anyDateOfPeriodEnd = anyDateOfPeriodEndFromState || anyDateOfPeriodEndFromProps;

    this.setState({ mobileMenuOpened: false });

    this.setNewSearchPath({
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,
    });
  };

  handlerOnInputClick = () => this.setState({ highlightedDate: 'start' });

  handlerClickOutside = () => this.setState({ highlightedDate: undefined });

  handlerArrowClick = (operation, startDate, endDate, periodType) => {
    // Если даты находятся в одном периоде (например для одной недели пн и вс), то diff будет 0. В этом случае берём единицу.
    const periodDiff = endDate.diff(startDate, periodType) || 1;

    const newStartDate = operation === 'subtract' ? startDate.subtract(periodDiff, periodType) : startDate.add(periodDiff, periodType);
    const newEndDate = operation === 'subtract' ? endDate.subtract(periodDiff, periodType) : endDate.add(periodDiff, periodType);

    this.setNewSearchPath({
      anyDateOfPeriodStart: newStartDate.clone().format(API_DATE_FORMAT),
      anyDateOfPeriodEnd: newEndDate.clone().format(API_DATE_FORMAT),
    });
  };

  handlerCloseMenu = () => this.setState({
    periodType: this.props.periodType,
    anyDateOfPeriodStart: null,
    anyDateOfPeriodEnd: null,
    highlightedDate: undefined,
    mobileMenuOpened: false,
  });

  handlerOpenMenu = () => this.setState({
    mobileMenuOpened: true,
  });

  renderDateRange = (startOfPeriod, endOfPeriod, highlightedDate) => (
    <span>
      <span
        className={classnames({ [styles.highlightedDate]: highlightedDate === 'start' })}
      >
        {startOfPeriod}
      </span>
      {' – '}
      <span
        className={classnames({ [styles.highlightedDate]: highlightedDate === 'end' })}
      >
        {endOfPeriod}
      </span>
    </span>
  );

  renderRangePicker = (isPhone = false) => {
    const {
      withoutRangePicker,
      timezone, plantingCycleId,
      anyDateOfPeriodStart: anyDateOfPeriodStartFromProps,
      anyDateOfPeriodEnd: anyDateOfPeriodEndFromProps,
      forcedMaxDate,
      withoutAddDisable,
      withoutArrow,
      dateButtonClassName,
      firstDateFormat,
      secondDateFormat,
      enableFutureDates,
      disabled,
    } = this.props;

    const { periodType, highlightedDate } = this.state;
    const {
      anyDateOfPeriodStart: anyDateOfPeriodStartFromState,
      anyDateOfPeriodEnd: anyDateOfPeriodEndFromState,
    } = this.state;

    const anyDateOfPeriodStart = anyDateOfPeriodStartFromState || anyDateOfPeriodStartFromProps;
    const anyDateOfPeriodEnd = anyDateOfPeriodEndFromState || anyDateOfPeriodEndFromProps;

    // Берём начало и конец периода, тк данные прилетают по периодам
    const startDate = moment(anyDateOfPeriodStart, API_DATE_FORMAT).clone().startOf(periodType);
    let endDate = moment(anyDateOfPeriodEnd, API_DATE_FORMAT).clone().endOf(periodType);

    if (endDate.isBefore(startDate)) {
      endDate = startDate.clone().add(1, 'days');
    }

    const startOfPeriod = startDate ? startDate.format(getDateFormat(firstDateFormat)) : null;
    const endOfPeriod = endDate ? endDate.format(getDateFormat(secondDateFormat)) : null;

    // Ограничение по минимальной начальной дате - три года назад.
    const minStartDate = moment(new Date()).tz(timezone).subtract(3, 'years');

    const todayDate = moment(new Date()).startOf('day');

    const addArrowDisabled = endDate >= todayDate;
    const isAddArrowDisabled = withoutAddDisable ? false : addArrowDisabled;

    const endOfCurrentPeriod = todayDate.clone().endOf(periodType);

    const maxDateForPicker = endDate > endOfCurrentPeriod ? endDate : endOfCurrentPeriod;

    const isDataPickerDisabled = disabled || !!plantingCycleId;

    const pickerMaxDateSetup = enableFutureDates ? {} : {
      maxDate: forcedMaxDate || maxDateForPicker
    };

    return (
      <div className={styles.customDatePickerWrapper}>
        {!withoutArrow && (
          <button
            type='button'
            className={classnames(styles.leftButton, { [styles.disabled]: isDataPickerDisabled })}
            onClick={() => this.handlerArrowClick('subtract', startDate, endDate, periodType)}
            disabled={isDataPickerDisabled}
          >
            <LeftIcon />
          </button>
        )}

        <DatePicker
          disabled={isDataPickerDisabled}
          selected={startDate}
          minDate={minStartDate}
          {...pickerMaxDateSetup}
          customInput={( // Обёртка из div нужна, т.к. react-datepicker прокидывает свой onClick, который перезапишет наш обработчик
            <div>
              <DateButton
                className={classnames({ [styles.dateButtonFocused]: !!highlightedDate }, dateButtonClassName)}
                date={this.renderDateRange(startOfPeriod, endOfPeriod, highlightedDate)}
                onClick={this.handlerOnInputClick}
                disabled={isDataPickerDisabled}
              />
            </div>
          )}
          onSelect={this.handlerChangeStart}
          // onInputClick={this.handlerTst}
          onClickOutside={this.handlerClickOutside}
          popperPlacement='bottom'
          popperModifiers={{
            offset: {
              enabled: true,
              offset: withoutRangePicker ? '-73px, 0' : '-147px, 0',
            },
          }}
        />

        <div className={styles.secondDatePicker}>
          <DatePicker
            disabled={isDataPickerDisabled}
            ref={(datepicker) => {
              this.endDatepicker = datepicker;
            }}
            selected={endDate}
            minDate={startDate}
            {...pickerMaxDateSetup}
            customInput={<div className={styles.invisibleDatepickerButton} />}
            onSelect={isPhone ? this.handlerChangeEndMobile : this.handlerChangeEnd}
            onClickOutside={this.handlerClickOutside}
            popperPlacement='bottom-end'
            popperModifiers={{
              offset: {
                enabled: true,
                offset: '0, 7px',
              },
            }}
          />
        </div>
        {!withoutArrow && (
          <button
            type='button'
            className={classnames(styles.rightButton, { [styles.disabled]: !!plantingCycleId || isAddArrowDisabled })}
            onClick={() => this.handlerArrowClick('add', startDate, endDate, periodType)}
            disabled={isDataPickerDisabled || isAddArrowDisabled}
          >
            <RightIcon />
          </button>
        )}
      </div>
    );
  };

  render() {
    const {
      intl,
      customRanges,
      withoutRangePicker,
      className,
    } = this.props;
    const { formatMessage } = intl;

    const {
      periodType, mobileMenuOpened, isPhone,
    } = this.state;

    const defaultRanges = [
      {
        label: formatMessage({ id: 'dashboards.weekly' }),
        value: 'week',
      },
      {
        label: formatMessage({ id: 'dashboards.monthly' }),
        value: 'month',
      },
    ];

    return (
      <div className={classnames(styles.rangePickerContainer, className)}>
        {isPhone ? (
          <div className={styles.mobileRangePickerContainer}>
            <MobileMenu
              className={styles.mobileMenu}
              icon={<CalendarIcon />}
              headerText={formatMessage({ id: 'harvest.selectPeriod' })}
              onClose={this.handlerCloseMenu}
              onOpen={this.handlerOpenMenu}
              menuOpened={mobileMenuOpened}
            >
              {!withoutRangePicker ? (
                <BigSelectInput
                  className={styles.mobilePeriodSelect}
                  placeholder={formatMessage({ id: 'harvest.period' })}
                  options={customRanges || defaultRanges}
                  value={periodType}
                  onChange={this.handlerRangeSelectMobile}
                  labelPath='label'
                  valuePath='value'
                  closeOnChange
                />
              ) : null}
              {this.renderRangePicker(isPhone)}
              <div className={styles.mobileControls}>
                <BigButton
                  className={styles.cancelButton}
                  title={formatMessage({ id: 'button.cancel' })}
                  type='button'
                  onClick={this.handlerCloseMenu}
                  theme='light'
                />
                <BigButton
                  title={formatMessage({ id: 'cropsPerformance.showResults' })}
                  type='button'
                  onClick={this.handlerShowResults}
                  theme='dark'
                />
              </div>
            </MobileMenu>
          </div>
        ) : (
          <div className={styles.desktopRangePickerContainer}>
            {!withoutRangePicker ? (
              <Select
                className={styles.select}
                classNameButton={styles.selectButton}
                classNamePopup={styles.selectPopup}
                options={customRanges || defaultRanges}
                value={periodType}
                onChange={this.handlerRangeSelect}
                labelPath='label'
                valuePath='value'
                closeOnChange
              />
            ) : null}

            {this.renderRangePicker(isPhone)}
          </div>
        )}
      </div>
    );
  }
}
