import { connect } from 'react-redux';

import { showNotificationWithTimeout } from 'store/notificationCenter/actions';

import {
  getAllFruitClasses,
  getAllPlantingCycles,
  getAllSubNodes,
  getAllVarieties,
  getCurrentCompartments,
  getCurrentSupportedSpecies
} from 'store/company/selectors';

import { getAvailableNodeIds, getMetricsSelector } from 'store/graphs/selectors';

import MetricGroup, { MetricGroupPassedProps } from './MetricGroup';


const mapStateToProps = (state: any) => ({
  ...state.intl,
  allMetrics: getMetricsSelector(state),
  allCompartments: getCurrentCompartments(state),
  allSubNodes: getAllSubNodes(state),
  allSpecies: getCurrentSupportedSpecies(state),
  allVarieties: getAllVarieties(state),
  allFruitClasses: getAllFruitClasses(state),
  allPlantingCycles: getAllPlantingCycles(state),
  availableNodeIds: getAvailableNodeIds(state)
});

const mapDispatchToProps = {
  showNotificationWithTimeout
};

export default connect<{}, {}, MetricGroupPassedProps>(mapStateToProps, mapDispatchToProps)(MetricGroup);
