import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { uniqueId } from 'lodash/util';

import styles from './Modal.module.css';

const ModalImpl = ({
  children,
  isOpen
}) => {
  const [id] = useState(uniqueId('pylot_modal-id-'));

  const modalRoot = document.querySelector('.layout__main_root');

  const rootEl = useMemo(() => {
    const modalRootData = document.querySelector(`.${id}`);
    if (!modalRootData) {
      const newRoot = document.createElement('div');
      newRoot.classList.add(`${id}`);
      return newRoot;
    }
    return modalRootData;
  }, [id]);

  useEffect(() => {
    if (!rootEl || !modalRoot) {
      return;
    }
    if (isOpen) {
      modalRoot.appendChild(rootEl);
      document.body.style.overflowY = 'hidden';
    } else if (modalRoot.contains(rootEl)) {
      modalRoot.removeChild(rootEl);
      document.body.style.overflowY = 'auto';
    }
  }, [isOpen, rootEl, modalRoot]);

  return rootEl ? ReactDOM.createPortal(
    children,
    rootEl
  ) : null;
};


ModalImpl.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  isOpen: PropTypes.bool,
};

ModalImpl.defaultProps = {
  isOpen: false,
};

const Modal = ({
  children,
  isOpen
}) => (
  <ModalImpl isOpen={isOpen}>
    <div className={styles.background}>
      {children}
    </div>
  </ModalImpl>
);

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  isOpen: PropTypes.bool,
};

Modal.defaultProps = {
  isOpen: false,
};

export default Modal;
