import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import classnames from 'classnames';

import styles from './DashboardParameter.module.css';

export default class DashboardParameter extends Component {
  static propTypes = {
    headerText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]).isRequired,
    values: PropTypes.array,
    containerClassName: PropTypes.string,

    rightContent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    bottomContent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
  };

  static defaultProps = {
    values: null,
    containerClassName: null,
    rightContent: null,
    bottomContent: null,
  };

  render() {
    const {
      headerText, values, rightContent, bottomContent, containerClassName
    } = this.props;

    return (
      <div
        className={classnames(
          styles.parameterParam,
          containerClassName,
          { [styles.withoutRightContent]: (!rightContent && !bottomContent) },
        )}
      >
        <div className={styles.parameterHeader}>
          {headerText}
        </div>
        <div className={styles.parameterValueWrapper}>
          {values.map((value, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`value-${key}`} className={styles.parameterValueContent}>
              <div className={styles.parameterValue}>
                <span className={styles.parameterValueAmount}>{get(value, 'value', null)}</span>
                <span className={styles.parameterValueUnits}>{get(value, 'units', null)}</span>
                {rightContent && <span className={styles.rightContent}>{rightContent}</span>}
              </div>
              {bottomContent &&
              <div>{bottomContent}</div>}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
