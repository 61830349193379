import React from 'react';
import { get } from 'lodash';
import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';
import BreakdownTooltipGraph from 'components/EnergyDashboard/components/BreakdownTooltipGraph';

const renderTooltipContent = (currentItem, props) => {
  const {
    labelPath,
    valuePath,
    items,
    tooltipTitle,
    intl: { formatMessage },
    roundTo,
  } = props;

  const item = items.find(x => get(x, labelPath) === currentItem);

  if (!tooltipTitle || !item) {
    return null;
  }

  const rawValue = get(item, valuePath);
  const units = formatMessage({ id: `cunits.mini.${item?.units}` });

  const generatedLines = [
    {
      id: 'line',
      value: rawValue ?
        `${numbersFormatting(numbersRounding(rawValue, 'fixed', roundTo || 1))}${units ? ' ' : ''}${units}` :
        formatMessage({ id: 'crops.noData' }),
      header: item.label,
      color: '#61CFE9',
    },
  ];

  return (
    <BreakdownTooltipGraph
      lines={generatedLines}
      tooltipTitle={tooltipTitle}
    />
  );
};

export default renderTooltipContent;
