import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import queryString from 'query-string';
import { get } from 'lodash';
import FileSaver from 'file-saver';

import initAxiosInstanse from '../../api/axios';

import { showNotification, showNotificationWithTimeout, hideNotification } from '../notificationCenter/actions';

import {
  requestPlanFactReport,
  receivePlanFactReport,

  requestPlanFactGraph,
  receivePlanFactGraph,

  requestPlanFactV2Report,
  receivePlanFactV2Report,

  requestPlanFactExport,
  receivePlanFactExport,
} from './actions';

export const getPlanFactEpic = (action$, store) =>
  action$.ofType(requestPlanFactReport)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          date,
          period,
          species,
          breakdown,
          graphKey,
          units,
          locationPlan,
        }
      } = actionData;

      const queryParams = queryString.stringify({
        date,
        period,
        species,
        breakdown,
        graphKey,
        relative: units ? units === 'relative' : true,
        locationPlan,
      });

      const query = queryParams ? `&${queryParams}` : '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`plan-fact/${organizationId}/report?${query}`))
        .map(({ data }) => receivePlanFactReport({ planFact: data }))
        .catch(() => Observable.of(
          receivePlanFactReport(),
          showNotificationWithTimeout({
            id: `notifications.getPlanFactError.${Date.now()}`,
            messageId: 'notifications.getPlanFactError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getPlanFactGraphEpic = (action$, store) =>
  action$.ofType(requestPlanFactGraph)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          date,
          period,
          species,
          graphKey,
          units,
          locationPlan,
        }
      } = actionData;

      const queryParams = queryString.stringify({
        date,
        period,
        species,
        graphKey,
        relative: units ? units === 'relative' : true,
        locationPlan,
      });

      const query = queryParams ? `&${queryParams}` : '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`plan-fact/${organizationId}/graph?${query}`))
        .map(({ data }) => receivePlanFactGraph({ graph: data }))
        .catch(() => Observable.of(
          receivePlanFactGraph(),
          showNotificationWithTimeout({
            id: `notifications.getPlanFactError.${Date.now()}`,
            messageId: 'notifications.getPlanFactError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getPlanFactV2Epic = (action$, store) =>
  action$.ofType(requestPlanFactV2Report)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          period,
          species,
          varietyId,
          fruitClassCode,
          breakdown,
          units,
          locationPlan,
          compartmentId,
          anyDateOfPeriodStart,
          anyDateOfPeriodEnd
        }
      } = actionData;

      const queryParams = queryString.stringify({
        period,
        species,
        varietyId,
        fruitClassCode,
        breakdown,
        relative: units ? units === 'relative' : true,
        locationPlan,
        compartmentId,

        startDate: anyDateOfPeriodStart,
        endDate: anyDateOfPeriodEnd,
      });

      const query = queryParams ? `&${queryParams}` : '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`plan-fact/v2/${organizationId}/report?${query}`))
        .map(({ data }) => receivePlanFactV2Report({ planFact: data }))
        .catch(() => Observable.of(
          receivePlanFactV2Report(),
          showNotificationWithTimeout({
            id: `notifications.backendError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const showPlanFactExportNotificationEpic = action$ =>
  action$
    .ofType(requestPlanFactExport)
    .mergeMap(() => Observable.of(
      showNotification({
        id: 'notifications.downloadPlanFactXSLX',
        messageId: 'notifications.downloadPlanFactXSLX',
        iconType: 'loading',
        notificationType: 'withAction',
      })
    ));

export const getPlanFactExportEpic = (action$, store) =>
  action$.ofType(requestPlanFactExport)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          period,
          species,
          breakdown,
          units,
          locationPlan,
          compartmentId,
          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
          actionAfterSuccess = () => {},
        }
      } = actionData;

      const queryParams = queryString.stringify({
        period,
        species,
        breakdown,
        relative: units ? units === 'relative' : true,
        locationPlan,
        compartmentId,

        startDate: anyDateOfPeriodStart,
        endDate: anyDateOfPeriodEnd,
      });

      const query = queryParams ? `&${queryParams}` : '';

      return Observable
        .from(
          initAxiosInstanse(
            searchParams,
            120000,
            {
              credentials: 'include',
              responseType: 'blob',
            }
          )
            .get(`plan-fact/v2/${organizationId}/export?${query}`)
        )
        .mergeMap((response) => {
          const fileName = get(response, 'headers[x-filename]');

          FileSaver.saveAs(response.data, fileName);

          return Observable.of(
            actionAfterSuccess,
            hideNotification('notifications.downloadPlanFactXSLX'),
            receivePlanFactExport(),
            showNotificationWithTimeout({
              id: 'notifications.planFactXSLXDownloaded',
              messageId: 'notifications.planFactXSLXDownloaded',
              iconType: 'success',
              notificationType: 'withAction',
            })
          );
        })
        // .map(({ data }) => receivePlanFactExport({ planFact: data }))
        .catch(() => Observable.of(
          hideNotification('notifications.downloadPlanFactXSLX'),
          receivePlanFactExport(),
          showNotificationWithTimeout({
            id: `notifications.planFactXSLXDownloadError.${Date.now()}`,
            messageId: 'notifications.planFactXSLXDownloadError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export default combineEpics(
  getPlanFactEpic,
  getPlanFactGraphEpic,
  getPlanFactV2Epic,
  showPlanFactExportNotificationEpic,
  getPlanFactExportEpic,
);
