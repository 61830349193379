import { useState, useCallback } from 'react';

const useChartTypeToggle = (initialValue) => {
  const [isToggleOn, setToggle] = useState(initialValue);
  const updateToggle = useCallback(() => {
    setToggle(!isToggleOn);
  }, [isToggleOn]);
  return [
    isToggleOn,
    updateToggle
  ];
};

export default useChartTypeToggle;
