import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getLogin } from '../../store/company/actions';

import OrganizationRoutes from './OrganizationRoutes';

const mapDispatchToProps = {
  getLogin,
};

const mapStateToProps = (state) => {
  const {
    company,
  } = state;

  return {
    locations: company.locations,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationRoutes));
