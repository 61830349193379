import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import moment from 'moment-timezone';
import { get, has } from 'lodash';

import LINE_COLORS from 'helpers/graphColors';

import ResultLineChart from '../ResultLineChart';

import styles from './DigitalTwinGraphs.module.css';

const DigitalTwinGraphs = ({
  intl,
  isDataFetching,
  calculatedResult,
}) => {
  const { formatMessage } = intl;

  const [screenX, setScreenX] = useState(null);
  const [screenY, setScreenY] = useState(null);
  const [closestPointCoordX, setClosestPointCoordX] = useState(null);
  const [tooltipDate, setTooltipDate] = useState(null);
  const [showTooltip, setShowTooltip] = useState(null);


  const generateLineByPoints = useCallback((dataPoints) => {
    if (dataPoints) {
      return Object.keys(dataPoints).reduce((acc, key) => {
        const date = moment.utc(new Date((+key) * 60000));

        return [...acc, { x: date.format('MM-DD-YYYY'), y: dataPoints[key] }];
      }, []);
    }
    return [];
  }, []);

  const getLines = useCallback((lineChart, result) => ([{
    key: lineChart.id,
    name: lineChart.title,
    points: generateLineByPoints(result?.points?.points),
    color: LINE_COLORS[0],
    units: result?.points?.units,
  }]), [generateLineByPoints]);

  const handlerTooltipChanged = (options) => {
    /**
     * Проверка через has, т.к. в поле может быть булевое значение
     * Устанавливаем отдельно и с проверкой, т.к. в options не приходят необновлённые поля,
     * из-за чего будет обнуление предыдущих значений, если устанавливать каждый раз
     */
    if (has(options, 'screenX')) {
      setScreenX(options.screenX);
    }

    if (has(options, 'screenY')) {
      setScreenY(options.screenY);
    }

    if (has(options, 'closestPointCoordX')) {
      setClosestPointCoordX(options.closestPointCoordX);
    }

    if (has(options, 'tooltipDate')) {
      setTooltipDate(options.tooltipDate);
    }

    if (has(options, 'showTooltip')) {
      setShowTooltip(options.showTooltip);
    }
  };


  const lineCharts = useMemo(() => ([
    {
      id: 'harvest',
      title: formatMessage({ id: 'dashboards.harvest' }),
      defaultUnits: 'kilogramPerSquareMeter',
      roundTo: 1,
    },
    {
      id: 'fruitWeight',
      title: formatMessage({ id: 'cropModel.harvestedFruitWeight' }),
      defaultUnits: 'gram',
      roundTo: 0,
    },
    {
      id: 'floweringSpeed',
      title: formatMessage({ id: 'cropModel.floweringSpeed' }),
      defaultUnits: 'trussesPerStemPerWeeek',
      roundTo: 1,
    },
    {
      id: 'fruitsPerTruss',
      title: formatMessage({ id: 'cropModel.fruitsPerTruss' }),
      defaultUnits: 'count',
      roundTo: 0,
    },
    {
      id: 'ripeningTime',
      title: formatMessage({ id: 'cropModel.ripeningTime' }),
      defaultUnits: 'days',
      roundTo: 1,
    },
    {
      id: 'fruitLoadPerM2',
      title: formatMessage({ id: 'cropModel.fruitLoadPerM2' }),
      defaultUnits: 'piecesPerSquareMeter',
      roundTo: 1,
    },
    {
      id: 'stemGrowth',
      title: formatMessage({ id: 'cropModel.stemGrowth' }),
      defaultUnits: 'centimeter',
      roundTo: 0,
    },
    // {
    //   id: 'fruitGrowth',
    //   title: formatMessage({ id: 'cropModel.fruitGrowth' }),
    //   defaultUnits: 'gramPerSquareMeterPerDay',
    //   roundTo: 1,
    // },
  ]), [formatMessage]);

  const lineChartsWithValues = useMemo(() => lineCharts.map(lineChart => ({
    ...lineChart,
    lines: getLines(lineChart, get(calculatedResult, lineChart.id)),
    values: get(calculatedResult, lineChart.id),
  })), [lineCharts, calculatedResult, getLines]);

  return (
    <div className={styles.lineCharts}>
      {lineChartsWithValues.map(lineChart => (
        <div key={`digitalTwinLineChart-${lineChart.id}`} className={styles.lineChartWrapper}>
          <ResultLineChart
            isDataFetching={isDataFetching}
            title={lineChart.title}
            lines={lineChart.lines}
            avgValue={lineChart.values?.weeklyAvg?.amount}
            totalValue={lineChart.values?.total?.amount}
            maxValue={lineChart.values?.max?.amount}
            units={lineChart.values?.total?.units || lineChart.defaultUnits}
            roundTo={lineChart.roundTo}
            onTooltipChanged={handlerTooltipChanged}
            screenX={screenX}
            closestPointCoordX={closestPointCoordX}
            tooltipDate={tooltipDate}
            showTooltip={showTooltip}
            screenY={screenY}
          />
        </div>
      ))}
    </div>
  );
};

DigitalTwinGraphs.propTypes = {
  intl: intlShape.isRequired,
  isDataFetching: PropTypes.bool,
  calculatedResult: PropTypes.object,
};

DigitalTwinGraphs.defaultProps = {
  isDataFetching: false,
  calculatedResult: null,
};

export default injectIntl(DigitalTwinGraphs);
