import { handleActions } from 'redux-actions';

import {
  requestSimulationById,
  receiveSimulationById,

  requestSaveSimulationById,
  receiveSaveSimulationById,

  requestDeleteSimulationById,
  receiveDeleteSimulationById,

  requestSimulationsList,
  receiveSimulationsList,

  requestNewSimulationId,
  receiveNewSimulationId,

  requestNewSimulationParams,
  receiveNewSimulationParams,

  setCalculateInProgress,
  requestSimulationCalculate,
  receiveSimulationCalculate,

  requestSupportedVarieties,
  receiveSupportedVarieties,

  requestSaveSimulation,
  receiveSaveSimulation,

  setSimulationEditedData,
  resetSimulationEditedData,

  requestSaveSimulationByIdWithoutParams,
  receiveSaveSimulationByIdWithoutParams,

  requestSimulationDatesChange,
  receiveSimulationDatesChange,
} from './actions';

const initialState = {
  isSimulationFetching: false,
  isSimulationSaving: false,
  isSimulationDeleting: false,
  isSimulationsListFetching: false,
  isNewSimulationIdFetching: false,
  isNewSimulationParamsFetching: false,
  isSimulationCalculated: false,
  isSupportedVarietiesFetching: false,
  isSimulationDatesChangeFetching: false,

  simulationsList: undefined,
  newSimulationId: undefined,
  supportedVarieties: undefined,

  currentSimulation: null,
  currentSimulationEdited: null,
};

function combineSeries(dst, src) {
  const baseKeys = Object.keys(dst);
  return baseKeys.reduce((memo, key) => ({
    ...memo,
    [key]: src[key] || dst[key],
  }), {});
}

export default handleActions({
  [requestSimulationById](state) {
    return {
      ...state,
      isSimulationFetching: true,
    };
  },
  [receiveSimulationById](state, action) {
    return {
      ...state,
      isSimulationFetching: false,
      currentSimulation: action.payload,
      currentSimulationEdited: null,
    };
  },
  [requestSaveSimulationByIdWithoutParams](state) {
    return {
      ...state,
      isSimulationSaving: true,
    };
  },
  [receiveSaveSimulationByIdWithoutParams](state) {
    return {
      ...state,
      isSimulationSaving: false,
    };
  },
  [requestSaveSimulationById](state) {
    return {
      ...state,
      isSimulationSaving: true,
    };
  },
  [receiveSaveSimulationById](state, action) {
    return {
      ...state,
      isSimulationSaving: false,
      currentSimulation: action.payload,
      currentSimulationEdited: null,
    };
  },
  [requestSaveSimulation](state) {
    return {
      ...state,
      isSimulationSaving: true,
    };
  },
  [receiveSaveSimulation](state) {
    return {
      ...state,
      isSimulationSaving: false,
    };
  },
  [requestDeleteSimulationById](state) {
    return {
      ...state,
      isSimulationDeleting: true,
    };
  },
  [receiveDeleteSimulationById](state) {
    return {
      ...state,
      isSimulationDeleting: false,
    };
  },
  [requestSimulationsList](state) {
    return {
      ...state,
      isSimulationsListFetching: true,
    };
  },
  [receiveSimulationsList](state, action) {
    return {
      ...state,
      isSimulationsListFetching: false,
      simulationsList: action.payload,
    };
  },
  [receiveNewSimulationId](state) {
    return {
      ...state,
      isNewSimulationIdFetching: true,
    };
  },
  [requestNewSimulationId](state, action) {
    return {
      ...state,
      isNewSimulationIdFetching: false,
      newSimulationId: action.payload, // возможно не нужно
    };
  },
  [requestNewSimulationParams](state) {
    return {
      ...state,
      isNewSimulationParamsFetching: true,
    };
  },
  [receiveNewSimulationParams](state, action) {
    const {
      currentSimulationEdited,
      currentSimulation
    } = state;

    // Принудительный ресет локации (используется, например, при кнопке new)
    const withReset = action?.payload?.withReset;

    const simulation = currentSimulationEdited || currentSimulation;

    if (!simulation || withReset) {
      return {
        ...state,
        isNewSimulationParamsFetching: false,
        currentSimulation: {
          name: null,
          description: '',
          varietyId: null,
          params: action.payload.data,
        },
        currentSimulationEdited: null,
      };
    }

    const params = action.payload.data;
    return {
      ...state,
      isNewSimulationParamsFetching: false,
      currentSimulationEdited: {
        ...simulation,
        params: {
          ...params,
          artificialLight: {
            ...simulation.params.artificialLight,
            points: combineSeries(
              params.artificialLight.points,
              simulation.params.artificialLight.points
            )
          },
          temperature: {
            ...simulation.params.temperature,
            points: combineSeries(
              params.temperature.points,
              simulation.params.temperature.points
            )
          },
          co2: {
            ...simulation.params.co2,
            points: combineSeries(
              params.co2.points,
              simulation.params.co2.points
            )
          },
          plantDensity: {
            ...simulation.params.plantDensity,
            points: combineSeries(
              params.plantDensity.points,
              simulation.params.plantDensity.points
            )
          },
          sunLight: {
            ...simulation.params.sunLight,
            pointsByEntryType: {
              ...simulation.params.sunLight.pointsByEntryType,
              custom: combineSeries(
                params.sunLight.pointsByEntryType.custom,
                simulation.params.sunLight.pointsByEntryType.custom
              ),
              fromExternalProvider: combineSeries(
                params.sunLight.pointsByEntryType.fromExternalProvider,
                simulation.params.sunLight.pointsByEntryType.fromExternalProvider
              )
            }
          }
        }
      }
    };
  },
  [setCalculateInProgress](state) {
    return {
      ...state,
      isSimulationCalculated: true,
    };
  },
  [requestSimulationCalculate](state) {
    return {
      ...state,
      isSimulationCalculated: true,
    };
  },
  [receiveSimulationCalculate](state, action) {
    return {
      ...state,
      isSimulationCalculated: false,
      currentSimulationEdited: {
        ...state.currentSimulationEdited,

        result: action.payload,
      }
    };
  },
  [requestSupportedVarieties](state) {
    return {
      ...state,
      isSupportedVarietiesFetching: true,
    };
  },
  [receiveSupportedVarieties](state, action) {
    return {
      ...state,
      isSupportedVarietiesFetching: false,
      supportedVarieties: action.payload,
    };
  },
  [setSimulationEditedData](state, action) {
    return {
      ...state,
      currentSimulationEdited: action.payload,
    };
  },
  [resetSimulationEditedData](state) {
    return {
      ...state,
      currentSimulationEdited: null,
    };
  },
  [requestSimulationDatesChange](state) {
    return {
      ...state,
      isSimulationDatesChangeFetching: true,
    };
  },
  [receiveSimulationDatesChange](state) {
    return {
      ...state,
      isSimulationDatesChangeFetching: false,
    };
  },
}, initialState);
