import React from 'react';
import { isNil } from 'lodash';

import Link from 'components/Link';

import { getRowName } from 'helpers/getRowName';
import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';

// eslint-disable-next-line import/prefer-default-export
export function getTableBodyRow({
  tableRow,
  intl,
  workTypes,
  compartments,
  fruitClasses,
  varieties,
  organizationSlug,
  productGroups,
  allPlantingCycles,
  currentUnitKind,
  currentBreakdown,
  currentSpecies,
  currentFruitClassCode,
  currentVarietyId,
  roundTo: forcedRoundTo,
  formatCell,
}) {
  let roundTo = currentUnitKind === 'absolute' ? 1 : 2;

  if (!isNil(forcedRoundTo)) {
    roundTo = forcedRoundTo;
  }

  const rowName = getRowName({
    type: tableRow.type,
    ref: tableRow.ref,
    intl,
    workTypes,
    compartments,
    fruitClasses,
    varieties,
    productGroups,
    allPlantingCycles,
    currentBreakdown,
    currentSpecies,
    currentFruitClassCode,
    currentVarietyId,
  });
  let label = rowName;

  if (tableRow?.ref?.type === 'PlantingCycle') {
    label = (
      <Link href={`/${organizationSlug}/crops/${tableRow.ref.id}`}>
        {rowName}
      </Link>
    );
  }

  return ({
    key: tableRow.key,
    label,
    columns: Object.keys(tableRow.data).map((columnKey) => {
      let cellValue;

      if (tableRow.data[columnKey]) {
        const rawCellValue = numbersFormatting(numbersRounding(tableRow.data[columnKey], 'fixed', roundTo));

        if (formatCell) {
          cellValue = formatCell({ value: rawCellValue });
        } else {
          cellValue = rawCellValue;
        }
      } else {
        cellValue = '–';
      }

      return ({
        label: cellValue,
      });
    }),

    labelAlign: 'left',
    rowStyle: tableRow.type === 'total' ? 'bold' : 'normal',
  });
}

export default getTableBodyRow;
