import React from 'react';
import { intlShape, injectIntl } from 'react-intl';

import Typography from 'components/Typography';

import styles from './EditModeDisclaimer.module.css';

const EditModeDisclaimer = ({
  intl,
}) => {
  const { formatMessage } = intl;

  return (
    <div className={styles.layout}>
      <div className={styles.content}>
        <Typography variant='h2' className={styles.header}>
          {formatMessage({ id: 'forecast.forecastEditMode' })}
        </Typography>
        <Typography variant='body1' className={styles.text}>
          {formatMessage({ id: 'forecast.forecastEditModeDisclaimer' })}
        </Typography>
      </div>
    </div>
  );
};

EditModeDisclaimer.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(EditModeDisclaimer);
