import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { get, find, uniq } from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import checkSpecies from 'helpers/checkSpecies';
import Select from 'components/Select';
import VarietySearchSelect from 'components/VarietySearchSelect';
import /* useQuery, */ { getQueryValue } from 'hooks/useQuery/useQuery';
import { getMlName, getVarietyName } from 'helpers/getVarietyName';
import updateLocationSearch from 'helpers/updateLocationSearch';
import styles from './VarietyFilter.module.css';

const joinParams = (paramsArray) => {
  if (Array.isArray(paramsArray) && paramsArray.length > 1) {
    return paramsArray.join(',');
  }

  if (Array.isArray(paramsArray) && paramsArray.length === 1) {
    return paramsArray[0];
  }

  return paramsArray;
};

function getFilterTitle({
  currentVarietyId,
  currentSpecies,
  currentFruitClassCode
}, {
  withCheckBox,
  formatMessage,
  locale,
  varieties,
  fruitClasses,
  // species
}) {
  const species = uniq(varieties.map(item => item.attributes.species));

  if (withCheckBox) {
    const varietyIds = (currentVarietyId ? currentVarietyId.split(',') : []).map(item => +item);
    const fruitClassCodeIds = (currentFruitClassCode ? currentFruitClassCode.split(',') : []);
    const speciesIds = (currentSpecies ? currentSpecies.split(',') : []);

    if (varietyIds.length === 0 && fruitClassCodeIds.length === 0 && speciesIds.length === 0) {
      return formatMessage({ id: 'dashboards.allSpecies' });
    }

    if (varietyIds.length > 1) {
      // dashboards
      return `${varietyIds.length} ${formatMessage({ id: 'dashboards.plants' })}`;
    }

    if (varietyIds.length === 1) {
      const variety = varieties.find(item => item.id === +varietyIds[0]);
      return getVarietyName(variety, locale);
    }

    if (fruitClassCodeIds.length > 1) {
      return `${fruitClassCodeIds.length} ${formatMessage({ id: 'dashboards.plants' })}`;
    }

    if (fruitClassCodeIds.length === 1) {
      const fruitClass = fruitClasses.find(item => item.id === fruitClassCodeIds[0]);
      return getMlName(fruitClass, locale);
    }

    if (speciesIds.length === 1) {
      return formatMessage({ id: `plantingCycles.allSpecies.${speciesIds[0]}` });
    }

    if (speciesIds.length === species.length) {
      return formatMessage({ id: 'dashboards.allSpecies' });
    }

    return `${speciesIds.length} ${formatMessage({ id: 'dashboards.plants' })}`;
  }

  if (currentVarietyId) {
    const variety = varieties.find(item => item.id === +currentVarietyId);
    return getVarietyName(variety, locale);
  }
  if (currentFruitClassCode) {
    const fruitClass = fruitClasses.find(item => item.id === currentFruitClassCode);
    return getMlName(fruitClass, locale);
  }
  if (!currentSpecies) {
    return formatMessage({ id: 'dashboards.allSpecies' });
  }

  return formatMessage({ id: `plantingCycles.allSpecies.${currentSpecies}` });
}

function urlToFilterOptions({
  currentVarietyId,
  currentSpecies,
  currentFruitClassCode
}, {
  varieties,
  fruitClasses,
  // species,
  withCheckBox
}) {
  // Если выбран species, добавляем все соответствующие с ним varieties и fruitClasses
  // Если выбран fruitClass, добавляем все связанные с ним varieties
  if (withCheckBox) {
    const varietyIds = (currentVarietyId ? currentVarietyId.split(',') : []).map(item => +item);
    const fruitClassCodeIds = (currentFruitClassCode ? currentFruitClassCode.split(',') : []);
    const speciesIds = (currentSpecies ? currentSpecies.split(',') : []);

    const selectedSpeciesIds = [];
    const selectedFruitClassIds = [];
    const selectedVarietiesIds = [];

    if (speciesIds.length > 0) {
      for (let i = 0; i < speciesIds.length; i += 1) {
        const selectedVarieties = varieties
          .filter(item => item.attributes.species === speciesIds[i])
          .map(item => +item.id);
        const selectedFruitClass = fruitClasses
          .filter(item => item.attributes.species === speciesIds[i])
          .map(item => item.id);
        selectedSpeciesIds.push(speciesIds[i]);
        selectedVarietiesIds.push(...selectedVarieties);
        selectedFruitClassIds.push(...selectedFruitClass);
      }
    }

    if (fruitClassCodeIds.length > 0) {
      for (let i = 0; i < fruitClassCodeIds.length; i += 1) {
        const selectedVarieties = varieties
          .filter(item => item.attributes.fruitClass === fruitClassCodeIds[i])
          .map(item => +item.id);
        selectedFruitClassIds.push(fruitClassCodeIds[i]);
        selectedVarietiesIds.push(...selectedVarieties);
      }
    }

    selectedVarietiesIds.push(...varietyIds);

    return {
      currentVarietyId: selectedVarietiesIds,
      currentSpecies: selectedSpeciesIds,
      currentFruitClassCode: selectedFruitClassIds
    };
  }

  return {
    currentVarietyId,
    currentSpecies,
    currentFruitClassCode
  };
}

function buildSelectedOption({
  currentVarietyId: varietyIds,
  currentSpecies: speciesIds,
  currentFruitClassCode: fruitClassIds
}, {
  withCheckBox,
  varieties,
  fruitClasses,
  species
}) {
  const {
    currentVarietyId,
    currentSpecies,
    currentFruitClassCode
  } = urlToFilterOptions({
    currentVarietyId: varietyIds,
    currentSpecies: speciesIds,
    currentFruitClassCode: fruitClassIds
  }, {
    withCheckBox,
    varieties,
    fruitClasses,
    species
  });
  if (withCheckBox) {
    if (currentVarietyId.length > 0 || currentSpecies.length > 0 || currentFruitClassCode.length > 0) {
      return [
        ...currentVarietyId,
        ...currentSpecies,
        ...currentFruitClassCode,
      ];
    }
    return [
      null,
      ...varieties.map(item => +item.id),
      ...fruitClasses.map(item => item.id),
      ...species,
    ];
  }
  return (currentVarietyId ? Number(currentVarietyId) : undefined) || currentFruitClassCode || currentSpecies;
}

function filterOptionsToUrl({
  selectedVarietyId,
  selectedSpecies,
  selectedFruitClassCode
}, {
  varieties,
  fruitClasses,
  // species
}) {
  // Если выбран species, выкидываем все связанные с ним varieties и fruitClasses
  // Если выбран fruitClass, выкидываем все связанные с ним varieties
  let varietiesIds = [...selectedVarietyId];
  let fruitClassIds = [...selectedFruitClassCode];
  const speciesIds = [...selectedSpecies];
  if (selectedSpecies.length > 0) {
    for (let i = 0; i < selectedSpecies.length; i += 1) {
      const relatedVarieties = varieties
        .filter(item => item.attributes.species === selectedSpecies[i])
        .map(item => +item.id);
      const relatedFruitClass = fruitClasses
        .filter(item => item.attributes.species === selectedSpecies[i])
        .map(item => item.id);

      varietiesIds = varietiesIds.filter(item => !relatedVarieties.includes(item));
      fruitClassIds = fruitClassIds.filter(item => !relatedFruitClass.includes(item));
    }
  }
  if (selectedFruitClassCode.length > 0) {
    for (let i = 0; i < selectedFruitClassCode.length; i += 1) {
      const relatedVarieties = varieties
        .filter(item => item.attributes.fruitClass === selectedFruitClassCode[i])
        .map(item => +item.id);
      varietiesIds = varietiesIds.filter(item => !relatedVarieties.includes(item));
    }
  }
  return {
    species: speciesIds,
    varietyId: varietiesIds,
    fruitClassCode: fruitClassIds,
  };
}

function isAllSpeciesSelect(changedOptions) {
  return !changedOptions?.species && !changedOptions?.varietyId && !changedOptions?.fruitClassCode;
}

function isAllSpecifiedSpeciesSelect(changedOptions) {
  return changedOptions?.species && !changedOptions?.varietyId && !changedOptions?.fruitClassCode;
}

function isFruitClassSpecifiedSelect(changedOptions) {
  return !changedOptions?.varietyId && changedOptions?.fruitClassCode;
}

function isVarietySpecifiedSelect(changedOptions) {
  return changedOptions?.varietyId;
}

function getAllFruitClassesForSpecies(options, species) {
  return options.filter(item => item.type === 'fruitClass' && item.species === species).map(item => item.id);
}

function getAllVarietiesForSpecies(options, species) {
  return options.filter(item => item.type === 'variety' && item.species === species).map(item => item.id);
}

function buildParams(
  changedOptions,
  currentOptions,
  options,
  fruitClasses,
  varieties,
  allSpecies,
  onlyCheck
) {
  const {
    species,
    varietyId,
    fruitClassCode,
  } = changedOptions;

  const {
    currentVarietyId,
    currentSpecies,
    currentFruitClassCode
  } = currentOptions;

  if (isAllSpeciesSelect(changedOptions)) {
    return {
      species: [],
      varietyId: [],
      fruitClassCode: []
    };
  }

  const speciesIds = (currentSpecies ? currentSpecies.split(',') : []);
  const fruitClassCodeIds = (currentFruitClassCode ? currentFruitClassCode.split(',') : []);
  const varietyIds = (currentVarietyId ? currentVarietyId.split(',') : []).map(item => +item);

  const isSelectAll = speciesIds.length === 0 &&
  fruitClassCodeIds.length === 0 &&
  varietyIds.length === 0;

  if (isAllSpecifiedSpeciesSelect(changedOptions)) {
    const allFruitClassesForSpecies = getAllFruitClassesForSpecies(options, species);
    const allVarietyForSpecies = varieties
      .filter(item => item.attributes.species === species)
      .map(item => +item.id);
    if (onlyCheck) {
      return {
        species: [species],
        varietyId: [...allVarietyForSpecies],
        fruitClassCode: [...allFruitClassesForSpecies]
      };
    }
    // Если были выбраны все (считаем что текущий тоже выбран)
    // Выбираем все что не относится к текущему species
    if (isSelectAll) {
      return {
        species: [...allSpecies.filter(item => item !== species)],
        varietyId: [...varieties.filter(item => item.attributes.species !== species).map(item => +item.id)],
        fruitClassCode: [...fruitClasses.filter(item => item.attributes.species !== species).map(item => item.id)],
      };
    }
    if (speciesIds.includes(species)) {
      // Если данный species был выбран
      // убираем все связанные с ним varieties и fruitClasses
      return {
        species: [...speciesIds.filter(item => item !== species)],
        varietyId: [...varietyIds.filter(item => !allVarietyForSpecies.includes(item))],
        fruitClassCode: [...fruitClassCodeIds.filter(item => !allFruitClassesForSpecies.includes(item))]
      };
    }
    // Если данный species не был выбран
    // добавляем все связанные с ним varieties и fruitClasses
    return {
      species: uniq([...speciesIds, species]),
      varietyId: [...allVarietyForSpecies],
      fruitClassCode: [...allFruitClassesForSpecies]
    };
  }

  if (isFruitClassSpecifiedSelect(changedOptions)) {
    if (onlyCheck) {
      const allFruitClassVarieties = varieties
        .filter(item => item.attributes.fruitClass === fruitClassCode)
        .map(item => +item.id);
      return {
        species: [],
        varietyId: [...allFruitClassVarieties],
        fruitClassCode: [fruitClassCode]
      };
    }
    // Если были выбраны все (считаем что текущий тоже выбран)
    // Убираем выбор текущего fruitClass
    // Убираем выбор соответсвующего species и всех variety
    if (isSelectAll) {
      return {
        species: [...allSpecies.filter(item => item !== species)],
        varietyId: [...varieties.filter(item => item.attributes.fruitClass !== fruitClassCode).map(item => +item.id)],
        fruitClassCode: [...fruitClasses.filter(item => item.id !== fruitClassCode).map(item => item.id)],
      };
    }
    const allFruitClassesForSpecies = getAllFruitClassesForSpecies(options, species);
    // fruitClass считается выбраным если:
    // 1. Он выбран непосредственно
    // 2. Если выбран соответствующий ему species
    const isFruitClassSelected = speciesIds.includes(species) || fruitClassCodeIds.includes(fruitClassCode);
    if (isFruitClassSelected) {
      if (speciesIds.includes(species)) {
        // Если был выбран соответствующий species, (случай когда вбрана вся группа)
        // то необходимо выделить все variety не соответстующие данному fruitClassCode
        // убрать этот species, также выделить все остальные fruitClassCode для данного species
        // (исключая текущий)
        const newSpecies = [...speciesIds.filter(item => item !== species)];
        const newFruitClassCodes = uniq([
          // сохраняем выбранные fruitClassCode исключая текущий
          ...fruitClassCodeIds.filter(item => item !== fruitClassCode),
          // выбираем все кроме текущего (с учетом species)
          ...allFruitClassesForSpecies.filter(item => item !== fruitClassCode),
        ]);
        const allSpeciesVarieties = getAllVarietiesForSpecies(options, species);
        const allFruitClassVarieties = varieties
          .filter(item => item.attributes.fruitClass === fruitClassCode)
          .map(item => +item.id);
        const newVarieties = uniq([
          // сохраняем выбранные variety исключая все что относится к текущему allFruitClassVarieties
          ...varietyIds.filter(item => !allFruitClassVarieties.includes(item)),
          // выбираем все остальные variety
          ...allSpeciesVarieties.filter(item => !allFruitClassVarieties.includes(item)),
        ]);
        return {
          species: newSpecies,
          varietyId: newVarieties,
          fruitClassCode: newFruitClassCodes
        };
      }
      // Если соответствующий species не был выбран, (случай раздельного выбора)
      // исключаем все variety соответстующие данному fruitClassCode
      const allFruitClassVarieties = varieties
        .filter(item => item.attributes.fruitClass === fruitClassCode)
        .map(item => +item.id);
      return {
        species: [...speciesIds],
        varietyId: [...varietyIds.filter(item => !allFruitClassVarieties.includes(item))],
        fruitClassCode: [...fruitClassCodeIds.filter(item => item !== fruitClassCode)]
      };
    }
    // Если fruitClass был не выбран
    // Добавляем текущий fruitClass
    // Добавляем все varieties свзяанные с ним
    const allFruitClassVarieties = varieties
      .filter(item => item.attributes.fruitClass === fruitClassCode)
      .map(item => +item.id);
    return {
      species: [...speciesIds],
      varietyId: uniq([...varietyIds, ...allFruitClassVarieties]),
      fruitClassCode: uniq([...fruitClassCodeIds, fruitClassCode])
    };
  }

  if (isVarietySpecifiedSelect(changedOptions)) {
    if (onlyCheck) {
      return {
        species: [],
        varietyId: [+varietyId],
        fruitClassCode: []
      };
    }
    const variety = varieties.find(item => +item.id === +varietyId);
    // Если были выбраны все (считаем что текущий тоже выбран)
    // Убираем выбор соответсвующего fruitClass и species
    if (isSelectAll) {
      return {
        species: [...allSpecies.filter(item => item !== variety.attributes.species)],
        varietyId: [...varieties.filter(item => item.attributes.fruitClass !== variety.attributes.fruitClass).map(item => +item.id)],
        fruitClassCode: [...fruitClasses.filter(item => item.id !== variety.attributes.fruitClass).map(item => item.id)],
      };
    }
    // Variety считается выбраным, если выбран непостредственно,
    // или если выбраны связанные с ним fruitClass и/или species
    const isVarietySelected = varietyIds.includes(varietyId) ||
      speciesIds.includes(variety.attributes.species) ||
      fruitClassCodeIds.includes(variety.attributes.fruitClass);
    if (isVarietySelected) {
      // Если был выбран непосредственно
      // убираем выбор соответствующего species и fruitClass
      // оставляем другие varieties (для данного species)
      if (varietyIds.includes(varietyId)) {
        const allVarieties = varieties
          .filter(item => item.attributes.species === variety.attributes.species)
          .map(item => +item.id);
        return {
          species: [...speciesIds.filter(item => item !== variety.attributes.species)],
          varietyId: uniq([...varietyIds.filter(item => +item !== +varietyId), ...allVarieties.filter(item => +item !== +varietyId)]),
          fruitClassCode: [...fruitClassCodeIds.filter(item => item !== variety.attributes.fruitClass)]
        };
      }

      // Нужно проверить не сбрасывается ли последний variety
      if (varietyIds.length === 0) {
        return {
          species: [],
          varietyId: [],
          fruitClassCode: []
        };
      }

      // Для группового выбора нужно выбрать все fruitClass которые не относятся к данному variety
      // и сбосить species соответственно
      const allSpeciesFruitClass = fruitClasses
        .filter(item => item.attributes.species === variety.attributes.species &&
        item.id !== variety.attributes.fruitClass)
        .map(item => item.id);

      const allSpeciesVarieties = varieties
        .filter(item => item.attributes.species === variety.attributes.species &&
        +item.id !== +varietyId)
        .map(item => +item.id);

      return {
        species: [...speciesIds.filter(item => item !== variety.attributes.species)],
        varietyId: uniq([...varietyIds.filter(item => +item !== +varietyId), ...allSpeciesVarieties]),
        fruitClassCode: uniq([
          ...fruitClassCodeIds.filter(item => item !== variety.attributes.fruitClass),
          ...allSpeciesFruitClass
        ])
      };
    }
    // Если текущий variety не был выбран просто добавлем его
    return {
      species: [...speciesIds],
      varietyId: uniq([...varietyIds, +varietyId]),
      fruitClassCode: [...fruitClassCodeIds]
    };
  }

  return {
    species: [],
    varietyId: [],
    fruitClassCode: []
  };
}

function getVarietiesData({
  varieties,
  fruitClasses
}) {
  const fruitClassIds = uniq(varieties.map(item => item.attributes.fruitClass));
  const availableSpecies = uniq(varieties.map(item => item.attributes.species));
  return {
    availableFruitClasses: fruitClasses.filter(item => fruitClassIds.includes(item.id)),
    availableSpecies,
    availableVarieties: varieties
  };
}

const VarietyFilter = ({
  intl,
  varieties,
  fruitClasses,
  species: allSpecies,
  onFiltersChange,
  classNameButton,
  classNameWrapper,
  capitalizeTitle,
  withCheckBox,
  selectComponent,
  isMobile,
  showActiveState
}) => {
  const { formatMessage, locale } = intl;

  const [open, setOpen] = useState(false);

  const currentUnitKind = getQueryValue('unitKind') || 'absolute';

  const currentVarietyId = getQueryValue('varietyId') || undefined;
  const currentSpecies = getQueryValue('species');
  const currentFruitClassCode = getQueryValue('fruitClassCode');

  const {
    availableFruitClasses,
    availableSpecies,
    availableVarieties
  } = useMemo(() => getVarietiesData({ varieties, fruitClasses }), [varieties, fruitClasses]);

  const selectedOption = useMemo(() => buildSelectedOption({
    currentVarietyId,
    currentSpecies,
    currentFruitClassCode
  }, {
    withCheckBox,
    varieties: availableVarieties,
    fruitClasses: availableFruitClasses,
    species: availableSpecies
  }), [
    currentVarietyId,
    availableVarieties,
    currentFruitClassCode,
    currentSpecies,
    availableFruitClasses,
    withCheckBox,
    availableSpecies
  ]);

  const handlerVarietyOptionChange = useCallback((
    { species, varietyId, fruitClassCode }, options, onlyCheck
  ) => {
    let innerFruitClassCode = fruitClassCode;

    if (varietyId) {
      const currentVariety = find(varieties, { id: varietyId });

      const varietyFruitClassCode = get(currentVariety, 'attributes.fruitClass');

      if (varietyFruitClassCode) {
        innerFruitClassCode = varietyFruitClassCode;
      }
    }

    let unitKind;
    let isUnitKindNeedChange = false;

    if (!withCheckBox) {
      const { isHasLettuce: isHasLettuceOld, isHasVegetables: isHasVegetablesOld } = checkSpecies({
        isAllSpecies: !species && !varietyId && !innerFruitClassCode,
        species: allSpecies,
        varieties,
        fruitClasses,
        currentSpecies,
        currentVarietyId,
        currentFruitClassCode,
      });

      const { isHasLettuce: isHasLettuceNext, isHasVegetables: isHasVegetablesNext } = checkSpecies({
        isAllSpecies: !species && !varietyId && !innerFruitClassCode,
        species: allSpecies,
        varieties,
        fruitClasses,
        currentSpecies: species,
        currentVarietyId: varietyId,
        currentFruitClassCode: innerFruitClassCode,
      });

      if (isHasVegetablesOld !== isHasVegetablesNext || isHasLettuceOld !== isHasLettuceNext) {
        if (currentUnitKind === 'perHarvestInKg' && !isHasVegetablesNext) {
          isUnitKindNeedChange = true;
          if (isHasLettuceNext) {
            unitKind = 'perHarvestInCount';
          } else {
            unitKind = undefined;
          }
        }

        if (currentUnitKind === 'perHarvestInCount' && !isHasLettuceNext) {
          isUnitKindNeedChange = true;
          if (isHasVegetablesNext) {
            unitKind = 'perHarvestInKg';
          } else {
            unitKind = undefined;
          }
        }
      }
    }

    let params;
    if (withCheckBox) {
      const changedOptions = { species, varietyId, fruitClassCode };
      const currentOptions = { currentSpecies, currentVarietyId, currentFruitClassCode };
      const filterParam = buildParams(
        changedOptions,
        currentOptions,
        options,
        availableFruitClasses,
        availableVarieties,
        availableSpecies,
        onlyCheck
      );
      params = filterOptionsToUrl({
        selectedVarietyId: filterParam?.varietyId,
        selectedSpecies: filterParam?.species,
        selectedFruitClassCode: filterParam?.fruitClassCode
      }, {
        varieties: availableVarieties,
        fruitClasses: availableFruitClasses,
        // species
      });
    } else {
      params = { species, varietyId, fruitClassCode: innerFruitClassCode };
    }

    if (isUnitKindNeedChange) {
      params.unitKind = unitKind;
      updateLocationSearch({
        unitKind
      });
    }

    if (open) {
      setOpen(false);
    }

    if (withCheckBox) {
      // Нормализуем параметры перед отправкой
      updateLocationSearch({
        species: joinParams(params?.species),
        varietyId: joinParams(params?.varietyId),
        fruitClassCode: joinParams(params?.fruitClassCode),
      });
    } else {
      updateLocationSearch(params);
    }

    if (onFiltersChange) {
      onFiltersChange(params);
    }
  }, [
    currentVarietyId,
    currentFruitClassCode,
    currentSpecies,
    currentUnitKind,
    onFiltersChange,
    open,
    withCheckBox,
    availableFruitClasses,
    availableSpecies,
    availableVarieties,
    allSpecies,
    varieties,
    fruitClasses
  ]);

  const customTitle = useMemo(() => getFilterTitle({
    currentVarietyId,
    currentSpecies,
    currentFruitClassCode
  }, {
    withCheckBox,
    formatMessage,
    locale,
    varieties: availableVarieties,
    fruitClasses: availableFruitClasses,
    species: availableSpecies
  }), [
    currentVarietyId,
    currentFruitClassCode,
    currentSpecies,
    formatMessage,
    locale,
    withCheckBox,
    availableFruitClasses,
    availableSpecies,
    availableVarieties
  ]);

  const customPlaceholder = isMobile ? formatMessage({ id: 'dashboards.speciesTitle' }) : customTitle;

  return (
    <VarietySearchSelect
      onSelectOption={handlerVarietyOptionChange}
      varieties={varieties}
      fruitClasses={fruitClasses}
      selectedOption={selectedOption}
      classNameButton={classNameButton || styles.varietySelect}
      className={classNameWrapper || styles.selectboxFilterWrapper}
      isShowAllSpecies
      capitalizeTitle={capitalizeTitle}
      withCheckBox={withCheckBox}
      selectComponent={selectComponent}
      customTitle={customTitle}
      closeOnChange
      customPlaceholder={customPlaceholder}
      showActiveState={showActiveState}
    />
  );
};

VarietyFilter.propTypes = {
  intl: intlShape.isRequired,
  varieties: PropTypes.array,
  fruitClasses: PropTypes.array,
  species: PropTypes.array,
  onFiltersChange: PropTypes.func,
  classNameButton: PropTypes.string,
  classNameWrapper: PropTypes.string,
  capitalizeTitle: PropTypes.bool,
  withCheckBox: PropTypes.bool,
  selectComponent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  isMobile: PropTypes.bool,
  showActiveState: PropTypes.bool
};

VarietyFilter.defaultProps = {
  onFiltersChange: null,
  classNameButton: null,
  classNameWrapper: null,
  varieties: [],
  fruitClasses: [],
  species: [],
  capitalizeTitle: false,
  withCheckBox: false,
  selectComponent: Select,
  isMobile: false,
  showActiveState: true
};

export default injectIntl(VarietyFilter);
