import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import classnames from 'classnames';
import {
  get, isNil, find, flow
} from 'lodash';

import numbersRounding from '../../../../helpers/numbersRounding';
import numbersFormatting from '../../../../helpers/numbersFormatting';
import { getProductTypeName } from '../../../../helpers/getVarietyName';
import { getPlantingCycleDatesById, getCompartmentNameByPlantingCycle } from '../../../../helpers/getPlantingCycleLabel';
import DefaultCircleLoader from '../../../DefaultCircleLoader';
import BreakdownHorizontalBarChart from '../../../BreakdownHorizontalBarChart';
import SpeciesIcon from '../../../SpeciesIcon';
import Speedometer from '../Speedometer';

import TableIcon from '../../../Icons/TableIcon';

import styles from './BreakdownItem.module.css';

class BreakdownItem extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    name: PropTypes.string.isRequired,
    isCurrentPeriod: PropTypes.bool.isRequired,
    organization: PropTypes.object,
    allPlantingCycles: PropTypes.array,
    varieties: PropTypes.array,
    fruitClasses: PropTypes.array,
    compartments: PropTypes.array,
    itemData: PropTypes.object,
    units: PropTypes.string,
    isFetching: PropTypes.bool,
    type: PropTypes.string,
    species: PropTypes.string,
  };

  static defaultProps = {
    organization: null,
    itemData: null,
    units: null,
    isFetching: false,
    type: null,
    species: null,
    allPlantingCycles: null,
    varieties: null,
    fruitClasses: null,
    compartments: null,
  };

  getHref = (organizationSlug, item) => {
    const itemType = get(item, 'ref.type');

    if (itemType === 'EffectivePlanSection') {
      return `/${organizationSlug}/plans`;
    }

    return `/${organizationSlug}/crops/${get(item, 'ref.id')}`;
  };

  getPlanLabel = (item) => {
    const {
      intl,
      type,
      varieties,
      compartments,
      fruitClasses,
    } = this.props;

    if (type === 'Variety') {
      const planCompartment = find(compartments, { id: get(item, 'compartment.id') });

      return get(planCompartment, 'attributes.name');
    }

    const planVariety = find(varieties, { id: get(item, 'variety.id') });
    const planFruitClass = find(fruitClasses, { id: get(item, 'fruitClass.id') });

    return getProductTypeName({
      intl,
      variety: planVariety,
      fruitClass: planFruitClass,
      targetWeight: get(item, 'targetWeight'),
    });
  };

  getItemLabel = (item) => {
    const {
      intl,
      type,
      allPlantingCycles,
      varieties,
      compartments,
      fruitClasses,
    } = this.props;

    const itemId = get(item, 'ref.id');
    const itemType = get(item, 'ref.type');

    if (itemType === 'EffectivePlanSection') {
      return this.getPlanLabel(item);
    }

    if (type === 'Variety') {
      return getCompartmentNameByPlantingCycle({
        plantingCycleId: itemId,
        allPlantingCycles,
        compartments,
      });
    }

    const plantingCycle = find(allPlantingCycles, { id: itemId });
    const varietyId = get(plantingCycle, 'relationships.variety.data.[0].id');
    const fruitClassId = get(plantingCycle, 'relationships.fruitClass.data.[0].id');

    const currentVariety = find(varieties, { id: varietyId });
    const currentFruitClass = find(fruitClasses, { id: fruitClassId });

    return getProductTypeName({
      intl,
      variety: currentVariety,
      fruitClass: currentFruitClass,
      targetWeight: get(plantingCycle, 'attributes.targetWeight'),
    });
  };

  renderLine = (value, units, header, color, isDashed) => (
    <div className={styles.line}>
      <span className={styles.lineHeader}>
        <span
          className={classnames(styles.lineColor, { [styles.dashedLine]: isDashed })}
          style={color ? { background: color } : null}
        />
        {header}
      </span>
      <span className={styles.lineValue}>
        {`${!isNil(value) ? numbersFormatting(numbersRounding(value, 'fixed', 0)) : '—'} ${units}`}
      </span>
    </div>
  );

  renderTooltipContent = (item) => {
    if (!item) {
      return null;
    }
    const {
      intl,
      allPlantingCycles,
      varieties,
      fruitClasses,
      compartments,
    } = this.props;

    const { formatMessage } = intl;

    const units = get(item, 'units');
    const isItemPlanSection = get(item, 'fullItem.ref.type') === 'EffectivePlanSection';

    const plantingCycle = find(allPlantingCycles, { id: item.id });
    const varietyId = isItemPlanSection ? get(item, 'fullItem.variety.id') : get(plantingCycle, 'relationships.variety.data.[0].id');
    const fruitClassId = isItemPlanSection ? get(item, 'fullItem.fruitClass.id') : get(plantingCycle, 'relationships.fruitClass.data.[0].id');
    const targetWeight = isItemPlanSection ? get(item, 'fullItem.targetWeight') : get(plantingCycle, 'attributes.targetWeight');

    const currentVariety = find(varieties, { id: varietyId });
    const currentFruitClass = find(fruitClasses, { id: fruitClassId });

    const mainHeader = getProductTypeName({
      intl,
      variety: currentVariety,
      fruitClass: currentFruitClass,
      targetWeight,
    });

    const datesHeader = getPlantingCycleDatesById({
      plantingCycleId: item.id,
      allPlantingCycles,
      formatMessage,
    });

    const compartmentName = isItemPlanSection ?
      flow([
        currentItem => get(currentItem, 'fullItem.compartment.id'),
        compartmentId => find(compartments, { id: compartmentId }),
        currentCompartment => get(currentCompartment, 'attributes.name')
      ])(item)
      :
      getCompartmentNameByPlantingCycle({
        plantingCycleId: item.id,
        allPlantingCycles,
        compartments,
      });

    return (
      <div>
        <div className={styles.tooltipHeader}>
          <div className={styles.tooltipDate}>{mainHeader}</div>
          <div className={styles.tooltipSubHeader}>
            <span>{datesHeader}</span>
            <span className={styles.dot} />
            <span>{compartmentName}</span>
          </div>
        </div>
        <div>
          {this.renderLine(item.value, units, formatMessage({ id: 'kpiDashboard.harvested' }), '#1DBADF')}
          {this.renderLine(item.expectedValue, units, formatMessage({ id: 'kpiDashboard.expectedForToday' }), '#777776', true)}
          {this.renderLine(item.targetValue, units, formatMessage({ id: 'kpiDashboard.harvestTarget' }), '#003C68')}
        </div>
      </div>
    );
  };

  render() {
    const {
      intl: { formatMessage },
      name,
      itemData,
      units,
      isFetching,
      type,
      species,
      isCurrentPeriod,
      organization,
    } = this.props;

    const { attributes: { slug: organizationSlug } } = organization;

    const percent = get(itemData, 'gauge.percentage.amount');
    const color = get(itemData, 'gauge.color');

    const currentValue = get(itemData, 'gauge.actual.amount');
    const finalValue = get(itemData, 'gauge.planned.amount');
    const expectedValue = get(itemData, 'gauge.expected.amount');

    const plantingCyclesData = get(itemData, 'plantingCycles', []);
    const planSectionsData = get(itemData, 'planSections', []);

    const preparedItemData = [...plantingCyclesData, ...planSectionsData].map(item => ({
      id: get(item, 'ref.id'),
      units: formatMessage({ id: `cunits.mini.${units}` }),
      label: this.getItemLabel(item),
      labelHref: this.getHref(organizationSlug, item),
      value: get(item, 'gauge.actual.amount'),
      targetValue: get(item, 'gauge.planned.amount'),
      expectedValue: get(item, 'gauge.expected.amount'),
      percentValue: get(item, 'gauge.percent.amount'),

      fullItem: item,
    }));

    return (
      <div className={styles.itemContainer}>
        {isFetching && <DefaultCircleLoader />}

        <div className={styles.itemHeader}>
          <div className={styles.itemHeaderIcon}>
            {type === 'Compartment' ?
              <TableIcon className={styles.speciesIcon} />
              :
              <SpeciesIcon species={species} className={styles.speciesIcon} />}
          </div>
          <div className={styles.itemHeaderText}>{name}</div>
        </div>
        <div className={styles.itemBody}>
          <Speedometer
            percent={percent}
            color={color}
            radius={70}
            strokeWidth={20}
            wrapperPadding={20}
            size='S'
            units={units}
            currentValue={currentValue}
            finalValue={finalValue}
            expectedValue={expectedValue}
            isCurrentPeriod={isCurrentPeriod}
          />
        </div>

        <div className={styles.plantingCycles}>
          <div className={styles.plantingCyclesHeader}>{formatMessage({ id: 'harvestDashboard.cropCycles' })}</div>
          <div className={styles.horizontalBarChartWrapper}>
            <BreakdownHorizontalBarChart
              dataList={preparedItemData}
              renderTooltipContent={this.renderTooltipContent}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(BreakdownItem);
