import { handleActions } from 'redux-actions';
import { get } from 'lodash';

import {
  requestDataQualityReport,
  receiveDataQualityReport,

  requestOverallQuality,
  receiveOverallQuality,

  updateGreenhousesOptions,
  updateDataTypeOptions,
} from './actions';

const buildOptions = options => options.map(option => ({
  label: option.name,
  value: option.id,
  checked: true,
}));

const initialState = {
  isDataQualityReportFetching: false,
  dataQualityReport: null,

  isOverallQualityFetching: false,
  currentYearOverallQuality: null,
  overallQualityReceivedAt: null,

  greenhousesOptions: [],
  dataTypeOptions: [],
};

export default handleActions({
  [requestDataQualityReport](state) {
    return {
      ...state,
      isDataQualityReportFetching: true,
    };
  },
  [receiveDataQualityReport](state, action) {
    return {
      ...state,
      isDataQualityReportFetching: false,
      dataQualityReport: action.payload.dataQualityReport,
      greenhousesOptions: buildOptions(get(action, 'payload.dataQualityReport.compartments', [])),
    };
  },
  [requestOverallQuality](state) {
    return {
      ...state,
      isOverallQualityFetching: true,
    };
  },
  [receiveOverallQuality](state, action) {
    return {
      ...state,
      isOverallQualityFetching: false,
      currentYearOverallQuality: get(action, 'payload.dataQualityReport.overallQuality'),
      overallQualityReceivedAt: get(action, 'payload.overallQualityReceivedAt'),
    };
  },
  [updateGreenhousesOptions](state, action) {
    return {
      ...state,
      greenhousesOptions: action.payload.greenhousesOptions,
    };
  },
  [updateDataTypeOptions](state, action) {
    return {
      ...state,
      dataTypeOptions: action.payload.dataTypeOptions,
    };
  },
}, initialState);
