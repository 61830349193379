import React, {
  useCallback, useEffect, useMemo, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Route, Switch, NavLink } from 'react-router-dom';

import classnames from 'classnames';
import { find, isEqual, sortBy } from 'lodash';
import queryString from 'query-string';

import { Redirect } from 'react-router';

import useMountEffect from 'hooks/useMountEffect';
import storageWrapper from 'helpers/storageWrapper';

import BackButton from 'components/BackButton';
import BigButton from 'components/BigButton';
import Select from 'components/Select';
import HarvestForecastEnterForm from 'components/HarvestForecastEnterForm';
import VersionHistorySidePanel from 'components/VersionHistorySidePanel';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import DefaultPlate from 'components/DefaultPlate';
import Typography from 'components/Typography';
import SaveChangesDialog from 'components/SaveChangesDialog';

import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';
import {
  formatDateLocalized,
  parseAndFormatDate,
  parseAndFormatDateLocalized,
  parseDate,
  parseDateIso
} from 'helpers/datesHelper';
import ReportBadge from 'components/HarvestForecastDashboard/components/HarvestForecastDashboard/ReportBadge';

import {
  getReportFiltersFromQuery,
  getGraphKeyFromQuery,
  getVersionIdFromQuery
} from 'store/harvestForecast/selectors';

import HistoryIcon from 'components/Icons/HistoryIcon';
import updateLocationSearch from 'helpers/updateLocationSearch';
import HarvestForecastModalDelete from '../HarvestForecastModalDelete';
import HarvestForecastModalPublish from '../HarvestForecastModalPublish';
import HarvestForecastModalExport from '../HarvestForecastModalExport';
import HarvestForecastTabPage from '../HarvestForecastTabPage';
import HasChanges from '../HasChanges';

// TODO: вынести в Icons (проверить, мб там уже есть такие иконки)
import { ReactComponent as PointsIcon } from './assets/points.svg';

import styles from './HarvestForecastDashboard.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');

function getTabQuery() {
  const queryFilters = getReportFiltersFromQuery();
  const graphKeys = getGraphKeyFromQuery();
  const versionId = getVersionIdFromQuery();

  const queryObject = {
    ...graphKeys,
    ...queryFilters,
    versionId
  };

  return queryString.stringify(
    Object.keys(queryObject)
      .filter(k => queryObject[k] != null)
      .reduce((acc, k) => {
        acc[k] = queryObject[k];
        return acc;
      }, {})
  );
}

const renderButtons = ({
  formatMessage,
  editMode,
  handlerClickAddData,
  handlerClickPublish,
  selectMenuOptions,
  handlerMenuChange,
  handlerCancelButton,
  handlerSaveButton,
  isAddHarvestForecastAvailable,
  linkToAddDataPage,
  isCanPublishForecast,
  isCanAddDataForecast,
  isAnyPublishedVersion,
}) => {
  if (!isAddHarvestForecastAvailable) {
    return null;
  }

  if (editMode) {
    return (
      <div className={styles.headerControls}>
        <HasChanges onSubmit={handlerSaveButton} />
        <div className={styles.headerControlsButtons}>
          <BigButton
            className={styles.cancelButton}
            title={formatMessage({ id: 'button.cancel' })}
            type='button'
            onClick={handlerCancelButton}
            theme='light'
          />
          {/* <DropdownButton
            handlerPublish={() => this.handlerSaveButton(true, true)}
            handlerSaveWithoutPublish={() => this.handlerSaveButton(true)}
            handlerPublishButton={this.handlerOpenPublishButtonDialog}
            disabled={isPlansEditorSaving}
          /> */}
          <BigButton
            className={styles.saveButton}
            title={formatMessage({ id: 'forecast.saveForecast' })}
            type='button'
            onClick={handlerSaveButton}
            theme='dark'
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <BigButton
        className={classnames(styles.addDataButton)}
        titleClassName={styles.addButtonTitle}
        title={formatMessage({ id: 'forecast.dashboard.addData' })}
        onClick={handlerClickAddData}
        href={linkToAddDataPage}
        theme='light'
        disabled={!isCanAddDataForecast}
      />
      <Select
        options={selectMenuOptions}
        className={styles.selectMenu}
        onChange={handlerMenuChange}
        theme='transparent'
        size='medium'
        labelPath='label'
        valuePath='value'
        withoutArrow
        closeOnChange
        placeholder={(
          <BigButton
            className={styles.fieldsButton}
            icon={<PointsIcon />}
          />
        )}
        isPopupRight
      />
      <BigButton
        className={classnames(styles.publishButton)}
        titleClassName={styles.publishButtonTitle}
        title={isAnyPublishedVersion ? formatMessage({ id: 'forecast.dashboard.republish' }) : formatMessage({ id: 'forecast.dashboard.publish' })}
        onClick={handlerClickPublish}
        theme='dark'
        disabled={!isCanPublishForecast}
      />
    </>
  );
};

const handlerPublishButton = (requestSaveAndPublishForecast, requestPublishForecast, getPreparedToSaveForecast, forecastId, forecastData, highlightsText, additionalSubscribers) => {
  const notify = additionalSubscribers ? additionalSubscribers.map(subscriber => subscriber.id) : undefined;

  if (!forecastData?.id) {
    const preparedToSaveForecast = getPreparedToSaveForecast();

    requestSaveAndPublishForecast({
      forecastId,
      productForecasts: preparedToSaveForecast,
      comment: highlightsText,
      notify,
    });
  } else {
    requestPublishForecast({
      forecastId: forecastData.forecastId,
      versionId: forecastData.id,
      notify,
    });
  }
};

const handlerExportButton = (requestHarvestForecastExport, versionId) => {
  const queryFilters = getReportFiltersFromQuery();

  return requestHarvestForecastExport({
    versionId,
    filters: queryFilters,
  });
};

const HarvestForecastDashboard = ({
  intl,
  match,
  history,
  forecastVersions,
  forecastData,
  requestGetForecastLastVersion,
  requestGetForecastVersions,
  requestSaveForecastVersion,
  requestGetForecastReportData,
  forecastReportData,
  isForecastReportDataFetching,
  requestGetForecastVersion,
  setShowVersionPanel,
  isShowVersionPanel,
  requestDeleteForecast,
  requestRestoreForecastVersion,
  requestPublishForecast,
  isLoading,
  setForecastVersionsFilter,
  versionsFilter,
  filteredVersions,
  harvestForecastAccess,
  isAddHarvestForecastAvailable,
  versionId,
  // reportFilters,
  clearForecastState,
  requestSaveAndPublishForecast,
  clearForecastReportData,
  requestHarvestForecastExport,
  isDeleteVersionInfoFetching,
  isVersionInfoFetching,
  requestEditForecastVersionInfo,
  requestDeleteForecastVersionInfo,
}) => {
  const childRef = useRef(null);
  const { formatMessage } = intl;
  const { params: { organizationSlug, forecastId } } = match;

  const forecastIdHash = forecastData?.forecastId ? `forecastData-${forecastData?.forecastId}-${forecastData?.id || 'notSaved'}` : null;
  const editModeFromStorageHash = forecastIdHash ? `editMode-of-${forecastIdHash}` : null;
  // const editModeFromStorage = safeLocalStorage.getItem(editModeFromStorageHash);

  const isCanDeleteForecast = forecastData?.permissions?.deleteForecast;
  const isCanAddDataForecast = forecastData?.permissions?.edit;
  const isCanPublishForecast = forecastData?.permissions?.publish;
  const isCanRestoreForecast = forecastData?.permissions?.restore;
  const isCanEditForecast = forecastData?.permissions?.editForecast;
  const problem = forecastReportData?.problem;

  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowPublishModal, setIsShowPublishModal] = useState(false);
  const [isShowExportModal, setIsShowExportModal] = useState(false);
  const [isShowSaveChangesDialog, setIsShowSaveChangesDialog] = useState(false);
  const [isDeleted, setDeleted] = useState(false);
  const [isEditDetails, setEditDetails] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [highlightsText, setHighlightsText] = useState(forecastData?.comment);
  const handlerUpdateHighlightsText = useCallback(event => setHighlightsText(event.target.value), [setHighlightsText]);

  // Включен ли edit mode берём из localstorage
  useEffect(() => {
    const editModeFromStorage = safeLocalStorage.getItem(editModeFromStorageHash);

    setEditMode(editModeFromStorage ? JSON.parse(editModeFromStorage) : false);
  }, [editModeFromStorageHash]);

  const updateEditModeState = useCallback((newValue) => {
    if (newValue) {
      safeLocalStorage.setItem(editModeFromStorageHash, JSON.stringify(newValue));
    } else {
      safeLocalStorage.removeItem(editModeFromStorageHash);
    }

    return setEditMode(newValue);
  }, [setEditMode, editModeFromStorageHash]);

  useMountEffect(() => {
    if (!harvestForecastAccess) {
      history.push(`/${organizationSlug}/crops`);
    }
    clearForecastState({});
    requestGetForecastVersions({ forecastId });
    if (!versionId) {
      requestGetForecastLastVersion({ forecastId });
    } else {
      requestGetForecastVersion({
        versionId
      });
    }
  });

  useEffect(() => {
    clearForecastReportData({});
    if (versionId) {
      requestGetForecastReportData({
        versionId,
        // filters: {}, // reportFilters
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionId, clearForecastReportData, requestGetForecastReportData]);

  useEffect(() => {
    if (forecastData?.comment !== highlightsText) {
      setHighlightsText(forecastData?.comment);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastData, setHighlightsText]);

  const handleReportFiltersChange = useCallback((newFilters) => {
    clearForecastReportData({});
    if (versionId) {
      requestGetForecastReportData({
        versionId,
        filters: newFilters
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionId, clearForecastReportData, requestGetForecastReportData]);

  const getPreparedToSaveForecast = () => {
    if (childRef?.current) {
      const productForecasts = childRef.current.getCurrentData();

      // Сохраняем только те plantingCycles, где были реальные изменения
      return productForecasts.reduce((acc, item) => {
        const oldData = find(forecastData?.manualForecast, { product: { id: item.product.id } });
        const oldForecast = oldData?.forecast || [];
        const sortedForecast = sortBy(item.forecast, 'date');
        const sortedOldForecast = sortBy(oldForecast, 'date');

        const isNoChanges = isEqual(sortedForecast, sortedOldForecast);

        if (isNoChanges) {
          return acc;
        }

        return [...acc, item];
      }, []);
    }

    return [];
  };

  const handlerSaveButton = () => {
    const preparedToSaveForecast = getPreparedToSaveForecast();

    requestSaveForecastVersion({
      forecastId,
      productForecasts: preparedToSaveForecast,
      comment: highlightsText,
    });

    updateEditModeState(false);
  };

  const handlerCancelButton = () => setIsShowSaveChangesDialog(true);

  const handlerCancelAccepted = () => {
    // Очищаем данные таблицы в localstorage
    safeLocalStorage.removeItem(forecastIdHash);

    return updateEditModeState(false);
  };

  const handlerClickAddData = useCallback(() => {
    updateEditModeState(true);
  }, [updateEditModeState]);

  const handlerClickPublish = useCallback(() => {
    setIsShowPublishModal(true);
  }, [setIsShowPublishModal]);

  const handlerClickClosePublishModal = useCallback(() => {
    setIsShowPublishModal(false);
  }, [setIsShowPublishModal]);

  const handlerClickCloseExportModal = useCallback(() => {
    setIsShowExportModal(false);
  }, [setIsShowExportModal]);

  const handlerMenuChange = useCallback(({ value }) => {
    if (value === 'showHistory') {
      setShowVersionPanel(true);
    } else if (value === 'delete') {
      setIsShowDeleteModal(true);
    } else if (value === 'edit') {
      setEditDetails(true);
    } else if (value === 'settings') {
      history.push(`/${organizationSlug}/settings/harvest-forecast`);
    } else if (value === 'export') {
      setIsShowExportModal(true);
    }
  }, [history, organizationSlug, setIsShowDeleteModal, setIsShowExportModal, setShowVersionPanel, setEditDetails]);

  const handlerClickDelete = useCallback(() => {
    setIsShowDeleteModal(false);
    requestDeleteForecast({
      id: forecastId,
      actionAfterSuccess: () => setDeleted(true),
    });
  }, [forecastId, setDeleted, setIsShowDeleteModal, requestDeleteForecast]);

  const handlerClickCloseDeleteModal = useCallback(() => {
    setIsShowDeleteModal(false);
  }, [setIsShowDeleteModal]);

  const defaultMenuOptions = [{
    label: formatMessage({ id: 'forecast.dashboard.menu.showVersionHistory' }),
    value: 'showHistory'
  }, {
    label: formatMessage({ id: 'forecast.dashboard.menu.exportXLSX' }),
    value: 'export',
    disabled: !versionId,
  },
  {
    label: formatMessage({ id: 'forecast.dashboard.menu.openSettings' }),
    value: 'settings',
  },
  ];

  const editOption = {
    label: formatMessage({ id: 'forecast.dashboard.menu.editDetails' }),
    value: 'edit'
  };

  const deleteOption = {
    label: formatMessage({ id: 'forecast.dashboard.menu.delete' }),
    value: 'delete'
  };

  const selectMenuOptions = [
    ...isCanEditForecast ? [editOption] : [],
    ...defaultMenuOptions,
    ...isCanDeleteForecast ? [deleteOption] : [],
  ];

  const forecastTitle = useMemo(() => {
    if (!forecastData) {
      return '';
    }
    if (forecastData.name) {
      return forecastData.name;
    }
    const {
      endInclusive,
      start
    } = forecastData;
    const yearDifference = differenceInCalendarYears(
      parseDate(endInclusive),
      parseDate(start),
    );
    const startDate = parseAndFormatDate(start, yearDifference !== 0 ? 'w yyyy' : 'w');
    const endDate = parseAndFormatDate(endInclusive, 'w');
    return `Forecast weeks ${startDate} – ${endDate}`;
  }, [forecastData]);

  const forecastDates = useMemo(() => {
    if (!forecastData) {
      return '';
    }
    const {
      endInclusive,
      start
    } = forecastData;

    const yearDifference = differenceInCalendarYears(
      parseDate(endInclusive),
      parseDate(start),
    );
    const startDate = parseAndFormatDateLocalized(start, yearDifference !== 0 ? 'lll' : 'llll');
    const endDate = parseAndFormatDateLocalized(endInclusive, 'llll');
    return ` ${startDate} – ${endDate}`;
  }, [forecastData]);

  const handlerSelectVersion = useCallback((version) => {
    requestGetForecastVersion({
      versionId: version.planId
    });
    updateLocationSearch({
      versionId: version.planId
    });
  }, [requestGetForecastVersion]);

  const handlerEditVersionInfo = useCallback((version, actionSuccess) => {
    requestEditForecastVersionInfo({
      forecastId,
      versionId: version.planId,
      label: version.label,
      comment: version.comment,
      actionSuccess,
    });
  }, [requestEditForecastVersionInfo, forecastId]);

  const handlerDeleteVersionInfo = useCallback((version, actionSuccess) => {
    requestDeleteForecastVersionInfo({
      forecastId,
      versionId: version.planId,
      actionSuccess,
    });
  }, [requestDeleteForecastVersionInfo, forecastId]);

  const isHistoryVersion = useMemo(() => {
    if (!forecastData?.id) {
      return false;
    }
    const lastVersionId = Math.max(...forecastVersions.map(ver => ver.id));
    return lastVersionId !== forecastData?.id;
  }, [forecastVersions, forecastData]);

  const versionCreated = useMemo(() => (forecastData?.whenCreated ?
    formatDateLocalized(
      parseDateIso(forecastData?.whenCreated),
      'll hh'
    ) : ''), [forecastData]);

  const handlerClickRestoreVersion = useCallback(() => {
    if (forecastData?.id) {
      requestRestoreForecastVersion({
        versionId: forecastData.id,
        forecastId
      });
    }
  }, [forecastData, requestRestoreForecastVersion, forecastId]);

  const handlerToggleSidePanel = useCallback(() => {
    setShowVersionPanel(!isShowVersionPanel);
  }, [setShowVersionPanel, isShowVersionPanel]);

  const pageQuery = useMemo(() => getTabQuery(),
    // eslint-disable-next-line
    [history.location]);

  if (isDeleted) {
    return (<Redirect to={`/${organizationSlug}/crops/forecasts`} />);
  }

  if (isEditDetails && forecastId) {
    return (<Redirect to={`/${organizationSlug}/crops/forecasts/${forecastId}/edit?_to=${forecastId}`} />);
  }

  const defaultErrorMessage = formatMessage({ id: 'forecast.problems.server_error' });

  const isAnyPublishedVersion = forecastVersions?.reduce((acc, item) => {
    if (item?.publication) {
      return true;
    }

    return acc;
  }, false);

  return (
    <div className={styles.layout}>
      {isLoading && <DefaultCircleLoader />}
      <div
        className={classnames(
          styles.container,
          {
            [styles.sidePanelOpened]: isShowVersionPanel
          }
        )}
      >
        <div className={styles.content}>
          <div className={styles.controls}>
            <div className={styles.backButton}>
              <BackButton
                link={`/${organizationSlug}/crops/forecasts`}
                text={formatMessage({ id: 'forecast.dashboard.backButtonText' })}
              />
            </div>
            <div className={styles.rightControls}>
              {isHistoryVersion ? (
                <div className={styles.historyControls}>
                  <div className={styles.restoreBanner}>{formatMessage({ id: 'forecast.versionNote' })} {versionCreated}.
                    {isCanRestoreForecast && (
                      <span
                        role='button'
                        tabIndex={0}
                        className={styles.restoreButton}
                        onClick={handlerClickRestoreVersion}
                      >
                        {formatMessage({ id: 'forecast.restoreVersion' })}
                      </span>
                    )}
                  </div>
                  <div className={styles.separaror} />
                  <BigButton
                    className={styles.historyButton}
                    titleClassName={styles.historyButtonTitle}
                    title={formatMessage({ id: 'plansVersion.history' })}
                    onClick={handlerToggleSidePanel}
                    icon={<HistoryIcon />}
                    theme='transparent'
                    highIcon
                  />
                </div>
              ) : (
                renderButtons({
                  formatMessage,
                  editMode,
                  handlerClickAddData,
                  handlerClickPublish,
                  selectMenuOptions,
                  handlerMenuChange,
                  handlerCancelButton,
                  handlerSaveButton,
                  isAddHarvestForecastAvailable,
                  linkToAddDataPage: `/${organizationSlug}/crops/forecasts/${forecastId}/data${history.location.search}`,
                  isCanPublishForecast,
                  isCanAddDataForecast,
                  isAnyPublishedVersion,
                })
              )}
            </div>
          </div>
          {problem && (
            <DefaultPlate type={problem.level} className={styles.problemPlate}>
              <Typography variant='body1'>
                {formatMessage({ id: `forecast.problems.${problem.problemClass}`, defaultMessage: defaultErrorMessage })}
              </Typography>
            </DefaultPlate>
          )}
          <div className={styles.titlesContainer}>
            <div className={styles.titleRow}>
              <span className={styles.titleText}>{forecastTitle}</span>
              <ReportBadge
                forecastData={forecastData}
                forecastVersions={forecastVersions}
                requestGetForecastVersion={requestGetForecastVersion}
                setShowVersionPanel={setShowVersionPanel}
              />
            </div>
            {forecastData && <div className={styles.subtitleRow}>{forecastDates} • {formatMessage({ id: `plantingCycles.speciesPlural.${forecastData?.species}` })}</div>}
          </div>
          <div className={styles.tabs}>
            <NavLink
              key='tab-forecast'
              className={styles.tab}
              activeClassName={styles.activeTab}
              to={`/${organizationSlug}/crops/forecasts/${forecastId}/report?${pageQuery}`}
            >
              {formatMessage({ id: 'forecast.dashboard.tabs.forecast' })}
            </NavLink>
            <NavLink
              key='tab-data'
              className={styles.tab}
              activeClassName={styles.activeTab}
              to={`/${organizationSlug}/crops/forecasts/${forecastId}/data?${pageQuery}`}
            >
              {formatMessage({ id: 'forecast.dashboard.tabs.data' })}
            </NavLink>
          </div>
          <div className={styles.tabsContent}>
            <Switch>
              <Route
                path='/:organizationSlug/crops/forecasts/:forecastId/report'
                render={() => (
                  <HarvestForecastTabPage
                    editMode={editMode}
                    forecastReportData={forecastReportData}
                    isForecastReportDataFetching={isForecastReportDataFetching}
                    isForecastDataFetching={isLoading}
                    onFiltersChange={handleReportFiltersChange}
                    forecastData={forecastData}
                    highlightsText={highlightsText}
                    handlerUpdateHighlightsText={handlerUpdateHighlightsText}
                  />
                )}
                exact
              />
              <Route
                path='/:organizationSlug/crops/forecasts/:forecastId/data'
                component={() => (
                  <HarvestForecastEnterForm
                    childRef={childRef}
                    editMode={editMode}
                    forecastData={forecastData}
                    onFiltersChange={handleReportFiltersChange}
                    forecastIdHash={forecastIdHash}
                  />
                )}
                exact
              />
            </Switch>
          </div>
        </div>
      </div>
      <VersionHistorySidePanel
        opened={isShowVersionPanel}
        onClose={() => setShowVersionPanel(false)}
        versions={filteredVersions}
        onVersionClick={handlerSelectVersion}
        activeVersionId={forecastData?.id}
        versionsFilter={versionsFilter}
        setFilterAction={setForecastVersionsFilter}
        readOnly={!isCanEditForecast}
        allVersions={forecastVersions}
        isVersionInfoFetching={isVersionInfoFetching}
        isDeleteVersionInfoFetching={isDeleteVersionInfoFetching}
        onDeleteVersionInfo={handlerDeleteVersionInfo}
        onEditVersionInfo={handlerEditVersionInfo}
        isRestoreVersionEnabled={isCanRestoreForecast}
        restoreVersion={(restoredForecastId, restoredVersionId) => requestRestoreForecastVersion({
          versionId: restoredVersionId,
          forecastId: restoredForecastId,
        })}
      />
      <HarvestForecastModalDelete
        isOpen={isShowDeleteModal}
        onDelete={handlerClickDelete}
        onClose={handlerClickCloseDeleteModal}
      />
      {isShowPublishModal && (
        <HarvestForecastModalPublish
          forecastId={forecastId}
          versionId={forecastData?.id}
          onClose={handlerClickClosePublishModal}
          onSubmit={additionalSubscribers => handlerPublishButton(requestSaveAndPublishForecast, requestPublishForecast, getPreparedToSaveForecast, forecastId, forecastData, highlightsText, additionalSubscribers)}
          isRepublish={isAnyPublishedVersion}
        />
      )}
      {isShowExportModal && (
        <HarvestForecastModalExport
          forecastData={forecastData}
          onClose={handlerClickCloseExportModal}
          onSubmit={() => handlerExportButton(requestHarvestForecastExport, versionId)}
          onFiltersChange={handleReportFiltersChange}
        />
      )}
      {isShowSaveChangesDialog && (
        <SaveChangesDialog
          handlerCloseDialog={() => setIsShowSaveChangesDialog(false)}
          handlerDontSave={() => {
            handlerCancelAccepted();

            setIsShowSaveChangesDialog(false);
          }}
          handlerSave={() => {
            handlerSaveButton();

            setIsShowSaveChangesDialog(false);
          }}
        />
      )}
    </div>
  );
};

HarvestForecastDashboard.propTypes = {
  intl: intlShape.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  forecastVersions: PropTypes.array,
  forecastData: PropTypes.object,
  requestGetForecastLastVersion: PropTypes.func.isRequired,
  requestGetForecastVersions: PropTypes.func.isRequired,
  requestSaveForecastVersion: PropTypes.func.isRequired,
  // history: PropTypes.object.isRequired,
  requestGetForecastReportData: PropTypes.func.isRequired,
  requestGetForecastVersion: PropTypes.func.isRequired,
  requestDeleteForecast: PropTypes.func.isRequired,
  requestRestoreForecastVersion: PropTypes.func.isRequired,
  forecastReportData: PropTypes.object,
  isForecastReportDataFetching: PropTypes.bool,
  setShowVersionPanel: PropTypes.func.isRequired,
  requestPublishForecast: PropTypes.func.isRequired,
  setForecastVersionsFilter: PropTypes.func.isRequired,
  versionsFilter: PropTypes.string.isRequired,
  isShowVersionPanel: PropTypes.bool,
  isLoading: PropTypes.bool,
  filteredVersions: PropTypes.array,
  harvestForecastAccess: PropTypes.bool,
  isAddHarvestForecastAvailable: PropTypes.bool,
  versionId: PropTypes.number,
  // reportFilters: PropTypes.object.isRequired,
  isDeleteVersionInfoFetching: PropTypes.bool,
  isVersionInfoFetching: PropTypes.bool,
  clearForecastState: PropTypes.func.isRequired,
  requestSaveAndPublishForecast: PropTypes.func.isRequired,
  clearForecastReportData: PropTypes.func.isRequired,
  requestHarvestForecastExport: PropTypes.func.isRequired,
  requestEditForecastVersionInfo: PropTypes.func.isRequired,
  requestDeleteForecastVersionInfo: PropTypes.func.isRequired,
};

HarvestForecastDashboard.defaultProps = {
  forecastVersions: [],
  forecastData: null,
  forecastReportData: null,
  isForecastReportDataFetching: false,
  isShowVersionPanel: false,
  isLoading: false,
  filteredVersions: [],
  harvestForecastAccess: false,
  isAddHarvestForecastAvailable: false,
  versionId: null,
  isDeleteVersionInfoFetching: false,
  isVersionInfoFetching: false,
};

export default HarvestForecastDashboard;
