import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import HorizontalBar from '../HorizontalBar';

import styles from './HorizontalBars.module.css';

export default class HorizontalBars extends Component {
  static propTypes = {
    forceColor: PropTypes.bool,
    scales: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    margins: PropTypes.object.isRequired,
    svgDimensions: PropTypes.object.isRequired,
    withCompareButton: PropTypes.bool,
    isShowLocationName: PropTypes.bool,
    hoveredItem: PropTypes.number,

    onMouseOverCallback: PropTypes.func,
    onMouseOutCallback: PropTypes.func,
    onClickCallback: PropTypes.func,
  };

  static defaultProps = {
    withCompareButton: false,
    isShowLocationName: true,
    hoveredItem: null,
    forceColor: false,

    onMouseOverCallback: () => {},
    onMouseOutCallback: () => {},
    onClickCallback: () => {},
  };

  render() {
    const {
      scales, items, margins, svgDimensions, withCompareButton, isShowLocationName,
      hoveredItem, forceColor,
      onMouseOutCallback,
      onMouseOverCallback,
      onClickCallback,
    } = this.props;

    const { xScale, yScale } = scales;
    const bars = items.map(item => (
      <HorizontalBar
        key={`bar-rect-${item.id}`}
        forceColor={forceColor}
        item={item}
        scales={{ xScale, yScale }}
        margins={margins}
        svgDimensions={svgDimensions}
        withCompareButton={withCompareButton}
        isShowLocationName={isShowLocationName}
        isHovered={hoveredItem === item.id}
        onMouseOutCallback={onMouseOutCallback}
        onMouseOverCallback={onMouseOverCallback}
        onClickCallback={onClickCallback}
      />
    ));

    return (
      <div className={styles.horizontalBars}>{bars}</div>
    );
  }
}
