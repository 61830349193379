import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Legend.module.css';

export default class Legend extends Component {
  static propTypes = {
    lines: PropTypes.array,
    columnView: PropTypes.bool,
    className: PropTypes.string,
    width: PropTypes.number
  };

  static defaultProps = {
    lines: [],
    columnView: false,
    className: null,
    width: null
  };

  getItem = (line, i) => (
    <div className={styles.legendItem} key={`legendItem-${i}`}>
      <div className={styles.legendItemColor}>
        <div className={styles.legendItemColorSquare} style={{ backgroundColor: line.color }} />
      </div>
      <div className={styles.legendItemTitle}>{line.name} {line.description && <span className={styles.legendItemSubTitle}>({line.description})</span>}</div>
    </div>
  );

  render() {
    const { lines, columnView, width } = this.props;
    const renderedLines = lines.filter(line => line.showInLegend !== false).map(this.getItem);

    const wrapperClassName = this.props?.className ?
      classnames(styles.legend, this.props?.className) : styles.legend;

    const style = width ? {
      width
    } : { };

    return (
      <div
        className={wrapperClassName}
        style={style}
      >
        {columnView ?
          <div className={styles.columnContainer}>{renderedLines}</div>
          :
          renderedLines}
      </div>
    );
  }
}
