import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router';

import { get } from 'lodash';

import { getLocation, clearCompanyStore } from '../../../store/company/actions';

import {
  setToggleErrorDialog,
  setTogglePhotoDeleteDialog,
  setTogglePhotoUploadDialog,
  setToggleCropCreateDialog,
  setTogglePhotoDetailDialog,
  setToggleGraphPresetDeleteDialog,
  setToggleGraphPresetUpdateDialog,
  setToggleGraphPresetCreateDialog,
  setToggleGraphPresetDatesDialog,
  setToggleGraphPresetParametersDialog,
  setToggleTableCellEditDialog,
} from '../../../store/dialogs';

import setIntl from '../../../store/localization';
import { clearCardsState } from '../../../store/dashboards/actions';
import { setNavigationMenuState } from '../../../store/navigationMenu/actions';

/*  This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component - in this case, the counter:   */

import Layout from '../components/Layout';
import { getFetching, getOrganization } from '../../../store/company/selectors';

/*  Object of action creators (can also be function that returns object).
 Keys will be passed as props to presentational components. Here we are
 implementing our wrapper around increment; the component doesn't care   */

const mapDispatchToProps = {
  getLocation,
  setIntl,
  setToggleErrorDialog,
  setTogglePhotoDeleteDialog,
  setTogglePhotoUploadDialog,
  setToggleCropCreateDialog,
  setTogglePhotoDetailDialog,
  setToggleGraphPresetDeleteDialog,
  setToggleGraphPresetUpdateDialog,
  setToggleGraphPresetCreateDialog,
  setToggleGraphPresetDatesDialog,
  setToggleGraphPresetParametersDialog,
  setToggleTableCellEditDialog,
  clearCardsState,
  setNavigationMenuState,
  clearCompanyStore,
};

const mapStateToProps = (state, props) => {
  const {
    company, dialogs, intl, sidePanel, navigationMenu,
  } = state;

  /**
   * Проверяем находимся ли в урле crops и получаем параметр cycleId
   * это необходимо, т.к. этот path прописан в компоненте более низкого уровня,
   * из-за чего в этом компоненте в match.params не приходит cycleId
   */
  const cropsMatch = matchPath(props.location.pathname, {
    path: '/:organizationSlug/crops/:cycleId',
    exact: false,
    strict: false
  });

  const plansMatch = matchPath(props.location.pathname, {
    path: '/:organizationSlug/plans',
    exact: true,
    strict: false
  });

  const plansEditMatch = matchPath(props.location.pathname, {
    path: '/:organizationSlug/plans/:planType/:planId/edit',
    exact: false,
    strict: false
  });

  // Параметр нужен для проверки находимся ли мы в конкретном planting cycle
  const isCycleIdExist = !!get(cropsMatch, 'params.cycleId');

  // Параметр нужен для проверки находимся ли мы в редактировании конкретного плана (т.к. он специфичен для локации)
  const isPlanIdExist = !!get(plansEditMatch, 'params.planId');

  const isNoScroll = !!plansMatch;

  const {
    location,
    ...otherCompany
  } = company;

  return {
    ...otherCompany,
    organization: getOrganization(state, props),
    ...dialogs,
    ...intl,
    ...sidePanel,
    ...navigationMenu,
    isFetching: getFetching(state),
    isLocationSpecificUrl: isCycleIdExist || isPlanIdExist,
    isNoScroll,
  };
};

/*  Note: mapStateToProps is where you should use `reselect` to create selectors, ie:

 import { createSelector } from 'reselect'
 const counter = (state) => state.counter
 const tripleCount = createSelector(counter, (count) => count * 3)
 const mapStateToProps = (state) => ({
 counter: tripleCount(state)
 })

 Selectors can compute derived data, allowing Redux to store the minimal possible state.
 Selectors are efficient. A selector is not recomputed unless one of its arguments change.
 Selectors are composable. They can be used as input to other selectors.
 https://github.com/reactjs/reselect    */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
