import { Observable } from 'rxjs';

import { get } from 'lodash';

import initAxiosInstanse from '../../api/axios';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

import {
  requestUserPrivileges,
  receiveUserPrivileges,

  requestUpdateUserInfo,
  receiveUpdateUserInfo,
  requestUpdateUserLocale,
  receiveUpdateUserLocale
} from './actions';

export const getUserPrivilegesEpic = (action$, store) =>
  action$.ofType(requestUserPrivileges)
    .switchMap(() => {
      const {
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      return Observable
        .from(initAxiosInstanse().get(`/locations/${organizationId}/user-privileges`))
        .mergeMap(({ data }) => Observable.of(receiveUserPrivileges({ userPrivileges: data })))
        .catch(() => Observable.of(
          receiveUserPrivileges({ userPrivileges: null }),
          showNotificationWithTimeout({
            id: `notifications.getUserPrivileges.${Date.now()}`,
            messageId: 'notifications.getUserPrivileges',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const updateUserInfoEpic = action$ =>
  action$.ofType(requestUpdateUserInfo)
    .switchMap((actionData) => {
      const {
        payload: {
          name,
        }
      } = actionData;


      return Observable
        .from(initAxiosInstanse().post('/user-profile/update', {
          name,
        }))
        .mergeMap(({ status, data }) => {
          if (status !== 200) {
            return Observable.of(
              receiveUpdateUserInfo(),
              showNotificationWithTimeout({
                id: `settings.notificationUpdateUserInfoError.${Date.now()}`,
                messageId: 'settings.notificationUpdateUserInfoError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withActionWide',
              }),
            );
          }

          return Observable.of(
            receiveUpdateUserInfo({ userProfile: get(data, 'data.[0]') }),
            showNotificationWithTimeout({
              id: `settings.notificationUpdateUserInfo.${Date.now()}`,
              messageId: 'settings.notificationUpdateUserInfo',
              position: 'leftDown',
              iconType: 'success',
              notificationType: 'withActionWide',
            }),
          );
        })
        .catch(() => Observable.of(
          receiveUpdateUserInfo(),
          showNotificationWithTimeout({
            id: `settings.notificationUpdateUserInfoError.${Date.now()}`,
            messageId: 'settings.notificationUpdateUserInfoError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });


export const updateUserLocaleEpic = (action$, store) =>
  action$.ofType(requestUpdateUserLocale)
    .switchMap((actionData) => {
      const {
        company,
      } = store.getState();

      const {
        user,
      } = company;
      const { id: userId } = user;

      const {
        payload
      } = actionData;

      return Observable
        .from(initAxiosInstanse().put(`/user-settings/${userId}/language`, { value: payload?.languageKey }))
        .mergeMap(() => {
          const observables = [
            receiveUpdateUserLocale()
          ];
          if (payload?.actionSuccess) {
            observables.push(() => payload?.actionSuccess());
          }
          return observables.length > 0 ? Observable.of(...observables) : Observable.empty();
        })
        .catch(() => Observable.of(
          receiveUpdateUserLocale(),
          showNotificationWithTimeout({
            id: `notifications.updateUserLocale.${Date.now()}`,
            messageId: 'notifications.updateUserLocaleError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });
