import classnames from 'classnames';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import Photos from '../Photos';
import CircleLoader from '../../../CircleLoader';

import styles from './PhotosWrapper.module.css';


class PhotosWrapper extends Component {
  static propTypes = {
    location: PropTypes.object,
    allCompartments: PropTypes.array,
    photoCategories: PropTypes.array,
    photoDetailDialogOpen: PropTypes.bool.isRequired,
    photoUploadDialogOpen: PropTypes.bool.isRequired,
    photoDeleteDialogOpen: PropTypes.bool.isRequired,

    getPhotoCategories: PropTypes.func.isRequired,

    isFetching: PropTypes.bool.isRequired,
    isPhotosFetching: PropTypes.bool.isRequired,

    cycleId: PropTypes.string,
  };

  static defaultProps = {
    allCompartments: [],
    photoCategories: [],
    location: null,
    cycleId: null,
  };

  componentDidMount() {
    const { getPhotoCategories, photoCategories } = this.props;

    if (!photoCategories.length && getPhotoCategories) {
      getPhotoCategories();
    }
  }

  render() {
    const {
      allCompartments,
      photoCategories,
      location,
      photoDetailDialogOpen,
      photoUploadDialogOpen,
      photoDeleteDialogOpen,

      isFetching,
      isPhotosFetching,
      cycleId,
    } = this.props;

    if (location && allCompartments && allCompartments.length && photoCategories && photoCategories.length && !isFetching) {
      return (
        <Photos
          isFetching={isFetching || isPhotosFetching}
          photoDetailDialogOpen={photoDetailDialogOpen}
          photoUploadDialogOpen={photoUploadDialogOpen}
          photoDeleteDialogOpen={photoDeleteDialogOpen}
          cycleId={cycleId}
        />
      );
    }
    return (
      <div style={{ margin: '0 auto' }}>
        <CircleLoader iconClassName={classnames(styles.circleLoader)} />
      </div>
    );
  }
}

export default PhotosWrapper;
