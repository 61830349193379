import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { showIntercom } from 'helpers/intercomHelpers';

import DefaultPlate from 'components/DefaultPlate';
import BigButton from 'components/BigButton';

import { getNameByLocal } from 'helpers/getNameByLocal';
import { getUnits } from 'helpers/getValueDetails';

import styles from './ModelSettings.module.css';

const ParameterBlock = ({
  intl,
  parameter,
  max,
  min,
  value,
  presentType
}) => {
  const { locale, formatMessage } = intl;
  const name = getNameByLocal(parameter, locale);
  const unit = getUnits(parameter, formatMessage, 'unit');
  let valueLocalizedString = '';
  const optimizationTypeLocalizedString = formatMessage({
    id: `unrealizedPotential.optimizationType.${parameter?.optimizationType}`
  });
  switch (parameter?.optimizationType) {
    case 'const': {
      valueLocalizedString = `${value}, ${optimizationTypeLocalizedString}`;
      break;
    }
    case 'optimizeInInterval': {
      valueLocalizedString = `${min} – ${max}, ${optimizationTypeLocalizedString}`;
      break;
    }
    case 'useActualData': {
      valueLocalizedString = `${optimizationTypeLocalizedString}`;
      break;
    }
    default: { // nop
      break;
    }
  }

  if (presentType === 'table') {
    return (
      <tr>
        <td className={classnames(styles.lineTitle, styles.trTitle)}>{name}, {unit}:</td>
        <td className={styles.lineValue}>{valueLocalizedString}</td>
      </tr>
    );
  }

  return (
    <div className={styles.settingLine}>
      <div className={styles.lineTitle}>{name}, {unit}:</div>
      <div className={styles.lineValue}>{valueLocalizedString}</div>
    </div>
  );
};

ParameterBlock.propTypes = {
  intl: intlShape.isRequired,
  parameter: PropTypes.shape({
    code: PropTypes.string.isRequired,
    optimizationType: PropTypes.oneOf(['const', 'optimizeInInterval', 'useActualData']).isRequired,
    unit: PropTypes.string.isRequired,
    name: PropTypes.object.isRequired
  }).isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  presentType: PropTypes.oneOf(['block', 'table']),
};

ParameterBlock.defaultProps = {
  min: undefined,
  max: undefined,
  value: undefined,
  presentType: 'block'
};

const ModelSettings = ({ intl, modelSettings }) => {
  const { formatMessage } = intl;
  const MODEL_PARAMS_ORDERING = [
    'targetFruitWeight', 'plantingDensity', 'fruitsPerTruss', 'floweringSpeed',
    'ripeningTime', 'sunRadiation', 'artificialLight', 'temperature',
  ];
  const getParamOrderNumByCode = code => MODEL_PARAMS_ORDERING.findIndex(p => p === code);
  const sortedModelSettings = modelSettings
    .sort((a, b) => (getParamOrderNumByCode(a) - getParamOrderNumByCode(b)));
  const bisectIndex = Math.floor(sortedModelSettings.length / 2);
  const leftColumnSettings = sortedModelSettings.slice(0, bisectIndex);
  const rightColumnSettings = sortedModelSettings.slice(bisectIndex);
  return (
    <div className={styles.settingsBlock}>
      <div className={styles.title}>
        <FormattedMessage id='unrealizedPotential.modelSettings' />
      </div>
      <DefaultPlate className={styles.infoPlate}>
        <div>
          <FormattedMessage id='unrealizedPotential.toChangeModelSettings' />
          &nbsp;
          <BigButton
            className={styles.supportButton}
            onClick={showIntercom}
            title={formatMessage({ id: 'unrealizedPotential.contactSupport' })}
            theme='transparent'
          />.
        </div>
      </DefaultPlate>
      <div className={styles.valuesContainer}>
        <div className={styles.values}>
          {leftColumnSettings.map(p => (<ParameterBlock key={`params-key-${p.parameter.code}`} {...p} intl={intl} />))}
        </div>
        <div className={styles.values}>
          {rightColumnSettings.map(p => (<ParameterBlock key={`params-key-${p.parameter.code}`} {...p} intl={intl} />))}
        </div>
      </div>

      <div className={styles.valuesContainerFullWidth}>
        <table className={styles.tableValues}>
          {leftColumnSettings.map(p => (
            <ParameterBlock
              key={`params-key-${p.parameter.code}`}
              {...p}
              intl={intl}
              presentType='table'
            />
          ))}
        </table>
        <table className={styles.tableValues}>
          {rightColumnSettings.map(p => (
            <ParameterBlock
              key={`params-key-${p.parameter.code}`}
              {...p}
              intl={intl}
              presentType='table'
            />
          ))}
        </table>
      </div>

    </div>
  );
};

ModelSettings.propTypes = {
  intl: intlShape.isRequired,
  modelSettings: PropTypes.array.isRequired
};

export default injectIntl(ModelSettings);
