import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getBenchmarkGroups,
  getIsBenchmarkGroupsFetching,
  getBenchmarkOverview,
  getIsBenchmarkOverviewFetching,
  getAvailableAdditionalMetrics,
  getCyclesToCompare,
} from 'store/benchmark/selectors';

import {
  requestBenchmarkGroups,
  requestBenchmarkOverview,
  requestBenchmarkMetrics,
} from 'store/benchmark/actions';

import {
  getFirstSpecies,
  getSpeciesFromRoute,
  getVarietyIdFromRoute,
  getVarietiesInLocationWithTargetWeight,
  getCurrentCompartments,
  getCompartmentIdFromRoute,
  getIsBenchmarkAvailable,
  getBenchmarkGroupIdFromRoute,
  getAdditionalMetricIdFromRoute,
  getViewByFromRoute,
  getEndDateFromRoute,
  getStartDateFromRoute,
  getAllFruitClasses,
  getFruitClassFromRoute,
  getAllPlantingCycles,
} from 'store/company/selectors';


import BenchmarkDashboard from '../components/BenchmarkDashboard';

const mapDispatchToProps = {
  requestBenchmarkGroups,
  requestBenchmarkOverview,
  requestBenchmarkMetrics,
};

const mapStateToProps = (state, props) => ({
  ...props,

  benchmarkOverview: getBenchmarkOverview(state),
  isBenchmarkOverviewFetching: getIsBenchmarkOverviewFetching(state),
  benchmarkGroups: getBenchmarkGroups(state),
  isBenchmarkGroupsFetching: getIsBenchmarkGroupsFetching(state),
  isBenchmarkAvailable: getIsBenchmarkAvailable(state),

  varieties: getVarietiesInLocationWithTargetWeight(state, props),
  fruitClasses: getAllFruitClasses(state, props),
  currentGrownFilter: getVarietyIdFromRoute(state, props) || getFruitClassFromRoute(state, props) || getSpeciesFromRoute(state, props) || getFirstSpecies(state),
  compartments: getCurrentCompartments(state),
  currentCompartment: getCompartmentIdFromRoute(state, props),
  currentBenchmarkGroup: getBenchmarkGroupIdFromRoute(state, props),
  currentAdditionalMetricId: getAdditionalMetricIdFromRoute(state, props),
  currentViewBy: getViewByFromRoute(state, props),
  currentVariety: getVarietyIdFromRoute(state, props),
  currentFruitClass: getFruitClassFromRoute(state, props),
  currentSpecies: getSpeciesFromRoute(state, props) || getFirstSpecies(state),
  availableAdditionalMetrics: getAvailableAdditionalMetrics(state, props),

  startDate: getStartDateFromRoute(state, props),
  endDate: getEndDateFromRoute(state, props),
  allPlantingCycles: getAllPlantingCycles(state, props),

  cyclesToCompare: getCyclesToCompare(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(BenchmarkDashboard)));
