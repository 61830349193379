import PropTypes from 'prop-types';
import React, { Component, } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router';


import UpdatePlantingCycle from '../UpdatePlantingCycle';
import CircleLoader from '../../../CircleLoader';

import styles from './UpdatePlantingCycleWrapper.module.css';
import loaderStyles from '../../../CircleLoader/CircleLoader.module.css';


class UpdatePlantingCycleWrapper extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    varieties: PropTypes.array,
    fruitClasses: PropTypes.array,
    organization: PropTypes.object,
    updatedPlantingCycle: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        cycleId: PropTypes.string,
        tabName: PropTypes.string,
      })
    }).isRequired,
    allCompartments: PropTypes.array,
    createPlantingCycleForm: PropTypes.object,
    updatePlantingCycleStart: PropTypes.func.isRequired,
    getPlantingCycleStart: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isUpdatePlantingCycleFetching: PropTypes.bool.isRequired,
    fromPage: PropTypes.string,
    clearHarvestData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fruitClasses: [],
    varieties: [],
    allCompartments: [],
    organization: null,
    updatedPlantingCycle: null,
    createPlantingCycleForm: {},
    fromPage: null,
  };

  render() {
    const {
      updatedPlantingCycle,
      intl,
      fruitClasses,
      varieties,
      organization,
      allCompartments,
      createPlantingCycleForm,
      updatePlantingCycleStart,
      getPlantingCycleStart,
      isFetching,
      isUpdatePlantingCycleFetching,
      fromPage,
      match,
      clearHarvestData,
    } = this.props;

    if (isFetching || !organization || varieties.length === 0) {
      return (
        <div className={styles.layout}>
          <CircleLoader
            className={loaderStyles.circleLoader}
            iconClassName={loaderStyles.circleLoaderIcon}
          />
        </div>
      );
    }

    return (
      <UpdatePlantingCycle
        intl={intl}
        varieties={varieties}
        fruitClasses={fruitClasses}
        cycleId={match.params.cycleId}
        organization={organization}
        plantingCycle={updatedPlantingCycle}
        allCompartments={allCompartments}
        createPlantingCycleForm={createPlantingCycleForm}
        updatePlantingCycleStart={updatePlantingCycleStart}
        getPlantingCycleStart={getPlantingCycleStart}
        isFetching={isFetching}
        isUpdatePlantingCycleFetching={isUpdatePlantingCycleFetching}
        fromPage={fromPage}
        clearHarvestData={clearHarvestData}
      />
    );
  }
}

export default injectIntl(withRouter(UpdatePlantingCycleWrapper));
