import { format } from 'd3';

/**
 * Форматирует tick графика в зависимости от его значения,ъ
 * чтобы крупные числа не вылезали за пределы графика
 *
 * @param {number} d - Значение тика графика (число).
 * @return {string} Отформатированное значение тика.
 */
export const formatTicksByMask = (d) => {
  // Иначе 0.5 выводится как 500m, а, например, 120 округляется до 100
  if (Math.abs(d) > 999) {
    /**
     * TODO: обновить версию d3 и поменять этот код на d3.format('~s')(d)
     *
     * Если применить шаблон d3.format('s'), то 100000 будет выводиться как 100.000k
     * если применить шаблон d3.format('.0s'), то 150000 будет выводиться как 200k
     * а нам нужно округлять с буквами k и M, но без лишних нулей, поэтому добавляем
     * маску формата округления, в зависимости от значения числа.
     * В новой версии d3-format (начиная с v1.3.0) появилась опция d3.format('~s'),
     * она делает как раз что надо, только нужно обновить версию d3
     */
    const formatMask = `.${d.toString().match(/[1-9]/g).length}s`;

    return format(formatMask)(d);
  }

  return format(',')(d);
};

export default formatTicksByMask;
