/**
 * Check if element content truncated
 * @param el
 * @returns {boolean}
 */
function isTruncated(el) {
  return el && el.offsetWidth < el.scrollWidth;
}

export default isTruncated;
