import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import classnames from 'classnames';

import DropdownMenu from 'components/DropdownMenu';
import BigButton from 'components/BigButton';
import DeleteIcon from 'components/Icons/Delete';

import styles from './DeletePopover.module.css';

const handlerCancel = (onCancel, setCloseDropdown) => {
  if (onCancel) {
    onCancel();
  }

  setCloseDropdown(true);
};

const handlerDelete = (onDelete, setCloseDropdown) => {
  if (onDelete) {
    onDelete();
  }

  setCloseDropdown(true);
};

const renderButton = (formatMessage, setCloseDropdown) => (
  <div className={styles.deleteWrapper}>
    <BigButton
      className={classnames(styles.delete)}
      icon={<DeleteIcon className={styles.deleteIcon} />}
      category='icon'
      tooltip={formatMessage({ id: 'forecast.deleteForecast' })}
      onClick={() => setCloseDropdown(false)}
    />
  </div>
);

const DeletePopover = ({
  intl,
  onDelete,
  onCancel
}) => {
  const { formatMessage } = intl;

  const [closeDropdown, setCloseDropdown] = useState(false);

  return (
    <DropdownMenu
      contentClassName={classnames(styles.dropdownMenu)}
      buttonElement={renderButton(formatMessage, setCloseDropdown)}
      withArrow
      closeDropdown={closeDropdown}
    >
      <div>
        <div className={styles.deleteTooltipTitle}>
          {formatMessage({ id: 'forecast.deleteVerify' })}
        </div>
        <div className={styles.deleteTooltipActions}>
          <div className={styles.deleteTooltipActionCancel} role='button' tabIndex='0' onClick={() => handlerCancel(onCancel, setCloseDropdown)}>{formatMessage({ id: 'button.cancel' })}</div>
          <div className={styles.deleteTooltipActionOk} role='button' tabIndex='0' onClick={() => handlerDelete(onDelete, setCloseDropdown)}>{formatMessage({ id: 'button.confirmDelete' })}</div>
        </div>
      </div>
    </DropdownMenu>
  );
};

DeletePopover.propTypes = {
  intl: intlShape.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default injectIntl(DeletePopover);
