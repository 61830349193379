import React, { memo, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import BigButton from 'components/BigButton';
import HasChanges from '../HasChanges';

import styles from './MeasurementsSaveControls.module.css';

const MeasurementsSaveControls = ({
  intl,
  isFetching,
  onCancel,
  onSubmit,
  onSubmitHasChanges,
}) => {
  const { formatMessage } = intl;

  return (
    <>
      <div className={styles.controls}>
        <HasChanges onSubmit={onSubmitHasChanges} />
        <BigButton
          className={styles.button}
          title={formatMessage({ id: 'button.cancel' })}
          type='button'
          theme='light'
          onClick={onCancel}
          size='small'
        />
        <BigButton
          isLoading={isFetching}
          className={styles.button}
          title={formatMessage({ id: 'crops.saveMeasurements' })}
          type='button'
          theme='dark'
          onClick={onSubmit}
          size='small'
        />
      </div>
    </>
  );
};

MeasurementsSaveControls.propTypes = {
  intl: intlShape.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitHasChanges: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

MeasurementsSaveControls.defaultProps = {
  isFetching: false,
};

export default injectIntl(memo(MeasurementsSaveControls));
