import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import {
  getIsAddOperationalPlansAvailable,
  getIsAddBudgetPlansAvailable,
} from 'store/company/selectors';

import {
  requestGetHarvestPlansList,
  clearHarvestPlans,
} from 'store/plans/actions';

import {
  getYear,
  getHarvestPlansList,
  getIsHarvestPlansListFetching,
} from 'store/plans/selectors';

import { trackPageViewPlans } from 'store/company/actions';

import PlansList from './PlansList';


const mapDispatchToProps = {
  requestGetHarvestPlansList,
  clearHarvestPlans,
  trackPageViewPlans,
};

const mapStateToProps = (state, props) => ({
  showedYear: getYear(state, props),

  harvestPlansList: getHarvestPlansList(state, props),
  isHarvestPlansListFetching: getIsHarvestPlansListFetching(state, props),
  isAddOperationalPlansAvailable: getIsAddOperationalPlansAvailable(state),
  isAddBudgetPlansAvailable: getIsAddBudgetPlansAvailable(state),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PlansList));
