import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';

import moment from 'moment-timezone';
import classnames from 'classnames';
import { get, first } from 'lodash';

import addGetParameters from 'helpers/addGetParameters';
import storageWrapper from 'helpers/storageWrapper';
import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';
import { getMlName } from 'helpers/getVarietyName';
import sortByLocal from 'helpers/sortByLocal';
import { API_DATE_FORMAT } from 'helpers/defaultDates';

import Select from 'components/Select';
import DefaultTable from 'components/DefaultTable';
import DefaultBarChart from 'components/DefaultBarChart';
import SelectBoxFilter from 'components/SelectBoxFilter';

import styles from './PlansSummary.module.css';

import EmptyState from '../../../EmptyState';
import CircleLoader from '../../../CircleLoader';
import loaderStyles from '../../../CircleLoader/CircleLoader.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');


export default class PlansSummary extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    allCompartments: PropTypes.array,
    allVarieties: PropTypes.array,
    fruitClasses: PropTypes.array,
    history: PropTypes.object.isRequired,
    relative: PropTypes.string,
    groupedBy: PropTypes.string,
    organization: PropTypes.object.isRequired,
    year: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    harvestPlanSummary: PropTypes.object,
    harvestPlanEdited: PropTypes.object,
    isDetachedComponent: PropTypes.bool,
    isPlanVersionSummaryFetching: PropTypes.bool.isRequired,
    periodLength: PropTypes.string,
    locationPlan: PropTypes.number,
    isOperationalPlan: PropTypes.bool,

    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
    productGroup: PropTypes.array,
    compartmentId: PropTypes.array,
    species: PropTypes.string,
    fruitClassCode: PropTypes.string,
    varietyId: PropTypes.string,

    requestPlanVersionSummary: PropTypes.func.isRequired,
    receivePlanVersionSummary: PropTypes.func.isRequired,
  };

  static defaultProps = {
    allCompartments: [],
    allVarieties: [],
    fruitClasses: [],
    relative: 'true',
    groupedBy: 'FruitClass',
    year: moment().format('YYYY'),
    harvestPlanSummary: null,
    harvestPlanEdited: null,
    locationPlan: null,
    isDetachedComponent: false,
    periodLength: 'month',
    isOperationalPlan: false,

    anyDateOfPeriodStart: undefined,
    anyDateOfPeriodEnd: undefined,
    productGroup: undefined,
    compartmentId: undefined,
    species: undefined,
    fruitClassCode: undefined,
    varietyId: undefined,
  };

  state = {
    selectedSpecies: null,
    year: this.props.year,
    relative: this.props.relative,
    groupedBy: this.props.groupedBy,
    periodLength: this.props.periodLength,
  };

  componentDidMount() {
    const {
      harvestPlanSummary,
      harvestPlanEdited,
      relative,
      groupedBy,
      year,
      isDetachedComponent,
      locationPlan,
      periodLength,
      isOperationalPlan,
    } = this.props;

    if (!harvestPlanSummary && (harvestPlanEdited || isDetachedComponent)) {
      this.requestSummary({
        relative,
        groupedBy,
        year,
        isDetachedComponent,
        locationPlan,
        periodLength,
        isOperationalPlan,
      });
    }
  }

  // TODO: Убрать запрос параметров при componentDidUpdate, иначе выпадаем в бесконечный цикл при ошибке на бэке
  componentDidUpdate(prevProps, prevState) {
    const {
      harvestPlanEdited: nextHarvestPlanEdited,
      harvestPlanSummary,
      year: propsYear,
      relative: propsRelative,
      groupedBy: propsGroupedBy,
      isDetachedComponent,
      locationPlan,
      periodLength,
      isOperationalPlan,
    } = this.props;

    const {
      year: stateYear,
      relative: stateRelative,
      groupedBy: stateGroupedBy,
    } = this.state;

    const year = isDetachedComponent ? stateYear : propsYear;
    const relative = isDetachedComponent ? stateRelative : propsRelative;
    const groupedBy = isDetachedComponent ? stateGroupedBy : propsGroupedBy;

    const {
      harvestPlanEdited: prevHarvestPlanEdited,
    } = prevProps;

    const { selectedSpecies: prevSelectedSpecies } = prevState;

    const species = Object.keys(get(harvestPlanSummary, 'species', {}) || {})
      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));

    if ((!harvestPlanSummary && isDetachedComponent) || (!harvestPlanSummary && nextHarvestPlanEdited) || (get(nextHarvestPlanEdited, 'id') !== get(prevHarvestPlanEdited, 'id'))) {
      this.requestSummary({
        relative,
        groupedBy,
        year,
        isDetachedComponent,
        locationPlan,
        periodLength,
        isOperationalPlan,
      });
    }

    if (!prevSelectedSpecies && species && species.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedSpecies: first(species) });
    }
  }

  componentWillUnmount() {
    const { receivePlanVersionSummary } = this.props;

    receivePlanVersionSummary({ harvestPlanSummary: null });
  }

  requestSummary = (params) => {
    const {
      requestPlanVersionSummary,

      isDetachedComponent,

      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      productGroup,
      compartmentId,
      species,
      fruitClassCode,
      varietyId,
    } = this.props;

    const filters = !isDetachedComponent ? {
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      productGroup,
      compartmentId,
      species,
      fruitClassCode,
      varietyId,
    } : {};

    requestPlanVersionSummary({
      ...filters,

      ...params,
    });
  };

  getUnitsList = (units, species, formatMessage) => units.reduce((acc, unit) => {
    // const vegUnit = unit === 'relative' ? 'kilogramPerSquareMeter' : 'kilogram';
    const label = species === 'lettuce' ? formatMessage({ id: 'cunits.mini.count' }) : formatMessage({ id: `cunits.mini.${unit}` });

    return [...acc, { label, value: unit }];
  }, []);

  getFormattedValue = (value) => {
    const {
      relative: propsRelative,
      isDetachedComponent,
    } = this.props;

    const {
      relative: stateRelative,
      selectedSpecies,
    } = this.state;


    const usedRelative = isDetachedComponent ? stateRelative : propsRelative;
    const roundTo = usedRelative !== 'true' || selectedSpecies === 'lettuce' ? 0 : 1;

    return numbersFormatting(numbersRounding(value, 'fixed', roundTo));
  };

  handlerRelativeChange = ({ value }) => this.setNewFilters({
    relative: value === 'kilogramPerSquareMeter' ? 'true' : 'false',
  });

  handlerGroupedByChange = ({ value }) => this.setNewFilters({
    groupedBy: value,
  });

  handlerPeriodLengthChange = ({ value }) => this.setNewFilters({
    periodLength: value,
  });;

  setNewFilters = (newParams) => {
    const {
      history,
      year: propsYear,
      groupedBy: propsGroupedBy,
      relative: propsRelative,
      periodLength: propsPeriodLength,
      isDetachedComponent,
      locationPlan,
      isOperationalPlan,
    } = this.props;

    const {
      year: stateYear,
      groupedBy: stateGroupedBy,
      relative: stateRelative,
      periodLength: statePeriodLength,
    } = this.state;

    const oldParameters = {
      year: isDetachedComponent ? stateYear : propsYear,
      groupedBy: isDetachedComponent ? stateGroupedBy : propsGroupedBy,
      relative: isDetachedComponent ? stateRelative : propsRelative,
      periodLength: isDetachedComponent ? statePeriodLength : propsPeriodLength,
    };

    if (isDetachedComponent) {
      this.setState({
        ...newParams,
      });
    } else {
      const searchString = addGetParameters(get(history, 'location.search'), { ...newParams });

      history.push({
        search: searchString
      });
    }

    this.requestSummary({
      isDetachedComponent,
      locationPlan,
      isOperationalPlan,

      ...oldParameters,
      ...newParams
    });
  };

  renderHeaderRows = () => {
    const {
      intl: { formatMessage },
      groupedBy: propsGroupedBy,
      isDetachedComponent,
      harvestPlanSummary
    } = this.props;
    const { groupedBy: stateGroupedBy } = this.state;

    const groupedBy = isDetachedComponent ? stateGroupedBy : propsGroupedBy;

    const currentPeriodLength = harvestPlanSummary?.descriptor?.periodLength;
    const periods = harvestPlanSummary?.descriptor?.periods || [];

    const datesArray = currentPeriodLength === 'week' ?
      periods.map(({ periodNumber, startDate, endDate }) => {
        const weekDates = `${moment(startDate, API_DATE_FORMAT).format('MMM D')} – ${moment(endDate, API_DATE_FORMAT).format('MMM D')}`;

        return `${weekDates}, ${formatMessage({ id: 'cycle.wk' })} ${periodNumber}`;
      })
      :
      periods.map(({ periodNumber }) => moment().month(periodNumber - 1).format('MMM'));

    let headerTitle = <FormattedMessage id='plans.type' />;

    if (groupedBy === 'Compartment') {
      headerTitle = <FormattedMessage id='plans.greenhouse' />;
    } else if (groupedBy === 'Variety') {
      headerTitle = <FormattedMessage id='plans.variety' />;
    } else if (groupedBy === 'FruitClass') {
      headerTitle = <FormattedMessage id='plans.type' />;
    }

    return (
      <tr className={styles.tableHeaderRow}>
        <th className={styles.tableHeaderRowCell}>{headerTitle}</th>
        {datesArray.map(date => (
          <th className={classnames(styles.tableHeaderRowCell, styles.alignRight)} key={`th-${date.toLowerCase()}`}>{date}</th>
        ))}
        <th className={classnames(styles.tableHeaderRowCell, styles.alignRight)}><FormattedMessage id='dashboards.totalTableAll' /></th>
      </tr>
    );
  };

  renderBodyRows = () => {
    const {
      harvestPlanSummary,
      groupedBy: propsGroupedBy,
      allCompartments,
      allVarieties,
      fruitClasses,
      isDetachedComponent,
    } = this.props;

    const {
      selectedSpecies,
      groupedBy: stateGroupedBy,
    } = this.state;

    const groupedBy = isDetachedComponent ? stateGroupedBy : propsGroupedBy;

    const rows = get(harvestPlanSummary, `species.${selectedSpecies}.rows`, []);
    const totalRow = get(harvestPlanSummary, `species.${selectedSpecies}.total`, []);

    const locale = safeLocalStorage.getItem('locale') || 'en';

    /* eslint-disable react/no-array-index-key */
    if (!rows || !rows.length) {
      return new Array(3).fill(true).map((item, k) => (
        <tr className={styles.tableRow} key={`tr-${k}`}>
          <td className={classnames(styles.tableRowCellEmpty)} />
          {new Array(12).fill(true).map((tdItem, tdKey) => <td key={`tableRowCellTd-${tdKey}`} className={classnames(styles.tableRowCellEmpty)} />)}
          <td className={classnames(styles.tableRowCellEmpty)} />
        </tr>
      ));
    }

    const rowsWithTitle = rows.map((row) => {
      const id = get(row, 'id');
      let rowTitle;

      if (groupedBy === 'FruitClass') {
        const fruitClass = fruitClasses.find(item => item.id === id);

        rowTitle = getMlName(fruitClass, locale);
      }

      if (groupedBy === 'Compartment') {
        const compartment = allCompartments.find(item => item.id === id);

        rowTitle = get(compartment, 'attributes.name');
      }

      if (groupedBy === 'Variety') {
        const currentVariety = allVarieties.find(item => item.id === id);

        rowTitle = getMlName(currentVariety, locale);
      }

      return {
        ...row,
        rowTitle,
      };
    });

    const sortedRows = sortByLocal(rowsWithTitle, 'rowTitle');

    /* eslint-enable react/no-array-index-key */
    const speciesRows = sortedRows.map((row) => {
      const id = get(row, 'id');
      const total = get(row, 'total');
      const data = get(row, 'data');
      const title = get(row, 'rowTitle');

      return (
        <tr className={styles.tableRow} key={`tr-${id}`}>
          <td className={styles.tableRowCell}>
            {title}
          </td>
          {data.map((item, k) => (
            // eslint-disable-next-line react/no-array-index-key
            <td key={`td-${k}`} className={classnames(styles.tableRowCell, styles.alignRight)}>{this.getFormattedValue(item)}</td>
          ))}
          <td className={classnames(styles.tableRowCell, styles.alignRight)}>{this.getFormattedValue(total)}</td>
        </tr>
      );
    });

    if (speciesRows.length) {
      speciesRows.push((
        <tr className={styles.tableRow} key='tr-total'>
          <td className={styles.tableRowCell}><FormattedMessage id='dashboards.totalTableAll' /></td>
          {totalRow.map((item, k) => (
            // eslint-disable-next-line react/no-array-index-key
            <td key={`td-${k}`} className={classnames(styles.tableRowCell, styles.alignRight)}>{this.getFormattedValue(item)}</td>
          ))}
          <td className={classnames(styles.tableRowCell, styles.alignRight)}>{this.getFormattedValue(totalRow.reduce((reducer, item) => reducer + item, 0))}</td>
        </tr>
      ));
    }

    return speciesRows;
  };

  renderTabList = () => {
    const {
      harvestPlanSummary,
    } = this.props;

    const {
      selectedSpecies,
    } = this.state;

    const species = get(harvestPlanSummary, 'species', {}) || {};

    const speciesArray = Object.keys(species)
      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));

    // Скрываем табы, если только 1 species
    if (speciesArray && speciesArray.length < 2) {
      return <div className={styles.emptyTabs} />;
    }

    return (
      <div className={styles.tabs}>
        {speciesArray.map(item => (
          <div
            key={`tab-${item}`}
            className={classnames(styles.tab, {
              [styles.activeTab]: item === selectedSpecies
            })}
            role='button'
            tabIndex='0'
            onClick={() => this.setState({ selectedSpecies: item })}
          >
            <FormattedMessage id={`plantingCycles.species.${item}`} />
          </div>
        ))}
      </div>
    );
  };

  render() {
    const {
      intl: { formatMessage },
      intl,
      allVarieties,
      allCompartments,
      fruitClasses,
      year: propsYear,
      relative: propsRelative,
      groupedBy: propsGroupedBy,
      periodLength: propsPeriodLength,
      organization,
      harvestPlanSummary,
      isPlanVersionSummaryFetching,
      isDetachedComponent,
    } = this.props;

    const {
      selectedSpecies,
      year: stateYear,
      relative: stateRelative,
      groupedBy: stateGroupedBy,
      periodLength: statePeriodLength,
    } = this.state;

    const year = isDetachedComponent ? stateYear : propsYear;
    const relative = isDetachedComponent ? stateRelative : propsRelative;
    const groupedBy = isDetachedComponent ? stateGroupedBy : propsGroupedBy;
    const periodLength = isDetachedComponent ? statePeriodLength : propsPeriodLength;

    const items = get(harvestPlanSummary, `species.${selectedSpecies}.rows`, []);

    const currentUnits = relative === 'true' ? 'kilogramPerSquareMeter' : 'kilogram';
    const units = selectedSpecies === 'lettuce' ? [currentUnits] : ['kilogramPerSquareMeter', 'kilogram'];
    const unitsList = this.getUnitsList(units, selectedSpecies, formatMessage);

    const groupedByList = [
      { label: formatMessage({ id: 'harvestDashboard.compartments' }), value: 'Compartment' },
      { label: formatMessage({ id: 'harvestDashboard.varieties' }), value: 'Variety' },
      { label: formatMessage({ id: 'harvestDashboard.fruitClass' }), value: 'FruitClass' },
    ];

    const locale = safeLocalStorage.getItem('locale') || 'en';

    const rows = get(harvestPlanSummary, `species.${selectedSpecies}.rows`);
    const isEmpty = !rows || !rows.length;

    const barChartItems = items.map((item) => {
      let title;

      if (groupedBy === 'FruitClass') {
        const fruitClass = fruitClasses.find(x => x.id === get(item, 'id'));

        title = getMlName(fruitClass, locale);
      }

      if (groupedBy === 'Compartment') {
        const compartment = allCompartments.find(x => x.id === get(item, 'id'));

        title = get(compartment, 'attributes.name');
      }

      if (groupedBy === 'Variety') {
        const currentVariety = allVarieties.find(x => x.id === get(item, 'id'));

        title = getMlName(currentVariety, locale);
      }

      return ({
        id: get(item, 'id'),
        name: title,
        value: get(item, 'total')
      });
    });

    const sortedBarChartItems = sortByLocal(barChartItems, 'name');

    const periodLengthOptions = [
      { label: formatMessage({ id: 'dashboards.weekly' }), value: 'week' },
      { label: formatMessage({ id: 'dashboards.monthly' }), value: 'month' },
    ];

    return (
      <div className={classnames(styles.layout, { [styles.isDetachedComponent]: isDetachedComponent })}>
        <div className={classnames(styles.container)}>
          <div className={styles.title}><FormattedMessage id='plans.titleSummary' values={{ year }} /></div>

          {this.renderTabList()}

          <DefaultBarChart
            key={`chart-${groupedBy}-${relative}-${year}`}
            items={sortedBarChartItems}
            getFormattedValue={this.getFormattedValue}
            barClassNames={styles.bar}
            tooltipItems={[]}
            location={organization}
            intl={intl}
            isFetching={isPlanVersionSummaryFetching}
            isEmpty={items && !items.length && !isPlanVersionSummaryFetching}
            height={112}
            emptyTitle={formatMessage({ id: 'harvestDashboard.emptyText' })}
          />

          <div className={styles.filters}>
            <div className={styles.filter}>
              <div className={styles.filterTitle}>
                <FormattedMessage id='plans.groupBy' />:
              </div>
              <Select
                disabled={false}
                options={groupedByList}
                value={groupedBy}
                onChange={this.handlerGroupedByChange}
                classNameButton={styles.filterSelectButton}
                labelPath='label'
                valuePath='value'
                theme='transparent'
                size='medium'
                closeOnChange
              />
            </div>
            <SelectBoxFilter
              value={periodLength}
              options={periodLengthOptions}
              onChange={this.handlerPeriodLengthChange}
              title={`${formatMessage({ id: 'plansList.period' })}:`}
              classNameWrapper={classnames(styles.periodLengthFilterWrapper)}
            />
            <div className={styles.filter}>
              <div className={styles.filterTitle}>
                <FormattedMessage id='plans.units' />:
              </div>
              <Select
                disabled={false}
                options={unitsList}
                value={currentUnits}
                onChange={this.handlerRelativeChange}
                classNameButton={styles.filterSelectButton}
                labelPath='label'
                valuePath='value'
                theme='transparent'
                size='medium'
                closeOnChange
              />
            </div>
          </div>

          <div className={styles.table}>
            <DefaultTable
              headerRows={this.renderHeaderRows()}
              bodyRows={this.renderBodyRows()}
              scrollerClassName={styles.tableScroller}
              fixedColumn
            />
            {isEmpty && !isPlanVersionSummaryFetching && (
              <div className={styles.emptyState}>
                <EmptyState
                  text={formatMessage({ id: 'harvestDashboard.emptyText' })}
                  isFixedSize
                />
              </div>
            )}
            {isPlanVersionSummaryFetching && (
              <CircleLoader
                className={loaderStyles.circleLoader}
                iconClassName={loaderStyles.circleLoaderIcon}
              />
            )}
          </div>

        </div>


      </div>
    );
  }
}
