import moment from 'moment-timezone';
import { get, uniq, find } from 'lodash';

const LIST_OF_MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

/**
 * Возвращает номера недель указанного месяца указанного года.
 *
 * @param {string} year - Год (например 2019).
 * @param {string} month - Двузначный номер месяца (например 01).
 * @return {number[]} Массив недель этого месяца.
 */
export const getWeeksOfMonth = (year, month) => {
  const firstDayOfMonth = moment(`${year}-${month}`, 'YYYY-MM-DD');
  const numOfDays = firstDayOfMonth.daysInMonth();
  let weeks = [];

  for (let i = 0; i < numOfDays; i++) {
    const currentDay = moment(firstDayOfMonth, 'YYYY-MM-DD').add(i, 'days');

    weeks = [...weeks, currentDay.isoWeek()];
  }

  return uniq(weeks);
};

/**
 * Возвращает массив объектов, содержащий номера недель и
 * даты начала недель указанного месяца указанного года без сплиттинга (повторов).
 *
 * @param {string} year - Год (например 2019).
 * @param {string} month - Двузначный номер месяца (например 01).
 * @return {number[]} Массив объектов недель этого месяца.
 */
export const getStartsOfWeeks = (year, month) => {
  const firstDayOfMonth = moment(`${year}-${month}`, 'YYYY-MM-DD');
  const firstWeekOfMonth = firstDayOfMonth.isoWeek();

  // Смотрим первую неделю следующего месяца, чтобы убрать дубли
  const firstDayOfNextMonth = moment(firstDayOfMonth, 'YYYY-MM-DD').add(1, 'M');
  const firstWeekOfNextMonth = firstDayOfNextMonth.isoWeek();

  const numOfDays = firstDayOfMonth.daysInMonth();
  let weeks = [];

  for (let i = 0; i < numOfDays; i++) {
    const currentDay = moment(firstDayOfMonth, 'YYYY-MM-DD').add(i, 'days');
    const weekNo = currentDay.isoWeek();
    const isWeekInNextMonth = weekNo === firstWeekOfNextMonth;

    if (!find(weeks, { weekNo }) && !isWeekInNextMonth) {
      weeks = [...weeks, {
        weekNo,
        firstDayOfMonth: firstDayOfMonth.format('YYYY-MM-DD'),
        startDate: currentDay.startOf('isoWeek').format('YYYY-MM-DD'),
        endDate: currentDay.endOf('isoWeek').format('YYYY-MM-DD'),
        isStartOfMonth: weekNo === firstWeekOfMonth,
      }];
    }
  }

  return weeks;
};

// TODO: написать тесты на генерацию недель без сплитования
/**
 * Возвращает массив объектов, содержащий номера недель и
 * даты начала недель указанного месяца указанного года c повторами (сплиттингом).
 *
 * @param {string} year - Год (например 2019).
 * @param {string} month - Двузначный номер месяца (например 01).
 * @return {number[]} Массив объектов недель этого месяца.
 */
export const getStartsOfWeeksWithSplitting = (year, month) => {
  const firstDayOfMonth = moment(`${year}-${month}`, 'YYYY-MM-DD');
  const numOfDays = firstDayOfMonth.daysInMonth();
  let weeks = [];

  const firstWeekOfMonth = firstDayOfMonth.isoWeek();

  for (let i = 0; i < numOfDays; i++) {
    const currentDay = moment(firstDayOfMonth, 'YYYY-MM-DD').add(i, 'days');
    const weekNo = currentDay.isoWeek();
    const yearToString = String(year);
    const isSameYearOfEndDate = currentDay.endOf('isoWeek').clone().format('YYYY') === yearToString;

    const startDate = currentDay.clone().startOf('isoWeek').format('YYYY-MM-DD');
    const endDate = currentDay.clone().endOf('isoWeek').format('YYYY-MM-DD');
    const isFirstDayOfMonthNotSameWithWeek = firstDayOfMonth > currentDay.clone().startOf('isoWeek');
    const isLastDayOfMonthNotSameWithWeek = currentDay.clone().endOf('isoWeek') > firstDayOfMonth.clone().endOf('month');

    if (!find(weeks, { weekNo: currentDay.isoWeek() })) {
      weeks = [...weeks, {
        weekNo,
        firstDayOfMonth: firstDayOfMonth.format('YYYY-MM-DD'),
        startDate: isFirstDayOfMonthNotSameWithWeek ? firstDayOfMonth.format('YYYY-MM-DD') : startDate,
        endDate: isLastDayOfMonthNotSameWithWeek ? firstDayOfMonth.clone().endOf('month').format('YYYY-MM-DD') : endDate,
        isStartOfMonth: (weekNo === firstWeekOfMonth) && isSameYearOfEndDate,
      }];
    }
  }

  return weeks;
};

/**
 * Возвращает номера недель указанного года c повторами,
 * если неделя приходится на 2 смежных месяца.
 *
 * @param {string} year - Год (например 2019).
 * @param {boolean} withStartDates - Возвращать номера недель с датами начала недели.
 * @return {number[]} Массив недель этого года.
 */
export const getWeeksOfYearWithSplitting = (year, withStartDates) => LIST_OF_MONTHS.reduce((acc, item) => {
  const weeksOfMonth = withStartDates ? getStartsOfWeeksWithSplitting(year, item) : getWeeksOfMonth(year, item);

  return [...acc, ...weeksOfMonth];
}, []);


/**
 * Возвращает номера недель указанного года без повторов,
 * если неделя приходится на 2 смежных месяца.
 *
 * @param {number || string} year - Год (например 2019).
 * @return {Object[]} Массив недель этого года c с дополнительными параметрами.
 */
export const getWeeksOfYear = (year) => {
  const yearToString = String(year);
  const momentYear = moment(yearToString, 'YYYY');
  const weeksInYear = momentYear.clone().isoWeeksInYear();
  let weeks = [];

  for (let i = 1; i <= weeksInYear; i++) {
    const momentStartDate = momentYear.clone().isoWeekYear(yearToString).isoWeekday(1).isoWeek(i);
    const momentEndDate = momentStartDate.clone().endOf('isoWeek');

    const startDate = momentStartDate.clone().format('YYYY-MM-DD');
    const endDate = momentEndDate.clone().format('YYYY-MM-DD');

    const momentFirstDayOfMonth = momentEndDate.clone().startOf('month');
    const firstDayOfMonth = momentFirstDayOfMonth.clone().format('YYYY-MM-DD');

    const isStartOfMonth = weeks.reduce((acc, week) => !moment(week.endDate).isSame(endDate, 'month'), true);
    const isSameYearOfEndDate = momentEndDate.clone().format('YYYY') === yearToString;

    weeks = [...weeks, {
      weekNo: i,
      firstDayOfMonth,
      startDate,
      endDate,
      isStartOfMonth: isStartOfMonth && isSameYearOfEndDate, // isSameYearOfEndDate для последней недели года
    }];
  }

  return weeks;
};

/**
 * Возвращает расширенный дополнительными параметрами массив периодов, полученный с back-end.
 *
 * @param {number || string} year - Год (например 2019).
 * @param {object} descriptor - Объект с периодами планов.
 * @return {Object[]} Массив недель этого года c с дополнительными параметрами.
 */
export const getWeeksFromDescriptor = (year, descriptor) => {
  const weekPeriods = get(descriptor, 'periods');
  const renderByDay = descriptor?.periodType === 'day';

  if (!weekPeriods) {
    return getWeeksOfYear(year);
  }

  const getFirstDayOfMonth = date => moment(date, 'YYYY-MM-DD').clone().startOf('month');
  const getIsNextYear = momentDate => (Number(momentDate.clone().format('YYYY')) > Number(year));

  return weekPeriods.map(({
    startDate, endDate, periodNumber, holiday
  }) => {
    const momentFirstDayOfMonth = getFirstDayOfMonth(endDate);
    const isSameYearOfEndDate = !getIsNextYear(moment(endDate, 'YYYY-MM-DD'));
    const isFirstWeekOfYear = periodNumber === 1;

    const isFirstWeekOfMonth = (moment(startDate, 'YYYY-MM-DD') <= momentFirstDayOfMonth) && (momentFirstDayOfMonth <= moment(endDate, 'YYYY-MM-DD'));

    // Если бъём по дням, а не неделям, то нельзя ориентироваться на periodNumber
    const isFirstByDay = renderByDay && startDate === momentFirstDayOfMonth.clone().format('YYYY-MM-DD');

    return {
      weekNo: periodNumber,
      startDate,
      endDate,
      holiday,
      renderByDay,
      firstDayOfMonth: momentFirstDayOfMonth.format('YYYY-MM-DD'),
      isStartOfMonth: (isFirstByDay || isFirstWeekOfMonth || isFirstWeekOfYear) && isSameYearOfEndDate,
    };
  });
};
