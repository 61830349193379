/**
 * Возвращает минимальную ширину страницы, если у страницы фиксированный лайаут (минимальная ширина).
 *
 * @param {string} path - Текущий pathname.
 * @param {Object[]} pages - Массив страниц, с лайаутом с фиксированной шириной.
 * @return {number || string || null} Минимальная ширина или null (если страницы нет в списке).
 */
const getLayoutMinWidth = (path, pages) => {
  const cleanPath = path.split('?')[0];

  return pages.reduce((acc, page) => {
    if (cleanPath.endsWith(page.name)) {
      return page.minWidth;
    }

    return acc;
  }, null);
};

export default getLayoutMinWidth;
