import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getAllCompartmentsFetching,
  getAllGreenhouseTypes,
  getCurrentCompartments,
  getDefaultOpenFromRoute
} from 'store/company/selectors';
import { trackPageViewSettings } from 'store/company/actions';

import SettingsGreenhouses from '../components/SettingsGreenhouses';

const mapDispatchToProps = {
  trackPageViewSettings,
};

const mapStateToProps = (state, props) => ({
  compartments: getCurrentCompartments(state),
  greenhouseTypes: getAllGreenhouseTypes(state),
  isAllCompartmentsFetching: getAllCompartmentsFetching(state),
  defaultOpen: getDefaultOpenFromRoute(state, props),

  ...props,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsGreenhouses));
