import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import classnames from 'classnames';
import { intlShape } from 'react-intl';
import queryString from 'query-string';

import React, { PureComponent } from 'react';
import BigButton from 'components/BigButton';
import EmptyState from 'components/EmptyState';

import CircleLoader from 'components/CircleLoader';
import loaderStyles from 'components/CircleLoader/CircleLoader.module.css';
import PageViewTracker from 'components/PageViewTracker';
import CanvasTimeline from 'components/CanvasTimeline';

import { getQueryValue } from 'hooks/useQuery/useQuery';
import PlansHeader from '../PlansHeader';

import { ReactComponent as EmptyIcon } from './assets/empty-icon.svg';
import { ReactComponent as DisabledOnMobileIcon } from './assets/disabled-on-mobile-icon.svg';

import styles from './Plans.module.css';

class Plans extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    history: PropTypes.object.isRequired,
    organization: PropTypes.object,
    compartments: PropTypes.array,
    varieties: PropTypes.array,
    harvestPlansSucceeded: PropTypes.bool.isRequired,
    harvestPlansFetching: PropTypes.bool.isRequired,
    isAddPlansAvailable: PropTypes.bool.isRequired,
    isOnlyVegetables: PropTypes.bool.isRequired,
    isOnlyLettuce: PropTypes.bool.isRequired,
    requestGetHarvestPlans: PropTypes.func.isRequired,
    trackPageViewPlans: PropTypes.func.isRequired,
    breakdown: PropTypes.string,
    periodType: PropTypes.string,
    relative: PropTypes.string,
    timelineDate: PropTypes.string,
    thickLines: PropTypes.array,
    descriptor: PropTypes.object,
    compartmentId: PropTypes.string,
    varietyId: PropTypes.string,
    locationPlan: PropTypes.string,
    species: PropTypes.string,
    fruitClassCode: PropTypes.string,
    isLocationPlansFetching: PropTypes.bool,
    locationPlanIdFromUrl: PropTypes.string
  };

  static defaultProps = {
    organization: null,
    compartments: null,
    varieties: null,
    thickLines: null,
    descriptor: null,
    relative: undefined,
    timelineDate: undefined,
    periodType: undefined,
    breakdown: undefined,
    compartmentId: undefined,
    varietyId: undefined,
    locationPlan: undefined,
    locationPlanIdFromUrl: undefined,
    species: undefined,
    fruitClassCode: undefined,
    isLocationPlansFetching: false
  };

  state = {

  };

  handlerTimelineDateChanged = debounce(({ timelineDate }) => {
    const { timelineDate: oldTimelineDate } = this.props;

    if (oldTimelineDate !== timelineDate) {
      const params = { timelineDate };

      this.setHistory(params);
    }
  }, 100);

  componentDidMount() {
    // super.componentDidMount();

    const {
      periodType,
    } = this.props;

    const timelineDate = this.getTimelineDate();

    this.setHistory({
      timelineDate,
      periodType: periodType || 'week'
    });

    this.fetchData();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      history,
      timelineDate
    } = this.props;
    if (timelineDate !== prevProps.timelineDate && history.action === 'POP') {
      this.fetchData();
    }
  }

  setHistory = (params) => {
    const { history } = this.props;
    const parsedQuery = queryString.parse(window.location.search);
    const newQuery = { ...parsedQuery, ...params };
    const newSearch = queryString.stringify(newQuery);
    const searchString = newSearch ? `?${newSearch}` : '';

    history.push({
      search: searchString,
    });
  };

  fetchData = (newFilter) => {
    const {
      requestGetHarvestPlans,
      organization,
      intl,
    } = this.props;
    const year = moment(getQueryValue('timelineDate'), 'YYYY-MM-DD').year();
    const filerInitState = newFilter || this.getFilter();
    requestGetHarvestPlans({
      organization,
      intl,
      ...filerInitState,
      year,
    });
  }

  getFilter = () => {
    const {
      timelineDate,
      relative,
      breakdown,
      compartmentId,
      varietyId,
      locationPlan,
      species,
      fruitClassCode,
      periodType,
      locationPlanIdFromUrl
    } = this.props;

    return {
      timelineDate,
      relative,
      breakdown,
      compartmentId,
      varietyId,
      locationPlan: locationPlanIdFromUrl || locationPlan,
      species,
      fruitClassCode,
      periodType: periodType || 'week'
    };
  };

  handlerFilterChange = (options) => {
    const filter = this.getFilter();
    this.fetchData({ ...filter, ...options });
  };

  getHeaderBodyColumnWidth = () => {
    const {
      width,
    } = this.state;

    if (width < 960) {
      return 263;
    }

    return 335;
  };

  getBodyColumnWidth = () => {
    const { relative } = this.props;

    return relative === 'true' ? 48 : 72;
  };

  getColumnsOffset = () => {
    const headerBodyColumnWidth = this.getHeaderBodyColumnWidth();
    const bodyColumnWidth = this.getBodyColumnWidth();

    return Math.round(headerBodyColumnWidth / bodyColumnWidth);
  };

  getTimelineDate = () => {
    const { timelineDate } = this.props;

    if (timelineDate) {
      return timelineDate;
    }

    const headerColumnWidth = window.innerWidth < 960 ? 263 : 335;
    const columnWidth = 48;
    const currentWeekOffset = Math.round((window.innerWidth - headerColumnWidth) / 2 / columnWidth) || 0;
    const currentWeek = moment().isoWeekday(1).isoWeek();

    let defaultTimelineDate;

    if (currentWeekOffset >= currentWeek) {
      defaultTimelineDate = moment().isoWeek(1).endOf('week').format('YYYY-MM-DD');
    } else {
      const isTwoYearWeek = moment().isoWeekday(1).add(-currentWeekOffset, 'week').year() !== moment().isoWeekday(7).add(-currentWeekOffset, 'week').year();

      defaultTimelineDate = moment()
        .isoWeekday(isTwoYearWeek ? 7 : 1)
        .add(-currentWeekOffset, 'week')
        .format('YYYY-MM-DD');
    }

    return defaultTimelineDate;
  }

  handlerPageView = () => {
    const { trackPageViewPlans } = this.props;

    trackPageViewPlans();
  };


  handlerCurrentWeekClick = ({ offset }) => {
    const timelineDate = moment().startOf('week');

    if (timelineDate.clone().add(-offset, 'weeks').year() === timelineDate.year()) {
      timelineDate.add(-offset, 'weeks');
    }

    const params = {
      timelineDate: timelineDate.format('YYYY-MM-DD'),
    };

    this.setHistory(params);

    this.fetchData();
  };

  handlerPrevYearClick = () => {
    const {
      timelineDate,
    } = this.props;
    const showedYear = moment(timelineDate, 'YYYY-MM-DD').year();
    const params = {
      timelineDate: moment().year(showedYear)
        .add(-1, 'years').endOf('year')
        .format('YYYY-MM-DD'),
    };

    this.setHistory(params);

    this.fetchData();
  };

  handlerNextYearClick = () => {
    const {
      timelineDate,
    } = this.props;

    const showedYear = moment(timelineDate, 'YYYY-MM-DD').year();
    const params = {
      timelineDate: moment().year(showedYear)
        .add(1, 'years').startOf('year')
        .format('YYYY-MM-DD'),
    };

    this.setHistory(params);

    this.fetchData();
  };

  render() {
    const {
      intl,
      varieties,
      organization,
      compartments,
      isAddPlansAvailable,
      isOnlyVegetables,
      isOnlyLettuce,
      relative,
      timelineDate,
      thickLines,
      descriptor,
      harvestPlansFetching,
      harvestPlansSucceeded,
      isLocationPlansFetching
    } = this.props;

    if (!compartments || !varieties || !timelineDate || isLocationPlansFetching) {
      return (
        <CircleLoader
          className={loaderStyles.circleLoader}
          iconClassName={loaderStyles.circleLoaderIcon}
        />
      );
    }

    const year = moment(timelineDate, 'YYYY-MM-DD').year();

    const { formatMessage } = intl;

    const { attributes: { slug } } = organization;
    const currentUnits = relative === 'true' ? 'kilogramPerSquareMeter' : 'kilogram';
    const addHarvestLink = `/${slug}/plans/list?year=${year}`;

    const isEmpty = !thickLines || !descriptor;

    return (
      <div className={classnames(styles.layout)}>
        <PageViewTracker onMount={this.handlerPageView} />
        <div className={styles.header}>
          <PlansHeader
            intl={intl}
            currentUnits={currentUnits}
            addHarvestLink={addHarvestLink}
            onFilterChange={this.handlerFilterChange}
            isAddPlansAvailable={isAddPlansAvailable}
            isOnlyVegetables={isOnlyVegetables}
            isOnlyLettuce={isOnlyLettuce}
            isLocationPlansFetching={isLocationPlansFetching}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.mobile}>
            <EmptyState
              isIcon
              isFlex
              className={styles.emptyState}
              header={formatMessage({ id: 'plans.disabledOnMobileTitle' })}
              text={formatMessage({ id: 'plans.disabledOnMobileText' })}
              image={<DisabledOnMobileIcon />}
            />
          </div>
          <div className={styles.desktop}>
            {isEmpty && harvestPlansSucceeded ? (
              <div className={styles.empty}>
                <EmptyState
                  isIcon
                  isButton
                  isFlex
                  className={styles.emptyState}
                  header={formatMessage({ id: 'plans.emptyTitle' })}
                  text={formatMessage({ id: 'plans.emptyText' })}
                  image={<EmptyIcon />}
                  button={(
                    <BigButton
                      className={styles.emptyButton}
                      theme='dark'
                      title={formatMessage({ id: 'plans.addPlans' })}
                      href={`plans/list?year=${year}`}
                    />
                  )}
                />
              </div>
            ) : (
              <CanvasTimeline
                thickLines={thickLines}
                descriptor={descriptor}
                onTimelineDateChanged={this.handlerTimelineDateChanged}
                onPrevYearClick={this.handlerPrevYearClick}
                onNextYearClick={this.handlerNextYearClick}
                onCurrentWeekClick={this.handlerCurrentWeekClick}
                isFetching={harvestPlansFetching}
                harvestPlansSucceeded={harvestPlansSucceeded}
                timelineDate={timelineDate}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Plans;
