import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import CircleLoader from 'components/CircleLoader';

import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as InfoIcon } from './assets/info.svg';
import { ReactComponent as SuccessIcon } from './assets/success.svg';
import { ReactComponent as ErrorIcon } from './assets/error.svg';

import styles from './Notification.module.css';


export default class Notification extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    iconType: PropTypes.string,
    hideNotification: PropTypes.func.isRequired,
    closeIcon: PropTypes.bool,
    notificationType: PropTypes.string,
    messageId: PropTypes.string,
    messageParams: PropTypes.object,
    withLineBreaks: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  };

  static defaultProps = {
    closeIcon: true,
    iconType: 'info',
    notificationType: 'withAction',
    messageId: null,
    messageParams: null,
    withLineBreaks: false,
    children: null,
  };

  renderIcon = (type) => {
    switch (type) {
      case 'loading':
        return (
          <div className={styles.loaderWrapper}>
            <CircleLoader
              className={styles.loaderClassName}
              iconClassName={styles.loaderIconClassName}
            />
          </div>
        );
      case 'info':
        return <InfoIcon />;
      case 'success':
        return <SuccessIcon />;
      case 'error':
        return <ErrorIcon />;
      default:
        return '';
    }
  };

  render() {
    const {
      id,
      messageId,
      messageParams,
      position,
      hideNotification,
      closeIcon,
      iconType,
      notificationType,
      withLineBreaks,
      children,
    } = this.props;

    let messageValues = messageParams;

    if (messageParams && withLineBreaks) {
      messageValues = Object.assign(messageParams, { br: <br /> });
    } else if (withLineBreaks) {
      messageValues = { br: <br /> };
    }

    return (
      <div
        className={classnames(
          styles.notification,
          {
            [styles[position]]: position,
            [styles[notificationType]]: notificationType
          },
        )}
      >
        <div className={styles.text}>
          {iconType && (
            <div className={styles.icon}>
              {this.renderIcon(iconType)}
            </div>
          )}

          <div>
            {children || <FormattedMessage id={messageId || id} values={messageValues || undefined} />}
          </div>
        </div>
        {closeIcon && (
          <button type='button' className={classnames(styles.button)} onClick={() => hideNotification(id)}>
            <CloseIcon />
          </button>
        )}
      </div>
    );
  }
}
