import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const getQueryValue = (
  key
) => {
  const values = new URLSearchParams(window.location.search);
  return values.get(key);
};

export const getQueryValueAll = (
  key
) => {
  const values = new URLSearchParams(window.location.search);
  return values.getAll(key);
};

const valueSetter = (newValue, key, history, setValue) => {
  setValue(newValue);
  const values = new URLSearchParams(window.location.search);
  if (newValue) {
    values.set(key, newValue);
  } else {
    values.delete(key);
  }
  history.push(`?${values.toString()}`);
};

const useQuery = (key, initialValue) => {
  const history = useHistory();
  const [value, setValue] = useState(getQueryValue(key) || initialValue);
  const onSetValue = useCallback(
    (newValue) => {
      valueSetter(newValue, key, history, setValue);
    },
    [key, history]
  );
  return [value, onSetValue];
};

const useQueryAll = (key, initialValue) => {
  const history = useHistory();
  const [value, setValue] = useState(getQueryValueAll(key) || initialValue);
  const onSetValue = useCallback(
    (newValue) => {
      valueSetter(newValue, key, history, setValue);
    },
    [key, history]
  );
  return [value, onSetValue];
};

export default useQuery;
export {
  useQueryAll
};
