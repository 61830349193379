import uuid from 'uuid/v4';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { findDOMNode } from 'react-dom';
import ReactTooltip from 'react-tooltip';
import React, { Component } from 'react';
import NumberFormat from '../NumberFormat';

import CellErrorHint from '../Icons/ErrorHint';
import { ReactComponent as CellWarningHint } from './assets/cell_warning_hint.svg';

import styles from './BigNumberInput.module.css';


export default class BigNumberInput extends Component {
  static propTypes = {
    isDebounceChange: PropTypes.bool,
    value: PropTypes.any,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onPaste: PropTypes.func,
    onKeyDown: PropTypes.func,
    mode: PropTypes.oneOf([null, 'cell', 'mini']),
    isFirstColumn: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    disabled: PropTypes.bool,
    allowNegative: PropTypes.bool,
    decimalScale: PropTypes.number,
    decimalZeroMask: PropTypes.number,
    fixedDecimalScale: PropTypes.bool,
    forceDecimalScale: PropTypes.bool,
    forcedDecimalScale: PropTypes.number,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    warning: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    autoFocus: PropTypes.bool,
  };

  static defaultProps = {
    isDebounceChange: false,
    isFirstColumn: false,
    decimalScale: undefined,
    fixedDecimalScale: false,
    className: null,
    onChange: null,
    decimalZeroMask: null,
    forceDecimalScale: null,
    forcedDecimalScale: undefined,
    wrapperClassName: null,
    onBlur: null,
    onFocus: null,
    onPaste: null,
    onKeyDown: null,
    value: '',
    placeholder: '',
    disabled: false,
    allowNegative: true,
    error: '',
    warning: '',
    min: null,
    max: null,
    autoFocus: false,
    mode: null,
  };

  state = {
    active: false,
    myid: uuid(),
  };

  handlerDebounceChange = debounce((options) => {
    this.callbackChange(options);
  }, 400);

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  callbackChange = ({ value }) => {
    const {
      min,
      max,
      onChange,
      disabled,
    } = this.props;

    if (!disabled) {
      const sample = 1.1;
      const delimeter = /^1(.+)1$/.exec(sample.toLocaleString())[1]; // FIXME

      const preparedValue = value.toString().replace('.', delimeter).replace(',', delimeter).replace(delimeter, '.');

      const parsedValue = +preparedValue;

      let isOverMax = false;
      let isOverMin = false;

      if (Number.isFinite(max)) {
        isOverMax = parsedValue > max;
      }

      if (Number.isFinite(min)) {
        isOverMin = parsedValue < min;
      }

      const isError = value === '' ? false : Number.isNaN(parsedValue) || !Number.isFinite(parsedValue) || isOverMax || isOverMin;

      if (onChange) {
        onChange(value, isError);
      }
    }
  };

  handlerChangeSelect = (options) => {
    const { isDebounceChange } = this.props;
    if (isDebounceChange) {
      this.handlerDebounceChange(options);
    } else {
      this.handlerChange(options);
    }
  };

  handlerChange = (options) => {
    this.callbackChange(options);
  };

  handlerClick = () => {
    const {
      disabled,
      mode,
      error,
      warning,
    } = this.props;

    if (!disabled) {
      this.setState({
        active: true,
      });

      if (mode === 'cell') {
        if (error && this.errorTooltip) {
          ReactTooltip.show(this.errorTooltip);
        } else if (warning && this.warningTooltip) {
          ReactTooltip.show(this.warningTooltip);
        }
      }
    }
  };

  handlerBlur = ({ value }) => {
    const {
      onBlur,
      disabled,
      mode,
      error,
      warning,
    } = this.props;

    if (!disabled) {
      this.setState({
        active: false,
      });

      if (mode === 'cell') {
        if (error && this.errorTooltip) {
          ReactTooltip.hide(findDOMNode(this.errorTooltip)); // eslint-disable-line react/no-find-dom-node
        } else if (warning && this.warningTooltip) {
          ReactTooltip.hide(findDOMNode(this.warningTooltip)); // eslint-disable-line react/no-find-dom-node
        }
      }
      onBlur(value);
    }
  };

  handlerFocus = ({ value }) => {
    const {
      onFocus,
      disabled,
      error,
      warning,
      mode,
    } = this.props;

    if (!disabled) {
      this.setState({
        active: true,
      });

      if (mode === 'cell') {
        if (error && this.errorTooltip) {
          ReactTooltip.show(this.errorTooltip);
        } else if (warning && this.warningTooltip) {
          ReactTooltip.show(this.warningTooltip);
        }
      }

      onFocus(value);
    }
  };

  handlerPaste = (e) => {
    const {
      onPaste,
      disabled,
    } = this.props;

    if (!disabled) {
      if (onPaste) {
        onPaste(e);
      }
    }
  };

  handlerOnKeyDown = (e) => {
    const {
      onKeyDown,
    } = this.props;

    if (onKeyDown) {
      onKeyDown(e);
    }
  }

  render() {
    const {
      className,
      wrapperClassName,
      value,
      disabled,
      error,
      warning,
      placeholder,
      fixedDecimalScale,
      decimalScale,
      decimalZeroMask,
      allowNegative,
      forceDecimalScale,
      forcedDecimalScale,
      autoFocus,
      mode,
      isFirstColumn,
    } = this.props;

    const {
      active,
      myid,
    } = this.state;

    const isError = !!error;
    const isWarning = !!warning;

    return (
      <div className={
        classnames(styles.inputWrapper, wrapperClassName, {
          [styles.disabled]: disabled,
          [styles.active]: !!active,
          [styles.warninged]: !!warning,
          [styles.errored]: !!error,
          [styles.cell]: mode === 'cell',
        })
}
      >
        <NumberFormat
          autoFocus={autoFocus}
          thousandSeparator=' '
          placeholder={mode === 'mini' ? placeholder : null}
          allowNegative={allowNegative}
          decimalZeroMask={decimalZeroMask}
          fixedDecimalScale={fixedDecimalScale}
          forceDecimalScale={forceDecimalScale}
          forcedDecimalScale={forcedDecimalScale}
          decimalScale={decimalScale}
          disabled={disabled}
          type='text'
          onClick={this.handlerClick}
          onBlur={this.handlerBlur}
          onPaste={this.handlerPaste}
          onFocus={this.handlerFocus}
          onKeyDown={this.handlerOnKeyDown}
          onChange={this.handlerChangeSelect}
          value={value}
          allowedDecimalSeparator={[',', '.']}
          className={
            classnames(
              styles.input,
              className,
              {
                [styles.danger]: isWarning,
                [styles.invalid]: isError,
                [styles.mini]: mode === 'mini',
                danger: isWarning,
                invalid: isError,
              },
            )
}
        />
        {placeholder && (mode !== 'mini') ? (
          <div
            className={classnames(
              styles.placeholder,
              {
                [styles.top]: active || (value !== null && value.toString().length > 0),
                [styles.danger]: isWarning,
                [styles.invalid]: isError,
                danger: isWarning,
                invalid: isError,
              },
            )}
          >
            {placeholder}
          </div>
        ) : null}
        {error ? (
          <div className={styles.error}>
            {error}
          </div>
        ) : null}
        {warning ? (
          <div className={styles.warning}>
            {warning}
          </div>
        ) : null}
        {mode === 'cell' && error ? (
          <div
            className={classnames(styles.errorHint)}
            data-tip=''
            data-for={`error-tooltip-${myid}`}
            data-offset="{'left': 12}"
            ref={(element) => {
              this.errorTooltip = element;
            }}
          >
            {!active ? <button type='button' className={styles.hintButton}><CellErrorHint /></button> : null}

            <ReactTooltip
              id={`error-tooltip-${myid}`}
              isCapture
              scrollHide
              effect='solid'
              place='left'
              class={classnames(styles.errorHintTooltip, { [styles.firstColumn]: isFirstColumn && mode === 'cell' })}
              event={null}
              offset={{ left: 12 }}
            >
              {error}
            </ReactTooltip>
          </div>
        ) : null}
        {mode === 'cell' && warning && !error ? (
          <div
            className={classnames(styles.warningHint)}
            data-tip=''
            data-for={`warning-tooltip-${myid}`}
            data-offset="{'left': 12}"
            ref={(element) => {
              this.warningTooltip = element;
            }}
          >
            {!active ? <button type='button' className={styles.hintButton}><CellWarningHint /></button> : null}

            <ReactTooltip
              id={`warning-tooltip-${myid}`}
              isCapture
              scrollHide
              effect='solid'
              place='left'
              class={classnames(styles.warningHintTooltip, { [styles.firstColumn]: isFirstColumn && mode === 'cell' })}
              event={null}
              offset={{ left: 12 }}
            >
              {warning}
            </ReactTooltip>
          </div>
        ) : null}
      </div>
    );
  }
}
