
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import classnames from 'classnames';
import moment from 'moment-timezone';
import { isNil } from 'lodash';

import getDateFormat from 'helpers/getDateFormat';
import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';

import TooltipMetric from '../TooltipMetric';

import styles from './Tooltip.module.css';

// Высота и отступ треугольника тултипа
const TAIL_HEIGHT = 9;
const TAIL_PADDING = 7;

// TODO: Вынести в хелпер
const renderDate = (formatMessage, tooltipDate, periodType, customRenderDate) => {
  if (customRenderDate) {
    return customRenderDate(tooltipDate, periodType);
  }

  if (periodType === 'week') {
    // Берём последний день недели. Проверяем попадает ли в текущий год. Если нет, то добавлеяем в тултип.
    const dateYear = tooltipDate.clone().isoWeekday(7).format('YYYY');
    const currentYear = moment(new Date()).format('YYYY');
    const isAddPrevYear = dateYear !== currentYear;
    const prevYearText = isAddPrevYear ? `, ${dateYear}` : '';

    const week = tooltipDate.isoWeek();

    // TODO: скопипастил, вынести в хелпер работы с датами
    const weekDates = `${tooltipDate.clone().isoWeekday(1).format(`${formatMessage({ id: 'date.dayMonth' })}`)} – ${tooltipDate.clone().isoWeekday(7).format(`${formatMessage({ id: 'date.dayMonth' })}`)}`;

    return `${weekDates}${prevYearText}, ${formatMessage({ id: 'tables.week.one' })} ${week}`;
  }

  if (periodType === 'month') {
    return tooltipDate.format('MMMM, YYYY');
  }

  if (periodType === 'day') {
    return tooltipDate.format(getDateFormat('lll'));
  }

  return tooltipDate.format(getDateFormat('lll'));
};

const Tooltip = ({
  intl: { formatMessage }, x, y, tooltipData, width, periodType, className, customRenderDate, isForceRenderDate,
}) => {
  const tooltipStyles = {
    width: `${width}px`,
    left: `${x - (width / 2)}px`,
    bottom: `${y + TAIL_HEIGHT + TAIL_PADDING}px`,
  };
  const {
    name, color, date, units, value, roundTo
  } = tooltipData;

  const roundToValue = roundTo || 0;

  const unitsText = formatMessage({ id: `cunits.mini.${units}` });

  const formattedValue = isNil(value) ? null : numbersFormatting(numbersRounding(value, 'fixed', roundToValue)); // isNil(value) ? formatMessage({ id: 'crops.noData' }) : numbersFormatting(value);

  const lineName = name || formatMessage({ id: 'dashboards.totalTooltip' });
  const dateText = date || isForceRenderDate ? renderDate(formatMessage, date, periodType, customRenderDate) : null;

  return (
    <div
      className={classnames(styles.tooltip, className)}
      style={tooltipStyles}
    >
      <div className={styles.tooltipContent}>
        {dateText && <div className={styles.tooltipHeader}>{dateText}</div>}

        <TooltipMetric
          value={formattedValue}
          units={unitsText}
          text={lineName}
          color={color}
        />
      </div>
      <div className={styles.triangle} />
    </div>
  );
};

Tooltip.propTypes = {
  intl: intlShape.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  tooltipData: PropTypes.object.isRequired,
  periodType: PropTypes.string,
  isForceRenderDate: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.number,
  customRenderDate: PropTypes.func,
};

Tooltip.defaultProps = {
  width: 288,
  className: null,
  isForceRenderDate: false,
  periodType: null,
  customRenderDate: null,
};

export default injectIntl(memo(Tooltip));
