import CanvasComponent from './CanvasComponent';
import { roundRect } from '../helpers/DrawUtils';

class IncidentTimelineOverlay extends CanvasComponent {
  constructor({
    position = { x: 0, y: 0 },
    viewport,
    intl
  }) {
    super({
      position,
      cursorType: 'default'
    });
    this.intl = intl;
    this.updateView({
      viewport
    });
    this.selectedNode = null;
  }

  updateView({
    viewport
  }) {
    this.viewport = viewport;
    this.setDirty();
    this.setZIndex(0);
  }

  getSize() {
    return this.viewport || {
      width: 0,
      height: 0
    };
  }

  setSelectedNode(selectedNode) {
    this.selectedNode = selectedNode;
  }

  draw(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    ctx.save();
    super.draw(ctx, {
      translateAdd
    });

    if (this.selectedNode) {
      this.drawTooltip({ ctx, selectedNode: this.selectedNode });
    }

    ctx.restore();
  }

  drawTooltip({
    ctx,
    selectedNode
  }) {
    ctx.save();
    const textMeasurements = ctx.measureText(selectedNode.tooltip.tooltipText);

    const size = {
      width: textMeasurements.width + 16,
      height: 22,
    };

    const position = {
      x: selectedNode.bound.x + (selectedNode.bound.width / 2) - (size.width / 2),
      y: selectedNode.bound.y - 11 - selectedNode.bound.height - 4,
    };

    roundRect(ctx, {
      ...position,
      ...size,
      fill: true,
      radius: {
        tl: 2, bl: 2, tr: 2, br: 2
      },
      color: '#4a4a49'
    });

    ctx.font = 'normal normal 400 12px Roboto';
    ctx.fillStyle = 'hsla(0,0%,100%,.9)';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(
      selectedNode.tooltip.tooltipText,
      position.x + size.width / 2,
      position.y + size.height / 2 + 1.5
    );

    const sWidth = size.width;
    const sHeight = 8;
    const tWidth = 6;
    const path = new Path2D();
    const triangleTop = size.height + 4;

    ctx.fillStyle = '#4a4a49';

    const points = [{
      x: position.x + (sWidth / 2) - tWidth,
      y: position.y + triangleTop - sHeight / 2
    }, {
      x: position.x + (sWidth / 2) + tWidth,
      y: position.y + triangleTop - sHeight / 2
    }, {
      x: position.x + (sWidth / 2),
      y: position.y + triangleTop - (sHeight / 2) + 4
    }];

    for (let i = 0; i < points.length; i += 1) {
      const point = points[i];
      if (i === 0) {
        path.moveTo(point.x, point.y);
      } else {
        path.lineTo(point.x, point.y);
      }
    }

    ctx.fill(path);
    ctx.restore();
  }
}

export default IncidentTimelineOverlay;
