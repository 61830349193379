import PropTypes from 'prop-types';
import React, {
  useCallback, useState, useRef, useMemo
} from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import ScaleSelect from 'components/Graphs/components/ScaleSelect';
import ScaleSettings from 'components/Graphs/components/ScaleSettings';

import useOnClickOutside from 'hooks/useOnClickOutside';

import { isFinite } from 'lodash';
import styles from './index.module.css';

const ScaleDrop = ({
  scales,
  selectedScale,
  onSelect,
  onNewScale,
  onChange,
  onDelete,
  intl
}) => {
  const { formatMessage } = intl;
  const modalsRef = useRef(null);

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isSetupOpen, setIsSetupOpen] = useState(false);

  const handleClickSelectScale = useCallback(() => {
    setIsSetupOpen(false);
    setIsSelectOpen(prevState => !prevState);
  }, [setIsSelectOpen, setIsSetupOpen]);

  const handleClickSetupScale = useCallback(() => {
    setIsSelectOpen(false);
    setIsSetupOpen(prevState => !prevState);
  }, [setIsSetupOpen, setIsSelectOpen]);

  const selectedScaleName = selectedScale?.name;
  const isCustomRange = isFinite(selectedScale?.range?.start) && isFinite(selectedScale?.range?.end);

  const hideModals = useCallback(() => {
    setIsSetupOpen(false);
    setIsSelectOpen(false);
  }, [setIsSetupOpen, setIsSelectOpen]);

  const handleSelectScale = useCallback((scale) => {
    setIsSelectOpen(false);
    if (onSelect) {
      onSelect(scale);
    }
  }, [onSelect]);

  const handleNewScale = useCallback((scale) => {
    setIsSelectOpen(false);
    if (onNewScale) {
      onNewScale(scale);
    }
  }, [onNewScale]);

  useOnClickOutside(modalsRef, hideModals);

  const uniqueScales = useMemo(() => scales.reduce((acc, scale) => {
      if (acc.findIndex(item => item.name === scale.name) === -1) {
        acc.push(scale);
      }
      return acc;
    }, []), [scales]);

  return (
    <div ref={modalsRef} className={styles.dropWrapper}>
      <button
        type='button'
        onClick={handleClickSelectScale}
        className={classnames(styles.scaleButton, { [styles.selected]: isSelectOpen })}
      >
        {selectedScaleName || formatMessage({ id: 'graphs.baseTitle' })}
      </button>
      <div className={styles.hLine} />
      <button
        type='button'
        onClick={handleClickSetupScale}
        className={classnames(styles.scaleButton, { [styles.selected]: isSetupOpen })}
      >
        {isCustomRange ? `${selectedScale?.range?.start} - ${selectedScale?.range?.end}` : formatMessage({ id: 'graphs.rangeAuto' })}
      </button>
      {isSelectOpen && (
        <ScaleSelect
          className={styles.dropSelectPosition}
          scales={uniqueScales}
          selectedScale={selectedScale}
          onSelect={handleSelectScale}
          onNewScale={handleNewScale}
        />
      )}
      {isSetupOpen && (
        <ScaleSettings
          className={styles.dropSetupPosition}
          onChange={onChange}
          onDelete={onDelete}
          {...selectedScale}
        />
      )}
    </div>
  );
};

ScaleDrop.propTypes = {
  intl: PropTypes.object,
  scales: PropTypes.array,
  selectedScale: PropTypes.object,
  onSelect: PropTypes.func,
  onNewScale: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

ScaleDrop.defaultProps = {
  intl: null,
  scales: [],
  selectedScale: null,
  onSelect: null,
  onNewScale: null,
  onChange: null,
  onDelete: null
};

export default injectIntl(ScaleDrop);
