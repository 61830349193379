
import { Metric } from 'store/graphs/types';

function getMetricName(metric: Metric, locale: string) {
  let metricName = '';
  // TODO Fix locale key types

  if (metric) {
    // @ts-ignore
    if (metric.attributes.name[locale]) {
      // @ts-ignore
      metricName = metric.attributes.name[locale];
    } else if (metric.attributes.name.en) {
      metricName = `[${metric.attributes.name.en}]`;
    }
  }

  return metricName;
}

export default getMetricName;
