import React from 'react';

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import queryString from 'query-string';
import { push } from 'connected-react-router';
import {
  find, get, pull, head
} from 'lodash';

import addGetParameters from 'helpers/addGetParameters';
import { CYCLES_TO_COMPARE, CYCLES_TO_COMPARE_LIMIT } from 'helpers/constants';
import storageWrapper from 'helpers/storageWrapper';
import { checkIfHasDifferentSpecies } from 'helpers/cropCycleHelpers';

import CropCompareSuccessMsg from 'components/CropCompareSuccessMsg';

import initAxiosInstanse from '../../api/axios';
import history from '../../history';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

import {
  requestBenchmarkGroups,
  receiveBenchmarkGroups,

  requestBenchmarkOverview,
  receiveBenchmarkOverview,

  requestBenchmarkComparison,
  receiveBenchmarkComparison,

  requestBenchmarkComparisonMetrics,
  receiveBenchmarkComparisonMetrics,

  requestDeleteBenchmarkComparisonMetrics,
  receiveDeleteBenchmarkComparisonMetrics,

  requestUpdateBenchmarkComparisonMetrics,
  receiveUpdateBenchmarkComparisonMetrics,

  requestBenchmarkMetrics,
  receiveBenchmarkMetrics,

  addCycleToComparison,
  replaceAllCyclesInComparison,
  removeCycleFromComparison,
  removeAllCyclesFromComparison,
  updateCyclesComparison,
} from './actions';

const safeLocalStorage = storageWrapper.get('localStorage');

export const getBenchmarkGroupsEpic = (action$, store) =>
  action$.ofType(requestBenchmarkGroups)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      return Observable
        .from(initAxiosInstanse(searchParams).get(`benchmark/groups/${organizationId}`))
        .map(({ data }) => receiveBenchmarkGroups({ groups: data }))
        .catch(() => Observable.of(
          receiveBenchmarkGroups(),
          showNotificationWithTimeout({
            id: `notifications.getBenchmarkGroupsError.${Date.now()}`,
            messageId: 'notifications.getBenchmarkGroupsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getBenchmarkMetricsEpic = (action$, store) =>
  action$.ofType(requestBenchmarkMetrics)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      return Observable
        .from(initAxiosInstanse(searchParams).get(`benchmark/overview/${organizationId}/metrics`))
        .map(({ data }) => receiveBenchmarkMetrics({ metrics: data }))
        .catch(() => Observable.of(
          receiveBenchmarkMetrics(),
          showNotificationWithTimeout({
            id: `notifications.getBenchmarkMetricsError.${Date.now()}`,
            messageId: 'notifications.getBenchmarkMetricsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getBenchmarkOverviewEpic = (action$, store) =>
  action$.ofType(requestBenchmarkOverview)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          species,
          startDate,
          endDate,
          varietyId,
          productGroupId,
          compartmentId,
          fruitClassCode,
          benchmarkGroupId,
          additionalMetricId,
        },
      } = actionData;

      const queryParams = queryString.stringify({
        species,
        startDate,
        endDate,
        varietyId,
        productGroupId,
        compartmentId,
        fruitClassCode,
        benchmarkGroupId,
        additionalMetricId,
        finished: false,
      });

      const query = queryParams ? `${queryParams}` : '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`benchmark/overview/${organizationId}?${query}`))
        .map(({ data }) => receiveBenchmarkOverview({ overview: data }))
        .catch(() => Observable.of(
          receiveBenchmarkOverview(),
          showNotificationWithTimeout({
            id: `notifications.getBenchmarkOverviewError.${Date.now()}`,
            messageId: 'notifications.getBenchmarkOverviewError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getBenchmarkComparisonEpic = (action$, store) =>
  action$.ofType(requestBenchmarkComparison)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const { id: organizationId } = location;

      const {
        payload: { cyclesToCompare }
      } = actionData;

      const searchParams = queryString.parse(get(router, 'location.search', ''));

      const graphMetricId = get(searchParams, 'graphMetricId');
      const compareType = get(searchParams, 'compareType', 'calendar');

      const paramsData = {};

      if (graphMetricId) {
        paramsData.graphMetricId = graphMetricId.split('_');
      }

      paramsData.productId = cyclesToCompare;
      paramsData.compareType = compareType;

      return Observable.from(initAxiosInstanse(searchParams).get(
        `v2/benchmark/comparison/${organizationId}`,
        {
          params: paramsData,
          paramsSerializer: params => queryString.stringify(params),
        },
      ))
        .map(({ data }) => receiveBenchmarkComparison({ comparison: data }))
        .catch(() => Observable.of(
          receiveBenchmarkComparison(),
          showNotificationWithTimeout({
            id: `notifications.getBenchmarkComparisonError.${Date.now()}`,
            messageId: 'notifications.getBenchmarkComparisonError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const receiveBenchmarkComparisonEpic = (action$, store) =>
  action$.ofType(receiveBenchmarkComparison)
    .switchMap((actionData) => {
      const comparison = get(actionData, 'payload.comparison');

      const {
        router,
      } = store.getState();

      const searchParams = queryString.parse(get(router, 'location.search', ''));
      const graphMetricId = get(searchParams, 'graphMetricId');

      if (!graphMetricId) {
        const graphMetrics = get(comparison, 'graphMetrics', []);

        const paramsData = {};

        if (graphMetrics && graphMetrics.length) {
          paramsData.graphMetricId = graphMetrics.map(item => get(item, 'id')).join('_');
        }

        const newParameters = {
          graphMetricId: graphMetrics.map(item => get(item, 'id')).join('_'),
        };

        const oldQuery = get(router, 'location.search', '');
        const searchString = addGetParameters(oldQuery, newParameters);

        return Observable.of(push({
          search: searchString,
        }));
      }

      return Observable.empty();
    });

export const getBenchmarkComparisonMetricsEpic = (action$, store) =>
  action$.ofType(requestBenchmarkComparisonMetrics)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const { id: organizationId } = location;

      const {
        payload: { cyclesToCompare }
      } = actionData;

      const searchParams = queryString.parse(get(router, 'location.search', ''));

      const graphMetricId = get(searchParams, 'graphMetricId');

      const paramsData = {};

      if (graphMetricId) {
        paramsData.graphMetricId = graphMetricId.split('_');
      }

      if (cyclesToCompare) {
        paramsData.productId = cyclesToCompare;
      }


      return Observable.from(initAxiosInstanse(searchParams).get(
        `benchmark/comparison/${organizationId}/metrics`,
        {
          params: paramsData,
          paramsSerializer: params => queryString.stringify(params),
        },
      ))
        .map(({ data }) => receiveBenchmarkComparisonMetrics({ metrics: data }))
        .catch(() => Observable.of(
          receiveBenchmarkComparisonMetrics(),
          showNotificationWithTimeout({
            id: `notifications.getBenchmarkComparisonMetricsError.${Date.now()}`,
            messageId: 'notifications.getBenchmarkComparisonMetricsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const deleteBenchmarkComparisonMetricsEpic = (action$, store) =>
  action$.ofType(requestDeleteBenchmarkComparisonMetrics)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const {
        payload: { cyclesToCompare, compareType }
      } = actionData;

      const { id: organizationId } = location;

      const searchParams = queryString.parse(get(router, 'location.search', ''));

      const paramsData = {};

      if (cyclesToCompare) {
        paramsData.productId = cyclesToCompare;
      }

      const newParameters = {
        graphMetricId: undefined,
      };

      const oldQuery = get(router, 'location.search', '');
      const searchString = addGetParameters(oldQuery, newParameters);


      return Observable.from(initAxiosInstanse(searchParams).delete(
        `benchmark/comparison/${organizationId}/metrics`,
        {
          params: paramsData,
          paramsSerializer: params => queryString.stringify(params),
        },
      ))
        .mergeMap(() => Observable.of(
          push({
            search: searchString,
          }),
          receiveDeleteBenchmarkComparisonMetrics(),
          requestBenchmarkComparison({ cyclesToCompare, compareType }),
          showNotificationWithTimeout({
            id: `notifications.receiveDeleteBenchmarkComparisonMetricsSuccess.${Date.now()}`,
            messageId: 'notifications.receiveDeleteBenchmarkComparisonMetricsSuccess',
            position: 'leftDown',
            iconType: 'success',
            notificationType: 'withActionWide',
          }),
        ))
        .catch(() => Observable.of(
          receiveDeleteBenchmarkComparisonMetrics(),
          showNotificationWithTimeout({
            id: `notifications.deleteBenchmarkComparisonMetricsError.${Date.now()}`,
            messageId: 'notifications.deleteBenchmarkComparisonMetricsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const updateBenchmarkComparisonMetricsEpic = (action$, store) =>
  action$.ofType(requestUpdateBenchmarkComparisonMetrics)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;

      const { id: organizationId } = location;

      const searchParams = queryString.parse(get(router, 'location.search', ''));

      const productId = get(searchParams, 'productId');
      const graphMetricId = get(searchParams, 'graphMetricId');

      const paramsData = {};

      if (graphMetricId) {
        paramsData.graphMetricId = graphMetricId.split('_').map(x => +x);
      }

      if (productId) {
        paramsData.productId = productId.split('_').map(x => +x);
      }

      return Observable.from(initAxiosInstanse(searchParams).post(
        `benchmark/comparison/${organizationId}/metrics`,
        {},
        {
          params: paramsData,
          paramsSerializer: params => queryString.stringify(params),
        },
      ))
        .mergeMap(() => Observable.of(
          receiveUpdateBenchmarkComparisonMetrics(),
          showNotificationWithTimeout({
            id: `notifications.receiveUpdateBenchmarkComparisonMetricsSuccess.${Date.now()}`,
            messageId: 'notifications.receiveUpdateBenchmarkComparisonMetricsSuccess',
            position: 'leftDown',
            iconType: 'success',
            notificationType: 'withActionWide',
          }),
        ))
        .catch(() => Observable.of(
          receiveUpdateBenchmarkComparisonMetrics(),
          showNotificationWithTimeout({
            id: `notifications.updateBenchmarkComparisonMetricsError.${Date.now()}`,
            messageId: 'notifications.updateBenchmarkComparisonMetricsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

const checkIfOtherSpecies = (allPlantingCycles, exsitCycleId, addedCycleId) => {
  if (!exsitCycleId) {
    return false;
  }

  const existPlantingCycle = find(allPlantingCycles, { id: Number(exsitCycleId) });
  const addedPlantingCycle = find(allPlantingCycles, { id: Number(addedCycleId) });

  if (existPlantingCycle?.attributes?.species !== addedPlantingCycle?.attributes?.species) {
    return true;
  }

  return false;
};

export const addCycleToComparisonEpic = (action$, store) =>
  action$.ofType(addCycleToComparison)
    .switchMap((actionData) => {
      const {
        company: { location, benchmarkAllProducts },
        benchmark: { cyclesToCompare }
      } = store.getState();

      const { id: organizationId } = location;

      const {
        payload: { cycleId, withSuccessAction }
      } = actionData;

      if (!cycleId) {
        return Observable.of();
      }

      const currentCycles = JSON.parse(cyclesToCompare) || {};
      const currentOrganizationCycles = currentCycles[organizationId] || [];

      if (currentOrganizationCycles.length > (CYCLES_TO_COMPARE_LIMIT - 1)) {
        return Observable.of(
          showNotificationWithTimeout({
            id: `notifications.benchmarkLimitError.${Date.now()}`,
            messageId: 'benchmarking.compareLimit',
            position: 'leftDown',
            iconType: 'info',
          }),
        );
      }

      const isOtherSpecies = checkIfOtherSpecies(benchmarkAllProducts, head(currentOrganizationCycles), cycleId);

      if (isOtherSpecies) {
        return Observable.of(
          showNotificationWithTimeout({
            id: `notifications.benchmarkLimitError.${Date.now()}`,
            messageId: 'benchmarking.differentSpecies',
            position: 'leftDown',
            iconType: 'info',
          }),
        );
      }

      const newData = {
        ...currentCycles,
        [organizationId]: [...currentOrganizationCycles, Number(cycleId)]
      };

      const newCyclesToCompare = JSON.stringify(newData);

      safeLocalStorage.setItem(CYCLES_TO_COMPARE, newCyclesToCompare);

      if (withSuccessAction) {
        return Observable.of(
          updateCyclesComparison(newCyclesToCompare),
          showNotificationWithTimeout({
            id: `notifications.addCycleToComparisonSuccess.${Date.now()}`,
            messageId: 'benchmarking.addCycleSuccess', // По идее можно удалить тк есть children, надо проверить
            position: 'leftDown',
            iconType: 'success',
            children: <CropCompareSuccessMsg />,
          }),
        );
      }

      return Observable.of(
        updateCyclesComparison(newCyclesToCompare)
      );
    });

export const removeCycleFromComparisonEpic = (action$, store) =>
  action$.ofType(removeCycleFromComparison)
    .switchMap((actionData) => {
      const { router, company, benchmark: { cyclesToCompare } } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          cycleId,
          withSuccessAction,
          actionAfterSuccess = () => {},
          withUpdateUrlParams = false,
        }
      } = actionData;

      if (!cycleId) {
        return Observable.of();
      }

      const currentCycles = JSON.parse(cyclesToCompare) || {};
      const currentOrganizationCycles = currentCycles[organizationId] || [];

      const newData = {
        ...currentCycles,

        [organizationId]: pull(currentOrganizationCycles, cycleId),
      };

      const newCyclesToCompare = JSON.stringify(newData);

      safeLocalStorage.setItem(CYCLES_TO_COMPARE, newCyclesToCompare);

      const newCyclesForCurrentOrganization = newData[organizationId];

      // Updating of url params
      const newParameters = {
        productId: newCyclesForCurrentOrganization.join('_'),
      };

      const oldQuery = get(router, 'location.search', '');
      const searchString = newCyclesForCurrentOrganization.length > 0 ?
        addGetParameters(oldQuery, newParameters)
        :
        oldQuery;

      if (withUpdateUrlParams) {
        history.push({
          search: searchString,
        });
      }

      if (withSuccessAction) {
        return Observable.of(
          updateCyclesComparison(newCyclesToCompare),
          showNotificationWithTimeout({
            id: `notifications.removeCycleFromComparisonSuccess.${Date.now()}`,
            messageId: 'benchmarking.removeCycleSuccess',
            position: 'leftDown',
            iconType: 'success',
          }),
          () => actionAfterSuccess(newData[organizationId]), // сюда отправляем апдейтнутые данные по конкретной локации
        );
      }

      return Observable.of(
        updateCyclesComparison(newCyclesToCompare),
        () => actionAfterSuccess(newData[organizationId]), // сюда отправляем апдейтнутые данные по конкретной локации
      );
    });

export const removeAllCyclesFromComparisonEpic = (action$, store) =>
  action$.ofType(removeAllCyclesFromComparison)
    .switchMap(() => {
      const { company, benchmark: { cyclesToCompare } } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const currentCycles = JSON.parse(cyclesToCompare) || {};

      const newData = {
        ...currentCycles,

        [organizationId]: [],
      };

      const newCyclesToCompare = JSON.stringify(newData);

      safeLocalStorage.setItem(CYCLES_TO_COMPARE, newCyclesToCompare);

      return Observable.of(
        updateCyclesComparison(newCyclesToCompare),
      );
    });

export const replaceAllCyclesInComparisonEpic = (action$, store) =>
  action$.ofType(replaceAllCyclesInComparison)
    .switchMap((actionData) => {
      const {
        company: { location, benchmarkAllProducts },
        benchmark: { cyclesToCompare }
      } = store.getState();

      const { id: organizationId } = location;

      const {
        payload: { cycleIdsList = [], actionAfterSuccess = () => {} }
      } = actionData;

      const currentCycles = JSON.parse(cyclesToCompare) || {};

      if (cycleIdsList.length > CYCLES_TO_COMPARE_LIMIT) {
        return Observable.of(
          showNotificationWithTimeout({
            id: `notifications.benchmarkLimitError.${Date.now()}`,
            messageId: 'benchmarking.compareLimit',
            position: 'leftDown',
            iconType: 'info',
          }),
        );
      }

      const isOtherSpecies = checkIfHasDifferentSpecies(benchmarkAllProducts, cycleIdsList);

      if (isOtherSpecies) {
        return Observable.of(
          showNotificationWithTimeout({
            id: `notifications.benchmarkLimitError.${Date.now()}`,
            messageId: 'benchmarking.differentSpecies',
            position: 'leftDown',
            iconType: 'info',
          }),
        );
      }

      const newData = {
        ...currentCycles,

        [organizationId]: cycleIdsList
      };

      const newCyclesToCompare = JSON.stringify(newData);

      safeLocalStorage.setItem(CYCLES_TO_COMPARE, newCyclesToCompare);

      return Observable.of(
        updateCyclesComparison(newCyclesToCompare),
        () => actionAfterSuccess(cycleIdsList),
      );
    });

export default combineEpics(
  deleteBenchmarkComparisonMetricsEpic,
  getBenchmarkComparisonEpic,
  getBenchmarkComparisonMetricsEpic,
  getBenchmarkGroupsEpic,
  getBenchmarkOverviewEpic,
  receiveBenchmarkComparisonEpic,
  updateBenchmarkComparisonMetricsEpic,
  getBenchmarkMetricsEpic,
  addCycleToComparisonEpic,
  removeCycleFromComparisonEpic,
  removeAllCyclesFromComparisonEpic,
  replaceAllCyclesInComparisonEpic,
);
