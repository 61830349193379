import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import BigButton from '../../../BigButton';
import DefaultTextInput from '../../../DefaultTextInput';

import styles from './UserInformationForm.module.css';

export default class UserInformationForm extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    isUserInfoUpdated: PropTypes.bool,
    userPhone: PropTypes.string,
    userFactor2: PropTypes.bool,

    requestUpdateUserInfo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userName: null,
    userEmail: null,
    isUserInfoUpdated: false,
    userPhone: undefined,
    userFactor2: false,
  };

  state = {
    newUserName: this.props.userName,
  };

  handlerUpdateInputValue = event => this.setState({ newUserName: event.target.value });

  handlerCancelUpdate = () => this.setState({ newUserName: this.props.userName });

  handlerSubmitForm = (e) => {
    const { requestUpdateUserInfo } = this.props;
    const { newUserName } = this.state;

    e.preventDefault();

    requestUpdateUserInfo({ name: newUserName });
  }

  render() {
    const {
      intl: { formatMessage }, userName, userEmail, isUserInfoUpdated, userFactor2, userPhone
    } = this.props;
    const { newUserName } = this.state;

    const isNameChanged = newUserName !== userName;
    const isNameInvalid = newUserName.length === 0;

    const phoneText = userPhone || formatMessage({ id: 'login.notAdded' });

    return (
      <form
        className={styles.userInformationForm}
        onSubmit={this.handlerSubmitForm}
      >
        <DefaultTextInput
          className={styles.userInfoInput}
          placeholder={formatMessage({ id: 'settings.fullName' })}
          value={newUserName}
          onChange={this.handlerUpdateInputValue}
        />
        <DefaultTextInput
          className={styles.userInfoInput}
          placeholder={formatMessage({ id: 'login.email' })}
          value={userEmail}
          disabled
        />
        {userFactor2 && (
          <DefaultTextInput
            className={styles.userInfoInput}
            placeholder={formatMessage({ id: 'login.phone' })}
            value={phoneText}
            disabled
          />
        )}
        {isNameChanged && (
          <div className={styles.buttons}>
            <BigButton
              title={formatMessage({ id: 'button.saveChanges' })}
              type='submit'
              theme={!isNameInvalid ? 'dark' : 'light'}
              disabled={!isNameChanged || isNameInvalid}
              isLoading={isUserInfoUpdated}
            />
            <BigButton
              className={styles.cancelButton}
              title={formatMessage({ id: 'button.cancel' })}
              type='button'
              onClick={this.handlerCancelUpdate}
              theme='light'
              disabled={isUserInfoUpdated}
            />
          </div>
        )}
      </form>
    );
  }
}
