import classnames from 'classnames';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import Select from '../Select';

import styles from './CustomLightSelectInput.module.css';

export default class CustomLightSelectInput extends Component {
  static propTypes = {
    options: PropTypes.array,
    input: PropTypes.object,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    empty: PropTypes.string,
    className: PropTypes.string,
    classNameButton: PropTypes.string,
    labelPath: PropTypes.string,
    buttonLabelPath: PropTypes.string,
    valuePath: PropTypes.string,
    titlePath: PropTypes.string,
    disabled: PropTypes.bool,
    isSearchable: PropTypes.bool,
    onInnerChange: PropTypes.func,
    onInnerOpenChange: PropTypes.func,
  };

  static defaultProps = {
    isSearchable: false,
    disabled: false,
    onInnerChange: null,
    onInnerOpenChange: null,
    input: null,
    meta: null,
    empty: PropTypes.string,
    placeholder: '',
    options: [],
    className: null,
    classNameButton: null,
    labelPath: 'title',
    buttonLabelPath: 'title',
    valuePath: 'id',
    titlePath: 'title',
  };

  render() {
    const {
      input,
      meta,
      placeholder,
      empty,
      className,
      classNameButton,
      options,
      labelPath,
      buttonLabelPath,
      valuePath,
      titlePath,
      disabled,
      isSearchable,
      onInnerChange,
      onInnerOpenChange,
    } = this.props;

    const {
      onChange,
      onBlur,
      value,
    } = input;

    const {
      error,
      touched,
    } = meta;

    return (
      <Select
        className={classnames(styles.selectWrapper, className)}
        classNameButton={classnames(styles.selectButton, classNameButton)}
        classNamePopup={styles.selectPopup}
        error={touched && error ? error : null}
        value={value}
        isSearchable={isSearchable}
        onChange={(selected) => {
          const { value: innerValue } = selected;
          onChange(innerValue);

          if (onInnerChange) {
            onInnerChange(selected);
          }
        }}
        onOpenChange={(open) => {
          if (onInnerOpenChange) {
            onInnerOpenChange(open);
          }
        }}
        onBlur={(data) => {
          onBlur(data);
        }}
        placeholder={placeholder}
        empty={empty}
        options={options}
        labelPath={labelPath}
        buttonLabelPath={buttonLabelPath}
        valuePath={valuePath}
        titlePath={titlePath}
        disabled={disabled}
        closeOnChange
      />
    );
  }
}
