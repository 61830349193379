import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { get, find, uniqueId } from 'lodash';
import { getMlName } from '../../../../helpers/getVarietyName';
import BreakdownItem from '../BreakdownItem';

import styles from './BreakdownGroup.module.css';

class BreakdownGroup extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    speciesName: PropTypes.string.isRequired,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    allPlantingCycles: PropTypes.array.isRequired,
    isCurrentPeriod: PropTypes.bool.isRequired,
    organization: PropTypes.object,
    units: PropTypes.string.isRequired,
    speciesCount: PropTypes.number.isRequired,
    breakdownGroupData: PropTypes.array,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    organization: null,
    breakdownGroupData: null,
    isFetching: false,
  };

  getBreakdownItemName = (item) => {
    const {
      intl: { formatMessage, locale, },
      varieties,
      compartments,
      fruitClasses,
    } = this.props;
    const defaultText = formatMessage({ id: 'fruitClass.other' });
    const type = get(item, 'key.type');

    // TODO: вынести в хелпер
    switch (type) {
      case 'Variety': {
        const variety = find(varieties, { id: get(item, 'key.id') });

        return getMlName(variety, locale);
      }
      case 'Compartment': {
        const compartment = find(compartments, { id: get(item, 'key.id') });

        return get(compartment, 'attributes.name');
      }
      case 'FruitClass': {
        const fruitClassId = get(item, 'key.id');
        const currentFruitClass = find(fruitClasses, { id: fruitClassId });

        const fruitClassName = fruitClassId === 'null' ? formatMessage({ id: 'fruitClass.other' }) : getMlName(currentFruitClass, locale);

        return fruitClassName;
      }
      default:
        return defaultText;
    }
  };

  render() {
    const {
      intl: { formatMessage },
      organization,
      varieties,
      fruitClasses,
      compartments,
      allPlantingCycles,
      speciesName,
      breakdownGroupData,
      units,
      isFetching,
      isCurrentPeriod,
      speciesCount,
    } = this.props;

    if (!breakdownGroupData) {
      return null;
    }

    // Natural sort
    const sortedBreakdownGroupData = breakdownGroupData
      .sort((a, b) => {
        const first = this.getBreakdownItemName(a);
        const second = this.getBreakdownItemName(b);

        if (!first) {
          return -1;
        }

        if (!second) {
          return +1;
        }

        return second.localeCompare(first, undefined, { numeric: true });
      })
      .reverse();

    return (
      <div className={styles.breakdownGroup}>
        {speciesCount > 1 && (
          <div className={styles.speciesName}>
            {formatMessage({ id: `plantingCycles.speciesPlural.${speciesName}` })}
          </div>
        )}
        <div className={styles.breakdownContainer}>
          {sortedBreakdownGroupData.map(item => (
            <BreakdownItem
              key={uniqueId('breakdow-item-')}
              name={this.getBreakdownItemName(item)}
              itemData={item}
              units={units}
              isFetching={isFetching}
              species={speciesName}
              varieties={varieties}
              fruitClasses={fruitClasses}
              compartments={compartments}
              allPlantingCycles={allPlantingCycles}
              type={get(item, 'key.type')}
              isCurrentPeriod={isCurrentPeriod}
              organization={organization}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default injectIntl(BreakdownGroup);
