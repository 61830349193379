import * as moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { utcMonday } from 'd3';
import isNumber from 'lodash/isNumber';

import Typography from 'components/Typography';
import SummaryTable from 'components/UnrealizedPotential/components/SummaryTable';
import LinesGraph from 'components/LinesGraph';

import renderTooltipContent from '../../../../renderTooltipContent';

import styles from './MetricsRow.module.css';

const tickTimeDisplayFormat = date => moment(date).isoWeek();
const defaultSize = { height: 176 };
const drawValue = value => (isNumber(value) ? value.toString() : '—');

const MetricsRow = ({
  series,
  summary,
  discrepancy,
  title,
  intl,
  onTooltipChanged,
  screenY,
  screenX,
  closestPoint,
  closestPointCoordX,
  tooltipDate,
  showTooltip,
  roundTo
}) => {
  const { formatMessage } = intl;

  const maxValue = useMemo(() =>
    Math.max(...series.reduce((acc, item) => [...acc, ...item.points.map(p => p.y)], [])) || 1,
  [series]);

  const customTicksFunction = useCallback((xFromValue, xToValue, ticksCount, preparedData) => {
    const ticksByData = preparedData ? (preparedData.length / 10) : 1;
    const everyTick = ticksByData > 1 ? ticksByData : 1;
    return utcMonday.every(everyTick);
  }, []);

  const renderTooltip = useCallback(date => renderTooltipContent({
    date, lines: series, formatMessage, roundTo
  }), [series, formatMessage, roundTo]);

  return (
    <div className={styles.metricsRow}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <Typography variant='h4'>
            <FormattedMessage id={`unrealizedPotential.metrics.${title}`} />
          </Typography>
        </div>
        <div className={styles.discrepancy}>
          <div className={styles.discrepancyTitle}>
            <FormattedMessage id='unrealizedPotential.discrepancy' />
          </div>
          <div className={styles.discrepancyValue}>{drawValue(discrepancy)}%</div>
        </div>
      </div>
      <div className={styles.metricContainer}>
        <div className={styles.chartWrapper}>
          <LinesGraph
            onTooltipChanged={onTooltipChanged}
            lines={series}
            tickTimeDisplayFormat={tickTimeDisplayFormat}
            customTicksFunction={customTicksFunction}
            withoutPoints
            isLegendDisabled
            defaultSize={defaultSize}
            maxValue={maxValue}
            renderTooltipContent={renderTooltip}
            screenY={screenY}
            screenX={screenX}
            closestPoint={closestPoint}
            closestPointCoordX={closestPointCoordX}
            tooltipDate={tooltipDate}
            showTooltip={showTooltip}
          />
        </div>
        <div className={styles.tableWrapper}>
          <SummaryTable {...summary} />
        </div>
      </div>
    </div>
  );
};

MetricsRow.propTypes = {
  discrepancy: PropTypes.number.isRequired,
  summary: PropTypes.object.isRequired,
  series: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  descriptor: PropTypes.object.isRequired,
  onTooltipChanged: PropTypes.func.isRequired,
  screenX: PropTypes.number,
  screenY: PropTypes.number,
  closestPointCoordX: PropTypes.number,
  closestPoint: PropTypes.object,
  tooltipDate: PropTypes.any,
  showTooltip: PropTypes.bool,
  roundTo: PropTypes.number
};

MetricsRow.defaultProps = {
  screenX: null,
  screenY: null,
  closestPointCoordX: null,
  closestPoint: null,
  tooltipDate: null,
  showTooltip: false,
  roundTo: 1
};

export default injectIntl(MetricsRow);
