import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './HarvestParameter.module.css';

export default class HarvestParameter extends Component {
  static propTypes = {
    headerText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]).isRequired,
    amount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    units: PropTypes.string,
    containerClassName: PropTypes.string,

    rightContent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    bottomContent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
  };

  static defaultProps = {
    amount: null,
    units: null,
    containerClassName: null,
    rightContent: null,
    bottomContent: null,
  };

  render() {
    const {
      headerText, amount, units, rightContent, bottomContent, containerClassName
    } = this.props;

    return (
      <div
        className={classnames(
          styles.harvestParam,
          containerClassName,
          { [styles.withoutRightContent]: (!rightContent && !bottomContent) },
        )}
      >
        <div className={styles.harvestHeader}>
          {headerText}
        </div>
        <div className={styles.harvestValueWrapper}>
          <div className={styles.harvestValue}>
            <span className={styles.harvestValueAmount}>{amount}</span>
            <span className={styles.harvestValueUnits}>{units}</span>
            {rightContent &&
              <span className={styles.rightContent}>{rightContent}</span>}
          </div>
          {bottomContent &&
            <div>{bottomContent}</div>}
        </div>
      </div>
    );
  }
}
