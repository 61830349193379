import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { get, find } from 'lodash';
import moment from 'moment-timezone';

import CircleLoader from '../../../CircleLoader';
import EmptyState from '../../../EmptyState';
import Legend from '../../../Legend';
import { Pie } from '../../../PieChart';

import { MEDIA_TABLET_BIG } from '../../../../helpers/constants';
import LINE_COLORS from '../../../../helpers/graphColors';
import isPhoneView from '../../../../helpers/isPhoneView';
import { API_DATE_FORMAT } from '../../../../helpers/defaultDates';
import getDateFormat from '../../../../helpers/getDateFormat';
import { getValue, getUnits } from '../../../../helpers/getValueDetails';
import { getMlName } from '../../../../helpers/getVarietyName';
import { getPlantingCycleLabel } from '../../../../helpers/getPlantingCycleLabel';

import styles from './BreakdownGraph.module.css';
import loaderStyles from '../../../CircleLoader/CircleLoader.module.css';

class BreakdownGraph extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    breakdown: PropTypes.array.isRequired,
    roundTo: PropTypes.number.isRequired,
    varieties: PropTypes.array.isRequired,
    plantingCycles: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    isDataFetching: PropTypes.bool,
    anyDateOfPeriodStart: PropTypes.string.isRequired,
    anyDateOfPeriodEnd: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isDataFetching: false,
  };

  getBreakDownName = (type, id) => {
    const {
      intl,
      varieties,
      plantingCycles,
      compartments,
      fruitClasses,
    } = this.props;

    const { formatMessage, locale } = intl;

    switch (type) {
      case 'Variety': {
        const currentVariety = find(varieties, { id });

        return getMlName(currentVariety, locale);
      }
      case 'PlantingCycle': {
        const plantingCycle = find(plantingCycles, { id });

        return getPlantingCycleLabel(plantingCycle, varieties, fruitClasses, intl);
      }
      case 'Compartment': {
        const compartment = find(compartments, { id });

        return get(compartment, 'attributes.name');
      }
      case 'FruitClass': {
        const currentFruitClass = find(fruitClasses, { id });

        return getMlName(currentFruitClass, locale);
      }
      default:
        return formatMessage({ id: 'fruitClass.other' });
    }
  };

  getLegendLines = data => data.map((item, i) => {
    const itemType = get(item, 'key.type');
    const itemId = get(item, 'key.id');

    return {
      id: `${itemType}-${itemId}`,
      name: this.getBreakDownName(itemType, itemId),
      color: LINE_COLORS[i] || LINE_COLORS[0],
    };
  });

  getPieChartData = breakdown => breakdown.map((item, i) => {
    const itemType = get(item, 'key.type');
    const itemId = get(item, 'key.id');

    const labelText = this.getBreakDownName(itemType, itemId);

    return {
      id: `${itemType}-${itemId}`,
      label: labelText,
      value: item.harvest.amount,
      harvest: item.harvest,
      harvestPercent: item.harvestPercent,
      color: LINE_COLORS[i] || LINE_COLORS[0],
    };
  });

  getPieChartSize = (isPhone, isTablet) => {
    const windowWidth = window.innerWidth;

    let pieMargins = {
      top: 90, right: 25, bottom: 90, left: 25
    };

    let pieWidth = 500;
    let pieHeight = 370;

    if (isTablet) {
      pieMargins = {
        top: 45, right: 15, bottom: 45, left: 15
      };
      pieWidth = 300;
      pieHeight = 270;
    }

    if (isPhone) {
      pieMargins = {
        top: 20, right: 0, bottom: 20, left: 0
      };
      pieWidth = windowWidth;
      pieHeight = 200;
    }

    return {
      pieWidth,
      pieHeight,
      pieMargins,
    };
  };

  renderRadialLabel = (d, roundTo, formatMessage) => {
    const harvestValue = getValue(d.harvest, roundTo);
    const harvestUnits = getUnits(d.harvest, formatMessage);

    const harvestPercentValue = getValue(d.harvestPercent, roundTo);
    const harvestPercentUnits = getUnits(d.harvestPercent, formatMessage);

    return (
      <tspan className={styles.radialLabel}>
        {harvestPercentValue}{harvestPercentUnits}
        <tspan className={styles.radialLabelSubtext}>
          {` (${harvestValue} ${harvestUnits})`}
        </tspan>
      </tspan>
    );
  };

  renderTooltip = (d) => {
    const {
      intl: { formatMessage }, breakdown, roundTo, anyDateOfPeriodStart, anyDateOfPeriodEnd
    } = this.props;

    const harvestValue = getValue(d.harvest, roundTo);
    const harvestUnits = getUnits(d.harvest, formatMessage);

    const harvestPercentValue = getValue(d.harvestPercent, roundTo);
    const harvestPercentUnits = getUnits(d.harvestPercent, formatMessage);

    const legendLines = this.getLegendLines(breakdown);
    const currentLine = find(legendLines, { id: d.id });

    if (!currentLine) return null;

    const startOfPeriod = moment(anyDateOfPeriodStart, API_DATE_FORMAT).format(getDateFormat('lll'));
    const endOfPeriod = moment(anyDateOfPeriodEnd, API_DATE_FORMAT).format(getDateFormat('lll'));

    return (
      <div className={styles.tooltip}>
        <div className={styles.tooltipHeader}>
          {`${startOfPeriod} – ${endOfPeriod}`}
        </div>
        <div className={styles.tooltipContent}>
          <div className={styles.legendItem}>
            <div className={styles.legendItemColor}>
              <div className={styles.legendItemColorSquare} style={{ backgroundColor: currentLine.color }} />
            </div>
            <div className={styles.legendItemTitle}>{currentLine.name}</div>
          </div>
          <span className={styles.tooltipValue}>
            {harvestPercentValue}{harvestPercentUnits}
            <span className={styles.tooltipSubtext}>
              {` (${harvestValue} ${harvestUnits})`}
            </span>
          </span>
        </div>
      </div>
    );
  };

  render() {
    const {
      intl: { formatMessage }, breakdown, roundTo, isDataFetching
    } = this.props;

    const pieChartData = this.getPieChartData(breakdown);
    const legendLines = this.getLegendLines(breakdown);

    if (pieChartData.length === 0 && !isDataFetching) {
      return (
        <EmptyState
          isFixedSize
          className={styles.breakdownEmptyState}
          textClassName={styles.breakdownEmptyStateText}
          text={formatMessage({ id: 'harvestDashboard.emptyText' })}
        />
      );
    }

    const isPhone = isPhoneView();
    const isTablet = isPhoneView(MEDIA_TABLET_BIG);

    const { pieWidth, pieHeight, pieMargins } = this.getPieChartSize(isPhone, isTablet);

    return (
      <div className={styles.breakdownGraph}>
        <Pie
          width={pieWidth}
          height={pieHeight}
          margin={pieMargins}
          innerRadius={0.6}
          padAngle={0.7}
          enableSlicesLabels={false}
          enableRadialLabels={!isTablet}
          radialLabelsLinkColor='inherit'
          radialLabel={d => this.renderRadialLabel(d, roundTo, formatMessage)}
          radialLabelsLinkOffset={3}
          radialLabelsLinkDiagonalLength={10}
          radialLabelsLinkHorizontalLength={10}
          tooltip={d => this.renderTooltip(d)}
          theme={{ tooltip: { container: { padding: 0, boxShadow: 'none', background: 'none' } } }}
          animate
          colorBy={d => d.color}
          data={pieChartData}
          sortByValue={false}
        />
        <Legend lines={legendLines} />
        {isDataFetching && (
          <CircleLoader
            className={loaderStyles.circleLoader}
            iconClassName={loaderStyles.circleLoaderIcon}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(BreakdownGraph);
