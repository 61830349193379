import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { reset, reduxForm, Field } from 'redux-form';
import { get, isEmpty } from 'lodash';

import BigButton from '../../../BigButton';
import DefaultTextareaInput from '../../../DefaultTextareaInput';

import CheckIcon from '../../../Icons/CheckIcon';

import styles from './RateIncidentButtonForm.module.css';

const validate = (values, props) => {
  const { intl: { formatMessage } } = props;
  const errors = {};

  if (!values.rateIncidentReason) {
    errors.rateIncidentReason = formatMessage({ id: 'feedback.validationError' });
  }

  return errors;
};

class RateIncidentButtonForm extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    handleSubmit: PropTypes.func.isRequired,

    feedback: PropTypes.object,
    isFeedbackSendingFetching: PropTypes.bool,

    handlerSubmitForm: PropTypes.func.isRequired,
    handlerDontSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    feedback: null,
    isFeedbackSendingFetching: false,
  };

  renderTextArea = ({
    input,
    meta: { touched, error, active },
  }) => {
    const { intl: { formatMessage }, feedback } = this.props;

    const feedbackText = get(feedback, 'comment', input.value);
    const feedbackInput = { ...input, value: feedbackText };

    return (
      <DefaultTextareaInput
        {...feedbackInput}
        theme='mini'
        placeholder={formatMessage({ id: 'feedback.textareaPlaceholder' })}
        rows={3}
        error={touched && !active && error ? error : null}
        disabled={!isEmpty(feedback)}
      />
    );
  };

  renderButtons = () => {
    const {
      intl: { formatMessage }, feedback, isFeedbackSendingFetching, handlerDontSave,
    } = this.props;

    return (
      <div className={styles.buttons}>
        {feedback ? (
          <div className={styles.sentButtons}>
            <CheckIcon className={styles.checkIcon} />
            <span>
              {formatMessage({ id: 'feedback.alreadySent' })}
            </span>
          </div>
        )
          : (
            <>
              <BigButton
                className={styles.cancel}
                onClick={handlerDontSave}
                title={formatMessage({ id: 'dialog.cancel' })}
                theme='light'
              />
              <BigButton
                className={styles.confitm}
                title={formatMessage({ id: 'button.send' })}
                theme='dark'
                type='submit'
                isLoading={isFeedbackSendingFetching}
              />
            </>
          )}
      </div>
    );
  };

  render() {
    const { handleSubmit, handlerSubmitForm } = this.props;

    return (
      <div className={styles.rateIncidentPopupText}>
        <form
          className={styles.rateIncidentForm}
          onSubmit={handleSubmit(handlerSubmitForm)}
        >
          <div className={styles.dialogText}>
            <Field
              name='rateIncidentReason'
              component={this.renderTextArea}
            />
          </div>
          {this.renderButtons()}
        </form>
      </div>
    );
  }
}

const afterSubmit = (result, dispatch) =>
  dispatch(reset('rateIncidentForm'));

export default reduxForm({
  form: 'rateIncidentForm',
  validate,
  destroyOnUnmount: false,
  onSubmitSuccess: afterSubmit
})(RateIncidentButtonForm);
