import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import classnames from 'classnames';
import { isString } from 'lodash';
import Select from 'components/Select';
import FilterIcon from 'components/Icons/FilterIcon';

import styles from './GroupFilter.module.css';
import BigSelectInput from '../../../BigSelectInput';

class GroupFilter extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    filters: PropTypes.arrayOf(
      PropTypes.oneOf([
        'breakdown',
        'compartment',
        'unit',
        'variety',
        'workType',
        'relative',
        'plan',
        'incidents',
        'severity',
        'incidentsGroupBy',
        PropTypes.arrayOf,
        PropTypes.object
      ])
    ),
    mode: PropTypes.oneOf(['dashboards:labor', 'dashboards:energy']),
    workTypes: PropTypes.array,
    varieties: PropTypes.array.isRequired,
    species: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    currentBreakdown: PropTypes.string,
    currentUnitKind: PropTypes.string,
    currentGrownFilter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    currentWorkTypeId: PropTypes.array,
    currentCompartment: PropTypes.string,
    currentSpecies: PropTypes.string,
    currentVarietyId: PropTypes.number,
    currentFruitClassCode: PropTypes.string,
    filtersMap: PropTypes.object,
    onFiltersChange: PropTypes.func,
    isOnlyVegetables: PropTypes.bool,
    isOnlyLettuce: PropTypes.bool,
    relative: PropTypes.string,
    withoutArrow: PropTypes.bool,
    className: PropTypes.string,
    renderIcon: PropTypes.func,
    renderPlaceholder: PropTypes.func,
    isMobile: PropTypes.bool,
    onResetAll: PropTypes.func,
    showResetAll: PropTypes.bool,
    onSetPlaceholder: PropTypes.func
  };

  static defaultProps = {
    onFiltersChange: null,
    mode: null,
    workTypes: null,
    currentBreakdown: 'compartment',
    currentUnitKind: 'absolute',
    currentGrownFilter: null,
    currentCompartment: null,
    currentWorkTypeId: null,
    currentSpecies: null,
    currentVarietyId: null,
    currentFruitClassCode: null,
    filtersMap: {},
    filters: [],
    isOnlyVegetables: null,
    isOnlyLettuce: null,
    relative: null,
    withoutArrow: false,
    className: null,
    renderIcon: null,
    renderPlaceholder: null,
    isMobile: false,
    onResetAll: null,
    showResetAll: false,
    onSetPlaceholder: null
  };

  state = {
    open: false,
  };

  handlerCloseSelect = () => {
    const { open } = this.state;

    if (open) {
      this.setState({ open: false });
    }
  };

  handlerSelectState = newState => this.setState({ open: newState });

  handlerFilterChanged = (options) => {
    const {
      onFiltersChange,
    } = this.props;

    if (onFiltersChange) {
      onFiltersChange(options);
    }
  };

  getApplied = () => {
    const {
      currentCompartment,
      currentSpecies,
      currentVarietyId,
      currentFruitClassCode,
      filters
    } = this.props;
    return filters.map((filterKey) => {
      if (Array.isArray(filterKey)) {
        // TODO: Inner group filters
      } else {
        const type = filterKey?.type || filterKey;
        switch (type) {
          case 'compartment': {
            if (currentCompartment) {
              return 'compartment';
            }
            break;
          }
          case 'variety': {
            if (currentVarietyId || currentSpecies || currentFruitClassCode) {
              return 'variety';
            }
            break;
          }
          default:
          // TODO: All other types
        }
      }
      return null;
    }).filter(val => val !== null);
  }

  renderFilter = (filterKey, index, isMobile) => {
    const {
      intl,
      varieties,
      compartments,
      fruitClasses,
      species,
      workTypes,
      mode,
      currentBreakdown,
      currentCompartment,
      currentUnitKind,
      currentGrownFilter,
      currentWorkTypeId,
      currentSpecies,
      currentVarietyId,
      currentFruitClassCode,
      filtersMap,
      isOnlyVegetables,
      isOnlyLettuce,
      relative,
      onSetPlaceholder
    } = this.props;

    let Filter;

    if (isString(filterKey)) {
      Filter = filtersMap[filterKey];
    } else {
      Filter = filtersMap[filterKey.type];
    }

    const classNameButton = filterKey?.options?.classNameButton ?
      classnames(styles.button, filterKey?.options?.classNameButton) :
      styles.button;

    const classNameWrapper = filterKey?.options?.classNameWrapper ?
      classnames(styles.noMargin, filterKey?.options?.classNameWrapper) :
      styles.noMargin;

    const key = `filter-grouped-${index}`;

    const mobileProps = {
      isMobile,
      noTitle: true,
      isTitleEnabled: true,
    };

    if (isMobile) {
      mobileProps.selectComponent = BigSelectInput;
     // mobileProps.noTitle = true;
     // mobileProps.isTitleEnabled = true;
      mobileProps.classNamePopup = styles.longPopup;
      mobileProps.classNameButton = styles.longButton;
      mobileProps.classNameWrapper = styles.longWrapper;
      mobileProps.closeOnChange = true;
    }

    if (!isMobile) {
      mobileProps.classNameButton = classNameButton;
      mobileProps.classNameWrapper = classNameWrapper;
    }

    const filterProps = {
      key,
      intl,
      varieties,
      compartments,
      fruitClasses,
      species,
      workTypes,
      mode,
      currentBreakdown,
      currentCompartment,
      currentUnitKind,
      currentGrownFilter,
      currentWorkTypeId,
      currentSpecies,
      currentVarietyId,
      currentFruitClassCode,
      isOnlyVegetables,
      isOnlyLettuce,
      relative,
      onSetPlaceholder,
      onFiltersChange: (options) => {
        this.handlerFilterChanged(options, key);
      },
      ...filterKey.options || {},
      ...mobileProps
    };


    if (isMobile && filterKey.type !== 'group') {
      return (
        <div className={styles.selectGroup}>
          <Filter {...filterProps} />
        </div>
      );
    }

    return (
      <div className={styles.selectWrapper}>
        <Filter {...filterProps} />
      </div>
    );
  }

  getPlaceholder = (applied) => {
    const {
      intl,
      renderPlaceholder
    } = this.props;
    const { formatMessage, formatPlural } = intl;

    if (renderPlaceholder) {
      return renderPlaceholder(applied);
    }

    const pluralFilterId = `groupFilter.applied.${formatPlural(applied.length)}`;
    return applied.length > 0 ? `${applied.length} ${formatMessage({ id: pluralFilterId })}` :
      formatMessage({ id: 'groupFilter.filter' });
  }

  handleClickResetAll = () => {
    const {
      filters,
      onResetAll
    } = this.props;
    if (onResetAll) {
      onResetAll(filters);
    }
  };

  renderIcon = () => {
    const applied = this.getApplied();
    const { renderIcon } = this.props;
    return renderIcon ? renderIcon(applied) : <FilterIcon className={styles.icon} />;
  }

  render() {
    const {
      filters, withoutArrow, className,
      isMobile, showResetAll
    } = this.props;
    if (isMobile) {
      return filters.map((filterKey, index) => this.renderFilter(filterKey, index, true));
    }
    const { open } = this.state;
    const applied = this.getApplied();
    return (
      <div className={classnames(styles.groupFilter, className)}>
        <div className={styles.selectContainer}>
          <Select
            classNamePopup={styles.topLevelPopup}
            classNameButton={styles.topLevelButton}
            placeholder={this.getPlaceholder(applied)}
            theme='transparent'
            size='medium'
            closeOnChange
            open={open}
            onOpenChange={this.handlerSelectState}
            textEllipsis
            withoutArrow={withoutArrow}
            renderIcon={this.renderIcon}
          >
            <div className={styles.groupFiltersContainer}>
              {filters.map((filterKey, index) => this.renderFilter(filterKey, index))}
            </div>
            {showResetAll && (
              <button
                type='button'
                className={styles.resetButton}
                onClick={this.handleClickResetAll}
              >
                Reset all
              </button>
            )}
          </Select>
        </div>
      </div>
    );
  }
}

export default injectIntl(GroupFilter);
