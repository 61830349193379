import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import * as classnames from 'classnames';
import moment from 'moment-timezone';
import { getNameByLocal } from '../../../../helpers/getNameByLocal';
import styles from '../BenchmarkMetricChart/BenchmarkMetricChart.module.css';
import Typography from '../../../Typography';
import getDateFormat from '../../../../helpers/getDateFormat';

const BenchmarkMetricChartInfo = ({
  intl,
  graphMetric,
  tooltipState,
  products
}) => {
  const { formatMessage, locale } = intl;

  const metricName = getNameByLocal(graphMetric, locale);

  return (
    <div className={classnames(styles.header)}>
      <Typography variant='subtitle2' bold>{metricName}</Typography>
      <div className={styles.dates}>
        {tooltipState?.isShow && tooltipState?.period ? (
          <Typography variant='body2'>
            <b>
              <FormattedMessage id='tables.week.one' />&nbsp;{tooltipState?.period?.weekNumber}:&nbsp;
            </b>
            {products
              .map((product) => {
                const productPeriods = product?.descriptor?.periods || [];
                const productPeriod = productPeriods.find(item => item.serialNumber === +tooltipState.serialNumber);
                let hintDate = formatMessage({ id: 'benchmarking.noDates' });
                if (productPeriod) {
                  const startDateMoment = moment(product.startDate).clone().startOf('week').format(getDateFormat('lll'));
                  const endDateMoment = moment(product.endDate).clone().endOf('week').format(getDateFormat('lll'));
                  hintDate = `${startDateMoment}—${endDateMoment}`;
                }
                return {
                  key: get(product, 'id'),
                  hintDate,
                };
              })
              .map((line, i) => <span key={`timestamp-${get(line, 'key', '')}`}>{i > 0 ? ' | ' : ''}{get(line, 'hintDate', '')}</span>)}
          </Typography>
        ) : null}
      </div>
    </div>
  );
};

BenchmarkMetricChartInfo.propTypes = {
  intl: intlShape.isRequired,
  graphMetric: PropTypes.object,
  tooltipState: PropTypes.any,
  // showTooltip: PropTypes.bool,
  // tooltipDate: PropTypes.any,
  products: PropTypes.array,
};

BenchmarkMetricChartInfo.defaultProps = {
  products: null,
  graphMetric: null,
  tooltipState: null
  // showTooltip: false,
  // tooltipDate: undefined,
};

export default injectIntl(BenchmarkMetricChartInfo);
