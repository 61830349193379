import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { intlShape } from 'react-intl';
import ReactDatePicker from 'react-datepicker/dist/react-datepicker.min';
import BigDateInput from '../../../BigDateInput';


import Select from '../../../Select';
import DateButton from '../DateButton';
import BigButton from '../../../BigButton';
import BigSelectInput from '../../../BigSelectInput';

import getDatePeriodRange from '../../../../helpers/getDatePeriodRange';

import { ReactComponent as LeftIcon } from './assets/left.svg';
import { ReactComponent as RightIcon } from './assets/right.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';

import styles from './DatePicker.module.css';


class DatePicker extends Component {
  static propTypes = {
    handlerAfterRangeSelect: PropTypes.func.isRequired,
    className: PropTypes.string,
    periodType: PropTypes.string.isRequired,
    organization: PropTypes.object.isRequired,
    date: PropTypes.object.isRequired,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    className: null,
    minDate: null,
    maxDate: null,
  };

  state = {
    isOpen: false,
    selectedDate: null,
    selectedPeriodType: null,
  };

  handlerClick = () => {
    this.setState(prevState => ({
      selectedDate: this.props.date,
      selectedPeriodType: this.props.periodType,
      isOpen: !prevState.open,
    }));
  };

  handlerDateSelect = (value) => {
    const { handlerAfterRangeSelect, periodType } = this.props;

    handlerAfterRangeSelect({ date: value, periodType });
  };

  handlerPeriodTypeSelect = (options) => {
    const { handlerAfterRangeSelect, date } = this.props;

    handlerAfterRangeSelect({ date, periodType: options.value });
  };

  handlerBackClick = () => {
    const {
      date,
      periodType,
      minDate,
      handlerAfterRangeSelect,
    } = this.props;

    if (periodType !== 'whole') {
      if (!date.clone().endOf(periodType).subtract(1, periodType).isBefore(minDate)) {
        const trigger = date.clone().subtract(1, periodType).isAfter(minDate);
        const result = trigger ? date.clone().subtract(1, periodType) : date.clone().subtract(Math.abs(minDate.diff(date, 'days')), 'days');

        handlerAfterRangeSelect({
          date: result,
          periodType,
        });
      }
    }
  };

  handlerForwardClick = () => {
    const {
      date,
      periodType,
      maxDate,
      handlerAfterRangeSelect,
    } = this.props;

    if (periodType !== 'whole') {
      if (!date.clone().startOf(periodType).add(1, periodType).isAfter(maxDate)) {
        const trigger = date.clone().add(1, periodType).isBefore(maxDate);
        const result = trigger ? date.clone().add(1, periodType) : date.clone().add(Math.abs(maxDate.diff(date, 'days')), 'days');

        handlerAfterRangeSelect({
          date: result,
          periodType,
        });
      }
    }
  };

  handlerClose = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  };

  handlerOpen = () => {
    this.setState({ isOpen: true });
  };

  handlerSave = () => {
    const { selectedDate, selectedPeriodType, } = this.state;
    const { handlerAfterRangeSelect, date, periodType } = this.props;

    handlerAfterRangeSelect({ date: selectedDate || date, periodType: selectedPeriodType || periodType });

    this.setState({ isOpen: false });
  };

  handlerPeriodTypeChange = ({ value }) => {
    this.setState({ selectedPeriodType: value });
  };

  handlerDateChange = (value) => {
    this.setState({ selectedDate: value });
  };

  render() {
    const {
      className,
      date,
      periodType,
      intl,
      maxDate,
      minDate,
      organization,
    } = this.props;

    const { isOpen, selectedDate, selectedPeriodType } = this.state;
    const isForwardDisabled = periodType === 'whole' || (selectedDate || date).clone().startOf(periodType).add(1, (selectedPeriodType || periodType)).isAfter(maxDate);
    const isBackDisabled = periodType === 'whole' || (selectedDate || date).clone().endOf(periodType).subtract(1, (selectedPeriodType || periodType)).isBefore(minDate);
    const { formatMessage } = intl;

    const periodTypes = [
      { label: formatMessage({ id: 'harvest.daily' }), value: 'day' },
      { label: formatMessage({ id: 'harvest.weekly' }), value: 'week' },
      { label: formatMessage({ id: 'harvest.entireCycle' }), value: 'whole' },
    ];

    const { start: startOfPeriod, end: endOfPeriod, sameYear } = getDatePeriodRange({
      date,
      periodType,
      minDate,
      maxDate,
      formatMessage,
    });

    const periodTypeLabel = periodTypes.find(({ value }) => value === periodType).label;

    return (
      <div className={classnames(styles.rangePickerContainer, className)}>
        <div className={styles.desktop}>
          <div className={classnames(styles.field, styles.period)}>
            <Select
              className={classnames(styles.selectWrapper)}
              classNameButton={styles.selectButton}
              classNamePopup={styles.selectPopup}
              labelPath='label'
              titlePath='label'
              valuePath='value'
              buttonLabelPath='label'
              organization={organization}
              options={periodTypes}
              value={periodType}
              onChange={this.handlerPeriodTypeSelect}
              closeOnChange
            />
          </div>
          <div className={styles.field}>
            <div className={styles.datePicker}>
              <button
                className={classnames(styles.leftButton, { [styles.disabled]: isBackDisabled })}
                onClick={this.handlerBackClick}
                disabled={isBackDisabled}
                type='button'
              >
                <LeftIcon />
              </button>
              <div>
                <ReactDatePicker
                  dropdownMode='select'
                  disabled={periodType === 'whole'}
                  minDate={minDate}
                  maxDate={maxDate}
                  customInput={(
                    <DateButton
                      intl={intl}
                      startOfPeriod={startOfPeriod}
                      endOfPeriod={endOfPeriod}
                      periodType={periodType}
                      disabled={periodType === 'whole'}
                      periodTypeLabel={periodTypeLabel}
                      isWide={!sameYear}
                    />
                  )}
                  selected={date}
                  onChange={this.handlerDateSelect}
                />
              </div>
              <button
                className={classnames(styles.rightButton, { [styles.disabled]: isForwardDisabled })}
                onClick={this.handlerForwardClick}
                disabled={isForwardDisabled}
                type='button'
              >
                <RightIcon />
              </button>
            </div>
          </div>
        </div>

        <div className={styles.mobile}>
          <div className={styles.field}>
            <div className={styles.datePicker}>
              <button
                className={classnames(styles.leftButton, { [styles.disabled]: isBackDisabled })}
                onClick={this.handlerBackClick}
                disabled={isBackDisabled}
                type='button'
              >
                <LeftIcon />
              </button>

              <DateButton
                intl={intl}
                startOfPeriod={startOfPeriod}
                endOfPeriod={endOfPeriod}
                periodType={periodType}
                disabled={false}
                onClick={this.handlerOpen}
                periodTypeLabel={periodTypeLabel}
              />

              <button
                className={classnames(styles.rightButton, { [styles.disabled]: isForwardDisabled })}
                onClick={this.handlerForwardClick}
                disabled={isForwardDisabled}
                type='button'
              >
                <RightIcon />
              </button>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className={styles.modal}>
            <div className={styles.header}>
              <div>
                {formatMessage({ id: 'harvest.selectPeriod' })}
              </div>
              <CloseIcon className={styles.close} onClick={this.handlerClose} />
            </div>
            <div className={styles.body}>
              <div className={styles.selects}>
                <BigSelectInput
                  placeholder={formatMessage({ id: 'harvest.period' })}
                  options={periodTypes}
                  classNameIcon={styles.popupDateSelectIcon}
                  value={selectedPeriodType || periodType}
                  onChange={this.handlerPeriodTypeChange}
                  labelPath='label'
                  valuePath='value'
                  closeOnChange
                />

                <BigDateInput
                  value={selectedDate || date}
                  onChange={this.handlerDateChange}
                  placeholder={formatMessage({ id: 'harvest.date' })}
                  minDate={minDate}
                  maxDate={maxDate}
                  disabled={(selectedPeriodType || periodType) === 'whole'}
                  periodType={(selectedPeriodType || periodType)}
                />
              </div>
              <div className={styles.buttons}>
                <BigButton
                  title={formatMessage({ id: 'button.cancel' })}
                  type='button'
                  onClick={this.handlerClose}
                  theme='light'
                />
                <BigButton
                  title={formatMessage({ id: 'cropsPerformance.showResults' })}
                  theme='dark'
                  onClick={this.handlerSave}
                />
              </div>
            </div>
          </div>
        )}

      </div>
    );
  }
}

export default DatePicker;
