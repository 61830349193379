import { createAction } from 'redux-actions';
import { SubmissionError } from 'redux-form';


export const setLoginError = createAction('SET_LOGIN_ERROR');
export const clearLoginError = createAction('CLEAR_LOGIN_ERROR');
export const clearLoginErrorWithDelay = createAction('CLEAR_LOGIN_ERROR_WITH_DELAY');

export const clearVerificationFormState = createAction('CLEAR_VERIFICATION_FORM_STATE');
export const clearVerificationErrors = createAction('CLEAR_VERIFICATION_ERRORS');
export const setVerificationError = createAction('SET_VERIFICATION_ERROR');

export const requestSendingCode = createAction('REQUEST_SENDING_CODE');
export const receiveSendingCode = createAction('RECEIVE_SENDING_CODE');

export const requestVerifyCode = createAction('REQUEST_VERIFY_CODE');
export const receiveVerifyCode = createAction('RECEIVE_VERIFY_CODE');
export const receiveVerifyCodeError = createAction('RECEIVE_VERIFY_CODE_ERROR');

export const requestSendResetEmail = createAction('REQUEST_SEND_RESET_EMAIL');
export const receiveSendResetEmail = createAction('RECEIVE_SEND_RESET_EMAIL');

export const cleanResetEmail = createAction('CLEAN_RESET_EMAIL');

export const requestResetPassword = createAction('REQUEST_RESET_PASSWORD');
export const receiveResetPassword = createAction('RECEIVE_RESET_PASSWORD');

export const requestValidateToken = createAction('REQUEST_VALIDATE_TOKEN');
export const receiveValidateToken = createAction('RECEIVE_VALIDATE_TOKEN');

export const requestUpdatePassword = createAction(
  'REQUEST_UPDATE_PASSWORD',
  payload => payload,
  (payload, meta) => meta,
);
export const receiveUpdatePassword = createAction('RECEIVE_UPDATE_PASSWORD');


export function userFormOnSubmit(payload) {
  return async (dispatch) => {
    try {
      await new Promise((resolve, reject) => {
        dispatch(requestUpdatePassword(payload, {
          resolve, reject
        }));
      });
    } catch (err) {
      throw new SubmissionError(err);
    }
  };
}
