// TODO: refactoring
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CellShape from './CellShape';

import styles from './ValueViewer.module.css';

export default class ValueViewer extends PureComponent {
  static propTypes = {
    row: PropTypes.number.isRequired,
    col: PropTypes.number.isRequired,
    cell: PropTypes.shape(CellShape),
    value: PropTypes.node.isRequired
  };

  render() {
    const { value } = this.props;
    return (
      <span className={classnames('value-viewer', styles.valueViewer)}>
        {value}
      </span>
    );
  }
}
/* eslint-enable react/no-unused-prop-types */
