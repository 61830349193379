import { handleActions } from 'redux-actions';
import { get } from 'lodash';

import {
  requestPlanFactReport,
  receivePlanFactReport,

  requestPlanFactGraph,
  receivePlanFactGraph,

  updatePlanFactSorting,

  requestPlanFactV2Report,
  receivePlanFactV2Report,
} from './actions';

const initialState = {
  isPlanFactFetching: false,
  isPlanFactV2Fetching: false,
  report: null,
  graph: null,
  units: null,
  planFactReportSorting: {
    type: 'type',
    direction: 'up'
  },
  v2Report: null,
};

export default handleActions({
  [requestPlanFactReport](state) {
    return {
      ...state,

      isPlanFactFetching: true,
    };
  },
  [receivePlanFactReport](state, action) {
    return {
      ...state,

      isPlanFactFetching: false,
      categories: get(action, 'payload.planFact.categories'),
      report: get(action, 'payload.planFact.report'),
      graph: get(action, 'payload.planFact.graph'),
      units: get(action, 'payload.planFact.units'),
    };
  },
  [requestPlanFactGraph](state) {
    return {
      ...state,

      isPlanFactFetching: true,
    };
  },
  [receivePlanFactGraph](state, action) {
    return {
      ...state,

      isPlanFactFetching: false,
      graph: get(action, 'payload.graph'),
    };
  },
  [updatePlanFactSorting](state, action) {
    return {
      ...state,

      planFactReportSorting: action.payload,
    };
  },
  [requestPlanFactV2Report](state) {
    return {
      ...state,

      isPlanFactV2Fetching: true,
    };
  },
  [receivePlanFactV2Report](state, action) {
    return {
      ...state,

      isPlanFactV2Fetching: false,
      v2Report: action?.payload,
    };
  },
}, initialState);
