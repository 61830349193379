import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { setDeletedPhoto, setSelectedPhoto, uploadPhoto } from 'store/photos/actions';
import { getIsAddPhotoAvailable, getOrganization, getPlantingCyclePhotoCategories } from 'store/company/selectors';
import { setTogglePhotoDeleteDialog, setTogglePhotoDetailDialog, setTogglePhotoUploadDialog } from 'store/dialogs';

import {
  getSelectedCompartment,
  getSelectedPhoto,
  getSelectedPhotos,
  getSelectedPlantingCycle,
  getSelectedWeek,
  getSelectedYear,
} from 'store/photos/selectors';

import PhotoUploadDialog from './PhotoUploadDialog';


const mapDispatchToProps = {
  setTogglePhotoDeleteDialog,
  setTogglePhotoUploadDialog,
  setTogglePhotoDetailDialog,
  setSelectedPhoto,
  setDeletedPhoto,
  uploadPhoto,
};

const mapStateToProps = state => ({
  location: getOrganization(state),
  photoCategories: getPlantingCyclePhotoCategories(state),
  selectedPhoto: getSelectedPhoto(state),
  selectedYear: getSelectedYear(state),
  selectedWeek: getSelectedWeek(state),
  selectedCompartment: getSelectedCompartment(state),
  selectedPlantingCycle: getSelectedPlantingCycle(state),
  selectedPhotos: getSelectedPhotos(state),
  isAddPhotoAvailable: getIsAddPhotoAvailable(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PhotoUploadDialog));
