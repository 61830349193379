import { sortBy, orderBy } from 'lodash';
import { getRowName } from 'helpers/getRowName';
import sortByLocal from 'helpers/sortByLocal';

/* eslint-disable import/prefer-default-export */
export const getSortedRows = ({
  sortingType,
  filteredRows,
  intl,
  workTypes,
  compartments,
  fruitClasses,
  varieties,
  productGroups,
  allPlantingCycles,
  currentBreakdown,
  currentSpecies,
  currentFruitClassCode,
  currentVarietyId,
  isPlantingCycleDatesAsTimestamp,
}) => {
  switch (sortingType) {
    case 'meter':
      return orderBy(
        filteredRows,
        row => getRowName({
          type: row.type,
          ref: row.ref,
          intl,
          workTypes,
          compartments,
          fruitClasses,
          varieties,
          productGroups,
          allPlantingCycles,
          currentBreakdown,
          currentSpecies,
          currentFruitClassCode,
          currentVarietyId,
          isPlantingCycleDatesAsTimestamp,
        }),
        (a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
      );
    case 'type':
      return sortByLocal(filteredRows, 'nameText');
    case 'plan':
      return sortBy(filteredRows, 'plan');
    case 'harvested':
      return sortBy(filteredRows, 'harvest.total');
    case 'implementation':
      return sortBy(filteredRows, 'harvest.totalPercentage');
    case 'quality':
      return sortBy(filteredRows, 'harvest.secondGrade');
    case 'qualityPercent':
      return sortBy(filteredRows, 'harvest.secondGradePercentage');
    default:
      return sortingType ? sortBy(filteredRows, `data.${sortingType}`) : filteredRows;
  }
};
/* eslint-enable import/prefer-default-export */
