import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import DefaultBackButton from 'components/DefaultBackButton';
import BigButton from 'components/BigButton';
import EmailField from 'components/EmailField';

import LoginResetSuccessSend from '../LoginResetSuccessSend';

import styles from './LoginReset.module.css';

class LoginReset extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    emailToReset: PropTypes.string,
    isResetEmailFetching: PropTypes.bool,
    requestSendResetEmail: PropTypes.func.isRequired,
    cleanResetEmail: PropTypes.func.isRequired,
  };

  static defaultProps = {
    emailToReset: null,
    isResetEmailFetching: false,
  };

  state = {
    email: '',
    emailError: null,
  };

  // TODO: Вынести в HOC обработку enter
  componentDidMount() {
    document.addEventListener('keydown', this.handlerKeyDown);
  }

  componentWillUnmount() {
    const { cleanResetEmail } = this.props;

    cleanResetEmail();

    document.removeEventListener('keydown', this.handlerKeyDown);
  }

  handlerKeyDown = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      return this.handlerSendEmail();
    }

    return null;
  };

  handlerSendEmail = () => {
    const {
      intl: { formatMessage },
      requestSendResetEmail
    } = this.props;
    const { email } = this.state;

    const emailError = email === '' || email.indexOf('@') === -1 ? formatMessage({ id: 'password.emailError' }) : null;

    if (emailError) {
      this.setState({ emailError });

      return;
    }

    requestSendResetEmail({ email });
  };

  handlerChangeEmail = e => this.setState({
    email: e.target.value,
    emailError: null
  });

  render() {
    const {
      intl: { formatMessage },
      isResetEmailFetching,
      emailToReset,
    } = this.props;
    const { email, emailError } = this.state;

    if (emailToReset) {
      return <LoginResetSuccessSend email={emailToReset} />;
    }

    return (
      <div className={styles.resetPageWrapper}>
        <DefaultBackButton
          className={styles.backButton}
          text={formatMessage({ id: 'button.back' })}
          link='/login'
        />
        <h2 className={styles.resetPageHeader}>
          {formatMessage({ id: 'password.resetPassword' })}
        </h2>
        <div className={styles.resetPageSubHeader}>
          {formatMessage({ id: 'password.enterEmail' })}
        </div>
        <div className={styles.emailField}>
          <EmailField
            value={email}
            placeholder={formatMessage({ id: 'login.email' })}
            onChange={this.handlerChangeEmail}
            error={emailError}
          />
        </div>

        <BigButton
          title={formatMessage({ id: 'password.sendResetLink' })}
          type='button'
          onClick={this.handlerSendEmail}
          theme='dark'
          className={styles.sendButton}
          titleClassName={styles.sendButtonTitle}
          isLoading={isResetEmailFetching}
        />
      </div>
    );
  }
}

export default LoginReset;
