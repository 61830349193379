import React, { useMemo } from 'react';
import * as d3 from 'd3';
import classnames from 'classnames';

import { DateType } from 'store/graphs/types';

import styles from './index.module.css';


export type LinePoint = {
  date: DateType;
  value: number;
};

export type AxisScaleType = d3.AxisScale<d3.AxisDomain>;

export type ChartLineProps = {
  data: Array<LinePoint>;
  xScale: any;
  yScale: AxisScaleType;

  key: string;
  selected: boolean;
  color: string;

  xFrom: DateType;
  xTo: DateType;
};

// TODO: Use parametrically polymorphism for all types,
const filterDataRows = (xFrom: DateType, xTo: DateType, point: LinePoint): boolean =>
  point.date.isSameOrAfter(xFrom) && point.date.isSameOrBefore(xTo);

type PointType = { x: number, y: number };

const mapDataRows = (
  point: LinePoint,
  xScale: AxisScaleType,
  yScale: AxisScaleType,
): PointType => ({
  x: xScale(point.date.toDate()) || 0,
  y: yScale(point.value) || 0
});

const ChartLine = ({
  key,
  color,
  selected,
  data,
  xScale,
  yScale,
  xFrom,
  xTo,
}: ChartLineProps) => {
  const linePath = useMemo(() => {
    if (!xScale || !yScale || !data || data.length === 0) {
      return '';
    }

    const filteredRows = data
      .filter(x => filterDataRows(xFrom, xTo, x))
      .map(x => mapDataRows(x, xScale, yScale));

    const line = d3
      .line<PointType>()
      .x(d => d.x)
      .y(d => d.y);

    return line(filteredRows) || '';
  }, [data, xScale, yScale, xFrom, xTo]);

  return (
    <g key={`data-line-path-${key}`}>
      <path
        className={classnames(styles.dataLine, styles.before, {
          [styles.selected]: selected,
          [styles.notSelected]: !selected
        })}
        style={{ stroke: color }}
        d={linePath}
      />
    </g>
  );
};

export default ChartLine;
