import { createAction } from 'redux-actions';

export const REQUEST_CLIMATE_INCIDENTS_TREE = 'REQUEST_CLIMATE_INCIDENTS_TREE';
export const requestClimateIncidentsTree = createAction(REQUEST_CLIMATE_INCIDENTS_TREE);

export const RECEIVE_CLIMATE_INCIDENTS_TREE = 'RECEIVE_CLIMATE_INCIDENTS_TREE';
export const receiveClimateIncidentsTree = createAction(RECEIVE_CLIMATE_INCIDENTS_TREE);

export const REQUEST_CLIMATE_INCIDENTS_TYPES = 'REQUEST_CLIMATE_INCIDENTS_TYPES';
export const requestClimateIncidentsTypes = createAction(REQUEST_CLIMATE_INCIDENTS_TYPES);

export const RECEIVE_CLIMATE_INCIDENTS_TYPES = 'RECEIVE_CLIMATE_INCIDENTS_TYPES';
export const receiveClimateIncidentsTypes = createAction(RECEIVE_CLIMATE_INCIDENTS_TYPES);

export const REQUEST_CLIMATE_INCIDENT = 'REQUEST_CLIMATE_INCIDENT';
export const requestClimateIncident = createAction(REQUEST_CLIMATE_INCIDENT);

export const RECEIVE_CLIMATE_INCIDENT = 'RECEIVE_CLIMATE_INCIDENT';
export const receiveClimateIncident = createAction(RECEIVE_CLIMATE_INCIDENT);

export const SET_TREE_EXPAND_NODES = 'SET_TREE_EXPAND_NODES';
export const setTreeExpandNodes = createAction(SET_TREE_EXPAND_NODES);
