import { handleActions } from 'redux-actions';
import { get } from 'lodash';

import {
  requestAllHarvestKpiDashboardData,
  requestBreakdownHarvestKpiDashboardData,
  requestHarvestKpiDashboardData,
  receiveHarvestKpiDashboardData,
  clearHarvestKpiDashboardData,
} from './actions';

const initialState = {
  isHarvestKpiFetching: false,
  isAllHarvestKpiFetching: false,
  isBreakdownHarvestKpiFetching: false,

  harvestKpi: null,
};

export default handleActions({
  [requestAllHarvestKpiDashboardData](state) {
    return {
      ...state,

      isAllHarvestKpiFetching: true,
    };
  },
  [requestBreakdownHarvestKpiDashboardData](state) {
    return {
      ...state,

      isBreakdownHarvestKpiFetching: true,
    };
  },
  [requestHarvestKpiDashboardData](state) {
    return {
      ...state,

      isHarvestKpiFetching: true,
    };
  },
  [receiveHarvestKpiDashboardData](state, action) {
    return {
      ...state,

      isHarvestKpiFetching: false,
      isAllHarvestKpiFetching: false,
      isBreakdownHarvestKpiFetching: false,
      // На самом деле это немного костыль, надо переделать на раздельные редьюсеры получения speciesOverall и breakdown
      harvestKpi: {
        speciesOverall: get(action, 'payload.harvestKpi.speciesOverall', null) || get(state, 'harvestKpi.speciesOverall', null),
        breakdown: get(action, 'payload.harvestKpi.breakdown'),
      }
    };
  },
  [clearHarvestKpiDashboardData](state) {
    return {
      ...state,

      harvestKpi: null,
    };
  },
}, initialState);
