import React, { useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';

import PaletteColorSelector from 'components/Graphs/components/ColorPicker/components/PaletteColorSelector';
import CustomColorSelector from 'components/Graphs/components/ColorPicker/components/CustomColorSelector';

import useOnClickOutside from 'hooks/useOnClickOutside';

import styles from './index.module.css';

const COLOR_PALETTE = [
  '#D25871', '#BE1034', '#AF0C27', '#990515',
  '#E69A81', '#DB6F4B', '#D25C3B', '#C44023',
  '#E6B675', '#DB973A', '#D2842C', '#C46918',
  '#95D98A', '#67C858', '#54BB47', '#39A72D',
  '#4DC8B6', '#00B197', '#00A184', '#008869',
  '#61CFE9', '#1DBADF', '#15ABD7', '#0A94CA',
  '#6BB5E9', '#2B95E0', '#2082D8', '#1067CB',
  '#4D7795', '#003C68', '#002E55', '#001A3A',
  '#AB72A1', '#873679', '#742966', '#571649',
  '#AF7ED5', '#8C46C3', '#7937B5', '#5C20A1',
  '#D879C8', '#C740B1', '#BA32A1', '#A61C88',
  '#D97BA2', '#C8427A', '#BB3367', '#A71D4A',
];

const ColorPicker = ({
  initValue,
  onChange,
  wrapperClassName,
  onClickOutside,
  intl
}) => {
  const { formatMessage } = intl;

  const ref = useRef(null);
  const [color, setColor] = useState(initValue);
  const [activeTab, setActiveTab] = useState('colors');

  const hideClickOutside = useCallback(() => {
    if (onClickOutside) {
      onClickOutside();
    }
  }, [onClickOutside]);

  const handlerDebounceChange = useCallback(debounce((value) => {
    onChange(value);
  }, 400), [onChange]);

  useOnClickOutside(ref, hideClickOutside);

  return (
    <div ref={ref} className={classnames(styles.container, wrapperClassName)}>
      <div className={styles.tabList}>
        <div
          tabIndex={-1}
          role='button'
          className={classnames(styles.tab, {
            [styles.active]: activeTab === 'colors'
          })}
          onClick={() => {
            setActiveTab('colors');
          }}
        >{formatMessage({ id: 'graphs.colorPaletteTab' })}
        </div>
        <div
          tabIndex={0}
          role='button'
          className={classnames(styles.tab, {
            [styles.active]: activeTab === 'custom'
          })}
          onClick={() => {
            setActiveTab('custom');
          }}
        >{formatMessage({ id: 'graphs.customPaletteTab' })}
        </div>
      </div>
      {activeTab === 'colors' ? (
        <PaletteColorSelector
          onChange={(value) => {
            setColor(value);
            handlerDebounceChange(value);
          }}
          paletteColors={COLOR_PALETTE}
          initValue={initValue}
        />
      ) : (
        <CustomColorSelector
          onChange={(value) => {
            setColor(value);
            handlerDebounceChange(value);
          }}
          initValue={color}
        />
      )}
    </div>
  );
};

ColorPicker.propTypes = {
  intl: PropTypes.object.isRequired,
  initValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  wrapperClassName: PropTypes.string,
  onClickOutside: PropTypes.func
};

ColorPicker.defaultProps = {
  initValue: 'rgba(255,255,255,1)',
  wrapperClassName: null,
  onClickOutside: null
};

export default injectIntl(ColorPicker);
