
import React, { Component, } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';

import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import {
  get, cloneDeep, throttle, isEqual
} from 'lodash';
import moment from 'moment-timezone';
import classnames from 'classnames';

import DefaultSimpleTooltip from 'components/DefaultSimpleTooltip';

import storageWrapper from '../../helpers/storageWrapper';
import numbersRounding from '../../helpers/numbersRounding';
import getDateFormat from '../../helpers/getDateFormat';
import { isMetricHidden } from '../../helpers/checkHarvestMetrics';
import CircleLoader from '../CircleLoader';
import styles from './Table.module.css';
import { ReactComponent as EditIcon } from './assets/edit.svg';
import { ReactComponent as CommentIcon } from './assets/comment.svg';
import { ReactComponent as ChartIcon } from './assets/chart.svg';
import { ReactComponent as ShareIcon } from './assets/share.svg';
import ChartNotSelectedIcon from '../Icons/ChartNotSelectedIcon';
import ChartSelectedIcon from '../Icons/ChartSelectedIcon';

import tableStyles from '../DefaultTableStyles/DefaultTableStyles.module.css';


const safeLocalStorage = storageWrapper.get('localStorage');

// TODO: в таблицах почему-то происходит постоянный перерендер, разобраться почему
class Table extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    location: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    weeklyData: PropTypes.array.isRequired,
    metrics: PropTypes.array,
    mode: PropTypes.string.isRequired,
    compartment: PropTypes.object,
    plantingCycle: PropTypes.object,
    getLeftDailyData: PropTypes.func.isRequired,
    getRightDailyData: PropTypes.func.isRequired,
    trackClickClimateTableWeek: PropTypes.func.isRequired,
    requestDrillDown: PropTypes.func.isRequired,
    canShowIndicatorsColumn: PropTypes.bool,
    className: PropTypes.string,
    tableClassName: PropTypes.string,
    selectedMetricId: PropTypes.number,
    onTableCellEdit: PropTypes.func,
    updateSelectedMetricId: PropTypes.func,
    onlyFirstColumn: PropTypes.bool,
    isDrillDownFetching: PropTypes.bool,
    tableWrapperClassName: PropTypes.string
  };

  static defaultProps = {
    metrics: [],
    updateSelectedMetricId: null,
    compartment: null,
    plantingCycle: null,
    onTableCellEdit: null,
    canShowIndicatorsColumn: false,
    className: '',
    tableClassName: '',
    selectedMetricId: null,
    onlyFirstColumn: false,
    isDrillDownFetching: false,
    tableWrapperClassName: null
  };

  constructor(...props) {
    super(...props);

    this.hints = {};
  }

  state = {
    expanded: [],
    cellEditPopupOpen: false,
    cellEditPopupMetric: null,
    cellEditPopupWeek: null,
    cellEditPopupDay: null,
    cellDailyData: null,
    tableScrolled: false,
  };

  componentDidMount() {
    ReactTooltip.rebuild();
    // I picked this because it's approx 1 frame (ie: 16.7ms)
    document.addEventListener('scroll', throttle(this.handlerScroll, 16), true);
    document.addEventListener('click', this.handlerClick, true);


    this.scrollToEnd();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { metrics: oldMetrics } = this.props;
    const { metrics: nextMetrics } = nextProps;

    if (oldMetrics !== nextMetrics) {
      this.setState({
        expanded: [],
      });
    }

    ReactTooltip.rebuild();
  }

  componentDidUpdate(oldProps) {
    const { weeklyData: oldWeeklyData } = oldProps;
    const { weeklyData: nextWeeklyData } = this.props;

    const { expanded: nextExpanded } = this.state;

    const oldFilteredWeeklyData = oldWeeklyData
      .filter(week => moment(get(week, 'periodStart'), 'YYYY-MM-DD') <= moment())
      .map(({ periodStart, periodLength, metricValues }) => ({ periodStart, periodLength, metricValues }));

    const nextFilteredWeeklyData = nextWeeklyData
      .filter(week => moment(get(week, 'periodStart'), 'YYYY-MM-DD') <= moment())
      .map(({ periodStart, periodLength, metricValues }) => ({ periodStart, periodLength, metricValues }));

    if (!isEqual(oldFilteredWeeklyData, nextFilteredWeeklyData)) {
      this.scrollToEnd();
    }


    const fetchedPeriodStarts = nextWeeklyData
      .filter(({ isFetching, periodStart }) => !isFetching && nextExpanded.find(a => a === periodStart) && oldWeeklyData.find(({ isFetching: oldIsFetching, periodStart: oldPeriodStart }) => oldIsFetching && oldPeriodStart === periodStart))
      .map(({ periodStart }) => periodStart)
      .reverse();

    const fetchedPeriodStart = fetchedPeriodStarts && fetchedPeriodStarts.length ? fetchedPeriodStarts[0] : null;
    const isExpanded = nextExpanded.find(a => a === fetchedPeriodStart);
    const finded = nextWeeklyData.find(({ periodStart }) => periodStart === fetchedPeriodStart);

    if (finded && isExpanded) {
      this.scrollToLastWeekDay(finded);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', throttle(this.handlerScroll, 16), true);
    document.removeEventListener('click', this.handlerClick, true);
  }

  handlerClick = (event) => {
    const { target } = event;

    const { cellEditPopupOpen } = this.state;

    if (cellEditPopupOpen && !target.closest(`td.${styles.cell}`) && !target.closest(`.${styles.tooltip}`)) {
      this.setState({ cellEditPopupOpen: false });
    }
  };

  handlerScroll = (e) => {
    const { cellEditPopupOpen, tableScrolled } = this.state;

    if (e.target.className === tableStyles.wrapper) {
      if (e.target.scrollLeft > 0 && !tableScrolled) {
        this.setState({ tableScrolled: true });
      } else if (e.target.scrollLeft === 0 && tableScrolled) {
        this.setState({ tableScrolled: false });
      }
    }

    if (cellEditPopupOpen) {
      this.setState({ cellEditPopupOpen: false });
    }
  };

  handlerToggleTooltip = ({
    week,
    metric,
    event,
    day = null,
    dailyData = null
  }) => {
    const popupWidth = 160;
    const { cellEditPopupOpen, cellEditPopupWeek, cellEditPopupDay } = this.state;


    const isMetricEqual = cellEditPopupWeek && cellEditPopupWeek.periodLength === week.periodLength && cellEditPopupWeek.periodStart === week.periodStart && day === cellEditPopupDay;

    const { target } = event;
    const cell = target.closest(`td.${tableStyles.cell}`);

    const {
      top,
      left,
      right,
      width,
    } = cell.getBoundingClientRect();

    const widthDelta = Math.abs(width - popupWidth) / 2;
    const popupLeft = left - widthDelta;

    let resultPopupLeft = popupLeft;


    if (popupLeft + popupWidth >= window.innerWidth) {
      resultPopupLeft = window.innerWidth - popupWidth - 10;
    }

    if (popupLeft <= 0) {
      resultPopupLeft = 0;
    }

    let resultPopupBeforeLeft = popupWidth / 2;

    if (popupLeft + popupWidth > window.innerWidth) {
      resultPopupBeforeLeft = Math.abs(window.innerWidth - left) / 2 + left - window.innerWidth + popupWidth; // eslint-disable-line no-mixed-operators
    }

    if (popupLeft <= 230) {
      if (window.innerWidth > 640) {
        const leftCenter = Math.abs(right - 230) / 2;
        const leftCenterOffset = leftCenter + 230;

        resultPopupBeforeLeft = leftCenterOffset - resultPopupLeft; // eslint-disable no-mixed-operators
      } else {
        const leftCenter = Math.abs(right - left) / 2;
        const leftCenterOffset = leftCenter + left;

        resultPopupBeforeLeft = leftCenterOffset - resultPopupLeft; // eslint-disable-line no-mixed-operators
      }
    }

    if (popupLeft <= 0) {
      resultPopupBeforeLeft = Math.abs(right) / 2;
    }

    const cellEditPopupStyle = {
      top: top - 75 - 8,
      left: resultPopupLeft,
    };

    const cellEditPopupBeforeStyle = {
      left: resultPopupBeforeLeft,
    };

    this.setState({
      cellEditPopupOpen: (!isMetricEqual || !cellEditPopupOpen),
      cellEditPopupMetric: metric,
      cellEditPopupWeek: week,
      cellEditPopupDay: day,
      cellEditPopupStyle,
      cellEditPopupBeforeStyle,
      cellDailyData: dailyData,
    });
  };

  handlerTableCellEdit = (options) => {
    const { onTableCellEdit } = this.props;

    if (onTableCellEdit) {
      onTableCellEdit(options);
    }
  };

  scrollToEnd = () => {
    setTimeout(() => {
      if (this.table && this.tableWrapper) {
        const componentStyle = window.getComputedStyle(this.table, null);
        const componentWidth = parseInt(componentStyle.getPropertyValue('width'), 10);

        if (this.tableWrapper) {
          this.tableWrapper.scrollLeft = componentWidth || 0;
        }
      }
    }, 100);
  };

  scrollToLastWeekDay = (weeklyData) => {
    const firstColumnWidth = 175;
    const { periodStart, rows } = weeklyData;
    const dailyData = rows[rows.length - 1];
    const { periodStart: dailyPeriodStart } = dailyData;

    const weekNumber = moment(periodStart).isoWeek();
    const dayNumber = moment(dailyPeriodStart).isoWeekday();

    setTimeout(() => {
      if (this.table && this.tableWrapper) {
        const weekCell = document.querySelector(`.table-header-cell-${moment(periodStart).format('YYYY-MM-DD')}`);
        const dayCell = document.querySelector(`.table-header-cell-${weekNumber}-${dayNumber}`);

        if (dayCell && weekCell) {
          const dayRect = dayCell.getBoundingClientRect();
          const weekRect = weekCell.getBoundingClientRect();

          if (this.tableWrapper) {
            const tableWrapperRect = this.tableWrapper.getBoundingClientRect();

            if (dayRect.x + dayRect.width > tableWrapperRect.x + tableWrapperRect.width) {
              const delta = (dayRect.x + dayRect.width + Math.round(dayRect.width / 2)) - (tableWrapperRect.x + tableWrapperRect.width);

              if (weekRect.x - delta > tableWrapperRect.x + firstColumnWidth) {
                this.tableWrapper.scrollLeft += delta;
              } else {
                this.tableWrapper.scrollLeft += weekRect.x - tableWrapperRect.x - firstColumnWidth;
              }
            }
          }
        }
      }
    }, 100);
  };

  renderCellEditPopup = () => {
    const {
      weeklyData,
      mode,
      getLeftDailyData,
      getRightDailyData,
      plantingCycle,
      compartment,
      location,
      requestDrillDown,
      isDrillDownFetching,
    } = this.props;

    const {
      cellEditPopupOpen,
      cellEditPopupMetric,
      cellEditPopupWeek,
      cellDailyData,
      cellEditPopupStyle,
      cellEditPopupBeforeStyle,
    } = this.state;

    if (!cellEditPopupOpen || !cellEditPopupMetric || !cellEditPopupWeek) {
      return null;
    }

    const { attributes: { manualInputAllowed, supportsDrillDown }, id } = cellEditPopupMetric;
    const { periodStart } = cellEditPopupWeek;

    if (manualInputAllowed) {
      return null;
    }

    // Хардкод для определения что это параметр "Total Radiation", нужен т.к. не считаем для него drill down
    const isTotalRadiation = id === 1003;


    let period = null;
    const isDailyPeriod = !!cellDailyData;

    // Если есть cellDailyData, то кликнули на день
    if (isDailyPeriod) {
      period = moment(cellDailyData?.periodStart).format('D MMM YYYY');
    } else {
      period = `${moment(periodStart).format(getDateFormat('ll'))} - ${moment(periodStart).add(6, 'days').format(getDateFormat('ll'))}`;
    }

    const handlerDrillDownClick = () => {
      const periodData = cellDailyData || cellEditPopupWeek;

      const drillDownPeriodLength = periodData?.periodLength;
      const drillDownPeriodStart = periodData?.periodStart;

      requestDrillDown({
        periodLength: drillDownPeriodLength,
        periodStart: drillDownPeriodStart,
        plantingCycleId: plantingCycle.id,
        metricId: cellEditPopupMetric.id,
        actionAfterSuccess: () => this.setState({
          cellEditPopupOpen: false,
        })
      });
    };

    const popupEditProps = {
      onClick: async () => {
        if (manualInputAllowed) {
          if (mode === 'left') {
            getLeftDailyData(plantingCycle.id, moment(periodStart), location);
          }

          if (mode === 'right') {
            getRightDailyData(plantingCycle.id, moment(periodStart), location);
          }

          const currentWeeklyDataItem = weeklyData.find(item => moment(periodStart).isSame(item.periodStart, 'day'));

          if (currentWeeklyDataItem) {
            const dailyValues = currentWeeklyDataItem.rows;

            this.handlerTableCellEdit({
              mode,
              open: true,
              date: moment(periodStart).isoWeekday(1),
              dailyValues,
              metric: cellEditPopupMetric,
              plantingCycle,
              compartment
            });

            this.setState({ cellEditPopupOpen: false });
          }
        }
      },
    };

    const isDrillDownDisabled = manualInputAllowed || isDrillDownFetching || isTotalRadiation || !supportsDrillDown;

    return (
      <div className={styles.tooltip} style={cellEditPopupStyle}>
        <div className={styles.before} style={cellEditPopupBeforeStyle} />
        <div className={styles.tooltipContent}>
          <div className={styles.tooltipPeriod}>{period}</div>
          <div className={styles.tooltipButtons}>
            <div
              className={classnames(styles.tooltipButton, { [styles.disabled]: !manualInputAllowed })}
              {...popupEditProps}
            >
              <EditIcon className={classnames(styles.tooltipButtonIcon)} />
            </div>
            <div className={classnames(styles.tooltipButton, styles.disabled)}><CommentIcon className={classnames(styles.tooltipButtonIcon)} /></div>
            <div className={classnames(styles.tooltipButton, styles.disabled)}><ChartIcon className={classnames(styles.tooltipButtonIcon)} /></div>
            {isDailyPeriod && (
              <DefaultSimpleTooltip
                text={<FormattedMessage id='tables.drillDown' />}
              >
                <div
                  className={classnames(styles.tooltipButton, {
                    [styles.disabled]: isDrillDownDisabled
                  })}
                  onClick={!isDrillDownDisabled ? handlerDrillDownClick : () => {}}
                  tabIndex={0}
                  role='button'
                >
                  <ShareIcon className={classnames(styles.tooltipButtonIcon)} />
                </div>
              </DefaultSimpleTooltip>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      intl: { formatMessage },
      weeklyData,
      metrics,
      mode,
      getLeftDailyData,
      getRightDailyData,
      plantingCycle,
      location,
      tableClassName,
      isFetching,
      updateSelectedMetricId,
      selectedMetricId,
      onlyFirstColumn,
      trackClickClimateTableWeek,
      tableWrapperClassName
    } = this.props;

    const { expanded: expandedState, tableScrolled } = this.state;
    const renderedCellEditPopup = this.renderCellEditPopup();
    const locale = safeLocalStorage.getItem('locale');
    const isTouchDevice = 'ontouchend' in document.documentElement;
    const expanded = cloneDeep(expandedState);

    // Выводим недели только до текущей даты
    const filteredWeeklyData = weeklyData.filter(week => moment(get(week, 'periodStart'), 'YYYY-MM-DD') <= moment());

    if (metrics && metrics.length) {
      return (
        <div className={classnames('table', styles.extraWrapper, {
          [styles.fullWidth]: mode !== 'left',
          [tableStyles.onlyFirstColumn]: onlyFirstColumn
        })}
        >
          <div className={classnames(tableStyles.wrapper, tableWrapperClassName)} ref={(node) => { this.tableWrapper = node; }}>
            <table
              className={classnames(tableStyles.table, tableClassName, { [tableStyles.tableScrolled]: tableScrolled })}
              ref={(node) => {
                this.table = node;
              }}
            >
              <tbody>
                <tr className={tableStyles.header}>
                  <th className={classnames(tableStyles.headerCell, tableStyles.indicatorCellHeader, tableStyles.indicatorCellContentHeader)}>
                    <FormattedMessage id='tables.indicatorName' />
                  </th>
                  {!onlyFirstColumn && filteredWeeklyData.length > 0 && filteredWeeklyData.map((week) => {
                    if (week) {
                      const { periodStart, isFetching: isWeekFetching, rows = [] } = week;
                      const weekNumber = moment(periodStart).isoWeek();
                      const isExpanded = expanded.findIndex(item => item === periodStart) >= 0;

                      const weeklyProps = {
                        onClick: async () => {
                          if (!isExpanded) {
                            trackClickClimateTableWeek('open');

                            expanded.push(periodStart);

                            this.setState({
                              expanded,
                            });
                          } else {
                            trackClickClimateTableWeek('close');
                            expanded.splice(expanded.findIndex(item => item === periodStart), 1);

                            this.setState({
                              expanded,
                            });
                          }

                          if (!isExpanded) {
                            if (mode === 'left') {
                              getLeftDailyData(plantingCycle.id, moment(periodStart), location);
                            }

                            if (mode === 'right') {
                              getRightDailyData(plantingCycle.id, moment(periodStart), location);
                            }
                          }
                        },
                      };

                      const headerCells = [
                        <td
                          className={
                            classnames(
                              tableStyles.headerCell,
                              tableStyles.headerCellMain,
                              `table-header-cell-${moment(periodStart).format('YYYY-MM-DD')}`,
                              {
                                [tableStyles.headerExpander]: isExpanded,
                                [tableStyles.borderLeft]: isExpanded,
                                [tableStyles.loading]: isWeekFetching
                              }
                            )
                          }
                          key={`table-header-cell-${weekNumber}`}
                        >
                          <div className={classnames(tableStyles.cellContentHeader)} {...weeklyProps}>
                            <div className={tableStyles.explandButton} />
                            {isFetching ?
                              <div className={classnames(tableStyles.loader, tableStyles.loaderTitle)} />
                              : (
                                <div className={tableStyles.cellContentHeaderTitle}>
                                  {<FormattedMessage id='tables.week.one' />} {weekNumber}
                                </div>
                              )}
                            {!isFetching && (
                            <div className={classnames(tableStyles.cellContentHeaderSubtitle)}>
                              {moment(periodStart).isoWeekday(1).format(getDateFormat('ll'))}
                              {' – '}
                              {moment(periodStart).isoWeekday(1).add(6, 'days').format(getDateFormat('ll'))}
                            </div>
                            )}
                          </div>
                        </td>
                      ];

                      if (isExpanded) {
                        if (isWeekFetching) {
                          const renderedWeeklyLoader = (
                            <th
                              className={
                                classnames(
                                  tableStyles.headerCell,
                                  tableStyles.headerCellExpanded,
                                  {
                                    [tableStyles.last]: true,
                                    [tableStyles.headerCellSunday]: false,
                                    [tableStyles.loading]: true
                                  }
                                )
                              }
                              key={`table-header-cell-${weekNumber}-loader`}
                            >
                              <div className={classnames(tableStyles.cellContentHeader, tableStyles.loading)}>
                                <CircleLoader className={tableStyles.cellCircleLoader} iconClassName={tableStyles.cellCircleLoaderIcon} />
                              </div>
                            </th>
                          );

                          headerCells.push(renderedWeeklyLoader);
                        } else {
                          const renderedDailyHeaders = rows.map((dailyData, k) => {
                            const { periodStart: dailyPeriodStart } = dailyData;
                            const dayNumber = moment(dailyPeriodStart).isoWeekday();

                            return (
                              <th
                                className={
                                  classnames(
                                    `table-header-cell-${weekNumber}-${dayNumber}`,
                                    tableStyles.headerCell,
                                    tableStyles.headerCellExpanded,
                                    {
                                      [tableStyles.last]: k + 1 === rows.length,
                                      [tableStyles.headerCellSunday]: dayNumber === 7,
                                    }
                                  )
                                }
                                key={`table-header-cell-${weekNumber}-${dayNumber}`}
                              >
                                <div className={tableStyles.cellContentHeader}>
                                  <div className={tableStyles.cellContentHeaderTitleDay}>
                                    {moment(dailyPeriodStart).format('ddd')}
                                  </div>
                                  <div className={tableStyles.cellContentHeaderSubtitle}>
                                    {moment(dailyPeriodStart).format(getDateFormat('ll'))}
                                  </div>
                                </div>
                              </th>
                            );
                          });

                          headerCells.push(...renderedDailyHeaders);
                        }
                      }

                      return headerCells;
                    }
                    return null;
                  })}
                  <th className={classnames(tableStyles.headerCell, tableStyles.lastHeaderCell)} ref={(node) => { this.lastHeaderCell = node; }} />
                </tr>

                {metrics
                  .filter(item => !isMetricHidden(item.id))
                  .map((item) => {
                    const {
                      attributes: {
                        name, description, cunit, manualInputAllowed,
                      }, id
                    } = item;
                    const cunitData = cunit ? formatMessage({ id: `cunits.mini.${cunit}` }) : null;

                    const hintProps = description ? {
                      'data-tip': description ? description[locale] || `[${description.en}]` : '',
                      'data-for': 'split-table-indicators-tooltip',
                    } : null;

                    hintProps.onClick = () => {
                      if (mode === 'left') {
                        updateSelectedMetricId(id, name);
                      }

                      if (this.openedHint) {
                        ReactTooltip.hide(findDOMNode(this.hints[id])); // eslint-disable-line react/no-find-dom-node
                      } else {
                        ReactTooltip.show(this.hints[id]);
                      }
                    };

                    return (
                      <tr className={tableStyles.row} key={`table-row-${id}`}>
                        <th
                          className={classnames(tableStyles.indicatorCell, tableStyles.hoverable, { [tableStyles.selectedMetric]: id === selectedMetricId })}
                          {...hintProps}
                          ref={(e) => {
                            this.hints[id] = e;
                          }}
                        >
                          <div className={tableStyles.chartIconWrapper}>{id === selectedMetricId ? <ChartSelectedIcon className={tableStyles.chartIcon} /> : <ChartNotSelectedIcon className={tableStyles.chartIcon} />}</div>
                          {name[locale || 'en'] || `[${name.en}]`}{cunit && ', '}{cunitData}
                        </th>
                        {!onlyFirstColumn && filteredWeeklyData.map((week) => {
                          if (week) {
                            const {
                              metricValues = {},
                              rows = [],
                              periodStart,
                              isFetching: isWeekFetching,
                            } = week;

                            const weekNumber = moment(periodStart).isoWeek();
                            const yearNumber = moment(periodStart).year();
                            const isExpanded = expanded.findIndex(expandedItem => expandedItem === periodStart) >= 0;
                            const weekEvents = {
                              onClick: event => this.handlerToggleTooltip({ week, metric: item, event, }),
                            };

                            // Для значений, измеряемых вручную, используем другой тип округления
                            const metricValue = manualInputAllowed ? numbersRounding(metricValues[id], 'fixed', 2) : numbersRounding(metricValues[id]);

                            const rowCells = [
                              <td
                                className={
                                  classnames(
                                    tableStyles.cell,
                                    {
                                      [tableStyles.borderLeft]: isExpanded,
                                      [tableStyles.manualInputAllowed]: manualInputAllowed,
                                    }
                                  )
                                }
                                key={`table-cell-${yearNumber}-${weekNumber}-${id}`}
                                {...weekEvents}
                              >
                                <div className={classnames(tableStyles.cellContent, { [tableStyles.loading]: isFetching })}>
                                  {isFetching ?
                                    <div className={tableStyles.loader} />
                                    : (
                                      <div className={classnames(tableStyles.cellContentValue)}>
                                        {metricValue || '–'}
                                      </div>
                                    )}
                                </div>
                              </td>
                            ];

                            if (isExpanded) {
                              if (isWeekFetching) {
                                const renderedWeeklyLoader = (
                                  <td
                                    className={
                                      classnames(
                                        tableStyles.cell,
                                        tableStyles.cellExpanded,
                                        {
                                          [tableStyles.last]: true,
                                        }
                                      )
                                    }
                                    key={`table-cell-${yearNumber}-${weekNumber}-loader`}
                                  >
                                    <div className={classnames(tableStyles.cellContent, tableStyles.loading)}>
                                      <div className={tableStyles.loader} />
                                    </div>
                                  </td>
                                );

                                rowCells.push(renderedWeeklyLoader);
                              } else {
                                const renderedDailyCells = rows.map((dailyData, k) => {
                                  const { periodStart: dailyPeriodStart } = dailyData;
                                  const dayNumber = moment(dailyPeriodStart).isoWeekday();
                                  const dayEvents = {
                                    onClick: event => this.handlerToggleTooltip({
                                      week,
                                      metric: item,
                                      event,
                                      day: dayNumber,
                                      dailyData,
                                    }),
                                  };

                                  // Для значений, измеряемых вручную, используем другой тип округления
                                  const dailyDataMetricValue = manualInputAllowed ?
                                    numbersRounding(dailyData.metricValues[id], 'fixed', 2) : numbersRounding(dailyData.metricValues[id]);

                                  return (
                                    <td
                                      className={
                                        classnames(
                                          tableStyles.cell,
                                          tableStyles.cellExpanded,
                                          {
                                            [tableStyles.last]: k + 1 === rows.length,
                                            [tableStyles.manualInputAllowed]: manualInputAllowed,
                                          }
                                        )
                                      }
                                      key={`table-cell-${yearNumber}-${weekNumber}-${dayNumber}-${id}`}
                                      {...dayEvents}
                                    >
                                      <div className={classnames(tableStyles.cellContent)}>
                                        <div className={classnames(tableStyles.cellContentValue)}>
                                          {dailyDataMetricValue || '–'}
                                        </div>
                                      </div>
                                    </td>
                                  );
                                });

                                rowCells.push(...renderedDailyCells);
                              }
                            }

                            return rowCells;
                          }

                          return null;
                        })}
                        <td className={classnames(tableStyles.cell, tableStyles.lastCell)} />
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          {renderedCellEditPopup}

          <ReactTooltip
            id='split-table-indicators-tooltip'
            isCapture
            scrollHide
            offset={{ top: 10 }}
            effect='solid'
            place='bottom'
            class={styles.tooltipIndicator}
            event={isTouchDevice ? 'click' : null}
            afterShow={() => {
              this.openedHint = true;
            }}
            afterHide={() => {
              this.openedHint = false;
            }}
          />
        </div>
      );
    }
    return null;
  }
}

export default injectIntl(Table);
