import moment from 'moment-timezone';
import { updateIntl } from 'react-intl-redux';
import messages from 'helpers/intl';
import storageWrapper from 'helpers/storageWrapper';
import getLocaleName from 'helpers/getLocaleName';
import { requestUpdateUserLocale } from 'store/user/actions';
import { trackUserProperties } from 'store/analytics/actions';

const safeLocalStorage = storageWrapper.get('localStorage');
// ------------------------------------
// Specialized Action Creator
// ------------------------------------

const setIntl = (locale = 'en') => async (dispatch) => {
  safeLocalStorage.setItem('locale', locale);
  moment.locale(locale === 'en' ? 'en-gb' : locale);
  await dispatch(updateIntl({ locale, messages: messages[locale] }));
  await dispatch(trackUserProperties({
    data: {
      'App Language': getLocaleName(locale),
    },
  }));
  await dispatch(requestUpdateUserLocale({
    languageKey: locale,
    actionSuccess: () => {
      document.location.reload(true);
    }
  }));
};

export default setIntl;
