function getWindowObject() {
  return (typeof window !== 'undefined' ? window : null);
}

const windowObject = getWindowObject();

/**
 * Определяем суррогатный объект с интерфейсом webStorage согласно спецификации:
 * http://dev.w3.org/html5/webstorage/#storage-0
 * @returns {Object} нативный или эмулятор локалстораджа
 */
function getStorageSurrogate() {
  const storage = {};

  Object.defineProperty(storage, 'key', {
    value(index) {
      return Object.keys(this)[index];
    }
  });

  Object.defineProperty(storage, 'getItem', {
    value(key) {
      if ({}.hasOwnProperty.call(this, key)) {
        return this[key];
      }

      return null;
    }
  });

  Object.defineProperty(storage, 'setItem', {
    value(key, value) {
      this[key] = value;
    }
  });

  Object.defineProperty(storage, 'removeItem', {
    value(key) {
      delete this[key];
    }
  });

  Object.defineProperty(storage, 'length', {
    get() {
      return Object.keys(this).length;
    }
  });

  Object.defineProperty(storage, 'clear', {
    value() {
      Object.keys(this)
        .forEach((key) => {
          if ({}.hasOwnProperty.call(this, key)) {
            delete this[key];
          }
        });
    }
  });

  return Object.create(storage);
}

/**
 * Проверяем сторадж на вменяемость
 * @param {Object} storage нативный стораж который проверяем на работоспособность
 * @returns {boolean} true если нативный стораж есть и работает, иначе false
 */
function checkStorage(storage) {
  try {
    storage.setItem('testStorageItem', 1);
    if (+storage.getItem('testStorageItem') !== 1) {
      throw new Error();
    }
    storage.removeItem('testStorageItem');
    return true;
  } catch (e) { // safari private browsing mode?
    return false;
  }
}

/**
 * Геттер компонента, возвращает рабочий сторадж
 * Инициализируется единожды
 * @param {string} type тип веб-хранилища, совпадающий с его именем в глобальной области
 * @returns {*} возвращает рабочий сторадж
 */
function getStorage(type) {
  if (windowObject && checkStorage(windowObject[type])) {
    return windowObject[type];
  }
  return getStorageSurrogate();
}

export default {
  get: getStorage
};
