import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import {
  get, groupBy, map, find, sortBy
} from 'lodash';
import classnames from 'classnames';
import moment from 'moment-timezone';

import { getProductTypeName } from '../../../../helpers/getVarietyName';
import { getPlantingCycleDates, getPlantingCycleLabel } from '../../../../helpers/getPlantingCycleLabel';

import Select from '../../../Select';
import BigButton from '../../../BigButton';

import selectStyles from '../../../Select/Select.module.css';
import styles from './PlantingCyclesSelect.module.css';

class PlantingCyclesSelect extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    onSelectOption: PropTypes.func.isRequired,

    plantingCycles: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    varieties: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    selectedOption: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
  };

  state = {
    plantingCyclesFilterOpened: false,
    showAllYears: false,
  };

  handlerCloseSelect = () => {
    const { plantingCyclesFilterOpened } = this.state;

    if (plantingCyclesFilterOpened) {
      this.setState({ plantingCyclesFilterOpened: false });
    }
  };

  handlerSelectOption = (plantingCycleId) => {
    const { onSelectOption } = this.props;

    onSelectOption(plantingCycleId);
    this.handlerCloseSelect();
  }

  handlerSelectState = newState => this.setState({ plantingCyclesFilterOpened: newState });

  handlerShowAllYears = newState => this.setState({ showAllYears: !newState });

  renderOptions = plantingCycles => plantingCycles.map((plantingCycle) => {
    const {
      intl: { formatMessage }, compartments, selectedOption
    } = this.props;

    const { id } = plantingCycle;
    const compartmentId = get(plantingCycle, 'relationships.compartment.data[0].id');
    const currentCompartment = find(compartments, { id: Number(compartmentId) });

    return (
      <div
        key={`plantingCycleOption-${id}`}
        className={classnames(
          styles.option,
          selectStyles.option,
          { [selectStyles.selected]: Number(selectedOption) === Number(id) }
        )}
        onClick={() => this.handlerSelectOption(id)}
        role='button'
        tabIndex={0}
      >
        <div className={styles.cycleDates}>{getPlantingCycleDates(plantingCycle, formatMessage)}</div>
        <div className={styles.cycleCompartment}>{get(currentCompartment, 'attributes.name')}</div>
      </div>
    );
  });

  renderGroupedPlantingCycles = groupedPlantingCycles => map(groupedPlantingCycles, (plantingCycles, varietyId) => {
    const { varieties, fruitClasses, intl } = this.props;

    const sortedPlantingCycles = sortBy(plantingCycles, 'attributes.startDate').reverse();
    const targetWeight = get(sortedPlantingCycles, '[0].attributes.targetWeight');
    const fruitClassId = get(sortedPlantingCycles, '[0].relationships.fruitClass.data[0].id');

    const currentVariety = find(varieties, { id: Number(varietyId) });
    const currentFruitClass = find(fruitClasses, { id: fruitClassId });

    const plantingCycleName = getProductTypeName({
      intl,
      variety: currentVariety,
      fruitClass: currentFruitClass,
      targetWeight,
    });

    return (
      <div key={`plantingCycleOptions-${varietyId}`}>
        <div className={styles.cycleVarietyName}>{plantingCycleName}</div>
        <div>{this.renderOptions(sortedPlantingCycles)}</div>
      </div>
    );
  });

  renderShowAllYearsButton = () => {
    const { intl: { formatMessage } } = this.props;
    const { showAllYears } = this.state;
    const currentYear = moment(new Date()).format('YYYY');
    const buttonText = showAllYears ?
      `${formatMessage({ id: 'harvestDashboard.showOnly' })} ${currentYear}` : formatMessage({ id: 'harvestDashboard.showAllYears' });


    return (
      <BigButton
        className={styles.showAllYearsButton}
        onClick={() => this.handlerShowAllYears(showAllYears)}
        theme='transparent'
        title={buttonText}
      />
    );
  };

  render() {
    const {
      intl, plantingCycles, varieties, selectedOption, fruitClasses
    } = this.props;
    const { locale, formatMessage } = intl;
    const { plantingCyclesFilterOpened } = this.state;

    // Пока кнопку не выводим
    // Берём только те циклы, которые закончились в текущем году (или ещё продолжаются)
    // const filteredPlantingCycles = plantingCycles.filter((cycle) => {
    //   const endDate = get(cycle, 'attributes.endDate');
    //   const momentDate = moment(endDate, 'YYYY-MM-DD');
    //   const startOfYear = moment().startOf('year');

    //   return !endDate || momentDate >= startOfYear;
    // });

    // const plantingCyclesForSelect = showAllYears ? plantingCycles : filteredPlantingCycles;

    // Сортируем по имени variety, чтобы сгрупированная потом коллекция была отсортирована по имени
    const sortedPlantingCycles = sortBy(plantingCycles, (cycle) => {
      const variety = find(varieties, { id: get(cycle, 'relationships.variety.data[0].id') });

      return get(variety, `attributes.mlName[${locale}]`) || get(variety, 'attributes.mlName.en');
    });
    const groupedPlantingCycles = groupBy(sortedPlantingCycles, 'relationships.variety.data[0].id');

    const currentPlantingCycle = find(plantingCycles, { id: selectedOption });
    const plantingCycleName = getPlantingCycleLabel(currentPlantingCycle, varieties, fruitClasses, intl);
    const placeholderName = plantingCycleName || formatMessage({ id: 'dashboards.allPlantingCycles' });

    return (
      <Select
        classNameButton={styles.longSelectButton}
        classNamePopup={styles.longSelectPopup}
        placeholder={placeholderName}
        theme='white'
        size='medium'
        open={plantingCyclesFilterOpened}
        onOpenChange={this.handlerSelectState}
        textEllipsis
      >
        <div className={styles.plantingCyclesFilterContainer}>
          <div className={selectStyles.options}>
            <div className={styles.optionsHeader}>
              <span>{formatMessage({ id: 'plantingCycles.plantingCycles' })}</span>
              {/* <span>{this.renderShowAllYearsButton()}</span> */}
            </div>
            <div
              className={classnames(
                selectStyles.option,
                styles.allOption,
                { [selectStyles.selected]: selectedOption === 'all' }
              )}
              onClick={() => this.handlerSelectOption('all')}
              role='button'
              tabIndex={0}
            >
              {formatMessage({ id: 'dashboards.allPlantingCycles' })}
            </div>
            {this.renderGroupedPlantingCycles(groupedPlantingCycles)}
          </div>
        </div>
      </Select>
    );
  }
}
export default injectIntl(PlantingCyclesSelect);
