import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getCurrentCompartments,
  getFirstSpecies,

  getSpeciesFromRoute,
  getCompartmentIdFromRoute,
  getVarietyIdFromRoute,
  getPlantingCycleIdFromRoute,
  getUnitsFromRoute,

  getVarietiesInLocationWithTargetWeight,
  getCurrentPlantingCycles,
  getAllFruitClasses,
} from '../../../store/company/selectors';

import DashboardFiltersMobile from '../components/DashboardFiltersMobile';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,

  varieties: getVarietiesInLocationWithTargetWeight(state, props),
  plantingCycles: getCurrentPlantingCycles(state, props),
  compartments: getCurrentCompartments(state),

  currentGrownFilter: getVarietyIdFromRoute(state, props) || getSpeciesFromRoute(state, props) || getFirstSpecies(state),
  currentVariety: getVarietyIdFromRoute(state, props),
  currentSpecies: getSpeciesFromRoute(state, props),
  currentCompartment: getCompartmentIdFromRoute(state, props),
  currentPlantingCycle: getPlantingCycleIdFromRoute(state, props),
  currentUnits: getUnitsFromRoute(state, props),
  fruitClasses: getAllFruitClasses(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardFiltersMobile)));
