import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { setHasChangesTime } from 'store/harvest/actions';
import { getChangesTime } from 'store/harvest/selectors';

import HasChanges from './HasChanges';

const mapDispatchToProps = {
  setHasChangesTime,
};

const mapStateToProps = (state, props) => ({
  ...props,

  changesTime: getChangesTime(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HasChanges));
