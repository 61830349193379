import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape, FormattedMessage } from 'react-intl';

import { get, uniqueId } from 'lodash';
import moment from 'moment-timezone';
import { withRouter } from 'react-router';

import numbersRounding from '../../../../helpers/numbersRounding';
import storageWrapper from '../../../../helpers/storageWrapper';
import CircleLoader from '../../../CircleLoader';
import BigButton from '../../../BigButton';

import HarvestWeek from '../HarvestWeek';

import circleLoaderStyles from '../../../CircleLoader/CircleLoader.module.css';
import styles from './Harvest.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');


class Harvest extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    getHarvestData: PropTypes.func.isRequired,
    getCycleData: PropTypes.func.isRequired,
    deleteHarvestData: PropTypes.func.isRequired,
    cycleId: PropTypes.string.isRequired,
    isHarvestFetching: PropTypes.bool.isRequired,
    isAddHarvestAvailable: PropTypes.bool.isRequired,
    harvestWeeks: PropTypes.array,
    harvestAverage: PropTypes.number,
    weeksWithHarvest: PropTypes.number,
    cycleInfo: PropTypes.object,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    trackAddHarvestClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    harvestWeeks: [],
    harvestAverage: null,
    cycleInfo: null,
    weeksWithHarvest: 0,
  };

  componentDidMount() {
    const { getHarvestData, cycleId } = this.props;

    getHarvestData(cycleId);

    window.scrollTo(0, 0);
  }

  handlerAddHarvest = () => {
    const {
      trackAddHarvestClick,
      history,
      match,
      cycleId,
    } = this.props;

    const fromPage = encodeURIComponent(`/${match.params.organizationSlug}/crops/${cycleId}`);
    const savedPeriodType = safeLocalStorage.getItem('periodType');

    trackAddHarvestClick('page');

    history.push(`/${match.params.organizationSlug}/crops/${cycleId}/add-harvest?date=${moment().format('YYYY-MM-DD')}&periodType=${savedPeriodType || 'week'}&fromPage=${fromPage}`);
  };

  handlerEditHarvest = (day) => {
    const {
      history,
      match,
      cycleId,
    } = this.props;
    const fromPage = encodeURIComponent(`/${match.params.organizationSlug}/crops/${cycleId}`);
    // const savedPeriodType = safeLocalStorage.getItem('periodType');

    // trackAddHarvestClick('page');

    history.push(`/${match.params.organizationSlug}/crops/${cycleId}/edit-harvest?date=${get(day, 'date')}&periodType=day&fromPage=${fromPage}`);

    // this.setState({
    //   dialogOpened: true,
    //   editMode: true,
    //   inputValue: get(day, 'value'),
    //   dateValue: moment(get(day, 'date')),
    //   harvestId: get(day, 'id'),
    // });
  };

  handlerDeleteHarvestData = async (day) => {
    const {
      cycleInfo, deleteHarvestData, getHarvestData, getCycleData
    } = this.props;

    const plantingCycleId = get(cycleInfo, 'cycle.id');
    const harvestId = get(day, 'date');

    await deleteHarvestData({
      plantingCycleId,
      harvestId,
    });

    await getCycleData(plantingCycleId);
    await getHarvestData(plantingCycleId);
  };

  renderContent = (harvestWeeks) => {
    const {
      intl, harvestAverage, cycleInfo, weeksWithHarvest, cycleId, isAddHarvestAvailable
    } = this.props;
    const { formatMessage, formatPlural } = intl;

    const average = numbersRounding(harvestAverage, 'fixed', 2);

    const pluralWeek = formatMessage({ id: `timeSelector.week.${formatPlural(weeksWithHarvest)}` });
    const species = get(cycleInfo, 'cycle.species');
    const isLettuce = species === 'lettuce';
    const units = isLettuce ? formatMessage({ id: 'cunits.mini.count' }) : formatMessage({ id: 'cunits.mini.kilogramPerSquareMeter' });

    if (harvestWeeks.length > 0) {
      return (
        <div className={styles.content}>
          <div className={styles.controls}>
            <div className={styles.info}>
              {weeksWithHarvest ?
                `${weeksWithHarvest} ${pluralWeek} ${formatMessage({ id: 'harvest.weeksAvg' }, { average, units })} `
                :
                <FormattedMessage id='harvest.noHarvest' />}
            </div>

            {isAddHarvestAvailable && (
              <BigButton
                className={styles.addButton}
                onClick={this.handlerAddHarvest}
                theme='dark'
                title={formatMessage({ id: 'harvest.addHarvest' })}
              />
            )}
          </div>
          <div className={styles.weeks}>
            {harvestWeeks
              .map(week => (
                <HarvestWeek
                  key={uniqueId('harvestWeek-')}
                  week={week}
                  handlerEditHarvest={this.handlerEditHarvest}
                  handlerDeleteHarvestData={this.handlerDeleteHarvestData}
                  cycleId={cycleId}
                  isLettuce={isLettuce}
                />
              ))}
          </div>
        </div>
      );
    }

    return null;
  };

  render() {
    const { harvestWeeks, isHarvestFetching } = this.props;

    return (
      <div className={styles.layout}>
        {isHarvestFetching ? (
          <CircleLoader
            className={circleLoaderStyles.circleLoader}
            iconClassName={circleLoaderStyles.circleLoaderIcon}
          />
        )
          :
          this.renderContent(harvestWeeks)}
      </div>
    );
  }
}

export default withRouter(Harvest);
