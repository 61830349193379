import classnames from 'classnames';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import styles from './CustomTextInput.module.css';

export default class CustomTextInput extends Component {
  static propTypes = {
    input: PropTypes.object,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    theme: PropTypes.string,
    meta: PropTypes.object,
    onInnerFocus: PropTypes.func,
    onInnerChange: PropTypes.func,
  };

  static defaultProps = {
    type: 'text',
    disabled: false,
    input: null,
    placeholder: '',
    className: null,
    onInnerFocus: null,
    onInnerChange: null,
    theme: null,
    meta: null,
  };

  render() {
    const {
      type,
      input,
      placeholder,
      className,
      disabled,
      theme,
      meta,
      onInnerFocus: handlerFocus,
      onInnerChange: handlerChange,
    } = this.props;

    const {
      onChange,
      onBlur,
      value,
    } = input;

    const {
      error,
      touched,
    } = meta;

    return (
      <input
        className={classnames(styles.input, className, { [styles.mini]: theme === 'mini', [styles.invalid]: touched && error ? error : null })}
        value={value}
        onChange={(options) => {
          onChange(options);

          if (handlerChange) {
            handlerChange(options);
          }
        }}
        onBlur={(options) => {
          onBlur(options);
        }}
        onFocus={(options) => {
          if (handlerFocus) {
            handlerFocus(options);
          }
        }}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
      />
    );
  }
}
