import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import queryString from 'query-string';

import initAxiosInstanse from '../../api/axios';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

import {
  requestCropReportsList,
  receiveCropReportsList,

  requestCropReport,
  receiveCropReport,

  requestCropReportsSettings,
  receiveCropReportsSettings,
} from './actions';

export const getCropReportsListEpic = (action$, store) =>
  action$.ofType(requestCropReportsList)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();

      const searchParams = router?.location?.search;

      const {
        payload: {
          plantingCycleId,
        }
      } = actionData;

      const queryParams = queryString.stringify({
        plantingCycleId,
      });

      const query = queryParams ? `&${queryParams}` : '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`custom-reports?${query}`))
        .map(({ data }) => receiveCropReportsList({ data, plantingCycleId }))
        .catch(() => Observable.of(
          receiveCropReportsList(),
          showNotificationWithTimeout({
            id: `notifications.getCropReportsError.${Date.now()}`,
            messageId: 'notifications.getCropReportsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getCropReportEpic = (action$, store) =>
  action$.ofType(requestCropReport)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();

      const searchParams = router?.location?.search;

      const {
        payload: {
          reportId,
          plantingCycleId,
          startDate,
          endDate,
        }
      } = actionData;

      const queryParams = queryString.stringify({
        startDate,
        endDate,
        plantingCycleId,
      });

      const query = queryParams ? `&${queryParams}` : '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/custom-reports/${reportId}?${query}`))
        .map(({ data }) => receiveCropReport(data))
        .catch(() => Observable.of(
          receiveCropReport(),
          showNotificationWithTimeout({
            id: `notifications.getCropReportsError.${Date.now()}`,
            messageId: 'notifications.getCropReportsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getCropReportsSettingsEpic = (action$, store) =>
  action$.ofType(requestCropReportsSettings)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = router?.location?.search;

      const queryParams = queryString.stringify({
        locationId: organizationId,
      });

      const query = queryParams ? `&${queryParams}` : '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/custom-reports/settings?${query}`))
        .map(({ data }) => receiveCropReportsSettings(data))
        .catch(() => Observable.of(
          receiveCropReportsSettings(),
          showNotificationWithTimeout({
            id: `notifications.getCropReportsSettingsError.${Date.now()}`,
            messageId: 'notifications.getCropReportsSettingsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export default combineEpics(
  getCropReportsListEpic,
  getCropReportEpic,
  getCropReportsSettingsEpic,
);
