import React, { useCallback } from 'react';

import parse from 'color-parse';
import PropTypes from 'prop-types';

import BaseSlider from 'components/Graphs/components/ColorPicker/components/BaseSlider';

const AlphaSlider = ({
  initValue,
  onChange
}) => {
  const color = parse(initValue);
  const rgba = (color?.values || [0, 0, 0]);
  const alpha = (color?.alpha || 0) * 255;

  const handleChange = useCallback((value) => {
    if (onChange) {
      onChange(`rgba(${rgba[0]},${rgba[1]},${rgba[2]},${value})`);
    }
  }, [onChange, rgba]);

  return (
    <BaseSlider
      initValue={alpha / 255}
      onChange={handleChange}
      containerStyle={{
        background: `linear-gradient(to right, rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, 0) 0%, rgb(${rgba[0]}, ${rgba[1]}, ${rgba[2]}) 100%)`
      }}
    />
  );
};

AlphaSlider.propTypes = {
  initValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

AlphaSlider.defaultProps = {
  initValue: 'rgba(255,255,255,1)'
};

export default AlphaSlider;
