import React, {
  useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';

import { parse } from 'date-fns';
import { isFinite } from 'lodash';
import { createStaticRanges } from 'date-range-picker';

import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import addYears from 'date-fns/addYears';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';
import isToday from 'date-fns/isToday';
import subDays from 'date-fns/subDays';


import { formatDateLocalized, formatDate, parseDateWithFormat } from 'helpers/datesHelper';
import updateLocationSearch from 'helpers/updateLocationSearch';
import { showIntercom } from 'helpers/intercomHelpers';
import { getQueryValue } from 'hooks/useQuery/useQuery';
import useIsMobile from 'helpers/useIsMobile';

import PageViewTracker from 'components/PageViewTracker';
import UiDateRangePicker from 'ui/UiDateRangePicker';
import Paper from 'components/Paper';
import DashboardComplexFilters from 'components/DashboardComplexFilters';
import IncidentInfoPanel from 'components/IncidentInfoPanel';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import BigButton from 'components/BigButton';
import PlusIcon from 'components/Icons/Plus';

import IncidentsCanvasTimeline from './IncidentsCanvasTimeline';
import { INCIDENT_TIMELINE_CONFIG } from '../CanvasTimeline/helpers/incidentScale';

import styles from './Incidents.module.css';

import { ReactComponent as ZoomOutIcon } from '../Graphs/components/LinesChart/assets/zoom_out.svg';
import { ReactComponent as ResetIcon } from '../Graphs/components/LinesChart/assets/reset.svg';
import { ReactComponent as FilterIcon } from './assets/filter.svg';

const getTypeOfCurrentPeriod = (start, end) => {
  const daysDifference = differenceInDays(
    end,
    start,
  );

  const isEndDateToday = isToday(end);

  if (isEndDateToday && daysDifference === 0) {
    return 'today';
  }

  if (isEndDateToday && daysDifference === 1) {
    return 'last2Days';
  }

  if (isEndDateToday && daysDifference === 6) {
    return 'last7Days';
  }

  if (isEndDateToday && daysDifference === 29) {
    return 'last30Days';
  }

  if (isEndDateToday && daysDifference === 89) {
    return 'last90Days';
  }

  return 'custom';
};

const getStaticRanges = formatMessage => createStaticRanges([
  {
    id: 'today',
    label: formatMessage({ id: 'dates.today' }),
    range: () => ({
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    id: 'last2Days',
    label: formatMessage({ id: 'dates.last2Days' }),
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -1)),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    id: 'last7Days',
    label: formatMessage({ id: 'dates.last7Days' }),
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -6)),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    id: 'last30Days',
    label: formatMessage({ id: 'dates.last30Days' }),
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -29)),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    id: 'last90Days',
    label: formatMessage({ id: 'dates.last90Days' }),
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -89)),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    id: 'custom',
    label: formatMessage({ id: 'dates.custom' }),
    range: () => ({
      startDate: undefined,
      endDate: undefined,
    }),
    isSelected: (range) => {
      if (!range?.startDate && !range?.endDate) {
        return true;
      }
      return getTypeOfCurrentPeriod(range.startDate, range.endDate) === 'custom';
    },
  },
]);

const getDatesDescRenderer = (formatMessage, start, end) => {
  const currentPeriod = getTypeOfCurrentPeriod(start, end);

  switch (currentPeriod) {
    case 'today': {
      return formatMessage({ id: 'dates.today' });
    }
    case 'last2Days': {
      return formatMessage({ id: 'dates.last2Days' });
    }
    case 'last7Days': {
      return formatMessage({ id: 'dates.last7Days' });
    }
    case 'last30Days': {
      return formatMessage({ id: 'dates.last30Days' });
    }
    case 'last90Days': {
      return formatMessage({ id: 'dates.last90Days' });
    }
    default: {
      const yearDifference = differenceInCalendarYears(
        end,
        start,
      );
      const daysDifference = differenceInDays(
        end,
        start,
      );

      const startDate = formatDateLocalized(start, yearDifference !== 0 ? 'lll' : 'll');
      const endDate = formatDateLocalized(end, 'lll');

      if (daysDifference === 0) {
        return endDate;
      }

      return `${startDate} – ${endDate}`;
    }
  }
};

const getPeriodType = (start, end) => {
  const daysDifference = differenceInDays(
    end,
    start,
  );
  switch (daysDifference) {
    case 0: {
      return 'today';
    }
    case 1: {
      return 'last2Days';
    }
    case 6: {
      return 'last7Days';
    }
    case 29: {
      return 'last30Days';
    }
    case 89: {
      return 'last90Days';
    }
    default: {
      return 'custom';
    }
  }
};

const setDate = (startDate, endDate) => {
  updateLocationSearch({
    startDate: formatDate(startDate, INCIDENT_TIMELINE_CONFIG.API_FORMAT),
    endDate: formatDate(endDate, INCIDENT_TIMELINE_CONFIG.API_FORMAT),
  });
};

const convertQueryDates = (startDateQuery, endDateQuery) => {
  const startDate = startDateQuery ? parse(startDateQuery, INCIDENT_TIMELINE_CONFIG.API_FORMAT, new Date()) :
      startOfDay(subDays(new Date(), 6));
  const endDate = endDateQuery ? parse(endDateQuery, INCIDENT_TIMELINE_CONFIG.API_FORMAT, new Date()) :
      endOfDay(new Date());
  return {
    startDate, endDate
  };
};

const getDatesFromQuery = () => {
  const startDateQuery = getQueryValue('startDate');
  const endDateQuery = getQueryValue('endDate');
  return convertQueryDates(startDateQuery, endDateQuery);
};

const getFiltersFromQuery = () => {
  const incidentTypeId = getQueryValue('incidentTypeId') || '';
  const incidentsGroupBy = getQueryValue('groupBy') || 'greenhouses';
  const severity = getQueryValue('severity');
  const compartmentId = getQueryValue('compartmentId');
  const activeIncidentId = getQueryValue('activeIncidentId');
  return {
    incidentTypeId,
    incidentsGroupBy,
    severity,
    compartmentId,
    activeIncidentId
  };
};

const setFilters = (value, incidentsTypes) => {
  const {
    incidentTypeId
  } = getFiltersFromQuery();

  if (value.incidentTypeId === null) {
    if (incidentTypeId) {
      updateLocationSearch({
        incidentTypeId: undefined,
      });
    } else {
      updateLocationSearch({
        incidentTypeId: '0',
      });
    }
  } else if (isFinite(+value.incidentTypeId)) {
    const selectedIncidents = incidentTypeId ? incidentTypeId.split(',') : [];
    const selectedIndex = selectedIncidents.findIndex(item => +item === +value.incidentTypeId);
    if (value.onlyCheck) {
      updateLocationSearch({
        incidentTypeId: value.incidentTypeId,
      });
    } else if (selectedIndex === -1 && selectedIncidents.length === 0) {
      updateLocationSearch({
        incidentTypeId: [...incidentsTypes
          .filter(item => +item.id !== +value.incidentTypeId)
          .map(item => +item.id)].join(','),
      });
    } else if (selectedIndex !== -1) {
      const selectedIncidentsClone = [...selectedIncidents];
      selectedIncidentsClone.splice(selectedIndex, 1);
      updateLocationSearch({
        incidentTypeId: selectedIncidentsClone.join(',')
      });
    } else {
      const incidents = [...selectedIncidents, +value.incidentTypeId];
      updateLocationSearch({
        incidentTypeId: incidents.length === incidentsTypes.length ? undefined : [...selectedIncidents, +value.incidentTypeId].join(',')
      });
    }
  }

  if (value.incidentsGroupBy) {
    updateLocationSearch({
      groupBy: value.incidentsGroupBy
    });
  }

  if (Object.prototype.hasOwnProperty.call(value, 'severity')) {
    if (value.severity) {
      if (Array.isArray(value.severity)) {
        if (value.severity.length === 3) {
          updateLocationSearch({
            severity: undefined
          });
        } else if (value.severity.length === 0) {
          updateLocationSearch({
            severity: 'none'
          });
        } else {
          updateLocationSearch({
            severity: value.severity.join(',')
          });
        }
      }
    } else {
      updateLocationSearch({
        severity: undefined
      });
    }
  }

  if (Object.prototype.hasOwnProperty.call(value, 'compartmentId')) {
    if (value.compartmentId) {
      if (Array.isArray(value.compartmentId)) {
        updateLocationSearch({
          compartmentId: value.compartmentId.join(',')
        });
      }
    } else {
      updateLocationSearch({
        compartmentId: undefined
      });
    }
  }
};

function convertGrouping(incidentsGroupBy) {
  if (incidentsGroupBy === 'greenhouses') {
    return 'g';
  }
  if (incidentsGroupBy === 'incidentTypes') {
    return 'i';
  }
  return 'g';
}

const SEVERITIES = {
  critical: 3,
  warning: 2,
  potential: 1,
  none: 0
};

function findNodeByContainsIncidentId(rootNode, id) {
  if (rootNode?.children?.length) {
    for (let i = 0; i < rootNode.children.length; i += 1) {
      const child = rootNode.children[i];
      for (let gIndex = 0; gIndex < child?.groups?.length; gIndex += 1) {
        const grp = child.groups[gIndex];
        const dataKeys = Object.keys(grp.data);
        for (let dIndex = 0; dIndex < dataKeys.length; dIndex += 1) {
          const data = grp.data[dataKeys[dIndex]];
          if (data?.ids && data.ids.includes(id)) {
            return child;
          }
        }
      }
      if (child.children) {
        const foundNode = findNodeByContainsIncidentId(child, id);
        if (foundNode) {
          return foundNode;
        }
      }
    }
  }

  return null;
}

function setIncidentTreeExpand({
  setTreeExpandNodes,
  expanded,
  subNodeChild,
  child
}) {
  if (!expanded.includes(subNodeChild.path.join('_'))) {
    setTreeExpandNodes(subNodeChild.path.join('_'));
  }
  if (!expanded.includes(child.path.join('_'))) {
    setTreeExpandNodes(child.path.join('_'));
  }
}

function showActiveIncident(data, activeIncidentId, setTreeExpandNodes, expanded) {
  if (activeIncidentId) {
    const expandNode = findNodeByContainsIncidentId(data.node, +activeIncidentId);
    if (expandNode) {
      if (!expanded.includes(expandNode.path.join('_'))) {
        setTreeExpandNodes(expandNode.path.join('_'));
      }
      if (expandNode?.children) {
        for (let i = 0; i < expandNode?.children?.length; i += 1) {
          const child = expandNode.children[i];
          if (child?.children) {
            for (let j = 0; j < child?.children?.length; j += 1) {
              const subNodeChild = child?.children[j];
              if (subNodeChild?.children) {
                for (let k = 0; k < subNodeChild?.children?.length; k += 1) {
                  const incidentChild = subNodeChild?.children[k];
                  for (let r = 0; r < incidentChild?.incidents?.length; r += 1) {
                    const incident = incidentChild?.incidents[r];
                    if (incident.id === +activeIncidentId) {
                      setIncidentTreeExpand({
                        setTreeExpandNodes,
                        expanded,
                        subNodeChild,
                        child
                      });
                      break;
                    }
                  }
                }
              } else if (subNodeChild?.incidents) {
                for (let z = 0; z < subNodeChild?.incidents?.length; z += 1) {
                  const incident = subNodeChild?.incidents[z];
                  if (incident.id === +activeIncidentId) {
                    setIncidentTreeExpand({
                      setTreeExpandNodes,
                      expanded,
                      subNodeChild,
                      child
                    });
                    break;
                  }
                }
              }
            }
          } else if (child?.entity?.type === 'IncidentType') {
            for (let l = 0; l < child?.groups?.length; l += 1) {
              const grp = child?.groups[l];
              const dataKeys = Object.keys(grp.data);
              for (let y = 0; y < dataKeys.length; y += 1) {
                const incident = grp.data[dataKeys[y]];
                if (incident?.ids?.includes(+activeIncidentId)) {
                  if (!expanded.includes(child.path.join('_'))) {
                    setTreeExpandNodes(child.path.join('_'));
                  }
                  break;
                }
              }
            }
          }
        }
      }
    }
  }
}

function buildFilters({
  incidentsTypes,
  compartments,
  formatMessage
}) {
  const {
    incidentTypeId,
    incidentsGroupBy,
    severity,
    compartmentId,
  } = getFiltersFromQuery();
  const isMobile = window.innerWidth < 720;
  return [{
    type: 'incidents',
    options: {
      incidentsTypes,
      selectedOption: incidentTypeId ? incidentTypeId.split(',').map(item => +item) :
        [null, ...(incidentsTypes ? incidentsTypes.map(item => +item.id) : [])],
      maxWidth: 100
    }
  }, {
    type: 'incidentsGroupBy',
    options: {
      selectedOption: incidentsGroupBy
    }
  }, {
    type: 'compartment',
    options: {
      title: formatMessage({ id: 'incidentsTimeline.filters.source' }),
      classNameWrapper: styles.compartmentFilterWrapper,
      disableUseQuery: true,
      compartments,
      compartmentId: compartmentId ? compartmentId.split(',') : [],
      customPlaceholder: isMobile ? formatMessage({ id: 'incidentsTimeline.filters.mobile.source' }) : '',
      popupClassName: styles.popupClassName
    }
  }, {
    type: 'severity',
    options: {
      selectedOption: severity ? severity.split(',') :
        ['critical', 'warning', 'potential']
    }
  }];
}

const Incidents = ({
  intl: { formatMessage },
  requestClimateIncidentsTree,
  requestClimateIncidentsTypes,
  incidentsTypes,
  incidentsTree,
  compartments,
  locations,
  expanded,
  setTreeExpandNodes,
  location,
  subNodes,
  incidentsAccess,
  requestIncidentById,
  requestIncidentPlot,
  trackPageViewIncidents,
  loadedNodes,
  isIncidentsTypesFetching,
}) => {
  const {
    startDate: currentStartDate,
    endDate: currentEndDate
  } = getDatesFromQuery();
  const [activeIncidentCollapsed, setActiveIncidentCollapsed] = useState(true);
  const [zoomState, setZoomState] = useState([]);
  const { organizationSlug/* , startDate: startDateQuery, endDate: endDateQuery */ } = useParams();
/*
  const {
    startDate: currentStartDate,
    endDate: currentEndDate
  } = useMemo(() =>
      convertQueryDates(startDateQuery, endDateQuery), [startDateQuery, endDateQuery]);
*/
  const history = useHistory();
  const [filtersInfo, setFiltersInfo] = useState({});

  const [filtersConfig, setFiltersConfig] = useState(() => buildFilters({
    incidentsTypes,
    compartments,
    formatMessage
  }));

  const {
    activeIncidentId: currentActiveIncident
  } = getFiltersFromQuery();

  useEffect(() => {
    if (!incidentsAccess) {
      history.push(`/${organizationSlug}/graphs`);
    }
    const {
      activeIncidentId
    } = getFiltersFromQuery();
    if (activeIncidentId && activeIncidentCollapsed) {
      requestIncidentById({ incidentId: +activeIncidentId });
      requestIncidentPlot({ incidentId: +activeIncidentId });
      setActiveIncidentCollapsed(false);
    }
  }, [
    setActiveIncidentCollapsed,
    requestIncidentById,
    requestIncidentPlot,
    organizationSlug,
    history,
    incidentsAccess,
    activeIncidentCollapsed
  ]);

  const handlerPageView = useCallback(() => {
    trackPageViewIncidents();
  }, [trackPageViewIncidents]);

  const handleIncidentCollapsed = useCallback(() => {
    setActiveIncidentCollapsed(true);
    updateLocationSearch({
      activeIncidentId: undefined
    });
  }, [setActiveIncidentCollapsed]);

  const handleShowIncidentPanel = useCallback((incidentId) => {
    requestIncidentById({ incidentId });
    requestIncidentPlot({ incidentId });
    setActiveIncidentCollapsed(false);
  }, [requestIncidentById, setActiveIncidentCollapsed, requestIncidentPlot]);

  const getKeepNodesFromTree = useCallback(tree => tree.reduce((acc, item) => {
    if (item.children) {
      return [...acc, item.path, ...getKeepNodesFromTree(item.children)];
    }

    return [...acc, item.path];
  }, []), []);


  useEffect(() => {
    requestClimateIncidentsTypes();
  }, [requestClimateIncidentsTypes]);

  // Dashboard first load
  useEffect(() => {
    if (incidentsTypes) {
      const {
        incidentTypeId,
        incidentsGroupBy,
        severity,
        compartmentId,
        activeIncidentId
      } = getFiltersFromQuery();
      const {
        startDate,
        endDate
      } = getDatesFromQuery();
      setFiltersConfig(buildFilters({
        incidentsTypes,
        formatMessage,
        compartments,
      }));
      const grouping = convertGrouping(incidentsGroupBy);
      requestClimateIncidentsTree({
        ids: `${grouping}/${location.id}`,
        start: formatDate(startDate, INCIDENT_TIMELINE_CONFIG.API_FORMAT),
        endInclusive: formatDate(endDate, INCIDENT_TIMELINE_CONFIG.API_FORMAT),
        compartmentId: compartmentId?.length ? compartmentId.split(',') : undefined,
        incidentTypeId: incidentTypeId?.length ? incidentTypeId.split(',') : undefined,
        incidentSeverity: severity?.length ? severity.split(',').map(item => SEVERITIES[item]) : undefined,
        resetExpand: true,
        expandNodeId: activeIncidentId ? +activeIncidentId : undefined,
        actionSuccess: (sourceIds, data) => {
          showActiveIncident(data, activeIncidentId, setTreeExpandNodes, expanded);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestClimateIncidentsTree,
    location,
    incidentsTypes,
    setTreeExpandNodes,
    setFiltersConfig,
    formatMessage,
    compartments
  ]);

  const handlerChangeDates = useCallback(({ startDate: newStartDate, endDate: newEndDate }) => {
    setDate(
        parseDateWithFormat(newStartDate, INCIDENT_TIMELINE_CONFIG.API_FORMAT),
        parseDateWithFormat(newEndDate, INCIDENT_TIMELINE_CONFIG.API_FORMAT)
    );
    const {
      incidentTypeId,
      incidentsGroupBy,
      severity,
      compartmentId,
    } = getFiltersFromQuery();
    const grouping = convertGrouping(incidentsGroupBy);
    requestClimateIncidentsTree({
      ids: `${grouping}/${location.id}`,
      start: newStartDate,
      endInclusive: newEndDate,
      compartmentId: compartmentId?.length ? compartmentId.split(',') : undefined,
      incidentTypeId: incidentTypeId?.length ? incidentTypeId.split(',') : undefined,
      incidentSeverity: severity?.length ? severity.split(',').map(item => SEVERITIES[item]) : undefined,
      resetExpand: true
    });
    return { newStartDate, newEndDate };
  }, [
    requestClimateIncidentsTree,
    location,
  ]);

  const handleFilterChanged = useCallback((value) => {
    setFilters(value, incidentsTypes);
    setFiltersConfig(buildFilters({
      incidentsTypes,
      formatMessage,
      compartments,
    }));
    const {
      incidentTypeId,
      incidentsGroupBy,
      severity,
      compartmentId,
    } = getFiltersFromQuery();
    const {
      startDate,
      endDate
    } = getDatesFromQuery();
    const grouping = convertGrouping(incidentsGroupBy);
    requestClimateIncidentsTree({
      ids: `${grouping}/${location.id}`,
      start: formatDate(startDate, INCIDENT_TIMELINE_CONFIG.API_FORMAT),
      endInclusive: formatDate(endDate, INCIDENT_TIMELINE_CONFIG.API_FORMAT),
      compartmentId: compartmentId?.length ? compartmentId.split(',') : undefined,
      incidentTypeId: incidentTypeId?.length ? incidentTypeId.split(',') : undefined,
      incidentSeverity: severity?.length ? severity.split(',').map(item => SEVERITIES[item]) : undefined,
      resetExpand: true,
    });
    setActiveIncidentCollapsed(true);
    updateLocationSearch({
      activeIncidentId: undefined
    });
  }, [
    incidentsTypes,
    requestClimateIncidentsTree,
    setActiveIncidentCollapsed,
    formatMessage,
    compartments,
    location
  ]);

  const handleExpandNodes = useCallback((nodeKey, node) => {
    if (node?.node?.incident) {
      handleShowIncidentPanel(node.node.incident.id);
      updateLocationSearch({
        activeIncidentId: node.node.incident.id
      });
    } else if (!node?.node?.sourceNode?.incidents) {
      const dates = getDatesFromQuery();
      setTreeExpandNodes(nodeKey);
      const ids = nodeKey.split('_').join('/');
      const {
        incidentTypeId,
        severity,
        compartmentId,
      } = getFiltersFromQuery();
      requestClimateIncidentsTree({
        ids,
        start: formatDate(dates.startDate, INCIDENT_TIMELINE_CONFIG.API_FORMAT),
        endInclusive: formatDate(dates.endDate, INCIDENT_TIMELINE_CONFIG.API_FORMAT),
        patchTree: true,
        loadedNode: nodeKey,
        compartmentId: compartmentId?.length ? compartmentId.split(',') : undefined,
        incidentTypeId: incidentTypeId?.length ? incidentTypeId.split(',') : undefined,
        incidentSeverity: severity?.length ? severity.split(',').map(item => SEVERITIES[item]) : undefined,
        // expandNodeId: node.nodeId,
        actionSuccess: (sourceIds, data) => {
            if (node.expandCallback) {
              node.expandCallback(nodeKey, sourceIds, data);
            }
        }
      });
    }
  }, [
    setTreeExpandNodes,
    requestClimateIncidentsTree,
    handleShowIncidentPanel,
  ]);

  const handlerZoom = useCallback(({
    start: newStartDate, end: newEndDate
  }) => {
    const {
      startDate,
      endDate
    } = getDatesFromQuery();

    newStartDate.setMinutes(0);
    newEndDate.setHours(newEndDate.getHours() + 1);
    newEndDate.setMinutes(0);

    setDate(newStartDate, newEndDate);
    setZoomState((prevState) => {
      if (prevState.length === 0) {
        return [{
          start: startDate,
          end: endDate
        }];
      }
      return [...prevState, { start: newStartDate, end: newEndDate }];
    });
  }, [
    setZoomState
  ]);

  const handlerZoomOut = useCallback(() => {
    if (zoomState.length > 0) {
      const copyState = [...zoomState];
      copyState.pop();
      window.history.back();
      // history.back();
      // setDate(start, end);
      setZoomState([...copyState]);
    }
  }, [setZoomState, zoomState]);

  const handlerReset = useCallback(() => {
    if (zoomState.length > 0) {
      const {
        start,
        end
      } = zoomState[0];
      setDate(start, end);
      setZoomState([]);
    } else {
      setZoomState([]);
    }
  }, [setZoomState, zoomState]);

  const handleFilterPlaceholder = useCallback((key, placeholder) => {
    setFiltersInfo(prevState => ({
      ...prevState,
      [key]: placeholder
    }));
  }, [setFiltersInfo]);

  const isMobile = useIsMobile(720);

  return (
    <div className={styles.container}>
      <PageViewTracker onMount={handlerPageView} />

      <Paper className={styles.paper}>
        <div className={styles.controlsWrapper}>
          <div className={styles.inputs}>
            <div className={styles.filter}>
              <DashboardComplexFilters
                filters={filtersConfig}
                onFiltersChange={handleFilterChanged}
                mobileFilterIcon={<FilterIcon />}
                mobileContainerClassName={styles.mobileFilter}
                onSetPlaceholder={handleFilterPlaceholder}
              />
            </div>
            <div className={styles.datePicker}>
              <UiDateRangePicker
                startDate={currentStartDate}
                endDate={currentEndDate}
                minDate={addYears(new Date(), -5)}
                maxDate={addYears(new Date(), 5)}
                onApply={handlerChangeDates}
                staticRanges={getStaticRanges(formatMessage)}
                datesRenderer={(start, end) => getDatesDescRenderer(formatMessage, start, end)}
                dateButtonClassName={styles.dateButtonClassName}
                dropdownContentClassName={styles.dropdownContentClassName}
                alignRight
                applyFormat={INCIDENT_TIMELINE_CONFIG.API_FORMAT}
              />
            </div>
          </div>
          {isMobile && (
            <div className={styles.filterPlaceholders}>
              {['incidents', 'incidentsGroupBy', 'compartment', 'severity'].map((key) => {
                const MAP_KEYS = {
                  incidents: 'incidentsTypes',
                  incidentsGroupBy: 'groupBy',
                  compartment: 'source',
                  severity: 'severity'
                };
                return filtersInfo[key] ? (
                  <span className={styles.filterInfo}>
                    {formatMessage({ id: `incidentsTimeline.filters.${MAP_KEYS[key]}` })}&nbsp;
                    {filtersInfo[key]}
                  </span>
                ) : null;
              })}
            </div>
          )}
        </div>
        <div className={styles.timelineWrapper}>
          <>
            {isIncidentsTypesFetching && <DefaultCircleLoader />}
          </>
          {!!incidentsTypes && (
          <IncidentsCanvasTimeline
            incidentsTree={incidentsTree}
            incidentsTypes={incidentsTypes}
            compartments={compartments}
            locations={locations}
            subNodes={subNodes}
            expanded={expanded}
            setTreeExpandNodes={handleExpandNodes}
            periodType={getPeriodType(currentStartDate, currentEndDate)}
            start={currentStartDate}
            endInclusive={currentEndDate}
            parentClassName={styles.timelineWrapper}
            onZoom={handlerZoom}
            loadedNodes={loadedNodes}
            activeIncidentId={+currentActiveIncident}
          />
          )}
          {zoomState?.length > 0 && (
            <div className={styles.historyButtons}>
              <button
                type='button'
                className={styles.historyButton}
                onClick={handlerZoomOut}
              >
                <ZoomOutIcon />
              </button>
              <div className={styles.historyButtonsDelimeter} />
              <button
                type='button'
                className={styles.historyButton}
                onClick={handlerReset}
              >
                <ResetIcon />
              </button>
            </div>
          )}
        </div>
        <IncidentInfoPanel
          panelCollapsed={activeIncidentCollapsed}
          onCollapse={handleIncidentCollapsed}
        />
        <BigButton
          className={styles.addIncidentsButton}
          icon={<PlusIcon />}
          title={formatMessage({ id: 'incidentsSettings.addIncidents' })}
          onClick={showIntercom}
          size='large'
        />
      </Paper>
    </div>
  );
};

Incidents.propTypes = {
  intl: intlShape.isRequired,
  requestClimateIncidentsTree: PropTypes.func,
  requestClimateIncidentsTypes: PropTypes.func,
  incidentsTypes: PropTypes.array,
  incidentsTree: PropTypes.object,
  compartments: PropTypes.array,
  locations: PropTypes.array,
  expanded: PropTypes.array,
  setTreeExpandNodes: PropTypes.func,
  location: PropTypes.string,
  subNodes: PropTypes.array,
  incidentsAccess: PropTypes.bool,
  loadedNodes: PropTypes.array,
  isIncidentsTypesFetching: PropTypes.bool,
  requestIncidentById: PropTypes.func.isRequired,
  requestIncidentPlot: PropTypes.func.isRequired,
  trackPageViewIncidents: PropTypes.func.isRequired,
};

Incidents.defaultProps = {
  requestClimateIncidentsTree: null,
  requestClimateIncidentsTypes: null,
  incidentsTypes: null,
  incidentsTree: null,
  compartments: null,
  locations: null,
  expanded: [],
  setTreeExpandNodes: null,
  location: null,
  subNodes: null,
  incidentsAccess: false,
  loadedNodes: [],
  isIncidentsTypesFetching: false,
};

export default Incidents;
