import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useDrag, useDrop } from 'react-dnd';

import Checkbox from '../../../Checkbox';

import styles from './DraggableOption.module.css';

import { ReactComponent as DashIcon } from './assets/dash.svg';


const getUpdatedArray = (array, item, newItem) => array.map(arrItem => (arrItem.value === item.value ? { ...item, ...newItem } : arrItem));

const DraggableOption = ({
  onChange,
  onMove,
  option,
  title,
  options,
  index,
}) => {
  const id = get(option, 'value');

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'DRAGGABLE_PANEL',
    hover(item, monitor) {
      const temp = item;

      if (!ref.current) {
        return;
      }
      const dragIndex = temp.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      onMove(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      temp.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'DRAGGABLE_PANEL', id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <div ref={ref} key={`option-${option.value}`} className={styles.checkboxItem} style={{ opacity }}>
      <Checkbox
        onChange={() => onChange(getUpdatedArray(options, option, { checked: !option.checked }))}
        checked={option.checked}
        value={option.value}
        title={title}
        alignCenter
        noWrap
      />
      <DashIcon className={styles.dash} />
    </div>
  );
};

DraggableOption.propTypes = {
  options: PropTypes.array.isRequired,
  option: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
};

export default DraggableOption;
