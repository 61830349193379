import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getCurrentLocation,
  getHarvestForecastAccess,
  getIsAddHarvestForecastAvailable,
} from 'store/company/selectors';

import {
  requestGetForecastVersions,
  requestGetForecastLastVersion,
  requestSaveForecastVersion,
  requestGetForecastReportData,
  requestGetForecastVersion,
  setShowVersionPanel,
  requestDeleteForecast,
  requestRestoreForecastVersion,
  requestPublishForecast,
  setForecastVersionsFilter,
  clearForecastState,
  requestForecastNotificationSubscribers,
  requestSaveAndPublishForecast,
  clearForecastReportData,
  requestHarvestForecastExport,
  requestEditForecastVersionInfo,
  requestDeleteForecastVersionInfo,
} from 'store/harvestForecast/actions';

import {
  getForecastVersions,
  getForecastData,
  getForecastReportData,
  getIsForecastReportDataFetching,
  getIsShowVersionPanel,
  getIsForecastFetching,
  getIsForecastSaving,
  getFilteredPlanVersions,
  getVersionsFilter,
  getForecastVersionId,
  getIsForecastDataFetching,
  getIsVersionInfoFetching,
  getIsDeleteVersionInfoFetching,
} from 'store/harvestForecast/selectors';

import HarvestForecastDashboard from '../components/HarvestForecastDashboard';

const mapDispatchToProps = {
  requestGetForecastVersions,
  requestGetForecastLastVersion,
  requestSaveForecastVersion,
  requestGetForecastReportData,
  requestGetForecastVersion,
  setShowVersionPanel,
  requestDeleteForecast,
  requestRestoreForecastVersion,
  requestPublishForecast,
  setForecastVersionsFilter,
  clearForecastState,
  requestForecastNotificationSubscribers,
  requestSaveAndPublishForecast,
  clearForecastReportData,
  requestHarvestForecastExport,
  requestEditForecastVersionInfo,
  requestDeleteForecastVersionInfo,
};

const mapStateToProps = (state, props) => ({
  ...props,
  location: getCurrentLocation(state),
  harvestForecastAccess: getHarvestForecastAccess(state),
  isAddHarvestForecastAvailable: getIsAddHarvestForecastAvailable(state, props),
  forecastVersions: getForecastVersions(state),
  filteredVersions: getFilteredPlanVersions(state),
  versionsFilter: getVersionsFilter(state),
  forecastData: getForecastData(state),
  forecastReportData: getForecastReportData(state),
  isForecastReportDataFetching: getIsForecastReportDataFetching(state),
  isShowVersionPanel: getIsShowVersionPanel(state),
  isLoading: getIsForecastFetching(state) || getIsForecastSaving(state) || getIsForecastDataFetching(state),
  versionId: getForecastVersionId(state),
  isDeleteVersionInfoFetching: getIsDeleteVersionInfoFetching(state, props),
  isVersionInfoFetching: getIsVersionInfoFetching(state, props),
  // reportFilters: getReportFilters(state),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(HarvestForecastDashboard)));
