import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CellShape from './CellShape';

export default class Cell extends PureComponent {
  static propTypes = {
    children: PropTypes.object.isRequired,
    row: PropTypes.number.isRequired,
    col: PropTypes.number.isRequired,
    cell: PropTypes.shape(CellShape).isRequired,
    selected: PropTypes.bool,
    editing: PropTypes.bool,
    updated: PropTypes.bool,
    attributesRenderer: PropTypes.func,
    onMouseDown: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    onContextMenu: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.object
  };

  static defaultProps = {
    selected: false,
    editing: false,
    updated: false,
    attributesRenderer: () => {},
    className: null,
    style: null,
  };

  render() {
    const {
      cell, row, col, attributesRenderer,
      className, style, onMouseDown, onMouseOver, onDoubleClick, onContextMenu
    } = this.props;

    const { colSpan, rowSpan } = cell;
    const attributes = attributesRenderer ? attributesRenderer(cell, row, col) : {};

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/mouse-events-have-key-events */
    return (
      <td
        className={className}
        onMouseDown={onMouseDown}
        onMouseOver={onMouseOver}
        onDoubleClick={onDoubleClick}
        onContextMenu={onContextMenu}
        colSpan={colSpan}
        rowSpan={rowSpan}
        style={style}
        {...attributes}
      >
        {this.props.children}
      </td>
    );
    /* eslint-enable jsx-a11y/mouse-events-have-key-events, jsx-a11y/mouse-events-have-key-events */
  }
}
