import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styles from './Policies.module.css';


export default class Policies extends Component {
  render() {
    return (
      <article className={styles.main}>
        <h1>Privacy- en Cookiebeleid</h1>

        <p>Via de website <RouterLink to='https://pylot.app'>https://pylot.app</RouterLink> (hierna: 'de website')
          worden persoonsgegevens verzameld en cookies gebruikt. Pylot B.V. (hierna: 'Pylot') respecteert de privacy
          van bezoekers van de website. Dit betekent dat wij de door ons verzamelde persoonsgegevens in overeenstemming
          met de eisen voor de verwerking van persoonsgegevens van de Europese privacywetgeving zorgvuldig verwerken en
          beveiligen. In dit Privacy- en Cookiebeleid informeren wij u over de verwerking van persoonsgegevens en uw
          privacy.
        </p>

        <br />

        <p>Bij het verwerken van persoonsgegevens houden wij ons aan de voorschriften van de privacywetgeving. Dit
          betekent onder meer dat wij:
        </p>

        <ul type='circle'>
          <li><strong>duidelijk omschrijven wat onze doelstellingen</strong> zijn voordat we persoonsgegevens verwerken,
            door middel van dit Privacy- en Cookiebeleid;
          </li>
          <li><strong>proberen alleen de persoonsgegevens te verzamelen</strong> die voor gerechtvaardigde doeleinden
            zijn vereist;
          </li>
          <li>eerst <strong>om uw uitdrukkelijke toestemming vragen</strong> voor de verwerking van uw persoonsgegevens
            wanneer uw toestemming is vereist;
          </li>
          <li>de <strong>juiste beveiligingsmaatregelen</strong> nemen ter bescherming van uw persoonsgegevens en dat
            wij eisen dat partijen die namens ons persoonsgegevens verwerken, hetzelfde doen;
          </li>
          <li>uw rechten <strong>respecteren</strong> met betrekking tot uw persoonsgegevens die wij bewaren.</li>
        </ul>

        <br />

        <p>Wij zijn de verwerkingsverantwoordelijke bij de gegevensverwerking. In dit Privacy- en Cookiebeleid lichten
          wij toe welke persoonsgegevens wij verzamelen en gebruiken en voor welke doeleinden. Wij raden u aan dit
          zorgvuldig door te lezen.
        </p>

        <br />

        <p>Dit Privacy- en Cookiebeleid is recentelijk op 11/12/2018 herzien.</p>

        <h2>Verwerking van persoonsgegevens</h2>
        <p>Door onze website te gebruiken, laat u bepaalde gegevens bij ons achter, waaronder eventueel uw
          persoonsgegevens. Alleen persoonsgegevens die u direct aan ons verstrekt of die duidelijk aan ons voor
          verwerkingsdoeleinden zijn verstrekt, worden door ons opgeslagen en gebruikt. Wij gebruiken de
          persoonsgegevens niet voor andere doeleinden, tenzij u vooraf toestemming daarvoor heeft gegeven of dat is
          toegestaan op grond van de wet.
        </p>

        <br />

        <p>Als u de website gebruikt, verwerken wij de volgende persoonsgegevens:</p>
        <ul type='circle'>
          <li>e-mailadres;</li>
          <li>gebruikersnaam;</li>
          <li>IP-adres;</li>
          <li>besturingssysteem;</li>
          <li>soort apparaat;</li>
          <li>uw regio;</li>
          <li>gebruikte taal;</li>
          <li>bezochte pagina's.</li>
        </ul>

        <br />

        <p>Wij verwerken deze persoonsgegevens voor de uitvoering van de overeenkomst, namelijk voor het instellen en
          onderhoud van uw account op de website, om toegang tot de website en uw account te verlenen, om contact met u
          op te nemen over de diensten die u afneemt, om de door u gevraagde diensten te verlenen en om u over de
          diensten te informeren.
        </p>

        <br />

        <p>Daarnaast gebruiken wij uw e-mailadres om u door middel van een e-mailnieuwsbrief te informeren over
          ontwikkelingen binnen de website indien u toestemming heeft gegeven voor de ontvangst van deze nieuwsbrieven.
          Elke nieuwsbrief bevat een knop om u uit te schrijven. U kunt zich ook voor deze nieuwsbrieven afmelden door
          contact op te nemen met onze helpdesk en hen te laten weten dat u zich voor de nieuwsbrief wilt afmelden. Als
          u zich voor de nieuwsbrief afmeldt, verwijderen wij uw e-mailadres van de verzendlijst.
        </p>

        <br />

        <p>Daarnaast traceren en analyseren we bepaalde informatie op basis van uw activiteiten op onze website. Wij
          gebruiken deze gegevens alleen om meer inzicht te krijgen in uw locatie, de gebruikte taal, de
          gebruiksfrequentie, de bezochte pagina's en uw IP-adres. Met deze gegevens kunnen wij het product
          verbeteren en inspelen op mogelijke problemen en deze oplossen en uw surfgedrag analyseren.
        </p>

        <br />

        <p>Hiervoor kunnen wij uw locatiegegevens gebruiken. Wij vragen u apart om toestemming voor uw locatiegegevens
          en wij verwerken deze gegevens alleen als u daadwerkelijk toestemming heeft gegeven.
        </p>

        <h2>Cookies </h2>
        <p>
          Cookies zijn kleine gegevensbestanden waarin wij informatie kunnen opslaan, zodat u die informatie niet
          opnieuw hoeft in te vullen. Wij kunnen cookies ook gebruiken om te kijken of u ons opnieuw bezoekt. Dit wordt
          gedaan via de webbrowser op uw apparaat (waaronder een PC, tablet of smartphone). De gegevens die met een
          cookie kunnen worden overgebracht, door het gebruik van onze website, kunnen naar de beveiligde servers van
          Pylot of naar de servers van een derde worden overgedragen.
        </p>

        <br />

        <p>Onze website maakt gebruik van cookies. Cookies worden door ons geplaatst, maar ook via derden die wij voor
          onze verwerkingsdoeleinden inschakelen. Wij gebruiken cookies op onze website voor de volgende doeleinden:
        </p>

        <ul type='circle'>
          <li>voor het opstellen van algemene statistieken en om inzicht te krijgen in het gebruik van onze website door
            het publiek zodat wij onze website en diensten kunnen optimaliseren (analytische cookies);
          </li>
          <li>om het internet interessanter voor u te maken door advertenties te tonen die op uw interesses zijn
            afgestemd en om de advertenties en inhoud op onze website te personaliseren op basis van uw interesses en
            klik- en surfgedrag (traceringscookies/doelgroepgerichte cookies/marketingcookies).
          </li>
        </ul>

        <br />

        <p>Voor bovengenoemde doeleinden gebruiken wij de volgende cookies:</p>

        <br />

        <div className={styles.scrollable}>
          <table>
            <thead>
              <tr>
                <th>Cookie-partij</th>
                <th>Categorie</th>
                <th>Vestigingsplaats</th>
                <th>Doeleinde</th>
                <th>Soort</th>
                <th>Looptijd</th>
                <th>Privacyverklaring</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Google Analytics _ga_cid</td>
                <td>Analytische cookie</td>
                <td>Verenigde Staten van Amerika</td>
                <td>Google Analytics: registreert een unieke ID om statistieken over het gebruik van de website op te
                  stellen.
                </td>
                <td>Https-cookie</td>
                <td>Sessiecookie</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Privacyverklaring</a></td>
              </tr>
              <tr>
                <td>Google Analytics _ga</td>
                <td>Analytische cookie</td>
                <td>Verenigde Staten van Amerika</td>
                <td>Google Analytics: houdt de (terugkerende) bezoekers bij.</td>
                <td>Https-cookie</td>
                <td>2 jaar</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Privacyverklaring</a></td>
              </tr>
              <tr>
                <td>Google Analytics _gid</td>
                <td>Analytische cookie</td>
                <td>Verenigde Staten van Amerika</td>
                <td>Google Analytics: maakt onderscheid tussen de verschillende soorten gebruikers.</td>
                <td>Https-cookie</td>
                <td>24 uur</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Privacyverklaring</a></td>
              </tr>
              <tr>
                <td>Google Analytics _gat</td>
                <td>Analytische cookie</td>
                <td>Verenigde Staten van Amerika</td>
                <td>Google Analytics: wordt gebruikt om de verzoeksnelheid te vertragen.</td>
                <td>Https-cookie</td>
                <td>Sessiecookie</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Privacyverklaring</a></td>
              </tr>
              <tr>
                <td>Google Doubleclick IDE</td>
                <td>Marketingcookie</td>
                <td>Verenigde Staten van Amerika</td>
                <td>Google Doubleclick: wordt gebruikt om advertenties te optimaliseren.</td>
                <td>Https-cookie</td>
                <td>1 year</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Privacyverklaring</a></td>
              </tr>
              <tr>
                <td>Google Doubleclick DSID</td>
                <td>Marketingcookie</td>
                <td>Verenigde Staten van Amerika</td>
                <td>Google Doubleclick: wordt gebruikt om uw bezoek te beoordelen zodat relevante advertenties kunnen
                  worden getoond.
                </td>
                <td>Https-cookie</td>
                <td>1 week</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Privacyverklaring</a></td>
              </tr>
              <tr>
                <td>Amplitude amplitude_id*</td>
                <td>Trackingcookie</td>
                <td>Verenigde Staten van Amerika</td>
                <td>Amplitude: houdt unieke bezoekers van een website bij voor optimalisering.</td>
                <td>Https-cookie</td>
                <td>10 jaar</td>
                <td><a href='https://amplitude.com/privacy'>Privacyverklaring</a></td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />

        <p>De eerste keer dat u onze dienst bezoekt, krijgt u van ons een melding waarin onze cookies worden toegelicht
          en aan u toestemming wordt gevraagd voor het gebruik van deze cookies. U kunt via uw browserinstellingen het
          gebruik van cookies uitschakelen, maar daardoor werken sommige delen van onze website mogelijk niet goed.
        </p>

        <h2>Intercom </h2>
        <p>Zoals hierboven is vermeld, gebruikt onze website analytische cookies, marketingcookies en
          trackingcookies/optimalisatiecookies. Wij gebruiken ook cookies die door Intercom zijn ingesteld en waarmee de
          online chat-functie mogelijk wordt gemaakt. Uw browser- en gespreksgegevens worden verzameld en op servers in
          de Verenigde Staten bewaard. Wij hebben een gegevensverwerkingsovereenkomst met Intercom gesloten. Op grond
          daarvan mag Intercom geen gegevens gebruiken die via deze cookies zijn verkregen. Wij maskeren zoveel mogelijk
          gegevens. Voor meer informatie raden wij u aan het <a href='https://www.intercom.com/terms-and-policies#terms'>Privacybeleid</a> van Intercom te lezen.
        </p>
        <h2>Google Analytics en Amplitude</h2>
        <p>Wij gebruiken Google Analytics en Amplitude voor het bijhouden van bezoekers aan onze website en om rapporten
          te verkrijgen over de wijze waarop bezoekers de website gebruiken. Wij hebben de
          gegevensverwerkingsovereenkomst van Google aanvaard. Op grond daarvan mag Google de gegevens die door
          Analytics zijn verkregen, niet gebruiken voor andere diensten van Google. Wij hebben ook een
          gegevensverwerkingsovereenkomst met Amplitude gesloten. Wij maskeren zoveel mogelijk gegevens. Voor meer
          informatie raden wij u aan het <a href='https://amplitude.com/privacy'>Privacybeleid</a> van Google en/of
          Amplitude te lezen.
        </p>

        <br />

        <p>Wij hebben afspraken gemaakt met andere partijen die cookies op onze website plaatsen. Wij kunnen echter geen
          volledige controle hebben op wat zij met hun cookies doen. Daarom wordt u verzocht ook hun privacyverklaring
          te lezen. Voor meer informatie kunt u de overzichtstabel over cookies in deze verklaring raadplegen.
        </p>

        <h2>Verstrekken van persoonsgegevens aan derden</h2>
        <p>Uw persoonsgegevens kunnen worden doorgegeven aan en bewaard door externe specialisten die door Pylot zijn
          ingehuurd om gegevens voor Pylot te verwerken en om te ondersteunen bij het leveren van de content en de
          diensten waar u om vraagt, zoals onze IT-leveranciers. Pylot neemt passende maatregelen ter bescherming van uw
          persoonsgegevens. Pylot verplicht deze externe specialisten om uw persoonsgegevens geheim te houden en om
          passende technische en organisatorische maatregelen te nemen ter bescherming van uw gegevens tegen alle vormen
          van onrechtmatige verwerking.
        </p>

        <br />

        <p>Daarnaast kunnen uw persoonsgegevens aan een derde openbaar worden gemaakt indien Pylot daartoe verplicht is
          op grond van een rechterlijk bevel of een andere juridische of wettelijke verplichting, of indien zulks
          volgens ons noodzakelijk is om onrechtmatige activiteiten of schendingen van ons beleid te voorkomen of stop
          te zetten.
        </p>

        <h2>Beveiliging</h2>
        <p>Wij zullen naar ons beste vermogen onze diensten beschermen tegen verkeerd gebruik, misbruik en onbevoegde
          toegang tot uw persoonsgegevens. Wij zorgen ervoor dat alleen bevoegde personen toegang tot uw
          persoonsgegevens hebben en dat er toezicht wordt gehouden op de toegang tot uw persoonsgegevens.
        </p>

        <h2>Gegevensopslag en bewaartermijnen</h2>
        <p>Alle door u verstrekte of ingevoerde persoonsgegevens worden veilig in een databank van Amazon Web Services
          Europe (Ierland) bewaard. Amazon Web Services is een van de veiligste platformen voor cloud-services.
        </p>

        <h2>Wijzigingen aan dit privacy- en cookiebeleid</h2>
        <p>Wanneer deze website wijzigt, moeten wij ons Privacy- en Cookiebeleid ook wijzigen. Let daarom op de datum
          van de laatste versie aan het begin van deze pagina en kijk of er nieuwe versies zijn. Wij proberen altijd om
          wijzigingen afzonderlijk van de website te melden.
        </p>

        <h2>Uw rechten</h2>
        <p>Mocht u vragen hebben over de verwerking door ons van uw persoonsgegevens of indien u wilt weten welk soort
          persoonsgegevens wij van u bewaren, dan kunt u contact met ons opnemen. Onze contactgegevens vindt u
          hieronder.
        </p>

        <br />

        <p>Op grond van de privacywetgeving heeft u de volgende rechten:</p>
        <ul type='circle'>
          <li>Het recht om <strong>informatie</strong> te krijgen over welke persoonsgegevens wij hebben en waar wij die
            voor gebruiken;
          </li>
          <li>Het recht om de persoonsgegevens die wij over u bewaren, te <strong>controleren</strong>;</li>
          <li>Het recht om onjuiste gegevens te laten <strong>corrigeren</strong>;</li>
          <li>Het recht om uw persoonsgegevens <strong>door te geven</strong>;</li>
          <li>Het recht om de persoonsgegevens te laten <strong>verwijderen</strong>;</li>
          <li>Het recht om te vragen om <strong>gegevensbeperking</strong>;</li>
          <li>Het recht om uw toestemming <strong>in te trekken</strong>;</li>
          <li>Het recht om <strong>bezwaar</strong> te maken tegen bepaald gebruik.</li>
        </ul>

        <br />

        <p>U dient ervoor te zorgen dat u ons altijd gegevens verstrekt over wie u bent, zodat wij ervoor kunnen zorgen
          dat wij de gegevens aan de juiste persoon doorgeven en dat wij geen gegevens van de verkeerde persoon wijzigen
          of verwijderen.
        </p>

        <h2>Klacht indienen</h2>
        <p>Op grond van privacywetgeving heeft u het recht om bij uw nationale toezichthoudende autoriteit een klacht in
          te dienen over de verwerking van uw persoonsgegevens. In Nederland is dat de Autoriteit Persoonsgegevens. Voor
          het indienen van een klacht over onze verwerking kunt u contact opnemen met de toezichthoudende autoriteit in
          uw land of met de <a href='https://autoriteitpersoonsgegevens.nl'>Autoriteit Persoonsgegevens</a>.
        </p>

        <h2>Contactgegevens</h2>
        <p>
          Pylot B.V.<br />
          Adres: Vlotlaan 710, Monster, 2681 TX, Nederland<br />
          KvK-nummer: 69516103<br />
          E-mail: info@pylot.nl<br />
          Telefoonnummer: +31 174 286 161<br />
        </p>
      </article>
    );
  }
}
