import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import InfoIcon from '../Icons/InfoIcon';

import styles from './GroupHeader.module.css';

const GroupHeader = React.memo(({
  text, tooltipId, tooltipText, className
}) => (
  <h3 className={classnames(styles.headerText, className)}>
    <span>{text}</span>
    <span
      className={styles.headerIcon}
      data-tip={tooltipText}
      data-for={tooltipId}
    >
      <InfoIcon />
    </span>
  </h3>
));

GroupHeader.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  tooltipId: PropTypes.string,
  tooltipText: PropTypes.string,
  className: PropTypes.string,
};

GroupHeader.defaultProps = {
  tooltipId: null,
  tooltipText: null,
  className: null,
};

export default GroupHeader;
