import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import SettingsMenu from 'components/SettingsMenu';
import SettingsProfile from 'components/SettingsProfile';
import SettingsPermissions from 'components/SettingsPermissions';
import SettingsBenchmarking from 'components/SettingsBenchmarking';
import SettingsDataQuality from 'components/SettingsDataQuality';
import SettingsEquipmentMeters from 'components/SettingsEquipmentMeters';
import SettingsGreenhouses from 'components/SettingsGreenhouses';
import SettingsLaborWorks from 'components/SettingsLaborWorks';
import SettingsMeasurements from 'components/SettingsMeasurements';
import SettingsMeasurementsCrop from 'components/SettingsMeasurementsCrop';
import SettingsHarvestForecast from 'components/SettingsHarvestForecast';
import SettingsHarvestForecastSpecies from 'components/SettingsHarvestForecastSpecies';
import SettingsReports from 'components/SettingsReports';
import SettingsIncidentPage from 'components/SettingsIncidentPage';
import SettingsIncidents from 'components/SettingsIncidents';
import SettingsAlerting from 'components/SettingsAlerting';

export default class SettingsRoutes extends Component {
  render() {
    return (
      <SettingsMenu>
        <Switch>
          <Route path='/:organizationSlug/settings/profile' component={SettingsProfile} />
          <Route path='/:organizationSlug/settings/permissions' component={SettingsPermissions} />
          <Route path='/:organizationSlug/settings/benchmarking' component={SettingsBenchmarking} />
          <Route path='/:organizationSlug/settings/data-quality' component={SettingsDataQuality} />
          <Route path='/:organizationSlug/settings/equipment/meters' component={SettingsEquipmentMeters} />
          <Route path='/:organizationSlug/settings/labor/works' component={SettingsLaborWorks} />
          <Route path='/:organizationSlug/settings/equipment/greenhouses' component={SettingsGreenhouses} />
          <Route path='/:organizationSlug/settings/harvest-forecast/:speciesType' exact component={SettingsHarvestForecastSpecies} />
          <Route path='/:organizationSlug/settings/harvest-forecast' exact component={SettingsHarvestForecast} />
          <Route path='/:organizationSlug/settings/crop-cycles/measurements/:cropType' exact component={SettingsMeasurementsCrop} />
          <Route path='/:organizationSlug/settings/crop-cycles/measurements' exact component={SettingsMeasurements} />
          <Route path='/:organizationSlug/settings/crop-cycles/reports' exact component={SettingsReports} />
          <Route path='/:organizationSlug/settings/incidents/:incidentType' exact component={SettingsIncidentPage} />
          <Route path='/:organizationSlug/settings/incidents' exact component={SettingsIncidents} />
          <Route path='/:organizationSlug/settings/alerting' exact component={SettingsAlerting} />
          <Redirect to='/:organizationSlug/404' />
        </Switch>
      </SettingsMenu>
    );
  }
}
