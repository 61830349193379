import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { ReactComponent as ToggleIcon } from './assets/icon.svg';

import styles from './DateButton.module.css';


export default class DateButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    startOfPeriod: PropTypes.string.isRequired,
    endOfPeriod: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
    periodTypeLabel: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isWide: PropTypes.bool,
  };

  static defaultProps = {
    onClick: null,
    disabled: false,
    isWide: false,
  };

  handlerClick = (e) => {
    const { onClick, disabled } = this.props;

    if (onClick && !disabled) {
      onClick(e);
    }
  };

  render() {
    const {
      startOfPeriod,
      endOfPeriod,
      disabled,
      onClick,
      periodType,
      periodTypeLabel,
      isWide,
    } = this.props;

    return (
      <button
        className={classnames(styles.button, { [styles.disabled]: disabled, [styles.wide]: isWide })}
        onClick={onClick}
        disabled={disabled}
        type='button'
      >
        <div className={styles.content}>
          <span className={styles.periodType}>{periodTypeLabel},&nbsp;</span>
          <span>{periodType === 'day' ? startOfPeriod : `${startOfPeriod} – ${endOfPeriod}`}</span>
        </div>
        <ToggleIcon />
      </button>
    );
  }
}
