import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import DashboardHeader from 'components/DashboardHeader';
import EssentialPricePlan from 'components/EssentialPricePlan';

import styles from './EssentialPricePlanDashboard.module.css';

const EssentialPricePlanDashboard = ({
  intl,
  tabName,
}) => {
  const { formatMessage } = intl;

  const dashboardsHeaders = {
    benchmarking: formatMessage({ id: 'benchmarking.header' }),
    energy: formatMessage({ id: 'dashboards.energyResources' }),
    'labor-resources': formatMessage({ id: 'dashboards.laborResources' }),
    'crop-performance': formatMessage({ id: 'dashboards.cropsPerformance' }),
    harvest: formatMessage({ id: 'dashboards.harvest' }),
    targets: formatMessage({ id: 'dashboards.kpi' }),
    'plan-fact': formatMessage({ id: 'planFact.header' }),
  };

  return (
    <div className={styles.layout}>
      <div className={styles.contentWrapper}>
        <DashboardHeader
          dashboardName={dashboardsHeaders[tabName]}
          withoutDatepicker
        />

        <EssentialPricePlan className={styles.pricePlan} />
      </div>
    </div>
  );
};

EssentialPricePlanDashboard.propTypes = {
  intl: intlShape.isRequired,
  tabName: PropTypes.string.isRequired,
};


export default injectIntl(EssentialPricePlanDashboard);
