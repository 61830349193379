import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import DefaultDialog from '../../DefaultDialog';
import BigButton from '../../BigButton';

import styles from './SaveChangesDialog.module.css';

export default class SaveChangesDialog extends Component {
  static propTypes = {
    handlerCloseDialog: PropTypes.func.isRequired,
    handlerDontSave: PropTypes.func.isRequired,
    handlerSave: PropTypes.func.isRequired,
  };

  render() {
    const {
      handlerCloseDialog, handlerDontSave, handlerSave
    } = this.props;

    return (
      <DefaultDialog
        title={<FormattedMessage id='plans.saveChangesDialogTitle' />}
        onClose={handlerCloseDialog}
      >
        <div>
          <p className={styles.dialogText}>
            <FormattedMessage id='plans.saveChangesDialogBody' />
          </p>
          <div className={styles.buttons}>
            <BigButton
              className={styles.cancel}
              onClick={handlerDontSave}
              title={<FormattedMessage id='plans.saveChangesDialogButton' />}
              theme='light'
            />
            <BigButton
              className={styles.confitm}
              onClick={handlerSave}
              title={<FormattedMessage id='button.save' />}
              theme='dark'
            />
          </div>
        </div>
      </DefaultDialog>
    );
  }
}
