import React, {
  useRef, useState, memo, useCallback
} from 'react';
import { useMeasure } from 'react-use';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import {
  Group, Text, Rect, Image
} from 'react-konva';
import useImage from 'use-image';
import {
  head, isEmpty, find, includes
} from 'lodash';

import numbersFormatting from 'helpers/numbersFormatting';
import numbersFormatAndRounding from 'helpers/numbersFormatAndRounding';

import FxIconSvg from 'components/Icons/FxIcon/fxIcon.svg';
import InfoIconSvg from 'components/Icons/InfoIcon/info.svg';
import ArrowDownSvg from 'components/Icons/ArrowDown/arrowDown.svg';
import ErrorHintSvg from 'components/Icons/ErrorHint/errorHint.svg';

import DefaultCanvasGrid from 'components/DefaultCanvasGrid';
import NameEditorComponent from 'components/DefaultCanvasGrid/components/NameEditorComponent';

import styles from './HarvestForecastEnterTable.module.css';

// TODO: вынести в отдельный хелпер
// const getTrimmedValue = (value) => {
//   if (value === '') {
//     return value;
//   }

//   const trimmedValue = isString(value) ?
//     value
//       .replace(/^\s+|\s+$/g, '')
//       .replace(/\s/g, '')
//       .replace(/,/g, '.')
//     :
//     value;

//   return toNumber(trimmedValue);
// };

const hideTooltip = setData => setData({
  isVisible: false,
  top: 0,
  left: 0,
  data: null,
  position: 'top',
});

const showTooltip = (setData, e, text, alignBySelf = false, addY = 0, addX = 0, leftPosition = false) => {
  const target = alignBySelf ? e?.target : e?.target?.parent?.children?.[0];
  const yPosition = target?.attrs?.y - target?.parent?.parent?.attrs?.offsetY;
  const xPosition = target?.attrs?.x - target?.parent?.parent?.attrs?.offsetX;

  const additionalYPosition = leftPosition ? target?.attrs?.height / 2 : 0;
  const additionalXPosition = leftPosition ? 0 : target?.attrs?.width / 2;

  setData({
    isVisible: true,
    top: yPosition + additionalYPosition + addY,
    left: xPosition + additionalXPosition + addX,
    data: text,
    position: leftPosition ? 'left' : 'top',
  });
};

const showFullNameTooltip = (setData, e, text, alignBySelf = false, addY = 0, addX = 0, leftPosition = false) => {
  /**
   * в currentTarget.textArr можно получить реально отображаемый текст
   * смотрим заканчивается ли этот реальный текст многоточием ...
   * и если реально заканчивается, то показываем тултип на элементе
   * при этом полный текст берём в currentTarget.attrs
   */
  const visibleText = e?.currentTarget?.textArr?.[0]?.text;
  const isTextEllipsis = visibleText?.endsWith('…');
  const value = e?.currentTarget?.attrs?.text;
  const target = alignBySelf ? e?.target : e?.target?.parent?.children?.[0];
  const yPosition = target?.attrs?.y - target?.parent?.parent?.attrs?.offsetY;
  const xPosition = target?.attrs?.x - target?.parent?.parent?.attrs?.offsetX + target?.attrs?.width / 2;

  const additionalYPosition = leftPosition ? target?.attrs?.height / 2 : 0;

  if (isTextEllipsis || text) {
    setData({
      isVisible: true,
      top: yPosition + additionalYPosition + addY,
      left: xPosition + addX,
      data: text || value,
      position: leftPosition ? 'left' : 'top',
    });
  }
};

const hideFullNameTooltip = setData => setData({
  isVisible: false,
  top: 0,
  left: 0,
  data: null,
  position: 'top',
});

const setHoverState = (e, editMode, isCellHovered, setIsCellHovered) => {
  if (!editMode) {
    return null;
  }

  const container = e.target.getStage().container();

  container.style.cursor = isCellHovered ? 'pointer' : 'default';

  return setIsCellHovered(isCellHovered);
};

const handlerExtraCycleClick = (setData, cellData, e) => {
  /**
   * Не берём сам target, а копаем до родителя, т.к. клик может попасть во внутренний элемент
   * Обращение children[0] это хак, на самом деле здесь нужно получать по имени Rect
   */
  const target = e?.target?.parent?.children[0];
  const xPosition = target?.attrs?.x - target?.parent?.parent?.attrs?.offsetX;

  setData({
    isVisible: true,
    top: target.attrs.y + target.attrs.height + 5,
    left: xPosition,
    data: cellData,
  });
};

const getCellColor = (isTotalColumnData, columnIndex, editMode, canEdit, forcedWhiteColor) => {
  if (isTotalColumnData) {
    return '#F7F9FA';
  }

  if (columnIndex === 0) {
    return '#FFF';
  }

  if (forcedWhiteColor) {
    return '#FFF';
  }

  if (editMode) {
    return (canEdit ? '#FFF' : '#F7F9FA');
  }

  return '#EFF2F4';
};

const getTextColor = (isTotalColumnData, isToday, isWeekend) => {
  if (isToday) {
    return '#1DBADF';
  }

  if (isWeekend || isTotalColumnData) {
    return '#777776';
  }

  return '#4A4A49';
};

const getTotalCellValue = (currentCellUnits, filteredByCategoryValues, filteredRelatedToValues) => {
  // Для относительных значений тоталы считаем в абсолютных (по relative колонке)
  if (currentCellUnits === 'percent') {
    const sumInKilos = filteredByCategoryValues.reduce((acc, item) => {
      if (!item.value) {
        return acc;
      }

      const relatedItemCell = find(filteredRelatedToValues, { productId: item.productId, rowIndex: item.rowIndex });
      const valueInKilos = (Number(relatedItemCell.value) / 100) * Number(item.value);

      return acc + valueInKilos;
    }, 0);

    return sumInKilos;
  }

  const sumOfValues = filteredByCategoryValues.reduce((acc, item) => {
    if (!item.value) {
      return acc;
    }

    return acc + Number(item.value);
  }, 0);

  return sumOfValues;
};

const getTotalColumnData = (harvestHeaderRowIndex, rowIndex, columnIndex, convertedArray) => {
  const currentCellCategoryHeader = find(convertedArray, { rowIndex: harvestHeaderRowIndex, columnIndex });
  const currentCellCategory = currentCellCategoryHeader?.category;
  const currentCellRelatedTo = currentCellCategoryHeader?.relatedTo;
  const currentCellUnits = currentCellCategoryHeader?.units;
  const columnsOfThisCategory = convertedArray
    .filter(item => item.category === currentCellCategory)
    .filter(item => item.columnIndex !== columnIndex)
    .map(item => item.columnIndex);

  const filteredValues = convertedArray
    .filter(item => item.type === 'data')
    .filter(item => item.rowIndex === rowIndex);

  const filteredByCategoryValues = filteredValues
    .filter(item => includes(columnsOfThisCategory, item.columnIndex));

  const columnsOfRelatedCategory = convertedArray
    .filter(relatedItem => relatedItem.category === currentCellRelatedTo)
    .filter(relatedItem => relatedItem.columnIndex !== columnIndex)
    .map(relatedItem => relatedItem.columnIndex);

  const filteredRelatedToValues = filteredValues
    .filter(item => includes(columnsOfRelatedCategory, item.columnIndex));

  const sumOfColumns = getTotalCellValue(currentCellUnits, filteredByCategoryValues, filteredRelatedToValues);

  const roundTo = currentCellUnits === 'kilogram' ? 0 : 1;

  return numbersFormatAndRounding(sumOfColumns, roundTo);
};

const getTotalRowData = (currentCellCategoryHeader, currentTotalHeader, harvestHeaderRowIndex, columnIndex, convertedArray) => {
  const currentCellRelatedTo = currentCellCategoryHeader?.relatedTo;
  const currentCellUnits = currentCellCategoryHeader?.units;
  const currentCellCategory = currentCellCategoryHeader?.category;

  const filteredDataValues = convertedArray
  .filter(item => item.type === 'data');

  const filteredValues = filteredDataValues
    .filter(item => item.columnIndex === columnIndex);

  const columnsOfRelatedCategory = convertedArray
    .filter(relatedItem => relatedItem.category === currentCellRelatedTo)
    .map(relatedItem => relatedItem.columnIndex);

  const filteredRelatedToValues = filteredDataValues
    .filter(item => includes(columnsOfRelatedCategory, item.columnIndex));

  if (currentTotalHeader) {
    /**
     * Для пересечения тоталов (ячейки, где пересекаются total col и total row) считаем отдельно,
     * т.к. иначе не будет работать динамическое обновление данных в ячейке
     */

    const columnsOfThisCategory = convertedArray
    .filter(item => item.category === currentCellCategory)
    .map(item => item.columnIndex);


    const filteredByCategoryValues = filteredDataValues
      .filter(item => includes(columnsOfThisCategory, item.columnIndex));

     return getTotalCellValue(currentCellUnits, filteredByCategoryValues, filteredRelatedToValues);
  }

  return getTotalCellValue(currentCellUnits, filteredValues, filteredRelatedToValues);
};

const Cell = memo(({
  intl,
  rowIndex,
  columnIndex,
  key,
  x,
  y,
  width,
  height,
  value,
  isCalculatedMetric,
  type,
  setTooltipData,
  metric,

  cellData,
  setErrorTooltipData,
  editMode,
  onDataCellClick,
  convertedArray,
  harvestHeaderRowIndex,
}) => {
  const { formatMessage, locale } = intl;
  const isDataCell = type === 'data';
  const isMetricCell = type === 'metricCell';
  const isTotalColumnData = type === 'totalColumnData';
  const cellWidth = isMetricCell ? width - 32 : width;
  const align = columnIndex > 0 ? 'right' : 'left';
  // const offsetX = columnIndex > 0 ? 16 : -16;

  const isFxIcon = isCalculatedMetric && columnIndex === 0;
  const [image] = useImage(FxIconSvg);

  /**
   * Колонка Total сделана через render функцию, а не просто высчитывается при ините данных,
   * чтобы было динамическое обновление при изменении ячеек
   */
  const formattedValue = isTotalColumnData ?
    getTotalColumnData(harvestHeaderRowIndex, rowIndex, columnIndex, convertedArray)
    :
    numbersFormatting(value);
  const getTooltipText = () => {
    const description = metric?.description?.[locale];

    return `${value} – ${description}`;
  };
  const tooltipText = isMetricCell ? getTooltipText() : undefined;

  const validationErrors = cellData?.validationErrors;
  const isError = !isEmpty(validationErrors);
  const firstError = head(validationErrors);
  const firstErrorCode = firstError?.errorCode;
  const errorText = firstErrorCode ? formatMessage({ id: `formErrors.${firstErrorCode}` }, { minValue: firstError?.minValue, maxValue: firstError?.maxValue }) : null;
  const [errorHintImage] = useImage(ErrorHintSvg);
  const cellColor = getCellColor(isTotalColumnData, columnIndex, editMode, cellData?.canEdit, cellData?.forcedWhiteColor);
  const textColor = getTextColor(isTotalColumnData, cellData?.isToday, cellData?.isWeekend);

  const onCellClick = isDataCell ? () => onDataCellClick(cellData?.productId) : () => {};

  return (
    // onClick не срабатывает, если ячейка не вся видна при скролле, поэтому юзаем onMouseDown
    <Group key={key} onMouseDown={onCellClick} onTap={onCellClick}>
      <Rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={cellColor}
        stroke='#e7e9ee'
        strokeWidth={1}
      />
      <Text
        x={x}
        y={y}
        width={cellWidth}
        height={height}
        text={formattedValue}
        verticalAlign='middle'
        align={align}
        offsetY={-1}
        // offsetX={offsetX}
        padding={16}
        fontFamily='Roboto'
        fill={textColor}
        fontSize={14}
        wrap='none'
        ellipsis
        onMouseEnter={isMetricCell ? e => showFullNameTooltip(setTooltipData, e, tooltipText) : () => {}}
        onMouseLeave={isMetricCell ? e => hideFullNameTooltip(setTooltipData, e) : () => {}}
        fontStyle={isTotalColumnData ? '500' : '400'}
      />
      {isFxIcon && (
        <Image
          x={x + width - 32}
          y={y + 9}
          width={15}
          height={15}
          image={image}
          onMouseEnter={e => showFullNameTooltip(setTooltipData, e, formatMessage({ id: 'measurements.calculated' }), true, -12)}
          onMouseLeave={e => hideFullNameTooltip(setTooltipData, e)}
        />
      )}

      {isError && (
        <>
          <Image
            x={x + 16}
            y={y + 7}
            width={20}
            height={20}
            image={errorHintImage}
            onMouseEnter={e => showTooltip(setErrorTooltipData, e, errorText, false, 0, 0, true)}
            onMouseLeave={e => hideTooltip(setErrorTooltipData, e)}
          />
          <Rect
            x={x}
            y={y}
            width={width - 1}
            height={1}
            fill='#be1034'
          />
          <Rect
            x={x}
            y={y + height - 2}
            width={width - 1}
            height={1}
            fill='#be1034'
          />
          <Rect
            x={x}
            y={y}
            width={1}
            height={height - 1}
            fill='#be1034'
          />
          <Rect
            x={x + width - 2}
            y={y}
            width={1}
            height={height - 1}
            fill='#be1034'
          />
        </>
      )}
    </Group>
  );
});

Cell.propTypes = {
  intl: intlShape.isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnIndex: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  type: PropTypes.string.isRequired,
  setTooltipData: PropTypes.func.isRequired,
  setErrorTooltipData: PropTypes.func.isRequired,
  isCalculatedMetric: PropTypes.bool,
  metric: PropTypes.object,
  cellData: PropTypes.object,
  editMode: PropTypes.bool,
  onDataCellClick: PropTypes.func,
  convertedArray: PropTypes.array.isRequired,
  harvestHeaderRowIndex: PropTypes.number.isRequired,
};

Cell.defaultProps = {
  isCalculatedMetric: false,
  metric: null,
  cellData: null,
  editMode: false,
  onDataCellClick: () => {},
};

const HeaderCell = memo(({
  rowIndex,
  columnIndex,
  key,
  x,
  y,
  width,
  height,
  value,
  extraCycleNameEditorData,
  setExtraCycleNameEditorData,
  intl,
  type,
  setTooltipData,
  cellData,
  createExtraProduct,
  editMode,
  getChildRef,
}) => {
  const [isCellHovered, setIsCellHovered] = useState(false);

  const { formatMessage } = intl;
  const isAddPlantButton = type === 'addPlantHeader' || type === 'addExtraPlantHeader';
  const isMainExtraHeader = type === 'mainHeader' || type === 'extraHeader';
  const isExtraHeader = type === 'extraHeader';
  const isGreenhouseHeader = type === 'greenhouse';
  const isExtraColumnHeader = type === 'extraColumnHeader';
  const isCreateExtraColumnHeader = type === 'createExtraColumnHeader';
  const isTotalColumnHeader = type === 'totalColumnHeader';
  const withAction = cellData?.withAction;

  let styleProps = {
    align: 'left',
    offsetX: -16,
    fontSize: 14,
    fontStyle: 500,
  };

  if (isMainExtraHeader) {
    styleProps = {
      align: 'center',
      offsetX: 0,
      fontSize: 14,
      fontStyle: 500,
    };
  }

  if (isAddPlantButton) {
    styleProps = {
      align: 'center',
      offsetX: 0,
      fontSize: 20,
      fontStyle: 300,
    };
  }

  if (isGreenhouseHeader) {
    styleProps = {
      align: 'center',
      offsetX: 0,
      fontSize: 14,
      fontStyle: 500,
    };
  }

  if (isTotalColumnHeader) {
    styleProps = {
      align: 'center',
      offsetX: 0,
      fontSize: 14,
      fontStyle: 500,
    };
  }

  const isPlantingCycleHeader = type === 'plantingCycle';

  const getCellWidth = () => {
    if (isExtraHeader) {
      return width - 146; // какой-то баг рассчёта mergedCells, прибавляется лишняя ячейка (а мы тут её вычитаем)
    }

    return width;
  };
  const cellWidth = getCellWidth();
  const getTextWidth = () => {
    if (isPlantingCycleHeader) {
      return width - 32;
    }

    return width;
  };
  const textWidth = getTextWidth();

  const isClickedCell = columnIndex === extraCycleNameEditorData?.data?.columnIndex && rowIndex === extraCycleNameEditorData?.data?.rowIndex;

  const onCellClick = (event) => {
    if (!editMode) {
      return () => {};
    }

    if (isCreateExtraColumnHeader) {
      const actionAfterSuccess = () => {
        const childRef = getChildRef();

        if (childRef?.current) {
          childRef.current.scrollToCoords(x, y);
        }
      };

      return createExtraProduct(actionAfterSuccess);
    }

    // if (isAddPlantButton) {
    //   const isExtra = type === 'addExtraPlantHeader';
    //   return requestCreateNextPlant({ plantingCycleId, date, extra: isExtra });
    // }

    if (isExtraColumnHeader) {
      return handlerExtraCycleClick(setExtraCycleNameEditorData, cellData, event);
    }

    return () => {};
  };

  const textRef = useRef(null);
  const [image] = useImage(ArrowDownSvg);
  const [extraHeaderImage] = useImage(InfoIconSvg);

  let iconPositionX = 0;

  if (isExtraHeader) {
    iconPositionX = textWidth / 2 + textRef?.current?.textWidth / 2;
  }

  // const plantingCycleHeight = type === 'plantingCycle' ? 70 : 0;
  return (
    <Group
      key={key}
      onClick={onCellClick}
      onTap={onCellClick}
      onMouseEnter={withAction ? e => setHoverState(e, editMode, true, setIsCellHovered) : () => {}}
      onMouseLeave={withAction ? e => setHoverState(e, editMode, false, setIsCellHovered) : () => {}}
      cursor='pointer'
    >
      <Rect
        x={x}
        y={y}
        width={cellWidth}
        height={height}
        fill={(isClickedCell || isCellHovered) && editMode ? '#EFF2F4' : '#fafbfc'}
        stroke='#e7e9ee'
        strokeWidth={1}
      />
      <Text
        ref={textRef}
        x={x}
        y={y}
        width={textWidth}
        height={height}
        text={value}
        verticalAlign='middle'
        fill='#777776'
        fontStyle='500'
        fontFamily='Roboto'
        // wrap='none'
        ellipsis
        {...styleProps}
      />
      {isExtraColumnHeader && (
        <Image
          x={x + width - 32}
          y={y + height / 2 - 10}
          width={20}
          height={20}
          image={image}
        />
      )}
      {isExtraHeader && (
        <Image
          x={x + iconPositionX + 4}
          y={y + 10}
          width={16}
          height={16}
          image={extraHeaderImage}
          onMouseEnter={e => showFullNameTooltip(setTooltipData, e, formatMessage({ id: 'measurements.extraTooltip' }), true, 0, -8, true)}
          onMouseLeave={e => hideFullNameTooltip(setTooltipData, e)}
        />
      )}
    </Group>
  );
});

HeaderCell.propTypes = {
  intl: intlShape.isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnIndex: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  type: PropTypes.string.isRequired,
  setTooltipData: PropTypes.func.isRequired,
  isCalculatedMetric: PropTypes.bool,

  extraCycleNameEditorData: PropTypes.object,
  setExtraCycleNameEditorData: PropTypes.func.isRequired,
  cellData: PropTypes.object,
  createExtraProduct: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  getChildRef: PropTypes.func,
};

HeaderCell.defaultProps = {
  isCalculatedMetric: false,
  extraCycleNameEditorData: null,
  cellData: null,
  editMode: false,
  getChildRef: () => {},
};

const CellTotal = memo(({
  rowIndex,
  columnIndex,
  x,
  y,
  width,
  height,
  convertedArray,
  intl,
  harvestHeaderRowIndex,
}) => {
  const { formatMessage } = intl;
  const text = `${rowIndex}x${columnIndex}`;

  let textStyleProps = {};

  const isTotalTextCell = columnIndex === 0;
  const totalColumns = convertedArray.filter(item => item.type === 'totalColumnHeader');
  const currentTotalHeader = find(totalColumns, { columnIndex });

  if (rowIndex === 0 && isTotalTextCell) {
    // Для вывода units берём первую значимую строку
    const currentCellCategoryHeader = find(convertedArray, { rowIndex: harvestHeaderRowIndex, columnIndex: columnIndex + 1 });
    const currentCellUnits = currentCellCategoryHeader?.units;
    const unitsText = formatMessage({ id: `cunits.mini.${currentCellUnits}` });

    textStyleProps = {
      ...textStyleProps,
      text: `${formatMessage({ id: 'harvest.total' })}, ${unitsText}`,
    };
  } else {
    const currentCellCategoryHeader = find(convertedArray, { rowIndex: harvestHeaderRowIndex, columnIndex });
    const currentCellUnits = currentCellCategoryHeader?.units;
    const totalValue = getTotalRowData(currentCellCategoryHeader, currentTotalHeader, harvestHeaderRowIndex, columnIndex, convertedArray);
    const roundTo = currentCellUnits === 'kilogram' ? 0 : 1;

    textStyleProps = {
      ...textStyleProps,
      text: numbersFormatAndRounding(totalValue, roundTo),
    };
  }

  if (isTotalTextCell) {
    textStyleProps = {
      ...textStyleProps,
      align: 'left',
      padding: 16,
      // x: x + 12,
      width: width - 12,
    };
  }

  return (
    <>
      <Rect
        x={x}
        y={y}
        height={height}
        width={width}
        fill='#F7F9FA'
        stroke='#e7e9ee'
        strokeWidth={1}
      />
      <Text
        x={x}
        y={y}
        height={height}
        width={width}
        text={text}
        verticalAlign='middle'
        align='right'
        fontFamily='Roboto'
        fill='#777776'
        fontStyle='500'
        fontSize={13}
        padding={16}
        {...textStyleProps}
      />
      <Rect
        x={x}
        y={y}
        height={1}
        width={width}
        fill='#E1E8F0'
      />
    </>
  );
});

CellTotal.propTypes = {
  intl: intlShape.isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnIndex: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  convertedArray: PropTypes.array.isRequired,
  harvestHeaderRowIndex: PropTypes.number.isRequired,
};

const getColumnWidth = (columnIndex) => {
  if (columnIndex === 0) {
    return 160;
  }

  return 180;
};

const getRowHeight = (rowsHeadersCount, rowIndex) => {
  if (rowIndex === 1) {
    return 76;
  }

  return rowIndex > rowsHeadersCount - 1 ? 36 : 39;
};

// const getCellData = (rowIndex, columnIndex, metric, value) => {
//   const trimmedValue = getTrimmedValue(value);
//   const isError = trimmedValue < metric?.validationRangeMin || value > metric?.validationRangeMax;

//   if (isError) {
//     return {
//       validationErrors: [{
//         errorCode: 'shouldBeBetween',
//         minValue: metric?.validationRangeMin,
//         maxValue: metric?.validationRangeMax,
//       }],
//     };
//   }

//   return {};
// };

const itemRenderer = ({
  props, intl, editMode, rowsHeadersCount, getCellInfo, extraCycleNameEditorData, setExtraCycleNameEditorData, setTooltipData, setErrorTooltipData, columnCount, onDataCellClick, data, createExtraProduct, getChildRef, cellType, convertedArray, harvestHeaderRowIndex,
}) => {
  const { rowIndex, columnIndex } = props;
  const value = data[[rowIndex, columnIndex]];

  const cellProps = getCellInfo(rowIndex, columnIndex);
  const type = cellProps?.type;
  // const cellData = getCellData(rowIndex, columnIndex, metric, value);
  // const cellData = getCellData(rowsHeadersCount, rowsHeadersCountFull, columnsHeadersCount, grid, rowIndex, columnIndex);

  if (cellType === 'totalCell') {
    return (
      <CellTotal {...props} intl={intl} convertedArray={convertedArray} harvestHeaderRowIndex={harvestHeaderRowIndex} />
    );
  }

  if (rowIndex < rowsHeadersCount) {
    return (
      <HeaderCell
        {...props}
        // value='Header'
        value={data[[rowIndex, columnIndex]]}
        isLastCell={columnIndex === columnCount - 1}
        setExtraCycleNameEditorData={setExtraCycleNameEditorData}
        setTooltipData={setTooltipData}
        extraCycleNameEditorData={extraCycleNameEditorData}
        createExtraProduct={createExtraProduct}
        type={type}
        intl={intl}
        cellData={cellProps}
        editMode={editMode}
        getChildRef={getChildRef}
      />
    );
  }

  return (
    <Cell
      intl={intl}
      {...props}
      value={value}
      // isCalculatedMetric={isCalculatedMetric}
      setTooltipData={setTooltipData}
      setErrorTooltipData={setErrorTooltipData}
      type={type}
      // metric={metric}
      cellData={cellProps}
      editMode={editMode}
      onDataCellClick={onDataCellClick}
      convertedArray={convertedArray}
      harvestHeaderRowIndex={harvestHeaderRowIndex}
    />
  );
};

const canEdit = ({ rowIndex, columnIndex }, editMode, getCellInfo) => {
  if (!editMode) {
    return false;
  }

  const cellInfo = getCellInfo(rowIndex, columnIndex);

  return cellInfo.canEdit;
};

const handlerUpdateExtraColumn = (clearForecastFromLocalstorage, requestUpdateExtraProduct, cellProps, newName) => {
  const extraProductId = cellProps?.data?.extraProductId;

  clearForecastFromLocalstorage();

  return requestUpdateExtraProduct({ extraProductId, name: newName });
};

const handlerDeleteExtraColumn = (clearForecastFromLocalstorage, requestDeleteExtraProduct, cellProps) => {
  const extraProductId = cellProps?.data?.extraProductId;

  clearForecastFromLocalstorage();

  return requestDeleteExtraProduct({ extraProductId });
};

const getCorrectCellCoords = (rowIndex, columnIndex, keyCode) => {
  switch (keyCode) {
    case 37: // left
      return [rowIndex, columnIndex - 1];
    case 38: // up
      return [rowIndex - 1, columnIndex];
    case 39: // right
      return [rowIndex, columnIndex + 1];
    case 40: // down
      return [rowIndex + 1, columnIndex];
    default:
      return [rowIndex, columnIndex];
  }
};

const HarvestForecastEnterTable = ({
  intl,
  rowCount,
  columnCount,
  data,
  setData,
  getCellInfo,
  mergedCells,
  rowsHeadersCount,
  columnsHeadersCount,
  validatePlantName,
  addPlantHeaderColumnIndex,
  editMode,
  afterOnSubmit,
  onDataCellClick,
  createExtraProduct,
  requestUpdateExtraProduct,
  requestDeleteExtraProduct,
  clearForecastFromLocalstorage,
  harvestHeaderRowIndex,
}) => {
  const { formatMessage } = intl;
  const [containerRef, { width, height }] = useMeasure();
  const childRef = useRef();

  const getChildRef = () => childRef;

  const [extraCycleNameEditorData, setExtraCycleNameEditorData] = useState({
    isVisible: false,
    top: 0,
    left: 0,
    data: null,
  });

  const [tooltipData, setTooltipData] = useState({
    isVisible: false,
    top: 0,
    left: 0,
    data: null,
    position: 'top',
  });

  const handleKeyDown = useCallback((activeCell, e) => {
    const keyCode = e?.nativeEvent?.which;
    const isArrowCode = keyCode === 37 || keyCode === 38 || keyCode === 39 || keyCode === 40;

    const { rowIndex, columnIndex } = activeCell;
    /**
     * activeCell возвращается по состоянию до нажатия клавиши, поэтому
     * корректируем координаты ячейки в зависимости от нажатой стрелки на клавиатуре
     */
    const [newRowIndex, newColumnIndex] = getCorrectCellCoords(rowIndex, columnIndex, keyCode);
    const cellInfo = getCellInfo(newRowIndex, newColumnIndex);

    if (isArrowCode && !!cellInfo?.productId) {
      onDataCellClick(cellInfo?.productId);
    }
  }, [getCellInfo, onDataCellClick]);

  const setErrorTooltipData = () => {};
  // const [errorTooltipData, setErrorTooltipData] = useState({
  //   isVisible: false,
  //   top: 0,
  //   left: 0,
  //   data: null,
  //   position: 'left',
  // });

  const convertedArray = Object.keys(data).map((key) => {
    const [rowIndex, columnIndex] = key.split(',');
    const cellProps = getCellInfo(rowIndex, columnIndex);
    const type = cellProps?.type;


    return {
      ...cellProps,
      rowIndex: Number(rowIndex),
      columnIndex: Number(columnIndex),
      value: data[key], // Обязательно берём value из data, иначе будут считаться init значения
      type,
    };
  });


  return (
    <div ref={containerRef} className={styles.dataSheetWrapper}>
      <DefaultCanvasGrid
        ref={childRef}
        intl={intl}
        width={width}
        height={height}
        rowCount={rowCount}
        columnCount={columnCount}
        data={data}
        setData={setData}
        getCellInfo={getCellInfo}
        mergedCells={mergedCells}
        rowsHeadersCount={rowsHeadersCount}
        columnsHeadersCount={columnsHeadersCount}
        validatePlantName={validatePlantName}
        addPlantHeaderColumnIndex={addPlantHeaderColumnIndex}
        getColumnWidth={columnIndex => getColumnWidth(columnIndex)}
        getRowHeight={rowIndex => getRowHeight(rowsHeadersCount, rowIndex)}
        itemRenderer={(props, cellType) => itemRenderer({
          props,
          intl,
          editMode,
          rowsHeadersCount,
          getCellInfo,
          extraCycleNameEditorData,
          itemRenderer,
          setTooltipData,
          setErrorTooltipData,
          columnCount,
          onDataCellClick,
          data,
          createExtraProduct,
          setExtraCycleNameEditorData,
          getChildRef,
          cellType,
          convertedArray,
          harvestHeaderRowIndex,
        })}
        canEdit={({ rowIndex, columnIndex }) => canEdit({ rowIndex, columnIndex }, editMode, getCellInfo)}
        tooltipData={tooltipData}
        afterOnSubmit={afterOnSubmit}
        contextMenuOffsetTop={300}
        withTotalRow
        handleKeyDown={handleKeyDown}
      />
      {extraCycleNameEditorData?.isVisible && (
        <NameEditorComponent
          nameEditorData={extraCycleNameEditorData}
          setNameEditorData={setExtraCycleNameEditorData}
          requestUpdate={(cellInfo, newName) => handlerUpdateExtraColumn(clearForecastFromLocalstorage, requestUpdateExtraProduct, extraCycleNameEditorData, newName)}
          requestDelete={() => handlerDeleteExtraColumn(clearForecastFromLocalstorage, requestDeleteExtraProduct, extraCycleNameEditorData)}
          getCellInfo={getCellInfo}
          initialValues={{
            plantName: extraCycleNameEditorData?.data?.value || ''
          }}
          validatePlantName={validatePlantName}
          deleteConfirmMessage={formatMessage({ id: 'forecast.deleteExtraCycle' })}
        />
      )}
    </div>
  );
};

HarvestForecastEnterTable.propTypes = {
  intl: intlShape.isRequired,
  rowCount: PropTypes.number.isRequired,
  columnCount: PropTypes.number.isRequired,
  harvestHeaderRowIndex: PropTypes.number.isRequired,
  data: PropTypes.object,
  setData: PropTypes.func.isRequired,
  getCellInfo: PropTypes.func.isRequired,
  mergedCells: PropTypes.array,
  rowsHeadersCount: PropTypes.number.isRequired,
  columnsHeadersCount: PropTypes.number.isRequired,
  validatePlantName: PropTypes.func.isRequired,
  addPlantHeaderColumnIndex: PropTypes.number.isRequired,
  onDataCellClick: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  afterOnSubmit: PropTypes.func,
  createExtraProduct: PropTypes.func.isRequired,
  requestUpdateExtraProduct: PropTypes.func.isRequired,
  requestDeleteExtraProduct: PropTypes.func.isRequired,
  clearForecastFromLocalstorage: PropTypes.func.isRequired,
};

HarvestForecastEnterTable.defaultProps = {
  data: null,
  mergedCells: [],
  editMode: false,
  afterOnSubmit: null,
};

export default injectIntl(memo(HarvestForecastEnterTable));
