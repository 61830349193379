import * as moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { injectIntl, intlShape } from 'react-intl';

import LinesGraph from 'components/LinesGraph/components/LinesGraph/LinesGraph';

import { API_DATE_FORMAT } from 'helpers/defaultDates';
import { truncateSeries, splitPastFuture } from 'components/UnrealizedPotential/splitSeries';
import { buildGraphSeries } from '../../buildGraphSeries';

import ChartHeaderWithTotal from './components/ChartHeaderWithTotal';
import renderTooltipContent from '../../renderTooltipContent';

import metricsColors from '../../metricsColors';

import styles from './Harvest.module.css';

const tickTimeDisplayFormat = date => moment(date).isoWeek();

const defaultSize = { height: 195 };

const Harvest = ({
  harvest,
  unrealized,
  plan,
  descriptor,
  intl,
  wrappedClassName
}) => {
  const { formatMessage } = intl;

  const {
    alignedForecastDate
  } = descriptor;

  const lineColors = useMemo(() => {
    const colors = [];
    if (plan.points) {
      colors.push('#00B197');
    }
    if (harvest.cycle.points) {
      colors.push('#1DBADF');
    }
    if (harvest.model.points) {
      colors.push('#873679');
      if (alignedForecastDate) {
        colors.push('#873679');
      }
    }
    return colors;
  }, [alignedForecastDate, harvest, plan]);

  const lineStyles = useMemo(() => {
    const result = [];
    if (plan.points) {
      result.push('solid');
    }
    if (harvest.cycle.points) {
      result.push('solid');
    }
    if (harvest.model.points) {
      result.push('solid');
      if (alignedForecastDate) {
        result.push('dashed');
      }
    }
    return result;
  }, [alignedForecastDate, harvest, plan]);

  const graphLines = useMemo(() => {
    const lines = [];

    if (alignedForecastDate) {
      const convertedAlignedForecastDate = moment.utc(alignedForecastDate, API_DATE_FORMAT).valueOf() / 60000;
      lines.push(buildGraphSeries(formatMessage({ id: 'unrealizedPotential.plan' }),
        metricsColors.PLAN,
        plan,
        {}));
      lines.push(buildGraphSeries(formatMessage({ id: 'unrealizedPotential.harvested' }),
        metricsColors.CYCLE,
        truncateSeries(harvest.cycle, 'future', convertedAlignedForecastDate),
        {}));
      const [pastModel, futureModel] = splitPastFuture(harvest.model, convertedAlignedForecastDate);
      lines.push(
        buildGraphSeries(formatMessage({ id: 'unrealizedPotential.potentialHarvest' }),
          metricsColors.MODEL,
          pastModel,
          {
            area: metricsColors.AREA,
            hideNullableInTooltip: true
          })
      );
      lines.push(
        buildGraphSeries(formatMessage({ id: 'unrealizedPotential.potentialHarvest' }),
          metricsColors.MODEL,
          futureModel,
          {
            lineStyle: 'dashed',
            showInLegend: false,
            hideNullableInTooltip: true,
            key: 'futureHarvest',
            tooltipHidePointIndexes: [0]
          })
      );
    } else {
      lines.push(buildGraphSeries(formatMessage({ id: 'unrealizedPotential.plan' }),
        metricsColors.PLAN,
        plan,
        {}));
      lines.push(buildGraphSeries(formatMessage({ id: 'unrealizedPotential.harvested' }),
        metricsColors.CYCLE,
        harvest.cycle,
        {}));
      lines.push(buildGraphSeries(
        formatMessage({ id: 'unrealizedPotential.potentialHarvest' }),
        metricsColors.MODEL,
        harvest.model,
        {
          area: metricsColors.AREA,
        }
      ));
    }
    return lines;
  }, [alignedForecastDate, harvest, plan, formatMessage]);

  const graphsMaxValue = useMemo(() =>
    Math.max(...graphLines.map(line =>
      line?.points.map(point => point.y))
      .reduce((acc, item) => ([...acc, ...item]), [])
      .filter(v => v !== null)),
  [graphLines]);

  const renderTooltip = useCallback(date => renderTooltipContent({
    date, lines: graphLines, formatMessage
  }), [graphLines, formatMessage]);

  return (
    <div className={classnames(styles.block, wrappedClassName)}>
      <ChartHeaderWithTotal harvest={harvest} plan={plan} unrealized={unrealized} />
      <div className={styles.chartContainer}>
        <LinesGraph
          maxValue={graphsMaxValue}
          tickTimeDisplayFormat={tickTimeDisplayFormat}
          lines={graphLines}
          withoutPoints
          defaultSize={defaultSize}
          renderTooltipContent={renderTooltip}
          lineColors={lineColors}
          lineStyles={lineStyles}
        />
      </div>
    </div>
  );
};

Harvest.propTypes = {
  unrealized: PropTypes.object.isRequired,
  harvest: PropTypes.object.isRequired,
  descriptor: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  wrappedClassName: PropTypes.string
};

Harvest.defaultProps = {
  wrappedClassName: null
};

export default injectIntl(Harvest);
