import { connect } from 'react-redux';

import {
  getPlantingCyclePhotoCategories,
} from 'store/company/selectors';

import WeekPhoto from './WeekPhoto';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  photoCategories: getPlantingCyclePhotoCategories(state, props),
});

export default connect(mapStateToProps, mapDispatchToProps)(WeekPhoto);
