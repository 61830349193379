import React, { useMemo } from 'react';
import moment from 'moment';
import {
  get, isFinite, max, min
} from 'lodash';
import { bisector, utcDay } from 'd3';

import LinesGraphTooltip from 'components/LinesGraphTooltip/LinesGraphTooltip';
import numbersFormatting from 'helpers/numbersFormatting';
import getDateFormat from 'helpers/getDateFormat';
import { getTicksRange as getTicksRangeDefault } from 'helpers/getTicks';
import { API_DATE_FORMAT } from 'helpers/defaultDates';

export function customTicksFunction(xFrom, xTo, ticksCount) {
  const daysDiff = xTo.diff(xFrom, 'days');
  const delimeter = ticksCount - 1;
  let dt = Math.floor(daysDiff / delimeter);
  if (dt <= 0) {
    dt = 1;
  }
  const startInterval = new Date(+xFrom);
  return utcDay.filter((d) => {
    const index = utcDay.count(startInterval, d);
    return index % dt === 0;
  });
}

export function tickTimeDisplayFormat(xFromValue) {
  return moment.utc(xFromValue).format(getDateFormat('llll'));
}

export function preparePointValue(value) {
  return isFinite(value) ? value : null;
}

export function getForecastLineChartItems(
  assistant,
  pointsData,
  units,
  periods,
  key,
  name,
  color = '#1DBADF',
  realDataTill,
  cropTill
) {
  if (!cropTill) {
    return [{
      points: periods?.map((period) => {
        const date = moment.utc(new Date((+period.epochMinutes) * 60000));
        const y = preparePointValue(pointsData[period.epochMinutes]);
        return {
          y,
          x: date.format('MM-DD-YYYY')
        };
      }),
      color,
      name,
      units,
      key: `data-${key}`
    }];
  }

  if (cropTill && !realDataTill) {
    return [{
      points: periods?.map((period) => {
        const date = moment.utc(new Date((+period.epochMinutes) * 60000));
        const y = preparePointValue(pointsData[period.epochMinutes]);
        return {
          y,
          x: date.format('MM-DD-YYYY')
        };
      }),
      color,
      name,
      units,
      key: `data-${key}-till`,
      lineStyle: 'dashed',
    }];
  }

  const allPoints = periods?.map((period) => {
    const date = moment.utc(new Date((+period.epochMinutes) * 60000));
    const isBefore = date.isBefore(realDataTill, 'date');
    const isSame = date.isSame(realDataTill, 'date');
    return {
      y: preparePointValue(pointsData[period.epochMinutes]),
      x: date.format('MM-DD-YYYY'),
      isBefore,
      isSame
    };
  });

  const items = [];

  items.push({
    points: allPoints.filter(p => p.isBefore || p.isSame),
    color,
    name,
    units,
    key: `data-${key}`,
    hideNullableInTooltip: true,
  });

  const lastPoint = items?.[0]?.points?.length > 0 ? items[0].points[items[0].points.length - 1] : null;
  const futurePoints = allPoints.filter(p => !p.isBefore);

  items.push({
    points: lastPoint ? [lastPoint, ...futurePoints] : futurePoints,
    color,
    name,
    units,
    key: `data-${key}-till`,
    hideNullableInTooltip: true,
    lineStyle: 'dashed',
    showInLegend: false,
    tooltipHidePointIndexes: lastPoint ? [0] : [],
  });

  return items;
}

function extractLineValues(lines) {
  return lines
    .map((line) => {
      const linePoints = get(line, 'points');

      return linePoints ? [
        ...linePoints.map(item => item.y),
        ...linePoints.map(item => item.max),
        ...linePoints.map(item => item.min),
        0
      ] : [];
    })
    .reduce((reducer, item) => [...reducer, ...item], [])
    .filter(item => item !== null);
}

export function getLinesMaxValue(lines) {
  const valuesArray = extractLineValues(lines);
  return max(valuesArray) || 100;
}

export function getLinesMinValue(lines) {
  const valuesArray = extractLineValues(lines);
  return min(valuesArray) || 0;
}

export function formatTooltipValue(value, unitsText) {
  return isFinite(value) ? `${numbersFormatting(value)}${unitsText && unitsText !== '%' ? ' ' : ''}${unitsText}` : '-';
}

export function renderTooltipContent(tooltipDate, items, intl) {
  if (!tooltipDate) {
    return null;
  }

  const { formatMessage } = intl;

  const dataKey = tooltipDate.format('MM-DD-YYYY');

  const maxLoc = formatMessage({ id: 'forecast.assistant.max' });
  const minLoc = formatMessage({ id: 'forecast.assistant.min' });

  const lines = items.reduce((acc, item) => {
    const pointIndex = item.points.findIndex(p => p.x === dataKey);
    const unitsText = formatMessage({ id: `cunits.mini.${item.units}` });
    const values = [...acc];
    const isHide = item.hideNullableInTooltip && item?.tooltipHidePointIndexes?.includes(pointIndex);
    const customName = item.customName && item?.customNameIndexes?.includes(pointIndex) ? item.customName : undefined;

    if (pointIndex !== -1 && !isHide) {
      const point = item.points[pointIndex];
      values.push({
        value: formatTooltipValue(point?.y, unitsText),
        header: customName || item.name,
        ...item,
      });
      if (point.min) {
        values.push({
          value: formatTooltipValue(point?.min, unitsText),
          header: minLoc,
          ...item,
        });
      }
      if (point.max) {
        values.push({
          value: formatTooltipValue(point?.max, unitsText),
          header: maxLoc,
          ...item,
        });
      }
    }
    return values;
  }, []);

  return (
    <LinesGraphTooltip
      lines={lines}
      tooltipDate={tooltipDate}
      isSpaced
      periodType='day'
    />
  );
}

export const getPw = () => {
  const base = [1, 2, 2.5, 5];
  const MAX_INTERVAL = 5;
  let pw = [];
  for (let i = 0; i <= MAX_INTERVAL; i += 1) {
    pw = pw.concat(base.map(bn => bn * (10 ** i)));
  }
  return pw;
};

const PW = getPw();

export const getTicksRange = (start, stop, count) => {
  const interval = stop - start;
  if (interval > PW[PW.length - 1]) {
    return getTicksRangeDefault(start, stop, count);
  }
  const bisect = bisector(d => d).right;
  const pwIndex = bisect(PW, interval);
  const dt = PW[pwIndex] / count;
  let ticks = [];
  for (let i = 0; i <= count; i += 1) {
    ticks.push(i * dt);
  }
  if (start < 0) {
    const startValueIndex = bisect(ticks, Math.abs(start));
    ticks = ticks.map(v => v - ticks[startValueIndex]);
  }
  return ticks;
};


export function useOverlayData(showHistoricalPeriod, assistant) {
  return useMemo(() => {
    if (assistant?.realDataTill && showHistoricalPeriod) {
      return {
        start: 0,
        end: moment.utc(assistant?.realDataTill, API_DATE_FORMAT)
      };
    }
    return null;
  }, [showHistoricalPeriod, assistant]);
}
