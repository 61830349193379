import { find, get } from 'lodash';
import { getMlName, getProductTypeName } from 'helpers/getVarietyName';
import { getValue } from 'helpers/getValueDetails';
import { getCompartmentNameById, getPlantingCycleExtendedLabel } from 'helpers/getPlantingCycleLabel';

/* eslint-disable import/prefer-default-export */
export const getRowName = ({
  type,
  ref,
  intl,
  compartments,
  fruitClasses,
  varieties,
  productGroups,
  allPlantingCycles,
  currentBreakdown,
  currentSpecies,
  currentFruitClassCode,
  currentVarietyId,
  workTypes,
  dataUnits,
  isPlantingCycleDatesAsTimestamp,
}) => {
  const { formatMessage, locale } = intl;

  const noRefMessages = {
    noPlantingCycles: 'dashboards.noPlantingCycles',
    total: 'dashboards.totalTableAll',
    plan: 'labor.stats.plan',
    actual: 'labor.stats.actual',
    completedPercentage: 'labor.stats.completedPercentage',
  };

  if (!type && !ref) {
    return 'no data';
  }

  if (type === 'noPlantingCycles') {
    return formatMessage({ id: 'dashboards.periodsWithoutCropCycles' });
  }

  const msgId = noRefMessages?.[type];

  if (msgId) {
    const laborWorksTypes = ['plan', 'actual', 'completedPercentage'];
    if (laborWorksTypes.includes(type)) {
      return `${formatMessage({ id: msgId })}, ${formatMessage({ id: `cunits.mini.${dataUnits}` }).toLowerCase()}`;
    }
    return formatMessage({ id: msgId });
  }

  if (!ref) {
    return type;
  }

  const currentGrownFilter = currentVarietyId || currentFruitClassCode || currentSpecies;

  const isAllSpecies = !currentGrownFilter;

  switch (ref.type) {
    case 'FruitClass': {
      const currentFruitClass = find(fruitClasses, { id: ref.id });

      return ref.id === 'null' ? formatMessage({ id: 'fruitClass.other' }) : `${getMlName(currentFruitClass, locale)}${isAllSpecies ? ` ${formatMessage({ id: `plantingCycles.species.${get(currentFruitClass, 'attributes.species')}` })}` : ''}`;
    }
    case 'Variety': {
      const currentVariety = find(varieties, { id: ref.id });

      const currentFruitClass = find(fruitClasses, { id: get(currentVariety, 'attributes.fruitClass') });

      return `${getMlName(currentVariety, locale)}${isAllSpecies && currentFruitClass ? ` ${formatMessage({ id: `plantingCycles.species.${get(currentFruitClass, 'attributes.species')}` })}` : ''}`;
    }
    case 'ProductGroup': {
      const currentProductGroup = find(productGroups, { id: ref.id });

      let currentProductGroupName;

      if (currentProductGroup) {
        const {
          attributes: {
            fruitClass,
            targetWeight,
            varietyId,
          },
        } = currentProductGroup;

        const currentVariety = find(varieties, { id: varietyId });
        const currentFruitClass = find(fruitClasses, { id: fruitClass });

        currentProductGroupName = getProductTypeName({
          intl,
          targetWeight: targetWeight ? getValue(targetWeight, 0) : null,
          variety: currentVariety,
          fruitClass: currentFruitClass,
          withSpecies: isAllSpecies,
        });
      }

      return currentProductGroupName;
    }
    case 'PlantingCycle': {
      const plantingCycle = find(allPlantingCycles, { id: ref.id });

      const varietyId = get(plantingCycle, 'relationships.variety.data[0].id');
      const fruitClassId = get(plantingCycle, 'relationships.fruitClass.data[0].id');
      const speciesId = get(plantingCycle, 'attributes.species');

      return getPlantingCycleExtendedLabel({
        plantingCycle,
        intl,
        allCompartments: compartments,
        fruitClasses,
        varieties,
        allPlantingCycles,
        currentFruitClassCode: currentFruitClassCode || fruitClassId,
        currentVarietyId: currentVarietyId || varietyId,
        currentSpecies: currentSpecies || speciesId,
        currentBreakdown,
        isPlantingCycleDatesAsTimestamp,
      });
    }
    case 'Compartment':
      return getCompartmentNameById({ compartmentId: ref.id, compartments });
    case 'GasMeter':
      return ref.type;
    case 'ElectricityMeter':
      return ref.type;
    case 'WaterMeter':
      return ref.type;
    case 'WorkType': {
      const workType = find(workTypes?.data || [], { id: ref.id });
      return workType?.mlName?.[locale] || workType?.mlName?.en || ref.type;
    }
    default:
      return '–';
  }
};

