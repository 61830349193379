import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';

import isTouchDevice from 'helpers/isTouchDevice';
import tooltipStyles from 'components/Tooltip/index.module.css';
import InfoIcon from 'components/Icons/InfoIcon';

import styles from './TitleWithInfoIcon.module.css';

const TitleWithInfoIcon = ({ titleText, tooltipText, className }) => {
  const tooltipId = 'text-tooltip-lighting';
  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.title}>{titleText}</div>
      <div
        className={styles.iconWrapper}
        data-tip={tooltipText}
        data-for={tooltipId}
      >
        <InfoIcon />
        <ReactTooltip
          className={classnames(tooltipStyles.smallTooltip, styles.tooltip)}
          html
          id={tooltipId}
          effect='solid'
          event={isTouchDevice() ? 'click' : null}
          isMultiline
          isCapture
        />
      </div>
    </div>
  );
};

TitleWithInfoIcon.propTypes = {
  titleText: PropTypes.object.isRequired,
  tooltipText: PropTypes.object.isRequired,
  className: PropTypes.string,
};

TitleWithInfoIcon.defaultProps = {
  className: null,
};


export default TitleWithInfoIcon;

