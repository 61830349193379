import * as d3 from 'd3';
import sizeMe from 'react-sizeme';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import {
  get, isEqual, throttle, uniqueId
} from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import { formatTicksByMask } from 'helpers/formatTicksByMask';
import ShowMoreButton from 'components/DefaultBreakdown/components/ShowMoreButton';
import { getTicksRange, getTicksMinMax } from '../../helpers/getTicks';

import BreakdownTooltipGraph from '../EnergyDashboard/components/BreakdownTooltipGraph';
import ChartTooltip from '../LinesGraph/components/ChartTooltip';

import CircleLoader from '../CircleLoader';
import loaderStyles from '../CircleLoader/CircleLoader.module.css';

import LINE_COLORS from '../../helpers/graphColors';
import numbersFormatting from '../../helpers/numbersFormatting';
import numbersRounding from '../../helpers/numbersRounding';

import styles from './DefaultBreakdown.module.css';
import tooltipStyles from '../Tooltip/index.module.css';

class DefaultBreakdown extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    items: PropTypes.array,
    className: PropTypes.string,
    roundTo: PropTypes.number,
    barClassNames: PropTypes.string,
    averageValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    valuePath: PropTypes.string,
    labelPath: PropTypes.string,
    tooltipTitle: PropTypes.string,
    emptyTitle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    isDataFetching: PropTypes.bool.isRequired,
    isEmpty: PropTypes.bool.isRequired,
    size: PropTypes.object.isRequired,
    height: PropTypes.number,
    barHeight: PropTypes.number,
    renderTooltipContent: PropTypes.func,
    maxBars: PropTypes.number,
    minHeight: PropTypes.number,
    sortByAsc: PropTypes.bool
  };

  static defaultProps = {
    roundTo: 0,
    className: null,
    barClassNames: null,
    averageValue: null,
    tooltipTitle: null,
    valuePath: 'value',
    labelPath: 'name',
    items: null,
    height: 394,
    minHeight: 394,
    emptyTitle: null,
    barHeight: null,
    renderTooltipContent: null,
    maxBars: 0,
    sortByAsc: false
  };

  chartWrapperRef = null;

  moreButtonRef = null;

  rootRef = null;

  handlerResize = throttle((e, callback) => {
    if (callback) {
      callback();
    } else {
      this.rebuildAxis();
    }
  }, 10);

  constructor(...props) {
    super(...props);

    this.x = d3.scaleLinear();
    this.y = d3.scaleBand();

    this.moreButtonRef = React.createRef();

    this.rootRef = React.createRef();
  }

  state = {
    tooltipId: uniqueId('breakdown-item-label'),
    margin: {
      top: 29,
      bottom: 28,
      left: 90,
      right: 24,
    },
    ticksCount: {
      x: 3,
      y: 12,
    },
    tooltipItem: null,

    screenX: null,
    screenY: null,
    showTooltip: false,
    showMore: true
  };


  componentDidMount() {
    window.addEventListener('resize', this.handlerResize);

    setTimeout(() => {
      this.handlerResize();
    }, 0);

    ReactTooltip.rebuild();
  }

  componentDidUpdate(prevProps) {
    const {
      items: oldItems,
      size: oldSize,
    } = prevProps;

    const {
      items: nextItems,
      size: nextSize,
    } = this.props;

    if (!isEqual(nextItems, oldItems) ||
      oldSize.width !== nextSize.width ||
      oldSize.height !== nextSize.height ||
      oldSize.minHeight !== nextSize.minHeight
    ) {
      this.handlerResize(null, () => {
        this.rebuildAxis();
      });
    }

    ReactTooltip.rebuild();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResize);
  }

  handleClickShowMore = () => {
    this.setState(prevState => ({
      showMore: !prevState.showMore
    }), () => {
      this.rebuild();
    });
  }

  handlerTooltipItemChanged = ({
    screenX,
    screenY,
    tooltipItem,
    showTooltip,
  }) => {
    this.setState({
      screenX,
      screenY,
      tooltipItem,
      showTooltip,
    });
  }

  handlerBarMouseMove = ({
    event,
    width,
    value,
  }) => {
    const rect = event.target.getBoundingClientRect();

    const rawScreenX = (rect.left + Math.round(width / 2)) - 144;

    this.handlerTooltipItemChanged({
      screenX: rawScreenX < 0 ? 78 : rawScreenX,
      screenY: rect.top - 16,
      tooltipItem: value,
      showTooltip: true,
    });
  }

  handlerBarMouseOver = () => {
    this.handlerTooltipItemChanged({
      // showTooltip: true,
    });
  }

  handlerBarMouseOut = () => {
    this.handlerTooltipItemChanged({
      showTooltip: false,
    });
  }

  getCurrentHeight = () => {
    const { height, minHeight } = this.props;
    const { showMore } = this.state;
    return showMore ? minHeight : height;
  }

  getBarsHeight = () => {
    const { barHeight } = this.props;
    const { margin } = this.state;

    const height = this.getCurrentHeight();

    if (barHeight) {
      return barHeight;
    }
    const items = this.getItems();
    if (items) {
      if (items.length > 10) {
        return Math.floor((height - margin.top - margin.bottom) / items.length / 4) * 4;
      }
    }
    return 24;
  };

  getScaleDomain = ({ items, scale, valuePath }) => {
    if (!items || !items.length) {
      scale.domain([0, 100]);
    } else {
      const values = items.map(item => get(item, valuePath, 0));
      const min = 0;
      const max = Math.ceil(d3.max([...values]));

      if ((min === 0 && max === 0) || (Number.isNaN(min) && Number.isNaN(max))) {
        scale.domain(d3.extent([0, 100]));
      } else if (min === max) {
        scale.domain(d3.extent([0, max + max]));
      } else {
        const deltaData = max - min;
        const delta = deltaData || max;

        const maxDelta = Math.ceil(delta * 0.1);

        scale.domain(d3.extent(
          [{ value: 0 }, { value: max + maxDelta }],
          d => d.value,
        ));
      }
    }
  };


  truncateText = (texts) => {
    const { margin } = this.state;

    const padding = margin.left - 16;
    /* eslint-disable func-names */
    // не стрелочная для сохранения контекста this для d3.select
    const trimText = (currentNode) => {
      if (currentNode.node().getComputedTextLength() > padding) {
        const oldText = currentNode.text();
        const newText = oldText.substring(0, oldText.length - 1);

        currentNode.text(newText);

        if (currentNode.node().getComputedTextLength() > padding) {
          return trimText(currentNode);
        }

        return currentNode.text(`${newText}...`);
      }

      return currentNode;
    };

    texts.each(function () {
      const text = d3.select(this);

      return trimText(text);
    });
    /* eslint-disable func-names */
  }

  rebuildAxis = () => setTimeout(this.rebuild, 0);

  rebuild = () => {
    const {
      labelPath,
      valuePath,
      size: {
        width,
      },
    } = this.props;
    const height = this.getCurrentHeight();
    const items = this.getItems();
    const { margin } = this.state;

    this.x.range([0, width - margin.left - margin.right]);
    this.y.range([height - margin.top - margin.bottom, 0]).domain(items.map(item => get(item, labelPath))).align(0.5);


    if (items && items.length) {
      this.getScaleDomain({ items, scale: this.x, valuePath });
    }

    this.renderAxises({ items, height: height - margin.top - margin.bottom });

    this.forceUpdate();

    const newMargin = {
      ...margin,
      bottom: 28,
    };

    this.setState({ margin: newMargin });
  };

  calculateTicksForHorizontalAxis = (xDomain) => {
    const delimeter = this.state.ticksCount.x - 1;
    const min = xDomain[0];
    const max = xDomain[1];

    const ticksRange = getTicksRange(min, max, delimeter);
    const xDomainRange = getTicksMinMax(ticksRange);

    this.x.domain(xDomainRange);

    return ticksRange;
  }

  renderAxises = ({ height, items }) => {
    this.xAxisScale = d3.axisBottom()
      .scale(this.x)
      .tickFormat(formatTicksByMask)
      .tickPadding(18)
      .tickSizeInner(-height)
      .tickSizeOuter(0)
      .tickValues(this.calculateTicksForHorizontalAxis(this.x.domain()));

    this.yAxisScale = d3.axisLeft()
      .scale(this.y)
      .tickFormat(item => item)
      .tickPadding(8)
      .tickSizeInner(0)
      .tickSizeOuter(0)
      .ticks(items ? items.length : 0);

    d3.select(this.axisX).call(this.xAxisScale);

    d3.select(this.axisY).call(this.yAxisScale)
      .selectAll('text')
      .call(this.truncateText)
      .attr('data-for', this.state.tooltipId)
      .attr('data-tip', e => e);
  };

  renderTooltipContent = (currentItem) => {
    const {
      labelPath, valuePath, tooltipTitle, intl: { formatMessage }, roundTo,
    } = this.props;

    const items = this.getItems();

    const item = items.find(x => get(x, labelPath) === currentItem);

    if (!tooltipTitle || !item) {
      return null;
    }

    const rawValue = get(item, valuePath);
    const units = get(item, 'units');

    const generatedLines = [
      {
        id: 'line',
        value: rawValue ? `${numbersFormatting(numbersRounding(rawValue, 'fixed', roundTo))}${units ? ' ' : ''}${units}` : formatMessage({ id: 'crops.noData' }),
        header: get(item, labelPath),
        color: LINE_COLORS[0],
        units: get(item, 'units'),
      },
    ];

    return (
      <BreakdownTooltipGraph
        lines={generatedLines}
        tooltipTitle={tooltipTitle}
      />
    );
  };

  getItems = () => {
    const {
      items,
      maxBars,
      sortByAsc
    } = this.props;
    const {
      showMore
    } = this.state;
    const normalizedMaxBars = maxBars > items.length ? items.length : maxBars;
    const sortedItems = sortByAsc ? [...items].sort((a, b) => a.value - b.value) : items;
    return maxBars > 0 && showMore ? sortedItems.slice(items.length - normalizedMaxBars) : sortedItems;
  }

  renderBars = () => {
    const {
      valuePath,
      labelPath,
      barClassNames,
    } = this.props;
    const items = this.getItems();
    return items.map((item) => {
      const label = get(item, labelPath, 0);
      const value = get(item, valuePath, 0);
      const id = get(item, labelPath, 0);

      let rectWidth = 0;
      const rectX = 0;

      if (value === null || value === 0) {
        rectWidth = 2;
      } else {
        rectWidth = this.x(value) < 2 ? 2 : this.x(value);
      }

      const y = this.y(label) + Math.round((this.y.bandwidth() - this.getBarsHeight()) / 2);

      const valueX = Number.isNaN(rectX) ? 0 : rectX;
      const valueY = Number.isNaN(y) ? 0 : y;

      return (
        <rect
          key={`rect-${id}`}
          className={classnames(styles.bar, barClassNames, {
            [styles.noData]: value === null,
          })}
          y={valueY}
          x={valueX}
          height={this.getBarsHeight()}
          width={rectWidth}
          data-id={label}
          onFocus={() => this.handlerBarMouseOver({ value: label })}
          onMouseOver={() => this.handlerBarMouseOver({ value: label })}
          onBlur={() => this.handlerBarMouseOut({ value: null })}
          onMouseOut={() => this.handlerBarMouseOut({ value: null })}
          onMouseMove={e => this.handlerBarMouseMove({
            x: valueX,
            y: valueY,
            width: rectWidth,
            height: this.getBarsHeight(),
            value: label,
            event: e,
          })}
        />
      );
    });
  };

  renderAvg = () => {
    const {
      intl,
      averageValue,
      size,
    } = this.props;

    const {
      margin,
    } = this.state;

    const propsHeight = this.getCurrentHeight();

    if (!averageValue) {
      return null;
    }

    const { width: propsWidth } = size;
    const { formatMessage } = intl;

    const averageText = numbersFormatting(averageValue);

    const width = propsWidth - margin.left - margin.right;
    const height = propsHeight - margin.top - margin.bottom;

    let rectX = 0;

    if (averageValue === 0) {
      rectX = width - 2;
    } else {
      rectX = width - this.x(averageValue) < 2 ? width - 2 : this.x(averageValue);
    }

    const x = rectX - 8;

    return (
      <g>
        <text
          className={classnames(styles.avgLabel, { [styles.noData]: averageValue === null })}
          x={Number.isNaN(x) ? 8 : x + 8}
          y={-17}
          textAnchor='left'
          data-id='average'
        >
          {formatMessage({ id: 'dashboards.avg' })}&nbsp;<tspan className={styles.avgLabelBold}>{averageText}</tspan>
        </text>
        <line x1={x} x2={x} y1={-29} y2={-13} className={styles.avgTickBold} />
        <line x1={x} x2={x} y1={-13} y2={height} className={styles.avgTick} />
      </g>
    );
  };

  renderDefs = () => (
    <defs>
      <linearGradient id='opacityGradient' x1={0.5} x2={0.5} y2={1}>
        <stop stopColor='#fff' stopOpacity={0} />
        <stop offset={1} stopColor='#fff' stopOpacity={0.9} />
      </linearGradient>
    </defs>
  )

  render() {
    const {
      isDataFetching,
      isEmpty,
      size,
      emptyTitle,
      className,
      renderTooltipContent,
      maxBars,
    } = this.props;

    const {
      margin,
      tooltipItem,
      screenX,
      screenY,
      showTooltip,
      tooltipId,
      showMore
    } = this.state;

    const height = this.getCurrentHeight();

    const { width } = size;
    const buttonWidth = this.moreButtonRef?.current?.clientWidth || 0;
    const containerWidth = this.rootRef?.current?.clientWidth || 0;
    const buttonLeft = (containerWidth / 2) - (buttonWidth / 2);
    return (
      <div
        className={classnames(styles.chart, className)}
        ref={this.rootRef}
      >
        {maxBars > 0 && (
          <ShowMoreButton
            className={styles.showMoreButton}
            containerRef={this.moreButtonRef}
            isExpanded={!showMore}
            onClick={this.handleClickShowMore}
            style={{
              position: 'absolute',
              left: `${buttonLeft}px`,
              bottom: '20px',
            }}
          />
        )}
        <svg width={width} height={height} ref={(element) => { this.chartWrapperRef = element; }}>
          {this.renderDefs()}
          <g transform={`translate(${margin.left},${margin.top})`}>
            <g
              className={styles.axisX}
              transform={`translate(0,${height - margin.top - margin.bottom})`}
              ref={(element) => {
                this.axisX = element;
              }}
            />
            <g
              className={classnames(styles.axisY)}
              ref={(element) => {
                this.axisY = element;
              }}
            />
            {this.renderAvg()}
            <g width={width} height={height}>
              {this.renderBars()}
            </g>
          </g>
          {maxBars > 0 && showMore && (
            <rect
              width={width}
              height={32}
              fill='url(#opacityGradient)'
              transform={`translate(0,${height - margin.top - margin.bottom})`}
            />
          )}
        </svg>

        {!isDataFetching && showTooltip && (
          <ChartTooltip
            screenX={screenX}
            screenY={screenY}
            showTooltip={showTooltip}
            className={styles.tooltip}
            fixedMobilePosition
            isTriangleShow
            isDisabledOffsetX
          >
            {renderTooltipContent ?
              renderTooltipContent(tooltipItem, this.props) : this.renderTooltipContent(tooltipItem)}
          </ChartTooltip>
        )}

        {isDataFetching ? (
          <CircleLoader
            className={loaderStyles.circleLoader}
            iconClassName={loaderStyles.circleLoaderIcon}
          />
        ) : null}

        {isEmpty && emptyTitle ? (
          <div className={styles.emptyState}>
            {emptyTitle}
          </div>
        ) : null}

        <ReactTooltip
          className={tooltipStyles.smallTooltip}
          id={tooltipId}
          effect='solid'
          html
        />
      </div>
    );
  }
}

export default sizeMe()(injectIntl(DefaultBreakdown));
