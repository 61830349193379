/**

 Round matrix
 Minutes    Hours
 1            1
 5            2
 10           3
 15           4
 20           6
 30           8
 45           12

 * @param threshold
 * @param type
 * @returns {Number}
 */
export default function getRoundGraphThreshold({ threshold, type }) {
  if (type === 'hours') {
    if (threshold <= 1) {
      return 1;
    }
    if (threshold <= 2 && threshold > 1) {
      return 2;
    }
    if (threshold <= 3 && threshold > 2) {
      return 3;
    }
    if (threshold <= 4 && threshold > 3) {
      return 4;
    }
    if (threshold <= 6 && threshold > 4) {
      return 6;
    }
    if (threshold <= 8 && threshold > 6) {
      return 8;
    }
    if (threshold > 8) {
      return 12;
    }
  }

  if (type === 'minutes') {
    if (threshold <= 1) {
      return 1;
    }
    if (threshold <= 5 && threshold > 1) {
      return 5;
    }
    if (threshold <= 10 && threshold > 5) {
      return 10;
    }
    if (threshold <= 15 && threshold > 10) {
      return 15;
    }
    if (threshold <= 20 && threshold > 15) {
      return 20;
    }
    if (threshold <= 30 && threshold > 20) {
      return 30;
    }
    if (threshold > 30) {
      return 45;
    }
  }

  return 0;
}
