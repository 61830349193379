import { find, get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router';

import { getLaborWorkTypes } from 'store/labor/selectors';

import DashboardReportBreakdown from './DashboardReportBreakdown';

import { requestEnergyReport, updateEnergyReportSorting } from '../../../../store/energy/actions';

import { getIsEnergyReportFetching } from '../../../../store/energy/selectors';

import {
  getAllFruitClasses,
  getAllPlantingCycles,
  getAllProductGroups,
  getCurrentCompartments,
  getSpeciesFromRoute,
  getVarietiesInLocationWithTargetWeight,
  getIntl,
  getAnyDateOfPeriodStartFromRoute,
  getAnyDateOfPeriodEndFromRoute, getUnitKindFromRoute, getRouteQueryParams,
} from '../../../../store/company/selectors';

import { getPeriodType } from '../../../../store/plans/selectors';
import { getRowName } from '../../../../helpers/getRowName';

const mapDispatchToProps = {
  requestEnergyReport,
  updateEnergyReportSorting,
};

const getGraphKeyId = (state, props) => props.meter.replace('Meter', '');
const getReport = (state, props) => props.report;

const getGraphKey = createSelector(
  [getRouteQueryParams, getGraphKeyId],
  (query, key) => get(query, key, 'total'),
);

const getFruitClassFromProps = (state, props) => props.currentFruitClassCode;
const getBreakdownTypeFromProps = (state, props) => props.currentBreakdown;
const getVarietyIdFromProps = (state, props) => props.currentVarietyId;


const getSelectedRow = createSelector(
  [
    getReport,
    getGraphKey,
  ],
  (report, key) => {
    const rows = get(report, 'rows', []);

    // Чтобы поиск по ключу был так же и в детях (нужно для отображения на графике данных, выбранных в таблице)
    const flatReportRows = rows.reduce((acc, row) => {
      if (row.children) {
        return [...acc, row, ...row.children];
      }
      return [...acc, row];
    }, []);

    return find(flatReportRows, { key });
  },
);

const getBreakdownItems = createSelector(
  [
    getReport,
    getIntl,
    getLaborWorkTypes,
    getCurrentCompartments,
    getAllFruitClasses,
    getVarietiesInLocationWithTargetWeight,
    getAllProductGroups,
    getAllPlantingCycles,
    getBreakdownTypeFromProps,
    getSpeciesFromRoute,
    getFruitClassFromProps,
    getVarietyIdFromProps,
    getSelectedRow,
    getUnitKindFromRoute,
  ],
  (report, intl, workTypes, compartments, fruitClasses, varieties, productGroups, allPlantingCycles, currentBreakdown = 'compartment', currentSpecies, currentFruitClassCode, currentVarietyId, selectedRow, currentUnitKind) => {
    const { formatMessage } = intl;

    const rows = get(report, 'rows', []);

    const seeds = rows
      .reduce((acc, row) => {
        if (row.children && get(selectedRow, 'type') !== 'total') {
          return [...acc, row, ...row.children];
        }
        return [...acc, row];
      }, [])
      .filter((row) => {
        const isTotalSelected = get(selectedRow, 'type') === 'total';
        const isCurrentRowNotTotal = get(row, 'type') !== 'total';

        const isRowSelected = get(selectedRow, 'ref.id') === get(row, 'ref.id');

        return (isTotalSelected || isRowSelected) && isCurrentRowNotTotal;
      })
      .map((row) => {
        let amount;

        if (currentUnitKind === 'absolute') {
          amount = get(row, 'total');
        }
        if (currentUnitKind === 'perArea') {
          amount = get(row, 'byArea');
        }
        if (currentUnitKind === 'perHarvestInKg') {
          amount = get(row, 'byHarvestPerUnit.0');
        }
        if (currentUnitKind === 'perHarvestInCount') {
          amount = get(row, 'byHarvestPerUnit.0');
        }

        const ref = get(row, 'ref');
        const type = get(row, 'type');

        return {
          ref,
          type,
          amount,
        };
      });

    return seeds
      .map((seed) => {
        const units = get(seed, 'amount.units');

        return ({
          label: getRowName({
            ref: get(seed, 'ref'),
            type: get(seed, 'type'),
            intl,
            compartments,
            workTypes,
            fruitClasses,
            varieties,
            productGroups,
            allPlantingCycles,
            currentBreakdown,
            currentSpecies,
            currentFruitClassCode,
            currentVarietyId,
          }),
          value: get(seed, 'amount.amount', 0),
          units: units ? formatMessage({ id: `cunits.mini.${units}` }) : null,
        });
      })
      .sort((a, b) => get(a, 'value') - get(b, 'value'));
  },
);

const mapStateToProps = (state, props) => ({
  breakdownItems: getBreakdownItems(state, props),

  isDataFetching: getIsEnergyReportFetching(state, props),

  anyDateOfPeriodStart: getAnyDateOfPeriodStartFromRoute(state, props),
  anyDateOfPeriodEnd: getAnyDateOfPeriodEndFromRoute(state, props),
  periodType: getPeriodType(state, props),
  currentUnitKind: getUnitKindFromRoute(state, props),
  selectedRow: getSelectedRow(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardReportBreakdown)));
