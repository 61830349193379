import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import {
  get, groupBy, sortBy, map, find
} from 'lodash';
import classnames from 'classnames';

import { getVarietyName } from '../../../../helpers/getVarietyName';
import BigSelectInput from '../../../BigSelectInput';

import selectStyles from '../../../Select/Select.module.css';
import styles from './VarietySelectMobile.module.css';

class VarietySelectMobile extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    onSelectOption: PropTypes.func.isRequired,
    varieties: PropTypes.array.isRequired,
    selectedOption: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
  };

  state = {
    varietySelectOpened: false,
  };

  handlerCloseSelect = () => {
    const { varietySelectOpened } = this.state;

    if (varietySelectOpened) {
      this.setState({ varietySelectOpened: false });
    }
  };

  handlerSelectOption = (species, varietyId) => {
    const { onSelectOption } = this.props;

    onSelectOption(species, varietyId);
    this.handlerCloseSelect();
  }

  handlerSelectState = newState => this.setState({ varietySelectOpened: newState });

  renderAllSpeciesOption = (species) => {
    const { intl: { formatMessage }, selectedOption } = this.props;

    return (
      <div
        key={`speciesOption-${species}`}
        className={classnames(
          styles.option,
          selectStyles.option,
          { [selectStyles.selected]: selectedOption === species }
        )}
        onClick={() => this.handlerSelectOption(species)}
        role='button'
        tabIndex={0}
      >
        {formatMessage({ id: `plantingCycles.allSpecies.${species}` })}
      </div>
    );
  };

  renderVarieties = varieties => varieties.map((variety) => {
    const { intl: { locale }, selectedOption } = this.props;

    const { id } = variety;
    const species = get(variety, 'attributes.species');

    return (
      <div
        key={`varietyOption-${id}`}
        className={classnames(
          styles.option,
          selectStyles.option,
          { [selectStyles.selected]: Number(selectedOption) === Number(id) }
        )}
        onClick={() => this.handlerSelectOption(species, id)}
        role='button'
        tabIndex={0}
      >
        {getVarietyName(variety, locale)}
      </div>
    );
  });

  renderSpecies = (groupedVarieties, formatMessage) => map(groupedVarieties, (varieties, species) => (
    <div key={`speciesOption-${species}`}>
      <div className={styles.speciesName}>{formatMessage({ id: `plantingCycles.speciesPlural.${species}` })}</div>
      <div>
        {this.renderAllSpeciesOption(species)}
        {this.renderVarieties(varieties)}
      </div>
    </div>
  ));


  render() {
    const { intl, varieties, selectedOption } = this.props;
    const { formatMessage, locale } = intl;
    const { varietySelectOpened } = this.state;

    const sortedVarieties = sortBy(varieties, 'attributes.species');
    const groupedVarieties = groupBy(sortedVarieties, 'attributes.species');
    const currentVariety = find(varieties, { id: selectedOption });
    const customTitle = currentVariety ? getVarietyName(currentVariety, locale) : formatMessage({ id: `plantingCycles.allSpecies.${selectedOption}` });

    return (
      <BigSelectInput
        placeholder={formatMessage({ id: 'harvestDashboard.speciesAndVariety' })}
        customTitle={customTitle}
        open={varietySelectOpened}
        onOpenChange={this.handlerSelectState}
        textEllipsis
      >
        <div className={selectStyles.options}>
          {this.renderSpecies(groupedVarieties, formatMessage, locale)}
        </div>
      </BigSelectInput>
    );
  }
}
export default injectIntl(VarietySelectMobile);
