import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getDefaultOpenFromRoute,
  getOrganizationSlug,
} from 'store/company/selectors';
import { trackPageViewSettings } from 'store/company/actions';


import {
  getIncidentTypes,
  getIsIncidentTypesFetching,
} from 'store/incidents/selectors';
import {
  requestIncidentTypeCategories,
  requestIncidentTypes,
  requestUpdateIncidentState,
} from 'store/incidents/actions';

import SettingsIncidentPage from '../components/SettingsIncidentPage';

const mapDispatchToProps = {
  trackPageViewSettings,
  requestIncidentTypeCategories,
  requestIncidentTypes,
  requestUpdateIncidentState,
};

const mapStateToProps = (state, props) => ({
  ...props,

  organizationSlug: getOrganizationSlug(state, props),
  defaultOpen: getDefaultOpenFromRoute(state, props),
  isSettingsFetching: getIsIncidentTypesFetching(state, props),
  incidentTypes: getIncidentTypes(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsIncidentPage)));
