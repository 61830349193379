import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styles from './Policies.module.css';


export default class Policies extends Component {
  render() {
    return (
      <article className={styles.main}>
        <h1>Privacy and Cookie Policy</h1>

        <p>
          Via the website <RouterLink to='https://pylot.app'>https://pylot.app</RouterLink> (hereinafter: the website)
          personal data is collected and cookies are being used. Pylot B.V.
          (hereinafter: Pylot) respects the privacy of the visitors of the website. Therefore, the personal data
          collected by us, is being carefully processed and secured, in accordance with the requirements for the
          processing of personal data laid down in European privacy legislation. In this Privacy and Cookie Policy we
          will inform you about the personal data processing and your privacy.
        </p>
        <br />
        <p>
          In our processing activities, we comply with privacy law requirements. This means, among other things, that we:
        </p>

        <ul type='circle'>
          <li><strong>clearly specify our purposes</strong> before we process personal data, by using this Privacy and
            Cookie Policy;
          </li>
          <li><strong>strive to limit the collection of personal data</strong> to those needed for legitimate purposes
            only;
          </li>
          <li>first <strong>ask for explicit consent</strong> to process your personal data in cases where your consent
            is required;
          </li>
          <li>take <strong>appropriate security measures</strong> to protect your personal data and we demand the same
            from parties who process personal data on our behalf;
          </li>
          <li><strong>respect</strong> your rights regarding your personal data held by us.</li>
        </ul>

        <br />
        <p>
          We are the controller in the data processing. In this Privacy and Cookie Policy, we explain which personal
          data we collect and use, and for what purposes. We recommend that you read it carefully.
        </p>
        <br />

        <p>This Privacy and Cookie Policy was most recently revised on 12/11/2018.</p>


        <h2>Processing of personal data</h2>

        <p>
          By using our website, you leave certain data with us, which may include personal data. We only store and use
          personal data that are provided directly by you or which are clearly provided to us for processing purposes.
          We
          will not use the personal data for any other purposes, unless you have given your prior consent thereto, or
          legislation allows us to do so.
        </p>

        <br />
        <p>In case you use the website, we will process the following personal data:</p>
        <ul type='circle'>
          <li>e-mail address;</li>

          <li>username;</li>

          <li>IP address;</li>

          <li>operating system</li>

          <li>device type</li>

          <li>your region;</li>

          <li>language use;</li>

          <li>pages you visit;</li>
        </ul>
        <br />
        <p>
          We will process this personal data for performance of the agreement, namely to set up and maintain your
          account
          on the website, to give access to the website and your account, to contact you regarding the services you
          requested, to provide the services you requested and to inform you about the services.
        </p>
        <br />
        <p>
          Additionally, by way of an email newsletter, we use your email address to keep you informed of developments
          within the website when you have given your consent to receive these newsletters. An unsubscribe button will
          be
          included in every newsletter. You can also opt out of receiving the newsletter by contacting our help desk and
          informing them that you wish to unsubscribe from the newsletter. In case you unsubscribe for the newsletter,
          we
          will delete your email address from the subscribers list.
        </p>

        <br />

        <p>
          Furthermore, we track and analyse certain information on the basis of your activity on our website. We only
          use this data for internal research to expand our insight into your location, language use, frequency of use,
          pages you visit, and your IP address. This information helps us to improve the product, as well as to
          anticipate and solve potential problems and analyse your browsing behaviour.
        </p>

        <br />

        <p>
          For these purposes, we may use your location data. We ask you separately for your consent for your location
          data and only process this data if you have actually given your consent.
        </p>

        <h2>Cookies</h2>
        <p>
          Cookies are small information files in which we can store information, so that you do not have to fill in that
          information again. We can also use them to see whether you are visiting us again. This is done through the web
          browser on your device (including a PC, tablet or smartphone). The information that can be transmitted by a
          cookie, over the use of our website, can be transferred to the own secured servers of Pylot or to the servers
          of a third party.
        </p>

        <br />

        <p>
          Our website uses cookies. Cookies are placed by us, but also placed via third parties that we engage for our
          processing purposes. We use cookies on our website for the following purposes:
        </p>
        <ul type='circle'>
          <li>to generate overall statistics and gain insight into the use of our website by the public in order to
            optimize our website and services (analytics cookies);
          </li>

          <li>to make the internet more interesting for you by displaying advertisements that fit your interests and to
            personalize the ads and content on our website, based on you interests and click and surf behaviour
            (tracking/targeting/marketing cookies).
          </li>
        </ul>

        <br />

        <p>
          For the above-mentioned purposes, we use the following cookies:
        </p>

        <br />

        <div className={styles.scrollable}>
          <table>
            <thead>
              <tr>
                <th>Cookie&nbsp;party</th>
                <th>Category</th>
                <th>Registered&nbsp;office</th>
                <th>Purpose</th>
                <th>Type</th>
                <th>Expiration</th>
                <th>Privacy&nbsp;statement</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Google Analytics _ga_cid</td>
                <td>Analytical</td>
                <td>USA</td>
                <td>Google Analytics: registers a unique ID in order to generate statistics about the use of the website.
                </td>
                <td>Https-cookie</td>
                <td>Session</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Privacy Statement</a></td>
              </tr>
              <tr>
                <td>Google Analytics _ga</td>
                <td>Analytical</td>
                <td>USA</td>
                <td>Google Analytics: keeps track of the (returning) visitors.</td>
                <td>Https-cookie</td>
                <td>2 years</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Privacy Statement</a></td>
              </tr>
              <tr>
                <td>Google Analytics _gid</td>
                <td>Analytical</td>
                <td>USA</td>
                <td>Google Analytic: makes a distinction between the different kinds of users.</td>
                <td>Https-cookie</td>
                <td>24 hours</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Privacy Statement</a></td>
              </tr>
              <tr>
                <td>Google Analytics _gat</td>
                <td>Analytical</td>
                <td>USA</td>
                <td>Google Analytics: used to throttle request rate.</td>
                <td>Https-cookie</td>
                <td>Session</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Privacy Statement</a></td>
              </tr>
              <tr>
                <td>Google Doubleclick IDE</td>
                <td>Marketing</td>
                <td>USA</td>
                <td>Google Doubleclick: used to optimize advertisements.</td>
                <td>Https-cookie</td>
                <td>1 year</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Privacy Statement</a></td>
              </tr>
              <tr>
                <td>Google Doubleclick DSID</td>
                <td>Marketing</td>
                <td>USA</td>
                <td>Google Doubleclick: used to review your visit in order to show relevant advertisements.</td>
                <td>Https-cookie</td>
                <td>1 week</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Privacy Statement</a></td>
              </tr>
              <tr>
                <td>Amplitude amplitude_id*</td>
                <td>Tracking</td>
                <td>USA</td>
                <td>Amplitude: tracks unique visitors of the website in order to optimize.</td>
                <td>Https-cookie</td>
                <td>10 years</td>
                <td><a href='https://amplitude.com/privacy'>Privacy Statement</a></td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />

        <p>
          The first time you visit our service, we will show you a notification explaining our cookies and ask for your
          consent for the use of these cookies. You can disable the use of cookies through your browser settings, but
          some parts of our website may not work properly as a result of that.
        </p>


        <h2>Intercom</h2>
        <p>
          Our website uses analytical, marketing and tracking/optimization cookies as mentioned above. We also use
          cookies which are set by Intercom that make it possible for the online chat to function. Your browser
          information and conversation data is being collected and saved at servers in the United States. We signed a
          data processing agreement with Intercom. As a result, we have not allowed Intercom to use the information
          obtained through these cookies. We mask as much information as possible. For more information we would like to
          recommend to consult Intercoms <a href='https://www.intercom.com/terms-and-policies#terms'>Privacy Policy</a>.
        </p>


        <h2>Google Analytics and Amplitude</h2>

        <p>
          We use Google Analytics and Amplitude to track visitors on our website and to get reports about how visitors
          use the website. We accepted the data processing agreement from Google. As a result, we have not allowed
          Google to use the information obtained by Analytics for other Google services. We have also signed a data
          processing agreement with Amplitude. We mask as much information as possible. For more information we would
          like to recommend to consult Google and/or Amplitude’s <a href='https://amplitude.com/privacy'>Privacy Policy</a>.
        </p>

        <br />

        <p>
          We have made arrangements with other parties who place cookies through our website. Nevertheless, we cannot
          fully control what they are doing with their cookies, so please read their privacy statements as well. Please
          consult the cookie summary table in this statement for more information.
        </p>


        <h2>Supplying personal data to third parties</h2>

        <p>
          Your personal data can be transferred to and saved by external specialists that are hired by Pylot to process
          data for Pylot and to help deliver the content and the services that you request, such as our IT-suppliers.
          Pylot will take appropriate measures to protect your personal data. Pylot will oblige these external
          specialists to handle your personal data confidentially and to take appropriate technical and organizational
          measures to protect your data against any form of unlawful processing.
        </p>

        <br />

        <p>
          Additionally, your personal data may be revealed to a third party if Pylot is obliged to do so by court order
          or by any other legal or statutory obligation, or if we think that this is necessary to prevent or stop
          illegal activities or violations of our policy.
        </p>


        <h2>Security</h2>

        <p>
          We will use our best ability to protect our services against misuse, abuse and unauthorised access to your
          personal data. We ensure that only authorized people have access to your personal data, and that access to
          your personal data is being controlled.
        </p>


        <h2>Data storage and retention periods</h2>

        <p>
          All personal data supplied or entered by you, is safely stored in a database of Amazon Web Service Europe
          (Ireland). Amazon Web Service is one of the most secure existing cloud services platforms.
        </p>


        <h2>Changes to this privacy and cookie statement</h2>

        <p>
          Whenever this website changes, we have to change our Privacy and Cookie Policy as well. Therefore, please pay
          attention to the date of the last revision at the beginning of this document and check for new versions. We will
          always try to announce changes, apart from the website.
        </p>


        <h2>Your rights</h2>

        <p>
          In case you have any questions about our processing of your personal data or if you want to know what kind of
          personal data we have of you, please can contact us. Please check below for our contact information.
        </p>

        <br />

        <p>
          Based on privacy legislation, you have the following rights:
        </p>

        <ul type='circle'>
          <li>To receive <strong>information</strong> about which personal data we have and what we are using it for;
          </li>

          <li>To <strong>verify</strong> the personal data that we keep for you;</li>

          <li>To have incorrect data <strong>corrected</strong>;</li>

          <li>To <strong>transfer</strong> your personal data;</li>

          <li>To request to <strong>delete</strong> personal data;</li>

          <li>To request for data <strong>restriction</strong>;</li>

          <li>To <strong>revoke</strong> your consent;</li>

          <li>To <strong>object</strong> to certain uses.</li>
        </ul>

        <br />

        <p>
          Please make sure you always provide us with information about who you are, so we can make sure that we pass
          the information to the right person and that we don’t change or remove information from the wrong person.
        </p>


        <h2>File a complaint</h2>

        <p>
          Based on privacy legislation, you have the right to file a complaint about the processing of your personal
          data by us at your national supervisory authority. For the Netherlands, this authority is called the
          Autoriteit Persoonsgegevens. In order to file a complaint about our processing, please contact the supervisory
          authority of your country or the <a href='https://autoriteitpersoonsgegevens.nl'>Autoriteit Persoonsgegevens</a>.
        </p>

        <h2>Contact information</h2>

        <p>
          Pylot B.V.<br />
          Address: Vlotlaan 710, Monster, 2681 TX, The Netherlands<br />
          Chamber of Commerce Number: 69516103<br />
          Email: info@pylot.nl<br />
          Phone number: +31 174 286 161<br />
        </p>

      </article>
    );
  }
}
