import React, {useCallback, useEffect, useMemo} from 'react';
import { uniqueId } from 'lodash';
import classnames from 'classnames';

// @ts-ignore
import moment from 'moment-timezone';

// @ts-ignore
import ReactTooltip from 'react-tooltip';

import tooltipStyles from 'components/Tooltip/index.module.css';
import MessageIcon from 'components/Icons/MessageIcon';
import CloseIcon from 'components/Icons/Close';


import { Incident } from 'store/graphs/types';


import {
  CheckNewIncidentStatus,
  CloseIncidentEditor,
  RequestNewIncidentId,
  SetActiveIncidentId
} from 'store/graphs/actions';


import AbnormalityIncidentButton, { BUTTON_HEIGHT } from './AbnormalityIncidentButton';

import AbnormalityIncidentEditorPopup from '../AbnormalityIncidentEditor/components/AbnormalityIncidentEditorPopup';


import { AxisScaleType } from '../ChartLines/components/ChartLine';

import styles from './index.module.css';

export type OnAction = () => void;

export type AbnormalityIncidentsProps = {
  incidents: Array<Incident>;
  activeIncidentId: number | null;
  width: number;
  height: number;
  leftOffset: number;
  topOffset: number;
  timezone: string;
  xScale: AxisScaleType;
  activeIncidentCollapsed: boolean;
  createdIncident: Incident | null;
  requestNewIncidentId: RequestNewIncidentId;
  closeIncidentEditor: CloseIncidentEditor;
};

export type AbnormalityIncidentsFunc = {
  setActiveIncidentId: SetActiveIncidentId;
  openActiveIncidentPopup: OnAction;
  checkNewIncidentStatus: CheckNewIncidentStatus;
}

const POPUP_WIDTH = 336;

export type AbnormalityIncidentsPopupProps = {
  incident: Incident;
  xScale: AxisScaleType;
  width: number;
  height: number;
  activeIncidentCollapsed: boolean;
  createdIncident: Incident | null;
}

export type AbnormalityIncidentsPopupFunc = {
  onCloseClick: OnAction;
  onOpen: OnAction;
}

const AbnormalityIncidentsPopup = ({
  incident,
  xScale,
  width,
  height,
  activeIncidentCollapsed,
  onCloseClick,
  onOpen,
  createdIncident
}: AbnormalityIncidentsPopupProps & AbnormalityIncidentsPopupFunc) => {
  const {
    // id,
    // severity,
    timeRange: {
      start,
      end
    }
  } = incident || createdIncident;

  const startCoord = xScale(
    moment.tz(start, 'YYYY-MM-DDTHH:mm:ss', 'UTC')
  );
  const endCoord = xScale(
    moment.tz(end, 'YYYY-MM-DDTHH:mm:ss', 'UTC')
  );

  const handleIncidentCollapse = useCallback(() => {
    onCloseClick();
  }, [onCloseClick]);

  const handleOnIncidentClick = useCallback(() => {
    onOpen();
  }, [onOpen]);

  if (!startCoord || !endCoord) {
    return null;
  }

  // Если начальная координата инцидента находится за границей графика, то отрисовываем его по границе
  const isStartDateOutOfRange = startCoord < 0;
  const leftCoord = isStartDateOutOfRange ? 0 : startCoord;
  // Если конечная координата инцидента находится за границей графика, то отрисовываем его по границе
  const isEndDateOutOfRange = endCoord > width;
  const rightCoord = isEndDateOutOfRange ? width : endCoord;
  const isPopupPositionLeft = width - rightCoord < POPUP_WIDTH;

  return (
    <div
      className={classnames(
        styles.abnormalityIncidentOverlay,
        {
          [styles.incidentOverlayStartOutOfRange]: isStartDateOutOfRange,
          [styles.incidentOverlayEndOutOfRange]: isEndDateOutOfRange,
        },
      )}
      style={{
        height,
        left: Math.round(leftCoord),
        width: Math.round(rightCoord - leftCoord),
        bottom: 0,
      }}
    >
      <div
        className={
          classnames(
            styles.abnormalityIncidentControls,
            {
              [styles.leftPosition]: isPopupPositionLeft,
            },
          )
        }
      >
        <button
          type='button'
          className={classnames(
            styles.incidentBtn,
            styles.incidentControlsBtn,
            styles.incidentControlsBtnExpand,
            {
              [styles.active]: !activeIncidentCollapsed
            }
          )}
          onClick={handleOnIncidentClick}
        >
          <MessageIcon />
        </button>
        <button
          type='button'
          className={classnames(styles.incidentBtn, styles.incidentControlsBtn)}
          onClick={handleIncidentCollapse}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

const AbnormalityIncidents = ({
  incidents,
  activeIncidentId,
  setActiveIncidentId,
  activeIncidentCollapsed,
  openActiveIncidentPopup,
  width,
  height,
  leftOffset,
  topOffset,
  timezone,
  xScale,
  createdIncident,
  requestNewIncidentId,
  closeIncidentEditor,
  checkNewIncidentStatus
}: AbnormalityIncidentsProps & AbnormalityIncidentsFunc) => {
  const tooltipId = useMemo(() => `AbnormalityIncidentsPopup-tooltip-${uniqueId()}`, []);

  const activeIncident = activeIncidentId ?
    incidents.find(incident => incident.id === activeIncidentId) : createdIncident;

  const handlerCloseIncidentPopup = useCallback(() => {
    setActiveIncidentId({
      activeIncidentId: null
    });
  }, [setActiveIncidentId]);

  const handlerIncidentButtonClick = (incident: Incident) => {
    setActiveIncidentId({
      activeIncidentId: incident.id
    });
  };

  const handlerOpenIncidentPopup = useCallback(() => {
    openActiveIncidentPopup();
  }, [openActiveIncidentPopup]);

  const handlerCreateAbnormalityIncident = useCallback((p:any) => {
    requestNewIncidentId();
  }, [requestNewIncidentId]);

  const handlerCheckAbnormalityIncident = useCallback(() => {
    checkNewIncidentStatus();
  }, [checkNewIncidentStatus]);

  const handlerOnClosePopupEditorClose = useCallback(() => {
    closeIncidentEditor();
  }, [closeIncidentEditor]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [incidents]);

  return (
    <div
      className={styles.abnormalityIncidentsContainer}
      style={{
        height: BUTTON_HEIGHT,
        width,
        top: (height + topOffset) - BUTTON_HEIGHT,
        left: leftOffset,
      }}
    >
      {activeIncident && (
        <AbnormalityIncidentsPopup
          incident={activeIncident}
          createdIncident={createdIncident}
          width={width}
          height={height}
          xScale={xScale}
          onCloseClick={handlerCloseIncidentPopup}
          onOpen={handlerOpenIncidentPopup}
          activeIncidentCollapsed={activeIncidentCollapsed}
        />
      )}
      {createdIncident && (
        <AbnormalityIncidentEditorPopup
          editedAbnormalityIncident={createdIncident}
          editedAbnormalityIncidentStatus={createdIncident?.status || 'create'}
          getXScaleValue={xScale}
          graphWidth={width}
          graphHeight={height}
          timezone={timezone}
          isCreateAbnormalityIncidentFetching={false}
          isUpdateAbnormalityIncidentFetching={false}
          onCreateAbnormalityIncident={handlerCreateAbnormalityIncident}
          onCheckAbnormalityIncident={handlerCheckAbnormalityIncident}
          onClose={handlerOnClosePopupEditorClose}
        />
      )}
      {incidents.map((incident) => {
        const isActive = incident.id === activeIncidentId;
        return (
          <AbnormalityIncidentButton
            isActive={isActive}
            incident={incident}
            timezone={timezone}
            graphWidth={width}
            tooltipId={tooltipId}
            xScale={xScale}
            onOpenIncident={handlerIncidentButtonClick}
          />
        );
      })}
      <ReactTooltip
        className={tooltipStyles.smallTooltip}
        id={tooltipId}
        effect='solid'
        html
      />
    </div>
  );
};

export default AbnormalityIncidents;
