import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import generateFakeCropsWeeklyData from '../../helpers/generateFakeCropsWeeklyData';

import {
  GET_PLANTING_CYCLE_STARTED,
  GET_PLANTING_CYCLE_SUCCEEDED,
  GET_PLANTING_CYCLE_FAILED,
  GET_PLANTING_CYCLE_ENDED,

  CREATE_PLANTING_CYCLE_STARTED,
  CREATE_PLANTING_CYCLE_SUCCEEDED,
  CREATE_PLANTING_CYCLE_FAILED,
  CREATE_PLANTING_CYCLE_ENDED,

  UPDATE_PLANTING_CYCLE_STARTED,
  UPDATE_PLANTING_CYCLE_SUCCEEDED,
  UPDATE_PLANTING_CYCLE_FAILED,
  UPDATE_PLANTING_CYCLE_ENDED,

  GET_CROPS_METRICS_STARTED,
  GET_CROPS_METRICS_SUCCEEDED,
  GET_CROPS_METRICS_FAILED,
  GET_CROPS_METRICS_ENDED,

  GET_CROPS_OBSERVATIONS_STARTED,
  GET_CROPS_OBSERVATIONS_SUCCEEDED,
  GET_CROPS_OBSERVATIONS_FAILED,
  GET_CROPS_OBSERVATIONS_ENDED,

  CREATE_CROPS_OBSERVATIONS_STARTED,
  CREATE_CROPS_OBSERVATIONS_SUCCEEDED,
  CREATE_CROPS_OBSERVATIONS_FAILED,
  CREATE_CROPS_OBSERVATIONS_ENDED,

  CLEAR_CROPS_OBSERVATIONS,
  RESET_CROPS_OBSERVATIONS,

  requestDeletePlantingCycle,
  receiveDeletePlantingCycle,
} from './actions';


export default function (state = {
  isDeletePlantingCycleFetching: false,

  getPlantingCycleStarted: false,
  getPlantingCycleSucceeded: false,
  getPlantingCycleFailed: false,
  getPlantingCycleEnded: false,
  getPlantingCycleTimestamp: null,
  getPlantingCycleError: null,
  updatedPlantingCycle: null,

  createPlantingCycleStarted: false,
  createPlantingCycleSucceeded: false,
  createPlantingCycleFailed: false,
  createPlantingCycleEnded: false,
  createPlantingCycleTimestamp: null,
  createPlantingCycleError: null,

  updatePlantingCycleStarted: false,
  updatePlantingCycleSucceeded: false,
  updatePlantingCycleFailed: false,
  updatePlantingCycleEnded: false,
  updatePlantingCycleTimestamp: null,
  updatePlantingCycleError: null,

  getCropsMetricsStarted: false,
  getCropsMetricsSucceeded: false,
  getCropsMetricsFailed: false,
  getCropsMetricsEnded: false,
  getCropsMetricsTimestamp: null,
  getCropsMetricsError: null,
  cropsMetrics: null,

  getCropsObservationsStarted: false,
  getCropsObservationsDateStarted: false,
  getCropsObservationsSucceeded: false,
  getCropsObservationsFailed: false,
  getCropsObservationsEnded: false,
  getCropsObservationsTimestamp: null,
  getCropsObservationsError: null,
  cropsObservations: generateFakeCropsWeeklyData(),

  createCropsObservationsStarted: false,
  createCropsObservationsSucceeded: false,
  createCropsObservationsFailed: false,
  createCropsObservationsEnded: false,
  createCropsObservationsTimestamp: null,
  createCropsObservationsError: null,
}, action) {
  const cropsObservations = cloneDeep(state.cropsObservations);

  switch (action.type) {
    case requestDeletePlantingCycle:
      return {
        ...state,
        isDeletePlantingCycleFetching: true,
      };

    case receiveDeletePlantingCycle:
      return {
        ...state,
        isDeletePlantingCycleFetching: false,
      };

    case CLEAR_CROPS_OBSERVATIONS:
      return { ...state, cropsObservations: [], };
    case RESET_CROPS_OBSERVATIONS:
      return { ...state, cropsObservations: generateFakeCropsWeeklyData(), };

    case GET_PLANTING_CYCLE_STARTED:
      return {
        ...state,
        getPlantingCycleStarted: true,
        getPlantingCycleSucceeded: false,
        getPlantingCycleFailed: false,
        getPlantingCycleEnded: false,
        getPlantingCycleTimestamp: null,
        getPlantingCycleError: null,
        updatedPlantingCycle: null,
      };
    case GET_PLANTING_CYCLE_SUCCEEDED:
      return {
        ...state,
        getPlantingCycleSucceeded: true,
        getPlantingCycleFailed: false,
        getPlantingCycleTimestamp: action.timestamp,
        updatedPlantingCycle: action.plantingCycle,
      };
    case GET_PLANTING_CYCLE_FAILED:
      return {
        ...state,
        getPlantingCycleFailed: true,
        getPlantingCycleSucceeded: false,
        getPlantingCycleError: action.error,
        getPlantingCycleTimestamp: action.timestamp,
      };
    case GET_PLANTING_CYCLE_ENDED:
      return {
        ...state,
        getPlantingCycleStarted: false,
        getPlantingCycleEnded: true,
      };

    case CREATE_PLANTING_CYCLE_STARTED:
      return {
        ...state,
        createPlantingCycleStarted: true,
        createPlantingCycleSucceeded: false,
        createPlantingCycleFailed: false,
        createPlantingCycleEnded: false,
        createPlantingCycleTimestamp: null,
        createPlantingCycleError: null,
      };
    case CREATE_PLANTING_CYCLE_SUCCEEDED:
      return {
        ...state,
        createPlantingCycleSucceeded: true,
        createPlantingCycleFailed: false,
        createPlantingCycleTimestamp: action.timestamp,
      };
    case CREATE_PLANTING_CYCLE_FAILED:
      return {
        ...state,
        createPlantingCycleFailed: true,
        createPlantingCycleSucceeded: false,
        createPlantingCycleError: action.error,
        createPlantingCycleTimestamp: action.timestamp,
      };
    case CREATE_PLANTING_CYCLE_ENDED:
      return {
        ...state,
        createPlantingCycleStarted: false,
        createPlantingCycleEnded: true,
      };

    case UPDATE_PLANTING_CYCLE_STARTED:
      return {
        ...state,
        updatePlantingCycleStarted: true,
        updatePlantingCycleSucceeded: false,
        updatePlantingCycleFailed: false,
        updatePlantingCycleEnded: false,
        updatePlantingCycleTimestamp: null,
        updatePlantingCycleError: null,
      };
    case UPDATE_PLANTING_CYCLE_SUCCEEDED:
      return {
        ...state,
        updatePlantingCycleSucceeded: true,
        updatePlantingCycleFailed: false,
        updatePlantingCycleTimestamp: action.timestamp,
      };
    case UPDATE_PLANTING_CYCLE_FAILED:
      return {
        ...state,
        updatePlantingCycleFailed: true,
        updatePlantingCycleSucceeded: false,
        updatePlantingCycleError: action.error,
        updatePlantingCycleTimestamp: action.timestamp,
      };
    case UPDATE_PLANTING_CYCLE_ENDED:
      return {
        ...state,
        updatePlantingCycleStarted: false,
        updatePlantingCycleEnded: true,
      };

    case GET_CROPS_METRICS_STARTED:
      return {
        ...state,
        getCropsMetricsStarted: true,
        getCropsMetricsSucceeded: false,
        getCropsMetricsFailed: false,
        getCropsMetricsEnded: false,
        getCropsMetricsTimestamp: null,
        getCropsMetricsError: null,
      };
    case GET_CROPS_METRICS_SUCCEEDED:
      return {
        ...state,
        getCropsMetricsSucceeded: true,
        getCropsMetricsFailed: false,
        getCropsMetricsTimestamp: action.timestamp,
        cropsMetrics: action.cropsMetrics,
      };
    case GET_CROPS_METRICS_FAILED:
      return {
        ...state,
        getCropsMetricsFailed: true,
        getCropsMetricsSucceeded: false,
        getCropsMetricsError: action.error,
        getCropsMetricsTimestamp: action.timestamp,
      };
    case GET_CROPS_METRICS_ENDED:
      return {
        ...state,
        getCropsMetricsStarted: false,
        getCropsMetricsEnded: true,
      };


    case GET_CROPS_OBSERVATIONS_STARTED:
      if (action.date) {
        const cropsObservationDateIndex = cropsObservations.findIndex(item => item.date === action.date.format('YYYY-MM-DD'));

        if (cropsObservationDateIndex > -1) {
          cropsObservations[cropsObservationDateIndex].isFetching = true;
        }
      }

      return {
        ...state,
        cropsObservations,
        getCropsObservationsStarted: true,
        getCropsObservationsDateStarted: !!action.date,
        getCropsObservationsSucceeded: false,
        getCropsObservationsFailed: false,
        getCropsObservationsEnded: false,
        getCropsObservationsTimestamp: null,
        getCropsObservationsError: null,
      };
    case GET_CROPS_OBSERVATIONS_SUCCEEDED:
      if (action.cropsObservationsDate) {
        const cropsObservationDateIndex = cropsObservations.findIndex(item => item.date === action.cropsObservationsDate.format('YYYY-MM-DD'));

        cropsObservations[cropsObservationDateIndex].isFetching = false;
        cropsObservations[cropsObservationDateIndex].samples = action.cropsObservations.find(item => item.date === action.cropsObservationsDate.format('YYYY-MM-DD')).samples;
      }

      return {
        ...state,
        getCropsObservationsSucceeded: true,
        getCropsObservationsFailed: false,
        getCropsObservationsTimestamp: action.timestamp,
        cropsObservations: action.cropsObservationsDate ? cropsObservations : action.cropsObservations,
      };
    case GET_CROPS_OBSERVATIONS_FAILED:
      return {
        ...state,
        getCropsObservationsFailed: true,
        getCropsObservationsSucceeded: false,
        getCropsObservationsError: action.error,
        getCropsObservationsTimestamp: action.timestamp,
      };
    case GET_CROPS_OBSERVATIONS_ENDED:
      return {
        ...state,
        getCropsObservationsStarted: false,
        getCropsObservationsDateStarted: false,
        getCropsObservationsEnded: true,
      };


    case CREATE_CROPS_OBSERVATIONS_STARTED:
      return {
        ...state,
        createCropsObservationsStarted: true,
        createCropsObservationsSucceeded: false,
        createCropsObservationsFailed: false,
        createCropsObservationsEnded: false,
        createCropsObservationsTimestamp: null,
        createCropsObservationsError: null,
      };
    case CREATE_CROPS_OBSERVATIONS_SUCCEEDED:
      if (action.cropsObservation) {
        if (state.cropsObservations.find(item => item.date === action.cropsObservation.date)) {
          const st = state;
          st.cropsObservations.find(item => item.date === action.cropsObservation.date).samples = (action.cropsObservation.samples || []).filter(item => item.sampleNumber !== '');
        } else {
          if (action.cropsObservation.samples) {
            action.cropsObservation.samples.filter(item => item.sampleNumber !== '');
          }
          state.cropsObservations.push(action.cropsObservation);
        }
      }

      return {
        ...state,
        createCropsObservationsSucceeded: true,
        createCropsObservationsFailed: false,
        createCropsObservationsTimestamp: action.timestamp,
        cropsObservations: state.cropsObservations.sort((a, b) => moment.tz(a.date, action.location.timezone) - moment.tz(b.date, action.location.timezone)),
      };
    case CREATE_CROPS_OBSERVATIONS_FAILED:
      return {
        ...state,
        createCropsObservationsFailed: true,
        createCropsObservationsSucceeded: false,
        createCropsObservationsError: action.error,
        createCropsObservationsTimestamp: action.timestamp,
      };
    case CREATE_CROPS_OBSERVATIONS_ENDED:
      return {
        ...state,
        createCropsObservationsStarted: false,
        createCropsObservationsEnded: true,
      };

    default:
      return state;
  }
}
