/* eslint-disable */

import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import moment from 'moment-timezone';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import classnames from 'classnames';


import styles from './index.module.css';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';

import BigSwitch from '../../../BigSwitch';
import Chart from '../Chart';
import storageWrapper from '../../../../helpers/storageWrapper';

const safeLocalStorage = storageWrapper.get('localStorage');


function getWidth(options) {
  if (options && options.width) {
    return options.width - (window.innerWidth < 720 ? 0 : 25) * 2;
  }

  const diff = window.innerWidth - (window.innerWidth < 720 ? 16 : 25) * 2;

  if (diff >= 1488) {
    return 1440 - 25 * 2 - 25 * 2;
  }

  return diff - (window.innerWidth < 720 ? 0 : 25) * 2;
}

function getBarsCount() {
  const width = window.innerWidth;

  if (width < 480) {
    return 14;
  }

  if (width < 720) {
    return 28;
  }

  if (width < 960) {
    return 42;
  }

  return 56;
}

function prepareWeeklyData(props) {
  return props.metricsWeeklyData ? props.metricsWeeklyData.map(({ value, periodStart }) => {
    const date = moment(periodStart, 'YYYY-MM-DD')
      .isoWeekday(3)
      .hours(12)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);

    return {
      value: value && !Number.isNaN(value) ? value : null,
      date,
    };
  }) : [];
}

function prepareHarvestWeeklyData(props) {
  return props.harvestWeeklyData ? props.harvestWeeklyData.map(({ value, periodStart }) => {
    const date = moment(periodStart, 'YYYY-MM-DD')
      .isoWeekday(3)
      .hours(12)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);

    return {
      value: value && !Number.isNaN(value) ? value : null,
      date,
    };
  }) : [];
}

function getCurrentDate({
  props,
  maxDate,
  minDate
}) {
  const { cycle: { cycle: { plantingDate } }, metricsWeeklyData } = props;

  const threshold = getBarsCount();

  const start = moment(plantingDate, 'YYYY-MM-DD').isoWeekday(1)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);

  if (metricsWeeklyData && prepareWeeklyData(props).length) {
    const currentDate = prepareWeeklyData(props).reduce((reducer, item) => {
      if (item && item.date.isBefore(reducer)) {
        return item.date;
      }
      return reducer;
    }, start);

    if (minDate || maxDate) {
      while (!currentDate.clone().add(threshold, 'weeks').isSameOrAfter(maxDate || minDate.clone().add(threshold, 'weeks'))) {
        currentDate.add(threshold, 'weeks');
      }

      return currentDate;
    }

    return currentDate;
  }

  return start;
}

function getMinDate({ props }) {
  const { cycle: { cycle: { plantingDate } }, metricsWeeklyData } = props;

  const start = moment(plantingDate, 'YYYY-MM-DD').isoWeekday(1)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);

  if (metricsWeeklyData && prepareWeeklyData(props).length) {
    return prepareWeeklyData(props).reduce((reducer, item) => {
      if (item && item.date.isBefore(reducer)) {
        return item.date;
      }
      return reducer;
    }, start);
  }

  return start;
}

function getMaxDate({ props }) {
  const { cycle: { cycle: { endDate, plantingDate } }, metricsWeeklyData } = props;

  // Если конец цикла позже чем сегодняшний день, то отрисовываем шкалу до сегодняшнего дня
  if (moment(endDate, 'YYYY-MM-DD') > moment()) {
    return null;
  }

  if (metricsWeeklyData && prepareWeeklyData(props).length) {
    const normalizeMaxDate = prepareWeeklyData(props)
      .map(item => item.date).reduce(
        (reducer, item) => {
          if (item.isAfter(reducer)) {
            return item;
          }
          return reducer;
        },
        moment(plantingDate, 'YYYY-MM-DD').isoWeekday(1)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
      );

    const preparedMaxDate = normalizeMaxDate ? normalizeMaxDate.isoWeekday(1)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0) : null;

    return endDate ? moment(endDate, 'YYYY-MM-DD').isoWeekday(1).add(1, 'week') : preparedMaxDate;
  }

  return endDate ? moment(endDate, 'YYYY-MM-DD').isoWeekday(1).add(1, 'week') : null;
}

class ChartWrapper extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    selectedMetric: PropTypes.object.isRequired,
    cycle: PropTypes.object,
    cycleId: PropTypes.string,
    location: PropTypes.object,
    selectedMetricId: PropTypes.number,
    leftMetrics: PropTypes.array,
    metricsWeeklyData: PropTypes.array,
    harvestWeeklyData: PropTypes.array,
    showHarvest: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isLeftWeeklyDataFetching: PropTypes.bool.isRequired,
    isRightWeeklyDataFetching: PropTypes.bool.isRequired,
    setShowHarvest: PropTypes.func.isRequired,
    trackClickShowHarvest: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedMetricId: null,
    leftMetrics: [],
    metricsWeeklyData: [],
    harvestWeeklyData: [],
    location: null,
    cycle: null,
    cycleId: null,
  };

  state = {
    preparedWeeklyData: prepareWeeklyData(this.props),
    preparedHarvestWeeklyData: prepareHarvestWeeklyData(this.props),
    currentDate: getCurrentDate({ props: this.props, maxDate: getMaxDate({ props: this.props }), minDate: getMinDate({ props: this.props }) }),
    maxDate: getMaxDate({ props: this.props }),
    minDate: getMinDate({ props: this.props }),
    width: getWidth(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.handlerResize);

    setTimeout(() => {
      this.handlerResize();
    }, 10);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const chunk = {};
    const changedData = !isEqual(prepareWeeklyData(nextProps).map(({ date }) => date.format()), prepareWeeklyData(this.props).map(({ date }) => date.format()));

    if (!isEqual(prepareWeeklyData(nextProps), prepareWeeklyData(this.props))) {
      chunk.preparedWeeklyData = prepareWeeklyData(nextProps);
    }

    if (!isEqual(prepareHarvestWeeklyData(nextProps), prepareHarvestWeeklyData(this.props))) {
      chunk.preparedHarvestWeeklyData = prepareHarvestWeeklyData(nextProps);
    }

    if (this.props.cycleId !== nextProps.cycleId || changedData) {
      const minDate = getMinDate({ props: nextProps });
      const maxDate = getMaxDate({ props: nextProps });

      if (!this.state.currentDate || changedData) {
        chunk.currentDate = getCurrentDate({
          props: nextProps,
          minDate,
          maxDate,
        });
      }

      chunk.maxDate = maxDate;
      chunk.minDate = minDate;
    }

    this.setState(chunk, () => {
      setTimeout(() => {
        this.handlerResize();
      }, 10);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResize);
  }

  getBarsCount = () => {
    const width = window.innerWidth;

    if (width < 480) {
      return 14;
    }

    if (width < 720) {
      return 28;
    }

    if (width < 960) {
      return 42;
    }

    return 56;
  };

  getIsDisabledMove = (direction) => {
    const {
      currentDate,
      minDate,
      maxDate,
    } = this.state;

    if (!currentDate) {
      return true;
    }

    const threshold = this.getBarsCount();

    return direction === 'back' ? currentDate.isSameOrBefore(minDate) : currentDate.clone().add(threshold, 'weeks').isSameOrAfter(maxDate || minDate.clone().add(threshold, 'weeks'));
  };

  handlerResize = throttle(() => {
    if (this.component) {
      const componentStyle = window.getComputedStyle(this.component, null);
      const componentWidth = parseInt(componentStyle.getPropertyValue('width'), 10);

      this.setState({ width: getWidth({ width: componentWidth }) });
    } else {
      this.setState({ width: getWidth() });
    }
  }, 100);

  handlerMove = (direction) => { // back/forward
    const isDisabled = this.getIsDisabledMove(direction);

    if (!isDisabled) {
      const {
        currentDate,
        width,
      } = this.state;

      const threshold = this.getBarsCount({ width });

      let newDate = currentDate.clone();

      if (direction === 'back') {
        newDate = newDate.subtract(threshold, 'weeks');
      } else {
        newDate = newDate.add(threshold, 'weeks');
      }

      this.setState({ currentDate: newDate }, () => this.handlerResize());
    }
  };

  handlerShowHarvest = () => {
    const { showHarvest, setShowHarvest, trackClickShowHarvest } = this.props;
    const toggleState = !showHarvest ? 'Show' : 'Hide';

    trackClickShowHarvest(toggleState);
    setShowHarvest(!showHarvest);
  };

  renderChart = () => {
    const {
      intl,
      selectedMetric,
      selectedMetricId,
      metricsWeeklyData,
      isFetching,
      isLeftWeeklyDataFetching,
      isRightWeeklyDataFetching,
      cycle,
      location,
      leftMetrics,
      showHarvest,
    } = this.props;

    const {
      currentDate,
      minDate,
      maxDate,
      preparedWeeklyData,
      preparedHarvestWeeklyData,
    } = this.state;

    let { width } = this.state;

    if (this.component) {
      const componentStyle = window.getComputedStyle(this.component, null);
      const componentWidth = parseInt(componentStyle.getPropertyValue('width'), 10);

      width = getWidth({ width: componentWidth });
    }

    let wrapperOffsetX = 0;

    if (this.chartWrapper) {
      const chartWrapperRect = this.chartWrapper.getBoundingClientRect();

      wrapperOffsetX = chartWrapperRect.left + (window.innerWidth < 720 ? 0 : 25);
    }

    return (
      <Chart
        leftMetrics={leftMetrics}
        showHarvest={showHarvest}
        preparedWeeklyData={preparedWeeklyData.filter(({ date }) => date.isSameOrBefore(moment()))}
        preparedHarvestWeeklyData={preparedHarvestWeeklyData.filter(({ date }) => date.isSameOrBefore(moment()))}
        currentDate={currentDate}
        maxDate={maxDate}
        minDate={minDate}
        width={width}
        height={128}
        wrapperOffsetX={wrapperOffsetX}
        intl={intl}
        selectedMetric={selectedMetric}
        cycle={cycle}
        location={location}
        selectedMetricId={selectedMetricId}
        metricsWeeklyData={metricsWeeklyData}
        isFetching={isFetching}
        isLeftWeeklyDataFetching={isLeftWeeklyDataFetching}
        isRightWeeklyDataFetching={isRightWeeklyDataFetching}
      />
    );
  };

  render() {
    const { selectedMetric, intl, showHarvest } = this.props;
    const { currentDate } = this.state;

    if (!currentDate) {
      return null;
    }

    const { formatMessage } = intl;

    const locale = safeLocalStorage.getItem('locale');

    const renderedChart = this.renderChart();

    const isBackDisabled = this.getIsDisabledMove('back');
    const isForwardDisabled = this.getIsDisabledMove('forward');

    const name = selectedMetric.attributes.name[locale || 'en'] || `[${selectedMetric.attributes.name.en}]`;

    return (
      <div className={styles.component} ref={(element) => { this.component = element; }}>
        <div className={styles.infoWrapper}>
          <div className={styles.info}>
            <div className={styles.name}>{name}</div>
            {!isBackDisabled || !isForwardDisabled ? (
              <div className={styles.meta}>
                <button
                  className={classnames(styles.walkButtonTop, styles.leftWalkButton, { [styles.disabled]: isBackDisabled })}
                  onClick={() => {
                    if (!isBackDisabled) {
                      this.handlerMove('back');
                    }
                  }}
                >
                  <ArrowIcon className={styles.icon} />
                </button>
                <button
                  className={classnames(styles.walkButtonTop, styles.rightWalkButton, { [styles.disabled]: isForwardDisabled })}
                  onClick={() => {
                    if (!isForwardDisabled) {
                      this.handlerMove('forward');
                    }
                  }}
                >
                  <ArrowIcon className={styles.icon} />
                </button>
              </div>
            ) : null}
            <BigSwitch className={styles.desktopHarvest} value={showHarvest} title={formatMessage({ id: 'crops.showHarvest' })} onClick={this.handlerShowHarvest} />
          </div>
          <div className={styles.mobileInfoAdditional}>
            <BigSwitch className={styles.mobileHarvest} value={showHarvest} title={formatMessage({ id: 'crops.showHarvest' })} onClick={this.handlerShowHarvest} />
          </div>
        </div>
        <div className={styles.chartWrapper} ref={(element) => { this.chartWrapper = element; }}>
          <button
            className={classnames(styles.walkButton, styles.leftWalkButton, { [styles.disabled]: isBackDisabled, [styles.showHarvestMode]: showHarvest })}
            onClick={() => this.handlerMove('back')}
          >
            <ArrowIcon className={styles.icon} />
          </button>
          <div
            className={styles.chart}
            ref={(element) => {
              this.wrapper = element;
            }}
          >
            {renderedChart}
          </div>
          <button
            className={classnames(styles.walkButton, styles.rightWalkButton, { [styles.disabled]: isForwardDisabled, [styles.showHarvestMode]: showHarvest })}
            onClick={() => this.handlerMove('forward')}
          >
            <ArrowIcon className={styles.icon} />
          </button>
        </div>
      </div>
    );
  }
}

export default ChartWrapper;
