import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as RuIcon } from './assets/ru.svg';
import { ReactComponent as EnIcon } from './assets/en.svg';
import { ReactComponent as FrIcon } from './assets/fr.svg';
import { ReactComponent as NlIcon } from './assets/nl.svg';
import { ReactComponent as CnIcon } from './assets/zh-cn.svg';

class Flag extends PureComponent {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  render() {
    const { locale, className } = this.props;

    switch (locale) {
      case 'ru':
        return (<RuIcon className={className} />);
      case 'en':
        return (<EnIcon className={className} />);
      case 'fr':
        return (<FrIcon className={className} />);
      case 'nl':
        return (<NlIcon className={className} />);
      case 'zh-cn':
        return (<CnIcon className={className} />);
      default:
        return '';
    }
  }
}

export default Flag;
