import React, { useCallback, useMemo } from 'react';

import classnames from 'classnames';

import { groupBy } from 'lodash';

// @ts-ignore
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';

import {
  Compartment, FruitClass,
  Metric,
  MetricData,
  MetricDef, NodeComponents, PlantingCycle,
  PresetMetricScale, SpeciesApi,
  SubNode, TreeNodePathComponents, Variety
} from 'store/graphs/types';

import Checkbox from 'components/Checkbox';

import {
  SetPresetMetricSelected,
  SetMetricColor,
  SetMetricToScale,
  ChangeScale,
  DeleteScale,
  SetAllPresetMetricSelected
} from 'store/graphs/actions';

import compareDataNodeMetric from '../../utils/compareDataNodeMetric';
import { buildMetricPath } from '../../utils/metricsGroupUtils';

import ChartTableRow from './components/ChartTableRow';

import styles from './index.module.css';


type ChartTableProp = {
  metricDatas: Array<MetricData>;
  scales: Array<PresetMetricScale>;
  presetMetrics: Array<MetricDef>;
  allMetrics: Array<Metric>;
  allCompartments: Array<Compartment>;
  allSubNodes: Array<SubNode>;
  allPlantingCycles: Array<PlantingCycle>;
  allSpecies: Array<SpeciesApi>;
  allVarieties: Array<Variety>;
  allFruitClasses: Array<FruitClass>;
};

type ChartTableFunc = {
  setPresetMetricSelected: SetPresetMetricSelected;
  setAllPresetMetricSelected: SetAllPresetMetricSelected;
  setMetricToScale: SetMetricToScale;
  setMetricColor: SetMetricColor;
  changeScale: ChangeScale;
  deleteScale: DeleteScale;
};

const HEADER_CELLS = ['selected', 'name', 'scale', 'minimum', 'maximum', 'average'];

const ChartTable = ({
  intl,
  metricDatas,
  scales,
  presetMetrics,
  allMetrics,
  allCompartments,
  allSubNodes,
  allPlantingCycles,
  allSpecies,
  allVarieties,
  allFruitClasses,
  setPresetMetricSelected,
  setMetricColor,
  setMetricToScale,
  changeScale,
  deleteScale,
  setAllPresetMetricSelected
} : ChartTableProp & ChartTableFunc & InjectedIntlProps) => {
  const { formatMessage, locale } = intl;

  const isAllSelected = useMemo(() => presetMetrics.every((item:MetricDef) => item.selected), [presetMetrics]);

  const handlerAllChecked = useCallback(() => {
    setAllPresetMetricSelected({
      selected: !isAllSelected
    });
  }, [setAllPresetMetricSelected, isAllSelected]);

  const handleCheck = useCallback((metric: Array<TreeNodePathComponents>, selected: boolean) => {
    setPresetMetricSelected({
      metric,
      selected
    });
  }, [setPresetMetricSelected]);

  const handleChangeColor = useCallback((metric: Array<TreeNodePathComponents>, color: string) => {
    setMetricColor({
      metric,
      color
    });
  }, [setMetricColor]);

  const handleDeleteScale = useCallback((metric: Array<TreeNodePathComponents>, scale: PresetMetricScale) => {
    if (scale?.name) {
      deleteScale({
        scaleName: scale.name
      });
    }
  }, [deleteScale]);

  const handleChangeScale = useCallback((metric: Array<TreeNodePathComponents>, scale: PresetMetricScale) => {
    changeScale({
      scale
    });
  }, [changeScale]);

  const handleNewScale = useCallback((metric: Array<TreeNodePathComponents>, scale: PresetMetricScale) => {
    setMetricToScale({
      metric,
      scale
    });
  }, [setMetricToScale]);

  const handleSelectScale = useCallback((metric: Array<TreeNodePathComponents>, scale: PresetMetricScale) => {
    setMetricToScale({
      metric,
      scale
    });
  }, [setMetricToScale]);

  const isSingleGroupCheck = useMemo(() =>
    Object.keys(groupBy(presetMetrics, item => [
      item.node[NodeComponents.TreeType],
      item.node[NodeComponents.Node],
      item.node[NodeComponents.SubNode]
    ])).length === 1,
  [presetMetrics]);

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.header}>
          {HEADER_CELLS.map((key:string) => {
            const isCheckbox = key === 'selected';
            const headerText = isCheckbox ? (
              <Checkbox
                className={styles.allCheckbox}
                onChange={handlerAllChecked}
                checked={isAllSelected}
                value='table-show-all'
              />
            ) : key;
            const columnHeaderLocalized = typeof headerText === 'string' ?
              <FormattedMessage id={`graphs.table.${headerText}`} /> : headerText;
            return (
              <th
                key={key}
                className={classnames(styles.headerColumn, {
                  [styles.name]: key === 'name',
                  [styles.checkbox]: isCheckbox,
                  [styles.scale]: key === 'scale',
                  [styles.number]: key === 'maximum' || key === 'minimum' || key === 'average',
                  [styles.main]: key === 'name'
                })}
              >
                {columnHeaderLocalized}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {presetMetrics.map((item:MetricDef, index:number) => {
          const metric = allMetrics.find((metricItem:Metric) => metricItem.id === item.node[NodeComponents.MetricId]);
          const path = buildMetricPath(item, {
            formatMessage,
            locale,
            allCompartments,
            allSubNodes,
            allVarieties,
            allFruitClasses,
            allPlantingCycles
          });
          const groupId = index; // TODO: group id
          const metricData = metricDatas?.find((dataItem:MetricData) =>
            compareDataNodeMetric(dataItem, item)) || null;
          return metric ? (
            <ChartTableRow
              metric={metric}
              path={path}
              presetMetric={item}
              groupId={groupId}
              metricData={metricData}
              isSingleGroupCheck={isSingleGroupCheck}
              scales={scales}
              onChangeColor={handleChangeColor}
              onDeleteScale={handleDeleteScale}
              onChangeScale={handleChangeScale}
              onNewScale={handleNewScale}
              onSelectScale={handleSelectScale}
              onCheck={handleCheck}
            />
          ) : null;
        })}
      </tbody>
    </table>
  );
};

export default injectIntl(ChartTable);
