import { isNil, get } from 'lodash';

/**
 * Отделяет разряды числа пробелом
 * @param {(string || number)} number - Число, которое нужно отформатировать.
 * @param { Object } options Дополнительные настройки
 * @param { number } options.minimumFractionDigits Минимальное используемое количество цифр дробной части числа. Возможными значениями являются значения от 0 до 20; значением по умолчанию для простого и процентного форматирования является 0; значением по умолчанию для форматирования валюты является число цифр младших единиц, определяемое в списке кодов валют ISO 4217 (2, если данный список не предоставляет такой информации).
 * @param { number } options.maximumFractionDigits Максимальное используемое количество цифр дробной части числа. Возможными значениями являются значения от 0 до 20; значением по умолчанию для простого форматирования является наибольшее значение из minimumFractionDigits и 3; значением по умолчанию для форматирования валюты является число цифр младших единиц, определяемое в списке кодов валют ISO 4217 (2, если данный список не предоставляет такой информации); значением по умолчанию для процентного форматирования является наибольшее значение из minimumFractionDigits и 0.
 * @return {string} Отформатированное число.
 */
const numbersFormatting = (number, options = {}) => {
  if (isNil(number) || Number.isNaN(parseFloat(number))) {
    return number;
  }

  const minimumFractionDigits = get(options, 'minimumFractionDigits');
  const maximumFractionDigits = get(options, 'maximumFractionDigits');
  const parsedFloatString = number.toString().replace(/,/g, '.');
  const zeroes = parsedFloatString.split('.')[1];
  const countZeroes = isNil(zeroes) ? 0 : zeroes.length;

  return new Intl
    .NumberFormat('en-US', {
      minimumFractionDigits: minimumFractionDigits || countZeroes,
      maximumFractionDigits: maximumFractionDigits || countZeroes,
    })
    .format(parsedFloatString)
    .replace(/,/g, ' ');
};

export default numbersFormatting;
