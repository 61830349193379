import classnames from 'classnames';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import BigSelectInput from '../BigSelectInput';

import styles from './CustomSelectInput.module.css';

export default class CustomSelectInput extends Component {
  static propTypes = {
    options: PropTypes.array,
    input: PropTypes.object,
    meta: PropTypes.object,
    theme: PropTypes.string,
    placeholder: PropTypes.string,
    placeholderSearchList: PropTypes.string,
    className: PropTypes.string,
    labelPath: PropTypes.string,
    buttonLabelPath: PropTypes.string,
    valuePath: PropTypes.string,
    titlePath: PropTypes.string,
    disabled: PropTypes.bool,
    isSearchable: PropTypes.bool,
    empty: PropTypes.string,
    onInnerChange: PropTypes.func,
    onInnerOpenChange: PropTypes.func,
  };

  static defaultProps = {
    isSearchable: false,
    disabled: false,
    onInnerChange: null,
    onInnerOpenChange: null,
    input: null,
    meta: null,
    placeholder: '',
    placeholderSearchList: '',
    options: [],
    empty: null,
    className: null,
    labelPath: 'title',
    buttonLabelPath: 'title',
    valuePath: 'id',
    titlePath: 'title',
    theme: null,
  };

  render() {
    const {
      input,
      meta,
      theme,
      placeholder,
      placeholderSearchList,
      className,
      options,
      labelPath,
      buttonLabelPath,
      valuePath,
      titlePath,
      disabled,
      isSearchable,
      empty,
      onInnerChange,
      onInnerOpenChange,
    } = this.props;

    const {
      onChange,
      onBlur,
      value,
    } = input;

    const {
      error,
      touched,
    } = meta;

    return (
      <BigSelectInput
        className={classnames(styles.selectWrapper, className)}
        classNameButton={styles.selectButton}
        classNamePopup={styles.selectPopup}
        classNameOption={styles.selectOption}
        error={touched && error ? error : null}
        value={value}
        onChange={(selected) => {
          const { value: innerValue } = selected;
          onChange(innerValue);

          if (onInnerChange) {
            onInnerChange(selected);
          }
        }}
        onOpenChange={(open) => {
          if (onInnerOpenChange) {
            onInnerOpenChange(open);
          }
        }}
        onBlur={(data) => {
          onBlur(data);
        }}
        placeholder={placeholder}
        placeholderSearchList={placeholderSearchList}
        options={options}
        labelPath={labelPath}
        buttonLabelPath={buttonLabelPath}
        valuePath={valuePath}
        titlePath={titlePath}
        disabled={disabled}
        empty={empty}
        isSearchable={isSearchable}
        closeOnChange
        theme={theme}
      />
    );
  }
}
