import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Truncate from 'react-truncate';

import cn from 'classnames';

import Typography from 'components/Typography';
import DefaultSimpleTooltip from 'components/DefaultSimpleTooltip';

import OutsideLinkIcon from 'components/Icons/OutsideLinkIcon';

import styles from './AlertRuleHref.module.css';

const AlertRuleHref = ({
  href,
  text,
  additionalInfo,
  tooltipText,
}) => {
  const [truncated, setTruncated] = useState(false);

  const handlerTruncate = useCallback((isTruncated) => {
    if (truncated !== isTruncated) {
      setTruncated(isTruncated);
    }
  }, [truncated]);

  const fullText = truncated ? text : undefined;
  const Container = href ? NavLink : 'div';

  return (
    <div className={styles.group}>
      <Container
        className={cn(styles.groupContainer, { [styles.withAdditionalInfo]: additionalInfo })}
        to={href}
      >
        <div className={styles.firstColumn}>
          <div className={styles.groupName}>
            <DefaultSimpleTooltip text={fullText}>
              <Typography variant='body1' className={styles.name} bold>
                <Truncate lines={2} onTruncate={handlerTruncate}>
                  {text}
                </Truncate>
              </Typography>
            </DefaultSimpleTooltip>
          </div>
          {additionalInfo && (
            <div className={styles.additionalInfo}>
              {additionalInfo}
            </div>
          )}
        </div>
        <div className={styles.arrowRightIconWrapper}>
          <DefaultSimpleTooltip text={tooltipText} noWrap>
            <OutsideLinkIcon className={styles.arrowRightIcon} />
          </DefaultSimpleTooltip>
        </div>
      </Container>
    </div>
  );
};

AlertRuleHref.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string.isRequired,
  additionalInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  tooltipText: PropTypes.string,
};

AlertRuleHref.defaultProps = {
  href: undefined,
  additionalInfo: undefined,
  tooltipText: undefined,
};

export default memo(AlertRuleHref);
