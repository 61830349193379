import { handleActions } from 'redux-actions';

import {
  setCardsState,
  requestDashboardsData,
  receiveDashboardsData,

  requestHarvestDashboardData,
  receiveHarvestDashboardData,

  requestHarvestGraphData,
  receiveHarvestGraphData,
  requestQualityGraphData,
  receiveQualityGraphData,
  requestBreakdownGraphData,
  receiveBreakdownGraphData,
} from './actions';

const initialState = {
  isDashboardsFetching: false,
  cardsState: {},
  growthEfficiency: null,
  harvestDashboard: null,
};

export default handleActions({
  // Default Dashboard
  [requestDashboardsData](state, action) {
    return {
      ...state,
      isDashboardsFetching: true,
      loadingType: action.payload
    };
  },
  [receiveDashboardsData](state, action) {
    return {
      ...state,
      isDashboardsFetching: false,
      growthEfficiency: action.payload,
      loadingType: null
    };
  },
  [setCardsState](state, action) {
    return { ...state, cardsState: action.payload };
  },

  // Harvest Dashboard
  [requestHarvestDashboardData](state) {
    return {
      ...state,
      isHarvestDashboardFetching: true,
    };
  },
  [receiveHarvestDashboardData](state, action) {
    return {
      ...state,
      isHarvestDashboardFetching: false,
      harvestDashboard: action.payload,
      harvestDashboardBreakdown: action.payload.breakdown,
      harvestDashboardCategories: {
        harvestByCategoriesEnabled: action.payload.harvestByCategoriesEnabled,
        pointsByCategories: action.payload.pointsByCategories,
        totalsByCategories: action.payload.totalsByCategories,
      }
    };
  },
  [requestHarvestGraphData](state) {
    return {
      ...state,
      isHarvestGraphFetching: true,
    };
  },
  [receiveHarvestGraphData](state, action) {
    return {
      ...state,
      isHarvestGraphFetching: false,
      harvestDashboard: action.payload,
    };
  },
  [requestQualityGraphData](state) {
    return {
      ...state,
      isQualityGraphFetching: true,
    };
  },
  [receiveQualityGraphData](state, action) {
    return {
      ...state,
      isQualityGraphFetching: false,
      harvestDashboardCategories: {
        harvestByCategoriesEnabled: action.payload.harvestByCategoriesEnabled,
        pointsByCategories: action.payload.pointsByCategories,
        totalsByCategories: action.payload.totalsByCategories,
      }
    };
  },
  [requestBreakdownGraphData](state) {
    return {
      ...state,
      isBreakdownGraphFetching: true,
    };
  },
  [receiveBreakdownGraphData](state, action) {
    return {
      ...state,
      isBreakdownGraphFetching: false,
      harvestDashboardBreakdown: action.payload.breakdown,
    };
  },
}, initialState);
