import { useEffect } from 'react';
import { isFinite } from 'lodash';
import { bisector, select } from 'd3';

import styles from '../LineChart.module.css';

const bisect = bisector(d => d).left;

function findDots(xValue, linesTraces) {
  return linesTraces.map((trace) => {
    const {
      x,
      y,
      // TODO: need getLineColor helper
      line: {
        color
      }
    } = trace;
    const iLeft = bisect(x, xValue);
    if (iLeft === -1) {
      return null;
    }
    if (iLeft >= x.length) {
      return null;
    }
    const point = y[iLeft];
    if (!isFinite(point)) {
      return null;
    }
    return {
      y: point,
      x: x[iLeft],
      color,
    };
  });
}

function useHoverLineV(groupRef, options) {
  const {
    chartParams: {
      x, y
    },
    height,
    position,
    linesTraces,
    isShowIntersectPoints,
    hidden
  } = options;
  // TODO
  /*
  const nearLinePoints = useMemo(() => {
    return [];
  },[linesTraces]);
  */
  useEffect(() => {
    const group = groupRef.current
      ? select(groupRef.current)
      : null;

    if (group) {
      group
        .selectAll('*')
        .remove();

      if (isFinite(position) && !hidden) {
        group
          .append('line')
          .attr('width', '1')
          .attr('height', height)
          .attr('class', styles.lineY)
          .attr('x1', position)
          .attr('x2', position)
          .attr('y1', '0')
          .attr('y2', height);
      }

      if (isShowIntersectPoints && !hidden) {
        const xValue = x.invert(position);
        const dotsY = findDots(xValue, linesTraces);
        group
          .selectAll(`.${styles.customDataPoint}`)
          .remove();
        for (let i = 0; i < dotsY.length; i += 1) {
          const point = dotsY[i];
          if (point && x(point.x) - position < 2) {
            group
              .append('circle')
              .attr('class', styles.customDataPoint)
              .attr('r', '4')
              .style('fill', point.color)
              .attr('cx', position)
              .attr('cy', y(point.y));
          }
        }
      }
    }
  }, [
    groupRef,
    x, y,
    height,
    position,
    linesTraces,
    isShowIntersectPoints,
    hidden
  ]);
}

export default useHoverLineV;
