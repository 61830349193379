import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import moment from 'moment-timezone';
import classnames from 'classnames';
import { get } from 'lodash';

import CloseIcon from 'components/Icons/Close';
import BigButton from 'components/BigButton';

import getIncidentCoord from 'helpers/getIncidentCoord';

import { ReactComponent as WarningIcon } from './assets/warning.svg';
import { ReactComponent as CheckIcon } from './assets/check.svg';

import AbnormalityIncidentForm from '../AbnormalityIncidentForm';

import styles from './AbnormalityIncidentEditorPopup.module.css';

const POPUP_WIDTH = 510;

class AbnormalityIncidentEditorPopup extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    editedAbnormalityIncident: PropTypes.object.isRequired,
    editedAbnormalityIncidentStatus: PropTypes.object.isRequired,
    getXScaleValue: PropTypes.func.isRequired,
    graphWidth: PropTypes.number.isRequired,
    graphHeight: PropTypes.number.isRequired,
    timezone: PropTypes.string.isRequired,

    isCreateAbnormalityIncidentFetching: PropTypes.bool.isRequired,
    isUpdateAbnormalityIncidentFetching: PropTypes.bool.isRequired,
    onCreateAbnormalityIncident: PropTypes.func.isRequired,
    onCheckAbnormalityIncident: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  handlerClose = () => {
    const { onClose } = this.props;

    onClose();
  };

  handlerSubmit = (form) => {
    const {
      isCreateAbnormalityIncidentFetching,
      isUpdateAbnormalityIncidentFetching,
      onCreateAbnormalityIncident,
      editedAbnormalityIncident,
    } = this.props;

    const {
      category,
      internalComment,
      startDate,
      endDate,
      startTime,
      endTime,
    } = form;

    const [startHour, startMinutes] = startTime.split(':');
    const [endHour, endMinutes] = endTime.split(':');

    const chunk = {
      category: {
        id: category,
        type: 'AbnormalityIncidentCategory',
      },
      internalComment,
      timeRange: {
        start: startDate.hour(startHour)
          .minute(startMinutes)
          .format('YYYY-MM-DDTHH:mm:ss'),
        end: endDate.hour(endHour)
          .minute(endMinutes)
          .format('YYYY-MM-DDTHH:mm:ss'),
      },
    };

    const incidentId = get(editedAbnormalityIncident, 'id');

    if (incidentId) {
      chunk.id = incidentId;
    }

    if (!isCreateAbnormalityIncidentFetching && !isUpdateAbnormalityIncidentFetching) {
      onCreateAbnormalityIncident(chunk);
    }
  };

  handlerCheck = () => {
    const {
      isCreateAbnormalityIncidentFetching,
      isUpdateAbnormalityIncidentFetching,
      onCheckAbnormalityIncident,
    } = this.props;

    if (!isCreateAbnormalityIncidentFetching && !isUpdateAbnormalityIncidentFetching) {
      onCheckAbnormalityIncident();
    }
  };

  renderCreate = () => {
    const {
      intl,
      editedAbnormalityIncident,
    } = this.props;

    const initialValues = {
      startDate: moment.tz(get(editedAbnormalityIncident, 'timeRange.start'), 'YYYY-MM-DDTHH:mm:ss', 'UTC')
        .startOf('day'),
      endDate: moment.tz(get(editedAbnormalityIncident, 'timeRange.end'), 'YYYY-MM-DDTHH:mm:ss', 'UTC')
        .startOf('day'),
      startTime: moment.tz(get(editedAbnormalityIncident, 'timeRange.start'), 'YYYY-MM-DDTHH:mm:ss', 'UTC')
        .format('HH:mm'),
      endTime: moment.tz(get(editedAbnormalityIncident, 'timeRange.end'), 'YYYY-MM-DDTHH:mm:ss', 'UTC')
        .format('HH:mm'),
      internalComment: get(editedAbnormalityIncident, 'internalComment', ''),
      category: get(editedAbnormalityIncident, 'category.id', null),
    };


    return (
      <AbnormalityIncidentForm
        intl={intl}
        initialValues={initialValues}
        onSubmit={this.handlerSubmit}
        onClose={this.handlerClose}
      />
    );
  };

  renderCheck = () => {
    const {
      intl,
      isCreateAbnormalityIncidentFetching,
      isUpdateAbnormalityIncidentFetching,
    } = this.props;

    const { formatMessage } = intl;


    return (
      <>
        <div className={styles.incidentPopupHeaderContainer}>
          <span className={styles.incidentPopupHeader}>
            {formatMessage({ id: 'graphIncidents.createIncidentCheckTitle' })}
          </span>
          <div className={styles.incidentPopupControls}>
            <button
              type='button'
              className={styles.incidentBtn}
              onClick={this.handlerClose}
            >
              <CloseIcon />
            </button>
          </div>
        </div>

        <div className={styles.incidentPopupGroup}>
          <div className={styles.incidentPopupGroupText}>
            <FormattedMessage id='graphIncidents.createIncidentCheckDescription' />
            <ul className={styles.incidentPopupGroupList}>
              <li>
                <FormattedMessage id='graphIncidents.createIncidentCheckDescriptionList1' />
              </li>
              <li>
                <FormattedMessage id='graphIncidents.createIncidentCheckDescriptionList2' />
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.controls}>
          <BigButton
            className={styles.button}
            title={formatMessage({ id: 'dialog.close' })}
            type='button'
            onClick={this.handlerClose}
            theme='light'
          />
          <BigButton
            isLoading={isCreateAbnormalityIncidentFetching || isUpdateAbnormalityIncidentFetching}
            className={styles.button}
            title={formatMessage({ id: 'dialog.check' })}
            type='submit'
            theme='dark'
            onClick={this.handlerCheck}
          />
        </div>
      </>
    );
  };

  renderSuccess = () => {
    const {
      intl,
      isCreateAbnormalityIncidentFetching,
      isUpdateAbnormalityIncidentFetching,
    } = this.props;

    const { formatMessage } = intl;


    return (
      <>
        <div className={styles.incidentPopupHeaderContainer}>
          <span className={styles.incidentPopupHeader}>
            {formatMessage({ id: 'graphIncidents.createIncidentSuccessTitle' })}
          </span>
          <div className={styles.incidentPopupControls}>
            <button
              type='button'
              className={styles.incidentBtn}
              onClick={this.handlerClose}
            >
              <CloseIcon />
            </button>
          </div>
        </div>

        <div className={styles.incidentPopupGroup}>
          <div className={styles.incidentPopupGroupText}>
            <div className={styles.incidentPopupGroupRow}>
              <CheckIcon className={styles.incidentPopupGroupIcon} />
              <FormattedMessage id='graphIncidents.createIncidentSuccessDescription' />
            </div>
          </div>
        </div>

        <div className={styles.controls}>
          <BigButton
            isLoading={isCreateAbnormalityIncidentFetching || isUpdateAbnormalityIncidentFetching}
            className={styles.button}
            title={formatMessage({ id: 'dialog.close' })}
            type='submit'
            theme='dark'
            onClick={this.handlerClose}
          />
        </div>
      </>
    );
  };

  renderWarning = () => {
    const {
      intl,
      isCreateAbnormalityIncidentFetching,
      isUpdateAbnormalityIncidentFetching,
    } = this.props;

    const { formatMessage } = intl;

    return (
      <>
        <div className={styles.incidentPopupHeaderContainer}>
          <span className={styles.incidentPopupHeader}>
            {formatMessage({ id: 'graphIncidents.createIncidentWarningTitle' })}
          </span>
          <div className={styles.incidentPopupControls}>
            <button
              type='button'
              className={styles.incidentBtn}
              onClick={this.handlerClose}
            >
              <CloseIcon />
            </button>
          </div>
        </div>

        <div className={styles.incidentPopupGroup}>
          <div className={styles.incidentPopupGroupText}>
            <div className={styles.incidentPopupGroupRow}>
              <WarningIcon className={styles.incidentPopupGroupIcon} />
              <FormattedMessage id='graphIncidents.createIncidentWarningDescription' />
            </div>
          </div>
        </div>

        <div className={styles.controls}>
          <BigButton
            className={styles.button}
            title={formatMessage({ id: 'dialog.close' })}
            type='button'
            onClick={this.handlerClose}
            theme='light'
          />
          <BigButton
            isLoading={isCreateAbnormalityIncidentFetching || isUpdateAbnormalityIncidentFetching}
            className={styles.button}
            title={formatMessage({ id: 'dialog.check' })}
            type='submit'
            theme='dark'
            onClick={this.handlerCheck}
          />
        </div>
      </>
    );
  };

  render() {
    const {
      editedAbnormalityIncident,
      editedAbnormalityIncidentStatus,
      getXScaleValue,
      graphWidth,
      graphHeight,
      timezone,
    } = this.props;

    let state = null;

    if (editedAbnormalityIncidentStatus === 'create') {
      state = this.renderCreate();
    }

    if (editedAbnormalityIncidentStatus === 'check') {
      state = this.renderCheck();
    }

    if (editedAbnormalityIncidentStatus === 'published') {
      state = this.renderSuccess();
    }

    if (editedAbnormalityIncidentStatus === 'warning') {
      state = this.renderWarning();
    }


    const { startCoord, endCoord } = getIncidentCoord(editedAbnormalityIncident, getXScaleValue, timezone);

    // Если начальная координата инцидента находится за границей графика, то отрисовываем его по границе
    const isStartDateOutOfRange = startCoord < 0;
    const leftCoord = isStartDateOutOfRange ? 0 : startCoord;
    // Если конечная координата инцидента находится за границей графика, то отрисовываем его по границе
    const isEndDateOutOfRange = endCoord > graphWidth;
    const rightCoord = isEndDateOutOfRange ? graphWidth : endCoord;

    const isPopupPositionLeft = graphWidth - rightCoord < POPUP_WIDTH;

    const isPopupPositionTop = leftCoord - POPUP_WIDTH < 0;

    return (
      <div
        className={classnames(
          styles.abnormalityIncidentOverlay,
          {
            [styles.incidentOverlayStartOutOfRange]: isStartDateOutOfRange,
            [styles.incidentOverlayEndOutOfRange]: isEndDateOutOfRange,
          },
        )}
        style={{
          height: graphHeight,
          left: Math.round(leftCoord),
          width: Math.round(rightCoord - leftCoord),
          bottom: 0,
        }}
      >
        <div
          className={
            classnames(
              styles.abnormalityIncidentPopup,
              {
                [styles.leftPosition]: isPopupPositionLeft && !isPopupPositionTop,
                [styles.topPosition]: isPopupPositionTop && isPopupPositionLeft,
              },
            )
          }
        >
          {state}
        </div>
      </div>
    );
  }
}

export default injectIntl(AbnormalityIncidentEditorPopup);
