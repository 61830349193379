import { useEffect } from 'react';

import { axisBottom, select } from 'd3';

const DEFAULT_X_AXIS_PADDING = 15;

function useXAxis(groupRef, options) {
  const {
    chartParams: {
      m, w, h, x,
    },
    xType,
    xTicks,
    isShowVerticalGrid,
    label,
    isRightLabel,
    tickDisplayFormat,
    ticksFunction
  } = options;

  useEffect(() => {
    const group = groupRef.current ? select(groupRef.current) : null;
    if (group) {
      const axis = axisBottom(x);
      const tickPadding = DEFAULT_X_AXIS_PADDING;
      if (isShowVerticalGrid) {
        axis
          .tickSize(-h, 6)
          .tickPadding(tickPadding);
      } else {
        axis
          .tickSize(-5)
          .tickPadding(tickPadding);
      }

      if (ticksFunction) {
        axis
          .ticks(ticksFunction);
      } else if (xTicks) {
        axis.ticks(xTicks);
      }

      if (tickDisplayFormat) {
        axis
          .tickFormat(tickDisplayFormat);
      }

      group
        .call(axis);

      if (label) {
        group
          .append('text')
          .attr('class', 'label')
          .attr('y', m.bottom - 10)
          .attr('x', isRightLabel ? 0 : w)
          .style(
            'text-anchor',
            (isRightLabel)
              ? 'start'
              : 'end'
          )
          .text(label);
      }
    }
  }, [
    groupRef,
    m, w, h, x,
    xType,
    label,
    xTicks,
    isShowVerticalGrid,
    isRightLabel,
    tickDisplayFormat,
    ticksFunction
  ]);
}

export default useXAxis;
