import { createAction } from 'redux-actions';

export const clearEnergyReport = createAction('CLEAR_ENERGY_REPORT');
export const requestEnergyReport = createAction('REQUEST_ENERGY_REPORT');
export const receiveEnergyReport = createAction('RECEIVE_ENERGY_REPORT');
export const updateFiltersEnergyReport = createAction('UPDATE_FILTERS_ENERGY_REPORT');

export const requestEnergyRegistrations = createAction('REQUEST_ENERGY_REGISTRATIONS');
export const receiveEnergyRegistrations = createAction('RECEIVE_ENERGY_REGISTRATIONS');
export const updateEnergyReportSorting = createAction('UPDATE_ENERGY_REPORT_SORTING');

export const requestSaveRegistrations = createAction('REQUEST_SAVE_REGISTRATIONS');
export const receiveSaveRegistrations = createAction('RECEIVE_SAVE_REGISTRATIONS');

export const requestEnergyMeters = createAction('REQUEST_ENERGY_METERS');
export const receiveEnergyMeters = createAction('RECEIVE_ENERGY_METERS');
