import React, { Component, } from 'react';
import { withRouter } from 'react-router';
import { NavLink as RouterLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import classnames from 'classnames';

import storageWrapper from 'helpers/storageWrapper';
import { ANIMATION_DURATION, LANGUAGES } from 'helpers/constants';
import { getDataQualityColor, getIsAlertVisible } from 'helpers/getDataQualityInfo';

import ClickOutside from 'components/ClickOutside';
import Flag from 'components/Flag';
import Avatar from 'components/Avatar';

import styles from './NavigationMenu.module.css';

import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { ReactComponent as ArrowBackIcon } from './assets/arrowBack.svg';
import { ReactComponent as ArrowRightIcon } from './assets/arrowRight.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';

const safeLocalStorage = storageWrapper.get('localStorage');

class NavigationMenu extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    localeChange: PropTypes.func.isRequired,
    userProfile: PropTypes.object,
    isLocationSpecificUrl: PropTypes.bool,
    locations: PropTypes.array,
    locale: PropTypes.string,
    location: PropTypes.object.isRequired,
    organization: PropTypes.object,
    setNavigationMenuState: PropTypes.func.isRequired,
    clearCompanyStore: PropTypes.func.isRequired,
    overallQuality: PropTypes.number,
    isCropCompareAvailable: PropTypes.bool,
    cyclesToCompare: PropTypes.array,
  };

  static defaultProps = {
    isLocationSpecificUrl: false,
    userProfile: null,
    locations: null,
    organization: null,
    locale: 'en',
    overallQuality: null,
    isCropCompareAvailable: false,
    cyclesToCompare: [],
  };

  constructor(props) {
    super(props);

    this.firstLevelRef = React.createRef();
    this.secondLevelRef = React.createRef();
  }

  state = {
    opened: false,
    showSecondLevel: false,
    secondLevelType: null,
    firstLevelHeight: undefined,
    secondLevelHeight: undefined,
  };

  handlerOpenMenu = () => {
    const { opened } = this.state;

    this.toggleMenu(!opened);
    this.hideSecondLevelWithDelay();
  };

  handleClickOutside = () => {
    const { opened } = this.state;

    if (opened) {
      this.toggleMenu(false);
      this.hideSecondLevelWithDelay();
    }
  };

  hideSecondLevelWithDelay = () => {
    setTimeout(() => this.setState({ showSecondLevel: false }), ANIMATION_DURATION);
  }

  toggleMenu = (isOpened) => {
    const { setNavigationMenuState } = this.props;

    this.setState({ opened: isOpened }, () => {
      // Утсанавливаем высоту первого уровня, после того как он появится на экране
      this.setState({ firstLevelHeight: get(this.firstLevelRef, 'current.clientHeight') });
    });
    setNavigationMenuState(isOpened);
  };

  toggleSecondLevel = (type) => {
    const { showSecondLevel } = this.state;

    this.setState({
      showSecondLevel: !showSecondLevel,
      secondLevelType: type,
      secondLevelHeight: get(this.secondLevelRef, 'current.clientHeight'),
    }, () => {
      /*
       * Здесь устанавливаем стейт высоты второго уровня после установки типа второго уровня,
       * нужно чтобы бралась высота уровня меню, которое появится, а не предыдущего
       * первая же установка стейта нужна чтобы анимация началась не к 0, а к какому-либо значению
       */
      this.setState({ secondLevelHeight: get(this.secondLevelRef, 'current.clientHeight') });
    });
  };

  renderOpenButton = ({ userName, locationName }) => (
    <div
      className={styles.openMenuBtn}
      onClick={this.handlerOpenMenu}
      tabIndex={0}
      role='button'
    >
      <Avatar
        className={styles.avatar}
        userName={userName}
      />
      <div className={styles.userInfo}>
        <div className={styles.userName}>
          {userName}
        </div>
        <div className={styles.userLocation}>
          {locationName}
        </div>
      </div>
      <div className={styles.arrow}>
        <ArrowIcon />
      </div>
    </div>
  );

  renderFirstLevel = ({
    userName,
    locationName,
    userEmail,
    organization,
    cyclesToCompare,
  }) => {
    const {
      intl: { formatMessage }, locale, clearCompanyStore, overallQuality, isCropCompareAvailable
    } = this.props;

    const isAlertVisible = getIsAlertVisible(overallQuality);
    const color = getDataQualityColor(overallQuality);

    const isBenchmarkBageVisible = cyclesToCompare?.length > 0;

    return (
      <div className={styles.firstLevel} ref={this.firstLevelRef}>
        <div className={styles.firstLevelHeader}>
          <div>
            {formatMessage({ id: 'header.menu.yourAccount' })}
          </div>
          <div
            className={styles.closeIcon}
            onClick={this.handlerOpenMenu}
            tabIndex={0}
            role='button'
          >
            <CloseIcon />
          </div>
        </div>
        <div className={classnames(styles.menuZone, styles.menuUserInfo)}>
          <Avatar
            className={styles.avatar}
            userName={userName}
          />
          <div className={styles.menuUserData}>
            <div className={styles.menuUserName}>
              {userName}
            </div>
            {userEmail && (
              <div className={styles.menuUserEmail}>
                {userEmail}
              </div>
            )}
            <div className={styles.menuUserLocation}>
              {locationName}
            </div>
          </div>
        </div>

        <ul className={classnames(styles.menuZone, styles.menuList, styles.onlyMobile)}>
          {isCropCompareAvailable && (
            <li
              className={styles.menuItem}
              tabIndex={0}
              role='menuitem'
            >
              <RouterLink
                className={classnames(styles.link, styles.linkWithBage)}
                to={`/${organization.attributes.slug}/crops/compare`}
                onClick={() => { this.toggleMenu(false); }}
              >
                <span>{formatMessage({ id: 'benchmarking.compareTitle' })}</span>
                {isBenchmarkBageVisible && (
                  <span
                    className={classnames(
                      styles.bage,
                      styles.grayBage,
                    )}
                  >
                    {cyclesToCompare?.length}
                  </span>
                )}
              </RouterLink>
            </li>
          )}
          <li
            className={styles.menuItem}
            tabIndex={0}
            role='menuitem'
          >
            <RouterLink
              className={classnames(styles.link, styles.linkWithBage)}
              to={`/${organization.attributes.slug}/settings/data-quality`}
              onClick={() => { this.toggleMenu(false); }}
            >
              <span>{formatMessage({ id: 'settings.dataQuality' })}</span>
              {isAlertVisible && (
                <span
                  className={classnames(
                    styles.bage,
                    {
                      [styles.red]: color === 'red',
                      [styles.yellow]: color === 'yellow',
                      [styles.green]: color === 'green',
                    }
                  )}
                >
                  {`${overallQuality}%`}
                </span>
              )}
            </RouterLink>
          </li>
        </ul>

        <ul className={classnames(styles.menuZone, styles.menuList)}>
          <li
            className={styles.menuItem}
            tabIndex={0}
            role='menuitem'
          >
            <RouterLink
              className={styles.link}
              to={`/${organization.attributes.slug}/settings/profile?defaultOpen=true`}
              onClick={() => { this.toggleMenu(false); }}
            >
              {formatMessage({ id: 'settings.header' })}
            </RouterLink>
          </li>
          <li
            className={styles.menuItem}
            onClick={() => this.toggleSecondLevel('organization')}
            tabIndex={0}
            role='menuitem'
          >
            {formatMessage({ id: 'header.menu.switch' })}
            <div className={styles.arrowRight}>
              <ArrowRightIcon />
            </div>
          </li>
          <li
            className={styles.menuItem}
            onClick={() => this.toggleSecondLevel('language')}
            tabIndex={0}
            role='menuitem'
          >
            <div className={styles.menuFlagWrapper}>
              {formatMessage({ id: 'header.menu.changeLang' })}
              <Flag locale={locale} className={styles.menuFlag} />
            </div>
            <div className={styles.arrowRight}>
              <ArrowRightIcon />
            </div>
          </li>
          <li
            className={styles.menuItem}
            tabIndex={0}
            role='menuitem'
          >
            <RouterLink
              className={styles.link}
              to={`/${organization.attributes.slug}/terms/general-terms-and-conditions`}
              onClick={() => { this.toggleMenu(false); }}
            >
              {formatMessage({ id: 'legal.terms' })}
            </RouterLink>
          </li>
        </ul>

        <RouterLink
          className={styles.logoutLink}
          onClick={() => {
            safeLocalStorage.removeItem('jwt');

            clearCompanyStore();

            if (window.Intercom) {
              window.Intercom('shutdown');
            }
          }}
          to='/login'
        >
          <div className={styles.menuZone}>
            <div className={classnames(styles.logOut, styles.menuItem)}>
              {formatMessage({ id: 'header.menu.logout' })}
            </div>
          </div>
        </RouterLink>
      </div>
    );
  };

  renderSecondLevel = ({ organization, locations, locale }) => {
    const {
      intl: {
        formatMessage,
      },
      location,
      localeChange,
      isLocationSpecificUrl,
    } = this.props;

    const { secondLevelType } = this.state;
    const locationId = get(organization, 'id');
    let menuItems;
    let headerText;

    switch (secondLevelType) {
      case 'language':
        headerText = formatMessage({ id: 'header.menu.changeLang' });
        menuItems = LANGUAGES.map(item => (
          <li
            key={`language-${get(item, 'locale')}`}
            className={classnames(
              styles.secondLevelMenuItem,
              styles.langMenuItem,
              { [styles.active]: locale === get(item, 'locale') }
            )}
            role='menuitem'
            onClick={() => localeChange(get(item, 'locale'))}
          >
            <div className={styles.flag}>
              <Flag locale={get(item, 'locale')} />
            </div>
            {get(item, 'localeName')}
          </li>
        ));
        break;
      default:
        headerText = formatMessage({ id: 'header.menu.switch' });
        menuItems = locations.map((item) => {
          const { id } = item;
          const pathname = location.pathname.split('/').filter(x => x);
          const path = isLocationSpecificUrl ? pathname[1] : pathname.slice(1).join('/');


          return (
            <li
              key={`location-${id}`}
              className={classnames(styles.secondLevelMenuItem, { [styles.active]: locationId === id })}
              role='menuitem'
            >
              <a className={styles.link} href={`/${item.attributes.slug}/${path}`}>
                {get(item, 'attributes.name')}
              </a>
            </li>
          );
        });
        break;
    }

    return (
      <div className={styles.secondLevelContent}>
        <div className={styles.secondLevelHeader}>
          <div
            className={styles.arrowBack}
            onClick={() => this.toggleSecondLevel(secondLevelType)}
            tabIndex={0}
            role='button'
          >
            <ArrowBackIcon />
          </div>
          {headerText}
        </div>
        <ul className={styles.secondLevelMenu}>
          {menuItems}
        </ul>
      </div>
    );
  };

  render() {
    const {
      userProfile, locations, organization, locale, cyclesToCompare
    } = this.props;
    const {
      opened, showSecondLevel, firstLevelHeight, secondLevelHeight
    } = this.state;

    const userName = get(userProfile, 'attributes.name');
    const userEmail = get(userProfile, 'attributes.email');
    const locationName = get(organization, 'attributes.name');

    /*
     * Установка высоты меню вручную нужна для создания анимации перехода ко второму уровню
     * такие сложности нужны т.к. меню не фиксированно ни по высоте, ни по ширине и эти параметры
     * изменяются в зависимости от контента
     */
    const menuContentStyles = { height: showSecondLevel ? secondLevelHeight : firstLevelHeight };

    return (
      <ClickOutside
        handleClickOutside={this.handleClickOutside}
      >
        <div className={styles.navigation}>
          {this.renderOpenButton({ userName, locationName })}

          <CSSTransition
            in={opened}
            timeout={ANIMATION_DURATION}
            unmountOnExit
            classNames={{
              enter: styles.menuEnter,
              enterActive: styles.menuEnterActive,
              exit: styles.menuLeave,
              exitActive: styles.menuLeaveActive,
            }}
          >
            <div
              className={styles.menu}
            >
              <div
                style={menuContentStyles}
                className={classnames(styles.menuContent, { [styles.secondLevelActive]: showSecondLevel })}
              >
                {this.renderFirstLevel({
                  userName,
                  locationName,
                  userEmail,
                  organization,
                  cyclesToCompare,
                })}

                <div className={styles.secondLevel} ref={this.secondLevelRef}>
                  {this.renderSecondLevel({ organization, locations, locale })}
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
      </ClickOutside>
    );
  }
}

export default withRouter(injectIntl(NavigationMenu));
