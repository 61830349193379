import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';

import classnames from 'classnames';

import isTouchDevice from 'helpers/isTouchDevice';

import styles from './ChartTooltip.module.css';

const defaultOffsetX = 16;
const defaultOffsetY = 0;

const ChartTooltip = ({
  children,
  showTooltip,
  fixedMobilePosition,
  screenX,
  screenY,
  className,
  isTriangleShow,
  chartParams
}) => {
  const nodeRef = useRef(null);
  const isTouch = isTouchDevice();

  const { leftPosition, topPosition } = useMemo(() => {
      const tooltipRect = nodeRef?.current?.getBoundingClientRect();
      const dx = chartParams.canvas.leftPadding || 0;
      const dy = chartParams.canvas.topPadding || 0;
      let leftPos = screenX + dx;
      let topPos = screenY + dy;
      if (tooltipRect) {
        // TODO: Check normalize coords. Maybe need refactoring styles (remove padding)
        if (screenX + tooltipRect.width > chartParams.w - chartParams.canvas.leftPadding) {
          leftPos -= tooltipRect.width + defaultOffsetX;
        } else {
          leftPos += defaultOffsetX;
        }
        if (screenY + tooltipRect.height > chartParams.h - chartParams.canvas.topPadding) {
          topPos -= tooltipRect.height - defaultOffsetY - (tooltipRect.height / 2);
        } else {
          topPos -= defaultOffsetY + (tooltipRect.height / 2);
        }
      }
      return {
        leftPosition: leftPos,
        topPosition: topPos,
      };
  },
   [
    screenX,
    screenY,
    nodeRef,
    chartParams
  ]);

  const fixedTooltip = fixedMobilePosition && isTouch;

  const triangleLeft = 0;

  const tooltipStyle = useMemo(() => {
    const tooltipStyleDef = {
      left: leftPosition,
      top: topPosition,
      position: 'absolute'
    };
    if (!showTooltip) {
      tooltipStyleDef.display = 'none';
    }
    return tooltipStyleDef;
  }, [leftPosition, topPosition, showTooltip]);

  return children ? (
    <div
      style={tooltipStyle}
      className={classnames(
        styles.tooltip,
        { [styles.fixedTooltip]: fixedTooltip },
        className
      )}
      ref={nodeRef}
    >
      {children}
      {(fixedTooltip || isTriangleShow) && (
        <div className={styles.triangle} style={{ left: triangleLeft }} />
      )}
    </div>
  ) : null;
};

ChartTooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
  showTooltip: PropTypes.bool,
  screenX: PropTypes.number,
  screenY: PropTypes.number,
  fixedMobilePosition: PropTypes.bool,
  isTriangleShow: PropTypes.bool,
  chartParams: PropTypes.object
};

ChartTooltip.defaultProps = {
  className: null,
  showTooltip: false,
  screenX: null,
  screenY: null,
  children: null,
  fixedMobilePosition: false,
  isTriangleShow: false,
  chartParams: null
};

export default ChartTooltip;
