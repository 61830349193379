import getDatesRange from './getDatesRange';
import getRoundGraphThreshold from './getRoundGraphThreshold';
/**

 Max chart range: 732 days
 Safe dots num: 802

 Time details types:
 1. N minutes => Formula 1
 2. N hours => Formula 2
 3. 1 day
 4. 1 week
 5. 1 month

 Formulas:
 1. Max details minutes = ((Full days * 24 * 60) / Safe dots num) => Round to bigger closest minute from matrix

 2. Max details hours = ((Full days * 24) / Safe dots num) => Round to bigger closest hour from matrix

 Round matrix
 Minutes    Hours
 1            1
 5            2
 10           3
 15           4
 20           6
 30           8
 45           12

 Types of custom range details tab
 1. N min – N min – N min
 2. N min – N min – 1 hr
 3. N min — 1 hr — 1 day
 4. N hr — 1 day - 1 week
 5. 1 day - 1 week - 1 month

 Approximate logic for time details:

 if (days = 1)
 1 min / 5 min / 30 min
 if (2 ≤ days ≤ 5)
 N2 = N1*3
 N1 min / N2 min / 1 hr
 if (6 ≤ days ≤ 25)
 N min / 1 hr / 1 day
 if (26 ≤ days ≤ 401)
 N hr / 1 day / 1 week
 if (402 ≤ days ≤ 732)
 1 day / 1 week /1 month
 if (days > 732)
 System message: Max period is 732 days at a time.

 *This is not an actual code how it should be written. If need days can be not constants. Basically, when we see that we reach top of units round like 45 minutes, we swith to next dimension with hours.

 Example on last if
 802 safe dots * 12 hours / 24 = 401 full day covered with 12 hours => After this from "N hr — 1 day - 1 week" we move to "1 day - 1 week - 1 month".

 * @param xRange
 * @param xRangeEnd
 * @param xRangeLengthInMins
 * @param location
 * @param safeDotNumber
 * @returns {*}
 */
export default function getGraphThreshold({
  xRange,
  xRangeEnd,
  xRangeLengthInMins,
  location,
  safeDotNumber = 802,
}) {
  const { xTo, xFrom } = getDatesRange({
    xRange,
    xRangeEnd,
    xRangeLengthInMins,
    location,
  });

  const diffDays = Math.ceil(xTo.add(1, 'minutes').diff(xFrom, 'minutes') / 60 / 24);
  const diffHours = diffDays * 24;
  const diffMinutes = diffDays * 24 * 60;

  const maxDetailsHours = Math.ceil(diffHours / safeDotNumber);
  const maxDetailsMinutes = Math.ceil(diffMinutes / safeDotNumber);

  if (diffDays <= 1) {
    return 1;
  }

  if (diffDays <= 2) {
    return 5;
  }

  if (diffDays > 2 && diffDays <= 5) {
    return getRoundGraphThreshold({ threshold: maxDetailsMinutes, type: 'minutes' });
  }

  if (diffDays === 7) {
    return 15;
  }

  if (diffDays >= 6 && diffDays <= 25) {
    return getRoundGraphThreshold({ threshold: maxDetailsMinutes, type: 'minutes' });
  }

  if (diffDays >= 26 && diffDays <= 401) {
    return getRoundGraphThreshold({ threshold: maxDetailsHours, type: 'hours' }) * 60;
  }

  if (diffDays >= 402 && diffDays <= 732) {
    return 24 * 60;
  }

  if (diffDays > 732) {
    return 0;
  }

  return 0;
}
