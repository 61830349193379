import history from '../../history';

/**
 * Обновляет урл. Функция с сайдэффектом, не возвращает ничего.
 *
 * @param {string} route - Новый роут.
 */
const historyPush = route => history.push(route);

export default historyPush;
