import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import queryString from 'query-string';
import { get } from 'lodash';
import { push } from 'connected-react-router';

import addGetParameters from 'helpers/addGetParameters';

import { getSelectedRow } from 'store/dashboards/selectors';

import initAxiosInstanse from '../../api/axios';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

import {
  requestEnergyReport,
  receiveEnergyReport,

  requestEnergyRegistrations,
  receiveEnergyRegistrations,

  requestSaveRegistrations,
  receiveSaveRegistrations,

  requestEnergyMeters,
  receiveEnergyMeters,

  updateFiltersEnergyReport,
} from './actions';

const METER_READING_ERROR_CODE = 'MeterReadingMustBeLargerOrEqualToThePreviousReading';

export const updateFiltersEnergyReportEpic = action$ =>
  action$.ofType(updateFiltersEnergyReport)
    .switchMap(actionData => Observable.of(requestEnergyReport(actionData.payload)));

export const getEnergyReportEpic = (action$, store) =>
  action$.ofType(requestEnergyReport)
    .switchMap((actionData) => {
      const state = store.getState();

      const {
        router,
        company,
      } = state;

      const {
        location,
      } = company;

      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          meterType,
          periodType,
          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
          breakdown,
          plantingCycleId,
          compartmentId,
          unitKind,
          species,
          varietyId,
          fruitClassCode,
        }
      } = actionData;

      const filteredProductGroups = [[species, fruitClassCode, varietyId].filter(x => x)].filter(item => item && item.length > 1).map(item => item.join('_'));

      const queryParams = queryString.stringify({
        meterType,
        periodType,
        anyDateOfPeriodStart,
        anyDateOfPeriodEnd,
        breakdown,
        productGroup: filteredProductGroups && filteredProductGroups.length ? filteredProductGroups : undefined,
        plantingCycleId,
        compartmentId: compartmentId ? compartmentId.filter(x => +x) : compartmentId,
        species,
        unitKind,
      });

      const query = queryParams || '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`energy/${organizationId}/report?${query}`))
        .mergeMap(({ data }) => {
          const actions = [];
          const newParams = {};

          meterType.forEach((meter) => {
            const graphKeyId = meter.replace('Meter', '');

            const selectedRow = getSelectedRow(state, {
              graphKeyId,
              report: get(data, meter, {}),
              location: router.location,
            });

            if (!selectedRow) {
              newParams[graphKeyId] = undefined;
            }
          });

          if (Object.keys(newParams).length) {
            actions.push(push({
              search: addGetParameters(searchParams, newParams)
            }));
          }

          return Observable.of(
            receiveEnergyReport({ energyReport: data }),
            ...actions,
          );
        })
        .catch(() => Observable.of(
          receiveEnergyReport(),
          showNotificationWithTimeout({
            id: `notifications.getEnergyReportError.${Date.now()}`,
            messageId: 'notifications.getEnergyReportError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ))
        .takeUntil(action$.ofType(updateFiltersEnergyReport));
    });

export const getEnergyRegistrationsEpic = (action$, store) =>
  action$.ofType(requestEnergyRegistrations)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          meterType,
          startDate,
          endDate,
        }
      } = actionData;

      const queryParams = queryString.stringify({
        meterType,
        startDate,
        endDate,
      });

      const query = queryParams || '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`energy/${organizationId}/registrations?${query}`))
        .map(({ data }) => receiveEnergyRegistrations({ energyRegistrations: data }))
        .catch(() => Observable.of(
          receiveEnergyRegistrations(),
          showNotificationWithTimeout({
            id: `notifications.getEnergyRegistrationsError.${Date.now()}`,
            messageId: 'notifications.getEnergyRegistrationsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });


export const saveRegistrationsEpic = (action$, store) =>
  action$.ofType(requestSaveRegistrations)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          registrations,
          actionAfterSuccess,
        }
      } = actionData;

      const searchParams = get(router, 'location.search');


      return Observable
        .from(initAxiosInstanse(searchParams).post(`/energy/${organizationId}/registrations`, registrations))
        .mergeMap(() => Observable.of(
          actionAfterSuccess,
          receiveSaveRegistrations(),
        ))
        .catch((error) => {
          const errorClass = get(error, 'response.data.errorClass');
          const messageId = errorClass === METER_READING_ERROR_CODE ? 'energy.formErrors.meterReading' : 'energy.saveEnergyRegistrationsError';

          return Observable.of(
            receiveSaveRegistrations(),
            showNotificationWithTimeout({
              id: `notifications.saveEnergyRegistrationsError.${Date.now()}`,
              messageId,
              position: 'leftDown',
              iconType: 'error',
              notificationType: 'withActionWide',
            }),
          );
        });
    });


export const getEnergyMetricEpic = (action$, store) =>
  action$.ofType(requestEnergyMeters)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/energy/${organizationId}/meters`))
        .map(({ data }) => receiveEnergyMeters({ meters: data }))
        .catch(() => Observable.of(
          receiveEnergyMeters(),
          showNotificationWithTimeout({
            id: `notifications.getEnergyMetricError.${Date.now()}`,
            messageId: 'notifications.getEnergyMetricError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });


export default combineEpics(
  getEnergyReportEpic,
  getEnergyRegistrationsEpic,
  saveRegistrationsEpic,
  getEnergyMetricEpic,
  updateFiltersEnergyReportEpic,
);
