import getDatesRange from './getDatesRange';

export default function getDatesRangeDiff({
  location,
  xRange,
  xRangeEnd,
  xRangeLengthInMins,
}) {
  switch (xRange) {
    case 'today': {
      return { diff: 1, type: 'days' };
    }
    case 'last2Days': {
      return { diff: 2, type: 'days' };
    }
    case 'last7Days': {
      return { diff: 7, type: 'days' };
    }
    case 'last30Days': {
      return { diff: 30, type: 'days' };
    }
    case 'last90Days': {
      return { diff: 90, type: 'days' };
    }
    default: {
      const { xTo, xFrom } = getDatesRange({
        location,
        xRange,
        xRangeEnd,
        xRangeLengthInMins,
      });

      const xToReal = xTo.add(1, 'minutes');
      const diff = xToReal.diff(xFrom, 'minutes');

      return { diff, type: 'minutes' };
    }
  }
}
