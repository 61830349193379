import React, {
  ReactElement,
  useCallback,
  useEffect, useMemo,
  useRef,
  useState,
} from 'react';

// @ts-ignore
import ReactTooltip from 'react-tooltip';

import { flatMapDeep, uniqueId } from 'lodash';

// @ts-ignore
import Tree, { TreeNodeProps } from 'rc-tree';
// @ts-ignore
import { EventDataNode, DataNode } from 'rc-tree/lib/interface';

import classnames from 'classnames';

// @ts-ignore
import { injectIntl, InjectedIntlProps } from 'react-intl';

import { parseDate } from 'helpers/datesHelper';

import {
  Compartment,
  FruitClass,
  Metric,
  MetricDef,
  PlantingCycle,
  SubNode,
  TreeNode,
  TreeNodePathComponents,
  Variety
} from 'store/graphs/types';

import tooltipStyles from 'components/Tooltip/index.module.css';

import {
  ITreeSearchData, RequestMetricsTree, SetLoadingNodeKey, SetTreeExpandedKeys, TogglePresetMetric
} from 'store/graphs/actions';

import CompleteIcon from 'components/Icons/Complete';

import { ReactComponent as SquareIcon } from './assets/square.svg';
import { ReactComponent as DataIcon } from './assets/data.svg';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { ReactComponent as LoaderIcon } from './assets/loader.svg';

import getTreeIdByType from '../../../../utils/getTreeIdByType';
import getTreeNodeTitle from '../../../../utils/getTreeNodeTitle';
import buildTreeNodeKey from '../../../../utils/buildTreeNodeKey';
import getTreeById from '../../../../utils/getTreeById';

import { MetricGroupsView } from '../../../../utils/metricsGroupUtils';


import styles from './PanelMetricTree.module.css';

import './tree.css';
import findMetricsTreeNode from '../../../../../../helpers/graphs/findMetricsTreeNode';

// import { useWhatChanged } from "@simbathesailor/use-what-changed";
// export type OnExpandItemFunc = (metricNode: Array<TreeNodePathComponents>) => void;

export type PanelMetricTreeProps = {
  selected: boolean;
  treeTypeId: string;
  metricsTree: TreeNode | null;
  requestMetricsTree: RequestMetricsTree;
  allCompartments: Array<Compartment>;
  allMetrics: Array<Metric>;
  allSubNodes: Array<SubNode>;
  allVarieties: Array<Variety>;
  allFruitClasses: Array<FruitClass>;
  allPlantingCycles: Array<PlantingCycle>;
  metricsSearchData: ITreeSearchData | null;
  togglePresetMetric: TogglePresetMetric;
  metricGroups: MetricGroupsView;
  // expandItem: React.RefObject<OnExpandItemFunc>;
  expandedKeys: Array<string | number>;
  setTreeExpandedKeys: SetTreeExpandedKeys;
  setLoadingNodeKey: SetLoadingNodeKey;
  loadingNodeKey: string | number | undefined;
};

type KeyType = string | number;

const TreeIcon = ({ icon } : { icon: ReactElement }) => (<span className={styles.treeIcon}>{icon}</span>);

const TreeTitle = ({
 formatMessage, title, unitsText, isComplete
}: { formatMessage: Function, title: string; unitsText?: string, isComplete?: boolean }) => {
  const titleRef = useRef<any>(null);
  const tooltipId = `tree-title-tooltip-${uniqueId()}`;
  const tooltipCompleteId = `tree-title-tooltip-complete-${uniqueId()}`;
  return (
    <span className={classnames(styles.treeTitle)}>
      <span
        className={styles.titleText}
        data-tip=''
        data-for={tooltipId}
        ref={titleRef}
      >
        {title}
      </span>
      {unitsText && <span className={styles.units}>{unitsText}</span>}
      {isComplete && (
        <span
          className={styles.complete}
          data-tip={formatMessage({ id: 'graphs.completedCycleTitle' })}
          data-for={tooltipCompleteId}
        >
          <CompleteIcon />
          <ReactTooltip
            className={classnames(tooltipStyles.smallTooltip)}
            id={tooltipCompleteId}
            effect='solid'
            html
          />
        </span>
      )}
    </span>
  );
};

TreeTitle.defaultProps = {
  unitsText: undefined,
  isComplete: false,
};

function isCompleteCycle(
  node: TreeNode,
  allPlantingCycles: Array<PlantingCycle>,
) {
  if (node.entity.type !== 'PlantingCycle') {
    return false;
  }

  // @ts-ignore // TODO: Fix ID types
  const cycle = allPlantingCycles.find(item => item.id === node.entity.id);

  if (!cycle) {
    return false;
  }

  // @ts-ignore
  return (cycle?.attributes?.endDate && parseDate(cycle?.attributes?.endDate) < new Date()) || false;
}

function buildTreeData(
  metricsTree: TreeNode,
  formatMessage: Function,
  locale: string,
  allCompartments: Array<Compartment>,
  allSubNodes: Array<SubNode>,
  allMetrics: Array<Metric>,
  allVarieties: Array<Variety>,
  allFruitClasses: Array<FruitClass>,
  allPlantingCycles: Array<PlantingCycle>,
  loadingNodeKey: string | number | undefined,
): Array<DataNode> {
  const nestingLevel = metricsTree?.node?.length;

  const metrics = metricsTree.children.map((childNode: TreeNode) => {
    const nodeKey = buildTreeNodeKey(childNode);
    if (childNode.entity.type === 'Metric') {
      const metric = allMetrics.find(item => item.id === childNode.entity.id);
      return {
        key: nodeKey,
        checkable: true,
        childrenCategorized: childNode?.childrenCategorized,
        categoryCode: childNode?.categoryCode,
        isLeaf: true,
        icon: <TreeIcon icon={<DataIcon />} />,
        className: classnames(
          styles.treeNode,
          styles.leafNode,
          `tree-key-${childNode.node.join('_')}`,
          {
            [styles.secondNestingLevel]: nestingLevel === 3,
            [styles.thirdNestingLevel]: nestingLevel === 4
          },
        ),
        switcherIcon: () => {},
        title: <TreeTitle
          title={getTreeNodeTitle(childNode, formatMessage, locale, allCompartments, allSubNodes, allMetrics, allVarieties, allFruitClasses, allPlantingCycles)}
          unitsText={metric ? formatMessage({ id: `cunits.mini.${metric?.attributes.cunit}` }) : null}
          formatMessage={formatMessage}
        />,
      };
    }
    const isComplete = isCompleteCycle(childNode, allPlantingCycles);
    return {
      key: nodeKey,
      className: classnames(styles.treeNode, `tree-key-${childNode.node.join('_')}`),
      icon: <TreeIcon icon={loadingNodeKey === nodeKey ? <LoaderIcon className={styles.loader} /> : <SquareIcon />} />,
      // icon: <TreeIcon icon={<LoaderIcon className={styles.loader} />} />,
      title: <TreeTitle
        title={getTreeNodeTitle(childNode, formatMessage, locale, allCompartments, allSubNodes, allMetrics, allVarieties, allFruitClasses, allPlantingCycles)}
        isComplete={isComplete}
        formatMessage={formatMessage}
      />,
      checkable: false,
      isLeaf: false,
      children: childNode?.children?.length > 0 ? buildTreeData(
        childNode,
        formatMessage,
        locale,
        allCompartments,
        allSubNodes,
        allMetrics,
        allVarieties,
        allFruitClasses,
        allPlantingCycles,
        loadingNodeKey,
      ) : undefined,
      childrenCategorized: childNode?.childrenCategorized
    };
  });
  const resultMetrics = [];
  let lastCategoryCode = null;
  for (let i = 0; i < metrics.length; i += 1) {
    const metric = metrics[i];
    if (metric.isLeaf && lastCategoryCode !== metric?.categoryCode) {
      resultMetrics.push({
        key: `category-${metric.key}`,
        checkable: false,
        disableCheckbox: true,
        childrenCategorized: metric?.childrenCategorized,
        categoryCode: metric?.categoryCode,
        isLeaf: true,
        icon: null,
        className: styles.categoryNode,
        switcherIcon: () => {},
        title: formatMessage({ id: `crops.categories.${metric?.categoryCode}` }),
      });
      lastCategoryCode = metric?.categoryCode;
    }
    resultMetrics.push(metric);
  }
  return resultMetrics;
}

/**
 * Find key in source
 * @param source - where looking
 * @param target - what looking
 */
function keyIncludes(source: string, target: string) {
  const sourceKeyParts: Array<TreeNodePathComponents> = source.split('/');
  const targetKeyParts: Array<TreeNodePathComponents> = target.split('/');
  for (let i = 0; i < targetKeyParts.length; i += 1) {
    if (targetKeyParts[i] !== sourceKeyParts[i]) {
      return false;
    }
  }
  return true;
}

function findTreeNode(
  treeData: Array<DataNode>, treeNode: TreeNode,
): DataNode | null {
  const nodeKey = buildTreeNodeKey(treeNode);
  for (let i = 0; i < treeData.length; i += 1) {
    if (treeData[i].key === nodeKey) {
      return treeData[i];
    }
    if (keyIncludes(nodeKey, treeData[i]?.key?.toString())) {
      // @ts-ignore
      if (treeData[i]?.children?.length && !treeData[i].childrenCategorized) {
        return findTreeNode(treeData[i]?.children || [], treeNode);
      }
    }
  }
  return null;
}

function insertOrReplaceTreeNode(
  treeData: Array<DataNode>,
  node: TreeNode,
  formatMessage: Function,
  locale: string,
  allCompartments: Array<Compartment>,
  allSubNodes: Array<SubNode>,
  allMetrics: Array<Metric>,
  allVarieties: Array<Variety>,
  allFruitClasses: Array<FruitClass>,
  allPlantingCycles: Array<PlantingCycle>,
  loadingNodeKey: string | number | undefined,
) {
  const nodeRef = findTreeNode(treeData, node);
  const newNode = buildTreeData(node, formatMessage, locale, allCompartments, allSubNodes, allMetrics, allVarieties, allFruitClasses, allPlantingCycles, loadingNodeKey);
  if (nodeRef) {
    nodeRef.children = newNode;
  }
}

function splitMetric(checked: string): Array<TreeNodePathComponents> {
  const parts = checked.split('/');
  // @ts-ignore
  return [parts[0], +parts[1], +parts[2], +parts[3], +parts[4]];
}

const PanelMetricTree = ({
  intl,
  selected,
  treeTypeId,
  metricsTree,
  requestMetricsTree,
  allCompartments,
  allSubNodes,
  allMetrics,
  allVarieties,
  allFruitClasses,
  allPlantingCycles,
  metricsSearchData,
  togglePresetMetric,
  metricGroups,
  // expandItem,
  expandedKeys,
  setTreeExpandedKeys,
  setLoadingNodeKey,
  loadingNodeKey
}: PanelMetricTreeProps & InjectedIntlProps) => {
  const { formatMessage, locale } = intl;

  const [treeData, setTreeData] = useState<Array<DataNode> | undefined>(undefined);
  const treeRef = useRef<any>(null);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    // first load
    if (metricsTree) {
      const treeId = getTreeIdByType(treeTypeId);
      if (treeId) {
        const tree = getTreeById(metricsTree, treeId);
        if (tree) {
          const data = buildTreeData(
            tree,
            formatMessage,
            locale,
            allCompartments,
            allSubNodes,
            allMetrics,
            allVarieties,
            allFruitClasses,
            allPlantingCycles,
            loadingNodeKey,
          );
          setTreeData(data);
          if (isFirstLoad) {
            setLoadingNodeKey(undefined);
            setTreeExpandedKeys({
              treeId,
              keys: []
            });
            setIsFirstLoad(false);
          }
        } else {
          setTreeData([]);
        }
      }
    }
  }, [
    treeTypeId,
    setTreeData,
    metricsTree,
    locale,
    formatMessage,
    allCompartments,
    allSubNodes,
    allMetrics,
    allVarieties,
    allFruitClasses,
    allPlantingCycles,
    loadingNodeKey,
    setLoadingNodeKey,
    setTreeExpandedKeys,
    isFirstLoad,
    setIsFirstLoad,
    metricsSearchData
  ]);

  const checkedKeys = useMemo(() => {
    const metrics = [];
    const treeId = getTreeIdByType(treeTypeId);
    for (let iTree = 0; iTree < metricGroups.length; iTree += 1) {
      const tree = metricGroups[iTree];
      if (tree.key === treeId) {
        for (let iGrp = 0; iGrp < tree.metricGroups.length; iGrp += 1) {
          metrics.push(...tree.metricGroups[iGrp].metrics.map((metric:MetricDef) => metric.node.join('/')));
        }
      }
    }
    return metrics;
  }, [metricGroups, treeTypeId]);

  const handleExpand = useCallback((keys:Array<KeyType>, nodeInfo: {
    node: EventDataNode<DataNode>;
    expanded: boolean;
    nativeEvent: MouseEvent;
  }) => {
    const nodeKey = String(nodeInfo?.node?.key);
    const treeId = getTreeIdByType(treeTypeId);
    const newKeys = nodeInfo.expanded ?
      [...expandedKeys, nodeInfo.node.key] : expandedKeys.filter((key:string) => key.indexOf(nodeKey) !== 0);
    setTreeExpandedKeys({
      treeId,
      keys: newKeys
    });
    // @ts-ignore
    if (nodeInfo.node.isLeaf || nodeInfo.node.children) {
      return;
    }
    setLoadingNodeKey(nodeKey);
    const tree = getTreeById(metricsTree, treeId);
    if (tree) {
      // rebuild tree with current node key (for show loader icon)
      const data = buildTreeData(
        tree,
        formatMessage,
        locale,
        allCompartments,
        allSubNodes,
        allMetrics,
        allVarieties,
        allFruitClasses,
        allPlantingCycles,
        nodeKey,
      );
      const node = findMetricsTreeNode(tree, nodeKey.split('/'));
      if (!node || node?.children?.length === 0) {
        setLoadingNodeKey(undefined);
        return;
      }
      setTreeData(data);
    }
    if (nodeInfo.expanded) {
      requestMetricsTree({
        ids: nodeKey,
        preventStateUpdate: true,
        actionSuccess: (ids:string, loadedNode:TreeNode | null) => {
          if (loadedNode && treeData) {
            insertOrReplaceTreeNode(treeData, loadedNode, formatMessage, locale, allCompartments, allSubNodes, allMetrics, allVarieties, allFruitClasses, allPlantingCycles, nodeKey);
            setTreeData([...treeData]);
          }
        }
      });
    }
  }, [
    expandedKeys,
    setTreeExpandedKeys,
    treeTypeId,
    treeData,
    requestMetricsTree,
    formatMessage,
    locale,
    allCompartments,
    allSubNodes,
    allMetrics,
    allVarieties,
    allFruitClasses,
    allPlantingCycles,
    setLoadingNodeKey,
    metricsTree,
  ]);

  useEffect(() => {
    if (metricsSearchData && metricsTree) {
      const treeId = getTreeIdByType(treeTypeId);
      const tree = getTreeById(metricsTree, treeId);
      const loop = (node: TreeNode): Array<string> =>
        (node?.children?.length > 0 ? flatMapDeep(node.children, loop) : [node.node.join('/')]);
      const allChildrenKeys = tree?.children.map((node: TreeNode) => loop(node))?.flat();
      setLoadingNodeKey(undefined);
      setTreeExpandedKeys({
        treeId,
        keys: allChildrenKeys || []
      });
    }
  }, [metricsSearchData, metricsTree, treeTypeId, setTreeExpandedKeys, setLoadingNodeKey]);

  const renderSwitcherIcon = (nodeProps: TreeNodeProps) => (
    <span className={classnames(styles.switcherIcon, {
      [styles.switcherIconExpanded]: nodeProps.expanded
    })}
    >
      <ArrowIcon />
    </span>
  );

  const handleCheck = useCallback((checked: any, info: any) => {
    togglePresetMetric({
      metric: splitMetric(info.node.key)
    });
  }, [togglePresetMetric]);

  return (
    <div className={classnames(styles.treeContainer, {
      [styles.selected]: selected
    })}
    >
      <Tree
        ref={treeRef}
        className={styles.rcTree}
        checkable
        showLine={false}
        onExpand={handleExpand}
        autoExpandParent
        expandedKeys={expandedKeys}
        expandAction='click'
        selectable={false}
        switcherIcon={renderSwitcherIcon}
        treeData={treeData}
        onCheck={handleCheck}
        checkedKeys={checkedKeys}
      />
    </div>
  );
};

export default injectIntl(PanelMetricTree);
