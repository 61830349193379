import { handleActions } from 'redux-actions';

import { get } from 'lodash';

import {
  requestEnergyReport,
  receiveEnergyReport,

  requestEnergyRegistrations,
  receiveEnergyRegistrations,
  updateEnergyReportSorting,

  requestSaveRegistrations,
  receiveSaveRegistrations,

  requestEnergyMeters,
  receiveEnergyMeters,

  clearEnergyReport,
} from './actions';

const initialState = {
  isEnergyReportFetching: false,
  energyReport: null,

  isEnergyRegistrationsFetching: false,
  energyRegistrations: null,

  isSaveRegistrationsFetching: false,

  isMetersFetching: false,
  meters: [],

  energyReportSorting: {
    type: 'meter',
    direction: 'up'
  },
};

export default handleActions({
  [clearEnergyReport](state) {
    return {
      ...state,

      isEnergyReportFetching: false,
      energyReport: null,
      energyReportSorting: {
        type: 'meter',
        direction: 'up'
      },
    };
  },
  [requestEnergyReport](state) {
    return {
      ...state,

      isEnergyReportFetching: true,
    };
  },
  [receiveEnergyReport](state, action) {
    return {
      ...state,

      isEnergyReportFetching: false,
      energyReport: get(action, 'payload.energyReport'),
    };
  },
  [requestEnergyRegistrations](state) {
    return {
      ...state,

      isEnergyRegistrationsFetching: true,
    };
  },
  [receiveEnergyRegistrations](state, action) {
    return {
      ...state,

      isEnergyRegistrationsFetching: false,
      energyRegistrations: get(action, 'payload.energyRegistrations'),
    };
  },
  [updateEnergyReportSorting](state, action) {
    return {
      ...state,

      energyReportSorting: action.payload,
    };
  },
  [requestSaveRegistrations](state) {
    return {
      ...state,
      isSaveRegistrationsFetching: true,
    };
  },
  [receiveSaveRegistrations](state) {
    return {
      ...state,
      isSaveRegistrationsFetching: false,
    };
  },
  [requestEnergyMeters](state) {
    return {
      ...state,

      isMetersFetching: true,
    };
  },
  [receiveEnergyMeters](state, action) {
    return {
      ...state,

      isMetersFetching: false,
      meters: get(action, 'payload.meters'),
    };
  },
}, initialState);
