import React from 'react';
import PropTypes from 'prop-types';

import { INCIDENT_TIMELINE_CONFIG } from 'components/CanvasTimeline/helpers/incidentScale';

import { ReactComponent as LoaderIcon } from './assets/loader.svg';

import styles from './IncidentLoader.module.css';


const IncidentLoader = ({
  lodadedNode,
  timeLineComponentRef
}) => {
  const iconPagging = 4; // 4 это разница между иконкой лоадера и стрелки

  const scroll = timeLineComponentRef?.current?.scroll || { y: 0 };

  const wrapperStyles = {
    left: lodadedNode?.nodeBounds?.x - iconPagging + 25,
    top: lodadedNode?.nodeBounds?.y - iconPagging + INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT + 20 + scroll.y,
  };

  if (!lodadedNode) {
    return null;
  }

  return (
    <div className={styles.incidentLoader} style={wrapperStyles}>
      <LoaderIcon className={styles.loader} />
    </div>
  );
};

IncidentLoader.propTypes = {
  lodadedNode: PropTypes.object,
  timeLineComponentRef: PropTypes.object
};

IncidentLoader.defaultProps = {
  lodadedNode: undefined,
  timeLineComponentRef: undefined
};

export default IncidentLoader;
