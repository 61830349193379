import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { isNumber } from 'lodash';

import { showIntercom } from 'helpers/intercomHelpers';
import { getMlNameByLocal } from 'helpers/getNameByLocal/getNameByLocal';
import numbersFormatting from 'helpers/numbersFormatting';

import PageViewTracker from 'components/PageViewTracker';
import Typography from 'components/Typography';
import BigButton from 'components/BigButton';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import DefaultPlate from 'components/DefaultPlate';
import DefaultTable from 'components/DefaultTable';

import styles from './SettingsGreenhouses.module.css';

export default class SettingsGreenhouses extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    compartments: PropTypes.array,
    greenhouseTypes: PropTypes.array,
    isAllCompartmentsFetching: PropTypes.bool.isRequired,
    trackPageViewSettings: PropTypes.func.isRequired,
    defaultOpen: PropTypes.bool,
  };

  static defaultProps = {
    compartments: [],
    greenhouseTypes: [],
    defaultOpen: false,
  };

  handlerPageView = () => {
    const { trackPageViewSettings, defaultOpen } = this.props;

    trackPageViewSettings({ settingName: 'Greenhouses', defaultOpen });
  };

  renderHeaderRows = () => {
    const { intl: { formatMessage } } = this.props;
    return (
      <tr>
        <th className={styles.alignLeft}>{formatMessage({ id: 'settings.greenhouse' })}</th>
        <th className={styles.alignLeft}>{formatMessage({ id: 'settings.type' })}</th>
        <th className={styles.alignRight}>{formatMessage({ id: 'settings.floorArea' })},&nbsp;{formatMessage({ id: 'cunits.mini.squareMeter' })}</th>
        <th className={styles.alignRight}>{formatMessage({ id: 'settings.lightPower' })},&nbsp;{formatMessage({ id: 'cunits.mini.wattPerSquareMeter' })}</th>
      </tr>
    );
  };

  renderRows = (compartments, greenhouseTypes, locale) => compartments.map((compartment) => {
    const greenhouseType = greenhouseTypes.find(type => type.id === compartment.relationships.greenhouseType.data[0].id);
    const floorArea = compartment?.attributes?.floorArea || '—';
    const lightPower = compartment?.attributes?.lightPower || '—';
    return (
      <tr key={`compartmentRow-${compartment.id}`}>
        <td className={styles.alignLeft}>{compartment.attributes.name || '—'}</td>
        <td className={styles.alignLeft}>{getMlNameByLocal(greenhouseType, locale) || '—'}</td>
        <td className={styles.alignRight}>{isNumber(floorArea) ? numbersFormatting(floorArea) : floorArea}</td>
        <td className={styles.alignRight}>{isNumber(lightPower) ? numbersFormatting(lightPower) : lightPower}</td>
      </tr>
    );
  });

  render() {
    const {
      intl: { formatMessage, locale },
      isAllCompartmentsFetching,
      compartments,
      greenhouseTypes,
    } = this.props;
    const isEmpty = !compartments || !compartments.length;
    return (
      <div className={styles.settingsGreenhouses}>
        <PageViewTracker onMount={this.handlerPageView} />

        {isAllCompartmentsFetching && <DefaultCircleLoader />}

        <Typography variant='h2' className={styles.header}>
          {formatMessage({ id: 'settings.greenhouses' })}
        </Typography>

        <DefaultPlate className={styles.infoPlate}>
          <div>
            {formatMessage({ id: 'settings.toChangeGreenhouses' })}
            &nbsp;
            <BigButton
              className={styles.supportButton}
              onClick={showIntercom}
              title={formatMessage({ id: 'settings.contactSupport' })}
              theme='transparent'
            />
          </div>
        </DefaultPlate>
        {!isEmpty && (
          <div className={styles.tableWrapper}>
            <DefaultTable
              headerRows={this.renderHeaderRows()}
              bodyRows={this.renderRows(compartments, greenhouseTypes, locale)}
              fixedColumnOnMobile
            />
          </div>
        )}
      </div>
    );
  }
}
