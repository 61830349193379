import React, { memo } from 'react';
import PropTypes from 'prop-types';

import PlantIcon from '../Icons/PlantIcon';
import TomatoIcon from '../Icons/TomatoIcon';
import CucumberIcon from '../Icons/CucumberIcon';
import EggplantIcon from '../Icons/EggplantIcon';
import LettuceIcon from '../Icons/LettuceIcon';
import PepperIcon from '../Icons/PepperIcon';

const SpeciesIcon = ({ species, className }) => {
  let icon = <PlantIcon className={className} />;

  if (species === 'tomato') {
    icon = <TomatoIcon className={className} />;
  }

  if (species === 'cucumber') {
    icon = <CucumberIcon className={className} />;
  }

  if (species === 'eggplant') {
    icon = <EggplantIcon className={className} />;
  }

  if (species === 'lettuce') {
    icon = <LettuceIcon className={className} />;
  }

  if (species === 'pepper') {
    icon = <PepperIcon className={className} />;
  }

  return icon;
};

SpeciesIcon.propTypes = {
  className: PropTypes.string,
  species: PropTypes.oneOf([null, 'cucumber', 'tomato', 'eggplant', 'lettuce', 'pepper']),
};

SpeciesIcon.defaultProps = {
  className: null,
  species: null,
};

export default memo(SpeciesIcon);
