import { createSelector } from 'reselect';
import { getRouteLocation, getRouteQueryParams } from 'store/router/selectors';
import { get } from 'lodash';

export const getMeasurements = state => state?.measurements?.measurements;
export const getMeasurementsPlantsList = state => state?.measurements?.plantsList;
export const getMeasurementsAdditionalMetrics = state => state?.measurements?.additionalMetrics;
export const getMeasurementsByDate = state => state?.measurements?.measurementsByDate;
export const getIsMeasurementsByDateFetching = state => state?.measurements?.isMeasurementsByDateFetching;
export const getCropType = (state, props) => get(props, 'match.params.cropType'); // Внимание! Селектор требует использование HOC withRouter в компоненте
export const getDefaultNumberOfPlants = state => state?.measurements?.defaultNumberOfPlants;
export const getIsDefaultNumberOfPlantsFetching = state => state?.measurements?.isDefaultNumberOfPlantsFetching;
export const getManualFormsMetrics = state => state?.measurements?.manualFormsMetrics;
export const getIsManualFormsMetricsFetching = state => state?.measurements?.isManualFormsMetricsFetching;


export const getIsMeasurementsFetching = state =>
  state?.measurements?.isMeasurementsByPeriodFetching;
export const getIsMeasurementsPlantsListFetching = state =>
  state?.measurements?.isMeasurementsPlantsListFetching;
export const getIsMeasurementsAdditionalMetricsFetching = state =>
  state?.measurements?.isMeasurementsAdditionalMetricsFetching;

export const getIsMeasurementsSuccess = state =>
  state?.measurements?.isMeasurementsByPeriodSuccess;
export const getIsMeasurementsPlantsListSuccess = state =>
  state?.measurements?.isMeasurementsPlantsListSuccess;
export const getIsMeasurementsAdditionalMetricsSuccess = state =>
  state?.measurements?.isMeasurementsAdditionalMetricsSuccess;

export const getPeriodFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'period'),
);

export const getPlantingCycleIdFromUrl = createSelector(
  [getRouteLocation],
  (location) => {
    const {
      pathname
    } = location;
    const parsedPathName = pathname.split('/');
    return Number(parsedPathName[3]);
  },
);
