import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';

import SaveChangesDialog from 'components/SaveChangesDialog';

/**
 * Тут используем Prompt но не забыть обновить при апгрейде роутера с 5 на 6 версию
 * там поменять на хук useBlocker, который лежит в папке hooks (т.к. его выпилили в поздних 6 версиях)
 *
 * TODO: change then react-router (react-router-dom) will be updated
 */
const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  onCancel,
  onConfirm,
  navigateOnCancel,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const handlerCancelButtonClick = () => {
    setModalVisible(false);
    if (navigateOnCancel) {
      setConfirmedNavigation(true);
    }

    onCancel();
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handlerConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);

    onConfirm();
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      const searchPath = (lastLocation.search && lastLocation.search !== '') ? `?${lastLocation.search}` : '';
      navigate(`${lastLocation.pathname}${searchPath}`);
    }
  }, [navigate, confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {modalVisible && (
        <SaveChangesDialog
          handlerCloseDialog={() => setModalVisible(false)}
          handlerDontSave={handlerCancelButtonClick}
          handlerSave={handlerConfirmNavigationClick}
        />
      )}
    </>
  );
};

RouteLeavingGuard.propTypes = {
  when: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  shouldBlockNavigation: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  navigateOnCancel: PropTypes.bool,
};

RouteLeavingGuard.defaultProps = {
  shouldBlockNavigation: () => true,
  onCancel: () => {},
  onConfirm: () => {},
  navigateOnCancel: false,
};

export default RouteLeavingGuard;
