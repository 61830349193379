import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import ProgressBar from '../../../ProgressBar';

import styles from './CycleHarvest.module.css';

class CycleHarvest extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    harvestValue: PropTypes.string,
    valueUnitsText: PropTypes.string,
    cycleWeek: PropTypes.number,
    isLettuce: PropTypes.bool,
    totalGoalValue: PropTypes.string,
    totalGoalUnitsText: PropTypes.string,
    harvestPlan: PropTypes.number,
  };

  static defaultProps = {
    harvestValue: undefined,
    valueUnitsText: undefined,
    cycleWeek: undefined,
    isLettuce: false,
    totalGoalValue: undefined,
    totalGoalUnitsText: undefined,
    harvestPlan: undefined,
  };

  render() {
    const {
      intl: { formatMessage }, harvestValue, valueUnitsText,
      cycleWeek, totalGoalValue, totalGoalUnitsText, harvestPlan,
      isLettuce,
    } = this.props;

    const units = isLettuce ? formatMessage({ id: 'cunits.mini.count' }) : formatMessage({ id: 'cunits.mini.kilogramPerSquareMeter' });

    return (
      <div className={styles.harvest}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{formatMessage({ id: 'cycle.table.harvested' })}</th>
              <th>{formatMessage({ id: 'cycle.table.week' })}</th>
              <th>{formatMessage({ id: 'cycle.table.plan' })}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>
                  {harvestValue || 0}
                  <span className={styles.units}>{valueUnitsText || units}</span>
                </span>
              </td>
              <td>{cycleWeek || '–'}</td>
              <td>
                <span>
                  {totalGoalValue || 0}
                  <span className={styles.units}>{totalGoalUnitsText || units}</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className={styles.progressContainer}>
          <ProgressBar percent={harvestPlan} />
        </div>
      </div>
    );
  }
}

export default injectIntl(CycleHarvest);
