import {
  get, find, reject, isEmpty, flatten, groupBy, sortBy, head, last, flow
} from 'lodash';
import moment from 'moment-timezone';

import { API_DATE_FORMAT } from 'helpers/defaultDates';

import { getWeeksFromDescriptor } from '../getWeekNumbers';

const EMPTY_CELL = { value: '' };
const EMPTY_READ_ONLY_CELL = {
  value: '',
  readOnly: true,
  noSelectable: true,
};

const getPlantingCycleInfo = (planDescriptor, plantingCycleId) => {
  const flatVarietyPlans = planDescriptor?.varietyPlans.map(varietyPlan => ({
    ...varietyPlan?.plantingCycle,
    cellRange: varietyPlan?.cellRange
  }));

  return find(flatVarietyPlans, { id: plantingCycleId });
};

/**
 * Генерирует пустой grid (двумерную матрицу) для datasheet указанного года
 *
 * @param {string} year - Год (например 2019).
 * @param {Object[]} compartments - Массив объектов отделений.
 * @param {object} planDescriptor - Объект, с информацией о периодах плана, в т.ч. массив периодов.
 * @param {string} yearType - Тип рассчёта недель года (iso или сalendar)
 * @return {Array} Массив массивов со значениями для грида.
 */
export const generateEmptyGrid = (year, compartments = [], planDescriptor, yearType) => {
  const weeks = getWeeksFromDescriptor(year, planDescriptor, yearType);

  // const columnsLength = withoutAddCompartment ? compartments.length : compartments.length + 1;
  const columnsLength = compartments.length + 1;

  const columns = new Array(columnsLength).fill(EMPTY_READ_ONLY_CELL); // +1 для кнопки добавления компартмента

  const grid = weeks.map(week => columns.map(day => ({
    ...day,
    ...week,
  })));

  return {
    headerRow: compartments,
    grid,
  };
};

/**
 * Генерирует пустой grid (двумерную матрицу), основанный на структуре данных
 * бэкенда (а конкретно - базируясь на compartments и varieties) для datasheet указанного года
 *
 * @param {string} year - Год (например 2019).
 * @param {Object[]} compartments - Массив объектов отделений.
 * @param {Object[]} plan - Массив объектов секций для отделений и varieties.
 * @param {object} planDescriptor - Объект, с информацией о периодах плана, в т.ч. массив периодов.
 * @param {bool} isOperationalPlan - Признак оперативного плана. Для оперативного плана грид строится немного по-другому.
 * @param {string} yearType - Тип рассчёта недель года (iso или сalendar)
 * @return {Array} Массив массивов со значениями для грида.
 */
export const generateGridWithData = (year, compartments, plan = [], planDescriptor, isOperationalPlan = false, yearType) => {
  // const withoutAddCompartment = isOperationalPlan;
  const weeks = getWeeksFromDescriptor(year, planDescriptor, yearType);

  const planWithColumnSum = plan.map(variety => ({
    ...variety,
    harvestSum: variety.sections.reduce((acc, section) => {
      const sumByCell = section.cells.reduce((cellAcc, cell) => (cellAcc + Number(cell.relativeHarvest)), 0);

      return acc + sumByCell;
    }, 0)
  }));

  const headerRow = compartments.map(compartment => ({
    ...compartment,
    varieties: planWithColumnSum.filter(item => item.compartmentId === compartment.id),
  }));

  const gridColumns = headerRow.reduce((acc, compartment) => {
    if (compartment.varieties.length > 0) {
      const compartmentFloorArea = get(compartment, 'attributes.floorArea');

      const varietyColumns = compartment.varieties.reduce((accVar, item) => {
        const {
          idx, varietyId, compartmentId, targetWeight, fruitClassCode, plantingCycleId, cunit,
        } = item;

        const plantingCycleInfo = plantingCycleId ? getPlantingCycleInfo(planDescriptor, plantingCycleId) : undefined;

        return [...accVar, {
          ...EMPTY_CELL,
          idx,
          varietyId,
          compartmentId,
          compartmentFloorArea,
          targetWeight,
          fruitClassCode,
          plantingCycleInfo,
          cunit,
        }];
      }, []);

      return [...acc, ...varietyColumns];
    }

    // if (withoutAddCompartment) {
    //   return [...acc];
    // }

    return [...acc, EMPTY_READ_ONLY_CELL];
  }, []);

  // const gridColumnsWithAddButton = withoutAddCompartment ? [...gridColumns] : [...gridColumns, EMPTY_READ_ONLY_CELL];
  const gridColumnsWithAddButton = [...gridColumns, EMPTY_READ_ONLY_CELL];

  const grid = weeks.map(week => gridColumnsWithAddButton.map((day) => {
    let currentCellGroup;
    let currentCell;

    let isInCycle = false;
    let isFirst = false;
    let isLast = false;
    let isInSection = false;
    let weekInfo = {};

    const sections = find(plan, { idx: get(day, 'idx'), varietyId: get(day, 'varietyId'), compartmentId: get(day, 'compartmentId') });
    const sectionsArray = get(sections, 'sections');
    const species = get(sections, 'species');

    if (sections) {
      // Находим группу ячеек для этого столбца и этой даты
      if (isOperationalPlan) {
        currentCellGroup = sectionsArray.reduce((acc, section) => ({
          ...section,

          // Считаем сумму урожая группы ячеек для отображения в плашке первой ячейки группы
          relativeHarvestSum: section.cells.reduce((cellsAcc, item) => (cellsAcc + Number.parseFloat(item.relativeHarvest)), 0),
        }), undefined);
      } else {
        currentCellGroup = sectionsArray.reduce((acc, section) => {
          if (find(get(section, 'cells'), { startDate: week.startDate })) {
            return {
              ...section,

              // Считаем сумму урожая группы ячеек для отображения в плашке первой ячейки группы
              relativeHarvestSum: section.cells.reduce((cellsAcc, item) => (cellsAcc + Number.parseFloat(item.relativeHarvest)), 0),
            };
          }

          return acc;
        }, undefined);
      }

      // Значение для этого столбца и этой даты в группе ячеек
      currentCell = find(get(currentCellGroup, 'cells'), { startDate: week.startDate });
    }

    if (currentCellGroup && currentCell) {
      // Отмечаем является ли ячейка первой или последней (нужно для навешивания css через класс)
      const firsElement = head(currentCellGroup.cells);
      const lastElement = last(currentCellGroup.cells);
      const currentCellStartDate = get(currentCell, 'startDate');

      isFirst = firsElement.startDate === currentCellStartDate;
      isLast = lastElement.startDate === currentCellStartDate;

      weekInfo = {
        weeksCount: currentCellGroup.cells.length,
        fistWeek: moment(firsElement.startDate, 'YYYY-MM-DD').isoWeek(),
        lastWeek: moment(lastElement.startDate, 'YYYY-MM-DD').isoWeek(),
      };
    }

    // Для оперативного плана в секцию обводим все ячейки между первой и последней
    if (isOperationalPlan && sectionsArray) {
      const cellsArray = get(sectionsArray, '[0].cells');

      const startDate = day?.plantingCycleInfo?.cellRange?.start;
      const endDate = day?.plantingCycleInfo?.cellRange?.end;
      // const currentCellStartDate = get(week, 'startDate');

      // const momentStartDate = moment(startDate, 'YYYY-MM-DD');
      // const momentEndDate = moment(endDate, 'YYYY-MM-DD');

      // Показываем поповер для первой ячейки, если цикл уходит в период до
      // isFirstForTableColumn = index === 0 && moment(planDescriptor?.startDate, 'YYYY-MM-DD') > momentStartDate;
      // isFirst = startDate === currentCellStartDate || isFirstForTableColumn;
      // isLast = endDate === currentCellStartDate;

      const firsElement = head(cellsArray);
      const lastElement = last(cellsArray);

      const cellDate = moment(week.startDate, API_DATE_FORMAT);

      if (firsElement && lastElement) {
        isInSection = cellDate > moment(firsElement.startDate, API_DATE_FORMAT) && cellDate < moment(lastElement.startDate, API_DATE_FORMAT);
      }

      // weekInfo = {
      //   weeksCount: momentEndDate.diff(momentStartDate, 'weeks'),
      //   fistWeek: momentStartDate.isoWeek(),
      //   lastWeek: momentEndDate.isoWeek(),
      // };

      if (startDate && endDate) {
        isInCycle = cellDate >= moment(startDate, API_DATE_FORMAT) && cellDate <= moment(endDate, API_DATE_FORMAT);
      }
    }

    return {
      ...day,
      ...week,
      ...weekInfo,
      value: get(currentCell, 'relativeHarvest', ''),
      plantingArea: get(currentCellGroup, 'plantingArea'),
      plantDensity: get(currentCellGroup, 'plantDensity'),
      relativeHarvestSum: get(currentCellGroup, 'relativeHarvestSum'),
      validationErrors: get(currentCell, 'validationErrors') || [],
      isFirst,
      isLast,
      isInCycle,
      isInSection,
      species,
    };
  }));

  return {
    headerRow,
    grid,
  };
};

// TODO: сделать эту функцию поэлегантнее, она сильно упростится, если у групп ячеек будут айдишники
const groupByCells = (cells, withServiceInfo = false, isOperationalPlan = false) => {
  const sortedArray = sortBy(cells, cell => moment(cell.startDate, 'YYYY-MM-DD'));

  let currentStartDate = get(sortedArray, '[0].startDate');
  let currentItem = get(sortedArray, '[0]');

  const groupedArrays = {};
  groupedArrays[currentStartDate] = [];

  if (isOperationalPlan) {
    groupedArrays[currentStartDate] = [...sortedArray];
  } else {
    // Группируем ячейки стоящие рядом по ключу даты первой ячейки группы
    sortedArray.forEach((item) => {
      const momentStartDate = moment(item.startDate, 'YYYY-MM-DD');
      const momentCurrentItemEndDate = moment(currentItem.endDate, 'YYYY-MM-DD');

      if (momentStartDate.clone().diff(momentCurrentItemEndDate.clone(), 'days') <= 1) {
        groupedArrays[currentStartDate] = [...groupedArrays[currentStartDate], item];
      } else {
        groupedArrays[item.startDate] = [item];
        currentStartDate = item.startDate;
      }

      currentItem = item;
    });
  }

  let newGrid = [];

  // Преобразовываем структуру в формат бекенда
  Object.keys(groupedArrays).forEach((key) => {
    if (groupedArrays[key].length > 0) {
      newGrid = [...newGrid, {
        cells: groupedArrays[key].map(item => ({
          ...(withServiceInfo ? { ...item } : {}),
          startDate: item.startDate,
          relativeHarvest: item.value,
        })),
        plantingArea: groupedArrays[key][0].plantingArea || groupedArrays[key][0].compartmentFloorArea,
        plantDensity: groupedArrays[key][0].plantDensity,
      }];
    }
  });

  return newGrid;
};

export const generateJsonByGrid = (oldJson, newGrid, withServiceInfo = false, isOperationalPlan = false) => {
  const cellsWithData = flatten(reject(newGrid.map(row => row.filter(cell => cell.value !== '')), isEmpty));
  const groupedRows = groupBy(cellsWithData, 'idx');
  const newData = oldJson.data.map(varietyColumn => ({
    ...varietyColumn, sections: groupByCells(groupedRows[varietyColumn.idx], withServiceInfo, isOperationalPlan)
  }));
  return { ...oldJson, data: newData };
};

// Убираем все лишние поля для cells
export const cleanServiceInfoJson = json => ({
  ...json,
  data: json.data.map(column => ({
    ...column,
    sections: column.sections.map(section => ({
      ...section,
      cells: section.cells.map(cell => ({
        relativeHarvest: Number(cell.relativeHarvest),
        startDate: cell.startDate,
      })),
    }))
  })),
});

// Возвращаем информацию есть ли хоть одна ошибка в ячейках
export const checkPlanErrors = harvestPlan => flow([
  value => get(value, 'data'),
  columns => columns.reduce((acc, column) => ([...acc, ...column.sections]), []),
  sections => sections.reduce((acc, section) => ([...acc, ...section.cells]), []),
  cells => cells.reduce((acc, cell) => ([...acc, ...(cell.validationErrors ? [...cell.validationErrors] : [])]), []),
  errors => (errors.length > 0),
])(harvestPlan);
