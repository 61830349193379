import { useEffect } from 'react';

import { select } from 'd3';

import { isFinite } from 'lodash';

function useHoverLineHIndicator(groupRef, options) {
  const {
    chartParams: { x, h },
    height,
    position,
    hidden,
    tickDisplayFormat
  } = options;
  useEffect(() => {
    const group = groupRef.current
      ? select(groupRef.current)
      : null;
    const xValue = x.invert(position);
    if (group) {
      group.selectAll('rect').remove();
      group.selectAll('text').remove();

      if (isFinite(position) && xValue && !hidden) {
        const formattedValue = tickDisplayFormat ? tickDisplayFormat(xValue) : xValue.toFixed(2);
        if (formattedValue !== null) {
          const rect = group
            .append('rect')
            .attr('rx', 5)
            .attr('ry', 5)
            .attr('x', position)
            .attr('y', h + 8)
            .attr('width', 34)
            .attr('height', 20)
            .attr('fill', '#4A4A49');

          const text = group
            .append('text')
            .attr('x', position)
            .attr('y', h + 22)
            .attr('font-size', '12px')
            .attr('fill', 'white')
            .text(tickDisplayFormat ? tickDisplayFormat(xValue) : xValue.toFixed(2));

          const textWidth = text.node().getBoundingClientRect().width;

          rect.node().setAttribute('width', text.node().getBoundingClientRect().width + 4);

          rect.node().setAttribute('x', position - (textWidth / 2));
          text.node().setAttribute('x', position - (textWidth / 2) + 2);
        }
      }
    }
  }, [
    groupRef,
    x,
    h,
    height,
    position,
    hidden,
    tickDisplayFormat
  ]);
}

export default useHoverLineHIndicator;
