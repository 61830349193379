import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import BigButton from 'components/BigButton';
import CloseIcon from 'components/Icons/Close';

import styles from './DefaultSidePanel.module.css';


const DefaultSidePanel = ({
  isOpened, children, headerText, onClose, className, contentClassName
}) => (
  <div
    className={classnames(styles.overlay, className, { [styles.overlayOpened]: isOpened })}
    onClick={onClose}
    tabIndex={0}
    role='button'
  >
    <div
      className={classnames(styles.content, contentClassName)}
      onClick={e => e.stopPropagation()}
      tabIndex={0}
      role='button'
    >
      <div className={styles.header}>
        <div className={styles.headerRow}>
          <h3 className={styles.headerText}>
            {headerText}
          </h3>
          <BigButton
            className={styles.closeIcon}
            onClick={onClose}
            icon={<CloseIcon />}
            theme='transparent'
            highIcon
          />
        </div>
      </div>
      {children}
    </div>
  </div>
);

DefaultSidePanel.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.element
  ]),
  onClose: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
};

DefaultSidePanel.defaultProps = {
  children: null,
  headerText: null,
  className: null,
  contentClassName: null,
};

export default DefaultSidePanel;
