import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './DefaultSimpleTable.module.css';

const DefaultSimpleTable = forwardRef(({
  headerRows, bodyRows, size, className, emptyText
}, ref) => (
  <>
    {!emptyText && (
      <table
        ref={ref}
        className={classnames(
          styles.table,
          className,
          {
            [styles.sizeSmall]: size === 'small',
          }
        )}
      >
        {headerRows && (
          <thead>
            {headerRows}
          </thead>
        )}
        {bodyRows && (
          <tbody>
            {bodyRows}
          </tbody>
        )}
      </table>
    )}
    {emptyText && (
      <div className={styles.emptyState}>
        {emptyText}
      </div>
    )}
  </>
));

DefaultSimpleTable.propTypes = {
  headerRows: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]),
  bodyRows: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]),
  size: PropTypes.string,
  className: PropTypes.string,
  emptyText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
};

DefaultSimpleTable.defaultProps = {
  headerRows: null,
  bodyRows: null,
  size: 'medium',
  className: null,
  emptyText: null,
};

export default DefaultSimpleTable;
