import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import {
  getCurrentCompartments,
  getAllFruitClasses,
  getVarietiesInLocationWithTargetWeight
} from 'store/company/selectors';

import {
  setHasChangesTime,
  changeReportFilters,
  requestCreateExtraProduct,
  requestUpdateExtraProduct,
  requestDeleteExtraProduct,
} from 'store/harvestForecast/actions';
import { getReportFilters } from 'store/harvestForecast/selectors';

import HarvestForecastEnterForm from '../components/HarvestForecastEnterForm';

const mapDispatchToProps = {
  setHasChangesTime,
  changeReportFilters,
  requestCreateExtraProduct,
  requestUpdateExtraProduct,
  requestDeleteExtraProduct,
};

const mapStateToProps = (state, props) => ({
  ...props,

  ref: props.childRef,
  varieties: getVarietiesInLocationWithTargetWeight(state, props),
  fruitClasses: getAllFruitClasses(state, props),
  compartments: getCurrentCompartments(state),
  filters: getReportFilters(state),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HarvestForecastEnterForm));
