import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import classnames from 'classnames';

import numbersRounding from 'helpers/numbersRounding';

import Typography from 'components/Typography';
import BulkEditIcon from 'components/Icons/BulkEditIcon';
import NumberFormat from 'components/NumberFormat';
import DefaultSimpleTooltip from 'components/DefaultSimpleTooltip';

import BulkEditTable from '../BulkEditTable';
import EditableLineChart, { buildReferencePoints } from '../EditableLineChart';

import styles from './SeriesEditorBlock.module.css';

class SeriesEditorBlock extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    title: PropTypes.string,
    inputTitle: PropTypes.string,
    valueMetrics: PropTypes.object,
    points: PropTypes.object,
    startWeek: PropTypes.number.isRequired,
    endWeek: PropTypes.number.isRequired,
    withOutTable: PropTypes.bool,
    onValuesChanged: PropTypes.func,
    withOutTitle: PropTypes.bool,
    editable: PropTypes.bool,
  };

  static defaultProps = {
    title: null,
    inputTitle: null,
    withOutTable: false,
    withOutTitle: false,
    valueMetrics: null,
    editable: true,
    points: null,
    onValuesChanged: null
  };

  state = {
    showBulkEditor: false,
    points: [],
    graphPoints: [],
    inputValue: 0,
    inputValueError: null,
    editableReferencePoints: null
  }

  componentDidMount() {
    const {
      points,
      valueMetrics
    } = this.props;
    if (points) {
      const newPoints = this.convertPoints(points);
      const refPoints = buildReferencePoints(newPoints);
      this.setState(prev => ({
        ...prev,
        points: newPoints,
        graphPoints: newPoints,
        inputValue: numbersRounding(
          refPoints[0]?.value || 0,
          'fixed',
          valueMetrics?.round || 0
        ),
        selectedPointKey: refPoints[0]?.key,
        inputValueError: null
      }));
    }
    this.inputRef = React.createRef();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.points !== this.props.points) {
      const {
        valueMetrics,
      } = this.props;
      const newPoints = this.convertPoints(this.props.points);
      const refPoints = buildReferencePoints(newPoints);
      const {
        selectedPointKey,
      } = prevState;
      let currentInputValue = selectedPointKey ? this.props.points[selectedPointKey] : newPoints[0]?.value;
      const isValidSelectedPoint = refPoints.some(point => point?.key === selectedPointKey);
      const currentSelectedPointKey = isValidSelectedPoint ? selectedPointKey : refPoints[0]?.key;
      if (!isValidSelectedPoint) {
        currentInputValue = newPoints[0]?.value;
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prev => ({
        ...prev,
        points: [...newPoints],
        graphPoints: [...newPoints],
        inputValue: numbersRounding(
          currentInputValue,
          'fixed',
          valueMetrics?.round || 0
        ),
        selectedPointKey: currentSelectedPointKey,
        inputValueError: null
      }));
    }
  }

  convertPoints = (points) => {
    const datesKeys = Object.keys(points);
    return [
      ...datesKeys.map(key => ({
        key: Number(key),
        value: points[key],
      })),
    ];
  };

  handlerChangeGraphSeries = (point, newPoints) => {
    const {
      editable,
    } = this.props;
    if (editable) {
      this.setState(prevState => ({
        ...prevState,
        points: [...newPoints.map(p => ({
          ...p,
          value: p.value || 0
        }))],
      }));
    }
  };

  handlerAfterChangeGraphSeries = (point, newPoints) => {
    const {
      editable,
    } = this.props;
    if (editable) {
      const { onValuesChanged } = this.props;
      if (onValuesChanged) {
        onValuesChanged(newPoints.map(p => ({
          ...p,
          value: p.value || 0
        })));
      }
    }
  }

  handlerClickBulkEdit = () => {
    this.setState(prevState => ({
      ...prevState,
      showBulkEditor: !prevState.showBulkEditor
    }));
  }

  handlerTablePointsChanged = (points, rowIndex) => {
    const {
      editable,
    } = this.props;
    const {
      selectedPointKey
    } = this.state;
    if (editable) {
      const { value, key } = points[rowIndex];
      const { onValuesChanged } = this.props;
      this.setState(prevState => ({
        ...prevState,
        graphPoints: points,
        inputValue: selectedPointKey && selectedPointKey === key ? value : prevState.inputValue,
        inputValueError: null
      }));
      if (onValuesChanged) {
        onValuesChanged([...points]);
      }
    }
  }

  validateInput = (value) => {
    const {
      valueMetrics,
      intl
    } = this.props;
    const { formatMessage } = intl;
    const {
      max,
      min
    } = valueMetrics;
    if (value > max) {
      return {
        message: `${formatMessage({ id: 'cropModel.cannotExceed' })} ${max}`,
        validateError: true
      };
    }
    if (value < min) {
      return {
        message: `${formatMessage({ id: 'cropModel.cannotLessThan' })} ${min}`,
        validateError: true
      };
    }
    return {
      validateError: false
    };
  }

  handlerChangeValue = ({
    value
  }) => {
    const {
      selectedPointKey,
      points,
    } = this.state;
    const {
      validateError,
      message,
    } = this.validateInput(value);
    if (validateError) {
      this.setState(prevState => ({
        ...prevState,
        inputValue: value,
        inputValueError: message
      }));
    } else {
      if (selectedPointKey) {
        const point = points.find(p => p.key === selectedPointKey);
        if (point) {
          point.value = value;
        }
      }
      this.setState(prevState => ({
        ...prevState,
        inputValue: value,
        inputValueError: null,
        points: [...points]
      }));
    }
  }

  handlerBlurValue = () => {
    const {
      onValuesChanged
    } = this.props;
    const {
      points,
    } = this.state;
    if (onValuesChanged) {
      onValuesChanged([...points]);
    }
  }


  handlerChangeSelectedPoint = (point) => {
    const {
      valueMetrics,
    } = this.props;
    this.setState(prevState => ({
      ...prevState,
      inputValue: numbersRounding(
        point?.value || 0,
        'fixed',
        valueMetrics?.round || 0
      ),
      selectedPointKey: point?.key,
      inputValueError: null
    }));
  }

  handlerReferencePointsUpdate = (point) => {
    const {
      valueMetrics,
    } = this.props;
    this.setState(prev => ({
      ...prev,
      inputValue: numbersRounding(
        point?.value || 0,
        'fixed',
        valueMetrics?.round || 0
      ),
      inputValueError: null
    }));
  }

  render() {
    const {
      intl,
      title,
      inputTitle,
      startWeek,
      endWeek,
      withOutTitle,
      valueMetrics,
      editable,
    } = this.props;

    const { formatMessage } = intl;

    const {
      showBulkEditor,
      points,
      graphPoints,
      inputValue,
      inputValueError,
    } = this.state;

    const editableComponentProps = {
      startWeek,
      endWeek,
      width: 358,
      height: 226,
      xTickPadding: 14,
      yTickPadding: 18,
      margin: {
        top: 28,
        right: 62,
        bottom: 28,
        left: 10,
      }
    };

    return (
      <div className={styles.container}>
        {!withOutTitle && (
          <div className={styles.title}>{title}</div>
        )}
        <div className={styles.chartWrapper}>
          <EditableLineChart
            {...editableComponentProps}
            onValueChanged={this.handlerChangeGraphSeries}
            onAfterValueChanged={this.handlerAfterChangeGraphSeries}
            onSelectReferencePoint={this.handlerChangeSelectedPoint}
            onReferencePointsUpdate={this.handlerReferencePointsUpdate}
            points={graphPoints}
            maxValue={valueMetrics?.max}
            minValue={valueMetrics?.min}
            defaultValue={valueMetrics?.default}
            editable={editable && !showBulkEditor}
            inputRef={this.inputRef}
            valueMetrics={valueMetrics}
          />
        </div>
        <div className={styles.valueInputContainer}>
          <div className={styles.inputWithLabel}>
            <Typography className={styles.label} variant='subtitle2'>
              {inputTitle || title}, {valueMetrics?.units ? formatMessage({ id: `cunits.mini.${valueMetrics?.units}` }) : ''}
            </Typography>
            <div
              style={{
                visibility: !showBulkEditor && editable ? 'visible' : 'hidden',
              }}
            >
              <NumberFormat
                autoFocus={false}
                allowNegative={false}
                thousandSeparator=' '
                allowedDecimalSeparator={[',', '.']}
                className={classnames(styles.valueInput, {
                  [styles.error]: !!inputValueError
                })}
                disabled={!editable}
                value={inputValue}
                onChange={this.handlerChangeValue}
                onBlur={this.handlerBlurValue}
              />
            </div>
          </div>
          <DefaultSimpleTooltip
            text={formatMessage({ id: 'cropModel.editByWeeks' })}
            position='topLeft'
            className={styles.iconTooltip}
          >
            <div
              className={classnames(
                styles.bulkEditIconWrapper,
                {
                  [styles.active]: showBulkEditor,
                }
              )}
            >
              <BulkEditIcon onClick={this.handlerClickBulkEdit} />
            </div>
          </DefaultSimpleTooltip>
        </div>
        {inputValueError && (
          <div className={styles.errorLabel}>{inputValueError}</div>
        )}
        {showBulkEditor && (
          <BulkEditTable
            points={points}
            onPointsChanged={this.handlerTablePointsChanged}
            valueMetrics={valueMetrics}
            editable={editable}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(SeriesEditorBlock);
