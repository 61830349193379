// @ts-ignore
import { uniqBy } from 'lodash/array';
import { isFinite } from 'lodash';

import { MetricDef, PresetMetricScale } from 'store/graphs/types';


// safe $-_.+!*'(),
// i can take $_+!*'
const GROUP_FIELDS_DELIMETER = '$';
const FIELDS_DELIMETER = '_';
const ARRAY_DELIMETER = '!';

function nmUrlParamsToNodeMetrics(nmGroups: Array<string>) {
  let presetMetrics: Array<MetricDef> = [];
  const scales: Array<PresetMetricScale> = [];

  for (let groupIndex = 0; groupIndex < nmGroups.length; groupIndex += 1) {
    const nodeMetric = nmGroups[groupIndex];
    if (nodeMetric.length > 0) {
      const [
        groupData,
        metricsData,
      ] = nodeMetric.split(GROUP_FIELDS_DELIMETER);

      const metricFields = groupData.split(FIELDS_DELIMETER);

      let treeType: string | null = null;
      let locationId: string | null = null;
      let node: string | null = null;
      let subNode: string | null = null;

      if (metricFields.length === 3) {
        treeType = 'g';
        ([locationId, node, subNode] = groupData.split(FIELDS_DELIMETER));
      } else if (metricFields.length === 2) {
        treeType = 'g';
        subNode = null;
        ([locationId, node] = groupData.split(FIELDS_DELIMETER));
      } else {
        ([treeType, locationId, node, subNode] = groupData.split(FIELDS_DELIMETER));
      }

      const metrics = metricsData.split(ARRAY_DELIMETER).map((metric: string): MetricDef => {
        const [
          metricId,
          // eslint-disable-next-line
          position,
          unselected,
          scaleName,
          invisible,
          rightAxis,
          start,
          end,
          color
        ] = metric.split(FIELDS_DELIMETER);
        const range = isFinite(+start) && isFinite(+end) ? {
          start: parseFloat(start),
          end: parseFloat(end)
        } : null;
        scales.push({
          invisible: Boolean(invisible),
          rightAxis: Boolean(rightAxis),
          name: scaleName || null,
          range
        });
        return {
          // position: +position || null,
          selected: !unselected,
          available: true, // TODO: Save to URL
          color: color ? `#${color}` : null,
          // @ts-ignore
          node: [treeType, +locationId, +node || 0, +subNode || 0, +metricId],
          scale: scaleName || null
        };
      });
      presetMetrics = presetMetrics.concat(metrics);
    }
  }
  return {
    presetMetrics,
    scales: uniqBy(scales, (scale:PresetMetricScale) => scale.name),
  };
}

export default nmUrlParamsToNodeMetrics;
