/* eslint-disable */

function TrapezeCurve(context, t) {
  this._context = context;
  this._t = t;
}

TrapezeCurve.prototype = {
  areaStart() {
    this._line = 0;
  },
  areaEnd() {
    this._line = NaN;
  },
  lineStart() {
    this._x = this._y = NaN;
    this._point = 0;
  },
  lineEnd() {
    if (this._t > 0 && this._t < 1 && this._point === 2) this._context.lineTo(this._x, this._y);
    if (this._line || (this._line !== 0 && this._point === 1)) this._context.closePath();
    if (this._line >= 0) {
      this._t = 1 - this._t;
      this._line = 1 - this._line;
    }
  },
  point(x, y) {
    x = +x;
    y = +y;
    switch (this._point) {
      case 0:
        this._point = 1;
        this._line ? this._context.lineTo(x, y) : this._context.moveTo(x, y);
        break;
      case 1:
        this._point = 2; // proceed
      default: {
        if (this._t <= 0) {
          this._context.lineTo(this._x + 4, y);
          this._context.lineTo(x, y);
        } else {
          const x1 = this._x * (1 - this._t) + x * this._t;
          this._context.lineTo(x1, this._y);
          this._context.lineTo(x1, y);
        }
        break;
      }
    }
    this._x = x;
    this._y = y;
  }
};

export default function stepTrapeze(context) {
  return new TrapezeCurve(context, 0);
}
