import { useEffect } from 'react';

import { getNameByLocal } from 'helpers/getNameByLocal';

import { select } from 'd3';

import styles from '../LineChart.module.css';

const TEXT_PADDING = 10;

function useBalance(groupRef, textGroupRef, options) {
  const {
    chartParams: {
      h, w, x, y, canvas
    },
    plantBalanceData,
    intl
  } = options;
  const { locale } = intl;
  useEffect(() => {
    const group = groupRef.current
      ? select(groupRef.current)
      : null;
    const textGroup = textGroupRef.current
      ? select(textGroupRef.current)
      : group;
    if (group && textGroup) {
      group
        .selectAll('*')
        .remove();

      textGroup
        .selectAll('*')
        .remove();

      if (plantBalanceData?.length > 0) {
        const figure = plantBalanceData[0];

        const xCoord = x(figure.center.x);
        const yCoord = y(figure.center.y);

        // draw rects
        group
          .append('rect')
          .attr('x', xCoord)
          .attr('y', 0)
          .attr('width', w - xCoord)
          .attr('height', yCoord)
          .attr('fill', '#F7F9FA');

        group
          .append('rect')
          .attr('x', 0)
          .attr('y', yCoord)
          .attr('width', xCoord)
          .attr('height', h - yCoord)
          .attr('fill', '#F7F9FA');

        // draw horizontal line
        group
          .append('line')
          .attr('width', w)
          .attr('height', 1)
          .attr('class', styles.lineX)
          .attr('y1', yCoord)
          .attr('y2', yCoord)
          .attr('x1', '0')
          .attr('x2', w);

        // draw vertical line
        group
          .append('line')
          .attr('width', '1')
          .attr('height', h)
          .attr('class', styles.lineY)
          .attr('x1', xCoord)
          .attr('x2', xCoord)
          .attr('y1', '0')
          .attr('y2', h);

        // draw labels
        if (figure?.zoneLabels?.topLeft) {
          textGroup
            .append('text')
            .attr('class', 'label')
            .attr('y', TEXT_PADDING)
            .attr('x', TEXT_PADDING)
            .attr('font-size', '12px')
            .attr('fill', '#4A4A49')
            .attr('alignment-baseline', 'hanging')
            .text(getNameByLocal(figure?.zoneLabels, locale, 'topLeft'));
        }

        if (figure?.zoneLabels?.bottomLeft) {
          textGroup
            .append('text')
            .attr('class', 'label')
            .attr('y', h - TEXT_PADDING)
            .attr('x', TEXT_PADDING)
            .attr('font-size', '12px')
            .attr('fill', '#4A4A49')
            .attr('alignment-baseline', 'alphabetic')
            .text(getNameByLocal(figure?.zoneLabels, locale, 'bottomLeft'));
        }

        if (figure?.zoneLabels?.topRight) {
          textGroup
            .append('text')
            .attr('class', 'label')
            .attr('y', TEXT_PADDING)
            .attr('x', w - TEXT_PADDING - canvas.leftPadding)
            .attr('font-size', '12px')
            .attr('fill', '#4A4A49')
            .attr('alignment-baseline', 'hanging')
            .attr('text-anchor', 'end')
            .text(getNameByLocal(figure?.zoneLabels, locale, 'topRight'));
        }

        if (figure?.zoneLabels?.bottomRight) {
          textGroup
            .append('text')
            .attr('class', 'label')
            .attr('y', h - TEXT_PADDING)
            .attr('x', w - TEXT_PADDING - canvas.leftPadding)
            .attr('font-size', '12px')
            .attr('fill', '#4A4A49')
            .attr('alignment-baseline', 'alphabetic')
            .attr('text-anchor', 'end')
            .text(getNameByLocal(figure?.zoneLabels, locale, 'bottomRight'));
        }
      }
    }
  }, [
    groupRef,
    textGroupRef,
    h,
    w,
    x,
    y,
    plantBalanceData,
    locale,
    canvas
  ]);
}

export default useBalance;
