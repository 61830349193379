import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';


import ReactTooltip from 'react-tooltip';
import tooltipStyles from 'components/Tooltip/index.module.css';
import isTouchDevice from 'helpers/isTouchDevice';
import { uniqueId } from 'lodash';
import styles from './DefaultPlate.module.css';
import WarningIcon from '../Icons/WarningIcon';
import InfoIcon from '../Icons/InfoIcon';

const DefaultPlate = React.memo(({
  children, className, type, iconTooltip
}) => {
  const tooltipId = uniqueId('default-plate-icon-');
  return (
    <div
      className={classnames(
        styles.plate,
        className,
        {
          [styles.error]: type === 'error',
          [styles.warning]: type === 'warning',
        }
      )}
    >
      {type === 'warning' ? (
        <WarningIcon
          className={styles.warningIcon}
          data-tip={iconTooltip}
          data-for={isTouchDevice() ? null : tooltipId}
        />
      ) : (
        <InfoIcon
          className={styles.infoIcon}
          data-tip={iconTooltip}
          data-for={isTouchDevice() ? null : tooltipId}
        />
      )}
      <div>
        {children}
      </div>
      {iconTooltip && (
        <ReactTooltip
          className={classnames(tooltipStyles.smallTooltip, styles.tooltip)}
          id={tooltipId}
          effect='solid'
          place='bottom'
          event={isTouchDevice() ? 'click' : null}
          html
        />
      )}
    </div>
  );
});

DefaultPlate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  iconTooltip: PropTypes.string
};

DefaultPlate.defaultProps = {
  type: 'default',
  className: null,
  iconTooltip: null
};

export default DefaultPlate;
