import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getDefaultOpenFromRoute } from 'store/company/selectors';
import { trackPageViewSettings } from 'store/company/actions';

import {
  workTypesSelector,
  getIsLaborReportFetching
} from 'store/labor/selectors';
import { requestLaborWorkTypes } from 'store/labor/actions';

import SettingsLaborWorks from '../components/SettingsLaborWorks';

const mapDispatchToProps = {
  requestLaborWorkTypes,
  trackPageViewSettings,
};

const mapStateToProps = (state, props) => ({
  workTypes: workTypesSelector(state),
  isFetching: getIsLaborReportFetching(state),
  defaultOpen: getDefaultOpenFromRoute(state, props),

  ...props,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsLaborWorks));
