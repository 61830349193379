import React from 'react';

const renderRows = ({
  styles,
  workTypes,
  formatMessage,
  locale
}) => workTypes.map(workType => (
  <tr key={`workType-${workType.id}`}>
    <td className={styles.alignLeft}>{workType?.mlName?.[locale] || workType?.mlName?.en || '—'}</td>
    <td className={styles.alignLeft}>{workType?.unit ? formatMessage({ id: `cunits.mini.${workType.unit}` }) : '—'}</td>
  </tr>
));

export default renderRows;
