import CanvasComponent from 'components/CanvasTimeline/components/CanvasComponent';
import { getColWidth } from 'components/CanvasTimeline/helpers/TimeLineUtils';
import { HEADER_HEIGHT } from 'components/CanvasTimeline/helpers/constants';
import { addPoints } from 'components/CanvasTimeline/helpers/GeometryUtils';

class HorizontalTimeline extends CanvasComponent {
  move = { x: 0, y: 0 }

  scrollX = 0;

  constructor({
    position = { x: 0, y: 0 },
    thickLines,
    descriptor,
    viewport,
  }) {
    super({
      position
    });

    this.updateView({
      viewport
    });

    this.updateData({
      thickLines,
      descriptor,
    });

    this.setZIndex(2000);
  }

  getSize() {
    return {
      width: this.viewport.width,
      height: HEADER_HEIGHT
    };
  }

  setTimelineScroll({ x }) {
    this.scrollX = x;
  }

  updateBoundingBox() {
    // super.updateBoundingBox();
    this.bbox = {
      x: 0,
      y: 0,
      ...this.getSize(),
    };
  }

  onPointerMove({ x, y }) {
    super.onPointerMove({ x, y });
    this.move = { x, y }; // TODO: Refactoring
  }

  updateView({
    viewport
  }) {
    this.viewport = viewport;
  }

  updateData({
    thickLines,
    descriptor,
  }) {
    this.thickLines = thickLines;
    this.descriptor = descriptor;
  }

  draw(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    super.draw(ctx, {
      // eslint-disable-next-line no-unused-vars
      translateAdd
    });

    this.drawHorizontalTimeline({
      ctx,
      mousePosition: this.move,
      position: addPoints(this.getPositionAbs(), { x: this.scrollX, y: 0 }),
    }, {
      thickLines: this.thickLines,
      descriptor: this.descriptor,
    });
  }

  drawHorizontalTimeline = ({
    ctx, position, mousePosition
  }, { descriptor }) => {
    ctx.fillStyle = '#FFF';
    ctx.fillRect(0, 0, ctx.canvas.width, HEADER_HEIGHT);
    ctx.font = 'normal normal 400 12px Roboto';

    const {
      relative,
      currentWeek,
      periodsBefore,
      year
    } = descriptor;

    const isRelative = relative === 'true';

    const periods = [...periodsBefore.slice(isRelative ? -7 : -5)].concat(descriptor?.periods);

    const textMarginLeft = isRelative ? 24 : 10; // TODO: Wtf ?
    const colWidth = getColWidth(descriptor);
    let lastMonth = null;
    let periodX = 0;
    for (
      let i = 0;
      i < periods.length;
      i += 1
    ) {
      const period = periods[i];
      const periodYear = period?.endDate.getUTCFullYear();
      const isCurrentWeek = period?.periodNumber === currentWeek && periodYear === year;
      const fontWeight = isCurrentWeek ? 500 : 400;
      ctx.fillStyle = isCurrentWeek ? '#1dbadf' : '#777776';
      ctx.font = `normal normal ${fontWeight} 12px Roboto`;
      ctx.textAlign = 'center';
      const overlap = mousePosition.x - position.x > periodX &&
        mousePosition.x - position.x < periodX + colWidth &&
        mousePosition.y > 30 && mousePosition.y < HEADER_HEIGHT;
      let periodText = period?.periodNumber;
      if (overlap && period?.endDate) {
        periodText = `${period.startDate.getDate()}-${period.endDate.getDate()}`;
      }
      ctx.fillText(
        `${periodText}`,
        position.x + periodX + textMarginLeft,
        HEADER_HEIGHT - 12
      );
      if (period?.endDate) {
        const month = period.endDate.getMonth() + 1;
        if ((!lastMonth || lastMonth !== month) && i > 0) {
          ctx.font = 'normal normal normal 13px Roboto';
          ctx.fillStyle = '#777776';
          ctx.textAlign = 'left';
          ctx.fillText(
            `${period?.monthText}`,
            position.x + periodX + 7 + (periods.length - 1 === i ? -30 : 0),
            18
          );
        }
        lastMonth = month;
      }
      periodX += colWidth;
    }

    ctx.strokeStyle = '#E7E9EE';
    ctx.beginPath();
    ctx.moveTo(0, HEADER_HEIGHT);
    ctx.lineTo(ctx.canvas.width, HEADER_HEIGHT);
    ctx.stroke();
  };
}

export default HorizontalTimeline;
