import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import sizeMe from 'react-sizeme';
import * as moment from 'moment';

import { flatten, get } from 'lodash';

import multiformat from 'helpers/multiformat';

import LinkedButton from 'components/LinkedButton';
import EmptyState from 'components/EmptyState';
import Legend from 'components/Legend';
import ArrowLeftIcon from 'components/Icons/WalkArrowLeftIcon';
import ArrowRightIcon from 'components/Icons/WalkArrowRightIcon';

import LineChart from 'components/LinesGraph/components/LineChart';

import ChartTooltip from 'components/LinesGraph/components/ChartTooltip';
import styles from './LinesGraph.module.css';

const renderDisabledCategoriesMessage = formatMessage => (
  <div>
    <div>{formatMessage({ id: 'harvestDashboard.harvestByCategoriesNotEnabled' })}</div>
    <div>
      {formatMessage({ id: 'harvestDashboard.toEnable' })}
      {' '}
      <LinkedButton
        theme='default'
        className={styles.supportButton}
        onClick={() => {
          if (window.Intercom) {
            window.Intercom('show');
          }
        }}
        title={formatMessage({ id: 'harvestDashboard.contactSupport' })}
      />
      .
    </div>
  </div>
);

// very strange shit
const getBarsCount = ({ items, width }) => {
  if (items) {
    if (width >= 720) {
      return items.length;
    } if (width >= 480) {
      return 20;
    }
    return 14;
  }
  return 0;
};

// TODO: From work
// - Check display cycle
// - Tooltip, not working for single usage
// - Display circles on lines, current time showing only for first line
// - Check calculate x axis range

// TODO: Reduce props, remove reverse
const LinesGraph = ({
  intl,
  lines,
  rightLines,
  isOverlapedEmptyState,
  isEmbeddedEmptyState,
  isDataFetching,
  wrapperClassName,
  isFlexModeEnabled, // WTF ?
  withoutPoints,
  isHoverLineSizeNeedCalc,
  xType,
  yTicks,
  xTicks,
  xAxisPadding,
  margin,
  xDomainRange, // TODO: Refactoring
  // yDomainRange: yDomainRangeProps, // TODO: Refactoring
  // rightYDomainRange, // TODO: Refactoring

  lineColors,
  lineStyles,

  showTooltip,
  closestPointCoordX,
  screenY,
  screenX,

  isEmpty,
  customTicksFunction,
  customGetTicksRange,
  defaultSize,
  size,

  tickTimeDisplayFormat,
  onTooltipChanged,
  isLegendDisabled,

  tooltipDate,
  renderTooltipContent,

  tooltipClassName,
  // TODO: Refactoring
  minValue,
  maxValue,
  rightMinValue,
  rightMaxValue,

  // feature specified
  // TODO: Refactoring
  harvestByCategoriesEnabled,
  graphType,
  emptyStateText,
  dataPreparer,
  withoutMinMax,
  cropEmptyValues,
  overlay,
  emptyTitle,
}) => {
  const { formatMessage, locale } = intl;

  const [tooltipState, setTooltipState] = useState({
    showTooltip,
    screenY,
    screenX,
    closestPointCoordX,
    closestPoint: undefined,
    tooltipDate,
  });

  const lineChartWrapper = useRef(null);

  const [chartEndItem, setChartEndItem] = useState(undefined);

  // TODO: Refactoring
  const allLines = useMemo(() => [...lines, ...rightLines], [lines, rightLines]);
  const pointsCount = useMemo(() =>
    allLines.reduce((acc, item) => acc + item.points.length, 0), [allLines]);
  const isRenderEmptyState = !isEmbeddedEmptyState && pointsCount === 0 && !isDataFetching;
  const filteredLines = useMemo(() => allLines, [allLines]);
  const samplePoints = useMemo(() => get(filteredLines, '0.points', []), [filteredLines]);
  const colorsForGraph = useMemo(() =>
    allLines.reduce((acc, item) => ([...acc, item.color]), []), [allLines]);
  const areaData = useMemo(() => filteredLines.filter(item => item.area), [filteredLines]);
  const chartLineStyles = useMemo(() => lineStyles || allLines.map(line => line.lineStyle), [allLines, lineStyles]);

  const linesForGraph = useMemo(() => lines
    .filter(item => item.points.length > 0)
    // .reverse()
    .reduce((acc, item) => ([
      ...acc,
      item.points,
    ]), []), [lines]);

  const rightLinesForGraph = useMemo(() => rightLines
    .filter(item => item.points.length > 0)
    // .reverse()
    .reduce((acc, item) => ([
      ...acc,
      item.points,
    ]), []), [rightLines]);

  // feature specified
  // TODO: Refactoring
  const isCategoriesDisabled = graphType === 'qualityGraph' && !harvestByCategoriesEnabled;
  const emptyStateTextDefault = useMemo(() => (isCategoriesDisabled ? renderDisabledCategoriesMessage(formatMessage) :
    formatMessage({ id: 'harvestDashboard.emptyText' })), [isCategoriesDisabled, formatMessage]);

  // TODO: Remove after refactoring
  const yDomainRange = useMemo(() =>
    [minValue, maxValue], [minValue, maxValue]);
  const rightYDomainRange = useMemo(() =>
    [rightMinValue, rightMaxValue], [rightMinValue, rightMaxValue]);

  const handlerMove = ({ direction }) => {
    const chartPosition = chartEndItem || samplePoints.length - 1;
    // TODO: Refactoring
    const threshold = getBarsCount({ items: samplePoints, width: window.innerWidth });
    const diff = direction === 'back' ? -threshold : threshold;

    let newChartEnd = chartPosition + diff;

    if (newChartEnd - threshold < 0) {
      newChartEnd = threshold;
    }
    if (newChartEnd > samplePoints.length - 1) {
      newChartEnd = samplePoints.length - 1;
    }
    setChartEndItem(newChartEnd);
  };

  useEffect(() => {
    setTooltipState(prevState => ({
      ...prevState,
      tooltipDate,
      closestPointCoordX,
      showTooltip,
      screenY,
      screenX,
      setTooltipState
    }));
  }, [
    tooltipDate,
    closestPointCoordX,
    showTooltip,
    screenY,
    screenX,
    setTooltipState
  ]);

  const handlerMouseMove = (e) => {
    setTooltipState(prevState => ({
      ...prevState,
      screenY: e.clientY
    }));

    if (onTooltipChanged) {
      onTooltipChanged({
        screenY: e.clientY,
      });
    }
  };

  const handlerMouseEnter = () => {
    if (!tooltipState.showTooltip) {
      setTooltipState(prevState => ({ ...prevState, showTooltip: true }));
    }
    if (onTooltipChanged) {
      onTooltipChanged({
        showTooltip: true,
      });
    }
  };

  const handlerMouseLeave = () => {
    if (tooltipState.showTooltip) {
      setTooltipState(prevState => ({ ...prevState, showTooltip: false }));
    }

    if (onTooltipChanged) {
      onTooltipChanged({
        showTooltip: false,
      });
    }
  };

  const tickTimeDisplay = useCallback((xFromValue, xToValue, ticksCount) =>
    (tickTimeDisplayFormat ?
      tickTimeDisplayFormat(xFromValue, xToValue, ticksCount) :
      multiformat(locale)(xFromValue, xToValue, ticksCount)),
  [tickTimeDisplayFormat, locale]);

  const setTooltipData = useCallback(({
    closestPoint,
    closestPointCoordX: cpcX, // TODO: Shit code, maybe add rules to eslint
    closestPointCoordXWithOffset
  }) => {
    setTooltipState(prevState => ({
      ...prevState,
      screenX: closestPointCoordXWithOffset,
      closestPoint,
      closestPointCoordX: cpcX,
      tooltipDate: get(closestPoint, 'x'),
    }));

    if (onTooltipChanged) {
      onTooltipChanged({
        screenX: closestPointCoordXWithOffset,
        closestPoint,
        closestPointCoordX: cpcX,
        tooltipDate: get(closestPoint, 'x'),
      });
    }
  }, [onTooltipChanged, setTooltipState]);

  // TODO: replace to hook
  const isMobile = window.innerWidth < 720;

  const threshold = getBarsCount({ items: samplePoints, width: window.innerWidth });
  const chartPosition = chartEndItem || samplePoints.length - 1;
  const isBackDisabled = chartPosition - threshold <= 0;
  const isForwardDisabled = chartPosition >= samplePoints.length - 1 || threshold >= samplePoints.length;

  const width = useMemo(() => size.width || get(defaultSize, 'width') || 980, [size, defaultSize]);
  const height = useMemo(() => size.height || get(defaultSize, 'height') || 332, [size, defaultSize]);

  const renderedTooltipContent = renderTooltipContent ? renderTooltipContent(tooltipState.tooltipDate, tooltipState) : null;

  const isRenderTooltip = !isDataFetching && renderedTooltipContent && !isEmpty;
  const colors = useMemo(() => lineColors || colorsForGraph, [lineColors, colorsForGraph]);

  if (isRenderEmptyState && isOverlapedEmptyState) {
    return (
      <div className={styles.emptyStateWrapper}>
        <EmptyState
          className={classnames(styles.emptyState, { [styles.emptyStateBackground]: isCategoriesDisabled })}
          text={emptyStateText || emptyStateTextDefault}
          // isFixedSize
          isFlex
        />
      </div>
    );
  }

  return (
    <div
      ref={lineChartWrapper}
      className={classnames(styles.lineChartWrapper, wrapperClassName)}
      onPointerMove={handlerMouseMove}
      onMouseEnter={handlerMouseEnter}
      onMouseLeave={handlerMouseLeave}
    >
      {isMobile && isFlexModeEnabled ? (
        <div className={styles.actions}>
          <button
            type='button'
            className={classnames(styles.walkButton, { [styles.disabled]: isBackDisabled })}
            onClick={() => !isBackDisabled && handlerMove({ direction: 'back' })}
          >
            <ArrowLeftIcon className={styles.icon} />
          </button>
          <button
            type='button'
            className={classnames(styles.walkButton, { [styles.disabled]: isForwardDisabled })}
            onClick={() => !isForwardDisabled && handlerMove({ direction: 'forward' })}
          >
            <ArrowRightIcon className={styles.icon} />
          </button>
        </div>
      ) : null}
      <LineChart
        withoutPoints={withoutPoints}
        isHoverLineSizeNeedCalc={isHoverLineSizeNeedCalc}
        dataPreparer={dataPreparer}
        xType={xType}
        datePattern='%m-%d-%Y'
        yTicks={yTicks}
        xTicks={xTicks}
        axes
        grid
        xAxisPadding={xAxisPadding}
        margin={margin}
        width={width}
        height={height}
        // Если в maxValue приходит 0, значит вся линия лежит в 0. Ставим 99 чтобы график нормально отрисовался.
        xDomainRange={xDomainRange}
        yDomainRange={yDomainRange}
        rightYDomainRange={rightYDomainRange}
        positionHoverLineY={tooltipState.showTooltip ? tooltipState.screenX : null}
        mouseMoveCallback={setTooltipData}
        lineColors={colors}
        isDataFetching={isDataFetching}
        data={linesForGraph}
        rightData={rightLinesForGraph}
        tickTimeDisplayFormat={tickTimeDisplay}
        closestPointCoordX={tooltipState.closestPointCoordX}
        closestPoint={tooltipState.closestPoint}
        isEmpty={isEmpty}
        emptyTitle={emptyTitle}
        isFlexModeEnabled={isFlexModeEnabled}
        customTicksFunction={customTicksFunction}
        areaData={areaData}
        customGetTicksRange={customGetTicksRange}
        lineStyles={chartLineStyles}
        allLines={allLines}
        withoutMinMax={withoutMinMax}
        cropEmptyValues={cropEmptyValues}
        overlay={overlay}
      />
      {isRenderTooltip && (
        <ChartTooltip
          screenX={tooltipState.screenX}
          screenY={tooltipState.screenY}
          closestPointCoordX={tooltipState.closestPointCoordX}
          showTooltip={tooltipState.showTooltip}
          parent={lineChartWrapper.current}
          className={tooltipClassName}
        >
          {renderedTooltipContent}
        </ChartTooltip>
      )}
      {!isLegendDisabled && !isEmpty && <Legend lines={allLines} />}
      {isEmpty && isEmbeddedEmptyState && !isOverlapedEmptyState && (
        <div className={styles.emptyStateWrapperNoOverlap}>
          <EmptyState
            className={classnames(styles.emptyState, { [styles.emptyStateBackground]: isCategoriesDisabled })}
            text={emptyStateText || emptyStateTextDefault}
            // isFixedSize
            isFlex
          />
        </div>
      )}
    </div>
  );
};

LinesGraph.propTypes = {
  intl: PropTypes.object.isRequired,
  lines: PropTypes.array,
  rightLines: PropTypes.array,
  isOverlapedEmptyState: PropTypes.bool,
  isEmbeddedEmptyState: PropTypes.bool,
  isDataFetching: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  isFlexModeEnabled: PropTypes.bool,
  withoutPoints: PropTypes.bool,
  isHoverLineSizeNeedCalc: PropTypes.bool,
  dataPreparer: PropTypes.func,
  xType: PropTypes.string,
  xTicks: PropTypes.number,
  yTicks: PropTypes.number,
  xAxisPadding: PropTypes.number,
  margin: PropTypes.object,
  xDomainRange: PropTypes.array,
  // yDomainRange: PropTypes.array,
  // rightYDomainRange: PropTypes.array,
  showTooltip: PropTypes.bool,
  lineColors: PropTypes.array,
  closestPointCoordX: PropTypes.number,
  isEmpty: PropTypes.bool,
  customTicksFunction: PropTypes.func,
  customGetTicksRange: PropTypes.func,
  defaultSize: PropTypes.object,
  screenX: PropTypes.number,
  tickTimeDisplayFormat: PropTypes.func,
  onTooltipChanged: PropTypes.func,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  rightMaxValue: PropTypes.number,
  rightMinValue: PropTypes.number,
  renderTooltipContent: PropTypes.func,
  tooltipDate: PropTypes.array,
  screenY: PropTypes.number,
  tooltipClassName: PropTypes.string,
  isLegendDisabled: PropTypes.bool,
  size: PropTypes.object.isRequired,
  harvestByCategoriesEnabled: PropTypes.bool,
  graphType: PropTypes.string,
  emptyStateText: PropTypes.string,
  lineStyles: PropTypes.array,
  withoutMinMax: PropTypes.bool,
  cropEmptyValues: PropTypes.bool,
  overlay: PropTypes.object,
  emptyTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};

LinesGraph.defaultProps = {
  lines: [],
  rightLines: [],
  isOverlapedEmptyState: true,
  isEmbeddedEmptyState: false,
  isDataFetching: false,
  wrapperClassName: null,
  isFlexModeEnabled: false,
  withoutPoints: false,
  isHoverLineSizeNeedCalc: false,
  dataPreparer: data => flatten(data).map(point => ({ ...point, x: moment.utc(point.x, 'MM-DD-YYYY') })),
  xType: 'time',
  xTicks: 7,
  yTicks: 4,
  xAxisPadding: 0,
  margin: null,
  xDomainRange: null,
  // yDomainRange: [0, 100], // TODO: Tracking change (not used)
  // rightYDomainRange: [0, 100], // TODO: Tracking change (not used)
  showTooltip: true,
  lineColors: null,
  closestPointCoordX: null,
  isEmpty: false,
  customTicksFunction: null,
  customGetTicksRange: null,
  defaultSize: null,
  screenX: null,
  tickTimeDisplayFormat: null,
  onTooltipChanged: null,
  minValue: 0, // TODO: Refactoring
  maxValue: 100, // TODO: Refactoring
  rightMinValue: 0, // TODO: Refactoring
  rightMaxValue: 100, // TODO: Refactoring
  renderTooltipContent: null,
  tooltipDate: null,
  screenY: null,
  tooltipClassName: null,
  isLegendDisabled: false,
  emptyStateText: null,
  harvestByCategoriesEnabled: false,
  graphType: null,
  lineStyles: null,
  withoutMinMax: true,
  cropEmptyValues: false,
  overlay: null,
  emptyTitle: undefined,
};

export default sizeMe({ monitorWidth: true })(injectIntl(LinesGraph));
