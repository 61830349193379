import React, { memo } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { isNil } from 'lodash';

import numbersRounding from '../../../../helpers/numbersRounding';

import styles from './AvgHarvest.module.css';

const AvgHarvest = ({
  headerText, groupText, value, units, groupValue, changeValue, changeUnits, roundTo, className
}) => (
  <div className={classnames(styles.container, className)}>
    <div className={classnames(styles.header)}>
      {headerText}
    </div>
    <div className={classnames(styles.group)}>
      <span className={classnames(styles.value)}>
        {numbersRounding(value, 'fixed', roundTo)}
      </span>
      <span className={classnames(styles.units)}>
        {` ${units}`}
      </span>
      {groupValue && (
        <span className={classnames(styles.groupValue)}>
          {`${groupText} ${numbersRounding(groupValue, 'fixed', roundTo)}`}
        </span>
      )}
      {!isNil(changeValue) && changeUnits && (
        <span className={classnames(styles.changeValue)}>
          {` (${numbersRounding(changeValue, 'fixed', 0)}${changeUnits})`}
        </span>
      )}
    </div>
  </div>
);

AvgHarvest.propTypes = {
  headerText: PropTypes.string.isRequired,
  groupText: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  units: PropTypes.string.isRequired,
  groupValue: PropTypes.number,
  changeValue: PropTypes.number,
  changeUnits: PropTypes.string,
  roundTo: PropTypes.number,
  className: PropTypes.string,
};

AvgHarvest.defaultProps = {
  groupValue: null,
  changeValue: null,
  changeUnits: null,
  roundTo: 1,
  className: null,
};

export default memo(AvgHarvest);
