import {
  useCallback, useEffect, useState
} from 'react';

function useMouseDrag(ref, initValue) {
  const [state, setState] = useState({
    isDragging: false,
    origin: initValue || null,
    translation: { x: 0, y: 0 },
    position: initValue || null,
  });

  const handleMouseDown = useCallback(({ clientX, clientY }) => {
    setState(oldState => ({
      ...oldState,
      isDragging: true,
      origin: { x: clientX, y: clientY }
    }));
  }, []);

  const handleMouseMove =
    ({
      clientX, clientY, movementX, movementY
    }) => {
      const rect = ref?.current?.getBoundingClientRect();
      const offset = { x: rect?.left || 0, y: rect?.top || 0 };
      const translation = {
        x: (clientX - offset.x) - state.origin.x,
        y: (clientY - offset.y) - state.origin.y
      };
      setState((oldState) => {
        const position = {
          x: (oldState?.position?.x || 0) + movementX,
          y: (oldState?.position?.y || 0) + movementY
        };
        return {
          ...oldState,
          translation,
          position
        };
      });
    };

  const handleMouseUp = useCallback(({ clientX, clientY }) => {
    setState(oldState => ({
      ...oldState,
      isDragging: false,
      translation: { x: clientX, y: clientY }
    }));
  }, []);

  useEffect(() => {
    const el = ref.current;
    if (el) {
      el.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      if (el) {
        el.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      }
    };
    // eslint-disable-next-line
  }, []);

  const setInitPosition = useCallback((position) => {
    setState(prevState => ({
      ...prevState,
      position
    }));
  }, [setState]);

  return [state, setInitPosition];
}

export default useMouseDrag;
