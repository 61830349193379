import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getSendingCodeInfo,
  getRetryInSecs,
  getIsSendingCodeInfoFetching,
  getNumberOfDigits,
  getCodeTicket,
  getAuthTicket,
  getWhenRetryIsAvailable,
  getWhenTicketIsExpired,
  getIsVerifyCodeFetching,
  getVerifyCodeError,
  getWhenCodeIsExpired,
} from 'store/login/selectors';
import {
  requestVerifyCode,
  requestSendingCode,
  clearVerificationErrors,
  setVerificationError,
} from 'store/login/actions';

import LoginVerification from '../components/LoginVerification';

const mapDispatchToProps = {
  requestVerifyCode,
  requestSendingCode,
  clearVerificationErrors,
  setVerificationError,
};

const mapStateToProps = (state, props) => ({
  ...props,

  sendingCodeInfo: getSendingCodeInfo(state, props),
  retryInSecs: getRetryInSecs(state, props),
  isFetching: getIsSendingCodeInfoFetching(state, props) || getIsVerifyCodeFetching(state, props),
  numberOfDigits: getNumberOfDigits(state, props),
  codeTicket: getCodeTicket(state, props),
  authTicket: getAuthTicket(state, props),
  verifyCodeError: getVerifyCodeError(state, props),

  whenRetryIsAvailable: getWhenRetryIsAvailable(state, props),
  whenTicketIsExpired: getWhenTicketIsExpired(state, props),
  whenCodeIsExpired: getWhenCodeIsExpired(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginVerification)));
