import { useEffect } from 'react';
import { line, select } from 'd3';

import interpolateLine from '../interpolate';

function buildLine({
  x,
  y,
  xValue,
  yValue,
  group,
  trace,
  cropNullValues
}) {
  const data = trace.x.map((xVal, xIndex) => ({ x: xVal, y: trace.y[xIndex] }));

  let lineDef = line()
    .x(d => x(xValue(d)))
    .y(d => y(yValue(d)))
    .curve(interpolateLine(null));

  if (cropNullValues) {
    lineDef = lineDef.defined(d => d.y != null);
  }

  group
    .append('g')
    .selectAll('path')
    .data([data])
    .enter()
    .append('path')
    .attr('class', 'line')
    .style('stroke', trace?.line?.color)
    .style('stroke-dasharray', trace?.line?.dash === 'dash' ? '6,3' : '0,0')
    .attr('d', lineDef);
}

function buildLinesGroup({
  groupRef,
  x,
  y,
  xValue,
  yValue,
  linesTraces,
  cropNullValues
}) {
  const group = groupRef.current ? select(groupRef.current) : null;
  if (group) {
    group.selectAll('*').remove();

    for (let i = 0; i < linesTraces.length; i += 1) {
      const trace = linesTraces[i];
      const lineOption = {
        x,
        y,
        xValue,
        yValue,
        group,
        trace,
        cropNullValues
      };
      buildLine(lineOption);
    }
  }
}


function useLines(groupRef, options) {
  const {
    chartParams: {
      x, y, xValue, yValue
    },
    linesTraces,
    cropNullValues
  } = options;
  useEffect(() => {
    buildLinesGroup({
      groupRef,
      x,
      y,
      xValue,
      yValue,
      linesTraces,
      cropNullValues
    });
  }, [
    groupRef,
    x, y, xValue, yValue,
    linesTraces,
    cropNullValues
  ]);
}

export default useLines;
