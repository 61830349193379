import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { injectIntl, intlShape } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import classnames from 'classnames';

import { ANIMATION_DURATION, MEDIA_DESKTOP_SMALL } from '../../../helpers/constants';
import isPhoneView from '../../../helpers/isPhoneView';

import SpeedometerIcon from '../../Icons/SpeedometerIcon';
import HarvestIcon from '../../Icons/HarvestIcon';
import GraphIcon from '../../Icons/GraphIcon';
import PlanFactIcon from '../../Icons/PlanFactIcon';
import BenchmarkingIcon from '../../Icons/BenchmarkingIcon';
import EnergyIcon from '../../Icons/EnergyIcon';
import LaborIcon from '../../Icons/LaborIcon';

import styles from './DashboardsMenu.module.css';

class DashboardsMenu extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    setDashboardsMenu: PropTypes.func.isRequired,
    setDashboardsCurrentTab: PropTypes.func.isRequired,
    isBenchmarkAvailable: PropTypes.bool.isRequired,
    isDashboardsMenuOpened: PropTypes.bool,
  };

  static defaultProps = {
    isDashboardsMenuOpened: null,
  };

  handlerCloseMenu = () => {
    const { setDashboardsMenu } = this.props;

    setDashboardsMenu({ isDashboardsMenuOpened: false });
  };

  handlerClickLink = (tabName) => {
    const { setDashboardsCurrentTab } = this.props;
    const isPhoneOrTablet = isPhoneView(MEDIA_DESKTOP_SMALL);

    setDashboardsCurrentTab({ dashboardsCurrentTab: tabName });

    if (isPhoneOrTablet) {
      this.handlerCloseMenu();
    }
  }

  renderMenu = (dashboardsList, mainDashboardPath) => dashboardsList.map(dashboard => (
    <NavLink
      key={`menuItem-${dashboard.value}`}
      to={dashboard.href}
      className={styles.menuLink}
      activeClassName={styles.routeActive}
      onClick={() => this.handlerClickLink(dashboard.value)}
      isActive={(match, location) => {
        /**
         * Нужна своя функция isActive, т.к. основной дашборд доступен как
         * по /dashboards, так и по dashboards/targets
         */
        if (dashboard.value === 'targets' && location.pathname === mainDashboardPath) {
          return true;
        }

        if (match) {
          return true;
        }

        return false;
      }}
    >
      <div className={styles.iconWrapper}>{dashboard.icon}</div>
      <div>{dashboard.text}</div>
    </NavLink>
  ));

  render() {
    const {
      intl, match, children, isDashboardsMenuOpened, isBenchmarkAvailable
    } = this.props;

    const { formatMessage } = intl;
    const { params: { organizationSlug } } = match;

    const targetsText = formatMessage({ id: 'dashboards.kpi' });
    const harvestText = formatMessage({ id: 'dashboards.harvest' });
    const cropsPerformanceText = formatMessage({ id: 'dashboards.cropsPerformanceMenu' });
    const planFactText = formatMessage({ id: 'dashboards.planFact' });
    const benchmarkingText = formatMessage({ id: 'dashboards.benchmarking' });
    const energyText = formatMessage({ id: 'dashboards.energyResources' });
    const laborText = formatMessage({ id: 'dashboards.laborResourcesShrink' });

    const mainDashboardPath = `/${organizationSlug}/dashboards`;
    let dashboardsList = [
      {
        href: `${mainDashboardPath}/targets`,
        text: targetsText,
        icon: <SpeedometerIcon />,
        value: 'targets'
      },
      {
        href: `${mainDashboardPath}/harvest`,
        text: harvestText,
        icon: <HarvestIcon />,
        value: 'harvest'
      },
      {
        href: `${mainDashboardPath}/plan-fact`,
        text: planFactText,
        icon: <PlanFactIcon />,
        value: 'plan-fact'
      },
      {
        href: `${mainDashboardPath}/crop-performance`,
        text: cropsPerformanceText,
        icon: <GraphIcon />,
        value: 'crop-performance'
      },
      {
        href: `${mainDashboardPath}/benchmarking`,
        text: benchmarkingText,
        icon: <BenchmarkingIcon />,
        value: 'benchmarking'
      },
      {
        href: `${mainDashboardPath}/energy`,
        text: energyText,
        icon: <EnergyIcon />, // EnergyIcon
        value: 'energy'
      },
      {
        href: `${mainDashboardPath}/labor-resources`,
        text: laborText,
        icon: <LaborIcon />, // EnergyIcon
        value: 'labor-resources'
      }
    ];


    if (!isBenchmarkAvailable) {
      dashboardsList = dashboardsList.filter(item => item.value !== 'benchmarking');
    }

    return (
      <CSSTransition
        in={isDashboardsMenuOpened}
        timeout={ANIMATION_DURATION}
        classNames={{
          enter: styles.menuEnter,
          enterActive: styles.menuEnterActive,
          exit: styles.menuLeave,
          exitActive: styles.menuLeaveActive,
        }}
      >
        <div className={classnames(styles.menuWrapper, { [styles.menuClosed]: !isDashboardsMenuOpened })}>
          <nav className={styles.menu}>
            {this.renderMenu(dashboardsList, mainDashboardPath)}
          </nav>
          <div className={styles.content}>
            {children}
            <div
              className={styles.overlay}
              onClick={this.handlerCloseMenu}
              role='button'
              tabIndex={0}
            />
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default injectIntl(withRouter(DashboardsMenu));
