import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import moment from 'moment-timezone';
import { get } from 'lodash';

import parseJwt from '../../../../helpers/parseJwt';

import BigButton from '../../../BigButton';
import PasswordField from '../../../PasswordField';
import LoginExpiredLink from '../../../LoginExpiredLink';
import DefaultCircleLoader from '../../../DefaultCircleLoader';

import styles from './LoginNewPassword.module.css';

class LoginNewPassword extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    jwt: PropTypes.string.isRequired,
    requestResetPassword: PropTypes.func.isRequired,
    requestValidateToken: PropTypes.func.isRequired,
    isResetPasswordFetching: PropTypes.bool,
    setForNewAccount: PropTypes.bool,
    tokenValidation: PropTypes.string,
    isTokenValidityFetching: PropTypes.bool,
  };

  static defaultProps = {
    isResetPasswordFetching: false,
    setForNewAccount: false,
    tokenValidation: null,
    isTokenValidityFetching: false,
  };

  state = {
    password: '',
    passwordError: null,
  };

  componentDidMount() {
    const { requestValidateToken, jwt } = this.props;

    // Проверяем не использовалась ли уже эта ссылка
    requestValidateToken({ jwt });

    // TODO: Вынести в HOC обработку enter
    document.addEventListener('keydown', this.handlerKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handlerKeyDown);
  }

  handlerKeyDown = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      return this.handlerResetPassword();
    }

    return null;
  };

  handlerResetPassword = () => {
    const {
      intl: { formatMessage },
      requestResetPassword,
      jwt,
    } = this.props;
    const { password } = this.state;

    const passwordError = password.length < 8 ? formatMessage({ id: 'password.passwordError' }) : null;

    if (passwordError) {
      this.setState({ passwordError });

      return;
    }

    requestResetPassword({ token: jwt, password });
  };

  handlerChangePassword = e => this.setState({
    password: e.target.value,
    passwordError: null
  });

  render() {
    const {
      intl: { formatMessage },
      isResetPasswordFetching,
      setForNewAccount,
      jwt,
      isTokenValidityFetching,
      tokenValidation,
    } = this.props;
    const { password, passwordError } = this.state;

    const parsedToken = parseJwt(jwt);
    const currentDateTimestamp = moment().unix();
    const expDateTimestamp = get(parsedToken, 'exp');
    const isLinkExpired = expDateTimestamp && currentDateTimestamp > expDateTimestamp;
    const isTokenUsed = tokenValidation === 'used';

    if (isTokenValidityFetching) {
      return <DefaultCircleLoader />;
    }

    if (isLinkExpired || isTokenUsed) {
      return <LoginExpiredLink />;
    }

    const headerText = setForNewAccount ? formatMessage({ id: 'password.setPassword' }) : formatMessage({ id: 'password.resetPassword' });
    const subHeaderText = setForNewAccount ? formatMessage({ id: 'password.enterPassword' }) : formatMessage({ id: 'password.enterNewPassword' });
    const placeholderText = setForNewAccount ? formatMessage({ id: 'login.password' }) : formatMessage({ id: 'password.newPassword' });
    const buttonText = setForNewAccount ? formatMessage({ id: 'password.continue' }) : formatMessage({ id: 'password.resetPasswordButton' });

    return (
      <div className={styles.resetPageWrapper}>
        <h2 className={styles.resetPageHeader}>{headerText}</h2>
        <div className={styles.resetPageSubHeader}>
          {subHeaderText}
        </div>
        <div className={styles.passwordField}>
          <PasswordField
            value={password}
            placeholder={placeholderText}
            onChange={this.handlerChangePassword}
            error={passwordError}
          />
        </div>

        <BigButton
          title={buttonText}
          type='button'
          onClick={this.handlerResetPassword}
          theme='dark'
          className={styles.sendButton}
          titleClassName={styles.sendButtonTitle}
          isLoading={isResetPasswordFetching}
        />
      </div>
    );
  }
}

export default LoginNewPassword;
