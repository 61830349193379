import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getCurrentLocation,
  getHarvestForecastAccess,
  getIsAddHarvestForecastAvailable
} from 'store/company/selectors';
import {
  trackPageViewHarvestForecasts
} from 'store/company/actions';

import { requestGetForecastList, requestDeleteForecast, clearForecastState } from 'store/harvestForecast/actions';
import { getIsForecastListFetching, getForecastsList } from 'store/harvestForecast/selectors';

import HarvestForecastsList from '../components/HarvestForecastsList';

const mapDispatchToProps = {
  requestGetForecastList,
  requestDeleteForecast,
  clearForecastState,
  trackPageViewHarvestForecasts,
};

const mapStateToProps = (state, props) => ({
  ...props,

  location: getCurrentLocation(state),
  forecastsList: getForecastsList(state),
  isForecastListFetching: getIsForecastListFetching(state),
  harvestForecastAccess: getHarvestForecastAccess(state, props),
  isAddHarvestForecastAvailable: getIsAddHarvestForecastAvailable(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(HarvestForecastsList)));
