import { getColWidth } from 'components/CanvasTimeline/helpers/TimeLineUtils';
import {
  COMPARTMENT_BLOCK_HEIGHT,
  COMPARTMENT_HEADER_HEIGHT, COMPARTMENT_VARIETY_BLOCK_HEIGHT,
  DATA_LINE_HEIGHT, FIRST_COMPARTMENT_PADDING,
  HEADER_HEIGHT,
  LEFT_PANEL_WIDTH,
  LINE_BORDER_RADIUS,
  LINE_MARGIN
} from 'components/CanvasTimeline/helpers/constants';
import DataLineHeader from 'components/CanvasTimeline/components/DataLineHeader';
import numbersRounding from 'helpers/numbersRounding';
import numbersFormatting from 'helpers/numbersFormatting';
import { addPoints, intersectsWith } from 'components/CanvasTimeline/helpers/GeometryUtils';
import hex2rgba from 'helpers/hex2rgba';
import { roundRect } from 'components/CanvasTimeline/helpers/DrawUtils';
import { textEllipsis } from 'components/CanvasTimeline/helpers/TextUtils';
import ScrollArea from 'components/CanvasTimeline/components/ScrollArea';

const COMPARTMENT_PLAN_LINE_TOP_PADDING = 15;
const COMPARTMENT_ACTUAL_LINE_TOP_PADDING = 59;

class Timeline extends ScrollArea {
  constructor({
    position = { x: 0, y: 0 },
    descriptor,
    thickLines,
    intl,
    viewport,
    match
  }) {
    super({
      position,
      viewport,
      scrollOnDrag: true
    });

    this.intl = intl;
    this.match = match;

    this.viewport = viewport;
    this.descriptor = descriptor;
    this.thickLines = thickLines;

    this.updateData({
      descriptor,
      thickLines,
    });

    this.updateView({
      viewport
    });
  }

  handleTooltipShowed(tooltip) {
    for (let i = 0; i < this.children.length; i += 1) {
      const component = this.children[i];
      if (component instanceof DataLineHeader &&
        component.toolipComponent.isDisplay() &&
        component.toolipComponent !== tooltip
      ) {
        component.toolipComponent.setDisplay(false);
        component.handleTooltipClosed();
      }
    }
  }

  getContentWidth() {
    const colWidth = getColWidth(this.descriptor);
    return colWidth * this.descriptor?.periods?.length;
  }

  getContentHeight() {
    return this.sections?.length > 0 ?
      this.sections[this.sections.length - 1].y + 400 : 0;
  }

  getSize() {
    return {
      width: this.viewport.width - LEFT_PANEL_WIDTH,
      height: this.viewport.height - this.viewport.top - HEADER_HEIGHT
    };
  }

  setTimelineScroll({ x, y }) {
    this.setScrollPosition({ x, y });
  }

  updateBoundingBox() {
    super.updateBoundingBox();
    this.bbox = {
      x: LEFT_PANEL_WIDTH,
      y: HEADER_HEIGHT,
      ...this.getSize()
    };
  }

  onScroll({ x, y }) {
    super.onScroll({ x, y });
    this.handleTooltipShowed(null);
  }

  onClick({ x, y }) {
    super.onClick({ x, y });
    this.handleTooltipShowed(null);
  }


  updateView({
    viewport
  }) {
    this.updateViewport(viewport);

    this.setScrollSize({
      width: this.getContentWidth(),
      height: this.getContentHeight(),
    });

    for (let i = 0; i < this.children.length; i += 1) {
      const component = this.children[i];
      if (component instanceof DataLineHeader
      ) {
        component.updateViewport({
          ...this.getPositionAbs(),
          ...this.getSize(),
        });
      }
    }

    this.setDirty();
  }

  updateData({
    descriptor,
    thickLines,
  }) {
    this.removeChildren();

    this.descriptor = descriptor;
    this.thickLines = thickLines;

    this.sections = this.getSections({
      thickLines,
      breakdown: descriptor?.breakdown,
    });

    const colWidth = getColWidth(descriptor);
    for (let sectionIndex = 0; sectionIndex < this.sections.length; sectionIndex += 1) {
      const section = this.sections[sectionIndex];
      this.appendHeader({
        ...section,
        type: 'plan',
        match: this.match,
        year: descriptor?.year,
        intl: this.intl,
        colWidth,
        descriptor,
        actualVsPlan: section?.section?.actualVsPlan
      });
      this.appendHeader({
        ...section,
        type: 'actual',
        year: descriptor?.year,
        match: this.match,
        intl: this.intl,
        colWidth,
        descriptor,
        actualVsPlan: section?.section?.actualVsPlan
      });
    }

    this.setScrollSize({
      width: this.getContentWidth(),
      height: this.getContentHeight(),
    });

    this.setDirty();
  }

  draw(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    ctx.clearRect(0, 0, this.viewport.width, this.viewport.height);

    super.draw(ctx, {
      translateAdd
    });

    const position = addPoints(this.getPositionAbs(), this.scroll);

    this.drawBackground(ctx, position);

    this.drawLines(ctx, {
      thickLines: this.thickLines,
      descriptor: this.descriptor,
      sections: this.sections,
      position,
      viewport: this.viewport
    });

    this.drawAfter(ctx, {
      translateAdd
    });
  }

  drawBackground = (ctx, position) => {
    const {
      periods,
      currentWeek
    } = this.descriptor;
    const height = Math.max(this.getContentHeight() || ctx.canvas.height, ctx.canvas.height);
    const colWidth = getColWidth(this.descriptor);
    ctx.fillStyle = 'rgb(250, 251, 252)';
    ctx.fillRect(this.position.x, this.position.y, ctx.canvas.width, ctx.canvas.height);
    let index = 0;
    for (let x = 0; x < colWidth * periods.length; x += colWidth) {
      const { periodNumber } = periods[index];
      if (periodNumber === currentWeek) {
        ctx.fillStyle = '#e4f3f8';
        ctx.fillRect(
          position.x + x,
          position.y,
          colWidth,
          height
        );
      }
      ctx.fillStyle = '#e7e9ee';
      ctx.fillRect(
        position.x + x,
        position.y,
        1,
        height
      );
      index += 1;
    }
  };

  getValueUnitsLocalized = (value) => {
    const {
      formatMessage
    } = this.intl;

    return value?.units ? formatMessage({ id: `cunits.mini.${value?.units}` }) : '';
  }

  getLocalizedValue = (value, digits = 1.0) => {
    const units = this.getValueUnitsLocalized(value);

    return value?.amount ? `${numbersFormatting(
      numbersRounding(value.amount, 'fixed', digits)
    )} ${units}` : '—';
  }

  isVarietyView = () => this.descriptor?.breakdown === 'varietyWeight'

  appendHeader = ({
    type,
    colWidth,
    intl,
    match,
    year,
    section,
    plan,
    actual,
    y: compartmentBlockY,
    noActualData,
    noPlanData,
    descriptor,
    actualVsPlan
  }) => {
    const { formatMessage } = intl;
    const { params: { organizationSlug } } = match;
    const isPlan = type === 'plan';
    const series = isPlan ? plan : actual;
    const {
      serialRange,
      total,
    } = series;
    const {
      endInclusive,
      start,
    } = serialRange;
    const isEmpty = isPlan ? noPlanData : noActualData;

    const padding = isPlan ? COMPARTMENT_PLAN_LINE_TOP_PADDING : COMPARTMENT_ACTUAL_LINE_TOP_PADDING;

    const startX = this.position.x + colWidth * start;

    const x = startX + 4;
    const y = this.position.y + compartmentBlockY + padding - 18;

    const weeksCount = endInclusive - start + 1;

    const name = isPlan ? formatMessage({ id: 'dashboards.plan' }) :
      formatMessage({ id: 'dashboards.actual' });

    const unitLocalized = total?.units ?
      formatMessage({ id: `cunits.mini.${total.units}` }) : '';

    const totalText = total?.amount ? `${total.amount} ${unitLocalized}` : '—';

    const actualVsPlanValue = !isPlan && actualVsPlan ? actualVsPlan.amount : 0;

    const title = !isPlan && total?.amount ?
      `${totalText} (${actualVsPlanValue}%)` : totalText;

    const tooltipSubtitle = isPlan ? `${plan?.weeks} weeks` :
      `${actual?.weeks} of ${plan?.weeks || 0} weeks`;

    const tooltipLines = [];
    if (isPlan) {
      tooltipLines.push({
        titleText: formatMessage({ id: 'plans.plan' }),
        valueText: this.getLocalizedValue(plan?.total, 0)
      });
      if (plan?.plantingArea) {
        tooltipLines.push({
          titleText: formatMessage({ id: 'plans.plantingArea' }),
          valueText: this.getLocalizedValue({
            amount: plan?.plantingArea,
            units: 'squareMeter'
          }, 0)
        });
      }
    } else {
      tooltipLines.push({
        titleText: formatMessage({ id: 'plans.harvestedCapitalized' }),
        valueText: this.getLocalizedValue(actual?.total, 0)
      });
      tooltipLines.push({
        titleText: formatMessage({ id: 'plans.plan' }),
        valueText: this.getLocalizedValue(plan?.total, 0)
      });
      if (actual?.plantingArea) {
        tooltipLines.push({
          titleText: formatMessage({ id: 'plans.plantingArea' }),
          valueText: this.getLocalizedValue({
            amount: actual?.plantingArea,
            units: 'squareMeter'
          }, 0)
        });
      }
    }

    const periods = descriptor.periods.length;
    const header = new DataLineHeader({
      baseWidth: colWidth * weeksCount,
      position: {
        x,
        y
      },
      varietyTitle: section?.sectionTypeName,
      typeTitle: `${name}:`,
      typeValue: title,
      tooltipParams: {
        headerText: section?.sectionTypeName,
        headerLinkUrl: isPlan ? `/${organizationSlug}/plans/list?year=${year}` :
          `/${organizationSlug}/crops/${actual.id}`,
        subTitleText: tooltipSubtitle,
        lines: tooltipLines,
      },
      isEmpty,
      timelineWidth: periods * colWidth,
      colWidth,
      viewport: {
        ...this.getPositionAbs(),
        ...this.getSize(),
      },
      isVarietyView: this.isVarietyView()
    });

    header.on('tooltipShowed', (tooltip) => {
      this.handleTooltipShowed(tooltip);
    });

    this.addChild(header);
  }

  drawSeries = ({
    ctx,
    series,
    seriesType,
    descriptor,
    compartmentBlockY,
    padding,
    color,
    noData,
    position,
    viewport
  }) => {
    const USE_TIMELINE_COLORS = false;
    const {
      dataPoints,
      serialRange,
    } = series;

    const {
      noHarvestDataLocalized,
      noMatchedPlanLocalized,
      relative
    } = descriptor;

    const colWidth = getColWidth(descriptor);

    const {
      endInclusive,
      extendsAfter,
      extendsBefore,
      start,
    } = serialRange;

    const closeRight = !extendsAfter;
    const closeLeft = !extendsBefore;

    const x = position.x + (colWidth * start);
    const y = position.y + compartmentBlockY + padding;

    const weeks = endInclusive - start + 1;

    let rightMargin = 0;
    if (closeRight) {
      rightMargin += LINE_MARGIN;
    }
    if (closeLeft) {
      rightMargin += LINE_MARGIN;
    }

    const width = colWidth * (weeks) - rightMargin;

    const bbox = {
      x,
      y,
      width,
      height: DATA_LINE_HEIGHT,
    };

    if (!intersectsWith({
      x: this.position.x,
      y: this.position.y,
      width: viewport.width,
      height: viewport.height
    }, bbox)) {
      return;
    }

    const noMatchText = seriesType === 'plan' ? noMatchedPlanLocalized : noHarvestDataLocalized;

    let isEmptyData = dataPoints === null || dataPoints === undefined;
    const dataPointsValues = !isEmptyData ? Object.values(dataPoints) : [];
    if (dataPointsValues.length > 0) {
      isEmptyData = dataPointsValues.every(point => parseInt(point, 10) === -1);
    }

    let lineColor;
    let lineEmptyColor;
    if (USE_TIMELINE_COLORS) {
      lineColor = (noData || dataPointsValues.length <= 0) ? 'rgba(84, 107, 126, 0.15)' :
        hex2rgba(color, isEmptyData ? 0.5 : 1.0);
      lineEmptyColor = hex2rgba(color, 0.5);
    } else {
      lineColor = (noData || dataPointsValues.length <= 0) ? 'rgba(84, 107, 126, 0.15)' :
        hex2rgba(seriesType === 'plan' ? '#9d62cc' : '#d05e8e', isEmptyData ? 0.5 : 1.0);
      lineEmptyColor = hex2rgba(seriesType === 'plan' ? '#9d62cc' : '#d05e8e', 0.5);
    }

    if (!isEmptyData) {
      const pointKeys = Object.keys(dataPoints);
      if (pointKeys.length > 0) {
        const lineStartX = x + (closeLeft ? LINE_MARGIN : 0);

        const intervals = [];

        let prevIntervalIndex = 0;
        for (let keyIndex = 0; keyIndex < pointKeys.length; keyIndex += 1) {
          if (keyIndex > 0) {
            const key = pointKeys[keyIndex];
            const value = dataPoints[key];
            const numValue = parseInt(value, 10);
            const empty = numValue === -1;
            const isPrevEmpty = parseInt(dataPoints[pointKeys[keyIndex - 1]], 10) === -1;
            if (empty !== isPrevEmpty) {
              intervals.push({
                x: lineStartX + (prevIntervalIndex * colWidth),
                width: (keyIndex - prevIntervalIndex) * colWidth,
                color: isPrevEmpty ? lineEmptyColor : lineColor
              });
              prevIntervalIndex = keyIndex;
            }
            if (keyIndex === pointKeys.length - 1) {
              intervals.push({
                x: lineStartX + (prevIntervalIndex * colWidth),
                width: (keyIndex - prevIntervalIndex + 1) * colWidth - rightMargin,
                color: isPrevEmpty ? lineEmptyColor : lineColor
              });
            }
          }
        }

        if (intervals.length === 0 && pointKeys.length > 0) {
          intervals.push({
            x: lineStartX,
            width,
            color: lineColor
          });
        }

        intervals.forEach((interval, index) => {
          const leftBorder = closeLeft && index === 0 ? LINE_BORDER_RADIUS : 0;
          const rightBorder = closeRight && index === intervals.length - 1 ? LINE_BORDER_RADIUS : 0;
          roundRect(ctx, {
            ...interval,
            y,
            height: DATA_LINE_HEIGHT,
            fill: true,
            radius: {
              tl: leftBorder,
              bl: leftBorder,
              tr: rightBorder,
              br: rightBorder,
            },
            color: lineColor,
          });
        });

        ctx.fillStyle = '#FFF';
        ctx.textAlign = 'center';
        ctx.font = 'normal normal 500 12px Roboto';

        for (let keyIndex = 0; keyIndex < pointKeys.length; keyIndex += 1) {
          const key = pointKeys[keyIndex];
          const value = dataPoints[key];
          const numValue = parseInt(value, 10);
          if (numValue !== -1) {
            const textValue = numbersFormatting(
              numbersRounding(value, 'fixed', relative === 'true' ? 1 : 0)
            );
            ctx.fillText(
              textValue,
              x + keyIndex * colWidth + colWidth / 2,
              y + DATA_LINE_HEIGHT / 2 + 2
            );
          }
        }
      } else {
        roundRect(ctx, {
          x: x + (closeLeft ? LINE_MARGIN : 0),
          width,
          y,
          height: DATA_LINE_HEIGHT,
          fill: true,
          radius: {
            tl: closeLeft ? LINE_BORDER_RADIUS : 0,
            bl: closeLeft ? LINE_BORDER_RADIUS : 0,
            tr: closeRight ? LINE_BORDER_RADIUS : 0,
            br: closeRight ? LINE_BORDER_RADIUS : 0,
          },
          color: lineEmptyColor,
        });
      }
    } else {
      roundRect(ctx, {
        x: x + (closeLeft ? LINE_MARGIN : 0),
        width,
        y,
        height: DATA_LINE_HEIGHT,
        fill: true,
        radius: {
          tl: closeLeft ? LINE_BORDER_RADIUS : 0,
          bl: closeLeft ? LINE_BORDER_RADIUS : 0,
          tr: closeRight ? LINE_BORDER_RADIUS : 0,
          br: closeRight ? LINE_BORDER_RADIUS : 0,
        },
        color: lineColor,
      });

      ctx.fillStyle = (noData || dataPointsValues.length <= 0) ? '#777776' : '#FFF';
      ctx.font = 'normal normal 400 12px Roboto';
      ctx.textAlign = 'left';
      ctx.fillText(
        textEllipsis(ctx, noMatchText, weeks * colWidth - 12),
        x + 12,
        y + DATA_LINE_HEIGHT / 2 + 2
      );
    }
  };

  drawLines = (ctx, {
    position, descriptor, sections, viewport
  }) => {
    sections.forEach((sectionDef) => {
      this.drawSeries({
        ctx,
        series: sectionDef.plan,
        seriesType: 'plan',
        descriptor,
        compartmentBlockY: sectionDef.y,
        noData: sectionDef.noPlanData,
        padding: COMPARTMENT_PLAN_LINE_TOP_PADDING,
        color: sectionDef?.section?.colors?.plan,
        ...sectionDef,
        position,
        viewport,
      });
      this.drawSeries({
        ctx,
        series: sectionDef.actual,
        seriesType: 'actual',
        descriptor,
        compartmentBlockY: sectionDef.y,
        noData: sectionDef.noActualData,
        padding: COMPARTMENT_ACTUAL_LINE_TOP_PADDING,
        color: sectionDef?.section?.colors?.actual,
        ...sectionDef,
        position,
        viewport,
      });
    });
  };

  getSections = ({
    breakdown, thickLines
  }) => {
    const sectionsResult = [];

    const breakdownCompartment = breakdown === 'compartment';
    let compartmentBlockY = (breakdownCompartment ? FIRST_COMPARTMENT_PADDING : 0);

    let lastCompartmentId = null;

    for (
      let tickLineIndex = 0;
      tickLineIndex < thickLines.length;
      tickLineIndex += 1
    ) {
      const tickLine = thickLines[tickLineIndex];
      const {
        lines,
        compartment: {
          id: compartmentId
        }
      } = tickLine;

      if (!breakdownCompartment && (!lastCompartmentId || lastCompartmentId !== compartmentId)) {
        compartmentBlockY += COMPARTMENT_HEADER_HEIGHT;
      }

      lastCompartmentId = compartmentId;

      for (let lineIndex = 0; lineIndex < lines.length; lineIndex += 1) {
        const line = lines[lineIndex];
        const {
          sections,
        } = line;

        for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex += 1) {
          const section = sections[sectionIndex];
          const {
            plan,
            actual,
          } = section;
          sectionsResult.push({
            y: compartmentBlockY,
            plan: plan ? {
              ...plan,
              serialRange: {
                ...(plan?.serialRange || actual.serialRange)
              }
            } : {
              total: null,
              serialRange: {
                ...(actual?.serialRange || plan.serialRange)
              }
            },
            actual: actual ? {
              ...actual,
              serialRange: {
                ...(actual?.serialRange || plan.serialRange)
              }
            } : {
              total: null,
              serialRange: {
                ...(plan?.serialRange || actual.serialRange)
              }
            },
            noPlanData: !plan,
            noActualData: !actual,
            section,
          });
        }
        compartmentBlockY += breakdownCompartment ? COMPARTMENT_BLOCK_HEIGHT : COMPARTMENT_VARIETY_BLOCK_HEIGHT;
      }
    }

    return sectionsResult;
  };
}

export default Timeline;
