import * as d3 from 'd3';
import { useMemo } from 'react';

import { DateType } from 'store/graphs/types';
import { LinePoint } from '../components/ChartLine';
import { LineData, Point } from '../index';

function findPointIntersection(
  xValue: DateType,
  data: Array<LinePoint>
) {
  const bisectDateLeft = d3.bisector((d:LinePoint) => d.date).left;
  const iLeft = bisectDateLeft(data, xValue);
  const d0:LinePoint = data[iLeft - 1];
  const d1:LinePoint = data[iLeft];
  if (d0 && d1) {
    // @ts-ignore TODO: Moment types
    return xValue - d0.date > d1.date - xValue ? d1 : d0;
  } if (d0 || d1) {
    return d1 || d0;
  }
  return null;
}

function useHoveredLines(
  lines: Array<LineData>,
  xScaleRange: any, // TODO: Fix d3 scale range: ScaleTime<Range, Output, Unknown = never>
  mouse: Point,
) {
  return useMemo(() => {
    const xValue = xScaleRange.invert(mouse.x);
    return lines.map((line) => {
      const point: LinePoint | null = findPointIntersection(xValue, line.data);
      return {
        key: line.key,
        point,
        xLinePosition: point ? xScaleRange(point.date) : null,
        yLinePosition: point ? line.yScale(point.value) : null,
        color: line.color,
        xValue
      };
    });
  }, [
    mouse, xScaleRange, lines
  ]);
}

export default useHoveredLines;
