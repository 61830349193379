import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import Paper from 'components/Paper';
import DashboardReportTable from 'components/DashboardReportTable';
import DashboardReportBarChart from '../DashboardReportBarChart';
import DashboardReportSelectedChart from '../DashboardReportSelectedChart';

import styles from './DashboardReport.module.css';


class DashboardReport extends PureComponent {
  static propTypes = {
    graphKeyId: PropTypes.string.isRequired,
    report: PropTypes.object,
    sorting: PropTypes.object,
    onRowClick: PropTypes.func,
    onUpdateSorting: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onRowClick: null,
    report: null,
    sorting: null,
  };

  handlerRowClick = (options) => {
    const { onRowClick, graphKeyId } = this.props;

    if (onRowClick) {
      onRowClick({ ...options, key: graphKeyId });
    }
  }

  render() {
    const { report, sorting, onUpdateSorting } = this.props;

    return (
      <div className={styles.report}>
        <Paper className={classnames(styles.chart)}>
          <DashboardReportBarChart report={report} />
        </Paper>

        <Paper className={classnames(styles.chart, styles.chartLines)}>
          <DashboardReportSelectedChart report={report} graphKeyId='labor' />
        </Paper>

        <DashboardReportTable
          graphKeyId='labor'
          meter='labor'
          report={report}
          sorting={sorting}
          roundTo={0}
          formatCell={({ value }) => <>{value}%</>}
          onRowClick={this.handlerRowClick}
          onUpdateSorting={onUpdateSorting}
        />
      </div>
    );
  }
}

export default injectIntl(DashboardReport);
