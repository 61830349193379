import CanvasComponent from 'components/CanvasTimeline/components/CanvasComponent';
import { HEADER_HEIGHT, LEFT_PANEL_WIDTH } from 'components/CanvasTimeline/helpers/constants';
import Button from 'components/CanvasTimeline/components/Button';
import ButtonStyle from 'components/CanvasTimeline/components/ButtonStyle';
import { ReactComponent as PrevIcon } from 'components/CanvasTimeline/assets/prev.svg';
import { ReactComponent as NextIcon } from 'components/CanvasTimeline/assets/next.svg';
import { ReactComponent as ChartIcon } from 'components/CanvasTimeline/assets/chart.svg';

const yearNavStyles = {
  style: new ButtonStyle({
    padding: {
      l: 12, t: 10, r: 12, b: 10
    },
    font: 'normal normal 500 14px Roboto',
  }),
  hoverStyle: new ButtonStyle({
    fill: '#f7f9fa',
    stroke: '#B4C5D6',
    padding: {
      l: 12, t: 10, r: 12, b: 10
    },
    font: 'normal normal 500 14px Roboto',
  }),
};

class TimelineHUD extends CanvasComponent {
  prevButton = new Button({
    ...yearNavStyles,
    position: {
      x: 50,
      y: 50
    },
    text: '2077',
    iconComponent: PrevIcon,
    iconSide: Button.ICON_LEFT,
    iconColor: '#777776',
    iconPadding: 8
  });

  nextButton = new Button({
    ...yearNavStyles,
    position: {
      x: 50,
      y: 100
    },
    text: '2077',
    iconComponent: NextIcon,
    iconSide: Button.ICON_RIGHT,
    iconPadding: 8,
    iconColor: '#777776'
  });

  currentWeekButton = new Button({
    style: new ButtonStyle({
      padding: {
        l: 12, t: 11, r: 12, b: 11
      }
    }),
    hoverStyle: new ButtonStyle({
      fill: '#f7f9fa',
      stroke: '#B4C5D6',
      padding: {
        l: 12, t: 11, r: 12, b: 11
      }
    }),
    position: {
      x: 50,
      y: 200
    },
    text: 'Current week'
  });

  chartButton = new Button({
    style: new ButtonStyle({
      padding: {
        l: 8, t: 6, r: 8, b: 6
      }
    }),
    hoverStyle: new ButtonStyle({
      fill: '#f7f9fa',
      stroke: '#B4C5D6',
      padding: {
        l: 8, t: 6, r: 8, b: 6
      }
    }),
    position: {
      x: 50,
      y: 250
    },
    iconComponent: ChartIcon,
    iconSide: Button.ICON_MIDDLE,
    iconPadding: 0,
    iconColor: '#777776',
    iconScale: 1
  });

  constructor({
    position = { x: 0, y: 0 },
    year,
    viewport,
    currentWeekText,
  }) {
    super({
      position,
      transparent: true
    });

    this.addChild(this.prevButton);
    this.addChild(this.nextButton);
    this.addChild(this.chartButton);
    this.addChild(this.currentWeekButton);

    this.year = year;
    this.viewport = viewport;

    this.prevButton.off('click').on('click', () => {
      this.emit('clickPrevYear');
    });
    this.nextButton.off('click').on('click', () => {
      this.emit('clickNextYear');
    });
    this.chartButton.off('click').on('click', () => {
      this.emit('clickSummary');
    });
    this.currentWeekButton.off('click').on('click', () => {
      this.emit('clickCurrentWeek');
    });
    this.currentWeekButton.setText(currentWeekText);

    this.updateView({
      viewport
    });

    this.updateData({
      descriptor: { year }
    });

    this.prevButton.setZIndex(1000);
    this.nextButton.setZIndex(1000);
    this.chartButton.setZIndex(1000);
    this.currentWeekButton.setZIndex(1000);
  }

  draw(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    super.draw(ctx, {
      // eslint-disable-next-line no-unused-vars
      translateAdd
    });
  }

  getSize() {
    return this.viewport;
  }

  updateBoundingBox() {
    this.bbox = {
      x: LEFT_PANEL_WIDTH,
      y: HEADER_HEIGHT,
      ...this.getSize(),
    };
  }

  updateView({
    viewport
  }) {
    const topButtonPos = HEADER_HEIGHT + 16;
    const leftButtonPos = viewport.width - 8;
    const weekButtonSize = this.currentWeekButton.getSize();
    const chartButtonSize = this.chartButton.getSize();
    const buttonMargin = 18;
    const horizontalButtonMargin = 8;
    const bottomButtonPos = viewport.height - 72;
    this.viewport = viewport;
    this.prevButton.setPosition({
      x: LEFT_PANEL_WIDTH + buttonMargin,
      y: bottomButtonPos
    });
    this.nextButton.setPosition({
      x: leftButtonPos - weekButtonSize.width - buttonMargin - 48,
      y: bottomButtonPos
    });
    this.currentWeekButton.setPosition({
      x: leftButtonPos - weekButtonSize.width - buttonMargin,
      y: topButtonPos
    });
    this.chartButton.setPosition({
      x: leftButtonPos - weekButtonSize.width - buttonMargin - horizontalButtonMargin - chartButtonSize.width,
      y: topButtonPos
    });
    this.setDirty();
  }

  updateData({
    descriptor,
  }) {
    this.descriptor = descriptor;
    this.updateYearText(this.descriptor?.year);
  }

  updateYearText(year) {
    if (year) {
      const baseYear = parseInt(year, 10);
      this.prevButton.setText(`${baseYear - 1}`);
      this.nextButton.setText(`${baseYear + 1}`);
      this.setDirty();
    }
  }
}

export default TimelineHUD;
