export const ANIMATION_DURATION = 150;
export const HIDE_DELAY_MS = 400;

export const MEDIA_PHONE = 360;
export const MEDIA_PHONE_LANDSCAPE = 480;
export const MEDIA_TABLET = 720;
export const MEDIA_TABLET_BIG = 960;
export const MEDIA_TABLET_LANDSCAPE = 1024;
export const MEDIA_DESKTOP_SMALL = 1280;
export const MEDIA_DESKTOP = 1440;

export const LANGUAGES = [
  {
    locale: 'en',
    localeName: 'English',
  },
  {
    locale: 'fr',
    localeName: 'Français',
  },
  {
    locale: 'nl',
    localeName: 'Nederlands',
  },
  {
    locale: 'ru',
    localeName: 'Русский',
  },
  {
    locale: 'zh-cn',
    localeName: '中文',
  },
];

export const GREEN_ZONE = 99;
export const YELLOW_ZONE = 86;

export const HARVEST_METRICS = [-1001, -1005];
export const HIDDEN_METRICS = [...HARVEST_METRICS];

export const DEFAULT_ENERGY_RESOURCE_TYPE = 'gas';
export const DEFAULT_SPECIES = 'cucumber';

export const BLOCKED_DASHBOARDS_FOR_ESSENTIAL = ['benchmarking', 'climate-insights', 'labor-resources', 'energy'];

export const CYCLES_TO_COMPARE = 'cyclesToCompare';

export const CYCLES_TO_COMPARE_LIMIT = 3;
