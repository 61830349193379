/* eslint-disable no-underscore-dangle */

import moment from 'moment-timezone';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import { applyMiddleware, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import rootEpic from './epics';
import messages from '../helpers/intl';
import { makeRootReducer } from './reducers';
import storageWrapper from '../helpers/storageWrapper';

const safeLocalStorage = storageWrapper.get('localStorage');

const lang = navigator.language || 'en';
const locale = lang.slice(0, 2);
const resultLocale = locale === 'en' || locale === 'ru' ? locale : 'en';
const logger = createLogger({ collapsed: true, });
const localeMoment = safeLocalStorage.getItem('locale') || resultLocale;

moment.locale(localeMoment === 'en' ? 'en-gb' : localeMoment);

if (localeMoment === 'ru') {
  moment.updateLocale('ru', {
    monthsShort: {
      format: 'янв_фев_мар_апр_мая_июн_июл_авг_сен_окт_ноя_дек'.split('_'),
      standalone: 'янв_фев_мар_апр_май_июн_июл_авг_сен_окт_ноя_дек'.split('_')
    },
    weekdaysShort: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
    weekdaysMin: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
  });
}

if (!safeLocalStorage.getItem('locale')) {
  safeLocalStorage.setItem('locale', safeLocalStorage.getItem('locale') || resultLocale);
}

export default (initialState = {
  intl: {
    defaultLocale: 'en',
    locale: safeLocalStorage.getItem('locale') || resultLocale,
    messages: messages[safeLocalStorage.getItem('locale') || resultLocale],
  },
}, history) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const epicMiddleware = createEpicMiddleware(rootEpic);

  const middleware = [routerMiddleware(history), epicMiddleware, thunk];

  const urlParams = new URLSearchParams(window.location.search);

  if (window.__DEV__ || urlParams.has('debug')) {
    middleware.push(logger);
  }

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];

  let composeEnhancers = compose;

  if (window.__DEV__) {
    const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    if (typeof composeWithDevToolsExtension === 'function') {
      composeEnhancers = composeWithDevToolsExtension;
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    connectRouter(history)(makeRootReducer()),
    initialState,
    composeEnhancers(
      ...enhancers,
      applyMiddleware(...middleware),
    )
  );

  store.asyncReducers = {};

  return store;
};
