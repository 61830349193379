import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import BigButton from '../../../BigButton';

import styles from '../LoginReset/LoginReset.module.css';

const LoginResetSuccessSend = ({
  email, intl: { formatMessage },
}) => (
  <div className={styles.resetPageWrapper}>
    <h2 className={styles.resetPageHeader}>
      {formatMessage({ id: 'password.pleaseCheck' })}
    </h2>
    <div className={styles.resetPageSubHeader}>
      {formatMessage({ id: 'password.successSendFirst' })}
      <span className={styles.boldEmail}>{email}</span>
      {formatMessage({ id: 'password.successSendSecond' })}
    </div>

    <BigButton
      title={formatMessage({ id: 'password.backToSignIn' })}
      type='button'
      href='/login'
      theme='dark'
      className={styles.sendButton}
      titleClassName={styles.sendButtonTitle}
    />
  </div>
);

LoginResetSuccessSend.propTypes = {
  email: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

// LoginResetSuccess.defaultProps = {
//   className: null,
// };

export default memo(injectIntl(LoginResetSuccessSend));
