import axios from 'axios';
import queryString from 'query-string';
import { pickBy, startsWith } from 'lodash';

import storageWrapper from 'helpers/storageWrapper';

import history from '../history';

const redirectToLogin = () => {
  if (history && history.location.pathname.indexOf('login') === -1) {
    const searchParams = new URLSearchParams(history.location.search);
    const paramsString = Array.from(searchParams.entries())
      .filter(([key]) => key !== 'redirect')
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
    const redirectUrl = paramsString.length > 0 ?
      `${history.location.pathname}?${paramsString}` :
      history.location.pathname;
      history.push(`/login?redirect=${encodeURIComponent(redirectUrl)}`);
  }
};

const safeLocalStorage = storageWrapper.get('localStorage');

/**
 * Инициализация axios инстанса со стандартными настройками для api запроса.
 *
 * Делать инит инстанса нужно на каждый запрос, тк window.API, locale, jwt
 * могут либо еще не существовать на момент инита инстанса, либо могут быть изменены позднее
 *
 * @param {string} searchParams - Параметры search запроса url (после ?).
 * @param {number} timeout - Таймаут на запрос.
 * @param {object} otherParams - Дополнительные параметры запроса.
 * @return {object} Инстанс axios.
 */
const initAxiosInstanse = (searchParams, timeout = 10000, otherParams = {}) => {
  const jwt = safeLocalStorage.getItem('jwt');
  const locale = safeLocalStorage.getItem('locale');

  /**
   * Проверяем нет ли в search запроса служебных переменных для внутреннего использования.
   * Если есть, то передаём их на бэкенд. Начинаются с нижнего подчёркивания (_).
   */
  const parsedQuery = queryString.parse(searchParams);
  const internalUseParams = pickBy(parsedQuery, (value, key) => startsWith(key, '_'));

  const instance = axios.create({
    baseURL: `${window.API}/`,
    timeout,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${jwt}`,
      'X-Lang': locale
    },
    params: internalUseParams,
    ...otherParams,
  });

  // обработка запроса от разлогиненного пользователя (например, после включения 2FA)
  instance.interceptors.response.use(response => response, (error) => {
   if (error.response.status === 401) {
     redirectToLogin();
   }

   return Promise.reject(error);
 });

  return instance;
};

export default initAxiosInstanse;
