import { connect } from 'react-redux';

import {
  getLeftPhotoWeeks,
} from 'store/company/selectors';

import WeeksPhotos from './WeeksPhotos';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  weeks: getLeftPhotoWeeks(state, props),
});

export default connect(mapStateToProps, mapDispatchToProps)(WeeksPhotos);
