import { sortBy } from 'lodash';

import sortByLocal from '../../../helpers/sortByLocal';

const getSorted = (sortingType, data) => {
  switch (sortingType) {
    case 'work':
      return sortByLocal(data, 'nameText');
    case 'plan':
      return sortBy(data, 'plan');
    case 'harvested':
      return sortBy(data, 'harvest.total');
    case 'implementation':
      return sortBy(data, 'harvest.totalPercentage');
    case 'quality':
      return sortBy(data, 'harvest.secondGrade');
    case 'qualityPercent':
      return sortBy(data, 'harvest.secondGradePercentage');
    default:
      return data;
  }
};

export default getSorted;
