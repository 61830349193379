import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { isNull } from 'lodash';
import classnames from 'classnames';

import numbersRounding from 'helpers/numbersRounding';
import numbersFormatting from 'helpers/numbersFormatting';

import ClickOutside from 'components/ClickOutside';
import BigNumberInput from 'components/BigNumberInput';
import BigButton from 'components/BigButton';
import EditIcon from 'components/Icons/Edit';
import CloseIcon from 'components/Icons/Close';
import CheckIcon from 'components/Icons/CheckIcon';

import styles from './EditableValue.module.css';

class EditableValue extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    onEditAction: PropTypes.func.isRequired,
    fieldUnits: PropTypes.string,
    fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isTableDisabled: PropTypes.bool,
    roundTo: PropTypes.number,
    editIconClassName: PropTypes.string,
  };

  static defaultProps = {
    fieldUnits: undefined,
    isTableDisabled: false,
    fieldValue: '',
    roundTo: undefined,
    editIconClassName: undefined,
  };

  state = {
    isEditMode: false,
    editedValue: this.props.fieldValue,
  }

  handlerOnChangeValue = (value) => {
    const { onEditAction } = this.props;

    const newValue = value === '' || isNull(value) ? null : Number(value);

    onEditAction(newValue);

    this.handlerSwitchMode(false);
  }

  handlerSwitchMode = newValue => this.setState({ isEditMode: newValue });

  handlerOnKeyDown = (event) => {
    event.stopPropagation();

    const { editedValue } = this.state;

    if (event.key === 'Enter' || event.keyCode === 13) {
      this.handlerOnChangeValue(editedValue);
    }

    if (event.key === 'Escape' || event.keyCode === 27) {
      this.handlerEditInputBlur();
    }
  };

  handlerClickSave = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const { editedValue } = this.state;

    this.handlerOnChangeValue(editedValue);
  };

  handlerEditInputBlur = () => {
    const { fieldValue } = this.props;

    this.setState({ editedValue: fieldValue });

    this.handlerSwitchMode(false);
  };


  render() {
    const {
      intl: { formatMessage }, isTableDisabled, fieldUnits, fieldValue, roundTo, editIconClassName
    } = this.props;
    const { isEditMode, editedValue } = this.state;

    if (isEditMode) {
      return (
        <ClickOutside handleClickOutside={this.handlerEditInputBlur}>
          <div className={styles.editControls}>
            <div className={styles.formButtons}>
              <BigButton
                className={styles.checkIcon}
                onClick={this.handlerClickSave}
                icon={<CheckIcon />}
                theme='transparent'
                tooltip={formatMessage({ id: 'button.save' })}
                bigIcon
              />
              <BigButton
                className={styles.closeIcon}
                onClick={this.handlerEditInputBlur}
                icon={<CloseIcon />}
                theme='transparent'
                tooltip={formatMessage({ id: 'button.cancel' })}
                bigIcon
              />
            </div>
            <BigNumberInput
              className={styles.input}
              value={editedValue}
              disabled={false}
              step={0.1}
              min={0}
              allowNegative={false}
              autoFocus
              onChange={value => this.setState({ editedValue: value })}
              onKeyDown={this.handlerOnKeyDown}
              // onBlur={this.handlerEditInputBlur}
              onBlur={() => {}}
              onFocus={() => {}}
              onClear={() => {}}
            />
          </div>
        </ClickOutside>
      );
    }

    const roundedValue = fieldValue && roundTo ? numbersRounding(fieldValue, 'fixed', roundTo) : fieldValue;

    return (
      <div
        className={styles.editWrapper}
        onClick={this.handlerSwitchMode}
        role='button'
        tabIndex={0}
      >
        {!isTableDisabled && (
          <span
            className={classnames(styles.editIcon, editIconClassName)}
          >
            <EditIcon />
          </span>
        )}
        {
          roundedValue ? (
            <span className={styles.popoverValueAmount}>
              {numbersFormatting(roundedValue)} {fieldUnits}
            </span>
          )
            :
            <span className={styles.popoverValueAmount}>–</span>
        }
      </div>
    );
  }
}

export default injectIntl(EditableValue);
