import React from 'react';
import { intlShape, injectIntl } from 'react-intl';

import Typography from 'components/Typography';
import BellIcon from 'components/Icons/BellIcon';

import styles from './SettingsAlertingEmpty.module.css';

const SettingsAlertingEmpty = ({
  intl: { formatMessage },
}) => (
  <div className={styles.emptyState}>
    <BellIcon />
    <Typography variant='body1' className={styles.text}>
      {formatMessage({ id: 'alerting.emptyState' })}
    </Typography>
  </div>
);

SettingsAlertingEmpty.propTypes = {
  intl: intlShape.isRequired,
};

SettingsAlertingEmpty.defaultProps = {};

export default injectIntl(SettingsAlertingEmpty);
