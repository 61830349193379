import { handleActions } from 'redux-actions';

import {
  requestHarvestData,
  receiveHarvestData,
  requestClearHarvestData,
  requestGetHarvestDataByCategories,
  receiveGetHarvestDataByCategories,
  requestUpdateHarvestDataByCategories,
  receiveUpdateHarvestDataByCategories,
  requestGetHarvestCategories,
  receiveGetHarvestCategories,
  requestClearHarvestDataByCategories,
  setHasChangesTime,
} from './actions';

const initialState = {
  isHarvestFetching: true,
  plantingCycleHarvest: null,

  harvestCategories: null,
  plantingCycleHarvestByCategories: null,
  isUpdateHarvestDataByCategoriesFetching: false,
  isGetHarvestDataByCategoriesFetching: false,
  changesTime: undefined,
};

export default handleActions({
  [requestGetHarvestCategories](state) {
    return { ...state, isGetHarvestCategoriesFetching: true, };
  },
  [receiveGetHarvestCategories](state, action) {
    return {
      ...state,
      isGetHarvestCategoriesFetching: false,
      harvestCategories: action.payload,
    };
  },
  [requestGetHarvestDataByCategories](state) {
    return { ...state, isGetHarvestDataByCategoriesFetching: true, };
  },
  [receiveGetHarvestDataByCategories](state, action) {
    return {
      ...state,
      isGetHarvestDataByCategoriesFetching: false,
      plantingCycleHarvestByCategories: action.payload,
    };
  },
  [requestGetHarvestDataByCategories](state) {
    return { ...state, isGetHarvestDataByCategoriesFetching: true, };
  },
  [receiveGetHarvestDataByCategories](state, action) {
    return {
      ...state,
      isGetHarvestDataByCategoriesFetching: false,
      plantingCycleHarvestByCategories: action.payload,
    };
  },
  [requestUpdateHarvestDataByCategories](state) {
    return { ...state, isUpdateHarvestDataByCategoriesFetching: true, };
  },
  [receiveUpdateHarvestDataByCategories](state) {
    return { ...state, isUpdateHarvestDataByCategoriesFetching: false, };
  },
  [requestHarvestData](state) {
    return { ...state, isHarvestFetching: true, };
  },
  [receiveHarvestData](state, action) {
    return {
      ...state,
      isHarvestFetching: false,
      plantingCycleHarvest: action.payload,
    };
  },
  [requestClearHarvestData](state) {
    return { ...state, plantingCycleHarvest: null };
  },
  [requestClearHarvestDataByCategories](state) {
    return { ...state, plantingCycleHarvestByCategories: null };
  },
  [setHasChangesTime](state, action) {
    return {
      ...state,
      changesTime: action.payload,
    };
  },
}, initialState);
