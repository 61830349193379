import React, {
  useCallback, useEffect, useRef, useState, useMemo
} from 'react';

import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { getNameByLocal } from 'helpers/getNameByLocal';
import isPhoneView from 'helpers/isPhoneView';

import { addMinutes } from 'date-fns';
import ReactCanvas from '../CanvasTimeline/components/ReactCanvas';
import CanvasComponent from '../CanvasTimeline/components/CanvasComponent';
import IncidentTimeline from '../CanvasTimeline/components/IncidentTimeline';
import IncidentTreePanel from '../CanvasTimeline/components/IncidentTreePanel';
import IncidentTimelineScale from '../CanvasTimeline/components/IncidentTimelineScale';
import IncidentTimelineOverlay from '../CanvasTimeline/components/IncidentTimelineOverlay';

import { drawRootComponent } from '../CanvasTimeline/helpers/DrawUtils';

import { getCurrentPeriod, INCIDENT_TIMELINE_CONFIG } from '../CanvasTimeline/helpers/incidentScale';

import IncidentLoader from './components/IncidentLoader';

import styles from './Incidents.module.css';

function getNodeText({
  locale,
  incidentTreeNode,
  locations,
  compartments,
  incidentsTypes,
  subNodes,
  formatMessage
}) {
  const { type, id } = incidentTreeNode.entity;

  switch (type) {
    case 'Location': {
      const location = locations?.find(item => item.id === id);
      if (location) {
        return location?.attributes?.name;
      }
      break;
    }
    case 'Compartment': {
      const compartment = compartments?.find(item => item.id === id);
      if (compartment) {
        return compartment?.attributes?.name;
      }
      break;
    }
    case 'IncidentType': {
      const incidentType = incidentsTypes?.find(item => item.id === id);
      if (incidentType) {
        return getNameByLocal(incidentType, locale);
      }
      break;
    }
    case 'SubNode': {
      const subNode = subNodes?.find(item => item.id === id);
      if (subNode) {
        // subNodes
        const typeName = formatMessage({ id: `subNodes.${subNode?.attributes?.type}` });
        return `${typeName} ${subNode?.attributes?.name}`;
      }
      break;
    }
    default: {
      return '';
    }
  }
  return '';
}

function mapNode({
   locale,
   formatMessage,
   formatPlural,
   incidentTreeNode,
   locations,
   compartments,
   incidentsTypes,
   subNodes
}) {
  const key = incidentTreeNode.path.join('_');
  const text = getNodeText({
    locale,
    incidentTreeNode,
    locations,
    compartments,
    incidentsTypes,
    subNodes,
    formatMessage
  });

  const result = {
    key,
    text,
    sourceNode: {
      ...incidentTreeNode,
      groups: incidentTreeNode?.groups?.map((grp) => {
        const data = {};
        const dataKeys = Object.keys(grp.data);
        for (let grpIndex = 0; grpIndex < dataKeys.length; grpIndex += 1) {
          const grpKey = dataKeys[grpIndex];
          const grpData = grp.data[grpKey];
          const incidentsText = formatMessage({
            id: `incidentsTimeline.filters.selectedIncidentsPlural.${formatPlural(grpData.incidents)}`
          });
          const typeText = formatMessage({
            id: `incidentsTimeline.incidentTooltip.typePlural.${formatPlural(grpData.types)}`
          });
          data[grpKey] = {
            ...grpData,
            tooltipText: `${grpData.incidents} ${incidentsText} ${formatMessage({ id: 'dashboards.of' })} ${grpData.types} ${typeText}`
          };
        }
        return {
          ...grp,
          data
        };
      })
    },
  };

  if (Object.prototype.hasOwnProperty.call(incidentTreeNode, 'children')) {
    result.children = incidentTreeNode.children ? incidentTreeNode.children.map(item => mapNode({
      locale,
      formatMessage,
      formatPlural,
      incidentTreeNode: item,
      locations,
      compartments,
      incidentsTypes,
      subNodes
    })) : [];
  }

  return result;
}

function mapTreeData({
  locale,
  formatMessage,
  formatPlural,
  incidentsTree,
  incidentsTypes,
  locations,
  compartments,
  subNodes
}) {
  const rootChildren = incidentsTree.node.children || [];

  return rootChildren.map(child => mapNode({
    locale,
    formatMessage,
    formatPlural,
    incidentTreeNode: child,
    locations,
    compartments,
    incidentsTypes,
    subNodes
  }));
}

const IncidentsCanvasTimeline = ({
  incidentsTree,
  incidentsTypes,
  locations,
  compartments,
  intl,
  expanded,
  setTreeExpandNodes,
  periodType,
  start,
  endInclusive,
  subNodes,
  parentClassName,
  onZoom,
  loadedNodes,
  activeIncidentId
}) => {
  const { locale, formatMessage, formatPlural } = intl;

  // Убираем фиксированную панель инцидентов на мобилках
  const isScrolledTreePanelX = useMemo(() => isPhoneView(), []);

  const [rootContainer, setRootContainer] = useState(null);
  const [viewport, setViewPort] = useState(null);
  const [isInit, setIsInit] = useState(false);

  const timeLineComponentRef = useRef(null);
  const incidentTreeRef = useRef(null);
  const headerTimelineRef = useRef(null);
  const overlayRef = useRef(null);

  const [expandedNodes, setExpandedNodes] = useState([]);

  const loadedNodesArr = useMemo(() => {
    const nodesList = incidentTreeRef?.current ? incidentTreeRef.current.getNodesBounds() : [];
    return nodesList.filter(nodeItem => loadedNodes.includes(nodeItem.node.key));
  }, [loadedNodes, incidentTreeRef]);

  const setExpanded = useCallback((node) => {
    const { key } = node.node;
    setTreeExpandNodes(key, node);
    const expandedIndex = expandedNodes.findIndex(item => item === key);
    let newExpandState = [];
    if (expandedIndex !== -1) {
      expandedNodes.splice(expandedIndex, 1);
      newExpandState = [...expandedNodes];
    } else {
      newExpandState = [...expandedNodes, key];
      if (timeLineComponentRef.current) {
        timeLineComponentRef.current.setExpanded(
            node.node,
            node.isNodeHasChildren || Boolean(node.node?.group)
        );
      }
    }
    setExpandedNodes(newExpandState);
  }, [
    setTreeExpandNodes,
    timeLineComponentRef,
    expandedNodes,
    setExpandedNodes,
  ]);

  useEffect(() => {
    setRootContainer(new CanvasComponent({
      position: { x: 0, y: 0 }
    }));
  }, [setRootContainer]);

  useEffect(() => {
    if (incidentTreeRef.current) {
      incidentTreeRef.current.setExpandedKeys(expanded);
      if (expanded.length === 0 && timeLineComponentRef.current) {
        timeLineComponentRef.current.resetExpanded();
      }
    }
  }, [expanded, incidentTreeRef]);

  useEffect(() => {
    if (rootContainer && viewport && incidentsTree && !isInit && incidentsTypes && subNodes) {
      const periodEnd = addMinutes(endInclusive, 1);
      const currentPeriod = getCurrentPeriod({
        periodType,
        start,
        endInclusive: periodEnd
      });

      const incidentOverlayPtr = new IncidentTimelineOverlay({
        position: { x: 0, y: 0 },
        viewport,
        intl
      });

      const incidentTreePtr = new IncidentTreePanel({
        position: { x: 0, y: 0 },
        viewport,
        data: mapTreeData({
          locale,
          formatMessage,
          formatPlural,
          incidentsTree,
          incidentsTypes,
          locations,
          compartments,
          subNodes
        }),
        onClickNode: (node) => { setExpanded(node); },
        intl,
        isScrolledTreePanelX,
      });

      const headerTimelinePtr = new IncidentTimelineScale({
        position: { x: INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH, y: 0 },
        period: {
          periodType,
          start,
          endInclusive: periodEnd
        },
        currentPeriod,
        treeData: incidentsTree,
        intl,
        overlayComponent: incidentOverlayPtr,
        isScrolledTreePanelX,
      });

      const timeLineComponentPtr = new IncidentTimeline({
        position: { x: INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH, y: INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT },
        period: {
          periodType,
          start,
          endInclusive: periodEnd
        },
        currentPeriod,
        treeData: incidentsTree,
        incidentTree: incidentTreePtr,
        onClickNode: (node) => { setExpanded(node); },
        intl,
        scaleComponent: headerTimelinePtr,
        onZoom: (zoomValue) => { onZoom(zoomValue); },
        overlayComponent: incidentOverlayPtr
      });

      headerTimelinePtr.setTimelineComponent(timeLineComponentPtr);
      incidentTreePtr.setTimelineComponent(timeLineComponentPtr);

      rootContainer.addChild(timeLineComponentPtr);
      rootContainer.addChild(incidentTreePtr);
      rootContainer.addChild(headerTimelinePtr);
      // rootContainer.addChild(incidentOverlayPtr);

      timeLineComponentRef.current = timeLineComponentPtr;
      incidentTreeRef.current = incidentTreePtr;
      headerTimelineRef.current = headerTimelinePtr;
      overlayRef.current = incidentOverlayPtr;

      setIsInit(true);
    }
  }, [
    viewport,
    formatPlural,
    formatMessage,
    rootContainer,
    isInit,
    setIsInit,
    incidentsTree,
    locations,
    compartments,
    intl,
    setExpanded,
    periodType,
    start,
    endInclusive,
    locale,
    onZoom,
    isScrolledTreePanelX,
    incidentsTypes,
    subNodes
  ]);

  useEffect(() => {
    if (timeLineComponentRef.current && isInit) {
      timeLineComponentRef.current.setActiveIncidentId(activeIncidentId);
    }
  }, [activeIncidentId, timeLineComponentRef, isInit]);

  useEffect(() => {
    if (isInit && viewport) {
      [
        timeLineComponentRef.current,
        incidentTreeRef.current,
        headerTimelineRef.current,
      ].forEach((component) => {
        component.updateView({
          viewport
        });
      });
    }
  }, [
    viewport,
    timeLineComponentRef,
    incidentTreeRef,
    headerTimelineRef,
    isInit
  ]);

  useEffect(() => {
    if (isInit && viewport) {
      [
        timeLineComponentRef.current,
        incidentTreeRef.current,
        headerTimelineRef.current,
      ].forEach((component) => {
        const periodEnd = addMinutes(endInclusive, 1);
        const currentPeriod = getCurrentPeriod({
          periodType,
          start,
          endInclusive: periodEnd
        });
        component.updateData({
          period: {
            periodType,
            start,
            endInclusive: periodEnd,
          },
          treeData: mapTreeData({
            locale,
            formatMessage,
            formatPlural,
            incidentsTree,
            incidentsTypes,
            locations,
            compartments,
            subNodes
          })
        });
        if (component?.updateCurrentPeriod) {
          component.updateCurrentPeriod(currentPeriod);
        }
      });
    }
  }, [
    locale,
    formatPlural,
    formatMessage,
    periodType,
    start,
    endInclusive,
    viewport,
    timeLineComponentRef,
    incidentTreeRef,
    headerTimelineRef,
    isInit,
    locations,
    compartments,
    incidentsTree,
    incidentsTypes,
    subNodes
  ]);

  const handleDraw = useCallback((ctx) => {
    drawRootComponent(ctx, rootContainer);
  }, [rootContainer]);

  const handleInit = useCallback((ctx, bounds, { width, height }) => {
    setViewPort(prevState => ({
      ...prevState,
      width,
      height
    }));
  }, [setViewPort]);

  const handleResize = useCallback((ctx, bounds, { width, height }) => {
    setViewPort(prevState => ({
      ...prevState,
      width,
      height
    }));
  }, [setViewPort]);

  const handlePointerUp = useCallback(({
    x, y, shiftKey, ctrlKey
  }) => {
    rootContainer.pointerUpEvent({
      x, y, shiftKey, ctrlKey
    });
  }, [rootContainer]);

  const handlePointerDown = useCallback(({ x, y }) => {
    rootContainer.pointerDownEvent({ x, y });
  }, [rootContainer]);

  const handlePointerMove = useCallback(({ x, y }) => {
    rootContainer.pointerMove({ x, y });
  }, [rootContainer]);

  const handleMouseWheel = useCallback(({
    deltaX, deltaY, x, y, shiftKey
  }) => {
    const dx = shiftKey ? deltaY : deltaX;
    const dy = shiftKey ? deltaX : deltaY;

    const nx = shiftKey ? y : x;
    const ny = shiftKey ? x : y;

    rootContainer.mouseWheel({
      deltaX: dx, deltaY: dy, x: nx, y: ny
    });
  }, [rootContainer]);

  return (
    <div className={styles.canvasWrapper}>
      {loadedNodesArr?.map(nodeItem => (
        <IncidentLoader key={`loader-for-${nodeItem?.node?.key}`} lodadedNode={nodeItem} timeLineComponentRef={timeLineComponentRef} />
      ))}
      <ReactCanvas
        canvasClassName={styles.canvas}
        onDraw={handleDraw}
        onInit={handleInit}
        onResize={handleResize}
        onPointerUp={handlePointerUp}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onMouseWheel={handleMouseWheel}
        adjustElClassName={parentClassName}
      />
    </div>
  );
};

IncidentsCanvasTimeline.propTypes = {
  intl: intlShape.isRequired,
  incidentsTree: PropTypes.object,
  compartments: PropTypes.array,
  locations: PropTypes.array,
  expanded: PropTypes.array,
  setTreeExpandNodes: PropTypes.func,
  periodType: PropTypes.string,
  start: PropTypes.string,
  endInclusive: PropTypes.string,
  incidentsTypes: PropTypes.array,
  subNodes: PropTypes.array,
  parentClassName: PropTypes.string,
  onZoom: PropTypes.func,
  loadedNodes: PropTypes.array,
  activeIncidentId: PropTypes.number
};

IncidentsCanvasTimeline.defaultProps = {
  incidentsTree: null,
  compartments: null,
  locations: null,
  expanded: [],
  setTreeExpandNodes: null,
  periodType: null,
  start: null,
  endInclusive: null,
  incidentsTypes: null,
  subNodes: null,
  parentClassName: null,
  onZoom: null,
  loadedNodes: [],
  activeIncidentId: null
};

export default injectIntl(IncidentsCanvasTimeline);
