import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './DefaultSimpleTooltip.module.css';

const DefaultSimpleTooltip = React.memo(({
  children,
  text,
  className,
  position,
  noWrap,
  theme,
  ellipsis
}) => {
  if (!text) {
    return children;
  }

  return (
    <div
      className={classnames(
        styles.tooltipWrapper,
        className,
        {
          [styles.topLeft]: position === 'topLeft',
          [styles.bottom]: position === 'bottom',
          [styles.noWrap]: noWrap,
          [styles.themeLight]: theme === 'light',
          [styles.ellipsis]: ellipsis
        },
      )}
    >
      {children}
      <div className={styles.tooltip}>
        {text}
      </div>
    </div>
  );
});

DefaultSimpleTooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  text: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  position: PropTypes.string,
  noWrap: PropTypes.bool,
  theme: PropTypes.string,
  ellipsis: PropTypes.bool
};

DefaultSimpleTooltip.defaultProps = {
  className: null,
  position: 'topCenter',
  noWrap: false,
  theme: 'dark',
  text: undefined,
  ellipsis: false
};

export default DefaultSimpleTooltip;
