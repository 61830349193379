import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import { getNameByLocal } from 'helpers/getNameByLocal';
import Select from 'components/Select';
import SelectBoxFilter from 'components/SelectBoxFilter';
import useQuery from 'hooks/useQuery';

import styles from './PlanFilter.module.css';

const PlanFilter = ({
  intl,
  locationPlans,
  locationPlan,
  onFiltersChange,
  capitalizeTitle,
  selectComponent
}) => {
  const [plan, setPlan] = useQuery('locationPlan', locationPlan);

  const { formatMessage, locale } = intl;

  useEffect(() => {
    if (locationPlan) {
      setPlan(locationPlan);
    }
  }, [locationPlan, setPlan]);

  const handleChangeFilter = ({
    value
  }) => {
    if (onFiltersChange) {
      setPlan(value);
      onFiltersChange({
        locationPlan: value
      });
    }
  };

  const planOptions = useMemo(() =>
    locationPlans.map(p => ({ value: p.id, label: getNameByLocal(p, locale) })),
  [locationPlans, locale]);

  const title = formatMessage({ id: 'plans.selectorTitle' });

  return (
    <SelectBoxFilter
      classNameWrapper={styles.selectboxFilterWrapper}
      onChange={handleChangeFilter}
      value={plan ? Number(plan) : null}
      options={planOptions}
      title={title}
      capitalizeTitle={capitalizeTitle}
      selectComponent={selectComponent}
    />
  );
};

PlanFilter.propTypes = {
  intl: intlShape.isRequired,
  locationPlans: PropTypes.array,
  onFiltersChange: PropTypes.func,
  locationPlan: PropTypes.number,
  capitalizeTitle: PropTypes.bool,
  selectComponent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
};

PlanFilter.defaultProps = {
  onFiltersChange: null,
  locationPlans: [],
  locationPlan: null,
  capitalizeTitle: false,
  selectComponent: Select
};


export default PlanFilter;
