import { getDateFromKey } from './getDate';

const getHeaderItems = ({ rows = [], periodType, intl }) => {
  const { formatMessage } = intl;
  const columns = rows?.[0]?.data || {};
  return [
    { label: formatMessage({ id: 'plantingCycles.compartment' }), value: 'meter', textAlign: 'left' },
    ...Object.keys(columns).map(key => ({
      label: getDateFromKey({ dateKey: key, periodType, intl }),
      value: key,
    })),
  ];
};

export default getHeaderItems;
