import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import moment from 'moment-timezone';
import classnames from 'classnames';
import { get } from 'lodash';

import styles from './RowRenderer.module.css';

const weekRerender = (weekNo, momentRowStartDate, momentRowEndDate) => (
  <>
    <span className={styles.weekNo}>{weekNo}</span>
    <span className={styles.datesRange}>
      {`${momentRowStartDate.format('DD')} – ${momentRowEndDate.format('DD')}`}
    </span>
  </>
);

const dayRerender = (isToday, isHoliday, isStartOfMonth, momentRowStartDate) => (
  <div
    className={classnames(
      styles.dayNo,
      {
        [styles.isHoliday]: isHoliday,
        [styles.isToday]: isToday,
      }
    )}
  >
    <span className={styles.dayWeekNo}>
      <FormattedMessage id='dashboards.week' />
      {` ${momentRowStartDate.isoWeek()}`}
    </span>
    <span>
      {momentRowStartDate.format('ddd, D')}
    </span>
  </div>
);

export default function RowRenderer({ children, cells }) {
  const weekNo = get(cells, '[0].weekNo');
  const isStartOfMonth = get(cells, '[0].isStartOfMonth');
  const firstDayOfMonth = get(cells, '[0].firstDayOfMonth');
  const isHoliday = get(cells, '[0].holiday');
  const renderByDay = get(cells, '[0].renderByDay');

  const rowStartDate = get(cells, '[0].startDate');
  const rowEndDate = get(cells, '[0].endDate');
  const momentRowStartDate = moment(rowStartDate, 'YYYY-MM-DD');
  const momentRowEndDate = moment(rowEndDate, 'YYYY-MM-DD');

  const isToday = moment().format('YYYY-MM-DD') === rowStartDate;

  /**
   * Название месяца добавляем только один раз, при первой неделе месяца.
   * В нашем случае начало первой недели совпадает с первым днём месяца, поэтому проверям на 01 день.
   */
  // const isStartOfMonth = momentRowStartDate.format('DD') === '01';

  return (
    <tr>
      <th className={classnames('cell', 'read-only', styles.month, styles.firstColumn)}>
        {isStartOfMonth ? moment(firstDayOfMonth, 'YYYY-MM-DD').format('MMM') : ''}
      </th>
      <th className={classnames('cell', 'read-only', styles.week, styles.firstColumn)}>
        {renderByDay ?
          dayRerender(isToday, isHoliday, isStartOfMonth, momentRowStartDate)
          :
          weekRerender(weekNo, momentRowStartDate, momentRowEndDate)}
      </th>
      {children}
    </tr>
  );
}

RowRenderer.propTypes = {
  cells: PropTypes.array.isRequired,
  children: PropTypes.array.isRequired,
};
