import { createAction } from 'redux-actions';

export const requestUserPrivileges = createAction('REQUEST_USER_PRIVILEGES');
export const receiveUserPrivileges = createAction('RECEIVE_USER_PRIVILEGES');

export const requestUpdateUserInfo = createAction('REQUEST_UPDATE_USER_INFO');
export const receiveUpdateUserInfo = createAction('RECEIVE_UPDATE_USER_INFO');

export const requestUpdateUserLocale = createAction('REQUEST_UPDATE_USER_LOCALE');
export const receiveUpdateUserLocale = createAction('RECEIVE_UPDATE_USER_LOCALE');

