import { googleAnalyticsLogEvent, googleAnalyticsTrackPageView, googleAnalyticsSetUserId } from './ga';
import { ampLogEvent, ampSetUserProperties, ampSetUserId } from './amplitude';

export function processingEvent({ payload }) {
  const {
    eventCategory = '',
    eventAction = '',
    eventLabel = '',
  } = payload;

  googleAnalyticsLogEvent({
    payload: {
      hitType: 'event',
      eventCategory,
      eventAction,
      eventLabel,
    }
  });
}

export async function processingPageView({ data, path }) {
  const {
    compartmentName,
    plantingCycleAttributes,
    dashboardName,
    pageName,
    sectionName,
    settingName,
    defaultOpen,
  } = data;

  const payload = {};

  if (plantingCycleAttributes) {
    Object.keys(plantingCycleAttributes).forEach((key) => {
      payload[key] = plantingCycleAttributes[key];
    });
  }

  if (dashboardName) {
    payload.Dashboard = dashboardName;
    payload['System View'] = defaultOpen ? 'True' : 'False';
  }

  if (compartmentName) {
    payload.Compartment = compartmentName;
  }

  if (sectionName) {
    payload.Section = sectionName;
    payload['System View'] = defaultOpen ? 'True' : 'False';
  }

  if (settingName) {
    payload.Setting = settingName;
    payload['System View'] = defaultOpen ? 'True' : 'False';
  }

  await ampLogEvent({ event: `Page View - View ${pageName}`, data: payload });
  await googleAnalyticsTrackPageView({ path });
}

export async function processingUserProperties({ data }) {
  await ampSetUserProperties({ data });
}

export async function processingUserId({ userId }) {
  await ampSetUserId({ userId });
  await googleAnalyticsSetUserId({ userId });
}

export async function processingUserEvent({ event, data }) {
  await ampLogEvent({ event, data });
}
