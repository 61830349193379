import { handleActions } from 'redux-actions';

import {
  requestUserPrivileges,
  receiveUserPrivileges,

  requestUpdateUserInfo,
  receiveUpdateUserInfo
} from './actions';

const initialState = {
  isUserPrivilegesFetching: false,
  userPrivileges: [],
  isUserInfoUpdated: false,
};

export default handleActions({
  [requestUserPrivileges](state) {
    return {
      ...state,
      isUserPrivilegesFetching: true,
    };
  },
  [receiveUserPrivileges](state, action) {
    return {
      ...state,
      isUserPrivilegesFetching: false,
      userPrivileges: action.payload.userPrivileges,
    };
  },
  [requestUpdateUserInfo](state) {
    return {
      ...state,
      isUserInfoUpdated: true,
    };
  },
  [receiveUpdateUserInfo](state) {
    return {
      ...state,
      isUserInfoUpdated: false,
    };
  },
}, initialState);
