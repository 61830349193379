import EventEmitter from 'eventemitter3';

import { addPoints, pointInRect } from '../helpers/GeometryUtils';

class CanvasComponent extends EventEmitter {
  static DRT_POSITION = 0x1;

  static DRT_SIZE = 0x2;

  static DRT_BOUNDS = 0x4;

  static IS_DEBUG = false;

  position = {
    x: 0, y: 0
  }

  size = {
    width: 0,
    height: 0,
  }

  bbox = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }

  z = 0;

  children = []

  display = true;

  cursorType = 'default';

  parent = null;

  // eslint-disable-next-line no-bitwise
  dirtyFlag = CanvasComponent.DRT_POSITION | CanvasComponent.DRT_SIZE | CanvasComponent.DRT_BOUNDS;

  absolutePos = null;

  pickPointer = null;

  overPointer = null;

  transparent = false;

  isDrag = false;

  screenTransform = false;

  /**
   * Ctor
   * @param position
   * @param transparent
   * @param drag
   * @param screenTransform
   * @param cursorType
   */
  constructor({
    position = { x: 0, y: 0 },
    transparent = false,
    drag = false,
    screenTransform = false,
    cursorType = 'default'
  }) {
    super();
    this.position.x = position.x;
    this.position.y = position.y;
    this.transparent = transparent;
    this.isDrag = drag;
    this.screenTransform = screenTransform;
    this.basePosition = {
      ...position,
    };
    this.cursorType = cursorType;
  }

  /**
   * Setup relative component position
   * @param x
   * @param y
   */
  setPosition({ x, y }) {
    this.position.x = x;
    this.position.y = y;
    // eslint-disable-next-line no-bitwise
    this.dirtyFlag |= CanvasComponent.DRT_POSITION | CanvasComponent.DRT_BOUNDS;
  }

  /**
   * Set size
   * @param width
   * @param height
   */
  setSize({ width, height }) {
    this.size.width = width;
    this.size.height = height;
    // eslint-disable-next-line no-bitwise
    this.dirtyFlag |= CanvasComponent.DRT_SIZE | CanvasComponent.DRT_BOUNDS;
  }

  setDirty() {
    // eslint-disable-next-line no-bitwise
    this.dirtyFlag |= CanvasComponent.DRT_SIZE | CanvasComponent.DRT_POSITION | CanvasComponent.DRT_BOUNDS;
    for (let i = 0; i < this.children.length; i += 1) {
      this.children[i].setDirty();
    }
  }

  /**
   * Get relative position
   * @param absolute
   * @returns {{x: number, y: number}}
   */
  getPosition(absolute = false) {
    return absolute ? this.getPositionAbs() : this.position;
  }

  /**
   * Get position in screen coord
   * @returns {{x: number, y: number}}
   */
  getPositionAbs() {
    return this.absolutePos || this.position;
  }

  /**
   * Get size
   * @returns {{width: number, height: number}}
   */
  getSize() {
    return this.size;
  }

  /**
   * Set parent component
   * @param component
   */
  setParent(component) {
    this.parent = component;
  }

  /**
   * Is node hovered
   */
  isHovered() {
    return this.isHover;
  }

  /**
   * Inner state adjustment
   */
  adjustCoords() {
    // eslint-disable-next-line no-bitwise
    if (this.dirtyFlag & CanvasComponent.DRT_POSITION) {
      const parentPos = this.parent && this.screenTransform === false ? this.parent?.getPositionAbs() : { x: 0, y: 0 };
      this.absolutePos = addPoints(parentPos, this.position);
      // adjust all children
      for (let i = 0; i < this.children.length; i += 1) {
        // eslint-disable-next-line no-bitwise
        this.children[i].dirtyFlag |= CanvasComponent.DRT_POSITION | CanvasComponent.DRT_BOUNDS;
        this.children[i].adjustCoords();
      }
      // eslint-disable-next-line no-bitwise
      this.dirtyFlag &= ~CanvasComponent.DRT_POSITION;
    }
    this.updateBoundingBox();
  }

  /**
   * Setup z index
   * @param z
   */
  setZIndex(z) {
    this.z = z;
  }

  /**
   * Display on canvas
   * @param ctx CanvasRenderingContext2D
   * @param translateAdd
   */
  draw(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    this.ctxCache = ctx;

    this.adjustCoords();

    if (CanvasComponent.IS_DEBUG) {
      this.drawDebug(ctx);
    }
  }

  /**
   * Process click event
   * @param x
   * @param y
   */
  onClick({
    x, y, shiftKey, ctrlKey
  }) {
    this.emit('click', {
      x, y, shiftKey, ctrlKey
    });
  }

  /**
   * Process hover event
   * @param x
   * @param y
   */
  onPointerHover({ x, y }) {
    this.isHover = true;
    this.emit('hover', { x, y });
    this.ctxCache.canvas.style.cursor = this.cursorType;
  }

  /**
   * Process out event
   * @param x
   * @param y
   */
  onPointerOut({ x, y }) {
    this.isHover = false;
    this.emit('out', { x, y });
    this.ctxCache.canvas.style.cursor = 'default';
  }

  /**
   * Set specified cursor type
   * @param type
   */
  setCursorType(type) {
    this.ctxCache.canvas.style.cursor = type;
  }

  /**
   * Process move event
   * @param x
   * @param y
   * @param dx
   * @param dy
   */
  onPointerMove({
    x, y, dx, dy
  }) {
    this.emit('move', {
      x, y, dx, dy
    });
  }

  /**
   * Process start drag event
   * @param x
   * @param y
   */
  onStartDrag({ x, y }) {
    this.startDragPoint = { x, y };
    this.emit('startDrag', { x, y });
  }

  /**
   * Process end drag event
   * @param x
   * @param y
   */
  onEndDrag({ x, y }) {
    this.startDragPoint = null;
    this.emit('endDrag', { x, y });
  }

  /**
   * Process on drag event
   * @param x
   * @param y
   */
  onDrag({ x, y }) {
    const dx = x - this.startDragPoint.x;
    const dy = y - this.startDragPoint.y;
    this.emit('drag', {
      x,
      y,
      dx,
      dy
    });
    this.onDragDelta({
      x, y, dx, dy
    });
    this.startDragPoint.x = x;
    this.startDragPoint.y = y;
  }

  onDragDelta({
    // eslint-disable-next-line no-unused-vars
    x, y, dx, dy
  }) {

  }

  /**
   * Process pointer down inside component bbox
   * @param x
   * @param y
   */
  onPointerDown({ x, y }) {
    this.emit('down', { x, y });
  }

  /**
   * Process pointer up inside component bbox
   * @param x
   * @param y
   */
  onPointerUp({ x, y }) {
    this.emit('down', { x, y });
  }

  /**
   * Process pointer move outside component bbox
   * @param x
   * @param y
   */
  onPointerMoveOutSide({ x, y }) {
    this.emit('moveOutSide', { x, y });
  }

  /**
   * Process pointer up outside component bbox
   * @param x
   * @param y
   */
  onPointerUpOutSide({ x, y }) {
    this.emit('upOutSide', { x, y });
    for (let i = 0; i < this.children.length; i += 1) {
      const child = this.children[i];
      child.onPointerUpOutSide({ x, y });
    }
  }

  onMouseWheel({ deltaY, deltaX }) {
    this.emit('wheel', { deltaX });
    this.emit('wheel', { deltaY });
  }

  /**
   * Action: Pointer down
   * @param x
   * @param y
   */
  pointerDownEvent({ x, y }) {
    this.pickPointer = this.pickNode({ x, y });
    if (this.isDrag) {
      this.onStartDrag({ x, y });
    }
    if (this.pickPointer) {
      if (this.pickPointer !== this) {
        this.pickPointer.pointerDownEvent({ x, y });
      } else {
        this.pickPointer.onPointerDown({ x, y });
      }
    }
    return Boolean(this.pickPointer);
  }

  /**
   * Action: Pointer up
   * @param x
   * @param y
   */
  pointerUpEvent({
    x, y, shiftKey, ctrlKey
  }) {
    const { pickPointer } = this;
    if (this.startDragPoint && this.isDrag) {
      this.onEndDrag({ x, y });
    }
    const currentPickNode = this.pickNode({ x, y });
    if (currentPickNode) {
      if (currentPickNode !== this) {
        currentPickNode.pointerUpEvent({
          x, y, shiftKey, ctrlKey
        });
        if (pickPointer) {
          pickPointer.onPointerUpOutSide({ x, y });
        }
        return true;
      }
      currentPickNode.onPointerUp({ x, y });
    }

    if (pickPointer && pickPointer === currentPickNode) {
      pickPointer.onClick({
        x, y, shiftKey, ctrlKey
      });
    }

    if (currentPickNode !== this) {
      this.onPointerUpOutSide({ x, y });
    }

    for (let i = 0; i < this.children.length; i += 1) {
      const child = this.children[i];
      if (!child.intersect({ x, y })) {
        child.onPointerUpOutSide({ x, y });
      }
    }

    return Boolean(currentPickNode);
  }

  /**
   * Action: Pointer move
   * @param x
   * @param y
   */
  pointerMove({ x, y }) {
    const { overPointer } = this;
    const pickNode = this.pickNode({ x, y });
    if (!overPointer) {
      if (pickNode) {
        pickNode.onPointerHover({ x, y });
        pickNode.onPointerMove({ x, y });
        if (this.startDragPoint && this.isDrag) {
          pickNode.onDrag({ x, y });
        }
        this.overPointer = pickNode;
      }
    } else if (pickNode !== overPointer) {
      overPointer.onPointerMove({ x, y });
      overPointer.onPointerOut({ x, y });
      overPointer.onEndDrag({ x, y });
      if (pickNode) {
        pickNode.onPointerHover({ x, y });
        pickNode.onPointerMove({ x, y });
        if (this.startDragPoint && this.isDrag) {
          pickNode.onDrag({ x, y });
        }
      }
      this.overPointer = pickNode;
    } else {
      overPointer.onPointerMove({ x, y });
      if (this.startDragPoint && this.isDrag) {
        overPointer.onDrag({ x, y });
      }
    }
    if (pickNode && pickNode !== this) {
      pickNode.pointerMove({ x, y });
    }

    if (pickNode !== this) {
      this.onPointerMoveOutSide({ x, y });
    }

    for (let i = 0; i < this.children.length; i += 1) {
      const child = this.children[i];
      if (!child.intersect({ x, y })) {
        child.onPointerMoveOutSide({ x, y });
      }
    }

    return Boolean(pickNode);
  }

  // eslint-disable-next-line no-unused-vars
  mouseWheel({
    deltaX, deltaY, x, y
  }) {
    for (let i = 0; i < this.children.length; i += 1) {
      const child = this.children[i];
      if (child.isDisplay()) {
        child.mouseWheel({
          deltaX, deltaY, x, y
        });
      }
    }
    this.onMouseWheel({ deltaX, deltaY });
  }

  /**
   * Add child component
   * @param args
   */
  addChild(...args) {
    const component = args;
    if (Array.isArray(component)) {
      for (let i = 0; i < component.length; i += 1) {
        this.children.push(component[i]);
        component[i].setParent(this);
      }
    } else {
      this.children.push(component);
      component.setParent(this);
    }
  }

  /**
   * Remove all child
   * @param recursive
   */
  removeChildren(recursive = false) {
    for (let i = 0; i < this.children.length; i += 1) {
      this.children[i].setParent(null);
      if (recursive) {
        this.children[i].removeChildren();
      }
    }
    this.children = [];
  }

  /**
   * Update bounding box for instance
   */
  updateBoundingBox() {
    // eslint-disable-next-line no-bitwise
    if (this.dirtyFlag & CanvasComponent.DRT_BOUNDS) {
      this.bbox = {
        ...this.getPositionAbs(),
        ...this.getSize()
      };
      // eslint-disable-next-line no-bitwise
      this.dirtyFlag &= ~CanvasComponent.DRT_BOUNDS;
    }
  }

  /**
   * Check intersect component with screen coords
   * @param x
   * @param y
   * @returns {boolean}
   */
  intersect({ x, y }) {
    return pointInRect({ x, y }, this.bbox);
  }

  getPickedNodes(sorted) {
    return sorted.reduce((acc, item) => {
      if (item.transparent) {
        acc.push(...[...item.children].reverse().filter(node => node.isDisplay()));
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
  }

  /**
   * Find node intersect with screen coords
   * @param x
   * @param y
   * @returns {any|CanvasComponent}
   */
  pickNode({ x, y }) {
    const reversed = [...this.children].reverse();
    const sorted = reversed.sort((a, b) => b.z - a.z).filter(node => node.isDisplay());

    const nodes = sorted.reduce((acc, item) => {
      if (item.transparent) {
        acc.push(...this.getPickedNodes(item.children));
      } else {
        acc.push(item);
      }
      return acc;
    }, []);

    const pickChild = nodes.find(child => child.intersect({ x, y }));

    let pickedNode = null;

    if (pickChild?.z >= this.z) {
      pickedNode = pickChild;
    }

    if (!pickedNode) {
      pickedNode = this.intersect({ x, y }) ? this : null;
    }

    return pickedNode !== this ? pickedNode?.pickNode({ x, y }) : this;
  }

  /**
   * Get list all components (this and all children) with flat array
   * @returns {*[]}
   */
  toListWithChildren() {
    const children = this.children.reduce(
      (acc, val) => acc.concat(val.toListWithChildren()),
      []
    );
    return [this, ...children];
  }

  /**
   * Get is node displayed
   * @returns {boolean}
   */
  isDisplay() {
    return this.display;
  }

  /**
   * Set node displayed state
   * @param display
   */
  setDisplay(display) {
    this.display = display;
    for (let i = 0; i < this.children.length; i += 1) {
      this.children[i].setDisplay(display);
    }
  }

  setDebugColor(color) {
    this.debugColor = color;
  }

  drawDebug(ctx) {
    const { canvas } = ctx;
    const position = this.getPositionAbs();
    const size = this.getSize();

    ctx.save();

    ctx.setLineDash([2]);
    ctx.strokeStyle = this.debugColor || 'red';

    ctx.beginPath();
    ctx.moveTo(position.x, 0);
    ctx.lineTo(position.x, position.y);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(position.x, position.y + size.height);
    ctx.lineTo(position.x, canvas.height);
    ctx.stroke();


    ctx.beginPath();
    ctx.moveTo(position.x + size.width, 0);
    ctx.lineTo(position.x + size.width, position.y);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(position.x + size.width, position.y + size.height);
    ctx.lineTo(position.x + size.width, canvas.height);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(0, position.y);
    ctx.lineTo(position.x, position.y);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(position.x + size.width, position.y);
    ctx.lineTo(canvas.width, position.y);
    ctx.stroke();


    ctx.beginPath();
    ctx.moveTo(0, position.y + size.height);
    ctx.lineTo(position.x, position.y + size.height);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(position.x + size.width, position.y + size.height);
    ctx.lineTo(canvas.width, position.y + size.height);
    ctx.stroke();

    ctx.restore();
  }
}

export default CanvasComponent;
