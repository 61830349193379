import { createSelector } from 'reselect';
import { get, find } from 'lodash';

export const getAllCompartments = state => state.company.allCompartments;
export const getPlantingCycle = state => state.company.plantingCycle;
export const getPlantingCycleForTable = state => state.company.leftPlantingCycleTables;

export const getCompartmentByCycle = createSelector(
  [getAllCompartments, getPlantingCycle],
  (allCompartments, plantingCycle) => find(allCompartments, compartment => get(compartment, 'id') === get(plantingCycle, 'relationships.compartment.data[0].id'))
);

// Объединить с getCompartmentByCycle, нужно чтобы на странице Climate plantingCycle тоже инитился (вместо leftPlantingCycleTables)
export const getCompartmentByCycleForTable = createSelector(
  [getAllCompartments, getPlantingCycleForTable],
  (allCompartments, plantingCycle) => find(allCompartments, compartment => get(compartment, 'id') === get(plantingCycle, 'relationships.compartment.data[0].id'))
);

