import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';

import { useHistory } from 'react-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import classnames from 'classnames';
import queryString from 'query-string';
import { uniq } from 'lodash';
import Paper from 'components/Paper';
import Select from 'components/Select';
import BigButton from 'components/BigButton';
import LinesGraph from 'components/LinesGraph';
import CropsTable from 'components/Measurements/components/Crops/components/CropsTable';
import LINE_COLORS from 'helpers/graphColors';

import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';

import LinesGraphTooltip from 'components/LinesGraphTooltip/LinesGraphTooltip';
import useQuery from 'hooks/useQuery/useQuery';
import getDateFormat from 'helpers/getDateFormat';
import { API_DATE_FORMAT } from 'helpers/defaultDates';
import CircleLoader from 'components/CircleLoader';
import loaderStyles from 'components/CircleLoader/CircleLoader.module.css';
import { bisector } from 'd3';
import { getTicksRange as getTicksRangeDefault } from 'helpers/getTicks';
import getFormattedDate from 'components/Measurements/components/Crops/helpers/getFormattedDate';
import styles from './Crops.module.css';
import MetricsChangeDialog from './components/MetricsChangeDialog';

import DownloadIcon from '../../../Icons/DownloadIcon';

const MAX_SELECTED_METRICS = 24;

const mapLines = (from, period) => (row, index) => {
  const name = row.parentName ? `${row.name} (${row.parentName})` : row.name;
  const allPoints = row.data.map(([key, y]) => {
    const date = moment.utc(new Date(+key * 60000));
    return {
      x: date.format('MM-DD-YYYY'), y, date, dtKey: key
    };
  });
  let points = [];
  if (period === 'day') {
    points.push(allPoints[0]);
    for (let pIndex = 0; pIndex < allPoints.length; pIndex += 1) {
      if (allPoints[pIndex].y !== null) {
        points.push(allPoints[pIndex]);
      }
    }
  } else {
    points = allPoints;
  }
  return {
    key: row.graphKey,
    color: LINE_COLORS[index + from],
    name,
    displayScale: row.displayScale,
    points,
    cunit: row.cunit,
  };
};

const parseMetrics = (metric) => {
  const parsed = metric.split('_');
  if (parsed.length > 3) {
    return {
      metricId: parsed[1],
      plant: parsed[2].toString(),
    };
  }
  return {
    metricId: parsed[1],
  };
};

const parseUrl = (params) => {
  if (!params) {
    return {
      additionalMetricId: [],
      selectedMetrics: [],
    };
  }
  const parsedParams = params.split(';');
  if (parsedParams.length !== 2) {
    return {
      additionalMetricId: [],
      selectedMetrics: [],
    };
  }
  const selected =
    parsedParams[1] && parsedParams[1] !== ''
      ? parsedParams[1]?.split(',')
      : [];
  return {
    additionalMetricId:
      parsedParams[0] && parsedParams[0] !== ''
        ? parsedParams[0]?.split(',')
        : [],
    selectedMetrics: selected.map((m) => {
      const parsed = m.split('_');
      if (parsed.length > 1) {
        return `row_${parsed[0]}_${parsed[1]}_key`;
      }
      return `row_${parsed[0]}_key`;
    }),
  };
};

const envelopeUrl = (params) => {
  const additionalMetricId = params?.additionalMetricId?.join(',') || '';
  const selectedMetrics =
    params?.selectedMetrics
      ?.map((m) => {
        const parsedKey = m.split('_');
        if (parsedKey.length > 3) {
          return `${parsedKey[1]}_${parsedKey[2]}`;
        }
        return parsedKey[1];
      })
      .join(',') || '';
  return `${additionalMetricId};${selectedMetrics}`;
};

const reduceLinesValues = lines =>
  lines.reduce(
    (acc, item) => [
      ...acc,
      ...item.points.map(point => point.y).filter(value => value !== null),
    ],
    []
  );

const normalizeMaxValue = (lines) => {
  const values = reduceLinesValues(lines);
  if (values.length === 0) {
    return 100;
  }
  return Math.max(...values);
};

// Set min value always 0
const normalizeMinValue = (lines) => {
  const values = reduceLinesValues(lines);
  if (values.length === 0) {
    return 0;
  }
  const minValue = Math.min(...values);
  return minValue > 0 ? 0 : minValue;
};

const getPw = () => {
  const base = [1, 2, 2.5, 5];
  const MAX_INTERVAL = 5;
  let pw = [];
  for (let i = 0; i <= MAX_INTERVAL; i += 1) {
    pw = pw.concat(base.map(bn => bn * 10 ** i));
  }
  return pw;
};

const PW = getPw();

const getTicksRange = (start, stop, count) => {
  const interval = stop - start;
  if (interval > PW[PW.length - 1]) {
    return getTicksRangeDefault(start, stop, count);
  }
  const bisect = bisector(d => d).right;
  const pwIndex = bisect(PW, interval);
  const dt = PW[pwIndex] / count;
  let ticks = [];
  for (let i = 0; i <= count; i += 1) {
    ticks.push(i * dt);
  }
  if (start < 0) {
    const startValueIndex = bisect(ticks, Math.abs(start));
    ticks = ticks.map(v => v - ticks[startValueIndex]);
    if (ticks.length > 0) {
      ticks.push(ticks[ticks.length - 1] + dt);
    }
  }
  return ticks;
};

const Crops = ({
  intl,
  period,
  plantingCycleId,
  descriptor,
  tableRows,
  requestMeasurementsByPeriod,
  isMeasurementsByPeriodFetching,
  isMeasurementsByPeriodSuccess,
  additionalMetrics,
  requestMeasurementsAdditionalMetrics,
  organizationSlug,
  metricCategories,
  showNotificationWithTimeout,
  saveMeasurementsAdditionalMetrics,
  saveMeasurementsGraphMetrics,
  metricsAndPlants,
  requestMeasurementsExport
}) => {
  const { formatMessage } = intl;

  const isDataFetching = isMeasurementsByPeriodFetching;

  const history = useHistory();

  const [selectedParams] = useQuery('sp');

  const additionalMetricId = useMemo(
    () => parseUrl(selectedParams).additionalMetricId,
    [selectedParams]
  );

  const selectedMetrics = useMemo(
    () => parseUrl(selectedParams).selectedMetrics,
    [selectedParams]
  );

  const setHistory = useCallback(
    (params) => {
      const parsedQuery = queryString.parse(window.location.search);
      let newParams = {};
      if (params.period) {
        newParams = { period: params.period };
      }
      const sp = parseUrl(parsedQuery?.sp || '');
      if (params?.additionalMetricId) {
        sp.additionalMetricId = params.additionalMetricId || [];
        Object.assign(newParams, {
          sp: envelopeUrl(sp),
        });
      }

      if (params?.selectedMetrics) {
        sp.selectedMetrics = params.selectedMetrics || [];
        Object.assign(newParams, {
          sp: envelopeUrl(sp),
        });
      }

      const newQuery = { ...parsedQuery, ...newParams };
      const newSearch = queryString.stringify(newQuery);
      const searchString = newSearch ? `?${newSearch}` : '';
      history.replace({
        search: searchString,
      });
    },
    [history]
  );

  const [currentGraphKey, setCurrentGraphKey] = useState(
    () => selectedMetrics || []
  );
  const [selectedAdditionalMetrics, setSelectedAdditionalMetrics] = useState(
    () => additionalMetricId || []
  );

  const [showSelectMetricsDialog, setShowSelectMetricsDialog] = useState(false);

  const startDate = useMemo(
    () => moment.utc(descriptor?.periods[0]?.startDate, API_DATE_FORMAT),
    [descriptor]
  );

  const endDate = useMemo(
    () =>
      moment.utc(
        descriptor?.periods[descriptor?.periods.length - 1]?.startDate,
        API_DATE_FORMAT
      ),
    [descriptor]
  );

  const tickTimeDisplayFormat = useCallback(
    (date) => {
      switch (period) {
        case 'week': {
          return moment(date).isoWeek();
        }
        case 'day': {
          return moment(date).format(getDateFormat('ll'));
        }
        case 'month':
        default:
          return moment(date).format('MMM');
      }
    },
    [period]
  );

  const defaultRanges = useMemo(
    () => [
      {
        label: formatMessage({ id: 'dashboards.daily' }),
        value: 'day',
      },
      {
        label: formatMessage({ id: 'dashboards.weekly' }),
        value: 'week',
      },
      {
        label: formatMessage({ id: 'dashboards.monthly' }),
        value: 'month',
      },
    ],
    [formatMessage]
  );

  useEffect(() => {
    const payload = {
      plantingCycleId,
      periodLength: period,
    };
    if (selectedAdditionalMetrics) {
      Object.assign(payload, {
        metricId: selectedAdditionalMetrics,
      });
    }
    requestMeasurementsByPeriod(payload);
    requestMeasurementsAdditionalMetrics({
      plantingCycleId,
    });
  }, [
    requestMeasurementsByPeriod,
    requestMeasurementsAdditionalMetrics,
    period,
    plantingCycleId,
    selectedAdditionalMetrics,
  ]);

  const [keyChanged, setKeyChanged] = useState(false);

  const savedGraphKeys = useMemo(
    () =>
      metricsAndPlants.map((metric) => {
        if (metric?.plant) {
          return `row_${metric?.metricId}_${metric?.plant}_key`;
        }
        return `row_${metric?.metricId}_key`;
      }),
    [metricsAndPlants]
  );

  const graphKeys = useMemo(() => {
    let allKeys = currentGraphKey;

    // Use localstorage selected metrics only if url params is empty
    if (currentGraphKey.length === 0) {
      if (savedGraphKeys.length > 0) {
        // If url params is empty check saved localstorage selected metrics and update url
        setHistory({
          selectedMetrics: savedGraphKeys,
        });
      }

      allKeys = uniq([...currentGraphKey, ...savedGraphKeys]);
    }

    return allKeys.length === 0 && tableRows.length > 0 && !keyChanged
      ? [tableRows[0].graphKey]
      : allKeys;
  }, [setHistory, currentGraphKey, savedGraphKeys, tableRows, keyChanged]);

  const isRenderEmptyGraph = useMemo(
    () =>
      graphKeys &&
      descriptor?.periods &&
      graphKeys.length <= 0 &&
      descriptor.periods.length > 0,
    [descriptor, graphKeys]
  );

  const lines = useMemo(
    () =>
      tableRows
        .filter(row => row.isAdditionalMetric === false)
        .reduce((acc, row) => acc.concat(row.children).concat(row), [])
        .filter(row => graphKeys.includes(row.graphKey))
        .map(mapLines(0, period))
        .sort(
          (a, b) =>
            graphKeys.indexOf(a.graphKey) - graphKeys.indexOf(b.graphKey)
        ),
    [tableRows, graphKeys, period]
  );

  const linesRight = useMemo(
    () =>
      tableRows
        .filter(row => row.isAdditionalMetric === true)
        .reduce((acc, row) => acc.concat(row.children).concat(row), [])
        .filter(row => graphKeys.includes(row.graphKey))
        .map(mapLines(lines.length, period))
        .sort(
          (a, b) =>
            graphKeys.indexOf(a.graphKey) - graphKeys.indexOf(b.graphKey)
        ),
    [tableRows, graphKeys, lines, period]
  );

  const maxValue = useMemo(() => normalizeMaxValue(lines), [lines]);
  const rightMaxValue = useMemo(
    () => normalizeMaxValue(linesRight),
    [linesRight]
  );

  const minValue = useMemo(() => normalizeMinValue(lines), [lines]);
  const rightMinValue = useMemo(
    () => normalizeMinValue(linesRight),
    [linesRight]
  );

  const updateMetricsSelection = useCallback(
    (keys) => {
      setKeyChanged(true);
      setHistory({
        selectedMetrics: keys,
      });
      setCurrentGraphKey(keys);
      saveMeasurementsGraphMetrics({
        metricId: keys.map(parseMetrics),
        plantingCycleId,
      });
    },
    [
      saveMeasurementsGraphMetrics,
      setHistory,
      setCurrentGraphKey,
      setKeyChanged,
      plantingCycleId,
    ]
  );

  const handlerGraphKeyChange = useCallback(
    (e, graphKey, prevSelectedState) => {
      const isAllSelected =
        currentGraphKey.length === MAX_SELECTED_METRICS ||
        currentGraphKey.length ===
          tableRows.filter(row => !row.isAdditionalMetric).length;
      if (isAllSelected && graphKey === 'all') {
        // unselect
        return updateMetricsSelection([]);
      }
      if (!isAllSelected && graphKey === 'all') {
        // select
        return updateMetricsSelection(
          tableRows
            .filter(metric => !metric.isAdditionalMetric)
            .slice(0, MAX_SELECTED_METRICS)
            .map(metric => metric.graphKey)
        );
      }
      const copyKeys = uniq([...currentGraphKey, ...savedGraphKeys]);
      if (copyKeys.includes(graphKey)) {
        copyKeys.splice(
          copyKeys.findIndex(key => key === graphKey),
          1
        );
      } else if (copyKeys.length < MAX_SELECTED_METRICS && !prevSelectedState) {
        copyKeys.push(graphKey);
      }
      // save default selected metric
      if (!keyChanged && copyKeys.length === 1 && tableRows.length > 0) {
        copyKeys.push(tableRows[0].graphKey);
      }
      return updateMetricsSelection(uniq(copyKeys));
    },
    [
      tableRows,
      currentGraphKey,
      updateMetricsSelection,
      savedGraphKeys,
      keyChanged,
    ]
  );

  const handleRangeSelect = useCallback(
    ({ value }) => {
      setHistory({
        period: value,
      });
    },
    [setHistory]
  );

  const handleExpandMetrics = useCallback(() => {
    setShowSelectMetricsDialog(true);
  }, [setShowSelectMetricsDialog]);

  const handlerChangeDialogClose = useCallback(() => {
    setShowSelectMetricsDialog(false);
  }, [setShowSelectMetricsDialog]);

  const handlerChangeDialogSave = useCallback(
    (options) => {
      const { graphMetrics: metrics, removed } = options;
      const metricId = [...metrics];
      setHistory({
        additionalMetricId: metricId,
      });
      const removedKeys = removed.map(id => `row_${id}_key`);
      setCurrentGraphKey((prevCurrentGraphKey) => {
        let newCurrentGraphKey = [];
        for (let i = 0; i < prevCurrentGraphKey.length; i += 1) {
          if (!removedKeys.includes(prevCurrentGraphKey[i])) {
            newCurrentGraphKey.push(prevCurrentGraphKey[i]);
          }
        }
        newCurrentGraphKey = newCurrentGraphKey.concat(
          metricId.map(id => `row_${id}_key`)
        );
        return newCurrentGraphKey;
      });
      saveMeasurementsAdditionalMetrics({ metricId, plantingCycleId });
      setSelectedAdditionalMetrics(metricId);
      setShowSelectMetricsDialog(false);
    },
    [
      setShowSelectMetricsDialog,
      setHistory,
      plantingCycleId,
      setCurrentGraphKey,
      saveMeasurementsAdditionalMetrics,
      setSelectedAdditionalMetrics,
    ]
  );

  const isLineChartEmpty =
    !isDataFetching &&
    isMeasurementsByPeriodSuccess &&
    (!lines ||
      !lines.length ||
      lines.every(line => !line.points || !line.points.length)) &&
    (!linesRight ||
      !linesRight.length ||
      linesRight.every(line => !line.points || !line.points.length));

  const allMetricsComparison = uniq(
    [
      ...(additionalMetrics?.selectedMetrics || []),
      ...selectedAdditionalMetrics,
    ].map(m => +m)
  );
  const comparison = {
    graphMetrics: allMetricsComparison.map(id => ({ id })),
  };

  const renderTooltipContent = useCallback(
    (tooltipDate) => {
      if (!tooltipDate || isRenderEmptyGraph) {
        return null;
      }
      const allLines = [...lines, ...linesRight];
      const generatedLines = allLines.map((line) => {
        const values = line.points.filter(p =>
          p.date.isSame(tooltipDate, 'date'));
        const value =
          values.length > 0 && values[0].y
            ? numbersFormatting(
              numbersRounding(values[0].y, 'fixed', line?.displayScale || 0)
            )
            : '—';
        const units = formatMessage({ id: `cunits.mini.${line?.cunit}` });
        return {
          id: line.key,
          value: `${value} ${units}`,
          header: line.name,
          color: line.color,
        };
      });
      return (
        <LinesGraphTooltip
          isSpaced
          lines={generatedLines}
          periodType={period}
          tooltipDate={tooltipDate}
          customRenderDate={(date, periodType) => {
            const periodData = descriptor?.periods?.find(
              p => p.startDate === date.format(API_DATE_FORMAT)
            );
            return getFormattedDate(
              formatMessage,
              periodData,
              periodType,
              false
            );
          }}
        />
      );
    },
    [lines, linesRight, period, isRenderEmptyGraph, formatMessage, descriptor]
  );

  const additionalMetricCount = useMemo(() => {
    if (allMetricsComparison === null) {
      return 0;
    }
    return Array.isArray(allMetricsComparison) &&
      allMetricsComparison.length > 0
      ? allMetricsComparison.length
      : 0;
  }, [allMetricsComparison]);

  const expandMetricsTitle = useMemo(() => {
    if (additionalMetricCount > 0) {
      return (
        <>
          {formatMessage({ id: 'crops.expandMetrics' })}&nbsp;
          <span className={styles.selectedMetricsCount}>
            {additionalMetricCount}
          </span>
        </>
      );
    }
    return formatMessage({ id: 'crops.expandMetrics' });
  }, [additionalMetricCount, formatMessage]);

  const lockUnselected = currentGraphKey.length === MAX_SELECTED_METRICS;

  useEffect(() => {
    if (lockUnselected) {
      showNotificationWithTimeout({
        id: `notifications.maxCropsMetricsSelected.${Date.now()}`,
        messageId: 'notifications.maxCropsMetricsSelected',
        position: 'leftDown',
        iconType: 'error',
        notificationType: 'withAction',
      });
    }
  }, [lockUnselected, showNotificationWithTimeout]);

  const lineColors = useMemo(
    () =>
      lines
        .map(line => line.color)
        .concat(linesRight.map(line => line.color)),
    [lines, linesRight]
  );

  const handleClickExportButton = useCallback(() => {
    requestMeasurementsExport({
      plantingCycleId,
      metricId: allMetricsComparison,
      periodLength: period
    });
  }, [requestMeasurementsExport, plantingCycleId, period, allMetricsComparison]);

  if (isDataFetching && !isMeasurementsByPeriodSuccess) {
    return (
      <div className={styles.loader}>
        <CircleLoader
          className={loaderStyles.circleLoader}
          iconClassName={loaderStyles.circleLoaderIcon}
        />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {showSelectMetricsDialog && (
        <MetricsChangeDialog
          comparison={comparison}
          metrics={additionalMetrics}
          handlerCancel={handlerChangeDialogClose}
          handlerOk={handlerChangeDialogSave}
        />
      )}
      <div className={styles.header}>
        <Select
          className={styles.periodSelect}
          classNameButton={styles.periodSelectButton}
          options={defaultRanges}
          value={period}
          onChange={handleRangeSelect}
          labelPath='label'
          valuePath='value'
          closeOnChange
        />
        <div className={styles.buttons}>
          <BigButton
            onClick={handleExpandMetrics}
            className={classnames(styles.button)}
            tabIndex={0}
            title={expandMetricsTitle}
            theme='light'
          />
          <BigButton
            className={classnames(styles.button)}
            tabIndex={0}
            title={formatMessage({ id: 'crops.addMeasurements' })}
            href={`/${organizationSlug}/crops/${plantingCycleId}/measurements/edit`}
            theme='dark'
          />
        </div>
      </div>
      <Paper className={styles.graphsWrapper}>
        <LinesGraph
          isOverlapedEmptyState={false}
          isEmbeddedEmptyState={false}
          isDataFetching={isDataFetching}
          lines={lines}
          rightLines={linesRight}
          maxValue={maxValue}
          minValue={minValue}
          rightMinValue={rightMinValue}
          rightMaxValue={rightMaxValue}
          renderTooltipContent={renderTooltipContent}
          isEmpty={isLineChartEmpty}
          emptyTitle={formatMessage({ id: 'harvestDashboard.emptyText' })}
          showTooltip
          tickTimeDisplayFormat={tickTimeDisplayFormat}
          startDate={startDate}
          endDate={endDate}
          customGetTicksRange={getTicksRange}
          yTicks={5}
          lineColors={lineColors}
          wrapperClassName={styles.lineChartWrapper}
        />
      </Paper>
      <div className={styles.tableActionButtons}>
        <BigButton
          className={classnames(styles.button, styles.exelButton)}
          title={formatMessage({ id: 'planFact.exportButton' })}
          icon={<DownloadIcon />}
          onClick={handleClickExportButton}
        />
      </div>
      <CropsTable
        descriptor={descriptor}
        period={period}
        tableRows={tableRows}
        handlerGraphKeyChange={handlerGraphKeyChange}
        currentGraphKey={graphKeys}
        emptyText={
          tableRows.length === 0 || descriptor?.periods?.length === 0
            ? formatMessage({ id: 'harvestDashboard.emptyText' })
            : null
        }
        metricCategories={metricCategories}
        lockUnselected={lockUnselected}
        isSelectAll={lockUnselected}
      />
    </div>
  );
};

Crops.propTypes = {
  intl: intlShape.isRequired,
  organizationSlug: PropTypes.string.isRequired,

  plantingCycleId: PropTypes.number.isRequired,
  descriptor: PropTypes.object,
  tableRows: PropTypes.array,
  period: PropTypes.string,

  isMeasurementsByPeriodFetching: PropTypes.bool,
  isMeasurementsByPeriodSuccess: PropTypes.bool,

  requestMeasurementsByPeriod: PropTypes.func.isRequired,
  requestMeasurementsAdditionalMetrics: PropTypes.func.isRequired,

  metricCategories: PropTypes.array,
  additionalMetrics: PropTypes.object,

  showNotificationWithTimeout: PropTypes.func.isRequired,

  saveMeasurementsAdditionalMetrics: PropTypes.func.isRequired,
  saveMeasurementsGraphMetrics: PropTypes.func.isRequired,

  metricsAndPlants: PropTypes.array,
  requestMeasurementsExport: PropTypes.func
};

Crops.defaultProps = {
  tableRows: [],
  period: 'week',
  descriptor: null,

  isMeasurementsByPeriodFetching: false,
  isMeasurementsByPeriodSuccess: false,
  additionalMetrics: null,
  metricCategories: [],
  metricsAndPlants: [],
  requestMeasurementsExport: null
};

export default Crops;
