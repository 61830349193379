import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CSSTransition } from 'react-transition-group';

import classnames from 'classnames';

import { ANIMATION_DURATION } from '../../helpers/constants';

import ClickOutside from '../ClickOutside';

import styles from './DropdownMenu.module.css';

import { ReactComponent as MoreIcon } from './assets/more.svg';
import { ReactComponent as MiniMoreIcon } from './assets/minimore.svg';

// TODO: Переписать на функциональный компонент и сделать функцию закрытия по ref ссылке
export default class DropdownMenu extends PureComponent {
  static propTypes = {
    children: PropTypes.element.isRequired,
    buttonElement: PropTypes.element,
    contentClassName: PropTypes.string,
    buttonSize: PropTypes.string,
    defaultButtonClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    isPreventClose: PropTypes.bool,
    closeDropdown: PropTypes.bool,
    disabled: PropTypes.bool,
    afterClickOutside: PropTypes.func,
    isMini: PropTypes.bool, // TODO: проверить этот пропс, возможно стоит удалить
    withArrow: PropTypes.bool,
  };

  static defaultProps = {
    buttonElement: null,
    contentClassName: null,
    buttonSize: null,
    defaultButtonClassName: null,
    buttonClassName: null,
    className: null,
    wrapperClassName: null,
    isPreventClose: false,
    closeDropdown: false,
    disabled: false,
    afterClickOutside: () => {},
    isMini: false,
    withArrow: false,
  };

  state = {
    opened: false,
  }

  closeMenu = () => {
    this.setState({ opened: false });
  }

  toggleMenu = (isOpened) => {
    this.setState({ opened: isOpened });
  }

  handlerBtnClick = (e) => {
    e.stopPropagation();

    const { disabled, closeDropdown } = this.props;
    const { opened } = this.state;

    if (disabled) {
      return null;
    }

    /**
     * Если было форсированное закрытие (closeDropdown), то открываем дропдаун.
     * Это убирает баг с двойным кликом после установки closeDropdown в true
     */
    const newOpened = closeDropdown ? true : !opened;

    return this.toggleMenu(newOpened);
  }

  handleClickOutside = () => {
    const { afterClickOutside, isPreventClose } = this.props;
    const { opened } = this.state;

    if (opened && !isPreventClose) {
      this.toggleMenu(false);

      afterClickOutside();
    }
  };

  renderDefaultButton = defaultButtonClassName => (
    <div className={classnames(styles.defaultButton, defaultButtonClassName)}>
      {this.props.isMini ?
        <MiniMoreIcon className={styles.moreIcon} />
        :
        <MoreIcon className={styles.moreIcon} />}
    </div>
  );

  render() {
    const {
      children, buttonElement, contentClassName, closeDropdown, className, buttonSize,
      defaultButtonClassName, buttonClassName, wrapperClassName, withArrow
    } = this.props;
    const { opened } = this.state;

    return (
      <ClickOutside
        handleClickOutside={this.handleClickOutside}
        className={wrapperClassName}
      >
        <div className={classnames(
          styles.dropdown,
          className,
          {
            [styles.dropdownOpened]: opened,
            [styles[buttonSize]]: buttonSize,
          }
        )}
        >
          <div
            className={classnames(styles.dropdownButton, buttonClassName)}
            onClick={this.handlerBtnClick}
            role='button'
            tabIndex={0}
          >
            {buttonElement || this.renderDefaultButton(defaultButtonClassName)}
          </div>
          <CSSTransition
            in={opened && !closeDropdown}
            timeout={ANIMATION_DURATION}
            unmountOnExit
            classNames={{
              enter: styles.dropdownEnter,
              enterActive: styles.dropdownEnterActive,
              exit: styles.dropdownLeave,
              exitActive: styles.dropdownLeaveActive,
            }}
          >
            <div className={classnames(styles.dropdownContent, contentClassName, { [styles.withArrow]: withArrow })}>
              {withArrow && <div className={styles.deleteTooltipTriangle} />}
              {children}
            </div>
          </CSSTransition>
        </div>
      </ClickOutside>
    );
  }
}
