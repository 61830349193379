import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getDefaultOpenFromRoute } from 'store/company/selectors';
import { trackPageViewSettings } from 'store/company/actions';

import SettingsProfile from '../components/SettingsProfile';

const mapDispatchToProps = {
  trackPageViewSettings,
};

const mapStateToProps = (state, props) => ({
  ...props,

  defaultOpen: getDefaultOpenFromRoute(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsProfile));
