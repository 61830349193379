export default function compareGraphMetricToGraphMetricGroup(checkedGroup, type) {
  return (item) => {
    if (type === 'byGroup' && item.attributes.group) {
      return item.attributes.group.id === checkedGroup.id;
    }

    const isLocationEqual = item.attributes.location.id === checkedGroup.location.id;

    const isCheckedGroupLocationAverages = !!checkedGroup.compartment && checkedGroup.compartment.id === '#';
    const isCompartmentEqual = item.attributes.compartment && checkedGroup.compartment ?
      item.attributes.compartment.id === checkedGroup.compartment.id :
      !item.attributes.compartment && isCheckedGroupLocationAverages;

    const isCheckedGroupCompartmentAverages = !!checkedGroup.subNode && checkedGroup.subNode.id === '#';
    const isSubNodeEqual = item.attributes.subNode && checkedGroup.subNode ?
      item.attributes.subNode.id === checkedGroup.subNode.id :
      !item.attributes.subNode && (isCheckedGroupCompartmentAverages || !checkedGroup.subNode);

    return isLocationEqual && isCompartmentEqual && isSubNodeEqual;
  };
}
