import 'whatwg-fetch';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { createAction } from 'redux-actions';

import { cloneDeep, debounce } from 'lodash';

import getLocaleName from 'helpers/getLocaleName';
import storageWrapper from '../../helpers/storageWrapper';

import { showNotification, showNotificationWithTimeout } from '../notificationCenter/actions';
import { trackUserProperties, trackUserId, trackPageView, } from '../analytics/actions';
import {
  resetCropsObservations, clearCropsObservations, getCropsMetricsStart, getCropsObservationsStart,
} from '../crops/actions';
import {
  getRightPhotos, getLeftPhotos, initLeftPhotos, initRightPhotos, clearLeftPhotos, clearRightPhotos,
} from '../photos/actions';

import {
  getLeftMetrics,
  getRightMetrics,
  getLeftWeeklyData,
  getRightWeeklyData,
  resetLeftTableData,
  resetRightTableData,
  clearLeftTableData,
  clearRightTableData,
} from '../tables/actions';
import initAxiosInstanse from '../../api/axios';


const safeLocalStorage = storageWrapper.get('localStorage');
// ------------------------------------
// Constants
// ------------------------------------

export const requestUserAuth = createAction('REQUEST_USER_AUTH');
export const requestSetUser = createAction('REQUEST_SET_USER');

export const requestLocationMetrics = createAction('REQUEST_LOCATION_METRICS');
export const receiveLocationMetrics = createAction('RECEIVE_LOCATION_METRICS');
export const clearCompanyStore = createAction('CLEAR_COMPANY_STORE');
export const requestVarietiesAndFruitClasses = createAction('REQUEST_VARIETIES_AND_FRUIT_CLASSES');
export const receiveVarietiesAndFruitClasses = createAction('RECEIVE_VARIETIES_AND_FRUIT_CLASSES');
export const requestProductGroups = createAction('REQUEST_PRODUCT_GROUPS');
export const receiveProductGroups = createAction('RECEIVE_PRODUCT_GROUPS');

export const REQUEST_ALL_GRAPH_METRICS = 'REQUEST_ALL_GRAPH_METRICS';
export const RECEIVE_ALL_GRAPH_METRICS = 'RECEIVE_ALL_GRAPH_METRICS';
export const INVALIDATE_ALL_GRAPH_METRICS = 'INVALIDATE_ALL_GRAPH_METRICS';

export const REQUEST_USER = 'REQUEST_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const INVALIDATE_USER = 'INVALIDATE_USER';

export const REQUEST_USER_PROFILE = 'REQUEST_PROFILE';
export const RECEIVE_USER_PROFILE = 'RECEIVE_PROFILE';
export const INVALIDATE_USER_PROFILE = 'INVALIDATE_PROFILE';

export const INVALIDATE_LOCATIONS = 'INVALIDATE_LOCATIONS';

export const REQUEST_TILES = 'REQUEST_TILES';
export const RECEIVE_TILES = 'RECEIVE_TILES';
export const INVALIDATE_TILES = 'INVALIDATE_TILES';

export const INVALIDATE_ALL_COMPARTMENTS = 'INVALIDATE_ALL_COMPARTMENTS';
export const INVALIDATE_ALL_PLANTING_CYCLES = 'INVALIDATE_ALL_PLANTING_CYCLES';
export const INVALIDATE_ALL_SUB_NODES = 'INVALIDATE_ALL_SUB_NODES';


export const INVALIDATE_VARIETIES = 'INVALIDATE_VARIETIES';

export const REQUEST_LOCATION = 'REQUEST_LOCATION';
export const RECEIVE_LOCATION = 'RECEIVE_LOCATION';
export const INVALIDATE_LOCATION = 'INVALIDATE_LOCATION';

export const REQUEST_COMPARTMENTS = 'REQUEST_COMPARTMENTS';
export const RECEIVE_COMPARTMENTS = 'RECEIVE_COMPARTMENTS';
export const INVALIDATE_COMPARTMENTS = 'INVALIDATE_COMPARTMENTS';

export const REQUEST_INIT_COMPARTMENT = 'REQUEST_INIT_COMPARTMENT';
export const RECEIVE_INIT_COMPARTMENT = 'RECEIVE_INIT_COMPARTMENT';
export const INVALIDATE_INIT_COMPARTMENT = 'INVALIDATE_INIT_COMPARTMENT';


/**
 * Crops
 */
export const REQUEST_COMPARTMENT = 'REQUEST_COMPARTMENT';
export const RECEIVE_COMPARTMENT = 'RECEIVE_COMPARTMENT';
export const INVALIDATE_COMPARTMENT = 'INVALIDATE_COMPARTMENT';

export const REQUEST_PLANTING_CYCLE = 'REQUEST_PLANTING_CYCLE';
export const RECEIVE_PLANTING_CYCLE = 'RECEIVE_PLANTING_CYCLE';
export const INVALIDATE_PLANTING_CYCLE = 'INVALIDATE_PLANTING_CYCLE';

export const REQUEST_INIT_PLANTING_CYCLES = 'REQUEST_INIT_PLANTING_CYCLES';
export const RECEIVE_INIT_PLANTING_CYCLES = 'RECEIVE_INIT_PLANTING_CYCLES';
export const INVALIDATE_INIT_PLANTING_CYCLES = 'INVALIDATE_INIT_PLANTING_CYCLES';

export const REQUEST_PLANTING_CYCLES = 'REQUEST_PLANTING_CYCLES';
export const RECEIVE_PLANTING_CYCLES = 'RECEIVE_PLANTING_CYCLES';
export const INVALIDATE_PLANTING_CYCLES = 'INVALIDATE_PLANTING_CYCLES';

export const REQUEST_INIT_PLANTING_CYCLE = 'REQUEST_INIT_PLANTING_CYCLE';
export const RECEIVE_INIT_PLANTING_CYCLE = 'RECEIVE_INIT_PLANTING_CYCLE';
export const INVALIDATE_INIT_PLANTING_CYCLE = 'INVALIDATE_INIT_PLANTING_CYCLE';


/**
 * Photos
 */
export const REQUEST_INIT_LEFT_COMPARTMENT = 'REQUEST_INIT_LEFT_COMPARTMENT';
export const RECEIVE_INIT_LEFT_COMPARTMENT = 'RECEIVE_INIT_LEFT_COMPARTMENT';
export const INVALIDATE_INIT_LEFT_COMPARTMENT = 'INVALIDATE_INIT_LEFT_COMPARTMENT';

export const REQUEST_LEFT_COMPARTMENT = 'REQUEST_LEFT_COMPARTMENT';
export const RECEIVE_LEFT_COMPARTMENT = 'RECEIVE_LEFT_COMPARTMENT';
export const INVALIDATE_LEFT_COMPARTMENT = 'INVALIDATE_LEFT_COMPARTMENT';

export const REQUEST_RIGHT_COMPARTMENT = 'REQUEST_RIGHT_COMPARTMENT';
export const RECEIVE_RIGHT_COMPARTMENT = 'RECEIVE_RIGHT_COMPARTMENT';
export const INVALIDATE_RIGHT_COMPARTMENT = 'INVALIDATE_RIGHT_COMPARTMENT';

export const REQUEST_INIT_LEFT_PLANTING_CYCLES = 'REQUEST_INIT_LEFT_PLANTING_CYCLES';
export const RECEIVE_INIT_LEFT_PLANTING_CYCLES = 'RECEIVE_INIT_LEFT_PLANTING_CYCLES';
export const INVALIDATE_INIT_LEFT_PLANTING_CYCLES = 'INVALIDATE_INIT_LEFT_PLANTING_CYCLES';

export const REQUEST_LEFT_PLANTING_CYCLES = 'REQUEST_LEFT_PLANTING_CYCLES';
export const RECEIVE_LEFT_PLANTING_CYCLES = 'RECEIVE_LEFT_PLANTING_CYCLES';
export const INVALIDATE_LEFT_PLANTING_CYCLES = 'INVALIDATE_LEFT_PLANTING_CYCLES';

export const REQUEST_RIGHT_PLANTING_CYCLES = 'REQUEST_RIGHT_PLANTING_CYCLES';
export const RECEIVE_RIGHT_PLANTING_CYCLES = 'RECEIVE_RIGHT_PLANTING_CYCLES';
export const INVALIDATE_RIGHT_PLANTING_CYCLES = 'INVALIDATE_RIGHT_PLANTING_CYCLES';

export const REQUEST_INIT_LEFT_PLANTING_CYCLE = 'REQUEST_INIT_LEFT_PLANTING_CYCLE';
export const RECEIVE_INIT_LEFT_PLANTING_CYCLE = 'RECEIVE_INIT_LEFT_PLANTING_CYCLE';
export const INVALIDATE_INIT_LEFT_PLANTING_CYCLE = 'INVALIDATE_INIT_LEFT_PLANTING_CYCLE';

export const REQUEST_LEFT_PLANTING_CYCLE = 'REQUEST_LEFT_PLANTING_CYCLE';
export const RECEIVE_LEFT_PLANTING_CYCLE = 'RECEIVE_LEFT_PLANTING_CYCLE';
export const INVALIDATE_LEFT_PLANTING_CYCLE = 'INVALIDATE_LEFT_PLANTING_CYCLE';

export const REQUEST_INIT_RIGHT_PLANTING_CYCLE = 'REQUEST_INIT_RIGHT_PLANTING_CYCLE';
export const RECEIVE_INIT_RIGHT_PLANTING_CYCLE = 'RECEIVE_INIT_RIGHT_PLANTING_CYCLE';
export const INVALIDATE_INIT_RIGHT_PLANTING_CYCLE = 'INVALIDATE_INIT_RIGHT_PLANTING_CYCLE';

export const REQUEST_RIGHT_PLANTING_CYCLE = 'REQUEST_RIGHT_PLANTING_CYCLE';
export const RECEIVE_RIGHT_PLANTING_CYCLE = 'RECEIVE_RIGHT_PLANTING_CYCLE';
export const INVALIDATE_RIGHT_PLANTING_CYCLE = 'INVALIDATE_RIGHT_PLANTING_CYCLE';

/**
 * Tables
 */
export const REQUEST_INIT_LEFT_COMPARTMENT_TABLES = 'REQUEST_INIT_LEFT_COMPARTMENT_TABLES';
export const RECEIVE_INIT_LEFT_COMPARTMENT_TABLES = 'RECEIVE_INIT_LEFT_COMPARTMENT_TABLES';
export const INVALIDATE_INIT_LEFT_COMPARTMENT_TABLES = 'INVALIDATE_INIT_LEFT_COMPARTMENT_TABLES';

export const REQUEST_LEFT_COMPARTMENT_TABLES = 'REQUEST_LEFT_COMPARTMENT_TABLES';
export const RECEIVE_LEFT_COMPARTMENT_TABLES = 'RECEIVE_LEFT_COMPARTMENT_TABLES';
export const INVALIDATE_LEFT_COMPARTMENT_TABLES = 'INVALIDATE_LEFT_COMPARTMENT_TABLES';

export const REQUEST_RIGHT_COMPARTMENT_TABLES = 'REQUEST_RIGHT_COMPARTMENT_TABLES';
export const RECEIVE_RIGHT_COMPARTMENT_TABLES = 'RECEIVE_RIGHT_COMPARTMENT_TABLES';
export const INVALIDATE_RIGHT_COMPARTMENT_TABLES = 'INVALIDATE_RIGHT_COMPARTMENT_TABLES';

export const REQUEST_INIT_LEFT_PLANTING_CYCLES_TABLES = 'REQUEST_INIT_LEFT_PLANTING_CYCLES_TABLES';
export const RECEIVE_INIT_LEFT_PLANTING_CYCLES_TABLES = 'RECEIVE_INIT_LEFT_PLANTING_CYCLES_TABLES';
export const INVALIDATE_INIT_LEFT_PLANTING_CYCLES_TABLES = 'INVALIDATE_INIT_LEFT_PLANTING_CYCLES_TABLES';

export const REQUEST_LEFT_PLANTING_CYCLES_TABLES = 'REQUEST_LEFT_PLANTING_CYCLES_TABLES';
export const RECEIVE_LEFT_PLANTING_CYCLES_TABLES = 'RECEIVE_LEFT_PLANTING_CYCLES_TABLES';
export const INVALIDATE_LEFT_PLANTING_CYCLES_TABLES = 'INVALIDATE_LEFT_PLANTING_CYCLES_TABLES';

export const REQUEST_RIGHT_PLANTING_CYCLES_TABLES = 'REQUEST_RIGHT_PLANTING_CYCLES_TABLES';
export const RECEIVE_RIGHT_PLANTING_CYCLES_TABLES = 'RECEIVE_RIGHT_PLANTING_CYCLES_TABLES';
export const INVALIDATE_RIGHT_PLANTING_CYCLES_TABLES = 'INVALIDATE_RIGHT_PLANTING_CYCLES_TABLES';

export const REQUEST_INIT_LEFT_PLANTING_CYCLE_TABLES = 'REQUEST_INIT_LEFT_PLANTING_CYCLE_TABLES';
export const RECEIVE_INIT_LEFT_PLANTING_CYCLE_TABLES = 'RECEIVE_INIT_LEFT_PLANTING_CYCLE_TABLES';
export const INVALIDATE_INIT_LEFT_PLANTING_CYCLE_TABLES = 'INVALIDATE_INIT_LEFT_PLANTING_CYCLE_TABLES';

export const REQUEST_LEFT_PLANTING_CYCLE_TABLES = 'REQUEST_LEFT_PLANTING_CYCLE_TABLES';
export const RECEIVE_LEFT_PLANTING_CYCLE_TABLES = 'RECEIVE_LEFT_PLANTING_CYCLE_TABLES';
export const INVALIDATE_LEFT_PLANTING_CYCLE_TABLES = 'INVALIDATE_LEFT_PLANTING_CYCLE_TABLES';

export const REQUEST_INIT_RIGHT_PLANTING_CYCLE_TABLES = 'REQUEST_INIT_RIGHT_PLANTING_CYCLE_TABLES';
export const RECEIVE_INIT_RIGHT_PLANTING_CYCLE_TABLES = 'RECEIVE_INIT_RIGHT_PLANTING_CYCLE_TABLES';
export const INVALIDATE_INIT_RIGHT_PLANTING_CYCLE_TABLES = 'INVALIDATE_INIT_RIGHT_PLANTING_CYCLE_TABLES';

export const REQUEST_RIGHT_PLANTING_CYCLE_TABLES = 'REQUEST_RIGHT_PLANTING_CYCLE_TABLES';
export const RECEIVE_RIGHT_PLANTING_CYCLE_TABLES = 'RECEIVE_RIGHT_PLANTING_CYCLE_TABLES';
export const INVALIDATE_RIGHT_PLANTING_CYCLE_TABLES = 'INVALIDATE_RIGHT_PLANTING_CYCLE_TABLES';

export const UPDATE_METRIC_CATEGORIES = 'UPDATE_METRIC_CATEGORIES';
export const UPDATE_SHOW_ALL_METRIC_CATEGORIES = 'UPDATE_SHOW_ALL_METRIC_CATEGORIES';

// ------------------------------------
// Actions
// ------------------------------------

/*  This is a thunk, meaning it is a function that immediately
 returns a function for lazy evaluation. It is incredibly useful for
 creating async actions, especially when combined with redux-thunk! */

export function receiveUser(user, check) {
  const result = {
    type: RECEIVE_USER,
    user,
    receivedAt: Date.now()
  };

  if (check) {
    result.error = !user;
  }


  return result;
}

function requestUser() {
  return {
    type: REQUEST_USER,
  };
}

export const receiveUserPermissions = createAction('RECEIVE_USER_PERMISSIONS');
export const requestUserPermissions = createAction('REQUEST_USER_PERMISSIONS');
export const requestLocations = createAction('REQUEST_LOCATIONS');
export const receiveLocations = createAction('RECEIVE_LOCATIONS');
export const requestAllCompartments = createAction('REQUEST_ALL_COMPARTMENTS');
export const receiveAllCompartments = createAction('RECEIVE_ALL_COMPARTMENTS');
export const requestAllSubNodes = createAction('REQUEST_ALL_SUB_NODES');
export const receiveAllSubNodes = createAction('RECEIVE_ALL_SUB_NODES');
export const requestAllPlantingCycles = createAction('REQUEST_ALL_PLANTING_CYCLES');
export const receiveAllPlantingCycles = createAction('RECEIVE_ALL_PLANTING_CYCLES');
export const requestAllGreenhouseTypes = createAction('REQUEST_ALL_GREENHOUSE_TYPES');
export const receiveAllGreenhouseTypes = createAction('RECEIVE_ALL_GREENHOUSE_TYPES');
export const requestAllLocationPlans = createAction('REQUEST_ALL_PLAN_TYPES');
export const receiveAllLocationPlans = createAction('RECEIVE_ALL_PLAN_TYPES');
export const changeLocationPlan = createAction('CHANGE_LOCATION_PLAN');
export const changedLocationPlan = createAction('CHANGED_LOCATION_PLAN');
export const requestLocationPlans = createAction('REQUEST_LOCATION_PLANS');
export const receiveLocationPlans = createAction('RECEIVE_LOCATION_PLANS');
export const receiveLocation = createAction('RECEIVE_LOCATION');
export const requestLocation = createAction('REQUEST_LOCATION');
export const setupLocation = createAction('SETUP_LOCATION');

export const requestAllAvailableToBenchmarkProducts = createAction('REQUEST_ALL_AVAILABLE_TO_BENCHMARK_PRODUCTS');
export const receiveAllAvailableToBenchmarkProducts = createAction('RECEIVE_ALL_AVAILABLE_TO_BENCHMARK_PRODUCTS');

function receiveCompartments() {
  return {
    type: RECEIVE_COMPARTMENTS,
    receivedAt: Date.now()
  };
}

function requestCompartments() {
  return {
    type: REQUEST_COMPARTMENTS,
  };
}

function receiveInitCompartment() {
  return {
    type: RECEIVE_INIT_COMPARTMENT,
    receivedAt: Date.now()
  };
}

function requestInitCompartment() {
  return {
    type: REQUEST_INIT_COMPARTMENT,
  };
}

function receiveCompartment(compartment) {
  return {
    type: RECEIVE_COMPARTMENT,
    compartment,
    receivedAt: Date.now()
  };
}

function requestCompartment() {
  return {
    type: REQUEST_COMPARTMENT,
  };
}

function receiveInitPlantingCycles(plantingCycles) {
  return {
    type: RECEIVE_INIT_PLANTING_CYCLES,
    plantingCycles,
    receivedAt: Date.now()
  };
}

function requestInitPlantingCycles() {
  return {
    type: REQUEST_INIT_PLANTING_CYCLES,
  };
}

function receivePlantingCycles(plantingCycles) {
  return {
    type: RECEIVE_PLANTING_CYCLES,
    plantingCycles,
    receivedAt: Date.now()
  };
}

function requestPlantingCycles() {
  return {
    type: REQUEST_PLANTING_CYCLES,
  };
}

function receivePlantingCycle(plantingCycle) {
  return {
    type: RECEIVE_PLANTING_CYCLE,
    plantingCycle,
    receivedAt: Date.now()
  };
}

function requestPlantingCycle() {
  return {
    type: REQUEST_PLANTING_CYCLE,
  };
}

function receiveInitPlantingCycle() {
  return {
    type: RECEIVE_INIT_PLANTING_CYCLE,
    receivedAt: Date.now()
  };
}

function requestInitPlantingCycle() {
  return {
    type: REQUEST_INIT_PLANTING_CYCLE,
  };
}


/**
 * Crops
 */

function receiveLeftCompartment(compartment) {
  return {
    type: RECEIVE_LEFT_COMPARTMENT,
    leftCompartment: compartment,
    receivedAt: Date.now()
  };
}

function requestLeftCompartment() {
  return {
    type: REQUEST_LEFT_COMPARTMENT,
  };
}

function receiveRightCompartment(compartment) {
  return {
    type: RECEIVE_RIGHT_COMPARTMENT,
    rightCompartment: compartment,
    receivedAt: Date.now()
  };
}

function requestRightCompartment() {
  return {
    type: REQUEST_RIGHT_COMPARTMENT,
  };
}

function receiveLeftPlantingCycles(plantingCycles) {
  return {
    type: RECEIVE_LEFT_PLANTING_CYCLES,
    leftPlantingCycles: plantingCycles,
    receivedAt: Date.now()
  };
}

function requestLeftPlantingCycles() {
  return {
    type: REQUEST_LEFT_PLANTING_CYCLES,
  };
}

function receiveRightPlantingCycles(plantingCycles) {
  return {
    type: RECEIVE_RIGHT_PLANTING_CYCLES,
    rightPlantingCycles: plantingCycles,
    receivedAt: Date.now()
  };
}

function requestRightPlantingCycles() {
  return {
    type: REQUEST_RIGHT_PLANTING_CYCLES,
  };
}

function receiveLeftPlantingCycle(plantingCycle) {
  return {
    type: RECEIVE_LEFT_PLANTING_CYCLE,
    leftPlantingCycle: plantingCycle,
    receivedAt: Date.now()
  };
}

function requestLeftPlantingCycle() {
  return {
    type: REQUEST_LEFT_PLANTING_CYCLE,
  };
}

function receiveRightPlantingCycle(plantingCycle) {
  return {
    type: RECEIVE_RIGHT_PLANTING_CYCLE,
    rightPlantingCycle: plantingCycle,
    receivedAt: Date.now()
  };
}

function requestRightPlantingCycle() {
  return {
    type: REQUEST_RIGHT_PLANTING_CYCLE,
  };
}

function receiveInitLeftPlantingCycle() {
  return {
    type: RECEIVE_INIT_LEFT_PLANTING_CYCLE,
    receivedAt: Date.now()
  };
}

function requestInitLeftPlantingCycle() {
  return {
    type: REQUEST_INIT_LEFT_PLANTING_CYCLE,
  };
}

function receiveInitRightPlantingCycle() {
  return {
    type: RECEIVE_INIT_RIGHT_PLANTING_CYCLE,
    receivedAt: Date.now()
  };
}

function requestInitRightPlantingCycle() {
  return {
    type: REQUEST_INIT_RIGHT_PLANTING_CYCLE,
  };
}

/**
 * Tables
 */
function receiveInitLeftCompartmentTables() {
  return {
    type: RECEIVE_INIT_LEFT_COMPARTMENT_TABLES,
    receivedAt: Date.now()
  };
}

function requestInitLeftCompartmentTables() {
  return {
    type: REQUEST_INIT_LEFT_COMPARTMENT_TABLES,
  };
}

function receiveLeftCompartmentTables(compartment) {
  return {
    type: RECEIVE_LEFT_COMPARTMENT_TABLES,
    leftCompartmentTables: compartment,
    receivedAt: Date.now()
  };
}

function requestLeftCompartmentTables() {
  return {
    type: REQUEST_LEFT_COMPARTMENT_TABLES,
  };
}

function receiveRightCompartmentTables(compartment) {
  return {
    type: RECEIVE_RIGHT_COMPARTMENT_TABLES,
    rightCompartmentTables: compartment,
    receivedAt: Date.now()
  };
}

function requestRightCompartmentTables() {
  return {
    type: REQUEST_RIGHT_COMPARTMENT_TABLES,
  };
}

function receiveInitLeftPlantingCyclesTables(plantingCycles) {
  return {
    type: RECEIVE_INIT_LEFT_PLANTING_CYCLES_TABLES,
    leftPlantingCyclesTables: plantingCycles,
    receivedAt: Date.now()
  };
}

function requestInitLeftPlantingCyclesTables() {
  return {
    type: REQUEST_INIT_LEFT_PLANTING_CYCLES_TABLES,
  };
}

function receiveLeftPlantingCyclesTables(plantingCycles) {
  return {
    type: RECEIVE_LEFT_PLANTING_CYCLES_TABLES,
    leftPlantingCyclesTables: plantingCycles,
    receivedAt: Date.now()
  };
}

function requestLeftPlantingCyclesTables() {
  return {
    type: REQUEST_LEFT_PLANTING_CYCLES_TABLES,
  };
}

function receiveRightPlantingCyclesTables(plantingCycles) {
  return {
    type: RECEIVE_RIGHT_PLANTING_CYCLES_TABLES,
    rightPlantingCyclesTables: plantingCycles,
    receivedAt: Date.now()
  };
}

function requestRightPlantingCyclesTables() {
  return {
    type: REQUEST_RIGHT_PLANTING_CYCLES_TABLES,
  };
}

function receiveLeftPlantingCycleTables(plantingCycle) {
  return {
    type: RECEIVE_LEFT_PLANTING_CYCLE_TABLES,
    leftPlantingCycleTables: plantingCycle,
    receivedAt: Date.now()
  };
}

function requestLeftPlantingCycleTables() {
  return {
    type: REQUEST_LEFT_PLANTING_CYCLE_TABLES,
  };
}

function receiveRightPlantingCycleTables(plantingCycle) {
  return {
    type: RECEIVE_RIGHT_PLANTING_CYCLE_TABLES,
    rightPlantingCycleTables: plantingCycle,
    receivedAt: Date.now()
  };
}

function requestRightPlantingCycleTables() {
  return {
    type: REQUEST_RIGHT_PLANTING_CYCLE_TABLES,
  };
}

function receiveInitLeftPlantingCycleTables() {
  return {
    type: RECEIVE_INIT_LEFT_PLANTING_CYCLE_TABLES,
    receivedAt: Date.now()
  };
}

function requestInitLeftPlantingCycleTables() {
  return {
    type: REQUEST_INIT_LEFT_PLANTING_CYCLE_TABLES,
  };
}

function receiveInitRightPlantingCycleTables() {
  return {
    type: RECEIVE_INIT_RIGHT_PLANTING_CYCLE_TABLES,
    receivedAt: Date.now()
  };
}

function requestInitRightPlantingCycleTables() {
  return {
    type: REQUEST_INIT_RIGHT_PLANTING_CYCLE_TABLES,
  };
}

function updateMetricCategories(metricCategories) {
  return {
    type: UPDATE_METRIC_CATEGORIES,
    metricCategories,
  };
}

function updateShowAllMetricCategories(showAllMetricCategories) {
  return {
    type: UPDATE_SHOW_ALL_METRIC_CATEGORIES,
    showAllMetricCategories,
  };
}

/*
 * Analytics
 *
 */
export function trackPageViewDashboards({ dashboardName, defaultOpen }) {
  return async (dispatch) => {
    const pageName = 'Dashboards';
    const path = window.location.href;

    await dispatch(trackPageView({
      path,
      data: {
        pageName,
        dashboardName,
        defaultOpen,
      }
    }));
  };
}

export function trackPageViewPlans() {
  return async (dispatch) => {
    const pageName = 'Plans';
    const path = window.location.href;

    await dispatch(trackPageView({ path, data: { pageName } }));
  };
}

export function trackPageViewGraphs() {
  return async (dispatch) => {
    const pageName = 'Graphs';
    const path = window.location.href;

    await dispatch(trackPageView({ path, data: { pageName } }));
  };
}

export function trackPageViewIncidents() {
  return async (dispatch) => {
    const pageName = 'Incidents';
    const path = window.location.href;

    await dispatch(trackPageView({ path, data: { pageName } }));
  };
}

export function trackPageViewHarvestForecasts() {
  return async (dispatch) => {
    const pageName = 'Harvest Forecasts';
    const path = window.location.href;

    await dispatch(trackPageView({ path, data: { pageName } }));
  };
}

export function trackPageViewCropCyclesCompare() {
  return async (dispatch) => {
    const pageName = 'Crop Cycles Compare';
    const path = window.location.href;

    await dispatch(trackPageView({ path, data: { pageName } }));
  };
}

export function trackPageViewResources({ sectionName, defaultOpen }) {
  return async (dispatch) => {
    const pageName = 'Resources';
    const path = window.location.href;

    await dispatch(trackPageView({
      path,
      data: {
        pageName, sectionName, defaultOpen
      }
    }));
  };
}

export function trackPageViewSettings({ settingName, defaultOpen }) {
  return async (dispatch) => {
    const pageName = 'Settings';
    const path = window.location.href;

    await dispatch(trackPageView({
      path,
      data: {
        pageName, settingName, defaultOpen
      }
    }));
  };
}

export function initPlantingCycle() {
  return async (dispatch, getState) => {
    await dispatch(requestInitPlantingCycle());
    await dispatch(receiveInitPlantingCycle());

    const state = getState();

    if (state && state.company.plantingCycle) {
      await dispatch(resetCropsObservations());
      await dispatch(getCropsMetricsStart({ id: state.company.plantingCycle ? state.company.plantingCycle.id : null }));
      await dispatch(getCropsObservationsStart({ id: state.company.plantingCycle ? state.company.plantingCycle.id : null }));
    }
  };
}

export function initPlantingCycles() {
  return async (dispatch) => {
    await dispatch(requestInitPlantingCycles());
    await dispatch(receiveInitPlantingCycles());
    await dispatch(initPlantingCycle());
  };
}

export function getPlantingCycle(plantingCycle) {
  return async (dispatch) => {
    await dispatch(clearCropsObservations());
    await dispatch(requestPlantingCycle());
    await dispatch(receivePlantingCycle(plantingCycle));
    await dispatch(resetCropsObservations());
    await dispatch(getCropsMetricsStart({ id: plantingCycle ? plantingCycle.id : null }));
    await dispatch(getCropsObservationsStart({ id: plantingCycle ? plantingCycle.id : null }));
  };
}

export function getPlantingCycles() {
  return async (dispatch) => {
    await dispatch(requestPlantingCycles());
    await dispatch(receivePlantingCycles());
    await dispatch(initPlantingCycle());
  };
}

export function initCompartment() {
  return async (dispatch) => {
    await dispatch(requestInitCompartment());
    await dispatch(receiveInitCompartment());
    await dispatch(initPlantingCycles());
  };
}

export function getCompartment(compartment) {
  return async (dispatch) => {
    await dispatch(clearCropsObservations());
    await dispatch(requestCompartment());
    await dispatch(receiveCompartment(compartment));
    await dispatch(getPlantingCycles());
  };
}

/**
 * Photos
 */
export function getRightPlantingCycle(plantingCycle) {
  const { id } = plantingCycle || {};
  return async (dispatch, getState) => {
    await dispatch(clearRightPhotos());
    await dispatch(requestRightPlantingCycle());
    await dispatch(receiveRightPlantingCycle(plantingCycle));

    const state = getState();
    const { company, photos } = state;
    const { photoCategories } = photos;
    const { varieties, rightPlantingCycle, location } = company;

    if (rightPlantingCycle) {
      await dispatch(initRightPhotos(photoCategories, rightPlantingCycle, varieties, location));
    }
    await dispatch(getRightPhotos(id));
  };
}

export function getLeftPlantingCycle(plantingCycle) {
  const { id } = plantingCycle || {};
  return async (dispatch, getState) => {
    const state = getState();

    await dispatch(clearLeftPhotos());
    await dispatch(requestLeftPlantingCycle());
    await dispatch(receiveLeftPlantingCycle(plantingCycle));

    const {
      company,
      photos
    } = state;

    const {
      varieties,
      rightPlantingCycle,
      location
    } = company;

    const {
      photoCategories
    } = photos;

    if (rightPlantingCycle) {
      const rightVariety = state.company.varieties.find(item => item.id === rightPlantingCycle.relationships.variety.data[0].id);
      const leftVariety = state.company.varieties.find(item => item.id === plantingCycle.relationships.variety.data[0].id);

      if (rightVariety && rightVariety.attributes.species !== leftVariety.attributes.species) {
        await dispatch(getRightPlantingCycle());
      }
    }

    if (plantingCycle) {
      await dispatch(initLeftPhotos(photoCategories, plantingCycle, varieties, location));
    }
    await dispatch(getLeftPhotos(id));
  };
}

export function initRightPlantingCycle() {
  return async (dispatch, getState) => {
    await dispatch(requestInitRightPlantingCycle());
    await dispatch(receiveInitRightPlantingCycle());

    const state = getState();
    const { company, photos } = state;

    if (state && state.company.rightPlantingCycle) {
      const {
        varieties,
        rightPlantingCycle,
        location,
      } = company;
      const { photoCategories } = photos;

      await dispatch(initRightPhotos(photoCategories, rightPlantingCycle, varieties, location));

      await dispatch(getRightPhotos(state.company.rightPlantingCycle.id));
    }
  };
}

// TODO: выпилить
export function initLeftPlantingCycle() {
  return async (dispatch, getState) => {
    await dispatch(requestInitLeftPlantingCycle());
    await dispatch(receiveInitLeftPlantingCycle());

    const state = getState();
    const { company } = state;
    const {
      rightPlantingCycle,
      leftPlantingCycle,
    } = company;


    if (state && state.company.rightPlantingCycle) {
      const rightVariety = state.company.varieties.find(item => item.id === rightPlantingCycle.relationships.variety.data[0].id);
      const leftVariety = state.company.varieties.find(item => item.id === leftPlantingCycle.relationships.variety.data[0].id);

      if (rightVariety && rightVariety.attributes.species !== leftVariety.attributes.species) {
        await dispatch(getRightPlantingCycle());
      }
    }

    if (state && state.company.leftPlantingCycle) {
      await dispatch(getLeftPhotos(state.company.leftPlantingCycle.id));
    }
  };
}

export function getLeftPlantingCycles() {
  return async (dispatch) => {
    await dispatch(requestLeftPlantingCycles());
    await dispatch(receiveLeftPlantingCycles());
    await dispatch(initLeftPlantingCycle());
  };
}

export function getRightPlantingCycles() {
  return async (dispatch, getState) => {
    await dispatch(requestRightPlantingCycles());
    await dispatch(receiveRightPlantingCycles());

    const state = getState();

    if (state && state.company.rightPlantingCycles) {
      const rightPlantingCycle = state.company.rightPlantingCycles[0];
      const { leftPlantingCycle } = state.company;
      const rightVariety = state.company.varieties.find(item => rightPlantingCycle && item.id === rightPlantingCycle.relationships.variety.data[0].id);
      const leftVariety = state.company.varieties.find(item => leftPlantingCycle && item.id === leftPlantingCycle.relationships.variety.data[0].id);

      if (rightVariety && rightVariety.attributes.species === leftVariety.attributes.species) {
        return dispatch(initRightPlantingCycle());
      }
    }
    return dispatch(getRightPlantingCycle());
  };
}

export function getRightCompartment(compartment) {
  return async (dispatch) => {
    await dispatch(clearRightPhotos());
    await dispatch(requestRightCompartment());
    await dispatch(receiveRightCompartment(compartment));
    await dispatch(getRightPlantingCycles());
  };
}

export function getLeftCompartment(compartment) {
  return async (dispatch) => {
    await dispatch(clearLeftPhotos());
    await dispatch(requestLeftCompartment());
    await dispatch(receiveLeftCompartment(compartment));
    await dispatch(getLeftPlantingCycles());
  };
}

/**
 * Tables
 */
export function getRightPlantingCycleTables(plantingCycle) {
  const { id } = plantingCycle || {};
  return async (dispatch, getState) => {
    const state = getState();
    const { company: { location } } = state;

    await dispatch(clearRightTableData());
    await dispatch(requestRightPlantingCycleTables());
    await dispatch(receiveRightPlantingCycleTables(plantingCycle));

    if (!window.TABLES_DISABLED) {
      await dispatch(resetRightTableData());
      await dispatch(getRightMetrics(id));
      await dispatch(getRightWeeklyData(id, location));
    }
  };
}

export function getLeftPlantingCycleTables(plantingCycle) {
  const { id } = plantingCycle || {};
  return async (dispatch, getState) => {
    await dispatch(clearLeftTableData());
    await dispatch(requestLeftPlantingCycleTables());
    await dispatch(receiveLeftPlantingCycleTables(plantingCycle));

    const state = getState();
    const { company: { location } } = state;

    if (state && state.company.rightPlantingCycleTables) {
      const rightPlantingCycle = state.company.rightPlantingCycleTables;
      const rightVariety = state.company.varieties.find(item => item.id === rightPlantingCycle.relationships.variety.data[0].id);
      const leftVariety = state.company.varieties.find(item => item.id === plantingCycle.relationships.variety.data[0].id);

      if (rightVariety && rightVariety.attributes.species !== leftVariety.attributes.species) {
        await dispatch(getRightPlantingCycleTables());
      }
    }

    if (!window.TABLES_DISABLED) {
      await dispatch(resetLeftTableData());
      await dispatch(getLeftMetrics(id));
      await dispatch(getLeftWeeklyData(id, location));
    }
  };
}

export function initRightPlantingCycleTables() {
  return async (dispatch, getState) => {
    await dispatch(requestInitRightPlantingCycleTables());
    await dispatch(receiveInitRightPlantingCycleTables());

    const state = getState();
    const { company: { location } } = state;

    if (state && state.company.rightPlantingCycleTables) {
      if (!window.TABLES_DISABLED) {
        await dispatch(resetRightTableData());
        await dispatch(getRightMetrics(state.company.rightPlantingCycleTables.id));
        await dispatch(getRightWeeklyData(state.company.rightPlantingCycleTables.id, location));
      }
    }
  };
}

export function initLeftPlantingCycleTables() {
  return async (dispatch, getState) => {
    await dispatch(requestInitLeftPlantingCycleTables());
    await dispatch(receiveInitLeftPlantingCycleTables());

    const state = getState();
    const { company: { location } } = state;

    if (state && state.company.rightPlantingCycleTables) {
      const rightPlantingCycle = state.company.rightPlantingCycleTables;
      const leftPlantingCycle = state.company.leftPlantingCycleTables;
      const rightVariety = state.company.varieties.find(item => item.id === rightPlantingCycle.relationships.variety.data[0].id);
      const leftVariety = state.company.varieties.find(item => item.id === leftPlantingCycle.relationships.variety.data[0].id);

      if (rightVariety && rightVariety.attributes.species !== leftVariety.attributes.species) {
        await dispatch(getRightPlantingCycleTables());
      }
    }

    if (state && state.company.leftPlantingCycleTables) {
      if (!window.TABLES_DISABLED) {
        await dispatch(resetLeftTableData());
        await dispatch(getLeftMetrics(state.company.leftPlantingCycleTables.id));
        await dispatch(getLeftWeeklyData(state.company.leftPlantingCycleTables.id, location));
      }
    }
  };
}

export function initLeftPlantingCyclesTables() {
  return async (dispatch) => {
    await dispatch(requestInitLeftPlantingCyclesTables());
    await dispatch(receiveInitLeftPlantingCyclesTables());
    await dispatch(initLeftPlantingCycleTables());
  };
}

export function getLeftPlantingCyclesTables() {
  return async (dispatch) => {
    await dispatch(requestLeftPlantingCyclesTables());
    await dispatch(receiveLeftPlantingCyclesTables());
    await dispatch(initLeftPlantingCycleTables());
  };
}

export function getRightPlantingCyclesTables() {
  return async (dispatch, getState) => {
    await dispatch(requestRightPlantingCyclesTables());
    await dispatch(receiveRightPlantingCyclesTables());

    const state = getState();

    if (state && state.company.rightPlantingCyclesTables) {
      const rightPlantingCycle = state.company.rightPlantingCyclesTables[0];
      const leftPlantingCycle = state.company.leftPlantingCycleTables;
      const rightVariety = state.company.varieties.find(item => rightPlantingCycle && item.id === rightPlantingCycle.relationships.variety.data[0].id);
      const leftVariety = state.company.varieties.find(item => leftPlantingCycle && item.id === leftPlantingCycle.relationships.variety.data[0].id);

      if (rightVariety && rightVariety.attributes.species === leftVariety.attributes.species) {
        return dispatch(initRightPlantingCycleTables());
      }
    }
    return dispatch(getRightPlantingCycleTables());
  };
}

export function initLeftCompartmentTables() {
  return async (dispatch) => {
    await dispatch(requestInitLeftCompartmentTables());
    await dispatch(receiveInitLeftCompartmentTables());
    await dispatch(initLeftPlantingCyclesTables());
  };
}

export function getRightCompartmentTables(compartment) {
  return async (dispatch) => {
    await dispatch(clearRightTableData());
    await dispatch(requestRightCompartmentTables());
    await dispatch(receiveRightCompartmentTables(compartment));
    await dispatch(getRightPlantingCyclesTables());
  };
}

export function getLeftCompartmentTables(compartment) {
  return async (dispatch) => {
    await dispatch(clearLeftTableData());
    await dispatch(requestLeftCompartmentTables());
    await dispatch(receiveLeftCompartmentTables(compartment));
    await dispatch(getLeftPlantingCyclesTables());
  };
}


export function getAllPlantingCycles(plantingCycles) {
  return async (dispatch) => {
    await dispatch(requestAllPlantingCycles());
    await dispatch(receiveAllPlantingCycles({
      plantingCycles,
      receivedAt: Date.now(),
    }));
  };
}

export function getAllGreenhouseTypes(greenhouseTypes) {
  return async (dispatch) => {
    await dispatch(requestAllGreenhouseTypes());
    await dispatch(receiveAllGreenhouseTypes(greenhouseTypes));
  };
}

export function getCompartments() {
  return async (dispatch) => {
    await dispatch(requestCompartments());
    await dispatch(receiveCompartments());
    await dispatch(initCompartment());
  };
}

export function getSplitCompartmentsTables() {
  return async (dispatch) => {
    await dispatch(requestCompartments());
    await dispatch(receiveCompartments());
    await dispatch(initLeftCompartmentTables());
    await dispatch(getRightCompartmentTables());
  };
}

export function getSplitCompartmentsPhotos() {
  return async (dispatch) => {
    await dispatch(requestCompartments());
    await dispatch(receiveCompartments());
  };
}

export function checkCookiePolicy() {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { user, location } = company;

    if (user && location) {
      const { attributes: userAttributes } = user;
      const { attributes: { slug } } = location;

      if (userAttributes) {
        const { cookiePolicyAccepted } = userAttributes;

        if (!cookiePolicyAccepted) {
          await dispatch(showNotification({
            id: 'legal',
            messageId: 'legal.popupText',
            messageParams: {
              link: (
                <RouterLink to={`/${slug}/terms/privacy-and-cookie-policy`} style={{ color: '#ffffff' }}>
                  <FormattedMessage id='legal.popupLink' />
                </RouterLink>
              )
            },
            position: 'center',
            iconType: null,
            notificationType: 'withAction',
          }));
        }
      }
    }
  };
}

export function getLocation(location, preventReload = false) {
  return async (dispatch) => {
    safeLocalStorage.setItem('location', location.id);

    if (!preventReload) {
      const editedLocation = location;

      editedLocation.attributes.showAll = true;
      editedLocation.attributes.showAggregatedSeries = true;
      editedLocation.attributes.loaded = false;
      editedLocation.attributes.loaded = false;
      editedLocation.attributes.loading = false;
      editedLocation.attributes.expandedMetrics = false;

      await dispatch(receiveLocation(editedLocation));
    }
  };
}

export function getLocations() {
  return async (dispatch) => {
    await dispatch(requestLocations());
  };
}

export function getLogin(browserHistory, passwordNotification = false) {
  return async (dispatch) => {
    await dispatch(requestUser());

    const jwt = safeLocalStorage.getItem('jwt');
    const locale = safeLocalStorage.getItem('locale');
    const headers = new Headers();

    if (jwt) {
      headers.append('Authorization', `JWT ${jwt}`);
      headers.append('X-Lang', locale);

      const response = await fetch(`${window.API}/user-profile`, {
        headers,
      });

      const { status } = response;

      if (Math.round(status / 100) === 2) {
        const { data } = await response.json();

        if (data && data.length) {
          const user = data[0];
          await dispatch(receiveUser(user));
          await dispatch(getLocations());

          await dispatch(trackUserId({ userId: user.id }));

          await dispatch(trackUserProperties({
            data: {
              Email: user.attributes ? user.attributes.email : '',
              'App Language': getLocaleName(locale),
            },
          }));

          if (window.Intercom && window.INTERCOM_KEY) {
            window.Intercom('boot', {
              app_id: window.INTERCOM_KEY,
              name: user.attributes ? user.attributes.name : '',
              email: user.attributes ? user.attributes.email : '',
            });
          }

          if (window.location.pathname.indexOf('login') !== -1) {
            browserHistory.push('/');
          }

          const { attributes: userAttributes } = user;

          if (userAttributes) {
            const { cookiePolicyAccepted } = userAttributes;

            if (!cookiePolicyAccepted) {
              await dispatch(showNotification({
                id: 'legal',
                messageId: 'legal.popupText',
                messageParams: { link: <RouterLink to='/terms/privacy-and-cookie-policy' style={{ color: '#ffffff' }}><FormattedMessage id='legal.popupLink' /></RouterLink> },
                position: 'center',
                iconType: null,
                notificationType: 'withAction',
              }));
            }
          }

          if (passwordNotification) {
            await dispatch(showNotificationWithTimeout({
              id: `notifications.setPasswordNotification.${Date.now()}`,
              messageId: 'notifications.setPasswordNotification',
              position: 'leftDown',
              iconType: 'success',
              notificationType: 'withActionWide',
            }));
          }

          return null;
        }
      }
    }

    await dispatch(receiveUser());
    await dispatch(trackUserId({ userId: null }));

    if (window.Intercom && window.INTERCOM_KEY) {
      window.Intercom('boot', {
        app_id: window.INTERCOM_KEY,
      });
    }

    if (browserHistory && browserHistory.location.pathname.indexOf('login') === -1) {
      const searchParams = new URLSearchParams(browserHistory.location.search);
      const paramsString = Array.from(searchParams.entries())
        .filter(([key]) => key !== 'redirect')
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
      const redirectUrl = paramsString.length > 0 ?
        `${browserHistory.location.pathname}?${paramsString}` :
        browserHistory.location.pathname;
      browserHistory.push(`/login?redirect=${encodeURIComponent(redirectUrl)}`);
    }

    return null;
  };
}

// Graph Metrics Tree

function requestAllGraphMetrics() {
  return {
    type: REQUEST_ALL_GRAPH_METRICS,
  };
}

function receiveAllGraphMetrics(allGraphMetrics) {
  return {
    type: RECEIVE_ALL_GRAPH_METRICS,
    allGraphMetrics,
    receivedAt: Date.now()
  };
}

export function getAllGraphMetrics({ location, compartment, subNode }) {
  return async (dispatch) => {
    dispatch(requestAllGraphMetrics({ location, compartment, subNode }));
    if (location) {
      let url = `${window.API}/locations/${location.id}/metrics`;

      if (compartment) {
        url += `?compartmentId=${compartment.id}`;
      }

      if (subNode) {
        url += `&subNodeId=${subNode.id}`;
      }

      const jwt = safeLocalStorage.getItem('jwt');
      const locale = safeLocalStorage.getItem('locale');
      const response = await fetch(url, {
        headers: {
          Authorization: `JWT ${jwt}`,
          'X-Lang': locale,
        },
        credentials: 'include',
      });
      const { status } = response;

      if (Math.round(status / 100) === 2) {
        const { data } = await response.json();

        data.forEach(((item) => {
          const graphMetric = item;

          graphMetric.attributes.subNode = subNode || null;
          graphMetric.attributes.location = location || null;
          graphMetric.attributes.compartment = compartment || null;
        }));

        return dispatch(receiveAllGraphMetrics(data));
      }
    }
    return dispatch(receiveAllGraphMetrics());
  };
}

export function resetShowAllMetricCategories() {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { metricCategories } = company;
    const editedMetricCategories = cloneDeep(metricCategories);

    editedMetricCategories
      .forEach((item) => {
        const editedItem = item;

        editedItem.showAll = true;
      });

    await dispatch(updateShowAllMetricCategories(true));
    await dispatch(updateMetricCategories(editedMetricCategories));
  };
}

export function toggleShowAllMetricCategories() {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { metricCategories, showAllMetricCategories } = company;
    const editedMetricCategories = cloneDeep(metricCategories);

    editedMetricCategories
      .forEach((item) => {
        const editedItem = item;

        editedItem.showAll = !showAllMetricCategories;
      });

    await dispatch(updateShowAllMetricCategories(!showAllMetricCategories));
    await dispatch(updateMetricCategories(editedMetricCategories));
  };
}

export function toggleShowAllMetricCategory(category) {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { metricCategories } = company;
    const editedMetricCategories = cloneDeep(metricCategories);
    const editedCategory = editedMetricCategories.find(item => item.title === category);
    const { showAll } = editedCategory;

    editedCategory.showAll = !showAll;

    if (showAll) {
      await dispatch(updateShowAllMetricCategories(false));
    } else {
      const isFullShowAllMetricCategories = editedMetricCategories.every(item => item.showAll);

      await dispatch(updateShowAllMetricCategories(isFullShowAllMetricCategories));
    }

    await dispatch(updateMetricCategories(editedMetricCategories));
  };
}

export function toggleShowAllMetricCategoryOnly(category) {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { metricCategories } = company;
    const editedMetricCategories = cloneDeep(metricCategories);
    const editedCategory = editedMetricCategories.find(item => item.title === category);

    await dispatch(updateShowAllMetricCategories(false));

    editedMetricCategories.forEach((item) => {
      const editedItem = item;

      editedItem.showAll = false;
    });

    editedCategory.showAll = true;

    await dispatch(updateMetricCategories(editedMetricCategories));
  };
}

export function resetLocationShowAll() {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { location, allCompartments } = company;
    const editedLocation = cloneDeep(location);

    editedLocation.attributes.showAll = true;
    editedLocation.attributes.showAggregatedSeries = true;

    allCompartments
      .filter(item => location && item.relationships.location && item.relationships.location.data[0].id === location.id)
      .forEach((item) => {
        const editedItem = item;

        editedItem.attributes.showAll = true;
      });

    await dispatch(receiveAllCompartments({
      compartments: allCompartments,
      receivedAt: Date.now()
    }));
    await dispatch(setupLocation(editedLocation));
  };
}

export function toggleLocationShowAll() {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { location, allCompartments } = company;
    const { attributes: { showAll } } = location;
    const editedLocation = cloneDeep(location);

    editedLocation.attributes.showAll = !showAll;
    editedLocation.attributes.showAggregatedSeries = !showAll;

    allCompartments
      .filter(item => location && item.relationships.location && item.relationships.location.data[0].id === location.id)
      .forEach((item) => {
        const editedItem = item;

        editedItem.attributes.showAll = !showAll;
      });

    await dispatch(receiveAllCompartments({
      compartments: allCompartments,
      receivedAt: Date.now()
    }));
    await dispatch(setupLocation(editedLocation));
  };
}

export function locationShowAllOn() {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { location, allCompartments } = company;
    const editedLocation = cloneDeep(location);

    editedLocation.attributes.showAll = true;
    editedLocation.attributes.showAggregatedSeries = true;

    allCompartments
      .filter(item => location && item.relationships.location && item.relationships.location.data[0].id === location.id)
      .forEach((item) => {
        const editedItem = item;

        editedItem.attributes.showAll = true;
      });

    await dispatch(receiveAllCompartments({
      compartments: allCompartments,
      receivedAt: Date.now()
    }));
    await dispatch(setupLocation(editedLocation));
  };
}

export function toggleLocationShowAggregatedSeries() {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { location, allCompartments } = company;
    const { attributes: { showAggregatedSeries } } = location;
    const editedLocation = cloneDeep(location);

    editedLocation.attributes.showAggregatedSeries = !showAggregatedSeries;

    if (showAggregatedSeries) {
      editedLocation.attributes.showAll = !showAggregatedSeries;
    } else {
      const isFullCompartmentsShowAll = allCompartments
        .filter(item => location && item.relationships.location && item.relationships.location.data[0].id === location.id)
        .every(item => item.attributes.showAll);

      editedLocation.attributes.showAll = isFullCompartmentsShowAll && !showAggregatedSeries;
    }
    await dispatch(setupLocation(editedLocation));
  };
}

export function toggleLocationShowAggregatedSeriesOnly() {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { location, allCompartments } = company;
    const editedLocation = cloneDeep(location);

    editedLocation.attributes.showAll = false;
    editedLocation.attributes.showAggregatedSeries = true;

    const newCompartments = cloneDeep(allCompartments);

    newCompartments
      .filter(item => location && item.relationships.location && item.relationships.location.data[0].id === location.id)
      .forEach((item) => {
        const editedItem = item;

        editedItem.attributes.showAll = false;
      });

    await dispatch(receiveAllCompartments({
      compartments: newCompartments,
      receivedAt: Date.now()
    }));
    await dispatch(setupLocation(editedLocation));
  };
}

export function toggleLocationShowCompartment(compartment) {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { location, allCompartments } = company;
    const { id, attributes: { showAll: compartmentShowAll } } = compartment;
    const editedLocation = cloneDeep(location);

    const newCompartments = cloneDeep(allCompartments);
    const editedCompartment = newCompartments.find(item => item.id === id);

    editedCompartment.attributes.showAll = !compartmentShowAll;

    if (compartmentShowAll) {
      editedLocation.attributes.showAll = !compartmentShowAll;
    } else {
      const isFullCompartmentsShowAll = newCompartments
        .filter(item => location && item.relationships.location && item.relationships.location.data[0].id === location.id)
        .every(item => item.attributes.showAll);

      editedLocation.attributes.showAll = isFullCompartmentsShowAll && !compartmentShowAll;
    }

    await dispatch(receiveAllCompartments({
      compartments: newCompartments,
      receivedAt: Date.now()
    }));
    await dispatch(setupLocation(editedLocation));
  };
}

export function toggleLocationShowCompartmentOnly(compartment) {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { location, allCompartments } = company;
    const { id } = compartment;
    const editedLocation = cloneDeep(location);

    const newCompartments = cloneDeep(allCompartments);
    const editedCompartment = newCompartments.find(item => item.id === id);

    newCompartments
      .filter(item => location && item.relationships.location && item.relationships.location.data[0].id === location.id)
      .forEach((item) => {
        const editedItem = item;

        editedItem.attributes.showAll = false;
      });

    editedCompartment.attributes.showAll = true;
    editedLocation.attributes.showAll = false;
    editedLocation.attributes.showAggregatedSeries = false;

    await dispatch(receiveAllCompartments({
      compartments: newCompartments,
      receivedAt: Date.now()
    }));
    await dispatch(setupLocation(editedLocation));
  };
}

export function toggleExpandedLocation({ location }) {
  return async (dispatch) => {
    const { attributes: { expanded } } = location;
    const editedLocation = cloneDeep(location);

    editedLocation.attributes.expanded = !expanded;

    await dispatch(setupLocation(editedLocation));
  };
}

export function toggleExpandedLocationMetrics() {
  return async (dispatch, getState) => {
    const state = getState();
    const { company } = state;
    const { location: storedLocation } = company;

    const { attributes: { expandedMetrics, loaded } } = storedLocation;

    if (!loaded && !expandedMetrics) {
      const editedLocation = cloneDeep(storedLocation);

      editedLocation.attributes.loading = true;

      await dispatch(setupLocation(editedLocation));
      await dispatch(getAllGraphMetrics({ location: storedLocation }));
    }

    const editedLocation = cloneDeep(storedLocation);

    editedLocation.attributes.expandedMetrics = !expandedMetrics;
    editedLocation.attributes.loading = false;
    editedLocation.attributes.loaded = true;

    await dispatch(setupLocation(editedLocation));
  };
}

export function toggleExpandedCompartment({ compartment }) {
  return async (dispatch, getState) => {
    const state = getState();
    const { company } = state;
    const { allCompartments } = company;
    const { id, attributes: { expanded } } = compartment;
    const newCompartments = cloneDeep(allCompartments);
    const editedCompartment = newCompartments.find(item => item.id === id);

    editedCompartment.attributes.expanded = !expanded;

    await dispatch(receiveAllCompartments({
      compartments: newCompartments,
      receivedAt: Date.now()
    }));

    return compartment;
  };
}

export function toggleExpandedCompartmentMetrics({ compartment, location }) {
  return async (dispatch, getState) => {
    const state = getState();
    const { company } = state;
    const { allCompartments } = company;
    const { id, attributes: { expandedMetrics, loaded } } = compartment;

    if (!loaded && !expandedMetrics) {
      const newCompartments = cloneDeep(allCompartments);
      const editedCompartment = newCompartments.find(item => item.id === id);

      editedCompartment.attributes.loading = true;

      await dispatch(receiveAllCompartments({
        compartments: newCompartments,
        receivedAt: Date.now()
      }));

      await dispatch(getAllGraphMetrics({ compartment, location }));
    }
    const newCompartments = cloneDeep(allCompartments);
    const editedCompartment = newCompartments.find(item => item.id === id);

    editedCompartment.attributes.expandedMetrics = !expandedMetrics;
    editedCompartment.attributes.loaded = true;
    editedCompartment.attributes.loading = false;

    await dispatch(receiveAllCompartments({
      compartments: newCompartments,
      receivedAt: Date.now()
    }));
  };
}

export function toggleExpandedSubNodeMetrics({ subNode, compartment, location }) {
  return async (dispatch, getState) => {
    const state = getState();
    const { company } = state;
    const { allSubNodes } = company;
    const { id, attributes: { expanded, expandedMetrics, loaded } } = subNode;

    if (!loaded && !expandedMetrics) {
      const newSubNodes = cloneDeep(allSubNodes);
      const editedSubNode = newSubNodes.find(item => item.id === id);

      editedSubNode.attributes.loading = true;

      await dispatch(receiveAllSubNodes({
        subNodes: newSubNodes,
        receivedAt: Date.now()
      }));

      await dispatch(getAllGraphMetrics({ subNode, compartment, location }));
    }

    const { company: { allSubNodes: newAllSubNodes } } = getState();

    const newSubNodes = cloneDeep(newAllSubNodes);
    const editedSubNode = newSubNodes.find(item => item.id === id);

    editedSubNode.attributes.expanded = !expanded;
    editedSubNode.attributes.expandedMetrics = !expandedMetrics;
    editedSubNode.attributes.loaded = true;
    editedSubNode.attributes.loading = false;

    await dispatch(receiveAllSubNodes({
      subNodes: newSubNodes,
      receivedAt: Date.now()
    }));
  };
}

export const REQUEST_DICTIONARIES = 'REQUEST_DICTIONARIES';
export const RECEIVE_DICTIONARIES = 'RECEIVE_DICTIONARIES';
export const requestDictionaries = createAction(REQUEST_DICTIONARIES);
export const receiveDictionaries = createAction(RECEIVE_DICTIONARIES);


// For SidePanel only. TODO: Remove after refactoring SidePanel
export const SELECT_GRAPH_METRIC_FILTER_OPTION = 'SELECT_GRAPH_METRIC_FILTER_OPTION';
export const RECEIVE_GRAPH_METRIC_FILTER_OPTIONS = 'RECEIVE_GRAPH_METRIC_FILTER_OPTIONS';
export const REQUEST_GRAPH_METRIC_FILTER_OPTIONS = 'REQUEST_GRAPH_METRIC_FILTER_OPTIONS';
export const UPDATE_GRAPH_METRIC_FILTER = 'UPDATE_GRAPH_METRIC_FILTER';

export function updateGraphMetricFilter({ graphMetricFilter }) {
  return {
    type: UPDATE_GRAPH_METRIC_FILTER,
    graphMetricFilter,
    receivedAt: Date.now(),
  };
}

export function selectGraphMetricFilterOption({ graphMetricFilterOption }) {
  return {
    type: SELECT_GRAPH_METRIC_FILTER_OPTION,
    graphMetricFilterOption,
    receivedAt: Date.now(),
  };
}

export function receiveGraphMetricFilterOptions({ graphMetricFilterOptions }) {
  return {
    type: RECEIVE_GRAPH_METRIC_FILTER_OPTIONS,
    graphMetricFilterOptions,
    receivedAt: Date.now(),
  };
}

export function requestGraphMetricFilterOptions() {
  return {
    type: REQUEST_GRAPH_METRIC_FILTER_OPTIONS,
  };
}

export function setSelectedGraphMetricFilterOption({
  graphMetricFilterOption,
}) {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { allCompartments, allSubNodes, location } = company;
    const editedCompartments = cloneDeep(allCompartments);
    const editedSubNodes = cloneDeep(allSubNodes);
    const editedLocation = cloneDeep(location);

    if (graphMetricFilterOption && graphMetricFilterOption.nodes) {
      editedSubNodes.forEach((subNode) => {
        if (
          graphMetricFilterOption.nodes.find(
            ({ compartmentId, subNodeId, locationId }) =>
              locationId === location.id &&
              compartmentId === subNode.relationships.compartment.data[0].id &&
              subNodeId === subNode.id
          )
        ) {
          const editedItem = subNode;
          editedItem.attributes.expanded = false;
          editedItem.attributes.expandedMetrics = false;
        }
      });
      await dispatch(
        receiveAllSubNodes({
          subNodes: editedSubNodes,
          receivedAt: Date.now(),
        })
      );
      editedCompartments.forEach((compartment) => {
        if (
          graphMetricFilterOption.nodes.find(
            ({ compartmentId, subNodeId, locationId }) =>
              locationId === location.id &&
              compartmentId === compartment.id &&
              !subNodeId
          )
        ) {
          const editedItem = compartment;
          editedItem.attributes.expanded = true;
          editedItem.attributes.expandedMetrics = true;
        }
      });
      await dispatch(
        receiveAllCompartments({
          compartments: editedCompartments,
          receivedAt: Date.now(),
        })
      );
      editedLocation.attributes.expandedMetrics = true;

      await dispatch(setupLocation(editedLocation));
    } else {
      editedSubNodes.forEach((item) => {
        const editedItem = item;
        editedItem.attributes.expanded = false;
        editedItem.attributes.expandedMetrics = false;
      });
      await dispatch(
        receiveAllSubNodes({
          subNodes: editedSubNodes,
          receivedAt: Date.now(),
        })
      );
      editedCompartments.forEach((item) => {
        const editedItem = item;
        editedItem.attributes.expanded = false;
        editedItem.attributes.expandedMetrics = false;
      });
      await dispatch(
        receiveAllCompartments({
          compartments: editedCompartments,
          receivedAt: Date.now(),
        })
      );
      editedLocation.attributes.expandedMetrics = false;

      await dispatch(setupLocation(editedLocation));
    }
    await dispatch(selectGraphMetricFilterOption({ graphMetricFilterOption }));
  };
}

const getGraphMetricFilterOptionsInner = debounce(
  async (dispatch, getState) => {
    dispatch(requestGraphMetricFilterOptions());
    const { graphs, company } = getState();
    const { graphMetricFilter } = graphs;
    const {
      location,
    } = company;
    if (
      graphMetricFilter &&
      (Number.isInteger(Number.parseInt(graphMetricFilter, 10)) ||
        graphMetricFilter.trim().length > 2)
    ) {
      const response = await initAxiosInstanse().post(
        `${window.API}/locations/${location.id}/metrics/search`,
        {
          locationId: location.id,
          searchText: graphMetricFilter,
          returnTopN: 10,
        }
      );
      const { status, data: graphMetricFilterOptions } = response;
      if (Math.round(status / 100) === 2) {
        return dispatch(
          receiveGraphMetricFilterOptions({ graphMetricFilterOptions })
        );
      }
    }
    return dispatch(
      receiveGraphMetricFilterOptions({ graphMetricFilterOptions: [] })
    );
  },
  200
);

export function getGraphMetricFilterOptions() {
  return getGraphMetricFilterOptionsInner;
}

export function setGraphMetricFilter({ graphMetricFilter, notSendRequest }) {
  return async (dispatch) => {
    await dispatch(updateGraphMetricFilter({ graphMetricFilter }));
    if (!notSendRequest) {
      await dispatch(getGraphMetricFilterOptions());
    }
  };
}
