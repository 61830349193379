import React, { memo, } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './Tooltip.module.css';

const Tooltip = React.memo(({
  text,
  style,
  className,
  leftPosition,
  noWrap,
  error,
}) => (
  <div
    style={style}
    className={classnames(
      styles.tooltip,
      className,
      {
        [styles.noWrap]: noWrap,
        [styles.leftPosition]: leftPosition,
        [styles.error]: error,
      },
    )}
  >
    {text}
  </div>
));

Tooltip.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  noWrap: PropTypes.bool,
  leftPosition: PropTypes.bool,
  error: PropTypes.bool,
};

Tooltip.defaultProps = {
  style: {},
  className: null,
  noWrap: false,
  leftPosition: false,
  error: false,
};

export default memo(Tooltip);
