import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { uniqueId } from 'lodash';
import classnames from 'classnames';

import Typography from 'components/Typography';

import TurnArrowIcon from 'components/Icons/TurnArrowIcon';
import ArrowNextIcon from 'components/Icons/ArrowNextIcon';

import styles from './SettingsMeasurementsList.module.css';

const renderPlate = (formatPlural, formatMessage, isSecondLevel, groupList) => groupList.map(group => (
  <div key={`group-${uniqueId()}`} className={classnames(styles.group, { [styles.secondLevel]: isSecondLevel })}>
    <NavLink className={styles.groupContainer} to={group?.href}>
      <div className={styles.firstColumn}>
        {isSecondLevel && <TurnArrowIcon className={styles.turnArrowIcon} />}
        <div className={styles.groupName}>
          <Typography variant='body2' className={styles.placeholder}>
            {isSecondLevel ?
              formatMessage({ id: 'settings.separateList' })
              :
              formatMessage({ id: 'settings.listByDefault' })}
          </Typography>
          <Typography variant='body1' className={styles.name}>
            {group?.groupName}
          </Typography>
        </div>
      </div>
      <div className={styles.metricsCount}>
        <span className={styles.metricsCountLength}>
          {`${group?.metricsCount} ${formatMessage({ id: `plural.metric.${formatPlural(group?.metricsCount?.length)}` })}`}
        </span>
        <div className={styles.arrowRightIconWrapper}>
          <ArrowNextIcon className={styles.arrowRightIcon} />
        </div>
      </div>
    </NavLink>
    {group.list && group.list.length > 0 && renderPlate(formatPlural, formatMessage, true, group?.list)}
  </div>
));

const SettingsMeasurementsList = ({
  intl: { formatMessage, formatPlural },
  list,
  className,
}) => {
  if (!list || list.length === 0) {
    return null;
  }

  return (
    <div className={classnames(styles.settingsMeasurementsList, className)}>
      {renderPlate(formatPlural, formatMessage, false, list)}
    </div>
  );
};

SettingsMeasurementsList.propTypes = {
  intl: intlShape.isRequired,
  list: PropTypes.array,
  className: PropTypes.string,
};

SettingsMeasurementsList.defaultProps = {
  list: [],
  className: undefined,
};

export default injectIntl(memo((SettingsMeasurementsList)));
