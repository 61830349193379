import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

class CoreLayout extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    getLogin: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { getLogin, history } = this.props;

    getLogin(history);

    if (window.Intercom) {
      window.Intercom('update');
    }
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default withRouter(CoreLayout);
