import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { getFetching, getCurrentOrganization, getOrganizationSlug } from '../../../store/company/selectors';

import { getCurrentYearOverallQuality, getOverallQualityReceivedAt } from '../../../store/dataQuality/selectors';

import { requestOverallQuality } from '../../../store/dataQuality/actions';

import DataQualityNotificationWrapper from '../components/DataQualityNotificationWrapper';

const mapDispatchToProps = {
  requestOverallQuality,
};

const mapStateToProps = (state, props) => ({
  ...props,

  organizationSlug: getOrganizationSlug(state, props),
  isOrganizationFetching: getFetching(state),
  organization: getCurrentOrganization(state),
  overallQuality: getCurrentYearOverallQuality(state),
  overallQualityReceivedAt: getOverallQualityReceivedAt(state),
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(DataQualityNotificationWrapper)));

