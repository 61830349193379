import debug from 'debug';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';

import getDateFormat from 'helpers/getDateFormat';
import numbersFormatting from 'helpers/numbersFormatting';

import initAxiosInstanse from '../../api/axios';
import { trackUserEvent } from '../analytics/actions';
import { showNotificationWithTimeout, showNotification } from '../notificationCenter/actions';

const log = debug('crops:harvest:actions');

const checkErrorType = (error, exprectedErrorClass) =>
  error?.response?.data && error?.response?.data?.errorClass === exprectedErrorClass;

const getErrorMessageParams = ({
  intl, error, exprectedErrorClass
}) => {
  const { formatMessage } = intl;
  if (!checkErrorType(error, exprectedErrorClass)) {
    return {};
  }
  const data = error?.response?.data?.data || {};
  const week = moment(data?.week?.start, 'YYYY-MM-DD').isoWeek();
  const startDate = moment(data?.week?.start, 'YYYY-MM-DD').format(getDateFormat('ll'));
  const endDate = moment(data?.week?.end, 'YYYY-MM-DD').format(getDateFormat('ll'));
  const dates = `${startDate} – ${endDate}`;
  return {
    maximumAllowedWeeklyHarvest: numbersFormatting(data?.maximumAllowedWeeklyHarvest),
    units: formatMessage({ id: `cunits.mini.${data?.units}` }),
    enteredWeeklyHarvest: numbersFormatting(data?.enteredWeeklyHarvest),
    week,
    dates,
  };
};

export const requestGetHarvestCategories = createAction('REQUEST_GET_HARVEST_CATEGORIES');
export const receiveGetHarvestCategories = createAction('RECEIVE_GET_HARVEST_CATEGORIES');
export const requestGetHarvestDataByCategories = createAction('REQUEST_GET_HARVEST_DATA_BY_CATEGORIES');
export const receiveGetHarvestDataByCategories = createAction('RECEIVE_GET_HARVEST_DATA_BY_CATEGORIES');
export const requestUpdateHarvestDataByCategories = createAction('REQUEST_UPDATE_HARVEST_DATA_BY_CATEGORIES');
export const receiveUpdateHarvestDataByCategories = createAction('RECEIVE_UPDATE_HARVEST_DATA_BY_CATEGORIES');
export const requestHarvestData = createAction('REQUEST_HARVEST_DATA');
export const receiveHarvestData = createAction('RECEIVE_HARVEST_DATA');
export const requestSaveNewHarvestData = createAction('REQUEST_SAVE_HARVEST_DATA');
export const receiveSaveNewHarvestData = createAction('RECEIVE_SAVE_HARVEST_DATA');
export const requestEditHarvestData = createAction('REQUEST_EDIT_HARVEST_DATA');
export const receiveEditHarvestData = createAction('RECEIVE_EDIT_HARVEST_DATA');
export const requestDeleteHarvestData = createAction('REQUEST_DELETE_HARVEST_DATA');
export const receiveDeleteHarvestData = createAction('RECEIVE_DELETE_HARVEST_DATA');
export const requestClearHarvestData = createAction('CLEAR_HARVEST_DATA');
export const requestClearHarvestDataByCategories = createAction('CLEAR_HARVEST_DATA_BY_CATEGORIES');

export function trackAddHarvestClick(btnSource) {
  const event = 'Planting Cycle - Click Add Harvest';
  const eventSource = btnSource === 'page' ? 'Planting Cycle Page' : 'Planting Cycles List';

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event, data: { Source: eventSource } }));
  };
}

export function trackAddHarvestSuccess(btnSource) {
  const event = 'Planting Cycle - Submit Add Harvest';
  const eventSource = btnSource === 'page' ? 'Planting Cycle Page' : 'Planting Cycles List';

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event, data: { Source: eventSource } }));
  };
}

export function getHarvestData(plantingCycleId) {
  return async (dispatch) => {
    dispatch(requestHarvestData());

    try {
      const response = await initAxiosInstanse()
        .get(`planting-cycles/${plantingCycleId}/harvest`);

      dispatch(receiveHarvestData(response.data));
    } catch (error) {
      dispatch(showNotificationWithTimeout({
        id: 'notifications.getHarvestDataError',
        messageId: 'notifications.getHarvestDataError',
        position: 'leftDown',
        iconType: 'error',
        notificationType: 'withActionWide',
      }));
    }
  };
}

export function getHarvestCategories() {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { location } = company;
    const { id } = location;

    dispatch(requestGetHarvestCategories());

    try {
      const response = await initAxiosInstanse()
        .get(`harvest-categories?locationId=${id}`);

      dispatch(receiveGetHarvestCategories(response.data));

      log(`Harvest enter form in ${get(response.data, 'enabledForLocation') ? 'categories' : 'total'} mode`);
    } catch (error) {
      dispatch(receiveGetHarvestCategories());
      dispatch(showNotificationWithTimeout({
        id: `notifications.getHarvestCategoriesError.${Date.now()}`,
        messageId: 'notifications.getHarvestCategoriesError',
        position: 'leftDown',
        iconType: 'error',
        notificationType: 'withActionWide',
      }));
    }
  };
}

export function getHarvestCategoriesDefault() {
  return async (dispatch, getState) => {
    const { company } = getState();
    const { location } = company;
    const { id } = location;

    dispatch(requestGetHarvestCategories());

    try {
      const response = await initAxiosInstanse()
        .get(`harvest-categories?locationId=${id}`);

      dispatch(receiveGetHarvestCategories(response.data));
    } catch (error) {
      dispatch(receiveGetHarvestCategories());
    }
  };
}

export function updateHarvestDataByCategories({
  plantingCycleId,
  data,
  fromPage,
  minDate,
  maxDate,
  btnSource,
  mode,
  intl,
}) {
  return async (dispatch, getState) => {
    const state = getState();
    const { harvest } = state;
    const enabledForLocation = get(harvest, 'harvestCategories.enabledForLocation');

    await dispatch(requestUpdateHarvestDataByCategories());

    try {
      let normalizedData = null;

      if (enabledForLocation) {
        normalizedData = {
          rows: data
            .filter(({ date }) => !moment(date, 'YYYY-MM-DD').isBefore(minDate) && !moment(date, 'YYYY-MM-DD').isAfter(maxDate))
            .map(({ date, values }) => ({
              date,
              values: values.map(({ discriminator, value }) => ({ discriminator, value }))
            })),
        };
      } else {
        normalizedData = data
          .filter(({ date }) => !moment(date, 'YYYY-MM-DD').isBefore(minDate) && !moment(date, 'YYYY-MM-DD').isAfter(maxDate))
          .reduce((reducer, { date, values }) => {
            const temp = reducer;

            if (values && values.length) {
              temp[date] = get(values, '0.value');
            }

            return temp;
          }, {});
      }


      const response = await initAxiosInstanse()
        .post(`planting-cycles/${plantingCycleId}/${enabledForLocation ? 'harvest-by-categories' : 'harvest-totals'}`, normalizedData);

      await dispatch(receiveUpdateHarvestDataByCategories(response.data));

      await dispatch(requestClearHarvestData());
      await dispatch(push(fromPage));

      if (mode === 'add') {
        await dispatch(trackAddHarvestSuccess(btnSource));
      }

      await dispatch(showNotificationWithTimeout({
        id: 'notifications.updateHarvestDataByCategoriesSuccess',
        messageId: 'notifications.updateHarvestDataByCategoriesSuccess',
        position: 'leftDown',
        iconType: 'success',
        notificationType: 'withActionWide',
      }));
    } catch (error) {
      await dispatch(receiveUpdateHarvestDataByCategories());

      await dispatch(showNotification({
        id: `notifications.updateHarvestDataByCategoriesError.${Date.now()}`,
        messageId: checkErrorType(error, 'UnrealisticHarvestException') ?
          'notifications.updateHarvestDataByCategoriesUnrealisticHarvestError' :
          'notifications.updateHarvestDataByCategoriesError',
        position: 'leftDown',
        iconType: 'error',
        notificationType: 'withActionWide',
        messageParams: getErrorMessageParams({
          intl,
          error,
          exprectedErrorClass: 'UnrealisticHarvestException'
        }),
        withLineBreaks: true,
      }));
    }
  };
}

export function clearHarvestData() {
  return async (dispatch) => {
    dispatch(requestClearHarvestData());
  };
}

export function clearHarvestDataByCategories() {
  return async (dispatch) => {
    dispatch(requestClearHarvestDataByCategories());
  };
}

export function saveNewHarvestData({
  plantingCycleId, value, date, btnSource
}) {
  return async (dispatch) => {
    dispatch(requestSaveNewHarvestData());

    try {
      const newHarvest = await initAxiosInstanse()
        .get(`planting-cycles/${plantingCycleId}/harvest/new-id`);

      await initAxiosInstanse()
        .post(`planting-cycles/${plantingCycleId}/harvest/${newHarvest.data.id}`, {
          date,
          value,
        });

      await dispatch(receiveSaveNewHarvestData());

      await dispatch(showNotificationWithTimeout({
        id: 'harvest.notifications.saved',
        iconType: 'success',
        notificationType: 'withActionWide',
      }));

      await dispatch(trackAddHarvestSuccess(btnSource));
    } catch (error) {
      await dispatch(showNotificationWithTimeout({
        id: 'harvest.notifications.addError',
        iconType: 'error',
        notificationType: 'withActionWide'
      }));

      throw new Error(error);
    }
  };
}

export function editHarvestData({
  plantingCycleId, harvestId, value, date, notificationSuccess, btnSource, editMode,
}) {
  return async (dispatch) => {
    dispatch(requestEditHarvestData());

    try {
      await initAxiosInstanse()
        .post(`planting-cycles/${plantingCycleId}/harvest/${harvestId}`, {
          date,
          value,
        });

      await dispatch(receiveEditHarvestData());

      await dispatch(showNotificationWithTimeout({
        id: notificationSuccess ? 'harvest.notifications.saved' : 'harvest.notifications.changed',
        iconType: 'success',
        notificationType: 'withActionWide',
      }));

      // Отправляем событие аналитики только если диалог добавления был открыт через кнопку добавления, а не редактирования
      if (!editMode) {
        await dispatch(trackAddHarvestSuccess(btnSource));
      }
    } catch (error) {
      await dispatch(showNotificationWithTimeout({
        id: notificationSuccess ? 'harvest.notifications.addError' : 'harvest.notifications.editError',
        iconType: 'error',
        notificationType: 'withActionWide'
      }));
      throw new Error(error);
    }
  };
}

export function deleteHarvestData({
  plantingCycleId, harvestId, withoutNotification
}) {
  return async (dispatch) => {
    dispatch(requestDeleteHarvestData());

    try {
      await initAxiosInstanse()
        .delete(`planting-cycles/${plantingCycleId}/harvest/${harvestId}`);

      await dispatch(receiveDeleteHarvestData());

      if (!withoutNotification) {
        await dispatch(showNotificationWithTimeout({
          id: 'harvest.notifications.deleted',
          iconType: 'success',
          notificationType: 'withActionWide',
        }));
      }
    } catch (error) {
      await dispatch(showNotificationWithTimeout({
        id: 'harvest.notifications.deleteError',
        iconType: 'error',
        notificationType: 'withActionWide',
      }));
      throw new Error(error);
    }
  };
}

export const setHasChangesTime = createAction('SET_HAS_CHANGES_TIME_HARVEST');
