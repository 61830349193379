import flatten from 'flat';
import localeEN from '../translations/en.yml';
import localeRU from '../translations/ru.yml';
import localeFR from '../translations/fr.yml';
import localeNL from '../translations/nl.yml';
import localeZH from '../translations/zh-cn.yml';

const messages = {
  en: flatten(localeEN),
  ru: flatten(localeRU),
  fr: flatten(localeFR),
  nl: flatten(localeNL),
  'zh-cn': flatten(localeZH),
};

export default messages;
