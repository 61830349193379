import React, { PureComponent } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { uniqueId } from 'lodash';

import Typography from 'components/Typography';
import LocationIcon from 'components/Icons/LocationIcon';
import Select from 'components/Select';
import tooltipStyles from 'components/Tooltip/index.module.css';
import isTouchDevice from 'helpers/isTouchDevice';
import SeriesEditorBlock from '../SeriesEditorBlock';
import EditableLineChart from '../EditableLineChart';
import styles from './SunLightEditorBlock.module.css';


const MODE = {
  GEOLOCATION: 'fromExternalProvider',
  CUSTOM: 'custom',
};

class SunLightEditorBlock extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    title: PropTypes.string,
    units: PropTypes.string,
    location: PropTypes.object.isRequired,
    fromExternalProvider: PropTypes.object,
    custom: PropTypes.object,
    startWeek: PropTypes.number,
    endWeek: PropTypes.number,
    onChangeLightType: PropTypes.func,
    correctionPercentage: PropTypes.number,
    entryType: PropTypes.string,
    onUpdateCustomLightPoints: PropTypes.func,
    onUpdateExternalLightPoints: PropTypes.func,
    getValuesMetrics: PropTypes.func.isRequired,
    sunAverage: PropTypes.number,
  };

  static defaultProps = {
    intl: null,
    title: null,
    fromExternalProvider: [],
    custom: [],
    onChangeLightType: null,
    onUpdateCustomLightPoints: null,
    onUpdateExternalLightPoints: null,
    sunAverage: 15,
    units: null,
    correctionPercentage: 1.0,
    startWeek: null,
    endWeek: null,
    entryType: null
  };

  state = {
    type: MODE.CUSTOM,
    showBulkEditor: false,
  };

  componentDidMount() {
    const {
      entryType,
    } = this.props;

    if (entryType) {
      this.setState(prevState => ({
        ...prevState,
        type: entryType,
      }));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      entryType,
    } = this.props;

    if (prevProps?.entryType !== entryType) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => ({
        ...prevState,
        type: entryType,
      }));
    }
  }

  getLightCorrectionMul = (value) => {
    const {
      correctionPercentage
    } = this.props;

    const currentCorrectionPercentage = value !== undefined ? value : correctionPercentage;

    let correctionMul = 1.0;

    if (currentCorrectionPercentage && currentCorrectionPercentage > 0) {
      correctionMul = 1.0 + currentCorrectionPercentage / 100;
    }

    if (currentCorrectionPercentage && currentCorrectionPercentage < 0) {
      correctionMul = 1.0 + currentCorrectionPercentage / 100;
    }

    return correctionMul;
  }

  convertPoints = (points) => {
    const datesKeys = Object.keys(points);
    return [
      ...datesKeys.map(key => ({
        key: Number(key),
        value: points[key],
      })),
    ];
  };

  getCorrectExternalPoints = (value) => {
    const {
      fromExternalProvider,
      correctionPercentage
    } = this.props;
    const correctMul = this.getLightCorrectionMul(value !== undefined ? value : correctionPercentage);
    return Object.keys(fromExternalProvider).reduce((memo, pKey) => {
      memo.push({
        key: pKey,
        value: fromExternalProvider[pKey] * correctMul
      });
      return memo;
    }, []);
  }

  handleSunRadiationAverageSelect = ({
    value
  }) => {
    const {
      onUpdateExternalLightPoints,
    } = this.props;
    if (onUpdateExternalLightPoints) {
      onUpdateExternalLightPoints(this.getCorrectExternalPoints(value), value);
    }
  };

  handleChangeLightType = (type) => {
    const {
      onChangeLightType
    } = this.props;
    this.setState(prevState => ({
      ...prevState,
      type,
    }));
    if (onChangeLightType) {
      onChangeLightType({
        entryType: type
      });
    }
  }

  buildGoogleMapsLink = location =>
    `https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`;

  handleClickGeo = (evt) => {
    const { location } = this.props;
    evt.preventDefault();
    const tab = window.open(this.buildGoogleMapsLink({
      ...location.attributes,
      zoom: 14
    }), '_blank');
    tab.focus();
  };

  renderGeolocationMode = () => {
    const {
      location, correctionPercentage, intl, sunAverage, units
    } = this.props;
    const { formatMessage } = intl;
    const options = [{
      value: sunAverage,
      label: `${formatMessage({
        id: 'cropModel.moreSun'
      })} (+${sunAverage}% ${formatMessage({ id: 'cropModel.toAvg' })})`,
    }, {
      value: 0,
      label: formatMessage({ id: 'cropModel.historicalAverage' }),
    }, {
      value: -sunAverage,
      label: `${formatMessage({
        id: 'cropModel.lessSun'
      })} (-${sunAverage}% ${formatMessage({ id: 'cropModel.toAvg' })})`,
    }];
    const unitsLocalized = units ? formatMessage({ id: `cunits.mini.${units}` }) : '';
    const tooltipId = `geo-tooltip-${uniqueId()}`;
    const locationTitle = location?.attributes?.shortAddress ||
      `@${location?.attributes?.latitude},${location?.attributes?.longitude}`;
    return (
      <div className={styles.geoModeContainer}>
        <div className={styles.selectLine}>
          <span>{formatMessage({ id: 'cropModel.greenhouseLocation' })}</span>
          <div
            role='button'
            tabIndex={0}
            className={styles.geolocation}
            onClick={this.handleClickGeo}
            data-tip={locationTitle}
            data-for={tooltipId}
          >
            <LocationIcon className={styles.icon} />
            <span className={styles.locationTitle}>{locationTitle}</span>
          </div>
        </div>
        <div className={styles.selectLine}>
          <span className={styles.selectLineText}>
            {formatMessage({ id: 'cropModel.sunRadiation' })}, {unitsLocalized}
          </span>
          <div className={styles.selectWrapper}>
            <Select
              classNameButton={styles.selectButton}
              classNamePopup={styles.selectPopup}
              placeholder={formatMessage({ id: 'cropModel.historicalAverage' })}
              options={options}
              value={correctionPercentage}
              onChange={this.handleSunRadiationAverageSelect}
              labelPath='label'
              valuePath='value'
              closeOnChange
              textEllipsis
            />
          </div>
        </div>
        <ReactTooltip
          className={tooltipStyles.smallTooltip}
          id={tooltipId}
          effect='solid'
          event={isTouchDevice() ? 'click' : null}
        />
      </div>
    );
  };

  handleUpdateCustomLight = (points) => {
    const {
      onUpdateCustomLightPoints
    } = this.props;
    if (onUpdateCustomLightPoints) {
      onUpdateCustomLightPoints(points);
    }
  };

  render() {
    const {
      title,
      startWeek,
      endWeek,
      intl,
      units,
      custom,
      getValuesMetrics
    } = this.props;
    const { formatMessage } = intl;
    const { type } = this.state;
    const editableComponentProps = {
      startWeek,
      endWeek,
      width: 358,
      height: 226,
      xTickPadding: 14,
      yTickPadding: 18,
      margin: {
        top: 28,
        right: 62,
        bottom: 28,
        left: 10,
      }
    };

    const valueMetrics = getValuesMetrics('sunLight', units);
    const fromExternalProvider = this.getCorrectExternalPoints();

    return (
      <div className={styles.editor}>
        <Typography className={styles.title} variant='subtitle1'>{title}</Typography>
        <div className={styles.toggleButtons}>
          <button
            type='button'
            className={classnames(styles.geo, {
              [styles.active]: type === MODE.GEOLOCATION,
            })}
            onClick={() => this.handleChangeLightType(MODE.GEOLOCATION)}
          >
            {formatMessage({ id: 'cropModel.geolocationLight' })}
          </button>
          <button
            type='button'
            className={classnames(styles.custom, {
              [styles.active]: type === MODE.CUSTOM,
            })}
            onClick={() => this.handleChangeLightType(MODE.CUSTOM)}
          >
            {formatMessage({ id: 'cropModel.customLight' })}
          </button>
        </div>
        {type === MODE.GEOLOCATION ? (
          <div className={styles.sunLightContainer}>
            <EditableLineChart
              key='geolocation'
              {...editableComponentProps}
              points={fromExternalProvider}
              maxValue={valueMetrics.max}
              minValue={valueMetrics.min}
              round={valueMetrics.round}
              editable={false}
              initialReferencePoints={[]}
              label='Sun data NASA'
              valueMetrics={valueMetrics}
            />
          </div>
        ) : (
          <SeriesEditorBlock
            startWeek={startWeek}
            valueMetrics={valueMetrics}
            endWeek={endWeek}
            points={custom}
            onValuesChanged={this.handleUpdateCustomLight}
            title={formatMessage({ id: 'cropModel.sunRadiation' })}
            units={units}
            withOutTitle
          />
        )}
        {type === MODE.GEOLOCATION && this.renderGeolocationMode()}
      </div>
    );
  }
}

export default injectIntl(SunLightEditorBlock);
