import { connect } from 'react-redux';
import { getHarvestForecastAccess, getDigitalTwinAccess } from 'store/company/selectors';

import DigitalTwinNavigation from './DigitalTwinNavigation';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,

  digitalTwinAccess: getDigitalTwinAccess(state),
  harvestForecastAccess: getHarvestForecastAccess(state, props),
});

export default connect(mapStateToProps, mapDispatchToProps)(DigitalTwinNavigation);
