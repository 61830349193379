import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { get, capitalize } from 'lodash';
import classnames from 'classnames';

import numbersRounding from 'helpers/numbersRounding';
import normalizeCycle from 'helpers/normalizeCycle';
import { getProductTypeName } from 'helpers/getVarietyName';

import Harvest from 'components/Harvest';
import Climate from 'components/Climate';
import Measurements from 'components/Measurements';
import Photos from 'components/Photos';
import UnrealizedPotential from 'components/UnrealizedPotential';
import CropReportsList from 'components/CropReportsList';

import CircleLoader from 'components/CircleLoader';
import CycleCard from 'components/CycleCard';
import NotFound from 'components/NotFound';
import PageViewTracker from 'components/PageViewTracker';
import EmptyState from 'components/EmptyState';
import BackButton from 'components/BackButton';

import CycleHarvest from '../CycleHarvest';

import circleLoaderStyles from '../../../CircleLoader/CircleLoader.module.css';
import styles from './Cycle.module.css';

const renderTabList = (organizationSlug, tabs, tabName, cycleId) => tabs.map(tab => (
  <RouterLink
    key={`tab-${tab.value}`}
    className={classnames(styles.tab, {
      [styles.activeTab]: tab.value === tabName
    })}
    to={`/${organizationSlug}/crops/${cycleId}/${tab.value}`}
  >
    {tab.name}
  </RouterLink>
));

const renderTabContent = ({
  id,
  cycleInfo,
  tabName,
  defaultTab,
  intl,
  organizationSlug,
  requestCropsExport
}) => {
  const { formatMessage } = intl;
  const { cycle: { startDate } } = cycleInfo;

  const now = moment();
  const startDateMoment = moment(startDate, 'YYYY-MM-DD');
  const isNotStarted = startDateMoment.isAfter(now);

  if (isNotStarted) {
    return (
      <>
        <EmptyState
          header={formatMessage({ id: 'plantingCycles.notStartedYet' })}
          text={(
            <span>
              {formatMessage({ id: 'plantingCycles.asSoonAsSeedingDate' })} <RouterLink className={styles.emptyButton} to={`/${organizationSlug}/crops/${id}/edit?fromPage=crop`}>{formatMessage({ id: 'plantingCycles.changeSeedingDate' })}</RouterLink>.
            </span>
          )}
          intl={intl}
        />
      </>
    );
  }

  switch (tabName) {
    case 'harvest':
      return <Harvest cycleId={id} defaultTab={defaultTab} />;
    case 'climate':
      return (
        <Climate
          cycleId={id}
          defaultTab={defaultTab}
          isEnableXSLSExportButton
          onClickExportButton={() => {
            requestCropsExport({ plantingCycleId: id });
          }}
        />
      );
    case 'photos':
      return <Photos cycleId={id} defaultTab={defaultTab} />;
    case 'measurements':
      return <Measurements cycleId={id} defaultTab={defaultTab} />;
    case 'potential':
      return <UnrealizedPotential cycleId={id} defaultTab={defaultTab} />;
    case 'reports':
        return <CropReportsList cycleId={id} defaultTab={defaultTab} cycleInfo={cycleInfo} />;
    default:
      return '';
  }
};

const renderCurrentCycle = ({
  intl,
  tabName,
  trackEditPlantingCycleClick,
  match,
  isAddPlantingCycleAvailable,
  requestDeletePlantingCycle,
  unrealizedPotentialAccess,
  cyclesToCompare,
  removeCycleFromComparison,
  addCycleToComparison,
  isCropCompareAvailable,
  currentCycle,
  cropReportsAccess,
  id,
  cycleInfo,
  defaultTab,
  organizationSlug,
  requestCropsExport
}) => {
  if (!currentCycle) {
    return null;
  }

  const { formatMessage, locale } = intl;

  const DEFAULT_TABS = [
    { name: formatMessage({ id: 'cycle.harvest' }), value: 'harvest' },
    { name: formatMessage({ id: 'cycle.climate' }), value: 'climate' },
    { name: formatMessage({ id: 'cycle.photos' }), value: 'photos' },
    { name: formatMessage({ id: 'cycle.measurements' }), value: 'measurements' },
  ];

  const additionalTabs = unrealizedPotentialAccess ? [{ name: formatMessage({ id: 'cycle.unrealized' }), value: 'potential' }] : [];
  const reportsTab = cropReportsAccess ? [{ name: formatMessage({ id: 'reports.reports' }), value: 'reports' }] : [];

  const TABS = [
    ...DEFAULT_TABS,
    ...additionalTabs,
    ...reportsTab,
  ];

  // TODO: Вынести получение этих параметров в какую-нибудь денормализацию
  const cycle = normalizeCycle(currentCycle, locale);

  const {
    id: cycleId, species, compartmentName,
    cycleWeek, harvestValue, totalGoalValue, startDate, endDate,
    valueUnits, totalGoalUnits, activeStatus
  } = cycle;

  const fullName = getProductTypeName({
    intl,
    variety: get(currentCycle, 'variety'),
    fruitClass: get(currentCycle, 'fruitClass'),
    targetWeight: get(currentCycle, 'cycle.targetWeight'),
    withSpecies: true,
  });
  const valueUnitsText = valueUnits ? formatMessage({ id: `cunits.mini.${valueUnits}` }) : '';
  const totalGoalUnitsText = totalGoalUnits ? formatMessage({ id: `cunits.mini.${totalGoalUnits}` }) : '';
  const harvestPlan = harvestValue && totalGoalValue ? numbersRounding(harvestValue / (totalGoalValue / 100), 'percentage') : undefined;

  const now = moment();
  const startDateMoment = moment(currentCycle.cycle.startDate, 'YYYY-MM-DD');
  const isNotSpecified = !currentCycle.cycle.endDate && startDateMoment.isAfter(now);

  const isLettuce = species === 'lettuce';

  return (
    <div className={styles.cycle}>
      <div className={styles.backButton}>
        <BackButton
          link={`/${match.params.organizationSlug}/crops`}
          text={formatMessage({ id: 'cycle.plantingCycles' })}
        />
      </div>
      <div className={styles.header}>
        <CycleCard
          intl={intl}
          cycleId={cycleId}
          fullName={fullName}
          species={species}
          startDate={startDate || undefined}
          endDate={endDate || undefined}
          compartmentName={compartmentName}
          completed={activeStatus === 'complete'}
          isNotSpecified={isNotSpecified}
          size='big'
          trackEditPlantingCycleClick={trackEditPlantingCycleClick}
          isAddPlantingCycleAvailable={isAddPlantingCycleAvailable}
          requestDeletePlantingCycle={requestDeletePlantingCycle}
          cyclesToCompare={cyclesToCompare}
          addCycleToComparison={addCycleToComparison}
          removeCycleFromComparison={removeCycleFromComparison}
          isCropCompareAvailable={isCropCompareAvailable}
        />
        <CycleHarvest
          harvestValue={harvestValue}
          valueUnitsText={valueUnitsText}
          cycleWeek={cycleWeek}
          totalGoalValue={totalGoalValue}
          totalGoalUnitsText={totalGoalUnitsText}
          harvestPlan={harvestPlan}
          isLettuce={isLettuce}
        />
      </div>
      <div className={classnames(styles.tabs, { [styles.reportsTabs]: tabName === 'reports' })}>
        {renderTabList(organizationSlug, TABS, tabName, cycleId)}
      </div>
      <div className={classnames(styles.tabsContent, { [styles.reportsTabsContent]: tabName === 'reports' })}>
        {renderTabContent({
            id,
            cycleInfo,
            tabName,
            defaultTab,
            intl,
            organizationSlug,
            requestCropsExport
        })}
      </div>
    </div>
  );
};

const Cycle = ({
  intl,
  getCycleData,
  clearHarvestData,
  id,
  tabName, cycleInfo, isCycleFetching,
  trackEditPlantingCycleClick,
  match,
  isAddPlantingCycleAvailable,
  requestDeletePlantingCycle,
  unrealizedPotentialAccess,
  cropReportsAccess,
  cyclesToCompare,
  removeCycleFromComparison,
  addCycleToComparison,
  isCropCompareAvailable,
  trackPageViewPlantingCycle,
  defaultTab,
  requestCropsExport
}) => {
  const organizationSlug = match?.params?.organizationSlug;

  useEffect(() => {
    getCycleData(id);

    return () => {
      clearHarvestData();
    };
  }, [getCycleData, clearHarvestData, id]);

  const handlerPageView = useCallback(() => {
    trackPageViewPlantingCycle(capitalize(tabName), defaultTab);
  }, [trackPageViewPlantingCycle, tabName, defaultTab]);

  if (!cycleInfo && !isCycleFetching) {
    return <NotFound />;
  }

  return (
    <div className={styles.layout}>
      {/* key нужен для ремаунта компонента, чтобы срабатывала аналитика при изменении таба */}
      <PageViewTracker onMount={handlerPageView} key={`tabName-${tabName}`} />

      {isCycleFetching || !cycleInfo ? (
        <CircleLoader
          className={circleLoaderStyles.circleLoader}
          iconClassName={circleLoaderStyles.circleLoaderIcon}
        />
      )
        : (
          <div className={styles.content}>
            {cycleInfo && renderCurrentCycle({
                intl,
                tabName,
                trackEditPlantingCycleClick,
                match,
                isAddPlantingCycleAvailable,
                requestDeletePlantingCycle,
                unrealizedPotentialAccess,
                cyclesToCompare,
                removeCycleFromComparison,
                addCycleToComparison,
                isCropCompareAvailable,
                currentCycle: cycleInfo,
                cropReportsAccess,
                id,
                cycleInfo,
                defaultTab,
                organizationSlug,
                requestCropsExport
            })}
          </div>
        )}
    </div>
  );
};

Cycle.propTypes = {
  tabName: PropTypes.string,
  cycleInfo: PropTypes.object,
  defaultTab: PropTypes.bool,
  intl: intlShape.isRequired,
  id: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  getCycleData: PropTypes.func.isRequired,
  isCycleFetching: PropTypes.bool.isRequired,
  isAddPlantingCycleAvailable: PropTypes.bool.isRequired,
  clearHarvestData: PropTypes.func.isRequired,
  trackPageViewPlantingCycle: PropTypes.func.isRequired,
  trackEditPlantingCycleClick: PropTypes.func.isRequired,
  requestDeletePlantingCycle: PropTypes.func.isRequired,
  unrealizedPotentialAccess: PropTypes.bool.isRequired,
  cyclesToCompare: PropTypes.array,
  addCycleToComparison: PropTypes.func.isRequired,
  removeCycleFromComparison: PropTypes.func.isRequired,
  isCropCompareAvailable: PropTypes.bool,
  cropReportsAccess: PropTypes.bool,
  requestCropsExport: PropTypes.bool
};

Cycle.defaultProps = {
  tabName: 'harvest',
  cycleInfo: null,
  defaultTab: false,
  cyclesToCompare: [],
  isCropCompareAvailable: false,
  cropReportsAccess: false,
  requestCropsExport: null
};

export default injectIntl(Cycle);
