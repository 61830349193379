import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  getYearFromRoute,
  getIsAddOperationalPlansAvailable,
  getIsAddBudgetPlansAvailable,
  getOrganization,
  getPeriodTypeFromRoute,
  getAnyDateOfPeriodStartFromRoute,
  getAnyDateOfPeriodEndFromRoute,
  getCompartmentIdsFromRoute,
  getCurrentCompartments,
  getVarietyIdFromRoute,
  getFruitClassFromRoute,
  getSpeciesFromRoute,
  getProductGroupFromRoute,
  getAllFruitClasses,
  getVarietiesInLocationWithTargetWeight,
} from 'store/company/selectors';

import {
  getHarvestPlan,
  getHarvestPlanEdited,
  getPlansEditorSaving,
  getWithoutSaveDate,
  getIsNotPublishedVersionPlate,
  getIsLastVersion,
  getIsOperationalPlan,
  getPlanType,
} from 'store/plansEditor/selectors';

import { showNotificationWithTimeout } from 'store/notificationCenter/actions';

import {
  resetPlansEditorData,
  requestPublishPlanById,
  requestSaveAndPublishPlan,
  requestSavePlan,

  requestAllPlanVersions,
  requestDefaultPlanVersion,
  requestRestorePlan,
  requestSaveVersionInfo,
  requestDeleteVersionInfo,
  requestPlanVersionById,
} from 'store/plansEditor/actions';

import PlansEditor from '../components/PlansEditor';

const mapDispatchToProps = {
  resetPlansEditorData,
  requestPublishPlanById,
  requestSaveAndPublishPlan,
  requestSavePlan,
  showNotificationWithTimeout,

  requestAllPlanVersions,
  requestDefaultPlanVersion,
  requestRestorePlan,
  requestSaveVersionInfo,
  requestDeleteVersionInfo,
  requestPlanVersionById,
};

const mapStateToProps = (state, props) => ({
  ...props,

  year: getYearFromRoute(state, props),
  harvestPlan: getHarvestPlan(state, props),
  harvestPlanEdited: getHarvestPlanEdited(state, props),
  isPlansEditorSaving: getPlansEditorSaving(state, props),
  withoutSaveDate: getWithoutSaveDate(state, props),
  isAddOperationalPlansAvailable: getIsAddOperationalPlansAvailable(state),
  isAddBudgetPlansAvailable: getIsAddBudgetPlansAvailable(state),
  isNotPublishedVersionPlate: getIsNotPublishedVersionPlate(state),
  isLastVersion: getIsLastVersion(state),
  organization: getOrganization(state, props),
  isOperationalPlan: getIsOperationalPlan(state, props),
  planType: getPlanType(state, props),

  periodType: getPeriodTypeFromRoute(state, props),
  anyDateOfPeriodStart: getAnyDateOfPeriodStartFromRoute(state, props),
  anyDateOfPeriodEnd: getAnyDateOfPeriodEndFromRoute(state, props),

  compartmentId: getCompartmentIdsFromRoute(state, props),
  compartments: getCurrentCompartments(state, props),

  currentGrownFilter: getVarietyIdFromRoute(state, props) || getFruitClassFromRoute(state, props) || getSpeciesFromRoute(state, props),
  species: getSpeciesFromRoute(state, props),
  productGroup: getProductGroupFromRoute(state, props),
  varietyId: getVarietyIdFromRoute(state, props),
  fruitClassCode: getFruitClassFromRoute(state, props),
  varieties: getVarietiesInLocationWithTargetWeight(state, props),
  fruitClasses: getAllFruitClasses(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(PlansEditor)));
