import 'whatwg-fetch';
import { createAction } from 'redux-actions';

import { trackUserEvent } from '../analytics/actions';

// ------------------------------------
// Constants
// ------------------------------------
export const GET_PLANTING_CYCLE_STARTED = 'GET_PLANTING_CYCLE_STARTED';
export const GET_PLANTING_CYCLE_SUCCEEDED = 'GET_PLANTING_CYCLE_SUCCEEDED';
export const GET_PLANTING_CYCLE_FAILED = 'GET_PLANTING_CYCLE_FAILED';
export const GET_PLANTING_CYCLE_ENDED = 'GET_PLANTING_CYCLE_ENDED';

export const CREATE_PLANTING_CYCLE_STARTED = 'CREATE_PLANTING_CYCLE_STARTED';
export const CREATE_PLANTING_CYCLE_SUCCEEDED = 'CREATE_PLANTING_CYCLE_SUCCEEDED';
export const CREATE_PLANTING_CYCLE_FAILED = 'CREATE_PLANTING_CYCLE_FAILED';
export const CREATE_PLANTING_CYCLE_ENDED = 'CREATE_PLANTING_CYCLE_ENDED';

export const UPDATE_PLANTING_CYCLE_STARTED = 'UPDATE_PLANTING_CYCLE_STARTED';
export const UPDATE_PLANTING_CYCLE_SUCCEEDED = 'UPDATE_PLANTING_CYCLE_SUCCEEDED';
export const UPDATE_PLANTING_CYCLE_FAILED = 'UPDATE_PLANTING_CYCLE_FAILED';
export const UPDATE_PLANTING_CYCLE_ENDED = 'UPDATE_PLANTING_CYCLE_ENDED';

export const requestDeletePlantingCycle = createAction('REQUEST_DELETE_PLANTING_CYCLE');
export const receiveDeletePlantingCycle = createAction('RECEIVE_DELETE_PLANTING_CYCLE');

export const GET_CROPS_METRICS_STARTED = 'GET_CROPS_METRICS_STARTED';
export const GET_CROPS_METRICS_SUCCEEDED = 'GET_CROPS_METRICS_SUCCEEDED';
export const GET_CROPS_METRICS_FAILED = 'GET_CROPS_METRICS_FAILED';
export const GET_CROPS_METRICS_ENDED = 'GET_CROPS_METRICS_ENDED';


export const CLEAR_CROPS_OBSERVATIONS = 'CLEAR_CROPS_OBSERVATIONS';
export const RESET_CROPS_OBSERVATIONS = 'RESET_CROPS_OBSERVATIONS';

export const GET_CROPS_OBSERVATIONS_STARTED = 'GET_CROPS_OBSERVATIONS_STARTED';
export const GET_CROPS_OBSERVATIONS_SUCCEEDED = 'GET_CROPS_OBSERVATIONS_SUCCEEDED';
export const GET_CROPS_OBSERVATIONS_FAILED = 'GET_CROPS_OBSERVATIONS_FAILED';
export const GET_CROPS_OBSERVATIONS_ENDED = 'GET_CROPS_OBSERVATIONS_ENDED';

export const CREATE_CROPS_OBSERVATIONS_STARTED = 'CREATE_CROPS_OBSERVATIONS_STARTED';
export const CREATE_CROPS_OBSERVATIONS_SUCCEEDED = 'CREATE_CROPS_OBSERVATIONS_SUCCEEDED';
export const CREATE_CROPS_OBSERVATIONS_FAILED = 'CREATE_CROPS_OBSERVATIONS_FAILED';
export const CREATE_CROPS_OBSERVATIONS_ENDED = 'CREATE_CROPS_OBSERVATIONS_ENDED';


// ------------------------------------
// Actions
// ------------------------------------

/*  This is a thunk, meaning it is a function that immediately
 returns a function for lazy evaluation. It is incredibly useful for
 creating async actions, especially when combined with redux-thunk! */

export function getPlantingCycleSucceeded(plantingCycle) {
  return {
    type: GET_PLANTING_CYCLE_SUCCEEDED,
    timestamp: Date.now(),
    plantingCycle,
  };
}

export function getPlantingCycleStart({ id }) {
  return {
    type: GET_PLANTING_CYCLE_STARTED,
    id,
  };
}

export function getPlantingCycleFailed({ error }) {
  return {
    type: GET_PLANTING_CYCLE_FAILED,
    error,
    timestamp: Date.now(),
  };
}

export function getPlantingCycleEnded() {
  return {
    type: GET_PLANTING_CYCLE_ENDED,
  };
}

export function createPlantingCycleSucceeded(plantingCycle, match) {
  return {
    type: CREATE_PLANTING_CYCLE_SUCCEEDED,
    timestamp: Date.now(),
    plantingCycle,
    match,
  };
}

export function createPlantingCycleStart(data, match) {
  return {
    type: CREATE_PLANTING_CYCLE_STARTED,
    data,
    match,
  };
}

export function createPlantingCycleFailed({ error }) {
  return {
    type: CREATE_PLANTING_CYCLE_FAILED,
    error,
    timestamp: Date.now(),
  };
}

export function createPlantingCycleEnded(plantingCycle, match) {
  return {
    type: CREATE_PLANTING_CYCLE_ENDED,
    plantingCycle,
    match,
  };
}

export function updatePlantingCycleSucceeded(plantingCycle, btnSource, history, match) {
  return {
    type: UPDATE_PLANTING_CYCLE_SUCCEEDED,
    timestamp: Date.now(),
    plantingCycle,
    btnSource,
    history,
    match,
  };
}

export function updatePlantingCycleStart({
  data, id, btnSource, history, match
}) {
  return {
    type: UPDATE_PLANTING_CYCLE_STARTED,
    data,
    id,
    btnSource,
    history,
    match,
  };
}

export function updatePlantingCycleFailed({ error }) {
  return {
    type: UPDATE_PLANTING_CYCLE_FAILED,
    error,
    timestamp: Date.now(),
  };
}

export function updatePlantingCycleEnded(plantingCycle, btnSource, history, match) {
  return {
    type: UPDATE_PLANTING_CYCLE_ENDED,
    plantingCycle,
    btnSource,
    history,
    match,
  };
}

export function getCropsMetricsSucceeded(cropsMetrics) {
  return {
    type: GET_CROPS_METRICS_SUCCEEDED,
    cropsMetrics,
    timestamp: Date.now()
  };
}

export function getCropsMetricsStart({ id }) {
  return {
    type: GET_CROPS_METRICS_STARTED,
    id,
  };
}

export function getCropsMetricsFailed({ error }) {
  return {
    type: GET_CROPS_METRICS_FAILED,
    error,
    timestamp: Date.now(),
  };
}

export function getCropsMetricsEnded() {
  return {
    type: GET_CROPS_METRICS_ENDED,
  };
}


export function getCropsObservationsSucceeded(cropsObservations, date) {
  return {
    type: GET_CROPS_OBSERVATIONS_SUCCEEDED,
    cropsObservations,
    cropsObservationsDate: date,
    timestamp: Date.now()
  };
}

export function getCropsObservationsStart({ id, date }) {
  return {
    type: GET_CROPS_OBSERVATIONS_STARTED,
    date,
    id,
  };
}

export function getCropsObservationsFailed({ error }) {
  return {
    type: GET_CROPS_OBSERVATIONS_FAILED,
    error,
    timestamp: Date.now(),
  };
}

export function getCropsObservationsEnded() {
  return {
    type: GET_CROPS_OBSERVATIONS_ENDED,
  };
}


export function createCropsObservationsSucceeded(cropsObservation, location, withTrackEvent = false) {
  return {
    type: CREATE_CROPS_OBSERVATIONS_SUCCEEDED,
    cropsObservation,
    location,
    timestamp: Date.now(),
    withTrackEvent,
  };
}

export function createCropsObservationsStart({
  plantingCycle,
  date,
  sampleNumber,
  observations,
  withTrackEvent,
}) {
  return {
    type: CREATE_CROPS_OBSERVATIONS_STARTED,
    plantingCycle,
    date,
    sampleNumber,
    observations,
    withTrackEvent,
  };
}

export function createCropsObservationsFailed({ error }) {
  return {
    type: CREATE_CROPS_OBSERVATIONS_FAILED,
    error,
    timestamp: Date.now(),
  };
}

export function createCropsObservationsEnded() {
  return {
    type: CREATE_CROPS_OBSERVATIONS_ENDED,
  };
}


export function resetCropsObservations() {
  return {
    type: RESET_CROPS_OBSERVATIONS,
  };
}

export function clearCropsObservations() {
  return {
    type: CLEAR_CROPS_OBSERVATIONS,
  };
}

export function trackCreatePlantingCycleClick() {
  const event = 'Planting Cycles - Click Create Cycle';

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event }));
  };
}

export function trackCreatePlantingCycleSuccess() {
  const event = 'Planting Cycles - Submit Create Cycle';

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event }));
  };
}

export function trackEditPlantingCycleClick(btnSource) {
  const event = 'Planting Cycle - Click Edit Cycle';
  const eventSource = btnSource === 'page' ? 'Planting Cycle Page' : 'Planting Cycles List';

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event, data: { Source: eventSource } }));
  };
}

export function trackEditPlantingCycleSuccess(btnSource) {
  const event = 'Planting Cycle - Submit Edit Cycle';
  const eventSource = btnSource === 'page' ? 'Planting Cycle Page' : 'Planting Cycles List';

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event, data: { Source: eventSource } }));
  };
}

export function trackClickMeasurementsTableDay(status) {
  const event = `Planting Cycle - ${status === 'close' ? 'Close' : 'Open'} Measurements Table Day`;

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event }));
  };
}

export function trackAddMeasurementClick() {
  const event = 'Planting Cycle - Click Add Measurements';

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event }));
  };
}

export function trackAddMeasurementSuccess() {
  const event = 'Planting Cycle - Submit Add Measurements';

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event }));
  };
}
