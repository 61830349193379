import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getCropReportsAccess,
} from 'store/company/selectors';

import { requestCropReportsList } from 'store/cropReports/actions';
import { getCropReportsList, getIsReportsListFetching } from 'store/cropReports/selectors';

import CropReportsList from '../components/CropReportsList';

const mapDispatchToProps = {
  requestCropReportsList,
};

const mapStateToProps = (state, props) => ({
  ...props,

  cropReportsAccess: getCropReportsAccess(state, props),
  reportsList: getCropReportsList(state, props),
  isFetching: getIsReportsListFetching(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CropReportsList));
