import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  requestResetPassword,
  requestValidateToken,
} from '../../../store/login/actions';

import {
  getJwtFromRoute,
  getTokenValidation,
  getIsResetPasswordFetching,
  getIsTokenValidityFetching,
} from '../../../store/login/selectors';

import LoginNewPassword from '../components/LoginNewPassword';

const mapDispatchToProps = {
  requestResetPassword,
  requestValidateToken,
};

const mapStateToProps = (state, props) => ({
  ...props,

  jwt: getJwtFromRoute(state, props),
  tokenValidation: getTokenValidation(state, props),
  isResetPasswordFetching: getIsResetPasswordFetching(state, props),
  isTokenValidityFetching: getIsTokenValidityFetching(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LoginNewPassword));
