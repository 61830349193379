import { get } from 'lodash';

/**
 * Делает сортировку через localeCompare по полю объекта
 *
 * @param {Object[]} array - Массив объектов.
 * @param {string} path - Путь к полю, по которому сортируем.
 * @return {Object[]} Отсортированный массив объектов.
 */
const sortByLocal = (array, path) => array.sort((a, b) => {
  if (!get(a, path)) {
    return b;
  }

  return get(a, path).localeCompare(get(b, path), undefined, { numeric: true, sensitivity: 'base' });
});

export default sortByLocal;
