import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  groupBy, first, get
} from 'lodash';
import React, { Component } from 'react';
import { intlShape, injectIntl } from 'react-intl';

import Typography from '../Typography';
import Checkbox from '../Checkbox';
import BigButton from '../BigButton';

import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as SearchIcon } from './assets/search.svg';
import { ReactComponent as LoaderIcon } from './assets/loader.svg';

import styles from './DefaultSearchList.module.css';

class DefaultSearchList extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    controlClassName: PropTypes.string,
    classNameOption: PropTypes.string,
    groupNameClassName: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    empty: PropTypes.string,
    isFetching: PropTypes.bool,
    selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
        groupId: PropTypes.string,
        groupName: PropTypes.string,
        leadIcon: PropTypes.oneOfType([
          PropTypes.object,
          PropTypes.element,
          PropTypes.node,
        ]),
        icon: PropTypes.oneOfType([
          PropTypes.object,
          PropTypes.element,
          PropTypes.node,
        ]),
      })
    ),
    onChanged: PropTypes.func,
    onSelected: PropTypes.func,
    withCheckBox: PropTypes.bool,
    whiteSpaceOptions: PropTypes.string,
    getSortedOptions: PropTypes.func,
    noClearOnSelect: PropTypes.bool,
    showActiveState: PropTypes.bool,
    scrollToSelected: PropTypes.bool
  };

  static defaultProps = {
    className: null,
    contentClassName: null,
    controlClassName: null,
    groupNameClassName: null,
    classNameOption: '',
    value: '',
    placeholder: null,
    empty: null,
    isFetching: false,
    selectedOption: null,
    options: null,
    onChanged: null,
    onSelected: null,
    withCheckBox: false,
    whiteSpaceOptions: 'nowrap',
    getSortedOptions: undefined,
    showActiveState: true,
    noClearOnSelect: false,
    scrollToSelected: true
  };

  state = {
    hoveredOption: null,
  };

  inputRef = React.createRef();

  optionRef = React.createRef();

  selectedOptionRef = React.createRef();

  componentDidMount() {
    const {
      scrollToSelected
    } = this.props;
    if (this.selectedOptionRef?.current && scrollToSelected) {
      this.selectedOptionRef.current.scrollIntoView({ block: 'end', inline: 'nearest' });
    }
  }

  handlerSelected = (option, isCurrentSelected) => {
    const {
      onSelected, onChanged, options, noClearOnSelect
    } = this.props;

    if (onSelected) {
      onSelected({ option, options, unCheck: isCurrentSelected });
    }

    if (onChanged && !noClearOnSelect) {
      onChanged({ value: '' });
    }
  };

  handlerChanged = (e) => {
    const { onChanged } = this.props;

    if (onChanged) {
      onChanged({ value: e ? e.target.value : '' });
    }
  };

  handlerOnlyClick = (e, option) => {
    const { onSelected, onChanged, options } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (onSelected) {
      onSelected({
        option,
        options,
        onlyCheck: true
      });
    }

    if (onChanged) {
      onChanged({ value: '' });
    }
  };

  render() {
    const {
      value,
      placeholder,
      isFetching,
      selectedOption,
      options,
      empty,
      className,
      contentClassName,
      controlClassName,
      groupNameClassName,
      intl,
      withCheckBox,
      whiteSpaceOptions,
      classNameOption,
      getSortedOptions,
      showActiveState
    } = this.props;

    const { hoveredOption } = this.state;

    const { formatMessage } = intl;

    const filteredOptions =
      options && options.length
        ? options.filter((option) => {
          const { name } = option;

          if (!value) {
            return true;
          }

          const processedWords = value ? value.split(' ') : [];

          return name.split(' ').some((titleChunk) => {
            const processedChunk = titleChunk;

            return processedWords.some((processedWord) => {
              const boldPosition = titleChunk
                .toLowerCase()
                .indexOf(processedWord.toLowerCase());

              return (
                boldPosition >= 0 &&
                processedChunk
                  .toLowerCase()
                  .localeCompare(titleChunk.toLowerCase(), undefined, {
                    numeric: true,
                    sensitivity: 'base',
                  }) === 0
              );
            });
          });
        })
        : [];

    const groupedOptions = groupBy(filteredOptions, 'groupId');
    const groupedOptionsArray = Object.keys(groupedOptions).map(key => ({
      groupId: key,
      optionsArray: groupedOptions[key],
    }));
    const sortedGroupedOptions = getSortedOptions ? getSortedOptions(groupedOptionsArray) : groupedOptionsArray;

    return (
      <div className={classnames(styles.layout, className)}>
        <div className={classnames(styles.control, controlClassName)}>
          <button
            tabIndex={0}
            type='button'
            className={classnames(styles.button, styles.left)}
            onClick={() => {
              if (this.inputRef) {
                this.inputRef.current.focus();
              }
            }}
          >
            <SearchIcon />
          </button>
          <input
            type='text'
            value={value}
            ref={this.inputRef}
            placeholder={placeholder}
            className={styles.input}
            onChange={this.handlerChanged}
          />
          {value && (
            <button
              tabIndex={0}
              type='button'
              className={classnames(styles.button, styles.right)}
              onClick={() => {
                setTimeout(() => {
                  this.handlerChanged(null);
                  if (this.inputRef) {
                    this.inputRef.current.focus();
                  }
                }, 0);
              }}
            >
              <CloseIcon />
            </button>
          )}
        </div>

        <div
          className={classnames(styles.content, contentClassName, {
            [styles.loading]: isFetching,
          })}
        >
          {isFetching && (
            <div className={classnames(styles.loader)}>
              <div className={classnames(styles.icon, styles.loadable)}>
                <LoaderIcon />
              </div>
            </div>
          )}

          {!isFetching && (!filteredOptions || !filteredOptions.length) && (
            <div className={styles.emptyOption}>
              {empty}
            </div>
          )}

          {!isFetching && filteredOptions && filteredOptions.length ? (
            <div className={styles.options}>
              {!isFetching && filteredOptions.length
                ? sortedGroupedOptions.map((groupItem) => {
                  const { optionsArray, groupId } = groupItem;
                  const firstGroupOption = get(groupedOptions, groupId);
                  const groupName = get(first(firstGroupOption), 'groupName');

                    const renderedGroup = groupName && (
                      <Typography
                        className={classnames(styles.group, styles.padding, groupNameClassName)}
                        bold
                        variant='subtitle2'
                      >
                        {groupName}
                      </Typography>
                    );

                  const renderedOptions = optionsArray.map((option) => {
                    const { id, name } = option;

                    const processedWords = value ? value.split(' ') : [];

                    const processedTitle = name
                      .split(' ')
                      .map((titleChunk) => {
                        let processedChunk = titleChunk;

                        processedWords.forEach((processedWord) => {
                          const boldPosition = titleChunk
                            .toLowerCase()
                            .indexOf(processedWord.toLowerCase());

                          if (
                            boldPosition >= 0 &&
                            processedChunk
                              .toLowerCase()
                              .localeCompare(
                                titleChunk.toLowerCase(),
                                undefined,
                                {
                                  numeric: true,
                                  sensitivity: 'base',
                                }
                              ) === 0
                          ) {
                            processedChunk = [
                              titleChunk.slice(0, boldPosition),
                              '<strong>',
                              titleChunk.slice(
                                boldPosition,
                                boldPosition + processedWord.length
                              ),
                              '</strong>',
                              titleChunk.slice(
                                boldPosition + processedWord.length
                              ),
                            ].join('');
                          }
                        });
                        return processedChunk;
                      })
                      .join(' ');

                    const isHovered =
                      hoveredOption && hoveredOption.id === id;

                    const isCurrentSelected = withCheckBox ? selectedOption.includes(id) : selectedOption === id;

                    /* eslint-disable react/no-danger */
                    return (
                      <div
                        role='button'
                        tabIndex={0}
                        key={`option-${id}`}
                        ref={isCurrentSelected ? this.selectedOptionRef : this.optionRef}
                        className={classnames(`option-${id}`, styles.option, classNameOption, {
                          [styles.hovered]: isHovered,
                          [styles.selected]: showActiveState && (isCurrentSelected || (!selectedOption && !id)),
                          [styles.withCheckBox]: withCheckBox,
                        })}
                        style={{
                          whiteSpace: whiteSpaceOptions
                        }}
                        onClick={() => {
                          this.handlerSelected(option, isCurrentSelected);
                        }}
                        onKeyDown={() => {
                          this.handlerSelected(option, isCurrentSelected);
                        }}
                        onMouseEnter={() =>
                          this.setState({ hoveredOption: option })}
                      >
                        {withCheckBox && (
                          <Checkbox
                            className={styles.optionCheckbox}
                            checked={isCurrentSelected}
                            value={option.id}
                            title={option.label}
                            alignCenter
                            noWrap
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          />
                        )}
                        <div className={styles.leadIconWrapper}>
                          {option?.leadIcon && option?.leadIcon}
                          <span
                            dangerouslySetInnerHTML={{ __html: processedTitle }}
                          />
                        </div>
                        {option?.icon && option?.icon}
                        {withCheckBox && id && (
                          <BigButton
                            onClick={e => this.handlerOnlyClick(e, option)}
                            className={styles.onlyButton}
                            theme='transparent'
                            title={formatMessage({ id: 'button.only' })}
                          />
                        )}
                      </div>
                    );
                    /* eslint-enable react/no-danger */
                  });
                  return (
                    <div key={`group-${groupId}`}>
                      {renderedGroup}
                      {renderedOptions}
                    </div>
                  );
                })
                : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default injectIntl(DefaultSearchList);
