import { addPoints } from 'components/CanvasTimeline/helpers/GeometryUtils';
import { roundRect } from 'components/CanvasTimeline/helpers/DrawUtils';
import CanvasComponent from './CanvasComponent';

class TextTooltip extends CanvasComponent {
  isMeasureTextDirty = true;

  text = '';

  textWidthPadding = 0;

  constructor({
    text,
    position = { x: 0, y: 0 }
  }) {
    super({
      position
    });
    this.text = text;
  }

  updateMeasureText(ctx) {
    if (this.isMeasureTextDirty) {
      this.measureText = this.text ? ctx.measureText(this.text) : null;
      this.isMeasureTextDirty = false;
    }
  }

  setText(text) {
    this.text = text;
    this.isMeasureTextDirty = true;
  }

  draw(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    super.draw(ctx, {
      translateAdd
    });

    this.updateMeasureText(ctx);

    const size = this.getSize();
    const screenPosition = addPoints(this.position, translateAdd);

    roundRect(ctx, {
      ...screenPosition,
      ...size,
      fill: true,
      radius: {
        tl: 2, bl: 2, tr: 2, br: 2
      },
      color: '#4a4a49'
    });

    if (this.text) {
      ctx.font = 'normal normal 400 12px Roboto';
      ctx.fillStyle = 'hsla(0,0%,100%,.9)';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        this.text,
        screenPosition.x + size.width / 2,
        screenPosition.y + size.height / 2 + 1.5
      );
    }

    const sWidth = size.width;
    const sHeight = 8;
    const path = new Path2D();
    const tWidth = 6;
    const triangleTop = size.height + 4;

    ctx.fillStyle = '#4a4a49';

    const points = [{
      x: screenPosition.x + (sWidth / 2) - tWidth,
      y: screenPosition.y + triangleTop - sHeight / 2
    }, {
      x: screenPosition.x + (sWidth / 2) + tWidth,
      y: screenPosition.y + triangleTop - sHeight / 2
    }, {
      x: screenPosition.x + (sWidth / 2),
      y: screenPosition.y + triangleTop - (sHeight / 2) + 4
    }];

    for (let i = 0; i < points.length; i += 1) {
      const point = points[i];
      if (i === 0) {
        path.moveTo(point.x, point.y);
      } else {
        path.lineTo(point.x, point.y);
      }
    }

    ctx.fill(path);
  }

  getSize() {
    return this.measureText ? {
      width: this.measureText.width + this.textWidthPadding,
      height: 22,
    } : {
      width: 0,
      height: 22,
    };
  }
}

export default TextTooltip;
