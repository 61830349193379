import TextTooltip from 'components/CanvasTimeline/components/TextTooltip';
import { textEllipsis, wrapText } from 'components/CanvasTimeline/helpers/TextUtils';
import {
  COMPARTMENT_BLOCK_HEIGHT,
  COMPARTMENT_HEADER_HEIGHT,
  COMPARTMENT_VARIETY_BLOCK_HEIGHT,
  FIRST_COMPARTMENT_PADDING, HEADER_HEIGHT,
  LEFT_PANEL_WIDTH
} from '../helpers/constants';

import { addPoints, pointInRect } from '../helpers/GeometryUtils';
import { roundRect } from '../helpers/DrawUtils';

import CanvasComponent from './CanvasComponent';
import SvgIcon from '../helpers/SvgIcon';

import { ReactComponent as CompartmentIcon } from '../assets/compartment.svg';

class LeftPanel extends CanvasComponent {
  tooltipHeights = []

  tooltipY = null;

  scrollY = 0;

  constructor({
    position = { x: 0, y: 0 },
    thickLines,
    viewport,
    descriptor,
    tooltipText,
    emptyText,
    formatMessage
  }) {
    super({
      position
    });
    this.thickLines = thickLines;
    this.descriptor = descriptor;
    this.updateView(viewport);

    this.iconCompantment = new SvgIcon(CompartmentIcon);

    this.textToolip = new TextTooltip({
      text: tooltipText,
    });

    this.emptyText = emptyText;
    this.formatMessage = formatMessage;
    this.setZIndex(1000);
  }


  updateView({
    viewport
  }) {
    this.viewport = viewport;
    this.setDirty();
  }

  updateData({
    thickLines,
    descriptor,
  }) {
    this.thickLines = thickLines;
    this.descriptor = descriptor;
    this.setDirty();
  }


  getSize() {
    return {
      width: LEFT_PANEL_WIDTH,
      height: this.viewport.height - this.position.y,
    };
  }

  setTimelineScroll({ y }) {
    this.scrollY = y;
  }

  draw(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    super.draw(ctx, {
      translateAdd
    });

    ctx.fillStyle = '#FFF';
    const size = this.getSize();
    ctx.fillRect(0, HEADER_HEIGHT, LEFT_PANEL_WIDTH, size.height);

    this.tooltipHeights = [];
    const screenPosition = addPoints(
      addPoints(this.position, translateAdd),
      { x: 0, y: this.scrollY }
    );

    if (this.thickLines.length > 0) {
      // left block
      if (this.descriptor?.breakdown === 'varietyWeight') {
        // TODO: calcCompartmentsHeight for this
        this.drawLeftPanelByVarieties(ctx, {
          thickLines: this.thickLines,
          descriptor: this.descriptor,
          position: screenPosition,
        });
      } else {
        const heights = this.calcCompartmentsHeight({
          thickLines: this.thickLines,
          position: screenPosition,
          viewport: this.viewport
        });
        this.drawLeftPanelByCompartments(ctx, {
          thickLines: this.thickLines,
          descriptor: this.descriptor,
          heights,
        });
      }

      if (this.tooltipY) {
        this.textToolip.setPosition({ x: 28, y: this.tooltipY?.y - 42 });
        this.textToolip.draw(ctx);
      }
    } else {
      const textX = screenPosition.x + (LEFT_PANEL_WIDTH / 2) - 100;
      const textY = screenPosition.y + 46;
      ctx.save();
      ctx.fillStyle = '#777776';
      ctx.textAlign = 'left';
      ctx.font = 'normal normal 400 13px Roboto';
      wrapText({
        ctx,
        text: this.emptyText,
        x: textX,
        y: textY,
        maxWidth: 200,
        lineHeight: 18,
        center: true,
      });
      ctx.restore();
    }
  }

  calcCompartmentsHeight(options) {
    const {
      thickLines, position, viewport
    } = options;
    const result = [];
    const minY = FIRST_COMPARTMENT_PADDING;
    let prevY = minY + position.y;
    for (
      let compartmentIdx = 0;
      compartmentIdx < thickLines.length;
      compartmentIdx++
    ) {
      const data = thickLines[compartmentIdx];
      const startY = prevY;
      const lines = data?.lines || [];
      const linesCount = lines.length;
      const endY = startY + linesCount * COMPARTMENT_BLOCK_HEIGHT;
      const isVisible = endY >= minY && startY < viewport.height;
      result.push({
        startY,
        endY,
        data,
        isVisible
      });
      prevY = endY;
    }
    return result;
  }

  drawTotalProgressBar(ctx, {
    bottomY, data
  }) {
    const planYearTotal = data?.planYearTotal?.amountRaw || 0;
    const actualYearTotal = data?.actualYearTotal?.amountRaw || 0;

    const actualAbs = actualYearTotal > 0 ? 100 : 0;
    let progressX = planYearTotal > 0 ? ((actualYearTotal / planYearTotal) * 100) : actualAbs;

    if (progressX > 100) {
      progressX = 100;
    }

    ctx.fillStyle = '#E7E9EE';

    roundRect(ctx, {
      x: 24,
      y: bottomY - 20,
      width: 48,
      height: 4,
      fill: true,
      radius: {
        tl: 2,
        bl: 2,
        tr: 2,
        br: 2,
      },
      color: '#E7E9EE',
    });

    if (progressX > 0) {
      roundRect(ctx, {
        x: 24,
        y: bottomY - 20,
        width: (48 / 100) * progressX,
        height: 4,
        fill: true,
        radius: {
          tl: 2,
          bl: 2,
          tr: 2,
          br: 2,
        },
        color: '#43B4DE',
      });
    }

    const units = this.formatMessage({ id: `cunits.mini.${data?.actualYearTotal?.units}` });

    const tooltipY = bottomY - 14;
    ctx.fillStyle = '#777776';
    ctx.font = 'normal normal 400 13px Roboto';
    ctx.fillText(
      `${data?.actualYearTotal?.amount || 0} of ${data?.planYearTotal?.amount || 0} ${units}`,
      80,
      bottomY - 16
    );

    this.tooltipHeights.push({
      id: data.compartment.id,
      y: tooltipY
    });
  }

  drawLeftPanelByVarieties(ctx, { thickLines, descriptor, position }) {
    ctx.fillStyle = '#FFF';
    ctx.fillRect(0, 0, LEFT_PANEL_WIDTH, ctx.canvas.height);

    let prevY = position.y;

    let lastCompartmentId = null;

    for (
      let compartmentIdx = 0;
      compartmentIdx < thickLines.length;
      compartmentIdx++
    ) {
      const data = thickLines[compartmentIdx];

      const compartmentId = data.compartment.id;

      const lines = data?.lines || [];

      ctx.strokeStyle = '#E7E9EE';
      ctx.beginPath();
      ctx.moveTo(0, prevY);
      ctx.lineTo(LEFT_PANEL_WIDTH, prevY);
      ctx.stroke();

      if (!lastCompartmentId || lastCompartmentId !== compartmentId) {
        ctx.fillStyle = '#777776';

        this.iconCompantment.draw(ctx, {
          position: {
            x: 33,
            y: prevY + 40
          }
        });

        const compartmentTitle = `${data.compartment.attributes.name}`;
        const compartmentTitleEllipsis = textEllipsis(ctx, compartmentTitle, 180);

        ctx.font = 'normal normal 500 13px Roboto';
        ctx.fillStyle = '#777776';
        ctx.textAlign = 'left';
        ctx.fillText(
          compartmentTitleEllipsis,
          50,
          prevY + 42
        );

        ctx.font = 'normal normal normal 13px Roboto';

        ctx.fillText(
          `(${data.compartment.attributes.floorArea} ${descriptor.squareMeterLocalized})`,
          50 + ctx.measureText(compartmentTitleEllipsis).width + 4,
          prevY + 42
        );

        prevY += COMPARTMENT_HEADER_HEIGHT;
      }

      lastCompartmentId = compartmentId;

      for (let lineIndex = 0; lineIndex < lines.length; lineIndex += 1) {
        ctx.beginPath();
        ctx.moveTo(0, prevY);
        ctx.lineTo(LEFT_PANEL_WIDTH, prevY);
        ctx.stroke();
        ctx.save();
        ctx.fillStyle = '#777776';
        ctx.font = 'normal normal 400 13px Roboto';
        wrapText({
          ctx,
          text: data?.productTypeName || '',
          x: 24,
          y: prevY + 24,
          maxWidth: 280,
          lineHeight: 18,
          center: false,
        });
        ctx.restore();

        this.drawTotalProgressBar(ctx, {
          bottomY: prevY + COMPARTMENT_VARIETY_BLOCK_HEIGHT - 4,
          descriptor,
          data
        });

        prevY += COMPARTMENT_VARIETY_BLOCK_HEIGHT;
      }
    }

    ctx.beginPath();
    ctx.moveTo(0, prevY);
    ctx.lineTo(LEFT_PANEL_WIDTH, prevY);
    ctx.stroke();

    ctx.strokeStyle = '#E7E9EE';
    ctx.beginPath();
    ctx.moveTo(LEFT_PANEL_WIDTH, 0);
    ctx.lineTo(LEFT_PANEL_WIDTH, ctx.canvas.height);
    ctx.stroke();
  }

  drawLeftPanelByCompartments(ctx, { descriptor, heights }) {
    ctx.fillStyle = '#FFF';
    ctx.fillRect(0, 0, LEFT_PANEL_WIDTH, ctx.canvas.height);

    for (let index = 0; index < heights.length; index += 1) {
      const {
        startY,
        endY,
        data,
        isVisible
      } = heights[index];

      if (isVisible) {
        ctx.strokeStyle = '#E7E9EE';
        ctx.beginPath();
        ctx.moveTo(0, startY);
        ctx.lineTo(LEFT_PANEL_WIDTH, startY);
        ctx.stroke();

        const compartmentTitle = `${data.compartment.attributes.name}`;
        const compartmentTitleEllipsis = textEllipsis(ctx, compartmentTitle, 180);

        ctx.font = 'normal normal 500 13px Roboto';
        ctx.fillStyle = '#777776';
        ctx.textAlign = 'left';
        ctx.fillText(
          compartmentTitleEllipsis,
          24,
          startY + 28
        );

        ctx.font = 'normal normal normal 13px Roboto';

        ctx.fillText(
          `(${data.compartment.attributes.floorArea} ${descriptor.squareMeterLocalized})`,
          24 + ctx.measureText(compartmentTitleEllipsis).width + 4,
          startY + 28
        );

        this.drawTotalProgressBar(ctx, {
          bottomY: endY, descriptor, data
        });

        ctx.strokeStyle = '#E7E9EE';
        ctx.beginPath();
        ctx.moveTo(0, endY);
        ctx.lineTo(LEFT_PANEL_WIDTH, endY);
        ctx.stroke();
      }
    }

    ctx.strokeStyle = '#E7E9EE';
    ctx.beginPath();
    ctx.moveTo(LEFT_PANEL_WIDTH, 0);
    ctx.lineTo(LEFT_PANEL_WIDTH, ctx.canvas.height);
    ctx.stroke();
  }

  onPointerMove({ x, y }) {
    super.onPointerMove({ x, y });
    this.tooltipY = this.tooltipHeights.find(heightInfo => pointInRect({ x, y }, {
      x: 0,
      y: heightInfo.y - 4,
      width: LEFT_PANEL_WIDTH,
      height: 14,
    }));
  }
}

export default LeftPanel;
