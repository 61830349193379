import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import DefaultDialog from 'components/DefaultDialog';
import BigButton from 'components/BigButton';

import styles from './DefaultDeleteDialog.module.css';

const DefaultDeleteDialog = ({
  intl,
  isFetching,
  title,
  text,
  handlerCloseDialog,
  handlerDelete,
  handlerDontDelete,
}) => {
  const { formatMessage } = intl;

  return (
    <DefaultDialog
      className={styles.dialog}
      wrapperClassName={styles.dialogWrapper}
      title={title}
      onClose={handlerCloseDialog}
    >
      <div>
        <p className={styles.dialogText}>
          {text}
        </p>
        <div className={styles.buttons}>
          <BigButton
            className={styles.cancel}
            onClick={handlerDontDelete}
            title={formatMessage({ id: 'button.cancel' })}
            theme='light'
            disabled={isFetching}
          />
          <BigButton
            className={styles.confitm}
            onClick={handlerDelete}
            title={formatMessage({ id: 'button.confirmDelete' })}
            theme='error'
            isLoading={isFetching}
          />
        </div>
      </div>
    </DefaultDialog>
  );
};

DefaultDeleteDialog.propTypes = {
  intl: intlShape.isRequired,
  isFetching: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,

  handlerCloseDialog: PropTypes.func.isRequired,
  handlerDontDelete: PropTypes.func.isRequired,
  handlerDelete: PropTypes.func.isRequired,
};

DefaultDeleteDialog.defaultProps = {
  isFetching: false,
  title: '',
  text: '',
};


export default injectIntl(DefaultDeleteDialog);
