import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import SidePageMenu from '../../SidePageMenu';

import styles from './ResourcesMenu.module.css';

// TODO: Refactoring menu. Untie from resource type (move link definitinon to specified component)
export default class ResourcesMenu extends Component {
  static propTypes = {
    intl: intlShape.isRequired,

    organizationSlug: PropTypes.string.isRequired,
    resourceType: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
    handlerMenuClick: PropTypes.func,
    title: PropTypes.string,
  };

  static defaultProps = {
    handlerMenuClick: null,
    title: null,
  };

  getLink = (resource) => {
    const {
      intl: { formatMessage },
      organizationSlug,
      handlerMenuClick,
    } = this.props;

    return {
      href: `/${organizationSlug}/resources/energy/${resource}`,
      name: formatMessage({ id: `energy.${resource}` }),
      ...(handlerMenuClick ? {
        onClick: () => handlerMenuClick(`${resource}Meter`)
      } : {}),
    };
  };

  render() {
    const {
      intl: { formatMessage },
      resourceType,
      children,
      organizationSlug,
      title,
    } = this.props;

    const energyTypes = ['gas', 'electricity', 'water'];

    const menuItems = [
      {
        header: formatMessage({ id: 'dashboards.energy' }),
        links: energyTypes.map(type => this.getLink(type))
      },
      {
        header: formatMessage({ id: 'labor.laborJob' }),
        links: [{
          href: `/${organizationSlug}/resources/labor/works`,
          name: formatMessage({ id: 'labor.works' })
        }]
      },
    ];

    return (
      <SidePageMenu
        header={title || formatMessage({ id: `energy.${resourceType}` })}
        menuItems={menuItems}
        className={styles.menuPage}
        contentClassName={styles.menuContent}
        hiddenOnDesktop
      >
        {children}
      </SidePageMenu>
    );
  }
}
