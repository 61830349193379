import uuid from 'uuid/v4';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { intlShape, FormattedMessage } from 'react-intl';
import {
  filter, find, get, uniqBy, throttle
} from 'lodash';

import updateLocationSearch from 'helpers/updateLocationSearch';
import numbersRounding from 'helpers/numbersRounding';
import getDateFormat from 'helpers/getDateFormat';
import { getProductTypeName } from 'helpers/getVarietyName';

import PlanFilter from 'components/DashboardComplexFilters/components/PlanFilter';
import DashboardHeader from 'components/DashboardHeader';
import YearPicker from 'components/YearPicker';
import EmptyState from 'components/EmptyState';
import BigTable from 'components/BigTable';
import SpeciesIcon from 'components/SpeciesIcon';

import { ReactComponent as TrendDownIcon } from './assets/trend-down.svg';
import { ReactComponent as TrendUpIcon } from './assets/trend-up.svg';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';

import styles from './CropsPerformanceDashboard.module.css';


export default class CropsPerformanceDashboard extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    organization: PropTypes.object,
    cropsPerformanceSorting: PropTypes.object,
    allCompartments: PropTypes.array,
    allPlantingCycles: PropTypes.array, // TODO: выпилить allPlantingCycles, поменять на allFullPlantingCycles
    allFullPlantingCycles: PropTypes.array,
    varieties: PropTypes.array,
    year: PropTypes.number,
    years: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    isCropsPerformanceFetching: PropTypes.bool,
    isEmptyCropsPerformance: PropTypes.bool,
    cropsPerformanceItems: PropTypes.object,
    getCropsPerformanceStarted: PropTypes.func.isRequired,
    updateCropsPerformanceSorting: PropTypes.func.isRequired,
    getCropsData: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    locationPlan: PropTypes.number,
  };

  static defaultProps = {
    year: null,
    cropsPerformanceItems: {},
    isCropsPerformanceFetching: false,
    isEmptyCropsPerformance: false,
    organization: null,
    allCompartments: null,
    allPlantingCycles: null,
    allFullPlantingCycles: null,
    varieties: null,
    cropsPerformanceSorting: null,
    locationPlan: null,
  };

  state = {
    isOnlyFirstColumnModeEnabled: window.innerWidth < 1024,
  };

  handlerResize = throttle(() => {
    this.setState({
      isOnlyFirstColumnModeEnabled: window.innerWidth < 1024,
    });
  }, 100);

  componentDidMount() {
    const {
      getCropsData,
      year,
      years,
      history,
      locationPlan,
    } = this.props;

    if (year) {
      getCropsData({ isCropsPerformanceCallback: true, year, locationPlan });
    } else if (years.length) {
      updateLocationSearch({ year: years[0] }, history);

      getCropsData({ isCropsPerformanceCallback: true, year: years[0], locationPlan });
    }

    window.addEventListener('resize', this.handlerResize);
  }

  componentDidUpdate(prevProps) {
    const { year: nextYear, history, years } = this.props;
    const { year: oldYear } = prevProps;

    if (!nextYear && oldYear && years.length) {
      updateLocationSearch({ year: oldYear }, history);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResize);
  }

  getColumns = ({ species }) => {
    const {
      intl,
      cropsPerformanceItems,
      allCompartments,
      allFullPlantingCycles,
      cropsPerformanceSorting,
      match,
    } = this.props;

    const activeSort = get(cropsPerformanceSorting, `${species}.type`);
    const sortDirection = get(cropsPerformanceSorting, `${species}.direction`);
    const activeCategory = get(cropsPerformanceSorting, `${species}.category`);

    const maxAvgHarvestPerWeek = Math.max(...get(cropsPerformanceItems, `${species}.items`, []).map(item => get(item, 'avgHarvestPerWeek.amount', 0)));

    const isLettuce = species === 'lettuce';
    const roundTo = isLettuce ? 0 : 1;
    const currentUnits = isLettuce ? <FormattedMessage id='cunits.mini.count' /> : <FormattedMessage id='cunits.mini.kilogramPerSquareMeter' />;

    return [
      {
        id: 'cycle',
        isFirstColumn: true,
        isHeader: true,
        onHeaderClick: () => this.handlerCropsPerformanceSorting({ type: 'cycle', category: species }),
        renderValueCell: ({ data }) => {
          const fullData = find(allFullPlantingCycles, { id: get(data, 'id') });
          const fullName = getProductTypeName({
            intl,
            variety: get(fullData, 'variety'),
            fruitClass: get(fullData, 'fruitClass'),
            targetWeight: get(fullData, 'cycle.targetWeight'),
          });

          const startDateMoment = moment(get(data, 'cycle.startDate'));
          const endDateMoment = moment(get(data, 'cycle.endDate'));
          const startDate = startDateMoment.format(getDateFormat('lll'));
          const endDate = endDateMoment.format(getDateFormat('lll'));

          return (
            <RouterLink className={classnames(styles.cropLink)} to={`/${match.params.organizationSlug}/crops/${data.id}`}>
              <div className={styles.titleCellHeader}>
                {fullName}
              </div>
              <div className={styles.titleCellDate}>
                {startDate} – {endDate}
              </div>
            </RouterLink>
          );
        },
        renderHeaderCell: () => (
          <div
            role='button'
            tabIndex={0}
            className={classnames(
              styles.indicatorHeaderTitle,
              {
                [styles.activeSort]: species === activeCategory && activeSort === 'cycle',
              }
            )}
          >
            <FormattedMessage id='cropsPerformance.cycle' />
            <div
              className={classnames(styles.arrow, {
                [styles.sortDirectionDown]: sortDirection === 'down' && species === activeCategory && activeSort === 'cycle'
              })}
            >
              <ArrowIcon />
            </div>
          </div>
        )
      },
      {
        id: 'compartment',
        isFirstColumn: false,
        isHeader: false,
        onHeaderClick: () => this.handlerCropsPerformanceSorting({ type: 'compartment', category: species }),
        renderValueCell: ({ data }) => {
          const compartment = find(allCompartments, compartmentItem => get(compartmentItem, 'id') === get(data, 'cycle.compartmentRef.id'));

          const compartmentName = get(compartment, 'attributes.name');

          return (
            <div className={styles.textCell}>
              {compartmentName}
            </div>
          );
        },
        renderHeaderCell: () => (
          <div
            role='button'
            tabIndex={0}
            style={{ width: 114 }}
            className={classnames(
              styles.headerTitle,
              {
                [styles.activeSort]: species === activeCategory && activeSort === 'compartment',
              }
            )}
          >
            <FormattedMessage id='cropsPerformance.compartment' />

            <div
              className={classnames(styles.arrow, {
                [styles.sortDirectionDown]: sortDirection === 'down' && species === activeCategory && activeSort === 'compartment'
              })}
            >
              <ArrowIcon />
            </div>
          </div>
        )
      },
      {
        id: 'total-weeks',
        isFirstColumn: false,
        isHeader: false,
        onHeaderClick: () => this.handlerCropsPerformanceSorting({ type: 'totalWeeks', category: species }),
        renderValueCell: ({ data }) => {
          const value = get(data, 'totalWeeks');

          return (
            <div className={styles.numberCell}>
              {value || '–'}
            </div>
          );
        },
        renderHeaderCell: () => (
          <div
            role='button'
            tabIndex={0}
            style={{ width: 70 }}
            className={classnames(
              styles.headerTitle,
              {
                [styles.activeSort]: species === activeCategory && activeSort === 'totalWeeks',
              }
            )}
          >
            <FormattedMessage id='cropsPerformance.totalWeeks' />

            <div className={classnames(styles.arrow, { [styles.sortDirectionDown]: sortDirection === 'down' && species === activeCategory && activeSort === 'totalWeeks' })}>
              <ArrowIcon />
            </div>
          </div>
        )
      },
      {
        id: 'harvest-weeks',
        isFirstColumn: false,
        isHeader: false,
        onHeaderClick: () => this.handlerCropsPerformanceSorting({ type: 'harvestedWeeks', category: species }),
        renderValueCell: ({ data }) => {
          const value = get(data, 'harvestWeeks');

          return (
            <div className={styles.numberCell}>
              {value || '–'}
            </div>
          );
        },
        renderHeaderCell: () => (
          <div
            role='button'
            tabIndex={0}
            style={{ width: 90 }}
            className={classnames(
              styles.headerTitle,
              {
                [styles.activeSort]: species === activeCategory && activeSort === 'harvestedWeeks',
              }
            )}
          >
            <FormattedMessage id='cropsPerformance.harvestedWeeks' />
            <div className={classnames(styles.arrow, { [styles.sortDirectionDown]: sortDirection === 'down' && species === activeCategory && activeSort === 'harvestedWeeks' })}>
              <ArrowIcon />
            </div>
          </div>
        )
      },
      {
        id: 'actual-harvest',
        isFirstColumn: false,
        isHeader: false,
        onHeaderClick: () => this.handlerCropsPerformanceSorting({ type: 'actualHarvest', category: species }),
        renderValueCell: ({ data }) => {
          const value = get(data, 'actualHarvest.amount');

          return (
            <div className={styles.numberCell}>
              {numbersRounding(value, 'fixed', roundTo) || '–'}
            </div>
          );
        },
        renderHeaderCell: () => (
          <div
            role='button'
            tabIndex={0}
            style={{ width: 109 }}
            className={classnames(
              styles.headerTitle,
              {
                [styles.activeSort]: species === activeCategory && activeSort === 'actualHarvest',
              }
            )}
          >
            <FormattedMessage id='cropsPerformance.harvestActual' /><br />({currentUnits})
            <div className={classnames(styles.arrow, { [styles.sortDirectionDown]: sortDirection === 'down' && species === activeCategory && activeSort === 'actualHarvest' })}>
              <ArrowIcon />
            </div>
          </div>
        )
      },
      {
        id: 'harvest-goal',
        isFirstColumn: false,
        isHeader: false,
        onHeaderClick: () => this.handlerCropsPerformanceSorting({ type: 'harvestGoal', category: species }),
        renderValueCell: ({ data }) => {
          const value = get(data, 'harvestGoal.amount');

          return (
            <div className={styles.numberCell}>
              {numbersRounding(value, 'fixed', roundTo) || '–'}
            </div>
          );
        },
        renderHeaderCell: () => (
          <div
            role='button'
            tabIndex={0}
            style={{ width: 114 }}
            className={classnames(
              styles.headerTitle,
              {
                [styles.activeSort]: species === activeCategory && activeSort === 'harvestGoal',
              }
            )}
          >
            <FormattedMessage id='cropsPerformance.harvestPlan' /> ({currentUnits})
            <div className={classnames(styles.arrow, { [styles.sortDirectionDown]: sortDirection === 'down' && species === activeCategory && activeSort === 'harvestGoal' })}>
              <ArrowIcon />
            </div>
          </div>
        )
      },
      {
        id: 'actual-harvest-vs-goal',
        isFirstColumn: false,
        isHeader: false,
        onHeaderClick: () => this.handlerCropsPerformanceSorting({ type: 'actualHarvestVsGoal', category: species }),
        renderValueCell: ({ data }) => {
          const category = get(data, 'actualHarvestVsGoal.category');
          const amount = get(data, 'actualHarvestVsGoal.percentage.amount');

          return (
            <div className={classnames(styles.contentCell, styles[category])}>
              {category === 'green' && <TrendUpIcon className={styles.numberCellIcon} />}
              {category === 'red' && <TrendDownIcon className={styles.numberCellIcon} />}
              {numbersRounding(amount, 'fixed', 1) || '–'}
              {amount && <FormattedMessage id='cunits.mini.percent' />}
            </div>
          );
        },
        renderHeaderCell: () => (
          <div
            role='button'
            tabIndex={0}
            style={{ width: 96 }}
            className={classnames(
              styles.headerTitle,
              {
                [styles.activeSort]: species === activeCategory && activeSort === 'actualHarvestVsGoal',
              }
            )}
          >
            <FormattedMessage id='cropsPerformance.actualHarvestVsGoal1' />&nbsp;
            <FormattedMessage id='cropsPerformance.actualHarvestVsGoal2' />&nbsp;(<FormattedMessage id='cunits.mini.percent' />)
            <div className={classnames(styles.arrow, { [styles.sortDirectionDown]: sortDirection === 'down' && species === activeCategory && activeSort === 'actualHarvestVsGoal' })}>
              <ArrowIcon />
            </div>
          </div>
        )
      },
      {
        id: 'avg-harvest-per-week',
        isFirstColumn: false,
        isHeader: false,
        onHeaderClick: () => this.handlerCropsPerformanceSorting({ type: 'avgHarvestPerWeek', category: species }),
        renderValueCell: ({ data }) => {
          const value = get(data, 'avgHarvestPerWeek.amount');
          const avgHarvestPerWeekPercent = value / maxAvgHarvestPerWeek;
          const width = Math.round(56 * avgHarvestPerWeekPercent);
          // const units = get(data, 'avgHarvestPerWeek.units');

          return (
            <div className={styles.contentCell}>
              <div className={styles.harvestValueMonitor}>
                {value ? numbersRounding(value, 'fixed', roundTo) : '–'}
              </div>
              <div className={styles.harvestMonitor}>
                {value ? <div style={{ width: width >= 4 ? width : 4 }} className={styles.harvestMonitorColor} /> : null}
              </div>
            </div>
          );
        },
        renderHeaderCell: () => (
          <div
            role='button'
            tabIndex={0}
            style={{ width: 128 }}
            className={classnames(
              styles.headerTitle,
              styles.headerTitleBig,
              {
                [styles.activeSort]: species === activeCategory && activeSort === 'avgHarvestPerWeek',
              }
            )}
          >
            <FormattedMessage id='cropsPerformance.avgHarvestPerWeek1' />&nbsp;
            <FormattedMessage id='cropsPerformance.avgHarvestPerWeek2' /> <FormattedMessage id='cropsPerformance.avgHarvestPerWeek3' />&nbsp;({currentUnits})
            <div className={classnames(styles.arrow, { [styles.sortDirectionDown]: sortDirection === 'down' && species === activeCategory && activeSort === 'actualHarvestVsGoal' })}>
              <ArrowIcon />
            </div>
          </div>
        )
      },
    ];
  };

  handlerAfterRangeSelect = ({ year }) => {
    const { getCropsPerformanceStarted, history, locationPlan } = this.props;

    updateLocationSearch({ year }, history);

    getCropsPerformanceStarted({ year, locationPlan });
  };

  handlerCropsPerformanceSorting = ({ type, category }) => {
    const {
      updateCropsPerformanceSorting,
      cropsPerformanceSorting,
      allCompartments,
      allPlantingCycles,
      varieties,
      organization,
    } = this.props;

    const locationCompartments = allCompartments.filter(item => get(item, 'relationships.location.data[0].id') === get(organization, 'id'));
    const locationPlantingCycles = filter(allPlantingCycles, plantingCycle => locationCompartments.find(compartment => compartment.id === get(plantingCycle, 'relationships.compartment.data[0].id')));
    const varietiesIds = locationPlantingCycles.map(plantingCycle => get(plantingCycle, 'relationships.variety.data[0].id'));
    const locationVarieties = filter(varieties, variety => varietiesIds.find(id => id === variety.id));
    const uniqLocationVarieties = uniqBy(locationVarieties, 'attributes.species');

    const locationSpecies = uniqLocationVarieties.map(variety => get(variety, 'attributes.species')).sort((a, b) => a.localeCompare(b));

    const isOldSort = type === get(cropsPerformanceSorting, `${category}.type`) && category === get(cropsPerformanceSorting, `${category}.category`);
    const currentSortDirection = get(cropsPerformanceSorting, `${category}.direction`);

    const sorting = locationSpecies.reduce((reducer, item) => {
      const temp = reducer;

      temp[item] = {
        type: get(cropsPerformanceSorting, `${item}.type`),
        category: get(cropsPerformanceSorting, `${item}.category`),
        direction: get(cropsPerformanceSorting, `${item}.direction`),
      };

      return temp;
    }, {});

    if (currentSortDirection === 'up' && isOldSort) {
      sorting[category] = { type, category, direction: 'down' };
    } else if (!isOldSort) {
      sorting[category] = { type, category, direction: 'up' };
    } else {
      sorting[category] = null;
    }

    updateCropsPerformanceSorting(sorting);
  };

  handlerPlanChange = ({ locationPlan }) => {
    const { getCropsPerformanceStarted, history, year } = this.props;

    updateLocationSearch({ locationPlan }, history);

    getCropsPerformanceStarted({ locationPlan, year });
  };

  render() {
    const {
      cropsPerformanceItems: items,
      isCropsPerformanceFetching: isFetching,
      isEmptyCropsPerformance,
      year,
      years,
      allCompartments,
      allPlantingCycles,
      varieties,
      organization,
      intl,
    } = this.props;

    const { isOnlyFirstColumnModeEnabled } = this.state;

    const { formatMessage } = intl;

    const locationCompartments = allCompartments.filter(item => get(item, 'relationships.location.data[0].id') === get(organization, 'id'));
    const locationPlantingCycles = filter(allPlantingCycles, plantingCycle => locationCompartments.find(compartment => compartment.id === get(plantingCycle, 'relationships.compartment.data[0].id')));
    const varietiesIds = locationPlantingCycles.map(plantingCycle => get(plantingCycle, 'relationships.variety.data[0].id'));
    const locationVarieties = filter(varieties, variety => varietiesIds.find(id => id === variety.id));
    const uniqLocationVarieties = uniqBy(locationVarieties, 'attributes.species');

    const locationSpecies = uniqLocationVarieties.map(variety => get(variety, 'attributes.species')).sort((a, b) => a.localeCompare(b));

    return (
      <div className={styles.layout}>
        <div className={styles.header}>
          <DashboardHeader
            containerClassName={styles.container}
            text={`${formatMessage({ id: 'cropsPerformance.year' })}, ${year}`}
            dashboardName={formatMessage({ id: 'dashboards.cropsPerformance' })}
            customDatePicker={(
              <YearPicker
                year={year}
                years={years}
                handlerAfterRangeSelect={this.handlerAfterRangeSelect}
              />
            )}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.filters}>
            <div className={styles.desktopContainer}>
              <PlanFilter
                onFiltersChange={this.handlerPlanChange}
                capitalizeTitle
              />
            </div>
          </div>

          {!isEmptyCropsPerformance && locationSpecies.map((species) => {
            const speciesName = formatMessage({ id: `plantingCycles.species.${species}` });
            const speciesItems = get(items, `${species}.items`, []);

            const columns = this.getColumns({ species });

            const isLettuce = species === 'lettuce';
            const roundTo = isLettuce ? 0 : 1;
            const currentUnits = isLettuce ? <FormattedMessage id='cunits.mini.count' /> : <FormattedMessage id='cunits.mini.kilogramPerSquareMeter' />;

            const myid = uuid();

            const avgHarvestedVsPlanIndicatorValue = Math.round(speciesItems.reduce((reducer, item) => reducer + (get(item, 'actualHarvestVsGoal.percentage.amount', 0) * (get(item, 'actualHarvestVsGoal.category') === 'red' ? -1 : 1)), 0) / speciesItems.length) || 0;
            const avgHarvestedVsPlanIndicatorFlow = avgHarvestedVsPlanIndicatorValue > 0 ? '+' : '-';

            return (
              <div className={styles.cropsPerformance} key={species}>
                <div className={styles.title}>
                  <SpeciesIcon species={species} className={styles.speciesIcon} />
                  {speciesName} {speciesItems.length ? `(${speciesItems.length})` : ''}
                </div>
                {speciesItems.length ? (
                  <div className={styles.indicators}>
                    <div className={styles.indicatorsContainer}>
                      <div className={styles.indicator}>
                        <div className={styles.indicatorTitle}><FormattedMessage id='cropsPerformance.avgHarvestedWeeks' /></div>
                        <div className={styles.indicatorValue}>{Math.round(speciesItems.reduce((reducer, item) => reducer + get(item, 'harvestWeeks', 0), 0) / speciesItems.length) || 0}</div>
                      </div>
                      <div className={styles.indicator}>
                        <div className={styles.indicatorTitle}><FormattedMessage id='cropsPerformance.avgHarvested' /></div>
                        <div className={styles.indicatorValue}>{numbersRounding((speciesItems.reduce((reducer, item) => reducer + get(item, 'actualHarvest.amount', 0), 0) / speciesItems.length) || 0, 'fixed', roundTo)}<span className={styles.indicatorUnit}>{currentUnits}</span></div>
                      </div>
                      <div className={styles.indicator}>
                        <div className={styles.indicatorTitle}><FormattedMessage id='cropsPerformance.avgHarvestedVsPlan' /></div>
                        <div className={styles.indicatorValue}>{avgHarvestedVsPlanIndicatorValue ? avgHarvestedVsPlanIndicatorFlow : ''}{numbersRounding(avgHarvestedVsPlanIndicatorValue, 'fixed', 1)}<FormattedMessage id='cunits.mini.percent' /></div>
                      </div>
                      <div className={styles.indicator}>
                        <div className={styles.indicatorTitle}><FormattedMessage id='cropsPerformance.avgWeeklyHarvest' /></div>
                        <div className={styles.indicatorValue}>{numbersRounding((speciesItems.reduce((reducer, item) => reducer + get(item, 'avgHarvestPerWeek.amount', 0), 0) / speciesItems.length) || 0, 'fixed', roundTo)}<span className={styles.indicatorUnit}>{currentUnits}</span></div>
                      </div>
                    </div>
                  </div>
                ) : ''}
                <div className={styles.tableWrapper}>
                  <BigTable uuid={myid} intl={intl} isFetching={isFetching} items={speciesItems} columns={columns} showFirstColumn />
                  {isOnlyFirstColumnModeEnabled && <BigTable uuid={myid} intl={intl} isFetching={isFetching} items={speciesItems} columns={columns} onlyFirstColumn />}
                </div>
              </div>
            );
          })}
          {isEmptyCropsPerformance && !isFetching && (
            <div className={styles.empty}>
              <EmptyState className={styles.emptyState} header={formatMessage({ id: 'cropsPerformance.emptyStateHeader' })} text={formatMessage({ id: 'cropsPerformance.emptyStateDescription' })} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
