import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getOrganizationSlug,
  getHarvestForecastAccess,
  getDefaultOpenFromRoute,
} from 'store/company/selectors';
import { trackPageViewSettings } from 'store/company/actions';

import {
  getHarvestForecastSettings,
  getForecastSubscribersOfLocation,
  getIsForecastSubscribersOfLocationFetching,
  getIsHarvestForecastSettingsFetching
} from 'store/harvestForecast/selectors';
import { requestHarvestForecastSettings, requestForecastSubscribersOfLocation } from 'store/harvestForecast/actions';
import { requestUserPrivileges } from 'store/user/actions';
import { getUserPrivileges, getIsUserPrivilegesFetching } from 'store/user/selectors';

import SettingsHarvestForecast from '../components/SettingsHarvestForecast';

const mapDispatchToProps = {
  requestHarvestForecastSettings,
  requestForecastSubscribersOfLocation,
  requestUserPrivileges,
  trackPageViewSettings,
};

const mapStateToProps = (state, props) => ({
  ...props,

  organizationSlug: getOrganizationSlug(state, props),
  harvestForecastAccess: getHarvestForecastAccess(state),
  harvestForecastSettings: getHarvestForecastSettings(state, props),
  forecastSubscribers: getForecastSubscribersOfLocation(state, props),
  userPrivileges: getUserPrivileges(state, props),
  defaultOpen: getDefaultOpenFromRoute(state, props),
  isSettingsFetching:
    getIsHarvestForecastSettingsFetching(state, props)
    ||
    getIsForecastSubscribersOfLocationFetching(state, props)
    ||
    getIsUserPrivilegesFetching(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsHarvestForecast));
