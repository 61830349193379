import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';

import BigButton from 'components/BigButton';
import CloseIcon from 'components/Icons/Close';
import SelectBoxFilter from 'components/SelectBoxFilter';
import VersionCard from '../VersionCard';

import styles from './VersionHistorySidePanel.module.css';

const handlerFilterChange = (setFilterAction, { value }) => setFilterAction({ versionsFilter: value });

const renderEmtyState = formatMessage => (
  <div className={styles.emptyState}>
    <div className={styles.emptyStateText}>
      {formatMessage({ id: 'forecast.versionsEmptyState' })}
    </div>
  </div>
);

const renderContent = ({
  versions,
  allVersions,
  activeVersionId,
  onVersionClick,
  onEditVersionInfo,
  onDeleteVersionInfo,

  isDeleteVersionInfoFetching,
  isPlansEditorSaving,
  isVersionInfoFetching,
  readOnly,
  isRestoreVersionEnabled,
  restoreVersion,
}) => (
  <div className={styles.versionsContainer}>
    {versions.map((version, index) => {
      const {
        id, forecastId
      } = version;

      return (
        <VersionCard
          key={`version-${id}`}
          version={version}
          activeVersionId={activeVersionId}
          isLastSaved={index === 0}
          onVersionClick={onVersionClick}
          onEditVersionInfo={onEditVersionInfo}
          onDeleteVersionInfo={onDeleteVersionInfo}
          isDeleteVersionInfoFetching={isDeleteVersionInfoFetching}
          isPlansEditorSaving={isPlansEditorSaving}
          readOnly={readOnly}
          isVersionInfoFetching={isVersionInfoFetching}
          allVersions={allVersions}
          isRestoreVersionEnabled={isRestoreVersionEnabled}
          restoreVersion={() => restoreVersion(forecastId, id)}
        />
      );
    })}
  </div>
);

const VersionHistorySidePanel = ({
  intl: { formatMessage },
  opened,
  subHeader,
  versions,
  allVersions,
  onClose,
  versionsFilter,
  activeVersionId,
  onVersionClick,
  onEditVersionInfo,
  onDeleteVersionInfo,
  setFilterAction,

  isDeleteVersionInfoFetching,
  isPlansEditorSaving,
  readOnly,
  isVersionInfoFetching,
  isRestoreVersionEnabled,
  restoreVersion,
}) => {
  const filterOptions = [
    { label: formatMessage({ id: 'plansVersion.allFilter' }), value: 'all' },
    { label: formatMessage({ id: 'plansVersion.namedFilter' }), value: 'named' },
    { label: formatMessage({ id: 'plansVersion.publishedFilter' }), value: 'published' },
  ];

  return (
    <div
      className={classnames(
        styles.sidePanel,
        {
          [styles.sidePanelOpened]: opened
        }
      )}
    >
      <div className={styles.header}>
        <div className={styles.headerRow}>
          <h3 className={styles.headerText}>
            {formatMessage({ id: 'plansVersion.versionHistory' })}
          </h3>
          <BigButton
            className={styles.closeButton}
            onClick={onClose}
            icon={<CloseIcon />}
            theme='transparent'
            bigIcon
          />
        </div>
        <div className={styles.subHeader}>
          {subHeader}
        </div>
        <SelectBoxFilter
          value={versionsFilter}
          options={filterOptions}
          onChange={newValue => handlerFilterChange(setFilterAction, newValue)}
          title={formatMessage({ id: 'plansVersion.filterTitle' })}
          classNameWrapper={styles.filterWrapper}
          classNameButton={styles.filterButton}
          classNameTitle={styles.filterTitle}
        />
      </div>
      <div className={styles.content}>
        {versions.length > 0 ?
          renderContent({
            versions,
            activeVersionId,
            onVersionClick,
            onEditVersionInfo,
            onDeleteVersionInfo,
            isDeleteVersionInfoFetching,
            isPlansEditorSaving,
            readOnly,
            isVersionInfoFetching,
            allVersions,
            isRestoreVersionEnabled,
            restoreVersion,
          })
          :
          renderEmtyState(formatMessage)}
      </div>
    </div>
  );
};

VersionHistorySidePanel.propTypes = {
  intl: intlShape.isRequired,

  opened: PropTypes.bool.isRequired,
  subHeader: PropTypes.string,
  versionsFilter: PropTypes.string.isRequired,
  versions: PropTypes.array,
  allVersions: PropTypes.array,
  activeVersionId: PropTypes.number,
  isDeleteVersionInfoFetching: PropTypes.bool,
  isPlansEditorSaving: PropTypes.bool,
  readOnly: PropTypes.bool,
  isVersionInfoFetching: PropTypes.bool,
  isRestoreVersionEnabled: PropTypes.bool,

  onClose: PropTypes.func.isRequired,
  onVersionClick: PropTypes.func.isRequired,
  onEditVersionInfo: PropTypes.func.isRequired,
  onDeleteVersionInfo: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  restoreVersion: PropTypes.func.isRequired,
};

VersionHistorySidePanel.defaultProps = {
  versions: [],
  allVersions: [],
  activeVersionId: null,
  isDeleteVersionInfoFetching: false,
  isPlansEditorSaving: false,
  subHeader: '',
  readOnly: false,
  isVersionInfoFetching: false,
  isRestoreVersionEnabled: false,
};

export default memo(VersionHistorySidePanel);
