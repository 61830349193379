import 'whatwg-fetch';
import moment from 'moment-timezone';
import { get } from 'lodash';

import storageWrapper from '../../helpers/storageWrapper';

import { trackUserEvent } from '../analytics/actions';
import { setToggleErrorDialog, setTogglePhotoDetailDialog } from '../dialogs';

const safeLocalStorage = storageWrapper.get('localStorage');

// ------------------------------------
// Constants
// ------------------------------------

export const CLEAR_LEFT_PHOTOS = 'CLEAR_LEFT_PHOTOS';
export const INIT_LEFT_PHOTOS = 'INIT_LEFT_PHOTOS';
export const REQUEST_LEFT_PHOTOS = 'REQUEST_LEFT_PHOTOS';
export const RECEIVE_LEFT_PHOTOS = 'RECEIVE_LEFT_PHOTOS';
export const INVALIDATE_LEFT_PHOTOS = 'INVALIDATE_LEFT_PHOTOS';

export const CLEAR_RIGHT_PHOTOS = 'CLEAR_RIGHT_PHOTOS';
export const INIT_RIGHT_PHOTOS = 'INIT_RIGHT_PHOTOS';
export const REQUEST_RIGHT_PHOTOS = 'REQUEST_RIGHT_PHOTOS';
export const RECEIVE_RIGHT_PHOTOS = 'RECEIVE_RIGHT_PHOTOS';
export const INVALIDATE_RIGHT_PHOTOS = 'INVALIDATE_RIGHT_PHOTOS';

export const SELECT_PHOTO = 'SELECT_PHOTO';
export const SELECT_DELETED_PHOTO = 'SELECT_DELETED_PHOTO';

export const REQUEST_PHOTO = 'REQUEST_PHOTO';
export const RECEIVE_PHOTO = 'RECEIVE_PHOTO';
export const INVALIDATE_PHOTO = 'INVALIDATE_PHOTO';

export const REQUEST_DELETE_PHOTO = 'REQUEST_DELETE_PHOTO';
export const RECEIVE_DELETE_PHOTO = 'RECEIVE_DELETE_PHOTO';
export const INVALIDATE_DELETE_PHOTO = 'INVALIDATE_DELETE_PHOTO';

export const REQUEST_PHOTO_CATEGORIES = 'REQUEST_PHOTO_CATEGORIES';
export const RECEIVE_PHOTO_CATEGORIES = 'RECEIVE_PHOTO_CATEGORIES';
export const INVALIDATE_PHOTO_CATEGORIES = 'INVALIDATE_PHOTO_CATEGORIES';

export const REQUEST_PHOTO_COMMENT = 'REQUEST_PHOTO_COMMENT';
export const RECEIVE_PHOTO_COMMENT = 'RECEIVE_PHOTO_COMMENT';
export const INVALIDATE_PHOTO_COMMENT = 'INVALIDATE_PHOTO_COMMENT';


// ------------------------------------
// Actions
// ------------------------------------

/*  This is a thunk, meaning it is a function that immediately
 returns a function for lazy evaluation. It is incredibly useful for
 creating async actions, especially when combined with redux-thunk! */

function receiveLeftPhotos(photos) {
  return {
    type: RECEIVE_LEFT_PHOTOS,
    leftPhotos: photos,
    receivedAt: Date.now()
  };
}

function requestLeftPhotos() {
  return {
    type: REQUEST_LEFT_PHOTOS,
  };
}

function receiveRightPhotos(photos) {
  return {
    type: RECEIVE_RIGHT_PHOTOS,
    rightPhotos: photos,
    receivedAt: Date.now()
  };
}

function requestRightPhotos() {
  return {
    type: REQUEST_RIGHT_PHOTOS,
  };
}

function selectPhoto({
  selectedPhoto,
  selectedYear,
  selectedWeek,
  selectedCompartment,
  selectedPlantingCycle,
  selectedPhotos,
}) {
  return {
    type: SELECT_PHOTO,
    selectedPhoto,
    selectedYear,
    selectedWeek,
    selectedCompartment,
    selectedPlantingCycle,
    selectedPhotos,
    receivedAt: Date.now()
  };
}

function selectDeletedPhoto({ deletedPhoto, deletedPlantingCycle }) {
  return {
    type: SELECT_DELETED_PHOTO,
    deletedPhoto,
    deletedPlantingCycle,
    receivedAt: Date.now()
  };
}

function receivePhoto({ photo, leftPlantingCycle, rightPlantingCycle }) {
  return {
    type: RECEIVE_PHOTO,
    photo,
    leftPlantingCycle,
    rightPlantingCycle,
    receivedAt: Date.now()
  };
}

function requestPhoto() {
  return {
    type: REQUEST_PHOTO,
  };
}

function receiveDeletePhoto(photo) {
  return {
    type: RECEIVE_DELETE_PHOTO,
    photo,
    receivedAt: Date.now()
  };
}

function requestDeletePhoto() {
  return {
    type: REQUEST_DELETE_PHOTO,
  };
}

function receivePhotoCategories(photoCategories) {
  return {
    type: RECEIVE_PHOTO_CATEGORIES,
    photoCategories,
    receivedAt: Date.now()
  };
}

// Analytics Start
export function trackUploadPhotoClick() {
  const event = 'Planting Cycle - Click Upload Photo';

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event }));
  };
}

export function trackUploadPhotoSuccess() {
  const event = 'Planting Cycle - Submit Upload Photo';

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event }));
  };
}
// Analytics End

export function clearLeftPhotos() {
  return {
    type: CLEAR_LEFT_PHOTOS,
  };
}

export function clearRightPhotos() {
  return {
    type: CLEAR_RIGHT_PHOTOS,
  };
}

export function initLeftPhotos(photoCategories, plantingCycle, varieties, location) {
  return {
    type: INIT_LEFT_PHOTOS,
    photoCategories,
    plantingCycle,
    varieties,
    location,
    receivedAt: Date.now()
  };
}

export function initRightPhotos(photoCategories, plantingCycle, varieties, location) {
  return {
    type: INIT_RIGHT_PHOTOS,
    photoCategories,
    plantingCycle,
    varieties,
    location,
    receivedAt: Date.now()
  };
}

function requestPhotoCategories() {
  return {
    type: REQUEST_PHOTO_CATEGORIES,
  };
}

function receivePhotoComment(photoComment) {
  return {
    type: RECEIVE_PHOTO_COMMENT,
    photoComment,
    receivedAt: Date.now()
  };
}

function requestPhotoComment() {
  return {
    type: REQUEST_PHOTO_COMMENT,
  };
}

export function getLeftPhotos(id) {
  return async (dispatch) => {
    if (id) {
      await dispatch(requestLeftPhotos());

      const jwt = safeLocalStorage.getItem('jwt');
      const locale = safeLocalStorage.getItem('locale');
      const response = await fetch(`${window.API}/planting-cycles/${id}/photos?include=comments`, {
        headers: {
          Authorization: `JWT ${jwt}`,
          'X-Lang': locale,
        },
        credentials: 'include',
      });
      const { status } = response;
      if (Math.round(status / 100) === 2) {
        const { data, included = [] } = await response.json();

        data.forEach(((item) => {
          const photo = item;

          photo.attributes.comments = included.filter(comment => comment.type === 'PhotoComment' && comment.relationships.photo.data[0].id === item.id);
        }));

        await dispatch(receiveLeftPhotos(data));
      } else {
        await dispatch(receiveLeftPhotos());
      }
    }
  };
}

export function getRightPhotos(id) {
  return async (dispatch) => {
    if (id) {
      await dispatch(requestRightPhotos());

      const jwt = safeLocalStorage.getItem('jwt');
      const locale = safeLocalStorage.getItem('locale');
      const response = await fetch(`${window.API}/planting-cycles/${id}/photos?include=comments`, {
        headers: {
          Authorization: `JWT ${jwt}`,
          'X-Lang': locale,
        },
        credentials: 'include',
      });
      const { status } = response;
      if (Math.round(status / 100) === 2) {
        const { data, included = [] } = await response.json();

        data.forEach(((item) => {
          const photo = item;

          photo.attributes.comments = included.filter(comment => comment.type === 'PhotoComment' && comment.relationships.photo.data[0].id === item.id);
        }));

        await dispatch(receiveRightPhotos(data));
      } else {
        await dispatch(receiveRightPhotos());
      }
    }
  };
}

export function getPhotoCategories() {
  return async (dispatch) => {
    await dispatch(requestPhotoCategories());

    const jwt = safeLocalStorage.getItem('jwt');
    const locale = safeLocalStorage.getItem('locale');
    const response = await fetch(`${window.API}/photo-categories`, {
      headers: {
        Authorization: `JWT ${jwt}`,
        'X-Lang': locale,
      },
      credentials: 'include',
    });
    const { status } = response;
    if (Math.round(status / 100) === 2) {
      const { data } = await response.json();

      await dispatch(receivePhotoCategories(data));
    } else {
      await dispatch(receivePhotoCategories());
    }
  };
}

export function uploadPhoto({
  plantingCycle,
  year,
  week,
  category,
  description,
  file,
}) {
  return async (dispatch, getState) => {
    try {
      await dispatch(requestPhoto());

      const { selectedPhoto } = getState().photos;
      const { location, leftPlantingCycle, rightPlantingCycle } = getState().company;
      const jwt = safeLocalStorage.getItem('jwt');
      const locale = safeLocalStorage.getItem('locale');

      let photoId = null;

      if (!selectedPhoto.id) {
        const responseNewId = await fetch(`${window.API}/photos/new-id`, {
          headers: {
            Authorization: `JWT ${jwt}`,
            'X-Lang': locale,
          },
          method: 'GET',
          credentials: 'include',
        });

        if (Math.round(responseNewId.status / 100) === 2) {
          const { id: newPhotoId } = await responseNewId.json();

          photoId = newPhotoId;
        }
      } else {
        photoId = selectedPhoto.id;
      }

      const { id: plantingCycleId } = plantingCycle;

      const body = new FormData();
      body.append('photo', JSON.stringify({
        description: description || '',
        date: moment.tz(location.attributes.timezone)
          .year(year)
          .isoWeek(week)
          .isoWeekday(1)
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .set('millisecond', 0)
          .format('YYYY-MM-DD'),
        category
      }));
      body.append('file', file, 'photo.jpg');


      const response = await fetch(`${window.API}/planting-cycles/${plantingCycleId}/photos/${photoId}`, {
        headers: {
          Authorization: `JWT ${jwt}`,
          'X-Lang': locale,
        },
        method: 'POST',
        credentials: 'include',
        body
      });

      const { status } = response;

      if (Math.round(status / 100) === 2) {
        const { data, included } = await response.json();

        if (data && data[0]) {
          data.forEach(((item) => {
            const photo = item;

            photo.attributes.comments = [];
          }));

          if (included) {
            data.forEach(((item) => {
              const photo = item;

              photo.attributes.comments = included.filter(comment => comment.type === 'PhotoComment' && comment.relationships.photo.data[0].id === item.id);
            }));
          }

          dispatch(trackUploadPhotoSuccess());
          dispatch(receivePhoto({ photo: data[0], leftPlantingCycle, rightPlantingCycle }));
        }
      } else {
        const { text } = await response.json();
        dispatch(setToggleErrorDialog(true, text));
      }
    } catch (e) {
      dispatch(setToggleErrorDialog(true, e.toString()));
    }
  };
}

export function deletePhoto() {
  return async (dispatch, getState) => {
    try {
      await dispatch(requestDeletePhoto());

      const state = getState().photos;
      const { deletedPhoto: photo, deletedPlantingCycle: plantingCycle, selectedPhoto } = state;
      const jwt = safeLocalStorage.getItem('jwt');
      const locale = safeLocalStorage.getItem('locale');
      const { id: photoId } = photo;
      const { id: plantingCycleId } = plantingCycle;

      if (selectedPhoto && selectedPhoto.id && selectedPhoto.id === photoId) {
        await dispatch(setTogglePhotoDetailDialog(false));
      }

      const response = await fetch(`${window.API}/planting-cycles/${plantingCycleId}/photos/${photoId}`, {
        headers: {
          Authorization: `JWT ${jwt}`,
          'X-Lang': locale,
        },
        method: 'DELETE',
        credentials: 'include',
      });

      const { status } = response;

      if (Math.round(status / 100) === 2) {
        dispatch(receiveDeletePhoto(photo));
      } else {
        const { text } = await response.json();

        dispatch(setToggleErrorDialog(true, text));
      }
    } catch (e) {
      dispatch(setToggleErrorDialog(true, e.toString()));
    }
  };
}

export function createPhotoComment({ photoComment, photoId }) {
  return async (dispatch) => {
    try {
      await dispatch(requestPhotoComment());

      if (photoComment) {
        const data = { text: photoComment };
        const jwt = safeLocalStorage.getItem('jwt');
        const locale = safeLocalStorage.getItem('locale');

        const responseNewId = await fetch(`${window.API}/photo-comments/new-id`, {
          headers: {
            Authorization: `JWT ${jwt}`,
            'X-Lang': locale,
          },
          method: 'GET',
          credentials: 'include',
        });


        if (Math.round(responseNewId.status / 100) === 2) {
          const { id: commentId } = await responseNewId.json();

          const headers = new Headers();

          headers.append('Content-Type', 'application/json');
          headers.append('Authorization', `JWT ${jwt}`);
          headers.append('X-Lang', locale);

          if (commentId) {
            const response = await fetch(`${window.API}/photos/${photoId}/comments/${commentId}`, {
              headers,
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify(data)
            });

            const { status } = response;
            if (Math.round(status / 100) === 2) {
              const { data: comments } = await response.json();

              if (comments && comments[0]) {
                return dispatch(receivePhotoComment(comments[0]));
              }
            }
          }
        }
      }

      return dispatch(receivePhotoComment());
    } catch (e) {
      return dispatch(receivePhotoComment());
    }
  };
}

export function setSelectedPhoto(selectedPhoto, withoutTrack = false) {
  return async (dispatch) => {
    // Отправка события метрики только если клик по пустой ячейке с фото
    if (!withoutTrack && !get(selectedPhoto, 'selectedPhoto.id')) {
      dispatch(trackUploadPhotoClick());
    }

    await dispatch(selectPhoto(selectedPhoto));
  };
}

export function setDeletedPhoto({ deletedPhoto, deletedPlantingCycle }) {
  return async (dispatch) => {
    await dispatch(selectDeletedPhoto({ deletedPhoto, deletedPlantingCycle }));
  };
}
