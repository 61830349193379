import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';

import { get, cloneDeep, isEqual } from 'lodash';
import classnames from 'classnames';
import moment from 'moment-timezone';

import LeafButton from '../LeafButton';
import NumberInput from '../NumberInput';
import CircleLoader from '../CircleLoader';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as ErrorIcon } from './assets/edit_error.svg';
import styles from './TableCellEditDialog.module.css';

const cropsMetricsSteps = {
  count: 1,
  centimeters: 0.01,
  millimeters: 0.01,
  orderNumber: 1,
  kilogram: 0.01,
  gram: 0.01,
  score: 1,
};


export default class TableCellEditDialog extends Component {
  static propTypes = {
    onDialogClose: PropTypes.func.isRequired,
    compartment: PropTypes.object.isRequired,
    plantingCycle: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    varieties: PropTypes.array.isRequired,
    weeklyData: PropTypes.array.isRequired,
    date: PropTypes.object.isRequired,
    metric: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
  };

  state = {
    error: false,
    errors: [],
    disabled: true,
    date: this.props.date || moment.tz(this.props.location.attributes.timezone),
    dailyValues: cloneDeep(this.props.weeklyData.find(item => moment(this.props.date || moment.tz(this.props.location.attributes.timezone)).isSame(item.periodStart, 'day')).rows || []),
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { weeklyData: nextWeeklyData } = nextProps;
    const { weeklyData: oldWeeklyData } = this.props;

    if (!isEqual(nextWeeklyData, oldWeeklyData)) {
      this.setState({
        dailyValues: cloneDeep(nextWeeklyData.find(item => moment(this.props.date || moment.tz(this.props.location.attributes.timezone)).isSame(item.periodStart, 'day')).rows || []),
      });
    }
  }

  handlerDialogClose = (isClose) => {
    const {
      onDialogClose,
      plantingCycle,
      compartment,
      metric,
    } = this.props;

    const {
      date,
      dailyValues,
    } = this.state;

    if (onDialogClose) {
      onDialogClose({
        date,
        plantingCycle,
        compartment,
        dailyValues,
        metric,
        isClose,
      });
    }
  };

  handlerDialogChange = ({ value, dayDate, isError }) => {
    if (dayDate.isSameOrBefore(moment())) {
      const { metric } = this.props;
      const { errors, dailyValues } = this.state;
      const copyDailyValues = cloneDeep(dailyValues);
      const dayValue = copyDailyValues.find(item => moment(item.periodStart).format('YYYY-MM-DD') === dayDate.format('YYYY-MM-DD'));

      dayValue.metricValues[metric.id] = value;

      const errorsResult = cloneDeep(errors);

      const errorIndex = errorsResult.findIndex(item => item === dayDate.format('YYYY-MM-DD'));

      if (isError) {
        if (errorIndex === -1) {
          errorsResult.push(dayDate.format('YYYY-MM-DD'));
        }
      } else if (errorIndex > -1) {
        errorsResult.splice(errorIndex, 1);
      }

      const valueItems = copyDailyValues.map(dayValueItem => dayValueItem.metricValues && dayValueItem.metricValues[metric.id]).filter(item => (typeof item !== 'undefined') && item !== null && item !== '');

      this.setState({
        error: !!errorsResult.length || !valueItems.length,
        errors: errorsResult,
        disabled: false,
        dailyValues: copyDailyValues,
      });
    }
  };

  handlerShadowClick = (e) => {
    if (e.target.classList && e.target.classList.contains(styles.dialog)) {
      this.handlerDialogClose(true);
    }
  };

  render() {
    const {
      weeklyData,
      compartment,
      plantingCycle,
      varieties,
      metric,
      intl
    } = this.props;

    const {
      error,
      errors,
      disabled,
      date,
      dailyValues,
    } = this.state;

    const { formatMessage, locale } = intl;
    const week = [];

    for (let i = 0; i < 7; i++) {
      week.push(moment(date).isoWeekday(1).add(i, 'days'));
    }

    if (plantingCycle) {
      const findedVariety = varieties.find((item) => {
        if (plantingCycle) {
          const { id: itemId } = item;
          const { id: varietyId } = plantingCycle.relationships.variety.data[0];
          return plantingCycle && itemId === varietyId;
        }
        return false;
      });

      const weeklyDataItem = weeklyData.find(item => moment(date).isSame(item.periodStart, 'day'));

      const { isFetching } = weeklyDataItem;

      const varietyName = get(findedVariety, `attributes.mlName[${locale}]`) || get(findedVariety, 'attributes.mlName.en');

      const selectedPlantingCycleName = findedVariety ? `${formatMessage({ id: `plantingCycles.species.${findedVariety.attributes.species}` })} ${varietyName}` : '';

      const shadowProps = {
        onClick: this.handlerShadowClick,
      };

      return (
        <div className={classnames(styles.dialog, 'table-cell-edit-dialog')} {...shadowProps}>
          <div className={styles.dialogWrapper}>
            <CloseIcon className={styles.dialogClose} onClick={() => this.handlerDialogClose(true)} />

            <div className={styles.dialogHeader}>
              <FormattedMessage id='tables.editDialog.title' />
            </div>

            <div className={styles.dialogBody}>
              <div className={styles.compartmentPlantingCycleInfo}>
                {compartment.attributes.name} / {selectedPlantingCycleName.replace(/(\(.*\))/, '')}
              </div>
              <div className={styles.category}>
                <div className={styles.categoryTitle}>
                  {<FormattedMessage id='photos.week.one' />} {moment(date).isoWeek()}
                </div>
                <div className={styles.categoryBody}>
                  {
                    week.map((dayDate) => {
                      const dayValue = dailyValues.find(item => moment(item.periodStart).format('YYYY-MM-DD') === dayDate.format('YYYY-MM-DD'));

                      return (
                        <div
                          key={`value-editor-${btoa(dayDate.format('YYYY-MM-DD'))}`}
                          className={classnames(styles.value)}
                        >
                          <div className={classnames(styles.valueWrapper, { [styles.disabled]: dayDate.isAfter(moment()) })}>
                            <div className={styles.valueTitleWrapper}>
                              <div className={styles.valueTitle}>
                                <div className={styles.valueTitleFirst}>{dayDate.format('dddd')}</div>
                                <div className={styles.valueTitleSecond}>{dayDate.format('D MMMM')}</div>
                              </div>
                            </div>
                            <div className={styles.valueEditorCompartment}>
                              <div className={styles.valueEditorWrapper}>
                                <NumberInput
                                  isError={errors.findIndex(item => item === dayDate.format('YYYY-MM-DD')) > -1}
                                  disabled={dayDate.isAfter(moment())}
                                  value={dayValue ? dayValue.metricValues[metric.id] : ''}
                                  step={cropsMetricsSteps[metric.attributes.cunit] || 0.01}
                                  onChange={(value, isError) => this.handlerDialogChange({ value, dayDate, isError })}
                                  min={metric.attributes.validationRangeMin}
                                  max={metric.attributes.validationRangeMax}
                                />
                                <ErrorIcon className={styles.valueErrorIcon} />
                              </div>
                              <div className={styles.valueDescription}>
                                <FormattedMessage id={`cunits.mini.${metric.attributes.cunit}`} />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>

            <div className={styles.dialogFooter}>
              <div className={styles.descriptions}>
                {error && (
                  <div className={styles.descriptionError}>
                    <ErrorIcon className={styles.icon} />
                    <FormattedMessage id='crops.error' />
                  </div>
                )}
                {/* <div className={styles.descriptionWarning}><WarningIcon className={styles.icon} /> */}
                {/* Be careful with this value */}
                {/* </div> */}
              </div>
              <LeafButton
                disabled={error || disabled}
                onClick={() => this.handlerDialogClose(false)}
                className={styles.submit}
                title={<FormattedMessage id='tables.submit' />}
              />
            </div>


            {isFetching && (
              <CircleLoader className={styles.circleLoader} iconClassName={classnames(styles.circleLoaderIcon)} />
            )}
          </div>
        </div>
      );
    }

    return null;
  }
}
