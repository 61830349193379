import React, {
  useEffect, useRef
} from 'react';

import parse from 'color-parse';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash/lang';

import useMouseDrag from 'hooks/useMouseDrag';
import { hslToRgb, hsvToRgb, rgbToHsv } from 'helpers/colors';

// import { line } from 'd3';
// import interpolateLine from 'components/LinesGraph/components/LineChart/interpolate';
// import Spline from './Spline';

import styles from './index.module.css';

const ColorToneSelector = ({
  baseColor,
  initValue,
  onChange
}) => {
  const sliderRef = useRef(null);
  const containerRef = useRef(null);

  const {
    // alpha,
    values: baseRgb
  } = parse(baseColor);

  const baseHsv = rgbToHsv(baseRgb[0], baseRgb[1], baseRgb[2]);
  const h = baseHsv[0];
  const baseRgbColor = hslToRgb(baseHsv[0], 1, 0.5);

  const {
    alpha,
    values: color
  } = parse(initValue);

  const hsv = rgbToHsv(color[0], color[1], color[2]);

  const s = hsv[1];
  const v = (1 - hsv[2]);

  const maxSliderX = (containerRef?.current?.getBoundingClientRect()?.width || 0);
  const maxSliderY = (containerRef?.current?.getBoundingClientRect()?.height || 0);

  const [{
    translation,
    isDragging
  }] = useMouseDrag(containerRef, {
    x: s * maxSliderX - 8,
    y: v * maxSliderY - 8,
  });

  useEffect(() => {
    if (isNumber(translation?.x) && isNumber(translation?.y) && maxSliderX && isDragging) {
      if (onChange) {
        const sVal = Math.min(Math.max(((s + translation.x) / maxSliderX), 0), 1);
        const vVal = Math.min(Math.max(((v + translation.y) / maxSliderY), 0), 1);
        const rgb = hsvToRgb(h, sVal, 1 - vVal);
        onChange(`rgba(${Math.round(rgb[0])}, ${Math.round(rgb[1])}, ${Math.round(rgb[2])}, ${alpha.toFixed(2)})`, [h, sVal, 1 - vVal]);
      }
    }
  }, [ // eslint-disable-line
    translation,
    isDragging,
    onChange,
    alpha,
  ]);

  /*
  const spline = {
    keyColors: ['#E4F7FB', '#1DBADF', '#0A94CA']
  };

  const {
    xs,
    ys
  } = useMemo(() => {
    function parseColorXY(stringColor) {
      const {
        values
      } = parse(stringColor);
      const hsvColor = rgbToHsv(values[0], values[1], values[2]);
      return {
        x: Math.max(hsvColor[1] * maxSliderX - 8, 0),
        y: Math.max((1 - hsvColor[2]) * maxSliderY - 8, 0)
      };
    }

    return spline.keyColors.reduce((acc, stringColor) => {
      const {
        x, y
      } = parseColorXY(stringColor);
      return {
        xs: [...acc.xs, x],
        ys: [...acc.ys, y]
      };
    }, { xs: [], ys: [] });
  }, [maxSliderX, maxSliderY]);

  // const xs = [1, 2, 3, 4, 5];
  // const ys = [9, 3, 6, 2, 4];

  // new a Spline object
  const splineObj = new Spline(xs, ys);

  // debugger;
  // console.log(xs, ys);

  const lineDef = useMemo(() => line()
      .curve(interpolateLine('basis'))
      .x((colorString) => {
        const {
          values
        } = parse(colorString);
        const hsvColor = rgbToHsv(values[0], values[1], values[2]);
        return Math.max(hsvColor[1] * maxSliderX - 8, 0);
      })
      .y((colorString) => {
        const {
          values
        } = parse(colorString);
        const hsvColor = rgbToHsv(values[0], values[1], values[2]);
        return Math.max((1 - hsvColor[2]) * maxSliderY - 8, 0);
      }), [maxSliderX, maxSliderY]);

  const lineData = lineDef(spline.keyColors);
  */
  return (
    <div
      className={styles.wrapper}
    >
      <div
        ref={containerRef}
        className={styles.container}
        style={{
          background: `rgb(${baseRgbColor[0]}, ${baseRgbColor[1]}, ${baseRgbColor[2]})`
        }}
      >
        <div className={styles.lighting}>
          <div className={styles.darkness}>
            <div
              ref={sliderRef}
              className={styles.slider}
              style={{
                left: Math.max(s * maxSliderX - 14, 0),
                top: Math.max(v * maxSliderY - 14, 0),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ColorToneSelector.propTypes = {
  initValue: PropTypes.string,
  baseColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ColorToneSelector.defaultProps = {
  initValue: 'rgba(255,255,255,1)',
  baseColor: 'rgba(255,255,255,1)',
};

/*
            {spline?.keyColors?.map((colorString) => {
              const {
                values
              } = parse(colorString);
              const hsvColor = rgbToHsv(values[0], values[1], values[2]);
              return (
                <div
                  className={styles.slider}
                  style={{
                    left: Math.max(hsvColor[1] * maxSliderX - 14, 0),
                    top: Math.max((1 - hsvColor[2]) * maxSliderY - 14, 0),
                  }}
                />
              );
            })}

            {splineObj?.xs?.length && splineObj?.xs?.map((item) => {
              const atY = splineObj.at(item);
              return (
                <div
                  className={styles.slider}
                  style={{
                    left: Math.max(item, 0),
                    top: Math.max(atY, 0),
                  }}
                />
              );
            })}


       {lineData && (
        <svg style={{
          width: '164px',
          height: '108px',
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none'
        }}
        >
          <g>
            <path d={lineData} stroke='red' fill='none' />
          </g>
        </svg>
      )}

          {Array(100).fill(0).map((val, index) => {
              const xMin = splineObj?.xs[0];
              const xMax = splineObj?.xs[splineObj?.xs?.length - 1];
              const item = xMin + ((index + 1) / 100) * xMax;
              const atY = splineObj.at(item);
              return (
                <div
                  className={styles.slider}
                  style={{
                    left: Math.max(item - 8, 0),
                    top: Math.max(atY - 8, 0),
                  }}
                />
              );
            })}
*/

export default ColorToneSelector;
