import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { intlShape, FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import moment from 'moment-timezone';
import { get, throttle } from 'lodash';
import classnames from 'classnames';

import DeleteIcon from 'components/Icons/Delete';
import AddToCompareIcon from 'components/Icons/AddToCompareIcon';
import RemoveFromCompareIcon from 'components/Icons/RemoveFromCompareIcon';

import numbersRounding from '../../../../helpers/numbersRounding';
import normalizeCycle from '../../../../helpers/normalizeCycle';
import storageWrapper from '../../../../helpers/storageWrapper';
import BigButton from '../../../BigButton';
import CycleCard from '../../../CycleCard';
import CycleProgress from '../CycleProgress';
import DropdownMenu from '../../../DropdownMenu';
import DefaultDialog from '../../../DefaultDialog';

import styles from './CycleTable.module.css';
import tooltipStyles from '../../../Tooltip/index.module.css';

import { ReactComponent as EditIcon } from './assets/edit.svg';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { ReactComponent as HarvestIcon } from './assets/harvest.svg';


const safeLocalStorage = storageWrapper.get('localStorage');


class CycleTable extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    updateCropsSorting: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    visibilityFilter: PropTypes.string.isRequired,
    trackEditPlantingCycleClick: PropTypes.func.isRequired,
    trackAddHarvestClick: PropTypes.func.isRequired,
    requestDeletePlantingCycle: PropTypes.func.isRequired,
    cropsSorting: PropTypes.object,
    match: PropTypes.object.isRequired,
    visibleCycles: PropTypes.array,
    isAddPlantingCycleAvailable: PropTypes.bool.isRequired,
    isAddHarvestAvailable: PropTypes.bool.isRequired,
    cyclesToCompare: PropTypes.array,
    addCycleToComparison: PropTypes.func.isRequired,
    removeCycleFromComparison: PropTypes.func.isRequired,
    isCropCompareAvailable: PropTypes.bool,
  };

  static defaultProps = {
    visibleCycles: [],
    cropsSorting: null,
    cyclesToCompare: [],
    isCropCompareAvailable: false,
  };

  state = {
    isShowDeleteConfirmation: false,
    deletedPlantingCycleId: null,
    deleteTooltipLeft: -110,
    deleteTooltipTriangleLeft: 120,
    deleteTooltipPosition: 'bottom',
  };

  tableRef = React.createRef();

  handlerResize = throttle(() => {
    if (this.tableRef && this.tableRef.current) {
      const bound = this.tableRef.current.getBoundingClientRect();
      const deleteTooltipDefaultRightSpace = 120 - 32;

      this.setState({
        deleteTooltipLeft: (window.innerWidth - bound.width) / 2 < deleteTooltipDefaultRightSpace ? -250 + (((window.innerWidth - bound.width) / 2) + 24) : -110,
        deleteTooltipTriangleLeft: (window.innerWidth - bound.width) / 2 < deleteTooltipDefaultRightSpace ? (250 - (((window.innerWidth - bound.width) / 2) + 24)) + 10 : 120,
      });
    }
  }, 300);

  componentDidMount() {
    window.addEventListener('resize', this.handlerResize);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();

    // TODO: выпилить отсюда resize, проверить нужны ли вообще тут размеры и если да, то использовать sizeMe
    // setTimeout(() => {
    //   this.handlerResize();
    // }, 100);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResize);
  }

  handlerEditPlantingCycle = () => {
    const { trackEditPlantingCycleClick } = this.props;

    trackEditPlantingCycleClick('list');
  };

  handlerDeletePlantingCycle = (id) => {
    this.setState({ isShowDeleteConfirmation: true, deletedPlantingCycleId: id });
  };

  handlerDeleteConfirmationOkClick = () => {
    const { requestDeletePlantingCycle, history, match } = this.props;
    const { deletedPlantingCycleId } = this.state;

    requestDeletePlantingCycle({ plantingCycleId: deletedPlantingCycleId, history, match });

    this.setState({ isShowDeleteConfirmation: false, deletedPlantingCycleId: null });
  };

  handlerDeleteConfirmationCancelClick = () => {
    this.setState({ isShowDeleteConfirmation: false, deletedPlantingCycleId: null });
  };

  handlerOpenHarvestDialog = (cycleId) => {
    const { history, match, trackAddHarvestClick } = this.props;
    const fromPage = encodeURIComponent(`/${match.params.organizationSlug}/crops`);
    const savedPeriodType = safeLocalStorage.getItem('periodType');

    trackAddHarvestClick();

    history.push(`/${match.params.organizationSlug}/crops/${cycleId}/add-harvest?date=${moment().format('YYYY-MM-DD')}&periodType=${savedPeriodType || 'week'}&fromPage=${fromPage}`);
  };

  handleCropsSorting = (type) => {
    const { updateCropsSorting, cropsSorting } = this.props;

    const isOldSort = type === get(cropsSorting, 'type');
    const currentSortDirection = get(cropsSorting, 'direction');

    let sorting = {};

    if (currentSortDirection === 'up' && isOldSort) {
      sorting = { type, direction: 'down' };
    } else if (!isOldSort) {
      sorting = { type, direction: 'up' };
    } else {
      sorting = null;
    }

    updateCropsSorting(sorting);
  };

  handlerAddToCompare = (cycleId) => {
    const { addCycleToComparison } = this.props;

    addCycleToComparison({ cycleId, withSuccessAction: true });
  };

  handlerRemoveFromCompare = (cycleId) => {
    const { removeCycleFromComparison } = this.props;

    removeCycleFromComparison({ cycleId, withSuccessAction: true });
  };


  renderHeaders = () => {
    const { intl: { formatMessage }, cropsSorting } = this.props;
    const activeSort = get(cropsSorting, 'type');
    const sortDirection = get(cropsSorting, 'direction');

    const headers = [
      { label: formatMessage({ id: 'cycle.table.cycle' }), value: 'cycle' },
      { label: formatMessage({ id: 'cycle.table.compartment' }), value: 'compartment' },
      { label: formatMessage({ id: 'cycle.table.species' }), value: 'species' },
      { label: formatMessage({ id: 'cycle.table.week' }), value: 'week', className: 'thRight' },
      { label: formatMessage({ id: 'cycle.table.harvested' }), value: 'harvested' },
      { label: formatMessage({ id: 'cycle.table.plan' }), value: 'plan' },
    ];

    return headers.map(item => (
      <th
        key={`tableHeader-${item.value}`}
        onClick={() => this.handleCropsSorting(item.value)}
        className={classnames({
          [styles.activeSort]: activeSort === item.value,
          [styles[item.className]]: item.className,
        })}
        scope='col'
      >
        <div className={styles.thContent}>
          {item.label}
          <div
            className={classnames(styles.arrow, {
              [styles.sortDirectionDown]: sortDirection === 'down' && activeSort === item.value
            })}
          >
            <ArrowIcon />
          </div>
        </div>
      </th>
    ));
  };

  renderDropdownCompareIcon = (isMobile, icon) => {
    if (isMobile) {
      return (
        <div className={styles.dropdownIcon}>{icon}</div>
      );
    }

    return icon;
  };

  renderCompareButton = (formatMessage, isCropCompareAvailable, isMobileMenu, id) => {
    if (!isCropCompareAvailable) {
      return null;
    }

    const { cyclesToCompare } = this.props;

    const isCycleAlreadyAdded = cyclesToCompare.includes(id);

    if (isCycleAlreadyAdded) {
      const additionalProps = isMobileMenu ?
        {
          title: formatMessage({ id: 'benchmarking.removeToComparison' }),
          className: styles.dropdownItem,
        }
        :
        {
          tooltip: formatMessage({ id: 'benchmarking.removeToComparison' }),
          className: styles.compareButton,
        };

      return (
        <BigButton
          onClick={() => this.handlerRemoveFromCompare(id)}
          icon={this.renderDropdownCompareIcon(isMobileMenu, <RemoveFromCompareIcon className={styles.compareIcon} />)}
          category='icon'
          // title={<FormattedMessage id='benchmarking.removeToComparison' />}
          {...additionalProps}
        />
      );
    }

    const additionalProps = isMobileMenu ?
      {
        title: formatMessage({ id: 'benchmarking.addToComparison' }),
        className: styles.dropdownItem,
      }
      :
      {
        tooltip: formatMessage({ id: 'benchmarking.addToComparison' }),
        className: styles.compareButton,
      };

    return (
      <BigButton
        onClick={() => this.handlerAddToCompare(id)}
        icon={this.renderDropdownCompareIcon(isMobileMenu, <AddToCompareIcon className={styles.compareIcon} />)}
        category='icon'
        // title={<FormattedMessage id='benchmarking.addToComparison' />}
        {...additionalProps}
      />
    );
  };

  renderDropdownContent = (formatMessage, isCropCompareAvailable, id, isAddHarvestDisabled, isAddHarvestAvailable, isAddPlantingCycleAvailable) => (
    <div className={styles.dropdownContent}>
      {isAddHarvestAvailable && (
        <BigButton
          className={classnames(styles.dropdownItem, {
            [styles.itemDisabled]: isAddHarvestDisabled,
          })}
          onClick={isAddHarvestDisabled ? () => false : () => this.handlerOpenHarvestDialog(id)}
          icon={<div className={styles.dropdownIcon}><HarvestIcon className={styles.harvestIcon} /></div>}
          category='icon'
          title={<FormattedMessage id='harvest.addHarvest' />}
        />
      )}
      {isAddPlantingCycleAvailable && (
        <BigButton
          href={`/${this.props.match.params.organizationSlug}/crops/${id}/edit`}
          onClick={this.handlerEditPlantingCycle}
          className={classnames(styles.dropdownItem)}
          icon={<div className={styles.dropdownIcon}><EditIcon className={styles.editIcon} /></div>}
          category='icon'
          title={<FormattedMessage id='harvest.edit' />}
        />
      )}
      {this.renderCompareButton(formatMessage, isCropCompareAvailable, true, id)}
      {isAddPlantingCycleAvailable && (
        <BigButton
          className={classnames(styles.dropdownItem)}
          onClick={() => this.handlerDeletePlantingCycle(id)}
          icon={<div className={styles.dropdownIcon}><DeleteIcon className={styles.deleteIcon} /></div>}
          category='icon'
          title={<FormattedMessage id='plantingCycles.delete' />}
        />
      )}
    </div>
  );

  render() {
    const {
      visibleCycles, visibilityFilter, intl, trackEditPlantingCycleClick, match,
      isAddPlantingCycleAvailable,
      isAddHarvestAvailable,
      requestDeletePlantingCycle,
      cyclesToCompare,
      removeCycleFromComparison,
      addCycleToComparison,
      isCropCompareAvailable,
    } = this.props;

    const {
      isShowDeleteConfirmation,
      deletedPlantingCycleId,
      deleteTooltipLeft,
      deleteTooltipTriangleLeft,
      deleteTooltipPosition,
    } = this.state;

    const { formatMessage, locale } = intl;
    const tooltipId = 'CycleProgressTooltip';

    // adaptive table example https://codepen.io/AllThingsSmitty/pen/MyqmdM
    return (
      <div>
        <table className={styles.table} ref={this.tableRef}>
          <thead>
            <tr>
              {this.renderHeaders()}
            </tr>
          </thead>
          <tbody>
            {visibleCycles && visibleCycles.map((item) => {
              // TODO: Вынести получение этих параметров в какую-нибудь денормализацию
              const cycle = normalizeCycle(item, locale);
              const {
                id,
                species,
                compartmentName,
                cycleWeek,
                harvestValue,
                totalGoalValue,
                startDate,
                endDate,
                valueUnits,
                activeStatus,
              } = cycle;

              const varietySpecies = formatMessage({ id: `plantingCycles.species.${species}` });
              const fullName = item?.fullName;

              const valueUnitsText = valueUnits ? formatMessage({ id: `cunits.mini.${valueUnits}` }) : '';
              // const totalGoalUnitsText = totalGoalUnits ? formatMessage({ id: `cunits.mini.${totalGoalUnits}` }) : '';
              const harvestPlan = harvestValue && totalGoalValue ?
                numbersRounding(harvestValue / (totalGoalValue / 100), 'percentage') : undefined;

              const harvestValueText = harvestValue ? `${harvestValue} ${valueUnitsText}` : `0.0 ${valueUnitsText}`;

              const now = moment();
              const startDateMoment = moment(item.cycle.startDate, 'YYYY-MM-DD');
              const isNotSpecified = !item.cycle.endDate && startDateMoment.isAfter(now);

              const isAddHarvestDisabled = !cycleWeek;

              const isWithoutButtons = !isAddHarvestAvailable && !isAddPlantingCycleAvailable && !isCropCompareAvailable;

              return (
                <tr className={styles.cycleRow} key={`cycle-${id}`}>
                  <td data-label='Cycle' className={styles.tdPhoneVisible}>
                    <CycleCard
                      intl={intl}
                      cycleId={id}
                      href={`/${match.params.organizationSlug}/crops/${id}`}
                      fullName={fullName}
                      isNotSpecified={isNotSpecified}
                      species={species}
                      startDate={startDate || undefined}
                      endDate={endDate || undefined}
                      completed={(activeStatus === 'complete' && visibilityFilter === 'SHOW_ALL') || false}
                      compartmentName={compartmentName}
                      percent={harvestPlan}
                      cycleWeek={cycleWeek}
                      harvestValueText={harvestValueText}
                      trackEditPlantingCycleClick={trackEditPlantingCycleClick}
                      isAddPlantingCycleAvailable={isAddPlantingCycleAvailable}
                      requestDeletePlantingCycle={requestDeletePlantingCycle}
                      cyclesToCompare={cyclesToCompare}
                      removeCycleFromComparison={removeCycleFromComparison}
                      addCycleToComparison={addCycleToComparison}
                      isCropCompareAvailable={isCropCompareAvailable}
                    />
                    {(isAddPlantingCycleAvailable || isAddHarvestAvailable || isCropCompareAvailable) && (
                      <div className={styles.more}>
                        <DropdownMenu>
                          {this.renderDropdownContent(formatMessage, isCropCompareAvailable, id, isAddHarvestDisabled, isAddHarvestAvailable, isAddPlantingCycleAvailable)}
                        </DropdownMenu>
                      </div>
                    )}
                  </td>
                  <td>{compartmentName}</td>
                  <td className={styles.tdSpecies}>{varietySpecies || '–'}</td>
                  <td className={styles.tdCycleWeek}>{cycleWeek || '–'}</td>
                  <td>
                    <div className={styles.values}>
                      <div className={styles.valuesValue}>
                        {harvestValueText}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={classnames(styles.otherWrapper, { [styles.focused]: isShowDeleteConfirmation && id === deletedPlantingCycleId })}>
                      <div className={styles.commandWrapper}>
                        {isAddHarvestAvailable && (
                          <BigButton
                            className={classnames(styles.addHarvest, {
                              [styles.addHarvestDisabled]: isAddHarvestDisabled,
                            })}
                            onClick={isAddHarvestDisabled ? () => false : () => this.handlerOpenHarvestDialog(id)}
                            icon={<HarvestIcon className={styles.harvestIcon} />}
                            category='icon'
                            tooltip={isAddHarvestDisabled ? formatMessage({ id: 'harvest.harvestCannotBeAdded' }) : formatMessage({ id: 'harvest.addHarvest' })}
                          />
                        )}
                        {isAddPlantingCycleAvailable && (
                          <BigButton
                            href={`/${match.params.organizationSlug}/crops/${id}/edit`}
                            onClick={this.handlerEditPlantingCycle}
                            className={classnames(styles.edit)}
                            icon={<EditIcon className={styles.editIcon} />}
                            category='icon'
                            tooltip={formatMessage({ id: 'plantingCycles.editPlantingCycle' })}
                          />
                        )}
                        {this.renderCompareButton(formatMessage, isCropCompareAvailable, false, id)}
                        {isAddPlantingCycleAvailable && (
                          <div className={styles.deleteWrapper}>
                            <BigButton
                              className={classnames(styles.delete)}
                              onClick={(e) => {
                                const rect = e.target.getBoundingClientRect();

                                const isBottom = rect.top + window.scrollY + 150 < (window.innerHeight + window.scrollY) - 96;

                                this.setState({
                                  deleteTooltipPosition: isBottom ? 'bottom' : 'top',
                                }, () => {
                                  this.handlerDeletePlantingCycle(id);
                                });
                              }}
                              icon={<DeleteIcon className={styles.deleteIcon} />}
                              category='icon'
                              tooltip={formatMessage({ id: 'plantingCycles.deletePlantingCycle' })}
                            />

                            {isShowDeleteConfirmation && (deletedPlantingCycleId === id) ? (
                              <div className={classnames(styles.deleteTooltip, { [styles.bottom]: deleteTooltipPosition === 'bottom', [styles.top]: deleteTooltipPosition === 'top' })} style={{ left: deleteTooltipLeft }}>
                                <div className={styles.deleteTooltipTriangle} style={{ left: deleteTooltipTriangleLeft }} />
                                <div className={styles.deleteTooltipTitle}>
                                  {formatMessage({ id: 'cycle.deleteVerify' })}
                                </div>
                                <div className={styles.deleteTooltipActions}>
                                  <div className={styles.deleteTooltipActionCancel} role='button' tabIndex='0' onClick={this.handlerDeleteConfirmationCancelClick}>{formatMessage({ id: 'button.cancel' })}</div>
                                  <div className={styles.deleteTooltipActionOk} role='button' tabIndex='0' onClick={this.handlerDeleteConfirmationOkClick}>{formatMessage({ id: 'button.confirmDelete' })}</div>
                                </div>
                              </div>
                            ) : null}

                          </div>
                        )}
                      </div>
                      <div
                        className={classnames(
                          styles.progressWrapper,
                          {
                            [styles.withoutButtons]: isWithoutButtons
                          }
                        )}
                      >
                        <CycleProgress
                          intl={intl}
                          percent={harvestPlan}
                          harvestValue={harvestValue}
                          totalGoalValue={totalGoalValue}
                          tooltipId={tooltipId}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {isShowDeleteConfirmation ? (
          <DefaultDialog
            title={<FormattedMessage id='cycle.delete' />}
            onClose={this.handlerDeleteConfirmationCancelClick}
            className={styles.dialog}
            wrapperClassName={styles.wrapper}
            hideCloseIcon={window.innerWidth < 960}
          >
            <div>
              <p className={styles.dialogText}>
                <FormattedMessage id='cycle.deleteVerify' />
              </p>
              <div className={styles.buttons}>
                <BigButton
                  className={styles.cancel}
                  onClick={this.handlerDeleteConfirmationCancelClick}
                  title={<FormattedMessage id='button.cancel' />}
                />
                <BigButton
                  className={styles.confirm}
                  onClick={this.handlerDeleteConfirmationOkClick}
                  title={<FormattedMessage id='button.confirmDelete' />}
                  theme='error'
                />
              </div>
            </div>
          </DefaultDialog>
        ) : null}

        <ReactTooltip
          className={tooltipStyles.smallTooltip}
          id={tooltipId}
          effect='solid'
          html
        />
      </div>
    );
  }
}

export default withRouter(CycleTable);
