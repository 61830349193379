import { useEffect } from 'react';

import { axisLeft, axisRight, select } from 'd3';

import { getTicksRange } from 'helpers/getTicks';
import { formatTicksByMask } from 'helpers/formatTicksByMask/formatTicksByMask';

function useYAxis(groupRef, options) {
  const {
    chartParams: {
      m, w, y, rY
    },
    yType,
    axisLabels: { y: label },
    yTicks,
    grid,
    tickTimeDisplayFormat,
    yAxisOrientRight,
    yDomainRange,
    isRightAxis,
    customGetTicksRange,
    isDisabled
  } = options;
  useEffect(() => {
    const group = groupRef?.current ? select(groupRef.current) : null;
    group.selectAll('*').remove();
    if (group && !isDisabled) {
      const axis = yAxisOrientRight ? axisRight(isRightAxis ? rY : y) : axisLeft(isRightAxis ? rY : y);

      if (yType === 'time' && tickTimeDisplayFormat) {
        axis
          .tickFormat(tickTimeDisplayFormat);
      }
      if (grid) {
        axis
          .tickSize(-w, 6)
          .tickPadding(12);
      } else {
        axis
          .tickPadding(10);
      }

      if (yTicks) {
        axis
          .tickValues(customGetTicksRange ? customGetTicksRange(yDomainRange[0], yDomainRange[1], yTicks) :
            getTicksRange(yDomainRange[0], yDomainRange[1], yTicks))
          .tickFormat(formatTicksByMask);
      }

      group.attr(
        'transform',
        (yAxisOrientRight)
          ? `translate(${w}, 0)`
          : 'translate(0, 0)'
      );

      group
        .call(axis);

      if (label) {
        group.selectAll('text').remove();
        group
          .append('text')
          .attr('class', 'label')
          .attr('transform', 'rotate(-90)')
          .attr('x', 0)
          .attr(
            'y',
            (yAxisOrientRight)
              ? -20 + m.right
              : 0 - m.left
          )
          .attr('dy', '.9em')
          .style('text-anchor', 'end')
          .text(label);
      }
    }
  }, [
    groupRef,
    m, w, y, rY,
    yType,
    label,
    yTicks,
    grid,
    tickTimeDisplayFormat,
    yAxisOrientRight,
    yDomainRange,
    isRightAxis,
    customGetTicksRange,
    isDisabled
  ]);
}

export default useYAxis;
