import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { isNumber } from 'lodash';

import Paper from 'components/Paper/Paper';
import numbersFormatting from 'helpers/numbersFormatting';
import Legend from 'components/Legend';
import TextInfoTooltip from 'components/TextInfoTooltip/TextInfoTooltip';

import styles from './HarvestForecastChartWrapper.module.css';

const HarvestForecastChartWrapper = ({
  intl,
  title,
  children,
  legend,
  indicators,
  tooltipText
}) => {
  const { formatMessage } = intl;
  return (
    <Paper className={styles.graphWrapper}>
      <div className={styles.graphHeaderWrapper}>
        <div className={styles.graphName}>
          <TextInfoTooltip
            text={title}
            tooltipText={tooltipText}
            tooltipClassName={styles.tooltipWrapper}
          />
        </div>
        <div className={styles.graphIndicators}>
          {indicators.map(indicator => (
            <div className={styles.graphIndicator}>
              <div className={styles.graphIndicatorTitle}>
                {indicator.name}
              </div>
              <span className={styles.graphIndicatorValue}>
                {numbersFormatting(indicator.value)}
              </span>
              <span className={styles.graphIndicatorUnits}>
                {formatMessage({ id: `cunits.mini.${indicator.units}` })}
              </span>
              {isNumber(indicator.accuracy) && (
                <span className={styles.graphIndicatorAccuracy}>
                  {`${indicator.accuracy}% ${formatMessage({ id: 'forecast.dashboard.accuracy' })}`}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.chartContainer}>
        {children}
      </div>
      {legend?.length > 1 && (
        <Legend className={styles.legend} lines={legend} />
      )}
    </Paper>
  );
};

HarvestForecastChartWrapper.propTypes = {
  intl: intlShape.isRequired,
  legend: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  indicators: PropTypes.array,
  tooltipText: PropTypes.string.isRequired
};

HarvestForecastChartWrapper.defaultProps = {
  legend: [],
  indicators: [],
};

export default injectIntl(HarvestForecastChartWrapper);
