import { connect } from 'react-redux';

import { getIsDrillDownFetching } from 'store/tables/selectors';

import { trackPageView } from '../../../store/analytics/actions';
import { trackPageViewPlantingCycle } from '../../../store/newCrops/actions';
import {
  getLogin,
  getLocation,
  getLeftCompartmentTables,
  getRightCompartmentTables,
  getLeftPlantingCycleTables,
  getRightPlantingCycleTables,
  getSplitCompartmentsTables,
} from '../../../store/company/actions';
import {
  getLeftDailyData,
  getRightDailyData,
  updateTableDailyData,
  updateSelectedMetricId,
  trackClickClimateTableWeek,
  requestDrillDown,
} from '../../../store/tables/actions';
import { setToggleTableCellEditDialog } from '../../../store/dialogs';
import setIntl from '../../../store/localization';
import { showNotification, hideNotification, showNotificationWithTimeout } from '../../../store/notificationCenter/actions';
import { getCompartmentByCycleForTable } from '../../../store/compartment/selectors';

import Tables from '../../Tables';

const mapDispatchToProps = {
  trackPageView,
  getLogin,
  getLocation,
  getLeftCompartmentTables,
  getRightCompartmentTables,
  getLeftPlantingCycleTables,
  getRightPlantingCycleTables,
  setIntl,
  getLeftDailyData,
  getRightDailyData,
  getSplitCompartmentsTables,
  updateTableDailyData,
  setToggleTableCellEditDialog,
  trackPageViewPlantingCycle,
  showNotification,
  hideNotification,
  showNotificationWithTimeout,
  updateSelectedMetricId,
  trackClickClimateTableWeek,
  requestDrillDown,
};

const mapStateToProps = (state, props) => {
  const {
    company,
    dialogs,
    tables,
    intl
  } = state;

  return {
    ...props,
    ...company,
    ...tables,
    ...intl,
    ...dialogs,
    isDrillDownFetching: getIsDrillDownFetching(state, props),
    currentCompartment: getCompartmentByCycleForTable(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tables);
