import classnames from 'classnames';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';

import styles from './Link.module.css';

export default class Link extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
    href: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    href: null,
    onClick: null,
  };

  onClick = (e) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(e);
    }
  };

  render() {
    const { href, children } = this.props;

    return (

      <RouterLink
        className={classnames(styles.link)}
        to={href}
        onClick={this.onClick}
      >
        {children}
      </RouterLink>
    );
  }
}
