import React from 'react';
import { Route } from 'react-router-dom';

import Graphs from 'components/Graphs';
import Incidents from 'components/Incidents';

import styles from './AnalysisRoutes.module.css';

const AnalysisRoutes = () => (
  <div className={styles.container}>
    <div className={styles.content}>
      <Route
        key='graphs'
        path='/:organizationSlug/graphs'
        exact
        component={Graphs}
      />
      <Route
        key='incidents'
        path='/:organizationSlug/incidents'
        exact
        component={Incidents}
      />
    </div>
  </div>
);

AnalysisRoutes.propTypes = {};

AnalysisRoutes.defaultProps = {};

export default AnalysisRoutes;
