import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import classnames from 'classnames';
import { get, isNil } from 'lodash';

import DefaultSimpleTable from 'components/DefaultSimpleTable';

import ChartSelectedIcon from 'components/Icons/ChartSelectedIcon';
import ChartNotSelectedIcon from 'components/Icons/ChartNotSelectedIcon';

import { getPercent } from 'helpers/getPercent';
import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';
import getFormattedDate from 'helpers/getFormattedDate';
import styles from './ByDatesTable.module.css';


const getColumns = (formatMessage, period, periods) => {
  if (!periods) {
    return [];
  }

  const items = periods.map(item => item?.epochMinutes);

  return items.map(dateItem => ({
    label: getFormattedDate(formatMessage, dateItem, period), value: dateItem
  }));
};

const getRowName = (formatMessage, getTypeName, isTotalCell, ref) => {
  if (isTotalCell) {
    return formatMessage({ id: 'planFact.total' });
  }

  if (!ref) {
    return null;
  }

  return getTypeName(ref);
};

const getHarvestItemKey = (value) => {
  if (value === 'harvested') {
    return 'total';
  }

  if (value === 'quality') {
    return 'secondGrade';
  }

  return value;
};

const renderHeaderRows = (formatMessage, breakdown, sorting, periods, period, tableData, rowsToShow) => {
  const activeSort = sorting?.type;
  const sortDirection = sorting?.direction;
  const isOneFieldSelected = rowsToShow?.length === 1;

  const headers = [
    { label: formatMessage({ id: `planFact.breakdown.${breakdown}` }), value: 'type', className: 'thLeft' },
  ];

  if (!isOneFieldSelected) {
    headers.push({ label: '', value: 'empty' });
  }

  headers.push(...getColumns(formatMessage, period, periods));

  return (
    <tr>
      <th className={styles.chartIconWrapper} />
      {headers.map((item) => {
        if (item.value === 'empty') {
          return (
            <th />
          );
        }

        return (
          <th
            key={`tableHeader-${item.value}`}
            // onClick={() => this.handlePlanFactSorting(item.value)}
            className={classnames(styles.activeTh, {
              [styles.activeSort]: activeSort === item.value,
              [styles[item.className]]: item.className,
            })}
          >
            <div className={styles.thContent}>
              {item.label}
              <div
                className={classnames(styles.arrow, {
                  [styles.sortDirectionDown]: sortDirection === 'down' && activeSort === item.value
                })}
              >
                {/* <ArrowDown /> */}
              </div>
            </div>
          </th>
        );
      })}
      <th />
    </tr>
  );
};

const getValue = ({
  combinedHarvest,
  column,
  currentUnits,
  timeKey
}) => {
  const columnId = column?.value;

  let key;
  let withPercent = true;
  let value;
  let percentValue;

  if (columnId === 'plan') {
    withPercent = false;
    key = columnId;
  } else if (columnId === 'harvested') {
    key = 'total';
  } else if (columnId === 'quality') {
    key = 'secondGrade';
  } else {
    key = columnId;
  }

  const categorizedHarvestValue = combinedHarvest.find(item => get(item, 'key') === key);

  if (categorizedHarvestValue) {
    value = get(categorizedHarvestValue, 'harvest');
    percentValue = get(categorizedHarvestValue, 'percentage');
  }

  if (isNil(value[timeKey])) {
    return '—';
  }

  const roundTo = currentUnits !== 'relative' ? 0 : 1;
  const roundedValue = numbersFormatting(numbersRounding(value[timeKey], 'fixed', roundTo));

  if (withPercent) {
    const percentedValue = isNil(percentValue[timeKey]) ? 0 : numbersFormatting(numbersRounding(percentValue[timeKey], 'fixed', roundTo));

    return <><span>{roundedValue}</span>&nbsp;<span className={styles.percentage}>({percentedValue}%)</span></>;
  }

  return roundedValue;
};

const renderHarvestDataRow = (
  roundTo,
  rowData,
  currentUnits,
  combinedHarvest,
  harvestItem
) => {
  if (!rowData) {
    return null;
  }

  const rowDataArray = Object.keys(rowData);
  const rowDataArraySorted = rowDataArray; // rowDataArray?.sort()?.reverse();
  return rowDataArraySorted.map(rowDataItemKey => (
    <td className={styles.dataCell}>{getValue({
      combinedHarvest, column: harvestItem, currentUnits, timeKey: rowDataItemKey
    })}
    </td>
  ));
};

const getPercentage = (plan, harvestData, category) => {
  if (category === 'total') {
    const planKeys = Object.keys(plan || {});
    return planKeys.reduce((acc, key) => ({ ...acc, [key]: getPercent(plan[key], harvestData[category][key]) }), {});
  }

  const totalKeys = Object.keys(harvestData?.total || {});
  return totalKeys.reduce((acc, key) => ({ ...acc, [key]: getPercent(harvestData?.total[key], harvestData[category][key]) }), {});
};

const renderBodyRows = (
  intl,
  roundTo,
  rowsToShow,
  graphKeyChange,
  getTypeName,
  categories,
  graphKey,
  tableData = [],
  currentUnits
) => {
  const { formatMessage } = intl;
  const isOneFieldSelected = rowsToShow?.length === 1;

  return tableData.map((typeItem) => {
    const typeRef = typeItem.ref;
    const harvestData = typeItem?.harvest?.data;
    const isTotalRow = typeItem?.key === 'total';

    if (!harvestData) {
      return null;
    }

    const harvestDataPlan = harvestData?.plan;

    const combinedHarvest = Object.keys(harvestData).map(combinedHarvestItem => ({
      key: combinedHarvestItem,
      harvest: harvestData[combinedHarvestItem],
      percentage: getPercentage(harvestDataPlan, harvestData, combinedHarvestItem)
    }));

    const dataKeysArrayLength = rowsToShow.length;

    return rowsToShow.map((harvestItem, harvestItemIndex) => {
      const isFirstRowInGroup = harvestItemIndex === 0;

      return (
        <tr
          className={classnames({ [styles.totalRow]: isTotalRow })}
        >
          {isFirstRowInGroup && (
            <td
              rowSpan={dataKeysArrayLength}
              onClick={e => graphKeyChange(e, typeItem?.key)}
              role='gridcell'
              tabIndex={0}
              className={styles.chartIconWrapper}
            >
              <div className={styles.chartIconContainer}>
                {typeItem?.key === graphKey ? <ChartSelectedIcon /> : <ChartNotSelectedIcon />}
              </div>
            </td>
          )}
          {isFirstRowInGroup && (
            <td
              rowSpan={dataKeysArrayLength}
              className={styles.firstRowInGroup}
            >
              {getRowName(formatMessage, getTypeName, isTotalRow, typeRef)}
            </td>
          )}
          {!isOneFieldSelected && (
            <td className={styles.categoryName}>
              {harvestItem.label}
            </td>
          )}
          {renderHarvestDataRow(
            roundTo,
            harvestData[getHarvestItemKey(harvestItem.value)],
            currentUnits,
            combinedHarvest,
            harvestItem
          )}
          <td />
        </tr>
      );
    });
  });
};

const ByDatesTable = ({
  intl,
  tableData,
  periods,
  categories,
  tableClassName,
  getTypeName,
  period,
  graphKey,
  graphKeyChange,
  roundTo,
  rowsToShow,
  currentUnits,
  breakdown,
}) => {
  const { formatMessage } = intl;
  const sorting = { value: 'type', direction: 'up' };

  const emptyText = tableData && tableData.length === 0 ? formatMessage({ id: 'harvestDashboard.emptyText' }) : null;

  return (
    <div className={styles.tableWrapper}>
      <DefaultSimpleTable
        className={classnames(styles.table, tableClassName)}
        headerRows={renderHeaderRows(formatMessage, breakdown, sorting, periods, period, tableData, rowsToShow)}
        bodyRows={renderBodyRows(intl, roundTo, rowsToShow, graphKeyChange, getTypeName, categories, graphKey, tableData, currentUnits)}
        size='small'
        emptyText={emptyText}
      />
    </div>
  );
};

ByDatesTable.propTypes = {
  intl: intlShape.isRequired,
  tableData: PropTypes.array,
  categories: PropTypes.array,
  periods: PropTypes.array,
  tableClassName: PropTypes.string,
  getTypeName: PropTypes.func.isRequired,
  period: PropTypes.string.isRequired,
  breakdown: PropTypes.string.isRequired,
  graphKey: PropTypes.string.isRequired,
  graphKeyChange: PropTypes.func.isRequired,
  rowsToShow: PropTypes.array.isRequired,
  roundTo: PropTypes.number,
  currentUnits: PropTypes.number
};

ByDatesTable.defaultProps = {
  tableData: [],
  categories: [],
  periods: [],
  tableClassName: null,
  roundTo: 1,
  currentUnits: null
};

export default injectIntl(ByDatesTable);
