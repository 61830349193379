import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classnames from 'classnames';

import DefaultBreakdown from 'components/DefaultBreakdown/DefaultBreakdown';
import Typography from 'components/Typography';

import useWindowSize from 'hooks/useWindowSize';
import renderTooltipContent from './renderTooltipContent';
import styles from './DiscrepancyOverview.module.css';


const DiscrepancyOverview = ({
  intl,
  metricsDiscrepancy,
  descriptor,
  wrappedClassName
}) => {
  const { formatMessage } = intl;
  const {
    periods,
  } = descriptor;
  const metricsItems = Object.keys(metricsDiscrepancy)
    .reduce((acc, key) => ([...acc, {
      value: metricsDiscrepancy[key].amount,
      label: formatMessage({ id: `unrealizedPotential.metrics.${key}` }),
      units: metricsDiscrepancy[key].units,
    }]), []);

  const size = useWindowSize();
  const isMobile = size.width < 720;

  const tooltipTitle = `${(moment(periods[0].startDate).format('MMM, YYYY'))} - ${(moment(periods[periods.length - 1].endDate).format('MMM, YYYY'))}`;
  return (
    <div className={classnames(styles.block, wrappedClassName)}>
      <div className={styles.metrics}>
        <Typography variant='h4'>
          <FormattedMessage id='unrealizedPotential.metricsDiscrepancy' />
        </Typography>
        <DefaultBreakdown
          emptyTitle={formatMessage({ id: 'metrics' })}
          renderTooltipContent={renderTooltipContent}
          items={metricsItems}
          labelPath='label'
          valuePath='value'
          height={238}
          minHeight={isMobile ? 238 : 142}
          roundTo={0}
          barHeight={12}
          isEmpty={false}
          tooltipTitle={tooltipTitle}
          maxBars={isMobile ? 0 : 5}
          sortByAsc
        />
      </div>
    </div>
  );
};

DiscrepancyOverview.propTypes = {
  intl: intlShape.isRequired,
  metricsDiscrepancy: PropTypes.object,
  descriptor: PropTypes.object,
  wrappedClassName: PropTypes.string,
};

DiscrepancyOverview.defaultProps = {
  metricsDiscrepancy: {},
  descriptor: null,
  wrappedClassName: null
};


export default injectIntl(DiscrepancyOverview);
