import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import isOutsideScreen from 'helpers/isOutsideScreen';
import BenchmarkCompareButton from 'components/BenchmarkCompareButton';

import TooltipContent from '../TooltipContent';

import styles from './Tooltip.module.css';

// Высота и отступ треугольника тултипа
const TAIL_HEIGHT = 9;
const TAIL_PADDING = 7;

const Tooltip = ({
  x, y, tooltipData, width, isShowLocationName, className,
  onMouseOverCallback, onMouseOutCallback,
}) => {
  const rootRef = useRef(null);
  const triangleStyle = {
    left: 'calc(50% - 9px)'
  };
  const tooltipStyles = {
    width: `${width}px`,
    left: `${x - (width / 2)}px`,
    bottom: `${y + TAIL_HEIGHT + TAIL_PADDING}px`,
  };

  const outside = isOutsideScreen(rootRef.current);
  if (outside.dir === 'right') {
    triangleStyle.left = `${(width / 2) + outside.value + 10}px`;
    tooltipStyles.left = `${x - (width / 2) - outside.value - 20}px`;
  } else if (outside.dir === 'left') {
    triangleStyle.left = `${(width / 2) - outside.value - 10}px`;
    tooltipStyles.left = `${x - (width / 2) + outside.value + 20}px`;
  }

  /* eslint-disable jsx-a11y/mouse-events-have-key-events */
  return (
    <div
      onMouseEnter={() => onMouseOverCallback({ tooltipData, x, y })}
      onMouseLeave={() => onMouseOutCallback(null)}
      className={classnames(styles.tooltip, className)}
      style={tooltipStyles}
      ref={rootRef}
    >
      <div className={styles.tooltipContent}>
        {/* TODO: вынести контент тултипа в функцию-рендер и передавать её пропсом */}
        <TooltipContent tooltipData={tooltipData} isShowLocationName={isShowLocationName} />
      </div>
      <BenchmarkCompareButton data={tooltipData} />
      <div className={styles.triangle} style={triangleStyle} />
    </div>
  );
  /* eslint-enable jsx-a11y/mouse-events-have-key-events */
};

Tooltip.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  tooltipData: PropTypes.object.isRequired,
  isShowLocationName: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.number,
  onMouseOverCallback: PropTypes.func,
  onMouseOutCallback: PropTypes.func,
};

Tooltip.defaultProps = {
  className: null,
  width: 288,
  isShowLocationName: true,
  onMouseOverCallback: () => {},
  onMouseOutCallback: () => {},
};

export default memo(Tooltip);

