import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import styles from './CropIcon.module.css';

import SpeciesIcon from '../SpeciesIcon';


export default class CropIcon extends Component {
  static propTypes = {
    className: PropTypes.string,
    crop: PropTypes.oneOf([null, 'cucumber', 'tomato', 'eggplant', 'lettuce', 'pepper']),
  };

  static defaultProps = {
    className: null,
    crop: null,
  };

  render() {
    const {
      crop,
      className,
    } = this.props;

    return (
      <div
        className={classnames(styles.layout, {
          [styles.tomato]: crop === 'tomato',
          [styles.cucumber]: crop === 'cucumber',
          [styles.eggplant]: crop === 'eggplant',
          [styles.lettuce]: crop === 'lettuce',
          [styles.pepper]: crop === 'pepper',
        }, className)}
      >
        <SpeciesIcon species={crop} />
      </div>
    );
  }
}
