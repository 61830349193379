import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import ResizeObserver from 'resize-observer-polyfill';
import { getPixelRatio } from 'components/CanvasTimeline/helpers/CanvasUtils';

class ReactCanvas extends Component {
  static propTypes = {
    onDraw: PropTypes.func.isRequired,
    onPointerUp: PropTypes.func,
    onPointerDown: PropTypes.func,
    onPointerMove: PropTypes.func,
    onMouseWheel: PropTypes.func,
    onInit: PropTypes.func,
    onResize: PropTypes.func,
    canvasClassName: PropTypes.string,
    adjustElClassName: PropTypes.string,
    verticalPadding: PropTypes.number,
    horizontalPadding: PropTypes.number
  };

  static defaultProps = {
    onPointerUp: null,
    onPointerDown: null,
    onPointerMove: null,
    onMouseWheel: null,
    onInit: null,
    onResize: null,
    canvasClassName: null,
    adjustElClassName: null,
    verticalPadding: 0,
    horizontalPadding: 0
  };

  canvasRef = React.createRef();

  lastFrame = 0

  breakRenderLoop = false

  frameCount = 0;

  isAdjusted = false;

  isInit = false;

  handleWindowResizeListener = null;

  componentDidMount() {
    if (this.canvasRef.current) {
      this.handleWindowResizeListener = this.handleWindowResize.bind(this);
      window.addEventListener('resize', this.handleWindowResizeListener);
      const canvasBounds = this.getCanvasBounds();
      this.setCanvasPixelRatio({ width: canvasBounds.width, height: canvasBounds.height });
      // this.resizeObserver.observe(this.canvasRef.current);
      this.adjustSize();
      window.requestAnimationFrame(this.renderLoop);
    }
  }

  componentWillUnmount() {
    // this.resizeObserver.disconnect();
    this.breakRenderLoop = true;
    window.removeEventListener('resize', this.handleWindowResizeListener);
  }

  handleWindowResize() {
    const {
      adjustElClassName,
    } = this.props;
    if (!adjustElClassName) {
      const canvasBounds = this.getCanvasBounds();
      this.setCanvasPixelRatio({ width: canvasBounds.width, height: canvasBounds.height });
    } else {
      this.adjustSize();
    }
  }

  handlePointerMove = ({ clientX, clientY }) => {
    const {
      onPointerMove
    } = this.props;
    const bounds = this.getCanvasBounds();
    if (onPointerMove) {
      onPointerMove({
        x: clientX - bounds.left,
        y: clientY - bounds.top,
      });
    }
  }

  handlePointerDown = ({ clientX, clientY }) => {
    const {
      onPointerDown
    } = this.props;
    if (onPointerDown) {
      const bounds = this.getCanvasBounds();
      onPointerDown({
        x: clientX - bounds.left, y: clientY - bounds.top
      });
    }
  }

  handlePointerUp = ({
    clientX, clientY, shiftKey, ctrlKey
  }) => {
    const {
      onPointerUp
    } = this.props;
    if (onPointerUp) {
      const bounds = this.getCanvasBounds();
      onPointerUp({
        x: clientX - bounds.left, y: clientY - bounds.top, shiftKey, ctrlKey
      });
    }
  }

  handleWheel = (values) => {
    const {
      deltaX, deltaY, clientX, clientY, shiftKey, ctrlKey
    } = values;

    const {
      onMouseWheel
    } = this.props;
    if (onMouseWheel) {
      const bounds = this.getCanvasBounds();
      onMouseWheel({
        deltaY,
        deltaX,
        x: clientX - bounds.left,
        y: clientY - bounds.top,
        shiftKey,
        ctrlKey
      });
    }
  }


  // eslint-disable-next-line react/sort-comp
  renderLoop = (timestamp) => {
    const dt = timestamp - this.lastFrame;

    const ctx = this.getContext();

    if (ctx) {
      this.draw(ctx, this.getCanvasBounds(), dt);
    }

    if (!this.breakRenderLoop) {
      this.lastFrame = timestamp;
      this.frameCount += 1;
      window.requestAnimationFrame(this.renderLoop);
    }
  }

  getCanvasBounds() {
    const canvas = this.canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    return {
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
      width: rect.width,
      height: rect.height,
      x: rect.x,
      y: rect.y
    };
  }

  getContext() {
    const canvas = this.canvasRef.current;
    return canvas ? canvas.getContext('2d') : null;
  }

  setCanvasPixelRatio({ width, height }) {
    const {
      onResize, onInit
    } = this.props;
    // this.cacheWidth = width;
    const canvas = this.canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      const ratio = getPixelRatio(context);
      canvas.width = width * ratio;
      canvas.height = height * ratio;
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;
      context.scale(ratio, ratio);
      if (onInit && !this.isInit) {
        onInit(this.getContext(), this.getCanvasBounds(), { width, height });
        this.isInit = true;
      }
      if (onResize) {
        onResize(this.getContext(), this.getCanvasBounds(), { width, height });
      }
    }
  }

  getAdjustElSize() {
    const {
      adjustElClassName,
    } = this.props;
    const parentEl = document.getElementsByClassName(adjustElClassName)[0];
    const styles = getComputedStyle(parentEl);
    const width = parseFloat(styles.getPropertyValue('width'));
    const height = parseFloat(styles.getPropertyValue('height'));
    return {
      width, height
    };
  }

  adjustSize() {
    const {
      adjustElClassName,
      verticalPadding,
      horizontalPadding
    } = this.props;
    if (adjustElClassName && this?.canvasRef?.current) {
      const {
        width, height
      } = this.getAdjustElSize();
      this.setCanvasPixelRatio({
        width: width - horizontalPadding,
        height: height - verticalPadding
      });
    }
  }

  draw(ctx, bounds, dt) {
    const {
      onDraw
    } = this.props;

    onDraw(ctx, bounds, dt);
  }

  render() {
    const {
      canvasClassName
    } = this.props;
    return (
      <canvas
        className={canvasClassName}
        ref={this.canvasRef}
        onPointerMove={this.handlePointerMove}
        onPointerDown={this.handlePointerDown}
        onPointerUp={this.handlePointerUp}
        onWheel={this.handleWheel}
        onTouchMove={(ev) => {
          this.handlePointerMove(ev.touches[0]);
        }}
      />
    );
  }
}


export default ReactCanvas;
