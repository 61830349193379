import * as d3 from 'd3';
import { RefObject, useEffect } from 'react';

function useYAxis(
  ref: RefObject<SVGGElement>,
  width: number,
  height: number,
  numLines: number = 6
) {
  useEffect(() => {
    if (ref.current) {
      const scale = d3.scaleLinear()
        .range([height || 100, 0])
        .nice()
        .domain([0, 100]);

      // @ts-ignore
      const axis = d3.axisLeft(scale)
        .scale(scale)
        .tickPadding(4)
        .tickSizeInner(-width)
        .tickSizeOuter(0)
        .tickValues(
          Array(numLines)
            .fill(null)
            .map((value, rangeIndex) => rangeIndex * 20)
        )
        .tickFormat(() => null);

      d3.select(ref.current).call(axis);
    }
  }, [ref, width, height, numLines]);
}

export default useYAxis;
