import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { ReactComponent as ArrowDown } from './assets/arrow_down.svg';

import DefaultSearchList from '../DefaultSearchList';

import getRandomId from '../../helpers/getRandomId';

import styles from './BigSelectInput.module.css';


export default class BigSelectInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    classNameButton: PropTypes.string,
    classNamePopup: PropTypes.string,
    classNameOption: PropTypes.string,
    classNameIcon: PropTypes.string,
    labelPath: PropTypes.string,
    valuePath: PropTypes.string,
    buttonLabelPath: PropTypes.string,
    isSearchable: PropTypes.bool,
    empty: PropTypes.string,
    disabled: PropTypes.bool,
    open: PropTypes.bool,
    closeOnChange: PropTypes.bool,
    value: PropTypes.any,
    placeholder: PropTypes.any,
    placeholderSearchList: PropTypes.any,
    options: PropTypes.array,
    onChange: PropTypes.func,
    onOpenChange: PropTypes.func,
    onRenderArrow: PropTypes.func,
    error: PropTypes.string,
    children: PropTypes.object,
    customTitle: PropTypes.string,
    textEllipsis: PropTypes.bool,
    theme: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    classNameButton: '',
    classNamePopup: '',
    classNameOption: '',
    classNameIcon: '',
    labelPath: 'attributes.name',
    valuePath: 'id',
    buttonLabelPath: null,
    empty: null,
    isSearchable: false,
    disabled: false,
    open: false,
    closeOnChange: false,
    value: null,
    placeholder: '',
    placeholderSearchList: '',
    options: [],
    onChange: null,
    onOpenChange: null,
    onRenderArrow: null,
    error: null,
    children: null,
    customTitle: null,
    textEllipsis: false,
    theme: null,
  };

  state = {
    query: '',
    open: this.props.open,
    id: getRandomId(),
  };

  componentDidMount() {
    document.addEventListener('click', this.handlerDocumentClick);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { open: oldOpen } = this.props;
    const { open: nextOpen } = nextProps;

    if (oldOpen !== nextOpen) {
      this.setState({
        open: nextOpen,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handlerDocumentClick);
  }

  handlerDocumentClick = async (e) => {
    const { onOpenChange } = this.props;
    const { open, id } = this.state;

    if (open) {
      if (e.target && (!e.target.closest(`.big-select-${id}`) && !e.target.closest(`.${styles.searchListControl}`))) {
        this.setState({
          open: false,
          query: '',
        }, () => {
          if (onOpenChange) {
            onOpenChange(!open);
          }
        });
      }
    }
  };

  handlerChange = (option) => {
    const { closeOnChange, onChange, disabled } = this.props;

    if (!disabled) {
      if (onChange) {
        if (option) {
          const { value, handlerClick } = option;

          if (handlerClick) {
            handlerClick({ value });
          } else {
            onChange({ value });
          }
        } else {
          onChange({ value: null });
        }
      }

      if (closeOnChange) {
        this.setState({
          open: false,
        });
      }
    }
  };

  handlerTogglePopup = () => {
    const { onOpenChange, disabled } = this.props;
    const { open, query } = this.state;

    if (!disabled) {
      this.setState({
        open: !open,
        query: !open ? query : '',
      }, () => {
        if (onOpenChange) {
          onOpenChange(!open);
        }
      });
    }
  };

  renderArrow = () => {
    const { onRenderArrow } = this.props;

    if (onRenderArrow) {
      return onRenderArrow({ props: this.props, className: styles.arrow });
    }

    return <ArrowDown className={styles.arrow} />;
  };

  renderOptions = () => {
    const {
      options,
      labelPath,
      valuePath,
      value: selectedValue,
      classNameOption,
    } = this.props;

    const data = options.map(item => ({
      label: get(item, labelPath),
      description: get(item, 'description'),
      value: get(item, valuePath),
      onClick: item.onClick ? item.onClick : null
    }));

    return (
      <div className={styles.options}>
        {data.map(({
          label,
          description,
          value,
          onClick: handlerClick,
        }) => {
          const optionData = {
            onClick: () => {
              this.handlerChange({ label, value, handlerClick });
            },
          };

          const isSelected = value === selectedValue;

          return (
            <div
              key={`select-${btoa(value)}`}
              className={classnames(styles.option, classNameOption, { [styles.selected]: isSelected })}
              {...optionData}
            >
              {label}
              {description ? (
                <span className={styles.description}>
                  {description}
                </span>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  };

  renderSearchList = () => {
    const {
      empty,
      placeholderSearchList,
      valuePath,
      labelPath,
      options,
    } = this.props;

    const {
      query,
    } = this.state;

    return (
      <DefaultSearchList
        controlClassName={styles.searchListControl}
        placeholder={placeholderSearchList}
        options={options}
        empty={empty}
        isFetching={false}
        value={query}
        valuePath={valuePath}
        labelPath={labelPath}
        onChanged={({ value: nextQuery }) => {
          this.setState({ query: nextQuery });
        }}
        onSelected={({ option }) => {
          this.handlerChange({ label: get(option, labelPath), value: get(option, valuePath), handlerClick: get(option, 'onClick') });
        }}
      />
    );
  };

  render() {
    const {
      className,
      classNameButton,
      classNamePopup,
      classNameIcon,
      value,
      valuePath,
      labelPath,
      buttonLabelPath,
      placeholder,
      disabled,
      isSearchable,
      options,
      error,
      children,
      customTitle,
      textEllipsis,
      theme,
    } = this.props;

    const {
      open,
      id,
    } = this.state;

    const isError = !!error;

    const titlePath = buttonLabelPath || labelPath;
    const selectedOption = options.find(item => get(item, valuePath) === value);
    const title = selectedOption ? get(selectedOption, titlePath) : '';
    const description = selectedOption ? get(selectedOption, 'description') : '';
    const arrow = this.renderArrow();
    const renderedOptions = !isSearchable && this.renderOptions();
    const renderedSearchList = isSearchable && this.renderSearchList();

    return (
      <div
        className={
          classnames(styles.select, `big-select-${id}`, className, {
            [styles.disabled]: disabled,
            [styles.focus]: open,
            [styles.errored]: !!error,
            [styles.mini]: theme === 'mini',
          })
}
      >
        <button
          className={
            classnames(
              styles.button,
              classNameButton,
              {
                [styles.notSelected]: !(selectedOption || customTitle),
                [styles.focus]: open,
                [styles.invalid]: isError,
                invalid: isError,
              }
            )
          }
          disabled={disabled}
          onClick={this.handlerTogglePopup}
          type='button'
        >
          <div
            className={classnames(
              styles.input,
              {
                [styles.invalid]: isError,
                [styles.textEllipsis]: textEllipsis,
                invalid: isError,
              }
            )}
          >
            {customTitle || title}
            <span className={styles.description}>
              {description}
            </span>
          </div>
          <div className={classnames(styles.icon, classNameIcon)}>
            {arrow}
          </div>
          <div
            className={classnames(
              styles.placeholder,
              {
                [styles.top]: !!value || !!open || !!customTitle,
                [styles.invalid]: isError,
                invalid: isError,
              }
            )}
          >
            {placeholder}
          </div>
        </button>

        {open && !disabled && (
          <div className={classnames(styles.popup, { [styles.isSearchable]: isSearchable }, classNamePopup)}>
            {isSearchable ? renderedSearchList : (children || renderedOptions)}
          </div>
        )}

        {error ? (
          <div className={styles.error}>
            {error}
          </div>
        ) : null}
      </div>
    );
  }
}
