import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import { get, find } from 'lodash';
import moment from 'moment-timezone';

import MobileMenu from '../../../MobileMenu';
import VarietySelectMobile from '../VarietySelectMobile';
import PlantingCyclesSelectMobile from '../PlantingCyclesSelectMobile';
import BigSelectInput from '../../../BigSelectInput';
import BigButton from '../../../BigButton';

import { API_DATE_FORMAT } from '../../../../helpers/defaultDates';

import styles from './DashboardFiltersMobile.module.css';

import { ReactComponent as FiltersIcon } from './assets/filters.svg';

export default class DashboardFiltersMobile extends Component {
  static propTypes = {
    onFiltersChange: PropTypes.func.isRequired,

    intl: intlShape.isRequired,

    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    plantingCycles: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    currentGrownFilter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    currentPlantingCycle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),

    currentCompartment: PropTypes.string,
    currentUnits: PropTypes.string,
    currentVariety: PropTypes.number,
    currentSpecies: PropTypes.string,
  };

  static defaultProps = {
    currentCompartment: 'all',
    currentPlantingCycle: 'all',
    currentUnits: 'absolute',
    currentVariety: undefined,
    currentSpecies: undefined,
  };

  state = {
    grownFilter: this.props.currentGrownFilter,
    plantingCycleId: this.props.currentPlantingCycle,
    compartmentId: this.props.currentCompartment,
    units: this.props.currentUnits,
    varietyId: this.props.currentVariety,
    species: this.props.currentSpecies,
    anyDateOfPeriodStart: undefined,
    anyDateOfPeriodEnd: undefined,

    mobileMenuOpened: false,
  }

  getValidSelectValue = (value, valuesList) => (get(valuesList.find(variety => variety.value === Number(value)), 'value') || 'all');

  getCompartmentsList = (compartments, formatMessage) => compartments.reduce((acc, compartment) => (
    [...acc, { label: get(compartment, 'attributes.name'), value: compartment.id }]
  ), [{ label: formatMessage({ id: 'dashboards.allCompartments' }), value: 'all' }]);

  getUnitsList = (units, formatMessage) => units.reduce((acc, unit) => {
    const unitName = unit === 'relative' ? 'kilogramPerSquareMeter' : 'kilogram';

    return [...acc, { label: formatMessage({ id: `cunits.mini.${unitName}` }), value: unit }];
  }, []);

  getFilteredByVarietiesPlantingCycles = (plantingCycles, varietyId, varieties, species) => {
    let filteredVarieties = varieties;

    // Фильтруем varieties по species
    if (species) {
      filteredVarieties = filteredVarieties.filter(variety => get(variety, 'attributes.species') === species);
    }

    // Сначала фильтруем по отфильтрованнным varieties
    const filteredByVarieties = plantingCycles
      .filter(cycle => filteredVarieties.find(variety => get(cycle, 'relationships.variety.data[0].id') === variety.id));

    // Если пришёл конкретный variety, то фильтруем по нему
    if (varietyId) {
      return filteredByVarieties
        .filter(cycle => get(cycle, 'relationships.variety.data[0].id') === Number(varietyId));
    }

    return filteredByVarieties;
  };

  setNewSearchPath = newParams => this.setState(newParams);

  handlerShowResults = () => {
    const { onFiltersChange } = this.props;
    const {
      species, varietyId, plantingCycleId, compartmentId, units, anyDateOfPeriodStart, anyDateOfPeriodEnd
    } = this.state;

    let newParams = {
      species, varietyId, units, anyDateOfPeriodEnd
    };

    if (plantingCycleId !== 'all') {
      newParams = { ...newParams, plantingCycleId };
    }

    if (compartmentId !== 'all') {
      newParams = { ...newParams, compartmentId };
    }

    if (anyDateOfPeriodStart) {
      newParams = { ...newParams, anyDateOfPeriodStart };
    }

    if (anyDateOfPeriodEnd) {
      newParams = { ...newParams, anyDateOfPeriodEnd };
    }

    this.setState({
      mobileMenuOpened: false,
    });

    onFiltersChange(newParams);
  };

  handlerCloseMenu = () => this.setState({
    mobileMenuOpened: false,
  });

  handlerOpenMenu = () => this.setState({
    mobileMenuOpened: true,
  });

  handlerVarietyOptionSelect = (species, varietyId) => {
    if (varietyId) {
      this.setNewSearchPath({
        grownFilter: varietyId, species, varietyId, plantingCycleId: undefined
      });
    } else {
      this.setNewSearchPath({
        grownFilter: species, species, varietyId: undefined, plantingCycleId: undefined
      });
    }
  };

  handlerPlantingCyclesSelect = (plantingCycleId) => {
    const { plantingCycles } = this.props;

    let newSearchPath = { plantingCycleId: plantingCycleId === 'all' ? undefined : plantingCycleId };

    const plantingCycle = find(plantingCycles, { id: Number(plantingCycleId) });

    if (plantingCycle) {
      // При выборе конкретного planting cycle фиксируем даты его начала и конца
      const newStartDate = get(plantingCycle, 'attributes.startDate');
      const newEndDate = get(plantingCycle, 'attributes.endDate');

      // Также сбрасываем фильтр compartment на all
      const compartmentId = undefined;

      newSearchPath = {
        ...newSearchPath,
        anyDateOfPeriodStart: newStartDate,
        anyDateOfPeriodEnd: newEndDate || moment(new Date()).format(API_DATE_FORMAT),
        compartmentId,
      };
    } else {
      newSearchPath = {
        ...newSearchPath,
        anyDateOfPeriodStart: undefined,
        anyDateOfPeriodEnd: undefined,
      };
    }

    this.setNewSearchPath(newSearchPath);
  };

  handlerCompartmentsSelect = options => this.setNewSearchPath({ compartmentId: options.value === 'all' ? undefined : options.value });

  handlerUnitsSelect = options => this.setNewSearchPath({ units: options.value });

  renderDefaultSelectBox = select => (
    <div key={`select-${select.name}`} className={classnames(styles.selectWrapper, select.className)}>
      <BigSelectInput
        classNameButton={select.classNameButton}
        classNamePopup={select.classNamePopup}
        disabled={select.disabled}
        options={select.options}
        value={select.value}
        placeholder={select.placeholder}
        onChange={select.handler}
        labelPath='label'
        valuePath='value'
        closeOnChange
        textEllipsis
      />
    </div>
  );

  render() {
    const {
      intl: { formatMessage },
      varieties,
      plantingCycles,
      fruitClasses,
      compartments,
    } = this.props;

    const {
      mobileMenuOpened,
      plantingCycleId: currentPlantingCycle,
      compartmentId: currentCompartment,
      units: currentUnits,
      grownFilter: currentGrownFilter,
      varietyId,
      species,
    } = this.state;


    // Фильтруем тут, а не в селекторе, т.к. при фильтре нужно учитывать стейт этого компонента
    const filteredPlantingCycles = this.getFilteredByVarietiesPlantingCycles(plantingCycles, varietyId, varieties, species);
    const compartmentsList = this.getCompartmentsList(compartments, formatMessage);
    // const units = ['absolute', 'relative'];
    const units = ['absolute'];
    const unitsList = this.getUnitsList(units, formatMessage);

    const compartmentsFilter = {
      name: 'compartments',
      value: this.getValidSelectValue(currentCompartment, compartmentsList),
      options: compartmentsList,
      handler: this.handlerCompartmentsSelect,
      placeholder: formatMessage({ id: 'cropsPerformance.compartment' }),
    };

    const unitsFilter = {
      name: 'units',
      value: currentUnits,
      options: unitsList,
      handler: this.handlerUnitsSelect,
      classNameButton: styles.unitsFilter,
      classNamePopup: styles.unitsFilterPopup,
      placeholder: formatMessage({ id: 'harvestDashboard.units' }),
    };

    return (
      <MobileMenu
        className={styles.mobileMenu}
        icon={<FiltersIcon />}
        headerText={formatMessage({ id: 'harvestDashboard.filtersHeader' })}
        onClose={this.handlerCloseMenu}
        onOpen={this.handlerOpenMenu}
        menuOpened={mobileMenuOpened}
      >
        <div className={styles.container}>
          <div className={styles.filtersContainer}>
            <div className={styles.selectGroup}>
              <div className={styles.selectGroupHeader}>
                {formatMessage({ id: 'harvestDashboard.crop' })}
              </div>
              <div className={styles.selectWrapper}>
                <VarietySelectMobile
                  onSelectOption={this.handlerVarietyOptionSelect}
                  varieties={varieties}
                  selectedOption={currentGrownFilter}
                />
              </div>

              <div className={styles.selectWrapper}>
                <PlantingCyclesSelectMobile
                  onSelectOption={this.handlerPlantingCyclesSelect}
                  plantingCycles={filteredPlantingCycles}
                  varieties={varieties}
                  fruitClasses={fruitClasses}
                  compartments={compartments}
                  selectedOption={currentPlantingCycle || 'all'}
                />
              </div>
            </div>

            <div className={styles.selectGroup}>
              <div className={styles.selectGroupHeader}>
                {formatMessage({ id: 'harvestDashboard.compartmentZone' })}
              </div>
              {this.renderDefaultSelectBox(compartmentsFilter)}
            </div>

            <div className={styles.selectGroup}>
              <div className={styles.selectGroupHeader}>
                {formatMessage({ id: 'harvestDashboard.units' })}
              </div>
              {this.renderDefaultSelectBox(unitsFilter)}
            </div>
          </div>

          <div className={styles.mobileControls}>
            <BigButton
              className={styles.cancelButton}
              title={formatMessage({ id: 'button.cancel' })}
              type='button'
              onClick={this.handlerCloseMenu}
              theme='light'
            />
            <BigButton
              title={formatMessage({ id: 'cropsPerformance.showResults' })}
              type='button'
              onClick={this.handlerShowResults}
              theme='dark'
            />
          </div>
        </div>
      </MobileMenu>
    );
  }
}
