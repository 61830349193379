import storageWrapper from '../storageWrapper';

const safeLocalStorage = storageWrapper.get('localStorage');
const LOCALE = safeLocalStorage.getItem('locale');

// Получаем формат времени с учётом локализации
const getDateFormat = (format) => {
  switch (format) {
    case 'l':
      return "MMM' YY";
    case 'll':
      if (LOCALE === 'ru') {
        return 'D MMM';
      }

      return 'MMM D';
    case 'lll':
      if (LOCALE === 'ru') {
        return 'D MMM, YYYY';
      }

      return 'MMM D, YYYY';
    case 'llll':
      if (LOCALE === 'ru') {
        return 'ddd, D MMM';
      }

      return 'ddd, MMM D';
    case 'LLL':
      if (LOCALE === 'ru') {
        return 'D MMMM, YYYY';
      }

      return 'MMMM D, YYYY';
    case 'LLLL':
      if (LOCALE === 'ru') {
        return 'ddd, D MMM, YYYY';
      }

      return 'ddd, MMM D, YYYY';
    case 'll hh':
      if (LOCALE === 'ru') {
        return 'D MMM, HH:mm';
      }

      return 'MMM D, HH:mm';
    case 'lll hh':
      if (LOCALE === 'ru') {
        return 'D MMM, YYYY HH:mm';
      }

      return 'MMM D, YYYY HH:mm';
    default:
      return null;
  }
};

export default getDateFormat;
