import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import { find } from 'lodash';

import CoreLayout from '../../CoreLayout';
import LanguageDialog from '../../../components/LanguageDialog';
import NotificationCenter from '../../../components/NotificationCenter';
import { LANGUAGES } from '../../../helpers/constants';

import styles from './LoginLayout.module.css';


class LoginLayout extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    children: PropTypes.element.isRequired,
    setIntl: PropTypes.func.isRequired,
  };

  state = {
    languageDialogOpen: false,
  };

  handlerLanguageDialogOpen = async () => {
    this.setState({
      languageDialogOpen: true,
    });
  };

  handlerLanguageDialogClose = async () => {
    this.setState({
      languageDialogOpen: false,
    });
  };

  render() {
    const { languageDialogOpen } = this.state;
    const {
      children,
      setIntl,
      intl,
    } = this.props;
    const { locale } = intl;

    const currentLocale = find(LANGUAGES, { locale });

    return (
      <CoreLayout>
        <>
          <div className={classnames(styles.login)}>
            <div className={styles.wrapper}>
              {children}
              <div className={styles.kubo} />
            </div>
            <div className={styles.footer}>
              <button type='button' href='/' className={styles.language} onClick={this.handlerLanguageDialogOpen}>
                {currentLocale.localeName}
              </button>
            </div>
            {languageDialogOpen && (
              <LanguageDialog
                locale={locale}
                onLanguageDialogClose={this.handlerLanguageDialogClose}
                setIntl={setIntl}
              />
            )}
          </div>
          <NotificationCenter />
        </>
      </CoreLayout>
    );
  }
}

export default LoginLayout;
