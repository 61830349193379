import { useEffect } from 'react';

import parse from 'color-parse';
import {
 select, symbol, symbolCircle, symbolSquare
} from 'd3';

import { hsvToRgb, rgbToHsv } from 'helpers/colors';

import Spline from 'helpers/spline';

import symbolDiamondRegular from '../symbolDiamondRegular';

function buildPalette(keyColors) {
  function parseColorXY(stringColor) {
    const { values } = parse(stringColor);
    const hsvColor = rgbToHsv(values[0], values[1], values[2]);
    return {
      x: hsvColor[1],
      y: hsvColor[2],
    };
  }

  const { xs, ys } = keyColors.reduce(
    (acc, stringColor) => {
      const { x: s, y: v } = parseColorXY(stringColor);
      return {
        xs: [...acc.xs, s],
        ys: [...acc.ys, v],
      };
    },
    { xs: [], ys: [] }
  );

  const { values } = parse(keyColors[0]);

  const spline = new Spline(xs, ys);

  const baseHSVColor = rgbToHsv(values[0], values[1], values[2]);

  return {
    spline,
    baseHSVColor,
  };
}

const getStroke = (d, spline, baseHSVColor) => {
  const xMin = Math.min(...spline?.xs);
  const xMax = Math.max(...spline?.xs);
  const item = xMin + (d.colorIndex / 100) * (xMax - xMin);
  const atY = spline.at(item);
  const color = hsvToRgb(baseHSVColor[0], item, atY);
  return `rgb(${color[0]},${color[1]},${color[2]})`;
};

const calcRadius = points => (d) => {
  if (points.length <= 5) {
    // const sizeTable = [24, 20, 16, 12, 8];
    const sizeTable = [8, 12, 16, 20, 24];
    const idx = Math.round((d.colorIndex / 100) * 4);
    return sizeTable[idx] / 2;
  }
  const colorIndex = d.colorIndex / 100;
  let maxSize = 40;
  if (points.length <= 10) {
    maxSize = 24;
  }
  if (points.length > 10 && points.length <= 30) {
    maxSize = 32;
  }
  return (8 + colorIndex * (maxSize - 8)) / 2;
};

/*
const PALLETS = {
  blue: buildPalette(['#E4F7FB', '#1DBADF', '#0A94CA']),
  green: buildPalette(['#B3E8E0', '#00B197', '#008869']),

  blue: buildPalette(['#E4F7FB', '#1DBADF', '#0A94CA']),
  green: buildPalette(['#E4F7FB', '#1DBADF', '#0A94CA']),
};
*/

const PALLETS = {
  blue: buildPalette(['#BBEAF5', '#1DBADF', '#0A94CA']),
  green: buildPalette(['#B3E8E0', '#00B197', '#008869']),
};

function useBubblesChart(groupRef, options) {
  const {
    chartParams: {
      x, y, xValue, yValue
    },
    bubblesData,
    height,
    onOverPoint,
    onOutPoint
  } = options;
  useEffect(() => {
    const group =
      groupRef.current && bubblesData?.length > 0
        ? select(groupRef.current)
        : null;

    const calcX = d => (x(d.x));
    const calcY = d => (y(d.y));

    const getSymbolType = (symbolType) => {
      if (symbolType === 'square') {
        return symbolSquare;
      }
      if (symbolType === 'diamond') {
        return symbolDiamondRegular;
      }
      return symbolCircle;
    };

    const calcSize = item => d => Math.PI * (calcRadius(item.points)(d) * calcRadius(item.points)(d));

    if (group) {
      group.selectAll('g').remove();

      bubblesData.forEach((item, groupIndex) => {
        const traceGroup = group
          .append('g')
          .attr('class', `bubbles-${groupIndex}`);

        const { baseHSVColor, spline } = PALLETS[item.marker.paletteIdx.name];

        const zSortedPoints = item.points.sort((lPoint, rPoint) => lPoint.colorIndex - rPoint.colorIndex);

        const bubbleGroup = traceGroup
          .selectAll('g')
          .data(zSortedPoints)
          .enter()
          .append('g');

        bubbleGroup.append('path')
          .attr('d', symbol().type(getSymbolType(item.marker.symbol)).size(d => calcSize(item)(d)))
          .attr('transform', d => `translate(${calcX(d)},${calcY(d)})`)
          .style('fill', d => getStroke(d, spline, baseHSVColor))
          .style('opacity', '0.9')
          .style('stroke', '#ffffff')
          .on('mouseover', (bubble, idx) => {
            bubbleGroup.select(`.bubble-outline-${groupIndex}-${idx}`).attr('opacity', '0.9');
            if (onOverPoint && item.hover) {
              onOverPoint(bubble, item, idx);
            }
          })
          /*
          .on('mousemove', (bubble) => {
            if (onMoveTooltip) {
              onMoveTooltip(bubble);
            }
          })
          */
          .on('mouseleave', (bubble, idx) => {
            bubbleGroup.select(`.bubble-outline-${groupIndex}-${idx}`).attr('opacity', '0.0');
            if (onOutPoint && item.hover) {
              onOutPoint(bubble, item, idx);
            }
          });

        bubbleGroup.append('path')
          .attr('class', (d, idx) => `bubble-outline-${groupIndex}-${idx}`)
          .attr('d', symbol().type(getSymbolType(item.marker.symbol)).size(d => calcSize(item)(d)))
          .attr('transform', d => `translate(${calcX(d)},${calcY(d)})`)
          .style('fill', 'none')
          .attr('opacity', '0.0')
          .attr('stroke', () => getStroke({
            colorIndex: 10
          }, spline, baseHSVColor))
          .attr('stroke-width', (d) => {
            const r = calcRadius(item.points)(d);
            return `${r * 0.25}px`;
          });
      });
    }
  }, [
    groupRef,
    x,
    y,
    xValue,
    yValue,
    bubblesData,
    height,
    onOverPoint,
    onOutPoint
  ]);
}

export default useBubblesChart;
