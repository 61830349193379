import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInDays from 'date-fns/differenceInDays';
import { formatDate, formatDateLocalized } from 'helpers/datesHelper';
import { isFinite } from 'lodash';
import { addMinutes } from 'date-fns';
import CanvasComponent from './CanvasComponent';
import { addPoints } from '../helpers/GeometryUtils';
import {
  getColumnWidthByViewport, INCIDENT_TIMELINE_CONFIG, slicePeriods
} from '../helpers/incidentScale';
import { roundRect } from '../helpers/DrawUtils';

class IncidentTimelineScale extends CanvasComponent {
  constructor({
    position = { x: 0, y: 0 },
    period,
    treeData,
    currentPeriod,
    intl,
    overlayComponent,
    isScrolledTreePanelX,
  }) {
    super({
      position,
      cursorType: 'pointer',
    });
   // this.updatePeriod(period);
    this.updateCurrentPeriod(currentPeriod);
    this.updateData({
      period,
      treeData
    });
    this.intl = intl;
    const { formatMessage } = intl;
    this.titleText = formatMessage({ id: 'incidentsTimeline.incidentPanelLabel' });
    this.overlayComponent = overlayComponent;
    this.scroll = {
      x: 0,
      y: 0,
    };
    this.isScrolledTreePanelX = isScrolledTreePanelX;
  }

  updateView({
    viewport
  }) {
    this.viewport = viewport;
    this.setDirty();
  }

  updatePeriod(period) {
    this.period = period;
    this.periods = slicePeriods(period);
  }

  updateCurrentPeriod(currentPeriod) {
    this.currentPeriod = currentPeriod;
  }

  setTimelineComponent(timeLineComponent) {
    this.timeLineComponent = timeLineComponent;
  }

  updateData({
    period,
    treeData
  }) {
    this.treeData = treeData;
    this.updatePeriod(period);
  }

  getTimeFormatByPeriod(period) {
    if (period.periodType === 'today') {
      return 'HH:mm';
    }
    if (period.periodType === 'last2Days') {
      return 'HH:mm';
    }
    if (period.periodType === 'last7Days') {
      return 'HH:mm';
    }
    if (period.periodType === 'last30Days') {
      return 'd';
    }
    if (period.periodType === 'last90Days') {
      return 'd';
    }
    const daysInPeriod = differenceInDays(
      period.endInclusive,
      period.start
    );

    if (daysInPeriod >= 30) {
      return 'd';
    }

    return 'HH:mm';
  }

  drawTimelineToday = ({
    ctx, period, position
  }) => {
    ctx.fillStyle = '#FFF';
    ctx.fillRect(0, 0, ctx.canvas.width, INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT);
    ctx.font = 'normal normal 400 12px Roboto';

    if (!this.timeLineComponent) {
      return;
    }

    const diffBetweenFontHeightAndFontSize = 5;
    this.contentWidth = 0;
    for (let periodIndex = 0; periodIndex < this.periods.length; periodIndex += 1) {
      const {
        periodNumber, start, end, isShowHead
      } = this.periods[periodIndex];
      const isSelectedPeriod = this.currentPeriod === periodNumber;
      const color = isSelectedPeriod ? '#1dbadf' : '#777776';
      const periodStartInMinutes = differenceInMinutes(start, this.timeLineComponent.periodStart);
      const periodWidthInMinutes = differenceInMinutes(end, start);
      const baseX = periodStartInMinutes * this.timeLineComponent.timelineStep;
      const baseWidth = periodWidthInMinutes * this.timeLineComponent.timelineStep;

      const fontWeight = isSelectedPeriod ? 500 : 400;
      ctx.fillStyle = color;
      ctx.font = `normal normal ${fontWeight} 12px Roboto`;
      ctx.textAlign = 'left';

      const periodText = formatDate(start, this.getTimeFormatByPeriod(period));
      // const periodTextMeasure = ctx.measureText(periodText);

      ctx.fillText(
        `${periodText}`,
        position.x + baseX,
        INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT - (8 + diffBetweenFontHeightAndFontSize)
      );

      this.contentWidth += baseWidth;

      if (isShowHead) {
        ctx.font = 'normal normal normal 13px Roboto';
        ctx.fillStyle = '#777776';
        ctx.textAlign = 'left';
        ctx.fillText(
          formatDateLocalized(start, 'll'),
          position.x + baseX, // + (textMarginLeft - (periodTextMeasure.width / 2)),
          (18 + diffBetweenFontHeightAndFontSize)
        );
      }
    }

    ctx.strokeStyle = '#E7E9EE';
    ctx.beginPath();
    ctx.moveTo(0, INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT);
    ctx.lineTo(ctx.canvas.width, INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT);
    ctx.stroke();
  }

  drawHoverIndicator({ ctx, period }) {
    if (isFinite(this?.hoverValue?.x) && this?.periods?.length > 0) {
      const x = this.hoverValue.x - INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH - this.scroll.x;
      if (x > 0) {
        ctx.save();
        // const addFunc = getAddFunc(period);
        const colWidth = getColumnWidthByViewport(this.viewport, this.periods);
        const xValue = ((x - (colWidth / 2.0)) / this.hoverValue.timelineStep);
        // debugger;
        const timeValue = addMinutes(this.hoverValue.periodStart, xValue);
        const timeText = formatDate(timeValue, this.getTimeFormatByPeriod(period));
        const textMeasurements = ctx.measureText(timeText);
        const textPadding = 4;
        const xPos = this.hoverValue.x - ((textMeasurements.width + textPadding) / 2);
        const yBaseLine = INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT - 24;
        roundRect(ctx, {
          x: xPos,
          y: yBaseLine - 5,
          height: 22,
          width: textMeasurements.width + textPadding * 2,
          fill: true,
          radius: {
            tl: 4, bl: 4, tr: 4, br: 4
          },
          color: '#4a4a49'
        });

        ctx.font = 'normal normal 400 12px Roboto';
        ctx.fillStyle = 'hsla(0,0%,100%,.9)';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(
          timeText,
          xPos + ((textMeasurements.width + textPadding * 2) / 2),
          yBaseLine + 12 - 5,
        );

        ctx.restore();
      }
    }
  }

  setHoverIndicatorValue(value) {
    this.hoverValue = value;
  }

  setScaleScroll({ x }) {
    this.scroll = {
      x,
      y: 0,
    };
  }

  getContentHeight() {
    return INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT;
  }

  getContentWidth() {
    if (this.viewport && this.periods) {
      const colWidth = getColumnWidthByViewport(this.viewport, this.periods);

      return this.periods.length * colWidth;
    }
    return 0;
    // return this.contentWidth || 0;
  }

  draw(
    ctx,
    {
      // eslint-disable-next-line no-unused-vars
      translateAdd = { x: 0, y: 0 },
    } = {}
  ) {
    ctx.save();
    super.draw(ctx, {
      translateAdd,
    });
    if (this.viewport) {
      const colWidth = getColumnWidthByViewport(this.viewport, this.periods);

      const position = this.getPositionAbs();
      const screenPosition = addPoints(position, {
        x: this.scroll.x + (colWidth / 2.0),
        y: 0,
      });

      this.drawTimelineToday({
        ctx, position: screenPosition, period: this.period
      });

      const borderXPosition = this.isScrolledTreePanelX ?
        screenPosition.x
        :
        INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH;

      const backgroundXPosition = this.isScrolledTreePanelX ?
        screenPosition.x - INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH
        :
        INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH;

      const headerXPosition = this.isScrolledTreePanelX ?
        screenPosition.x - INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH
        :
        0;

      // Фон для заголовка Incidents, чтобы при скролле было перекрытие
      ctx.save();
      ctx.fillStyle = '#FFF';
      ctx.fillRect(0, 0, backgroundXPosition, INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT);
      ctx.restore();

      // Заголовок Incidents
      ctx.save();
      ctx.fillStyle = '#777776';
      ctx.backgroundColor = '#FFF';
      ctx.textAlign = 'left';
      ctx.textBaseline = 'top';
      ctx.font = 'normal normal 600 14px Roboto';
      ctx.fillText(this.titleText, headerXPosition + 24, 38);
      ctx.restore();

      // Бордер заголовка Incidents
      ctx.save();
      ctx.strokeStyle = '#e7e9ee';
      ctx.beginPath();
      ctx.moveTo(borderXPosition, 0);
      ctx.lineTo(borderXPosition, INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT);
      ctx.stroke();
      ctx.restore();

      this.drawHoverIndicator({ ctx, period: this.period, screenPosition });

      if (this.overlayComponent) {
        this.overlayComponent.draw(ctx, { translateAdd });
      }
    }
    ctx.restore();
  }
}

export default IncidentTimelineScale;
