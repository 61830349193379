import { handleActions } from 'redux-actions';

import {
  requestMeasurementsByPeriod,
  receiveMeasurementsByPeriod,
  requestMeasurementsPlantsList,
  receiveMeasurementsPlantsList,
  requestMeasurementsAdditionalMetrics,
  receiveMeasurementsAdditionalMetrics,
  requestMeasurementsByDate,
  receiveMeasurementsByDate,
  clearMeasurementsByDate,
  requestCreateNextPlant,
  receiveCreateNextPlant,
  requestUpdatePlant,
  receiveUpdatePlant,
  requestDeletePlant,
  receiveDeletePlant,
  receiveSaveMeasurementsAdditionalMetrics,
  receiveDefaultNumberOfPlants,
  requestDefaultNumberOfPlants,
  requestDefaultNumberOfPlantsUpdate,
  receiveDefaultNumberOfPlantsUpdate,
  requestManualFormsMetrics,
  receiveManualFormsMetrics,
  receiveSaveMeasurementsGraphMetrics,
} from 'store/measurements/actions';

const initialState = {
  isMeasurementsByPeriodFetching: false,
  isMeasurementsPlantsListFetching: false,
  isMeasurementsAdditionalMetricsFetching: false,

  isMeasurementsByPeriodSuccess: false,
  isMeasurementsPlantsListSuccess: false,
  isMeasurementsAdditionalMetricsSuccess: false,

  measurements: null,
  plantsList: null,
  additionalMetrics: null,

  measurementsByDate: null,
  isMeasurementsByDateFetching: false,
};

export default handleActions({
  [requestMeasurementsByPeriod](state) {
    return {
      ...state,
      isMeasurementsByPeriodFetching: true,
      isMeasurementsByPeriodSuccess: false,
      measurements: null,
    };
  },
  [receiveMeasurementsByPeriod](state, action) {
    return {
      ...state,
      isMeasurementsByPeriodFetching: false,
      isMeasurementsByPeriodSuccess: true,
      measurements: action.payload
    };
  },
  [requestMeasurementsPlantsList](state) {
    return {
      ...state,
      isMeasurementsPlantsListFetching: true,
      isMeasurementsPlantsListSuccess: false,
      plantsList: null,
    };
  },
  [receiveMeasurementsPlantsList](state, action) {
    return {
      ...state,
      isMeasurementsPlantsListFetching: false,
      isMeasurementsPlantsListSuccess: true,
      plantsList: action.payload
    };
  },
  [requestMeasurementsAdditionalMetrics](state) {
    return {
      ...state,
      isMeasurementsAdditionalMetricsFetching: true,
      isMeasurementsAdditionalMetricsSuccess: false,
      additionalMetrics: null
    };
  },
  [receiveMeasurementsAdditionalMetrics](state, action) {
    return {
      ...state,
      isMeasurementsAdditionalMetricsFetching: false,
      isMeasurementsAdditionalMetricsSuccess: true,
      additionalMetrics: action.payload
    };
  },
  [receiveSaveMeasurementsAdditionalMetrics](state, action) {
    return {
      ...state,
      additionalMetrics: {
        ...state?.additionalMetrics,
        selectedMetrics: action?.payload?.metricId || [],
      }
    };
  },
  [requestMeasurementsByDate](state) {
    return {
      ...state,
      isMeasurementsByDateFetching: true,
      measurementsByDate: null,
    };
  },
  [receiveMeasurementsByDate](state, action) {
    return {
      ...state,
      isMeasurementsByDateFetching: false,
      measurementsByDate: action.payload
    };
  },
  [clearMeasurementsByDate](state) {
    return {
      ...state,
      measurementsByDate: null,
    };
  },

  [requestCreateNextPlant](state) {
    return {
      ...state,
      isMeasurementsByDateFetching: true,
      measurementsByDate: null,
    };
  },
  [receiveCreateNextPlant](state, action) {
    return {
      ...state,
      isMeasurementsByDateFetching: false,
      measurementsByDate: action.payload
    };
  },
  [requestUpdatePlant](state) {
    return {
      ...state,
      isMeasurementsByDateFetching: true,
      measurementsByDate: null,
    };
  },
  [receiveUpdatePlant](state, action) {
    return {
      ...state,
      isMeasurementsByDateFetching: false,
      measurementsByDate: action.payload
    };
  },
  [requestDeletePlant](state) {
    return {
      ...state,
      isMeasurementsByDateFetching: true,
      measurementsByDate: null,
    };
  },
  [receiveDeletePlant](state, action) {
    return {
      ...state,
      isMeasurementsByDateFetching: false,
      measurementsByDate: action.payload
    };
  },
  [requestDefaultNumberOfPlants](state) {
    return {
      ...state,
      isDefaultNumberOfPlantsFetching: true,
      defaultNumberOfPlants: null,
    };
  },
  [receiveDefaultNumberOfPlants](state, action) {
    return {
      ...state,
      isDefaultNumberOfPlantsFetching: false,
      defaultNumberOfPlants: action.payload?.value,
    };
  },
  [requestDefaultNumberOfPlantsUpdate](state) {
    return {
      ...state,
      isDefaultNumberOfPlantsUpdateFetching: true,
    };
  },
  [receiveDefaultNumberOfPlantsUpdate](state, action) {
    return {
      ...state,
      isDefaultNumberOfPlantsUpdateFetching: false,
      defaultNumberOfPlants: action.payload?.value,
    };
  },
  [requestManualFormsMetrics](state) {
    return {
      ...state,
      isManualFormsMetricsFetching: true,
      manualFormsMetrics: null,
    };
  },
  [receiveManualFormsMetrics](state, action) {
    return {
      ...state,
      isManualFormsMetricsFetching: false,
      manualFormsMetrics: action.payload,
    };
  },
  [receiveSaveMeasurementsGraphMetrics](state, action) {
    return {
      ...state,
      measurements: {
        ...state.measurements,
        graphMetricsAndPlants: [...action.payload.metricId]
      }
    };
  }
}, initialState);
