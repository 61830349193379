import { isArray, find, get } from 'lodash';

import { uniqBy } from 'lodash/array';
import hex2rgba, { rgbaToHex } from 'helpers/hex2rgba';
import compareGraphMetricToGraphMetricGroup from '../compareGraphMetricToGraphMetricGroup';

// safe $-_.+!*'(),
// i can take $_+!*'
const GROUP_FIELDS_DELIMETER = '$';
const FIELDS_DELIMETER = '_';
const ARRAY_DELIMETER = '!';

export const mappingGraphPresetMetricsToUrl = ({ graphPresetMetrics, graphPresetMetricGroups }) => {
  if (!graphPresetMetrics || !isArray(graphPresetMetrics)) {
    return null;
  }

  if (!graphPresetMetricGroups || !isArray(graphPresetMetricGroups)) {
    return null;
  }

  return graphPresetMetricGroups
    .map((graphPresetMetricGroup) => {
      const locationId = get(graphPresetMetricGroup, 'location.id', '') || '';
      const compartmentId = get(graphPresetMetricGroup, 'compartment.id', '') || '';
      const subNodeId = get(graphPresetMetricGroup, 'subNode.id', '') || '';

      const metrics = graphPresetMetrics
        .sort((a, b) => a.attributes.position - b.attributes.position)
        .filter(compareGraphMetricToGraphMetricGroup(graphPresetMetricGroup))
        .map((item) => {
          const metricId = get(item, 'relationships.attributes.metricId') ||
            get(item, 'relationships.metric.data.0.id');

          const color = item?.attributes?.color ? rgbaToHex(item?.attributes?.color).slice(1) : '';
          const unselected = get(item, 'attributes.selected') ? '' : 1;
          const scale = get(item, 'attributes.scale');

          const scaleName = scale?.name;
          const invisible = scale?.invisible ? 1 : '';
          const rightAxis = scale?.rightAxis ? 1 : '';
          const start = scale?.range?.start;
          const end = scale?.range?.end;

          const { position } = item.attributes;

          const metricResult = [metricId, position, unselected, scaleName, invisible, rightAxis, start, end, color];

          let minifiedResult = metricResult.join(FIELDS_DELIMETER);

          while (minifiedResult.endsWith(FIELDS_DELIMETER)) {
            minifiedResult = minifiedResult.slice(0, minifiedResult.length - 1);
          }

          return minifiedResult;
        });

      const groupResult = [
        [locationId, compartmentId === '#' ? '' : compartmentId, subNodeId === '#' ? '' : subNodeId].join(FIELDS_DELIMETER),
        metrics.join(ARRAY_DELIMETER)
      ];

      return groupResult.join(GROUP_FIELDS_DELIMETER);
    });
};


export const mappingUrlToGraphPresetMetrics = ({
  nodeMetrics,
  location,
  allCompartments,
  allSubNodes,
  allGraphMetrics,
}) => {
  if (!nodeMetrics) {
    return null;
  }

  const nodeMetricsArray = isArray(nodeMetrics) ? nodeMetrics : [nodeMetrics];

  const graphPresetMetricGroups = nodeMetricsArray.map((nodeMetric, groupIndex) => {
    const [
      groupData,
      metricsData,
    ] = nodeMetric.split(GROUP_FIELDS_DELIMETER);

    const [
      locationId,
      compartmentId,
      subNodeId,
    ] = groupData.split(FIELDS_DELIMETER);

    const metrics = metricsData.split(ARRAY_DELIMETER);

    const compartment = find(allCompartments, { id: +compartmentId }) || null;
    const subNode = find(allSubNodes, { id: +subNodeId }) || null;

    const graphPresetMetricGroup = {
      id: groupIndex,
      type: 'GraphPresetMetricGroup',
      location,
      compartment: compartment || {
        id: '#',
        type: 'Compartment',
        attributes: { name: '#' },
        relationships: { location: { data: [] } }
      },
      subNode: subNode || (compartment ? {
        id: '#',
        type: 'SubNode',
        attributes: { name: '#', type: '' },
        relationships: { location: { data: [] }, compartment: { data: [] } }
      } : null),
    };

    const graphPresetMetrics = metrics.map((metric) => {
      const [
        metricId,
        position,
        unselected,
        scaleName,
        invisible,
        rightAxis,
        start,
        end,
        color
      ] = metric.split(FIELDS_DELIMETER);
      const startValue = start ? parseInt(start, 10) : null;
      const endValue = end ? parseInt(end, 10) : null;
      const posIndex = +position;
      return ({
        type: 'GraphPresetMetric',
        attributes: {
          position: posIndex,
          selected: !unselected,
          // color: hex2rgba(color ? `#${color}` : colors[posIndex]),
          color: color ? hex2rgba(`#${color}`) : null,
          location,
          compartment: find(allCompartments, { id: +compartmentId }) || null,
          subNode: find(allSubNodes, { id: +subNodeId }) || null,
          metric: find(allGraphMetrics, { id: +metricId }) || null,
          group: graphPresetMetricGroup,
          scale: {
            invisible: Boolean(invisible),
            rightAxis: Boolean(rightAxis),
            name: scaleName || null,
            range: Number.isInteger(startValue) ? {
              start: startValue,
              end: endValue
            } : null
          }
        },
        relationships: {
          compartment: compartmentId ? { data: [{ id: +compartmentId, type: 'Compartment' }] } : null,
          location: locationId ? { data: [{ id: +locationId, type: 'Location' }] } : null,
          metric: { data: [{ id: +metricId, type: 'Metric' }] },
          subNode: subNodeId ? { data: [{ id: +subNodeId, type: 'SubNode' }] } : null,
        }
      });
    });
    return {
      metrics: graphPresetMetrics,
    };
  });

  const flatMetrics = graphPresetMetricGroups
    .reduce((reducer, item) => {
      reducer.push(...item.metrics);

      return reducer;
    }, [])
    .sort((a, b) => a.attributes.position - b.attributes.position);

  const scales = uniqBy(flatMetrics.map(metric => metric.attributes.scale), scale => scale.name);
  return flatMetrics.length > 0 ? flatMetrics.map(metric => ({
    ...metric,
    relationships: {
      ...metric.relationships,
      scales,
    }
  })) : null;
};

export const mappingUrlToGraphPresetMetricGroups = ({
  nodeMetrics,
  location,
  allCompartments,
  allSubNodes,
}) => {
  if (!nodeMetrics) {
    return null;
  }
  const nodeMetricsArray = isArray(nodeMetrics) ? nodeMetrics : [nodeMetrics];
  return nodeMetricsArray.length > 0 ? nodeMetricsArray.map((nodeMetric, groupIndex) => {
    const [
      groupData,
      // eslint-disable-next-line no-unused-vars
      metricsData,
    ] = nodeMetric.split(GROUP_FIELDS_DELIMETER);

    const [
      // eslint-disable-next-line no-unused-vars
      locationId,
      compartmentId,
      subNodeId,
    ] = groupData.split(FIELDS_DELIMETER);

    const compartment = find(allCompartments, { id: +compartmentId }) || null;
    const subNode = find(allSubNodes, { id: +subNodeId }) || null;
    return {
      id: groupIndex,
      location,
      compartment: compartment || {
        id: '#',
        type: 'Compartment',
        attributes: { name: '#' },
        relationships: { location: { data: [] } }
      },
      subNode: subNode || (compartment ? {
        id: '#',
        type: 'SubNode',
        attributes: { name: '#', type: '' },
        relationships: { location: { data: [] }, compartment: { data: [] } }
      } : null),
    };
  }) : null;
};


export const mappingToGraphPresetGraphMetrics = ({
  nodeMetrics,
  location,
  allCompartments,
  allSubNodes,
  allGraphMetrics
}) => {
  if (!nodeMetrics || !isArray(nodeMetrics)) {
    return null;
  }

  return nodeMetrics.map(metric => ({
    type: 'GraphPresetMetric',
    attributes: {
      position: metric.position,
      rightAxis: metric.rightAxis,
      selected: metric.selected,
      color: metric.color,
      location,
      compartment: find(allCompartments, { id: metric.compartmentId }) || null,
      subNode: find(allSubNodes, { id: metric.subNodeId }) || null,
      metric: find(allGraphMetrics, { id: metric.metricId }) || null,
    },
    relationships: {
      compartment: metric.compartmentId ? {
        data: [{ id: metric.compartmentId, type: 'Compartment' }]
      } : null,
      location: metric.locationId ? {
        data: [{ id: metric.locationId, type: 'Location' }]
      } : null,
      metric: {
        data: [{ id: metric.metricId, type: 'Metric' }]
      },
      subNode: metric.subNodeId ? {
        data: [{ id: metric.subNodeId, type: 'SubNode' }]
      } : null
    }
  }));
};
