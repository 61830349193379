import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import SimpleBarChart
  from 'components/PlanFactReportDashboard/components/SimpleBarChart/components/SimpleBarChartWrapper/SimpleBarChartWrapper';
import HarvestForecastChartWrapper from 'components/HarvestForecastDashboard/components/HarvestForecastChartWrapper';
import Tooltip from 'components/PlanFactReportDashboard/components/Tooltip/Tooltip';

const renderCustomTooltip = ({
  className, x, y, item
}, roundTo) => (
  <Tooltip
    x={x}
    y={y}
    tooltipData={{ ...item, name: item.periodLabel }}
    className={className}
    roundTo={roundTo}
  />
);

const HarvestForecastBarChart = ({
  intl,
  items,
  legend,
  isFetching,
  indicators
}) => {
  const { formatMessage } = intl;
  return (
    <HarvestForecastChartWrapper
      legend={legend}
      indicators={indicators}
      roundTo={-1}
      title={formatMessage({ id: 'forecast.dashboard.barchartTitle' })}
      tooltipText={formatMessage({ id: 'forecast.dashboard.overallTooltip' })}
    >
      <SimpleBarChart
        items={items}
        fixedHeight={296}
        isFetching={isFetching}
        renderCustomTooltip={tooltipData => renderCustomTooltip(tooltipData, -1)}
        emptyTitle={formatMessage({ id: 'emptyStates.noData' })}
      />
    </HarvestForecastChartWrapper>
  );
};

HarvestForecastBarChart.propTypes = {
  intl: intlShape.isRequired,
  items: PropTypes.array,
  legend: PropTypes.array,
  isFetching: PropTypes.bool,
  indicators: PropTypes.array
};

HarvestForecastBarChart.defaultProps = {
  items: [],
  legend: [],
  indicators: [],
  isFetching: false,
};

export default injectIntl(HarvestForecastBarChart);
