import { get } from 'lodash';
import { createSelector } from 'reselect';

import { getRouteQueryParams } from '../router/selectors';

export const getHarvestKpi = state => get(state, 'harvestKpiDashboard.harvestKpi');
export const getHarvestKpiSpeciesOverall = state => get(state, 'harvestKpiDashboard.harvestKpi.speciesOverall');
export const getHarvestKpiBreakdown = state => get(state, 'harvestKpiDashboard.harvestKpi.breakdown');
export const getHarvestKpiFetching = state => get(state, 'harvestKpiDashboard.isHarvestKpiFetching');
export const getAllHarvestKpiFetching = state => get(state, 'harvestKpiDashboard.isAllHarvestKpiFetching');
export const getBreakdownHarvestKpiFetching = state => get(state, 'harvestKpiDashboard.isBreakdownHarvestKpiFetching');

export const getPeriodTypeFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'periodType'),
);

export const getBreakdownTypeFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'breakdown'),
);

export const getRelativeFromRoute = createSelector(
  [getRouteQueryParams],
  query => (get(query, 'isRelative') ? JSON.parse(get(query, 'isRelative')) : undefined),
);

export const getAnyDateOfPeriodFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'anyDateOfPeriod'),
);

export const getCategoryFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'category') || undefined,
);
