import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { get, isEmpty, isNil } from 'lodash';
import moment from 'moment-timezone';

import LinesGraph from '../../../LinesGraph';

import LINE_COLORS from '../../../../helpers/graphColors';
import { findGetAndRoundingValue, getLocalizedCategoryName, getUnits } from '../../../../helpers/getValueDetails';
import numbersFormatting from '../../../../helpers/numbersFormatting';

import LinesGraphTooltip from '../../../LinesGraphTooltip';

import styles from './QualityGraph.module.css';

class QualityGraph extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    isDataFetching: PropTypes.bool,
    points: PropTypes.array,
    maxValue: PropTypes.number.isRequired,
    periodType: PropTypes.string,
    roundTo: PropTypes.number,
    harvestCategories: PropTypes.array,
    percentageForQuality: PropTypes.bool.isRequired,
    cumulativeForQuality: PropTypes.bool.isRequired,
    harvestByCategoriesEnabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isDataFetching: false,
    points: [],
    periodType: null,
    roundTo: 1,
    harvestCategories: [],
  };

  generateLineByPoints = (dataPoints) => {
    if (dataPoints) {
      return Object.keys(dataPoints).reduce((acc, key) => {
        const date = moment.utc(new Date((+key) * 60000));

        return [...acc, { x: date.format('MM-DD-YYYY'), y: dataPoints[key] }];
      }, []);
    }

    return [];
  };

  generateLines = (points) => {
    const { intl: { formatMessage, locale }, harvestCategories, percentageForQuality } = this.props;

    return points.reduce((acc, item) => {
      const dataPoints = percentageForQuality ? get(item, 'percentagePoints') : get(item, 'dataPoints');
      const extraPoints = percentageForQuality ? get(item, 'dataPoints') : get(item, 'percentagePoints');
      const percentageUnits = { unit: 'percent' };

      if (isEmpty(dataPoints)) {
        return acc;
      }

      const localizedCategoryName = getLocalizedCategoryName(item.discriminator, locale, harvestCategories);
      const categoryName = localizedCategoryName || `Category ${item.discriminator}`;

      // Не берём номер в исходном массиве, т.к. могут быть nullable линии, что приведёт к пропуску цветов из палитры
      const colorNumber = acc.length;

      return [...acc, {
        id: item.discriminator,
        key: `quality-line-${item.discriminator}`,
        points: this.generateLineByPoints(dataPoints),
        extraPoints: this.generateLineByPoints(extraPoints),
        name: categoryName,
        color: LINE_COLORS[colorNumber] || LINE_COLORS[0],
        units: getUnits(percentageForQuality ? percentageUnits : item, formatMessage, 'unit'),
        extraUnits: getUnits(percentageForQuality ? item : percentageUnits, formatMessage, 'unit')
      }];
    }, []).sort((a, b) => a.id - b.id);
  };

  renderLineValue = ({
    points, extraPoints, units, extraUnits
  }, tooltipDate) => {
    const {
      intl: { formatMessage }, roundTo, cumulativeForQuality,
    } = this.props;

    const getUnitsSpace = currentUnits => (currentUnits === '%' ? '' : ' ');

    const currentDate = tooltipDate ? tooltipDate.format('MM-DD-YYYY') : undefined;
    const amount = numbersFormatting(findGetAndRoundingValue(points, currentDate, roundTo));
    const extraAmount = numbersFormatting(findGetAndRoundingValue(extraPoints, currentDate, roundTo));

    if (isNil(amount)) {
      return formatMessage({ id: 'crops.noData' });
    }

    return (
      <span>
        <span>{`${amount}${getUnitsSpace(units)}${units}`}</span>
        {!cumulativeForQuality &&
          <span className={styles.tooltipPercent}>{` (${extraAmount}${getUnitsSpace(extraUnits)}${extraUnits})`}</span>}
      </span>
    );
  }

  renderTooltipContent = (tooltipDate) => {
    const {
      points, periodType
    } = this.props;

    const lines = this.generateLines(points);

    const generatedLines = lines.map(line => ({
      id: line.id,
      value: this.renderLineValue(line, tooltipDate),
      header: line.name,
      color: line.color
    }));

    return (
      <LinesGraphTooltip
        lines={generatedLines}
        tooltipDate={tooltipDate}
        periodType={periodType}
      />
    );
  };

  render() {
    const {
      isDataFetching, points, maxValue, harvestByCategoriesEnabled,
    } = this.props;

    const lines = this.generateLines(points);

    return (
      <LinesGraph
        isDataFetching={isDataFetching}
        lines={lines}
        maxValue={maxValue}
        renderTooltipContent={this.renderTooltipContent}
        harvestByCategoriesEnabled={harvestByCategoriesEnabled}
        graphType='qualityGraph'
      />
    );
  }
}

export default injectIntl(QualityGraph);
