import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { reduxForm, Field } from 'redux-form';

import {
  isEmpty, toNumber, uniqueId, groupBy, find, filter
} from 'lodash';
import classnames from 'classnames';

import { showIntercom } from 'helpers/intercomHelpers';
import { getVarietyName } from 'helpers/getVarietyName';

import PageViewTracker from 'components/PageViewTracker';
import Typography from 'components/Typography';
import BigButton from 'components/BigButton';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import DefaultPlate from 'components/DefaultPlate';
import DefaultTextInput from 'components/DefaultTextInput';

import SettingsMeasurementsList from '../SettingsMeasurementsList';

import styles from './SettingsMeasurements.module.css';

const validate = (values, props) => {
  const { intl: { formatMessage } } = props;
  const errors = {};

  if (!values.plantsToEnter) {
    errors.plantsToEnter = formatMessage({ id: 'formErrors.requiredField' });
  }

  if (values.plantsToEnter && values.plantsToEnter > 100) {
    errors.plantsToEnter = formatMessage({ id: 'formErrors.cannotBeExceed' }, { number: 100 });
  }

  return errors;
};

const normalizeFieldValue = (value, previousValue) => {
  const convertedValue = toNumber(value);

  return !convertedValue && convertedValue !== 0 ? previousValue : value;
};

const normalizeMetricsList = (intl, organizationSlug, varieties, configs = []) => {
  if (configs.length === 0) {
    return [];
  }

  const { formatMessage, locale } = intl;

  const groupedConfigs = groupBy(configs, 'species');
  const metricsList = Object.keys(groupedConfigs).map((key) => {
    const speciesConfigs = find(groupedConfigs[key], { varietyId: null });
    const varietyConfigs = filter(groupedConfigs[key], varietyConfig => varietyConfig?.varietyId !== null);

    const speciesCategoryMetrics = speciesConfigs?.categoryMetrics.reduce((acc, item) => ([...acc, ...item.metrics]), []);

    const varietyMetricsList = varietyConfigs.map((varietyItem) => {
      const varietyCategoryMetrics = varietyItem?.categoryMetrics.reduce((acc, item) => ([...acc, ...item.metrics]), []);
      const currentVariety = find(varieties, { id: varietyItem.varietyId });
      const varietyName = getVarietyName(currentVariety, locale);

      return {
        groupName: varietyName,
        href: `/${organizationSlug}/settings/crop-cycles/measurements/${varietyItem.varietyId}`,
        metricsCount: varietyCategoryMetrics.length,
      };
    });

    return {
      groupName: formatMessage({ id: `plantingCycles.species.${key}` }),
      list: [
        {
          groupName: formatMessage({ id: `plantingCycles.allSpecies.${key}` }),
          href: `/${organizationSlug}/settings/crop-cycles/measurements/${key}`,
          metricsCount: speciesCategoryMetrics.length,
          list: varietyMetricsList,
        }
      ]
    };
  });

  return metricsList;
};

const renderInput = (props) => {
  const {
    input,
    meta: { error },
    formatMessage,
  } = props;

  return (
    <DefaultTextInput
      {...input}
      placeholder={formatMessage({ id: 'settings.plantsToEnterByDefault' })}
      className={styles.field}
      error={error}
    />
  );
};

const renderMetrics = metricsList => metricsList.map(metric => (
  <div key={`metric-${uniqueId()}`}>
    <Typography variant='h4' className={styles.metricName}>
      {metric?.groupName}
    </Typography>
    <SettingsMeasurementsList list={metric?.list} className={styles.settingsMeasurementsList} />
  </div>
));

const SettingsMeasurements = ({
  intl,
  intl: { formatMessage },
  organizationSlug,
  manualFormsMetrics,
  isSettingsFetching,
  isPlantsToEnterUpdated,
  formValues,
  formSyncErrors,
  initialValues,
  varieties,
  defaultOpen,

  reset,
  requestDefaultNumberOfPlantsUpdate,
  trackPageViewSettings,
}) => {
  const handlerPageView = useCallback(() => {
    trackPageViewSettings({ settingName: 'Measurements', defaultOpen });
  }, [trackPageViewSettings, defaultOpen]);

  const handlerSubmitForm = (e) => {
    e.preventDefault();

    if (!formValues || !isEmpty(formSyncErrors)) {
      return null;
    }

    const { plantsToEnter } = formValues;

    return requestDefaultNumberOfPlantsUpdate({
      value: toNumber(plantsToEnter),
      initialValue: initialValues?.plantsToEnter,
    });
  };

  const handlerCancel = () => reset();

  const metricsList = normalizeMetricsList(intl, organizationSlug, varieties, manualFormsMetrics?.configs);

  const isPlantsToEnterInvalid = !isEmpty(formSyncErrors);
  const isPlantsToEnterChanged = toNumber(initialValues?.plantsToEnter) !== toNumber(formValues?.plantsToEnter);

  if (!initialValues?.plantsToEnter) {
    return (
      <div className={styles.settingsMeasurements}>
        <DefaultCircleLoader />
      </div>
    );
  }

  return (
    <div className={styles.settingsMeasurements}>
      <PageViewTracker onMount={handlerPageView} />

      {isSettingsFetching && <DefaultCircleLoader />}

      <Typography variant='h2' className={styles.header}>
        {formatMessage({ id: 'settings.measurementsInCycles' })}
      </Typography>
      <Typography variant='subtitle3' className={styles.subtitle}>
        {formatMessage({ id: 'settings.managePlants' })}
      </Typography>
      <DefaultPlate className={styles.infoPlate}>
        <div>
          {formatMessage({ id: 'settings.toChangeMetrics' })}
          &nbsp;
          <BigButton
            className={styles.supportButton}
            onClick={showIntercom}
            title={formatMessage({ id: 'settings.contactSupport' })}
            theme='transparent'
          />
        </div>
      </DefaultPlate>
      <div>
        <Typography variant='h3' className={classnames(styles.subtitle, styles.plantsSubtitle)}>
          {formatMessage({ id: 'settings.plants' })}
        </Typography>

        <form
          className={styles.plantsForm}
          onSubmit={handlerSubmitForm}
        >
          <Field
            name='plantsToEnter'
            component={renderInput}
            className={styles.textInput}
            isLoading={isPlantsToEnterUpdated}
            formatMessage={formatMessage}
            normalize={normalizeFieldValue}
          />

          {isPlantsToEnterChanged && (
            <div className={styles.buttons}>
              <BigButton
                title={formatMessage({ id: 'button.saveChanges' })}
                type='submit'
                theme='dark'
                disabled={isPlantsToEnterInvalid}
                isLoading={isPlantsToEnterUpdated}
              />
              <BigButton
                className={styles.cancelButton}
                title={formatMessage({ id: 'button.cancel' })}
                type='button'
                onClick={handlerCancel}
                theme='light'
                disabled={isPlantsToEnterUpdated}
              />
            </div>
          )}
        </form>
      </div>
      <Typography variant='h3' className={classnames(styles.subtitle, styles.metricsSubtitle)}>
        {formatMessage({ id: 'crops.metrics' })}
      </Typography>
      {renderMetrics(metricsList)}
    </div>
  );
};

SettingsMeasurements.propTypes = {
  intl: intlShape.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  isSettingsFetching: PropTypes.bool,
  isPlantsToEnterUpdated: PropTypes.bool,
  formValues: PropTypes.object,
  formSyncErrors: PropTypes.object,
  initialValues: PropTypes.object,
  manualFormsMetrics: PropTypes.object,
  defaultOpen: PropTypes.bool,
  varieties: PropTypes.array.isRequired,

  reset: PropTypes.func.isRequired,
  requestDefaultNumberOfPlantsUpdate: PropTypes.func.isRequired,
  trackPageViewSettings: PropTypes.func.isRequired,
};

SettingsMeasurements.defaultProps = {
  isSettingsFetching: false,
  isPlantsToEnterUpdated: false,
  formValues: null,
  formSyncErrors: null,
  initialValues: null,
  manualFormsMetrics: null,
  defaultOpen: false,
};

export default memo(reduxForm({
  form: 'plantsToEnterForm',
  validate,
  enableReinitialize: true,
})(SettingsMeasurements));
