import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import ColorToneSelector from 'components/Graphs/components/ColorPicker/components/ColorToneSelector';
import ColorInput from 'components/Graphs/components/ColorPicker/components/ColorInput';
import HueSlider from 'components/Graphs/components/ColorPicker/components/HueSlider';
import AlphaSlider from 'components/Graphs/components/ColorPicker/components/AlphaSlider';

import styles from './index.module.css';

const CustomColorSelector = ({ initValue, onChange }) => {
  const [baseColor, setBaseColor] = useState(initValue);

  const handleChangeToon = useCallback((value) => {
    onChange(value);
  }, [onChange]);

  const handleChangeHue = useCallback(
    (value) => {
      setBaseColor(value);
      onChange(value);
    },
    [onChange]
  );

  const handleChangeAlpha = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange]
  );

  const handleChangeInput = useCallback((value) => {
    setBaseColor(value);
    onChange(value);
  }, [onChange]);

  return (
    <div className={styles.container}>
      <ColorToneSelector baseColor={baseColor} initValue={initValue} onChange={handleChangeToon} />
      <div className={styles.rightBlock}>
        <div className={styles.sliders}>
          <div className={styles.hueWrapper}><HueSlider initValue={baseColor} onChange={handleChangeHue} /></div>
          <AlphaSlider initValue={initValue} onChange={handleChangeAlpha} />
        </div>
        <ColorInput initValue={initValue} onChange={handleChangeInput} />
      </div>
    </div>
  );
};


CustomColorSelector.propTypes = {
  initValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CustomColorSelector.defaultProps = {
  initValue: 'rgba(255,255,255,1)'
};

export default CustomColorSelector;
