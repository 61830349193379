import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { NavLink } from 'react-router-dom';

import moment from 'moment-timezone';
import classnames from 'classnames';

import { getDataQualityColor, getIsAlertVisible } from 'helpers/getDataQualityInfo';

import DropdownMenu from 'components/DropdownMenu';
import OverallQuality from 'components/OverallQuality';
import DataUsageIcon from 'components/Icons/DataUsageIcon';

import styles from './DataQualityNotification.module.css';

const HOURS_TO_UPDATE = 8;

export default class DataQualityNotification extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    organizationSlug: PropTypes.string.isRequired,
    overallQuality: PropTypes.number,
    overallQualityReceivedAt: PropTypes.number,

    requestOverallQuality: PropTypes.func.isRequired,
  };

  static defaultProps = {
    overallQuality: null,
    overallQualityReceivedAt: Date.now(),
  };

  state = {
    closeDropdown: false,
  };

  componentDidMount() {
    const { overallQuality, requestOverallQuality } = this.props;
    const currentYear = moment(new Date()).format('YYYY');
    const isOverallQualityExpired = this.checkOverallQualityExpired();

    if (!overallQuality || isOverallQualityExpired) {
      requestOverallQuality({
        year: currentYear,
        detailed: false,
        onError: this.handlerAuthError,
      });
    }

    this.intervalId = setInterval(this.setIntervalOfExpiredCheck, 3600000);
  }


  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  setIntervalOfExpiredCheck = () => {
    const { requestOverallQuality } = this.props;

    const currentYear = moment(new Date()).format('YYYY');
    const isOverallQualityExpired = this.checkOverallQualityExpired();

    if (isOverallQualityExpired) {
      requestOverallQuality({
        year: currentYear,
        detailed: false,
        onError: this.handlerAuthError,
      });
    }
  };

  checkOverallQualityExpired = () => {
    const { overallQualityReceivedAt } = this.props;

    const now = moment();
    const then = moment(overallQualityReceivedAt);

    const timeDifference = moment.duration(now.diff(then)).get('hours');

    if (timeDifference >= HOURS_TO_UPDATE) {
      return true;
    }

    return false;
  };

  handlerAuthError = () => clearInterval(this.intervalId);

  handlerCloseMenu = () => this.setState({ closeDropdown: true });

  renderButton = () => {
    const { overallQuality } = this.props;

    const isAlertVisible = getIsAlertVisible(overallQuality);
    const color = getDataQualityColor(overallQuality);

    return (
      <div
        className={styles.dataQualityNotification}
        onClick={() => this.setState({ closeDropdown: false })}
        role='button'
        tabIndex={0}
      >
        {isAlertVisible && (
          <div className={styles.alertBageWrapper}>
            <div
              className={classnames(
                styles.alertBage,
                {
                  [styles.red]: color === 'red',
                  [styles.yellow]: color === 'yellow',
                  [styles.green]: color === 'green',
                }
              )}
            >
              {`${overallQuality}%`}
            </div>
          </div>
        )}
        <DataUsageIcon />
      </div>
    );
  }


  render() {
    const {
      intl: { formatMessage },
      overallQuality,
      organizationSlug,
    } = this.props;

    const { closeDropdown } = this.state;

    return (
      <DropdownMenu
        contentClassName={styles.dropdownContent}
        buttonElement={this.renderButton()}
        closeDropdown={closeDropdown}
      >
        <div>
          <OverallQuality
            percent={overallQuality}
            className={styles.overallQuality}
            textClassName={styles.overallQualityText}
          />

          <div className={styles.linkWrapper}>
            <NavLink
              className={styles.link}
              to={`/${organizationSlug}/settings/data-quality`}
              onClick={this.handlerCloseMenu}
            >
              {formatMessage({ id: 'dataQuality.openReport' })}
            </NavLink>
          </div>
        </div>
      </DropdownMenu>
    );
  }
}
