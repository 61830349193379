import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router';


import { requestEnergyReport, updateEnergyReportSorting } from 'store/energy/actions';

import { getIsEnergyReportFetching } from 'store/energy/selectors';

import {
  getAllFruitClasses,
  getAllPlantingCycles,
  getAllProductGroups,
  getCurrentCompartments,
  getSpeciesFromRoute,
  getVarietiesInLocationWithTargetWeight,
  getIntl,
  getUnitKindFromRoute,
  getBreakdownTypeFromRoute,
  getVarietyIdFromRoute,
  getFruitClassFromRoute,
  getAnyDateOfPeriodStartFromRoute,
  getAnyDateOfPeriodEndFromRoute
} from 'store/company/selectors';

import { getLaborWorkTypes } from 'store/labor/selectors';
import { getPeriodType } from 'store/plans/selectors';
import { getRowName } from 'helpers/getRowName';
import { getSelectedRow } from 'store/dashboards/selectors';

import DashboardReportSelectedChart from './DashboardReportSelectedChart';

const mapDispatchToProps = {
  requestEnergyReport,
  updateEnergyReportSorting,
};

const getFruitClassFromProps = (state, props) => props.currentFruitClassCode;
const getBreakdownTypeFromProps = (state, props) => props.currentBreakdown;
const getVarietyIdFromProps = (state, props) => props.currentVarietyId;

const getSelectedRowTitle = createSelector(
  [
    getSelectedRow,
    getIntl,
    getLaborWorkTypes,
    getCurrentCompartments,
    getAllFruitClasses,
    getVarietiesInLocationWithTargetWeight,
    getAllProductGroups,
    getAllPlantingCycles,
    getBreakdownTypeFromProps,
    getSpeciesFromRoute,
    getFruitClassFromProps,
    getVarietyIdFromProps,
  ],
  (selectedRow, intl, workTypes, compartments, fruitClasses, varieties, productGroups, allPlantingCycles, currentBreakdown = 'compartment', currentSpecies, currentFruitClassCode, currentVarietyId) => {
    let selectedRowTitle = getRowName({
      type: get(selectedRow, 'type'),
      ref: get(selectedRow, 'ref'),
      intl,
      workTypes,
      compartments,
      fruitClasses,
      varieties,
      productGroups,
      allPlantingCycles,
      currentBreakdown,
      currentSpecies,
      currentFruitClassCode,
      currentVarietyId,
    });

    if (!get(selectedRow, 'ref')) {
      selectedRowTitle = selectedRowTitle.toLowerCase();
    }

    return selectedRowTitle;
  },
);

const mapStateToProps = (state, props) => ({
  selectedRowTitle: getSelectedRowTitle(state, props),
  selectedRow: getSelectedRow(state, props),

  isDataFetching: getIsEnergyReportFetching(state, props),
  varieties: getVarietiesInLocationWithTargetWeight(state, props),
  compartments: getCurrentCompartments(state),
  fruitClasses: getAllFruitClasses(state, props),
  currentUnitKind: getUnitKindFromRoute(state, props),

  periodType: getPeriodType(state, props),

  productGroups: getAllProductGroups(state, props),
  allPlantingCycles: getAllPlantingCycles(state),
  workTypes: getLaborWorkTypes(state, props),
  currentBreakdown: getBreakdownTypeFromRoute(state, props),
  currentSpecies: getSpeciesFromRoute(state, props),
  currentVarietyId: getVarietyIdFromRoute(state, props),
  currentFruitClassCode: getFruitClassFromRoute(state, props),

  anyDateOfPeriodStart: getAnyDateOfPeriodStartFromRoute(state, props),
  anyDateOfPeriodEnd: getAnyDateOfPeriodEndFromRoute(state, props)
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardReportSelectedChart)));
