import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { findIndex } from 'lodash';

import {
  requestFeedbackSending,
  receiveFeedbackSending,
  clearFeedbackSending,
  requestIncidentById,
  receiveIncidentById,
  requestIncidentTypes,
  receiveIncidentTypes,
  requestIncidentTypeCategories,
  receiveIncidentTypeCategories,
  requestUpdateIncidentState,
  receiveUpdateIncidentState,
  requestIncidentPlot,
  receiveIncidentPlot,
  requestAlertRules,
  receiveAlertRules,
  receiveUpdateAlertState,
} from './actions';

const initialState = {
  isFeedbackSendingFetching: false,
  sendingError: null,
  successfulSending: false,
  incidentData: null,
  isIncidentFetching: false,
  isIncidentTypesFetching: true,
  incidentTypes: undefined,
  isIncidentTypeCategoriesFetching: true,
  incidentTypeCategories: undefined,
  isIncidentStateFetching: false,
  isIncidentPlotFetching: false,
  incidentPlot: undefined,
  alertRules: undefined,
  isAlertRulesFetching: false,
};

export default handleActions({
  [receiveUpdateAlertState](state, action) {
    return {
      ...state,
      alertRules: state?.alertRules?.map((alertRule) => {
        if (alertRule.id === action.payload.id) {
          return {
            ...alertRule,
            disabled: action.payload.disabled,
          };
        }

        return alertRule;
      }),
    };
  },
  [requestAlertRules](state) {
    return {
      ...state,
      isAlertRulesFetching: true,
    };
  },
  [receiveAlertRules](state, action) {
    return {
      ...state,
      isAlertRulesFetching: false,
      alertRules: action.payload,
    };
  },
  [requestIncidentPlot](state) {
    return {
      ...state,
      isIncidentPlotFetching: true,
    };
  },
  [receiveIncidentPlot](state, action) {
    return {
      ...state,
      isIncidentPlotFetching: false,
      incidentPlot: action.payload,
    };
  },
  [requestUpdateIncidentState](state) {
    return {
      ...state,
      isIncidentStateFetching: true,
    };
  },
  [receiveUpdateIncidentState](state, action) {
    /**
     * Обновляем стор, после успешного запроса на изменение инцидента. Делаем это, т.к. для
     * optimistic update на странице используем переключение стейта, а не результат стора.
     * В целом, это делать необязательно, т.к. enabled берётся из стейта, но на всякий случай
     * сделал, вдруг ещё где-то будет использоваться этот массив.
     */
    const incidentIndex = findIndex(state.incidentTypes, { id: Number(action.payload.id) });
    const updatedArray = update(
      state.incidentTypes,
      {
        [incidentIndex]: { enabled: { $set: action.payload.enabled } }
      }
    );

    return {
      ...state,
      isIncidentStateFetching: false,
      incidentTypes: updatedArray,
    };
  },
  [requestIncidentTypeCategories](state) {
    return {
      ...state,
      isIncidentTypeCategoriesFetching: true,
    };
  },
  [receiveIncidentTypeCategories](state, action) {
    return {
      ...state,
      isIncidentTypeCategoriesFetching: false,
      incidentTypeCategories: action.payload,
    };
  },
  [requestIncidentTypes](state) {
    return {
      ...state,
      isIncidentTypesFetching: true,
    };
  },
  [receiveIncidentTypes](state, action) {
    return {
      ...state,
      isIncidentTypesFetching: false,
      incidentTypes: action.payload,
    };
  },
  [requestIncidentById](state) {
    return {
      ...state,
      isIncidentFetching: true,
    };
  },
  [receiveIncidentById](state, action) {
    return {
      ...state,
      isIncidentFetching: false,
      incidentData: action.payload,
    };
  },
  [requestFeedbackSending](state) {
    return {
      ...state,
      isFeedbackSendingFetching: true,
    };
  },
  [receiveFeedbackSending](state, action) {
    return {
      ...state,
      isFeedbackSendingFetching: false,
      sendingError: action.payload.sendingError,
      successfulSending: action.payload.successfulSending,
    };
  },
  [clearFeedbackSending](state) {
    return {
      ...state,
      isFeedbackSendingFetching: false,
      sendingError: null,
      successfulSending: false,
    };
  },
}, initialState);
