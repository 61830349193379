import React, {
  useCallback,
  ReactElement, useMemo,
} from 'react';

import classnames from 'classnames';
import { isFinite } from 'lodash';

// @ts-ignore
import { injectIntl, InjectedIntlProps } from 'react-intl';

import { TreeNodeIdTypes } from 'store/graphs/types';
import { ITreeSearchData } from 'store/graphs/actions';

import styles from './PanelMetricTabs.module.css';


export type OnTabSelectFunc = (tabType: TreeNodeIdTypes) => void;

export type PanelMetricTabsProps = {
  currentTab: TreeNodeIdTypes;
  onTabSelect: OnTabSelectFunc;
  children: ReactElement[];
  metricsSearchData: ITreeSearchData | null;
};

function getMatchesCount(metricsSearchData: ITreeSearchData | null, type: TreeNodeIdTypes) {
  if (!metricsSearchData) {
    return null;
  }
  const matchesData = metricsSearchData?.matches.find((item:any) => item.tree === type);
  return matchesData ? matchesData.count : null;
}

const DISABLE_EQUIPMENT_TAB = true;

const PanelMetricTabs = ({
  intl,
  currentTab,
  onTabSelect,
  children,
  metricsSearchData
}: PanelMetricTabsProps & InjectedIntlProps) => {
  const { formatMessage } = intl;

  const handleSelectTab = useCallback(
    (tab: TreeNodeIdTypes) => {
      onTabSelect(tab);
    },
    [onTabSelect]
  );

  const compartmentMatchesCount = useMemo(() => getMatchesCount(metricsSearchData, 'g'), [metricsSearchData]);
  const plantingCycleMatchesCount = useMemo(() => getMatchesCount(metricsSearchData, 'c'), [metricsSearchData]);
  const equipmentMatchesCount = useMemo(() => getMatchesCount(metricsSearchData, 'e'), [metricsSearchData]);

  return (
    <div className={styles.tabs}>
      <div className={styles.tabNavsWrapper}>
        <div className={styles.tabNavs}>
          <div
            className={classnames(styles.tab, {
              [styles.active]: currentTab === 'g',
            })}
            role='button'
            tabIndex={0}
            onClick={() => handleSelectTab('g')}
          >
            {formatMessage({ id: 'graphs.metricPanel.tabs.compartment' })}
            {isFinite(compartmentMatchesCount) && (<span className={styles.matchCount}>{compartmentMatchesCount}</span>)}
          </div>
          <div
            className={classnames(styles.tab, {
              [styles.active]: currentTab === 'c',
            })}
            role='button'
            tabIndex={0}
            onClick={() => handleSelectTab('c')}
          >
            {formatMessage({ id: 'graphs.metricPanel.tabs.plantingCycle' })}
            {isFinite(plantingCycleMatchesCount) && (<span className={styles.matchCount}>{plantingCycleMatchesCount}</span>)}
          </div>
          {!DISABLE_EQUIPMENT_TAB && (
            <div
              className={classnames(styles.tab, {
                [styles.active]: currentTab === 'e',
              })}
              role='button'
              tabIndex={0}
              onClick={() => handleSelectTab('e')}
            >
              {formatMessage({ id: 'graphs.metricPanel.tabs.equipment' })}
              {isFinite(equipmentMatchesCount) && (<span className={styles.matchCount}>{equipmentMatchesCount}</span>)}
            </div>
          )}
        </div>
      </div>
      <div className={styles.tabContainer}>{children}</div>
    </div>
  );
};

export default injectIntl(PanelMetricTabs);
