import React, { useCallback } from 'react';


import {MetricDef, PresetMetricScale, TreeNodePathComponents} from 'store/graphs/types';

import ScaleDrop from 'components/Graphs/components/ScaleDrop';


import CellDefault from '../CellDefault';

// eslint-disable-next-line import/no-unresolved
import styles from '../../index.module.css';

export type OnDeleteScaleFunc = (
  presetMetric: Array<TreeNodePathComponents>,
  scale: PresetMetricScale,
) => void;

export type OnChangeScaleFunc = (
  presetMetric: Array<TreeNodePathComponents>,
  scale: PresetMetricScale,
) => void;

export type OnNewScaleFunc = (
  presetMetric: Array<TreeNodePathComponents>,
  scale: PresetMetricScale,
) => void;

export type OnSelectScaleFunc = (
  presetMetric: Array<TreeNodePathComponents>,
  scale: PresetMetricScale,
) => void;

type CellScaleProp = {
  presetMetric: MetricDef;
  headerKey: string;
  groupId: number;
  // eslint-disable-next-line react/require-default-props
  isMetricDatasFetching?: boolean;
  scales: Array<PresetMetricScale>;
  scale: string | null;
}

type CellScaleFunc = {
  onDelete: OnDeleteScaleFunc;
  onChange: OnChangeScaleFunc;
  onNew: OnNewScaleFunc;
  onSelect: OnSelectScaleFunc;
}

const CellScale = ({
  presetMetric,
  groupId,
  headerKey,
  scales,
  scale,
  isMetricDatasFetching = false,
  onDelete,
  onChange,
  onNew,
  onSelect
}: CellScaleProp & CellScaleFunc) => {
  const handleDeleteScale = useCallback((value: PresetMetricScale) => {
    onDelete(presetMetric.node, value);
  }, [presetMetric, onDelete]);
  const handleChangeScale = useCallback((value: PresetMetricScale) => {
    onChange(presetMetric.node, value);
  }, [presetMetric, onChange]);
  const handleNewScale = useCallback((value: PresetMetricScale) => {
    onNew(presetMetric.node, value);
  }, [presetMetric, onNew]);
  const handleSelectScale = useCallback((value: PresetMetricScale) => {
    onSelect(presetMetric.node, value);
  }, [presetMetric, onSelect]);
  const selectedScale = scales.find(item => item.name === scale);
  return (
    <CellDefault
      presetMetric={presetMetric}
      headerKey={headerKey}
      groupId={groupId}
      isMetricDatasFetching={isMetricDatasFetching}
      className={styles.scale}
      align='left'
    >
      <ScaleDrop
        scales={scales}
        selectedScale={selectedScale}
        onDelete={handleDeleteScale}
        onChange={handleChangeScale}
        onNewScale={handleNewScale}
        onSelect={handleSelectScale}
      />
    </CellDefault>
  );
};

export default CellScale;
