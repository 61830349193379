import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Header from '../Header';
import LanguageDialog from '../LanguageDialog';
import ErrorDialog from '../ErrorDialog';
import CircleLoader from '../CircleLoader';
import styles from './Page.module.css';

import NotificationCenter from '../NotificationCenter';
import circleLoaderStyles from '../CircleLoader/CircleLoader.module.css';


export default class Page extends Component {
  static propTypes = {
    setIntl: PropTypes.func.isRequired,
    getLocation: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    errorDialogMessage: PropTypes.string,
    userProfile: PropTypes.object,
    locations: PropTypes.array,
    location: PropTypes.object,
    organization: PropTypes.object,
    locale: PropTypes.string,
    isStatic: PropTypes.bool,

    errorDialogOpen: PropTypes.bool.isRequired,
    tableCellEditDialogOpen: PropTypes.bool.isRequired,
    photoDetailDialogOpen: PropTypes.bool.isRequired,
    photoUploadDialogOpen: PropTypes.bool.isRequired,
    photoDeleteDialogOpen: PropTypes.bool.isRequired,
    cropCreateDialogOpen: PropTypes.bool.isRequired,
    graphPresetCreateDialogOpen: PropTypes.bool.isRequired,
    graphPresetUpdateDialogOpen: PropTypes.bool.isRequired,
    graphPresetDeleteDialogOpen: PropTypes.bool.isRequired,
    graphPresetDatesDialogOpen: PropTypes.bool.isRequired,
    graphPresetParametersDialogOpen: PropTypes.bool.isRequired,
    graphPresetChangeWorkspaceDialogOpen: PropTypes.bool.isRequired,
    sidePanelOpen: PropTypes.bool,

    isFetching: PropTypes.bool.isRequired,

    setToggleErrorDialog: PropTypes.func.isRequired,

    clearCardsState: PropTypes.func.isRequired,

    setNavigationMenuState: PropTypes.func.isRequired,
    isNavigationMenuOpened: PropTypes.bool.isRequired,

    isNoScroll: PropTypes.bool,
    isLocationSpecificUrl: PropTypes.bool,

    clearCompanyStore: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isNoScroll: false,
    userProfile: null,
    locations: null,
    organization: null,
    location: null,
    locale: 'en',
    errorDialogMessage: '',
    sidePanelOpen: false,
    isLocationSpecificUrl: false,
    isStatic: false,
  };

  state = {
    languageDialogOpen: false,
  };

  handlerLanguageDialogClose = async () => {
    this.setState({
      languageDialogOpen: false,
    });
  };

  handlerErrorDialogClose = async () => {
    const { setToggleErrorDialog } = this.props;
    setToggleErrorDialog(false);
  };

  render() {
    const {
      locale,
      children,
      userProfile,
      locations,
      location,
      organization,
      getLocation,
      errorDialogMessage,
      setIntl,

      graphPresetDatesDialogOpen,
      graphPresetParametersDialogOpen,
      graphPresetCreateDialogOpen,
      graphPresetUpdateDialogOpen,
      graphPresetDeleteDialogOpen,
      graphPresetChangeWorkspaceDialogOpen,
      photoDeleteDialogOpen,
      photoDetailDialogOpen,
      photoUploadDialogOpen,
      cropCreateDialogOpen,
      tableCellEditDialogOpen,
      errorDialogOpen,
      sidePanelOpen,

      isFetching,

      clearCardsState,
      setNavigationMenuState,
      isNavigationMenuOpened,
      isLocationSpecificUrl,
      isStatic,
      isNoScroll,
      clearCompanyStore,
    } = this.props;

    const { languageDialogOpen } = this.state;

    const noScroll = graphPresetDatesDialogOpen ||
      graphPresetParametersDialogOpen ||
      graphPresetCreateDialogOpen ||
      graphPresetUpdateDialogOpen ||
      graphPresetDeleteDialogOpen ||
      graphPresetChangeWorkspaceDialogOpen ||
      photoDeleteDialogOpen ||
      photoDetailDialogOpen ||
      photoUploadDialogOpen ||
      cropCreateDialogOpen ||
      tableCellEditDialogOpen ||
      sidePanelOpen ||
      isNoScroll;

    return (
      <div className={classnames(styles.layout__main, {
        [styles.no_scroll]: noScroll,
        [styles.noScrollOnlyForMobile]: isNavigationMenuOpened,
      }, 'layout__main_root')}
      >
        <Header
          locale={locale}
          setIntl={setIntl}
          userProfile={userProfile}
          locations={locations}
          location={location}
          organization={organization}
          getLocation={getLocation}
          clearCardsState={clearCardsState}
          setNavigationMenuState={setNavigationMenuState}
          isLocationSpecificUrl={isLocationSpecificUrl}
          clearCompanyStore={clearCompanyStore}
        />
        <div className={classnames(styles.layout__viewport, 'layout__viewport')}>
          {((!isFetching && organization) || isStatic) && children}
          {isFetching && !isStatic && <CircleLoader className={circleLoaderStyles.circleLoader} iconClassName={circleLoaderStyles.circleLoaderIcon} />}
        </div>
        <NotificationCenter />
        {languageDialogOpen && <LanguageDialog locale={locale} onLanguageDialogClose={this.handlerLanguageDialogClose} setIntl={setIntl} />}
        {errorDialogOpen && <ErrorDialog locale={locale} onDialogClose={this.handlerErrorDialogClose} setIntl={setIntl} content={errorDialogMessage} />}
      </div>
    );
  }
}
