import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Icon0 } from './assets/weather/day/0.svg';
import { ReactComponent as Icon1 } from './assets/weather/day/1.svg';
import { ReactComponent as Icon2 } from './assets/weather/day/2.svg';
import { ReactComponent as Icon3 } from './assets/weather/day/3.svg';
import { ReactComponent as Icon4 } from './assets/weather/day/4.svg';
import { ReactComponent as Icon5 } from './assets/weather/day/5.svg';
import { ReactComponent as Icon6 } from './assets/weather/day/6.svg';
import { ReactComponent as Icon7 } from './assets/weather/day/7.svg';
import { ReactComponent as Icon8 } from './assets/weather/day/8.svg';
import { ReactComponent as Icon9 } from './assets/weather/day/9.svg';
import { ReactComponent as Icon10 } from './assets/weather/day/10.svg';
import { ReactComponent as Icon11 } from './assets/weather/day/11.svg';
import { ReactComponent as Icon12 } from './assets/weather/day/12.svg';
import { ReactComponent as Icon13 } from './assets/weather/day/13.svg';
import { ReactComponent as Icon16 } from './assets/weather/day/16.svg';

const WeatherIcon = ({
  index,
  className
}) => {
  switch (index) {
    default:
    case 0: return <Icon0 className={className} />;
    case 1: return <Icon1 className={className} />;
    case 2: return <Icon2 className={className} />;
    case 3: return <Icon3 className={className} />;
    case 4: return <Icon4 className={className} />;
    case 5: return <Icon5 className={className} />;
    case 6: return <Icon6 className={className} />;
    case 7: return <Icon7 className={className} />;
    case 8: return <Icon8 className={className} />;
    case 9: return <Icon9 className={className} />;
    case 10: return <Icon10 className={className} />;
    case 11: return <Icon11 className={className} />;
    case 12: return <Icon12 className={className} />;
    case 13: return <Icon13 className={className} />;
    case 16: return <Icon16 className={className} />;
  }
};

WeatherIcon.propTypes = {
  index: PropTypes.number.isRequired,
  className: PropTypes.string
};

WeatherIcon.defaultProps = {
  className: null
};

export default WeatherIcon;
