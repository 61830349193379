import { connect } from 'react-redux';

import { getHarvestData, deleteHarvestData } from '../../../../../store/harvest/actions';
import { getCycleData } from '../../../../../store/newCrops/actions';

import HarvestDeleteDialog from '../components/HarvestDeleteDialog';

const mapDispatchToProps = {
  getHarvestData,
  deleteHarvestData,
  getCycleData,
};

const mapStateToProps = (state, props) => ({
  ...props,
});

export default connect(mapStateToProps, mapDispatchToProps)(HarvestDeleteDialog);
