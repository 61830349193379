import { handleActions } from 'redux-actions';

import {
  getCropsPerformanceStarted,
  getCropsPerformanceSucceeded,
  getCropsPerformanceFailed,
  getCropsPerformanceEnded,
  updateCropsPerformanceSorting,
} from './actions';

const initialState = {
  getCropsPerformanceStarted: false,
  getCropsPerformanceSucceeded: false,
  getCropsPerformanceFailed: false,
  getCropsPerformanceEnded: false,
  getCropsPerformanceTimestamp: null,
  getCropsPerformanceError: null,
  cropsPerformanceData: [],
  cropsPerformanceSorting: null,
};

export default handleActions({
  [getCropsPerformanceStarted](state) {
    return {
      ...state,
      getCropsPerformanceStarted: true,
      getCropsPerformanceSucceeded: false,
      getCropsPerformanceFailed: false,
      getCropsPerformanceEnded: false,
      getCropsPerformanceTimestamp: null,
      getCropsPerformanceError: null,
    };
  },
  [getCropsPerformanceSucceeded](state, action) {
    return {
      ...state,
      getCropsPerformanceSucceeded: true,
      getCropsPerformanceFailed: false,
      getCropsPerformanceTimestamp: action.payload.timestamp,

      cropsPerformanceData: action.payload.data,
      cropsPerformanceSorting: action.payload.sorting,
    };
  },
  [getCropsPerformanceFailed](state, action) {
    return {
      ...state,
      getCropsPerformanceFailed: true,
      getCropsPerformanceSucceeded: false,
      getCropsPerformanceError: action.payload.error,
      getCropsPerformanceTimestamp: action.payload.timestamp,
    };
  },
  [getCropsPerformanceEnded](state) {
    return {
      ...state,
      getCropsPerformanceStarted: false,
      getCropsPerformanceEnded: true,
    };
  },
  [updateCropsPerformanceSorting](state, action) {
    return { ...state, cropsPerformanceSorting: action.payload, };
  },

}, initialState);
