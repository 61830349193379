export default function compareGraphMetrics(checker) {
  return (item) => {
    if (checker) {
      return item && checker.attributes.location.id === item.attributes.location.id &&
        (item.attributes.compartment ? !!checker.attributes.compartment && checker.attributes.compartment.id === item.attributes.compartment.id : !checker.attributes.compartment) &&
        (item.attributes.subNode ? !!checker.attributes.subNode && checker.attributes.subNode.id === item.attributes.subNode.id : !checker.attributes.subNode) &&
        checker.id === item.id;
    }
    return false;
  };
}
