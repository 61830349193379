import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  uniqueId,
  groupBy,
  find,
} from 'lodash';

import { showIntercom } from 'helpers/intercomHelpers';
import { getNameByLocal } from 'helpers/getNameByLocal';
import useMountEffect from 'hooks/useMountEffect';

import UiSearchInput from 'ui/UiSearchInput';

import PageViewTracker from 'components/PageViewTracker';
import Typography from 'components/Typography';
import BigButton from 'components/BigButton';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import DefaultPlate from 'components/DefaultPlate';
import ListWithSwitch from 'components/ListWithSwitch';
import PlusIcon from 'components/Icons/Plus';

import styles from './SettingsIncidents.module.css';

const normalizeIncidentsList = (intl, organizationSlug, incidentTypeCategories, incidentTypes) => {
  if (incidentTypes.length === 0 || !incidentTypeCategories.length === 0) {
    return [];
  }

  const { locale } = intl;

  const groupedIncidentTypes = groupBy(incidentTypes, 'category');

  const incidentsList = Object.keys(groupedIncidentTypes).map((key) => {
    const currentCategory = find(incidentTypeCategories, { id: Number(key) });
    const groupName = getNameByLocal(currentCategory, locale);
    const list = groupedIncidentTypes[key].map(listItem => ({
      id: listItem.id,
      text: getNameByLocal(listItem, locale),
      href: `/${organizationSlug}/settings/incidents/${listItem.id}`,
      enabled: listItem.enabled,
      withIcon: listItem?.alertRules?.length > 0,
      alertRules: listItem?.alertRules,
    }));

    return {
      groupName,
      list,
    };
  });

  return incidentsList;
};

const deepFlattenCollection = list => list.reduce((acc, listItem) => {
  if (listItem.list) {
    return [...acc, ...deepFlattenCollection(listItem.list)];
  }

  return [...list];
}, []);

const getSearchOptions = (dataArray) => {
  const flattenDataArray = deepFlattenCollection(dataArray);

  return flattenDataArray.map(item => ({
    id: item.id,
    name: item.text,
  }));
};

const renderIncidents = (formatMessage, handlerUpdateIncident, incidentsList) => incidentsList.map(incident => (
  <div key={`metric-${uniqueId()}`}>
    <Typography variant='h3' className={styles.listHeader}>
      {incident?.groupName}
    </Typography>
    <ListWithSwitch
      list={incident?.list}
      className={styles.incidentsList}
      enabledTooltipText={formatMessage({ id: 'incidentsSettings.tapToDisable' })}
      disabledTooltipText={formatMessage({ id: 'incidentsSettings.tapToEnable' })}
      onUpdateSwitch={handlerUpdateIncident}
    />
  </div>
));

const SettingsIncidents = ({
  intl,
  intl: { formatMessage },
  organizationSlug,
  isSettingsFetching,
  incidentTypes,
  incidentTypeCategories,
  requestIncidentTypeCategories,
  requestIncidentTypes,
  requestUpdateIncidentState,
  defaultOpen,
  incidentsAccess,
  trackPageViewSettings,
}) => {
  const history = useHistory();

  const handlerPageView = useCallback(() => {
    trackPageViewSettings({ settingName: 'Incidents', defaultOpen });
  }, [trackPageViewSettings, defaultOpen]);

  useMountEffect(() => {
    if (!incidentsAccess) {
      history.push(`/${organizationSlug}/settings/profile`);
    }

    requestIncidentTypeCategories();
    requestIncidentTypes();
  });

  const incidentsList = normalizeIncidentsList(intl, organizationSlug, incidentTypeCategories, incidentTypes);

  const [filterOptionId, setFilterOptionId] = useState(null);

  const handlerClickToSearchOption = useCallback((optionId) => {
    setFilterOptionId(optionId);
  }, [setFilterOptionId]);

  const handlerClearSearch = useCallback(() => {
    setFilterOptionId(null);
  }, [setFilterOptionId]);

  const filteredIncidentsList = useMemo(() => {
    if (!filterOptionId) {
      return incidentsList;
    }

    const filteredLists = incidentsList.map(groupItem => ({
      ...groupItem,
      list: groupItem.list.filter(listItem => listItem.id === filterOptionId),
    }));
    const filteredGroups = filteredLists.filter(listItem => listItem?.list?.length > 0);

    return filteredGroups;
  }, [incidentsList, filterOptionId]);

  const searchOptionsList = getSearchOptions(incidentsList);

  const handlerUpdateIncident = useCallback(({ id, enabled, actionAfterFail }) => {
    requestUpdateIncidentState({
      incidentId: id,
      enabled,
      actionAfterFail,
    });
  }, [requestUpdateIncidentState]);

  return (
    <div className={styles.settingsIncidents}>
      <PageViewTracker onMount={handlerPageView} />

      {isSettingsFetching && <DefaultCircleLoader />}

      <Typography variant='h2' className={styles.header}>
        {formatMessage({ id: 'header.links.incidents' })}
      </Typography>
      <Typography variant='subtitle3' className={styles.subtitle}>
        {formatMessage({ id: 'incidentsSettings.subTitle' })}
      </Typography>
      <DefaultPlate className={styles.infoPlate}>
        <div>
          {formatMessage({ id: 'incidentsSettings.toChangeIncidents' })}
          &nbsp;
          <BigButton
            className={styles.supportButton}
            onClick={showIntercom}
            title={formatMessage({ id: 'settings.contactSupport' })}
            theme='transparent'
          />
        </div>
      </DefaultPlate>
      <div className={styles.controlsWrapper}>
        <div className={styles.searchWrapper}>
          <UiSearchInput
            placeholder={formatMessage({ id: 'incidentsSettings.searchIncident' })}
            optionsList={searchOptionsList}
            onOptionClick={handlerClickToSearchOption}
            onEmpty={handlerClearSearch}
            optionsClassName={styles.searchOptions}
          />
        </div>

        <BigButton
          className={styles.titleButton}
          icon={<PlusIcon />}
          title={formatMessage({ id: 'incidentsSettings.addIncidents' })}
          onClick={showIntercom}
          size='large'
        />
      </div>
      <div className={styles.incidentsListContainer}>
        {renderIncidents(formatMessage, handlerUpdateIncident, filteredIncidentsList)}
      </div>
    </div>
  );
};

SettingsIncidents.propTypes = {
  intl: intlShape.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  isSettingsFetching: PropTypes.bool,
  incidentTypeCategories: PropTypes.array,
  incidentTypes: PropTypes.array,
  requestIncidentTypeCategories: PropTypes.func.isRequired,
  requestIncidentTypes: PropTypes.func.isRequired,
  requestUpdateIncidentState: PropTypes.func.isRequired,
  defaultOpen: PropTypes.bool,
  incidentsAccess: PropTypes.bool,

  trackPageViewSettings: PropTypes.func.isRequired,
};

SettingsIncidents.defaultProps = {
  isSettingsFetching: false,
  incidentTypeCategories: [],
  incidentTypes: [],
  defaultOpen: false,
  incidentsAccess: false,
};

export default SettingsIncidents;
