import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import classnames from 'classnames';
import moment from 'moment-timezone';

import DatePicker from 'react-datepicker/dist/react-datepicker.min';

import MobileMenu from '../../../MobileMenu';
import BigSelectInput from '../../../BigSelectInput';
import BigButton from '../../../BigButton';

import DateButton from '../DateButton';

import CalendarIcon from '../../../Icons/CalendarIcon';

import styles from './PeriodPickerMobile.module.css';

class PeriodPicker extends Component {
  static propTypes = {
    intl: intlShape.isRequired,

    organization: PropTypes.object.isRequired,
    currentPeriodType: PropTypes.string.isRequired,
    ranges: PropTypes.array.isRequired,
    currentDate: PropTypes.object.isRequired,

    onMobileFiltersChange: PropTypes.func.isRequired,
  };

  state = {
    mobileMenuOpened: false,
    periodType: this.props.currentPeriodType,
    date: this.props.currentDate,
    isDatePickerOpened: false,
  };

  handlerCloseMenu = () => this.setState({
    mobileMenuOpened: false,
    periodType: this.props.currentPeriodType,
    date: this.props.currentDate,
  });

  handlerOpenMenu = () => this.setState({ mobileMenuOpened: true });

  handlerClickDatePicker = () => this.setState({ isDatePickerOpened: true });

  handlerClickOutside = () => this.setState({ isDatePickerOpened: false });

  handlerSelectPeriod = options => this.setState({ periodType: options.value });

  handlerSelectDate = date => this.setState({
    date,
    isDatePickerOpened: false,
  });

  handlerShowResults = () => {
    const { onMobileFiltersChange } = this.props;
    const { date, periodType } = this.state;

    const anyDateOfPeriod = date.format('YYYY-MM-DD');

    onMobileFiltersChange({ periodType, anyDateOfPeriod });

    this.setState({ mobileMenuOpened: false });
  };

  render() {
    const {
      intl,
      organization,
      ranges,
    } = this.props;
    const { formatMessage } = intl;
    const { attributes: { timezone } } = organization;

    const {
      mobileMenuOpened,
      periodType,
      date,
      isDatePickerOpened,
    } = this.state;

    return (
      <div className={styles.mobileRangePickerContainer}>
        <MobileMenu
          className={styles.mobileMenu}
          icon={<CalendarIcon />}
          headerText={formatMessage({ id: 'harvest.selectPeriod' })}
          onClose={this.handlerCloseMenu}
          onOpen={this.handlerOpenMenu}
          menuOpened={mobileMenuOpened}
        >
          <BigSelectInput
            className={styles.mobilePeriodSelect}
            placeholder={formatMessage({ id: 'harvest.period' })}
            options={ranges}
            value={periodType}
            onChange={this.handlerSelectPeriod}
            labelPath='label'
            valuePath='value'
            closeOnChange
          />
          <div className={classnames(styles.mobilePeriodSelect, styles.customDatePickerWrapper)}>
            <DatePicker
              maxDate={moment(new Date()).tz(timezone)}
              customInput={( // Обёртка из div нужна, т.к. react-datepicker прокидывает свой onClick, который перезапишет наш обработчик
                <div>
                  <DateButton
                    className={classnames({ [styles.dateButtonFocused]: isDatePickerOpened })}
                    anyDateOfPeriod={date}
                    periodType={periodType}
                    onClick={this.handlerClickDatePicker}
                  />
                </div>
              )}
              selected={date}
              onSelect={this.handlerSelectDate}
              onClickOutside={this.handlerClickOutside}
              popperPlacement='bottom-start'
            />
          </div>
          <div className={styles.mobileControls}>
            <BigButton
              className={styles.cancelButton}
              title={formatMessage({ id: 'button.cancel' })}
              type='button'
              onClick={this.handlerCloseMenu}
              theme='light'
            />
            <BigButton
              title={formatMessage({ id: 'cropsPerformance.showResults' })}
              type='button'
              onClick={this.handlerShowResults}
              theme='dark'
            />
          </div>
        </MobileMenu>
      </div>
    );
  }
}

export default injectIntl(PeriodPicker);
