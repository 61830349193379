import ButtonStyle from 'components/CanvasTimeline/components/ButtonStyle';
import { textEllipsis } from '../helpers/TextUtils';
import { addPoints } from '../helpers/GeometryUtils';
import { roundRect } from '../helpers/DrawUtils';

import Button from './Button';
import CanvasComponent from './CanvasComponent';

import { ReactComponent as CrossIcon } from '../assets/cross.svg';
import { ReactComponent as LinkIcon } from '../assets/link.svg';

const buttonStyles = {
  style: new ButtonStyle({
    stroke: '#FFF',
  }),
  hoverStyle: new ButtonStyle({
    stroke: '#FFF',
  }),
};

class DataLineTooltip extends CanvasComponent {
  lines = []

  constructor({
    headerText,
    headerLinkUrl,
    subTitleText,
    lines,
    position = { x: 0, y: 0 },
  }) {
    super({
      position
    });
    const tooltipWidth = 336;
    this.headerText = headerText;
    this.headerTextEllipsis = null;
    this.headerLinkUrl = headerLinkUrl;
    this.subTitleText = subTitleText;
    this.lines = lines || [];
    this.bodyColor = '#FFF';
    this.bodyBorderColor = '#e1e8f0';
    this.tooltipWidth = tooltipWidth;
    this.linesTopPadding = 90;
    this.linesMargin = 32;
    this.headerTopPadding = 28;
    this.bodyLeftPadding = 15;
    this.bodyRightPadding = 15;
    this.headerSubtitleTopPadding = 54;
    this.maxHeight = 174;
    this.crossIcon = new Button({
      ...buttonStyles,
      position: {
        x: tooltipWidth - 35,
        y: 20
      },
      iconComponent: CrossIcon,
      iconSide: Button.ICON_MIDDLE,
      iconPadding: 4,
      iconColor: '#777776',
    });
    this.linkIcon = new Button({
      ...buttonStyles,
      iconComponent: LinkIcon,
      iconSide: Button.ICON_MIDDLE,
      iconPadding: 4,
      iconScale: 0.85,
      iconColor: '#777776',
    });
    this.headerTextWidth = null;

    this.addChild(this.crossIcon);
    this.addChild(this.linkIcon);

    this.crossIcon.setZIndex(100);
    this.linkIcon.setZIndex(100);

    this.setPosition(position);

    this.setDisplay(false);

    this.crossIcon.on('click', () => {
      this.handleClickClose();
      this.emit('closed');
    });

    this.linkIcon.on('click', ({ shiftKey, ctrlKey }) => {
      this.handleClickLink(shiftKey, ctrlKey);
    });
  }

  handleClickLink(shiftKey, ctrlKey) {
    if (ctrlKey) {
      window.open(this.headerLinkUrl, '_blank').focus();
    } else {
      window.location.href = this.headerLinkUrl;
    }
  }

  handleClickClose() {
    this.setDisplay(false);
    this.setZIndex(0);
  }

  getTooltipHeight() {
    return Math.min(
      this.linesTopPadding + this.lines.length * this.linesMargin,
      this.maxHeight
    );
  }

  getTooltipSize() {
    return this.getSize();
  }

  getSize() {
    return {
      width: this.tooltipWidth,
      height: this.getTooltipHeight(),
    };
  }

  drawBody(ctx, {
    position
  }) {
    const radius = 4;
    const buttonRadius = {
      tl: radius, bl: radius, tr: radius, br: radius
    };
    const size = this.getTooltipSize();
    roundRect(ctx, {
      ...position,
      ...size,
      fill: true,
      radius: buttonRadius,
      color: this.bodyColor
    });
    roundRect(ctx, {
      ...position,
      ...size,
      stroke: true,
      radius: buttonRadius,
      color: this.bodyBorderColor,
    });
  }

  drawHeader(ctx, {
    position
  }) {
    this.setContextFont(ctx, 'typeTitle');

    ctx.fillText(
      this.headerTextEllipsis,
      position.x + this.bodyLeftPadding,
      position.y + this.headerTopPadding
    );

    this.setContextFont(ctx, 'typeSubTitle');

    ctx.fillText(
      this.subTitleText,
      position.x + this.bodyLeftPadding,
      position.y + this.headerSubtitleTopPadding
    );
  }

  drawLines(ctx, {
    position
  }) {
    for (let lineIndex = 0; lineIndex < this.lines.length; lineIndex += 1) {
      this.drawLine(ctx, {
        position: {
          ...position,
          y: position.y + this.linesTopPadding + (lineIndex * this.linesMargin)
        },
        line: this.lines[lineIndex]
      });
    }
  }

  drawLine(ctx, {
    position,
    line
  }) {
    const {
      titleText,
      valueText
    } = line;

    const size = this.getTooltipSize();

    this.setContextFont(ctx, 'typeLineTitle');

    ctx.fillText(
      titleText,
      position.x + this.bodyLeftPadding,
      position.y,
    );

    this.setContextFont(ctx, 'typeLineValue');
    ctx.fillText(
      valueText,
      position.x + size.width - this.bodyRightPadding,
      position.y,
    );
  }

  draw(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    super.draw(ctx, {
      translateAdd
    });
    const position = this.getPositionAbs();

    const screenPosition = addPoints(position, translateAdd);

    if (!this.headerTextEllipsis) {
      this.setContextFont(ctx, 'typeTitle');
      this.headerTextEllipsis = textEllipsis(ctx, this.headerText, 169);
    }

    if (!this.headerTextWidth) {
      this.setContextFont(ctx, 'typeTitle');
      this.headerTextWidth = ctx.measureText(this.headerTextEllipsis).width;

      this.linkIcon.setPosition({
        y: 16,
        x: this.headerTextWidth + 18
      });
    }

    this.drawBody(ctx, {
      position: screenPosition
    });

    this.drawHeader(ctx, {
      position: screenPosition
    });

    this.drawLines(ctx, {
      position: screenPosition
    });
  }

  setContextFont(ctx, fontType) {
    switch (fontType) {
      case 'typeTitle': {
        ctx.fillStyle = '#4a4a49';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.font = 'normal normal 500 16px Roboto';
        break;
      }
      case 'typeSubTitle': {
        ctx.fillStyle = '#777776';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.font = 'normal normal 400 13px Roboto';
        break;
      }
      case 'typeLineTitle': {
        ctx.fillStyle = '#777776';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.font = 'normal normal 400 14px Roboto';
        break;
      }
      case 'typeLineValue': {
        ctx.font = 'normal normal 400 14px Roboto';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#4a4a49';
        break;
      }
      default: {
        ctx.font = 'normal normal 400 12px Roboto';
        ctx.fillStyle = '#777776';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.textBaseline = 'middle';
        break;
      }
    }
  }
}

export default DataLineTooltip;
