import React, {
 useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { isFinite } from 'lodash';
import { injectIntl } from 'react-intl';

import styles from './index.module.css';

const INPUT_MODE_AUTO = 'auto';
const INPUT_MODE_FIXED = 'fixed';
export const DELIMITER = '–';
const MINUS_CHAR = '-';
const DOT_CHAR = '.';
const COMMA_CHAR = ',';

const setInputCursor = (inputEl, pos) => {
  inputEl.focus();
  setTimeout(() => inputEl.setSelectionRange(pos, pos), 1);
};

const availableChars = '0123456789,.';

const isDelimiterChar = key => key === DOT_CHAR || key === COMMA_CHAR;

const isSpecialChar = key => key === MINUS_CHAR || isDelimiterChar(key);

// const isStringContainsDelimiterChars = str => str.indexOf(DOT_CHAR) !== -1 || str.indexOf(COMMA_CHAR) !== -1;

const isStringContainsSpecialChars = (str, exclude) => {
  let testChars = [MINUS_CHAR, DOT_CHAR, COMMA_CHAR];
  if (exclude) {
    testChars = testChars.filter(ch => ch !== exclude);
  }

  for (let i = 0; i < testChars.length; i += 1) {
    if (str.indexOf([testChars[i]]) !== -1) {
      return true;
    }
  }
  return false;
  // return str.indexOf(MINUS_CHAR) !== -1 || isStringContainsDelimiterChars(str);
};

const getInputSide = (value, selectionStart, selectionEnd) => {
  const delimiterPos = value.indexOf(DELIMITER);
  if (selectionStart > delimiterPos) {
    return 'right';
  }
  if (selectionEnd <= delimiterPos) {
    return 'left';
  }
  return 'both';
};

const splitValues = (value) => {
  const delimiterPos = value.indexOf(DELIMITER);
  return {
    leftValue: value.substring(0, delimiterPos),
    rightValue: value.substring(delimiterPos + 1, value.length)
  };
};

const isInputAvailable = (inputEvent) => {
  const {
    key,
    target: { selectionStart, selectionEnd, value }
  } = inputEvent;

  const selectedString = value.substring(selectionStart, selectionEnd);

  if (selectedString.indexOf(DELIMITER) !== -1) {
    return false;
  }

  if (
    availableChars.indexOf(key) === -1 &&
    key !== MINUS_CHAR &&
    key !== DOT_CHAR &&
    key !== COMMA_CHAR
  ) {
    return false;
  }

  const { leftValue, rightValue } = splitValues(value);
  const inputSide = getInputSide(value, selectionStart, selectionEnd);

  if (selectionStart === selectionEnd) {
    if (isSpecialChar(key)) {
      if (inputSide === 'left' && leftValue.indexOf(key) !== -1) {
        return false;
      }
      if (inputSide === 'right' && rightValue.indexOf(key) !== -1) {
        return false;
      }
    }
  } else if (isSpecialChar(key)) {
      if (
        inputSide === 'left' &&
        !isStringContainsSpecialChars(selectedString)
      ) {
        return false;
      }
      if (
        inputSide === 'right' &&
        !isStringContainsSpecialChars(selectedString)
      ) {
        return false;
      }
    }

  return true;
};

const replaceAt = (str, selectionStart, selectionEnd, replacement) => {
  const beginString = str.substring(0, selectionStart);
  const endString = str.substring(selectionEnd, str.length);
  return `${beginString}${replacement}${endString}`;
};

const envelopeDefaultValue = str => (str.length === 0 ? DELIMITER : str);

const inputValue = (prevValue, inputEvent) => {
  const {
    key,
    target: { selectionStart, selectionEnd }
  } = inputEvent;

  if (key === ' ' && selectionStart !== selectionEnd) {
    return envelopeDefaultValue(
      replaceAt(prevValue, selectionStart, selectionEnd, '')
    );
  }

  if (key === 'Delete') {
    return envelopeDefaultValue(
      replaceAt(prevValue, selectionStart, selectionEnd + 1, '')
    );
  }

  if (key === 'Backspace' && selectionStart === selectionEnd) {
    return envelopeDefaultValue(
      replaceAt(prevValue, selectionStart - 1, selectionEnd, '')
    );
  }

  if (key !== ' ' && key !== 'Backspace' && key !== 'Delete' && key !== 'Space') {
    return replaceAt(prevValue, selectionStart, selectionEnd, key);
  }

  return prevValue;
};

const MaskedInput = ({
 intl, onChange, defaultValue, defaultMode
}) => {
  const { formatMessage } = intl;

  const inputRef = useRef();
  const [value, setValue] = useState(defaultValue);
  const [mode, setMode] = useState(defaultMode);

  const handleKeyPress = useCallback(
    (inputEvent) => {
      const {
        target: { selectionStart }
      } = inputEvent;
      if (isInputAvailable(inputEvent)) {
        setValue(inputValue(value, inputEvent));
        setInputCursor(inputRef.current, selectionStart + 1);
      } else {
        setInputCursor(inputRef.current, selectionStart);
      }
    },
    [inputRef, setValue, value]
  );

  const handleChangeMode = useCallback(
    (inputEvent) => {
      setMode(inputEvent.target.value);
      if (inputEvent.target.value === INPUT_MODE_AUTO) {
        setValue(DELIMITER);
        onChange({
          range: null,
          mode
        });
      }
    },
    [setMode, setValue, onChange, mode]
  );

  useEffect(() => {
    const { leftValue, rightValue } = splitValues(value);
    const start = parseFloat(leftValue);
    const end = parseFloat(rightValue);
    if ((isFinite(start)) || (isFinite(end))) {
      setMode(INPUT_MODE_FIXED);
    } else {
      setMode(INPUT_MODE_AUTO);
    }
  }, [setMode, value]);

  const handleKeyDown = useCallback(
    (inputEvent) => {
      const {
        key,
        target: { selectionStart }
      } = inputEvent;
      if (key === 'Backspace' || key === 'Delete' || key === ' ') {
        setValue(inputValue(value, inputEvent));
        if (key === 'Backspace') {
          setInputCursor(inputRef.current, selectionStart - 1);
        } else {
          setInputCursor(inputRef.current, selectionStart);
        }
      }
    },
    [setValue, inputRef, value]
  );

  const handleChange = useCallback(() => {
    const { leftValue, rightValue } = splitValues(value);
    const start = parseFloat(leftValue);
    const end = parseFloat(rightValue);
    if (isFinite(start) && isFinite(end)) {
      onChange({
        range: {
          start,
          end
        },
        mode: 'INPUT_MODE_FIXED'
      });
    }
  }, [value, onChange]);

  const handlePaste = useCallback((e) => {
    e.preventDefault();
    const pastedValue = e?.clipboardData?.getData('Text') || null;
    const { leftValue, rightValue } = splitValues(pastedValue);
    const start = parseFloat(leftValue);
    const end = parseFloat(rightValue);
    if (isFinite(start) && isFinite(end)) {
      setValue(pastedValue);
      onChange({
        range: {
          start,
          end
        },
        mode: 'INPUT_MODE_FIXED'
      });
    }
  }, [onChange, setValue]);

  return (
    <div className={styles.scaleSettingsInputWrapper}>
      <input
        type='text'
        ref={inputRef}
        value={value}
        defaultValue={defaultValue}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        className={styles.scaleSettingsRangeInput}
        placeholder={`${formatMessage({ id: 'graphs.rangeMin' })}${DELIMITER}${formatMessage({ id: 'graphs.rangeMax' })}`}
        onChange={handleChange}
        onPaste={handlePaste}
      />
      <select
        onChange={handleChangeMode}
        value={mode}
        className={styles.scaleSettingsInputMode}
      >
        <option value={INPUT_MODE_AUTO} selected={mode === INPUT_MODE_AUTO}>
          {formatMessage({ id: 'graphs.rangeAuto' })}
        </option>
        <option value={INPUT_MODE_FIXED} selected={mode === INPUT_MODE_FIXED}>
          {formatMessage({ id: 'graphs.rangeFixed' })}
        </option>
      </select>
    </div>
  );
};

MaskedInput.propTypes = {
  intl: PropTypes.object,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  defaultMode: PropTypes.string
};

MaskedInput.defaultProps = {
  intl: null,
  onChange: null,
  defaultValue: DELIMITER,
  defaultMode: INPUT_MODE_AUTO
};

export default injectIntl(MaskedInput);
