import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';


import { getOrganization } from '../../../store/company/selectors';

import PeriodPicker from '../components/PeriodPicker';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,

  organization: getOrganization(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(PeriodPicker)));
