import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as d3 from 'd3';
import moment from 'moment-timezone';
import { get } from 'lodash';

import DefaultTimeline from '../../../DefaultTimeline';

import styles from './BenchmarkTimeline.module.css';

export default class BenchmarkTimeline extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    comparison: PropTypes.object,
    colors: PropTypes.array,
    forceColor: PropTypes.bool,
    alignedBy: PropTypes.oneOf(['years', 'calendar', 'cycleWeeks']),
    withCompareButton: PropTypes.bool,
    isShowLocationName: PropTypes.bool,
    toCompareItems: PropTypes.array,
    hoveredItem: PropTypes.number,

    onMouseOverCallback: PropTypes.func,
    onMouseOutCallback: PropTypes.func,
    onClickCallback: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    comparison: null,
    colors: null,
    alignedBy: 'calendar',
    withCompareButton: false,
    isShowLocationName: true,
    forceColor: false,
    toCompareItems: null,
    hoveredItem: null,

    onMouseOverCallback: () => {},
    onMouseOutCallback: () => {},
    onClickCallback: () => {},
  };

  getPreparedItems = (alignedBy, items) => {
    const minYear = moment(d3.min(items.map(item => moment(item.startDate).valueOf()))).format('YYYY');

    return items.map((item, i) => {
      const { colors } = this.props;

      let limitedStartTime = item.startDate;
      let limitedEndTime = item.endDate;

      if (alignedBy === 'years') {
        // const currentYear = moment().format('YYYY');
        const yearsDiff = moment(limitedEndTime).diff(moment(limitedStartTime), 'years') + 1;
        const finalYearsDiff = moment(limitedStartTime).format('YYYY') === moment(limitedEndTime).format('YYYY') ? 0 : yearsDiff;
        // Если идёт выравнивание всех оборотов по одному году, то берём минимальный, который есть
        limitedStartTime = moment(limitedStartTime).year(minYear).valueOf();
        limitedEndTime = moment(limitedEndTime).year(Number(minYear) + Number(finalYearsDiff)).valueOf();
      }

      return {
        id: item.id,
        label: item.graphLabel || get(item, 'location.name'),
        color: colors ? get(colors, i, '#8C46C3') : '#8C46C3',
        startTime: moment(limitedStartTime).valueOf(),
        endTime: moment(limitedEndTime).valueOf(),
        // Служебная информация для тултипа
        product: item,
        descriptor: item.descriptor
      };
    });
  };

  getXTickFormat = (alignedBy) => {
    if (alignedBy === 'cycleWeeks') {
      return null;
    }

    if (alignedBy === 'years') {
      return 'MMM';
    }

    return 'MMM YYYY';
  }

  render() {
    const {
      data,
      alignedBy,
      withCompareButton,
      isShowLocationName,
      toCompareItems,
      hoveredItem,
      forceColor,
      onMouseOutCallback,
      onMouseOverCallback,
      onClickCallback,
      comparison
    } = this.props;

    const tickDateFormat = this.getXTickFormat(alignedBy);
    const preparedItems = this.getPreparedItems(alignedBy, data);
    const timesArray = preparedItems.reduce((acc, item) => ([...acc, item.startTime, item.endTime || moment().endOf('day').valueOf()]), []);
    // Добавляем по месяцу с концов, чтобы было видно что таймлайн уходит в другой год
    const minDateLimit = moment(d3.min(timesArray)).subtract(1, 'month').valueOf();
    const maxDateLimit = moment(d3.max(timesArray)).add(1, 'month').valueOf();
    return (
      <div className={styles.timeline}>
        <DefaultTimeline
          forceColor={forceColor}
          descriptor={comparison?.descriptor}
          items={preparedItems}
          minDateLimit={minDateLimit}
          maxDateLimit={maxDateLimit}
          tickDateFormat={tickDateFormat}
          withCompareButton={withCompareButton}
          isShowLocationName={isShowLocationName}
          toCompareItems={toCompareItems}
          hoveredItem={hoveredItem}
          onMouseOutCallback={onMouseOutCallback}
          onMouseOverCallback={onMouseOverCallback}
          onClickCallback={onClickCallback}
        />
      </div>
    );
  }
}
