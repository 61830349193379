import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { useHistory } from 'react-router-dom';

import updateLocationSearch from 'helpers/updateLocationSearch';
import useMountEffect from 'hooks/useMountEffect';
import useSearchParams from 'hooks/useSearchParams';

import PageViewTracker from 'components/PageViewTracker';
import Typography from 'components/Typography';
import BigButton from 'components/BigButton';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import ListWithSwitch from 'components/ListWithSwitch';
import AdditionalInfo from 'components/AdditionalInfo';

import AlertPanel from '../AlertPanel';
import SettingsAlertingEmpty from '../SettingsAlertingEmpty';

import styles from './SettingsAlerting.module.css';

const renderAdditionalInfo = (intl, alertItem) => {
  const { formatMessage, formatPlural } = intl;

  const incidentsText = formatMessage({
    id: `plural.incident.${formatPlural(alertItem.incidents.length)}`
  });

  const recipientsText = formatMessage({
    id: `alerting.recipientsPlural.${formatPlural(alertItem.recipients.length)}`
  });

  const additionalInfoItems = [`${alertItem.incidents.length} ${incidentsText}`, `${alertItem.recipients.length} ${recipientsText}`];

  return <AdditionalInfo items={additionalInfoItems} />;
};

const SettingsAlerting = ({
  intl,
  intl: { formatMessage },
  organizationSlug,
  alertRules,
  isSettingsFetching,
  incidentsAccess,
  defaultOpen,

  requestAlertRules,
  requestIncidentTypes,
  requestIncidentTypeCategories,
  requestUserPrivileges,
  requestUpdateAlertState,
  trackPageViewSettings,
}) => {
  const history = useHistory();

  useMountEffect(() => {
    if (!incidentsAccess) {
      history.push(`/${organizationSlug}/settings/profile`);
    }

    requestAlertRules();
    requestIncidentTypes();
    requestIncidentTypeCategories();
    requestUserPrivileges();
  });

  const activeAlertId = useSearchParams('activeAlertId', undefined);

  const handlerPageView = useCallback(() => {
    trackPageViewSettings({ settingName: 'Alerting', defaultOpen });
  }, [trackPageViewSettings, defaultOpen]);

  const [activeAlertCollapsed, setActivAlertCollapsed] = useState(!activeAlertId);

  const alertsList = alertRules.map(alertItem => ({
    id: alertItem.id,
    enabled: !alertItem.disabled,
    text: alertItem.name,
    additionalInfo: renderAdditionalInfo(intl, alertItem),
  }));

  const handlerUpdateAlert = useCallback(({ id, enabled, actionAfterFail }) => {
    requestUpdateAlertState({
      alertId: id,
      enabled,
      actionAfterFail,
    });
  }, [requestUpdateAlertState]);

  const handleIncidentCollapsed = useCallback(() => {
    setActivAlertCollapsed(true);
    updateLocationSearch({
      activeAlertId: undefined
    });
  }, [setActivAlertCollapsed]);

  const handlerOnAlertClick = useCallback(({ id }) => {
    updateLocationSearch({
      activeAlertId: id
    });

    setActivAlertCollapsed(false);
  }, [setActivAlertCollapsed]);

  const handlerOnAddRuleClick = useCallback(() => {
    updateLocationSearch({
      activeAlertId: 0,
    });

    setActivAlertCollapsed(false);
  }, [setActivAlertCollapsed]);


  return (
    <div className={styles.settingsAlerting}>
      <PageViewTracker onMount={handlerPageView} />

      {isSettingsFetching && <DefaultCircleLoader />}

      <Typography variant='h2' className={styles.header}>
        {formatMessage({ id: 'settings.alerting' })}
      </Typography>
      <Typography variant='subtitle3' className={styles.subtitle}>
        {formatMessage({ id: 'alerting.settingsSubtitle' })}
      </Typography>

      <div className={styles.controlsWrapper}>
        <Typography variant='h3' className={styles.rulesHeader}>
          {formatMessage({ id: 'alerting.settingsListSubtitle' })}
        </Typography>

        <BigButton
          className={styles.titleButton}
          title={formatMessage({ id: 'alerting.addRule' })}
          onClick={handlerOnAddRuleClick}
          size='large'
        />
      </div>
      <div className={styles.alertsListContainer}>
        {alertsList.length === 0 ?
          <SettingsAlertingEmpty />
          : (
            <ListWithSwitch
              list={alertsList}
              className={styles.incidentsList}
              onClick={handlerOnAlertClick}
              onUpdateSwitch={handlerUpdateAlert}
              enabledTooltipText={formatMessage({ id: 'alerting.tapToDisable' })}
              disabledTooltipText={formatMessage({ id: 'alerting.tapToEnable' })}
              activeId={Number(activeAlertId)}
            />
          )}
      </div>
      {/* TODO: с activeAlertId и isSettingsFetching хак, чтобы ремаунтить компонент, по-хорошему
      надо это убрать и прописать внутири компонента апдейт стейта,
      чтобы работала анимация открытия панельки */}
      {(activeAlertId && !isSettingsFetching) && (
        <AlertPanel
          key={`active-alert-panel-${activeAlertId}`}
          panelCollapsed={activeAlertCollapsed}
          onCollapse={handleIncidentCollapsed}
          activeAlertId={Number(activeAlertId)}
          alertRules={alertRules}
          isFetching={isSettingsFetching}
        />
      )}
    </div>
  );
};

SettingsAlerting.propTypes = {
  intl: intlShape.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  alertRules: PropTypes.array,
  isSettingsFetching: PropTypes.bool,
  incidentsAccess: PropTypes.bool,
  defaultOpen: PropTypes.bool,

  trackPageViewSettings: PropTypes.func.isRequired,
  requestAlertRules: PropTypes.func.isRequired,
  requestIncidentTypes: PropTypes.func.isRequired,
  requestIncidentTypeCategories: PropTypes.func.isRequired,
  requestUserPrivileges: PropTypes.func.isRequired,
  requestUpdateAlertState: PropTypes.func.isRequired,
};

SettingsAlerting.defaultProps = {
  isSettingsFetching: false,
  alertRules: [],
  incidentsAccess: false,
  defaultOpen: false,
};

export default SettingsAlerting;
