import { connect } from 'react-redux';
import { getFormValues, getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

import { getIsPasswordUpdateFetching } from '../../../store/login/selectors';

import { userFormOnSubmit } from '../../../store/login/actions';

import UserPasswordForm from '../components/UserPasswordForm';

const mapDispatchToProps = {
  userFormOnSubmit,
};

const mapStateToProps = (state, props) => ({
  isPasswordUpdateFetching: getIsPasswordUpdateFetching(state),
  formValues: getFormValues('userPasswordForm')(state),
  formSyncErrors: getFormSyncErrors('userPasswordForm')(state),

  ...props,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserPasswordForm));
