import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import moment from 'moment-timezone';

import DataSheet from '../DataSheet';

/**
 * Враппер нужен как оптимизация, чтобы компонент не перерендеривался
 * из-за объекта роутера match. Временное решение, TODO: подумать что сделать с роутером и получением
 * параметра match.
 */
const DataSheetWrapper = ({
  intl,
  year,
  compartments,
  varieties,
  fruitClasses,
  plantingCycles,
  organization,

  harvestPlanEdited,
  isPlansEditorFetching,
  isVarietyRemoving,
  maxIdx,
  tableScroll,
  isLastVersion,
  isOperationalPlan,
  planType,
  yearType,

  setHarvestPlanGrid,
  setTableScrollPosition,
  isNotPublishedVersionPlate,

  planDescriptor,

  requestPlanForecast,
  showNotificationWithTimeout,
}) => (
  <DataSheet
    intl={intl}
    year={year}
    compartments={compartments}
    varieties={varieties}
    fruitClasses={fruitClasses}
    organization={organization}
    harvestPlanEdited={harvestPlanEdited}
    isPlansEditorFetching={isPlansEditorFetching}
    isVarietyRemoving={isVarietyRemoving}
    maxIdx={maxIdx}
    tableScroll={tableScroll}
    isLastVersion={isLastVersion}
    isOperationalPlan={isOperationalPlan}
    planType={planType}
    yearType={yearType}
    setHarvestPlanGrid={setHarvestPlanGrid}
    setTableScrollPosition={setTableScrollPosition}
    isNotPublishedVersionPlate={isNotPublishedVersionPlate}
    planDescriptor={planDescriptor}
    requestPlanForecast={requestPlanForecast}
    showNotificationWithTimeout={showNotificationWithTimeout}
    plantingCycles={plantingCycles}
  />
);

DataSheetWrapper.propTypes = {
  intl: intlShape.isRequired,

  compartments: PropTypes.array.isRequired,
  varieties: PropTypes.array.isRequired,
  fruitClasses: PropTypes.array.isRequired,
  plantingCycles: PropTypes.array.isRequired,
  organization: PropTypes.object,
  year: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  harvestPlanEdited: PropTypes.object,
  maxIdx: PropTypes.number,
  isPlansEditorFetching: PropTypes.bool,
  isVarietyRemoving: PropTypes.bool,
  tableScroll: PropTypes.object,
  isNotPublishedVersionPlate: PropTypes.bool,
  isLastVersion: PropTypes.bool,
  isOperationalPlan: PropTypes.bool,
  planType: PropTypes.string.isRequired,
  yearType: PropTypes.string.isRequired,

  setHarvestPlanGrid: PropTypes.func.isRequired,
  setTableScrollPosition: PropTypes.func.isRequired,
  requestPlanForecast: PropTypes.func.isRequired,
  showNotificationWithTimeout: PropTypes.func.isRequired,
  planDescriptor: PropTypes.object,
};

DataSheetWrapper.defaultProps = {
  year: moment().format('YYYY'),
  organization: null,
  harvestPlanEdited: null,
  isPlansEditorFetching: false,
  isVarietyRemoving: false,
  maxIdx: 0,
  tableScroll: null,
  isNotPublishedVersionPlate: true,
  isLastVersion: false,
  isOperationalPlan: false,
  planDescriptor: null,
};

export default DataSheetWrapper;
