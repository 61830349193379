import moment from 'moment';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { uniqueId, groupBy, capitalize } from 'lodash';

import numbersFormatting from 'helpers/numbersFormatting';
import { showIntercom } from 'helpers/intercomHelpers';

import PageViewTracker from 'components/PageViewTracker';
import Typography from 'components/Typography';
import BigButton from 'components/BigButton';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import DefaultPlate from 'components/DefaultPlate';
import CollapsibleCard from 'components/CollapsibleCard';
import PropertyView from 'components/PropertyView';

import styles from './SettingsEquipmentMeters.module.css';

export default class SettingsEquipmentMeters extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    meters: PropTypes.array,
    isMetersFetching: PropTypes.bool.isRequired,
    requestEnergyMeters: PropTypes.func.isRequired,
    trackPageViewSettings: PropTypes.func.isRequired,
    defaultOpen: PropTypes.bool,
  };

  static defaultProps = {
    meters: [],
    defaultOpen: false,
  };

  static availableTypes = ['electricityMeter', 'gasMeter', 'waterMeter'];

  static typeDict = {
    electricityMeter: 'electricity',
    gasMeter: 'gas',
    waterMeter: 'water',
  };

  componentDidMount() {
    const { requestEnergyMeters } = this.props;
    requestEnergyMeters();
  }

  handlerPageView = () => {
    const { trackPageViewSettings, defaultOpen } = this.props;

    trackPageViewSettings({ settingName: 'Meters', defaultOpen });
  };

  getMeterTypeLocalized = (type) => {
    const { intl: { formatMessage } } = this.props;
    return formatMessage({ id: `energy.${SettingsEquipmentMeters.typeDict[type]}` });
  }

  getMeterUnitLocalized = (unit) => {
    const { intl: { formatMessage } } = this.props;
    return formatMessage({ id: `cunits.mini.${unit}` });
  }

  getCardTitleLocalized = item =>
    `${item.name}, ${this.getMeterUnitLocalized(item.unit)}`;

  getActualGreenhouseMapping = (item) => {
    const sorted = item.compartmentMappingHistory.sort((a, b) => -a.effectiveEnd.diff(b.effectiveEnd));
    const now = moment.utc();
    const lastMapping = sorted[0];
    const isActual = lastMapping?.effectiveStart?.isBefore(now) && lastMapping?.effectiveEnd?.isSameOrAfter(now);
    return isActual ? sorted[0] : null;
  }

  getCardSubTitleLocalized = (item) => {
    const { intl: { formatMessage } } = this.props;
    const actualMapping = this.getActualGreenhouseMapping(item);
    const greenhousesLocalized = capitalize(formatMessage({ id: 'dataQuality.greenhouses.many' }));
    const greenhouseMappedCount = actualMapping?.compartmentIds?.length || 0;
    return actualMapping && greenhouseMappedCount > 0 ?
      `${greenhouseMappedCount} ${greenhousesLocalized}` : formatMessage({ id: 'energy.notMapped' });
  }

  getGreenhouseMappingLocalized = (item) => {
    const { intl: { formatMessage } } = this.props;
    const actualMapping = this.getActualGreenhouseMapping(item);
    const greenhouseMappedCount = actualMapping?.compartmentIds?.length || 0;
    if (greenhouseMappedCount === 0) {
      return formatMessage({ id: 'energy.notMapped' });
    }

    const greenhousesList = actualMapping.compartmentIds.map(compartment =>
      `${compartment?.attributes?.name} (${numbersFormatting(compartment?.attributes?.floorArea)} ${formatMessage({ id: 'cunits.mini.squareMeter' })})`)
      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
      .join(', ');
    return `${greenhousesList}`;
  }

  getAvailableMeters = () => {
    const { meters } = this.props;
    const now = moment.utc();
    return meters
      .filter(meter => SettingsEquipmentMeters.availableTypes.includes(meter.type))
      .filter(meter => meter?.effectiveStart?.isBefore(now) && meter?.effectiveEnd?.isSameOrAfter(now));
  }

  mapEquipmentItems = (meter) => {
    const { intl: { formatMessage } } = this.props;
    const items = [];

    if (meter.units) {
      items.push({
        name: formatMessage({ id: 'settings.units' }),
        value: this.getMeterUnitLocalized(meter.units),
      });
    }

    if (meter.registrationMode) {
      items.push({
        name: formatMessage({ id: 'energy.registrationMode' }),
        value: formatMessage({ id: `energy.registrationModes.${meter.registrationMode}` }),
      });
    }

    if (meter.electricityGenerationType) {
      items.push({
        name: formatMessage({ id: 'energy.electricityGenerationType' }),
        value: formatMessage({ id: `energy.electricityGenerationTypes.${meter.electricityGenerationType}` }),
      });
    }

    if (meter.compartmentMappingHistory) {
      items.push({
        name: formatMessage({ id: 'energy.compartmentMappingHistory' }),
        value: this.getGreenhouseMappingLocalized(meter),
      });
    }

    items.push({
      name: capitalize(formatMessage({ id: 'energy.reversible' })),
      value: formatMessage({ id: `energy.${meter.reverse ? 'yes' : 'no'}` }),
    });

    return items;
  }

  renderMetersType = ({ type, meters }) => (
    <div key={uniqueId('meters-unit-block-')} className={styles.metersBlock}>
      <Typography variant='h3' className={styles.header}>
        {this.getMeterTypeLocalized(type)}
      </Typography>
      <CollapsibleCard
        className={styles.metersWrapper}
        items={
          meters
            .map(meter => ({
              id: meter.id.toString(),
              title: this.getCardTitleLocalized(meter),
              subTitle: this.getCardSubTitleLocalized(meter),
              contentItem: <PropertyView items={this.mapEquipmentItems(meter)} />
            }))
        }
      />
    </div>
  );

  renderMeters = () => {
    const meters = this.getAvailableMeters();
    const grouped = groupBy(meters, 'type');
    return (
      <div>
        {!!grouped?.gasMeter?.length && this.renderMetersType({
          type: 'gasMeter',
          meters: grouped.gasMeter
        })}
        {!!grouped?.electricityMeter?.length && this.renderMetersType({
          type: 'electricityMeter',
          meters: grouped.electricityMeter
        })}
        {!!grouped?.waterMeter?.length && this.renderMetersType({
          type: 'waterMeter',
          meters: grouped.waterMeter
        })}
      </div>
    );
  };

  render() {
    const { intl: { formatMessage }, isMetersFetching } = this.props;
    return (
      <div className={styles.settingsMeters}>
        <PageViewTracker onMount={this.handlerPageView} />

        {isMetersFetching && <DefaultCircleLoader />}

        <Typography variant='h2' className={styles.header}>
          {formatMessage({ id: 'settings.meters' })}
        </Typography>

        <DefaultPlate className={styles.infoPlate}>
          <div>
            {formatMessage({ id: 'settings.toChangeMeters' })}
            &nbsp;
            <BigButton
              className={styles.supportButton}
              onClick={showIntercom}
              title={formatMessage({ id: 'settings.contactSupport' })}
              theme='transparent'
            />
          </div>
        </DefaultPlate>
        {this.renderMeters()}
      </div>
    );
  }
}
