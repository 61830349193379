import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { intlShape, injectIntl } from 'react-intl';

import Select from '../../../Select';
import DateButton from '../DateButton';
import BigButton from '../../../BigButton';
import BigSelectInput from '../../../BigSelectInput';

import { ReactComponent as LeftIcon } from './assets/left.svg';
import { ReactComponent as RightIcon } from './assets/right.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as ToggleIcon } from './assets/toggle.svg';

import styles from './YearPicker.module.css';


class YearPicker extends Component {
  static propTypes = {
    handlerAfterRangeSelect: PropTypes.func.isRequired,
    year: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    years: PropTypes.array,
    intl: intlShape.isRequired,
    withoutSelect: PropTypes.bool,
    labelClassName: PropTypes.string,
  };

  static defaultProps = {
    years: [],
    year: null,
    withoutSelect: false,
    labelClassName: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { year: newYear } = nextProps;
    const { selectedYear } = prevState;

    if (selectedYear !== newYear) {
      return {
        selectedYear: newYear,
      };
    }

    return null;
  }

  state = {
    isOpen: false,
    selectedYear: this.props.year,
  };

  handlerClick = () => {
    this.setState(prevState => ({
      selectedYear: this.props.year,
      isOpen: !prevState.open,
    }));
  };

  handlerRangeSelect = (options) => {
    const { handlerAfterRangeSelect } = this.props;

    handlerAfterRangeSelect({ year: options.value });
  };

  handlerForwardClick = () => {
    const {
      year, years, handlerAfterRangeSelect, withoutSelect
    } = this.props;

    if (year < Math.max(...years) || withoutSelect) {
      handlerAfterRangeSelect({ year: Number.parseFloat(year) + 1 });
    }
  };

  handlerBackClick = () => {
    const {
      year, years, handlerAfterRangeSelect, withoutSelect
    } = this.props;

    if (year > Math.min(...years) || withoutSelect) {
      handlerAfterRangeSelect({ year: Number.parseFloat(year) - 1 });
    }
  };

  handlerClose = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  };

  handlerSave = () => {
    const { selectedYear } = this.state;
    const { handlerAfterRangeSelect } = this.props;

    handlerAfterRangeSelect({ year: selectedYear });

    this.setState({ isOpen: false });
  };

  handlerChange = ({ value }) => {
    this.setState({ selectedYear: value });
  };

  render() {
    const {
      years, year, intl, withoutSelect, labelClassName
    } = this.props;
    const { isOpen, selectedYear } = this.state;
    const isBackDisabled = !withoutSelect ? selectedYear <= Math.min(...years) : false;
    const isForwardDisabled = !withoutSelect ? selectedYear >= Math.max(...years) : false;
    const yearsOptions = years.map(y => ({ label: y, value: y }));
    const { formatMessage } = intl;

    return (
      <div className={classnames(styles.rangePickerContainer, { [styles.withoutSelect]: withoutSelect })}>
        <div className={styles.desktop}>
          <button
            type='button'
            className={classnames(styles.leftButton, { [styles.disabled]: isBackDisabled })}
            onClick={this.handlerBackClick}
            disabled={isBackDisabled}
          >
            <LeftIcon />
          </button>

          {withoutSelect ?
            <div className={classnames(styles.yearLabel, labelClassName)}>{year}</div>
            : (
              <Select
                className={classnames(styles.select, labelClassName)}
                classNameButton={styles.selectButton}
                classNamePopup={styles.selectPopup}
                options={yearsOptions}
                value={year}
                onChange={this.handlerRangeSelect}
                labelPath='label'
                valuePath='value'
                closeOnChange
              />
            )}

          <button
            type='button'
            className={classnames(styles.rightButton, { [styles.disabled]: isForwardDisabled })}
            onClick={this.handlerForwardClick}
            disabled={isForwardDisabled}
          >
            <RightIcon />
          </button>
        </div>

        {!withoutSelect && (
          <div className={styles.mobile}>
            <DateButton onClick={this.handlerClick} />
          </div>
        )}

        {isOpen && !withoutSelect && (
          <div className={styles.modal}>
            <div className={styles.header}>
              <div>
                {formatMessage({ id: 'harvest.selectPeriod' })}
              </div>
              <CloseIcon className={styles.close} onClick={this.handlerClose} />
            </div>
            <div className={styles.body}>
              <div className={styles.selects}>
                <BigSelectInput
                  onRenderArrow={({ className }) => <ToggleIcon className={className} />}
                  placeholder={formatMessage({ id: 'harvest.selectPeriod' })}
                  options={yearsOptions}
                  classNameIcon={styles.popupDateSelectIcon}
                  value={selectedYear}
                  onChange={this.handlerChange}
                  labelPath='label'
                  valuePath='value'
                  closeOnChange
                />
              </div>
              <div className={styles.buttons}>
                <BigButton
                  title={formatMessage({ id: 'button.cancel' })}
                  type='button'
                  onClick={this.handlerClose}
                  theme='light'
                />
                <BigButton
                  title={formatMessage({ id: 'cropsPerformance.showResults' })}
                  theme='dark'
                  onClick={this.handlerSave}
                />
              </div>
            </div>
          </div>
        )}

      </div>
    );
  }
}

export default injectIntl(YearPicker);
