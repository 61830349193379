import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import { get } from 'lodash';

import { Link as RouterLink } from 'react-router-dom';

import BigButton from '../BigButton';
import CircleLoader from '../CircleLoader';
import Select from '../Select';
import EmptyState from '../EmptyState';
import CycleTable from './components/CycleTable';
import PageViewTracker from '../PageViewTracker';

import styles from './NewCrops.module.css';
import circleLoaderStyles from '../CircleLoader/CircleLoader.module.css';

const SHOW_ALL = 'SHOW_ALL';
const SHOW_ACTIVE = 'SHOW_ACTIVE';
const SHOW_COMPLETED = 'SHOW_COMPLETED';


export default class NewCrops extends Component {
  static propTypes = {
    intl: intlShape.isRequired,

    getCropsData: PropTypes.func.isRequired,
    updateCropsVisibility: PropTypes.func.isRequired,
    updateCropsSorting: PropTypes.func.isRequired,
    requestDeletePlantingCycle: PropTypes.func.isRequired,

    visibilityFilter: PropTypes.string.isRequired,
    isCropsFetching: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    cyclesCounter: PropTypes.object.isRequired,
    isAddPlantingCycleAvailable: PropTypes.bool.isRequired,
    isAddHarvestAvailable: PropTypes.bool.isRequired,
    trackPageViewPlantingCycles: PropTypes.func.isRequired,
    trackCreatePlantingCycleClick: PropTypes.func.isRequired,
    trackEditPlantingCycleClick: PropTypes.func.isRequired,
    trackAddHarvestClick: PropTypes.func.isRequired,
    clearHarvestData: PropTypes.func.isRequired,
    visibleCycles: PropTypes.array,
    cropsSorting: PropTypes.object,
    fromPage: PropTypes.string,
    organization: PropTypes.object,
    cyclesToCompare: PropTypes.array,
    addCycleToComparison: PropTypes.func.isRequired,
    removeCycleFromComparison: PropTypes.func.isRequired,
    isCropCompareAvailable: PropTypes.bool,
  };

  static defaultProps = {
    visibleCycles: [],
    cropsSorting: null,
    fromPage: null,
    organization: null,
    cyclesToCompare: [],
    isCropCompareAvailable: false,
  };

  componentDidMount() {
    const { getCropsData } = this.props;

    getCropsData();
  }

  getEmptyState = () => {
    const {
      intl,
      visibilityFilter,
      match,
    } = this.props;

    const { params: { organizationSlug } } = match;
    const { formatMessage } = intl;

    let type = '';
    let typeText = '';

    switch (visibilityFilter) {
      case SHOW_ALL:
        type = 'planting';
        typeText = 'allEmpty';
        break;
      case SHOW_COMPLETED:
        type = 'completed';
        typeText = 'completedEmpty';
        break;
      default:
        type = 'active';
        typeText = 'activeEmpty';
        break;
    }

    const emptyStateHeader = formatMessage(
      { id: 'emptyStates.cyclesEmpty' },
      { type: formatMessage({ id: `emptyStates.${type}` }) }
    );

    const emptyStateText = (
      <span>
        {formatMessage({ id: `emptyStates.${typeText}` })}
        {visibilityFilter !== SHOW_COMPLETED && (
          <span>
            <RouterLink
              className={styles.chatLink}
              to={`/${organizationSlug}/crops/create`}
            >
              {formatMessage({ id: 'emptyStates.createCycle' })}
            </RouterLink>
            <span>.</span>
          </span>
        )}
      </span>
    );

    return (
      <EmptyState
        header={emptyStateHeader}
        text={emptyStateText}
        intl={intl}
      />
    );
  };

  handlerPageView = () => {
    const { trackPageViewPlantingCycles, fromPage } = this.props;
    const defaultOpen = fromPage === 'cropEdit';

    trackPageViewPlantingCycles('Planting Cycles', defaultOpen);
  };

  handlerCreatePlantingCycle = () => {
    const { trackCreatePlantingCycleClick, clearHarvestData } = this.props;

    // Нужно чтобы после создания нового цикла и редиректа на него не рендерился старый цикл из стора
    clearHarvestData();

    trackCreatePlantingCycleClick();
  }

  handlerVisibilitySelect = (options) => {
    const { updateCropsVisibility } = this.props;

    updateCropsVisibility(options.value);
  };

  renderSelectLabel = (text, count) => (
    <div className={styles.selectLabel}>
      <span>{text}</span>
      <span className={styles.selectLabelValue}>{count}</span>
    </div>
  );

  render() {
    const {
      intl,
      visibilityFilter,
      visibleCycles,
      isCropsFetching,
      updateCropsSorting,
      cropsSorting,
      cyclesCounter,
      trackEditPlantingCycleClick,
      trackAddHarvestClick,
      history,
      match,
      organization,
      isAddPlantingCycleAvailable,
      isAddHarvestAvailable,
      requestDeletePlantingCycle,
      cyclesToCompare,
      addCycleToComparison,
      removeCycleFromComparison,
      isCropCompareAvailable,
    } = this.props;

    const { params: { organizationSlug } } = match;
    const { formatMessage } = intl;

    const visibility = [
      {
        buttonLabel: formatMessage({ id: 'cycle.showAllTitle' }),
        label: this.renderSelectLabel(formatMessage({ id: 'cycle.showAll' }), get(cyclesCounter, SHOW_ALL)),
        value: SHOW_ALL
      },
      {
        buttonLabel: formatMessage({ id: 'cycle.showActiveTitle' }),
        label: this.renderSelectLabel(formatMessage({ id: 'cycle.showActive' }), get(cyclesCounter, SHOW_ACTIVE)),
        value: SHOW_ACTIVE
      },
      {
        buttonLabel: formatMessage({ id: 'cycle.showCompletedTitle' }),
        label: this.renderSelectLabel(formatMessage({ id: 'cycle.showCompleted' }), get(cyclesCounter, SHOW_COMPLETED)),
        value: SHOW_COMPLETED
      },
    ];

    return (
      <>
        <div
          className={classnames(styles.layout)}
        >
          <PageViewTracker onMount={this.handlerPageView} />
          {isCropsFetching ? (
            <CircleLoader
              className={circleLoaderStyles.circleLoader}
              iconClassName={circleLoaderStyles.circleLoaderIcon}
            />
          )
            : (
              <div className={styles.content}>
                <div className={styles.controls}>
                  <Select
                    className={classnames(styles.control, styles.select)}
                    classNameButton={styles.selectButton}
                    classNamePopup={styles.selectPopup}
                    classNameOption={styles.selectOption}
                    options={visibility}
                    value={visibilityFilter}
                    onChange={this.handlerVisibilitySelect}
                    labelPath='label'
                    buttonLabelPath='buttonLabel'
                    valuePath='value'
                    closeOnChange
                    withBigArrow
                  />

                  {isAddPlantingCycleAvailable && (
                  <BigButton
                    onClick={this.handlerCreatePlantingCycle}
                    href={`/${organizationSlug}/crops/create`}
                    className={classnames(styles.control, styles.createPlantingCycleButton)}
                    tabIndex={0}
                    title={formatMessage({ id: 'plantingCycles.addCycle' })}
                    theme='dark'
                  />
                  )}
                </div>
                {visibleCycles.length === 0 ?
                  this.getEmptyState()
                  : (
                    <div className={styles.table}>
                      <CycleTable
                        organization={organization}
                        history={history}
                        intl={intl}
                        visibleCycles={visibleCycles}
                        updateCropsSorting={updateCropsSorting}
                        cropsSorting={cropsSorting}
                        visibilityFilter={visibilityFilter}
                        trackEditPlantingCycleClick={trackEditPlantingCycleClick}
                        trackAddHarvestClick={trackAddHarvestClick}
                        isAddPlantingCycleAvailable={isAddPlantingCycleAvailable}
                        isAddHarvestAvailable={isAddHarvestAvailable}
                        requestDeletePlantingCycle={requestDeletePlantingCycle}
                        cyclesToCompare={cyclesToCompare}
                        removeCycleFromComparison={removeCycleFromComparison}
                        addCycleToComparison={addCycleToComparison}
                        isCropCompareAvailable={isCropCompareAvailable}
                      />
                    </div>
                  )}
              </div>
            )}
        </div>
      </>
    );
  }
}
