import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
// @ts-ignore
import { injectIntl, InjectedIntlProps } from 'react-intl';


import { TreeNodePathComponents } from 'store/graphs/types';

import ColorPicker from '../../../../../ColorPicker';


import styles from './index.module.css';

export type OnSelectItemFunc = (nodeMetric: Array<TreeNodePathComponents>) => void;
export type OnChangeColorItemFunc = (nodeMetric: Array<TreeNodePathComponents>, color: string) => void;

export type ChartLegendItemProps = {
  itemKey: string;
  title: string;
  description: string | null;
  selected: boolean;
  rightAxis: boolean;
  color: string;
  // eslint-disable-next-line react/require-default-props
  node?:Array<TreeNodePathComponents>;
  // eslint-disable-next-line react/require-default-props
  isSingleGroupCheck?: boolean;
  hiddenLines: Array<Array<TreeNodePathComponents>>;
};

export type ChartLegendItemPropsFunc = {
  onSelected: OnSelectItemFunc;
  onChangeColor: OnChangeColorItemFunc;
}

const ChartLegendItem = ({
  intl,
  node,
  itemKey,
  title,
  description,
  isSingleGroupCheck,
  color,
  rightAxis,
  selected,
  onSelected,
  onChangeColor,
  hiddenLines
}: ChartLegendItemProps & ChartLegendItemPropsFunc & InjectedIntlProps) => {
  const { formatMessage } = intl;

  const [showColorPicker, setShowColorPicker] = useState(false);

  const handlerSelect = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    onSelected(node);
  }, [onSelected, node]);

  const handleClickColorSelect = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowColorPicker(true);
  }, [setShowColorPicker]);

  const handleChangeColor = useCallback((selectedColor: string) => {
    onChangeColor(node, selectedColor);
  }, [onChangeColor, node]);

  const handleClickOutsideColorPicker = useCallback(() => {
    setShowColorPicker(false);
  }, [setShowColorPicker]);

  const isLineHidden = useMemo(() => hiddenLines.findIndex((lineMetric: Array<TreeNodePathComponents>) => (
      node.join('_') === lineMetric.join('_')
    )) !== -1, [hiddenLines, node]);

  return (
    <div
      className={classnames(styles.legendItem, {
        [styles.selected]: !isLineHidden,
        [styles.notSelected]: isLineHidden
      })}
      key={`legend-item-${itemKey}`}
    >
      <div
        role='button'
        tabIndex={0}
        className={styles.legendItemColor}
        onClick={handleClickColorSelect}
      >
        <div className={styles.legendItemColorSquare} style={{ backgroundColor: color }} />
      </div>
      <div
        className={styles.textContainer}
        onClick={handlerSelect}
        role='button'
        tabIndex={0}
      >
        <div
          className={styles.legendItemTitle}
        >
          {title}
        </div>
        {!isSingleGroupCheck && description && <div className={styles.legendItemSpace}>,&nbsp;</div>}
        {!isSingleGroupCheck && description ? <div className={styles.legendItemDescription}>{description}</div> : ''}
        {rightAxis && (
          <div className={styles.legendItemSecondAxis}>&nbsp;{rightAxis ?
            `(${formatMessage({ id: 'graphs.rightAxis' })})` : ''}
          </div>
        )}
      </div>
      {showColorPicker && (
        <ColorPicker
          wrapperClassName={styles.colorPicker}
          onChange={handleChangeColor}
          onClickOutside={handleClickOutsideColorPicker}
          initValue={color}
        />
      )}
    </div>
  );
};

export default injectIntl(ChartLegendItem);
