import React, { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import moment from 'moment-timezone';
import classnames from 'classnames';
import {
  get, isNumber, isNaN, isNil
} from 'lodash';

import { API_DATE_FORMAT } from 'helpers/defaultDates';
import getDateFormat from 'helpers/getDateFormat';
import numbersRounding from 'helpers/numbersRounding';

import DefaultSimpleTooltip from 'components/DefaultSimpleTooltip';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import DefaultHotTable from 'components/DefaultHotTable';

import ChartIcon from 'components/Icons/ChartIcon';
import ChartNotSelectedIcon from 'components/Icons/ChartNotSelectedIcon';

import numbersFormatting from 'helpers/numbersFormatting';
import CellWithError from '../CellWithError';

import styles from './EnergyAddTable.module.css';

const ROW_HEADER_HEIGHT = 112;
const ROW_HEIGHT = 39;
const MAX_VISIBLE_COL = 3;

const numericValidator = (prevValue, maxValue, formatMessage, query, callback) => {
  if (query === null || query === '') {
    return callback(true);
  }

  if (!isNumber(query) || isNaN(query)) {
    return callback(false, formatMessage({ id: 'formErrors.shouldBeANumber' }));
  }

  // Временно убираем, пока не можем получить с бэка достоверных чисел для maxValue
  // if (Number(query) > maxValue) {
  //   const formattedMaxValue = numbersFormatting(maxValue);
  //   const errorText = formatMessage({ id: 'formErrors.cannotBeExceed' }, { number: formattedMaxValue });

  //   return callback(false, errorText);
  // }
  if (Number(query) < 0) {
    return callback(false, formatMessage({ id: 'formErrors.cannotBeZero' }));
  }

  /**
   * Если текущее значение счетчика “много меньше” предыдущего, то мы считаем, что это не ошибка, а счетчик просто обнулили
   * https://pylotapp.slack.com/archives/C80K84L8J/p1618228088003700?thread_ts=1617960877.001400&cid=C80K84L8J
   */
  const isMeterReset = Number(query) < 0.01 * Number(prevValue);

  // При типе meterReading в каждую ячейку должно вводиться значение не меньше предыдущего
  if ((prevValue !== null || prevValue !== '') && Number(query) < Number(prevValue) && !isMeterReset) {
    return callback(false, formatMessage({ id: 'energy.formErrors.meterReading' }));
  }

  return callback(true);
};

const getMaxMeterValue = (mode, type) => {
  if (type === 'electricityMeter') {
    if (mode === 'meterReading') {
      return 1000000000;
    }
    return 9000000;
  }

  if (type === 'gasMeter') {
    if (mode === 'meterReading') {
      return 1000000000;
    }
    return 1000000;
  }

  if (type === 'waterMeter') {
    if (mode === 'meterReading') {
      return 1000000000;
    }
    return 100000;
  }

  return 1000000;
};

const getMeterValidationParams = (registrationsMeters, instance, row, col) => {
  let prevValue = null;
  const currentMeterMode = get(registrationsMeters, `[${col - 1}].registrationMode`);
  const currentMeterType = get(registrationsMeters, `[${col - 1}].type`);
  const maxValue = getMaxMeterValue(currentMeterMode, currentMeterType);

  // считаем только для meterReading, для других типов возвращаем null
  if (currentMeterMode === 'meterReading') {
    prevValue = instance.getDataAtCell(row - 1, col);
  }

  return {
    prevValue,
    maxValue,
  };
};


// renderToStaticMarkup т.к. handsontable работает только с html
const renderCellWithError = (alignLeft, alignBottom, error, value) => ReactDOMServer
  .renderToStaticMarkup(<CellWithError
    value={value}
    error={error}
    errorClassName={styles.errorContent}
    alignLeft={alignLeft}
    alignBottom={alignBottom}
  />);

class EnergyAddTable extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    isFetching: PropTypes.bool,
    energyRegistrations: PropTypes.object,
    tableData: PropTypes.array,
    colWidths: PropTypes.number.isRequired,

    tableRef: PropTypes.object.isRequired,
    handleTableChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isFetching: false,
    energyRegistrations: null,
    tableData: [],
  };

  // Пока что отказался от registerValidator, чтобы передавать prevValue для Cross-column validation
  // constructor(props) {
  //   super(props);

  //   const { intl: { formatMessage } } = props;

  //   Handsontable.validators.registerValidator('table.numericValidator', (query, callback) => numericValidator(null, formatMessage, query, callback));
  // }

  calculateTableHeight = (data) => {
    const borders = 2;
    // TODO: Посмотреть на методы получения высоты хедеров и строк в handsontable
    const height = (data.length * ROW_HEIGHT) + ROW_HEADER_HEIGHT + borders;

    return height;
  };

  // handleAfterValidate = (isValid, value, row, prop) => {
  //   const { formErrors } = this.state;

  //   const errorObj = {
  //     dataId: prop[0],
  //     isValid,
  //     row,
  //     value,
  //   };

  //   const filteredFormErrors = filter(formErrors, item => (item.dataId !== errorObj.dataId));

  //   if (isValid) {
  //     return this.setState({ formErrors: filteredFormErrors });
  //   }

  //   return this.setState({ formErrors: [...filteredFormErrors, errorObj] });
  // };

  rerenderColHeaders = (registrationsMeters, col, formatMessage) => {
    if (col === 0) {
      return formatMessage({ id: 'harvest.date' });
    }

    return this.renderMeterHeaderCell(registrationsMeters[col - 1]);
  };

  rerenderColumns = (registrationsMeters, formatMessage) => ([
    {
      data: 'date',
      editor: false,
      readOnly: true,
      renderer(instance, td, row, col, prop, value) {
        /* eslint-disable no-param-reassign */
        if (value === 'total') {
          td.className = styles.totalFirstCell;
          td.innerHTML = formatMessage({ id: 'energy.total' });
        } else {
          const isDateDisabled = moment(value, API_DATE_FORMAT) > moment();
          td.className = isDateDisabled ? classnames(styles.dateCell, styles.dateCellDisabled) : styles.dateCell;
          td.innerHTML = moment(value, API_DATE_FORMAT).format(getDateFormat('LLLL'));
        }
        /* eslint-enable no-param-reassign */

        return td;
      },
    },

    ...registrationsMeters.reduce((acc, meter) => ([
      ...acc,

      {
        data: [meter.id],
        type: 'numeric',
        // renderer: 'html',
        renderer(instance, td, row, col, prop, value) {
          let error;
          let isCellValid = true;

          const validationCallback = (isValid, errorMessage) => {
            error = errorMessage;

            return isValid;
          };

          if (!isNil(value) && value !== '') {
            // При типе meterReading в каждую ячейку должно вводиться значение не меньше предыдущего
            const { prevValue, maxValue } = getMeterValidationParams(registrationsMeters, instance, row, col);

            isCellValid = numericValidator(prevValue, maxValue, formatMessage, value, validationCallback);
          }

          /* eslint-disable no-param-reassign */
          if (!isCellValid) {
            const currentRowArray = instance.getDataAtRow(row);
            const colCount = currentRowArray.length;
            // выравниваем по левому крю для последних столбцов (если их много и есть скролл), чтобы не ехала таблица
            const alignLeft = (colCount > MAX_VISIBLE_COL) && ((colCount - 1) - col <= 1);

            const currentColArray = instance.getDataAtCol(col);
            const rowCount = currentColArray.length;
            // выравниваем по низу для последних 3 строк, чтобы не ехала таблица
            const alignBottom = ((rowCount - 1) - row) < 3;

            td.className = classnames('htInvalid', styles.invalidCell);
            td.innerHTML = renderCellWithError(alignLeft, alignBottom, error, value);
          } else {
            td.innerHTML = numbersFormatting(value);
          }
          /* eslint-enable no-param-reassign */

          // return td;
        },
        validator: function cellValidator(value, callback) {
          // Не вешаем просто 'table.numericValidator', чтобы передавать prevValue
          // При типе meterReading в каждую ячейку должно вводиться значение не меньше предыдущего
          const { prevValue, maxValue } = getMeterValidationParams(registrationsMeters, this.instance, this.row, this.col);

          numericValidator(prevValue, maxValue, formatMessage, value, callback);
        }
      }
    ]), []),
  ]);

  rerenderCells = (registrationsMeters, rowsCount, row, col) => {
    const cellProperties = {};

    if (col === 0) {
      return cellProperties;
    }

    const totalCellsRenderer = (instance, td) => {
      const colArray = instance.getDataAtCol(col);
      const currentMeterMode = get(registrationsMeters, `[${col - 1}].registrationMode`);
      const firstColValue = Number(get(colArray, '[0]'));

      const colSum = colArray.reduce((acc, item) => {
        const numberItem = Number(item);
        if (isNumber(numberItem) && !isNaN(numberItem)) {
          // При типе meterReading в каждую ячейку вводится итоговое значение, поэтому просто берём последнее
          if (currentMeterMode === 'meterReading' && !isNil(item) && item !== '') { // !isNil(item) && item !== '' защита от пустых строк в тотале (например, будущее)
            return numberItem - firstColValue;
          }

          return acc + numberItem;
        }

        return acc;
      }, 0);
      /* eslint-disable no-param-reassign */
      td.innerHTML = colSum === 0 ? '' : numbersRounding(colSum, 'fixed', 2);
      td.classList.add(styles.totalCell);
      /* eslint-enable no-param-reassign */
    };

    // В последней строке выводим сумму
    if (row === (rowsCount - 1)) {
      cellProperties.renderer = totalCellsRenderer;
      cellProperties.editor = false;
      cellProperties.readOnly = true;
    }
    return cellProperties;
  };

  renderMeterHeaderCell = (meter) => {
    const { intl: { formatMessage } } = this.props;

    const { name, unit, registrationMode } = meter;

    const unitsText = formatMessage({ id: `cunits.mini.${unit}` });
    const registrationModeText = formatMessage({ id: `energy.registrationModes.${registrationMode}` });
    const tooltipText = formatMessage({ id: `energy.registrationModesTooltip.${registrationMode}` });

    const isReverse = get(meter, 'reverse');
    const reversibleLocalizeText = formatMessage({ id: 'energy.reversible' });

    const meterName = isReverse ? `${name} (${reversibleLocalizeText})` : name;

    const elementRender = () => (
      <div className={styles.meterHeader}>
        <div className={styles.meterHeaderDesc}>
          <div className={styles.meterHeaderName}>{meterName}</div>
          <div className={styles.meterHeaderUnit}>{unitsText}</div>
        </div>
        <div className={styles.meterHeaderUsage}>
          <DefaultSimpleTooltip text={tooltipText}>
            <div className={styles.meterHeaderUsageIconWrapper}>
              {registrationMode === 'meterReading' ?
                <ChartIcon className={styles.meterHeaderUsageIcon} />
                :
                <ChartNotSelectedIcon className={styles.meterHeaderUsageIcon} />}
              {registrationModeText}
            </div>
          </DefaultSimpleTooltip>
        </div>
      </div>
    );

    // Т.к. handsontable работает только с html
    return ReactDOMServer.renderToStaticMarkup(elementRender());
  };

  render() {
    const {
      intl: { formatMessage },

      colWidths,
      isFetching,
      energyRegistrations,
      tableData,
      tableRef,
      handleTableChange,
    } = this.props;

    // Строим заголовки таблицы
    const registrationsMeters = get(energyRegistrations, 'meters', []);

    return (
      <div className={styles.tableContainer}>
        {isFetching && <DefaultCircleLoader />}

        <DefaultHotTable
          ref={tableRef}
          classNameWrapper={styles.tableWrapper}
          data={tableData}
          height={this.calculateTableHeight(tableData)}
          colWidths={colWidths}
          colHeaders={col => this.rerenderColHeaders(registrationsMeters, col, formatMessage)}
          columns={this.rerenderColumns(registrationsMeters, formatMessage)}
          cells={(row, col, prop) => this.rerenderCells(registrationsMeters, tableData.length, row, col, prop)}
          beforeChange={handleTableChange}
          // afterValidate={this.handleAfterValidate}
        />
      </div>
    );
  }
}

export default injectIntl(EnergyAddTable);
