import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import classnames from 'classnames';

import Select from '../../../Select';
import CumulativeToggle from '../../../CumulativeToggle';
import PercentageToggle from '../../../PercentageToggle';

import styles from './GraphHeader.module.css';

class GraphHeader extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    headerText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]).isRequired,
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.node,
      PropTypes.element
    ]),
    toggleHandler: PropTypes.func,
    percentageToggleHandler: PropTypes.func,
    selectHandler: PropTypes.func,
    className: PropTypes.string,
    currentBreakdown: PropTypes.string,
    isQualityCumulative: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    toggleHandler: null,
    percentageToggleHandler: null,
    selectHandler: null,
    className: null,
    currentBreakdown: 'compartment',
    isQualityCumulative: false,
  };

  handlerOnSelectChange = ({ value }) => {
    const { selectHandler } = this.props;

    selectHandler({ breakdown: value });
  }

  render() {
    const {
      intl: { formatMessage }, children, headerText, toggleHandler, selectHandler, className,
      currentBreakdown, isQualityCumulative, percentageToggleHandler,
    } = this.props;

    const options = [
      {
        label: formatMessage({ id: 'harvestDashboard.compartments' }),
        value: 'compartment',
      },
      {
        label: formatMessage({ id: 'harvestDashboard.cropCycles' }),
        value: 'plantingCycle',
      },
      {
        label: formatMessage({ id: 'harvestDashboard.varieties' }),
        value: 'variety',
      },
      {
        label: formatMessage({ id: 'harvestDashboard.fruitClass' }),
        value: 'fruitClass',
      },
    ];

    return (
      <div className={classnames(styles.graphHeaderWrapper, className)}>
        <div className={styles.graphHeader}>
          <h2 className={styles.graphHeaderText}>
            {headerText}
          </h2>
          <div className={styles.toggles}>
            {percentageToggleHandler && (
              <PercentageToggle
                onToggleChange={percentageToggleHandler}
                className={styles.percentageToggle}
              />
            )}
            {toggleHandler && (
              <CumulativeToggle
                onToggleChange={toggleHandler}
                isQualityCumulative={isQualityCumulative}
              />
            )}
          </div>
          {selectHandler && (
            <Select
              className={styles.select}
              classNameButton={styles.selectButton}
              classNamePopup={styles.selectPopup}
              options={options}
              value={currentBreakdown}
              onChange={this.handlerOnSelectChange}
              labelPath='label'
              valuePath='value'
              theme='transparent'
              size='medium'
              closeOnChange
            />
          )}
        </div>
        {children && (
          <div className={styles.graphHeaderContent}>
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(GraphHeader);
