import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import classnames from 'classnames';

import BigButton from '../../BigButton';
import ArrowBackIcon from '../../Icons/ArrowBack';

import styles from './BackButton.module.css';

export default function BackButton({
  className, link, text, onClick
}) {
  if (link) {
    return (
      <RouterLink
        className={classnames(styles.backButton, className)}
        to={link}
      >
        <ArrowBackIcon />
        <span>
          {text}
        </span>
      </RouterLink>
    );
  }

  return (
    <BigButton
      className={classnames(styles.backButton, styles.backButtonWithAction, className)}
      titleClassName={styles.backButtonTitle}
      icon={<ArrowBackIcon />}
      title={text}
      onClick={onClick}
    />
  );
}

BackButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

BackButton.defaultProps = {
  link: null,
  className: undefined,
  onClick: () => {},
};
