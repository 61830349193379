import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { midAngle, positionFromAngle, labelsThemePropType } from '@nivo/core';
import { arcPropType } from './props';

const sliceStyle = {
  pointerEvents: 'none',
};

export default class PieSlicesLabels extends Component {
    static propTypes = {
      arcs: PropTypes.arrayOf(arcPropType).isRequired,

      /* eslint-disable react/require-default-props */
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      /* eslint-enable react/require-default-props */
      skipAngle: PropTypes.number,
      radius: PropTypes.number.isRequired,
      innerRadius: PropTypes.number.isRequired,
      textColor: PropTypes.func.isRequired,
      theme: PropTypes.shape({
        labels: labelsThemePropType.isRequired,
      }).isRequired,
    }

    static defaultProps = {
      skipAngle: 0,
    }

    render() {
      const {
        arcs, label, radius, skipAngle, innerRadius, textColor, theme
      } = this.props;

      const centerRadius = innerRadius + ((radius - innerRadius) / 2);

      return (
        <>
          {arcs.filter(arc => skipAngle === 0 || arc.angleDeg > skipAngle).map((arc) => {
            const angle = midAngle(arc) - (Math.PI / 2);
            const position = positionFromAngle(angle, centerRadius);

            return (
              <g
                key={arc.data.id}
                transform={`translate(${position.x}, ${position.y})`}
                style={sliceStyle}
              >
                <text
                  textAnchor='middle'
                  style={{
                    ...theme.labels.text,
                    fill: textColor(arc.data, theme),
                  }}
                >
                  {label(arc.data)}
                </text>
              </g>
            );
          })}
        </>
      );
    }
}
