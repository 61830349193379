import differenceInMinutes from 'date-fns/differenceInMinutes';

import { isFinite } from 'lodash';

import { addMinutes } from 'date-fns';

import hex2rgba from 'helpers/hex2rgba';
import { epochMinutesToGMT } from 'helpers/datesHelper';
import { addPoints, pointInRect } from '../helpers/GeometryUtils';
import {
  getColumnWidthByViewport,
  INCIDENT_TIMELINE_CONFIG,
  slicePeriods
} from '../helpers/incidentScale';
import { roundRect } from '../helpers/DrawUtils';
import { textEllipsis } from '../helpers/TextUtils';

import ScrollArea from './ScrollArea';


const COLORS = ['#F7E2E7', '#D25871', '#990515'];
const COLORS_GRAY = ['#F5F5F5', '#E6E6E6', '#D6D6D6'];


function getSeverityColor(severity) {
  return COLORS[severity - 1];
}

function getSeverityColorGray(severity) {
  return COLORS_GRAY[severity - 1];
}

class IncidentTimeline extends ScrollArea {
  constructor({
    position,
    currentPeriod,
    incidentTree,
    onClickNode,
    intl,
    onZoom,
    scaleComponent,
    overlayComponent
  }) {
    super({
      position,
      cursorType: 'default',
      scrollOnDrag: false
    });
    this.updateCurrentPeriod(currentPeriod);
    /*
    this.updateData({
      period,
      treeData
    });
    */
    this.incidentTree = incidentTree;
    this.incidentsBounds = [];
    this.onClickNode = onClickNode;
    this.intl = intl;
    this.onZoom = onZoom;
    this.scaleComponent = scaleComponent;
    this.animationState = [];
    this.overlayComponent = overlayComponent;
    // this.transparentNodes = [];
    this.setDebugColor('green');
    this.incidentsBoundsCache = null;
    this.incidentsTextGeometry = [];
    this.transparentNodeDirty = true;
    this.expandedNodes = [];
  }

  updateView({
    viewport
  }) {
    this.viewport = viewport;
    this.setDirty();
    this.updateColWidth();
    this.setScrollSize({
      width: this.getContentWidth(),
      height: this.getContentHeight(),
    });
    // this.calcIncidentsBounds();
    // this.incidentCacheDirty = true;
  }

  setIncidentCacheDirty() {
    this.incidentCacheDirty = true;
  }

  getContentHeight() {
    return this.incidentTree ? this.incidentTree.getContentHeight() : 0;
  }

  getContentWidth() {
    return this.scaleComponent ? this.scaleComponent?.getContentWidth() : 0;
  }

  setTimelineScroll({ x, y }) {
    this.setScrollPosition({ x, y });
  }

  onScroll({ x, y }) {
    super.onScroll({ x, y });
    if (this.incidentTree) {
      this.incidentTree.setTreeScroll(this.scroll);
    }

    if (this.scaleComponent) {
      this.scaleComponent.setScaleScroll(this.scroll);
    }
  }

  updateBoundingBox() {
    super.updateBoundingBox();
    this.bbox = {
      x: INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH,
      y: INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT,
      ...this.getSize()
    };
  }

  getSize() {
    return this.viewport ? {
      width: this.viewport.width - INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH,
      height: this.viewport.height - INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT
    } : {
      width: 0,
      height: 0
    };
  }

  updatePeriod(period) {
    this.period = period;
    this.periods = slicePeriods(period);
    this.updateColWidth();
    this.setScrollSize({
      width: this.getContentWidth(),
      height: this.getContentHeight(),
    });
    this.incidentCacheDirty = true;
    // this.expandedNodes = {};
  }

  updateCurrentPeriod(currentPeriod) {
    this.currentPeriod = currentPeriod;
  }

  updateColWidth() {
    if (this.viewport && this.periods) {
      this.colWidth = getColumnWidthByViewport(this.viewport, this.periods);
      this.updateIncidentTimeRange();
    }
  }

  updateData({
    period,
    treeData
  }) {
    // this.expandedNodes = [];
    this.treeData = treeData;
    this.updatePeriod(period);
    this.setScrollSize({
      width: this.getContentWidth(),
      height: this.getContentHeight(),
    });
  }

  updateIncidentTimeRange() {
    if (this.colWidth && this?.periods?.length) {
      this.periodStart = this.period.start;
      this.periodEnd = this.periods[this.periods.length - 1].end;
      this.minutesInPeriod = differenceInMinutes(this.periodEnd, this.periodStart);
      this.timelineWidth = this.colWidth * this.periods.length;
      this.timelineStep = this.timelineWidth / this.minutesInPeriod;
    }
  }

  calcIncidentBounds({
    range,
    bounds
  }) {
    const height = 16;

    const incidentStart = epochMinutesToGMT(range[0]);
    const incidentEnd = epochMinutesToGMT(range[1]);

    const periodStartInMinutes = differenceInMinutes(incidentStart, this.periodStart);
    const periodWidthInMinutes = differenceInMinutes(incidentEnd, incidentStart);

    const x = periodStartInMinutes * this.timelineStep;
    const width = periodWidthInMinutes * this.timelineStep;
    const baselineY = (bounds.y + bounds.height / 2) - (height / 2);

    return {
      x,
      width,
      y: baselineY,
      height,
    };
  }

  buildBoundKey({
    range,
    bounds
  }) {
    return `${range[0]}-${range[1]}-${bounds.x}-${bounds.y}-${bounds.width}-${bounds.height}`;
  }

  calcIncidentsBounds({ ctx }) {
    if (this.incidentTree && this.incidentCacheDirty) {
      this.incidentsBoundsCache = {};
      this.incidentsTextGeometry = [];
      const nodesBounds = this.incidentTree.getNodesBounds();
      for (let nodeBoundIndex = 0; nodeBoundIndex < nodesBounds.length; nodeBoundIndex += 1) {
        const {
          node: {
            sourceNode,
            key,
            text
          },
          nodeBounds: bounds
        } = nodesBounds[nodeBoundIndex];
        // Calc incident groups bounds
        if (sourceNode?.groups?.length > 0) {
          for (let grpIndex = 0; grpIndex < sourceNode.groups.length; grpIndex += 1) {
            const group = sourceNode.groups[grpIndex];
            const nextGroup = grpIndex >= sourceNode.groups.length - 1 ? null :
                sourceNode.groups[grpIndex + 1];
            const {
              data,
              range: groupRange
            } = group;
            const dataKeys = Object.keys(data);
            for (let i = 0; i < dataKeys.length; i += 1) {
              const severity = dataKeys[i];
              const {
                ranges,
              } = data[severity];
              for (let rangeIndex = 0; rangeIndex < ranges.length; rangeIndex += 1) {
                const range = ranges[rangeIndex];
                if (INCIDENT_TIMELINE_CONFIG.CROP_RANGE) {
                  if (range[0] >= groupRange[0] && range[1] <= groupRange[1]) {
                    this.incidentsBoundsCache[this.buildBoundKey({
                      range,
                      bounds
                    })] = this.calcIncidentBounds({
                      range,
                      bounds
                    });
                  }
                } else {
                  this.incidentsBoundsCache[this.buildBoundKey({
                    range,
                    bounds
                  })] = this.calcIncidentBounds({
                    range,
                    bounds
                  });
                }
              }
            }
            // save lines
            this.saveIncidentLineGeometry({
              ctx,
              range: groupRange,
              bounds,
              entity: sourceNode.entity,
              text,
              nextGroup,
              key
            });
          }
        }
        // Calc incidents bounds
        if (sourceNode?.incidents?.length > 0) {
          for (let incidentIndex = 0; incidentIndex < sourceNode.incidents.length; incidentIndex += 1) {
            const nextIncident = incidentIndex >= sourceNode.incidents.length - 1 ? null :
                sourceNode.incidents[incidentIndex + 1];
            const {
              range,
              id,
              type
            } = sourceNode.incidents[incidentIndex];
            this.incidentsBoundsCache[this.buildBoundKey({
              range,
              bounds
            })] = this.calcIncidentBounds({
              range,
              bounds
            });
            this.saveIncidentLineGeometry({
              ctx,
              range,
              bounds,
              text,
              nextGroup: nextIncident ? { range: nextIncident.range } : null,
              incident: {
                id, type
              },
              key
            });
          }
        }
      }
      this.incidentCacheDirty = false;
      this.transparentNodeDirty = true;
      this.setScrollSize({
        width: this.getContentWidth(),
        height: this.getContentHeight(),
      });
      this.updateScroll();
      this.setDirty();
    }
  }

  drawBackground = ({
    ctx, position, scrollX
  }) => {
    const height = Math.max(this.getContentHeight() || ctx.canvas.height, ctx.canvas.height);

    ctx.fillStyle = '#ffffff';
    ctx.fillRect(position.x, position.y, ctx.canvas.width, ctx.canvas.height);

    for (let periodIndex = 0; periodIndex < this.periods.length; periodIndex += 1) {
      const { periodNumber, start, end } = this.periods[periodIndex];
      const color = periodNumber === this.currentPeriod ? '#e4f3f8' : '#ffffff';
      const periodStartInMinutes = differenceInMinutes(start, this.periodStart);
      const periodWidthInMinutes = differenceInMinutes(end, start);
      const baseX = (periodStartInMinutes * this.timelineStep + INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH)
        + (this.colWidth / 2.0) + scrollX;
      const baseWidth = periodWidthInMinutes * this.timelineStep;
      ctx.fillStyle = color;
      ctx.fillRect(
        baseX,
        position.y,
        baseWidth,
        height
      );
      ctx.fillStyle = '#e7e9ee';
      ctx.fillRect(
        baseX,
        position.y,
        1,
        height
      );
      ctx.fillRect(
        baseX + baseWidth,
        position.y,
        1,
        height
      );
    }
  }

  drawIncidentGroup({
    ctx,
    group,
    position,
    bounds,
    entity,
    text,
    nextGroup,
    key
  }) {
    if (!this.colWidth || !this.period) {
      return;
    }
    const {
      data,
      range: groupRange
    } = group;
    const dataKeys = Object.keys(data);
    for (let i = 0; i < dataKeys.length; i += 1) {
      const severity = dataKeys[i];
      const {
        incidents,
        ranges,
        tooltipText,
        types
      } = data[severity];
      for (let rangeIndex = 0; rangeIndex < ranges.length; rangeIndex += 1) {
        const range = ranges[rangeIndex];
        if (INCIDENT_TIMELINE_CONFIG.CROP_RANGE) {
          if (range[0] >= groupRange[0] && range[1] <= groupRange[1]) {
            this.drawIncident({
              ctx,
              range,
              severity,
              position,
              bounds,
              entity,
              text,
              nextGroup,
              tooltip: {
                incidents,
                types,
                tooltipText
              },
              key,
              group
            });
          }
        } else {
          this.drawIncident({
            ctx,
            range,
            severity,
            position,
            bounds,
            entity,
            text,
            nextGroup,
            tooltip: {
              incidents,
              types,
              tooltipText
            },
            key,
            group
          });
        }
      }
    }
  }

  transformIncidentBounds = ({
   range,
   position,
   bounds
  }) => {
    const key = this.buildBoundKey({
      range, bounds
    });
    const bound = this.incidentsBoundsCache[key];
    if (!bound) {
      return null;
    }
    return {
      x: bound.x + position.x,
      width: bound.width < INCIDENT_TIMELINE_CONFIG.MIN_WIDTH_OF_INCIDENT_LINE ?
          INCIDENT_TIMELINE_CONFIG.MIN_WIDTH_OF_INCIDENT_LINE
          :
          bound.width,
      y: bound.y + position.y,
      height: bound.height,
    };
  }

  drawIncident({
    ctx,
    range,
    severity,
    position,
    bounds,
    entity,
    text,
    tooltip,
    incident,
    key,
    group
  }) {
    const incidentBound = this.transformIncidentBounds({
      range,
      position,
      bounds
    });
    ctx.save();
    const color = getSeverityColor(severity);
    const grayColor = getSeverityColorGray(severity);
    const boundsNode = {
      bound: incidentBound,
      entity,
      text,
      tooltip,
      incident,
      key,
      severity,
      color,
      group,
      bounds
    };
    const boundColor = this.isNodeTransparent(key) ? grayColor : color;
    roundRect(ctx, {
      ...incidentBound,
      fill: true,
      radius: {
        tl: 4,
        tr: 4,
        br: 4,
        bl: 4
      },
      color: boundColor
    });
    this.incidentsBounds.push(boundsNode);
    ctx.restore();
  }

  drawIncidentLabels = ({
    ctx,
    position,
    labels
  }) => {
    /*
    ctx.save();
    for (let i = 0; i < labels.length; i += 1) {
        const label = labels[i];
        ctx.beginPath();
        ctx.arc(label.incidentXEnd + position.x, label.baseY + position.y, 2, 0, 2 * Math.PI, false);
        ctx.fillStyle = 'green';
        ctx.fill();
    }
    ctx.restore();
    */
    ctx.save();
    ctx.fillStyle = '#777776';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';
    ctx.font = 'normal normal 400 13px Roboto';
    for (let i = 0; i < labels.length; i += 1) {
      const label = labels[i];
      ctx.fillStyle = this.isNodeTransparent(label.key) ? 'rgba(119, 119, 118, 0.5)' : '#777776';
      const ellipsisText = textEllipsis(ctx, label.text, label.availableTextWidth);
      if (ellipsisText !== '...' || (ellipsisText.length >= 6 && ellipsisText.includes('...'))) {
        ctx.fillText(ellipsisText, label.incidentXEnd + 8 + position.x, label.baseY + 2 + position.y);
      }
    }
    ctx.restore();
  }

  saveIncidentLineGeometry = ({
    ctx, range, bounds, entity, text, nextGroup, incident, key
  }) => {
    const textGeometry = {
      incidentXEnd: null,
      availableTextWidth: null,
      baseY: null,
      entity,
      text,
      incident,
      key
    };
    ctx.save();
    const firstIncidentBounds = this.calcIncidentBounds({
      range,
      bounds
    });
    textGeometry.incidentXEnd = firstIncidentBounds.x + firstIncidentBounds.width;
    textGeometry.baseY = firstIncidentBounds.y;
    if (nextGroup) {
      const nextIncidentBounds = this.calcIncidentBounds({
        range: nextGroup.range,
        bounds
      });
      const rightTextPadding = 12;
      textGeometry.availableTextWidth = nextIncidentBounds.x - textGeometry.incidentXEnd - rightTextPadding;
    } else {
      textGeometry.availableTextWidth = this.viewport.width;
    }
    this.incidentsTextGeometry.push(textGeometry);
    ctx.restore();
  }

  drawIncidents({
  // eslint-disable-next-line no-unused-vars
    ctx, position, period
  }) {
    if (this.incidentTree) {
      const nodesBounds = this.incidentTree.getNodesBounds();
      for (let i = 0; i < nodesBounds.length; i += 1) {
        const {
          node: {
            key,
            sourceNode,
            text
          },
          nodeBounds: bounds
        } = nodesBounds[i];

        // Draw incident groups
        if (sourceNode?.groups?.length > 0) {
          for (let grpIndex = 0; grpIndex < sourceNode.groups.length; grpIndex += 1) {
            const group = sourceNode.groups[grpIndex];
            const nextGroup = grpIndex >= sourceNode.groups.length - 1 ? null :
              sourceNode.groups[grpIndex + 1];
            this.drawIncidentGroup({
              ctx,
              position,
              group,
              period,
              bounds,
              entity: sourceNode.entity,
              text,
              nextGroup,
              key
            });
          }
        }

        // Draw incidents
        if (sourceNode?.incidents?.length > 0) {
          for (let incidentIndex = 0; incidentIndex < sourceNode.incidents.length; incidentIndex += 1) {
            const {
              range,
              severity,
              id,
              type
            } = sourceNode.incidents[incidentIndex];
            this.drawIncident({
              ctx,
              range,
              severity,
              position,
              bounds,
              text,
              incident: {
                id, type
              },
              key
            });
          }
        }
      }
    }
  }

  drawAnimationItem = ({
    ctx, bound, severity
  }) => {
    const color = getSeverityColor(severity);
    const BOUND_SIZE = 4;
    const boundColor = hex2rgba(color, 0.6);
    roundRect(ctx, {
      x: bound.x - BOUND_SIZE,
      y: bound.y - BOUND_SIZE,
      width: bound.width + BOUND_SIZE * 2,
      height: bound.height + BOUND_SIZE * 2,
      fill: true,
      radius: {
        tl: 4,
        tr: 4,
        br: 4,
        bl: 4
      },
      color: boundColor
    });
  }

  drawAnimation({
    ctx, position
  }) {
    if (this.incidentTree) {
      for (let i = 0; i < this.animationState.length; i += 1) {
        const { key: animationKey, severity: animationSeverity, range: animationRange } = this.animationState[i];
        const nodesBounds = this.incidentTree.getNodesBounds();
        for (let iNode = 0; iNode < nodesBounds.length; iNode += 1) {
          const {
            node: {
              key,
              sourceNode,
            },
            nodeBounds: bounds
          } = nodesBounds[iNode];
          if (key.startsWith(animationKey) && key !== animationKey) {
            if (sourceNode?.groups?.length > 0) {
              for (let grpIndex = 0; grpIndex < sourceNode.groups.length; grpIndex += 1) {
                const {
                  data,
                } = sourceNode.groups[grpIndex];
                const dataKeys = Object.keys(data);
                for (let iKey = 0; iKey < dataKeys.length; iKey += 1) {
                  const severity = dataKeys[iKey];
                  if (+severity === +animationSeverity) {
                    const {
                      ranges,
                    } = data[severity];
                    for (let rangeIndex = 0; rangeIndex < ranges.length; rangeIndex += 1) {
                      const range = ranges[rangeIndex];
                      if (animationRange[0] === range[0] && animationRange[1] === range[1]) {
                        const incidentBound = this.transformIncidentBounds({
                          range,
                          position,
                          bounds
                        });
                        this.drawAnimationItem({
                          ctx,
                          bound: incidentBound,
                          severity
                        });
                      }
                    }
                  }
                }
              }
            }
            if (sourceNode?.incidents?.length > 0) {
              for (let incidentIndex = 0;
                   incidentIndex < sourceNode.incidents.length;
                   incidentIndex += 1
              ) {
                const {
                  range,
                  severity,
                } = sourceNode.incidents[incidentIndex];
                if (+severity === +animationSeverity && animationRange[0] === range[0] && animationRange[1] === range[1]) {
                  const incidentBound = this.transformIncidentBounds({
                    range,
                    position,
                    bounds
                  });
                  this.drawAnimationItem({
                    ctx,
                    bound: incidentBound,
                    severity
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  draw(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    ctx.save();
    super.draw(ctx, {
      translateAdd: {
        // x: this.scroll.x,
        y: this.scroll.y,
      }
    });
    this.incidentsBounds = [];

    if (this.viewport) {
      this.calcIncidentsBounds({ ctx });

      ctx.clearRect(0, 0, this.viewport.width, this.viewport.height);

      const position = this.getPositionAbs();
      const screenPosition = addPoints(position, {
        x: this.scroll.x + (this.colWidth / 2.0),
        y: -INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT + this.scroll.y
      });

      this.drawBackground({
        ctx,
        position: screenPosition,
        scrollX: this.scroll.x,
        period: this.period
      });

      const drawPosition = {
        x: screenPosition.x,
        y: screenPosition.y + INCIDENT_TIMELINE_CONFIG.HEADER_HEIGHT + 20
      };

      this.drawIncidents({
        ctx,
        position: drawPosition,
        period: this.period
      });

      this.drawIncidentLabels({
        ctx,
        position: drawPosition,
        labels: this.incidentsTextGeometry
      });

      this.drawIncidentHoverState({
        ctx,
        position: drawPosition
      });

      if (isFinite(this.hoverLineX)) {
        this.drawPeriodLine({
          ctx,
          x: this.hoverLineX
        });
      }

      this.drawAnimation({ ctx, position: drawPosition });

      this.drawZoomArea({ ctx });

      const size = this.getSize();
      const bottomLineY = position.y + size.height;

      ctx.save();
      ctx.strokeStyle = '#e7e9ee';
      ctx.beginPath();
      ctx.moveTo(0, bottomLineY);
      ctx.lineTo(this.viewport.width, bottomLineY);
      ctx.stroke();
      ctx.restore();
    }

    ctx.restore();

    this.updateAnimation();
  }


  isNodeTransparent(nodeKey) {
    return Boolean(this.expandedNodes.find(n => (n.key === nodeKey)));
  }

  drawIncidentHover = ({
    ctx, bound, severity
  }) => {
    const BOUND_COLOR = 'rgba(190, 16, 52, 0.2)';
    const BOUND_SIZE = 2;

    if (!bound?.x) {
      return;
    }

    roundRect(ctx, {
      x: bound.x - BOUND_SIZE,
      y: bound.y - BOUND_SIZE,
      width: bound.width + BOUND_SIZE * 2,
      height: bound.height + BOUND_SIZE * 2,
      fill: true,
      radius: {
        tl: 4,
        tr: 4,
        br: 4,
        bl: 4
      },
      color: BOUND_COLOR
    });

    const color = getSeverityColor(severity);

    roundRect(ctx, {
      ...bound,
      fill: true,
      radius: {
        tl: 4,
        tr: 4,
        br: 4,
        bl: 4
      },
      color
    });
  }

  drawIncidentHoverState = ({
    ctx, position: screenPos
  }) => {
    ctx.save();
    if (this.selectedNode) {
      if (this.selectedNode.group) {
        const {
          ranges
        } = this.selectedNode.group.data[this.selectedNode.severity];

        for (let i = 0; i < ranges.length; i += 1) {
          const incidentBound = this.transformIncidentBounds({
            range: ranges[i],
            position: screenPos,
            bounds: this.selectedNode.bounds
          });

          this.drawIncidentHover({
            ctx,
            bound: incidentBound,
            severity: this.selectedNode.severity
          });
        }
      } else {
        this.drawIncidentHover({
          ctx,
          bound: this.selectedNode.bound,
          severity: this.selectedNode.severity
        });
      }

      if (this.selectedNode?.tooltip) {
        this.overlayComponent.setSelectedNode(this.selectedNode);
      }
    } else {
      this.overlayComponent.setSelectedNode(null);
    }

    if (this.activeIncidentId) {
      const activeNode = this.findBoundsByEntityId(this.activeIncidentId);
      if (activeNode) {
        this.drawIncidentHover({
          ctx,
          bound: activeNode.bound,
          severity: activeNode.severity
        });
      }
    }

    ctx.restore();
  }

  drawPeriodLine({
    ctx,
    x
  }) {
    const height = Math.max(this.getContentHeight() || ctx.canvas.height, ctx.canvas.height);
    ctx.save();
    ctx.strokeStyle = '#B4C5D6';
    ctx.beginPath();
    ctx.moveTo(x, 0);
    ctx.lineTo(x, height);
    ctx.stroke();
    ctx.restore();
  }

  drawZoomArea({
    ctx
  }) {
    const height = Math.max(this.getContentHeight() || ctx.canvas.height, ctx.canvas.height);
    if (this.isZoomStarted) {
      ctx.save();
      const width = this.zoomEndX - this.zoomStartX;
      if (width > 1) {
        roundRect(ctx, {
          x: this.zoomStartX,
          y: 0,
          width,
          height,
          fill: true,
          radius: {
            tl: 0, bl: 0, tr: 0, br: 0
          },
          color: 'rgba(180, 197, 214, 0.6)'
        });

        ctx.strokeStyle = '#F7F9FA';

        ctx.beginPath();
        ctx.moveTo(this.zoomStartX, 0);
        ctx.lineTo(this.zoomStartX, height);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(this.zoomEndX, 0);
        ctx.lineTo(this.zoomEndX, height);
        ctx.stroke();
      }
      ctx.restore();
    }
  }

  findIntersectWithNodeBounds({
    x, y
  }) {
    return this.incidentsBounds.sort((l, r) =>
      (+r.severity) - (+l.severity)).find(node =>
      pointInRect({ x, y }, node.bound));
  }

  findBoundsByEntityId(id) {
    return this.incidentsBounds.find(node => node?.entity?.id === id || node?.incident?.id === id);
  }

  onPointerMove({
    x, y, dx, dy
  }) {
    super.onPointerMove({
      x, y, dx, dy
    });

    this.selectedNode = this.findIntersectWithNodeBounds({ x, y });

    this.hoverLineX = x;

    this.scaleComponent.setHoverIndicatorValue({
      x,
      timelineStep: this.timelineStep,
      periodStart: this.periodStart
    });

    if (this.selectedNode) {
      this.setCursorType('pointer');
    } else {
      this.setCursorType('default');
      this.selectedNode = null;
    }
  }

  onPointerHover({ x, y }) {
    super.onPointerHover({
      x, y
    });

    this.hoverLineX = x;
  }

  onPointerOut({ x, y }) {
    super.onPointerOut({
      x, y
    });

    this.hoverLineX = null;
    this.scaleComponent.setHoverIndicatorValue(null);
  }

  onClick({
    x, y, shiftKey, ctrlKey
  }) {
    super.onClick({
      x, y, shiftKey, ctrlKey
    });
    const clickNode = this.findIntersectWithNodeBounds({ x, y });
    if (clickNode && this.onClickNode) {
      this.onClickNode({
        node: clickNode,
        nodeId: clickNode?.entity?.id
      });
    }
    return false;
  }

  onStartDrag({ x, y }) {
    super.onStartDrag({
      x, y
    });
    const clickNode = this.findIntersectWithNodeBounds({ x, y });
    // Не показываем зум, если клик был по инцеденту
    if (!clickNode) {
      this.isZoomStarted = true;
      this.zoomStartX = x;
    }
  }

  onEndDrag({ x, y }) {
    super.onEndDrag({
      x, y
    });
    if (this.isZoomStarted) {
      this.zoomEndX = x;
      this.isZoomStarted = false;
      if (this.onZoom) {
        let startX = (this.zoomStartX - INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH) / this.timelineStep;
        let endX = (this.zoomEndX - INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH) / this.timelineStep;
        if (startX > endX) {
          const endBack = endX;
          endX = startX;
          startX = endBack;
        }
        const zoomThreshold = 10;
        const zoomLen = endX - startX;
        if (zoomLen > zoomThreshold) {
          const timeValueStart = addMinutes(this.periodStart, startX);
          const timeValueEnd = addMinutes(this.periodStart, endX);
          const minutes = Math.abs(differenceInMinutes(timeValueStart, timeValueEnd));
          const minMinutesZoomInterval = 60 * 24;
          if (minutes >= minMinutesZoomInterval) {
            this.onZoom({
              start: timeValueStart,
              end: timeValueEnd
            });
          }
        }
      }
    }
  }

  onDrag({ x, y }) {
    super.onDrag({
      x, y
    });
    this.zoomEndX = x;
  }

  setExpanded(node, isNodeHasChildren) {
    const { key, severity, group } = node;

    if (isNodeHasChildren) {
      const nodeIndex = this.expandedNodes.findIndex(n => n.key === node.key);
      if (nodeIndex !== -1) {
        this.expandedNodes.splice(nodeIndex, 1);
      } else {
        this.expandedNodes.push(node);
      }
    }

    if (this.animationState.findIndex(item => item.key === key) === -1) {
      this.animationState.push({
        key,
        severity,
        range: group?.range,
        time: Date.now()
      });
      this.incidentCacheDirty = true;
    }
  }

  resetExpanded() {
    this.expandedNodes = [];
  }

  updateAnimation() {
    const TIMEOUT = 2000;
    for (let i = 0; i < this.animationState.length; i += 1) {
      const animState = this.animationState[i];
      if (Date.now() - animState.time > TIMEOUT) {
        animState.end = true;
      }
    }
    this.animationState = this.animationState.filter(item => !item.end);
  }

  setActiveIncidentId(activeIncidentId) {
    this.activeIncidentId = activeIncidentId;
  }
}

export default IncidentTimeline;
