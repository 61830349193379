import React from 'react';
import * as moment from 'moment';

import numbersFormatting from 'helpers/numbersFormatting';
import LinesGraphTooltip from 'components/LinesGraphTooltip/LinesGraphTooltip';
import numbersRounding from 'helpers/numbersRounding';
import { isNumeric } from 'rxjs/util/isNumeric';

const renderTooltipContent = ({
  date,
  lines,
  formatMessage,
}) => {
  if (!date) {
    return null;
  }
  const pointDate = moment(date).format('MM-DD-YYYY');
  const generatedLines = lines.map((line) => {
    const pointIndex = line.points.findIndex(p => p.x === pointDate);
    const hideNullable = line?.hideNullableInTooltip && (pointIndex === -1 || !isNumeric(line.points[pointIndex]?.y));
    const hideByIndex = line.tooltipHidePointIndexes.includes(pointIndex);
    if (hideNullable || hideByIndex) {
      return null;
    }
    const point = line.points[pointIndex];
    const unitsText = line?.units ? formatMessage({ id: `cunits.mini.${line?.units}` }) : null;
    const valueText = numbersFormatting(numbersRounding(point?.y || 0, 'fixed', 1));
    const value = line?.units ? `${valueText} ${unitsText}` : valueText;
    return {
      id: line.id,
      value,
      header: line.name,
      color: line.color
    };
  });

  return (
    <LinesGraphTooltip
      lines={generatedLines.filter(line => line !== null)}
      tooltipDate={date}
      periodType='week'
    />
  );
};

export default renderTooltipContent;
