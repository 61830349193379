import { handleActions } from 'redux-actions';

import {
  requestGetUnrealizedPotential,
  receiveGetUnrealizedPotential,
  receiveGetUnrealizedPotentialArguments,
} from './actions';

const initialState = {
  potential: {},
  descriptor: {},
  supported: false,
  isUnrealizedPotentialFetching: false,
  isUnrealizedPotentialSuccess: false,
  modelSettings: [],
  warnings: {}
};

export default handleActions({
  [requestGetUnrealizedPotential](state) {
    return {
      ...state,
      isUnrealizedPotentialFetching: true,
    };
  },
  [receiveGetUnrealizedPotential](state, action) {
    if (!action?.payload) {
      return {
        ...state,
        isUnrealizedPotentialFetching: false,
        isUnrealizedPotentialSuccess: false,
        potential: {},
        supported: false,
      };
    }
    return {
      ...state,
      isUnrealizedPotentialFetching: false,
      isUnrealizedPotentialSuccess: true,
      potential: action?.payload?.potential,
      supported: action?.payload?.supported,
      descriptor: action?.payload?.descriptor,
      warnings: action?.payload?.potential?.warnings,
    };
  },
  [receiveGetUnrealizedPotentialArguments](state, action) {
    const modelSettings = action?.payload || [];
    return {
      ...state,
      modelSettings: [...modelSettings]
    };
  }
}, initialState);
