import React from 'react';
import ReactDOM from 'react-dom';

import ConditionalWrap from 'components/ConditionalWrap';

import 'url-search-params-polyfill';

import 'moment-timezone';
import 'moment/locale/en-gb';
import 'moment/locale/ru';
import 'moment/locale/fr';
import 'moment/locale/nl';
import 'moment/locale/zh-cn';

import { initAmplitude } from 'helpers/amplitude';

import AppContainer from './containers/AppContainer';
import createStore from './store/createStore';
import history from './history';

import * as serviceWorker from './serviceWorker';

import './styles/index.css';

// eslint-disable-next-line no-underscore-dangle
const initialState = window.___INITIAL_STATE__;
const store = createStore(initialState, history);

const USE_STRICT_MODE = true;

// Init amplitude analytics-browser
initAmplitude();

ReactDOM.render(
  <ConditionalWrap
    condition={USE_STRICT_MODE}
    wrap={children => <React.StrictMode>{children}</React.StrictMode>}
  >
    <AppContainer store={store} history={history} />
  </ConditionalWrap>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
