import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import classnames from 'classnames';

import BigButton from 'components/BigButton';
import SaveIcon from 'components/Icons/SaveIcon';
import NewIcon from 'components/Icons/NewIcon';
import FolderIcon from 'components/Icons/FolderIcon';
import SaveAsIcon from 'components/Icons/SaveAsIcon';
import RefreshIcon from 'components/Icons/RefreshIcon';

import styles from './DigitalTwinHeader.module.css';

const DigitalTwinHeader = ({
  intl,
  simulationName,
  simulationDesc,
  isEdited,
  isDataFetching,
  isNewSimulation,
  onSaveSimulationClick,
  onNewSimulationClick,
  onOpenSimulationsListClick,
  onSimulationNameClick,
  onSaveAsSimulationClick,
  onRevertClick,
}) => {
  const { formatMessage } = intl;

  const newSimulationAndEdited = isNewSimulation && isEdited;
  const savedSimulationAndEdited = !isNewSimulation && isEdited;

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.header}>
        {/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */}
        <h1
          className={styles.headerText}
          onClick={onSimulationNameClick}
          role='textbox'
          tabIndex={0}
        >
          <div className={styles.nameAndDesc}>
            <span className={styles.simulationNameText}>{simulationName}</span>
            {simulationDesc && <div className={styles.headerDesc}>{simulationDesc}</div>}
          </div>
          {savedSimulationAndEdited && <span className={styles.edited}>{formatMessage({ id: 'graphs.edited' })}</span>}
        </h1>
        {/* eslint-enable jsx-a11y/no-noninteractive-element-to-interactive-role */}
        <div className={styles.buttons}>
          {(newSimulationAndEdited || savedSimulationAndEdited) && (
            <BigButton
              onClick={onSaveSimulationClick}
              className={classnames(styles.button, styles.saveButton)}
              title={formatMessage({ id: 'button.save' })}
              icon={<SaveIcon />}
              size='large'
              bigIcon
              disabled={isDataFetching}
            />
          )}
          {savedSimulationAndEdited && (
            <>
              <BigButton
                onClick={onSaveAsSimulationClick}
                className={classnames(styles.button, styles.saveAsButton)}
                title={formatMessage({ id: 'graphs.saveAsPreset' })}
                icon={<SaveAsIcon />}
                size='large'
                bigIcon
                disabled={isDataFetching}
              />
              <BigButton
                onClick={onRevertClick}
                className={classnames(styles.button, styles.revertButton)}
                title={formatMessage({ id: 'graphs.revertPreset' })}
                icon={<RefreshIcon />}
                size='large'
                bigIcon
                disabled={isDataFetching}
              />
            </>
          )}
          {(newSimulationAndEdited || savedSimulationAndEdited) && <div className={styles.separator} />}
          <BigButton
            onClick={onNewSimulationClick}
            className={classnames(styles.button, styles.newButton)}
            title={formatMessage({ id: 'cropModel.new' })}
            icon={<NewIcon />}
            size='large'
            bigIcon
            disabled={isDataFetching}
          />
          <BigButton
            onClick={onOpenSimulationsListClick}
            className={classnames(styles.button, styles.openButton)}
            title={formatMessage({ id: 'cropModel.open' })}
            icon={<FolderIcon />}
            size='large'
            bigIcon
            disabled={isDataFetching}
          />
        </div>
      </div>
    </div>
  );
};

DigitalTwinHeader.propTypes = {
  intl: intlShape.isRequired,
  simulationName: PropTypes.string,
  simulationDesc: PropTypes.string,
  isEdited: PropTypes.bool,
  isDataFetching: PropTypes.bool,
  isNewSimulation: PropTypes.bool,
  onSaveSimulationClick: PropTypes.func.isRequired,
  onNewSimulationClick: PropTypes.func.isRequired,
  onOpenSimulationsListClick: PropTypes.func.isRequired,
  onSimulationNameClick: PropTypes.func.isRequired,
  onSaveAsSimulationClick: PropTypes.func.isRequired,
  onRevertClick: PropTypes.func.isRequired,
};

DigitalTwinHeader.defaultProps = {
  simulationName: null,
  simulationDesc: null,
  isEdited: false,
  isNewSimulation: false,
  isDataFetching: false,
};

export default injectIntl(DigitalTwinHeader);
