import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { NavLink } from 'react-router-dom';

import moment from 'moment-timezone';

import { find, uniqueId, isEqual } from 'lodash';
import queryString from 'query-string';

import getDateFormat from 'helpers/getDateFormat';

import DropdownMenu from 'components/DropdownMenu';
import BigButton from 'components/BigButton';
import CropsLink from 'components/CropsLink';
import Typography from 'components/Typography';
import CloseIcon from 'components/Icons/Close';

import CropCompareEmpty from '../CropCompareEmpty';
import CropCompareButton from '../CropCompareButton';

import styles from './CropCompare.module.css';

const getProducts = (locations, products, compartments, varieties, fruitClasses) => {
  const productsWithLabels = products.map((item) => {
    const currentCompartment = find(compartments, { id: item?.relationships?.compartment?.data[0]?.id });
    const currentLocation = find(locations, { id: currentCompartment?.relationships?.location?.data[0]?.id });

    return {
      ...item,

      plantingCycleId: item?.id,
      variety: find(varieties, { id: item?.relationships?.variety?.data[0]?.id }),
      fruitClass: find(fruitClasses, { id: item?.relationships?.fruitClass?.data[0]?.id }),
      targetWeight: item?.relationships?.targetWeight?.data[0],
      compartment: currentCompartment,
      location: {
        id: currentLocation?.id,
        name: currentLocation?.attributes?.name,
      }
    };
  });

  return productsWithLabels;
};

const getFormattedDate = date => moment(date).format(getDateFormat('lll'));

const getProductDates = (formatMessage, product) => {
  const startDate = product?.attributes?.startDate;
  const endDate = product?.attributes?.endDate;
  const dates = `${getFormattedDate(startDate)} – ${endDate ? getFormattedDate(endDate) : formatMessage({ id: 'cycle.present' })}`;

  const datesAndCompartmentText = `${dates}, ${product?.compartment?.attributes?.name}`;

  return datesAndCompartmentText;
};

const renderProductItems = (formatMessage, locations, isBenchmarkAvailable, withUpdateUrlParams, removeCycleFromComparison, productItems) => productItems.map(item => (
  <div className={styles.productItem} key={uniqueId('productItem-')}>
    <div>
      {isBenchmarkAvailable && (
        <Typography variant='body2' className={styles.locationName}>
          {item?.location?.name}
        </Typography>
      )}
      <div className={styles.productName}>
        <CropsLink product={item} directPath={false} organizations={locations} />
      </div>
      <div
        className={styles.dates}
      >
        {getProductDates(formatMessage, item)}
      </div>
    </div>
    <BigButton
      className={styles.deleteIconWrapper}
      onClick={() => removeCycleFromComparison({ cycleId: item.id, withSuccessAction: true, withUpdateUrlParams })}
      icon={<CloseIcon />}
      category='icon'
      tooltip={formatMessage({ id: 'benchmarking.removeFrom' })}
    />
  </div>
));

const renderContent = (formatMessage, organizationSlug, locations, withUpdateUrlParams, isBenchmarkAvailable, removeCycleFromComparison, setIsCloseDropdown, productsWithLabels, hrefParams) => (
  <>
    <div className={styles.products}>
      {renderProductItems(formatMessage, locations, isBenchmarkAvailable, withUpdateUrlParams, removeCycleFromComparison, productsWithLabels)}
    </div>
    <div className={styles.controls}>
      <BigButton
        className={styles.cancelButton}
        title={formatMessage({ id: 'dialog.cancel' })}
        type='button'
        onClick={() => setIsCloseDropdown(true)}
        theme='light'
      />
      <BigButton
        className={styles.submitButton}
        title={formatMessage({ id: 'benchmarking.compare' })}
        onClick={() => setIsCloseDropdown(true)}
        href={`/${organizationSlug}/crops/compare${hrefParams}`}
        theme='dark'
        // isLoading={isForecastFetching}
      />
    </div>
  </>
);

const CropCompare = ({
  intl,
  tabName,
  organizationSlug,
  locations,
  allPlantingCycles,
  compartments,
  varieties,
  fruitClasses,
  cyclesToCompare,
  cyclesToCompareFromRoute,
  removeCycleFromComparison,
  replaceAllCyclesInComparison,
  isBenchmarkAvailable,
  isCropCompareAvailable,
  isCropsComparePage,
}) => {
  useEffect(() => {
    if (isCropsComparePage) {
      const isCyclesEqual = isEqual(cyclesToCompare, cyclesToCompareFromRoute);
      if (!isCyclesEqual) {
        replaceAllCyclesInComparison({ cycleIdsList: cyclesToCompareFromRoute });
      }
    }
  }, [isCropsComparePage, cyclesToCompare, cyclesToCompareFromRoute, replaceAllCyclesInComparison]);

  const { formatMessage } = intl;

  // Чтобы при переходе на страницу сравнения появлялась кнопка назад в bench, если открываем из него
  const fromPage = tabName;
  const compareHrefParams = cyclesToCompare?.length > 0 ? cyclesToCompare.join('_') : undefined;
  const stringifiedParams = queryString.stringify({
    productId: compareHrefParams,
    fromPage,
  });
  const hrefParams = stringifiedParams ? `?${stringifiedParams}` : '';

  const [isCloseDropdown, setIsCloseDropdown] = useState(false);

  const productsToCompareCounter = cyclesToCompare?.length;
  const isHaveProductsToCompare = productsToCompareCounter > 0;

  const productsArray = cyclesToCompare.map(productId => find(allPlantingCycles, { id: Number(productId) }));
  const productsWithLabels = getProducts(locations, productsArray, compartments, varieties, fruitClasses);

  // Обновляем url при удалении только на странице сравнения
  const withUpdateUrlParams = isCropsComparePage;

  if (!isCropCompareAvailable) {
    return null;
  }

  return (
    <>
      <DropdownMenu
        buttonClassName={styles.cropCompareWrapper}
        contentClassName={styles.cropCompareContent}
        buttonElement={(
          <CropCompareButton
            counter={productsToCompareCounter}
            isVisible={isHaveProductsToCompare}
            setIsCloseDropdown={setIsCloseDropdown}
          />
        )}
        closeDropdown={isCloseDropdown}
      >
        {isHaveProductsToCompare ?
          renderContent(
            formatMessage,
            organizationSlug,
            locations,
            withUpdateUrlParams,
            isBenchmarkAvailable,
            removeCycleFromComparison,
            setIsCloseDropdown,
            productsWithLabels,
            hrefParams,
          )
          :
          (
            <CropCompareEmpty
              organizationSlug={organizationSlug}
              actionAfterLinkClick={() => setIsCloseDropdown(true)}
              hrefParams={hrefParams}
            />
          )}
      </DropdownMenu>

      {!isCropsComparePage && (
        <div className={styles.onMobile}>
          <NavLink className={styles.compareLink} to={`/${organizationSlug}/crops/compare${hrefParams}`}>
            <CropCompareButton
              counter={productsToCompareCounter}
              isVisible={isHaveProductsToCompare}
              setIsCloseDropdown={setIsCloseDropdown}
              size='big'
            />
          </NavLink>
        </div>
      )}
    </>
  );
};

CropCompare.propTypes = {
  intl: intlShape.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  allPlantingCycles: PropTypes.array,
  locations: PropTypes.array.isRequired,
  compartments: PropTypes.array.isRequired,
  varieties: PropTypes.array.isRequired,
  fruitClasses: PropTypes.array.isRequired,
  cyclesToCompare: PropTypes.array,
  cyclesToCompareFromRoute: PropTypes.array,
  tabName: PropTypes.string,
  isBenchmarkAvailable: PropTypes.bool,
  isCropCompareAvailable: PropTypes.bool,
  isCropsComparePage: PropTypes.bool,
  removeCycleFromComparison: PropTypes.func.isRequired,
  replaceAllCyclesInComparison: PropTypes.func.isRequired,
};

CropCompare.defaultProps = {
  allPlantingCycles: [],
  cyclesToCompare: [],
  cyclesToCompareFromRoute: [],
  tabName: undefined,
  isBenchmarkAvailable: false,
  isCropCompareAvailable: false,
  isCropsComparePage: false,
};


export default CropCompare;
