
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import moment from 'moment-timezone';
import classnames from 'classnames';
import { get } from 'lodash';

import numbersRounding from 'helpers/numbersRounding';
import getDateFormat from 'helpers/getDateFormat';

import CropsLink from 'components/CropsLink';

import TooltipMetric from '../TooltipMetric';

import styles from './TooltipContent.module.css';

const getFormattedDate = date => moment(date).format(getDateFormat('lll'));

const TooltipContent = ({
  intl, tooltipData, isShowLocationName, className
}) => {
  const { formatMessage, locale } = intl;
  const {
    color, viewBy, product, additionalMetrics
  } = tooltipData;

  const totalAmount = get(product, 'harvest.total.amount');
  const totalUnits = get(product, 'harvest.total.units');
  const valueTotal = numbersRounding(totalAmount, 'fixed', 1);
  const unitsTotal = totalUnits ? formatMessage({ id: `cunits.mini.${totalUnits}` }) : '';

  const weeklyAmount = get(product, 'harvest.weekly.amount');
  const weeklyUnits = get(product, 'harvest.weekly.units');
  const valueWeekly = numbersRounding(weeklyAmount, 'fixed', 1);
  const unitsWeekly = weeklyUnits ? formatMessage({ id: `cunits.mini.${weeklyUnits}` }) : '';

  const locationName = get(product, 'location.name');

  const startDate = get(product, 'startDate');
  const endDate = get(product, 'endDate');
  const dates = `${getFormattedDate(startDate)} – ${endDate ? getFormattedDate(endDate) : formatMessage({ id: 'cycle.present' })}`;

  const valueMetric = additionalMetrics ? numbersRounding(additionalMetrics.metricValue, 'fixed', 1) : null;
  const unitsMetric = additionalMetrics ? formatMessage({ id: `cunits.mini.${additionalMetrics.cunit}` }) : '';

  const avgWeeklyMetric = (isSecond = false) => (
    <TooltipMetric
      value={valueWeekly}
      units={unitsWeekly}
      text={formatMessage({ id: 'benchmarking.avgWeeklyHarvest' })}
      color={color}
      isActive={viewBy === 'weekly'}
      isSecond={isSecond}
    />
  );

  const totalMetric = (isSecond = false) => (
    <TooltipMetric
      value={valueTotal}
      units={unitsTotal}
      text={formatMessage({ id: 'benchmarking.totalHarvest' })}
      color={color}
      isActive={viewBy === 'total'}
      isSecond={isSecond}
    />
  );

  return (
    <div className={classnames(styles.tooltipContent, className)}>
      {isShowLocationName && (
        <div className={styles.locationName}>
          {locationName}
        </div>
      )}
      <div className={styles.productName}>
        <CropsLink product={product} />
      </div>
      <div className={styles.dates}>
        {dates}
      </div>
      <div className={styles.compartment}>
        {product?.compartment?.name}
      </div>
      <div className={styles.harvest}>
        {viewBy === 'weekly' ? (
          <>
            {avgWeeklyMetric()}
            {totalMetric(true)}
          </>
        )
          : (
            <>
              {totalMetric()}
              {avgWeeklyMetric(true)}
            </>
          )}
        {additionalMetrics && (
          <TooltipMetric
            value={valueMetric}
            units={unitsMetric}
            text={get(additionalMetrics, `name[${locale}]`) || get(additionalMetrics, 'name.en')}
            color={additionalMetrics.metricColor}
            isActive
          />
        )}
      </div>
    </div>
  );
};

TooltipContent.propTypes = {
  intl: intlShape.isRequired,
  tooltipData: PropTypes.object.isRequired,
  isShowLocationName: PropTypes.bool,
  className: PropTypes.string,
};

TooltipContent.defaultProps = {
  isShowLocationName: true,
  className: null,
};

export default memo(injectIntl(TooltipContent));

