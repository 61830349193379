import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import queryString from 'query-string';

import {
  getIsCropCompareAvailable,
} from 'store/company/selectors';

import {
  trackPageViewCropCyclesCompare
} from 'store/company/actions';

import {
  requestBenchmarkComparison,
  requestBenchmarkComparisonMetrics,
  removeCycleFromComparison,
  removeAllCyclesFromComparison,
  requestDeleteBenchmarkComparisonMetrics,
  requestUpdateBenchmarkComparisonMetrics,
} from 'store/benchmark/actions';

import {
  getCyclesToCompareFromRoute,
  getFilteredBenchmarkComparison,
  getBenchmarkComparisonMetrics,
  getIsBenchmarkComparisonFetching,
} from 'store/benchmark/selectors';


import BenchmarkCompare from '../components/BenchmarkCompare';

const mapDispatchToProps = {
  requestBenchmarkComparison,
  requestBenchmarkComparisonMetrics,
  removeCycleFromComparison,
  removeAllCyclesFromComparison,
  requestDeleteBenchmarkComparisonMetrics,
  requestUpdateBenchmarkComparisonMetrics,
  trackPageViewCropCyclesCompare,
};

const mapStateToProps = (state, props) => {
  const parsedQuery = queryString.parse(props?.location?.search);
  const fromPage = parsedQuery?.fromPage;

  return {
    ...props,

    isCropCompareAvailable: getIsCropCompareAvailable(state),
    comparison: getFilteredBenchmarkComparison(state),
    metrics: getBenchmarkComparisonMetrics(state),
    isBenchmarkComparisonFetching: getIsBenchmarkComparisonFetching(state, props),
    fromPage,

    cyclesToCompare: getCyclesToCompareFromRoute(state, props),
  };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(BenchmarkCompare)));
