import CanvasComponent from 'components/CanvasTimeline/components/CanvasComponent';
import ScrollBar from 'components/CanvasTimeline/components/ScrollBar';
import { addPoints } from 'components/CanvasTimeline/helpers/GeometryUtils';

class ScrollArea extends CanvasComponent {
  scrollArea = {
    width: 0,
    height: 0
  }

  scroll = {
    x: 0,
    y: 0
  }

  constructor({
    position = { x: 0, y: 0 },
    scrollOnDrag
  }) {
    super({
      position,
      drag: true
    });
    this.scrollOnDrag = scrollOnDrag;
    this.horizontalScroll = new ScrollBar({
      position: { x: position.x, y: 0 },
      type: ScrollBar.HORIZONTAL,
    });
    this.verticalScroll = new ScrollBar({
      position: {
        x: 0,
        y: position.y
      },
      type: ScrollBar.VERTICAL,
    });
    this.horizontalScroll.on('scroll', ({ abs }) => {
      const maxX = this.getMaxX();
      this.setScrollPosition({ ...this.scroll, x: -((Math.abs(maxX) / 100) * abs) });
      this.onScrollBars(this.scroll);
    });
    this.verticalScroll.on('scroll', ({ abs }) => {
      const maxY = this.getMaxY();
      this.setScrollPosition({ ...this.scroll, y: -((Math.abs(maxY) / 100) * abs) });
      this.onScrollBars(this.scroll);
    });
  }

  draw(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    super.draw(ctx, {
      // eslint-disable-next-line no-unused-vars
      translateAdd
    });
  }

  drawAfter(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  }) {
    this.horizontalScroll.draw(ctx);
    this.verticalScroll.draw(ctx);
  }

  // eslint-disable-next-line no-unused-vars
  onScroll({ x, y }) {

  }

  onScrollBars({ x, y }) {
    this.setScrollPosition({ x, y });
  }

  onMouseWheel({ deltaX, deltaY }) {
    super.onMouseWheel({ deltaX, deltaY });
    const scroll = addPoints(this.scroll, {
      y: deltaY * -0.3, x: -deltaX
    });
    this.setScrollPosition(scroll);
    this.updateScroll();
    this.updateChildrenPosition();
  }

  setScrollSize({ width, height }) {
    this.scrollArea = {
      width,
      height
    };
    this.updateScroll();
    this.updateChildrenPosition();
  }

  updateViewport(viewport) {
    this.viewport = viewport;
  }

  updateScroll() {
    const position = this.getPositionAbs();
    const elSize = this.getSize();
    this.horizontalScroll.setMaxValue(this.scrollArea.width);
    this.horizontalScroll.setViewSize(elSize.width);
    this.horizontalScroll.setPosition({
      x: position.x,
      y: position.y + elSize.height - this.horizontalScroll.thickness,
    });
    this.verticalScroll.setMaxValue(this.scrollArea.height);
    this.verticalScroll.setViewSize(elSize.height);
    this.verticalScroll.setPosition({
      x: position.x + elSize.width - this.verticalScroll.thickness,
      y: position.y,
    });
  }

  setScrollPosition({ x, y }) {
    this.scroll = this.normalizePosition({ x, y });

    const maxX = this.getMaxX();
    this.horizontalScroll.setScrollPercent((Math.abs(this.scroll.x) / Math.abs(maxX)) * 100);
    const maxY = -this.getMaxY();
    this.verticalScroll.setScrollPercent((Math.abs(this.scroll.y) / Math.abs(maxY)) * 100);

    this.updateChildrenPosition();
    this.onScroll({
      ...this.scroll
    });
    this.emit('scroll', {
      ...this.scroll
    });
  }

  getMaxX() {
    const elSize = this.getSize();
    return this.scrollArea.width > elSize.width ? Math.abs(this.scrollArea.width - elSize.width) : 0;
  }

  getMaxY() {
    const elSize = this.getSize();
    return this.scrollArea.height > elSize.height ? Math.abs(this.scrollArea.height - elSize.height) : 0;
  }

  normalizePosition({ x, y }) {
    let nX = x;
    let nY = y;
    if (nX > 0) {
      nX = 0;
    }
    if (nY > 0) {
      nY = 0;
    }

    const maxX = this.getMaxX();
    if (nX < -maxX) {
      nX = -maxX;
    }

    const maxY = -this.getMaxY();

    if (nY < maxY) {
      nY = maxY;
    }

    return {
      x: nX,
      y: nY
    };
  }

  onDragDelta({
    x, y, dx, dy
  }) {
    super.onDragDelta({
      x, y, dx, dy
    });
    if (this.scrollOnDrag) {
      this.setScrollPosition(addPoints(this.scroll, { x: dx, y: dy }));
    }
  }

  updateChildrenPosition() {
    for (let i = 0; i < this.children.length; i += 1) {
      const child = this.children[i];
      child.setPosition(addPoints(child.basePosition, this.scroll));
    }
  }

  pointerDownEvent({ x, y }) {
    let capture = this.horizontalScroll.pointerDownEvent({ x, y });
    // eslint-disable-next-line no-bitwise
    capture |= this.verticalScroll.pointerDownEvent({ x, y });
    if (capture) {
      return true;
    }
    return super.pointerDownEvent({ x, y });
  }

  pointerUpEvent({ x, y }) {
    let capture = this.horizontalScroll.pointerUpEvent({ x, y });
    // eslint-disable-next-line no-bitwise
    capture |= this.verticalScroll.pointerUpEvent({ x, y });
    if (capture) {
      return true;
    }
    return super.pointerUpEvent({ x, y });
  }

  pointerMove({ x, y }) {
    let capture = this.horizontalScroll.pointerMove({ x, y });
    // eslint-disable-next-line no-bitwise
    capture |= this.verticalScroll.pointerMove({ x, y });
    if (capture) {
      return true;
    }
    return super.pointerMove({ x, y });
  }

  pickNode({ x, y }) {
    if (this.horizontalScroll.intersect({ x, y }) || this.horizontalScroll.isActiveScroll()) {
      return this.horizontalScroll;
    }
    if (this.verticalScroll.intersect({ x, y }) || this.verticalScroll.isActiveScroll()) {
      return this.verticalScroll;
    }
    return super.pickNode({ x, y });
  }
}

export default ScrollArea;
