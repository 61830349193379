import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import FileSaver from 'file-saver';

import initAxiosInstanse from 'api/axios';
import { get } from 'lodash';

import { showNotificationWithTimeout, showNotification, hideNotification } from '../notificationCenter/actions';

import {
  UPDATE_SELECTED_METRIC_ID,
  REQUEST_DRILL_DOWN,
  receiveDrillDown,
  trackSelectClimateGraphMetric,
} from './actions';

export const updateSelectedMetricIdEpic = action$ =>
  action$
    .ofType(UPDATE_SELECTED_METRIC_ID)
    .map(({ id, name }) => trackSelectClimateGraphMetric(id, name));

export const showDrillDownNotificationEpic = action$ =>
  action$
    .ofType(REQUEST_DRILL_DOWN)
    .mergeMap(() => Observable.of(
      showNotification({
        id: 'notifications.downloadDrillDown',
        messageId: 'notifications.downloadDrillDown',
        iconType: 'loading',
        notificationType: 'withAction',
      })
    ));

export const downloadDrillDownEpic = (action$, store) =>
  action$
    .ofType(REQUEST_DRILL_DOWN)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();

      const searchParams = router?.location?.search;

      const {
        payload: {
          periodStart,
          periodLength,
          plantingCycleId,
          metricId,
          actionAfterSuccess = () => {},
        }
      } = actionData;

      return Observable
        .from(
          initAxiosInstanse(
            searchParams,
            120000,
            {
              credentials: 'include',
              responseType: 'blob',
            }
          )
            .get(`/planting-cycles/${plantingCycleId}/table-cell-drilldown?metricId=${metricId}&anyDateOfPeriod=${periodStart}&periodLength=${periodLength}`)
        )
        .mergeMap((response) => {
          const fileName = get(response, 'headers[x-filename]');

          FileSaver.saveAs(response.data, fileName);

          return Observable.of(
            actionAfterSuccess,
            hideNotification('notifications.downloadDrillDown'),
            receiveDrillDown(),
            showNotificationWithTimeout({
              id: 'notifications.drillDownDownloaded',
              messageId: 'notifications.drillDownDownloaded',
              iconType: 'success',
              notificationType: 'withAction',
            })
          );
        })
        .catch(() => Observable.of(
          hideNotification('notifications.downloadDrillDown'),
          receiveDrillDown(),
          showNotificationWithTimeout({
            id: `notifications.backendError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export default combineEpics(
  updateSelectedMetricIdEpic,
  showDrillDownNotificationEpic,
  downloadDrillDownEpic,
);
