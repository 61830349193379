import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getSortedUserPrivileges,
} from 'store/user/selectors';

import {
  getOrganizationSlug,
} from 'store/company/selectors';

import {
  getIncidentTypes,
  getIncidentTypeCategories,
} from 'store/incidents/selectors';

import {
  requestCreateAlertRule,
  requestUpdateAlertRule,
  requestDeleteAlertRule,
} from 'store/incidents/actions';

import AlertPanel from './components/AlertPanel';

const mapDispatchToProps = {
  requestCreateAlertRule,
  requestUpdateAlertRule,
  requestDeleteAlertRule,
};

const mapStateToProps = (state, props) => ({
  ...props,

  organizationSlug: getOrganizationSlug(state, props),
  incidentTypes: getIncidentTypes(state, props),
  incidentTypeCategories: getIncidentTypeCategories(state, props),
  userPrivileges: getSortedUserPrivileges(state, props),
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(AlertPanel)));
