// We only need to import the modules necessary for initial render
import 'whatwg-fetch';

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import OrganizationRoutes from './OrganizationRoutes';
import LoginRoutes from './LoginRoutes';


export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route
          key='login'
          path='/login'
          component={LoginRoutes}
        />
        <Route
          key='organization'
          path='*'
          exact
          component={OrganizationRoutes}
        />
      </Switch>
    );
  }
}

