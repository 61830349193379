import { SET_MENU_STATE } from './actions';

const initialState = {
  isNavigationMenuOpened: false,
};

export default function navigationMenuReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MENU_STATE:
      return { ...state, isNavigationMenuOpened: action.payload };
    default:
      return state;
  }
}
