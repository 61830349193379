import moment from 'moment-timezone';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import getDatePeriodRange from '../../../../helpers/getDatePeriodRange';

import { ReactComponent as CloseIcon } from './assets/close.svg';
import styles from './CustomDatePickerInput.module.css';

class CustomDatePickerInput extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    onClick: PropTypes.func,
    onInnerClick: PropTypes.func,
    onClear: PropTypes.func,
    periodType: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    dateFormat: PropTypes.string,
    icon: PropTypes.object,
    container: PropTypes.object,
    wrapperClassName: PropTypes.string,
    disabled: PropTypes.bool,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    theme: PropTypes.string,
    clearable: PropTypes.bool,
    isErrorTextDisable: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
  };

  static defaultProps = {
    container: {},
    onClick: null,
    dateFormat: null,
    onInnerClick: null,
    onClear: null,
    periodType: null,
    value: null,
    placeholder: '',
    wrapperClassName: null,
    className: null,
    icon: null,
    disabled: false,
    error: '',
    minDate: null,
    maxDate: null,
    theme: null,
    clearable: true,
    isErrorTextDisable: false,
  };

  handlerClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { onClick, onInnerClick, disabled } = this.props;

    if (onClick && !disabled) {
      onClick(e);
    }

    if (onInnerClick && !disabled) {
      onInnerClick(e);
    }
  };

  handlerClear = (e) => {
    const { onClear, disabled } = this.props;

    if (onClear && !disabled) {
      onClear(e);
    }

    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const {
      intl,
      icon,
      value,
      error,
      placeholder,
      className,
      wrapperClassName,
      disabled,
      container,
      periodType,
      minDate,
      maxDate,
      dateFormat,
      theme,
      clearable,
      isErrorTextDisable,
    } = this.props;

    const { formatMessage } = intl;

    const isError = !!error;
    const isOpened = container.datepicker ? container.datepicker.state.open : false;
    let textValue = value;

    if (periodType && periodType !== 'day' && value) {
      const date = moment(value, dateFormat);

      const { start: startOfPeriod, end: endOfPeriod } = getDatePeriodRange({
        date,
        periodType,
        minDate,
        maxDate,
        formatMessage,
      });

      textValue = `${startOfPeriod} – ${endOfPeriod}`;
    }

    const clearButton = (
      <button type='button' className={styles.clear} onClick={this.handlerClear}>
        <CloseIcon className={styles.clearIcon} />
      </button>
    );

    return (
      <div>
        <div
          className={
            classnames(
              styles.button,
              wrapperClassName,
              {
                [styles.disabled]: disabled,
                [styles.focus]: isOpened,
                [styles.errored]: !isErrorTextDisable && !!error,
                [styles.invalid]: isError,
                [styles.mini]: theme === 'mini',
                invalid: isError,
              }
            )
          }
          onClick={this.handlerClick}
          role='button'
          tabIndex={0}
        >
          <div
            className={classnames(
              styles.input,
              className,
              {
                [styles.invalid]: isError,
                invalid: isError,
              }
            )}
          >
            {textValue}
          </div>
          {value && clearable && !disabled ? clearButton : null}
          {!value || !clearable ? (
            <div className={styles.icon}>
              {icon}
            </div>
          ) : null}
          <div
            className={classnames(
              styles.placeholder,
              {
                [styles.top]: isOpened || !!value,
                [styles.invalid]: isError,
                invalid: isError,
              }
            )}
          >
            {placeholder}
          </div>
        </div>
        {!isErrorTextDisable && error ? (
          <div className={styles.error}>
            {error}
          </div>
        ) : null}
      </div>
    );
  }
}
export default injectIntl(CustomDatePickerInput);
