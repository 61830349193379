import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CircleLoader from '../CircleLoader';
import Table from '../Table';
import styles from './Split.module.css';


export default class Split extends Component {
  static propTypes = {
    isLeftWeeklyDataFetching: PropTypes.bool.isRequired,
    isRightWeeklyDataFetching: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    leftWeeklyData: PropTypes.array,
    rightWeeklyData: PropTypes.array,
    leftMetrics: PropTypes.array,
    rightMetrics: PropTypes.array,
    rightCompartment: PropTypes.object,
    leftCompartment: PropTypes.object,
    rightPlantingCycle: PropTypes.object,
    leftPlantingCycle: PropTypes.object,
    getLeftDailyData: PropTypes.func.isRequired,
    getRightDailyData: PropTypes.func.isRequired,
    onTableCellEdit: PropTypes.func,
    showNotification: PropTypes.func.isRequired,
    hideNotification: PropTypes.func.isRequired,
    showNotificationWithTimeout: PropTypes.func.isRequired,
    updateSelectedMetricId: PropTypes.func.isRequired,
    trackClickClimateTableWeek: PropTypes.func.isRequired,
    requestDrillDown: PropTypes.func.isRequired,
    selectedMetricId: PropTypes.number,
    isDrillDownFetching: PropTypes.bool,
    innerComponent: PropTypes.object,
    tableWrapperClassName: PropTypes.string
  };

  static defaultProps = {
    leftMetrics: [],
    rightMetrics: [],
    leftWeeklyData: [],
    rightWeeklyData: [],
    rightCompartment: null,
    leftCompartment: null,
    rightPlantingCycle: null,
    leftPlantingCycle: null,
    onTableCellEdit: null,
    selectedMetricId: null,
    isDrillDownFetching: false,
    innerComponent: null,
    tableWrapperClassName: null
  };

  handlerTableCellEdit = (options) => {
    const { onTableCellEdit } = this.props;

    if (onTableCellEdit) {
      onTableCellEdit(options);
    }
  };

  renderWeeklyData({ mode = 'left' }) {
    const {
      leftPlantingCycle,
      leftCompartment,
      leftWeeklyData,
      leftMetrics,
      rightPlantingCycle,
      rightCompartment,
      rightWeeklyData,
      rightMetrics,
      getLeftDailyData,
      getRightDailyData,
      isLeftWeeklyDataFetching,
      isRightWeeklyDataFetching,
      location,
      showNotification,
      hideNotification,
      showNotificationWithTimeout,
      updateSelectedMetricId,
      selectedMetricId,
      trackClickClimateTableWeek,
      requestDrillDown,
      isDrillDownFetching,
      tableWrapperClassName
    } = this.props;

    const plantingCycle = mode === 'left' ? leftPlantingCycle : rightPlantingCycle;
    const compartment = mode === 'left' ? leftCompartment : rightCompartment;
    const weeklyData = mode === 'left' ? leftWeeklyData : rightWeeklyData;
    const metrics = mode === 'left' ? leftMetrics : rightMetrics;

    const tableProps = {
      isFetching: mode === 'left' ? isLeftWeeklyDataFetching : isRightWeeklyDataFetching,
      isColumnFetching: false,
      onTableCellEdit: this.handlerTableCellEdit,
      mode,
      location,
      metrics,
      weeklyData,
      plantingCycle,
      compartment,
      getLeftDailyData,
      getRightDailyData,
      canShowIndicatorsColumn: mode === 'left',
      className: mode === 'left' ? styles.tableLeft : styles.tableRight,
      showNotification,
      hideNotification,
      selectedMetricId,
      showNotificationWithTimeout,
      trackClickClimateTableWeek,
      updateSelectedMetricId: mode === 'left' ? updateSelectedMetricId : null,
      requestDrillDown,
      isDrillDownFetching,
      tableWrapperClassName
    };

    if (plantingCycle && Array.isArray(weeklyData) && metrics && metrics.length) {
      return (
        <>
          <Table {...tableProps} />
          <Table {...tableProps} onlyFirstColumn />
        </>
      );
    }

    return null;
  }

  render() {
    const {
      leftPlantingCycle,
      rightPlantingCycle,
      isRightWeeklyDataFetching,
      isLeftWeeklyDataFetching,
      isFetching,
      innerComponent
    } = this.props;

    const renderedLeft = this.renderWeeklyData({ mode: 'left' });
    const renderedRight = this.renderWeeklyData({ mode: 'right' });
    const showLoader = isFetching || isLeftWeeklyDataFetching || isRightWeeklyDataFetching;

    return (
      <div className={styles.split}>
        {innerComponent}
        {leftPlantingCycle && (
          <div className={classnames(styles.splitLeft, { [styles.splitLeftFull]: !renderedRight })}>
            {renderedLeft}
          </div>
        )}
        {rightPlantingCycle && (
          <div className={styles.splitRight}>
            {renderedRight}
          </div>
        )}
        {showLoader && (
          <div className={styles.tableLoader}>
            <CircleLoader className={styles.circleLoader} iconClassName={classnames(styles.circleLoaderIcon)} />
          </div>
        )}
      </div>
    );
  }
}
