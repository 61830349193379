import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

function paramsToObject(entries: any) {
  const result: any = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const entry of entries) {
    const [key, value] = entry;
    if (result[key]) {
      if (Array.isArray(result[key])) {
        result[key] = [...result[key], value];
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
    }
  }
  return result;
}

function useUrlNavHandler(watchList: Array<string> = [], callback: Function) {
  const [queryState, setQueryState] = useState<any>({});
  const history = useHistory();

  useEffect(() => {
    setQueryState(paramsToObject(
      new URLSearchParams(history.location.search).entries()
    ));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newQueryState = paramsToObject(
      new URLSearchParams(history.location.search).entries()
    );
    if (callback && history.action === 'POP') {
      if (watchList.length < 0) {
        callback(queryState, newQueryState);
      } else {
        const diff: any = {};
        const prevKeys = Object.keys(queryState);
        for (let i = 0; i < prevKeys.length; i += 1) {
          const key = prevKeys[i];
          if (queryState[key] !== newQueryState[key]) {
            diff[key] = newQueryState[key];
          }
        }
        const newKeys = Object.keys(newQueryState);
        for (let i = 0; i < newKeys.length; i += 1) {
          const key = newKeys[i];
          if (queryState[key] !== newQueryState[key] && !diff[key]) {
            diff[key] = newQueryState[key];
          }
        }
        let changed = false;
        const diffKeys = Object.keys(diff);
        for (let i = 0; i < diffKeys.length; i += 1) {
          if (watchList.includes(diffKeys[i])) {
            changed = true;
            break;
          }
        }
        if (changed) {
          callback(queryState, newQueryState);
        }
      }
    }
    setQueryState(newQueryState);
    // eslint-disable-next-line
  }, [history.location]);
}

export default useUrlNavHandler;
