import { handleActions } from 'redux-actions';

import {
  setHarvestPlanGrid,
  setTableScrollPosition,
  resetPlansEditorData,

  requestAllPlanVersions,
  receiveAllPlanVersions,

  requestPlanVersionById,
  receivePlanVersionById,

  requestPublishPlanById,
  receivePublishPlanById,
  setVersionsFilter,

  requestDefaultPlanVersion,
  receiveDefaultPlanVersion,

  requestSavePlan,
  requestSavePlanById,
  receiveSavePlanById,

  requestRestorePlan,
  requestRestorePlanById,
  receiveRestorePlanById,

  requestSaveAndPublishPlan,
  requestPublishPlan,
  receivePublishPlan,

  requestPlanVersionSummary,
  receivePlanVersionSummary,

  requestSaveVersionInfo,
  receiveSaveVersionInfo,

  requestDeleteVersionInfo,
  receiveDeleteVersionInfo,
} from './actions';

const initialState = {
  isPlansEditorFetching: false,
  isVarietyRemoving: false,
  isVarietyChanging: false,
  isPlansEditorSaving: false,
  isPlanVersionSummaryFetching: false,
  isPlanVersionsFetching: false,

  harvestPlan: {},
  harvestPlanEdited: undefined,
  withoutSaveDate: undefined,
  tableScroll: null,

  allPlanVersions: [],
  versionsFilter: 'all',

  harvestPlanSummary: null,

  isVersionInfoFetching: false,
  isDeleteVersionInfoFetching: false,
};

export default handleActions({
  [setHarvestPlanGrid](state, action) {
    return {
      ...state,
      harvestPlanEdited: action.payload.harvestPlanEdited,
      withoutSaveDate: action.payload.withoutSaveDate,
    };
  },
  [setTableScrollPosition](state, action) {
    return {
      ...state,
      tableScroll: action.payload.tableScroll,
    };
  },
  [resetPlansEditorData](state) {
    return {
      ...state,
      ...initialState,
    };
  },
  [requestAllPlanVersions](state) {
    return {
      ...state,
      isPlanVersionsFetching: true,
    };
  },
  [receiveAllPlanVersions](state, action) {
    return {
      ...state,
      isPlanVersionsFetching: false,
      allPlanVersions: action.payload.allPlanVersions,
    };
  },
  [requestPlanVersionById](state) {
    return {
      ...state,
      isPlansEditorFetching: true,
    };
  },
  [receivePlanVersionById](state, action) {
    return {
      ...state,
      isPlansEditorFetching: false,
      harvestPlan: action.payload?.harvestPlan || {},
      harvestPlanEdited: action.payload?.harvestPlanEdited,
      harvestPlanSummary: null,
      withoutSaveDate: undefined,
    };
  },
  [requestDefaultPlanVersion](state) {
    return {
      ...state,
      isPlansEditorFetching: true,
    };
  },
  [receiveDefaultPlanVersion](state, action) {
    return {
      ...state,
      isPlansEditorFetching: false,
      harvestPlan: action.payload.harvestPlan || {},
      harvestPlanEdited: action.payload.harvestPlanEdited,
      harvestPlanSummary: null,
      withoutSaveDate: action.payload.withoutSaveDate || null,
    };
  },
  [requestPublishPlanById](state) {
    return {
      ...state,
      isPlansEditorFetching: true,
      isPlansEditorSaving: true,
    };
  },
  [receivePublishPlanById](state, action) {
    return {
      ...state,
      isPlansEditorFetching: false,
      isPlansEditorSaving: false,
      harvestPlan: action.payload.harvestPlan || {},
      harvestPlanEdited: action.payload.harvestPlanEdited,
    };
  },
  [requestRestorePlanById](state) {
    return {
      ...state,
      isPlansEditorFetching: true,
      isPlansEditorSaving: true,
    };
  },
  [requestRestorePlan](state) {
    return {
      ...state,
      isPlansEditorFetching: true,
      isPlansEditorSaving: true,
    };
  },
  [receiveRestorePlanById](state, action) {
    return {
      ...state,
      isPlansEditorFetching: false,
      isPlansEditorSaving: false,
      harvestPlan: action.payload.harvestPlan || {},
      harvestPlanEdited: action.payload.harvestPlanEdited,
    };
  },
  [requestSavePlan](state) {
    return {
      ...state,
      isPlansEditorFetching: true,
      isPlansEditorSaving: true,
    };
  },
  [requestSavePlanById](state) {
    return {
      ...state,
      isPlansEditorFetching: true,
      isPlansEditorSaving: true,
    };
  },
  [receiveSavePlanById](state, action) {
    return {
      ...state,
      isPlansEditorFetching: false,
      isPlansEditorSaving: false,
      withoutSaveDate: action.payload.withoutSaveDate,

      harvestPlan: action.payload.harvestPlan || {},
      harvestPlanEdited: action.payload.harvestPlanEdited,
    };
  },
  [setVersionsFilter](state, action) {
    return {
      ...state,
      versionsFilter: action.payload.versionsFilter,
    };
  },
  [requestPlanVersionSummary](state) {
    return {
      ...state,
      isPlanVersionSummaryFetching: true,
    };
  },
  [requestSaveAndPublishPlan](state) {
    return {
      ...state,
      isPlansEditorFetching: true,
      isPlansEditorSaving: true,
    };
  },
  [requestPublishPlan](state) {
    return {
      ...state,
      isPlansEditorFetching: true,
      isPlansEditorSaving: true,
    };
  },
  [receivePublishPlan](state, action) {
    return {
      ...state,
      isPlansEditorFetching: false,
      isPlansEditorSaving: false,
      withoutSaveDate: action.payload.withoutSaveDate,
    };
  },
  [receivePlanVersionSummary](state, action) {
    return {
      ...state,
      isPlanVersionSummaryFetching: false,
      harvestPlanSummary: action.payload.harvestPlanSummary,
    };
  },
  [requestSaveVersionInfo](state) {
    return {
      ...state,
      isVersionInfoFetching: true,
    };
  },
  [receiveSaveVersionInfo](state) {
    return {
      ...state,
      isVersionInfoFetching: false,
    };
  },
  [requestDeleteVersionInfo](state) {
    return {
      ...state,
      isDeleteVersionInfoFetching: true,
    };
  },
  [receiveDeleteVersionInfo](state) {
    return {
      ...state,
      isDeleteVersionInfoFetching: false,
    };
  },
}, initialState);
