import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Truncate from 'react-truncate';

import cn from 'classnames';

import Typography from 'components/Typography';
import BigSwitch from 'components/BigSwitch';
import DefaultSimpleTooltip from 'components/DefaultSimpleTooltip';

import ArrowNextIcon from 'components/Icons/ArrowNextIcon';
import BellIcon from 'components/Icons/BellIcon';

import styles from './ListItemWithSwitch.module.css';

const ListItemWithSwitch = ({
  id,
  enabled,
  href,
  text,
  additionalInfo,
  enabledTooltipText,
  disabledTooltipText,
  onClick,
  onUpdateSwitch,
  withIcon,
  activeId,
}) => {
  const [switchEnabled, setSwitchEnabled] = useState(enabled);
  const [truncated, setTruncated] = useState(false);

  const handlerTruncate = useCallback((isTruncated) => {
    if (truncated !== isTruncated) {
      setTruncated(isTruncated);
    }
  }, [truncated]);

  const fullText = truncated ? text : undefined;

  const handlerSwitchClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    const newSwitchState = !switchEnabled;
    setSwitchEnabled(newSwitchState);

    onUpdateSwitch({
      id,
      enabled: newSwitchState,
      actionAfterFail: () => {
        setSwitchEnabled(!newSwitchState);
      },
    });
  }, [setSwitchEnabled, switchEnabled, onUpdateSwitch, id]);

  const handlerItemClick = useCallback(() => {
    if (onClick) {
      onClick({
        id,
        enabled,
      });
    }
  }, [onClick, id, enabled]);

  const Container = href ? NavLink : 'div';

  const tooltipText = switchEnabled ? enabledTooltipText : disabledTooltipText;

  return (
    <div className={styles.group}>
      <Container
        className={cn(styles.groupContainer, {
          [styles.withAdditionalInfo]: additionalInfo,
          [styles.isActive]: activeId === id,
        })}
        to={href}
        onClick={handlerItemClick}
      >
        <div className={styles.firstColumn}>
          <div className={styles.groupName}>
            {withIcon ? (
              <div className={styles.textWrapper}>
                <Typography variant='body1' className={styles.name}>
                  {text}
                </Typography>
                <div className={styles.iconBellWrapper}>
                  <BellIcon />
                </div>
              </div>
            )
          : (
            <DefaultSimpleTooltip text={fullText}>
              <Typography variant='body1' className={styles.name}>
                <Truncate lines={2} onTruncate={handlerTruncate}>
                  {text}
                </Truncate>
              </Typography>
            </DefaultSimpleTooltip>
          )}
          </div>
          {additionalInfo && (
            <div className={styles.additionalInfo}>
              {additionalInfo}
            </div>
          )}
        </div>
        <div className={styles.navigationElements}>
          <BigSwitch
            onClick={e => handlerSwitchClick(e)}
            value={switchEnabled}
            tooltip={enabledTooltipText && disabledTooltipText ? tooltipText : undefined}
          />
          <div className={styles.arrowRightIconWrapper}>
            <ArrowNextIcon className={styles.arrowRightIcon} />
          </div>
        </div>
      </Container>
    </div>
  );
};

ListItemWithSwitch.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  enabled: PropTypes.bool.isRequired,
  href: PropTypes.string,
  text: PropTypes.string.isRequired,
  onUpdateSwitch: PropTypes.func.isRequired,
  additionalInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  enabledTooltipText: PropTypes.string,
  disabledTooltipText: PropTypes.string,
  onClick: PropTypes.func,
  withIcon: PropTypes.bool,
  activeId: PropTypes.number,
};

ListItemWithSwitch.defaultProps = {
  href: undefined,
  additionalInfo: undefined,
  enabledTooltipText: undefined,
  disabledTooltipText: undefined,
  onClick: undefined,
  withIcon: false,
  activeId: undefined,
};

export default memo(ListItemWithSwitch);
