import moment from 'moment-timezone';

/**
 * Возвращает массив заданного количества лет до переданного года
 *
 * @param {string || number} year - Год (например 2019).
 * @param {number} yearsCount - Количество лет до, которое надо получить.
 * @return {string[]} Массив лет
 */
export const getYearsBefore = (year, yearsCount) => {
  if (!yearsCount) {
    return [year.toString()];
  }

  const back = yearsCount + 1;
  const years = Array.from({ length: back }, (v, i) => ((Number(year) - back) + i + 1));

  return years.map(item => item.toString());
};

/**
 * Возвращает массив лет по стартовой и конечной дате
 *
 * @param {string} startDate - Начальная дата в формате YYYY-MM-DD
 * @param {number} endDate - Конечная дата в формате YYYY-MM-DD
 * @return {string[]} Массив лет
 */
export const getYearsByDates = (startDate, endDate) => {
  const startYear = Number(moment(startDate).format('YYYY'));
  const endYear = Number(moment(endDate).format('YYYY'));

  const yearsCount = endYear - startYear;

  return getYearsBefore(endYear, yearsCount);
};
