import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import {
  reduxForm, Field, touch
} from 'redux-form';

import classnames from 'classnames';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { API_DATE_FORMAT } from 'helpers/defaultDates';

import BigButton from 'components/BigButton';
import Typography from 'components/Typography';
import DefaultTextInput from 'components/DefaultTextInput';
import CropToggleButton from 'components/CropToggleButton';
import CustomDatePickerInput from 'components/CustomDatePickerInput';

import styles from './HarvestForecastCreateForm.module.css';

const FORM_NAME = 'forecastCreateOrEditForm';

const validate = (values, props) => {
  const { intl: { formatMessage } } = props;
  const errors = {};

  if (!values.forecastName) {
    errors.forecastName = formatMessage({ id: 'formErrors.requiredField' });
  }

  if (!values.startDate) {
    errors.startDate = formatMessage({ id: 'formErrors.requiredField' });
  }

  if (!values.endDate) {
    errors.endDate = formatMessage({ id: 'formErrors.requiredField' });
  }

  if (values.startDate && values.endDate) {
    const daysDiff = values.endDate?.diff(moment(values.startDate), 'days');

    if (daysDiff > 31) {
      errors.endDate = formatMessage({ id: 'forecast.createForecastDateRangeError' });
    }

    if (daysDiff < 0) {
      errors.endDate = formatMessage({ id: 'forecast.createForecastEndDateError' });
    }
  }

  return errors;
};

const actionAfterSuccess = (history, organizationSlug, forecastId) => history.push(`/${organizationSlug}/crops/forecasts/${forecastId}/report`);

const renderVersionNameInput = ({
  input,
  meta: { touched, error, active },
  ...rest
}) => (
  <DefaultTextInput
    {...input}
    {...rest}
    className={styles.forecastNameInput}
    error={touched && !active && error ? error : null}
  />
);

const handlerSubmitForm = (e, dispatch, history, organizationSlug, forecastId, editMode, requestUpdateForecast, requestCreateForecast, selectedCrop, formValues, formSyncErrors) => {
  e.preventDefault();

  dispatch(touch(FORM_NAME, 'forecastName'));
  dispatch(touch(FORM_NAME, 'startDate'));
  dispatch(touch(FORM_NAME, 'endDate'));

  if (!formValues || !isEmpty(formSyncErrors)) {
    return null;
  }

  if (editMode) {
    return requestUpdateForecast({
      id: forecastId,
      name: formValues?.forecastName,
      start: formValues.startDate.format(API_DATE_FORMAT),
      endInclusive: formValues.endDate.format(API_DATE_FORMAT),
      actionAfterSuccess: () => actionAfterSuccess(history, organizationSlug, forecastId),
    });
  }

  return requestCreateForecast({
    species: selectedCrop,
    name: formValues?.forecastName,
    start: formValues.startDate.format(API_DATE_FORMAT),
    endInclusive: formValues.endDate.format(API_DATE_FORMAT),
    actionAfterSuccess: newForecastId => actionAfterSuccess(history, organizationSlug, newForecastId),
  });
};

const HarvestForecastCreateForm = ({
  intl,
  history,
  match,
  formValues,
  formSyncErrors,
  editMode,
  forecastId,
  species,
  isForecastFetching,
  requestUpdateForecast,
  requestCreateForecast,
  dispatch,
  speciesList,
}) => {
  const [selectedCrop, setSelectedCrop] = useState(species);
  const { formatMessage } = intl;
  const { params: { organizationSlug } } = match;

  return (
    <form
      className={styles.form}
      onSubmit={e => handlerSubmitForm(e, dispatch, history, organizationSlug, forecastId, editMode, requestUpdateForecast, requestCreateForecast, selectedCrop, formValues, formSyncErrors)}
    >
      <div className={styles.inputBlock}>
        <Typography variant='h3' className={styles.inputTitle}>
          {formatMessage({ id: 'forecast.whatName' })}
        </Typography>
        <Typography variant='subtitle3' className={styles.inputSubtitle}>
          {formatMessage({ id: 'forecast.nameSubtitle' })}
        </Typography>
        <div className={styles.inputControl}>
          <Field
            name='forecastName'
            component={renderVersionNameInput}
            placeholder={`${formatMessage({ id: 'plansList.name' })}*`}
          />
        </div>
      </div>

      <div className={styles.inputBlock}>
        <Typography variant='h3' className={styles.inputTitle}>
          {formatMessage({ id: 'forecast.whatCropSpecies' })}
        </Typography>
        <Typography variant='subtitle3' className={styles.inputSubtitle}>
          {formatMessage({ id: 'forecast.cropSpeciesSubtitle' })}
        </Typography>

        <div className={classnames(styles.inputControl, styles.speciesItems)}>
          {speciesList.map(currentSpecies => (
            <CropToggleButton
              key={`crop-toggle-button${currentSpecies}`}
              className={styles.item}
              crop={currentSpecies}
              isSelected={selectedCrop === currentSpecies}
              title={formatMessage({ id: `plantingCycles.species.${currentSpecies}` })}
              onSelected={() => setSelectedCrop(currentSpecies)}
              disabled={editMode}
            />
          ))}
        </div>
      </div>

      <div className={classnames(styles.inputBlock, styles.inputBlockDates)}>
        <Typography variant='h3' className={styles.inputTitle}>
          {formatMessage({ id: 'forecast.whatDates' })}
        </Typography>
        <Typography variant='subtitle3' className={styles.inputSubtitle}>
          {formatMessage({ id: 'forecast.datesSubtitle' })}
        </Typography>

        <div className={styles.datesControls}>
          <Field
            name='startDate'
            component={CustomDatePickerInput}
            formatMessage={formatMessage}
            placeholder={`${formatMessage({ id: 'forecast.startDate' })}*`}
            wrapperClassName={styles.dateControl}
          />
          <Field
            name='endDate'
            component={CustomDatePickerInput}
            formatMessage={formatMessage}
            placeholder={`${formatMessage({ id: 'plantingCycles.endDate' })}*`}
            wrapperClassName={styles.dateControl}
          />
        </div>
      </div>

      <div className={styles.controls}>
        <BigButton
          className={styles.cancelButton}
          title={formatMessage({ id: 'dialog.cancel' })}
          type='button'
          onClick={history.goBack}
          theme='light'
        />
        <BigButton
          className={styles.submitButton}
          title={editMode ? formatMessage({ id: 'forecast.saveReport' }) : formatMessage({ id: 'forecast.createForecast' })}
          type='submit'
          theme='dark'
          isLoading={isForecastFetching}
        />
      </div>
    </form>
  );
};

HarvestForecastCreateForm.propTypes = {
  intl: intlShape.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  formValues: PropTypes.object,
  formSyncErrors: PropTypes.object,
  species: PropTypes.string.isRequired,
  requestUpdateForecast: PropTypes.func.isRequired,
  requestCreateForecast: PropTypes.func.isRequired,
  isForecastFetching: PropTypes.bool,
  forecastId: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  speciesList: PropTypes.array.isRequired,
};

HarvestForecastCreateForm.defaultProps = {
  editMode: false,
  formValues: null,
  formSyncErrors: null,
  isForecastFetching: false,
  forecastId: null,
};

export default reduxForm({
  form: FORM_NAME,
  validate,
})(HarvestForecastCreateForm);
