import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import uniqueId from 'lodash';
import classnames from 'classnames';

import Checkbox from 'components/Checkbox';
import DoubleCheckIcon from 'components/Icons/DoubleCheckIcon';

import styles from './TreeItem.module.css';

class TreeItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    offset: PropTypes.string,
    checkboxVisible: PropTypes.bool,
    onItemCheckboxClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    offset: 'offsetOne',
    checkboxVisible: false,
  };

  render() {
    const {
      item,
      offset,
      checkboxVisible,
      onItemCheckboxClick,
    } = this.props;

    const isTouchDevice = 'ontouchend' in document.documentElement;

    const {
      id, name, checked, completed
    } = item;

    const metricProps = {
      onClick: () => {
        onItemCheckboxClick(item);
      },
    };

    return (
      <div
        role='menu'
        tabIndex={0}
        key={uniqueId('tree-item-')}
        className={classnames(styles.item, styles[offset], {
          [styles.checked]: checked,
          [styles.touch]: isTouchDevice,
          [styles.checkboxVisible]: checkboxVisible,
        })}
        {...metricProps}
      >
        <div className={classnames(styles.checkboxControl)}>
          <Checkbox
            className={styles.checkbox}
            onChange={() => {
              onItemCheckboxClick(item);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            checked={!!checked}
            value={id}
          />
        </div>
        {/* <div className={classnames(styles.icon, styles.simpleIcon)}><DataIcon /></div> */}
        <div className={classnames(styles.title)}>{name}</div>
        {completed && <div className={classnames(styles.checkIcon)}><DoubleCheckIcon /></div>}
        {/* <div className={classnames(styles.cunit)}>{cunitData}</div> */}
      </div>
    );
  }
}

export default injectIntl(TreeItem);
