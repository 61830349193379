import React, { useCallback, useMemo, useState } from 'react';

import {
  Metric, MetricData, MetricDef, PresetMetricScale, TreeNodePathComponents
} from 'store/graphs/types';

import getPresetMetricKey from '../../../../utils/getPresetMetricKey';

import CellSelected, { OnCheckFunc } from './components/CellSelected';

import CellNameWithColor, { OnChangeColorFunc } from './components/CellNameWithColor';

import CellScale, {
  OnChangeScaleFunc,
  OnDeleteScaleFunc,
  OnNewScaleFunc,
  OnSelectScaleFunc
} from './components/CellScale';

import { MetricGroupPath } from '../../../../utils/metricsGroupUtils';
import CellNumeric from './components/CellNumeric';

import styles from './index.module.css';

type ChartTableRowProp = {
  metric: Metric;
  path: MetricGroupPath | null;
  presetMetric: MetricDef;
  groupId: number;
  metricData: MetricData | null;
  isSingleGroupCheck: boolean;
  scales: Array<PresetMetricScale>;
}

type ChartTableRowFunc = {
  onChangeColor: OnChangeColorFunc;
  onDeleteScale: OnDeleteScaleFunc;
  onChangeScale: OnChangeScaleFunc;
  onNewScale: OnNewScaleFunc;
  onSelectScale: OnSelectScaleFunc;
  onCheck: OnCheckFunc;
};

const ChartTableRow = ({
  metric,
  presetMetric,
  groupId,
  metricData,
  scales,
  isSingleGroupCheck,
  onChangeColor,
  onDeleteScale,
  onChangeScale,
  onNewScale,
  onSelectScale,
  onCheck,
  path,
}: ChartTableRowProp & ChartTableRowFunc) => {
  const [isShowColorPicker, setIsShowColorPicker] = useState(false);

  const handleMetricSelectedCheck = useCallback((target: Array<TreeNodePathComponents>, value: boolean) => {
    onCheck(target, value);
  }, [onCheck]);

  const handleClickColorSelectOpen = useCallback(() => {
    setIsShowColorPicker(true);
  }, []);

  const handleClickOutsideColorPicker = useCallback(() => {
    setIsShowColorPicker(false);
  }, [setIsShowColorPicker]);

  const handleChangeColor = useCallback((value: string) => {
    onChangeColor(presetMetric.node, value);
  }, [presetMetric, onChangeColor]);

  const handleDeleteScale = useCallback((targetMetric: Array<TreeNodePathComponents>, scaleValue: PresetMetricScale) => {
    onDeleteScale(targetMetric, scaleValue);
  }, [onDeleteScale]);

  const handleChangeScale = useCallback((targetMetric: Array<TreeNodePathComponents>, scaleValue: PresetMetricScale) => {
    onChangeScale(targetMetric, scaleValue);
  }, [onChangeScale]);

  const handleNewScale = useCallback((targetMetric: Array<TreeNodePathComponents>, scaleValue: PresetMetricScale) => {
    onNewScale(targetMetric, scaleValue);
  }, [onNewScale]);

  const handleSelectScale = useCallback((targetMetric: Array<TreeNodePathComponents>, scaleValue: PresetMetricScale) => {
    onSelectScale(targetMetric, scaleValue);
  }, [onSelectScale]);

  const {
    min, max, avg
  } = useMemo(() => {
    if (!metricData?.dataPoints) {
      return {
        min: null, max: null, avg: null
      };
    }
    const { dataPoints } = metricData;
    const values = Object.values(dataPoints).filter(value => value !== null);
    if (values.length === 0) {
      return {
        min: null, max: null, avg: null
      };
    }
    let sum = 0;
    const result = {
      min: values[0],
      max: values[0],
      avg: 0
    };
    values.forEach((value) => {
      if (value > result.max) result.max = value;
      if (value < result.min) result.min = value;
      sum += value;
    });
    result.avg = sum / values.length;
    return result;
  }, [metricData]);

  return (
    <tr className={styles.row} key={`metric-row-${getPresetMetricKey(presetMetric, `${groupId}`)}`}>
      <CellSelected
        presetMetric={presetMetric}
        headerKey='selected'
        groupId={groupId}
        onCheck={handleMetricSelectedCheck}
      />
      <CellNameWithColor
        metric={metric}
        presetMetric={presetMetric}
        headerKey='name'
        groupId={groupId}
        path={path}
        isShowColorPicker={isShowColorPicker}
        isSingleGroupCheck={isSingleGroupCheck}
        onClickColorSelectOpen={handleClickColorSelectOpen}
        onChangeColor={handleChangeColor}
        onClickOutsideColorPicker={handleClickOutsideColorPicker}
      />
      <CellScale
        presetMetric={presetMetric}
        headerKey='scale'
        groupId={groupId}
        scales={scales}
        scale={presetMetric.scale}
        onDelete={handleDeleteScale}
        onChange={handleChangeScale}
        onNew={handleNewScale}
        onSelect={handleSelectScale}
      />
      <CellNumeric
        metric={metric}
        presetMetric={presetMetric}
        headerKey='min'
        groupId={groupId}
        value={min}
      />
      <CellNumeric
        metric={metric}
        presetMetric={presetMetric}
        headerKey='max'
        groupId={groupId}
        value={max}
      />
      <CellNumeric
        metric={metric}
        presetMetric={presetMetric}
        headerKey='average'
        groupId={groupId}
        value={avg}
      />
    </tr>
  );
};

export default ChartTableRow;
