import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import format from 'date-fns/format';

import DefaultCircleLoader from 'components/DefaultCircleLoader';

import MeasurementsEnter from '../MeasurementsEnter';

const MeasurementsEnterWrapper = (props) => {
  const {
    cycleId,
    measurementsByDate,
    isFetching,
    date,
    requestMeasurementsByDate,
    clearMeasurementsByDate,
  } = props;

  useEffect(() => {
    requestMeasurementsByDate({ plantingCycleId: cycleId, date });

    return () => {
      clearMeasurementsByDate();
    };
  }, [
    clearMeasurementsByDate,
    requestMeasurementsByDate,
    cycleId,
    date
  ]);

  return (
    <>
      {!isFetching && measurementsByDate && (
        <MeasurementsEnter {...props} />
      )}
      {isFetching && <DefaultCircleLoader />}
    </>
  );
};

MeasurementsEnterWrapper.propTypes = {
  intl: intlShape.isRequired,
  organization: PropTypes.object,
  date: PropTypes.string,
  measurementsByDate: PropTypes.object,
  requestSaveMeasurementsByDate: PropTypes.func.isRequired,
  clearMeasurementsByDate: PropTypes.func.isRequired,

  cycleId: PropTypes.string,
  cycleInfo: PropTypes.object,
  allCompartments: PropTypes.array,
  requestMeasurementsByDate: PropTypes.func.isRequired,
  getCycleData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  showNotificationWithTimeout: PropTypes.func.isRequired,
};

MeasurementsEnterWrapper.defaultProps = {
  measurementsByDate: null,
  cycleId: null,
  cycleInfo: null,
  allCompartments: [],
  organization: null,
  date: format(new Date(), 'yyyy-MM-dd'),
};

export default injectIntl(MeasurementsEnterWrapper);
