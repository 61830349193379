const filterForecastPlantingData = (forecastData, data, key) => {
  if (!forecastData?.manualForecast) {
    return data;
  }
  const {
    manualForecast
  } = forecastData;

  const ids = manualForecast
    .map(pcItem => pcItem?.product?.payload?.[key]?.id)
    .filter(item => item !== null);

  return data.filter(item => ids.includes(item.id));
};

export default filterForecastPlantingData;
