import moment from 'moment-timezone';

import getDateFormat from '../getDateFormat';

export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_DATE_FORMAT_USA = 'MM-DD-YYYY';

export const getDefaultDateRange = (periodType = 'year', byPeriod = false) => {
  const startDate = moment().startOf(periodType).format(API_DATE_FORMAT);
  const endDate = byPeriod ?
    moment().endOf(periodType).format(API_DATE_FORMAT)
    :
    moment(new Date()).format(API_DATE_FORMAT);

  return {
    startDate,
    endDate,
  };
};

export const getDatesArray = (startDate, endDate, periodType = 'days') => {
  const days = [];
  const dateStart = moment(startDate, API_DATE_FORMAT);
  const dateEnd = moment(endDate, API_DATE_FORMAT);

  while (dateEnd.diff(dateStart, periodType) >= 0) {
    days.push(dateStart.format(API_DATE_FORMAT));
    dateStart.add(1, periodType);
  }

  return days;
};

export const getFormattedRangeDates = (anyDateOfPeriodStart, anyDateOfPeriodEnd, periodType, isShort = false) => {
  const dateFormat = isShort ? 'l' : 'll';
  const startOfPeriod = moment(anyDateOfPeriodStart, API_DATE_FORMAT);
  const endOfPeriod = moment(anyDateOfPeriodEnd, API_DATE_FORMAT).clone().endOf(periodType); // Для конечной даты берём конец периода
  const startDate = startOfPeriod ? startOfPeriod.format(getDateFormat(dateFormat)) : null;
  const endDate = endOfPeriod ? endOfPeriod.format(getDateFormat(dateFormat)) : null;

  return {
    startDate,
    endDate,
  };
};

export const getFormattedPeriod = (periodType, formatMessage) => {
  switch (periodType) {
    case 'day':
      return formatMessage({ id: 'dashboards.daily' });
    case 'week':
      return formatMessage({ id: 'dashboards.weekly' });
    case 'month':
      return formatMessage({ id: 'dashboards.monthly' });
    default:
      return formatMessage({ id: 'dashboards.weekly' });
  }
};

export const getDirectFormattedPeriod = (periodType, formatMessage) => {
  switch (periodType) {
    case 'year':
      return formatMessage({ id: 'kpiDashboard.years' });
    case 'month':
      return formatMessage({ id: 'kpiDashboard.months' });
    default:
      return formatMessage({ id: 'kpiDashboard.weekly' });
  }
};
