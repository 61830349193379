import PropTypes from 'prop-types';
import React from 'react';

import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import updateLocationSearch from 'helpers/updateLocationSearch';

import DropdownMenu from 'components/DropdownMenu';
import BigButton from 'components/BigButton';

import styles from './HarvestForecastDashboard.module.css';

import { ReactComponent as DocsIcon } from './assets/doc.svg';
import { ReactComponent as NoPubIcon } from './assets/not_published.svg';
import { ReactComponent as PubIcon } from './assets/published.svg';
import { ReactComponent as ClosedIcon } from './assets/closed.svg';
import { ReactComponent as OpenVersionIcon } from './assets/open_version.svg';

const renderVersionButton = (formatMessage, isShowMostRecent, currentVersion) => (
  <div
    role='button'
    tabIndex={0}
    className={classNames(styles.titleStatusIcon, styles.versionsBadge)}
  >
    <DocsIcon className={styles.titleStatusIconSvg} />
    <div className={styles.titleStatusIconText}>{isShowMostRecent ? formatMessage({ id: 'forecast.mostRecent' }) : `${formatMessage({ id: 'forecast.version' })} ${currentVersion?.publication?.version}`}</div>
    <OpenVersionIcon />
  </div>
);

const ReportBadge = ({
  intl,
  forecastData,
  forecastVersions,
  requestGetForecastVersion,
  setShowVersionPanel
}) => {
  const { formatMessage } = intl;

  const handlerClickShowFullHistory = () => {
    setShowVersionPanel({});
  };

  const handlerSelectVersion = (version) => {
    requestGetForecastVersion({
      versionId: version.id
    });
    updateLocationSearch({
      versionId: version.id
    });

    setShowVersionPanel({});
  };

  const status = forecastData?.status;
  const isShowSecondStatusBadge = status === 'NOT_PUBLISHED' || status === 'PUBLISHED';
  const statusText = formatMessage({ id: `forecast.badges.${status}` });

  let secondBadgeClass = null;
  if (status === 'NOT_PUBLISHED') {
    secondBadgeClass = styles.iconNotPublished;
  }
  if (status === 'PUBLISHED') {
    secondBadgeClass = styles.iconPublished;
  }

  const currentVersion = forecastVersions?.find(ver => ver.id === forecastData?.id);
  const lastVersion = forecastVersions.length > 0 ? forecastVersions[0] : null;
  const isCurrentVersionIsNotHistorical = currentVersion?.id === lastVersion?.id;
  let isShowMostRecent = currentVersion?.id === lastVersion?.id && (
    !currentVersion?.id || !lastVersion?.id
  );
  if (status === 'NOT_PUBLISHED' && !currentVersion?.publication?.version) {
    isShowMostRecent = true;
  }

  return status && isCurrentVersionIsNotHistorical ? (
    <div
      className={styles.iconsContainer}
    >
      {status === 'DRAFT' && (
        <div className={styles.titleStatusIcon}>
          <DocsIcon className={styles.titleStatusIconSvg} />
          <div className={styles.titleStatusIconText}>{formatMessage({ id: 'forecast.badges.DRAFT' })}</div>
        </div>
      )}
      {status === 'CLOSED' && (
        <div className={styles.titleStatusIcon}>
          <ClosedIcon className={styles.titleStatusIconSvg} />
          <div className={styles.titleStatusIconText}>{formatMessage({ id: 'forecast.badges.CLOSED' })}</div>
        </div>
      )}
      {forecastVersions?.length > 0 && status !== 'DRAFT' && status !== 'CLOSED' && (
        <DropdownMenu
          wrapperClassName={styles.dropdownWrapper}
          contentClassName={styles.dropdownContent}
          buttonElement={renderVersionButton(formatMessage, isShowMostRecent, currentVersion)}
        >
          <div className={styles.versionsList}>
            {forecastVersions.filter(ver => ver.publication).map(ver => (
              <BigButton
                className={styles.dropdownItem}
                onClick={() => handlerSelectVersion(ver)}
                theme='menuItem'
                title={`${formatMessage({ id: 'forecast.version' })} ${ver?.publication?.version}`}
              />
            ))}
            <BigButton
              className={styles.dropdownItem}
              onClick={handlerClickShowFullHistory}
              theme='menuItem'
              title={formatMessage({ id: 'forecast.seeFullHistory' })}
            />
          </div>
        </DropdownMenu>
      )}
      {isShowSecondStatusBadge && (
        <div className={classNames(styles.titleStatusIcon, secondBadgeClass)}>
          {status === 'NOT_PUBLISHED' && <NoPubIcon className={styles.titleStatusIconSvg} />}
          {status === 'PUBLISHED' && <PubIcon className={styles.titleStatusIconSvg} />}
          <div className={styles.titleStatusIconText}>{statusText}</div>
        </div>
      )}
    </div>
  ) : null;
};

ReportBadge.propTypes = {
  intl: intlShape.isRequired,
  forecastVersions: PropTypes.array,
  forecastData: PropTypes.object,
  requestGetForecastVersion: PropTypes.func.isRequired,
  setShowVersionPanel: PropTypes.func.isRequired,
};

ReportBadge.defaultProps = {
  forecastVersions: [],
  forecastData: null,
};


export default injectIntl(ReportBadge);
