import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import CropCompareIcon from 'components/Icons/CropCompareIcon';

import styles from './CropCompareButton.module.css';

const CropCompareButton = ({
  counter,
  isVisible,
  size,
  setIsCloseDropdown,
}) => (
  <div className={classnames(styles.compareButton, { [styles.bigSize]: size === 'big' })}>
    {isVisible && (
      <div className={styles.alertBageWrapper}>
        <div className={styles.alertBage}>
          {counter}
        </div>
      </div>
    )}
    <CropCompareIcon className={styles.compareIcon} onClick={() => setIsCloseDropdown(false)} />
  </div>
);

CropCompareButton.propTypes = {
  counter: PropTypes.number,
  isVisible: PropTypes.bool,
  setIsCloseDropdown: PropTypes.func,
  size: PropTypes.string,
};

CropCompareButton.defaultProps = {
  counter: 0,
  isVisible: false,
  size: 'medium',
  setIsCloseDropdown: () => {},
};

export default CropCompareButton;
