import { get } from 'lodash';
import { Observable } from 'rxjs';

import initAxiosInstanse from '../../api/axios';

import {
  requestGetHarvestDataByCategories,
  receiveGetHarvestDataByCategories,
  clearHarvestDataByCategories,
} from './actions';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

export function test() { return { value: 'debug' }; }

export const getHarvestDataByCategoriesEpic = (action$, store) =>
  action$.ofType(requestGetHarvestDataByCategories)
    .switchMap((actionData) => {
      const {
        payload: {
          plantingCycleId,
          startDate,
          endDate,
        }
      } = actionData;

      const {
        harvest,
      } = store.getState();

      const enabledForLocation = get(harvest, 'harvestCategories.enabledForLocation');

      return Observable.from(initAxiosInstanse().get(`planting-cycles/${plantingCycleId}/${enabledForLocation ? 'harvest-by-categories' : 'harvest-totals'}?startDate=${startDate}&endDate=${endDate}`))
        .map(({ data, error }) => {
          if (error) {
            return Observable.of(
              receiveGetHarvestDataByCategories(),
              showNotificationWithTimeout({
                id: `notifications.getHarvestDataByCategoriesError.${Date.now()}`,
                messageId: 'notifications.getHarvestDataByCategoriesError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withActionWide',
              })
            );
          }

          return receiveGetHarvestDataByCategories(data);
        })
        .catch(() => Observable.of(
          receiveGetHarvestDataByCategories(),
          showNotificationWithTimeout({
            id: `notifications.getHarvestDataByCategoriesError.${Date.now()}`,
            messageId: 'notifications.getHarvestDataByCategoriesError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          })
        ))
        .takeUntil(action$.ofType(clearHarvestDataByCategories));
    });
