import { get } from 'lodash';

// TODO: удалить функцию getVarietyName, вместо неё getMlName
export const getVarietyName = (variety, locale) => get(variety, `attributes.mlName[${locale}]`) || get(variety, 'attributes.mlName.en');
// TODO: написать общую функцию на getMlNameDirect и getMlName
export const getMlNameDirect = (variety, locale) => get(variety, `mlName[${locale}]`) || get(variety, 'mlName.en');
export const getMlName = (variety, locale) => get(variety, `attributes.mlName[${locale}]`) || get(variety, 'attributes.mlName.en');

export const getVarietyFullName = (variety, locale, formatMessage, withoutComma) => {
  const name = getMlName(variety, locale);
  const varietyFruitClass = get(variety, 'attributes.fruitClass');

  if (!varietyFruitClass) {
    return name;
  }

  const fruitClass = formatMessage({ id: `fruitClass.${varietyFruitClass}` });

  return withoutComma ? `${name} ${fruitClass}` : `${name}, ${fruitClass}`;
};

// TODO: Вынести в хелпер. Не аналог lodash функции, тк лодаш плохо работает с тире в словах (не как CSS)
// const capitalize = text => text.replace(/\b\w/g, m => m.toUpperCase());
const capitalize = text => text.toLowerCase()
  .split(' ')
  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');

export const getProductTypeName = ({
  intl,
  variety,
  fruitClass,
  targetWeight,
  withSpecies = false,
  withFruitClass = true,
  withCommaSeparated = false,
  directPath = false,
}) => {
  const { formatMessage, locale } = intl;
  const separator = withCommaSeparated ? ', ' : ' ';
  const varietyName = directPath ? getMlNameDirect(variety, locale) : getMlName(variety, locale);
  const fruitClassName = directPath ? getMlNameDirect(fruitClass, locale) : getMlName(fruitClass, locale);
  const species = get(variety, 'attributes.species');
  const speciesName = species ? formatMessage({ id: `plantingCycles.species.${species}` }) : species;

  const varietyText = varietyName || '';
  const fruitClassText = fruitClassName && withFruitClass ? `${separator}${capitalize(fruitClassName)}` : '';
  const targetWeightText = species === 'lettuce' && targetWeight ? `${separator}${targetWeight}\u00A0${formatMessage({ id: 'cunits.mini.gram' })}` : '';
  const speciesText = withSpecies && speciesName ? `${separator}${capitalize(speciesName)}` : '';

  return `${varietyText}${fruitClassText}${targetWeightText}${speciesText}`;
};
