import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import BigButton from 'components/BigButton';
import DashboardComplexFilters from 'components/DashboardComplexFilters';

import styles from './PlansHeader.module.css';

export default class PlansHeader extends PureComponent {
  static propTypes = {
    addHarvestLink: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    isAddPlansAvailable: PropTypes.bool.isRequired,
    isOnlyLettuce: PropTypes.bool,
  };

  static defaultProps = {
    isOnlyLettuce: false,
  };

  render() {
    const {
      intl,
      addHarvestLink,
      isAddPlansAvailable,
      isOnlyLettuce,
      onFilterChange,
    } = this.props;

    const { formatMessage } = intl;

    const breakdownOptions = [
      { label: formatMessage({ id: 'dashboards.compartments' }), value: 'compartment' },
      { label: formatMessage({ id: 'dashboards.varieties' }), value: 'varietyWeight' },
    ];

    const filters = [{
      type: 'breakdown',
      options: {
        breakdownOptions,
        title: formatMessage({ id: 'plans.showBy' }),
        useQueryParams: true,
        defaultAsUndefined: false,
        currentBreakdown: 'varietyWeight'
      }
    }, 'plan', {
      type: 'relative',
      options: {
        title: formatMessage({ id: 'plans.units' }),
      }
    }, {
      type: 'group',
      filters: [{
        type: 'compartment',
        options: {
          classNameButton: styles.selectButtonText,
          defaultNoSelectedValue: []
        }
      }, {
        type: 'variety',
        options: {
          classNameButton: styles.selectButtonText,
        }
      }]
    }];

    return (
      <div className={classnames(styles.container)}>
        <div className={styles.title}>
          <FormattedMessage id='plans.title' />
        </div>
        <div className={styles.controls}>
          {!isOnlyLettuce && (
            <DashboardComplexFilters
              filters={filters}
              onFiltersChange={onFilterChange}
            />
          )}
          {isAddPlansAvailable && (
            <BigButton
              className={styles.button}
              title={formatMessage({ id: 'plans.addPlans' })}
              href={addHarvestLink}
            />
          )}
        </div>
      </div>
    );
  }
}
