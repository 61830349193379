/**
 * Компонент на замену RangePicker без контейнера с пропсами из стейта
 * TODO: После того, как команда дизайна сделает дизайн, отрефакторить этот компонент
 */
import React, { Component } from 'react';
import DatePicker from 'react-datepicker/dist/react-datepicker.min';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import moment from 'moment-timezone';
import classnames from 'classnames';

import DateButton from '../DateButton';
import Select from '../../../Select';
import MobileMenu from '../../../MobileMenu';
import BigSelectInput from '../../../BigSelectInput';
import BigButton from '../../../BigButton';

import getDateFormat from '../../../../helpers/getDateFormat';
import { API_DATE_FORMAT, getDefaultDateRange } from '../../../../helpers/defaultDates';
import isPhoneView from '../../../../helpers/isPhoneView';

import { ReactComponent as LeftIcon } from './assets/left.svg';
import { ReactComponent as RightIcon } from './assets/right.svg';
import CalendarIcon from '../../../Icons/CalendarIcon';

import styles from './DefaultRangePicker.module.css';

import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DEFAULT_DATE_RANGE = getDefaultDateRange();

class DefaultRangePicker extends Component {
  static propTypes = {
    intl: intlShape.isRequired,

    timezone: PropTypes.string.isRequired,
    handlerAfterRangeSelect: PropTypes.func.isRequired,
    customRanges: PropTypes.array,
    periodType: PropTypes.string,
    withoutRangePicker: PropTypes.bool,
    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
    forcedMaxDate: PropTypes.object,
    disabled: PropTypes.bool,
    byOneDate: PropTypes.bool,
    className: PropTypes.string,
    onMenuOpened: PropTypes.func,
    onMenuClosed: PropTypes.func,
  };

  static defaultProps = {
    withoutRangePicker: false,
    customRanges: null,
    periodType: 'week',
    anyDateOfPeriodStart: DEFAULT_DATE_RANGE.startDate,
    anyDateOfPeriodEnd: DEFAULT_DATE_RANGE.endDate,
    forcedMaxDate: null,
    disabled: false,
    byOneDate: false,
    className: null,
    onMenuOpened: undefined,
    onMenuClosed: undefined,
  };

  state = {
    highlightedDate: undefined,
    mobileMenuOpened: false,
    isPhone: isPhoneView(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.handlerResizeWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResizeWindow);
  }

  openStartDate = () => {
    const { isPhone } = this.state;

    if (!isPhone) {
      this.startDatepicker.setOpen(true);
    }
  };

  setNewSearchPath = (newParams) => {
    const { handlerAfterRangeSelect } = this.props;

    handlerAfterRangeSelect(newParams);
  };

  handlerResizeWindow = () => {
    this.setState({ isPhone: isPhoneView() });
  };

  handlerRangeSelect = (options) => {
    const {
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      byOneDate,
    } = this.props;

    const periodType = options.value;
    const newStartDate = moment(anyDateOfPeriodStart, API_DATE_FORMAT).clone().startOf(periodType).format(API_DATE_FORMAT);
    const newEndDate = byOneDate ?
      moment(anyDateOfPeriodStart, API_DATE_FORMAT).clone().endOf(periodType).format(API_DATE_FORMAT)
      :
      moment(anyDateOfPeriodEnd, API_DATE_FORMAT).clone().endOf(periodType).format(API_DATE_FORMAT);

    this.setNewSearchPath({
      periodType,
      anyDateOfPeriodStart: newStartDate,
      anyDateOfPeriodEnd: newEndDate,
    });
  };

  handlerByOneDate = (date) => {
    const { periodType } = this.props;
    const newStartDate = date.clone().startOf(periodType).format(API_DATE_FORMAT);
    const newEndDate = date.clone().endOf(periodType).format(API_DATE_FORMAT);

    this.setState({ highlightedDate: undefined });

    this.setNewSearchPath({
      periodType,
      anyDateOfPeriodStart: newStartDate,
      anyDateOfPeriodEnd: newEndDate,
    });
  };

  handlerChangeStart = (date) => {
    const { periodType, byOneDate } = this.props;

    if (byOneDate) {
      return this.handlerByOneDate(date);
    }

    this.endDatepicker.setOpen(true);

    const newStartDate = date.clone().startOf(periodType).format(API_DATE_FORMAT);

    return this.setState({ highlightedDate: 'end', temporaryDateOfPeriodStart: newStartDate });
  };

  handlerChangeEnd = (date) => {
    const { periodType } = this.props;
    const { temporaryDateOfPeriodStart } = this.state;

    this.setState({ highlightedDate: undefined });

    // Устанавливаем дату конца, в зависимости от конца периода (неделя/месяц) выбранной даты
    this.setNewSearchPath({
      periodType,
      anyDateOfPeriodStart: temporaryDateOfPeriodStart,
      anyDateOfPeriodEnd: date.clone().endOf(periodType).format(API_DATE_FORMAT),
    });
  };

  handlerShowResults = () => {
    const { onMenuClosed } = this.props;

    if (onMenuClosed) {
      onMenuClosed();
    }

    this.setState({ mobileMenuOpened: false });
  };

  handlerOnInputClick = () => this.setState({ highlightedDate: 'start' });

  handlerClickOutside = () => this.setState({ temporaryDateOfPeriodStart: null, highlightedDate: undefined });

  handlerArrowClick = (operation, startDate, endDate, periodType) => {
    // Если даты находятся в одном периоде (например для одной недели пн и вс), то diff будет 0. В этом случае берём единицу.
    const periodDiff = endDate.diff(startDate, periodType) || 1;

    const newStartDate = operation === 'subtract' ? startDate.subtract(periodDiff, periodType) : startDate.add(periodDiff, periodType);
    const newEndDate = operation === 'subtract' ? endDate.subtract(periodDiff, periodType) : endDate.add(periodDiff, periodType);

    this.setNewSearchPath({
      periodType,
      anyDateOfPeriodStart: newStartDate.clone().startOf(periodType).format(API_DATE_FORMAT),
      anyDateOfPeriodEnd: newEndDate.clone().endOf(periodType).format(API_DATE_FORMAT),
    });
  };

  handlerCloseMenu = () => {
    const { onMenuClosed } = this.props;

    if (onMenuClosed) {
      onMenuClosed();
    }

    return this.setState({
      highlightedDate: undefined,
      mobileMenuOpened: false,
    });
  };

  handlerOpenMenu = () => {
    const { onMenuOpened } = this.props;

    if (onMenuOpened) {
      onMenuOpened();
    }

    return this.setState({
      mobileMenuOpened: true,
    });
  };

  renderDateRange = (startOfPeriod, endOfPeriod, highlightedDate) => (
    <span>
      <span
        className={classnames({ [styles.highlightedDate]: highlightedDate === 'start' })}
      >
        {startOfPeriod}
      </span>
      {' – '}
      <span
        className={classnames({ [styles.highlightedDate]: highlightedDate === 'end' })}
      >
        {endOfPeriod}
      </span>
    </span>
  );

  renderRangePicker = () => {
    const {
      withoutRangePicker,
      timezone,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,
      forcedMaxDate,
      disabled,
    } = this.props;

    const { highlightedDate, temporaryDateOfPeriodStart } = this.state;

    // Берём начало и конец периода, тк данные прилетают по периодам
    const startDate = moment(anyDateOfPeriodStart, API_DATE_FORMAT).clone().startOf(periodType);
    const endDate = moment(anyDateOfPeriodEnd, API_DATE_FORMAT).clone().endOf(periodType);

    const startOfPeriod = startDate ? startDate.format(getDateFormat('lll')) : null;
    const endOfPeriod = endDate ? endDate.format(getDateFormat('lll')) : null;

    // Ограничение по минимальной начальной дате - три года назад.
    const minStartDate = moment(new Date()).tz(timezone).subtract(3, 'years');

    const todayDate = moment(new Date()).startOf('day');

    const isAddArrowDisabled = endDate >= todayDate;

    const endOfCurrentPeriod = todayDate.clone().endOf(periodType);

    const maxDateForPicker = endDate > endOfCurrentPeriod ? endDate : endOfCurrentPeriod;

    return (
      <div className={styles.customDatePickerWrapper}>
        <button
          type='button'
          className={classnames(styles.leftButton, { [styles.disabled]: disabled })}
          onClick={() => this.handlerArrowClick('subtract', startDate, endDate, periodType)}
          disabled={disabled}
        >
          <LeftIcon />
        </button>

        <DatePicker
          ref={(datepicker) => {
            this.startDatepicker = datepicker;
          }}
          disabled={disabled}
          selected={startDate}
          minDate={minStartDate}
          maxDate={forcedMaxDate || maxDateForPicker}
          customInput={( // Обёртка из div нужна, т.к. react-datepicker прокидывает свой onClick, который перезапишет наш обработчик
            <div>
              <DateButton
                className={classnames({ [styles.dateButtonFocused]: !!highlightedDate })}
                date={this.renderDateRange(startOfPeriod, endOfPeriod, highlightedDate)}
                onClick={this.handlerOnInputClick}
                disabled={disabled}
              />
            </div>
          )}
          onSelect={this.handlerChangeStart}
          onClickOutside={this.handlerClickOutside}
          popperPlacement='bottom'
          popperModifiers={{
            offset: {
              enabled: true,
              offset: withoutRangePicker ? '-73px, 0' : '-147px, 0',
            },
          }}
        />

        <div className={styles.secondDatePicker}>
          <DatePicker
            disabled={disabled}
            ref={(datepicker) => {
              this.endDatepicker = datepicker;
            }}
            selected={endDate}
            minDate={temporaryDateOfPeriodStart || startDate}
            maxDate={forcedMaxDate || maxDateForPicker}
            customInput={<div className={styles.invisibleDatepickerButton} />}
            onSelect={this.handlerChangeEnd}
            onClickOutside={this.handlerClickOutside}
            popperPlacement='bottom-end'
            popperModifiers={{
              offset: {
                enabled: true,
                offset: '0, 7px',
              },
            }}
          />
        </div>

        <button
          type='button'
          className={classnames(styles.rightButton, { [styles.disabled]: disabled || isAddArrowDisabled })}
          onClick={() => this.handlerArrowClick('add', startDate, endDate, periodType)}
          disabled={disabled || isAddArrowDisabled}
        >
          <RightIcon />
        </button>
      </div>
    );
  };

  render() {
    const {
      intl,
      customRanges,
      withoutRangePicker,
      className,
      periodType,
    } = this.props;
    const { formatMessage } = intl;

    const {
      mobileMenuOpened, isPhone,
    } = this.state;

    const defaultRanges = [
      {
        label: formatMessage({ id: 'dashboards.daily' }),
        value: 'day',
      },
      {
        label: formatMessage({ id: 'dashboards.weekly' }),
        value: 'week',
      },
      {
        label: formatMessage({ id: 'dashboards.monthly' }),
        value: 'month',
      },
    ];

    return (
      <div className={classnames(styles.rangePickerContainer, className)}>
        {isPhone ? (
          <div className={styles.mobileRangePickerContainer}>
            <MobileMenu
              className={styles.mobileMenu}
              icon={<CalendarIcon />}
              headerText={formatMessage({ id: 'harvest.selectPeriod' })}
              onClose={this.handlerCloseMenu}
              onOpen={this.handlerOpenMenu}
              menuOpened={mobileMenuOpened}
            >
              {!withoutRangePicker ? (
                <BigSelectInput
                  className={styles.mobilePeriodSelect}
                  placeholder={formatMessage({ id: 'harvest.period' })}
                  options={customRanges || defaultRanges}
                  value={periodType}
                  onChange={this.handlerRangeSelect}
                  labelPath='label'
                  valuePath='value'
                  closeOnChange
                />
              ) : null}
              {this.renderRangePicker()}
              <div className={styles.mobileControls}>
                <BigButton
                  className={styles.cancelButton}
                  title={formatMessage({ id: 'button.cancel' })}
                  type='button'
                  onClick={this.handlerCloseMenu}
                  theme='light'
                />
                <BigButton
                  title={formatMessage({ id: 'cropsPerformance.showResults' })}
                  type='button'
                  onClick={this.handlerShowResults}
                  theme='dark'
                />
              </div>
            </MobileMenu>
          </div>
        )
          : (
            <div className={styles.desktopRangePickerContainer}>
              {!withoutRangePicker ? (
                <Select
                  className={styles.select}
                  classNameButton={styles.selectButton}
                  classNamePopup={styles.selectPopup}
                  options={customRanges || defaultRanges}
                  value={periodType}
                  onChange={this.handlerRangeSelect}
                  labelPath='label'
                  valuePath='value'
                  closeOnChange
                />
              ) : null}

              {this.renderRangePicker(isPhone)}
            </div>
          )}
      </div>
    );
  }
}

export default DefaultRangePicker;
