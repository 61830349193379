import {
  GraphsPresetRange,
  Location,
  Preset
} from 'store/graphs/types';

import getDatesRange from 'helpers/getDatesRange';

function getGraphPointsPayload(
  preset: Preset,
  newRange: GraphsPresetRange,
  location: Location,
  threshold: number
) {
  // TODO: To helper
  const nodes = preset?.preset?.presetMetrics?.map(metric => [...metric.node]) || [];
  const { xTo, xFrom } = getDatesRange({
    ...newRange,
    location,
  });
  return {
    includeMetrics: true,
    nodes,
    returnIncidents: 'all',
    scaleAsRoundToNMinutes: threshold,
    xFrom: Math.round(+xFrom / 60 / 1000),
    xTo: Math.round(+xTo / 60 / 1000)
  };
}

export default getGraphPointsPayload;
