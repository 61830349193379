import { createSelector } from 'reselect';
import { get, find, max } from 'lodash';
import { getRouteQueryParams } from 'store/company/selectors';

export const getPointsArray = (object, path) => {
  const points = get(object, path || 'dataPoints');

  return points ? Object.keys(points).map(key => points[key]) : [];
};

export const getHarvestDashboard = state => state.dashboards.harvestDashboard;
export const getHarvestDashboardFetching = state => state.dashboards.isHarvestDashboardFetching;
export const getHarvestGraphFetching = state => state.dashboards.isHarvestGraphFetching;
export const getQualityGraphFetching = state => state.dashboards.isQualityGraphFetching;
export const getBreakdownGraphFetching = state => state.dashboards.isBreakdownGraphFetching;
export const getHarvestDashboardCategories = state => state.dashboards.harvestDashboardCategories;
export const getHarvestDashboardBreakdown = state => state.dashboards.harvestDashboardBreakdown;
export const getDefaultBreakdownType = () => 'compartment';

export const getGraphKeyId = (state, props) => props.graphKeyId;
export const getReport = (state, props) => props.report;
export const getRoundTo = (state, props) => props.roundTo;
export const getFormatCell = (state, props) => props.formatCell;
export const getMeter = (state, props) => props.meter;
export const getSorting = (state, props) => props.sorting;

export const getGraphKey = createSelector(
  [getRouteQueryParams, getGraphKeyId],
  (query, key) => get(query, key, 'total'),
);

function flatten({ rows }) {
  if (!rows) {
    return [];
  }

  return rows.reduce((acc, row) => [...acc, row, ...flatten({ rows: row.children })], []);
}

export const getSelectedRow = createSelector(
  [
    getReport,
    getGraphKey,
  ],
  (report, key) => {
    const rows = get(report, 'rows', []);

    // Чтобы поиск по ключу был так же и в детях (нужно для отображения на графике данных, выбранных в таблице)
    const flatReportRows = flatten({ rows });

    return find(flatReportRows, { key });
  },
);


export const getHarvestDashboardPoints = createSelector(
  [getHarvestDashboard],
  harvestDashboard => get(harvestDashboard, 'points') || [],
);

export const getHarvestDashboardActual = createSelector(
  [getHarvestDashboard],
  harvestDashboard => get(harvestDashboard, 'totals.actual'),
);

export const getHarvestDashboardGoal = createSelector(
  [getHarvestDashboard],
  harvestDashboard => get(harvestDashboard, 'totals.goal'),
);

export const getHarvestDashboardPrevYear = createSelector(
  [getHarvestDashboard],
  harvestDashboard => get(harvestDashboard, 'totals.prevYear'),
);

export const getHarvestDashboardGoalPoints = createSelector(
  [getHarvestDashboardPoints],
  points => find(points, { key: 'goal' }),
);

export const getHarvestDashboardActualPoints = createSelector(
  [getHarvestDashboardPoints],
  points => find(points, { key: 'actual' }),
);

export const getHarvestDashboardPrevYearPoints = createSelector(
  [getHarvestDashboardPoints],
  points => find(points, { key: 'prevYear' }),
);

export const getHarvestDashboardMaxValue = createSelector(
  getHarvestDashboardGoalPoints,
  getHarvestDashboardActualPoints,
  getHarvestDashboardPrevYearPoints,
  (goal, actual, prevYear) => {
    const goalArr = getPointsArray(goal);
    const actualArr = getPointsArray(actual);
    const prevYearArr = getPointsArray(prevYear);

    const valuesArray = [
      ...actualArr,
      ...goalArr,
      ...prevYearArr,
    ];

    return max(valuesArray);
  }
);

export const getHarvestDashboardPointsByCategories = createSelector(
  [getHarvestDashboardCategories],
  categories => get(categories, 'pointsByCategories') || [],
);

export const getHarvestDashboardTotalsByCategories = createSelector(
  [getHarvestDashboardCategories],
  categories => get(categories, 'totalsByCategories') || [],
);

export const getHarvestByCategoriesEnabled = createSelector(
  [getHarvestDashboardCategories],
  categories => get(categories, 'harvestByCategoriesEnabled'),
);

export const getHarvestCategoriesMaxValue = createSelector(
  getHarvestDashboardPointsByCategories,
  (points) => {
    const valuesArray = points.reduce((acc, line) => {
      const values = getPointsArray(line);

      return [...acc, ...values];
    }, []);

    return max(valuesArray);
  }
);

export const getHarvestCategoriesPercentMaxValue = createSelector(
  getHarvestDashboardPointsByCategories,
  (points) => {
    const valuesArray = points.reduce((acc, line) => {
      const values = getPointsArray(line, 'percentagePoints');

      return [...acc, ...values];
    }, []);

    return max(valuesArray);
  }
);
