import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import DefaultPlate from '../../../DefaultPlate';

import styles from './CellWithError.module.css';

const CellWithError = React.memo(({
  value, error, className, errorClassName, alignLeft, alignBottom
}) => (
  <div className={classnames(
    styles.cellWithError,
    className,
    {
      [styles.alignLeft]: alignLeft,
      [styles.alignBottom]: alignBottom,
    }
  )}
  >
    <span>{value}</span>
    <div className={classnames(styles.errorContent, errorClassName)}>
      <DefaultPlate className={styles.errorPlate} type='error'>
        {error}
      </DefaultPlate>
    </div>
  </div>
));

CellWithError.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  error: PropTypes.string.isRequired,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  alignLeft: PropTypes.bool,
  alignBottom: PropTypes.bool,
};

CellWithError.defaultProps = {
  className: null,
  errorClassName: null,
  alignLeft: false,
  alignBottom: false,
};

export default CellWithError;
