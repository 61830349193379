/**
 * Парсит jwt токен
 *
 * @param {string} token - Jwt токен
 * @return {object} Распарсенный токен
 */

const parseJwt = (token) => {
  if (!token) {
    return null;
  }

  const stringToken = token.toString();
  const base64Url = stringToken.split('.')[1];

  if (!base64Url) {
    return null;
  }

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    const code = `00${c.charCodeAt(0).toString(16)}`.slice(-2);

    return `%${code}`;
  }).join(''));

  return JSON.parse(jsonPayload);
};

export default parseJwt;
