import parse from 'date-fns/parse';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { utcToZonedTime } from 'date-fns-tz';
import {
  enUS as en, zhCN as zh, fr, nl
} from 'date-fns/locale';

import ruLocale from './ruLocale';
import storageWrapper from '../storageWrapper';

const safeLocalStorage = storageWrapper.get('localStorage');
const API_FORMAT = 'yyyy-MM-dd';

const LOCALES = {
  en,
  ru: ruLocale,
  fr,
  nl,
  'zh-cn': zh,
};

export const getCurrentLocale = () => {
  const locale = safeLocalStorage.getItem('locale');

  if (!locale) {
    return LOCALES.en;
  }

  return LOCALES[locale];
};
/**
 * Return the date template considering the localization
 *
 * @param {string} formatString - string code of format
 * @return {string} the string of tokens
 */
export const getLocalizedDateFormat = (formatString) => {
  const LOCALE = safeLocalStorage.getItem('locale');

  switch (formatString) {
    case 'l':
      return "MMM'' YY";
    case 'll':
      if (LOCALE === 'ru') {
        return 'd MMM';
      }

      return 'MMM d';
    case 'lll':
      if (LOCALE === 'ru') {
        return 'd MMM, yyyy';
      }

      return 'MMM d, yyyy';
    case 'llll':
      if (LOCALE === 'ru') {
        return 'EEEEEE, d MMM';
      }

      return 'EEE, MMM d';
    case 'LLL':
      if (LOCALE === 'ru') {
        return 'd MMMM, yyyy';
      }

      return 'MMMM d, yyyy';
    case 'LLLL':
      if (LOCALE === 'ru') {
        return 'EEE, d MMM, yyyy';
      }

      return 'EEE, MMM d, yyyy';
    case 'll hh':
      if (LOCALE === 'ru') {
        return 'd MMM, HH:mm';
      }

      return 'MMM d, HH:mm';
    case 'lll hh':
      if (LOCALE === 'ru') {
        return 'd MMM, yyyy HH:mm';
      }

      return 'MMM d, yyyy HH:mm';
    default:
      return null;
  }
};

/**
 * Return the date parsed from string using the default API date format
 *
 * @param {string} date - string date in YYYY-MM-DD format
 * @return {Date} date object
 */
export const parseDate = date => parse(date, API_FORMAT, new Date());

/**
 * Return the date parsed from string using the default API date format
 *
 * @param {string} date - string date in specified format
 * @param {string} fmt - string parsed date format
 * @return {Date} date object
 */
export const parseDateWithFormat = (date, fmt) => parse(date, fmt, new Date());

/**
 * Return the formatted date string in the given format
 *
 * @param {Date} date - the original date
 * @param {string} formatString - the string of tokens
 * @return {string} the formatted date string
 */
export const formatDate = (date, formatString) => format(date, formatString, { locale: getCurrentLocale() });

/**
 * Parse from string using the default API date format and return the formatted
 * date string in the given format
 *
 * @param {string} date - string date in YYYY-MM-DD format
 * @param {string} formatString - the string of tokens
 * @return {string} the formatted date string
 */
export const parseAndFormatDate = (date, formatString) => format(parseDate(date), formatString, { locale: getCurrentLocale() });

/**
 * Return the formatted date string in the localized format (l/ll/lll/LLLL etc)
 *
 * @param {Date} date - the original date
 * @param {string} localizedFormatString - string code of format (the string of tokens)
 * @return {string} the formatted date string
 */
export const formatDateLocalized = (date, localizedFormatString) => format(date, getLocalizedDateFormat(localizedFormatString), { locale: getCurrentLocale() });

/**
 * Parse from string using the default API date format and return the formatted
 * date string in the localized format (l/ll/lll/LLLL etc)
 *
 * @param {string} date - string date in YYYY-MM-DD format
 * @param {string} localizedFormatString - string code of format (the string of tokens)
 * @return {string} the formatted date string
 */
export const parseAndFormatDateLocalized = (date, localizedFormatString) => format(parseDate(date), getLocalizedDateFormat(localizedFormatString), { locale: getCurrentLocale() });


/**
 * Return the date parsed from string using the ISO date format
 *
 * @param {string} date - string date in yyyy-MM-ddTHH:mm:ss.SSSX format
 * @return {Date} date object
 */
export const parseDateIso = date => parseISO(date);

/**
 * Convert epoch minutes (not seconds or milliseconds) to GMT javascript Date
 *
 * @param {string|number} epochMinutes - string date in epoch minutes format
 * @return {Date} date object
 */
export const epochMinutesToGMT = epochMinutes => utcToZonedTime(new Date((+epochMinutes) * 60000), 'UTC');
