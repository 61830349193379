import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { get } from 'lodash';
import queryString from 'query-string';

import { updatePlantingCycleStart, getPlantingCycleStart } from '../../store/crops/actions';
import { clearHarvestData } from '../../store/harvest/actions';

import { getAllFruitClasses } from '../../store/company/selectors';

import UpdatePlantingCycleWrapper from './components/UpdatePlantingCycleWrapper';

const isUpdatePlantingCycleStarted = state => state.crops.updatePlantingCycleStarted;
const isUpdatePlantingCycleEnded = state => state.crops.updatePlantingCycleEnded;

const isUpdatePlantingCycleFetching = createSelector(
  [isUpdatePlantingCycleStarted, isUpdatePlantingCycleEnded],
  (isUpdatePlantingCycleStartedFlag, isUpdatePlantingCycleEndedFlag) => isUpdatePlantingCycleStartedFlag && !isUpdatePlantingCycleEndedFlag,
);

const mapDispatchToProps = {
  getPlantingCycleStart,
  updatePlantingCycleStart,
  clearHarvestData,
};

const mapStateToProps = (state, props) => {
  // Получаем параметр из GET запросв
  const parsedQuery = queryString.parse(get(props, 'location.search'));
  const fromPage = get(parsedQuery, 'fromPage');

  // TODO: убрать отсюда внесение вообще всех данных, передавать конкретные пропсы.
  // Компонент UpdatePlantingCycleWrapper удалить, он абсолютно лишний
  const {
    intl,
    form,
    company,
    crops,
  } = state;

  const {
    location,
    ...otherCompany
  } = company;

  return {
    organization: location,
    ...intl,
    ...otherCompany,
    ...crops,
    ...form,
    isUpdatePlantingCycleFetching: isUpdatePlantingCycleFetching(state),
    fromPage,
    fruitClasses: getAllFruitClasses(state, props),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePlantingCycleWrapper);
