
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './TooltipMetric.module.css';

const TooltipMetric = ({
  value, units, text, color, isActive, className, isSecond
}) => (
  <div
    className={classnames(
      className,
      styles.harvestRow,
      {
        [styles.harvestRowActive]: isActive,
        [styles.secondRow]: isSecond
      }
    )}
  >
    <span className={styles.harvestTitle}>
      <span className={styles.harvestColor} style={{ backgroundColor: color }} />
      <span>
        {text}
      </span>
    </span>
    <span className={styles.harvestRowAmount}>{value || '—'} {units}</span>
  </div>
);

TooltipMetric.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  units: PropTypes.string,
  value: PropTypes.string,

  isActive: PropTypes.bool,
  isSecond: PropTypes.bool,
  className: PropTypes.string,
};

TooltipMetric.defaultProps = {
  className: null,
  isActive: false,
  isSecond: false,
  value: null,
  units: '',
};

export default memo(TooltipMetric);
