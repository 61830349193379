import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import DefaultDialog from 'components/DefaultDialog';
import BigButton from 'components/BigButton';

import styles from './DeleteInfoDialog.module.css';

class DeleteInfoDialog extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    isDeleteVersionInfoFetching: PropTypes.bool,

    handlerCloseDialog: PropTypes.func.isRequired,
    handlerDontSave: PropTypes.func.isRequired,
    handlerSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isDeleteVersionInfoFetching: false,
  };

  render() {
    const {
      intl: { formatMessage }, isDeleteVersionInfoFetching,
      handlerCloseDialog, handlerDontSave, handlerSave
    } = this.props;

    return (
      <DefaultDialog
        title={formatMessage({ id: 'plansVersion.deleteInfo' })}
        onClose={handlerCloseDialog}
        wrapperClassName={styles.dialogWrapper}
      >
        <div>
          <p className={styles.dialogText}>
            {formatMessage({ id: 'plansVersion.deleteConfirmMessage' })}
          </p>
          <div className={styles.buttons}>
            <BigButton
              className={styles.cancel}
              onClick={handlerDontSave}
              title={formatMessage({ id: 'button.cancel' })}
              theme='light'
              disabled={isDeleteVersionInfoFetching}
            />
            <BigButton
              className={styles.confitm}
              onClick={handlerSave}
              title={formatMessage({ id: 'button.confirmDelete' })}
              theme='error'
              isLoading={isDeleteVersionInfoFetching}
            />
          </div>
        </div>
      </DefaultDialog>
    );
  }
}

export default injectIntl(DeleteInfoDialog);
