import React, {
  useEffect, useMemo, useRef, useState, useCallback
} from 'react';

import classnames from 'classnames';

import * as d3 from 'd3';

// @ts-ignore
import moment from 'moment-timezone';

// @ts-ignore
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';

import { Line, Preset } from 'store/graphs/types';

import { LinePoint } from '../ChartLines/components/ChartLine';

import styles from './index.module.css';

type GraphsTooltipProp = {
  parent: string;
  screenY: number;
  showTooltip: boolean;
  xScale: any;
  preset: Preset | null;
  hoverX: number;
  hoverY: number;
  lines: Array<Line>;
  threshold: number;
  // location: Location
}

// const defaultOffsetX = 20;
const defaultOffsetY = 40;

type TooltipState = {
  leftPosition: number;
  topPosition: number;
};

const GraphsTooltip = ({
  intl,
  preset,
  lines,
  xScale,
  hoverY,
  hoverX,
  screenY,
  parent,
  showTooltip,
  threshold,
  // location
} : GraphsTooltipProp & InjectedIntlProps) => {
  const { formatMessage } = intl;

  const tooltipRef = useRef<HTMLDivElement>(null);

  const [tooltipPos, setTooltipPos] = useState<TooltipState>({
    leftPosition: 0,
    topPosition: 0
  });

  const splitMetricsDatas = useMemo(() => lines.map((line: Line):Array<LinePoint> => {
    if (line) {
      return line.data;
    }
    return [];
  }).reduce((reducer: Array<LinePoint>, metricRows: Array<LinePoint>) => {
    metricRows.forEach((row) => {
      if (!reducer.some(innerGr => innerGr.date === row.date)) {
        reducer.push(row);
      }
    });
    return reducer;
  }, [])
    // @ts-ignore
    .sort((a: LinePoint, b:LinePoint) => a.date - b.date), [lines]);

  const screenX = useMemo(() => {
    const xValue = xScale.invert(hoverX);
    // @ts-ignore
    const bisectDateLeft = d3.bisector(d => d.date).left;
    const iLeft = bisectDateLeft(splitMetricsDatas, xValue);
    const d0 = splitMetricsDatas[iLeft - 1];
    const d1 = splitMetricsDatas[iLeft];

    let xLinePosition = -100;

    if (d0 && d1) {
      const d = xValue - d0.date > d1.date - xValue ? d1 : d0;

      xLinePosition = xScale(d.date);
    } else if (d0 || d1) {
      const dNull = d1 || d0;

      xLinePosition = xScale(dNull.date);
    }

    return xLinePosition + 100;
  }, [xScale, splitMetricsDatas, hoverX]);

  const getIsTooltipOverTheScreen = useCallback((viewportRect, rect, tooltipLeft) => {
    if (tooltipLeft > (viewportRect?.width - rect.width)) {
      return true;
    }

    return false;
  }, []);


  useEffect(() => {
    if (tooltipRef.current && showTooltip) {
      const rect = tooltipRef.current.getBoundingClientRect();
      const viewportElement = document.querySelector('.layout__viewport');
      const parentElement = parent
        ? document.querySelector(`.${parent}`)
        : null;
      const parentRect = parentElement?.getBoundingClientRect();
      const viewportRect = viewportElement?.getBoundingClientRect();
      if (rect && rect.width && parentRect && viewportElement) {
        const parentRectBottom = parentRect.top + parentRect.height;
        const tooltipLeft = screenX - rect.width;

        const leftScreen = tooltipLeft > defaultOffsetY ? screenX - rect.width : screenX + defaultOffsetY;
        const isTooltipOverTheScreen = getIsTooltipOverTheScreen(viewportRect, rect, tooltipLeft);
        /**
         * Небольшой хак, чтобы тултип не уходил за границы экрана, если начинает уходить, то
         * принудительно показываем его в поле экрана
         * TODO: Но на самом деле стоит переделать логику вычисления координаты Y, т.к. сейчас
         * она не учитывает позицию скролла, поэтому и возникают такие проблемы
         */
        const leftScreenWithScreenOverflow = isTooltipOverTheScreen && viewportRect?.width ?
          viewportRect?.width - rect.width - defaultOffsetY
          :
          leftScreen;

        let topPosition = screenY - defaultOffsetY;
        if (topPosition - 10 < parentRect.top) {
          topPosition = parentRect.top + 10;
        }
        if (
          topPosition + rect.height + 10 >
          parentRect.top + parentRect.height
        ) {
          topPosition = parentRectBottom - rect.height - 10;
        }
        setTooltipPos({
          leftPosition: leftScreenWithScreenOverflow,
          topPosition,
        });
      }
    }
  }, [screenX, screenY, tooltipRef, setTooltipPos, parent, showTooltip, getIsTooltipOverTheScreen]);

  /**
   * Рассчитываем к какой линии (по Y оси) ближе курсор, чтобы
   * выделить эту линию.
   * Это нельзя внести в цикл прохода по линиям, т.к. на момент формирования массива items
   * мы уже должны знать, какая линия ближе к курсору.
   */
  const nearestLine = useMemo(() => lines.reduce((acc:any, item:Line) => {
    const metricDatasFiltered = item.data;
    const xValue = xScale.invert(hoverX);

    // @ts-ignore
    const bisectDateLeft = d3.bisector(d => d.date).left;

    const iLeft = bisectDateLeft(metricDatasFiltered, xValue);
    const d0 = metricDatasFiltered[iLeft - 1];
    const d1 = metricDatasFiltered[iLeft];

    if (d0 || d1) {
      let d = d0 || d1;

      if (d0 && d1) {
        // @ts-ignore
        d = xValue - d0.date > d1.date - xValue ? d1 : d0;
      }

      // @ts-ignore
      const closestValueCoord = item.yScale(d.value);
      const lineAndHoverDiff = Math.abs(hoverY - closestValueCoord);

      if (acc?.value === null || lineAndHoverDiff < acc?.value) {
        return {
          key: item.key,
          value: lineAndHoverDiff,
        };
      }

      return acc;
    }

    return {
      key: null,
      value: null,
    };
  }, {
    key: null,
    value: null,
  }),
  [lines, hoverX, xScale, hoverY]);

  const items = useMemo(() => lines.map((item:Line) => {
    const metricDatasFiltered = item.data;
    const xValue = xScale.invert(hoverX);

    // @ts-ignore
    const bisectDateLeft = d3.bisector(d => d.date).left;

    const iLeft = bisectDateLeft(metricDatasFiltered, xValue);
    const d0 = metricDatasFiltered[iLeft - 1];
    const d1 = metricDatasFiltered[iLeft];

    if (d0 || d1) {
      let d = d0 || d1;

      if (d0 && d1) {
        // @ts-ignore
        d = xValue - d0.date > d1.date - xValue ? d1 : d0;
      }

      return {
        key: `data-popup-point-${item.key}-left`,
        value: d.value.toFixed(item.displayScale),
        date: d.date,
        name: item.title,
        description: item.description,
        isSingleGroupCheck: item.isSingleGroupCheck,
        color: item.color,
        cunit: item.cunit,
        selected: item.selected,
        // подсвечиваем метрику в тултипе если линия ближайшая к курсору (если линия только одна, не подсвечиваем)
        isClosestLine: lines?.length > 1 && nearestLine?.key === item.key,
      };
    }

    return null;
  }).filter((item:any) => item !== null),
  [lines, hoverX, xScale, nearestLine]);

  const tooltipDate = useMemo(() => (items[0] && items[0].date
    ? moment(items[0].date).tz('UTC')
    : moment.tz('UTC')), [items]);


  const date = useMemo(() => {
    let currentDate =
      tooltipDate.year() === moment().year()
        ? tooltipDate.format(
          `${formatMessage({ id: 'date.dayFullMonth' })}, HH:mm`
        )
        : tooltipDate.format(
          `${formatMessage({ id: 'date.dayFullMonth' })}, YYYY, HH:mm`
        );

    if (threshold >= 60 * 24) {
      currentDate =
        tooltipDate.year() === moment().year()
          ? tooltipDate.format(`${formatMessage({ id: 'date.dayFullMonth' })}`)
          : tooltipDate.format(
            `${formatMessage({ id: 'date.dayFullMonth' })}, YYYY`
          );
    }
    if (threshold === 60 * 24 * 7) {
      currentDate =
        tooltipDate.clone().isoWeekday(1).year() === moment().year()
          ? `${tooltipDate
            .clone()
            .isoWeekday(1)
            .format(
              `${formatMessage({ id: 'date.dayFullMonth' })}`
            )} - ${tooltipDate
            .clone()
            .isoWeekday(7)
            .format(`${formatMessage({ id: 'date.dayFullMonth' })}`)}`
          : `${tooltipDate
            .clone()
            .isoWeekday(1)
            .format(
              `${formatMessage({ id: 'date.dayFullMonth' })}, YYYY`
            )} - ${tooltipDate
            .clone()
            .isoWeekday(7)
            .format(`${formatMessage({ id: 'date.dayFullMonth' })}, YYYY`)}`;
    }

    if (threshold === 60 * 24 * 30) {
      currentDate =
        tooltipDate.year() === moment().year()
          ? tooltipDate.format('MMMM, YYYY')
          : tooltipDate.format('MMMM, YYYY');
    }
    return currentDate;
  }, [
    tooltipDate,
    formatMessage,
    threshold
  ]);

  if (!preset || lines.length === 0 || !showTooltip) {
    return null;
  }

  return (
    <div
      style={{
        left: tooltipPos.leftPosition,
        top: tooltipPos.topPosition,
      }}
      className={classnames(styles.tooltip, {
        [styles.compact]: items.length > 10,
      })}
      ref={tooltipRef}
    >
      <div className={styles.metricDate}>{date}</div>
      <table
        className={classnames(styles.tooltipTable, {
          [styles.compact]: items.length > 10,
        })}
      >
        <tbody>
          {items.map((item:any) => (
            <tr
              key={item.key}
              className={classnames({
                [styles.selected]: item.selected,
                [styles.notSelected]: !item.selected,
                [styles.closestLine]: item.isClosestLine,
              })}
            >
              <td className={styles.color}>
                <div className={styles.colorWrapper}>
                  <div
                    className={styles.colorSquare}
                    style={{ backgroundColor: item.color }}
                  />
                </div>
              </td>
              <td className={styles.metricName}>
                <div className={styles.title}>{item.name}</div>
                {!item.isSingleGroupCheck && (
                  <div className={styles.description}>
                    <div className={styles.descriptionWrapper}>
                      {item.description}
                    </div>
                  </div>
                )}
              </td>
              <td className={styles.metricValue}>
                {item.value}&nbsp;<FormattedMessage id={`cunits.mini.${item.cunit}`} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default injectIntl(GraphsTooltip);
