import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import DefaultDialog from '../../../../DefaultDialog';
import BigButton from '../../../../BigButton';

import styles from './HarvestDeleteDialog.module.css';

export default class HarvestDeleteDialog extends Component {
  static propTypes = {
    deleteHarvestData: PropTypes.func.isRequired,
    getHarvestData: PropTypes.func.isRequired,
    getCycleData: PropTypes.func.isRequired,
    handlerCloseHarvestDialog: PropTypes.func.isRequired,
    harvestId: PropTypes.number.isRequired,
    cycleId: PropTypes.string.isRequired,
  };

  handlerDeleteButton = async () => {
    const {
      cycleId, harvestId, getHarvestData, deleteHarvestData, getCycleData
    } = this.props;

    await deleteHarvestData({
      plantingCycleId: cycleId,
      harvestId,
    });

    await getCycleData(cycleId);
    await getHarvestData(cycleId);
  };

  render() {
    const {
      handlerCloseHarvestDialog,
    } = this.props;

    return (
      <DefaultDialog
        title={<FormattedMessage id='harvest.deleteHarvest' />}
        onClose={handlerCloseHarvestDialog}
        wrapperClassName={styles.wrapper}
        hideCloseIcon
      >
        <div className={styles.deleteDialog}>
          <p className={styles.deleteDialogText}>
            <FormattedMessage
              id='harvest.textDelete'
            />
          </p>
          <div className={styles.deleteButtons}>
            <BigButton
              className={styles.deleteCancel}
              onClick={handlerCloseHarvestDialog}
              title={<FormattedMessage id='harvest.cancelButton' />}
              theme='light'
            />
            <BigButton
              className={styles.deleteConfitm}
              onClick={this.handlerDeleteButton}
              title={<FormattedMessage id='harvest.confirmDelete' />}
              theme='error'
            />
          </div>
        </div>
      </DefaultDialog>
    );
  }
}
