class ButtonStyle {
  borderRadius = {
    tl: 4, bl: 4, tr: 4, br: 4
  };

  fill = '#FFFFFF'

  stroke = '#B4C5D6'

  font = 'normal normal 500 13px Roboto';

  padding = {
    l: 0, t: 0, r: 0, b: 0
  }

  textColor = '#777776'

  constructor({
    borderRadius,
    fill,
    stroke,
    font,
    padding,
    textColor
  }) {
    Object.assign(
      this,
      borderRadius ? { borderRadius } : {},
      fill ? { fill } : {},
      stroke ? { stroke } : {},
      font ? { font } : {},
      padding ? { padding } : {},
      textColor ? { textColor } : {},
    );
  }
}

export default ButtonStyle;
