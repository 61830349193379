import { find, reverse, filter } from 'lodash';
import getSorted from './getSorted';

const getSortedRows = ({
  sorting, rows = []
}) => {
  if (rows.length === 0) {
    return rows;
  }

  const sortingType = sorting?.type || 'work';
  const sortingDirection = sorting?.direction || 'up';

  // Чтобы Total вседа был в конце
  const totalRow = find(rows, { type: 'total' });
  const filteredRows = filter(rows, row => (row.type !== 'total'));

  const sortWithDirection = array => (sortingDirection === 'down' ? reverse(array) : array);

  const sortedFirstLevel = getSorted(sortingType, filteredRows);
  const sortedWithDirectionFirstLevel = sortWithDirection(sortedFirstLevel);

  const sortedRows = sortedWithDirectionFirstLevel.map(row => ({
    ...row,

    children: sortWithDirection(getSorted(sortingType, row.children)),
  }));

  return [...sortedRows, totalRow];
};

export default getSortedRows;
