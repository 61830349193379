import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import setIntl from '../../../store/localization';

import LoginLayout from '../components/LoginLayout';

const mapDispatchToProps = {
  setIntl,
};

const mapStateToProps = (state, props) => ({
  ...props,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LoginLayout));
