import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import React, { Component } from 'react';
import { NavLink as RouterLink, Switch, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import GeneralEn from './components/en/General/General';
import PoliciesEn from './components/en/Policies/Policies';
import GeneralRu from './components/ru/General/General';
import PoliciesRu from './components/ru/Policies/Policies';
import GeneralFr from './components/fr/General/General';
import PoliciesFr from './components/fr/Policies/Policies';
import GeneralNl from './components/nl/General/General';
import PoliciesNl from './components/nl/Policies/Policies';

import storageWrapper from '../../helpers/storageWrapper';

import styles from './Terms.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');


class Terms extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  render() {
    const { match } = this.props;

    const locale = safeLocalStorage.getItem('locale');

    let general = GeneralEn;
    let policies = PoliciesEn;

    switch (locale) {
      case 'ru': {
        general = GeneralRu;
        policies = PoliciesRu;
        break;
      }

      case 'nl': {
        general = GeneralNl;
        policies = PoliciesNl;
        break;
      }

      case 'fr': {
        general = GeneralFr;
        policies = PoliciesFr;
        break;
      }

      default: {
        general = GeneralEn;
        policies = PoliciesEn;
      }
    }

    return (
      <main className={styles.main}>
        <div className={styles.content}>
          <h1 className={styles.title}><FormattedMessage id='legal.termsAndPolicies' /></h1>
          <div className={styles.table}>
            <div className={classnames(styles.column, styles.columnMini)}>
              <RouterLink className={styles.link} activeClassName={styles.active} to={`/${match.params.organizationSlug}/terms/general-terms-and-conditions`}><FormattedMessage id='legal.generalTermsAndConditionsLink' /></RouterLink>
              <RouterLink className={styles.link} activeClassName={styles.active} to={`/${match.params.organizationSlug}/terms/privacy-and-cookie-policy`}><FormattedMessage id='legal.privacyAndCookiePolicyLink' /></RouterLink>
            </div>
            <div className={classnames(styles.column, styles.columnMain, styles.article)}>
              <Switch>
                <Route key='general' path='/:organizationSlug/terms/general-terms-and-conditions' exact component={general} />
                <Route key='policies' path='/:organizationSlug/terms/privacy-and-cookie-policy' exact component={policies} />
              </Switch>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(Terms);
