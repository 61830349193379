import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { requestMeasurementsByDate } from 'store/measurements/actions';
import { clearHarvestDataByCategories } from 'store/harvest/actions';
import {
  getAllFruitClasses,
  getAllVarieties,
  getAllPlantingCycles,
  getAllCompartments,
} from 'store/company/selectors';
import { showNotificationWithTimeout } from 'store/notificationCenter/actions';

import MeasurementsFormHeader from './MeasurementsFormHeader';

const mapDispatchToProps = {
  requestMeasurementsByDate,
  clearHarvestDataByCategories,
  showNotificationWithTimeout,
};

const mapStateToProps = (state, props) => ({
  ...props,

  fruitClasses: getAllFruitClasses(state, props),
  varieties: getAllVarieties(state, props),
  allPlantingCycles: getAllPlantingCycles(state),
  allCompartments: getAllCompartments(state),
  // cycle: getCurrentCycleFullInfo(state),
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(MeasurementsFormHeader)));
