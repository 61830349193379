import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { getFormValues, getFormSyncErrors } from 'redux-form';

import {
  getCreateAbnormalityIncidentFetching,
  getUpdateAbnormalityIncidentFetching,
} from 'store/climateInsights/selectors';

import AbnormalityIncidentForm from './AbnormalityIncidentForm';

const mapDispatchToProps = {
};

const mapStateToProps = (state, props) => ({
  ...props,
  isCreateAbnormalityIncidentFetching: getCreateAbnormalityIncidentFetching(state),
  isUpdateAbnormalityIncidentFetching: getUpdateAbnormalityIncidentFetching(state),
  formValues: getFormValues('abnormalityIncidentForm')(state),
  formSyncErrors: getFormSyncErrors('abnormalityIncidentForm')(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(AbnormalityIncidentForm)));
