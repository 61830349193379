export const SHOW_SIDE_PANEL = 'SHOW_SIDE_PANEL';
export const HIDE_SIDE_PANEL = 'HIDE_SIDE_PANEL';

export const showSidePanel = data => ({
  type: SHOW_SIDE_PANEL,
  payload: data,
});

export const hideSidePanel = () => ({
  type: HIDE_SIDE_PANEL,
});

export function showSidePanelAction(data) {
  return async (dispatch) => {
    await dispatch(showSidePanel(data));
  };
}

export function hideSidePanelAction() {
  return async (dispatch) => {
    await dispatch(hideSidePanel());
  };
}
