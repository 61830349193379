import { useState, useEffect } from 'react';

function getStorageValue(key, defaultValue) {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

const useLocalStorageWithTimestamp = (key, defaultValue) => {
  const timeKey = `time-${key}`;

  const [value, setValue] = useState(() => getStorageValue(key, defaultValue));
  const [timestamp] = useState(() => getStorageValue(timeKey, Date.now()));

  const clearValue = () => {
    localStorage.removeItem(key);
    localStorage.removeItem(timeKey);
  };

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
    localStorage.setItem(timeKey, JSON.stringify(Date.now()));
  }, [key, timeKey, value]);

  return [value, setValue, clearValue, timestamp];
};

export default useLocalStorageWithTimestamp;
