import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component, } from 'react';

import { isNull } from 'lodash';


import isPhoneView from 'helpers/isPhoneView';
import storageWrapper from 'helpers/storageWrapper';
import { MEDIA_DESKTOP_SMALL } from 'helpers/constants';

import DashboardHeaderIcon from 'components/DashboardHeaderIcon';
import RangePicker from 'components/RangePicker';


import styles from './DashboardHeader.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');

class DashboardHeader extends Component {
  static propTypes = {
    timezone: PropTypes.string,
    text: PropTypes.string,
    dashboardName: PropTypes.string.isRequired,
    defaultPeriodType: PropTypes.string,
    withoutRangePicker: PropTypes.bool,
    withoutDatepicker: PropTypes.bool,
    customDatePicker: PropTypes.node,
    customRanges: PropTypes.array,
    filters: PropTypes.node,
    containerClassName: PropTypes.string,
    forcedMaxDate: PropTypes.object,
    isDashboardsMenuOpened: PropTypes.bool,

    handlerAfterRangeSelect: PropTypes.func,
    setDashboardsMenu: PropTypes.func.isRequired,
  };

  static defaultProps = {
    text: null,
    defaultPeriodType: 'week',
    withoutDatepicker: false,
    withoutRangePicker: false,
    customDatePicker: null,
    customRanges: null,
    filters: null,
    containerClassName: '',
    timezone: 'Europe/London',
    forcedMaxDate: null,
    isDashboardsMenuOpened: null,
    handlerAfterRangeSelect: () => {},
  };

  constructor(props) {
    super(props);

    const { setDashboardsMenu } = this.props;

    let isDashboardsMenuOpened = safeLocalStorage.getItem('isDashboardsMenuOpened');
    const isPhoneOrTablet = isPhoneView(MEDIA_DESKTOP_SMALL);

    // По-умолчанию на десктопе меню показывается, на мобилке скрывается
    if (isNull(isDashboardsMenuOpened)) {
      isDashboardsMenuOpened = !isPhoneOrTablet;
    }

    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      isPhoneOrTablet,
    };

    setDashboardsMenu({ isDashboardsMenuOpened });
  }

  handlerToggleMenu = () => {
    const { isDashboardsMenuOpened, setDashboardsMenu } = this.props;

    const newDashboardsMenuOpened = !isDashboardsMenuOpened;

    setDashboardsMenu({ isDashboardsMenuOpened: newDashboardsMenuOpened });
  }

  renderRangePicker = (customDatePicker, timezone, handlerAfterRangeSelect) => {
    const {
      customRanges,
      withoutRangePicker,
      forcedMaxDate,
      defaultPeriodType,
    } = this.props;

    if (customDatePicker) {
      return customDatePicker;
    }

    return (
      <RangePicker
        defaultPeriodType={defaultPeriodType}
        forcedMaxDate={forcedMaxDate}
        timezone={timezone}
        handlerAfterRangeSelect={handlerAfterRangeSelect}
        customRanges={customRanges}
        withoutRangePicker={withoutRangePicker}
        withoutAddDisable
      />
    );
  };

  render() {
    const {
      dashboardName,
      withoutDatepicker,
      customDatePicker,
      timezone,
      handlerAfterRangeSelect,
      text,
      containerClassName,
      filters,
      isDashboardsMenuOpened,
    } = this.props;

    const { isPhoneOrTablet } = this.state;

    return (
      <div className={classnames(styles.container, containerClassName)}>
        <div className={styles.left}>
          <h2 className={classnames(styles.header, { [styles.marginBottom]: !!text })}>
            <DashboardHeaderIcon
              isClosed={isPhoneOrTablet || !isDashboardsMenuOpened}
              onClick={this.handlerToggleMenu}
            />
            <span className={styles.dashboardName}>
              {dashboardName}
            </span>
          </h2>
          {text && <div className={styles.text}>{text}</div>}
        </div>
        <div className={styles.right}>
          {filters || null}
          {!withoutDatepicker && this.renderRangePicker(customDatePicker, timezone, handlerAfterRangeSelect)}
        </div>
      </div>
    );
  }
}

export default DashboardHeader;
