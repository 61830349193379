import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, } from 'react';

import { ReactComponent as ToggleIcon } from './assets/icon.svg';

import styles from './DateButton.module.css';

export default class DateButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: null,
  };

  render() {
    const {
      onClick,
    } = this.props;

    return (
      <button type='button' className={classnames(styles.button)} onClick={onClick}>
        <ToggleIcon />
      </button>
    );
  }
}
