import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

// @ts-ignore
import { injectIntl, InjectedIntlProps } from 'react-intl';

import {
  Compartment,
  FruitClass,
  Metric, NodeComponents,
  PlantingCycle,
  Preset,
  Species,
  SubNode,
  TreeNode,
  TreeNodeIdTypes, TreeNodePathComponents,
  Variety
} from 'store/graphs/types';

import {
  DeletePresetMetric, DeletePresetMetricGroup,
  ITreeSearchData,
  RequestMetricsTree, ResetTreeExpandedKeys, SetGraphMetricSearch,
  SetMetricsPanelFilters, SetTreeExpandedKeys, SetTreeSearchData,
  TogglePresetMetric
} from 'store/graphs/actions';

import { buildMetricsTreeGroups, MetricGroupsViewEntity, MetricGroupView } from '../../utils/metricsGroupUtils';

import PanelMetricGroup from './components/PanelMetricGroup';
import PanelMetricSearch from './components/PanelMetricSearch';
import PanelMetricTabs from './components/PanelMetricTabs';
import PanelMetricTree from './components/PanelMetricTree';

import styles from './SelectMetricsPanel.module.css';

export type SelectMetricsPanelProps = {
  preset: Preset;
  allMetrics: Array<Metric>;
  allCompartments: Array<Compartment>;
  allSubNodes: Array<SubNode>;
  allSpecies: Array<Species>;
  allVarieties: Array<Variety>;
  allFruitClasses: Array<FruitClass>;
  allPlantingCycles: Array<PlantingCycle>;
  requestMetricsTree: RequestMetricsTree;
  setMetricsPanelFilters: SetMetricsPanelFilters;
  metricsTree: TreeNode | null;
  metricsSearchResult: Array<number> | null;
  metricsSearchData: ITreeSearchData | null;
  togglePresetMetric: TogglePresetMetric;
  deletePresetMetric: DeletePresetMetric;
  deletePresetMetricGroup: DeletePresetMetricGroup;
  expandedKeys: {
    g: Array<string | number>;
    c: Array<string | number>;
    e: Array<string | number>;
  },
  setTreeExpandedKeys: SetTreeExpandedKeys;
  resetTreeExpandedKeys: ResetTreeExpandedKeys;
  setTreeSearchData: SetTreeSearchData;
  graphMetricSearch: string;
  setGraphMetricSearch: SetGraphMetricSearch;
  metricsTreeFilter: {
    metricId: Array<number> | null;
    compartmentId: Array<number> | null;
    categoryId: Array<number> | null;
    categoryCode: Array<string> | null;
    species: Array<string> | null;
    varietyId: Array<number> | null;
  },
  isMetricsPanelFilterFetching: boolean;
};

const SelectMetricsPanel = ({
  intl,
  preset,
  allMetrics,
  allCompartments,
  allSubNodes,
  allSpecies,
  allVarieties,
  allFruitClasses,
  allPlantingCycles,
  requestMetricsTree,
  metricsTree,
  setMetricsPanelFilters,
  metricsSearchResult,
  metricsSearchData,
  togglePresetMetric,
  deletePresetMetric,
  deletePresetMetricGroup,
  setTreeExpandedKeys,
  resetTreeExpandedKeys,
  expandedKeys,
  setTreeSearchData,
  graphMetricSearch,
  setGraphMetricSearch,
  metricsTreeFilter,
  isMetricsPanelFilterFetching
} : SelectMetricsPanelProps & InjectedIntlProps) => {
  const { formatMessage, locale } = intl;

  const [loadingNodeKey, setLoadingNodeKey] = useState<string | number | undefined>(undefined);

  const [currentTab, setCurrentTab] = useState<TreeNodeIdTypes>('g');

  const metricGroups = useMemo(() => (preset ? buildMetricsTreeGroups(preset, {
      formatMessage,
      locale,
      allCompartments,
      allSubNodes,
      allVarieties,
      allFruitClasses,
      allPlantingCycles
    }) : []), [
    preset,
    formatMessage,
    locale,
    allCompartments,
    allSubNodes,
    allVarieties,
    allFruitClasses,
    allPlantingCycles
  ]);

  const handleTabSelect = useCallback((tab: TreeNodeIdTypes) => {
    setCurrentTab(tab);
  }, [setCurrentTab]);

  const handleClickTitle = useCallback((node:Array<TreeNodePathComponents>) => {
    const treeId = node[NodeComponents.TreeType];
    // @ts-ignore
    setCurrentTab(treeId);
    const expandKey = [
      node[NodeComponents.TreeType],
      node[NodeComponents.LocationId],
      node[NodeComponents.Node],
      node[NodeComponents.SubNode]
    ];
    setTreeExpandedKeys({
      treeId,
      keys: [...expandedKeys[treeId], expandKey.join('/')]
    });
    requestMetricsTree({
      ids: expandKey.join('/'),
      // preventStateUpdate: true,
      patchTree: true,
      ...metricsTreeFilter,
      actionSuccess: () => {
        setTimeout(() => {
          const elements = document.querySelectorAll(`.tree-key-${node.join('_')}`);
          if (elements.length === 1) {
            elements[0].scrollIntoView();
          }
        }, 100);
      }
    });
  }, [setCurrentTab, metricsTreeFilter, setTreeExpandedKeys, requestMetricsTree, expandedKeys]);

  return (
    <div className={styles.container}>
      <div className={styles.metrics}>
        {metricGroups.map((treeGroup: MetricGroupsViewEntity) => (
          <div className={styles.treeGroup} key={treeGroup.key}>
            {treeGroup.metricGroups.map((group:MetricGroupView, index: number) => (
              <PanelMetricGroup
                showIcon={index === 0}
                type={treeGroup.key}
                metrics={group.metrics}
                groupKey={group.key}
                allMetrics={allMetrics}
                input={group.path.input}
                deletePresetMetric={deletePresetMetric}
                deletePresetMetricGroup={deletePresetMetricGroup}
                locationId={group.path.locationId}
                onClickTitle={handleClickTitle}
              />
            ))}
          </div>
          ))}
      </div>
      <PanelMetricSearch
        allMetrics={allMetrics}
        setMetricsPanelFilters={setMetricsPanelFilters}
        metricsSearchResult={metricsSearchResult}
        requestMetricsTree={requestMetricsTree}
        metricsSearchData={metricsSearchData}
        metricsTree={metricsTree}
        resetTreeExpandedKeys={resetTreeExpandedKeys}
        setTreeSearchData={setTreeSearchData}
        setLoadingNodeKey={setLoadingNodeKey}
        graphMetricSearch={graphMetricSearch}
        setGraphMetricSearch={setGraphMetricSearch}
        metricsTreeFilter={metricsTreeFilter}
        isMetricsPanelFilterFetching={isMetricsPanelFilterFetching}
        allVarieties={allVarieties}
      />
      <PanelMetricTabs currentTab={currentTab} onTabSelect={handleTabSelect} metricsSearchData={metricsSearchData}>
        {metricsTree && (
          <>
            <PanelMetricTree
              metricGroups={metricGroups}
              selected={currentTab === 'g'}
              metricsTree={metricsTree}
              treeTypeId='Compartment'
              currentTree={currentTab}
              requestMetricsTree={requestMetricsTree}
              allCompartments={allCompartments}
              allSubNodes={allSubNodes}
              allMetrics={allMetrics}
              allSpecies={allSpecies}
              allVarieties={allVarieties}
              allFruitClasses={allFruitClasses}
              allPlantingCycles={allPlantingCycles}
              metricsSearchData={metricsSearchData}
              togglePresetMetric={togglePresetMetric}
              // expandItem={gTreeExpandFunc}
              setTreeExpandedKeys={setTreeExpandedKeys}
              expandedKeys={expandedKeys.g}
              setLoadingNodeKey={setLoadingNodeKey}
              loadingNodeKey={loadingNodeKey}
            />
            <PanelMetricTree
              metricGroups={metricGroups}
              selected={currentTab === 'c'}
              metricsTree={metricsTree}
              treeTypeId='PlantingCycle'
              currentTree={currentTab}
              requestMetricsTree={requestMetricsTree}
              allCompartments={allCompartments}
              allSubNodes={allSubNodes}
              allMetrics={allMetrics}
              allSpecies={allSpecies}
              allVarieties={allVarieties}
              allFruitClasses={allFruitClasses}
              allPlantingCycles={allPlantingCycles}
              metricsSearchData={metricsSearchData}
              togglePresetMetric={togglePresetMetric}
              // expandItem={cTreeExpandFunc}
              setTreeExpandedKeys={setTreeExpandedKeys}
              expandedKeys={expandedKeys.c}
              setLoadingNodeKey={setLoadingNodeKey}
              loadingNodeKey={loadingNodeKey}
            />
          </>
        )}
      </PanelMetricTabs>
    </div>
  );
};

export default injectIntl(SelectMetricsPanel);
