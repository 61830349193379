/**
 * TODO: компонент был скопипащен из барчарта на странице климата, в нём очень
 * много неоптимальных вещей и костылей, перед переиспользованием в других местах требует рефакторинга
 */

import * as d3 from 'd3';

import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';
import {
  get, isEqual, uniqueId, throttle
} from 'lodash';
import { intlShape, FormattedMessage, injectIntl } from 'react-intl';
import sizeMe from 'react-sizeme';

import { getTicksRange, getTicksMinMax } from 'helpers/getTicks';
import GraphsTooltip from 'components/CropsChart/components/Tooltip';

import CircleLoader from 'components/CircleLoader';
import loaderStyles from 'components/CircleLoader/CircleLoader.module.css';

import styles from './BarChart.module.css';

class BarChart extends Component {
  static DISPLAY_TYPES = {
    STACKED: 'STACKED',
    TWO_BARS: 'TWO_BARS'
  };

  static propTypes = {
    items: PropTypes.array,
    dashItems: PropTypes.array,
    tooltipItems: PropTypes.array,
    intl: intlShape.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isEmpty: PropTypes.bool.isRequired,
    size: PropTypes.object.isRequired,

    fixedHeight: PropTypes.number.isRequired,
    displayType: PropTypes.oneOf([BarChart.DISPLAY_TYPES.STACKED, BarChart.DISPLAY_TYPES.TWO_BARS]),
    barsMarginLeft: PropTypes.number,
    minHeight: PropTypes.number,
  };

  static defaultProps = {
    items: null,
    dashItems: null,
    tooltipItems: null,
    displayType: BarChart.DISPLAY_TYPES.TWO_BARS,
    barsMarginLeft: 8,
    minHeight: 1
  };


  chart = React.createRef();

  handlerResize = throttle((e, callback) => {
    if (this.chart?.current) {
      const chartStyle = window.getComputedStyle(this.chart.current, null);
      const chartWidth = parseInt(chartStyle.getPropertyValue('width'), 10);

      this.setState({
        width: chartWidth,
      }, () => {
        if (callback) {
          callback();
        } else {
          this.rebuild(this.props, this.state);
        }
      });
    }
  }, 100);

  constructor(...props) {
    super(...props);

    this.x = d3.scaleUtc();
    this.yLeft = d3.scaleLinear();
  }

  state = {
    hoverDate: null,
    showTooltip: false,
    margin: {
      top: 10,
      bottom: 28,
      left: 65,
      right: 0,
    },
    ticksCount: {
      x: 12,
      y: 3,
    },
    width: this.props.size.width || window.innerWidth - (24 * 2) - (24 * 2),
    height: this.props.fixedHeight || 269,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handlerResize);

    document.addEventListener('click', this.handlerDocumentClick);
    document.addEventListener('touchstart', this.handlerDocumentTouchStart);
    document.addEventListener('scroll', this.handlerScroll);

    setTimeout(() => {
      this.handlerResize();
    }, 0);
  }

  componentDidUpdate(prevProps) {
    const {
      items: oldItems,
      size: oldSize,
    } = prevProps;

    const {
      items: nextItems,
      size: nextSize,
    } = this.props;

    if (!isEqual(nextItems, oldItems) || oldSize.width !== nextSize.width) {
      this.handlerResize(null, () => {
        this.rebuild(this.props, this.state);
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResize);

    document.removeEventListener('click', this.handlerDocumentClick);
    document.removeEventListener('touchstart', this.handlerDocumentTouchStart);
    document.removeEventListener('scroll', this.handlerScroll);
  }


  handlerDocumentClick = async (e) => {
    const { showTooltip, hoverDate } = this.state;

    if (showTooltip && hoverDate) {
      if (!e.target.closest(`.${styles.chart}`)) {
        this.setState({
          showTooltip: false,
          hoverDate: null,
        });
      }
    }
  };

  handlerDocumentTouchStart = (e) => {
    const { touches } = e;

    if (touches && touches.length) {
      const touch = touches[0];

      const { showTooltip, hoverDate } = this.state;

      if (showTooltip && hoverDate) {
        if (!touch.target.closest(`.${styles.chart}`)) {
          this.setState({
            showTooltip: false,
            hoverDate: null,
          });
        }
      }
    }
  };

  handlerScroll = () => {
    const { hoverDate, showTooltip } = this.state;

    if (showTooltip && hoverDate) {
      this.setState({
        showTooltip: false,
        hoverDate: null,
      });
    }
  };

  handlerTouchStart = (e) => {
    const { touches } = e;

    if (touches && touches.length) {
      const { clientX, clientY } = touches[0];

      this.handlerMouseMove({
        clientX,
        clientY,
      });
    }
  };

  handlerTouchEnd = () => {
  };

  handlerTouchMove = (e) => {
    const { touches } = e;

    if (touches && touches.length) {
      const { clientX, clientY } = touches[0];

      this.handlerMouseMove({
        clientX,
        clientY,
      });
    }
  };

  handlerMouseMove = (e) => {
    const { items } = this.props;
    const rect = this.chartOverlay.getBoundingClientRect();

    const data = items;

    const xValue = this.x.invert(e.clientX - rect.left);
    const bisectDateLeft = d3.bisector(d => d.date).left;
    const iLeft = bisectDateLeft(data, xValue);
    const d0 = data[iLeft - 1];
    const d1 = data[iLeft];

    let d = null;

    if (d0 && d1) {
      d = xValue - d0.date > d1.date - xValue ? d1 : d0;
    } else if (d0 || d1) {
      d = d1 || d0;
    }

    if (d) {
      this.setState({
        hoverDate: d.date.format('YYYY-MM-DD'),
        showTooltip: true,
        screenY: e.clientY,
        screenX: e.clientX,
      });
    } else {
      this.setState({
        screenY: e.clientY,
        screenX: e.clientX,
        hoverDate: null,
        showTooltip: false,
      });
    }
  };

  handlerMouseEnter = () => {
  };

  handlerMouseLeave = () => {
    this.setState({
      showTooltip: false,
      hoverDate: null,
    });
  };

  getBarsCount = ({ items }) => {
    if (items) {
      return items.length;
    }

    return 0;
  };

  getBarsWidth = () => {
    if (window.innerWidth < 720) {
      return 12;
    }

    return 24;
  };

  getTicksCount = () => {
    if (window.innerWidth < 720) {
      return 6;
    }

    return 12;
  };

  getScaleDomain = ({ items, dashItems, scale }) => {
    if (!items || !items.length) {
      scale.domain([0, 100]);
    } else {
      const values = items.map(item => item.value);
      const dashValues = dashItems ? dashItems.map(item => item.value) : [];
      const min = 0;
      const max = Math.ceil(d3.max([...values, ...dashValues]));

      if ((min === 0 && max === 0) || (Number.isNaN(min) && Number.isNaN(max))) {
        scale.domain(d3.extent([0, 100]));
      } else if (min === max) {
        scale.domain(d3.extent([min - min, max + max]));
      } else {
        const deltaData = max - min;
        const delta = deltaData || max;

        const maxDelta = Math.ceil(delta * 0.04);

        scale.domain(d3.extent(
          [{ value: 0 }, { value: max + maxDelta }],
          d => d.value,
        ));
      }
    }
  };

  rebuild = (props, state) => {
    const {
      startDate, endDate, items, size: { width }
    } = props;
    const { margin, height } = state;
    const newMargin = {
      ...margin,
      bottom: 28,
    };

    this.calculateDomains(props, state);
    this.calculateHorizontalAxis({
      startDate,
      endDate,
      width: width - newMargin.left - newMargin.right,
      height: height - newMargin.top - newMargin.bottom,
    });
    this.renderAxises({
      width,
      threshold: this.getBarsCount({
        items,
        width: width - newMargin.left - newMargin.right,
        height: height - newMargin.top - newMargin.bottom,
      }),
    });

    this.setState({ margin: newMargin });
  };

  calculateHorizontalAxis = ({ startDate, endDate, width }) => {
    const { barsMarginLeft } = this.props;
    const fromDate = startDate.clone();
    const toDate = endDate.clone();

    this.x.domain(d3.extent([+fromDate, +toDate]))
      .range([barsMarginLeft, width]);
  };

  calculateTicksForVerticalAxis = (yDomain) => {
    const delimeter = this.state.ticksCount.y - 1;
    const min = yDomain[0];
    const max = yDomain[1];

    const ticksRange = getTicksRange(min, max, delimeter);
    const yLeftDomainRange = getTicksMinMax(ticksRange);
    this.yLeft.domain(yLeftDomainRange);

    return ticksRange;
  }

  calculateDomains = (props, state) => {
    const {
      items,
      dashItems,
    } = props;

    const { margin, height } = state;

    this.yLeft.range([height - margin.top - margin.bottom, 0]);

    if (items && items.length) {
      this.getScaleDomain({
        items,
        dashItems,
        scale: this.yLeft,
      });
    }
  }

  renderAxises = ({ width }) => {
    this.xAxisScale = d3.axisBottom()
      .scale(this.x)
      .tickFormat(date => moment(date).format("MMM 'YY"))
      .ticks(this.getTicksCount()) // количество тиков по количеству месяцев
      .tickPadding(12)
      .tickSizeInner(-4)
      .tickSizeOuter(0);

    this.yLeftAxisScale = d3.axisLeft()
      .scale(this.yLeft)
      .tickValues(this.calculateTicksForVerticalAxis(this.yLeft.domain()))
      .tickFormat(d => d3.format(',')(d).replace(/,/g, ' '))
      .tickPadding(12)
      .tickSizeInner(-width)
      .tickSizeOuter(0);

    d3.select(this.axisX)
      .call(this.xAxisScale);
    d3.select(this.axisYLeft)
      .call(this.yLeftAxisScale);
  };

  renderDoubleBars = () => {
    const {
      items,
      dashItems,
      isEmpty,
    } = this.props;

    const {
      hoverDate,
      margin,
      height: propsHeight,
    } = this.state;

    if (isEmpty) {
      return null;
    }

    if (!dashItems || dashItems.length === 0) {
      return this.renderBars();
    }

    const barsMargin = 2;
    const height = propsHeight - margin.top - margin.bottom;
    const d = items.find(({ date }) => date.format('YYYY-MM-DD') === hoverDate);
    const barsWidth = (this.getBarsWidth() / 2) + 1;

    const dashItemsView = dashItems.map(item =>
      this.drawItem(item, {
        d, height, barsWidth, marginLeft: -barsMargin, prefix: 'dash'
      }));

    const itemsView = items.map(item =>
      this.drawItem(item, {
        d, height, barsWidth, marginLeft: barsWidth + barsMargin, prefix: 'line'
      }));

    return dashItemsView.map((item, i) => [item, itemsView[i]]);
  }

  drawItem = (
    { value, date, color },
    {
      d,
      height,
      barsWidth,
      marginLeft,
      prefix,
    }
  ) => {
    const { minHeight } = this.props;
    const key = `${prefix}_${btoa(date.format())}`;

    let rectHeight = 0;
    let rectY = 0;

    if (value === null || value === 0) {
      rectY = height - minHeight;
      rectHeight = minHeight;
    } else {
      rectY = height - this.yLeft(value) < 2 ? height - 2 : this.yLeft(value);
      rectHeight = height - this.yLeft(value) < 2 ? 2 : height - this.yLeft(value);
    }

    const x = this.x(date.toDate()) + marginLeft;

    return (
      <rect
        key={key}
        className={classnames(styles.barItem, {
          [styles.hover]: d && d.date.isSame(date),
          [styles.noData]: value === null,
        })}
        x={x}
        y={rectY}
        width={barsWidth}
        height={rectHeight}
        style={{
          fill: color
        }}
        data-date={date.format('YYYY-MM-DD')}
      />
    );
  }

  renderBars = () => {
    const {
      items,
      isEmpty,
    } = this.props;

    const {
      hoverDate,
      margin,
      height: propsHeight,
    } = this.state;


    if (isEmpty) {
      return null;
    }

    const height = propsHeight - margin.top - margin.bottom;
    const d = items.find(({ date }) => date.format('YYYY-MM-DD') === hoverDate);
    const barsWidth = this.getBarsWidth();

    return items.map(item =>
      this.drawItem(item, {
        d, height, barsWidth, marginLeft: 0
      }));
  };

  renderDashItems = () => {
    const {
      dashItems,
      isEmpty,
    } = this.props;

    const {
      hoverDate,
      margin,
      height: propsHeight,
    } = this.state;

    if (isEmpty || !dashItems) {
      return null;
    }

    const height = propsHeight - margin.top - margin.bottom;

    const d = dashItems.find(({ date }) => date.format('YYYY-MM-DD') === hoverDate);

    return dashItems.map(({ value, date }) => {
      const key = btoa(date.format());

      // let rectHeight = 0;
      let rectY = 0;

      if (value === null || value === 0) {
        rectY = height - 2;
        // rectHeight = 2;
      } else {
        rectY = height - this.yLeft(value) < 2 ? height - 2 : this.yLeft(value);
        // rectHeight = height - this.yLeft(value) < 2 ? 2 : height - this.yLeft(value);
      }

      return (
        <rect
          key={key}
          className={classnames(styles.barDash, {
            [styles.hoverDash]: d && d.date.isSame(date),
            [styles.noData]: value === null,
          })}
          x={this.x(date.toDate()) - 1} // на stroke бордер -1
          y={rectY}
          width={this.getBarsWidth() + 2} // на stroke бордер +2
          height={6} // на stroke бордер + 2
          data-date={date.format('YYYY-MM-DD')}
        />
      );
    });
  };

  findHovered = (hoverDate) => {
    const { dashItems, items } = this.props;
    const findPredicate = ({ date }) => {
      const itemDate = date.format('YYYY-MM-DD');

      return itemDate === hoverDate;
    };
    const dashItem = dashItems.find(findPredicate);
    const regularItem = items.find(findPredicate);

    return dashItem?.value > regularItem?.value ? dashItem : regularItem;
  }

  render() {
    const {
      intl,
      tooltipItems,
      isFetching,
      isEmpty,
      displayType,
    } = this.props;

    const {
      margin,
      width,
      height,
      showTooltip,
      screenY,
      hoverDate,
    } = this.state;

    const func = this.x;

    const d = this.findHovered(hoverDate);

    const xLinePosition = d ? func(d.date) : -100;

    let defaultOffsetY = 0;

    if (d) {
      if (d.value) {
        defaultOffsetY = (height - margin.top - margin.bottom - this.yLeft(d.value)) + 15;
      } else {
        defaultOffsetY = 17;
      }
    }

    const tooltipItem = tooltipItems.filter(x => x.date.format('YYYY-MM-DD') === hoverDate);

    const sortedItems = tooltipItem.map(item => (
      {
        key: `data-popup-point-${uniqueId()}`,
        value: item.value || 0,
        date: d ? d.date : null,
        name: item.name,
        description: null,
        isSingleGroupCheck: true,
        color: item.color,
        cunit: item.cunit,
        selected: true,
      }
    ));

    const tooltipDate = sortedItems[0] && sortedItems[0].date ? moment(sortedItems[0].date).tz('UTC') : moment.tz('UTC');

    const date = get(d, 'date', moment());

    let wrapperOffsetX = 0;

    if (this.chart && this.chart.current) {
      const chartRect = this.chart.current.getBoundingClientRect();

      wrapperOffsetX = chartRect.left;
    }

    const screenX = xLinePosition + wrapperOffsetX + margin.left + (this.getBarsWidth() / 2);

    return (
      <div
        ref={this.chart}
        className={styles.chart}
        onTouchMove={this.handlerTouchMove}
        onTouchStart={this.handlerTouchStart}
        onTouchEnd={this.handlerTouchEnd}
        onMouseMove={this.handlerMouseMove}
        onMouseEnter={this.handlerMouseEnter}
        onMouseLeave={this.handlerMouseLeave}
      >
        {d && sortedItems && sortedItems.length > 0 && (
          <GraphsTooltip
            intl={intl}
            items={sortedItems}
            date={date}
            week={tooltipDate.isoWeek()}
            screenX={screenX}
            screenY={screenY}
            showTooltip={showTooltip}
            parent={this.chartOverlay}
            defaultOffsetY={defaultOffsetY}
            dateFormat={
              ({ date: formattedDate }) => <span className={styles.tooltipDate}>{formattedDate.format('MMMM YYYY')}</span>
            }
            isShowLabelsOnMobile
          />
        )}
        <svg
          ref={(element) => {
            this.svg = element;
          }}
          width={width}
          height={height}
        >
          <g transform={`translate(${margin.left},${margin.top})`}>
            <g
              className={styles.axisX}
              transform={`translate(12,${height - margin.top - margin.bottom})`} // 12 магическое число выведенное эмпирическим путём, пофиксить
              ref={(element) => {
                this.axisX = element;
              }}
            />
            <g
              className={classnames(styles.axisY)}
              ref={(element) => {
                this.axisYLeft = element;
              }}
            />
            <g width={width} height={height} clipPath='url(#clip)'>
              {displayType === BarChart.DISPLAY_TYPES.TWO_BARS && this.renderDoubleBars()}
              {displayType === BarChart.DISPLAY_TYPES.STACKED && this.renderBars()}
              {displayType === BarChart.DISPLAY_TYPES.STACKED && this.renderDashItems()}
            </g>
            <g>
              <rect
                width={width - margin.left - margin.right}
                height={height - margin.top - margin.bottom}
                className={classnames(styles.chartOverlay)}
                ref={(element) => {
                  this.chartOverlay = element;
                }}
              />
            </g>
          </g>
        </svg>

        {isFetching ? (
          <CircleLoader
            className={loaderStyles.circleLoader}
            iconClassName={loaderStyles.circleLoaderIcon}
          />
        ) : null}

        {isEmpty ? (
          <div className={styles.emptyState}>
            <FormattedMessage id='dashboards.noDataForSelectedPeriod' />
          </div>
        ) : null}
      </div>
    );
  }
}

export default sizeMe()(injectIntl(BarChart));
