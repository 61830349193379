import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import { includes, uniqueId } from 'lodash';

import ReactTooltip from 'react-tooltip';

import { showIntercom } from 'helpers/intercomHelpers';
import isTouchDevice from 'helpers/isTouchDevice';
import isTruncated from 'helpers/isTruncated';

import BigButton from 'components/BigButton';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import DefaultPlate from 'components/DefaultPlate';
import DefaultTable from 'components/DefaultTable';
import CheckIcon from 'components/Icons/CheckIcon';
import InfoIcon from 'components/Icons/InfoIcon';
import ClickOutside from 'components/ClickOutside';
import PageViewTracker from 'components/PageViewTracker';

import tooltipStyles from 'components/Tooltip/index.module.css';
import styles from './SettingsPermissions.module.css';

// TODO: Move into universal component
function GreenhouseListDropdown({
  intl,
  userData,
  handleClickOutside,
  allCompartments,
  getCellRef,
}) {
  const { formatMessage } = intl;
  const { id } = userData.user;
  const ref = getCellRef(id);
  const bounds = ref?.getBoundingClientRect();
  const TOP_PADDING = 35;

  const renderGreenhouseList = (user, compartments) => {
    const greenhouses = user?.greenhousesAccess?.greenhouses || compartments;
    return greenhouses.map(greenhouse => (
      <div className={styles.greenhouse} key={`greenhouse-${uniqueId()}`}>
        {compartments.find(comp => comp.id === greenhouse.id)?.attributes?.name}
      </div>
    ));
  };

  return createPortal(
    <ClickOutside
      handleClickOutside={handleClickOutside}
    >
      <div
        className={styles.tooltipStyle}
        style={{
          top: bounds.y + window.scrollY + TOP_PADDING,
          left: bounds.x
        }}
      >
        <div className={styles.tooltipTitle}>{formatMessage({ id: 'settings.greenhousesList' })}</div>
        {renderGreenhouseList(userData, allCompartments)}
      </div>
    </ClickOutside>,
    document.body
  );
}

export default class SettingsPermissions extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    userPrivileges: PropTypes.array,
    isUserPrivilegesFetching: PropTypes.bool.isRequired,
    requestUserPrivileges: PropTypes.func.isRequired,
    allCompartments: PropTypes.array,
    isEssentialPricePlan: PropTypes.bool.isRequired,
    trackPageViewSettings: PropTypes.func.isRequired,
    defaultOpen: PropTypes.bool,
  };

  static defaultProps = {
    userPrivileges: [],
    allCompartments: [],
    defaultOpen: false,
  };

  state = {
    openedDropdownId: null,
  };

  tableRef = new Map();

  userInfoRef = new Map();

  componentDidMount() {
    const { requestUserPrivileges } = this.props;

    requestUserPrivileges();
  }

  handlerPageView = () => {
    const { trackPageViewSettings, defaultOpen } = this.props;

    trackPageViewSettings({ settingName: 'Permissions', defaultOpen });
  };

  renderHeaderRows = (tooltipId) => {
    const { intl: { formatMessage }, isEssentialPricePlan } = this.props;

    return (
      <tr>
        <th className={styles.nameColumn}>{formatMessage({ id: 'settings.name' })}</th>
        <th className={styles.greenhouseName}>{formatMessage({ id: 'settings.compartmentsAccess' })}</th>
        <th className={styles.longTh}>{formatMessage({ id: 'plantingCycles.plantingCycles' })}</th>
        <th>
          <div
            className={styles.infoBlock}
            data-tip={formatMessage({ id: 'settings.plantsTooltip' })}
            data-for={tooltipId}
          >
            {formatMessage({ id: 'settings.plants' })}
            <InfoIcon className={styles.infoIcon} />
          </div>
        </th>
        <th>{formatMessage({ id: 'dashboards.harvest' })}</th>
        <th>{formatMessage({ id: 'header.links.plans' })}</th>
        {!isEssentialPricePlan && (
          <>
            <th className={styles.longTh}>{formatMessage({ id: 'forecast.harvestForecasts' })}</th>
            <th className={styles.longTh}>
              <div
                className={styles.infoBlock}
                data-tip={formatMessage({ id: 'forecast.settings.forecastsPublishTooltip' })}
                data-for={tooltipId}
              >
                {formatMessage({ id: 'forecast.settings.forecastsPublish' })}
                <InfoIcon className={styles.infoIcon} />
              </div>
            </th>

            <th>{formatMessage({ id: 'dashboards.benchmarking' })}</th>
            <th>{formatMessage({ id: 'dashboards.energy' })}</th>
            <th>{formatMessage({ id: 'dashboards.labor' })}</th>
          </>
        )}
      </tr>
    );
  };

  renderCheckbox = (privileges, section) => {
    const isChecked = includes(privileges, section);

    return (
      <div
        className={classnames(
          styles.checkboxIcon,
          {
            [styles.checkboxIconActive]: isChecked
          }
        )}
      >
        {isChecked && <CheckIcon />}
      </div>
    );
  };

  showDropdown = (id) => {
    if (this.state.openedDropdownId !== id) {
      this.setState({
        openedDropdownId: id,
      });
    }
  }

  hideDropdown = () => this.setState({
    openedDropdownId: null,
  });

  handleClickAccess = (id) => {
    this.showDropdown(id);
  }

  handleClickOutside = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.hideDropdown();
  }

  renderAccess = (userData) => {
    const { intl: { formatMessage, formatPlural } } = this.props;

    let message = null;

    if (userData?.greenhousesAccess?.allGreenhouses) {
      message = formatMessage({ id: 'settings.allCompartments' });
    } else {
      const greenhouses = userData?.greenhousesAccess?.greenhouses || [];
      message = `${greenhouses.length} ${formatMessage({
        id: `dataQuality.greenhouses.${formatPlural(greenhouses.length)}`
      })} `;
    }

    return (
      <span
        role='button'
        tabIndex={0}
        className={styles.compartmentsLink}
        onClick={() => {
          this.handleClickAccess(userData.user.id);
        }}
      >
        {message}
      </span>
    );
  };

  renderName = (userData) => {
    const { intl: { formatMessage } } = this.props;
    const { user } = userData;
    const isMobile = window.innerWidth < 720;
    const userInfo = `${user.company} • ${isMobile ? '<br/>' : ''} ${user.title}`;
    const userInfoTooltipId = `userInfo-tooltip-${userData.user.id}`;
    const tooltipData = {
      'data-for': userInfoTooltipId,
      'data-tip': ''
    };
    const is2FA = user?.factor2;
    const twoFaTooltipId = uniqueId('2FA-bage-tooltip-');

    return (
      <div>
        <div className={styles.userName}>
          <span className={styles.userNameText}>{user.name}</span>
          {is2FA && (
            <>
              <span
                className={styles.twoFaBage}
                data-for={twoFaTooltipId}
                data-tip={formatMessage({ id: 'settings.2faTooltip' })}
              >
                {formatMessage({ id: 'settings.2fa' })}
              </span>
              <ReactTooltip
                className={classnames(tooltipStyles.smallTooltip)}
                id={twoFaTooltipId}
                effect='solid'
                event={isTouchDevice() ? 'click' : null}
                html
              />
            </>
          )}
        </div>
        {user?.company && user?.title && (
          <div
            className={styles.userInfo}
            {...tooltipData}
            ref={(ref) => {
              const key = user.id;
              if (this.userInfoRef.get(key)) {
                this.userInfoRef.delete(key);
              }
              this.userInfoRef.set(key, ref);
            }}
          >
            {user.company} • {user.title}
            <ReactTooltip
              className={classnames(tooltipStyles.smallTooltip)}
              id={userInfoTooltipId}
              effect='solid'
              event={isTouchDevice() ? 'click' : null}
              html
              getContent={() => (this.isTextEllipsis(user.id) ? userInfo : undefined)}
            />
          </div>
        )}
      </div>
    );
  }

  getCellRef = key => this.tableRef.get(key);

  isTextEllipsis = (id) => {
    const ref = this.userInfoRef.get(id);
    return ref && isTruncated(ref);
  }

  renderPrivileges = (userPrivileges) => {
    const { intl, allCompartments, isEssentialPricePlan } = this.props;

    return userPrivileges.map(userData => (
      <tr key={`userRow-${userData.user.id}`}>
        <td className={styles.nameColumn}>{this.renderName(userData)}</td>
        <td
          className={styles.greenhouseName}
          ref={(ref) => {
            const key = userData.user.id;
            if (this.tableRef.get(key)) {
              this.tableRef.delete(key);
            }
            this.tableRef.set(userData.user.id, ref);
          }}
        >
          {this.renderAccess(userData)}
          {this.state.openedDropdownId === userData.user.id && (
            <GreenhouseListDropdown
              {...{
                intl,
                userData,
                handleClickOutside: this.handleClickOutside,
                allCompartments,
                getCellRef: this.getCellRef,
              }}
            />
          )}
        </td>
        <td>{this.renderCheckbox(userData.privileges, 'PLANTING_CYCLES')}</td>
        <td>{this.renderCheckbox(userData.privileges, 'PLANTS')}</td>
        <td>{this.renderCheckbox(userData.privileges, 'HARVEST')}</td>
        <td>{this.renderCheckbox(userData.privileges, 'PLANS')}</td>
        {!isEssentialPricePlan && (
          <>
            <td>{this.renderCheckbox(userData.privileges, 'HARVEST_FORECAST_EDIT')}</td>
            <td>{this.renderCheckbox(userData.privileges, 'HARVEST_FORECAST_PUBLISH')}</td>
            <td>{this.renderCheckbox(userData.privileges, 'BENCHMARK')}</td>
            <td>{this.renderCheckbox(userData.privileges, 'ENERGY')}</td>
            <td>{this.renderCheckbox(userData.privileges, 'LABOR')}</td>
          </>
        )}
      </tr>
    ));
  }

  render() {
    const { intl: { formatMessage }, isUserPrivilegesFetching, userPrivileges } = this.props;
    const tooltipId = `Table-header-${uniqueId()}`;

    return (
      <div className={styles.settingsPermission}>
        <PageViewTracker onMount={this.handlerPageView} />

        {isUserPrivilegesFetching && <DefaultCircleLoader />}

        <h2 className={styles.header2}>
          {formatMessage({ id: 'settings.permissions' })}
        </h2>

        <DefaultPlate className={styles.infoPlate}>
          <div>
            {formatMessage({ id: 'settings.toChange' })}
            <BigButton
              className={styles.supportButton}
              onClick={showIntercom}
              title={formatMessage({ id: 'harvestDashboard.contactSupport' })}
              theme='transparent'
            />
          </div>
        </DefaultPlate>
        <h3 className={styles.header3}>
          {formatMessage({ id: 'settings.permissionsSubHeader' })}
        </h3>
        <div className={styles.tableWrapper}>
          <DefaultTable
            headerRows={this.renderHeaderRows(tooltipId)}
            bodyRows={this.renderPrivileges(userPrivileges)}
          />
        </div>
        <ReactTooltip
          className={tooltipStyles.smallTooltip}
          id={tooltipId}
          effect='solid'
          html
        />
      </div>
    );
  }
}
