import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import classnames from 'classnames';
import { get, find } from 'lodash';

import Select from '../Select';
import Checkbox from '../Checkbox';
import BigButton from '../BigButton';

import styles from './DefaultSelectWithChecks.module.css';

class DefaultSelectWithChecks extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    options: PropTypes.array.isRequired, // Обязательно должны содержать поля label, valuе и checked
    onChange: PropTypes.func.isRequired, // Желательно заворачивать в throttle
    classNameButton: PropTypes.string,
    placeholderText: PropTypes.string,
    pluralPlaceholderId: PropTypes.oneOfType([ // Передавать, если нет placeholderText
      PropTypes.string,
      PropTypes.node
    ]),
    className: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    size: PropTypes.string,
    customTitle: PropTypes.string,
    customPlaceholder: PropTypes.string,
    closeOnChange: PropTypes.bool,
    isTitleEnabled: PropTypes.bool,
    selectComponent: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),
    dropdownRightAlign: PropTypes.string,
    possibleSelectAll: PropTypes.bool,
    popupClassName: PropTypes.string,
  };

  static defaultProps = {
    placeholderText: null,
    pluralPlaceholderId: null,
    className: null,
    value: undefined,
    customTitle: undefined,
    customPlaceholder: undefined,
    size: 'medium',
    closeOnChange: undefined,
    isTitleEnabled: false,
    selectComponent: Select,
    classNameButton: null,
    dropdownRightAlign: false,
    possibleSelectAll: true,
    popupClassName: null
  };

  getPlaceholderText = (isAllChecked) => {
    const {
      intl: { formatMessage, formatPlural },
      options,
      placeholderText,
      pluralPlaceholderId,
    } = this.props;

    if (placeholderText) {
      return placeholderText;
    }

    if (isAllChecked && pluralPlaceholderId) {
      const pluralPlaceholderTextAll = formatMessage({ id: `${pluralPlaceholderId}.other` });

      return `${formatMessage({ id: 'button.all' })} ${pluralPlaceholderTextAll.toLowerCase()}`;
    }

    const countCheckedOptions = options.reduce((acc, option) => (option.checked ? acc + 1 : acc), 0);

    if (pluralPlaceholderId && (countCheckedOptions > 1 || countCheckedOptions === 0)) {
      const pluralPlaceholderText = formatMessage({ id: `${pluralPlaceholderId}.${formatPlural(countCheckedOptions)}` });

      return `${countCheckedOptions} ${pluralPlaceholderText.toLowerCase()}`;
    }

    if (pluralPlaceholderId && countCheckedOptions === 1) {
      const checkedOption = find(options, { checked: true });

      return get(checkedOption, 'label');
    }

    // return 'Nothing selected';
    return null;
  };

  getUpdatedArray = (arr, item, newItem) => arr.map(arrItem => (arrItem.value === item.value ? { ...item, ...newItem } : arrItem));

  checkIfAllChecked = options => options.every(option => option.checked);

  handlerAllCheckbox = (isAllChecked, options) => {
    const { onChange } = this.props;

    if (isAllChecked) {
      return onChange(options.map(option => ({ ...option, checked: false })));
    }

    return onChange(options.map(option => ({ ...option, checked: true })));
  };

  handlerOnlyClick = (options, onlyValue) => {
    const { onChange } = this.props;
    const newOptions = options.map(option => (
      onlyValue === option.value ? { ...option, checked: true } : { ...option, checked: false }
    ));

    return onChange(newOptions);
  };

  renderOptions = options => options.map((option) => {
    const { intl: { formatMessage }, onChange } = this.props;

    return (
      <div key={`option-${option.value}`} className={styles.checkboxItem}>
        <Checkbox
          onChange={() => onChange(this.getUpdatedArray(options, option, { checked: !option.checked }))}
          checked={option.checked}
          value={option.value}
          title={option.label}
          alignCenter
          noWrap
        />
        <BigButton
          className={styles.onlyButton}
          onClick={() => this.handlerOnlyClick(options, option.value)}
          theme='transparent'
          title={formatMessage({ id: 'button.only' })}
        />
      </div>
    );
  });

  render() {
    const {
      intl: { formatMessage }, options, className, selectComponent,
      value, customTitle, closeOnChange, size, customPlaceholder,
      isTitleEnabled, dropdownRightAlign, classNameButton, possibleSelectAll, popupClassName
    } = this.props;

    const isAllChecked = this.checkIfAllChecked(options);

    const ParentComponent = selectComponent;

    return (
      <div className={classnames(styles.selectWrapper, className)}>
        <ParentComponent
          classNameButton={classNameButton}
          classNamePopup={classnames(styles.selectPopup, {
            [styles.dropdownRightAlign]: dropdownRightAlign
          }, popupClassName)}
          placeholder={customPlaceholder || this.getPlaceholderText(isAllChecked)}
          theme='transparent'
          size={size}
          textEllipsis
          value={value}
          customTitle={customTitle || (isTitleEnabled ? this.getPlaceholderText(isAllChecked) : null)}
          closeOnChange={closeOnChange}
        >
          <div className={styles.checkboxesContainer}>
            {possibleSelectAll && (
              <div className={classnames(styles.checkboxItem, styles.allCheckbox)}>
                <Checkbox
                  onChange={() => this.handlerAllCheckbox(isAllChecked, options)}
                  checked={isAllChecked}
                  value='all'
                  title={formatMessage({ id: 'button.all' })}
                  alignCenter
                  noWrap
                />
              </div>
            )}
            {this.renderOptions(options)}
          </div>
        </ParentComponent>
      </div>
    );
  }
}

export default injectIntl(DefaultSelectWithChecks);
