import {
  Metric,
  MetricDef,
  NodeComponents,
  Preset,
  PresetMetricScale,
} from 'store/graphs/types';

function addMetricsToPreset(dst: Preset, {
  presetMetric,
  scale
} : {
  presetMetric: MetricDef,
  scale: PresetMetricScale | null
}, metrics: Array<Metric>): void {
  const metric = metrics.find(item => item.id === presetMetric.node[NodeComponents.MetricId]);
  // TODO: Check duplicates
  dst.preset.presetMetrics.push({
    scale: scale?.name || null,
    available: presetMetric.available,
    color: presetMetric.color || null,
    selected: presetMetric.selected,
    node: [...presetMetric.node],
  });
  // TODO: Check duplicates
  if (scale) {
    dst.preset.scales.push(scale);
  }
  // @ts-ignore
  dst.metrics.push({
    // @ts-ignore
    id: presetMetric.node[NodeComponents.MetricId],
    ...metric?.attributes
  });
}

export default addMetricsToPreset;
