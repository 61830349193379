import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';

import classnames from 'classnames';
import { uniqueId, isUndefined } from 'lodash';

import DefaultOverlay from '../DefaultOverlay';
import MenuIcon from '../Icons/MenuIcon';

import styles from './SidePageMenu.module.css';

class SidePageMenu extends Component {
  static propTypes = {
    menuItems: PropTypes.array.isRequired,
    children: PropTypes.object.isRequired,
    header: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    minWidth: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    hiddenOnDesktop: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    menuClassName: PropTypes.string,
    overlayClassName: PropTypes.string,
    history: PropTypes.object.isRequired,
    outsideOpenedState: PropTypes.bool,
    onOverlayClick: PropTypes.func,
    onMenuIconClick: PropTypes.func,
  };

  static defaultProps = {
    header: null,
    minWidth: null,
    hiddenOnDesktop: false,
    className: null,
    contentClassName: null,
    menuClassName: null,
    overlayClassName: null,
    outsideOpenedState: undefined,
    onOverlayClick: undefined,
    onMenuIconClick: undefined,
  };

  state = {
    menuOpenedState: false,
  };

  handlerMenuIconClick = () => {
    const { onMenuIconClick } = this.props;

    this.setState({ menuOpenedState: true });

    if (onMenuIconClick) {
      onMenuIconClick();
    }
  };

  handlerMenuItemClick = (menuItemOnClick) => {
    this.setState({ menuOpenedState: false });

    if (menuItemOnClick) {
      menuItemOnClick();
    }
  };

  handlerOverlayClick = () => {
    const { onOverlayClick } = this.props;

    this.setState({ menuOpenedState: false });

    if (onOverlayClick) {
      onOverlayClick();
    }
  };

  handleMenuTopLevelClick = url => (e) => {
    e.preventDefault();
    this.props.history.push(url);
  }

  renderNavLink = link => (
    <NavLink
      key={uniqueId('side-page-menu-item-link-')}
      to={link.href}
      className={styles.menuItemLink}
      activeClassName={styles.menuItemLinkActive}
      onClick={() => this.handlerMenuItemClick(link.onClick)}
    >
      {link.name}
    </NavLink>
  );

  renderSubLevelMenuItems = link => (
    <div key={uniqueId('side-page-menu-item-link-')} className={classnames(styles.parentMenuItemLink, styles.pointer)}>
      <span
        onClick={this.handleMenuTopLevelClick(link.links[0].href)}
        role='button'
        tabIndex='0'
      >
        {link.name}
      </span>
      <div className={styles.childs}>
        {this.renderLinks(link.links)}
      </div>
    </div>
  );

  renderLinks = links => links.map(link => (
    link.href && !link.items ?
      this.renderNavLink(link) : this.renderSubLevelMenuItems(link)
  ));

  renderMenuItems = menuItems => (
    <>
      {menuItems.map(item => (
        <div className={styles.menuItem} key={uniqueId('side-page-menu-item-')}>
          {item.header &&
            <div className={styles.menuItemHeader}>{item.header}</div>}
          {item.links &&
            this.renderLinks(item.links)}
        </div>
      ))}
    </>
  );

  render() {
    const {
      header,
      menuItems,
      children,
      minWidth,
      hiddenOnDesktop,
      className,
      contentClassName,
      menuClassName,
      overlayClassName,
      outsideOpenedState,
    } = this.props;
    const { menuOpenedState } = this.state;

    const menuOpened = isUndefined(outsideOpenedState) ? menuOpenedState : outsideOpenedState;

    return (
      <main
        className={classnames(
          className,
          styles.main,
          { [styles.menuOpened]: menuOpened }
        )}
      >
        {header && (
          <h1
            className={classnames(
              styles.header,
              {
                [styles.menuOpened]: menuOpened,
                [styles.hiddenOnDesktop]: hiddenOnDesktop,
              }
            )}
          >
            <div
              className={styles.menuIconWrapper}
              onClick={this.handlerMenuIconClick}
              role='button'
              tabIndex={0}
            >
              <MenuIcon className={styles.menuIcon} />
            </div>
            {header}
          </h1>
        )}
        <div className={styles.pageLayout}>
          <nav
            className={classnames(
              styles.menu,
              menuClassName,
              { [styles.menuOpened]: menuOpened }
            )}
          >
            {this.renderMenuItems(menuItems)}
          </nav>
          <div
            className={classnames(
              contentClassName,
              styles.content,
              { [styles.menuOpened]: menuOpened }
            )}
            style={minWidth ? { minWidth } : null} // overflow: 'scroll'
          >
            {children}
          </div>
        </div>
        <DefaultOverlay
          isVisible={menuOpened}
          onClick={this.handlerOverlayClick}
          className={overlayClassName}
        />
      </main>
    );
  }
}

export default withRouter(SidePageMenu);
