import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import Typography from 'components/Typography';
import BigButton from 'components/BigButton';

import styles from './CropCompareEmpty.module.css';

const CropCompareEmpty = ({
  intl,
  organizationSlug,
  actionAfterLinkClick,
  hrefParams,
}) => {
  const { formatMessage } = intl;

  return (
    <div className={styles.emptyWrapper}>
      <Typography variant='h3'>
        {formatMessage({ id: 'benchmarking.noCropsToCompare' })}
      </Typography>
      <Typography variant='subtitle3' className={styles.subtitle}>
        {formatMessage({ id: 'benchmarking.emptyHeaderContropDescription' })}
      </Typography>
      <BigButton
        className={styles.openButton}
        title={formatMessage({ id: 'benchmarking.openCropsCompare' })}
        onClick={actionAfterLinkClick}
        href={`/${organizationSlug}/crops/compare${hrefParams}`}
        theme='dark'
      />
    </div>
  );
};

CropCompareEmpty.propTypes = {
  intl: intlShape.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  actionAfterLinkClick: PropTypes.func.isRequired,
  hrefParams: PropTypes.string,
};

CropCompareEmpty.defaultProps = {
  hrefParams: '',
};

export default injectIntl(CropCompareEmpty);
