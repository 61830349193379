import classnames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ReactTooltip from 'react-tooltip';
import React, { Component, } from 'react';
import { withRouter } from 'react-router';
import { injectIntl, intlShape } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import { getProductTypeName } from '../../../../helpers/getVarietyName';
import getMinMaxDates from '../../../../helpers/getMinMaxDates';
import normalizeCycle from '../../../../helpers/normalizeCycle';
import storageWrapper from '../../../../helpers/storageWrapper';
import DatePicker from '../../../DatePicker';

import styles from './HarvestFormHeader.module.css';
import tooltipStyles from '../../../Tooltip/index.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');


class HarvestFormHeader extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    clearHarvestDataByCategories: PropTypes.func.isRequired,
    cycle: PropTypes.object,
    organization: PropTypes.object,
    history: PropTypes.object.isRequired,
    periodType: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
  };

  static defaultProps = {
    organization: null,
    cycle: null,
  };

  handlerDateChange = ({ date, periodType }) => {
    const { history, clearHarvestDataByCategories } = this.props;

    const newParams = {
      date: date.format('YYYY-MM-DD'),
      periodType,
    };

    const oldQuery = get(history, 'location.search');
    const parsedQuery = queryString.parse(oldQuery);
    const newQuery = { ...parsedQuery, ...newParams };
    const newSearch = queryString.stringify(newQuery);
    const searchString = newSearch ? `?${newSearch}` : '';

    clearHarvestDataByCategories();


    safeLocalStorage.setItem('periodType', periodType);

    history.push({
      search: searchString
    });
  };

  render() {
    const {
      periodType,
      date,
      intl,
      cycle,
      organization,
    } = this.props;

    const {
      formatMessage,
      locale,
    } = intl;

    const currentCycle = normalizeCycle(cycle, locale);

    const {
      startDate,
      endDate,
      compartmentName,
    } = currentCycle;

    const { minDate, maxDate } = getMinMaxDates({ startDate, endDate });

    const productTypeName = getProductTypeName({
      intl,
      variety: get(cycle, 'variety'),
      fruitClass: get(cycle, 'fruitClass'),
      targetWeight: get(cycle, 'cycle.targetWeight'),
      withSpecies: true,
    });

    const fullName = `${productTypeName}, ${compartmentName}`;

    return (
      <div className={classnames(styles.header)}>
        <div className={styles.headerPartColumn}>
          <Switch>
            <Route
              key='edit-harvest-title'
              path='/:organizationSlug/crops/:cycleId/edit-harvest'
              exact
              render={() => <h1 className={styles.headerTitle}>{formatMessage({ id: 'harvest.editHarvest' })}</h1>}
            />
            <Route
              key='add-harvest-title'
              path='/:organizationSlug/crops/:cycleId/add-harvest'
              exact
              render={() => <h1 className={styles.headerTitle}>{formatMessage({ id: 'harvest.harvestAdd' })}</h1>}
            />
          </Switch>
          <h2 className={styles.headerDescription} data-for='harvest-form-header-tooltip' data-tip={fullName}>{fullName}</h2>

          <ReactTooltip
            className={classnames(tooltipStyles.smallTooltip, styles.headerTooltip)}
            id='harvest-form-header-tooltip'
            effect='solid'
            place='bottom'
          >
            {fullName}
          </ReactTooltip>
        </div>
        <div className={styles.headerPartRow}>
          <DatePicker
            className={styles.headerRangePicker}
            intl={intl}
            date={date}
            periodType={periodType}
            organization={organization}
            maxDate={maxDate}
            minDate={minDate}
            handlerAfterRangeSelect={this.handlerDateChange}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(HarvestFormHeader));
