import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import Typography from 'components/Typography';

import styles from './IncidentConfiguration.module.css';

const IncidentConfiguration = ({
  intl: { formatMessage },
  configuration
}) => {
  if (!configuration) {
    return null;
  }

  return (
    <div className={styles.configuration}>
      <Typography variant='h3' className={styles.configurationHeader}>
        {formatMessage({ id: 'incidentsSettings.configuration' })}
      </Typography>

      <div className={styles.configurationText}>
        <div
          className={styles.incidentLogic}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: configuration }}
        />
      </div>
    </div>
  );
};

IncidentConfiguration.propTypes = {
  intl: intlShape.isRequired,
  configuration: PropTypes.string,
};

IncidentConfiguration.defaultProps = {
  configuration: undefined,
};

export default injectIntl(IncidentConfiguration);
