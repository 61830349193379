import { Observable } from 'rxjs';
import initAxiosInstanse from 'api/axios';
import { get } from 'lodash';

import { showNotificationWithTimeout } from 'store/notificationCenter/actions';

import {
  receiveGetUnrealizedPotential,
  receiveGetUnrealizedPotentialArguments,
  requestGetUnrealizedPotentialArguments,
  requestGetUnrealizedPotential
} from './actions';

export const getUnrealizedPotentialEpic = (action$, store) =>
  action$.ofType(requestGetUnrealizedPotential)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();
      const {
        payload: {
          plantingCycleId
        }
      } = actionData;

      const searchParams = get(router, 'location.search');

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/unrealized-potential/${plantingCycleId}`))
        .map(({ data }) => receiveGetUnrealizedPotential(data))
        .catch(() => Observable.of(
          receiveGetUnrealizedPotential(),
          showNotificationWithTimeout({
            id: `notifications.getUnrealizedPotentialError.${Date.now()}`,
            messageId: 'notifications.getUnrealizedPotentialError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getUnrealizedPotentialArgumentsEpic = (action$, store) =>
  action$.ofType(requestGetUnrealizedPotentialArguments)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();
      const {
        payload: {
          plantingCycleId
        }
      } = actionData;

      const searchParams = get(router, 'location.search');

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/unrealized-potential/${plantingCycleId}/arguments`))
        .map(({ data }) => receiveGetUnrealizedPotentialArguments(data))
        .catch(() => Observable.of(
          receiveGetUnrealizedPotentialArguments(),
          showNotificationWithTimeout({
            id: `notifications.getUnrealizedPotentialArgumentsEpic.${Date.now()}`,
            messageId: 'notifications.getUnrealizedPotentialArgumentsEpic',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });
