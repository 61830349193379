import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { get, max } from 'lodash';

import { getDefaultDateRange } from 'helpers/defaultDates';
import DashboardSectionHeader from 'components/DashboardSectionHeader';
import MeterTypeGraph from '../MeterTypeGraph';

const DEFAULT_DATE_RANGE = getDefaultDateRange();

class DashboardReportSelectedChart extends Component {
  static propTypes = {
    intl: intlShape.isRequired,

    selectedRow: PropTypes.object,

    isDataFetching: PropTypes.bool,
    periodType: PropTypes.string,
    currentUnitKind: PropTypes.string,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    allPlantingCycles: PropTypes.array.isRequired,
    productGroups: PropTypes.array.isRequired,
    workTypes: PropTypes.array,
    currentBreakdown: PropTypes.string,
    currentSpecies: PropTypes.string,
    currentVarietyId: PropTypes.number,
    currentFruitClassCode: PropTypes.string,
    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
  };

  static defaultProps = {
    workTypes: null,
    currentBreakdown: 'compartment',
    currentSpecies: null,
    currentVarietyId: null,
    currentFruitClassCode: null,
    selectedRow: null,
    isDataFetching: false,
    periodType: 'month',
    currentUnitKind: 'absolute',
    anyDateOfPeriodStart: DEFAULT_DATE_RANGE.startDate,
    anyDateOfPeriodEnd: DEFAULT_DATE_RANGE.endDate,
  };

  render() {
    const {
      intl,
      periodType,
      varieties,
      compartments,
      fruitClasses,
      selectedRow,
      currentUnitKind,
      isDataFetching,
      allPlantingCycles,
      productGroups,
      workTypes,
      currentBreakdown,
      currentSpecies,
      currentVarietyId,
      currentFruitClassCode,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd
    } = this.props;

    const { formatMessage } = intl;

    const roundTo = currentUnitKind === 'absolute' ? 1 : 2;

    const data = get(selectedRow, 'data', {});
    const values = Object.values(data);
    const maxValue = max(values) || 100;

    return (
      <>
        <DashboardSectionHeader headerText={formatMessage({ id: 'dashboards.plannedWorksByPeriods' })} />

        <MeterTypeGraph
          points={selectedRow}
          maxValue={maxValue}
          periodType={periodType}
          roundTo={roundTo}
          varieties={varieties}
          fruitClasses={fruitClasses}
          compartments={compartments}
          isDataFetching={isDataFetching}
          allPlantingCycles={allPlantingCycles}
          productGroups={productGroups}
          workTypes={workTypes}
          currentBreakdown={currentBreakdown}
          currentSpecies={currentSpecies}
          currentVarietyId={currentVarietyId}
          currentFruitClassCode={currentFruitClassCode}
          anyDateOfPeriodStart={anyDateOfPeriodStart}
          anyDateOfPeriodEnd={anyDateOfPeriodEnd}
        />
      </>
    );
  }
}

export default injectIntl(DashboardReportSelectedChart);
