/* eslint-disable import/prefer-default-export */

import { Observable } from 'rxjs';

import { REMOVE_NOTIFICATION, } from './actions';
import { receiveUser } from '../company/actions';

import initAxiosInstanse from '../../api/axios';

export const removeNotificationEpic = (action$, store) =>
  action$.ofType(REMOVE_NOTIFICATION)
    .switchMap((actionData) => {
      const {
        id,
      } = actionData;

      const {
        company,
      } = store.getState();

      const { user } = company;

      if (id === 'legal') {
        return Observable.from(initAxiosInstanse().post('/user-profile/accept-cookie-policy'))
          .mergeMap(({ status }) => {
            if (status === 200) {
              user.attributes.cookiePolicyAccepted = true;

              return Observable.of(receiveUser(user));
            }

            return Observable.empty();
          })
          .catch(() => Observable.empty());
      }
      return Observable.empty();
    });
