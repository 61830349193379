import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { get, uniqueId } from 'lodash';

import ClickOutside from '../ClickOutside';
import DefaultSearchList from '../DefaultSearchList';

import { ReactComponent as ArrowDown } from './assets/arrow_down.svg';

import styles from './Select.module.css';

// TODO: Заменить дефолтный путь до лейбла и значения на label и value (гооораздо чаще встречаются чем текущие)
export default class Select extends Component {
  static propTypes = {
    className: PropTypes.string,
    classNameButton: PropTypes.string,
    classNamePopup: PropTypes.string,
    classNameOption: PropTypes.string,
    labelPath: PropTypes.string,
    valuePath: PropTypes.string,
    buttonLabelPath: PropTypes.string,
    disabled: PropTypes.bool,
    isPopupRight: PropTypes.bool,
    isSearchable: PropTypes.bool,
    open: PropTypes.bool,
    closeOnChange: PropTypes.bool,
    withBigArrow: PropTypes.bool,
    value: PropTypes.any,
    error: PropTypes.string,
    placeholder: PropTypes.any,
    empty: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    onOpenChange: PropTypes.func,
    onRenderArrow: PropTypes.func,
    selectType: PropTypes.string,
    theme: PropTypes.string,
    size: PropTypes.string,
    children: PropTypes.object,
    textEllipsis: PropTypes.bool,
    withoutArrow: PropTypes.bool,
    withoutButton: PropTypes.bool,
    rightPopupAlign: PropTypes.bool,
    optionsFooter: PropTypes.object,
    whiteSpaceOptions: PropTypes.string,
    searchPlaceholder: PropTypes.any,
    renderIcon: PropTypes.func,
  };

  static defaultProps = {
    isSearchable: false,
    className: '',
    classNameButton: '',
    classNamePopup: '',
    classNameOption: '',
    labelPath: 'attributes.name',
    valuePath: 'id',
    buttonLabelPath: null,
    disabled: false,
    open: false,
    isPopupRight: false,
    closeOnChange: false,
    withBigArrow: false,
    value: null,
    error: null,
    empty: null,
    placeholder: '',
    options: [],
    onChange: null,
    onOpenChange: null,
    onRenderArrow: null,
    selectType: '',
    theme: null,
    size: null,
    children: null,
    textEllipsis: false,
    withoutArrow: false,
    withoutButton: false,
    rightPopupAlign: false,
    optionsFooter: null,
    whiteSpaceOptions: 'nowrap',
    searchPlaceholder: 'Search variety and type',
    renderIcon: null
  };

  state = {
    query: '',
    open: this.props.open,
  };

  popupRef = React.createRef();

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { open: oldOpen } = this.props;
    const { open: nextOpen } = nextProps;

    if (oldOpen !== nextOpen) {
      this.setState({
        open: nextOpen,
      });
    }
  }

  handleClickOutside = () => {
    const { onOpenChange } = this.props;
    const { open } = this.state;

    if (open) {
      this.setState({
        open: false,
      }, () => {
        if (onOpenChange) {
          onOpenChange(!open);
        }
      });
    }
  };

  handlerChange = (option) => {
    const { closeOnChange, onChange } = this.props;

    if (onChange) {
      if (option) {
        const { value } = option;

        onChange({ value });
      } else {
        onChange({ value: null });
      }
    }

    if (closeOnChange) {
      this.setState({
        open: false,
      });
    }
  };

  handlerTogglePopup = () => {
    const { onOpenChange } = this.props;
    const { open } = this.state;

    this.setState({
      open: !open,
    }, () => {
      if (onOpenChange) {
        onOpenChange(!open);
      }
    });
  };

  renderArrow = () => {
    const { onRenderArrow } = this.props;

    if (onRenderArrow) {
      return onRenderArrow({ props: this.props, className: styles.arrow });
    }

    return <ArrowDown className={styles.arrow} />;
  };

  renderSearchList = () => {
    const {
      valuePath,
      labelPath,
      options,
      empty,
      whiteSpaceOptions,
      searchPlaceholder,
      classNameOption
    } = this.props;

    const {
      query,
    } = this.state;

    return (
      <DefaultSearchList
        controlClassName={styles.searchListControl}
        placeholder={searchPlaceholder}
        classNameOption={classNameOption}
        empty={empty}
        options={options}
        isFetching={false}
        value={query}
        valuePath={valuePath}
        labelPath={labelPath}
        onChanged={({ value: nextQuery }) => {
          this.setState({ query: nextQuery });
        }}
        onSelected={({ option }) => {
          this.handlerChange({ label: get(option, labelPath), value: get(option, valuePath), handlerClick: get(option, 'onClick') });
        }}
        whiteSpaceOptions={whiteSpaceOptions}
      />
    );
  };

  renderOptions = () => {
    const {
      options,
      labelPath,
      valuePath,
      value: selectedValue,
      classNameOption,
      whiteSpaceOptions
    } = this.props;

    const data = options.map(item => ({
      label: get(item, labelPath),
      value: get(item, valuePath),
      disabled: item?.disabled,
    }));

    return (
      <div className={styles.options}>
        {data.map((option) => {
          const { label, value } = option;
          const optionData = {
            onClick: () => {
              if (!option?.disabled) {
                this.handlerChange({ label, value });
              }
            },
          };

          const isSelected = value === selectedValue;

          return (
            <div
              key={`select-${uniqueId(value)}`}
              className={classnames(styles.option, classNameOption, { [styles.selected]: isSelected, [styles.disabled]: option?.disabled })}
              style={{
                whiteSpace: whiteSpaceOptions
              }}
              {...optionData}
            >
              {label}
            </div>
          );
        })}
      </div>
    );
  };

  fixOffscreenPosition = () => {
    if (this.popupRef.current) {
      const rect = this.popupRef.current.getBoundingClientRect();
      const dx = window.innerWidth - (rect.x + rect.width);
      if (dx < 0) {
        this.popupRef.current.style.marginLeft = `${dx - 8}px`;
      }
    }
  }

  render() {
    const {
      className,
      classNameButton,
      classNamePopup,
      value,
      error,
      valuePath,
      labelPath,
      buttonLabelPath,
      placeholder,
      disabled,
      isSearchable,
      options,
      selectType,
      theme,
      size,
      children,
      isPopupRight,
      withBigArrow,
      textEllipsis,
      withoutArrow,
      withoutButton,
      rightPopupAlign,
      optionsFooter,
      renderIcon
    } = this.props;

    const {
      open,
    } = this.state;

    const isError = !!error;

    const selectedOption = options.find(item => get(item, valuePath) === value);
    const titleFromPathPath = get(selectedOption, buttonLabelPath) || get(selectedOption, labelPath);
    const title = titleFromPathPath || placeholder;
    const arrow = this.renderArrow();
    const renderedOptions = !isSearchable && this.renderOptions();
    const renderedSearchList = isSearchable && this.renderSearchList();

    this.fixOffscreenPosition();

    return (
      <ClickOutside handleClickOutside={this.handleClickOutside}>
        <div
          className={classnames(
            styles.select,
            className,
            styles[selectType],
            {
              [styles[theme]]: !!theme,
              [styles[size]]: !!size,
              [styles.right]: isPopupRight,
              [styles.errored]: !!error,
              [styles.withBigArrow]: !!withBigArrow,
              [styles.withoutArrow]: withoutArrow,
            }
          )}
        >
          <button
            className={classnames(
              styles.button,
              classNameButton,
              {
                [styles.notSelected]: !selectedOption,
                [styles.withoutButton]: withoutButton,
                [styles.invalid]: isError,
                invalid: isError,
              }
            )}
            disabled={disabled}
            onClick={this.handlerTogglePopup}
            type='button'
          >{renderIcon ? (<div className={styles.icon}>{renderIcon()}</div>) : null}
            {textEllipsis ?
              <span className={styles.textEllipsis}>{title}</span>
              :
              title}
            {!withoutArrow && arrow}
          </button>
          {open && (
            <div
              ref={this.popupRef}
              className={classnames(styles.popup, classNamePopup, {
                [styles.isSearchable]: isSearchable, [styles.rightPopupAlign]: rightPopupAlign
              })}
            >
              {isSearchable ? renderedSearchList : (children || renderedOptions)}
              {optionsFooter && optionsFooter}
            </div>
          )}
          {error ? (
            <div className={styles.error}>
              {error}
            </div>
          ) : null}
        </div>
      </ClickOutside>
    );
  }
}
