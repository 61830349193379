import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { uniqueId } from 'lodash';
import classnames from 'classnames';

import ListItemWithSwitch from '../ListItemWithSwitch';

import styles from './ListWithSwitch.module.css';

const ListWithSwitch = ({
  list,
  className,
  ...restProps
}) => {
  if (!list || list.length === 0) {
    return null;
  }

  return (
    <div className={classnames(styles.listWithSwitch, className)}>
      {list.map(listItem => (
        <ListItemWithSwitch
          // добавляем ключ в id, чтобы компонент ремаунтился при изменении пропсов
          key={`incidentItem-${listItem?.id}-${uniqueId()}`}
          {...listItem}
          {...restProps}
        />
      ))}
    </div>
  );
};

ListWithSwitch.propTypes = {
  list: PropTypes.array,
  className: PropTypes.string,
};

ListWithSwitch.defaultProps = {
  list: [],
  className: undefined,
};

export default memo(ListWithSwitch);
