import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';


import { requestLaborReport, updateLaborReportSorting } from 'store/labor/actions';

import { getIsLaborReportFetching, getLaborWorkTypes } from 'store/labor/selectors';

import {
  getAllFruitClasses,
  getCurrentCompartments,
  getVarietiesInLocationWithTargetWeight,
  getUnitKindFromRoute,
  getBreakdownTypeFromRoute,
  getVarietyIdFromRoute,
  getFruitClassFromRoute,
  getSpeciesFromRoute,
  getAllPlantingCycles,
  getAllProductGroups,
  getAnyDateOfPeriodStartFromRoute,
  getAnyDateOfPeriodEndFromRoute
} from 'store/company/selectors';

import { getPeriodType } from 'store/plans/selectors';
import { getSelectedRow } from 'store/dashboards/selectors';
import DashboardReportSelectedChart from './DashboardReportSelectedChart';

const mapDispatchToProps = {
  requestLaborReport,
  updateLaborReportSorting,
};


const mapStateToProps = (state, props) => ({
  selectedRow: getSelectedRow(state, props),

  isDataFetching: getIsLaborReportFetching(state, props),
  varieties: getVarietiesInLocationWithTargetWeight(state, props),
  compartments: getCurrentCompartments(state),
  fruitClasses: getAllFruitClasses(state, props),
  currentUnitKind: getUnitKindFromRoute(state, props),

  periodType: getPeriodType(state, props),

  productGroups: getAllProductGroups(state, props),
  allPlantingCycles: getAllPlantingCycles(state),
  workTypes: getLaborWorkTypes(state, props),
  currentBreakdown: getBreakdownTypeFromRoute(state, props),
  currentSpecies: getSpeciesFromRoute(state, props),
  currentVarietyId: getVarietyIdFromRoute(state, props),
  currentFruitClassCode: getFruitClassFromRoute(state, props),

  anyDateOfPeriodStart: getAnyDateOfPeriodStartFromRoute(state, props),
  anyDateOfPeriodEnd: getAnyDateOfPeriodEndFromRoute(state, props)
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardReportSelectedChart)));
