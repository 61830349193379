import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import classnames from 'classnames';
import moment from 'moment-timezone';

import DatePicker from 'react-datepicker/dist/react-datepicker.min';

import Select from '../../../Select';
import DateButton from '../DateButton';
import PeriodPickerMobile from '../PeriodPickerMobile';

import ArrowRightIcon from '../../../Icons/ArrowRightIcon';
import ArrowLeftIcon from '../../../Icons/ArrowLeftIcon';

import isPhoneView from '../../../../helpers/isPhoneView';

import styles from './PeriodPicker.module.css';

const getAddArrowDisabledInitialState = (props) => {
  const {
    date, periodType, organization
  } = props;
  const { attributes: { timezone } } = organization;

  if (!date) {
    return true;
  }

  const newDate = date.clone().add(1, periodType);
  const isAfter = newDate.isAfter(moment(new Date()).tz(timezone), periodType);

  return isAfter;
};

const getAddArrowDisabled = (date, maxDate, periodType, organization) => {
  const { attributes: { timezone } } = organization;

  if (!date) {
    return true;
  }

  const newDate = date.clone().add(1, periodType);

  const isAfter = newDate.isAfter(moment(maxDate).tz(timezone), periodType);

  return isAfter;
};

const getSubArrowDisabledInitialState = (date, minDate, periodType, organization) => {
  const { attributes: { timezone } } = organization;

  if (!date) {
    return true;
  }

  const newDate = date.clone().subtract(1, periodType);

  const isBefore = newDate.isBefore(moment(minDate).tz(timezone), periodType);

  return isBefore;
};

class PeriodPicker extends Component {
  static propTypes = {
    intl: intlShape.isRequired,

    periodType: PropTypes.string.isRequired,
    organization: PropTypes.object,
    date: PropTypes.object,
    withoutAddDisable: PropTypes.bool,
    dateFormat: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    hidePeriodSelect: PropTypes.bool,

    onDateChange: PropTypes.func,
    onPeriodChange: PropTypes.func,
    onMobileFiltersChange: PropTypes.func,
    className: PropTypes.string,
    dateButtonClassName: PropTypes.string,
    popperPlacement: PropTypes.string,
    capitalized: PropTypes.bool,
    onArrowAddClick: PropTypes.func,
    onArrowSubtractClick: PropTypes.func,
    withoutPhoneVersion: PropTypes.bool,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
  };

  static defaultProps = {
    date: null,
    organization: null,
    className: null,
    dateButtonClassName: null,
    withoutAddDisable: false,
    hidePeriodSelect: false,
    dateFormat: 'MMMM, YYYY',
    popperPlacement: 'bottom-end',
    capitalized: true,
    withoutPhoneVersion: false,
    minDate: null,
    maxDate: null,

    onDateChange: () => {},
    onPeriodChange: () => {},
    onMobileFiltersChange: () => {},
    onArrowAddClick: null,
    onArrowSubtractClick: null,
  };

  state = {
    dateEditable: true,
    addArrowDisabled: getAddArrowDisabledInitialState(this.props),
    isPhone: isPhoneView(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.handlerResizeWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResizeWindow);
  }

  getIsAddArrowDisabled = (withoutAddDisable, addArrowDisabled, date, maxDate, periodType, organization) => {
    if (withoutAddDisable) {
      return false;
    }

    if (maxDate) {
      return getAddArrowDisabled(date, maxDate, periodType, organization);
    }

    return addArrowDisabled;
  };

  handlerResizeWindow = () => {
    this.setState({ isPhone: isPhoneView() });
  };

  // TODO: Вынести общие куски хендлеров в отдельные функции
  handlerAddArrowClick = () => {
    const {
      date, periodType, organization, onDateChange, withoutAddDisable, onArrowAddClick
    } = this.props;
    const { attributes: { timezone } } = organization;

    const newDate = date ? date.clone().add(1, periodType) : null;
    const isAfter = newDate.isAfter(moment(new Date()).tz(timezone), periodType);
    const isSame = newDate.isSame(moment(new Date()).tz(timezone), periodType);

    if (onArrowAddClick) {
      return onArrowAddClick(date, newDate);
    }

    if (isAfter && !withoutAddDisable) {
      return this.setState({ addArrowDisabled: true });
    }

    this.setState({ addArrowDisabled: isSame });

    return onDateChange(newDate);
  }

  handlerSubtractArrowClick = () => {
    const {
      date, periodType, organization, onDateChange, onArrowSubtractClick
    } = this.props;
    const { attributes: { timezone } } = organization;

    const newDate = date ? date.clone().subtract(1, periodType) : null;
    const isBefore = newDate.isBefore(moment(new Date()).tz(timezone), periodType);

    if (onArrowSubtractClick) {
      return onArrowSubtractClick(date, newDate);
    }

    this.setState({ addArrowDisabled: !isBefore });

    return onDateChange(newDate);
  };

  handlerRangeSelect = (options) => {
    const { date, organization, onPeriodChange } = this.props;
    const { attributes: { timezone } } = organization;

    const selectedDate = date || moment(new Date()).tz(timezone);
    const isBefore = selectedDate.isBefore(moment(new Date()).tz(timezone), options.value);

    this.setState({
      addArrowDisabled: !isBefore,
    });

    onPeriodChange(options.value);
  };

  handlerDateChange = (newDate) => {
    const { periodType, organization, onDateChange } = this.props;
    const { attributes: { timezone } } = organization;

    const isBefore = newDate.isBefore(moment(new Date()).tz(timezone), periodType);

    this.setState({ addArrowDisabled: !isBefore });

    onDateChange(newDate);
  };

  render() {
    const {
      intl,
      organization,
      date,
      periodType,
      className,
      onMobileFiltersChange,
      withoutAddDisable,
      dateFormat,
      hidePeriodSelect,
      popperPlacement,
      dateButtonClassName,
      capitalized,
      withoutPhoneVersion,
      minDate,
      maxDate,
    } = this.props;

    const { formatMessage } = intl;
    const {
      dateEditable,
      addArrowDisabled,
      isPhone,
    } = this.state;

    const { attributes: { timezone } } = organization;

    const isAddArrowDisabled = this.getIsAddArrowDisabled(withoutAddDisable, addArrowDisabled, date, maxDate, periodType, organization);
    const isSubArrowDisabled = minDate ? getSubArrowDisabledInitialState(date, minDate, periodType, organization) : false;

    const ranges = [
      { label: formatMessage({ id: 'kpiDashboard.months' }), value: 'month' },
      { label: formatMessage({ id: 'kpiDashboard.years' }), value: 'year' },
    ];

    if (!withoutPhoneVersion && isPhone) {
      return (
        <PeriodPickerMobile
          organization={organization}
          ranges={ranges}
          currentPeriodType={periodType}
          currentDate={date}
          onMobileFiltersChange={onMobileFiltersChange}
        />
      );
    }

    return (
      <div className={classnames(styles.stateControls, className, { [styles.withPhoneVersion]: !withoutPhoneVersion })}>
        {!hidePeriodSelect && (
          <Select
            className={styles.select}
            classNameButton={styles.selectButton}
            classNamePopup={styles.selectPopup}
            options={ranges}
            value={periodType}
            onChange={this.handlerRangeSelect}
            labelPath='label'
            valuePath='value'
            closeOnChange
          />
        )}

        <button
          type='button'
          className={classnames(styles.leftButton, { [styles.disabled]: !dateEditable || isSubArrowDisabled })}
          onClick={this.handlerSubtractArrowClick}
          disabled={!dateEditable || isSubArrowDisabled}
        >
          <ArrowLeftIcon />
        </button>
        <DatePicker
          disabled={!dateEditable}
          minDate={minDate ? moment(minDate) : null}
          maxDate={maxDate ? moment(maxDate) : moment(new Date()).tz(timezone)}
          customInput={(
            <DateButton
              intl={intl}
              anyDateOfPeriod={date}
              periodType={periodType}
              disabled={!dateEditable}
              dateFormat={dateFormat}
              dateButtonClassName={classnames(dateButtonClassName)}
              capitalized={capitalized}
              withoutPhoneVersion={withoutPhoneVersion}
            />
          )}
          selected={date}
          onChange={this.handlerDateChange}
          popperPlacement={popperPlacement}
        />
        <button
          type='button'
          className={classnames(styles.rightButton, { [styles.disabled]: !dateEditable || isAddArrowDisabled })}
          onClick={this.handlerAddArrowClick}
          disabled={!dateEditable || isAddArrowDisabled}
        >
          <ArrowRightIcon />
        </button>
      </div>
    );
  }
}

export default injectIntl(PeriodPicker);
