import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Route, Switch, NavLink } from 'react-router-dom';

import moment from 'moment-timezone';
import classnames from 'classnames';
import {
  get, isEqual, includes, filter, uniq, flow
} from 'lodash';
import queryString from 'query-string';

import PeriodPicker from 'components/PeriodPicker';
import DataSheet from 'components/DataSheet';
import BigButton from 'components/BigButton';
import BackButton from 'components/BackButton';
import DefaultPlate from 'components/DefaultPlate';
import HistoryIcon from 'components/Icons/HistoryIcon';
import SaveChangesDialog from 'components/SaveChangesDialog';
import SelectboxFilterWithChecks from 'components/SelectboxFilterWithChecks';
import VarietySearchSelect from 'components/VarietySearchSelect';
import PlansSummary from 'components/PlansSummary';

import { checkPlanErrors } from 'helpers/generateGrid';
import storageWrapper from 'helpers/storageWrapper';
import { API_DATE_FORMAT } from 'helpers/defaultDates';
import updateLocationSearch from 'helpers/updateLocationSearch';

import prepareProductGroupParams from 'helpers/prepareProductGroupParams';
import PlansEditorSidePanel from '../PlansEditorSidePanel';
import DropdownButton from '../DropdownButton';
import PublishButtonDialog from '../PublishButtonDialog';
import RestoreDialog from '../RestoreDialog';

import styles from './PlansEditor.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');

export default class PlansEditor extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isAddOperationalPlansAvailable: PropTypes.bool.isRequired,
    isAddBudgetPlansAvailable: PropTypes.bool.isRequired,
    organization: PropTypes.object,
    year: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    harvestPlan: PropTypes.object,
    harvestPlanEdited: PropTypes.object,
    withoutSaveDate: PropTypes.number,
    isPlansEditorSaving: PropTypes.bool,
    isLastVersion: PropTypes.bool.isRequired,
    isNotPublishedVersionPlate: PropTypes.bool.isRequired,
    isOperationalPlan: PropTypes.bool.isRequired,
    planType: PropTypes.string.isRequired,
    compartments: PropTypes.array.isRequired,

    periodType: PropTypes.string,
    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
    productGroup: PropTypes.array,
    compartmentId: PropTypes.array,
    species: PropTypes.string,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    currentGrownFilter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    fruitClassCode: PropTypes.string,
    varietyId: PropTypes.string,

    showNotificationWithTimeout: PropTypes.func.isRequired,
    requestDefaultPlanVersion: PropTypes.func.isRequired,
    requestAllPlanVersions: PropTypes.func.isRequired,
    requestPublishPlanById: PropTypes.func.isRequired,
    requestRestorePlan: PropTypes.func.isRequired,
    requestSaveVersionInfo: PropTypes.func.isRequired,
    requestDeleteVersionInfo: PropTypes.func.isRequired,
    requestPlanVersionById: PropTypes.func.isRequired,
    resetPlansEditorData: PropTypes.func.isRequired,

    requestSavePlan: PropTypes.func.isRequired,
    requestSaveAndPublishPlan: PropTypes.func.isRequired,
  };

  static defaultProps = {
    year: moment().format('YYYY'),
    harvestPlan: null,
    harvestPlanEdited: null,
    withoutSaveDate: undefined,
    isPlansEditorSaving: false,
    organization: null,
    anyDateOfPeriodStart: undefined,
    anyDateOfPeriodEnd: undefined,
    productGroup: undefined,
    compartmentId: undefined,
    species: undefined,
    currentGrownFilter: undefined,
    fruitClassCode: undefined,
    varietyId: undefined,
    periodType: 'year',
  };

  state = {
    timeText: undefined,
    actionAfter: undefined,
    isPlansEditorDialogVisible: false,
    opened: false,
    isPublishButtonDialogOpened: false,
    isRestoreDialogOpened: false,
  }

  componentDidMount() {
    const {
      history,
      match,
      organization,
      year,
      isAddOperationalPlansAvailable,
      isAddBudgetPlansAvailable,
      requestAllPlanVersions,
      requestDefaultPlanVersion,
      isOperationalPlan,

      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      productGroup,
      compartmentId,
      species,
    } = this.props;

    const { params: { planId } } = match;

    // Проверяем какого типа план и есть ли права на редактирование
    const isNotAvalibleOperational = isOperationalPlan && !isAddOperationalPlansAvailable;
    const isNotAvalibleBudget = !isOperationalPlan && !isAddBudgetPlansAvailable;

    // Проверка прав пользователя
    if (isNotAvalibleOperational || isNotAvalibleBudget) {
      history.push(`/${match.params.organizationSlug}/plans`);
    }

    let planParams = { year, isOperationalPlan, planId };

    const localStoragePlanName = this.getLocalStoragePlanName();
    const plansStateString = safeLocalStorage.getItem(localStoragePlanName);
    const plansState = JSON.parse(plansStateString);
    const isCorrectPlan = plansState && (Number(get(plansState, 'harvestPlanEdited.year')) === Number(year)) && (get(organization, 'id') === get(plansState, 'organization.id'));

    // Если в localstorage есть сохранённый план, то выводим его
    if (isCorrectPlan) {
      planParams = {
        year,
        isOperationalPlan,
        planId,
        harvestPlanEdited: get(plansState, 'harvestPlanEdited'),
        withoutSaveDate: get(plansState, 'withoutSaveDate'),
      };

      /* eslint-disable react/no-did-mount-set-state */
      this.setState({ timeText: this.getTimeText(get(plansState, 'withoutSaveDate')) });
      /* eslint-enable react/no-did-mount-set-state */
    }

    this.intervalId = setInterval(this.setTimer, 60000);

    requestDefaultPlanVersion({
      ...planParams,

      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      productGroup,
      compartmentId,
      species,
    });

    requestAllPlanVersions({ year, isOperationalPlan, planId });
  }

  componentDidUpdate(prevProps) {
    const { withoutSaveDate } = this.props;

    if (withoutSaveDate !== prevProps.withoutSaveDate) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ timeText: this.getTimeText(this.props.withoutSaveDate) });
      /* eslint-enable react/no-did-update-set-state */
    }
  }

  componentWillUnmount() {
    const { resetPlansEditorData } = this.props;

    clearInterval(this.intervalId);

    resetPlansEditorData();
  }

  setTimer = () => this.setState({ timeText: this.getTimeText(this.props.withoutSaveDate) });

  getAnyDateOfPeriod = () => {
    const { periodType, year, anyDateOfPeriodStart } = this.props;

    if (periodType === 'year') {
      return moment(year, 'YYYY').format(API_DATE_FORMAT);
    }

    if (anyDateOfPeriodStart) {
      return anyDateOfPeriodStart;
    }

    return moment().year(year).format(API_DATE_FORMAT);
  };

  getLocalStoragePlanName = () => {
    const { planType } = this.props;

    return `${planType}PlansState`;
  };

  getTimeText = (withoutSaveDate) => {
    if (!withoutSaveDate) {
      return undefined;
    }

    const start = moment.utc(withoutSaveDate);
    const end = moment.utc(Date.now());

    const duration = moment.duration(end.diff(start));

    return duration.humanize();
  };

  getTimelineHref = () => {
    const { year, match } = this.props;

    const timelineDate = moment().year(year).format('YYYY-MM-DD');

    return `/${match.params.organizationSlug}/plans?timelineDate=${timelineDate}`;
  };

  getPlansListHref = () => {
    const { year, match } = this.props;

    return `/${match.params.organizationSlug}/plans/list?year=${year}`;
  };

  // В оперативном плане выводим только те compartments, в которых были planting cycles
  getAvaliableCompartments = (isOperationalPlan, compartments = [], planData = []) => {
    if (!isOperationalPlan) {
      return compartments;
    }
    const currentCompartmentsIds = flow([
      currentData => currentData.map(item => item.compartmentId),
      currentCompartmentIds => uniq(currentCompartmentIds),
    ])(planData);

    const filteredCompartments = filter(compartments, compartment => includes(currentCompartmentsIds, compartment.id));

    return filteredCompartments;
  };

  getCompartmentsList = (compartments) => {
    const { compartmentId } = this.props;

    return compartments.reduce((acc, compartment) => (
      [
        ...acc,
        {
          label: get(compartment, 'attributes.name'),
          value: compartment.id,
          checked: compartmentId ? !!compartmentId.find(id => +compartment.id === +id) : true,
        },
      ]
    ), []);
  };

  getOptionsChanges = (options) => {
    const isAll = options.every(item => get(item, 'checked'));
    const isAllReset = options.every(item => !get(item, 'checked'));

    let changes;

    if (!isAll) {
      changes = options.filter(item => get(item, 'checked')).map(item => get(item, 'value'));
    }

    if (isAllReset) {
      changes = [0];
    }

    return changes;
  };

  setNewYear = (newParams) => {
    const {
      match,
      isOperationalPlan,

      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      productGroup,
      compartmentId,
      species,

      requestAllPlanVersions,
      requestDefaultPlanVersion,
    } = this.props;

    const { params: { planId } } = match;

    requestDefaultPlanVersion({
      isOperationalPlan,
      planId,
      requestDefaultPlanVersion,

      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      productGroup,
      compartmentId,
      species,

      ...newParams,
    });

    const { year } = newParams;

    requestAllPlanVersions({ year, isOperationalPlan, planId });
  };

  setNewFilters = (newParams) => {
    const {
      match,
      isOperationalPlan,

      year,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      productGroup,
      compartmentId,
      species,

      requestDefaultPlanVersion,
    } = this.props;

    const { params: { planId } } = match;

    requestDefaultPlanVersion({
      year,
      isOperationalPlan,
      planId,
      requestDefaultPlanVersion,

      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      productGroup,
      compartmentId,
      species,

      ...newParams,
    });
  };

  resetStoragePlan = () => {
    const localStoragePlanName = this.getLocalStoragePlanName();

    safeLocalStorage.setItem(localStoragePlanName, null);
  };

  restorePlan = (params) => {
    const { requestRestorePlan } = this.props;

    this.resetStoragePlan();

    requestRestorePlan(params);
  };

  savePlan = (params) => {
    const {
      requestSavePlan,

      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      productGroup,
      compartmentId,
      species,
    } = this.props;

    this.resetStoragePlan();

    requestSavePlan({
      ...params,

      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      productGroup,
      compartmentId,
      species,
    });
  };

  publishPlanById = (params) => {
    const { requestPublishPlanById } = this.props;

    this.resetStoragePlan();

    requestPublishPlanById(params);
  };

  saveAndPublishPlan = (params) => {
    const {
      requestSaveAndPublishPlan,

      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      productGroup,
      compartmentId,
      species,
    } = this.props;

    this.resetStoragePlan();

    requestSaveAndPublishPlan({
      ...params,

      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      productGroup,
      compartmentId,
      species,
    });
  };

  showDialog = ({ actionAfter }) => this.setState({ isPlansEditorDialogVisible: true, actionAfter });

  handlerToggleSidePanel = () => this.setState(prevState => ({ opened: !prevState.opened }));

  handlerSidePanelClose = () => this.setState({ opened: false });

  handlerCancelButton = () => {
    const {
      history,
      harvestPlan,
      harvestPlanEdited,
    } = this.props;

    const plansListHref = this.getPlansListHref();

    if (isEqual(harvestPlan, harvestPlanEdited)) {
      history.push(plansListHref);
    } else {
      this.showDialog({ actionAfter: () => history.push(plansListHref) });
    }
  }

  handlerSaveButton = (withRedirect = false, withPublish = false) => {
    const {
      history,
      harvestPlanEdited, showNotificationWithTimeout,
    } = this.props;

    const hasErrors = checkPlanErrors(harvestPlanEdited);

    const timelineHref = this.getTimelineHref();

    const actionAfterSave = withRedirect ? () => history.push(timelineHref) : () => {};

    if (hasErrors) {
      showNotificationWithTimeout({
        id: 'notifications.harvestPlanSaveValidationError',
        messageId: 'notifications.harvestPlanSaveValidationError',
        position: 'leftDown',
        iconType: 'error',
        notificationType: 'withActionWide',
      });
    } else if (withPublish) {
      this.saveAndPublishPlan({
        modifiedHarvestPlan: harvestPlanEdited,
        actionAfterSave,
      });
    } else {
      this.savePlan({
        modifiedHarvestPlan: harvestPlanEdited,
        actionAfterSave,
      });
    }
  };

  handlerRestoreButton = () => {
    const { harvestPlan } = this.props;

    this.restorePlan({ planId: harvestPlan.id, actionAfterSave: this.handlerCloseRestoreDialog });
  };

  handlerOpenPublishButtonDialog = () => {
    const isPublishDialogHidden = safeLocalStorage.getItem('isPublishDialogHidden');

    if (isPublishDialogHidden) {
      return this.handlerSaveButton(true, true);
    }

    return this.setState({ isPublishButtonDialogOpened: true });
  };

  handlerOnVersionClick = ({ planId }) => {
    const {
      isOperationalPlan,
      harvestPlan,
      harvestPlanEdited,
      requestPlanVersionById,

      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      productGroup,
      compartmentId,
      species,
    } = this.props;

    if (isEqual(harvestPlan, harvestPlanEdited)) {
      requestPlanVersionById({
        planId,
        isOperationalPlan,

        anyDateOfPeriodStart,
        anyDateOfPeriodEnd,
        productGroup,
        compartmentId,
        species,
      });
    } else {
      this.showDialog({
        actionAfter: () => requestPlanVersionById({
          planId,
          isOperationalPlan,

          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
          productGroup,
          compartmentId,
          species,
        })
      });
    }
  };

  handlerClosePublishButtonDialog = () => {
    this.resetStoragePlan();

    this.setState({ isPublishButtonDialogOpened: false });
  }

  handlerOpenRestoreDialog = (e) => {
    e.stopPropagation();

    return this.setState({ isRestoreDialogOpened: true });
  };

  handlerCloseRestoreDialog = () => {
    this.resetStoragePlan();

    this.setState({ isRestoreDialogOpened: false });
  };

  handlerUpdateFilters = (action, newParameters) => {
    const {
      harvestPlan,
      harvestPlanEdited,
    } = this.props;

    if (isEqual(harvestPlan, harvestPlanEdited)) {
      updateLocationSearch(newParameters);
      action(newParameters);
    } else {
      this.showDialog({
        actionAfter: () => {
          updateLocationSearch(newParameters);
          action(newParameters);
        }
      });
    }
  };

  // handlerUpdateFilters = (newParameters) => {
  //   updateLocationSearch(newParameters);
  // };

  handlerDateChange = (newDate) => {
    const {
      periodType,
      year,
    } = this.props;

    const newYear = newDate.clone().year();

    const changeYear = Number(newYear) !== Number(year);

    if (periodType === 'year') {
      return this.handlerUpdateFilters(this.setNewYear, { year: newYear });
    }

    const newParameters = {
      year: newYear,
      anyDateOfPeriodStart: newDate.clone().startOf(periodType).format(API_DATE_FORMAT),
      anyDateOfPeriodEnd: newDate.clone().endOf(periodType).format(API_DATE_FORMAT)
    };

    // Если при переключении календаря происходит смена года, то вызываем соответствующий обработчик
    if (periodType === 'month' && changeYear) {
      return this.handlerUpdateFilters(this.setNewYear, newParameters);
    }

    return this.handlerUpdateFilters(this.setNewFilters, newParameters);
  };

  handlerCompartmentsChange = (options) => {
    const compartmentId = this.getOptionsChanges(options);

    return this.handlerUpdateFilters(this.setNewFilters, { compartmentId });
  };

  handlerPeriodChange = (newPeriod) => {
    const { year: selectedYear } = this.props;

    if (newPeriod === 'year') {
      const year = moment().year(selectedYear).format('YYYY');
      return this.handlerUpdateFilters(this.setNewYear, {
        year,
        anyDateOfPeriodStart: undefined,
        anyDateOfPeriodEnd: undefined,
        periodType: newPeriod,
      });
    }

    const newParameters = {
      anyDateOfPeriodStart: moment()
        .year(selectedYear)
        .clone()
        .startOf(newPeriod)
        .format(API_DATE_FORMAT),

      anyDateOfPeriodEnd: moment()
        .year(selectedYear)
        .clone()
        .endOf(newPeriod)
        .format(API_DATE_FORMAT),

      periodType: newPeriod,
    };

    return this.handlerUpdateFilters(this.setNewFilters, newParameters);
  };

  handlerVarietyOptionSelect = (newParams) => {
    const { varieties } = this.props;
    const { species, varietyId, fruitClassCode } = newParams;
    const paramsWithReset = prepareProductGroupParams(varieties, species, varietyId, fruitClassCode);
    return this.handlerUpdateFilters(this.setNewFilters, paramsWithReset);
  };

  renderSaveText = (timeText, isPlansEditorSaving, formatMessage) => (
    <span className={styles.timeText}>
      <span>{`${formatMessage({ id: 'plans.lastSave' })} ${timeText} ${formatMessage({ id: 'plans.ago' })}.`}</span>
      <BigButton
        className={styles.timeTextButton}
        title={`${formatMessage({ id: 'plans.saveChanges' })}.`}
        type='button'
        onClick={() => this.handlerSaveButton(false)}
        disabled={isPlansEditorSaving}
        theme='transparent'
      />
    </span>
  );

  renderNotLastVersion = () => {
    const {
      intl: { formatMessage }, isPlansEditorSaving, harvestPlan
    } = this.props;

    const whenCreated = get(harvestPlan, 'whenCreated');
    const timeText = moment(whenCreated).format('DD MMM, HH:mm');

    return (
      <span className={styles.notificationText}>
        <span>{`${formatMessage({ id: 'plansVersion.notificationText' })} ${timeText}.`}</span>
        <BigButton
          className={styles.notificationTextButton}
          title={`${formatMessage({ id: 'plansVersion.restoreButton' })}.`}
          type='button'
          onClick={this.handlerOpenRestoreDialog}
          disabled={isPlansEditorSaving}
          theme='transparent'
        />
      </span>
    );
  };

  render() {
    const {
      intl: { formatMessage },
      match,
      year,

      withoutSaveDate,
      isPlansEditorSaving,

      isNotPublishedVersionPlate,
      isLastVersion,
      isOperationalPlan,
      harvestPlanEdited,
      requestSaveVersionInfo,
      requestDeleteVersionInfo,
      harvestPlan,
      compartments,

      periodType,
      currentGrownFilter,
      varieties,
      fruitClasses,

      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,

      compartmentId,
      productGroup,
      species,
      fruitClassCode,
      varietyId,
    } = this.props;

    const queryParams = queryString.stringify({
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      year,
      periodType,

      compartmentId,
      productGroup,
      species,
      fruitClassCode,
      varietyId,
    });

    // TODO: поменять на селектор
    const { params: { organizationSlug, planType, planId } } = match;
    const {
      timeText, isPlansEditorDialogVisible, actionAfter, opened, isPublishButtonDialogOpened, isRestoreDialogOpened
    } = this.state;


    const whenCreated = get(harvestPlan, 'whenCreated');
    const formattedWhenCreated = moment(whenCreated).format('DD MMM, HH:mm');

    const anyDateOfPeriod = this.getAnyDateOfPeriod();
    const momentAnyDateOfPeriod = moment(anyDateOfPeriod, 'YYYY-MM-DD');
    // const avaliableCompartments = this.getAvaliableCompartments(isOperationalPlan, compartments, harvestPlanEdited?.data);
    const compartmentsList = this.getCompartmentsList(compartments, formatMessage);

    return (
      <div className={styles.layout}>
        <div
          className={classnames(
            styles.container,
            {
              [styles.sidePanelOpened]: opened
            }
          )}
        >
          <div className={styles.header}>
            <div className={styles.headerRow}>
              <BackButton
                text={formatMessage({ id: 'plans.plansList' })}
                onClick={this.handlerCancelButton}
              />
            </div>
            <div className={classnames(styles.headerRow, styles.headerRowCentral)}>
              <h1 className={styles.headerText}>
                {isOperationalPlan ?
                  formatMessage({ id: 'plans.operationalPlan' })
                  :
                  formatMessage({ id: 'plans.budgetPlan' })}
              </h1>
              {isLastVersion ? (
                <div className={styles.headerControls}>
                  <div className={styles.headerControlsSave}>
                    {withoutSaveDate ?
                      this.renderSaveText(timeText, isPlansEditorSaving, formatMessage)
                      :
                      formatMessage({ id: 'plans.noChanges' })}
                  </div>
                  <div className={styles.headerControlsButtons}>
                    <BigButton
                      className={styles.cancelButton}
                      title={formatMessage({ id: 'button.cancel' })}
                      type='button'
                      onClick={this.handlerCancelButton}
                      theme='light'
                    />
                    <DropdownButton
                      handlerPublish={() => this.handlerSaveButton(true, true)}
                      handlerSaveWithoutPublish={() => this.handlerSaveButton(true)}
                      handlerPublishButton={this.handlerOpenPublishButtonDialog}
                      disabled={isPlansEditorSaving}
                    />
                  </div>
                </div>
              )
                :
                this.renderNotLastVersion()}
            </div>
            {isNotPublishedVersionPlate && (
              <div className={styles.headerRow}>
                <DefaultPlate className={styles.infoPlate}>
                  {formatMessage({ id: 'plansVersion.publishNotification' })}
                </DefaultPlate>
              </div>
            )}
            <div className={styles.headerRow}>
              <div className={styles.plansControls}>
                <PeriodPicker
                  className={styles.periodPicker}
                  dateButtonClassName={styles.dateButton}
                  periodType={periodType}
                  date={momentAnyDateOfPeriod}
                  dateFormat='MMM YYYY'
                  onPeriodChange={this.handlerPeriodChange}
                  onDateChange={this.handlerDateChange}
                  popperPlacement='bottom-start'
                  withoutAddDisable
                  hidePeriodSelect
                  // onMobileFiltersChange={this.handlerFiltersChange}
                />
                <div className={styles.linksToggle}>
                  <NavLink
                    to={`/${organizationSlug}/plans/${planType}/${planId}/edit?${queryParams}`}
                    className={styles.plansLink}
                    activeClassName={styles.plansLinkActive}
                    exact
                  >
                    {formatMessage({ id: 'plans.edit' })}
                  </NavLink>
                  <NavLink
                    to={`/${organizationSlug}/plans/${planType}/${planId}/edit/summary?${queryParams}`}
                    className={styles.plansLink}
                    activeClassName={styles.plansLinkActive}
                    exact
                  >
                    {formatMessage({ id: 'plans.summary' })}
                  </NavLink>
                </div>
              </div>

              <div className={styles.rightControls}>
                {isOperationalPlan && (
                  <div className={styles.filters}>
                    <VarietySearchSelect
                      onSelectOption={this.handlerVarietyOptionSelect}
                      varieties={varieties}
                      fruitClasses={fruitClasses}
                      selectedOption={currentGrownFilter}
                      classNameButton={styles.varietySelect}
                      isShowAllSpecies
                      title={`${formatMessage({ id: 'header.links.input' })}:`}
                    />
                    <SelectboxFilterWithChecks
                      options={compartmentsList}
                      pluralPlaceholderId='dataQuality.greenhouses'
                      onChange={this.handlerCompartmentsChange}
                      className={styles.selectboxFilterWrapper}
                      title={`${formatMessage({ id: 'dashboards.compartments' })}:`}
                      dropdownRightAlign={opened}
                    />
                  </div>
                )}

                {/* Сепаратор отдельно, чтобы удобно было управлять видимостью кнопки и фильтров */}
                {(isOperationalPlan && !opened) && (
                  <div className={styles.buttonSeparator} />
                )}
                {!opened && (
                  <BigButton
                    className={styles.historyButton}
                    titleClassName={styles.historyButtonTitle}
                    title={formatMessage({ id: 'plansVersion.history' })}
                    onClick={this.handlerToggleSidePanel}
                    icon={<HistoryIcon />}
                    theme='transparent'
                    highIcon
                  />
                )}
              </div>
            </div>
          </div>

          <div className={styles.content}>
            <Switch>
              <Route
                path='/:organizationSlug/plans/:planType/:planId/edit'
                component={DataSheet}
                exact
              />
              <Route
                path='/:organizationSlug/plans/:planType/:planId/edit/summary'
                component={PlansSummary}
                exact
              />
            </Switch>
          </div>
        </div>

        <PlansEditorSidePanel
          opened={opened}
          year={year}
          onClose={this.handlerSidePanelClose}
          onVersionClick={this.handlerOnVersionClick}
          onEditVersionInfo={requestSaveVersionInfo}
          onPublishVersion={this.publishPlanById}
          onRestoreVersion={this.restorePlan}
          onDeleteVersionInfo={requestDeleteVersionInfo}
        />

        {isPlansEditorDialogVisible && (
          <SaveChangesDialog
            handlerCloseDialog={() => this.setState({ isPlansEditorDialogVisible: false, actionAfter: undefined })}
            handlerDontSave={() => {
              actionAfter();

              this.resetStoragePlan();

              this.setState({ isPlansEditorDialogVisible: false });
            }}
            handlerSave={() => {
              this.savePlan({
                modifiedHarvestPlan: harvestPlanEdited,
                actionAfterSave: actionAfter,
              });

              this.setState({ isPlansEditorDialogVisible: false, actionAfter: undefined });
            }}
          />
        )}

        {isPublishButtonDialogOpened && (
          <PublishButtonDialog
            handlerCloseDialog={this.handlerClosePublishButtonDialog}
            handlerDontSave={this.handlerClosePublishButtonDialog}
            handlerSave={() => this.handlerSaveButton(true, true)}
            handlerSaveWithoutPublish={() => this.handlerSaveButton(true)}
            isFetching={isPlansEditorSaving}
          />
        )}

        {/* TODO: объединить с диалогом из VersionCard */}
        {isRestoreDialogOpened && (
          <RestoreDialog
            handlerCloseDialog={this.handlerCloseRestoreDialog}
            handlerDontSave={this.handlerCloseRestoreDialog}
            handlerSave={this.handlerRestoreButton}
            isFetching={isPlansEditorSaving}
            versionTime={formattedWhenCreated}
          />
        )}
      </div>
    );
  }
}
