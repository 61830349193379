import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import classnames from 'classnames';

// @ts-ignore
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';

import BigButton from 'components/BigButton';
import useOnClickOutside from 'hooks/useOnClickOutside';


import { GraphPresetListItem, Preset } from 'store/graphs/types';

import {
  ChangePresetDescription,
  ChangePresetName, ClearWorkspace,
  RequestCreateGraphPreset,
  RequestGetGraphPresetFunc,
  RequestMetricsTree,
  RequestUpdateGraphPreset

} from 'store/graphs/actions';

import { ReactComponent as SaveIcon } from './assets/save.svg';
import { ReactComponent as RefreshIcon } from './assets/refresh.svg';

import styles from './index.module.css';

type GraphsPresetMetaProp = {
  preset: Preset | null;
  presets: Array<GraphPresetListItem>;
  isEditedPreset: boolean;
  isNoGroupsErrors: boolean;
}

type GraphsPresetMetaFunc = {
  showSidePanelAction: Function; // TODO: Types for SidePanel
  requestGetGraphPreset: RequestGetGraphPresetFunc;
  requestCreateGraphPreset: RequestCreateGraphPreset;
  requestUpdateGraphPreset: RequestUpdateGraphPreset;
  changePresetName: ChangePresetName;
  changePresetDescription: ChangePresetDescription;
  setToggleGraphPresetDeleteDialog: Function;
  clearWorkspace: ClearWorkspace;
  requestMetricsTree: RequestMetricsTree;
}

function isPressEnterKey(e: React.KeyboardEvent<HTMLInputElement>) {
  return e.key === 'Enter' || e.keyCode === 13;
}

function isClickTargetNotFunctionalityElements(e: any) {
  return e.target && !e.target.closest(`.${styles.presetButtons}`) &&
    !e.target.closest(`.${styles.savePreset}`) &&
    !e.target.closest(`.${styles.saveAsPreset}`) &&
    !e.target.closest(`.${styles.graphPresetNameDiv}`) &&
    !e.target.closest(`.${styles.graphPresetNameInput}`) &&
    !e.target.closest(`.${styles.graphPresetDescriptionDiv}`) &&
    !e.target.closest(`.${styles.graphPresetDescriptionInput}`);
}

const GraphsPresetMeta = ({
  intl,
  preset,
  presets,
  isEditedPreset,
  showSidePanelAction,
  requestGetGraphPreset,
  requestCreateGraphPreset,
  requestUpdateGraphPreset,
  changePresetName,
  changePresetDescription,
  isNoGroupsErrors,
  setToggleGraphPresetDeleteDialog,
  clearWorkspace,
  requestMetricsTree
}: GraphsPresetMetaProp & InjectedIntlProps & GraphsPresetMetaFunc) => {
  const { formatMessage } = intl;

  const graphPresetNameInputRef = useRef<HTMLInputElement>(null);
  const graphPresetDescriptionInputRef = useRef<HTMLInputElement>(null);
  const graphPresetNameRef = useRef<HTMLDivElement>(null);

  const [saveAsMode, setSaveAsMode] = useState(false);
  const [graphPresetNameReadOnly, setGraphPresetNameReadOnly] = useState(true);
  const [graphPresetDescriptionReadOnly, setGraphPresetDescriptionReadOnly] = useState(true);

  const graphPresetType = preset?.preset?.type || 'standard';
  const isHaveMetricsPreset = preset?.preset?.presetMetrics?.length > 0;
  const isSavedPreset = preset?.preset?.id;

  const isReadonlyNameAndDescription = graphPresetNameReadOnly && graphPresetDescriptionReadOnly;
  const isReadonlyOrSaved = isReadonlyNameAndDescription || isSavedPreset;
  const isSavingButtonsVisible = isEditedPreset || isReadonlyOrSaved;

  const [graphPresetNameEdited, setGraphPresetNameEdited] = useState(preset?.preset?.name);
  const [graphPresetDescriptionEdited, setGraphPresetDescriptionEdited] = useState(preset?.preset?.description);

  useEffect(() => {
    setGraphPresetNameEdited(preset?.preset?.name);
    // eslint-disable-next-line
  }, [preset?.preset?.name, setGraphPresetNameEdited]);

  useEffect(() => {
    setGraphPresetDescriptionEdited(preset?.preset?.description);
    // eslint-disable-next-line
  }, [preset?.preset?.description, setGraphPresetDescriptionEdited]);


  const saveMetaInformation = useCallback((e) => {
    if (isClickTargetNotFunctionalityElements(e)) {
      if (!graphPresetNameReadOnly || !graphPresetDescriptionReadOnly) {
        if (saveAsMode) {
          setGraphPresetNameReadOnly(true);
          setGraphPresetDescriptionReadOnly(true);
          setSaveAsMode(false);
          setGraphPresetNameEdited(preset?.preset?.name);
          setGraphPresetDescriptionEdited(preset?.preset?.description);
        } else {
          setGraphPresetNameReadOnly(true);
          setGraphPresetDescriptionReadOnly(true);
          changePresetName({
            name: graphPresetNameEdited
          });
          changePresetDescription({
            description: graphPresetDescriptionEdited
          });
        }
      }
    }
  }, [
    saveAsMode,
    setSaveAsMode,
    setGraphPresetNameEdited,
    setGraphPresetDescriptionEdited,
    graphPresetNameReadOnly,
    graphPresetDescriptionReadOnly,
    setGraphPresetNameReadOnly,
    setGraphPresetDescriptionReadOnly,
    graphPresetNameEdited,
    graphPresetDescriptionEdited,
    changePresetDescription,
    preset,
    changePresetName
  ]);

  const handlerGraphPresetNameClick = useCallback(() => {
    if (isHaveMetricsPreset && graphPresetType !== 'standard') {
      setGraphPresetNameReadOnly(false);
      setGraphPresetDescriptionReadOnly(true);
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        graphPresetNameInputRef?.current?.focus();
      }, 0);
    }
  }, [
    isHaveMetricsPreset,
    graphPresetType,
    graphPresetNameInputRef,
    setGraphPresetNameReadOnly,
    setGraphPresetDescriptionReadOnly
  ]);

  const handlerGraphPresetDescriptionClick = useCallback(() => {
    if (isHaveMetricsPreset && graphPresetType !== 'standard') {
      setGraphPresetNameReadOnly(true);
      setGraphPresetDescriptionReadOnly(false);
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        graphPresetDescriptionInputRef?.current?.focus();
      }, 0);
    }
  }, [
    isHaveMetricsPreset,
    graphPresetType,
    graphPresetDescriptionInputRef,
    setGraphPresetNameReadOnly,
    setGraphPresetDescriptionReadOnly
  ]);

  const handlerChangeGraphPreset = useCallback((id: number) => {
    requestGetGraphPreset({ id });
  }, [requestGetGraphPreset]);

  const handlerDeleteGraphPreset = useCallback((id: number) => {
    setToggleGraphPresetDeleteDialog(true, id);
  }, [setToggleGraphPresetDeleteDialog]);

  const handlerOpenSavedReports = useCallback(() => {
    const selectedPreset = preset ?
      presets.find((item:GraphPresetListItem) => item.id === preset.preset.id) : null;
    showSidePanelAction({
      sidePanelType: 'graphPresets',
      sidePanelProps: {
        handlerChangeGraphPreset,
        handlerDeleteGraphPreset,
        // TODO: Fix after refactoring side panel
        graphPreset: selectedPreset ? {
          id: selectedPreset.id,
          type: 'GraphPreset',
          attributes: {
            ...selectedPreset,
          }
        } : {
          id: null,
          type: 'GraphPreset',
          attributes: {
            description: '',
            name: '',
            type: 'custom',
            xRange: 'last2Days',
            xRangeEnd: null,
            xRangeLengthInMins: null,
          }
        },
        headerText: <FormattedMessage id='graphs.savedReports' />
      },
    });
  }, [
    preset,
    showSidePanelAction,
    handlerChangeGraphPreset,
    handlerDeleteGraphPreset,
    presets
  ]);

  const handlerGraphPresetNameCancel = useCallback(() => {
    if (saveAsMode) {
      setGraphPresetNameReadOnly(true);
      setGraphPresetDescriptionReadOnly(true);
      setSaveAsMode(false);
      setGraphPresetNameEdited(preset?.preset?.name);
      setGraphPresetDescriptionEdited(preset?.preset?.description);
    } else {
      setGraphPresetNameReadOnly(true);
      setGraphPresetDescriptionReadOnly(true);
      setGraphPresetNameEdited(preset?.preset?.name);
      setGraphPresetDescriptionEdited(preset?.preset?.description);
    }
  }, [
    saveAsMode,
    setSaveAsMode,
    preset,
    setGraphPresetNameReadOnly,
    setGraphPresetDescriptionReadOnly,
    setGraphPresetNameEdited,
    setGraphPresetDescriptionEdited
  ]);

  const handlerGraphPresetNameChange = useCallback((e) => {
    setGraphPresetNameEdited(e.target.value);
  }, [setGraphPresetNameEdited]);

  const handlerGraphPresetDescriptionChange = useCallback((e) => {
    setGraphPresetDescriptionEdited(e.target.value);
  }, [setGraphPresetDescriptionEdited]);

  const handlerGraphPresetSave = useCallback(() => {
    if (graphPresetNameEdited) {
      if (preset.preset.id) {
        requestUpdateGraphPreset({
          preset: {
            ...preset.preset,
            type: 'current',
            name: graphPresetNameEdited,
            description: graphPresetDescriptionEdited
          }
        });
      } else {
        requestCreateGraphPreset({
          preset: {
            ...preset.preset,
            type: 'current',
            name: graphPresetNameEdited,
            description: graphPresetDescriptionEdited
          }
        });
      }
    } else {
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        graphPresetNameRef?.current?.click();
        setTimeout(() => {
          // eslint-disable-next-line no-unused-expressions
          graphPresetNameInputRef?.current?.focus();
        }, 0);
      }, 0);
    }
  }, [
    preset,
    graphPresetNameEdited,
    graphPresetDescriptionEdited,
    graphPresetNameInputRef,
    graphPresetNameRef,
    requestUpdateGraphPreset,
    requestCreateGraphPreset,
  ]);

  const handlerGraphPresetNameKeyDown = useCallback((e) => {
    if (isPressEnterKey(e)) {
      if (saveAsMode) {
        setGraphPresetNameReadOnly(true);
        setGraphPresetDescriptionReadOnly(true);
        setSaveAsMode(false);
        requestCreateGraphPreset({
          preset: {
            ...preset.preset,
            type: 'current',
            name: graphPresetNameEdited,
            description: graphPresetDescriptionEdited
          }
        });
      } else {
        setGraphPresetNameReadOnly(true);
        changePresetName({
          name: graphPresetNameEdited
        });
        if (!isSavedPreset) {
          handlerGraphPresetSave();
        }
      }
    }
  }, [
    preset,
    saveAsMode,
    changePresetName,
    setSaveAsMode,
    setGraphPresetNameReadOnly,
    setGraphPresetDescriptionReadOnly,
    graphPresetNameEdited,
    graphPresetDescriptionEdited,
    isSavedPreset,
    handlerGraphPresetSave,
    requestCreateGraphPreset
  ]);

  const handlerGraphPresetDescriptionKeyDown = useCallback((e) => {
    if (isPressEnterKey(e)) {
      if (saveAsMode) {
        setGraphPresetNameReadOnly(true);
        setGraphPresetDescriptionReadOnly(true);
        setSaveAsMode(false);
        requestCreateGraphPreset({
          preset: {
            ...preset.preset,
            type: 'current',
            name: graphPresetNameEdited,
            description: graphPresetDescriptionEdited
          }
        });
      } else {
        setGraphPresetDescriptionReadOnly(true);
        changePresetDescription({
          description: graphPresetDescriptionEdited
        });
        if (!isSavedPreset) {
          handlerGraphPresetSave();
        }
      }
    }
  }, [
    preset,
    saveAsMode,
    setSaveAsMode,
    setGraphPresetNameReadOnly,
    setGraphPresetDescriptionReadOnly,
    requestCreateGraphPreset,
    changePresetDescription,
    handlerGraphPresetSave,
    graphPresetDescriptionEdited,
    graphPresetNameEdited,
    isSavedPreset
  ]);

  const handlerGraphPresetNameSave = useCallback(() => {
    if (saveAsMode) {
      setGraphPresetNameReadOnly(true);
      setGraphPresetDescriptionReadOnly(true);
      setSaveAsMode(false);
      requestCreateGraphPreset({
        preset: {
          ...preset.preset,
          type: 'current',
          name: graphPresetNameEdited,
          description: graphPresetDescriptionEdited
        }
      });
    } else {
      setGraphPresetNameReadOnly(true);
      setGraphPresetDescriptionReadOnly(true);
      changePresetName({
        name: graphPresetNameEdited
      });
      changePresetDescription({
        description: graphPresetDescriptionEdited
      });
      handlerGraphPresetSave();
    }
  }, [
    preset,
    saveAsMode,
    setSaveAsMode,
    setGraphPresetNameReadOnly,
    setGraphPresetDescriptionReadOnly,
    requestCreateGraphPreset,
    changePresetName,
    changePresetDescription,
    handlerGraphPresetSave,
    graphPresetDescriptionEdited,
    graphPresetNameEdited
  ]);

  const handlerGraphPresetSaveAs = useCallback(() => {
    setSaveAsMode(true);
    setGraphPresetNameReadOnly(false);
    setGraphPresetDescriptionReadOnly(true);
    const newGraphPresetName = `${preset?.preset?.name} (copy)`;
    setGraphPresetNameEdited(newGraphPresetName);
    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      graphPresetNameInputRef?.current?.focus();
      // eslint-disable-next-line no-unused-expressions
      graphPresetNameInputRef?.current?.setSelectionRange(0, newGraphPresetName.length);
    }, 0);
  }, [
    preset,
    graphPresetNameInputRef,
    setSaveAsMode,
    setGraphPresetNameReadOnly,
    setGraphPresetDescriptionReadOnly,
    setGraphPresetNameEdited
  ]);

  const handlerGraphPresetRevert = useCallback(() => {
    if (preset.preset.id) {
      requestGetGraphPreset({ id: preset.preset.id });
    }
  }, [requestGetGraphPreset, preset]);

  const handlerResetWorkspace = useCallback(() => {
    requestMetricsTree({
      ids: null,
    });
    clearWorkspace(null);
  }, [clearWorkspace, requestMetricsTree]);


  useOnClickOutside(graphPresetNameInputRef, saveMetaInformation);
  useOnClickOutside(graphPresetDescriptionInputRef, saveMetaInformation);

  return (
    <div className={styles.graphsSettingsHeader}>
      <div className={styles.graphsSettingsOperations}>
        <div className={styles.graphPresetNameEditor}>
          {graphPresetNameReadOnly && (
            <div
              role='button'
              tabIndex={0}
              className={classnames(styles.graphPresetNameDiv)}
              onClick={handlerGraphPresetNameClick}
              ref={graphPresetNameRef}
            >
              {graphPresetNameEdited || <FormattedMessage id='graphs.graphPresetNamePlaceholder' />}
              {isSavedPreset && isEditedPreset && <span className={styles.edited}><FormattedMessage id='graphs.edited' /></span>}
            </div>
          )}
          {!graphPresetNameReadOnly && (
            <input
              type='text'
              className={classnames(styles.graphPresetNameInput)}
              value={graphPresetNameEdited}
              onKeyDown={handlerGraphPresetNameKeyDown}
              onChange={handlerGraphPresetNameChange}
              placeholder={formatMessage({ id: 'graphs.graphPresetNamePlaceholder' })}
              ref={graphPresetNameInputRef}
            />
          )}
          {((!isSavedPreset && isHaveMetricsPreset && graphPresetType !== 'standard' && (!graphPresetNameReadOnly || !graphPresetDescriptionReadOnly)) || saveAsMode) && (
            <BigButton
              className={classnames(styles.graphsPresetNameSave, styles.bigButton, { [styles.disabled]: !isNoGroupsErrors })}
              disabled={!isNoGroupsErrors}
              onClick={handlerGraphPresetNameSave}
              title={<FormattedMessage id='button.save' />}
            />
          )}
          {((!isSavedPreset && isHaveMetricsPreset && graphPresetType !== 'standard' && (!graphPresetNameReadOnly || !graphPresetDescriptionReadOnly)) || saveAsMode) && (
            <BigButton
              className={classnames(styles.graphsPresetNameCancel, styles.bigButton)}
              onClick={handlerGraphPresetNameCancel}
              title={<FormattedMessage id='button.cancel' />}
            />
          )}
        </div>
        <div className={styles.buttons}>
          {isSavingButtonsVisible && !saveAsMode && (
            <div className={styles.presetButtons}>
              {isHaveMetricsPreset && graphPresetType !== 'standard' && isEditedPreset && (
                <BigButton
                  className={classnames(styles.button, styles.savePreset, styles.bigButton, { [styles.disabled]: !isNoGroupsErrors })}
                  disabled={!isNoGroupsErrors}
                  onClick={handlerGraphPresetSave}
                  title={<FormattedMessage id='graphs.savePreset' />}
                />
              )}
              {isHaveMetricsPreset && isSavedPreset && (
                <BigButton
                  className={classnames(styles.button, styles.saveAsPreset, styles.bigButton, { [styles.disabled]: !isNoGroupsErrors })}
                  disabled={!isNoGroupsErrors}
                  onClick={handlerGraphPresetSaveAs}
                  title={<FormattedMessage id='graphs.saveAsPreset' />}
                />
              )}
              {isSavedPreset && graphPresetType !== 'standard' && isEditedPreset && (
                <BigButton
                  className={classnames(styles.button, styles.revertPreset, styles.bigButton)}
                  onClick={handlerGraphPresetRevert}
                  title={<FormattedMessage id='graphs.revertPreset' />}
                />
              )}
            </div>
          )}
          {!saveAsMode && (isHaveMetricsPreset || isSavedPreset) && isSavingButtonsVisible && <div className={styles.buttonsDelimeter} />}
          <div className={classnames(styles.workspaceButtons)}>
            <BigButton
              className={classnames(styles.button, styles.savedReports, styles.bigButton)}
              onClick={handlerOpenSavedReports}
              title={<FormattedMessage id='graphs.savedReports' />}
              icon={<SaveIcon />}
              bigIcon
            />
            <BigButton
              className={classnames(styles.button, styles.resetWorkspace, styles.bigButton)}
              onClick={handlerResetWorkspace}
              title={<FormattedMessage id='graphs.resetWorkspace' />}
              icon={<RefreshIcon />}
              bigIcon
            />
          </div>
        </div>
      </div>
      <div className={styles.graphsSettingsDescriptionEditor}>
        {graphPresetDescriptionReadOnly && (graphPresetDescriptionEdited || !graphPresetNameReadOnly) && (
          <div
            role='button'
            tabIndex={0}
            className={classnames(styles.graphPresetDescriptionDiv)}
            onClick={handlerGraphPresetDescriptionClick}
          >
            {graphPresetDescriptionEdited || (!graphPresetNameReadOnly && <FormattedMessage className='test' id='graphs.graphPresetDescriptionPlaceholder' />)}
          </div>
        )}
        {!graphPresetDescriptionReadOnly && (
          <input
            type='text'
            className={classnames(styles.graphPresetDescriptionInput)}
            value={graphPresetDescriptionEdited}
            onKeyDown={handlerGraphPresetDescriptionKeyDown}
            onChange={handlerGraphPresetDescriptionChange}
            placeholder={formatMessage({ id: 'graphs.graphPresetDescriptionPlaceholder' })}
            ref={graphPresetDescriptionInputRef}
          />
        )}
      </div>
    </div>
  );
};

export default injectIntl(GraphsPresetMeta);
