import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import {
  getActiveVersionId,
  getFilteredPlanVersions,
  getVersionsFilter,
  getIsDeleteVersionInfoFetching,
  getPlansEditorSaving,
  getAllPlanVersions,
  getIsVersionInfoFetching,
} from 'store/plansEditor/selectors';

import { setVersionsFilter } from 'store/plansEditor/actions';

import PlansEditorSidePanel from '../components/PlansEditorSidePanel';

const mapDispatchToProps = {
  setVersionsFilter,
};

const mapStateToProps = (state, props) => ({
  ...props,

  activeVersionId: getActiveVersionId(state, props),
  versions: getFilteredPlanVersions(state),
  versionsFilter: getVersionsFilter(state),
  isDeleteVersionInfoFetching: getIsDeleteVersionInfoFetching(state),
  isPlansEditorSaving: getPlansEditorSaving(state),
  allVersions: getAllPlanVersions(state),
  isVersionInfoFetching: getIsVersionInfoFetching(state),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PlansEditorSidePanel));
