import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import classnames from 'classnames';

import MobileMenu from '../MobileMenu';
import BigSelectInput from '../BigSelectInput';
import BigButton from '../BigButton';
import FiltersIcon from '../Icons/FiltersIcon';

import styles from './DashboardTargetsFiltersMobile.module.css';

class DashboardFiltersMobile extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    onFiltersChange: PropTypes.func.isRequired,

    unitsOptions: PropTypes.array.isRequired,
    qualityOptions: PropTypes.array.isRequired,
    currentUnits: PropTypes.string,
    currentQuality: PropTypes.string,
  };

  static defaultProps = {
    currentQuality: '1',
    currentUnits: 'kilogram',
  };

  state = {
    quality: this.props.currentQuality,
    units: this.props.currentUnits,

    mobileMenuOpened: false,
  }

  handlerShowResults = () => {
    const { onFiltersChange } = this.props;

    const {
      quality, units
    } = this.state;

    const newParams = {
      category: quality,
      isRelative: units === 'kilogramPerSquareMeter' || false,
    };

    this.setState({
      mobileMenuOpened: false,
    });

    onFiltersChange(newParams);
  };


  handlerQualitySelect = options => this.setState({ quality: options.value });

  handlerUnitsSelect = options => this.setState({ units: options.value });

  handlerCloseMenu = () => this.setState({
    mobileMenuOpened: false,
    quality: this.props.currentQuality,
    units: this.props.currentUnits,
  });

  handlerOpenMenu = () => this.setState({
    mobileMenuOpened: true,
  });

  renderDefaultSelectBox = select => (
    <div key={`select-${select.name}`} className={classnames(styles.selectWrapper, select.className)}>
      <BigSelectInput
        classNameButton={select.classNameButton}
        classNamePopup={select.classNamePopup}
        disabled={select.disabled}
        options={select.options}
        value={select.value}
        placeholder={select.placeholder}
        onChange={select.handler}
        labelPath='label'
        valuePath='value'
        closeOnChange
        textEllipsis
      />
    </div>
  );

  render() {
    const {
      intl: { formatMessage },
      unitsOptions,
      qualityOptions,
    } = this.props;

    const {
      mobileMenuOpened,
      units,
      quality,
    } = this.state;

    const compartmentsFilter = {
      name: 'quality',
      value: quality,
      options: qualityOptions,
      handler: this.handlerQualitySelect,
      placeholder: formatMessage({ id: 'harvestDashboard.quality' }),
    };

    const unitsFilter = {
      name: 'units',
      value: units,
      options: unitsOptions,
      handler: this.handlerUnitsSelect,
      classNameButton: styles.unitsFilter,
      classNamePopup: styles.unitsFilterPopup,
      placeholder: formatMessage({ id: 'harvestDashboard.units' }),
    };

    return (
      <MobileMenu
        className={styles.mobileMenu}
        icon={<FiltersIcon />}
        headerText={formatMessage({ id: 'harvestDashboard.filtersHeader' })}
        onClose={this.handlerCloseMenu}
        onOpen={this.handlerOpenMenu}
        menuOpened={mobileMenuOpened}
      >
        <div className={styles.container}>
          <div className={styles.filtersContainer}>
            <div className={styles.selectGroup}>
              <div className={styles.selectGroupHeader}>
                {formatMessage({ id: 'dashboards.harvest' })}
              </div>
              {this.renderDefaultSelectBox(compartmentsFilter)}
            </div>

            <div className={styles.selectGroup}>
              <div className={styles.selectGroupHeader}>
                {formatMessage({ id: 'harvestDashboard.units' })}
              </div>
              {this.renderDefaultSelectBox(unitsFilter)}
            </div>
          </div>

          <div className={styles.mobileControls}>
            <BigButton
              className={styles.cancelButton}
              title={formatMessage({ id: 'button.cancel' })}
              type='button'
              onClick={this.handlerCloseMenu}
              theme='light'
            />
            <BigButton
              title={formatMessage({ id: 'cropsPerformance.showResults' })}
              type='button'
              onClick={this.handlerShowResults}
              theme='dark'
            />
          </div>
        </div>
      </MobileMenu>
    );
  }
}

export default injectIntl(DashboardFiltersMobile);
