import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { HotTable } from '@handsontable/react';

import classnames from 'classnames';

import 'handsontable/dist/handsontable.full.css';
import styles from './DefaultHotTable.module.css';

const DefaultHotTable = forwardRef(({ classNameWrapper, ...props }, ref) => (
  <div className={classnames(styles.table, classNameWrapper)}>
    <HotTable {...props} ref={ref} />
  </div>
));

DefaultHotTable.propTypes = {
  classNameWrapper: PropTypes.string,
};

DefaultHotTable.defaultProps = {
  classNameWrapper: null,
};

export default DefaultHotTable;
