import React, { Component, } from 'react';
import PropTypes from 'prop-types';
// import { injectIntl, intlShape } from 'react-intl';

import classnames from 'classnames';
import differenceInDays from 'date-fns/differenceInDays';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';

import { formatDate } from 'helpers/datesHelper';

import ArrowLeftIcon from 'components/Icons/ArrowLeftIcon';
import ArrowRightIcon from 'components/Icons/ArrowRightIcon';

import { ReactComponent as ToggleIcon } from './assets/icon.svg';
// import { ReactComponent as MobileDateIcon } from './assets/date_icon.svg';

import styles from './DateButton.module.css';

class DateButton extends Component {
  static propTypes = {
    // intl: intlShape.isRequired,
    updateDates: PropTypes.func.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    className: undefined,
    onClick: null,
    date: null,
    disabled: false,
    startDate: undefined,
    endDate: undefined,
  };

  handlerArrowClick = (operation, updateDates, startDate, endDate) => {
    // Если даты находятся в одном периоде (например для одной недели пн и вс), то diff будет 0. В этом случае берём единицу.
    const currentStartDate = startDate;
    const currentEndDate = endDate;

    const daysDifference = differenceInDays(
      currentEndDate,
      currentStartDate,
    ) + 1;

    const newStartDate = operation === 'subtract' ? subDays(currentStartDate, daysDifference) : addDays(currentStartDate, daysDifference);
    const newEndDate = operation === 'subtract' ? subDays(currentEndDate, daysDifference) : addDays(currentEndDate, daysDifference);

    updateDates({
      startDate: formatDate(newStartDate, 'yyyy-MM-dd'),
      endDate: formatDate(newEndDate, 'yyyy-MM-dd'),
    });
  };

  render() {
    const {
      className, date, disabled, onClick, startDate, endDate, updateDates,
    } = this.props;

    return (
      <div className={styles.buttonWrapper}>
        <button
          type='button'
          className={classnames(styles.leftButton)}
          // onClick={e => e.stopPropagation()}
          // className={classnames(styles.leftButton, { [styles.disabled]: disabled })}
          onClick={(e) => {
            e.stopPropagation();

            this.handlerArrowClick('subtract', updateDates, startDate, endDate);
          }}
          // handlerArrowClick = (operation, setPickerState, pickerState
          // disabled={disabled}
        >
          <ArrowLeftIcon />
        </button>
        <button
          type='button'
          className={classnames(styles.button, className, { [styles.disabled]: disabled })}
          onClick={onClick}
          disabled={disabled}
        >
          {/* <span className={styles.placeholder}>
            {formatMessage({ id: 'harvest.date' })}
          </span> */}
          {date}
          <ToggleIcon className={styles.icon} />
          {/* <MobileDateIcon className={styles.mobileIcon} /> */}
        </button>
        <button
          type='button'
          className={classnames(styles.rightButton)}
          // onClick={e => e.stopPropagation()}
          onClick={(e) => {
            e.stopPropagation();

            this.handlerArrowClick('add', updateDates, startDate, endDate);
          }}
          // className={classnames(styles.rightButton, { [styles.disabled]: disabled || isAddArrowDisabled })}
          // onClick={() => this.handlerArrowClick('add', startDate, endDate, periodType)}
          // disabled={disabled || isAddArrowDisabled}
        >
          <ArrowRightIcon />
        </button>
      </div>
    );
  }
}

export default DateButton;
