// TODO: !!!!!! Не копипастить в labor, вынести в общие компоненты
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { get } from 'lodash';

import checkSpecies from 'helpers/checkSpecies';
import getDatePeriodRange from 'helpers/getDatePeriodRange';
import updateLocationSearch from 'helpers/updateLocationSearch';
import addGetParameters from 'helpers/addGetParameters';

import { API_DATE_FORMAT, getDefaultDateRange } from 'helpers/defaultDates';

import MobileFiltersIcon from 'components/Icons/MobileFilters';

import Typography from 'components/Typography';
import CloseIcon from 'components/Icons/Close';
import DefaultPlate from 'components/DefaultPlate';
import DashboardHeader from 'components/DashboardHeader';
import DashboardComplexFilters from 'components/DashboardComplexFilters';
import CircleLoader from 'components/CircleLoader';
import loaderStyles from 'components/CircleLoader/CircleLoader.module.css';
// import EnergyDashboardFiltersMobile from '../EnergyDashboardFiltersMobile';
import DashboardReport from '../DashboardReport';

import styles from './EnergyDashboard.module.css';


const DEFAULT_DATE_RANGE = getDefaultDateRange();

const METER_TYPES = ['gasMeter', 'electricityMeter', 'waterMeter'];

// TODO: Сделать рефакторинг компонента и составляющих при завершении labor, вытащить общие компоненты и удалить копипасту
// Сыпется очень много ворнингов от react-intl Missing message: "cunits.mini.null" for locale: "en"
export default class EnergyDashboard extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    history: PropTypes.object.isRequired,
    energyReport: PropTypes.object,
    energyReportSorting: PropTypes.object.isRequired,
    periodType: PropTypes.string.isRequired,
    organizationSlug: PropTypes.string.isRequired,
    currentCompartment: PropTypes.string,
    currentBreakdown: PropTypes.string,
    currentSpecies: PropTypes.string,
    currentVarietyId: PropTypes.string,
    currentFruitClassCode: PropTypes.string,
    currentUnitKind: PropTypes.string,
    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
    fruitClasses: PropTypes.array,
    varieties: PropTypes.array,
    allSpecies: PropTypes.array,
    productGroups: PropTypes.array.isRequired,
    requestEnergyReport: PropTypes.func.isRequired,
    updateFiltersEnergyReport: PropTypes.func.isRequired,
    updateEnergyReportSorting: PropTypes.func.isRequired,
    clearEnergyReport: PropTypes.func.isRequired,
    isEnergyReportFetching: PropTypes.bool,
  };

  static defaultProps = {
    fruitClasses: [],
    varieties: [],
    allSpecies: [],
    energyReport: null,
    currentUnitKind: 'absolute',
    currentBreakdown: 'compartment',
    currentCompartment: undefined,
    currentSpecies: undefined,
    currentVarietyId: null,
    currentFruitClassCode: null,
    anyDateOfPeriodStart: DEFAULT_DATE_RANGE.startDate,
    anyDateOfPeriodEnd: DEFAULT_DATE_RANGE.endDate,
    isEnergyReportFetching: false,
  };

  state = {
    isDifferentUnitsWarningClose: false,
  };

  componentDidMount() {
    const {
      requestEnergyReport,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,
      currentBreakdown,
      currentCompartment,
      currentUnitKind,
      currentSpecies,
      currentVarietyId,
      currentFruitClassCode,
      productGroups,
    } = this.props;

    requestEnergyReport({
      // meterType: ['climateZone', 'irrigationValve', 'drain', 'electricityMeter', 'gasMeter', 'waterMeter', 'wirelessCensorsSubsystem'],
      meterType: METER_TYPES,
      periodType,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      breakdown: currentBreakdown,
      species: currentSpecies || undefined,
      varietyId: currentVarietyId,
      fruitClassCode: currentFruitClassCode,
      compartmentId: currentCompartment || undefined,
      unitKind: currentUnitKind,
      productGroups,
    });

    /**
     * Устанавливаем listener на переход по кнопкам браузера назад/вперёд,
     * чтобы обновлять данные в соответствии с изменившимся search запросом в урле
    */
    // this.backListener = history.listen((location, action) => {
    //   if (action === 'POP') {
    //     requestEnergyReport(location.search);
    //   }
    // });
  }

  componentWillUnmount() {
    const { clearEnergyReport } = this.props;

    clearEnergyReport();
  }

  handlerRowClick = ({ row, e, key }) => {
    e.stopPropagation();

    updateLocationSearch({ [key]: row.key });
  };

  handlerPlateClose = () => {
    this.setState({ isDifferentUnitsWarningClose: true });
  };

  handlerFiltersChange = (newParams) => {
    const {
      history,
      updateFiltersEnergyReport,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,
      currentSpecies,
      currentVarietyId,
      currentFruitClassCode,
      currentBreakdown,
      currentCompartment,
      currentUnitKind,
      productGroups,
    } = this.props;

    // TODO: we need to make upgrade of query-string for {arrayFormat: 'comma'}
    const preparedParams = newParams ? Object.keys(newParams).reduce((reducer, key) => {
      const temp = reducer;

      const item = newParams[key];

      if (Array.isArray(item)) {
        temp[key] = item.join(',');
      } else {
        temp[key] = item;
      }
      return temp;
    }, {}) : {};

    const searchString = addGetParameters(get(history, 'location.search'), preparedParams);

    history.push({
      search: searchString
    });

    const { unitKind, breakdown, ...payload } = newParams;

    if ('unitKind' in newParams) {
      payload.unitKind = unitKind || 'absolute';
    }

    if ('breakdown' in newParams) {
      payload.breakdown = breakdown || 'compartment';
    }

    updateFiltersEnergyReport({
      // meterType: ['climateZone', 'irrigationValve', 'drain', 'electricityMeter', 'gasMeter', 'waterMeter', 'wirelessCensorsSubsystem'],
      meterType: METER_TYPES,
      periodType,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      breakdown: currentBreakdown,
      species: currentSpecies || undefined,
      varietyId: currentVarietyId,
      fruitClassCode: currentFruitClassCode,
      compartmentId: currentCompartment || undefined,
      unitKind: currentUnitKind,
      productGroups,

      ...payload,
    });
  };

  render() {
    const {
      intl, periodType, energyReport, energyReportSorting,
      anyDateOfPeriodStart, anyDateOfPeriodEnd, allSpecies, fruitClasses, varieties,
      currentSpecies,
      currentUnitKind,
      currentFruitClassCode,
      currentVarietyId,
      currentBreakdown,
      // currentCompartment,
      organizationSlug,
      isEnergyReportFetching,
      updateEnergyReportSorting,
    } = this.props;

    const { isDifferentUnitsWarningClose } = this.state;

    const { formatMessage } = intl;

    const currentGrownFilter = currentVarietyId || currentFruitClassCode || currentSpecies;

    const isAllSpecies = !currentGrownFilter;

    const { isHasLettuce, isHasVegetables } = checkSpecies({
      isAllSpecies,
      species: allSpecies,
      varieties,
      fruitClasses,
      currentSpecies,
      currentVarietyId,
      currentFruitClassCode,
    });

    const isDifferentUnitsWarningShow = !isDifferentUnitsWarningClose && isAllSpecies && currentUnitKind !== 'absolute' && currentUnitKind !== 'perArea' && isHasLettuce && isHasVegetables;

    const startOfPeriod = moment(anyDateOfPeriodStart, API_DATE_FORMAT);
    const endOfPeriod = moment(anyDateOfPeriodEnd, API_DATE_FORMAT).clone().endOf(periodType);

    const { start, end } = getDatePeriodRange({
      date: null,
      periodType: 'whole',
      minDate: startOfPeriod,
      maxDate: endOfPeriod,
      formatMessage,
    });

    const rangeText = `${start} – ${end}`;

    const customRanges = [
      {
        label: formatMessage({ id: 'dashboards.daily' }),
        value: 'day',
      },
      {
        label: formatMessage({ id: 'dashboards.weekly' }),
        value: 'week',
      },
      {
        label: formatMessage({ id: 'dashboards.monthly' }),
        value: 'month',
      },
    ];

    return (
      <div className={styles.layout}>
        <div className={styles.contentWrapper}>
          <DashboardHeader
            defaultPeriodType={periodType}
            customRanges={customRanges}
            dashboardName={formatMessage({ id: 'dashboards.energyResources' })}
            handlerAfterRangeSelect={this.handlerFiltersChange}
            containerClassName={styles.dashboardHeader}
            text={rangeText}
            filters={(
              <DashboardComplexFilters
                filters={['variety', 'breakdown', 'compartment', 'unit']}
                onFiltersChange={this.handlerFiltersChange}
                renderMode='mobile'
                mobileFilterIcon={<MobileFiltersIcon />}
                mobileContainerClassName={styles.mobileContainer}
              />
            )}
          />

          <DashboardComplexFilters
            filters={['variety', 'breakdown', 'compartment', 'unit']}
            onFiltersChange={this.handlerFiltersChange}
            renderMode='desktop'
          />

          {isDifferentUnitsWarningShow ? (
            <DefaultPlate className={styles.infoPlate}>
              <div className={styles.infoPlateContent}>
                <Typography variant='body1'>{formatMessage({ id: 'dashboards.differentUnitsWarning' })}</Typography>
                <button type='button' className={styles.infoPlateClose} onClick={this.handlerPlateClose}>
                  <CloseIcon />
                </button>
              </div>
            </DefaultPlate>
          ) : null}

          {energyReport && METER_TYPES.filter(key => energyReport[key]).map(key => (
            <DashboardReport
              key={`report-${key}`}
              meter={key}
              graphKeyId={key.replace('Meter', '')}
              report={get(energyReport, key)}
              sorting={energyReportSorting}
              onGraphKeyChange={this.handlerGraphKeyChange}
              onRowClick={this.handlerRowClick}
              onUpdateSorting={updateEnergyReportSorting}
              currentSpecies={currentSpecies}
              currentFruitClassCode={currentFruitClassCode}
              currentVarietyId={currentVarietyId}
              currentBreakdown={currentBreakdown}
              currentUnitKind={currentUnitKind}
              organizationSlug={organizationSlug}
            />
          ))}

          {isEnergyReportFetching && (
            <div className={styles.loader}>
              <CircleLoader className={loaderStyles.circleLoader} iconClassName={loaderStyles.circleLoaderIcon} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
