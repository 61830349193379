import {
  processingPageView, processingUserProperties, processingUserId, processingUserEvent
} from '../../helpers/analytics';

export const SEND_PAGE_VIEW = 'SEND_PAGE_VIEW';
export const SEND_USER_PROPERTIES = 'SEND_USER_PROPERTIES';
export const SEND_USER_ID = 'SEND_USER_ID';
export const SEND_USER_EVENT = 'SEND_USER_EVENT';


function sendPageView() {
  return {
    type: SEND_PAGE_VIEW,
  };
}

function sendUserId() {
  return {
    type: SEND_USER_ID,
  };
}

function sendUserProperties() {
  return {
    type: SEND_USER_PROPERTIES,
  };
}

function sendUserEvent() {
  return {
    type: SEND_USER_EVENT,
  };
}

export function trackPageView({ path, data = {} }) {
  return async (dispatch) => {
    await processingPageView({ path, data });
    await dispatch(sendPageView());
  };
}

export function trackUserProperties({ data = {} }) {
  return async (dispatch) => {
    await processingUserProperties({ data });
    await dispatch(sendUserProperties());
  };
}

export function trackUserId({ userId }) {
  return async (dispatch) => {
    await processingUserId({ userId });
    await dispatch(sendUserId());
  };
}

export function trackUserEvent({ event, data = {} }) {
  return async (dispatch) => {
    await processingUserEvent({ event, data });
    await dispatch(sendUserEvent());
  };
}
