import React from 'react';

import DigitalTwinNavigation from 'components/DigitalTwin/components/DigitalTwinNavigation';
import EssentialPricePlan from 'components/EssentialPricePlan';

import styles from './EssentialPricePlanDigitalTwin.module.css';

const EssentialPricePlanDigitalTwin = () => (
  <>
    <DigitalTwinNavigation />
    <div className={styles.layout}>
      <EssentialPricePlan className={styles.pricePlan} />
    </div>
  </>
);

EssentialPricePlanDigitalTwin.propTypes = {};

export default EssentialPricePlanDigitalTwin;
