import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import moment from 'moment';

import { API_DATE_FORMAT } from 'helpers/defaultDates';
import { getMlNameDirect } from 'helpers/getVarietyName';

import Typography from 'components/Typography';
import Select from 'components/Select';
import RangePicker from 'components/RangePicker';

import styles from './CropsSelectBlock.module.css';

const CropsSelectBlock = ({
  intl,
  timezone,
  varieties,
  currentVariety,
  onChangeDateRange,
  onChangeVariety,
  seedingDate,
  endDate,
  error,
  isDataFetching,
  minDaysPeriod,
  maxDaysPeriodTomato,
  showNotificationWithTimeout,
  varietySelectRef,
}) => {
  const { formatMessage, locale } = intl;

  const getName = (variety) => {
    const varietyName = getMlNameDirect(variety, locale);
    const fruitClassName = formatMessage({ id: `fruitClass.${variety.fruitClass}` });

    return `${varietyName}, ${fruitClassName}`;
  };

  const isSameYear = moment(seedingDate, API_DATE_FORMAT).year() === moment(endDate, API_DATE_FORMAT).year();

  const options = varieties.map(variety => ({
    label: getName(variety),
    value: variety.id
  }));

  const handleChangeDateRange = useCallback(({
    anyDateOfPeriodStart,
    anyDateOfPeriodEnd,
  }) => {
    const start = moment(anyDateOfPeriodStart, API_DATE_FORMAT);
    const end = moment(anyDateOfPeriodEnd, API_DATE_FORMAT);
    const periodDays = moment.duration(end.diff(start)).asDays();
    if (periodDays < minDaysPeriod) {
      showNotificationWithTimeout({
        id: `notifications.minDaysPeriodError.${Date.now()}`,
        messageId: 'notifications.minDaysPeriodError',
        position: 'leftDown',
        iconType: 'error',
        notificationType: 'withActionWide',
      });
      return;
    }
    const isCurrentVarietyTomato = varieties.find(variety => variety.id === currentVariety)?.species === 'tomato';
    if (isCurrentVarietyTomato && periodDays > maxDaysPeriodTomato) {
      showNotificationWithTimeout({
        id: `notifications.maxDaysPeriodTomatoError.${Date.now()}`,
        messageId: 'notifications.maxDaysPeriodTomatoError',
        position: 'leftDown',
        iconType: 'error',
        notificationType: 'withActionWide',
      });
      return;
    }
    if (onChangeDateRange) {
      onChangeDateRange({
        anyDateOfPeriodStart,
        anyDateOfPeriodEnd,
      });
    }
  }, [
    onChangeDateRange,
    currentVariety,
    showNotificationWithTimeout,
    varieties,
    maxDaysPeriodTomato,
    minDaysPeriod
  ]);

  return (
    <div className={styles.cropsSelectBlock}>
      <Typography variant='h3' className={styles.headerTitle}>
        {formatMessage({ id: 'harvestDashboard.crop' })}
      </Typography>
      <div className={styles.selectLine} ref={varietySelectRef}>
        <span className={styles.lineTitle}>{formatMessage({ id: 'plans.variety' })}</span>
        <Select
          className={styles.selectWrapper}
          classNameButton={styles.selectButton}
          classNamePopup={styles.selectPopup}
          placeholder={formatMessage({ id: 'cropModel.selectVariety' })}
          options={options}
          value={currentVariety}
          onChange={onChangeVariety}
          labelPath='label'
          valuePath='value'
          closeOnChange
          error={error}
          textEllipsis
        />
      </div>
      <div className={styles.selectLine}>
        <span className={styles.lineTitle}>
          {formatMessage({ id: 'cropModel.seedingAndEnd' })}
        </span>
        <RangePicker
          className={styles.rangePicker}
          timezone={timezone}
          handlerAfterRangeSelect={handleChangeDateRange}
          withoutRangePicker
          withoutAddDisable
          withoutArrow
          defaultPeriodType='day'
          dateButtonClassName={styles.dateButton}
          anyDateOfPeriodStart={seedingDate}
          anyDateOfPeriodEnd={endDate}
          firstDateFormat={isSameYear ? 'll' : 'lll'}
          enableFutureDates
          disabled={isDataFetching}
        />
      </div>
    </div>
  );
};

CropsSelectBlock.propTypes = {
  intl: intlShape.isRequired,
  timezone: PropTypes.string,
  varieties: PropTypes.array,
  currentVariety: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChangeDateRange: PropTypes.func.isRequired,
  onChangeVariety: PropTypes.func.isRequired,
  seedingDate: PropTypes.string,
  endDate: PropTypes.string,
  error: PropTypes.string,
  isDataFetching: PropTypes.bool,
  minDaysPeriod: PropTypes.number,
  maxDaysPeriodTomato: PropTypes.number,
  showNotificationWithTimeout: PropTypes.func.isRequired,
  varietySelectRef: PropTypes.object,
};

CropsSelectBlock.defaultProps = {
  timezone: 'Europe/London',
  varieties: [],
  currentVariety: null,
  error: null,
  isDataFetching: false,
  endDate: null,
  seedingDate: null,
  minDaysPeriod: 84,
  maxDaysPeriodTomato: 730,
  varietySelectRef: null,
};

export default CropsSelectBlock;
