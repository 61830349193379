import { get } from 'lodash';
import moment from 'moment-timezone';

import numbersRounding from './numbersRounding';
import getDateFormat from './getDateFormat';

export default function normalizeCycle(cycle, locale) {
  const rounding = get(cycle, 'cycle.species') === 'lettuce' ? 0 : 1;

  return {
    id: get(cycle, 'cycle.id'),
    varietyName: get(cycle, `variety.attributes.mlName[${locale}]`) || get(cycle, 'variety.attributes.mlName.en'),
    varietyFruitClass: get(cycle, 'variety.attributes.fruitClass'),
    species: get(cycle, 'variety.attributes.species'),
    compartmentName: get(cycle, 'compartment.attributes.name'),
    cycleWeek: get(cycle, 'weekNo'),
    harvestValue: numbersRounding(get(cycle, 'totalHarvest.value.amount'), 'fixed', rounding),
    totalGoalValue: numbersRounding(get(cycle, 'totalHarvest.totalGoal.amount'), 'fixed', rounding),
    startDate: get(cycle, 'cycle.startDate') ? moment(get(cycle, 'cycle.startDate')).format(getDateFormat('lll')) : undefined,
    endDate: get(cycle, 'cycle.endDate') ? moment(get(cycle, 'cycle.endDate')).format(getDateFormat('lll')) : undefined,
    plantingDate: get(cycle, 'cycle.plantingDate') ? moment(get(cycle, 'cycle.plantingDate')).format(getDateFormat('lll')) : undefined,
    totalGoalUnits: get(cycle, 'totalHarvest.totalGoal.units'),
    valueUnits: get(cycle, 'totalHarvest.value.units'),
    activeStatus: get(cycle, 'activeStatus'),
  };
}
