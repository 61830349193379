import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import initAxiosInstanse from '../../api/axios';

import { trackUserId, } from '../analytics/actions';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

import history from '../../history';
import storageWrapper from '../../helpers/storageWrapper';

import {
  requestSendResetEmail,
  receiveSendResetEmail,

  requestResetPassword,
  receiveResetPassword,

  requestValidateToken,
  receiveValidateToken,

  requestUpdatePassword,
  receiveUpdatePassword,

  requestSendingCode,
  receiveSendingCode,

  requestVerifyCode,
  receiveVerifyCode,
  receiveVerifyCodeError,

  clearLoginError,
  clearLoginErrorWithDelay,
} from './actions';

import {
  getLogin, receiveUser, requestUserAuth, requestSetUser
} from '../company/actions';

const safeLocalStorage = storageWrapper.get('localStorage');

const getErrorMessageCode = (errorCode) => {
  switch (errorCode) {
    case 'TRY_AGAIN_LATER':
      return 'login.tryAgainLater';
    case 'SENDING_FAILURE':
      return 'login.authError';
    default:
      return 'notification.backendError';
  }
};

export const sendResetEmailEpic = (action$, store) =>
  action$.ofType(requestSendResetEmail)
    .switchMap((actionData) => {
      const {
        payload: {
          email,
        }
      } = actionData;

      const { intl: { locale } } = store.getState();

      return Observable
        .from(initAxiosInstanse().post('password/send-reset-email', {
          email,
          lang: locale,
        }))
        .mergeMap(({ data }) => {
          if (data && data.result === 'delayed') {
            return Observable.of(
              receiveSendResetEmail({ emailToReset: null }),
              showNotificationWithTimeout({
                id: `notifications.sendResetEmailError.${Date.now()}`,
                messageId: 'notifications.sendResetEmailError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withActionWide',
              }),
            );
          }

          return Observable.of(receiveSendResetEmail({ emailToReset: email }));
        })
        .catch(() => Observable.of(
          receiveSendResetEmail({ emailToReset: null }),
          showNotificationWithTimeout({
            id: `notifications.sendResetEmailError.${Date.now()}`,
            messageId: 'notifications.sendResetEmailError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const resetPasswordEpic = action$ =>
  action$.ofType(requestResetPassword)
    .switchMap((actionData) => {
      const {
        payload: {
          token,
          password,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse().post('password/update', {
          token,
          password,
        }))
        .mergeMap(({ data }) => {
          if (!data || !data.token) {
            return Observable.of(
              receiveResetPassword(),
              showNotificationWithTimeout({
                id: `notifications.sendResetEmailError.${Date.now()}`,
                messageId: 'notifications.sendResetEmailError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withActionWide',
              }),
            );
          }

          const newToken = data.token;
          safeLocalStorage.setItem('jwt', newToken);

          return Observable.of(
            receiveResetPassword(),
            getLogin(history, true)
          );
        })
        .catch(() => Observable.of(
          receiveResetPassword(),
          showNotificationWithTimeout({
            id: `notifications.sendResetEmailError.${Date.now()}`,
            messageId: 'notifications.sendResetEmailError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const validateTokenEpic = action$ =>
  action$.ofType(requestValidateToken)
    .switchMap((actionData) => {
      const {
        payload: {
          jwt,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse().get(`password/validate-token/${jwt}`))
        .mergeMap(({ data }) => Observable.of(receiveValidateToken({ tokenValidation: data.result })))
        .catch(() => Observable.of(
          receiveValidateToken({ tokenValidation: null }),
          showNotificationWithTimeout({
            id: `notifications.sendResetEmailError.${Date.now()}`,
            messageId: 'notifications.sendResetEmailError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const updatePasswordEpic = action$ =>
  action$.ofType(requestUpdatePassword)
    .switchMap((actionData) => {
      const {
        payload: {
          oldPassword,
          newPassword,
          currentPasswordError,
          actionAfterSuccess,
        },
        meta: {
          resolve,
          reject,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse().post('password/change', {
          oldPassword,
          newPassword,
        }))
        .mergeMap(({ data }) => {
          if (data && data.result === 'invalid-password') {
            if (reject) reject({ currentPassword: currentPasswordError });

            return Observable.of(receiveUpdatePassword());
          }

          if (!data || !data.token) {
            return Observable.of(
              receiveUpdatePassword(),
              showNotificationWithTimeout({
                id: `notifications.sendResetEmailError.${Date.now()}`,
                messageId: 'notifications.sendResetEmailError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withActionWide',
              }),
            );
          }

          const newToken = data.token;
          safeLocalStorage.setItem('jwt', newToken);

          if (resolve) resolve();

          return Observable.of(
            actionAfterSuccess,
            receiveUpdatePassword(),
            showNotificationWithTimeout({
              id: `notifications.setPasswordNotification.${Date.now()}`,
              messageId: 'notifications.setPasswordNotification',
              position: 'leftDown',
              iconType: 'success',
              notificationType: 'withActionWide',
            })
          );
        })
        .catch((err) => {
          if (reject) reject(err);

          return Observable.of(
            receiveUpdatePassword(),
            showNotificationWithTimeout({
              id: `notifications.sendResetEmailError.${Date.now()}`,
              messageId: 'notifications.sendResetEmailError',
              position: 'leftDown',
              iconType: 'error',
              notificationType: 'withActionWide',
            }),
          );
        });
    });

export const setUserEpic = action$ =>
  action$.ofType(requestSetUser)
    .switchMap((actionData) => {
      const {
        payload: {
          token, user
        },
      } = actionData;

      return Observable.of(
        () => {
          if (token) {
            safeLocalStorage.setItem('jwt', token);
          }

          if (window.Intercom) {
            window.Intercom('shutdown');
          }

          if (history) {
            const params = new URLSearchParams(history.location.search);
            if (params.has('redirect')) {
              history.push(params.get('redirect'));
            } else {
              history.push('/');
            }
          }
        },
        receiveUser(user, true),
      );
    });


export const clearLoginErrorWithDelayEpic = action$ =>
  action$.ofType(clearLoginErrorWithDelay)
    .delayWhen(({ payload: { delayTime } }) => Observable.timer(delayTime))
    .mapTo(clearLoginError());

export const sendingCodeEpic = (action$, store) =>
  action$.ofType(requestSendingCode)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();

      const {
        payload: {
          ticket,
          isLoginForm,
        },
      } = actionData;

      const searchParams = router?.location?.search;

      return Observable.from(initAxiosInstanse(
        searchParams
      ).post(`/factor2/send?ticket=${ticket}`))
        .mergeMap(({ data }) => {
          if (!data?.success) {
            if (isLoginForm) {
              return Observable.of(
                // setLoginError(data?.error),
                // clearLoginErrorWithDelay({ delayTime: data?.retryInSecs * 1000 }),
                showNotificationWithTimeout({
                  id: `notifications.sendingCodeError.${Date.now()}`,
                  messageId: getErrorMessageCode(data?.error),
                  messageParams: { retryIn: data?.retryInSecs },
                  position: 'leftDown',
                  iconType: 'error',
                  notificationType: 'withActionWide',
                }),
              );
            }

            return Observable.of(
              () => history.push('/login'),
            );
          }

          return Observable.of(
            receiveSendingCode(data),
            () => history.push('/login/code'),
          );
        })
        .catch(() => Observable.of(
          receiveSendingCode({ success: false }),
          () => history.push('/login'),
        ));
    });

export const getUserAuthEpic = (action$, store) =>
  action$.ofType(requestUserAuth)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();

      const {
        payload: {
          email, password, browserHistory
        },
      } = actionData;

      const searchParams = router?.location?.search;

      return Observable.from(initAxiosInstanse(
        searchParams
      ).post('/auth/login', {
        email: email.trim(),
        password,
      }))
        .mergeMap(({ data: { token, user, factor2 } }) => {
          if (factor2) {
            return Observable.of(
              requestSendingCode({ ...factor2, isLoginForm: true }),
            );
          }

          return Observable.of(
            requestSetUser({ token, user, browserHistory }),
          );
        })
        .catch(() => Observable.of(
          receiveUser(null, true),
          trackUserId({ userId: null }),
          () => {
            if (browserHistory) {
              browserHistory.push('/login');
            }
          },
        ));
    });

export const verifyCodeEpic = (action$, store) =>
    action$.ofType(requestVerifyCode)
      .switchMap((actionData) => {
        const {
          router,
        } = store.getState();

        const {
          payload: {
            ticket,
            code,
          },
        } = actionData;

        const searchParams = router?.location?.search;

        return Observable.from(initAxiosInstanse(
          searchParams
        ).post(`/factor2/verify?ticket=${ticket}&code=${code}`))
          .mergeMap(({ data }) => {
            if (!data?.success) {
              return Observable.of(
                receiveVerifyCodeError(data?.error),
              );
            }

            return Observable.of(
              receiveVerifyCode(data),
              requestSetUser({ token: data?.token }),
            );
          })
          .catch(error => Observable.of(
            receiveVerifyCodeError(error),
          ));
      });

export default combineEpics(
  sendResetEmailEpic,
  resetPasswordEpic,
  validateTokenEpic,
  updatePasswordEpic,
  getUserAuthEpic,
  setUserEpic,
  sendingCodeEpic,
  verifyCodeEpic,
  clearLoginErrorWithDelayEpic,
);
