export const SET_MENU_STATE = 'SET_MENU_STATE';

export function setMenuState(opened) {
  return {
    type: SET_MENU_STATE,
    payload: opened,
  };
}

export function setNavigationMenuState(opened) {
  return async dispatch => dispatch(setMenuState(opened));
}
