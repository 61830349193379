import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import queryString from 'query-string';

import auditMap from 'helpers/auditMap';

import initAxiosInstanse from '../../api/axios';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

import {
  requestSimulationsList,
  receiveSimulationsList,

  requestNewSimulationParams,
  receiveNewSimulationParams,

  requestSimulationById,
  receiveSimulationById,

  requestSaveSimulationById,
  receiveSaveSimulationById,

  requestDeleteSimulationById,
  receiveDeleteSimulationById,

  requestNewSimulationId,
  receiveNewSimulationId,

  setCalculateInProgress,
  requestSimulationCalculate,
  receiveSimulationCalculate,

  requestSupportedVarieties,
  receiveSupportedVarieties,

  requestSaveSimulation,
  receiveSaveSimulation,

  requestSaveSimulationByIdWithoutParams,
  receiveSaveSimulationByIdWithoutParams,

  requestSimulationDatesChange,
  // receiveSimulationDatesChange
} from './actions';

// Реализация метода как exhaustMap, но с проигрыванием последнего события
Observable.prototype.auditMap = auditMap;

export const getSimulationsListEpic = (action$, store) =>
  action$.ofType(requestSimulationsList)
    .switchMap(() => {
      const {
        company,
      } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      return Observable
        .from(initAxiosInstanse().get(`digital-twin/${organizationId}`))
        .map(({ data }) => receiveSimulationsList(data))
        .catch(() => Observable.of(
          receiveSimulationsList(),
          showNotificationWithTimeout({
            id: `notifications.getSimulationsListError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getNewSimulationParamsEpic = (action$, store) =>
  action$.ofType(requestNewSimulationParams)
    .auditMap((actionData) => {
      const {
        company,
      } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          seedingDate,
          endDate,
          withReset = false,
          actionAfterSuccess,
        }
      } = actionData;

      const queryParams = queryString.stringify({
        seedingDate,
        endDate,
      });

      return Observable
        .from(initAxiosInstanse().get(`digital-twin/${organizationId}/new-params?${queryParams}`))
        .mergeMap(({ data }) => Observable.of(
          receiveNewSimulationParams({ data, withReset, actionAfterSuccess }),
        ))
        .catch(() => Observable.of(
          receiveNewSimulationParams(),
          showNotificationWithTimeout({
            id: `notifications.getNewSimulationParamsError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getSimulationByIdEpic = (action$, store) =>
  action$.ofType(requestSimulationById)
    .switchMap((actionData) => {
      const {
        company,
      } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          simulationId,
          actionAfterSuccess = () => {},
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse().get(`digital-twin/${organizationId}/${simulationId}`))
        .mergeMap(({ data }) => Observable.of(
          receiveSimulationById(data),
          actionAfterSuccess,
        ))
        .catch(() => Observable.of(
          receiveSimulationById(),
          showNotificationWithTimeout({
            id: `notifications.getSimulationByIdError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const saveSimulationByIdEpic = (action$, store) =>
  action$.ofType(requestSaveSimulationById)
    .switchMap((actionData) => {
      const {
        company,
      } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          newSimulation,
        }
      } = actionData;

      // Почему-то тут у бэка формат симуляции отличается от формата при получении
      const formattedSimulation = {
        input: newSimulation,
        result: newSimulation?.result,
      };

      return Observable
        .from(initAxiosInstanse().post(`digital-twin/${organizationId}/${newSimulation?.id}`, formattedSimulation))
        .mergeMap(({ data }) => Observable.of(
          receiveSaveSimulationById(data),
          requestSimulationsList(),
          showNotificationWithTimeout({
            id: `notifications.saveSimulationByIdSuccess.${Date.now()}`,
            messageId: 'cropModel.saveSuccess',
            position: 'leftDown',
            iconType: 'success',
            notificationType: 'withActionWide',
          }),
        ))
        .catch(() => Observable.of(
          receiveSaveSimulationById(),
          showNotificationWithTimeout({
            id: `notifications.saveSimulationByIdError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

// Нужен для редактирования симуляции из списка (чтобы не перетирать данные текущей симуляции)
export const saveSimulationByIdWithoutParamsEpic = (action$, store) =>
  action$.ofType(requestSaveSimulationByIdWithoutParams)
    .switchMap((actionData) => {
      const {
        company,
      } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          newSimulation,
        }
      } = actionData;

      // Почему-то тут у бэка формат симуляции отличается от формата при получении
      const formattedSimulation = {
        input: newSimulation,
      };

      return Observable
        .from(initAxiosInstanse().post(`digital-twin/${organizationId}/${newSimulation?.id}`, formattedSimulation))
        .mergeMap(() => Observable.of(
          receiveSaveSimulationByIdWithoutParams(),
          requestSimulationsList(),
          showNotificationWithTimeout({
            id: `notifications.saveSimulationByIdSuccess.${Date.now()}`,
            messageId: 'cropModel.saveSuccess',
            position: 'leftDown',
            iconType: 'success',
            notificationType: 'withActionWide',
          }),
        ))
        .catch(() => Observable.of(
          receiveSaveSimulationByIdWithoutParams(),
          showNotificationWithTimeout({
            id: `notifications.saveSimulationByIdError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const deleteSimulationByIdEpic = (action$, store) =>
  action$.ofType(requestDeleteSimulationById)
    .switchMap((actionData) => {
      const {
        company,
      } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          simulationId,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse().delete(`digital-twin/${organizationId}/${simulationId}`))
        .mergeMap(({ data }) => Observable.of(
          receiveDeleteSimulationById(data),
          requestSimulationsList(),
        ))
        .catch(() => Observable.of(
          receiveDeleteSimulationById(),
          showNotificationWithTimeout({
            id: `notifications.deleteSimulationByIdError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getNewSimulationIdEpic = (action$, store) =>
  action$.ofType(requestNewSimulationId)
    .switchMap(() => {
      const {
        company,
      } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      return Observable
        .from(initAxiosInstanse().get(`digital-twin/${organizationId}/new-id`))
        .map(({ data }) => receiveNewSimulationId(data))
        .catch(() => Observable.of(
          receiveNewSimulationId(),
          showNotificationWithTimeout({
            id: `notifications.getNewSimulationIdError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getSupportedVarietiesEpic = (action$, store) =>
  action$.ofType(requestSupportedVarieties)
    .switchMap(() => {
      const {
        company,
      } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      return Observable
        .from(initAxiosInstanse().get(`digital-twin/${organizationId}/supported-varieties`))
        .map(({ data }) => receiveSupportedVarieties(data))
        .catch(() => Observable.of(
          receiveSupportedVarieties(),
          showNotificationWithTimeout({
            id: `notifications.getSupportedVarieties.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const saveSimulationEpic = (action$, store) =>
  action$.ofType(requestSaveSimulation)
    .switchMap((actionData) => {
      const {
        company,
      } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          newSimulation,
        }
      } = actionData;

      if (newSimulation?.id) {
        return Observable.of(
          requestSaveSimulationById({ simulationId: newSimulation.id, newSimulation }),
        );
      }

      return Observable
        .from(initAxiosInstanse().get(`digital-twin/${organizationId}/new-id`))
        .map(({ data }) => requestSaveSimulationById({
          simulationId: data.id,
          newSimulation: {
            ...newSimulation,
            id: data.id,
          },
        }))
        .catch(() => Observable.of(
          receiveSaveSimulation(),
          showNotificationWithTimeout({
            id: `notifications.saveSimulationError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

// Используем auditMap, чтобы абортить все вызовы, пока идёт текущий, а потом вызвать последний пришедший
export const calculateSimulationEpic = (action$, store) =>
  action$.ofType(requestSimulationCalculate)
    .auditMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();
      const {
        location,
      } = company;
      const { id: organizationId } = location;
      const searchParams = router?.location?.search;

      const {
        payload: {
          calculatedSimulation,
        }
      } = actionData;

      /**
       * Нужно дополнительно выставить loading статус (setCalculateInProgress) из-за очереди.
       * requestSimulationCalculate (который в очереди) уже выставил loading в true когда сработал и ушёл в очередь,
       * но предыдущий отработавший requestSimulationCalculate его установит в false
       */
      return Observable.concat(
        Observable.of(setCalculateInProgress()),
        Observable
          .from(initAxiosInstanse(searchParams, 60000).post(`digital-twin/${organizationId}/calculate`, calculatedSimulation))
          .map(({ data }) => receiveSimulationCalculate(data))
          .catch(() => Observable.of(
            receiveSimulationCalculate(),
            showNotificationWithTimeout({
              id: `notifications.calculateSimulationError.${Date.now()}`,
              messageId: 'notifications.backendError',
              iconType: 'error',
              notificationType: 'withActionWide',
            }),
          ))
      );
    });

// Используем auditMap, чтобы абортить все вызовы, пока идёт текущий, а потом вызвать последний пришедший
export const calculateSimulationDatesChangeEpic = action$ =>
  action$.ofType(requestSimulationDatesChange)
    .auditMap((actionData) => {
      const {
        payload
      } = actionData;

      const afterNewParamsLoading = simulationNewParamsResult => requestSimulationCalculate({ calculatedSimulation: simulationNewParamsResult });

      /**
       * На самом деле тут используется костыль. Мне нужно дёрнуть последовательно два
       * экшна – requestNewSimulationParams и requestSimulationCalculate, но при этом передать
       * в requestSimulationCalculate не просто результат выполнения requestNewSimulationParams, а результат
       * рельюсера receiveNewSimulationParams
       * я не нашёл как это сделать в promise like стиле, поэтому вкорячил промежуточный
       * мусорный экшн receiveNewSimulationParams чтобы ловить результаты выполнения редьюсера receiveNewSimulationParams
       * и только после этого дёргать requestNewSimulationParams
       */
      return Observable.of(
        requestNewSimulationParams({
          ...payload,
          actionAfterSuccess: afterNewParamsLoading,
        })
      );
    });

export const successNewSimulationParamsEpic = (action$, store) =>
  action$.ofType(receiveNewSimulationParams)
    .switchMap((actionData) => {
      const { digitalTwin: { currentSimulationEdited } } = store.getState();

      const {
        payload: { actionAfterSuccess }
      } = actionData;

      if (!actionAfterSuccess) {
        return Observable.of();
      }

      return Observable.of(
        actionAfterSuccess(currentSimulationEdited)
      );
    });

export default combineEpics(
  getSimulationsListEpic,
  getSupportedVarietiesEpic,
  getNewSimulationParamsEpic,
  getSimulationByIdEpic,
  saveSimulationByIdEpic,
  saveSimulationEpic,
  deleteSimulationByIdEpic,
  getNewSimulationIdEpic,
  calculateSimulationEpic,
  saveSimulationByIdWithoutParamsEpic,
  calculateSimulationDatesChangeEpic,
  successNewSimulationParamsEpic,
);
