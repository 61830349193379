import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { reduxForm, Field } from 'redux-form';

import {
  get, isEmpty, find, trim
} from 'lodash';

import DefaultDialog from 'components/DefaultDialog';
import BigButton from 'components/BigButton';
import DefaultTextInput from 'components/DefaultTextInput';
import DefaultTextareaInput from 'components/DefaultTextareaInput';

import styles from './VersionInfoDialog.module.css';

const isNameAlreadyExist = (planId, versions, newName) => {
  const findedValue = find(versions, version => (trim(version.label) === trim(newName)));

  if (findedValue && planId !== get(findedValue, 'id')) {
    return true;
  }

  return false;
};

const validate = (values, props) => {
  const { intl: { formatMessage }, versions, planId } = props;
  const errors = {};

  if (values.versionName && isNameAlreadyExist(planId, versions, values.versionName)) {
    errors.versionName = formatMessage({ id: 'plansVersion.versionNameError' });
  }

  return errors;
};

class VersionInfoDialog extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    planId: PropTypes.number.isRequired,
    label: PropTypes.string,
    formValues: PropTypes.object,
    isVersionInfoFetching: PropTypes.bool,
    formSyncErrors: PropTypes.object,
    versions: PropTypes.array,

    handlerCloseDialog: PropTypes.func.isRequired,
    handlerDontSave: PropTypes.func.isRequired,
    handlerSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: null,
    formValues: null,
    isVersionInfoFetching: false,
    formSyncErrors: null,
    versions: [],
  };

  getIsFormValuesNotEmpty = () => {
    const { formValues } = this.props;

    return formValues && formValues.versionName;
  };

  handlerSubmitForm = (e) => {
    e.preventDefault();

    const {
      planId, formValues, handlerSave, handlerCloseDialog, formSyncErrors
    } = this.props;
    const { versionName, versionComment } = formValues;
    const isFormEmpty = !this.getIsFormValuesNotEmpty();

    if (!formValues || isFormEmpty || !isEmpty(formSyncErrors)) {
      return null;
    }

    return handlerSave({
      planId,
      label: trim(versionName),
      comment: versionComment,
      actionSuccess: handlerCloseDialog,
    });
  }

  renderVersionNameInput = ({
    input,
    meta: { touched, error, active },
  }) => {
    const { intl: { formatMessage } } = this.props;

    return (
      <DefaultTextInput
        {...input}
        className={styles.versionName}
        placeholder={formatMessage({ id: 'plansVersion.versionNamePlaceholder' })}
        error={touched && !active && error ? error : null}
      />
    );
  };

  renderVersionCommentTextarea = ({
    input,
  }) => {
    const { intl: { formatMessage } } = this.props;

    return (
      <DefaultTextareaInput
        {...input}
        placeholder={formatMessage({ id: 'plansVersion.describeChanges' })}
        rows={4}
      />
    );
  };

  render() {
    const {
      intl: { formatMessage }, isVersionInfoFetching,
      label, handlerCloseDialog, handlerDontSave,
    } = this.props;

    const isFormEmpty = !this.getIsFormValuesNotEmpty();

    const titleText = label ? formatMessage({ id: 'plansVersion.editVersionInfo' }) : formatMessage({ id: 'plansVersion.nameVersionTitle' });

    return (
      <DefaultDialog
        title={titleText}
        onClose={handlerCloseDialog}
        wrapperClassName={styles.dialogWrapper}
      >
        <form
          className={styles.passwordForm}
          onSubmit={this.handlerSubmitForm}
        >
          <div className={styles.dialogText}>
            <Field
              name='versionName'
              component={this.renderVersionNameInput}
            />
            <Field
              name='versionComment'
              component={this.renderVersionCommentTextarea}
            />
          </div>
          <div className={styles.buttons}>
            <BigButton
              className={styles.cancel}
              onClick={handlerDontSave}
              title={formatMessage({ id: 'button.cancel' })}
              theme='light'
              disabled={isVersionInfoFetching}
            />
            <BigButton
              className={styles.confitm}
              title={formatMessage({ id: 'button.saveChanges' })}
              theme='dark'
              disabled={isFormEmpty}
              isLoading={isVersionInfoFetching}
            />
          </div>
        </form>
      </DefaultDialog>
    );
  }
}

export default reduxForm({
  form: 'versionNameForm',
  validate,
})(VersionInfoDialog);
