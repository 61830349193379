export const LEFT_PANEL_WIDTH = 335;
export const HEADER_HEIGHT = 58;
export const COMPARTMENT_BLOCK_HEIGHT = 97;
export const DATA_LINE_HEIGHT = 24;
export const FIRST_COMPARTMENT_PADDING = 12;
export const LINE_BORDER_RADIUS = 4;
export const LINE_MARGIN = 4;
export const COMPARTMENT_HEADER_HEIGHT = 62;
export const COMPARTMENT_VARIETY_BLOCK_HEIGHT = 96;
export const LAYOUT_OFFSET_Y = 140;
