import * as d3 from 'd3';

import sizeMe from 'react-sizeme';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';
import { get, isEqual, throttle } from 'lodash';

import { getTicksRange, getTicksMinMax } from '../../helpers/getTicks';

import CircleLoader from '../CircleLoader';
import loaderStyles from '../CircleLoader/CircleLoader.module.css';

import styles from './DefaultBarChart.module.css';

// TODO: удалить и заменить на BarChart
class DefaultBarChart extends Component {
  static propTypes = {
    items: PropTypes.array,
    barClassNames: PropTypes.string,
    valuePath: PropTypes.string,
    labelPath: PropTypes.string,
    emptyTitle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    isFetching: PropTypes.bool.isRequired,
    isEmpty: PropTypes.bool.isRequired,
    size: PropTypes.object.isRequired,
    height: PropTypes.number,
    getFormattedValue: PropTypes.func,
  };

  static defaultProps = {
    barClassNames: null,
    valuePath: 'value',
    labelPath: 'name',
    items: null,
    height: 269,
    emptyTitle: null,
    getFormattedValue: null,
  };


  handlerResize = throttle((e, callback) => {
    if (callback) {
      callback();
    } else {
      this.rebuild(this.props, this.state);
    }
  }, 10);

  constructor(...props) {
    super(...props);

    this.x = d3.scaleBand();
    this.yLeft = d3.scaleLinear();
  }

  state = {
    margin: {
      top: 10,
      bottom: 28,
      left: 65,
      right: 0,
    },
    ticksCount: {
      x: 12,
      y: 3,
    },
  };

  componentDidMount() {
    window.addEventListener('resize', this.handlerResize);

    setTimeout(() => {
      this.handlerResize();
    }, 0);
  }

  componentDidUpdate(prevProps) {
    const {
      items: oldItems,
      size: oldSize,
    } = prevProps;

    const {
      items: nextItems,
      size: nextSize,
    } = this.props;

    if (!isEqual(nextItems, oldItems) || oldSize.width !== nextSize.width) {
      this.handlerResize(null, () => {
        this.rebuild(this.props, this.state);
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResize);
  }

  getBarsWidth = () => {
    if (window.innerWidth < 1024) {
      return 12;
    }

    return 24;
  };

  getScaleDomain = ({ items, scale, valuePath }) => {
    if (!items || !items.length) {
      scale.domain([0, 100]);
    } else {
      const values = items.map(item => get(item, valuePath, 0));
      const min = 0;
      const max = Math.ceil(d3.max([...values]));

      if ((min === 0 && max === 0) || (Number.isNaN(min) && Number.isNaN(max))) {
        scale.domain(d3.extent([0, 100]));
      } else if (min === max) {
        scale.domain(d3.extent([0, max + max]));
      } else {
        const deltaData = max - min;
        const delta = deltaData || max;

        const maxDelta = Math.ceil(delta * 0.1);

        scale.domain(d3.extent(
          [{ value: 0 }, { value: max + maxDelta }],
          d => d.value,
        ));
      }
    }
  };


  rebuild = (props, state) => {
    const {
      items,
      size: {
        width,
      },
    } = props;

    const { margin } = state;

    const newMargin = {
      ...margin,
      bottom: 28,
    };

    this.calculateDomains(props, state);

    this.calculateHorizontalAxis({
      width: width - newMargin.left - newMargin.right,
    });

    this.renderAxises({
      width,
      items,
    });

    this.setState({ margin: newMargin });
  };

  calculateHorizontalAxis = ({ width }) => {
    const { items, labelPath } = this.props;

    this.x.domain(items.map(item => get(item, labelPath))).range([0, width]);
  };

  calculateTicksForVerticalAxis(yDomain) {
    const delimeter = this.state.ticksCount.y - 1;
    const min = yDomain[0];
    const max = yDomain[1];

    const ticksRange = getTicksRange(min, max, delimeter);
    const yLeftDomainRange = getTicksMinMax(ticksRange);

    this.yLeft.domain(yLeftDomainRange);

    return ticksRange;
  }

  calculateDomains(props, state) {
    const {
      items,
      height,
      valuePath,
    } = props;

    const { margin } = state;

    this.yLeft.range([height - margin.top - margin.bottom, 0]);

    if (items && items.length) {
      this.getScaleDomain({
        items,
        scale: this.yLeft,
        valuePath,
      });
    }
  }

  renderAxises = ({ width, items }) => {
    this.xAxisScale = d3.axisBottom()
      .scale(this.x)
      .tickFormat(item => item)
      .ticks(items ? items.length : 0)
      .tickPadding(13)
      .tickSizeInner(-4)
      .tickSizeOuter(0);

    this.yLeftAxisScale = d3.axisLeft()
      .scale(this.yLeft)
      .tickValues(this.calculateTicksForVerticalAxis(this.yLeft.domain()))
      .tickFormat(d => d3.format(',')(d).replace(/,/g, ' '))
      .tickPadding(12)
      .tickSizeInner(-width)
      .tickSizeOuter(0);

    d3.select(this.axisX).call(this.xAxisScale);

    d3.select(this.axisYLeft).call(this.yLeftAxisScale);
  };

  renderBars = () => {
    const {
      items,
      height: propsHeight,
      valuePath,
      labelPath,
      barClassNames,
    } = this.props;

    const {
      margin,
    } = this.state;

    const height = propsHeight - margin.top - margin.bottom;

    return items.map((item) => {
      const label = get(item, labelPath, 0);
      const value = get(item, valuePath, 0);
      const id = get(item, 'id', 0);

      let rectHeight = 0;
      let rectY = 0;

      if (value === null || value === 0) {
        rectY = height - 2;
        rectHeight = 2;
      } else {
        rectY = height - this.yLeft(value) < 2 ? height - 2 : this.yLeft(value);
        rectHeight = height - this.yLeft(value) < 2 ? 2 : height - this.yLeft(value);
      }

      const x = this.x(label) + Math.round((this.x.bandwidth() - this.getBarsWidth()) / 2) + Math.round(this.getBarsWidth() / 2);

      return (
        <rect
          key={`rect-${id}`}
          className={classnames(styles.bar, barClassNames, {
            [styles.noData]: value === null,
          })}
          x={Number.isNaN(x) ? 0 : x}
          y={Number.isNaN(rectY) ? 0 : rectY}
          width={this.getBarsWidth()}
          height={rectHeight}
          data-id={label}
        />
      );
    });
  };

  renderNumbers = () => {
    const {
      items,
      height: propsHeight,
      valuePath,
      labelPath,
      getFormattedValue,
    } = this.props;

    const {
      margin,
    } = this.state;

    const height = propsHeight - margin.top - margin.bottom;

    return items.map((item) => {
      const label = get(item, labelPath, 0);
      const value = get(item, valuePath, 0);
      const id = get(item, 'id', 0);

      const displayValue = getFormattedValue ? getFormattedValue(value) : value;

      let rectY = 0;

      if (value === null || value === 0) {
        rectY = height - 2;
      } else {
        rectY = height - this.yLeft(value) < 2 ? height - 2 : this.yLeft(value);
      }

      const x = this.x(label) + Math.round((this.x.bandwidth()) / 2) + Math.round(this.getBarsWidth() / 2);
      const y = rectY - 8;

      return (
        <text
          key={`text-${id}`}
          className={classnames(styles.barLabel, {
            [styles.noData]: value === null,
          })}
          x={Number.isNaN(x) ? 0 : x}
          y={Number.isNaN(y) ? 0 : y}
          textAnchor='middle'
          data-id={label}
        >
          {displayValue}
        </text>
      );
    });
  };

  render() {
    const {
      isFetching,
      isEmpty,
      height,
      size,
      emptyTitle,
    } = this.props;

    const {
      margin,
    } = this.state;

    const { width } = size;

    return (
      <div className={styles.chart}>
        <svg width={width} height={height}>
          <g transform={`translate(${margin.left},${margin.top})`}>
            <g
              className={styles.axisX}
              transform={`translate(12,${height - margin.top - margin.bottom})`} // 12 магическое число выведенное эмпирическим путём, пофиксить
              ref={(element) => {
                this.axisX = element;
              }}
            />
            <g
              className={classnames(styles.axisY)}
              ref={(element) => {
                this.axisYLeft = element;
              }}
            />
            <g width={width} height={height} clipPath='url(#clip)'>
              {this.renderBars()}
              {this.renderNumbers()}
            </g>
          </g>
        </svg>

        {isFetching ? (
          <CircleLoader
            className={loaderStyles.circleLoader}
            iconClassName={loaderStyles.circleLoaderIcon}
          />
        ) : null}

        {isEmpty && emptyTitle ? (
          <div className={styles.emptyState}>
            {emptyTitle}
          </div>
        ) : null}
      </div>
    );
  }
}

export default sizeMe()(DefaultBarChart);
