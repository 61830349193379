import React, {
  useCallback, useEffect, useMemo, useRef
} from 'react';
import PropTypes from 'prop-types';

import DefaultSimpleTable from 'components/DefaultSimpleTable';

import { groupBy } from 'lodash/collection';
import { injectIntl, intlShape } from 'react-intl';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import tooltipStyles from 'components/Tooltip/index.module.css';
import CollapsedRow from '../CollapsedRow';
import HeaderRow from '../HeaderRow';

import styles from './CropsTable.module.css';

const CropsTable = ({
  intl,
  descriptor,
  period,
  tableRows,
  handlerGraphKeyChange,
  currentGraphKey,
  emptyText,
  metricCategories,
  lockUnselected,
  isSelectAll
}) => {
  const { formatMessage, locale } = intl;

  const scrollRef = useRef();

  const handlerRowGraphKeyChange = useCallback((el, graphKey, selected) => {
    if (handlerGraphKeyChange) {
      handlerGraphKeyChange(el, graphKey, selected);
    }
  }, [handlerGraphKeyChange]);

  const handleCheckAll = useCallback((el) => {
    if (handlerGraphKeyChange) {
      handlerGraphKeyChange(el, 'all');
    }
  }, [handlerGraphKeyChange]);

  const bodyRows = useMemo(() => {
    let rows = [];
    const getRows = (metricsList, options) => {
      const { withGroup } = options;
      const rowsList = [];
      const groupedRows = groupBy(metricsList, 'groupKey');
      const groupKeys = Object.keys(groupedRows);
      for (let groupIdx = 0; groupIdx < groupKeys.length; groupIdx += 1) {
        const groupKey = groupKeys[groupIdx];
        const groupName = metricCategories.find(category => category.code === groupKey)?.name[locale] || groupKey;
        if (withGroup) {
          rowsList.push(
            <tr>
              <td className={classnames(styles.categoryGroup, styles.sticky)}>{groupName}</td>
              {descriptor.periods.map(() => (<td>&nbsp;</td>))}
              <td>&nbsp;</td>
            </tr>
          );
        }
        rowsList.push(
          ...groupedRows[groupKey].map(row => (
            <CollapsedRow
              key={`rowFragment-${row.graphKey}`}
              tableRow={row}
              currentGraphKey={currentGraphKey}
              onGraphKeyChange={handlerRowGraphKeyChange}
              lockUnselected={lockUnselected}
            />
          ))
        );
      }
      return rowsList;
    };
    const notAdditionalItems = tableRows.filter(row => !row.isAdditionalMetric);
    const additionalItems = tableRows.filter(row => row.isAdditionalMetric);
    rows = rows.concat([...getRows(notAdditionalItems, { withGroup: true })]);
    if (additionalItems.length > 0) {
      rows.push(
        <tr>
          <td className={classnames(styles.categoryGroup, styles.sticky)}>
            {formatMessage({ id: 'crops.additionalMetrics' })}
          </td>
          {descriptor.periods.map(() => (<td>&nbsp;</td>))}
          <td>&nbsp;</td>
        </tr>
      );
      rows = rows.concat([...getRows(additionalItems, { withGroup: false })]);
    }
    return rows;
  }, [
    tableRows,
    currentGraphKey,
    formatMessage,
    descriptor,
    handlerRowGraphKeyChange,
    lockUnselected,
    metricCategories,
    locale
  ]);

  useEffect(() => {
    const scrollEl = scrollRef?.current;
    if (tableRows.length > 0 && scrollEl) {
      scrollEl.scrollLeft = scrollEl.scrollWidth - scrollEl.clientWidth;
    }
  }, [tableRows, scrollRef]);

  const isTouchDevice = 'ontouchend' in document.documentElement;

  return (
    <div className={styles.tableWrapper}>
      <div
        className={styles.tableScroller}
        ref={scrollRef}
      >
        <DefaultSimpleTable
          className={styles.table}
          headerRows={(
            <HeaderRow
              descriptor={descriptor}
              period={period}
              title={formatMessage({ id: 'crops.metrics' })}
              currentGraphKey={currentGraphKey}
              onCheckAll={handleCheckAll}
              isSelectAll={isSelectAll}
            />
          )}
          bodyRows={bodyRows}
          emptyText={emptyText}
        />
      </div>
      <ReactTooltip
        id='measurements-table-indicators-tooltip'
        isCapture
        scrollHide
        offset={{ top: 10 }}
        effect='solid'
        place='bottom'
        class={styles.tooltipIndicator}
        event={isTouchDevice ? 'click' : null}
      />
      <ReactTooltip
        className={classnames(tooltipStyles.smallTooltip)}
        id='markers-table-indicators-tooltip'
        effect='solid'
        event={isTouchDevice ? 'click' : null}
        html
      />
      <ReactTooltip
        className={classnames(tooltipStyles.smallTooltip)}
        id='markers-extra-table-indicators-tooltip'
        effect='solid'
        event={isTouchDevice ? 'click' : null}
        html
      />
    </div>
  );
};

CropsTable.propTypes = {
  intl: intlShape.isRequired,
  descriptor: PropTypes.object.isRequired,
  period: PropTypes.string.isRequired,
  tableRows: PropTypes.array,
  handlerGraphKeyChange: PropTypes.func.isRequired,
  currentGraphKey: PropTypes.array,
  emptyText: PropTypes.string,
  metricCategories: PropTypes.array,
  lockUnselected: PropTypes.bool,
  isSelectAll: PropTypes.bool,
};

CropsTable.defaultProps = {
  tableRows: [],
  currentGraphKey: [],
  metricCategories: [],
  emptyText: null,
  lockUnselected: false,
  isSelectAll: false
};

export default injectIntl(CropsTable);
