import { connect } from 'react-redux';

import { getCurrentTimezone } from '../../../store/company/selectors';
import { getIsDashboardsMenuOpened } from '../../../store/dashboardsMenu/selectors';

import { setDashboardsMenu } from '../../../store/dashboardsMenu/actions';

import DashboardHeader from '../components/DashboardHeader/DashboardHeader';

const mapDispatchToProps = { setDashboardsMenu };

const mapStateToProps = (state, props) => ({
  timezone: getCurrentTimezone(state),
  isDashboardsMenuOpened: getIsDashboardsMenuOpened(state),

  ...props,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
