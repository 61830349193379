import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  getLocationPlans,
  getCurrentLocationPlan,
} from 'store/company/selectors';

import {
  changeLocationPlan,
} from 'store/company/actions';


import PlanFilter from './PlanFilter';

const mapDispatchToProps = {
  changeLocationPlan,
};

const mapStateToProps = (state, props) => ({
  ...props,
  locationPlans: getLocationPlans(state),
  locationPlan: getCurrentLocationPlan(state)
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(PlanFilter)));
