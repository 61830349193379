import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import { get } from 'lodash';
import moment from 'moment-timezone';
import classnames from 'classnames';

import ReactTooltip from 'react-tooltip';

import BigButton from '../../../BigButton';
import DropdownMenu from '../../../DropdownMenu';

import HarvestDeleteDialog from '../HarvestDeleteDialog';

import numbersRounding from '../../../../helpers/numbersRounding';
import getDateFormat from '../../../../helpers/getDateFormat';

import styles from './HarvestWeek.module.css';
import tooltipStyles from '../../../Tooltip/index.module.css';

import { ReactComponent as EditIcon } from './assets/edit.svg';
import { ReactComponent as DeleteIcon } from './assets/delete.svg';

class HarvestWeek extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    isLettuce: PropTypes.bool,
    week: PropTypes.object.isRequired,
    handlerEditHarvest: PropTypes.func.isRequired,
    handlerDeleteHarvestData: PropTypes.func.isRequired,
    cycleId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isLettuce: false,
  } ;

  state = {
    currentDayId: null,
    deleteDialogOpened: false,
  };

  tooltipRef = React.createRef();

  handlerDelete = (day) => {
    const { handlerDeleteHarvestData } = this.props;

    handlerDeleteHarvestData(day);
  };

  handlerCancelDelete = () => {
    ReactTooltip.hide();
  };

  handlerTooltipShow = (dayId) => {
    this.setState({ currentDayId: dayId });
  };

  handlerTooltipHide = (dayId) => {
    this.setState({ currentDayId: dayId });
  };

  handlerCloseDialog = () => {
    this.setState({ deleteDialogOpened: false });
  }

  handlerOpenDialog = (dayId) => {
    this.setState({ deleteDialogOpened: true, currentDayId: dayId });
  };

  renderDropdownContent = (day) => {
    const { handlerEditHarvest } = this.props;

    const dayId = get(day, 'date');

    return (
      <div className={styles.dropdownContent}>
        <div
          className={styles.dropdownItem}
          onClick={() => handlerEditHarvest(day)}
          role='button'
          tabIndex={0}
        >
          <div className={styles.dropdownIcon}><EditIcon /></div>
          <FormattedMessage id='harvest.edit' />
        </div>
        <div
          className={styles.dropdownItem}
          onClick={() => this.handlerOpenDialog(dayId)}
          role='button'
          tabIndex={0}
        >
          <div className={styles.dropdownIcon}><DeleteIcon /></div>
          <FormattedMessage id='harvest.delete' />
        </div>
      </div>
    );
  };

  renderDay = (day) => {
    const {
      week,
      handlerEditHarvest,
      intl,
      isLettuce,
    } = this.props;

    const { formatMessage } = intl;
    const { currentDayId, deleteDialogOpened } = this.state;

    const weekNo = get(week, 'weekNo');
    const dayId = get(day, 'date');
    const date = moment(get(day, 'date')).format(getDateFormat('llll'));
    const value = numbersRounding(get(day, 'value'), 'fixed', 2);
    const units = isLettuce ? <FormattedMessage id='cunits.mini.count' /> : <FormattedMessage id='cunits.mini.kilogramPerSquareMeter' />;

    return (
      <div
        key={`weekDay-${weekNo}-${dayId}`}
        className={classnames(styles.weekDay, { [styles.currentDay]: dayId === currentDayId })}
      >
        <div>{value} {units}</div>
        <div className={styles.weekDayDate}>{date}</div>

        <div className={styles.weekDayDropdownWrapper}>
          <DropdownMenu closeDropdown={deleteDialogOpened}>
            {this.renderDropdownContent(day)}
          </DropdownMenu>
        </div>
        <div className={styles.weekDayControls}>
          <div
            className={styles.weekDayEdit}
            data-tip={formatMessage({ id: 'harvest.editHarvest' })}
            data-for={`HarvestWeekTooltip-${weekNo}`}
            onClick={() => handlerEditHarvest(day)}
            role='button'
            tabIndex={0}
          >
            <EditIcon />
          </div>
          <div
            className={styles.weekDayDeleteWrapper}
            data-tip={formatMessage({ id: 'harvest.deleteHarvest' })}
            data-for={`HarvestWeekTooltip-${weekNo}`}
          >
            <div
              className={styles.weekDayDelete}
              data-tip
              data-for={`HarvestDelete-${weekNo}-${dayId}`}
            >
              <DeleteIcon />
            </div>
          </div>
        </div>

        <ReactTooltip
          ref={this.tooltipRef}
          id={`HarvestDelete-${weekNo}-${dayId}`}
          className={classnames(tooltipStyles.lightTooltip, tooltipStyles.clickable, styles.deletePopup)}
          event='click'
          globalEventOff='click'
          effect='solid'
          type='light'
          border
          place='bottom'
          afterShow={() => this.handlerTooltipShow(dayId)}
          afterHide={() => this.handlerTooltipHide(null)}
        >
          <p className={styles.deletePopupText}>
            <FormattedMessage
              id='harvest.textDelete'
            />
          </p>
          <div className={styles.deleteButtons}>
            <BigButton
              className={styles.deleteCancel}
              onClick={this.handlerCancelDelete}
              title={<FormattedMessage id='harvest.cancelButton' />}
              theme='transparent'
            />
            <BigButton
              className={styles.deleteConfitm}
              onClick={() => this.handlerDelete(day)}
              title={<FormattedMessage id='harvest.confirmDelete' />}
              theme='transparentRed'
            />
          </div>
        </ReactTooltip>
      </div>
    );
  };

  render() {
    const { week, cycleId, isLettuce } = this.props;
    const { deleteDialogOpened, currentDayId } = this.state;

    const weekNo = get(week, 'weekNo');
    const total = numbersRounding(get(week, 'total'), 'fixed', isLettuce ? 0 : 2);
    const startDate = moment(get(week, 'startDate')).format(getDateFormat('ll'));
    const endDate = moment(get(week, 'endDate')).format(getDateFormat('ll'));
    const days = get(week, 'days');
    const units = isLettuce ? <FormattedMessage id='cunits.mini.count' /> : <FormattedMessage id='cunits.mini.kilogramPerSquareMeter' />;

    return (
      <div className={styles.week}>
        <div className={styles.weekHeader}>
          <div className={styles.weekHeaderAverage}>{total} {units}</div>
          <div className={styles.weekNo}>
            <div className={styles.weekNoText}><FormattedMessage id='harvest.week' /></div>
            {
              /* weekNo располагается по-центру, хак для ie11,
                см. https://stackoverflow.com/questions/32991051/absolutely-positioned-flex-item-is-not-removed-from-the-normal-flow-in-ie11
              */
            }
            <div className={styles.weekNoValue}>{weekNo}</div>
          </div>
          <div className={styles.weekHeaderDate}>{startDate} – {endDate}</div>
        </div>
        <div>
          {days.length > 0 ?
            days.map(day => this.renderDay(day))
            : (
              <div className={classnames(styles.weekDay, styles.weekDayEmpty)}>
                <FormattedMessage id='harvest.noRegistrations' />
              </div>
            )}
        </div>

        {deleteDialogOpened && (
          <HarvestDeleteDialog
            handlerCloseHarvestDialog={this.handlerCloseDialog}
            harvestId={currentDayId}
            cycleId={cycleId}
          />
        )}

        <ReactTooltip
          id={`HarvestWeekTooltip-${weekNo}`}
          className={tooltipStyles.smallTooltip}
          effect='solid'
          html
        />
      </div>
    );
  }
}

export default injectIntl(HarvestWeek);
