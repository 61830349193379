import * as d3 from 'd3';
import getD3Locale from './getD3Locale';

// Establish the desired formatting options using locale.format():
// https://github.com/d3/d3-time-format/blob/master/README.md#locale_format

// Define filter conditions
export default function multiformat(localeName) {
  const locale = getD3Locale(localeName);
  const timeLocateObject = locale ? d3.timeFormatLocale(locale) : d3;

  const formatMillisecond = timeLocateObject.utcFormat('.%L');
  const formatSecond = timeLocateObject.utcFormat(':%S');
  const formatMinute = timeLocateObject.utcFormat('%H:%M');
  const formatHour = timeLocateObject.utcFormat('%H:%M');
  const formatMonth = timeLocateObject.utcFormat('%b %e');
  const formatYear = timeLocateObject.utcFormat('%Y');

  const formater = (fmt, date) => {
    if (d3.utcSecond(date) < date) {
      return fmt.formatMillisecond(date);
    } if (d3.utcMinute(date) < date) {
      return fmt.formatSecond(date);
    } if (d3.utcHour(date) < date) {
      return fmt.formatMinute(date);
    } if (d3.utcDay(date) < date) {
      return fmt.formatHour(date);
    } if (d3.utcWeek(date) < date) {
      return fmt.formatMonth(date);
    } if (d3.utcMonth(date) < date) {
      return fmt.formatMonth(date);
    } if (d3.utcYear(date) < date) {
      return fmt.formatMonth(date);
    } if (d3.utcDay(date) < date && d3.timeWeek(date) < date) {
      return fmt.formatYear(date);
    }
    return fmt.formatYear(date);
  };

  return formater.bind(null, {
    formatMillisecond,
    formatSecond,
    formatMinute,
    formatHour,
    formatMonth,
    formatYear
  });
}
