import React from 'react';

import { TreeNodePathComponents } from 'store/graphs/types';

import ChartLegendItem, {
  OnSelectItemFunc,
  OnChangeColorItemFunc,
  ChartLegendItemProps,
} from './components/ChartLegendItem';

import styles from './index.module.css';

type ChartLegendProps = {
  items: Array<ChartLegendItemProps>;
  hiddenLines: Array<Array<TreeNodePathComponents>>;
};

type ChartLegendPropsFunc = {
  onSelected: OnSelectItemFunc;
  onChangeColor: OnChangeColorItemFunc;
};

const ChartLegend = ({
  items,
  onSelected,
  onChangeColor,
  hiddenLines
}: ChartLegendProps & ChartLegendPropsFunc) => (
  <div className={styles.legend}>
    {items.map(item => (
      <ChartLegendItem
        {...item}
        key={item.itemKey}
        onChangeColor={onChangeColor}
        onSelected={onSelected}
        hiddenLines={hiddenLines}
      />
    ))}
  </div>
);

export default ChartLegend;
