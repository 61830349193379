import React, { useRef, useState, memo } from 'react';
import { useMeasure } from 'react-use';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import {
  Group, Text, Rect, Image
} from 'react-konva';
import useImage from 'use-image';
import {
  head, isEmpty, isNull
} from 'lodash';

import { getTrimmedValue } from 'helpers/getTrimmedValue';
import numbersFormatting from 'helpers/numbersFormatting';
import FxIconSvg from 'components/Icons/FxIcon/fxIcon.svg';
import InfoIconSvg from 'components/Icons/InfoIcon/info.svg';
import ArrowDownSvg from 'components/Icons/ArrowDown/arrowDown.svg';
import ErrorHintSvg from 'components/Icons/ErrorHint/errorHint.svg';

import DefaultCanvasGrid from 'components/DefaultCanvasGrid';
import NameEditorComponent from 'components/DefaultCanvasGrid/components/NameEditorComponent';
import Tooltip from 'components/DefaultCanvasGrid/components/Tooltip';

import styles from './MeasurementsEnterTable.module.css';

const hideTooltip = setData => setData({
  isVisible: false,
  top: 0,
  left: 0,
  data: null,
  position: 'top',
});

const showTooltip = (setData, e, text, alignBySelf = false, addY = 0, addX = 0, leftPosition = false) => {
  const target = alignBySelf ? e?.target : e?.target?.parent?.children?.[0];
  const yPosition = target?.attrs?.y - target?.parent?.parent?.attrs?.offsetY;
  const xPosition = target?.attrs?.x - target?.parent?.parent?.attrs?.offsetX;

  const additionalYPosition = leftPosition ? target?.attrs?.height / 2 : 0;
  const additionalXPosition = leftPosition ? 0 : target?.attrs?.width / 2;

  setData({
    isVisible: true,
    top: yPosition + additionalYPosition + addY,
    left: xPosition + additionalXPosition + addX,
    data: text,
    position: leftPosition ? 'left' : 'top',
  });
};

const showFullNameTooltip = (setData, e, text, alignBySelf = false, addY = 0, addX = 0, leftPosition = false) => {
  /**
   * в currentTarget.textArr можно получить реально отображаемый текст
   * смотрим заканчивается ли этот реальный текст многоточием ...
   * и если реально заканчивается, то показываем тултип на элементе
   * при этом полный текст берём в currentTarget.attrs
   */
  const visibleText = e?.currentTarget?.textArr?.[0]?.text;
  const isTextEllipsis = visibleText?.endsWith('…');
  const value = e?.currentTarget?.attrs?.text;
  const target = alignBySelf ? e?.target : e?.target?.parent?.children?.[0];
  const yPosition = target?.attrs?.y - target?.parent?.parent?.attrs?.offsetY;
  const xPosition = target?.attrs?.x - target?.parent?.parent?.attrs?.offsetX + target?.attrs?.width / 2;

  const additionalYPosition = leftPosition ? target?.attrs?.height / 2 : 0;

  if (isTextEllipsis || text) {
    setData({
      isVisible: true,
      top: yPosition + additionalYPosition + addY,
      left: xPosition + addX,
      data: text || value,
      position: leftPosition ? 'left' : 'top',
    });
  }
};

const hideFullNameTooltip = setData => setData({
  isVisible: false,
  top: 0,
  left: 0,
  data: null,
  position: 'top',
});

const handlePlantNameClick = (setData, cellData, e) => {
  /**
   * Не берём сам target, а копаем до родителя, т.к. клик может попасть во внутренний элемент
   * Обращение children[0] это хак, на самом деле здесь нужно получать по имени Rect
   */
  const target = e?.target?.parent?.children[0];
  const xPosition = target?.attrs?.x - target?.parent?.parent?.attrs?.offsetX;

  setData({
    isVisible: true,
    top: target.attrs.y + target.attrs.height + 5,
    left: xPosition,
    data: cellData,
  });
};


const Cell = memo(({
  intl,
  columnIndex,
  key,
  x,
  y,
  width,
  height,
  value,
  isCalculatedMetric,
  type,
  setTooltipData,
  metric,

  cellData,
  setErrorTooltipData,
}) => {
  const { formatMessage, locale } = intl;
  const isMetricCell = type === 'metricCell';
  const cellWidth = isMetricCell ? width - 32 : width;
  const align = columnIndex > 0 ? 'right' : 'left';
  // const offsetX = columnIndex > 0 ? 16 : -16;

  const isFxIcon = isCalculatedMetric && columnIndex === 0;
  const [image] = useImage(FxIconSvg);

  const formattedValue = numbersFormatting(value);
  const getTooltipText = () => {
    const description = metric?.description?.[locale];

    return `${value} – ${description}`;
  };
  const tooltipText = isMetricCell ? getTooltipText() : undefined;

  const validationErrors = cellData?.validationErrors;
  const isError = !isEmpty(validationErrors) && !isCalculatedMetric;
  const firstError = head(validationErrors);
  const firstErrorCode = firstError?.errorCode;
  const errorText = firstErrorCode ? formatMessage({ id: `formErrors.${firstErrorCode}` }, { minValue: firstError?.minValue, maxValue: firstError?.maxValue }) : null;
  const [errorHintImage] = useImage(ErrorHintSvg);

  return (
    <Group key={key}>
      <Rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={isCalculatedMetric ? '#F7F9FA' : '#FFF'}
        stroke='#e7e9ee'
        strokeWidth={1}
      />
      <Text
        x={x}
        y={y}
        width={cellWidth}
        height={height}
        text={formattedValue}
        verticalAlign='middle'
        align={align}
        offsetY={-1}
        // offsetX={offsetX}
        padding={16}
        fontFamily='Roboto'
        fill='#4A4A49'
        fontSize={14}
        wrap='none'
        ellipsis
        onMouseEnter={isMetricCell ? e => showFullNameTooltip(setTooltipData, e, tooltipText) : () => {}}
        onMouseLeave={isMetricCell ? e => hideFullNameTooltip(setTooltipData, e) : () => {}}
      />
      {isFxIcon && (
        <Image
          x={x + width - 32}
          y={y + 9}
          width={15}
          height={15}
          image={image}
          onMouseEnter={e => showFullNameTooltip(setTooltipData, e, formatMessage({ id: 'measurements.calculated' }), true, -12)}
          onMouseLeave={e => hideFullNameTooltip(setTooltipData, e)}
        />
      )}

      {isError && (
        <>
          <Image
            x={x + 16}
            y={y + 7}
            width={20}
            height={20}
            image={errorHintImage}
            onMouseEnter={e => showTooltip(setErrorTooltipData, e, errorText, false, 0, 0, true)}
            onMouseLeave={e => hideTooltip(setErrorTooltipData, e)}
          />
          <Rect
            x={x}
            y={y}
            width={width - 1}
            height={1}
            fill='#be1034'
          />
          <Rect
            x={x}
            y={y + height - 2}
            width={width - 1}
            height={1}
            fill='#be1034'
          />
          <Rect
            x={x}
            y={y}
            width={1}
            height={height - 1}
            fill='#be1034'
          />
          <Rect
            x={x + width - 2}
            y={y}
            width={1}
            height={height - 1}
            fill='#be1034'
          />
        </>
      )}
    </Group>
  );
});

Cell.propTypes = {
  intl: intlShape.isRequired,
  columnIndex: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  type: PropTypes.string.isRequired,
  setTooltipData: PropTypes.func.isRequired,
  setErrorTooltipData: PropTypes.func.isRequired,
  isCalculatedMetric: PropTypes.bool,
  metric: PropTypes.object,
  cellData: PropTypes.object,
};

Cell.defaultProps = {
  isCalculatedMetric: false,
  metric: null,
  cellData: null,
};

const HeaderCell = memo(({
  rowIndex,
  columnIndex,
  key,
  x,
  y,
  width,
  height,
  value,
  isLastCell,
  nameEditorData,
  setNameEditorData,
  requestCreateNextPlant,
  plantingCycleId,
  date,
  intl,
  type,
  setTooltipData,
}) => {
  const { formatMessage } = intl;
  const isAddPlantButton = type === 'addPlantHeader' || type === 'addExtraPlantHeader';
  const isMainExtraHeader = type === 'mainHeader' || type === 'extraHeader';
  const isExtraHeader = type === 'extraHeader';

  const cellData = {
    rowIndex,
    columnIndex,
    value,
    isLastCell,
  };

  let styleProps = {
    align: 'left',
    offsetX: -16,
    fontSize: 14,
    fontStyle: 500,
  };

  if (isMainExtraHeader) {
    styleProps = {
      align: 'center',
      offsetX: 0,
      fontSize: 14,
      fontStyle: 500,
    };
  }

  if (isAddPlantButton) {
    styleProps = {
      align: 'center',
      offsetX: 0,
      fontSize: 20,
      fontStyle: 300,
    };
  }

  const isPlantHeader = type === 'plantHeader';
  const getCellWidth = () => {
    if (isExtraHeader) {
      return width - 146; // какой-то баг рассчёта mergedCells, прибавляется лишняя ячейка (а мы тут её вычитаем)
    }

    return width;
  };
  const cellWidth = getCellWidth();
  const getTextWidth = () => {
    if (isExtraHeader) {
      return width - 146; // какой-то баг рассчёта mergedCells, прибавляется лишняя ячейка (а мы тут её вычитаем)
    }

    if (isPlantHeader) {
      return width - 42; // поправка на оффсет и иконку стрелочки
    }

    return width;
  };
  const textWidth = getTextWidth();

  const isClickedCell = columnIndex === nameEditorData?.data?.columnIndex && rowIndex === nameEditorData?.data?.rowIndex;

  const onCellClick = (event) => {
    if (isAddPlantButton) {
      const isExtra = type === 'addExtraPlantHeader';
      return requestCreateNextPlant({ plantingCycleId, date, extra: isExtra });
    }

    if (type === 'plantHeader') {
      return handlePlantNameClick(setNameEditorData, cellData, event);
    }

    return () => {};
  };

  const textRef = useRef(null);
  const [image] = useImage(ArrowDownSvg);
  const [extraHeaderImage] = useImage(InfoIconSvg);

  let iconPositionX = 0;

  if (isExtraHeader) {
    iconPositionX = textWidth / 2 + textRef?.current?.textWidth / 2;
  }

  return (
    <Group key={key} onClick={onCellClick} onTap={onCellClick}>
      <Rect
        x={x}
        y={y}
        width={cellWidth}
        height={height}
        fill={isClickedCell ? '#EFF2F4' : '#fafbfc'}
        stroke='#e7e9ee'
        strokeWidth={1}
      />
      <Text
        ref={textRef}
        x={x}
        y={y}
        width={textWidth}
        height={height}
        text={value}
        verticalAlign='middle'
        fill='#777776'
        fontStyle='500'
        fontFamily='Roboto'
        wrap='none'
        ellipsis
        onMouseEnter={isPlantHeader ? e => showFullNameTooltip(setTooltipData, e) : () => {}}
        onMouseLeave={isPlantHeader ? e => hideFullNameTooltip(setTooltipData, e) : () => {}}
        {...styleProps}
      />
      {isPlantHeader && (
        <Image
          x={x + width - 32}
          y={y + 9}
          width={20}
          height={20}
          image={image}
        />
      )}
      {isExtraHeader && (
        <Image
          x={x + iconPositionX + 4}
          y={y + 10}
          width={16}
          height={16}
          image={extraHeaderImage}
          onMouseEnter={e => showFullNameTooltip(setTooltipData, e, formatMessage({ id: 'measurements.extraTooltip' }), true, 0, -8, true)}
          onMouseLeave={e => hideFullNameTooltip(setTooltipData, e)}
        />
      )}
    </Group>
  );
});

HeaderCell.propTypes = {
  intl: intlShape.isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnIndex: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  type: PropTypes.string.isRequired,
  setTooltipData: PropTypes.func.isRequired,
  isCalculatedMetric: PropTypes.bool,

  isLastCell: PropTypes.bool,
  nameEditorData: PropTypes.object,
  setNameEditorData: PropTypes.func.isRequired,
  requestCreateNextPlant: PropTypes.func.isRequired,
  plantingCycleId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

HeaderCell.defaultProps = {
  isCalculatedMetric: false,
  isLastCell: false,
  nameEditorData: null,
};

const getColumnWidth = (plantsLength, extraPlantsLength, columnsHeadersCount, columnCount, columnIndex, getCellInfo, rowsHeadersCount) => {
  const { cellProps } = getCellInfo(rowsHeadersCount - 1, columnIndex);
  const type = cellProps?.type;
  const forcedWidth = cellProps?.forcedWidth;

  const isAddPlantButton = type === 'addPlantHeader' || type === 'addExtraPlantHeader';
  const isMainHeader = type === 'mainHeader';
  const isExtraHeader = type === 'extraHeader';

  if (isMainHeader) {
    return plantsLength * 146 + 64;
  }

  if (isExtraHeader) {
    return extraPlantsLength * 146 + 64;
  }

  if (forcedWidth) {
    return forcedWidth;
  }

  if (isAddPlantButton) {
    return 64;
  }

  if (type === 'metricHeader') {
    return 300;
  }

  return 146;
};

const getCellData = (rowIndex, columnIndex, metric, value) => {
  const trimmedValue = getTrimmedValue(value, '');
  const numericValue = trimmedValue === '' ? null : trimmedValue;
  const isError = !isNull(numericValue) && (!isNull(metric?.validationRangeMin) && !isNull(metric?.validationRangeMax)) && (numericValue < metric?.validationRangeMin || numericValue > metric?.validationRangeMax);

  if (isError) {
    return {
      validationErrors: [{
        errorCode: 'shouldBeBetween',
        minValue: metric?.validationRangeMin,
        maxValue: metric?.validationRangeMax,
      }],
    };
  }

  return {};
};

const itemRenderer = ({
  props, intl, rowsHeadersCount, getCellInfo, requestCreateNextPlant, plantingCycleId, date, nameEditorData, setNameEditorData, setTooltipData, setErrorTooltipData, columnCount, data
}) => {
  const { rowIndex, columnIndex } = props;
  const value = data[[rowIndex, columnIndex]];

  const { metric, cellProps } = getCellInfo(rowIndex, columnIndex);
  const isCalculatedMetric = metric?.calculated;
  const type = cellProps?.type;
  const cellData = getCellData(rowIndex, columnIndex, metric, value);
  // const cellData = getCellData(rowsHeadersCount, rowsHeadersCountFull, columnsHeadersCount, grid, rowIndex, columnIndex);

  if (rowIndex < rowsHeadersCount) {
    return (
      <HeaderCell
        {...props}
        // value='Header'
        value={data[[rowIndex, columnIndex]]}
        isLastCell={columnIndex === columnCount - 1}
        setNameEditorData={setNameEditorData}
        setTooltipData={setTooltipData}
        nameEditorData={nameEditorData}
        requestCreateNextPlant={requestCreateNextPlant}
        plantingCycleId={plantingCycleId}
        date={date}
        type={type}
        intl={intl}
      />
    );
  }

  return (
    <Cell
      intl={intl}
      {...props}
      value={value}
      isCalculatedMetric={isCalculatedMetric}
      setTooltipData={setTooltipData}
      setErrorTooltipData={setErrorTooltipData}
      type={type}
      metric={metric}
      cellData={cellData}
    />
  );
};

const MeasurementsEnterTable = ({
  intl,
  rowCount,
  columnCount,
  data,
  setData,
  plantingCycleId,
  date,
  requestCreateNextPlant,
  requestUpdatePlant,
  requestDeletePlant,
  getCellInfo,
  mergedCells,
  rowsHeadersCount,
  columnsHeadersCount,
  validatePlantName,
  plantsLength,
  extraPlantsLength,
  addPlantHeaderColumnIndex,
  childRef,
}) => {
  const { formatMessage } = intl;
  const [containerRef, { width, height }] = useMeasure();

  const [nameEditorData, setNameEditorData] = useState({
    isVisible: false,
    top: 0,
    left: 0,
    data: null,
  });

  const [tooltipData, setTooltipData] = useState({
    isVisible: false,
    top: 0,
    left: 0,
    data: null,
    position: 'top',
  });

  const [errorTooltipData, setErrorTooltipData] = useState({
    isVisible: false,
    top: 0,
    left: 0,
    data: null,
    position: 'left',
  });

  const canEdit = ({ rowIndex, columnIndex }) => {
    const { cellProps: headerCellProps } = getCellInfo(rowsHeadersCount - 1, columnIndex);
    const { metric } = getCellInfo(rowIndex, columnIndex);
    const isCalculatedMetric = metric?.calculated;
    const isAddPlantColumn = headerCellProps?.type === 'addPlantHeader' || headerCellProps?.type === 'addExtraPlantHeader';

    if (rowIndex < 1 || columnIndex < 1 || isCalculatedMetric || isAddPlantColumn) return false;

    return true;
  };

  return (
    <div ref={containerRef} className={styles.dataSheetWrapper}>
      <DefaultCanvasGrid
        ref={childRef}
        intl={intl}
        width={width}
        height={height}
        rowCount={rowCount}
        columnCount={columnCount}
        data={data}
        setData={setData}
        date={date}
        plantingCycleId={plantingCycleId}
        getCellInfo={getCellInfo}
        mergedCells={mergedCells}
        rowsHeadersCount={rowsHeadersCount}
        columnsHeadersCount={columnsHeadersCount}
        validatePlantName={validatePlantName}
        extraPlantsLength={extraPlantsLength}
        plantsLength={plantsLength}
        addPlantHeaderColumnIndex={addPlantHeaderColumnIndex}
        getColumnWidth={columnIndex => getColumnWidth(plantsLength, extraPlantsLength, columnsHeadersCount, columnCount, columnIndex, getCellInfo, rowsHeadersCount)}
        itemRenderer={props => itemRenderer({
          props, intl, rowsHeadersCount, getCellInfo, requestCreateNextPlant, plantingCycleId, date, nameEditorData, setNameEditorData, setTooltipData, setErrorTooltipData, columnCount, data
        })}
        canEdit={canEdit}
        tooltipData={tooltipData}
        // afterOnChange={changes => handlerAfterOnSubmit(planType, organization, setHarvestPlanGrid, setNewTableData, rowsHeadersCountFull, columnsHeadersCount, harvestPlanEdited, grid, changes)}
        // additionalContextMenuItems={additionalContextMenuItems}
        contextMenuOffsetTop={200}
      />
      {nameEditorData?.isVisible && (
        <NameEditorComponent
          nameEditorData={nameEditorData}
          setNameEditorData={setNameEditorData}
          requestUpdate={(cellProps, plantName) => requestUpdatePlant({
            plantingCycleId,
            discriminator: cellProps.discriminator,
            name: plantName,
            date,
          })}
          requestDelete={cellProps => requestDeletePlant({
            plantingCycleId,
            discriminator: cellProps.discriminator,
            date,
          })}
          getCellInfo={getCellInfo}
          initialValues={{
            plantName: nameEditorData?.data?.value || ''
          }}
          validatePlantName={validatePlantName}
          deleteConfirmMessage={formatMessage({ id: 'measurements.deleteDialog' })}
        />
      )}
      {errorTooltipData?.isVisible && (
        <Tooltip
          text={errorTooltipData.data}
          style={{
            top: errorTooltipData.top,
            left: errorTooltipData.left,
            maxWidth: 168,
          }}
          leftPosition={errorTooltipData.position === 'left'}
          error
        />
      )}
    </div>
  );
};

MeasurementsEnterTable.propTypes = {
  intl: intlShape.isRequired,
  plantingCycleId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columnCount: PropTypes.number.isRequired,
  data: PropTypes.object,
  setData: PropTypes.func.isRequired,
  requestCreateNextPlant: PropTypes.func.isRequired,
  requestUpdatePlant: PropTypes.func.isRequired,
  requestDeletePlant: PropTypes.func.isRequired,
  getCellInfo: PropTypes.func.isRequired,
  mergedCells: PropTypes.array,
  rowsHeadersCount: PropTypes.number.isRequired,
  columnsHeadersCount: PropTypes.number.isRequired,
  validatePlantName: PropTypes.func.isRequired,
  plantsLength: PropTypes.number,
  extraPlantsLength: PropTypes.number,
  addPlantHeaderColumnIndex: PropTypes.number.isRequired,
  childRef: PropTypes.object,
};

MeasurementsEnterTable.defaultProps = {
  data: null,
  mergedCells: [],
  plantsLength: 0,
  extraPlantsLength: 0,
  childRef: null,
};

export default injectIntl(memo(MeasurementsEnterTable));
