import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';

import { ReactComponent as ErrorIcon } from './assets/edit_error.svg';
import styles from './NumberInput.module.css';


export default class NumberInput extends Component {
  static propTypes = {
    value: PropTypes.any,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isError: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    onChange: null,
    value: '',
    placeholder: '',
    disabled: false,
    isError: false,
  };

  handlerChange = (e) => {
    const {
      min,
      max,
      onChange,
    } = this.props;

    const sample = 1.1;
    const delimeter = /^1(.+)1$/.exec(sample.toLocaleString())[1]; // FIXME

    const { value } = e.target;
    const preparedValue = value.toString().replace('.', delimeter).replace(',', delimeter).replace(delimeter, '.');

    const parsedValue = +preparedValue;

    let isOverMax = false;
    let isOverMin = false;

    if (Number.isFinite(max)) {
      isOverMax = parsedValue > max;
    }

    if (Number.isFinite(min)) {
      isOverMin = parsedValue < min;
    }

    const isError = value === '' ? false : Number.isNaN(parsedValue) || !Number.isFinite(parsedValue) || isOverMax || isOverMin;

    if (onChange) {
      onChange(value, isError);
    }
  };

  render() {
    const {
      className,
      value,
      disabled,
      isError,
      placeholder,
    } = this.props;


    return (
      <div className={styles.valueEditorWrapper}>
        <input
          disabled={disabled}
          type='text'
          placeholder={placeholder}
          onChange={this.handlerChange}
          value={value}
          className={classnames(
            styles.valueEditor,
            className,
            {
              [styles.invalid]: isError,
              invalid: isError,
            }
          )}
        />
        <ErrorIcon className={styles.valueErrorIcon} />
      </div>
    );
  }
}
