import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import { uniqueId } from 'lodash';
import classnames from 'classnames';

import ReactTooltip from 'react-tooltip';

import tooltipStyles from '../../Tooltip/index.module.css';
import styles from './DefaultToggle.module.css';

export default class DefaultToggle extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onToggleChange: PropTypes.func.isRequired,
    toggleState: PropTypes.bool.isRequired,
    leftIcon: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.node,
      PropTypes.element
    ]).isRequired,
    rightIcon: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.node,
      PropTypes.element
    ]).isRequired,
    leftTooltip: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]),
    rightTooltip: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]),
  };

  static defaultProps = {
    className: null,
    disabled: false,
    leftTooltip: null,
    rightTooltip: null,
  };

  render() {
    const {
      className, toggleState, onToggleChange, leftIcon, rightIcon, disabled,
      leftTooltip, rightTooltip,
    } = this.props;

    const tooltipId = `Toggle-${uniqueId()}`;

    return (
      <div
        role='button'
        tabIndex={0}
        className={classnames(
          styles.cumulativeToggle,
          {
            [styles.cumulativeOn]: toggleState,
            [styles.disabled]: disabled,
          },
          className
        )}
        onClick={disabled ? () => {} : onToggleChange}
      >
        <div
          className={classnames(styles.cumulativeToggleIcon, { [styles.selected]: !toggleState })}
          data-tip={leftTooltip}
          data-for={tooltipId}
        >
          {leftIcon}
        </div>
        <div
          className={classnames(styles.cumulativeToggleIcon, { [styles.selected]: toggleState })}
          data-tip={rightTooltip}
          data-for={tooltipId}
        >
          {rightIcon}
        </div>
        {(leftTooltip || rightTooltip) && (
          <ReactTooltip
            className={tooltipStyles.smallTooltip}
            id={tooltipId}
            effect='solid'
            html
          />
        )}
      </div>
    );
  }
}
