import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import BigButton from '../BigButton';

import styles from './MicroForm.module.css';


class MicroForm extends Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      component: PropTypes.func.isRequired,
      props: PropTypes.object,
      isRequired: PropTypes.bool,
      options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
      })),
    })).isRequired,
    isLoading: PropTypes.bool,
    cancelTitle: PropTypes.string,
    submitTitle: PropTypes.string,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    validate: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    className: null,
    isLoading: false,
    cancelTitle: null,
    submitTitle: null,
    pristine: false,
    submitting: false,
    invalid: false,
    validate: null,
    onSubmit: null,
    onCancel: null,
  };

  handlerCancel = (options) => {
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel(options);
    }
  };

  handlerSubmit = (options) => {
    const { onSubmit } = this.props;

    if (onSubmit) {
      return onSubmit(options);
    }

    return options;
  };

  render() {
    const {
      fields,
      isLoading,
      submitTitle,
      cancelTitle,
      submitting,
      handleSubmit,
    } = this.props;

    return (
      <div className={classnames(styles.layout)}>
        <form className={styles.form} onSubmit={handleSubmit(this.handlerSubmit)}>
          {fields.map((field) => {
            const {
              id,
              options,
              component,
              props,
            } = field;

            return (
              <div className={styles.row}>
                <Field
                  name={id}
                  component={component}
                  options={options}
                  className={styles.field}
                  classNameButton={styles.fieldButton}
                  {...props}
                />
              </div>
            );
          })}

          <div className={styles.controls}>
            {cancelTitle && (
              <BigButton
                className={styles.button}
                title={cancelTitle}
                type='button'
                onClick={this.handlerCancel}
                theme='light'
              />
            )}
            <BigButton
              className={styles.button}
              title={submitTitle}
              type='submit'
              theme='dark'
              disabled={submitting}
              isLoading={isLoading}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({ destroyOnUnmount: true })(MicroForm);
