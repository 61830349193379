import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import Truncate from 'react-truncate';

import { uniqueId } from 'lodash';
import classnames from 'classnames';

import isTouchDevice from '../../helpers/isTouchDevice';


import tooltipStyles from '../Tooltip/index.module.css';
import styles from './ReadMore.module.css';

class ReadMore extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    text: PropTypes.string.isRequired,
    lines: PropTypes.number,
  };

  static defaultProps = {
    lines: 1,
  };

  state = {
    opened: false,
    truncated: false,
    isTouch: isTouchDevice(),
  }

  handlerTruncate = (newTruncated) => {
    const { truncated } = this.state;

    if (truncated !== newTruncated) {
      this.setState({
        truncated: newTruncated
      });
    }
  };

  handlerClick = (e) => {
    e.stopPropagation();

    const { opened } = this.state;

    this.setState({ opened: !opened });
  };

  render() {
    const { intl: { formatMessage }, text, lines } = this.props;
    const { isTouch, opened, truncated } = this.state;
    const tooltipId = `text-ellipsis-${uniqueId()}`;

    return (
      <span className={styles.textWrapper}>
        <Truncate
          lines={!opened && lines}
          ellipsis={(
            <span>
              {'... '}
              <button
                type='button'
                className={classnames(styles.ellipsisButton, { [styles.ellipsisButtonOpened]: opened })}
                onClick={this.handlerClick}
                data-tip={opened ? formatMessage({ id: 'button.showLess' }) : formatMessage({ id: 'button.showMore' })}
                data-for={isTouch ? null : tooltipId}
              >
                ...
              </button>
            </span>
          )}
          onTruncate={this.handleTruncate}
        >
          {text}
        </Truncate>
        {!truncated && opened && (
          <button
            type='button'
            className={classnames(styles.ellipsisButton, { [styles.ellipsisButtonOpened]: opened })}
            onClick={this.handlerClick}
            data-tip={opened ? formatMessage({ id: 'button.showLess' }) : formatMessage({ id: 'button.showMore' })}
            data-for={isTouch ? null : tooltipId}
          >
            ...
          </button>
        )}

        <ReactTooltip
          className={classnames(tooltipStyles.smallTooltip, styles.tooltip)}
          id={tooltipId}
          effect='solid'
          event={null}
          html
        />
      </span>
    );
  }
}

export default injectIntl(ReadMore);
