import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getOrganizationSlug,
  getDefaultOpenFromRoute,
  getClimateInsightsAccess,
} from 'store/company/selectors';
import { trackPageViewSettings } from 'store/company/actions';

import {
  getAlertRules,
  getIsAlertRulesFetching,
  getIsIncidentTypesFetching,
  getIsIncidentTypeCategoriesFetching,
} from 'store/incidents/selectors';

import {
  requestIncidentTypeCategories,
  requestIncidentTypes,
  requestAlertRules,
  requestUpdateAlertState,
} from 'store/incidents/actions';

import { requestUserPrivileges } from 'store/user/actions';
import { getIsUserPrivilegesFetching } from 'store/user/selectors';

import SettingsAlerting from '../components/SettingsAlerting';

const mapDispatchToProps = {
  trackPageViewSettings,
  requestIncidentTypeCategories,
  requestIncidentTypes,
  requestAlertRules,
  requestUserPrivileges,
  requestUpdateAlertState,
};

const mapStateToProps = (state, props) => ({
  ...props,

  organizationSlug: getOrganizationSlug(state, props),
  defaultOpen: getDefaultOpenFromRoute(state, props),
  alertRules: getAlertRules(state, props),
  incidentsAccess: getClimateInsightsAccess(state, props),
  isSettingsFetching: getIsAlertRulesFetching(state, props)
    ||
    getIsIncidentTypeCategoriesFetching(state, props)
    ||
    getIsIncidentTypesFetching(state, props)
    ||
    getIsUserPrivilegesFetching(state, props)
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsAlerting)));
