import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import Dashboards from '../../components/Dashboards';

export default class DashboardsRoutes extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  render() {
    const { match } = this.props;
    const { params: { organizationSlug } } = match;

    return (
      <Switch>
        <Route
          key='old-compare'
          path='/:organizationSlug/dashboards/benchmarking/compare'
          render={() => <Redirect to={`/${organizationSlug}/crops/compare`} />}
        />
        <Route path='/:organizationSlug/dashboards/:tabName?' component={Dashboards} />
      </Switch>
    );
  }
}
