import React, { Component, } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { isNil } from 'lodash';

import { ANIMATION_DURATION } from '../../helpers/constants';

import styles from './MobileMenu.module.css';

import { ReactComponent as CloseIcon } from './assets/close.svg';

export default class MobileMenu extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.node,
    ]).isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.node,
    ]).isRequired,
    headerText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]).isRequired,
    className: PropTypes.string,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    menuOpened: PropTypes.bool,
    withoutCloseIcon: PropTypes.bool,
    menuClassName: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    onOpen: null,
    onClose: null,
    menuOpened: null,
    withoutCloseIcon: false,
    menuClassName: undefined,
  }

  state = {
    opened: this.props.menuOpened,
  };

  handlerOpenMenu = () => {
    const { onOpen } = this.props;

    if (onOpen) {
      onOpen();
    }

    this.toggleMenu(true);
  };

  handlerCloseMenu = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }

    this.toggleMenu(false);
  };

  toggleMenu = isOpened => this.setState({ opened: isOpened });

  renderOpenButton = icon => (
    <div
      className={styles.openMenuBtn}
      onClick={this.handlerOpenMenu}
      tabIndex={0}
      role='button'
    >
      {icon}
    </div>
  );

  render() {
    const {
      children, headerText, icon, className, menuOpened, withoutCloseIcon, menuClassName
    } = this.props;
    const { opened } = this.state;

    // Если состоянием управляем из props, то берём props
    const menuState = isNil(menuOpened) ? opened : menuOpened;

    return (
      <div className={classnames(styles.menuContainer, className)}>
        {this.renderOpenButton(icon)}

        <CSSTransition
          in={menuState}
          timeout={ANIMATION_DURATION}
          unmountOnExit
          classNames={{
            enter: styles.menuEnter,
            enterActive: styles.menuEnterActive,
            exit: styles.menuLeave,
            exitActive: styles.menuLeaveActive,
          }}
        >
          <div className={classnames(styles.menu, menuClassName)}>
            <div className={styles.menuHeader}>
              <div>
                {headerText}
              </div>
              {!withoutCloseIcon && (
                <div
                  className={styles.closeIcon}
                  onClick={this.handlerCloseMenu}
                  tabIndex={0}
                  role='button'
                >
                  <CloseIcon />
                </div>
              )}
            </div>
            <div className={styles.menuContent}>
              {children}
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}
