import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';

import moment from 'moment-timezone';
import getDateFormat from 'helpers/getDateFormat';

import styles from './LinesGraphTooltip.module.css';

class LinesGraphTooltip extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    lines: PropTypes.array.isRequired,
    isSpaced: PropTypes.bool,
    periodType: PropTypes.string.isRequired,
    customRenderDate: PropTypes.func,
    tooltipDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    tooltipText: PropTypes.string
  };

  static defaultProps = {
    customRenderDate: null,
    tooltipDate: null,
    isSpaced: false,
    tooltipText: null
  };

  renderLines = lines => lines.map((line) => {
    const {
      id, value, header, color
    } = line;
    return (
      <div key={`tooltipLine-${id}`} className={styles.line}>
        <span className={styles.lineHeader}>
          {color && <span className={styles.lineColor} style={color ? { background: color } : null} />}
          <span className={styles.lineTitle}>{header}</span>
          {line.description && (
          <span className={styles.lineSubTitle}>&nbsp;({line.description})</span>
          )}
        </span>
        <span className={styles.lineValue}>{value}</span>
      </div>
    );
  });

  renderDate = (tooltipDate, periodType) => {
    const { intl: { formatMessage }, customRenderDate } = this.props;

    if (customRenderDate) {
      return customRenderDate(tooltipDate, periodType);
    }

    if (periodType === 'weekLight') {
      return `${formatMessage({ id: 'tables.week.one' })} ${tooltipDate}`;
    }

    if (periodType === 'week') {
      // Берём последний день недели. Проверяем попадает ли в текущий год. Если нет, то добавлеяем в тултип.
      const dateYear = tooltipDate.clone().isoWeekday(7).format('YYYY');
      const currentYear = moment(new Date()).format('YYYY');
      const isAddPrevYear = dateYear !== currentYear;
      const prevYearText = isAddPrevYear ? `, ${dateYear}` : '';

      const week = tooltipDate.isoWeek();

      // TODO: скопипастил, вынести в хелпер работы с датами
      const weekDates = `${tooltipDate.clone().isoWeekday(1).format(`${formatMessage({ id: 'date.dayMonth' })}`)} – ${tooltipDate.clone().isoWeekday(7).format(`${formatMessage({ id: 'date.dayMonth' })}`)}`;

      return `${formatMessage({ id: 'tables.week.one' })} ${week}, ${weekDates}${prevYearText}`;
    }

    if (periodType === 'month') {
      return tooltipDate.format('MMM, YYYY');
    }

    if (periodType === 'day') {
      return tooltipDate.format(getDateFormat('lll'));
    }

    return tooltipDate.format(getDateFormat('lll'));
  };

  render() {
    const {
      lines, periodType, tooltipDate, isSpaced, tooltipText
    } = this.props;
    let currentDate;
    if (tooltipDate) {
      if (periodType === 'weekLight') {
        currentDate = tooltipDate;
      } else {
        currentDate = tooltipDate.format('MM-DD-YYYY');
      }
    }
    if (!currentDate && !tooltipText) {
      return null;
    }
    const dateText = tooltipText || this.renderDate(tooltipDate, periodType);
    return (
      <div className={classnames({ [styles.spased]: isSpaced })}>
        <div className={styles.tooltipDate}>{dateText}</div>
        <div>
          {this.renderLines(lines)}
        </div>
      </div>
    );
  }
}

export default injectIntl(LinesGraphTooltip);
