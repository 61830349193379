import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import DefaultSimpleTooltip from 'components/DefaultSimpleTooltip';

import styles from './BigSwitch.module.css';

export default class BigSwitch extends Component {
  static propTypes = {
    title: PropTypes.any,
    titleLeft: PropTypes.any,
    className: PropTypes.string,
    value: PropTypes.bool,
    onClick: PropTypes.func,
    tooltip: PropTypes.string,
  };

  static defaultProps = {
    onClick: null,
    title: null,
    titleLeft: null,
    className: null,
    value: false,
    tooltip: undefined,
  };

  handlerClick = (e) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(e);
    }
  };

  renderSwitchContent = () => {
    const {
      value,
    } = this.props;

    const clickProps = {
      onClick: this.handlerClick,
    };

    return (
      <button type='button' className={styles.wrapper} {...clickProps}>
        <div className={classnames(styles.button, { [styles.enabled]: value })}>
          <div className={styles.circle} />
        </div>
      </button>
    );
  };

  render() {
    const {
      value,
      title,
      titleLeft,
      className,
      tooltip
    } = this.props;

    const clickProps = {
      onClick: this.handlerClick,
    };

    return (
      <div className={classnames(styles.component, className, { [styles.enabledWrapper]: value })}>
        {titleLeft && (
          <div className={styles.titleLeft} {...clickProps}>
            {titleLeft}
          </div>
        )}
        {tooltip ? (
          <DefaultSimpleTooltip text={tooltip} noWrap>
            {this.renderSwitchContent()}
          </DefaultSimpleTooltip>
          )
          :
          this.renderSwitchContent()}
        {title && (
          <div className={styles.title} {...clickProps}>
            {title}
          </div>
        )}
      </div>
    );
  }
}
