import { find, get } from 'lodash';

export default function prepareProductGroupParams(allVarieties, species, varietyId, fruitClassCode) {
  let innerFruitClassCode = fruitClassCode;

  if (varietyId) {
    const currentVariety = find(allVarieties, { id: varietyId });
    const varietyFruitClassCode = get(currentVariety, 'attributes.fruitClass');

    if (varietyFruitClassCode) {
      innerFruitClassCode = varietyFruitClassCode;
    }
  }

  const params = { species, varietyId, fruitClassCode: innerFruitClassCode };

  const filteredProductGroups = [[species, innerFruitClassCode, varietyId].filter(x => x)].filter(item => item && item.length > 1).map(item => item.join('_'));

  // TODO: вынести в хелпер, уже где-то применяется
  const preparedParams = params ? Object.keys(params).reduce((reducer, key) => {
    const temp = reducer;

    const item = params[key];

    if (Array.isArray(item)) {
      temp[key] = item.join(',');
    } else {
      temp[key] = item;
    }
    return temp;
  }, {}) : {};

  return {
    species: preparedParams.species || undefined,
    productGroup: filteredProductGroups.length > 0 ? filteredProductGroups : undefined,
    fruitClassCode: preparedParams.fruitClassCode || undefined,
    varietyId: preparedParams.varietyId || undefined,
  };
}
