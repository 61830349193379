import React, {
  useEffect, useRef, useCallback, useState
} from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import cn from 'classnames';
import {
 uniqueId,
} from 'lodash';

import ClickOutside from 'components/ClickOutside';
import SearchIcon from 'components/Icons/SearchIcon';
import CloseIcon from 'components/Icons/Close';

import styles from './UiSearchInput.module.css';

const handlerDocumentKeydown = ({
  event, searchOptions, hoveredOption, setHoveredOption, searchInputResultsOpen, setSearchInputResultsOpen, handlerClickToSearchOption
}) => {
  if (searchInputResultsOpen) {
    if (event.key === 'Esc' || event.keyCode === 27) {
      setSearchInputResultsOpen(false);
    }

    if (event.key === 'Enter' || event.keyCode === 13) {
      handlerClickToSearchOption(hoveredOption);
    }
  }

  if (searchInputResultsOpen && hoveredOption && searchOptions && searchOptions.length) {
    const index = searchOptions.findIndex(item => item.id === hoveredOption.id) || 0;

    if (event.key === 'ArrowUp' || event.keyCode === 38) {
      setHoveredOption(searchOptions[index - 1 >= 0 ? index - 1 : index]);
    }

    if (event.key === 'ArrowDown' || event.keyCode === 40) {
      setHoveredOption(searchOptions[index + 1 < searchOptions.length ? index + 1 : index]);
    }
  }
};

const getSearchOptions = (optionsList, searchInput) => {
  if (searchInput.trim().length < 1) {
    return [];
  }

  return optionsList.filter(option => option.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1);
};

const renderSearchResultsBody = ({
  searchOptions,
  searchInput,
  handlerClickToSearchOption,
  hoveredOption,
  setHoveredOption,
}) => (
  <div className={styles.filterOptions}>
    {searchOptions.length ? searchOptions.map((option) => {
      const { id, name } = option;
      const processedFilterWords = searchInput.split(' ');

      const processedTitle = name
        .split(' ')
        .map((titleChunk) => {
          let processedChunk = titleChunk;
          processedFilterWords.forEach((processedFilterWord) => {
            const boldPosition = titleChunk.toLowerCase().indexOf(processedFilterWord.toLowerCase());

            if (boldPosition >= 0 && processedChunk.toLowerCase().localeCompare(titleChunk.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }) === 0) {
              processedChunk = [
                titleChunk.slice(0, boldPosition),
                '<strong>',
                titleChunk.slice(boldPosition, boldPosition + processedFilterWord.length),
                '</strong>',
                titleChunk.slice(boldPosition + processedFilterWord.length),
              ].join('');
            }
          });
          return processedChunk;
        }).join(' ');

        const isHovered = hoveredOption && hoveredOption.id === id;

      /* eslint-disable react/no-danger */
      return (
        <div
          role='button'
          tabIndex={0}
          key={uniqueId('filter-metric-')}
          className={cn(styles.filterOption, { [styles.hovered]: isHovered })}
          dangerouslySetInnerHTML={{ __html: processedTitle }}
          onClick={() => handlerClickToSearchOption(option)}
          onKeyDown={() => handlerClickToSearchOption(option)}
          onMouseEnter={() => setHoveredOption(option)}
        />
      );
      /* eslint-enable react/no-danger */
    }) : null}
  </div>
);


const renderSearchResults = ({
  formatMessage,
  searchOptions,
  searchInput,
  setSearchInputResultsOpen,
  handlerClickToSearchOption,
  hoveredOption,
  setHoveredOption,
  optionsClassName,
}) => (
  <ClickOutside
    handleClickOutside={() => setSearchInputResultsOpen(false)}
  >
    <div className={cn(styles.filterOptionsPopup, optionsClassName)}>
      {
        !searchOptions.length && (
          <div className={styles.filterEmpty}>
            {formatMessage({ id: 'graphs.sidePanel.noSearchResult' })}
          </div>
        )
      }
      {
        searchOptions.length ? (
          renderSearchResultsBody({
            searchOptions,
            searchInput,
            handlerClickToSearchOption,
            hoveredOption,
            setHoveredOption,
          })
        ) : null
      }
    </div>
  </ClickOutside>
);

const UiSearchInput = ({
  intl: { formatMessage },
  placeholder,
  optionsList,
  onOptionClick,
  onEmpty,
  optionsClassName,
}) => {
  const inputRef = useRef(null);
  const [searchInput, setSearchInput] = useState('');
  const [searchInputResultsOpen, setSearchInputResultsOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState();

  const searchOptions = getSearchOptions(optionsList, searchInput);

  const handlerClickToSearchOption = useCallback((option) => {
    setSearchInput(option.name);
    onOptionClick(option.id);
    setSearchInputResultsOpen(false);
  }, [
    setSearchInput,
    onOptionClick,
    setSearchInputResultsOpen,
  ]);

  const handleKeyDown = useCallback(event => handlerDocumentKeydown({
    event,
    searchOptions,
    hoveredOption,
    setHoveredOption,
    searchInputResultsOpen,
    setSearchInputResultsOpen,
    handlerClickToSearchOption,
  }), [
    searchOptions,
    hoveredOption,
    setHoveredOption,
    searchInputResultsOpen,
    setSearchInputResultsOpen,
    handlerClickToSearchOption,
  ]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handlerInputChange = useCallback((e) => {
    if (e.target.value === '') {
      onEmpty();
    }

    setSearchInput(e.target.value);
    setSearchInputResultsOpen(true);
  }, [setSearchInputResultsOpen, setSearchInput, onEmpty]);


  const handlerClear = useCallback(() => {
    setSearchInput('');
    onEmpty();
  }, [
    setSearchInput,
    onEmpty,
  ]);

  return (
    <div className={styles.uiSearchInput}>
      <div className={cn(styles.searchControl)}>
        <div className={cn(styles.icon, styles.searchInputButton, styles.searchInputButtonLeft)}>
          <SearchIcon />
        </div>
        <input
          type='text'
          className={styles.searchInput}
          onChange={handlerInputChange}
          value={searchInput}
          ref={inputRef}
          placeholder={placeholder || formatMessage({ id: 'graphs.searchPlaceholder' })}
        />
        {searchInput !== '' && (
          <div
            role='menu'
            tabIndex={0}
            className={cn(styles.icon, styles.searchInputButton, styles.searchInputButtonRight)}
            onClick={() => { handlerClear(); if (inputRef) { inputRef.current.focus(); } }}
            onKeyDown={() => { handlerClear(); if (inputRef) { inputRef.current.focus(); } }}
          >
            <CloseIcon />
          </div>
        )}

        {searchInputResultsOpen && searchInput.trim().length > 0 && (
          renderSearchResults({
            formatMessage,
            searchOptions,
            searchInput,
            setSearchInputResultsOpen,
            handlerClickToSearchOption,
            hoveredOption,
            setHoveredOption,
            optionsClassName,
          })
        )}
      </div>
    </div>
  );
};


UiSearchInput.propTypes = {
  intl: intlShape.isRequired,
  placeholder: PropTypes.string,
  optionsList: PropTypes.array,
  onOptionClick: PropTypes.func.isRequired,
  onEmpty: PropTypes.func.isRequired,
  optionsClassName: PropTypes.string,
};

UiSearchInput.defaultProps = {
  placeholder: undefined,
  optionsList: [],
  optionsClassName: undefined,
};

export default injectIntl(UiSearchInput);
