import moment from 'moment';
import getDateFormat from 'helpers/getDateFormat';
import React from 'react';

const getFormattedDate = (formatMessage, periodData, period = 'month', weekTwoLine = true) => {
  const momentStartDate = moment.utc(periodData?.startDate);
  const momentEndDate = moment.utc(periodData?.endDate);

  switch (period) {
    case 'week': {
      const startDate = momentStartDate.format(getDateFormat('ll'));
      const endDate = momentEndDate.format(getDateFormat('ll'));
      return (<span>{`${startDate} – ${endDate},`}{weekTwoLine && <br />}{`${formatMessage({ id: 'cycle.wk' })} ${periodData?.weekNumber}`}</span>);
    }
    case 'day': {
      const wk = `${formatMessage({ id: 'cycle.wk' })} ${periodData?.weekNumber}`;
      return `${momentStartDate.format('MMM D')}, ${wk}`;
    }
    case 'month':
    default:
      return momentStartDate.format('MMM');
  }
};

export default getFormattedDate;
