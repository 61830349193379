export const getLeftPhotos = state => state?.photos?.leftPhotos;
export const getLeftPhotosMap = state => state?.photos?.leftPhotosMap;
export const getPhotoCategories = state => state?.photos?.photoCategories;

export const getSelectedPhoto = state => state?.photos?.selectedPhoto;
export const getSelectedYear = state => state?.photos?.selectedYear;
export const getSelectedWeek = state => state?.photos?.selectedWeek;
export const getSelectedCompartment = state => state?.photos?.selectedCompartment;
export const getSelectedPlantingCycle = state => state?.photos?.selectedPlantingCycle;
export const getSelectedPhotos = state => state?.photos?.selectedPhotos;
