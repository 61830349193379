import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import { uniqueId } from 'lodash';

import { showIntercom } from 'helpers/intercomHelpers';

import PageViewTracker from 'components/PageViewTracker';
import Typography from 'components/Typography';
import BigButton from 'components/BigButton';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import DefaultPlate from 'components/DefaultPlate';
import DefaultTable from 'components/DefaultTable';

import renderHeaderRows from '../utils/renderHeaderRows';
import renderRows from '../utils/renderRows';

import styles from './SettingsLaborWorks.module.css';

const CultureWorkTypesTable = injectIntl(({
  intl: { formatMessage, locale },
  curlureType,
  works
}) => (works?.length > 0 ? (
  <div className={styles.tableWrapper}>
    <Typography variant='h2' className={styles.header}>
      {formatMessage({ id: `plantingCycles.speciesPlural.${curlureType}` })}
    </Typography>
    <DefaultTable
      headerRows={renderHeaderRows(styles, formatMessage)}
      bodyRows={renderRows({
        styles,
        workTypes: works,
        formatMessage,
        locale
      })}
    />
  </div>
) : null));

const SettingsLaborWorks = ({
  intl: { formatMessage },
  workTypes,
  isFetching,
  requestLaborWorkTypes,
  trackPageViewSettings,
  defaultOpen,
}) => {
  useEffect(() => {
    requestLaborWorkTypes();
  }, [requestLaborWorkTypes]);
  const isEmpty = !workTypes || !workTypes.length;

  const handlerPageView = useCallback(() => {
    trackPageViewSettings({ settingName: 'Works', defaultOpen });
  }, [trackPageViewSettings, defaultOpen]);

  return (
    <div className={styles.settingsLaborWorks}>
      <PageViewTracker onMount={handlerPageView} />

      {isFetching && <DefaultCircleLoader />}

      <Typography variant='h2' className={styles.header}>
        {formatMessage({ id: 'settings.laborWorkTypes' })}
      </Typography>

      <DefaultPlate className={styles.infoPlate}>
        <div>
          {formatMessage({ id: 'labor.toChangeGreenhousesWorks' })}
          &nbsp;
          <BigButton
            className={styles.supportButton}
            onClick={showIntercom}
            title={formatMessage({ id: 'settings.contactSupport' })}
            theme='transparent'
          />
        </div>
      </DefaultPlate>
      {!isEmpty && (
        <div>
          {workTypes.map(workTypeItem => (
            <CultureWorkTypesTable
              key={uniqueId('work-type-')}
              curlureType={workTypeItem.type}
              works={workTypeItem.works}
            />
          ))}
        </div>
      )}
    </div>
  );
};

SettingsLaborWorks.propTypes = {
  intl: intlShape.isRequired,
  workTypes: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  requestLaborWorkTypes: PropTypes.func.isRequired,
  trackPageViewSettings: PropTypes.func.isRequired,
  defaultOpen: PropTypes.bool,
};

SettingsLaborWorks.defaultProps = {
  workTypes: [],
  defaultOpen: false,
};

export default SettingsLaborWorks;
