// import { createSelector } from 'reselect';

// import { getMatch } from 'store/company/selectors';

export const getIsSimulationFetching = state => state?.digitalTwin?.isSimulationFetching;
export const getIsSimulationSaving = state => state?.digitalTwin?.isSimulationSaving;
export const getIsSimulationDeleting = state => state?.digitalTwin?.isSimulationDeleting;
export const getIsSimulationsListFetching = state => state?.digitalTwin?.isSimulationsListFetching;
export const getIsNewSimulationIdFetching = state => state?.digitalTwin?.isNewSimulationIdFetching;
export const getIsNewSimulationParamsFetching = state => state?.digitalTwin?.isNewSimulationParamsFetching;
export const getIsSimulationCalculated = state => state?.digitalTwin?.isSimulationCalculated;
export const getSupportedVarietiesFetching = state => state?.digitalTwin?.isSupportedVarietiesFetching;

export const getSimulationsList = state => state?.digitalTwin?.simulationsList;
export const getNewSimulationId = state => state?.digitalTwin?.newSimulationId;
export const getSupportedVarieties = state => state?.digitalTwin?.supportedVarieties;
export const getCurrentSimulation = state => state?.digitalTwin?.currentSimulation;
export const getCurrentSimulationEdited = state => state?.digitalTwin?.currentSimulationEdited;
