import { textEllipsis } from 'components/CanvasTimeline/helpers/TextUtils';
import { addPoints, adjustRectHorizontal } from '../helpers/GeometryUtils';
import { roundRect } from '../helpers/DrawUtils';

import SvgIcon from '../helpers/SvgIcon';
import DataLineTooltip from './DataLineTooltip';
import CanvasComponent from './CanvasComponent';

import { ReactComponent as ExpandIcon } from '../assets/expand.svg';

class DataLineHeader extends CanvasComponent {
  constructor({
    baseWidth,
    varietyTitle,
    typeTitle,
    typeValue,
    position = { x: 0, y: 0 },
    tooltipParams,
    isEmpty = false,
    colWidth,
    timelineWidth,
    viewport,
    isVarietyView = false
  }) {
    super({
      position,
      screenTransform: true,
      cursorType: 'pointer'
    });
    this.viewport = viewport;
    this.measureDirty = true;
    this.baseWidth = baseWidth;
    this.timelineWidth = timelineWidth;
    this.colWidth = colWidth;
    this.varietyTitleSource = varietyTitle;
    this.typeTitle = typeTitle;
    this.typeValue = typeValue;
    this.typeLabelTextPadding = 2;
    this.bodyTextPadding = 8;
    this.delimiterPadding = 8;
    this.isEmpty = isEmpty;
    this.delimiterWidth = 1;
    this.expandIconPadding = 8;
    this.expandIconWidth = 6;
    this.height = 20;
    this.textBaselineFix = 1;
    this.varietyTitleClosedWidth = Math.min(76, this.baseWidth);
    this.isClosed = true;
    this.expandIcon = new SvgIcon(ExpandIcon);
    this.isVarietyView = isVarietyView;
    if (tooltipParams) {
      this.toolipComponent = new DataLineTooltip(tooltipParams);
      this.addChild(this.toolipComponent);
    }

    this.toolipComponent.on('closed', () => {
      this.handleTooltipClosed();
    });

    this.setPosition(position);
  }

  draw(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    super.draw(ctx, {
      translateAdd
    });
    const position = this.getPositionAbs();
    const screenPosition = addPoints(position, translateAdd);
    if (this.measureDirty) {
      this.calcMeasurements(ctx);
      if (this.toolipComponent) {
        this.updateTooltipPosition(screenPosition);
      }
      this.measureDirty = false;
      this.setDirty();
    }
    if (!this.isDisplay()) {
      return;
    }
    if (this.isClosed) {
      this.drawClosed(ctx, {
        position: screenPosition
      });
    } else {
      this.drawOpened(ctx, {
        position: screenPosition
      });
    }
  }

  updateTooltipPosition(screenPosition) {
    const position = adjustRectHorizontal({
      y: screenPosition.y + this.height + 2,
      x: screenPosition.x + this.getXAlign(),
      ...this.toolipComponent.getSize(),
    }, this.viewport, 8);
    this.toolipComponent.setPosition({
      x: position.x - screenPosition.x,
      y: position.y - screenPosition.y,
    });
  }

  handleTooltipClosed() {
    this.ctxCache.canvas.style.cursor = 'default';
    this.isClosed = true;
    this.setZIndex(0);
    this.setDirty();
    this.emit('tooltipClosed', this.toolipComponent);
  }

  onPointerHover({ x, y }) {
    super.onPointerHover({ x, y });
    this.isClosed = false;
    this.setDirty();
    this.setZIndex(100);
  }

  onPointerOut({ x, y }) {
    super.onPointerOut({ x, y });
    if (!this.toolipComponent.isDisplay()) {
      this.isClosed = true;
      this.setZIndex(0);
    }
    this.setDirty();
  }

  onClick({
    x, y, shiftKey, ctrlKey
  }) {
    super.onClick({
      x, y, shiftKey, ctrlKey
    });
    if (!this.isEmpty) {
      this.setTooltipVisibility(true);
      this.setZIndex(100);
      this.updateBoundingBox();
      this.emit('tooltipShowed', this.toolipComponent);
      this.measureDirty = true;
      this.setDirty();
    }
    return false;
  }

  setTooltipVisibility(value) {
    this.toolipComponent.setDisplay(value);
    this.toolipComponent.z = value ? 100 : 0;
  }

  getSize() {
    return {
      width: this.isClosed ? this.getActualClosedWidth() : this.getOpenedFullWidth(),
      height: this.height,
    };
  }

  updateBoundingBox() {
    super.updateBoundingBox();
    const position = this.getPositionAbs();
    const size = this.getSize();
    this.bbox = {
      ...position,
      ...size,
      height: this.toolipComponent.isDisplay() ? (
        size.height + 4 + this.toolipComponent.getSize().height
      ) : size.height,
      width: this.toolipComponent.isDisplay() ? (
        this.toolipComponent.getSize().width
      ) : size.width,
    };
  }

  calcMeasurements(ctx) {
    this.setContextFont(ctx, 'varietyTitle');
    this.varietyTitleFullWidth = ctx.measureText(this.varietyTitleSource).width;
    this.setContextFont(ctx, 'typeTitle');
    this.typeTitleWidth = ctx.measureText(this.typeTitle).width;
    this.setContextFont(ctx, 'varietyValue');
    this.typeValueWidth = ctx.measureText(this.typeValue).width;
  }

  getTypeLabelWidth() {
    return this.typeTitleWidth +
      this.typeLabelTextPadding +
      this.typeValueWidth + (
      this.isEmpty ? this.expandIconPadding :
        this.expandIconPadding +
          this.expandIconWidth +
          this.expandIconPadding
    );
  }

  getOpenedFullWidth() {
    if (!this.isVarietyView) {
      return this.bodyTextPadding +
        this.varietyTitleFullWidth +
        this.delimiterPadding +
        this.delimiterWidth +
        this.delimiterPadding +
        this.getTypeLabelWidth();
    }
    return this.bodyTextPadding + this.getTypeLabelWidth();
  }


  getClosedFullWidth() {
    if (!this.isVarietyView) {
      return this.bodyTextPadding +
        this.varietyTitleClosedWidth +
        this.delimiterPadding +
        this.delimiterWidth +
        this.delimiterPadding +
        this.getTypeLabelWidth();
    }
    return this.bodyTextPadding + this.getTypeLabelWidth();
  }

  drawDelimiter(ctx, {
    position
  }) {
    const { x, y } = position;
    ctx.strokeStyle = '#E1E8F0';
    ctx.beginPath();
    ctx.moveTo(x, y - 4);
    ctx.lineTo(x, y + 4);
    ctx.stroke();
  }

  drawTitle = (ctx, {
    position,
    width,
    title
  }) => {
    this.setContextFont(ctx, 'varietyTitle');

    const text = textEllipsis(ctx, title, width);

    ctx.fillText(
      text,
      position.x,
      position.y
    );
  };

  drawBody(ctx, {
    position,
    width,
  }) {
    // draw main layout
    roundRect(ctx, {
      ...position,
      width,
      height: this.height,
      fill: true,
      radius: {
        tl: 4,
        bl: 4,
        tr: 4,
        br: 4,
      },
      color: '#FFF',
    });

    // draw stroke
    roundRect(ctx, {
      ...position,
      width,
      height: this.height,
      stroke: true,
      radius: {
        tl: 4,
        bl: 4,
        tr: 4,
        br: 4,
      },
      color: '#e1e8f0',
    });
  }

  drawTypeLabel(ctx, {
    position,
    title,
    value
  }) {
    this.setContextFont(ctx, 'varietyTitle');

    let { x } = position;

    ctx.fillText(
      title,
      x,
      position.y
    );

    this.setContextFont(ctx, 'varietyValue');

    x += this.typeTitleWidth + this.typeLabelTextPadding;

    ctx.fillText(
      value,
      x,
      position.y
    );

    if (!this.isEmpty) {
      x += this.typeValueWidth + this.expandIconPadding;

      this.expandIcon.draw(ctx, {
        position: {
          x, y: position.y - 3
        },
        fillColor: '#777776'
      });
    }
  }

  updateViewport(viewport) {
    this.viewport = viewport;
  }

  isClosedMinimize() {
    const width = this.getClosedFullWidth();
    return this.baseWidth <= width;
  }

  getActualClosedWidth() {
    const width = this.getClosedFullWidth();
    return this.isClosedMinimize() ? this.varietyTitleClosedWidth : width;
  }

  drawClosed(ctx, {
    position
  }) {
    const width = this.getActualClosedWidth();
    const isClosedMinimize = this.isClosedMinimize();

    this.drawBody(ctx, {
      position,
      width,
    });

    let x = position.x + this.bodyTextPadding;

    if (!this.isVarietyView) {
      this.drawTitle(ctx, {
        position: {
          x,
          y: position.y + this.height / 2 + this.textBaselineFix,
        },
        width: isClosedMinimize ?
          this.varietyTitleClosedWidth - this.bodyTextPadding - 4 : this.varietyTitleClosedWidth,
        title: this.varietyTitleSource,
      });
    }

    if (!isClosedMinimize) {
      x += this.isVarietyView ? 0 : this.varietyTitleClosedWidth + this.delimiterPadding;

      this.drawLabelWithDelimiter(ctx, {
        position: {
          x,
          y: position.y
        }
      });
    } else if (this.isVarietyView) {
      const titleEllipsisText = textEllipsis(ctx, this.typeTitle, width);
      this.setContextFont(ctx, 'varietyTitle');
      ctx.fillText(
        titleEllipsisText,
        x,
        position.y + this.height / 2 + this.textBaselineFix
      );
    }
  }

  drawOpened(ctx, {
    position,
  }) {
    const width = this.getOpenedFullWidth();

    const xAlign = this.getXAlign();

    this.drawBody(ctx, {
      position: {
        ...position,
        x: position.x + xAlign,
      },
      width,
    });

    let x = position.x + xAlign + this.bodyTextPadding;

    if (!this.isVarietyView) {
      this.drawTitle(ctx, {
        position: {
          x,
          y: position.y + this.height / 2 + this.textBaselineFix,
        },
        width,
        title: this.varietyTitleSource,
      });
    }

    x += this.isVarietyView ? 0 : this.varietyTitleFullWidth + this.delimiterPadding;

    this.drawLabelWithDelimiter(ctx, {
      position: {
        x,
        y: position.y
      }
    });
  }

  drawLabelWithDelimiter(ctx, {
    position
  }) {
    let { x } = position;

    if (!this.isVarietyView) {
      this.drawDelimiter(ctx, {
        position: {
          x,
          y: position.y + this.height / 2,
        },
      });

      x += this.delimiterWidth + this.delimiterPadding;
    }

    this.drawTypeLabel(ctx, {
      position: {
        x,
        y: position.y + this.height / 2 + this.textBaselineFix,
      },
      title: this.typeTitle,
      value: this.typeValue
    });
  }

  isRightAligned() {
    const position = this.getPositionAbs();
    const width = this.getOpenedFullWidth();
    return position.x + width >= this.viewport.x + this.viewport.width;
  }

  getXAlign() {
    const position = this.getPositionAbs();
    const width = this.getOpenedFullWidth();
    const xAlign = (position.x + width) - (this.viewport.x + this.viewport.width);
    return this.isRightAligned() ? -xAlign : 0;
  }

  setContextFont(ctx, fontType) {
    switch (fontType) {
      case 'typeTitle':
      case 'varietyTitle': {
        ctx.fillStyle = '#777776';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.font = 'normal normal 500 12px Roboto';
        break;
      }
      case 'varietyValue': {
        ctx.fillStyle = '#777776';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.font = 'normal normal 400 12px Roboto';
        break;
      }
      default: {
        ctx.fillStyle = '#777776';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.font = 'normal normal 400 12px Roboto';
        break;
      }
    }
  }
}

export default DataLineHeader;
