import numbersRounding from '../../../helpers/numbersRounding';
import numbersFormatting from '../../../helpers/numbersFormatting';
import { getRowName } from '../../../helpers/getRowName';

const getRowItem = ({
  totalKey,
  row,
  handlerRowClick,
  intl,
  compartments,
  allPlantingCycles,
  varieties,
  fruitClasses,
  currentBreakdown,
  workTypes,
}) => ({
  key: row.key,
  label: getRowName({
    ...row,
    intl,
    compartments,
    allPlantingCycles,
    varieties,
    fruitClasses,
    currentBreakdown,
    workTypes,
  }),
  columns: Object.keys(row.data).map((columnKey) => {
    if (!row.data[columnKey]) {
      return {
        label: '–'
      };
    }
    const formatedValue = numbersFormatting(numbersRounding(row.data[columnKey], 'fixed', 0));
    return {
      label: row.dataUnits === 'percent' ? `${formatedValue}%` : formatedValue,
    };
  }),

  labelAlign: 'left',
  handlerRowClick,
  rowStyle: row.key === totalKey ? 'bold' : 'normal',
  subRows: row.children ? row.children.map(subRow => getRowItem({
    totalKey,
    row: subRow,
    handlerRowClick,
    intl,
    compartments,
    allPlantingCycles,
    varieties,
    fruitClasses,
    currentBreakdown,
    workTypes,
  })) : undefined,
});

export default getRowItem;
