import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import {
  get, find, max, isNil
} from 'lodash';
import moment from 'moment-timezone';

import LINE_COLORS from 'helpers/graphColors';
import numbersFormatting from 'helpers/numbersFormatting';
import { findGetAndRoundingValue } from 'helpers/getValueDetails';
import getDateFormat from 'helpers/getDateFormat';
import {
  emptyStateIntervals
} from 'helpers/emptyStateInterval';

import Typography from 'components/Typography';
import Paper from 'components/Paper';
import LinesGraph from 'components/LinesGraph';
import LinesGraphTooltip from 'components/LinesGraphTooltip';
import DefaultToggle from 'components/DefaultToggle';
import SimpleBarChart from 'components/SimpleBarChart';
import ChartNotSelectedIcon from 'components/Icons/ChartNotSelectedIcon';
import LineChartIcon from 'components/Icons/LineChartIcon';

import styles from './EnergyGraphs.module.css';

const GRAPH_LINE_KEY = 'resourcesGraph';

class EnergyGraphs extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    totalData: PropTypes.object,
    isEnergyReportFetching: PropTypes.bool.isRequired,
    periodType: PropTypes.string.isRequired,
    graphName: PropTypes.string,
    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
  };

  static defaultProps = {
    totalData: null,
    graphName: null,
    anyDateOfPeriodStart: null,
    anyDateOfPeriodEnd: null,
  };

  state = {
    isToggleOn: false,
  }

  getValuesArray = lines => lines
    .map((line) => {
      const linePoints = get(line, 'points');

      return linePoints ? linePoints.map(item => item.y) : [];
    })
    .reduce((reducer, item) => [...reducer, ...item], []);

  getMaxValue = (lines) => {
    const valuesArray = this.getValuesArray(lines);

    return max(valuesArray) || 100;
  }

  getMinValue = (lines) => {
    const valuesArray = this.getValuesArray(lines);

    // не используем lodash min, т.к. он не учитывает нули
    return Math.min(valuesArray) || 0;
  }

  generateLineByPoints = (dataPoints) => {
    if (dataPoints) {
      return Object.keys(dataPoints).reduce((acc, key) => {
        const date = moment.utc(new Date((+key) * 60000));

        return [...acc, { x: date.format('MM-DD-YYYY'), y: dataPoints[key] }];
      }, []);
    }

    return [];
  };

  handlerOnToggleChange = () => {
    const { isToggleOn } = this.state;

    this.setState({ isToggleOn: !isToggleOn });
  };


  renderTooltipContent = (tooltipDate, lines) => {
    const {
      intl: { formatMessage }, periodType,
    } = this.props;


    const currentLine = find(lines, { key: GRAPH_LINE_KEY });

    const currentLinePoints = get(currentLine, 'points');

    const currentDate = tooltipDate ? tooltipDate.format('MM-DD-YYYY') : undefined;

    if (!currentDate) {
      return null;
    }

    const actualLineValue = findGetAndRoundingValue(currentLinePoints, currentDate, 1);
    const unitsText = formatMessage({ id: `cunits.mini.${currentLine.units}` });

    const actualLineWithUnits = isNil(actualLineValue) ? formatMessage({ id: 'crops.noData' }) : `${numbersFormatting(actualLineValue)} ${unitsText}`;

    const generatedLines = [
      {
        id: currentLine.key,
        value: actualLineWithUnits,
        header: currentLine.name,
        color: currentLine.color,
      },
    ];

    return (
      <LinesGraphTooltip
        lines={generatedLines}
        tooltipDate={tooltipDate}
        periodType={periodType}
      />
    );
  };

  render() {
    const {
      isEnergyReportFetching,
      totalData,
      graphName,
      periodType,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      intl
    } = this.props;

    const { isToggleOn } = this.state;

    const data = totalData?.data ? totalData : emptyStateIntervals({
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,
      dataUnits: get(totalData, 'dataUnits')
    });

    const dataPoints = get(data, 'data', {});
    const isEmpty = !totalData?.data || Object.keys(totalData?.data).length === 0;

    const lines = [{
      key: GRAPH_LINE_KEY,
      points: this.generateLineByPoints(dataPoints),
      name: graphName,
      color: LINE_COLORS[0],
      units: get(data, 'dataUnits'),
    }];

    const preparedPointsByDate = Object.keys(dataPoints).reduce((acc, key) => ([...acc, {
      id: moment.utc(new Date((+key) * 60000)).format('YYYY-MM-DD'),
      date: moment.utc(new Date((+key) * 60000)),
      value: dataPoints[key],

      // Данные для тултипа
      roundTo: 1,
      units: get(data, 'dataUnits'),
      color: LINE_COLORS[0],
    }]), []);

    const timeFormat = periodType === 'day' ? getDateFormat('ll') : 'MMM YYYY';

    return (
      <Paper className={styles.graphWrapper}>
        <div className={styles.graphHeaderWrapper}>
          <Typography variant='h2' className={styles.header2}>
            {graphName}
          </Typography>

          <DefaultToggle
            toggleState={isToggleOn}
            onToggleChange={this.handlerOnToggleChange}
            leftIcon={<LineChartIcon />}
            rightIcon={<ChartNotSelectedIcon />}
            leftTooltip='Line chart'
            rightTooltip='Bar chart'
          />
        </div>

        <div className={styles.chartsWrapper}>
          {isToggleOn ? (
            <SimpleBarChart
              items={preparedPointsByDate}
              fixedHeight={336}
              intl={intl}
              timeFormat={timeFormat}
              periodType={periodType}
              isFetching={isEnergyReportFetching}
              isEmpty={isEmpty}
              showEmptyBars={false}
              xTypeTime
            />
          )
            : (
              <LinesGraph
                isOverlapedEmptyState={false}
                isDataFetching={isEnergyReportFetching}
                lines={lines}
                intl={intl}
                isEmpty={isEmpty}
                maxValue={this.getMaxValue(lines)}
                minValue={this.getMinValue(lines)}
                isLegendDisabled
              // xDomainRange={[periodStartUsa, periodEndUsa]}
                renderTooltipContent={tooltipDate => this.renderTooltipContent(tooltipDate, lines)}
              />
            )}
        </div>
      </Paper>
    );
  }
}

export default injectIntl(EnergyGraphs);
