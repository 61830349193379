import React, { useMemo } from 'react';
import classnames from 'classnames';

// @ts-ignore
import { injectIntl, InjectedIntlProps } from 'react-intl';

import { ReactComponent as BlockedIcon } from './assets/blocked.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';

import styles from './index.module.css';

export type OnCloseFunc = (metricId: number, locationId: number) => void;

type MetricItemProp = {
  key: string;
  metricId: number;
  locationId: number;
  title: string | undefined;
  blocked: boolean;
  // eslint-disable-next-line react/require-default-props
  className?: string;
  // eslint-disable-next-line react/require-default-props
  closeClassName?: string;
  units?: string;
};

type MetricItemFunc = {
  onClose: OnCloseFunc
};

const MetricItem = ({
  intl,
  key,
  metricId,
  locationId,
  title,
  onClose,
  blocked = false,
  className = '',
  closeClassName = '',
  units
}: MetricItemProp & MetricItemFunc & InjectedIntlProps) => {
  const { formatMessage } = intl;

  const handlerClose = () => {
    onClose(metricId, locationId);
  };
  const isFetching = title === '';
  const isTouchDevice = 'ontouchend' in document.documentElement;

  const titleText = useMemo(() => {
    if (isFetching) {
      return (<div className={styles.loader} />);
    }
    return units ? `${title}, ${formatMessage({ id: `cunits.mini.${units}` })}` : title;
  }, [isFetching, units, title, formatMessage]);

  return (
    <div
      className={classnames(styles.dataSeries, className, {
        [styles.touch]: isTouchDevice,
      })}
      key={`data-series-item-${metricId}-${key}`}
    >
      {blocked && <BlockedIcon className={styles.dataSeriesBlockedIcon} />}
      <div
        className={classnames(styles.dataSeriesTitle, {
          [styles.blocked]: blocked,
        })}
      >
        {titleText}
      </div>
      <button
        type='button'
        className={classnames(styles.dataSeriesClose, closeClassName)}
        onClick={handlerClose}
      >
        <CloseIcon className={styles.dataSeriesCloseIcon} />
      </button>
    </div>
  );
};

export default injectIntl(MetricItem);
