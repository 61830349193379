import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import {
  indexOf, uniq, find, uniqueId
} from 'lodash';

import useMountEffect from 'hooks/useMountEffect';
import { getVarietyName } from 'helpers/getVarietyName';
import { getNameByLocal } from 'helpers/getNameByLocal';

import Typography from 'components/Typography';
import BackButton from 'components/BackButton';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import DefaultSimpleTable from 'components/DefaultSimpleTable';
import ReadMore from 'components/ReadMore';

import styles from './SettingsMeasurementsCrop.module.css';


const getCropName = (intl, varieties, isSpecies, cropType) => {
  const { formatMessage, locale } = intl;

  if (isSpecies) {
    return formatMessage({ id: `plantingCycles.allSpecies.${cropType}` });
  }

  const currentVariety = find(varieties, { id: Number(cropType) });

  return getVarietyName(currentVariety, locale);
};

const renderHeaderRows = formatMessage => (
  <tr>
    <th className={styles.th}>{formatMessage({ id: 'graphs.table.name' })}</th>
    <th className={classnames(styles.th, styles.description)}>{formatMessage({ id: 'cropModel.description' })}</th>
    <th className={styles.th}>{formatMessage({ id: 'graphs.table.unit' })}</th>
    <th className={styles.th}>{formatMessage({ id: 'settings.type' })}</th>
  </tr>
);

const renderCategoryRows = (formatMessage, metricsList) => metricsList.map(metric => (
  <tr key={uniqueId('metric-row-')}>
    <td className={classnames(styles.td, styles.tdLong)}>{metric.name}</td>
    <td className={classnames(styles.td, styles.tdMedium)}>
      <div className={styles.tdEllipsis}>
        <ReadMore
          lines={1}
          text={metric.description || ''}
        />
      </div>
    </td>
    <td className={styles.td}>{metric.unit}</td>
    <td className={classnames(styles.td, styles.tdNoWrap)}>{metric.calculated ?
      formatMessage({ id: 'settings.calculated' })
      :
      formatMessage({ id: 'settings.manualEnter' })}
    </td>
  </tr>
));


const renderBodyRows = (formatMessage, cropMetricsByCategory) => cropMetricsByCategory.map(categoryMetrics => (
  <>
    <tr key={uniqueId('metric-row-')}>
      <td className={classnames(styles.td, styles.tdLong, styles.tdHeader)} colSpan={4}>{categoryMetrics?.category?.name}</td>
    </tr>
    {renderCategoryRows(formatMessage, categoryMetrics?.metrics)}
  </>
));

const SettingsMeasurementsCrop = ({
  intl,
  intl: { formatMessage, locale },
  isSettingsFetching,
  cropType,
  organizationSlug,
  manualFormsMetrics,
  varieties,

  requestManualFormsMetrics,
}) => {
  useMountEffect(() => {
    requestManualFormsMetrics();
  });

  const locationMetrics = manualFormsMetrics?.metrics;
  const speciesList = uniq(manualFormsMetrics?.configs?.map(metric => (metric.species)));
  const isSpecies = indexOf(speciesList, cropType) !== -1;

  const currentConfig = isSpecies ?
    find(manualFormsMetrics?.configs, { species: cropType, varietyId: null })
    :
    find(manualFormsMetrics?.configs, { varietyId: Number(cropType) });

  const cropMetricsByCategory = currentConfig?.categoryMetrics.map((categoryMetricsItem) => {
    const currentCategory = find(manualFormsMetrics?.metricCategories, { id: categoryMetricsItem?.category });

    const category = {
      id: categoryMetricsItem?.category,
      name: getNameByLocal(currentCategory, locale),
    };

    const metricsList = categoryMetricsItem?.metrics.map((metric) => {
      if (!locationMetrics) {
        return [];
      }

      const currentMetric = locationMetrics[metric];

      return {
        name: getNameByLocal(currentMetric, locale),
        description: getNameByLocal(currentMetric, locale, 'description'),
        unit: currentMetric.cunit ? formatMessage({ id: `cunits.mini.${currentMetric.cunit}` }) : '',
        calculated: currentMetric.calculated,
      };
    });

    return {
      category,
      metrics: metricsList,
    };
  });

  const allCropMetrics = cropMetricsByCategory?.reduce((acc, item) => ([...acc, ...item.metrics]), []) || [];

  return (
    <div className={styles.settingsMeasurementsCrop}>
      {isSettingsFetching && <DefaultCircleLoader />}

      <BackButton link={`/${organizationSlug}/settings/crop-cycles/measurements`} text={formatMessage({ id: 'settings.measurementsInCycles' })} />
      <Typography variant='h2' className={styles.header}>
        {/* {formatMessage({ id: 'settings.measurementsInCycles' })} */}
        {getCropName(intl, varieties, isSpecies, cropType)}
      </Typography>
      <Typography variant='subtitle3' className={styles.subtitle}>
        {isSpecies ? formatMessage({ id: 'settings.metricsListByDefault' }) : formatMessage({ id: 'settings.metricsSeparateList' })}
      </Typography>

      <div className={styles.tableScroll}>
        <DefaultSimpleTable
          className={styles.table}
          headerRows={renderHeaderRows(formatMessage)}
          bodyRows={allCropMetrics.length > 0 ? renderBodyRows(formatMessage, cropMetricsByCategory) : null}
        />
      </div>
    </div>
  );
};

SettingsMeasurementsCrop.propTypes = {
  intl: intlShape.isRequired,
  isSettingsFetching: PropTypes.bool,
  cropType: PropTypes.string.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  manualFormsMetrics: PropTypes.object,
  varieties: PropTypes.array.isRequired,

  requestManualFormsMetrics: PropTypes.func.isRequired,
};

SettingsMeasurementsCrop.defaultProps = {
  manualFormsMetrics: null,
  isSettingsFetching: false,
};

export default memo((SettingsMeasurementsCrop));
