import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import { get, find } from 'lodash';
import moment from 'moment-timezone';

import { getVarietyName } from 'helpers/getVarietyName';
import { getPlantingCycleLabel } from 'helpers/getPlantingCycleLabel';
import { API_DATE_FORMAT } from 'helpers/defaultDates';

import PlanFilter from 'components/DashboardComplexFilters/components/PlanFilter';
import VarietySelect from 'components/VarietySelect';
import Select from 'components/Select';

import PlantingCyclesSelect from '../PlantingCyclesSelect';

import styles from './DashboardFilters.module.css';

export default class DashboardFilters extends Component {
  static propTypes = {
    onFiltersChange: PropTypes.func.isRequired,

    intl: intlShape.isRequired,

    varieties: PropTypes.array.isRequired,
    plantingCycles: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    // productGroups: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    isOnlyLettuce: PropTypes.bool.isRequired,
    currentGrownFilter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    currentPlantingCycle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),

    currentCompartment: PropTypes.string,
    currentUnits: PropTypes.string,
  };

  static defaultProps = {
    currentCompartment: 'all',
    currentPlantingCycle: 'all',
    currentUnits: 'absolute',
  };

  state = {
    open: false,
  };

  getValidSelectValue = (value, valuesList) => (get(valuesList.find(variety => variety.value === Number(value)), 'value') || 'all');

  getCompartmentsList = (compartments, formatMessage) => compartments.reduce((acc, compartment) => (
    [...acc, { label: get(compartment, 'attributes.name'), value: compartment.id }]
  ), [{ label: formatMessage({ id: 'dashboards.allCompartments' }), value: 'all' }]);

  getUnitsList = (units, currentGrownFilter, formatMessage) => units.reduce((acc, unit) => {
    const vegUnit = unit === 'relative' ? 'kilogramPerSquareMeter' : 'kilogram';
    const label = currentGrownFilter === 'lettuce' ? formatMessage({ id: 'cunits.mini.count' }) : formatMessage({ id: `cunits.mini.${vegUnit}` });

    return [...acc, { label, value: unit }];
  }, []);

  getPlaceholderText = () => {
    const {
      intl, plantingCycles, currentPlantingCycle, currentGrownFilter, varieties, fruitClasses
    } = this.props;
    const { formatMessage, locale } = intl;

    const currentVariety = find(varieties, { id: currentGrownFilter });

    if (currentPlantingCycle !== 'all') {
      const plantingCycle = find(plantingCycles, { id: Number(currentPlantingCycle) });

      return getPlantingCycleLabel(plantingCycle, varieties, fruitClasses, intl);
    } if (currentVariety) {
      return getVarietyName(currentVariety, locale);
    }

    return formatMessage({ id: `plantingCycles.allSpecies.${currentGrownFilter}` });
  };

  setNewSearchPath = (newParams) => {
    const { onFiltersChange } = this.props;

    onFiltersChange(newParams);
  }

  handlerCloseSelect = () => {
    const { open } = this.state;

    if (open) {
      this.setState({ open: false });
    }
  };

  handlerSelectState = newState => this.setState({ open: newState });

  handlerVarietyOptionSelect = (species, varietyId) => {
    if (varietyId) {
      this.setNewSearchPath({ species, varietyId, plantingCycleId: undefined });
    } else {
      this.setNewSearchPath({ species, varietyId: undefined, plantingCycleId: undefined });
    }

    this.handlerCloseSelect();
  };

  handlerPlantingCyclesSelect = (plantingCycleId) => {
    const { plantingCycles } = this.props;

    let newSearchPath = { plantingCycleId: plantingCycleId === 'all' ? undefined : plantingCycleId };

    const plantingCycle = find(plantingCycles, { id: Number(plantingCycleId) });

    if (plantingCycle) {
      // При выборе конкретного planting cycle фиксируем даты его начала и конца
      const newStartDate = get(plantingCycle, 'attributes.startDate');
      const newEndDate = get(plantingCycle, 'attributes.endDate');

      // Также сбрасываем фильтр compartment на all
      const compartmentId = undefined;

      newSearchPath = {
        ...newSearchPath,
        anyDateOfPeriodStart: newStartDate,
        anyDateOfPeriodEnd: newEndDate || moment(new Date()).format(API_DATE_FORMAT),
        compartmentId,
      };
    } else {
      newSearchPath = {
        ...newSearchPath,
        anyDateOfPeriodStart: undefined,
        anyDateOfPeriodEnd: undefined,
      };
    }

    this.setNewSearchPath(newSearchPath);
    this.handlerCloseSelect();
  };

  handlerCompartmentsSelect = options => this.setNewSearchPath({ compartmentId: options.value === 'all' ? undefined : options.value });

  handlerUnitsSelect = options => this.setNewSearchPath({ units: options.value });

  renderDefaultSelectBox = select => (
    <div key={`select-${select.name}`} className={classnames(styles.selectWrapper, select.className)}>
      <Select
        classNameButton={select.classNameButton}
        classNamePopup={select.classNamePopup}
        disabled={select.disabled}
        options={select.options}
        value={select.value}
        onChange={select.handler}
        labelPath='label'
        valuePath='value'
        theme='transparent'
        size='medium'
        closeOnChange
      />
    </div>
  );

  handlerPlanChange = ({ locationPlan }) => {
    this.setNewSearchPath({ locationPlan });
  };

  render() {
    const {
      intl: { formatMessage },
      varieties,
      plantingCycles,
      compartments,
      currentPlantingCycle,
      currentCompartment,
      currentUnits,
      currentGrownFilter,
      // productGroups,
      isOnlyLettuce,
      fruitClasses,
    } = this.props;
    const { open } = this.state;

    const compartmentsList = this.getCompartmentsList(compartments, formatMessage);
    // const units = currentGrownFilter === 'lettuce' ? [currentUnits] : ['relative', 'absolute'];
    const units = currentGrownFilter === 'lettuce' ? [currentUnits] : ['absolute'];
    const unitsList = this.getUnitsList(units, currentGrownFilter, formatMessage);

    const placeholderText = this.getPlaceholderText();
    const compartmentText = get(find(compartmentsList, {
      value: currentCompartment === 'all' ? currentCompartment : Number(currentCompartment)
    }), 'label');
    const unitsText = get(find(unitsList, { value: currentUnits }), 'label');

    const compartmentsFilter = {
      name: 'compartments',
      value: this.getValidSelectValue(currentCompartment, compartmentsList),
      options: compartmentsList,
      handler: this.handlerCompartmentsSelect,
    };

    const unitsFilter = {
      name: 'units',
      value: currentUnits,
      options: unitsList,
      handler: this.handlerUnitsSelect,
      classNameButton: styles.unitsFilter,
      classNamePopup: styles.unitsFilterPopup,
    };

    return (
      <div className={styles.filtersContainer}>
        <div className={styles.desktopContainer}>
          <div className={styles.selectWrapper}>
            <Select
              classNamePopup={styles.topLevelPopup}
              classNameButton={styles.topLevelButton}
              placeholder={placeholderText}
              theme='transparent'
              size='medium'
              closeOnChange
              open={open}
              onOpenChange={this.handlerSelectState}
              textEllipsis
            >
              <div className={styles.growingFiltersContainer}>
                <div className={styles.selectWrapper}>
                  <VarietySelect
                    onSelectOption={this.handlerVarietyOptionSelect}
                    varieties={varieties}
                    selectedOption={currentGrownFilter}
                  />
                </div>
                <div className={styles.selectWrapper}>
                  <PlantingCyclesSelect
                    onSelectOption={this.handlerPlantingCyclesSelect}
                    plantingCycles={plantingCycles}
                    fruitClasses={fruitClasses}
                    varieties={varieties}
                    compartments={compartments}
                    selectedOption={currentPlantingCycle}
                  />
                </div>
              </div>
            </Select>
          </div>

          <div className={styles.filterTitle}>{formatMessage({ id: 'harvestDashboard.growingIn' })}</div>
          {this.renderDefaultSelectBox(compartmentsFilter)}
          <PlanFilter onFiltersChange={this.handlerPlanChange} />
          {!isOnlyLettuce && (
            <>
              <div className={styles.filterTitle}>{formatMessage({ id: 'harvestDashboard.countIn' })}</div>
              {this.renderDefaultSelectBox(unitsFilter)}
            </>
          )}
        </div>
        <div className={styles.mobileContainer}>
          <div className={styles.filterItem}>{placeholderText}</div>
          <div className={styles.filterItem}>{compartmentText}</div>
          <div className={styles.filterItem}>{unitsText}</div>
        </div>
      </div>
    );
  }
}
