import moment from 'moment-timezone';
import { get } from 'lodash';
import { Observable } from 'rxjs';

import initAxiosInstanse from '../../api/axios';

import {
  requestGetClimateIncidents,
  receiveGetClimateIncidents,
  requestGetClimateIncidentsDetail,
  receiveGetClimateIncidentsDetail,
  requestSetClimateIncidentsDay,
  receiveSetClimateIncidentsDay,
  requestCreateAbnormalityIncident,
  receiveCreateAbnormalityIncident,
  requestUpdateAbnormalityIncident,
  receiveUpdateAbnormalityIncident,
  requestDeleteAbnormalityIncident,
  receiveDeleteAbnormalityIncident,
  setIsAddAbnormalityIncidentsMode,
  setEditedAbnormalityIncident,
  setIsDeleteAbnormalityIncidentsMode,
  requestGetClimateIncidentsForGraphs,
  receiveGetClimateIncidentsForGraphs,
  setIsUpdateAbnormalityIncidentsMode,
  requestCheckAbnormalityIncident,
  receiveCheckAbnormalityIncident,
} from './actions';

import {
  updateAbnormalityIncidentsOnDelete,
  updateAbnormalityIncidentsOnUpdate,
} from '../graphs/actions';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

import { mappingGraphPresetMetricsToUrl } from '../../helpers/graphMetricsMapping';
import getGraphMetricGroups from '../../helpers/getGraphMetricsGroups';
import updateLocationSearch from '../../helpers/updateLocationSearch';
import getDateFormat from '../../helpers/getDateFormat';


export const getClimateIncidentsForGraphsEpic = action$ =>
  action$.ofType(requestGetClimateIncidentsForGraphs)
    .mergeMap((actionData) => {
      const {
        payload: {
          graphPeriodStart,
          graphPeriodEnd,
          compartmentDaysStart,
          compartmentDaysEnd,
          compartmentTotalsStart,
          compartmentTotalsEnd,
          compartmentId,
          organization,
          history,
        },
      } = actionData;

      const { id: organizationId } = organization;

      return Observable.from(initAxiosInstanse(get(history, 'location.search'))
        .get(`/locations/${organizationId}/kpi/climate-insights-v2`, {
          params: {
            graphPeriodStart,
            graphPeriodEnd,
            compartmentDaysStart,
            compartmentDaysEnd,
            compartmentTotalsStart,
            compartmentTotalsEnd,
            compartmentId,
          },
        }))
        .mergeMap(({ data, error }) => {
          if (error) {
            return Observable.of(
              receiveGetClimateIncidentsForGraphs({ climateIncidents: null }),
              showNotificationWithTimeout({
                id: `notifications.getClimateIncidentsError.${Date.now()}`,
                messageId: 'notifications.getClimateIncidentsError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withAction',
              }),
            );
          }

          return Observable.of(receiveGetClimateIncidentsForGraphs({ climateIncidents: data }));
        })
        .catch(() => Observable.of(
          receiveGetClimateIncidentsForGraphs({ climateIncidents: null }),
          showNotificationWithTimeout({
            id: `notifications.getClimateIncidentsError.${Date.now()}`,
            messageId: 'notifications.getClimateIncidentsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withAction',
          }),
        ));
    });

export const getClimateIncidentsEpic = action$ =>
  action$.ofType(requestGetClimateIncidents)
    .mergeMap((actionData) => {
      const {
        payload: {
          graphPeriodStart,
          graphPeriodEnd,
          compartmentDaysStart,
          compartmentDaysEnd,
          compartmentTotalsStart,
          compartmentTotalsEnd,
          compartmentId,
          organization,
          history,
        },
      } = actionData;

      const { id: organizationId } = organization;

      return Observable.from(initAxiosInstanse(get(history, 'location.search'))
        .get(`/locations/${organizationId}/kpi/climate-insights-v2`, {
          params: {
            graphPeriodStart,
            graphPeriodEnd,
            compartmentDaysStart,
            compartmentDaysEnd,
            compartmentTotalsStart,
            compartmentTotalsEnd,
            compartmentId,
          },
        }))
        .mergeMap(({ data, error }) => {
          if (error) {
            return Observable.of(
              receiveGetClimateIncidents({ climateIncidents: null }),
              showNotificationWithTimeout({
                id: `notifications.getClimateIncidentsError.${Date.now()}`,
                messageId: 'notifications.getClimateIncidentsError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withAction',
              }),
            );
          }

          return Observable.of(
            receiveGetClimateIncidents({ climateIncidents: data }),
            requestGetClimateIncidentsForGraphs({
              graphPeriodStart,
              graphPeriodEnd,
              compartmentDaysStart: graphPeriodStart,
              compartmentDaysEnd: graphPeriodEnd,
              compartmentTotalsStart,
              compartmentTotalsEnd,
              compartmentId,
              organization,
              history,
            }),
          );
        })
        .catch(() => Observable.of(
          receiveGetClimateIncidents({ climateIncidents: null }),
          showNotificationWithTimeout({
            id: `notifications.getClimateIncidentsError.${Date.now()}`,
            messageId: 'notifications.getClimateIncidentsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withAction',
          }),
        ));
    });

export const getClimateIncidentsDetailEpic = action$ =>
  action$.ofType(requestGetClimateIncidentsDetail)
    .mergeMap((actionData) => {
      const {
        payload: {
          graphPeriodStart,
          graphPeriodEnd,
          compartmentDaysStart,
          compartmentDaysEnd,
          compartmentTotalsStart,
          compartmentTotalsEnd,
          compartmentId,
          organization,
          history,
        },
      } = actionData;

      const { id: organizationId } = organization;

      return Observable.from(initAxiosInstanse(get(history, 'location.search'))
        .get(`/locations/${organizationId}/kpi/climate-insights-v2`, {
          params: {
            graphPeriodStart,
            graphPeriodEnd,
            compartmentDaysStart,
            compartmentDaysEnd,
            compartmentTotalsStart,
            compartmentTotalsEnd,
            compartmentId,
          },
        }))
        .mergeMap(({ data, error }) => {
          if (error) {
            return Observable.of(
              receiveGetClimateIncidentsDetail({
                climateIncidents: null,
                compartmentId,
                periodStart: compartmentDaysStart,
                periodEnd: compartmentDaysEnd,
              }),
              showNotificationWithTimeout({
                id: `notifications.getClimateIncidentsDetailError.${Date.now()}`,
                messageId: 'notifications.getClimateIncidentsDetailError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withAction',
              }),
            );
          }

          return Observable.of(receiveGetClimateIncidentsDetail({
            climateIncidents: data,
            compartmentId,
            periodStart: compartmentDaysStart,
            periodEnd: compartmentDaysEnd,
          }));
        })
        .catch(() => Observable.of(
          receiveGetClimateIncidentsDetail({
            climateIncidents: null,
            compartmentId,
            periodStart: compartmentDaysStart,
            periodEnd: compartmentDaysEnd,
          }),
          showNotificationWithTimeout({
            id: `notifications.getClimateIncidentsDetailError.${Date.now()}`,
            messageId: 'notifications.getClimateIncidentsDetailError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withAction',
          }),
        ));
    });

export const setClimateIncidentsDayEpic = action$ =>
  action$.ofType(requestSetClimateIncidentsDay)
    .mergeMap((actionData) => {
      const {
        payload: {
          history,
          day,
          compartmentId,
          status,
          organization,
          periodStart,
          periodEnd,
        },
      } = actionData;

      const { id: organizationId, attributes: { timezone } } = organization;

      const lastTwoWeeksStart = moment.tz(timezone).add(-1, 'week').startOf('week').format('YYYY-MM-DD');
      const lastTwoWeeksEnd = moment.tz(timezone).endOf('week').format('YYYY-MM-DD');

      return Observable.from(initAxiosInstanse(get(history, 'location.search'))
        .post(
          `/locations/${organizationId}/incident/day`,
          {
            day,
            compartmentId,
            status,
          },
        ))
        .mergeMap(({ data, error }) => {
          if (error) {
            return Observable.of(
              receiveSetClimateIncidentsDay(),
              showNotificationWithTimeout({
                id: `notifications.setClimateIncidentsDayError.${Date.now()}`,
                messageId: `notifications.setClimateIncidentsDay${status === 'good' ? 'Checked' : 'Unchecked'}Error`,
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withAction',
                messageParams: { date: moment.tz(day, 'YYYY-MM-DD', timezone).format(getDateFormat('lll')) },
              }),
            );
          }

          return Observable.of(
            receiveSetClimateIncidentsDay(data),

            requestGetClimateIncidents({
              graphPeriodStart: periodStart,
              graphPeriodEnd: periodEnd,
              compartmentDaysStart: lastTwoWeeksStart,
              compartmentDaysEnd: lastTwoWeeksEnd,
              compartmentTotalsStart: periodStart,
              compartmentTotalsEnd: periodEnd,
              compartmentId: null,
              organization,
              history,
            }),

            showNotificationWithTimeout({
              id: `notifications.setClimateIncidentsDaySuccess.${Date.now()}`,
              messageId: `notifications.setClimateIncidentsDay${status === 'good' ? 'Checked' : 'Unchecked'}Success`,
              position: 'leftDown',
              iconType: 'success',
              notificationType: 'withAction',
              messageParams: { date: moment.tz(day, 'YYYY-MM-DD', timezone).format(getDateFormat('lll')) },
            }),
          );
        })
        .catch(() => Observable.of(
          receiveSetClimateIncidentsDay(),
          showNotificationWithTimeout({
            id: `notifications.setClimateIncidentsDay${status === 'good' ? 'Checked' : 'Unchecked'}Error.${Date.now()}`,
            messageId: `notifications.setClimateIncidentsDay${status === 'good' ? 'Checked' : 'Unchecked'}Error`,
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withAction',
            messageParams: { date: moment.tz(day, 'YYYY-MM-DD', timezone).format(getDateFormat('lll')) },
          }),
        ));
    });

export const createAbnormalityIncidentEpic = action$ =>
  action$.ofType(requestCreateAbnormalityIncident)
    .mergeMap((actionData) => {
      const {
        payload: {
          history,
          incident,
          graphPresetMetrics,
          organization,
        },
      } = actionData;

      const {
        timeRange,
      } = incident;

      const { id: organizationId } = organization;


      return Observable.from(initAxiosInstanse(get(history, 'location.search')).get(`/locations/${organizationId}/incident/new-id`))
        .mergeMap(({ data: newIdData }) => {
          const { id: incidentId } = newIdData;

          const graphPresetMetricGroups = getGraphMetricGroups(graphPresetMetrics);
          const nodeMetrics = mappingGraphPresetMetricsToUrl({ graphPresetMetricGroups, graphPresetMetrics });

          // eslint-disable-next-line no-underscore-dangle
          window.open(`https://admin${window.__DEV__ ? '-dev' : ''}.pylot.app/incident/${incidentId}?start=${+moment.tz(timeRange.start, 'YYYY-MM-DDTHH:mm:ss', 'UTC')}&end=${+moment.tz(timeRange.end, 'YYYY-MM-DDTHH:mm:ss', 'UTC')}${nodeMetrics.map(nm => `&nm=${nm}`)}`, `Pylot ${window.__DEV__ ? 'Dev' : ''} Admin`);

          return Observable.of(
            // setIsAddAbnormalityIncidentsMode({ isAddAbnormalityIncidentsMode: false }),
            setEditedAbnormalityIncident({ editedAbnormalityIncident: { ...incident, id: incidentId } }),
            receiveCreateAbnormalityIncident(),
            // updateAbnormalityIncidentsOnCreate(data),
            // updateAbnormalityIncidents(true),
            // showNotificationWithTimeout({
            //   id: `notifications.receiveCreateAbnormalityIncidentSuccess.${Date.now()}`,
            //   messageId: 'notifications.receiveCreateAbnormalityIncidentSuccess',
            //   position: 'leftDown',
            //   iconType: 'success',
            //   notificationType: 'withAction',
            // }),
          );
        });
    });

export const checkAbnormalityIncidentEpic = (action$, store) =>
  action$.ofType(requestCheckAbnormalityIncident)
    .mergeMap(() => {
      const { company, climateInsights } = store.getState();
      const { location: organization } = company;
      const { editedAbnormalityIncident: incident } = climateInsights;

      const { id: organizationId } = organization;
      const { id: incidentId } = incident;


      return Observable.from(initAxiosInstanse().get(`/locations/${organizationId}/incident/find/${incidentId}`))
        .mergeMap(({ data }) => {
          const { entity } = data;

          return Observable.of(receiveCheckAbnormalityIncident({ status: entity ? 'success' : 'warning', incident: entity || incident }));
        });
    });

export const updateAbnormalityIncidentEpic = action$ =>
  action$.ofType(requestUpdateAbnormalityIncident)
    .mergeMap((actionData) => {
      const {
        payload: {
          history,
          incident,
          metrics,
          organization,
        },
      } = actionData;

      const {
        timeRange,
        internalComment,
        category,
        id: incidentId,
      } = incident;

      const { id: organizationId } = organization;


      return Observable.from(initAxiosInstanse(get(history, 'location.search'))
        .post(
          `/locations/${organizationId}/incident/${incidentId}`,
          {
            metrics,
            timeRange,
            internalComment,
            category,
          },
        ))
        .mergeMap(({ data, error }) => {
          if (error) {
            return Observable.of(
              receiveUpdateAbnormalityIncident(),
              showNotificationWithTimeout({
                id: `notifications.receiveUpdateAbnormalityIncidentError.${Date.now()}`,
                messageId: 'notifications.receiveUpdateAbnormalityIncidentError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withAction',
              }),
            );
          }

          updateLocationSearch({
            activeIncidentId: undefined,
            showFirstIncident: undefined,
          });

          return Observable.of(
            setIsAddAbnormalityIncidentsMode({ isAddAbnormalityIncidentsMode: false }),
            setIsUpdateAbnormalityIncidentsMode({ isUpdateAbnormalityIncidentsMode: false }),
            setEditedAbnormalityIncident({ abnormalityIncident: null }),
            receiveUpdateAbnormalityIncident(data),
            updateAbnormalityIncidentsOnUpdate(data),
            showNotificationWithTimeout({
              id: `notifications.receiveUpdateAbnormalityIncidentSuccess.${Date.now()}`,
              messageId: 'notifications.receiveUpdateAbnormalityIncidentSuccess',
              position: 'leftDown',
              iconType: 'success',
              notificationType: 'withAction',
            }),
          );
        })
        .catch(() => Observable.of(
          receiveUpdateAbnormalityIncident(),
          showNotificationWithTimeout({
            id: `notifications.receiveUpdateAbnormalityIncidentError.${Date.now()}`,
            messageId: 'notifications.receiveUpdateAbnormalityIncidentError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withAction',
          }),
        ));
    });

export const deleteAbnormalityIncidentEpic = action$ =>
  action$.ofType(requestDeleteAbnormalityIncident)
    .mergeMap((actionData) => {
      const {
        payload: {
          history,
          incident,
          organization,
        },
      } = actionData;

      const {
        id: incidentId,
      } = incident;

      const { id: organizationId } = organization;


      return Observable.from(initAxiosInstanse(get(history, 'location.search')).delete(`/locations/${organizationId}/incident/${incidentId}`))
        .mergeMap(({ data, error }) => {
          if (error) {
            return Observable.of(
              receiveDeleteAbnormalityIncident(),
              showNotificationWithTimeout({
                id: `notifications.receiveDeleteAbnormalityIncidentError.${Date.now()}`,
                messageId: 'notifications.receiveDeleteAbnormalityIncidentError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withAction',
              }),
            );
          }

          updateLocationSearch({
            activeIncidentId: undefined,
            showFirstIncident: undefined,
          });

          return Observable.of(
            setIsDeleteAbnormalityIncidentsMode({ isDeleteAbnormalityIncidentsMode: false }),
            setIsAddAbnormalityIncidentsMode({ isAddAbnormalityIncidentsMode: false }),
            setEditedAbnormalityIncident({ abnormalityIncident: null }),
            receiveDeleteAbnormalityIncident(data),
            updateAbnormalityIncidentsOnDelete(data),
            showNotificationWithTimeout({
              id: `notifications.receiveDeleteAbnormalityIncidentSuccess.${Date.now()}`,
              messageId: 'notifications.receiveDeleteAbnormalityIncidentSuccess',
              position: 'leftDown',
              iconType: 'success',
              notificationType: 'withAction',
            }),
          );
        })
        .catch(() => Observable.of(
          receiveDeleteAbnormalityIncident(),
          showNotificationWithTimeout({
            id: `notifications.receiveDeleteAbnormalityIncidentError.${Date.now()}`,
            messageId: 'notifications.receiveDeleteAbnormalityIncidentError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withAction',
          }),
        ));
    });
