import React, {
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';

import * as d3 from 'd3';

// @ts-ignore
import { injectIntl, InjectedIntlProps } from 'react-intl';

// @ts-ignore
import moment from 'moment-timezone';

import {
  Preset, GraphsPresetRange, Incident, Location, Margin
} from 'store/graphs/types';

import {
  CheckNewIncidentStatus,
  CloseIncidentEditor,
  CreateIncident,
  RequestNewIncidentId,
  SetActiveIncidentId
} from 'store/graphs/actions';

import getDatesRange from 'helpers/getDatesRange';

import ChartLine, {
  AxisScaleType, ChartLineProps, LinePoint
} from './components/ChartLine';

import useYAxis from './hooks/useYAxis';
import useHoveredLines from './hooks/useHoveredLines';
import useXAxis from './hooks/useXAxis';
import AbnormalityIncidents from '../AbnormalityIncidents';

import GraphsTooltip from '../GraphsTooltip';

import styles from './index.module.css';

export type Point = {
  x: number;
  y: number;
};

export type LineData = {
  key:string;
  data: Array<LinePoint>;
  yScale: AxisScaleType;
  selected: boolean;
  color: string;
};

export type HoverModeType = 'BOTH' | 'VERTICAL' | 'HORIZONTAL';

export type OnChangeRange = (range: GraphsPresetRange) => void;

export type BrushInfo = {
  xStart: number;
  xEnd: number;
  x: number;
  showBrush: boolean;
};

export type ChartLinesProps = {
  lines: Array<LineData>;
  margin: Margin;
  range: GraphsPresetRange;
  location: Location;
  hoverMode: HoverModeType;
  height: number | null;
  width: number | null;
  incidents: Array<Incident>;
  activeIncidentId: number | null;
  returnAbnormalityIncidents: boolean;
  activeIncidentCollapsed: boolean;
  isAddAbnormalityIncidentsMode: boolean;
  preset: Preset;
  threshold: number;
  createdIncident: Incident | null;
};

export type OnOpenShowIncidentPopupFunc = () => void;

export type ChartLinesPropsFunc = {
  onChangeRange: OnChangeRange;
  setActiveIncidentId: SetActiveIncidentId;
  openShowIncidentPopup: OnOpenShowIncidentPopupFunc;
  createIncident: CreateIncident;
  requestNewIncidentId: RequestNewIncidentId;
  closeIncidentEditor: CloseIncidentEditor;
  checkNewIncidentStatus: CheckNewIncidentStatus;
  onUpdateInnerMouseCoords: Function;
}


export type HoverState = {
  hoverX: number;
  hoverY: number;
}

type SVGMouseEventInfo = React.MouseEvent<SVGSVGElement, MouseEvent>;
type RectMouseEventInfo = React.MouseEvent<SVGRectElement, MouseEvent>;

const ChartLines = ({
  intl,
  lines,
  margin,
  range,
  location,
  height,
  width,
  hoverMode,
  incidents,
  activeIncidentId,
  setActiveIncidentId,
  returnAbnormalityIncidents,
  activeIncidentCollapsed,
  openShowIncidentPopup,
  onChangeRange,
  preset,
  threshold,
  isAddAbnormalityIncidentsMode,
  createdIncident,
  createIncident,
  requestNewIncidentId,
  closeIncidentEditor,
  checkNewIncidentStatus,
  onUpdateInnerMouseCoords
}: ChartLinesProps & InjectedIntlProps & ChartLinesPropsFunc) => {
  const { locale } = intl;

  const [mouseOver, setMouseOver] = useState(false);
  const [mouseCoord, setMouseCoord] = useState<Point>({ x: 0, y: 0 });

  const [brush, setBrushPosition] = useState<BrushInfo>({
    xStart: -100,
    xEnd: -100,
    x: -100,
    showBrush: false,
  });

  const [hover, setHover] = useState<HoverState>({
    hoverX: -100,
    hoverY: -100,
  });

  const clipRootRef = useRef<SVGGElement>(null);
  const axisXRef = useRef<SVGGElement>(null);
  const overlayRef = useRef<SVGRectElement>(null);
  const axisYRef = useRef<SVGGElement>(null);

  const [screenY, setScreenY] = useState(0);

  const [selectTimer, setSelectTimer] = useState(0);

  const handleMouseMove = useCallback((e: SVGMouseEventInfo) => {
    const rect = overlayRef?.current?.getBoundingClientRect();
    const coords = {
      x: e.clientX - (rect?.left || 0),
      y: e.clientY - (rect?.top || 0)
    };
    setMouseCoord(coords);
    setScreenY(e.clientY);
    if (onUpdateInnerMouseCoords) {
      onUpdateInnerMouseCoords(coords);
    }
  }, [onUpdateInnerMouseCoords, setMouseCoord, setScreenY]);

  const handleMouseEnter = useCallback((e: SVGMouseEventInfo) => {
    setMouseOver(true);
    const rect = overlayRef?.current?.getBoundingClientRect();
    const coords = {
      x: e.clientX - (rect?.left || 0),
      y: e.clientY - (rect?.top || 0)
    };
    setMouseCoord(coords);
    if (onUpdateInnerMouseCoords) {
      onUpdateInnerMouseCoords(coords);
    }
  }, [onUpdateInnerMouseCoords, setMouseCoord]);

  const handleMouseLeave = useCallback(() => {
    setMouseOver(false);
  }, []);

  const { xTo, xFrom } = useMemo(() =>
    getDatesRange({
      location,
      ...range,
    }), [range, location]);
/*
  // FIX
  const xScale = useXScale(
    xTo,
    xFrom,
    width,
  );
*/
  const xRangeWidth = width;
  const extentValues = [
    xFrom.valueOf(),
    xTo.valueOf()
  ];
  const xScale = d3.scaleUtc().domain(
    // @ts-ignore
    d3.extent(extentValues)
  ).range([0, xRangeWidth]);

  // @ts-ignore  // TODO: Fix d3 scale range: ScaleTime<Range, Output, Unknown = never>
  useXAxis(axisXRef, xFrom, xTo, locale, 6, xScale);

  // @ts-ignore
  useYAxis(axisYRef, width, 420, 6);

  // @ts-ignore  // TODO: Fix d3 scale range: ScaleTime<Range, Output, Unknown = never>
  const hoveredPoints = useHoveredLines(lines, xScale, mouseCoord);

  const xLinePosition = hoveredPoints.length > 0 ? Math.max(...hoveredPoints.map(point => point.xLinePosition)) : null;

  const enableVerticalHoverLine = (hoverMode === 'BOTH' || hoverMode === 'VERTICAL')
    && xLinePosition && !brush.showBrush;

  const handleClipMouseMove = (e: RectMouseEventInfo) => {
    const rect = overlayRef?.current?.getBoundingClientRect();
    if (rect && brush.showBrush /* &&
      Math.abs(brush.x - (e.clientX - rect.x)) > 100 */
    ) {
      const isReverse = (e.clientX - rect.x) < brush.x;
      setBrushPosition({
        xStart: isReverse ? (e.clientX - rect.x) : brush.xStart,
        xEnd: isReverse ? brush.xEnd : (e.clientX - rect.x),
        x: brush.x,
        showBrush: true,
      });
    }
    if (rect) {
      setHover({
        hoverY: e.clientY - rect.y,
        hoverX: e.clientX - rect.x
      });
    }
  };

  const handleClipMouseDown = (e: RectMouseEventInfo) => {
    const rect = overlayRef?.current?.getBoundingClientRect();
    if (e && e.button === 0 && rect) {
      setBrushPosition({
        xStart: e.clientX - rect.x,
        xEnd: e.clientX - rect.x,
        x: e.clientX - rect.x,
        showBrush: true,
      });
    }
    setSelectTimer(Date.now());
  };

  const handleClipMouseUp = useCallback((e: RectMouseEventInfo) => {
    const rect = overlayRef?.current?.getBoundingClientRect();
    if (rect && brush.showBrush) {
      const isReverse = (e.clientX - rect.x) < brush.x;
      const newBrushPosition = {
        xStart: isReverse ? (e.clientX - rect.x) : brush.xStart,
        xEnd: isReverse ? brush.xEnd : (e.clientX - rect.x),
        x: brush.x,
      };
      if (Date.now() - selectTimer > 200) {
        setSelectTimer(0);
        setBrushPosition({
          ...newBrushPosition,
          showBrush: false,
        });
        const xToDate = xScale.invert(newBrushPosition.xEnd);
        // @ts-ignore
        const endDate = moment.tz(xToDate, 'UTC')
          .seconds(0)
          .milliseconds(0);
        const xFromDate = xScale.invert(newBrushPosition.xStart);
        const xFromValue = moment.tz(xFromDate, 'UTC')
          .seconds(0)
          .milliseconds(0);
        if (!isAddAbnormalityIncidentsMode) {
          const xRangeLengthInMins = endDate.diff(xFromValue, 'minutes');
          if (xRangeLengthInMins >= 1) {
            onChangeRange({
              xRangeEnd: endDate.format('YYYY-MM-DDTHH:mm:ss'),
              xRange: 'custom',
              xRangeLengthInMins
            });
          }
        } else {
          createIncident({
            id: 0,
            timeRange: {
              start: xFromValue.format('YYYY-MM-DDTHH:mm:ss'),
              end: endDate.format('YYYY-MM-DDTHH:mm:ss'),
            },
            title: 'New Incident',
            severity: 'high'
          });
        }
      } else {
        setBrushPosition({
          ...newBrushPosition,
          showBrush: false,
        });
      }
    }
  }, [
    onChangeRange,
    setBrushPosition,
    brush,
    overlayRef,
    xScale,
    isAddAbnormalityIncidentsMode,
    createIncident,
    selectTimer,
    setSelectTimer
  ]);

  const dtValue = useMemo(() => {
    if (xLinePosition) {
      const dt = xScale.invert(xLinePosition);
      const momentDt = moment.tz(dt, 'UTC');
      return momentDt.format('HH:mm');
    }
    return null;
  }, [xScale, xLinePosition]);

  // @ts-ignore
  return (
    <div
      className={styles.chart}
    >
      <GraphsTooltip
        xScale={xScale}
        hoverX={hover.hoverX}
        hoverY={hover.hoverY}
        preset={preset}
        parent={styles.chart}
        screenY={screenY}
        lines={lines}
        showTooltip={mouseOver}
        location={location}
        threshold={threshold}
      />
      <svg
        className={styles.graphSvg}
        width={width + margin.left + margin.right}
        height={height + margin.top + margin.bottom}
        onMouseMove={handleMouseMove}
        onMouseOver={handleMouseEnter}
        onMouseOut={handleMouseLeave}
      >
        <g
          pointerEvents='none'
          transform={`translate(${margin.left},${margin.top})`}
        >
          <g
            className={styles.axisX}
            transform={`translate(0,${height})`}
            ref={axisXRef}
          />
          <g
            className={styles.axisYLeft}
            ref={axisYRef}
          />
          {mouseOver && enableVerticalHoverLine && (
            <g pointerEvents='none'>
              <line
                className={styles.lineY}
                width={0.5}
                y1={mouseCoord.y}
                y2={mouseCoord.y}
                x1={0}
                x2={width}
              />
              <line
                className={styles.lineY}
                width={0.5}
                y1={0}
                y2={height}
                x1={xLinePosition}
                x2={xLinePosition}
              />
              <g>
                <rect pointerEvents='none' rx='5' ry='5' x={xLinePosition - 16} y={height + 7} width='38' height='20' fill='#4A4A49' />
                <text pointerEvents='none' fontSize='11px' x={xLinePosition + 5 - 16} y={height + 21} fill='white'>{dtValue}</text>
              </g>
            </g>
          )}
          <g
            height={height}
            width={width}
            ref={clipRootRef}
            clipPath='url(#clip)'
          >
            {lines.map((line: ChartLineProps) => (
              <ChartLine
                {...line}
                xTo={xTo}
                xFrom={xFrom}
                xScale={xScale}
              />
            ))}
            {mouseOver && hoveredPoints.map(hoveredPoint => (hoveredPoint.point ? (
              <circle
                fill={`${hoveredPoint.color}`}
                r='5'
                cx={hoveredPoint.xLinePosition || 0}
                cy={hoveredPoint.yLinePosition || 0}
              />
            ) : null))}
          </g>

          <g>
            {brush.showBrush && (
              <rect
                className={styles.brush}
                x={brush.xStart}
                y={0}
                width={Math.abs(brush.xEnd - brush.xStart)}
                height={height}
              />
            )}
          </g>
          <g>
            <rect
              className={styles.chartOverlay}
              onMouseMove={handleClipMouseMove}
              onMouseDown={handleClipMouseDown}
              onMouseUp={handleClipMouseUp}
              ref={overlayRef}
              width={width}
              height={height}
            />
          </g>
        </g>
      </svg>
      {(returnAbnormalityIncidents || createdIncident) && (
        <AbnormalityIncidents
          incidents={incidents}
          activeIncidentId={activeIncidentId}
          createdIncident={createdIncident}
          setActiveIncidentId={setActiveIncidentId}
          openActiveIncidentPopup={openShowIncidentPopup}
          activeIncidentCollapsed={activeIncidentCollapsed}
          requestNewIncidentId={requestNewIncidentId}
          width={width}
          height={420}
          leftOffset={margin.left}
          topOffset={margin.top}
          // @ts-ignore
          xScale={xScale}
          timezone={location.attributes.timezone}
          closeIncidentEditor={closeIncidentEditor}
          checkNewIncidentStatus={checkNewIncidentStatus}
        />
      )}
    </div>
  );
};


export default injectIntl(ChartLines);
