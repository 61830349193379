import React, { memo } from 'react';
import PropTypes from 'prop-types';

import useMountEffect from 'hooks/useMountEffect';

import DefaultCircleLoader from 'components/DefaultCircleLoader';
import SettingsMeasurementsContainer from '../../containers/SettingsMeasurementsContainer';

import styles from './SettingsMeasurementsWrapper.module.css';

// Компонент-обёртка нужен, чтобы дефолтное количество plants уже было в initialValues на момент загрузки
const SettingsMeasurementsWrapper = ({
  initialValues,
  isDefaultNumberOfPlantsFetching,

  requestDefaultNumberOfPlants,
  requestManualFormsMetrics,
}) => {
  useMountEffect(() => {
    requestDefaultNumberOfPlants();
    requestManualFormsMetrics();
  });

  if (isDefaultNumberOfPlantsFetching || !initialValues?.plantsToEnter) {
    return (
      <div className={styles.settingsMeasurementsWrapper}>
        <DefaultCircleLoader />
      </div>
    );
  }

  return (
    <SettingsMeasurementsContainer />
  );
};

SettingsMeasurementsWrapper.propTypes = {
  initialValues: PropTypes.object,
  isDefaultNumberOfPlantsFetching: PropTypes.bool,

  requestDefaultNumberOfPlants: PropTypes.func.isRequired,
  requestManualFormsMetrics: PropTypes.func.isRequired,
};

SettingsMeasurementsWrapper.defaultProps = {
  initialValues: null,
  isDefaultNumberOfPlantsFetching: false.object,
};

export default memo(SettingsMeasurementsWrapper);
