import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { getOrganizationSlug, getHarvestForecastAccess } from 'store/company/selectors';
import {
  getHarvestForecastSettings,
  getIsHarvestForecastSettingsFetching,
  getSpeciesType,
} from 'store/harvestForecast/selectors';
import { requestHarvestForecastSettings } from 'store/harvestForecast/actions';

import SettingsHarvestForecastSpecies from '../components/SettingsHarvestForecastSpecies';

const mapDispatchToProps = {
  requestHarvestForecastSettings,
};

const mapStateToProps = (state, props) => ({
  ...props,

  organizationSlug: getOrganizationSlug(state, props),
  speciesType: getSpeciesType(state, props),
  harvestForecastSettings: getHarvestForecastSettings(state, props),
  isSettingsFetching: getIsHarvestForecastSettingsFetching(state, props),
  harvestForecastAccess: getHarvestForecastAccess(state),
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsHarvestForecastSpecies)));
