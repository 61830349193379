import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { DateRangePicker } from 'date-range-picker';
import cn from 'classnames';

import { getCurrentLocale } from 'helpers/datesHelper';

import BigButton from 'components/BigButton';

import styles from './DateRangePickerContent.module.css';

const DateRangePickerContent = ({
  intl: { formatMessage },
  minDate,
  maxDate,
  months,
  pickerState,
  setPickerState,
  staticRanges,
  handlerClickCancel,
  handlerClickApply,
  withMobileVersion,
}) => (
  <div className={cn(styles.contentPickerWrapper, { [styles.withMobileVersion]: withMobileVersion })}>
    <DateRangePicker
      ranges={pickerState}
      minDate={minDate}
      maxDate={maxDate}
      months={months}
      direction='vertical'
      scroll={{
        enabled: true,
        calendarHeight: 255,
      }}
      moveRangeOnFirstSelection={false}
      editableDateInputs
      onChange={item => setPickerState([item.selection])}
      navigatorRenderer={(() => {})}
      inputRanges={[]}
      staticRanges={staticRanges}
      locale={getCurrentLocale()}
      startDatePlaceholder={formatMessage({ id: 'forecast.startDate' })}
      endDatePlaceholder={formatMessage({ id: 'plantingCycles.endDate' })}
    />
    <div className={styles.actions}>
      <BigButton
        className={styles.cancelButton}
        title={formatMessage({ id: 'button.cancel' })}
        onClick={handlerClickCancel}
        theme='light'
        size='small'
      />
      <BigButton
        className={styles.okButton}
        title={formatMessage({ id: 'button.apply' })}
        onClick={handlerClickApply}
        theme='dark'
        size='small'
        disabled={!pickerState[0]?.startDate && !pickerState[0]?.endDate}
      />
    </div>
  </div>
);


DateRangePickerContent.propTypes = {
  intl: intlShape.isRequired,
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  months: PropTypes.number.isRequired,
  pickerState: PropTypes.array.isRequired,
  setPickerState: PropTypes.func.isRequired,
  staticRanges: PropTypes.array.isRequired,
  handlerClickCancel: PropTypes.func.isRequired,
  handlerClickApply: PropTypes.func.isRequired,
  withMobileVersion: PropTypes.bool.isRequired,
};

DateRangePickerContent.defaultProps = {};

export default injectIntl(DateRangePickerContent);
