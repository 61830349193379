import { isNil } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const valueValidator = ({
  formatMessage,
  value,
  maxValue,
  minValue,
  callback
}) => {
  if (isNil(value) || value === '') {
    return callback(false, formatMessage ? formatMessage({ id: 'cropModel.invalidValue' }) : '');
  }

  const numberValue = parseFloat(value);

  if (numberValue > maxValue) {
    return callback(false, `${formatMessage ? formatMessage({ id: 'cropModel.cannotExceed' }) : ''} ${maxValue}`);
  }

  if (numberValue < minValue) {
    return callback(false, `${formatMessage ? formatMessage({ id: 'cropModel.cannotLessThan' }) : ''} ${minValue}`);
  }

  return callback(true);
};
