import getRowItem from './getRowItem';

const getBodyItems = ({
  totalKey,
  rows,
  handlerRowClick,
  intl,
  compartments,
  allPlantingCycles,
  varieties,
  fruitClasses,
  currentBreakdown,
  workTypes,
}) => rows.map(row => ({
  ...getRowItem({
    totalKey,
    row,
    handlerRowClick,
    intl,
    compartments,
    allPlantingCycles,
    varieties,
    fruitClasses,
    currentBreakdown,
    workTypes,
  }),
}));

export default getBodyItems;
