import React from 'react';
import PropTypes from 'prop-types';

import { uniqueId } from 'lodash';

import styles from './AdditionalInfo.module.css';

const AdditionalInfo = ({
  items,
}) => (
  <span className={styles.additionalInfo}>
    {items.map(item => (
      <span key={uniqueId('additionalInfoItem-')} className={styles.additionalInfoItem}>{item}</span>
    ))}
  </span>
);

AdditionalInfo.propTypes = {
  items: PropTypes.array,
};

AdditionalInfo.defaultProps = {
  items: [],
};

export default AdditionalInfo;
