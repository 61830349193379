import React, { useCallback } from 'react';

// @ts-ignore
import { FormattedMessage } from 'react-intl';

import {
  ChangeScale,
  ClearRangeHistory,
  CloseIncidentEditor,
  CreateIncident,
  DeleteScale,
  PopRangeHistory,
  RequestNewIncidentId,
  SetActiveIncidentId,
  SetMetricColor,
  SetShowIncidents,
  CheckNewIncidentStatus,
} from 'store/graphs/actions';

import {
  Preset,
  GraphsPresetRange,
  Incident,
  Line,
  Location,
  PresetMetricScale,
} from 'store/graphs/types';

import { RangeHistory } from 'store/graphs/reducers';

import LinesChart, { OnScaleRangeFunc, OnWalkRangeFunc } from '../LinesChart';

import { ReactComponent as DataIcon } from './assets/data.svg';

import styles from './index.module.css';

const margin = {
  top: 10,
  right: 18,
  bottom: 30,
  left: 18,
};

type ChartContainerProp = {
  lines: Array<Line>;
  enableMove: "BOTH" | "BACK" | "FORWARD";
  isFetching: boolean;
  range: GraphsPresetRange;
  location: Location;
  scales: Array<PresetMetricScale>;
  incidents: Array<Incident>;
  activeIncidentId: number | null;
  returnAbnormalityIncidents: boolean;
  hasNonBlockedMetrics: boolean;
  activeIncidentCollapsed: boolean;
  hasMetrics: boolean;
  hasSelectedAndNonBlockedMetrics: boolean;
  rangeHistory: Array<RangeHistory>;
  preset: Preset;
  defaultMinY: number;
  defaultMaxY: number;
  threshold: number;
  createdIncident: Incident | null;
  permissions: Array<any>;
};

export type OnOpenShowIncidentPopup = () => void;

type ChartContainerFunc = {
  setMetricColor: SetMetricColor;
  changeScale: ChangeScale;
  deleteScale: DeleteScale;
  walkRange: OnWalkRangeFunc;
  scaleRange: OnScaleRangeFunc;
  popRangeHistory: PopRangeHistory;
  clearRangeHistory: ClearRangeHistory;
  setShowIncidents: SetShowIncidents;
  setActiveIncidentId: SetActiveIncidentId;
  openShowIncidentPopup: OnOpenShowIncidentPopup;
  showSidePanelAction: Function; // TODO: Types for SidePanel
  createIncident: CreateIncident;
  requestNewIncidentId: RequestNewIncidentId;
  closeIncidentEditor: CloseIncidentEditor;
  checkNewIncidentStatus: CheckNewIncidentStatus;
};

const ChartContainer = ({
  lines,
  enableMove,
  isFetching,
  range,
  location,
  scales,
  incidents,
  activeIncidentId,
  returnAbnormalityIncidents,
  setShowIncidents,
  setActiveIncidentId,
  activeIncidentCollapsed,
  openShowIncidentPopup,
  setMetricColor,
  changeScale,
  deleteScale,
  hasNonBlockedMetrics,
  hasMetrics,
  hasSelectedAndNonBlockedMetrics,
  walkRange,
  scaleRange,
  rangeHistory,
  popRangeHistory,
  clearRangeHistory,
  preset,
  defaultMinY,
  defaultMaxY,
  threshold,
  showSidePanelAction,
  createdIncident,
  createIncident,
  requestNewIncidentId,
  closeIncidentEditor,
  checkNewIncidentStatus,
  permissions,
}: ChartContainerProp & ChartContainerFunc) => {
  const handlerMetricListClick = useCallback(() => {
    showSidePanelAction({
      sidePanelType: 'graphParameters',
      sidePanelProps: {
        headerText: <FormattedMessage id='graphs.dataSeries' />,
      },
    });
  }, [showSidePanelAction]);

  if (hasMetrics) {
    if (!hasNonBlockedMetrics) {
      return (
        <div className={styles.emptyState}>
          <div className={styles.emptyStateInfo}>
            <div className={styles.emptyStateDescription}>
              <FormattedMessage id='graphs.noDataToShow' />
            </div>
          </div>
        </div>
      );
    }

    if (hasSelectedAndNonBlockedMetrics) {
      return (
        <LinesChart
          margin={margin}
          lines={lines}
          height={420}
          enableMove={enableMove}
          isFetching={isFetching}
          range={range}
          location={location}
          scales={scales}
          incidents={incidents}
          activeIncidentId={activeIncidentId}
          returnAbnormalityIncidents={returnAbnormalityIncidents}
          setShowIncidents={setShowIncidents}
          setActiveIncidentId={setActiveIncidentId}
          openShowIncidentPopup={openShowIncidentPopup}
          activeIncidentCollapsed={activeIncidentCollapsed}
          setMetricColor={setMetricColor}
          changeScale={changeScale}
          deleteScale={deleteScale}
          walkRange={walkRange}
          scaleRange={scaleRange}
          rangeHistory={rangeHistory}
          popRangeHistory={popRangeHistory}
          clearRangeHistory={clearRangeHistory}
          preset={preset}
          defaultMinY={defaultMinY}
          defaultMaxY={defaultMaxY}
          threshold={threshold}
          createdIncident={createdIncident}
          createIncident={createIncident}
          requestNewIncidentId={requestNewIncidentId}
          closeIncidentEditor={closeIncidentEditor}
          checkNewIncidentStatus={checkNewIncidentStatus}
          permissions={permissions}
        />
      );
    }

    return (
      <div className={styles.emptyState}>
        <div className={styles.emptyStateInfo}>
          <div className={styles.emptyStateDescription}>
            <FormattedMessage id='graphs.noSelectedGraphsMetricsChart' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.emptyState}>
      <div className={styles.emptyStateInfo}>
        <DataIcon />
        <div className={styles.emptyStateTitle}>
          <FormattedMessage id='graphs.emptyGraphsMetricsChartTitle' />
        </div>
        <div className={styles.emptyStateDescription}>
          <FormattedMessage id='graphs.emptyGraphsMetricsChartDescriptionFirst' />
          &nbsp;
          <button
            type='button'
            className={styles.emptyStateButton}
            onClick={handlerMetricListClick}
          >
            <FormattedMessage id='graphs.emptyGraphsMetricsChartDescriptionAction' />
          </button>
          .
        </div>
      </div>
    </div>
  );
};

export default ChartContainer;
