import { TreeNodeIdTypes } from 'store/graphs/types';

function getTreeIdByType(currentTree: string): TreeNodeIdTypes {
  if (currentTree === 'Compartment') {
    return 'g';
  }
  if (currentTree === 'PlantingCycle') {
    return 'c';
  }
  if (currentTree === 'Equipment') {
    return 'e';
  }
  // TODO: contract assert
  return 'g';
}

/*
function getTreeTypeById(type: TreeNodeIdTypes): string {
  if (type === 'g') {
    return 'Compartment';
  }
  if (type === 'c') {
    return 'PlantingCycle';
  }
  if (type === 'e') {
    return 'Equipment';
  }
  return 'Compartment';
}
*/

export default getTreeIdByType;
