import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

// import { requestBenchmarkGroups, requestBenchmarkOverview } from '../../../store/benchmark/actions';

import {
  getCyclesToCompare,
} from 'store/benchmark/selectors';

import {
  addCycleToComparison,
  removeCycleFromComparison,
} from 'store/benchmark/actions';

import BenchmarkCompareButton from '../components/BenchmarkCompareButton';

const mapDispatchToProps = {
  addCycleToComparison,
  removeCycleFromComparison,
};

const mapStateToProps = (state, props) => ({
  ...props,

  cyclesToCompare: getCyclesToCompare(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(BenchmarkCompareButton)));
