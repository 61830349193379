import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import { BasicTooltip } from '@nivo/core';

const PieTooltip = ({
  data, color, tooltipFormat, tooltip, theme
}) => (
  <BasicTooltip
    id={data.label}
    value={data.value}
    enableChip
    color={color}
    theme={theme}
    format={tooltipFormat}
    // TODO: smells
    // eslint-disable-next-line react/jsx-no-bind
    renderContent={
      typeof tooltip === 'function' ? tooltip.bind(null, { color, ...data }) : null
    }
  />
);

PieTooltip.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  color: PropTypes.string.isRequired,
  /* eslint-disable react/require-default-props */
  tooltipFormat: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  tooltip: PropTypes.func,
  /* eslint-enable react/require-default-props */
  theme: PropTypes.shape({
    tooltip: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default pure(PieTooltip);
