import classnames from 'classnames';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from './assets/close.svg';

import styles from './Dialog.module.css';

export default class Dialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    children: PropTypes.object,
    title: PropTypes.object,
    isCentered: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    title: null,
    className: null,
    wrapperClassName: null,
    isCentered: true,
  };

  handlerClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  handlerShadowClick = (e) => {
    if (e.target.classList && e.target.classList.contains(styles.dialog)) {
      this.handlerClose(true);
    }
  };

  render() {
    const {
      title, children, className, wrapperClassName, isCentered
    } = this.props;

    const handlers = {
      onClick: this.handlerShadowClick,
    };

    return (
      <div className={classnames(styles.dialog, className, { [styles.isCentered]: isCentered })} {...handlers}>
        <div className={classnames(styles.dialogWrapper, wrapperClassName)}>
          <CloseIcon className={styles.dialogClose} onClick={this.handlerClose} />
          <div className={styles.dialogHeader}>
            {title}
          </div>
          <div className={styles.dialogBody}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}
