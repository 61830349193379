import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sizeMe from 'react-sizeme';

import * as d3 from 'd3';
import classnames from 'classnames';
import { get, slice } from 'lodash';

import ArrowLeftIcon from '../../../Icons/WalkArrowLeftIcon';
import ArrowRightIcon from '../../../Icons/WalkArrowRightIcon';

import SimpleBarChart from '../SimpleBarChart';

import styles from './SimpleBarChartArrows.module.css';

const BAR_COUNT_FIRST_LIMIT_LABELS = 15;

class SimpleBarChartArrows extends Component {
  static propTypes = {
    size: PropTypes.object.isRequired,
    fixedHeight: PropTypes.number.isRequired,
    isFlexModeEnabled: PropTypes.bool,
    isForceRenderDate: PropTypes.bool,
    chartWrapperClassName: PropTypes.string,

    items: PropTypes.array,
    xTypeTime: PropTypes.bool,

    defaultBarWidth: PropTypes.number
  };

  static defaultProps = {
    items: null,
    chartWrapperClassName: null,
    isFlexModeEnabled: false,
    isForceRenderDate: false,
    xTypeTime: false,
    defaultBarWidth: null
  };

  state = {
    currentPage: 1,
    innerWidth: window.innerWidth,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handlerResize);

    setTimeout(() => {
      this.handlerResize();
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResize);
  }


  handlerResize = () => {
    this.setState({
      innerWidth: window.innerWidth,
    });
  };

  getPagesCount = (realWidth, barColumnWidth, items = []) => {
    const fittedCount = (realWidth - (realWidth % barColumnWidth)) / barColumnWidth;
    const barsCount = items ? items.length : 0;

    return Math.ceil(barsCount / fittedCount);
  };

  getIsDisabledMove = (pagesCount, direction) => {
    const { currentPage } = this.state;

    if (direction === 'forward') {
      return currentPage === 1;
    }

    return currentPage === pagesCount;
  };

  getCurrentItemsPack = (pagesCount, items) => {
    const { currentPage } = this.state;

    const barsCount = items ? items.length : 0;

    const barsOnPage = Math.ceil(barsCount / pagesCount);

    // reverse т.к. страницы считаем с конца
    return slice([...items].reverse(), (currentPage - 1) * barsOnPage, currentPage * barsOnPage).reverse();
  };

  handlerMove = (direction) => { // back/forward
    const { currentPage } = this.state;

    if (direction === 'back') {
      this.setState({ currentPage: currentPage + 1 }); // Т.к. нумерация с конца
    } else {
      this.setState({ currentPage: currentPage - 1 });
    }
  };

  render() {
    const {
      size,
      items,
      fixedHeight,
      isFlexModeEnabled,
      chartWrapperClassName,
      xTypeTime,
      defaultBarWidth,
    } = this.props;

    const { innerWidth } = this.state;
    const barWidth = defaultBarWidth || 12;
    const barWrapperPadding = 20;
    const additionalMetricsPadding = 8;

    const barsCount = items ? items.length : 0;

    // TODO: этот редьюс можно убрать, если просто прокидывать признак наличия additionalMetrics
    // TODO: вынести работу с additionalMetrics и slicedBar в универсальный код дополнительных барчартов
    const additionalMetrics = [...items].reduce((acc, item) => {
      if (item.additionalMetrics) {
        return [...acc, item.additionalMetrics];
      }

      return acc;
    }, []);

    const slicedBar = [...items].reduce((acc, item) => {
      if (item.slicedBar) {
        return [...acc, item.slicedBar];
      }

      return acc;
    }, []);

    const extraBarsLength = additionalMetrics.length > 0 ? additionalMetrics.length : slicedBar.length;

    const additionalMetricsValues = items.map(item => get(item, 'additionalMetrics.metricValue'));
    const slicedBarValues = items.map(item => get(item, 'slicedBar.totalValue'));
    const maxRightValue = Math.ceil(d3.max(additionalMetricsValues)) || Math.ceil(d3.max(slicedBarValues));

    let barColumnWidth = barWidth * 2;

    if (extraBarsLength > 0) {
      barColumnWidth = barColumnWidth + additionalMetricsPadding + barWidth;
    }

    const rotateLabels = xTypeTime ? false : items.length + extraBarsLength > BAR_COUNT_FIRST_LIMIT_LABELS;

    const margins = {
      top: 10,
      bottom: rotateLabels ? 96 : 38,
      left: 56,
      right: 36,
    };

    const barChartHeight = rotateLabels ? fixedHeight + 58 : fixedHeight;

    const realWidth = size.width - margins.left - margins.right;

    const isBarsFit = realWidth / barsCount > barColumnWidth;

    let currentItemsPack = [...items];

    const pagesCount = isBarsFit ? 0 : this.getPagesCount(realWidth, barColumnWidth, items);

    if (!isBarsFit) {
      currentItemsPack = this.getCurrentItemsPack(pagesCount, items);
    }

    const isBackDisabled = this.getIsDisabledMove(pagesCount, 'back');
    const isForwardDisabled = this.getIsDisabledMove(pagesCount, 'forward');

    const values = items.map(item => get(item, 'value'));
    const maxLeftValue = Math.ceil(d3.max(values)); // Специально берём по всем значениям, чтобы шкала не прыгала

    const isMobile = innerWidth < 720;

    return (
      <>
        {isMobile && isFlexModeEnabled ? (
          <div className={styles.actions}>
            <button
              type='button'
              className={classnames(styles.walkButtonMobile, { [styles.disabled]: isBackDisabled })}
              onClick={() => !isBackDisabled && this.handlerMove({ direction: 'back' })}
            >
              <ArrowLeftIcon className={styles.icon} />
            </button>

            <button
              type='button'
              className={classnames(styles.walkButtonMobile, { [styles.disabled]: isForwardDisabled })}
              onClick={() => !isForwardDisabled && this.handlerMove({ direction: 'forward' })}
            >
              <ArrowRightIcon className={styles.icon} />
            </button>
          </div>
        ) : null}

        <div className={classnames(styles.wrapper, chartWrapperClassName)}>
          <button
            type='button'
            className={classnames(styles.walkButton, styles.leftWalkButton, { [styles.disabled]: isBackDisabled, [styles.arrowsAvailable]: !isBarsFit })}
            onClick={() => this.handlerMove('back')}
          >
            <ArrowLeftIcon className={styles.icon} />
          </button>

          <SimpleBarChart
            {...this.props}
            items={currentItemsPack}
            margins={margins}
            defaultBarWidth={barWidth}
            barWrapperPadding={barWrapperPadding}
            additionalMetricsPadding={additionalMetricsPadding}
            maxLeftValue={maxLeftValue}
            maxRightValue={maxRightValue}
            rotateLabels={rotateLabels}
            fixedHeight={barChartHeight}
          />

          <button
            type='button'
            className={classnames(styles.walkButton, styles.rightWalkButton, { [styles.disabled]: isForwardDisabled, [styles.arrowsAvailable]: !isBarsFit })}
            onClick={() => this.handlerMove('forward')}
          >
            <ArrowRightIcon className={styles.icon} />
          </button>
        </div>
      </>
    );
  }
}

export default sizeMe()(SimpleBarChartArrows);
