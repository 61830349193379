import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import TreeItem from '../components/TreeItem';


const mapDispatchToProps = {
  // toggleSelectedGraphMetric,
  // togglePresetMetric
};

// const mapStateToProps = (state, props) => ({
//   graphPresetMetrics: graphPresetMetricsSelector(state, props),
// });
const mapStateToProps = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TreeItem));
