import update from 'immutability-helper';
import moment from 'moment-timezone';

import generateFakePhotosWeeklyData from '../../helpers/generateFakePhotosWeeklyData';

import {
  INIT_LEFT_PHOTOS,
  CLEAR_LEFT_PHOTOS,
  REQUEST_LEFT_PHOTOS,
  RECEIVE_LEFT_PHOTOS,
  INVALIDATE_LEFT_PHOTOS,

  INIT_RIGHT_PHOTOS,
  CLEAR_RIGHT_PHOTOS,
  REQUEST_RIGHT_PHOTOS,
  RECEIVE_RIGHT_PHOTOS,
  INVALIDATE_RIGHT_PHOTOS,

  SELECT_PHOTO,
  SELECT_DELETED_PHOTO,

  REQUEST_PHOTO,
  RECEIVE_PHOTO,
  INVALIDATE_PHOTO,

  REQUEST_DELETE_PHOTO,
  RECEIVE_DELETE_PHOTO,
  INVALIDATE_DELETE_PHOTO,

  REQUEST_PHOTO_CATEGORIES,
  RECEIVE_PHOTO_CATEGORIES,
  INVALIDATE_PHOTO_CATEGORIES,

  REQUEST_PHOTO_COMMENT,
  RECEIVE_PHOTO_COMMENT,
  INVALIDATE_PHOTO_COMMENT,
} from './actions';


export default function (state = {
  isLeftPhotosFetching: false,
  didLeftPhotosInvalidate: false,
  leftPhotos: null,
  leftPhotosMap: null,
  isRightPhotosFetching: false,
  didRightPhotosInvalidate: false,
  rightPhotos: null,
  isPhotoFetching: false,
  didPhotoInvalidate: false,
  selectedPhoto: null,
  deletedPhoto: null,
  isPhotoCommentFetching: false,
  didPhotoCommentInvalidate: false,
  photoComment: null,
  photoCategories: [],

  isPhotosFetching: false,
  invalidatePhotos: false,
}, action) {
  const { selectedPhoto, leftPhotos, rightPhotos } = state;
  const { leftPlantingCycle, rightPlantingCycle } = action;
  const { id: leftPlantingCycleId } = leftPlantingCycle || {};
  const { id: rightPlantingCycleId } = rightPlantingCycle || {};
  const { id: photoId } = selectedPhoto || {};
  const { id: actionPhotoId } = action.photo || {};
  const patch = {};

  const leftPhotoIndex = leftPhotos ? leftPhotos.findIndex((item) => {
    const { id: itemId } = item;

    return itemId === actionPhotoId;
  }) : -1;

  const leftPhoto = leftPhotos && leftPhotos.find((item) => {
    const { id: itemId } = item;

    return itemId === actionPhotoId;
  });

  const rightPhoto = rightPhotos && rightPhotos.find((item) => {
    const { id: itemId } = item;

    return itemId === actionPhotoId;
  });

  const leftPhotoCommentParentIndex = leftPhotos && action.photoComment ? leftPhotos.findIndex((item) => {
    const { id: itemId } = item;

    return itemId === action.photoComment.relationships.photo.data[0].id;
  }) : -1;

  const rightPhotoCommentParentIndex = rightPhotos && action.photoComment ? rightPhotos.findIndex((item) => {
    const { id: itemId } = item;

    return itemId === action.photoComment.relationships.photo.data[0].id;
  }) : -1;

  switch (action.type) {
    case INVALIDATE_PHOTO_CATEGORIES:
      return { ...state, didPhotoCategoriesInvalidate: true };
    case REQUEST_PHOTO_CATEGORIES:
      return {
        ...state,
        isPhotosFetching: true,
        isPhotoCategoriesFetching: true,
        didPhotoCategoriesInvalidate: false
      };
    case RECEIVE_PHOTO_CATEGORIES:
      return {
        ...state,
        isPhotosFetching: false,
        isPhotoCategoriesFetching: false,
        didPhotoCategoriesInvalidate: false,
        photoCategories: action.photoCategories,
        lastPhotoCategoriesUpdated: action.receivedAt
      };

    case INIT_LEFT_PHOTOS:
      // eslint-disable-next-line no-case-declarations
      const fakeWeeklyData = generateFakePhotosWeeklyData({
        location: action.location,
        varieties: action.varieties,
        photoCategories: action.photoCategories,
        plantingCycle: action.plantingCycle,
      });

      return {
        ...state,
        leftPhotos: fakeWeeklyData,
        leftPhotosMap: fakeWeeklyData
          .reduce((reducer, item) => {
            const { date, category } = item.attributes;
            const week = moment(date).isoWeek();
            const year = moment(date).year();

            return {
              ...reducer,
              [`${category}/${year}/${week}`]: item,
            };
          }, {}),
      };
    case CLEAR_LEFT_PHOTOS:
      return { ...state, leftPhotos: [], };
    case INVALIDATE_LEFT_PHOTOS:
      return { ...state, didLeftPhotosInvalidate: true };
    case REQUEST_LEFT_PHOTOS:
      return {
        ...state,
        isPhotosFetching: true,
        isLeftPhotosFetching: true,
        didLeftPhotosInvalidate: false
      };
    case RECEIVE_LEFT_PHOTOS:
      return {
        ...state,
        isPhotosFetching: false,
        isLeftPhotosFetching: false,
        didLeftPhotosInvalidate: false,
        leftPhotos: action.leftPhotos,
        leftPhotosMap: action.leftPhotos ? action.leftPhotos
          .reduce((reducer, item) => {
            const { date, category } = item.attributes;
            const week = moment(date).isoWeek();
            const year = moment(date).year();

            return {
              ...reducer,
              [`${category}/${year}/${week}`]: item,
            };
          }, {}) : {},
        lastLeftPhotosUpdated: action.receivedAt
      };

    case INIT_RIGHT_PHOTOS:
      return {
        ...state,
        rightPhotos: generateFakePhotosWeeklyData({
          location: action.location,
          varieties: action.varieties,
          photoCategories: action.photoCategories,
          plantingCycle: action.plantingCycle,
        }),
      };
    case CLEAR_RIGHT_PHOTOS:
      return { ...state, rightPhotos: [], };
    case INVALIDATE_RIGHT_PHOTOS:
      return { ...state, didRightPhotosInvalidate: true };
    case REQUEST_RIGHT_PHOTOS:
      return {
        ...state,
        isPhotosFetching: true,
        isRightPhotosFetching: true,
        didRightPhotosInvalidate: false
      };
    case RECEIVE_RIGHT_PHOTOS:
      return {
        ...state,
        isPhotosFetching: false,
        isRightPhotosFetching: false,
        didRightPhotosInvalidate: false,
        rightPhotos: action.rightPhotos,
        lastRightPhotosUpdated: action.receivedAt
      };

    case SELECT_PHOTO:
      return {
        ...state,
        selectedPhoto: action.selectedPhoto,
        selectedYear: action.selectedYear,
        selectedWeek: action.selectedWeek,
        selectedCompartment: action.selectedCompartment,
        selectedPlantingCycle: action.selectedPlantingCycle,
        selectedPhotos: action.selectedPhotos,
      };

    case SELECT_DELETED_PHOTO:
      return {
        ...state,
        deletedPhoto: action.deletedPhoto,
        deletedPlantingCycle: action.deletedPlantingCycle,
      };

    case INVALIDATE_PHOTO:
      patch.didPhotoInvalidate = true;

      return { ...state, ...patch };
    case REQUEST_PHOTO:
      patch.isPhotosFetching = true;
      patch.isPhotoFetching = true;
      patch.didPhotoInvalidate = false;

      return { ...state, ...patch };
    case RECEIVE_PHOTO:
      patch.isPhotosFetching = false;

      if (photoId === actionPhotoId) {
        patch.isPhotoFetching = false;
        patch.didPhotoInvalidate = false;
        patch.selectedPhoto = action.photo;
        patch.lastPhotoUpdated = action.receivedAt;
      }

      if (leftPhoto) {
        const { attributes: { date, category } } = action.photo;
        const week = moment(date).isoWeek();
        const year = moment(date).year();

        patch.leftPhotos = update(state.leftPhotos, {
          [leftPhotoIndex]: {
            $set: action.photo,
          }
        });
        patch.leftPhotosMap = update(state.leftPhotosMap, {
          [`${category}/${year}/${week}`]: {
            $set: action.photo,
          }
        });

        patch.isPhotoFetching = false;
        patch.didPhotoInvalidate = false;
        patch.lastPhotoUpdated = action.receivedAt;
      } else if (leftPlantingCycle && action.photo && (leftPlantingCycleId === action.photo.relationships.plantingCycle.data[0].id)) {
        const { attributes: { date, category } } = action.photo;
        const week = moment(date).isoWeek();
        const year = moment(date).year();

        patch.leftPhotos = [...state.leftPhotos, action.photo];
        patch.leftPhotosMap = update(state.leftPhotosMap, {
          [`${category}/${year}/${week}`]: {
            $set: action.photo
          },
        });
        patch.isPhotoFetching = false;
        patch.didPhotoInvalidate = false;
        patch.lastPhotoUpdated = action.receivedAt;
      }

      if (rightPhoto) {
        rightPhoto.attributes.mainUrl = action.photo.attributes.mainUrl;
        rightPhoto.attributes.thumbnailUrl = action.photo.attributes.thumbnailUrl;
        rightPhoto.attributes.originUrl = action.photo.attributes.originUrl;
        rightPhoto.relationships = action.photo.relationships;
        rightPhoto.attributes.comments = action.photo.attributes.comments;

        patch.isPhotoFetching = false;
        patch.didPhotoInvalidate = false;
        patch.lastPhotoUpdated = action.receivedAt;
      } else if (rightPlantingCycle && action.photo && (rightPlantingCycleId === action.photo.relationships.plantingCycle.data[0].id)) {
        patch.rightPhotos = [...state.rightPhotos, action.photo];
        patch.isPhotoFetching = false;
        patch.didPhotoInvalidate = false;
        patch.lastPhotoUpdated = action.receivedAt;
      }

      return { ...state, ...patch };

    case INVALIDATE_DELETE_PHOTO:
      patch.didPhotoInvalidate = true;

      return { ...state, ...patch };
    case REQUEST_DELETE_PHOTO:
      patch.isPhotosFetching = true;
      patch.isPhotoFetching = true;
      patch.didPhotoInvalidate = false;

      return { ...state, ...patch };
    case RECEIVE_DELETE_PHOTO:
      patch.isPhotosFetching = false;
      patch.deletedPhoto = null;
      patch.deletedPlantingCycle = null;

      if (photoId === actionPhotoId) {
        patch.isPhotoFetching = false;
        patch.didPhotoInvalidate = false;
        patch.selectedPhoto = {};
        patch.lastPhotoUpdated = action.receivedAt;
      }

      if (leftPhoto) {
        const leftDeletedIndex = state.leftPhotos.findIndex(item => item && item.id === leftPhoto.id);

        if (leftDeletedIndex >= 0) {
          const { attributes: { date, category } } = leftPhoto;
          const week = moment(date).isoWeek();
          const year = moment(date).year();

          patch.leftPhotos = update(state.leftPhotos, {
            $splice: [[leftDeletedIndex, 1]],
          });
          patch.leftPhotosMap = update(state.leftPhotosMap, {
            $unset: [`${category}/${year}/${week}`],
          });
          patch.isPhotoFetching = false;
          patch.didPhotoInvalidate = false;
          patch.lastPhotoUpdated = action.receivedAt;
        }
      }

      if (rightPhoto) {
        const rightDeletedIndex = state.rightPhotos.findIndex(item => item && item.id === rightPhoto.id);

        if (rightDeletedIndex >= 0) {
          patch.rightPhotos = update(state.rightPhotos, {
            $splice: [[rightDeletedIndex, 1]],
          });
          patch.isPhotoFetching = false;
          patch.didPhotoInvalidate = false;
          patch.lastPhotoUpdated = action.receivedAt;
        }
      }

      return { ...state, ...patch };

    case INVALIDATE_PHOTO_COMMENT:
      patch.didPhotoCommentInvalidate = true;

      return { ...state, ...patch };
    case REQUEST_PHOTO_COMMENT:
      patch.isPhotosFetching = true;
      patch.isPhotoCommentFetching = true;
      patch.didPhotoCommentInvalidate = false;

      return { ...state, ...patch };
    case RECEIVE_PHOTO_COMMENT:
      patch.isPhotosFetching = false;


      if (leftPhotoCommentParentIndex > -1) {
        const leftPhotoParent = state.leftPhotos[leftPhotoCommentParentIndex];
        const { attributes: { date, category } } = leftPhotoParent;
        const week = moment(date).isoWeek();
        const year = moment(date).year();

        patch.leftPhotos = update(state.leftPhotos, {
          [leftPhotoCommentParentIndex]: {
            attributes: {
              comments: {
                $push: [action.photoComment],
              }
            }
          }
        });
        patch.leftPhotosMap = update(state.leftPhotosMap, {
          [`${category}/${year}/${week}`]: {
            attributes: {
              comments: {
                $push: [action.photoComment],
              }
            }
          }
        });
        patch.selectedPhoto = update(state.selectedPhoto, {
          attributes: {
            comments: {
              $push: [action.photoComment],
            }
          }
        });

        patch.isPhotoCommentFetching = false;
        patch.didPhotoCommentInvalidate = false;
        patch.lastPhotoCommentUpdated = action.receivedAt;
      }

      if (rightPhotoCommentParentIndex > -1) {
        patch.rightPhotos = update(state.rightPhotos, {
          [rightPhotoCommentParentIndex]: {
            attributes: {
              comments: {
                $push: action.photoComment,
              }
            }
          }
        });
        patch.isPhotoCommentFetching = false;
        patch.didPhotoCommentInvalidate = false;
        patch.lastPhotoCommentUpdated = action.receivedAt;
      }

      return { ...state, ...patch };

    default:
      return state;
  }
}
