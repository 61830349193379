import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getCropReportsAccess,
  getDefaultOpenFromRoute,
} from 'store/company/selectors';
import { trackPageViewSettings } from 'store/company/actions';

import { getReportsSettings, getIsReportsSettingsFetching } from 'store/cropReports/selectors';
import { requestCropReportsSettings, } from 'store/cropReports/actions';

import SettingsReports from '../components/SettingsReports';

const mapDispatchToProps = {
  trackPageViewSettings,
  requestCropReportsSettings,
};

const mapStateToProps = (state, props) => ({
  ...props,

  cropReportsAccess: getCropReportsAccess(state, props),
  defaultOpen: getDefaultOpenFromRoute(state, props),
  reportsSettings: getReportsSettings(state, props),
  isFetching: getIsReportsSettingsFetching(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsReports));
