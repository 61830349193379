import React, {
  useRef, useCallback, useState, forwardRef, useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import Grid, {
  useSelection, useCopyPaste, useEditable, useTouch
} from '@rowsncolumns/grid';

import { isString, toNumber } from 'lodash';

import isPhoneView from 'helpers/isPhoneView';

import Tooltip from './components/Tooltip';
import ContextMenu from './components/ContextMenu';

const getTrimmedValue = (value) => {
  if (value === '') {
    return value;
  }

  const trimmedValue = isString(value) ?
    value
      .replace(/^\s+|\s+$/g, '')
      .replace(/\s/g, '')
      .replace(/,/g, '.')
    :
    value;

  return toNumber(trimmedValue);
};

const getRowHeightByDefault = (rowIndex, rowsHeadersCount, getRowHeight) => {
  if (getRowHeight) {
    return getRowHeight(rowIndex);
  }

  return (rowIndex > rowsHeadersCount - 1 ? 36 : 39);
};

const DefaultCanvasGrid = forwardRef(({
  height,
  width,
  data,
  setData,
  rowCount,
  columnCount,
  mergedCells,
  rowsHeadersCount,
  columnsHeadersCount,

  getRowHeight,
  getColumnWidth, // plantsLength, extraPlantsLength
  itemRenderer, // requestCreateNextPlant, plantingCycleId, date
  canEdit,

  onPasteGapColumn, // addPlantHeaderColumnIndex
  tooltipData,
  onScroll,
  afterOnSubmit,
  withTotalRow,
  additionalContextMenuItems,
  contextMenuOffsetTop,
  afterOnChange,
  handleKeyDown,
}, ref) => {
  const [isPhone] = useState(isPhoneView());

  const gridRef = useRef(null);
  const additionalGridRef = useRef(null);
  const [contextMenuPostion, setContextMenuPosition] = useState(null);
  const getValue = useCallback(
    ({ rowIndex, columnIndex }) => {
      const value = data[[rowIndex, columnIndex]];

      /**
       * Почему-то @rowsncolumns/grid не копирует value если оно number
       * не стал править сорцы либы, сделал костыльный быстрофикс
       */
      return value?.toString();
    },
    [data]
  );
  // const gridRef = useRef<GridRef>(null);

  const {
    selections, clearLastSelection, clearSelections, activeCell, setSelections, setActiveCell, ...selectionProps
  } = useSelection({
    gridRef,
    rowCount,
    columnCount,
    selectionTopBound: rowsHeadersCount,
    selectionLeftBound: 0,
  });

  const { copy, paste } = useCopyPaste({
    gridRef,
    selections,
    activeCell,
    getValue,
    onPaste: (rows, activeCellOnPaste) => {
      const preparedRows = rows.map(row => row.reduce((acc, cellItem, index) => {
        if (index + activeCellOnPaste.columnIndex === onPasteGapColumn) {
          return [...acc, '', cellItem];
        }
        return [...acc, cellItem];
      }, []));
      // TODO: добавить проверку на редактируемость строки
      const { rowIndex, columnIndex } = activeCellOnPaste;
      const endRowIndex = Math.max(rowIndex, rowIndex + preparedRows.length - 1);
      const endColumnIndex = Math.max(
        columnIndex,
        columnIndex + (preparedRows.length && preparedRows[0].length - 1)
      );
      const changes = {};

      /* eslint-disable no-restricted-syntax */
      for (const [i, row] of preparedRows.entries()) {
        for (const [j, cell] of row.entries()) {
          const cellText = isString(cell) ? cell : Object.keys(cell).reduce((acc, key) => {
            if (key === 'sourceCell') {
              return acc;
            }

            return acc + cell[key];
          }, '');

          const isValidValue = cellText === '' || cellText.toString() === '0' || !!toNumber(cellText.toString().replace(',', '.'));

          if (rowIndex + i < rowCount && columnIndex + j < columnCount && isValidValue && canEdit({ rowIndex: rowIndex + i, columnIndex: columnIndex + j })) {
            changes[[rowIndex + i, columnIndex + j]] = cellText;
          }
        }
      }
      /* eslint-enable no-restricted-syntax */
      setData(prev => ({ ...prev, ...changes }));

      /* Should select */
      if (rowIndex === endRowIndex && columnIndex === endColumnIndex) return;

      setSelections([
        {
          bounds: {
            top: rowIndex,
            left: columnIndex,
            bottom: endRowIndex,
            right: endColumnIndex,
          },
        },
      ]);

      if (afterOnSubmit) {
        afterOnSubmit(changes);
      }

      if (afterOnChange) {
        afterOnChange(changes);
      }
    },
    onCut: (selection) => {
      const { bounds } = selection;
      const changes = {};
      for (let i = bounds.top; i <= bounds.bottom; i++) {
        for (let j = bounds.left; j <= bounds.right; j++) {
          changes[[i, j]] = undefined;
        }
      }
      setData(prev => ({ ...prev, ...changes }));

      if (afterOnSubmit) {
        afterOnSubmit(changes);
      }

      if (afterOnChange) {
        afterOnChange(changes);
      }
    },
  });

  const {
    editorComponent, isEditInProgress, hideEditor, ...editableProps
  } = useEditable(
    {
      rowCount,
      columnCount,
      gridRef,
      getValue,
      selections,
      activeCell,
      onDelete: (activeCellEditor, selectionsEditor) => {
        let changes = {};

        if (selectionsEditor.length) {
          const newValues = selectionsEditor.reduce((acc, { bounds: sel }) => {
            for (let i = sel.top; i <= sel.bottom; i++) {
              for (let j = sel.left; j <= sel.right; j++) {
                /**
                 * на самом деле это костыль, надо смотреть на является ли ячейка canEdit
                */
                if (j > 0) {
                  acc[[i, j]] = '';
                }
              }
            }
            return acc;
          }, {});
          changes = newValues;
          setData(prev => ({ ...prev, ...changes }));
          const selectionBounds = selectionsEditor[0].bounds;

          gridRef.current.resetAfterIndices(
            {
              columnIndex: selectionBounds.left,
              rowIndex: selectionBounds.top
            },
            true
          );
        } else if (activeCellEditor) {
          setData((prev) => {
            /**
             * на самом деле это костыль, надо смотреть на является ли ячейка canEdit
            */
            if (activeCellEditor.columnIndex < 1) {
              return prev;
            }

            changes = {
              [[activeCellEditor.rowIndex, activeCellEditor.columnIndex]]: ''
            };

            return {
              ...prev,
              ...changes,
            };
          });
          gridRef.current.resetAfterIndices(activeCellEditor);
        }

        if (afterOnSubmit) {
          afterOnSubmit(changes);
        }

        if (afterOnChange) {
          afterOnChange(changes);
        }
      },
      canEdit,
      onChange: (value, { rowIndex, columnIndex }) => {
        const trimmedValue = getTrimmedValue(value);

        const changes = {
          [[rowIndex, columnIndex]]: trimmedValue
        };


        setData(prev => ({ ...prev, ...changes }));
        gridRef.current.resizeColumns([columnIndex]);

        if (afterOnChange) {
          afterOnChange(changes);
        }
      },
      onSubmit: (value, { rowIndex, columnIndex }, nextActiveCell) => {
        const trimmedValue = getTrimmedValue(value);

        const changes = {
          [[rowIndex, columnIndex]]: trimmedValue
        };

        setData(prev => ({ ...prev, ...changes }));

        gridRef.current.resizeColumns([columnIndex]);

        if (afterOnSubmit) {
          afterOnSubmit(changes);
        }

        if (afterOnChange) {
          afterOnChange(changes);
        }

        /* Select the next cell */
        if (nextActiveCell) {
          setActiveCell(nextActiveCell);
        }
      }
    }
  );

  const touchProps = useTouch({
    gridRef,
  });

  const frozenColumns = isPhone ? 0 : columnsHeadersCount;
  const frozenRows = isPhone ? 0 : rowsHeadersCount;

  // Для возможности скрыть редактирование из родительского компонента
  useImperativeHandle(ref, () => ({
    hideCellEditor() {
      hideEditor();
    },
    scrollToCoords(scrollLeft, scrollTop) {
      gridRef.current.scrollTo({ scrollLeft, scrollTop });
    }
  }));

  return (
    <div
      style={{ position: 'relative' }}
    >
      <Grid
        ref={gridRef}
        activeCell={activeCell}
        selections={selections}
        rowCount={rowCount}
        columnCount={columnCount}
        frozenRows={frozenRows}
        frozenColumns={frozenColumns}
        width={width}
        height={withTotalRow ? height - 36 : height}
        rowHeight={rowIndex => getRowHeightByDefault(rowIndex, rowsHeadersCount, getRowHeight)}
        columnWidth={getColumnWidth}
        showFillHandle={!isEditInProgress}
        itemRenderer={props => itemRenderer(props, 'regularCell')}
        {...selectionProps}
        {...editableProps}
        onKeyDown={(...args) => {
          handleKeyDown(activeCell, ...args);

          selectionProps.onKeyDown(...args);
          editableProps.onKeyDown(...args);
        }}
        onMouseDown={(...args) => {
          selectionProps.onMouseDown(...args);
          editableProps.onMouseDown(...args);
        }}
        onScroll={({ scrollLeft }) => {
          if (withTotalRow) {
            additionalGridRef.current.scrollTo({ scrollLeft });
          }

          if (onScroll) {
            onScroll();
          }

          setContextMenuPosition(null);
          hideEditor();
        }}
        onContextMenu={(e) => {
          const cellCoords = gridRef?.current?.getCellCoordsFromOffset(e.clientX, e.clientY);

          if (cellCoords) {
            const {
              rowIndex,
              columnIndex
            } = cellCoords;
            if (rowIndex > 1) {
              setContextMenuPosition({
                left: e.clientX,
                top: e.clientY - contextMenuOffsetTop,
                rowIndex,
                columnIndex
              });
              e.preventDefault();
            }
          }
        }}
        mergedCells={mergedCells}
        selectionBorderColor='rgb(21, 171 ,215)'
        selectionBackgroundColor='rgb(21, 171, 215, 0.1)'
        {...touchProps}
      />
      {withTotalRow && (
        <Grid
          ref={additionalGridRef}
          rowCount={1}
          columnCount={columnCount}
          frozenColumns={1}
          width={width}
          height={36}
          rowHeight={() => 36}
          columnWidth={getColumnWidth}
          // itemRenderer={props => <CellTotal {...props} grid={grid} />}
          showScrollbar={false}
          itemRenderer={props => itemRenderer(props, 'totalCell')}
        />
      )}
      {editorComponent}
      {/* {nameEditorData?.isVisible && (
        <NameEditorComponent
          nameEditorData={nameEditorData}
          setNameEditorData={setNameEditorData}
          plantingCycleId={plantingCycleId}
          date={date}
          requestUpdatePlant={requestUpdatePlant}
          requestDeletePlant={requestDeletePlant}
          getCellInfo={getCellInfo}
          initialValues={{
            plantName: nameEditorData?.data?.value || ''
          }}
          validatePlantName={validatePlantName}
        />
      )} */}
      {tooltipData?.isVisible && (
        <Tooltip
          text={tooltipData.data}
          style={{
            top: tooltipData.top,
            left: tooltipData.left,
          }}
          leftPosition={tooltipData.position === 'left'}
        />
      )}
      {contextMenuPostion && <ContextMenu {...contextMenuPostion} copy={copy} paste={paste} additionalItems={additionalContextMenuItems} handleClickOutside={() => setContextMenuPosition(null)} />}
    </div>
  );
});

DefaultCanvasGrid.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  columnCount: PropTypes.number.isRequired,
  getCellInfo: PropTypes.func.isRequired,
  rowsHeadersCount: PropTypes.number.isRequired,
  columnsHeadersCount: PropTypes.number.isRequired,
  mergedCells: PropTypes.object,
  withTotalRow: PropTypes.bool,
  additionalContextMenuItems: PropTypes.array,

  onPasteGapColumn: PropTypes.number,
  contextMenuOffsetTop: PropTypes.number,

  getColumnWidth: PropTypes.func.isRequired,
  getRowHeight: PropTypes.func,
  itemRenderer: PropTypes.func.isRequired,
  canEdit: PropTypes.func,

  tooltipData: PropTypes.object,
  onScroll: PropTypes.func,
  afterOnSubmit: PropTypes.func,
  afterOnChange: PropTypes.func,
  handleKeyDown: PropTypes.func,
};

DefaultCanvasGrid.defaultProps = {
  mergedCells: [],
  canEdit: () => true,
  onPasteGapColumn: null,
  tooltipData: null,
  onScroll: null,
  afterOnSubmit: null,
  afterOnChange: null,
  withTotalRow: false,
  additionalContextMenuItems: [],
  contextMenuOffsetTop: 0,
  getRowHeight: null,
  handleKeyDown: () => {},
};

export default DefaultCanvasGrid;
