import { useEffect } from 'react';

import { select } from 'd3';

import { isFinite } from 'lodash';

function useHoverLineXIndicator(groupRef, options) {
  const {
    chartParams: { y },
    position,
    hidden
  } = options;
  useEffect(() => {
    const group = groupRef.current
      ? select(groupRef.current)
      : null;
    const yValue = y.invert(position);
    if (group) {
      group.selectAll('rect').remove();
      group.selectAll('text').remove();
      if (isFinite(position) && !hidden) {
        const rect = group
          .append('rect')
          .attr('rx', 5)
          .attr('ry', 5)
          .attr('x', -36)
          .attr('y', position - 8)
          .attr('width', 30)
          .attr('height', 20)
          .attr('fill', '#4A4A49');

        const text = group
          .append('text')
          .attr('x', -34)
          .attr('y', position + 6)
          .attr('font-size', '12px')
          .attr('fill', 'white')
          .text(yValue.toFixed(2));

        rect.node().setAttribute('width', text.node().getBoundingClientRect().width + 4);
      }
    }
  }, [
    groupRef,
    y,
    position,
    hidden
  ]);
}

export default useHoverLineXIndicator;
