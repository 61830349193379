import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';


import {
  requestLaborReport,
  requestLaborWorkTypes,
  updateLaborReportSorting,
  clearLaborReport,
} from 'store/labor/actions';

import {
  getLaborReport,
  getIsLaborReportFetching,
  getLaborWorkTypes,
  getLaborReportSorting,
} from 'store/labor/selectors';

import {
  getAllFruitClasses, getAllPlantingCycles,
  getAllProductGroups,
  getAnyDateOfPeriodEndFromRoute,
  getAnyDateOfPeriodStartFromRoute,
  getBreakdownTypeFromRoute,
  getCompartmentIdsFromRoute,
  getCurrentCompartments,
  getFruitClassFromRoute,
  getSpeciesFromRoute,
  getVarietiesInLocationWithTargetWeight,
  getVarietyIdFromRoute,
} from 'store/company/selectors';

import { getPeriodType } from 'store/plans/selectors';
import LaborDashboard from '../components/LaborDashboard';

const mapDispatchToProps = {
  requestLaborReport,
  requestLaborWorkTypes,
  updateLaborReportSorting,
  clearLaborReport,
};

const mapStateToProps = (state, props) => ({
  laborReport: getLaborReport(state, props),
  laborReportSorting: getLaborReportSorting(state, props),
  isDataFetching: getIsLaborReportFetching(state, props),
  varieties: getVarietiesInLocationWithTargetWeight(state, props),
  compartments: getCurrentCompartments(state),
  allPlantingCycles: getAllPlantingCycles(state),
  fruitClasses: getAllFruitClasses(state, props),

  workTypes: getLaborWorkTypes(state, props),
  productGroups: getAllProductGroups(state, props),
  anyDateOfPeriodStart: getAnyDateOfPeriodStartFromRoute(state, props),
  anyDateOfPeriodEnd: getAnyDateOfPeriodEndFromRoute(state, props),
  periodType: getPeriodType(state, props),
  currentSpecies: getSpeciesFromRoute(state, props),
  currentVarietyId: getVarietyIdFromRoute(state, props),
  currentFruitClassCode: getFruitClassFromRoute(state, props),
  currentBreakdown: getBreakdownTypeFromRoute(state, props),
  currentGrownFilter: getVarietyIdFromRoute(state, props) || getSpeciesFromRoute(state, props),
  currentCompartment: getCompartmentIdsFromRoute(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(LaborDashboard)));
