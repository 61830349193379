import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Redirect, NavLink } from 'react-router-dom';

import {
  get, find, flow, capitalize
} from 'lodash';

import { getDefaultDateRange } from 'helpers/defaultDates';
import updateLocationSearch from 'helpers/updateLocationSearch';
import { getPlantingCycleLabel, getCompartmentNameById } from 'helpers/getPlantingCycleLabel';

import PageViewTracker from 'components/PageViewTracker';

import Typography from '../../../Typography';
import BigButton from '../../../BigButton';
import DefaultRangePicker from '../../../DefaultRangePicker';
import EnergyGraphs from '../EnergyGraphs';
import EnergyTable from '../EnergyTable';

import ResourcesMenu from '../../../ResourcesMenu';

import styles from './Energy.module.css';

export default class Energy extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    location: PropTypes.object.isRequired,
    organizationSlug: PropTypes.string.isRequired,
    compartments: PropTypes.array.isRequired,
    allPlantingCycles: PropTypes.array.isRequired,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    resourceType: PropTypes.string.isRequired,
    energyReportSorting: PropTypes.object,
    timezone: PropTypes.string,
    energyReport: PropTypes.object,
    isEnergyReportFetching: PropTypes.bool,
    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
    periodType: PropTypes.string,
    graphKey: PropTypes.string,
    meters: PropTypes.array,
    isAddEnergyAvailable: PropTypes.bool.isRequired,
    defaultOpen: PropTypes.bool,

    requestEnergyReport: PropTypes.func.isRequired,
    requestEnergyMeters: PropTypes.func.isRequired,
    updateEnergyReportSorting: PropTypes.func.isRequired,
    clearEnergyReport: PropTypes.func.isRequired,
    trackPageViewResources: PropTypes.func.isRequired,
  };

  static defaultProps = {
    timezone: 'Europe/London',
    energyReport: null,
    energyReportSorting: null,
    isEnergyReportFetching: false,
    anyDateOfPeriodStart: getDefaultDateRange().startDate,
    anyDateOfPeriodEnd: getDefaultDateRange().endDate,
    periodType: 'week',
    graphKey: 'total',
    meters: [],
    defaultOpen: false,
  };

  componentDidMount() {
    const {
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,
      resourceType,
      graphKey,
      requestEnergyReport,
      requestEnergyMeters,
    } = this.props;

    requestEnergyMeters();

    requestEnergyReport({
      meterType: [this.getMeterType(resourceType)], // ['electricityMeter', 'gasMeter'],
      periodType,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      graphKey,
    });
  }

  componentWillUnmount() {
    const { clearEnergyReport } = this.props;

    clearEnergyReport();
  }

  getMeterType = (resourceType) => {
    switch (resourceType) {
      case 'gas':
        return 'gasMeter';
      case 'electricity':
        return 'electricityMeter';
      case 'water':
        return 'waterMeter';
      default:
        return 'unknown';
    }
  };

  // TODO: Проверить, мб объединить с хелпером helpers/getRowName
  getRowName = (rowData, onlyString = false) => {
    const {
      intl,
      intl: { formatMessage },
      compartments,
      allPlantingCycles,
      varieties,
      fruitClasses,
      meters,
      organizationSlug,
    } = this.props;

    const type = get(rowData, 'type');
    const ref = get(rowData, 'ref');
    const rowDataUnits = get(rowData, 'dataUnits');

    if (type === 'noPlantingCycles') {
      return formatMessage({ id: 'dashboards.periodsWithoutCropCycles' });
    }

    if (type === 'total' || !ref) {
      return formatMessage({ id: 'energy.total' });
    }

    if (ref.type === 'PlantingCycle') {
      const plantingCycleId = ref.id;
      const plantingCycle = find(allPlantingCycles, { id: plantingCycleId });
      const text = getPlantingCycleLabel(plantingCycle, varieties, fruitClasses, intl);

      if (onlyString) {
        return text;
      }

      return (
        <NavLink
          to={plantingCycleId ? `/${organizationSlug}/crops/${plantingCycleId}` : `/${organizationSlug}/crops`}
          className={styles.plantingCycleLink}
        >
          {text}
        </NavLink>
      );
    }

    if (ref.type === 'Compartment') {
      return getCompartmentNameById({ compartmentId: ref.id, compartments });
    }

    const currentMeter = flow([
      refTo => get(refTo, 'id'),
      meterId => find(meters, { id: meterId }),
    ])(ref);

    if (currentMeter) {
      const meterName = get(currentMeter, 'name');
      const meterUnits = formatMessage({ id: `cunits.mini.${rowDataUnits}` });
      const isReverse = get(currentMeter, 'reverse');
      const reversibleLocalizeText = formatMessage({ id: 'energy.reversible' });
      return isReverse ? `${meterName} (${reversibleLocalizeText}), ${meterUnits}` : `${meterName}, ${meterUnits}`;
    }

    return 'unknown';
  };

  getEnergyReport = (newParams) => {
    const {
      anyDateOfPeriodStart, anyDateOfPeriodEnd, periodType, requestEnergyReport, resourceType,
    } = this.props;

    requestEnergyReport({
      meterType: [this.getMeterType(resourceType)],
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,

      ...newParams,
    });
  };

  handlerDatesChange = (newParams) => {
    updateLocationSearch(newParams);

    this.getEnergyReport(newParams);
  };

  handlerPageView = () => {
    const { trackPageViewResources, defaultOpen, resourceType } = this.props;

    trackPageViewResources({ sectionName: capitalize(resourceType), defaultOpen });
  };

  render() {
    const {
      intl,
      intl: { formatMessage },
      location: { pathname },
      organizationSlug,

      resourceType,
      timezone,
      energyReport,
      isEnergyReportFetching,

      periodType,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      graphKey,
      isAddEnergyAvailable,

      energyReportSorting,
      updateEnergyReportSorting,
    } = this.props;

    const meterType = this.getMeterType(resourceType);

    if (meterType === 'unknown') {
      return <Redirect to={`/${organizationSlug}/404`} />;
    }

    const reportRows = get(energyReport, `${meterType}.rows`, []);

    // Чтобы поиск по ключу был так же и в детях (нужно для отображения на графике данных, выбранных в таблице)
    // TODO: вынести в общую функцию для deepFloat
    const getFlatReportRows = rowsToFlat => rowsToFlat.reduce((acc, row) => {
      if (row.children) {
        return [...acc, row, ...getFlatReportRows(row.children)];
      }
      return [...acc, row];
    }, []);

    const flatReportRows = getFlatReportRows(reportRows);

    const totalData = find(flatReportRows, { key: graphKey });

    const graphName = this.getRowName(totalData, true);

    return (
      <ResourcesMenu handlerMenuClick={resource => this.getEnergyReport({ meterType: [resource] })}>
        {/* key нужен для ремаунта компонента, чтобы срабатывала аналитика при изменении таба */}
        <PageViewTracker onMount={this.handlerPageView} key={`resourceType-${resourceType}`} />
        <div className={styles.energyReportContainer}>
          <Typography variant='h1' className={styles.header}>
            {formatMessage({ id: `energy.${resourceType}` })}
          </Typography>

          <div className={styles.filters}>
            <DefaultRangePicker
              intl={intl}
              timezone={timezone}
              handlerAfterRangeSelect={this.handlerDatesChange}
              periodType={periodType}
              anyDateOfPeriodStart={anyDateOfPeriodStart}
              anyDateOfPeriodEnd={anyDateOfPeriodEnd}
            />

            {isAddEnergyAvailable && (
              <BigButton
                className={styles.button}
                title={formatMessage({ id: 'energy.addData' })}
                href={`${pathname}/add`}
                theme='light'
              />
            )}
          </div>

          <div className={styles.content}>
            <EnergyGraphs
              totalData={totalData}
              isEnergyReportFetching={isEnergyReportFetching}
              periodType={periodType}
              graphName={graphName}
              anyDateOfPeriodStart={anyDateOfPeriodStart}
              anyDateOfPeriodEnd={anyDateOfPeriodEnd}
            />

            <EnergyTable
              energyReportSorting={energyReportSorting}
              periodType={periodType}
              graphKey={graphKey}
              reportRows={reportRows}
              getRowName={this.getRowName}
              updateEnergyReportSorting={updateEnergyReportSorting}
              anyDateOfPeriodStart={anyDateOfPeriodStart}
              anyDateOfPeriodEnd={anyDateOfPeriodEnd}
            />
          </div>
        </div>
      </ResourcesMenu>
    );
  }
}
