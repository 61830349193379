import React from 'react';

import SimpleBarChartArrows from '../SimpleBarChartArrows';

import styles from './SimpleBarChartWrapper.module.css';

const SimpleBarChartWrapper = props => (
  <div className={styles.chartWrapper}>
    <SimpleBarChartArrows {...props} />
  </div>
);

export default SimpleBarChartWrapper;
