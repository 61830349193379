import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import {
  get, head, drop, isEmpty, find, isNil, isString, isNaN, flattenDeep, filter, omit
} from 'lodash';

import updateLocationSearch from 'helpers/updateLocationSearch';
import storageWrapper from 'helpers/storageWrapper';
import { getMlNameDirect } from 'helpers/getVarietyName';

import Typography from 'components/Typography';
import BigButton from 'components/BigButton';
import BackButton from 'components/BackButton';
import DefaultRangePicker from 'components/DefaultRangePicker';
import SaveChangesDialog from 'components/SaveChangesDialog';
import SelectBoxFilter from 'components/SelectBoxFilter';
import SelectboxFilterWithChecks from 'components/SelectboxFilterWithChecks';
import LaborWorksAddTable from '../LaborWorksAddTable';

import styles from './LaborWorksAdd.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');

const FIRST_COL_WIDTHS = 206;
const COL_WIDTHS = 100;
const SINGLE_COL_WIDTHS = 160;
const SIDE_PADDING = 24;
const DEFAULT_MIN_WIDTH = 768;

export default class LaborWorksAdd extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    history: PropTypes.object.isRequired,

    organizationSlug: PropTypes.string.isRequired,
    timezone: PropTypes.string,
    isLaborRegistrationsFetching: PropTypes.bool,
    isSaveLaborRegistrationsFetching: PropTypes.bool,
    filteredLaborRegistrations: PropTypes.object,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
    isAddLaborAvailable: PropTypes.bool.isRequired,

    allPlantingCycles: PropTypes.array.isRequired,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    currentCompartment: PropTypes.array,
    currentWorkTypeId: PropTypes.array,
    locationWorkTypes: PropTypes.object,
    currentWorkClass: PropTypes.string,
    avaliableWorkClasses: PropTypes.array,

    requestLaborRegistrations: PropTypes.func.isRequired,
    requestSaveLaborRegistrations: PropTypes.func.isRequired,
    showNotificationWithTimeout: PropTypes.func.isRequired,
    requestLaborWorkTypes: PropTypes.func.isRequired,
  };

  static defaultProps = {
    timezone: 'Europe/London',
    isLaborRegistrationsFetching: false,
    isSaveLaborRegistrationsFetching: false,
    filteredLaborRegistrations: null,
    currentCompartment: null,
    currentWorkTypeId: null,
    locationWorkTypes: null,
    avaliableWorkClasses: [],
    currentWorkClass: 'all',
  };

  constructor(props) {
    super(props);

    this.tableDataEnter = React.createRef();
  }

  state = {
    // formErrors: [],
    stateTableData: null,
    isTableValid: true,
    isSaveChangesDialogVisible: false,
    actionAfter: undefined,
  };

  componentDidMount() {
    const {
      history,
      organizationSlug,
      isAddLaborAvailable,
      startDate,
      endDate,
      requestLaborRegistrations,
      requestLaborWorkTypes,
    } = this.props;

    // Проверка прав пользователя
    if (!isAddLaborAvailable) {
      history.push(`/${organizationSlug}/resources/labor`);
    } else {
      requestLaborWorkTypes();
      requestLaborRegistrations({
        startDate,
        endDate,
      });
    }
  }

  handlerDialogBeforeChange = (changeFunction, changeOptions) => {
    const { stateTableData } = this.state;

    if (stateTableData) {
      return this.showDialog({ actionAfter: () => changeFunction(changeOptions) });
    }

    return changeFunction(changeOptions);
  };

  handlerAfterCompartmentsChange = (options) => {
    const compartmentId = this.getOptionsChanges(options);

    updateLocationSearch({ compartmentId });
  };

  handlerAfterWorkTypesChange = (options) => {
    const workTypeId = this.getOptionsChanges(options);

    updateLocationSearch({ workTypeId });
  };

  handlerAfterWorkClassChange = ({ value }) => {
    const workClassId = value !== 'all' ? value : undefined;

    updateLocationSearch({ workClassId });
  };

  handlerCompartmentsChange = options => this.handlerDialogBeforeChange(this.handlerAfterCompartmentsChange, options);

  handlerWorkTypesChange = options => this.handlerDialogBeforeChange(this.handlerAfterWorkTypesChange, options);

  handlerWorkClassChange = options => this.handlerDialogBeforeChange(this.handlerAfterWorkClassChange, options);

  getCellWidth = (availableWorkClasses) => {
    const workClassesLength = availableWorkClasses ? availableWorkClasses.length : 0;

    if (workClassesLength > 1) {
      return COL_WIDTHS;
    }

    return SINGLE_COL_WIDTHS;
  };

  getOptionsChanges = (options) => {
    const isAll = options.every(item => get(item, 'checked'));
    const isAllReset = options.every(item => !get(item, 'checked'));

    let changes;

    if (!isAll) {
      changes = options.filter(item => get(item, 'checked')).map(item => get(item, 'value'));
    }

    if (isAllReset) {
      changes = [0];
    }

    return changes;
  };

  getCompartmentsList = (compartments) => {
    const { currentCompartment } = this.props;

    return compartments.reduce((acc, compartment) => (
      [
        ...acc,
        {
          label: get(compartment, 'attributes.name'),
          value: compartment.id,
          checked: currentCompartment ? !!currentCompartment.find(id => +compartment.id === +id) : true,
        },
      ]
    ), []);
  };

  getWorkTypesList = (workTypes) => {
    const { currentWorkTypeId, intl: { locale } } = this.props;

    if (!workTypes || !workTypes.data) {
      return [];
    }

    return workTypes.data.reduce((acc, workType) => (
      [
        ...acc,
        {
          label: getMlNameDirect(workType, locale),
          value: workType.id,
          checked: currentWorkTypeId ? !!currentWorkTypeId.find(id => +workType.id === +id) : true,
        },
      ]
    ), []);
  };

  getValueId = laborWork => (`${laborWork.plantingCycleId}-${laborWork.workTypeId}-${laborWork.workClass}`);

  // Связан с getValueId
  getKeyInfo = (key) => {
    const splittedKey = key.split('-');
    const plantingCycleId = Number(splittedKey[0]);
    const workTypeId = Number(splittedKey[1]);
    const workClass = splittedKey[2];

    return {
      plantingCycleId,
      workTypeId,
      workClass,
    };
  };

  getTableData = (filteredLaborRegistrations) => {
    const { stateTableData } = this.state;

    if (stateTableData) {
      return stateTableData;
    }

    const startDatesArray = get(filteredLaborRegistrations, 'descriptor.workPeriods', []).map(period => period.startDate);
    // Добавляем строку с Total
    const datesArray = [...startDatesArray, 'total'];

    const laborWorks = get(filteredLaborRegistrations, 'works', []);

    const prefilledTable = datesArray.map((currentDate) => {
      const currentRow = filter(laborWorks, { startDate: currentDate });

      const values = currentRow.reduce((acc, laborWork) => ({
        ...acc,

        [this.getValueId(laborWork)]: laborWork.amount,
      }), {});

      return { date: currentDate, ...values };
    });

    return prefilledTable;
  };

  getTableContainerMaxWidth = (availableWorkClasses, rowsGrid = []) => {
    if (rowsGrid.length > 3) {
      return (rowsGrid.length * this.getCellWidth(availableWorkClasses)) + FIRST_COL_WIDTHS + (SIDE_PADDING * 2);
    }

    return DEFAULT_MIN_WIDTH + (SIDE_PADDING * 2);
  };

  getRowsGrid = (filteredLaborRegistrations) => {
    const compartments = get(filteredLaborRegistrations, 'descriptor.compartments', []);
    const workClasses = get(filteredLaborRegistrations, 'descriptor.workClasses', []);

    const rowsGrid = compartments.map(compartment => compartment?.plantingCycles.map((plantingCycle) => {
      const workTypeIds = plantingCycle?.workTypeIds;

      return workTypeIds.map(workTypeId => workClasses.map(workClass => ({
        workTypeId,
        plantingCycleId: plantingCycle?.id,
        compartmentId: compartment?.id,
        workClass,
      })));
    }));

    return flattenDeep(rowsGrid);
  };

  getDiffWithOriginalData = (laborWorks, converdedResults) => {
    if (!converdedResults) {
      return null;
    }

    return converdedResults.filter((resultItem) => {
      const originalItem = find(laborWorks, {
        plantingCycleId: resultItem.plantingCycleId,
        workTypeId: resultItem.workTypeId,
        workClass: resultItem.workClass,
        startDate: resultItem.startDate,
      });

      return originalItem.amount !== resultItem.amount;
    });
  };

  convertTableArray = (rowsGrid, tableData) => tableData.map((row) => {
    const date = head(row);
    const valuesList = drop(row);

    const values = valuesList.reduce((acc, listItem, index) => {
      const stringValue = !isNil(listItem) ? listItem.toString() : null;

      /**
       * Пустые строки оставляем, это значит что там было значение и мы его удалили,
       * если их не оставлять, то не получится правильно посчитать diff изменений для бэкенда
       */
      if (isEmpty(stringValue) && stringValue !== '') {
        return acc;
      }

      const trimmedValue = isString(listItem) ? listItem.replace(/\s+/g, '') : listItem;
      const numberValue = stringValue !== '' ? Number(trimmedValue) : trimmedValue;
      // Чтобы если человек ввёл текст, он отобразился с ошибкой
      const realValue = isNaN(numberValue) ? trimmedValue : numberValue;

      // Находим побочные данные этой колонки (compartment, plantingCycleId, workType, workClass)
      const columnInfo = rowsGrid[index];

      return {
        ...acc,
        [this.getValueId(columnInfo)]: realValue,
      };
    }, {});

    return {
      date,
      ...values,
    };
  });

  convertRegistrations = (laborWorks, tableData) => {
    const items = tableData.reduce((acc, rowItem) => {
      const startDate = rowItem.date;
      const values = omit(rowItem, 'date');

      const newItems = Object.keys(values).map(key => ({
        startDate,
        amount: values[key],

        ...this.getKeyInfo(key),
      }));

      // Добавляем параметр disabled, приходящий с бэка
      const withDisabledInfo = newItems.map((item) => {
        const originalItem = find(laborWorks, {
          plantingCycleId: item.plantingCycleId,
          workTypeId: item.workTypeId,
          workClass: item.workClass,
          startDate: item.startDate,
        });

        return {
          ...item,

          disabled: originalItem?.disabled,
        };
      });

      return [...acc, ...withDisabledInfo];
    }, []);

    return items;
  };

  handleAfterSuccess = () => {
    const {
      history,
      organizationSlug,
      showNotificationWithTimeout
    } = this.props;

    showNotificationWithTimeout({
      id: 'labor.saveLaborRegistrationsSuccess',
      messageId: 'labor.saveLaborRegistrationsSuccess',
      position: 'leftDown',
      iconType: 'error',
      notificationType: 'withActionWide',
    });

    history.push(`/${organizationSlug}/resources/labor/works`);
  }

  handleSave = () => {
    const {
      filteredLaborRegistrations,
      requestSaveLaborRegistrations,
    } = this.props;

    const { isTableValid, stateTableData } = this.state;

    if (!isTableValid || !stateTableData) {
      return null;
    }

    const laborWorks = get(filteredLaborRegistrations, 'works', []);
    const converdedResults = this.convertRegistrations(laborWorks, stateTableData);

    // Бэкенд принимает дифф изменений
    const diffWithOriginalData = this.getDiffWithOriginalData(laborWorks, converdedResults);

    // Заменяем пустые строки (удалённые значения) на null
    const cleanedValues = diffWithOriginalData.map(dataItem => ({
      ...dataItem,

      amount: dataItem.amount === '' ? null : dataItem.amount,
    }));

    return requestSaveLaborRegistrations({
      registrations: cleanedValues,
      actionAfterSuccess: this.handleAfterSuccess,
    });
  };

  showDialog = ({ actionAfter }) => this.setState({ isSaveChangesDialogVisible: true, actionAfter });

  handleOnRangeSelect = (newParams) => {
    const { stateTableData } = this.state;

    // Добавить проверку isEqual
    if (stateTableData) {
      return this.showDialog({ actionAfter: () => this.handleAfterRangeSelect(newParams) });
    }

    return this.handleAfterRangeSelect(newParams);
  };

  handleAfterRangeSelect = (newParams) => {
    const {
      startDate,
      endDate,
      requestLaborRegistrations,
    } = this.props;

    const newPeriodType = get(newParams, 'periodType');

    if (newPeriodType) {
      safeLocalStorage.setItem('laborAddPeriodType', newPeriodType);
    }

    updateLocationSearch(newParams);

    const dateParams = {
      startDate: get(newParams, 'anyDateOfPeriodStart', startDate),
      endDate: get(newParams, 'anyDateOfPeriodEnd', endDate)
    };

    return requestLaborRegistrations({
      ...dateParams,
    });
  };

  handleChangeTableValues = async (changesArray) => {
    const tableInstance = this.tableDataEnter.current.hotInstance;
    const oldTableData = tableInstance.getData();

    const newTableData = [...oldTableData];

    if (tableInstance) {
      /* eslint-disable no-restricted-syntax, no-unused-vars */
      for (const [row, column, oldValue, newValue] of changesArray) {
        // Т.к. у нас prop отличается от номера col (берётся по data атрибуту колонки)
        const colNum = tableInstance.propToCol(column);

        if (newTableData[row]) {
          newTableData[row][colNum] = newValue;
        }
      }
      /* eslint-enable no-restricted-syntax, no-unused-vars */

      return newTableData;
    }

    return null;
  };

  handleLaborTableChange = async (rowsGrid, changesArray) => {
    // TODO: Копипаста из аналогичной функции в Energy, надо объединить
    changesArray.forEach((change) => {
      // Вырезаем спецсимволы перед изменением ячейки (чтобы не вставлялись переносы строк и прочее подобное)
      const pastedValue = change[3];
      const trimmedValue = isString(pastedValue) ? pastedValue.replace(/^\s+|\s+$/g, '') : pastedValue;

      /**
       * Меняем сам передаваемый массив changesArray (по ссылке), а не возвращаем новый, т.к.
       * handsontable по-другому не умеет, нужно именно менять передаваемый массив изменений
       */
      // eslint-disable-next-line no-param-reassign
      change[3] = trimmedValue;
    });

    const tableData = await this.handleChangeTableValues(changesArray);

    const formattedTableData = this.convertTableArray(rowsGrid, tableData);

    const tableInstance = this.tableDataEnter.current.hotInstance;

    // Оборачиваем в setTimeout, т.к. иначе hotInstance будет валидироваться до конвертации значений string в number
    setTimeout(() => {
      tableInstance.validateCells(isValid => this.setState({
        isTableValid: isValid
      }));
    }, 0);

    this.setState({
      stateTableData: formattedTableData,
    });
  };

  render() {
    const {
      intl,
      intl: { formatMessage },

      organizationSlug,
      timezone,
      isLaborRegistrationsFetching,
      isSaveLaborRegistrationsFetching,
      filteredLaborRegistrations,

      startDate,
      endDate,
      periodType,

      allPlantingCycles,
      varieties,
      fruitClasses,
      compartments,
      locationWorkTypes,
      currentWorkClass,
      avaliableWorkClasses,
    } = this.props;

    const { isSaveChangesDialogVisible, actionAfter } = this.state;

    const rowsGrid = this.getRowsGrid(filteredLaborRegistrations);

    // Строим данные таблицы
    const tableData = this.getTableData(filteredLaborRegistrations);

    /**
     * Задаём сами, т.к. иначе при маленьком количестве столбцов не будет центровки таблицы
     */
    const tableContainerMaxWidth = this.getTableContainerMaxWidth(avaliableWorkClasses, rowsGrid);

    const workClassesList = [
      { label: formatMessage({ id: 'labor.allData' }), value: 'all' },

      ...avaliableWorkClasses.map(workClass => ({
        value: workClass,
        label: formatMessage({ id: `labor.stats.${workClass}` }),
      }))
    ];

    const compartmentsList = this.getCompartmentsList(compartments, formatMessage);
    const workTypesList = this.getWorkTypesList(locationWorkTypes, formatMessage);

    const customRanges = [
      {
        label: formatMessage({ id: 'dashboards.weekly' }),
        value: 'week',
      },
      {
        label: formatMessage({ id: 'dashboards.monthly' }),
        value: 'month',
      },
      {
        label: formatMessage({ id: 'dashboards.yearly' }),
        value: 'year',
      },
    ];

    return (
      <div
        className={styles.laborAddContainer}
        style={{
          maxWidth: `${tableContainerMaxWidth}px`,
          paddingLeft: SIDE_PADDING,
          paddingRight: SIDE_PADDING,
        }}
      >
        <BackButton
          link={`/${organizationSlug}/resources/labor/works`}
          text={formatMessage({ id: 'labor.worksTitle' })}
        />

        <div className={styles.controls}>
          <Typography variant='h2' className={styles.header}>
            {formatMessage({ id: 'labor.addLaborWorks' })}
          </Typography>

          <DefaultRangePicker
            intl={intl}
            className={styles.rangePicker}
            timezone={timezone}
            handlerAfterRangeSelect={this.handleOnRangeSelect}
            periodType={periodType}
            customRanges={customRanges}
            anyDateOfPeriodStart={startDate}
            anyDateOfPeriodEnd={endDate}
            byOneDate
          />
        </div>

        <div className={styles.filters}>
          <SelectboxFilterWithChecks
            options={compartmentsList}
            pluralPlaceholderId='dataQuality.greenhouses'
            onChange={this.handlerCompartmentsChange}
            className={styles.selectboxFilterWrapper}
            title={`${formatMessage({ id: 'dashboards.compartments' })}:`}
          />
          <SelectboxFilterWithChecks
            options={workTypesList}
            onChange={this.handlerWorkTypesChange}
            title={`${formatMessage({ id: 'dashboards.show' })}:`}
            classNameWrapper={styles.selectboxFilterWrapper}
            pluralPlaceholderId='plural.workType'
          />
          <SelectBoxFilter
            value={currentWorkClass}
            options={workClassesList}
            onChange={this.handlerWorkClassChange}
            title={`${formatMessage({ id: 'labor.dataEntry' })}:`}
            classNameWrapper={styles.selectboxFilterWrapper}
          />
        </div>

        <LaborWorksAddTable
          tableRef={this.tableDataEnter}
          isFetching={isLaborRegistrationsFetching}
          laborRegistrations={filteredLaborRegistrations}
          tableData={tableData}
          rowsGrid={rowsGrid}
          firstColWidths={FIRST_COL_WIDTHS}
          colWidths={this.getCellWidth(avaliableWorkClasses)}
          handleTableChange={changes => this.handleLaborTableChange(rowsGrid, changes)}
          getValueId={this.getValueId}
          allPlantingCycles={allPlantingCycles}
          varieties={varieties}
          fruitClasses={fruitClasses}
          compartments={compartments}
          organizationSlug={organizationSlug}
        />

        <div className={styles.buttons}>
          <BigButton
            className={styles.cancelBtn}
            href={`/${organizationSlug}/resources/labor/works`}
            title={formatMessage({ id: 'button.cancel' })}
            theme='light'
            disabled={isSaveLaborRegistrationsFetching}
          />
          <BigButton
            className={styles.saveBtn}
            onClick={() => this.handleSave()}
            title={formatMessage({ id: 'labor.saveWorks' })}
            theme='dark'
            isLoading={isSaveLaborRegistrationsFetching}
          />
        </div>

        {isSaveChangesDialogVisible && (
          <SaveChangesDialog
            handlerCloseDialog={() => this.setState({ isSaveChangesDialogVisible: false, actionAfter: undefined })}
            handlerDontSave={() => {
              actionAfter();

              this.setState({
                isSaveChangesDialogVisible: false, stateTableData: null, isTableValid: true, actionAfter: undefined
              });
            }}
            handlerSave={() => {
              this.handleSave();

              this.setState({ isSaveChangesDialogVisible: false, actionAfter: undefined });
            }}
          />
        )}
      </div>
    );
  }
}
