export default function getMetricCategories() {
  const data = [
    'other',
    'cropEvaluation',
    'cropPollination',
    'cropProduction',
    'cropProtection',
    'labor',
    'climate',
    'climateExtra',
    'climateSettings',
    'dailyIndicators',
    'climateUC',
    'heating',
    'lighting',
    'irrigation',
    'screening',
    'ventilation',
    'weather',
    'weight',
    'climateRichel',
  ];

  return data.sort().map(item => ({ title: item, showAll: true }));
}
