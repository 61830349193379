import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  getExpandedKeys,
  getGraphMetricSearch,
  getMetricsSelector,
  getMetricsTree,
  getMetricsTreeSearchData,
  getMetricsTreeSearchFilterResult,
  getPreset,
  getMetricsTreeFilter,
  getIsMetricsPanelFilterFetching
} from 'store/graphs/selectors';

import {
  getAllFruitClasses,
  getAllPlantingCycles,
  getAllSubNodes,
  getAllVarieties,
  getCurrentCompartments,
  getCurrentSpecies
} from 'store/company/selectors';

import {
  requestMetricsTree,
  setMetricsPanelFilters,
  togglePresetMetric,
  deletePresetMetricGroup,
  deletePresetMetric,
  setTreeExpandedKeys,
  resetTreeExpandedKeys,
  setTreeSearchData,
  setGraphMetricSearch
} from 'store/graphs/actions';

import SelectMetricsPanel from './SelectMetricsPanel';

const mapStateToProps = (state: any) => ({
  allMetrics: getMetricsSelector(state),
  allCompartments: getCurrentCompartments(state),
  allSubNodes: getAllSubNodes(state),
  allSpecies: getCurrentSpecies(state),
  allVarieties: getAllVarieties(state),
  allFruitClasses: getAllFruitClasses(state),
  allPlantingCycles: getAllPlantingCycles(state),
  preset: getPreset(state),
  metricsTree: getMetricsTree(state),
  metricsSearchResult: getMetricsTreeSearchFilterResult(state),
  metricsSearchData: getMetricsTreeSearchData(state),
  expandedKeys: getExpandedKeys(state),
  graphMetricSearch: getGraphMetricSearch(state),
  metricsTreeFilter: getMetricsTreeFilter(state),
  isMetricsPanelFilterFetching: getIsMetricsPanelFilterFetching(state),
});

const mapDispatchToProps = {
  requestMetricsTree,
  setMetricsPanelFilters,
  togglePresetMetric,
  deletePresetMetricGroup,
  deletePresetMetric,
  setTreeExpandedKeys,
  resetTreeExpandedKeys,
  setTreeSearchData,
  setGraphMetricSearch
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectMetricsPanel));
