import 'whatwg-fetch';

import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';

import { get } from 'lodash';
import queryString from 'query-string';

import storageWrapper from '../../helpers/storageWrapper';
import { getDefaultDateRange } from '../../helpers/defaultDates';

import initAxiosInstanse from '../../api/axios';

import { getDefaultBreakdownType } from './selectors';
import { getFirstSpecies } from '../company/selectors';

const safeLocalStorage = storageWrapper.get('localStorage');

export const requestDashboardsData = createAction('REQUEST_DASHBOARDS_DATA');
export const receiveDashboardsData = createAction('RECEIVE_DASHBOARDS_DATA');
export const setCardsState = createAction('SET_CARDS_STATE');

export const requestHarvestDashboardData = createAction('REQUEST_HARVEST_DASHBOARD_DATA');
export const receiveHarvestDashboardData = createAction('RECEIVE_HARVEST_DASHBOARD_DATA');

export const requestHarvestGraphData = createAction('REQUEST_HARVEST_GRAPH_DATA');
export const receiveHarvestGraphData = createAction('RECEIVE_HARVEST_GRAPH_DATA');
export const requestQualityGraphData = createAction('REQUEST_QUALITY_GRAPH_DATA');
export const receiveQualityGraphData = createAction('RECEIVE_QUALITY_GRAPH_DATA');
export const requestBreakdownGraphData = createAction('REQUEST_BREAKDOWN_GRAPH_DATA');
export const receiveBreakdownGraphData = createAction('RECEIVE_BREAKDOWN_GRAPH_DATA');

export function getDashboardsData({
  species, periodType, anyDateOfPeriod, loadingType
}) {
  return async (dispatch, getState) => {
    dispatch(requestDashboardsData(loadingType));

    const state = getState();

    const { location } = state.company;
    const jwt = safeLocalStorage.getItem('jwt');
    const locale = safeLocalStorage.getItem('locale');

    // TODO: Притащить для работы с get параметрами какую-нибудь либу
    let getParams = `species=${species}`;

    if (periodType && anyDateOfPeriod && periodType !== 'whole') {
      getParams += `&periodType=${periodType}&anyDateOfPeriod=${anyDateOfPeriod}`;
    } else {
      getParams += '&periodType=whole';
    }

    const headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `JWT ${jwt}`);
    headers.append('X-Lang', locale);

    if (location) {
      const response = await fetch(`${window.API}/locations/${location.id}/kpi/growth-efficiency?${getParams}`, {
        method: 'GET',
        headers,
        credentials: 'include',
      });

      const { status } = response;
      if (Math.round(status / 100) === 2) {
        const data = await response.json() || [];

        dispatch(receiveDashboardsData(data));
      } else {
        dispatch(receiveDashboardsData());
      }
    } else {
      dispatch(receiveDashboardsData());
    }
  };
}

export function updateCardOpenedState({ cardId, opened }) {
  return async (dispatch, getState) => {
    const state = getState();

    const { cardsState } = state.dashboards;

    const immutableCardsState = fromJS(cardsState);

    const newCardsState = immutableCardsState.setIn([cardId, 'opened'], opened);

    dispatch(setCardsState(newCardsState.toJS()));
  };
}

export function setCardsOpenedState({ ids, opened }) {
  return async (dispatch, getState) => {
    const state = getState();

    const { cardsState } = state.dashboards;

    const immutableCardsState = fromJS(cardsState);

    const changedCardsState = ids.reduce((acc, id) => {
      acc[id] = { opened };

      return acc;
    }, {});

    const newCardsState = immutableCardsState.mergeDeep(changedCardsState);

    dispatch(setCardsState(newCardsState.toJS()));
  };
}

export function clearCardsState() {
  return async dispatch => dispatch(setCardsState({}));
}

const getQuery = (searchParams, defaultSpecies, onlyPart) => {
  const DEFAULT_DATE_RANGE = getDefaultDateRange('year', true);
  const parsedQuery = queryString.parse(searchParams);

  const queryParams = queryString.stringify({
    periodType: get(parsedQuery, 'periodType') || 'week',
    anyDateOfPeriodStart: get(parsedQuery, 'anyDateOfPeriodStart') || DEFAULT_DATE_RANGE.startDate,
    anyDateOfPeriodEnd: get(parsedQuery, 'anyDateOfPeriodEnd') || DEFAULT_DATE_RANGE.endDate,
    cumulative: get(parsedQuery, 'cumulative') || false,
    cumulativeForQuality: get(parsedQuery, 'cumulativeForQuality') || false,
    breakdown: get(parsedQuery, 'breakdown') || getDefaultBreakdownType(),

    species: get(parsedQuery, 'species') || defaultSpecies,
    varietyId: get(parsedQuery, 'varietyId'),
    compartmentId: get(parsedQuery, 'compartmentId'),
    plantingCycleId: get(parsedQuery, 'plantingCycleId'),

    relative: get(parsedQuery, 'units', 'absolute') === 'relative',
    onlyPart,
    locationPlan: get(parsedQuery, 'locationPlan')
  });

  const query = queryParams ? `&${queryParams}` : '';

  return query;
};

export function getHarvestDashboardData(searchParams) {
  return async (dispatch, getState) => {
    dispatch(requestHarvestDashboardData());

    const state = getState();
    const { location } = state.company;
    const defaultSpecies = getFirstSpecies(state);

    const query = getQuery(searchParams, defaultSpecies);

    try {
      const response = await initAxiosInstanse(searchParams)
        .get(`/locations/${location.id}/kpi/harvest-dashboard?${query}`);

      dispatch(receiveHarvestDashboardData(response.data));
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function getHarvestGraphData(searchParams) {
  return async (dispatch, getState) => {
    dispatch(requestHarvestGraphData());

    const state = getState();
    const { location } = state.company;
    const defaultSpecies = getFirstSpecies(state);

    const query = getQuery(searchParams, defaultSpecies, 'harvest');

    try {
      const response = await initAxiosInstanse(searchParams)
        .get(`/locations/${location.id}/kpi/harvest-dashboard?${query}`);

      dispatch(receiveHarvestGraphData(response.data));
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function getQualityGraphData(searchParams) {
  return async (dispatch, getState) => {
    dispatch(requestQualityGraphData());

    const state = getState();
    const { location } = state.company;
    const defaultSpecies = getFirstSpecies(state);

    const query = getQuery(searchParams, defaultSpecies, 'quality');

    try {
      const response = await initAxiosInstanse(searchParams)
        .get(`/locations/${location.id}/kpi/harvest-dashboard?${query}`);

      dispatch(receiveQualityGraphData(response.data));
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function getBreakdownGraphData(searchParams) {
  return async (dispatch, getState) => {
    dispatch(requestBreakdownGraphData());

    const state = getState();
    const { location } = state.company;
    const defaultSpecies = getFirstSpecies(state);

    const query = getQuery(searchParams, defaultSpecies, 'harvestBreakdown');

    try {
      const response = await initAxiosInstanse(searchParams)
        .get(`/locations/${location.id}/kpi/harvest-dashboard?${query}`);

      dispatch(receiveBreakdownGraphData(response.data));
    } catch (error) {
      throw new Error(error);
    }
  };
}
