import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, } from 'react';

import CropIcon from '../CropIcon';
import { ReactComponent as CheckIcon } from './assets/check.svg';

import styles from './CropToggleButton.module.css';


export default class CropToggleButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    isSelected: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isTitleComponent: PropTypes.bool,
    icon: PropTypes.object,
    crop: PropTypes.oneOf([null, 'cucumber', 'tomato', 'eggplant', 'lettuce', 'pepper']),
    onSelected: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    title: null,
    isTitleComponent: false,
    icon: null,
    crop: null,
    isSelected: false,
    onSelected: null,
    disabled: false,
  };

  handlerClick = () => {
    const { onSelected, isSelected } = this.props;

    if (onSelected) {
      onSelected({ isSelected: !isSelected });
    }
  };

  render() {
    const {
      crop,
      isSelected,
      title,
      isTitleComponent,
      icon,
      className,
      disabled,
    } = this.props;

    let renderedTitle = null;

    if (title) {
      if (isTitleComponent) {
        renderedTitle = title;
      } else {
        renderedTitle = <div className={styles.title}>{title}</div>;
      }
    }

    return (
      <button type='button' className={classnames(styles.layout, { [styles.selected]: isSelected }, className)} onClick={this.handlerClick} disabled={disabled}>
        {icon || <CropIcon className={styles.icon} crop={crop} />}
        {renderedTitle}
        <CheckIcon className={styles.check} />
      </button>
    );
  }
}
