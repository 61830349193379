import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import queryString from 'query-string';
import { get } from 'lodash';

import initAxiosInstanse from '../../api/axios';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

import {
  requestHarvestKpiDashboardData,
  receiveHarvestKpiDashboardData,

  requestAllHarvestKpiDashboardData,
  requestBreakdownHarvestKpiDashboardData,
} from './actions';

export const getPartHarvestKpiDashboardEpic = action$ =>
  action$.ofType(requestAllHarvestKpiDashboardData, requestBreakdownHarvestKpiDashboardData)
    .mergeMap(actionData => Observable.of(requestHarvestKpiDashboardData(actionData.payload)));


export const getHarvestKpiDashboardEpic = (action$, store) =>
  action$.ofType(requestHarvestKpiDashboardData)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          periodType,
          anyDateOfPeriod,
          breakdown,
          isRelative,
          category,
          breakdownOnly,
          locationPlan,
        }
      } = actionData;

      const queryParams = queryString.stringify({
        periodType,
        anyDateOfPeriod,
        breakdown,
        relative: isRelative,
        category: category === '1' ? 1 : undefined,
        breakdownOnly,
        locationPlan,
      });

      const query = queryParams ? `&${queryParams}` : '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`locations/${organizationId}/kpi/harvest-plan?${query}`))
        .map(({ data }) => receiveHarvestKpiDashboardData({ harvestKpi: data }))
        .catch(() => Observable.of(
          receiveHarvestKpiDashboardData(),
          showNotificationWithTimeout({
            id: `notifications.getHarvestKpiDashboardError.${Date.now()}`,
            messageId: 'notifications.getHarvestKpiDashboardError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export default combineEpics(
  getHarvestKpiDashboardEpic,
  getPartHarvestKpiDashboardEpic,
);
