import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';

import classnames from 'classnames';

import useResizeObserver from 'use-resize-observer';

// @ts-ignore
import { injectIntl, InjectedIntlProps } from 'react-intl';

import {
 get, merge, uniqueId
} from 'lodash';

import EmptyState from 'components/EmptyState';
import Legend from 'components/Legend';
import ArrowLeftIcon from 'components/Icons/WalkArrowLeftIcon';
import ArrowRightIcon from 'components/Icons/WalkArrowRightIcon';

// @ts-ignore
import { Style } from 'radium';
import loaderStyles from 'components/CircleLoader/CircleLoader.module.css';
import CircleLoader from 'components/CircleLoader';
import { getNameByLocal } from 'helpers/getNameByLocal';

import { CustomChartPlot, CustomChartTrace } from '../../../../types';

import ChartTooltip from '../ChartTooltip';

import {
  createDomainRangeGenerator,
  createValueGenerator,
  defaultStyles,
  getAxisStyles,
} from '../LineChart/shared';

import useXAxis from '../LineChart/hoohs/useXAxis';
import useYAxis from '../LineChart/hoohs/useYAxis';
import useHoverLineV from '../LineChart/hoohs/useHoverLineV';
import useHoverLineH from '../LineChart/hoohs/useHoverLineH';
import useLines from '../LineChart/hoohs/useLines';
import useHoverLineVIndicator from '../LineChart/hoohs/useHoverLineVIndicator';
import useHoverLineHIndicator from '../LineChart/hoohs/useHoverLineHIndicator';
import useBubblesChart from '../LineChart/hoohs/useBubblesChart';
import useBalance from '../LineChart/hoohs/useBalance';
import useOverlay from '../LineChart/hoohs/useOverlay';

import styles from './LinesGraph.module.css';
import useHoverPoints from "../LineChart/hoohs/useHoverPoints";

// very strange shit
const getBarsCount = (count: number, width: number): number => {
  if (count) {
    if (width >= 720) {
      return count;
    } if (width >= 480) {
      return 20;
    }
    return 14;
  }
  return 0;
};

function getLegendPaletteColor(palette: string | undefined) {
  if (palette === 'green') {
    return '#00B197';
  }

  if (palette === 'blue') {
    return '#1DBADF';
  }

  return '#000000';
}

/*
type Margin = {
  left: number;
  top: number;
  right: number;
  bottom: number;
};
*/

type ChartSize = {
  width: number;
  height: number;
}

type EmptyStateMode = 'overlap' | 'embedded';
// type LineRefMode = 'vertical' | 'horizontal' | 'both';

type HoveredBubbles = {
  point: {
    x: number,
    y: number,
    colorIndex: number;
    index: number;
  };
  trace: CustomChartTrace;
}

type TooltipState = {
  screenX: number;
  screenY: number;
  isShow: boolean;
  bubbles: Array<HoveredBubbles>;
  lines: Array<any>;
  xCoord: number;
};

type LinesGraphProps = {
  plot: CustomChartPlot;
  isDataFetching: boolean;
  wrapperClassName: string;
  tooltipClassName: string;
  isFlexModeEnabled: boolean;
  tooltipState: TooltipState;
  isEmpty: boolean;
  defaultSize: ChartSize;
  size: ChartSize;
  onTooltipChanged: Function;
  isLegendDisabled: boolean;
  renderTooltipContent: Function;
  emptyStateMode: EmptyStateMode;
  // refLinesMode: LineRefMode;
  emptyStateText: string;
  isShowVerticalGrid: boolean;
  // eslint-disable-next-line react/require-default-props
  tickDisplayFormat?: Function;
  // eslint-disable-next-line react/require-default-props
  ticksFunction?: Function;
  xTicks: number;
  yTicks: number
  cropNullValues: boolean;
  legendClassName: string;
};

type PointerState = {
  x: number;
  y: number;
};

// @ts-ignore
function getRelativeCoord(e: any, chartParams: any, svgRef: any) {
  if (svgRef?.current) {
    const rect = svgRef.current.getBoundingClientRect();
    return {
      x: e.clientX - rect.left - chartParams.pointerDelta.x,
      y: e.clientY - rect.top - chartParams.pointerDelta.y
    };
  }
  return {
    x: 0,
    y: 0
  };
}

const yAxisOrientRight = false;

const xType = 'linear';
const yType = 'linear';

const HORIZONTAL_AXIS_PADDING = 8;

// TODO: TS
const findIntersectPointsInTraces = (traces: Array<CustomChartTrace>, xCoord: number) => {
  const found = [];
  const index = +xCoord.toFixed(0);
  for (let i = 0; i < traces.length; i += 1) {
    const trace = traces[i];
    // @ts-ignore
    const valueIndex = trace.x.findIndex(item => item === index);
    if (valueIndex !== -1) {
      found.push({
        trace,
        value: trace.y[valueIndex]
      });
    }
  }
  return found;
};

const LinesGraph = ({
  intl,
  plot,
  isDataFetching,
  wrapperClassName,
  tooltipClassName,
  isFlexModeEnabled,
  tooltipState,
  isEmpty,
  defaultSize,
  size, // from sizeMe
  onTooltipChanged,
  isLegendDisabled,
  renderTooltipContent,
  emptyStateMode = 'embeded',
  emptyStateText,
  isShowVerticalGrid = false,
  tickDisplayFormat,
  ticksFunction,
  xTicks = 6,
  yTicks = 4,
  cropNullValues = false,
  legendClassName = null,
} : LinesGraphProps & InjectedIntlProps) => {
  const { locale } = intl;

  const [pointerState, setPointerState] = useState<PointerState>({
    x: 0,
    y: 0,
  });

  const [currentTooltipState, setTooltipState] = useState<TooltipState>({
    ...(tooltipState || {
      screenX: 0, screenY: 0, isShow: false, bubbles: [], lines: [], xCoord: 0
    })
  });

  const {
    ref,
    width = 0 || size?.width || get(defaultSize, 'width'),
  } = useResizeObserver<HTMLDivElement>();
  // const lineChartWrapperRef = useRef(null);

  const lineChartEl = useRef(null);
  const svgRef = useRef(null);
  const chartRootEl = useRef(null);
  const xAxisRef = useRef(null);
  const yAxisLeftRef = useRef(null);
  const hoverLineVRef = useRef(null);
  const hoverLineHRef = useRef(null);
  const linesRef = useRef(null);
  const pointsRef = useRef(null);
  const yAxisIndicatorRef = useRef(null);
  const xAxisIndicatorRef = useRef(null);
  const overlayRef = useRef(null);
  const bubblesRef = useRef(null);
  const balanceRef = useRef(null);
  const textGroupRef = useRef(null);

  const [chartEndItem, setChartEndItem] = useState(undefined);

  const maxTracesPoints = useMemo(() =>
    // @ts-ignore
    Math.max(...(plot?.traces?.map(trace => trace.x.length) || [])), [plot]);

  const paginationState = useMemo(() => {
    const threshold = getBarsCount(maxTracesPoints, window.innerWidth);
    // @ts-ignore
    const chartPosition = chartEndItem || maxTracesPoints.length - 1;
    return {
      threshold,
      chartPosition,
      isBackDisabled: chartPosition - threshold <= 0,
      // @ts-ignore
      isForwardDisabled: chartPosition >= maxTracesPoints.length - 1 || threshold >= maxTracesPoints.length
    };
  }, [maxTracesPoints, chartEndItem]);

  const handlerMove = useCallback(({ direction }) => {
    const diff = direction === 'back' ? -paginationState.threshold : paginationState.threshold;
    let newChartEnd = paginationState.chartPosition + diff;
    if (newChartEnd - paginationState.threshold < 0) {
      newChartEnd = paginationState.threshold;
    }
    // @ts-ignore
    if (newChartEnd > maxTracesPoints.length - 1) {
      // @ts-ignore
      newChartEnd = maxTracesPoints.length - 1;
    }
    // @ts-ignore
    setChartEndItem(newChartEnd);
  }, [maxTracesPoints, paginationState]);

  const height = useMemo(() => size?.height || get(defaultSize, 'height') || 332,
    [size, defaultSize]);

  const {
    maxXValue,
    minXValue,
    maxYValue,
    minYValue,
    yDomainRange
  } = useMemo(() => {
    const {
      xAxis,
      yAxis
    } = plot;

    if (isEmpty) {
      return {
        maxXValue: 100,
        minXValue: 0,
        maxYValue: 100,
        minYValue: 0,
        yDomainRange: [0, 100]
      };
    }

    // @ts-ignore
    const values = (plot?.traces?.reduce((acc, trace) => ({
      ...acc, x: [...acc.x, ...trace.x], y: [...acc.y, ...trace.y]
    }), {
      x: [],
      y: []
    }));

    if (xAxis.type === 'xRanged' && xAxis.range) {
      values.x.push(xAxis.range.start, xAxis.range.end);
    }

    if (yAxis.type === 'yRanged' && yAxis.range) {
      values.y.push(yAxis.range.start, yAxis.range.end);
    }

    const maxX = Math.max(...values.x);
    const minX = Math.min(...values.x);
    const maxY = Math.max(...values.y);
    const minY = Math.min(...values.y);

    return {
      maxXValue: maxX,
      minXValue: minX,
      maxYValue: maxY,
      minYValue: minY,
      yDomainRange: [minY, maxY]
    };
  }, [plot, isEmpty]);

  const chartParams = useMemo(() => {
    const axisWidth = 64;
    const axisHeight = 64;

    const x = createDomainRangeGenerator(
      'x',
      // getTicksMinMax(getTicksRange(minXValue, maxXValue, xTicks)),
      [Math.floor(minXValue), Math.ceil(maxXValue)],
      [],
      xType,
      width - ((axisWidth) + HORIZONTAL_AXIS_PADDING),
      // parseDate
    );

    const y = createDomainRangeGenerator(
      'y',
      [Math.floor(minYValue), Math.ceil(maxYValue)],
      // getTicksMinMax(getTicksRange(minYValue, maxYValue, yTicks)),
      [],
      yType,
      height,
      // parseDate
    );

    const xValue = createValueGenerator('x', xType, /* parseDate */);
    const yValue = createValueGenerator('y', yType, /* parseDate */);

    return {
      w: width,
      h: height,
      x,
      y,
      xValue,
      yValue,
      canvas: {
        width,
        height: height + axisHeight,
        leftPadding: axisWidth / 2 + HORIZONTAL_AXIS_PADDING,
        topPadding: axisHeight / 2,
        bottomPadding: axisHeight / 2,
      },
      pointerDelta: {
        x: axisWidth / 2 + HORIZONTAL_AXIS_PADDING,
        y: axisHeight / 2
      }
    };
  }, [
    width,
    height,
    minXValue,
    maxXValue,
    minYValue,
    maxYValue,
  ]);

  const [hiddenElements, setHiddenElements] = useState(true);

  const handlerMouseMove = useCallback((e) => {
    setHiddenElements(false);
    const relativeCoord = getRelativeCoord(e, chartParams, svgRef);
    setPointerState(relativeCoord);
    // @ts-ignore
    const xCoord = chartParams?.x?.invert(relativeCoord.x);
    const lines = findIntersectPointsInTraces(plot.traces, xCoord);
    setTooltipState(prevState => ({
      ...prevState,
      screenX: relativeCoord.x,
      screenY: relativeCoord.y,
      xCoord,
      lines
    }));
    if (onTooltipChanged) {
      onTooltipChanged({
        isShow: true,
        screenX: relativeCoord.x,
        screenY: relativeCoord.y,
        xCoord,
        lines
      });
    }
  }, [chartParams, svgRef, setPointerState, setTooltipState, setHiddenElements, plot, onTooltipChanged]);


  useEffect(() => {
    if (tooltipState) {
      setTooltipState((prevState => {
        // @ts-ignore
        const lines = findIntersectPointsInTraces(plot.traces, tooltipState.xCoord);
        return {
          ...prevState,
          screenX: tooltipState.screenX,
          screenY: tooltipState.screenY,
          isShow: tooltipState.isShow,
          xCoord: tooltipState.xCoord,
          lines
        };
      }));
    }
  } ,[chartParams, svgRef, setPointerState, tooltipState, setTooltipState, plot]);

  const handlerMouseEnter = useCallback(() => {
    setTooltipState(prevState => ({ ...prevState, isShow: true }));
    setHiddenElements(false);
    if (onTooltipChanged) {
      onTooltipChanged({
        isShow: true,
      });
    }
  }, [onTooltipChanged, setTooltipState, setHiddenElements]);

  // TODO: Refactoring, filtering events, remove pointerEvents='none' from elements
  const handlerMouseLeave = useCallback(() => {
    setTooltipState(prevState => ({ ...prevState, isShow: false }));
    setHiddenElements(true);
    if (onTooltipChanged) {
      onTooltipChanged({
        isShow: false,
        bubbles: [],
        lines: []
      });
    }
  }, [onTooltipChanged, setTooltipState, setHiddenElements]);

  useXAxis(xAxisRef, {
    chartParams,
    xType,
    xTicks,
    isShowVerticalGrid,
    tickDisplayFormat,
    ticksFunction
  });

  useYAxis(yAxisLeftRef, {
    chartParams,
    yType,
    axisLabels: null,
    yTicks,
    grid: true,
    tickTimeDisplayFormat: null,
    yAxisOrientRight,
    yDomainRange,
    customGetTicksRange: null,
  });

  const linesTraces = useMemo(() =>
    plot?.traces?.filter((trace:CustomChartTrace) => trace?.line) || [], [plot]);

  useHoverLineV(hoverLineVRef, {
    chartParams,
    height,
    position: Math.max(0, pointerState.x),
    linesTraces,
    isShowIntersectPoints: false,
    hidden: hiddenElements
  });

  useHoverLineHIndicator(xAxisIndicatorRef, {
    chartParams,
    position: Math.max(0, pointerState.x),
    hidden: hiddenElements,
    tickDisplayFormat
  });

  useHoverLineH(hoverLineHRef, {
    chartParams,
    width,
    position: Math.min(Math.max(0, pointerState.y), height),
    hidden: hiddenElements
  });

  useHoverLineVIndicator(yAxisIndicatorRef, {
    chartParams,
    position: Math.min(Math.max(0, pointerState.y), height),
    hidden: hiddenElements
  });

  useLines(linesRef, {
    chartParams,
    linesTraces,
    cropNullValues
  });

  const bubblesTraces = useMemo(() =>
    (plot?.traces?.filter((trace:CustomChartTrace) => trace?.marker) || [])
      .map((trace:CustomChartTrace) => ({
        ...trace,
        points: trace.x.map((x, index) => ({
          x,
          y: trace.y[index],
          colorIndex: trace?.marker?.paletteIdx?.index ?
            trace?.marker?.paletteIdx?.index[index] : undefined,
        })),
      })), [plot]);

  const onOverPoint = useCallback((bubble: any, item: any, idx: number) => {
    setTooltipState(prevState => ({
     ...prevState,
    bubbles: [{
            point: {
              ...bubble,
              index: idx,
            },
            trace: item
        }]
    }));
  }, [setTooltipState]);

  const onOutPoint = useCallback(() => {
    setTooltipState(prevState => ({
      ...prevState,
      bubbles: []
    }));
  }, [setTooltipState]);

  const overlayFigure = plot?.figures?.find((figure: any) => figure?.type === 'Rectangle');

  if (overlayFigure) {
  }
  useOverlay(overlayRef, {
    chartParams,
    overlayFigure,
  });

  useBubblesChart(bubblesRef, {
    chartParams,
    bubblesData: bubblesTraces,
    height,
    onOverPoint,
    onOutPoint
  });

  useBalance(balanceRef, textGroupRef, {
    chartParams,
    plantBalanceData: plot?.figures ? plot?.figures?.filter((figureItem: any) => figureItem.type !== 'Rectangle') : [],
    intl
  });

  useHoverPoints(pointsRef, {
    chartParams,
    isShowHoverPoints: currentTooltipState?.isShow || tooltipState?.isShow,
    xCoord: currentTooltipState?.xCoord ? +currentTooltipState.xCoord.toFixed(0) : 0,
    traces: currentTooltipState?.lines || []
  });

  const legend = useMemo(() => plot?.traces?.map((trace: CustomChartTrace) => ({
    name: getNameByLocal(trace, locale),
    color: trace?.line?.color || getLegendPaletteColor(trace.marker?.paletteIdx.name)
  })) || [], [plot, locale]);

  const isMobile = window.innerWidth < 720;
  const isRenderTooltip = !isDataFetching && renderTooltipContent && !isEmpty;

  const uid = useMemo(() => uniqueId('line-chart-id-'), []);
  const lineChartWrapperClassName = useMemo(() => `line-chart-${uid}`, [uid]);

  const StyleDef = useMemo(() => {
    const scope = `.line-chart-${uid}`;
    const axisStyles = getAxisStyles(true, isShowVerticalGrid, yAxisOrientRight);
    const rules = merge({}, defaultStyles, axisStyles);
    return <Style scopeSelector={scope} rules={rules} />;
  }, [uid, isShowVerticalGrid]);

  if (isEmpty && emptyStateMode === 'overlap') {
    return (
      <div className={styles.emptyStateWrapper}>
        <EmptyState
          className={classnames(styles.emptyState)}
          text={emptyStateText}
          // isFixedSize
          isFlex
        />
      </div>
    );
  }

  return (
    <div
      ref={ref}
      className={classnames(styles.lineChartWrapper, wrapperClassName)}
    >
      {isMobile && isFlexModeEnabled ? (
        <div className={styles.actions}>
          <button
            type='button'
            className={classnames(styles.walkButton, { [styles.disabled]: paginationState?.isBackDisabled })}
            onClick={() => !paginationState?.isBackDisabled && handlerMove({ direction: 'back' })}
          >
            <ArrowLeftIcon className={styles.icon} />
          </button>
          <button
            type='button'
            className={classnames(styles.walkButton, { [styles.disabled]: paginationState?.isForwardDisabled })}
            onClick={() => !paginationState?.isForwardDisabled && handlerMove({ direction: 'forward' })}
          >
            <ArrowRightIcon className={styles.icon} />
          </button>
        </div>
      ) : null}
      <div
        ref={lineChartEl}
        className={lineChartWrapperClassName}
        onMouseLeave={handlerMouseLeave}
        style={{
          position: 'relative',
          width: '100%'
        }}
      >
        {StyleDef}
        <svg
          width='100%'
          height={chartParams?.canvas?.height || 0}
          ref={svgRef}
          onPointerMove={handlerMouseMove}
          onMouseEnter={handlerMouseEnter}
        >
          <g
            transform={`translate(${chartParams?.canvas?.leftPadding}, ${chartParams?.canvas?.topPadding})`}
            ref={chartRootEl}
          >
            <g
              ref={balanceRef}
              className='balance'
              pointerEvents='none'
            />
            <g
              ref={xAxisRef}
              transform={`translate(0, ${chartParams?.canvas?.height - chartParams?.canvas?.topPadding - chartParams?.canvas?.bottomPadding})`}
              className='x axis'
              pointerEvents='none'
            />
            <g ref={yAxisLeftRef} className='yAxisLeft' pointerEvents='none' />
            <g ref={linesRef} className='lines' pointerEvents='none' />
            <g ref={pointsRef} className='points' pointerEvents='none' />
            <g ref={yAxisIndicatorRef} className='yAxisIndicator' pointerEvents='none' />
            <g ref={xAxisIndicatorRef} className='xAxisIndicator' pointerEvents='none' />
            <g ref={overlayRef} className='overlay' />
            <g ref={bubblesRef} className='bubbles' />
            <g ref={hoverLineVRef} className='hoveLineV' pointerEvents='none' />
            <g ref={hoverLineHRef} className='hoveLineH' pointerEvents='none' />
            <g ref={textGroupRef} className='text' pointerEvents='none' />
          </g>
        </svg>
        {isRenderTooltip && (
          <ChartTooltip
            screenX={currentTooltipState?.screenX}
            screenY={currentTooltipState?.screenY}
            showTooltip={currentTooltipState?.isShow}
            chartParams={chartParams}
            className={tooltipClassName}
          >
            {renderTooltipContent ? renderTooltipContent(currentTooltipState) : null}
          </ChartTooltip>
        )}
        {isDataFetching && (
          <CircleLoader
            className={loaderStyles.circleLoader}
            iconClassName={loaderStyles.circleLoaderIcon}
          />
        )}
      </div>
      {!isLegendDisabled && !isEmpty && <Legend className={classnames(styles.legend, legendClassName)} lines={legend} />}
      {isEmpty && emptyStateMode === 'embeded' && (
        <div className={styles.emptyStateWrapperNoOverlap}>
          <EmptyState
            className={classnames(styles.emptyState)}
            text={emptyStateText}
            isFlex
          />
        </div>
      )}
    </div>
  );
};

export default injectIntl(LinesGraph);
