import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import moment from 'moment';
import classnames from 'classnames';


import getDateFormat from 'helpers/getDateFormat';

import DropdownMenu from 'components/DropdownMenu';
import Avatar from 'components/Avatar';
import ReadMore from 'components/ReadMore';
// import VersionInfoDialog from '../VersionInfoDialog';
import VersionInfoDialog from 'components/VersionInfoDialog';
import DeleteInfoDialog from 'components/DeleteInfoDialog';
// import RestoreDialog from '../RestoreDialog';
// import PublishDialog from '../PublishDialog';

import styles from './VersionCard.module.css';

const handlerMouseEnter = setIsHovered => setIsHovered(true);
const handlerMouseLeave = setIsHovered => setIsHovered(false);

const handlerOpenNameDialog = (e, setIsNameDialogOpened) => {
  e.stopPropagation();

  return setIsNameDialogOpened(true);
};

const handlerOpenDeleteInfoDialog = (e, setIsDeleteInfoDialogOpened, isDeleteVersionInfoEnabled) => {
  e.stopPropagation();

  if (!isDeleteVersionInfoEnabled) {
    return null;
  }

  return setIsDeleteInfoDialogOpened(true);
};

const handlerClickRestoreVersion = (e, restoreVersion, isRestoreVersionEnabled) => {
  e.stopPropagation();

  if (!isRestoreVersionEnabled) {
    return null;
  }

  return restoreVersion();
};

const renderNamedVersionCreationDate = (author, formattedWhenCreated) => (
  <span className={styles.authorWrapper}>
    <span className={styles.authorName}>{author}</span>
    <span className={styles.dot} />
    <span>{formattedWhenCreated}</span>
  </span>
);

const renderBages = (formatMessage, { version }) => (
  <div className={styles.bages}>
    <div className={styles.bage}>{`${formatMessage({ id: 'forecast.version' })} ${version}`}</div>
    <div className={styles.bage}>{formatMessage({ id: 'plansVersion.published' })}</div>
  </div>
);

const VersionCard = ({
  intl: { formatMessage },
  version,
  allVersions,
  activeVersionId,
  onVersionClick,
  isLastSaved,
  readOnly,
  isDeleteVersionInfoFetching,
  isVersionInfoFetching,
  onEditVersionInfo,
  onDeleteVersionInfo,
  restoreVersion,
  isRestoreVersionEnabled,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isNameDialogOpened, setIsNameDialogOpened] = useState(false);
  const [isDeleteInfoDialogOpened, setIsDeleteInfoDialogOpened] = useState(false);

  const {
    id: planId, label, labelComment, author, whenCreated, published, restoredFrom, publication
  } = version;

  const formattedWhenCreated = moment(whenCreated).format(getDateFormat('ll hh'));
  const formattedWhenRestored = restoredFrom ? moment(restoredFrom.creationDate).format(getDateFormat('ll hh')) : null;
  const defaultLabel = formattedWhenCreated;

  const isCardActive = planId === activeVersionId;

  const isDeleteVersionInfoEnabled = label || labelComment;

  const initialValues = {
    versionName: label,
    versionComment: labelComment,
  };

  return (
    <>
      <div
        className={classnames(
          styles.plan,
          { [styles.cardActive]: isCardActive }
        )}
        onClick={() => onVersionClick({ planId })}
        role='button'
        tabIndex={0}
        onMouseEnter={() => handlerMouseEnter(setIsHovered)}
        onMouseLeave={() => handlerMouseLeave(setIsHovered)}
      >
        <div className={styles.planLeftContainer}>
          <Avatar
            className={styles.avatar}
            userName={author}
          />

          <div className={styles.planInfo}>
            <div className={styles.planLabel}>
              {label || defaultLabel}
            </div>
            {isLastSaved && (
              <div className={styles.lastSaved}>
                {formatMessage({ id: 'plansVersion.lastSaved' })}
              </div>
            )}
            {restoredFrom && (
              <div className={styles.restored}>
                {`${formatMessage({ id: 'plansVersion.restored' })} ${formattedWhenRestored}`}
              </div>
            )}
            <div className={styles.planAuthor}>
              {label ?
                renderNamedVersionCreationDate(author, formattedWhenCreated)
                :
                author}
            </div>
            {published && (
              <div className={styles.planPublished}>
                {formatMessage({ id: 'plansVersion.published' })}
              </div>
            )}
            {labelComment && (
              <div className={styles.planComment}>
                <ReadMore
                  text={labelComment}
                  lines={4}
                />
              </div>
            )}
            {publication && renderBages(formatMessage, publication)}
          </div>
        </div>

        {isHovered && !readOnly ? (
          <DropdownMenu
            wrapperClassName={styles.dropdownWrapper}
            defaultButtonClassName={styles.dropdownButton}
          >
            <div className={styles.dropdownContent}>
              <div
                className={styles.dropdownOption}
                onClick={e => handlerOpenNameDialog(e, setIsNameDialogOpened)}
                role='button'
                tabIndex={0}
              >
                {label ? formatMessage({ id: 'plansVersion.editVersionInfo' }) : formatMessage({ id: 'plansVersion.nameVersion' }) }
              </div>
              <div
                className={classnames(
                  styles.dropdownOption,
                  {
                    [styles.dropdownOptionDisabled]: !isRestoreVersionEnabled
                  }
                )}
                onClick={e => handlerClickRestoreVersion(e, restoreVersion, isRestoreVersionEnabled)}
                role='button'
                tabIndex={0}
              >
                {formatMessage({ id: 'plansVersion.restoreVersion' })}
              </div>
              <div
                className={classnames(
                  styles.dropdownOption,
                  {
                    [styles.dropdownOptionDisabled]: !isDeleteVersionInfoEnabled
                  }
                )}
                onClick={e => handlerOpenDeleteInfoDialog(e, setIsDeleteInfoDialogOpened, isDeleteVersionInfoEnabled)}
                role='button'
                tabIndex={0}
              >
                {formatMessage({ id: 'plansVersion.deleteVersionInfo' })}
              </div>
            </div>
          </DropdownMenu>
        )
          :
          <div className={styles.stub} />}
      </div>
      {isNameDialogOpened && (
        <VersionInfoDialog
          planId={planId}
          label={label}
          comment={labelComment}
          handlerCloseDialog={() => setIsNameDialogOpened(false)}
          handlerDontSave={() => setIsNameDialogOpened(false)}
          handlerSave={params => onEditVersionInfo(params, () => setIsNameDialogOpened(false))}
          initialValues={initialValues}
          isVersionInfoFetching={isVersionInfoFetching}
          versions={allVersions}
        />
      )}
      {isDeleteInfoDialogOpened && (
        <DeleteInfoDialog
          handlerCloseDialog={() => setIsDeleteInfoDialogOpened(false)}
          handlerDontSave={() => setIsDeleteInfoDialogOpened(false)}
          handlerSave={() => onDeleteVersionInfo({ planId }, () => setIsDeleteInfoDialogOpened(false))}
          isDeleteVersionInfoFetching={isDeleteVersionInfoFetching}
        />
      )}
    </>
  );
};

VersionCard.propTypes = {
  intl: intlShape.isRequired,
  version: PropTypes.object.isRequired,
  allVersions: PropTypes.array,
  activeVersionId: PropTypes.number,
  isLastSaved: PropTypes.bool,
  readOnly: PropTypes.bool,
  isDeleteVersionInfoFetching: PropTypes.bool,
  isVersionInfoFetching: PropTypes.bool,
  isRestoreVersionEnabled: PropTypes.bool,

  onVersionClick: PropTypes.func.isRequired,
  onEditVersionInfo: PropTypes.func.isRequired,
  onDeleteVersionInfo: PropTypes.func.isRequired,
  restoreVersion: PropTypes.func.isRequired,
};

VersionCard.defaultProps = {
  allVersions: [],
  activeVersionId: null,
  isLastSaved: false,
  readOnly: false,
  isDeleteVersionInfoFetching: false,
  isVersionInfoFetching: false,
  isRestoreVersionEnabled: false,
};

export default injectIntl(memo(VersionCard));
