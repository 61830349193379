import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';

import classnames from 'classnames';
import { uniqueId } from 'lodash';

import ReactTooltip from 'react-tooltip';

import isTouchDevice from '../../../../helpers/isTouchDevice';
import numbersRounding from '../../../../helpers/numbersRounding';
import numbersFormatting from '../../../../helpers/numbersFormatting';

import tooltipStyles from '../../../Tooltip/index.module.css';
import styles from './Speedometer.module.css';

const getScaleColor = (color) => {
  switch (color) {
    case 'red':
      return '#BE1034';
    case 'green':
      return '#00B197';
    case 'yellow':
      return '#DB973A';
    case 'blue':
      return '#1DBADF';
    default:
      return '#C2C6CF';
  }
};

const Speedometer = React.memo(({
  intl,
  percent,
  radius,
  wrapperPadding,
  strokeWidth,
  color,
  size,
  units,
  isCurrentPeriod,
  initialValue,
  currentValue,
  finalValue,
  expectedValue,
  currentValueText,
  percentBoxClassName,
  currentValueBoxClassName,
  withoutTooltip
}) => {
  const { formatMessage } = intl;
  const limitedPercent = percent > 100 ? 100 : percent; // Для расчётов
  const formattedPercent = numbersFormatting(numbersRounding(percent, 'fixed', 1)); // Для вывода текста

  // TODO: вынести комбинацию numbersFormatting и numbersRounding в одну функцию
  const roundTo = units === 'kilogram' || units === 'count' ? 0 : 1;

  const expectedPercent = (expectedValue / finalValue) * 100;
  const limitedExpectedPercent = expectedPercent > 100 ? 100 : expectedPercent;
  const formattedExpectedValue = numbersFormatting(numbersRounding(expectedValue, 'fixed', roundTo));

  const dimensions = (radius * 2) + wrapperPadding;
  const cf = 2 * Math.PI * radius;
  const semiCf = cf / 2;

  const colorDasharry = `${semiCf},${cf}`;

  const meterValue = semiCf - ((limitedPercent * semiCf) / 100);
  const maskDasharray = `${meterValue},${cf}`;
  const rotateDeg = 270 + ((limitedPercent * 180) / 100);
  const rotateDegExpected = 270 + ((limitedExpectedPercent * 180) / 100);
  const rotateDegText = 90 - ((limitedExpectedPercent * 180) / 100);

  const formattedFinalValue = finalValue ? numbersFormatting(numbersRounding(finalValue, 'fixed', roundTo)) : null;
  const formattedCurrentValue = numbersFormatting(numbersRounding(currentValue, 'fixed', roundTo));

  const tooltipId = `speedometer-tooltip-${uniqueId()}`;

  return (
    <div
      className={classnames(styles.wrapper, { [styles.small]: size === 'S' })}
      style={{ width: dimensions, height: dimensions }}
    >
      <svg className={styles.meter}>
        <circle
          className={classnames(styles.circle, styles.range)}
          cx='50%'
          cy='50%'
          stroke={getScaleColor(color)}
          r={radius}
          strokeDasharray={colorDasharry}
          strokeWidth={strokeWidth}
        />

        <circle
          className={classnames(styles.circle, styles.mask)}
          cx='50%'
          cy='50%'
          r={radius}
          strokeDasharray={maskDasharray}
          strokeWidth={strokeWidth + 1} // + 1 чтобы не выезжал цвет подложки
        />
      </svg>

      {isCurrentPeriod && expectedValue > 0 && finalValue > 0 && (
        <div
          className={styles.expectedValue}
          style={{ transform: `rotate(${rotateDegExpected}deg)` }}
        >
          <div
            className={styles.expectedValueAmount}
            style={{ transform: `translate(-50%, -10px) rotate(${rotateDegText}deg)` }}
          >
            <span
              data-tip={formatMessage({ id: 'kpiDashboard.expectedHarvest' })}
              data-for={tooltipId}
            >
              {formattedExpectedValue}
            </span>
          </div>
          <div className={styles.expectedValueDash}>
            <div className={styles.expectedValueDashBorder} style={{ height: strokeWidth + 8 }} />
          </div>
        </div>
      )}

      {limitedPercent > 0 && (
        <>
          <div className={styles.arrowPoint} />
          <div className={styles.arrow} style={{ transform: `rotate(${rotateDeg}deg)` }} />
        </>
      )}

      <div className={styles.valuesBox}>
        {finalValue && (
          <div className={styles.scaleValuesBox}>
            <span
              style={{ left: wrapperPadding / 2 }}
              className={classnames(styles.scaleValue, styles.scaleValueLeft)}
            >
              {initialValue}
            </span>
            <span
              style={{ right: wrapperPadding / 2 }}
              className={styles.scaleValue}
              data-tip={formatMessage({ id: 'kpiDashboard.harvestTarget' })}
              data-for={tooltipId}
            >
              {formattedFinalValue}
            </span>
          </div>
        )}
        <div
          data-tip={formatMessage({ id: 'kpiDashboard.harvestedFromTarget' })}
          data-for={tooltipId}
        >
          <div className={classnames(styles.percentBox, percentBoxClassName)}>
            {formattedPercent || '—'}
            <FormattedMessage id='cunits.mini.percent' />
          </div>
          <div className={classnames(styles.currentValueBox, currentValueBoxClassName)}>
            {`${currentValueText || formattedCurrentValue || '—'} `}
            <FormattedMessage id={`cunits.mini.${units}`} />
          </div>
        </div>
      </div>
      <ReactTooltip
        className={tooltipStyles.smallTooltip}
        id={tooltipId}
        effect='solid'
        event={isTouchDevice() ? 'click' : null}
        // html
        disable={withoutTooltip}
      />
    </div>
  );
});

Speedometer.propTypes = {
  intl: intlShape.isRequired,
  percent: PropTypes.number,
  radius: PropTypes.number,
  wrapperPadding: PropTypes.number,
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
  size: PropTypes.string,
  units: PropTypes.string,
  isCurrentPeriod: PropTypes.bool,
  initialValue: PropTypes.number,
  currentValue: PropTypes.number,
  finalValue: PropTypes.number,
  expectedValue: PropTypes.number,
  currentValueText: PropTypes.string,
  percentBoxClassName: PropTypes.string,
  currentValueBoxClassName: PropTypes.string,
  withoutTooltip: PropTypes.bool,
};

Speedometer.defaultProps = {
  percent: 0,
  radius: 90,
  wrapperPadding: 30,
  strokeWidth: 30,
  color: null,
  size: 'M',
  units: 'kilogram',
  isCurrentPeriod: false,
  initialValue: 0,
  currentValue: 0,
  finalValue: null,
  expectedValue: 0,
  currentValueText: null,
  percentBoxClassName: null,
  currentValueBoxClassName: null,
  withoutTooltip: false
};

export default injectIntl(Speedometer);
