import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import DefaultToggle from '../../DefaultToggle';
import LineChartIcon from '../../Icons/LineChartIcon';
import { ReactComponent as ChartCumulativeIcon } from './assets/chart_cumulative.svg';

class CumulativeToggle extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    className: PropTypes.string,
    isQualityCumulative: PropTypes.bool,
    cumulative: PropTypes.bool,
    cumulativeForQuality: PropTypes.bool,
    percentageForQuality: PropTypes.bool,
    onToggleChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: null,
    isQualityCumulative: false,
    cumulative: false,
    cumulativeForQuality: false,
    percentageForQuality: false,
  };

  handlerOnClick = () => {
    const { cumulative, onToggleChange } = this.props;

    onToggleChange({ cumulative: !cumulative });
  }

  handlerOnClickQuality = () => {
    const { cumulativeForQuality, onToggleChange } = this.props;

    onToggleChange({ cumulativeForQuality: !cumulativeForQuality });
  }

  render() {
    const {
      intl: { formatMessage }, className, cumulative, cumulativeForQuality, isQualityCumulative, percentageForQuality
    } = this.props;

    const isToggleOn = isQualityCumulative ? cumulativeForQuality : cumulative;

    return (
      <DefaultToggle
        className={className}
        toggleState={isToggleOn}
        onToggleChange={isQualityCumulative ? this.handlerOnClickQuality : this.handlerOnClick}
        leftIcon={<LineChartIcon />}
        rightIcon={<ChartCumulativeIcon />}
        leftTooltip={formatMessage({ id: 'harvestDashboard.showByPeriods' })}
        rightTooltip={formatMessage({ id: 'harvestDashboard.showCumulative' })}
        disabled={isQualityCumulative && percentageForQuality}
      />
    );
  }
}

export default injectIntl(CumulativeToggle);
