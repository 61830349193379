import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getLocation } from 'store/company/actions';

import MainRoutes from './MainRoutes';

const mapDispatchToProps = {
  getLocation,
};

const mapStateToProps = (state) => {
  const {
    company,
  } = state;

  const {
    location,
    locations,
  } = company;

  return {
    locations,
    organization: location,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainRoutes));
