import 'whatwg-fetch';

import { get, upperFirst } from 'lodash';

import { trackUserEvent } from '../analytics/actions';
import storageWrapper from '../../helpers/storageWrapper';
import { setToggleTableCellEditDialog, setToggleErrorDialog } from '../dialogs';
import initAxiosInstanse from '../../api/axios';

const safeLocalStorage = storageWrapper.get('localStorage');

export const REQUEST_DRILL_DOWN = 'REQUEST_DRILL_DOWN';
export const RECEIVE_DRILL_DOWN = 'RECEIVE_DRILL_DOWN';
// ------------------------------------
// Constants
// ------------------------------------

export const UPDATE_SELECTED_METRIC_ID = 'UPDATE_SELECTED_METRIC_ID';
export const SET_SHOW_HARVEST = 'SET_SHOW_HARVEST';

export const UPDATE_LEFT_WEEKLY_DATA = 'UPDATE_LEFT_WEEKLY_DATA';
export const UPDATE_RIGHT_WEEKLY_DATA = 'UPDATE_RIGHT_WEEKLY_DATA';

export const REQUEST_LEFT_METRICS = 'REQUEST_LEFT_METRICS';
export const RECEIVE_LEFT_METRICS = 'RECEIVE_LEFT_METRICS';
export const INVALIDATE_LEFT_METRICS = 'INVALIDATE_LEFT_METRICS';

export const REQUEST_RIGHT_METRICS = 'REQUEST_RIGHT_METRICS';
export const RECEIVE_RIGHT_METRICS = 'RECEIVE_RIGHT_METRICS';
export const INVALIDATE_RIGHT_METRICS = 'INVALIDATE_RIGHT_METRICS';

export const CLEAR_LEFT_TABLE_DATA = 'CLEAR_LEFT_TABLE_DATA';
export const RESET_LEFT_TABLE_DATA = 'RESET_LEFT_TABLE_DATA';
export const REQUEST_LEFT_WEEKLY_DATA = 'REQUEST_LEFT_WEEKLY_DATA';
export const RECEIVE_LEFT_WEEKLY_DATA = 'RECEIVE_LEFT_WEEKLY_DATA';
export const INVALIDATE_LEFT_WEEKLY_DATA = 'INVALIDATE_LEFT_WEEKLY_DATA';

export const CLEAR_RIGHT_TABLE_DATA = 'CLEAR_RIGHT_TABLE_DATA';
export const RESET_RIGHT_TABLE_DATA = 'RESET_RIGHT_TABLE_DATA';
export const REQUEST_RIGHT_WEEKLY_DATA = 'REQUEST_RIGHT_WEEKLY_DATA';
export const RECEIVE_RIGHT_WEEKLY_DATA = 'RECEIVE_RIGHT_WEEKLY_DATA';
export const INVALIDATE_RIGHT_WEEKLY_DATA = 'INVALIDATE_RIGHT_WEEKLY_DATA';

export const REQUEST_LEFT_DAILY_DATA = 'REQUEST_LEFT_DAILY_DATA';
export const RECEIVE_LEFT_DAILY_DATA = 'RECEIVE_LEFT_DAILY_DATA';
export const INVALIDATE_LEFT_DAILY_DATA = 'INVALIDATE_LEFT_DAILY_DATA';

export const REQUEST_RIGHT_DAILY_DATA = 'REQUEST_RIGHT_DAILY_DATA';
export const RECEIVE_RIGHT_DAILY_DATA = 'RECEIVE_RIGHT_DAILY_DATA';
export const INVALIDATE_RIGHT_DAILY_DATA = 'INVALIDATE_RIGHT_DAILY_DATA';


// ------------------------------------
// Actions
// ------------------------------------

/*  This is a thunk, meaning it is a function that immediately
 returns a function for lazy evaluation. It is incredibly useful for
 creating async actions, especially when combined with redux-thunk! */
export function requestDrillDown(payload) {
  return {
    type: REQUEST_DRILL_DOWN,
    payload,
    receivedAt: Date.now(),
  };
}

export function receiveDrillDown(payload) {
  return {
    type: RECEIVE_DRILL_DOWN,
    payload,
    receivedAt: Date.now(),
  };
}

export function setShowHarvest(showHarvest) {
  return {
    type: SET_SHOW_HARVEST,
    showHarvest,
    receivedAt: Date.now(),
  };
}

export function updateSelectedMetricId(id, name) {
  return {
    type: UPDATE_SELECTED_METRIC_ID,
    id,
    name,
    receivedAt: Date.now(),
  };
}

function receiveLeftMetrics(metrics) {
  return {
    type: RECEIVE_LEFT_METRICS,
    leftMetrics: metrics,
    receivedAt: Date.now()
  };
}

function requestLeftMetrics() {
  return {
    type: REQUEST_LEFT_METRICS,
  };
}

function receiveRightMetrics(metrics) {
  return {
    type: RECEIVE_RIGHT_METRICS,
    rightMetrics: metrics,
    receivedAt: Date.now()
  };
}

function requestRightMetrics() {
  return {
    type: REQUEST_RIGHT_METRICS,
  };
}

function receiveLeftWeeklyData(weeklyData = [], location) {
  return {
    type: RECEIVE_LEFT_WEEKLY_DATA,
    leftWeeklyData: weeklyData,
    location,
    receivedAt: Date.now()
  };
}

export function resetLeftTableData() {
  return {
    type: RESET_LEFT_TABLE_DATA,
  };
}

export function clearLeftTableData() {
  return {
    type: CLEAR_LEFT_TABLE_DATA,
  };
}

function requestLeftWeeklyData() {
  return {
    type: REQUEST_LEFT_WEEKLY_DATA,
  };
}

function receiveRightWeeklyData(weeklyData = [], location) {
  return {
    type: RECEIVE_RIGHT_WEEKLY_DATA,
    rightWeeklyData: weeklyData,
    location,
    receivedAt: Date.now()
  };
}

function requestRightWeeklyData() {
  return {
    type: REQUEST_RIGHT_WEEKLY_DATA,
  };
}

export function resetRightTableData() {
  return {
    type: RESET_RIGHT_TABLE_DATA,
  };
}

export function clearRightTableData() {
  return {
    type: CLEAR_RIGHT_TABLE_DATA,
  };
}

function receiveLeftDailyData(dailyData = [], date, location, metric) {
  return {
    type: RECEIVE_LEFT_DAILY_DATA,
    leftDailyData: dailyData,
    leftDailyDataDate: date,
    location,
    metric,
    receivedAt: Date.now()
  };
}

function requestLeftDailyData(date) {
  return {
    leftDailyDataDate: date,
    type: REQUEST_LEFT_DAILY_DATA,
  };
}

function receiveRightDailyData(dailyData = [], date, location, metric) {
  return {
    type: RECEIVE_RIGHT_DAILY_DATA,
    rightDailyData: dailyData,
    rightDailyDataDate: date,
    location,
    metric,
    receivedAt: Date.now()
  };
}

function requestRightDailyData(date) {
  return {
    rightDailyDataDate: date,
    type: REQUEST_RIGHT_DAILY_DATA,
  };
}


function updateLeftWeeklyData(weeklyData) {
  return {
    type: UPDATE_LEFT_WEEKLY_DATA,
    leftWeeklyData: weeklyData,
    receivedAt: Date.now()
  };
}


function updateRightWeeklyData(weeklyData) {
  return {
    type: UPDATE_RIGHT_WEEKLY_DATA,
    rightWeeklyData: weeklyData,
    receivedAt: Date.now()
  };
}


export function updateTableDailyData({
  plantingCycle,
  date,
  metric,
  dailyValues,
}) {
  return async (dispatch, getState) => {
    const state = getState();
    const { location } = state.company;

    try {
      let mode = null;

      if (state.company.leftPlantingCycleTables && state.company.leftPlantingCycleTables.id === plantingCycle.id) {
        mode = 'left';
      }

      if (state.company.rightPlantingCycleTables && state.company.rightPlantingCycleTables.id === plantingCycle.id) {
        mode = 'right';
      }

      if (mode) {
        const requestDailyData = mode === 'left' ? requestLeftDailyData : requestRightDailyData;
        const receiveDailyData = mode === 'left' ? receiveLeftDailyData : receiveRightDailyData;
        const updateWeeklyData = mode === 'left' ? updateLeftWeeklyData : updateRightWeeklyData;

        await dispatch(requestDailyData());

        if (date && dailyValues && plantingCycle) {
          const data = {
            metricId: metric.id,
            dailyValues: dailyValues.reduce((reducer, item) => {
              const result = reducer;
              if (item.metricValues[metric.id] !== '' && typeof item.metricValues[metric.id] !== 'undefined') {
                const value = item.metricValues[metric.id];

                const sample = 1.1;
                const delimeter = /^1(.+)1$/.exec(sample.toLocaleString())[1]; // FIXME

                const preparedValue = value.toString().replace('.', delimeter).replace(',', delimeter).replace(delimeter, '.');

                const parsedValue = +preparedValue;

                const isValid = !Number.isNaN(parsedValue) && Number.isFinite(parsedValue);

                if (isValid) {
                  result[item.periodStart] = parsedValue;
                }
              }
              return result;
            }, {})
          };

          const { id: plantingCycleId } = plantingCycle;
          const jwt = safeLocalStorage.getItem('jwt');
          const locale = safeLocalStorage.getItem('locale');
          const headers = new Headers();

          headers.append('Content-Type', 'application/json');
          headers.append('Authorization', `JWT ${jwt}`);
          headers.append('X-Lang', locale);

          const response = await fetch(`${window.API}/planting-cycles/${plantingCycleId}/table-daily-data`, {
            headers,
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
          });

          const { status } = response;
          if (Math.round(status / 100) === 2) {
            const dailyData = await response.json();

            if (dailyData) {
              dispatch(setToggleTableCellEditDialog({ open: false }));
              dispatch(updateWeeklyData(dailyData.filter(item => item.periodLength === 'week')));
              return dispatch(receiveDailyData(dailyData.filter(item => item.periodLength === 'day'), date, location, metric));
            }
          }

          const { text } = await response.json();
          dispatch(receiveDailyData([], date, location));
          return dispatch(setToggleErrorDialog(true, text));
        }

        dispatch(receiveLeftDailyData([], date, location));
        return dispatch(receiveRightDailyData([], date, location));
      }
    } catch (e) {
      dispatch(receiveLeftDailyData([], date, location));
      dispatch(receiveRightDailyData([], date, location));
      return dispatch(setToggleErrorDialog(true, e.toString()));
    }
    return null;
  };
}

export function getLeftMetrics(id) {
  return async (dispatch) => {
    try {
      await dispatch(requestLeftMetrics());

      if (id) {
        const jwt = safeLocalStorage.getItem('jwt');
        const locale = safeLocalStorage.getItem('locale');
        const response = await fetch(`${window.API}/planting-cycles/${id}/table-metrics`, {
          headers: {
            Authorization: `JWT ${jwt}`,
            'X-Lang': locale,
          },
          credentials: 'include',
        });
        const { status } = response;
        if (Math.round(status / 100) === 2) {
          const { data: metrics } = await response.json();

          return dispatch(receiveLeftMetrics(metrics));
        }
      }

      return dispatch(receiveLeftMetrics());
    } catch (e) {
      return dispatch(receiveLeftMetrics());
    }
  };
}

export function getRightMetrics(id) {
  return async (dispatch) => {
    try {
      await dispatch(requestRightMetrics());

      if (id) {
        const jwt = safeLocalStorage.getItem('jwt');
        const locale = safeLocalStorage.getItem('locale');
        const response = await fetch(`${window.API}/planting-cycles/${id}/table-metrics`, {
          headers: {
            Authorization: `JWT ${jwt}`,
            'X-Lang': locale,
          },
          credentials: 'include',
        });
        const { status } = response;
        if (Math.round(status / 100) === 2) {
          const { data: metrics } = await response.json();

          return dispatch(receiveRightMetrics(metrics));
        }
      }

      return dispatch(receiveRightMetrics());
    } catch (e) {
      return dispatch(receiveRightMetrics());
    }
  };
}

export function getLeftWeeklyData(id, location) {
  return async (dispatch, getState) => {
    try {
      await dispatch(requestLeftWeeklyData());

      const {
        router,
      } = getState();

      if (id) {
        const searchParams = get(router, 'location.search');
        const response = await initAxiosInstanse(searchParams).get(`planting-cycles/${id}/table-weekly-data`);
        const { status } = response;

        if (Math.round(status / 100) === 2) {
          return dispatch(receiveLeftWeeklyData(response.data, location));
        }
      }

      return dispatch(receiveLeftWeeklyData());
    } catch (e) {
      return dispatch(receiveLeftWeeklyData());
    }
  };
}

export function getRightWeeklyData(id, location) {
  return async (dispatch, getState) => {
    try {
      await dispatch(requestRightWeeklyData());

      if (id) {
        const {
          router,
        } = getState();

        const searchParams = get(router, 'location.search');
        const response = await initAxiosInstanse(searchParams).get(`planting-cycles/${id}/table-weekly-data`);
        const { status } = response;

        if (Math.round(status / 100) === 2) {
          return dispatch(receiveRightWeeklyData(response.data, location));
        }
      }

      return dispatch(receiveRightWeeklyData());
    } catch (e) {
      return dispatch(receiveRightWeeklyData());
    }
  };
}

export function getLeftDailyData(id, date, location) {
  return async (dispatch, getState) => {
    try {
      await dispatch(requestLeftDailyData(date));

      if (id) {
        const {
          router,
        } = getState();
        const searchParams = get(router, 'location.search');
        const response = await initAxiosInstanse(searchParams).get(`planting-cycles/${id}/table-daily-data?anyDateOfWeek=${date.format('YYYY-MM-DD')}`);
        const { status } = response;

        if (Math.round(status / 100) === 2) {
          return dispatch(receiveLeftDailyData(response.data, date, location));
        }
      }

      return dispatch(receiveLeftDailyData());
    } catch (e) {
      return dispatch(receiveLeftDailyData());
    }
  };
}

export function getRightDailyData(id, date, location) {
  return async (dispatch, getState) => {
    try {
      await dispatch(requestRightDailyData(date));

      if (id) {
        const {
          router,
        } = getState();
        const searchParams = get(router, 'location.search');
        const response = await initAxiosInstanse(searchParams).get(`planting-cycles/${id}/table-daily-data?anyDateOfWeek=${date.format('YYYY-MM-DD')}`);
        const { status } = response;

        if (Math.round(status / 100) === 2) {
          return dispatch(receiveRightDailyData(response.data, date, location));
        }
      }

      return dispatch(receiveRightDailyData());
    } catch (e) {
      return dispatch(receiveRightDailyData());
    }
  };
}

export function trackSelectClimateGraphMetric(id, name) {
  const event = 'Planting Cycle - Select Climate Graph Metric';

  /**
   * Здесь не подходит startCase или обычный capitalize с map, т.к. встречаются
   * кейсы например с "Average CO2" или "Average Temp 24/7", которые при этом
   * будут преобразовываться неправильно (разрывы в случае startCase и Co2 в случае capitalize)
   */
  const metricName = get(name, 'en')
    .split(' ')
    .map(upperFirst)
    .join(' ');

  const data = {
    Metric: metricName,
    'Metric ID': id,
  };

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event, data }));
  };
}

export function trackClickClimateTableWeek(status) {
  const event = `Planting Cycle - ${status === 'close' ? 'Close' : 'Open'} Climate Table Week`;

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event }));
  };
}

export function trackClickShowHarvest(value) {
  const event = 'Planting Cycle - Click Climate Show Harvest';

  const data = {
    'Toggle State': value,
  };

  return async (dispatch) => {
    await dispatch(trackUserEvent({ event, data }));
  };
}
