import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import SelectBoxFilter from 'components/SelectBoxFilter';
import useQuery from 'hooks/useQuery';

import styles from './BreakdownFilter.module.css';
import Select from '../../../Select';

const BreakdownFilter = ({
  intl,
  mode,
  currentBreakdown,
  breakdownOptions,
  onFiltersChange,
  noTitle,
  classNameButton,
  title,
  useQueryParams,
  defaultAsUndefined,
  capitalizeTitle,
  selectComponent,
  isMobile
}) => {
  const { formatMessage } = intl;

  const [breakdownQuery, setBreakdownQuery] = useQuery('breakdown', currentBreakdown || 'compartment');

  const handlerBreakdownChange = useCallback((event) => {
    const value = defaultAsUndefined && event.value === 'compartment' ? undefined : event.value;
    if (breakdownOptions) {
      setBreakdownQuery(value);
    }
    onFiltersChange({
      breakdown: value,
    });
  }, [onFiltersChange, setBreakdownQuery, defaultAsUndefined, breakdownOptions]);

  const defaultBreakdownOptions = [
    { label: formatMessage({ id: 'dashboards.compartments' }), value: 'compartment' },
    { label: formatMessage({ id: 'dashboards.varieties' }), value: 'varietyWeight' },
    { label: formatMessage({ id: 'dashboards.types' }), value: 'fruitClass' },
  ];

  if (mode === 'dashboards:labor') {
    defaultBreakdownOptions.push({ label: formatMessage({ id: 'dashboards.workTypes' }), value: 'workType' });
  }

  const options = {
    value: useQueryParams ? breakdownQuery : currentBreakdown,
    options: breakdownOptions || defaultBreakdownOptions,
    onChange: handlerBreakdownChange,
    classNameWrapper: styles.selectboxFilterWrapper,
    classNameButton,
    capitalizeTitle,
    selectComponent,
    isMobile,
    customPlaceholder: formatMessage({ id: 'dashboards.groupedByTitle' }),
  };

  if (!noTitle) {
    options.title = title || formatMessage({ id: 'dashboards.groupedByTitle' });
  }

  return (
    <SelectBoxFilter {...options} />
  );
};

BreakdownFilter.propTypes = {
  intl: intlShape.isRequired,
  currentBreakdown: PropTypes.string,
  mode: PropTypes.oneOf(['dashboards:labor', 'dashboards:energy']),
  onFiltersChange: PropTypes.func,
  noTitle: PropTypes.bool,
  classNameButton: PropTypes.string,
  breakdownOptions: PropTypes.array,
  title: PropTypes.string,
  useQueryParams: PropTypes.bool,
  defaultAsUndefined: PropTypes.bool,
  capitalizeTitle: PropTypes.bool,
  selectComponent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  isMobile: PropTypes.bool
};

BreakdownFilter.defaultProps = {
  mode: null,
  currentBreakdown: 'compartment',
  onFiltersChange: null,
  noTitle: false,
  classNameButton: null,
  breakdownOptions: null,
  title: null,
  useQueryParams: false,
  defaultAsUndefined: true,
  capitalizeTitle: false,
  selectComponent: Select,
  isMobile: false
};

export default BreakdownFilter;
