import {
  filter, find, get, reverse,
} from 'lodash';

import { getSortedRows } from 'helpers/getSortedRows';


/* eslint-disable import/prefer-default-export */
export const getSortedTree = ({
  sorting,
  rows = [],
  intl,
  workTypes,
  compartments,
  fruitClasses,
  varieties,
  organizationSlug,
  productGroups,
  allPlantingCycles,
  currentBreakdown,
  currentSpecies,
  currentFruitClassCode,
  currentVarietyId,
  isPlantingCycleDatesAsTimestamp,
}) => {
  if (rows.length === 0) {
    return rows;
  }

  const sortingType = get(sorting, 'type');
  const sortingDirection = get(sorting, 'direction');

  // Чтобы Total всегда был в конце
  const totalRow = find(rows, { type: 'total' });
  const filteredRows = filter(rows, row => (get(row, 'type') !== 'total'));

  const sortWithDirection = array => (sortingDirection === 'down' ? reverse(array) : array);

  const sortedFirstLevel = getSortedRows({
    sortingType,
    filteredRows,
    intl,
    workTypes,
    compartments,
    fruitClasses,
    varieties,
    organizationSlug,
    productGroups,
    allPlantingCycles,
    currentBreakdown,
    currentSpecies,
    currentFruitClassCode,
    currentVarietyId,
    isPlantingCycleDatesAsTimestamp,
  });

  const sortedWithDirectionFirstLevel = sortWithDirection(sortedFirstLevel);

  const sortedRows = sortedWithDirectionFirstLevel.map(row => ({
    ...row,

    children: row.children ? getSortedTree({
      sortingType,
      rows: row.children,
      intl,
      compartments,
      fruitClasses,
      varieties,
      organizationSlug,
      productGroups,
      allPlantingCycles,
      currentBreakdown,
      currentSpecies,
      currentFruitClassCode,
      currentVarietyId,
      isPlantingCycleDatesAsTimestamp,
    }) : undefined,
  }));

  const result = [...sortedRows];

  if (totalRow) {
    result.push(totalRow);
  }

  return result;
};
/* eslint-enable import/prefer-default-export */
