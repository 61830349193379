import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import styles from './TextLabel.module.css';


export default class TextLabel extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.any
  };

  static defaultProps = {
    icon: null
  }

  render() {
    const { text, icon } = this.props;

    return (
      <div className={styles.textLabel}>{icon ? (<span className={styles.icon}>{icon}</span>) : null} {text}</div>
    );
  }
}
