import debug from 'debug';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import React, { Component } from 'react';

import {
  get, first, zip, head, drop, isEmpty, isString, isNaN, isNil, dropRight,
} from 'lodash';

import animateScrollTo from 'animated-scroll-to';

import toNumber from '../../../../helpers/toNumber';
import getMinMaxDates from '../../../../helpers/getMinMaxDates';
import normalizeCycle from '../../../../helpers/normalizeCycle';
import getDatePeriodRange from '../../../../helpers/getDatePeriodRange';

import BigButton from '../../../BigButton';
import HarvestTable from '../HarvestTable';


import styles from './HarvestForm.module.css';

const log = debug('harvest:edit');

export default class HarvestForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    // formSyncErrors: PropTypes.object,
    loadedValues: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    clearHarvestData: PropTypes.func.isRequired,
    showNotificationWithTimeout: PropTypes.func.isRequired,
    removeNotification: PropTypes.func.isRequired,
    cycle: PropTypes.object,
    history: PropTypes.object.isRequired,
    harvestCategories: PropTypes.array,
    periodType: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    pageToGoBack: PropTypes.string,
    isUpdateHarvestDataByCategoriesFetching: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    harvestCategories: null,
    cycle: null,
    loadedValues: null,
    pageToGoBack: null,
    formValues: null,
    // formSyncErrors: null,
  };

  state = {
    values: [],
    focus: null,
    isLoaded: false,
    isSubmitted: false,
  };

  tableDataEnter = React.createRef();

  validations = {};

  componentDidMount() {
    const {
      date,
      periodType,
    } = this.props;

    if (date && periodType) {
      this.getTableData();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      date: nextDate,
      periodType: nextPeriodType,
    } = this.props;

    const {
      date: oldDate,
      periodType: oldPeriodType,
    } = prevProps;

    if (
      oldDate.format('YYYY-MM-DD') !== nextDate.format('YYYY-MM-DD') ||
      oldPeriodType !== nextPeriodType
    ) {
      this.getTableData();
    }
  }

  scrollToElement = (element) => {
    if (element) {
      const { y } = element.getBoundingClientRect();
      const desiredOffset = (y + window.scrollY) - 64 - 12 - 32;
      const offset = desiredOffset > 0 ? desiredOffset : 0;
      animateScrollTo(offset);
    }
  };

  getTableData = () => {
    const {
      date,
      periodType,
      cycle,
      intl,
      loadedValues,
      harvestCategories,
    } = this.props;

    const {
      locale,
      formatMessage,
    } = intl;

    this.setState({ isSubmitted: false });

    const currentCycle = normalizeCycle(cycle, locale);

    const {
      plantingDate,
      endDate,
    } = currentCycle;

    // Отвечает за количество строк (с какой по какую даты)
    const { minDate, maxDate } = getMinMaxDates({ startDate: plantingDate, endDate });

    const loadedRows = get(loadedValues, 'rows', [])
      .map(item => ({
        date: item.date,
        values: harvestCategories.map((harvestCategory) => {
          const finded = item.values.find(({ discriminator }) => +discriminator === +harvestCategory.id);

          return {
            discriminator: harvestCategory.id,
            value: finded ? finded.value : 0,
            includedInTotal: get(harvestCategory, 'includedInTotal'),
          };
        }),
      }));

    const { startDate: startOfPeriodDate, endDate: endOfPeriodDate } = getDatePeriodRange({
      date,
      periodType,
      minDate,
      maxDate,
      formatMessage,
    });


    let datesDiff = 0;

    if (periodType === 'whole') {
      datesDiff = endOfPeriodDate.diff(startOfPeriodDate, 'days');
    } else {
      datesDiff = endOfPeriodDate.clone().endOf(periodType).diff(startOfPeriodDate.clone().startOf(periodType), 'days');
    }

    const values = [];

    for (let i = 0; i <= datesDiff; i++) {
      const startDateForPayload = periodType === 'whole' ? startOfPeriodDate.clone() : startOfPeriodDate.clone().startOf(periodType);

      const payload = {
        date: startDateForPayload.add(i, 'days').format('YYYY-MM-DD'),
        values: harvestCategories.map(harvestCategory => ({
          discriminator: harvestCategory.id,
          value: null,
          includedInTotal: get(harvestCategory, 'includedInTotal'),
        })),
      };

      const findedLoadedRow = loadedRows.find(item => item.date === payload.date);

      if (findedLoadedRow) {
        payload.values.forEach((value) => {
          const temp = value;
          const { discriminator } = value;

          const findedValue = findedLoadedRow.values.find(x => +x.discriminator === +discriminator);

          if (findedValue) {
            temp.value = findedValue.value;
          }
        });
      }

      values.push(payload);
    }

    values.push({
      date: 'total',
      values: harvestCategories.map(harvestCategory => ({
        discriminator: harvestCategory.id,
        value: null,
        includedInTotal: get(harvestCategory, 'includedInTotal'),
      })),
    });

    this.setState({ values });
  };

  handleChangeTableValues = async (changesArray) => {
    const tableInstance = this.tableDataEnter.current.hotInstance;
    const oldTableData = tableInstance.getData();

    const newTableData = [...oldTableData];

    if (tableInstance) {
      /* eslint-disable no-restricted-syntax, no-unused-vars */
      for (const [row, column, oldValue, newValue] of changesArray) {
        // Т.к. у нас prop отличается от номера col (берётся по data атрибуту колонки)
        const colNum = tableInstance.propToCol(column);

        if (newTableData[row]) {
          newTableData[row][colNum] = newValue === '' ? null : newValue;
        }
      }
      /* eslint-enable no-restricted-syntax, no-unused-vars */

      return newTableData;
    }

    return null;
  };

  convertTableArray = (tableData, isBackendFormat = false) => tableData.map((row) => {
    const { harvestCategories } = this.props;

    const date = head(row);
    const valuesList = dropRight(drop(row));

    const values = valuesList
      .map((listItem, index) => {
        const discriminator = harvestCategories[index]?.id;
        const trimmedValue = isString(listItem) ? listItem.replace(/\s+/g, '') : listItem;

        const numberValue = trimmedValue;
        // Чтобы если человек ввёл текст, он отобразился с ошибкой
        const realValue = isNaN(numberValue) ? trimmedValue : numberValue;

        return {
          value: isBackendFormat ? numberValue : realValue,
          discriminator,
        };
      });

    if (isBackendFormat) {
      return {
        date,
        values,
      };
    }

    return {
      date,
      values,
    };
  });

  convertRegistrations = (startDate, endDate, tableResults) => {
    const rows = this.convertTableArray(tableResults, true);

    const filteredRows = rows
      .filter(filteredRow => !isEmpty(filteredRow.values))
      .map(row => ({
        ...row,
        values: row.values
          .map((item) => {
            const result = {
              ...item,
            };

            if (isNil(item.value)) {
              delete result.value;
            }

            return result;
          }),
      }));

    return {
      rows: filteredRows,
    };
  };

  // По-сути нужно для тримминга значений на onPaste
  handleTableChange = async (changesArray) => {
    changesArray.forEach((change) => {
      /**
       * Вырезаем спецсимволы перед изменением ячейки (чтобы не вставлялись переносы строк и прочее подобное)
       * Затем вырезаем пробелы
       * И меняем запятые на точки (иначе при парсинге через Intl.NumberFormat получим NaN)
       */
      const pastedValue = change[3];
      const trimmedValue = isString(pastedValue) ?
        pastedValue
          .replace(/^\s+|\s+$/g, '')
          .replace(/\s/g, '')
          .replace(/,/g, '.')
        :
        pastedValue;

      /**
       * Меняем сам передаваемый массив changesArray (по ссылке), а не возвращаем новый, т.к.
       * handsontable по-другому не умеет, нужно именно менять передаваемый массив изменений
       */
      // eslint-disable-next-line no-param-reassign
      change[3] = trimmedValue;
    });

    const tableData = await this.handleChangeTableValues(changesArray);

    const formattedTableData = this.convertTableArray(tableData);

    // const tableInstance = this.tableDataEnter.current.hotInstance;

    // tableInstance.validateCells();

    this.setState({
      values: formattedTableData,
    });
  };

  // TODO: Подключить обработку вставки к таблице
  /**
   * Операция вставки таблиц в форму ввода урожая
   *
   * Главное
   * Определить, что первая колонка содержит даты или не содержит
   * Определить, что первая строка содержит названия колонок
   *
   * @param event
   */
  handlerPaste = (event) => {
    const {
      harvestCategories,
      date,
      periodType,
      cycle,
      intl,
      showNotificationWithTimeout,
      removeNotification,
      formValues,
    } = this.props;

    const { focus, isLoaded } = this.state;

    const notifyId = `notifications.pasteHarvestCategoriesData.${Date.now()}`;

    const {
      locale,
      formatMessage,
    } = intl;

    const self = this;

    const text = (event.clipboardData || window.clipboardData).getData('text');

    const rows = text.split(/\r\n|\n|\r/);

    if (rows.length && rows[rows.length - 1] === '') {
      rows.pop();
    }

    const urlParams = new URLSearchParams(window.location.search);

    if (window.DEV || urlParams.has('debug')) {
      log('Pasted text:', JSON.stringify(text));
      log('Parsed rows:', rows);
    }

    if (!isLoaded) {
      const currentCycle = normalizeCycle(cycle, locale);

      const {
        startDate,
        endDate,
      } = currentCycle;

      const { minDate, maxDate } = getMinMaxDates({ startDate, endDate });

      const { startDate: startOfPeriodDate, endDate: endOfPeriodDate } = getDatePeriodRange({
        date,
        periodType,
        minDate,
        maxDate,
        formatMessage,
      });


      let datesDiff = 0;

      if (periodType === 'whole') {
        datesDiff = endOfPeriodDate.diff(startOfPeriodDate, 'days');
      } else {
        datesDiff = endOfPeriodDate.clone().endOf(periodType).diff(startOfPeriodDate.clone().startOf(periodType), 'days');
      }

      const formDates = [];

      for (let i = 0; i <= datesDiff; i++) {
        const startDateForPayload = periodType === 'whole' ? startOfPeriodDate.clone() : startOfPeriodDate.clone().startOf(periodType);

        formDates.push(startDateForPayload.add(i, 'days').format('YYYY-MM-DD'));
      }

      const focusDiscriminator = get(focus, 'discriminator');
      const focusDate = get(focus, 'date');
      const focusIndex = focusDiscriminator ? harvestCategories.findIndex(({ id }) => +id === +focusDiscriminator) : 0;

      const initialTable = rows
        .map((item) => {
          const cells = item.split('\t');

          return cells
            .map((cell) => {
              if (cell.length) {
                return toNumber(cell);
              }

              return cell;
            });
        })
        .filter(x => x);

      // Триггер для транспонирования таблицы
      const isTranspositionTable = initialTable.length > 0 && initialTable.length < 4 && initialTable[0].length > 4;

      /**
       * Если количество столбцов сильно больше количества строк, то транспонируем таблицу,
       * т.к. значит в исходном файле было обратное форматирование (категории это строки, даты это столбцы)
       */
      const table = isTranspositionTable ? zip(...initialTable) : initialTable;

      const firstRow = first(table);
      const isFirstRowNumber = firstRow.every(item => !Number.isNaN(Number(item)));

      const isFirstRowHeader = !isFirstRowNumber;

      const tableBody = table.filter((item, index) => (isFirstRowHeader && index !== 0) || !isFirstRowHeader);

      if (tableBody && tableBody.length > 2) {
        showNotificationWithTimeout({
          id: notifyId,
          messageId: 'notifications.working',
          position: 'leftDown',
          iconType: 'info',
          notificationType: 'withSmallWidth',
          timeout: 2000,
        });
      }

      const isFirstColumnDate = tableBody.every((item) => {
        const firstItem = first(item);

        return moment(firstItem, 'YYYY-MM-DD', true).isValid();
      });

      const isFirstColumnNumber = tableBody.every((item) => {
        const firstItem = first(item);

        return !Number.isNaN(Number(firstItem));
      });

      let tableDates = [];

      if (isFirstColumnNumber) {
        const focusOffset = focus ? formDates.findIndex(tableDate => tableDate === focusDate) : 0;

        for (let i = 0; i <= datesDiff - focusOffset; i++) {
          const startDateForPayload = periodType === 'whole' ? startOfPeriodDate.clone() : startOfPeriodDate.clone().startOf(periodType);

          tableDates.push({
            date: startDateForPayload
              .add(focusOffset, 'days')
              .add(i, 'days')
              .format('YYYY-MM-DD'),
            values: tableBody[i],
          });
        }
      } else if (isFirstColumnDate) {
        showNotificationWithTimeout({
          id: `notifications.pasteHarvestCategoriesDataError.${Date.now()}`,
          messageId: 'notifications.pasteHarvestCategoriesDataError',
          position: 'leftDown',
          iconType: 'error',
          notificationType: 'withActionWide',
        });
      } else {
        showNotificationWithTimeout({
          id: `notifications.pasteHarvestCategoriesDataError.${Date.now()}`,
          messageId: 'notifications.pasteHarvestCategoriesDataError',
          position: 'leftDown',
          iconType: 'error',
          notificationType: 'withActionWide',
        });
      }

      if (focus) {
        tableDates = tableDates.filter(({ date: tableDate }) => moment(tableDate, 'YYYY-MM-DD').isSameOrAfter(moment(focusDate, 'YYYY-MM-DD')));
      }

      const pastedValues = {
        rows: tableDates.map(({ date: tableDate, values: tableValues }) => {
          let preparedValues = [];

          if (tableValues) {
            preparedValues = tableValues.map((tableValue, i) => {
              const category = harvestCategories[i];

              if (category) {
                const parsedTableValue = Number(tableValue);

                return {
                  discriminator: category.id,
                  value: Number.isNaN(parsedTableValue) ? null : parsedTableValue,
                  includedInTotal: get(category, 'includedInTotal'),
                };
              }
              return null;
            })
              .filter(item => !!item);
          }

          return {
            date: tableDate,
            values: preparedValues,
          };
        })
          .filter(({ values: filteredValues }) => filteredValues.some(({ value }) => value !== null && value !== undefined && value.toString().length > 0)),
      };


      this.setState({
        isLoaded: true,
      }, () => {
        setTimeout(() => {
          const parentRows = get(formValues, 'rows', [])
            .map(({ date: parentDate, values: parentValues }) => {
              const pastedRow = pastedValues.rows.find(({ date: pastedDate }) => pastedDate === parentDate);
              const pastedRowValues = pastedRow ? get(pastedRow, 'values', []) : [];
              const parentDateMoment = moment(parentDate, 'YYYY-MM-DD');
              const isEditedParentDate = !parentDateMoment.isBefore(minDate) && !parentDateMoment.isAfter(maxDate);

              const resultValues = parentValues.map(({ discriminator, value }, k) => {
                const overwritePastedValue = pastedRowValues.find((item, i) => focusIndex + i === k);

                return {
                  discriminator,
                  value: overwritePastedValue && isEditedParentDate ? overwritePastedValue.value : value,
                };
              });

              return {
                date: parentDate,
                values: resultValues,
              };
            });

          this.setState({ values: parentRows });
          removeNotification(notifyId);

          self.setState({
            isLoaded: false,
          });
        }, 0);
      });
    }

    event.stopPropagation();
    event.preventDefault();
  };

  handlerSubmit = () => {
    const {
      intl,
      cycle,
      handleSubmit,
      // formSyncErrors,
    } = this.props;

    this.setState({ isSubmitted: true }, () => {
      const tableInstance = this.tableDataEnter.current.hotInstance;

      setTimeout(() => {
        tableInstance.validateCells((isValid) => {
          // const firstErrorCell = document.querySelector('.htInvalid');
          const errorCells = Object.keys(this.validations)
            .filter(key => this.validations[key])
            .sort((a, b) => a.localeCompare(b, undefined, {
              numeric: true,
              sensitivity: 'base'
            }));

          const firstErrorCell = first(errorCells);

          if (firstErrorCell) {
            const invalidRow = first(firstErrorCell.split('-'));

            if (!isNaN(invalidRow)) {
              tableInstance.scrollViewportTo(+invalidRow);
              // this.scrollToElement(firstErrorCell, document.querySelector('#root'));
            }
          }

          if (!isValid) {
            return;
          }

          const {
            locale,
          } = intl;

          const currentCycle = normalizeCycle(cycle, locale);

          const {
            startDate,
            endDate,
          } = currentCycle;

          const tableResults = tableInstance.getData();
          // Удаляем строку с значениями Total
          const tableResultsWithoutTotal = dropRight(tableResults);
          const options = this.convertRegistrations(startDate, endDate, tableResultsWithoutTotal);

          if (handleSubmit) {
            handleSubmit(options);
          }
        });
      }, 0);
    });
  };

  handleValidChange = ({ isValid, row, prop }) => {
    this.validations[`${row}-${prop}`] = isValid;
  };

  renderTable = () => {
    const {
      intl,
      date,
      cycle,
      periodType,
      harvestCategories,
    } = this.props;

    const {
      values,
      isSubmitted,
    } = this.state;

    if (!date || !periodType) {
      return null;
    }

    const {
      locale,
    } = intl;

    const currentCycle = normalizeCycle(cycle, locale);

    const {
      plantingDate,
      endDate,
      species,
    } = currentCycle;

    const isLettuce = species === 'lettuce';

    // Отвечает за disable состояние ячеек
    const { minDate, maxDate } = getMinMaxDates({ startDate: plantingDate, endDate });

    return (
      <React.Fragment key={`${periodType}-${date}`}>
        <HarvestTable
          tableData={values}
          periodType={periodType}
          date={date}
          cycle={cycle}
          isLettuce={isLettuce}
          isSubmitted={isSubmitted}
          minDate={minDate}
          maxDate={maxDate}
          onTableChange={this.handleTableChange}
          tableRef={this.tableDataEnter}
          harvestCategories={harvestCategories}
          onValidChange={this.handleValidChange}
        />
      </React.Fragment>
    );
  };

  render() {
    const {
      date,
      periodType,
      intl,
      history,
      pageToGoBack,
      isUpdateHarvestDataByCategoriesFetching,
      clearHarvestData,
    } = this.props;

    if (!date || !periodType) {
      return null;
    }

    const { formatMessage } = intl;

    const renderedTable = this.renderTable();

    return (
      <>
        {renderedTable}


        <div className={styles.controls}>
          <BigButton
            className={styles.button}
            title={formatMessage({ id: 'harvest.cancelButton' })}
            type='button'
            theme='light'
            onClick={() => {
              clearHarvestData();
              history.push(pageToGoBack);
            }}
          />
          <BigButton
            isLoading={isUpdateHarvestDataByCategoriesFetching}
            className={styles.button}
            title={formatMessage({ id: 'harvest.saveButton' })}
            type='button'
            theme='dark'
            onClick={this.handlerSubmit}
          />
        </div>
      </>
    );
  }
}

