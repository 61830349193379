import moment from 'moment';

import { LinePoint } from '../../components/Graphs/components/LinesChart/components/ChartLines/components/ChartLine';

export type Margin = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export type DateType = moment.Moment;

export type AvailableIdTypes = number | string | '#' | null;

export type IdMixin = { id: AvailableIdTypes; };

export type Relationships = {
  plantingCycles?: {
    data: Array<{ id: number, type: 'PlantingCycle' }>
  };
  subNodes?: {
    data: Array<{ id: number, type: 'SubNode' }>
  };
  greenhouseType?: {
    data: Array<{ id: number, type: 'GreenhouseType' }>
  };
  location?: {
    data: Array<{ id: number, type: 'Location' }>
  };
  compartment?: {
    data: Array<{ id: number, type: 'Compartment' }>
  };
  variety?: {
    data: Array<{ id: number, type: 'Variety' }>
  };
  fruitClass?: {
    data: Array<{ id: string, type: 'FruitClass' }>
  };
};

export type MultilingualStringDefinition = {
  en?: string;
  ru?: string;
  nl?: string;
  fr?: string;
  'zh-cn'?: string;
}

export type SpeciesName = string;
export type EquipmentName = string;
export type MetricCategoryName = string;

export type Species = {
  id: string | number | '#';
  type?: 'Species';
  code?: string;
  attributes: {
    name: SpeciesName
  }
}

export type SpeciesApi = {
  id: number;
  code: string;
}

export type Equipment = {
  id: string | '#';
  type?: 'Equipment';
  attributes: {
    name: EquipmentName
  }
}

export type MetricCategory = {
  id: string | '#';
  type?: 'MetricCategory';
  attributes: {
    name: MetricCategoryName
  }
}

export type LocationAttr = {
  timezone: string;
}

export type Location = {
  type: 'Location';
  attributes: LocationAttr;
} & IdMixin;

export type CompartmentAttr = {
  name: string;
  lightPower?: number;
  greenhouseKind?: string;
  artificialLights?: Array<any>;
  floorArea?: number;
  location?: { id: number, type: 'Location' };
};

export type Compartment = {
  id: number | '#';
  type?: 'Compartment';
  attributes: CompartmentAttr;
  relationships?: Relationships;
};

export type PlantingCycleAttr = {
  effectivePlantingRange?: {
    start: DateType;
    end: DateType;
  };
  endDate?: DateType;
  grafted?: boolean;
  plantDensity?: number;
  plantingArea?: number;
  plantingDate?: DateType;
  species?: Species;
  startDate?: DateType;
  targetWeight?: number | null;
  name?: string;
};

export type PlantingCycle = {
  id: number | '#';
  type?: 'PlantingCycle';
  attributes: PlantingCycleAttr;
  relationships?: Relationships;
};

export type VarietyAttr = {
  fruitClass: string,
  mlName: MultilingualStringDefinition,
  name: string,
  species: SpeciesName
};

export type Variety = {
  id: number | '#';
  type?: 'Variety';
  attributes: VarietyAttr;
};

export type FruitClassAttr = {
  code: string;
  iid: number,
  mlName: MultilingualStringDefinition,
  name: string,
  species: SpeciesName
};

export type FruitClass = {
  id: string | '#';
  type?: 'FruitClass';
  attributes: FruitClassAttr;
};

export type SubNodeTypes = 'unknown' |
  'climateZone' |
  'irrigationValve' |
  'drain' |
  'electricityMeter' |
  'gasMeter' |
  'waterMeter' |
  'wirelessCensorsSubsystem';

export type SubNodeAttr = {
  name: string;
  type?: SubNodeTypes;
  location?: { id: number, type: 'Location' };
  compartment?: { id: number, type: 'Compartment' };
}

export type SubNode = {
  id: number | '#';
  type?: 'SubNode';
  attributes: SubNodeAttr;
  relationships?: Relationships;
};

export type MetricCategories = 'dailyIndicators';

export type MetricAttr = {
  subNode?: SubNode;
  compartment?: Compartment;
  location?:Location;
  calculated: boolean;
  category: MetricCategories;
  categoryId: number;
  cunit: string;
  description: MultilingualStringDefinition;
  displayScale: number;
  manualInputAllowed: boolean;
  name: MultilingualStringDefinition;
  referenceRangeMax?: number;
  referenceRangeMin?: number;
  supportsDrillDown: boolean;
  validationRangeMax: number;
  validationRangeMin: number;
};

export type Metric = {
  type?: 'Metric';
  attributes: MetricAttr;
} & IdMixin;

export type TreeNodeIdTypes = 'g' | 'c' | 'e' | string;

export type TreeNodeTypes = 'Root' | 'Tree' | 'LocationAverages' | 'Location' | 'CompartmentAverages' |
  'Compartment' | 'Species' | 'SubNode' | 'Metric' | 'PlantingCycle';

export type TreeNodePathComponents = TreeNodeIdTypes | number;

export type TreeNodeEntity = {
  id: TreeNodeIdTypes;
  type: TreeNodeTypes;
};

export type TreeNode = {
  entity: TreeNodeEntity;
  children: Array<TreeNode>;
  node: Array<TreeNodePathComponents>;
  childrenCategorized?:boolean;
  categoryCode?:string;
};

export enum NodeComponents {
  TreeType = 0,
  LocationId = 1,
  CompartmentId = 2,
  SpeciesId = 2,
  SubNodeId = 3,
  PlantingCycleId = 3,
  MetricId = 4,
  Node= 2,
  SubNode = 3
}

export type PresetMetricScale = {
  name: string | null;
  rightAxis: boolean;
  invisible: boolean;
  range: {
    start: number;
    end: number;
  } | null
};

export type Ranges = 'custom' | 'today' | 'last2Days' | 'last7Days' | 'last30Days' | 'last90Days';

export type PresetRange = {
  xRange: Ranges,
  xRangeEnd: string | null, // YYYY-MM-DDTHH:mm:ss
  xRangeLengthInMins: number | null,
};

export type MetricDefAttr = {
  selected: boolean;
  available: boolean;
  scale: string | null;
  color: string | null;
};

export type MetricDef = {
  node: Array<TreeNodePathComponents>;
} & MetricDefAttr;


export type BaseMetricGroupEntity = IdMixin & {
  attributes: {
    name: string;
  }
}

/*
export type TypedMetricGroupInput<FT, ST> = {
  firstNode: FT;
  secondNode: ST;
};

export type CompartmentMetricGroupInput = TypedMetricGroupInput<
  Compartment,
  SubNode
  >;

export type PlantingCycleMetricGroupInput = TypedMetricGroupInput<
  Species,
  PlantingCycle
  >;

export type MetricGroupInput =
  | CompartmentMetricGroupInput
  | PlantingCycleMetricGroupInput;
*/

export type MetricGroupInput = {
  firstNode: Compartment | Species;
  secondNode: SubNode | PlantingCycle;
};

export type PresetAttr = {
  id: number | null;
  description: string;
  name: string;
  type: string; // TODO: Declare types
  scales: Array<PresetMetricScale>;
  presetMetrics: Array<MetricDef>;
} & PresetRange;

export type Preset = {
  preset: PresetAttr & IdMixin;
  compartments: Array<CompartmentAttr & IdMixin>;
  metrics: Array<MetricAttr & IdMixin>;
  locations: Array<LocationAttr & IdMixin>;
  subNodes: Array<SubNodeAttr & IdMixin>;
};

export type IncidentTimeRange = {
  start: DateType;
  end: DateType;
};

export type IncidentSeverity = 'high' | 'middle' | 'low' | 'potential';

export type IncidentDiagramStateItem = {
  node: Array<TreeNodePathComponents>;
  position: number;
  rightAxis: boolean;
  selected: boolean;
};

export type Incident = {
  id: number | null;
  timeRange: IncidentTimeRange;
  title: string;
  severity: IncidentSeverity;
  status?: string;
  diagramState?:Array<IncidentDiagramStateItem>;
};

export type IncidentIssueImprovementChange = {
  when: string;
  how: string;
  howMuch: string;
};

export type IncidentIssueImprovement = {
  what: string;
  dependsOn: Array<string>;
  changes: Array<IncidentIssueImprovementChange>;
};

export type GraphPresetListItem = {
  description: string;
  name: string;
  type: string; // TODO: Declare types
} & IdMixin;

export type GraphsPresetRange = {
  xRange: Ranges,
  xRangeEnd: string | null, // YYYY-MM-DDTHH:mm:ss
  xRangeLengthInMins: number | null,
};

export type DataPoints = {
  [key: number]: number;
}

export type MetricData = {
  node: Array<TreeNodePathComponents>;
  path: any; // TODO: Tree path type
  dataPoints: DataPoints;
  available: boolean;
}

export type ScaleRange = {
  start: number;
  end: number;
};

export type Line = {
  isSingleGroupCheck?: boolean;
  key: string;
  title: string;
  description: string | null;
  rightAxis: boolean;
  scale: PresetMetricScale;
  color: string;
  data: Array<LinePoint>;
  node?:Array<TreeNodePathComponents>;
  selected: boolean;
  displayScale: number;
  cunit: string;
  range: {
    start: number;
    end: number;
  }
}

export type LocalizationUtilOptions = {
  formatMessage: Function,
  locale: string,
  allCompartments: Array<Compartment>,
  allSubNodes: Array<SubNode>,
  allVarieties: Array<Variety>,
  allFruitClasses: Array<FruitClass>,
  allPlantingCycles: Array<PlantingCycle>
};

export type Feedback = {
  authorName: string;
  comment: string;
  id: number;
  score: number;
  whenCreated: string;
};
