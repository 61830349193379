import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Select from '../Select';

import styles from './SelectBoxFilter.module.css';

const SelectBoxFilter = React.memo(({
  onChange,
  value,
  options,

  disabled,
  title,
  rightPopupAlign,
  optionsFooter,

  className,
  classNameWrapper,
  classNameButton,
  classNamePopup,
  classNameTitle,
  capitalizeTitle,
  selectComponent,
  isMobile,
  customPlaceholder
}) => {
  const ParentComponent = selectComponent;
  if (isMobile) {
    return (
      <ParentComponent
        classNameButton={classNameButton}
        classNamePopup={classNamePopup}
        disabled={disabled}
        options={options}
        value={value}
        onChange={onChange}
        labelPath='label'
        valuePath='value'
        theme='transparent'
        size='medium'
        closeOnChange
        rightPopupAlign={rightPopupAlign}
        optionsFooter={optionsFooter}
        placeholder={customPlaceholder}
        capitalizeTitle={capitalizeTitle}
      />
    );
  }
  return (
    <div className={classnames(styles.filterWrapper, classNameWrapper)}>
      {title && (
        <div className={classnames(styles.filterTitle, classNameTitle, {
          [styles.capitalizeTitle]: capitalizeTitle
        })}
        >{title}
        </div>
      )}
      <div className={classnames(styles.selectWrapper, className)}>
        <ParentComponent
          classNameButton={classNameButton}
          classNamePopup={classNamePopup}
          disabled={disabled}
          options={options}
          value={value}
          onChange={onChange}
          labelPath='label'
          valuePath='value'
          theme='transparent'
          size='medium'
          closeOnChange
          rightPopupAlign={rightPopupAlign}
          optionsFooter={optionsFooter}
          customPlaceholder={customPlaceholder}
          capitalizeTitle={capitalizeTitle}
        />
      </div>
    </div>
  );
});

SelectBoxFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  options: PropTypes.array.isRequired,

  disabled: PropTypes.bool,
  title: PropTypes.string,
  rightPopupAlign: PropTypes.bool,
  optionsFooter: PropTypes.object,

  className: PropTypes.string,
  classNameWrapper: PropTypes.string,
  classNameButton: PropTypes.string,
  classNamePopup: PropTypes.string,
  classNameTitle: PropTypes.string,
  capitalizeTitle: PropTypes.bool,
  selectComponent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  isMobile: PropTypes.bool,
  customPlaceholder: PropTypes.string,
};

SelectBoxFilter.defaultProps = {
  disabled: false,
  title: null,
  rightPopupAlign: false,
  optionsFooter: null,

  className: null,
  classNameWrapper: null,
  classNameButton: null,
  classNamePopup: null,
  classNameTitle: null,
  capitalizeTitle: false,
  selectComponent: Select,
  isMobile: false,
  customPlaceholder: undefined
};

export default SelectBoxFilter;
