import { handleActions } from 'redux-actions';

import { cloneDeep } from 'lodash';

import {
  receiveClimateIncidentsTree,
  requestClimateIncidentsTypes,
  receiveClimateIncidentsTypes,
  setTreeExpandNodes,
  requestClimateIncidentsTree,
} from 'store/climateInsightsTimeline/actions';

import findPatchedTreeEntity from 'components/Incidents/utils/findPatchedTreeEntity';

const initialState = {
  timelineData: null,
  incidentsTypes: null,
  expanded: [],
  loadedNodes: [],
  isIncidentsTypesFetching: false,
};

export default handleActions({
  [requestClimateIncidentsTree](state, action) {
    return {
      ...state,
      loadedNodes: action.payload?.loadedNode ? [...state?.loadedNodes, action.payload?.loadedNode] : [...state?.loadedNodes],
      isIncidentsTypesFetching: true,
    };
  },

  [receiveClimateIncidentsTree](state, data) {
    const nodePath = data?.payload?.node?.path.join('_');
    const newLoaded = [...state?.loadedNodes]?.filter(nodeItem => nodeItem !== nodePath);

    let newState = {
      ...state,
      timelineData: data?.payload,
      loadedNodes: newLoaded,
    };

    if (data?.payload?.patchTree) {
      const patchedNode = findPatchedTreeEntity(state.timelineData.node, data.payload.node.path);
      patchedNode.range = data.payload.range;
      patchedNode.complete = data.payload.complete;
      if (data.payload.node.children) {
        patchedNode.children = [...data.payload.node.children];
      }
      return {
        ...state,
        timelineData: cloneDeep(state.timelineData),
        loadedNodes: newLoaded,
        isIncidentsTypesFetching: false
      };
    }

    if (data?.payload?.resetExpand) {
      newState = {
        ...newState,
        expanded: []
      };
    }


    return {
      ...newState,
      isIncidentsTypesFetching: false
    };
  },
  [requestClimateIncidentsTypes](state) {
    return {
      ...state,
      isIncidentsTypesFetching: true,
    };
  },
  [receiveClimateIncidentsTypes](state, data) {
    return {
      ...state,
      isIncidentsTypesFetching: false,
      incidentsTypes: data?.payload
    };
  },
  [setTreeExpandNodes](state, data) {
    const {
      expanded
    } = state;
    const key = data?.payload;
    const expandedIndex = expanded.findIndex(item => item === key);
    if (expandedIndex !== -1) {
      expanded.splice(expandedIndex, 1);
      return {
        ...state,
        expanded: [...expanded]
      };
    }
    return {
      ...state,
      expanded: [...expanded, key]
    };
  }
}, initialState);
