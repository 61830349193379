import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import VisibilityOnIcon from '../Icons/VisibilityOnIcon';
import VisibilityOffIcon from '../Icons/VisibilityOffIcon';

import styles from './PasswordField.module.css';

class PasswordField extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]).isRequired,
    className: PropTypes.string,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
  };

  static defaultProps = {
    className: null,
    error: null,
  };

  state = {
    showPassword: false,
  };

  handlerShowPassword = () => this.setState(prevState => ({
    showPassword: !prevState.showPassword
  }));

  render() {
    const {
      value, placeholder, onChange, className, error
    } = this.props;
    const { showPassword } = this.state;

    return (
      <div className={classnames(styles.fieldWrapper, className)}>
        <div className={styles.inputWrapper}>
          <input
            className={classnames(styles.password, { [styles.withError]: error })}
            name='password'
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
          <div
            className={styles.eyeIconWrapper}
            onClick={this.handlerShowPassword}
            role='button'
            tabIndex={0}
          >
            {showPassword ?
              <VisibilityOffIcon className={styles.eyeIcon} />
              :
              <VisibilityOnIcon className={styles.eyeIcon} />}
          </div>
        </div>
        {error &&
          <div className={styles.passwordError}>{error}</div>}
      </div>
    );
  }
}

export default PasswordField;
