import { createSelector } from 'reselect';
import { get, find, max } from 'lodash';

import { getRouteQueryParams } from '../router/selectors';

export const getPointsArray = (object, path) => {
  const points = get(object, path || 'dataPoints');

  return points ? Object.keys(points).map(key => points[key]) : [];
};

export const getPlanFactUnits = state => get(state, 'planFact.units');
export const getPlanFactGraph = state => get(state, 'planFact.graph');
export const getPlanFactReport = state => get(state, 'planFact.report');
export const getIsPlanFactFetching = state => get(state, 'planFact.isPlanFactFetching');
export const getPlanFactReportSorting = state => get(state, 'planFact.planFactReportSorting');
export const getPlanFactV2Report = state => get(state, 'planFact.v2Report');
export const getIsPlanFactV2Fetching = state => get(state, 'planFact.isPlanFactV2Fetching');
export const getPlanFactCategories = state => get(state, 'planFact.v2Report.planFact.categories');

export const getPeriodFromRoute = createSelector(
  [getRouteQueryParams],
  query => get(query, 'period'),
);

export const getPlanFactPoints = createSelector(
  [getPlanFactGraph],
  graph => get(graph, 'points'),
);

export const getTotalHarvestPoints = createSelector(
  [getPlanFactPoints],
  points => find(points, { key: 'totalHarvest' }),
);

export const getPlannedHarvestPoints = createSelector(
  [getPlanFactPoints],
  points => find(points, { key: 'plannedHarvest' }),
);

export const getSecondGradeHarvestPoints = createSelector(
  [getPlanFactPoints],
  points => find(points, { key: 'secondGradeHarvest' }),
);

// TODO: вынести в механику графика
export const getPlanFactMaxValue = createSelector(
  getTotalHarvestPoints,
  getPlannedHarvestPoints,
  getSecondGradeHarvestPoints,
  (total, planned, secondGrade) => {
    const totalArr = getPointsArray(total);
    const plannedArr = getPointsArray(planned);
    const secondGradeArr = getPointsArray(secondGrade);

    const valuesArray = [
      ...totalArr,
      ...plannedArr,
      ...secondGradeArr,
    ];

    return max(valuesArray);
  }
);
