import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';

import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import moment from 'moment';
import { isEqual } from 'lodash';

import { getProductTypeName } from 'helpers/getVarietyName';
import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';
import { getPlantingCycleDates } from 'helpers/harvestForecast';
import getDateFormat from 'helpers/getDateFormat';

import DefaultPlate from 'components/DefaultPlate';
import Typography from 'components/Typography';
import SelectBoxFilter from 'components/SelectBoxFilter';
import BigButton from 'components/BigButton';
import CloseIcon from 'components/Icons/Close';
import classNames from 'classnames';
import HarvestForecastWeatherIndicator from '../HarvestForecastWeatherIndicator';

import HarvestForecastChartBlock from './HarvestForecastChartBlock';
import HarvestForecastHarvestChart from '../HarvestForecastHarvestChart';
import HarvestForecastWeatherChart from '../HarvestForecastWeatherChart';
// import PanelSettings from '../PanelSettings';

import { ReactComponent as EmptyIcon } from './assets/empty.svg';

import styles from './HarvestForecastAssistantPanel.module.css';

const CHART_MARGINS = {
  top: 16, right: 8, bottom: 24, left: 48
};

const ASSISTANT_LIGHT_CHARTS = [
  'sunLightIntegral',
  'artificialLightIntegral',
  'sunAndArtificialLightSum',
];

const ASSISTANT_CHARTS = [
  'temperature',
  'fruitWeight',
  'plantingDensity',
  'fruitsPerTruss',
  'floweringSpeed',
  'ripeningTime'
];

function getItemName(
  plantingCycle,
  varieties,
  fruitClasses,
  compartments,
  intl
) {
  const plantingCycleDates = getPlantingCycleDates(plantingCycle, intl);
  const currentCompartment = compartments.find(coItem => coItem.id === plantingCycle?.compartmentRef?.id);
  const compartmentName = currentCompartment?.attributes?.name || undefined;
  const targetWeight = plantingCycle?.targetWeight;
  const currentVariety = varieties.find(vrItem => vrItem.id === plantingCycle?.varietyRef?.id);
  const currentFruitClass = fruitClasses.find(fcItem => fcItem.id === plantingCycle?.fruitClassRef?.id);
  const currentProductGroupName = getProductTypeName({
    intl,
    targetWeight: targetWeight ? numbersFormatting(numbersRounding(targetWeight, 'fixed', 0)) : null,
    variety: currentVariety,
    fruitClass: currentFruitClass,
    withSpecies: false,
    withFruitClass: true,
  });
  return `${[compartmentName, currentProductGroupName].join(' • ')}, ${plantingCycleDates}`;
}


const HarvestForecastAssistantPanel = ({
  intl,
  forecastId,
  versionId,
  plantingCycleId,
  assistant,
  isAssistantFetching,
  varieties,
  fruitClasses,
  compartments,
  requestAssistantForecast,
  onClose,
  currentProductData,
  opened,
  options,
  // setAssistantPanelSettings
}) => {
  const { formatMessage } = intl;

  const problem = assistant?.problem;

  const [period, setPeriod] = useState(14);
  const [productData, setProductData] = useState(currentProductData);

  useEffect(() => {
    if (opened && forecastId && !isEqual(currentProductData, productData)) {
      setProductData(currentProductData);

      requestAssistantForecast({
        versionId,
        forecastId,
        history: period,
        manualForecast: currentProductData
      });
    }
  }, [opened, forecastId, period, requestAssistantForecast, currentProductData, setProductData, productData, versionId]);

  const periodsOptions = useMemo(() => [{
    label: formatMessage({ id: 'forecast.assistant.sevenDays' }),
    value: 7
  }, {
    label: formatMessage({ id: 'forecast.assistant.fourteenDays' }),
    value: 14
  }, {
    label: formatMessage({ id: 'forecast.assistant.twentyEightDays' }),
    value: 28
  }, {
    label: formatMessage({ id: 'forecast.assistant.entireCycle' }),
    value: 0
  }], [formatMessage]);

  const handlerPeriodChange = useCallback((option) => {
    const newPeriod = option.value || 0;
    setPeriod(newPeriod);

    requestAssistantForecast({
      versionId,
      forecastId,
      history: newPeriod,
      manualForecast: productData
    });
  }, [requestAssistantForecast, setPeriod, productData, forecastId, versionId]);

  const weatherIndicatorItems = useMemo(() => {
    if (!assistant) {
      return [];
    }

    const {
      data: {
        weather
      }
    } = assistant;

    const AVAILABLE_ICON_IDS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 16];

    const dataKeys = Object.keys(weather.points || []);

    return dataKeys.map((item) => {
      const date = moment.utc(new Date((+item) * 60000));
      const iconType = AVAILABLE_ICON_IDS.includes(weather.points[item]) ? weather.points[item] : 0;
      return {
        date: date.format(getDateFormat('llll')),
        iconType,
        title: formatMessage({ id: `forecast.weatherTypes.${iconType}` }),
      };
    });
  }, [assistant, formatMessage]);

  const itemName = useMemo(() => (assistant?.plantingCycle ? getItemName(
    assistant?.plantingCycle,
    varieties,
    fruitClasses,
    compartments,
    intl
  ) : null), [
    assistant,
    varieties,
    fruitClasses,
    compartments,
    intl
  ]);

  // FEATURE: Show historic period feature, temporary hided
  // const handleChangeOptions = useCallback((value) => {
  //   setAssistantPanelSettings(value);
  // }, [setAssistantPanelSettings]);

  const defaultErrorMessage = formatMessage({ id: 'forecast.problems.server_error' });

  const additionalCharts = assistant?.additionalCharts || [];

  return (
    <div className={styles.panel}>
      <BigButton
        className={styles.closeButton}
        onClick={onClose}
        icon={<CloseIcon />}
        theme='transparent'
        bigIcon
      />
      <div className={styles.header}>
        <div className={styles.title}>{formatMessage({ id: 'forecast.assistant.title' })}</div>
        <div className={styles.plantingCycle}>{plantingCycleId ? itemName : ''}</div>
      </div>
      <div className={classNames(styles.body, {
        [styles.bodyEmpty]: !plantingCycleId
      })}
      >
        {plantingCycleId ? (
          <>
            {problem && (
              <DefaultPlate type={problem.level} className={styles.problemPlate}>
                <Typography variant='body1'>
                  {formatMessage({ id: `forecast.problems.${problem.problemClass}`, defaultMessage: defaultErrorMessage })}
                </Typography>
              </DefaultPlate>
            )}
            <div className={styles.filters}>
              <SelectBoxFilter
                value={period}
                options={periodsOptions}
                onChange={handlerPeriodChange}
                title={formatMessage({ id: 'forecast.assistant.periodSelect' })}
              />
              {/* FEATURE: Show historic period feature, temporary hided
                <PanelSettings
                onChange={handleChangeOptions}
                options={options}
              /> */}
            </div>
            <HarvestForecastHarvestChart
              assistant={assistant}
              isAssistantFetching={isAssistantFetching}
              showHistoricalPeriod={options.showHistoricalPeriod}
              chartMargins={CHART_MARGINS}
            />
            <HarvestForecastWeatherChart
              assistant={assistant}
              isAssistantFetching={isAssistantFetching}
              showHistoricalPeriod={options.showHistoricalPeriod}
              chartMargins={CHART_MARGINS}
            />
            <HarvestForecastWeatherIndicator items={weatherIndicatorItems} />
            {ASSISTANT_LIGHT_CHARTS.map(dataKey => (
              <HarvestForecastChartBlock
                dataKey={dataKey}
                assistant={assistant}
                isAssistantFetching={isAssistantFetching}
                cropTill
                cropEmptyValues
                showHistoricalPeriod={options.showHistoricalPeriod}
                chartMargins={CHART_MARGINS}
              />
            ))}
            {ASSISTANT_CHARTS.map((dataKey) => {
              if (!assistant?.data?.hasOwnProperty(dataKey) ||
                !assistant?.data?.[dataKey]?.points ||
                Object.keys(assistant?.data?.[dataKey]?.points).length === 0
              ) {
                return null;
              }
              return (
                <HarvestForecastChartBlock
                  dataKey={dataKey}
                  assistant={assistant}
                  isAssistantFetching={isAssistantFetching}
                  cropEmptyValues
                  showHistoricalPeriod={options.showHistoricalPeriod}
                  chartMargins={CHART_MARGINS}
                />
              );
            })}
            {additionalCharts.map((additionalChartItem) => {
              if (!additionalChartItem?.data?.points || additionalChartItem?.data?.points?.length === 0) {
                return null;
              }

              return (
                <HarvestForecastChartBlock
                  chartData={additionalChartItem}
                  assistant={assistant}
                  isAssistantFetching={isAssistantFetching}
                  cropEmptyValues
                  showHistoricalPeriod={options.showHistoricalPeriod}
                  chartMargins={CHART_MARGINS}
                />
              );
            })}
          </>
        ) : (
          <div className={styles.emptyState}>
            <EmptyIcon />
            <div className={styles.emptyHeader}>{formatMessage({ id: 'forecast.assistant.emptyHeader' })}</div>
            <div className={styles.emptyDescription}>{formatMessage({ id: 'forecast.assistant.emptyDescription' })}</div>
          </div>
        )}
      </div>
    </div>
  );
};

HarvestForecastAssistantPanel.propTypes = {
  intl: intlShape.isRequired,
  isAssistantFetching: PropTypes.bool,
  assistant: PropTypes.object,
  versionId: PropTypes.number,
  forecastId: PropTypes.number.isRequired,
  plantingCycleId: PropTypes.number,
  currentProductData: PropTypes.object,
  requestAssistantForecast: PropTypes.func.isRequired,
  varieties: PropTypes.array.isRequired,
  fruitClasses: PropTypes.array.isRequired,
  compartments: PropTypes.array.isRequired,
  onClose: PropTypes.array.isRequired,
  opened: PropTypes.bool.isRequired,
  options: PropTypes.object,
  // setAssistantPanelSettings: PropTypes.func.isRequired,
};

HarvestForecastAssistantPanel.defaultProps = {
  isAssistantFetching: false,
  assistant: null,
  versionId: null,
  plantingCycleId: null,
  currentProductData: null,
  options: {
    showHistoricalPeriod: true
  }
};

export default HarvestForecastAssistantPanel;
