import { find, get } from 'lodash';

const checkSpecies = ({
  isAllSpecies,
  species,
  varieties,
  fruitClasses,
  currentFruitClassCode,
  currentVarietyId,
  currentSpecies,
}) => {
  let isHasVegetables = false;
  let isHasLettuce = false;

  if (isAllSpecies) {
    isHasVegetables = species ? species.some(x => x !== 'lettuce') : false;
    isHasLettuce = species ? species.some(x => x === 'lettuce') : false;
  } else if (currentFruitClassCode) {
    const currentFruitClass = find(fruitClasses, { id: currentFruitClassCode });

    const speciesValue = get(currentFruitClass, 'attributes.species');

    isHasVegetables = speciesValue !== 'lettuce';
    isHasLettuce = speciesValue === 'lettuce';
  } else if (currentVarietyId) {
    const currentVariety = find(varieties, { id: currentVarietyId });

    const speciesValue = get(currentVariety, 'attributes.species');

    isHasVegetables = speciesValue !== 'lettuce';
    isHasLettuce = speciesValue === 'lettuce';
  } else if (currentSpecies) {
    isHasVegetables = currentSpecies !== 'lettuce';
    isHasLettuce = currentSpecies === 'lettuce';
  }

  return {
    isHasLettuce,
    isHasVegetables,
  };
};

export default checkSpecies;
