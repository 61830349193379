import React, { memo } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './Paper.module.css';

const Paper = ({ className, children }) => <div className={classnames(styles.paper, className)}>{children}</div>;

Paper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
};

Paper.defaultProps = {
  children: null,
  className: null,
};

export default memo(Paper);
