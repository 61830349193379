import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getCurrentLocation,
  getDigitalTwinAccess,
  getIsEssentialPricePlan,
} from 'store/company/selectors';

import {
  getSimulationsList,
  getSupportedVarieties,
  getCurrentSimulation,
  getCurrentSimulationEdited,
  getIsSimulationCalculated,
  getIsSimulationFetching,
} from 'store/digitalTwin/selectors';

import {
  requestSimulationsList,
  requestNewSimulationParams,
  requestSimulationById,
  requestDeleteSimulationById,
  requestSimulationCalculate,
  requestSupportedVarieties,
  requestSaveSimulation,
  requestSaveSimulationByIdWithoutParams,
  resetSimulationEditedData,
  setSimulationEditedData,
  requestSimulationDatesChange,
} from 'store/digitalTwin/actions';

import DigitalTwin from '../components/DigitalTwin';

const mapDispatchToProps = {
  requestSimulationsList,
  requestNewSimulationParams,
  requestSimulationById,
  requestDeleteSimulationById,
  requestSimulationCalculate,
  requestSupportedVarieties,
  requestSaveSimulation,
  requestSaveSimulationByIdWithoutParams,
  resetSimulationEditedData,
  setSimulationEditedData,
  requestSimulationDatesChange,
};

const mapStateToProps = (state, props) => ({
  ...props,

  location: getCurrentLocation(state),
  simulationsList: getSimulationsList(state),
  supportedVarieties: getSupportedVarieties(state),
  currentSimulation: getCurrentSimulation(state),
  currentSimulationEdited: getCurrentSimulationEdited(state),
  isDataFetching: getIsSimulationCalculated(state) || getIsSimulationFetching(state),
  digitalTwinAccess: getDigitalTwinAccess(state),
  isEssentialPricePlan: getIsEssentialPricePlan(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalTwin)));
