import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { showNotificationWithTimeout } from 'store/notificationCenter/actions';

import CropsSelectBlock from './CropsSelectBlock';


const mapDispatchToProps = { showNotificationWithTimeout };

const mapStateToProps = (state, props) => ({
  ...props,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CropsSelectBlock));
