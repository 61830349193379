import React, { useState } from 'react';
import classnames from 'classnames';
// @ts-ignore
import { FormattedMessage } from 'react-intl';

import {
  IncidentIssueImprovementChange,
  IncidentIssueImprovement, Metric,
} from 'store/graphs/types';

import WarningIcon from 'components/Icons/WarningIcon';

import { get } from 'lodash';
import styles from './AbnormalityIncidentIssue.module.css';

export type IncidentIssueImprovementChangeProp = {
  change: IncidentIssueImprovementChange;
  changeKey: string;
};

const ImprovementChange = ({
  change,
  changeKey,
}: IncidentIssueImprovementChangeProp) => {
  const { when, how, howMuch } = change;
  return (
    // eslint-disable-next-line react/no-array-index-key
    <table
      className={classnames(styles.secondaryTable, styles.spaced)}
      key={`change-${changeKey}`}
    >
      <tbody>
        <tr>
          <th className={styles.secondaryHeaderCell}>
            <div className={styles.secondaryHeaderCellContent}>
              <FormattedMessage id='graphIncidents.when' />
            </div>
          </th>
          <td className={styles.secondaryCell}>
            <div className={styles.secondaryCellContent}>{when || '—'}</div>
          </td>
        </tr>

        <tr>
          <th className={styles.secondaryHeaderCell}>
            <div className={styles.secondaryHeaderCellContent}>
              <FormattedMessage id='graphIncidents.how' />
            </div>
          </th>
          <td className={styles.secondaryCell}>
            <div className={styles.secondaryCellContent}>{how || '—'}</div>
          </td>
        </tr>

        <tr>
          <th className={styles.secondaryHeaderCell}>
            <div className={styles.secondaryHeaderCellContent}>
              <FormattedMessage id='graphIncidents.howMuch' />
            </div>
          </th>
          <td className={styles.secondaryCell}>
            <div className={styles.secondaryCellContent}>{howMuch || '—'}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export type IncidentIssueImprovementProp = {
  improvement: IncidentIssueImprovement;
};
const Improvement = ({
  improvement,
}: IncidentIssueImprovementProp) => {
  const { what, dependsOn, changes } = improvement;
  return (
    <div className={styles.improvement}>
      <table className={styles.secondaryTable}>
        <tbody>
          {what && (
            <tr>
              <th className={styles.secondaryHeaderCell}>
                <div className={styles.secondaryHeaderCellContent}>
                  <FormattedMessage id='graphIncidents.what' />
                </div>
              </th>
              <td className={styles.secondaryCell}>
                <div className={styles.secondaryCellContent}>{what}</div>
              </td>
            </tr>
          )}
          {dependsOn && dependsOn.length ? (
            <tr>
              <th className={styles.secondaryHeaderCell}>
                <div className={styles.secondaryHeaderCellContent}>
                  <FormattedMessage id='graphIncidents.dependsOn' />
                </div>
              </th>
              <td className={styles.secondaryCell}>
                <div className={styles.secondaryCellContent}>
                  {dependsOn.join(', ')}
                </div>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>

      {changes && changes.length && (
        <div className={classnames(styles.changes)}>
          {changes.map((change, k) => (
            <ImprovementChange
              change={change}
              changeKey={`${k}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export type AbnormalityIncidentIssueProp = {
  issue: any;
  locale: string;
  allGraphMetrics: Array<Metric>;
}

const AbnormalityIncidentIssue = ({
  issue,
  locale,
  allGraphMetrics
}: AbnormalityIncidentIssueProp) => {
  const [isOpen, setIsOpen] = useState(false);

  const isExpert = true;

  const improvements = get(issue, 'recommendation.payload.improvements', [])
    .map((improvement: any) => {
      const improvementResult = allGraphMetrics.find(metric => metric.id === get(improvement, 'subject.metricId'));
      const changes = get(improvement, 'changes', [])
        .map((change: any) => ({
          when: get(change, `when.${locale}`) || get(change, 'when.en'),
          how: get(change, `typeOfChange.${locale}`) || get(change, 'typeOfChange.en'),
          howMuch: get(change, `howMuch.${locale}`) || get(change, 'howMuch.en'),
        }));
      const dependsOnMetrics = get(improvement, 'dependsOnMetrics', [])
        .map((dependsOnMetric: any) => {
          const { metricId, text } = dependsOnMetric;
          if (metricId) {
            const metric = allGraphMetrics.find(item => item.id === metricId);
            return `${metricId ? `m${metricId} - ` : ''}${get(metric, `attributes.name.${locale}`) || get(metric, 'attributes.name.en')}`;
          }
          return get(text, locale) || get(text, 'en');
        });
      return {
        what: get(improvementResult, `attributes.name.${locale}`) || get(improvementResult, 'attributes.name.en'),
        dependsOn: dependsOnMetrics,
        changes,
      };
    });

  const issueInternalComment = get(issue, 'internalComment');
  const problemTypeName = get(issue, `problemType.payload.name.${locale}`) || get(issue, 'problemType.payload.name.en') || <FormattedMessage id='graphIncidents.notDefined' />;
  const appliesIf = get(issue, 'recommendation.payload.appliesIf');
  const recommendationComment = get(issue, `recommendation.payload.comment.${locale}`) || get(issue, 'recommendation.payload.comment.en');
  const problemTypeInternalComment = get(issue, 'problemType.payload.internalComment');
  const recommendationInternalComment = get(issue, 'recommendation.payload.internalComment');

  const isShowInternalDetails = !!issueInternalComment || !!problemTypeInternalComment || !!appliesIf || !!recommendationInternalComment;

  return (
    <div className={styles.issue}>
      <div className={styles.issueTitle}>
        <WarningIcon className={styles.issueIcon} />
        {problemTypeName}
      </div>
      {recommendationComment && (
        <div className={styles.issueDescription}>
          {recommendationComment}
        </div>
      )}
      <div className={classnames(styles.issueContent, { [styles.open]: isOpen })}>
        {isExpert && isShowInternalDetails && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <FormattedMessage id='graphIncidents.expertInternalDetails' />
            </div>
            <div className={styles.sectionContent}>
              <table className={styles.primaryTable}>
                <tbody>
                  {issueInternalComment && (
                  <tr>
                    <th className={styles.problemTypeHeaderCell}>
                      <div className={styles.problemTypeHeaderCellContent}>
                        <FormattedMessage id='graphIncidents.problemComment' />
                      </div>
                    </th>
                    <td className={styles.problemTypeCell}>
                      <div className={styles.problemTypeCellContent}>
                        {issueInternalComment}
                      </div>
                    </td>
                  </tr>
                  )}
                  {problemTypeInternalComment && (
                  <tr>
                    <th className={styles.problemTypeHeaderCell}>
                      <div className={styles.problemTypeHeaderCellContent}>
                        <FormattedMessage id='graphIncidents.problemTypeComment' />
                      </div>
                    </th>
                    <td className={styles.problemTypeCell}>
                      <div className={styles.problemTypeCellContent}>
                        {problemTypeInternalComment}
                      </div>
                    </td>
                  </tr>
                  )}
                  {appliesIf && (
                  <tr>
                    <th className={styles.problemTypeHeaderCell}>
                      <div className={styles.problemTypeHeaderCellContent}>
                        <FormattedMessage id='graphIncidents.contextCondition' />
                      </div>
                    </th>
                    <td className={styles.problemTypeCell}>
                      <div className={styles.problemTypeCellContent}>
                        {appliesIf}
                      </div>
                    </td>
                  </tr>
                  )}
                  {recommendationInternalComment && (
                  <tr>
                    <th className={styles.problemTypeHeaderCell}>
                      <div className={styles.problemTypeHeaderCellContent}>
                        <FormattedMessage id='graphIncidents.recommendationComment' />
                      </div>
                    </th>
                    <td className={styles.problemTypeCell}>
                      <div className={styles.problemTypeCellContent}>
                        {recommendationInternalComment}
                      </div>
                    </td>
                  </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <FormattedMessage id='graphIncidents.expectedSystemsBehavior' />
          </div>
          <div>
            {improvements?.length ? (
              improvements.map((item:any) => <Improvement improvement={item} />)
            ) : null}
            {!improvements || !improvements.length ? (
              <div className={styles.improvementsEmptyState}>
                <FormattedMessage id='graphIncidents.emptyStateCauses' />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.issueActions}>
        {!isOpen && <button type='button' onClick={() => setIsOpen(true)} className={styles.issueAction}><FormattedMessage id='graphIncidents.showDetails' /></button>}
        {isOpen && <button type='button' onClick={() => setIsOpen(false)} className={styles.issueAction}><FormattedMessage id='graphIncidents.closeDetails' /></button>}
      </div>
    </div>
  );
};

export default AbnormalityIncidentIssue;
