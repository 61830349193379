import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import WeekPhoto from '../WeekPhoto';

import styles from './WeeksPhotos.module.css';


export default class WeeksPhotos extends Component {
  static propTypes = {
    weeks: PropTypes.array.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    isAddPhotoAvailable: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onClick: null,
    onDelete: null,
    className: null,
  };

  componentDidMount() {
    this.scrollToEnd();
  }

  componentDidUpdate(oldProps) {
    const { weeks: oldWeeklyData } = oldProps;
    const { weeks: nextWeeklyData } = this.props;

    const oldFilteredWeeklyData = oldWeeklyData.map(({ id, week, year }) => ({ id, week, year }));

    const nextFilteredWeeklyData = nextWeeklyData.map(({ id, week, year }) => ({ id, week, year }));

    if (!isEqual(oldFilteredWeeklyData, nextFilteredWeeklyData)) {
      this.scrollToEnd();
    }
  }

  scrollToEnd = () => {
    const {
      weeks,
    } = this.props;

    setTimeout(() => {
      if (this.photos) {
        const componentWidth = weeks.length * 200;

        this.photos.scrollLeft = componentWidth || 0;
      }
    }, 100);
  };

  handlerClick = (options) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(options);
    }
  };

  handlerDelete = (options) => {
    const { onDelete } = this.props;

    if (onDelete) {
      onDelete(options);
    }
  };

  render() {
    const {
      weeks,
      className,
      isAddPhotoAvailable,
    } = this.props;

    return (
      <div className={classnames(styles.weeksPhotos, className, 'week-photos')} ref={(element) => { this.photos = element; }}>
        {weeks.map(week => (
          <WeekPhoto
            week={week}
            onDelete={this.handlerDelete}
            onClick={this.handlerClick}
            isAddPhotoAvailable={isAddPhotoAvailable}
          />
        ))}
      </div>
    );
  }
}
