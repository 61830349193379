import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import queryString from 'query-string';

import styles from './CropCompareSuccessMsg.module.css';

const CropCompareSuccessMsg = ({
  intl,
  organizationSlug,
  tabName,
  cyclesToCompare,
}) => {
  const { formatMessage } = intl;

  // Чтобы при переходе на страницу сравнения появлялась кнопка назад в bench, если открываем из него
  const fromPage = tabName;
  const compareHrefParams = cyclesToCompare?.length > 0 ? cyclesToCompare.join('_') : undefined;
  const stringifiedParams = queryString.stringify({
    productId: compareHrefParams,
    fromPage,
  });
  const hrefParams = stringifiedParams ? `?${stringifiedParams}` : '';

  return (
    <span className={styles.linkWrapper}>
      <span>{formatMessage({ id: 'benchmarking.cropsHasBeenAdded' })}</span>
      <a href={`/${organizationSlug}/crops/compare${hrefParams}`} className={styles.link}>
        {formatMessage({ id: 'benchmarking.cropsComparison' })}
      </a>
      .
    </span>
  );
};

CropCompareSuccessMsg.propTypes = {
  intl: intlShape.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  tabName: PropTypes.string,
  cyclesToCompare: PropTypes.array,
};

CropCompareSuccessMsg.defaultProps = {
  tabName: undefined,
  cyclesToCompare: [],
};

export default injectIntl(CropCompareSuccessMsg);
