import React, {
 useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import {
 find, get, groupBy, sortBy, uniqueId,
} from 'lodash';

import useMountEffect from 'hooks/useMountEffect';
import { getPlantingCycleExtendedLabel } from 'helpers/getPlantingCycleLabel';
import { parseDate } from 'helpers/datesHelper';
import { hideIntercomLauncher, showIntercomLauncher } from 'helpers/intercomHelpers';
import { checkIfHasDifferentSpecies } from 'helpers/cropCycleHelpers';

import DefaultDialog from 'components/DefaultDialog';
import BigButton from 'components/BigButton';
// import DefaultComplexFilters from 'components/DefaultComplexFilters';
import DashboardComplexFilters from 'components/DashboardComplexFilters';
import ClickOutside from 'components/ClickOutside';
import Typography from 'components/Typography';
import SearchIcon from 'components/Icons/SearchIcon';
import CloseIcon from 'components/Icons/Close';
import FilterIcon from 'components/Icons/FilterIcon';

import { CYCLES_TO_COMPARE_LIMIT } from 'helpers/constants';

import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { ReactComponent as SquareIcon } from './assets/square.svg';

import TreeItem from '../TreeItem';

import styles from './BenchmarkAddCycleDialog.module.css';

const getFilteredPlantingCycles = (filterByCycleId, filters, plantingCycles) => {
  if (!plantingCycles) {
    return plantingCycles;
  }

  let filteredData = [...plantingCycles];

  if (filterByCycleId) {
    return filteredData?.filter(item => (filterByCycleId === item.id));
  }

  if (filters.compartmentId) {
    const compartmentsList = filters.compartmentId.map(item => Number(item));
    const filteredPlantingCycles = filteredData?.filter((item) => {
      const arrayIndex = compartmentsList.indexOf(item.relationships.compartment.data[0].id);

      return arrayIndex !== -1;
    });

    filteredData = [...filteredPlantingCycles];
  }

  if (filters.species) {
    const filteredPlantingCycles = filteredData?.filter(item => (filters.species === item.attributes.species));

    filteredData = [...filteredPlantingCycles];
  }

  if (filters.fruitClassCode) {
    const filteredPlantingCycles = filteredData?.filter(item => (filters.fruitClassCode === item.relationships.fruitClass.data[0].id));

    filteredData = [...filteredPlantingCycles];
  }

  if (filters.varietyId) {
    const filteredPlantingCycles = filteredData?.filter(item => (Number(filters.varietyId) === item.relationships.variety.data[0].id));

    filteredData = [...filteredPlantingCycles];
  }

  return filteredData;
};

const getAllCheckedCycles = (list) => {
  const cycleIdsList = list.reduce((acc, group) => {
    if (!group.nestedList) {
      if (group.checked) {
        return [...acc, group.id];
      }

      return acc;
    }

    return [...acc, ...getAllCheckedCycles(group.nestedList)];
  }, []);

  return cycleIdsList;
};

const getIfChecked = (prevValues, cyclesToCompare, cycleId) => {
  if (!prevValues) {
    return cyclesToCompare.indexOf(cycleId) !== -1;
  }
  const cycleIdsList = getAllCheckedCycles(prevValues);
  return cycleIdsList.indexOf(cycleId) !== -1;
};

const getIfExpanded = (prevValues, currentValue, path, cyclePath) => {
  if (!prevValues) {
    return false;
  }

  if (cyclePath && cyclePath[path] === currentValue) {
    return true;
  }

  return find(prevValues, { [path]: currentValue })?.expanded;
};

const deepFlattenCollection = list => list.reduce((acc, listItem) => {
  if (listItem.nestedList) {
    return [...acc, ...deepFlattenCollection(listItem.nestedList)];
  }

  return [...list];
}, []);

const getSearchOptions = (groupOfCycles, searchInput) => {
  if (searchInput.trim().length < 1) {
    return [];
  }

  const cyclesWithNames = deepFlattenCollection(groupOfCycles);

  return cyclesWithNames.filter(cycle => cycle.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1);
};

const getGroupedBySpecies = ({
  intl,
  filteredPlantingCycles,
  plantingCycles,
  compartments,
  fruitClasses,
  varieties,
  cyclesToCompare,
  prevValues,
  cyclePath,
  sortedPlantingCycles,
}) => {
  const { formatMessage } = intl;

  const plantingCyclesGrouped = groupBy(filteredPlantingCycles, 'attributes.species');

  const preparedPlantingCycles = Object.keys(plantingCyclesGrouped).map(key => ({
      id: key,
      species: key,
      speciesName: formatMessage({ id: `plantingCycles.speciesPlural.${key}` }),
      expanded: getIfExpanded(prevValues, key, 'species', cyclePath),
      nestedList: plantingCyclesGrouped[key].map((cycle) => {
        const plantingCycle = cycle;
        const varietyId = get(plantingCycle, 'relationships.variety.data[0].id');
        const fruitClassId = get(plantingCycle, 'relationships.fruitClass.data[0].id');
        const speciesId = get(plantingCycle, 'attributes.species');
        const startDate = get(plantingCycle, 'attributes.startDate');
        const endDate = get(plantingCycle, 'attributes.endDate');
        const completed = (endDate && parseDate(endDate) < new Date()) || false;

        return {
          id: cycle.id,
          value: cycle.id,
          checked: getIfChecked(prevValues, cyclesToCompare, cycle.id),
          name: getPlantingCycleExtendedLabel({
            plantingCycle,
            intl,
            allCompartments: compartments,
            fruitClasses,
            varieties,
            allPlantingCycles: plantingCycles,
            currentFruitClassCode: fruitClassId,
            currentVarietyId: varietyId,
            currentSpecies: speciesId,
            currentBreakdown: 'fruitClass',
            isPlantingCycleDatesAsTimestamp: false,
            withFruitClass: true,
          }),
          completed,
          startDate,
        };
      })
    }));

  const sortedBySpeciesPlantingCycles = sortBy(preparedPlantingCycles, 'species');
  return sortedBySpeciesPlantingCycles.map(speciesGroup => ({
    ...speciesGroup,
    nestedList: sortBy(speciesGroup.nestedList, item => sortedPlantingCycles.indexOf(item.id)),
  }));
};

const getPreparedPlantingCycles = ({
  intl,
  filters,
  filterByCycleId,
  compartments,
  fruitClasses,
  varieties,
  plantingCycles,
  cyclesToCompare,
  benchmarkAllLocations,
  prevValues,
  openFoldersFor,
  filtered,
  sortedPlantingCycles,
}) => {
  let cyclePath;

  const filteredPlantingCycles = filtered ? getFilteredPlantingCycles(filterByCycleId, filters, plantingCycles) : plantingCycles;

  if (openFoldersFor) {
    const currentCycle = find(plantingCycles, { id: openFoldersFor });
    cyclePath = {
      locationName: currentCycle?.location?.name,
      species: currentCycle?.attributes?.species,
    };
  }

  if (benchmarkAllLocations.length > 1) {
    const groupedByLocation = groupBy(filteredPlantingCycles, 'location.name');

    return Object.keys(groupedByLocation).map(key => ({
      id: key,
      locationName: key,
      expanded: getIfExpanded(prevValues, key, 'locationName', cyclePath),
      nestedList: getGroupedBySpecies({
        intl,
        filteredPlantingCycles: groupedByLocation[key],
        plantingCycles,
        compartments,
        fruitClasses,
        varieties,
        cyclesToCompare,
        prevValues: find(prevValues, { id: key })?.nestedList,
        cyclePath,
        sortedPlantingCycles,
      }),
    }));
  }

  return getGroupedBySpecies({
    intl,
    filteredPlantingCycles,
    plantingCycles,
    compartments,
    fruitClasses,
    varieties,
    cyclesToCompare,
    prevValues,
    cyclePath,
    sortedPlantingCycles,
  });
};

const handlerUpdateMetrics = (replaceAllCyclesInComparison, actionAfterSuccess, cyclesList) => {
  const cycleIdsList = getAllCheckedCycles(cyclesList);

  return replaceAllCyclesInComparison({ cycleIdsList, actionAfterSuccess });
};

const toggleField = (path, list, depth = 1, id, firstLevelId, isClickOnFirstLevel) => {
  if (depth === 1) {
    return list.map((listGroup) => {
      if (listGroup.id === id) {
        return {
          ...listGroup,
          [path]: !listGroup[path]
        };
      }
      return { ...listGroup };
    });
  }

  return list.map((listGroup) => {
    if (listGroup.id === firstLevelId) {
      return {
        ...listGroup,
        [path]: isClickOnFirstLevel ? !listGroup[path] : listGroup[path],
        nestedList: toggleField(path, listGroup.nestedList, depth - 1, id, listGroup.id)
      };
    }
    return { ...listGroup };
  });
};


const deepUpdateByUniqId = (pathToToggle, list, id) => list.map((listItem) => {
  if (!listItem.nestedList) {
    if (listItem.id === id) {
      return {
        ...listItem,
        [pathToToggle]: !listItem[pathToToggle]
      };
    }

    return { ...listItem };
  }

  return {
    ...listItem,

    nestedList: deepUpdateByUniqId(pathToToggle, listItem.nestedList, id),
  };
});

const handlerFolderClick = (setCyclesList, cyclesList, depth, id, firstLevelId, isClickOnFirstLevel) => {
  const newCyclesList = toggleField('expanded', cyclesList, depth, id, firstLevelId, isClickOnFirstLevel);

  return setCyclesList(newCyclesList);
};

const handlerCheckboxClick = (showNotificationWithTimeout, setCyclesList, fullList, allPlantingCycles, checkedItem, cycleValue) => {
  const newCyclesList = deepUpdateByUniqId('checked', fullList, cycleValue);
  const cycleIdsList = getAllCheckedCycles(newCyclesList);
  const isOtherSpecies = checkIfHasDifferentSpecies(allPlantingCycles, cycleIdsList);

  if (isOtherSpecies) {
    return showNotificationWithTimeout({
      id: `notifications.benchmarkLimitError.${Date.now()}`,
      messageId: 'benchmarking.differentSpecies',
      position: 'leftDown',
      iconType: 'info',
    });
  }

  if (cycleIdsList.length > CYCLES_TO_COMPARE_LIMIT) {
    return showNotificationWithTimeout({
      id: `notifications.benchmarkLimitError.${Date.now()}`,
      messageId: 'benchmarking.compareLimit',
      position: 'leftDown',
      iconType: 'info',
    });
}

  return setCyclesList(newCyclesList);
};

const renderSpeciesFolder = (showNotificationWithTimeout, setCyclesList, allPlantingCycles, depth, cycleGroupItem, fullList, parentId) => (
  <div key={uniqueId('tree-species-level-')}>
    <div
      role='menu'
      tabIndex={0}
      className={classnames(styles.item, {
        [styles.expanded]: cycleGroupItem.expanded,
        [styles.offsetTwo]: depth === 2
      })}
      onClick={() => handlerFolderClick(setCyclesList, fullList, depth, cycleGroupItem.id, parentId)}
      onKeyDown={() => {}}
    >
      <div className={classnames(styles.icon, styles.expander)}><ArrowIcon /></div>
      <div className={classnames(styles.icon, styles.simpleIcon)}><SquareIcon /></div>
      <div className={classnames(styles.title)}>{cycleGroupItem.speciesName}</div>
    </div>
    <div>
      {(cycleGroupItem.expanded) && (
        <>
          {cycleGroupItem.nestedList.map(cycle => (
            <TreeItem
              key={uniqueId('tree-second-level-')}
              item={cycle}
              onItemCheckboxClick={checkedItem => handlerCheckboxClick(showNotificationWithTimeout, setCyclesList, fullList, allPlantingCycles, checkedItem, cycle.value)}
              offset={depth === 2 ? 'offsetTwo' : 'offsetOne'}
            />
          ))}
        </>
      )}
    </div>
  </div>
);

const renderList = (showNotificationWithTimeout, formatMessage, allPlantingCycles, currentLocationName, setCyclesList, cyclesList, filteredCyclesList) => filteredCyclesList.map((cycleGroupItem) => {
  if (cycleGroupItem?.locationName) {
    const depth = 2;
    const isSameLocation = cycleGroupItem?.locationName === currentLocationName;

    return (
      <div key={uniqueId('tree-location-level-')}>
        <div
          role='menu'
          tabIndex={0}
          className={classnames(styles.item, { [styles.expanded]: cycleGroupItem.expanded })}
          onClick={() => handlerFolderClick(setCyclesList, cyclesList, depth, cycleGroupItem.id, cycleGroupItem.id, true)}
          onKeyDown={() => {}}
        >
          <div className={classnames(styles.icon, styles.expander)}><ArrowIcon /></div>
          <div className={classnames(styles.icon, styles.simpleIcon)}><SquareIcon /></div>
          <div className={classnames(styles.title)}>{cycleGroupItem.locationName}</div>
          {!isSameLocation && (
            <Typography variant='subtitle3' className={styles.subTitle}>
              {formatMessage({ id: 'dashboards.benchmarking' })}
            </Typography>
          )}
        </div>
        <div>
          {(cycleGroupItem.expanded) && (
            <>
              {cycleGroupItem.nestedList.map(speciesItem => renderSpeciesFolder(showNotificationWithTimeout, setCyclesList, allPlantingCycles, depth, speciesItem, cyclesList, cycleGroupItem.id))}
            </>
          )}
        </div>
      </div>
    );
  }

  const depth = 1;

  return renderSpeciesFolder(showNotificationWithTimeout, setCyclesList, allPlantingCycles, depth, cycleGroupItem, cyclesList);
});

const handlerSuccessAdding = (onClose, getNewData, newCyclesToCompare) => {
  onClose();

  return getNewData(newCyclesToCompare);
};

const handlerInputChange = (e, handlerEmptyFilterByCycleId, setSearchInputResultsOpen, setSearchInput) => {
  if (e.target.value === '') {
    handlerEmptyFilterByCycleId();
  }

  setSearchInput(e.target.value);
  setSearchInputResultsOpen(true);
};

// const handlerDocumentKeydown = (event, searchOptions, searchInputResultsOpen, setSearchInputResultsOpen, hoveredOption) => {
const handlerDocumentKeydown = ({
  event, searchOptions, hoveredOption, setHoveredOption, searchInputResultsOpen, setSearchInputResultsOpen, handlerClickToSearchOption
}) => {
  if (searchInputResultsOpen) {
    if (event.key === 'Esc' || event.keyCode === 27) {
      setSearchInputResultsOpen(false);
    }

    if (event.key === 'Enter' || event.keyCode === 13) {
      handlerClickToSearchOption(hoveredOption);
    }
  }

  if (searchInputResultsOpen && hoveredOption && searchOptions && searchOptions.length) {
    const index = searchOptions.findIndex(item => item.id === hoveredOption.id) || 0;

    if (event.key === 'ArrowUp' || event.keyCode === 38) {
      setHoveredOption(searchOptions[index - 1 >= 0 ? index - 1 : index]);
    }

    if (event.key === 'ArrowDown' || event.keyCode === 40) {
      setHoveredOption(searchOptions[index + 1 < searchOptions.length ? index + 1 : index]);
    }
  }
};

const renderSearchResultsBody = ({
  searchOptions,
  searchInput,
  handlerClickToSearchOption,
  hoveredOption,
  setHoveredOption,
}) => (
  <div className={styles.filterOptions}>
    {searchOptions.length ? searchOptions.map((option) => {
      const { id, name } = option;
      const processedFilterWords = searchInput.split(' ');

      const processedTitle = name
        .split(' ')
        .map((titleChunk) => {
          let processedChunk = titleChunk;
          processedFilterWords.forEach((processedFilterWord) => {
            const boldPosition = titleChunk.toLowerCase().indexOf(processedFilterWord.toLowerCase());

            if (boldPosition >= 0 && processedChunk.toLowerCase().localeCompare(titleChunk.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }) === 0) {
              processedChunk = [
                titleChunk.slice(0, boldPosition),
                '<strong>',
                titleChunk.slice(boldPosition, boldPosition + processedFilterWord.length),
                '</strong>',
                titleChunk.slice(boldPosition + processedFilterWord.length),
              ].join('');
            }
          });
          return processedChunk;
        }).join(' ');

        const isHovered = hoveredOption && hoveredOption.id === id;

      /* eslint-disable react/no-danger */
      return (
        <div
          role='button'
          tabIndex={0}
          key={uniqueId('filter-metric-')}
          className={classnames(styles.filterOption, { [styles.hovered]: isHovered })}
          dangerouslySetInnerHTML={{ __html: processedTitle }}
          onClick={() => handlerClickToSearchOption(option)}
          onKeyDown={() => handlerClickToSearchOption(option)}
          onMouseEnter={() => setHoveredOption(option)}
        />
      );
      /* eslint-enable react/no-danger */
    }) : null}
  </div>
);

const renderSearchResults = ({
  formatMessage,
  searchOptions,
  searchInput,
  setSearchInputResultsOpen,
  handlerClickToSearchOption,
  hoveredOption,
  setHoveredOption,
}) => (
  <ClickOutside
    handleClickOutside={() => setSearchInputResultsOpen(false)}
  >
    <div className={styles.filterOptionsPopup}>
      {
        !searchOptions.length && (
          <div className={styles.filterEmpty}>
            {formatMessage({ id: 'graphs.sidePanel.noSearchResult' })}
          </div>
        )
      }
      {
        searchOptions.length ? (
          renderSearchResultsBody({
            searchOptions,
            searchInput,
            handlerClickToSearchOption,
            hoveredOption,
            setHoveredOption,
          })
        ) : null
      }
    </div>
  </ClickOutside>
);

const BenchmarkAddCycleDialog = ({
  intl,
  intl: { formatMessage },
  onClose,
  plantingCycles,
  compartments,
  fruitClasses,
  varieties,
  cyclesToCompare,
  replaceAllCyclesInComparison,
  getNewData,
  filters,
  benchmarkAllLocations,
  location,
  showNotificationWithTimeout,
  sortedPlantingCycles,
}) => {
  useMountEffect(() => {
    hideIntercomLauncher();

    return () => {
      showIntercomLauncher();
    };
  });

  const inputRef = useRef(null);

  const currentLocationName = location?.attributes?.name;

  const [searchInput, setSearchInput] = useState('');
  const [searchInputResultsOpen, setSearchInputResultsOpen] = useState(false);
  const [filterByCycleId, setFilterByCycleId] = useState();
  const [hoveredOption, setHoveredOption] = useState();

  const preparedPlantingCycles = getPreparedPlantingCycles({
    intl,
    filters,
    filterByCycleId,
    compartments,
    fruitClasses,
    varieties,
    plantingCycles,
    cyclesToCompare,
    benchmarkAllLocations,
    sortedPlantingCycles,
  });

  const [cyclesList, setCyclesList] = useState(preparedPlantingCycles);

  /**
   * Отдельно делаем filteredCyclesList, а не фильтруем исходный cyclesList,
   * чтобы сохранялось состояние раскрытия папок.
   * Можно было бы фильтровать уже cyclesList, а не исходный массив циклов, но тогда, из-за вложенности,
   * фильтрация будет в разы сложнее (надо фильтровать родителей по ребёнку) и придётся заводить дополнительные
   * поля у объектов (с compartment id, planting cycle id, variety id, fruitClassCode), по которым фильтровать
   */
  const filteredCyclesList = getPreparedPlantingCycles({
    intl,
    filters,
    filterByCycleId,
    compartments,
    fruitClasses,
    varieties,
    plantingCycles,
    cyclesToCompare,
    benchmarkAllLocations,
    prevValues: cyclesList,
    filtered: true,
    sortedPlantingCycles,
  });

  // Делаем доп. переменную withFilterByCycleId, чтобы оставалась возможность обнулять фильтр filterByCycleId
  const handlerOnFiltersChange = useCallback((newFilters, withFilterByCycleId = false, newFilterByCycleId) => {
    const newPreparedPlantingCycles = getPreparedPlantingCycles({
      intl,
      filters: newFilters,
      filterByCycleId: withFilterByCycleId ? newFilterByCycleId : filterByCycleId,
      compartments,
      fruitClasses,
      varieties,
      plantingCycles,
      cyclesToCompare,
      benchmarkAllLocations,
      prevValues: cyclesList,
      openFoldersFor: newFilterByCycleId,
      sortedPlantingCycles,
    });

    setCyclesList(newPreparedPlantingCycles);
  }, [
    intl,
    filterByCycleId,
    compartments,
    fruitClasses,
    varieties,
    plantingCycles,
    cyclesToCompare,
    setCyclesList,
    cyclesList,
    benchmarkAllLocations,
    sortedPlantingCycles,
  ]);

  const handlerClickToSearchOption = useCallback((option) => {
    setSearchInput(option.name);
    setFilterByCycleId(option.id);
    handlerOnFiltersChange(filters, true, option.id);
    setSearchInputResultsOpen(false);
  }, [
    filters,
    setSearchInput,
    setFilterByCycleId,
    handlerOnFiltersChange,
    setSearchInputResultsOpen,
  ]);

  const handlerEmptyFilterByCycleId = useCallback(() => {
    setSearchInput('');

    if (filterByCycleId) {
      setFilterByCycleId();
      handlerOnFiltersChange(filters, true, undefined);
    }
  }, [
    filters,
    filterByCycleId,
    setSearchInput,
    setFilterByCycleId,
    handlerOnFiltersChange,
  ]);

  const searchOptions = getSearchOptions(cyclesList, searchInput);

  const filterButtons = [{
    type: 'group',
    options: {
      withoutArrow: true,
      className: styles.groupFilterButton,
      renderPlaceholder: () => 'Filters',
      renderIcon: applied => (applied?.length > 0 ?
        <span className={styles.filterAppliedCount}>{applied?.length}</span> :
        <FilterIcon />)
    },
    filters: [{
      type: 'compartment',
      options: {
        classNameButton: styles.selectButtonText,
        defaultNoSelectedValue: []
      }
    }, {
      type: 'variety',
      options: {
        classNameButton: styles.selectButtonText,
        // withCheckBox: true
      }
    }]
  }];

  const handleKeyDown = useCallback(event => handlerDocumentKeydown({
    event,
    searchOptions,
    hoveredOption,
    setHoveredOption,
    searchInputResultsOpen,
    setSearchInputResultsOpen,
    handlerClickToSearchOption,
  }), [
    searchOptions,
    hoveredOption,
    setHoveredOption,
    searchInputResultsOpen,
    setSearchInputResultsOpen,
    handlerClickToSearchOption,
  ]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <DefaultDialog
      title={formatMessage({ id: 'benchmarking.addCropCycles' })}
      onClose={onClose}
      wrapperClassName={styles.layout}
      className={styles.dialog}
      headerClassName={styles.dialogHeader}
      dialogCloseName={styles.dialogClose}
    >
      <div className={styles.body}>
        <div className={styles.content}>
          <div className={styles.filtersAndSearch}>
            {/* TODO: заменить на компонент UiSearchInput */}
            <div className={classnames(styles.filterInputWrapper)}>
              <div className={classnames(styles.filterControl)}>
                <div className={classnames(styles.icon, styles.filterInputButton, styles.filterInputButtonLeft)}>
                  <SearchIcon />
                </div>
                <input
                  type='text'
                  className={styles.filterInput}
                  onChange={e => handlerInputChange(e, handlerEmptyFilterByCycleId, setSearchInputResultsOpen, setSearchInput)}
                  value={searchInput}
                  ref={inputRef}
                  placeholder={formatMessage({ id: 'benchmarking.searchCropCycle' })}
                />
                {searchInput !== '' && (
                  <div
                    role='menu'
                    tabIndex={0}
                    className={classnames(styles.icon, styles.filterInputButton, styles.filterInputButtonRight)}
                    onClick={() => { handlerEmptyFilterByCycleId(); if (inputRef) { inputRef.current.focus(); } }}
                    onKeyDown={() => { handlerEmptyFilterByCycleId(); if (inputRef) { inputRef.current.focus(); } }}
                  >
                    <CloseIcon />
                  </div>
                )}
              </div>
              {searchInputResultsOpen && searchInput.trim().length > 0 && (
                renderSearchResults({
                  formatMessage,
                  searchOptions,
                  searchInput,
                  setSearchInputResultsOpen,
                  handlerClickToSearchOption,
                  hoveredOption,
                  setHoveredOption,
                })
              )}
            </div>
            <div className={styles.filters}>
              <DashboardComplexFilters
                filters={filterButtons}
                onFiltersChange={handlerOnFiltersChange}
                showResetAll
              />
            </div>
          </div>

          <div className={styles.treeWrapper}>
            {renderList(showNotificationWithTimeout, formatMessage, plantingCycles, currentLocationName, setCyclesList, cyclesList, filteredCyclesList)}
          </div>
        </div>
        <div className={styles.actions}>
          <BigButton
            className={classnames(styles.dontSaveButton)}
            title={formatMessage({ id: 'dialog.cancel' })}
            onClick={onClose}
            theme='light'
          />
          <BigButton
            className={classnames(styles.saveButton)}
            title={formatMessage({ id: 'benchmarking.addCropCycles' })}
            onClick={() => handlerUpdateMetrics(replaceAllCyclesInComparison, newCyclesToCompare => handlerSuccessAdding(onClose, getNewData, newCyclesToCompare), cyclesList)}
            theme='dark'
          />
        </div>
      </div>
    </DefaultDialog>
  );
};

BenchmarkAddCycleDialog.propTypes = {
  intl: intlShape.isRequired,
  onClose: PropTypes.func.isRequired,
  plantingCycles: PropTypes.array.isRequired,
  compartments: PropTypes.array.isRequired,
  fruitClasses: PropTypes.array.isRequired,
  varieties: PropTypes.array.isRequired,
  cyclesToCompare: PropTypes.array,
  filters: PropTypes.object,
  benchmarkAllLocations: PropTypes.array,
  replaceAllCyclesInComparison: PropTypes.func.isRequired,
  showNotificationWithTimeout: PropTypes.func.isRequired,
  getNewData: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  sortedPlantingCycles: PropTypes.array,
};

BenchmarkAddCycleDialog.defaultProps = {
  cyclesToCompare: [],
  filters: null,
  benchmarkAllLocations: [],
  sortedPlantingCycles: [],
};

export default BenchmarkAddCycleDialog;
