import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import DropdownMenu from '../../../DropdownMenu';

import ArrowDown from '../../../Icons/ArrowDown';

import styles from './DropdownButton.module.css';

class DropdownButton extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    disabled: PropTypes.bool,

    handlerPublish: PropTypes.func.isRequired,
    handlerSaveWithoutPublish: PropTypes.func.isRequired,
    handlerPublishButton: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  renderArrow = () => (
    <div className={styles.dropdownButtonArrow}>
      <ArrowDown />
    </div>
  );

  renderContent = () => {
    const {
      intl: { formatMessage },
      handlerPublish,
      handlerSaveWithoutPublish
    } = this.props;

    return (
      <div>
        <div
          className={styles.dropdownOption}
          onClick={handlerPublish}
          role='button'
          tabIndex={0}
        >
          <div className={styles.dropdownOptionHeader}>
            {formatMessage({ id: 'plansVersion.publish' })}
          </div>
          <div className={styles.dropdownOptionText}>
            {formatMessage({ id: 'plansVersion.publishDesc' })}
          </div>
        </div>
        <div
          className={styles.dropdownOption}
          onClick={handlerSaveWithoutPublish}
          role='button'
          tabIndex={0}
        >
          <div className={styles.dropdownOptionHeader}>
            {formatMessage({ id: 'plansVersion.saveWithoutPublish' })}
          </div>
          <div className={styles.dropdownOptionText}>
            {formatMessage({ id: 'plansVersion.saveWithoutPublishDesc' })}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { intl: { formatMessage }, disabled, handlerPublishButton } = this.props;

    return (
      <button type='button' className={styles.dropdownButton} disabled={disabled}>
        <span
          className={styles.dropdownButtonText}
          onClick={handlerPublishButton}
          role='button'
          tabIndex={0}
        >
          {formatMessage({ id: 'plansVersion.publish' })}
        </span>

        {disabled ?
          this.renderArrow()
          : (
            <DropdownMenu
              className={styles.dropdownMenu}
              contentClassName={styles.dropdownContent}
              buttonElement={this.renderArrow()}
            >
              {this.renderContent()}
            </DropdownMenu>
          )}
      </button>
    );
  }
}

export default injectIntl(DropdownButton);
