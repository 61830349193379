import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getAllVarieties,
  getAllFruitClasses,
  getAllCompartments,
  getCurrentPlantingCycles,
  getBenchmarkAllProducts,
} from 'store/company/selectors';

import CropSwitch from '../components/CropSwitch';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,

  varieties: getAllVarieties(state, props),
  fruitClasses: getAllFruitClasses(state, props),
  compartments: getAllCompartments(state, props),
  plantingCycles: getCurrentPlantingCycles(state, props),
  sortedPlantingCycles: getBenchmarkAllProducts(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CropSwitch));
