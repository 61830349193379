import { connect } from 'react-redux';

import {
  getLeftPlantingCycle,
  getPlantingCyclePhotoCategories,
} from 'store/company/selectors';

import SplitPhotos from './SplitPhotos';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  leftPlantingCycle: getLeftPlantingCycle(state, props),
  photoCategories: getPlantingCyclePhotoCategories(state, props),
});

export default connect(mapStateToProps, mapDispatchToProps)(SplitPhotos);
