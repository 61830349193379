// TODO: Отрефакторить этот компонент, кажется его скопипастили не доделав
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component, } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import styles from './index.module.css';


export default class Tooltip extends Component {
  static propTypes = {
    isShowLabelsOnMobile: PropTypes.bool,
    isColorsDisabled: PropTypes.bool,
    showTooltip: PropTypes.bool,
    screenX: PropTypes.number,
    screenY: PropTypes.number,
    week: PropTypes.number,
    date: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    dateFormat: PropTypes.func,
    items: PropTypes.array,
    totalItem: PropTypes.object,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    isShowLabelsOnMobile: false,
    isColorsDisabled: false,
    showTooltip: false,
    screenX: null,
    screenY: null,
    totalItem: null,
    date: null,
    dateFormat: null,
    items: [],
    week: null,
  };

  state = {
    leftPosition: this.props.screenX,
    topPosition: this.props.screenY,
  };

  componentDidMount() {
    this.calcPosition(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.calcPosition(nextProps);
  }

  calcPosition = (props) => {
    const {
      screenX,
      parent,
      defaultOffsetY,
    } = props;

    const sideOffset = 20;

    if (this.node && this.node.getBoundingClientRect) {
      const rect = this.node.getBoundingClientRect();
      const viewportElement = document.querySelector('.layout__viewport');
      // Проверяем как передаётся родитель - по классу или объектом
      const parentElementByClass = typeof parent === 'string' ? document.querySelector(`.${parent}`) : parent;
      const parentElement = parentElementByClass || null;

      if (parentElement) {
        const parentRect = parentElement.getBoundingClientRect();
        const parentRectBottom = parentRect.top + parentRect.height;
        const defaultOffsetX = -rect.width / 2;

        /* eslint-disable no-mixed-operators */
        // Проверка умещается ли справа тултип во вьюпорт по ширине
        if (rect && rect.width) {
          const isTooltipInViewportX = (screenX - rect.width / 2) > window.innerWidth + viewportElement.scrollLeft - sideOffset ||
            (screenX - rect.width / 2) < sideOffset ||
            (screenX + rect.width) > window.innerWidth + viewportElement.scrollLeft - sideOffset;

          let offsetX = isTooltipInViewportX ? (-rect.width - defaultOffsetX) : defaultOffsetX;

          if ((screenX - rect.width / 2) < sideOffset) {
            offsetX = -screenX + sideOffset;
          }

          if ((screenX + rect.width / 2 + sideOffset) > window.innerWidth + viewportElement.scrollLeft - sideOffset) {
            offsetX = -screenX - rect.width + window.innerWidth + viewportElement.scrollLeft - sideOffset;
          }

          const leftScreen = screenX + offsetX;
          const leftPosition = leftScreen - viewportElement.scrollLeft;
          const topPosition = parentRectBottom - rect.height - defaultOffsetY;
          const triangleLeft = screenX - leftPosition - 9;

          /* eslint-enable no-mixed-operators */

          this.setState({
            leftPosition,
            topPosition,
            triangleLeft,
          });
        }
      }
    }
  };

  render() {
    const {
      date,
      dateFormat,
      items,
      totalItem,
      week,
      showTooltip,
      isColorsDisabled,
      intl,
      isShowLabelsOnMobile,
    } = this.props;

    const {
      leftPosition,
      topPosition,
      triangleLeft,
    } = this.state;

    const tooltipStyle = {
      left: leftPosition,
      top: topPosition
    };

    if (!showTooltip) {
      tooltipStyle.display = 'none';
    }

    const { formatMessage } = intl;

    let dateTitle = null;


    if (dateFormat) {
      dateTitle = dateFormat({ date });
    } else {
      dateTitle = `${week && formatMessage({ id: 'tables.week.one' })} ${week}${week && ','} ${date}`;
    }

    return (
      <div style={tooltipStyle} className={classnames(styles.tooltip, { [styles.compact]: items.length > 10 })} ref={(node) => { this.node = node; }}>
        <div className={styles.metricDate}>
          {dateTitle}
        </div>
        <table className={classnames(styles.tooltipTable, { [styles.compact]: items.length > 10 })}>
          <tbody>
            {items.map((metric) => {
              if (metric) {
                return (
                  <tr key={metric.key} className={classnames({ [styles.selected]: metric.selected, [styles.notSelected]: !metric.selected, [styles.bold]: metric.isBold })}>
                    {!isColorsDisabled ? (
                      <td className={styles.color}>
                        <div className={styles.colorWrapper}>
                          <div className={styles.colorSquare} style={{ backgroundColor: metric.color }} />
                        </div>
                      </td>
                    ) : null}
                    <td className={classnames(styles.metricName, { [styles.showLabelsOnMobile]: isShowLabelsOnMobile })}>
                      <div className={styles.title}>{metric.name}</div>
                    </td>
                    {/* eslint-disable no-nested-ternary */}
                    <td className={classnames(styles.metricValue, { [styles.showLabelsOnMobile]: isShowLabelsOnMobile })}>
                      {metric.value}{metric.cunit ? formatMessage({ id: `cunits.mini.${metric.cunit}` }) === '%' ? '' : <span>&nbsp;</span> : ''}{metric.cunit ? <FormattedMessage id={`cunits.mini.${metric.cunit}`} /> : null}
                    </td>
                    {/* eslint-enable no-nested-ternary */}
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
        {totalItem && (
          <table className={classnames(styles.tooltipTable, { [styles.compact]: items.length > 10 })}>
            <tbody>
              <tr key='total' className={classnames(styles.bold)}>
                <td className={classnames(styles.metricName, { [styles.showLabelsOnMobile]: isShowLabelsOnMobile })}>
                  <div className={styles.title}>{totalItem.name}</div>
                </td>
                {/* eslint-disable no-nested-ternary */}
                <td className={classnames(styles.metricValue, { [styles.showLabelsOnMobile]: isShowLabelsOnMobile })}>
                  {totalItem.value}{totalItem.cunit ? formatMessage({ id: `cunits.mini.${totalItem.cunit}` }) === '%' ? '' : <span>&nbsp;</span> : ''}{totalItem.cunit ? <FormattedMessage id={`cunits.mini.${totalItem.cunit}`} /> : null}
                </td>
                {/* eslint-enable no-nested-ternary */}
              </tr>
            </tbody>
          </table>
        )}
        <div className={styles.triangle} style={{ left: triangleLeft }} />
      </div>
    );
  }
}
