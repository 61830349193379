import classnames from 'classnames';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import BigDateInput from '../BigDateInput';

import styles from './CustomDatePickerInput.module.css';

export default class CustomDatePickerInput extends Component {
  static propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    theme: PropTypes.string,
    clearable: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onInnerBlur: PropTypes.func,
    onInnerFocus: PropTypes.func,
    onInnerClear: PropTypes.func,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    isErrorDisable: PropTypes.bool,
    isErrorTextDisable: PropTypes.bool,
    wrapperClassName: PropTypes.string,
    popperPlacement: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    input: null,
    meta: null,
    theme: null,
    clearable: true,
    placeholder: '',
    className: null,
    onInnerBlur: null,
    onInnerFocus: null,
    onInnerClear: null,
    minDate: null,
    maxDate: null,
    isErrorDisable: false,
    isErrorTextDisable: false,
    wrapperClassName: null,
    popperPlacement: 'bottom-start'
  };

  render() {
    const {
      input,
      meta,
      placeholder,
      className,
      disabled,
      onInnerBlur: handlerBlur,
      onInnerFocus: handlerFocus,
      onInnerClear: handlerClear,
      minDate,
      maxDate,
      theme,
      clearable,
      isErrorDisable,
      isErrorTextDisable,
      wrapperClassName,
      popperPlacement
    } = this.props;

    const {
      onChange,
      onBlur,
      value,
    } = input;

    const {
      error,
      touched,
    } = meta;

    return (
      <BigDateInput
        className={classnames(styles.button, className)}
        wrapperClassName={classnames(styles.buttonWrapper, wrapperClassName)}
        error={(!isErrorDisable && touched && error) ? error : null}
        value={value || null}
        popperPlacement={popperPlacement}
        onChange={(options) => {
          onChange(options);
          onBlur(options);
        }}
        onBlur={(options) => {
          if (value) {
            onBlur(options);
          }

          if (handlerBlur) {
            handlerBlur(options);
          }
        }}
        onFocus={(options) => {
          if (handlerFocus) {
            handlerFocus(options);
          }
        }}
        onClear={(options) => {
          if (handlerClear) {
            handlerClear(options);
          }
        }}
        placeholder={placeholder}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        theme={theme}
        clearable={clearable}
        isErrorTextDisable={isErrorTextDisable}
      />
    );
  }
}
