import React, { memo } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './Avatar.module.css';

const Avatar = ({ userName, className }) => {
  const getFirstLetter = string => (string ? string.charAt(0) : '');
  const firstLetter = getFirstLetter(userName);

  return (
    <div className={classnames(styles.avatar, className)}>
      {firstLetter}
    </div>
  );
};

Avatar.propTypes = {
  userName: PropTypes.string,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  userName: null,
  className: null,
};

export default memo(Avatar);
