import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Login from 'components/Login';
import LoginReset from 'components/LoginReset';
import LoginNewPassword from 'components/LoginNewPassword';
import LoginVerificationProblems from 'components/LoginVerificationProblems';
import LoginVerification from 'components/LoginVerification';

import LoginLayout from '../../layouts/LoginLayout';

import NotFound from '../NotFound';

export default class LoginRoutes extends Component {
  render() {
    return (
      <LoginLayout>
        <Switch>
          <Route path='/login' component={Login} exact />
          <Route path='/login/reset-password' component={LoginReset} exact />
          <Route path='/login/new-password/:jwt' component={LoginNewPassword} exact />
          <Route path='/login/set-password/:jwt' component={props => <LoginNewPassword {...props} setForNewAccount />} exact />
          <Route path='/login/code/problems' component={LoginVerificationProblems} exact />
          <Route path='/login/code' component={LoginVerification} exact />
          <Route path='/login/*' exact component={NotFound} />
        </Switch>
      </LoginLayout>
    );
  }
}
