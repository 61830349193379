// TODO: refactoring
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CellShape from './CellShape';

import styles from './ValueViewer.module.css';

export default class DataEditor extends PureComponent {
  static propTypes = {
    value: PropTypes.node.isRequired,
    row: PropTypes.number.isRequired,
    col: PropTypes.number.isRequired,
    cell: PropTypes.shape(CellShape),
    onChange: PropTypes.func.isRequired,
    onCommit: PropTypes.func.isRequired,
    onRevert: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  /* eslint-disable no-underscore-dangle */
  componentDidMount() {
    this._input.focus();
  }

  handleChange(e) {
    this.props.onChange(e.target.value);
  }

  render() {
    const { value, onKeyDown } = this.props;
    return (
      <input
        ref={(input) => { this._input = input; }}
        className={classnames('data-editor', styles.dataEditor)}
        value={value}
        onChange={this.handleChange}
        onKeyDown={onKeyDown}
      />
    );
  }
  /* eslint-enable no-underscore-dangle */
}
/* eslint-enable react/no-unused-prop-types */
