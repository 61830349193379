import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

import classnames from 'classnames';

import BigButton from 'components/BigButton';

import styles from './DefaultLink.module.css';

const DefaultLink = ({
  className, href, onClick, children
}) => {
  if (href) {
    return (
      <NavLink className={classnames(styles.link, className)} to={href}>
        {children}
      </NavLink>
    );
  }
  return (
    <BigButton
      className={classnames(styles.link, className)}
      onClick={onClick}
      title={children}
      theme='transparent'
    />
  );
};

DefaultLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

DefaultLink.defaultProps = {
  className: undefined,
  href: undefined,
  onClick: undefined,
};

export default DefaultLink;
