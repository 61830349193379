import moment from 'moment-timezone';
import getDateFormat from 'helpers/getDateFormat';


export default function getWeeks({ startDate, endDate, timezone }) {
  // https://en.wikipedia.org/wiki/ISO_week_date#First_week
  const startPoint = moment.tz(startDate, timezone).isoWeekday(1);
  const endPointCandidate = moment.tz(endDate, timezone);
  const nowDate = moment.tz(timezone);

  const endPoint = endPointCandidate.isSameOrBefore(nowDate) ? endPointCandidate : nowDate;

  // Без +1 не будет учитвыаться текущая неделя
  const diffSize = endPoint.diff(startPoint, 'weeks') + 1;

  const weeksGenerator = new Array(diffSize).fill(true);

  return weeksGenerator.map((a, i) => {
    const point = startPoint.clone().add(i, 'weeks');
    const year = point.year();
    const week = point.isoWeek();

    const date = point
      .isoWeekday(1)
      .format(getDateFormat('ll'));

    return {
      id: i,
      date,
      year,
      week,
      fullDate: point
        .isoWeekday(1)
        .format('YYYY-MM-DD')
    };
  });
}
