import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
// import VerificationInput from 'react-verification-input';
import PinField from 'react-pin-field';

import styles from './DefaultVerificationInput.module.css';

const DefaultVerificationInput = ({
  length,
  onChange,
  onComplete,
  autoFocus,
  error,
}) => (
  <div className={styles.container}>
    <div className={cn(styles.inputContainer, { [styles.hasError]: error })}>
      <PinField
        className={styles.pinField}
        onComplete={onComplete}
        onChange={onChange}
        length={length}
        autoFocus={autoFocus}
        validate='0123456789'
        inputmode='numeric'
        pattern='[0-9]*'
        type='text'
      />
    </div>
    {error && (
      <div className={styles.errorText}>
        {error}
      </div>
    )}
  </div>
);

DefaultVerificationInput.propTypes = {
  length: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  error: PropTypes.string,
};

DefaultVerificationInput.defaultProps = {
  length: 4,
  autoFocus: true,
  error: undefined,
};

export default DefaultVerificationInput;
