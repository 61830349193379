import { useEffect } from 'react';
import { select } from 'd3';

function useOverlay(overlayRef, options) {
  const {
    chartParams: {
      x, h
    },
    start,
    end
  } = options;

  useEffect(() => {
    const group = overlayRef.current
      ? select(overlayRef.current)
      : null;

    if (group) {
      group
        .selectAll('rect').remove();
      if (end) {
        const startX = start ? x(start) : 0;
        const endX = x(end);

        group.append('rect')
          .attr('x', startX)
          .attr('y', 0)
          .attr('width', endX - startX)
          .attr('height', h || 0)
          .attr('fill', '#F7F9FA');
      }
    }
  }, [overlayRef, h, start, end, x]);
}

export default useOverlay;
