
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import classnames from 'classnames';
import { uniqueId } from 'lodash';

import isTouchDevice from '../../helpers/isTouchDevice';

import InfoIcon from '../Icons/InfoIcon';

import tooltipStyles from '../Tooltip/index.module.css';
import styles from './TextInfoTooltip.module.css';

const TextInfoTooltip = ({
  text, tooltipText, tooltipPlace,
  className, wrapperClassName, tooltipClassName,
}) => {
  const tooltipId = `text-info-tooltip-${uniqueId()}`;

  return (
    <div className={classnames(styles.textWrapper, wrapperClassName)}>
      <span className={classnames(styles.text, className)}>
        {text}
      </span>
      <span
        className={styles.icon}
        data-tip={tooltipText}
        data-for={tooltipId}
      >
        <InfoIcon />
      </span>
      <ReactTooltip
        className={classnames(tooltipStyles.smallTooltip, styles.tooltip, tooltipClassName)}
        id={tooltipId}
        effect='solid'
        place={tooltipPlace}
        event={isTouchDevice() ? 'click' : null}
        html
      />
    </div>
  );
};

TextInfoTooltip.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  tooltipText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  tooltipPlace: PropTypes.string,

  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
};

TextInfoTooltip.defaultProps = {
  tooltipPlace: 'top',

  className: null,
  wrapperClassName: null,
  tooltipClassName: null,
};

export default memo(TextInfoTooltip);

