import React from 'react';
import { intlShape } from 'react-intl';

import classnames from 'classnames';

import { showIntercom } from 'helpers/intercomHelpers';

import DefaultBackButton from 'components/DefaultBackButton';
import Typography from 'components/Typography';
import BigButton from 'components/BigButton';

import styles from './LoginVerificationProblems.module.css';

const LoginVerificationProblems = ({
  intl,
}) => {
  const { formatMessage } = intl;

  return (
    <div className={styles.verificationProblemsWrapper}>
      <div className={styles.verificationProblemsContainer}>
        <DefaultBackButton
          className={styles.backButton}
          text={formatMessage({ id: 'button.back' })}
          link='/login/code'
        />
        <Typography
          className={styles.header}
          variant='h2'
        >
          {formatMessage({ id: 'login.didntGetCode' })}
        </Typography>

        <Typography variant='body1' className={classnames(styles.firstParagraph, styles.paragraph)}>
          {formatMessage({ id: 'login.problemsText' })}
        </Typography>

        <BigButton
          onClick={showIntercom}
          title={formatMessage({ id: 'noAccess.contactSupport' })}
          theme='dark'
          className={styles.button}
          titleClassName={styles.buttonTitle}
        />
      </div>
    </div>
  );
};

LoginVerificationProblems.propTypes = {
  intl: intlShape.isRequired,
};

LoginVerificationProblems.defaultProps = {};


export default LoginVerificationProblems;
