import { get } from 'lodash';
import { Observable } from 'rxjs';
import queryString from 'query-string';

import initAxiosInstanse from '../../api/axios';

import {
  requestGetHarvestPlans,
  receiveGetHarvestPlans,
  cancelGetHarvestPlans,
  receiveGetHarvestPlansList,
  requestGetHarvestPlansList,
} from './actions';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

export const getHarvestPlansEpic = (action$, store) =>
  action$.ofType(requestGetHarvestPlans)
    .mergeMap((actionData) => {
      const {
        payload: {
          year,
          periodType,
          relative,
          organization,
          history,
          intl,
          breakdown,
          compartmentId,
          varietyId,
          species,
          fruitClassCode,
          locationPlan,
        },
      } = actionData;

      const { company } = store.getState();
      const {
        allCompartments: compartments,
        varieties,
        fruitClasses,
        allPlantingCycles,
        productGroups,
      } = company;

      const { id: organizationId } = organization;

      const queryParams = queryString.stringify({
        relative,
        breakdown,
        compartmentId,
        varietyId,
        locationPlan,
        species,
        fruitClassCode,
      });

      const query = queryParams || '';

      return Observable.from(
        initAxiosInstanse(get(history, 'location.search'))
          .get(`timeline/${organizationId}/${year}?${query}`)
      )
        .mergeMap(({ data, error }) => {
          if (error) {
            return Observable.of(
              receiveGetHarvestPlans({
                compartments,
                varieties,
                fruitClasses,
                periodType,
                relative,
                organization,
                locationPlan,
                allPlantingCycles,
                productGroups,
                breakdown,
                intl,
                isError: true,
                year,
              }),
              showNotificationWithTimeout({
                id: `notifications.getHarvestPlansError.${Date.now()}`,
                messageId: 'notifications.getHarvestPlansError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withActionWide',
              }),
            );
          }

          return Observable.of(
            receiveGetHarvestPlans({
              compartments,
              varieties,
              fruitClasses,
              periodType,
              relative,
              organization,
              locationPlan,
              allPlantingCycles,
              productGroups,
              breakdown,
              data,
              intl,
              year,
            }),
          );
        })
        .takeUntil(action$.ofType(cancelGetHarvestPlans))
        .catch(() => Observable.of(
          receiveGetHarvestPlans({
            compartments,
            varieties,
            fruitClasses,
            periodType,
            relative,
            organization,
            locationPlan,
            allPlantingCycles,
            productGroups,
            breakdown,
            intl,
            isError: true,
            year,
          }),
          showNotificationWithTimeout({
            id: `notifications.getHarvestPlansError.${Date.now()}`,
            messageId: 'notifications.getHarvestPlansError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getHarvestPlansListEpic = (action$, store) =>
  action$.ofType(requestGetHarvestPlansList)
    .switchMap(() => {
      const state = store.getState();

      const {
        router,
        company,
      } = state;

      const {
        location,
      } = company;

      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/location-plan/${organizationId}`))
        .mergeMap(({ data }) => Observable.of(receiveGetHarvestPlansList({ harvestPlansList: data })))
        .catch(() => Observable.of(
          receiveGetHarvestPlansList(),
          showNotificationWithTimeout({
            id: `notifications.getHarvestPlansError.${Date.now()}`,
            messageId: 'notifications.getHarvestPlansError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });
