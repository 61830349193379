import { connect } from 'react-redux';
import { getFormValues, getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

import VersionInfoDialog from '../components/VersionInfoDialog';

const mapDispatchToProps = {
  // userFormOnSubmit,
};

const mapStateToProps = (state, props) => ({
  formValues: getFormValues('versionNameForm')(state),
  formSyncErrors: getFormSyncErrors('versionNameForm')(state),

  ...props,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(VersionInfoDialog));
