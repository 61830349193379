import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import moment from 'moment-timezone';
import classnames from 'classnames';

import getDateFormat from 'helpers/getDateFormat';

import DeleteInfoDialog from 'components/DeleteInfoDialog';
import DropdownMenu from 'components/DropdownMenu';
import Avatar from 'components/Avatar';
import ReadMore from 'components/ReadMore';
import VersionInfoDialog from 'components/VersionInfoDialog';

import RestoreDialog from '../RestoreDialog';
import PublishDialog from '../PublishDialog';

import styles from './VersionCard.module.css';

class VersionCard extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    version: PropTypes.object.isRequired,
    activeVersionId: PropTypes.number,
    isLastSaved: PropTypes.bool,
    isDeleteVersionInfoFetching: PropTypes.bool,
    isPlansEditorSaving: PropTypes.bool,
    versions: PropTypes.array,
    isVersionInfoFetching: PropTypes.bool,

    onVersionClick: PropTypes.func.isRequired,
    onEditVersionInfo: PropTypes.func.isRequired,
    onPublishVersion: PropTypes.func.isRequired,
    onRestoreVersion: PropTypes.func.isRequired,
    onDeleteVersionInfo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeVersionId: null,
    isLastSaved: false,
    isDeleteVersionInfoFetching: false,
    isPlansEditorSaving: false,
    isVersionInfoFetching: false,
    versions: [],
  };

  state = {
    hover: false,
    isNameDialogOpened: false,
    isDeleteInfoDialogOpened: false,
    isRestoreDialogOpened: false,
    isPublishDialogOpened: false,
  };

  handlerMouseEnter = () => this.setState({ hover: true });

  handlerMouseLeave = () => this.setState({ hover: false });

  handlerPublishVersion = (e, planId) => {
    e.stopPropagation();

    const { onPublishVersion, version } = this.props;
    const { published } = version;

    if (published) {
      return null;
    }

    return onPublishVersion({ planId });
  };

  handlerOpenNameDialog = (e) => {
    e.stopPropagation();

    this.setState({ isNameDialogOpened: true });
  };

  handlerOpenDeleteInfoDialog = (e, isDeleteVersionInfoEnabled) => {
    e.stopPropagation();

    if (!isDeleteVersionInfoEnabled) {
      return null;
    }

    return this.setState({ isDeleteInfoDialogOpened: true });
  };

  handlerOpenRestoreDialog = (e) => {
    e.stopPropagation();

    const { isLastSaved } = this.props;

    if (isLastSaved) {
      return null;
    }

    return this.setState({ isRestoreDialogOpened: true });
  };

  handlerOpenPublishDialog = (e) => {
    e.stopPropagation();

    const { version } = this.props;
    const { published } = version;

    if (published) {
      return null;
    }

    return this.setState({ isPublishDialogOpened: true });
  };

  handlerCloseNameDialog = () => this.setState({ isNameDialogOpened: false });

  handlerCloseDeleteInfoDialog = () => this.setState({ isDeleteInfoDialogOpened: false });

  handlerCloseRestoreDialog = () => this.setState({ isRestoreDialogOpened: false });

  handlerClosePublishDialog = () => this.setState({ isPublishDialogOpened: false });

  renderNamedVersionCreationDate = (author, formattedWhenCreated) => (
    <span className={styles.authorWrapper}>
      <span className={styles.authorName}>{author}</span>
      <span className={styles.dot} />
      <span>{formattedWhenCreated}</span>
    </span>
  );

  render() {
    const {
      intl: { formatMessage }, version, activeVersionId, onVersionClick, isLastSaved,
      onEditVersionInfo, onDeleteVersionInfo, isDeleteVersionInfoFetching, onRestoreVersion, onPublishVersion, isPlansEditorSaving,
      versions, isVersionInfoFetching,
    } = this.props;
    const {
      hover, isNameDialogOpened, isDeleteInfoDialogOpened, isRestoreDialogOpened, isPublishDialogOpened,
    } = this.state;

    const {
      id: planId, label, comment, author, whenCreated, published, restoredFrom
    } = version;

    const formattedWhenCreated = moment(whenCreated).format(getDateFormat('ll hh'));
    const formattedWhenRestored = restoredFrom ? moment(restoredFrom.creationDate).format(getDateFormat('ll hh')) : null;
    const defaultLabel = formattedWhenCreated;

    const isPlanActive = planId === activeVersionId;

    const initialValues = {
      versionName: label,
      versionComment: comment,
    };

    const isDeleteVersionInfoEnabled = label || comment;

    return (
      <>
        <div
          className={classnames(
            styles.plan,
            { [styles.planActive]: isPlanActive }
          )}
          onClick={() => onVersionClick({ planId })}
          role='button'
          tabIndex={0}
          onMouseEnter={this.handlerMouseEnter}
          onMouseLeave={this.handlerMouseLeave}
        >
          <div className={styles.planLeftContainer}>
            <Avatar
              className={styles.avatar}
              userName={author}
            />

            <div className={styles.planInfo}>
              <div className={styles.planLabel}>
                {label || defaultLabel}
              </div>
              {isLastSaved && (
                <div className={styles.lastSaved}>
                  {formatMessage({ id: 'plansVersion.lastSaved' })}
                </div>
              )}
              {restoredFrom && (
                <div className={styles.restored}>
                  {`${formatMessage({ id: 'plansVersion.restored' })} ${formattedWhenRestored}`}
                </div>
              )}
              <div className={styles.planAuthor}>
                {label ?
                  this.renderNamedVersionCreationDate(author, formattedWhenCreated)
                  :
                  author}
              </div>
              {published && (
                <div className={styles.planPublished}>
                  {formatMessage({ id: 'plansVersion.published' })}
                </div>
              )}
              {comment && (
                <div className={styles.planComment}>
                  <ReadMore
                    text={comment}
                    lines={4}
                  />
                </div>
              )}
            </div>
          </div>

          {hover ? (
            <DropdownMenu
              wrapperClassName={styles.dropdownWrapper}
              defaultButtonClassName={styles.dropdownButton}
            >
              <div className={styles.dropdownContent}>
                <div
                  className={styles.dropdownOption}
                  onClick={this.handlerOpenNameDialog}
                  role='button'
                  tabIndex={0}
                >
                  {label ? formatMessage({ id: 'plansVersion.editVersionInfo' }) : formatMessage({ id: 'plansVersion.nameVersion' }) }
                </div>
                <div
                  className={classnames(
                    styles.dropdownOption,
                    {
                      [styles.dropdownOptionDisabled]: published
                    }
                  )}
                  onClick={this.handlerOpenPublishDialog}
                  role='button'
                  tabIndex={0}
                >
                  {formatMessage({ id: 'plansVersion.publishVersion' })}
                </div>
                <div
                  className={classnames(
                    styles.dropdownOption,
                    {
                      [styles.dropdownOptionDisabled]: isLastSaved
                    }
                  )}
                  onClick={this.handlerOpenRestoreDialog}
                  role='button'
                  tabIndex={0}
                >
                  {formatMessage({ id: 'plansVersion.restoreVersion' })}
                </div>
                <div
                  className={classnames(
                    styles.dropdownOption,
                    {
                      [styles.dropdownOptionDisabled]: !isDeleteVersionInfoEnabled
                    }
                  )}
                  onClick={e => this.handlerOpenDeleteInfoDialog(e, isDeleteVersionInfoEnabled)}
                  role='button'
                  tabIndex={0}
                >
                  {formatMessage({ id: 'plansVersion.deleteVersionInfo' })}
                </div>
              </div>
            </DropdownMenu>
          )
            :
            <div className={styles.stub} />}
        </div>
        {isNameDialogOpened && (
          <VersionInfoDialog
            planId={planId}
            label={label}
            comment={comment}
            handlerCloseDialog={this.handlerCloseNameDialog}
            handlerDontSave={this.handlerCloseNameDialog}
            handlerSave={onEditVersionInfo}
            initialValues={initialValues}
            isVersionInfoFetching={isVersionInfoFetching}
            versions={versions}
          />
        )}
        {isDeleteInfoDialogOpened && (
          <DeleteInfoDialog
            handlerCloseDialog={this.handlerCloseDeleteInfoDialog}
            handlerDontSave={this.handlerCloseDeleteInfoDialog}
            handlerSave={() => onDeleteVersionInfo({ planId, actionSuccess: this.handlerCloseDeleteInfoDialog, })}
            isDeleteVersionInfoFetching={isDeleteVersionInfoFetching}
          />
        )}
        {isRestoreDialogOpened && (
          <RestoreDialog
            handlerCloseDialog={this.handlerCloseRestoreDialog}
            handlerDontSave={this.handlerCloseRestoreDialog}
            handlerSave={() => onRestoreVersion({ planId, actionAfterSave: this.handlerCloseRestoreDialog, })}
            isFetching={isPlansEditorSaving}
            versionTime={formattedWhenCreated}
          />
        )}
        {isPublishDialogOpened && (
          <PublishDialog
            handlerCloseDialog={this.handlerClosePublishDialog}
            handlerDontSave={this.handlerClosePublishDialog}
            handlerSave={() => onPublishVersion({ planId, actionAfterSave: this.handlerClosePublishDialog, })}
            isFetching={isPlansEditorSaving}
            versionTime={formattedWhenCreated}
          />
        )}
      </>
    );
  }
}

export default injectIntl(VersionCard);
