import * as moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export function buildGraphSeries(name, color, series, options = {}) {
  const {
    units, points
  } = series;
  const {
    area,
    lineStyle,
    showInLegend,
    hideNullableInTooltip,
    key: seriesKey,
    tooltipHidePointIndexes
  } = options;
  return {
    id: `${seriesKey || name}`,
    key: `line-${seriesKey || name}`,
    name,
    color,
    units,
    area,
    points: points ? Object.keys(points)
      .reduce((acc, key) => ([...acc, {
        x: moment.utc(new Date((+key) * 60000)).format('MM-DD-YYYY'),
        y: points[key] || 0,
      }]), []) : [],
    lineStyle: lineStyle || 'solid',
    showInLegend,
    hideNullableInTooltip,
    tooltipHidePointIndexes: tooltipHidePointIndexes || [],
  };
}
