import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  requestClimateIncidentsTree,
  requestClimateIncidentsTypes,
  setTreeExpandNodes,
} from 'store/climateInsightsTimeline/actions';

import {
  getClimateIncidentsTypes,
  getClimateIncidentsTree,
  getExpandedNodes,
  getLoadedNodes,
  getIsIncidentsTypesFetching,
} from 'store/climateInsightsTimeline/selectors';

import {
  getAllCompartments,
  getOrganizations,
  getCurrentLocation,
  getAllSubNodes,
  getClimateInsightsAccess,
} from 'store/company/selectors';

import { trackPageViewIncidents } from 'store/company/actions';

import { requestIncidentById, requestIncidentPlot } from 'store/incidents/actions';

import Incidents from './Incidents';

const mapDispatchToProps = {
  requestClimateIncidentsTree,
  requestClimateIncidentsTypes,
  setTreeExpandNodes,
  requestIncidentById,
  requestIncidentPlot,
  trackPageViewIncidents,
};

const mapStateToProps = (state, props) => ({
  ...props,
  incidentsTypes: getClimateIncidentsTypes(state),
  incidentsTree: getClimateIncidentsTree(state),
  compartments: getAllCompartments(state),
  locations: getOrganizations(state),
  expanded: getExpandedNodes(state),
  location: getCurrentLocation(state),
  subNodes: getAllSubNodes(state),
  incidentsAccess: getClimateInsightsAccess(state, props),
  loadedNodes: getLoadedNodes(state, props),
  isIncidentsTypesFetching: getIsIncidentsTypesFetching(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Incidents)));
