import React, {
 useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import {
  uniqueId,
} from 'lodash';

import DefaultDialog from 'components/DefaultDialog';
import BigButton from 'components/BigButton';
import ClickOutside from 'components/ClickOutside';
import SearchIcon from 'components/Icons/SearchIcon';
import CloseIcon from 'components/Icons/Close';

import TreeItem from 'components/BenchmarkCompare/components/TreeItem/components/TreeItem';

import styles from './SelectIncidentsDialog.module.css';

const getAllCheckedCycles = (list) => {
  const idsList = list.reduce((acc, group) => {
    if (!group.list) {
      if (group.checked) {
        return [...acc, group.id];
      }

      return acc;
    }

    return [...acc, ...getAllCheckedCycles(group.list)];
  }, []);

  return idsList;
};

const deepFlattenCollection = list => list.reduce((acc, listItem) => {
  if (listItem.list) {
    return [...acc, ...deepFlattenCollection(listItem.list)];
  }

  return [...list];
}, []);

const getSearchOptions = (groupOfIncidents, searchInput) => {
  if (searchInput.trim().length < 1) {
    return [];
  }

  const cyclesWithNames = deepFlattenCollection(groupOfIncidents);

  return cyclesWithNames.filter(cycle => cycle.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1);
};

const handlerOnSelectIncidents = (onClose, onSelect, listState) => {
  const idsList = getAllCheckedCycles(listState);

  onClose();

  return onSelect(idsList);
};


const deepUpdateByUniqId = (pathToToggle, list, id) => list.map((listItem) => {
  if (!listItem.list) {
    if (listItem.id === id) {
      return {
        ...listItem,
        [pathToToggle]: !listItem[pathToToggle]
      };
    }

    return { ...listItem };
  }

  return {
    ...listItem,

    list: deepUpdateByUniqId(pathToToggle, listItem.list, id),
  };
});

const updateByGroupName = (pathToToggle, isAllChecked, fullList, groupItem) => fullList.map((listItem) => {
  if (listItem.groupName === groupItem.groupName) {
    return {
      ...listItem,
      list: listItem.list.map(item => ({
        ...item,
        [pathToToggle]: !isAllChecked,
      }))
    };
  }

  return listItem;
});

const handlerCheckboxClick = (setListState, fullList, id) => {
  const newCyclesList = deepUpdateByUniqId('checked', fullList, id);

  return setListState(newCyclesList);
};

const handlerAllCheckboxClick = (setListState, isAllChecked = false, fullList, incidentGroupItem) => {
  const newCyclesList = updateByGroupName('checked', isAllChecked, fullList, incidentGroupItem);

  return setListState(newCyclesList);
};

const renderIncidentsGroup = (formatMessage, setListState, fullList, incidentGroupItem) => {
  const isAllChecked = incidentGroupItem.list.reduce((acc, item) => {
    if (!item.checked) {
      return false;
    }

    return acc;
  }, true);

  return (
    <div key={uniqueId('tree-incidents-group-level-')}>
      <div className={styles.item}>
        <div className={classnames(styles.title)}>{incidentGroupItem.groupName}</div>
      </div>
      <div>
        <>
          <TreeItem
            key={uniqueId('tree-second-level-')}
            item={{
              id: `all-${incidentGroupItem.groupName}`,
              name: formatMessage({ id: 'button.all' }),
              checked: isAllChecked,
            }}
            onItemCheckboxClick={() => handlerAllCheckboxClick(setListState, isAllChecked, fullList, incidentGroupItem)}
            offset='offsetNone'
            checkboxVisible
          />
          {incidentGroupItem.list.map(incident => (
            <TreeItem
              key={uniqueId('tree-second-level-')}
              item={incident}
              onItemCheckboxClick={() => handlerCheckboxClick(setListState, fullList, incident.id)}
              offset='offsetNone'
              checkboxVisible
            />
          ))}
        </>
      </div>
    </div>
  );
};

const renderList = (formatMessage, setListState, fullList, incidentsList) => incidentsList.map(incidentGroupItem => renderIncidentsGroup(formatMessage, setListState, fullList, incidentGroupItem));

const handlerInputChange = (e, handlerEmptyFilterByCycleId, setSearchInputResultsOpen, setSearchInput) => {
  if (e.target.value === '') {
    handlerEmptyFilterByCycleId();
  }

  setSearchInput(e.target.value);
  setSearchInputResultsOpen(true);
};

// const handlerDocumentKeydown = (event, searchOptions, searchInputResultsOpen, setSearchInputResultsOpen, hoveredOption) => {
const handlerDocumentKeydown = ({
  event, searchOptions, hoveredOption, setHoveredOption, searchInputResultsOpen, setSearchInputResultsOpen, handlerClickToSearchOption
}) => {
  if (searchInputResultsOpen) {
    if (event.key === 'Esc' || event.keyCode === 27) {
      setSearchInputResultsOpen(false);
    }

    if (event.key === 'Enter' || event.keyCode === 13) {
      handlerClickToSearchOption(hoveredOption);
    }
  }

  if (searchInputResultsOpen && hoveredOption && searchOptions && searchOptions.length) {
    const index = searchOptions.findIndex(item => item.id === hoveredOption.id) || 0;

    if (event.key === 'ArrowUp' || event.keyCode === 38) {
      setHoveredOption(searchOptions[index - 1 >= 0 ? index - 1 : index]);
    }

    if (event.key === 'ArrowDown' || event.keyCode === 40) {
      setHoveredOption(searchOptions[index + 1 < searchOptions.length ? index + 1 : index]);
    }
  }
};

const renderSearchResultsBody = ({
  searchOptions,
  searchInput,
  handlerClickToSearchOption,
  hoveredOption,
  setHoveredOption,
}) => (
  <div className={styles.filterOptions}>
    {searchOptions.length ? searchOptions.map((option) => {
      const { id, name } = option;
      const processedFilterWords = searchInput.split(' ');

      const processedTitle = name
        .split(' ')
        .map((titleChunk) => {
          let processedChunk = titleChunk;
          processedFilterWords.forEach((processedFilterWord) => {
            const boldPosition = titleChunk.toLowerCase().indexOf(processedFilterWord.toLowerCase());

            if (boldPosition >= 0 && processedChunk.toLowerCase().localeCompare(titleChunk.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }) === 0) {
              processedChunk = [
                titleChunk.slice(0, boldPosition),
                '<strong>',
                titleChunk.slice(boldPosition, boldPosition + processedFilterWord.length),
                '</strong>',
                titleChunk.slice(boldPosition + processedFilterWord.length),
              ].join('');
            }
          });
          return processedChunk;
        }).join(' ');

        const isHovered = hoveredOption && hoveredOption.id === id;

      /* eslint-disable react/no-danger */
      return (
        <div
          role='button'
          tabIndex={0}
          key={uniqueId('filter-metric-')}
          className={classnames(styles.filterOption, { [styles.hovered]: isHovered })}
          dangerouslySetInnerHTML={{ __html: processedTitle }}
          onClick={() => handlerClickToSearchOption(option)}
          onKeyDown={() => handlerClickToSearchOption(option)}
          onMouseEnter={() => setHoveredOption(option)}
        />
      );
      /* eslint-enable react/no-danger */
    }) : null}
  </div>
);

const renderSearchResults = ({
  formatMessage,
  searchOptions,
  searchInput,
  setSearchInputResultsOpen,
  handlerClickToSearchOption,
  hoveredOption,
  setHoveredOption,
}) => (
  <ClickOutside
    handleClickOutside={() => setSearchInputResultsOpen(false)}
  >
    <div className={styles.filterOptionsPopup}>
      {
        !searchOptions.length && (
          <div className={styles.filterEmpty}>
            {formatMessage({ id: 'graphs.sidePanel.noSearchResult' })}
          </div>
        )
      }
      {
        searchOptions.length ? (
          renderSearchResultsBody({
            searchOptions,
            searchInput,
            handlerClickToSearchOption,
            hoveredOption,
            setHoveredOption,
          })
        ) : null
      }
    </div>
  </ClickOutside>
);

const SelectIncidentsDialog = ({
  intl: { formatMessage },
  onClose,
  onSelect,
  incidentsList,
}) => {
  const inputRef = useRef(null);

  const [searchInput, setSearchInput] = useState('');
  const [searchInputResultsOpen, setSearchInputResultsOpen] = useState(false);
  const [filterById, setFilterById] = useState();
  const [hoveredOption, setHoveredOption] = useState();

  const [listState, setListState] = useState(incidentsList);

  const filteredIncidentsList = listState.map(incidentsListGroup => ({
    ...incidentsListGroup,
    list: incidentsListGroup?.list.filter(listItem => (!filterById || listItem.id === filterById)),
  }))?.filter(groupItem => groupItem?.list?.length > 0);

  const handlerClickToSearchOption = useCallback((option) => {
    setSearchInput(option.name);
    setFilterById(option.id);
    setSearchInputResultsOpen(false);
  }, [
    setSearchInput,
    setFilterById,
    setSearchInputResultsOpen,
  ]);

  const handlerEmptyFilterByCycleId = useCallback(() => {
    setSearchInput('');

    if (filterById) {
      setFilterById();
    }
  }, [
    filterById,
    setSearchInput,
    setFilterById,
  ]);

  const searchOptions = getSearchOptions(incidentsList, searchInput);

  const handleKeyDown = useCallback(event => handlerDocumentKeydown({
    event,
    searchOptions,
    hoveredOption,
    setHoveredOption,
    searchInputResultsOpen,
    setSearchInputResultsOpen,
    handlerClickToSearchOption,
  }), [
    searchOptions,
    hoveredOption,
    setHoveredOption,
    searchInputResultsOpen,
    setSearchInputResultsOpen,
    handlerClickToSearchOption,
  ]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <DefaultDialog
      title={formatMessage({ id: 'alerting.selectIncidents' })}
      onClose={onClose}
      wrapperClassName={styles.layout}
      className={styles.dialog}
      headerClassName={styles.dialogHeader}
      dialogCloseName={styles.dialogClose}
    >
      <div className={styles.body}>
        <div className={styles.content}>
          <div className={styles.filtersAndSearch}>
            {/* TODO: заменить на компонент UiSearchInput */}
            <div className={classnames(styles.filterInputWrapper)}>
              <div className={classnames(styles.filterControl)}>
                <div className={classnames(styles.icon, styles.filterInputButton, styles.filterInputButtonLeft)}>
                  <SearchIcon />
                </div>
                <input
                  type='text'
                  className={styles.filterInput}
                  onChange={e => handlerInputChange(e, handlerEmptyFilterByCycleId, setSearchInputResultsOpen, setSearchInput)}
                  value={searchInput}
                  ref={inputRef}
                  placeholder={formatMessage({ id: 'incidentsSettings.searchIncident' })}
                />
                {searchInput !== '' && (
                  <div
                    role='menu'
                    tabIndex={0}
                    className={classnames(styles.icon, styles.filterInputButton, styles.filterInputButtonRight)}
                    onClick={() => { handlerEmptyFilterByCycleId(); if (inputRef) { inputRef.current.focus(); } }}
                    onKeyDown={() => { handlerEmptyFilterByCycleId(); if (inputRef) { inputRef.current.focus(); } }}
                  >
                    <CloseIcon />
                  </div>
                )}
              </div>
              {searchInputResultsOpen && searchInput.trim().length > 0 && (
                renderSearchResults({
                  formatMessage,
                  searchOptions,
                  searchInput,
                  setSearchInputResultsOpen,
                  handlerClickToSearchOption,
                  hoveredOption,
                  setHoveredOption,
                })
              )}
            </div>
          </div>

          <div className={styles.treeWrapper}>
            {renderList(formatMessage, setListState, listState, filteredIncidentsList)}
          </div>
        </div>
        <div className={styles.actions}>
          <BigButton
            className={classnames(styles.dontSaveButton)}
            title={formatMessage({ id: 'dialog.cancel' })}
            onClick={onClose}
            theme='light'
          />
          <BigButton
            className={classnames(styles.saveButton)}
            title={formatMessage({ id: 'alerting.selectIncidents' })}
            onClick={() => handlerOnSelectIncidents(onClose, onSelect, listState)}
            theme='dark'
          />
        </div>
      </div>
    </DefaultDialog>
  );
};

SelectIncidentsDialog.propTypes = {
  intl: intlShape.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  incidentsList: PropTypes.array,
};

SelectIncidentsDialog.defaultProps = {
  incidentsList: [],
};

export default SelectIncidentsDialog;
