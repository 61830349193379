import { addMinutes, differenceInHours } from 'date-fns';
import addHours from 'date-fns/addHours';
import isEqual from 'date-fns/isEqual';
import differenceInDays from 'date-fns/differenceInDays';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import startOfHour from 'date-fns/startOfHour';

const getDaysInPeriod = period => differenceInDays(
    period.endInclusive,
    period.start
);

const getHoursInPeriod = period => differenceInHours(
    period.endInclusive,
    period.start
);

const INCIDENT_TIMELINE_CONFIG = {
  API_FORMAT: "yyyy-MM-dd'T'HH:mm",
  LEFT_PANEL_WIDTH: 240,
  HEADER_HEIGHT: 60,
  TREE_NODE_Y_PADDING: 12,
  TREE_EXPAND_X_SHIFT: 20,
  CROP_RANGE: true,
  MIN_WIDTH_OF_COLUMN: 36,
  MIN_WIDTH_OF_INCIDENT_LINE: 6,
  TREE_FIRST_NODE_PADDING: 52, // 20,
  TREE_BUTTON_SCROLL_RESERVE: 0 // 52 // 68
};

const getColumnWidthByViewport = (
  viewport,
  periods
) => {
  const width = viewport.width - INCIDENT_TIMELINE_CONFIG.LEFT_PANEL_WIDTH;
  const countedColWidth = Math.floor(width / periods.length);

  if (countedColWidth < INCIDENT_TIMELINE_CONFIG.MIN_WIDTH_OF_COLUMN) {
    return INCIDENT_TIMELINE_CONFIG.MIN_WIDTH_OF_COLUMN;
  }

  return countedColWidth;
};

const getPeriodByDays = (days) => {
  if (days === 1) {
    return 'today';
  }

  if (days >= 2 && days <= 5) {
    return 'last2Days';
  }

  if (days >= 6 && days <= 25) {
    return 'last7Days';
  }

  if (days >= 26 && days <= 90) {
    return 'last30Days';
  }

  return 'last90Days';
};

const getTicks = (period) => {
  const daysInPeriod = getDaysInPeriod(period);
  const hoursInPeriod = getHoursInPeriod(period);

  // per 1 hour
  if (daysInPeriod === 1) {
    return hoursInPeriod;
  }

  // per 2 hour
  if (daysInPeriod >= 2 && daysInPeriod <= 5) {
    return Math.floor(hoursInPeriod / 2);
  }

  // per 12 hour
  if (daysInPeriod >= 6 && daysInPeriod <= 25) {
    return Math.floor(hoursInPeriod / 12);
  }

  // per 1 day
  if (daysInPeriod >= 26 && daysInPeriod < 90) {
    return daysInPeriod;
  }

  // per 7 days
  return Math.floor(daysInPeriod / 7);
};

const isDayBorder = (period, tick) => {
  const periodType = period.periodType === 'custom' ? getPeriodByDays(
    getDaysInPeriod(period)
  ) : period.periodType;

  if (periodType === 'today') { // hours
    return tick === 0;
  }
  if (periodType === 'last2Days') { // hours
    return tick % 12 === 0;
  }
  if (periodType === 'last7Days') { // hours
    return tick % 2 === 0;
  }
  if (periodType === 'last30Days') { // days
    return tick === 0;
  }
  if (periodType === 'last90Days') { // days
    return tick % 6 === 0;
  }
  return tick === 0;
};

const getAddValue = (period) => {
  const periodType = period.periodType === 'custom' ? getPeriodByDays(
    getDaysInPeriod(period)
  ) : period.periodType;

  if (periodType === 'today') {
    return 1;
  }
  if (periodType === 'last2Days') {
    return 2;
  }
  if (periodType === 'last7Days') {
    return 12;
  }
  if (periodType === 'last30Days') {
    return 1;
  }
  return 7;
};

const getAddFunc = (period) => {
  const periodType = period.periodType === 'custom' ? getPeriodByDays(
    getDaysInPeriod(period)
  ) : period.periodType;

  if (periodType === 'today') {
    return addHours;
  }
  if (periodType === 'last2Days') {
    return addHours;
  }
  if (periodType === 'last7Days') {
    return addHours;
  }
  if (periodType === 'last30Days') {
    return addDays;
  }
  if (periodType === 'last90Days') {
    return addDays;
  }
  return addMinutes;
};

const slicePeriods = (period) => {
  const periods = [];

  const addFunc = getAddFunc(period);
  const ticks = getTicks(period);
  const addValue = getAddValue(period);

  let currentPeriod = null;
  for (let i = 0; i < ticks; i += 1) {
    if (currentPeriod === null) {
      currentPeriod = period.start;
    }
    const endCurrentPeriod = addFunc(currentPeriod, addValue);
    periods.push({
      start: currentPeriod,
      end: endCurrentPeriod,
      periodNumber: i,
      isShowHead: isDayBorder(period, i)
    });
    currentPeriod = endCurrentPeriod;
  }
  return periods;
};

const getCurrentPeriod = (period) => {
  const periods = slicePeriods(period);
  if (period.periodType === 'today') {
    const hourStart = startOfHour(new Date());
    return periods.findIndex(item => isEqual(item.start, hourStart));
  }
  if (period.periodType === 'last2Days') {
    const hourStart = startOfHour(new Date());
    return periods.findIndex(item => hourStart >= item.start && hourStart < item.end);
  }
  if (period.periodType === 'last7Days') {
    const hourStart = startOfHour(new Date());
    return periods.findIndex(item => hourStart >= item.start && hourStart < item.end);
  }
  if (period.periodType === 'last30Days') {
    const dayStart = startOfDay(new Date());
    return periods.findIndex(item => dayStart >= item.start && dayStart < item.end);
  }
  const hourStart = startOfHour(new Date());
  return periods.findIndex(item => isEqual(item.start, hourStart));
};

export {
  INCIDENT_TIMELINE_CONFIG,
  getCurrentPeriod,
  slicePeriods,
  getColumnWidthByViewport,
};
