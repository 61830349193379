import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import isNumber from 'lodash/isNumber';

import styles from './SummaryTable.module.css';

const drawValue = value => (isNumber(value) ? value.toString() : '—');

const SummaryRow = ({
  name,
  total,
  average,
  min,
  max,
  classNameTr
}) => (
  <tr className={classNameTr}>
    <td className={styles.nameCell}>
      <div className={styles.legendItem}>
        <div className={styles.legendItemColor}>
          <div className={styles.legendItemColorSquare} />
        </div>
        <div className={styles.legendItemTitle}>{name}</div>
      </div>
    </td><td className={styles.centerSeparator} />
    <td className={styles.valueCell}>{drawValue(total)}</td><td className={styles.valueSeparatorCell} />
    <td className={styles.valueCell}>{drawValue(average)}</td><td className={styles.valueSeparatorCell} />
    <td className={styles.valueCell}>{drawValue(max)}</td><td className={styles.valueSeparatorCell} />
    <td className={styles.valueCell}>{drawValue(min)}</td><td className={styles.endCell} />
  </tr>
);

SummaryRow.propTypes = {
  name: PropTypes.string.isRequired,
  total: PropTypes.oneOfType([PropTypes.number, null]).isRequired,
  average: PropTypes.oneOfType([PropTypes.number, null]).isRequired,
  min: PropTypes.oneOfType([PropTypes.number, null]).isRequired,
  max: PropTypes.oneOfType([PropTypes.number, null]).isRequired,
  classNameTr: PropTypes.number,
};

SummaryRow.defaultProps = {
  classNameTr: null
};

const SummaryTable = ({
  cycle,
  model,
  nasa
}) => (
  <table className={styles.summaryTable}>
    <thead>
      <tr className={styles.headerRow}>
        <td className={styles.nameCell}>&nbsp;</td><td className={styles.centerSeparator} />
        <td className={styles.titleCell}>
          <FormattedMessage id='unrealizedPotential.total' />
        </td>
        <td className={styles.valueSeparatorCell} />
        <td className={styles.titleCell}>
          <FormattedMessage id='unrealizedPotential.avg' />
        </td>
        <td className={styles.valueSeparatorCell} />
        <td className={styles.titleCell}>
          <FormattedMessage id='unrealizedPotential.max' />
        </td>
        <td className={styles.valueSeparatorCell} />
        <td className={styles.titleCell}>
          <FormattedMessage id='unrealizedPotential.min' />
        </td>
        <td className={styles.endCell} />
      </tr>
    </thead>
    <tbody>
      <SummaryRow classNameTr={styles.modelRowCycle} name={<FormattedMessage id='unrealizedPotential.cycle' />} {...cycle} />
      <SummaryRow classNameTr={styles.modelRow} name={<FormattedMessage id='unrealizedPotential.model' />} {...model} />
      {nasa && <SummaryRow classNameTr={styles.nasaRow} name={<FormattedMessage id='unrealizedPotential.nasa' />} {...nasa} />}
    </tbody>
  </table>
);

SummaryTable.propTypes = {
  cycle: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  nasa: PropTypes.object
};

SummaryTable.defaultProps = {
  nasa: null
};

export default SummaryTable;
