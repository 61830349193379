import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import styles from './DashboardReportTable.module.css';

import NestedTable from '../NestedTable';

class DashboardReportTable extends PureComponent {
  static propTypes = {
    graphKeyId: PropTypes.string.isRequired,
    tableHeaderItems: PropTypes.array.isRequired,
    tableBodyItems: PropTypes.array.isRequired,
    sorting: PropTypes.object,
    graphKey: PropTypes.string,
    onRowClick: PropTypes.func,
    onUpdateSorting: PropTypes.func,
  };

  static defaultProps = {
    onRowClick: null,
    sorting: null,
    graphKey: 'total',
    onUpdateSorting: null,
  };

  handlerRowClick = (options) => {
    const { onRowClick, graphKeyId } = this.props;

    if (onRowClick) {
      onRowClick({ ...options, key: graphKeyId });
    }
  }

  render() {
    const {
      graphKey, tableBodyItems, tableHeaderItems, sorting, onUpdateSorting,
    } = this.props;

    return (
      <NestedTable
        className={styles.table}
        headerItems={tableHeaderItems}
        bodyItems={tableBodyItems}
        selectedRow={graphKey}
        updateSorting={onUpdateSorting}
        tableSorting={sorting}
        onRowClick={this.handlerRowClick}
        tableSize='small'
        showEmptyState
      />
    );
  }
}

export default injectIntl(DashboardReportTable);
