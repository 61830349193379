import { find } from 'lodash';

import { HIDDEN_METRICS, HARVEST_METRICS } from '../constants';


/**
 * Возвращает номера недель указанного месяца указанного года.
 *
 * @param {number} metricId - id метрики.
 * @return {boolean} Скрываем ли мы эту метрику при выводе.
 */
export const isMetricHidden = metricId => HIDDEN_METRICS.includes(metricId);

/**
 * Возвращает метрику урожая (для разных культур может отличаться id метрики, поэтому ищем в массиве возможных)
 *
 * @param {Oject} metrics - Массив метрик.
 * @return {object} Метрика урожая (havest).
 */
export const getHarvestMetric = metrics => find(metrics, metric => HARVEST_METRICS.includes(metric.id));

