import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import DefaultPlate from '../../../DefaultPlate';

import styles from './CellWithWarning.module.css';

const CellWithWarning = React.memo(({
  value, warning, className, warningClassName, alignLeft, alignBottom, alignTop,
}) => (
  <div className={classnames(
    styles.cellWithWarning,
    className,
    {
      [styles.alignLeft]: alignLeft,
      [styles.alignBottom]: alignBottom,
      [styles.alignTop]: alignTop,
    }
  )}
  >
    <span>{value}</span>
    <div className={classnames(styles.warningContent, warningClassName)}>
      <DefaultPlate className={styles.warningPlate} type='warning'>
        {warning}
      </DefaultPlate>
    </div>
  </div>
));

CellWithWarning.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  warning: PropTypes.string.isRequired,
  className: PropTypes.string,
  warningClassName: PropTypes.string,
  alignLeft: PropTypes.bool,
  alignBottom: PropTypes.bool,
  alignTop: PropTypes.bool,
};

CellWithWarning.defaultProps = {
  className: null,
  warningClassName: null,
  alignLeft: false,
  alignBottom: false,
  alignTop: false,
};

export default CellWithWarning;
