// TODO: Нужно переделать работу VarietySearchSelect на productGroup, а не отдельно fruitClassCode и varietyId
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import {
  get, find, uniqBy, sortBy, indexOf
} from 'lodash';
import classnames from 'classnames';

import { getMlName, getVarietyName } from 'helpers/getVarietyName';
import Select from 'components/Select';
import DefaultSearchList from 'components/DefaultSearchList';
import BigSelectInput from 'components/BigSelectInput';

import styles from './VarietySearchSelect.module.css';

class VarietySearchSelect extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    onSelectOption: PropTypes.func.isRequired,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    speciesList: PropTypes.array.isRequired,
    selectedOption: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    customPlaceholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    className: PropTypes.string,
    classNameButton: PropTypes.string,
    classNamePopup: PropTypes.string,
    withoutArrow: PropTypes.bool,
    onlyContent: PropTypes.bool,
    isShowAllSpecies: PropTypes.bool,
    opened: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    size: PropTypes.string,
    customTitle: PropTypes.string,
    closeOnChange: PropTypes.bool,
    selectComponent: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),
    title: PropTypes.string,
    withCheckBox: PropTypes.bool,
    enabledSpecies: PropTypes.array,
    isLoading: PropTypes.bool,
    isDefaultSelect: PropTypes.bool,
    showActiveState: PropTypes.bool
  };

  static defaultProps = {
    isShowAllSpecies: false,
    selectedOption: null,
    customPlaceholder: null,
    className: null,
    classNameButton: null,
    classNamePopup: null,
    withoutArrow: false,
    onlyContent: false,
    opened: false,
    disabled: false,
    value: undefined,
    customTitle: undefined,
    size: 'medium',
    closeOnChange: undefined,
    selectComponent: Select,
    title: undefined,
    withCheckBox: false,
    enabledSpecies: null,
    isLoading: false,
    isDefaultSelect: false,
    showActiveState: true
  };

  state = {
    varietySelectOpened: this.props.opened,
    query: '',
  };

  getPlaceholderName = (currentVariety, currentFruitClass, selectedOption, locale, formatMessage) => {
    const {
      fruitClasses,
      isShowAllSpecies
    } = this.props;

    if (currentVariety) {
      return getVarietyName(currentVariety, locale);
    } if (currentFruitClass) {
      const fruitClass = fruitClasses.find(item => item.id === currentFruitClass);
      return getMlName(fruitClass, locale);
    }

    if (!selectedOption && isShowAllSpecies) {
      return formatMessage({ id: 'dashboards.allSpecies' });
    }

    return formatMessage({ id: `plantingCycles.allSpecies.${selectedOption}` });
  }

  handlerCloseSelect = () => {
    const { varietySelectOpened } = this.state;

    if (varietySelectOpened) {
      this.setState({ varietySelectOpened: false });
    }
  };

  handlerSelectOption = (option, options, onlyCheck) => {
    const { onSelectOption, withCheckBox } = this.props;

    onSelectOption(option, options, onlyCheck);

    if (!withCheckBox) {
      this.handlerCloseSelect();
    }
  }

  handlerSelectState = newState => this.setState({ varietySelectOpened: newState });

  renderOptions = () => {
    const {
      intl, varieties, fruitClasses, selectedOption, isShowAllSpecies,
      speciesList, enabledSpecies, withCheckBox, showActiveState
    } = this.props;

    const {
      query,
    } = this.state;

    const { formatMessage, locale } = intl;

    const allOptions = [];

    if (isShowAllSpecies) {
      allOptions.push({
        id: null,
        name: formatMessage({ id: 'dashboards.allSpecies' }),
        species: null,
        type: null,
        groupId: null,
        groupName: null,
        sortId: '#',
      });
    }

    let varietyOptions = varieties.map((variety) => {
      const species = get(variety, 'attributes.species');
      const id = get(variety, 'id');
      const name = getMlName(variety, locale);
      const groupId = `${species}.varieties`;
      const groupName = formatMessage({ id: `plantingCycles.ofSpecies.${species}` });
      const speciesSortId = indexOf(speciesList, species);

      return {
        id,
        name,
        species,
        type: 'variety',
        groupId,
        groupName: formatMessage({ id: 'benchmarking.varieties' }, { name: groupName }),
        sortId: `${speciesSortId} / 2 / ${name}`,
      };
    });

    if (enabledSpecies) {
      varietyOptions = varietyOptions.filter(item => enabledSpecies.includes(item.species));
    }

    let speciesOptions = varieties.map((variety) => {
      const species = get(variety, 'attributes.species');
      const id = get(variety, 'attributes.species');
      const name = formatMessage({ id: `plantingCycles.allSpecies.${species}` });
      const groupId = get(variety, 'attributes.species');
      const groupName = formatMessage({ id: `plantingCycles.speciesPlural.${species}` });
      const speciesSortId = indexOf(speciesList, species);

      return {
        id,
        name,
        species,
        type: 'species',
        groupId,
        groupName,
        sortId: `${speciesSortId} / 1`,
      };
    });

    if (enabledSpecies) {
      speciesOptions = speciesOptions.filter(item => enabledSpecies.includes(item.species));
    }

    let fruitClassOptions = varieties.map((variety) => {
      const species = get(variety, 'attributes.species');
      const id = get(variety, 'attributes.fruitClass');
      const fruitClass = fruitClasses.find(item => item.id === id);
      const name = getMlName(fruitClass, locale);
      const groupId = `${species}.fruitClasses`;
      const groupName = formatMessage({ id: `plantingCycles.ofSpecies.${species}` });
      const speciesSortId = indexOf(speciesList, species);

      return {
        id,
        name,
        species,
        type: 'fruitClass',
        groupId,
        groupName: formatMessage({ id: 'benchmarking.fruitClasses' }, { name: groupName }),
        sortId: `${speciesSortId} / 3 / ${name}`,
      };
    });

    if (enabledSpecies) {
      fruitClassOptions = fruitClassOptions.filter(item => enabledSpecies.includes(item.species));
    }

    const options = sortBy([
      ...allOptions,
      ...varietyOptions,
      ...uniqBy(speciesOptions, 'id'),
      ...uniqBy(fruitClassOptions, 'id')
    ], 'sortId');

    return (
      <DefaultSearchList
        placeholder={formatMessage({ id: 'benchmarking.search' })}
        options={options}
        selectedOption={selectedOption}
        isFetching={false}
        value={query}
        empty={formatMessage({ id: 'graphs.sidePanel.noSearchResult' })}
        onChanged={({ value }) => {
          this.setState({ query: value });
        }}
        withCheckBox={withCheckBox}
        showActiveState={showActiveState}
        onSelected={({ option, options: defOptions, onlyCheck }) => {
          // TODO: составление id ProductGroup нужно вынести сюда, на уровень VarietySearchSelect
          const payload = {};

          if (get(option, 'type') === 'species') {
            payload.species = get(option, 'id');
          }

          if (get(option, 'type') === 'fruitClass') {
            payload.fruitClassCode = get(option, 'id');
            payload.species = get(option, 'species');
          }

          if (get(option, 'type') === 'variety') {
            payload.varietyId = get(option, 'id');
            payload.species = get(option, 'species');
          }

          this.handlerSelectOption(payload, defOptions, onlyCheck);
        }}
      />
    );
  }

  renderSelect = () => {
    const {
      intl, varieties, selectedOption, customPlaceholder,
      classNameButton, withoutArrow,
      disabled, fruitClasses, size, selectComponent, classNamePopup,
      value, customTitle, closeOnChange, className, isDefaultSelect
    } = this.props;
    const { formatMessage, locale } = intl;
    const { varietySelectOpened } = this.state;

    // TODO: For backward compatibility. Harvest benchmarking, etc.
    const selectedId = Array.isArray(selectedOption) ? selectedOption[0] : selectedOption;

    const currentVariety = find(varieties, { id: selectedId });
    const currentFruitClass = find(fruitClasses, { id: selectedId }) ? selectedId : null;

    // FIX ME
    const ParentComponent = isDefaultSelect ? BigSelectInput : selectComponent;
    const selectboxTitle = isDefaultSelect ? (customTitle || this.getPlaceholderName(currentVariety, currentFruitClass, selectedId, locale, formatMessage)) : customTitle;

    return (
      <ParentComponent
        classNameButton={classnames(styles.longSelectButton, classNameButton)}
        classNamePopup={classnames(styles.longSelectPopup, classNamePopup)}
        className={classnames(styles.select, className)}
        placeholder={customPlaceholder || this.getPlaceholderName(currentVariety, currentFruitClass, selectedId, locale, formatMessage)}
        theme='white'
        size={size}
        open={varietySelectOpened}
        onOpenChange={this.handlerSelectState}
        textEllipsis
        withoutArrow={withoutArrow}
        disabled={disabled}
        value={value}
        customTitle={selectboxTitle}
        closeOnChange={closeOnChange}
      >
        {this.renderOptions()}
      </ParentComponent>
    );
  };

  render() {
    const {
      title, onlyContent, isLoading
    } = this.props;

    if (isLoading) {
      if (title) {
        return (
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{title}</div>
            <div className={styles.loading} />
          </div>
        );
      }
      return <div className={styles.loading} />;
    }

    if (onlyContent) {
      return (
        <div className={styles.popup}>
          {this.renderOptions()}
        </div>
      );
    }

    if (title) {
      return (
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{title}</div>
          {this.renderSelect()}
        </div>
      );
    }

    return this.renderSelect();
  }
}
export default injectIntl(VarietySearchSelect);
