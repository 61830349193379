import React, { Component } from 'react';
import { withRouter, matchPath } from 'react-router';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { eventFrom } from 'event-from';

import storageWrapper from 'helpers/storageWrapper';

import UiDropdownByHover from 'ui/UiDropdownByHover';
import CropCompare from 'components/CropCompare';
import ArrowDownLightIcon from 'components/Icons/ArrowDownLightIcon';

import NavigationMenu from '../NavigationMenu';
import DataQualityNotification from '../DataQualityNotification';
import ApiUseNotification from '../ApiUseNotification';

import styles from './Header.module.css';

import { ReactComponent as Logo } from './assets/logo.svg';
import { ReactComponent as LogoMini } from './assets/logo_mini.svg';
import { ReactComponent as DashboardsIcon } from './assets/dashboards.svg';
import { ReactComponent as CropsIcon } from './assets/crops.svg';
import { ReactComponent as PlansIcon } from './assets/plans.svg';
import { ReactComponent as GraphsIcon } from './assets/graphs.svg';
import { ReactComponent as EnergyIcon } from './assets/energy.svg';

const safeLocalStorage = storageWrapper.get('localStorage');

class Header extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    userProfile: PropTypes.object,
    locations: PropTypes.array,
    locale: PropTypes.string,
    organization: PropTypes.object,
    setIntl: PropTypes.func.isRequired,
    clearCardsState: PropTypes.func.isRequired,
    setNavigationMenuState: PropTypes.func.isRequired,
    clearCompanyStore: PropTypes.func.isRequired,
    isLocationSpecificUrl: PropTypes.bool,
    digitalTwinAccess: PropTypes.bool,
    harvestForecastAccess: PropTypes.bool,
    incidentsAccess: PropTypes.bool,
  };

  static defaultProps = {
    userProfile: null,
    locations: null,
    organization: null,
    locale: 'en',
    isLocationSpecificUrl: false,
    digitalTwinAccess: false,
    harvestForecastAccess: false,
    incidentsAccess: false,
  };

  state = {
    isOpenMenu: false,
  }

  localeChange = (id) => {
    const { setIntl } = this.props;

    if (setIntl) {
      setIntl(id);
    }
  };

  handlerMenuClick = () => {
    const { clearCardsState } = this.props;

    clearCardsState();
  };

  renderButtonTitle = ({
    match, icon, text,
  }) => (
    <div
      onClick={(e) => {
        if (eventFrom(e) === 'touch') {
          e.preventDefault();
        } else {
          this.handlerMenuClick();
        }
      }}
      className={classnames(
        styles.route,
        styles.dropdownRoute,
        {
          [styles.routeActive]: match,
        }
      )}
      activeClassName={styles.routeActive}
      tabIndex={0}
      role='button'
    >
      <span className={styles.routeText}>
        {text}
        <ArrowDownLightIcon className={styles.arrowDownIcon} />
      </span>
      <span className={styles.routeIcon}>
        {icon}
      </span>
    </div>
  );

  renderDefaultRouteLink = ({
    isMatch, pathname, menuText, menuIcon
  }) => {
    const { history } = this.props;

    return (
      <RouterLink
        onClick={this.handlerMenuClick}
        to={{
          pathname,
          search: isMatch ? history.location.search : '',
        }}
        className={classnames(styles.route, 'header__link')}
        activeClassName={styles.routeActive}
        tabIndex={0}
        role='button'
      >
        <span className={styles.routeText}>
          {menuText}
        </span>
        <span className={styles.routeIcon}>
          {menuIcon}
        </span>
      </RouterLink>
    );
  }

  renderCropsRouteLink = () => {
    const {
      history, organization, digitalTwinAccess, harvestForecastAccess,
    } = this.props;
    const { isOpenMenu } = this.state;

    const cropsMatch = matchPath(history.location.pathname, {
      path: '/:organizationSlug/crops',
      exact: false,
      strict: false
    });

    const organizationSlug = organization.attributes.slug;

    return (
      <UiDropdownByHover
        className={styles.dropdown}
        contentClassName={styles.dropdownContent}
        buttonElement={this.renderButtonTitle({
          match: cropsMatch,
          icon: <CropsIcon />,
          text: <FormattedMessage id='header.links.input' />,
          pathname: `/${organizationSlug}/crops`,
        })}
        isOpenExternal={isOpenMenu}
        setIsOpenExternal={menuState => this.setState({ isOpenMenu: menuState })}
      >
        <RouterLink
          onClick={() => {
            this.setState({ isOpenMenu: false });
            this.handlerMenuClick();
          }}
          className={classnames(styles.routeDropdownChild, 'header__link')}
          activeClassName={styles.routeActive}
          to={`/${organizationSlug}/crops`}
          tabIndex={0}
          role='button'
          exact
        >
          <FormattedMessage id='cycle.plantingCycles' />
        </RouterLink>
        {harvestForecastAccess && (
          <RouterLink
            onClick={() => {
              this.setState({ isOpenMenu: false });
              this.handlerMenuClick();
            }}
            className={classnames(styles.routeDropdownChild, 'header__link')}
            activeClassName={styles.routeActive}
            to={`/${organizationSlug}/crops/forecasts`}
            tabIndex={0}
            role='button'
            exact
          >
            <FormattedMessage id='forecast.harvestForecasts' />
          </RouterLink>
        )}
        {digitalTwinAccess && (
          <RouterLink
            onClick={() => {
              this.setState({ isOpenMenu: false });
              this.handlerMenuClick();
            }}
            className={classnames(styles.routeDropdownChild, 'header__link')}
            activeClassName={styles.routeActive}
            to={`/${organizationSlug}/crops/model`}
            tabIndex={0}
            role='button'
            exact
          >
            <FormattedMessage id='cycle.cropModel' />
          </RouterLink>
        )}
      </UiDropdownByHover>
    );
  };

  renderAnalysisRouteLink = () => {
    const { history, organization, incidentsAccess } = this.props;
    const { isOpenMenu } = this.state;

    const graphsMatch = matchPath(history.location.pathname, {
      path: '/:organizationSlug/graphs',
      exact: false,
      strict: false
    });

    const incidentsMatch = matchPath(history.location.pathname, {
      path: '/:organizationSlug/incidents',
      exact: false,
      strict: false
    });

    return (
      <UiDropdownByHover
        className={styles.dropdown}
        contentClassName={styles.dropdownContent}
        buttonElement={this.renderButtonTitle({
          match: graphsMatch || incidentsMatch,
          icon: <GraphsIcon />,
          text: <FormattedMessage id='header.links.analysis' />,
          pathname: `/${organization.attributes.slug}/graphs`,
        })}
        isOpenExternal={isOpenMenu}
        setIsOpenExternal={menuState => this.setState({ isOpenMenu: menuState })}
      >
        <RouterLink
          onClick={() => {
            this.setState({ isOpenMenu: false });
            this.handlerMenuClick();
          }}
          className={classnames(styles.routeDropdownChild, 'header__link')}
          activeClassName={styles.routeActive}
          to={`/${organization.attributes.slug}/graphs`}
          tabIndex={0}
          role='button'
          exact
        >
          <FormattedMessage id='header.links.graphs' />
        </RouterLink>
        {incidentsAccess && (
          <RouterLink
            onClick={() => {
              this.setState({ isOpenMenu: false });
              this.handlerMenuClick();
            }}
            className={classnames(styles.routeDropdownChild, 'header__link')}
            activeClassName={styles.routeActive}
            to={`/${organization.attributes.slug}/incidents`}
            tabIndex={0}
            role='button'
            exact
          >
            <FormattedMessage id='header.links.incidents' />
          </RouterLink>
        )}
      </UiDropdownByHover>
    );
  };

  renderLinks = ({
    className,
    organization,
    history,
  }) => {
    const dashboardsMatch = matchPath(history.location.pathname, {
      path: '/:organizationSlug/dashboards',
      exact: true,
      strict: false
    });

    // На случай перехода с дочернего роута (например benchmarking/compare)
    const dashboardsWithTabMatch = matchPath(history.location.pathname, {
      path: '/:organizationSlug/dashboards/:tabName',
      exact: true,
      strict: false
    });

    const plansMatch = matchPath(history.location.pathname, {
      path: '/:organizationSlug/plans',
      exact: true,
      strict: false
    });

    const resourcesMatch = matchPath(history.location.pathname, {
      path: '/:organizationSlug/resources',
      exact: false,
      strict: false
    });

    const dashboardsCurrentTab = safeLocalStorage.getItem('dashboardsCurrentTab');
    // Т.к. dashboard climate-insights удалён
    const dashboardsLink = (dashboardsCurrentTab && dashboardsCurrentTab !== 'climate-insights') ? `/${dashboardsCurrentTab}` : '';

    return (
      <div className={className}>
        {this.renderDefaultRouteLink({
          isMatch: dashboardsMatch || dashboardsWithTabMatch,
          pathname: `/${organization.attributes.slug}/dashboards${dashboardsLink}`,
          menuText: <FormattedMessage id='header.links.dashboards' />,
          menuIcon: <DashboardsIcon />,
        })}
        {this.renderCropsRouteLink()}
        {this.renderDefaultRouteLink({
          isMatch: plansMatch,
          pathname: `/${organization.attributes.slug}/plans`,
          menuText: <FormattedMessage id='header.links.plans' />,
          menuIcon: <PlansIcon />,
        })}
        {this.renderDefaultRouteLink({
          isMatch: resourcesMatch,
          pathname: `/${organization.attributes.slug}/resources`,
          menuText: <FormattedMessage id='header.links.resources' />,
          menuIcon: <EnergyIcon className={styles.energyIcon} />,
        })}
        {this.renderAnalysisRouteLink()}
      </div>
    );
  }

  render() {
    const {
      history,
      userProfile,
      locations,
      organization,
      locale,
      setNavigationMenuState,
      isLocationSpecificUrl,
      clearCompanyStore,
    } = this.props;

    return (
      <header className={styles.header}>
        <RouterLink to={`/${organization.attributes.slug}`} className={classnames(styles.headerLogo)}>
          <div className={styles.logo}>
            <Logo className={styles.logoDesktop} />
            <LogoMini className={styles.logoMobile} />
          </div>
        </RouterLink>

        {this.renderLinks({
          className: styles.routes,
          organization,
          history,
        })}

        <div className={styles.navigationWrapper}>
          <ApiUseNotification />
          <CropCompare />
          <DataQualityNotification />
          <NavigationMenu
            userProfile={userProfile}
            locations={locations}
            organization={organization}
            locale={locale}
            localeChange={this.localeChange}
            setNavigationMenuState={setNavigationMenuState}
            isLocationSpecificUrl={isLocationSpecificUrl}
            clearCompanyStore={clearCompanyStore}
          />
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
