import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getBenchmarkGroups,
  getIsBenchmarkGroupsFetching,
} from 'store/benchmark/selectors';
import { requestBenchmarkGroups } from 'store/benchmark/actions';

import { getDefaultOpenFromRoute } from 'store/company/selectors';
import { trackPageViewSettings } from 'store/company/actions';

import SettingsBenchmarking from '../components/SettingsBenchmarking';

const mapDispatchToProps = {
  requestBenchmarkGroups,
  trackPageViewSettings,
};

const mapStateToProps = (state, props) => ({
  groups: getBenchmarkGroups(state),
  isBenchmarkGroupsFetching: getIsBenchmarkGroupsFetching(state),
  defaultOpen: getDefaultOpenFromRoute(state, props),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsBenchmarking));
