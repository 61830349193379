import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import Select from 'components/Select';

import SelectBoxFilter from '../../../SelectBoxFilter';


const IncidentsGroupByFilter = ({
  intl,
  onFiltersChange,
  selectedOption,
  isMobile,
  selectComponent,
  onSetPlaceholder
}) => {
  const { formatMessage } = intl;

  const options = useMemo(() => ({
      options: [{
        value: 'greenhouses',
        label: formatMessage({ id: 'incidentsTimeline.incidentGrouping.greenhouses' })
      }, /* {
      value: 'cropCycle',
      label: formatMessage({ id: 'incidentsTimeline.incidentGrouping.cropCycle' })
    }, */{
        value: 'incidentTypes',
        label: formatMessage({ id: 'incidentsTimeline.incidentGrouping.incidentTypes' })
      }],
      title: formatMessage({ id: 'incidentsTimeline.filters.groupBy' }),
      onChange: (changes) => {
        onFiltersChange({
          incidentsGroupBy: changes?.value
        });
      }
    }), [onFiltersChange, formatMessage]);

  const placeholder = useMemo(() =>
    (isMobile ? formatMessage({ id: 'incidentsTimeline.filters.mobile.groupBy' }) : ''),
    [formatMessage, isMobile]);

  useEffect(() => {
    if (onSetPlaceholder) {
      onSetPlaceholder('incidentsGroupBy',
        options.options.find(item => item.value === selectedOption)?.label);
    }
  }, [onSetPlaceholder, options, selectedOption]);

  return (
    <SelectBoxFilter
      {...options}
      value={selectedOption}
      isMobile={isMobile}
      selectComponent={selectComponent}
      customPlaceholder={placeholder}
    />
  );
};

IncidentsGroupByFilter.propTypes = {
  intl: intlShape.isRequired,
  onFiltersChange: PropTypes.func,
  selectedOption: PropTypes.array,
  isMobile: PropTypes.bool,
  selectComponent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  onSetPlaceholder: PropTypes.func
};

IncidentsGroupByFilter.defaultProps = {
  onFiltersChange: null,
  selectedOption: [],
  isMobile: false,
  selectComponent: Select,
  onSetPlaceholder: null
};

export default IncidentsGroupByFilter;

