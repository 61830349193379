import React from 'react';

import { MetricDef } from 'store/graphs/types';
import classnames from 'classnames';
import getPresetMetricKey from '../../../../../../utils/getPresetMetricKey';
import styles from '../../index.module.css';


type CellDefaultProp = {
  presetMetric: MetricDef;
  groupId: number;
  headerKey: string;
  children: React.ReactNode;
  isMetricDatasFetching: boolean;
  className: string;
  // eslint-disable-next-line react/require-default-props
  align?: 'left' | 'center' | 'right' | 'justify' | 'char' | undefined;
}

const CellDefault = ({
  children,
  presetMetric,
  headerKey,
  groupId,
  className = styles.text,
  isMetricDatasFetching,
  align
}: CellDefaultProp) => (
  <td
    key={`${getPresetMetricKey(presetMetric, `${groupId}`)}-${headerKey.replace('.', '-')}`}
    className={classnames(className, { [styles.loading]: isMetricDatasFetching })}
    align={align}
  >
    {isMetricDatasFetching ? <div className={styles.loading} /> : children}
  </td>
);

export default CellDefault;
