import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { matchPath, withRouter } from 'react-router';

import { isNil } from 'lodash';

import {
  getOrganizationSlug,
  getIsBenchmarkAvailable,
  getIsCropCompareAvailable,

  getBenchmarkAllLocations,
  getBenchmarkAllProducts,
  getBenchmarkAllCompartments,
  getBenchmarkAllFruitClasses,
  getBenchmarkAllVarieties,
} from 'store/company/selectors';

import {
  getCyclesToCompare,
  getCyclesToCompareFromRoute,
} from 'store/benchmark/selectors';

import {
  removeCycleFromComparison,
  replaceAllCyclesInComparison,
} from 'store/benchmark/actions';

import CropCompare from '../components/CropCompare';

const mapDispatchToProps = {
  removeCycleFromComparison,
  replaceAllCyclesInComparison,
};


const mapStateToProps = (state, props) => {
  const { location } = props;

  const dashboardsPageMatch = matchPath(location?.pathname, {
    path: '/:organizationSlug/dashboards/:tabName',
    exact: false,
    strict: false
  });

  const cropsComparePageMatch = matchPath(location?.pathname, {
    path: '/:organizationSlug/crops/compare',
    exact: false,
    strict: false
  });

    // не используем match.params.tabName т.к. в хедере он будет пустой
  const tabName = dashboardsPageMatch?.params?.tabName;

  return {
    ...props,

    isCropCompareAvailable: getIsCropCompareAvailable(state, props),
    organizationSlug: getOrganizationSlug(state, props),
    cyclesToCompare: getCyclesToCompare(state, props),
    cyclesToCompareFromRoute: getCyclesToCompareFromRoute(state, props),
    isBenchmarkAvailable: getIsBenchmarkAvailable(state, props),
    isCropsComparePage: !isNil(cropsComparePageMatch),

    locations: getBenchmarkAllLocations(state, props), // locations: getOrganizations(state, props),
    varieties: getBenchmarkAllVarieties(state, props), // varieties: getVarietiesInLocationWithTargetWeight(state, props),
    fruitClasses: getBenchmarkAllFruitClasses(state, props), // fruitClasses: getAllFruitClasses(state, props),
    compartments: getBenchmarkAllCompartments(state, props), // compartments: getAllCompartments(state),
    allPlantingCycles: getBenchmarkAllProducts(state, props), // allPlantingCycles: getAllPlantingCycles(state),

    tabName,
  };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(CropCompare)));
