import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { has } from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import Typography from 'components/Typography';
import {
  truncateSeries,
  splitPastFuture,
} from 'components/UnrealizedPotential/splitSeries';
import * as moment from 'moment';
import { API_DATE_FORMAT } from 'helpers/defaultDates';
import { buildGraphSeries } from '../../buildGraphSeries';

import metricsColors from '../../metricsColors';
import MetricsRow from './components/MetricsRow';

import styles from './Metrics.module.css';

const Metrics = ({
  metrics,
  descriptor,
  intl,
  paramsOrdering,
  paramsRounds,
  defaultRound,
}) => {
  const [screenX, setScreenX] = useState(null);
  const [screenY, setScreenY] = useState(null);
  const [closestPointCoordX, setClosestPointCoordX] = useState(null);
  const [tooltipDate, setTooltipDate] = useState(null);
  const [showTooltip, setShowTooltip] = useState(null);

  const { formatMessage } = intl;

  const { alignedForecastDate } = descriptor;

  const convertedAlignedForecastDate = useMemo(() => (alignedForecastDate
    ? moment.utc(alignedForecastDate, API_DATE_FORMAT).valueOf() / 60000
    : null), [alignedForecastDate]);

  const getParamOrderNumByCode = useCallback(
    code => paramsOrdering.findIndex(p => p === code),
    [paramsOrdering]
  );

  const metricsRows = useMemo(
    () =>
      Object.keys(metrics)
        .reduce((acc, key) => {
          const series = [];
          if (convertedAlignedForecastDate) {
            const [pastModel, futureModel] = splitPastFuture(
              metrics[key].model,
              convertedAlignedForecastDate
            );

            const points = Object.keys(metrics?.[key]?.cycle?.points || {});
            if (points.filter(p => p !== null).length > 0) {
              series.push(
                buildGraphSeries(
                  formatMessage({ id: 'unrealizedPotential.cycle' }),
                  metricsColors.CYCLE,
                  truncateSeries(
                    metrics[key].cycle,
                    'future',
                    convertedAlignedForecastDate
                  )
                )
              );
            }

            series.push(
              buildGraphSeries(
                formatMessage({ id: 'unrealizedPotential.model' }),
                metricsColors.MODEL,
                pastModel,
                {
                  hideNullableInTooltip: true,
                }
              )
            );
            series.push(
              buildGraphSeries(
                formatMessage({ id: 'unrealizedPotential.model' }),
                metricsColors.MODEL,
                futureModel,
                {
                  lineStyle: 'dashed',
                  showInLegend: false,
                  hideNullableInTooltip: true,
                  key: 'futureHarvest',
                  tooltipHidePointIndexes: [0],
                }
              )
            );
          } else {
            series.push(
              buildGraphSeries(
                formatMessage({ id: 'unrealizedPotential.model' }),
                metricsColors.MODEL,
                metrics[key].model
              )
            );
            series.push(
              buildGraphSeries(
                formatMessage({ id: 'unrealizedPotential.cycle' }),
                metricsColors.CYCLE,
                metrics[key].cycle
              )
            );
          }
          if (key === 'sunRadiation' && metrics[key]?.nasa) {
            series.push(
              buildGraphSeries(
                formatMessage({ id: 'unrealizedPotential.nasa' }),
                metricsColors.NASA,
                metrics[key].nasa
              )
            );
          }
          return [
            ...acc,
            {
              title: key,
              discrepancy: metrics[key].discrepancy.amount,
              summary: {
                model: metrics[key].model,
                cycle: metrics[key].cycle,
                nasa: key === 'sunRadiation' ? metrics[key]?.nasa : null,
              },
              series,
            },
          ];
        }, [])
        .sort(
          (a, b) =>
            getParamOrderNumByCode(a.title) - getParamOrderNumByCode(b.title)
        ),
    [metrics, formatMessage, getParamOrderNumByCode, convertedAlignedForecastDate]
  );

  const handlerTooltipChanged = useCallback(
    (options) => {
      if (has(options, 'screenX')) {
        setScreenX(options.screenX);
      }
      if (has(options, 'screenY')) {
        setScreenY(options.screenY);
      }
      if (has(options, 'closestPointCoordX')) {
        setClosestPointCoordX(options.closestPointCoordX);
      }
      if (has(options, 'tooltipDate')) {
        setTooltipDate(options.tooltipDate);
      }
      if (has(options, 'showTooltip')) {
        setShowTooltip(options.showTooltip);
      }
    },
    [
      setScreenX,
      setScreenY,
      setClosestPointCoordX,
      setTooltipDate,
      setShowTooltip,
    ]
  );

  return (
    <div className={styles.block}>
      <div className={styles.blockTitle}>
        <Typography variant='h3'>
          <FormattedMessage id='unrealizedPotential.metricsTitle' />
        </Typography>
      </div>
      <div className={styles.metricsContainer}>
        {metricsRows.map(metric => (
          <MetricsRow
            key={`metrics-row-${metric.title}`}
            {...metric}
            descriptor={descriptor}
            onTooltipChanged={handlerTooltipChanged}
            screenX={screenX}
            screenY={screenY}
            closestPointCoordX={closestPointCoordX}
            tooltipDate={tooltipDate}
            showTooltip={showTooltip}
            roundTo={
              Object.prototype.hasOwnProperty.call(paramsRounds, metric.title)
                ? paramsRounds[metric.title]
                : defaultRound
            }
          />
        ))}
      </div>
    </div>
  );
};

Metrics.propTypes = {
  metrics: PropTypes.array.isRequired,
  descriptor: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  paramsOrdering: PropTypes.array,
  paramsRounds: PropTypes.object,
  defaultRound: PropTypes.number,
};

Metrics.defaultProps = {
  paramsOrdering: [
    'fruitWeight',
    'plantingDensity',
    'fruitsPerTruss',
    'trussSpeed',
    'floweringSpeed',
    'ripeningPeriod',
    'droppedHeads',
    'sunRadiation',
    'artificialLight',
    'sunAndArtificialLightSum',
    'lightNeeded',
    'temperature',
    'co2',
  ],
  paramsRounds: {
    plantingDensity: 2,
    sunRadiation: 0,
    sunAndArtificialLightSum: 0,
    lightNeeded: 0,
  },
  defaultRound: 1,
};

export default injectIntl(Metrics);
