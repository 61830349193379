import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';


import classnames from 'classnames';
import { get, filter } from 'lodash';
import moment from 'moment-timezone';

import Link from 'components/Link';
import BigButton from 'components/BigButton';
import Typography from 'components/Typography';
import BackButton from 'components/BackButton';
import DefaultTable from 'components/DefaultTable';

import getDateFormat from 'helpers/getDateFormat';
import { getUnits } from 'helpers/getValueDetails';
import { showIntercom } from 'helpers/intercomHelpers';
import { API_DATE_FORMAT } from 'helpers/defaultDates';

import EmptyState from '../EmptyState';

import styles from './PlansList.module.css';

import CircleLoader from '../CircleLoader';
import loaderStyles from '../CircleLoader/CircleLoader.module.css';


export default class PlansList extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    isAddOperationalPlansAvailable: PropTypes.bool.isRequired,
    isAddBudgetPlansAvailable: PropTypes.bool.isRequired,
    showedYear: PropTypes.string,
    harvestPlansList: PropTypes.array,
    isHarvestPlansListFetching: PropTypes.bool,
    clearHarvestPlans: PropTypes.func.isRequired,
    requestGetHarvestPlansList: PropTypes.func.isRequired,
  };

  static defaultProps = {
    harvestPlansList: null,
    isHarvestPlansListFetching: false,
    showedYear: moment().year().toString(),
  };

  componentDidMount() {
    const {
      showedYear,
      requestGetHarvestPlansList,
    } = this.props;

    requestGetHarvestPlansList({ showedYear });
  }

  componentWillUnmount() {
    const { clearHarvestPlans } = this.props;

    clearHarvestPlans();
  }

  getBackLink = () => {
    const { match } = this.props;

    const urlParams = new URLSearchParams(window.location.search);

    const baseBackUrl = `/${match.params.organizationSlug}/plans`;

    return Array.from(urlParams).length > 0 ? `${baseBackUrl}?${urlParams.toString()}` : baseBackUrl;
  };

  renderHeaderRows = () => {
    const { intl: { formatMessage } } = this.props;

    return (
      <tr>
        <th className={styles.alignLeft}>{formatMessage({ id: 'plansList.name' })}</th>
        <th className={styles.alignLeft}>{formatMessage({ id: 'plansList.period' })}</th>
        <th className={styles.alignLeft}>{formatMessage({ id: 'plansList.unit' })}</th>
        <th className={styles.alignLeft}>{formatMessage({ id: 'plansList.publishedVersion' })}</th>
      </tr>
    );
  };

  renderRows = () => {
    const {
      intl: { formatMessage, locale }, showedYear, match, harvestPlansList,
      isAddOperationalPlansAvailable, isAddBudgetPlansAvailable,
    } = this.props;

    const { params: { organizationSlug } } = match;

    // Если у пользователя
    const filteredByBudgetHarvestPlansList = !isAddBudgetPlansAvailable ? filter(harvestPlansList, { planType: 'OPERATIONAL' }) : harvestPlansList;
    const filteredHarvestPlansList = !isAddOperationalPlansAvailable ? filter(filteredByBudgetHarvestPlansList, { planType: 'BUDGET' }) : filteredByBudgetHarvestPlansList;

    return filteredHarvestPlansList
      .sort((a, b) => {
        const aName = get(a, `name.${locale}`) || get(a, 'name.en');
        const bName = get(b, `name.${locale}`) || get(b, 'name.en');

        return aName.localeCompare(bName, undefined, { numeric: true, sensitivity: 'base' });
      })
      .map((plan, i) => {
        const planType = get(plan, 'planType', '').toLowerCase();
        const name = get(plan, `name.${locale}`) || get(plan, 'name.en');
        const periodLength = get(plan, 'periodLength');
        const period = formatMessage({ id: `timeSelector.${periodLength === 'weekSplitByMonth' ? 'week' : periodLength}.one` });
        const rawUnits = get(plan, 'units', []);
        const units = Array.isArray(rawUnits) ? rawUnits : [rawUnits];
        const formattedUnits = units.filter(x => x).map(x => getUnits({ units: x }, formatMessage)).join(', ');

        const publishedVersionLabel = get(plan, 'published.label');
        const whenCreated = get(plan, 'published.whenCreated');
        const publishedVersionDate = whenCreated ? moment(whenCreated).format(getDateFormat('lll')) : '–';

        const publishedVersion = [planType === 'budget' ? publishedVersionLabel : null, publishedVersionDate].filter(x => x).join(', ');

        const id = plan?.id;

        const periodType = 'month';
        const anyDateOfPeriodStart = moment().year(showedYear).startOf(periodType).format(API_DATE_FORMAT);
        const anyDateOfPeriodEnd = moment().year(showedYear).endOf(periodType).format(API_DATE_FORMAT);

        const url = planType === 'budget' ?
          `/${organizationSlug}/plans/${planType}/${id}/edit?year=${showedYear}`
          :
          `/${organizationSlug}/plans/${planType}/${id}/edit?year=${showedYear}&periodType=${periodType}&anyDateOfPeriodStart=${anyDateOfPeriodStart}&anyDateOfPeriodEnd=${anyDateOfPeriodEnd}`;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={`plan-${i}`}>
            <td className={classnames(styles.alignLeft, styles.bold)}><Link href={url}>{name}</Link></td>
            <td className={classnames(styles.alignLeft, styles.capitalize)}>{period}</td>
            <td className={styles.alignLeft}>{formattedUnits}</td>
            <td className={styles.alignLeft}>{publishedVersion}</td>
          </tr>
        );
      });
  }

  render() {
    const {
      intl,
      harvestPlansList,
      isHarvestPlansListFetching,
    } = this.props;

    const { formatMessage } = intl;

    if (!harvestPlansList || isHarvestPlansListFetching) {
      return (
        <div className={classnames(styles.layout)}>
          <CircleLoader
            className={loaderStyles.circleLoader}
            iconClassName={loaderStyles.circleLoaderIcon}
          />
        </div>
      );
    }

    const renderedHeader = (
      <div className={styles.header}>
        <div className={styles.controls}>
          <BackButton
            link={this.getBackLink()}
            text={formatMessage({ id: 'plans.plansViewing' })}
          />
        </div>

        <Typography variant='h1' className={styles.title}>
          {formatMessage({ id: 'plansList.title' })}
        </Typography>

      </div>
    );

    if (harvestPlansList && !harvestPlansList.length) {
      return (
        <div className={classnames(styles.layout)}>
          {renderedHeader}
          <div className={styles.content}>
            <div className={styles.empty}>
              <EmptyState
                isFlex
                className={styles.emptyState}
                header={formatMessage({ id: 'plansList.emptyTitle' })}
                text={(
                  <span>
                    {formatMessage({ id: 'plansList.emptyText' })}
                    &nbsp;
                    <BigButton
                      className={styles.supportButton}
                      onClick={showIntercom}
                      title={formatMessage({ id: 'settings.contactSupport' })}
                      theme='transparent'
                    />
                    <span>.</span>
                  </span>
                )}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={classnames(styles.layout)}>
        {renderedHeader}
        <div className={styles.content}>
          <DefaultTable
            className={styles.table}
            headerRows={this.renderHeaderRows()}
            bodyRows={this.renderRows()}
            fixedColumnOnMobile
          />
        </div>
      </div>
    );
  }
}
