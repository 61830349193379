
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import BenchmarkCompareButton from '../../../BenchmarkCompareButton';
import HorizontalBarsTooltipContent from '../HorizontalBarsTooltipContent';

import styles from './HorizontalBarsTooltip.module.css';

const TOOLTIP_PADDING = 8;

const HorizontalBarsTooltip = ({
  x, y, width, tooltipData, maxXCoord, className, withCompareButton, isShowLocationName,
  onMouseOverCallback, onMouseOutCallback
}) => {
  // Проверяем влезает ли тултип по левой границе страницы
  const isTooltipOversize = maxXCoord ? (maxXCoord - x) < width : false;

  const tooltipStyles = {
    width: `${width}px`,
    top: `${y + TOOLTIP_PADDING}px`,
  };

  if (isTooltipOversize && maxXCoord) {
    tooltipStyles.right = `${0}px`;
  } else {
    tooltipStyles.left = `${x}px`;
  }

  /* eslint-disable jsx-a11y/mouse-events-have-key-events */
  return (
    <div
      onMouseEnter={() => onMouseOverCallback({ tooltipData, x, y })}
      onMouseLeave={() => onMouseOutCallback(null)}
      className={classnames(styles.tooltip, className)}
      style={tooltipStyles}
    >
      <div className={styles.tooltipContent}>
        {/* TODO: вынести контент тултипа в функцию-рендер и передавать её пропсом */}
        <HorizontalBarsTooltipContent tooltipData={tooltipData} isShowLocationName={isShowLocationName} />
        {withCompareButton && <BenchmarkCompareButton data={tooltipData} />}
      </div>
    </div>
  );
  /* eslint-enable jsx-a11y/mouse-events-have-key-events */
};

HorizontalBarsTooltip.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  tooltipData: PropTypes.object.isRequired,
  width: PropTypes.number,
  maxXCoord: PropTypes.number,
  className: PropTypes.string,
  withCompareButton: PropTypes.bool,
  isShowLocationName: PropTypes.bool,
  onMouseOverCallback: PropTypes.func,
  onMouseOutCallback: PropTypes.func,
};

HorizontalBarsTooltip.defaultProps = {
  className: null,
  width: 312,
  maxXCoord: undefined,
  withCompareButton: false,
  isShowLocationName: true,
  onMouseOverCallback: () => {},
  onMouseOutCallback: () => {},
};

export default memo(HorizontalBarsTooltip);

