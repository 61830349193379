import { createAction } from 'redux-actions';

import {
  Preset,
  GraphPresetListItem,
  GraphsPresetRange,
  IdMixin,
  Incident,
  Metric,
  MetricAttr,
  MetricData,
  PresetMetricScale,
  TreeNode,
  TreeNodeIdTypes,
  TreeNodePathComponents,
  PresetAttr,
  MetricDef
} from './types';


// Metrics
export interface IMetricsRequest {

}

export interface IMetricsResponse {
  metrics: Array<Metric>;
}

export const REQUEST_GET_METRICS = 'REQUEST_GET_METRICS';
export const RESPONSE_GET_METRICS = 'RESPONSE_GET_METRICS';

export const requestGetMetrics = createAction<IMetricsRequest>(REQUEST_GET_METRICS);
export const responseGetMetrics = createAction<IMetricsResponse>(RESPONSE_GET_METRICS);

// Graph presets
export interface IGraphPresetsResponse {
  presets: Array<GraphPresetListItem>;
}

export const REQUEST_GET_GRAPH_PRESETS = 'REQUEST_GET_GRAPH_PRESETS';
export const RESPONSE_GET_GRAPH_PRESETS = 'RESPONSE_GET_GRAPH_PRESETS';

export const requestGetGraphPresets = createAction<null>(REQUEST_GET_GRAPH_PRESETS);
export const responseGetGraphPresets = createAction<IGraphPresetsResponse>(RESPONSE_GET_GRAPH_PRESETS);

// Graph preset
export interface IGetGraphPresetRequest {
  id: number;
  presetMetrics?: Array<MetricDef>;
  scales?: Array<PresetMetricScale>;
  range?: GraphsPresetRange;
  threshold?: number;
  returnAbnormalityIncidents?: boolean;
  activeIncidentId?: number;
  preventUpdatePresetIdInUrl?: boolean;
  showFirstIncident?: boolean;
}

export interface IGetGraphPresetResponse {
  preset: Preset | null;
  originalPreset?: Preset | null;
  preventUpdatePresetIdInUrl?: boolean;
}

export interface ICreatePresetRequest {
  preset: PresetAttr;
}

export interface ICreatePresetResponse {
  preset: Preset | null;
}

export interface IUpdatePresetRequest {
  preset: PresetAttr & IdMixin;
}

export interface IUpdatePresetResponse {
  preset: Preset | null;
}

export interface IDeletePresetRequest {
  presetId: number;
}

export type GraphPointNodeMetric = {
  compartmentId: number | null;
  locationId: number | null;
  metricId: number | null;
  subNodeId: number | null;
};

export interface IGetGraphDataPointsRequest {
  includeMetrics: boolean;
  nodes: Array<Array<TreeNodePathComponents>>;
  returnIncidents: string; // TODO: type
  scaleAsRoundToNMinutes: number;
  xFrom: number;
  xTo: number;
  showFirstIncident?:boolean;
}

export interface IGetGraphDataPointsResponse {
  data: Array<MetricData>;
  incidents: Array<Incident>;
  metrics: Array<MetricAttr & IdMixin>;
}

export const REQUEST_GET_GRAPH_PRESET = 'REQUEST_GET_GRAPH_PRESET';
export const RESPONSE_GET_GRAPH_PRESET = 'RESPONSE_GET_GRAPH_PRESET';

export const REQUEST_CREATE_GRAPH_PRESET = 'REQUEST_CREATE_GRAPH_PRESET';
export const RESPONSE_CREATE_GRAPH_PRESET = 'RESPONSE_CREATE_GRAPH_PRESET';

export const REQUEST_UPDATE_GRAPH_PRESET = 'REQUEST_UPDATE_GRAPH_PRESET';
export const RESPONSE_UPDATE_GRAPH_PRESET = 'RESPONSE_UPDATE_GRAPH_PRESET';

export const REQUEST_DELETE_GRAPH_PRESET = 'REQUEST_DELETE_GRAPH_PRESET';
export const RESPONSE_DELETE_GRAPH_PRESET = 'RESPONSE_DELETE_GRAPH_PRESET';

export const requestGetGraphPreset = createAction<IGetGraphPresetRequest>(REQUEST_GET_GRAPH_PRESET);
export const responseGetGraphPreset = createAction<IGetGraphPresetResponse>(RESPONSE_GET_GRAPH_PRESET);

export const requestCreateGraphPreset = createAction<ICreatePresetRequest>(REQUEST_CREATE_GRAPH_PRESET);
export const responseCreateGraphPreset = createAction<ICreatePresetResponse>(RESPONSE_CREATE_GRAPH_PRESET);

export const requestUpdateGraphPreset = createAction<IUpdatePresetRequest>(REQUEST_UPDATE_GRAPH_PRESET);
export const responseUpdateGraphPreset = createAction<IUpdatePresetResponse>(RESPONSE_UPDATE_GRAPH_PRESET);

export const requestDeleteGraphPreset = createAction<IDeletePresetRequest>(REQUEST_DELETE_GRAPH_PRESET);
export const responseDeleteGraphPreset = createAction<null>(RESPONSE_DELETE_GRAPH_PRESET);

// Graph data points
export const REQUEST_GET_GRAPH_DATA_POINTS = 'REQUEST_GET_GRAPH_DATA_POINTS';
export const RESPONSE_GET_GRAPH_DATA_POINTS = 'RESPONSE_GET_GRAPH_DATA_POINTS';

export const requestGetGraphDataPoints = createAction<IGetGraphDataPointsRequest>(REQUEST_GET_GRAPH_DATA_POINTS);
export const responseGetGraphDataPoints = createAction<IGetGraphDataPointsResponse>(RESPONSE_GET_GRAPH_DATA_POINTS);

// Preset actions
export interface IChangePresetName {
  name: string;
}
export const CHANGE_PRESET_NAME = 'CHANGE_PRESET_NAME';
export const changePresetName = createAction<IChangePresetName>(CHANGE_PRESET_NAME);

export interface IChangePresetDescription {
  description: string;
}
export const CHANGE_PRESET_DESCRIPTION = 'CHANGE_PRESET_DESCRIPTION';
export const changePresetDescription = createAction<IChangePresetDescription>(CHANGE_PRESET_DESCRIPTION);

export interface ISetPresetMetricSelected {
  metric: Array<TreeNodePathComponents>;
  selected: boolean;
}
export const SET_PRESET_METRIC_SELECTED = 'SET_PRESET_METRIC_SELECTED';
export const setPresetMetricSelected = createAction<ISetPresetMetricSelected>(SET_PRESET_METRIC_SELECTED);

export interface ISetAllPresetMetricSelected {
  selected: boolean;
}
export const SET_ALL_PRESET_METRIC_SELECTED = 'SET_ALL_PRESET_METRIC_SELECTED';
export const setAllPresetMetricSelected = createAction<ISetAllPresetMetricSelected>(SET_ALL_PRESET_METRIC_SELECTED);

export interface ISetMetricColor {
  metric: Array<TreeNodePathComponents>;
  color: string;
}
export const SET_METRIC_COLOR = 'SET_METRIC_COLOR';
export const setMetricColor = createAction<ISetMetricColor>(SET_METRIC_COLOR);

export interface IDeletePresetMetric {
  metric: Array<TreeNodePathComponents>;
}
export const DELETE_PRESET_METRIC = 'DELETE_PRESET_METRIC';
export const deletePresetMetric = createAction<IDeletePresetMetric>(DELETE_PRESET_METRIC);

export interface ITogglePresetMetric {
  metric: Array<TreeNodePathComponents>;
}
export const TOGGLE_PRESET_METRIC = 'TOGGLE_PRESET_METRIC';
export const togglePresetMetric = createAction<ITogglePresetMetric>(TOGGLE_PRESET_METRIC);

export interface IDeletePresetMetricGroup {
  group: Array<TreeNodePathComponents>;
}
export const DELETE_PRESET_METRIC_GROUP = 'DELETE_PRESET_METRIC_GROUP';
export const deletePresetMetricGroup = createAction<IDeletePresetMetricGroup>(DELETE_PRESET_METRIC_GROUP);

export interface IChangePresetMetricGroup {
  target: Array<TreeNodePathComponents>;
  values: Array<TreeNodePathComponents>;
}
export const CHANGE_PRESET_METRIC_GROUP = 'CHANGE_PRESET_METRIC_GROUP';
export const changePresetMetricGroup = createAction<IChangePresetMetricGroup>(CHANGE_PRESET_METRIC_GROUP);

export interface ISetMetricToScale {
  metric: Array<TreeNodePathComponents>;
  scale: PresetMetricScale;
}
export const SET_METRIC_TO_SCALE = 'SET_METRIC_TO_SCALE';
export const setMetricToScale = createAction<ISetMetricToScale>(SET_METRIC_TO_SCALE);

export interface IChangeScale {
  scale: PresetMetricScale;
}
export const CHANGE_SCALE = 'CHANGE_SCALE';
export const changeScale = createAction<IChangeScale>(CHANGE_SCALE);

export interface IDeleteScale {
  scaleName: string;
}
export const DELETE_SCALE = 'DELETE_SCALE';
export const deleteScale = createAction<IDeleteScale>(DELETE_SCALE);

export interface IChangeGraphPeriodRange {
  range: GraphsPresetRange;
  preventHistory: boolean;
}
export const CHANGE_GRAPH_PERIOD_RANGE = 'CHANGE_GRAPH_PERIOD_RANGE';
export const changeGraphPeriodRange = createAction<IChangeGraphPeriodRange>(CHANGE_GRAPH_PERIOD_RANGE);

export interface IChangeGraphPeriodThreshold {
  threshold: number;
  preventHistory: boolean;
}
export const CHANGE_GRAPH_THRESHOLD = 'CHANGE_GRAPH_THRESHOLD';
export const changeGraphPeriodThreshold = createAction<IChangeGraphPeriodThreshold>(CHANGE_GRAPH_THRESHOLD);

export interface IChangeGraphPeriodAndThreshold {
  range: GraphsPresetRange;
  threshold: number;
  preventHistory: boolean;
}
export const CHANGE_GRAPH_PERIOD_AND_THRESHOLD = 'CHANGE_GRAPH_PERIOD_AND_THRESHOLD';
export const changeGraphPeriodAndThreshold = createAction<IChangeGraphPeriodAndThreshold>(CHANGE_GRAPH_PERIOD_AND_THRESHOLD);

export interface IPushRangeHistory {
  range: GraphsPresetRange;
  threshold: number;
}
export const PUSH_RANGE_HISTORY = 'PUSH_RANGE_HISTORY';
export const pushRangeHistory = createAction<IPushRangeHistory>(PUSH_RANGE_HISTORY);

export const POP_RANGE_HISTORY = 'POP_RANGE_HISTORY';
export const POP_RANGE_HISTORY_SUCCESS = 'POP_RANGE_HISTORY_SUCCESS';
export const popRangeHistorySuccess = createAction<null>(POP_RANGE_HISTORY_SUCCESS);
export const popRangeHistory = createAction<null>(POP_RANGE_HISTORY);

export const CLEAR_RANGE_HISTORY = 'CLEAR_RANGE_HISTORY';
export const CLEAR_RANGE_HISTORY_SUCCESS = 'CLEAR_RANGE_HISTORY_SUCCESS';
export const clearRangeHistorySuccess = createAction<null>(CLEAR_RANGE_HISTORY_SUCCESS);
export const clearRangeHistory = createAction<null>(CLEAR_RANGE_HISTORY);

export interface IMetricValidationData {
  metrics: Array<Metric>;
  validationTarget: {
    subNodeId?: number;
    compartmentId?: number;
  } | null
}
export const SET_METRIC_VALIDATION_DATA = 'SET_METRIC_VALIDATION_DATA';
export const setMetricValidationData = createAction<IMetricValidationData>(SET_METRIC_VALIDATION_DATA);

export const UPDATE_NODE_METRIC_URL = 'UPDATE_NODE_METRIC_URL';
export const updateNodeMetricUrl = createAction<null>(UPDATE_NODE_METRIC_URL);

export interface ISetShowIncidents {
  returnAbnormalityIncidents: boolean;
  preventHistory?: boolean;
}
export const SET_SHOW_INCIDENTS = 'SET_SHOW_INCIDENTS';
export const setShowIncidents = createAction<ISetShowIncidents>(SET_SHOW_INCIDENTS);

export interface ISetActiveIncidentId {
  activeIncidentId: number | null;
  preventHistory?: boolean;
}
export const SET_ACTIVE_INCIDENT_ID = 'SET_ACTIVE_INCIDENT_ID';
export const setActiveIncidentId = createAction<ISetActiveIncidentId>(SET_ACTIVE_INCIDENT_ID);

export const CLEAR_WORKSPACE = 'CLEAR_WORKSPACE';
export const clearWorkspace = createAction<null>(CLEAR_WORKSPACE);

export interface IUpdatePresetDataFromUrl {
  presetMetrics: Array<MetricDef>;
  scales: Array<PresetMetricScale>;
  range?: GraphsPresetRange;
  threshold?: number;
  returnAbnormalityIncidents?: boolean;
  activeIncidentId?: number;
  showFirstIncident?: boolean;
}
export const UPDATE_PRESET_DATA_FROM_URL = 'UPDATE_PRESET_DATA_FROM_URL';
export const updatePresetDataFromUrl = createAction<IUpdatePresetDataFromUrl>(UPDATE_PRESET_DATA_FROM_URL);

export const UPDATE_ABNORMALITY_INCIDENTS_ON_CREATE = 'UPDATE_ABNORMALITY_INCIDENTS_ON_CREATE';
export const updateAbnormalityIncidentsOnCreate = createAction<null>(UPDATE_ABNORMALITY_INCIDENTS_ON_CREATE);

export const UPDATE_ABNORMALITY_INCIDENTS_ON_UPDATE = 'UPDATE_ABNORMALITY_INCIDENTS_ON_UPDATE';
export const updateAbnormalityIncidentsOnUpdate = createAction<null>(UPDATE_ABNORMALITY_INCIDENTS_ON_UPDATE);

export const UPDATE_ABNORMALITY_INCIDENT_FEEDBACK = 'UPDATE_ABNORMALITY_INCIDENT_FEEDBACK';
export const updateAbnormalityIncidentFeedback = createAction<null>(UPDATE_ABNORMALITY_INCIDENT_FEEDBACK);

export const UPDATE_ABNORMALITY_INCIDENTS_ON_DELETE = 'UPDATE_ABNORMALITY_INCIDENTS_ON_DELETE';
export const updateAbnormalityIncidentsOnDelete = createAction<null>(UPDATE_ABNORMALITY_INCIDENTS_ON_DELETE);

export const CREATE_INCIDENT = 'CREATE_INCIDENT';
export const createIncident = createAction<Incident>(CREATE_INCIDENT);

export const CLOSE_INCIDENT_EDITOR = 'CLOSE_INCIDENT_EDITOR';
export const closeIncidentEditor = createAction<Incident>(CLOSE_INCIDENT_EDITOR);

export const REQUEST_NEW_INCIDENT_ID = 'REQUEST_NEW_INCIDENT_ID';
export const requestNewIncidentId = createAction<null>(REQUEST_NEW_INCIDENT_ID);

export interface ISetCreateIncidentId {
  id: number | null;
}
export const SET_CREATE_INCIDENT_ID = 'SET_CREATE_INCIDENT_ID';
export const setCreateIncidentId = createAction<ISetCreateIncidentId>(SET_CREATE_INCIDENT_ID);

export const SET_INCIDENT_COLLAPSE = 'SET_INCIDENT_COLLAPSE';
export const setIncidentCollapse = createAction(SET_INCIDENT_COLLAPSE);

export const CHECK_NEW_INCIDENT_STATUS = 'CHECK_NEW_INCIDENT_STATUS';
export const checkNewIncidentStatus = createAction(CHECK_NEW_INCIDENT_STATUS);

export type OnRequestMetricsTreeActionSuccessFunc = (ids: string, tree: TreeNode | null) => void;

export type ITreeFilters = {
  metricId: Array<number> | null;
  compartmentId: Array<number> | null;
  categoryId: Array<number> | null;
  categoryCode: Array<string> | null;
  species: Array<string> | null;
  varietyId: Array<number> | null;
  fruitClassCode: Array<string> | null;
};

export type IRequestMetricsTree = {
  ids: string;
  actionSuccess: OnRequestMetricsTreeActionSuccessFunc;
  preventStateUpdate?: boolean;
  patchTree?: boolean;
  expand?: number;
} & ITreeFilters;

export const REQUEST_METRICS_TREE = 'REQUEST_METRICS_TREE';
export const requestMetricsTree = createAction(REQUEST_METRICS_TREE);

export const PATCH_METRICS_TREE = 'PATCH_METRICS_TREE';
export const patchMetricsTree = createAction(PATCH_METRICS_TREE);

export const RESPONSE_METRICS_TREE = 'RESPONSE_METRICS_TREE';
export const responseMetricsTree = createAction(RESPONSE_METRICS_TREE);

export type ISetAvailableNodeIds = {
  plantingCycles: Array<number>;
  species: Array<number>;
  compartments: Array<number>;
  subNodes: Array<number>;
};

export const SET_AVAILABLE_NODE_IDS = 'SET_AVAILABLE_NODE_IDS';
export const setAvailableNodeIds = createAction<ISetAvailableNodeIds>(SET_AVAILABLE_NODE_IDS);

export type ISetMetricsPanelFilters = {
  term: string;
  limit: number;
};

export const SET_METRICS_PANEL_FILTERS = 'SET_METRICS_PANEL_FILTERS';
export const setMetricsPanelFilters = createAction(SET_METRICS_PANEL_FILTERS);

export type IMetricSearchResult = {
  metrics: Array<number>;
};

export const RECEIVE_METRICS_PANEL_SEARCH_RESULT = 'RECEIVE_METRICS_PANEL_SEARCH_RESULT';
export const receiveMetricsPanelSearchResult = createAction(RECEIVE_METRICS_PANEL_SEARCH_RESULT);

export type ITreeSearchData = {
  metricIds: Array<number>;
  matches: Array<{
    tree: TreeNodeIdTypes;
    count: number;
  }>
};

export const SET_TREE_SEARCH_DATA = 'SET_TREE_SEARCH_DATA';
export const setTreeSearchData = createAction(SET_TREE_SEARCH_DATA);

export type ISetTreeExpandedKeys = {
  treeId: TreeNodeIdTypes;
  keys: Array<string | number>;
};
export const SET_TREE_EXPANDED_KEYS = 'SET_TREE_EXPANDED_KEYS';
export const setTreeExpandedKeys = createAction<ISetTreeExpandedKeys>(SET_TREE_EXPANDED_KEYS);

export const RESET_TREE_EXPANDED_KEYS = 'RESET_TREE_EXPANDED_KEYS';
export const resetTreeExpandedKeys = createAction(RESET_TREE_EXPANDED_KEYS);

export type ISetLoadingNodeKey = {
  key: string | number | undefined;
};

export const SET_GRAPH_METRIC_SEARCH = 'SET_GRAPH_METRIC_SEARCH';
export const setGraphMetricSearch = createAction<string>(SET_GRAPH_METRIC_SEARCH);

export const REQUEST_FULL_TREE_DATA = 'REQUEST_FULL_TREE_DATA';
export const requestFullTreeData = createAction(REQUEST_FULL_TREE_DATA);

// TODO: Expand types (after update)
export type RequestGetMetricsFunc = (payload? : IMetricsRequest) => void;
export type RequestGetGraphPresetsFunc = () => void;
export type RequestGetGraphPresetFunc = (payload? : IGetGraphPresetRequest) => void;
export type RequestCreateGraphPreset = (payload? : ICreatePresetRequest) => void;
export type RequestUpdateGraphPreset = (payload? : IUpdatePresetRequest) => void;
export type RequestDeleteGraphPreset = (payload? : IDeletePresetRequest) => void;
export type ChangePresetName = (payload? : IChangePresetName) => void;
export type ChangePresetDescription = (payload? : IChangePresetDescription) => void;
export type GetGraphDataPointsRequest = (payload? : IGetGraphDataPointsRequest) => void;
export type DeletePresetMetricGroup = (payload? : IDeletePresetMetricGroup) => void;
export type ChangePresetMetricGroup = (payload? : IChangePresetMetricGroup) => void;
export type DeletePresetMetric = (payload? : IDeletePresetMetric) => void;
export type ChangeGraphPeriodRange = (payload? : IChangeGraphPeriodRange) => void;
export type ChangeGraphPeriodThreshold = (payload? : IChangeGraphPeriodThreshold) => void;
export type SetPresetMetricSelected = (payload? : ISetPresetMetricSelected) => void;
export type SetMetricColor = (payload? : ISetMetricColor) => void;
export type SetMetricToScale = (payload? : ISetMetricToScale) => void;
export type ChangeScale = (payload? : IChangeScale) => void;
export type DeleteScale = (payload? : IDeleteScale) => void;
export type PushRangeHistory = (payload? : IPushRangeHistory) => void;
export type PopRangeHistory = () => void;
export type ClearRangeHistory = () => void;
export type SetAllPresetMetricSelected = (payload? : ISetAllPresetMetricSelected) => void;
export type UpdatePresetDataFromUrl = (payload? : IUpdatePresetDataFromUrl) => void;
export type ClearWorkspace = () => void;
export type SetShowIncidents = (payload? : ISetShowIncidents) => void;
export type SetActiveIncidentId = (payload? : ISetActiveIncidentId) => void;
export type ChangeGraphPeriodAndThreshold = (payload? : IChangeGraphPeriodAndThreshold) => void;
export type CreateIncident = (payload? : Incident) => void;
export type RequestNewIncidentId = () => void;
export type CloseIncidentEditor = () => void;
export type CheckNewIncidentStatus = () => void;
export type RequestMetricsTree = (payload? : IRequestMetricsTree) => void;
export type SetMetricsPanelFilters = (payload: ISetMetricsPanelFilters) => void;
export type TogglePresetMetric = (payload: ITogglePresetMetric) => void;
export type SetTreeExpandedKeys = (payload: ISetTreeExpandedKeys) => void;
export type ResetTreeExpandedKeys = () => void;
export type SetTreeSearchData = (payload: ITreeSearchData | null) => void;
export type SetLoadingNodeKey = (payload: ISetLoadingNodeKey) => void;
export type SetGraphMetricSearch = (payload: string) => void;
export type TrackPageViewGraphs = () => void;
export type RequestFullTreeData = () => void;
export type RequestIncidentById = () => void;
export type RequestIncidentPlot = () => void;
