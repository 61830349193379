import { createAction } from 'redux-actions';

export const requestPlanFactReport = createAction('REQUEST_PLAN_FACT_REPORT');
export const receivePlanFactReport = createAction('RECEIVE_PLAN_FACT_REPORT');

export const requestPlanFactGraph = createAction('REQUEST_PLAN_FACT_GRAPH');
export const receivePlanFactGraph = createAction('RECEIVE_PLAN_FACT_GRAPH');

export const updatePlanFactSorting = createAction('UPDATE_PLAN_FACT_SORTING');

export const requestPlanFactV2Report = createAction('REQUEST_PLAN_FACT_V2_REPORT');
export const receivePlanFactV2Report = createAction('RECEIVE_PLAN_FACT_V2_REPORT');

export const requestPlanFactExport = createAction('REQUEST_PLAN_FACT_EXPORT');
export const receivePlanFactExport = createAction('RECEIVE_PLAN_FACT_EXPORT');
