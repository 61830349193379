import React, {
  useCallback, useState
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import parse from 'color-parse';

import { rgb2hex } from 'helpers/colors';
import hex2rgba from 'helpers/hex2rgba';

import styles from './index.module.css';

const PaletteColorSelector = ({
  paletteColors,
  initValue,
  onChange,
  wrapperClassName
}) => {
  const parsed = parse(initValue);
  const [selectedColor, setSelectedColor] = useState(`#${rgb2hex(parsed[0], parsed[1], parsed[2])}`);

  const handleColorSelect = useCallback((color) => {
    if (setSelectedColor) {
      setSelectedColor(color);
      onChange(hex2rgba(color, 1));
    }
  }, [setSelectedColor, onChange]);

  return (
    <div className={classnames(styles.palette, wrapperClassName)}>
      {paletteColors?.length > 0 && paletteColors.map(color => (
        <div
          tabIndex={0}
          role='button'
          className={classnames(styles.item, {
            [styles.selected]: color === selectedColor
          })}
          style={{
            background: color
          }}
          onClick={() => {
            handleColorSelect(color);
          }}
        />
      ))}
    </div>
  );
};

PaletteColorSelector.propTypes = {
  initValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  paletteColors: PropTypes.array,
  wrapperClassName: PropTypes.string
};

PaletteColorSelector.defaultProps = {
  initValue: 'rgba(255,255,255,1)',
  paletteColors: [],
  wrapperClassName: null
};

export default PaletteColorSelector;
