import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import {
  get, isFinite, isNil
} from 'lodash';
import moment from 'moment-timezone';

import LinesGraph from 'components/CustomChart/components/LinesGraph';

import numbersFormatting from '../../../../helpers/numbersFormatting';

import LINE_COLORS from '../../../../helpers/harvestColors';
import { getProductTypeName } from '../../../../helpers/getVarietyName';
import getDateFormat from '../../../../helpers/getDateFormat';

import LinesGraphTooltip from '../../../LinesGraphTooltip';

import styles from './BenchmarkHarvestChart.module.css';
import numbersRounding from '../../../../helpers/numbersRounding';

function generateLineByPoints(dataPoints, comparison) {
  if (dataPoints && comparison) {
    const {
      descriptor: {
        periods,
        compareType
      }
    } = comparison;

    return Object.keys(dataPoints).reduce((acc, key, i) => {
      const period = periods[key];
      let date = null;
      let x = i + 1;
      if (compareType === 'CALENDAR') {
        date = moment.utc(new Date((+period.epochMinutes) * 60000));
        x = date.format('MM-DD-YYYY');
      }
      const y = dataPoints[key];

      return [...acc, { x, y, date }];
    }, []);
  }
  return [];
}

const renderTooltip = ({
  tooltipState, comparison, periodType, intl
}) => {
  const { formatMessage } = intl;

  if (!tooltipState || !tooltipState.isShow || !tooltipState?.lines?.length) {
    return null;
  }

  const products = get(comparison, 'products');

  const {
    descriptor: {
      periods,
    }
  } = comparison;

  const serialNumber = +tooltipState.xCoord.toFixed(0);
  const period = periods.find(item => item.serialNumber === +serialNumber);

  const generatedLines = tooltipState?.lines.map((line) => {
    const productId = line.trace.id;
    const product = products.find(item => item.id === productId);
    if (!isFinite(line.value)) {
      return null;
    }
    const actualLineValue = numbersRounding(line.value, 'fixed', 1);
    const actualLineWithUnit = isNil(actualLineValue) ? formatMessage({ id: 'crops.noData' }) : `${numbersFormatting(actualLineValue)} ${formatMessage({ id: 'cunits.mini.kilogramPerSquareMeter' })}`;
    const organizationName = get(product, 'location.name', '');
    const productTypeName = getProductTypeName({
      intl,
      variety: get(product, 'variety'),
      fruitClass: get(product, 'fruitClass'),
      directPath: true,
    });
    const startDate = get(product, 'startDate');
    const endDate = get(product, 'endDate');
    const startDateMoment = moment(startDate).format(getDateFormat('lll'));
    const endDateMoment = endDate ? moment(endDate).format(getDateFormat('lll')) : formatMessage({ id: 'cycle.present' });
    const units = formatMessage({ id: 'cunits.mini.kilogramPerSquareMeter' });
    return {
      id: productId,
      value: actualLineWithUnit,
      header: (
        <div className={styles.tooltipHeaderContent}>
          <div className={styles.locationName}>
            {`${organizationName}, ${productTypeName},`}
          </div>
          <div className={styles.dates}>
            {`${startDateMoment}—${endDateMoment}, ${product?.compartment?.name}`}
          </div>
        </div>
      ),
      color: line.trace.line.color,
      width: 195,
      textEllipsis: true,
      points: generateLineByPoints(get(product, 'harvest.dataPoints'), comparison),
      units,
    };
  }).filter(item => item !== null);

  return generatedLines.length > 0 ? (
    <LinesGraphTooltip
      isSpaced
      lines={generatedLines}
      tooltipDate={period.weekNumber}
      periodType={periodType}
    />
  ) : null;
};

const BenchmarkHarvestChart = ({
  intl,
  isDataFetching,
  comparison,
  periodType,
  onTooltipChanged,
  tooltipState
}) => {
  const plot = useMemo(() => {
    const { locale, formatMessage } = intl;
    const comparisonProducts = get(comparison, 'products');
    return comparisonProducts.length > 0 ? {
      traces: comparisonProducts.map((product, i) => {
        const organizationName = get(product, 'location.name', '');
        const productTypeName = getProductTypeName({
          intl,
          variety: get(product, 'variety'),
          fruitClass: get(product, 'fruitClass'),
          directPath: true,
        });
        const startDate = get(product, 'startDate');
        const endDate = get(product, 'endDate');
        const startDateMoment = moment(startDate).format(getDateFormat('lll'));
        const endDateMoment = endDate ? moment(endDate).format(getDateFormat('lll')) : formatMessage({ id: 'cycle.present' });
        const dataPoints = get(product, 'harvest.dataPoints', {});
        const { x, y } = Object.keys(dataPoints).reduce((acc, key) =>
           ({
            x: [...acc.x, +key],
            y: [...acc.y, dataPoints[key]]
          }),
         { x: [], y: [] });
        return {
          id: product.id,
          type: 'scatter',
          name: {
            [locale]: `${organizationName}, ${productTypeName}, ${startDateMoment}—${endDateMoment}, ${product?.compartment?.name}`
          },
          line: {
            color: LINE_COLORS[i]
          },
          x,
          y
        };
      }),
      xAxis: {
        type: 'xRanged'
      },
      yAxis: {
        type: 'yRanged'
      },
      hover: {
        type: 'nearPointer'
      }
    } : null;
  }, [comparison, intl]);

  const tickDisplayFormat = useCallback((value) => {
    if (!comparison) {
      return null;
    }
    const {
      descriptor: {
        periods,
        compareType
      }
    } = comparison;

    const serialNumber = +value.toFixed(0);
    const period = periods.find(item => item.serialNumber === +serialNumber);

    if (!period) {
      return null;
    }

    const date = moment.utc(new Date((+period.epochMinutes) * 60000));

    if (compareType === 'CALENDAR') {
      return date.format('MMM YYYY');
    }

    if (compareType === 'YEARS') {
      return date.format('MMM');
    }

    if (compareType === 'CYCLE_WEEKS') {
      return period.weekNumber;
    }

    return serialNumber;
  }, [comparison]);

  const handlerTooltipChanged = useCallback((options) => {
    if (onTooltipChanged) {
      onTooltipChanged(options);
    }
  }, [onTooltipChanged]);

  const renderTooltipContent = useCallback(tooltipStateInner => renderTooltip({
    tooltipState: tooltipStateInner, comparison, periodType, intl
  }), [comparison, periodType, intl]);


  return (
    <LinesGraph
      plot={plot}
      isDataFetching={isDataFetching}
      defaultSize={{ height: 112 }}
      isEmpty={!plot?.traces?.length}
      tickDisplayFormat={tickDisplayFormat}
      ticksFunction={13}
      onTooltipChanged={handlerTooltipChanged}
      renderTooltipContent={renderTooltipContent}
      cropNullValues
      legendClassName={styles.legend}
      tooltipState={tooltipState}
    />
  );
};

BenchmarkHarvestChart.propTypes = {
  intl: intlShape.isRequired,
  isDataFetching: PropTypes.bool,
  comparison: PropTypes.object,
  periodType: PropTypes.string,
  onTooltipChanged: PropTypes.func,
  tooltipState: PropTypes.any,
};

BenchmarkHarvestChart.defaultProps = {
  isDataFetching: false,
  comparison: null,
  periodType: null,
  onTooltipChanged: null,
  tooltipState: null,
};

export default injectIntl(BenchmarkHarvestChart);
