import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';

import classnames from 'classnames';

import isTouchDevice from 'helpers/isTouchDevice';

import styles from './ChartTooltip.module.css';

const TOOLTIP_WIDTH = 300;
const TOOLTIP_PADDING = 16;
const TRIANGLE_WIDTH = 18;

const defaultOffsetX = 20;
const defaultOffsetY = 40;

const ChartTooltip = ({
  children,
  showTooltip,
  fixedMobilePosition,
  screenX,
  screenY,
  className,
  isTriangleShow,
  parent,
  isDisabledOffsetX,
}) => {
  const nodeRef = useRef(null);
  const isTouch = isTouchDevice();

  const { leftPosition, topPosition, leftPadding } = useMemo(() => {
    if (!parent || !nodeRef?.current) {
      return {
        leftPosition: screenX,
        topPosition: screenY,
        leftPadding: 16,
      };
    }
    const rect = nodeRef?.current.getBoundingClientRect();
    const viewportElement = document.querySelector('.layout__viewport');
    const parentElement = parent || null;
    const parentRect = parentElement.getBoundingClientRect();
    const parentRectBottom = parentRect.top + parentRect.height;
    const parentRectLeft = parentRect.left;

    // Проверка умещается ли справа тултип во вьюпорт по ширине
    const isTooltipInViewportX =
      screenX + rect.width + defaultOffsetX + 128 >
      window.innerWidth + viewportElement.scrollLeft;
    const offsetX = isTooltipInViewportX
      ? -rect.width - defaultOffsetX
      : defaultOffsetX;
    const leftScreen = screenX + (isDisabledOffsetX ? 0 : offsetX);
    let newTopPosition = screenY - defaultOffsetY;

    if (newTopPosition - 10 < parentRect.top) {
      newTopPosition = parentRect.top + 10;
    }

    if (newTopPosition + rect.height + 10 > parentRect.top + parentRect.height) {
      newTopPosition = parentRectBottom - rect.height - 10;
    }

    return {
      leftPosition: leftScreen - viewportElement.scrollLeft,
      topPosition: newTopPosition,
      leftPadding: parentRectLeft + viewportElement.scrollLeft,
    };
  }, [
    screenX,
    screenY,
    parent,
    nodeRef,
    isDisabledOffsetX,
  ]);

  const fixedTooltip = fixedMobilePosition && isTouch;

  const hoverLineCoordX = screenX - leftPadding;
  const leftCoord = hoverLineCoordX - TOOLTIP_WIDTH / 2;
  const absoluteLeftCoord = leftCoord + leftPadding;
  const absoluteRightCoord = absoluteLeftCoord + TOOLTIP_WIDTH;

  // Рассчёт положения тултипа для мобильной версии (fixedTooltip)
  let triangleLeft = `calc(50% - ${TRIANGLE_WIDTH / 2}px)`;
  let tooltipLeftCoord = leftCoord;

  if (absoluteLeftCoord - TOOLTIP_PADDING < 0) {
    tooltipLeftCoord = -leftPadding + TOOLTIP_PADDING;
    triangleLeft = screenX - TOOLTIP_PADDING - TRIANGLE_WIDTH / 2;
  } else if (absoluteRightCoord + TOOLTIP_PADDING > window.innerWidth) {
    tooltipLeftCoord =
      window.innerWidth - leftPadding - TOOLTIP_WIDTH - TOOLTIP_PADDING;
    triangleLeft =
      screenX - tooltipLeftCoord - leftPadding - TRIANGLE_WIDTH / 2;
  }

  const tooltipStyle = useMemo(() => {
    const tooltipStyleDef = fixedTooltip
      ? {
          left: tooltipLeftCoord,
          top: '-120px',
        }
      : {
          left: leftPosition,
          top: topPosition,
        };

    if (!showTooltip) {
      tooltipStyleDef.display = 'none';
    }

    return tooltipStyleDef;
  }, [fixedTooltip, tooltipLeftCoord, leftPosition, topPosition, showTooltip]);

  return (
    <div
      style={tooltipStyle}
      className={classnames(
        styles.tooltip,
        { [styles.fixedTooltip]: fixedTooltip },
        className
      )}
      ref={nodeRef}
    >
      {children}
      {(fixedTooltip || isTriangleShow) && (
        <div className={styles.triangle} style={{ left: triangleLeft }} />
      )}
    </div>
  );
};

ChartTooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
  showTooltip: PropTypes.bool,
  screenX: PropTypes.number,
  screenY: PropTypes.number,
  parent: PropTypes.object,
  fixedMobilePosition: PropTypes.bool,
  isTriangleShow: PropTypes.bool,
  isDisabledOffsetX: PropTypes.bool,
};

ChartTooltip.defaultProps = {
  className: null,
  showTooltip: false,
  screenX: null,
  screenY: null,
  parent: null,
  children: null,
  fixedMobilePosition: false,
  isTriangleShow: false,
  isDisabledOffsetX: false,
};

export default ChartTooltip;
