import { getTextHeight } from 'components/CanvasTimeline/helpers/TextUtils';
import { addPoints } from 'components/CanvasTimeline/helpers/GeometryUtils';
import { roundRect } from 'components/CanvasTimeline/helpers/DrawUtils';
import SvgIcon from 'components/CanvasTimeline/helpers/SvgIcon';

import ButtonStyle from './ButtonStyle';

import CanvasComponent from './CanvasComponent';

class Button extends CanvasComponent {
  static ICON_LEFT = 0;

  static ICON_RIGHT = 1;

  static ICON_MIDDLE = 2;

  isMeasureTextDirty = true;

  isBoundingBoxDirty = true;

  iconSide = Button.ICON_MIDDLE;

  iconPadding = 0;

  iconScale = 1.0;

  constructor({
    position = { x: 0, y: 0 },
    iconComponent,
    text,
    style,
    iconSide = Button.ICON_MIDDLE,
    iconPadding = 0.0,
    hoverStyle,
    iconScale = 1.0,
    //  size = null,
    iconColor = '#FFF'
  }) {
    super({
      position,
      cursorType: 'pointer'
    });
    this.text = text;
    this.style = style || new ButtonStyle({});
    this.hoverStyle = hoverStyle || new ButtonStyle({});
    this.icon = iconComponent ? new SvgIcon(iconComponent, iconScale) : null;
    if (this.icon) {
      this.icon.scale = iconScale;
    }
    if (Number.isInteger(iconSide)) {
      this.iconSide = iconSide;
    }
    if (Number.isInteger(iconPadding)) {
      this.iconPadding = iconPadding;
    }
    this.iconColor = iconColor;
  }

  getSize() {
    const style = this.getCurrentStyle();

    const textHeights = this.measureText ? getTextHeight(this.measureText) : {
      actualHeight: 0,
      fontHeight: 0
    };

    const textWidth = this.measureText?.width || 0;
    const textHeight = textHeights.actualHeight || 0;

    const wPad = style.padding.l + style.padding.r;
    const hPad = style.padding.t + style.padding.b;

    const iconWidth = this.icon?.width || 0;
    const iconHeight = this.icon?.height || 0;

    const iconPad = this.icon ? this.iconPadding : 0;

    return {
      width: textWidth + iconWidth + iconPad + wPad,
      height: Math.max(textHeight, iconHeight) + hPad
    };
  }

  updateMeasureText(ctx) {
    if (this.isMeasureTextDirty) {
      this.measureText = this.text ? ctx.measureText(this.text) : null;
      this.isMeasureTextDirty = false;
    }
  }

  setText(text) {
    this.text = text;
    this.isMeasureTextDirty = true;
    this.isBoundingBoxDirty = true;
  }

  setPosition({ x, y }) {
    super.setPosition({ x, y });
    this.isMeasureTextDirty = true;
    this.isBoundingBoxDirty = true;
  }

  getIconPosition(screenPosition) {
    const size = this.getSize();
    const style = this.getCurrentStyle();

    const textWidth = this.measureText?.width || 0;

    if (this.iconSide === Button.ICON_LEFT) {
      return {
        x: screenPosition.x + style.padding.l,
        y: screenPosition.y + size.height / 2
      };
    }

    if (this.iconSide === Button.ICON_RIGHT) {
      return {
        x: screenPosition.x + style.padding.l + textWidth + this.iconPadding,
        y: screenPosition.y + size.height / 2
      };
    }

    return {
      x: screenPosition.x + (size.width / 2),
      y: screenPosition.y + (size.height / 2)
    };
  }

  getCurrentStyle() {
    return this.isHovered() ? this.hoverStyle : this.style;
  }

  draw(ctx, {
    // eslint-disable-next-line no-unused-vars
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    ctx.save();
    super.draw(ctx, {
      translateAdd
    });

    const position = this.getPositionAbs();
    const screenPosition = addPoints(position, translateAdd);

    const size = this.getSize();
    const style = this.getCurrentStyle();

    // TODO: Refactoring stroke rect rendering
    roundRect(ctx, {
      ...screenPosition,
      ...size,
      fill: true,
      radius: style.borderRadius,
      color: style.fill
    });
    // draw stroke
    if (style.stroke) {
      roundRect(ctx, {
        ...screenPosition,
        ...size,
        stroke: true,
        radius: style.borderRadius,
        color: style.stroke,
      });
    }

    if (this.text) {
      ctx.font = style.font;
      ctx.fillStyle = style.textColor;
      ctx.textAlign = 'left';
      ctx.textBaseline = 'top';

      this.updateMeasureText(ctx);

      const textX = this.iconSide === Button.ICON_LEFT ?
        screenPosition.x + style.padding.l + (this.icon.width / 2) + this.iconPadding :
        screenPosition.x + style.padding.l;

      ctx.fillText(
        this.text,
        textX,
        screenPosition.y + style.padding.t
      );
    }

    if (this.icon) {
      this.icon.draw(ctx, {
        position: this.getIconPosition(screenPosition),
        fillColor: this.iconColor,
      });
    }
    ctx.restore();
  }
}

export default Button;
