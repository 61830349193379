import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import WeeksPhotos from '../WeeksPhotos';
import PhotosTypes from '../PhotosTypes';

import styles from './SplitPhotos.module.css';


export default class SplitPhotos extends PureComponent {
  static propTypes = {
    leftPlantingCycle: PropTypes.object,
    photoCategories: PropTypes.array.isRequired,
    onPhotoDialogOpen: PropTypes.func.isRequired,
    onPhotoDeleteDialogOpen: PropTypes.func.isRequired,
    isAddPhotoAvailable: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    leftPlantingCycle: null,
  };

  handlerPhotoDialogOpen = (options) => {
    const { onPhotoDialogOpen } = this.props;

    if (onPhotoDialogOpen) {
      onPhotoDialogOpen(options);
    }
  };

  handlerPhotoDeleteDialogOpen = (options) => {
    const { onPhotoDeleteDialogOpen } = this.props;

    if (onPhotoDeleteDialogOpen) {
      onPhotoDeleteDialogOpen(options);
    }
  };

  render() {
    const { leftPlantingCycle, photoCategories, isAddPhotoAvailable } = this.props;

    return (
      <div className={styles.split}>
        {leftPlantingCycle && (
          <div className={classnames(styles.splitLeft, styles.splitLeftFull)}>
            <PhotosTypes photoCategories={photoCategories} />
            <WeeksPhotos
              onClick={this.handlerPhotoDialogOpen}
              onDelete={this.handlerPhotoDeleteDialogOpen}
              isAddPhotoAvailable={isAddPhotoAvailable}
            />
          </div>
        )}
      </div>
    );
  }
}
