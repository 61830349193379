import 'focus-outline-manager';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import React, { Component, } from 'react';
import { addLocaleData } from 'react-intl';
import { IntlProvider } from 'react-intl-redux';
import { ConnectedRouter } from 'connected-react-router';

import __intlEN from 'react-intl/locale-data/en';
import __intlRU from 'react-intl/locale-data/ru';
import __intlFR from 'react-intl/locale-data/fr';
import __intlNL from 'react-intl/locale-data/nl';
import __intlZH from 'react-intl/locale-data/zh'; // zh вместо zh-cn, из-за нейминга в react-intl

import Routes from '../routes';

// Load 'en' and 'ru' utils
addLocaleData(__intlEN);
addLocaleData(__intlRU);
addLocaleData(__intlFR);
addLocaleData(__intlNL);
addLocaleData(__intlZH);

class AppContainer extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { store, history } = this.props;

    return (
      <Provider store={store}>
        <IntlProvider textComponent={React.Fragment}>
          <ConnectedRouter history={history}>
            <Routes />
          </ConnectedRouter>
        </IntlProvider>
      </Provider>
    );
  }
}

export default AppContainer;
