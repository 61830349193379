/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import { get } from 'lodash';

import addGetParameters from 'helpers/addGetParameters';
import updateLocationSearch from 'helpers/updateLocationSearch';
import { getFormattedRangeDates, getDefaultDateRange, getFormattedPeriod } from 'helpers/defaultDates';

import DashboardHeader from 'components/DashboardHeader';
import DashboardComplexFilters from 'components/DashboardComplexFilters';
import MobileFiltersIcon from 'components/Icons/MobileFilters';

// import LaborDashboardFiltersMobile from '../LaborDashboardFiltersMobile';
import CircleLoader from 'components/CircleLoader';
import loaderStyles from 'components/CircleLoader/CircleLoader.module.css';

import DashboardReport from '../DashboardReport';

import styles from './LaborDashboard.module.css';

const DEFAULT_DATE_RANGE = getDefaultDateRange();

export default class LaborDashboard extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    laborReport: PropTypes.object,
    history: PropTypes.object.isRequired,
    isDataFetching: PropTypes.bool.isRequired,
    laborReportSorting: PropTypes.object.isRequired,
    periodType: PropTypes.string,
    currentCompartment: PropTypes.string,
    currentBreakdown: PropTypes.string,
    currentSpecies: PropTypes.string,
    currentVarietyId: PropTypes.string,
    currentFruitClassCode: PropTypes.string,
    currentUnits: PropTypes.string,
    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
    productGroups: PropTypes.array.isRequired,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    allPlantingCycles: PropTypes.array.isRequired,
    graphKey: PropTypes.string,
    requestLaborReport: PropTypes.func.isRequired,
    requestLaborWorkTypes: PropTypes.func.isRequired,
    updateLaborReportSorting: PropTypes.func.isRequired,
    clearLaborReport: PropTypes.func.isRequired,
  };

  static defaultProps = {
    graphKey: 'total',
    laborReport: null,
    currentUnits: 'absolute',
    currentBreakdown: 'compartment',
    currentCompartment: undefined,
    currentSpecies: undefined,
    currentVarietyId: null,
    currentFruitClassCode: null,
    periodType: 'month',
    anyDateOfPeriodStart: DEFAULT_DATE_RANGE.startDate,
    anyDateOfPeriodEnd: DEFAULT_DATE_RANGE.endDate,
  };

  state = {
    clickedItem: null,
  };

  componentDidMount() {
    const {
      requestLaborReport,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,
      currentBreakdown,
      currentCompartment,
      currentWorkTypeId,
      currentUnits,
      currentSpecies,
      currentVarietyId,
      currentFruitClassCode,
      productGroups,
      requestLaborWorkTypes,
    } = this.props;

    requestLaborWorkTypes();

    requestLaborReport({
      periodType,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      breakdown: currentBreakdown,
      species: currentSpecies || undefined,
      varietyId: currentVarietyId,
      fruitClassCode: currentFruitClassCode,
      compartmentId: currentCompartment || undefined,
      workTypeId: currentWorkTypeId || undefined,
      unitKind: currentUnits,
      productGroups,
    });
  }

  componentWillUnmount() {
    const { clearLaborReport } = this.props;

    clearLaborReport();
  }

  handlerRowClick = ({ row, e, key }) => {
    e.stopPropagation();

    updateLocationSearch({ [key]: row.key });
  };

  handlerFiltersChange = (newParams) => {
    const {
      history,
      requestLaborReport,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,
      currentSpecies,
      currentVarietyId,
      currentFruitClassCode,
      currentBreakdown,
      currentCompartment,
      currentUnits,
      productGroups,
    } = this.props;

    // TODO: we need to make upgrade of query-string for {arrayFormat: 'comma'}
    const preparedParams = newParams ? Object.keys(newParams).reduce((reducer, key) => {
      const temp = reducer;

      const item = newParams[key];

      if (Array.isArray(item)) {
        temp[key] = item.join(',');
      } else {
        temp[key] = item;
      }
      return temp;
    }, {}) : {};

    const searchString = addGetParameters(get(history, 'location.search'), preparedParams);

    history.push({
      search: searchString,
    });

    requestLaborReport({
      workTypeId: undefined,
      periodType,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      breakdown: currentBreakdown,
      species: currentSpecies || undefined,
      varietyId: currentVarietyId,
      fruitClassCode: currentFruitClassCode,
      compartmentId: currentCompartment || undefined,
      unitKind: currentUnits,
      productGroups,

      ...newParams,
    });
  };

  render() {
    const {
      intl, periodType, laborReport, isDataFetching,
      anyDateOfPeriodStart, anyDateOfPeriodEnd, laborReportSorting, updateLaborReportSorting,
    } = this.props;

    const { formatMessage } = intl;

    const formattedRangeDates = getFormattedRangeDates(anyDateOfPeriodStart, anyDateOfPeriodEnd, periodType, true);
    const startDate = get(formattedRangeDates, 'startDate');
    const endDate = get(formattedRangeDates, 'endDate');
    const periodText = getFormattedPeriod(periodType, formatMessage);

    const rangeText = `${periodText}, ${startDate} – ${endDate}`;

    const customRanges = [
      {
        label: formatMessage({ id: 'dashboards.weekly' }),
        value: 'week',
      },
      {
        label: formatMessage({ id: 'dashboards.monthly' }),
        value: 'month',
      },
    ];

    return (
      <div className={styles.layout}>
        <div className={styles.contentWrapper}>
          <DashboardHeader
            defaultPeriodType='month'
            customRanges={customRanges}
            dashboardName={formatMessage({ id: 'dashboards.laborResources' })}
            handlerAfterRangeSelect={this.handlerFiltersChange}
            containerClassName={styles.dashboardHeader}
            text={rangeText}
            filters={
              <DashboardComplexFilters
                filters={['variety', 'breakdown', 'compartment', 'workType']}
                mode='dashboards:labor'
                onFiltersChange={this.handlerFiltersChange}
                renderMode='mobile'
                mobileFilterIcon={<MobileFiltersIcon />}
                mobileContainerClassName={styles.mobileContainer}
              />
            }
          />

          <DashboardComplexFilters
            filters={['variety', 'breakdown', 'compartment', 'workType']}
            mode='dashboards:labor'
            onFiltersChange={this.handlerFiltersChange}
            renderMode='desktop'
          />

          {!!laborReport && (
            <DashboardReport
              graphKeyId='labor'
              onRowClick={this.handlerRowClick}
              report={laborReport}
              sorting={laborReportSorting}
              onUpdateSorting={updateLaborReportSorting}
            />
          )}

          {isDataFetching && (
            <div className={styles.loader}>
              <CircleLoader className={loaderStyles.circleLoader} iconClassName={loaderStyles.circleLoaderIcon} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
