import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './DefaultOverlay.module.css';

const DefaultOverlay = ({ isVisible, onClick, className }) => (
  <div
    className={classnames(
      styles.overlay,
      className,
      { [styles.overlayVisible]: isVisible }
    )}
    role='button'
    tabIndex={0}
    onClick={onClick}
  />
);

DefaultOverlay.propTypes = {
  isVisible: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

DefaultOverlay.defaultProps = {
  isVisible: false,
  onClick: () => {},
  className: null,
};

export default DefaultOverlay;
