import moment from 'moment-timezone';
import { get, find, flow } from 'lodash';
import queryString from 'query-string';
import { createSelector } from 'reselect';
import { getCurrentCycleFullInfo } from '../newCrops/selectors';

export const getLocale = state => state.intl.locale; // TODO: вынести в общие селекторы
export const getPlantingCycleHarvest = state => state.harvest.plantingCycleHarvest;
export const getHarvestFetching = state => state.harvest.isHarvestFetching;
export const getHarvestCategories = state => state.harvest.harvestCategories;
export const getPlantingCycleHarvestByCategories = state => state.harvest.plantingCycleHarvestByCategories;

export const getHarvestFirstCategoryName = createSelector(
  getHarvestCategories,
  getLocale,
  (item, locale) => flow(
    currentItem => find(get(currentItem, 'data[0].categories'), { id: 1 }),
    category => get(category, `name.${locale || 'en'}`),
  )(item),
);

export const getHarvestWeeks = createSelector(
  [getPlantingCycleHarvest],
  cycle => get(cycle, 'harvest.weeks') || [],
);

export const getHarvestTotal = createSelector(
  [getPlantingCycleHarvest],
  cycle => get(cycle, 'harvest.total'),
);

export const getHarvestAverage = createSelector(
  [getPlantingCycleHarvest],
  cycle => get(cycle, 'harvest.average'),
);

export const getCycleInfo = createSelector(
  [getPlantingCycleHarvest],
  cycle => get(cycle, 'plantingCycle'),
);

export const getHarvestCategoriesByCycle = createSelector(
  [getHarvestCategories, getCurrentCycleFullInfo],
  (harvestCategories, cycle) => {
    if (harvestCategories) {
      if (harvestCategories.enabledForLocation) {
        const item = harvestCategories.data.find(harvestCategory => get(cycle, 'variety.attributes.species') === harvestCategory.species);

        const categories = get(item, 'categories', null);

        if (categories) {
          return categories.filter(({ enabled }) => !!enabled);
        }

        // return null;
      }

      return [
        {
          enabled: true,
          id: 1,
          includedInTotal: true,
          name: {
            en: 'Harvest',
            ru: 'Урожай',
            fr: 'Récolte',
            nl: 'Oogst',
          }
        }
      ];
    }
    return null;
  },
);

export const getHarvestByCategories = createSelector(
  [getHarvestCategories, getHarvestCategoriesByCycle, getPlantingCycleHarvestByCategories],
  (harvestCategories, filteredHarvestCategories, harvest) => {
    if (harvestCategories && harvest) {
      if (harvestCategories.enabledForLocation) {
        const { rows } = harvest;

        if (rows) {
          return {
            rows: rows
              .map(({ date, values }) => {
                const filteredValues = values && values.filter(({ discriminator }) => filteredHarvestCategories && filteredHarvestCategories.length && filteredHarvestCategories.find(({ id }) => +id === +discriminator));

                return {
                  date,
                  values: filteredValues,
                };
              })
              .filter(({ values }) => values && values.length)
          };
        }

        return harvest;
      }

      return {
        rows: Object.keys(harvest).map(date => ({
          date,
          values: [
            {
              discriminator: 1,
              value: harvest[date],
            }
          ],
        })),
      };
    }
    return null;
  },
);

export const getWeeksWithHarvest = createSelector(
  [getHarvestWeeks],
  (weeks) => {
    const weeksWithHarvest = weeks.filter(week => week.average > 0);

    return weeksWithHarvest.length;
  },
);

export const getRouteLocation = (state, props) => props.location;

export const getRouteQueryParams = createSelector([getRouteLocation], location => queryString.parse(get(location, 'search')));

export const getHarvestDate = createSelector(
  [getRouteQueryParams],
  (query) => {
    const date = get(query, 'date');

    return date ? moment(date, 'YYYY-MM-DD') : null;
  }
);

export const getHarvestPeriodType = createSelector(
  [getRouteQueryParams],
  (query) => {
    const periodType = get(query, 'periodType');

    return periodType;
  }
);

export const getChangesTime = state => state.harvest.changesTime;
