import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { isNull } from 'lodash';

import storageWrapper from 'helpers/storageWrapper';
import { getDefaultDateRange } from 'helpers/defaultDates';

import {
  getOrganizationSlug,
  getCurrentTimezone,
  getIsAddLaborAvailable,
  getAnyDateOfPeriodStartFromRoute,
  getAnyDateOfPeriodEndFromRoute,
  getPeriodTypeFromRoute,
  getAllVarieties,
  getAllFruitClasses,
  getCurrentCompartments,
  getAllPlantingCycles,

  getCompartmentIdsFromRoute,
  getWorkTypeIdsFromRoute,
  getWorkClassIdFromRoute,
} from '../../../store/company/selectors';

import {
  getIsLaborRegistrationsFetching,
  getIsSaveLaborRegistrationsFetching,
  getLaborWorkTypes,
  getFilteredLaborRegistrations,
  getAvaliableWorkClasses,
} from '../../../store/labor/selectors';

import { showNotificationWithTimeout } from '../../../store/notificationCenter/actions';

import {
  requestLaborRegistrations,
  requestSaveLaborRegistrations,
  requestLaborWorkTypes,
} from '../../../store/labor/actions';

import LaborWorksAdd from '../components/LaborWorksAdd';

const DEFAULT_PERIOD = 'month';
const safeLocalStorage = storageWrapper.get('localStorage');

const mapDispatchToProps = {
  requestLaborRegistrations,
  requestSaveLaborRegistrations,
  showNotificationWithTimeout,
  requestLaborWorkTypes,
};

const mapStateToProps = (state, props) => {
  const periodTypeFromStorage = safeLocalStorage.getItem('laborAddPeriodType');
  const defaultPeriod = isNull(periodTypeFromStorage) ? DEFAULT_PERIOD : periodTypeFromStorage;

  const periodType = getPeriodTypeFromRoute(state, props) || defaultPeriod;
  const defaultDateRange = getDefaultDateRange(periodType, true);

  const startDate = getAnyDateOfPeriodStartFromRoute(state, props) || defaultDateRange.startDate;
  const endDate = getAnyDateOfPeriodEndFromRoute(state, props) || defaultDateRange.endDate;

  return {
    ...props,

    varieties: getAllVarieties(state, props),
    fruitClasses: getAllFruitClasses(state, props),
    compartments: getCurrentCompartments(state, props),
    allPlantingCycles: getAllPlantingCycles(state, props),

    locationWorkTypes: getLaborWorkTypes(state, props),
    currentCompartment: getCompartmentIdsFromRoute(state, props),
    currentWorkTypeId: getWorkTypeIdsFromRoute(state, props),
    currentWorkClass: getWorkClassIdFromRoute(state, props),
    availableWorkClasses: getAvaliableWorkClasses(state, props),

    isLaborRegistrationsFetching: getIsLaborRegistrationsFetching(state, props),
    filteredLaborRegistrations: getFilteredLaborRegistrations(state, props),
    isSaveLaborRegistrationsFetching: getIsSaveLaborRegistrationsFetching(state, props),
    isAddLaborAvailable: getIsAddLaborAvailable(state),

    organizationSlug: getOrganizationSlug(state, props),
    timezone: getCurrentTimezone(state, props),
    periodType,
    startDate,
    endDate,
  };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(LaborWorksAdd)));
