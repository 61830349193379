import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { matchPath, withRouter } from 'react-router';

import {
  getOrganizationSlug,
} from 'store/company/selectors';

import {
  removeCycleFromComparison,
} from 'store/benchmark/actions';

import {
  getCyclesToCompare,
} from 'store/benchmark/selectors';

import CropCompareSuccessMsg from '../components/CropCompareSuccessMsg';

const mapDispatchToProps = {
  removeCycleFromComparison,
};


const mapStateToProps = (state, props) => {
  const { location } = props;

  const dashboardsPageMatch = matchPath(location?.pathname, {
    path: '/:organizationSlug/dashboards/:tabName',
    exact: false,
    strict: false
  });

    // не используем match.params.tabName т.к. в хедере он будет пустой
  const tabName = dashboardsPageMatch?.params?.tabName;

  return {
    ...props,

    organizationSlug: getOrganizationSlug(state, props),
    tabName,
    cyclesToCompare: getCyclesToCompare(state, props),
  };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(CropCompareSuccessMsg)));
