// TODO: включить flow и стабилизировать компонент c индийским кодом, взятым из github

/* eslint-disable */


import PropTypes from 'prop-types';
import React from 'react';

import {
  noop,
  returnTrue,
  escapeRegExp,
  fixLeadingZero,
  limitToScale,
  roundToPrecision,
  omit,
  splitDecimal,
} from '../../helpers/numberFormat';


class NumberFormat extends React.Component {

  static propTypesDefs = {
    thousandSeparator: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([true])]),
    decimalSeparator: PropTypes.string,
    decimalScale: PropTypes.number,
    decimalZeroMask: PropTypes.number,
    forcedDecimalScale: PropTypes.number,
    forceDecimalScale: PropTypes.bool,
    fixedDecimalScale: PropTypes.bool,
    displayType: PropTypes.oneOf(['input', 'text']),
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    removeFormatting: PropTypes.func,
    mask: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isNumericString: PropTypes.bool,
    customInput: PropTypes.func,
    allowNegative: PropTypes.bool,
    allowEmptyFormatting: PropTypes.bool,
    onValueChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    type: PropTypes.oneOf(['text', 'tel']),
    isAllowed: PropTypes.func,
    renderText: PropTypes.func,
    getInputRef: PropTypes.func,
    allowedDecimalSeparator: PropTypes.arrayOf(PropTypes.string),
  };

  // NOTE: propTypes will be removed in production build
  static propTypes = Object.assign(NumberFormat.propTypesDefs, {});

  static defaultProps = {
    displayType: 'input',
    decimalSeparator: '.',
    thousandSeparator: ',',
    forcedDecimalScale: 1,
    decimalZeroMask: null,
    forceDecimalScale: null,
    fixedDecimalScale: false,
    prefix: '',
    suffix: '',
    allowNegative: true,
    allowEmptyFormatting: false,
    isNumericString: false,
    type: 'text',
    onValueChange: noop,
    onChange: noop,
    onKeyDown: noop,
    onMouseUp: noop,
    onFocus: noop,
    onBlur: noop,
    isAllowed: returnTrue,
    getInputRef: noop,
    allowedDecimalSeparator: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value: newValue } = nextProps;
    const { value } = prevState;

    if (value !== newValue) {
      return {
        value: newValue,
      };
    }

    return null;
  }

  state = {
    active: false,
    value: null,
  };

  componentDidMount() {
    this.validateProps();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { value: nextPropsValue, className: nextClassName } = nextProps;
    const { value: prevPropsValue, className: prevClassName } = this.props;
    const { value: nextStateValue, active: nextActive } = nextState;
    const { value: prevStateValue, active: prevActive } = this.state;

    return nextPropsValue !== prevPropsValue || nextStateValue !== prevStateValue || nextActive !== prevActive || nextClassName !== prevClassName;
  }

  getFloatString(num) {
    const { decimalScale } = this.props;
    const { decimalSeparator } = this.getSeparators();
    const numRegex = this.getNumberRegex(true);

    // remove negation for regex check
    const hasNegation = num[0] === '-';
    if (hasNegation) {
      num = num.replace('-', '');
    }

    this.props.allowedDecimalSeparator.forEach(separator => {
      num = num.replace(separator, decimalSeparator)
    });

    // if decimal scale is zero remove decimal and number after decimalSeparator
    if (decimalSeparator && decimalScale === 0) {
      num = num.split(decimalSeparator)[0];
    }

    num = (num.match(numRegex) || []).join('').replace(decimalSeparator, '.');

    // remove extra decimals
    const firstDecimalIndex = num.indexOf('.');

    if (firstDecimalIndex !== -1) {
      num = `${num.substring(0, firstDecimalIndex)}.${num.substring(firstDecimalIndex + 1, num.length).replace(new RegExp(escapeRegExp(decimalSeparator), 'g'), '')}`
    }

    // add negation back
    if (hasNegation) num = '-' + num;

    return num;
  }

  // returned regex assumes decimalSeparator is as per prop
  getNumberRegex(g, ignoreDecimalSeparator) {
    const { format, decimalScale } = this.props;
    const { decimalSeparator } = this.getSeparators();

    let regexp = '\\d';

    if (decimalSeparator && decimalScale !== 0 && !ignoreDecimalSeparator && !format) {
      [decimalSeparator].concat(this.props.allowedDecimalSeparator).forEach(separator => {
        regexp = regexp + '|' + escapeRegExp(separator)
      })
    }

    return new RegExp(regexp, g ? 'g' : undefined);
  }

  getSeparators() {
    const { decimalSeparator, thousandSeparator } = this.props;

    let thousandSeparatorText = thousandSeparator;
    let decimalSeparatorText = decimalSeparator;

    if (thousandSeparator && typeof thousandSeparator === typeof true) {
      thousandSeparatorText = ','
    }

    if (decimalSeparator && typeof decimalSeparator === typeof true) {
      decimalSeparatorText = '.'
    }

    return {
      decimalSeparator: decimalSeparatorText,
      thousandSeparator: thousandSeparatorText,
    }
  }

  getMaskAtIndex(index) {
    const { mask = ' ' } = this.props;
    if (typeof mask === 'string') {
      return mask;
    }

    return mask[index] || ' ';
  }

  validateProps() {
    const { mask } = this.props;

    // validate decimalSeparator and thousandSeparator
    const { decimalSeparator, thousandSeparator } = this.getSeparators();

    if (decimalSeparator === thousandSeparator) {
      throw new Error(`
          Decimal separator can't be same as thousand separator.
          thousandSeparator: ${thousandSeparator} (thousandSeparator = {true} is same as thousandSeparator = ",")
          decimalSeparator: ${decimalSeparator} (default value for decimalSeparator is .)
       `);
    }

    // validate mask
    if (mask) {
      const maskAsStr = mask === 'string' ? mask : mask.toString();
      if (maskAsStr.match(/\d/g)) {
        throw new Error(`
          Mask ${mask} should not contain numeric character;
        `);
      }
    }
  }

  removePrefixAndSuffix(val) {
    const { format, prefix, suffix } = this.props;

    // remove prefix and suffix
    if (!format && val) {
      const isNegative = val[0] === '-';

      // remove negation sign
      if (isNegative) val = val.substring(1, val.length);

      // remove prefix
      val = prefix && val.indexOf(prefix) === 0 ? val.substring(prefix.length, val.length) : val;

      // remove suffix
      const suffixLastIndex = val.lastIndexOf(suffix);
      val = suffix && suffixLastIndex !== -1 && suffixLastIndex === val.length - suffix.length ? val.substring(0, suffixLastIndex) : val;

      // add negation sign back
      if (isNegative) val = '-' + val;
    }

    return val;
  }

  removePatternFormatting(val) {
    const { format } = this.props;
    const formatArray = format.split('#').filter(str => str !== '');
    let start = 0;
    let numStr = '';

    for (let i = 0, ln = formatArray.length; i <= ln; i++) {
      const part = formatArray[i] || '';

      // if i is the last fragment take the index of end of the value
      // For case like +1 (911) 911 91 91 having pattern +1 (###) ### ## ##
      const index = i === ln ? val.length : val.indexOf(part, start);

      /* in any case if we don't find the pattern part in the value assume the val as numeric string
      This will be also in case if user has started typing, in any other case it will not be -1
      unless wrong prop value is provided */
      if (index === -1) {
        numStr = val;
        break;
      } else {
        numStr += val.substring(start, index);
        start = index + part.length;
      }
    }

    return (numStr.match(/\d/g) || []).join('');
  }

  removeFormatting(val) {
    const { format, removeFormatting } = this.props;
    if (!val) return val;

    if (!format) {
      val = this.removePrefixAndSuffix(val);
      val = this.getFloatString(val);
    } else if (typeof format === 'string') {
      val = this.removePatternFormatting(val);
    } else if (typeof removeFormatting === 'function') { // condition need to be handled if format method is provide,
      val = removeFormatting(val);
    } else {
      val = (val.match(/\d/g) || []).join('')
    }
    return val;
  }

  /**
   * Format when # based string is provided
   * @param  {string} numStr Numeric String
   * @return {string}        formatted Value
   */
  formatWithPattern(numStr) {
    const { format } = this.props;
    let hashCount = 0;
    const formattedNumberAry = format.split('');
    for (let i = 0, ln = format.length; i < ln; i++) {
      if (format[i] === '#') {
        formattedNumberAry[i] = numStr[hashCount] || this.getMaskAtIndex(hashCount);
        hashCount += 1;
      }
    }
    return formattedNumberAry.join('');
  }

  /**
   * @param  {string} numStr Numeric string/floatString] It always have decimalSeparator as .
   * @return {string} formatted Value
   */
  formatAsNumber(numStr, forceDecimalScale, decimalZeroMask) {
    const { decimalScale, forcedDecimalScale, fixedDecimalScale, prefix, suffix, allowNegative } = this.props;
    const { thousandSeparator, decimalSeparator } = this.getSeparators();

    const hasDecimalSeparator = numStr.indexOf('.') !== -1 || (decimalScale && fixedDecimalScale) || (forcedDecimalScale && forceDecimalScale) || decimalZeroMask;
    let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(numStr, allowNegative); //  eslint-disable-line prefer-const

    // apply decimal precision if its defined
    if (decimalScale !== undefined) afterDecimal = limitToScale(afterDecimal, decimalScale, fixedDecimalScale);
    if (forceDecimalScale && forcedDecimalScale !== undefined) afterDecimal = limitToScale(afterDecimal, forcedDecimalScale, forceDecimalScale);
    if (decimalZeroMask && afterDecimal.length < decimalZeroMask) afterDecimal = limitToScale(afterDecimal, decimalZeroMask, true);

    if (thousandSeparator) {
      beforeDecimal = beforeDecimal.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + thousandSeparator);
    }

    // add prefix and suffix
    if (prefix) beforeDecimal = prefix + beforeDecimal;
    if (suffix) afterDecimal = afterDecimal + suffix;

    // restore negation sign
    if (addNegation) beforeDecimal = '-' + beforeDecimal;

    numStr = beforeDecimal + (hasDecimalSeparator && decimalSeparator || '') + afterDecimal;

    return numStr;
  }

  formatNumString(value = '', forceDecimalScale, decimalZeroMask) {
    const { format, allowEmptyFormatting } = this.props;
    let formattedValue = value;

    if (value === '' && !allowEmptyFormatting) {
      formattedValue = '';
    } else if (value === '-' && !format) {
      formattedValue = '-';
      value = '';
    } else if (typeof format === 'string') {
      formattedValue = this.formatWithPattern(formattedValue);
    } else if (typeof format === 'function') {
      formattedValue = format(formattedValue);
    } else {
      formattedValue = this.formatAsNumber(formattedValue, forceDecimalScale, decimalZeroMask)
    }

    return formattedValue;
  }

  formatNegation(value = '') {
    const { allowNegative } = this.props;
    const negationRegex = new RegExp('(-)');
    const doubleNegationRegex = new RegExp('(-)(.)*(-)');

    //  Check number has '-' value
    const hasNegation = negationRegex.test(value);

    //  Check number has 2 or more '-' values
    const removeNegation = doubleNegationRegex.test(value);

    // remove negation
    value = value.replace(/-/g, '');

    if (hasNegation && !removeNegation && allowNegative) {
      value = '-' + value;
    }

    return value;
  }

  formatValueProp() {
    const { format, decimalScale, fixedDecimalScale, allowEmptyFormatting } = this.props;
    let { isNumericString } = this.props;
    let { value } = this.state;

    const isNonNumericFalsy = !value && value !== 0;

    if (isNonNumericFalsy && allowEmptyFormatting) {
      value = '';
    }

    //  if value is not defined return empty string
    if (isNonNumericFalsy && !allowEmptyFormatting) return '';

    if (typeof value === 'number') {
      value = value.toString();
      isNumericString = true;
    }

    // round the number based on decimalScale
    // format only if non formatted value is provided
    if (isNumericString && !format && typeof decimalScale === 'number') {
      value = roundToPrecision(value, decimalScale, fixedDecimalScale)
    }

    return isNumericString ? this.formatNumString(value) : this.formatInput(value);
  }

  formatInput(value) {
    let result = value;

    const { format } = this.props;

    // format negation only if we are formatting as number
    if (!format) {
      result = this.formatNegation(result);
    }

    // remove formatting from number
    result = this.removeFormatting(result);

    return this.formatNumString(result);
  }

  onChange = (e) => {
    const { onChange } = this.props;

    const inputValue = e.target.value;
    const formattedValue = this.formatInput(inputValue) || '';
    const numAsString = this.removeFormatting(formattedValue);

    this.setState({
      value: numAsString,
    }, () => {
      if (onChange) {
        onChange({ value: numAsString });
      }
    });
  };

  onBlur = (e) => {
    const {
      format,
      onBlur,
      onChange,
      forceDecimalScale,
      decimalZeroMask,
    } = this.props;

    const inputValue = e.target.value;

    if (!format) {
      const formattedValue = this.formatInput(inputValue) || '';
      const numAsString = this.removeFormatting(formattedValue);
      const fixedNumAsString = fixLeadingZero(numAsString);
      const formattedNumStringValue = this.formatNumString(fixedNumAsString, forceDecimalScale, decimalZeroMask);
      const value = this.removeFormatting(formattedNumStringValue);

      this.setState({
        active: false,
        value,
      }, () => {
        if (onChange) {
          onChange({ value });
        }

        if (onBlur) {
          onBlur({ value });
        }
      });
    } else {
      this.setState({
        active: false,
        value: inputValue,
      }, () => {
        if (onChange) {
          onChange({ value: inputValue });
        }

        if (onBlur) {
          onBlur({ value: inputValue });
        }
      });
    }
  };

  onFocus = (e) => {
    const {
      onFocus,
      forceDecimalScale,
      decimalZeroMask,
    } = this.props;

    const inputValue = e.target.value;

    const formattedValue = this.formatInput(inputValue) || '';
    const numAsString = this.removeFormatting(formattedValue);
    const fixedNumAsString = fixLeadingZero(numAsString);
    const formattedNumStringValue = this.formatNumString(fixedNumAsString, forceDecimalScale, decimalZeroMask);
    const value = this.removeFormatting(formattedNumStringValue);

    this.setState({
      active: true,
    });

    if (onFocus) {
      onFocus({ value });
    }
  };

  onKeyDown = (e) => {
    const {
      onKeyDown,
    } = this.props;

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  render() {
    const {
      type,
      displayType,
      customInput,
      renderText,
      getInputRef,
    } = this.props;

    const {
      active,
      value,
    } = this.state;

    const otherProps = omit(this.props, NumberFormat.propTypesDefs);

    const inputProps = Object.assign({}, otherProps, {
      type,
      value: active ? value : this.formatValueProp(),
      onChange: this.onChange,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onKeyDown: this.onKeyDown,
    });

    if (displayType === 'text') {
      return renderText ? (renderText(value) || null) : <span {...otherProps} ref={getInputRef}>{value}</span>;
    }

    if (customInput) {
      const CustomInput = customInput;

      return (
        <CustomInput {...inputProps} />
      );
    }

    return (
      <input {...inputProps} ref={getInputRef} />
    );
  }
}

export default NumberFormat;
