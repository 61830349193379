import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import {
  useParams,
  useLocation,
} from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import cn from 'classnames';
import {
  uniqueId,
} from 'lodash';
import { createStaticRanges } from 'date-range-picker';

import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import subDays from 'date-fns/subDays';
import addYears from 'date-fns/addYears';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';
import isToday from 'date-fns/isToday';
import isSameDay from 'date-fns/isSameDay';

import { showIntercom } from 'helpers/intercomHelpers';
import { getNameByLocal } from 'helpers/getNameByLocal';
import { formatDateLocalized, formatDate, parseDate } from 'helpers/datesHelper';
import updateLocationSearch from 'helpers/updateLocationSearch';
import useSearchParams from 'hooks/useSearchParams';

import UiDateRangePicker from 'ui/UiDateRangePicker';
import SidePageMenu from 'components/SidePageMenu';
import DashboardHeaderIcon from 'components/DashboardHeaderIcon';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import Typography from 'components/Typography';
import CustomChart from 'components/CustomChart';
import Speedometer from 'components/Speedometer';

import AddViaIntercomButton from '../AddViaIntercomButton';
// import TempDatePicker from '../TempDatePicker';
// import graphsMock from './graphsMock';

import styles from './CropReport.module.css';

const getDefaultStartDate = (cycleStartDate, value) => {
  switch (value) {
    case 'today':
      return formatDate(new Date(), 'yyyy-MM-dd');
    case 'last2Days':
      return formatDate(subDays(new Date(), 1), 'yyyy-MM-dd');
    case 'last7Days':
      return formatDate(subDays(new Date(), 6), 'yyyy-MM-dd');
    case 'last30Days':
      return formatDate(subDays(new Date(), 29), 'yyyy-MM-dd');
    case 'entireCycle':
      return cycleStartDate;
    default:
      return formatDate(subDays(new Date(), 6), 'yyyy-MM-dd');
  }
};

const renderGraphByType = (locale, plot) => {
  if (plot.type === 'gauge') {
    return (
      <Speedometer
        wrapperClassName={styles.speedometerWrapper}
        segments={plot?.segments}
        range={plot?.axis?.range}
        captions={plot?.captions?.map(item => getNameByLocal(item, locale, 'text'))}
        value={plot?.value}
        showSegmentBoundaryCaptions={plot?.showSegmentBoundaryCaptions}
      />
    );
  }

  return <CustomChart plot={plot} />;
};

// const renderGraph = (intl, plot, position) => {
const renderGraph = (intl, plot) => {
  const { locale } = intl;

  const title = getNameByLocal(plot, locale, 'title');
  const description = getNameByLocal(plot, locale, 'description');

  return (
    <div>
      <Typography variant='h3' className={styles.title}>
        {title}
      </Typography>
      <Typography variant='subtitle3' className={styles.description}>
        {description}
      </Typography>
      <div className={styles.graphContent}>
        {renderGraphByType(locale, plot)}
        {/* {`columnStart: ${position?.columnStart}`}
        <br />
        {`columnEnd: ${position?.columnEnd}`}
        <br />
        {`rowStart: ${position?.rowStart}`}
        <br />
        {`rowEnd: ${position?.rowEnd}`} */}
      </div>
    </div>
  );
};

const renderGrid = (intl, elements) => elements.map(({ position, plot }) => {
  const {
    columnStart, columnEnd, rowStart, rowEnd,
  } = position;

  return (
    <div
      key={uniqueId('grid-item-')}
      className={styles.gridItem}
      style={{
        gridColumnStart: columnStart,
        gridColumnEnd: columnEnd,
        gridRowStart: rowStart,
        gridRowEnd: rowEnd,
      }}
    >
      {renderGraph(intl, plot, position)}
    </div>
  );
});

const getTypeOfCurrentPeriod = (cycleStartDate, cycleEndDate, start, end) => {
  const daysDifference = differenceInDays(
    end,
    start,
  );

  const isEndDateToday = isToday(end);

  if (isEndDateToday && daysDifference === 0) {
    return 'today';
  }

  if (isEndDateToday && daysDifference === 1) {
    return 'last2Days';
  }

  if (isEndDateToday && daysDifference === 6) {
    return 'last7Days';
  }

  if (isEndDateToday && daysDifference === 29) {
    return 'last30Days';
  }

  const parsedCycleStartDate = parseDate(cycleStartDate);
  const parsedCycleEndDate = cycleEndDate ? parseDate(cycleEndDate) : new Date();

  if (isSameDay(parsedCycleStartDate, start) && isSameDay(parsedCycleEndDate, end)) {
    return 'entireCycle';
  }

  return 'custom';
};

const getDatesDescRenderer = (formatMessage, cycleStartDate, cycleEndDate, start, end) => {
  const currentPeriod = getTypeOfCurrentPeriod(cycleStartDate, cycleEndDate, start, end);

  switch (currentPeriod) {
    case 'today': {
      return formatMessage({ id: 'dates.today' });
    }
    case 'last2Days': {
      return formatMessage({ id: 'dates.last2Days' });
    }
    case 'last7Days': {
      return formatMessage({ id: 'dates.last7Days' });
    }
    case 'last30Days': {
      return formatMessage({ id: 'dates.last30Days' });
    }
    case 'entireCycle': {
      return formatMessage({ id: 'dates.entireCycle' });
    }
    default: {
      const yearDifference = differenceInCalendarYears(
        end,
        start,
      );

      const startDate = formatDateLocalized(start, yearDifference !== 0 ? 'lll' : 'll');
      const endDate = formatDateLocalized(end, 'lll');

      return `${startDate} – ${endDate}`;
    }
  }
};

const getStaticRanges = (formatMessage, cycleStartDate, cycleEndDate) => createStaticRanges([
  {
    id: 'today',
    label: formatMessage({ id: 'dates.today' }),
    range: () => ({
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    id: 'last2Days',
    label: formatMessage({ id: 'dates.last2Days' }),
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -1)),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    id: 'last7Days',
    label: formatMessage({ id: 'dates.last7Days' }),
    range: () => ({
      startDate: addDays(new Date(), -6),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    id: 'last30Days',
    label: formatMessage({ id: 'dates.last30Days' }),
    range: () => ({
      startDate: addDays(new Date(), -29),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    id: 'entireCycle',
    label: formatMessage({ id: 'dates.entireCycle' }),
    range: () => ({
      startDate: parseDate(cycleStartDate),
      endDate: cycleEndDate ? parseDate(cycleEndDate) : new Date(),
    }),
  },
  {
    id: 'custom',
    label: formatMessage({ id: 'dates.custom' }),
    range: () => ({
      startDate: undefined,
      endDate: undefined,
    }),
    isSelected: (range) => {
      if (!range?.startDate && !range?.endDate) {
        return true;
      }

      const currentPeriod = getTypeOfCurrentPeriod(cycleStartDate, cycleEndDate, range.startDate, range.endDate);

      if (currentPeriod === 'custom') {
        return true;
      }

      return false;
    },
  },
]);

const CropReport = ({
  intl,
  reportsList,
  currentReport,
  isFetching,
  // timezone,
  cycleInfo,

  requestCropReport,
}) => {
  const { formatMessage, locale } = intl;

  const [menuOpened, setMenuOpened] = useLocalStorage('reports-menu-opened', true);
  const { organizationSlug, cycleId, reportId } = useParams();

  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

  const cycleStartDate = cycleInfo?.cycle?.startDate;
  const cycleEndDate = cycleInfo?.cycle?.endDate;

  const dateRange = useSearchParams('dateRange', 'last7Days');
  // Get default start date by default period (dateRange)
  const defaultStartDate = getDefaultStartDate(cycleStartDate, dateRange);
  const startDate = useSearchParams('startDate', defaultStartDate);
  const endDate = useSearchParams('endDate', formatDate(new Date(), 'yyyy-MM-dd'));

  const handlerToggleMenu = useCallback(() => {
    setMenuOpened(!menuOpened);
  }, [setMenuOpened, menuOpened]);

  const handlerChangeDates = useCallback(({ startDate: newStartDate, endDate: newEndDate }) => {
    updateLocationSearch({
      // dateRange: 'custom',
      startDate: newStartDate,
      endDate: newEndDate,
    });
  }, []);

  useEffect(() => {
    requestCropReport({
      reportId,
      plantingCycleId: cycleId,
      startDate,
      endDate,
    });
  }, [requestCropReport, cycleId, reportId, startDate, endDate]);

  const location = useLocation();

  const menuItems = [{
    links: reportsList.map(reportItem => ({
      href: `/${organizationSlug}/crops/${cycleId}/reports/${reportItem.id}${location?.search}`,
      name: getNameByLocal(reportItem, locale),
    }))
  }];

  const graphsGridElements = currentReport?.content || [];

  return (
    <SidePageMenu
      className={styles.reportWrapper}
      menuClassName={cn(styles.menu, { [styles.opened]: menuOpened })}
      contentClassName={cn(styles.content, {
        [styles.opened]: menuOpened,
        /**
         * isMobileMenuOpened нужно, т.к. на контейнер накладывается transform, а при этом
         * не будет работать position fixed относительно всей страницы
         * (при transform на элементе fixed начинает отсчитываться от него)
         */
        [styles.whenMobileMenuOpened]: isMobileMenuOpened,
      })}
      overlayClassName={cn(styles.overlay, { [styles.opened]: menuOpened })}
      menuItems={menuItems}
      outsideOpenedState={menuOpened}
      onOverlayClick={() => setMenuOpened(false)}
    >
      <>
        {isFetching && <DefaultCircleLoader />}

        <div className={styles.controls}>
          <h2 className={styles.header}>
            <DashboardHeaderIcon
              isClosed={!menuOpened}
              onClick={handlerToggleMenu}
            />
            <span className={styles.dashboardName}>
              {getNameByLocal(currentReport, locale)}
            </span>
          </h2>

          <div className={styles.datePicker}>
            <UiDateRangePicker
              startDate={parseDate(startDate)}
              endDate={parseDate(endDate)}
              minDate={addYears(new Date(), -5)}
              maxDate={addYears(new Date(), 5)}
              onApply={handlerChangeDates}
              staticRanges={getStaticRanges(formatMessage, cycleStartDate, cycleEndDate)}
              datesRenderer={(start, end) => getDatesDescRenderer(formatMessage, cycleStartDate, cycleEndDate, start, end)}
              isMobileMenuOpened={isMobileMenuOpened}
              setIsMobileMenuOpened={setIsMobileMenuOpened}
            />
          </div>
        </div>

        <div className={styles.graphsWrapper}>
          {renderGrid(intl, graphsGridElements)}
        </div>


        <AddViaIntercomButton
          text={formatMessage({ id: 'reports.addGraphs' })}
          onClick={showIntercom}
        />
      </>
    </SidePageMenu>
  );
};

CropReport.propTypes = {
  intl: intlShape.isRequired,
  reportsList: PropTypes.array,
  currentReport: PropTypes.object,
  isFetching: PropTypes.bool,
  requestCropReport: PropTypes.func.isRequired,
  // timezone: PropTypes.string,
  cycleInfo: PropTypes.object,
};

CropReport.defaultProps = {
  reportsList: [],
  currentReport: undefined,
  isFetching: false,
  // timezone: 'Europe/London',
  cycleInfo: undefined,
};

export default CropReport;
