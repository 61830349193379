import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { get } from 'lodash';

import { getMlNameDirect } from 'helpers/getVarietyName';

import SelectboxFilterWithChecks from 'components/SelectboxFilterWithChecks';
import Select from 'components/Select';

import styles from './WorkTypeFilter.module.css';

export default class WorkTypeFilter extends Component {
  static propTypes = {
    onFiltersChange: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    workTypes: PropTypes.array,
    currentWorkTypeId: PropTypes.array,
    capitalizeTitle: PropTypes.bool,
    selectComponent: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),
    isMobile: PropTypes.bool,
    customPlaceholder: PropTypes.string
  };

  static defaultProps = {
    workTypes: null,
    currentWorkTypeId: null,
    capitalizeTitle: false,
    selectComponent: Select,
    isMobile: false,
    customPlaceholder: null
  };

  getWorkTypesList = (workTypes) => {
    const { currentWorkTypeId, intl: { locale } } = this.props;

    if (!workTypes || !workTypes.data) {
      return [];
    }

    return workTypes.data.reduce((acc, workType) => (
      [
        ...acc,
        {
          label: getMlNameDirect(workType, locale),
          value: workType.id,
          checked: currentWorkTypeId ? !!currentWorkTypeId.find(id => +workType.id === +id) : true,
        },
      ]
    ), []);
  }

  setNewSearchPath = (params) => {
    const { onFiltersChange } = this.props;

    if (onFiltersChange) {
      onFiltersChange(params);
    }
  }

  handlerWorkTypesChange = (options) => {
    const isAll = options.every(item => get(item, 'checked'));

    let workTypeId;

    if (!isAll) {
      workTypeId = options.filter(item => get(item, 'checked')).map(item => get(item, 'value'));
    }

    this.setNewSearchPath({ workTypeId });
  }

  render() {
    const {
      intl: { formatMessage },
      workTypes,
      capitalizeTitle,
      selectComponent,
      isMobile,
      customPlaceholder
    } = this.props;

    const workTypesList = this.getWorkTypesList(workTypes, formatMessage);

    return (
      <SelectboxFilterWithChecks
        options={workTypesList}
        onChange={this.handlerWorkTypesChange}
        title={formatMessage({ id: 'dashboards.show' })}
        classNameWrapper={styles.selectboxFilterWrapper}
        pluralPlaceholderId='plural.workType'
        capitalizeTitle={capitalizeTitle}
        selectComponent={selectComponent}
        isMobile={isMobile}
        customPlaceholder={customPlaceholder || (isMobile ? formatMessage({ id: 'dashboards.allWorkTypes' }) : null)}
        isTitleEnabled
      />
    );
  }
}
