import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import * as d3 from 'd3';
import { throttle } from 'lodash';

import styles from './YAxis.module.css';

export default class YAxis extends Component {
  static propTypes = {
    scale: PropTypes.func.isRequired,
    margins: PropTypes.object.isRequired,
    ticksCount: PropTypes.number.isRequired,
    tickFormatting: PropTypes.func,
  };

  static defaultProps = {
    tickFormatting: d => d
  };

  handlerResize = throttle(() => {
    this.rebuildAxis();
  }, 100);

  componentDidMount() {
    window.addEventListener('resize', this.handlerResize);

    this.rebuildAxis();
  }

  componentDidUpdate() {
    this.rebuildAxis();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResize);
  }

  rebuildAxis = () => setTimeout(() => {
    this.renderAxis();
  }, 0); // Баг d3, кажется уже можно убрать

  truncateText = (texts) => {
    const { margins } = this.props;
    const padding = margins.left - 20;
    /* eslint-disable func-names */
    // не стрелочная для сохранения контекста this для d3.select
    const trimText = (currentNode) => {
      if (currentNode.node().getComputedTextLength() > padding) {
        const oldText = currentNode.text();
        const newText = oldText.substring(0, oldText.length - 1);

        currentNode.text(newText);

        if (currentNode.node().getComputedTextLength() > padding) {
          return trimText(currentNode);
        }

        return currentNode.text(`${newText}...`);
      }

      return currentNode;
    };

    texts.each(function () {
      const text = d3.select(this);

      return trimText(text);
    });
    /* eslint-disable func-names */
  }

  renderAxis = () => {
    const { scale, tickFormatting, ticksCount } = this.props;

    const yAxisScale = d3.axisLeft()
      .scale(scale)
      .tickFormat(item => tickFormatting(item))
      .ticks(ticksCount)
      .tickPadding(12)
      .tickSizeInner(0)
      .tickSizeOuter(0);

    d3.select(this.axisElement)
      .call(yAxisScale)
      .selectAll('text')
      .call(this.truncateText);
  }

  render() {
    const { margins } = this.props;

    return (
      <g
        className={styles.axisY}
        ref={(el) => { this.axisElement = el; }}
        transform={`translate(${margins.left},${-margins.top})`}
      />
    );
  }
}
