import { Observable } from 'rxjs';

import initAxiosInstanse from '../../api/axios';

import { showNotificationWithTimeout } from '../notificationCenter/actions';

import {
  requestDataQualityReport,
  receiveDataQualityReport,

  requestOverallQuality,
  receiveOverallQuality,
} from './actions';

export const getDataQualityReportEpic = (action$, store) =>
  action$.ofType(requestDataQualityReport)
    .switchMap((actionData) => {
      const {
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          year,
          detailed,
        },
      } = actionData;

      return Observable
        .from(initAxiosInstanse().get(`/data-quality-report/${organizationId}/${year}?detailed=${detailed.toString()}`))
        .mergeMap(({ data }) => Observable.of(receiveDataQualityReport({ dataQualityReport: data })))
        .catch(() => Observable.of(
          receiveDataQualityReport({ dataQualityReport: null }),
          showNotificationWithTimeout({
            id: `notifications.getDataQualityReport.${Date.now()}`,
            messageId: 'notifications.getDataQualityReport',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getOverallQualityEpic = (action$, store) =>
  action$.ofType(requestOverallQuality)
    .switchMap((actionData) => {
      const {
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const {
        payload: {
          year,
          detailed,
          onError,
        },
      } = actionData;

      return Observable
        .from(initAxiosInstanse().get(`/data-quality-report/${organizationId}/${year}?detailed=${detailed.toString()}`))
        .mergeMap(({ data }) => Observable.of(receiveOverallQuality({
          dataQualityReport: data,
          overallQualityReceivedAt: Date.now(),
        })))
        .catch(() => Observable.of(
          receiveOverallQuality({ dataQualityReport: null }),
          onError,
          showNotificationWithTimeout({
            id: `notifications.getOverallQuality.${Date.now()}`,
            messageId: 'notifications.getOverallQuality',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });
