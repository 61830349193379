// @ts-nocheck

import React, { useCallback } from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import moment from 'moment';

import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';
import { getNameByLocal } from 'helpers/getNameByLocal';
import LINE_COLORS from 'helpers/graphColors';
import getDateFormat from 'helpers/getDateFormat';

import LinesGraphTooltip from './components/LinesGraphTooltip';
import LinesGraph from './components/LinesGraph';

import { CustomChartPlot } from './types';

const tickDisplayFormat = (value, index) => {
  const date = moment.utc(new Date((+value) * 60000));

  if (index === 0) {
    return date.format(getDateFormat('ll'));
  }

  return date.format('HH:mm');
};

const renderFuncImpl = (formatMessage, type, value, options) => {
  if (type === 'dateAndIsoWeekNo') {
    // TODO: remove moment
    const m = moment(value);
    return `${m.format('MMM D')}, ${formatMessage({ id: 'cycle.wk' })} ${m.isoWeek()}`;
  }

  if (type === 'decimalAndUnit' && options) {
    const unitsText = formatMessage({ id: `cunits.mini.${options.units}` });
    return `${numbersFormatting(numbersRounding(value, 'fixed', options.numOfDecimals))} ${unitsText}`;
  }
  return value;
};

const renderTitle = (intl, bubbles) => {
  const { formatMessage } = intl;
  const bubble = bubbles[0];
  const {
    format: {
      renderFunc
    },
    values,
    valueType
  } = bubble.trace.hover.title;

  if (valueType === 'date') {
    return renderFuncImpl(formatMessage, renderFunc, values[bubble.point.index]);
  }

  return values[bubble.point.index];
};

const prepareLines = (intl, bubbles) => {
  const { formatMessage, locale } = intl;
  const bubble = bubbles[0];
  const { trace, point: { index } } = bubble;
  return [{
    header: getNameByLocal(trace.hover, locale, 'xCaption'),
    id: 'xCaption',
    value: renderFuncImpl(formatMessage, trace.hover.xFormat.renderFunc, trace.x[index], {
      units: trace.xUnits,
      numOfDecimals: trace.hover.xFormat.numOfDecimals
    })
  }, {
    header: getNameByLocal(trace.hover, locale, 'yCaption'),
    id: 'yCaption',
    value: renderFuncImpl(formatMessage, trace.hover.yFormat.renderFunc, trace.y[index], {
      units: trace.yUnits,
      numOfDecimals: trace.hover.xFormat.numOfDecimals
    })
  }];
};

const renderTooltipContent = intl => (tooltipState) => {
  const bubbles = tooltipState?.bubbles;
  if (bubbles?.length > 0) {
    return (
      <LinesGraphTooltip
        lines={prepareLines(intl, bubbles)}
        tooltipText={renderTitle(intl, bubbles)}
        isSpaced
      />
    );
  }
  return null;
};

type ChartTableProp = {
  plot: CustomChartPlot;
};

const defaultChartSize = {
  height: 240
};

function isEmpty(plot: CustomChartPlot): boolean {
  if (!plot) {
    return true;
  }

  const {
    traces
  } = plot;
  return traces?.length === 0;
}

const CustomChart = ({
  intl,
  plot,
  isDataFetching,
  chartSize,
} : ChartTableProp & InjectedIntlProps) => {
  const { formatMessage } = intl;
  const renderTooltip = useCallback(renderTooltipContent(intl), [intl]);

  // Добавляем цвет, если не задан на бэке
  const preparedTraces = plot?.traces ? {
    traces: plot?.traces?.map((traceItem, index) => {
      if (traceItem?.line && !traceItem?.line?.color) {
        const colorIndex = index <= LINE_COLORS.length ? index : index - LINE_COLORS.length;

        return {
          ...traceItem,
          line: {
            ...traceItem?.line,
            color: LINE_COLORS[colorIndex],
          }
        };
      }

      return traceItem;
    }),
  } : {};

  const preparedPlot = {
    ...plot,
    ...preparedTraces,
  };

  const xAxisFormatter = plot?.xType === 'epochMinutes' ? tickDisplayFormat : undefined;

  return (
    <LinesGraph
      plot={preparedPlot}
      isEmpty={isEmpty(plot)}
      defaultSize={chartSize || defaultChartSize}
      renderTooltipContent={renderTooltip}
      emptyStateText={formatMessage({ id: 'dashboards.noDataForSelectedPeriod' })}
      isDataFetching={isDataFetching}
      tickDisplayFormat={xAxisFormatter}
    />
  );
};


export default injectIntl(CustomChart);
