import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataQualityNotification from '../DataQualityNotification';

// Враппер нужен, чтобы не маунтить компонент, пока не подгрузились данные организации
export default class DataQualityNotificationWrapper extends Component {
  static propTypes = {
    isOrganizationFetching: PropTypes.bool.isRequired,
    organization: PropTypes.object,
  };

  static defaultProps = {
    organization: null,
  };

  render() {
    const {
      isOrganizationFetching,
      organization
    } = this.props;

    if (isOrganizationFetching || !organization) {
      return null;
    }

    return (
      <DataQualityNotification {...this.props} />
    );
  }
}
