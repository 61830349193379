import { connect } from 'react-redux';
import { getFormValues, getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

import { getIsSimulationSaving } from 'store/digitalTwin/selectors';

import EditDialog from '../components/EditDialog';

const mapDispatchToProps = {
  // userFormOnSubmit,
};

const mapStateToProps = (state, props) => ({
  isFetching: getIsSimulationSaving(state),
  formValues: getFormValues('simulationNameForm')(state),
  formSyncErrors: getFormSyncErrors('simulationNameForm')(state),

  ...props,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditDialog));
