import { Observable } from 'rxjs';
import initAxiosInstanse from 'api/axios';
import queryString from 'query-string';
import { combineEpics } from 'redux-observable';
import { get } from 'lodash';
import FileSaver from 'file-saver';

import { showNotification, showNotificationWithTimeout, hideNotification } from 'store/notificationCenter/actions';
import {
  requestCreateForecast,
  requestDeleteForecast,
  requestGetForecast,
  requestGetForecastList,
  requestUpdateForecast,
  responseCreateForecast,
  responseDeleteForecast,
  responseGetForecast,
  responseGetForecastList,
  responseUpdateForecast,
  requestGetForecastVersions,
  receiveGetForecastVersions,
  requestGetForecastLastVersion,
  receiveGetForecastLastVersion,
  requestGetForecastReportData,
  responseGetForecastReportData,
  requestGetForecastVersion,
  receiveGetForecastVersion,
  requestSaveForecastVersion,
  receiveSaveForecastVersion,
  requestRestoreForecastVersion,
  receiveRestoreForecastVersion,
  requestPublishForecast,
  receivePublishForecast,
  requestAssistantForecast,
  receiveAssistantForecast,
  changeReportFilters,
  setGraphKey,
  requestForecastNotificationSubscribers,
  receiveForecastNotificationSubscribers,
  receiveSaveAndPublishForecast,
  requestSaveAndPublishForecast, clearForecastState, clearGraphKeys,
  requestHarvestForecastSettings,
  receiveHarvestForecastSettings,
  requestForecastSubscribersOfLocation,
  receiveForecastSubscribersOfLocation, setFields,
  requestHarvestForecastExport,
  receiveHarvestForecastExport,
  setAssistantPanelSettings,
  requestCreateExtraProduct,
  receiveCreateExtraProduct,
  requestUpdateExtraProduct,
  receiveUpdateExtraProduct,
  requestDeleteExtraProduct,
  receiveDeleteExtraProduct,
  requestEditForecastVersionInfo,
  receiveEditForecastVersionInfo,
  requestDeleteForecastVersionInfo,
  receiveDeleteForecastVersionInfo,
} from 'store/harvestForecast/actions';

import updateLocationSearch from 'helpers/updateLocationSearch';
import { getReportFiltersFromQuery } from 'store/harvestForecast/selectors';
import storageWrapper from 'helpers/storageWrapper';

export const getForecastListEpic = (action$, store) =>
  action$.ofType(requestGetForecastList)
    .switchMap((actionData) => {
      const {
        company,
        router
      } = store.getState();
      const {
        location,
      } = company;
      const { id: locationId } = location;

      const {
        payload: {
          pageNumber,
          pageSize,
        }
      } = actionData;

      const queryParams = queryString.stringify({
        locationId,
        pageNumber, // : pageNumber || 1,
        pageSize, // : pageSize || 10
      });

      const searchParams = router?.location?.search;
      return Observable
        .from(initAxiosInstanse(searchParams).get(`harvest-forecasts?${queryParams}`))
        .map(({ data }) => responseGetForecastList(data))
        .catch(() => Observable.of(
          responseGetForecastList(),
          showNotificationWithTimeout({
            id: `notifications.getForecastListEpicError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const createForecastEpic = (action$, store) =>
  action$.ofType(requestCreateForecast)
    .switchMap((actionData) => {
      const {
        company,
        router
      } = store.getState();
      const {
        location,
      } = company;
      const { id: locationId } = location;

      const {
        payload: {
          name,
          start,
          endInclusive,
          species,
          actionAfterSuccess = () => {},
        }
      } = actionData;

      const queryParams = queryString.stringify({
        locationId,
      });

      const searchParams = router?.location?.search;
      return Observable
        .from(initAxiosInstanse(searchParams).post(`harvest-forecasts?${queryParams}`, {
          name,
          start,
          endInclusive,
          species
        }))
        .mergeMap(({ data }) => Observable.of(
          clearForecastState(),
          responseCreateForecast(data),
          () => actionAfterSuccess(data?.id),
        ))
        .catch(() => Observable.of(
          responseCreateForecast(),
          showNotificationWithTimeout({
            id: `notifications.createForecastEpicError.${Date.now()}`,
            messageId: 'forecast.saveForecastError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });


export const getForecastEpic = (action$, store) =>
  action$.ofType(requestGetForecast)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          id,
        }
      } = actionData;
      const searchParams = router?.location?.search;
      return Observable
        .from(initAxiosInstanse(searchParams).get(`harvest-forecasts/${id}`))
        .map(({ data }) => responseGetForecast(data))
        .catch(() => Observable.of(
          responseGetForecast(),
          showNotificationWithTimeout({
            id: `notifications.getForecastEpicError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const updateForecastEpic = (action$, store) =>
  action$.ofType(requestUpdateForecast)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          id,
          name,
          start,
          endInclusive,
          actionAfterSuccess = () => {},
        }
      } = actionData;
      const searchParams = router?.location?.search;
      return Observable
        .from(initAxiosInstanse(searchParams).put(`harvest-forecasts/${id}`, {
          name,
          start,
          endInclusive,
        }))
        .mergeMap(({ data }) => Observable.of(
          clearForecastState(),
          actionAfterSuccess,
          responseUpdateForecast(data),
          showNotificationWithTimeout({
            id: 'notifications.saveForecastSuccess',
            messageId: 'forecast.saveForecastSuccess',
            iconType: 'success',
            notificationType: 'withAction',
          })
        ))
        .catch(() => Observable.of(
          responseUpdateForecast(),
          showNotificationWithTimeout({
            id: `notifications.updateForecastEpicError.${Date.now()}`,
            messageId: 'forecast.saveForecastError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });


export const deleteForecastEpic = (action$, store) =>
  action$.ofType(requestDeleteForecast)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          id,
          actionAfterSuccess = () => {},
        }
      } = actionData;
      const searchParams = router?.location?.search;
      return Observable
        .from(initAxiosInstanse(searchParams).delete(`harvest-forecasts/${id}`))
        .mergeMap(({ data }) => Observable.of(
          actionAfterSuccess,
          responseDeleteForecast(data),
          showNotificationWithTimeout({
            id: 'notifications.deleteForecastSuccess',
            messageId: 'forecast.deleteForecastSuccess',
            iconType: 'success',
            notificationType: 'withAction',
          })
        ))
        .catch(() => Observable.of(
          responseDeleteForecast(),
          showNotificationWithTimeout({
            id: `notifications.deleteForecastEpicError.${Date.now()}`,
            messageId: 'forecast.deleteForecastError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getForecastVersionsEpic = (action$, store) =>
  action$.ofType(requestGetForecastVersions)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          forecastId,
        }
      } = actionData;
      const searchParams = router?.location?.search;
      return Observable
        .from(initAxiosInstanse(searchParams).get(`harvest-forecasts/${forecastId}/versions`))
        .map(({ data }) => receiveGetForecastVersions(data))
        .catch(() => Observable.of(
          receiveGetForecastVersions(),
          showNotificationWithTimeout({
            id: `notifications.getForecastVersionsEpic.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getForecastLastVersionEpic = (action$, store) =>
  action$.ofType(requestGetForecastLastVersion)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          forecastId,
        }
      } = actionData;
      const searchParams = router?.location?.search;
      return Observable
        .from(initAxiosInstanse(searchParams).get(`harvest-forecasts/${forecastId}/versions/last`))
        .map(({ data }) => receiveGetForecastLastVersion(data))
        .catch(() => Observable.of(
          receiveGetForecastLastVersion(),
          showNotificationWithTimeout({
            id: `notifications.getForecastLastVersionEpic.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getForecastVersionEpic = (action$, store) =>
  action$.ofType(requestGetForecastVersion)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          versionId
        }
      } = actionData;
      const searchParams = router?.location?.search;
      return Observable
        .from(initAxiosInstanse(searchParams).get(`harvest-forecasts/versions/${versionId}`))
        .map(({ data }) => receiveGetForecastVersion(data))
        .catch(() => Observable.of(
          receiveGetForecastVersion(),
          showNotificationWithTimeout({
            id: `notifications.getForecastVersionEpic.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const restoreForecastVersionEpic = (action$, store) =>
  action$.ofType(requestRestoreForecastVersion)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          versionId,
          forecastId
        }
      } = actionData;
      const searchParams = router?.location?.search;
      return Observable
        .from(initAxiosInstanse(searchParams).post(`harvest-forecasts/versions/${versionId}/restore`))
        .mergeMap(({ data }) => Observable.of(
          receiveRestoreForecastVersion(data),
          requestGetForecastVersions({ forecastId })
        ))
        .catch(() => Observable.of(
          receiveRestoreForecastVersion(),
          showNotificationWithTimeout({
            id: `notifications.restoreForecastVersionEpic.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getForecastReportDataEpic = (action$, store) =>
  action$.ofType(requestGetForecastReportData)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          versionId,
          filters/*: {
            breakdown,
            relative,
            compartmentId,
            varietyId,
            fruitClassCode,
            productGroup,
          } */
        }
      } = actionData;

      // FIXME: Crutch for stabilization HarvestForecastEnterForm component
      // Remove it after fix HarvestForecastEnterForm lifecycle bug, use getReportFilters in mapStateToProps
      const queryFilters = getReportFiltersFromQuery();
      const queryParams = queryString.stringify({
        ...filters,
        ...queryFilters,
      });
      const searchParams = router?.location?.search;
      return Observable
        .from(initAxiosInstanse(searchParams).get(`harvest-forecasts/versions/${versionId}/report?${queryParams}`))
        .map(({ data }) => responseGetForecastReportData(data))
        .catch(() => Observable.of(
          responseGetForecastReportData(),
          showNotificationWithTimeout({
            id: `notifications.getForecastReportDataEpic.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const saveForecastVersionEpic = (action$, store) =>
  action$.ofType(requestSaveForecastVersion)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          forecastId,
          productForecasts,
          comment,
        }
      } = actionData;

      let editedForecast = {
        productForecasts
      };

      if (comment) {
        editedForecast = {
          ...editedForecast,
          comment
        };
      }
      const searchParams = router?.location?.search;
      return Observable
        .from(initAxiosInstanse(searchParams).post(`harvest-forecasts/${forecastId}/versions`, editedForecast))
        .mergeMap(({ data }) => Observable.of(
          requestGetForecastVersions({ forecastId }),
          receiveSaveForecastVersion(data),
          showNotificationWithTimeout({
            id: 'notifications.saveForecastVersionSuccess',
            messageId: 'forecast.saveForecastVersionSuccess',
            iconType: 'success',
            notificationType: 'withAction',
          })
        ))
        .catch(() => Observable.of(
          receiveSaveForecastVersion(),
          showNotificationWithTimeout({
            id: `notifications.saveForecastVersionEpic.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const publishForecastEpic = (action$, store) =>
  action$.ofType(requestPublishForecast)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          versionId,
          forecastId,
          notify,
        }
      } = actionData;
      const searchParams = router?.location?.search;
      const queryParams = queryString.stringify({
        notify,
      });

      return Observable
        .from(initAxiosInstanse(searchParams).post(`harvest-forecasts/versions/${versionId}/publish?${queryParams}`))
        .mergeMap(({ data }) => Observable.of(
          receivePublishForecast(data),
          requestGetForecastVersions({ forecastId }),
          showNotificationWithTimeout({
            id: 'notifications.publishForecastSuccess',
            messageId: 'forecast.publishForecastSuccess',
            iconType: 'success',
            notificationType: 'withAction',
          })
        ))
        .catch(() => Observable.of(
          receivePublishForecast(),
          showNotificationWithTimeout({
            id: `notifications.publishForecastEpic.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getAssistantForecastEpic = (action$, store) =>
  action$.ofType(requestAssistantForecast)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          versionId,
          forecastId,
          history,
          manualForecast
        }
      } = actionData;

      const queryParams = queryString.stringify({
        history,
        versionId
      });
      const searchParams = router?.location?.search;

      return Observable
        .from(initAxiosInstanse(searchParams).post(`harvest-forecasts/${forecastId}/assistant?${queryParams}`, manualForecast))
        .map(({ data }) => receiveAssistantForecast(data))
        .catch(() => Observable.of(
          receiveAssistantForecast(),
          showNotificationWithTimeout({
            id: `notifications.getAssistantForecastEpic.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const changeReportFiltersEpic = action$ =>
  action$.ofType(changeReportFilters)
    .switchMap((actionData) => {
      const {
        payload
      } = actionData;
      updateLocationSearch(payload);
      return Observable.of();
    });

export const setGraphKeyEpic = action$ =>
  action$.ofType(setGraphKey)
    .switchMap((actionData) => {
      const {
        payload
      } = actionData;
      updateLocationSearch(payload);
      return Observable.of();
    });

export const clearGraphKeysEpic = action$ =>
  action$.ofType(clearGraphKeys)
    .switchMap(() => {
      updateLocationSearch({
        byDates: undefined,
        overall: undefined
      });
      return Observable.of();
    });

export const setFieldsEpic = action$ =>
  action$.ofType(setFields)
    .switchMap((actionData) => {
      const safeLocalStorage = storageWrapper.get('localStorage');
      const {
        payload: {
          fields,
          settingsId
        }
      } = actionData;
      const fieldsString = safeLocalStorage.getItem(`HFActionTable-${settingsId}`);
      const fieldsData = fieldsString ? JSON.parse(fieldsString) : {};
      const newFields = { ...fieldsData, ...fields };

      safeLocalStorage.setItem(`HFActionTable-${settingsId}`, JSON.stringify(newFields));
      return Observable.of();
    });

export const getForecastNotificationSubscribersEpic = (action$, store) =>
  action$.ofType(requestForecastNotificationSubscribers)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();

      const {
        payload: {
          forecastId,
          versionId,
        }
      } = actionData;

      const searchParams = router?.location?.search;

      const queryParams = queryString.stringify({
        versionId,
      });

      const query = queryParams ? `${queryParams}` : '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`/harvest-forecasts/${forecastId}/notifications?${query}`))
        .map(({ data }) => receiveForecastNotificationSubscribers(data))
        .catch(() => Observable.of(
          receiveForecastNotificationSubscribers(),
          showNotificationWithTimeout({
            id: `notifications.getForecastNotificationSubscribersEpic.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const saveAndPublishForecastEpic = (action$, store) =>
  action$.ofType(requestSaveAndPublishForecast)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();
      const {
        payload: {
          forecastId,
          productForecasts,
          comment,
          notify,
        }
      } = actionData;

      let editedForecast = {
        productForecasts
      };

      if (comment) {
        editedForecast = {
          ...editedForecast,
          comment
        };
      }
      const searchParams = router?.location?.search;
      return Observable
        .from(initAxiosInstanse(searchParams).post(`harvest-forecasts/${forecastId}/versions`, editedForecast))
        .mergeMap(({ data }) => Observable.of(
          receiveSaveForecastVersion(data),
          requestPublishForecast({ versionId: data.id, forecastId, notify }),
          showNotificationWithTimeout({
            id: 'notifications.publishForecastSuccess',
            messageId: 'forecast.publishForecastSuccess',
            iconType: 'success',
            notificationType: 'withAction',
          })
        ))
        .catch(() => Observable.of(
          receiveSaveAndPublishForecast(),
          showNotificationWithTimeout({
            id: `notifications.saveAndPublishForecastEpic.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getForecastSettingsEpic = (action$, store) =>
  action$.ofType(requestHarvestForecastSettings)
    .switchMap(() => {
      const {
        company,
        router
      } = store.getState();
      const {
        location,
      } = company;
      const { id: locationId } = location;


      const searchParams = router?.location?.search;
      const queryParams = queryString.stringify({
        locationId,
      });

      return Observable
        .from(initAxiosInstanse(searchParams).get(`harvest-forecasts/settings?${queryParams}`))
        .map(({ data }) => receiveHarvestForecastSettings(data))
        .catch(() => Observable.of(
          receiveHarvestForecastSettings(),
          showNotificationWithTimeout({
            id: `notifications.getForecastSettingsEpic.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getForecastSubscribersOfLocationEpic = (action$, store) =>
  action$.ofType(requestForecastSubscribersOfLocation)
    .switchMap(() => {
      const {
        company,
        router
      } = store.getState();
      const {
        location,
      } = company;
      const { id: locationId } = location;


      const searchParams = router?.location?.search;
      const queryParams = queryString.stringify({
        locationId,
      });

      return Observable
        .from(initAxiosInstanse(searchParams).get(`harvest-forecasts/notifications?${queryParams}`))
        .map(({ data }) => receiveForecastSubscribersOfLocation(data))
        .catch(() => Observable.of(
          receiveForecastSubscribersOfLocation(),
          showNotificationWithTimeout({
            id: `notifications.getForecastSettingsEpic.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const showHarvestForecastExportNotificationEpic = action$ =>
  action$
    .ofType(requestHarvestForecastExport)
    .mergeMap(() => Observable.of(
      showNotification({
        id: 'notifications.downloadHarvestForecastXSLX',
        messageId: 'notifications.downloadHarvestForecastXSLX',
        iconType: 'loading',
        notificationType: 'withActionWide',
      })
    ));

export const getHarvestForecastExportEpic = (action$, store) =>
  action$.ofType(requestHarvestForecastExport)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();

      const searchParams = router?.location?.search;

      const {
        payload: {
          versionId,
          filters,
          actionAfterSuccess = () => {},
        }
      } = actionData;

      const queryParams = queryString.stringify(filters);

      return Observable
        .from(
          initAxiosInstanse(
            searchParams,
            120000,
            {
              credentials: 'include',
              responseType: 'blob',
            }
          )
            .get(`harvest-forecasts/versions/${versionId}/report/xlsx?${queryParams}`)
        )
        .mergeMap((response) => {
          const fileName = get(response, 'headers[x-filename]');

          FileSaver.saveAs(response.data, fileName);

          return Observable.of(
            actionAfterSuccess,
            hideNotification('notifications.downloadHarvestForecastXSLX'),
            receiveHarvestForecastExport(),
            showNotificationWithTimeout({
              id: 'notifications.harvestForecastXSLXDownloaded',
              messageId: 'notifications.harvestForecastXSLXDownloaded',
              iconType: 'success',
              notificationType: 'withActionWide',
            })
          );
        })
        .catch(() => Observable.of(
          hideNotification('notifications.downloadHarvestForecastXSLX'),
          receiveHarvestForecastExport(),
          showNotificationWithTimeout({
            id: `notifications.harvestForecastXSLXDownloadError.${Date.now()}`,
            messageId: 'notifications.harvestForecastXSLXDownloadError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const setAssistantPanelSettingsEpic = action$ =>
  action$.ofType(setAssistantPanelSettings)
    .switchMap((actionData) => {
      const safeLocalStorage = storageWrapper.get('localStorage');
      const {
        payload
      } = actionData;
      const fieldsString = safeLocalStorage.getItem('assistantPanelSettings');
      const fields = fieldsString ? JSON.parse(fieldsString) : {};
      const newFields = { ...fields, ...payload };
      safeLocalStorage.setItem('assistantPanelSettings', JSON.stringify(newFields));
      return Observable.of();
    });

export const createExtraProductEpic = (action$, store) =>
  action$.ofType(requestCreateExtraProduct)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          forecastId,
          name,
          actionAfterSuccess = () => {},
        }
      } = actionData;
      const searchParams = router?.location?.search;

      return Observable
        .from(initAxiosInstanse(searchParams).post(`harvest-forecasts/${forecastId}/extra-products`, { name }))
        .mergeMap(({ data }) => Observable.of(
          actionAfterSuccess,
          receiveCreateExtraProduct(data),
        ))
        .catch(() => Observable.of(
          receiveCreateExtraProduct(),
          showNotificationWithTimeout({
            id: `notifications.createExtraProductEpic.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const updateExtraProductEpic = (action$, store) =>
  action$.ofType(requestUpdateExtraProduct)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          extraProductId,
          name,
          actionAfterSuccess = () => {},
        }
      } = actionData;
      const searchParams = router?.location?.search;

      return Observable
        .from(initAxiosInstanse(searchParams).put(`harvest-forecasts/extra-products/${extraProductId}`, {
          name,
        }))
        .mergeMap(({ data }) => Observable.of(
          actionAfterSuccess,
          receiveUpdateExtraProduct(data),
        ))
        .catch(() => Observable.of(
          receiveUpdateExtraProduct(),
          showNotificationWithTimeout({
            id: `notifications.updateExtraProductEpic.${Date.now()}`,
            messageId: 'forecast.updateExtraProductError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const deleteExtraProductEpic = (action$, store) =>
  action$.ofType(requestDeleteExtraProduct)
    .switchMap((actionData) => {
      const {
        router
      } = store.getState();
      const {
        payload: {
          extraProductId,
          actionAfterSuccess = () => {},
        }
      } = actionData;
      const searchParams = router?.location?.search;

      return Observable
        .from(initAxiosInstanse(searchParams).delete(`harvest-forecasts/extra-products/${extraProductId}`))
        .mergeMap(({ data }) => Observable.of(
          actionAfterSuccess,
          receiveDeleteExtraProduct(data),
        ))
        .catch(() => Observable.of(
          receiveDeleteExtraProduct(),
          showNotificationWithTimeout({
            id: `notifications.deleteExtraProductEpic.${Date.now()}`,
            messageId: 'forecast.deleteExtraProductError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const editForecastVersionInfoEpic = action$ =>
  action$.ofType(requestEditForecastVersionInfo)
    .switchMap((actionData) => {
      const {
        payload: {
          forecastId,
          versionId,
          label,
          comment,
          actionSuccess,
        },
      } = actionData;

      return Observable
        .from(initAxiosInstanse().post(`harvest-forecasts/versions/${versionId}/label`, {
          label,
          comment,
        }))
        .mergeMap(({ data }) => Observable.of(
          actionSuccess,
          receiveEditForecastVersionInfo(data),
          requestGetForecastVersions({ forecastId }),
        ))
        .catch(() => Observable.of(
          receiveEditForecastVersionInfo(),
          showNotificationWithTimeout({
            id: `notifications.editForecastVersionInfoError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const deleteForecastVersionInfoEpic = action$ =>
  action$.ofType(requestDeleteForecastVersionInfo)
    .switchMap((actionData) => {
      const {
        payload: {
          forecastId,
          versionId,
          actionSuccess,
        },
      } = actionData;

      return Observable
        .from(initAxiosInstanse().delete(`harvest-forecasts/versions/${versionId}/label`))
        .mergeMap(({ data }) => Observable.of(
          actionSuccess,
          receiveDeleteForecastVersionInfo(data),
          requestGetForecastVersions({ forecastId }),
        ))
        .catch(() => Observable.of(
          receiveDeleteForecastVersionInfo(),
          showNotificationWithTimeout({
            id: `notifications.deleteForecastVersionInfoError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export default combineEpics(
  createForecastEpic,
  deleteForecastEpic,
  getForecastEpic,
  getForecastListEpic,
  updateForecastEpic,
  getForecastVersionsEpic,
  getForecastLastVersionEpic,
  getForecastReportDataEpic,
  getForecastVersionEpic,
  saveForecastVersionEpic,
  restoreForecastVersionEpic,
  publishForecastEpic,
  getAssistantForecastEpic,
  changeReportFiltersEpic,
  setGraphKeyEpic,
  getForecastNotificationSubscribersEpic,
  saveAndPublishForecastEpic,
  clearGraphKeysEpic,
  getForecastSettingsEpic,
  getForecastSubscribersOfLocationEpic,
  setFieldsEpic,
  showHarvestForecastExportNotificationEpic,
  getHarvestForecastExportEpic,
  setAssistantPanelSettingsEpic,
  createExtraProductEpic,
  updateExtraProductEpic,
  deleteExtraProductEpic,
  editForecastVersionInfoEpic,
  deleteForecastVersionInfoEpic,
);
