import { createSelector } from 'reselect';
import storageWrapper from 'helpers/storageWrapper';

export const getForecastsList = state => state.harvestForecast.forecasts;

export const getIsForecastListFetching = state => state.harvestForecast.isForecastListFetching;

export const getForecast = state => state.harvestForecast.forecast;

export const getIsForecastFetching = state => state.harvestForecast.isForecastFetching;

export const getForecastVersions = state => state.harvestForecast.forecastVersions;
export const getIsForecastVersionsFetching = state => state.harvestForecast.isForecastVersionsFetching;

export const getForecastData = state => state.harvestForecast.forecastData;
export const getIsForecastDataFetching = state => state.harvestForecast.isForecastDataFetching;

export const getForecastReportData = state => state.harvestForecast.forecastReportData;
export const getIsForecastReportDataFetching = state => state.harvestForecast.isForecastReportDataFetching;

export const getFieldsFromStore = state => state.harvestForecast.fields;

export const getVersionIdFromQuery = () => {
  const values = new URLSearchParams(window.location.search);
  if (values.has('versionId')) {
    return values.get('versionId');
  }
  return null;
};

export const getForecastVersionId = createSelector([
  getForecastData
], (
  forecastData
) => {
  const values = new URLSearchParams(window.location.search);
  if (values.has('versionId')) {
    return values.get('versionId') || forecastData?.id || null;
  }
  return forecastData?.id || null;
});

const getReportFiltersFromState = state => state.harvestForecast.reportFilters;

export const getReportFiltersFromQuery = () => {
  const values = new URLSearchParams(window.location.search);
  const filters = {};
  if (values.has('fruitClassCode')) {
    filters.fruitClassCode = values.get('fruitClassCode');
  }
  if (values.has('productGroup')) {
    filters.productGroup = values.getAll('productGroup');
  }
  if (values.has('species')) {
    filters.species = values.get('species');
  }
  if (values.has('varietyId')) {
    filters.varietyId = parseInt(values.get('varietyId'), 10);
  }
  if (values.has('breakdown')) {
    filters.breakdown = values.get('breakdown') || 'fruitClass';
  } else {
    filters.breakdown = 'fruitClass';
  }
  if (values.has('compartmentId')) {
    filters.compartmentId = values.getAll('compartmentId');
  }
  if (values.has('relative')) {
    filters.relative = values.get('relative') === 'true';
  } else {
    filters.relative = false;
  }
  if (values.has('period')) {
    filters.period = values.get('period') || 'all';
  } else {
    filters.period = 'all';
  }
  return filters;
};

export const getReportFilters = createSelector([
  getReportFiltersFromState,
  getReportFiltersFromQuery
], (
  reportFilters,
  queryFilters
) => ({
  // ...reportFilters,
  ...queryFilters
}));

const getGraphKeyFromState = state => state.harvestForecast.graphKey;

export const getGraphKeyFromQuery = () => {
  const values = new URLSearchParams(window.location.search);
  const graphKey = {
    overall: null,
    byDates: null,
  };

  if (values.has('overall')) {
    graphKey.overall = values.getAll('overall');
  }

  if (values.has('byDates')) {
    graphKey.byDates = values.getAll('byDates');
  }

  return graphKey;
};

export const getGraphKey = createSelector([
  getGraphKeyFromState,
  // getGraphKeyFromQuery,
  getForecastReportData
], (
  graphKeyState,
  // graphKeyQuery,
  forecastReportData
) => {
  const values = new URLSearchParams(window.location.search);

  const graphKey = {
    overall: null,
    byDates: null,
  };

  if (values.has('overall')) {
    graphKey.overall = values.getAll('overall');
  } else if (forecastReportData?.report) {
    graphKey.overall = forecastReportData?.report?.map(item => item.key)?.filter(key => key !== 'total');
  }

  if (values.has('byDates')) {
    graphKey.byDates = values.getAll('byDates');
  } else {
    graphKey.byDates = ['total'];
  }

  return graphKey;
});

const getFieldsFromLocalStorage = (state) => {
  const settingsId = state?.harvestForecast?.forecastReportData?.settingsId;
  if (!settingsId) {
    return null;
  }
  const safeLocalStorage = storageWrapper.get('localStorage');
  const fields = safeLocalStorage.getItem(`HFActionTable-${settingsId}`);
  return fields ? JSON.parse(fields) : null;
};

function combineCategories(localStorageCat, reportCategories) {
  let categoryList = [];

  categoryList = localStorageCat.filter(item =>
    reportCategories.findIndex(cat =>
      cat.categoryType === item.categoryType &&
      cat.forecastType === item.forecastType &&
      cat.forecastCategoryId === item.forecastCategoryId) !== -1);

  categoryList.push(...reportCategories.filter(item => localStorageCat.findIndex(cat =>
    cat.categoryType === item.categoryType &&
    cat.forecastType === item.forecastType &&
    cat.forecastCategoryId === item.forecastCategoryId) === -1));

  return categoryList;
}

function normalizeCategoriesNames(reportCategories, categories) {
  return categories.map((cat) => {
    const foundCat = reportCategories.find(reportCat =>
      cat.categoryType === reportCat.categoryType &&
      cat.forecastType === reportCat.forecastType &&
      cat.forecastCategoryId === reportCat.forecastCategoryId);
    return {
      ...cat,
      name: foundCat?.name
    };
  });
}

export const getFields = createSelector(
  [
    getForecastReportData,
    getFieldsFromLocalStorage,
    getFieldsFromStore,
  ], (
    forecastReportData,
    localStorageFields,
    fieldsFromStore,
  ) => {
    const reportCategories = forecastReportData?.categories.map(catItem => ({
      ...catItem,
      checked: catItem.categoryType !== 'ACCURACY'
    })).filter(catItem => catItem.categoryType !== 'DIFFERENCE_ACCURACY') || [];

    const storeFields = fieldsFromStore ? fieldsFromStore?.fields : {};

    let fields = {
      overall: [],
      byDates: [],
    };

    if (localStorageFields?.overall) {
      fields.overall = combineCategories(localStorageFields?.overall, reportCategories);
    } else {
      fields.overall = [...reportCategories];
    }
    if (localStorageFields?.byDates) {
      fields.byDates = combineCategories(localStorageFields?.byDates, reportCategories);
    } else {
      fields.byDates = [...reportCategories];
    }

    fields.overall = normalizeCategoriesNames(reportCategories, fields.overall);
    fields.byDates = normalizeCategoriesNames(reportCategories, fields.byDates);

    fields = {
      ...fields,
      ...storeFields,
    };

    return fields;
  }
);

export const getIsShowVersionPanel = state => state.harvestForecast.showVersionPanel;

export const getIsForecastSaving = state => state.harvestForecast.isForecastSaving;

export const getVersionsFilter = state => state.harvestForecast.versionsFilter;

export const getFilteredPlanVersions = createSelector(
  getForecastVersions,
  getVersionsFilter,
  (allVersions, versionsFilter) => {
    switch (versionsFilter) {
      case 'all':
        return allVersions;
      case 'named':
        return allVersions.filter(version => version.label);
      case 'published':
        return allVersions.filter(version => version.publication);
      default:
        return allVersions;
    }
  },
);

export const getAssistantForecast = state => state.harvestForecast.assistant;
export const getIsAssistantForecastFetching = state => state.harvestForecast.isAssistantFetching;

export const getChangesTime = state => state.harvestForecast.changesTime;

export const getNotificationSubscribers = state => state.harvestForecast.notificationSubscribers;
export const getIsSubscribersFetching = state => state.harvestForecast.isSubscribersFetching;

export const getIsHarvestForecastSettingsFetching = state => state.harvestForecast.isHarvestForecastSettingsFetching;
export const getHarvestForecastSettings = state => state.harvestForecast.harvestForecastSettings;

export const getIsForecastSubscribersOfLocationFetching = state => state.harvestForecast.isForecastSubscribersOfLocationFetching;
export const getForecastSubscribersOfLocation = state => state.harvestForecast.forecastSubscribersOfLocation;

export const getSpeciesType = (state, props) => props?.match?.params?.speciesType; // Внимание! Селектор требует использование HOC withRouter в компоненте

export const getAssistantPanelSettings = () => {
  const safeLocalStorage = storageWrapper.get('localStorage');
  const fields = safeLocalStorage.getItem('assistantPanelSettings');
  return fields ? JSON.parse(fields) : {
    showHistoricalPeriod: true
  };
};

export const getIsDeleteVersionInfoFetching = state => state.harvestForecast.isDeleteVersionInfoFetching;
export const getIsVersionInfoFetching = state => state.harvestForecast.isVersionInfoFetching;
