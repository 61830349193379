import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { drawPathXY } from './DrawUtils';

class SvgIcon {
  /**
   * Ctor
   * @param component - svg as ReactComponent
   * @param scale - draw image scale
   */
  constructor(component, scale = 1.0) {
    const IconComponent = component;
    const iconText = ReactDOMServer.renderToString(<IconComponent />);
    const svgParser = new DOMParser();
    const iconDoc = svgParser.parseFromString(iconText, 'image/svg+xml');

    const svgRoot = iconDoc.getElementsByTagName('svg')[0];

    this.width = parseInt(svgRoot.getAttribute('width'), 10) * scale;
    this.height = parseInt(svgRoot.getAttribute('height'), 10) * scale;
    const viewBoxAttr = svgRoot.getAttribute('viewBox');

    if (viewBoxAttr) {
      const values = viewBoxAttr.split(' ');
      this.viewBox = {
        x: values[0],
        y: values[1],
        w: values[2],
        h: values[3],
      };
    }

    const path = svgRoot.getElementsByTagName('path')[0];

    this.path = new Path2D(path.getAttribute('d'));
    this.fillStyle = path.getAttribute('fill');

    this.scale = scale;
  }

  /**
   * Draw image on canvas
   * @param ctx
   * @param position - absolute position { x, y }
   * @param fillColor - override fill color
   */
  draw(ctx, {
    position,
    fillColor
  }) {
    ctx.fillStyle = fillColor || this.fillStyle || '#777776';
    drawPathXY(
      ctx,
      this.path,
      position.x - this.width / 2,
      position.y - this.height / 2,
      this.scale
    );
  }
}

export default SvgIcon;
