const LINE_COLORS = [
  // First iteration
  '#1DBADF',
  '#00B197',
  '#873679',
  '#BE1034',
  '#DB973A',
  '#8C46C3',
  '#C740B1',
  '#C8427A',
  '#2576C1',
  '#1185AD',
  '#34BA6A',
  '#E17227',
  '#3FC4E4',
  '#26597F',
  '#26BDA7',
  '#99548D',

  // Second iteration (change alpha channel)
  '#61CFE9',
  '#4D7795',
  '#4DC8B6',
  '#AB72A1',
  '#AF7ED5',
  '#D879C8',
  '#D97BA2',
  '#BBEAF5',
  '#B3C5D2',
  '#B3E8E0',
  '#DBC3D7',
  '#DDC8ED',
  '#EEC6E8',
  '#EFC6D7',
  '#F8E8EF',

  // Third iteration (change alpha channel)
  '#0A94CA',
  '#001A3A',
  '#008869',
  '#571649',
  '#5C20A1',
  '#A61C88',
  '#A71D4A',
  '#15ABD7',
  '#002E55',
  '#00A184',
  '#742966',
  '#7937B5',
  '#BA32A1',
  '#BB3367',
  '#E0F6F3',
];

export default LINE_COLORS;
