import { SHOW_SIDE_PANEL, HIDE_SIDE_PANEL } from './actions';

const initialState = {
  sidePanel: {
    sidePanelType: null,
    sidePanelProps: {}
  },
  sidePanelOpen: false
};

export default function sidePanelReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SIDE_PANEL:
      return {
        ...state,
        sidePanel: {
          sidePanelType: action.payload.sidePanelType,
          sidePanelProps: action.payload.sidePanelProps
        },
        sidePanelOpen: true
      };
    case HIDE_SIDE_PANEL:
      return initialState;
    default:
      return state;
  }
}
