import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import compareGraphMetrics from '../../helpers/compareGraphMetrics';
import getMetricCategories from '../../helpers/getMetricCategories';

import {
  REQUEST_ALL_GRAPH_METRICS,
  RECEIVE_ALL_GRAPH_METRICS,
  INVALIDATE_ALL_GRAPH_METRICS,

  REQUEST_USER,
  RECEIVE_USER,
  INVALIDATE_USER,

  requestUserPermissions,
  receiveUserPermissions,

  REQUEST_USER_PROFILE,
  RECEIVE_USER_PROFILE,
  INVALIDATE_USER_PROFILE,

  requestLocations,
  receiveLocations,
  setupLocation,
  INVALIDATE_LOCATIONS,

  REQUEST_TILES,
  RECEIVE_TILES,
  INVALIDATE_TILES,

  requestAllCompartments,
  receiveAllCompartments,
  INVALIDATE_ALL_COMPARTMENTS,

  requestAllPlantingCycles,
  receiveAllPlantingCycles,

  requestAllGreenhouseTypes,
  receiveAllGreenhouseTypes,

  INVALIDATE_ALL_PLANTING_CYCLES,

  requestAllSubNodes,
  receiveAllSubNodes,

  INVALIDATE_ALL_SUB_NODES,

  INVALIDATE_VARIETIES,
  INVALIDATE_LOCATION,

  REQUEST_COMPARTMENTS,
  RECEIVE_COMPARTMENTS,
  INVALIDATE_COMPARTMENTS,

  REQUEST_INIT_COMPARTMENT,
  RECEIVE_INIT_COMPARTMENT,
  INVALIDATE_INIT_COMPARTMENT,


  REQUEST_COMPARTMENT,
  RECEIVE_COMPARTMENT,
  INVALIDATE_COMPARTMENT,

  REQUEST_PLANTING_CYCLE,
  RECEIVE_PLANTING_CYCLE,
  INVALIDATE_PLANTING_CYCLE,

  REQUEST_INIT_PLANTING_CYCLES,
  RECEIVE_INIT_PLANTING_CYCLES,
  INVALIDATE_INIT_PLANTING_CYCLES,

  REQUEST_PLANTING_CYCLES,
  RECEIVE_PLANTING_CYCLES,
  INVALIDATE_PLANTING_CYCLES,

  REQUEST_INIT_PLANTING_CYCLE,
  RECEIVE_INIT_PLANTING_CYCLE,
  INVALIDATE_INIT_PLANTING_CYCLE,

  REQUEST_INIT_LEFT_COMPARTMENT,
  RECEIVE_INIT_LEFT_COMPARTMENT,
  INVALIDATE_INIT_LEFT_COMPARTMENT,

  REQUEST_LEFT_COMPARTMENT,
  RECEIVE_LEFT_COMPARTMENT,
  INVALIDATE_LEFT_COMPARTMENT,

  REQUEST_RIGHT_COMPARTMENT,
  RECEIVE_RIGHT_COMPARTMENT,
  INVALIDATE_RIGHT_COMPARTMENT,

  REQUEST_INIT_LEFT_PLANTING_CYCLES,
  RECEIVE_INIT_LEFT_PLANTING_CYCLES,
  INVALIDATE_INIT_LEFT_PLANTING_CYCLES,

  REQUEST_LEFT_PLANTING_CYCLES,
  RECEIVE_LEFT_PLANTING_CYCLES,
  INVALIDATE_LEFT_PLANTING_CYCLES,

  REQUEST_RIGHT_PLANTING_CYCLES,
  RECEIVE_RIGHT_PLANTING_CYCLES,
  INVALIDATE_RIGHT_PLANTING_CYCLES,

  REQUEST_INIT_LEFT_PLANTING_CYCLE,
  RECEIVE_INIT_LEFT_PLANTING_CYCLE,
  INVALIDATE_INIT_LEFT_PLANTING_CYCLE,

  REQUEST_LEFT_PLANTING_CYCLE,
  RECEIVE_LEFT_PLANTING_CYCLE,
  INVALIDATE_LEFT_PLANTING_CYCLE,

  REQUEST_INIT_RIGHT_PLANTING_CYCLE,
  RECEIVE_INIT_RIGHT_PLANTING_CYCLE,
  INVALIDATE_INIT_RIGHT_PLANTING_CYCLE,

  REQUEST_RIGHT_PLANTING_CYCLE,
  RECEIVE_RIGHT_PLANTING_CYCLE,
  INVALIDATE_RIGHT_PLANTING_CYCLE,

  REQUEST_INIT_LEFT_COMPARTMENT_TABLES,
  RECEIVE_INIT_LEFT_COMPARTMENT_TABLES,
  INVALIDATE_INIT_LEFT_COMPARTMENT_TABLES,

  REQUEST_LEFT_COMPARTMENT_TABLES,
  RECEIVE_LEFT_COMPARTMENT_TABLES,
  INVALIDATE_LEFT_COMPARTMENT_TABLES,

  REQUEST_RIGHT_COMPARTMENT_TABLES,
  RECEIVE_RIGHT_COMPARTMENT_TABLES,
  INVALIDATE_RIGHT_COMPARTMENT_TABLES,

  REQUEST_INIT_LEFT_PLANTING_CYCLES_TABLES,
  RECEIVE_INIT_LEFT_PLANTING_CYCLES_TABLES,
  INVALIDATE_INIT_LEFT_PLANTING_CYCLES_TABLES,

  REQUEST_LEFT_PLANTING_CYCLES_TABLES,
  RECEIVE_LEFT_PLANTING_CYCLES_TABLES,
  INVALIDATE_LEFT_PLANTING_CYCLES_TABLES,

  REQUEST_RIGHT_PLANTING_CYCLES_TABLES,
  RECEIVE_RIGHT_PLANTING_CYCLES_TABLES,
  INVALIDATE_RIGHT_PLANTING_CYCLES_TABLES,

  REQUEST_INIT_LEFT_PLANTING_CYCLE_TABLES,
  RECEIVE_INIT_LEFT_PLANTING_CYCLE_TABLES,
  INVALIDATE_INIT_LEFT_PLANTING_CYCLE_TABLES,

  REQUEST_LEFT_PLANTING_CYCLE_TABLES,
  RECEIVE_LEFT_PLANTING_CYCLE_TABLES,
  INVALIDATE_LEFT_PLANTING_CYCLE_TABLES,

  REQUEST_INIT_RIGHT_PLANTING_CYCLE_TABLES,
  RECEIVE_INIT_RIGHT_PLANTING_CYCLE_TABLES,
  INVALIDATE_INIT_RIGHT_PLANTING_CYCLE_TABLES,

  REQUEST_RIGHT_PLANTING_CYCLE_TABLES,
  RECEIVE_RIGHT_PLANTING_CYCLE_TABLES,
  INVALIDATE_RIGHT_PLANTING_CYCLE_TABLES,

  UPDATE_METRIC_CATEGORIES,
  UPDATE_SHOW_ALL_METRIC_CATEGORIES,

  clearCompanyStore,

  requestVarietiesAndFruitClasses,
  receiveVarietiesAndFruitClasses,

  requestProductGroups,
  receiveProductGroups,

  requestAllLocationPlans,
  receiveAllLocationPlans,

  requestLocationPlans,
  receiveLocationPlans,
  changedLocationPlan,
  changeLocationPlan,
  receiveLocation,
  requestLocation,

  requestLocationMetrics,
  receiveLocationMetrics,

  receiveDictionaries,

  requestAllAvailableToBenchmarkProducts,
  receiveAllAvailableToBenchmarkProducts,
} from './actions';

import { receiveUpdateUserInfo } from '../user/actions';

const initialState = {
  metricCategories: getMetricCategories(),
  showAllMetricCategories: true,

  isUserFetching: false,
  didUserInvalidate: false,
  user: null,
  permissions: null,
  isPermissionsFetching: true,
  error: false,
  isUserProfileFetching: false,
  didUserProfileInvalidate: false,
  userProfile: null,
  isLocationsFetching: false,
  didLocationsInvalidate: false,
  locations: [],
  isMetricsFetching: false,
  didMetricsInvalidate: false,
  metrics: [],
  isSubNodesFetching: false,
  didSubNodesInvalidate: false,
  subNodes: [],
  isVarietiesFetching: false,
  didVarietiesInvalidate: false,
  varieties: [],
  fruitClasses: [],
  productGroups: [],
  isLocationFetching: false,
  didLocationInvalidate: false,
  location: null,
  isAllGraphMetricsFetching: false,
  didAllGraphMetricsInvalidate: false,
  allGraphMetrics: null,
  isAllCompartmentsFetching: false,
  didAllCompartmentsInvalidate: false,
  allCompartments: [],
  isAllSubNodesFetching: false,
  didAllSubNodesInvalidate: false,
  allSubNodes: [],
  isCompartmentsFetching: false,
  didCompartmentsInvalidate: false,
  compartments: null,
  isCompartmentFetching: false,
  didCompartmentInvalidate: false,
  compartment: null,
  isPlantingCycleFetching: false,
  didPlantingCycleInvalidate: false,
  plantingCycle: null,
  isAllPlantingCyclesFetching: false,
  didAllPlantingCyclesInvalidate: false,
  allPlantingCycles: [],
  isTilesFetching: false,
  didTilesInvalidate: false,
  tiles: [],
  isPlantingCyclesFetching: false,
  didPlantingCyclesInvalidate: false,
  plantingCycles: null,

  isAllGreenhouseTypesFetching: false,
  didAllGreenhouseTypesInvalidate: false,
  allGreenhouseTypes: null,
  lastAllGreenhouseTypesUpdated: null,

  // Crops
  isLeftCompartmentFetching: false,
  didLeftCompartmentInvalidate: false,
  leftCompartment: null,
  isRightCompartmentFetching: false,
  didRightCompartmentInvalidate: false,
  rightCompartment: null,
  isLeftPlantingCycleFetching: false,
  didLeftPlantingCycleInvalidate: false,
  leftPlantingCycle: null,
  isRightPlantingCycleFetching: false,
  didRightPlantingCycleInvalidate: false,
  rightPlantingCycle: null,
  isLeftPlantingCyclesFetching: false,
  didLeftPlantingCyclesInvalidate: false,
  leftPlantingCycles: null,
  isRightPlantingCyclesFetching: false,
  didRightPlantingCyclesInvalidate: false,
  rightPlantingCycles: null,

  isFetching: false,
  invalidate: false,
  isProductGroupsFetching: false,

  isAllLocationPlansFetching: false,
  lastAllLocationPlansUpdated: null,
  allLocationPlans: null,

  isLocationPlansFetching: false,
  lastLocationPlansUpdated: null,
  locationPlans: null,
  currentLocationPlan: null,

  dictionaries: null,

  benchmarkAllProducts: [],
  benchmarkAllLocations: [],
  benchmarkAllCompartments: [],
  benchmarkAllFruitClasses: [],
  benchmarkAllVarieties: [],
  isAllAvailableToBenchmarkProductsFetching: false,
};

// TODO: Разбить этот редьюсер на несколько, тут ад
export default handleActions({
  [requestAllAvailableToBenchmarkProducts](state) {
    return {
      ...state,
      isAllAvailableToBenchmarkProductsFetching: true,
    };
  },

  [receiveAllAvailableToBenchmarkProducts](state, action) {
    return {
      ...state,
      isAllAvailableToBenchmarkProductsFetching: false,

      benchmarkAllLocations: action.payload?.locations,
      benchmarkAllCompartments: action.payload?.compartments,
      benchmarkAllFruitClasses: action.payload?.fruitClasses,
      benchmarkAllVarieties: action.payload?.varieties,
      benchmarkAllProducts: action.payload?.plantingCycles,
    };
  },

  [requestLocationMetrics](state) {
    return {
      ...state,
      isMetricsFetching: true,
    };
  },
  [receiveLocationMetrics](state, action) {
    return {
      ...state,
      isMetricsFetching: false,
      metrics: action.payload?.data,
    };
  },

  [requestAllLocationPlans](state) {
    return {
      ...state,
      isAllLocationPlansFetching: true,
    };
  },
  [receiveAllLocationPlans](state, action) {
    return {
      ...state,
      isAllLocationPlansFetching: false,
      allLocationPlans: action.payload.locationPlans,
      lastAllLocationPlansUpdated: action.receivedAt
    };
  },

  [requestLocationPlans](state) {
    return {
      ...state,
      isLocationPlansFetching: true,
    };
  },

  [receiveLocationPlans](state, action) {
    const currentLocationPlan = action.payload.plans.find(plan => plan.default)?.id;
    return {
      ...state,
      isLocationPlansFetching: false,
      locationPlans: action.payload.plans,
      lastLocationPlansUpdated: action.receivedAt,
      currentLocationPlan,
    };
  },

  [changeLocationPlan](state, action) {
    return {
      ...state,
      currentLocationPlan: action.payload?.locationPlanId,
    };
  },

  [changedLocationPlan](state, action) {
    return {
      ...state,
      currentLocationPlan: action.payload?.locationPlanId,
    };
  },

  [clearCompanyStore]() {
    return {
      ...initialState
    };
  },
  [receiveUpdateUserInfo](state, action) {
    return {
      ...state,
      // TODO: в сторе идёт дублирование (userProfile и user), убрать
      userProfile: action.payload.userProfile,
      user: update(state.user, {
        attributes: {
          name: {
            $set: action.payload.userProfile.attributes.name
          }
        }
      }),
    };
  },
  [INVALIDATE_USER](state) {
    return { ...state, didUserInvalidate: true };
  },
  [REQUEST_USER](state) {
    return {
      ...state,
      isUserFetching: true,
      didUserInvalidate: false
    };
  },
  [RECEIVE_USER](state, action) {
    return {
      ...state,
      isUserFetching: false,
      didUserInvalidate: false,
      user: action.user,
      userProfile: action.user,
      error: action.error,
      lastUserUpdated: action.receivedAt
    };
  },
  [requestUserPermissions](state) {
    return { ...state, isPermissionsFetching: true, };
  },
  [receiveUserPermissions](state, action) {
    return {
      ...state,
      isPermissionsFetching: false,
      permissions: action.payload.permissions,
    };
  },
  [INVALIDATE_USER_PROFILE](state) {
    return { ...state, didUserProfileInvalidate: true };
  },
  [REQUEST_USER_PROFILE](state) {
    return {
      ...state,
      isUserProfileFetching: true,
      didUserProfileInvalidate: false
    };
  },
  [RECEIVE_USER_PROFILE](state, action) {
    return {
      ...state,
      isUserProfileFetching: false,
      didUserProfileInvalidate: false,
      userProfile: action.userProfile,
      lastUserProfileUpdated: action.receivedAt
    };
  },
  [INVALIDATE_LOCATIONS](state) {
    return { ...state, didLocationsInvalidate: true };
  },
  [requestLocations](state) {
    return {
      ...state,
      isLocationsFetching: true,
      didLocationsInvalidate: false
    };
  },
  [receiveLocations](state, action) {
    return {
      ...state,
      isLocationsFetching: false,
      didLocationsInvalidate: false,
      locations: action.payload.locations,
      lastLocationsUpdated: action.payload.receivedAt
    };
  },
  [INVALIDATE_TILES](state) {
    return { ...state, didTilesInvalidate: true };
  },
  [REQUEST_TILES](state) {
    return {
      ...state,
      isTilesFetching: true,
      didTilesInvalidate: false
    };
  },
  [RECEIVE_TILES](state, action) {
    return {
      ...state,
      isTilesFetching: false,
      didTilesInvalidate: false,
      tiles: action.tiles,
      lastTilesUpdated: action.receivedAt
    };
  },
  [INVALIDATE_VARIETIES](state) {
    return { ...state, didVarietiesInvalidate: true };
  },
  [requestVarietiesAndFruitClasses](state) {
    return {
      ...state,

      isVarietiesFetching: true,
      didVarietiesInvalidate: false
    };
  },
  [receiveVarietiesAndFruitClasses](state, action) {
    return {
      ...state,

      isVarietiesFetching: false,
      didVarietiesInvalidate: false,
      varieties: action.payload.varieties,
      fruitClasses: action.payload.fruitClasses,
      lastVarietiesUpdated: Date.now(),
    };
  },
  [requestProductGroups](state) {
    return {
      ...state,
      isProductGroupsFetching: true,
    };
  },
  [receiveProductGroups](state, action) {
    return {
      ...state,
      isProductGroupsFetching: false,
      productGroups: action.payload.productGroups,
    };
  },
  [INVALIDATE_LOCATION](state) {
    return { ...state, didLocationInvalidate: true };
  },
  [requestLocation](state) {
    return {
      ...state,
      isLocationFetching: true,
      didLocationInvalidate: false
    };
  },
  [receiveLocation](state, action) {
    return {
      ...state,
      isLocationFetching: false,
      didLocationInvalidate: false,
      location: action.payload,
      lastLocationUpdated: Date.now()
    };
  },
  [setupLocation](state, action) {
    return {
      ...state,
      location: action.payload,
      lastLocationUpdated: Date.now()
    };
  },
  [INVALIDATE_ALL_COMPARTMENTS](state) {
    return { ...state, didAllCompartmentsInvalidate: true };
  },
  [requestAllCompartments](state) {
    return {
      ...state,
      isAllCompartmentsFetching: true,
      didAllCompartmentsInvalidate: false
    };
  },
  [receiveAllCompartments](state, action) {
    return {
      ...state,
      isAllCompartmentsFetching: false,
      didAllCompartmentsInvalidate: false,
      allCompartments: action.payload.compartments,
      lastAllCompartmentsUpdated: action.payload.receivedAt
    };
  },
  [INVALIDATE_ALL_SUB_NODES](state) {
    return { ...state, didAllSubNodesInvalidate: true };
  },
  [requestAllSubNodes](state) {
    return {
      ...state,
      isAllSubNodesFetching: true,
      didAllSubNodesInvalidate: false
    };
  },
  [receiveAllSubNodes](state, action) {
    return {
      ...state,
      isAllSubNodesFetching: false,
      didAllSubNodesInvalidate: false,
      allSubNodes: action.payload.subNodes,
      lastAllSubNodesUpdated: action.payload.receivedAt
    };
  },
  [INVALIDATE_ALL_GRAPH_METRICS](state) {
    return { ...state, didAllGraphMetricsInvalidate: true };
  },
  [REQUEST_ALL_GRAPH_METRICS](state) {
    return {
      ...state,
      isAllGraphMetricsFetching: true,
      didAllGraphMetricsInvalidate: false
    };
  },
  [RECEIVE_ALL_GRAPH_METRICS](state, action) {
    return {
      ...state,
      isAllGraphMetricsFetching: false,
      didAllGraphMetricsInvalidate: false,
      allGraphMetrics: cloneDeep(get(state, 'allGraphMetrics', []) || [])
        .concat(action.allGraphMetrics.filter(graphMetric => !(state.allGraphMetrics || []).find(compareGraphMetrics(graphMetric)))),
      lastAllGraphMetricsUpdated: action.receivedAt
    };
  },
  [INVALIDATE_COMPARTMENTS](state) {
    return { ...state, didCompartmentsInvalidate: true };
  },
  [REQUEST_COMPARTMENTS](state) {
    return {
      ...state,
      isCompartmentsFetching: true,
      didCompartmentsInvalidate: false
    };
  },
  [RECEIVE_COMPARTMENTS](state, action) {
    return {
      ...state,
      isCompartmentsFetching: false,
      didCompartmentsInvalidate: false,
      compartments: state.allCompartments.filter(item => state.location && item.relationships.location && item.relationships.location.data[0].id === state.location.id),
      lastCompartmentsUpdated: action.receivedAt
    };
  },
  [INVALIDATE_INIT_COMPARTMENT](state) {
    return { ...state, didCompartmentInvalidate: true };
  },
  [REQUEST_INIT_COMPARTMENT](state) {
    return {
      ...state,
      isCompartmentFetching: true,
      didCompartmentInvalidate: false
    };
  },
  [RECEIVE_INIT_COMPARTMENT](state, action) {
    return {
      ...state,
      isCompartmentFetching: false,
      didCompartmentInvalidate: false,
      compartment: state.compartments && state.compartments[0],
      lastCompartmentUpdated: action.receivedAt
    };
  },
  [INVALIDATE_COMPARTMENT](state) {
    return { ...state, didCompartmentInvalidate: true };
  },
  [REQUEST_COMPARTMENT](state) {
    return {
      ...state,
      isCompartmentFetching: true,
      didCompartmentInvalidate: false
    };
  },
  [RECEIVE_COMPARTMENT](state, action) {
    return {
      ...state,
      isCompartmentFetching: false,
      didCompartmentInvalidate: false,
      compartment: action.compartment,
      lastCompartmentUpdated: action.receivedAt
    };
  },
  [INVALIDATE_PLANTING_CYCLE](state) {
    return { ...state, didPlantingCycleInvalidate: true };
  },
  [REQUEST_PLANTING_CYCLE](state) {
    return {
      ...state,
      isPlantingCycleFetching: true,
      didPlantingCycleInvalidate: false
    };
  },
  [RECEIVE_PLANTING_CYCLE](state, action) {
    return {
      ...state,
      isPlantingCycleFetching: false,
      didPlantingCycleInvalidate: false,
      plantingCycle: action.plantingCycle,
      lastPlantingCycleUpdated: action.receivedAt
    };
  },
  [INVALIDATE_INIT_PLANTING_CYCLE](state) {
    return { ...state, didPlantingCycleInvalidate: true };
  },
  [REQUEST_INIT_PLANTING_CYCLE](state) {
    return {
      ...state,
      isPlantingCycleFetching: true,
      didPlantingCycleInvalidate: false
    };
  },
  [RECEIVE_INIT_PLANTING_CYCLE](state, action) {
    return {
      ...state,
      isPlantingCycleFetching: false,
      didPlantingCycleInvalidate: false,
      plantingCycle: state.plantingCycles[0],
      lastPlantingCycleUpdated: action.receivedAt
    };
  },
  [INVALIDATE_ALL_PLANTING_CYCLES](state) {
    return { ...state, didAllPlantingCyclesInvalidate: true };
  },
  [requestAllPlantingCycles](state) {
    return {
      ...state,
      isAllPlantingCyclesFetching: true,
      didAllPlantingCyclesInvalidate: false
    };
  },
  [receiveAllPlantingCycles](state, action) {
    return {
      ...state,
      isAllPlantingCyclesFetching: false,
      didAllPlantingCyclesInvalidate: false,
      allPlantingCycles: action.payload.plantingCycles,
      lastAllPlantingCyclesUpdated: action.payload.receivedAt
    };
  },
  [requestAllGreenhouseTypes](state) {
    return {
      ...state,
      isAllGreenhouseTypesFetching: true,
      didAllGreenhouseTypesInvalidate: false
    };
  },
  [receiveAllGreenhouseTypes](state, action) {
    return {
      ...state,
      isAllGreenhouseTypesFetching: false,
      didAllGreenhouseTypesInvalidate: false,
      allGreenhouseTypes: action.payload.greenhouseTypes,
      lastAllGreenhouseTypesUpdated: action.payload.receivedAt
    };
  },
  [INVALIDATE_INIT_PLANTING_CYCLES](state) {
    return { ...state, didPlantingCyclesInvalidate: true };
  },
  [REQUEST_INIT_PLANTING_CYCLES](state) {
    return {
      ...state,
      isPlantingCyclesFetching: true,
      didPlantingCyclesInvalidate: false
    };
  },
  [RECEIVE_INIT_PLANTING_CYCLES](state, action) {
    return {
      ...state,
      isPlantingCyclesFetching: false,
      didPlantingCyclesInvalidate: false,
      plantingCycles: state.allPlantingCycles.filter(item => state.compartment && item.relationships.compartment && state.compartment && item.relationships.compartment.data[0].id === state.compartment.id),
      lastPlantingCyclesUpdated: action.receivedAt
    };
  },
  [INVALIDATE_PLANTING_CYCLES](state) {
    return { ...state, didPlantingCyclesInvalidate: true };
  },
  [REQUEST_PLANTING_CYCLES](state) {
    return {
      ...state,
      isPlantingCyclesFetching: true,
      didPlantingCyclesInvalidate: false
    };
  },
  [RECEIVE_PLANTING_CYCLES](state, action) {
    return {
      ...state,
      isPlantingCyclesFetching: false,
      didPlantingCyclesInvalidate: false,
      plantingCycles: state.allPlantingCycles.filter(item => state.compartment && item.relationships.compartment && state.compartment && item.relationships.compartment.data[0].id === state.compartment.id),
      lastPlantingCyclesUpdated: action.receivedAt
    };
  },
  // Crops
  [INVALIDATE_INIT_LEFT_COMPARTMENT](state) {
    return { ...state, didLeftCompartmentInvalidate: true };
  },
  [REQUEST_INIT_LEFT_COMPARTMENT](state) {
    return {
      ...state,
      isLeftCompartmentFetching: true,
      didLeftCompartmentInvalidate: false
    };
  },
  [RECEIVE_INIT_LEFT_COMPARTMENT](state, action) {
    return {
      ...state,
      isLeftCompartmentFetching: false,
      didLeftCompartmentInvalidate: false,
      leftCompartment: state.compartments && state.compartments[0],
      lastLeftCompartmentUpdated: action.receivedAt
    };
  },
  [INVALIDATE_LEFT_COMPARTMENT](state) {
    return { ...state, didLeftCompartmentInvalidate: true };
  },
  [REQUEST_LEFT_COMPARTMENT](state) {
    return {
      ...state,
      isLeftCompartmentFetching: true,
      didLeftCompartmentInvalidate: false
    };
  },
  [RECEIVE_LEFT_COMPARTMENT](state, action) {
    return {
      ...state,
      isLeftCompartmentFetching: false,
      didLeftCompartmentInvalidate: false,
      leftCompartment: action.leftCompartment,
      lastLeftCompartmentUpdated: action.receivedAt
    };
  },
  [INVALIDATE_RIGHT_COMPARTMENT](state) {
    return { ...state, didRightCompartmentInvalidate: true };
  },
  [REQUEST_RIGHT_COMPARTMENT](state) {
    return {
      ...state,
      isRightCompartmentFetching: true,
      didRightCompartmentInvalidate: false
    };
  },
  [RECEIVE_RIGHT_COMPARTMENT](state, action) {
    return {
      ...state,
      isRightCompartmentFetching: false,
      didRightCompartmentInvalidate: false,
      rightCompartment: action.rightCompartment,
      lastRightCompartmentUpdated: action.receivedAt
    };
  },
  [INVALIDATE_LEFT_PLANTING_CYCLE](state) {
    return { ...state, didLeftPlantingCycleInvalidate: true };
  },
  [REQUEST_LEFT_PLANTING_CYCLE](state) {
    return {
      ...state,
      isLeftPlantingCycleFetching: true,
      didLeftPlantingCycleInvalidate: false
    };
  },
  [RECEIVE_LEFT_PLANTING_CYCLE](state, action) {
    return {
      ...state,
      isLeftPlantingCycleFetching: false,
      didLeftPlantingCycleInvalidate: false,
      leftPlantingCycle: action.leftPlantingCycle,
      lastLeftPlantingCycleUpdated: action.receivedAt
    };
  },
  [INVALIDATE_RIGHT_PLANTING_CYCLE](state) {
    return { ...state, didRightPlantingCycleInvalidate: true };
  },
  [REQUEST_RIGHT_PLANTING_CYCLE](state) {
    return {
      ...state,
      isRightPlantingCycleFetching: true,
      didRightPlantingCycleInvalidate: false
    };
  },
  [RECEIVE_RIGHT_PLANTING_CYCLE](state, action) {
    return {
      ...state,
      isRightPlantingCycleFetching: false,
      didRightPlantingCycleInvalidate: false,
      rightPlantingCycle: action.rightPlantingCycle,
      lastRightPlantingCycleUpdated: action.receivedAt
    };
  },
  [INVALIDATE_INIT_LEFT_PLANTING_CYCLE](state) {
    return { ...state, didLeftPlantingCycleInvalidate: true };
  },
  [REQUEST_INIT_LEFT_PLANTING_CYCLE](state) {
    return {
      ...state,
      isLeftPlantingCycleFetching: true,
      didLeftPlantingCycleInvalidate: false
    };
  },
  [RECEIVE_INIT_LEFT_PLANTING_CYCLE](state, action) {
    return {
      ...state,
      isLeftPlantingCycleFetching: false,
      didLeftPlantingCycleInvalidate: false,
      leftPlantingCycle: state.leftPlantingCycles[0],
      lastLeftPlantingCycleUpdated: action.receivedAt
    };
  },
  [INVALIDATE_INIT_RIGHT_PLANTING_CYCLE](state) {
    return { ...state, didRightPlantingCycleInvalidate: true };
  },
  [REQUEST_INIT_RIGHT_PLANTING_CYCLE](state) {
    return {
      ...state,
      isRightPlantingCycleFetching: true,
      didRightPlantingCycleInvalidate: false
    };
  },
  [RECEIVE_INIT_RIGHT_PLANTING_CYCLE](state, action) {
    return {
      ...state,
      isRightPlantingCycleFetching: false,
      didRightPlantingCycleInvalidate: false,
      rightPlantingCycle: state.rightPlantingCycles[0],
      lastRightPlantingCycleUpdated: action.receivedAt
    };
  },
  [INVALIDATE_INIT_LEFT_PLANTING_CYCLES](state) {
    return { ...state, didLeftPlantingCyclesInvalidate: true };
  },
  [REQUEST_INIT_LEFT_PLANTING_CYCLES](state) {
    return {
      ...state,
      isLeftPlantingCyclesFetching: true,
      didLeftPlantingCyclesInvalidate: false
    };
  },
  [RECEIVE_INIT_LEFT_PLANTING_CYCLES](state, action) {
    return {
      ...state,
      isLeftPlantingCyclesFetching: false,
      didLeftPlantingCyclesInvalidate: false,
      leftPlantingCycles: state.allPlantingCycles.filter(item => state.leftCompartment && item.relationships.compartment && item.relationships.compartment.data[0].id === state.leftCompartment.id),
      lastLeftPlantingCyclesUpdated: action.receivedAt
    };
  },
  [INVALIDATE_LEFT_PLANTING_CYCLES](state) {
    return { ...state, didLeftPlantingCyclesInvalidate: true };
  },
  [REQUEST_LEFT_PLANTING_CYCLES](state) {
    return {
      ...state,
      isLeftPlantingCyclesFetching: true,
      didLeftPlantingCyclesInvalidate: false
    };
  },
  [RECEIVE_LEFT_PLANTING_CYCLES](state, action) {
    return {
      ...state,
      isLeftPlantingCyclesFetching: false,
      didLeftPlantingCyclesInvalidate: false,
      leftPlantingCycles: state.allPlantingCycles.filter(item => state.leftCompartment && item.relationships.compartment && item.relationships.compartment.data[0].id === state.leftCompartment.id),
      lastLeftPlantingCyclesUpdated: action.receivedAt
    };
  },
  [INVALIDATE_RIGHT_PLANTING_CYCLES](state) {
    return { ...state, didRightPlantingCyclesInvalidate: true };
  },
  [REQUEST_RIGHT_PLANTING_CYCLES](state) {
    return {
      ...state,
      isRightPlantingCyclesFetching: true,
      didRightPlantingCyclesInvalidate: false
    };
  },
  [RECEIVE_RIGHT_PLANTING_CYCLES](state, action) {
    return {
      ...state,
      isRightPlantingCyclesFetching: false,
      didRightPlantingCyclesInvalidate: false,
      rightPlantingCycles: state.allPlantingCycles.filter(item => state.rightCompartment && item.relationships.compartment && item.relationships.compartment.data[0].id === state.rightCompartment.id),
      lastRightPlantingCyclesUpdated: action.receivedAt
    };
  },
  // Tables
  [INVALIDATE_INIT_LEFT_COMPARTMENT_TABLES](state) {
    return { ...state, didLeftCompartmentTablesInvalidate: true };
  },
  [REQUEST_INIT_LEFT_COMPARTMENT_TABLES](state) {
    return {
      ...state,
      isLeftCompartmentTablesFetching: true,
      didLeftCompartmentTablesInvalidate: false
    };
  },
  [RECEIVE_INIT_LEFT_COMPARTMENT_TABLES](state, action) {
    return {
      ...state,
      isLeftCompartmentTablesFetching: false,
      didLeftCompartmentTablesInvalidate: false,
      leftCompartmentTables: state.compartments && state.compartments[0],
      lastLeftCompartmentTablesUpdated: action.receivedAt
    };
  },
  [INVALIDATE_LEFT_COMPARTMENT_TABLES](state) {
    return { ...state, didLeftCompartmentTablesInvalidate: true };
  },
  [REQUEST_LEFT_COMPARTMENT_TABLES](state) {
    return {
      ...state,
      isLeftCompartmentTablesFetching: true,
      didLeftCompartmentTablesInvalidate: false
    };
  },
  [RECEIVE_LEFT_COMPARTMENT_TABLES](state, action) {
    return {
      ...state,
      isLeftCompartmentTablesFetching: false,
      didLeftCompartmentTablesInvalidate: false,
      leftCompartmentTables: action.leftCompartmentTables,
      lastLeftCompartmentTablesUpdated: action.receivedAt
    };
  },
  [INVALIDATE_RIGHT_COMPARTMENT_TABLES](state) {
    return { ...state, didRightCompartmentTablesInvalidate: true };
  },
  [REQUEST_RIGHT_COMPARTMENT_TABLES](state) {
    return {
      ...state,
      isRightCompartmentTablesFetching: true,
      didRightCompartmentTablesInvalidate: false
    };
  },
  [RECEIVE_RIGHT_COMPARTMENT_TABLES](state, action) {
    return {
      ...state,
      isRightCompartmentTablesFetching: false,
      didRightCompartmentTablesInvalidate: false,
      rightCompartmentTables: action.rightCompartmentTables,
      lastRightCompartmentTablesUpdated: action.receivedAt
    };
  },
  [INVALIDATE_LEFT_PLANTING_CYCLE_TABLES](state) {
    return { ...state, didLeftPlantingCycleTablesInvalidate: true };
  },
  [REQUEST_LEFT_PLANTING_CYCLE_TABLES](state) {
    return {
      ...state,
      isLeftPlantingCycleTablesFetching: true,
      didLeftPlantingCycleTablesInvalidate: false
    };
  },
  [RECEIVE_LEFT_PLANTING_CYCLE_TABLES](state, action) {
    return {
      ...state,
      isLeftPlantingCycleTablesFetching: false,
      didLeftPlantingCycleTablesInvalidate: false,
      leftPlantingCycleTables: action.leftPlantingCycleTables,
      lastLeftPlantingCycleTablesUpdated: action.receivedAt
    };
  },
  [INVALIDATE_RIGHT_PLANTING_CYCLE_TABLES](state) {
    return { ...state, didRightPlantingCycleTablesInvalidate: true };
  },
  [REQUEST_RIGHT_PLANTING_CYCLE_TABLES](state) {
    return {
      ...state,
      isRightPlantingCycleTablesFetching: true,
      didRightPlantingCycleTablesInvalidate: false
    };
  },
  [RECEIVE_RIGHT_PLANTING_CYCLE_TABLES](state, action) {
    return {
      ...state,
      isRightPlantingCycleTablesFetching: false,
      didRightPlantingCycleTablesInvalidate: false,
      rightPlantingCycleTables: action.rightPlantingCycleTables,
      lastRightPlantingCycleTablesUpdated: action.receivedAt
    };
  },
  [INVALIDATE_INIT_LEFT_PLANTING_CYCLE_TABLES](state) {
    return { ...state, didLeftPlantingCycleTablesInvalidate: true };
  },
  [REQUEST_INIT_LEFT_PLANTING_CYCLE_TABLES](state) {
    return {
      ...state,
      isLeftPlantingCycleTablesFetching: true,
      didLeftPlantingCycleTablesInvalidate: false
    };
  },
  [RECEIVE_INIT_LEFT_PLANTING_CYCLE_TABLES](state, action) {
    return {
      ...state,
      isLeftPlantingCycleTablesFetching: false,
      didLeftPlantingCycleTablesInvalidate: false,
      leftPlantingCycleTables: state.leftPlantingCyclesTables[0],
      lastLeftPlantingCycleTablesUpdated: action.receivedAt
    };
  },
  [INVALIDATE_INIT_RIGHT_PLANTING_CYCLE_TABLES](state) {
    return { ...state, didRightPlantingCycleTablesInvalidate: true };
  },
  [REQUEST_INIT_RIGHT_PLANTING_CYCLE_TABLES](state) {
    return {
      ...state,
      isRightPlantingCycleTablesFetching: true,
      didRightPlantingCycleTablesInvalidate: false
    };
  },
  [RECEIVE_INIT_RIGHT_PLANTING_CYCLE_TABLES](state, action) {
    return {
      ...state,
      isRightPlantingCycleTablesFetching: false,
      didRightPlantingCycleTablesInvalidate: false,
      rightPlantingCycleTables: state.rightPlantingCyclesTables[0],
      lastRightPlantingCycleTablesUpdated: action.receivedAt
    };
  },
  [INVALIDATE_INIT_LEFT_PLANTING_CYCLES_TABLES](state) {
    return { ...state, didLeftPlantingCyclesTablesInvalidate: true };
  },
  [REQUEST_INIT_LEFT_PLANTING_CYCLES_TABLES](state) {
    return {
      ...state,
      isLeftPlantingCyclesTablesFetching: true,
      didLeftPlantingCyclesTablesInvalidate: false
    };
  },
  [RECEIVE_INIT_LEFT_PLANTING_CYCLES_TABLES](state, action) {
    return {
      ...state,
      isLeftPlantingCyclesTablesFetching: false,
      didLeftPlantingCyclesTablesInvalidate: false,
      leftPlantingCyclesTables: state.allPlantingCycles.filter(item => item.relationships.compartment && state.leftCompartmentTables && item.relationships.compartment.data[0].id === state.leftCompartmentTables.id),
      lastLeftPlantingCyclesTablesUpdated: action.receivedAt
    };
  },
  [INVALIDATE_LEFT_PLANTING_CYCLES_TABLES](state) {
    return { ...state, didLeftPlantingCyclesTablesInvalidate: true };
  },
  [REQUEST_LEFT_PLANTING_CYCLES_TABLES](state) {
    return {
      ...state,
      isLeftPlantingCyclesTablesFetching: true,
      didLeftPlantingCyclesTablesInvalidate: false
    };
  },
  [RECEIVE_LEFT_PLANTING_CYCLES_TABLES](state, action) {
    return {
      ...state,
      isLeftPlantingCyclesTablesFetching: false,
      didLeftPlantingCyclesTablesInvalidate: false,
      leftPlantingCyclesTables: state.allPlantingCycles.filter(item => item.relationships.compartment && state.leftCompartmentTables && item.relationships.compartment.data[0].id === state.leftCompartmentTables.id),
      lastLeftPlantingCyclesTablesUpdated: action.receivedAt
    };
  },
  [INVALIDATE_RIGHT_PLANTING_CYCLES_TABLES](state) {
    return { ...state, didRightPlantingCyclesTablesInvalidate: true };
  },
  [REQUEST_RIGHT_PLANTING_CYCLES_TABLES](state) {
    return {
      ...state,
      isRightPlantingCyclesTablesFetching: true,
      didRightPlantingCyclesTablesInvalidate: false
    };
  },
  [RECEIVE_RIGHT_PLANTING_CYCLES_TABLES](state, action) {
    return {
      ...state,
      isRightPlantingCyclesTablesFetching: false,
      didRightPlantingCyclesTablesInvalidate: false,
      rightPlantingCyclesTables: state.allPlantingCycles.filter(item => item.relationships.compartment && state.rightCompartmentTables && item.relationships.compartment.data[0].id === state.rightCompartmentTables.id),
      lastRightPlantingCyclesTablesUpdated: action.receivedAt
    };
  },
  [UPDATE_METRIC_CATEGORIES](state, action) {
    return { ...state, metricCategories: action.metricCategories, };
  },
  [UPDATE_SHOW_ALL_METRIC_CATEGORIES](state, action) {
    return { ...state, showAllMetricCategories: action.showAllMetricCategories, };
  },
  [receiveDictionaries](state, actions) {
    return { ...state, dictionaries: actions.payload };
  }
}, initialState);
