import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './LeafButton.module.css';


export default class LeafButton extends Component {
  static propTypes = {
    title: PropTypes.object.isRequired,
    href: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.string,
    width: PropTypes.number,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    href: null,
    onClick: null,
    className: null,
    type: 'button',
    width: 188,
  };

  handlerClick = (e) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(e);
    }
  };

  render() {
    const {
      title,
      href,
      className,
      type,
      width,
      disabled,
    } = this.props;

    const props = {
      onClick: this.handlerClick,
      style: {},
    };

    if (width) {
      props.style.width = width;
    }

    if (href) {
      return (
        <a href={href || '#'} className={classnames(styles.button, className, { [styles.disabled]: disabled })} {...props}>{title}</a>
      );
    }

    if (type === 'button') {
      return (
        <button type='button' className={classnames(styles.button, className, { [styles.disabled]: disabled })} disabled={disabled} {...props}>{title}</button>
      );
    }

    if (type === 'input') {
      return (
        <input type='submit' className={classnames(styles.button, className, { [styles.disabled]: disabled })} disabled={disabled} value={title} {...props} />
      );
    }

    return null;
  }
}
