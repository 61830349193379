import React, { memo } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './EmailField.module.css';

const EmailField = ({
  value, placeholder, onChange, className, error
}) => (
  <div>
    <input
      className={classnames(styles.email, className, { [styles.withError]: error })}
      name='email'
      type='text'
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    {error &&
      <div className={styles.emailError}>{error}</div>}
  </div>
);

EmailField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
};

EmailField.defaultProps = {
  className: null,
  error: null,
};

export default memo(EmailField);
