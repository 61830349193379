import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import classnames from 'classnames';

import BigButton from 'components/BigButton';
import ArrowBackFootedIcon from 'components/Icons/ArrowBackFootedIcon';

import styles from './DefaultBackButton.module.css';

const DefaultBackButton = ({
  className, link, text, onClick
}) => {
  if (link) {
    return (
      <RouterLink
        className={classnames(styles.backButton, className)}
        to={link}
      >
        <ArrowBackFootedIcon />
        <span>
          {text}
        </span>
      </RouterLink>
    );
  }

  return (
    <BigButton
      className={classnames(styles.backButton, styles.backButtonWithAction, className)}
      titleClassName={styles.backButtonTitle}
      icon={<ArrowBackFootedIcon />}
      title={text}
      onClick={onClick}
    />
  );
};

DefaultBackButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

DefaultBackButton.defaultProps = {
  link: null,
  className: undefined,
  onClick: () => {},
};

export default DefaultBackButton;
