import { RefObject, useEffect } from 'react';

import * as d3 from 'd3';

import multiformat from 'helpers/multiformat';


import { DateType } from 'store/graphs/types';


import { AxisScaleType } from '../components/ChartLine';

function useXAxis(
  ref: RefObject<SVGGElement>,
  xFrom: DateType,
  xTo: DateType,
  locale: string = 'en',
  graphXTicksCount: number = 6,
  xScale: AxisScaleType
) {
  useEffect(() => {
    if (ref.current) {
      const xToPlusMin = xTo.clone().add(1, 'minutes');

      const divInterval = graphXTicksCount - 1;
      const yearsDiff = xToPlusMin.diff(xFrom, 'years');
      const monthsDiff = xToPlusMin.diff(xFrom, 'months');
      const weeksDiff = xToPlusMin.diff(xFrom, 'weeks');
      const daysDiff = xToPlusMin.diff(xFrom, 'days');
      const hoursDiff = xToPlusMin.diff(xFrom, 'hours');
      const minutesDiff = xToPlusMin.diff(xFrom, 'minutes');
      const secondsDiff = xToPlusMin.diff(xFrom, 'seconds');

      let range = d3.timeDay.every(1);
      if (yearsDiff > 1) {
        range = d3.utcYear.every(Math.floor(yearsDiff / divInterval));
      } else if (monthsDiff > 1) {
        range = d3.utcMonth.every(Math.floor(monthsDiff / divInterval));
      } else if (weeksDiff > 1) {
        if (Math.floor(weeksDiff / divInterval)) {
          range = d3.utcWeek.every(Math.floor(weeksDiff / divInterval));
        } else {
          range = d3.utcDay.every(4);
        }
      } else if (daysDiff > 1) {
        range = d3.utcDay.every(Math.floor(daysDiff / divInterval));
      } else if (hoursDiff > 1) {
        range = d3.utcHour.every(Math.floor(hoursDiff / divInterval));
      } else if (minutesDiff > 1) {
        range = d3.utcMinute.every(Math.floor(minutesDiff / divInterval));
      } else if (secondsDiff > 1) {
        range = d3.utcSecond.every(Math.floor(secondsDiff / divInterval));
      }

      const axis = d3.axisBottom(xScale)
        .scale(xScale)
        .tickFormat(multiformat(locale))
        .tickPadding(13)
        .tickSizeInner(-4)
        .tickSizeOuter(0)
        .ticks(range);

      d3.select(ref.current).call(axis);
    }
  }, [ref, xScale, xFrom, xTo, locale, graphXTicksCount]);
}

export default useXAxis;
