import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { clearHarvestDataByCategories } from '../../../../store/harvest/actions';
import { getHarvestDate, getHarvestPeriodType } from '../../../../store/harvest/selectors';
import { getCurrentCycleFullInfo } from '../../../../store/newCrops/selectors';

import HarvestFormHeader from './HarvestFormHeader';

const mapDispatchToProps = {
  clearHarvestDataByCategories,
};

const mapStateToProps = (state, props) => ({
  ...props,

  date: getHarvestDate(state, props),
  periodType: getHarvestPeriodType(state, props),
  cycle: getCurrentCycleFullInfo(state),
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(HarvestFormHeader)));
