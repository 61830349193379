import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import moment from 'moment-timezone';
import classnames from 'classnames';
import { get, throttle, isNil } from 'lodash';

import PageViewTracker from 'components/PageViewTracker';

import updateLocationSearch from '../../../../helpers/updateLocationSearch';

import DefaultCircleLoader from '../../../DefaultCircleLoader';
import YearPicker from '../../../YearPicker';
import ProgressBarWithValue from '../../../ProgressBarWithValue';
import OverallQuality from '../../../OverallQuality';
import DefaultSelectWithChecks from '../../../DefaultSelectWithChecks';

import DataQualityTable from '../DataQualityTable';

import styles from './SettingsDataQuality.module.css';

export default class SettingsDataQuality extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    year: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    dataQualityReport: PropTypes.object,
    greenhousesOptions: PropTypes.array,
    filteredGreenhouses: PropTypes.array,
    dataTypeOptions: PropTypes.array,
    isDataQualityReportFetching: PropTypes.bool.isRequired,
    organizationSlug: PropTypes.string.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    varieties: PropTypes.array.isRequired,
    allPlantingCycles: PropTypes.array.isRequired,

    requestDataQualityReport: PropTypes.func.isRequired,
    updateGreenhousesOptions: PropTypes.func.isRequired,
    updateDataTypeOptions: PropTypes.func.isRequired,
    trackPageViewSettings: PropTypes.func.isRequired,
    defaultOpen: PropTypes.bool,
  };

  static defaultProps = {
    year: moment().format('YYYY'),
    dataQualityReport: null,
    filteredGreenhouses: [],
    greenhousesOptions: [],
    dataTypeOptions: [],
    defaultOpen: false,
  };

  handlerDataChange = throttle((newDataTypeOptions) => {
    const { updateDataTypeOptions } = this.props;

    updateDataTypeOptions({ dataTypeOptions: newDataTypeOptions });
  }, 100);

  handlerGreenhousesChange = throttle((newGreenhousesOptions) => {
    const { updateGreenhousesOptions } = this.props;

    updateGreenhousesOptions({ greenhousesOptions: newGreenhousesOptions });
  }, 100);

  componentDidMount() {
    const {
      intl: { formatMessage },
      year,
      updateDataTypeOptions,
      requestDataQualityReport,
    } = this.props;

    // Инициализируем данные фильтра (можно было оставить в стейте, но вынес в редьюсер для консистентновсти)
    updateDataTypeOptions({
      dataTypeOptions: [
        { label: formatMessage({ id: 'dataQuality.plans' }), value: 'plans', checked: true },
        { label: formatMessage({ id: 'dataQuality.harvest' }), value: 'harvest', checked: true },
        { label: formatMessage({ id: 'dataQuality.measurements' }), value: 'measurements', checked: true },
      ],
    });

    requestDataQualityReport({ year, detailed: true });
  }

  handlerAfterRangeSelect = ({ year }) => {
    const { requestDataQualityReport } = this.props;

    updateLocationSearch({ year });

    requestDataQualityReport({ year, detailed: true });
  };

  handlerResetFilters = () => {
    const {
      dataTypeOptions, greenhousesOptions,
      updateDataTypeOptions, updateGreenhousesOptions
    } = this.props;

    const newDataTypeOptions = dataTypeOptions.map(option => ({ ...option, checked: true }));
    const newGreenhousesOptions = greenhousesOptions.map(option => ({ ...option, checked: true }));

    updateDataTypeOptions({ dataTypeOptions: newDataTypeOptions });
    updateGreenhousesOptions({ greenhousesOptions: newGreenhousesOptions });
  }

  handlerPageView = () => {
    const { trackPageViewSettings, defaultOpen } = this.props;

    trackPageViewSettings({ settingName: 'Data Quality', defaultOpen });
  };

  render() {
    const {
      intl: { formatMessage },
      year,
      isDataQualityReportFetching,
      dataQualityReport,
      greenhousesOptions,
      dataTypeOptions,
      filteredGreenhouses,
      organizationSlug,

      fruitClasses,
      varieties,
      allPlantingCycles,
    } = this.props;

    const overallQuality = get(dataQualityReport, 'overallQuality');
    const harvestQuality = get(dataQualityReport, 'harvestQuality');
    const plansQuality = get(dataQualityReport, 'plansQuality');

    const harvestQualityPercent = isNil(harvestQuality) ? 0 : harvestQuality;
    const plansQualityPercent = isNil(plansQuality) ? 0 : plansQuality;

    return (
      <div className={styles.settingsDataQuality}>
        <PageViewTracker onMount={this.handlerPageView} />

        {isDataQualityReportFetching && <DefaultCircleLoader />}

        <div className={styles.reportHeader}>
          <h2 className={styles.header2}>
            {formatMessage({ id: 'settings.dataQuality' })}
          </h2>

          <YearPicker
            year={year}
            handlerAfterRangeSelect={this.handlerAfterRangeSelect}
            withoutSelect
          />
        </div>

        <div className={styles.overallQuality}>
          <OverallQuality percent={overallQuality} withInfoIcon />
        </div>

        <div>
          <h3 className={styles.subHeader}>
            {formatMessage({ id: 'dataQuality.dataScore' })}
          </h3>
          <div className={styles.qualityItem}>
            <div className={styles.qualityItemText}>
              {formatMessage({ id: 'dataQuality.plans' })}
            </div>
            <ProgressBarWithValue percent={plansQualityPercent} width={96} colorized />
          </div>
          <div className={styles.qualityItem}>
            <div className={styles.qualityItemText}>
              {formatMessage({ id: 'dataQuality.harvest' })}
            </div>
            <ProgressBarWithValue percent={harvestQualityPercent} width={96} colorized />
          </div>
        </div>

        <div className={styles.filtersContainer}>
          <div className={styles.filters}>
            <DefaultSelectWithChecks
              options={greenhousesOptions}
              pluralPlaceholderId='dataQuality.greenhouses'
              onChange={this.handlerGreenhousesChange}
            />
            <DefaultSelectWithChecks
              className={styles.dataFilter}
              options={dataTypeOptions}
              pluralPlaceholderId='dataQuality.dataTypes'
              onChange={this.handlerDataChange}
            />
          </div>
          <div className={styles.legend}>
            <div className={styles.legendItem}>
              <div className={classnames(styles.legendItemLine, styles.green)} />
              <div className={styles.legendItemText}>
                {formatMessage({ id: 'dataQuality.dataEntered' })}
              </div>
            </div>
            <div className={styles.legendItem}>
              <div className={classnames(styles.legendItemLine, styles.red)} />
              <div className={styles.legendItemText}>
                {formatMessage({ id: 'dataQuality.dataNotEntered' })}
              </div>
            </div>
          </div>
        </div>

        <DataQualityTable
          year={year}
          dataTypeOptions={dataTypeOptions}
          filteredGreenhouses={filteredGreenhouses}
          handlerResetFilters={this.handlerResetFilters}
          organizationSlug={organizationSlug}
          fruitClasses={fruitClasses}
          varieties={varieties}
          allPlantingCycles={allPlantingCycles}
        />
      </div>
    );
  }
}
