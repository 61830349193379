import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getFeedbackSendingError = state => get(state, 'incidents.sendingError');
export const getFeedbackSuccessfulSending = state => get(state, 'incidents.successfulSending');
export const getIsFeedbackSendingFetching = state => get(state, 'incidents.isFeedbackSendingFetching');

export const getIncidentData = state => state?.incidents.incidentData;
export const getIsIncidentFetching = state => state?.incidents.isIncidentFetching;

export const getUserFeedbackFromIncident = createSelector([
  getIncidentData,
], incidentData => get(incidentData, 'feedbacks[0]'));

export const getIncidentTypes = state => state?.incidents.incidentTypes;
export const getIsIncidentTypesFetching = state => state?.incidents.isIncidentTypesFetching;

export const getIncidentTypeCategories = state => state?.incidents.incidentTypeCategories;
export const getIsIncidentTypeCategoriesFetching = state => state?.incidents.isIncidentTypeCategoriesFetching;

export const getIncidentPlot = state => state?.incidents.incidentPlot;
export const getIsIncidentPlotFetching = state => state?.incidents.isIncidentPlotFetching;

export const getAlertRules = state => state?.incidents.alertRules;
export const getIsAlertRulesFetching = state => state?.incidents.isAlertRulesFetching;
