import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import {
  get, find, groupBy, uniqueId
} from 'lodash';
import moment from 'moment-timezone';
import classnames from 'classnames';

import ReactTooltip from 'react-tooltip';

import isTouchDevice from 'helpers/isTouchDevice';
import { getDirectFormattedPeriod } from 'helpers/defaultDates';
import updateLocationSearch from 'helpers/updateLocationSearch';

import DashboardHeader from 'components/DashboardHeader';
import PeriodPicker from 'components/PeriodPicker';
import SelectBoxFilter from 'components/SelectBoxFilter';
import DashboardTargetsFiltersMobile from 'components/DashboardTargetsFiltersMobile';
import PlanFilter from 'components/DashboardComplexFilters/components/PlanFilter';

import GroupHeader from '../../../GroupHeader';
import OverallGroup from '../OverallGroup';
import BreakdownGroup from '../BreakdownGroup';

import tooltipStyles from '../../../Tooltip/index.module.css';
import styles from './HarvestKpiDashboard.module.css';


export default class HarvestKpiDashboard extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    history: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,

    periodType: PropTypes.string,
    anyDateOfPeriod: PropTypes.string,
    breakdown: PropTypes.string,
    isRelative: PropTypes.bool,
    category: PropTypes.string,

    species: PropTypes.array.isRequired,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    allPlantingCycles: PropTypes.array.isRequired,
    speciesOverallData: PropTypes.array,
    breakdownData: PropTypes.array,
    isAllHarvestKpiFetching: PropTypes.bool.isRequired,
    isBreakdownHarvestKpiFetching: PropTypes.bool.isRequired,
    isOnlyVegetables: PropTypes.bool.isRequired,
    isOnlyLettuce: PropTypes.bool.isRequired,
    harvestFirstCategoryName: PropTypes.string,

    requestAllHarvestKpiDashboardData: PropTypes.func.isRequired,
    requestBreakdownHarvestKpiDashboardData: PropTypes.func.isRequired,
    clearHarvestKpiDashboardData: PropTypes.func.isRequired,
    getHarvestCategoriesDefault: PropTypes.func.isRequired,
    locationPlan: PropTypes.number
  };

  static defaultProps = {
    periodType: 'month',
    anyDateOfPeriod: moment(new Date()).format('YYYY-MM-DD'),
    breakdown: 'compartment',
    isRelative: false,
    category: 'all',
    harvestFirstCategoryName: 'Category A',

    speciesOverallData: null,
    breakdownData: null,
    locationPlan: null
  };

  componentDidMount() {
    const { history, getHarvestCategoriesDefault } = this.props;

    getHarvestCategoriesDefault();

    this.getAllHarvestKpiDashboardData();

    /**
     * Устанавливаем listener на переход по кнопкам браузера назад/вперёд,
     * чтобы обновлять данные в соответствии с изменившимся search запросом в урле
     *
     * TODO: Вынести в HOC
    */
    this.backListener = history.listen((location, action) => {
      if (action === 'POP') {
        // TODO: заполнять данными, проверить можно ли пропсами или надо через location.search
        this.getAllHarvestKpiDashboardData();
      }
    });
  }

  componentWillUnmount() {
    const { clearHarvestKpiDashboardData } = this.props;

    clearHarvestKpiDashboardData();

    this.backListener();
  }

  getAllHarvestKpiDashboardData = (newParams) => {
    const {
      requestAllHarvestKpiDashboardData,

      periodType,
      anyDateOfPeriod,
      breakdown,
      isRelative,
      locationPlan,
      category,
    } = this.props;

    requestAllHarvestKpiDashboardData({
      periodType,
      anyDateOfPeriod,
      breakdown,
      isRelative,
      category,
      locationPlan,
      breakdownOnly: false,

      ...newParams,
    });
  };

  getBreakdownData = (newParams) => {
    const {
      requestBreakdownHarvestKpiDashboardData,

      periodType,
      anyDateOfPeriod,
      breakdown,
      isRelative,
      category,
      locationPlan,
    } = this.props;

    requestBreakdownHarvestKpiDashboardData({
      periodType,
      anyDateOfPeriod,
      breakdown,
      isRelative,
      category,
      locationPlan,
      breakdownOnly: true,

      ...newParams,
    });
  };

  getIsCurrentPeriod = (periodType, anyDateOfPeriod) => moment(anyDateOfPeriod).isSame(moment().format('YYYY-MM-DD'), periodType);

  getHeaderTooltip = () => {
    const { intl: { formatMessage } } = this.props;

    const getColoredSpan = (colorName, value) => `<span class="${styles[colorName]}">${value}</span>`;
    const mainText = formatMessage({ id: 'kpiDashboard.mainTooltipText' });
    const red = formatMessage({ id: 'kpiDashboard.tooltipTextRed' });
    const orange = formatMessage({ id: 'kpiDashboard.tooltipTextOrange' });
    const green = formatMessage({ id: 'kpiDashboard.tooltipTextGreen' });
    const andMore = formatMessage({ id: 'kpiDashboard.andMore' });

    return (
      `<span>${mainText} ${red} — ${getColoredSpan('tooltipRed', '0-85%')}, ${orange} <br/> — ${getColoredSpan('tooltipOrange', '85–100%')}, ${green} — ${getColoredSpan('tooltipGreen', andMore)}</span>`
    );
  };

  updateHarvestKpiParameters = (parameters, breakdownOnly = false) => {
    const { history } = this.props;

    updateLocationSearch(parameters, history);

    if (breakdownOnly) {
      this.getBreakdownData(parameters);
    } else {
      this.getAllHarvestKpiDashboardData(parameters);
    }
  };

  handlerDateChange = (date) => {
    const anyDateOfPeriod = date.format('YYYY-MM-DD');

    this.updateHarvestKpiParameters({ anyDateOfPeriod });
  };

  handlerPeriodChange = periodType => this.updateHarvestKpiParameters({ periodType });

  handlerCategoryChange = (option) => {
    const category = option.value === 'all' ? 'all' : '1';

    this.updateHarvestKpiParameters({ category });
  };

  handlerPlanChange = ({ locationPlan }) => {
    this.updateHarvestKpiParameters({ locationPlan });
  };

  handlerUnitsChange = (option) => {
    const isRelative = option.value === 'kilogramPerSquareMeter' || false;

    this.updateHarvestKpiParameters({ isRelative });
  };

  handlerFiltersChange = newParams => this.updateHarvestKpiParameters(newParams);

  handlerGroupedByChange = option => this.updateHarvestKpiParameters({ breakdown: option.value }, true);

  render() {
    const {
      intl: { formatMessage, },
      species,
      varieties,
      fruitClasses,
      compartments,
      allPlantingCycles,
      organization,

      speciesOverallData,
      breakdownData,
      isAllHarvestKpiFetching,
      isBreakdownHarvestKpiFetching,
      harvestFirstCategoryName,

      breakdown,
      category,
      isRelative,
      anyDateOfPeriod,
      periodType,

      isOnlyLettuce,
    } = this.props;

    const tooltipId = `kpi-header-tooltip-${uniqueId()}`;
    const groupedBreakdownData = groupBy(breakdownData, 'species');
    const qualityOptions = [
      { label: formatMessage({ id: 'kpiDashboard.totalHarvest' }), value: 'all' },
      { label: harvestFirstCategoryName, value: '1' },
    ];
    const unitsOptions = ['kilogramPerSquareMeter', 'kilogram'].reduce((acc, unit) => {
      const { isOnlyVegetables } = this.props;
      const label = isOnlyVegetables ? formatMessage({ id: `cunits.mini.${unit}` }) : `${formatMessage({ id: `cunits.mini.${unit}` })}, ${formatMessage({ id: 'cunits.mini.count' })}`;

      return [...acc, { label, value: unit }];
    }, []);

    const breakdownOptions = [
      { label: formatMessage({ id: 'kpiDashboard.compartments' }), value: 'compartment' },
      { label: formatMessage({ id: 'kpiDashboard.varieties' }), value: 'variety' },
      { label: formatMessage({ id: 'kpiDashboard.type' }), value: 'fruitClass' },
    ];
    const currentUnits = isRelative ? 'kilogramPerSquareMeter' : 'kilogram';
    const currentQuality = category === '1' ? '1' : 'all';

    const isCurrentPeriod = this.getIsCurrentPeriod(periodType, anyDateOfPeriod);

    const periodText = getDirectFormattedPeriod(periodType, formatMessage);
    const momentAnyDateOfPeriod = moment(anyDateOfPeriod, 'YYYY-MM-DD');
    const dateText = momentAnyDateOfPeriod && periodType !== 'year' ?
      momentAnyDateOfPeriod.clone().format('MMMM YYYY') :
      momentAnyDateOfPeriod.clone().format('YYYY');
    const rangeText = `${periodText}, ${dateText}`;
    const unitsText = get(find(unitsOptions, { value: currentUnits }), 'label');
    const categoryText = get(find(qualityOptions, { value: currentQuality }), 'label');

    return (
      <div className={styles.layout}>
        <div className={styles.contentWrapper}>
          <DashboardHeader
            containerClassName={styles.kpiDashboardHeader}
            dashboardName={formatMessage({ id: 'dashboards.kpi' })}
            customDatePicker={(
              <PeriodPicker
                periodType={periodType}
                date={momentAnyDateOfPeriod}
                onPeriodChange={this.handlerPeriodChange}
                onDateChange={this.handlerDateChange}
                onMobileFiltersChange={this.handlerFiltersChange}
                withoutAddDisable
              />
            )}
            text={rangeText}
            filters={(
              <DashboardTargetsFiltersMobile
                unitsOptions={unitsOptions}
                qualityOptions={qualityOptions}
                currentUnits={currentUnits}
                currentQuality={currentQuality}
                onFiltersChange={this.handlerFiltersChange}
              />
            )}
          />

          <div className={styles.content}>
            <div className={styles.filters}>
              <div className={styles.desktopContainer}>
                <SelectBoxFilter
                  value={currentQuality}
                  options={qualityOptions}
                  onChange={this.handlerCategoryChange}
                />
                <PlanFilter
                  onFiltersChange={this.handlerPlanChange}
                />
                {!isOnlyLettuce && (
                  <SelectBoxFilter
                    value={currentUnits}
                    options={unitsOptions}
                    onChange={this.handlerUnitsChange}
                    title={formatMessage({ id: 'harvestDashboard.countIn' })}
                    classNameWrapper={styles.unitsFilter}
                    classNameButton={styles.unitsFilterButton}
                  />
                )}
              </div>

              <div className={styles.mobileContainer}>
                <div className={styles.filterItem}>{categoryText}</div>
                <div className={styles.filterItem}>{unitsText}</div>
              </div>
            </div>
            <div className={styles.groupWrapper}>
              <GroupHeader
                text={formatMessage({ id: 'kpiDashboard.overall' })}
                tooltipText={this.getHeaderTooltip()}
                tooltipId={tooltipId}
              />

              {species.map(speciesItem => (
                <OverallGroup
                  key={`overallGroup-${speciesItem}`}
                  speciesName={speciesItem}
                  overallData={find(speciesOverallData, { species: speciesItem })}
                  units={speciesItem === 'lettuce' ? 'count' : currentUnits}
                  isFetching={isAllHarvestKpiFetching}
                  organization={organization}
                  anyDateOfPeriod={anyDateOfPeriod}
                  isCurrentPeriod={isCurrentPeriod}
                  speciesCount={species.length}
                  periodType={periodType}
                />
              ))}
            </div>
            <div className={styles.groupWrapper}>
              <div className={styles.groupHeader}>
                <GroupHeader
                  text={formatMessage({ id: 'kpiDashboard.breakdown' })}
                  tooltipText={this.getHeaderTooltip()}
                  tooltipId={tooltipId}
                />

                <SelectBoxFilter
                  classNameWrapper={styles.groupFilter}
                  value={breakdown}
                  options={breakdownOptions}
                  onChange={this.handlerGroupedByChange}
                  title={formatMessage({ id: 'kpiDashboard.groupedBy' })}
                  rightPopupAlign
                />
              </div>

              {species.map(speciesItem => (
                <BreakdownGroup
                  key={`breakdownGroup-${speciesItem}`}
                  speciesName={speciesItem}
                  breakdownGroupData={get(groupedBreakdownData, speciesItem)}
                  varieties={varieties}
                  fruitClasses={fruitClasses}
                  compartments={compartments}
                  allPlantingCycles={allPlantingCycles}
                  units={speciesItem === 'lettuce' ? 'count' : currentUnits}
                  isFetching={isAllHarvestKpiFetching || isBreakdownHarvestKpiFetching}
                  isCurrentPeriod={isCurrentPeriod}
                  organization={organization}
                  speciesCount={species.length}
                />
              ))}
            </div>

            <ReactTooltip
              className={classnames(tooltipStyles.smallTooltip, styles.tooltip)}
              id={tooltipId}
              effect='solid'
              event={isTouchDevice() ? 'click' : null}
              html
            />
          </div>
        </div>
      </div>
    );
  }
}
