import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import {
  get, find, isNil, reverse, sortBy, max,
} from 'lodash';
import moment from 'moment-timezone';
import classnames from 'classnames';

import updateLocationSearch from 'helpers/updateLocationSearch';
import { getDefaultDateRange } from 'helpers/defaultDates';
// import LINE_COLORS from 'helpers/planFactColors';
import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';
import { findGetAndRoundingValue } from 'helpers/getValueDetails';
import { getPercent } from 'helpers/getPercent';
import { getMlName } from 'helpers/getVarietyName';
import { getNameByLocal } from 'helpers/getNameByLocal';
import storageWrapper from 'helpers/storageWrapper';
import { getPlantingCycleDatesById, getCompartmentNameByPlantingCycle } from 'helpers/getPlantingCycleLabel';
import sortByLocal from 'helpers/sortByLocal';
import { getRowName } from 'helpers/getRowName';

import LinesGraph from 'components/LinesGraph';
import DefaultSimpleTable from 'components/DefaultSimpleTable';
import SelectBoxFilter from 'components/SelectBoxFilter';
import DashboardHeader from 'components/DashboardHeader';
import LinesGraphTooltip from 'components/LinesGraphTooltip';
import Paper from 'components/Paper';
import DefaultConfigurableSelect from 'components/DefaultConfigurableSelect';
import Select from 'components/Select';
import Legend from 'components/Legend';
import BigButton from 'components/BigButton';
import VarietySearchSelect from 'components/VarietySearchSelect';
import CompartmentFilter from 'components/DashboardComplexFilters/components/CompartmentFilter';
// import DefaultCircleLoader from 'components/DefaultCircleLoader';

import ArrowDown from 'components/Icons/ArrowDown';
import ColumnIcon from 'components/Icons/ColumnIcon';
import ChartNotSelectedIcon from 'components/Icons/ChartNotSelectedIcon';
import ChartSelectedIcon from 'components/Icons/ChartSelectedIcon';
import DownloadIcon from 'components/Icons/DownloadIcon';

import PlanFilter from 'components/DashboardComplexFilters/components/PlanFilter';

import getDateFormat from 'helpers/getDateFormat';
import { getQueryValueAll } from 'hooks/useQuery/useQuery';
import CollapsedRow from '../CollapsedRow';
import ByDatesTable from '../ByDatesTable';
import Tooltip from '../Tooltip';
import SimpleBarChart from '../SimpleBarChart';

import styles from './PlanFactReportDashboard.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');

const DEFAULT_DATE_RANGE = getDefaultDateRange();

const DEFAULT_FIELDS = [
  {
    value: 'plan',
    label: 'planFact.plan',
    checked: true,
  },
  {
    value: 'harvested',
    label: 'planFact.harvested',
    checked: true,
  },
  {
    value: 'quality',
    label: 'planFact.quality',
    checked: true,
  },
  {
    value: 'qualityCategories',
    label: 'planFact.qualityCategories',
    checked: false,
  },
];

const PLAN_COLOR = '#00B197';
const HARVEST_COLOR = '#1DBADF';
const NON_FIRST_QUALITY = '#8C46C3';

export default class PlanFactReportDashboard extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    history: PropTypes.object.isRequired,
    planFactCategories: PropTypes.array,
    isPlanFactFetching: PropTypes.bool,
    period: PropTypes.string,
    breakdown: PropTypes.string,
    currentUnits: PropTypes.string,
    currentGraphKey: PropTypes.string,
    currentGraphKeySecond: PropTypes.string,
    currentSpecies: PropTypes.string.isRequired,
    currentFruitClass: PropTypes.string,
    currentVariety: PropTypes.string,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    allPlantingCycles: PropTypes.array.isRequired,
    productGroups: PropTypes.array.isRequired,
    organizationSlug: PropTypes.string.isRequired,
    planFactReportSorting: PropTypes.object.isRequired,
    compartmentId: PropTypes.array,

    updatePlanFactSorting: PropTypes.func.isRequired,
    requestPlanFactV2Report: PropTypes.func.isRequired,
    requestPlanFactExport: PropTypes.func.isRequired,
    v2Report: PropTypes.object,
    locationPlan: PropTypes.number,

    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
    currentGrownFilter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
  };

  static defaultProps = {
    isPlanFactFetching: false,
    planFactCategories: null,
    currentUnits: 'absolute',

    period: 'month',
    breakdown: 'fruitClass',
    currentGraphKey: 'total',
    currentGraphKeySecond: 'total',
    currentVariety: undefined,
    currentFruitClass: undefined,
    locationPlan: null,
    v2Report: null,

    compartmentId: undefined,
    anyDateOfPeriodStart: DEFAULT_DATE_RANGE.startDate,
    anyDateOfPeriodEnd: DEFAULT_DATE_RANGE.endDate,
  };

  constructor(props) {
    super(props);

    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      fields: this.getFields(),
      fieldsByPeriods: this.getFieldsByPeriods(),
    };
  }

  componentDidMount() {
    const {
      period, currentSpecies, breakdown,
      currentUnits, locationPlan,
      requestPlanFactV2Report, anyDateOfPeriodStart, anyDateOfPeriodEnd, compartmentId,
      currentVariety, currentFruitClass,
    } = this.props;

    updateLocationSearch({ anyDateOfPeriodStart, anyDateOfPeriodEnd });

    requestPlanFactV2Report({
      period,
      species: currentSpecies,
      varietyId: currentVariety,
      fruitClassCode: currentFruitClass,
      units: currentUnits,
      breakdown,
      locationPlan,
      compartmentId,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
    });
  }

  getPercentage = (plan, harvestData, category) => {
    if (category === 'total') {
      return getPercent(plan, harvestData[category]);
    }

    return getPercent(harvestData?.total, harvestData[category]);
  };

  getFields = () => {
    const savedFields = safeLocalStorage.getItem('planFactFields');

    return savedFields ? JSON.parse(savedFields) : DEFAULT_FIELDS;
  };

  getFieldsByPeriods = () => {
    const savedFields = safeLocalStorage.getItem('planFactFieldsByPeriods');

    return savedFields ? JSON.parse(savedFields) : DEFAULT_FIELDS;
  };

  getName = ({ type, id }) => {
    const {
      intl,
      varieties,
      fruitClasses,
      allPlantingCycles,
      compartments,
      productGroups,
      breakdown,
      currentVariety,
      currentSpecies,
      currentFruitClass,
      currentUnits
    } = this.props;

    return getRowName({
      ref: { type, id },
      intl,
      varieties,
      compartments,
      fruitClasses,
      productGroups,
      allPlantingCycles,
      currentBreakdown: breakdown,
      isPlantingCycleDatesAsTimestamp: false,
      currentVarietyId: currentVariety,
      currentSpecies,
      currentFruitClassCode: currentFruitClass,
      dataUnits: currentUnits
    });
  };

  getSecondLevelRowName = (row) => {
    const {
      intl: { locale, formatMessage }, varieties, breakdown, allPlantingCycles, compartments
    } = this.props;

    const {
      variety,
      plantingCycleId
    } = row;

    const varietyName = getMlName(find(varieties, { id: variety.id }), locale);

    if (!plantingCycleId) {
      return `${varietyName} (${formatMessage({ id: 'planFact.planOnly' })})`;
    }

    const plantingCycleDates = getPlantingCycleDatesById({ plantingCycleId, allPlantingCycles, formatMessage });

    switch (breakdown) {
      case 'varietyWeight': {
        const compartmentName = getCompartmentNameByPlantingCycle({ plantingCycleId, allPlantingCycles, compartments });

        return `${compartmentName}, ${plantingCycleDates}`;
      }
      case 'compartment': {
        return `${varietyName}, ${plantingCycleDates}`;
      }
      case 'fruitClass': {
        const compartmentName = getCompartmentNameByPlantingCycle({ plantingCycleId, allPlantingCycles, compartments });

        return `${varietyName}, ${plantingCycleDates}, ${compartmentName}`;
      }
      default:
        return varietyName;
    }
  }

  getReportBreakdownWithNames = reportBreakdown => reportBreakdown.map(item => ({
    ...item,

    nameText: this.getName(item.ref),
    seeds: item.seeds.map(seedsItem => ({
      ...seedsItem,

      nameText: this.getSecondLevelRowName(seedsItem),
    })),
  }));

  getGraphName = (currentNode) => {
    const {
      intl: { formatMessage, locale },
      varieties,
    } = this.props;

    if (!currentNode) {
      return formatMessage({ id: 'planFact.total' });
    }

    const { variety, ref } = currentNode;

    const varietyName = variety ? getMlName(find(varieties, { id: variety.id }), locale) : null;

    return varietyName || this.getName(ref);
  }

  getValue = ({ combinedHarvest, column }) => {
    const { currentUnits } = this.props;

    const columnId = get(column, 'value');

    let key;
    let withPercent = true;
    let value;
    let percentValue;

    if (columnId === 'plan') {
      withPercent = false;
      key = columnId;
    } else if (columnId === 'harvested') {
      key = 'total';
    } else if (columnId === 'quality') {
      key = 'secondGrade';
    } else {
      key = columnId;
    }

    const categorizedHarvestValue = combinedHarvest.find(item => get(item, 'key') === key);

    if (categorizedHarvestValue) {
      value = get(categorizedHarvestValue, 'harvest');
      percentValue = get(categorizedHarvestValue, 'percentage');
    }

    if (isNil(value)) {
      return '—';
    }

    const roundTo = currentUnits !== 'relative' ? 0 : 1;
    const roundedValue = numbersFormatting(numbersRounding(value, 'fixed', roundTo));


    if (withPercent) {
      const percentedValue = isNil(percentValue) ? 0 : numbersFormatting(numbersRounding(percentValue, 'fixed', roundTo));

      return <><span>{roundedValue}</span>&nbsp;<span className={styles.percentage}>({percentedValue}%)</span></>;
    }

    return roundedValue;
  };

  getColumnsV2 = (fields) => {
    const {
      intl: { formatMessage, locale }, planFactCategories
    } = this.props;

    return fields
      .filter(field => get(field, 'checked'))
      .map((field) => {
        const id = get(field, 'value');

        if (id === 'qualityCategories') {
          return planFactCategories && planFactCategories.length ? planFactCategories.map(category => ({
            value: get(category, 'id'),
            label: getNameByLocal(category, locale),
          })) : [];
        }

        // Не выводим secondGrade, если в локации нет категорий
        if (!planFactCategories && id === 'quality') {
          return [];
        }

        return [{ value: get(field, 'value'), label: formatMessage({ id: get(field, 'label') }) }];
      })
      .reduce((reducer, item) => [...reducer, ...item], []);
  };

  /*
  getFieldsOrder = () => {
    const { planFactCategories } = this.props;

    const order = [
      'total',
      'plan',
      'secondGrade',
    ];

    if (planFactCategories && planFactCategories.length) {
      planFactCategories.forEach((category) => {
        const categoryId = get(category, 'id');

        order.push(categoryId);
      });
    }

    return order;
  };
  */

  getSorted = (sortingType, reportBreakdown) => {
    let key;

    if (sortingType === 'type') {
      return sortByLocal(reportBreakdown, 'nameText');
    }

    if (sortingType === 'plan') {
      key = sortingType;
    } else if (sortingType === 'harvested') {
      key = 'total';
    } else if (sortingType === 'quality') {
      key = 'secondGrade';
    } else {
      key = sortingType;
    }

    return sortBy(reportBreakdown, (item) => {
      const combinedHarvest = get(item, 'combinedHarvest');
      const harvest = combinedHarvest ? combinedHarvest.find(x => get(x, 'key') === key) : null;

      return get(harvest, 'harvest');
    });
  };

  getSortedReportBreakdown = (planFactReportSorting, reportBreakdown) => {
    const sortingType = get(planFactReportSorting, 'type');
    const sortingDirection = get(planFactReportSorting, 'direction');
    const sortWithDirection = array => (sortingDirection === 'down' ? reverse(array) : array);

    const sortedFirstLevel = this.getSorted(sortingType, reportBreakdown);
    const sortedWithDirectionFirstLevel = sortWithDirection(sortedFirstLevel);

    return sortedWithDirectionFirstLevel.map(row => ({
      ...row,

      seeds: sortWithDirection(this.getSorted(sortingType, row.seeds)),
    }));
  };

  getUnitsList = (units, species, formatMessage) => units.reduce((acc, unit) => {
    const vegUnit = unit === 'relative' ? 'kilogramPerSquareMeter' : 'kilogram';
    const label = species === 'lettuce' ? formatMessage({ id: 'cunits.mini.count' }) : formatMessage({ id: `cunits.mini.${vegUnit}` });

    return [...acc, { label, value: unit }];
  }, []);

  getMaxValue = (newPoints) => {
    const lines = this.generateLines(newPoints);

    const valuesArray = lines
      .map((line) => {
        const linePoints = get(line, 'points');

        return linePoints ? linePoints.map(item => item.y) : [];
      })
      .reduce((reducer, item) => [...reducer, ...item], []);

    return max(valuesArray) || 100;
  };

  setNewSearchPath = (newParams) => {
    updateLocationSearch(newParams);
  };

  handlerDateChange = ({ period, anyDateOfPeriodStart, anyDateOfPeriodEnd }) => this.updatePlanFactParameters({
    period,
    anyDateOfPeriodStart,
    anyDateOfPeriodEnd,
    graphKey: undefined,
    graphKeySecond: undefined,
  });

  handlerBreakdownChange = breakdown => this.updatePlanFactParameters({
    breakdown: breakdown.value,
    graphKey: undefined,
    graphKeySecond: undefined,
  });

  handlerPlanChange = ({ locationPlan }) => {
    this.updatePlanFactParameters({ locationPlan });
  };

  handlerVarietyOptionSelect = ({ species, varietyId, fruitClassCode }) => {
    const params = {
      species,
      varietyId,
      fruitClassCode,
      graphKey: undefined,
      graphKeySecond: undefined,
    };

    if (species?.value === 'lettuce') {
      params.units = 'absolute';
    }

    this.updatePlanFactParameters(params);
  };

  handlerUnitsSelect = options => this.updatePlanFactParameters({ units: options.value });

  handlerGraphKeyChange = (e, graphKey) => {
    e.stopPropagation();

    updateLocationSearch({ graphKey });
  };

  handlerGraphKeySecondChange = (e, graphKeySecond) => {
    e.stopPropagation();

    updateLocationSearch({ graphKeySecond });
  };

  handlerFieldsChange = (options) => {
    safeLocalStorage.setItem('planFactFields', JSON.stringify(options));

    this.setState({ fields: options });
  };

  handlerFieldsByPeriodsChange = (options) => {
    safeLocalStorage.setItem('planFactFieldsByPeriods', JSON.stringify(options));

    this.setState({ fieldsByPeriods: options });
  };

  handlePlanFactSorting = (type) => {
    const { updatePlanFactSorting, planFactReportSorting } = this.props;

    const isOldSort = type === get(planFactReportSorting, 'type');
    const currentSortDirection = get(planFactReportSorting, 'direction');

    let sorting = {};

    if (currentSortDirection === 'up' && isOldSort) {
      sorting = { type, direction: 'down' };
    } else if (!isOldSort) {
      sorting = { type, direction: 'up' };
    } else {
      sorting = null;
    }

    updatePlanFactSorting(sorting);
  };

  handlerCompartmentsSelect = ({ compartmentId }) => this.updatePlanFactParameters({ compartmentId });

  updatePlanFactParameters = (parameters) => {
    const {
      period, currentSpecies, breakdown, currentUnits,
      history, locationPlan, requestPlanFactV2Report,
      anyDateOfPeriodStart, anyDateOfPeriodEnd, compartmentId,
      currentVariety, currentFruitClass,
    } = this.props;

    updateLocationSearch(parameters, history);

    requestPlanFactV2Report({
      period,
      species: currentSpecies,
      varietyId: currentVariety,
      fruitClassCode: currentFruitClass,
      units: currentUnits,
      breakdown,
      locationPlan,
      compartmentId,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,

      ...parameters,
    });
  };

  generateLineByPoints = (dataPoints) => {
    if (dataPoints) {
      return Object.keys(dataPoints).reduce((acc, key) => {
        const date = moment.utc(new Date((+key) * 60000));

        return [...acc, { x: date.format('MM-DD-YYYY'), y: dataPoints[key] }];
      }, []);
    }

    return [];
  };

  // TODO: вынести генерацию линий в хелпер (часто встречаемся с такой структурой данных)
  generateLines = (points) => {
    const { intl: { formatMessage, locale }, planFactCategories } = this.props;
    const { fieldsByPeriods } = this.state;

    const categoriesColors = {
      harvested: HARVEST_COLOR,
      plan: PLAN_COLOR,
      quality: NON_FIRST_QUALITY,
    };

    const isHarvestVisible = fieldsByPeriods.some(cat => cat.value === 'harvested' && cat.checked);
    return fieldsByPeriods
      .filter(field => get(field, 'checked'))
      .map((field) => {
        const id = get(field, 'value');

        if (id === 'qualityCategories') {
          const qualitiesColorScheme = this.getQualitiesColorScheme(isHarvestVisible);
          return planFactCategories && planFactCategories.length ? planFactCategories.map((category) => {
            const categoryId = get(category, 'id');
            const harvest = find(points, { key: categoryId });

            return {
              key: categoryId,
              points: this.generateLineByPoints(get(harvest, 'dataPoints')),
              name: getNameByLocal(category, locale),
              color: qualitiesColorScheme[categoryId],
              units: formatMessage({ id: `cunits.mini.${harvest?.dataUnit}` }),
            };
          }) : [];
        }

        let titleKey;

        if (id === 'harvested') {
          titleKey = 'total';
        }

        if (id === 'plan') {
          titleKey = 'plan';
        }

        if (id === 'quality') {
          titleKey = 'secondGrade';
        }

        const harvest = find(points, { key: titleKey });

        return [{
          key: titleKey,
          points: this.generateLineByPoints(get(harvest, 'dataPoints')),
          name: formatMessage({ id: `planFact.${id}` }),
          color: categoriesColors[id],
          units: formatMessage({ id: `cunits.mini.${harvest?.dataUnit}` }),
        }];
      })
      .reduce((reducer, item) => [...reducer, ...item], []);
  };

  renderValueWithPercent = (previous, actual, units) => {
    const { intl: { formatMessage } } = this.props;

    if (isNil(actual)) {
      return formatMessage({ id: 'crops.noData' });
    }

    return (
      <span>
        <span>{`${numbersFormatting(actual)} ${units}`}</span>
        <span className={styles.tooltipPercent}>{` (${getPercent(previous, actual)})`}</span>
      </span>
    );
  };

  // TODO: Вынести рендер контента тултипа в общий компонент
  renderTooltipContent = (newPoints, tooltipDate) => {
    const {
      intl: { formatMessage }, period, planFactCategories, currentUnits,
    } = this.props;

    const { fieldsByPeriods } = this.state;

    const roundTo = currentUnits !== 'relative' ? 0 : 1;

    const lines = this.generateLines(newPoints);

    const getPoints = (linesArray, lineKey) => get(find(linesArray, { key: lineKey }), 'points');
    const getName = (linesArray, lineKey) => get(find(linesArray, { key: lineKey }), 'name');
    const getColor = (linesArray, lineKey) => get(find(linesArray, { key: lineKey }), 'color');
    const getLineUnits = (linesArray, lineKey) => get(find(linesArray, { key: lineKey }), 'units');

    const plannedHarvestLine = getPoints(lines, 'plan');
    const totalHarvestLine = getPoints(lines, 'total');
    const secondGradeHarvestLine = getPoints(lines, 'secondGrade');

    const currentDate = tooltipDate ? tooltipDate.format('MM-DD-YYYY') : undefined;

    if (!currentDate) {
      return null;
    }

    const plannedHarvestValue = findGetAndRoundingValue(plannedHarvestLine, currentDate, roundTo);
    const totalHarvestValue = findGetAndRoundingValue(totalHarvestLine, currentDate, roundTo);
    const secondGradeHarvestValue = findGetAndRoundingValue(secondGradeHarvestLine, currentDate, roundTo);

    const plannedHarvestWithPercent = isNil(plannedHarvestValue) ? formatMessage({ id: 'crops.noData' }) : `${numbersFormatting(plannedHarvestValue)} ${getLineUnits(lines, 'plan')}`;
    const totalHarvestPercent = this.renderValueWithPercent(plannedHarvestValue, totalHarvestValue, getLineUnits(lines, 'total'));
    const secondGradeHarvestPercent = this.renderValueWithPercent(totalHarvestValue, secondGradeHarvestValue, getLineUnits(lines, 'secondGrade'));

    const generatedLines = fieldsByPeriods
      .filter(field => get(field, 'checked'))
      .map((field) => {
        const id = get(field, 'value');

        if (id === 'qualityCategories') {
          return planFactCategories && planFactCategories.length ? planFactCategories.map((category) => {
            const categoryId = get(category, 'id');

            const harvestLine = getPoints(lines, categoryId);
            const harvestValue = findGetAndRoundingValue(harvestLine, currentDate, roundTo);
            const harvestPercent = this.renderValueWithPercent(totalHarvestValue, harvestValue, getLineUnits(lines, categoryId));

            return {
              id: categoryId,
              header: getName(lines, categoryId),
              color: getColor(lines, categoryId),
              value: harvestPercent,
            };
          }) : [];
        }

        let value;
        let titleKey;

        if (id === 'harvested') {
          value = totalHarvestPercent;
          titleKey = 'total';
        }

        if (id === 'plan') {
          value = plannedHarvestWithPercent;
          titleKey = 'plan';
        }

        if (id === 'quality') {
          value = secondGradeHarvestPercent;
          titleKey = 'secondGrade';
        }

        return [{
          id: titleKey,
          header: getName(lines, titleKey),
          color: getColor(lines, titleKey),
          value,
        }];
      })
      .reduce((reducer, item) => [...reducer, ...item], []);

    return (
      <LinesGraphTooltip
        lines={generatedLines}
        tooltipDate={tooltipDate}
        periodType={period === 'year' ? 'week' : 'day'}
      />
    );
  };

  renderHeaderRowsV2 = () => {
    const {
      intl: { formatMessage },
      planFactReportSorting,
      breakdown
    } = this.props;

    const { fields } = this.state;

    const activeSort = get(planFactReportSorting, 'type');
    const sortDirection = get(planFactReportSorting, 'direction');

    const headers = [
      { label: formatMessage({ id: `planFact.breakdown.${breakdown}` }), value: 'type', className: 'thLeft' },

      ...this.getColumnsV2(fields),
    ];

    return (
      <tr>
        <th className={styles.chartIconWrapper} />
        {headers.map(item => (
          <th
            key={`tableHeader-${item.value}`}
            onClick={() => this.handlePlanFactSorting(item.value)}
            className={classnames(styles.activeTh, {
              [styles.activeSort]: activeSort === item.value,
              [styles[item.className]]: item.className,
            })}
          >
            <div className={styles.thContent}>
              {item.label}
              <div
                className={classnames(styles.arrow, {
                  [styles.sortDirectionDown]: sortDirection === 'down' && activeSort === item.value
                })}
              >
                <ArrowDown />
              </div>
            </div>
          </th>
        ))}
      </tr>
    );
  };

  v2ReportItemAdapter = (item) => {
    const harvestData = item?.harvest?.data || {};
    const harvestDataPlan = item?.harvest?.data?.plan;

    let productFields = {};

    const product = item?.product;

    if (product) {
      productFields = {
        ...(product?.variety && {
          variety: {
            id: product?.variety,
            type: 'Variety'
          }
        }),
        ...(product?.fruitClass && {
          fruitClass: {
            id: product?.fruitClass,
            type: 'FruitClass'
          }
        }),
        ...(product?.targetWeight && {
          targetWeight: product?.targetWeight
        }),
        ...(item?.ref?.type === 'PlantingCycle' && {
          plantingCycleId: item?.ref?.id,
        }),
        // planSectionId: null,
      };
    }

    return {
      graphKey: item?.key,
      type: item?.type,
      ref: item?.ref,
      plan: harvestDataPlan,
      combinedHarvest: Object.keys(harvestData).map(combinedHarvestItem => ({
        key: combinedHarvestItem,
        harvest: harvestData[combinedHarvestItem],
        percentage: this.getPercentage(harvestDataPlan, harvestData, combinedHarvestItem)
      })),
      ...productFields,
      seeds: item?.children?.map(childrenItem => this.v2ReportItemAdapter(childrenItem)),
    };
  };

  v2ReportDataAdapter = (report = []) => report.map(reportRow => this.v2ReportItemAdapter(reportRow));

  renderBodyRowsV2 = (reportBreakdown, total) => {
    if (!reportBreakdown || reportBreakdown.length === 0) {
      return null;
    }

    const {
      intl: { formatMessage },
      currentGraphKey,
      varieties,
      organizationSlug,
      planFactReportSorting,
    } = this.props;

    const { fields } = this.state;

    const { combinedHarvest } = total;

    const reportBreakdownWithNames = this.getReportBreakdownWithNames(reportBreakdown);
    const sortedReportBreakdown = this.getSortedReportBreakdown(planFactReportSorting, reportBreakdownWithNames);
    const columns = this.getColumnsV2(fields);
    return (
      <>
        {sortedReportBreakdown.map(tableRow => (
          <CollapsedRow
            key={`rowFragment-${tableRow.graphKey}`}
            tableRow={tableRow}
            currentGraphKey={currentGraphKey}
            onGraphKeyChange={this.handlerGraphKeyChange}
            varieties={varieties}
            columns={columns}
            organizationSlug={organizationSlug}
            getValue={this.getValue}
            getName={this.getName}
          />
        ))}
        <tr className={styles.totalRow}>
          <td
            onClick={e => this.handlerGraphKeyChange(e, 'total')}
            role='gridcell'
            tabIndex={0}
            className={styles.chartIconWrapper}
          >
            <div className={styles.chartIconContainer}>
              {currentGraphKey === 'total' ? <ChartSelectedIcon /> : <ChartNotSelectedIcon />}
            </div>
          </td>
          <td className={styles.totalCell}>
            {formatMessage({ id: 'planFact.total' })}
          </td>

          {columns.map(column => <td key={`cell-${get(column, 'value')}`} className={styles.tdValue}>{this.getValue({ combinedHarvest, column })}</td>)}
        </tr>
      </>
    );
  };

  renderTableV2= (formatMessage, report) => {
    const dataAdapterResult = this.v2ReportDataAdapter(report?.report);

    const reportBreakdown = dataAdapterResult?.filter(resultItem => (resultItem?.type === 'entity'));
    const total = find(dataAdapterResult, { graphKey: 'total' });

    const emptyText = reportBreakdown && reportBreakdown.length === 0 ? formatMessage({ id: 'harvestDashboard.emptyText' }) : null;

    return (
      <DefaultSimpleTable
        className={styles.table}
        headerRows={this.renderHeaderRowsV2(report)}
        bodyRows={this.renderBodyRowsV2(reportBreakdown, total)}
        emptyText={emptyText}
        size='small'
      />
    );
  };

  handlerAfterRangeSelect = (result) => {
    const {
      period,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
    } = this.props;

    return this.handlerDateChange({
      anyDateOfPeriodStart: result?.anyDateOfPeriodStart || anyDateOfPeriodStart,
      anyDateOfPeriodEnd: result?.anyDateOfPeriodEnd || anyDateOfPeriodEnd,
      period: result?.periodType || period,
    });
  };

  renderDefaultSelectBox = select => (
    <div key={`select-${select.name}`} className={classnames(styles.selectWrapper, select.className)}>
      <Select
        classNameButton={select.classNameButton}
        classNamePopup={select.classNamePopup}
        disabled={select.disabled}
        options={select.options}
        value={select.value}
        onChange={select.handler}
        labelPath='label'
        valuePath='value'
        theme='transparent'
        size='medium'
        closeOnChange
      />
    </div>
  );

  renderExportExelButton = () => {
    const {
      intl: { formatMessage },
      period, currentSpecies, breakdown, currentUnits, locationPlan,
      anyDateOfPeriodStart, anyDateOfPeriodEnd, compartmentId, requestPlanFactExport,
    } = this.props;

    return (
      <BigButton
        className={classnames(styles.button, styles.exelButton)}
        title={formatMessage({ id: 'planFact.exportButton' })}
        icon={<DownloadIcon />}
        onClick={() => requestPlanFactExport({
          period,
          species: currentSpecies,
          units: currentUnits,
          breakdown,
          locationPlan,
          compartmentId,
          anyDateOfPeriodStart,
          anyDateOfPeriodEnd,
        })}
      />
    );
  };

  renderCustomTooltip = ({
    className, x, y, item
  }, roundTo) => (
    <Tooltip
      x={x}
      y={y}
      tooltipData={{ ...item, name: item.periodLabel }}
      className={className}
      roundTo={roundTo}
    />
  );

  getQualitiesColorScheme = useHarvest => (useHarvest ? {
    1: '#0A94CA',
    2: '#DB973A',
    3: '#E17227',
    5: '#BE1034', // waste
    6: '#61CFE9', // vine
    7: '#4D7795',
    9: '#00B197',
    10: '#8C46C3',
    11: '#571649',
  } : {
    1: '#1DBADF',
    2: '#DB973A',
    3: '#E17227',
    5: '#BE1034', // waste
    6: '#61CFE9', // vine
    7: '#4D7795',
    9: '#00B197',
    10: '#8C46C3',
    11: '#571649',
  })

  render() {
    const {
      intl,
      intl: { formatMessage, locale },
      isPlanFactFetching,
      breakdown,
      currentUnits,
      currentSpecies,
      currentGraphKey,
      currentGraphKeySecond,
      v2Report,
      compartments,
      planFactCategories,
      period,
      currentGrownFilter,
      varieties,
      fruitClasses,
      anyDateOfPeriodEnd,
      anyDateOfPeriodStart
    } = this.props;

    const roundTo = currentUnits !== 'relative' ? 0 : 1;

    const newPlanFactReport = v2Report?.planFact;

    const { fields, fieldsByPeriods } = this.state;

    const columnsByPeriods = this.getColumnsV2(fieldsByPeriods);

    const isLettuce = currentSpecies === 'lettuce';

    const totalPoints = newPlanFactReport ? find(newPlanFactReport?.table, { key: currentGraphKeySecond }) : {};
    const totalData = totalPoints?.harvest?.data || {};

    const newPoints = Object.keys(totalData).map(dataPoint => ({
      key: dataPoint,
      dataPoints: totalData[dataPoint],
      dataUnit: totalPoints?.harvest?.dataUnit,
    }));

    const lines = this.generateLines(newPoints); // было points
    const maxValue = this.getMaxValue(newPoints);

    const breakdownOptions = [
      { label: formatMessage({ id: 'kpiDashboard.compartments' }), value: 'compartment' },
      { label: formatMessage({ id: 'kpiDashboard.varieties' }), value: 'varietyWeight' },
      { label: formatMessage({ id: 'kpiDashboard.type' }), value: 'fruitClass' },
    ];

    const units = isLettuce ? [currentUnits] : ['relative', 'absolute'];
    const unitsList = this.getUnitsList(units, currentSpecies, formatMessage);

    let reportData;

    if (currentGraphKey === 'total') {
      reportData = newPlanFactReport?.report?.filter(reportItem => (reportItem?.type === 'entity'));
    } else {
      // Чтобы переключение графиков
      const flattenReportData = newPlanFactReport?.report?.reduce((acc, dataItem) => (
        [...acc, dataItem, ...(dataItem?.children ? dataItem?.children : [])]
      ), []);

      reportData = flattenReportData
        ?.filter(reportItem => (reportItem?.type === 'entity'))
        ?.filter((filterItem) => {
          if (currentGraphKey !== 'total') {
            return filterItem.key === currentGraphKey;
          }

          return true;
        });
    }

    // const fieldsColorIndexes = this.getFieldsOrder();
    const columns = this.getColumnsV2(fields);
    const isPlanVisible = find(columns, { value: 'plan' });
    const isHarvestVisible = find(columns, { value: 'harvested' });
    const isSecondGradeVisible = find(columns, { value: 'quality' });

    const qualitiesColorScheme = this.getQualitiesColorScheme(isHarvestVisible);

    const startPeriod = moment(anyDateOfPeriodStart).startOf(period).format(getDateFormat('lll'));
    const endPeriod = moment(anyDateOfPeriodEnd).endOf(period).format(getDateFormat('lll'));

    const newProducts = !reportData || columns.length === 0 ?
      []
      :
      reportData.map((reportDataItem) => {
        const harvestData = reportDataItem?.harvest?.data;

        const harvestDataValues = harvestData ?
          Object.keys(harvestData)
            ?.filter(filterItem => filterItem !== 'total' && filterItem !== 'plan' && filterItem !== 'secondGrade') // TODO: Тут при фильтрации лучше смотреть на массив planFactCategories (и брать сюда только то, что есть там)
            ?.filter(filterItem => find(columns, { value: filterItem }))
            .map(mapItem => ({
              key: mapItem,
              value: harvestData[mapItem],
              // color: LINE_COLORS[index + 3],
              color: qualitiesColorScheme[mapItem],
              name: getNameByLocal(find(planFactCategories, { id: mapItem?.toString() }), locale),
            }))
          :
          [];

        const isAllQualitiesEnable = harvestDataValues.length > 0;

        const planBar = isPlanVisible ?
          [{
            key: 'plan',
            name: formatMessage({ id: 'planFact.plan' }),
            // color: LINE_COLORS[fieldsColorIndexes.findIndex(x => x === 'plan')],
            color: PLAN_COLOR,
            value: harvestData?.plan,
          }]
          :
          [];

        const harvestBar = isHarvestVisible ?
          [{
            key: 'harvest',
            name: formatMessage({ id: 'planFact.harvested' }),
            //  color: LINE_COLORS[fieldsColorIndexes.findIndex(x => x === 'total')],
            color: HARVEST_COLOR,
            value: harvestData?.total,
            // Фильтрация All quality
            slicedValues: isAllQualitiesEnable ? harvestDataValues : null,
          }]
          :
          [];

        const secondGradeBar = isSecondGradeVisible ?
          [{
            key: 'secondGrade',
            name: formatMessage({ id: 'planFact.quality' }),
            // color: LINE_COLORS[fieldsColorIndexes.findIndex(x => x === 'secondGrade')],
            color: NON_FIRST_QUALITY,
            value: harvestData?.secondGrade,
          }]
          :
          [];

        return {
          id: `barchart-${reportDataItem?.key}`,
          name: this.getName(reportDataItem?.ref),
          bars: [
            ...planBar,
            ...harvestBar,
            ...secondGradeBar,
          ],

          // Служебная информация для тултипа
          units: reportDataItem?.harvest?.dataUnit,
          periodLabel: `${startPeriod} – ${endPeriod}`
        };
      });

    const customRanges = [
      {
        label: formatMessage({ id: 'dashboards.daily' }),
        value: 'day',
      },
      {
        label: formatMessage({ id: 'dashboards.weekly' }),
        value: 'week',
      },
      {
        label: formatMessage({ id: 'dashboards.monthly' }),
        value: 'month',
      },
    ];

    const legend = newProducts[0]?.bars?.reduce((acc, metric) => {
      if (metric.slicedValues) {
        return [
          ...acc,

          ...metric.slicedValues.map(metricSliced => ({
            id: metricSliced?.key,
            name: metricSliced?.name,
            color: metricSliced?.color,
          }))
        ];
      }

      return [
        ...acc,

        {
          id: metric?.key,
          name: metric?.name,
          color: metric?.color,
        }
      ];
    }, []);

    const preparedDataForTable = newPlanFactReport?.table;
    const descriptorPeriods = newPlanFactReport?.descriptor?.periods;
    const isOneFieldSelected = columnsByPeriods?.length === 1;

    const compartmentId = getQueryValueAll('compartmentId');

    return (
      <div className={styles.layout}>
        <div className={styles.contentWrapper}>
          <DashboardHeader
            containerClassName={styles.planFactDashboardHeader}
            dashboardName={formatMessage({ id: 'planFact.header' })}
            defaultPeriodType='month'
            customRanges={customRanges}
            handlerAfterRangeSelect={this.handlerAfterRangeSelect}
          />

          <div className={styles.filtersWrapper}>
            <VarietySearchSelect
              onSelectOption={this.handlerVarietyOptionSelect}
              varieties={varieties}
              fruitClasses={fruitClasses}
              selectedOption={currentGrownFilter}
              classNameButton={styles.varietySelect}
            />
            <SelectBoxFilter
              value={breakdown}
              options={breakdownOptions}
              onChange={this.handlerBreakdownChange}
              title={formatMessage({ id: 'planFact.groupBy' })}
            />
            <CompartmentFilter
              intl={intl}
              compartments={compartments}
              compartmentId={compartmentId}
              disableUseQuery
              onFiltersChange={this.handlerCompartmentsSelect}
            />
            <PlanFilter
              onFiltersChange={this.handlerPlanChange}
            />
            <SelectBoxFilter
              value={currentUnits}
              options={unitsList}
              onChange={this.handlerUnitsSelect}
              title={formatMessage({ id: 'harvestDashboard.countIn' })}
              classNameButton={styles.unitsFilter}
            />
          </div>
          <div className={styles.reportWrapper}>
            <Paper className={styles.graphWrapper}>
              <div className={styles.graphHeaderWrapper}>
                <div className={styles.graphName}>
                  {formatMessage({ id: 'dataQuality.harvest' })}
                </div>
              </div>
              <div className={styles.barchart}>
                <SimpleBarChart
                  items={newProducts}
                  fixedHeight={240}
                  isFetching={isPlanFactFetching}
                  renderCustomTooltip={tooltipData => this.renderCustomTooltip(tooltipData, roundTo)}
                />
              </div>
              {legend?.length > 1 && (
                <Legend className={styles.legend} lines={legend} />
              )}
            </Paper>
            <div className={styles.actions}>
              {this.renderExportExelButton()}
              <DefaultConfigurableSelect
                className={styles.fieldsSelect}
                placeholder={(
                  <BigButton
                    className={styles.fieldsButton}
                    title={formatMessage({ id: 'planFact.fields' })}
                    icon={<ColumnIcon className={styles.fieldsIcon} />}
                  />
                )}
                options={fields}
                onChange={this.handlerFieldsChange}
              />
            </div>
            <Paper className={classnames(styles.defaultTableWrapper, styles.scrolledHoryzontal)}>
              {this.renderTableV2(formatMessage, newPlanFactReport)}
            </Paper>
            <br />
            <br />
            <Paper className={styles.graphWrapper}>
              <div className={styles.graphHeaderWrapper}>
                <div className={styles.graphName}>
                  {formatMessage({ id: 'planFact.harvestByPeriods' })}
                </div>
              </div>
              <LinesGraph
                isDataFetching={isPlanFactFetching}
                lines={lines}
                maxValue={maxValue}
                // xDomainRange={[periodStartUsa, periodEndUsa]}
                renderTooltipContent={tooltipDate => this.renderTooltipContent(newPoints, tooltipDate)}
                isEmbeddedEmptyState
                isEmpty={!isPlanFactFetching && (!lines || !lines.length || lines.every(line => !line.points || !line.points.length))}
                emptyTitle={formatMessage({ id: 'harvestDashboard.emptyText' })}
                isLegendDisabled={isOneFieldSelected}
              />
            </Paper>

            <div className={styles.actions}>
              {this.renderExportExelButton()}
              <DefaultConfigurableSelect
                className={styles.fieldsSelect}
                placeholder={(
                  <BigButton
                    className={styles.fieldsButton}
                    title={formatMessage({ id: 'planFact.fields' })}
                    icon={<ColumnIcon className={styles.fieldsIcon} />}
                  />
                )}
                options={fieldsByPeriods}
                onChange={this.handlerFieldsByPeriodsChange}
              />
            </div>
            <div className={styles.newtable}>
              <Paper className={classnames(styles.newTableWrapper, styles.scrolledHoryzontal)}>
                <ByDatesTable
                  rowsToShow={columnsByPeriods}
                  reportData={newPlanFactReport}
                  getTypeName={this.getName}
                  period={period}
                  tableData={preparedDataForTable}
                  periods={descriptorPeriods}
                  categories={newPlanFactReport?.categories}
                  graphKey={currentGraphKeySecond}
                  graphKeyChange={this.handlerGraphKeySecondChange}
                  roundTo={roundTo}
                  currentUnits={currentUnits}
                  breakdown={breakdown}
                />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
