import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, {
  useState, useRef, useEffect, useCallback
} from 'react';
import { matchPath } from 'react-router';
import { NavLink as RouterLink } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';

import {
  groupBy, find, isNull, sortBy, findIndex, isEqual, omitBy
} from 'lodash';
import moment from 'moment-timezone';

// Почему-то не работает хук из react-use, разобраться
// import { useLocalStorage } from 'react-use';
import useLocalStorageWithTimestamp from 'hooks/useLocalStorageWithTimestamp';
import getDateFormat from 'helpers/getDateFormat';
import { getTrimmedValue } from 'helpers/getTrimmedValue';
import { getNameByLocal } from 'helpers/getNameByLocal';
import storageWrapper from 'helpers/storageWrapper';

import RouteLeavingGuard from 'components/RouteLeavingGuard';
import SaveChangesDialog from 'components/SaveChangesDialog';
import CircleLoader from 'components/CircleLoader';
import loaderStyles from 'components/CircleLoader/CircleLoader.module.css';
import MeasurementsForm from '../MeasurementsForm';
import MeasurementsFormHeader from '../MeasurementsFormHeader';
import MeasurementsSaveControls from '../MeasurementsSaveControls';
import { ReactComponent as ArrowBackIcon } from './assets/arrow_back.svg';

import styles from './MeasurementsEnter.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');

const handlerActionAfter = (pageToGoBack, history, actionAfter) => {
  switch (actionAfter) {
    case 'goBack':
      history.push(pageToGoBack);
      break;
    default:
      break;
  }
};

const getExtraPlants = (plants = []) => plants.filter(plant => plant.extra);

const getConvertedData = (data = {}, rowsHeadersCount, columnsHeadersCount, metrics, initialDataArr, isExtraPlants, extraDataArray, addPlantHeaderColumnIndex, addExtraPlantHeaderColumnIndex) => {
  const convertedArray = Object.keys(data).map((key) => {
    const [rowIndex, colIndex] = key.split(',');

    return {
      rowIndex: Number(rowIndex),
      colIndex: Number(colIndex),
      value: data[key],
    };
  });

  const filteredData = convertedArray
    .filter(item => item.rowIndex > rowsHeadersCount - 1)
    .filter(item => item.colIndex > columnsHeadersCount - 1)
    .filter(item => item.colIndex !== addPlantHeaderColumnIndex)
    .filter(item => item.colIndex !== addExtraPlantHeaderColumnIndex);

  const groupedData = groupBy(filteredData, 'colIndex');

  const metricsList = initialDataArr.filter(item => item.type === 'metricCell');
  const plantsList = initialDataArr.filter(item => item.type === 'plantHeader');

  let errorsCounter = 0;

  const convertedDataArray = Object.keys(groupedData).map((key) => {
    const currentPlant = find(plantsList, { columnIndex: Number(key) });

    const observations = groupedData[key].reduce((acc, item) => {
      const currentMetric = find(metricsList, { rowIndex: item.rowIndex });
      const currentMetricId = currentMetric?.itemData?.id;

      // Не отправляем на сервер вычислимые метрики, т.к. нет прав на их перезапись
      if (currentMetric?.itemData?.calculated) {
        return acc;
      }

      const numericValue = getTrimmedValue(item.value);

      const isRangeError = !isNull(numericValue) && (!isNull(currentMetric?.itemData?.validationRangeMin) && !isNull(currentMetric?.itemData?.validationRangeMax)) && (numericValue < currentMetric?.itemData?.validationRangeMin || numericValue > currentMetric?.itemData?.validationRangeMax);

      if (isRangeError) {
        errorsCounter += 1;
      }

      return {
        ...acc,

        [currentMetricId]: numericValue
      };
    }, {});

    return {
      discriminator: currentPlant.discriminator,
      observations,
    };
  });

  // Если выключены extra метрики, то добавляем их в массив чтобы не обнулить их при запписи данных
  if (!isExtraPlants) {
    return {
      convertedData: [
        ...convertedDataArray,
        ...extraDataArray,
      ],
      errorsCounter,
    };
  }
  return { convertedData: convertedDataArray, errorsCounter };
};

const getInitialDataArray = (intl, rowsHeadersCount, columnsHeadersCount, isExtraPlants, metrics = [], plants = [], extraPlants = [], dataArray = [], extraDataArray = []) => {
  const { formatMessage, locale } = intl;

  const metricHeader = [{
    type: 'metricHeader',
    rowIndex: rowsHeadersCount - 1,
    columnIndex: columnsHeadersCount - 1,
    isEditable: false,
    isSelectable: false,
    value: formatMessage({ id: 'crops.metrics' }),
    itemData: null,
  }];

  const metricsList = metrics.map((metricItem, index) => ({
    type: 'metricCell',
    rowIndex: index + rowsHeadersCount,
    columnIndex: columnsHeadersCount - 1,
    isEditable: false,
    isSelectable: false,
    value: `${getNameByLocal(metricItem, locale)}, ${metricItem?.cunit ? formatMessage({ id: `cunits.mini.${metricItem?.cunit}` }) : ''}`,
    itemData: metricItem,
  }));

  const getPlantsList = plantsArray => plantsArray.map((plantsItem, index) => {
    const currentColumnIndex = plantsItem.extra ? index + 2 : index + 1;

    return {
      type: 'plantHeader',
      rowIndex: rowsHeadersCount - 1,
      columnIndex: currentColumnIndex + (columnsHeadersCount - 1),
      isEditable: false,
      isSelectable: false,
      value: plantsItem.name,
      itemData: plantsItem,
      discriminator: plantsItem.discriminator,
    };
  });

  const allPlants = isExtraPlants ? [...plants, ...extraPlants] : plants;
  const plantsList = getPlantsList(allPlants);

  const getDataList = (data, plantsData) => data.reduce((acc, dataItem) => {
    if (data.length === 0 || plantsData.length === 0) {
      return [];
    }

    const observations = dataItem?.observations || {};

    const observationsList = Object.keys(observations).map((key) => {
      const metricIndex = findIndex(metrics, { id: Number(key) });

      const currentPlant = find(plantsData, { discriminator: dataItem.discriminator });
      const { columnIndex } = currentPlant;

      return {
        [[metricIndex + 1, dataItem.discriminator]]: observations[key],

        type: 'dataCell',
        rowIndex: metricIndex + rowsHeadersCount,
        columnIndex,
        isEditable: true,
        isSelectable: true,
        value: observations[key],
        itemData: null,
      };
    });

    return [
      ...acc,
      ...observationsList,
    ];
  }, []);

  const allData = isExtraPlants ? [...dataArray, ...extraDataArray] : dataArray;
  const dataList = getDataList(allData, plantsList);

  const addPlantHeader = [{
    type: 'addPlantHeader',
    rowIndex: rowsHeadersCount - 1,
    columnIndex: plants.length + columnsHeadersCount,
    isEditable: false,
    isSelectable: false,
    value: '+',
    itemData: null,
  }];

  const addExtraPlantHeader = [{
    type: 'addExtraPlantHeader',
    rowIndex: rowsHeadersCount - 1,
    columnIndex: plants.length + extraPlants.length + columnsHeadersCount + 1,
    isEditable: false,
    isSelectable: false,
    value: '+',
    itemData: null,
  }];

  const extraMainHeader = [...metricHeader, ...plants, ...addPlantHeader].map((listItem, index) => ({
    type: 'mainHeader',
    rowIndex: 0,
    columnIndex: index,
    isEditable: false,
    isSelectable: false,
    value: index === 0 ? formatMessage({ id: 'crops.metrics' }) : formatMessage({ id: 'measurements.main' }),
    itemData: null,
  }));

  const extraExtraHeader = [...extraPlants, ...addExtraPlantHeader].map((listItem, index) => ({
    type: 'extraHeader',
    rowIndex: 0,
    columnIndex: extraMainHeader.length + index,
    isEditable: false,
    isSelectable: false,
    value: formatMessage({ id: 'measurements.extra' }),
    itemData: null,
  }));

  const extra = isExtraPlants ? [
    ...extraMainHeader,
    ...extraExtraHeader,
    ...addExtraPlantHeader,
  ]
    :
    [];

  return [
    ...metricHeader,
    ...metricsList,
    ...plantsList,
    ...dataList,
    ...addPlantHeader,
    ...extra,
  ];
};

const getInitialDataObjects = (initialDataArr = []) => initialDataArr.reduce((acc, item) => ({
  ...acc,
  [[item.rowIndex, item.columnIndex]]: item.value,
}), {});

const formatDataToTable = (intl, rowsHeadersCount, columnsHeadersCount, isExtraPlants, sortedMetrics, plants, extraPlants, dataArray, extraDataArray) => {
  const initialDataArr = getInitialDataArray(intl, rowsHeadersCount, columnsHeadersCount, isExtraPlants, sortedMetrics, plants, extraPlants, dataArray, extraDataArray);
  const initialData = getInitialDataObjects(initialDataArr);

  return {
    initialDataArr,
    initialData,
  };
};

const getTableData = (intl, measurementsByDate, isExtraPlants) => {
  const initialMetrics = measurementsByDate?.metrics || [];
  const sortedMetrics = sortBy(initialMetrics || [], 'calculated'); // Вычислимые метрики пуляем в конец массива, чтобы отображались в самом низу

  const plants = measurementsByDate?.plants?.filter(plant => !plant.extra) || [];
  const extraPlants = getExtraPlants(measurementsByDate?.plants);

  // У extra plants discriminator идёт меньше 0, хотя не уверен что стоит на это завязываться
  const dataArray = measurementsByDate?.data?.filter(item => Number(item.discriminator) > 0) || [];
  const extraDataArray = measurementsByDate?.data?.filter(item => Number(item.discriminator) < 0) || [];
  const rowsHeadersCount = isExtraPlants ? 2 : 1;
  const columnsHeadersCount = 1;

  // const initialDataArr = getInitialDataArray(intl, isExtraPlants, sortedMetrics, plants, extraPlants, dataArray, extraDataArray);
  // const initialData = getInitialDataObjects(initialDataArr);

  // TODO: переделать инит данных, тут наспех сделал, очень неаккуратно и многословно
  const { initialData, initialDataArr } = formatDataToTable(intl, rowsHeadersCount, columnsHeadersCount, isExtraPlants, sortedMetrics, plants, extraPlants, dataArray, extraDataArray);

  // const rowCount = sortedMetrics?.length + HEADER_ROWS_LENGTH;
  // const columnCount = plants?.length + HEADER_COLUMNS_LENGTH + ADD_COLUMNS_LENGTH;
  const rowCount = initialDataArr.filter(dataItem => dataItem.columnIndex === 0).length;
  const columnCount = initialDataArr.filter(dataItem => dataItem.rowIndex === 0).length;

  // Нужно чтобы убирать из результатов (и при копипасте) данные фейковых столбцов (с + на добавление колонки)
  const addPlantHeaderColumnIndex = find(initialDataArr, { type: 'addPlantHeader' })?.columnIndex;
  const addExtraPlantHeaderColumnIndex = find(initialDataArr, { type: 'addExtraPlantHeader' })?.columnIndex;

  return {
    addExtraPlantHeaderColumnIndex,
    addPlantHeaderColumnIndex,
    columnCount,
    rowCount,
    plants,
    rowsHeadersCount,
    columnsHeadersCount,
    sortedMetrics,
    dataArray,
    initialData,
    initialDataArr,
    extraDataArray,
    extraPlants,
  };
};

const handlerSubmit = ({
  startDate,
  endDate,
  isInputDisabled,
  data,
  history,
  organization,
  date,
  requestSaveMeasurementsByDate,
  cycleId,
  showNotificationWithTimeout,
  withoutRedirect,
  clearDataAfterSave,
}) => {
  const { attributes: { slug } } = organization;

  const actionAfterSuccess = withoutRedirect ? clearDataAfterSave : () => {
    clearDataAfterSave();
    history.push(`/${slug}/crops/${cycleId}/measurements`);
  };


  const formattedStartDate = moment(startDate).format(getDateFormat('LLL'));
  const formattedEndDate = endDate ? moment(endDate).format(getDateFormat('LLL')) : null;

  if (isInputDisabled) {
    return showNotificationWithTimeout({
      id: 'notifications.updatePlantingCycleErrorOutside',
      messageId: 'notifications.updatePlantingCycleErrorOutsideEndDate',
      messageParams: {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
      position: 'leftDown',
      iconType: 'error',
      notificationType: 'withFixedWidth',
      withLineBreaks: true,
    });
  }

  return requestSaveMeasurementsByDate({
    plantingCycleId: cycleId,
    date,
    data,
    actionAfterSuccess,
  });
};

const onCancel = (history, pageToGoBack, changesTime, setIsSaveChangesDialogVisible, setActionAfter) => {
  if (changesTime) {
    setIsSaveChangesDialogVisible(true);
    setActionAfter('goBack');
  } else {
    history.push(pageToGoBack);
  }
};

const onSubmit = ({
  childRef,
  showNotificationWithTimeout,
  data,
  rowsHeadersCount,
  columnsHeadersCount,
  sortedMetrics,
  initialDataArr,
  isExtraPlants,
  extraDataArray,
  addPlantHeaderColumnIndex,
  addExtraPlantHeaderColumnIndex,
  startDate,
  endDate,
  isInputDisabled,
  history,
  organization,
  date,
  requestSaveMeasurementsByDate,
  cycleId,
  withoutRedirect,
  clearDataAfterSave,
}) => {
  // Скрываем инпут редактирования ячейки
  if (childRef?.current) {
    childRef.current.hideCellEditor();
  }

  const { convertedData, errorsCounter } = getConvertedData(
    data,
    rowsHeadersCount,
    columnsHeadersCount,
    sortedMetrics,
    initialDataArr,
    isExtraPlants,
    extraDataArray,
    addPlantHeaderColumnIndex,
    addExtraPlantHeaderColumnIndex
  );

  if (errorsCounter > 0) {
    return showNotificationWithTimeout({
      id: 'measurements.validationError',
      messageId: 'notifications.saveMeasurementsError',
      position: 'leftDown',
      notificationType: 'withActionWide',
    });
  }

  return handlerSubmit({
    startDate,
    endDate,
    isInputDisabled,
    data: convertedData,
    history,
    organization,
    date,
    requestSaveMeasurementsByDate,
    cycleId,
    showNotificationWithTimeout,
    withoutRedirect,
    clearDataAfterSave,
  });
};

const shouldBlockNavigation = (history, nextLocation) => {
  const prevLocation = history?.location;

  // Не блокируем переход, когда изменяем параметры урла (например дату) через history.push
  if ((prevLocation?.pathname === nextLocation?.pathname) && (prevLocation?.search !== nextLocation?.search)) {
    return false;
  }

  const prevLocationMatch = matchPath(prevLocation?.pathname, {
    path: '/:organizationSlug/crops/:cycleId/measurements/edit',
  });

  const nextLocationMatch = matchPath(nextLocation?.pathname, {
    path: '/:organizationSlug/crops/:cycleId/measurements/edit',
  });

  /**
   * Не блокируем переход, когда изменяем cycleId через history.push,
   * т.к. там надо менеджерить вручную очистку стейта и перезапрос новых параметров через useEffect
   */
  if (prevLocationMatch?.isExact && nextLocationMatch?.isExact && prevLocationMatch?.params?.cycleId !== nextLocationMatch?.params?.cycleId) {
    return false;
  }

  return true;
};

const MeasurementsEnter = ({
  history,
  cycleId,
  measurementsByDate,
  isFetching,
  date,
  intl,
  organization,
  allCompartments,
  showNotificationWithTimeout,
  requestSaveMeasurementsByDate,
  setHasChangesTime,
  changesTime,
  clearMeasurementsByDate,
}) => {
  const childRef = useRef();

  const isDataChanged = !!changesTime;

  const isExtraPlantsFromStorage = safeLocalStorage.getItem('isExtraPlants');
  const isTrueSet = (isExtraPlantsFromStorage === 'true');
  const valueFromStorage = JSON.parse(isTrueSet);

  const [isExtraPlants, setExtraPlants] = useState(valueFromStorage);

  const [isSaveChangesDialogVisible, setIsSaveChangesDialogVisible] = useState(false);
  const [actionAfter, setActionAfter] = useState(undefined);

  const momentDate = moment(date, 'YYYY-MM-DD');

  const compartments = allCompartments.filter(item => organization && item.relationships.organization && item.relationships.organization.data[0].id === organization.id);

  const { formatMessage } = intl;
  const { attributes: { slug } } = organization;

  const pageToGoBack = `/${slug}/crops/${cycleId}/measurements`;

  const previousDate = measurementsByDate?.previous;
  const nextDate = measurementsByDate?.next;

  const startDate = measurementsByDate?.startDate;
  const endDate = measurementsByDate?.endDate;

  const isInputDisabled = (endDate && momentDate > moment(endDate, 'YYYY-MM-DD')) || (startDate && momentDate < moment(startDate, 'YYYY-MM-DD'));

  const {
    addExtraPlantHeaderColumnIndex,
    addPlantHeaderColumnIndex,
    columnCount,
    rowCount,
    plants,
    rowsHeadersCount,
    columnsHeadersCount,
    sortedMetrics,
    dataArray,
    initialData,
    initialDataArr,
    extraDataArray,
    extraPlants,
  } = getTableData(intl, measurementsByDate, isExtraPlants, setExtraPlants);

  const dataId = `measurementsTable-v0-${cycleId}-${measurementsByDate?.date}`;
  const [data, setData, clearData, dataTimestamp] = useLocalStorageWithTimestamp(dataId, initialData);

  const clearDataAfterSave = useCallback(() => {
    clearData();
    setHasChangesTime(null);
  }, [clearData, setHasChangesTime]);

  useEffect(() => {
    /**
     * Нужно удалять пустые ячейки, т.к. если ввести в таблицу значение, а потом
     * удалить его на backspace, то в массиве останется элемент с пустым значением ''
     * и технически новый массив данных будет отличаться от старого, хотя фактически
     * данные там будут такие же
     */
    const initialDataWithoutNull = omitBy(initialData, value => value === '');
    const dataWithoutNull = omitBy(data, value => value === '');

    if (isEqual(initialDataWithoutNull, dataWithoutNull)) {
      setHasChangesTime(null);
    } else {
      // safeLocalStorage.setItem(forecastIdHash, JSON.stringify(data));
      setHasChangesTime(dataTimestamp);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const tableProps = {
    childRef,
    rowCount,
    columnCount,
    isExtraPlants,
    plants,
    extraPlants,
    rowsHeadersCount,
    columnsHeadersCount,
    setExtraPlants,
    setData,
    data,
    sortedMetrics,
    dataArray,
    extraDataArray,
    initialData,
    initialDataArr,
    addPlantHeaderColumnIndex,
  };

  const handlerSave = useCallback(withoutRedirect => onSubmit({
    childRef,
    showNotificationWithTimeout,
    data,
    rowsHeadersCount,
    columnsHeadersCount,
    sortedMetrics,
    initialDataArr,
    isExtraPlants,
    extraDataArray,
    addPlantHeaderColumnIndex,
    addExtraPlantHeaderColumnIndex,
    startDate,
    endDate,
    isInputDisabled,
    history,
    organization,
    date,
    requestSaveMeasurementsByDate,
    cycleId,
    clearDataAfterSave,
    withoutRedirect,
  }), [
    childRef,
    showNotificationWithTimeout,
    data,
    rowsHeadersCount,
    columnsHeadersCount,
    sortedMetrics,
    initialDataArr,
    isExtraPlants,
    extraDataArray,
    addPlantHeaderColumnIndex,
    addExtraPlantHeaderColumnIndex,
    startDate,
    endDate,
    isInputDisabled,
    history,
    organization,
    date,
    requestSaveMeasurementsByDate,
    cycleId,
    clearDataAfterSave,
  ]);

  const handlerDontSave = useCallback(() => {
    /**
     * Тут используем setData вместе с clearData, чтобы обнулить внутренний стейт таблички
     * и заодно стригеррить действие изменения массива data, чтобы сработал useEffect
     */
    setData(initialData);
    clearData();

    handlerActionAfter(pageToGoBack, history, actionAfter);

    setIsSaveChangesDialogVisible(false);
  }, [setData, clearData, initialData, pageToGoBack, history, actionAfter, setIsSaveChangesDialogVisible]);

  return (
    <div
      className={classnames(styles.body)}
    >
      <div className={styles.controls}>
        <RouterLink
          to={pageToGoBack}
          className={classnames(styles.routeControl)}
          activeClassName={styles.routeControlActive}
          tabIndex={0}
          role='button'
        >
          <span className={styles.routeControlIcon}>
            <ArrowBackIcon />
          </span>
          <span className={styles.routeControlText}>
            {formatMessage({ id: 'plantingCycles.plantingCycle' })}
          </span>
        </RouterLink>

        <MeasurementsSaveControls
          onSubmit={() => handlerSave(false)}
          onSubmitHasChanges={() => handlerSave(true)}
          onCancel={() => onCancel(history, pageToGoBack, changesTime, setIsSaveChangesDialogVisible, setActionAfter)}
        />
      </div>
      <MeasurementsFormHeader
        mode='update'
        organization={organization}
        plantingCycleId={cycleId}
        date={momentDate}
        nextDate={nextDate}
        previousDate={previousDate}
        minDate={startDate}
        maxDate={endDate}
        isPreventDateChange={isDataChanged}
        handlerDontSave={handlerDontSave}
        handlerSave={() => handlerSave(true)}
        clearData={() => {
          clearMeasurementsByDate();
          clearDataAfterSave();
        }}
      />
      {!isFetching && measurementsByDate && (
        <MeasurementsForm
          tableProps={tableProps}
          plantingCycleId={cycleId}
          measurementsByDate={measurementsByDate}
          date={date}
          compartments={compartments}
          // handleSubmit={submitData => handlerSubmit(startDate, endDate, isInputDisabled, submitData)}
          mode='update'
          isLoading={isFetching}
          pageToGoBack={pageToGoBack}
          setIsSaveChangesDialogVisible={setIsSaveChangesDialogVisible}
          changesTime={changesTime}
          clearData={clearData}
        />
      )}
      {isFetching && (
        <div className={styles.loaderWrapper}>
          <CircleLoader
            className={classnames(loaderStyles.circleLoader, styles.loader)}
            iconClassName={loaderStyles.circleLoaderIcon}
          />
        </div>
      )}

      {isSaveChangesDialogVisible && (
        <SaveChangesDialog
          handlerCloseDialog={() => setIsSaveChangesDialogVisible(false)}
          handlerDontSave={handlerDontSave}
          handlerSave={() => handlerSave(false)}
        />
      )}

      <RouteLeavingGuard
        when={isDataChanged && !isSaveChangesDialogVisible}
        navigate={path => history.push(path)}
        onCancel={handlerDontSave}
        onConfirm={() => handlerSave(false)}
        shouldBlockNavigation={nextLocation => shouldBlockNavigation(history, nextLocation)}
        navigateOnCancel
      />
    </div>
  );
};

MeasurementsEnter.propTypes = {
  intl: intlShape.isRequired,
  organization: PropTypes.object,
  date: PropTypes.string,
  measurementsByDate: PropTypes.object,
  requestSaveMeasurementsByDate: PropTypes.func.isRequired,
  clearMeasurementsByDate: PropTypes.func.isRequired,

  cycleId: PropTypes.string,
  allCompartments: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  showNotificationWithTimeout: PropTypes.func.isRequired,
  setHasChangesTime: PropTypes.func.isRequired,
  changesTime: PropTypes.number,
};

MeasurementsEnter.defaultProps = {
  measurementsByDate: null,

  cycleId: null,
  allCompartments: [],
  organization: null,
  date: moment().format('YYYY-MM-DD'),
  changesTime: undefined,
};

export default injectIntl(MeasurementsEnter);
