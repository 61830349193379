import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import * as d3 from 'd3';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { throttle } from 'lodash';

import styles from './XAxis.module.css';

export default class XAxis extends Component {
  static propTypes = {
    scale: PropTypes.func.isRequired,
    height: PropTypes.number.isRequired,
    margins: PropTypes.object.isRequired,
    // ticksCount: PropTypes.number.isRequired,
    tickDateFormat: PropTypes.string,
    // ticks: PropTypes.array,
    // Для скрытия первого тика, если он пересекается с осью Y
    isFirstTickHidden: PropTypes.bool,
  };

  static defaultProps = {
    isFirstTickHidden: false,
    // ticks: null,
    tickDateFormat: 'MMM YYYY',
  };

  handlerResize = throttle(() => {
    this.rebuildAxis();
  }, 100);

  componentDidMount() {
    window.addEventListener('resize', this.handlerResize);

    this.rebuildAxis();
  }

  componentDidUpdate() {
    this.rebuildAxis();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlerResize);
  }

  getTicks = (ticksCount) => {
    if (ticksCount <= 14) {
      return d3.utcMonth.every(1);
    }
    if (ticksCount <= 28) {
      return d3.utcMonth.every(2);
    }
    if (ticksCount > 28) {
      return d3.utcMonth.every(Math.round(ticksCount / 14));
    }
    return d3.utcMonth.every(2);
  };

  rebuildAxis = () => setTimeout(this.renderAxis, 0); // Баг d3

  renderAxis = () => {
    const {
      scale, height, tickDateFormat
    } = this.props;

    const monthsTicks = 13; // this.getTicks(ticksCount);

    const xAxisScale = d3.axisBottom()
      .scale(scale)
      .ticks(monthsTicks)
      .tickFormat((date, index) => (tickDateFormat ? moment(date).format(tickDateFormat) : index + 1))
      .tickPadding(12)
      .tickSizeInner(-height)
      .tickSizeOuter(0);

    d3.select(this.axisElement)
      .call(xAxisScale);
  }

  render() {
    const { height, margins, isFirstTickHidden } = this.props;

    return (
      <g
        className={classnames(styles.axisX, { [styles.firstTickHidden]: isFirstTickHidden })}
        ref={(el) => { this.axisElement = el; }}
        transform={`translate(0,${height - margins.top - margins.bottom})`}
      />
    );
  }
}
