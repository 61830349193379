import React, { memo, useState, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import {
  findIndex, find,
} from 'lodash';

import { getNameByLocal } from 'helpers/getNameByLocal';
import storageWrapper from 'helpers/storageWrapper';

import MeasurementsEnterTable from 'components/MeasurementsEnterTable';
import BigButton from 'components/BigButton';
import DropdownMenu from 'components/DropdownMenu';
import BigSwitch from 'components/BigSwitch';
import CustomizeIcon from 'components/Icons/CustomizeIcon';

import styles from './MeasurementsForm.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');

const getInitialDataArray = (intl, rowsHeadersCount, columnsHeadersCount, isExtraPlants, metrics = [], plants = [], extraPlants = [], dataArray = [], extraDataArray = []) => {
  const { formatMessage, locale } = intl;

  const metricHeader = [{
    type: 'metricHeader',
    rowIndex: rowsHeadersCount - 1,
    columnIndex: columnsHeadersCount - 1,
    isEditable: false,
    isSelectable: false,
    value: formatMessage({ id: 'crops.metrics' }),
    itemData: null,
  }];

  const metricsList = metrics.map((metricItem, index) => ({
    type: 'metricCell',
    rowIndex: index + rowsHeadersCount,
    columnIndex: columnsHeadersCount - 1,
    isEditable: false,
    isSelectable: false,
    value: `${getNameByLocal(metricItem, locale)}, ${metricItem?.cunit ? formatMessage({ id: `cunits.mini.${metricItem?.cunit}` }) : ''}`,
    itemData: metricItem,
  }));

  const getPlantsList = plantsArray => plantsArray.map((plantsItem, index) => {
    const currentColumnIndex = plantsItem.extra ? index + 2 : index + 1;

    return {
      type: 'plantHeader',
      rowIndex: rowsHeadersCount - 1,
      columnIndex: currentColumnIndex + (columnsHeadersCount - 1),
      isEditable: false,
      isSelectable: false,
      value: plantsItem.name,
      itemData: plantsItem,
      discriminator: plantsItem.discriminator,
    };
  });

  const allPlants = isExtraPlants ? [...plants, ...extraPlants] : plants;
  const plantsList = getPlantsList(allPlants);

  const getDataList = (data, plantsData) => data.reduce((acc, dataItem) => {
    if (data.length === 0 || plantsData.length === 0) {
      return [];
    }

    const observations = dataItem?.observations || {};

    const observationsList = Object.keys(observations).map((key) => {
      const metricIndex = findIndex(metrics, { id: Number(key) });

      const currentPlant = find(plantsData, { discriminator: dataItem.discriminator });
      const { columnIndex } = currentPlant;

      return {
        [[metricIndex + 1, dataItem.discriminator]]: observations[key],

        type: 'dataCell',
        rowIndex: metricIndex + rowsHeadersCount,
        columnIndex,
        isEditable: true,
        isSelectable: true,
        value: observations[key],
        itemData: null,
      };
    });

    return [
      ...acc,
      ...observationsList,
    ];
  }, []);

  const allData = isExtraPlants ? [...dataArray, ...extraDataArray] : dataArray;
  const dataList = getDataList(allData, plantsList);

  const addPlantHeader = [{
    type: 'addPlantHeader',
    rowIndex: rowsHeadersCount - 1,
    columnIndex: plants.length + columnsHeadersCount,
    isEditable: false,
    isSelectable: false,
    value: '+',
    itemData: null,
  }];

  const addExtraPlantHeader = [{
    type: 'addExtraPlantHeader',
    rowIndex: rowsHeadersCount - 1,
    columnIndex: plants.length + extraPlants.length + columnsHeadersCount + 1,
    isEditable: false,
    isSelectable: false,
    value: '+',
    itemData: null,
  }];

  const extraMainHeader = [...metricHeader, ...plants, ...addPlantHeader].map((listItem, index) => ({
    type: 'mainHeader',
    rowIndex: 0,
    columnIndex: index,
    isEditable: false,
    isSelectable: false,
    value: index === 0 ? formatMessage({ id: 'crops.metrics' }) : formatMessage({ id: 'measurements.main' }),
    itemData: null,
  }));

  const extraExtraHeader = [...extraPlants, ...addExtraPlantHeader].map((listItem, index) => ({
    type: 'extraHeader',
    rowIndex: 0,
    columnIndex: extraMainHeader.length + index,
    isEditable: false,
    isSelectable: false,
    value: formatMessage({ id: 'measurements.extra' }),
    itemData: null,
  }));

  const extra = isExtraPlants ? [
    ...extraMainHeader,
    ...extraExtraHeader,
    ...addExtraPlantHeader,
  ]
    :
    [];

  return [
    ...metricHeader,
    ...metricsList,
    ...plantsList,
    ...dataList,
    ...addPlantHeader,
    ...extra,
  ];
};

const getInitialDataObjects = (initialDataArr = []) => initialDataArr.reduce((acc, item) => ({
  ...acc,
  [[item.rowIndex, item.columnIndex]]: item.value,
}), {});


const formatDataToTable = (intl, rowsHeadersCount, columnsHeadersCount, isExtraPlants, sortedMetrics, plants, extraPlants, dataArray, extraDataArray) => {
  const initialDataArr = getInitialDataArray(intl, rowsHeadersCount, columnsHeadersCount, isExtraPlants, sortedMetrics, plants, extraPlants, dataArray, extraDataArray);
  const initialData = getInitialDataObjects(initialDataArr);

  return {
    initialDataArr,
    initialData,
  };
};

// TODO: переписать эту функцию, слишком большая завязка со стейтом
const setNewData = ({
  intl, columnsHeadersCount, setExtraPlants, setData, isExtraPlants, sortedMetrics, plants, extraPlants, dataArray, extraDataArray, setExtraPlantsLoading
}) => {
  const rowsHeadersCount = !isExtraPlants ? 2 : 1; // Если передавать в функцию, то не обновится это значение
  const { initialData } = formatDataToTable(intl, rowsHeadersCount, columnsHeadersCount, !isExtraPlants, sortedMetrics, plants, extraPlants, dataArray, extraDataArray);
  safeLocalStorage.setItem('isExtraPlants', !isExtraPlants);
  setExtraPlantsLoading(true);
  setExtraPlants(!isExtraPlants);

  const newData = {
    ...initialData,
    // ...data, нужно подмёрдживать текуший массив, но со смещением на 1 строку
  };
  setData(newData);

  // Чтобы таблица полностью перерендерилась
  setTimeout(() => setExtraPlantsLoading(false), 0);
};

const getCellInfo = (initialDataArr, plants, extraPlants, metrics = [], rowsHeadersCount, rowIndex, columnIndex) => {
  const currentCellProps = find(initialDataArr, { rowIndex, columnIndex }) || null;
  const currentMetric = metrics[rowIndex - rowsHeadersCount] || null;

  const isAddButtonWithEmptyExtra = currentCellProps?.type === 'addExtraPlantHeader' && extraPlants.length === 0;
  const additionalCellProps = isAddButtonWithEmptyExtra ? {
    forcedWidth: 144,
  } : {};

  return {
    metric: currentMetric,
    cellProps: {
      ...currentCellProps,
      ...additionalCellProps,
    }
    // pllant,
  };
};

const renderButton = formatMessage => (
  <BigButton
    className={styles.deleteButton}
    // onClick={handlerCustomi}
    icon={<CustomizeIcon />}
    bigIcon
    theme='transparent'
    title={formatMessage({ id: 'measurements.customize' })}
  />
);

const validatePlantName = (plantsArray, initialDataArr, rowIndex, columnIndex, plantName) => {
  const currentCellProps = find(initialDataArr, { rowIndex, columnIndex });
  const { itemData: { discriminator, extra } } = currentCellProps;
  const filteredPlants = plantsArray
    .filter(plant => plant.extra === extra)
    .filter(plant => plant.discriminator !== discriminator);
  const findedSameName = find(filteredPlants, { name: plantName });

  return !!findedSameName;
};

const handlerChangePlantInfo = (clearData, setIsSaveChangesDialogVisible, changesTime, changePlantInfoAction, props) => {
  if (changesTime) {
    return setIsSaveChangesDialogVisible(true);
  }

  return changePlantInfoAction({
    ...props,
    actionAfterSuccess: clearData,
  });
};

const MeasurementsForm = ({
  intl,
  measurementsByDate,
  date,
  plantingCycleId,
  requestCreateNextPlant,
  requestUpdatePlant,
  requestDeletePlant,
  tableProps,
  setIsSaveChangesDialogVisible,
  changesTime,
  clearData,
}) => {
  const { formatMessage } = intl;
  const [isExtraPlantsLoading, setExtraPlantsLoading] = useState(false);

  const {
    childRef,
    rowCount,
    columnCount,
    isExtraPlants,
    plants,
    extraPlants,
    rowsHeadersCount,
    columnsHeadersCount,
    setExtraPlants,
    setData,
    data,
    sortedMetrics,
    dataArray,
    extraDataArray,
    initialDataArr,
    addPlantHeaderColumnIndex,
  } = tableProps;

  const mergedCells = isExtraPlants ? [
    {
      top: 0,
      bottom: 1,
      left: 0,
      right: 0,
    },
    {
      top: 0,
      bottom: 0,
      left: 1,
      right: plants.length + 1,
    },
    {
      top: 0,
      bottom: 0,
      left: plants.length + 2,
      right: plants.length + 2 + extraPlants.length + 1,
    }
  ] : [];

  return (
    <>
      <DropdownMenu
        wrapperClassName={styles.dropdownWrapper}
        contentClassName={styles.dropdownContent}
        buttonElement={renderButton(formatMessage)}
      >
        <BigSwitch
          className={styles.extraPlantsSwitch}
          value={isExtraPlants}
          title={formatMessage({ id: 'measurements.extraPlants' })}
          onClick={() => setNewData({
            intl,
            columnsHeadersCount,
            setExtraPlants,
            setData,
            isExtraPlants,
            sortedMetrics,
            plants,
            extraPlants,
            dataArray,
            extraDataArray,
            setExtraPlantsLoading,
          })}
        />
      </DropdownMenu>

      {!isExtraPlantsLoading && (
        <MeasurementsEnterTable
          childRef={childRef}
          extraPlantsLength={extraPlants.length}
          plantsLength={plants.length}
          plantingCycleId={plantingCycleId}
          rowCount={rowCount}
          columnCount={columnCount}
          data={data}
          date={date}
          setData={setData}
          requestCreateNextPlant={props => handlerChangePlantInfo(clearData, setIsSaveChangesDialogVisible, changesTime, requestCreateNextPlant, props)}
          requestUpdatePlant={props => handlerChangePlantInfo(clearData, setIsSaveChangesDialogVisible, changesTime, requestUpdatePlant, props)}
          requestDeletePlant={props => handlerChangePlantInfo(clearData, setIsSaveChangesDialogVisible, changesTime, requestDeletePlant, props)}
          getCellInfo={(rowIndex, colIndex) => getCellInfo(initialDataArr, plants, extraPlants, sortedMetrics, rowsHeadersCount, rowIndex, colIndex)}
          mergedCells={mergedCells}
          rowsHeadersCount={rowsHeadersCount}
          columnsHeadersCount={columnsHeadersCount}
          addPlantHeaderColumnIndex={addPlantHeaderColumnIndex}
          validatePlantName={(rowIndex, columnIndex, plantName) => validatePlantName(measurementsByDate?.plants, initialDataArr, rowIndex, columnIndex, plantName)}
          setIsSaveChangesDialogVisible={setIsSaveChangesDialogVisible}
          changesTime={changesTime}
        />
      )}

      {/* <div className={styles.controls}>
        <BigButton
          className={styles.button}
          title={formatMessage({ id: 'button.cancel' })}
          type='button'
          theme='light'
          onClick={() => {
            // clearHarvestData();
            setIsSaveChangesDialogVisible(true);
            setActionAfter('goBack');
            // history.push(pageToGoBack);
          }}
        />
        <BigButton
          isLoading={isFetching}
          className={styles.button}
          title={formatMessage({ id: 'crops.saveMeasurements' })}
          type='button'
          theme='dark'
          onClick={handlerSubmit}
        />
      </div> */}
    </>
  );
};

MeasurementsForm.propTypes = {
  intl: intlShape.isRequired,
  measurementsByDate: PropTypes.object,
  plantingCycleId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  requestCreateNextPlant: PropTypes.func.isRequired,
  requestUpdatePlant: PropTypes.func.isRequired,
  requestDeletePlant: PropTypes.func.isRequired,
  tableProps: PropTypes.object.isRequired,
  clearData: PropTypes.func.isRequired,
  setIsSaveChangesDialogVisible: PropTypes.func.isRequired,
  changesTime: PropTypes.number,
};

MeasurementsForm.defaultProps = {
  measurementsByDate: null,
  changesTime: undefined,
};

export default memo(MeasurementsForm);
