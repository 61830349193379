import { handleActions } from 'redux-actions';

import {
  requestCropReportsList,
  receiveCropReportsList,

  requestCropReport,
  receiveCropReport,

  requestCropReportsSettings,
  receiveCropReportsSettings,
} from './actions';

const initialState = {
  /**
   * такая структура для reportsList нужна чтобы инвалидировать список
   * при переключении в другой crop cycle
   */
  reportsList: {
    list: [],
    plantingCycleId: undefined,
  },
  isReportsListFetching: false,
  report: undefined,
  isCropReportFetching: false,
  reportsSettings: undefined,
  isReportsSettingsFetching: false,
};

export default handleActions({
  [requestCropReportsSettings](state) {
    return {
      ...state,

      isReportsSettingsFetching: true,
    };
  },
  [receiveCropReportsSettings](state, action) {
    return {
      ...state,

      isReportsSettingsFetching: false,
      reportsSettings: action?.payload,
    };
  },
  [requestCropReportsList](state) {
    return {
      ...state,

      isReportsListFetching: true,
    };
  },
  [receiveCropReportsList](state, action) {
    return {
      ...state,

      isReportsListFetching: false,
      reportsList: {
        list: action?.payload?.data,
        plantingCycleId: action?.payload?.plantingCycleId,
      },
    };
  },

  [requestCropReport](state) {
    return {
      ...state,

      isCropReportFetching: true,
    };
  },
  [receiveCropReport](state, action) {
    return {
      ...state,

      isCropReportFetching: false,
      report: action?.payload,
    };
  },
}, initialState);
