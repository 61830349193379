import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import moment from 'moment-timezone';
import classnames from 'classnames';
import {
  get, isEqual, isNaN, filter, find, sortBy, replace, zip, uniqueId, uniq, flow, includes, isString
} from 'lodash';

import ReactDataSheet from '../../../ReactDataSheet';
import BigNumberInput from '../../../BigNumberInput';
import CircleLoader from '../../../CircleLoader';

import SheetRenderer from '../SheetRenderer';
import RowRenderer from '../RowRenderer';
import CellRenderer from '../CellRenderer';
import CellContextMenu from '../CellContextMenu';

import { generateEmptyGrid, generateGridWithData, generateJsonByGrid } from '../../../../helpers/generateGrid';
import storageWrapper from '../../../../helpers/storageWrapper';

import loaderStyles from '../../../CircleLoader/CircleLoader.module.css';
import styles from './DataSheet.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');

// При генерации в рендер функции возникает баг с инитом в react-contexify, поэтому генерим в константу
const CONTEXT_MENU_ID = `context-menu-${uniqueId()}`;

export default class DataSheet extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    compartments: PropTypes.array.isRequired,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    plantingCycles: PropTypes.array.isRequired,
    organization: PropTypes.object,
    year: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    harvestPlanEdited: PropTypes.object,
    maxIdx: PropTypes.number,
    isPlansEditorFetching: PropTypes.bool,
    isVarietyRemoving: PropTypes.bool,
    isNotPublishedVersionPlate: PropTypes.bool,
    tableScroll: PropTypes.object,
    planDescriptor: PropTypes.object,
    isLastVersion: PropTypes.bool,
    isOperationalPlan: PropTypes.bool.isRequired,
    planType: PropTypes.string.isRequired,
    yearType: PropTypes.string.isRequired,

    setHarvestPlanGrid: PropTypes.func.isRequired,
    setTableScrollPosition: PropTypes.func.isRequired,
    requestPlanForecast: PropTypes.func.isRequired,
    showNotificationWithTimeout: PropTypes.func.isRequired,
  };

  static defaultProps = {
    year: moment().format('YYYY'),
    organization: null,
    harvestPlanEdited: null,
    isPlansEditorFetching: false,
    isVarietyRemoving: false,
    maxIdx: 0,
    tableScroll: null,
    isNotPublishedVersionPlate: true,
    planDescriptor: null,
    isLastVersion: false,
  };

  constructor(props) {
    super(props);
    this.connectScrollTarget = React.createRef();
  }

  state = {
    withoutSaveDate: undefined,
  }

  setModifiedDate = () => {
    const { withoutSaveDate } = this.state;

    let newSaveDate = withoutSaveDate;

    if (!withoutSaveDate) {
      newSaveDate = Date.now();
      this.setState({ withoutSaveDate: newSaveDate });
    }

    return newSaveDate;
  };

  setHarvestPlanGridToStorage = ({ harvestPlanEdited, withoutSaveDate }) => {
    const {
      setHarvestPlanGrid,
      organization,
    } = this.props;

    const plansState = {
      harvestPlanEdited,
      withoutSaveDate,
      organization,
    };

    const localStoragePlanName = this.getLocalStoragePlanName();

    safeLocalStorage.setItem(localStoragePlanName, JSON.stringify(plansState));

    setHarvestPlanGrid({ harvestPlanEdited, withoutSaveDate });
  };

  setModifiedHarvestPlan = (modifiedHarvestPlan) => {
    this.setScroll();

    const sortedModifiedHarvestPlan = {
      ...modifiedHarvestPlan,
      data: sortBy(modifiedHarvestPlan.data, 'idx'),
    };

    const newSaveDate = this.setModifiedDate();

    this.setHarvestPlanGridToStorage({ harvestPlanEdited: sortedModifiedHarvestPlan, withoutSaveDate: newSaveDate });
  };

  setScroll = () => {
    /**
     * Сохраняем позицию скролла для SheetRerenderer, т.к. компонент ремаунтится после изменения грида
     * именно поэтому не используем ref внутри самого SheetRerenderer, а передаём снаружи.
     * TODO: Убрать эту штуку, после переноса комопнента таблицы в свою кодобазу и переделывания
     * SheetRerenderer на обычный компонент, а не rerener функцию
     */
    const { setTableScrollPosition, tableScroll } = this.props;

    const table = this.connectScrollTarget ? this.connectScrollTarget.current : null;

    if (table) {
      const newTableScroll = {
        x: table.scrollLeft,
        y: table.scrollTop,
      };

      if (!isEqual(tableScroll, newTableScroll)) {
        setTableScrollPosition({ tableScroll: newTableScroll });
      }
    }
  }

  // TODO: Объединить с аналогом в PlansEditor
  getLocalStoragePlanName = () => {
    const { planType } = this.props;

    return `${planType}PlansState`;
  };

  // В оперативном плане выводим только те compartments, в которых были planting cycles
  getAvaliableCompartments = (isOperationalPlan, compartments = [], planData = []) => {
    if (!isOperationalPlan) {
      return compartments;
    }
    const currentCompartmentsIds = flow([
      currentData => currentData.map(item => item.compartmentId),
      currentCompartmentIds => uniq(currentCompartmentIds),
    ])(planData);

    const filteredCompartments = filter(compartments, compartment => includes(currentCompartmentsIds, compartment.id));

    return filteredCompartments;
  };

  handlerChanges = (stateGrid, changes) => {
    const {
      harvestPlanEdited,
      isOperationalPlan,
    } = this.props;

    this.setScroll();

    const newSaveDate = this.setModifiedDate();

    const grid = stateGrid.map(row => [...row]);

    changes.forEach(({
      row, col, value
    }) => {
      let validationErrors = [];

      const cunit = get(grid[row][col], 'cunit');
      const isRelativeHarvest = cunit === 'kilogramPerSquareMeter';
      const maxValue = isRelativeHarvest ? 100 : 1000000;
      const errorCode = isRelativeHarvest ? 'cannotBeExceedOneHundred' : 'cannotBeExceedOneMillion';

      // Валидация на максимальное значение
      if (value > maxValue) {
        validationErrors = [...validationErrors, { errorCode }];
      }

      const newValue = isNaN(parseFloat(value)) ? '' : value;
      const cleanValue = replace(newValue, ',', '.');
      const trimmedValue = isString(cleanValue) ?
        cleanValue
          .replace(/^\s+|\s+$/g, '')
          .replace(/\s/g, '')
        :
        cleanValue;

      grid[row][col] = { ...grid[row][col], value: trimmedValue, validationErrors };
    });

    // Прогоняем грид через модификацию к формату бекенда для группировки по циклам
    const modifiedJson = generateJsonByGrid(harvestPlanEdited, grid, true, isOperationalPlan);

    this.setHarvestPlanGridToStorage({ harvestPlanEdited: modifiedJson, withoutSaveDate: newSaveDate });
  };

  handlerAddVariety = (compartmentId, fruitClassCode, varietyId, targetWeight = null, species) => {
    const {
      maxIdx, harvestPlanEdited,
    } = this.props;

    const newVariety = {
      sections: [],
      compartmentId,
      fruitClassCode,
      varietyId,
      targetWeight,
      species,
      idx: maxIdx + 1,
    };

    const modifiedHarvestPlan = {
      ...harvestPlanEdited,
      data: [
        ...get(harvestPlanEdited, 'data', []),
        newVariety,
      ]
    };

    this.setModifiedHarvestPlan(modifiedHarvestPlan);
  };

  handlerRemoveVariety = (idx, afterRemove) => {
    const {
      harvestPlanEdited
    } = this.props;

    const modifiedHarvestPlan = {
      ...harvestPlanEdited,
      data: filter(get(harvestPlanEdited, 'data'), variety => variety.idx !== idx)
    };

    this.setModifiedHarvestPlan(modifiedHarvestPlan);

    if (afterRemove) {
      afterRemove();
    }
  };

  handlerChangeVariety = (idx, newFruitClassCode, newVarietyId, newTargetWeight, newSpecies) => {
    const {
      harvestPlanEdited
    } = this.props;

    const modifiedHarvestPlan = {
      ...harvestPlanEdited,
      data: [
        ...filter(get(harvestPlanEdited, 'data'), variety => variety.idx !== idx),
        {
          ...find(get(harvestPlanEdited, 'data'), variety => variety.idx === idx),
          varietyId: newVarietyId,
          fruitClassCode: newFruitClassCode,
          targetWeight: newTargetWeight,
          species: newSpecies,
        },
      ]
    };

    this.setModifiedHarvestPlan(modifiedHarvestPlan);
  };

  // newValue - объект с изменяемым параметром
  handlerChangeCellGroupParam = (idx, startDate, newValue) => {
    const {
      harvestPlanEdited
    } = this.props;

    const currentColumn = find(get(harvestPlanEdited, 'data'), variety => variety.idx === idx);

    const modifiedHarvestPlan = {
      ...harvestPlanEdited,
      data: [
        ...filter(get(harvestPlanEdited, 'data'), variety => variety.idx !== idx),
        {
          ...currentColumn,
          sections: currentColumn.sections.map((section) => {
            const cellGroup = find(section.cells, cell => cell.startDate === startDate);

            if (cellGroup) {
              return {
                ...section,
                ...newValue,
              };
            }

            return section;
          })
        },
      ]
    };

    this.setModifiedHarvestPlan(modifiedHarvestPlan);
  };

  handlerAddCompartment = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };

  handlerParsePaste = (str) => {
    const initialTable = str.split(/\r\n|\n|\r/)
      .map(row => row.split('\t'));

    // Триггер для транспонирования таблицы
    const isTranspositionTable =
      (initialTable.length > 0 && initialTable.length < 4 && initialTable[0].length > 4)
      ||
      (initialTable.length > 0 && initialTable.length === 1 && initialTable[0].length > 1); // так же транспонируем таблицу если это одна горизонтальная строка

    /**
     * Если количество столбцов сильно больше количества строк, то транспонируем таблицу,
     * т.к. значит в исходном файле было обратное форматирование (категории это строки, даты это столбцы)
     */
    const table = isTranspositionTable ? zip(...initialTable) : initialTable;

    return table;
  }

  handlerAddPlanForecast = (grid, row, col, data) => {
    const { dataPoints } = data;

    const {
      year, showNotificationWithTimeout
    } = this.props;

    const dataArray = Object.keys(dataPoints).reduce((acc, key) => {
      const date = moment.utc(new Date((+key) * 60000));

      return [...acc, { date, value: dataPoints[key] }];
    }, []);

    // Фильтруем данные по году текущего плана плана
    const filteredDataArray = filter(dataArray, point => point.date.year() <= year);

    const changes = filteredDataArray.reduce((acc, item, index) => {
      const incrementedRow = row + index;

      /**
       * Если строка изменения больше длины грида, значит это значение следующего года.
       * Его мы не берём.
       */
      if (incrementedRow > (grid.length - 1)) {
        return acc;
      }

      return [...acc, {
        row: incrementedRow,
        col,
        value: item.value,
      }];
    }, []);

    showNotificationWithTimeout({
      id: 'plans.calculateSuccess',
      messageId: 'plans.calculateSuccess',
      messageParams: { weeksCount: filteredDataArray.length },
      position: 'leftDown',
      iconType: 'success',
      notificationType: 'withActionWide',
    });

    this.handlerChanges(grid, changes);
  }

  handlerOnModelHarvest = (grid, cellProps) => {
    const { varieties, requestPlanForecast } = this.props;
    const {
      startDate, varietyId, compartmentId, row, col,
    } = cellProps;

    const currentVariety = find(varieties, { id: varietyId });
    const fruitClass = get(currentVariety, 'attributes.fruitClass');

    requestPlanForecast({
      date: startDate,
      compartmentId,
      fruitClass,
      varietyId,
      actionSuccess: data => this.handlerAddPlanForecast(grid, row, col, data),
    });
  };

  renderSheet = (props, isOperationalPlan, headerRow, fruitClasses, varieties, isVarietyRemoving, tableScroll, hasValueAtFirstRow, year, intl, isNotPublishedVersionPlate, isLastVersion, organizationSlug, plantingCycles) => (
    <SheetRenderer
      {...props}
      intl={intl}
      headerRow={headerRow}
      varieties={varieties}
      fruitClasses={fruitClasses}
      plantingCycles={plantingCycles}
      handlerAddVariety={this.handlerAddVariety}
      handlerRemoveVariety={this.handlerRemoveVariety}
      handlerChangeVariety={this.handlerChangeVariety}
      handlerAddCompartment={this.handlerAddCompartment}
      isVarietyRemoving={isVarietyRemoving}
      connectScrollTarget={this.connectScrollTarget}
      tableScroll={tableScroll}
      showFakeRow={hasValueAtFirstRow}
      year={year}
      notPublished={isNotPublishedVersionPlate}
      isTableDisabled={!isLastVersion}
      isOperationalPlan={isOperationalPlan}
      organizationSlug={organizationSlug}
    />
  );

  renderRow = props => (<RowRenderer {...props} />);

  renderCell = (props, plantingAreaUnits, fruitClasses, varieties, intl, isLastVersion, isOperationalPlan) => (
    <CellRenderer
      {...props}
      intl={intl}
      changeCellGroupParam={this.handlerChangeCellGroupParam}
      plantingAreaUnits={plantingAreaUnits}
      fruitClasses={fruitClasses}
      varieties={varieties}
      isTableDisabled={!isLastVersion}
      contextMenuId={CONTEXT_MENU_ID}
      isOperationalPlan={isOperationalPlan}
    />
  );

  renderDataEditor = props => (
    <BigNumberInput
      {...props}
      className={styles.cellInput}
      disabled={false}
      step={0.1}
      min={0}
      max={1000}
      allowNegative={false}
      autoFocus
      onBlur={() => {}}
      onFocus={() => {}}
      onClear={() => {}}
    />
  );

  render() {
    const {
      intl, varieties, fruitClasses, isPlansEditorFetching, isVarietyRemoving, tableScroll, year,
      compartments, harvestPlanEdited, planDescriptor, isNotPublishedVersionPlate, isLastVersion,
      isOperationalPlan, organization, plantingCycles, yearType,
    } = this.props;

    const organizationSlug = organization?.attributes?.slug;
    const avaliableCompartments = this.getAvaliableCompartments(isOperationalPlan, compartments, harvestPlanEdited?.data);

    const generatedGrid = harvestPlanEdited ? generateGridWithData(year, avaliableCompartments, harvestPlanEdited.data, planDescriptor, isOperationalPlan, yearType) : generateEmptyGrid(year, avaliableCompartments, planDescriptor, yearType);

    const { grid, headerRow } = generatedGrid;
    const { formatMessage } = intl;

    const plantingAreaUnits = formatMessage({ id: 'cunits.mini.squareMeter' });

    /**
     * Если хотя бы в одной ячейке первой строки есть значение, то будем отрисовывать фейковую
     * строку в таблице, чтобы было удобно пользоваться плашкой planting area,
     * для operation планов показываем её если planting cycle уходит за пределы периода
     */
    const hasValueAtRow = get(grid, '[0]').reduce((acc, cell) => (cell.value !== '' ? true : acc), false);
    const hasValueAtFirstRow = isOperationalPlan ?
      {
        hasValue: hasValueAtRow,
        firstRow: get(grid, '[0]'),
      }
      :
      hasValueAtRow;

    return (
      <div className={styles.dataSheetWrapper}>
        <ReactDataSheet
          data={grid}
          valueRenderer={cell => cell.value}
          sheetRenderer={props => this.renderSheet(props, isOperationalPlan, headerRow, fruitClasses, varieties, isVarietyRemoving, tableScroll, hasValueAtFirstRow, year, intl, isNotPublishedVersionPlate, isLastVersion, organizationSlug, plantingCycles)}
          rowRenderer={this.renderRow}
          cellRenderer={props => this.renderCell(props, plantingAreaUnits, fruitClasses, varieties, intl, isLastVersion, isOperationalPlan)}
          dataEditor={this.renderDataEditor}
          onCellsChanged={changes => this.handlerChanges(grid, changes)}
          parsePaste={this.handlerParsePaste}
        />
        {isPlansEditorFetching && (
          <CircleLoader
            className={classnames(loaderStyles.circleLoader, styles.circleLoader)}
            iconClassName={loaderStyles.circleLoaderIcon}
          />
        )}
        <CellContextMenu
          intl={intl}
          contextMenuId={CONTEXT_MENU_ID}
          onModelHarvest={({ props }) => this.handlerOnModelHarvest(grid, props)}
        />
      </div>
    );
  }
}
