import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import { get } from 'lodash';
import classnames from 'classnames';

import DashboardHeader from 'components/DashboardHeader';
import DashboardFilters from 'components/DashboardFilters';
import DashboardFiltersMobile from 'components/DashboardFiltersMobile';

import numbersRounding from 'helpers/numbersRounding';
import numbersFormatting from 'helpers/numbersFormatting';
import { getPercentDiff } from 'helpers/getPercent';
import addGetParameters from 'helpers/addGetParameters';
import getArrow from 'helpers/getArrow';
import { getFormattedRangeDates, getDefaultDateRange, getFormattedPeriod } from 'helpers/defaultDates';
import {
  getValue, getUnits, getCurrentHarvestCategories, getLocalizedCategoryName
} from 'helpers/getValueDetails';

import HarvestGraph from '../HarvestGraph';
import QualityGraph from '../QualityGraph';
import BreakdownGraph from '../BreakdownGraph';
import GraphHeader from '../GraphHeader';
import HarvestParameter from '../HarvestParameter';

import styles from './HarvestDashboard.module.css';

const DEFAULT_DATE_RANGE = getDefaultDateRange('year', true);

export default class HarvestDashboard extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    getHarvestCategoriesDefault: PropTypes.func.isRequired,
    clearHarvestDataByCategories: PropTypes.func.isRequired,
    getHarvestDashboardData: PropTypes.func.isRequired,
    getHarvestGraphData: PropTypes.func.isRequired,
    getQualityGraphData: PropTypes.func.isRequired,
    getBreakdownGraphData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    periodType: PropTypes.string.isRequired,
    varieties: PropTypes.array.isRequired,
    currentSpecies: PropTypes.string,
    plantingCycles: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    percentageForQuality: PropTypes.bool.isRequired,
    cumulativeForQuality: PropTypes.bool.isRequired,
    fruitClasses: PropTypes.array.isRequired,

    currentUnits: PropTypes.string,
    defaultSpecies: PropTypes.string,
    maxValue: PropTypes.number,
    isDataFetching: PropTypes.bool,
    isHarvestGraphFetching: PropTypes.bool,
    isQualityGraphFetching: PropTypes.bool,
    isBreakdownGraphFetching: PropTypes.bool,

    points: PropTypes.array,
    totalActual: PropTypes.object,
    totalGoal: PropTypes.object,
    totalPrevYear: PropTypes.object,

    pointsByCategories: PropTypes.array,
    totalsByCategories: PropTypes.array,
    harvestByCategoriesEnabled: PropTypes.bool,
    maxCategoriesValue: PropTypes.number,
    maxCategoriesPercentValue: PropTypes.number,

    currentBreakdown: PropTypes.string,
    breakdown: PropTypes.array,
    harvestCategories: PropTypes.object,

    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,

    locationPlan: PropTypes.number,
  };

  static defaultProps = {
    currentUnits: 'absolute',
    currentSpecies: null,
    defaultSpecies: null,
    isDataFetching: false,
    isHarvestGraphFetching: false,
    isQualityGraphFetching: false,
    isBreakdownGraphFetching: false,
    points: null,
    totalActual: null,
    totalGoal: null,
    totalPrevYear: null,
    maxValue: 100,

    pointsByCategories: null,
    totalsByCategories: null,
    harvestByCategoriesEnabled: false,
    maxCategoriesValue: 100,
    maxCategoriesPercentValue: 100,

    currentBreakdown: undefined,
    breakdown: [],
    harvestCategories: undefined,

    anyDateOfPeriodStart: DEFAULT_DATE_RANGE.startDate,
    anyDateOfPeriodEnd: DEFAULT_DATE_RANGE.endDate,

    locationPlan: null,
  };

  componentDidMount() {
    const {
      getHarvestDashboardData,
      getHarvestCategoriesDefault,
      history,
      defaultSpecies,
      locationPlan,
    } = this.props;

    /**
     * TODO: Выпилить логику с defaultSpecies, просто устанавливать currentSpecies
     * в значение defaultSpecies (если в currentSpecies пусто)
     */
    const params = { species: defaultSpecies, ...(locationPlan ? { locationPlan } : {}) };
    const searchString = addGetParameters(params, get(history, 'location.search'));

    history.push({
      search: searchString
    });

    getHarvestDashboardData(searchString);

    getHarvestCategoriesDefault();

    /**
     * Устанавливаем listener на переход по кнопкам браузера назад/вперёд,
     * чтобы обновлять данные в соответствии с изменившимся search запросом в урле
    */
    this.backListener = history.listen((location, action) => {
      if (action === 'POP') {
        getHarvestDashboardData(location.search);
      }
    });
  }

  componentWillUnmount() {
    const { clearHarvestDataByCategories } = this.props;

    clearHarvestDataByCategories();

    this.backListener();
  }

  handlerFiltersChange = (newParams) => {
    const {
      history, getHarvestDashboardData
    } = this.props;

    const searchString = addGetParameters(get(history, 'location.search'), newParams);

    history.push({
      search: searchString
    });

    getHarvestDashboardData(searchString);
  };

  handlerHarvestGraphFiltersChange = (newParams) => {
    const {
      history, getHarvestGraphData
    } = this.props;

    const searchString = addGetParameters(get(history, 'location.search'), newParams);

    history.push({
      search: searchString
    });

    getHarvestGraphData(searchString);
  };

  handlerQualityGraphFiltersChange = (newParams) => {
    const {
      history, getQualityGraphData
    } = this.props;

    const searchString = addGetParameters(get(history, 'location.search'), newParams);

    history.push({
      search: searchString
    });

    getQualityGraphData(searchString);
  };

  handlerBreakdownGraphFiltersChange = (newParams) => {
    const {
      history, getBreakdownGraphData
    } = this.props;

    const searchString = addGetParameters(get(history, 'location.search'), newParams);

    history.push({
      search: searchString
    });

    getBreakdownGraphData(searchString);
  };

  renderDynamic = (actual, old, roundTo) => {
    if (!actual || !old) {
      return null;
    }

    const dynamic = actual - old;
    const dynamicColor = dynamic > 0 ? 'green' : 'red';
    const percent = getPercentDiff(old, actual, true);

    return (
      <span className={classnames({ [styles[dynamicColor]]: dynamic !== 0 })}>
        {dynamic &&
          <span className={styles.arrowIcon}>{getArrow(dynamic)}</span>}
        <span className={styles.dynamicValue}>{numbersFormatting(numbersRounding(dynamic, 'fixed', roundTo))}</span>
        <span>({percent})</span>
      </span>
    );
  };

  renderQualityPercent = ({ amount, units }, roundTo, formatMessage) => {
    const unitsText = formatMessage({ id: `cunits.mini.${units}` });
    const roundedAmount = numbersRounding(amount, 'fixed', roundTo);

    return (
      <span className={styles.qualityPercent}>{`${roundedAmount}${unitsText} ${formatMessage({ id: 'harvestDashboard.ofTotal' })}`}</span>
    );
  };

  renderQualityParams = (totalsByCategories, currentHarvestCategories, roundTo, formatMessage, locale) => totalsByCategories.map((category) => {
    const { amount, discriminator, percentage } = category;
    const value = getValue(amount, roundTo);
    const units = getUnits(amount, formatMessage);
    const localizedCategoryName = getLocalizedCategoryName(discriminator, locale, currentHarvestCategories);
    const categoryName = localizedCategoryName || `Category ${discriminator}`;

    return (
      <HarvestParameter
        key={`quality-parameter-${discriminator}`}
        containerClassName={styles.harvestParam}
        headerText={categoryName}
        amount={value}
        units={units}
        bottomContent={this.renderQualityPercent(percentage, roundTo, formatMessage)}
      />
    );
  });

  renderRadialLabel = (d, roundTo, formatMessage) => {
    const harvestValue = getValue(d.harvest, roundTo);
    const harvestUnits = getUnits(d.harvest, formatMessage);

    const harvestPercentValue = getValue(d.harvestPercent, roundTo);
    const harvestPercentUnits = getUnits(d.harvestPercent, formatMessage);

    return (
      <tspan className={styles.radialLabel}>
        {harvestPercentValue}{harvestPercentUnits}
        <tspan className={styles.radialLabelSubtext}>
          {` (${harvestValue} ${harvestUnits})`}
        </tspan>
      </tspan>
    );
  }

  render() {
    const {
      intl, points, totalActual, totalGoal, totalPrevYear, maxValue, periodType, currentUnits,
      pointsByCategories, totalsByCategories, maxCategoriesValue, maxCategoriesPercentValue,
      breakdown, currentBreakdown,
      varieties, plantingCycles, compartments, fruitClasses,
      isDataFetching, isHarvestGraphFetching, isQualityGraphFetching, isBreakdownGraphFetching,
      harvestCategories, currentSpecies, percentageForQuality, cumulativeForQuality,
      anyDateOfPeriodStart, anyDateOfPeriodEnd, harvestByCategoriesEnabled
    } = this.props;
    const { formatMessage, locale } = intl;

    const roundTo = currentUnits === 'absolute' || currentSpecies === 'lettuce' ? 0 : 1;

    const totalActualAmount = getValue(totalActual, roundTo);
    const totalActualUnits = getUnits(totalActual, formatMessage);
    const totalGoalAmount = getValue(totalGoal, roundTo);
    const totalGoalUnits = getUnits(totalGoal, formatMessage);
    const totalPrevYearAmount = getValue(totalPrevYear, roundTo);
    const totalPrevYearUnits = getUnits(totalPrevYear, formatMessage);

    const currentHarvestCategories = getCurrentHarvestCategories(harvestCategories, currentSpecies);


    const formattedRangeDates = getFormattedRangeDates(anyDateOfPeriodStart, anyDateOfPeriodEnd, periodType, true);
    const startDate = get(formattedRangeDates, 'startDate');
    const endDate = get(formattedRangeDates, 'endDate');
    const periodText = getFormattedPeriod(periodType, formatMessage);

    const rangeText = `${periodText}, ${startDate} – ${endDate}`;

    return (
      <div className={styles.layout}>
        <div className={styles.contentWrapper}>
          <DashboardHeader
            dashboardName={formatMessage({ id: 'dashboards.harvest' })}
            handlerAfterRangeSelect={this.handlerFiltersChange}
            containerClassName={styles.harvestDashboardHeader}
            text={rangeText}
            forcedMaxDate={DEFAULT_DATE_RANGE.endDate}
            filters={<DashboardFiltersMobile onFiltersChange={this.handlerFiltersChange} />}
          />
          <DashboardFilters onFiltersChange={this.handlerFiltersChange} />

          <div className={styles.content}>
            <div className={styles.firstGraph}>
              <GraphHeader
                headerText={formatMessage({ id: 'dashboards.harvest' })}
                toggleHandler={this.handlerHarvestGraphFiltersChange}
              >
                <div className={styles.harvest}>
                  <HarvestParameter
                    containerClassName={styles.harvestParam}
                    headerText={formatMessage({ id: 'dashboards.harvested' })}
                    amount={totalActualAmount}
                    units={totalActualUnits}
                  />
                  <HarvestParameter
                    containerClassName={styles.harvestParam}
                    headerText={formatMessage({ id: 'dashboards.vsPlan' })}
                    amount={totalGoalAmount}
                    units={totalGoalUnits}
                    rightContent={this.renderDynamic(get(totalActual, 'amount'), get(totalGoal, 'amount'), roundTo)}
                  />
                  <HarvestParameter
                    containerClassName={styles.harvestParam}
                    headerText={formatMessage({ id: 'dashboards.previousYear' })}
                    amount={totalPrevYearAmount}
                    units={totalPrevYearUnits}
                    rightContent={this.renderDynamic(get(totalActual, 'amount'), get(totalPrevYear, 'amount'), roundTo)}
                  />
                </div>
              </GraphHeader>

              <HarvestGraph
                isDataFetching={isDataFetching || isHarvestGraphFetching}
                points={points}
                maxValue={maxValue}
                periodType={periodType}
                roundTo={roundTo}
              />
            </div>

            <div className={styles.secondGraph}>
              <GraphHeader
                headerText={formatMessage({ id: 'harvestDashboard.quality' })}
                toggleHandler={this.handlerQualityGraphFiltersChange}
                percentageToggleHandler={this.handlerQualityGraphFiltersChange}
                isQualityCumulative
              >
                <div className={styles.harvest}>
                  {this.renderQualityParams(totalsByCategories, currentHarvestCategories, roundTo, formatMessage, locale)}
                </div>
              </GraphHeader>
              <QualityGraph
                isDataFetching={isDataFetching || isQualityGraphFetching}
                points={pointsByCategories}
                maxValue={percentageForQuality ? maxCategoriesPercentValue : maxCategoriesValue}
                periodType={periodType}
                roundTo={roundTo}
                harvestCategories={currentHarvestCategories}
                percentageForQuality={percentageForQuality}
                cumulativeForQuality={cumulativeForQuality}
                harvestByCategoriesEnabled={harvestByCategoriesEnabled}
              />
            </div>

            <div className={styles.breakdown}>
              <GraphHeader
                className={styles.breakdownHeader}
                headerText={formatMessage({ id: 'harvestDashboard.harvestBreakdown' })}
                selectHandler={this.handlerBreakdownGraphFiltersChange}
                currentBreakdown={currentBreakdown}
              />
              <BreakdownGraph
                isDataFetching={isDataFetching || isBreakdownGraphFetching}
                breakdown={breakdown}
                roundTo={roundTo}
                varieties={varieties}
                fruitClasses={fruitClasses}
                plantingCycles={plantingCycles}
                compartments={compartments}
                anyDateOfPeriodStart={anyDateOfPeriodStart}
                anyDateOfPeriodEnd={anyDateOfPeriodEnd}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
