import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SidePanelHeader from './SidePanelHeader';
import SidePanelGraphPresets from './SidePanelGraphPresets';

import SelectMetricsPanel from '../Graphs/components/SelectMetricsPanel';

import styles from './SidePanel.module.css';

export default class SidePanel extends PureComponent {
  static propTypes = {
    sidePanel: PropTypes.object.isRequired,
    handlerClose: PropTypes.func,
  };

  static defaultProps = {
    handlerClose: null,
  };

  contentClick = (e) => {
    e.stopPropagation();
  };

  renderBody = ({ type }) => {
    const { sidePanel: { sidePanelProps }, handlerClose } = this.props;

    switch (type) {
      case 'graphPresets': {
        const {
          graphPreset: selectedPreset, handlerChangeGraphPreset, handlerDeleteGraphPreset
        } = sidePanelProps;

        const props = {
          selectedPreset, handlerChangeGraphPreset, handlerDeleteGraphPreset, handlerClose
        };

        return (
          <SidePanelGraphPresets {...props} />
        );
      }
      case 'graphParameters': {
        return (
          <SelectMetricsPanel />
        );
      }
      default:
        return null;
    }
  };

  render() {
    const { sidePanel: { sidePanelProps, sidePanelType }, handlerClose } = this.props;
    const isOpened = !!sidePanelType;
    const { headerText } = sidePanelProps;

    const handlerProps = {
      onClick: handlerClose,
      onKeyPress: handlerClose,
    };

    const contentProps = {
      onClick: this.contentClick,
      onKeyPress: this.contentClick,
    };

    return (
      <div className={classnames(styles.overlay, { [styles.overlayOpened]: isOpened })} {...handlerProps}>
        <div className={styles.content} {...contentProps}>
          {headerText && <SidePanelHeader headerText={headerText} handlerClose={handlerClose} />}
          {sidePanelType && this.renderBody({ type: sidePanelType })}
        </div>
      </div>
    );
  }
}
