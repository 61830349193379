import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { get } from 'lodash';
import queryString from 'query-string';

import PercentageToggle from '../components/PercentageToggle';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => {
  const parsedQuery = queryString.parse(get(props, 'location.search'));

  const percentageForQuality = get(parsedQuery, 'percentageForQuality') || false;
  const cumulativeForQuality = get(parsedQuery, 'cumulativeForQuality') || false;

  return {
    ...props,

    percentageForQuality: JSON.parse(percentageForQuality),
    cumulativeForQuality: JSON.parse(cumulativeForQuality),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PercentageToggle));
