import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';

import generateFakeWeeklyData from '../../helpers/generateFakeWeeklyData';
import { isMetricHidden } from '../../helpers/checkHarvestMetrics';

import {
  SET_SHOW_HARVEST,
  UPDATE_SELECTED_METRIC_ID,

  UPDATE_LEFT_WEEKLY_DATA,
  UPDATE_RIGHT_WEEKLY_DATA,

  REQUEST_LEFT_METRICS,
  RECEIVE_LEFT_METRICS,
  INVALIDATE_LEFT_METRICS,

  REQUEST_RIGHT_METRICS,
  RECEIVE_RIGHT_METRICS,
  INVALIDATE_RIGHT_METRICS,

  CLEAR_LEFT_TABLE_DATA,
  RESET_LEFT_TABLE_DATA,
  REQUEST_LEFT_WEEKLY_DATA,
  RECEIVE_LEFT_WEEKLY_DATA,
  INVALIDATE_LEFT_WEEKLY_DATA,

  CLEAR_RIGHT_TABLE_DATA,
  RESET_RIGHT_TABLE_DATA,
  REQUEST_RIGHT_WEEKLY_DATA,
  RECEIVE_RIGHT_WEEKLY_DATA,
  INVALIDATE_RIGHT_WEEKLY_DATA,

  REQUEST_LEFT_DAILY_DATA,
  RECEIVE_LEFT_DAILY_DATA,
  INVALIDATE_LEFT_DAILY_DATA,

  REQUEST_RIGHT_DAILY_DATA,
  RECEIVE_RIGHT_DAILY_DATA,
  INVALIDATE_RIGHT_DAILY_DATA,


  REQUEST_DRILL_DOWN,
  RECEIVE_DRILL_DOWN,
} from './actions';


export default function (state = {
  showHarvest: false,
  isLeftMetricsFetching: false,
  didLeftMetricsInvalidate: false,
  leftMetrics: [],
  selectedLeftMetric: null,
  isRightMetricsFetching: false,
  didRightMetricsInvalidate: false,
  rightMetrics: [],
  isLeftWeeklyDataFetching: false,
  didLeftWeeklyDataInvalidate: false,
  leftWeeklyData: generateFakeWeeklyData(),
  isRightWeeklyDataFetching: false,
  didRightWeeklyDataInvalidate: false,
  rightWeeklyData: generateFakeWeeklyData(),

  isTablesFetching: false,
  invalidateTables: false,

  isDrillDownFetching: false,
}, action) {
  const leftWeeklyData = cloneDeep(state.leftWeeklyData);
  const rightWeeklyData = cloneDeep(state.rightWeeklyData);

  switch (action.type) {
    case REQUEST_DRILL_DOWN:
      return { ...state, isDrillDownFetching: true, };
    case RECEIVE_DRILL_DOWN:
      return { ...state, isDrillDownFetching: false };
    case SET_SHOW_HARVEST:
      return { ...state, showHarvest: action.showHarvest, };
    case INVALIDATE_LEFT_METRICS:
      return { ...state, didLeftMetricsInvalidate: true };
    case REQUEST_LEFT_METRICS:
      return {
        ...state,
        isTablesFetching: true,
        isLeftWeeklyDataFetching: true,
        isLeftMetricsFetching: true,
        didLeftMetricsInvalidate: false
      };
    case RECEIVE_LEFT_METRICS:
      return {
        ...state,
        isTablesFetching: false,
        isLeftWeeklyDataFetching: false,
        isLeftMetricsFetching: false,
        didLeftMetricsInvalidate: false,
        leftMetrics: action.leftMetrics,
        selectedMetricId: action.leftMetrics && action.leftMetrics.length ? action.leftMetrics.filter(item => !isMetricHidden(item.id))[0].id : null,
        lastMetricsUpdated: action.receivedAt,
        lastSelectedLeftMetricIdUpdated: action.receivedAt
      };

    case UPDATE_SELECTED_METRIC_ID:
      return {
        ...state,
        selectedMetricId: action.id,
        lastSelectedMetricIdUpdated: action.receivedAt
      };

    case INVALIDATE_RIGHT_METRICS:
      return { ...state, didRightMetricsInvalidate: true };
    case REQUEST_RIGHT_METRICS:
      return {
        ...state,
        isTablesFetching: true,
        isRightWeeklyDataFetching: true,
        isRightMetricsFetching: true,
        didRightMetricsInvalidate: false
      };
    case RECEIVE_RIGHT_METRICS:
      return {
        ...state,
        isTablesFetching: false,
        isRightWeeklyDataFetching: false,
        isRightMetricsFetching: false,
        didRightMetricsInvalidate: false,
        rightMetrics: action.rightMetrics,
        lastRightMetricsUpdated: action.receivedAt
      };

    case CLEAR_LEFT_TABLE_DATA:
      return { ...state, leftWeeklyData: [], };
    case RESET_LEFT_TABLE_DATA:
      return { ...state, leftWeeklyData: generateFakeWeeklyData(), };
    case INVALIDATE_LEFT_WEEKLY_DATA:
      return { ...state, didLeftWeeklyDataInvalidate: true };
    case REQUEST_LEFT_WEEKLY_DATA:
      return {
        ...state,
        leftWeeklyData: generateFakeWeeklyData(),
        isLeftWeeklyDataFetching: true,
        didLeftWeeklyDataInvalidate: false
      };
    case RECEIVE_LEFT_WEEKLY_DATA:
      return {
        ...state,
        isLeftWeeklyDataFetching: false,
        didLeftWeeklyDataInvalidate: false,
        leftWeeklyData: action.leftWeeklyData.sort((a, b) => moment.tz(a.periodStart, action.location.timezone) - moment.tz(b.periodStart, action.location.timezone)),
        lastLeftWeeklyDataUpdated: action.receivedAt
      };

    case CLEAR_RIGHT_TABLE_DATA:
      return { ...state, rightWeeklyData: [], };
    case RESET_RIGHT_TABLE_DATA:
      return { ...state, rightWeeklyData: generateFakeWeeklyData(), };
    case INVALIDATE_RIGHT_WEEKLY_DATA:
      return { ...state, didRightWeeklyDataInvalidate: true };
    case REQUEST_RIGHT_WEEKLY_DATA:
      return {
        ...state,
        rightWeeklyData: generateFakeWeeklyData(),
        isRightWeeklyDataFetching: true,
        didRightWeeklyDataInvalidate: false
      };
    case RECEIVE_RIGHT_WEEKLY_DATA:
      return {
        ...state,
        isRightWeeklyDataFetching: false,
        didRightWeeklyDataInvalidate: false,
        rightWeeklyData: action.rightWeeklyData.sort((a, b) => moment.tz(a.periodStart, action.location.timezone) - moment.tz(b.periodStart, action.location.timezone)).reverse(),
        lastRightWeeklyDataUpdated: action.receivedAt
      };

    case INVALIDATE_LEFT_DAILY_DATA:
      return { ...state, didLeftDailyDataInvalidate: true };
    case REQUEST_LEFT_DAILY_DATA:
      if (action.leftDailyDataDate) {
        const leftDailyDataDateIndex = leftWeeklyData.findIndex(item => action.leftDailyDataDate && action.leftDailyDataDate.isSame(item.periodStart, 'day'));

        if (leftDailyDataDateIndex > -1) {
          leftWeeklyData[leftDailyDataDateIndex].isFetching = true;
        }
      }
      return {
        ...state,
        leftWeeklyData,
        isLeftDailyDataFetching: true,
        didLeftDailyDataInvalidate: false
      };
    case RECEIVE_LEFT_DAILY_DATA:
      if (action.leftDailyData) {
        const leftDailyDataDateIndex = leftWeeklyData.findIndex(item => action.leftDailyDataDate && action.leftDailyDataDate.isSame(item.periodStart, 'day'));

        if (leftDailyDataDateIndex > -1) {
          leftWeeklyData[leftDailyDataDateIndex].isFetching = false;

          if (leftWeeklyData[leftDailyDataDateIndex] && leftWeeklyData[leftDailyDataDateIndex].rows) {
            action.leftDailyData.forEach((leftDailyDataItem) => {
              const findedLeftDailyDataIndex = leftWeeklyData[leftDailyDataDateIndex].rows.findIndex(item => item.periodStart === leftDailyDataItem.periodStart);
              if (findedLeftDailyDataIndex > -1) {
                Object.keys(leftDailyDataItem.metricValues).forEach((metricId) => {
                  leftWeeklyData[leftDailyDataDateIndex].rows[findedLeftDailyDataIndex].metricValues[metricId] = leftDailyDataItem.metricValues[metricId];
                });
              } else {
                leftWeeklyData[leftDailyDataDateIndex].rows.push(leftDailyDataItem);
                leftWeeklyData[leftDailyDataDateIndex].rows = cloneDeep(leftWeeklyData[leftDailyDataDateIndex].rows.sort((a, b) => moment.tz(a.periodStart, action.location.timezone) - moment.tz(b.periodStart, action.location.timezone)));
              }
            });

            leftWeeklyData[leftDailyDataDateIndex].rows.forEach((leftDailyData) => {
              const editLeftDailyData = leftDailyData;
              const findedLeftDailyDataIndex = action.leftDailyData.findIndex(item => item.periodStart === leftDailyData.periodStart);
              if (findedLeftDailyDataIndex > -1) {
                Object.keys(leftDailyData.metricValues).forEach((metricId) => {
                  if (action.metric && metricId.toString() === action.metric.id.toString()) {
                    if ((typeof action.leftDailyData[findedLeftDailyDataIndex].metricValues[metricId] === 'undefined') || action.leftDailyData[findedLeftDailyDataIndex].metricValues[metricId] === null || action.leftDailyData[findedLeftDailyDataIndex].metricValues[metricId] === '') {
                      delete editLeftDailyData.metricValues[metricId];
                    }
                  }
                });
              }
            });
          } else {
            leftWeeklyData[leftDailyDataDateIndex].rows = action.leftDailyData.sort((a, b) => moment.tz(a.periodStart, action.location.timezone) - moment.tz(b.periodStart, action.location.timezone));
          }
        }
      }

      return {
        ...state,
        isTablesFetching: false,
        isLeftDailyDataFetching: false,
        didLeftDailyDataInvalidate: false,
        leftWeeklyData,
        lastLeftDailyDataUpdated: action.receivedAt
      };

    case INVALIDATE_RIGHT_DAILY_DATA:
      return { ...state, didRightDailyDataInvalidate: true };
    case REQUEST_RIGHT_DAILY_DATA:
      if (action.rightDailyDataDate) {
        const rightDailyDataDateIndex = rightWeeklyData.findIndex(item => action.rightDailyDataDate && action.rightDailyDataDate.isSame(item.periodStart, 'day'));

        if (rightDailyDataDateIndex > -1) {
          rightWeeklyData[rightDailyDataDateIndex].isFetching = true;
        }
      }
      return {
        ...state, // isTablesFetching: true,
        rightWeeklyData,
        isRightDailyDataFetching: true,
        didRightDailyDataInvalidate: false
      };
    case RECEIVE_RIGHT_DAILY_DATA:
      if (action.rightDailyData) {
        const rightDailyDataDateIndex = rightWeeklyData.findIndex(item => action.rightDailyDataDate && item.periodStart === action.rightDailyDataDate.format('YYYY-MM-DD'));


        if (rightDailyDataDateIndex > -1) {
          rightWeeklyData[rightDailyDataDateIndex].isFetching = false;

          if (rightWeeklyData[rightDailyDataDateIndex] && rightWeeklyData[rightDailyDataDateIndex].rows) {
            action.rightDailyData.forEach((rightDailyDataItem) => {
              const findedrightDailyDataIndex = rightWeeklyData[rightDailyDataDateIndex].rows.findIndex(item => item.periodStart === rightDailyDataItem.periodStart);
              if (findedrightDailyDataIndex > -1) {
                Object.keys(rightDailyDataItem.metricValues).forEach((metricId) => {
                  rightWeeklyData[rightDailyDataDateIndex].rows[findedrightDailyDataIndex].metricValues[metricId] = rightDailyDataItem.metricValues[metricId];
                });
              } else {
                rightWeeklyData[rightDailyDataDateIndex].rows.push(rightDailyDataItem);
                rightWeeklyData[rightDailyDataDateIndex].rows = cloneDeep(rightWeeklyData[rightDailyDataDateIndex].rows.sort((a, b) => moment.tz(a.periodStart, action.location.timezone) - moment.tz(b.periodStart, action.location.timezone)));
              }
            });

            rightWeeklyData[rightDailyDataDateIndex].rows.forEach((rightDailyData) => {
              const editRightDailyData = rightDailyData;
              const findedRightDailyDataIndex = action.rightDailyData.findIndex(item => item.periodStart === rightDailyData.periodStart);
              if (findedRightDailyDataIndex > -1) {
                Object.keys(rightDailyData.metricValues).forEach((metricId) => {
                  if (action.metric && metricId.toString() === action.metric.id.toString()) {
                    if ((typeof action.rightDailyData[findedRightDailyDataIndex].metricValues[metricId] === 'undefined') || action.rightDailyData[findedRightDailyDataIndex].metricValues[metricId] === null || action.rightDailyData[findedRightDailyDataIndex].metricValues[metricId] === '') {
                      delete editRightDailyData.metricValues[metricId];
                    }
                  }
                });
              }
            });
          } else {
            rightWeeklyData[rightDailyDataDateIndex].rows = action.rightDailyData.sort((a, b) => moment.tz(a.periodStart, action.location.timezone) - moment.tz(b.periodStart, action.location.timezone));
          }
        }
      }

      return {
        ...state,
        isTablesFetching: false,
        isRightDailyDataFetching: false,
        didRightDailyDataInvalidate: false,
        rightDailyData: state.rightDailyData,
        rightWeeklyData,
        lastRightDailyDataUpdated: action.receivedAt
      };


    case UPDATE_LEFT_WEEKLY_DATA:
      if (action.leftWeeklyData) {
        const dailyState = state;

        action.leftWeeklyData.forEach((weeklyDataItem) => {
          const leftDailyDataDateIndex = state.leftWeeklyData.findIndex(item => item.periodStart === weeklyDataItem.periodStart);

          if (dailyState.leftWeeklyData[leftDailyDataDateIndex] && dailyState.leftWeeklyData[leftDailyDataDateIndex].metricValues) {
            Object.keys(weeklyDataItem.metricValues).forEach((metricId) => {
              dailyState.leftWeeklyData[leftDailyDataDateIndex].metricValues[metricId] = weeklyDataItem.metricValues[metricId];
            });
          } else {
            dailyState.leftWeeklyData[leftDailyDataDateIndex] = cloneDeep(weeklyDataItem);
          }
        });
      }

      return {
        ...state,
        isTablesFetching: false,
        isLeftWeeklyDataFetching: false,
        didLeftWeeklyDataInvalidate: false,
        leftWeeklyData: state.leftWeeklyData,
        lastLeftWeeklyDataUpdated: action.receivedAt
      };

    case UPDATE_RIGHT_WEEKLY_DATA:
      if (action.rightWeeklyData) {
        const dailyState = state;

        action.rightWeeklyData.forEach((weeklyDataItem) => {
          const rightDailyDataDateIndex = state.rightWeeklyData.findIndex(item => item.periodStart === weeklyDataItem.periodStart);

          if (dailyState.rightWeeklyData[rightDailyDataDateIndex] && dailyState.rightWeeklyData[rightDailyDataDateIndex].metricValues) {
            Object.keys(weeklyDataItem.metricValues).forEach((metricId) => {
              dailyState.rightWeeklyData[rightDailyDataDateIndex].metricValues[metricId] = weeklyDataItem.metricValues[metricId];
            });
          } else {
            dailyState.rightWeeklyData[rightDailyDataDateIndex] = cloneDeep(weeklyDataItem);
          }
        });
      }

      return {
        ...state,
        isTablesFetching: false,
        isRightWeeklyDataFetching: false,
        didRightWeeklyDataInvalidate: false,
        rightWeeklyData: state.rightWeeklyData,
        lastRightWeeklyDataUpdated: action.receivedAt
      };


    default:
      return state;
  }
}
