import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import { get, find } from 'lodash';
import classnames from 'classnames';

import ClickOutside from '../../../ClickOutside';
import ArrowDown from '../../../Icons/ArrowDown';
import CloseIcon from '../../../Icons/Close';

import numbersRounding from '../../../../helpers/numbersRounding';
import numbersFormatting from '../../../../helpers/numbersFormatting';
import { getProductTypeName } from '../../../../helpers/getVarietyName';

import EditableValue from '../EditableValue';

import styles from './PlantingAreaPopover.module.css';

export default class PlantingAreaPopover extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    varietyId: PropTypes.number.isRequired,
    changeCellGroupParam: PropTypes.func.isRequired,
    plantingAreaUnits: PropTypes.string,
    cell: PropTypes.object,
    isTableDisabled: PropTypes.bool,
  };

  static defaultProps = {
    plantingAreaUnits: undefined,
    cell: null,
    isTableDisabled: false,
  };

  state = {
    isPopoverOpened: false,
  }

  handlerClosePopover = () => this.setState({ isPopoverOpened: false });

  handlerClickBage = () => this.setState(prevState => ({ isPopoverOpened: !prevState.isPopoverOpened }));

  handlerPreventClickPropagation = e => e.stopPropagation();

  render() {
    const {
      intl,
      intl: { formatMessage, formatPlural },
      fruitClasses,
      plantingAreaUnits,
      varieties, varietyId,
      cell,
      changeCellGroupParam,
      isTableDisabled
    } = this.props;
    const { isPopoverOpened } = this.state;

    const {
      idx, relativeHarvestSum, plantingArea, plantDensity, weeksCount, fistWeek, lastWeek, startDate, targetWeight, cunit
    } = cell;

    const currentVariety = find(varieties, { id: varietyId });
    const currentSpecies = get(currentVariety, 'attributes.species');
    const fruitClassId = get(currentVariety, 'attributes.fruitClass');
    const currentFruitClass = find(fruitClasses, { id: fruitClassId });

    const isLettuce = currentSpecies === 'lettuce';

    const harvestUnits = cunit ? formatMessage({ id: `cunits.mini.${cunit}` }) : '';
    const roundTo = cunit === 'count' || cunit === 'kilogram' ? 0 : 1;

    const harvestValue = numbersFormatting(numbersRounding(relativeHarvestSum, 'fixed', roundTo));
    const plantingAreaValue = numbersFormatting(plantingArea);
    const pluralWeek = formatMessage({ id: `timeSelector.week.${formatPlural(weeksCount)}` });

    const name = getProductTypeName({
      intl,
      variety: currentVariety,
      fruitClass: currentFruitClass,
      targetWeight,
    });

    return (
      <ClickOutside
        handleClickOutside={this.handlerClosePopover}
      >
        <div
          className={styles.plantingAreaWrapper}
          /**
           * If we are not stop bubbling at onMouseDown and onDoubleClick, events fire
           * on data cell (parent component), cell will be active and will be editable.
           * So we stop propagation of these events for prevent bugs and side effects
           */
          onMouseDown={this.handlerPreventClickPropagation}
          onDoubleClick={this.handlerPreventClickPropagation}
          role='button'
          tabIndex={0}
        >
          <div
            className={styles.plantingArea}
            onClick={this.handlerClickBage}
            role='button'
            tabIndex={0}
          >
            <span className={styles.harvestValue}>
              {harvestValue}
              <span> {harvestUnits}</span>
            </span>
            {!isLettuce && <span className={styles.dot} />}
            {!isLettuce && (
              <span className={styles.areaValue}>
                {plantingAreaValue}
                <span> {plantingAreaUnits}</span>
              </span>
            )}
            <span className={styles.plantingAreaIcon}>
              <ArrowDown />
            </span>
          </div>

          <div className={classnames(styles.plantingAreaPopover, { [styles.popoverOpened]: isPopoverOpened })}>
            <div className={styles.popoverHeader}>
              <span className={styles.popoverHeaderText}>
                {name}
              </span>
              <span
                className={styles.popoverHeaderCloseIcon}
                onClick={this.handlerClosePopover}
                role='button'
                tabIndex={0}
              >
                <CloseIcon className={styles.closeIcon} />
              </span>
            </div>
            <div className={styles.popoverHeaderWeeks}>
              {`${weeksCount} ${pluralWeek} (${fistWeek}-${lastWeek})`}
            </div>
            <div>
              <div className={styles.popoverValue}>
                {formatMessage({ id: 'plans.totalHarvest' })}
                <span className={styles.popoverValueAmount}>{harvestValue} {harvestUnits}</span>
              </div>
              {!isLettuce && (
                <div className={classnames(styles.popoverValue, styles.popoverValuePlantingArea)}>
                  {formatMessage({ id: 'plans.plantingArea' })}
                  <EditableValue
                    idx={idx}
                    startDate={startDate}
                    onEditAction={newValue => changeCellGroupParam(idx, startDate, { plantingArea: newValue })}
                    fieldUnits={plantingAreaUnits}
                    fieldValue={plantingArea || ''}
                    isTableDisabled={isTableDisabled}
                    editIconClassName={styles.editIcon}
                  />
                </div>
              )}
              {!isLettuce && (
                <div className={classnames(styles.popoverValue, styles.popoverValuePlantingDensity)}>
                  {formatMessage({ id: 'plans.plantingDensity' })}
                  <EditableValue
                    idx={idx}
                    startDate={startDate}
                    onEditAction={newValue => changeCellGroupParam(idx, startDate, { plantDensity: newValue })}
                    fieldUnits={formatMessage({ id: 'cunits.mini.plantsPerSquareMeter' })}
                    fieldValue={plantDensity || ''}
                    isTableDisabled={isTableDisabled}
                    roundTo={1}
                    editIconClassName={styles.editIcon}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </ClickOutside>
    );
  }
}

