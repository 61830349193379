import { useEffect } from 'react';

import { area, select } from 'd3';

import interpolateLine from 'components/LinesGraph/components/LineChart/interpolate';

function useArea(groupRef, options) {
  const {
    chartParams: {
      x, y, xValue, yValue
    },
    areaData,
    interpolate,
    height,
    margin,
    isHoverLineSizeNeedCalc,
  } = options;
  useEffect(() => {
    const group = groupRef.current && areaData?.length > 0
      ? select(groupRef.current)
      : null;
    if (group) {
      group
        .selectAll('path').remove();

      const areaPath = area()
        .curve(interpolateLine(interpolate))
        .x(d => x(xValue(d)))
        .y0(height - (isHoverLineSizeNeedCalc ? margin.top + margin.bottom : 70))
        .y1(d => y(yValue(d)));

      for (let i = 0; i < areaData.length; i += 1) {
        group
          .selectAll('path')
          .data([areaData[i].points])
          .enter()
          .append('path')
          .attr('class', 'area')
          .attr('fill', areaData[i].area)
          .attr('d', areaPath);
      }
    }
  }, [
    groupRef,
    x, y, xValue, yValue,
    areaData,
    interpolate,
    height,
    margin,
    isHoverLineSizeNeedCalc,
  ]);
}

export default useArea;
