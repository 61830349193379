import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { get } from 'lodash';

import styles from './BenchmarkMetrics.module.css';

import BenchmarkMetricChart from '../BenchmarkMetricChart/BenchmarkMetricChart';


class BenchmarkMetrics extends Component {
  static propTypes = {
    comparison: PropTypes.object,
    onTooltipChanged: PropTypes.func,
    products: PropTypes.array,
    tooltipState: PropTypes.any
  };

  static defaultProps = {
    products: null,
    comparison: null,
    onTooltipChanged: null,
    tooltipState: null
  };

  handlerTooltipChanged = (options) => {
    const { onTooltipChanged } = this.props;

    if (onTooltipChanged) {
      onTooltipChanged(options);
    }
  }

  render() {
    const {
      comparison,
      products,
      tooltipState
    } = this.props;

    const graphMetrics = get(comparison, 'graphMetrics', []);

    return graphMetrics.map(graphMetric => (
      <div className={styles.graphMetric} key={`metrics-${get(graphMetric, 'id')}`}>
        <BenchmarkMetricChart
          products={products}
          isDataFetching={false}
          comparison={comparison}
          graphMetric={graphMetric}
          periodType='weekLight'
          onTooltipChanged={this.handlerTooltipChanged}
          tooltipState={tooltipState}
        />
      </div>
    ));
  }
}

export default injectIntl(BenchmarkMetrics);
