import { get, } from 'lodash';
import { createSelector } from 'reselect';
import queryString from 'query-string';

export const getRouteLocation = state => get(state, 'router.location');
export const getRouteMatchParams = (state, props) => get(props, 'match.params');

export const getRouteQueryParams = createSelector(
  [getRouteLocation],
  location => queryString.parse(get(location, 'search')),
);
