import { handleActions } from 'redux-actions';

import {
  setDashboardsMenuState,
  setDashboardsCurrentTabState,
} from './actions';

const initialState = {
  isDashboardsMenuOpened: null,
  dashboardsCurrentTab: null,
};

export default handleActions({
  [setDashboardsMenuState](state, action) {
    return {
      ...state,
      isDashboardsMenuOpened: action.payload.isDashboardsMenuOpened,
    };
  },
  [setDashboardsCurrentTabState](state, action) {
    return {
      ...state,
      dashboardsCurrentTab: action.payload.dashboardsCurrentTab,
    };
  },
}, initialState);
