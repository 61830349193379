import { connect } from 'react-redux';

import { requestDefaultNumberOfPlants, requestManualFormsMetrics } from 'store/measurements/actions';
import { getDefaultNumberOfPlants, getIsDefaultNumberOfPlantsFetching } from 'store/measurements/selectors';

import SettingsMeasurementsWrapper from '../components/SettingsMeasurementsWrapper';

const mapDispatchToProps = {
  requestDefaultNumberOfPlants,
  requestManualFormsMetrics
};

const mapStateToProps = (state, props) => ({
  ...props,

  isDefaultNumberOfPlantsFetching: getIsDefaultNumberOfPlantsFetching(state, props),
  initialValues: {
    plantsToEnter: getDefaultNumberOfPlants(state, props),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMeasurementsWrapper);
