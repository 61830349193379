import React, { memo } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import CalendarShapeIcon from '../../../Icons/CalendarShapeIcon';

import styles from './YearRangePickerButton.module.css';

const YearRangePickerButton = ({
  date, className, disabled, onClick
}) => (
  <button
    type='button'
    className={classnames(styles.button, className, { [styles.disabled]: disabled })}
    disabled={disabled}
    onClick={onClick && !disabled ? onClick : () => {}}
  >
    {date}
    <CalendarShapeIcon className={styles.icon} />
  </button>
);

YearRangePickerButton.propTypes = {
  date: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

YearRangePickerButton.defaultProps = {
  className: null,
  disabled: false,
  onClick: null,
};

export default memo(YearRangePickerButton);
