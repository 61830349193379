import moment from 'moment-timezone';

export default function getDatesRange({
  location,
  xRange,
  xRangeEnd,
  xRangeLengthInMins,
}) {
  const { attributes: { timezone } } = location;

  let xFrom = moment.tz('UTC');
  let xTo = moment.tz('UTC');

  const utcTo = moment.tz(timezone)
    .add(-moment.tz.zone(timezone).offset(+moment.tz(timezone)), 'minutes')
    .utc()
    .hours(23)
    .minutes(59)
    .seconds(0)
    .milliseconds(0);

  switch (xRange) {
    case 'today': {
      xFrom = moment.tz(timezone)
        .add(-moment.tz.zone(timezone).offset(+moment.tz(timezone)), 'minutes')
        .utc()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0);

      xTo = utcTo;

      break;
    }
    case 'last2Days': {
      xFrom = moment.tz(timezone)
        .add(-moment.tz.zone(timezone).offset(+moment.tz(timezone)), 'minutes')
        .utc()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .subtract(1, 'days');

      xTo = utcTo;

      break;
    }
    case 'last7Days': {
      xFrom = moment.tz(timezone)
        .add(-moment.tz.zone(timezone).offset(+moment.tz(timezone)), 'minutes')
        .utc()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .subtract(6, 'days');

      xTo = utcTo;

      break;
    }
    case 'last30Days': {
      xFrom = moment.tz(timezone)
        .add(-moment.tz.zone(timezone).offset(+moment.tz(timezone)), 'minutes')
        .utc()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .subtract(29, 'days');

      xTo = utcTo;

      break;
    }
    case 'last90Days': {
      xFrom = moment.tz(timezone)
        .add(-moment.tz.zone(timezone).offset(+moment.tz(timezone)), 'minutes')
        .utc()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .subtract(89, 'days');

      xTo = utcTo;

      break;
    }
    default: {
      xTo = xRangeEnd ?
        moment.tz(xRangeEnd, 'YYYY-MM-DDTHH:mm:ss', 'UTC') :
        moment.tz('UTC')
          .hours(23)
          .minutes(59)
          .seconds(0)
          .milliseconds(0);

      xFrom = xTo.clone()
        .seconds(0)
        .milliseconds(0)
        .add(1, 'minutes')
        .subtract(xRangeLengthInMins, 'minutes');

      break;
    }
  }

  return {
    xFrom,
    xTo,
  };
}
