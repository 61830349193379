import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styles from './Policies.module.css';


export default class Policies extends Component {
  render() {
    return (
      <article className={styles.main}>
        <h1>Politique de confidentialité et d’utilisation des fichiers cookies</h1>

        <p>Sur le site web <RouterLink to='https://pylot.app'>https://pylot.app</RouterLink> (ci-après le « site web ») les données personnelles sont collectées, et pour ceci les fichiers cookies sont utilisés. La société Pylot B. V. (ci-après « Pylot ») respecte la vie privée des visiteurs du site web. C’est pourquoi des données personnelles sont collectées et traitées avec une précaution et en conformité avec les exigences de protéger des données personnelles établies par les lois européennes relatives à la confidentialité. La présente Politique de confidentialité et d’utilisation des fichiers cookies vous informe sur la façon dont les données personnelles sont traitées, et sur votre protection de confidentialité.</p>

        <br />

        <p>En traitant des données, nous respectons les exigences de la législation relative à la confidentialité. Cela signifie, entre autre, que nous :</p>

        <ul type='circle'>
          <li><strong>désignons exactement nos objectifs</strong> avant de traiter les données personnelles par la présente Politique de confidentialité et d’utilisation des fichiers cookies ;</li>
          <li><strong>essayons de limiter la collecte de données personnelles</strong>, ne collectant que des données nécessaires à l’atteinte des objectifs légaux ;</li>
          <li><strong>demandons</strong> préalablement votre accord de traiter vos données personnelles dans les cas où cet accord est requis;</li>
          <li>prenons <strong>des mesures de sécurité nécessaires</strong> pour protéger vos données personnelles et exigeons que les personnes, traitant des données personnelles au nom de Pylot, agissent de la même façon ;</li>
          <li><strong>respectons</strong> vos droits concernant vos données personnelles se trouvant à notre disposition.</li>
        </ul>

        <br />

        <p>Nous jouons le rôle du contrôleur de traitement de données. La présente Politique de confidentialité et d’utilisation des fichiers cookies comprend l’information suivante : quelles données personnelles nous collectons, comment nous les utilisons et dans quel but. Nous vous recommandons d’étudier attentivement le présent document.</p>

        <br />

        <p>La dernière version de la présente Politique de confidentialité et d’utilisation des fichiers cookies a été rédigée le 11/12/2018.</p>

        <h2>Traitement de données personnelles</h2>
        <p>En utilisant notre site web, vous partagez de l’information qui peut comprendre vos données personnelles. Nous ne sauvegardons et n’utilisons que les données personnelles qui nous ont été fournies directement par vous et spécialement aux fins de traitement. Nous n’utiliserons jamais vos données personnelles à d’autres fins sans recevoir votre accord préalable et sans observer les normes législatives autorisant leur utilisation.</p>

        <br />

        <p>Quand vous entrez sur le site web, nous traitons vos données personnelles suivantes :</p>

        <ul type='circle'>
          <li>adresse e-mail;</li>
          <li>nom d’utilisateur;</li>
          <li>adresse IP;</li>
          <li>système opérationnel;</li>
          <li>type du dispositif;</li>
          <li>votre région;</li>
          <li>langue utilisée;</li>
          <li>pages visitées.</li>
        </ul>

        <br />

        <p>Nous traitons ces données personnelles dans les buts d’exécution des obligations contractuelles, et notamment pour créer votre compte d'utilisateur et assurer son assistance sur le site web, pour assurer votre accès au site web et à votre compte d'utilisateur, pour vous contacter concernant les services que vous avez demandés et pour vous informer sur ces services.</p>

        <br />

        <p>En outre, lorsque vous nous avez donné votre accord, nous utilisons votre adresse e-mail pour vous envoyer des bulletins électroniques, afin de vous informer sur les mises à jour du site web. Chaque bulletin comprend le bouton « Se désabonner ». Vous pouvez également vous désabonner en vous adressant à notre service d’assistance et en nous communiquant votre souhait de ne plus recevoir ces bulletins. Si vous vous désabonnez des bulletins électroniques, nous supprimerons votre adresse e-mail de la liste de nos abonnés.</p>

        <br />

        <p>De plus, nous suivons et analysons certaines informations liées à vos activités sur notre site web. Nous étudions ces données uniquement de façon interne afin de savoir plus sur votre localisation, les langues utilisées, la fréquence d’utilisation du site, les pages visitées et votre adresse IP. Cette information nous permettra d’améliorer notre produit, faire des pronostics et résoudre des problèmes potentiels, aussi bien qu’analyser le comportement des utilisateurs.</p>

        <br />

        <p>Dans ces buts, il se peut que nous utilisions les données relatives à votre localisation. Nous demandons spécialement votre accord d’utiliser les données concernant votre localisation et nous ne traiterons ces données qu’avec votre accord.</p>

        <h2>Fichiers cookies</h2>
        <p>Les fichiers cookies représentent de petits fichiers d’information, dans lesquels nous pouvons stocker des informations, de sorte que vous n’ayez pas à remplir ces données plusieurs fois. Nous pouvons également les utiliser pour reconnaître votre visite sur le site web. Ceci est fait par le navigateur web de votre dispositif (y compris ordinateur, tablette, ou smartphone). Les informations qui peuvent être communiquées par les fichiers cookies, en utilisant notre site web, peuvent être transmises sur les serveurs protégés de Pylot ou bien sur les serveurs d’un tiers.</p>

        <br />

        <p>Notre site web utilise des fichiers cookies. Les fichiers cookies sont placés par nous-mêmes, aussi bien que par l'intermédiaire des tiers que nous engageons pour le traitement de données. Nous utilisons des fichiers сookies sur notre site web dans les buts suivants :</p>

        <ul type='circle'>
          <li>pour apporter des statistiques générales et comprendre mieux comment le site web est utilisé par le grand public, afin d’optimiser notre site web et les services (des fichiers cookies analytiques) ;</li>
          <li>pour rendre internet plus intéressant pour vous par le placement des publicités qui correspondent à vos intérêts, et en personnalisant les messages publicitaires et le contenu du site web, en fonction de vos intérêts et comportement de navigation web (des fichiers cookies de suivi / cookies de ciblage / cookies de marketing).</li>
        </ul>

        <br />

        <p>Dans les buts indiqués ci-dessus, nous utilisons les fichiers cookies suivants :</p>

        <br />

        <div className={styles.scrollable}>
          <table>
            <thead>
              <tr>
                <th>Partie fournissant les fichiers сookies</th>
                <th>Catégorie</th>
                <th>Siège social</th>
                <th>Objectif</th>
                <th>Type</th>
                <th>Durée d’expiration</th>
                <th>Règlement de confidentialité</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Google Analytics _ga_cid</td>
                <td>Cookies analytiques</td>
                <td>États-Unis</td>
                <td>Google Analytics : enregistre l’identificateur unique pour fournir des statistiques des visites du site web
                </td>
                <td>Https-cookie</td>
                <td>Après l’écoulement de la séance</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Règlement de confidentialité</a></td>
              </tr>
              <tr>
                <td>Google Analytics _ga</td>
                <td>Cookies analytiques</td>
                <td>États-Unis</td>
                <td>Google Analytics : tient compte des visites web (répétées)</td>
                <td>Https-cookie</td>
                <td>2 ans</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Règlement de confidentialité</a></td>
              </tr>
              <tr>
                <td>Google Analytics _gid</td>
                <td>Cookies analytiques</td>
                <td>États-Unis</td>
                <td>Google Analytic : discerne les utilisateurs en types différents</td>
                <td>Https-cookie</td>
                <td>24 heures</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Règlement de confidentialité</a></td>
              </tr>
              <tr>
                <td>Google Analytics _gat</td>
                <td>Cookies analytiques</td>
                <td>États-Unis</td>
                <td>Google Analytics : utilisés pour accélérer la vitesse de requêtes</td>
                <td>Https-cookie</td>
                <td>Après l’écoulement de la séance</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Règlement de confidentialité</a></td>
              </tr>
              <tr>
                <td>Google Doubleclick IDE</td>
                <td>Cookies de marketing</td>
                <td>États-Unis</td>
                <td>Google Doubleclick : utilisés pour optimiser les annonces publicitaires</td>
                <td>Https-cookie</td>
                <td>1 an</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Règlement de confidentialité</a></td>
              </tr>
              <tr>
                <td>Google Doubleclick DSID</td>
                <td>Cookies de marketing</td>
                <td>États-Unis</td>
                <td>Google Doubleclick : utilisés pour l’analyse de vos visites dans le but d’affichage des annonces publicitaires correspondantes</td>
                <td>Https-cookie</td>
                <td>1 semaine</td>
                <td><a href='https://policies.google.com/privacy?hl=nl'>Règlement de confidentialité</a></td>
              </tr>
              <tr>
                <td>Amplitude amplitude_id*</td>
                <td>Cookies de suivi</td>
                <td>États-Unis</td>
                <td>Amplitude : suit des visiteurs uniques du site web dans le but d’optimisation</td>
                <td>Https-cookie</td>
                <td>10 ans</td>
                <td><a href='https://amplitude.com/privacy'>Règlement de confidentialité</a></td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />

        <p>Lors de la première visite sur notre site web, nous affichons un message contenant la description de nos fichiers cookies et demandons votre accord d’utiliser ces fichiers. Vous pouvez désactiver l’utilisation des fichiers cookies dans les réglages de votre navigateur web, mais en ce cas le fonctionnement de certains rubriques de notre site peut être incorrect.</p>

        <h2>Intercom</h2>
        <p>Notre site web utilise des cookies analytiques, des cookies de marketing et des cookies de suivi / d’optimisation (voir ci-dessus). Nous utilisons également des fichiers de la société Intercom, rendant possible les discussions en ligne. Les informations sur votre navigateur web et les discussions sont collectées et sauvegardées sur des serveurs aux Etats-Unis. Nous avons signé un Contrat de traitement de données avec la société Intercom. Par conséquent, nous n’avons pas donné à la société Intercom notre accord d’utiliser les informations reçues à l’aide de ces fichiers cookies. Nous masquons autant d’informations que possible. Pour plus de détails, nous vous recommandons d’étudier plus attentivement le <a href='https://www.intercom.com/terms-and-policies#terms'>Règlement de confidentialité</a> de la société Intercom.</p>

        <h2>Google Analytics et Amplitude</h2>
        <p>Nous utilisons Google Analytics et Amplitude pour suivre les visiteurs sur notre site web et pour obtenir des rapports sur l’utilisation de notre site web par les visiteurs. Nous avons accepté les dispositions de traitement de données de Google. Par conséquent, nous n'autorisons pas la société Google de se servir des informations reçues par les Google Analytics pour autres services du Google. Nous avons également signé un contrat de traitement de données avec la société Amplitude. Nous masquons autant d’informations que possible. Pour plus de détails, nous vous recommandons d’étudier plus attentivement le <a href='https://amplitude.com/privacy'>Règlement de confidentialité</a> de Google et/ou de la société Amplitude.</p>

        <br />

        <p>Nous avons pris des dispositions avec des tiers qui placent des fichiers cookies sur notre site web. Néanmoins, nous ne pouvons pas assurer le contrôle complet de leurs actions concernant leurs fichiers cookies. C’est pourquoi nous vous prions d’étudier également leurs Règlements de confidentialité. Plus plus d’informations, veuillez consulter le tableau récapitulatif des fichiers cookies dans ce document.</p>

        <h2>Transmission de données personnelles aux tiers</h2>
        <p>Vos données personnelles peuvent être transmises aux spécialistes externes, embauchés par Pylot. Ils auront pour occupation le traitement de données au nom de la société Pylot et assisteront la prestations des services que vous demandez. Un tel exemple sont nos fournisseurs IT. Pylot s’engage à prendre les mesure nécessaires pour protéger vos données personnelles. Pylot obligera ces spécialistes externes à traiter vos données personnelles de manière confidentielle et à prendre les mesures techniques et organisationnelles nécessaires pour protéger vos données personnelles contre toute forme de traitement illicite.</p>

        <br />

        <p>De plus, vos données personnelles peuvent être divulguées à des tiers si Pylot est obligé de le faire par une ordonnance du tribunal ou par toute autre obligation légale ou juridique, ou dans les cas où Pylot croit nécessaire de prévenir ou arrêter des actions illégales, ou en cas de violation de notre Politique de confidentialité.</p>

        <h2>Sécurité</h2>
        <p>Nous nous engageons à faire tout le possible de protéger nos services contre une mauvaise utilisation, un abus et un accès non-autorisé de vos données personnelles. Nous affirmons que seules les personnes autorisées ont accès à vos données personnelles, et nous garantissons que l’accès à ces données est contrôlé.</p>

        <h2>Périodes de stockage et de conservation des données</h2>
        <p>Toutes les données personnelles fournies ou saisies par vous sont stockées en toute sécurité dans une base de données d'Amazon Web Service Europe (Irlande). La base de données Amazon Web Service est une des plateformes cloud les plus sécurisées existantes.</p>

        <h2>Modifications apportées à cette Politique de confidentialité et d’utilisation des fichiers cookies</h2>
        <p>En cas de modifications de ce site web, nous sommes tenus d’apporter ces modifications dans notre Politique de confidentialité et d’utilisation des fichiers cookies. C’est pourquoi nous vous prions de prêter attention à la date de la dernière révision indiquée au début de ce document, et de s’assurer de l’absence de nouvelles versions. Nous nous engageons de vous informer des modifications, en dehors du site web.</p>

        <h2>Vos droits</h2>
        <p>Vous pouvez nous contacter si vous avez des questions concernant le traitement de vos données personnelles ou si vous voulez recevoir des informations sur la nature de vos données personnelles à notre disposition. Nos contacts sont donnés ci-dessous.</p>

        <br />

        <p>En conformité avec les lois relatives à la protection de la confidentialité, vous avez les droits suivants :</p>

        <ul type='circle'>
          <li>le droit de recevoir de <strong>l’information</strong> concernant vos données personnelles à notre disposition et le but de leur utilisation ;</li>
          <li>le droit de <strong>vérifier</strong> vos données personnelles que nous sauvegardons sur vous;</li>
          <li>le droit de <strong>corriger les erreurs</strong>, si les données sont fausses;</li>
          <li>le droit de <strog>transmettre</strog> vos données personnelles;</li>
          <li>le droit d’envoyer une demande de <strong>supprimer</strong> vos données personnelles;</li>
          <li>le droit d’envoyer la demande de <strong>limiter</strong> la transmission de vos données personnelles;</li>
          <li>le droit <strong>d’annuler</strong> votre accord;</li>
          <li>le droit d’exprimer votre <strong>désaccord</strong> avec certaines formes d’utilisation.</li>
        </ul>

        <br />

        <p>Nous vous prions de toujours nous fournir l’information de vous identifiants, pour que nous puissions être sûrs de livrer l’information à la personne concernée, et ne pas modifier ou bien supprimer l’information d’une autre personne.</p>

        <h2>Déposer une plainte</h2>
        <p>En conformité avec les lois relatives à la protection de la confidentialité, vous avez le droit de déposer une plainte concernant le traitement de vos données personnelles auprès d’un organe de surveillance de votre pays. Au Royaume des Pays-Bas, c’est l’organe Autoriteit Persoonsgegevens. Si vous voulez déposer une plainte concernant le traitement de vos données personnelles, nous vous prions de bien vouloir contacter un organe de surveillance de votre pays ou de vous adresser à <a href='https://autoriteitpersoonsgegevens.nl'>Autoriteit Persoonsgegevens</a>.</p>

        <h2>Nos contacts</h2>
        <p>
          Pylot B. V.<br />
          Adresse: Vlotlaan 710, Monster, 2681 TX, The Netherlands (Royaume des Pays-Bas)<br />
          Numéro dans le registre de Commerce et des Sociétés: 69516103<br />
          E-mail adresse: info@pylot.nl<br />
          Numéro du téléphone: +31 174 286 161<br />
        </p>
      </article>
    );
  }
}
