import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './BreakdownTooltipGraph.module.css';


class BreakdownTooltipGraph extends PureComponent {
  static propTypes = {
    lines: PropTypes.array.isRequired,
    isSpaced: PropTypes.bool,
    tooltipTitle: PropTypes.string,
  };

  static defaultProps = {
    tooltipTitle: null,
    isSpaced: false,
  };

  renderLines = lines => lines.map(line => (
    <div key={`tooltipLine-${line.id}`} className={styles.line}>
      <span className={styles.lineHeader}>
        <span className={styles.lineColor} style={line.color ? { background: line.color } : null} />
        {line.header}
        {line.description && (
          <span className={styles.lineSubTitle}>&nbsp;({line.description})</span>
        )}
      </span>
      <span className={styles.lineValue}>{line.value}</span>
    </div>
  ));

  render() {
    const {
      lines, tooltipTitle, isSpaced,
    } = this.props;

    return (
      <div className={classnames({ [styles.spased]: isSpaced })}>
        <div className={styles.tooltipDate}>{tooltipTitle}</div>
        <div>
          {this.renderLines(lines)}
        </div>
      </div>
    );
  }
}

export default BreakdownTooltipGraph;
