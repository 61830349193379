import {
  get, find, isNil, flow
} from 'lodash';

import numbersRounding from '../numbersRounding';
import numbersFormatting from '../numbersFormatting';

/**
 * Достаёт значение из объекта, округляет и форматирует.
 *
 * @param {object} value - Объект со значением и размерностью.
 * @param {number} roundTo - Количество знаков, до скольки округлять.
 * @return {string} Округлённое и отформатированное значение.
 */
export const getValue = (value, roundTo) => {
  const amount = get(value, 'amount');

  if (isNil(amount)) {
    return '–';
  }

  const roundedValue = numbersRounding(amount, 'fixed', roundTo);

  return numbersFormatting(roundedValue);
};

/**
 * Достаёт единицы измерения из объекта, возвращает в нужной локализации.
 *
 * @param {object} value - Объект со значением и размерностью.
 * @param {function} formatMessage - Функция локализации.
 * @param {string} path - Путь до единиц измерения (иногда бывает units, а иногда unit).
 * @return {string} Текст единицы измерения.
 */
export const getUnits = (value, formatMessage, path = 'units') => (get(value, path) ? formatMessage({ id: `cunits.mini.${get(value, path)}` }) : '');


/**
 * Находим точку графика, соответствующую дате (её может и не быть),
 * получаем значение точки и округляем. Если такой точки нет, возращаем прочерк.
 *
 * @param {object} value - Объект со значением и размерностью.
 * @param {object} date - Дата, по которой ищем точку.
 * @param {number} roundTo - Количество знаков, до скольки округлять.
 * @return {string} Округлённое и отформатированное значение.
 */
export const findGetAndRoundingValue = (value, date, roundTo) => numbersRounding(get(find(value, ['x', date]), 'y'), 'fixed', roundTo);

/**
 * Получаем категорию, соответствующую конкретному species.
 *
 * @param {object} harvestCategories - Объект категорий локации.
 * @param {string} currentSpecies - Необходимый species.
 * @return {[object]} Массив категорий конкретного species.
 */
export const getCurrentHarvestCategories = (harvestCategories, currentSpecies) => flow([
  value => get(value, 'data'),
  value => find(value, { species: currentSpecies }),
  value => get(value, 'categories'),
])(harvestCategories);


/**
 * Получаем локализованное имя категории.
 *
 * @param {string} discriminator - Id категории.
 * @param {string} locale - Текущая локаль.
 * @param {object} currentHarvestCategories - Объект категорий конкретного species.
 * @return {[object]} Массив категорий конкретного species.
 */
export const getLocalizedCategoryName = (discriminator, locale, currentHarvestCategories) => flow([
  d => find(d, { id: Number(discriminator) }),
  d => get(d, 'name'),
  d => (get(d, locale) || get(d, 'en'))
])(currentHarvestCategories);
