import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  getIsAddPlantingCycleAvailable,
  getUnrealizedPotentialAccess,
  getCropReportsAccess,
  getIsCropCompareAvailable,
  getCycleId,
} from 'store/company/selectors';
import { getCurrentCycleFullInfo, getCycleFetching } from 'store/newCrops/selectors';
import { getCycleData, trackPageViewPlantingCycle, requestCropsExport } from 'store/newCrops/actions';
import { requestDeletePlantingCycle, trackEditPlantingCycleClick } from 'store/crops/actions';
import { clearHarvestData } from 'store/harvest/actions';
import { removeCycleFromComparison, addCycleToComparison } from 'store/benchmark/actions';
import { getCyclesToCompare } from 'store/benchmark/selectors';

import Cycle from '../components/Cycle';


const mapDispatchToProps = {
  getCycleData,
  trackEditPlantingCycleClick,
  trackPageViewPlantingCycle,
  clearHarvestData,
  requestDeletePlantingCycle,
  removeCycleFromComparison,
  addCycleToComparison,
  requestCropsExport
};

const mapStateToProps = (state, props) => {
  const { intl } = state;
  const { match } = props;

  return {
    ...props,
    ...intl,
    cycleInfo: getCurrentCycleFullInfo(state),
    isCycleFetching: getCycleFetching(state),
    id: getCycleId(state, props),
    tabName: match.params.tabName,
    defaultTab: !match.params.tabName,
    isAddPlantingCycleAvailable: getIsAddPlantingCycleAvailable(state),
    unrealizedPotentialAccess: getUnrealizedPotentialAccess(state, props),
    cropReportsAccess: getCropReportsAccess(state, props),
    cyclesToCompare: getCyclesToCompare(state, props),
    isCropCompareAvailable: getIsCropCompareAvailable(state, props),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cycle));
