import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import checkSpecies from 'helpers/checkSpecies';

import SelectBoxFilter from 'components/SelectBoxFilter';
import Select from 'components/Select';

import styles from './UnitFilter.module.css';

export default class UnitFilter extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    varieties: PropTypes.array.isRequired,
    species: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    currentUnitKind: PropTypes.string,
    currentGrownFilter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    currentSpecies: PropTypes.string,
    currentVarietyId: PropTypes.number,
    currentFruitClassCode: PropTypes.string,
    onFiltersChange: PropTypes.func,
    unitsOptions: PropTypes.array,
    capitalizeTitle: PropTypes.bool,
    selectComponent: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),
    isMobile: PropTypes.bool,
    customPlaceholder: PropTypes.string
  };

  static defaultProps = {
    currentUnitKind: 'absolute',
    currentGrownFilter: null,
    currentSpecies: null,
    currentVarietyId: null,
    currentFruitClassCode: null,
    onFiltersChange: null,
    unitsOptions: null,
    capitalizeTitle: false,
    selectComponent: Select,
    isMobile: false,
    customPlaceholder: null
  };

  state = {
    // open: false,
  };

  setNewSearchPath = (params) => {
    const { onFiltersChange } = this.props;

    if (onFiltersChange) {
      onFiltersChange(params);
    }
  }

  handlerViewByChange = option => this.setNewSearchPath({ unitKind: option.value === 'absolute' ? undefined : option.value });

  render() {
    const {
      intl: { formatMessage },
      varieties,
      fruitClasses,
      species,
      currentUnitKind,
      currentGrownFilter,
      currentSpecies,
      currentVarietyId,
      currentFruitClassCode,
      unitsOptions,
      capitalizeTitle,
      selectComponent,
      isMobile,
      customPlaceholder
    } = this.props;

    const isAllSpecies = !currentGrownFilter;

    const { isHasVegetables, isHasLettuce } = checkSpecies({
      isAllSpecies,
      species,
      varieties,
      fruitClasses,
      currentFruitClassCode,
      currentSpecies,
      currentVarietyId,
    });

    const defaultUnitsOptions = [
      { label: formatMessage({ id: 'dashboards.totalAll' }), value: 'absolute' },
      { label: formatMessage({ id: 'dashboards.perArea' }), value: 'perArea' },
    ];

    if (isHasVegetables) {
      defaultUnitsOptions.push({ label: formatMessage({ id: 'dashboards.perHarvestInKg' }), value: 'perHarvestInKg' });
    }

    if (isHasLettuce) {
      defaultUnitsOptions.push({ label: formatMessage({ id: 'dashboards.perHarvestInCount' }), value: 'perHarvestInCount' });
    }

    return (
      <SelectBoxFilter
        value={currentUnitKind}
        options={unitsOptions || defaultUnitsOptions}
        onChange={this.handlerViewByChange}
        title={formatMessage({ id: 'dashboards.viewByTitle' })}
        classNameWrapper={styles.selectboxFilterWrapper}
        capitalizeTitle={capitalizeTitle}
        selectComponent={selectComponent}
        isMobile={isMobile}
        customPlaceholder={customPlaceholder || (isMobile ? formatMessage({ id: 'dashboards.viewByTitle' }) : null)}
      />
    );
  }
}
