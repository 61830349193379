import { get, sortBy } from 'lodash';
import { connect } from 'react-redux';
import React, { Component, } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as GraphIcon } from './assets/graph.svg';
import { ReactComponent as DeleteIcon } from './assets/delete.svg';
import styles from './SidePanelGraphPresets.module.css';


import { getPresetsSelector } from '../../../store/graphs/selectors';


class SidePanelGraphPresets extends Component {
  static propTypes = {
    intl: intlShape.isRequired,

    graphPresets: PropTypes.array,
    selectedPreset: PropTypes.object,
    handlerChangeGraphPreset: PropTypes.func,
    handlerDeleteGraphPreset: PropTypes.func,
    handlerClose: PropTypes.func,
  };

  static defaultProps = {
    graphPresets: [],
    selectedPreset: null,
    handlerChangeGraphPreset: null,
    handlerDeleteGraphPreset: null,
    handlerClose: null,
  };

  handlerChange = (id) => {
    const { handlerChangeGraphPreset, handlerClose } = this.props;

    handlerChangeGraphPreset(id);
    handlerClose();
  };

  handlerDelete = (id) => {
    const { handlerDeleteGraphPreset } = this.props;

    if (handlerDeleteGraphPreset) {
      handlerDeleteGraphPreset(id);
    }

    return false;
  };

  renderPresets = (selectedPreset, graphPresets) => {
    const { intl: { formatMessage } } = this.props;

    return graphPresets.map((preset) => {
      const isSelected = selectedPreset && preset.id === selectedPreset.id;
      const isCustomPreset = preset.attributes.type === 'custom';

      const deleteProps = {
        onClick: (e) => { this.handlerDelete(preset.id); e.stopPropagation(); },
      };

      const presetName = isCustomPreset ? get(preset, 'attributes.name') : formatMessage({ id: `presets.${preset.id}` });

      return (
        <div
          key={`side-panel-graph-presets-${preset.id}`}
          role='button'
          tabIndex={0}
          className={classnames(styles.preset, { [styles.isSelected]: isSelected })}
          onKeyPress={() => { this.handlerChange(preset.id); }}
          onClick={() => { this.handlerChange(preset.id); }}
        >
          <div
            className={styles.presetHeader}
          >
            <GraphIcon className={styles.graphIcon} />
            <div className={styles.presetName}>{presetName}</div>
          </div>
          {isCustomPreset && (
            <div className={styles.delete} {...deleteProps}>
              <DeleteIcon className={styles.deleteIcon} />
            </div>
          )}
        </div>
      );
    });
  };

  render() {
    const { selectedPreset, graphPresets } = this.props;
    const standartPresets = graphPresets.filter(preset => get(preset, 'attributes.type') === 'standard');
    const customPresets = sortBy(graphPresets.filter(preset => get(preset, 'attributes.type') === 'custom'), 'attributes.name');

    return (
      <div className={styles.content}>
        <div className={styles.presetsHeader}>
          <FormattedMessage id='graphs.multiCompartment' className={styles.presetsHeader} />
        </div>

        {graphPresets && this.renderPresets(selectedPreset, standartPresets)}

        <div className={styles.presetsHeader}>
          <FormattedMessage id='graphs.myReports' className={styles.presetsHeader} />
        </div>

        {graphPresets && this.renderPresets(selectedPreset, customPresets)}
      </div>
    );
  }
}

export default connect((state, props) => ({
  graphPresets: getPresetsSelector(state, props).map(item => ({
    id: item.id,
    type: 'GraphPreset',
    attributes: {
      ...item
    }
  }))
}))(injectIntl(SidePanelGraphPresets));
