
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import classnames from 'classnames';

import TooltipMetric from '../TooltipMetric';

import styles from './TooltipContent.module.css';

const TooltipContent = ({
  intl, tooltipData, className, roundTo
}) => {
  const { formatMessage } = intl;

  let unitsText = formatMessage({ id: `cunits.mini.${tooltipData?.units}` });

  const metricsList = tooltipData?.bars?.reduce((acc, metric) => {
    if (metric.slicedValues) {
      return [
        ...acc,

        ...metric.slicedValues.map(metricSliced => ({
          key: metricSliced?.key,
          value: metricSliced?.value,
          percentValue: metricSliced?.percentValue,
          text: metricSliced?.name,
          color: metricSliced?.color,
          units: unitsText,
        }))
      ];
    }

    if (metric?.units) {
      unitsText = formatMessage({ id: `cunits.mini.${metric?.units}` });
    }

    return [
      ...acc,
      {
        key: metric?.key,
        value: metric?.value,
        percentValue: metric?.percentValue,
        text: metric?.name,
        color: metric?.color,
        units: unitsText,
      }
    ];
  }, []);

  return (
    <div className={classnames(styles.tooltipContent, className)}>
      <div className={styles.productName}>
        {tooltipData?.name}
      </div>
      <div className={styles.harvest}>
        {metricsList.map(metricItem => (
          <TooltipMetric
            key={metricItem.key}
            value={metricItem.value}
            percentValue={metricItem.percentValue}
            units={metricItem.units}
            text={metricItem.text}
            color={metricItem.color}
            roundTo={roundTo}
          />
        ))}
      </div>
    </div>
  );
};

TooltipContent.propTypes = {
  intl: intlShape.isRequired,
  tooltipData: PropTypes.object.isRequired,
  className: PropTypes.string,
  roundTo: PropTypes.number,
};

TooltipContent.defaultProps = {
  className: null,
  roundTo: 0,
};

export default memo(injectIntl(TooltipContent));

