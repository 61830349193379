import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  useParams,
  useHistory,
} from 'react-router-dom';

import { findIndex } from 'lodash';

import useMountEffect from 'hooks/useMountEffect';

import DefaultCircleLoader from 'components/DefaultCircleLoader';
import CropReport from 'components/CropReport';
import CropReportsEmpty from 'components/CropReportsEmpty';

const CropReportsList = ({
  cropReportsAccess,
  reportsList,
  cycleInfo,
  isFetching,
  requestCropReportsList,
}) => {
  const { organizationSlug, cycleId, reportId } = useParams();
  const history = useHistory();
  const list = reportsList?.list;

  /**
 * Check if report id from URL exist and exist in current cycle.
 * If it is not exist, redirect to first report from list
 */
  const firstReportId = list[0]?.id;
  const isIdCorrect = findIndex(list, { id: Number(reportId) }) !== -1;
  const isCycleIdCorrect = Number(cycleId) === Number(reportsList?.plantingCycleId);
  const isResetCurrentId = !reportId || !isIdCorrect || !isCycleIdCorrect;

  useEffect(() => {
    if (firstReportId && isResetCurrentId) {
      history.push(`/${organizationSlug}/crops/${cycleId}/reports/${firstReportId}`);
    }
  }, [history, organizationSlug, cycleId, isResetCurrentId, firstReportId]);


  useMountEffect(() => {
    if (!cropReportsAccess) {
      history.push(`/${organizationSlug}/crops/${cycleId}`);
    } else {
      requestCropReportsList({ plantingCycleId: cycleId });
    }
  });

  if (isFetching) {
    return <DefaultCircleLoader />;
  }

  if (isResetCurrentId) {
    return <CropReportsEmpty />;
  }

  return (
    <CropReport
      reportId={reportId}
      reportsList={list}
      cycleInfo={cycleInfo}
    />
  );
};

CropReportsList.propTypes = {
  cropReportsAccess: PropTypes.bool,
  reportsList: PropTypes.object,
  isFetching: PropTypes.bool,
  cycleInfo: PropTypes.object,
  requestCropReportsList: PropTypes.func.isRequired,
};

CropReportsList.defaultProps = {
  cropReportsAccess: false,
  reportsList: [],
  isFetching: false,
  cycleInfo: undefined,
};

export default CropReportsList;
