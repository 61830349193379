import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './DashboardSectionHeader.module.css';

class DashboardSectionHeader extends Component {
  static propTypes = {
    headerText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]).isRequired,
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.node,
      PropTypes.element
    ]),
    actions: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.element
    ]),
    className: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    actions: null,
    className: null,
  };

  render() {
    const {
      children, headerText, className, actions,
    } = this.props;

    return (
      <div className={classnames(styles.graphHeaderWrapper, className)}>
        <div className={styles.graphHeader}>
          <h2 className={styles.graphHeaderText}>
            {headerText}
          </h2>
          {actions || null}
        </div>
        {children && (
          <div className={styles.graphHeaderContent}>
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default DashboardSectionHeader;
