import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';
import intervalToDuration from 'date-fns/intervalToDuration';
import differenceInSeconds from 'date-fns/differenceInSeconds';

import DefaultBackButton from 'components/DefaultBackButton';
import Typography from 'components/Typography';
import DefaultVerificationInput from 'components/DefaultVerificationInput';
import DefaultLink from 'components/DefaultLink';
import DefaultCircleLoader from 'components/DefaultCircleLoader';

import styles from './LoginVerification.module.css';

const zeroPad = num => String(num).padStart(2, '0');

const LoginVerification = ({
  intl,
  history,
  sendingCodeInfo,
  isFetching,
  numberOfDigits,
  authTicket,
  codeTicket,
  whenRetryIsAvailable,
  whenTicketIsExpired,
  whenCodeIsExpired,
  verifyCodeError,
  requestVerifyCode,
  requestSendingCode,
  clearVerificationErrors,
  setVerificationError,
}) => {
  const { formatMessage } = intl;

  const isTicketExpired = whenTicketIsExpired ? differenceInSeconds(whenTicketIsExpired, new Date()) <= 0 : undefined;

  // Redirect to login page if user without auth or with expired ticket
  if ((!isFetching && !codeTicket) || isTicketExpired) {
    history.push('/login');
  }

  const retryInSecs = whenRetryIsAvailable ? differenceInSeconds(whenRetryIsAvailable, new Date()) : 0;

  const [timeLeft, setTimeLeft] = useState(retryInSecs);

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    // Redirect to login page if user with expired ticket
    if (whenTicketIsExpired && differenceInSeconds(whenTicketIsExpired, new Date()) <= 0) {
      history.push('/login');
    }

    // Redirect to login page if ticket was expired
    if (!verifyCodeError && whenCodeIsExpired && differenceInSeconds(whenCodeIsExpired, new Date()) <= 0) {
      setVerificationError('INVALID_TICKET');
    }

    const intervalId = setInterval(() => {
      setTimer(timer + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [history, whenCodeIsExpired, verifyCodeError, setVerificationError, whenTicketIsExpired, timer]);

  useEffect(() => {
    // Reset (re init) counter then retry is available if it changes
    if (whenRetryIsAvailable) {
      setTimeLeft(differenceInSeconds(whenRetryIsAvailable, new Date()));
    }
  }, [whenRetryIsAvailable]);

  useEffect(() => {
    if (!timeLeft) return () => {};

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const onInputChange = useCallback(() => {
    if (isFetching) {
      return null;
    }

    if (verifyCodeError) {
      clearVerificationErrors();
    }

    return null;
  }, [verifyCodeError, clearVerificationErrors, isFetching]);

  const onInputComplete = useCallback(
    code => requestVerifyCode({ ticket: codeTicket, code }),
    [requestVerifyCode, codeTicket]
  );

  const handlerRequestNewCodeClick = useCallback(() => {
    if (verifyCodeError) {
      clearVerificationErrors();
    }

    requestSendingCode({ ticket: authTicket });
  }, [verifyCodeError, clearVerificationErrors, requestSendingCode, authTicket]);

  const getError = useCallback(() => {
    if (verifyCodeError) {
      switch (verifyCodeError) {
        case 'INVALID_CODE':
          return formatMessage({ id: 'login.invalidCode' });
        case 'TOO_MANY_ATTEMPTS':
          return formatMessage({ id: 'login.tooManyAttempts' });
        case 'INVALID_TICKET':
          return formatMessage({ id: 'login.codeHasExpired' });
        default:
          return formatMessage({ id: 'notification.backendError' });
      }
    }

    return undefined;
  }, [formatMessage, verifyCodeError]);

  const duration = intervalToDuration({ start: 0, end: timeLeft * 1000 });
  const formattedTimeLeft = `${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`;

  const phoneNumberMask = sendingCodeInfo?.data?.recipient;

  return (
    <div className={styles.verificationWrapper}>
      <div className={styles.verificationContainer}>
        <DefaultBackButton
          className={styles.backButton}
          text={formatMessage({ id: 'button.back' })}
          link='/login'
        />
        <Typography
          className={styles.header}
          variant='h2'
        >
          {formatMessage({ id: 'login.enterCodeHeader' })}
        </Typography>
        <DefaultVerificationInput
          onChange={onInputChange}
          onComplete={onInputComplete}
          length={numberOfDigits}
          error={getError(verifyCodeError)}
        />

        {isFetching && (
          <div className={styles.loaderWrapper}>
            <DefaultCircleLoader
              className={styles.circleLoader}
              iconClassName={styles.iconCircleLoader}
            />
          </div>
        )}

        {!isFetching && (
          <>
            <Typography variant='body1' className={classnames(styles.firstParagraph, styles.paragraph)}>
              {`${formatMessage({ id: 'login.codeWasSentTo' })} `}
              <span className={styles.phoneNumberMask}>{phoneNumberMask}</span>
            </Typography>

            {timeLeft ? (
              <Typography variant='body1' className={styles.paragraph}>
                {`${formatMessage({ id: 'login.requestAnotherIn' })} ${formattedTimeLeft}`}
              </Typography>
              )
              : (
                <DefaultLink onClick={handlerRequestNewCodeClick} className={styles.requestNewCodeLink}>
                  {formatMessage({ id: 'login.requestNewCode' })}
                </DefaultLink>
                )}
          </>
        )}

        <DefaultLink href='/login/code/problems'>
          {formatMessage({ id: 'login.didntGetCode' })}
        </DefaultLink>
      </div>
    </div>
  );
};

LoginVerification.propTypes = {
  intl: intlShape.isRequired,
  history: PropTypes.object.isRequired,
  sendingCodeInfo: PropTypes.object,
  isFetching: PropTypes.bool,
  numberOfDigits: PropTypes.number,
  authTicket: PropTypes.string,
  codeTicket: PropTypes.string,
  whenRetryIsAvailable: PropTypes.instanceOf(Date),
  whenTicketIsExpired: PropTypes.instanceOf(Date),
  whenCodeIsExpired: PropTypes.instanceOf(Date),
  verifyCodeError: PropTypes.string,

  requestVerifyCode: PropTypes.func.isRequired,
  requestSendingCode: PropTypes.func.isRequired,
  clearVerificationErrors: PropTypes.func.isRequired,
  setVerificationError: PropTypes.func.isRequired,
};

LoginVerification.defaultProps = {
  sendingCodeInfo: undefined,
  isFetching: false,
  numberOfDigits: 4,
  authTicket: undefined,
  codeTicket: undefined,
  whenRetryIsAvailable: undefined,
  whenTicketIsExpired: undefined,
  whenCodeIsExpired: undefined,
  verifyCodeError: undefined,
};


export default LoginVerification;
