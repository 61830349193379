import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import cn from 'classnames';
import { sortBy, findIndex } from 'lodash';

import { getPlantingCycleExtendedLabel } from 'helpers/getPlantingCycleLabel';
import { parseDate } from 'helpers/datesHelper';

import DefaultSearchList from 'components/DefaultSearchList';

import DoubleCheckIcon from 'components/Icons/DoubleCheckIcon';

import styles from './CropSwitchSearchList.module.css';

const renderIcon = () => (
  <div className={styles.iconWrapper}>
    <DoubleCheckIcon className={styles.doubleCheckIcon} />
  </div>
);

const getOptions = ({
  intl,
  compartments,
  fruitClasses,
  varieties,
  plantingCycles,
}) => plantingCycles.map((cycleItem) => {
  const { formatMessage } = intl;
  const varietyId = cycleItem?.relationships?.variety?.data[0].id;
  const fruitClassId = cycleItem?.relationships?.fruitClass?.data[0].id;
  const speciesId = cycleItem?.attributes?.species;
  const isCompleted = cycleItem?.attributes?.endDate ?
    parseDate(cycleItem?.attributes?.endDate) < new Date()
    :
    false;

  return {
    id: cycleItem?.id,
    name: getPlantingCycleExtendedLabel({
      plantingCycle: cycleItem,
      intl,
      allCompartments: compartments,
      fruitClasses,
      varieties,
      allPlantingCycles: plantingCycles,
      currentFruitClassCode: fruitClassId,
      currentVarietyId: varietyId,
      currentSpecies: speciesId,
      currentBreakdown: 'fruitClass',
      isPlantingCycleDatesAsTimestamp: false,
      withFruitClass: true,
    }),
    groupId: speciesId,
    groupName: formatMessage({ id: `plantingCycles.speciesPlural.${speciesId}` }),
    icon: isCompleted ? renderIcon() : undefined,
  };
});

const getSortedOptions = (formatMessage, sortedPlantingCycles, groupsOfOptions) => {
  const sortedGroups = groupsOfOptions.sort((a, b) => {
    const aSpecies = formatMessage({ id: `plantingCycles.speciesPlural.${a.groupId}` });
    const bSpecies = formatMessage({ id: `plantingCycles.speciesPlural.${b.groupId}` });
    return aSpecies.localeCompare(bSpecies);
  });

  const sortedOptions = sortedGroups.map(group => ({
    ...group,
    optionsArray: sortBy(group.optionsArray, item => findIndex(sortedPlantingCycles, { id: item.id })),
  }));

  return sortedOptions;
};

const CropSwitchSearchList = ({
  intl,
  cycleId,
  compartments,
  fruitClasses,
  varieties,
  plantingCycles,
  sortedPlantingCycles,
  isMobileVersion,
  handlerClickCycle,
}) => {
  const { formatMessage } = intl;

  const [query, setQuery] = useState('');

  const listOfOptions = getOptions({
    intl,
    compartments,
    fruitClasses,
    varieties,
    plantingCycles,
  });

  const selectedOption = listOfOptions.find(item => item?.id === cycleId)?.id;

  return (
    <DefaultSearchList
      contentClassName={cn(styles.searchListContent, { [styles.searchListContentMobile]: isMobileVersion })}
      classNameOption={cn(styles.option, { [styles.onMobile]: isMobileVersion })}
      controlClassName={cn(styles.control, { [styles.onMobile]: isMobileVersion })}
      groupNameClassName={cn(styles.groupName, { [styles.onMobile]: isMobileVersion })}
      placeholder={formatMessage({ id: 'benchmarking.search' })}
      options={listOfOptions}
      selectedOption={selectedOption}
      value={query}
      empty={formatMessage({ id: 'graphs.sidePanel.noSearchResult' })}
      onChanged={({ value }) => setQuery(value)}
      onSelected={handlerClickCycle}
      whiteSpaceOptions='normal'
      getSortedOptions={
        groupsOfOptions => getSortedOptions(formatMessage, sortedPlantingCycles, groupsOfOptions)
      }
      noClearOnSelect={isMobileVersion}
    />
  );
};

CropSwitchSearchList.propTypes = {
  intl: intlShape.isRequired,
  cycleId: PropTypes.number.isRequired,
  compartments: PropTypes.array.isRequired,
  fruitClasses: PropTypes.array.isRequired,
  varieties: PropTypes.array.isRequired,
  plantingCycles: PropTypes.array.isRequired,
  sortedPlantingCycles: PropTypes.array,
  isMobileVersion: PropTypes.bool,
  handlerClickCycle: PropTypes.func.isRequired,
};

CropSwitchSearchList.defaultProps = {
  sortedPlantingCycles: [],
  isMobileVersion: false,
};

export default CropSwitchSearchList;
