import moment from 'moment-timezone';
import { get } from 'lodash';
import queryString from 'query-string';
import { createSelector } from 'reselect';

export const getClimateIncidents = state => state.climateInsights.climateIncidents;
export const getClimateIncidentsForGraphs = state => state.climateInsights.climateIncidentsForGraphs;
export const getClimateIncidentsFetching = state => state.climateInsights.isClimateIncidentsFetching;
export const getClimateIncidentsForGraphsFetching = state => state.climateInsights.isClimateIncidentsForGraphsFetching;
export const getEditedAbnormalityIncident = state => state.climateInsights.editedAbnormalityIncident;
export const getEditedAbnormalityIncidentStatus = state => state.climateInsights.editedAbnormalityIncidentStatus;
export const getIsAddAbnormalityIncidentsMode = state => state.climateInsights.isAddAbnormalityIncidentsMode;
export const getIsUpdateAbnormalityIncidentsMode = state => state.climateInsights.isUpdateAbnormalityIncidentsMode;
export const getIsDeleteAbnormalityIncidentsMode = state => state.climateInsights.isDeleteAbnormalityIncidentsMode;

export const getCreateAbnormalityIncidentFetching = state => state.climateInsights.isCreateAbnormalityIncidentFetching;
export const getCheckAbnormalityIncidentFetching = state => state.climateInsights.isCheckAbnormalityIncidentFetching;
export const getUpdateAbnormalityIncidentFetching = state => state.climateInsights.isUpdateAbnormalityIncidentFetching;
export const getDeleteAbnormalityIncidentFetching = state => state.climateInsights.isDeleteAbnormalityIncidentFetching;

export const getClimateIncidentsDetail = state => state.climateInsights.climateIncidentsDetail;
export const getClimateIncidentsDetailFetching = state => state.climateInsights.isClimateIncidentsDetailFetching;

export const getRouteLocation = (state, props) => props.location;

export const getRouteQueryParams = createSelector([getRouteLocation], location => queryString.parse(get(location, 'search')));

export const getPeriodStart = createSelector(
  [getRouteQueryParams],
  query => get(query, 'anyDateOfPeriodStart'),
);

export const getPeriodEnd = createSelector(
  [getRouteQueryParams],
  query => get(query, 'anyDateOfPeriodEnd'),
);

export const getPeriodType = createSelector(
  [getRouteQueryParams],
  query => get(query, 'periodType'),
);

export const getClimateIncidentsEmpty = createSelector(
  [getClimateIncidents, getClimateIncidentsFetching],
  (climateIncidents, isClimateIncidentsFetching) => get(climateIncidents, 'compartmentInfos', []).length === 0 && !isClimateIncidentsFetching,
);

export const getClimateIncidentsChartItems = createSelector(
  [getClimateIncidentsForGraphs, getPeriodStart, getPeriodEnd, getPeriodType],
  (climateIncidentsForGraphs, periodStart, periodEnd, periodType) => {
    if (!periodType || !periodStart || !periodEnd) {
      return [];
    }

    const datePointsMap = get(climateIncidentsForGraphs, 'numOfIncidentsGraph.pointsBySeverity', {});

    const periodLength = 'day';

    const startDate = moment.utc(periodStart, 'YYYY-MM-DD');
    const endDate = moment.utc(periodEnd, 'YYYY-MM-DD');

    const dateDiff = endDate.diff(startDate, periodLength) + 1;

    return new Array(dateDiff).fill(true).map((k, i) => {
      const date = startDate.clone().add(i, periodLength);

      const datePoint = +date / 60 / 1000;

      const high = get(datePointsMap, `high.${datePoint}`, 0);
      const middle = get(datePointsMap, `middle.${datePoint}`, 0);
      const low = get(datePointsMap, `low.${datePoint}`, 0);
      const potential = get(datePointsMap, `potential.${datePoint}`, 0);

      return {
        date,
        value: high + middle + low + potential,
        high,
        middle,
        low,
        potential,
      };
    });
  },
);
