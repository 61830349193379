import ruLocale from 'd3-time-format/locale/ru-RU.json';
import nlLocale from 'd3-time-format/locale/nl-NL.json';
import frLocale from 'd3-time-format/locale/fr-FR.json';
import znLocale from 'd3-time-format/locale/zh-CN.json';

export const CUSTOM_RU_LOCALE = {
  ...ruLocale,
  months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
};

/**
 * Возвращает локализацию для шкалы времени d3
 *
 * @param {string} locale - Текущая локализация.
 * @return {object || null} Объект локали для d3-time-format.
 */
const getD3Locale = (locale) => {
  switch (locale) {
    case 'ru':
      return CUSTOM_RU_LOCALE;
    case 'nl':
      return nlLocale;
    case 'fr':
      return frLocale;
    case 'zn':
    case 'zh-cn':
      return znLocale;
    default:
      return null;
  }
};

export default getD3Locale;
