import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';

import DefaultDialog from 'components/DefaultDialog';
import BigButton from 'components/BigButton';
import Typography from 'components/Typography';
import VarietySearchSelect from 'components/VarietySearchSelect';
import CompartmentFilter from 'components/DashboardComplexFilters/components/CompartmentFilter';
import BigSelectInput from 'components/BigSelectInput';

import prepareProductGroupParams from 'helpers/prepareProductGroupParams';

import styles from './HarvestForecastModalExport.module.css';

const filterForecastPlantingData = (forecastData, data, key) => {
  if (!forecastData?.manualForecast) {
    return data;
  }
  const {
    manualForecast
  } = forecastData;

  const ids = manualForecast
    .map(pcItem => pcItem?.product?.payload?.[key]?.id)
    .filter(item => item !== null);

  return data.filter(item => ids.includes(item.id));
};

const HarvestForecastModalExport = ({
  intl,
  onClose,
  onSubmit,

  filters,
  forecastData,
  varieties,
  fruitClasses,
  compartments,

  onFiltersChange,
  changeReportFilters,
  clearGraphKeys,
  // versionId,
  // forecastId,
}) => {
  const { formatMessage } = intl;

  const handlerClickCancel = () => {
    onClose();
  };
  const handlerClickExport = () => {
    onSubmit();
    onClose();
  };

  const handlerVarietyChange = (options) => {
    const { species, varietyId, fruitClassCode } = options;
    const filterParams = prepareProductGroupParams(varieties, species, varietyId, fruitClassCode);
    changeReportFilters(filterParams);
    onFiltersChange(filterParams);
  };

  const handlerBreakdownChange = useCallback((options) => {
    const payload = {
      breakdown: options.value
    };
    changeReportFilters(payload);
    onFiltersChange(payload);
    clearGraphKeys();
  }, [changeReportFilters, onFiltersChange, clearGraphKeys]);

  const handlerCompartmentChange = useCallback((options) => {
    changeReportFilters(options);
    onFiltersChange(options);
  }, [changeReportFilters, onFiltersChange]);

  const handlerUnitsChange = useCallback((options) => {
    const payload = {
      relative: options.value === 'kilogramPerSquareMeter'
    };
    changeReportFilters(payload);
    onFiltersChange(payload);
  }, [changeReportFilters, onFiltersChange]);

  const handlerPeriodChange = useCallback((options) => {
    const payload = {
      period: options.value
    };
    changeReportFilters(payload);
    onFiltersChange(payload);
  }, [changeReportFilters, onFiltersChange]);

  const searchVarieties = useMemo(() =>
    filterForecastPlantingData(forecastData, varieties, 'varietyRef'),
  [forecastData, varieties]);

  const searchFruitClasses = useMemo(() =>
    filterForecastPlantingData(forecastData, fruitClasses, 'fruitClassRef'),
  [forecastData, fruitClasses]);

  const searchCompartments = useMemo(() =>
    filterForecastPlantingData(forecastData, compartments, 'compartmentRef'),
  [forecastData, compartments]);

  const breakdownOptions = [
    { label: formatMessage({ id: 'dashboards.compartments' }), value: 'compartment' },
    { label: formatMessage({ id: 'dashboards.varieties' }), value: 'varietyWeight' },
    { label: formatMessage({ id: 'dashboards.types' }), value: 'fruitClass' },
    { label: formatMessage({ id: 'dashboards.plantingCycles' }), value: 'plantingCycle' },
  ];

  const unitsOptions = [
    { label: formatMessage({ id: 'cunits.mini.kilogramPerSquareMeter' }), value: 'kilogramPerSquareMeter' },
    { label: formatMessage({ id: 'cunits.mini.kilogram' }), value: 'kilogram' },
  ];

  const periodOptions = useMemo(() => [
    { label: formatMessage({ id: 'dashboards.allTime' }), value: 'all' },
    { label: formatMessage({ id: 'dashboards.today' }), value: 'today' },
    { label: formatMessage({ id: 'dashboards.yesterday' }), value: 'yesterday' },
  ], [formatMessage]);

  return (
    <DefaultDialog
      title={formatMessage({ id: 'forecast.dashboard.menu.exportXLSX' })}
      onClose={onClose}
      wrapperClassName={styles.layout}
      className={styles.dialog}
    >
      <div className={classnames(styles.body)}>
        <div className={styles.window}>
          <div className={styles.contentWrapper}>
            <Typography className={styles.description} variant='subtitle2'>
              {formatMessage({ id: 'forecast.exportDesc' })}
            </Typography>
            <div className={styles.filters}>
              <VarietySearchSelect
                className={styles.filterWrapper}
                isShowAllSpecies={false}
                enabledSpecies={[forecastData?.species]}
                onSelectOption={handlerVarietyChange}
                varieties={searchVarieties}
                fruitClasses={searchFruitClasses}
                selectedOption={filters.varietyId || filters.fruitClassCode || filters.species || forecastData?.species}
                classNameButton={styles.varietySelect}
                customPlaceholder={formatMessage({ id: 'header.links.input' })}
                isDefaultSelect
              />
              <BigSelectInput
                className={styles.filterWrapper}
                options={breakdownOptions}
                value={filters.breakdown}
                placeholder={formatMessage({ id: 'plans.groupBy' })}
                onChange={handlerBreakdownChange}
                labelPath='label'
                valuePath='value'
                closeOnChange
                textEllipsis
              />
              <CompartmentFilter
                classNameWrapper={styles.filterWrapper}
                className={styles.compartmentFilter}
                intl={intl}
                compartments={searchCompartments}
                compartmentId={filters.compartmentId}
                disableUseQuery
                onFiltersChange={handlerCompartmentChange}
                selectComponent={BigSelectInput}
                customPlaceholder={formatMessage({ id: 'dashboards.compartments' })}
                noTitle
                isTitleEnabled
              />
              <BigSelectInput
                className={styles.filterWrapper}
                options={periodOptions}
                value={filters.period}
                placeholder={formatMessage({ id: 'forecast.dashboard.filters.period' })}
                onChange={handlerPeriodChange}
                labelPath='label'
                valuePath='value'
                closeOnChange
                textEllipsis
              />
              <BigSelectInput
                className={styles.filterWrapper}
                options={unitsOptions}
                value={filters.relative === true ? 'kilogramPerSquareMeter' : 'kilogram'}
                placeholder={formatMessage({ id: 'forecast.dashboard.filters.units' })}
                onChange={handlerUnitsChange}
                labelPath='label'
                valuePath='value'
                closeOnChange
                textEllipsis
              />
            </div>
            <div className={styles.actions}>
              <BigButton
                className={classnames(styles.dontSaveButton)}
                title={formatMessage({ id: 'forecast.dashboard.publishPopup.cancelButton' })}
                onClick={handlerClickCancel}
                theme='light'
              />
              <BigButton
                className={classnames(styles.saveButton)}
                title={formatMessage({ id: 'forecast.export' })}
                onClick={handlerClickExport}
                theme='dark'
              />
            </div>
          </div>
        </div>
      </div>
    </DefaultDialog>
  );
};

HarvestForecastModalExport.propTypes = {
  intl: intlShape.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  varieties: PropTypes.array,
  fruitClasses: PropTypes.array,
  compartments: PropTypes.array,
  filters: PropTypes.object,
  forecastData: PropTypes.object,
  changeReportFilters: PropTypes.func,
  onFiltersChange: PropTypes.func.isRequired,
  clearGraphKeys: PropTypes.func.isRequired,
  // forecastId: PropTypes.number,
  // versionId: PropTypes.number,
};

HarvestForecastModalExport.defaultProps = {
  fruitClasses: null,
  compartments: null,
  varieties: null,
  filters: null,
  forecastData: null,
  changeReportFilters: () => {},
};

export default HarvestForecastModalExport;
