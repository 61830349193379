import { handleActions } from 'redux-actions';

import update from 'immutability-helper';
import { get } from 'lodash';

import {
  requestCropsData,
  receiveCropsData,
  setCropsVisibility,
  setCropsSorting,
  requestCycleData,
  receiveCycleData
} from './actions';
import { requestClearHarvestData, receiveHarvestData } from '../harvest/actions';

const initialState = {
  isCropsFetching: true,
  locationCycles: [],
  visibilityFilter: 'SHOW_ACTIVE',
  cropsSorting: null,

  isCycleFetching: true,
  cycleInfo: null,
};

export default handleActions({
  [requestCropsData](state) {
    return { ...state, isCropsFetching: true, };
  },
  [receiveCropsData](state, action) {
    return {
      ...state,
      isCropsFetching: false,
      locationCycles: action.payload.data,
    };
  },
  [setCropsVisibility](state, action) {
    return { ...state, visibilityFilter: action.payload, };
  },
  [setCropsSorting](state, action) {
    return { ...state, cropsSorting: action.payload, };
  },
  [requestCycleData](state) {
    return {
      ...state,
      isCycleFetching: true,
      cycleInfo: null,
    };
  },
  [receiveCycleData](state, action) {
    return {
      ...state,
      isCycleFetching: false,
      cycleInfo: action.payload,
    };
  },
  [receiveHarvestData](state, action) {
    if (!state.cycleInfo) {
      return { ...state };
    }

    return update(state, {
      cycleInfo: {
        totalHarvest: {
          value: oldValue =>
            // Такой сложный update нужен, т.к. harvest и totalHarvest имеют разную структуру
            update(oldValue || {}, {
              $merge: { amount: get(action, 'payload.harvest.total') }
            })
        }
      }
    });
  },
  [requestClearHarvestData](state) {
    return { ...state, cycleInfo: null };
  },
}, initialState);
