import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import moment from 'moment-timezone';
import classnames from 'classnames';
import { get, find } from 'lodash';
import ReactTooltip from 'react-tooltip';

import numbersRounding from 'helpers/numbersRounding';
import numbersFormatting from 'helpers/numbersFormatting';

import DefaultCircleLoader from 'components/DefaultCircleLoader';
import Legend from 'components/Legend';
import BarChart from 'components/BarChart';
import Speedometer from '../Speedometer';

import styles from './OverallGroup.module.css';

class OverallGroup extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    organization: PropTypes.object.isRequired,
    speciesName: PropTypes.string.isRequired,
    anyDateOfPeriod: PropTypes.string.isRequired,
    isCurrentPeriod: PropTypes.bool.isRequired,
    periodType: PropTypes.string.isRequired,
    overallData: PropTypes.object,
    units: PropTypes.string.isRequired,
    speciesCount: PropTypes.number.isRequired,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    overallData: null,
    isFetching: false,
  };

  componentDidUpdate() {
    // Решение не совсем понятного бага https://github.com/wwayne/react-tooltip/issues/226
    ReactTooltip.rebuild();
  }

  getPreparedPoints = (points, name, color, units) => Object.keys(points).reduce((acc, key) => ([...acc, {
    name,
    color,
    cunit: units,
    date: moment.utc(new Date((+key) * 60000)),
    value: points[key],
  }]), []);

  renderSpeedometer = (overallData, units, isCurrentPeriod) => {
    const { intl: { formatMessage }, periodType } = this.props;

    const percent = get(overallData, 'gauge.percentage.amount');
    const color = get(overallData, 'gauge.color');

    const currentValue = get(overallData, 'gauge.actual.amount');
    const finalValue = get(overallData, 'gauge.planned.amount');
    const expectedValue = get(overallData, 'gauge.expected.amount');

    const isMonthPeriod = periodType === 'month';
    const periodText = isMonthPeriod ?
      formatMessage({ id: 'kpiDashboard.monthTargets' })
      :
      formatMessage({ id: 'kpiDashboard.yearTargets' });

    return (
      <>
        <div className={styles.targetsHeader}>{periodText}</div>
        <div className={styles.targetsContent}>
          <Speedometer
            percent={percent}
            color={color}
            units={units}
            currentValue={currentValue}
            finalValue={finalValue}
            expectedValue={expectedValue}
            isCurrentPeriod={isCurrentPeriod}
          />
        </div>
      </>
    );
  };

  renderMultichart = () => {
    const {
      intl,
      organization,
      overallData,
      anyDateOfPeriod,
      isFetching,
      units,
    } = this.props;

    const { formatMessage } = intl;

    const points = get(find(get(overallData, 'dataPoints'), { key: 'actual' }), 'dataPoints') || {};
    const plannedPoints = get(find(get(overallData, 'dataPoints'), { key: 'planned' }), 'dataPoints') || {};

    const harvestedLoc = formatMessage({ id: 'kpiDashboard.harvested' });
    const harvestTargetLoc = formatMessage({ id: 'kpiDashboard.harvestTarget' });

    const preparedPoints = this.getPreparedPoints(points, harvestedLoc, '#1DBADF', units);
    const preparedPlannedPoints = this.getPreparedPoints(plannedPoints, harvestTargetLoc, '#003C68', units);

    const tooltipItems = [...preparedPlannedPoints, ...preparedPoints].map(item => ({
      ...item,
      value: numbersFormatting(numbersRounding(item.value, 'fixed', 0)),
    }));

    const startDate = moment(anyDateOfPeriod, 'YYYY-MM-DD').startOf('year');
    const endDate = moment(anyDateOfPeriod, 'YYYY-MM-DD').endOf('year');

    const legend = [{
      color: '#1DBADF',
      name: harvestedLoc,
      id: 'harvested-legend'
    }, {
      color: '#003C68',
      name: harvestTargetLoc,
      id: 'harvested-target'
    }];

    return (
      <>
        <div className={styles.targetsHeader}>{formatMessage({ id: 'kpiDashboard.monthToMonthTargets' })}</div>
        <div className={classnames(styles.targetsContent, styles.targetsContentChart)}>
          <BarChart
            items={preparedPoints}
            dashItems={preparedPlannedPoints}
            tooltipItems={tooltipItems}
            startDate={startDate}
            endDate={endDate}
            location={organization}
            intl={intl}
            isFetching={isFetching}
            isEmpty={preparedPoints.length === 0}
            fixedHeight={224}
            displayType='TWO_BARS'
          />
        </div>
        <Legend className={styles.legend} lines={legend} />
      </>
    );
  };

  render() {
    const {
      intl: { formatMessage },
      speciesName,
      overallData,
      units,
      isFetching,
      isCurrentPeriod,
      speciesCount,
    } = this.props;

    return (
      <div className={styles.overall}>
        {speciesCount > 1 && (
          <div className={styles.speciesName}>
            {formatMessage({ id: `plantingCycles.speciesPlural.${speciesName}` })}
          </div>
        )}
        <div className={styles.targetsContainer}>
          <div className={classnames(styles.targets, styles.targetsMonth)}>
            {isFetching && <DefaultCircleLoader />}
            {this.renderSpeedometer(overallData, units, isCurrentPeriod)}
          </div>
          <div className={classnames(styles.targets, styles.targetsMonthToMonth)}>
            {this.renderMultichart()}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(OverallGroup);
