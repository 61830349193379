import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { get } from 'lodash';

import {
  receiveGetClimateIncidents,
  requestGetClimateIncidents,
  receiveGetClimateIncidentsForGraphs,
  requestGetClimateIncidentsForGraphs,
  receiveGetClimateIncidentsDetail,
  requestGetClimateIncidentsDetail,
  receiveSetClimateIncidentsDay,
  requestSetClimateIncidentsDay,
  requestCreateAbnormalityIncident,
  receiveCreateAbnormalityIncident,
  requestCheckAbnormalityIncident,
  receiveCheckAbnormalityIncident,
  requestUpdateAbnormalityIncident,
  receiveUpdateAbnormalityIncident,
  requestDeleteAbnormalityIncident,
  receiveDeleteAbnormalityIncident,
  setEditedAbnormalityIncident,
  setIsAddAbnormalityIncidentsMode,
  setIsUpdateAbnormalityIncidentsMode,
  setIsDeleteAbnormalityIncidentsMode,
  clearClimateIncidents,
} from './actions';


const initialState = {
  isClimateIncidentsFetching: false,
  isClimateIncidentsForGraphsFetching: false,
  climateIncidents: null,
  climateIncidentsForGraphs: null,
  isClimateIncidentsDetailFetching: {},
  climateIncidentsDetail: {},
  isSetClimateIncidentsDayFetching: false,
  isCheckAbnormalityIncidentFetching: false,
  isCreateAbnormalityIncidentFetching: false,
  isUpdateAbnormalityIncidentFetching: false,
  isDeleteAbnormalityIncidentFetching: false,
  editedAbnormalityIncident: null,
  editedAbnormalityIncidentStatus: 'create',
  isAddAbnormalityIncidentsMode: false,
  isUpdateAbnormalityIncidentsMode: false,
  isDeleteAbnormalityIncidentsMode: false,
};

export default handleActions({
  [requestSetClimateIncidentsDay](state) {
    return { ...state, isClimateIncidentsFetching: true, };
  },

  [receiveSetClimateIncidentsDay](state, action) {
    const chunk = {
      isClimateIncidentsFetching: false,
    };

    const { climateIncidents, climateIncidentsDetail } = state;
    const { payload } = action;

    if (payload) {
      const { compartmentId, day, status } = payload;

      const compartmentInfos = get(climateIncidents, 'compartmentInfos', []);
      const compartmentIndex = compartmentInfos.findIndex(compartmentInfo => compartmentInfo.compartment.id === compartmentId);

      if (status === 'good') {
        if (compartmentIndex > -1) {
          chunk.climateIncidents = update(climateIncidents, {
            compartmentInfos: {
              [compartmentIndex]: {
                goodDays: {
                  $push: [day],
                },
              },
            },
          });
        }

        if (get(climateIncidentsDetail, compartmentId)) {
          chunk.climateIncidentsDetail = update(climateIncidentsDetail, {
            [compartmentId]: {
              climateIncidents: {
                compartmentInfos: {
                  0: {
                    goodDays: {
                      $push: [day],
                    },
                  },
                },
              }
            }
          });
        }
      }

      if (status === 'unprocessed') {
        if (compartmentIndex > -1) {
          const goodDays = get(compartmentInfos, `${compartmentIndex}.goodDays`, []);
          const badDays = get(compartmentInfos, `${compartmentIndex}.badDays`, []);
          const goodDayIndex = goodDays.findIndex(goodDay => goodDay === day);
          const badDayIndex = badDays.findIndex(badDay => badDay.date === day);

          if (goodDayIndex > -1) {
            chunk.climateIncidents = update(climateIncidents, {
              compartmentInfos: {
                [compartmentIndex]: {
                  goodDays: {
                    $splice: [[goodDayIndex, 1]],
                  },
                },
              },
            });
          }

          if (badDayIndex > -1) {
            chunk.climateIncidents = update(climateIncidents, {
              compartmentInfos: {
                [compartmentIndex]: {
                  badDays: {
                    $splice: [[badDayIndex, 1]],
                  },
                },
              },
            });
          }
        }

        if (get(climateIncidentsDetail, compartmentId)) {
          const goodDays = get(climateIncidentsDetail, `${compartmentId}.climateIncidents.compartmentInfos.0.goodDays`, []);
          const badDays = get(climateIncidentsDetail, `${compartmentId}.climateIncidents.compartmentInfos.0.badDays`, []);
          const goodDayIndex = goodDays.findIndex(goodDay => goodDay === day);
          const badDayIndex = badDays.findIndex(badDay => badDay.date === day);

          if (goodDayIndex > -1) {
            chunk.climateIncidentsDetail = update(climateIncidentsDetail, {
              [compartmentId]: {
                climateIncidents: {
                  compartmentInfos: {
                    0: {
                      goodDays: {
                        $splice: [[goodDayIndex, 1]],
                      },
                    },
                  },
                },
              },
            });
          }

          if (badDayIndex > -1) {
            chunk.climateIncidentsDetail = update(climateIncidentsDetail, {
              [compartmentId]: {
                climateIncidents: {
                  compartmentInfos: {
                    0: {
                      badDays: {
                        $splice: [[badDayIndex, 1]],
                      },
                    },
                  },
                },
              },
            });
          }
        }
      }
    }

    return { ...state, ...chunk };
  },

  [requestGetClimateIncidents](state) {
    return { ...state, isClimateIncidentsFetching: true, };
  },

  [receiveGetClimateIncidents](state, action) {
    return {
      ...state,
      isClimateIncidentsFetching: false,
      climateIncidents: action.payload.climateIncidents,
    };
  },

  [clearClimateIncidents]() {
    return { ...initialState };
  },

  [requestGetClimateIncidentsForGraphs](state) {
    return { ...state, isClimateIncidentsForGraphsFetching: true, };
  },

  [receiveGetClimateIncidentsForGraphs](state, action) {
    return {
      ...state,
      isClimateIncidentsForGraphsFetching: false,
      climateIncidentsForGraphs: action.payload.climateIncidents,
    };
  },

  [requestGetClimateIncidentsDetail](state, action) {
    return {
      ...state,
      isClimateIncidentsDetailFetching: {
        ...state.isClimateIncidentsDetailFetching,
        [action.payload.compartmentId]: true,
      },
    };
  },

  [receiveGetClimateIncidentsDetail](state, action) {
    return {
      ...state,
      isClimateIncidentsDetailFetching: {
        ...state.isClimateIncidentsDetailFetching,
        [action.payload.compartmentId]: false,
      },
      climateIncidentsDetail: {
        ...state.climateIncidentsDetail,
        [action.payload.compartmentId]: {
          periodStart: action.payload.periodStart,
          periodEnd: action.payload.periodEnd,
          climateIncidents: action.payload.climateIncidents,
        },
      },
    };
  },

  [requestCreateAbnormalityIncident](state) {
    return { ...state, isCreateAbnormalityIncidentFetching: true, };
  },

  [receiveCreateAbnormalityIncident](state) {
    return {
      ...state,
      isCreateAbnormalityIncidentFetching: false,
      editedAbnormalityIncidentStatus: 'check',
    };
  },

  [requestCheckAbnormalityIncident](state) {
    return { ...state, isCheckAbnormalityIncidentFetching: true, };
  },

  [receiveCheckAbnormalityIncident](state, action) {
    return {
      ...state,
      isCheckAbnormalityIncidentFetching: false,
      editedAbnormalityIncidentStatus: action.payload.status,
      editedAbnormalityIncident: action.payload.incident,
    };
  },

  [requestUpdateAbnormalityIncident](state) {
    return { ...state, isUpdateAbnormalityIncidentFetching: true, };
  },

  [receiveUpdateAbnormalityIncident](state) {
    return { ...state, isUpdateAbnormalityIncidentFetching: false, };
  },

  [requestDeleteAbnormalityIncident](state) {
    return { ...state, isDeleteAbnormalityIncidentFetching: true, };
  },

  [receiveDeleteAbnormalityIncident](state) {
    return { ...state, isDeleteAbnormalityIncidentFetching: false, };
  },

  [setEditedAbnormalityIncident](state, action) {
    return {
      ...state,
      editedAbnormalityIncident: action.payload.editedAbnormalityIncident,
      editedAbnormalityIncidentStatus: 'create',
    };
  },

  [setIsAddAbnormalityIncidentsMode](state, action) {
    return {
      ...state,
      isAddAbnormalityIncidentsMode: action.payload.isAddAbnormalityIncidentsMode,
      editedAbnormalityIncident: null,
    };
  },
  [setIsUpdateAbnormalityIncidentsMode](state, action) {
    return { ...state, isUpdateAbnormalityIncidentsMode: action.payload.isUpdateAbnormalityIncidentsMode, };
  },
  [setIsDeleteAbnormalityIncidentsMode](state, action) {
    return { ...state, isDeleteAbnormalityIncidentsMode: action.payload.isDeleteAbnormalityIncidentsMode, };
  },
}, initialState);
