import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router';

import CloseIcon from 'components/Icons/Close';
import PlansSummary from 'components/PlansSummary';

import Timeline from 'components/CanvasTimeline/components/Timeline';
import HorizontalTimeline from 'components/CanvasTimeline/components/HorizontalTimeline';
import TimelineHUD from 'components/CanvasTimeline/components/TimelineHUD';
import LeftPanel from 'components/CanvasTimeline/components/LeftPanel';

import ReactCanvas from 'components/CanvasTimeline/components/ReactCanvas';
import { HEADER_HEIGHT, LEFT_PANEL_WIDTH } from 'components/CanvasTimeline/helpers/constants';
import CircleLoader from 'components/CircleLoader';
import loaderStyles from 'components/CircleLoader/CircleLoader.module.css';

import { API_DATE_FORMAT } from 'helpers/defaultDates';
import {
  getColWidth
} from './helpers/TimeLineUtils';

import { drawRootComponent } from './helpers/DrawUtils';

import CanvasComponent from './components/CanvasComponent';

import styles from './CanvasTimeline.module.css';

class CanvasTimeline extends PureComponent {
  static propTypes = {
    thickLines: PropTypes.object.isRequired,
    descriptor: PropTypes.array.isRequired,
    onTimelineDateChanged: PropTypes.func.isRequired,
    onPrevYearClick: PropTypes.func.isRequired,
    onNextYearClick: PropTypes.func.isRequired,
    onCurrentWeekClick: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    isFetching: PropTypes.bool,
    // timelineDate: PropTypes.string,
  };

  static defaultProps = {
    isFetching: false,
    // timelineDate: null
  };

  state = {
    isShowSummary: false,
    viewport: null,
  }

  mouseState = {
    isDragging: false,
    origin: { x: 0, y: 0 },
    translation: { x: 0, y: 0 },
    position: { x: 0, y: 0 },
    isPress: false
  };

  timelineState = {
    position: { x: 0, y: 0 },
    currentItem: null,
  };

  timeLineComponent = null;

  buttonContainer = null;

  leftPanelComponent = null;

  horizontalTimelineComponent = null;

  rootContainer = new CanvasComponent({
    position: { x: 0, y: 0 }
  });

  componentDidMount() {
    this.initComponents();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      descriptor: prevDescriptor,
      thickLines: prevThickLines
    } = prevProps;

    const {
      descriptor,
      thickLines
    } = this.props;

    const {
      viewport: prevViewport
    } = prevState;

    const {
      viewport
    } = this.state;

    // First initialization
    if (descriptor && thickLines && viewport && !prevViewport) {
      this.initComponents();
    }

    if (prevDescriptor && prevThickLines &&
      descriptor !== prevDescriptor && prevThickLines !== thickLines &&
      viewport) {
      this.updateComponentsDataFromProps();
      this.updateComponentView(viewport);
      this.initFirstTimelineScroll();
    }

    if (prevDescriptor && prevThickLines &&
      descriptor === prevDescriptor && prevThickLines === thickLines &&
      prevViewport !== viewport && viewport && prevViewport) {
      this.updateComponentView(viewport);
    }
  }

  initComponents = () => {
    const {
      intl,
      match,
      descriptor,
      thickLines,
    } = this.props;

    const { viewport } = this.state;

    if (!descriptor || !thickLines || !viewport) {
      return;
    }

    const { formatMessage } = intl;

    const panelData = {
      descriptor,
      thickLines,
      viewport,
      tooltipText: formatMessage({ id: 'plans.harvestedTitle' }, { year: descriptor?.year }),
      emptyText: formatMessage({ id: 'plans.emptyDateRange' },
        { link: formatMessage({ id: 'plans.emptyDateRangeLink' }) })
    };

    const timelineData = {
      descriptor,
      thickLines,
      viewport,
      intl,
      match
    };

    // setup timeline
    this.timeLineComponent = new Timeline({
      position: { x: LEFT_PANEL_WIDTH, y: HEADER_HEIGHT },
      ...timelineData
    });
    this.rootContainer.addChild(this.timeLineComponent);

    this.timeLineComponent.off('scroll').on('scroll', (pos) => {
      this.handleUpdateTimelineScroll(pos);
    });

    this.leftPanelComponent = new LeftPanel({
      position: { x: 0, y: HEADER_HEIGHT },
      ...panelData,
      formatMessage
    });
    this.rootContainer.addChild(this.leftPanelComponent);

    this.horizontalTimelineComponent = new HorizontalTimeline({
      position: { x: 0, y: 0 },
      ...timelineData,
    });
    this.rootContainer.addChild(this.horizontalTimelineComponent);

    this.buttonContainer = new TimelineHUD({
      position: { x: 0, y: 0 },
      year: descriptor.year,
      viewport,
      currentWeekText: formatMessage({ id: 'plans.currentWeek' })
    });

    this.buttonContainer.off('clickPrevYear').on('clickPrevYear', () => {
      const {
        onPrevYearClick,
      } = this.props;

      onPrevYearClick();
    });
    this.buttonContainer.off('clickNextYear').on('clickNextYear', () => {
      const {
        onNextYearClick
      } = this.props;

      onNextYearClick();
    });
    this.buttonContainer.on('clickSummary', () => {
      this.setState({ isShowSummary: true });
    });
    this.buttonContainer.on('clickCurrentWeek', () => {
      const {
        onCurrentWeekClick
      } = this.props;
      if (onCurrentWeekClick) {
        onCurrentWeekClick({ offset: 0 });
      }
    });

    this.rootContainer.addChild(this.buttonContainer);

    this.updateComponentsDataFromProps();
    this.updateComponentView(viewport);
    this.initFirstTimelineScroll();

    this.rootContainer.setDirty();
  }

  updateComponentsDataFromProps = () => {
    const {
      descriptor,
      thickLines
    } = this.props;

    const payload = {
      descriptor,
      thickLines,
    };

    [
      this.timeLineComponent,
      this.horizontalTimelineComponent,
      this.leftPanelComponent,
      this.buttonContainer
    ].forEach((component) => {
      if (component) {
        component.updateData(payload);
      }
    });

    this.rootContainer.setDirty();
  }

  updateComponentView = () => {
    const {
      viewport
    } = this.state;

    [
      this.timeLineComponent,
      this.horizontalTimelineComponent,
      this.leftPanelComponent,
      this.buttonContainer
    ].forEach((component) => {
      component.updateView({
        viewport
      });
    });

    this.timeLineComponent.off('scroll').on('scroll', (pos) => {
      this.handleUpdateTimelineScroll(pos);
    });
  }

  initFirstTimelineScroll = () => {
    this.timeLineComponent.setTimelineScroll({
      y: this.timeLineComponent.scroll.y,
      x: this.getScrollXFromTimelineDate(),
    });
  }

  handleInit = (ctx, bounds) => {
    this.setState(prevState => ({
      ...prevState,
      // хз почему, но без обнуления y и top полоска скролла криво позиционируется
      viewport: {
        ...bounds,
        y: 0,
        top: 0,
      }
    }));
  }

  handleResize = (ctx, bounds, {
    // eslint-disable-next-line no-unused-vars
    width, height
  }) => {
    this.setState(prevState => ({
      ...prevState,
      // хз почему, но без обнуления y и top полоска скролла криво позиционируется
      viewport: {
        ...bounds,
        y: 0,
        top: 0,
      }
    }));
  }

  handleUpdateTimelineScroll = ({ x, y }) => {
    const {
      descriptor,
      onTimelineDateChanged
    } = this.props;
    this.horizontalTimelineComponent.setTimelineScroll({ x, y });
    this.leftPanelComponent.setTimelineScroll({ x, y });
    const colWidth = getColWidth(descriptor);
    const timelineIndex = Math.floor(((x) / colWidth)) * -1;
    if (timelineIndex <= descriptor?.periods?.length && timelineIndex >= 1) {
      const timelineDate = moment(descriptor?.periods[timelineIndex - 1]?.endDate).format(API_DATE_FORMAT);
      onTimelineDateChanged({
        timelineDate
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  handleDraw = (ctx, bounds, dt) => {
    drawRootComponent(ctx, this.rootContainer);
  }

  handlePointerUp = ({
    x, y, shiftKey, ctrlKey
  }) => {
    this.rootContainer.pointerUpEvent({
      x, y, shiftKey, ctrlKey
    });
  }

  handlePointerDown = ({ x, y }) => {
    this.rootContainer.pointerDownEvent({ x, y });
  }

  handlePointerMove = ({ x, y }) => {
    this.rootContainer.pointerMove({ x, y });
  }

  handleMouseWheel = ({
    deltaX, deltaY, x, y, shiftKey
  }) => {
    const dx = shiftKey ? deltaY : deltaX;
    const dy = shiftKey ? deltaX : deltaY;

    const nx = shiftKey ? y : x;
    const ny = shiftKey ? x : y;

    this.rootContainer.mouseWheel({
      deltaX: dx, deltaY: dy, x: nx, y: ny
    });
  }

  getPeriodsView = ({ descriptor }) => {
    const {
      relative,
      periodsBefore,
    } = descriptor;
    const isRelative = relative === 'true';
    return [...periodsBefore.slice(isRelative ? -7 : -5)].concat(descriptor?.periods);
  }

  getScrollXFromTimelineDate = () => {
    const {
      descriptor,
    } = this.props;

    const {
      currentWeek,
      year
    } = descriptor;

    const periods = this.getPeriodsView({ descriptor });
    const currentPeriodIndex = periods.findIndex((period =>
      period?.periodNumber === currentWeek && period?.endDate.getUTCFullYear() === year));
    return currentPeriodIndex === -1 ? 0 : -(currentPeriodIndex * getColWidth(descriptor));
  }

  render() {
    const {
      isShowSummary,
    } = this.state;
    const {
      descriptor,
      thickLines,
      isFetching,
    } = this.props;
    return (
      <div className={styles.canvasTimeline}>
        {isFetching && (
          <div className={styles.loader}>
            <CircleLoader
              className={loaderStyles.circleLoader}
              iconClassName={loaderStyles.circleLoaderIcon}
            />
          </div>
        )}
        {thickLines && descriptor && (
          <ReactCanvas
            canvasClassName={styles.canvas}
            onDraw={this.handleDraw}
            onInit={this.handleInit}
            onResize={this.handleResize}
            onPointerUp={this.handlePointerUp}
            onPointerDown={this.handlePointerDown}
            onPointerMove={this.handlePointerMove}
            onMouseWheel={this.handleMouseWheel}
          />
        )}
        {isShowSummary && (
          <div className={styles.popupOverlay}>
            <div className={styles.popup}>
              <div className={styles.popupActions}>
                <CloseIcon
                  className={styles.iconButton}
                  onClick={() => {
                    this.setState({ isShowSummary: false });
                  }}
                />
              </div>
              <div className={styles.popupContent}>
                <PlansSummary
                  year={descriptor?.year}
                  relative={descriptor?.relative}
                  groupedBy='Compartment'
                  isDetachedComponent
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(withRouter(CanvasTimeline));
