import { createSelector } from 'reselect';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { getAllCompartments } from '../company/selectors';

import { API_DATE_FORMAT } from '../../helpers/defaultDates';

export const getEnergyReport = state => get(state, 'energy.energyReport');
export const getIsEnergyReportFetching = state => get(state, 'energy.isEnergyReportFetching');

export const getEnergyRegistrations = state => get(state, 'energy.energyRegistrations');
export const getIsEnergyRegistrationsFetching = state => get(state, 'energy.isEnergyRegistrationsFetching');

export const getIsSaveRegistrationsFetching = state => get(state, 'energy.isSaveRegistrationsFetching');

export const getEnergyMeters = state => get(state, 'energy.meters');
export const getIsEnergyMetersFetching = state => get(state, 'energy.isMetersFetching');

export const getEnergyReportSorting = state => get(state, 'energy.energyReportSorting');

export const getMetersCombined = createSelector(
  [getAllCompartments, getEnergyMeters],
  (compartments, meters) => meters.map(meter => ({
    ...meter,
    effectiveStart: moment(meter.effectiveStart, API_DATE_FORMAT),
    effectiveEnd: moment(meter.effectiveEnd, API_DATE_FORMAT),
    compartmentMappingHistory: meter.compartmentMappingHistory.map(history => ({
      ...history,
      compartmentIds: history.compartmentIds.map(id => compartments.find(compartment => compartment.id === id))
    })).map(history => ({
      ...history,
      effectiveStart: moment(history.effectiveStart, API_DATE_FORMAT),
      effectiveEnd: moment(history.effectiveEnd, API_DATE_FORMAT)
    }))
  }))
);
