import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import {
  isString
} from 'lodash';
import classnames from 'classnames';

import Typography from 'components/Typography';
import MobileMenu from 'components/MobileMenu';
import BigButton from 'components/BigButton';
import FilterIcon from 'components/Icons/FilterIcon';
import ArrowBackFootedIcon from 'components/Icons/ArrowBackFootedIcon';

import updateLocationSearch from 'helpers/updateLocationSearch';

import BreakdownFilter from '../BreakdownFilter';
import CompartmentFilter from '../CompartmentFilter';
import UnitFilter from '../UnitFilter';
import VarietyFilter from '../VarietyFilter';
import WorkTypeFilter from '../WorkTypeFilter';
import GroupFilter from '../GroupFilter';
import RelativeFilter from '../RelativeFilter';
import PlanFilter from '../PlanFilter';
import IncidentsFilter from '../IncidentsFilter';
import SeverityFilter from '../SeverityFilter';
import IncidentsGroupByFilter from '../IncidentsGroupByFilter';

import styles from './DashboardComplexFilters.module.css';
import BigSelectInput from '../../../BigSelectInput';


const filtersMap = {
  breakdown: BreakdownFilter,
  compartment: CompartmentFilter,
  unit: UnitFilter,
  variety: VarietyFilter,
  workType: WorkTypeFilter,
  relative: RelativeFilter,
  plan: PlanFilter,
  group: GroupFilter,
  incidents: IncidentsFilter,
  severity: SeverityFilter,
  incidentsGroupBy: IncidentsGroupByFilter
};

export default class DashboardComplexFilters extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    filters: PropTypes.arrayOf(PropTypes.oneOf([
      'breakdown',
      'compartment',
      'unit',
      'variety',
      'workType',
      'relative',
      'plan',
      'incidents',
      'severity',
      'incidentsGroupBy',
      PropTypes.arrayOf,
      PropTypes.object
    ])),

    workTypes: PropTypes.array,
    varieties: PropTypes.array,
    plantingCycles: PropTypes.array,
    species: PropTypes.array,
    compartments: PropTypes.array,
    fruitClasses: PropTypes.array,

    currentBreakdown: PropTypes.string,
    currentUnitKind: PropTypes.string,

    currentGrownFilter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    currentPlantingCycle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),

    currentWorkTypeId: PropTypes.array,
    currentCompartment: PropTypes.string,
    currentSpecies: PropTypes.string,
    currentVarietyId: PropTypes.number,
    currentFruitClassCode: PropTypes.string,
    mode: PropTypes.oneOf(['dashboards:labor', 'dashboards:energy', 'dashboards:plans']),

    onFiltersChange: PropTypes.func,

    isOnlyVegetables: PropTypes.bool,
    isOnlyLettuce: PropTypes.bool,
    relative: PropTypes.bool,

    onResetAll: PropTypes.func,
    showResetAll: PropTypes.bool,

    renderMode: PropTypes.oneOf(['desktop', 'mobile', 'both']),
    mobileFilterIcon: PropTypes.node,
    mobileFilterClassName: PropTypes.string,
    mobileContainerClassName: PropTypes.string,
    onSetPlaceholder: PropTypes.func
  };

  static defaultProps = {
    mode: null,
    currentBreakdown: 'compartment',
    currentUnitKind: 'absolute',
    currentGrownFilter: null,
    currentCompartment: null,
    currentWorkTypeId: null,
    currentPlantingCycle: 'all',
    currentSpecies: null,
    currentVarietyId: null,
    currentFruitClassCode: null,
    onFiltersChange: null,
    isOnlyVegetables: false,
    isOnlyLettuce: false,
    relative: false,

    filters: [],
    workTypes: [],
    varieties: [],
    plantingCycles: [],
    species: [],
    compartments: [],
    fruitClasses: [],

    onResetAll: null,
    showResetAll: false,

    renderMode: 'both',
    mobileFilterIcon: null,
    mobileFilterClassName: null,
    mobileContainerClassName: null,
    onSetPlaceholder: null
  };

  state = {
    mobileMenuOpened: false,
    filterState: {},
  }

  handlerCloseMenu = () => this.setState({
    mobileMenuOpened: false,
  });

  handlerOpenMenu = () => this.setState({
    mobileMenuOpened: true,
  });

  renderHeaderText = () => {
    const { intl: { formatMessage } } = this.props;

    return (
      <Typography variant='h2' className={styles.headerMobile}>
        <ArrowBackFootedIcon className={styles.backIcon} onClick={this.handlerCloseMenu} />
        {formatMessage({ id: 'groupFilter.filter' })}
      </Typography>
    );
  };

  changeFilterState = (options) => {
    const {
      onFiltersChange,
    } = this.props;
    // TODO: Fix it
    const isMobile = window.innerWidth < 720;
    if (!isMobile) {
      onFiltersChange(options);
    } else {
      this.setState(prevState => ({
        ...prevState,
        options
      }));
    }
  }

  handlerShowResults = () => {
    const {
      onFiltersChange
    } = this.props;
    const {
      filterState
    } = this.state;
    onFiltersChange(filterState);
    this.setState({
      mobileMenuOpened: false,
    });
  }

  handleResetAll = () => {
    const {
      onResetAll,
      onFiltersChange
    } = this.props;

    // TODO: Fix it
    onFiltersChange({
      compartmentId: undefined,
      species: undefined,
      fruitClassCode: undefined,
      varietyId: undefined,
    });

    updateLocationSearch({
      compartmentId: undefined,
      species: undefined,
      fruitClassCode: undefined,
      varietyId: undefined,
    });

    if (onResetAll) {
      onResetAll();
    }
  }

  renderFilter = (filterKey, index, isMobile) => {
    const {
      intl,
      varieties,
      compartments,
      fruitClasses,
      species,
      workTypes,
      mode,

      currentBreakdown,
      currentCompartment,
      currentUnitKind,
      currentGrownFilter,
      currentWorkTypeId,

      currentSpecies,
      currentVarietyId,
      currentFruitClassCode,

      isOnlyVegetables,
      isOnlyLettuce,
      relative,

      onFiltersChange,
      showResetAll,
      onSetPlaceholder,
    } = this.props;

    const filterKeyString = isString(filterKey) ? filterKey : filterKey.type;
    const Filter = filtersMap[filterKeyString];

    const mobileProps = {
      isMobile,
      noTitle: false,
      isTitleEnabled: true,
    };

    if (isMobile) {
      mobileProps.selectComponent = BigSelectInput;
      mobileProps.noTitle = true;
      mobileProps.classNamePopup = styles.longPopup;
      mobileProps.classNameButton = styles.longButton;
      mobileProps.closeOnChange = true;
    }

    const styleProps = {};
    if (filterKeyString === 'incidents') {
      styleProps.classNamePopup = styles.incidentPopup;
    }

    const filterProps = {
      key: `filter-${index}`,
      intl,
      varieties,
      compartments,
      filters: filterKey?.filters || [],
      filtersMap,
      fruitClasses,
      species,
      workTypes,
      mode,
      currentBreakdown,
      currentCompartment,
      currentUnitKind,
      currentGrownFilter,
      currentWorkTypeId,
      currentSpecies,
      currentVarietyId,
      currentFruitClassCode,
      onFiltersChange,
      isOnlyVegetables,
      isOnlyLettuce,
      relative,
      showResetAll,
      ...filterKey.options || {},
      ...mobileProps,
      isMobile,
      onSetPlaceholder,
      onResetAll: () => {
        this.handleResetAll();
      },
      ...styleProps
    };

    if (isMobile && filterKey.type !== 'group') {
      return (
        <div className={styles.selectGroup}>
          <Filter {...filterProps} />
        </div>
      );
    }

    return (
      <Filter {...filterProps} />
    );
  }

  render() {
    const {
      intl: { formatMessage },
      filters,
      renderMode,
      mobileFilterIcon,
      mobileFilterClassName,
      mobileContainerClassName
    } = this.props;

    const {
      mobileMenuOpened,
    } = this.state;

    return (
      <div>
        {renderMode !== 'mobile' && (
          <div className={styles.desktopContainer}>
            {filters.map((filterKey, index) => (
              this.renderFilter(filterKey, index, false)
            ))}
          </div>
        )}
        {renderMode !== 'desktop' && (
          <div className={classnames(styles.mobileContainer, mobileContainerClassName)}>
            <MobileMenu
              className={classnames(styles.mobileMenu, mobileFilterClassName)}
              icon={mobileFilterIcon || <FilterIcon />}
              headerText={this.renderHeaderText()}
              onClose={this.handlerCloseMenu}
              onOpen={this.handlerOpenMenu}
              menuOpened={mobileMenuOpened}
              withoutCloseIcon
            >
              <div className={styles.container}>
                {filters.map((filterKey, index) => (
                  this.renderFilter(filterKey, index, true)
                ))}
              </div>
              <div className={styles.mobileControls}>
                <BigButton
                  className={styles.cancelButton}
                  title={formatMessage({ id: 'button.cancel' })}
                  type='button'
                  onClick={this.handlerCloseMenu}
                  theme='light'
                />
                <BigButton
                  title={formatMessage({ id: 'cropsPerformance.showResults' })}
                  type='button'
                  onClick={this.handlerShowResults}
                  theme='dark'
                />
              </div>
            </MobileMenu>
          </div>
        )}
      </div>
    );
  }
}
