/**
 * Открывает Intercom. Функция с сайдэффектом, не возвращает ничего.
 */
export const showIntercom = () => {
  if (window.Intercom) {
    window.Intercom('show');
  }
};

/**
 * Скрывает лаунчер Intercom (сама кнопка). Функция с сайдэффектом, не возвращает ничего.
 */
export const hideIntercomLauncher = () => {
  if (window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: true
    });
  }
};

/**
 * Показывает лаунчер Intercom (сама кнопка). Функция с сайдэффектом, не возвращает ничего.
 */
export const showIntercomLauncher = () => {
  if (window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: false
    });
  }
};
