import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getCurrentCompartments,
  getFirstSpecies,

  getSpeciesFromRoute,
  getCompartmentIdFromRoute,
  getVarietyIdFromRoute,
  getPlantingCycleIdFromRoute,
  getUnitsFromRoute,

  getVarietiesInLocationWithTargetWeight,
  getFilteredPlantingCyclesByVarieties,

  getAllProductGroups,
  getIsOnlyLettuce,
  getAllFruitClasses,
} from '../../../store/company/selectors';

import DashboardFilters from '../components/DashboardFilters';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,

  varieties: getVarietiesInLocationWithTargetWeight(state, props),
  plantingCycles: getFilteredPlantingCyclesByVarieties(state, props),
  compartments: getCurrentCompartments(state),

  currentGrownFilter: getVarietyIdFromRoute(state, props) || getSpeciesFromRoute(state, props) || getFirstSpecies(state),
  currentCompartment: getCompartmentIdFromRoute(state, props),
  currentPlantingCycle: getPlantingCycleIdFromRoute(state, props),
  currentUnits: getUnitsFromRoute(state, props),
  productGroups: getAllProductGroups(state, props),
  fruitClasses: getAllFruitClasses(state, props),
  isOnlyLettuce: getIsOnlyLettuce(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardFilters)));
