import { connect } from 'react-redux';

// @ts-ignore
import { injectIntl } from 'react-intl';

// @ts-ignore
import { withRouter } from 'react-router';

import {
  changeGraphPeriodRange,
  changeGraphPeriodThreshold,
  changePresetDescription,
  changePresetMetricGroup,
  changePresetName,
  changeScale,
  deletePresetMetric,
  deletePresetMetricGroup,
  deleteScale,
  requestCreateGraphPreset,
  requestDeleteGraphPreset,
  requestGetGraphPreset,
  requestGetGraphPresets,
  requestGetMetrics,
  requestUpdateGraphPreset,
  setMetricColor,
  setMetricToScale,
  setPresetMetricSelected,
  requestGetGraphDataPoints,
  pushRangeHistory,
  popRangeHistory,
  clearRangeHistory,
  setAllPresetMetricSelected,
  updatePresetDataFromUrl,
  clearWorkspace,
  setShowIncidents,
  setActiveIncidentId,
  changeGraphPeriodAndThreshold,
  createIncident,
  requestNewIncidentId,
  closeIncidentEditor,
  checkNewIncidentStatus,
  requestMetricsTree,
  requestFullTreeData
} from 'store/graphs/actions';

import {
  showSidePanelAction,
  hideSidePanelAction,
} from 'store/sidePanel/actions';

import {
  checkIsEditedPreset,
  getActiveIncidentId,
  getCreateIncident,
  getDataPointsMetrics,
  getIncidents,
  getMetricDatas,
  getMetricsSelector,
  getPreset,
  getPresetsSelector,
  getPresetThreshold,
  getRangeHistory,
  getReturnAbnormalityIncidents,
  isDataPointsFetchingSelector,
  getPresetMetrics,
  getPresetScales,
} from 'store/graphs/selectors';

import {
  getAllCompartments,
  getAllFruitClasses, getAllPlantingCycles,
  getAllSubNodes,
  getAllVarieties,
  getCurrentSpecies,
  getLocation,
  getPermissions
} from 'store/company/selectors';

import { getAllGraphMetrics, trackPageViewGraphs } from 'store/company/actions';

import { requestIncidentById, requestIncidentPlot } from 'store/incidents/actions';

import Graphs from '../../components/Graphs';

import { setToggleGraphPresetDeleteDialog } from '../../../../store/dialogs';

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state: any, props: any) => ({
  ...state.intl,
  ...state.sidePanel,
  ...state.dialogs,
  allMetrics: getMetricsSelector(state),
  allCompartments: getAllCompartments(state),
  allSubNodes: getAllSubNodes(state),
  allSpecies: getCurrentSpecies(state),
  allVarieties: getAllVarieties(state),
  allFruitClasses: getAllFruitClasses(state),
  allPlantingCycles: getAllPlantingCycles(state),
  isMetricsFetching: isDataPointsFetchingSelector(state),
  preset: getPreset(state),
  location: getLocation(state),
  isEditedPreset: checkIsEditedPreset(state),
  presets: getPresetsSelector(state),
  metricDatas: getMetricDatas(state),
  incidents: getIncidents(state),
  dataPointsMetrics: getDataPointsMetrics(state),
  threshold: getPresetThreshold(state),
  rangeHistory: getRangeHistory(state),
  activeIncidentId: getActiveIncidentId(state),
  returnAbnormalityIncidents: getReturnAbnormalityIncidents(state),
  createdIncident: getCreateIncident(state),
  permissions: getPermissions(state),
  presetMetrics: getPresetMetrics(state),
  presetScales: getPresetScales(state),
});

const mapDispatchToProps = {
  requestGetMetrics,
  requestGetGraphPresets,
  requestGetGraphPreset,
  requestCreateGraphPreset,
  requestUpdateGraphPreset,
  requestDeleteGraphPreset,
  changePresetName,
  changePresetDescription,
  setPresetMetricSelected,
  setMetricColor,
  deletePresetMetric,
  deletePresetMetricGroup,
  changePresetMetricGroup,
  setMetricToScale,
  changeScale,
  deleteScale,
  changeGraphPeriodRange,
  changeGraphPeriodThreshold,
  showSidePanelAction,
  hideSidePanelAction,
  getAllGraphMetrics,
  requestGetGraphDataPoints,
  pushRangeHistory,
  popRangeHistory,
  clearRangeHistory,
  setAllPresetMetricSelected,
  setToggleGraphPresetDeleteDialog,
  updatePresetDataFromUrl,
  clearWorkspace,
  setShowIncidents,
  setActiveIncidentId,
  changeGraphPeriodAndThreshold,
  createIncident,
  requestNewIncidentId,
  closeIncidentEditor,
  checkNewIncidentStatus,
  requestMetricsTree,
  trackPageViewGraphs,
  requestFullTreeData,
  requestIncidentById,
  requestIncidentPlot,
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Graphs)));
