import { get, maxBy, find } from 'lodash';
import { createSelector } from 'reselect';

import { getMatch } from 'store/company/selectors';

export const getHarvestPlan = state => get(state, 'plansEditor.harvestPlan');
export const getActiveVersionId = state => get(state, 'plansEditor.harvestPlan.id');
export const getPlansEditorData = state => get(state, 'plansEditor.harvestPlan.data');
export const getPlansEditorFetching = state => get(state, 'plansEditor.isPlansEditorFetching');
export const getVarietyRemoving = state => get(state, 'plansEditor.isVarietyRemoving');
export const getPlansEditorSaving = state => get(state, 'plansEditor.isPlansEditorSaving');
export const getWithoutSaveDate = state => get(state, 'plansEditor.withoutSaveDate');
export const getTableScroll = state => get(state, 'plansEditor.tableScroll');
export const getPlanVersionSummary = state => get(state, 'plansEditor.harvestPlanSummary');
export const getIsPlanVersionSummaryFetching = state => get(state, 'plansEditor.isPlanVersionSummaryFetching');
export const getPlansSplitting = state => (get(state, 'plansEditor.harvestPlan.periodLength') === 'weekSplitByMonth');
export const getPlanDescriptor = state => get(state, 'plansEditor.harvestPlan.descriptor');
export const getYearType = state => get(state, 'plansEditor.harvestPlan.yearType');

export const getHarvestPlanEdited = state => get(state, 'plansEditor.harvestPlanEdited');
export const getHarvestPlanEditedData = state => get(state, 'plansEditor.harvestPlanEdited.data');

export const getAllPlanVersions = state => get(state, 'plansEditor.allPlanVersions');
export const getVersionsFilter = state => get(state, 'plansEditor.versionsFilter');

export const getIsVersionInfoFetching = state => get(state, 'plansEditor.isVersionInfoFetching');
export const getIsDeleteVersionInfoFetching = state => get(state, 'plansEditor.isDeleteVersionInfoFetching');

export const getMaxIdx = createSelector(
  getHarvestPlanEditedData,
  getPlansEditorData,
  (editedDataArray, dataArray) => get(maxBy(editedDataArray || dataArray, 'idx'), 'idx') || 0,
);

export const getIsCurrentVersionPublished = createSelector(
  getHarvestPlan,
  harvestPlan => get(harvestPlan, 'published', true)
);

export const getIsLastVersion = createSelector(
  getHarvestPlan,
  getAllPlanVersions,
  /**
   * Последним план признаём так же, когда его нет в массиве allVersions (это значит,
   * что создали новый план, а массив allVersions ещё не обновился). В идеале надо
   * эту логику перенести на бэкенд, чтобы в harvestPlan приходило поле isLastVersion
   */
  (harvestPlan, allVersions) => (get(harvestPlan, 'id') === get(allVersions, '[0].id') || !find(allVersions, { id: get(harvestPlan, 'id') }))
);

export const getIsNotPublishedVersionPlate = createSelector(
  getIsCurrentVersionPublished,
  getIsLastVersion,
  (isCurrentVersionPublished, isLastVersion) => !isCurrentVersionPublished && isLastVersion
);

export const getFilteredPlanVersions = createSelector(
  getAllPlanVersions,
  getVersionsFilter,
  (allVersions, versionsFilter) => {
    switch (versionsFilter) {
      case 'all':
        return allVersions;
      case 'named':
        return allVersions.filter(version => version.label);
      case 'published':
        return allVersions.filter(version => version.published);
      default:
        return allVersions;
    }
  },
);

export const getPlanType = createSelector(
  [getMatch],
  match => get(match, 'params.planType'),
);

export const getIsOperationalPlan = createSelector(
  [getPlanType],
  planType => planType === 'operational',
);
