import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classnames from 'classnames';

import Checkbox from 'components/Checkbox';
import ArrowRightIcon from 'components/Icons/ArrowRightIcon';

import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';

import FxIcon from 'components/Icons/FxIcon';
import styles from './CollapsedRow.module.css';

const getIsActiveChildren = (tableRow, currentGraphKey) => {
  const childrenKeys = new Set(tableRow.children.map(chRow => chRow.graphKey));
  const currentGraphKeySet = new Set(currentGraphKey);
  return [...childrenKeys].filter(key => currentGraphKeySet.has(key));
};

const CollapsedRow = ({
  tableRow,
  currentGraphKey,
  onGraphKeyChange,
  lockUnselected,
  intl
}) => {
  const { formatMessage, locale } = intl;

  const [opened, setOpened] = useState(
    getIsActiveChildren(tableRow, currentGraphKey).length > 0
  );

  const handlerRowClick = () => {
    setOpened(prevState => !prevState);
  };

  const handleClickIcon = (e, graphKey, checked) => {
    if (onGraphKeyChange) {
      onGraphKeyChange(e, graphKey, checked);
    }
  };

  const renderTableRow = (row, secondLevel = false) => {
    if (!row) {
      return null;
    }

    const {
      graphKey, name, data, calculated, displayScale, cunit, description, extra
    } = row;

    const checked = currentGraphKey.includes(graphKey);
    const descriptionText = description ? description[locale] || `[${description.en}]` : '';

    const hintPropsLabel = description ? {
      'data-tip': `${descriptionText}, ${formatMessage({ id: `cunits.mini.${cunit}` })}`,
      'data-for': 'measurements-table-indicators-tooltip',
    } : null;

    const hintPropsExtra = {
      'data-tip': formatMessage({ id: 'crops.tooltipExtra' }),
      'data-for': 'markers-extra-table-indicators-tooltip',
    };

    const hintPropsFx = {
      'data-tip': formatMessage({ id: 'crops.tooltipFx' }),
      'data-for': 'markers-table-indicators-tooltip',
    };

    return (
      <tr
        key={`row-${graphKey}`}
        className={classnames({
          [styles.trSecondLevel]: secondLevel
        })}
        onClick={secondLevel ? () => {} : handlerRowClick}
      >
        <td className={classnames(styles.nameWrapperTd, styles.sticky)}>
          {!secondLevel && (
            <Checkbox
              value={graphKey}
              checked={checked}
              onChange={e => handleClickIcon(e, graphKey, checked)}
              markerIcon={Checkbox.MARKERS.Arrow}
              noBackgroundColor={false}
              disabled={lockUnselected && !checked}
            />
          )}
          <span className={styles.nameWrapper}>
            {!secondLevel && row?.children?.length > 0 && (
              <span
                className={classnames(
                  styles.arrowRightIcon,
                  {
                    [styles.arrowIconRotate]: opened
                  }
                )}
              >
                <ArrowRightIcon />
              </span>
            )}
            {secondLevel && (
              <Checkbox
                value={graphKey}
                checked={checked}
                onChange={e => handleClickIcon(e, graphKey, checked)}
                markerIcon={Checkbox.MARKERS.Arrow}
                noBackgroundColor={false}
                disabled={lockUnselected && !checked}
              />
            )}
            <span {...hintPropsLabel} className={classnames(styles.nameText, { [styles.calculated]: calculated })}>{name}<div className={styles.nameExtra} {...hintPropsExtra}>{extra && ` • ${formatMessage({ id: 'crops.labelExtra' })}`}</div>{!secondLevel && `, ${formatMessage({ id: `cunits.mini.${cunit}` })}`}</span>&nbsp;{calculated && <FxIcon {...hintPropsFx} />}
          </span>
        </td>
        {/* eslint-disable-next-line no-unused-vars */}
        {data.map(([time, value]) => (
          <td className={styles.tdValue}>{
          value !== null ? numbersFormatting(numbersRounding(value, 'fixed', displayScale || 0)) : '–'
        }
          </td>
        ))}
        <td>&nbsp;</td>
      </tr>
    );
  };
  return (
    <Fragment key={`rowFragment-${tableRow.graphKey}`}>
      {renderTableRow(tableRow)}
      {opened && tableRow?.children?.length > 0 ?
        tableRow.children.map(childRow => renderTableRow(childRow, true))
        :
        null}
    </Fragment>
  );
};

CollapsedRow.propTypes = {
  intl: intlShape.isRequired,
  tableRow: PropTypes.object.isRequired,
  currentGraphKey: PropTypes.array,
  onGraphKeyChange: PropTypes.func.isRequired,
  lockUnselected: PropTypes.bool
};

CollapsedRow.defaultProps = {
  currentGraphKey: [],
  lockUnselected: false
};

export default injectIntl(CollapsedRow);
