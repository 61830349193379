import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import moment from 'moment-timezone';

import HorizontalBarsTooltip from '../HorizontalBarsTooltip';

import styles from './HorizontalBar.module.css';

export default class HorizontalBar extends Component {
  static propTypes = {
    scales: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    fixedBarHeight: PropTypes.number,
    margins: PropTypes.object.isRequired,
    svgDimensions: PropTypes.object.isRequired,
    withCompareButton: PropTypes.bool,
    isShowLocationName: PropTypes.bool,
    isHovered: PropTypes.bool,
    forceColor: PropTypes.bool,

    onMouseOverCallback: PropTypes.func,
    onMouseOutCallback: PropTypes.func,
    onClickCallback: PropTypes.func,
  };

  static defaultProps = {
    fixedBarHeight: 8,
    withCompareButton: false,
    isShowLocationName: true,
    isHovered: false,
    forceColor: false,
    onMouseOverCallback: () => {},
    onMouseOutCallback: () => {},
    onClickCallback: () => {},
  };

  render() {
    const {
      scales, item, fixedBarHeight, margins, svgDimensions, withCompareButton, isShowLocationName, isHovered, forceColor,
      onMouseOutCallback,
      onMouseOverCallback,
      onClickCallback,
    } = this.props;

    const { xScale, yScale } = scales;

    const barHeight = yScale.bandwidth();
    const barPaddingTop = (barHeight - fixedBarHeight) / 2;


    const rectX = xScale(item.startTime);
    const rectY = (yScale(item.id) - barHeight) + barPaddingTop + (fixedBarHeight / 2);

    const realBarWidth = xScale(item.endTime || moment().endOf('day').valueOf()) - xScale(item.startTime);
    const barWidth = realBarWidth || 8; // xScale(moment().endOf('day').valueOf()) - xScale(moment().startOf('day').valueOf());

    const barWrapperPadding = fixedBarHeight;

    const barRowStyles = {
      top: `${rectY - fixedBarHeight}px`,
      right: `${margins.right}px`,
      left: `${margins.left}px`,
      height: `${fixedBarHeight * 3}px`,
    };

    const barWrapperStyles = {
      height: `${fixedBarHeight * 3}px`,
      left: `${(rectX - margins.left) - barWrapperPadding}px`,
      width: `${barWidth + (barWrapperPadding * 2)}px`,
      top: 0,
    };

    const barStyles = {
      height: `${fixedBarHeight}px`,
      width: `${barWidth}px`,
      left: `${barWrapperPadding}px`,
      top: `${fixedBarHeight}px`,
      background: item.color, // TODO: подумать как передавать цвет для hover
    };

    /* eslint-disable jsx-a11y/mouse-events-have-key-events */
    return (
      <div
        className={classnames(
          styles.horizontalBarRow,
          {
            [styles.isHovered]: isHovered,
          }
        )}
        style={barRowStyles}
      >
        <div
          className={styles.horizontalBarWrapper}
          style={barWrapperStyles}
          onMouseOver={() => onMouseOverCallback(item.id)}
          onMouseOut={() => onMouseOutCallback(item.id)}
          onClick={() => onClickCallback(item.id)}
          role='button'
          tabIndex={0}
        >
          <div
            className={classnames(styles.horizontalBar, { [styles.defaultColor]: !forceColor })}
            style={barStyles}
          />
          <HorizontalBarsTooltip
            x={barWrapperPadding}
            y={barWrapperPadding * 2}
            maxXCoord={svgDimensions.width}
            tooltipData={item}
            withCompareButton={withCompareButton}
            isShowLocationName={isShowLocationName}
            className={styles.horizontalBarTooltip}
          />
        </div>
      </div>
    );
    /* eslint-enable jsx-a11y/mouse-events-have-key-events */
  }
}
