import moment from 'moment';
import { get } from 'lodash';

import getDateByPeriod from 'helpers/getDateByPeriod';

export function getBreakdownLabel(currentBreakdown) {
  const breakdownMapping = {
    compartment: 'greenhouse',
    varietyWeight: 'variety',
    fruitClass: 'type',
    workType: 'greenhouseWork',
  };
  return Object.prototype.hasOwnProperty.call(breakdownMapping, currentBreakdown) ?
    breakdownMapping[currentBreakdown] : 'compartment';
}


export function getTableHeaderItems({
  reportRows = [],
  currentBreakdown,
  intl,
  periodType,
}) {
  const { formatMessage } = intl;

  const columns = get(reportRows, '0.data', {});
  const currentBreakdownLabel = getBreakdownLabel(currentBreakdown);

  return [
    { label: formatMessage({ id: `plans.${currentBreakdownLabel}` }), value: 'meter', textAlign: 'left' },

    ...Object.keys(columns).map(key => ({
      label: getDateByPeriod({ date: moment.utc(new Date((+key) * 60000)), periodType, formatMessage }),
      value: key,
    })),
  ];
}

export default getTableHeaderItems;
