import { get } from 'lodash';
import { Observable } from 'rxjs';

import moment from 'moment-timezone';
import { push } from 'connected-react-router';

import Api from '../../api';

import { toggleCropCreateDialog, toggleErrorDialog } from '../dialogs';

import getDateFormat from '../../helpers/getDateFormat';

import {
  GET_CROPS_METRICS_STARTED,
  GET_CROPS_METRICS_SUCCEEDED,
  GET_CROPS_METRICS_FAILED,
  GET_CROPS_OBSERVATIONS_SUCCEEDED,
  GET_CROPS_OBSERVATIONS_STARTED,
  GET_CROPS_OBSERVATIONS_FAILED,
  CREATE_CROPS_OBSERVATIONS_SUCCEEDED,
  CREATE_CROPS_OBSERVATIONS_STARTED,
  CREATE_CROPS_OBSERVATIONS_FAILED,
  GET_PLANTING_CYCLE_SUCCEEDED,
  GET_PLANTING_CYCLE_STARTED,
  GET_PLANTING_CYCLE_FAILED,
  CREATE_PLANTING_CYCLE_SUCCEEDED,
  CREATE_PLANTING_CYCLE_STARTED,
  CREATE_PLANTING_CYCLE_FAILED,
  CREATE_PLANTING_CYCLE_ENDED,
  UPDATE_PLANTING_CYCLE_SUCCEEDED,
  UPDATE_PLANTING_CYCLE_STARTED,
  UPDATE_PLANTING_CYCLE_FAILED,
  UPDATE_PLANTING_CYCLE_ENDED,

  getCropsMetricsSucceeded,
  getCropsMetricsFailed,
  getCropsMetricsEnded,
  getCropsObservationsSucceeded,
  getCropsObservationsFailed,
  getCropsObservationsEnded,
  createCropsObservationsSucceeded,
  createCropsObservationsFailed,
  createCropsObservationsEnded,
  getPlantingCycleSucceeded,
  getPlantingCycleFailed,
  getPlantingCycleEnded,
  createPlantingCycleSucceeded,
  createPlantingCycleFailed,
  createPlantingCycleEnded,
  updatePlantingCycleSucceeded,
  updatePlantingCycleFailed,
  updatePlantingCycleEnded,
  trackCreatePlantingCycleSuccess,
  trackEditPlantingCycleSuccess,
  trackAddMeasurementSuccess,
  requestDeletePlantingCycle,
  receiveDeletePlantingCycle,
} from './actions';

import { receiveCropsData } from '../newCrops/actions';
import { REQUEST_PLANTING_CYCLE, getAllPlantingCycles, receiveAllPlantingCycles } from '../company/actions';
import { showNotificationWithTimeout } from '../notificationCenter/actions';
import initAxiosInstanse from '../../api/axios';

export const startedGetCropsMetricsEpic = action$ =>
  action$.ofType(GET_CROPS_METRICS_STARTED)
    .switchMap((actionData) => {
      const {
        id,
      } = actionData;

      const api = new Api();

      return Observable.from(api.getCropsMetrics({ id }))
        .map(({ data, error }) => {
          if (error) {
            return getCropsMetricsFailed({ error });
          }

          return getCropsMetricsSucceeded(data);
        })
        .takeUntil(action$.ofType(REQUEST_PLANTING_CYCLE));
    });

export const failedGetCropsMetricsEpic = action$ =>
  action$
    .ofType(GET_CROPS_METRICS_FAILED)
    .mapTo(getCropsMetricsEnded());

export const succeededGetCropsMetricsEpic = action$ =>
  action$
    .ofType(GET_CROPS_METRICS_SUCCEEDED)
    .mapTo(getCropsMetricsEnded());


export const startedGetCropsObservationsEpic = action$ =>
  action$.ofType(GET_CROPS_OBSERVATIONS_STARTED)
    .switchMap((actionData) => {
      const {
        id,
        date,
      } = actionData;

      const api = new Api();

      return Observable.from(api.getCropsObservations({ id, date }))
        .map((result) => {
          const { error } = result;

          if (error) {
            return getCropsObservationsFailed({ error });
          }

          return getCropsObservationsSucceeded(result, date);
        })
        .takeUntil(action$.ofType(REQUEST_PLANTING_CYCLE));
    });

export const failedGetCropsObservationsEpic = action$ =>
  action$
    .ofType(GET_CROPS_OBSERVATIONS_FAILED)
    .mapTo(getCropsObservationsEnded());

export const succeededGetCropsObservationsEpic = action$ =>
  action$
    .ofType(GET_CROPS_OBSERVATIONS_SUCCEEDED)
    .mapTo(getCropsObservationsEnded());


export const startedCreateCropsObservationsEpic = (action$, store) =>
  action$.ofType(CREATE_CROPS_OBSERVATIONS_STARTED)
    .switchMap((actionData) => {
      const {
        plantingCycle,
        date,
        sampleNumber = null,
        observations,
        withTrackEvent,
      } = actionData;

      const { company } = store.getState();
      const { location } = company;
      const api = new Api();

      let currentObservations = {};

      if (observations && Object.keys(observations).length) {
        currentObservations = Object.keys(observations).reduce((reducer, key) => {
          const result = reducer;
          if (observations[key] !== '' && typeof observations[key] !== 'undefined') {
            const value = observations[key];

            const sample = 1.1;
            const delimeter = /^1(.+)1$/.exec(sample.toLocaleString())[1]; // FIXME

            const preparedValue = value.toString().replace('.', delimeter).replace(',', delimeter).replace(delimeter, '.');

            const parsedValue = +preparedValue;

            const isValid = !Number.isNaN(parsedValue) && Number.isFinite(parsedValue);

            if (isValid) {
              result[key] = parsedValue;
            }
          }
          return result;
        }, {});
      }

      const payload = {
        date,
        observations: currentObservations
      };

      if (sampleNumber) {
        payload.sampleNumber = sampleNumber;
      }

      return Observable.from(api.createCropsObservations({ plantingCycle, data: payload }))
        .mergeMap((result) => {
          const { error } = result;
          if (error) {
            return Observable.of(
              toggleErrorDialog(true, error.toString()),
              createCropsObservationsFailed({ error })
            );
          }

          return Observable.of(
            toggleCropCreateDialog(false),
            createCropsObservationsSucceeded(result && result.length ? result[0] : null, location, withTrackEvent)
          );
        });
    });

export const failedCreateCropsObservationsEpic = action$ =>
  action$
    .ofType(CREATE_CROPS_OBSERVATIONS_FAILED)
    .mapTo(createCropsObservationsEnded());

export const succeededCreateCropsObservationsEpic = action$ =>
  action$
    .ofType(CREATE_CROPS_OBSERVATIONS_SUCCEEDED)
    .switchMap((actionData) => {
      const { withTrackEvent } = actionData;

      if (withTrackEvent) {
        return Observable.of(
          trackAddMeasurementSuccess(),
          createCropsObservationsEnded(),
        );
      }

      return Observable.of(createCropsObservationsEnded());
    });

export const startedCreatePlantingCycleEpic = action$ =>
  action$.ofType(CREATE_PLANTING_CYCLE_STARTED)
    .switchMap((actionData) => {
      const {
        data,
        match,
      } = actionData;

      const api = new Api();

      return Observable
        .from(api.createPlantingCycle({ data }))
        .mergeMap((result) => {
          const { error } = result;
          if (error) {
            return Observable.of(
              showNotificationWithTimeout({
                id: 'notifications.createPlantingCycleError',
                messageId: 'notifications.createPlantingCycleError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withActionWide',
              }),
              createPlantingCycleFailed({ error })
            );
          }

          return Observable.of(createPlantingCycleSucceeded(result && result.data && result.data.length ? result.data[0] : null, match));
        });
    });

export const failedCreatePlantingCycleEpic = action$ =>
  action$
    .ofType(CREATE_PLANTING_CYCLE_FAILED)
    .mapTo(createPlantingCycleEnded());

export const succeededCreatePlantingCycleEpic = (action$, store) =>
  action$
    .ofType(CREATE_PLANTING_CYCLE_SUCCEEDED)
    .switchMap((actionData) => {
      const { plantingCycle, match } = actionData;
      const { id: cycleId } = plantingCycle;
      const { newCrops, company } = store.getState();
      const { location, allPlantingCycles } = company;
      const { id: locationId } = location;
      const newAllPlantingCycles = allPlantingCycles.slice();

      const { locationCycles } = newCrops;
      const newLocationCycles = locationCycles ? locationCycles.slice() : null;

      const api = new Api();

      return Observable
        .from(api.getLocationCycle({ cycleId, locationId }))
        .mergeMap((cycle) => {
          let receiveCropsDataAction = null;

          if (newLocationCycles) {
            newLocationCycles.push(cycle);

            receiveCropsDataAction = receiveCropsData(newLocationCycles);
          }

          newAllPlantingCycles.push(plantingCycle);

          if (plantingCycle.relationships.compartment && cycle.cycle.compartmentRef) {
            plantingCycle.relationships.compartment.data[0].id = cycle.cycle.compartmentRef.id;
          }
          if (plantingCycle.relationships.variety && cycle.cycle.varietyRef) {
            plantingCycle.relationships.variety.data[0].id = cycle.cycle.varietyRef.id;
          }

          const actionsPayload = [
            getAllPlantingCycles(newAllPlantingCycles),
            receiveCropsDataAction,
            createPlantingCycleEnded(plantingCycle, match),
            trackCreatePlantingCycleSuccess(),
          ].filter(x => x);

          return Observable.of(...actionsPayload);
        });
    });

export const endedCreatePlantingCycleEpic = action$ =>
  action$
    .ofType(CREATE_PLANTING_CYCLE_ENDED)
    .switchMap((actionData) => {
      const { plantingCycle, match } = actionData;

      if (plantingCycle) {
        return Observable.of(push(`/${match.params.organizationSlug}/crops/${plantingCycle.id}`));
      }

      return Observable.empty();
    });

export const startedUpdatePlantingCycleEpic = action$ =>
  action$.ofType(UPDATE_PLANTING_CYCLE_STARTED)
    .switchMap((actionData) => {
      const {
        data,
        id,
        btnSource,
        history,
        match,
      } = actionData;

      const api = new Api();

      return Observable.from(api.updatePlantingCycle({ data, id }))
        .mergeMap((result) => {
          const { error, errorClass } = result;

          if (errorClass && errorClass === 'EntityOutsidePlantingCycleExistsException') {
            // Error on seeding date change if there are manual records out of the new cycle.
            const { startDate, endDate } = data;

            const formattedStartDate = moment(startDate).format(getDateFormat('LLL'));
            const formattedEndDate = endDate ? moment(endDate).format(getDateFormat('LLL')) : null;

            return Observable.of(
              showNotificationWithTimeout({
                id: 'notifications.updatePlantingCycleErrorOutside',
                messageId: endDate ? 'notifications.updatePlantingCycleErrorOutsideEndDate' : 'notifications.updatePlantingCycleErrorOutside',
                messageParams: {
                  startDate: formattedStartDate,
                  endDate: formattedEndDate,
                },
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withFixedWidth',
                withLineBreaks: true,
              }),
              updatePlantingCycleFailed({ error })
            );
          }

          if (error) {
            return Observable.of(
              showNotificationWithTimeout({
                id: 'notifications.updatePlantingCycleError',
                messageId: 'notifications.updatePlantingCycleError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withActionWide',
              }),
              updatePlantingCycleFailed({ error })
            );
          }

          return Observable.of(updatePlantingCycleSucceeded(result && result.data && result.data.length ? result.data[0] : null, btnSource, history, match));
        });
    });

export const failedUpdatePlantingCycleEpic = action$ =>
  action$
    .ofType(UPDATE_PLANTING_CYCLE_FAILED)
    .mapTo(updatePlantingCycleEnded());

export const succeededUpdatePlantingCycleEpic = (action$, store) =>
  action$
    .ofType(UPDATE_PLANTING_CYCLE_SUCCEEDED)
    .switchMap((actionData) => {
      const {
        plantingCycle,
        btnSource,
        history,
        match,
      } = actionData;

      const { id: cycleId } = plantingCycle;
      const { newCrops, company } = store.getState();
      const { location, allPlantingCycles } = company;
      const { id: locationId } = location;
      const newAllPlantingCycles = allPlantingCycles.slice();

      const { locationCycles } = newCrops;
      const newLocationCycles = locationCycles ? locationCycles.slice() : null;

      const api = new Api();

      return Observable
        .from(api.getLocationCycle({ cycleId, locationId }))
        .mergeMap((cycle) => {
          let receiveCropsDataAction = null;

          if (newLocationCycles) {
            const findLocationCycleIndex = newLocationCycles.findIndex(item => cycle.cycle.id === item.cycle.id);

            newLocationCycles[findLocationCycleIndex] = cycle;

            receiveCropsDataAction = receiveCropsData(newLocationCycles);
          }

          const findPlantingCycleIndex = newAllPlantingCycles.findIndex(item => plantingCycle.id === item.id);

          if (plantingCycle.relationships.compartment && cycle.cycle.compartmentRef) {
            plantingCycle.relationships.compartment.data[0].id = cycle.cycle.compartmentRef.id;
          }
          if (plantingCycle.relationships.variety && cycle.cycle.varietyRef) {
            plantingCycle.relationships.variety.data[0].id = cycle.cycle.varietyRef.id;
          }

          newAllPlantingCycles[findPlantingCycleIndex] = plantingCycle;

          // TODO: переделать на честный запрос за циклами на бэк
          const actionsPayload = [
            getAllPlantingCycles(newAllPlantingCycles),
            receiveCropsDataAction,
            updatePlantingCycleEnded(plantingCycle, btnSource, history, match),
            trackEditPlantingCycleSuccess(btnSource),
            showNotificationWithTimeout({
              id: 'notifications.updatePlantingCycleSuccess',
              messageId: 'notifications.updatePlantingCycleSuccess',
              position: 'leftDown',
              iconType: 'success',
              notificationType: 'withActionWide',
            })
          ].filter(x => x);

          return Observable.of(...actionsPayload);
        });
    });

export const endedUpdatePlantingCycleEpic = action$ =>
  action$
    .ofType(UPDATE_PLANTING_CYCLE_ENDED)
    .switchMap((actionData) => {
      const {
        plantingCycle,
        btnSource,
        history,
        match,
      } = actionData;

      if (plantingCycle) {
        const pageToGoBack = btnSource === 'page' ? `/${match.params.organizationSlug}/crops/${plantingCycle.id}` : `/${match.params.organizationSlug}/crops?fromPage=cropEdit`;

        history.push(pageToGoBack);
      }

      return Observable.empty();
    });

export const startedGetPlantingCycleEpic = (action$, store) =>
  action$.ofType(GET_PLANTING_CYCLE_STARTED)
    .switchMap((actionData) => {
      const {
        id,
      } = actionData;

      const { company } = store.getState();
      const { location } = company;
      const { id: locationId } = location;
      const api = new Api();

      return Observable.from(api.getLocationCycle({ cycleId: id, locationId }))
        .mergeMap((result) => {
          const { error } = result;
          if (error) {
            return Observable.of(
              toggleErrorDialog(true, error.toString()),
              getPlantingCycleFailed({ error })
            );
          }

          return Observable.of(getPlantingCycleSucceeded(result, location));
        });
    });

export const failedGetPlantingCycleEpic = action$ =>
  action$
    .ofType(GET_PLANTING_CYCLE_FAILED)
    .mapTo(getPlantingCycleEnded());

export const succeededGetPlantingCycleEpic = action$ =>
  action$
    .ofType(GET_PLANTING_CYCLE_SUCCEEDED)
    .mapTo(getPlantingCycleEnded());


export const requestDeletePlantingCycleEpic = action$ =>
  action$.ofType(requestDeletePlantingCycle)
    .mergeMap((actionData) => {
      const {
        payload: {
          history,
          match,
          plantingCycleId,
          isNeedRedirect,
        },
      } = actionData;


      return Observable.from(initAxiosInstanse(get(history, 'location.search')).delete(`/planting-cycles/${plantingCycleId}`))
        .mergeMap(({ error }) => {
          if (error) {
            return Observable.of(
              receiveDeletePlantingCycle({}),
              showNotificationWithTimeout({
                id: `notifications.receiveDeletePlantingCycleError.${Date.now()}`,
                messageId: 'notifications.receiveDeletePlantingCycleError',
                position: 'leftDown',
                iconType: 'error',
                notificationType: 'withActionWide',
              }),
            );
          }

          const actions = [
            receiveDeletePlantingCycle({ plantingCycleId }),
            showNotificationWithTimeout({
              id: `notifications.receiveDeletePlantingCycleSuccess.${Date.now()}`,
              messageId: 'notifications.receiveDeletePlantingCycleSuccess',
              position: 'leftDown',
              iconType: 'success',
              notificationType: 'withActionWide',
            }),
          ];


          if (isNeedRedirect) {
            actions.push(push(`/${match.params.organizationSlug}/crops`));
          }

          return Observable.of(...actions);
        })
        .catch(() => Observable.of(
          receiveDeletePlantingCycle({}),
          showNotificationWithTimeout({
            id: `notifications.receiveDeletePlantingCycleError.${Date.now()}`,
            messageId: 'notifications.receiveDeletePlantingCycleError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const receiveDeletePlantingCycleEpic = (action$, store) =>
  action$.ofType(receiveDeletePlantingCycle)
    .mergeMap((actionData) => {
      const {
        payload: {
          plantingCycleId,
        },
      } = actionData;

      if (plantingCycleId) {
        const { company, newCrops } = store.getState();
        const { allPlantingCycles } = company;
        const { locationCycles } = newCrops;

        const actions = [];

        const receivedAt = Date.now();

        // TODO: переделать на честный запрос за циклами на бэк
        if (allPlantingCycles) {
          actions.push(receiveAllPlantingCycles({
            plantingCycles: allPlantingCycles.filter(item => item.id !== plantingCycleId),
            receivedAt,
          }));
        }

        if (locationCycles) {
          actions.push(receiveCropsData({ data: locationCycles.filter(item => get(item, 'cycle.id') !== plantingCycleId) }));
        }

        if (actions.length) {
          return Observable.of(...actions);
        }

        return Observable.empty();
      }

      return Observable.empty();
    });
