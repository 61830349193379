import moment from 'moment-timezone';

export default function generateFakePhotosWeeklyData({
  plantingCycle,
  photoCategories,
  varieties,
  location
}) {
  const { attributes: { timezone } } = location;
  const { id: plantingCycleId, attributes: plantingCycleAttributes } = plantingCycle;
  const { startDate, endDate } = plantingCycleAttributes;
  const startPoint = moment.tz(startDate, timezone).isoWeekday(1);
  const endPointCandidate = moment.tz(endDate, timezone);
  const nowDate = moment.tz(timezone);
  const endPoint = endPointCandidate.isSameOrBefore(nowDate) ? endPointCandidate : nowDate;
  const diffSize = Math.ceil(endPoint.diff(startPoint, 'days', true) / 7);

  const result = [];
  const now = moment().isoWeekday(1).subtract(28 * 7, 'days');
  const findedVariety = varieties.find(item => item.id === plantingCycle.relationships.variety.data[0].id);
  const { categories } = photoCategories.find(item => item.species === findedVariety.attributes.species);

  for (let i = 0; i < diffSize; i++) {
    categories.forEach((category) => {
      const { code } = category;

      result.push({
        type: 'Photo',
        id: i,
        attributes: {
          category: code,
          date: now.format('YYYY-MM-DD'),
          mainUrl: '-',
          originUrl: '-',
          thumbnailUrl: '-',
        },
        relationships: {
          comments: { data: [] },
          plantingCycle: { data: [{ id: plantingCycleId, type: 'PlantingCycle' }] }
        }
      });
    });

    now.add(7, 'days');
  }

  return result;
}
