import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import classnames from 'classnames';

import BigButton from '../../../../BigButton';
import CloseIcon from '../../../../Icons/Close';
import SelectBoxFilter from '../../../../SelectBoxFilter';
import VersionCard from '../../VersionCard';

import styles from './PlansEditorSidePanel.module.css';

export default class PlansEditorSidePanel extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    opened: PropTypes.bool.isRequired,
    year: PropTypes.string.isRequired,
    versionsFilter: PropTypes.string.isRequired,
    versions: PropTypes.array,
    allVersions: PropTypes.array,
    activeVersionId: PropTypes.number,
    isDeleteVersionInfoFetching: PropTypes.bool,
    isPlansEditorSaving: PropTypes.bool,
    isVersionInfoFetching: PropTypes.bool,

    onClose: PropTypes.func.isRequired,
    onVersionClick: PropTypes.func.isRequired,
    onEditVersionInfo: PropTypes.func.isRequired,
    onPublishVersion: PropTypes.func.isRequired,
    onRestoreVersion: PropTypes.func.isRequired,
    onDeleteVersionInfo: PropTypes.func.isRequired,
    setVersionsFilter: PropTypes.func.isRequired,
  };

  static defaultProps = {
    versions: [],
    allVersions: [],
    activeVersionId: null,
    isDeleteVersionInfoFetching: false,
    isPlansEditorSaving: false,
    isVersionInfoFetching: false,
  };

  handlerFilterChange = ({ value }) => {
    const { setVersionsFilter } = this.props;

    setVersionsFilter({ versionsFilter: value });
  };

  renderEmtyState = () => {
    const { intl: { formatMessage } } = this.props;

    return (
      <div className={styles.emptyState}>
        <div className={styles.emptyStateText}>
          {formatMessage({ id: 'plansVersion.emptyState' })}
        </div>
      </div>
    );
  }

  renderContent = () => {
    const {
      versions, allVersions, activeVersionId, onVersionClick,
      onEditVersionInfo,
      onPublishVersion,
      onRestoreVersion,
      onDeleteVersionInfo,

      isDeleteVersionInfoFetching,
      isPlansEditorSaving,
      isVersionInfoFetching,
    } = this.props;

    return (
      <div className={styles.versionsContainer}>
        {versions.map((version, index) => {
          const {
            id
          } = version;

          return (
            <VersionCard
              key={`version-${id}`}
              version={version}
              activeVersionId={activeVersionId}
              isLastSaved={index === 0}
              onVersionClick={onVersionClick}
              onEditVersionInfo={onEditVersionInfo}
              onPublishVersion={onPublishVersion}
              onRestoreVersion={onRestoreVersion}
              onDeleteVersionInfo={onDeleteVersionInfo}
              isDeleteVersionInfoFetching={isDeleteVersionInfoFetching}
              isPlansEditorSaving={isPlansEditorSaving}
              isVersionInfoFetching={isVersionInfoFetching}
              versions={allVersions}
            />
          );
        })}
      </div>
    );
  }

  render() {
    const {
      intl: { formatMessage },
      opened,
      year,
      versions,
      onClose,
      versionsFilter,
    } = this.props;

    const filterOptions = [
      { label: formatMessage({ id: 'plansVersion.allFilter' }), value: 'all' },
      { label: formatMessage({ id: 'plansVersion.namedFilter' }), value: 'named' },
      { label: formatMessage({ id: 'plansVersion.publishedFilter' }), value: 'published' },
    ];

    return (
      <div
        className={classnames(
          styles.sidePanel,
          {
            [styles.sidePanelOpened]: opened
          }
        )}
      >
        <div className={styles.header}>
          <div className={styles.headerRow}>
            <h3 className={styles.headerText}>
              {formatMessage({ id: 'plansVersion.versionHistory' })}
            </h3>
            <BigButton
              className={styles.closeButton}
              onClick={onClose}
              icon={<CloseIcon />}
              theme='transparent'
              bigIcon
            />
          </div>
          <div className={styles.yearOfPlan}>
            {`${formatMessage({ id: 'plans.plan' })} ${year}`}
          </div>
          <SelectBoxFilter
            value={versionsFilter}
            options={filterOptions}
            onChange={this.handlerFilterChange}
            title={formatMessage({ id: 'plansVersion.filterTitle' })}
            classNameWrapper={styles.filterWrapper}
            classNameButton={styles.filterButton}
            classNameTitle={styles.filterTitle}
          />
        </div>
        <div className={styles.content}>
          {versions.length > 0 ?
            this.renderContent()
            :
            this.renderEmtyState()}
        </div>
      </div>
    );
  }
}
