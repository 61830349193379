import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import classnames from 'classnames';

import BigButton from 'components/BigButton';

import DefaultConfigurableSelect
  from 'components/DefaultConfigurableSelect/components/DefaultConfigurableSelect/DefaultConfigurableSelect';
import ColumnIcon from 'components/Icons/ColumnIcon';
import DefaultSimpleTable from 'components/DefaultSimpleTable';

import Checkbox from 'components/Checkbox';

import styles from './HarvestForecastActionsTable.module.css';

const HarvestForecastActionsTable = ({
  intl,
  breakdown,
  children,
  tableFields,
  tableHeaders,
  isExpandable,
  onFieldsChange,
  onSelectAllChange,
  isAllSelected,
  tableKey,
  graphKey,
  isThreeColumnsFixed,
  isEmpty,
}) => {
  const { formatMessage } = intl;

  const breakdowns = useMemo(() => ({
    compartment: formatMessage({ id: 'dashboards.compartments' }),
    varietyWeight: formatMessage({ id: 'dashboards.varieties' }),
    fruitClass: formatMessage({ id: 'dashboards.types' }),
    plantingCycle: formatMessage({ id: 'dashboards.plantingCycles' }),
  }), [formatMessage]);

  const handlerFieldsChange = (options) => {
    onFieldsChange(options);
  };

  const handlerChangeSelectAll = useCallback(() => {
    onSelectAllChange();
  }, [onSelectAllChange]);

  const selectedIsEmpty = useMemo(() => {
    const selectedKeys = graphKey[tableKey] || [];
    return selectedKeys?.length === 1 && selectedKeys.includes('');
  }, [graphKey, tableKey]);

  const CheckBoxMarkerIcon = useMemo(() => {
    if (isAllSelected) {
      return Checkbox.MARKERS.Arrow;
    }
    return !selectedIsEmpty ? Checkbox.MARKERS.Square : Checkbox.MARKERS.Arrow;
  }, [selectedIsEmpty, isAllSelected]);

  const headerRows = useMemo(() => {
    const headers = tableHeaders.map(row => (
      { label: row.label, id: row.id }
    ));
    return (
      <tr>
        <th className={styles.chartIconWrapper}>
          <Checkbox
            onChange={handlerChangeSelectAll}
            checked={isAllSelected || !selectedIsEmpty}
            value={`select-all-${tableKey}`}
            alignCenter
            noWrap
            pointClass={styles.point}
            markerIcon={CheckBoxMarkerIcon}
            noBackgroundColor={!isAllSelected}
          />
        </th>
        <th
          key='tableHeader-type'
          className={classnames(styles.activeTh, styles.thLeft)}
        >
          {formatMessage({ id: breakdowns[breakdown] || '-' })}
        </th>
        {!isExpandable && (<th>&nbsp;</th>)}
        {headers.map(item => (
          <th
            key={`tableHeader-${item.id}`}
            className={classnames(styles.activeTh, {
              [styles[item.className]]: item.className,
            })}
          >
            <div className={styles.thContent}>
              {item.label}
            </div>
          </th>
        ))}
      </tr>
    );
  }, [
    tableKey,
    tableHeaders,
    isExpandable,
    formatMessage,
    breakdowns,
    breakdown,
    handlerChangeSelectAll,
    isAllSelected,
    CheckBoxMarkerIcon,
    selectedIsEmpty
  ]);

  const emptyText = null;

  // const isGroupedByPlantingCycle = breakdown === 'plantingCycle';

  return (
    <div className={classnames(styles.harvestForecastActionsTable)}>
      <div className={styles.actions}>
        <DefaultConfigurableSelect
          className={styles.fieldsSelect}
          placeholder={(
            <BigButton
              className={styles.fieldsButton}
              title={formatMessage({ id: 'planFact.fields' })}
              icon={<ColumnIcon />}
            />
          )}
          options={tableFields}
          onChange={handlerFieldsChange}
        />
      </div>
      <div className={classnames(styles.defaultTableWrapper, styles.scrolledHorizontal)}>
        <div
          className={styles.tableScroller}
        >
          <DefaultSimpleTable
            className={classnames(styles.table, {
              [styles.threeColumns]: isThreeColumnsFixed,
              // [styles.wideFixedColumn]: isGroupedByPlantingCycle,
              [styles.fixedColumns]: !isEmpty,
            })}
            headerRows={headerRows}
            bodyRows={children}
            emptyText={emptyText}
            size='small'
          />
        </div>
      </div>
    </div>
  );
};

HarvestForecastActionsTable.propTypes = {
  intl: intlShape.isRequired,
  breakdown: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  tableFields: PropTypes.array,
  tableHeaders: PropTypes.array,
  isExpandable: PropTypes.bool,
  onFieldsChange: PropTypes.func.isRequired,
  onSelectAllChange: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool,
  tableKey: PropTypes.string.isRequired,
  graphKey: PropTypes.object,
  isThreeColumnsFixed: PropTypes.bool,
  isEmpty: PropTypes.bool,
};

HarvestForecastActionsTable.defaultProps = {
  tableFields: [],
  tableHeaders: [],
  isExpandable: false,
  isAllSelected: false,
  graphKey: null,
  isThreeColumnsFixed: false,
  isEmpty: false,
};

export default injectIntl(HarvestForecastActionsTable);
