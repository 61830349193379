import CanvasComponent from 'components/CanvasTimeline/components/CanvasComponent';
import { roundRect } from 'components/CanvasTimeline/helpers/DrawUtils';
import { pointInRect } from 'components/CanvasTimeline/helpers/GeometryUtils';

class ScrollBar extends CanvasComponent {
  static HORIZONTAL = 0;

  static VERTICAL = 1;

  type = ScrollBar.HORIZONTAL;

  drawBody = false;

  maxValue = 100;

  currentValue = 0;

  thickness = 10;

  size = 100;

  constructor({
    position = { x: 0, y: 0 },
    type = ScrollBar.HORIZONTAL,
    maxValue = 100,
    thickness = 10,
    size = 100,
    transparent = false
  }) {
    super({
      position,
      transparent
    });

    this.type = type;
    if (maxValue) this.maxValue = maxValue;
    if (thickness) this.thickness = thickness;
    if (size) this.size = size;
  }

  getScrollSize() {
    const ratio = Math.min(this.size / this.maxValue, 1.0);
    return this.size * ratio;
  }

  getSize() {
    const rect = this.getBodyRect();
    return {
      width: rect.width,
      height: rect.height
    };
  }

  getScrollRect() {
    const position = this.getPositionAbs();
    return this.type === ScrollBar.HORIZONTAL ? {
      x: position.x + this.currentValue,
      y: position.y,
      width: this.getScrollSize(),
      height: this.thickness,
    } : {
      x: position.x,
      y: position.y + this.currentValue,
      width: this.thickness,
      height: this.getScrollSize(),
    };
  }

  getBodyRect() {
    const position = this.getPositionAbs();
    return this.type === ScrollBar.HORIZONTAL ? {
      x: position.x,
      y: position.y,
      width: this.size,
      height: this.thickness,
    } : {
      x: position.x,
      y: position.y,
      width: this.thickness,
      height: this.size,
    };
  }

  setMaxValue(value) {
    this.maxValue = value;
  }

  setViewSize(value) {
    this.size = value;
  }

  setScrollPercent(value) {
    const maxValue = this.size - this.getScrollSize();
    this.currentValue = (maxValue / 100) * value;
  }

  draw(ctx, {
    translateAdd = { x: 0, y: 0 }
  } = {}) {
    super.draw(ctx, {
      translateAdd
    });

    if (this.maxValue <= 0 || this.size >= this.maxValue) {
      return;
    }

    if (this.drawBody) {
      roundRect(ctx, {
        ...this.getBodyRect(),
        fill: true,
        radius: {
          tl: 4, bl: 4, tr: 4, br: 4
        },
        color: 'rgba(60,60,60,0.1)'
      });
    }

    roundRect(ctx, {
      ...this.getScrollRect(),
      fill: true,
      radius: {
        tl: 4, bl: 4, tr: 4, br: 4
      },
      color: 'rgba(60,60,60,0.3)'
    });
  }

  handleMove({ x, y }) {
    if (this.scrollPointer) {
      const oldScroll = this.currentValue;

      const dx = x - this.scrollPointer.x;
      const dy = y - this.scrollPointer.y;
      if (this.type === ScrollBar.HORIZONTAL) {
        this.currentValue += dx;
      } else {
        this.currentValue += dy;
      }

      const maxValue = this.size - this.getScrollSize();

      if (this.currentValue < 0) {
        this.currentValue = 0;
      }

      if (this.currentValue > maxValue) {
        this.currentValue = maxValue;
      }

      if (maxValue !== 0) {
        this.emit('scroll', {
          delta: oldScroll - this.currentValue,
          scroll: this.currentValue,
          abs: (this.currentValue / maxValue) * 100
        });
        this.scrollPointer = { x, y };
      }
    }
  }

  isActiveScroll() {
    return Boolean(this.scrollPointer);
  }

  onPointerMove({ x, y }) {
    super.onPointerMove({ x, y });
    this.handleMove({ x, y });
  }

  onPointerMoveOutSide({ x, y }) {
    super.onPointerMoveOutSide({ x, y });
    this.handleMove({ x, y });
  }

  onPointerUpOutSide({ x, y }) {
    super.onPointerUpOutSide({ x, y });
    this.scrollPointer = null;
  }

  onPointerUp({ x, y }) {
    super.onPointerUp({ x, y });
    this.scrollPointer = null;
  }

  onPointerOut({ x, y }) {
    super.onPointerOut({ x, y });
  }

  onPointerDown({ x, y }) {
    super.onPointerDown({ x, y });
    const scrollReact = this.getScrollRect();
    if (pointInRect({ x, y }, scrollReact)) {
      this.scrollPointer = { x, y };
    }
  }
}

export default ScrollBar;
