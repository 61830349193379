import { isObject } from 'lodash';

export const roundRect = (ctx, rect) => {
  const {
    x, y, width, height, radius, fill, stroke, color
  } = rect;
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    if (color) {
      ctx.fillStyle = color;
    }
    ctx.fill();
  }
  if (stroke) {
    if (color) {
      ctx.strokeStyle = color;
    }
    ctx.stroke();
  }
};

export const drawFormattedText = (ctx, position, formattedText) => {
  const {
    x,
    y
  } = position;
  let measureX = 0;
  for (let i = 0; i < formattedText.length; i += 1) {
    const item = formattedText[i];
    if (isObject(item)) {
      if (item.fillStyle) {
        ctx.fillStyle = item.fillStyle;
      }
      if (item.textAlign) {
        ctx.textAlign = item.textAlign;
      }
      if (item.font) {
        ctx.font = item.font;
      }
    } else {
      ctx.fillText(
        item,
        x + measureX,
        y
      );
      measureX += ctx.measureText(item).width + 2;
    }
  }
};

export const drawPathXY = (ctx, path, x, y, scale = 1.0) => {
  ctx.save();
  ctx.translate(x, y);
  ctx.scale(scale || 1.0, scale || 1.0);
  ctx.fill(path);
  ctx.restore();
};

export const drawLine = (ctx, x1, y1, x2, y2, color = 'red') => {
  ctx.save();
  ctx.strokeStyle = color;
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.stroke();
  ctx.restore();
};

export const drawRootComponent = (ctx, root, offset = { x: 0, y: 0 }) => {
  const allComponents = root.toListWithChildren();
  allComponents
    .filter(d => d.isDisplay())
    .sort((a, b) => a.z - b.z)
    .forEach(component => component.draw(ctx, { translateAdd: offset }));

  allComponents.filter(d => d.isDisplay() && d.drawAfter)
    .sort((a, b) => a.z - b.z)
    .forEach(component => component.drawAfter(ctx, { translateAdd: offset }));
};
