import React, {
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import moment from 'moment-timezone';

import { find, isNil } from 'lodash';
import { getDefaultDateRange } from 'helpers/defaultDates';

import Typography from 'components/Typography';
import BigButton from 'components/BigButton';
import DefaultRangePicker from 'components/DefaultRangePicker';
import Paper from 'components/Paper';
import LinesGraph from 'components/LinesGraph';
import LinesGraphTooltip from 'components/LinesGraphTooltip';
import NestedTable from 'components/NestedTable';
import DefaultToggle from 'components/DefaultToggle';
import SimpleBarChart from 'components/SimpleBarChart';
import PageViewTracker from 'components/PageViewTracker';

import ChartNotSelectedIcon from 'components/Icons/ChartNotSelectedIcon';
import LineChartIcon from 'components/Icons/LineChartIcon';
import ResourcesMenu from 'components/ResourcesMenu';

import updateLocationSearch from 'helpers/updateLocationSearch';
import LINE_COLORS from 'helpers/graphColors';
import {
  emptyStateIntervals,
  tableEmptyStateIntervals
} from 'helpers/emptyStateInterval';

import numbersFormatting from 'helpers/numbersFormatting';
import { getRowName } from 'helpers/getRowName';

import { findGetAndRoundingValue } from 'helpers/getValueDetails';

import useQuery from 'hooks/useQuery';
import useSorting from '../../hooks/useSorting';
import useChartTypeToggle from '../../hooks/useChartTypeToggle';


import getSortedRows from '../../helpers/getSortedRows';
import getBodyItems from '../../helpers/getBodyItems';
import getHeaderItems from '../../helpers/getHeaderItems';
import { getDate } from '../../helpers/getDate';

import styles from './LaborWorks.module.css';

const GRAPH_LINE_KEY = 'resourcesGraph';

const RenderTooltipContent = ({
  intl: { formatMessage },
  lines,
  tooltipDate,
  periodType
}) => {
  const currentLine = find(lines, { key: GRAPH_LINE_KEY });

  const currentLinePoints = currentLine?.points;

  const currentDate = tooltipDate
    ? tooltipDate.format('MM-DD-YYYY')
    : undefined;

  if (!currentDate) {
    return null;
  }

  const actualLineValue = findGetAndRoundingValue(
    currentLinePoints,
    currentDate,
    1
  );
  const unitsText = formatMessage({ id: `cunits.mini.${currentLine.units}` });

  const actualLineWithUnits = isNil(actualLineValue)
    ? formatMessage({ id: 'crops.noData' })
    : `${numbersFormatting(actualLineValue)} ${unitsText}`;

  const generatedLines = [
    {
      id: currentLine.key,
      value: actualLineWithUnits,
      header: currentLine.name,
      color: currentLine.color
    }
  ];

  return (
    <LinesGraphTooltip
      lines={generatedLines}
      tooltipDate={tooltipDate}
      periodType={periodType}
    />
  );
};

RenderTooltipContent.propTypes = {
  intl: intlShape.isRequired,
  lines: PropTypes.array.isRequired,
  tooltipDate: PropTypes.object,
  periodType: PropTypes.string
};

RenderTooltipContent.defaultProps = {
  tooltipDate: null,
  periodType: null
};

const LaborWorks = ({
  intl,
  location: { pathname },
  timezone,
  requestLaborResources,
  requestLaborWorkTypes,
  isFetching,
  resources,
  workTypes,
  compartments,
  allPlantingCycles,
  varieties,
  fruitClasses,
  isAddLaborAvailable,
  trackPageViewResources,
  defaultOpen,
} = {}) => {
  const totalKey = 'total';
  const { formatMessage } = intl;

  const [periodType, setPeriodType] = useQuery('periodType', 'week');
  const [graphKey, setGraphKey] = useQuery('graphKey', totalKey);

  const defaultDateRange = getDefaultDateRange('week', true);
  const [anyDateOfPeriodStart, setAnyDateOfPeriodStart] = useQuery(
    'anyDateOfPeriodStart',
    defaultDateRange.startDate
  );
  const [anyDateOfPeriodEnd, setAnyDateOfPeriodEnd] = useQuery(
    'anyDateOfPeriodEnd',
    defaultDateRange.endDate
  );

  const [sorting, updateSorting] = useSorting({
    type: 'work',
    direction: 'up'
  });
  const [isToggleOn, updateToggle] = useChartTypeToggle(false);
  const [clickedItem] = useState(null);

  const handlerPageView = useCallback(() => {
    trackPageViewResources({ sectionName: 'Works', defaultOpen });
  }, [trackPageViewResources, defaultOpen]);

  useEffect(() => {
    requestLaborWorkTypes();
  }, [requestLaborWorkTypes]);

  useEffect(() => {
    requestLaborResources({
      periodType,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd
    });
  }, [
    periodType,
    anyDateOfPeriodStart,
    anyDateOfPeriodEnd,
    requestLaborResources
  ]);

  const onDatesChange = (params) => {
    if (params.periodType) {
      setPeriodType(params.periodType);
    }
    if (params.anyDateOfPeriodStart) {
      setAnyDateOfPeriodStart(params.anyDateOfPeriodStart);
    }
    if (params.anyDateOfPeriodEnd) {
      setAnyDateOfPeriodEnd(params.anyDateOfPeriodEnd);
    }
  };

  const onNestedTableRowClick = ({
    row, e
  }) => {
    e.stopPropagation();

    updateLocationSearch({ graphKey: row.key });
  };


  const tableData = useMemo(() => {
    if (!resources?.rows?.length) {
      return tableEmptyStateIntervals({
        anyDateOfPeriodStart,
        anyDateOfPeriodEnd,
        periodType,
        getDate,
        value: 'meter',
        intl,
        labelLocId: 'plantingCycles.compartment'
      });
    }
    const sortedReportRows = getSortedRows({
      sorting,
      rows: resources.rows
    });
    return {
      headerItems: sortedReportRows
        ? getHeaderItems({
          rows: sortedReportRows,
          periodType,
          intl
        })
        : [],
      bodyItems: sortedReportRows
        ? getBodyItems({
          totalKey,
          rows: sortedReportRows,
          intl,
          compartments,
          allPlantingCycles,
          varieties,
          fruitClasses,
          workTypes,
          currentBreakdown: 'compartment',
          handlerRowClick: (e, key) => {
            e.stopPropagation();
            setGraphKey(key);
          }
        })
        : []
    };
  }, [
    periodType,
    anyDateOfPeriodStart,
    anyDateOfPeriodEnd,
    sorting,
    resources,
    compartments,
    allPlantingCycles,
    varieties,
    fruitClasses,
    intl,
    setGraphKey,
    workTypes,
  ]);

  const getFlatRows = useCallback((value) => {
    const getFlatRowsImpl = rowsToFlat =>
      rowsToFlat.reduce((acc, row) => {
        if (row.children) {
          return [...acc, row, ...getFlatRowsImpl(row.children)];
        }
        return [...acc, row];
      }, []);
    return getFlatRowsImpl(value);
  }, []);

  const generateLineByPoints = (points) => {
    if (points) {
      return Object.keys(points).reduce((acc, key) => {
        const date = moment.utc(new Date(+key * 60000));

        return [...acc, { x: date.format('MM-DD-YYYY'), y: points[key] }];
      }, []);
    }

    return [];
  };

  const getMaxValue = (graphLines) => {
    const valuesArray = graphLines
      .map((line) => {
        const linePoints = line?.points;

        return linePoints ? linePoints.map(item => item.y) : [];
      })
      .reduce((reducer, item) => [...reducer, ...item], []);

    return Math.max(...valuesArray) || 100;
  };

  const graphData = useMemo(() => {
    const flatReportRows = getFlatRows(resources?.rows || []);
    const totalData = find(flatReportRows, { key: graphKey }) || emptyStateIntervals({
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd,
      periodType,
      dataUnits: 'percent'
    });
    const graphName = getRowName({
      ...totalData,
      intl,
      compartments,
      allPlantingCycles,
      varieties,
      fruitClasses,
      currentBreakdown: 'compartment',
      workTypes
    });
    const dataPoints = totalData?.data || {};
    const lines = [
      {
        key: GRAPH_LINE_KEY,
        points: generateLineByPoints(dataPoints),
        name: graphName,
        color: LINE_COLORS[0],
        units: totalData?.dataUnits
      }
    ];
    const preparedPoints = Object.keys(dataPoints).reduce(
      (acc, key) => [
        ...acc,
        {
          id: moment.utc(new Date(+key * 60000)).format('YYYY-MM-DD'),
          date: moment.utc(new Date(+key * 60000)),
          value: dataPoints[key],
          // Данные для тултипа
          roundTo: 0,
          units: totalData?.dataUnits,
          color: LINE_COLORS[0]
        }
      ],
      []
    );
    return {
      graphName,
      preparedPoints,
      maxValue: getMaxValue(lines),
      lines,
      isLinesEmpty: !resources?.rows?.length
    };
  }, [
    periodType,
    anyDateOfPeriodStart,
    anyDateOfPeriodEnd,
    resources,
    graphKey,
    compartments,
    allPlantingCycles,
    varieties,
    fruitClasses,
    intl,
    getFlatRows,
    workTypes
  ]);

  const customRanges = [
    {
      label: formatMessage({ id: 'dashboards.weekly' }),
      value: 'week',
    },
    {
      label: formatMessage({ id: 'dashboards.monthly' }),
      value: 'month',
    }
  ];

  const getTimeFormat = (date, period) => {
    if (period === 'month') {
      const currentYeat = moment().utc().year();
      return moment(date).year() === currentYeat ? 'MMM' : 'MMM YYYY';
    }
    if (period === 'week') {
      return 'MMM D';
    }
    return 'MMM YYYY';
  };


  const timeFormat = useCallback(date =>
    getTimeFormat(date, periodType), [periodType]);

  const customRenderTooltipDate = (tooltipDate, period) =>
    moment(tooltipDate).format(getTimeFormat(tooltipDate, period));

  return (
    <ResourcesMenu title={formatMessage({ id: 'labor.worksTitle' })}>
      <PageViewTracker onMount={handlerPageView} key='resourceType-works' />

      <div>
        <Typography variant='h1' className={styles.header}>
          {formatMessage({ id: 'labor.worksTitle' })}
        </Typography>

        <div className={styles.filters}>
          <DefaultRangePicker
            intl={intl}
            timezone={timezone}
            handlerAfterRangeSelect={onDatesChange}
            periodType={periodType}
            anyDateOfPeriodStart={anyDateOfPeriodStart}
            anyDateOfPeriodEnd={anyDateOfPeriodEnd}
            customRanges={customRanges}
          />
          {isAddLaborAvailable && (
            <BigButton
              className={styles.button}
              title={formatMessage({ id: 'energy.addData' })}
              href={`${pathname}/add`}
              theme='light'
            />
          )}
        </div>

        <div className={styles.content}>
          <Paper className={styles.graphWrapper}>
            <div className={styles.graphHeaderWrapper}>
              <Typography variant='h2' className={styles.header2}>
                {graphData.graphName}
              </Typography>
              <DefaultToggle
                toggleState={isToggleOn}
                onToggleChange={updateToggle}
                leftIcon={<LineChartIcon />}
                rightIcon={<ChartNotSelectedIcon />}
                leftTooltip='Line chart'
                rightTooltip='Bar chart'
              />
            </div>
            <div className={styles.chartsWrapper}>
              {isToggleOn ? (
                <SimpleBarChart
                  items={graphData.preparedPoints}
                  intl={intl}
                  fixedHeight={336}
                  isEmpty={graphData.isLinesEmpty}
                  hoveredItem={clickedItem}
                  periodType={periodType}
                  timeFormat={timeFormat}
                  isFetching={isFetching}
                  showEmptyBars={false}
                  emptyText={formatMessage({ id: 'harvestDashboard.emptyText' })}
                  xTypeTime
                  customRenderTooltipDate={customRenderTooltipDate}
                  defaultBarWidth={16}
                />
              ) : (
                <LinesGraph
                  isOverlapedEmptyState={false}
                  isDataFetching={isFetching}
                  lines={graphData.lines}
                  isEmpty={graphData.isLinesEmpty}
                  maxValue={getMaxValue(graphData.lines)}
                  isLegendDisabled
                  // eslint-disable-next-line arrow-body-style
                  renderTooltipContent={(tooltipDate) => {
                    return (
                      <RenderTooltipContent
                        tooltipDate={tooltipDate}
                        lines={graphData.lines}
                        intl={intl}
                        periodType={periodType}
                      />
                    );
                  }}
                />
              )}
            </div>
          </Paper>

          <NestedTable
            {...tableData}
            selectedRow={graphKey}
            updateSorting={updateSorting}
            tableSorting={sorting}
            onRowClick={onNestedTableRowClick}
            showEmptyState
          />
        </div>
      </div>
    </ResourcesMenu>
  );
};

LaborWorks.propTypes = {
  intl: intlShape.isRequired,

  location: PropTypes.object.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  compartments: PropTypes.array.isRequired,
  allPlantingCycles: PropTypes.array.isRequired,
  varieties: PropTypes.array.isRequired,
  fruitClasses: PropTypes.array.isRequired,
  timezone: PropTypes.string,
  requestLaborResources: PropTypes.func.isRequired,
  requestLaborWorkTypes: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  resources: PropTypes.array,
  workTypes: PropTypes.array,
  isAddLaborAvailable: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  trackPageViewResources: PropTypes.func.isRequired,
};

LaborWorks.defaultProps = {
  timezone: 'Europe/London',
  isFetching: false,
  resources: [],
  workTypes: [],
  isAddLaborAvailable: false,
  defaultOpen: false,
};

export default LaborWorks;
