import classnames from 'classnames';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import styles from './LinkedButton.module.css';


export default class LinkedButton extends Component {
  static propTypes = {
    title: PropTypes.any.isRequired,
    href: PropTypes.string,
    className: PropTypes.string,
    titleClassName: PropTypes.string,
    icon: PropTypes.object,
    disabled: PropTypes.bool,
    theme: PropTypes.string,
  };

  static defaultProps = {
    href: null,
    icon: null,
    className: null,
    theme: null,
    titleClassName: null,
    disabled: false,
  };

  render() {
    const {
      className, theme, titleClassName, href, title, icon, disabled, ...props
    } = this.props;

    if (href) {
      return (
        <a className={classnames(styles.link, { [styles.hasIcon]: !!icon, [styles.defaultTheme]: theme === 'default' }, className)} href={href} {...props}>
          {icon}
          <div className={classnames(styles.title, titleClassName)}>{title}</div>
        </a>
      );
    }

    return (
      <button
        type='button'
        disabled={disabled}
        className={classnames(styles.button, { [styles.hasIcon]: !!icon, [styles.defaultTheme]: theme === 'default', }, className)}
        {...props}
      >
        {icon}
        <div className={classnames(styles.title, titleClassName)}>{title}</div>
      </button>
    );
  }
}
