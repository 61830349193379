import React, {
  useCallback
} from 'react';
import parse from 'color-parse';
import PropTypes from 'prop-types';

import { hslToRgb, rgbToHsl } from 'helpers/colors';
import BaseSlider from 'components/Graphs/components/ColorPicker/components/BaseSlider';

import styles from './index.module.css';

const HueSlider = ({
  initValue,
  onChange
}) => {
  const color = parse(initValue);
  const hsl = rgbToHsl(color?.values?.[0], color?.values?.[1], color?.values?.[2]);
  const alpha = color?.alpha || 0;

  const handleChange = useCallback((value) => {
    if (onChange) {
      const rgb = hslToRgb(value, 1, 0.5);
      onChange(`rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`);
    }
  }, [alpha, onChange]);

  return (
    <BaseSlider
      initValue={hsl[0]}
      onChange={handleChange}
      containerClassName={styles.container}
    />
  );
};

HueSlider.propTypes = {
  initValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

HueSlider.defaultProps = {
  initValue: 'rgba(255,255,255,1)'
};

export default HueSlider;
