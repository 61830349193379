import { find } from 'lodash';
/**
 * Проверяет есть ли в массиве циклы с отличающимися species
 *
 * @param {Object[]} allPlantingCycles - Все crop cycles с дополнительной информацией.
 * @param {number[]} cycleIdsList - Массив id crop cycles, которые нужно проверить.
 * @return {boolean} Есть или нет циклы с отличающимися species.
 */
// eslint-disable-next-line import/prefer-default-export
export const checkIfHasDifferentSpecies = (allPlantingCycles, cycleIdsList = []) => {
  if (cycleIdsList.length === 0) {
    return false;
  }

  const firstPlantingCycleInList = find(allPlantingCycles, { id: Number(cycleIdsList[0]) });

  const firstCycleSpecies = firstPlantingCycleInList?.attributes?.species;

  const isHasDifferentSpecies = cycleIdsList.reduce((acc, id) => {
    const currentPlantingCycle = find(allPlantingCycles, { id: Number(id) });
    const currentSpecies = currentPlantingCycle?.attributes?.species;

    if (firstCycleSpecies !== currentSpecies) {
      return true;
    }

    return acc;
  }, false);

  return isHasDifferentSpecies;
};
