import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import queryString from 'query-string';
import { get } from 'lodash';
import FileSaver from 'file-saver';
import {
  receiveMeasurementsByPeriod,
  requestMeasurementsByPeriod,
  requestMeasurementsPlantsList,
  receiveMeasurementsPlantsList,
  requestMeasurementsAdditionalMetrics,
  receiveMeasurementsAdditionalMetrics,
  requestMeasurementsByDate,
  receiveMeasurementsByDate,
  requestSaveMeasurementsByDate,
  receiveSaveMeasurementsByDate,
  requestCreateNextPlant,
  receiveCreateNextPlant,
  requestUpdatePlant,
  receiveUpdatePlant,
  requestDeletePlant,
  receiveDeletePlant,
  receiveSaveMeasurementsAdditionalMetrics,
  saveMeasurementsAdditionalMetrics,
  receiveSaveMeasurementsGraphMetrics,
  saveMeasurementsGraphMetrics,
  requestDefaultNumberOfPlants,
  receiveDefaultNumberOfPlants,
  requestDefaultNumberOfPlantsUpdate,
  receiveDefaultNumberOfPlantsUpdate,
  requestManualFormsMetrics,
  receiveManualFormsMetrics,
  receiveMeasurementsExport,
  requestMeasurementsExport
} from 'store/measurements/actions';
import initAxiosInstanse from 'api/axios';
import { hideNotification, showNotification, showNotificationWithTimeout } from 'store/notificationCenter/actions';

export const getMeasurementsByPeriodEpic = (action$, store) =>
  action$.ofType(requestMeasurementsByPeriod)
    .switchMap((actionData) => {
      const state = store.getState();

      const {
        router,
      } = state;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          plantingCycleId,
          periodLength,
          metricId
        }
      } = actionData;

      const queryParams = queryString.stringify({
        periodLength,
        metricId,
      });

      const query = queryParams || '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`measurements/${plantingCycleId}?${query}`))
        .mergeMap(({ data }) => Observable.of(
          receiveMeasurementsByPeriod({ ...data }),
        ))
        .catch(() => Observable.of(
          receiveMeasurementsByPeriod(),
          showNotificationWithTimeout({
            id: `notifications.getMeasurementsByPeriodError.${Date.now()}`,
            messageId: 'notifications.getMeasurementsByPeriodError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getMeasurementsPlantsListEpic = (action$, store) =>
  action$.ofType(requestMeasurementsPlantsList)
    .switchMap((actionData) => {
      const state = store.getState();

      const {
        router,
      } = state;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          plantingCycleId,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).get(`plants/${plantingCycleId}`))
        .mergeMap(({ data }) => Observable.of(
          receiveMeasurementsPlantsList({ ...data }),
        ))
        .catch(() => Observable.of(
          receiveMeasurementsPlantsList(),
          showNotificationWithTimeout({
            id: `notifications.getMeasurementsPlantsListError.${Date.now()}`,
            messageId: 'notifications.getMeasurementsPlantsListError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getMeasurementsAdditionalMetricsEpic = (action$, store) =>
  action$.ofType(requestMeasurementsAdditionalMetrics)
    .switchMap((actionData) => {
      const state = store.getState();

      const {
        router,
      } = state;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          plantingCycleId,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).get(`measurements/${plantingCycleId}/additional-metrics`))
        .mergeMap(({ data }) => Observable.of(
          receiveMeasurementsAdditionalMetrics({ ...data }),
        ))
        .catch(() => Observable.of(
          receiveMeasurementsAdditionalMetrics(),
          showNotificationWithTimeout({
            id: `notifications.getMeasurementsAdditionalMetricsError.${Date.now()}`,
            messageId: 'notifications.getMeasurementsAdditionalMetricsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const saveMeasurementsAdditionalMetricsEpic = (action$, store) =>
  action$.ofType(saveMeasurementsAdditionalMetrics)
    .switchMap((actionData) => {
      const state = store.getState();

      const {
        router,
      } = state;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          plantingCycleId,
          metricId
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).put(`measurements/${plantingCycleId}/additional-metrics`, { metrics: metricId }))
        .mergeMap(() => Observable.of(
          receiveSaveMeasurementsAdditionalMetrics({ metricId }),
          // requestMeasurementsAdditionalMetrics({ plantingCycleId })
        ))
        .catch(() => Observable.of(
          receiveSaveMeasurementsAdditionalMetrics(),
          showNotificationWithTimeout({
            id: `notifications.saveMeasurementsAdditionalMetricsEpicError.${Date.now()}`,
            messageId: 'notifications.saveMeasurementsAdditionalMetricsEpicError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const saveMeasurementsGraphMetricsEpic = (action$, store) =>
  action$.ofType(saveMeasurementsGraphMetrics)
    .switchMap((actionData) => {
      const state = store.getState();

      const {
        router,
      } = state;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          plantingCycleId,
          metricId
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).put(`measurements/${plantingCycleId}/graph-metrics`, { metrics: metricId }))
        .mergeMap(() => Observable.of(
          receiveSaveMeasurementsGraphMetrics({ metricId }),
          // requestMeasurementsGraphMetrics({ plantingCycleId })
        ))
        .catch(() => Observable.of(
          receiveSaveMeasurementsGraphMetrics(),
          showNotificationWithTimeout({
            id: `notifications.saveMeasurementsGraphMetricsEpicError.${Date.now()}`,
            messageId: 'notifications.saveMeasurementsGraphMetricsEpicError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getMeasurementsByDateEpic = (action$, store) =>
  action$.ofType(requestMeasurementsByDate)
    .switchMap((actionData) => {
      const state = store.getState();

      const {
        router,
      } = state;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          plantingCycleId,
          date,
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).get(`measurements/${plantingCycleId}/${date}`))
        .mergeMap(({ data }) => Observable.of(
          receiveMeasurementsByDate({ ...data }),
        ))
        .catch(() => Observable.of(
          receiveMeasurementsByDate(),
          showNotificationWithTimeout({
            id: `notifications.getMeasurementsByDateError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const saveMeasurementsByDateEpic = (action$, store) =>
  action$.ofType(requestSaveMeasurementsByDate)
    .switchMap((actionData) => {
      const state = store.getState();

      const {
        router,
      } = state;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          plantingCycleId,
          date,
          data,
          actionAfterSuccess = () => {},
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).put(`measurements/${plantingCycleId}/${date}`, { data }))
        .mergeMap(() => Observable.of(
          actionAfterSuccess,
          receiveSaveMeasurementsByDate(),
          showNotificationWithTimeout({
            id: 'notifications.saveMeasurementsByDate',
            messageId: 'measurements.saveMeasurementsByDate',
            iconType: 'success',
            notificationType: 'withAction',
          })
        ))
        .catch(() => Observable.of(
          receiveSaveMeasurementsByDate(),
          showNotificationWithTimeout({
            id: `notifications.saveMeasurementsByDateError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const updatePlantEpic = (action$, store) =>
  action$.ofType(requestUpdatePlant)
    .switchMap((actionData) => {
      const state = store.getState();

      const {
        router,
      } = state;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          plantingCycleId,
          discriminator,
          name,
          date,
          actionAfterSuccess = () => {},
        }
      } = actionData;


      return Observable
        .from(initAxiosInstanse(searchParams).put(`plants/${plantingCycleId}/${discriminator}`, { name }))
        .mergeMap(() => Observable.of(
          actionAfterSuccess,
          receiveUpdatePlant(),
          requestMeasurementsByDate({
            plantingCycleId,
            date,
          }),
          showNotificationWithTimeout({
            id: 'notifications.updatePlant',
            messageId: 'measurements.updatePlant',
            iconType: 'success',
            notificationType: 'withAction',
          })
        ))
        .catch(() => Observable.of(
          receiveUpdatePlant(),
          showNotificationWithTimeout({
            id: `notifications.updatePlantError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const createNextPlantEpic = (action$, store) =>
  action$.ofType(requestCreateNextPlant)
    .switchMap((actionData) => {
      const state = store.getState();

      const {
        router,
      } = state;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          plantingCycleId,
          extra,
          date,
          actionAfterSuccess = () => {},
        }
      } = actionData;


      return Observable
        .from(initAxiosInstanse(searchParams).post(`plants/${plantingCycleId}`, { extra }))
        .mergeMap(() => Observable.of(
          actionAfterSuccess,
          receiveCreateNextPlant(),
          requestMeasurementsByDate({
            plantingCycleId,
            date,
          }),
          showNotificationWithTimeout({
            id: 'notifications.createNextPlant',
            messageId: 'measurements.createNextPlant',
            iconType: 'success',
            notificationType: 'withAction',
          })
        ))
        .catch(() => Observable.of(
          receiveCreateNextPlant(),
          showNotificationWithTimeout({
            id: `notifications.createNextPlantError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const deletePlantEpic = (action$, store) =>
  action$.ofType(requestDeletePlant)
    .switchMap((actionData) => {
      const state = store.getState();

      const {
        router,
      } = state;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          plantingCycleId,
          discriminator,
          date,
          actionAfterSuccess = () => {},
        }
      } = actionData;

      return Observable
        .from(initAxiosInstanse(searchParams).delete(`plants/${plantingCycleId}/${discriminator}`))
        .mergeMap(() => Observable.of(
          actionAfterSuccess,
          receiveDeletePlant(),
          requestMeasurementsByDate({
            plantingCycleId,
            date,
          }),
          showNotificationWithTimeout({
            id: 'notifications.deletePlant',
            messageId: 'measurements.deletePlant',
            iconType: 'success',
            notificationType: 'withAction',
          })
        ))
        .catch(() => Observable.of(
          receiveDeletePlant(),
          showNotificationWithTimeout({
            id: `notifications.deletePlantError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getDefaultNumberOfPlantsEpic = (action$, store) =>
  action$.ofType(requestDefaultNumberOfPlants)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      return Observable
        .from(initAxiosInstanse(searchParams).get(`location-settings/${organizationId}/measurements.defaultNumberOfPlants`))
        .mergeMap(({ data }) => Observable.of(
          receiveDefaultNumberOfPlants({ ...data }),
        ))
        .catch(() => Observable.of(
          receiveDefaultNumberOfPlants(),
          showNotificationWithTimeout({
            id: `notifications.getDefaultNumberOfPlantsError.${Date.now()}`,
            messageId: 'notifications.getDefaultNumberOfPlantsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const updateDefaultNumberOfPlantsEpic = (action$, store) =>
  action$.ofType(requestDefaultNumberOfPlantsUpdate)
    .switchMap((actionData) => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          value,
          initialValue,
          actionAfterSuccess = () => {},
        }
      } = actionData;


      return Observable
        .from(initAxiosInstanse(searchParams).put(`location-settings/${organizationId}/measurements.defaultNumberOfPlants`, { value }))
        .mergeMap(() => Observable.of(
          actionAfterSuccess,
          receiveDefaultNumberOfPlantsUpdate({ value }),
          showNotificationWithTimeout({
            id: 'notifications.defaultNumberOfPlantsUpdate',
            messageId: 'settings.savePlantsToEnterByDefaultSuccess',
            iconType: 'success',
            notificationType: 'withAction',
          })
        ))
        .catch(() => Observable.of(
          receiveDefaultNumberOfPlantsUpdate({ value: initialValue }),
          showNotificationWithTimeout({
            id: `notifications.defaultNumberOfPlantsUpdateError.${Date.now()}`,
            messageId: 'notifications.backendError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export const getManualFormsMetricsEpic = (action$, store) =>
  action$.ofType(requestManualFormsMetrics)
    .switchMap(() => {
      const {
        router,
        company,
      } = store.getState();

      const {
        location,
      } = company;
      const { id: organizationId } = location;

      const searchParams = get(router, 'location.search');

      const queryParams = queryString.stringify({
        locationId: organizationId,
      });

      const query = queryParams || '';

      return Observable
        .from(initAxiosInstanse(searchParams).get(`manual-forms?${query}`))
        .mergeMap(({ data }) => Observable.of(
          receiveManualFormsMetrics({ ...data }),
        ))
        .catch(() => Observable.of(
          receiveManualFormsMetrics(),
          showNotificationWithTimeout({
            id: `notifications.getManualFormsMetricsError.${Date.now()}`,
            messageId: 'notifications.getManualFormsMetricsError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

const showMeasurementsNotificationEpic = action$ =>
  action$
    .ofType(requestMeasurementsExport)
    .mergeMap(() => Observable.of(
      showNotification({
        id: 'notifications.downloadMeasurementsXSLX',
        messageId: 'notifications.downloadMeasurementsXSLX',
        iconType: 'loading',
        notificationType: 'withAction',
      })
    ));

const getMeasurementsEpic = (action$, store) =>
  action$.ofType(requestMeasurementsExport)
    .switchMap((actionData) => {
      const {
        router,
      } = store.getState();

      const searchParams = get(router, 'location.search');

      const {
        payload: {
          plantingCycleId,
          periodLength,
          metricId,
          actionAfterSuccess = () => {},
        }
      } = actionData;

      const queryParams = queryString.stringify({
        periodLength,
        metricId
      });

      const query = queryParams || '';

      return Observable
        .from(
          initAxiosInstanse(
            searchParams,
            120000,
            {
              credentials: 'include',
              responseType: 'blob',
            }
          )
            .get(`measurements/${plantingCycleId}/export?${query}`)
        )
        .mergeMap((response) => {
          const fileName = get(response, 'headers[x-filename]');

          FileSaver.saveAs(response.data, fileName);

          return Observable.of(
            actionAfterSuccess,
            hideNotification('notifications.downloadMeasurementsXSLX'),
            receiveMeasurementsExport(),
            showNotificationWithTimeout({
              id: 'notifications.measurementsXSLXDownloaded',
              messageId: 'notifications.measurementsXSLXDownloaded',
              iconType: 'success',
              notificationType: 'withAction',
            })
          );
        })
        // .map(({ data }) => receivePlanFactExport({ planFact: data }))
        .catch(() => Observable.of(
          hideNotification('notifications.downloadMeasurementsXSLX'),
          receiveMeasurementsExport(),
          showNotificationWithTimeout({
            id: `notifications.measurementsXSLXDownloadError.${Date.now()}`,
            messageId: 'notifications.measurementsXSLXDownloadError',
            position: 'leftDown',
            iconType: 'error',
            notificationType: 'withActionWide',
          }),
        ));
    });

export default combineEpics(
  getMeasurementsByPeriodEpic,
  getMeasurementsPlantsListEpic,
  getMeasurementsAdditionalMetricsEpic,
  getMeasurementsByDateEpic,
  saveMeasurementsByDateEpic,
  updatePlantEpic,
  createNextPlantEpic,
  deletePlantEpic,
  saveMeasurementsAdditionalMetricsEpic,
  saveMeasurementsGraphMetricsEpic,
  getDefaultNumberOfPlantsEpic,
  updateDefaultNumberOfPlantsEpic,
  getManualFormsMetricsEpic,
  showMeasurementsNotificationEpic,
  getMeasurementsEpic
);
