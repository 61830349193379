import React, {
  useEffect, useRef, useMemo
} from 'react';
import classnames from 'classnames';
import useMouseDrag from 'hooks/useMouseDrag';
import { isNumber } from 'lodash/lang';
import PropTypes from 'prop-types';

import styles from './index.module.css';

// TODO: Refactoring initialization
const BaseSlider = ({
  initValue,
  containerClassName,
  onChange,
  containerStyle
}) => {
  const sliderRef = useRef(null);
  const containerRef = useRef(null);

  const maxSliderX = useMemo(() => {
    if (!containerRef.current) {
      return 0;
    }
    return containerRef.current?.getBoundingClientRect()?.width - 12;
  }, [containerRef.current]); // eslint-disable-line

  const [{
    translation,
    isDragging
  }] = useMouseDrag(containerRef, {
    x: maxSliderX * initValue,
    y: 0
  });

  useEffect(() => {
    if (isNumber(translation?.x) && maxSliderX && isDragging) {
      const sliderPercent = Math.min(Math.max((((initValue + translation?.x)) / maxSliderX), 0), 1);
      if (onChange) {
        onChange(sliderPercent);
      }
    }
  },
  [translation, isDragging, maxSliderX, onChange]); // eslint-disable-line

  return (
    <div
      className={styles.background}
    >
      <div
        ref={containerRef}
        className={classnames(styles.container, containerClassName)}
        style={containerStyle}
      >
        <span
          ref={sliderRef}
          className={styles.slider}
          style={{
            left: (initValue * maxSliderX)
          }}
        />
      </div>
    </div>
  );
};

BaseSlider.propTypes = {
  initValue: PropTypes.string,
  containerClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  containerStyle: PropTypes.object
};

BaseSlider.defaultProps = {
  initValue: 'rgba(255,255,255,1)',
  containerClassName: null,
  containerStyle: {}
};

export default BaseSlider;
