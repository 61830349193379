import { connect } from 'react-redux';
import { getFormValues, getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

import MobileDateRangePickerContent from './MobileDateRangePickerContent';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  formValues: getFormValues('mobileDateRangePickerForm')(state),
  formSyncErrors: getFormSyncErrors('mobileDateRangePickerForm')(state),

  ...props,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MobileDateRangePickerContent));
