import { MEDIA_TABLET } from '../constants';

/**
 * Возвращает true, если ширина окна в диапазоне значений media для телефона (или заданного значения)
 *
 * @param {number} [phoneWidth=720] - Ширина, от которой начинаются планшеты (по-умолчанию 720).
 * @return {boolean} Подходит ли ширина окна под значение мобильных.
 */
const isPhoneView = (phoneWidth = MEDIA_TABLET) => {
  const windowWidth = window.innerWidth;

  if (windowWidth < phoneWidth) {
    return true;
  }

  return false;
};

export default isPhoneView;
