import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  getCurrentLocation,
  getHarvestForecastAccess,
  getIsAddHarvestForecastAvailable,
  getCurrentSpecies,
} from 'store/company/selectors';

import { requestGetForecast } from 'store/harvestForecast/actions';
import { getForecast, getIsForecastFetching } from 'store/harvestForecast/selectors';

import HarvestForecastCreate from '../components/HarvestForecastCreate';

const mapDispatchToProps = {
  requestGetForecast,
};

const mapStateToProps = (state, props) => ({
  ...props,

  location: getCurrentLocation(state),
  isForecastFetching: getIsForecastFetching(state, props),
  forecast: getForecast(state, props),
  harvestForecastAccess: getHarvestForecastAccess(state, props) && getIsAddHarvestForecastAvailable(state, props),
  supportedSpecies: getCurrentSpecies(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(HarvestForecastCreate)));
