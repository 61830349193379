import { connect } from 'react-redux';

import { getIsDashboardsMenuOpened } from '../../../store/dashboardsMenu/selectors';

import {
  setDashboardsMenu,
  setDashboardsCurrentTab,
} from '../../../store/dashboardsMenu/actions';

import { getIsBenchmarkAvailable } from '../../../store/company/selectors';

import DashboardsMenu from '../components/DashboardsMenu';

const mapDispatchToProps = {
  setDashboardsMenu,
  setDashboardsCurrentTab,
};

const mapStateToProps = (state, props) => ({
  isDashboardsMenuOpened: getIsDashboardsMenuOpened(state),
  isBenchmarkAvailable: getIsBenchmarkAvailable(state),

  ...props,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardsMenu);
