import moment from 'moment-timezone';
import getDateFormat from './getDateFormat';

export default function getMinMaxDates({
  startDate,
  endDate,
}) {
  const nowDate = moment();

  let maxDate = nowDate.clone();

  if (endDate) {
    const cycleMaxDate = moment(endDate, getDateFormat('lll'));

    if (!cycleMaxDate.isAfter(nowDate)) {
      maxDate = cycleMaxDate;
    }
  }

  let minDate = nowDate.clone();

  if (startDate) {
    const cycleMinDate = moment(startDate, getDateFormat('lll'));

    if (cycleMinDate.isBefore(nowDate)) {
      minDate = cycleMinDate;
    }
  }

  return {
    minDate,
    maxDate,
  };
}
