import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import moment from 'moment';

import { formatTicksByMask } from 'helpers/formatTicksByMask';
import binarySearch from 'helpers/binarySearch';
import stepTrapeze from './TrapezeCurve';
import ValueTooltip from './ValueTooltip';

import { ReactComponent as RemoveIcon } from './assets/remove.svg';

import styles from './EditableLineChart.module.css';

// TODO: Smelt
const getPointsFromReference = ({
  referencePoints,
  startIndex,
  maxWeek
}) => {
  const nextRefIndex = startIndex + 1;
  const currentReference = referencePoints[startIndex];
  const nextReference =
    nextRefIndex < referencePoints.length
      ? referencePoints[nextRefIndex]
      : null;
  const startKey = currentReference.key;
  const endKey = nextReference
    ? nextReference.key
    : maxWeek + 10080;
  const result = [];
  for (let key = startKey; key < endKey; key += 10080) {
    result.push({
      key,
      value: currentReference.value,
    });
  }
  return result;
};

// TODO: Smelt
export const convertReferenceToDataPoints = ({
  referencePoints,
  points
}) => {
  const intervalsCount = points.length;
  const maxWeek = points[intervalsCount - 1].key;
  let dataPoints = [];
  for (let refIndex = 0; refIndex < referencePoints.length; refIndex += 1) {
    const pointsBetween = getPointsFromReference({
      referencePoints,
      intervalsCount,
      startIndex: refIndex,
      maxWeek
    });
    if (pointsBetween) {
      dataPoints = dataPoints.concat(pointsBetween);
    }
  }
  return dataPoints;
};

export const buildReferencePoints = (points) => {
  const referencePoints = [];
  for (let pointIndex = 0; pointIndex < points.length; pointIndex += 1) {
    const point = points[pointIndex];
    if (pointIndex === 0) {
      referencePoints.push({
        ...point,
      });
    } else {
      const prevPoint = points[pointIndex - 1];
      if (point.value !== prevPoint.value) {
        referencePoints.push({
          ...point,
        });
      }
    }
  }
  return referencePoints;
};

class EditableLineChart extends Component {
  static propTypes = {
    points: PropTypes.array.isRequired,
    margin: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    onValueChanged: PropTypes.func,
    onAfterValueChanged: PropTypes.func,
    xTickPadding: PropTypes.number,
    yTickPadding: PropTypes.number,
    startWeek: PropTypes.number.isRequired,
    endWeek: PropTypes.number.isRequired,
    editable: PropTypes.bool,
    onSelectReferencePoint: PropTypes.func,
    onReferencePointsUpdate: PropTypes.func,
    label: PropTypes.string,
    valueMetrics: PropTypes.object,
  };

  static defaultProps = {
    margin: {
      top: 32,
      right: 28,
      bottom: 20,
      left: 10,
    },
    xTickPadding: 10,
    yTickPadding: 10,
    width: 290,
    height: 210,
    maxValue: 10,
    minValue: 0,
    onValueChanged: null,
    onAfterValueChanged: null,
    editable: true,
    onSelectReferencePoint: null,
    onReferencePointsUpdate: null,
    label: null,
    valueMetrics: null
  };

  state = {
    line: '',
    area: '',
    mouseOver: false,
    mouse: {
      x: 0,
      y: 0
    },
    referencePoints: null,
    points: [],
    selectedPointIndex: 0,
  };

  constructor(props) {
    super(props);
    this.chartWrapperRef = React.createRef();
    this.xAxisRef = React.createRef();
    this.yAxisRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const {
      points,
    } = this.props;
    const {
      referencePoints
    } = this.state;
    this.updateAxis();

    const pointMargin = 5;
    // prevent reload
    if (prevProps.points !== points && points?.length > 0) {
      const referencePointsPayload = {};

      // first init referencePoints
      if (!referencePoints) {
        referencePointsPayload.referencePoints = buildReferencePoints(points).map(p => ({
          ...p,
          x: this.walkers.walkX(this.keyToDate(p.key)) - pointMargin,
          y: this.walkers.walkY(p.value) - pointMargin
        }));
      } else {
        // update first point
        const firstPoint = points[0];
        const lastPoint = points[points.length - 1];
        if (firstPoint?.key < prevProps?.points[0].key) {
          referencePoints.unshift({
            ...firstPoint,
            x: this.walkers.walkX(this.keyToDate(firstPoint.key)) - pointMargin,
            y: this.walkers.walkY(firstPoint.value) - pointMargin
          });
        }

        // remove last points outside new range
        const removeIf = (array, callback) => {
          let i = array.length;
          // eslint-disable-next-line no-plusplus
          while (i--) {
            if (callback(array[i], i)) {
              array.splice(i, 1);
            }
          }
        };
        removeIf(referencePoints, p => p.key > lastPoint.key);

        for (let i = 0; i < points?.length; i += 1) {
          const point = points[i];
          const pointIndex = referencePoints.findIndex(p => p.key === point.key);
          if (pointIndex !== -1) {
            referencePoints[pointIndex] = {
              ...point,
              x: this.walkers.walkX(this.keyToDate(point.key)) - pointMargin,
              y: this.walkers.walkY(point.value) - pointMargin
            };
          }
        }
        referencePointsPayload.referencePoints = referencePoints;
      }

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => ({
        ...prevState,
        line: this.graphs.line(points),
        area: this.graphs.area(points),
        points: [...points],
        ...referencePointsPayload,
      }));
    }
  }

  resetTimeOfDate = (date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  keyToDate = key => Date.parse((new Date((+key) * 60000)).toISOString());

  initWalkers = () => {
    const {
      width,
      height,
      margin,
      maxValue,
      startWeek,
      endWeek,
    } = this.props;
    const xFromValue = moment.utc((+startWeek) * 60000).toDate();
    const xToValue = moment.utc((+endWeek) * 60000).toDate();

    const walkX = d3
      .scaleTime()
      .domain([this.resetTimeOfDate(xFromValue), this.resetTimeOfDate(xToValue)])
      .range([margin.left, width - margin.right]);

    const walkY = d3
      .scaleLinear()
      .domain([0, maxValue])
      .range([height - margin.bottom, margin.top]);

    return {
      walkX,
      walkY
    };
  };

  initGraphs = ({ walkX, walkY }) => ({
    area: d3
      .area()
      .curve(stepTrapeze)
      .x(d => walkX(this.keyToDate(d.key)))
      .y0(walkY(0))
      .y1(d => walkY(d.value)),
    line: d3
      .line()
      .curve(stepTrapeze)
      .x(d => walkX(this.keyToDate(d.key)))
      .y(d => walkY(d.value)),
  });


  initAxis = () => {
    const {
      width,
      height,
      margin,
      xTickPadding,
      yTickPadding,
      points
    } = this.props;

    const weeks = points.length || 0;
    const everyWeeks = Math.floor(weeks / 5);

    return {
      xAxis: g =>
        g
          .attr('transform', `translate(0,${height - margin.bottom})`)
          .call(
            d3
              .axisBottom(this.walkers.walkX)
              .ticks(d3.utcWeek.every(everyWeeks))
              .tickSize(-height + margin.top + margin.bottom)
              .tickPadding(xTickPadding)
              .tickFormat(d => moment.utc(d).isoWeek() + 1)
          )
          .call(gElement =>
            gElement
              .selectAll('line')
              .attr('stroke-opacity', 0.5)
              .attr('stroke-dasharray', '2,2'))
          .selectAll('path')
          .attr('opacity', 0),
      yAxis: g =>
        g
          .attr('transform', `translate(${width - margin.right},0)`)
          .call(
            d3
              .axisRight(this.walkers.walkY)
              .ticks(4)
              .tickSize(-width + margin.right + margin.left)
              .tickPadding(yTickPadding)
              .tickFormat(formatTicksByMask)
          )
          .call(gElement =>
            gElement
              .selectAll('line')
              .attr('stroke-opacity', 0.5)
              .attr('stroke-dasharray', '2,2'))
          .selectAll('path')
          .attr('opacity', 0),
    };
  };

  updateAxis = () => {
    this.walkers = this.initWalkers();
    this.graphs = this.initGraphs(this.walkers);
    this.axis = this.initAxis();

    d3.select(this.xAxisRef.current).call(this.axis.xAxis);
    d3.select(this.yAxisRef.current).call(this.axis.yAxis);
  }

  handlerSvgMouseOut = () => {
    this.dragTarget = null;
    this.chartWrapperRef.current.style.cursor = 'default';
    this.setState(prevState => ({
      ...prevState,
      mouseOver: false,
    }));
  }

  handlerSvgMouseOver = () => {
    this.setState(prevState => ({
      ...prevState,
      mouseOver: true,
    }));
  }

  handlerSvgMouseMove = (ev) => {
    const {
      mouseOver,
      referencePoints,
      selectedPointIndex,
      points
    } = this.state;

    const {
      onValueChanged,
      onReferencePointsUpdate,
      editable,
      minValue,
      maxValue,
    } = this.props;

    if (mouseOver) {
      const mouse = this.transformPointToScreen({
        x: ev.clientX, y: ev.clientY
      });

      let newPoints = null;

      if (this.dragTarget && editable) {
        const key = parseInt(this.dragTarget.getAttribute('data-key'), 10);
        const currentRefPointIndex = referencePoints.findIndex(p => p.key === key);
        if (currentRefPointIndex !== -1 && currentRefPointIndex === selectedPointIndex) {
          const currentRefPoint = referencePoints[currentRefPointIndex];
          const oldValue = currentRefPoint?.value;
          let value = this.walkers.walkY.invert(mouse.y);
          if (value <= minValue) {
            value = minValue;
          }
          if (value >= maxValue) {
            value = maxValue;
          }
          currentRefPoint.value = value;
          currentRefPoint.y = this.walkers.walkY(value) - 5;
          const nextRefPointIndex = currentRefPointIndex + 1;
          const nextRefPointMaxKey = nextRefPointIndex >= referencePoints.length ?
            points[points.length - 1].key + 10080 : referencePoints[nextRefPointIndex].key;
          newPoints = points.map((p) => {
            let newValue = p.value;
            if (p.key >= currentRefPoint.key && p.key < nextRefPointMaxKey) {
              newValue = value;
            }
            return {
              ...p,
              value: newValue,
            };
          });
          if (oldValue !== value) {
            this.dragChanged = true;
          }
          if (onValueChanged && this.dragChanged) {
            onValueChanged(currentRefPoint, newPoints);
          }
          if (onReferencePointsUpdate && this.dragChanged) {
            onReferencePointsUpdate(currentRefPoint, referencePoints);
          }
        }
      }

      this.setState(prevState => ({
        ...prevState,
        mouse,
        ...(newPoints ? {
          line: this.graphs.line(newPoints),
          area: this.graphs.area(newPoints),
          referencePoints: [...referencePoints],
          points: [...newPoints]
        } : {}),
      }));
    }
  }

  handlerMouseDownRefPoint = (ev) => {
    this.dragTarget = ev.currentTarget;
    this.targetKey = parseInt(this.dragTarget.getAttribute('data-key'), 10);
    this.dragChanged = false;
    this.chartWrapperRef.current.style.cursor = 'ns-resize';
  }

  handlerSvgMouseUp = (linePoint) => {
    this.chartWrapperRef.current.style.cursor = 'default';
    const {
      editable,
      onAfterValueChanged,
    } = this.props;
    if (!editable) {
      return;
    }
    const {
      referencePoints,
      points,
    } = this.state;
    if (this.dragTarget) {
      const key = parseInt(this.dragTarget.getAttribute('data-key'), 10);
      const currentRefPoint = referencePoints.find(p => p.key === key);
      this.chartWrapperRef.current.style.cursor = 'default';
      this.dragTarget = null;
      this.targetKey = null;
      if (currentRefPoint && this.dragChanged) {
        if (onAfterValueChanged) {
          onAfterValueChanged(currentRefPoint, points);
        }
      }
      this.setState(prevState => ({
        ...prevState,
      }));
    } else {
      this.handlerDataPointClick(linePoint);
    }
  }

  handlerDataPointClick = (linePoint) => {
    if (this.dragTarget || !linePoint) {
      return;
    }
    const {
      referencePoints,
    } = this.state;
    const {
      onSelectReferencePoint,
      onReferencePointsUpdate
    } = this.props;
    // prevent add duplicate point
    if (referencePoints.some(point => point.key === linePoint.key)) {
      return;
    }
    // prevent mouse shift
    if (this.targetKey && this.targetKey !== linePoint.key) {
      return;
    }
    this.targetKey = null;
    const insetIndex = binarySearch(
      referencePoints,
      item => item.key > linePoint.key
    );
    const pointMargin = 5;
    referencePoints.splice(insetIndex, 0, {
      ...linePoint,
      x: this.walkers.walkX(this.keyToDate(linePoint.key)) - pointMargin,
      y: this.walkers.walkY(linePoint.value) - pointMargin,
    });
    this.setState(prevState => ({
      ...prevState,
      referencePoints: [...referencePoints],
      selectedPointIndex: insetIndex,
    }));
    if (onSelectReferencePoint) {
      onSelectReferencePoint(referencePoints[insetIndex], referencePoints);
    }
    if (onReferencePointsUpdate) {
      onReferencePointsUpdate(referencePoints[insetIndex], referencePoints);
    }
  }

  handlerSelectReferencePoint = (point) => {
    const {
      editable,
      onSelectReferencePoint,
    } = this.props;
    const {
      selectedPointIndex: currentSelectedIndex,
      referencePoints
    } = this.state;
    const selectedPointIndex = referencePoints.findIndex(p => p.key === point.key);
    if (!editable || selectedPointIndex === currentSelectedIndex) {
      return;
    }
    this.setState(prevState => ({
      ...prevState,
      selectedPointIndex,
      prevSelectedPointIndex: prevState.selectedPointIndex,
    }));
    if (onSelectReferencePoint) {
      onSelectReferencePoint(point, referencePoints);
    }
  }

  deletePointByKey = (key) => {
    const {
      referencePoints,
      onReferencePointsUpdate,
    } = this.state;
    const {
      points,
      onAfterValueChanged,
      onSelectReferencePoint
    } = this.props;
    const hoveredRefPointIndex = referencePoints.findIndex(p => p.key === key);
    if (hoveredRefPointIndex > 0) {
      const point = referencePoints[hoveredRefPointIndex];
      referencePoints.splice(hoveredRefPointIndex, 1);
      const newPoints = convertReferenceToDataPoints({
        referencePoints,
        points
      });
      this.setState(prevState => ({
        ...prevState,
        referencePoints,
        points: [...newPoints],
        line: this.graphs.line(newPoints),
        area: this.graphs.area(newPoints),
        selectedPointIndex: 0,
      }));
      if (onSelectReferencePoint) {
        onSelectReferencePoint(referencePoints[0], referencePoints);
      }
      if (onAfterValueChanged) {
        onAfterValueChanged(point, newPoints);
      }
      if (onReferencePointsUpdate) {
        onReferencePointsUpdate(point, referencePoints);
      }
    }
  }

  handlerRemoveButtonClick = ({
    key
  }) => {
    this.deletePointByKey(key);
  }

  handlerKeyDown = (e, point) => {
    const BACKSPACE_KEY_CODE = 8;
    if (e.keyCode === BACKSPACE_KEY_CODE && point) {
      this.deletePointByKey(point.key);
    }
  }

  transformPointToScreen = ({ x, y }) => {
    const svg = this.chartWrapperRef.current;
    if (!this.point) {
      this.point = svg.createSVGPoint();
    }
    this.point.x = x;
    this.point.y = y;
    return this.point.matrixTransform(svg.getScreenCTM().inverse());
  }

  render() {
    const {
      width,
      height,
      margin,
      editable,
      label,
      valueMetrics
    } = this.props;

    const {
      line,
      area,
      mouseOver,
      mouse,
      referencePoints,
      points,
      selectedPointIndex
    } = this.state;
    const hoverElementsOpacity = mouseOver ? 1 : 0;

    const linePoint = {
      x: 0,
      y: 0,
      key: 0,
      value: 0
    };

    const deletePointTooltip = {
      x: 0,
      y: 0,
      show: mouseOver,
      key: null,
    };

    let hoveredPointIndex = null;
    let hoveredPoint = null;
    let lastPointX = null;
    if (this.walkers && referencePoints) {
      const {
        walkX,
        walkY
      } = this.walkers;
      const startX = points[0]?.key;
      const xValue = this.walkers.walkX.invert(mouse.x).getTime() / 60000;
      const pointIndex = Math.round((xValue - startX) / 10080);
      const hoveredKey = points[pointIndex]?.key; // getKeyByX(mouse.x, walkX);
      hoveredPointIndex = points.findIndex(p => p.key === hoveredKey);
      if (hoveredPointIndex !== -1) {
        const lastPoint = points[points.length - 1];
        lastPointX = walkX(this.keyToDate(lastPoint.key));
        hoveredPoint = points[hoveredPointIndex];
        linePoint.x = walkX(this.keyToDate(hoveredKey));
        linePoint.y = walkY(hoveredPoint.value);
        linePoint.key = hoveredKey;
        linePoint.value = hoveredPoint.value;
      }
      const hoveredRefPointIndex = referencePoints.findIndex(p => p.key === hoveredKey);
      const hoveredRefPoint = referencePoints[hoveredRefPointIndex];
      const TOOLTIP_MARGINS = {
        x: 16,
        y: 32
      };
      if (hoveredRefPoint && hoveredRefPointIndex > 0 && mouseOver) {
        deletePointTooltip.x = walkX(this.keyToDate(hoveredKey)) - TOOLTIP_MARGINS.x;
        deletePointTooltip.y = walkY(hoveredRefPoint.value) - TOOLTIP_MARGINS.y;
        deletePointTooltip.show = selectedPointIndex === hoveredRefPointIndex && !this.dragTarget;
        deletePointTooltip.key = hoveredKey;
      } else {
        deletePointTooltip.show = false;
      }
    }

    const showDataTooltip = mouseOver && !this.dragTarget && hoveredPoint;

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        className={styles.chartContainer}
        onKeyDown={(e) => {
          if (selectedPointIndex > 0) {
            e.preventDefault();
            e.stopPropagation();
            this.handlerKeyDown(e, referencePoints[selectedPointIndex]);
          }
        }}
      >
        <svg
          height={height}
          width={width}
          ref={this.chartWrapperRef}
          onMouseLeave={() => {
            this.handlerSvgMouseOut();
          }}
          onMouseEnter={() => {
            this.handlerSvgMouseOver();
          }}
          onMouseMove={(ev) => {
            this.handlerSvgMouseMove(ev);
          }}
          onMouseUp={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            this.handlerSvgMouseUp(linePoint);
          }}
        >
          <g ref={this.xAxisRef} />
          <g ref={this.yAxisRef} />
          <path
            className='area'
            fill='rgba(29, 186, 223, 0.2)'
            d={area}
          />
          <path
            className='line'
            fill='none'
            stroke='#1DBADF'
            strokeWidth='1px'
            d={line}
          />
          <path
            d={`M${margin.left},${height - margin.bottom},${margin.left},${margin.top}`}
            stroke='rgb(180, 197, 214)'
            strokeWidth='1px'
            opacity={0.5}
          />
          <path
            d={`M${margin.left},${height - margin.bottom},${width - margin.right},${height - margin.top}`}
            stroke='rgb(180, 197, 214)'
            strokeWidth='1px'
            opacity={0.5}
          />
          {hoveredPointIndex >= 0 && mouse.x >= margin.left && mouse.x <= lastPointX && (
            <g height={height} width={width} className='mouse-over-effects'>
              <path
                className='mouse-line'
                d={`M${mouse.x},${height - margin.bottom},${mouse.x},${margin.top}`}
                stroke='rgb(180, 197, 214)'
                strokeWidth='1px'
                opacity={hoverElementsOpacity}
              />
            </g>
          )}
          {hoveredPointIndex >= 0 && (
            <circle
              cx={linePoint.x}
              cy={linePoint.y}
              r='4'
              fill='#1DBADF'
              opacity={hoverElementsOpacity}
            />
          )}
          {editable && (
            <g opacity={hoverElementsOpacity}>
              {referencePoints && referencePoints.map((point, index) => (
                <rect
                  key={`rect-ref-${point.key}`}
                  className={`rect-ref-${point.key}`}
                  x={point.x}
                  y={point.y}
                  width='10'
                  height='10'
                  stroke='#FFF'
                  rx='2'
                  ry='2'
                  fill={index === selectedPointIndex ? '#003C68' : '#1DBADF'}
                  onMouseDown={this.handlerMouseDownRefPoint}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.handlerSelectReferencePoint(point);
                  }}
                  data-key={point.key}
                />
              ))}
            </g>
          )}
          {label && (
            <text
              x={width - margin.right - 90}
              y={height - margin.bottom - 10}
              fontStyle='normal'
              fontWeight='normal'
              fontSize='11px'
              fill='#777776'
            >
              {label}
            </text>
          )}
          {hoveredPoint && (
            <ValueTooltip
              x={mouse.x}
              dateKey={hoveredPoint?.key}
              value={hoveredPoint?.value}
              valueMetrics={valueMetrics}
              show={showDataTooltip}
            />
          )}
          {editable && (
            <RemoveIcon
              opacity={deletePointTooltip.show ? 1 : 0}
              x={deletePointTooltip.x}
              y={deletePointTooltip.y}
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                this.handlerRemoveButtonClick(deletePointTooltip);
              }}
            />
          )}
        </svg>
      </div>
    );
  }
}

export default EditableLineChart;
