import { createAction } from 'redux-actions';

export const requestGetForecastList = createAction('REQUEST_GET_FORECAST_LIST');
export const responseGetForecastList = createAction('RESPONSE_GET_FORECAST_LIST');

export const requestCreateForecast = createAction('REQUEST_CREATE_FORECAST');
export const responseCreateForecast = createAction('RESPONSE_CREATE_FORECAST');

export const requestGetForecast = createAction('REQUEST_GET_FORECAST');
export const responseGetForecast = createAction('RESPONSE_GET_FORECAST');

export const requestUpdateForecast = createAction('REQUEST_UPDATE_FORECAST');
export const responseUpdateForecast = createAction('RESPONSE_UPDATE_FORECAST');

export const requestDeleteForecast = createAction('REQUEST_DELETE_FORECAST');
export const responseDeleteForecast = createAction('RESPONSE_DELETE_FORECAST');

export const requestGetForecastVersions = createAction('REQUEST_GET_FORECAST_VERSIONS');
export const receiveGetForecastVersions = createAction('RECEIVE_GET_FORECAST_VERSIONS');

export const requestGetForecastLastVersion = createAction('REQUEST_GET_FORECAST_LAST_VERSION');
export const receiveGetForecastLastVersion = createAction('RECEIVE_GET_FORECAST_LAST_VERSION');

export const requestSaveForecastVersion = createAction('REQUEST_SAVE_FORECAST_VERSION');
export const receiveSaveForecastVersion = createAction('RECEIVE_SAVE_FORECAST_VERSION');

export const requestGetForecastReportData = createAction('REQUEST_GET_FORECAST_REPORT_DATA');
export const responseGetForecastReportData = createAction('RESPONSE_GET_FORECAST_REPORT_DATA');

export const requestGetForecastVersion = createAction('REQUEST_GET_FORECAST_VERSION');
export const receiveGetForecastVersion = createAction('RECEIVE_GET_FORECAST_VERSION');

export const requestRestoreForecastVersion = createAction('REQUEST_RESTORE_FORECAST_VERSION');
export const receiveRestoreForecastVersion = createAction('RECEIVE_RESTORE_FORECAST_VERSION');

export const changeReportFilters = createAction('CHANGE_REPORT_FILTERS');
export const setGraphKey = createAction('SET_GRAPH_KEY');
export const setFields = createAction('SET_FIELDS');
export const setShowVersionPanel = createAction('SET_SHOW_VERSION_PANEL');

export const requestPublishForecast = createAction('REQUEST_PUBLISH_FORECAST');
export const receivePublishForecast = createAction('RECEIVE_PUBLISH_FORECAST');

export const setForecastVersionsFilter = createAction('SET_FORECAST_VERSIONS_FILTER');

export const requestAssistantForecast = createAction('REQUEST_ASSISTANT_FORECAST');
export const receiveAssistantForecast = createAction('RECEIVE_ASSISTANT_FORECAST');

export const setHasChangesTime = createAction('SET_HAS_CHANGES_TIME');

export const clearForecastState = createAction('CLEAR_FORECAST_STATE');

export const requestForecastNotificationSubscribers = createAction('REQUEST_FORECAST_NOTIFICATION_SUBSCRIBERS');
export const receiveForecastNotificationSubscribers = createAction('RECEIVE_FORECAST_NOTIFICATION_SUBSCRIBERS');
export const clearForecastNotificationSubscribers = createAction('CLEAR_FORECAST_NOTIFICATION_SUBSCRIBERS');

export const requestSaveAndPublishForecast = createAction('REQUEST_SAVE_AND_PUBLISH_FORECAST');
export const receiveSaveAndPublishForecast = createAction('RECEIVE_SAVE_AND_PUBLISH_FORECAST');

export const clearForecastReportData = createAction('CLEAR_FORECAST_REPORT_DATA');
export const clearGraphKeys = createAction('CLEAR_GRAPH_KEYS');

export const requestHarvestForecastSettings = createAction('REQUEST_HARVEST_FORECAST_SETTINGS');
export const receiveHarvestForecastSettings = createAction('RECEIVE_HARVEST_FORECAST_SETTINGS');

export const requestForecastSubscribersOfLocation = createAction('REQUEST_FORECAST_SUBSCRIBERS_OF_LOCATION');
export const receiveForecastSubscribersOfLocation = createAction('RECEIVE_FORECAST_SUBSCRIBERS_OF_LOCATION');

export const requestHarvestForecastExport = createAction('REQUEST_HARVEST_FORECAST_EXPORT');
export const receiveHarvestForecastExport = createAction('RECEIVE_HARVEST_FORECAST_EXPORT');

export const setAssistantPanelSettings = createAction('SET_ASSISTANT_PANEL_SETTINGS');

export const requestCreateExtraProduct = createAction('REQUEST_CREATE_EXTRA_PRODUCT');
export const receiveCreateExtraProduct = createAction('RECEIVE_CREATE_EXTRA_PRODUCT');
export const requestUpdateExtraProduct = createAction('REQUEST_UPDATE_EXTRA_PRODUCT');
export const receiveUpdateExtraProduct = createAction('RECEIVE_UPDATE_EXTRA_PRODUCT');
export const requestDeleteExtraProduct = createAction('REQUEST_DELETE_EXTRA_PRODUCT');
export const receiveDeleteExtraProduct = createAction('RECEIVE_DELETE_EXTRA_PRODUCT');

export const requestEditForecastVersionInfo = createAction('REQUEST_EDIT_FORECAST_VERSION_INFO');
export const receiveEditForecastVersionInfo = createAction('RECEIVE_EDIT_FORECAST_VERSION_INFO');

export const requestDeleteForecastVersionInfo = createAction('REQUEST_DELETE_FORECAST_VERSION_INFO');
export const receiveDeleteForecastVersionInfo = createAction('RECEIVE_DELETE_FORECAST_VERSION_INFO');
