import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import classnames from 'classnames';
import { reverse, get } from 'lodash';

import Notification from '../../Notification';

import styles from './NotificationCenter.module.css';


export default class NotificationCenter extends Component {
  static propTypes = {
    notifications: PropTypes.array.isRequired,
    hideNotification: PropTypes.func.isRequired,
  };

  renderNotifications = (notifications, hideNotification) => reverse(notifications).map((item) => {
    const { id } = item;
    const { ...props } = item;

    return (
      <CSSTransition
        key={id}
        timeout={150}
        classNames={{
          enter: styles.fadeEnter,
          enterActive: styles.fadeEnterActive,
          exit: styles.fadeExit,
          exitActive: styles.fadeExitActive,
        }}
      >
        <Notification
          key={`notification-${id}`}
          hideNotification={hideNotification}
          {...props}
        />
      </CSSTransition>
    );
  });

  render() {
    const { notifications, hideNotification } = this.props;

    const notificationsCenter = notifications.filter(item => get(item, 'position') === 'center');
    const notificationsLeft = notifications.filter(item => get(item, 'position') === 'leftDown');
    const notificationsRight = notifications.filter(item => get(item, 'position') === 'rightDown');

    return (
      <>
        <div
          className={classnames(
            styles.notificationCenter,
            styles.notificationCenterLeft,
            { [styles.empty]: notificationsLeft.length === 0 }
          )}
        >
          <TransitionGroup>
            {this.renderNotifications(notificationsLeft, hideNotification)}
          </TransitionGroup>
        </div>
        <div
          className={classnames(
            styles.notificationCenter,
            styles.notificationCenterFixed,
            { [styles.empty]: notificationsCenter.length === 0 }
          )}
        >
          <TransitionGroup>
            {this.renderNotifications(notificationsCenter, hideNotification)}
          </TransitionGroup>
        </div>
        <div
          className={classnames(
            styles.notificationCenter,
            styles.notificationCenterRight,
            { [styles.empty]: notificationsRight.length === 0 }
          )}
        >
          <TransitionGroup>
            {this.renderNotifications(notificationsRight, hideNotification)}
          </TransitionGroup>
        </div>
      </>
    );
  }
}
