import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import classnames from 'classnames';

import { showIntercomLauncher, hideIntercomLauncher } from 'helpers/intercomHelpers';

import CropSwitch from 'components/CropSwitch';

import ArrowsClockwiseIcon from 'components/Icons/ArrowsClockwiseIcon';
import AddToCompareIcon from 'components/Icons/AddToCompareIcon';
import RemoveFromCompareIcon from 'components/Icons/RemoveFromCompareIcon';
import CompleteIcon from 'components/Icons/Complete';

import TextLabel from '../TextLabel';
import BigButton from '../BigButton';
import DropdownMenu from '../DropdownMenu';
import DefaultDialog from '../DefaultDialog';
import ProgressBarCircle from '../ProgressBarCircle';

import styles from './CycleCard.module.css';

import { ReactComponent as WeekIcon } from './assets/week.svg';
import { ReactComponent as HarvestIcon } from './assets/harvest.svg';
import { ReactComponent as SuccessIcon } from './assets/success.svg';
import { ReactComponent as EditIcon } from './assets/edit.svg';

import DeleteIcon from '../Icons/Delete';
import CropIcon from '../CropIcon';


class CycleCard extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    cycleId: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    trackEditPlantingCycleClick: PropTypes.func.isRequired,
    requestDeletePlantingCycle: PropTypes.func.isRequired,
    href: PropTypes.string,
    species: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    compartmentName: PropTypes.string,
    completed: PropTypes.bool,
    isNotSpecified: PropTypes.bool,
    size: PropTypes.string,
    percent: PropTypes.number,
    cycleWeek: PropTypes.number,
    harvestValueText: PropTypes.string,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isAddPlantingCycleAvailable: PropTypes.bool.isRequired,
    cyclesToCompare: PropTypes.array,
    addCycleToComparison: PropTypes.func.isRequired,
    removeCycleFromComparison: PropTypes.func.isRequired,
    isCropCompareAvailable: PropTypes.bool,
  };

  static defaultProps = {
    href: undefined,
    species: undefined,
    startDate: '–',
    endDate: undefined,
    compartmentName: undefined,
    completed: false,
    size: 'normal',
    isNotSpecified: false,
    percent: undefined,
    cycleWeek: undefined,
    harvestValueText: undefined,
    cyclesToCompare: [],
    isCropCompareAvailable: false,
  };

  state = {
    dropdownKey: performance.now(),
    isShowDeleteConfirmation: false,
    isMobileCropSwitchOpen: false,
  };

  dropdownMenuRef = React.createRef();

  handlerEditPlantingCycle = () => {
    const { trackEditPlantingCycleClick } = this.props;

    trackEditPlantingCycleClick('page');
  };

  handlerDeletePlantingCycle = () => {
    this.setState({ isShowDeleteConfirmation: true, dropdownKey: performance.now() });
  };

  handlerDeleteConfirmationOkClick = (plantingCycleId) => {
    const { requestDeletePlantingCycle, history, match } = this.props;

    requestDeletePlantingCycle({
      plantingCycleId,
      history,
      match,
      isNeedRedirect: true,
    });

    this.setState({ isShowDeleteConfirmation: false });
  };

  handlerDeleteConfirmationCancelClick = () => {
    this.setState({ isShowDeleteConfirmation: false });
  };

  handlerAddToCompare = (cycleId) => {
    const { addCycleToComparison } = this.props;

    addCycleToComparison({ cycleId, withSuccessAction: true });
  };

  handlerRemoveFromCompare = (cycleId) => {
    const { removeCycleFromComparison } = this.props;

    removeCycleFromComparison({ cycleId, withSuccessAction: true });
  };

  handlerSwitchCropMobileClick = () => {
    this.setState({ isMobileCropSwitchOpen: true });
    hideIntercomLauncher();

    if (this.dropdownMenuRef) {
      this.dropdownMenuRef.current.closeMenu();
    }
  };

  handlerSwitchCropMobileClose = () => {
    this.setState({ isMobileCropSwitchOpen: false });
    showIntercomLauncher();
  };

  renderCompareButton = (formatMessage, cycleId) => {
    const { cyclesToCompare, isCropCompareAvailable } = this.props;
    const isCycleAlreadyAdded = cyclesToCompare.includes(cycleId);

    if (!isCropCompareAvailable) {
      return null;
    }

    if (isCycleAlreadyAdded) {
      return (
        <BigButton
          className={styles.dropdownItem}
          onClick={() => this.handlerRemoveFromCompare(cycleId)}
          icon={<RemoveFromCompareIcon />}
          theme='menuItem'
          title={formatMessage({ id: 'benchmarking.removeToComparison' })}
        />
      );
    }

    return (
      <BigButton
        className={styles.dropdownItem}
        onClick={() => this.handlerAddToCompare(cycleId)}
        icon={<AddToCompareIcon />}
        theme='menuItem'
        title={formatMessage({ id: 'benchmarking.addToComparison' })}
      />
    );
  };

  renderCropSwitchMobileButton = formatMessage => (
    <BigButton
      className={classnames(styles.dropdownItem, styles.arrowsClockwiseIcon)}
      onClick={this.handlerSwitchCropMobileClick}
      icon={<ArrowsClockwiseIcon />}
      theme='menuItem'
      title={formatMessage({ id: 'cycle.switchCropCycle' })}
    />
  );

  renderContent = (withEditButton) => {
    const {
      intl: { formatMessage },
      cycleId,
      fullName,
      species,
      startDate,
      endDate,
      compartmentName,
      completed,
      size,
      cycleWeek,
      percent,
      harvestValueText,
      isNotSpecified,
      match,
      isAddPlantingCycleAvailable,
    } = this.props;

    const {
      isShowDeleteConfirmation,
      dropdownKey,
      isMobileCropSwitchOpen,
    } = this.state;

    let textEndDate = null;

    if (isNotSpecified) {
      textEndDate = formatMessage({ id: 'cycle.notSpecified' });
    } else {
      textEndDate = endDate || formatMessage({ id: 'cycle.present' });
    }

    const weekText = formatMessage({ id: 'cycle.wk' });
    const percentValue = percent || 0;

    return (
      <>
        {withEditButton && (
          <DropdownMenu ref={this.dropdownMenuRef} className={classnames(styles.dropdownMenu, styles.mobile)} buttonSize='big' key={`dropdown-key-${dropdownKey}`}>
            <div>
              {this.renderCropSwitchMobileButton(formatMessage) }

              {this.renderCompareButton(formatMessage, cycleId)}

              {isAddPlantingCycleAvailable && (
                <BigButton
                  className={styles.dropdownItem}
                  href={`/${
                    match.params.organizationSlug
                  }/crops/${cycleId}/edit?fromPage=crop`}
                  onClick={this.handlerEditPlantingCycle}
                  icon={<EditIcon />}
                  theme='menuItem'
                  title={formatMessage({ id: 'harvest.edit' })}
                />
              )}

              <BigButton
                className={styles.dropdownItem}
                onClick={this.handlerDeletePlantingCycle}
                icon={<DeleteIcon />}
                theme='menuItem'
                title={formatMessage({ id: 'cycle.delete' })}
              />
            </div>
          </DropdownMenu>
        )}
        <div className={styles.icon}>
          <CropIcon crop={species} className={styles.cropIcon} />
          {completed && (
            <div className={styles.completedIcon}>
              <SuccessIcon />
            </div>
          )}
        </div>
        <div className={styles.cardContent}>
          <div className={styles.compartmentName}>{compartmentName}</div>
          <div className={styles.cardName}>
            <span className={styles.cardNameText}>{fullName}</span>
            {completed && (
              <div className={styles.completedLabel}>
                <TextLabel text={formatMessage({ id: 'cycle.completed' })} icon={<CompleteIcon />} />
              </div>
            )}

            {/* shows only on desktop */}
            {size === 'big' && <CropSwitch cycleId={Number(cycleId)} className={styles.cropSwitchDesktop} />}

            {/* shows only on mobile */}
            {isMobileCropSwitchOpen && (
              <CropSwitch
                cycleId={Number(cycleId)}
                className={styles.cropSwitchMobile}
                onCloseMobileMenu={this.handlerSwitchCropMobileClose}
                isMobileVersion
              />
            )}

            {withEditButton && isAddPlantingCycleAvailable && (
              <BigButton
                className={styles.editButton}
                href={`/${
                  match.params.organizationSlug
                }/crops/${cycleId}/edit?fromPage=crop`}
                onClick={this.handlerEditPlantingCycle}
                icon={<EditIcon />}
                theme='transparent'
                tooltip={formatMessage({
                  id: 'plantingCycles.editPlantingCycle'
                })}
              />
            )}
            {withEditButton && isAddPlantingCycleAvailable && (
              <DropdownMenu
                isMini
                key={`dropdown-key-extra-${dropdownKey}`}
                className={classnames(styles.dropdownMenu, styles.desktop)}
                defaultButtonClassName={styles.dropdownMenuDefaultButton}
              >
                <div>
                  {this.renderCompareButton(formatMessage, cycleId)}

                  <BigButton
                    className={styles.dropdownItem}
                    onClick={this.handlerDeletePlantingCycle}
                    icon={<DeleteIcon />}
                    theme='menuItem'
                    title={formatMessage({ id: 'cycle.delete' })}
                  />
                </div>
              </DropdownMenu>
            )}
          </div>
          <div className={styles.cardPeriod}>
            {compartmentName && size === 'big' ? (
              <span className={styles.dotWrapper}>
                <span className={styles.startDate}>{startDate}</span>
                {` – ${textEndDate}`}
                <span className={styles.dot} />
                {compartmentName}
              </span>
            ) : (
              <span>
                <span className={styles.startDate}>{startDate}</span>
                {` – ${textEndDate}`}
              </span>
            )}
          </div>
          <div className={styles.harvest}>
            <div className={styles.harvestWeek}>
              <div className={styles.harvestIcon}>
                <WeekIcon />
              </div>
              <div className={styles.harvestText}>
                {cycleWeek ? `${weekText} ${cycleWeek}` : '–'}
              </div>
            </div>
            {harvestValueText && (
              <div className={styles.harvestValue}>
                <div className={styles.harvestIcon}>
                  <HarvestIcon />
                </div>
                <div className={styles.harvestText}>{harvestValueText}</div>
              </div>
            )}
            <div className={styles.harvestPercent}>
              <div className={styles.harvestIcon}>
                <ProgressBarCircle percent={percentValue} strokeLinecap />
              </div>
              <div className={styles.harvestText}>{`${percentValue}%`}</div>
            </div>
          </div>
        </div>

        {isShowDeleteConfirmation ? (
          <DefaultDialog
            title={<FormattedMessage id='cycle.delete' />}
            onClose={this.handlerDeleteConfirmationCancelClick}
            className={styles.dialog}
            wrapperClassName={styles.wrapper}
            hideCloseIcon={window.innerWidth < 720}
          >
            <div>
              <p className={styles.dialogText}>
                <FormattedMessage id='cycle.deleteVerify' />
              </p>
              <div className={styles.buttons}>
                <BigButton
                  className={styles.cancel}
                  onClick={this.handlerDeleteConfirmationCancelClick}
                  title={<FormattedMessage id='button.cancel' />}
                />
                <BigButton
                  className={styles.confirm}
                  onClick={() => this.handlerDeleteConfirmationOkClick(cycleId)}
                  title={<FormattedMessage id='button.confirmDelete' />}
                  theme='error'
                />
              </div>
            </div>
          </DefaultDialog>
        ) : null}
      </>
    );
  };

  render() {
    const { href, size } = this.props;

    const withEditButton = !href;

    if (href) {
      return (
        <RouterLink
          className={classnames(styles.card, { [styles[size]]: size })}
          to={href}
        >
          {this.renderContent()}
        </RouterLink>
      );
    }

    return (
      <div className={classnames(styles.card, { [styles[size]]: size })}>
        {this.renderContent(withEditButton)}
      </div>
    );
  }
}

export default withRouter(CycleCard);
