import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import BigButton from '../BigButton';

import { ReactComponent as Icon } from './assets/icon.svg';

import styles from './index.module.css';


export default class EmptyState extends Component {
  static propTypes = {
    header: PropTypes.string,
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    isFlex: PropTypes.bool,
    isIcon: PropTypes.bool,
    isButton: PropTypes.bool,
    isInline: PropTypes.bool,
    isFixedSize: PropTypes.bool,
    className: PropTypes.string,
    textClassName: PropTypes.string,
    image: PropTypes.object,
    button: PropTypes.object,
  };

  static defaultProps = {
    header: null,
    text: null,
    isIcon: false,
    isFlex: false,
    isButton: false,
    isInline: false,
    isFixedSize: false,
    className: '',
    textClassName: null,
    image: null,
    button: null,
  };

  handlerSupportButton = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };

  render() {
    const {
      header,
      text,
      isIcon,
      isButton,
      isInline,
      isFixedSize,
      isFlex,
      className,
      textClassName,
      image,
      button,
    } = this.props;

    return (
      <div className={classnames(
        styles.layout,
        className,
        {
          [styles.inline]: isInline,
          [styles.fixedSize]: isFixedSize,
          [styles.flex]: isFlex,
        },
      )}
      >
        {isIcon && (image || <Icon />)}
        {header && <h2 className={styles.header}> {header} </h2>}
        {text && <div className={classnames(styles.text, textClassName)}> {text} </div>}
        {isButton && (
          button || (
            <BigButton
              className={styles.button}
              onClick={this.handlerSupportButton}
              title={<FormattedMessage id='emptyStates.chatBtn' />}
            />
          )
        )}
      </div>
    );
  }
}
