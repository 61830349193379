import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  requestBreakdownHarvestKpiDashboardData,
  requestAllHarvestKpiDashboardData,
  clearHarvestKpiDashboardData,
} from '../../../store/harvestKpiDashboard/actions';

import { getHarvestCategoriesDefault } from '../../../store/harvest/actions';

import { getHarvestFirstCategoryName } from '../../../store/harvest/selectors';

import {
  getOrganization,
  getCurrentSpecies,
  getAllVarieties,
  getAllFruitClasses,
  getCurrentCompartments,
  getAllPlantingCycles,
  getIsOnlyLettuce,
  getIsOnlyVegetables,
  getCurrentLocationPlan
} from '../../../store/company/selectors';

import {
  getHarvestKpiSpeciesOverall,
  getHarvestKpiBreakdown,
  getAllHarvestKpiFetching,
  getBreakdownHarvestKpiFetching,
  getPeriodTypeFromRoute,
  getAnyDateOfPeriodFromRoute,
  getBreakdownTypeFromRoute,
  getRelativeFromRoute,
  getCategoryFromRoute,
} from '../../../store/harvestKpiDashboard/selectors';

import HarvestKpiDashboard from '../components/HarvestKpiDashboard';

const mapDispatchToProps = {
  requestAllHarvestKpiDashboardData,
  requestBreakdownHarvestKpiDashboardData,
  clearHarvestKpiDashboardData,
  getHarvestCategoriesDefault,
};

const mapStateToProps = (state, props) => ({
  ...props,

  locationPlan: getCurrentLocationPlan(state),
  organization: getOrganization(state, props),
  species: getCurrentSpecies(state),
  varieties: getAllVarieties(state, props),
  fruitClasses: getAllFruitClasses(state, props),
  compartments: getCurrentCompartments(state),
  allPlantingCycles: getAllPlantingCycles(state),
  speciesOverallData: getHarvestKpiSpeciesOverall(state),
  breakdownData: getHarvestKpiBreakdown(state),
  harvestFirstCategoryName: getHarvestFirstCategoryName(state),

  isAllHarvestKpiFetching: getAllHarvestKpiFetching(state),
  isBreakdownHarvestKpiFetching: getBreakdownHarvestKpiFetching(state),
  periodType: getPeriodTypeFromRoute(state),
  anyDateOfPeriod: getAnyDateOfPeriodFromRoute(state),

  breakdown: getBreakdownTypeFromRoute(state),
  isRelative: getRelativeFromRoute(state),
  category: getCategoryFromRoute(state),

  isOnlyLettuce: getIsOnlyLettuce(state, props),
  isOnlyVegetables: getIsOnlyVegetables(state, props),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(HarvestKpiDashboard)));
