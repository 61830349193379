import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

// import cn from 'classnames';

import useMountEffect from 'hooks/useMountEffect';
import { hideIntercomLauncher, showIntercomLauncher } from 'helpers/intercomHelpers';
import { formatDate } from 'helpers/datesHelper';

import BigButton from 'components/BigButton';

import MobileDateButton from '../MobileDateButton';
import MobileDateRangePickerContent from '../MobileDateRangePickerContent';

import styles from './MobileMenuContent.module.css';

const MobileMenuContent = ({
  intl: { formatMessage },
  isFirstStep,
  fotmattedRange,
  setIsMobileMenuOpened,
  setIsFirstStep,

  minDate,
  maxDate,
  months,
  pickerState,
  setPickerState,
  staticRanges,
  handlerClickCancel,
  handlerClickApply,
  withMobileVersion,
}) => {
  useMountEffect(() => {
    hideIntercomLauncher();

    return () => {
      showIntercomLauncher();
    };
  });

  if (isFirstStep) {
    return (
      <>
        <MobileDateButton
          date={fotmattedRange}
          onClick={() => setIsFirstStep(false)}
        />
        <div className={styles.mobileControls}>
          <BigButton
            className={styles.mobileCancelButton}
            title={formatMessage({ id: 'button.cancel' })}
            type='button'
            onClick={() => setIsMobileMenuOpened(false)}
            theme='light'
          />
          <BigButton
            title={formatMessage({ id: 'cropsPerformance.showResults' })}
            type='button'
            onClick={() => {}}
            theme='dark'
          />
        </div>
      </>
    );
  }

  const initialValues = {
    startDate: formatDate(pickerState[0]?.startDate, 'dd/MM/yyyy'),
    endDate: formatDate(pickerState[0]?.endDate, 'dd/MM/yyyy'),
  };

  return (
    <div>
      <MobileDateRangePickerContent
        minDate={minDate}
        maxDate={maxDate}
        months={months}
        pickerState={pickerState}
        setPickerState={setPickerState}
        staticRanges={staticRanges}
        handlerClickCancel={handlerClickCancel}
        handlerClickApply={handlerClickApply}
        withMobileVersion={withMobileVersion}
        initialValues={initialValues}
        setIsMobileMenuOpened={setIsMobileMenuOpened}
      />
    </div>
  );
};


MobileMenuContent.propTypes = {
  intl: intlShape.isRequired,
  fotmattedRange: PropTypes.string.isRequired,
  setIsMobileMenuOpened: PropTypes.func.isRequired,
  setIsFirstStep: PropTypes.func.isRequired,
  isFirstStep: PropTypes.bool,

  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  months: PropTypes.number.isRequired,
  pickerState: PropTypes.array.isRequired,
  setPickerState: PropTypes.func.isRequired,
  staticRanges: PropTypes.array.isRequired,
  handlerClickCancel: PropTypes.func.isRequired,
  handlerClickApply: PropTypes.func.isRequired,
  withMobileVersion: PropTypes.func.isRequired,
};

MobileMenuContent.defaultProps = {
  isFirstStep: true,
};

export default injectIntl(MobileMenuContent);
