import React, { useCallback } from 'react';

import {MetricDef, TreeNodePathComponents} from 'store/graphs/types';

import Checkbox from 'components/Checkbox';

import { get } from 'lodash';

import CellDefault from '../CellDefault';

import getPresetMetricKey from '../../../../../../utils/getPresetMetricKey';

// eslint-disable-next-line import/no-unresolved
import styles from '../../index.module.css';

export type OnCheckFunc = (
  presetMetric: Array<TreeNodePathComponents>,
  value: boolean
) => void;

type CellSelectedProp = {
  presetMetric: MetricDef;
  headerKey: string;
  groupId: number;
}

type CellSelectedFunc = {
  onCheck: OnCheckFunc
}

const CellSelected = ({
  presetMetric,
  headerKey,
  groupId,
  onCheck
}: CellSelectedProp & CellSelectedFunc) => {
  const handlerChecked = useCallback((value) => {
    onCheck(presetMetric.node, value.target.checked);
  }, [onCheck, presetMetric]);
  return (
    <CellDefault
      presetMetric={presetMetric}
      headerKey={headerKey}
      groupId={groupId}
      isMetricDatasFetching={false}
      className={styles.checkbox}
    >
      <Checkbox
        className={styles.singleCheckbox}
        onChange={handlerChecked}
        checked={get(presetMetric, headerKey)}
        value={`${getPresetMetricKey(presetMetric, `${groupId}`)}-selected`}
      />
    </CellDefault>
  );
};

export default CellSelected;
