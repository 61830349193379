import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Sheet extends PureComponent {
  static propTypes = {
    children: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  render() {
    return (
      <table className={this.props.className}>
        <tbody>
          {this.props.children}
        </tbody>
      </table>
    );
  }
}

export default Sheet;
