const diag = Math.PI / 3;
const diag2 = diag * 2;
export default {
  draw(context, size) {
    const y = Math.sqrt(size / diag2);
      const x = y * diag;
    context.moveTo(0, -y);
    context.lineTo(x, 0);
    context.lineTo(0, y);
    context.lineTo(-x, 0);
    context.closePath();
  }
};
