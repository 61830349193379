import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { Link as RouterLink } from 'react-router-dom';

import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';
import classnames from 'classnames';
import { uniqueId, isNil } from 'lodash';

import useMountEffect from 'hooks/useMountEffect';
import { parseDate, parseAndFormatDateLocalized, formatDateLocalized } from 'helpers/datesHelper';
import numbersFormatAndRounding from 'helpers/numbersFormatAndRounding';

import BigButton from 'components/BigButton';
import Typography from 'components/Typography';
import DefaultSimpleTable from 'components/DefaultSimpleTable';
import DefaultSimpleTooltip from 'components/DefaultSimpleTooltip';
import InfoIcon from 'components/Icons/InfoIcon';
import EditIcon from 'components/Icons/Edit';
import DefaultCircleLoader from 'components/DefaultCircleLoader';
import DeletePopover from 'components/DeletePopover';
import PageViewTracker from 'components/PageViewTracker';

import tooltipStyles from 'components/Tooltip/index.module.css';
import styles from './HarvestForecastsList.module.css';

const getSpeciesText = (formatMessage, species) => formatMessage({ id: `plantingCycles.species.${species}` });

const getDatesDesc = (start, endInclusive) => {
  const yearDifference = differenceInCalendarYears(
    parseDate(endInclusive),
    parseDate(start),
  );


  const startDate = parseAndFormatDateLocalized(start, yearDifference !== 0 ? 'lll' : 'll');
  const endDate = parseAndFormatDateLocalized(endInclusive, 'lll');

  return `${startDate} – ${endDate}`;
};

const getConvertedForecastsList = (formatMessage, forecastsList = []) => forecastsList.map(listItem => ({
  id: listItem.id,
  name: listItem.name,
  permissions: listItem.permissions,
  description: getDatesDesc(listItem.start, listItem.endInclusive),
  species: getSpeciesText(formatMessage, listItem.species),
  lastPublished: listItem?.publication?.date ? formatDateLocalized(new Date(listItem?.publication?.date), 'llll') : null,
  publishedVersions: listItem?.publication?.version || null,
  avgDailyAccurancy: isNil(listItem?.manualAccuracy?.average) ? null : listItem?.manualAccuracy?.average,
  minDailyAccurancy: isNil(listItem?.manualAccuracy?.min) ? null : listItem?.manualAccuracy?.min,
  maxDailyAccurancy: isNil(listItem?.manualAccuracy?.max) ? null : listItem?.manualAccuracy?.max,
}));

const getRoundedValue = (value) => {
  if (value === 0) {
    return value;
  }

  if (value > 1) {
    return numbersFormatAndRounding(value, 0);
  }

  return numbersFormatAndRounding(value, 2);
};

const handlerDeleteForecast = (requestGetForecastList, requestDeleteForecast, forecastId) => requestDeleteForecast({
  id: forecastId,
  actionAfterSuccess: () => requestGetForecastList({}),
});

const renderName = (name, description) => (
  <div className={styles.forecastName}>
    <div className={styles.name}>{name}</div>
    <div className={styles.description}>{description}</div>
  </div>
);

const renderDailyAccurancyTooltip = (formatMessage, avgDailyAccurancy, minDailyAccurancy, maxDailyAccurancy) => (
  <div className={styles.tooltipContent}>
    <div className={styles.tooltipContentRow}>
      <span>{formatMessage({ id: 'forecast.average' })}</span>
      <span className={styles.tooltipContentBold}>{`${avgDailyAccurancy}%`}</span>
    </div>
    <div className={styles.tooltipContentRow}>
      <span>{formatMessage({ id: 'unrealizedPotential.min' })}</span>
      <span className={styles.tooltipContentBold}>{`${minDailyAccurancy}%`}</span>
    </div>
    <div className={styles.tooltipContentRow}>
      <span>{formatMessage({ id: 'unrealizedPotential.max' })}</span>
      <span className={styles.tooltipContentBold}>{`${maxDailyAccurancy}%`}</span>
    </div>
  </div>
);

const renderHeaderRows = (formatMessage, tooltipId) => (
  <tr>
    <th className={styles.firstHeaderColumn}>{formatMessage({ id: 'forecast.forecast' })}</th>
    <th>{formatMessage({ id: 'cycle.table.species' })}</th>
    <th>{formatMessage({ id: 'forecast.lastPublished' })}</th>
    <th>{formatMessage({ id: 'forecast.publishedVersions' })}</th>
    <th className={styles.lastColumn}>
      <div
        className={styles.infoBlock}
        data-tip={formatMessage({ id: 'forecast.avgDailyAccurancyTooltip' })}
        data-for={tooltipId}
      >
        {formatMessage({ id: 'forecast.avgDailyAccurancy' })}
        <InfoIcon className={styles.infoIcon} />
      </div>
    </th>
  </tr>
);

const renderBodyRows = (formatMessage, requestGetForecastList, requestDeleteForecast, isAddHarvestForecastAvailable, organizationSlug, forecastsList) => forecastsList.map((forecastData) => {
  const isAvgAccurancyAvailable = !isNil(forecastData.avgDailyAccurancy);
  const roundedAvgAccurancy = getRoundedValue(forecastData.avgDailyAccurancy);
  const roundedMinAccurancy = getRoundedValue(forecastData.minDailyAccurancy);
  const roundedMaxAccurancy = getRoundedValue(forecastData.maxDailyAccurancy);

  const isCanDelete = forecastData?.permissions?.delete;
  const isCanEdit = forecastData?.permissions?.edit;

  return (
    <tr key={`forecastRow-${forecastData.id}`} className={styles.tableRow}>
      <td className={styles.firstColumn}>
        <RouterLink
          className={styles.forecastNameLink}
          to={`/${organizationSlug}/crops/forecasts/${forecastData.id}/report`}
        >
          {renderName(forecastData.name, forecastData.description)}
        </RouterLink>
      </td>
      <td>{forecastData.species}</td>
      <td>{forecastData.lastPublished ? forecastData.lastPublished : '–'}</td>
      <td>{forecastData.publishedVersions ? forecastData.publishedVersions : '–'}</td>
      <td className={styles.lastColumn}>
        <div className={styles.avgAccurancyCellContainer}>
          {isAvgAccurancyAvailable ? (
            <div className={styles.avgAccurancyCell}>
              <DefaultSimpleTooltip
                text={renderDailyAccurancyTooltip(formatMessage, roundedAvgAccurancy, roundedMinAccurancy, roundedMaxAccurancy)}
                theme='light'
                className={styles.iconTooltip}
              >
                {`${roundedAvgAccurancy}%`}
              </DefaultSimpleTooltip>
            </div>
          )
            :
            '–'}
          <div className={styles.commandWrapper}>
            {(isAddHarvestForecastAvailable && isCanEdit) && (
              <BigButton
                href={`/${organizationSlug}/crops/forecasts/${forecastData.id}/edit`}
                // onClick={this.handlerEditPlantingCycle}
                className={classnames(styles.edit)}
                icon={<EditIcon className={styles.editIcon} />}
                category='icon'
                tooltip={formatMessage({ id: 'forecast.editForecast' })}
              />
            )}
            {(isAddHarvestForecastAvailable && isCanDelete) && (
              <DeletePopover
                onDelete={() => handlerDeleteForecast(requestGetForecastList, requestDeleteForecast, forecastData.id)}
              />
            )}
          </div>
        </div>
      </td>
    </tr>
  );
});

const HarvestForecastsList = ({
  intl,
  match,
  history,
  forecastsList,
  requestGetForecastList,
  requestDeleteForecast,
  isForecastListFetching,
  harvestForecastAccess,
  isAddHarvestForecastAvailable,
  clearForecastState,
  trackPageViewHarvestForecasts,
}) => {
  const { formatMessage } = intl;
  const { params: { organizationSlug } } = match;

  useMountEffect(() => {
    if (!harvestForecastAccess) {
      history.push(`/${organizationSlug}/crops`);
    }
    clearForecastState({});
    requestGetForecastList({});
  });

  const convertedForecastsList = getConvertedForecastsList(formatMessage, forecastsList?.data);

  const tooltipId = `Table-header-${uniqueId()}`;

  return (
    <div>
      <PageViewTracker onMount={trackPageViewHarvestForecasts} />

      <div className={styles.layout}>
        <div className={styles.content}>
          <div className={styles.controls}>
            <Typography variant='h1' className={styles.title}>
              {formatMessage({ id: 'forecast.harvestForecasts' })}
            </Typography>

            {isAddHarvestForecastAvailable && (
              <BigButton
                // onClick={this.handlerCreatePlantingCycle}
                href={`/${organizationSlug}/crops/forecasts/create`}
                className={classnames(styles.control, styles.createPlantingCycleButton)}
                tabIndex={0}
                title={formatMessage({ id: 'forecast.createForecast' })}
                theme='dark'
              />
            )}
          </div>

          <div className={styles.tableWrapper}>
            {isForecastListFetching && <DefaultCircleLoader />}
            <DefaultSimpleTable
              className={styles.table}
              headerRows={renderHeaderRows(formatMessage, tooltipId)}
              bodyRows={renderBodyRows(formatMessage, requestGetForecastList, requestDeleteForecast, isAddHarvestForecastAvailable, organizationSlug, convertedForecastsList)}
            />
          </div>
        </div>
      </div>

      <ReactTooltip
        className={classnames(tooltipStyles.smallTooltip, styles.tooltipWrapper)}
        id={tooltipId}
        effect='solid'
        html
      />
    </div>
  );
};

HarvestForecastsList.propTypes = {
  intl: intlShape.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  requestGetForecastList: PropTypes.func.isRequired,
  requestDeleteForecast: PropTypes.func.isRequired,
  forecastsList: PropTypes.array,
  isForecastListFetching: PropTypes.bool,
  harvestForecastAccess: PropTypes.bool,
  isAddHarvestForecastAvailable: PropTypes.bool,
  clearForecastState: PropTypes.func.isRequired,
  trackPageViewHarvestForecasts: PropTypes.func.isRequired,
};

HarvestForecastsList.defaultProps = {
  forecastsList: [],
  isForecastListFetching: false,
  harvestForecastAccess: false,
  isAddHarvestForecastAvailable: false,
};


export default HarvestForecastsList;
