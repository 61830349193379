import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { uniqueId } from 'lodash';

import numbersRounding from 'helpers/numbersRounding';
import numbersFormatting from 'helpers/numbersFormatting';

import styles from './Speedometer.module.css';

const getAbsValue = (range, value) => {
  if (range?.start < 0) {
    return Math.abs(range?.start) + value;
  }
  if (range?.start > 0) {
    return value;
  }

  return value;
};

const Speedometer = React.memo(({
  radius,
  wrapperPadding,
  strokeWidth,
  size,
  finalValue,
  expectedValue,
  percentBoxClassName,
  currentValueBoxClassName,

  wrapperClassName,
  segments,
  range,
  captions,
  value,
  // showSegmentBoundaryCaptions,
}) => {
  const showSegmentBoundaryCaptions = false;
  const scaleLength = Math.abs(range?.start) + Math.abs(range?.end);
  const absValue = getAbsValue(range, value);
  const percent = absValue / (scaleLength / 100);
  const limitedPercent = percent > 100 ? 100 : percent; // Для расчётов

  const roundTo = 1;

  const expectedPercent = (expectedValue / finalValue) * 100;
  const limitedExpectedPercent = expectedPercent > 100 ? 100 : expectedPercent;
  const formattedExpectedValue = numbersFormatting(numbersRounding(expectedValue, 'fixed', roundTo));

  const dimensions = (radius * 2) + wrapperPadding;
  const cf = 2 * Math.PI * radius;
  const semiCf = cf / 2;

  const colorDasharry = `${semiCf},${cf}`;

  const rotateDeg = 270 + ((limitedPercent * 180) / 100);
  const rotateDegExpected = 270 + ((limitedExpectedPercent * 180) / 100);
  const rotateDegText = 90 - ((limitedExpectedPercent * 180) / 100);

  const [firstCaption, ...restCaptions] = captions;

  const segmentsFigures = segments.map((segmentItem) => {
    const segmentAbsValueStart = getAbsValue(range, segmentItem.range.start);
    const percentOfSegmentStart = segmentAbsValueStart / (scaleLength / 100);
    const segmentStartValue = semiCf - ((percentOfSegmentStart * semiCf) / 100);
    const dasharray = `${segmentStartValue},${cf}`;

    return {
      dasharray,
      color: segmentItem?.color,
    };
  });

  const startRangeValue = range ? numbersFormatting(range.start) : null;
  const engRangeValue = range ? numbersFormatting(range.end) : null;

  return (
    <div
      className={classnames(styles.wrapper, wrapperClassName, { [styles.small]: size === 'S' })}
      style={{ width: dimensions, height: dimensions }}
    >
      <svg className={styles.meter}>
        <circle
          className={classnames(styles.circle, styles.mask)}
          cx='50%'
          cy='50%'
          // stroke={getScaleColor(color)}
          r={radius}
          strokeDasharray={colorDasharry}
          strokeWidth={strokeWidth}
        />

        {segmentsFigures?.map(segmentItem => (
          <circle
            key={uniqueId('segment-')}
            className={classnames(styles.circle, styles.range)}
            cx='50%'
            cy='50%'
            r={radius}
            strokeDasharray={segmentItem?.dasharray}
            stroke={segmentItem?.color}
            strokeWidth={strokeWidth}
          />
        ))}

        {/* <circle
          className={classnames(styles.circle, styles.mask)}
          cx='50%'
          cy='50%'
          r={radius}
          strokeDasharray={maskDasharray}
          strokeWidth={strokeWidth + 1} // + 1 чтобы не выезжал цвет подложки
        /> */}
      </svg>

      {showSegmentBoundaryCaptions && (
        <div
          className={styles.expectedValue}
          style={{ transform: `rotate(${rotateDegExpected}deg)` }}
        >
          <div
            className={styles.expectedValueAmount}
            style={{ transform: `translate(-50%, -10px) rotate(${rotateDegText}deg)` }}
          >
            <span>
              {formattedExpectedValue}
            </span>
          </div>
          <div className={styles.expectedValueDash}>
            <div className={styles.expectedValueDashBorder} style={{ height: strokeWidth + 8 }} />
          </div>
        </div>
      )}

      {limitedPercent > 0 && (
        <>
          <div className={styles.arrowPoint} />
          <div className={styles.arrow} style={{ transform: `rotate(${rotateDeg}deg)` }} />
        </>
      )}

      <div className={styles.valuesBox}>
        {engRangeValue && (
          <div className={styles.scaleValuesBox}>
            <span
              style={{ left: wrapperPadding / 2 }}
              className={classnames(styles.scaleValue, styles.scaleValueLeft)}
            >
              {startRangeValue}
            </span>
            <span
              style={{ right: wrapperPadding / 2 }}
              className={styles.scaleValue}
            >
              {engRangeValue}
            </span>
          </div>
        )}
        <div>
          <div className={classnames(styles.percentBox, percentBoxClassName)}>
            {firstCaption || '—'}
          </div>
          {restCaptions?.map(captionItem => (
            <div
              key={uniqueId('secondCaption-')}
              className={classnames(styles.currentValueBox, currentValueBoxClassName)}
            >
              {`${captionItem || '—'} `}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

Speedometer.propTypes = {
  radius: PropTypes.number,
  wrapperPadding: PropTypes.number,
  strokeWidth: PropTypes.number,
  size: PropTypes.string,
  finalValue: PropTypes.number,
  expectedValue: PropTypes.number,
  percentBoxClassName: PropTypes.string,
  currentValueBoxClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  segments: PropTypes.array,
  range: PropTypes.object,
  captions: PropTypes.array,
  value: PropTypes.number,
  // showSegmentBoundaryCaptions: PropTypes.bool,
};

Speedometer.defaultProps = {
  radius: 90,
  wrapperPadding: 30,
  strokeWidth: 30,
  size: 'M',
  finalValue: null,
  expectedValue: 0,
  percentBoxClassName: null,
  currentValueBoxClassName: null,
  wrapperClassName: undefined,
  segments: [],
  range: undefined,
  captions: [],
  value: undefined,
  // showSegmentBoundaryCaptions: false,
};

export default Speedometer;
