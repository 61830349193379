import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import styles from './PropertyView.module.css';

const ProperyItem = ({ name, value }) => (
  <div className={styles.item}>
    <div className={styles.name}>{name}</div>
    <div className={styles.value}>{value}</div>
  </div>
);

ProperyItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

const PropertyView = ({ items }) => (
  <div className={styles.propertyView}>
    {items.map(item => <ProperyItem key={uniqueId()} name={item.name} value={item.value} />)}
  </div>
);

const PropertyItemDataShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
});

PropertyView.propTypes = {
  items: PropTypes.arrayOf(PropertyItemDataShape).isRequired
};

export default PropertyView;
