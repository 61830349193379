import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';


import SelectIncidentsDialog from './SelectIncidentsDialog';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  ...props,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SelectIncidentsDialog));
