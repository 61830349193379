import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import styles from './General.module.css';


class General extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  render() {
    return (
      <article className={styles.main}>
        <h1>General Terms and Conditions</h1>

        <p>
          Before you start using Pylot Application you should become familiar with our <RouterLink to={`/${this.props.match.params.organizationSlug}/terms/privacy-and-cookie-policy`}>Privacy and cookie policy</RouterLink> and read
          and agree with the present General Terms and Conditions.
        </p>

        <br />

        <p>
          The following Terms and Conditions apply to the SaaS services offered by Pylot to the client, with whom Pylot
          concludes an Agreement <strong>(the Client)</strong>.
        </p>

        <br />

        <p>
          The Services are provided by Pylot B.V., with its registered office at Vlotlaan 710, 2681 TX Monster and registered with the Chamber of Commerce under number 68947232 <strong>(Pylot)</strong>.
        </p>


        <h2><strong>Article 1.</strong> Definitions</h2>

        <p>
          The capitalised terms in these General Terms and Conditions have (in their singular as well as plural forms)
          the meanings set out below:
        </p>
        <br />
        <p>
          <strong>1.1 Account:</strong>&nbsp;the account which is provided to the Client by Pylot for the purpose of
          enabling use of the Software.
        </p>

        <p>
          <strong>1.2 Agreement:</strong>&nbsp;any Agreement between Pylot and the Client under which Pylot delivers the
          Services to the Client, and of which these General Terms and Conditions form an integral part.
        </p>

        <p>
          <strong>1.3 Application:</strong>&nbsp;the application of Pylot with a website&nbsp;<a href='https://pylot.app'>https://pylot.app</a>.
        </p>

        <p>
          <strong>1.4 Client:</strong>&nbsp;the natural person or legal entity, acting in a professional capacity, with
          whom Pylot has concluded an Agreement.
        </p>

        <p>
          <strong>1.5 End Users:</strong>&nbsp;the employees of the Client enabled via the Client to access the Software
          delivered to the
          Client by Pylot via user accounts.
        </p>

        <p>
          <strong>1.6 General Terms and Conditions:</strong>&nbsp;these terms and conditions.
        </p>

        <p>
          <strong>1.7 Writing:</strong>&nbsp;dated and signed correspondence on paper. This also includes, in addition
          to
          paper as described,
          email and fax messages, insofar as the origin and integrity of such messages is sufficiently established.
        </p>

        <p>
          <strong>1.8 Intellectual Property Rights:</strong>&nbsp;all intellectual property rights and related rights,
          including, but not
          limited to, copyright, database rights, domain names, trade name rights, trademark rights, design rights,
          neighbouring rights, patent rights as well as rights to know-how.
        </p>

        <p>
          <strong>1.9 Materials:</strong>&nbsp;all works, such as websites and applications/web applications, corporate
          identities, logos,
          flyers, brochures, leaflets, lettering, advertisements, marketing and/or communication plans, drafts,
          images, texts, sketches, documentation, consultancy, reports and other creations of the mind, as well as
          the
          preparatory material thereof and the files or data carriers (whether or not decoded) on which the
          materials
          are stored.
        </p>

        <p>
          <strong>1.10 Office Hours:</strong>&nbsp;the hours between 9:00 am and 5:00 pm Dutch time, Monday to Friday,
          excluding official Dutch
          national holidays.
        </p>

        <p>
          <strong>1.11 Party/Parties:</strong>&nbsp;the parties to the Agreement, Pylot and the Client, individually or
          jointly.
        </p>

        <p>
          <strong>1.12 Services:</strong>&nbsp;all services that Pylot delivers to the Client under the Agreement,
          including,
          but not limited
          to: (i) the provision and installation of Software (ii) the provisioning of cloud-based
          Software-as-a-Service via the Application, (iii) the configuration of user accounts, and (iv) the
          maintenance of the Software (as-a-Service).
        </p>

        <p>
          <strong>1.13 Software:</strong>&nbsp;all software (as-a-Service) provided on the platform (Pylot’s
          Application,
          https Pylot domain
          and app, agents installed in the greenhouse for integration with local systems, API) by Pylot pursuant to
          the Agreement.
        </p>

        <p>
          <strong>1.14 Trial Period:</strong>&nbsp;a customized period of time during which Pylot’s Application is
          tested by
          the Client.
        </p>


        <h2><strong>Article 2.</strong>&nbsp;Conclusion and fulfilment of the Agreement</h2>

        <p>
          <strong>2.1</strong>&nbsp;
          All offers submitted by Pylot are without any obligation for Pylot and are valid for a period of
          thirty days after the date of the quotation or offer. Pylot is not obliged to accept an indication of
          acceptance after the expiry of this period, but if Pylot does so, the offer or quotation will be deemed as
          accepted.

        </p>

        <p>
          <strong>2.2</strong>&nbsp;
          If the acceptance by the Client differs (even on a minor point of detail) from the offer, Pylot shall
          not be bound by it. The Agreement will in that case not be concluded in accordance with this varying
          acceptance, unless Pylot expressly indicates otherwise In Writing.

        </p>

        <p>
          <strong>2.3</strong>&nbsp;
          The Agreement is concluded with the acceptance or confirmation of the offer, as indicated above.
        </p>

        <p>
          <strong>2.4</strong>&nbsp;
          Pylot will deliver the Software to the best of its ability, exercising due care and professionalism.

        </p>


        <h2><strong>Article 3.</strong> Applicability, term and termination</h2>


        <p>
          <strong>3.1</strong>&nbsp;
          The General Terms and Conditions apply to all offers submitted by Pylot, their acceptance by the
          Client and the Agreement thus concluded between the Parties and the performance of the Services by Pylot.

        </p>

        <p>
          <strong>3.2</strong>&nbsp;
          The applicability of any conditions or other terms and conditions of the Client are expressly
          excluded.

        </p>

        <p>
          <strong>3.3</strong>&nbsp;
          The Agreement will commence upon the date as agreed between the Parties and will then remain in force
          for the Trial Period.

        </p>

        <p>
          <strong>3.4</strong>&nbsp;
          Following the expiry of the Trial Period the Agreement will be extended for a year if and insofar
          agreed between the Parties. Following the expiry of this year, the Agreement will be extended for another
          year, if and insofar agreed between the Parties. Unless the Agreement is concluded for an indefinite period,
          it cannot be terminated prematurely, with the exception of the events mentioned in these General Terms and
          Conditions.

        </p>

        <p>
          <strong>3.5</strong>&nbsp;
          Pylot may suspend or give notice to terminate the Agreement In Writing with immediate effect, without
          notice of default being required, if the Client defaults on a material obligation under the Agreement.

        </p>

        <p>
          <strong>3.6</strong>&nbsp;
          Pylot may suspend or give notice to terminate the Agreement In Writing with immediate effect, without
          notice of default being required, in the event the Client is declared bankrupt, the Client applies for or is
          granted suspension of payments, the Client’s activities are ceased or its business is wound up.

        </p>

        <p>
          <strong>3.7</strong>&nbsp;
          Where Pylot suspends the performance of the Agreement, Pylot will retain all its rights and claims
          under the Agreement and the applicable laws and regulations.

        </p>

        <p>
          <strong>3.8</strong>&nbsp;
          In the event that the Agreement is terminated, the amounts owed to Pylot by the Client will become
          immediately due and payable (irrespective of the reason for the termination).

        </p>

        <p>
          <strong>3.9</strong>&nbsp;
          In the event that the Agreement is dissolved, the amounts already invoiced will remain payable by the
          Client and no obligation to reverse will be created or arise.

        </p>

        <p>
          <strong>3.10</strong>&nbsp;
          The Client may only cancel the part of the Agreement that has not yet been fulfilled by Pylot.
        </p>


        <h2><strong>Article 4.</strong> Delivery of Services</h2>

        <p>
          <strong>4.1</strong>&nbsp;
          On conclusion of the Agreement, Pylot will endeavour to deliver the Services within a reasonable
          period of time in conformity with the Agreement.

        </p>

        <p>
          <strong>4.2</strong>&nbsp;
          The client will provide Pylot with all the support needed and desirable to enable the correct and
          timely delivery of the Services. The Client will in any event:
        </p>

        <ol type='a'>
          <li>
            provide all the data and other information which Pylot indicates are necessary, or which the Client
            should reasonably understand are essential for the delivery of the Services;
          </li>

          <li>give temporary access to Pylot support to set up integration (Team Viewer);</li>

          <li>support installation of integration agents on local machines.</li>
        </ol>

        <p>
          <strong>4.3</strong>&nbsp;
          The Client is responsible for providing correct data and information (via the Software). The results
          and the decisions shown in the Software are based on the data provided by the Client. Pylot does not accept
          any liability in this regard.

        </p>

        <p>
          <strong>4.4</strong>&nbsp;
          Pylot may engage third parties for the purpose of fulfilling the Agreement. Any associated costs will
          only be for the Client’s account where this has been agreed in advance.

        </p>

        <p>
          <strong>4.5</strong>&nbsp;
          In the event that employees of Pylot perform work at the Client’s office or another location
          designated by the Client when fulfilling the Agreement, the Client will provide all the support and
          facilities necessary to enable the performance of the work, at no cost.

        </p>


        <h2><strong>Article 5.</strong> Use of the Software</h2>


        <p>
          <strong>5.1</strong>&nbsp;
          The Client requires an Account to use the Software. Pylot will endeavour to set up the Account’s
          initial configuration for the Client within four weeks after the conclusion of the Agreement. The Client can
          request additional user accounts for the End Users with the organisation Account.

        </p>

        <p>
          <strong>5.2</strong>&nbsp;
          The Client shall secure the Account(s) by means of a user name and password. The password(s) in
          particular must be kept strictly confidential. Pylot may assume that all the activities carried out through
          the Client’s Account(s), after the username and password were used to log in, take place under the
          supervision and control of the Client.

        </p>

        <p>
          <strong>5.3</strong>&nbsp;
          Pylot will endeavour to connect the Client’s Account at no cost. Pylot maintains the right to charge a
          fee for connecting a new device or a connection with another system, if the connection set-up cannot be
          completed without purchasing third party products or services. In such cases, Pylot will inform the Client
          beforehand about it.

        </p>

        <p>
          <strong>5.4</strong>&nbsp;
          If and insofar the Client gives Pylot access to the system of the Client, for instance via Team
          Viewer, it is the responsibility of the Client to supervise the connections and to remove the access of
          Pylot. Furthermore, the Client is responsible for (inspecting the) correct operation of the machinery from
          the Client. Pylot does not accept any liability in this regard.

        </p>


        <h2><strong>Article 6.</strong> Rules of use</h2>

        <p>
          <strong>6.1</strong>&nbsp;
          It is prohibited to use the Software in a manner that is in violation of these terms and conditions or
          applicable laws and regulations. Additionally, using the Software in a manner that may cause hindrance or
          loss and/or damage to Pylot or any third party is expressly not permitted.

        </p>

        <p>
          <strong>6.2</strong>&nbsp;
          If Pylot determines that the Client or an End User has violated these terms and conditions or the law,
          or receives a complaint in this regard, Pylot may take measures to end the violation. Pylot will then block
          access to the information concerned.

        </p>

        <p>
          <strong>6.3</strong>&nbsp;
          If, in Pylot's opinion, the operation of the computer systems or network of Pylot or third parties
          and/or service provision via the internet is obstructed, impaired or otherwise at risk, in particular as a
          result of the transmission of excessive amounts of email or other data, leaked personal data or virus
          activity, Trojan horses and similar software, Pylot is authorised to take any and all measures it deems
          reasonably necessary to avert or prevent such risk. These measures include, but are not limited to,
          suspension of the Software and termination of the Agreement.

        </p>

        <p>
          <strong>6.4</strong>&nbsp;
          Pylot is authorised at all times to report any criminal acts that are discovered and will cooperate
          with duly authorised orders and commands. In addition, Pylot is authorised to provide the name, address, IP
          address and other data identifying the Client/an End User of the Software to a third party who has
          complained that the Client/an End User of the Software has violated its rights or these terms and
          conditions, provided that:
        </p>

        <ol type='a'>
          <li>it is sufficiently plausible that the information, on its own, is unlawful and harmful with regard to
            the third party;
          </li>
          <li>the third party has a genuine interest in obtaining the data;</li>
          <li>it is plausible that, in the specific case, there is no less far-reaching measure to obtain the data;
          </li>
          <li>the weighing of the interests involved entails that the third party’s interest should prevail.</li>
        </ol>

        <p>
          <strong>6.5</strong>&nbsp;
          The Client shall follow all reasonable instructions issued by Pylot related to the use of the
          Software.

        </p>

        <p>
          <strong>6.6</strong>&nbsp;
          Pylot may recover from the Client any loss and/or damage sustained as a result of violations of the
          rules under this article. The Client indemnifies Pylot against any and all third-party claims pertaining to
          loss and/or damage arising from a violation of the rules under this article.

        </p>


        <h2><strong>Article 7.</strong> Modifications and improvements</h2>

        <p>
          <strong>7.1</strong>&nbsp;
          Pylot may modify the Software to correct errors, add new functionality or improve performance. While
          the Parties may consult on such modifications in advance and the Client may make suggestions, the final
          decision on whether or not to implement the modification will be made by Pylot.

        </p>

        <p>
          <strong>7.2</strong>&nbsp;
          Pylot will endeavour to remedy any faults in the Software, but is also dependent on its suppliers in
          this regard. Pylot is entitled to not install certain updates or upgrades of suppliers it if believes that
          installing such updates or upgrades will not benefit the operation of the Software.

        </p>

        <p>
          <strong>7.3</strong>&nbsp;
          If implementing modifications and improvements may have the effect of restricting the availability of
          the Software, this will be carried out as far as possible outside Office Hours. Emergency maintenance may be
          carried out at any moment and will not be announced in advance.

        </p>


        <h2><strong>Article 8.</strong> Support</h2>

        <p>
          <strong>8.1</strong>&nbsp;
          Pylot will offer the End-Users and the Client a reasonable level of support with regard to questions
          about the use and management of the Software, as well as with technical issues related to the Software.

        </p>

        <p>
          <strong>8.2</strong>&nbsp;
          The support referred to in Article 8.1 will be provided via a help desk, which can be reached during
          Office Hours.

        </p>

        <p>
          <strong>8.3</strong>&nbsp;
          Pylot endeavours to handle requests submitted to the help desk within a reasonable term. The time
          needed to resolve reported issues may vary.

        </p>


        <h2><strong>Article 9.</strong> Availability</h2>

        <p>
          <strong>9.1</strong>&nbsp;
          Pylot will endeavour to ensure the Software remain online as far as possible, but cannot guarantee
          uninterrupted availability.

        </p>

        <p>
          <strong>9.2</strong>&nbsp;
          If, in Pylot's opinion, a risk is posed to the operation of the computer systems or network of Pylot
          or third parties, for example in the event of a D(DOS) attack or malware activities, Pylot is authorised to
          take any and all measures it deems reasonably necessary to avert the risk and limit or prevent any loss
          and/or damage. This may result in degraded availability.

        </p>

        <p>
          <strong>9.3</strong>&nbsp;
          Pylot is not obliged to make back-ups, unless this has been agreed to in a Service Level Agreement.

        </p>


        <h2><strong>Article 10.</strong> Security and privacy</h2>

        <p>
          <strong>10.1</strong>&nbsp;
          Pylot will use its best ability to protect the Software against misuse, abuse and unauthorised access
          to the Client’s data.

        </p>

        <p>
          <strong>10.2</strong>&nbsp;
          The fulfilment of the Agreement may entail the processing of personal data by Pylot on behalf of the
          Client, with the Client being considered as the controller and Pylot as the data processor. In the event
          that personal data are processed, the Parties will conclude a data processor’s agreement in which agreements
          on the processing and security of such personal data will be laid down. The aforementioned terms have the
          meanings set out in the applicable privacy legislation.

        </p>

        <p>
          <strong>10.3</strong>&nbsp;
          The Client warrants that any inputting or uploading of (personal) data to Pylot is lawful and that
          the processing of such data in accordance with the Agreement does not contravene any applicable privacy
          legislation and regulations.

        </p>


        <h2><strong>Article 11.</strong> Additional work</h2>

        <p>
          <strong>11.1</strong>&nbsp;
          If the Client requests supplementary work, Software or Services falling outside the scope of the
          Agreement, the Parties will consult one another in relation to this request and Pylot will prepare a
          supplementary quotation. Pylot will not carry out the additional work until the Client has accepted the
          quotation.

        </p>


        <h2><strong>Article 12.</strong> Payment</h2>

        <p>
          <strong>12.1</strong>&nbsp;
          During the Trial Period the Client shall not be charged a fee. After the Trial Period, Pylot will
          issue an invoice for all amounts owed and may invoice in advance and electronically. Any objection raised by
          the Client to (the amount of) an invoice will not suspend the payment obligation.

        </p>

        <p>
          <strong>12.2</strong>&nbsp;
          All invoices are subject to a payment term of 14 days, unless the invoice specifies a different
          payment term or another term has been agreed to In Writing.

        </p>

        <p>
          <strong>12.3</strong>&nbsp;
          If an invoice is not paid within the payment term, the Client will be in default without notice of
          default being required. In that case, Pylot may suspend the delivery of all or part of the Software. Pylot
          is not liable for any resulting loss and/or damage sustained by the Client.

        </p>

        <p>
          <strong>12.4</strong>&nbsp;
          All amounts owed to Pylot are immediately due and payable in the event the Client is declared
          bankrupt, the Client applies for or is granted suspension of payments, the Client’s activities are ceased or
          its business is wound up.

        </p>

        <p>
          <strong>12.5</strong>&nbsp;
          All prices quoted by Pylot are in euros and are exclusive of VAT and other government levies.
        </p>

        <p>
          <strong>12.6</strong>&nbsp;
          If a fee is based on information provided by the Client and such information proves to be incorrect,
          Pylot is authorised to adjust the fee accordingly, even after the Agreement has already been concluded.

        </p>

        <p>
          <strong>12.7</strong>&nbsp;
          Fees that are due periodically, can be adjusted annually with due regard for the Consumer Price Index
          or a different index or standard agreed between the Parties. The Client is not granted any right of
          termination in relation to such adjustment.

        </p>

        <p>
          <strong>12.8</strong>&nbsp;
          If the Client fails to pay the amounts due within the agreed term, the Client shall be liable for
          payment of the statutory commercial interest, referred to in Section 6:119a of the Dutch Civil Code, on the
          outstanding amount, without notice of default being required.

        </p>

        <p>
          <strong>12.9</strong>&nbsp;
          If, after receiving a demand for payment or notice of default, the Client fails to pay the amount
          due, Pylot may refer the claim for collection, in which case the Client shall also be liable to pay in full
          all judicial and extrajudicial costs, including all costs and fees of lawyers and external experts, in
          addition to the total amount due at that time.

        </p>


        <h2><strong>Article 13.</strong> Liability and force majeure</h2>

        <p>
          <strong>13.1</strong>&nbsp;
          Because of the fact that the Client shall not be charged a compensation for delivery of the Software
          during the Trial Period, Pylot is not liable for loss and/or damages resulting from a failure in the
          performance of the Agreement, an unlawful act or otherwise during the Trial Period, except in the event that
          the loss and/or damage is attributable to (1) wilful misconduct or deliberate recklessness on the part of
          Pylot’s management, or (2) death or bodily injury.

        </p>

        <p>
          <strong>13.2</strong>&nbsp;
          After termination of the Trial Period, Pylot's liability for loss and/or damages resulting from a
          failure in the performance of the Agreement, an unlawful act or otherwise is limited to the amount paid to
          Pylot by the insurer. Pylot will use its best endeavours to ensure the insurer pays out. If no payout is
          made, liability for each occurrence (where a series of consecutive occurrences is considered to constitute a
          single occurrence) will be limited to the amount (exclusive of VAT) that the Client has paid under the
          Agreement during the six months prior to the loss and/or damage occurring.

        </p>

        <p>
          <strong>13.3</strong>&nbsp;
          Pylot is only liable for direct loss and/or damage arising from an attributable failure in the
          performance of the Agreement.

        </p>

        <p>
          <strong>13.4</strong>&nbsp;
          Any limitation or exclusion of liability stipulated in the Agreement shall not apply in the event
          that the loss and/or damage is attributable to (1) wilful misconduct or deliberate recklessness on the part
          of Pylot’s (company) management, or (2) death or bodily injury.

        </p>

        <p>
          <strong>13.5</strong>&nbsp;
          Any right to claim compensation is at all times subject to the condition that the Client notify Pylot
          of the loss and/or damage In Writing within no more than 30 days of its discovery.

        </p>

        <p>
          <strong>13.6</strong>&nbsp;
          Pylot cannot be obliged to perform any obligation under the Agreement if the performance is prevented
          due to force majeure. Pylot is not liable for any loss and/or damage due to force majeure.

        </p>

        <p>
          <strong>13.7</strong>&nbsp;
          Force majeure is considered to exist in any event in case of power outages, Internet failures,
          telecommunication infrastructure failures, network attacks (including D(DOS) attacks), attacks by malware or
          other harmful software, civil commotion, natural disaster, terror, mobilisation, war, import and export
          barriers, strikes, stagnation in supplies, fire, floods and any circumstance whereby Pylot is not enabled to
          perform or prevented from performing by its suppliers, irrespective of the reason.

        </p>

        <p>
          <strong>13.8</strong>&nbsp;
          If a force majeure situation has lasted for more than 90 days, both Parties shall be entitled to give
          notice to terminate the Agreement In Writing with immediate effect. The Services which in that case have
          been delivered by Pylot prior to the occurrence of the force majeure situation and during the force majeure
          situation will be paid for on a proportional basis.

        </p>

        <p>
          <strong>13.9</strong>&nbsp;
          The Parties agree that Section 6:271 of the Dutch Civil Code and the effect of its provisions in the
          following articles are excluded.

        </p>

        <p>
          <strong>13.10</strong>&nbsp;
          Any right to claim compensation will at all times be subject to the condition that the Client
          notifies Pylot of the loss and/or damage In Writing within 30 days of it arising.

        </p>


        <h2><strong>Article 14.</strong> Intellectual property</h2>

        <p>
          <strong>14.1</strong>&nbsp;
          All Intellectual Property Rights to the Software and any Materials that are provided by Pylot under
          the Agreement reside with Pylot or its licensors.

        </p>

        <p>
          <strong>14.2</strong>&nbsp;
          The Client will receive from Pylot the non-exclusive, non-transferable and non-sublicensable rights
          of use in respect of the Software and any Materials insofar as they ensue from the Agreement or which have
          otherwise been granted In Writing. The rights of use apply for the duration of the Agreement.

        </p>

        <p>
          <strong>14.3</strong>&nbsp;
          The Client is not authorised to make changes in the Software except in cases where that is mandatory
          by mandatory law.

        </p>

        <p>
          <strong>14.4</strong>&nbsp;
          Pylot may implement technical or other measures to protect the Software. Where Pylot has implemented
          such security measures, the Client may not remove or circumvent that security.

        </p>

        <p>
          <strong>14.5</strong>&nbsp;
          The Client is not permitted to remove from the Software or modify any indication or designation of
          Intellectual Property Rights. It is also not permitted to remove from the Software any indication or
          designation concerning their confidential nature.

        </p>


        <h2><strong>Article 15.</strong> Amendment of terms and conditions</h2>


        <p>
          <strong>15.1</strong>&nbsp;
          Pylot may amend these General Terms and Conditions at any time.
        </p>

        <p>
          <strong>15.2</strong>&nbsp;
          Pylot will endeavour to announce the changes or additions to the Client by email or by notification
          upon connection to Pylot, at least thirty days before they take effect, to enable the Client to take note of
          them.

        </p>

        <p>
          <strong>15.3</strong>&nbsp;
          If the Client does not wish to accept a change or addition, the Client may give notice to terminate
          the Agreement until the date it takes effect. Use of the Software after the effective date will be regarded
          as acceptance of the amended or supplemented terms and conditions.

        </p>

        <p>
          <strong>15.4</strong>&nbsp;
          Both Parties will cooperate fully in respect of changes or additions to the General Terms and
          Conditions that are necessary in light of new or changing legislation. Such changes may be implemented
          without notice by Pylot but Pylot will endeavour to announce these changes as well, without the Client
          having the possibility of giving notice to terminate the Agreement.

        </p>

        <p>
          <strong>15.5</strong>&nbsp;
          The procedure in this article does not apply to minor changes. Such changes may be implemented
          without notice by Pylot, without the Client having the possibility of giving notice to terminate the
          Agreement.

        </p>


        <h2><strong>Article 16.</strong> Confidentiality</h2>

        <p>
          <strong>16.1</strong>&nbsp;
          All information the parties provide each other before, during or after the fulfilment of this
          Agreement, or include in the Service, will be treated by the parties as confidential (hereinafter:
          <strong>Confidential Information</strong>). The Parties will also impose this obligation on their employees,
          as well as on
          any third parties engaged by them for the purpose of fulfilling the Agreement.
        </p>

        <p>
          <strong>16.2</strong>&nbsp;
          With regard to Confidential Information, Parties will:
        </p>

        <ol type='a'>
          <li>
            take due account of all reasonable measures to ensure safekeeping or storage of the Confidential
            Information, while ensuring at least the same level of security as it observes for its own Confidential
            Information;
          </li>

          <li>
            not use the Confidential Information for any other purpose than the agreed purpose (the fulfilment of
            the Agreement);
          </li>

          <li>
            not retain the Confidential Information for any longer than is reasonably necessary for the fulfilment
            of the agreed obligations and will make these data, including any copies that have been made, available
            to the other Party again immediately after full compliance with the obligations referred to or will,
            after a copy of the Confidential Information has been handed to the other Party and that Party has
            confirmed proper receipt thereof In Writing, destroy such data.
          </li>
        </ol>

        <p>
          <strong>16.3</strong>&nbsp;
          If and insofar expressly agreed between the Parties, Pylot may share some Confidential Information
          with other clients of Pylot. The Client, in its sole discretion, decides which Confidential information
          Pylot may share and with whom.
        </p>


        <h2><strong>Article 17.</strong> Exit</h2>

        <p>
          <strong>17.1</strong>&nbsp;
          Pylot will retain all data and other details of the Client for a period of thirty days after the
          Agreement has ended. Provided that the Client has met all its payment and other obligations under the
          Agreement, Pylot will endeavour to provide a copy of this data and other details, if requested by the
          Client. Pylot will charge a fee for this.
        </p>

        <p>
          <strong>17.2</strong>&nbsp;
          The copy will be provided in a standard file format to be determined by Pylot. The reasonable costs
          associated with copying the files and data in accordance with this article shall be for the Client’s
          account.
        </p>


        <h2><strong>Article 18.</strong> Final provisions</h2>

        <p>
          <strong>18.1</strong>&nbsp;
          The Agreement shall be governed exclusively by Dutch law.
        </p>

        <p>
          <strong>18.2</strong>&nbsp;
          To the extent that the rules of mandatory law do not prescribe otherwise, any dispute between the
          Parties in connection with the Agreement will be submitted to the competent court in the Netherlands for the
          district where Pylot has its registered office.
        </p>

        <p>
          <strong>18.3</strong>&nbsp;
          Pylot may transfer its rights and obligations arising from the Agreement to a third party that takes
          over Pylot or its business activities.
        </p>

        <p>
          <strong>18.4</strong>&nbsp;
          The Client shall promptly notify Pylot of any change in name, (postal) address, email address,
          telephone number and other information and details relevant to the fulfilment of the Agreement.
        </p>

        <p>
          <strong>18.5</strong>&nbsp;
          The logs and communication saved by Pylot are deemed to be correct, unless the Client provides
          evidence to the contrary.
        </p>

        <p>
          <strong>18.6</strong>&nbsp;
          If any provision in the Agreement is found to be void or invalid, this shall not affect the validity
          of the Agreement as a whole. In such case, the parties will replace that provision by one or more new
          provisions reflecting the purport of the original provision as far as is possible under the law.

        </p>

      </article>
    );
  }
}

export default withRouter(General);
