import { FormattedMessage, intlShape, FormattedPlural } from 'react-intl';
import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import storageWrapper from 'helpers/storageWrapper';

import Checkbox from 'components/Checkbox';

import { ReactComponent as CloseIcon } from './assets/close.svg';

import styles from './PhotoUploadDialog.module.css';

const safeLocalStorage = storageWrapper.get('localStorage');

export default class PhotoUploadDialog extends Component {
  static propTypes = {
    selectedPhoto: PropTypes.object.isRequired,
    selectedWeek: PropTypes.number.isRequired,
    onPhotoSelectDialogClose: PropTypes.func.isRequired,
    uploadedPhoto: PropTypes.string.isRequired,
    photoCategories: PropTypes.array.isRequired,
    intl: intlShape.isRequired,
    location: PropTypes.object,
    uploadPhoto: PropTypes.func.isRequired,
    selectedPlantingCycle: PropTypes.object,
    selectedYear: PropTypes.number,
    selectedCompartment: PropTypes.string,
    compressQuality: PropTypes.number,
  };

  static defaultProps = {
    location: null,
    selectedPlantingCycle: null,
    selectedYear: null,
    selectedCompartment: null,
    compressQuality: 0.3
  };

  state = {
    show: true,
    photoDescription: '',
    fileLoading: false,
    useCompress: false,
    uploadedPhoto: null
  };

  componentDidMount() {
    const useCompress = safeLocalStorage.getItem('useCompress') || false;
    const {
      uploadedPhoto,
      compressQuality
    } = this.props;
    this.setState(prevState => ({
      ...prevState,
      useCompress,
      uploadedPhoto: uploadedPhoto.toDataURL('image/jpeg', useCompress ? compressQuality : 1.0)
    }));
  }

  getImageBlob = async () => {
    const { uploadedPhoto, compressQuality } = this.props;
    const { useCompress } = this.state;

    return new Promise((res, rej) => {
      uploadedPhoto.toBlob((blob) => {
        if (!blob) {
          return rej(new Error('Image toBlob return null'));
        }
        return res(blob);
      }, 'image/jpeg', useCompress ? compressQuality : 1.0);
    });
  }

  handlerPhotoSubmit = async () => {
    const { location, uploadPhoto } = this.props;
    const { fileLoading } = this.state;

    if (!fileLoading) {
      this.setState({
        fileLoading: true,
      });

      const {
        selectedPlantingCycle,
        selectedWeek,
        selectedYear,
        selectedCompartment,
        selectedPhoto,
      } = this.props;

      const { photoDescription } = this.state;

      const { category: selectedPlace } = selectedPhoto.attributes;

      try {
        const file = await this.getImageBlob();

        await uploadPhoto({
          plantingCycle: selectedPlantingCycle,
          compartment: selectedCompartment,
          year: selectedYear,
          week: selectedWeek,
          category: selectedPlace,
          description: photoDescription,
          location,
          file
        });
      } catch (e) {
        // TODO: Show error message
        this.setState({
          errorFileLoading: '',
        });
      }

      this.setState({
        photoDescription: '',
        fileLoading: false,
      });
      const { onPhotoSelectDialogClose } = this.props;
      if (onPhotoSelectDialogClose) {
        onPhotoSelectDialogClose();
      }
    }

    return null;
  };

  handlerPhotoSelectDialogClose = () => {
    const { onPhotoSelectDialogClose } = this.props;

    if (onPhotoSelectDialogClose) {
      onPhotoSelectDialogClose();
    }
  };

  handleCheckCompress = () => {
    const {
      uploadedPhoto,
      compressQuality
    } = this.props;
    this.setState((prevState) => {
      const useCompress = !prevState.useCompress;
      safeLocalStorage.setItem('useCompress', useCompress);
      return {
        ...prevState,
        useCompress,
        uploadedPhoto: uploadedPhoto.toDataURL('image/jpeg', useCompress ? compressQuality : 1.0),
      };
    });
  }

  render() {
    const {
      selectedWeek,
      selectedPhoto,
      intl,
      uploadedPhoto: uploadedPhotoFromProps,
      photoCategories,
      compressQuality
    } = this.props;
    const {
      fileLoading,
      photoDescription,
      show,
      useCompress,
      uploadedPhoto: uploadedPhotoFromState
    } = this.state;
    const uploadedPhoto = uploadedPhotoFromState ||
      uploadedPhotoFromProps.toDataURL('image/jpeg', useCompress ? compressQuality : 1.0);
    const { category: selectedPlace } = selectedPhoto.attributes;
    const { formatMessage } = intl;
    const uploadedUrlStyle = {
      backgroundImage: `url(${uploadedPhoto})`,
      width: show ? '100%' : '1px',
      height: show ? '100%' : '1px'
    };
    const locale = safeLocalStorage.getItem('locale') || 'en';

    const uploadProps = {
      onClick: this.handlerPhotoSubmit,
    };

    return (
      <div className={styles.uploadPhotoDialog} style={uploadedUrlStyle}>
        {show && <div className={styles.uploadPhotoDialogShadowTop} />}
        {show && <div className={styles.uploadPhotoDialogShadowBottom} />}
        {show && <CloseIcon className={styles.uploadPhotoDialogClose} onClick={this.handlerPhotoSelectDialogClose} />}
        {show && (
          <div className={styles.uploadPhotoDialogForm}>
            <span className={styles.uploadPhotoDialogSelect}>
              <FormattedPlural
                value={1}
                one={<FormattedMessage id='photos.week.one' />}
                other={<FormattedMessage id='photos.week.other' />}
              />
              {` ${selectedWeek} / `}
              {photoCategories.find(item => item.code === selectedPlace)[locale]}
            </span>
            <input
              className={styles.uploadPhotoDialogInput}
              placeholder={formatMessage({ id: 'photos.photoDescriptionText' })}
              value={photoDescription}
              maxLength={1000}
              onChange={(e) => {
                this.setState({
                  photoDescription: e.target.value || ''
                });
              }}
            />
            <div className={styles.uploadPhotoDialogLineRight}>
              <div className={styles.checkboxContainer}>
                <Checkbox
                  className={styles.checkbox}
                  onChange={this.handleCheckCompress}
                  checked={useCompress}
                  value='compress'
                  title={formatMessage({ id: 'photos.withCompression' })}
                  noWrap
                  alignCenter
                />
              </div>
              <div className={styles.uploadPhotoDialogSubmit} {...uploadProps}>
                <FormattedMessage id='photos.submit' />
              </div>
            </div>
          </div>
        )}
        {show && fileLoading && (
          <div className={styles.loader}>
            <div className={styles.shadow} />
            <div className={styles.content}>
              <div className={styles.spinner}>
                <div className={styles.leaf} />
                <div className={styles.leaf} />
                <div className={styles.leaf} />
              </div>
              <div className={styles.status}><FormattedMessage id='photos.loadingPhoto' /></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
