const isOutsideScreen = (el) => {
  if (!el) {
    return {
      dir: null,
      value: 0,
    };
  }
  const bounding = el.getBoundingClientRect();
  if (bounding.top < 0) {
    return {
      dir: 'top',
      value: Math.abs(bounding.top),
    };
  }
  if (bounding.left < 0) {
    return {
      dir: 'left',
      value: Math.abs(bounding.left),
    };
  }
  const screenHeight = window.innerHeight || document.documentElement.clientHeight;
  if (bounding.bottom > screenHeight) {
    return {
      dir: 'bottom',
      value: bounding.bottom - screenHeight,
    };
  }
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;
  if (bounding.right > screenWidth) {
    return {
      dir: 'right',
      value: bounding.right - screenWidth,
    };
  }
  return {
    dir: null,
    value: 0,
  };
};

export default isOutsideScreen;
