import { get } from 'lodash';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router';
import {
  getAllCompartments,
  getAllVarieties,
  getFetching,
  getLocation,
  getIsAddHarvestAvailable,
} from '../../../../store/company/selectors';

import {
  requestGetHarvestDataByCategories, getHarvestCategories, updateHarvestDataByCategories, clearHarvestData
} from '../../../../store/harvest/actions';

import { getCurrentCycleFullInfo } from '../../../../store/newCrops/selectors';
import { getCycleData } from '../../../../store/newCrops/actions';
import {
  getHarvestDate,
  getHarvestPeriodType,
  getHarvestByCategories,
  getHarvestCategoriesByCycle
} from '../../../../store/harvest/selectors';

import UpdateHarvest from './UpdateHarvest';


const mapDispatchToProps = {
  getCycleData,
  getHarvestCategories,
  requestGetHarvestDataByCategories,
  updateHarvestDataByCategories,
  clearHarvestData,
};

const mapStateToProps = (state, props) => {
  const {
    harvest,
  } = state;

  const {
    match,
    location,
  } = props;

  const parsedQuery = queryString.parse(get(props, 'location.search'));
  const fromPage = get(parsedQuery, 'fromPage');

  const cropMatch = matchPath(fromPage, {
    path: '/:organizationSlug/crops/:cycleId',
    exact: false,
    strict: false
  });

  const addModeMatch = matchPath(location.pathname, {
    path: '/:organizationSlug/crops/:cycleId/add-harvest',
    exact: false,
    strict: false
  });

  return {
    ...harvest,
    cycleId: match.params.cycleId,
    cycleInfo: getCurrentCycleFullInfo(state),
    date: getHarvestDate(state, props),
    harvestByCategories: getHarvestByCategories(state),
    harvestCategories: getHarvestCategoriesByCycle(state),
    periodType: getHarvestPeriodType(state, props),
    isFetching: getFetching(state),
    allCompartments: getAllCompartments(state),
    varieties: getAllVarieties(state),
    organization: getLocation(state),
    fromPage,
    btnSource: get(cropMatch, 'params.cycleId') ? 'page' : null,
    mode: addModeMatch ? 'add' : 'edit',
    isAddHarvestAvailable: getIsAddHarvestAvailable(state),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateHarvest));
