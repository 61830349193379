import { useState } from 'react';
import useWindowResize from './useWindowResize';

function useIsMobile(width) {
  const [isMobile, setIsMobile] = useState(() => window.innerWidth < width);

  useWindowResize(() => {
    setIsMobile(window.innerWidth < width);
  }, [setIsMobile]);

  return isMobile;
}

export default useIsMobile;

