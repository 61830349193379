import moment from 'moment-timezone';

import { handleActions } from 'redux-actions';

import numbersFormatting from 'helpers/numbersFormatting';
import numbersRounding from 'helpers/numbersRounding';
import { getProductTypeName } from 'helpers/getVarietyName';
import {
  requestGetHarvestPlans,
  receiveGetHarvestPlans,
  clearHarvestPlans,
  requestGetHarvestPlansList,
  receiveGetHarvestPlansList,
} from './actions';

const initialState = {
  showedYears: {},

  showedYear: null,
  thickLines: null,
  descriptor: null,

  harvestPlansFailed: false,
  harvestPlansFetching: false,
  harvestPlansSucceeded: false,

  harvestPlansList: null,
  isHarvestPlansListFetching: false,
};

export default handleActions({
  [clearHarvestPlans]() {
    return { ...initialState };
  },

  [requestGetHarvestPlansList](state) {
    return {
      ...state,
      isHarvestPlansListFetching: true,
    };
  },

  [receiveGetHarvestPlansList](state, action) {
    return {
      ...state,

      harvestPlansList: action.payload?.harvestPlansList,
      isHarvestPlansListFetching: false,
    };
  },

  [requestGetHarvestPlans](state) {
    return {
      ...state,
      harvestPlansFetching: true,
      harvestPlansSucceeded: false,
      harvestPlansFailed: false,
    };
  },

  [receiveGetHarvestPlans](state, action) {
    const {
      payload:
        {
          data,
          year,
          // periodType,
          relative,
          compartments,
          varieties,
          fruitClasses,
          // allPlantingCycles,
          productGroups,
          breakdown,
          species,
          intl
        },
    } = action;

    const {
      thickLines,
      descriptor,
    } = data;

    const { formatMessage } = intl;

    const squareMeterLocalized = formatMessage({ id: 'cunits.mini.squareMeter' });
    const unitLocalized = formatMessage({
      id: `cunits.mini.${relative === 'true' ? 'kilogramPerSquareMeter' : 'kilogram'}`
    });

    const roundTo = relative !== 'true' || species === 'lettuce' ? 0 : 1;

    const normalizeDataPoints = (dataPoints, round) => Object.keys(dataPoints).reduce((memo, point) => {
      const value = dataPoints[point];
      return {
        ...memo,
        [point]: roundTo > 0 ? numbersFormatting(numbersRounding(value, 'fixed', round)) : value,
      };
    }, {});

    const sortByCompartment = (one, two) =>
      one.compartment.attributes.name.localeCompare(
        two.compartment.attributes.name, undefined, { numeric: true, sensitivity: 'base' }
      );

    const sortByVarieties = (one, two) => {
      const oneName = `${one?.compartment?.attributes?.name}-${one?.variety?.attributes?.name}-${one?.productGroup?.attributes?.name}`;
      const twoName = `${two?.compartment?.attributes?.name}-${two?.variety?.attributes?.name}-${two?.productGroup?.attributes?.name}`;

      return oneName.localeCompare(
        twoName, undefined, { numeric: true, sensitivity: 'base' }
      );
    };

    const mapPeriods = (period) => {
      const monthText = moment(period.endDate, 'YYYY-MM-DD').format('MMM, YYYY');
      return {
        ...period,
        startDate: new Date(period.startDate),
        endDate: new Date(period.endDate),
        monthText
      };
    };

    const getStartEndDateFromPeriods = (periods) => {
      const start = periods[0];
      const end = periods[periods.length - 1];
      const { startDate } = start;
      const { endDate } = end;
      return {
        startDate,
        endDate,
      };
    };

    const currentWeek = descriptor?.year === moment().year() ? moment().isoWeekday(1).isoWeek() : null;
    const normalizedDescriptor = {
      ...descriptor,
      currentWeek,
      relative,
      breakdown,
      squareMeterLocalized,
      unitLocalized,
      noHarvestDataLocalized: formatMessage({ id: 'plans.noHarvestData' }),
      noMatchedPlanLocalized: formatMessage({ id: 'plans.noMatchedPlan' }),
      planTitleLocalized: formatMessage({ id: 'dashboards.plan' }),
      actualTitleLocalized: formatMessage({ id: 'dashboards.actual' }),
      periods: descriptor.periods.map(mapPeriods),
      periodsBefore: descriptor.periodsBefore.map(mapPeriods),
    };

    const { startDate, endDate } = getStartEndDateFromPeriods(normalizedDescriptor.periods);

    const normalizedThickLines = thickLines.map((thickLine) => {
      const {
        compartment,
        variety,
        productGroup
      } = thickLine;

      const foundCompartment = compartments.find(item => item.id === compartment?.id);
      const foundVariety = varieties.find(item => item.id === variety?.id);
      const foundProductGroup = productGroups.find(item => item.id === productGroup?.id);
      const foundFruitClass = foundProductGroup ?
        fruitClasses.find(item => item.id === foundProductGroup?.attributes?.fruitClass) : null;

      let productTypeName = '';
      if (foundVariety) {
        productTypeName = foundFruitClass ? getProductTypeName({
          intl,
          variety: foundVariety,
          fruitClass: foundFruitClass,
          withSpecies: true,
          targetWeight: productGroup?.targetWeight ? productGroup.targetWeight.amount : 0
        }) : getProductTypeName({
          intl,
          variety: foundVariety,
          withSpecies: true,
          targetWeight: productGroup?.targetWeight ? productGroup.targetWeight.amount : 0
        });
      }

      const getTotalRoundTo = (relativeFlag, total) => (relativeFlag !== 'true' || total?.units === 'count' ? 0 : 1);

      return {
        ...thickLine,
        productTypeName,
        compartment: compartment ? {
          ...foundCompartment,
          attributes: {
            ...foundCompartment.attributes,
            floorArea: numbersFormatting(numbersRounding(foundCompartment.attributes.floorArea, 'fixed', 0))
          }
        } : null,
        variety: variety ? foundVariety : null,
        productGroup: productGroup ? foundProductGroup : null,
        planYearTotal: thickLine.planYearTotal ? {
          ...thickLine.planYearTotal,
          amount: numbersFormatting(numbersRounding(thickLine.planYearTotal.amount, 'fixed', roundTo)),
          amountRaw: thickLine.planYearTotal.amount
        } : null,
        actualYearTotal: thickLine.actualYearTotal ? {
          ...thickLine.actualYearTotal,
          amount: numbersFormatting(numbersRounding(thickLine.actualYearTotal.amount, 'fixed', roundTo)),
          amountRaw: thickLine.actualYearTotal.amount
        } : null,
        lines: thickLine.lines.map(line => ({
          ...line,
          sections: line.sections.map((section) => {
            const sectionVariety = varieties.find(item => item.id === section?.variety?.id);
            const sectionProductGroup = productGroups.find(item => item.id === section?.productGroup?.id);
            const sectionFruitClass = sectionProductGroup ?
              fruitClasses.find(item => item.id === sectionProductGroup?.attributes?.fruitClass) : null;

            let sectionTypeName = '';
            if (sectionVariety) {
              sectionTypeName = sectionFruitClass ? getProductTypeName({
                intl,
                variety: sectionVariety,
                fruitClass: sectionFruitClass,
                withSpecies: true,
                targetWeight: section?.productGroup?.targetWeight ? section?.productGroup?.targetWeight.amount : 0
              }) : getProductTypeName({
                intl,
                variety: sectionVariety,
                withSpecies: true,
                targetWeight: section?.productGroup?.targetWeight ? section?.productGroup?.targetWeight.amount : 0
              });
            }

            return {
              ...section,
              variety: sectionVariety,
              productGroup: sectionProductGroup,
              fruitClass: sectionFruitClass,
              sectionTypeName,
              actual: section.actual ? {
                ...section.actual,
                range: {
                  firstPeriodStart: new Date(section.actual.range.firstPeriodStart),
                  lastPeriodStart: new Date(section.actual.range.lastPeriodStart)
                },
                total: {
                  ...section.actual.total,
                  amount: numbersFormatting(numbersRounding(section.actual.total.amount, 'fixed', roundTo))
                },
                dataPoints: normalizeDataPoints(section.actual.dataPoints, getTotalRoundTo(relative, section?.actual?.total)),
              } : null,
              plan: section.plan ? {
                ...section.plan,
                range: {
                  firstPeriodStart: new Date(section.plan.range.firstPeriodStart),
                  lastPeriodStart: new Date(section.plan.range.lastPeriodStart)
                },
                total: {
                  ...section.plan.total,
                  amount: numbersFormatting(numbersRounding(section.plan.total.amount, 'fixed', roundTo))
                },
                dataPoints: normalizeDataPoints(section.plan.dataPoints, getTotalRoundTo(relative, section?.plan?.total)),
              } : null,
            };
          }),
        })),
      };
    });

    if (breakdown === 'varietyWeight') {
      normalizedThickLines.sort(sortByVarieties);
    } else {
      normalizedThickLines.sort(sortByCompartment);
    }

    const processedData = {
      descriptor: {
        ...normalizedDescriptor,
        startDate,
        endDate,
      },
      thickLines: normalizedThickLines
    };

    return {
      ...initialState,
      showedYears: [year],
      showedYear: year,
      harvestPlansSucceeded: true,
      harvestPlansFetching: false,
      ...processedData
    };
  },
}, initialState);
