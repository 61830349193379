import React, { useCallback, useRef, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { ReactComponent as PrevIcon } from './assets/arrow_prev.svg';
import { ReactComponent as NextIcon } from './assets/arrow_next.svg';

import WeatherIcon from './WeatherIcon';

import styles from './HarvestForecastWeatherIndicator.module.css';

const SCROLL_SPEED = 30;

const HarvestForecastWeatherIndicator = ({
  intl,
  items
}) => {
  const scrollRef = useRef();

  const { formatMessage } = intl;

  const [leftButtonDisabled, setLeftButtonDisabled] = useState(true);
  const [rightButtonDisabled, setRightButtonDisabled] = useState(false);

  const maxScrollLeft = scrollRef?.current ?
    scrollRef?.current?.scrollWidth - scrollRef?.current?.clientWidth : 0;

  const truncated = items?.length > 7;

  const handlerClickPrev = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= SCROLL_SPEED;
      setRightButtonDisabled(false);
      if (scrollRef.current.scrollLeft <= 0) {
        setLeftButtonDisabled(true);
      }
    }
  }, [scrollRef, setLeftButtonDisabled]);

  const handlerClickNext = useCallback(() => {
    if (scrollRef.current) {
      setLeftButtonDisabled(false);
      scrollRef.current.scrollLeft += SCROLL_SPEED;
      if (scrollRef.current.scrollLeft >= maxScrollLeft) {
        setRightButtonDisabled(true);
      }
    }
  }, [scrollRef, setRightButtonDisabled, maxScrollLeft]);

  return (
    <div
      className={styles.container}
    >
      <div
        className={classNames(styles.scroll, {
          [styles.scrollEmpty]: items?.length === 0,
          [styles.fixJustify]: items?.length < 7
        })}
        ref={scrollRef}
      >
        {items?.length > 0 ? items.map((item, index) => (
          <div className={classNames(styles.indicator, {
            [styles.indicatorMargin]: index !== 0
          })}
          >
            <div className={styles.title}>{item.title}</div>
            <WeatherIcon index={item.iconType} className={styles.icon} />
            <div className={styles.date}>{item.date}</div>
          </div>
        )) : (
          <div className={classNames(styles.indicator)}>
            <WeatherIcon index={3} className={styles.icon} />
            <div className={styles.emptyTitle}>{formatMessage({ id: 'forecast.weatherEmptyTitle' })}</div>
            <div className={styles.emptyText}>{formatMessage({ id: 'forecast.weatherEmptyText' })}</div>
          </div>
        )}
      </div>
      {truncated && (
        <div className={styles.navigations}>
          <div
            role='button'
            tabIndex={0}
            className={classNames(styles.button, {
              [styles.disabled]: leftButtonDisabled
            })}
            onClick={handlerClickPrev}
          >
            <PrevIcon />
          </div>
          <div
            role='button'
            tabIndex={0}
            className={classNames(styles.button, {
              [styles.disabled]: rightButtonDisabled
            })}
            onClick={handlerClickNext}
          >
            <NextIcon />
          </div>
        </div>
      )}
    </div>
  );
};

HarvestForecastWeatherIndicator.propTypes = {
  intl: intlShape.isRequired,
  items: PropTypes.array
};

HarvestForecastWeatherIndicator.defaultProps = {
  items: []
};

export default injectIntl(HarvestForecastWeatherIndicator);
