import React from 'react';
import PropTypes from 'prop-types';

import { uniqueId } from 'lodash';
import classnames from 'classnames';

import Typography from 'components/Typography';
import Avatar from 'components/Avatar';
import DefaultSimpleTooltip from 'components/DefaultSimpleTooltip';

import Close from 'components/Icons/Close';

import styles from './UserBage.module.css';

const renderCloseButton = (onCloseClick, isCloseDisabled, closeDisabledTooltip) => (
  <DefaultSimpleTooltip text={isCloseDisabled ? closeDisabledTooltip : ''} noWrap>
    <div
      role='button'
      tabIndex={0}
      onClick={isCloseDisabled ? () => {} : onCloseClick}
      className={classnames(styles.userCloseWrapper, { [styles.disabled]: isCloseDisabled })}
    >
      <Close className={styles.userClose} />
    </div>
  </DefaultSimpleTooltip>
);
const UserBage = ({
  user, className, onCloseClick, closeDisabledTooltip, isCloseDisabled
}) => (
  <div
    key={uniqueId('user-bage-')}
    className={classnames(styles.user, className)}
  >
    <DefaultSimpleTooltip text={user?.email || user.name}>
      <div className={styles.userNameWrapper}>
        <Avatar
          className={styles.avatar}
          userName={user.name}
        />
        <div className={styles.username}>
          <Typography variant='body1'>
            {user.name}
          </Typography>
        </div>
      </div>
    </DefaultSimpleTooltip>
    {onCloseClick && renderCloseButton(onCloseClick, isCloseDisabled, closeDisabledTooltip)}
  </div>
);

UserBage.propTypes = {
  user: PropTypes.object.isRequired,
  className: PropTypes.string,
  onCloseClick: PropTypes.func,
  closeDisabledTooltip: PropTypes.string,
  isCloseDisabled: PropTypes.bool,
};

UserBage.defaultProps = {
  className: undefined,
  onCloseClick: undefined,
  closeDisabledTooltip: undefined,
  isCloseDisabled: false,
};

export default UserBage;
