import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import BigButton from 'components/BigButton';
import AddToCompareIcon from 'components/Icons/AddToCompareIcon';
import RemoveFromCompareIcon from 'components/Icons/RemoveFromCompareIcon';

import styles from './BenchmarkCompareButton.module.css';

export default class BenchmarkCompareButton extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,

    data: PropTypes.object,
    cyclesToCompare: PropTypes.array,

    addCycleToComparison: PropTypes.func.isRequired,
    removeCycleFromComparison: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: null,
    cyclesToCompare: [],
  };

  getIsInComparison = (id) => {
    const { cyclesToCompare } = this.props;

    if (cyclesToCompare.indexOf(id) !== -1) {
      return true;
    }

    return false;
  };

  handleClickCompareButton = (e, isInComparison, id) => {
    e.stopPropagation();
    const { addCycleToComparison, removeCycleFromComparison } = this.props;


    if (isInComparison) {
      return removeCycleFromComparison({ cycleId: id, withSuccessAction: true });
    }

    return addCycleToComparison({ cycleId: id, withSuccessAction: true });
  };

  render() {
    const {
      intl: { formatMessage },
      data,
    } = this.props;

    if (!data) {
      return null;
    }

    const isInComparison = this.getIsInComparison(data.id);

    const buttonText = isInComparison ? formatMessage({ id: 'benchmarking.removeToComparison' }) : formatMessage({ id: 'benchmarking.addToComparison' });

    return (
      <div className={styles.compareButtonWrapper}>
        <BigButton
          className={styles.compareButton}
          title={buttonText}
          titleClassName={styles.title}
          onClick={e => this.handleClickCompareButton(e, isInComparison, data.id)}
          icon={isInComparison ? <RemoveFromCompareIcon /> : <AddToCompareIcon />}
          theme='transparent'
          bigIcon
        />
      </div>
    );
  }
}
