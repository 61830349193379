import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { get, max } from 'lodash';

import DashboardSectionHeader from 'components/DashboardSectionHeader';
import { getUnits, getValue } from 'helpers/getValueDetails';

import { getDefaultDateRange } from 'helpers/defaultDates';
import DashboardParameter from 'components/DashboardParameter';
import MeterTypeGraph from '../MeterTypeGraph';


import styles from './DashboardReportSelectedChart.module.css';

const DEFAULT_DATE_RANGE = getDefaultDateRange();

class DashboardReportSelectedChart extends Component {
  static propTypes = {
    intl: intlShape.isRequired,

    selectedRowTitle: PropTypes.string.isRequired,
    selectedRow: PropTypes.object,

    isDataFetching: PropTypes.bool,
    periodType: PropTypes.string,
    currentUnitKind: PropTypes.string,
    varieties: PropTypes.array.isRequired,
    fruitClasses: PropTypes.array.isRequired,
    compartments: PropTypes.array.isRequired,
    allPlantingCycles: PropTypes.array.isRequired,
    productGroups: PropTypes.array.isRequired,
    workTypes: PropTypes.array,
    currentBreakdown: PropTypes.string,
    currentSpecies: PropTypes.string,
    currentVarietyId: PropTypes.number,
    currentFruitClassCode: PropTypes.string,
    anyDateOfPeriodStart: PropTypes.string,
    anyDateOfPeriodEnd: PropTypes.string,
  };

  static defaultProps = {
    workTypes: null,
    currentBreakdown: 'compartment',
    currentSpecies: null,
    currentVarietyId: null,
    currentFruitClassCode: null,
    selectedRow: null,
    isDataFetching: false,
    periodType: 'month',
    currentUnitKind: 'absolute',
    anyDateOfPeriodStart: DEFAULT_DATE_RANGE.startDate,
    anyDateOfPeriodEnd: DEFAULT_DATE_RANGE.endDate,
  };

  render() {
    const {
      intl,
      periodType,
      selectedRowTitle,
      varieties,
      compartments,
      fruitClasses,
      selectedRow,
      currentUnitKind,
      isDataFetching,
      allPlantingCycles,
      productGroups,
      workTypes,
      currentBreakdown,
      currentSpecies,
      currentVarietyId,
      currentFruitClassCode,
      anyDateOfPeriodStart,
      anyDateOfPeriodEnd
    } = this.props;

    const { formatMessage } = intl;

    const roundTo = currentUnitKind === 'absolute' ? 1 : 2;

    const total = get(selectedRow, 'total');
    const byArea = get(selectedRow, 'byArea');
    const byHarvestPerUnit = get(selectedRow, 'byHarvestPerUnit', []);

    const totalAmount = getValue(total, 1);
    const totalUnits = getUnits(total, formatMessage);
    const byAreaAmount = getValue(byArea, 2);
    const byAreaUnits = getUnits(byArea, formatMessage);

    const byHarvestPerUnitValues = byHarvestPerUnit && byHarvestPerUnit.length ? byHarvestPerUnit.map(item => ({
      value: getValue(item, 2),
      units: getUnits(item, formatMessage),
    })) : [{ value: '-' }];

    const data = get(selectedRow, 'data', {});
    const values = Object.values(data);
    const maxValue = max(values) || 100;
    const minValue = Math.min(values) || 0; // не используем lodash min, т.к. он не учитывает нули


    return (
      <>
        <DashboardSectionHeader headerText={`${formatMessage({ id: 'dashboards.usage' })} ${selectedRowTitle}`}>
          <div className={styles.harvest}>
            <DashboardParameter
              containerClassName={styles.energyParam}
              headerText={formatMessage({ id: 'dashboards.totalAll' })}
              values={[{
                value: totalAmount,
                units: totalUnits,
              }]}
            />
            <DashboardParameter
              containerClassName={styles.energyParam}
              headerText={formatMessage({ id: 'dashboards.byArea' })}
              values={[{
                value: byAreaAmount,
                units: byAreaUnits,
              }]}
            />
            <DashboardParameter
              containerClassName={styles.energyParam}
              headerText={formatMessage({ id: 'dashboards.byHarvest' })}
              values={byHarvestPerUnitValues}
            />
          </div>
        </DashboardSectionHeader>

        <MeterTypeGraph
          points={selectedRow}
          maxValue={maxValue}
          minValue={minValue}
          periodType={periodType}
          roundTo={roundTo}
          varieties={varieties}
          fruitClasses={fruitClasses}
          compartments={compartments}
          isDataFetching={isDataFetching}
          allPlantingCycles={allPlantingCycles}
          productGroups={productGroups}
          workTypes={workTypes}
          currentBreakdown={currentBreakdown}
          currentSpecies={currentSpecies}
          currentVarietyId={currentVarietyId}
          currentFruitClassCode={currentFruitClassCode}
          anyDateOfPeriodStart={anyDateOfPeriodStart}
          anyDateOfPeriodEnd={anyDateOfPeriodEnd}
        />
      </>
    );
  }
}

export default injectIntl(DashboardReportSelectedChart);
