import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { get } from 'lodash';
import queryString from 'query-string';

import RangePicker from '../components/RangePicker';

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => {
  // Тут используем history.location.search а не location.search, т.к. почему-то роутер не обновляет location
  const parsedQuery = queryString.parse(get(props, 'history.location.search'));
  const defaultPeriodType = get(props, 'defaultPeriodType');

  const periodType = get(parsedQuery, 'periodType') || defaultPeriodType;
  const anyDateOfPeriodStart = get(parsedQuery, 'anyDateOfPeriodStart', props?.anyDateOfPeriodStart);
  const anyDateOfPeriodEnd = get(parsedQuery, 'anyDateOfPeriodEnd', props?.anyDateOfPeriodEnd);
  const plantingCycleId = get(parsedQuery, 'plantingCycleId');

  return {
    ...props,

    periodType,
    anyDateOfPeriodStart,
    anyDateOfPeriodEnd,
    plantingCycleId,
  };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(RangePicker)));
