import { handleActions } from 'redux-actions';

import {
  requestCreateForecast,
  requestDeleteForecast,
  requestGetForecast,
  requestGetForecastList,
  requestUpdateForecast,
  responseCreateForecast,
  responseDeleteForecast,
  responseGetForecast,
  responseGetForecastList,
  responseUpdateForecast,
  requestGetForecastVersions,
  receiveGetForecastVersions,
  requestGetForecastLastVersion,
  receiveGetForecastLastVersion,
  requestSaveForecastVersion,
  receiveSaveForecastVersion,
  requestGetForecastReportData,
  responseGetForecastReportData,
  changeReportFilters,
  setGraphKey,
  setFields,
  setShowVersionPanel,
  requestGetForecastVersion,
  receiveGetForecastVersion,
  requestRestoreForecastVersion,
  receiveRestoreForecastVersion,
  requestPublishForecast,
  receivePublishForecast,
  setForecastVersionsFilter,
  requestAssistantForecast,
  receiveAssistantForecast,
  setHasChangesTime,
  clearForecastState,
  requestForecastNotificationSubscribers,
  receiveForecastNotificationSubscribers,
  clearForecastNotificationSubscribers,
  clearForecastReportData,
  clearGraphKeys,
  requestHarvestForecastSettings,
  receiveHarvestForecastSettings,
  requestForecastSubscribersOfLocation,
  receiveForecastSubscribersOfLocation,
  requestCreateExtraProduct,
  receiveCreateExtraProduct,
  requestUpdateExtraProduct,
  receiveUpdateExtraProduct,
  requestDeleteExtraProduct,
  receiveDeleteExtraProduct,
  requestEditForecastVersionInfo,
  receiveEditForecastVersionInfo,
  requestDeleteForecastVersionInfo,
  receiveDeleteForecastVersionInfo,
} from 'store/harvestForecast/actions';
import { getCategorySortingValue } from 'helpers/harvestForecast';

const initialState = {
  forecasts: [],
  isForecastListFetching: false,

  forecast: null,
  isForecastFetching: false,

  forecastVersions: [],
  isForecastVersionsFetching: false,

  forecastData: null,
  isForecastDataFetching: false,

  isForecastSaving: false,
  forecastReportData: null,
  isForecastReportDataFetching: false,

  reportFilters: {
    breakdown: 'fruitClass',
    relative: false,
    compartmentId: undefined,
    varietyId: undefined,
    fruitClassCode: undefined,
    productGroup: undefined,
    period: 'all'
  },

  graphKey: {
    overall: null,
    byDates: null,
  },

  fields: null,

  versionsFilter: 'all',
  showVersionPanel: false,

  assistant: null,
  isAssistantFetching: false,

  isSubscribersFetching: false,
  notificationSubscribers: null,

  harvestForecastSettings: undefined,
  isHarvestForecastSettingsFetching: false,

  extraProduct: undefined,
  isExtraProductFetching: false,

  isVersionInfoFetching: false,
  isDeleteVersionInfoFetching: false,
};


function envelopeCategories(categories) {
  let groupIndex = -1;
  let currentCategoryId = null;
  return categories.map((catItem) => {
    if (currentCategoryId !== catItem.forecastCategoryId) {
      currentCategoryId = catItem.forecastCategoryId;
      groupIndex += 1;
    }
    return { ...catItem, groupIndex };
  }).sort((c1, c2) => getCategorySortingValue(c2) - getCategorySortingValue(c1));
}

function envelopeForecastReportData(forecastReportData) {
  const {
    categories
  } = forecastReportData;

  return {
    ...forecastReportData,
    categories: envelopeCategories(categories)
  };
}

function envelopeAssistantData(assistant) {
  const {
    data: {
      harvest: {
        categories
      }
    }
  } = assistant;

  return {
    ...assistant,
    data: {
      ...assistant.data,
      harvest: {
        ...assistant.data.harvest,
        categories: envelopeCategories(categories)
      }
    }
  };
}

export default handleActions({
  [requestGetForecastList](state) {
    return {
      ...state,
      isForecastListFetching: true,
    };
  },
  [responseGetForecastList](state, action) {
    return {
      ...state,
      isForecastListFetching: false,
      forecasts: action.payload,
    };
  },
  [requestGetForecast](state) {
    return {
      ...state,
      isForecastFetching: true,
    };
  },
  [responseGetForecast](state, action) {
    return {
      ...state,
      isForecastFetching: false,
      forecast: action.payload,
    };
  },
  [requestUpdateForecast](state) {
    return {
      ...state,
      isForecastFetching: true,
    };
  },
  [responseUpdateForecast](state, action) {
    return {
      ...state,
      isForecastFetching: false,
      forecast: action.payload,
    };
  },
  [requestDeleteForecast](state) {
    return {
      ...state,
      isForecastFetching: true,
    };
  },
  [responseDeleteForecast](state) {
    return {
      ...state,
      isForecastFetching: false,
      forecast: null,
    };
  },
  [requestCreateForecast](state) {
    return {
      ...state,
      isForecastFetching: true,
    };
  },
  [responseCreateForecast](state, action) {
    return {
      ...state,
      isForecastFetching: false,
      forecast: action.payload,
    };
  },
  [requestGetForecastVersions](state) {
    return {
      ...state,
      isForecastVersionsFetching: true,
    };
  },
  [receiveGetForecastVersions](state, action) {
    return {
      ...state,
      isForecastVersionsFetching: false,
      forecastVersions: action.payload,
    };
  },
  [requestGetForecastLastVersion](state) {
    return {
      ...state,
      forecastData: null,
      isForecastDataFetching: true,
    };
  },
  [receiveGetForecastLastVersion](state, action) {
    return {
      ...state,
      isForecastDataFetching: false,
      forecastData: action.payload,
    };
  },
  [requestSaveForecastVersion](state) {
    return {
      ...state,
      forecastData: null,
      isForecastSaving: true,
    };
  },
  [receiveSaveForecastVersion](state, action) {
    return {
      ...state,
      isForecastSaving: false,
      forecastData: action.payload,
    };
  },
  [requestGetForecastVersion](state) {
    return {
      ...state,
      forecastData: null,
      isForecastDataFetching: true,
    };
  },
  [receiveGetForecastVersion](state, action) {
    return {
      ...state,
      isForecastDataFetching: false,
      forecastData: action.payload,
    };
  },
  [requestRestoreForecastVersion](state) {
    return {
      ...state,
      forecastData: null,
      isForecastDataFetching: true,
    };
  },
  [receiveRestoreForecastVersion](state, action) {
    return {
      ...state,
      isForecastDataFetching: false,
      forecastData: action.payload,
    };
  },
  [requestGetForecastReportData](state) {
    return {
      ...state,
      forecastReportData: null,
      isForecastReportDataFetching: true
    };
  },
  [responseGetForecastReportData](state, action) {
    return {
      ...state,
      isForecastReportDataFetching: false,
      forecastReportData: envelopeForecastReportData(action.payload),
    };
  },
  [changeReportFilters](state, action) {
    return {
      ...state,
      reportFilters: {
        ...state.reportFilters,
        ...action.payload
      },
    };
  },
  [setGraphKey](state, action) {
    return {
      ...state,
      graphKey: {
        ...state.graphKey,
        ...action.payload,
      },
    };
  },
  [setFields](state, action) {
    return {
      ...state,
      fields: {
        ...state.fields,
        ...action.payload,
      },

    };
  },
  [setShowVersionPanel](state, action) {
    return {
      ...state,
      showVersionPanel: action.payload
    };
  },
  [requestPublishForecast](state) {
    return {
      ...state,
      isForecastSaving: true,
    };
  },
  [receivePublishForecast](state, action) {
    return {
      ...state,
      isForecastSaving: false,
      forecastData: action.payload,
    };
  },
  [setForecastVersionsFilter](state, action) {
    return {
      ...state,
      versionsFilter: action.payload.versionsFilter,
    };
  },
  [requestAssistantForecast](state) {
    return {
      ...state,
      isAssistantFetching: true,
    };
  },
  [receiveAssistantForecast](state, action) {
    return {
      ...state,
      isAssistantFetching: false,
      assistant: envelopeAssistantData(action.payload)
    };
  },
  [setHasChangesTime](state, action) {
    return {
      ...state,
      changesTime: action.payload,
    };
  },
  [clearForecastState](state) {
    return {
      ...state,
      forecast: null,
      forecastData: null,
      forecastReportData: null,
      assistant: null,
      notificationSubscribers: null,
      graphKey: {
        overall: null,
        byDates: null,
      },
      fields: null,
    };
  },
  [requestForecastNotificationSubscribers](state) {
    return {
      ...state,
      isSubscribersFetching: true,
    };
  },
  [receiveForecastNotificationSubscribers](state, action) {
    return {
      ...state,
      isSubscribersFetching: false,
      notificationSubscribers: action.payload,
    };
  },
  [clearForecastNotificationSubscribers](state) {
    return {
      ...state,
      notificationSubscribers: null,
    };
  },
  [clearForecastReportData](state) {
    return {
      ...state,
      forecastReportData: null,
      graphKey: {
        overall: null,
        byDates: null,
      },
      fields: null,
    };
  },
  [clearGraphKeys](state) {
    return {
      ...state,
      graphKey: {
        overall: null,
        byDates: null,
      },
      fields: null,
    };
  },
  [requestHarvestForecastSettings](state) {
    return {
      ...state,
      isHarvestForecastSettingsFetching: true,
    };
  },
  [receiveHarvestForecastSettings](state, action) {
    return {
      ...state,
      isHarvestForecastSettingsFetching: false,
      harvestForecastSettings: action.payload,
    };
  },
  [requestForecastSubscribersOfLocation](state) {
    return {
      ...state,
      isForecastSubscribersOfLocationFetching: true,
    };
  },
  [receiveForecastSubscribersOfLocation](state, action) {
    return {
      ...state,
      isForecastSubscribersOfLocationFetching: false,
      forecastSubscribersOfLocation: action.payload,
    };
  },
  [requestCreateExtraProduct](state) {
    return {
      ...state,
      isExtraProductFetching: true,
    };
  },
  [receiveCreateExtraProduct](state, action) {
    return {
      ...state,
      isExtraProductFetching: false,
      forecastData: action.payload,
    };
  },
  [requestUpdateExtraProduct](state) {
    return {
      ...state,
      isExtraProductFetching: true,
    };
  },
  [receiveUpdateExtraProduct](state, action) {
    return {
      ...state,
      isExtraProductFetching: false,
      forecastData: action.payload,
    };
  },
  [requestDeleteExtraProduct](state) {
    return {
      ...state,
      isExtraProductFetching: true,
    };
  },
  [receiveDeleteExtraProduct](state, action) {
    return {
      ...state,
      isExtraProductFetching: false,
      forecastData: action.payload,
    };
  },
  [requestEditForecastVersionInfo](state) {
    return {
      ...state,
      isVersionInfoFetching: true,
    };
  },
  [receiveEditForecastVersionInfo](state) {
    return {
      ...state,
      isVersionInfoFetching: false,
    };
  },
  [requestDeleteForecastVersionInfo](state) {
    return {
      ...state,
      isDeleteVersionInfoFetching: true,
    };
  },
  [receiveDeleteForecastVersionInfo](state) {
    return {
      ...state,
      isDeleteVersionInfoFetching: false,
    };
  },
}, initialState);
