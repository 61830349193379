import {
  Compartment,
  FruitClass,
  Metric,
  PlantingCycle,
  SubNode,
  TreeNode,
  Variety
} from 'store/graphs/types';

import { getNameByLocal } from 'helpers/getNameByLocal';

import getSubNodeTitle from './getSubNodeTitle';
import getSpeciesTitle from './getSpeciesTitle';
import getPlantingCycleTitle from './getPlantingCycleTitle';

import getSpeciesCode from './getSpeciesCode';

function getTreeNodeTitle(
  node: TreeNode,
  formatMessage: Function,
  locale: string,
  allCompartments: Array<Compartment>,
  allSubNodes: Array<SubNode>,
  allMetrics: Array<Metric>,
  allVarieties: Array<Variety>,
  allFruitClasses: Array<FruitClass>,
  allPlantingCycles: Array<PlantingCycle>
) {
  if (node.entity.type === 'LocationAverages') {
    return formatMessage({ id: 'graphs.sidePanel.locationAggregated' });
  }
  if (node.entity.type === 'CompartmentAverages') {
    return formatMessage({ id: 'graphs.sidePanel.commonCompartmentMetrics' });
  }
  if (node.entity.type === 'Compartment') {
    // @ts-ignore // TODO: Fix ID types
    const compartment = allCompartments.find(item => item.id === node.entity.id);
    if (compartment) {
      return compartment.attributes.name;
    }
  }
  if (node.entity.type === 'SubNode') {
    // @ts-ignore // TODO: Fix ID types
    const subNode = allSubNodes.find(item => item.id === node.entity.id);
    if (subNode) {
      return getSubNodeTitle({
        subNode, formatMessage
      });
    }
  }
  if (node.entity.type === 'Metric') {
    const metric = allMetrics.find(item => item.id === node.entity.id);
    return getNameByLocal(metric?.attributes, locale);
  }
  if (node.entity.type === 'Species') {
    return getSpeciesTitle(getSpeciesCode(+node.entity.id), formatMessage);
  }
  if (node.entity.type === 'PlantingCycle') {
    // @ts-ignore // TODO: Fix ID types
    const cycle = allPlantingCycles.find(item => item.id === node.entity.id);
    return cycle ? getPlantingCycleTitle(cycle, allCompartments, allVarieties, allFruitClasses, formatMessage, locale) : '';
  }
  return 'Unnamed entity';
}

export default getTreeNodeTitle;
