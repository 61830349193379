import React, {
 useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import { getNameByLocal } from 'helpers/getNameByLocal';

import DefaultSearchList from 'components/DefaultSearchList';
import Select from 'components/Select';

import classnames from 'classnames';
import styles from './IncidentsFilter.module.css';

const IncidentsFilter = ({
  intl,
  className,
  classNameButton,
  classNamePopup,
  incidentsTypes,
  disabled,
  onFiltersChange,
  selectedOption,
  isMobile,
  selectComponent,
  onSetPlaceholder
}) => {
  const { formatMessage, locale, formatPlural } = intl;

  const [opened, setOpened] = useState(false);
  const [query, setQuery] = useState('');

  const handlerOpenChange = useCallback((newState) => {
    setOpened(newState);
  }, [setOpened]);

  const handlerCloseOnChange = useCallback(() => {

  }, []);

  const options = useMemo(() => {
    const searchOptions = [];
    searchOptions.push({
      id: null,
      name: formatMessage({ id: 'incidentsTimeline.filters.allIncidents' }),
      species: null,
      type: null,
      groupId: null,
      groupName: null,
      sortId: '#',
    });
    if (incidentsTypes?.length > 0) {
      searchOptions.push(
        ...incidentsTypes.map(incidentType => ({
          id: incidentType.id,
          name: getNameByLocal(incidentType, locale),
          type: null,
          groupId: null,
          groupName: null,
          sortId: '#',
        }))
      );
    }

    return (
      <DefaultSearchList
        placeholder={formatMessage({ id: 'incidentsTimeline.filters.searchListPlaceholder' })}
        options={searchOptions}
        selectedOption={selectedOption}
        contentClassName={styles.content}
        isFetching={false}
        value={query}
        empty={formatMessage({ id: 'incidentsTimeline.filters.searchListEmpty' })}
        onChanged={({ value: queryValue, onlyCheck }) => {
          setQuery(queryValue, onlyCheck);
        }}
        withCheckBox
        whiteSpaceOptions='break-word'
        onSelected={({ option, onlyCheck }) => {
          if (onFiltersChange) {
            onFiltersChange({ incidentTypeId: option.id, onlyCheck });
          }
        }}
      />
    );
  }, [query, incidentsTypes, locale, selectedOption, formatMessage, onFiltersChange]);

  const placeholder = useMemo(() => {
    if (selectedOption.length === 0 || (selectedOption.length - 1) === incidentsTypes?.length) {
      return formatMessage({ id: 'incidentsTimeline.filters.allIncidents' });
    }
    const count = selectedOption.length === 1 && selectedOption[0] === 0 ? 0 : selectedOption.length;
    const pluralIncidents = formatMessage({
      id: `incidentsTimeline.filters.selectedIncidentsPlural.${formatPlural(count)}`
    });
    return `${count} ${pluralIncidents}`;
  }, [selectedOption, formatMessage, formatPlural, incidentsTypes]);

  useEffect(() => {
    if (onSetPlaceholder) {
      onSetPlaceholder('incidents', placeholder);
    }
  }, [onSetPlaceholder, placeholder]);

  const SelectComponent = selectComponent;

  return (
    <div className={styles.titleWrapper}>
      <div className={styles.title}>{formatMessage({ id: 'incidentsTimeline.filters.incidentsTypes' })}</div>
      <SelectComponent
        classNameButton={classnames(styles.longSelectButton, classNameButton)}
        classNamePopup={classnames(styles.longSelectPopup, classNamePopup)}
        className={classnames(styles.select, className)}
        placeholder={isMobile ? formatMessage({ id: 'incidentsTimeline.filters.mobile.incidentsTypes' }) : placeholder}
        theme='white'
        size='medium'
        open={opened}
        onOpenChange={handlerOpenChange}
        textEllipsis
        withoutArrow={false}
        disabled={disabled}
        customTitle={placeholder}
        closeOnChange={handlerCloseOnChange}
        isMobile={isMobile}
        selectComponent={selectComponent}
      >
        {options}
      </SelectComponent>
    </div>
  );
};

IncidentsFilter.propTypes = {
  intl: intlShape.isRequired,
  incidentsTypes: PropTypes.array,
  onFiltersChange: PropTypes.func,
  selectedOption: PropTypes.array,
  className: PropTypes.string,
  classNameButton: PropTypes.string,
  classNamePopup: PropTypes.string,
  disabled: PropTypes.bool,
  isMobile: PropTypes.bool,
  selectComponent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  onSetPlaceholder: PropTypes.func
};

IncidentsFilter.defaultProps = {
  incidentsTypes: null,
  onFiltersChange: null,
  selectedOption: [],
  className: null,
  classNameButton: null,
  classNamePopup: null,
  disabled: false,
  isMobile: false,
  selectComponent: Select,
  onSetPlaceholder: null
};

export default IncidentsFilter;
