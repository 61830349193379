import moment from 'moment-timezone';

export default function generateFakeCropsWeeklyData() {
  const result = [];
  const now = moment().isoWeekday(1).subtract(4 * 7, 'days');

  for (let i = 0; i < 28; i++) {
    result.push({
      date: now.format('YYYY-MM-DD'),
      samples: [{ sampleNumber: null, observations: {} }]
    });

    now.add(1, 'days');
  }

  return result;
}
