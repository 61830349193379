import { find } from 'lodash';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export function addNotification(notification) {
  return {
    type: ADD_NOTIFICATION,
    payload: notification,
  };
}

export function removeNotification(id) {
  return {
    type: REMOVE_NOTIFICATION,
    id,
  };
}

export function showNotification({
  id, messageId, messageParams, iconType, notificationType, position = 'leftDown', withLineBreaks, children
}) {
  return async (dispatch, getState) => {
    const { notificationCenter: { notifications } } = getState();

    if (find(notifications, { id })) {
      return;
    }

    dispatch(addNotification({
      id, messageId, messageParams, position, iconType, notificationType, withLineBreaks, children
    }));
  };
}

export function showNotificationWithTimeout({
  id, messageId, messageParams, iconType, notificationType, position = 'leftDown', timeout = 5000, withLineBreaks, children,
}) {
  return async (dispatch, getState) => {
    const { notificationCenter: { notifications } } = getState();

    if (find(notifications, { id })) {
      return;
    }

    dispatch(addNotification({
      id, messageId, messageParams, position, iconType, notificationType, withLineBreaks, children
    }));

    setTimeout(() => {
      dispatch(removeNotification(id));
    }, timeout);
  };
}

export function hideNotification(notificationId) {
  return async (dispatch) => {
    dispatch(removeNotification(notificationId));
  };
}
